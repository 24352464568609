import React, { useEffect } from "react";
import Form from "../../components/form/Form";
import { FacilityFields } from "../../assets/fields/facilityFields";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearForm,
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
  resetData,
} from "../../redux/reducers";
import usePageMeta from "../../hooks/usePageMeta";

const AddFacility = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const { validations, values } = useSelector((state) => state.form);
  const { loading, created, data, current } = useSelector(
    (state) => state.records
  );
  const formValues = values;
  const { setMeta } = usePageMeta();

  var canUpdate = false;
  if (id) canUpdate = true;

  //navigation back to main list
  useEffect(() => {
    if (created && !canUpdate) {
      navigate("/facilities");
    }
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [created]);

  //update project actions
  useEffect(() => {
    setMeta({ title: "Add Facility" });
    if (id) {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/Facility/${id}`,
        },
      });
    }
  }, [id]);

  //mask data on single project data retrieval
  useEffect(() => {
    if (data) {
      dispatch({
        type: formatRecords.type,
        payload: { ...data },
      });
    }
  }, [data]);

  let i = {
    province: "province",
    district: "district",
    tehsil: "tehsil",
    unionCouncil: "unionCouncil",
    village: "village",
    resAddress: "resAddress",
  };

  function formateFacilityAddress(values) {
    let address = {
      parentType: "Facilities",
    };

    Object.entries(values).forEach(([key, value]) => {
      if (key === i[key]) {
        if (typeof value?.value === "object") {
          address[key] = {
            id: value?.value?.value,
            name: value?.value?.label,
          };
        } else if (typeof value?.value === "string") {
          address[key] = value?.value;
        }
      }
    });

    return address;
  }

  const addRecord = () => {
    let locationId;

    const formattedAddress = formateFacilityAddress(formValues);
    const address = { name: "address", value: formattedAddress };
    locationId = formValues?.district?.value;

    const Location = {
      name: "Location",
      value: { id: locationId?.value, name: locationId?.label },
    };

    const Target = {
      name: "Target",
      value: { id: 0, name: null },
    };

    const updatedFormValues = {
      ...formValues,
      address,
      Location,
      Target,
    };
    

    dispatch({
      type: createRecord.type,
      payload: {
        ...updatedFormValues,
        url: "/Facility",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
  };

  const updateRecord = () => {
    const targetName = {
      name: "targetName",
      value: data?.targetName,
    };

    const remarks = {
      name: "remarks",
      value: data?.remarks,
    };

    const accomplishmentDate = {
      name: "accomplishmentDate",
      value: data?.accomplishmentDate,
    };

    let transformedArray = data?.attachments?.value?.map((item, index) => {
      return {
        file: item,
      };
    });

    const attachments = {
      name: "attachments",
      value: {
        id: "attachments",
        value: transformedArray,
      },
    };

    const updatedFormValues = {
      ...data,
      targetName,
      remarks,
      accomplishmentDate,
      attachments,
    };

    dispatch({
      type: putRecord.type,
      payload: { ...updatedFormValues, url: `/Facility/${id}` },
    });
  };

  return (
    <>
      <h4>Add Infrastructure/Center/Facility</h4>
      <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
        <Form
          formData={FacilityFields ? FacilityFields : []}
          withValidation={true}
          onSubmit={() => (canUpdate ? updateRecord() : addRecord())}
          formValidation={validations}
          extraInputClass=""
        />
      </div>
    </>
  );
};

export default AddFacility;
