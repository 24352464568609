import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INTERVENTION_LOGIC_TYPE } from "../../Constants";
import { clearForm, createRecord } from "../../redux/reducers";

import Form from "../form/Form";
import Loader from "../loader/loader";

const AddActivity = ({ projectId = "" }) => {
  const dispatch = useDispatch();
  const { formFields } = useSelector((state) => state.fields);
  const { created, loading } = useSelector((state) => state.records);

  //by default clear previous step form data
  useEffect(() => {
    dispatch({ type: clearForm.type });
  }, []);

  const onAdd = (params) => {
    let formData = { ...params, projectId: { value: projectId } };
    dispatch({
      type: createRecord.type,
      payload: { ...formData, url: "/ERActivity" },
    });
  };

  function getFieldsByCategory(fields = [], key) {
    return fields.filter(({ category = "" }) => category == key);
  }

  function formatFields() {
    let fields = getFieldsByCategory(formFields, "activities");
    return fields.map((field) => {
      if (field.name == "expectedResult")
        return { ...field, url: field.url + projectId };
      return field;
    });
  }

  return (
    <div className="position-relative">
      {/* <Loader loading={loading} theme="light" /> */}
      <Form
        formData={formatFields()}
        onSubmit={(params) => onAdd(params)}
        withValidation={false}
        formValidation={[]}
        extraInputClass=""
      />
    </div>
  );
};
export default AddActivity;
