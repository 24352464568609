import { getFormatedDate } from "../../Utility";
import { formateStatus } from "../../utils/util";


const ActionPointMVRColumns = [
  {
    name: "Action Point",
    selector: (row) => row?.actionPoints,
    wrap: true,
    minWidth: "300px",
  },
  {
    name: "Followup Date",
    selector: (row) => getFormatedDate(row?.followUpDate),
  },
  {
    name: "Latest Followup by (Person)",
    selector: (row) => <>{row?.latestFollowUp?.followUpPersonName
      ? row?.latestFollowUp?.followUpPersonName :
      <span className="badge badge-pill bg-secondary py-1 text-white ml-2">
        {"No Followup Yet"}
      </span>
    }</>,
  },
  {
    name: "Latest Followup Date",
    selector: (row) => <> {
      row?.latestFollowUp?.dateofFollowup ?
        getFormatedDate(row?.latestFollowUp?.dateofFollowup)
        : <span className="badge badge-pill bg-secondary text-white py-1 ml-2">
          {"No Followup Yet"}
        </span>

    }</>,
  },
  {
    name: "Status",
    selector: (row) => <>{formateStatus(row?.status)}</>,
  },

];

export default ActionPointMVRColumns;


export const ActionPointMVRColumnsForExcel = {
  "Action Point": "actionPoints",
  "Followup Date": 'followUpDate',
  'Latest Followup by (Person)': 'latestFollowUp',
  'Latest Followup Date': 'latestFollowUp',
  'Status': 'status'
}