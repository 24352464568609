import React from "react";
import { Route, Routes, useLocation } from "react-router";

import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import "./AddContainer.scss";
import DashboardPage from "./../../pages/dashboard/DashboardPage";
import Projects from "./../../pages/projects/Projects";
import DefinitionManagement from "./../definition/DefinitionManagement";
import SearchFilterContainer from "./../search-filter/SearchFilterContainer";
import AddUpdateProject from "../../pages/projects/AddUpdateProject";
import Beneficiaries from "../../pages/beneficiaries/Beneficiaries";
import AddBeneficiaries from "../../pages/beneficiaries/AddBeneficiaries";
import SingleProject from "../../pages/projects/SingleProjects";
import MealProgressReports from "../../pages/meal-progress-report/MealProgressReports";
import MealProgressReport from "../../pages/meal-progress-report/MealProgressReport";
import AddMealProgressReport from "../../pages/meal-progress-report/AddMealProgressReport";
import Complaints from "../../pages/complaints/Complaints";
import Complaint from "../../pages/complaints/Complaint";
import AddUpdateComplaint from "../../pages/complaints/AddUpdateComplaint";
import Activity from "../../pages/activity/Activity";
import Logframe from "../../pages/logframe/Logframe";
import SpecificObjective from "../../pages/specificobjective/SpecificObjective";
import usePageMeta from "../../hooks/usePageMeta";
import MonitoringVisitReports from "../../pages/monitoring-visit-report/MonitoringVisitReports";
import MonitoringVisitReport from "../../pages/monitoring-visit-report/MonitoringVisitReport";
import AddUpdateMonitoringVisitReport from "../../pages/monitoring-visit-report/AddUpdateMonitoringVisitReport";
import ProjectAlbums from "../../pages/projects/ProjectAlbums";
import ComplaintDashboard from "../../pages/complaints/ComplaintDashboard";
import ProjectAlbum from "../../pages/projects/ProjectAlbum";
import AddActivity from "../activities/AddActivity";
import UserDetailPage from "../../pages/userAccount.js/UserDetailPage";
import UserUpdatePage from "../../pages/userAccount.js/UserUpdatePage";
import UserManagements from "../../pages/user-management/UserManagements";
import UserManagement from "../../pages/user-management/UserManagement";
import AddUpdateUserManagement from "../../pages/user-management/AddUpdateUserManagement";
import NotificationPage from "../../pages/notification/NotificationPage";
import ChangePassword from "../../pages/userAccount.js/ChangePassword";
import { useSelector } from "react-redux";
import { canUserAccess, canView } from "../../utils/util";
import TargetsList from "../../pages/targets/TargetsList";
import ActionPoint from "../../pages/monitoring-visit-report/ActionPoint";
import ChartDetail from "../../pages/chart-detail/ChartDetail";
import KeyFindings from "../../pages/monitoring-visit-report/KeyFindings";
import AddMonitoringVisitReport from "../../pages/monitoring-visit-report/AddMonitoringVisitReport";
import Facilities from "../../pages/facility/Facilities";
import AddFacility from "../../pages/facility/AddFacility";
import AddUpdateKeyFindings from "../../pages/monitoring-visit-report/AddUpdateKeyFindings";
import ProjectFacilities from "../../pages/facility/ProjectFacilities";
import MonitoringVisitReportProject from "../../pages/monitoring-visit-report/MonitoringVisitReportProject";
import ComplaintProject from "../complain/ComplaintProject";
import LogframeView from "../../pages/logframe/LogframeView";
import MonitoringVisitReportView from "../../pages/monitoring-visit-report/MonitoringVisitReportView";
import KeyFindingsView from "../../pages/monitoring-visit-report/KeyFindingsView";
import BeneficiaryView from "../../pages/beneficiaries/BeneficiaryView";
import FacilityView from "../../pages/facility/FacilityView";
import VisitReports from "../../pages/monitoring-visit-report/VisitReports";

const AppContainer = () => {
  // const { setMeta } = usePageMeta();
  const location = useLocation();
  const { roles } = useSelector((state) => state.setting?.user?.userDetail);
  const userRoles = roles && roles.map(({ name }) => name);

  React.useEffect(() => {
    // setMeta();
  }, [location]);

  return (
    <div className="d-flex">
      <div className="sidebar" style={{ backgroundColor: "#e9ebed" }}>
        <Sidebar />
      </div>
      <div className="px-4 main-content w-100">
        <Header />
        <Routes>
          <Route path="/user/*">
            <Route path="Detail" element={<UserDetailPage />} />
            <Route path=":id" element={<UserUpdatePage />} />
            <Route path="changePassword" element={<ChangePassword />} />
          </Route>

          <Route
            path="/*"
            element={
              canView(["Admin", "Management","Manager"], userRoles) ? (
                <DashboardPage />
              ) : (
                <Projects />
              )
            }
          />
          <Route path="/chart-detail/" element={<ChartDetail />} />
          <Route path="/projects/*">
            <Route path="" element={
              <Projects />
            } />
            {canView(["Admin", 'Manager'], userRoles) && (
              <>
                <Route path="add" element={<AddUpdateProject />} />
              </>
            )}
            <Route path="update/:id" element={<AddUpdateProject />} />
            <Route path=":id" element={<SingleProject />} />

            <Route path="albums/:id" element={<ProjectAlbums />} />
            <Route
              path="specific-objective/:id"
              element={<SpecificObjective />}
            />
            <Route path="logframe/:id" element={<Logframe />} />
            <Route
              path="logframe/View/:id/:indicatorId"
              element={<LogframeView />}
            />
            <Route
              path="monitoringVisitReport/:id"
              element={<VisitReports />}
            />
            <Route
              path="projectFacilities/:id"
              element={<ProjectFacilities />}
            />
            <Route
              path="albums/:id/album/:albumId"
              element={<ProjectAlbum />}
            />
            <Route path="projectComplaint/:id" element={<ComplaintProject />} />
          </Route>
          <Route path="/targets/*">
            <Route path="" element={<TargetsList />} />
          </Route>
          <Route path="/definitions/*" element={<DefinitionManagement />} />
          <Route path="/search-filter/*" element={<SearchFilterContainer />} />
          <Route path="/beneficiaries/*">
            <Route path="" element={<Beneficiaries />} />
            <Route path=":id" element={<Beneficiaries />} />
            <Route path="add" element={<AddBeneficiaries />} />
            <Route path="update/:id" element={<AddBeneficiaries />} />
            <Route path="beneficiaryView/:id" element={<BeneficiaryView />} />
          </Route>
          {canView(["Admin",'Manager'], userRoles) && (
            <>
              <Route path="/meal-progress-report/*">
                <Route path="" element={<MealProgressReports />} />
                <Route path=":id" element={<MealProgressReport />} />
                <Route path="add" element={<AddMealProgressReport />} />
                <Route path="update/:id" element={<AddMealProgressReport />} />
              </Route>
              <Route path="/monitoring-visit-report/*">
                <Route path="" element={<MonitoringVisitReports />} />
                <Route path=":id" element={<MonitoringVisitReport />} />
                <Route path="visits/:id" element={<VisitReports />} />
                <Route path="visits/key-finidings/:id" element={<KeyFindings />} />
                <Route
                  path="key-finidings/add/:reportId"
                  element={<AddUpdateKeyFindings />}
                />
                <Route
                  path="key-finidings/:reportId/update/:id"
                  element={<AddUpdateKeyFindings />}
                />
                <Route
                  path="key-finidings/action-point/:findings"
                  element={<ActionPoint />}
                />
                <Route
                  path="key-finidings/:id/key-finidings-view/:id"
                  element={<KeyFindingsView />}
                />
                {/* <Route path=":id/action-point" element={<ActionPoint />} /> */}
                <Route
                  path="add"
                  element={<AddUpdateMonitoringVisitReport />}
                />
                <Route
                  path="add-new/:id"
                  element={<AddMonitoringVisitReport />}
                />
                <Route
                  path="update-new/:id"
                  element={<AddMonitoringVisitReport />}
                />
                <Route
                  path="update/:id"
                  element={<AddUpdateMonitoringVisitReport />}
                />
                <Route
                  path="MonitoringVisitReportView/:id"
                  element={<MonitoringVisitReportView />}
                />
              </Route>

            </>
          )}

          <Route path="/facilities/*">
            <Route path="" element={<Facilities />} />
            <Route path="add" element={<AddFacility />} />
            <Route path="update/:id" element={<AddFacility />} />
            <Route path="facilityView/:id" element={<FacilityView />} />
          </Route>

          <Route path="/complaints/*">
            <Route path="" element={<Complaints />} />
            <Route path="dashboard" element={<ComplaintDashboard />} />
            <Route path="project/:id" element={<Complaints />} />
            <Route path=":id" element={<Complaint />} />
            {canView(["Admin", "Management", "Officer", 'Manager'], userRoles) && (
              <Route path="add" element={<AddUpdateComplaint />} />
            )}
            <Route path="update/:id" element={<AddUpdateComplaint />} />
          </Route>

          {canView(["Admin",'Manager'], userRoles) && (
            <Route path="/UserManagement/*">
              <Route path="" element={<UserManagements />} />
              <Route path="project/:id" element={<UserManagements />} />
              <Route path=":id" element={<UserManagement />} />
              <Route path="add" element={<AddUpdateUserManagement />} />
              <Route path="update/:id" element={<AddUpdateUserManagement />} />
            </Route>
          )}
          <Route path="/activity/*">
            <Route path="" element={<Activity />} />
            <Route path=":id" element={<Activity />} />
            <Route path="add" element={<AddActivity />} />
          </Route>
          <Route path="/notifications/*">
            <Route path="" element={<NotificationPage />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
};

export default AppContainer;
