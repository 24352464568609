export const mvrFields = [
  {
    label: "Visit Name",
    placeholder: "Enter Visit Name",
    name: "visitName",
    inputType:'textarea',
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    label: "Date of Monitoring",
    placeholder: "Enter DOD",
    name: "dateOfMonitoring",
    required: true,
    inputType: "date",
    inForm: true,
  },
  {
    label: "Reporting Person",
    placeholder: "Select Reporting Person",
    name: "reportingPerson",
    inputType: "async-dropdown",
    url: "/UserManagement/GetAll",
    searchAttrib: "Search.value",
    id: "reportingPerson",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    label: "Location of Visit",
    placeholder: "Enter Facility Name",
    name: "locationOfVisit",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Add MVR",
    inForm: true,
    wrapperClass: "mr-3 mt-2 btn-wrapper position-right",
  },
];
