import React from "react";
import { deleteRecord } from "../redux/reducers/recordSlice";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import * as Icon from "react-bootstrap-icons";
import ToolTip from "../components/tooltip/Tooltip";
import FaIcon from "../components/icon/FaIcon";

const DeleteRow = ({ row, url, handleUpdate = () => {} }) => {
  const dispatch = useDispatch();
  const onDelete = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{ minWidth: "30vw" }}
            className="d-flex flex-column shadow rounded rounded-6"
          >
            <div className="bg-warning px-3 py-4 text-center rounded-top">
              <FaIcon
                iconName="FaExclamationTriangle"
                color="#fff"
                size="4em"
              />
            </div>
            <div className="text-center px-3 py-4 bg-white rounded-bottom">
              <h3 style={{ color: "#162E4A" }}>Are you sure?</h3>
              <p style={{ color: "#162E4A" }}>
                You want to delete this record?
              </p>
              <button
                className=""
                onClick={() => {
                  handleDelete(id);
                  onClose();
                }}
              >
                Yes
              </button>
              <button className="" onClick={onClose}>
                Cancel
              </button>
            </div>
          </div>
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      afterClose: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {},
      overlayClassName: "overlay-custom-class-name",
    });
  };
  const handleDelete = (id) => {
    dispatch({
      type: deleteRecord.type,
      payload: { id, url },
    });
    handleUpdate();
  };
  return (
    <>
      <ToolTip label="Delete">
        <Icon.Trash
          onClick={() => onDelete(row?.id)}
          className="action-icons"
        />
      </ToolTip>
    </>
  );
};

export default DeleteRow;
