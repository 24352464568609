export const userManagementFields = [
  {
    label: "Username",
    name: "userName",
    placeholder: "Enter Username",
    inForm: true,
    required: true,
    wrapperClass: " col-md-6",
  },
  {
    name: "firstName",
    label: "First Name",
    placeholder: "Enter First Name",
    inForm: true,
    required: true,
    wrapperClass: " col-md-6",
  },
  {
    name: "lastName",
    label: "Last Name",
    placeholder: "Enter Last Name",
    required: true,
    inForm: true,
    wrapperClass: " col-md-6",
  },
  {
    name: "email",
    placeholder: "Enter Email",
    inputType: "email",
    label: "Email",
    type: "email",
    inForm: true,
    required: true,
    wrapperClass: " col-md-6",
  },

  {
    label: "Addresses",
    inputType: "tableInput",
    inForm: true,
    name: "addresses",
    header: [
      {
        label: "Province",
        placeholder: "Enter Province",
        name: "province",
        id: "province",
        inputType: "async-dropdown",
        url: "/Province/GetAll",
        searchAttrib: "Search.value",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        isMulti: false,
      },
      {
        label: "District",
        placeholder: "Enter District",
        name: "district",
        id: "district",
        inputType: "async-dropdown",
        url: "/District/GetAll",
        searchAttrib: "Search.value",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        isMulti: false,
        condition: {
          fieldName: "province",
          paramField: "ProvinceId",
          action: "useValue",
        },
      },
      {
        label: "Tehsil",
        placeholder: "Enter Tehsil",
        name: "tehsil",
        id: "tehsil",
        inputType: "async-dropdown",
        url: "/Tehsil/GetAll",
        searchAttrib: "Search.value",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        isMulti: false,
      },
      {
        label: "UC",
        placeholder: "Enter UC",
        name: "unionCouncil",
        id: "unionCouncil",
        inputType: "async-dropdown",
        url: "/UnionCouncil/GetAll",
        searchAttrib: "Search.value",
        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        isMulti: false,
      },
      {
        label: "Village",
        placeholder: "Enter Village",
        name: "village",
        inForm: true,
        wrapperClass: "col-4",
        isMulti: false,
        onChangeMethod: "text-in-table",
      },
      {
        label: "Residential Address",
        placeholder: "Enter Address",
        name: "resAddress",
        inForm: true,
        onChangeMethod: "text-in-table",
      },
    ],
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    placeholder: "Enter Phone Number",
    pattern: "[0-9]+",
    maxlength: 13,
    inForm: true,
    required: true,
    wrapperClass: " col-md-6",
    validation: {
      min: 9,
      max: 11,
    },
  },
  {
    name: "roles",
    label: "Role",
    id: "roles",
    placeholder: "Select Role",
    inputType: "async-dropdown",
    url: "/UserManagement/GetAllRoles?DisablePagination=true",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: true,
  },
  {
    name: "designation",
    placeholder: "Enter Designation",
    label: "Designation",
    inForm: true,
    wrapperClass: " col-md-6",
  },
  {
    name: "password",
    placeholder: "Enter Password",
    label: "Password",
    inForm: true,
    type: "password",
    inputType: "password",
    wrapperClass: " col-md-6",
  },
  {
    name: "confirmPassword",
    placeholder: "Enter Confirm Password",
    label: "Confirm Password",
    inForm: true,
    type: "password",
    inputType: "password",
    wrapperClass: " col-md-6",
  },
  // {
  //   label: "Image",
  //   placeholder: "",
  //   name: "imageUrl",
  //   inForm: true,
  //   wrapperClass: "col-md-6",
  // },
  {
    label: "Upload Display Image",
    name: "attachment",
    placeholder: "",
    onChangeMethod: "onFileChange",
    inputType: "file",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    type: "hidden",
    inForm: true,
    wrapperClass: "col-12",
  },
  {
    name: "submit",
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "btn-wrapper position-right mr-3",
  },
];
