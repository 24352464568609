export const StatusFields = [
  {
    label: "Select Action Point Status",
    placeholder: "Select Status",
    name: "status",
    inputType: "async-dropdown",
    options: [
      {
        label: "Open",
        value: "Open",
      },
      {
        label: "Resolved",
        value: "Resolved",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ],
    searchAttrib: "Search.value",
    id: "status",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-12 mb-3",
    isMulti: false,
  },
  {
    type: "hidden",
    inForm: true,
    wrapperClass: "col-12",
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Change Status",
    inForm: true,
    wrapperClass: "mr-3 mt-2 btn-wrapper position-right",
  },
];
