import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { useLocation } from "react-router-dom";

const LightBoxGallery = ({imageList,selectedImage}) => {
  // const { state } = useLocation();
  // const { imageList, index } = state;
  return (
    <div className="mt-4 mx-auto w-50">
      <ImageGallery
      showThumbnails={true}
      items={imageList} startIndex={1} />
    </div>
  );
};

export default LightBoxGallery;
