import React, { useEffect, useState } from "react";
import "./DashboardPage.scss";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../redux/reducers";
import useMediaQuery from "../../hooks/useMediaQuery";
import {
  getBarChartFormattedDate,
  getFormattedArrayBarChartData,
  getPieFormatedData,
  getPieFormatedDataByArray,
} from "../../utils/util";
import usePageMeta from "../../hooks/usePageMeta";
import BarChartApex from "../../components/apex/BarChartApex";
import PieChartApex from "../../components/apex/PieChartApex";
import {
  BarChartImpactData,
  PieChartAgeData,
  PieChartDisabilityData,
  PieChartGenderData,
} from "../../components/apex/ApexSampleData";
import PakistanMap from "../../components/map/PakistanMap";
import { useNavigate } from "react-router-dom";
import ActualVsTargetChart from "./ActualVsTargetChart";

const DashboardPage = () => {
  const dispatch = useDispatch();
  const { setMeta } = usePageMeta();
  const navigate = useNavigate();
  const isMobileView = useMediaQuery("(max-width: 768px)");
  const [filter1, setFilter1] = useState("");
  const [filter2, setFilter2] = useState("");
  const [filter3, setFilter3] = useState("");
  const { data, current } = useSelector((state) => state.records);
  const {
    gendersCount,
    activitiesCount,
    disabilitiesCount,
    ageCohortsCount,
    thematicAreaCount,
    allProjects,
    actualVsTarget,
    disctrictList,
    thematicArea,
    chartFilter,
    AllBeneficiary,
    countByLocation,
  } = current;

  const yearlyData = actualVsTarget?.data?.items;

  useEffect(() => {
    setMeta({ title: "Dashboard" });
    getBeneficiaryCountByGenders();
    setTimeout(() => {
      getBeneficiaryActualVsTarget();
    }, 100);
    setTimeout(() => {
      getBeneficiaryCountByDisabilities();
    }, 200);
    setTimeout(() => {
      getBeneficiaryCountByAgeCohorts();
    }, 400);
    setTimeout(() => {
      getBeneficiaryCountByThematicArea();
    }, 600);
    setTimeout(() => {
      getCountByLocation();
    }, 800);
    getFIltersSelectOptionData();
    getBeneficiaryCount();
  }, []);

  const getBeneficiaryActualVsTarget = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetQuarterWiseTargetVsBeneficiaryActualTarget`,
        appendCurrentReducerKey: "actualVsTarget",
      },
    });
  };

  const getBeneficiaryCountByGenders = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetBeneficiaryCountByGenders`,
        appendCurrentReducerKey: "gendersCount",
      },
    });
  };

  const getFIltersSelectOptionData = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Project/GetAll`,
        appendCurrentReducerKey: "allProjects",
      },
    });
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ThematicArea/GetAll`,
        appendCurrentReducerKey: "thematicArea",
      },
    });
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/District/GetAll`,
        appendCurrentReducerKey: "disctrictList",
      },
    });
  };

  const getBeneficiaryCountByDisabilities = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetBeneficiaryCountByDisabilities`,
        appendCurrentReducerKey: "disabilitiesCount",
      },
    });
  };

  const getBeneficiaryCount = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Beneficiary/GetAll?DisablePagination=true&OrderDir=Desc`,
        appendCurrentReducerKey: "AllBeneficiary",
      },
    });
  };

  const getBeneficiaryCountByAgeCohorts = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetBeneficiaryCountByAgeCohort`,
        appendCurrentReducerKey: "ageCohortsCount",
      },
    });
  };

  const getBeneficiaryCountByThematicArea = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetBeneficiaryCountByThematicArea`,
        appendCurrentReducerKey: "thematicAreaCount",
      },
    });
  };

  const getCountByLocation = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetCountByLocations`,
        appendCurrentReducerKey: "countByLocation",
      },
    });
  };

  const disabilityCountData = getPieFormatedDataByArray(
    disabilitiesCount?.data?.items
  );
  const ageCountData = getPieFormatedDataByArray(ageCohortsCount?.data?.items);

  const thematicAreaCountData = getFormattedArrayBarChartData(
    thematicAreaCount?.data?.items
  );

  const showTargetVsActualChart = () => {
    if (chartFilter?.data?.smartIndicatorAccomplishmentYearlyForBeneficiary) {
      let newData =
        chartFilter?.data?.smartIndicatorAccomplishmentYearlyForBeneficiary;
      return <ActualVsTargetChart yearlyData={newData} />;
    } else if (yearlyData != undefined) {
      return <ActualVsTargetChart yearlyData={yearlyData} />;
    }
  };

  const showThematicAreaChart = () => {
    if (chartFilter?.data?.dashboardReportForThematicarea?.items) {
      const thematicAreaCountData2 = getFormattedArrayBarChartData(
        chartFilter?.data?.dashboardReportForThematicarea?.items
      );
      BarChartImpactData.series = thematicAreaCountData2?.data;
      BarChartImpactData.options.xaxis.categories =
        thematicAreaCountData2?.categories;
      return <BarChartApex data={BarChartImpactData} />;
    } else if (thematicAreaCount?.data?.items) {
      return <BarChartApex data={BarChartImpactData} />;
    }
  };

  const showAgeChart = () => {
    if (chartFilter?.data?.dashboardReportForAgeRange?.items) {
      const ageCountData2 = getPieFormatedDataByArray(
        chartFilter?.data?.dashboardReportForAgeRange?.items
      );
      PieChartAgeData.series = ageCountData2?.values;
      PieChartAgeData.options.labels = ageCountData2?.labels;
      return <PieChartApex data={PieChartAgeData} />;
    } else if (ageCohortsCount?.data?.items) {
      return <PieChartApex data={PieChartAgeData} />;
    }
  };

  const showDisabilityChart = () => {
    if (chartFilter?.data?.dashboardReportForDisabilities?.items) {
      const disabilityCountData2 = getPieFormatedDataByArray(
        chartFilter?.data?.dashboardReportForDisabilities?.items
      );
      PieChartDisabilityData.series = disabilityCountData2?.values;
      PieChartDisabilityData.options.labels = disabilityCountData2?.labels;
      return <PieChartApex data={PieChartDisabilityData}/>;
    } else if (disabilitiesCount?.data?.items) {
      return <PieChartApex data={PieChartDisabilityData} />;
    }
  };

  function formatGenderData(data) {
    return {
      labels: Object.keys(data),
      values: Object.values(data),
    };
  }

  const showGenderChart = () => {
    if (chartFilter?.data?.genderVM) {
      const { female, male, other } = chartFilter.data.genderVM;

      if (female !== 0 || male !== 0 || other !== 0) {
        const genderCountData2 = formatGenderData(chartFilter?.data?.genderVM);
        PieChartGenderData.series = genderCountData2?.values;
        PieChartGenderData.options.labels = genderCountData2?.labels;
        return <PieChartApex data={PieChartGenderData} />;
      } else {
        PieChartGenderData.series = [];
        PieChartGenderData.options.labels = [];
        return <PieChartApex data={PieChartGenderData} />;
      }
    } else if (gendersCount?.data) {
      return <PieChartApex data={PieChartGenderData} />;
    }
  };
  const genderCountData = getPieFormatedData(gendersCount?.data);
  const activitiesCountData = getBarChartFormattedDate(
    activitiesCount?.data?.items
  );

  PieChartGenderData.series = genderCountData?.values;
  PieChartGenderData.options.labels = genderCountData?.labels;

  PieChartDisabilityData.series = disabilityCountData?.values;
  PieChartDisabilityData.options.labels = disabilityCountData?.labels;

  PieChartAgeData.series = ageCountData?.values;
  PieChartAgeData.options.labels = ageCountData?.labels;

  BarChartImpactData.series = thematicAreaCountData?.data;
  BarChartImpactData.options.xaxis.categories =
    thematicAreaCountData?.categories;

  const handleFilter1Change = (event) => {
    setFilter1(event.target.value);
  };

  const handleFilter2Change = (event) => {
    setFilter2(event.target.value);
  };

  const handleFilter3Change = (event) => {
    setFilter3(event.target.value);
  };

  const applyChartFilter = () => {
    // alert('Apply chart filter');
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetBeneficiaryGetAll?Project.Id=${filter1}&ThematicArea.Id=${filter2}&District.Id=${filter3}`,
        appendCurrentReducerKey: "chartFilter",
      },
    });

    showThematicAreaChart();
    showAgeChart();
    showGenderChart();
    // showTargetVsActualChart()
  };

  // let getChatYearlyData = generateChartOptions(yearlyData);

  return (
    <div className="main">
      <div className="filterBox bg-white p-5 mb-3">
        <div className="row ">
          <div className="col-md-2">
            <div class="main_circle">
              <div class="content">
                <Icon.PeopleFill color="#E4701E" size={22} /> <br />
                <span class="main_numbers">
                  {AllBeneficiary && AllBeneficiary?.data?._meta?.totalCount}
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-10 m-auto">
            <div className="row ">
              <div className="col-md-6 ">
                {/* <div className="bg-InputFields"> */}
                <label htmlFor="filter1">
                  <b>Project:</b>
                </label>
                <select
                  id="filter1"
                  value={filter1}
                  onChange={handleFilter1Change}
                  className="w-100"
                >
                  <option value="">Select an option</option>
                  {allProjects?.data?.items &&
                    allProjects?.data?.items.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.title}
                        </option>
                      );
                    })}
                </select>
                {/* </div> */}
              </div>
              <div className="col-md-6 ">
                {/* <div className="bg-InputFields">   */}
                <label htmlFor="filter2">
                  <b>Thematic Area:</b>
                </label>
                <select
                  id="filter2"
                  value={filter2}
                  onChange={handleFilter2Change}
                  className="w-100"
                >
                  <option value="">Select an option</option>
                  {thematicArea?.data?.items &&
                    thematicArea?.data?.items.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
                {/* </div> */}
              </div>
            </div>
            {/* <div className="row">
              <div className="col-md-12 text-right">
              <button className="btn btn-primary w-40" style={{ marginTop: 26 }}>Apply Filters</button>
              </div>
            </div> */}
            <div className="row mt-3">
              <div className="col-md-6">
                <label htmlFor="filter3">
                  <b>District:</b>
                </label>
                <select
                  id="filter3"
                  value={filter3}
                  onChange={handleFilter3Change}
                  className="w-100"
                >
                  <option value="">Select an option</option>
                  {disctrictList?.data?.items &&
                    disctrictList?.data?.items.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-md-6">
                <button
                  className="btn btn-primary w-100"
                  style={{ marginTop: 26 }}
                  onClick={() => applyChartFilter()}
                >
                  Apply Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        {/* {yearlyData != undefined && (
          <ActualVsTargetChart yearlyData={yearlyData} />
        )} */}
        {showTargetVsActualChart()}
        <div className="col-md-6">
          <div className="chart-bg-new text-center p-5">
            {/* <h4>
              <b>Impact by Impact Area</b>
            </h4> */}
            <div className="text-center ml-3">
              <h2
              // onClick={() =>
              //   navigate("/chart-detail", {
              //     state: { type: CHART_TYPE.Vertical_bar_CHART },
              //   })
              // }
              >
                <b>Impact Areas</b>
              </h2>
            </div>
            {/* {thematicAreaCount?.data?.items && (
              <BarChartApex data={BarChartImpactData} />
            )} */}
            {showThematicAreaChart()}
          </div>
        </div>
      </div>
      <div className="row mt-4 mb-4">
        <div className="col-md-12">
          <div className="row ">
            <div className="col-md-4">
              <div className="chart-bg-new2 p-3">
                <div className="text-left ml-3">
                  <h2
                  // onClick={() =>
                  //   navigate("/chart-detail", {
                  //     state: {
                  //       type: CHART_TYPE.Donut_CHART,
                  //       data: PieChartAgeData,
                  //     },
                  //   })
                  // }
                  >
                    <b>Age</b>
                  </h2>
                </div>
                {/* {ageCohortsCount?.data?.items && (
                  <PieChartApex data={PieChartAgeData} />
                )} */}
                {showAgeChart()}
              </div>
            </div>
            <div className="col-md-4">
              <div className="chart-bg-new2 p-3">
                <div className="text-left ml-3">
                  <h2>
                    <b>Gender</b>
                  </h2>
                </div>
                {/* {gendersCount?.data?.items && (
                  <PieChartApex data={PieChartGenderData} />
                )} */}
                {showGenderChart()}
              </div>
            </div>
            <div className="col-md-4">
              <div className="chart-bg-new2 p-3">
                <div className="text-left ml-3">
                  <h2>
                    <b>Disability</b>
                  </h2>
                </div>
                {/* {disabilitiesCount?.data?.items && (
                  <PieChartApex data={PieChartDisabilityData} />
                )} */}
                {showDisabilityChart()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4 mb-4 justify-content-center">
        <div className="col-md-6 ">
          <div className="pakistan_map">
            {countByLocation && <PakistanMap items={countByLocation?.data} />}
          </div>
        </div>
        <div className="col-md-6" style={{ height: "500px" }}>
          {/* <PakistanMap /> */}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
