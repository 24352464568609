import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { setPageMeta } from "../../redux/reducers";
import { useDispatch } from "react-redux";
import "./UserDetail.scss";
import { baseUrl } from "../../redux";
const UserDetailPage = () => {
  const { user } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  const { userDetail = {} } = user;

  useEffect(() => {
    dispatch({ type: setPageMeta.type, payload: { title: "Account Details" } });
  }, []);

  return (
    <>
      <div className="row justify-content-center mt-5">
        <h3>User Info</h3>
      </div>
      <div className="container py-3">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-lg-8 mb-4 mb-lg-0">
            <div className="card mb-3" style={{ borderRadius: ".5rem" }}>
              <div className="row g-0">
                <div
                  className="col-md-4 gradient-custom text-center text-white"
                  style={{
                    borderTopLeftRadius: ".5rem",
                    borderBottomLeftRadius: ".5rem",
                  }}
                >
                  <img
                    src={
                      userDetail?.imageUrl
                        ? `${baseUrl}${userDetail?.imageUrl}`
                        : require("../../assets/profile.png")
                    }
                    alt="Avatar"
                    className="img-fluid my-5"
                    style={{
                      width: "160px",
                      height: "160px",
                      objectFit: "cover",
                      borderRadius: 100,
                    }}
                  />
                  <h5>{userDetail?.name}</h5>
                  <p>{userDetail?.designation}</p>
                </div>

                <div className="col-md-8">
                  <div className="card-body p-4">
                    <h6>Information</h6>
                    <hr className="mt-0 mb-4" />
                    <div className="pt-1">
                      <div className="col-md-12 mb-3">
                        <h6>Full Name</h6>
                        <p className="text-muted">{userDetail?.name}</p>
                      </div>
                      <div className="col-md-12 mb-3">
                        <h6>Email</h6>
                        <p className="text-muted">{userDetail?.email}</p>
                      </div>
                      <div className="col-md-12 mb-3">
                        <h6>Phone</h6>
                        <p className="text-muted">{userDetail?.phoneNumber}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetailPage;
