import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { Link, useNavigate, useParams } from "react-router-dom";

import Table from "../../components/table/Table";
import Loader from "../../components/loader/loader";
import DeleteRow from "../DeleteRow";
import {
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
} from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";
import ToolTip from "../../components/tooltip/Tooltip";
import usePageMeta from "../../hooks/usePageMeta";
import Searchbar from "../../serachbar/Searchbar";
import Modal from "react-modal";
import { mvrFields } from "../../assets/fields/mvrFields";
import "./MonitoringVisitReport.scss";
import Form from "../../components/form/Form";
import ActionPointMVR from "./ActionPointMVR";
import VisitsColumns, { VisitsColumnsForExcel } from "../../assets/columns/VisitsColumns";

const VisitReports = () => {
  let { id } = useParams();

  const columns = [
    ...VisitsColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Details">
            <Link to={`/monitoring-visit-report/MonitoringVisitReportView/${row?.id}`}>
              <Icon.Eye className="text-primary mr-2" />
            </Link>
          </ToolTip>
          <ToolTip label="Edit">
            <Icon.PencilSquare
              className="action-icons"
              onClick={() => OpenMonitoringVisitModal(row)} //row?.id && toggleIT
            />
          </ToolTip>
          <DeleteRow row={row} url="/MonitoringVisitReport" />
        </>
      ),
    },
  ];

  const { setMeta } = usePageMeta();
  const dispatch = useDispatch();
  const { loading, data, created, onRecordSuccess, response } = useSelector(
    (state) => state.records
  );
  const { items = [] } = data;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const formValues = useSelector((state) => state.form.values);
  const navigation = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/MonitoringVisitReport/GetAll?Project.Id=${id}`,
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
        },
      });
    }, 400);
  }, [created]);

  // useEffect(() => {
  //   if (created && Object.keys(currentRow).length !== 0) {
  //     navigation(`/monitoring-visit-report/add-new/${response?.data?.id}`);
  //   } else {
  //     closeModal();
  //   }
  // }, [created]);

  useEffect(() => {
    setMeta({ title: "Monitoring Visits" });
  }, []);

  function postData() {
    let updatedFormValue = {
      ...formValues,
      project: {
        name: "project",
        value: { id: Number(id), name: "string" },
      },
      action: { name: "action", value: "string" },
    };

    // if (currentRow && Object.keys(currentRow).length === 0) {
    //   dispatch({
    //     type: createRecord.type,
    //     payload: { ...updatedFormValue, url: `/MonitoringVisitReport` },
    //   });
    // } else {
    //   dispatch({
    //     type: putRecord.type,
    //     payload: { ...updatedFormValue, url: `/MonitoringVisitReport` },
    //   });
    // }
  }

  function closeModal() {
    setModalIsOpen(false);
    setCurrentRow({});
    dispatch({ type: "form/clearForm" });
  }

  function afterMonitoringVisitModal() {}

  function OpenMonitoringVisitModal(row) {
    if (row) {
      setCurrentRow(row);
      dispatch({
        type: formatRecords.type,
        payload: { ...row },
      });
    }
    if (!row) {
      setCurrentRow({});
      dispatch({ type: "form/clearForm" });
    }
    setModalIsOpen(true);
  }

  return (
    <div className="monitoringVisitReports">
      <div className="flex justify-content-between align-items-center mb-3">
        <Searchbar
          searchListParams={{
            url: "/MonitoringVisitReport/GetAll",
            params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
            searchFieldName: "id",
          }}
        />
        <button
          className="btn btn-primary"
          onClick={() => OpenMonitoringVisitModal()}
        >
          Add New
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterMonitoringVisitModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        overlayClassName="modal-sm monitoringVisit-modal"
        contentLabel="Monitoring Visit Report"
      >
        <div className="flex justify-content-between align-content-center p-2 my-3">
          <h3>Monitoring Visits</h3>
          <Icon.XCircle onClick={closeModal} className="action-icons" />
        </div>
        <Form
          formData={mvrFields}
          onSubmit={() => postData()}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </Modal>

      {/* Table */}
      <div className="mt-5">
        <div
          id="mvrAccordion"
          className="bg-white shadow-lg py-3 accordion accordion-group col-md-12"
        >
          <div class="accordion-item">
            <h2 class="accordion-header">
              <h2> Monitoring Visits</h2>
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mvrTableCollapse"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <div>
                  <Icon.ChevronBarExpand />
                </div>
              </button>
            </h2>
            <div
              id="mvrTableCollapse"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#mvrAccordion"
            >
              <div class="accordion-body">
                <Loader loading={loading} />
                <Table columns={columns}
                customColumnNames={VisitsColumnsForExcel}
                data={items && items} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Action Point Accordion */}
      <ActionPointMVR />
    </div>
  );
};

export default VisitReports;
