import React from "react";
import { truncateText } from "../../utils/util";

const ProgressBar = ({
  value = 25,
  wrapperClass = "",
  color = "#EC7B00",
  label = null,
  limit = 3,
  ...otherProps
}) => {
  return (
    <div className={`${wrapperClass} my-1`}>
      <p style={{ marginBottom: 0}}>{label}</p>
      <div className="bg-light">
        <div
          className={`py-1 text-center text-light`}
          style={{
            ...styles.progress,
            ...{
              width: `${value > 100 ? 100 : value}%`,
              backgroundColor: `${color}`,
            },
          }}
          {...otherProps}
        >
          {/* {label && (
          <span style={styles.label}>{truncateText(label, limit)}</span>
        )}
      <span style={styles.textSpan}>{value}%</span> */}
          <span style={styles.textSpan}>{value}%</span>
        </div>
      </div>
    </div>
  );
};

const styles = {
  wrapper: {
    borderRadius: 5,
    minWidth: "100px",
    width: "100%",
  },
  progress: {
    width: 0,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    fontSize: 13,
    minHeight: "20px",
    transition: "1s all",
  },
  textSpan: {
    textShadow: "rgb(0 0 0) 1px 1px 0px",
    position: "absolute",
    marginTop: -3,
  },
  label: {
    textShadow: "rgb(0 0 0) 1px 1px 0px",
    textAlign: "left",
    position: "absolute",
    marginTop: -3,
    marginLeft: 35,
  },
};

export default ProgressBar;
