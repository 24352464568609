import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import LogframeColumns from "../../assets/columns/LogframeColumns";
import "./Logframe.scss";
import { projectMenu } from "../projects/projectMenu";
import { getRecords } from "../../redux/reducers";
import {
  getQuarterDataArray,
  getQuarterlyData,
  saveQuarterValuesToLocalStorage,
  updateQuarterDataArray,
} from "../../components/smart-indicator/Helper";


const LogframeView = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [targetList, setTargetList] = useState();
  const [quarterData, setQuarterData] = useState(null);
  const [smartIndicatorAccomplishVMs, setSmartIndicatorAccomplishVMs] = useState(null);
  const [smartIndicators, setSmartIndicators] = useState([]);
  let { id, indicatorId } = useParams();
  const { data, current } = useSelector((state) => state.records);
  const { logframeDetails, projectDetails } = current

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Project/${id}`,
        appendCurrentReducerKey: "projectDetails",
      },
    });
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/SmartIndicator/${indicatorId}`,
        appendCurrentReducerKey: "logframeDetails",
      },
    });
    setTimeout(() => {
      setStartDate(projectDetails?.data?.startDate);
      setEndDate(projectDetails?.data?.endDate);
      setTargetList(logframeDetails?.data?.targetList);
      setSmartIndicatorAccomplishVMs(logframeDetails?.data?.smartIndicatorAccomplishVMs);
      handleQuarterData();
    }, 300);
  }, [startDate, endDate, indicatorId, id]);

  const handleQuarterData = () => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);
    const data2 = getQuarterlyData(newStartDate, newEndDate);
    
    setTimeout(() => {
      const data3 = updateQuarterDataArray(data2, targetList);
     
      setQuarterData(data3);
    }, 2);
  };

  //TODO: update quarter data
  function updateQuarterlyValue(quartersData, id, value) {
    const parsedData = quartersData;
    let foundQuarter = null;

    parsedData.quarters.forEach((yearData) => {
      yearData.quarters.forEach((quarterData) => {
        if (quarterData.id === id) {
          quarterData.value = value;
          foundQuarter = quarterData;
        }
      });
    });

    if (foundQuarter) {
      let finalQuarterArray = getQuarterDataArray(parsedData);
      setQuarterData(parsedData);
      saveQuarterValuesToLocalStorage(finalQuarterArray);
      // return JSON.stringify(parsedData);
    } else {
      throw new Error(`Quarter with id "${id}" not found.`);
    }
    
  }



  const getFieldValue = (key) => {
    if (!logframeDetails?.data) return null;
    const field = logframeDetails?.data[key];
    if (typeof field === "object")
      return (
        field && field.map(({ name }) => <span className="mr-1">{name}</span>)
      );
    else return logframeDetails?.data[key];
  };


  const projectMetaCol1 = [
    {
      label: "Project Name",
      value: projectDetails?.data?.title,
    },
    {
      label: "Objective",
      value: logframeDetails?.data?.objective?.name,
    },
  ];

  const projectMetaCol2 = [
    {
      label: "Total Indicator Targets",
      value: getFieldValue("indicatorTarget"),
    },
    {
      label: "Baseline Value",
      value: getFieldValue("baselineValue"),
    },
    {
      label: "Total Targets",
      value: getFieldValue("totalTarget"),
    },
    // {
    //   label: "Project Component",
    //   value: "",
    // },
    // {
    //   label: "Description",
    //   value: "",
    // },
  ];

  return (
    <>
    <div className="row">
      <div className="col-md-8 project-summary-col">
        <h5 className=" font-weight-bold mb-4">{getFieldValue("indicator")}</h5>
        <div className="row">
          <div className="col-md-6 project-summary-col pr-md-4">
            {projectMetaCol1.map(({ label, value }, index) => (
              <div key={index} className="row mb-2">
                <div className="col-md-6 font-weight-bold">{label}</div>
                <div className="col-md-6">{value}</div>
              </div>
            ))}
          </div>
          <div className="col-md-6 pl-md-4">
            {projectMetaCol2.map(({ label, value }, index) => (
              <div key={index} className="row mb-2">
                <div className="col-md-6 font-weight-bold">{label}</div>
                <div className="col-md-6 pl-0">{value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="col-md-4">
        {smartIndicatorAccomplishVMs && smartIndicatorAccomplishVMs.map((item, index) => {
          const value = (item.accomplishedTarget / item.target) * 100
          return (
            <div key={index} className="mt-3 mb-3">
              <div className="row">
                <div className="col"><b>{item.quarterName}</b></div>
                <div className="col text-right"><small>{item.accomplishmentStatus}</small></div>
              </div>
              <div className="progress">
                <div className="progress-bar" role="progressbar" style={{width: value}} aria-valuenow={value} aria-valuemin="0" aria-valuemax="100">{value}</div>
            </div>
            </div>
          )
        })}
      </div>
    </div>
    <div className="row">
      <div className="col-md-8">
      <div className="d-inline-flex QuarterData mt-5">
          {quarterData &&
            quarterData.quarters.map((item, index) => {
              return (
                <div className="quarterBox mb-3 w-1" key={index}>
                  <div className="quarterYear border-bottom- text-center border">
                    {item.year}
                  </div>
                  <div className="quarterValues">
                    <div className="row m-0">
                      {item.quarters.map((q, index) => {
                        return (
                          <div
                            className="col border border-bottom text-center"
                            key={q.id}
                          >
                            {q.quarter}
                          </div>
                        );
                      })}
                    </div>
                    <div className="row m-0">
                      {item.quarters.map((q, index) => {
                        return (
                          <div className="col text-center p-0" key={q.id}>
                            <input
                              disabled={true}
                              defaultValue={q.value}
                              className="quarterVal border text-center"
                              placeholder=""
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
    </>
  );
};

export default LogframeView;
