import React from "react";
import { useEffect } from "react";
import { useState } from "react";

import * as Icon from "react-bootstrap-icons";
// import { baseUrl } from "../../../redux";

const UploadMultiFiles = ({
  className = "",
  value,
  multiple = true,
  ...props
}) => {
  const [files, setFiles] = useState([...value]);

  useEffect(() => {
    setFiles([...value]);
  }, [value]);

  const handleDelete = (index) => {
    let updatedFiles = [...value];
    updatedFiles.splice(index, 1);
    props.onChange({
      target: { name: props.name, isDeleted: true, updatedFiles },
    });
  };

  return (
    <>
      <input
        {...props}
        className={className}
        type="file"
        // accept={accept}
        multiple={multiple}
      />
      <span className="d-flex flex-wrap mt-2">
        {files &&
          files.map((file, index) => {
            let fileName = file?.url?.substring(
              file?.url?.lastIndexOf("/") + 1
            );
            return (
              <div key={index} className="mr-2">
                <div className="badge badge-light mr-1">
                  {fileName ? fileName : file.name}
                </div>
                <Icon.Trash
                  onClick={() => handleDelete(index)}
                  size="16"
                  className="action-icons"
                />
              </div>
            );
          })}
      </span>
    </>
  );
};

export default UploadMultiFiles;
