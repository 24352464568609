import React, { useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  use,
  useNavigation,
} from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";

import { useEffect } from "react";
import Loader from "../../components/loader/loader";
import { debriefFields } from "../../assets/fields/debriefFields";
import "../projects/Projects.scss";
import Form from "../../components/form/Form";
import {
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
} from "../../redux/reducers";
import usePageMeta from "../../hooks/usePageMeta";
import Table from "../../components/table/Table";
import { ORDER_DIRECTION } from "../../Constants";
import { getFacebookFormattedDate, getFormatedDate } from "../../Utility";
import { baseUrl } from "../../redux";
import ToolTip from "../../components/tooltip/Tooltip";
import DeleteRow from "../DeleteRow";
import ActionPointColumns, { ActionPointColumnsForExcel } from "../../assets/columns/ActionPointColumns";
import { ActionPointFields } from "../../assets/fields/actionPointFields";
import { formateDateTime, formateStatus } from "../../utils/util";
import { StatusFields } from "../../assets/fields/StatusFields";

const ActionPoint = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalIsOpen2, setIsOpen2] = useState(false);
  const [modalIsOpen3, setIsOpen3] = useState(false);
  const [statusModalIsOpen, setStatusModalIsOpen] = useState(false);
  const [activityModalId, setActivityModalId] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");
  const [actionPointId, setActionPointId] = useState(null);
  const [actionPointInfo, setActionPointInfo] = useState("");

  const { userDetail } = useSelector((state) => state.setting.user);
  const nav = useNavigate();

  const { findings } = useParams();
  const { setMeta } = usePageMeta();
  const { loading, data, current, created } = useSelector(
    (state) => state.records
  );
  const items = current?.action_points?.data?.items;
  const keyFindingInfo = current?.keyFindingById?.data;
  const dispatch = useDispatch();
  const debriefData = current?.debriefById?.data;
  const followUps = current?.follow_ups?.data?.items;

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: { url: `/ActionPoint/GetAll?KeyFinding.Id=${findings}` },
    });

    setTimeout(() => {
      getAllDebriefs();
    }, 200);
  }, []);

  useEffect(() => {
    if (created) {
      setIsOpen(false);
      getAllDebriefs();
      getAllFollowUps();
    }
  }, [created]);

  useEffect(() => {
    if (created) {
      setResponseMessage("");
    }
  }, [created]);

  useEffect(() => {
    setMeta({
      title: "Monitoring Visit Reports / Key Findings / Action Points",
    });
    getKeyFinding();
  }, []);

  useEffect(() => {
    if (!actionPointId) return;
    getAllFollowUps();
  }, [actionPointId]);

  function getAllFollowUps() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/FollowUp/GetAll?ActionPoint.Id=${actionPointId}`,
        appendCurrentReducerKey: "follow_ups",
      },
    });
  }

  function getKeyFinding() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/KeyFinding/${findings}`,
        appendCurrentReducerKey: "keyFindingById",
      },
    });
  }

  function getAllDebriefs() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ActionPoint/GetAll?KeyFinding.Id=${findings}`,
        params: {
          // MonitoringReportId: id,
          OrderDir: ORDER_DIRECTION.DESC,
          DisablePagination: false,
        },
        appendCurrentReducerKey: "action_points",
      },
    });
  }

  function toggleActivityModal(id) {
    setActionPointId(id);
    setActivityModalId(id);
    setIsOpen(!modalIsOpen);
  }
  function toggleActivityModal2() {
    setIsOpen2(!modalIsOpen2);
  }
  function toggleActivityModal3() {
    setIsOpen3(!modalIsOpen3);
  }

  function toggleStatusModal() {
    setStatusModalIsOpen(!statusModalIsOpen);
  }

  const debriefActionCol = [
    ...ActionPointColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Details">
            <Icon.Eye
              style={{ cursor: "pointer" }}
              onClick={() => {
                toggleActivityModal(row?.id);
                setActionPointInfo(row);
              }}
              className="text-primary mr-2"
            />
          </ToolTip>
          <DeleteRow row={row} url="/ActionPoint" />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...debriefData } });
    }
  }, [debriefData]);

  const postFollowUp = () => {
    const params = {
      response: { name: "response", value: responseMessage },
      followupPerson: { id: userDetail?.id, name: userDetail?.name },
      actionPoint: { id: actionPointId, name: "string" },
      dateofFollowup: {
        name: "dateofFollowup",
        value: new Date().toISOString(),
      },
      action: { name: "action", value: "string" },
    };

    dispatch({
      type: createRecord.type,
      payload: {
        ...params,
        url: "/FollowUp",
      },
    });
  };

  return (
    <div className="mt-4">
      <Loader loading={loading} />
      <div className="row">
        <div className="col-md-6">
          <div className="font-weight-bold ">key Finding</div>
          <div style={{ color: "#7C9DB2" }}>{keyFindingInfo?.keyFindings}</div>
        </div>
        <div className="col-md-6">
          <div className="font-weight-bold ">De-brief Memo</div>
          <div style={{ color: "#7C9DB2" }}>{keyFindingInfo?.debriefMemo}</div>
        </div>
      </div>

      <div>
        <div className="d-flex justify-content-between">
          <h5 className="text-primary">Action Points</h5>
          <div
            onClick={() => setIsOpen3(!modalIsOpen3)}
            className="flex border cursor-pointer px-2 py-2 bg-white rounded align-items-center"
          >
            <Icon.PlusCircle className="mr-2" />
            <span>Action Point</span>
          </div>
        </div>

        <Loader loading={loading} />
        <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
          {/* <Table columns={debriefActionCol} data={dummyData} /> */}
          <Table
            columns={debriefActionCol}
            customColumnNames={ActionPointColumnsForExcel}
            data={items ? items : []}
            nav={nav}
          />
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Indicator"
      >
        <div
          style={{ height: "100%" }}
          className=" d-flex flex-column justify-content-between"
        >
          <div>
            <div
              style={{ position: "sticky", top: 0, background: "#fff" }}
              className="rounded border-bottom"
            >
              <div className="d-flex justify-content-between align-items-center px-3 py-2">
                <div className="row">
                  <h4>Action Point</h4>
                  <span>{formateStatus(actionPointInfo?.status)}</span>
                </div>
                <Icon.XCircle
                  onClick={toggleActivityModal}
                  className="action-icons"
                />
              </div>

              <div className="font-weight-bold px-0">
                <p>{actionPointInfo && actionPointInfo?.actionPoints}</p>
              </div>
            </div>

            <div className="mt-3">
              {followUps &&
                followUps?.map((item, index) => {
                  return (
                    <div className="mb-3 pb-1 border-bottom">
                      <div className="row align-items-center ml-0">
                        <h6 className=" py-0 my-0">
                          {item?.followupPerson?.name}
                        </h6>
                        <sub className="ml-5">
                          {" "}
                          {`(${getFacebookFormattedDate(
                            item?.dateofFollowup
                          )})`}
                        </sub>
                      </div>
                      <div style={{ color: "#7C9DB2" }}>{item?.response}</div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className="text-right mt-5 row justify-content-end"
            style={{
              position: "sticky",
              bottom: -20,
              right: 20,
              background: "#fff",
            }}
          >
            <button className="btn btn-primary" onClick={toggleActivityModal}>
              Cancel
            </button>

            <button
              class="btn btn-primary ml-2"
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => toggleStatusModal()}
            >
              Change Status
            </button>

            <button
              className="btn btn-primary ml-2"
              onClick={() => setIsOpen2(true)}
            >
              Add Follow Up
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={statusModalIsOpen}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Indicator"
      >
        <div
          style={{ position: "sticky", top: -6, background: "#fff" }}
          className="rounded border-bottom d-flex justify-content-between align-items-center pt-3 px-3 py-2"
        >
          <h4>Action Point Status</h4>
          <Icon.XCircle onClick={toggleStatusModal} className="action-icons" />
        </div>

        <div class="container mt-3">
          <div class="row">
            <div class="col-md-12 border rounded-top py-2">
              <b> Action Point : </b> {actionPointInfo?.actionPoints}
            </div>
            <div class="col-sm border mt-1 mr-1 py-2">
              <b>Followup Date : </b>
              {getFacebookFormattedDate(actionPointInfo?.followUpDate)}
            </div>
            <div class="col-sm border mt-1 ml-1 py-2">
              <b>Current Status : </b>
              {formateStatus(actionPointInfo?.status)}
            </div>
          </div>
        </div>

        <div className="mt-3">
          <Form
            formData={StatusFields}
            onSubmit={(params) => changeActionPointStatus(params)}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen2}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Indicator"
      >
        <div
          style={{ height: "100%" }}
          className=" d-flex flex-column justify-content-between"
        >
          <div>
            <div
              style={{ position: "sticky", top: -6, background: "#fff" }}
              className="rounded border-bottom d-flex justify-content-between align-items-center pt-3 px-3 py-2"
            >
              <h4>Follow up List</h4>
              <Icon.XCircle
                onClick={toggleActivityModal2}
                className="action-icons"
              />
            </div>

            <div className="mt-3">
              {followUps &&
                followUps?.map((item, index) => {
                  return (
                    <div className="mb-3 pb-1 border-bottom">
                      <div className="row align-items-center ml-0">
                        <h6 className=" py-0 my-0">
                          {item?.followupPerson?.name}
                        </h6>
                        <sub className="ml-5">
                          {`(${getFacebookFormattedDate(
                            item?.dateofFollowup
                          )})`}
                        </sub>
                      </div>
                      <div style={{ color: "#7C9DB2" }}>{item?.response}</div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className="row"
            style={{
              position: "sticky",
              bottom: -20,
              // background: "#fff",
              right: 20,
              width: "100%",
            }}
          >
            <div className="col-12">
              <div className="text-right mt-5 d-flex align-items-center">
                <input
                  type="text"
                  className="form-control w-100 mr-2"
                  placeholder="Add Followups"
                  style={{ height: "46px" }}
                  value={responseMessage}
                  onChange={(e) => setResponseMessage(e.target.value)}
                />
                <button
                  onClick={() => postFollowUp()}
                  className="btn btn-primary mr-2"
                >
                  Add
                </button>
                <button
                  className="btn btn-primary"
                  onClick={toggleActivityModal2}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpen3}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Indicator"
      >
        <Icon.XCircle onClick={toggleActivityModal3} className="action-icons" />
        <h4>Add Action Point</h4>

        <div className="mt-5">
          <Form
            formData={ActionPointFields}
            onSubmit={(params) => ActionPointPostData(params)}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />
        </div>
      </Modal>
    </div>
  );

  function ActionPointPostData(params) {
    let updatedParams = {
      ...params,
      keyFinding: { id: Number(findings), name: null },
      followUpDate: { name: "followUpDate", value: new Date().toISOString() },
      action: { name: "action", value: "string" },
    };
    // debugger;
    dispatch({
      type: createRecord.type,
      payload: {
        ...updatedParams,
        url: "/ActionPoint",
      },
    });
    toggleActivityModal3();
  }

  function changeActionPointStatus(params) {
    let updatedParams = {
      ...actionPointInfo,
      action: { name: "action", value: "string" },
      timeline: { name: "timeline", value: actionPointInfo.timeline },
      status: { name: "status", value: params.status.value.value },
      id: { name: "id", value: actionPointInfo?.id },
      actionPoints: {
        name: "actionPoints",
        value: actionPointInfo?.actionPoints,
      },
      followUpDate: {
        name: "followUpDate",
        value: actionPointInfo?.followUpDate,
      },
    };

    dispatch({
      type: putRecord.type,
      payload: {
        ...updatedParams,
        url: "/ActionPoint",
      },
    });

    toggleStatusModal();
  }
};

export default ActionPoint;
