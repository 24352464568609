import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Form from "../../components/form/Form";
import Activities from "../../components/activities/Activities";
import { FormStepper, Step } from "../../components/form-stepper/FormStepper";
import FundingDetails from "../../components/funding-details/FundingDetails";
import SmartIndicator from "../../components/smart-indicator/SmartIndicator";
import {
  createRecord,
  resetData,
  setFormValues,
  putRecord,
  formatRecords,
  setStepper,
} from "../../redux/reducers";
import "./Projects.scss";
import AddSpecificObjective from "../../components/specific-objective/AddSpecificObjective";
import ProjectDetailForm from "../../components/project/ProjectDetailForm";
import AddExpectedResult from "../../components/smart-indicator/AddExpectedResult";
import { INTERVENTION_LOGIC_TYPE } from "../../Constants";
import AddActivity from "../../components/activities/AddActivity";
import ProjectFunding from "../../components/project-funding/ProjectFunding";

const AddUpdateProject = () => {
  let { id } = useParams();
  let canUpdate = false;
  if (id) canUpdate = true;

  const dispatch = useDispatch();
  const formValues = useSelector((state) => state.form.values);
  const { stepper } = useSelector((state) => state.setting);
  const { status } = useSelector((state) => state.records?.data);
  const { currentStep } = stepper;
  const navigate = useNavigate();

  const onAddProject = () => {

    dispatch({
      type: createRecord.type,
      payload: { ...formValues, url: "/Project" },
    });
  };

  const onUpdateProject = () => {
    if (currentStep <= 1 && status == "Pending") updateProjectRecord();
    else
      dispatch({
        type: setStepper.type,
        payload: { currentStep: currentStep + 1 },
      });
  };

  //get form fields
  useEffect(() => {
    dispatch({ type: "fields/fetchLayout", payload: "/json/project-fields" });
  }, []);

  function submitForm() {
    // updateProjectRecord();
    // dispatch({
    //   type: putRecord.type,
    //   payload: { ...formValues, url: "/Project/ProjectFundingDetails" },
    // });
    navigate("/projects");
  }

  function updateProjectRecord() {
    dispatch({
      type: putRecord.type,
      payload: { ...formValues, url: "/Project" },
    });
  }

  return (
    <div className="mb-4 slide-in-fwd-center">
      <FormStepper
        onNext={() => (canUpdate ? onUpdateProject() : onAddProject())}
        initialStep={0} //initialStep={0} originally
        onSubmit={() => submitForm()}
      >
        <Step key="projectDetailStep" title="Project Details">
          <div className="col-md-12 bg-white shadow mb-2 px-5 py-4 rounded-lg">
            <ProjectDetailForm canUpdate={canUpdate} projectId={id} />
          </div>
        </Step>
        <Step key="interventionLogicStep" title="Indicators">
          <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
            <AddSpecificObjective projectId={id} />
          </div>

          <div className="col-sm-12 bg-white shadow mb-2 px-4 py-4 rounded">
            <SmartIndicator
              projectId={id}
              type={INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE}
            />
          </div>
        </Step>
        {/* <Step key="logFramStep" title="Specific Objective Results">
          <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
            <AddExpectedResult projectId={id} />
          </div>

          <div className="col-sm-12 bg-white shadow mb-2 px-4 py-4 rounded">
            <SmartIndicator
              projectId={id}
              type={INTERVENTION_LOGIC_TYPE.EXPECTED_RESULT}
            />
          </div>
        </Step>
        <Step key="step3" title="Activities & Tasks">
          <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
            <AddActivity projectId={id} />
          </div>
          <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 pb-4 pt-2 rounded">
            <Activities projectId={id} />
          </div>
        </Step>
        <Step key="step4" title="Funding Details"> 
          <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 pb-4 pt-2 rounded-lg">
            <ProjectFunding projectId={id} />
          </div>
        </Step>*/}
      </FormStepper>
    </div>
  );
};
export default AddUpdateProject;
