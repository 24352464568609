import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import SearchList from "../../components/search-list/SearchList";
import Table from "../../components/table/Table";
import ProjectColumns, { ProjectCustomColumnForExcel } from "../../assets/columns/ProjectColumns";
import Loader from "../../components/loader/loader";
import { createRecord, getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";
import DeleteRow from "../DeleteRow";
import ListHeader from "../../components/list-header/ListHeader";
import "./Projects.scss";
import ToolTip from "../../components/tooltip/Tooltip";
import usePageMeta from "../../hooks/usePageMeta";
import useUserRole from "../../hooks/useUserRole";
import { canView } from "../../utils/util";

const Projects = () => {
  const dispatch = useDispatch();
  const { setMeta } = usePageMeta();
  const [hideSearchBar, setHideSearchBar] = useState(true);

  const { loading, data, created, current } = useSelector(
    (state) => state.records
  );

  const { roles } = useSelector((state) => state.setting?.user?.userDetail);
  const userRoles = roles && roles.map(({ name }) => name);

  const { items = [] } = data;
  const sdg = current?.sdg?.data?.items;

  useEffect(() => {
    setMeta({ title: "All Projects" });
    getProject();
    // setTimeout(() => {
    // getSdgs();
    // }, 1000);
  }, [created]);

  const getProject = (SDGNo = "") => {
    let params = { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC };
    params = SDGNo ? { ...params, SDGNo } : params;
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/project/GetAll",
        params: params,
      },
    });
  };

  const getSdgs = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/SDG/GetAll`,
        appendCurrentReducerKey: "sdg",
      },
    });
  };

  const onFilterClick = () => {
    setHideSearchBar(!hideSearchBar);
    if (hideSearchBar) getSdgs();
  };

  const handleApprove = (id) => {
    dispatch({
      type: createRecord.type,
      payload: { url: `/Project/Approve?id=${id}` },
    });
  };
  const columns = [
    ...ProjectColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          {row.canView && (
            <ToolTip label="Details">
              <Link to={`/projects/${row?.id}`}>
                <Icon.EyeFill className="text-primary mr-2" />
              </Link>
            </ToolTip>
          )}
          {canView(['Admin', 'Manager'],userRoles) && row.canEdit && (
            <ToolTip label="Edit">
              <Link to={`/projects/update/${row.id}`}>
                <Icon.PencilSquare className="action-icons" />
              </Link>
            </ToolTip>
          )}
          {canView(['Admin'],userRoles) &&
            row.canDelete && <DeleteRow row={row} url="/Project" />}

          {canView(["Admin"], userRoles) && !row?.isApproved && (
            <ToolTip label="Approve">
              <Icon.Check
                className="text-success h5 mt-1 cursor-pointer"
                onClick={() => handleApprove(row?.id)}
              />
            </ToolTip>
          )}
        </>
      ),
    },
  ];

  let icons = [];

  canView(["Admin"], userRoles) &&
    icons.push({
      id: 1,
      name: "PlusCircle",
      url: "/projects/add",
    });

  return (
    <>
      <div className="sub d-md-flex justify-content-between align-items-center mb-3">
        <div className="right d-md-flex py-2">
          <SearchList
            url="/Project/GetAll"
            params={{
              DisablePagination: true,
              OrderDir: ORDER_DIRECTION.DESC,
            }}
            searchFieldName="Title"
            placeholder="Search Project"
          />
          <div
            className={`${hideSearchBar ? "d-none" : "d-flex align-items-center ml-3"
              } `}
          >
            {/* <label htmlFor="" className="mr-2 mb-0">
              Select SDG:
            </label> */}
            <select
              className="w-md-50 w-75 form-control overflow-auto slide-in-fwd-center"
              onChange={({ target }) => getProject(target.value)}
            >
              <option disabled selected>
                Select SDG
              </option>
              <option value="">Show All</option>
              {sdg &&
                sdg.map(({ name, sdgNo }) => (
                  <option value={sdgNo} key={sdgNo}>
                    {name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="right text-right py-2">
          <div className="btn-bg d-flex align-items-center">
            <div
              className="add-filter-btns d-flex align-items-center"
              onClick={() => onFilterClick()}
            >
              <span>
                <Icon.Funnel />
              </span>
              <span className="ml-1">FILTER</span>
            </div>
            {canView(['Admin', "Manager"],userRoles) && <Link to="add">
              <div className="add-filter-btns d-flex align-items-center">
                <span>
                  <Icon.Plus size={20} />
                </span>
                <span className="ml-1">ADD NEW</span>
              </div>
            </Link>}
          </div>
          {/* <ListHeader options={icons}>
            <span
              className="px-3 cursor-pointer"
              onClick={() => onFilterClick()}
            >
              <ToolTip label="Filter Projects">
                <Icon.Funnel className="text-primary" />
              </ToolTip>
            </span>
          </ListHeader> */}
        </div>
      </div>
      <div className="projectTable slide-in-fwd-center">
        <Loader loading={loading} />
        <Table columns={columns} data={items} customColumnNames={ProjectCustomColumnForExcel} />
      </div>
    </>
  );
};

export default Projects;
