import React, { useEffect } from "react";
import DonutChart from "../../components/chart/DonutChart";
import { getRecords, setPageMeta } from "../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import SimpleCard from "../../components/card/SimpleCard";
import { getPieFormatedDataByArray } from "../../utils/util";
import ProgressBar from "../../components/progress-bar/ProgressBar";
import useMediaQuery from "../../hooks/useMediaQuery";
import PieChartApex from "../../components/apex/PieChartApex";
import {
  ReceivingModePieChartData,
  TypeOfComplaintPieChartData,
  NatureOfComplaintsPieChartData,
  StatusOfTreatmentPieChartData,
  ActivitiesPieChartData,
  ComplaintsPieChartGenderData,
  ComplaintsPieChartAgeData,
} from "../../components/apex/ComplaintSampleData";
import usePageMeta from "../../hooks/usePageMeta";

const ComplaintDashboard = () => {
  const { current } = useSelector((state) => state.records);
  const {
    receivingModeStats,
    typeOfComplaintStats,
    natureOfComplaintStats,
    statusOfTreatmentStats,
    activitiesStats,
    ageStats,
    projectStats,
    genderStats,
  } = current;

  const isMobileView = useMediaQuery("(max-width: 425px)");
  const dispatch = useDispatch();
  const {setMeta} = usePageMeta()

  const receivingModeStatsData = getPieFormatedDataByArray(
    receivingModeStats?.data?.items
  );
 
  const typeOfComplaintStatsData = getPieFormatedDataByArray(
    typeOfComplaintStats?.data?.items
  );
  const natureOfComplaintStatsData = getPieFormatedDataByArray(
    natureOfComplaintStats?.data?.items
  );
  const statusOfTreatmentStatsData = getPieFormatedDataByArray(
    statusOfTreatmentStats?.data?.items
  );
  const activitiesStatsData = getPieFormatedDataByArray(
    activitiesStats?.data?.items
  );
  const ageStatsData = getPieFormatedDataByArray(ageStats?.data?.items);
  const projectStatsData = getPieFormatedDataByArray(projectStats?.data?.items);
 
  const genderStatsData = getPieFormatedDataByArray(genderStats?.data?.items);

  const getReceivingModeStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetReceivingModeStats?DisablePagination=true`,
        appendCurrentReducerKey: "receivingModeStats",
      },
    });
  };

  const getTypeOfComplaintStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetTypeOfComplainantStats?DisablePagination=true`,
        appendCurrentReducerKey: "typeOfComplaintStats",
      },
    });
  };
  const getNatureOfComplaintStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetNatureOfComplaintStats?DisablePagination=true`,
        appendCurrentReducerKey: "natureOfComplaintStats",
      },
    });
  };
  const getStatusOfTreatmentStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetStatusOfTreatmentStats?DisablePagination=true`,
        appendCurrentReducerKey: "statusOfTreatmentStats",
      },
    });
  };
  const getActivitiesStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetActivitiesStats?DisablePagination=true`,
        appendCurrentReducerKey: "activitiesStats",
      },
    });
  };
  const getAgeStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetAgeStats?DisablePagination=true`,
        appendCurrentReducerKey: "ageStats",
      },
    });
  };
  const getProjectStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetProjectStats?DisablePagination=true`,
        appendCurrentReducerKey: "projectStats",
      },
    });
  };
  const getGenderStats = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/ComplaintDashboard/GetGenderStats?DisablePagination=true`,
        appendCurrentReducerKey: "genderStats",
      },
    });
  };

  useEffect(() => {
    setMeta({title: "Complaint Dashboard"})
    getProjectStats();
    setTimeout(() => {
      getStatusOfTreatmentStats();
    }, 200);
    setTimeout(() => {
      getNatureOfComplaintStats();
    }, 400);
    setTimeout(() => {
      getTypeOfComplaintStats();
    }, 600);
    setTimeout(() => {
      getReceivingModeStats();
    }, 800);
    setTimeout(() => {
      getAgeStats();
    }, 1000);
    setTimeout(() => {
      getGenderStats();
    }, 1200);
  }, []);

  // receiving mode
  ReceivingModePieChartData.series = receivingModeStatsData?.values;
  ReceivingModePieChartData.options.labels = receivingModeStatsData?.labels;

  // type of complaint
  TypeOfComplaintPieChartData.series = typeOfComplaintStatsData?.values;
  TypeOfComplaintPieChartData.options.labels = typeOfComplaintStatsData?.labels;

  // nature of complaint
  NatureOfComplaintsPieChartData.series = natureOfComplaintStatsData?.values;
  NatureOfComplaintsPieChartData.options.labels =
    natureOfComplaintStatsData?.labels;

  // status of treatment
  StatusOfTreatmentPieChartData.series = statusOfTreatmentStatsData?.values;
  StatusOfTreatmentPieChartData.options.labels =
    statusOfTreatmentStatsData?.labels;

  // activities
  ActivitiesPieChartData.series = activitiesStatsData?.values;
  ActivitiesPieChartData.options.labels = activitiesStatsData?.labels;

  // age stats
  ComplaintsPieChartAgeData.series = ageStatsData?.values;
  ComplaintsPieChartAgeData.options.labels = ageStatsData?.labels;

  // project stats
  // ProjectPieChartData.series = projectStatsData?.values;
  // ProjectPieChartData.options.labels = projectStatsData?.labels;

  //gender stats
  ComplaintsPieChartGenderData.series = genderStatsData?.values;
  ComplaintsPieChartGenderData.options.labels = genderStatsData?.labels;

  return (
    <div className="main">
      {/* first row */}
      <div className="row">
        <div className="col-md-6 col-lg-3">
          <SimpleCard title={"Receiving Mode"}>
            {receivingModeStats && (
              <PieChartApex data={ReceivingModePieChartData} />
            )}
          </SimpleCard>
        </div>
        <div className="col-md-6 col-lg-3">
          <SimpleCard title={"Type of Complaints"}>
            {typeOfComplaintStats && (
              <PieChartApex data={TypeOfComplaintPieChartData} />
            )}
          </SimpleCard>
        </div>
        <div className="col-md-6 col-lg-3">
          <SimpleCard title={"Nature of Complaints"}>
            {natureOfComplaintStats && (
              <PieChartApex data={NatureOfComplaintsPieChartData} />
            )}
          </SimpleCard>
        </div>
        <div className="col-md-6 col-lg-3">
          <SimpleCard title={"Status Of Complaints"}>
            {statusOfTreatmentStats && (
              <PieChartApex data={StatusOfTreatmentPieChartData} />
            )}
          </SimpleCard>
        </div>
      </div>

      {/* second row */}

      {/* 3rd Row */}
      <div className="row">
        <div className="col-md-4">
          <SimpleCard title={"Gender"}>
            {genderStats && (
              <PieChartApex data={ComplaintsPieChartGenderData} />
            )}
          </SimpleCard>
        </div>
        <div className="col-md-4">
          <SimpleCard title={"Age"}>
            {ageStats && <PieChartApex data={ComplaintsPieChartAgeData} />}
          </SimpleCard>
        </div>
        <div className="col-md-4 h-100">
          <SimpleCard title={"Projects"}>
            {projectStats &&
              projectStatsData.labels.map((lbl, ind) => (
                <ProgressBar
                  label={lbl}
                  value={projectStatsData.values[ind]}
                  key={ind}
                />
              ))}
          </SimpleCard>
        </div>
      </div>

      {/* <div className="row">
       <div className="col-md-8">
          <SimpleCard title={"Activities"}>
            <PieChartApex data={ActivitiesPieChartData} />
          </SimpleCard>
        </div> 
         <div className="col-md-4 h-100">
          <SimpleCard title={"Projects"}>
            {projectStatsData.labels.map((lbl, ind) => (
              <ProgressBar
                label={lbl}
                value={projectStatsData.values[ind]}
                key={ind}
              />
            ))}
          </SimpleCard>
        </div>
      </div> */}
    </div>
  );
};

export default ComplaintDashboard;
