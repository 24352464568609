import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { Link, useNavigate, useParams } from "react-router-dom";

import Table from "../../components/table/Table";
import Loader from "../../components/loader/loader";
import MonitoringVisitColumns from "../../assets/columns/MonitoringVisitColumns";
import DeleteRow from "../DeleteRow";
import { getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";
import SearchList from "../../components/search-list/SearchList";
import ListHeader from "../../components/list-header/ListHeader";
import ToolTip from "../../components/tooltip/Tooltip";
import usePageMeta from "../../hooks/usePageMeta";
import Searchbar from "../../serachbar/Searchbar";
import DebriefColumns, { DebriefColumnsForExcel } from "../../assets/columns/DeBriefColumns";

const KeyFindings = () => {
  const columns = [
    // ...MonitoringVisitColumns,
    ...DebriefColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Details">
            <Link
              to={`/monitoring-visit-report/key-finidings/${id}/key-finidings-view/${row?.id}`}
            >
              <Icon.Eye className="text-primary mr-2" />
            </Link>
          </ToolTip>
          <ToolTip label="Edit">
            <Link to={`update/${row?.id}`}>
              <Icon.PencilSquare className="action-icons" />
            </Link>
          </ToolTip>
          <DeleteRow row={row} url="/KeyFinding" />
        </>
      ),
    },
  ];

  const { setMeta } = usePageMeta();
  const dispatch = useDispatch();
  const { loading, data, created } = useSelector((state) => state.records);
  const { id } = useParams();
  const { items = [] } = data;
  const nav = useNavigate();

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/KeyFinding/GetAll?MonitoringVisitReport.Id=${id}`,
        params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
      },
    });
  }, [created]);

  useEffect(() => {
    setMeta({ title: "Monitoring Visit Reports / Key Findings" });
  }, []);

  return (
    <>
      <Searchbar
        className="mb-3"
        searchListParams={{
          url: "/KeyFinding/GetAll",
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
          searchFieldName: "id",
        }}
        listHeaderParams={{
          options: [
            {
              id: 1,
              name: "PlusCircle",
              url: `/monitoring-visit-report/key-finidings/add/${id}`,
            },
          ],
        }}
      />
      {/* Table */}
      <div className="projectTable">
        <Loader loading={loading} />
        <Table columns={columns} customColumnNames={DebriefColumnsForExcel} data={items} nav={nav} />
        {/* <Table columns={columns} data={items && items} /> */}
      </div>
    </>
  );
};

export default KeyFindings;
