import React, { useEffect, useRef, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ProgressBar from "../../components/progress-bar/ProgressBar";
import Tooltip from "../../components/tooltip/Tooltip";
import { projectMenu } from "./projectMenu";
import "./Projects.scss";
import usePageMeta from "../../hooks/usePageMeta";
import { getIntProgressValue, truncateText } from "../../utils/util";
import RadialBarAngleCircle from "../../components/chart/RadialBarAngleCircle";
import { getFormatedDate } from "../../Utility";
import { getRecords } from "../../redux/reducers";
import ActualVsTargetCard from "../../components/project/ActualVsTargetCard";
const loadImage = (url) => {
  const image = require(url);

  return image;
};

const SingleProject = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const { data, current } = useSelector((state) => state.records);
  const projectData = data;
  const { canEditBeneficiaries, canEditComplaints, canEditProject } =
    projectData;
  const quarterWiseData = current?.quarterWiseData?.data?.items;
  const facilityQuarterWiseData = current?.facilityQuarterWiseData?.data?.items;

  const { setMeta } = usePageMeta();

  const getFieldValue = (key) => {
    if (!projectData) return null;
    const field = projectData[key];
    if (typeof field === "object")
      return (
        field && field.map(({ name }) => <span className="mr-1">{name}</span>)
      );
    else return projectData[key];
  };

  const projectMetaCol1 = [
    {
      label: "Meal Manager",
      value: getFieldValue("projectManagers"),
    },
    {
      label: "MEAL Officers",
      value: getFieldValue("mealOfficers"),
    },
    {
      label: "Start Date",
      value: getFormatedDate(getFieldValue("startDate")),
    },
    {
      label: "End Date",
      value: getFormatedDate(getFieldValue("endDate")),
    },
    {
      label: "Funds",
      value: `PKR. ${projectData?.totalCost}`,
      // "Total: 388,874 EUR 82% SIF (321,940 EUR) 16% SIAPEP (61,394 EUR) 2% Sami Foundation (6,630 EUR)",
    },
    {
      label: "Global Indicators",
      value: projectData?.globalIndicator?.map((item) => (
        <p className="border-bottom pb-1 mt-0">{item?.name}</p>
      )),
      // "Total: 388,874 EUR 82% SIF (321,940 EUR) 16% SIAPEP (61,394 EUR) 2% Sami Foundation (6,630 EUR)",
    },
    // {
    //   label: "SDG",
    //   value:
    //     projectData?.sdGs &&
    //     projectData?.sdGs.map(({ id, name }, ind) => (
    //       <Tooltip label={name}>
    //         <img
    //           key={id}
    //           style={{ width: "35px", paddingLeft: "8px" }}
    //           src={`/images/sdg_icons/icon ${ind + 1}.svg`}
    //         />
    //       </Tooltip>
    //     )),
    // },
    {
      label: "Donor Agency",
      value: projectData?.fundingDetails?.map((item) => (
        <span
          style={{
            backgroundColor: "#67748E",
            fontSize: 16,
            fontWeight: "normal",
          }}
          className="badge rounded-pill text-white px-2 py-1 mx-1"
        >
          {item.name}
        </span>
      )),
    },

    //second columns values
    {
      label: "Implementing Partner(s)",
      value: projectData?.implementingPartners?.map((item) => (
        <span
          style={{
            backgroundColor: "#67748E",
            fontSize: 16,
            fontWeight: "normal",
          }}
          className="badge text-white px-2 py-1 mx-1"
        >
          {item.name}
        </span>
      )),
    },
    {
      label: "Location",
      value: projectData?.locations?.map((item) => (
        <span
          style={{
            backgroundColor: "#67748E",
            fontSize: 16,
            fontWeight: "normal",
          }}
          className="badge text-white px-2 py-1 mx-1"
        >
          {item.name}
        </span>
      )),
    },
  ];

  const projectMetaCol2 = [
    // {
    //   label: "Target Groups",
    //   value: getFieldValue("targetGroups"),
    // },
    // {
    //   label: "Project Component",
    //   value: getFieldValue("generalObjectives"),
    // },
    // {
    //   label: "Description",
    //   value: getFieldValue("generalObjectives"),
    // },
  ];

  useEffect(() => {
    // dispatch({ type: "records/getRecords", payload: { url: `/project/${id}` } })
    dispatch({
      type: "records/getRecords",
      payload: { url: `/Project/${id}` },
    });
  }, []);

  useEffect(() => {
    setMeta({ title: projectData?.title });
    getActualVsTargetData();
    setTimeout(() => {
      getFacilityActualVsTargetData();
    }, 200);
  }, [projectData]);

  function getActualVsTargetData() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetQuarterWiseTargetVsBeneficiaryActualTarget`,
        appendCurrentReducerKey: "quarterWiseData",
      },
    });
  }

  function getFacilityActualVsTargetData() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Dashboard/GetQuarterWiseTargetVsFacilityActualTarget`,
        appendCurrentReducerKey: "facilityQuarterWiseData",
      },
    });
  }

  return (
    <div className="row">
      <div className="col-md-8 project-summary-col">
        <h5 className="text-center font-weight-bold mb-4">Project Impact</h5>
        <div className="row">
          <div className="col-md-12 project-summary-col pr-md-4">
            {projectMetaCol1?.map(({ label, value }, index) => (
              <div key={index} className="row mb-2">
                <div className="col-md-6 font-weight-bold">{label}</div>
                <div className="col-md-6 pl-0">{value}</div>
              </div>
            ))}
          </div>
          {/* <div className="col-md-6 pl-md-4">
            {projectMetaCol2?.map(({ label, value }, index) => (
              <div key={index} className="row mb-2">
                <div className="col-md-6 font-weight-bold">{label}</div>
                <div className="col-md-6 pl-0">{value}</div>
              </div>
            ))}
          </div> */}
        </div>
        <div className="d-flex project-menu-wrapper my-5">
          {projectMenu?.map(({ label, thumbnail = "", link = "" }, index) => (
            <div className="card" key={index}>
              <Link to={link + "/" + id}>
                <img className="image-responsive" src={thumbnail} alt={label} />
                <label className="text-sm" style={{ color: "#E4701E" }}>
                  {label}
                </label>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="col-md-4">
        {facilityQuarterWiseData != undefined &&
          quarterWiseData != undefined && (
            <ActualVsTargetCard
              facilityQuarterWiseData={facilityQuarterWiseData}
              quarterWiseData={quarterWiseData}
            />
          )}

        <div className="bg-white shadow p-4 mt-4 col-md-12 rounded rounded-3">
          <div className="col-md-12 d-flex justify-content-between align-items-center mb-2">
            <h5 className="text-center font-weight-bold col-md-11">
              Project Impact
            </h5>
            <Icon.ChevronBarExpand
              data-bs-toggle="collapse"
              data-bs-target="#projectImpact"
              aria-expanded="true"
              aria-controls="projectImpact"
              className="action-icons"
              size={24}
            />
          </div>
          <div id="projectImpact" className="row">
            <div className="col-md-12">{projectData?.projectImpact}</div>
          </div>
        </div>

        <div className="bg-white shadow p-4 mt-4 col-md-12 rounded rounded-3">
          <div className="col-md-12 d-flex justify-content-between align-items-center mb-2">
            <h5 className="text-center font-weight-bold col-md-11">
              Project Description
            </h5>
            <Icon.ChevronBarExpand
              data-bs-toggle="collapse"
              data-bs-target="#projectDescription"
              aria-expanded="false"
              aria-controls="projectDescription"
              className="action-icons"
              size={24}
            />
          </div>
          <div id="projectDescription" className="row">
            <div className="col-md-12">{projectData?.projectDescription}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProject;
