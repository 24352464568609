import React from "react";
import "./Upload.scss";

const Upload = ({ className = "", value = "", ...props }) => {
  console.log("🚀 ~ file: Upload.js:5 ~ Upload ~ value:", value)
  const getValue = () => {
    return value && value.replace(/^.*[\\\/]/, "");
  };
  return (
    <>
      <input {...props} className={className} value="" type="file" />
      {getValue()}
    </>
  );
};

export default Upload;
