import complaintLevelSatisfaction from "../values/complaintLevelSatisfaction";
import modeOfResponseTypes from "../values/modeOfResponseTypes";

export const complaintResponseFields = [
  {
    "label": "Complainant Level Of Satisfaction",
    "placeholder": "Select Level",
    "name": "complainantLevelOfSatisfaction",
    "id": "complainantLevelOfSatisfaction",
    "inputType": "async-dropdown",
    "options": complaintLevelSatisfaction,
    "searchAttrib": "name",
    "labelAttribute": "name",
    "valueAttribute": "id",
    "onChangeMethod": "asyncdropdown",
    "inForm": true,
    "wrapperClass": "col-6",
    "isMulti": false,
    required: true,
    isEnum: true
  },
  {
    "label": "Mode of Response",
    "placeholder": "Select mode",
    "name": "modeOfResponding",
    "id": "modeOfResponding",
    "inputType": "async-dropdown",
    "options": modeOfResponseTypes,
    "searchAttrib": "name",
    "labelAttribute": "name",
    "valueAttribute": "id",
    "onChangeMethod": "asyncdropdown",
    "inForm": true,
    "wrapperClass": "col-6",
    "isMulti": false,
    required: true,
    isEnum: true
  },
  {
    "name": "comments",
    "label": "Comments",
    "placeholder": "Enter comments",
    "inputType": "textarea",
    "inForm": true,
    "wrapperClass": "col-6"
  },
  // {
  //   "name": "appeal",
  //   "label": "Appeal",
  //   "placeholder": "Enter appeal",
  //   "inputType": "textarea",
  //   "inForm": true,
  //   "wrapperClass": "col-6"
  // },
  {
    "type": "hidden",
    "inForm": true,
    "wrapperClass": "col-12"
  },
  {
    "inputType": "button",
    "buttonType": "submit",
    "children": "Submit",
    "inForm": true,
    "wrapperClass": "mr-3 btn-wrapper position-right"
  }
]