import React, { useEffect } from "react";
import usePageMeta from "../../hooks/usePageMeta";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../redux/reducers";
import { getFormatedDate } from "../../Utility";

const KeyFindingsView = () => {
  const { setMeta } = usePageMeta();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data, created, current } = useSelector(
    (state) => state.records
  );
  useEffect(() => {
    setMeta({ title: "Key Finding View" });
  }, []);

  useEffect(() => {
    getKeyfindingsView();
  }, []);

  const getKeyfindingsView = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/KeyFinding/${id}`,
        CurrentReducerKey: "keyFindingsView",
      },
    });
  };

  return (
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <h4 className="pt-1 mb-3 text-center">Key Finding View</h4>
        <div className="row bg-white pt-3 pb-3 mt-5 shadow rounded p-2 border border-warning">
          <div className="col-sm-3 col-6 mt-2">
            <h6>Key Findings</h6>
          </div>
          <div className="col-sm-9 col-6">{data?.keyFindings}</div>
          <div className="col-sm-3 col-6 mt-2">
            <h6>Monitoring Visit Report</h6>
          </div>
          <div className="col-sm-9 col-6">{data?.monitoringVisitReport?.name}</div>
          <div className="col-sm-3 col-6 mt-2">
            <h6>Date of Debrief</h6>
          </div>
          <div className="col-sm-9 col-6">
            {getFormatedDate(data?.dateOfDeBrief)}
          </div>
          <div className="col-sm-3 col-6 mt-2">
            <h6>Debrief Memo</h6>
          </div>
          <div className="col-sm-9 col-6">{data?.debriefMemo}</div>
          <div className="col-sm-3 col-6 mt-2">
            <h6>Created On</h6>
          </div>
          <div className="col-sm-9 col-6">{getFormatedDate(data?.createdOn)}</div>
        </div>
      </div>
    </div>
  );
};

export default KeyFindingsView;
