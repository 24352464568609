import React, { useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MAP_ICON from "./../../assets/images/map-icon.png";
import "./Projects.scss";
import LightBoxGallery from "../lightbox-gallery/LightBoxGallery";
import { baseUrl } from "../../redux";

const ProjectAlbum = () => {
  const dispatch = useDispatch();
  // let { id, albumId, selectedImage = null } = useParams();
  const location = useLocation();
  const imageList = location?.state?.imageList || [];
  useEffect(() => {
    // dispatch({ type: "records/getRecords", payload: { url: `/Project/GetProjectDetails?id=${id}` } })
  }, []);

  const transformArray = imageList?.map(({ id, url }) => {
    return {
      original: `${baseUrl}${url}`,
      thumbnail: `${baseUrl}${url}`,
    };
  });

  return (
    <LightBoxGallery
      imageList={transformArray}
      selectedImage={location?.index}
    />
  );
};

export default ProjectAlbum;
