export const leftMenu = [
  {
    label: "Country",
    path: "/definitions/country"
  },
  {
    label: "Province",
    path: "/definitions/province"
  },
  {
    label: "District",
    path: "/definitions/district"
  },
  {
    label: "Tehsil",
    path: "/definitions/tehsil"
  },
  {
    label: "Union Council",
    path: "/definitions/union-council"
  },
  {
    label: "Thematic Area",
    path: "/definitions/thematic-area"
  },
  {
    label: "Vulnerability",
    path: "/definitions/vulnerability"
  },
  {
    label: "Sustainable Development Goal",
    path: "/definitions/sustainable-development-goal"
  },
  {
    label: "Frequency",
    path: "/definitions/periodicity"
  },
  {
    label: "Target Type",
    path: "/definitions/target-type"
  },
  {
    label: "Global Indicators",
    path: "/definitions/global-indicators"
  },
  {
    label: "Means of Verification",
    path: "/definitions/means-of-verification"
  },
  {
    label: "Data Collection Tools",
    path: "/definitions/data-collection-tools"
  },
  {
    label: "Donor Organization",
    path: "/definitions/funding-organization"
  },
  {
    label: "Implementing Partner",
    path: "/definitions/implementing-partner"
  }
];




 
