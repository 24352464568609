import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import ComplaintColumns, { ComplaintCustomColumnForExcel } from "../../assets/columns/ComplaintsColumns";

const ComplaintProject = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const { items } = useSelector((state) => state.records.data);

  useEffect(() => {
    dispatch({
      type: "records/getRecords",
      payload: { url: `/Complaint/GetAll?ProjectId=${id}` },
    });
  }, []);


  const columns = [
    ...ComplaintColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <Link to={`/projects/specific-objective/${row.id}`}>
            <Icon.Folder2Open className="action-icons" />
          </Link>
          {/* <Icon.PencilSquare className="action-icons" />
          <Icon.Trash className="action-icons" /> */}
        </>
      ),
    },
  ];
  return (
    <>
      <div className="sub d-flex justify-content-between align-items-center px-3 mb-3">
        <div>
          <h3>Complaint</h3>
        </div>
        {/* <div className="right">
              <span className="px-1">
                <Icon.Funnel />
              </span>
              <span>
                <Link to="/projects/add">
                  <Icon.PlusCircle />
                </Link>
              </span>
            </div> */}
      </div>
      <div>
        <Table columns={columns} customColumnNames={ComplaintCustomColumnForExcel} data={items} />
      </div>
    </>
  );
};

export default ComplaintProject;
