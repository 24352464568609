import { FormStepper, Step } from "../../components/form-stepper/FormStepper";
import Form from "../../components/form/Form";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import Loader from "../../components/loader/loader";
import {
  clearForm,
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
  resetData,
} from "../../redux/reducers";
import { useNavigate, useParams } from "react-router-dom";
import { getFieldsByCategory } from "../../utils/util";
import Expandable from "../../components/accordian/KeyFindingExpandable";
import ActionPointColumns, { ActionPointColumnsForExcel } from "../../assets/columns/ActionPointColumns";
import Table from "../../components/table/Table";
import { mvrFields } from "../../assets/fields/mvrFields";
import { keyfindingsFields } from "../../assets/fields/keyfindingsFields";
import { useState } from "react";
import ToolTip from "../../components/tooltip/Tooltip";
import DeleteRow from "../DeleteRow";
import Modal from "react-modal";
import { ActionPointFields } from "../../assets/fields/actionPointFields";
import { resetCurrent } from "../../redux/reducers/recordSlice";

const AddMonitoringVisitReport = () => {
  const actionPointColumns = [
    ...ActionPointColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Edit">
            <Icon.PencilSquare
              className="action-icons"
              onClick={() => OpenActionPointModal(row)} //row?.id && toggleIT
            />
          </ToolTip>
          <DeleteRow row={row} url="/ActionPoint" />
        </>
      ),
    },
  ];

  const dispatch = useDispatch();
  let { id } = useParams();
  const navigate = useNavigate();
  const formFields = useSelector((state) => state.fields.formFields);
  const { loading, created, data, current } = useSelector(
    (state) => state.records
  );

  const formValues = useSelector((state) => state.form.values);
  const { userDetail } = useSelector((state) => state.setting.user);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [keyFindingId, setKeyFindingId] = useState();
  let canUpdate = false;
  if (id) canUpdate = true;

  useEffect(() => {
    dispatch({
      type: "fields/fetchLayout",
      payload: "/json/monitoring-visit-fields",
    });
  }, []);

  useEffect(() => {
    if (created) {
      closeModal();
      getAllkeyfindings(id);

      // navigate("/monitoring-visit-report");
    }
  }, [created]);

  useEffect(() => {
    if (id) {
      getAllkeyfindings(id);
    }
  }, []);

  function getAllkeyfindings(id) {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/KeyFinding/GetAll?MonitoringVisitReport.Id=${id}`,
        appendCurrentReducerKey: "keyfindings",
      },
    });
  }

  //update project actions
  useEffect(() => {
    if (id) {
      dispatch({
        type: getRecords.type,
        payload: { url: `/MonitoringVisitReport/${id}` },
      });
    }
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [id]);

  //mask data on single project data retrieval
  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...data } });
    }
  }, [data]);

  const addKeyfindings = (params) => {
    dispatch({
      type: createRecord.type,
      payload: {
        ...formValues,
        dateOfDeBrief: {
          name: "dateOfDeBrief",
          value: new Date().toISOString(),
        },
        monitoringVisitReport: {
          id: id,
          name: "Flood Relief Fund",
        },
        // reportingPerson: userDetail,
        url: "/KeyFinding",
        // headers: { "Content-Type": "multipart/form-data" },
      },
    });
  };

  function closeModal() {
    setModalIsOpen(false);
    setCurrentRow({});
    dispatch({ type: "form/clearForm" });
  }

  function afterActionPointModalOpen() { }

  function OpenActionPointModal(row) {
    if (row) {
      setCurrentRow(row);
      dispatch({
        type: formatRecords.type,
        payload: { ...row },
      });
    }
    if (!row) {
      setCurrentRow({});
      dispatch({ type: "form/clearForm" });
    }
    setModalIsOpen(true);
  }

  const postActionPoint = (params) => {
    let newFormValues = {
      ...formValues,
      keyFinding: { name: "keyFinding", value: keyFindingId },
      action: { name: "action", value: "string" },
      followUpDate: { name: "followUpDate", value: new Date().toISOString() },
    };

    dispatch({ type: resetCurrent.type });

    if (currentRow && Object.keys(currentRow).length === 0) {
      dispatch({
        type: createRecord.type,
        payload: { ...newFormValues, url: `/ActionPoint` },
      });
    } else {
      dispatch({
        type: putRecord.type,
        payload: { ...newFormValues, url: `/ActionPoint` },
      });
    }
    setTimeout(() => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/KeyFinding/GetAll?MonitoringVisitReport.Id=${id}`,
          appendCurrentReducerKey: "keyfindings",
        },
      });
    }, 500);
  };

  return (
    <div className="mb-4">
      <Loader loading={loading} />
      <>
        <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
          <Form
            formData={keyfindingsFields}
            withValidation={false}
            onSubmit={(params) => addKeyfindings(params)}
            formValidation={[]}
            extraInputClass=""
          />
        </div>
        <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
          {current?.keyfindings?.data?.items.map((item, index) => {
            return (
              <Expandable
                title={item.keyFindings}
                addActionPoint={OpenActionPointModal}
                item={item}
                setKeyFindingId={setKeyFindingId}
                content={actionPointColumns}
                customColumn={ActionPointColumnsForExcel}
              />
            );
          })}
        </div>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterActionPointModalOpen}
          onRequestClose={closeModal}
          ariaHideApp={false}
          overlayClassName="modal-sm monitoringVisit-modal"
          contentLabel="Monitoring Visit Report"
        >
          <div className="flex justify-content-between align-content-center p-2 my-3">
            <h3>Action Point</h3>
            <Icon.XCircle onClick={closeModal} className="action-icons" />
          </div>
          <Form
            formData={ActionPointFields}
            onSubmit={(params) => postActionPoint(params)}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />
        </Modal>
      </>
    </div>
  );
};

export default AddMonitoringVisitReport;
