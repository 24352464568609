import genderTypes from "./../values/genderTypes";
import ageBrackets from "./../values/ageBrackets";
import relationshipTypes from "../values/relationshipTypes";

const beneficiaryFields = [
  {
    category: "details",
    label: "Project Title",
    placeholder: "Select Project",
    name: "project",
    inputType: "async-dropdown",
    url: "/Project/GetAll",
    searchAttrib: "Search.value",
    id: "project",
    labelAttribute: "title",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    label: "Objective Name",
    category: "details",
    placeholder: "Select Objective",
    name: "objectiveName",
    inputType: "async-dropdown",
    url: "/Objective/GetAll?DisablePagination=true",
    searchAttrib: "Search.value",
    id: "objectiveName",
    labelAttribute: "objectiveTitle",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isBeneficiary: true,
    condition: {
      fieldName: "project",
      paramField: "Project.Id",
      action: "useValue",
    },
  },
  {
    label: "Indicator Name",
    category: "details",
    placeholder: "Select Indicator",
    name: "smartIndicator",
    inputType: "async-dropdown",
    url: "/SmartIndicator/GetAll?DisablePagination=true",
    searchAttrib: "Search.value",
    id: {
      key: "smartIndicator",
      onCondition: { key: "isBeneficiary", value: true },
    },
    labelAttribute: "indicator",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    condition: {
      fieldName: "objectiveName",
      paramField: "Objective.Id",
      action: "useValue",
    },
  },
  {
    category: "details",
    label: "Beneficiary Name",
    placeholder: "Enter Beneficiary Name",
    name: "name",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "details",
    label: "Father/Husband Name",
    placeholder: "Enter Father/Husband Name",
    name: "fatherHusbandName",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "details",
    label: "Occupation",
    placeholder: "Enter Occupation",
    name: "occupation",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "details",
    label: "Monthly Income (PKR)",
    placeholder: "Enter Monthly Income",
    name: "monthlyIncome",
    type: "number",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "details",
    label: "PWD in Household (Person with Disability)",
    placeholder: "Enter PWD in Household",
    name: "houseHoldDisability",
    id: "houseHoldDisability",
    inputType: "async-dropdown",
    options: [
      {
        label: "Yes",
        value: true,
      },
      {
        label: "No",
        value: false,
      },
    ],
    onChangeMethod: "asyncdropdown",
    searchAttrib: "Search.value",
    labelAttribute: "label",
    valueAttribute: "value",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  {
    category: "details",
    label: "Support Provided",
    placeholder: "Select Support Provided",
    name: "supportProvided",
    id: "supportProvided",
    inputType: "async-dropdown",
    url: "/SupportProvided/GetAll",
    onChangeMethod: "asyncdropdown",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: true,
  },
  {
    category: "details",
    label: "Accomplishment Date",
    placeholder: "Enter Accomplishment Date",
    name: "accomplishmentDate",
    inputType: "date",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "details",
    label: "Gender",
    placeholder: "Select Gender",
    name: "gender",
    id: "gender",
    inputType: "async-dropdown",
    options: genderTypes,
    onChangeMethod: "asyncdropdown",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  {
    category: "details",
    label: "Age",
    placeholder: "Enter Age",
    name: "age",
    inForm: true,
    // type: "number",
    wrapperClass: "col-md-6",
  },
  {
    category: "details",
    label: "Phone",
    placeholder: "Enter Phone",
    name: "phoneNo",
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      min: 10,
      max: 11,
    },
  },
  {
    category: "details",
    label: "CNIC/ POR No.",
    placeholder: "CNIC/ POR No.",
    // type: "number",
    name: "cnic",
    pattern: "[0-9]+",
    maxlength: 13,
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      min: 13,
      max: 13,
    },
  },
  {
    category: "details",
    label: "Vulnerability",
    placeholder: "Select Vulnerability",
    name: "vulnerabilities",
    id: "vulnerabilities",
    inputType: "async-dropdown",
    url: "/Vulnerability/GetAll",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: true,
  },

  // {
  //   category: "details",
  //   label: "Sustainable Development Goals",
  //   placeholder: "Add Sustainable Development Goals",
  //   name: "sdGs",
  //   inputType: "async-dropdown",
  //   url: "/SDG/GetAll",
  //   searchAttrib: "Search.value",

  //   id: "sdGs",
  //   labelAttribute: "name",
  //   valueAttribute: "id",
  //   onChangeMethod: "dropdown",
  //   inForm: true,
  //   wrapperClass: "col-md-6",
  //   isMulti: true,
  // },
  // {
  //   label: "Thematic Area",
  //   category: "details",
  //   placeholder: "Add Thematic Area",
  //   name: "thematicAreas",
  //   inputType: "async-dropdown",
  //   url: "/ThematicArea/GetAll",
  //   searchAttrib: "Search.value",

  //   id: "thematicAreas",
  //   labelAttribute: "name",
  //   valueAttribute: "id",
  //   onChangeMethod: "dropdown",
  //   inForm: true,
  //   wrapperClass: "col-md-6",
  //   isMulti: true,
  // },
  {
    category: "details",
    label: "Upload File",
    placeholder: "Attachments",
    name: "attachments",
    onChangeMethod: "onMultiFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "multiFiles",
  },
  {
    category: "address",
    name: "address_label",
    inputType: "h4",
    children: "Residential Address",
    inForm: true,
    wrapperClass: "col-12 py-3",
  },
  {
    category: "address",
    label: "Addresses",
    inputType: "tableInput",
    inForm: true,
    name: "addresses",
    header: [
      {
        label: "Province",
        placeholder: "Enter Province",
        name: "province",
        id: "province",
        inputType: "async-dropdown",
        url: "/Province/GetAll?DisablePagination=true",
        searchAttrib: "Search.value",

        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        className: "border border-2 border-secondary rounded",
        isMulti: false,
      },
      {
        label: "District",
        placeholder: "Enter District",
        name: "district",
        id: "district",
        inputType: "async-dropdown",
        url: "/District/GetAll?DisablePagination=true",
        searchAttrib: "Search.value",

        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        className: "border border-2 border-secondary rounded",
        isMulti: false,
        condition: {
          fieldName: "province",
          paramField: "Province.Id",
          action: "useValue",
        },
      },
      {
        label: "Tehsil",
        placeholder: "Enter Tehsil",
        name: "tehsil",
        id: "tehsil",
        inputType: "async-dropdown",
        url: "/Tehsil/GetAll",
        searchAttrib: "Search.value",

        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        className: "border border-2 border-secondary rounded",
        isMulti: false,
        condition: {
          fieldName: "district",
          paramField: "District.Id",
          action: "useValue",
        },
      },
      {
        label: "UC",
        placeholder: "Enter UC",
        name: "unionCouncil",
        id: "unionCouncil",
        inputType: "async-dropdown",
        url: "/UnionCouncil/GetAll",
        searchAttrib: "Search.value",

        labelAttribute: "name",
        valueAttribute: "id",
        onChangeMethod: "dropdown-in-table",
        inForm: true,
        wrapperClass: "col-4",
        className: "border border-2 border-secondary rounded",
        isMulti: false,
        condition: {
          fieldName: "tehsil",
          paramField: "TehsilId",
          action: "useValue",
        },
      },
      {
        label: "Village",
        placeholder: "Enter Village",
        name: "village",
        inForm: true,
        wrapperClass: "col-4",
        className: "bg-white rounded",
        isMulti: false,
        onChangeMethod: "text-in-table",
      },
      {
        label: "Residential Address",
        placeholder: "Enter Address",
        name: "resAddress",
        className: "bg-white rounded",
        inForm: true,
        onChangeMethod: "text-in-table",
      },
    ],
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
  },
  {
    category: "household",
    name: "household_label",
    inputType: "h4",
    children: "Household Details",
    inForm: true,
    wrapperClass: "col-12 pt-3",
  },
  {
    category: "household",
    label: "Head of Household Name",
    placeholder: "Enter Name",
    name: "headOfHouseHoldName",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    category: "household",
    label: "Relation of Head of Household with Beneficiary",
    placeholder: "Enter Name",
    name: "relationshipWithBeneficiary",
    id: "relationshipWithBeneficiary",
    inputType: "async-dropdown",
    options: relationshipTypes,
    onChangeMethod: "asyncdropdown",
    searchAttrib: "Search.value",

    labelAttribute: "name",
    valueAttribute: "id",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  {
    category: "household",
    label: "People in Household",
    inputType: "tableInput",
    inForm: true,
    name: "peopleInHousehold",
    header: [
      {
        label: "Age Bracket",
        placeholder: "Select Age Bracket",
        name: "ageRange",
        id: "ageRange",
        inputType: "async-dropdown",
        // options: ageBrackets,
        url: "/AgeRange/GetAll",
        onChangeMethod: "dropdown-in-table",
        searchAttrib: "Search.value",

        labelAttribute: "name",
        valueAttribute: "id",
        inForm: true,
        wrapperClass: "col-md-6",
        className: "border border-2 border-secondary rounded",
        isMulti: false,
      },
      {
        label: "Number of Female",
        placeholder: "Enter Number",
        name: "noOfFemales",
        type: "number",
        onChangeMethod: "text-in-table",
        inForm: true,
        wrapperClass: "col-md-6",
        className: "rounded bg-white",
      },
      {
        label: "Number of Male",
        placeholder: "Enter Number",
        name: "noOfMales",
        type: "number",
        onChangeMethod: "text-in-table",
        inForm: true,
        wrapperClass: "col-md-6",
        className: "rounded bg-white",
      },
    ],
    className: "w-100 table-overflow",
    wrapperClass: "col-12",
  },
];
export default beneficiaryFields;
