import React, { useEffect, useState } from "react";
import "./Expandable.scss";
import * as Icon from "react-bootstrap-icons";
import Table from "../table/Table";

const Expandable = ({
  title,
  content,
  addActionPoint,
  item,
  setKeyFindingId,
  customColumn
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [actionPoint, setActionPoint] = useState([]);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setActionPoint(item?.actionPoints);
    setKeyFindingId({ id: item?.id, name: item?.keyFindings });
  }, [actionPoint]);

  const handleActionPointModal = () => {
    addActionPoint();
    setKeyFindingId({ id: item?.id, name: item?.keyFindings });
  };

  return (
    <div className="accordion">
      <div className="accordion-header">
        <button className="openCloseBtn" onClick={handleClick}>
          {isOpen ? "-" : "+"}
        </button>
        <p className="m-0 mt-1" style={{ flex: 1 }}>
          <b>{title} </b>
        </p>
        {/* <h4>{title}</h4> */}
        <div>
          <button
            className="btn btn-primary btn-sm ml-auto mb-2"
            onClick={handleActionPointModal}
          >
            {/* <Icon.Plus />  */}
            Add
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <Table columns={content} data={actionPoint} customColumnNames={customColumn} />
        </div>
      )}
    </div>
  );
};

export default Expandable;
