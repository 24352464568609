import React, { useState, useEffect } from "react";
import "./DefinitionManagement.scss";
import SearchFields from "./SearchFields";
import CrudFields from "./CrudFields";
import { useLocation } from "react-router-dom";
import usePageMeta from "../../hooks/usePageMeta";

const DefinitionManagement = () => {
  const { setMeta } = usePageMeta();
  const location = useLocation();
  const [headingName, setHeadingName] = useState("Country");

  const tabChange = (payload) => {
    setHeadingName(payload);
  };
  useEffect(() => {
    setMeta({ title: "Definitions" });
  }, []);

  return (
    <div className="cont">
      <div className="card-body shadow rounded-lg bg-white">
        <div className="two-panel-flex">
          <div className="first">
            <SearchFields setHeadingName={tabChange} match={location} />
          </div>
          <div className="second">
            <CrudFields headingName={headingName} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefinitionManagement;
