export const ReceivingModePieChartData = {
    series: [13, 32, 20, 15, 20],
    options: {
      chart: {
        type: "donut",
      },
      labels: ["Verbal", "Email", "Telephonically", "Written", "Other"],
      legend: {
        horizontalAlign: "left",
        // offsetX: 40,
        position: "bottom",
      },
      colors: ["#09909F", "#01CC9B", "#ED7D31", "#1C1D30", "#5059AB"],
      dataLabels: {
        enabled: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "left",
            },
          },
        },
      ],
  
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
    },
  };
  
  export const TypeOfComplaintPieChartData = {
    series: [9, 91],
    options: {
      chart: {
        type: "donut",
      },
      labels: [
        "Beneficiary",
        "Non-Beneficiary",
        "CIP Staff",
        "PartnerStaff",
        "Other",
      ],
      legend: {
        horizontalAlign: "left",
        // offsetX: 40,
        position: "bottom",
      },
      colors: ["#ED7D31", "#01CC9B", "#1C1D30", "#09909F", "#5059AB"],
      dataLabels: {
        enabled: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "left",
            },
          },
        },
      ],
  
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
    },
  };
  
  export const NatureOfComplaintsPieChartData = {
    series: [3, 87, 10],
    options: {
      chart: {
        type: "donut",
      },
      labels: ["Feedbacks", "Serious Complaints", "Regular Complaints"],
      legend: {
        horizontalAlign: "left",
        // offsetX: 40,
        position: "bottom",
      },
      colors: ["#01CC9B", "#09909F", "#1C1D30"],
      dataLabels: {
        enabled: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "left",
            },
          },
        },
      ],
  
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
    },
  };
  
  export const StatusOfTreatmentPieChartData = {
    series: [25, 65],
    options: {
      chart: {
        type: "donut",
      },
      labels: ["Closed", "Pending"],
      legend: {
        horizontalAlign: "left",
        // offsetX: 40,
        position: "bottom",
      },
      colors: ["#01CC9B", "#ED7D31"],
      dataLabels: {
        enabled: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "left",
            },
          },
        },
      ],
  
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
    },
  };
  
  export const ActivitiesPieChartData = {
    series: [10, 20, 40, 30],
    options: {
      chart: {
        type: "donut",
      },
      labels: [
        "Distribution",
        "Recruitment",
        "Procurement",
        "Training",
        "SEA Case",
        "Other",
        "Assessment",
        "Payment",
        "Construction",
      ],
      legend: {
        horizontalAlign: "left",
        // offsetX: 40,
        position: "right",
      },
      colors: [
        "#ED7D31",
        "#662E9B",
        "#43BCCD",
        "#F9C80E",
        "#09909F",
        "#09909F",
        "#09909F",
        "#09909F",
        "#09909F",
      ],
      dataLabels: {
        enabled: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "left",
            },
          },
        },
      ],
  
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
          customScale: 1,
        },
      },
    },
  };
  
  export const ComplaintsPieChartGenderData = {
    series: [19, 81],
    options: {
      chart: {
        type: "donut",
      },
      labels: ["Female", "Male"],
      legend: {
        horizontalAlign: "left",
        // offsetX: 40,
        position: "bottom",
      },
      colors: ["#7DE314", "#ED7D31"],
      dataLabels: {
        enabled: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  
  export const ComplaintsPieChartAgeData = {
    series: [0, 100],
    options: {
      chart: {
        type: "donut",
      },
      labels: ["Child", "Adult"],
      legend: {
        horizontalAlign: "left",
        // offsetX: 40,
        position: "bottom",
      },
      colors: ["#ED7D31", "#1C1D30"],
      dataLabels: {
        enabled: true,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };