import React, { useCallback, useEffect, useState } from "react";
import "./DashboardPage.scss";
import BarChartHorizontalApex from "../../components/apex/BarChartHorizontalApex";
import * as Icon from "react-bootstrap-icons";

const ActualVsTargetChart = ({ yearlyData }) => {
  const [series, setSeries] = useState([
    {
      name: "Target",
      data: yearlyData?.map((item) => item?.totalTarget),
    },
    {
      name: "Actual",
      data: yearlyData?.map((item) => item?.accomplishedTarget),
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      id: "target-vs-actual",
      events: {
        dataPointSelection: (event, chartContext, config) => {
          handleDataPointSelection(config);
        },
      },
    },
    xaxis: {
      categories: yearlyData?.map((item) => item?.year),
    },
    yaxis: {
      title: {
        text: "Beneficiaries",
      },
    },
    legend: {
      show: true,
      position: "top",
    },
    toolbar: {
      show: false,
    },
    colors: ["#FF8929", "#E63A00"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "90%",
      },
    },
  });

  useEffect(() => {
    setSeries([
      {
        name: "Target",
        data: yearlyData?.map((item) => item?.totalTarget),
      },
      {
        name: "Actual",
        data: yearlyData?.map((item) => item?.accomplishedTarget),
      },
    ]);

    setOptions({
      chart: {
        id: "target-vs-actual",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            handleDataPointSelection(config);
          },
        },
      },
      xaxis: {
        categories: yearlyData?.map((item) => item?.year),
      },
      yaxis: {
        title: {
          text: "Beneficiaries",
        },
      },
      legend: {
        show: true,
        position: "top",
      },
      toolbar: {
        show: false,
      },
      colors: ["#FF8929", "#E63A00"],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "90%",
        },
      },
    });
  }, [yearlyData]);

  // useEffect(() => {
  //   updateQuarters();
  // }, [yearlyData]);

  // function updateQuarters() {
  //   if (yearlyData) {
  //     setSeries([
  //       {
  //         name: "Target",
  //         data: yearlyData?.map((item) => item?.totalTarget),
  //       },
  //       {
  //         name: "Actual",
  //         data: yearlyData?.map((item) => item?.accomplishedTarget),
  //       },
  //     ]);
  //     setOptions({
  //       ...options,
  //       xaxis: {
  //         categories: yearlyData?.map((item) => item?.year),
  //       },
  //     });
  //   }
  // }

  const formateQuartersActualData = (selectedYear) => {
    return (
      yearlyData &&
      yearlyData
        ?.find((item) => item?.year === selectedYear)
        ?.quarters?.map((item) => item?.actual)
    );
  };

  const formateQuartersTargetData = (selectedYear) => {
    return (
      yearlyData &&
      yearlyData
        ?.find((item) => item?.year === selectedYear)
        ?.quarters?.map((item) => item?.target)
    );
  };

  const refreshChart = useCallback(() => {
    setSeries([
      {
        name: "Target",
        data: yearlyData?.map((item) => item?.totalTarget),
      },
      {
        name: "Actual",
        data: yearlyData?.map((item) => item?.accomplishedTarget),
      },
    ]);

    setOptions({
      chart: {
        id: "target-vs-actual",
        events: {
          dataPointSelection: (event, chartContext, config) => {
            handleDataPointSelection(config);
          },
        },
      },
      xaxis: {
        categories: yearlyData?.map((item) => item?.year),
      },
      yaxis: {
        title: {
          text: "Beneficiaries",
        },
      },
      legend: {
        show: true,
        position: "top",
      },
      toolbar: {
        show: false,
      },
      colors: ["#FF8929", "#E63A00"],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "90%",
        },
      },
    });
  }, [yearlyData]);

  // const refreshChart = () => {

  //   setTimeout(() => {
  //     setSeries([
  //       {
  //         name: "Target",
  //         data: yearlyData?.map((item) => item?.totalTarget),
  //       },
  //       {
  //         name: "Actual",
  //         data: yearlyData?.map((item) => item?.accomplishedTarget),
  //       },
  //     ]);

  //     setOptions({
  //       chart: {
  //         id: "target-vs-actual",
  //         events: {
  //           dataPointSelection: (event, chartContext, config) => {
  //             handleDataPointSelection(config);
  //           },
  //         },
  //       },
  //       xaxis: {
  //         categories: yearlyData?.map((item) => item?.year),
  //       },
  //       yaxis: {
  //         title: {
  //           text: "Beneficiaries",
  //         },
  //       },
  //       legend: {
  //         show: true,
  //         position: "top",
  //       },
  //       toolbar: {
  //         show: false,
  //       },
  //       colors: ["#FF8929", "#E63A00"],
  //       plotOptions: {
  //         bar: {
  //           horizontal: false,
  //           columnWidth: "90%",
  //         },
  //       },
  //     });
  //   }, 2000);
  // }

  const handleDataPointSelection = (config) => {
    setTimeout(() => {}, 2000);
    const selectedSeries = series[config.seriesIndex];
    const selectedYear = options.xaxis.categories[config.dataPointIndex];

    let findSelectedYearData = yearlyData?.find(
      (item) => item?.year === selectedYear
    ).year;

    // Logic to display quarter-wise data
    if (
      selectedSeries.name === "Actual" ||
      (selectedSeries.name === "Target" &&
        selectedYear === findSelectedYearData)
    ) {
      setSeries([
        {
          name: "Target",
          data: formateQuartersTargetData(selectedYear),
        },
        {
          name: "Actual",
          data: formateQuartersActualData(selectedYear),
        },
      ]);
      setOptions({
        ...options,
        toolbar: {
          show: false, // Set 'show' to false to hide the hamburger icon
        },
        xaxis: {
          categories: [
            `${selectedYear}-Q1`,
            `${selectedYear}-Q2`,
            `${selectedYear}-Q3`,
            `${selectedYear}-Q4`,
          ],
        },
        colors: ["#FF8929", "#E63A00"],
      });
    }
  };
  return (
    <div className="col-md-6">
      <div className="chart-bg-new text-center p-5">
        {/* <h4>
              <b>Actual vs Target</b>
            </h4> */}
        <div className="text-center ml-3">
          <h2
          // onClick={() =>
          //   navigate("/chart-detail", {
          //     state: { type: CHART_TYPE.Bar_CHART },
          //   })
          // }
          >
            <b>Actual Vs Target</b>
            <span
              onClick={() => refreshChart()}
              style={{ float: "right", cursor: "pointer" }}
            >
              <Icon.ArrowCounterclockwise size={22} />
            </span>
          </h2>
        </div>
        <BarChartHorizontalApex options={options} series={series} />
        {/* {actualVsTarget?.data?.items && <BarChartHorizontalApex options={options} series={series} />} */}
      </div>
    </div>
  );
};

export default ActualVsTargetChart;
