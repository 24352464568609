export const UploadImageFields = [
  {
    label: "Upload File",
    placeholder: "Attachments",
    name: "model",
    onChangeMethod: "onMultiFileChange",
    inForm: true,
    wrapperClass: "col-md-12",
    inputType: "image",
  },
  {
    type: "hidden",
    inForm: true,
    wrapperClass: "col-12",
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "btn-wrapper ml-auto mt-2",
  },
];
