import GENDER from "../enums/GENDER.js";

const genderTypes = [
  {
    label: GENDER.MALE,
    value: GENDER.MALE,
  },
  {
    label: GENDER.FEMALE,
    value: GENDER.FEMALE,
  },
];
export default genderTypes;
