export const FacilityFields = [
  {
    label: "Project Title",
    placeholder: "Select Project",
    name: "project",
    inputType: "async-dropdown",
    url: "/Project/GetAll?DisablePagination=true",
    searchAttrib: "Search.value",
    id: "project",
    labelAttribute: "title",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    label: "Objective Name",
    placeholder: "Select Objective",
    name: "objectiveName",
    inputType: "async-dropdown",
    url: "/Objective/GetAll?DisablePagination=true",
    searchAttrib: "Search.value",
    id: "objectiveName",
    labelAttribute: "objectiveTitle",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    condition: {
      fieldName: "project",
      paramField: "Project.Id",
      action: "useValue",
    },
  },
  {
    label: "Indicator Name",
    placeholder: "Select Indicator",
    name: "smartIndicator",
    inputType: "async-dropdown",
    url: "/SmartIndicator/GetAll?DisablePagination=true",
    searchAttrib: "Search.value",
    id: {
      key: "smartIndicator",
      onCondition: {
        key: "isBeneficiary",
        value: false,
      },
    },
    labelAttribute: "indicator",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    condition: {
      fieldName: "objectiveName",
      paramField: "Objective.Id",
      action: "useValue",
    },
  },
  {
    label: "Facility Name",
    placeholder: "Enter Facility Name",
    name: "targetName",
    inForm: true,
    wrapperClass: "col-md-6",
  },

  {
    label: "Target Theme",
    placeholder: "Select Target Theme",
    name: "thematicArea",
    inputType: "async-dropdown",
    url: "/ThematicArea/GetAll",
    searchAttrib: "Search.value",
    id: "thematicArea",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    label: "Accomplishment Date",
    placeholder: "Enter Accomplishment Date",
    name: "accomplishmentDate",
    inputType: "date",
    inForm: true,
    wrapperClass: "col-md-6",
  },
  {
    label: "Remarks",
    placeholder: "Enter Facility Remarks",
    inputType: "textarea",
    inForm: true,
    name: "remarks",
    wrapperClass: "col-md-6",
  },
  {
    label: "Upload File",
    placeholder: "Attachments",
    name: "attachments",
    onChangeMethod: "onMultiFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "image",
  },
  {
    name: "address_label",
    inputType: "h4",
    children: "Facility Address",
    inForm: true,
    wrapperClass: "col-12 py-3",
  },
  // {
  //   label: "Addresses",
  //   inputType: "tableInput",
  //   inForm: true,
  //   name: "address",
  //   header: [
  //     {
  //       label: "Province",
  //       placeholder: "Enter Province",
  //       name: "province",
  //       id: "province",
  //       inputType: "async-dropdown",
  //       url: "/Province/GetAll?DisablePagination=true",
  //       searchAttrib: "Search.value",
  //       labelAttribute: "name",
  //       valueAttribute: "id",
  //       onChangeMethod: "dropdown-in-table",
  //       inForm: true,
  //       wrapperClass: "col-4",
  //       className: "border border-2 border-secondary rounded",
  //       isMulti: false,
  //     },
  //     {
  //       label: "District",
  //       placeholder: "Enter District",
  //       name: "district",
  //       id: "district",
  //       inputType: "async-dropdown",
  //       url: "/District/GetAll?DisablePagination=true",
  //       searchAttrib: "Search.value",
  //       labelAttribute: "name",
  //       valueAttribute: "id",
  //       onChangeMethod: "dropdown-in-table",
  //       inForm: true,
  //       wrapperClass: "col-4",
  //       className: "border border-2 border-secondary rounded",
  //       isMulti: false,
  //       condition: {
  //         fieldName: "province",
  //         paramField: "ProvinceId",
  //         action: "useValue",
  //       },
  //     },
  //     {
  //       label: "Tehsil",
  //       placeholder: "Enter Tehsil",
  //       name: "tehsil",
  //       id: "tehsil",
  //       inputType: "async-dropdown",
  //       url: "/Tehsil/GetAll?DisablePagination=true",
  //       searchAttrib: "Search.value",
  //       labelAttribute: "name",
  //       valueAttribute: "id",
  //       onChangeMethod: "dropdown-in-table",
  //       inForm: true,
  //       wrapperClass: "col-4",
  //       className: "border border-2 border-secondary rounded",
  //       isMulti: false,
  //       condition: {
  //         fieldName: "district",
  //         paramField: "DistrictId",
  //         action: "useValue",
  //       },
  //     },
  //     {
  //       label: "UC",
  //       placeholder: "Enter UC",
  //       name: "unionCouncil",
  //       id: "unionCouncil",
  //       inputType: "async-dropdown",
  //       url: "/UnionCouncil/GetAll?DisablePagination=true",
  //       searchAttrib: "Search.value",
  //       labelAttribute: "name",
  //       valueAttribute: "id",
  //       onChangeMethod: "dropdown-in-table",
  //       inForm: true,
  //       wrapperClass: "col-4",
  //       className: "border border-2 border-secondary rounded",
  //       isMulti: false,
  //       condition: {
  //         fieldName: "tehsil",
  //         paramField: "TehsilId",
  //         action: "useValue",
  //       },
  //     },
  //     {
  //       label: "Village",
  //       placeholder: "Enter Village",
  //       name: "village",
  //       inForm: true,
  //       wrapperClass: "col-4",
  //       className: "bg-white rounded",
  //       isMulti: false,
  //       onChangeMethod: "text-in-table",
  //     },
  //     {
  //       label: "Residential Address",
  //       placeholder: "Enter Address",
  //       name: "resAddress",
  //       className: "bg-white rounded",
  //       inForm: true,
  //       onChangeMethod: "text-in-table",
  //     },
  //   ],
  //   className: "w-100 table-overflow",
  //   wrapperClass: "col-12",
  // },
  {
    label: "Province",
    placeholder: "Enter Province",
    name: "province",
    id: "province",
    inputType: "async-dropdown",
    url: "/Province/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-3",
    isMulti: false,
  },
  {
    label: "District",
    placeholder: "Enter District",
    name: "district",
    id: "district",
    inputType: "async-dropdown",
    url: "/District/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-3",
    isMulti: false,
    condition: {
      fieldName: "province",
      paramField: "ProvinceId",
      action: "useValue",
    },
  },
  {
    label: "Tehsil",
    placeholder: "Enter Tehsil",
    name: "tehsil",
    id: "tehsil",
    inputType: "async-dropdown",
    url: "/Tehsil/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-3",
    isMulti: false,
    condition: {
      fieldName: "district",
      paramField: "DistrictId",
      action: "useValue",
    },
  },
  {
    label: "UC",
    placeholder: "Enter UC",
    name: "unionCouncil",
    id: "unionCouncil",
    inputType: "async-dropdown",
    url: "/UnionCouncil/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-3",
    isMulti: false,
    condition: {
      fieldName: "tehsil",
      paramField: "TehsilId",
      action: "useValue",
    },
  },
  {
    label: "Village",
    placeholder: "Enter Village",
    name: "village",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    onChangeMethod: "text-in-table",
  },
  {
    label: "Residential Address",
    placeholder: "Enter Address",
    name: "resAddress",
    wrapperClass: "col-md-4",
    inForm: true,
    onChangeMethod: "text-in-table",
  },
  {
    type: "hidden",
    inForm: true,
    wrapperClass: "col-12",
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "btn-wrapper ml-auto mt-2",
  },
];
