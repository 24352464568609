import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";

import { useEffect } from "react";
import Loader from "../../components/loader/loader";
import { debriefFields } from "../../assets/fields/debriefFields";
import "../projects/Projects.scss";
import Form from "../../components/form/Form";
import {
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
} from "../../redux/reducers";
import usePageMeta from "../../hooks/usePageMeta";
import Table from "../../components/table/Table";
import DebriefColumns from "../../assets/columns/DeBriefColumns";
import { ORDER_DIRECTION } from "../../Constants";
import { getFormatedDate } from "../../Utility";
import { baseUrl } from "../../redux";
import ToolTip from "../../components/tooltip/Tooltip";
import DeleteRow from "../DeleteRow";

const MonitoringVisitReport = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [debriefId, setDebriefId] = useState(null);
  const { id } = useParams();
  const { setMeta } = usePageMeta();
  const { loading, data, current, created } = useSelector(
    (state) => state.records
  );
  const items = current?.monitoringDebrief?.data?.items;
  const dispatch = useDispatch();
  const debriefData = current?.debriefById?.data;

  const keyActivitiesColumns = [
    {
      name: "s.No.",
      selector: (row) => row?.id,
    },
    {
      name: "Activity Name",
      selector: (row) => row?.activity?.name,
    },
    {
      name: "Planned Target",
      selector: (row) => row?.plannedTarget,
    },
    {
      name: "Achevied",
      selector: (row) => row?.achievedTarget,
    },
    {
      name: "Remarks",
      selector: (row) => row?.remarks,
    },
  ];

  useEffect(() => {
    dispatch({
      type: getRecords.type,
      payload: { url: `/MonitoringVisitReport/${id}` },
    });

    setTimeout(() => {
      getAllDebriefs();
    }, 200);
  }, [id]);

  useEffect(() => {
    if (created) {
      setIsOpen(false);
      getAllDebriefs();
    }
  }, [created]);

  useEffect(() => {
    setMeta({ title: data?.project?.name });
  }, [data?.project]);

  function getAllDebriefs() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/MonitoringDeBrief/GetAll`,
        params: {
          MonitoringReportId: id,
          OrderDir: ORDER_DIRECTION.DESC,
          DisablePagination: false,
        },
        appendCurrentReducerKey: "monitoringDebrief",
      },
    });
  }

  function postData(params) {
    debriefId
      ? dispatch({
          type: putRecord.type,
          payload: {
            url: `/MonitoringDeBrief`,
            ...params,
          },
        })
      : dispatch({
          type: createRecord.type,
          payload: {
            url: "/MonitoringDeBrief",
            ...params,
            monitoringVisitReport: { id },
          },
        });
  }

  function toggleActivityModal() {
    setIsOpen(!modalIsOpen);
  }

  const formatedFields = (fields) => {
    return fields.map((field) => {
      if (field?.url)
        return { ...field, url: `${field.url}?MonitoringReportId=${id}` };
      return { ...field };
    });
  };

  const debriefActionCol = [
    ...DebriefColumns,

    {
      name: "Action",
      selector: (row) => (
        <>
        <ToolTip label="Details">
            <Link to={`action-point`}>
            {/* <Link to={`action-point/${row?.id}`}> */}
            {/* <Link to={`${row?.id}`}> */}
              <Icon.Eye className="text-primary mr-2" />
            </Link>
          </ToolTip>
          <ToolTip label="Edit">
            <Icon.PencilSquare
              className="action-icons"
              onClick={() => toggleDebriefModel(row?.id)}
            />
          </ToolTip>
          <DeleteRow row={row} url="/MonitoringDeBrief" />
        </>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...debriefData } });
    }
  }, [debriefData]);

  const toggleDebriefModel = (id) => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/MonitoringDeBrief/${id}`,
        appendCurrentReducerKey: "debriefById",
      },
    });
    setDebriefId(id);
    setIsOpen(true);
  };
  return (
    <div className="mt-4">
      <Loader loading={loading} />
      <div className="d-flex justify-content-between">
        <h5 className="mb-4">Monitoring Visit Report</h5>
        <div className="button">
          <button
            type="button"
            className="btn add-filter-btns "
            onClick={() => setIsOpen(!modalIsOpen)}
          >Add Debrief
          </button>
        </div>
      </div>
      <hr className="m-0" />
      {/* <h5 className="text-primary mt-2">Details</h5>
      <div className="py-2 row">
        <div className="col-md-6">
          <p>
            <b>Project:</b> {data?.project?.name}
          </p>
          <p>
            <b>Start Date:</b> {getFormatedDate(data?.projectStartDate)}
          </p>
          <p>
            <b>End Date:</b> {getFormatedDate(data?.projectEndDate)}
          </p>
          <p>
            <b>Reporting Person Name:</b> {data?.reportingPerson?.name}
          </p>
          <p>
            <b>Date of Monitoring Visit:</b>
            {getFormatedDate(data?.dateOfMonitoring)}
          </p>
          <p>
            <b>Location of Visit:</b> {data?.locationOfVisit?.name}
          </p>
          <p>
            <b>Visit Objectives:</b> <br />
            {data?.visitObjectives}
          </p>
          <p>
            <b>Methodology adopted:</b> <br /> {data?.methodologyAdopted}
          </p>
          <p>
            <b>Project Result/Output:</b> <br /> {data?.projectResult}
          </p>
        </div>
        <div className="col-md-6">
          <p>
            <b>Methodology adopted:</b> <br /> {data?.methodologyAdopted}
          </p>
          <p>
            <b>Project Result/Output:</b> <br /> {data?.projectResult}
          </p>
          <p>
            <b>Key Observations:</b> <br /> {data?.keyObservation}
          </p>
          <p>
            <b>Best Practices in Ongoing Activities:</b> <br />{" "}
            {data?.bestPractices}
          </p>
          <p>
            <b>Key Challenges/Risk:</b> <br /> {data?.keyChallenges}
          </p>
          <p>
            <b>Recommendations/ Suggestions:</b> <br /> {data?.recommendations}
          </p>
          <p>
            <b>Attachments:</b> <br />{" "}
            <div className="d-flex flex-wrap">
              {data.attachments &&
                data.attachments.map((img, index) => (
                  <div key={index} className="mr-2">
                    {img?.url && (
                      <a target="_blank" href={baseUrl + img?.url}>
                        <img
                          style={{
                            height: "80px",
                            width: "80px",
                            objectFit: "cover",
                          }}
                          src={baseUrl + img?.url}
                          alt="image"
                        />
                      </a>
                    )}
                  </div>
                ))}
            </div>
          </p>
        </div>
      </div> */}

      {/* <hr />
      <h5 className="text-primary">Key Activities</h5>
      <div className="my-3">
        <Table columns={keyActivitiesColumns} data={data?.keyActivities} />
      </div> */}

      {/* <hr /> */}
      <div>
        {/* <div className="d-flex justify-content-between">
          <h5 className="text-primary">DeBrief</h5>
          <div className="button">
            <ToolTip label="Add Debrief">
              <Icon.PlusCircle
                className="text-primary mr-2 cursor-pointer"
                onClick={() => setIsOpen(!modalIsOpen)}
              />
            </ToolTip>
          </div>
        </div> */}

        <Loader loading={loading} />
        <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
        <Table columns={debriefActionCol} data={items ? items : []} />
        </div>
      </div>

      {/* Modal */}
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Indicator"
      >
        <Icon.XCircle onClick={toggleActivityModal} className="action-icons" />
        <h4>Add Debrief</h4>
        <Form
          formData={formatedFields(debriefFields)}
          onSubmit={(params) => postData(params)}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""  
        />
      </Modal>
    </div>
  );
};

export default MonitoringVisitReport;
