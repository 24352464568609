export const debriefFields = [
  {
    label: "Key Challenge",
    placeholder: "Enter key challenge",
    name: "keyChallenge",
    inForm: true,
  },
  {
    label: "De-brief Memo",
    placeholder: "Enter memo",
    name: "deBriefMemo",
    inForm: true,
  },
  {
    label: "Date of Debrief",
    placeholder: "Enter DOD",
    name: "dateOfDeBrief",
    required: true,
    inputType: "date",
    inForm: true,
  },
  {
    label: "Response of BM/PM",
    placeholder: "Enter response",
    name: "response",
    inForm: true,
  },
  {
    label: "Action Point",
    placeholder: "Enter action point",
    name: "actionPoint",
    inForm: true,
  },
  {
    label: "Timeline",
    placeholder: "Enter timeline",
    name: "timeline",
    inForm: true,
  },
  {
    label: "Parent Debrief",
    placeholder: "Select Parent Debrief",
    name: "parentDebrief",
    id: "parentDebrief",
    inputType: "async-dropdown",
    url: "/MonitoringDeBrief/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "deBriefMemo",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-6",
    isMulti: false,
  },
  {
    name: "divider",
    inForm: true,
    wrapperClass: "col-12",
    inputType: "p",
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "mr-3 mt-2 btn-wrapper position-right",
  },
];
