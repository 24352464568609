export const ProjectFacilitiesColumn = [
     // {
    //   name: "Monitoring Visit Report",
    //   selector: (row) => row?.monitoringVisitReport?.name,
    // },
    {
        name: "Name",
        selector: (row) => row?.targetName,
      },
      // {
      //   name: "Parent DeBrief",
      //   selector: (row) => row?.parentDebrief?.deBriefMemo,
      // },
      {
        name: "Thematic Area",
        selector: (row) => row?.thematicArea?.name,
      },
      {
        name: "Location",
        selector: (row) => row?.locations && row?.locations.map(({name}) => name)
      },
      {
        name: "Address",
        selector: (row) => row?.status,
      },
];

export const ProjectFacilitiesCustomColumnForExcel = {
    "Name": 'targetName',
    "Thematic Area": 'thematicArea',
    "Location": 'locations',
    "Address": 'status',
}
