import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Table from "../../components/table/Table";
import Loader from "../../components/loader/loader";
import MealColumns, { MealCustomColumnForExcel } from "../../assets/columns/MealsColumns";
import DeleteRow from "../DeleteRow";
import { getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";
import ToolTip from "../../components/tooltip/Tooltip";
import usePageMeta from "../../hooks/usePageMeta";
import { getFormatedDate } from "../../Utility";
import { formateDateTime } from "../../utils/util";
import "../projects/Projects.scss";
import jsPDF from "jspdf";
import Modal from "react-modal";
import Searchbar from "../../serachbar/Searchbar";

const MealProgressReports = () => {
  const [state, setState] = useState({
    modalIsOpen: false,
    achieveModalIsOpen: false,
    selectedIndicator: null
  });
  function toggleModal(indicator = null) {
    setState({
      ...state,
      modalIsOpen: !state.modalIsOpen,
      selectedIndicator: indicator
    });
  }

  const { setMeta } = usePageMeta();
  const columns = [
    ...MealColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Details">
            <Link to={`${row?.id}`}>
              <Icon.Eye className="text-primary mr-2" />
            </Link>
          </ToolTip>
          <ToolTip label="Edit">
            <Link to={`update/${row?.id}`}>
              <Icon.PencilSquare className="action-icons" />
            </Link>
          </ToolTip>
          <DeleteRow row={row} url="/MealProject" />
          <ToolTip label="Download Monthly Meal Report">
            <Icon.Download
              className="cursor-pointer"
              onClick={() =>
                fileDownload(row?.mealOfficer?.id, row?.startDate)
              }
            />
          </ToolTip>
        </>
      )
    }
  ];

  const fileDownload = (id, startDate) => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: "/MealProject/GetMealProgressReportByMonth",
        appendCurrentReducerKey: "mealProgressReportData",
        params: { MealOfficerId: id, Date: formateDateTime(startDate) },
      },
    });
    toggleModal();
  };

  const dispatch = useDispatch();
  const { loading, data, created } = useSelector((state) => state.records);
  const fileData = useSelector(
    (state) => state.records?.current?.mealProgressReportData?.data?.items
  );

  const { items = [] } = data;

  const downloadPDF = () => {
    var doc = new jsPDF("p", "pt", "a4");
    doc.html(document.querySelector("#pdf"), {
      callback: function (pdf) {
        pdf.save("Monthly Meal Reports.pdf");
      },
    });
  };

  useEffect(() => {
    dispatch({
      type: "records/getRecords",
      payload: {
        url: "/MealProject/GetAll",
        params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
      },
    });
  }, [created]);

  useEffect(() => {
    setMeta({ title: "Meal Unit Progress" });
  }, []);

  const generatePDF = () => {
    return (
      <div id="pdf">
        {fileData &&
          fileData.map((obj, ind) => {
            return (
              <div className="row p-3" key={ind}>
                <div className="col-12">
                  <p className="fw-bold">
                    Meal Progress Report{" "}
                    {`(${getFormatedDate(obj?.startDate)})`}
                  </p>
                </div>
                {Object.entries(obj).map(([key, values], index) => {
                  return (
                    <>
                      <div
                        className="col-6 text-capitalize small border border-1"
                        style={{ fontSize: "10px" }}
                      >
                        {key}
                      </div>
                      <div
                        className="col-6 text-capitalize small border border-1"
                        style={{ fontSize: "10px" }}
                      >
                        {typeof values == "object" ? values?.name : values}
                      </div>
                    </>
                  );
                })}
              </div>
            );
          })}
      </div>
    );
  };
  return (
    <>
      <Searchbar
        className="mb-3"
        searchListParams={{
          url: "/MealProject/GetAll",
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
          searchFieldName: "search.value",
        }}
        listHeaderParams={{
          options: [{ id: 1, name: "PlusCircle", url: "/meal-progress-report/add" }],
        }}
      />

      {/* Table */}
      <div id="content" className="projectTable">
        <Loader loading={loading} />
        <Table columns={columns} customColumnNames={MealCustomColumnForExcel} data={items && items} />
      </div>

      {/* Modal  */}
      <Modal
        isOpen={state.modalIsOpen}
        onRequestClose={() => toggleModal()}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Meal Progress Report Data"
      >
        {/* Modal Header */}
        <button
          type="button"
          onClick={() => downloadPDF()}
          className="btn btn-danger btn-sm"
        >
          Download PDF
        </button>

        <Icon.XCircle
          onClick={() => toggleModal()}
          className="action-icons mb-2"
        />
        <Loader loading={loading} />
        <div className="w-75 pt-3">
          {Array.isArray(fileData) && fileData.length !== 0 ? generatePDF() : <p className="text-danger">No Meal Record Available</p>}
        </div>
      </Modal>
    </>
  );
};

export default MealProgressReports;
