import React, { useState, useEffect, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import Form from "../../components/form/Form";
import "./Beneficiaries.scss";
import Table from "../../components/table/Table";
import BeneficiaryColumns, { BeneficiaryCustomColumnForExcel } from "../../assets/columns/BeneficiaryColumns";
import Loader from "../../components/loader/loader";
import { clearForm, createRecord, resetData } from "../../redux/reducers";
import DeleteRow from "../DeleteRow";
import { ORDER_DIRECTION } from "../../Constants";
import SearchList from "../../components/search-list/SearchList";
import ListHeader from "../../components/list-header/ListHeader";
import ToolTip from "../../components/tooltip/Tooltip";
import usePageMeta from "../../hooks/usePageMeta";
import FilterAccordian from "../../components/accordian/FilterAccordian";
import { BeneficiaryFilterFields } from "../../assets/fields/BeneficiaryFilterFields";
import uploadSmartIndicatorExcelFields from "../../assets/fields/uploadSmartIndicatorExcelFields";
import { canView } from "../../utils/util";

const Beneficiaries = () => {
  const { id } = useParams();
  const { loading, data, created } = useSelector((state) => state.records);
  const { items = [] } = data;

  const dispatch = useDispatch();
  const [projectBeneficiaries, setProjectBeneficiaries] = useState([]);
  const { setMeta } = usePageMeta();

  const { roles } = useSelector((state) => state.setting?.user?.userDetail);
  const userRoles = roles && roles.map(({ name }) => name);

  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getBeneficiaries();
  }, [created]);

  useEffect(() => {
    setMeta({ title: "Target Beneficiaries" });
  }, []);

  const getBeneficiaries = () => {
    const beneficiariesListUrl = id
      ? `/Beneficiary/GetAll?Project.Id=${id}`
      : "/Beneficiary/GetAll";
    dispatch({
      type: "records/getRecords",
      payload: {
        url: beneficiariesListUrl,
        params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
      },
    });
  };

  const columns = [
    ...BeneficiaryColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Details">
            <Link to={`beneficiaryView/${row?.id}`}>
              <Icon.Eye className="text-primary mr-2" />
            </Link>
          </ToolTip>
          <ToolTip label="Edit">
            <Link to={`update/${row?.id}`}>
              <Icon.PencilSquare className="action-icons" />
            </Link>
          </ToolTip>
          {
            canView(["Admin"], userRoles) &&
            <DeleteRow row={row} url="/Beneficiary" />
          }
        </>
      ),
    },
  ];

  const handleRowSelected = useCallback(({ selectedRows }) => {
    setProjectBeneficiaries(selectedRows);
  }, []);

  const onUpdateProjectBeneficiaries = () => {
    const beneficiaryIds = projectBeneficiaries.map(({ id }) => id);
    dispatch({
      type: createRecord.type,
      payload: {
        beneficiaryIds: { value: beneficiaryIds },
        projectId: { value: id },
        url: "/ProjectBeneficiary",
      },
    });
  };

  let toggleActivityModal = useCallback(() => {
    setIsOpen(!modalIsOpen);
    dispatch({ type: clearForm.type });
    dispatch({ type: resetData.type })
    getBeneficiaries()
  })

  function postData(params) {

    params = {
      smartIndicatorId: {
        name: 'SmartIndicatorId',
        value: params?.smartIndicator?.value?.value
      },
      attachment: {
        name: 'attachment',
        value: params?.attachments?.fileObject
      }
    }



    dispatch({
      type: createRecord.type,
      payload: {
        ...params,
        url: "/Beneficiary/ImportBeneficiaries",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
    setIsOpen(!modalIsOpen);
    getBeneficiaries()

  }

  const [searchFilterIsOpen, setSearchFilterIsOpen] = useState(false);

  return (
    <div className="beneficiaries">
      <div className="d-md-flex py-3  justify-content-between align-items-center border-bottom ">
        {/* <h5>Beneficiaries</h5> */}
        <SearchList
          url="/Beneficiary/GetAll"
          params={{ DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC }}
          searchFieldName="Name"
          placeholder="Search by name"
        />
        <div className="d-flex mt-2">
          <div className="">
            {/* <ToolTip label="Filter">
              <Icon.Funnel
                className="text-primary cursor-pointer mr-2"
                onClick={() => setSearchFilterIsOpen(!searchFilterIsOpen)}
              />
            </ToolTip> */}
            <div className="add-filter-btns d-flex align-items-center" onClick={toggleActivityModal}>
              <span>
                <Icon.FileEarmarkSpreadsheetFill />
              </span>
              <span className="ml-1">
                Upload
              </span>
            </div>
          </div>
          <div className="">
            {/* <ToolTip label="Filter">
              <Icon.Funnel
                className="text-primary cursor-pointer mr-2"
                onClick={() => setSearchFilterIsOpen(!searchFilterIsOpen)}
              />
            </ToolTip> */}
            <div className="add-filter-btns d-flex align-items-center" onClick={() => setSearchFilterIsOpen(!searchFilterIsOpen)}>
              <span>
                <Icon.Funnel />
              </span>
              <span className="ml-1">
                Filter
              </span>
            </div>
          </div>
          {!id ? (
            <div className="text-right">
              <ListHeader
                options={[
                  { id: 1, name: "PlusCircle", url: "/beneficiaries/add" },
                ]}
              ></ListHeader>
            </div>
          ) : (
            <div className="">
              {/* <ToolTip label="Filter">
              <Icon.Funnel
                className="text-primary cursor-pointer mr-2"
                onClick={() => setSearchFilterIsOpen(!searchFilterIsOpen)}
              />
            </ToolTip> */}
              <div className="add-filter-btns d-flex align-items-center" onClick={onUpdateProjectBeneficiaries}>
                <span className="ml-1">
                  Update Project Beneficiaries
                </span>
              </div>
            </div>
          )}
        </div>
      </div>


      <FilterAccordian
        formFields={BeneficiaryFilterFields}
        url={"/Beneficiary/GetAll"}
        toggleFilter={searchFilterIsOpen}
      />

      <div className="beneficiaryTable shadow ">
        <Loader loading={loading} />
        <Table
          columns={id ? BeneficiaryColumns : columns}
          customColumnNames={BeneficiaryCustomColumnForExcel}
          data={items && items}
          selectableRows={id ? true : false}
          onSelectedRowsChange={handleRowSelected}
          selectableRowSelected={rowSelectCritera}
        />
      </div>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Indicator"
      >
        <Icon.XCircle onClick={toggleActivityModal} className="action-icons" />
        <h4>Upload Excel Sheet</h4>
        <Form
          formData={uploadSmartIndicatorExcelFields}
          onSubmit={(params) => postData(params)}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </Modal>
    </div>
  );
};

const rowSelectCritera = (row) => {
  return row.isAdded === true;
};

export default Beneficiaries;
