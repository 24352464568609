import { Link } from "react-router-dom";
import TableTitle from "../../components/table-title/TableTitle";
import { getFormatedDate } from "../../Utility";

const MonitoringVisitColumns = [
  {
    name: "Project Title",
    selector: (row) => (
      <>
        <Link to={`visits/${row?.id}`}>
          <TableTitle title={row?.title} limit={100} />
        </Link>
      </>
    ),
    wrap: true,
    minWidth: "300px",
  },

  {
    name: "Project Manager",
    selector: (row) => row?.projectManagers?.map((item) => item?.name),
  },
  {
    name: "Implementing Partner",
    selector: (row) => row?.implementingPartners?.map((item) => item?.name)
  },

  // {
  //   name: "Location",
  //   selector: (row) => row?.locations.map((item) =>
  //     <span className="badge badge-pill bg-secondary text-white py-1 mx-1">
  //       {item?.name}
  //     </span>
  //   ),
  // },
];

export default MonitoringVisitColumns;

export const MonitoringVisitColumnsForExcel = {
  "Project Title": 'title',
  "Project Manager": "projectManagers",
  "Implementing Partner": "implementingPartners",
}
