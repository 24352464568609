import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loader from "../../components/loader/loader";
import beneficiaryFields from "../../assets/fields/beneficiariesFields";
import Form from "../../components/form/Form";
import { FormStepper, Step } from "../../components/form-stepper/FormStepper";
import { getFieldsByCategory } from "../../utils/util";
import {
  clearForm,
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
  resetData,
} from "../../redux/reducers";

const AddBeneficiaries = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const navigate = useNavigate();
  const { loading, created, data } = useSelector((state) => state.records);
  const { values, validations } = useSelector((state) => state.form);
  const formValues = values;

  let canUpdate = false;
  if (id) canUpdate = true;

  useEffect(() => {
    if (created && !canUpdate) {
      navigate("/beneficiaries");
    }
  }, [created]);

  //update project actions
  useEffect(() => {
    if (id)
      dispatch({
        type: getRecords.type,
        payload: { url: `/Beneficiary/${id}` },
      });
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [id]);

  //mask data on single project data retrieval
  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...data } });
    }
  }, [data]);

  const addRecord = () => {
    dispatch({
      type: createRecord.type,
      payload: {
        ...formValues,
        url: "/Beneficiary",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
  };

  const transformArray = formValues?.attachments?.value.map((item, index) => {
    return {
      smartIndicator: {
        id: formValues?.smartIndicator?.value?.value,
        name: formValues?.smartIndicator?.value?.label,
      },
      file: item,
    };
  });

  let updatedFormValues = {
    ...formValues,
    attachments: {
      name: "attachments",
      value: {
        id: "attachments",
        value: transformArray,
      },
    },
  };

  function updateRecord() {
    dispatch({
      type: putRecord.type,
      payload: {
        ...updatedFormValues,
        url: "/Beneficiary",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
  }

  return (
    <div className="mb-4">
      {/* <Loader loading={loading} /> */}
      <FormStepper
        initialStep={0}
        onSubmit={() => {
          canUpdate ? updateRecord() : addRecord();
          // handleShow();
        }}
      >
        <Step key="beneficiaryDetail" title="Beneficiary Details">
          <div className="col-sm-12 bg-white shadow mb-2 px-5 py-4 rounded-lg">
            <Form
              formData={getFieldsByCategory(beneficiaryFields, "details")}
              withValidation={true}
              formValidation={validations}
              extraInputClass=""
            />
          </div>
        </Step>
        <Step key="project-address" title="Project and Address">
          <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded-lg">
            <Form
              formData={getFieldsByCategory(beneficiaryFields, "address")}
              withValidation={true}
              formValidation={validations}
              extraInputClass=""
            />
          </div>
          <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
            <Form
              formData={getFieldsByCategory(beneficiaryFields, "household")}
              withValidation={true}
              formValidation={validations}
              extraInputClass=""
            />
          </div>
        </Step>
      </FormStepper>
    </div>
  );
};

export default AddBeneficiaries;
