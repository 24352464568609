import SmartIndicatorLabels from "../../components/smart-indicator/SmartIndicatorLabels";
import ColumnInnerList from "../../components/Column/ColumnInnerList";
import { Link } from "react-router-dom";
import "../../pages/logframe/Logframe.scss";

const LogframeColumns = [
  {
    name: "Indicator",
    wrap: true,
    selector: (row) => row?.indicator,
    wrap: true,
    minWidth: "300px",
  },

  {
    name: "Baseline Value",
    wrap: true,
    selector: (row) => row?.baselineValue,
  },
  {
    name: "Frequency",
    wrap: true,
    selector: (row) => row?.periodicity?.name,
  },
  {
    name: "Thematic Area",
    wrap: true,
    selector: (row) => row?.thematicArea?.name,
  },

  {
    name: "Responsible",
    wrap: true,
    selector: (row) => row?.responsiblePerson?.name,
  },
];

export default LogframeColumns;

export const LogframeColumnsForExcel = {
  "Indicator": 'indicator',
  "Baseline Value": 'baselineValue',
  "Frequency": 'periodicity',
  "Thematic Area": 'thematicArea',
  "Responsible": 'responsiblePerson',
}

const TargetsComp = () => {
  return (
    <div className="flex">
      <div className="logFrameTarget log-orange"></div>
      <div className="logFrameTarget log-black"></div>
      <div className="logFrameTarget log-gray"></div>
    </div>
  );
};
