import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import usePageMeta from "../../hooks/usePageMeta";
import { getRecords } from "../../redux/reducers";
import { getFormatedDate } from "../../Utility";

const FacilityView = () => {
  const { setMeta } = usePageMeta();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data, created, current } = useSelector(
    (state) => state.records
  );

  useEffect(() => {
    setMeta({ title: "Facility View" });
  }, []);

  useEffect(() => {
    getFacility();
  }, []);

  const getFacility = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Facility/${id}`,
        CurrentReducerKey: "facilityView",
      },
    });
  };

  return (
    <>
      <h3 className="pt-1 mb-3 text-center">Facility View</h3>
      <div className="container my-3 bg-white pt-3 pb-3 mt-5 shadow rounded p-5 border border-warning">
        <div className="row">
          <div className="col-lg-6">
            <h4
              style={{ backgroundColor: "#E4701e" }}
              className="pt-1 mb-3 text-center text-white px-2 rounded py-2"
            >
              Facility Details
            </h4>
            <div className="row px-2">
              <div className="col-sm-3 col-6 mt-2">
                <h6>Facility Name</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">{data?.targetName}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Location</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">{data?.location?.name}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Accomplishment Date</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">
                {getFormatedDate(data?.accomplishmentDate)}
              </div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Thematic Area</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">
                {data?.thematicArea?.name}
              </div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Indicator Name</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">
                {data?.smartIndicator?.name}
              </div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Remarks</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">{data?.remarks}</div>
            </div>
          </div>

          <div className="col-lg-6">
            <h4
              style={{ backgroundColor: "#E4701e" }}
              className="pt-1 mb-3 text-center text-white px-2 rounded py-2"
            >
              Residential Info
            </h4>
            <div className="row px-2">
              <div className="col-sm-4 col-6 mt-2">
                <h6>Province</h6>
              </div>
              <div className="col-md-8 col-6 mt-2">
                {data?.address?.province?.name}
              </div>
              <div className="col-sm-4 col-6 mt-2">
                <h6>District</h6>
              </div>
              <div className="col-md-8 col-6 mt-2">
                {data?.address?.district?.name}
              </div>
              <div className="col-sm-4 col-6 mt-2">
                <h6>Tehsil</h6>
              </div>
              <div className="col-md-8 col-6 mt-2">
                {data?.address?.tehsil?.name}
              </div>
              <div className="col-sm-4 col-6 mt-2">
                <h6>Union Council</h6>
              </div>
              <div className="col-md-8 col-6 mt-2">
                {data?.address?.unionCouncil?.name}
              </div>
              <div className="col-sm-4 col-6 mt-2">
                <h6>Village</h6>
              </div>
              <div className="col-md-8 col-6 mt-2">
                {data?.address?.village}
              </div>
              <div className="col-sm-4 col-6 mt-2">
                <h6>Residential Address</h6>
              </div>
              <div className="col-md-8 col-6 mt-2">
                {data?.address?.resAddress}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilityView;
