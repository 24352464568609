export const ActionPointFields = [
  {
    label: "Action Point",
    placeholder: "Enter Action Point",
    inputType: "text",
    inForm: true,
    name: "actionPoints",
    wrapperClass: "col-md-12",
  },
  // {
  //   label: "Action",
  //   placeholder: "Enter Action",
  //   inputType: "text",
  //   inForm: true,
  //   name: "action",
  //   wrapperClass: "col-md-6",
  // },
  {
    label : 'Timeline',
    placeholder : 'Enter Timeline',
    inputType : 'text',
    inForm : true,
    name : 'timeline',
    wrapperClass : 'col-md-6'
  },
  {
    label : 'Accountable Person',
    placeholder : 'Select Accountable Person',
    inputType : 'async-dropdown',
    inForm : true,
    name : 'accountablePerson',
    url : '/UserManagement/GetAll',
    searchAttrib : 'Search.value',
    id : 'accountablePerson',
    labelAttribute : 'name',
    valueAttribute : 'id',
    onChangeMethod : 'asyncdropdown',
    wrapperClass : 'col-md-6'
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "btn-wrapper my-2 ml-3",
    className: "btn btn-primary py-1",
  },
];
