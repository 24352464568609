import React, { useRef, useState } from "react";
import * as Icon from "react-bootstrap-icons";

const ActualVsTargetCard = ({ facilityQuarterWiseData, quarterWiseData }) => {
  const [open, setOpen] = useState(quarterWiseData?.map(() => false));
  const [facilityOpen, setFacilityOpen] = useState(
    facilityQuarterWiseData?.map(() => false)
  );

  const toggleCard = (index) => {
    const newOpen = [...open];
    newOpen[index] = !newOpen[index];
    setOpen(newOpen);
  };
  const toggleFacilityCard = (index) => {
    const newOpen = [...facilityOpen];
    newOpen[index] = !newOpen[index];
    setFacilityOpen(newOpen);
  };
  return (
    <>
      <h5 className="text-center font-weight-bold mb-4 mt-2">
        Project Actual Vs Target
      </h5>
      <h6 className="text-center font-weight-bold mb-2 mt-4">
        Beneficiary Data
      </h6>
      <div className="row row-cols-md-12 justify-content-center">
        {quarterWiseData?.map((item, index) => {
          return (
            <div className="col-md-5 m-1">
              <div
                style={{ backgroundColor: "#E4701E" }}
                className="row justify-content-between px-4 align-items-center shadow-sm"
              >
                <span className="py-1 text-white">
                  <b>{item?.year}</b>
                </span>
                {open[index] ? (
                  <Icon.ChevronBarUp
                    onClick={() => toggleCard(index)}
                    color="white"
                    size={20}
                  />
                ) : (
                  <Icon.ChevronBarDown
                    onClick={() => toggleCard(index)}
                    color="white"
                    size={20}
                  />
                )}
              </div>
              {open[index] && (
                <div
                  style={{
                    backgroundColor: open[index] ? "#fff" : "rgba(0,0,0,0)",
                  }}
                  className="row shadow-sm"
                >
                  <div className="py-1 px-2">
                    <b>Actual</b>
                    {`: ${item?.accomplishedTarget}`}
                  </div>
                  <div className="py-1 px-2">
                    <b>Target</b>
                    {`: ${item?.totalTarget}`}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <h6 className="text-center font-weight-bold mb-2 mt-4">Facility Data</h6>
      <div className="row row-cols-md-12 justify-content-center mb-4">
        {facilityQuarterWiseData?.map((item, index) => {
          return (
            <div className="col-md-5 m-1">
              <div
                style={{ backgroundColor: "#E4701E" }}
                className="row justify-content-between px-4 align-items-center shadow-sm"
              >
                <span className="py-1 text-white">
                  <b>{item?.year}</b>
                </span>
                {facilityOpen[index] ? (
                  <Icon.ChevronBarUp
                    onClick={() => toggleFacilityCard(index)}
                    color="white"
                    size={20}
                  />
                ) : (
                  <Icon.ChevronBarDown
                    onClick={() => toggleFacilityCard(index)}
                    color="white"
                    size={20}
                  />
                )}
              </div>
              {facilityOpen[index] && (
                <div
                  style={{
                    backgroundColor: open[index] ? "#fff" : "rgba(0,0,0,0)",
                  }}
                  className="row shadow-sm"
                >
                  <div className="py-1 px-2">
                    <b>Actual</b>
                    {`: ${item?.accomplishedTarget}`}
                  </div>

                  <div className="py-1 px-2">
                    <b>Target</b>
                    {`: ${item?.totalTarget}`}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ActualVsTargetCard;
