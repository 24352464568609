import React, { useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { feedbackResolutionFields } from "../../assets/fields/feedbackResolutionFields";

import { clearForm, createRecord, putRecord } from "../../redux/reducers";
import Form from "../form/Form";
import Loader from "../loader/loader";

const FeedbackResolutionModal = ({
  modalIsOpen,
  toggleFeedbackResolutionModal,
  feedbackIsEdit,
}) => {
  const dispatch = useDispatch();
  const { loading, created, data } = useSelector((state) => state.records);
  const formData = useSelector((state) => state.form.values);

  useEffect(() => {
    if (created) { }
    toggleFeedbackResolutionModal()
    return () => {
    }
  }, [created])

  useEffect(() => {
    if (!modalIsOpen) {
      dispatch({ type: clearForm.type })
    }
  }, [modalIsOpen])

  function postData(params) {
    const formValues = { ...formData, ...params, complaint: { id: data.id, name: data.complainantName } }
    if(feedbackIsEdit){
      dispatch({ type: putRecord.type, payload: { ...formValues, url: '/ComplaintsFeedbackResolution' } })
    }
    else{
      dispatch({ type: createRecord.type, payload: { ...formValues, url: '/ComplaintsFeedbackResolution' } })
    }
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
      >
        {modalIsOpen && <div className="mb-5">
          <Icon.XCircle onClick={toggleFeedbackResolutionModal} className="action-icons mb-2" />
          <h4 className="text-capitalize">{data.complainantName}: Complaint Feedback</h4>
          <Loader loading={loading} />
          <Form
            formData={[...feedbackResolutionFields]}
            onSubmit={(params) => postData(params)}
          />
        </div>}
      </Modal>
    </>
  );
};

export default FeedbackResolutionModal;
