/*===================================District Heat Map============================================*/
export const provincesGEOData = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      id: "01",
      properties: { name: "BAGH", count: 847 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.591968000000065, 34.074711000000036],
            [73.61848400000008, 34.062336000000073],
            [73.622903500000064, 34.079130000000077],
            [73.630858500000045, 34.083549500000061],
            [73.632626000000073, 34.06587200000007],
            [73.647652000000051, 34.062336000000073],
            [73.671517000000051, 34.059685000000059],
            [73.69096200000007, 34.064988000000028],
            [73.694451266000044, 34.069347041000071],
            [73.705103500000064, 34.082666000000074],
            [73.714826500000072, 34.078246000000036],
            [73.742226500000072, 34.082666000000074],
            [73.774046000000055, 34.082666000000074],
            [73.794375000000059, 34.076478500000064],
            [73.81028450000008, 34.083549500000061],
            [73.830614000000082, 34.081782000000032],
            [73.851827000000071, 34.066756000000055],
            [73.854478500000084, 34.04024000000004],
            [73.857130500000039, 34.026981500000034],
            [73.875691500000073, 34.017259000000024],
            [73.892485500000078, 34.020794000000024],
            [73.917496438000057, 34.019988192000028],
            [73.933927500000038, 34.011026000000072],
            [73.949281500000041, 34.016400000000033],
            [73.951534965000064, 34.01586375800008],
            [73.952665000000081, 34.00530800000007],
            [73.949603000000081, 34.000331000000074],
            [73.949220000000082, 33.995354000000077],
            [73.952665000000081, 33.988463000000024],
            [73.964916000000073, 33.984635000000026],
            [73.970276000000069, 33.981955000000028],
            [73.978315000000066, 33.984252000000026],
            [73.989035000000058, 33.984635000000026],
            [73.995160000000055, 33.980041000000028],
            [74.000520000000051, 33.97736100000003],
            [73.994394000000057, 33.972384000000034],
            [73.989418000000057, 33.965493000000038],
            [73.98482400000006, 33.958602000000042],
            [73.980612000000065, 33.954008000000044],
            [73.976784000000066, 33.94443700000005],
            [73.975635000000068, 33.929124000000058],
            [73.974870000000067, 33.918405000000064],
            [73.974853000000053, 33.918355000000076],
            [73.961427000000072, 33.920033000000046],
            [73.94551800000005, 33.907658000000026],
            [73.932260000000042, 33.890865000000076],
            [73.922791002000054, 33.890307970000038],
            [73.917233500000066, 33.889981000000034],
            [73.909278500000084, 33.896168000000046],
            [73.895136500000035, 33.905007000000069],
            [73.885414000000083, 33.898820000000057],
            [73.865085000000079, 33.90058700000003],
            [73.843872000000033, 33.905891000000054],
            [73.830614000000082, 33.902355000000057],
            [73.825310500000057, 33.882910000000038],
            [73.811168500000065, 33.879374000000041],
            [73.791723500000046, 33.874955000000057],
            [73.783768500000065, 33.885561000000052],
            [73.771394000000043, 33.888213000000064],
            [73.749297500000068, 33.893516000000034],
            [73.736039000000062, 33.905891000000054],
            [73.740458500000045, 33.915613000000064],
            [73.719245500000056, 33.91296200000005],
            [73.706871500000034, 33.925336000000073],
            [73.694497000000069, 33.94036200000005],
            [73.676819500000079, 33.953620000000058],
            [73.675936000000036, 33.964226000000053],
            [73.670368876000055, 33.967937814000038],
            [73.662677500000029, 33.973065000000076],
            [73.640581000000054, 33.977484000000061],
            [73.622019500000079, 33.970413000000065],
            [73.61406450000004, 33.953620000000058],
            [73.604342000000031, 33.933291000000054],
            [73.585513871000046, 33.909327809000047],
            [73.584924894000039, 33.90972623600004],
            [73.584671594000042, 33.909897713000078],
            [73.58189360800003, 33.911777186000052],
            [73.580493404000038, 33.912724420000075],
            [73.578651814000068, 33.913969807000058],
            [73.575675688000047, 33.915983077000078],
            [73.574064109000062, 33.917086244000075],
            [73.569137000000069, 33.920407000000068],
            [73.568185232000076, 33.921674704000054],
            [73.567306045000066, 33.922845735000067],
            [73.565738801000066, 33.924935504000075],
            [73.564992019000044, 33.925932368000076],
            [73.564292161000083, 33.926866594000046],
            [73.563532012000053, 33.92787906600006],
            [73.562834488000078, 33.928808124000057],
            [73.561216338000065, 33.930965771000047],
            [73.559338281000066, 33.933470768000063],
            [73.557813985000053, 33.935503909000033],
            [73.555823733000068, 33.938157500000045],
            [73.55260548800004, 33.942447924000078],
            [73.552600307000034, 33.942454831000077],
            [73.550922344000071, 33.94469181900007],
            [73.548354176000032, 33.948116222000067],
            [73.546699320000073, 33.950322813000071],
            [73.545371037000052, 33.952093953000031],
            [73.545371001000035, 33.952094001000034],
            [73.545370974000036, 33.952094037000052],
            [73.543699000000061, 33.954322000000047],
            [73.543615387000045, 33.954541988000074],
            [73.543497150000064, 33.954853068000034],
            [73.541600562000042, 33.959811485000046],
            [73.539492221000046, 33.96532350800004],
            [73.539117978000036, 33.966304345000026],
            [73.53871864100006, 33.967350951000071],
            [73.537637993000033, 33.970175045000076],
            [73.536350000000084, 33.973541000000068],
            [73.53634783900003, 33.973559762000036],
            [73.535229883000056, 33.983264252000026],
            [73.534655000000043, 33.988237000000026],
            [73.532763981000073, 33.989707862000046],
            [73.526789434000079, 33.994354949000069],
            [73.519394000000034, 34.000108000000068],
            [73.52448000000004, 34.012543000000051],
            [73.511034000000052, 34.018706000000066],
            [73.509794029000034, 34.018615259000057],
            [73.501413387000071, 34.018002034000062],
            [73.511033000000054, 34.018706000000066],
            [73.51103263400006, 34.018706168000051],
            [73.511032372000045, 34.018706288000033],
            [73.510913000000073, 34.01876100000004],
            [73.514869000000033, 34.036850000000072],
            [73.510913000000073, 34.059460000000058],
            [73.510727719000045, 34.059684964000041],
            [73.508793733000061, 34.062033166000049],
            [73.502999000000045, 34.06906900000007],
            [73.502999000000045, 34.08941800000008],
            [73.501476095000044, 34.103122347000067],
            [73.501478105000047, 34.103123593000078],
            [73.501413423000031, 34.103705899000033],
            [73.50269650000007, 34.103879000000063],
            [73.520374000000061, 34.112717000000032],
            [73.532748000000083, 34.131279000000063],
            [73.546006500000033, 34.131279000000063],
            [73.540703000000065, 34.102995000000078],
            [73.574290500000075, 34.099459000000024],
            [73.591968000000065, 34.074711000000036],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "02",
      properties: { name: "Bhimber", count: 1376 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.974686000000077, 33.240332000000024],
            [73.979105000000061, 33.234145000000069],
            [73.984408500000029, 33.238564000000054],
            [73.992363000000068, 33.248287000000062],
            [74.009049765000043, 33.243615082000076],
            [74.006859000000077, 33.241788000000042],
            [74.008394000000067, 33.214918500000067],
            [74.012016877000065, 33.213567580000074],
            [74.053688000000079, 33.198029000000076],
            [74.074416000000042, 33.190352000000075],
            [74.091495947000055, 33.176556609000045],
            [74.094376500000067, 33.17423000000008],
            [74.100180167000076, 33.172295444000042],
            [74.112801000000047, 33.168088500000067],
            [74.135832500000049, 33.159644000000071],
            [74.145812500000034, 33.155038000000047],
            [74.160399000000041, 33.128168000000073],
            [74.16577250000006, 33.125097000000039],
            [74.166737184000056, 33.116415470000049],
            [74.167308000000048, 33.111278500000026],
            [74.182522870000071, 33.093890683000041],
            [74.183430000000044, 33.092854000000045],
            [74.183191309000051, 33.092476071000078],
            [74.174217500000054, 33.078267500000038],
            [74.191170554000053, 33.074876889000052],
            [74.193410000000028, 33.074429000000066],
            [74.20339000000007, 33.071358000000032],
            [74.214905500000043, 33.069055000000048],
            [74.251755500000058, 33.049862500000074],
            [74.253317071000083, 33.048584708000078],
            [74.260199500000056, 33.042953000000068],
            [74.26864450000005, 33.042953000000068],
            [74.285534000000041, 33.034508500000072],
            [74.291350373000057, 33.030872596000052],
            [74.297817000000066, 33.026831500000071],
            [74.323151000000053, 33.02836700000006],
            [74.332363500000042, 32.983840000000043],
            [74.33929142900007, 32.95889963500008],
            [74.340040500000043, 32.956203000000073],
            [74.332363500000042, 32.941616500000066],
            [74.321615500000064, 32.927798000000053],
            [74.320246945000065, 32.918215441000029],
            [74.320080500000074, 32.917050000000074],
            [74.322490358000039, 32.916717580000068],
            [74.342343500000084, 32.91397900000004],
            [74.370748500000047, 32.916282500000079],
            [74.384626943000058, 32.908625310000048],
            [74.393012000000056, 32.903999000000056],
            [74.409133500000053, 32.896322000000055],
            [74.414507000000071, 32.884806500000025],
            [74.414507000000071, 32.866381500000045],
            [74.418346000000042, 32.849492000000055],
            [74.439841000000058, 32.826461000000052],
            [74.445983000000069, 32.805733000000032],
            [74.43954242500007, 32.792266896000058],
            [74.438826319000043, 32.790769646000058],
            [74.437538000000075, 32.788076000000046],
            [74.439519155000085, 32.788142394000033],
            [74.436885974000063, 32.786737835000054],
            [74.430426462000071, 32.783292281000058],
            [74.430251340000041, 32.783881328000064],
            [74.422936992000075, 32.779297670000062],
            [74.422751340000048, 32.77918132800005],
            [74.413251340000045, 32.773481328000059],
            [74.410651340000072, 32.771981328000038],
            [74.407510890000083, 32.771294355000066],
            [74.40745134000008, 32.771281328000043],
            [74.406512184000064, 32.771050970000033],
            [74.391551340000035, 32.767381328000056],
            [74.388751340000056, 32.767281328000024],
            [74.388629540000068, 32.767300662000025],
            [74.382451340000046, 32.768281328000057],
            [74.373851340000044, 32.769281328000034],
            [74.37175134000006, 32.769581328000072],
            [74.367595463000043, 32.771745847000034],
            [74.366951340000071, 32.77208132800007],
            [74.366943573000071, 32.772087320000026],
            [74.360230831000081, 32.775603585000056],
            [74.358751340000083, 32.775981328000057],
            [74.35714734000004, 32.777986328000054],
            [74.356598827000084, 32.778425140000024],
            [74.35602679200008, 32.778882768000074],
            [74.349351340000055, 32.782581328000049],
            [74.34865134000006, 32.784781328000065],
            [74.348650495000072, 32.784783804000028],
            [74.347723248000079, 32.787501600000041],
            [74.345751340000049, 32.793281328000035],
            [74.34435134000006, 32.798381328000062],
            [74.341251340000042, 32.809681328000067],
            [74.325451340000029, 32.814181328000075],
            [74.309351340000035, 32.821381328000029],
            [74.293951340000035, 32.828181328000028],
            [74.289019379000081, 32.830686451000076],
            [74.287651340000082, 32.831381328000077],
            [74.274851340000055, 32.838081328000044],
            [74.250951340000029, 32.849881328000038],
            [74.250522434000061, 32.84963228600003],
            [74.236664406000045, 32.85574622200005],
            [74.212960500000065, 32.866204000000039],
            [74.201876823000077, 32.871632978000036],
            [74.201778039000033, 32.87168136400004],
            [74.201651376000029, 32.871743406000064],
            [74.201642593000031, 32.871747708000044],
            [74.201651340000069, 32.871781328000054],
            [74.201551340000037, 32.873481328000025],
            [74.191771823000067, 32.876582151000036],
            [74.185151340000061, 32.878681328000027],
            [74.176751340000067, 32.88168132800007],
            [74.172851340000079, 32.882581328000072],
            [74.172841186000085, 32.882593775000032],
            [74.169751340000062, 32.886381328000027],
            [74.164951340000073, 32.88938132800007],
            [74.164324941000075, 32.889619776000075],
            [74.146946992000039, 32.896234947000039],
            [74.140166436000072, 32.898816065000062],
            [74.131851340000082, 32.901981328000034],
            [74.123151340000049, 32.905581328000039],
            [74.11665675900008, 32.909019636000039],
            [74.116351340000051, 32.909181328000045],
            [74.108651340000051, 32.913381328000071],
            [74.108325777000061, 32.913531589000058],
            [74.098251340000047, 32.91818132800006],
            [74.092629422000073, 32.922126534000029],
            [74.092551340000057, 32.922181328000079],
            [74.083151340000029, 32.927981328000044],
            [74.074751340000034, 32.931081328000062],
            [74.05295134000005, 32.930681328000048],
            [74.03815134000007, 32.938381328000048],
            [74.035651340000072, 32.93848132800008],
            [74.035571646000051, 32.938386691000062],
            [74.035466705000033, 32.93843333600006],
            [74.035466531000054, 32.938433414000031],
            [74.035466848000056, 32.938433811000039],
            [74.034189737000077, 32.939001422000047],
            [74.027557000000058, 32.941948000000025],
            [74.01949632600008, 32.945721198000058],
            [74.001853460000063, 32.953979815000025],
            [74.000990000000058, 32.954384000000061],
            [73.98403200000007, 32.966820000000041],
            [73.972207948000062, 32.98206027100008],
            [73.958596000000057, 32.999605000000031],
            [73.955939041000079, 33.00359043900005],
            [73.951698012000065, 33.009951984000054],
            [73.950615064000033, 33.011576406000074],
            [73.947839035000072, 33.015740448000031],
            [73.945030000000031, 33.019954000000041],
            [73.925811000000067, 33.034085000000061],
            [73.904333254000051, 33.039171703000079],
            [73.904332836000037, 33.039171802000055],
            [73.904332000000068, 33.039172000000065],
            [73.895578157000045, 33.037681998000039],
            [73.887581723000039, 33.036320916000079],
            [73.882869866000078, 33.03551890500006],
            [73.877765000000068, 33.034650000000056],
            [73.877642705000085, 33.034597118000079],
            [73.856850000000065, 33.025606000000039],
            [73.85528174600006, 33.024822000000029],
            [73.855362500000069, 33.025550000000067],
            [73.856496210000046, 33.027392299000041],
            [73.869504500000062, 33.048531000000025],
            [73.883646500000054, 33.073280000000068],
            [73.870388500000047, 33.079467000000079],
            [73.858014500000081, 33.099796000000026],
            [73.865969000000064, 33.115706000000046],
            [73.888066000000038, 33.129848000000038],
            [73.880995000000041, 33.150177000000042],
            [73.891601500000036, 33.156364000000053],
            [73.916350000000079, 33.171390000000031],
            [73.916349750000052, 33.182166000000052],
            [73.916350000000079, 33.184648000000038],
            [73.909279000000083, 33.202326000000028],
            [73.913698500000066, 33.216468000000077],
            [73.906136642000035, 33.224869978000072],
            [73.90662750000007, 33.225306000000046],
            [73.924305000000061, 33.237681000000066],
            [73.939331000000038, 33.250055000000032],
            [73.948169500000063, 33.259777000000042],
            [73.96142750000007, 33.253590000000031],
            [73.962311500000055, 33.24210000000005],
            [73.974686000000077, 33.240332000000024],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "03",
      properties: { name: "Hattian Bala", count: 926 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.936231000000078, 34.339602500000069],
            [73.938534000000061, 34.332693000000063],
            [73.936999000000071, 34.321178000000032],
            [73.940837000000045, 34.315804000000071],
            [73.946979000000056, 34.311197500000048],
            [73.950050000000033, 34.300450000000069],
            [73.946206415000063, 34.296256874000051],
            [73.941605000000038, 34.291237000000024],
            [73.945443000000068, 34.280489000000046],
            [73.962333000000058, 34.278186000000062],
            [73.963591990000054, 34.278042952000078],
            [73.996112000000039, 34.274348000000032],
            [74.000718000000063, 34.250549000000035],
            [74.002253000000053, 34.180688000000032],
            [73.964636000000041, 34.172243000000037],
            [73.962333000000058, 34.164566000000036],
            [73.973081000000036, 34.158425000000079],
            [73.962333000000058, 34.154586000000052],
            [73.95158500000008, 34.140767500000038],
            [73.93085700000006, 34.130787000000055],
            [73.93239200000005, 34.109292000000039],
            [73.906291000000067, 34.104685500000073],
            [73.910897000000034, 34.084725000000049],
            [73.900149000000056, 34.060926500000051],
            [73.90475500000008, 34.048643000000027],
            [73.922412000000065, 34.04250200000007],
            [73.929081027000052, 34.032021923000059],
            [73.933160000000044, 34.025612000000024],
            [73.917038000000048, 34.020238000000063],
            [73.917496438000057, 34.019988192000028],
            [73.892485500000078, 34.020794000000024],
            [73.875691500000073, 34.017259000000024],
            [73.857130500000039, 34.026981500000034],
            [73.854478500000084, 34.04024000000004],
            [73.851827000000071, 34.066756000000055],
            [73.830614000000082, 34.081782000000032],
            [73.81028450000008, 34.083549500000061],
            [73.794375000000059, 34.076478500000064],
            [73.774046000000055, 34.082666000000074],
            [73.742226500000072, 34.082666000000074],
            [73.714826500000072, 34.078246000000036],
            [73.705103500000064, 34.082666000000074],
            [73.694451266000044, 34.069347041000071],
            [73.694916000000035, 34.081717000000026],
            [73.692805000000078, 34.092273000000034],
            [73.677499000000068, 34.103356000000076],
            [73.673276000000044, 34.110745000000065],
            [73.664304000000072, 34.120773000000042],
            [73.663776000000041, 34.127107000000024],
            [73.658498000000066, 34.136079000000052],
            [73.649526000000037, 34.145579000000055],
            [73.64741500000008, 34.153496000000075],
            [73.64741500000008, 34.159830000000056],
            [73.64741500000008, 34.165635000000066],
            [73.640026000000034, 34.180413000000044],
            [73.635276000000033, 34.192025000000058],
            [73.629998000000057, 34.202580000000069],
            [73.630526000000032, 34.213136000000077],
            [73.638970000000029, 34.214192000000025],
            [73.642665000000079, 34.209969000000058],
            [73.654276000000038, 34.203636000000074],
            [73.661137000000053, 34.201525000000061],
            [73.667999000000066, 34.202580000000069],
            [73.675915000000032, 34.199941000000024],
            [73.677499000000068, 34.199941000000024],
            [73.684360000000083, 34.204164000000048],
            [73.688582000000054, 34.207331000000067],
            [73.694916000000035, 34.209969000000058],
            [73.70177700000005, 34.212608000000046],
            [73.709166000000039, 34.213664000000051],
            [73.716555000000085, 34.21683100000007],
            [73.722361000000035, 34.227914000000055],
            [73.735028000000057, 34.238470000000063],
            [73.744528000000059, 34.244803000000047],
            [73.75244500000008, 34.248498000000041],
            [73.75244500000008, 34.254304000000047],
            [73.75666700000005, 34.260637000000031],
            [73.760889000000077, 34.266971000000069],
            [73.763000000000034, 34.274887000000035],
            [73.765112000000045, 34.282276000000024],
            [73.75666700000005, 34.287026000000026],
            [73.751389000000074, 34.295471000000077],
            [73.751389000000074, 34.302332000000035],
            [73.751389000000074, 34.314471000000026],
            [73.751917000000049, 34.32608300000004],
            [73.752972000000057, 34.332944000000055],
            [73.757723000000055, 34.342444000000057],
            [73.758652983000047, 34.343104960000062],
            [73.770407500000033, 34.338067000000024],
            [73.790368000000058, 34.336532000000034],
            [73.802651000000083, 34.340370000000064],
            [73.808792500000038, 34.346512000000075],
            [73.818773000000078, 34.341905500000053],
            [73.816469500000039, 34.334228500000052],
            [73.844107000000065, 34.330390000000079],
            [73.855622000000039, 34.325016000000062],
            [73.871744000000035, 34.338835000000074],
            [73.885563000000047, 34.334996000000046],
            [73.906291000000067, 34.343441000000041],
            [73.916658827000049, 34.345100004000074],
            [73.925483000000042, 34.346512000000075],
            [73.936231000000078, 34.339602500000069],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "04",
      properties: { name: "Haveli", count: 707 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.168844000000036, 34.038663000000042],
            [74.18112700000006, 34.025612000000024],
            [74.200319500000035, 34.024077000000034],
            [74.197249000000056, 34.000278000000037],
            [74.221815000000049, 33.999510000000043],
            [74.252797452000038, 34.002264135000075],
            [74.256362000000081, 34.002581000000077],
            [74.268645000000049, 33.989530000000059],
            [74.267109500000061, 33.978015000000028],
            [74.27632200000005, 33.97494400000005],
            [74.27632200000005, 33.963428000000079],
            [74.27478700000006, 33.954983500000026],
            [74.284767000000045, 33.948074000000076],
            [74.283999000000051, 33.938094000000035],
            [74.280160500000079, 33.928882000000044],
            [74.280160500000079, 33.911992000000055],
            [74.283999000000051, 33.902780000000064],
            [74.274019000000067, 33.891264000000035],
            [74.24638200000004, 33.881284000000051],
            [74.217209000000082, 33.856718000000058],
            [74.188036500000067, 33.843667000000039],
            [74.158864000000051, 33.834454000000051],
            [74.154765600000076, 33.834302248000029],
            [74.138136000000031, 33.833686500000056],
            [74.12617036000006, 33.827443631000051],
            [74.120478500000047, 33.824474000000066],
            [74.099799679000057, 33.82659506400006],
            [74.090538500000036, 33.827545000000043],
            [74.057527500000049, 33.828313000000037],
            [74.050681928000074, 33.813101582000058],
            [74.024182000000053, 33.841368000000045],
            [74.00031800000005, 33.866116000000034],
            [73.997666000000038, 33.888213000000064],
            [73.982641000000058, 33.917381000000034],
            [73.974853000000053, 33.918355000000076],
            [73.974870000000067, 33.918405000000064],
            [73.975635000000068, 33.929124000000058],
            [73.976784000000066, 33.94443700000005],
            [73.980612000000065, 33.954008000000044],
            [73.98482400000006, 33.958602000000042],
            [73.989418000000057, 33.965493000000038],
            [73.994394000000057, 33.972384000000034],
            [74.000520000000051, 33.97736100000003],
            [73.995160000000055, 33.980041000000028],
            [73.989035000000058, 33.984635000000026],
            [73.978315000000066, 33.984252000000026],
            [73.970276000000069, 33.981955000000028],
            [73.964916000000073, 33.984635000000026],
            [73.952665000000081, 33.988463000000024],
            [73.949220000000082, 33.995354000000077],
            [73.949603000000081, 34.000331000000074],
            [73.952665000000081, 34.00530800000007],
            [73.951534965000064, 34.01586375800008],
            [73.965403500000036, 34.012561000000062],
            [73.984595500000069, 34.012561000000062],
            [74.000717500000064, 34.015632000000039],
            [74.005817182000044, 34.022771998000053],
            [74.012232500000039, 34.031754000000035],
            [74.016071500000066, 34.037128000000052],
            [74.033728500000052, 34.029451000000051],
            [74.079790500000058, 34.040198500000031],
            [74.095501124000066, 34.040198500000031],
            [74.116640500000074, 34.040198500000031],
            [74.16141674000005, 34.038881463000052],
            [74.168844000000036, 34.038663000000042],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "05",
      properties: { name: "Kotli", count: 1257 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.727200500000038, 33.587695000000053],
            [73.740458500000045, 33.581508000000042],
            [73.750181500000053, 33.598302000000047],
            [73.750181500000053, 33.614212000000066],
            [73.765207500000031, 33.613328000000024],
            [73.78288450000008, 33.60714100000007],
            [73.800562500000069, 33.614212000000066],
            [73.820891500000073, 33.603605000000073],
            [73.842988500000047, 33.592115000000035],
            [73.858898500000066, 33.587695000000053],
            [73.878343500000028, 33.592115000000035],
            [73.886298500000066, 33.600953000000061],
            [73.895137000000034, 33.592115000000035],
            [73.895248844000037, 33.592209791000073],
            [73.904301903000032, 33.599870060000057],
            [73.904858500000046, 33.597418000000062],
            [73.929607500000031, 33.606257000000028],
            [73.948168500000065, 33.613328000000024],
            [73.96054300000003, 33.615979000000038],
            [73.969381500000054, 33.608908000000042],
            [73.984407500000032, 33.608908000000042],
            [73.995898000000068, 33.601837000000046],
            [74.015860751000048, 33.596348341000066],
            [74.029538532000061, 33.592587703000049],
            [74.034495500000048, 33.586486500000035],
            [74.036031000000037, 33.580345000000079],
            [74.042172500000049, 33.575738500000057],
            [74.052152500000034, 33.57650650000005],
            [74.060597500000028, 33.581112500000074],
            [74.074416000000042, 33.583415500000058],
            [74.085163500000078, 33.58571900000004],
            [74.092840500000079, 33.578809500000034],
            [74.097105033000048, 33.577388100000064],
            [74.108962500000075, 33.573435500000073],
            [74.128922500000044, 33.562688000000037],
            [74.14734750000008, 33.554243000000042],
            [74.155792500000075, 33.541192000000024],
            [74.163469500000076, 33.527373500000067],
            [74.163469500000076, 33.50357450000007],
            [74.180358500000068, 33.494362000000024],
            [74.188035500000069, 33.472099000000071],
            [74.188803500000063, 33.449068000000068],
            [74.182661500000052, 33.437552000000039],
            [74.182661500000052, 33.41759200000007],
            [74.18266066700005, 33.414984000000061],
            [74.182661500000052, 33.408379500000024],
            [74.196480500000064, 33.408379500000024],
            [74.19571250000007, 33.396096000000057],
            [74.192641500000036, 33.386884000000066],
            [74.189571500000056, 33.379207000000065],
            [74.181126500000062, 33.369994500000075],
            [74.178823500000078, 33.358479000000045],
            [74.170378500000083, 33.344660000000033],
            [74.158863500000052, 33.325468000000058],
            [74.142285375000029, 33.314918463000026],
            [74.141973500000063, 33.314720000000079],
            [74.127387500000054, 33.300901000000067],
            [74.118175000000065, 33.300133500000072],
            [74.097447000000045, 33.28477950000007],
            [74.078254500000071, 33.283244000000025],
            [74.049082000000055, 33.280941000000041],
            [74.03296000000006, 33.267890000000079],
            [74.021445000000028, 33.264819000000045],
            [74.021088989000077, 33.259480914000051],
            [74.020677000000035, 33.253303500000072],
            [74.012482288000058, 33.246475239000063],
            [74.009049765000043, 33.243615082000076],
            [73.992363000000068, 33.248287000000062],
            [73.984408500000029, 33.238564000000054],
            [73.979105000000061, 33.234145000000069],
            [73.974686000000077, 33.240332000000024],
            [73.962311500000055, 33.24210000000005],
            [73.96142750000007, 33.253590000000031],
            [73.948169500000063, 33.259777000000042],
            [73.939331000000038, 33.250055000000032],
            [73.924305000000061, 33.237681000000066],
            [73.90662750000007, 33.225306000000046],
            [73.906136642000035, 33.224869978000072],
            [73.897788500000047, 33.234145000000069],
            [73.858014500000081, 33.263313000000039],
            [73.810285000000079, 33.307507000000044],
            [73.773162500000069, 33.337559000000056],
            [73.773160000000075, 33.360065800000029],
            [73.773162500000069, 33.360539000000074],
            [73.75725250000005, 33.371146000000067],
            [73.763143466000031, 33.40281191300005],
            [73.764323500000046, 33.409152000000063],
            [73.734272000000033, 33.435669000000075],
            [73.697149000000081, 33.459533000000079],
            [73.676820000000077, 33.458649000000037],
            [73.665329500000041, 33.466604000000075],
            [73.650304000000062, 33.458649000000037],
            [73.629752823000047, 33.457792998000059],
            [73.629751835000036, 33.457792957000038],
            [73.62975102300004, 33.457792923000056],
            [73.629750306000062, 33.457792893000033],
            [73.625661000000036, 33.471593000000041],
            [73.611530000000073, 33.491943000000049],
            [73.611669433000031, 33.495988274000069],
            [73.611739741000065, 33.498028090000048],
            [73.611743108000042, 33.498125766000044],
            [73.612095000000068, 33.508335000000045],
            [73.615925064000066, 33.513696819000074],
            [73.620574000000033, 33.520205000000033],
            [73.626128406000078, 33.524051056000076],
            [73.627922000000069, 33.525293000000033],
            [73.630110001000048, 33.52573036800004],
            [73.633575000000064, 33.526423000000079],
            [73.63301000000007, 33.539989000000048],
            [73.625729938000063, 33.545077028000037],
            [73.62576447400005, 33.547763667000027],
            [73.625825087000067, 33.549341233000064],
            [73.626059542000064, 33.555443319000062],
            [73.626128779000055, 33.557233460000077],
            [73.626227000000029, 33.559773000000064],
            [73.627399618000084, 33.563877423000065],
            [73.627943203000029, 33.565781838000078],
            [73.628487500000062, 33.567687000000035],
            [73.625096000000042, 33.573057000000063],
            [73.621705000000077, 33.578427000000033],
            [73.613549526000043, 33.588280655000062],
            [73.610543761000031, 33.591912297000079],
            [73.608138000000054, 33.59481900000003],
            [73.606763307000051, 33.597318743000073],
            [73.606763176000072, 33.59731878100007],
            [73.60589224000006, 33.598902362000047],
            [73.605889233000084, 33.598907831000076],
            [73.601921000000061, 33.606124000000079],
            [73.59768100000008, 33.610081000000037],
            [73.59344150000004, 33.61403800000005],
            [73.579310000000078, 33.623647000000062],
            [73.57243075000008, 33.629997293000031],
            [73.571962000000042, 33.630430000000047],
            [73.57415314800005, 33.639438819000077],
            [73.575532187000078, 33.645108684000036],
            [73.577049000000045, 33.651345000000049],
            [73.577049000000045, 33.658184145000064],
            [73.577049000000045, 33.666607000000056],
            [73.583832000000029, 33.672824000000048],
            [73.595702500000073, 33.680738000000076],
            [73.59570343300004, 33.680741306000073],
            [73.596384897000064, 33.683155829000043],
            [73.596387132000075, 33.683154000000059],
            [73.615832500000067, 33.667244000000039],
            [73.62997450000006, 33.649567000000047],
            [73.674168500000064, 33.629237000000046],
            [73.69714950000008, 33.615979000000038],
            [73.727200500000038, 33.587695000000053],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "06",
      properties: { name: "Mirpur", count: 472 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.665329500000041, 33.466604000000075],
            [73.676820000000077, 33.458649000000037],
            [73.697149000000081, 33.459533000000079],
            [73.734272000000033, 33.435669000000075],
            [73.764323500000046, 33.409152000000063],
            [73.763143466000031, 33.40281191300005],
            [73.75725250000005, 33.371146000000067],
            [73.773162500000069, 33.360539000000074],
            [73.773160000000075, 33.360065800000029],
            [73.773162500000069, 33.337559000000056],
            [73.810285000000079, 33.307507000000044],
            [73.858014500000081, 33.263313000000039],
            [73.897788500000047, 33.234145000000069],
            [73.906136642000035, 33.224869978000072],
            [73.913698500000066, 33.216468000000077],
            [73.909279000000083, 33.202326000000028],
            [73.916350000000079, 33.184648000000038],
            [73.916349750000052, 33.182166000000052],
            [73.916350000000079, 33.171390000000031],
            [73.891601500000036, 33.156364000000053],
            [73.880995000000041, 33.150177000000042],
            [73.888066000000038, 33.129848000000038],
            [73.865969000000064, 33.115706000000046],
            [73.858014500000081, 33.099796000000026],
            [73.870388500000047, 33.079467000000079],
            [73.883646500000054, 33.073280000000068],
            [73.869504500000062, 33.048531000000025],
            [73.856496210000046, 33.027392299000041],
            [73.855362500000069, 33.025550000000067],
            [73.85528174600006, 33.024822000000029],
            [73.846670255000049, 33.020516127000064],
            [73.829718000000071, 33.01204000000007],
            [73.816153901000064, 33.010344238000073],
            [73.816154085000051, 33.010344199000031],
            [73.816154267000059, 33.010344159000056],
            [73.816154634000043, 33.010344080000039],
            [73.81615400000004, 33.010344000000032],
            [73.816153634000045, 33.010344080000039],
            [73.816153452000037, 33.010344119000024],
            [73.816153267000061, 33.010344159000056],
            [73.816152634000048, 33.010344080000039],
            [73.816152316000057, 33.010344039000074],
            [73.816152000000045, 33.010344000000032],
            [73.79523800000004, 33.01486600000004],
            [73.794974263000029, 33.014951335000035],
            [73.782885000000078, 33.018863000000067],
            [73.776019000000076, 33.02108400000003],
            [73.773987102000035, 33.023319178000065],
            [73.773850024000069, 33.023469970000065],
            [73.770281796000063, 33.027395179000052],
            [73.769034348000048, 33.028767426000059],
            [73.764714000000083, 33.033520000000067],
            [73.763456482000038, 33.035369307000053],
            [73.755105000000071, 33.04765100000003],
            [73.754553440000052, 33.04816810300008],
            [73.75370272400005, 33.048965672000065],
            [73.74876856700007, 33.053591581000035],
            [73.746061000000054, 33.056130000000053],
            [73.744192902000066, 33.057031911000024],
            [73.737530378000031, 33.060248555000044],
            [73.729669000000058, 33.064044000000024],
            [73.726623184000061, 33.064835871000071],
            [73.720081136000033, 33.06653671600003],
            [73.711016828000083, 33.068893314000036],
            [73.702579101000083, 33.071087009000053],
            [73.701406000000077, 33.07139200000006],
            [73.695966334000047, 33.072715049000067],
            [73.695810609000034, 33.072752925000032],
            [73.693278293000049, 33.073368842000036],
            [73.692376255000056, 33.07358823800007],
            [73.680491000000075, 33.076479000000063],
            [73.674343703000034, 33.07737553700008],
            [73.666666337000038, 33.078495224000051],
            [73.662897546000067, 33.079044874000033],
            [73.655863048000072, 33.080070803000069],
            [73.65335900000008, 33.080436000000077],
            [73.648239601000057, 33.083899279000036],
            [73.648224408000033, 33.083909557000027],
            [73.647167358000047, 33.084624653000049],
            [73.644878020000078, 33.086173393000024],
            [73.641686512000035, 33.088332452000031],
            [73.640883189000078, 33.088875901000051],
            [73.635878253000044, 33.092261747000066],
            [73.634141000000056, 33.093437000000051],
            [73.629286501000081, 33.103415083000073],
            [73.629268362000062, 33.103452366000056],
            [73.625544714000057, 33.111106065000058],
            [73.624083510000048, 33.114109467000048],
            [73.623966000000053, 33.114351000000056],
            [73.625096000000042, 33.131874000000039],
            [73.631327283000076, 33.135229404000029],
            [73.637603784000078, 33.138609157000076],
            [73.637792603000037, 33.138710831000026],
            [73.639793000000054, 33.139788000000067],
            [73.640059286000053, 33.139876751000031],
            [73.656751000000042, 33.145440000000065],
            [73.670317000000068, 33.152224000000047],
            [73.670779779000043, 33.156622039000069],
            [73.671447000000057, 33.162963000000047],
            [73.671102063000035, 33.177629553000031],
            [73.670317000000068, 33.211010000000044],
            [73.669461149000028, 33.212037042000077],
            [73.661838000000046, 33.221185000000048],
            [73.655368382000063, 33.221931433000066],
            [73.649788031000071, 33.222575268000071],
            [73.649588643000072, 33.222598297000047],
            [73.647141000000033, 33.222881000000029],
            [73.645545528000071, 33.23085836100006],
            [73.644880000000057, 33.234186000000079],
            [73.627357000000075, 33.241534000000058],
            [73.624682000000064, 33.255248000000051],
            [73.62468165000007, 33.255247946000054],
            [73.624681632000033, 33.255248036000069],
            [73.624677888000065, 33.255267220000064],
            [73.622835000000066, 33.264710000000036],
            [73.614357000000041, 33.278841000000057],
            [73.618313000000057, 33.289016000000061],
            [73.611083984000061, 33.300903320000032],
            [73.603698730000076, 33.319091797000056],
            [73.604147420000061, 33.320830470000033],
            [73.60590467600008, 33.326980866000042],
            [73.60827636700003, 33.334899902000075],
            [73.606812151000042, 33.335713355000053],
            [73.606181889000084, 33.336063501000069],
            [73.606257678000077, 33.336094616000025],
            [73.606364606000056, 33.336138515000073],
            [73.599095000000034, 33.341019000000074],
            [73.596841665000056, 33.341184651000049],
            [73.588833782000052, 33.341773342000067],
            [73.579311000000075, 33.357977000000062],
            [73.575977865000084, 33.377401190000057],
            [73.58270200000004, 33.40263200000004],
            [73.592311000000052, 33.413372000000038],
            [73.601920500000062, 33.434852000000035],
            [73.615679381000064, 33.448060291000047],
            [73.616052000000082, 33.448418000000061],
            [73.623380596000061, 33.452521682000054],
            [73.630008145000033, 33.456232809000028],
            [73.630183500000044, 33.456331000000034],
            [73.630008145000033, 33.456922768000027],
            [73.629750306000062, 33.457792893000033],
            [73.62975102300004, 33.457792923000056],
            [73.629751835000036, 33.457792957000038],
            [73.629752823000047, 33.457792998000059],
            [73.650304000000062, 33.458649000000037],
            [73.665329500000041, 33.466604000000075],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "07",
      properties: { name: "Muzaffarabad", count: 1196 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.567728704000046, 34.587624335000044],
            [73.57422100000008, 34.584018000000071],
            [73.574221089000048, 34.584018006000065],
            [73.590613000000076, 34.585148000000061],
            [73.590614500000072, 34.585148000000061],
            [73.591461841000068, 34.585148000000061],
            [73.605875000000083, 34.585148000000061],
            [73.614361854000038, 34.583127457000046],
            [73.61774500000007, 34.582322000000033],
            [73.617745545000048, 34.582321727000078],
            [73.627920000000074, 34.577234000000033],
            [73.63639900000004, 34.56479900000005],
            [73.64431200000007, 34.561407000000031],
            [73.644313237000063, 34.56140711300003],
            [73.644313500000067, 34.561407000000031],
            [73.655268982000052, 34.56240319300008],
            [73.665329500000041, 34.54228100000006],
            [73.693613500000083, 34.532558000000051],
            [73.719246000000055, 34.527255000000025],
            [73.745762500000069, 34.528139000000067],
            [73.767859500000043, 34.514881000000059],
            [73.759904500000061, 34.486597000000074],
            [73.74753000000004, 34.475106000000039],
            [73.737807500000031, 34.461848000000032],
            [73.748414000000082, 34.445938500000068],
            [73.742226500000072, 34.431796000000077],
            [73.752833500000065, 34.412351000000058],
            [73.742226500000072, 34.392022000000054],
            [73.744878500000084, 34.377880000000062],
            [73.758256801000073, 34.37787957900008],
            [73.753518000000042, 34.372614000000056],
            [73.757356500000071, 34.35802700000005],
            [73.754286000000036, 34.344976000000031],
            [73.758652983000047, 34.343104960000062],
            [73.757723000000055, 34.342444000000057],
            [73.752972000000057, 34.332944000000055],
            [73.751917000000049, 34.32608300000004],
            [73.751389000000074, 34.314471000000026],
            [73.751389000000074, 34.302332000000035],
            [73.751389000000074, 34.295471000000077],
            [73.75666700000005, 34.287026000000026],
            [73.765112000000045, 34.282276000000024],
            [73.763000000000034, 34.274887000000035],
            [73.760889000000077, 34.266971000000069],
            [73.75666700000005, 34.260637000000031],
            [73.75244500000008, 34.254304000000047],
            [73.75244500000008, 34.248498000000041],
            [73.744528000000059, 34.244803000000047],
            [73.735028000000057, 34.238470000000063],
            [73.722361000000035, 34.227914000000055],
            [73.716555000000085, 34.21683100000007],
            [73.709166000000039, 34.213664000000051],
            [73.70177700000005, 34.212608000000046],
            [73.694916000000035, 34.209969000000058],
            [73.688582000000054, 34.207331000000067],
            [73.684360000000083, 34.204164000000048],
            [73.677499000000068, 34.199941000000024],
            [73.675915000000032, 34.199941000000024],
            [73.667999000000066, 34.202580000000069],
            [73.661137000000053, 34.201525000000061],
            [73.654276000000038, 34.203636000000074],
            [73.642665000000079, 34.209969000000058],
            [73.638970000000029, 34.214192000000025],
            [73.630526000000032, 34.213136000000077],
            [73.629998000000057, 34.202580000000069],
            [73.635276000000033, 34.192025000000058],
            [73.640026000000034, 34.180413000000044],
            [73.64741500000008, 34.165635000000066],
            [73.64741500000008, 34.159830000000056],
            [73.64741500000008, 34.153496000000075],
            [73.649526000000037, 34.145579000000055],
            [73.658498000000066, 34.136079000000052],
            [73.663776000000041, 34.127107000000024],
            [73.664304000000072, 34.120773000000042],
            [73.673276000000044, 34.110745000000065],
            [73.677499000000068, 34.103356000000076],
            [73.692805000000078, 34.092273000000034],
            [73.694916000000035, 34.081717000000026],
            [73.694451266000044, 34.069347041000071],
            [73.69096200000007, 34.064988000000028],
            [73.671517000000051, 34.059685000000059],
            [73.647652000000051, 34.062336000000073],
            [73.632626000000073, 34.06587200000007],
            [73.630858500000045, 34.083549500000061],
            [73.622903500000064, 34.079130000000077],
            [73.61848400000008, 34.062336000000073],
            [73.591968000000065, 34.074711000000036],
            [73.574290500000075, 34.099459000000024],
            [73.540703000000065, 34.102995000000078],
            [73.546006500000033, 34.131279000000063],
            [73.532748000000083, 34.131279000000063],
            [73.520374000000061, 34.112717000000032],
            [73.50269650000007, 34.103879000000063],
            [73.501413423000031, 34.103705899000033],
            [73.501478105000047, 34.103123593000078],
            [73.501476095000044, 34.103122347000067],
            [73.501458451000076, 34.103281128000049],
            [73.501303000000064, 34.10468000000003],
            [73.500794056000075, 34.107178393000027],
            [73.495085000000074, 34.135204000000044],
            [73.495085000000074, 34.136024565000071],
            [73.495085000000074, 34.173024480000038],
            [73.495085000000074, 34.187208000000055],
            [73.499042000000031, 34.207557000000065],
            [73.490563000000066, 34.229037000000062],
            [73.489811601000042, 34.230239274000041],
            [73.473606000000075, 34.256169000000057],
            [73.483780000000081, 34.268605000000036],
            [73.484949748000076, 34.271382958000061],
            [73.488302000000033, 34.279344000000037],
            [73.480390219000071, 34.291213172000027],
            [73.480389000000059, 34.291215000000079],
            [73.479258000000073, 34.283866000000046],
            [73.469649749000041, 34.279910308000069],
            [73.469649000000061, 34.279910000000029],
            [73.46271002800006, 34.28738252100004],
            [73.462301000000082, 34.28782300000006],
            [73.459437511000033, 34.29113867500007],
            [73.451561000000083, 34.30025900000004],
            [73.451007765000043, 34.305376360000025],
            [73.449300000000051, 34.321173000000044],
            [73.440256000000034, 34.326261000000045],
            [73.439788554000074, 34.326784088000068],
            [73.426053619000072, 34.342153956000061],
            [73.425003023000045, 34.343329609000079],
            [73.416515000000061, 34.352828000000045],
            [73.407631855000034, 34.361711145000072],
            [73.402949000000035, 34.366394000000071],
            [73.401295000000061, 34.370735000000025],
            [73.401190000000042, 34.370728000000042],
            [73.401074053000059, 34.370762975000048],
            [73.401174753000078, 34.370766119000052],
            [73.40127975300004, 34.370773119000035],
            [73.398427964000064, 34.378259563000029],
            [73.398426305000044, 34.378263918000073],
            [73.398427964000064, 34.378264595000076],
            [73.398428795000029, 34.378264934000072],
            [73.412558000000047, 34.385613000000035],
            [73.421036808000054, 34.398612706000051],
            [73.42103763800003, 34.398614701000042],
            [73.424220840000032, 34.407103709000069],
            [73.424222263000047, 34.407107505000056],
            [73.427820000000054, 34.416702000000043],
            [73.428822753000077, 34.420378891000041],
            [73.428847794000035, 34.420470713000043],
            [73.432907000000057, 34.435355000000072],
            [73.431920772000069, 34.443809988000055],
            [73.431917476000081, 34.443838243000073],
            [73.428951186000063, 34.469268405000037],
            [73.42895100000004, 34.469270000000051],
            [73.434794107000073, 34.478521801000056],
            [73.435734000000082, 34.48001000000005],
            [73.442516729000033, 34.494706413000074],
            [73.442517000000066, 34.494707000000062],
            [73.442517544000054, 34.494710447000045],
            [73.44421200000005, 34.505447000000061],
            [73.436662370000079, 34.524574867000069],
            [73.435734000000082, 34.526927000000057],
            [73.438211772000045, 34.528729089000024],
            [73.438213252000082, 34.52873016500007],
            [73.448169000000064, 34.535971000000075],
            [73.448292045000073, 34.541018001000054],
            [73.448292090000052, 34.541019861000052],
            [73.448734000000059, 34.559146000000055],
            [73.452379411000038, 34.567348578000065],
            [73.453256000000067, 34.569321000000059],
            [73.465692000000047, 34.574973000000057],
            [73.465693000000044, 34.574973000000057],
            [73.474171000000069, 34.574973000000057],
            [73.479823000000067, 34.57101700000004],
            [73.479823503000034, 34.57101719800005],
            [73.479824500000063, 34.571016500000042],
            [73.484162989000083, 34.572720784000069],
            [73.495651061000046, 34.57723363100007],
            [73.508651000000043, 34.572712000000024],
            [73.523348000000055, 34.569886000000054],
            [73.523348616000078, 34.569886074000067],
            [73.523349000000053, 34.569886000000054],
            [73.537480500000072, 34.571582000000035],
            [73.548785500000065, 34.578365000000076],
            [73.551455984000029, 34.581607647000055],
            [73.556699000000037, 34.587974000000031],
            [73.564046440000084, 34.589669755000045],
            [73.567723362000038, 34.587627302000044],
            [73.567728704000046, 34.587624335000044],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "08",
      properties: { name: "Neelum", count: 541 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.455767500000036, 35.13176500000003],
            [74.463492000000031, 35.107998500000065],
            [74.475375000000042, 35.110375000000033],
            [74.487258000000054, 35.111563000000046],
            [74.509836000000064, 35.101463000000024],
            [74.516371000000049, 35.084232000000043],
            [74.523501000000067, 35.076508000000047],
            [74.538355000000081, 35.068190000000072],
            [74.541326000000083, 35.053930000000037],
            [74.535978500000056, 35.04798900000003],
            [74.534790000000044, 35.035511000000042],
            [74.539543500000036, 35.026005000000055],
            [74.550832000000071, 35.020657000000028],
            [74.561527000000069, 35.009368500000051],
            [74.569251000000065, 35.011745000000076],
            [74.573410000000081, 35.018281000000059],
            [74.57875800000005, 35.012933000000032],
            [74.580540000000042, 35.006398000000047],
            [74.588858000000073, 34.998674000000051],
            [74.579352000000029, 34.989167000000066],
            [74.57519300000007, 34.990355000000079],
            [74.575787000000048, 34.974313000000052],
            [74.569845000000043, 34.969560000000058],
            [74.569251000000065, 34.959459000000038],
            [74.566280500000062, 34.955894000000058],
            [74.566280500000062, 34.948170000000061],
            [74.572816000000046, 34.940446000000065],
            [74.56865700000003, 34.92381000000006],
            [74.574004500000058, 34.914303000000075],
            [74.570439500000077, 34.906579000000079],
            [74.570439500000077, 34.896479000000056],
            [74.579352000000029, 34.897073000000034],
            [74.584105000000079, 34.904203000000052],
            [74.59301750000003, 34.907768000000033],
            [74.598959000000036, 34.910738000000038],
            [74.607277000000067, 34.911332500000071],
            [74.618566000000044, 34.910144000000059],
            [74.634608000000071, 34.903608500000075],
            [74.645897000000048, 34.896479000000056],
            [74.657186000000081, 34.897073000000034],
            [74.665504500000054, 34.900043500000038],
            [74.670257500000048, 34.889349000000038],
            [74.678575500000079, 34.88519000000008],
            [74.68392300000005, 34.872712000000035],
            [74.695212000000083, 34.868553000000077],
            [74.698777000000064, 34.869147500000054],
            [74.702936000000079, 34.853699000000063],
            [74.710660000000075, 34.850728500000059],
            [74.718384000000071, 34.856670000000065],
            [74.725514000000032, 34.855482000000052],
            [74.726702500000044, 34.849540000000047],
            [74.727890500000058, 34.838251000000071],
            [74.735614500000054, 34.833498000000077],
            [74.752845000000036, 34.822803000000079],
            [74.757598500000029, 34.814485000000047],
            [74.760569000000032, 34.806167000000073],
            [74.762945500000058, 34.787748000000079],
            [74.771858000000066, 34.773488000000043],
            [74.776017000000081, 34.762199000000066],
            [74.786117500000046, 34.755069000000049],
            [74.799153511000043, 34.756203124000024],
            [74.800662500000044, 34.74187900000004],
            [74.792985500000043, 34.731898500000057],
            [74.769187000000045, 34.725757000000044],
            [74.729266500000051, 34.714241000000072],
            [74.702397000000076, 34.71577700000006],
            [74.673224000000062, 34.708100000000059],
            [74.659706238000069, 34.709367256000064],
            [74.648658000000069, 34.710403000000042],
            [74.628698000000043, 34.724221500000056],
            [74.604131000000052, 34.740343000000053],
            [74.604131000000052, 34.752475453000045],
            [74.604131000000052, 34.754162000000065],
            [74.60035173600005, 34.755255994000038],
            [74.574959000000035, 34.762606500000061],
            [74.560492826000029, 34.77455671000007],
            [74.557301500000051, 34.777193000000068],
            [74.541180000000054, 34.778728000000058],
            [74.537991932000068, 34.776177587000063],
            [74.525826000000052, 34.766445000000033],
            [74.505098000000032, 34.770283500000062],
            [74.478996000000052, 34.777960500000063],
            [74.474390000000085, 34.770283500000062],
            [74.464410000000044, 34.777961000000062],
            [74.455965000000049, 34.781799000000035],
            [74.44291400000003, 34.779496000000051],
            [74.435237000000029, 34.774122000000034],
            [74.419115000000033, 34.780264000000045],
            [74.393013500000052, 34.787173000000053],
            [74.369982500000049, 34.787941000000046],
            [74.340042000000039, 34.787941000000046],
            [74.323920500000042, 34.781799000000035],
            [74.331597500000044, 34.757232500000043],
            [74.333133000000032, 34.74571700000007],
            [74.327759000000071, 34.728828000000078],
            [74.329294000000061, 34.715009000000066],
            [74.326991000000078, 34.700423000000058],
            [74.322385000000054, 34.691210000000069],
            [74.30319250000008, 34.693513000000053],
            [74.295515500000079, 34.69658400000003],
            [74.281697000000065, 34.686604000000045],
            [74.280161500000077, 34.671250000000043],
            [74.279394000000082, 34.651290000000074],
            [74.285535500000037, 34.640542000000039],
            [74.279394000000082, 34.635168000000078],
            [74.26173650000004, 34.639006000000052],
            [74.247150000000033, 34.639006000000052],
            [74.239473000000032, 34.636703000000068],
            [74.227958000000058, 34.635168000000078],
            [74.219513000000063, 34.636703000000068],
            [74.211068500000067, 34.642077000000029],
            [74.204159000000061, 34.628259000000071],
            [74.191876000000036, 34.614440000000059],
            [74.188037500000064, 34.603692000000024],
            [74.17038000000008, 34.604460000000074],
            [74.151955500000042, 34.596783000000073],
            [74.139672000000076, 34.592944000000045],
            [74.129692000000034, 34.579893000000027],
            [74.122782500000085, 34.579126000000031],
            [74.111267000000055, 34.587570000000028],
            [74.10128700000007, 34.593712000000039],
            [74.075953000000084, 34.581429000000071],
            [74.065205000000049, 34.592944000000045],
            [74.046780500000068, 34.569145500000047],
            [74.031426500000066, 34.566842000000065],
            [74.009163000000058, 34.566842000000065],
            [73.996879500000034, 34.561468000000048],
            [73.992273500000067, 34.551488000000063],
            [73.989202500000033, 34.542276000000072],
            [73.957727000000034, 34.536134000000061],
            [73.933928500000036, 34.52845700000006],
            [73.910129500000039, 34.511568000000068],
            [73.896051548000059, 34.500049410000031],
            [73.893240000000048, 34.497749000000056],
            [73.892760687000077, 34.492716453000071],
            [73.89170450000006, 34.48162700000006],
            [73.888633500000083, 34.468576000000041],
            [73.89016950000007, 34.460132000000044],
            [73.884795500000052, 34.456293000000073],
            [73.876350500000058, 34.447848500000077],
            [73.864835500000083, 34.437868000000037],
            [73.850248500000077, 34.431727000000024],
            [73.827985500000068, 34.430191000000036],
            [73.808792500000038, 34.426353000000063],
            [73.801115500000037, 34.414070000000038],
            [73.783458500000052, 34.40332200000006],
            [73.769675203000077, 34.390194888000053],
            [73.767337000000055, 34.387968000000058],
            [73.758256801000073, 34.37787957900008],
            [73.744878500000084, 34.377880000000062],
            [73.742226500000072, 34.392022000000054],
            [73.752833500000065, 34.412351000000058],
            [73.742226500000072, 34.431796000000077],
            [73.748414000000082, 34.445938500000068],
            [73.737807500000031, 34.461848000000032],
            [73.74753000000004, 34.475106000000039],
            [73.759904500000061, 34.486597000000074],
            [73.767859500000043, 34.514881000000059],
            [73.745762500000069, 34.528139000000067],
            [73.719246000000055, 34.527255000000025],
            [73.693613500000083, 34.532558000000051],
            [73.665329500000041, 34.54228100000006],
            [73.655268982000052, 34.56240319300008],
            [73.655268871000033, 34.562403480000057],
            [73.65674800000005, 34.562538000000075],
            [73.663531000000035, 34.571582000000035],
            [73.665792000000067, 34.580061000000057],
            [73.663389387000052, 34.58967003600003],
            [73.662400000000048, 34.593627000000026],
            [73.659009000000083, 34.599279000000024],
            [73.666334135000056, 34.607805305000056],
            [73.666339570000048, 34.607811630000072],
            [73.693489000000056, 34.639413000000047],
            [73.699707000000046, 34.64789200000007],
            [73.686141000000077, 34.655805000000043],
            [73.670148857000072, 34.660602643000061],
            [73.670125312000039, 34.660609707000049],
            [73.663531000000035, 34.662588000000028],
            [73.664661000000081, 34.669936000000064],
            [73.664661516000081, 34.669936756000027],
            [73.664662931000066, 34.669938832000071],
            [73.673140000000046, 34.682372000000044],
            [73.686706000000072, 34.691981000000055],
            [73.685576000000083, 34.705548000000078],
            [73.696315000000084, 34.713461000000052],
            [73.70366400000006, 34.719114000000047],
            [73.715534000000048, 34.736071000000038],
            [73.715534000000048, 34.744550000000061],
            [73.715537047000055, 34.744550871000058],
            [73.723447000000078, 34.746811000000037],
            [73.72796900000003, 34.75416000000007],
            [73.72796900000003, 34.764334000000076],
            [73.743797000000029, 34.773378000000037],
            [73.75058000000007, 34.776770000000056],
            [73.753517964000082, 34.778728643000079],
            [73.777712000000065, 34.794858000000033],
            [73.777713442000049, 34.794857628000045],
            [73.795235000000048, 34.790336000000025],
            [73.795235433000073, 34.790336275000072],
            [73.797287683000036, 34.791642492000051],
            [73.80145200000004, 34.794293000000039],
            [73.801454172000035, 34.794292791000032],
            [73.815596527000082, 34.792933070000061],
            [73.830847500000061, 34.79146650000007],
            [73.837630500000046, 34.802206500000068],
            [73.838196000000039, 34.814076500000056],
            [73.852890243000047, 34.81464191300006],
            [73.86080400000003, 34.811816000000078],
            [73.865326000000039, 34.808990000000051],
            [73.865326697000057, 34.808990627000071],
            [73.870978000000036, 34.814077000000054],
            [73.88737100000003, 34.814642000000049],
            [73.898111000000029, 34.819729000000052],
            [73.898111276000066, 34.819728920000045],
            [73.914110493000067, 34.815114170000072],
            [73.927504000000056, 34.811251000000027],
            [73.927504064000061, 34.811251059000028],
            [73.93428700000004, 34.817468000000076],
            [73.93428700000004, 34.831600000000037],
            [73.945592000000033, 34.834991000000059],
            [73.968202000000076, 34.835557000000051],
            [73.971593000000041, 34.850253000000066],
            [73.977811000000031, 34.866081000000065],
            [73.989935781000042, 34.865272348000076],
            [73.994769000000076, 34.864950000000078],
            [73.994769235000035, 34.864950118000024],
            [73.994771000000071, 34.864950000000078],
            [74.003815000000031, 34.86947200000003],
            [74.007771910000031, 34.874558884000066],
            [74.021901000000071, 34.873994000000039],
            [74.02190114800004, 34.873994074000052],
            [74.021903000000066, 34.873994000000039],
            [74.02868600000005, 34.877385500000059],
            [74.045079000000044, 34.890386500000034],
            [74.039991000000043, 34.899430500000051],
            [74.038861000000054, 34.914127000000065],
            [74.048470000000066, 34.922040500000037],
            [74.064297000000067, 34.930519500000059],
            [74.071645500000045, 34.937868000000037],
            [74.067123500000037, 34.956521500000065],
            [74.061838710000075, 34.976339658000029],
            [74.061829374000069, 34.976374668000062],
            [74.060340500000052, 34.981958000000077],
            [74.060906000000045, 34.988741000000061],
            [74.069384500000069, 34.996654500000034],
            [74.075602500000059, 34.997785000000079],
            [74.087472500000047, 34.998915500000066],
            [74.097082000000057, 35.000611500000048],
            [74.106691500000068, 35.010220500000059],
            [74.111213500000076, 35.017569000000037],
            [74.107821500000057, 35.02604800000006],
            [74.095386000000076, 35.031700500000056],
            [74.082950500000038, 35.037353000000053],
            [74.076733000000047, 35.047527500000058],
            [74.081255000000056, 35.062789500000065],
            [74.087472500000047, 35.082573500000024],
            [74.094821000000081, 35.091052500000046],
            [74.118561500000055, 35.101227000000051],
            [74.128577299000028, 35.105233168000041],
            [74.128388000000029, 35.104433500000027],
            [74.142053500000031, 35.099086000000057],
            [74.150966000000039, 35.087203000000045],
            [74.169385000000034, 35.078885000000071],
            [74.190180000000055, 35.084826000000078],
            [74.21038150000004, 35.084826000000078],
            [74.223453000000063, 35.08126100000004],
            [74.226423500000067, 35.068784000000051],
            [74.238901000000055, 35.052148000000045],
            [74.243060000000071, 35.045612000000062],
            [74.25732000000005, 35.039670000000058],
            [74.268014500000049, 35.042047000000025],
            [74.282274000000029, 35.046206000000041],
            [74.292969000000085, 35.062248000000068],
            [74.301287000000059, 35.065813000000048],
            [74.316141000000073, 35.066407500000025],
            [74.332777500000077, 35.062842500000045],
            [74.349414000000081, 35.056901000000039],
            [74.363079000000084, 35.057495000000074],
            [74.371992000000034, 35.063437000000079],
            [74.385063000000059, 35.077696500000059],
            [74.407641000000069, 35.096115000000054],
            [74.419524000000081, 35.106810000000053],
            [74.426654000000042, 35.107998500000065],
            [74.430813000000057, 35.12285200000008],
            [74.438822565000066, 35.130863435000037],
            [74.443884000000082, 35.135924000000045],
            [74.455767500000036, 35.13176500000003],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "09",
      properties: { name: "Poonch", count: 1303 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.706871500000034, 33.925336000000073],
            [73.719245500000056, 33.91296200000005],
            [73.740458500000045, 33.915613000000064],
            [73.736039000000062, 33.905891000000054],
            [73.749297500000068, 33.893516000000034],
            [73.771394000000043, 33.888213000000064],
            [73.783768500000065, 33.885561000000052],
            [73.791723500000046, 33.874955000000057],
            [73.811168500000065, 33.879374000000041],
            [73.825310500000057, 33.882910000000038],
            [73.830614000000082, 33.902355000000057],
            [73.843872000000033, 33.905891000000054],
            [73.865085000000079, 33.90058700000003],
            [73.885414000000083, 33.898820000000057],
            [73.895136500000035, 33.905007000000069],
            [73.909278500000084, 33.896168000000046],
            [73.917233500000066, 33.889981000000034],
            [73.922791002000054, 33.890307970000038],
            [73.932260000000042, 33.890865000000076],
            [73.94551800000005, 33.907658000000026],
            [73.961427000000072, 33.920033000000046],
            [73.974853000000053, 33.918355000000076],
            [73.982641000000058, 33.917381000000034],
            [73.997666000000038, 33.888213000000064],
            [74.00031800000005, 33.866116000000034],
            [74.024182000000053, 33.841368000000045],
            [74.050681928000074, 33.813101582000058],
            [74.050618500000041, 33.812959000000035],
            [74.04294150000004, 33.80451400000004],
            [74.027587500000038, 33.791463000000078],
            [74.00911724100007, 33.772310125000047],
            [74.006859000000077, 33.769967000000065],
            [74.011465000000044, 33.762290000000064],
            [74.011408470000049, 33.761837797000055],
            [74.009929500000055, 33.750007000000039],
            [74.002252500000054, 33.735421000000031],
            [73.991505000000075, 33.72774400000003],
            [73.984595500000069, 33.716228000000058],
            [73.978454000000056, 33.716228000000058],
            [73.977686500000061, 33.706248000000073],
            [73.989969500000029, 33.693197000000055],
            [73.999182000000076, 33.68091400000003],
            [74.001485000000059, 33.662489000000051],
            [73.999182000000076, 33.640993000000037],
            [74.002252500000054, 33.62487150000004],
            [74.015303500000073, 33.612588000000073],
            [74.024516000000062, 33.598770000000059],
            [74.029538532000061, 33.592587703000049],
            [74.015860751000048, 33.596348341000066],
            [73.995898000000068, 33.601837000000046],
            [73.984407500000032, 33.608908000000042],
            [73.969381500000054, 33.608908000000042],
            [73.96054300000003, 33.615979000000038],
            [73.948168500000065, 33.613328000000024],
            [73.929607500000031, 33.606257000000028],
            [73.904858500000046, 33.597418000000062],
            [73.904301903000032, 33.599870060000057],
            [73.90044000000006, 33.616863000000023],
            [73.914582000000053, 33.619515000000035],
            [73.920769500000063, 33.631005000000073],
            [73.926072500000032, 33.64603100000005],
            [73.941098500000066, 33.649567000000047],
            [73.962311500000055, 33.65752150000003],
            [73.941098500000066, 33.676083000000062],
            [73.924305000000061, 33.694644000000039],
            [73.90044000000006, 33.700831000000051],
            [73.883646500000054, 33.695528000000024],
            [73.883646500000054, 33.71850900000004],
            [73.886298000000068, 33.734419000000059],
            [73.880111000000056, 33.752096000000051],
            [73.825311000000056, 33.737954000000059],
            [73.817356000000075, 33.752096000000051],
            [73.794087055000034, 33.766747087000056],
            [73.793491500000073, 33.767122000000029],
            [73.765207500000031, 33.781264000000078],
            [73.743994500000042, 33.806896000000052],
            [73.731620000000078, 33.807780000000037],
            [73.698033000000066, 33.806896000000052],
            [73.672400500000037, 33.828993000000025],
            [73.660910000000058, 33.828993000000025],
            [73.645000500000037, 33.82810900000004],
            [73.635278000000085, 33.817503000000045],
            [73.621136000000035, 33.827226000000053],
            [73.606110000000058, 33.81838700000003],
            [73.574290500000075, 33.81838700000003],
            [73.573437499000079, 33.818444159000023],
            [73.573935806000065, 33.820016056000043],
            [73.573935877000054, 33.820016279000072],
            [73.574224000000072, 33.820922000000053],
            [73.57483841800007, 33.822526409000034],
            [73.575817615000062, 33.825083348000078],
            [73.576156605000051, 33.82597090400003],
            [73.576156836000052, 33.825971510000045],
            [73.57648378600004, 33.826827540000068],
            [73.576839490000054, 33.827754009000046],
            [73.577181406000079, 33.828644567000026],
            [73.577588917000071, 33.82970868600006],
            [73.57805062500006, 33.830914329000052],
            [73.578595085000075, 33.832336057000077],
            [73.578820524000037, 33.832926756000063],
            [73.578820716000052, 33.832927262000055],
            [73.579040366000072, 33.833502790000068],
            [73.57925308800003, 33.834056242000031],
            [73.579447597000069, 33.834562307000056],
            [73.579460051000069, 33.834594710000033],
            [73.580077278000033, 33.836206454000035],
            [73.580094450000047, 33.836253151000051],
            [73.580094470000063, 33.836253204000059],
            [73.580260151000061, 33.836703745000079],
            [73.580709181000032, 33.83785830000005],
            [73.580709223000042, 33.837858405000077],
            [73.58075361300007, 33.837972544000024],
            [73.581523011000058, 33.83998164500008],
            [73.582445307000057, 33.842390004000038],
            [73.582836347000068, 33.843411112000069],
            [73.584398000000078, 33.847489000000053],
            [73.58602417600008, 33.850624844000038],
            [73.587099386000034, 33.852699364000046],
            [73.587100329000066, 33.852701183000079],
            [73.588322255000037, 33.855058780000036],
            [73.588341785000068, 33.855095229000028],
            [73.591125488000046, 33.860290527000075],
            [73.592285156000059, 33.862670898000033],
            [73.592287874000078, 33.862704939000025],
            [73.592475444000058, 33.865054229000066],
            [73.593216808000079, 33.875437916000067],
            [73.593230680000033, 33.875625125000056],
            [73.593230690000041, 33.875625266000043],
            [73.593495361000066, 33.879197152000074],
            [73.594005288000062, 33.885058822000076],
            [73.594005456000048, 33.885060757000076],
            [73.594196529000044, 33.887251416000026],
            [73.594573000000082, 33.891579000000036],
            [73.593569077000041, 33.894134338000072],
            [73.592576684000051, 33.896660328000053],
            [73.59167575400005, 33.898953511000059],
            [73.590911048000066, 33.900899957000036],
            [73.59028978300006, 33.902480023000066],
            [73.59028937800008, 33.902481054000077],
            [73.589011013000061, 33.905734944000073],
            [73.588355000000035, 33.907406000000037],
            [73.587439460000041, 33.908025064000071],
            [73.585513871000046, 33.909327809000047],
            [73.604342000000031, 33.933291000000054],
            [73.61406450000004, 33.953620000000058],
            [73.622019500000079, 33.970413000000065],
            [73.640581000000054, 33.977484000000061],
            [73.662677500000029, 33.973065000000076],
            [73.670368876000055, 33.967937814000038],
            [73.675936000000036, 33.964226000000053],
            [73.676819500000079, 33.953620000000058],
            [73.694497000000069, 33.94036200000005],
            [73.706871500000034, 33.925336000000073],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "10",
      properties: { name: "Sundhnoti", count: 453 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.672400500000037, 33.828993000000025],
            [73.698033000000066, 33.806896000000052],
            [73.731620000000078, 33.807780000000037],
            [73.743994500000042, 33.806896000000052],
            [73.765207500000031, 33.781264000000078],
            [73.793491500000073, 33.767122000000029],
            [73.794087055000034, 33.766747087000056],
            [73.817356000000075, 33.752096000000051],
            [73.825311000000056, 33.737954000000059],
            [73.880111000000056, 33.752096000000051],
            [73.886298000000068, 33.734419000000059],
            [73.883646500000054, 33.71850900000004],
            [73.883646500000054, 33.695528000000024],
            [73.90044000000006, 33.700831000000051],
            [73.924305000000061, 33.694644000000039],
            [73.941098500000066, 33.676083000000062],
            [73.962311500000055, 33.65752150000003],
            [73.941098500000066, 33.649567000000047],
            [73.926072500000032, 33.64603100000005],
            [73.920769500000063, 33.631005000000073],
            [73.914582000000053, 33.619515000000035],
            [73.90044000000006, 33.616863000000023],
            [73.904301903000032, 33.599870060000057],
            [73.895248844000037, 33.592209791000073],
            [73.895137000000034, 33.592115000000035],
            [73.886298500000066, 33.600953000000061],
            [73.878343500000028, 33.592115000000035],
            [73.858898500000066, 33.587695000000053],
            [73.842988500000047, 33.592115000000035],
            [73.820891500000073, 33.603605000000073],
            [73.800562500000069, 33.614212000000066],
            [73.78288450000008, 33.60714100000007],
            [73.765207500000031, 33.613328000000024],
            [73.750181500000053, 33.614212000000066],
            [73.750181500000053, 33.598302000000047],
            [73.740458500000045, 33.581508000000042],
            [73.727200500000038, 33.587695000000053],
            [73.69714950000008, 33.615979000000038],
            [73.674168500000064, 33.629237000000046],
            [73.62997450000006, 33.649567000000047],
            [73.615832500000067, 33.667244000000039],
            [73.596387132000075, 33.683154000000059],
            [73.596384897000064, 33.683155829000043],
            [73.600790500000073, 33.698826000000054],
            [73.600946108000073, 33.705675900000074],
            [73.600996846000044, 33.707872260000045],
            [73.601015592000067, 33.70869586200007],
            [73.601239351000061, 33.718526926000038],
            [73.601264109000056, 33.719674386000065],
            [73.601264112000081, 33.719674559000055],
            [73.601350878000062, 33.723696047000033],
            [73.598529000000042, 33.739525000000071],
            [73.590492352000069, 33.753035943000043],
            [73.583787366000081, 33.76424139900007],
            [73.583787493000045, 33.764241435000031],
            [73.583793000000071, 33.764243000000079],
            [73.581793926000046, 33.767549042000041],
            [73.58076991300004, 33.769242543000075],
            [73.580514589000074, 33.769665641000074],
            [73.580120088000058, 33.77031936800006],
            [73.579608866000058, 33.77116359300004],
            [73.578775200000052, 33.772542373000078],
            [73.578775000000064, 33.772542703000056],
            [73.578139045000057, 33.773595855000053],
            [73.576898371000084, 33.775647830000025],
            [73.576329452000039, 33.776587336000034],
            [73.575822524000046, 33.777424471000074],
            [73.57474290600004, 33.779209930000036],
            [73.574030858000071, 33.780384319000063],
            [73.572125715000084, 33.783536484000024],
            [73.571640376000062, 33.784342486000071],
            [73.571398000000045, 33.784745000000044],
            [73.570445479000057, 33.787480679000055],
            [73.570444920000057, 33.787482284000077],
            [73.569183726000063, 33.791108285000064],
            [73.568875735000063, 33.791996651000034],
            [73.568478680000055, 33.793135332000077],
            [73.568478313000071, 33.793136387000061],
            [73.568081021000069, 33.794275749000064],
            [73.567862571000035, 33.794906680000054],
            [73.567644182000038, 33.79553743300005],
            [73.567339493000077, 33.796413433000055],
            [73.567106730000035, 33.797079766000024],
            [73.566874964000078, 33.797743240000045],
            [73.566874000000041, 33.797746000000075],
            [73.566874964000078, 33.797749027000066],
            [73.567105497000057, 33.798472989000061],
            [73.567329830000062, 33.799177480000026],
            [73.567926756000077, 33.801060318000054],
            [73.568162121000057, 33.801805588000036],
            [73.568946556000071, 33.804279700000052],
            [73.569134711000061, 33.80487047500003],
            [73.569321513000034, 33.805459688000042],
            [73.569633336000038, 33.806443248000051],
            [73.569868775000032, 33.807185875000073],
            [73.569925512000054, 33.807364836000033],
            [73.570080223000048, 33.807852829000069],
            [73.570265846000041, 33.808438324000065],
            [73.570662021000032, 33.809687949000079],
            [73.570890451000082, 33.810408466000069],
            [73.571141229000034, 33.811199477000059],
            [73.571408451000082, 33.812042352000049],
            [73.571736014000066, 33.813075558000037],
            [73.572013345000073, 33.813950323000029],
            [73.572126411000056, 33.814308978000042],
            [73.572315643000081, 33.814909244000035],
            [73.572874340000055, 33.816671405000079],
            [73.572975411000073, 33.816986730000053],
            [73.573028935000082, 33.817153718000043],
            [73.573139997000055, 33.817505835000077],
            [73.573437499000079, 33.818444159000023],
            [73.574290500000075, 33.81838700000003],
            [73.606110000000058, 33.81838700000003],
            [73.621136000000035, 33.827226000000053],
            [73.635278000000085, 33.817503000000045],
            [73.645000500000037, 33.82810900000004],
            [73.660910000000058, 33.828993000000025],
            [73.672400500000037, 33.828993000000025],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "11",
      properties: { name: "Awaran", count: 970 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [65.659301758000083, 27.400085449000073],
            [65.686706543000071, 27.391906738000046],
            [65.686709325000038, 27.391907408000066],
            [65.686709639000071, 27.391907313000047],
            [65.68694400000004, 27.391837000000066],
            [65.701811000000077, 27.386306000000047],
            [65.722900000000038, 27.379391000000055],
            [65.736729000000082, 27.374551000000054],
            [65.752979000000039, 27.367636000000061],
            [65.763350000000059, 27.364179000000036],
            [65.778563000000076, 27.352770000000078],
            [65.79342900000006, 27.341015000000027],
            [65.805184000000054, 27.328223000000037],
            [65.815457328000036, 27.31838655200005],
            [65.82143300000007, 27.312665000000038],
            [65.821786975000066, 27.312193016000037],
            [65.830816862000063, 27.300152719000039],
            [65.834899902000075, 27.294677734000061],
            [65.835249371000032, 27.294122168000058],
            [65.837280273000033, 27.290893555000025],
            [65.847473145000038, 27.273681641000053],
            [65.853698730000076, 27.258728027000075],
            [65.863708496000072, 27.239685059000067],
            [65.864074707000043, 27.224487305000025],
            [65.868896484000061, 27.20788574200003],
            [65.877929687000062, 27.186279297000056],
            [65.880310059000067, 27.174499512000068],
            [65.888305664000029, 27.166076660000044],
            [65.898681641000053, 27.151916504000042],
            [65.906921387000068, 27.14288330100004],
            [65.91685681000007, 27.133923134000042],
            [65.91687011700003, 27.133911133000026],
            [65.938720703000058, 27.134887695000032],
            [65.962406608000038, 27.137078538000026],
            [65.973693848000039, 27.138122559000067],
            [65.982910156000059, 27.140502930000025],
            [65.98542212600006, 27.141038817000037],
            [65.987487793000071, 27.14147949200003],
            [66.001153171000055, 27.146653215000072],
            [66.002255679000029, 27.147070626000072],
            [66.002319336000028, 27.147094727000024],
            [66.006669226000042, 27.153358567000055],
            [66.00670914300008, 27.153416047000064],
            [66.006896973000039, 27.153686523000033],
            [66.014099121000072, 27.164123535000044],
            [66.018047329000069, 27.171077095000044],
            [66.019333823000068, 27.173342860000048],
            [66.022277832000043, 27.178527832000043],
            [66.034729004000042, 27.192077637000068],
            [66.041495557000076, 27.193472270000029],
            [66.042259896000076, 27.193629805000057],
            [66.042260071000044, 27.193629841000075],
            [66.042714529000079, 27.193723508000062],
            [66.042724609000061, 27.193725586000028],
            [66.042857078000054, 27.193629841000075],
            [66.048889160000044, 27.189270020000038],
            [66.048890824000068, 27.189263237000034],
            [66.049657344000082, 27.18613930500004],
            [66.052124024000079, 27.17608642600004],
            [66.051696778000064, 27.153320312000062],
            [66.051255544000071, 27.144073607000053],
            [66.050292969000054, 27.12390136700003],
            [66.050278087000038, 27.122502481000026],
            [66.050109864000035, 27.106689453000058],
            [66.049316406000059, 27.093872070000032],
            [66.046430962000045, 27.081284843000049],
            [66.045104981000065, 27.075500489000035],
            [66.044988060000037, 27.072133196000038],
            [66.044494629000042, 27.057922364000035],
            [66.041415577000066, 27.040454662000059],
            [66.04132080100004, 27.03991699200003],
            [66.03991699200003, 27.029479980000076],
            [66.039757590000079, 27.028278336000028],
            [66.038330078000058, 27.017517090000069],
            [66.035522461000028, 27.001892090000069],
            [66.033081055000082, 26.989685059000067],
            [66.02749723800008, 26.974639774000025],
            [66.026489258000083, 26.971923828000058],
            [66.024093269000048, 26.963016998000057],
            [66.023681641000053, 26.961486816000047],
            [66.023679395000045, 26.961465047000047],
            [66.023632426000063, 26.961009810000064],
            [66.022094727000081, 26.946105957000043],
            [66.019287109000061, 26.928710937000062],
            [66.01788330100004, 26.913696289000029],
            [66.014755834000027, 26.902896102000057],
            [66.013880264000079, 26.899872465000044],
            [66.013305664000029, 26.897888184000067],
            [66.00811767600004, 26.878906250000057],
            [66.007506070000034, 26.876626626000075],
            [66.006976110000039, 26.874651325000059],
            [66.004089356000065, 26.863891602000024],
            [66.000915528000064, 26.846313477000024],
            [65.996924714000045, 26.833687676000068],
            [65.987121582000043, 26.802673340000069],
            [65.978515625000057, 26.771728516000053],
            [65.974487305000082, 26.757690430000025],
            [65.967712403000064, 26.734924316000047],
            [65.959472656000059, 26.70147705100004],
            [65.955876836000073, 26.693711079000025],
            [65.955137490000084, 26.692114296000057],
            [65.950683594000054, 26.68249511700003],
            [65.949068777000036, 26.677020493000043],
            [65.948536458000035, 26.675215800000046],
            [65.945678711000028, 26.665527344000054],
            [65.940124512000068, 26.645080567000036],
            [65.934875488000046, 26.629516602000024],
            [65.930891786000075, 26.616042314000026],
            [65.930725098000039, 26.615478516000053],
            [65.930725837000068, 26.615475352000033],
            [65.931849440000065, 26.610664924000048],
            [65.932483252000054, 26.607951417000038],
            [65.932581448000064, 26.607531018000032],
            [65.932678223000039, 26.607116699000073],
            [65.930820873000073, 26.603894766000053],
            [65.930725941000048, 26.603730087000031],
            [65.930580411000051, 26.603477639000062],
            [65.929687500000057, 26.601928711000028],
            [65.927307129000042, 26.598083496000072],
            [65.926879883000083, 26.587280273000033],
            [65.923522949000073, 26.580688477000024],
            [65.924499512000068, 26.570495605000076],
            [65.926112869000065, 26.562673265000058],
            [65.926513672000056, 26.560729980000076],
            [65.926861559000031, 26.560075952000034],
            [65.931091309000067, 26.552124024000022],
            [65.931349030000035, 26.551429533000032],
            [65.935848232000069, 26.539305366000065],
            [65.936889648000033, 26.536499023000033],
            [65.943547599000055, 26.527713274000064],
            [65.948730469000054, 26.520874023000033],
            [65.957092285000044, 26.505676270000038],
            [65.969116211000028, 26.488098145000038],
            [65.982299805000082, 26.468078613000046],
            [65.994323730000076, 26.450073243000077],
            [66.000915527000075, 26.44030761700003],
            [66.008911133000083, 26.427917480000076],
            [66.020343930000081, 26.409797576000074],
            [66.025085449000073, 26.402282715000069],
            [66.028680098000052, 26.396996467000065],
            [66.036499023000033, 26.385498047000056],
            [66.054504395000038, 26.355285645000038],
            [66.072875977000081, 26.320495605000076],
            [66.091125488000046, 26.293884277000075],
            [66.103271484000061, 26.270324707000043],
            [66.108664151000028, 26.270082340000044],
            [66.108703613000046, 26.270080566000047],
            [66.108620793000057, 26.270505021000076],
            [66.108563344000061, 26.270799447000059],
            [66.10772705100004, 26.275085449000073],
            [66.104309082000043, 26.283081055000025],
            [66.099121094000054, 26.299072266000053],
            [66.091491699000073, 26.319702149000022],
            [66.090881348000039, 26.333679199000073],
            [66.090881348000039, 26.346130371000072],
            [66.088317871000072, 26.353698730000076],
            [66.08428955100004, 26.361877442000036],
            [66.081726074000073, 26.377075195000032],
            [66.080688476000034, 26.38311767600004],
            [66.080688476000034, 26.386901856000065],
            [66.080505371000072, 26.399719238000046],
            [66.081723621000037, 26.410308648000068],
            [66.082048247000046, 26.413130390000049],
            [66.082092285000044, 26.413513184000067],
            [66.096313477000081, 26.421508789000029],
            [66.099829484000054, 26.421593854000037],
            [66.102541159000054, 26.421659459000068],
            [66.103873458000066, 26.421691692000024],
            [66.103881836000028, 26.421691895000038],
            [66.104311944000074, 26.422192781000035],
            [66.104579667000053, 26.42250456000005],
            [66.108703613000046, 26.427307129000042],
            [66.109518715000036, 26.431790190000072],
            [66.10968017600004, 26.43267822200005],
            [66.110717773000033, 26.438293457000043],
            [66.111087619000045, 26.440180948000034],
            [66.112487793000071, 26.447326660000044],
            [66.112658373000045, 26.449397999000041],
            [66.112915039000029, 26.452514648000033],
            [66.113281250000057, 26.457702637000068],
            [66.113891602000081, 26.467712402000075],
            [66.109774074000029, 26.472482709000076],
            [66.109770265000066, 26.472487124000054],
            [66.10975598400006, 26.472503668000058],
            [66.108886719000054, 26.47351074200003],
            [66.107286661000046, 26.477963078000073],
            [66.106079102000081, 26.48132324200003],
            [66.106079102000081, 26.490478516000053],
            [66.107482910000044, 26.49890136700003],
            [66.110107422000056, 26.507080078000058],
            [66.113788551000027, 26.516399392000039],
            [66.114929199000073, 26.519287109000061],
            [66.117664600000069, 26.524825451000027],
            [66.117664620000028, 26.524825493000037],
            [66.117664633000061, 26.524825518000057],
            [66.117667420000032, 26.524831161000066],
            [66.119873047000056, 26.529296875000057],
            [66.126708984000061, 26.538879395000038],
            [66.13647461000005, 26.548889160000044],
            [66.145690918000071, 26.556884766000053],
            [66.155090332000043, 26.559692383000026],
            [66.163330078000058, 26.565490723000039],
            [66.170715332000043, 26.570312500000057],
            [66.173803347000046, 26.570597211000063],
            [66.17723699000004, 26.570913787000052],
            [66.179263000000049, 26.571076000000062],
            [66.182189176000065, 26.570821358000046],
            [66.187215000000037, 26.570384000000047],
            [66.187280457000043, 26.570316116000072],
            [66.194657826000082, 26.562665278000054],
            [66.19839535300008, 26.558811196000079],
            [66.198536542000056, 26.558665608000069],
            [66.202843363000056, 26.554250892000027],
            [66.210083008000083, 26.546875000000057],
            [66.220750000000066, 26.536503000000039],
            [66.222318598000072, 26.517234715000029],
            [66.222466415000042, 26.515418960000034],
            [66.22248623400003, 26.515079825000043],
            [66.222492053000053, 26.515072641000074],
            [66.230431000000067, 26.50504200000006],
            [66.242199090000042, 26.493632000000048],
            [66.244011822000061, 26.490986302000067],
            [66.24829105200007, 26.484740728000077],
            [66.248477183000034, 26.484469067000077],
            [66.24854287800008, 26.484377320000078],
            [66.248533479000059, 26.484368026000027],
            [66.248464094000042, 26.484464926000044],
            [66.24829105200007, 26.484128307000049],
            [66.248046201000079, 26.483652000000063],
            [66.236312202000079, 26.468008000000054],
            [66.230424198000037, 26.45181387100007],
            [66.230055201000027, 26.450799000000075],
            [66.230055201000027, 26.437501000000054],
            [66.229160798000066, 26.422749343000078],
            [66.229155533000039, 26.422748291000062],
            [66.228485000000035, 26.411689000000024],
            [66.230050000000062, 26.396044000000074],
            [66.228656155000067, 26.39265941900004],
            [66.224574000000075, 26.382747000000052],
            [66.224462939000034, 26.382524877000037],
            [66.22129420400006, 26.376187409000067],
            [66.219406948000028, 26.372412897000061],
            [66.21842186300006, 26.370442726000078],
            [66.217534000000057, 26.368667000000073],
            [66.217518346000077, 26.368658761000063],
            [66.202672000000064, 26.36084500000004],
            [66.200807786000041, 26.359912851000047],
            [66.197569525000063, 26.35829364600005],
            [66.18077100000005, 26.349894000000063],
            [66.174637266000047, 26.344101151000075],
            [66.166687011000079, 26.336730957000043],
            [66.166687011000079, 26.308471680000025],
            [66.166501395000068, 26.307157209000025],
            [66.16356200000007, 26.28731700000003],
            [66.162218387000053, 26.278808785000024],
            [66.161215000000084, 26.272455000000036],
            [66.164604015000066, 26.256187555000054],
            [66.165126000000043, 26.253682000000026],
            [66.165905863000035, 26.239659399000061],
            [66.165909000000056, 26.239603000000045],
            [66.172949000000074, 26.224741000000051],
            [66.181505940000079, 26.211191752000047],
            [66.18233500000008, 26.209879000000058],
            [66.183997229000056, 26.206435843000065],
            [66.193286000000057, 26.187195000000031],
            [66.202672000000064, 26.162164000000075],
            [66.202992276000032, 26.15720146700005],
            [66.203148906000081, 26.154774544000077],
            [66.203418364000072, 26.150599413000066],
            [66.204237000000035, 26.137915000000078],
            [66.210495000000037, 26.111320000000035],
            [66.210652604000074, 26.110006296000051],
            [66.211600943000064, 26.102101449000031],
            [66.212841000000083, 26.091765000000066],
            [66.218317001000059, 26.073774001000061],
            [66.22222800000003, 26.052655000000073],
            [66.222964963000038, 26.046906458000024],
            [66.224455443000068, 26.035280259000046],
            [66.226139000000046, 26.022148000000072],
            [66.234743000000037, 25.990860000000055],
            [66.236963721000052, 25.979438393000066],
            [66.240218000000084, 25.962701000000038],
            [66.245694000000071, 25.926719000000048],
            [66.250195919000078, 25.913213244000076],
            [66.252734000000032, 25.905599000000052],
            [66.254298000000063, 25.892302000000029],
            [66.255264385000032, 25.888049758000022],
            [66.258209000000079, 25.875093000000049],
            [66.25977400000005, 25.846934000000033],
            [66.255080000000078, 25.832854000000054],
            [66.24100100000004, 25.803912000000025],
            [66.22379200000006, 25.765584000000047],
            [66.208225105000054, 25.72472100300007],
            [66.20501900000005, 25.716305000000034],
            [66.195491410000045, 25.695485503000043],
            [66.186367578000045, 25.675548287000026],
            [66.183899000000054, 25.670154000000025],
            [66.180560789000083, 25.663581739000051],
            [66.17700736300003, 25.656585763000066],
            [66.16165040900006, 25.62635103100007],
            [66.158869000000038, 25.620875000000069],
            [66.153519660000029, 25.610176320000051],
            [66.132274000000052, 25.56768500000004],
            [66.12664077200003, 25.558874253000056],
            [66.120325382000033, 25.548996557000066],
            [66.101767000000052, 25.519970000000058],
            [66.085494191000066, 25.493017470000041],
            [66.080829103000042, 25.485290720000023],
            [66.077924550000034, 25.480479931000048],
            [66.076737000000037, 25.478513000000078],
            [66.076724422000041, 25.478503647000025],
            [66.050939880000044, 25.459330482000041],
            [66.046955456000035, 25.456367698000065],
            [66.046231000000034, 25.455829000000051],
            [66.017248643000073, 25.459544541000071],
            [66.015724000000034, 25.459740000000068],
            [66.00693728400006, 25.464587723000022],
            [65.993040000000065, 25.472255000000075],
            [65.97505701700004, 25.487893897000049],
            [65.975050000000067, 25.487900000000025],
            [65.955494000000044, 25.477731000000063],
            [65.949488257000041, 25.477530860000059],
            [65.932112710000069, 25.476951823000036],
            [65.926696777000075, 25.48468017600004],
            [65.926577490000057, 25.484736013000031],
            [65.926553000000069, 25.484771000000023],
            [65.924820993000083, 25.485558203000039],
            [65.917948000000081, 25.48868200000004],
            [65.914229614000078, 25.491703356000073],
            [65.905433000000073, 25.498851000000059],
            [65.893700000000081, 25.498851000000059],
            [65.889436255000078, 25.497095227000045],
            [65.880402000000061, 25.493375000000071],
            [65.874833825000053, 25.488734656000076],
            [65.871016000000054, 25.485553000000039],
            [65.849886982000044, 25.485553000000039],
            [65.841292000000067, 25.485553000000039],
            [65.834008667000035, 25.486463453000056],
            [65.823194570000055, 25.487815269000066],
            [65.816261000000054, 25.48868200000004],
            [65.795142000000055, 25.497286000000031],
            [65.772940196000036, 25.506963765000023],
            [65.764635000000055, 25.510584000000051],
            [65.73960500000004, 25.518406000000027],
            [65.727288298000076, 25.515121477000037],
            [65.716138000000058, 25.512148000000025],
            [65.687197000000083, 25.502762000000075],
            [65.662888276000047, 25.491823113000066],
            [65.655908000000068, 25.48868200000004],
            [65.61132200000003, 25.467562000000044],
            [65.577687000000083, 25.44722500000006],
            [65.575224816000059, 25.44606224100005],
            [65.55460052400008, 25.436322484000073],
            [65.549528000000066, 25.43392700000004],
            [65.543989691000036, 25.432786852000049],
            [65.523301180000033, 25.428527796000026],
            [65.52293300000008, 25.42845200000005],
            [65.522765164000077, 25.428527796000026],
            [65.498718225000061, 25.439387543000066],
            [65.498684000000083, 25.439403000000027],
            [65.498718225000061, 25.439461663000031],
            [65.498742441000047, 25.439503170000023],
            [65.50413886900003, 25.448752780000063],
            [65.50416000000007, 25.448789000000033],
            [65.511472810000043, 25.453520850000075],
            [65.51163819900006, 25.453627868000069],
            [65.512071180000078, 25.453908034000051],
            [65.517457000000036, 25.457393000000025],
            [65.533101000000045, 25.462869000000069],
            [65.540108381000039, 25.470654758000023],
            [65.540639846000033, 25.474587672000041],
            [65.54401828500005, 25.499588637000045],
            [65.54539730700003, 25.516130732000079],
            [65.545400511000082, 25.516169170000069],
            [65.545431022000059, 25.516175094000062],
            [65.54561700000005, 25.518406000000027],
            [65.54561700000005, 25.534050000000036],
            [65.54561700000005, 25.548130000000072],
            [65.540924000000075, 25.555952000000048],
            [65.529190000000028, 25.561427000000037],
            [65.516675000000077, 25.570814000000041],
            [65.518240000000048, 25.579418000000032],
            [65.517483589000051, 25.588486231000047],
            [65.517535014000032, 25.588477271000045],
            [65.517517090000069, 25.588684082000043],
            [65.515075683000077, 25.600524902000075],
            [65.509704590000069, 25.608276368000077],
            [65.504397967000045, 25.619466418000059],
            [65.504126285000041, 25.620048638000071],
            [65.504161967000073, 25.620088785000064],
            [65.50688198000006, 25.623149180000041],
            [65.510498047000056, 25.627075195000032],
            [65.519897461000028, 25.627075195000032],
            [65.524803327000029, 25.632750609000027],
            [65.526028285000052, 25.63412863700006],
            [65.534632285000043, 25.63960363700005],
            [65.534622685000045, 25.639620437000076],
            [65.534666000000072, 25.639648000000022],
            [65.534656885000061, 25.639663951000045],
            [65.534729004000042, 25.639709473000039],
            [65.531677246000072, 25.645080567000036],
            [65.530883789000029, 25.652282715000069],
            [65.535522461000028, 25.662475586000028],
            [65.535522461000028, 25.674072265000063],
            [65.538696289000029, 25.68267822200005],
            [65.534208934000048, 25.687037368000063],
            [65.53231900000003, 25.688927000000035],
            [65.532291380000061, 25.688907877000076],
            [65.532288018000031, 25.688905550000072],
            [65.522151000000065, 25.681887000000074],
            [65.508853000000045, 25.674065000000041],
            [65.497120000000052, 25.670154000000025],
            [65.486951000000033, 25.66546100000005],
            [65.47208900000004, 25.66546100000005],
            [65.463485000000048, 25.661550000000034],
            [65.452534000000071, 25.653728000000058],
            [65.438454000000036, 25.64121300000005],
            [65.427503000000058, 25.637302000000034],
            [65.411859000000049, 25.631826000000046],
            [65.400908000000072, 25.627133000000072],
            [65.393086000000039, 25.627133000000072],
            [65.379788500000075, 25.627133000000072],
            [65.379788000000076, 25.627133000000072],
            [65.372749000000056, 25.627133000000072],
            [65.363307479000071, 25.624911424000061],
            [65.359451000000035, 25.62400400000007],
            [65.351629000000059, 25.616182000000038],
            [65.351629000000059, 25.602884000000074],
            [65.350065000000029, 25.595062000000041],
            [65.34354830400008, 25.590323480000052],
            [65.341460000000041, 25.588805000000036],
            [65.335203000000035, 25.595844500000055],
            [65.326598000000047, 25.601320000000044],
            [65.316430000000082, 25.606013000000075],
            [65.305479000000048, 25.612271000000078],
            [65.306261000000063, 25.602102000000059],
            [65.29843900000003, 25.597409000000027],
            [65.287488000000053, 25.595062000000041],
            [65.276004871000055, 25.588277152000046],
            [65.270279000000073, 25.584894000000077],
            [65.265586000000042, 25.571596000000056],
            [65.256199000000038, 25.561427000000037],
            [65.250887573000057, 25.561018509000064],
            [65.250739056000043, 25.561007087000064],
            [65.246031000000073, 25.560645000000079],
            [65.235080000000039, 25.568467000000055],
            [65.222564000000034, 25.570032000000026],
            [65.20678504600005, 25.564771862000043],
            [65.206784967000033, 25.564771988000075],
            [65.193623000000059, 25.585676000000035],
            [65.18345400000004, 25.596627000000069],
            [65.172503000000063, 25.60366700000003],
            [65.149037000000078, 25.595062000000041],
            [65.122442000000035, 25.588022000000024],
            [65.090371000000061, 25.58098300000006],
            [65.060647000000074, 25.572378000000072],
            [65.02701200000007, 25.566121000000066],
            [64.987902000000076, 25.559081000000049],
            [64.956445543000029, 25.556154666000054],
            [64.954267000000073, 25.555952000000048],
            [64.919850000000054, 25.554387000000077],
            [64.875264000000072, 25.543436000000042],
            [64.806429000000037, 25.541090000000054],
            [64.79644922600005, 25.539565364000055],
            [64.750110000000063, 25.532486000000063],
            [64.689098000000058, 25.521535000000029],
            [64.628868000000068, 25.512148000000025],
            [64.558469000000059, 25.504326000000049],
            [64.502150000000029, 25.496504000000073],
            [64.475555000000043, 25.48868200000004],
            [64.437227000000064, 25.466780000000028],
            [64.413020221000068, 25.450275954000062],
            [64.402809000000047, 25.443314000000044],
            [64.37308500000006, 25.433145000000025],
            [64.341797000000042, 25.436274000000026],
            [64.305815000000052, 25.450354000000061],
            [64.280785000000037, 25.463651000000027],
            [64.258101000000067, 25.466780000000028],
            [64.23385200000007, 25.467562000000044],
            [64.199435000000051, 25.467562000000044],
            [64.176681000000031, 25.462921000000051],
            [64.174944000000039, 25.467658000000029],
            [64.144096000000047, 25.468111000000079],
            [64.132755000000031, 25.469926000000044],
            [64.125589000000048, 25.481231000000037],
            [64.144680000000051, 25.503544000000034],
            [64.158760000000029, 25.524663000000032],
            [64.164236000000074, 25.542654000000027],
            [64.17152800000008, 25.556631000000039],
            [64.17362200000008, 25.560645000000079],
            [64.189266000000032, 25.577854000000059],
            [64.200217000000066, 25.597409000000027],
            [64.212733000000071, 25.61070600000005],
            [64.22837700000008, 25.613053000000036],
            [64.237763000000029, 25.614618000000064],
            [64.240892000000031, 25.624786000000029],
            [64.244803000000047, 25.631044000000031],
            [64.254972000000066, 25.621657000000027],
            [64.267487000000074, 25.615400000000022],
            [64.277656000000036, 25.613053000000036],
            [64.290171000000043, 25.62244000000004],
            [64.301904000000036, 25.617746000000068],
            [64.314420000000041, 25.618529000000024],
            [64.324588000000062, 25.623222000000055],
            [64.333975000000066, 25.629480000000058],
            [64.355877000000078, 25.636519000000078],
            [64.355877000000078, 25.648253000000068],
            [64.351966000000061, 25.658421000000033],
            [64.340233000000069, 25.670154000000025],
            [64.326153000000033, 25.676412000000028],
            [64.31129100000004, 25.685799000000031],
            [64.301904000000036, 25.685799000000031],
            [64.290171000000043, 25.699878000000069],
            [64.287661473000071, 25.704212796000036],
            [64.281567000000052, 25.714740000000063],
            [64.277545000000032, 25.729009000000076],
            [64.277138000000036, 25.742019000000028],
            [64.284456000000034, 25.75096300000007],
            [64.290961000000038, 25.755435000000034],
            [64.30112500000007, 25.756655000000023],
            [64.314135000000078, 25.766819000000055],
            [64.321453000000076, 25.77698300000003],
            [64.328364000000079, 25.787960000000055],
            [64.327551000000028, 25.798123000000032],
            [64.326274170000033, 25.803358786000047],
            [64.323486000000059, 25.814792000000068],
            [64.323892000000058, 25.829428000000064],
            [64.328364000000079, 25.842845000000068],
            [64.332430000000045, 25.850569000000064],
            [64.340154000000041, 25.855041000000028],
            [64.350318000000073, 25.856261000000075],
            [64.35885600000006, 25.857887000000062],
            [64.359669000000054, 25.866018000000054],
            [64.359262000000058, 25.879435000000058],
            [64.353571000000045, 25.887972000000048],
            [64.352758000000051, 25.902608000000043],
            [64.353571000000045, 25.920090000000073],
            [64.360482000000047, 25.932694000000026],
            [64.362108000000035, 25.95098900000005],
            [64.367394000000047, 25.969690000000071],
            [64.369122840000045, 25.982142409000062],
            [64.369426000000033, 25.984326000000067],
            [64.371138341000062, 25.988671963000058],
            [64.374712000000045, 25.997742000000073],
            [64.384469000000081, 26.013598000000059],
            [64.39513343200008, 26.022263159000033],
            [64.397479000000033, 26.024169000000029],
            [64.413334000000077, 26.034332000000063],
            [64.431629000000044, 26.035552000000052],
            [64.444233000000054, 26.035959000000048],
            [64.460088000000042, 26.037992000000031],
            [64.472692000000052, 26.045310000000029],
            [64.48854700000004, 26.053034000000025],
            [64.501557000000048, 26.062385000000063],
            [64.512128000000075, 26.076208000000065],
            [64.517006000000038, 26.090844000000061],
            [64.519039000000078, 26.106699000000049],
            [64.519446000000073, 26.116457000000025],
            [64.514973000000055, 26.135158000000047],
            [64.505623000000071, 26.162398000000053],
            [64.497085000000084, 26.178660000000036],
            [64.487328000000048, 26.192076000000043],
            [64.473911000000044, 26.207932000000028],
            [64.462528000000077, 26.221348000000035],
            [64.447933000000035, 26.230105000000037],
            [64.446265000000039, 26.231106000000068],
            [64.437321000000054, 26.249807000000033],
            [64.428784000000064, 26.276233000000047],
            [64.423905000000047, 26.292496000000028],
            [64.421872000000064, 26.308758000000068],
            [64.42227900000006, 26.33111800000006],
            [64.425125000000037, 26.342095000000029],
            [64.431629000000044, 26.351446000000067],
            [64.442200000000071, 26.362423000000035],
            [64.462934000000075, 26.370554000000027],
            [64.481636000000037, 26.371774000000073],
            [64.508062000000052, 26.372181000000069],
            [64.538960000000031, 26.372587000000067],
            [64.577583000000061, 26.372994000000062],
            [64.590999000000068, 26.372994000000062],
            [64.606042000000059, 26.369335000000035],
            [64.638973000000078, 26.368522000000041],
            [64.653202500000077, 26.366895000000056],
            [64.676783000000057, 26.364863000000071],
            [64.69507800000008, 26.361610000000042],
            [64.719471000000055, 26.363236000000029],
            [64.749556000000041, 26.363643000000025],
            [64.780048000000079, 26.365269000000069],
            [64.828022000000033, 26.366895000000056],
            [64.846723000000054, 26.365676000000065],
            [64.863392000000033, 26.364049000000023],
            [64.888598000000059, 26.363236000000029],
            [64.905674000000033, 26.365269000000069],
            [64.91909000000004, 26.368115000000046],
            [64.933726000000036, 26.37462000000005],
            [64.934946000000082, 26.385597000000075],
            [64.934133000000031, 26.40145300000006],
            [64.928847500000074, 26.408364000000063],
            [64.919497000000035, 26.423407000000054],
            [64.915025000000071, 26.439262000000042],
            [64.91787000000005, 26.452679000000046],
            [64.925188000000048, 26.462436000000025],
            [64.94023100000004, 26.478292000000067],
            [64.958933000000059, 26.493741000000057],
            [64.978447000000074, 26.510816000000034],
            [64.999588000000074, 26.526265000000024],
            [65.026827500000081, 26.542528000000061],
            [65.044716000000051, 26.559603000000038],
            [65.081713000000036, 26.589281500000027],
            [65.107732000000055, 26.612455000000068],
            [65.128737268000066, 26.629259373000025],
            [65.13415800000007, 26.633596000000068],
            [65.180099000000041, 26.676691000000062],
            [65.184413828000061, 26.681378729000073],
            [65.21303000000006, 26.712468000000058],
            [65.237424000000033, 26.737674000000027],
            [65.257345000000043, 26.759628000000077],
            [65.274420000000077, 26.777923500000043],
            [65.280907447000061, 26.782512272000076],
            [65.291089000000056, 26.78971400000006],
            [65.296374000000071, 26.798658000000046],
            [65.302879000000075, 26.813294000000042],
            [65.307530345000032, 26.824922006000065],
            [65.30938400000008, 26.829556000000025],
            [65.319954000000052, 26.848664000000042],
            [65.329712000000029, 26.866146000000072],
            [65.335190546000035, 26.881896927000071],
            [65.336217000000033, 26.884848000000034],
            [65.335808457000041, 26.895825803000037],
            [65.335808557000064, 26.895826443000033],
            [65.338795000000061, 26.915077000000053],
            [65.341906000000051, 26.928214000000025],
            [65.34225200000003, 26.94411800000006],
            [65.341952822000053, 26.956374768000046],
            [65.341906000000051, 26.958293000000026],
            [65.344326500000079, 26.971430000000055],
            [65.351241000000073, 26.983531000000028],
            [65.356081000000074, 26.994594000000063],
            [65.362659927000038, 27.005832405000035],
            [65.364379000000042, 27.008769000000029],
            [65.383740000000046, 27.034699000000046],
            [65.402063000000055, 27.060629000000063],
            [65.425476531000072, 27.091038801000025],
            [65.432142000000056, 27.099696000000051],
            [65.45426800000007, 27.135306000000071],
            [65.464640000000031, 27.146715000000029],
            [65.477260081000054, 27.166671824000048],
            [65.479507000000069, 27.170225000000073],
            [65.495410000000049, 27.195809000000054],
            [65.509931000000051, 27.220356000000038],
            [65.52860000000004, 27.243174000000067],
            [65.543798614000082, 27.266815292000047],
            [65.547270000000083, 27.272215000000074],
            [65.557296000000065, 27.288119000000052],
            [65.56628500000005, 27.299873000000048],
            [65.56870500000008, 27.312665000000038],
            [65.575619000000074, 27.325457000000029],
            [65.58149700000007, 27.339978000000031],
            [65.581843000000049, 27.356919000000062],
            [65.58460800000006, 27.372822000000042],
            [65.581843000000049, 27.391837000000066],
            [65.58460800000006, 27.410161000000073],
            [65.597055000000069, 27.421570000000031],
            [65.598889991000078, 27.420266040000058],
            [65.598890016000041, 27.420266022000078],
            [65.61019200000004, 27.412235000000067],
            [65.63543100000004, 27.405667000000051],
            [65.646686353000064, 27.403056871000047],
            [65.653320312000062, 27.401489258000026],
            [65.659301758000083, 27.400085449000073],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "12",
      properties: { name: "Barkhan", count: 760 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.619193296000049, 30.150618622000025],
            [69.619226559000083, 30.150595946000067],
            [69.619226609000066, 30.150595998000028],
            [69.619193296000049, 30.150618622000025],
            [69.625611000000049, 30.157290001000035],
            [69.63341900000006, 30.16935600000005],
            [69.640937060000056, 30.176500605000058],
            [69.646905000000061, 30.182132000000024],
            [69.646905000000061, 30.210522000000026],
            [69.666517427000031, 30.232163987000035],
            [69.667488000000048, 30.233234999000047],
            [69.684522000000072, 30.249560000000031],
            [69.691620000000057, 30.261626000000035],
            [69.694484546000069, 30.26564324800006],
            [69.695853843000066, 30.267563553000059],
            [69.695853868000029, 30.267563589000076],
            [69.69727847200005, 30.269601948000059],
            [69.700137000000041, 30.27369200000004],
            [69.701026117000083, 30.27618147700008],
            [69.703686000000062, 30.283629000000076],
            [69.707897108000054, 30.288605421000057],
            [69.711494000000073, 30.292856000000029],
            [69.711502431000042, 30.292861932000051],
            [69.711501720000058, 30.304899339000031],
            [69.713615343000072, 30.307013434000055],
            [69.71859100000006, 30.312020000000075],
            [69.727819000000068, 30.316278000000068],
            [69.730656062000037, 30.325498701000072],
            [69.730658000000062, 30.325505000000078],
            [69.724988490000044, 30.334717580000074],
            [69.724979000000076, 30.334733000000028],
            [69.733497000000057, 30.344669000000067],
            [69.742988788000048, 30.355323526000063],
            [69.744352454000079, 30.356854241000065],
            [69.744383886000037, 30.356889523000063],
            [69.745596887000033, 30.356112708000069],
            [69.747850448000065, 30.354669510000065],
            [69.748663993000036, 30.354151837000074],
            [69.750513026000078, 30.352975263000076],
            [69.75758029800005, 30.348438438000073],
            [69.763682456000083, 30.344530569000028],
            [69.764581000000078, 30.343962000000033],
            [69.764582166000082, 30.343953258000056],
            [69.764709473000039, 30.343872070000032],
            [69.765013684000053, 30.341573581000034],
            [69.766807000000028, 30.327261000000078],
            [69.770155195000029, 30.309684858000026],
            [69.771260999000049, 30.303880000000049],
            [69.779916576000062, 30.296011038000074],
            [69.78350800000004, 30.292746000000079],
            [69.788394990000029, 30.292094343000031],
            [69.79227205400008, 30.291577354000026],
            [69.800292969000054, 30.290527344000054],
            [69.821289063000052, 30.289489746000072],
            [69.821335335000072, 30.289512333000062],
            [69.821364000000074, 30.289406001000032],
            [69.821576462000053, 30.289512297000044],
            [69.821700194000073, 30.28957420100005],
            [69.823268577000078, 30.29035828800005],
            [69.824526726000045, 30.290987363000056],
            [69.824531132000061, 30.290989566000064],
            [69.830249692000052, 30.29384884600006],
            [69.831505710000044, 30.294476855000028],
            [69.83917800100005, 30.298313000000064],
            [69.848556067000061, 30.301574889000051],
            [69.855673796000076, 30.304050584000038],
            [69.864786000000038, 30.307220000000029],
            [69.867558749000068, 30.307774351000035],
            [69.87035300000008, 30.308333000000061],
            [69.874390442000049, 30.314894353000057],
            [69.877892925000083, 30.320586329000037],
            [69.879260000000045, 30.322808000000066],
            [69.885859671000048, 30.32610758900006],
            [69.892621000000076, 30.329488000000026],
            [69.892745811000054, 30.329495798000039],
            [69.893088261000059, 30.329517194000061],
            [69.893133533000082, 30.329520022000054],
            [69.900610119000078, 30.329987152000058],
            [69.910307045000081, 30.330593006000072],
            [69.921672626000031, 30.330601000000058],
            [69.925029222000035, 30.330595292000055],
            [69.92936300000008, 30.330601000000058],
            [69.937749351000036, 30.328568095000037],
            [69.940898496000045, 30.327804722000053],
            [69.943135203000054, 30.327262530000041],
            [69.947847252000031, 30.326120299000024],
            [69.95060856200007, 30.325450940000053],
            [69.955514529000084, 30.324261702000058],
            [69.96461264900006, 30.322056260000068],
            [69.965018000000043, 30.321958000000052],
            [69.96501842300006, 30.321957847000078],
            [69.963536000000033, 30.319542000000069],
            [69.961084000000028, 30.291963000000067],
            [69.976406000000054, 30.29380100000003],
            [69.981922000000054, 30.298091001000046],
            [69.981937122000033, 30.298073987000066],
            [69.981955131000063, 30.298091001000046],
            [69.986834044000034, 30.292575000000056],
            [69.984995045000062, 30.279705000000035],
            [69.983313176000081, 30.276359351000053],
            [69.98069600000008, 30.27112500000004],
            [69.980608755000048, 30.270979596000075],
            [69.980562040000052, 30.27088666800006],
            [69.975189044000047, 30.261932000000058],
            [69.975189044000047, 30.253352000000064],
            [69.979470001000038, 30.249062000000038],
            [69.992417622000062, 30.252739000000076],
            [69.993269969000039, 30.253814654000053],
            [69.994845536000071, 30.255803000000071],
            [69.99995320000005, 30.26154484400007],
            [70.015017000000057, 30.278479000000061],
            [70.026681721000045, 30.278479000000061],
            [70.026681757000063, 30.278479000000061],
            [70.046273000000042, 30.278479000000061],
            [70.046433924000041, 30.278453399000057],
            [70.073239000000058, 30.274189000000035],
            [70.073852000000045, 30.255191000000025],
            [70.068516178000039, 30.246088504000056],
            [70.063433000000032, 30.23741700000005],
            [70.054985388000034, 30.228668882000079],
            [70.054985353000063, 30.228668846000062],
            [70.051789000000042, 30.225773000000061],
            [70.044113849000041, 30.224535216000049],
            [70.044120061000058, 30.224523700000077],
            [70.03804232400006, 30.223543533000054],
            [70.032867622000083, 30.222709000000066],
            [70.021835622000083, 30.217806000000053],
            [70.020880564000038, 30.215982449000023],
            [70.018288797000082, 30.211033832000055],
            [70.015094622000049, 30.204935000000035],
            [70.016315220000081, 30.193903547000048],
            [70.017468000000065, 30.183484999000029],
            [70.013221121000072, 30.183484999000029],
            [70.00990575000003, 30.183484999000029],
            [70.005211000000031, 30.183485000000076],
            [70.004183904000058, 30.180990521000069],
            [70.003406083000073, 30.179101451000065],
            [70.00227095300005, 30.176344591000031],
            [70.001573422000035, 30.172681455000031],
            [69.999660867000046, 30.166946823000046],
            [69.998204151000039, 30.159873041000026],
            [69.997228095000082, 30.155133337000052],
            [69.996630000000039, 30.152229000000034],
            [69.988061720000076, 30.139345601000059],
            [69.988061592000065, 30.13926955900007],
            [69.988629625000044, 30.134886429000062],
            [69.988050999000052, 30.121380943000077],
            [69.988051116000065, 30.12132790000004],
            [69.988663000000031, 30.105038000000036],
            [69.983402519000038, 30.087192779000077],
            [69.981922000000054, 30.082975000000033],
            [69.979997535000052, 30.078512243000034],
            [69.972116000000028, 30.060299000000043],
            [69.962049144000048, 30.053108277000035],
            [69.959246000000064, 30.051106000000061],
            [69.946309260000078, 30.038169252000046],
            [69.937128886000039, 30.02898887300006],
            [69.933505000000082, 30.025365000000079],
            [69.931947537000042, 30.02159413000004],
            [69.930689471000051, 30.018548151000061],
            [69.929280557000084, 30.015136943000073],
            [69.927573146000043, 30.011003026000026],
            [69.926329094000039, 30.007990974000052],
            [69.924680293000051, 30.003998962000026],
            [69.924068746000046, 30.002518308000049],
            [69.921861000000035, 29.997173000000032],
            [69.921335549000048, 29.995079176000047],
            [69.920627999000033, 29.992240994000042],
            [69.918427883000049, 29.983440533000078],
            [69.917049744000053, 29.977927976000046],
            [69.913857227000051, 29.965157911000063],
            [69.912668000000053, 29.960401000000047],
            [69.908782533000078, 29.950040036000075],
            [69.907795967000084, 29.947409265000033],
            [69.903475000000071, 29.935887000000037],
            [69.903422757000044, 29.935775981000063],
            [69.898761039000078, 29.925869712000065],
            [69.888766000000032, 29.904630000000054],
            [69.887651301000062, 29.903580937000072],
            [69.878830853000068, 29.895271476000062],
            [69.868129340000053, 29.885189924000031],
            [69.867956861000039, 29.885046166000052],
            [69.867928000000063, 29.885019000000057],
            [69.84586500000006, 29.866633000000036],
            [69.832995000000039, 29.851311000000067],
            [69.830163668000068, 29.845336816000042],
            [69.830149414000061, 29.845355083000072],
            [69.830147299000032, 29.845336816000042],
            [69.81734537400007, 29.818272506000028],
            [69.810932000000037, 29.804733000000056],
            [69.81004138600008, 29.802432418000024],
            [69.803577000000075, 29.785734000000048],
            [69.796922833000053, 29.778523693000068],
            [69.796223000000055, 29.77776700000004],
            [69.78948100000008, 29.765510000000063],
            [69.771708000000046, 29.755704000000037],
            [69.750871000000075, 29.73854300000005],
            [69.738001001000043, 29.720157000000029],
            [69.729393220000077, 29.717287913000064],
            [69.717826623000064, 29.713432614000055],
            [69.704906000000051, 29.709126000000026],
            [69.682842000000051, 29.696256000000062],
            [69.674573417000033, 29.694307048000042],
            [69.674572166000075, 29.694299894000039],
            [69.662034100000028, 29.691353000000049],
            [69.651823433000061, 29.684873546000063],
            [69.651364000000058, 29.684582000000034],
            [69.61785100000003, 29.696039000000042],
            [69.596892000000082, 29.703524000000073],
            [69.563955000000078, 29.705021000000045],
            [69.537007000000074, 29.705021000000045],
            [69.517319394000083, 29.702208619000032],
            [69.494247279000035, 29.698393385000031],
            [69.486887687000035, 29.696887741000069],
            [69.481731272000047, 29.695813691000069],
            [69.475626000000034, 29.694542000000069],
            [69.467391000000077, 29.689302000000055],
            [69.44268900000003, 29.679570000000069],
            [69.437610789000075, 29.675380502000053],
            [69.42682717100007, 29.66648407100007],
            [69.412747000000081, 29.654868000000079],
            [69.387296000000049, 29.651125000000036],
            [69.369331000000045, 29.642143000000033],
            [69.357926472000031, 29.637610331000076],
            [69.34231279200003, 29.631404758000031],
            [69.329230155000062, 29.626205133000042],
            [69.320940049000058, 29.622910274000049],
            [69.310943000000066, 29.618937000000074],
            [69.294223842000065, 29.614451320000057],
            [69.280253000000073, 29.610703000000058],
            [69.269279883000081, 29.610991929000079],
            [69.251807000000042, 29.611452001000032],
            [69.227854000000036, 29.617440000000045],
            [69.21226428600005, 29.623935859000028],
            [69.209888000000035, 29.624926000000073],
            [69.180695000000071, 29.635406000000046],
            [69.169466000000057, 29.645885000000078],
            [69.167848113000048, 29.647151230000077],
            [69.160948329000064, 29.652551305000031],
            [69.152250000000038, 29.659359000000052],
            [69.109656530000052, 29.675051260000032],
            [69.103593000000046, 29.677325000000053],
            [69.089371000000028, 29.687805000000026],
            [69.08413100000007, 29.707267000000058],
            [69.069160000000068, 29.720741000000032],
            [69.068484000000069, 29.724569000000031],
            [69.066914000000054, 29.733466000000078],
            [69.076645000000042, 29.739455000000078],
            [69.086377000000084, 29.747689000000037],
            [69.105091000000073, 29.752180000000067],
            [69.119313000000034, 29.758169000000066],
            [69.132039000000077, 29.763409000000024],
            [69.144764000000066, 29.766403000000025],
            [69.152998000000082, 29.770894000000055],
            [69.158238001000029, 29.779877000000056],
            [69.16272900000007, 29.790357000000029],
            [69.173209000000043, 29.797094000000072],
            [69.179253566000057, 29.803138629000046],
            [69.181443000000058, 29.805328000000031],
            [69.195666000000074, 29.812813000000062],
            [69.207643000000076, 29.821796000000063],
            [69.219570383000075, 29.826907671000072],
            [69.233842000000038, 29.833024000000023],
            [69.241214993000028, 29.837742731000048],
            [69.248201237000046, 29.842213942000058],
            [69.252556000000084, 29.845001000000025],
            [69.254721333000077, 29.845892152000033],
            [69.260687851000057, 29.848349597000038],
            [69.264646255000059, 29.849979619000067],
            [69.265281000000073, 29.85024100000004],
            [69.273752021000064, 29.85588866300003],
            [69.282498000000032, 29.860721000000069],
            [69.287353886000062, 29.862311099000067],
            [69.287355636000029, 29.86229632800007],
            [69.295011518000081, 29.864702713000042],
            [69.298943655000073, 29.865938655000036],
            [69.312417655000047, 29.869681655000079],
            [69.327389655000047, 29.869681655000079],
            [69.332868189000067, 29.874050429000079],
            [69.334897000000069, 29.875692000000072],
            [69.340137000000084, 29.884675000000072],
            [69.347230026000034, 29.88609351100007],
            [69.350392665000072, 29.886725996000052],
            [69.355108001000076, 29.887669000000074],
            [69.35780425300004, 29.887797294000052],
            [69.360722062000036, 29.88793613200005],
            [69.370828000000074, 29.888417000000061],
            [69.391787000000079, 29.889166000000046],
            [69.395530000000065, 29.895903000000033],
            [69.40077000000008, 29.907880001000024],
            [69.403016000000036, 29.919108000000051],
            [69.407507000000066, 29.928091001000041],
            [69.420232000000055, 29.931834000000038],
            [69.429215000000056, 29.936325000000068],
            [69.440443001000062, 29.949799000000041],
            [69.453189743000053, 29.958789016000026],
            [69.453188538000063, 29.958815216000062],
            [69.453188401000034, 29.958818195000049],
            [69.453839456000082, 29.959921820000034],
            [69.460654000000034, 29.971507000000031],
            [69.469637000000034, 29.982735000000048],
            [69.479368000000079, 29.991718000000049],
            [69.483111000000065, 30.00070099900006],
            [69.47787100000005, 30.007438000000036],
            [69.474128000000064, 30.013426001000028],
            [69.482363000000078, 30.022409000000039],
            [69.489100001000054, 30.026900000000069],
            [69.493591000000038, 30.033637000000056],
            [69.493685205000077, 30.035007490000055],
            [69.493968598000038, 30.039683106000041],
            [69.494035960000076, 30.040794494000068],
            [69.494339000000082, 30.045614000000057],
            [69.49957900000004, 30.058340000000044],
            [69.503322000000082, 30.065825000000075],
            [69.48984839700006, 30.070315867000033],
            [69.476376781000056, 30.069568000000061],
            [69.466645781000068, 30.068071000000032],
            [69.462902781000082, 30.062082000000032],
            [69.462154781000038, 30.05010500000003],
            [69.453919781000081, 30.044117000000028],
            [69.453917214000057, 30.044117155000038],
            [69.453917000000047, 30.044117000000028],
            [69.429215000000056, 30.045614000000057],
            [69.416490000000067, 30.047860000000071],
            [69.412129547000063, 30.065298816000052],
            [69.412131682000052, 30.06530080400006],
            [69.412131803000079, 30.065300916000069],
            [69.412123781000048, 30.065333000000066],
            [69.424748781000062, 30.077085001000057],
            [69.434039408000046, 30.087957254000059],
            [69.446064611000054, 30.10202961300007],
            [69.458031073000029, 30.116033234000042],
            [69.466478965000078, 30.123829784000066],
            [69.476183098000035, 30.132763724000029],
            [69.477927419000082, 30.134369602000049],
            [69.487846058000059, 30.143501022000066],
            [69.490373801000032, 30.145828145000053],
            [69.493907687000046, 30.149081555000066],
            [69.502211100000068, 30.156725945000062],
            [69.504785525000045, 30.159296338000047],
            [69.505675473000053, 30.160205227000063],
            [69.508325847000037, 30.16291201100006],
            [69.513645262000068, 30.168344641000033],
            [69.523461299000076, 30.178369599000064],
            [69.532710777000034, 30.187815939000075],
            [69.536584273000074, 30.191763250000065],
            [69.540301470000031, 30.195480228000065],
            [69.543580753000072, 30.198759319000033],
            [69.55321500000008, 30.208393000000058],
            [69.568120000000079, 30.220459000000062],
            [69.577347000000032, 30.227557000000047],
            [69.589413000000036, 30.233234999000047],
            [69.594381000000055, 30.224008000000026],
            [69.594378960000029, 30.224003106000055],
            [69.587342542000044, 30.207114510000054],
            [69.579476000000056, 30.194907000000057],
            [69.569539000000077, 30.192068000000063],
            [69.566700000000083, 30.177873001000023],
            [69.565439764000075, 30.170310402000041],
            [69.564571000000058, 30.16509700000006],
            [69.561732000000063, 30.15587000000005],
            [69.562464112000043, 30.152207893000025],
            [69.563151000000062, 30.148772000000065],
            [69.563155419000054, 30.148772680000036],
            [69.565641688000028, 30.149155307000058],
            [69.572378000000072, 30.150192000000061],
            [69.581605000000081, 30.145223000000044],
            [69.589413000000036, 30.13741600000003],
            [69.593375649000052, 30.13772070400006],
            [69.598087786000065, 30.138083038000048],
            [69.60100871800006, 30.13830764000005],
            [69.607867000000056, 30.138835000000029],
            [69.616278659000045, 30.147583713000074],
            [69.619193296000049, 30.150618622000025],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "13",
      properties: { name: "Chagai", count: 257 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [62.313693000000058, 29.427808000000027],
            [62.457188000000031, 29.385219000000063],
            [62.522397000000069, 29.395354000000054],
            [62.624270000000081, 29.402255000000025],
            [62.719674000000055, 29.412207000000024],
            [62.947296000000051, 29.433705000000032],
            [63.082192000000077, 29.446758000000045],
            [63.142553000000078, 29.452707000000032],
            [63.239203062000058, 29.463016648000064],
            [63.241198288000078, 29.467003502000068],
            [63.295179325000049, 29.472761652000031],
            [63.384358325000051, 29.477004651000072],
            [63.508195326000077, 29.487488652000025],
            [63.538336326000035, 29.490109651000068],
            [63.594030325000062, 29.490109651000068],
            [63.668725326000072, 29.477004651000072],
            [63.781424326000035, 29.459314652000046],
            [63.858085326000037, 29.446209651000061],
            [63.951782326000057, 29.433760652000046],
            [64.006821326000079, 29.422621652000032],
            [64.048100326000053, 29.417379651000033],
            [64.085448326000062, 29.386584652000067],
            [64.111002326000062, 29.377411651000045],
            [64.127215326000055, 29.383396651000055],
            [64.14441832600005, 29.399033652000071],
            [64.149005326000065, 29.421966651000048],
            [64.156867326000054, 29.444243651000079],
            [64.165278326000077, 29.453169652000042],
            [64.194353325000066, 29.469554651000067],
            [64.207569326000055, 29.488583652000045],
            [64.218142326000077, 29.496511651000048],
            [64.247745326000029, 29.502853651000066],
            [64.267305326000042, 29.521881652000047],
            [64.286601326000039, 29.528767652000056],
            [64.316086326000061, 29.530077652000045],
            [64.333911326000077, 29.536679651000043],
            [64.36326232600004, 29.549734651000051],
            [64.41764532500008, 29.544492652000031],
            [64.465538325000068, 29.569975652000039],
            [64.491030326000043, 29.572667652000064],
            [64.550655325000037, 29.57659865100004],
            [64.619365326000036, 29.581070651000061],
            [64.670560326000043, 29.579874651000068],
            [64.725598325000078, 29.571356652000077],
            [64.802259326000069, 29.568080652000049],
            [64.871713326000076, 29.559562651000078],
            [64.931338326000059, 29.555631651000056],
            [64.962788326000066, 29.553010651000079],
            [64.998825326000031, 29.537940652000032],
            [65.03617332500005, 29.534009651000076],
            [65.070244326000079, 29.535319651000066],
            [65.110868326000059, 29.544492652000031],
            [65.131591325000045, 29.552505651000047],
            [65.170938326000055, 29.520783651000045],
            [65.229658326000049, 29.460386652000068],
            [65.259098805000065, 29.425593140000046],
            [65.266567326000029, 29.416766652000035],
            [65.293411326000069, 29.371468651000043],
            [65.315268848000073, 29.332609626000078],
            [65.362197325000068, 29.24899565100003],
            [65.402996658000063, 29.18092851800003],
            [65.464537326000084, 29.071158651000076],
            [65.470084001000032, 29.036426146000053],
            [65.449886326000069, 29.027769651000028],
            [65.40304132600005, 28.992281651000042],
            [65.364713325000082, 28.968858651000062],
            [65.316448325000067, 28.933370651000075],
            [65.288058325000065, 28.914916651000055],
            [65.26250632600005, 28.905689651000046],
            [65.24547132500004, 28.895752651000066],
            [65.229146326000034, 28.878007651000075],
            [65.214241326000035, 28.860263651000025],
            [65.194368326000074, 28.841809651000062],
            [65.171655326000064, 28.823355651000043],
            [65.143974326000034, 28.814128651000033],
            [65.114163326000039, 28.811999651000065],
            [65.072287326000037, 28.808450651000044],
            [65.047445326000059, 28.805611652000039],
            [65.024732326000048, 28.785027652000053],
            [64.977177325000071, 28.732504651000056],
            [64.945238325000048, 28.701984651000032],
            [64.931042326000068, 28.689918652000074],
            [64.91045932600008, 28.687079651000033],
            [64.879939325000066, 28.690628651000054],
            [64.854387326000051, 28.694886651000047],
            [64.830964326000071, 28.697725652000031],
            [64.809671326000057, 28.698435651000068],
            [64.769214326000053, 28.684949651000068],
            [64.747945231000074, 28.678934299000048],
            [64.745688415000075, 28.67829602300003],
            [64.745951906000073, 28.674947649000046],
            [64.696954000000062, 28.661090000000058],
            [64.66643300000004, 28.651153000000079],
            [64.638752000000068, 28.645475000000033],
            [64.611071000000038, 28.64618500000006],
            [64.569195000000036, 28.630570000000034],
            [64.547902000000079, 28.622052000000053],
            [64.520220000000052, 28.612825000000043],
            [64.493959000000075, 28.610696000000075],
            [64.469827000000066, 28.592242000000056],
            [64.447453378000034, 28.565674001000048],
            [64.440590326000063, 28.550803651000024],
            [64.440320880000058, 28.550552173000028],
            [64.438597000000073, 28.546817000000033],
            [64.417303000000061, 28.526943000000074],
            [64.412825334000047, 28.525748984000074],
            [64.396010000000047, 28.521265000000028],
            [64.364780000000053, 28.519845000000032],
            [64.332131000000061, 28.524104000000079],
            [64.315806000000066, 28.531911000000036],
            [64.297352000000046, 28.538299000000052],
            [64.285497863000046, 28.539547174000063],
            [64.284440326000038, 28.527380651000044],
            [64.285859326000036, 28.516733652000028],
            [64.281601325000054, 28.50111865100007],
            [64.280622308000034, 28.500851687000079],
            [64.279608000000053, 28.497132000000079],
            [64.263993000000028, 28.492874000000029],
            [64.249087000000031, 28.492874000000029],
            [64.237731000000053, 28.488615000000038],
            [64.222826000000055, 28.483647000000076],
            [64.205082000000061, 28.470161000000076],
            [64.18804700000004, 28.468742000000077],
            [64.171013000000073, 28.471581000000072],
            [64.160366000000067, 28.468742000000077],
            [64.147590000000037, 28.469451000000049],
            [64.13055600000007, 28.469451000000049],
            [64.119909000000064, 28.46448300000003],
            [64.107133000000033, 28.452417000000025],
            [64.091518000000065, 28.441770000000076],
            [64.07519300000007, 28.428285000000074],
            [64.051061000000061, 28.423316000000057],
            [64.041124000000082, 28.413379000000077],
            [64.026929000000052, 28.401313000000073],
            [64.019637731000046, 28.392199427000037],
            [64.012733000000082, 28.383569000000023],
            [63.996409000000028, 28.384988000000078],
            [63.991440000000068, 28.390667000000064],
            [63.979374000000064, 28.38782800000007],
            [63.968018000000029, 28.380730000000028],
            [63.94885400000004, 28.376471000000038],
            [63.926851000000056, 28.377891000000034],
            [63.901299000000051, 28.368664000000024],
            [63.879296000000068, 28.352339000000029],
            [63.856583000000057, 28.333885000000066],
            [63.837420000000066, 28.312592000000052],
            [63.827646000000072, 28.300212000000045],
            [63.82677300000006, 28.299106000000052],
            [63.822514000000069, 28.313302000000078],
            [63.822514000000069, 28.336014000000034],
            [63.818256000000076, 28.345241000000044],
            [63.805480000000045, 28.33743400000003],
            [63.804115051000053, 28.335159013000066],
            [63.79909200000003, 28.326787000000024],
            [63.791284000000076, 28.316850000000045],
            [63.782057000000066, 28.311882000000026],
            [63.760054000000082, 28.303365000000042],
            [63.733083000000079, 28.295557000000031],
            [63.708241000000044, 28.291299000000038],
            [63.675592000000051, 28.289169000000072],
            [63.645781000000056, 28.292718000000036],
            [63.602485000000058, 28.294138000000032],
            [63.55493000000007, 28.294138000000032],
            [63.520151000000055, 28.290589000000068],
            [63.496019000000047, 28.291299000000038],
            [63.467628000000047, 28.285620000000051],
            [63.435689000000082, 28.279942000000062],
            [63.402329000000066, 28.269296000000054],
            [63.373938000000066, 28.265037000000063],
            [63.344128000000069, 28.265747000000033],
            [63.30154200000004, 28.269296000000054],
            [63.267473000000052, 28.274974000000043],
            [63.253277000000082, 28.272844000000077],
            [63.230564000000072, 28.252261000000033],
            [63.221337000000062, 28.230258000000049],
            [63.217789000000039, 28.206836000000067],
            [63.214240000000075, 28.19689900000003],
            [63.206432000000063, 28.184833000000026],
            [63.195076000000029, 28.177025000000071],
            [63.174492000000043, 28.177735000000041],
            [63.151070000000061, 28.169928000000027],
            [63.129067000000077, 28.157861000000025],
            [63.104225000000042, 28.138698000000034],
            [63.056908376000081, 28.103064035000045],
            [63.046733000000074, 28.095401000000038],
            [63.009116000000063, 28.062752000000046],
            [62.976466000000073, 28.035071000000073],
            [62.94949500000007, 28.015197000000057],
            [62.935299000000043, 28.003131000000053],
            [62.921104000000071, 27.981838000000039],
            [62.897681000000034, 27.96267400000005],
            [62.881357000000037, 27.942800000000034],
            [62.863612000000046, 27.925766000000067],
            [62.845158000000083, 27.914410000000032],
            [62.821026000000074, 27.895246000000043],
            [62.80340300000006, 27.888377000000048],
            [62.803402031000076, 27.888376882000045],
            [62.799475000000029, 27.91055300000005],
            [62.793124000000034, 27.952289000000064],
            [62.793577000000028, 27.978600000000029],
            [62.783597000000043, 27.994478000000072],
            [62.774070000000052, 28.013985000000048],
            [62.770895000000053, 28.029863000000034],
            [62.773617000000058, 28.055721000000062],
            [62.780422000000044, 28.078857000000028],
            [62.784051000000034, 28.113334000000066],
            [62.79176300000006, 28.153255000000058],
            [62.794938000000059, 28.182289000000026],
            [62.795392000000049, 28.215405000000032],
            [62.792670000000044, 28.281637000000046],
            [62.701486000000045, 28.267574000000025],
            [62.643419000000051, 28.259862000000055],
            [62.593971000000067, 28.253058000000067],
            [62.567206000000056, 28.281637000000046],
            [62.552235000000053, 28.299783000000048],
            [62.527265077000038, 28.316846102000056],
            [62.525016000000051, 28.31838300000004],
            [62.49961200000007, 28.331539000000078],
            [62.487328000000048, 28.353247000000067],
            [62.47148500000003, 28.377357000000075],
            [62.446704000000068, 28.399112000000059],
            [62.42311600000005, 28.413527000000045],
            [62.391010000000051, 28.427287000000035],
            [62.368733000000077, 28.438425000000052],
            [62.339903000000049, 28.444322000000057],
            [62.31434900000005, 28.454151000000024],
            [62.286829000000068, 28.465945000000033],
            [62.260212000000081, 28.467135000000042],
            [62.235722000000067, 28.463324000000057],
            [62.216720000000066, 28.473152000000027],
            [62.191167000000064, 28.48232500000006],
            [62.168234000000041, 28.48756700000007],
            [62.138093000000083, 28.484291000000042],
            [62.117126000000042, 28.484291000000042],
            [62.090917000000047, 28.492809000000079],
            [62.06601900000004, 28.500016000000073],
            [62.054225000000031, 28.503947000000039],
            [62.037844000000064, 28.506568000000073],
            [62.02539500000006, 28.521638000000053],
            [62.007049000000052, 28.530156000000034],
            [61.980184000000065, 28.541950000000043],
            [61.950699000000043, 28.548502000000042],
            [61.921214000000077, 28.564883000000066],
            [61.904178000000059, 28.574056000000041],
            [61.89107400000006, 28.581919000000028],
            [61.877969000000064, 28.591747000000055],
            [61.862244000000032, 28.600265000000036],
            [61.844553000000076, 28.613369000000034],
            [61.833414000000062, 28.625818000000038],
            [61.81768900000003, 28.633026000000029],
            [61.803274000000044, 28.639578000000029],
            [61.793445000000077, 28.65530300000006],
            [61.780341000000078, 28.669063000000051],
            [61.764615000000049, 28.691340000000025],
            [61.75151100000005, 28.705755000000067],
            [61.733820000000037, 28.720825000000048],
            [61.714818000000037, 28.743103000000076],
            [61.698438000000067, 28.754241000000036],
            [61.685333000000071, 28.766691000000037],
            [61.672229000000073, 28.778485000000046],
            [61.657159000000036, 28.798796000000038],
            [61.640778000000068, 28.818453000000034],
            [61.628984000000059, 28.834834000000058],
            [61.616535000000056, 28.86038700000006],
            [61.586394000000041, 28.89576900000003],
            [61.57787600000006, 28.907563000000039],
            [61.568048000000033, 28.920667000000037],
            [61.564117000000067, 28.935082000000079],
            [61.552978000000053, 28.953428000000031],
            [61.547081000000048, 28.963257000000056],
            [61.532666000000063, 28.978327000000036],
            [61.520973000000083, 28.992127000000039],
            [61.503836000000035, 29.001915000000054],
            [61.503181000000041, 29.022227000000044],
            [61.505146000000082, 29.045159000000069],
            [61.503120000000081, 29.057301000000052],
            [61.490076000000045, 29.067437000000041],
            [61.47893700000003, 29.081196000000034],
            [61.478085000000078, 29.101970000000051],
            [61.465833000000032, 29.113957000000028],
            [61.449452000000065, 29.124441000000047],
            [61.434382000000028, 29.127717000000075],
            [61.413841000000048, 29.135870000000068],
            [61.41830600000003, 29.162654000000032],
            [61.424049000000082, 29.17432400000007],
            [61.427175000000034, 29.192584000000068],
            [61.420622000000037, 29.199791000000062],
            [61.406207000000052, 29.202412000000038],
            [61.387861000000044, 29.208964000000037],
            [61.381309000000044, 29.220758000000046],
            [61.379068000000075, 29.228857000000062],
            [61.37554700000004, 29.237526000000059],
            [61.36034200000006, 29.263348000000065],
            [61.354445000000055, 29.276452000000063],
            [61.362307000000044, 29.296764000000053],
            [61.381964000000039, 29.331491000000028],
            [61.374101000000053, 29.345905000000073],
            [61.330201000000045, 29.380632000000048],
            [61.29416400000008, 29.410117000000071],
            [61.284336000000053, 29.421256000000028],
            [61.256816000000072, 29.440257000000031],
            [61.223400000000083, 29.475639000000058],
            [61.184741000000031, 29.509710000000041],
            [61.163904000000059, 29.535899000000029],
            [61.130358000000058, 29.566715000000045],
            [61.083837000000074, 29.617822000000046],
            [61.030155000000036, 29.684351000000049],
            [60.977690000000052, 29.742314000000079],
            [60.90233900000004, 29.832734000000073],
            [60.891856000000075, 29.851735000000076],
            [61.546425000000056, 29.654514000000063],
            [61.59294600000004, 29.640099000000077],
            [61.663710000000037, 29.617167000000052],
            [61.745614000000046, 29.593579000000034],
            [61.86552000000006, 29.558852000000059],
            [61.976908000000037, 29.527402000000052],
            [62.102056000000061, 29.489399000000049],
            [62.219996000000037, 29.45729300000005],
            [62.313693000000058, 29.427808000000027],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "14",
      properties: { name: "Dera Bugti", count: 130 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.600718000000029, 29.525879000000032],
            [69.597863286000063, 29.514221409000072],
            [69.596953247000044, 29.510505151000075],
            [69.595963547000053, 29.506463584000073],
            [69.593364000000065, 29.495848000000024],
            [69.585605653000073, 29.488817146000031],
            [69.57375200000007, 29.478075000000047],
            [69.563946000000044, 29.451722000000075],
            [69.565577590000032, 29.428231899000025],
            [69.567011000000036, 29.407595000000072],
            [69.581719000000078, 29.403305000000046],
            [69.588362608000068, 29.40710090500005],
            [69.594590000000039, 29.410659000000066],
            [69.599438148000047, 29.414901933000067],
            [69.603717840000058, 29.418647373000056],
            [69.604395000000068, 29.419240000000059],
            [69.612363000000073, 29.422917000000041],
            [69.629523000000063, 29.416175000000067],
            [69.638716000000045, 29.403918000000033],
            [69.645983246000071, 29.397619536000036],
            [69.646020871000076, 29.397619185000053],
            [69.656783016000077, 29.388291720000041],
            [69.657139220000033, 29.387983000000077],
            [69.670009221000043, 29.36530700000003],
            [69.66510622100003, 29.352437000000066],
            [69.660458027000061, 29.347431163000067],
            [69.649172221000072, 29.335277000000076],
            [69.63691422100004, 29.32547100000005],
            [69.636302220000061, 29.311988000000042],
            [69.654075220000038, 29.30831100000006],
            [69.663231000000053, 29.30831100000006],
            [69.663268220000077, 29.30831100000006],
            [69.676138221000031, 29.298505000000034],
            [69.680428221000057, 29.288086000000078],
            [69.679202220000036, 29.26908700000007],
            [69.684103406000077, 29.258060083000032],
            [69.684105220000049, 29.258056000000067],
            [69.700040220000062, 29.259894000000031],
            [69.72639322100008, 29.284409000000039],
            [69.746005220000029, 29.297892000000047],
            [69.758844501000056, 29.300337150000075],
            [69.758875221000039, 29.300343000000055],
            [69.76448209800003, 29.295297817000062],
            [69.76449060300007, 29.29525667200005],
            [69.764801519000059, 29.293752579000056],
            [69.76482285600008, 29.293649358000039],
            [69.76327740000005, 29.283605783000041],
            [69.762552220000032, 29.278893000000039],
            [69.753972221000083, 29.261733000000049],
            [69.744166221000057, 29.244572000000062],
            [69.738650220000068, 29.224348000000077],
            [69.742940220000037, 29.197995000000049],
            [69.749069220000081, 29.187576000000035],
            [69.745392221000031, 29.172867000000053],
            [69.72639322100008, 29.147127000000069],
            [69.719258963000073, 29.134444162000079],
            [69.715361220000034, 29.127515000000074],
            [69.708620221000047, 29.110355000000027],
            [69.708115013000054, 29.10075770800006],
            [69.708007221000059, 29.09871000000004],
            [69.697588220000057, 29.088291000000027],
            [69.677977220000059, 29.080324000000076],
            [69.652849220000064, 29.071131000000037],
            [69.639979221000033, 29.058261000000073],
            [69.630786220000061, 29.041713000000072],
            [69.61965553400006, 29.032231603000071],
            [69.614238220000061, 29.027617000000077],
            [69.607180823000078, 29.016527474000043],
            [69.607178961000045, 29.016524549000053],
            [69.606583474000047, 29.015588839000031],
            [69.606554198000083, 29.015600766000034],
            [69.604135885000062, 29.011800615000027],
            [69.601331000000073, 29.007393000000036],
            [69.598932286000036, 29.00457113300007],
            [69.59091200000006, 28.995136000000059],
            [69.587198638000075, 28.984281162000059],
            [69.587198385000079, 28.98428042200004],
            [69.582945000000052, 28.971847000000025],
            [69.582874781000044, 28.971683151000036],
            [69.577429000000052, 28.958976000000064],
            [69.564559000000031, 28.955299000000025],
            [69.555366000000049, 28.954686000000038],
            [69.551691273000074, 28.949020889000053],
            [69.545248923000031, 28.939089097000078],
            [69.540657000000067, 28.932010000000048],
            [69.525347787000044, 28.921710902000029],
            [69.519823794000047, 28.91799469800003],
            [69.513432329000068, 28.913694913000029],
            [69.506950000000074, 28.909334000000058],
            [69.503997542000036, 28.907186718000048],
            [69.493467000000066, 28.899528000000032],
            [69.49213297600005, 28.894192232000023],
            [69.490479815000072, 28.887579993000031],
            [69.489409847000047, 28.883300379000048],
            [69.488069916000029, 28.877940984000077],
            [69.48638148100008, 28.871187659000043],
            [69.485090464000052, 28.866023905000077],
            [69.483703032000051, 28.860474515000078],
            [69.482334882000032, 28.855002252000077],
            [69.481209000000035, 28.85049900000007],
            [69.482767710000076, 28.849581838000063],
            [69.499167533000048, 28.839931990000025],
            [69.499175550000075, 28.839930999000046],
            [69.502047000000061, 28.838242000000037],
            [69.522884000000033, 28.832113000000049],
            [69.525650686000063, 28.827502024000069],
            [69.528746254000055, 28.822342930000048],
            [69.533916000000033, 28.813727000000029],
            [69.535296526000081, 28.807859694000058],
            [69.537210191000042, 28.799726519000046],
            [69.538819000000046, 28.792889000000059],
            [69.533610966000083, 28.781084387000078],
            [69.529626000000064, 28.772052000000031],
            [69.523383440000032, 28.760881328000039],
            [69.517981000000077, 28.751214000000061],
            [69.514575690000072, 28.746357300000057],
            [69.501837155000032, 28.728189427000075],
            [69.487752906000082, 28.708102278000069],
            [69.48775167000008, 28.708100514000023],
            [69.484072339000079, 28.70285300200004],
            [69.480596000000048, 28.697895000000074],
            [69.480560190000062, 28.697839069000054],
            [69.478933036000058, 28.695297612000047],
            [69.473168712000074, 28.686294299000053],
            [69.467921354000055, 28.678098437000074],
            [69.45897874700006, 28.664130954000029],
            [69.452784522000059, 28.654456175000064],
            [69.451461769000048, 28.652390166000032],
            [69.451318273000084, 28.652166038000075],
            [69.445082033000062, 28.642425639000066],
            [69.436877934000051, 28.62961163500006],
            [69.431487366000056, 28.621192092000058],
            [69.420732369000064, 28.604393833000074],
            [69.420731927000077, 28.604393142000049],
            [69.416808439000079, 28.598265036000043],
            [69.41624500000006, 28.597385000000031],
            [69.398472000000083, 28.59003000000007],
            [69.377022000000068, 28.578999000000067],
            [69.349443000000065, 28.570419000000072],
            [69.34493115600003, 28.568044223000072],
            [69.326154000000031, 28.558161000000041],
            [69.31879900000007, 28.552033000000051],
            [69.31964799900004, 28.547449234000055],
            [69.321864000000062, 28.535485000000051],
            [69.332282000000077, 28.526292000000069],
            [69.334892287000059, 28.520288636000032],
            [69.334892901000046, 28.52028722600005],
            [69.338411000000065, 28.512196000000074],
            [69.338443576000032, 28.51209827200006],
            [69.346844786000077, 28.48689464000006],
            [69.346844823000083, 28.486894531000075],
            [69.34699100000006, 28.486456000000032],
            [69.354959000000065, 28.461941000000024],
            [69.360841806000053, 28.451244893000023],
            [69.360841553000057, 28.451244893000023],
            [69.323826269000051, 28.45124540300003],
            [69.302894312000035, 28.451245691000054],
            [69.30011554400005, 28.451245730000039],
            [69.300115393000056, 28.451245730000039],
            [69.261884000000066, 28.451246256000047],
            [69.241515000000049, 28.451242000000036],
            [69.206175530000053, 28.446193427000026],
            [69.206107633000045, 28.446183728000051],
            [69.196245158000067, 28.444774783000071],
            [69.175121000000047, 28.441757000000052],
            [69.173581950000028, 28.441687212000033],
            [69.131036292000033, 28.439756871000043],
            [69.131035000000054, 28.439763000000028],
            [69.130799657000068, 28.440913292000062],
            [69.127428000000066, 28.457393000000025],
            [69.122741566000059, 28.467936988000076],
            [69.117868000000044, 28.478902000000062],
            [69.112871744000074, 28.482553426000038],
            [69.112852152000073, 28.48256774400005],
            [69.10147007900008, 28.490886132000071],
            [69.097156000000041, 28.494039000000043],
            [69.095559766000065, 28.494627003000062],
            [69.082019000000059, 28.499615000000063],
            [69.064493000000084, 28.486072000000036],
            [69.039797000000078, 28.460579000000052],
            [69.039515700000038, 28.460645197000076],
            [69.026254000000051, 28.463766000000078],
            [69.011288667000031, 28.465314160000048],
            [69.011287080000045, 28.465314324000076],
            [69.000154042000077, 28.466466034000064],
            [68.992441901000063, 28.467263853000077],
            [68.980048000000068, 28.46854600000006],
            [68.962738298000033, 28.468860463000055],
            [68.962737103000052, 28.46886048500005],
            [68.939146786000038, 28.469289047000075],
            [68.936232000000075, 28.46934200000004],
            [68.905163000000073, 28.467749000000026],
            [68.903652745000045, 28.467934485000058],
            [68.903645661000041, 28.467935355000066],
            [68.859754000000066, 28.473326000000043],
            [68.850674930000082, 28.473023238000053],
            [68.850674342000048, 28.473023218000037],
            [68.846004227000037, 28.472867482000026],
            [68.83585400000004, 28.472529000000065],
            [68.833208408000075, 28.473379394000062],
            [68.813548000000083, 28.479699000000039],
            [68.804282674000035, 28.481606387000056],
            [68.803040122000084, 28.481862182000043],
            [68.786462000000029, 28.485275000000058],
            [68.784030364000046, 28.485635280000054],
            [68.784028055000078, 28.485635622000075],
            [68.764952000000051, 28.488462000000027],
            [68.743411089000062, 28.499232033000055],
            [68.739459000000068, 28.501208000000076],
            [68.705630529000075, 28.514739766000048],
            [68.705298766000055, 28.514872475000061],
            [68.703610000000083, 28.515548000000024],
            [68.661388000000045, 28.535464000000047],
            [68.640618407000034, 28.547925755000051],
            [68.637488000000076, 28.549804000000051],
            [68.61836900000003, 28.561754000000064],
            [68.61661282700004, 28.562890290000041],
            [68.591282000000035, 28.57928000000004],
            [68.579425416000049, 28.585368385000038],
            [68.561806000000047, 28.594416000000024],
            [68.557153530000051, 28.597012778000078],
            [68.546365840000078, 28.603033932000073],
            [68.545156636000058, 28.603708850000032],
            [68.528206038000064, 28.61316983200004],
            [68.527550000000076, 28.613536000000067],
            [68.523349803000031, 28.616180627000062],
            [68.520763831000068, 28.617808867000065],
            [68.520753404000061, 28.617815432000043],
            [68.513230314000054, 28.622552295000048],
            [68.512373497000056, 28.623091784000053],
            [68.506041000000039, 28.627079000000037],
            [68.499267470000063, 28.632783049000068],
            [68.490904000000057, 28.639826000000028],
            [68.474971000000039, 28.643012000000056],
            [68.464176394000049, 28.652008133000038],
            [68.464176358000032, 28.652008162000072],
            [68.464002105000077, 28.65215338400003],
            [68.464002061000031, 28.652153420000047],
            [68.462375102000067, 28.653509313000029],
            [68.460632000000032, 28.654962000000069],
            [68.446179001000075, 28.675500001000046],
            [68.445495000000051, 28.676472000000047],
            [68.442324014000064, 28.691536670000062],
            [68.44230900000008, 28.691607999000041],
            [68.441879877000076, 28.693876031000059],
            [68.441879609000068, 28.693877458000031],
            [68.442031880000059, 28.693991270000026],
            [68.436928507000061, 28.721360144000073],
            [68.439295494000078, 28.74976300000003],
            [68.439277423000078, 28.749945719000038],
            [68.439528437000035, 28.77253044400004],
            [68.438797623000028, 28.776179114000058],
            [68.435312494000073, 28.793579000000079],
            [68.435141087000034, 28.79356858500006],
            [68.435139001000039, 28.793579000000079],
            [68.434342000000072, 28.816284560000042],
            [68.431337763000045, 28.825328197000033],
            [68.428765912000074, 28.833412234000036],
            [68.428759401000036, 28.833429597000077],
            [68.425716826000041, 28.841542704000062],
            [68.422908950000078, 28.849029981000058],
            [68.419206000000031, 28.858904000000052],
            [68.416040009000028, 28.874322304000032],
            [68.414876727000035, 28.879987464000067],
            [68.413419059000034, 28.88695173900004],
            [68.412039779000054, 28.893207238000059],
            [68.410443000000043, 28.909093000000041],
            [68.411862067000072, 28.930394152000076],
            [68.411862413000051, 28.930399351000062],
            [68.412024079000048, 28.932824843000049],
            [68.41205119500006, 28.933847950000029],
            [68.412050116000046, 28.933844109000063],
            [68.412152697000067, 28.937125242000036],
            [68.412153120000028, 28.937138804000028],
            [68.412302795000073, 28.941526776000046],
            [68.412314611000056, 28.941904721000071],
            [68.41240663800005, 28.944848297000078],
            [68.412722159000054, 28.955340159000059],
            [68.412722500000029, 28.955351058000076],
            [68.412832999000045, 28.95848600000005],
            [68.412824496000042, 28.958613559000071],
            [68.412833000000035, 28.958885559000066],
            [68.411393064000038, 28.980481085000065],
            [68.411378153000044, 28.980704713000023],
            [68.411352984000075, 28.980689232000032],
            [68.41135113100006, 28.980716801000028],
            [68.41123900000008, 28.982385000000079],
            [68.407409136000069, 28.986215344000072],
            [68.404653685000028, 28.988971142000025],
            [68.404653297000038, 28.988971530000072],
            [68.40327300000007, 28.99035200000003],
            [68.40225829600007, 29.001008623000075],
            [68.402254365000033, 29.001049903000023],
            [68.40182505000007, 29.005558663000045],
            [68.401680000000056, 29.007081999000036],
            [68.39585388100005, 29.019148568000048],
            [68.394834908000064, 29.021684099000026],
            [68.394117205000043, 29.023264803000075],
            [68.388228051000056, 29.036235365000039],
            [68.387940179000054, 29.036869391000039],
            [68.381763000000035, 29.051694000000055],
            [68.380967000000055, 29.052491000000032],
            [68.381072000000074, 29.052583000000027],
            [68.365034000000037, 29.073204000000032],
            [68.351491000000067, 29.092323000000079],
            [68.34210345300005, 29.102649301000042],
            [68.327591000000041, 29.118613000000039],
            [68.302098000000058, 29.14729200000005],
            [68.287759000000051, 29.162428000000034],
            [68.283827430000031, 29.167408099000056],
            [68.280690211000035, 29.171381996000036],
            [68.275809000000038, 29.177565000000072],
            [68.274501555000029, 29.186716572000023],
            [68.273419000000047, 29.19429400000007],
            [68.27791783300006, 29.209077378000075],
            [68.278995000000066, 29.212617000000023],
            [68.28616500000004, 29.222974000000079],
            [68.288524948000031, 29.224232573000052],
            [68.298115000000053, 29.229347000000075],
            [68.31962500000003, 29.22536400000007],
            [68.319638276000035, 29.225358965000055],
            [68.342727000000082, 29.216601000000026],
            [68.356270000000052, 29.208634000000075],
            [68.356289229000083, 29.20863092500008],
            [68.376187000000073, 29.205448000000047],
            [68.404866000000084, 29.204651000000069],
            [68.435139000000049, 29.205447000000049],
            [68.467005000000029, 29.207041000000061],
            [68.480548000000056, 29.221381000000065],
            [68.480609805000029, 29.22149464000006],
            [68.481544000000042, 29.221454000000051],
            [68.481988728000033, 29.221577020000041],
            [68.481988152000042, 29.22157745100003],
            [68.484347390000039, 29.22223002000004],
            [68.516726000000062, 29.231186000000037],
            [68.566879000000029, 29.243911000000026],
            [68.596073000000047, 29.252145000000041],
            [68.617781000000036, 29.257385000000056],
            [68.629009000000053, 29.260379000000057],
            [68.646975000000054, 29.267116000000044],
            [68.659700000000043, 29.267116000000044],
            [68.674671000000046, 29.266368000000057],
            [68.696379000000036, 29.264122000000043],
            [68.716590000000053, 29.262625000000071],
            [68.74279000000007, 29.256637000000069],
            [68.757012000000032, 29.257385000000056],
            [68.784709000000078, 29.25364200000007],
            [68.803423000000066, 29.24915100000004],
            [68.828125000000057, 29.246157000000039],
            [68.844593000000032, 29.243911000000026],
            [68.859564000000034, 29.239420000000052],
            [68.886512000000039, 29.231186000000037],
            [68.899238000000082, 29.229689000000064],
            [68.917952000000071, 29.22444900000005],
            [68.929929000000072, 29.225946000000079],
            [68.945648000000062, 29.227443000000051],
            [68.960619000000065, 29.235677000000067],
            [68.976339000000053, 29.24016800000004],
            [68.990562000000068, 29.252894000000026],
            [69.001790000000028, 29.260379000000057],
            [69.016761000000031, 29.261876000000029],
            [69.031732000000034, 29.264122000000043],
            [69.044458000000077, 29.261876000000029],
            [69.065417000000082, 29.25963100000007],
            [69.08413100000007, 29.25364200000007],
            [69.097605000000044, 29.251397000000054],
            [69.111079000000075, 29.251397000000054],
            [69.14102100000008, 29.248402000000056],
            [69.165724000000068, 29.256637000000069],
            [69.175455000000056, 29.258134000000041],
            [69.188180000000045, 29.262625000000071],
            [69.204648000000077, 29.268613000000073],
            [69.212883000000033, 29.283585000000073],
            [69.225608000000079, 29.301550000000077],
            [69.236088000000052, 29.315024000000051],
            [69.242076000000054, 29.323258000000067],
            [69.251059000000055, 29.337481000000025],
            [69.263784000000044, 29.345715000000041],
            [69.278007000000059, 29.348709000000042],
            [69.289235000000076, 29.348709000000042],
            [69.301961000000063, 29.352452000000028],
            [69.313938000000064, 29.353949000000057],
            [69.328160000000082, 29.359937000000059],
            [69.339388000000042, 29.366674000000046],
            [69.350617000000057, 29.375657000000047],
            [69.361097000000029, 29.390628000000049],
            [69.367883714000072, 29.398032062000027],
            [69.367919020000045, 29.398070580000024],
            [69.369331000000045, 29.39961100000005],
            [69.40975300000008, 29.416827000000069],
            [69.430712000000028, 29.425062000000025],
            [69.445683000000031, 29.429553000000055],
            [69.45616300000006, 29.43703800000003],
            [69.471134000000063, 29.44602100000003],
            [69.48386000000005, 29.460244000000046],
            [69.493591000000038, 29.470723000000078],
            [69.501825000000053, 29.482700000000079],
            [69.507065000000068, 29.493929000000037],
            [69.507065000000068, 29.494176168000024],
            [69.507065000000068, 29.50890000000004],
            [69.503322000000082, 29.514140000000054],
            [69.495837000000051, 29.518631000000028],
            [69.483111000000065, 29.519380000000069],
            [69.473380000000077, 29.519380000000069],
            [69.46215200000006, 29.520877000000041],
            [69.446432000000073, 29.527614000000028],
            [69.433706000000029, 29.53809300000006],
            [69.429215000000056, 29.548573000000033],
            [69.429215000000056, 29.559802000000047],
            [69.430712000000028, 29.571030000000064],
            [69.435952000000043, 29.580013000000065],
            [69.44718000000006, 29.598726000000056],
            [69.459906000000046, 29.613698000000056],
            [69.477123000000063, 29.627920000000074],
            [69.498831000000052, 29.642891000000077],
            [69.516796000000056, 29.655617000000063],
            [69.533264000000031, 29.666096000000039],
            [69.551978000000076, 29.675828000000024],
            [69.568446000000051, 29.677325000000053],
            [69.58716000000004, 29.67807300000004],
            [69.611863000000028, 29.673582000000067],
            [69.622781964000069, 29.669292333000044],
            [69.631967363000058, 29.66568372100005],
            [69.631967732000078, 29.665683450000074],
            [69.627684000000045, 29.660096000000067],
            [69.626295940000034, 29.654195755000046],
            [69.624414991000037, 29.646200377000071],
            [69.622782000000029, 29.639259000000038],
            [69.622781964000069, 29.639258993000055],
            [69.604395000000068, 29.635582000000056],
            [69.592751000000078, 29.627614000000051],
            [69.594768817000045, 29.622368137000024],
            [69.598880000000065, 29.611680000000035],
            [69.606234000000029, 29.600035000000048],
            [69.604075317000081, 29.581531717000075],
            [69.602833918000044, 29.570890985000062],
            [69.602352684000039, 29.566766064000035],
            [69.60194400000006, 29.563263000000063],
            [69.601767533000043, 29.557882054000061],
            [69.601506764000078, 29.549930521000078],
            [69.601289214000076, 29.543296855000051],
            [69.600875098000074, 29.530669346000025],
            [69.600718000000029, 29.525879000000032],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "15",
      properties: { name: "Gwadar", count: 1448 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [62.04254426600005, 25.770797000000073],
            [62.047112739000056, 25.769136185000036],
            [62.046880000000044, 25.76843800000006],
            [62.05015700000007, 25.767246000000057],
            [62.05770300000006, 25.763251000000025],
            [62.058147000000076, 25.751266000000044],
            [62.056816000000083, 25.745052000000044],
            [62.055040000000076, 25.739725000000078],
            [62.05015700000007, 25.728628000000072],
            [62.052821000000051, 25.721969000000058],
            [62.05326400000007, 25.711760000000027],
            [62.060367000000042, 25.703326000000061],
            [62.067012000000034, 25.693565000000035],
            [62.078332000000046, 25.674012000000062],
            [62.074216000000035, 25.655488000000048],
            [62.072157000000061, 25.635935000000075],
            [62.074216000000035, 25.617411000000061],
            [62.089652000000058, 25.596829000000071],
            [62.114350000000059, 25.581393000000048],
            [62.11743800000005, 25.57213100000007],
            [62.112292000000082, 25.557724000000064],
            [62.095827000000043, 25.554636000000073],
            [62.076274000000069, 25.553607000000056],
            [62.06392500000004, 25.544345000000078],
            [62.061866000000066, 25.523763000000031],
            [62.065983000000074, 25.503181000000041],
            [62.077303000000029, 25.502152000000024],
            [62.080682746000036, 25.50191060700007],
            [62.091710000000035, 25.501123000000064],
            [62.095827000000043, 25.495977000000039],
            [62.10097200000007, 25.483628000000067],
            [62.115380000000073, 25.475395000000049],
            [62.134933000000046, 25.475395000000049],
            [62.173009000000036, 25.488774000000035],
            [62.217261000000065, 25.507298000000048],
            [62.239901000000032, 25.509356000000025],
            [62.258425000000045, 25.513472000000036],
            [62.277978000000076, 25.521705000000054],
            [62.307822000000044, 25.530967000000032],
            [62.32443270400006, 25.53602270600004],
            [62.331491000000028, 25.538171000000034],
            [62.348986000000082, 25.544345000000078],
            [62.369568000000072, 25.547433000000069],
            [62.394266000000073, 25.553607000000056],
            [62.413819000000046, 25.553607000000056],
            [62.433372000000077, 25.55257800000004],
            [62.459920000000068, 25.551557000000059],
            [62.460129000000052, 25.551549000000023],
            [62.475565000000074, 25.555665000000033],
            [62.491002000000037, 25.555665000000033],
            [62.51140440000006, 25.555665000000033],
            [62.519817000000046, 25.555665000000033],
            [62.523235594000028, 25.555665000000033],
            [62.548631000000057, 25.555665000000033],
            [62.578475000000083, 25.55669400000005],
            [62.606261000000075, 25.55669400000005],
            [62.629931000000056, 25.55669400000005],
            [62.670065000000079, 25.554636000000073],
            [62.687560000000076, 25.55669400000005],
            [62.704026000000056, 25.559782000000041],
            [62.708609551000052, 25.559782000000041],
            [62.72666600000008, 25.559782000000041],
            [62.751365000000078, 25.562869000000035],
            [62.782238000000063, 25.565956000000028],
            [62.803849000000071, 25.566985000000045],
            [62.815169000000083, 25.564927000000068],
            [62.826489000000038, 25.55669400000005],
            [62.845013000000051, 25.55669400000005],
            [62.855304000000046, 25.563898000000052],
            [62.864566000000082, 25.571102000000053],
            [62.87897300000003, 25.578306000000055],
            [62.891322000000059, 25.582422000000065],
            [62.906759000000079, 25.582422000000065],
            [62.924254000000076, 25.586538000000075],
            [62.938661000000081, 25.589626000000067],
            [62.941227000000083, 25.590481000000068],
            [62.94122703000005, 25.59048137800005],
            [62.963359000000082, 25.597859000000028],
            [62.979825000000062, 25.593742000000077],
            [62.995262000000082, 25.580364000000031],
            [63.014815000000056, 25.560811000000058],
            [63.028193000000044, 25.545374000000038],
            [63.033338000000072, 25.531996000000049],
            [63.045688000000041, 25.526851000000079],
            [63.057008000000053, 25.528909000000056],
            [63.073473000000035, 25.528909000000056],
            [63.088910000000055, 25.521705000000054],
            [63.09920100000005, 25.516559000000029],
            [63.125958000000082, 25.515530000000069],
            [63.149627000000066, 25.514501000000052],
            [63.171238000000074, 25.514501000000052],
            [63.186675000000037, 25.516559000000029],
            [63.190791000000047, 25.510385000000042],
            [63.195682608000027, 25.509521874000029],
            [63.208286000000044, 25.507298000000048],
            [63.219606000000056, 25.512443000000076],
            [63.228868000000034, 25.508327000000065],
            [63.241217000000063, 25.504210000000057],
            [63.26282800000007, 25.500094000000047],
            [63.275177000000042, 25.49906500000003],
            [63.27855567000006, 25.499190123000062],
            [63.302963000000034, 25.500094000000047],
            [63.325603000000058, 25.500094000000047],
            [63.347215000000062, 25.49803600000007],
            [63.361622000000068, 25.489803000000052],
            [63.37294200000008, 25.471279000000038],
            [63.379117000000065, 25.460988000000043],
            [63.392495000000054, 25.453784000000041],
            [63.407931000000076, 25.453784000000041],
            [63.409075382000083, 25.453656860000024],
            [63.417193000000054, 25.452755000000025],
            [63.426455000000033, 25.434231000000068],
            [63.426455000000033, 25.42599800000005],
            [63.440863000000036, 25.417766000000029],
            [63.435717000000068, 25.405416000000059],
            [63.424397000000056, 25.394096000000047],
            [63.40999000000005, 25.384834000000069],
            [63.403815000000066, 25.373514000000057],
            [63.412048000000027, 25.361165000000028],
            [63.436746000000028, 25.37248500000004],
            [63.456299000000058, 25.385864000000026],
            [63.472765000000038, 25.405416000000059],
            [63.481197458000054, 25.411648872000058],
            [63.496434000000079, 25.422911000000056],
            [63.524220000000071, 25.433202000000051],
            [63.542744000000027, 25.445551000000023],
            [63.581850000000031, 25.468192000000045],
            [63.629188000000056, 25.487745000000075],
            [63.692993000000058, 25.513472000000036],
            [63.741360000000043, 25.531996000000049],
            [63.775321000000076, 25.55257800000004],
            [63.807223000000079, 25.564927000000068],
            [63.839125000000081, 25.570073000000036],
            [63.865882000000056, 25.579335000000071],
            [63.900871000000052, 25.587567000000035],
            [63.930715000000077, 25.59271300000006],
            [63.957472000000053, 25.601975000000039],
            [63.973937000000035, 25.606091000000049],
            [64.005840000000035, 25.614324000000067],
            [64.031567000000052, 25.614324000000067],
            [64.053178000000059, 25.614324000000067],
            [64.068615000000079, 25.605062000000032],
            [64.080964000000051, 25.600946000000079],
            [64.08919700000007, 25.593742000000077],
            [64.103604000000075, 25.571102000000053],
            [64.12624500000004, 25.557724000000064],
            [64.149914000000081, 25.55669400000005],
            [64.169467000000054, 25.55669400000005],
            [64.17152800000008, 25.556631000000039],
            [64.164236000000074, 25.542654000000027],
            [64.158760000000029, 25.524663000000032],
            [64.144680000000051, 25.503544000000034],
            [64.125589000000048, 25.481231000000037],
            [64.132755000000031, 25.469926000000044],
            [64.144096000000047, 25.468111000000079],
            [64.174944000000039, 25.467658000000029],
            [64.176681000000031, 25.462921000000051],
            [64.199435000000051, 25.467562000000044],
            [64.23385200000007, 25.467562000000044],
            [64.258101000000067, 25.466780000000028],
            [64.280785000000037, 25.463651000000027],
            [64.305815000000052, 25.450354000000061],
            [64.341797000000042, 25.436274000000026],
            [64.37308500000006, 25.433145000000025],
            [64.402809000000047, 25.443314000000044],
            [64.413020221000068, 25.450275954000062],
            [64.437227000000064, 25.466780000000028],
            [64.475555000000043, 25.48868200000004],
            [64.502150000000029, 25.496504000000073],
            [64.558469000000059, 25.504326000000049],
            [64.628868000000068, 25.512148000000025],
            [64.689098000000058, 25.521535000000029],
            [64.750110000000063, 25.532486000000063],
            [64.79644922600005, 25.539565364000055],
            [64.806429000000037, 25.541090000000054],
            [64.875264000000072, 25.543436000000042],
            [64.919850000000054, 25.554387000000077],
            [64.954267000000073, 25.555952000000048],
            [64.956445543000029, 25.556154666000054],
            [64.987902000000076, 25.559081000000049],
            [65.02701200000007, 25.566121000000066],
            [65.060647000000074, 25.572378000000072],
            [65.090371000000061, 25.58098300000006],
            [65.122442000000035, 25.588022000000024],
            [65.149037000000078, 25.595062000000041],
            [65.172503000000063, 25.60366700000003],
            [65.18345400000004, 25.596627000000069],
            [65.193623000000059, 25.585676000000035],
            [65.206784967000033, 25.564771988000075],
            [65.206138000000067, 25.564556000000039],
            [65.208485000000053, 25.547348000000056],
            [65.209267000000068, 25.521535000000029],
            [65.210831000000042, 25.48868200000004],
            [65.209267000000068, 25.455047000000036],
            [65.209267000000068, 25.433145000000025],
            [65.209267000000068, 25.427627556000061],
            [65.209203039000045, 25.411179039000046],
            [65.20842103800004, 25.397099039000068],
            [65.207638038000027, 25.383802039000045],
            [65.220936039000037, 25.367375039000024],
            [65.239630000000034, 25.353881000000058],
            [65.240799043000038, 25.350092335000056],
            [65.239003000000082, 25.344625000000065],
            [65.234969000000035, 25.339130000000068],
            [65.231497000000047, 25.337628000000052],
            [65.226218000000074, 25.329167000000041],
            [65.223998000000051, 25.322971000000052],
            [65.225238000000047, 25.316475000000025],
            [65.221950000000049, 25.314467000000036],
            [65.217907000000082, 25.312046000000066],
            [65.209089000000063, 25.311326000000065],
            [65.200190000000077, 25.309869000000049],
            [65.196516000000031, 25.305431000000056],
            [65.182746000000066, 25.303954000000033],
            [65.16398300000003, 25.303480000000036],
            [65.135593000000028, 25.30377100000004],
            [65.117502000000059, 25.30289700000003],
            [65.105743000000075, 25.305138000000056],
            [65.08974900000004, 25.307682000000057],
            [65.055993000000058, 25.314330000000041],
            [65.03466300000008, 25.317851000000076],
            [65.01741700000008, 25.319275000000061],
            [64.997827000000029, 25.320447000000058],
            [64.968668000000036, 25.32259300000004],
            [64.943715000000054, 25.324297000000058],
            [64.91189200000008, 25.325570000000027],
            [64.887667000000079, 25.326391000000058],
            [64.865232000000049, 25.326374000000044],
            [64.859067000000039, 25.32612000000006],
            [64.831422000000032, 25.324678000000063],
            [64.805600000000084, 25.323453000000029],
            [64.789494000000047, 25.321727000000067],
            [64.728411000000051, 25.314495000000079],
            [64.715016000000048, 25.311256000000071],
            [64.697587000000055, 25.305595000000039],
            [64.689072000000067, 25.302376000000038],
            [64.680157000000065, 25.297401000000036],
            [64.672666000000049, 25.292549000000065],
            [64.661610000000053, 25.283196000000032],
            [64.655577000000051, 25.275876000000039],
            [64.649424000000067, 25.267825000000073],
            [64.645664000000068, 25.261573000000055],
            [64.642641000000083, 25.254951000000062],
            [64.640634000000034, 25.249384000000077],
            [64.638926000000083, 25.242807000000028],
            [64.638578000000052, 25.240263000000027],
            [64.637366000000043, 25.23204000000004],
            [64.637417000000028, 25.224216000000069],
            [64.637611000000049, 25.222384000000034],
            [64.638588000000027, 25.216608000000065],
            [64.63968200000005, 25.213890000000049],
            [64.640054000000077, 25.213043000000027],
            [64.641430000000071, 25.21067400000004],
            [64.642479000000037, 25.20942400000007],
            [64.64357700000005, 25.208365000000072],
            [64.645076000000074, 25.20705300000003],
            [64.646419000000037, 25.20589700000005],
            [64.647730000000081, 25.205332000000055],
            [64.64956600000005, 25.205034000000069],
            [64.651164000000051, 25.204697000000067],
            [64.652132000000051, 25.20441500000004],
            [64.654075000000034, 25.203742000000034],
            [64.656465000000082, 25.203087000000039],
            [64.65869500000008, 25.202617000000032],
            [64.665458000000058, 25.203039000000047],
            [64.672511000000043, 25.203413000000069],
            [64.672370000000058, 25.204817000000048],
            [64.674834000000033, 25.205053000000078],
            [64.674956000000066, 25.203921000000037],
            [64.676528000000076, 25.203992000000028],
            [64.676592000000028, 25.203395000000057],
            [64.674898000000042, 25.203351000000055],
            [64.674936000000059, 25.20265100000006],
            [64.676388000000031, 25.20274100000006],
            [64.67650100000003, 25.202237000000025],
            [64.674931000000072, 25.202140000000043],
            [64.674961000000053, 25.20098500000006],
            [64.670819000000051, 25.200725000000034],
            [64.671263000000067, 25.19417500000003],
            [64.671619000000078, 25.194162000000063],
            [64.671806000000061, 25.193953000000079],
            [64.672266000000036, 25.193647000000055],
            [64.672664000000054, 25.19347700000003],
            [64.67311600000005, 25.193389000000025],
            [64.673852000000068, 25.193073000000027],
            [64.674736000000053, 25.192796000000044],
            [64.676083000000062, 25.198178000000041],
            [64.67650100000003, 25.198168000000067],
            [64.676421000000062, 25.197693000000072],
            [64.679764000000034, 25.197025000000053],
            [64.679937000000052, 25.197140000000047],
            [64.681819000000075, 25.196778000000052],
            [64.683469000000059, 25.199430000000064],
            [64.684888000000058, 25.201693000000034],
            [64.685251000000051, 25.201601000000039],
            [64.684450000000083, 25.200380000000052],
            [64.684504000000061, 25.199380000000076],
            [64.685098000000039, 25.198938000000055],
            [64.686267000000043, 25.198181000000034],
            [64.687072000000057, 25.19754800000004],
            [64.688483000000076, 25.193320000000028],
            [64.688939000000062, 25.193125000000066],
            [64.689342000000067, 25.192819000000043],
            [64.689780000000042, 25.192433000000051],
            [64.690256000000034, 25.19129300000003],
            [64.690950000000043, 25.19036200000005],
            [64.69188900000006, 25.190481000000034],
            [64.69254200000006, 25.190350000000024],
            [64.693193000000065, 25.190481000000034],
            [64.694203000000073, 25.190443000000073],
            [64.694830000000081, 25.190521000000047],
            [64.695333000000062, 25.190383000000054],
            [64.695890000000077, 25.190090000000055],
            [64.696340000000077, 25.189836000000071],
            [64.696650000000034, 25.189585000000022],
            [64.696665000000053, 25.189190000000053],
            [64.696649000000036, 25.188764000000049],
            [64.696401000000037, 25.188103000000069],
            [64.696461000000056, 25.187324000000046],
            [64.695977000000028, 25.186403000000041],
            [64.695268000000056, 25.185725000000048],
            [64.695007000000032, 25.184876000000031],
            [64.69482400000004, 25.184631000000024],
            [64.694631000000072, 25.18447100000003],
            [64.694205000000068, 25.184266000000036],
            [64.693894000000057, 25.183926000000042],
            [64.693576000000064, 25.183673000000056],
            [64.693256000000076, 25.183448000000055],
            [64.69290600000005, 25.183194000000071],
            [64.692631000000063, 25.182860000000062],
            [64.692305000000033, 25.182755000000043],
            [64.691911000000061, 25.182675000000074],
            [64.691418000000056, 25.182590000000062],
            [64.691041000000041, 25.18224900000007],
            [64.690759000000071, 25.181971000000033],
            [64.690334000000064, 25.181892000000062],
            [64.68971300000004, 25.181775000000073],
            [64.688863000000083, 25.181767000000036],
            [64.688016000000061, 25.181708000000071],
            [64.68712800000003, 25.181891000000064],
            [64.686722000000032, 25.182079000000044],
            [64.686465000000055, 25.18225700000005],
            [64.68609900000007, 25.182052000000056],
            [64.685460000000035, 25.181824000000063],
            [64.684685000000059, 25.181680000000028],
            [64.68400900000006, 25.181752000000074],
            [64.683021000000053, 25.181403000000046],
            [64.682119000000057, 25.181302000000073],
            [64.681414000000075, 25.18104100000005],
            [64.679734000000053, 25.18049300000007],
            [64.678447000000062, 25.180145000000039],
            [64.677369000000056, 25.179953000000069],
            [64.67668800000007, 25.179744000000028],
            [64.676065000000051, 25.179685000000063],
            [64.675077000000044, 25.179742000000033],
            [64.67454500000008, 25.179913000000056],
            [64.673897000000068, 25.179791000000023],
            [64.673092000000054, 25.179675000000032],
            [64.672153000000037, 25.179299000000071],
            [64.67136000000005, 25.179166000000066],
            [64.670355000000029, 25.179109000000039],
            [64.670252000000062, 25.178944000000058],
            [64.669658000000084, 25.178697000000057],
            [64.669046000000037, 25.178744000000052],
            [64.667954000000066, 25.178563000000054],
            [64.667354000000046, 25.17812200000003],
            [64.666817000000037, 25.177856000000077],
            [64.666255000000035, 25.177549000000056],
            [64.665756000000044, 25.177273000000071],
            [64.665212000000054, 25.176952000000028],
            [64.664154000000053, 25.176763000000051],
            [64.663165000000049, 25.176652000000047],
            [64.662526000000071, 25.176557000000059],
            [64.661931000000038, 25.176148000000069],
            [64.661753000000033, 25.176302000000078],
            [64.661416000000031, 25.176025000000038],
            [64.66129200000006, 25.176326000000074],
            [64.661048000000051, 25.176097000000027],
            [64.660766000000081, 25.17589300000003],
            [64.660257000000058, 25.175741000000073],
            [64.659926000000041, 25.175436000000047],
            [64.659511000000066, 25.175179000000071],
            [64.658608000000072, 25.175135000000068],
            [64.657466000000056, 25.174982000000057],
            [64.657061000000056, 25.174701000000027],
            [64.656188000000043, 25.174499000000026],
            [64.655464000000052, 25.174435000000074],
            [64.654774000000032, 25.174656000000027],
            [64.654284000000075, 25.174445000000048],
            [64.654049000000043, 25.174240000000054],
            [64.653724000000068, 25.174198000000047],
            [64.653126000000043, 25.174197000000049],
            [64.652884000000029, 25.174299000000076],
            [64.652145000000075, 25.174191000000064],
            [64.650532000000055, 25.173656000000051],
            [64.649363000000051, 25.173275000000046],
            [64.648185000000069, 25.173125000000027],
            [64.647178000000054, 25.172903000000076],
            [64.646559000000082, 25.172648000000038],
            [64.645999000000074, 25.172766000000024],
            [64.64525100000003, 25.172700000000077],
            [64.644359000000065, 25.172406000000024],
            [64.643857000000082, 25.172343000000069],
            [64.64325000000008, 25.17205000000007],
            [64.642840000000035, 25.17205800000005],
            [64.642204000000049, 25.171996000000036],
            [64.641212000000053, 25.171843000000024],
            [64.64029400000004, 25.171775000000025],
            [64.639525000000049, 25.171829000000059],
            [64.639189000000044, 25.171811000000048],
            [64.638936000000058, 25.171652000000051],
            [64.638523000000077, 25.171244000000058],
            [64.63806100000005, 25.171132000000057],
            [64.637350000000083, 25.170955000000049],
            [64.636419000000046, 25.170848000000035],
            [64.635342000000037, 25.170602000000031],
            [64.63421100000005, 25.170445000000029],
            [64.633417000000065, 25.170180000000073],
            [64.632445000000075, 25.169986000000051],
            [64.631825000000049, 25.169924000000037],
            [64.631136000000083, 25.169943000000046],
            [64.630763000000059, 25.169697000000042],
            [64.630417000000079, 25.169507000000067],
            [64.630235000000027, 25.169297000000029],
            [64.629140000000064, 25.169173000000058],
            [64.628709000000072, 25.168779000000029],
            [64.628490000000056, 25.168626000000074],
            [64.628294000000039, 25.168728000000044],
            [64.62785400000007, 25.168507000000034],
            [64.627510000000029, 25.168262000000027],
            [64.627036000000032, 25.167952000000071],
            [64.626532000000054, 25.167697000000032],
            [64.626001000000031, 25.167554000000052],
            [64.625689000000079, 25.167481000000066],
            [64.625407000000052, 25.167091000000028],
            [64.624876000000029, 25.166777000000025],
            [64.624034000000051, 25.16611800000004],
            [64.62391000000008, 25.165912000000048],
            [64.623601000000065, 25.165497000000073],
            [64.623309000000063, 25.16537100000005],
            [64.622799000000043, 25.165237000000047],
            [64.622047000000066, 25.165181000000075],
            [64.621222000000046, 25.165296000000069],
            [64.62044700000007, 25.165411000000063],
            [64.620046000000059, 25.165513000000033],
            [64.619695000000036, 25.165875000000028],
            [64.619234000000063, 25.166037000000074],
            [64.618747000000042, 25.16611000000006],
            [64.618241000000069, 25.166174000000069],
            [64.617910000000052, 25.166097000000036],
            [64.617643000000044, 25.165733000000046],
            [64.617257000000052, 25.165538000000026],
            [64.616775000000075, 25.165339000000074],
            [64.616126000000065, 25.165224000000023],
            [64.61511900000005, 25.165508000000045],
            [64.614253000000076, 25.165646000000038],
            [64.613725000000045, 25.165652000000023],
            [64.61330300000003, 25.165545000000066],
            [64.61311900000004, 25.165297000000066],
            [64.612711000000047, 25.164949000000036],
            [64.612276000000065, 25.164484000000073],
            [64.611744000000044, 25.163957000000039],
            [64.611075000000028, 25.163453000000061],
            [64.610555000000033, 25.163252000000057],
            [64.609794000000079, 25.163249000000064],
            [64.609236000000067, 25.16318700000005],
            [64.608845000000031, 25.163328000000035],
            [64.608398000000079, 25.163355000000024],
            [64.607824000000051, 25.163144000000045],
            [64.607046000000082, 25.162859000000026],
            [64.606530000000078, 25.162738000000047],
            [64.60616600000003, 25.162718000000041],
            [64.605877000000078, 25.162931000000071],
            [64.605318000000068, 25.162766000000033],
            [64.604493000000048, 25.162884000000076],
            [64.603887000000043, 25.162696000000039],
            [64.603535000000079, 25.16239500000006],
            [64.603214000000037, 25.162021000000038],
            [64.602843000000064, 25.161879000000056],
            [64.602492000000041, 25.161552000000029],
            [64.602010000000064, 25.161143000000038],
            [64.601541000000054, 25.160974000000067],
            [64.601108000000067, 25.160763000000031],
            [64.600596000000053, 25.160405000000026],
            [64.600179000000082, 25.159995000000038],
            [64.599832000000049, 25.159618000000023],
            [64.599412000000029, 25.159429000000046],
            [64.598924000000068, 25.159310000000062],
            [64.598573000000044, 25.159368000000029],
            [64.598205000000064, 25.159254000000033],
            [64.597760000000051, 25.159159000000045],
            [64.59725700000007, 25.15916100000004],
            [64.596785000000068, 25.159240000000068],
            [64.596454000000051, 25.159276000000034],
            [64.59614700000003, 25.15933700000005],
            [64.595798000000059, 25.159297000000038],
            [64.595491000000038, 25.159359000000052],
            [64.595193000000052, 25.159519000000046],
            [64.594937000000073, 25.159410000000037],
            [64.594699000000048, 25.159325000000024],
            [64.594329000000073, 25.159109000000058],
            [64.59393300000005, 25.158818000000053],
            [64.593579000000034, 25.158728000000053],
            [64.593494000000078, 25.158274000000063],
            [64.593270000000075, 25.158291000000077],
            [64.593117000000063, 25.158035000000041],
            [64.593332000000032, 25.157597000000067],
            [64.593712000000039, 25.157189000000074],
            [64.593525000000056, 25.157011000000068],
            [64.59323900000004, 25.156585000000064],
            [64.593341000000066, 25.156262000000027],
            [64.592994000000033, 25.156075000000044],
            [64.592490000000055, 25.155733000000055],
            [64.591919000000075, 25.155642000000057],
            [64.591162000000054, 25.155524000000071],
            [64.59105900000003, 25.155391000000066],
            [64.59025500000007, 25.155028000000073],
            [64.589117000000044, 25.155084000000045],
            [64.588113000000078, 25.154991000000052],
            [64.587450000000047, 25.155077000000063],
            [64.58696400000008, 25.155543000000023],
            [64.586250000000064, 25.155622000000051],
            [64.585898000000043, 25.155767000000026],
            [64.585767000000033, 25.155963000000042],
            [64.58511800000008, 25.156057000000033],
            [64.584471000000065, 25.156151000000023],
            [64.583841000000064, 25.156195000000025],
            [64.583053000000064, 25.156427000000065],
            [64.582247000000052, 25.156747000000053],
            [64.581434000000058, 25.157248000000038],
            [64.580830000000049, 25.157934000000068],
            [64.58047700000003, 25.158828000000028],
            [64.580132000000049, 25.159565000000043],
            [64.579294000000061, 25.16055700000004],
            [64.57895300000007, 25.16121400000003],
            [64.578648000000044, 25.162032000000067],
            [64.578179000000034, 25.162644000000057],
            [64.57783900000004, 25.164132000000052],
            [64.577679000000046, 25.165509000000043],
            [64.577118000000041, 25.166277000000036],
            [64.576629000000082, 25.167324000000065],
            [64.575974000000031, 25.168125000000032],
            [64.575219000000061, 25.169120000000078],
            [64.575300000000027, 25.170033000000046],
            [64.575061000000062, 25.170418000000041],
            [64.57468700000004, 25.170877000000075],
            [64.574922000000072, 25.17140100000006],
            [64.574774000000048, 25.171711000000073],
            [64.57509600000003, 25.172239000000047],
            [64.574797000000046, 25.172938000000045],
            [64.574797000000046, 25.173768000000052],
            [64.575102000000072, 25.174612000000025],
            [64.575372000000073, 25.17529600000006],
            [64.575638000000083, 25.176058000000069],
            [64.57574100000005, 25.176577000000066],
            [64.575789000000043, 25.17733000000004],
            [64.575876000000051, 25.178164000000038],
            [64.576200000000028, 25.178653000000054],
            [64.576918000000035, 25.179119000000071],
            [64.577429000000052, 25.179378000000042],
            [64.57788400000004, 25.179872000000046],
            [64.578834000000029, 25.180153000000075],
            [64.579021000000068, 25.18067300000007],
            [64.579499000000055, 25.180733000000032],
            [64.579841000000044, 25.180866000000037],
            [64.580369000000076, 25.180810000000065],
            [64.58094200000005, 25.180841000000044],
            [64.581503000000055, 25.180780000000027],
            [64.581982000000039, 25.181172000000061],
            [64.582728000000031, 25.18108600000005],
            [64.58329400000008, 25.181026000000031],
            [64.583935000000054, 25.181164000000024],
            [64.584392000000037, 25.180874000000074],
            [64.58526100000006, 25.180427000000066],
            [64.586185000000057, 25.180097000000046],
            [64.587382000000048, 25.179857000000027],
            [64.588383000000078, 25.179870000000051],
            [64.589285000000075, 25.180078000000037],
            [64.590336000000036, 25.180712000000028],
            [64.591257000000041, 25.18131900000003],
            [64.591911000000039, 25.182242000000031],
            [64.592385000000036, 25.18313500000005],
            [64.592629000000045, 25.184225000000026],
            [64.592768000000035, 25.184903000000077],
            [64.593076000000053, 25.185707000000036],
            [64.593346000000054, 25.187221000000022],
            [64.59346800000003, 25.188215000000071],
            [64.593574000000046, 25.188694000000055],
            [64.594120000000032, 25.189113000000077],
            [64.594496000000049, 25.189446000000032],
            [64.594592000000034, 25.190122000000031],
            [64.594521000000043, 25.190633000000048],
            [64.594752000000028, 25.19123600000006],
            [64.595306000000051, 25.191537000000039],
            [64.595510000000047, 25.191996000000074],
            [64.595530000000053, 25.192858000000058],
            [64.596069000000057, 25.19402800000006],
            [64.596830000000068, 25.194785000000024],
            [64.597854000000041, 25.195614000000035],
            [64.598561000000075, 25.196128000000044],
            [64.599163000000033, 25.196164000000067],
            [64.599968000000047, 25.196222000000034],
            [64.600823000000048, 25.196447000000035],
            [64.601596000000029, 25.196966000000032],
            [64.602290000000039, 25.197646000000077],
            [64.603060000000028, 25.198228000000029],
            [64.603630000000067, 25.198802000000057],
            [64.603841000000045, 25.199805000000026],
            [64.604477000000031, 25.200384000000042],
            [64.605462000000045, 25.20064700000006],
            [64.609478000000081, 25.201466000000039],
            [64.609785000000045, 25.202119000000039],
            [64.610411000000056, 25.202981000000023],
            [64.611670000000061, 25.202931000000035],
            [64.612705000000062, 25.203519000000028],
            [64.614194000000055, 25.204478000000051],
            [64.615015000000028, 25.205415000000073],
            [64.61734000000007, 25.205401000000052],
            [64.617951000000062, 25.206628000000023],
            [64.618368000000032, 25.207668000000069],
            [64.618237000000079, 25.208929000000069],
            [64.618159000000048, 25.211762000000078],
            [64.617110000000082, 25.216559000000075],
            [64.614898000000039, 25.223107000000027],
            [64.611276000000032, 25.229776000000072],
            [64.608802000000082, 25.233713000000023],
            [64.605181000000073, 25.238375000000076],
            [64.602152000000046, 25.241476000000034],
            [64.598288000000082, 25.245501000000047],
            [64.595231000000069, 25.248388000000034],
            [64.587799000000075, 25.253359000000046],
            [64.585641000000066, 25.254828000000032],
            [64.581414000000052, 25.257558000000074],
            [64.575372000000073, 25.260458000000028],
            [64.56736200000006, 25.26348500000006],
            [64.557592000000056, 25.266706000000056],
            [64.552162000000067, 25.26838900000007],
            [64.545385000000067, 25.270332000000053],
            [64.540175000000033, 25.27159800000004],
            [64.535270000000082, 25.27234100000004],
            [64.527698000000044, 25.273180000000025],
            [64.522838000000036, 25.273544000000072],
            [64.517532000000074, 25.273633000000075],
            [64.513385000000028, 25.27334600000006],
            [64.511243000000036, 25.272966000000054],
            [64.509802000000036, 25.272683000000029],
            [64.508681000000081, 25.272387000000037],
            [64.50777400000004, 25.271788000000072],
            [64.507487000000083, 25.271278000000052],
            [64.507701000000054, 25.270222000000047],
            [64.50732000000005, 25.269993000000056],
            [64.505290000000059, 25.269048000000055],
            [64.503521000000035, 25.268369000000064],
            [64.501520000000028, 25.26760900000005],
            [64.498564000000044, 25.266485000000046],
            [64.496332000000052, 25.265643000000068],
            [64.493863000000033, 25.264826000000028],
            [64.492943000000082, 25.264395000000036],
            [64.492464000000041, 25.264587000000063],
            [64.492073000000062, 25.264606000000072],
            [64.491532000000063, 25.264476000000059],
            [64.49127500000003, 25.264074000000051],
            [64.491208000000029, 25.263787000000036],
            [64.488927000000047, 25.263028000000077],
            [64.488642000000027, 25.26319200000006],
            [64.488180000000057, 25.263662000000068],
            [64.487859000000071, 25.263790000000029],
            [64.487150000000042, 25.263866000000064],
            [64.486298000000033, 25.263690000000054],
            [64.48557100000005, 25.263414000000068],
            [64.484915000000058, 25.263246000000038],
            [64.483212000000037, 25.262741000000062],
            [64.481944000000055, 25.262303000000031],
            [64.479942000000051, 25.261536000000035],
            [64.478526000000045, 25.260811000000047],
            [64.476553000000081, 25.259580000000028],
            [64.475121000000058, 25.25851700000004],
            [64.473965000000078, 25.25736900000004],
            [64.472980000000064, 25.255943000000059],
            [64.472004000000084, 25.254409000000066],
            [64.471898000000067, 25.254191000000048],
            [64.471256000000039, 25.253027000000031],
            [64.47105300000004, 25.250996000000043],
            [64.471266000000071, 25.24919600000004],
            [64.471739000000071, 25.248473000000047],
            [64.472358000000042, 25.247897000000023],
            [64.472870000000057, 25.247849000000031],
            [64.473304000000041, 25.247796000000051],
            [64.473853000000076, 25.247785000000079],
            [64.47420900000003, 25.247730000000047],
            [64.474949000000038, 25.247797000000048],
            [64.47531500000008, 25.247565000000066],
            [64.475694000000033, 25.247086000000024],
            [64.476069000000052, 25.246677000000034],
            [64.476499000000047, 25.246694000000048],
            [64.476769000000047, 25.246030000000076],
            [64.476233000000036, 25.245795000000044],
            [64.475734000000045, 25.245596000000035],
            [64.475196000000039, 25.24539500000003],
            [64.474571000000083, 25.245332000000076],
            [64.474147000000073, 25.245037000000025],
            [64.473172000000034, 25.24488800000006],
            [64.471791000000053, 25.244970000000023],
            [64.470222000000035, 25.244938000000047],
            [64.468630000000076, 25.244655000000023],
            [64.468065000000081, 25.244274000000075],
            [64.466739000000075, 25.244110000000035],
            [64.465976000000069, 25.243722000000048],
            [64.46489500000007, 25.243391000000031],
            [64.463660000000061, 25.243017000000066],
            [64.463048000000072, 25.242742000000078],
            [64.46182600000003, 25.24215300000003],
            [64.461215000000038, 25.241842000000076],
            [64.460271000000034, 25.241872000000058],
            [64.459138000000053, 25.241790000000037],
            [64.45788600000003, 25.241735000000062],
            [64.456633000000068, 25.241679000000033],
            [64.455303000000072, 25.241621000000066],
            [64.454064000000074, 25.241319000000033],
            [64.452638000000036, 25.241612000000032],
            [64.452054000000032, 25.241728000000023],
            [64.451332000000036, 25.241946000000041],
            [64.450489000000061, 25.242090000000076],
            [64.449931000000049, 25.242139000000066],
            [64.449195000000032, 25.24196500000005],
            [64.449056000000041, 25.24174400000004],
            [64.449117000000058, 25.241496000000041],
            [64.448942000000045, 25.241237000000069],
            [64.448138000000029, 25.240917000000024],
            [64.447312000000068, 25.240848000000028],
            [64.446233000000063, 25.240585000000067],
            [64.445581000000061, 25.240377000000024],
            [64.445172000000071, 25.240542000000062],
            [64.444182000000069, 25.240609000000063],
            [64.443257000000074, 25.24038900000005],
            [64.44272600000005, 25.240148000000033],
            [64.442398000000082, 25.239810000000034],
            [64.442054000000041, 25.240193000000033],
            [64.441341000000079, 25.240237000000036],
            [64.440237000000081, 25.240367000000049],
            [64.439260000000047, 25.240325000000041],
            [64.437914000000035, 25.23987500000004],
            [64.435302000000036, 25.239635000000078],
            [64.434314000000029, 25.239119000000073],
            [64.433308000000068, 25.238896000000068],
            [64.432555000000036, 25.239076000000068],
            [64.431106000000057, 25.239000000000033],
            [64.429619000000059, 25.239280000000065],
            [64.428380000000061, 25.238933000000031],
            [64.427644000000043, 25.238868000000025],
            [64.426251000000036, 25.239002000000028],
            [64.425592000000051, 25.238754000000029],
            [64.423638000000039, 25.239041000000043],
            [64.422288000000037, 25.238786000000061],
            [64.420960000000036, 25.238434000000041],
            [64.419926000000032, 25.238503000000037],
            [64.418278000000043, 25.23831000000007],
            [64.416855000000055, 25.238431000000048],
            [64.414963000000057, 25.238296000000048],
            [64.412928000000079, 25.238114000000053],
            [64.410976000000062, 25.238127000000077],
            [64.408966000000078, 25.238399000000072],
            [64.407199000000048, 25.238593000000037],
            [64.406068000000062, 25.238652000000059],
            [64.403659000000061, 25.238541000000055],
            [64.402295000000038, 25.238664000000028],
            [64.401317000000063, 25.238613000000043],
            [64.399898000000064, 25.238823000000025],
            [64.398435000000063, 25.23880900000006],
            [64.397735000000068, 25.239236000000062],
            [64.39608400000003, 25.239524000000074],
            [64.39445500000005, 25.239702000000023],
            [64.392270000000053, 25.240337000000068],
            [64.389992000000063, 25.240961000000027],
            [64.388326000000063, 25.241313000000048],
            [64.386768000000075, 25.241593000000023],
            [64.383115000000032, 25.242449000000079],
            [64.381404000000032, 25.242995000000064],
            [64.381364000000076, 25.243009000000029],
            [64.380078000000083, 25.243449000000055],
            [64.378674000000046, 25.244081000000051],
            [64.378202000000044, 25.244842000000062],
            [64.37786200000005, 25.245483000000036],
            [64.377653000000066, 25.246424000000047],
            [64.37753500000008, 25.24735000000004],
            [64.377425000000073, 25.248042000000055],
            [64.377286000000083, 25.249299000000065],
            [64.376859000000081, 25.250056000000029],
            [64.376149000000055, 25.250875000000065],
            [64.375203000000056, 25.251685000000066],
            [64.374273000000073, 25.252922000000069],
            [64.365814000000057, 25.257588000000055],
            [64.364079000000061, 25.258503000000076],
            [64.36298800000003, 25.258649000000048],
            [64.360494000000074, 25.259748000000059],
            [64.357322000000067, 25.261024000000077],
            [64.35477700000007, 25.262331000000074],
            [64.339402000000064, 25.269233000000042],
            [64.317871000000082, 25.276865000000043],
            [64.313791000000037, 25.278062000000034],
            [64.30659600000007, 25.280847000000051],
            [64.29389400000008, 25.285203000000024],
            [64.287181000000032, 25.287288000000046],
            [64.280850000000044, 25.289053000000024],
            [64.27646500000003, 25.290347000000054],
            [64.273443000000043, 25.291131000000064],
            [64.269104000000027, 25.292387000000076],
            [64.265103000000067, 25.293595000000039],
            [64.262669000000074, 25.294337000000041],
            [64.260443000000066, 25.294943000000046],
            [64.258770000000084, 25.295399000000032],
            [64.256928000000073, 25.296096000000034],
            [64.255331000000069, 25.29662600000006],
            [64.253382000000045, 25.297069000000079],
            [64.251427000000035, 25.297584000000029],
            [64.249353000000042, 25.298093000000051],
            [64.247697000000073, 25.298302000000035],
            [64.246345000000076, 25.298595000000034],
            [64.245446000000072, 25.298555000000079],
            [64.244346000000064, 25.298612000000048],
            [64.243147000000079, 25.298983000000078],
            [64.242233000000056, 25.299153000000047],
            [64.241097000000082, 25.29913700000003],
            [64.239728000000071, 25.299076000000071],
            [64.235027000000059, 25.300352000000032],
            [64.233246000000065, 25.300726000000054],
            [64.232825000000048, 25.300497000000064],
            [64.232255000000066, 25.300051000000053],
            [64.231609000000049, 25.299632000000031],
            [64.230758000000037, 25.299381000000039],
            [64.230362000000071, 25.299434000000076],
            [64.230305000000044, 25.299751000000072],
            [64.230275000000063, 25.300317000000064],
            [64.229903000000036, 25.300793000000056],
            [64.229660000000081, 25.300711000000035],
            [64.22856900000005, 25.301409000000035],
            [64.228750000000048, 25.30197400000003],
            [64.228846000000033, 25.302740000000028],
            [64.229052000000081, 25.303278000000034],
            [64.229336000000046, 25.303786000000059],
            [64.229704000000083, 25.304225000000031],
            [64.230538000000081, 25.304883000000075],
            [64.23125900000008, 25.305397000000028],
            [64.230538000000081, 25.306845000000067],
            [64.229745000000037, 25.308008000000029],
            [64.228782000000081, 25.308681000000036],
            [64.22834800000004, 25.309322000000066],
            [64.228662000000043, 25.310664000000031],
            [64.228071000000057, 25.31063400000005],
            [64.227400000000046, 25.310840000000042],
            [64.22680100000008, 25.310930000000042],
            [64.226221000000066, 25.310719000000063],
            [64.225177000000031, 25.310371000000032],
            [64.223941000000082, 25.311038000000053],
            [64.219838000000038, 25.311933000000067],
            [64.214713000000074, 25.313265000000058],
            [64.198927000000083, 25.316162000000077],
            [64.185609000000056, 25.317953000000045],
            [64.163177000000076, 25.320828000000063],
            [64.15337500000004, 25.322601000000077],
            [64.146352000000036, 25.323360000000037],
            [64.138416000000063, 25.323836000000028],
            [64.126390000000072, 25.323762000000045],
            [64.118276000000037, 25.322846000000027],
            [64.107935000000055, 25.322308000000078],
            [64.104244000000051, 25.32291900000007],
            [64.102029000000073, 25.323297000000025],
            [64.101248602000055, 25.323475173000077],
            [64.100999000000058, 25.332017000000064],
            [64.095102000000054, 25.347895000000051],
            [64.100545000000068, 25.357421000000045],
            [64.109618000000069, 25.35606000000007],
            [64.122321000000056, 25.353792000000055],
            [64.130940000000066, 25.365587000000062],
            [64.114608000000032, 25.377382000000068],
            [64.099184000000037, 25.381465000000048],
            [64.091019000000074, 25.390538000000049],
            [64.106896000000063, 25.396435000000054],
            [64.120052000000044, 25.400971000000027],
            [64.134569000000056, 25.405054000000064],
            [64.147725000000037, 25.399157000000059],
            [64.157975000000079, 25.41606100000007],
            [64.168887000000041, 25.432189000000051],
            [64.174653000000035, 25.443599000000063],
            [64.176566000000037, 25.45217400000007],
            [64.173170000000027, 25.457138000000043],
            [64.15618900000004, 25.461318000000063],
            [64.132938000000081, 25.463408000000072],
            [64.127148504000047, 25.464179815000023],
            [64.113345000000038, 25.466020000000071],
            [64.104489000000058, 25.469767000000047],
            [64.081946000000073, 25.461307000000033],
            [64.047922000000028, 25.463121000000058],
            [64.02798800000005, 25.46966800000007],
            [64.010269000000051, 25.458585000000028],
            [63.998099000000082, 25.437886000000049],
            [63.983503000000042, 25.434995000000072],
            [63.969894000000068, 25.42093200000005],
            [63.972162000000083, 25.409591000000034],
            [63.983050000000048, 25.395074000000022],
            [63.99348400000008, 25.385094000000038],
            [64.011176000000034, 25.385094000000038],
            [64.020993000000033, 25.398915000000045],
            [64.019897000000071, 25.403992000000073],
            [64.042478000000074, 25.402332000000058],
            [64.043385000000058, 25.386455000000069],
            [64.049283000000059, 25.374660000000063],
            [64.056995000000029, 25.361958000000072],
            [64.067882000000054, 25.348802000000035],
            [64.061531000000059, 25.333378000000039],
            [64.061936243000048, 25.328524119000065],
            [64.058844000000079, 25.326663000000053],
            [64.056517000000042, 25.325533000000064],
            [64.053484000000083, 25.325091000000043],
            [64.050957000000039, 25.32509600000003],
            [64.047501000000068, 25.325113000000044],
            [64.012059000000079, 25.328967000000034],
            [63.982295000000079, 25.333448000000033],
            [63.960313000000042, 25.335686000000067],
            [63.918876000000068, 25.338647000000037],
            [63.911592000000041, 25.339141000000041],
            [63.897730000000081, 25.34082600000005],
            [63.879958000000045, 25.344372000000078],
            [63.853581000000077, 25.350455000000068],
            [63.835721000000035, 25.354988000000048],
            [63.821650000000034, 25.359059000000059],
            [63.808587000000045, 25.363001000000054],
            [63.799401000000046, 25.365375000000029],
            [63.782229000000029, 25.369646000000046],
            [63.76954900000004, 25.37286800000004],
            [63.76348900000005, 25.374137000000076],
            [63.74544300000008, 25.378009000000077],
            [63.728198000000077, 25.381191000000058],
            [63.716597000000036, 25.382847000000027],
            [63.699153000000081, 25.38439500000004],
            [63.695044000000053, 25.384374000000037],
            [63.677433000000065, 25.385252000000037],
            [63.668614000000048, 25.384996000000058],
            [63.659005000000036, 25.384824000000037],
            [63.651680000000056, 25.383983000000057],
            [63.649102000000028, 25.383675000000039],
            [63.648127000000045, 25.383266000000049],
            [63.647366000000034, 25.382689000000028],
            [63.646089000000075, 25.38184700000005],
            [63.644417000000033, 25.380985000000067],
            [63.624846000000048, 25.378705000000025],
            [63.614796000000069, 25.377171000000033],
            [63.59504400000003, 25.374154000000033],
            [63.589754000000028, 25.373115000000041],
            [63.586528000000044, 25.372230000000059],
            [63.583715000000041, 25.371309000000053],
            [63.577589000000046, 25.369565000000023],
            [63.573630000000037, 25.368108000000063],
            [63.572148000000027, 25.367374000000041],
            [63.571645000000046, 25.36687100000006],
            [63.571286000000043, 25.366251000000034],
            [63.571074000000067, 25.36539700000003],
            [63.570260000000076, 25.364575000000059],
            [63.569365000000062, 25.363991000000055],
            [63.568093000000033, 25.363330000000076],
            [63.565151000000071, 25.362018000000035],
            [63.563663000000076, 25.361278000000027],
            [63.562383000000068, 25.36062000000004],
            [63.560983000000078, 25.359777000000065],
            [63.560049000000049, 25.358835000000056],
            [63.559180000000083, 25.357894000000044],
            [63.558945000000051, 25.357150000000047],
            [63.558879000000047, 25.356194000000073],
            [63.558449000000053, 25.355508000000043],
            [63.557596000000046, 25.355282000000045],
            [63.556762000000049, 25.354947000000038],
            [63.554456000000073, 25.353823000000034],
            [63.542791000000079, 25.350105000000042],
            [63.532309000000055, 25.346763000000067],
            [63.523003000000074, 25.342390000000023],
            [63.514947000000063, 25.338260000000048],
            [63.507926000000054, 25.333582000000035],
            [63.503739000000053, 25.330393000000072],
            [63.499850000000038, 25.327162000000044],
            [63.494578000000047, 25.322025000000053],
            [63.492012000000045, 25.319368000000054],
            [63.489088000000038, 25.31628500000005],
            [63.48644900000005, 25.312727000000052],
            [63.483326000000034, 25.308361000000048],
            [63.48177000000004, 25.305580000000077],
            [63.481174000000067, 25.303389000000038],
            [63.48111300000005, 25.301163000000031],
            [63.481173000000069, 25.298043000000064],
            [63.48028000000005, 25.295297000000062],
            [63.47852400000005, 25.292568000000074],
            [63.477448000000038, 25.290606000000025],
            [63.475647000000038, 25.288588000000061],
            [63.474554000000069, 25.287995000000024],
            [63.472497000000033, 25.286871000000076],
            [63.470400000000041, 25.286350000000027],
            [63.468451000000073, 25.285593000000063],
            [63.469119000000035, 25.274944000000062],
            [63.471944000000065, 25.274350000000027],
            [63.475173000000041, 25.271742000000074],
            [63.47814900000003, 25.270496000000037],
            [63.477742000000035, 25.270085000000051],
            [63.47713200000004, 25.268994000000077],
            [63.476901000000055, 25.267755000000022],
            [63.476527000000033, 25.265841000000023],
            [63.477411000000075, 25.265493000000049],
            [63.478534000000082, 25.265269000000046],
            [63.478681000000051, 25.264885000000049],
            [63.479092000000037, 25.263230000000078],
            [63.48071200000004, 25.263060000000053],
            [63.479970000000037, 25.26157900000004],
            [63.477764000000036, 25.256459000000063],
            [63.477143000000069, 25.252936000000034],
            [63.476716000000067, 25.250334000000066],
            [63.476787000000058, 25.247833000000071],
            [63.477281000000062, 25.243911000000026],
            [63.477906000000075, 25.241970000000038],
            [63.478460000000041, 25.241163000000029],
            [63.479371000000071, 25.239995000000079],
            [63.48046800000003, 25.238532000000077],
            [63.483251000000052, 25.235640000000046],
            [63.484770000000083, 25.234123000000068],
            [63.486793000000034, 25.232634000000076],
            [63.489256000000069, 25.231376000000068],
            [63.490090000000066, 25.230890000000045],
            [63.490980000000036, 25.230381000000023],
            [63.491954000000078, 25.229986000000054],
            [63.492563000000075, 25.229990000000043],
            [63.493046000000049, 25.230043000000023],
            [63.493518000000051, 25.230262000000039],
            [63.493972000000042, 25.230314000000078],
            [63.494497000000081, 25.230257000000051],
            [63.495050000000049, 25.230257000000051],
            [63.495731000000035, 25.230234000000053],
            [63.495783000000074, 25.230375000000038],
            [63.495685000000037, 25.230454000000066],
            [63.495144000000039, 25.230706000000055],
            [63.494485000000054, 25.230950000000064],
            [63.493806000000063, 25.231026000000043],
            [63.493060000000071, 25.230765000000076],
            [63.492595000000051, 25.230436000000054],
            [63.492277000000058, 25.230641000000048],
            [63.492173000000037, 25.230830000000026],
            [63.492732000000046, 25.231136000000049],
            [63.493236000000081, 25.231330000000071],
            [63.494816000000071, 25.23154900000003],
            [63.495378000000073, 25.231383000000051],
            [63.496127000000058, 25.231171000000074],
            [63.496754000000067, 25.230953000000056],
            [63.497445000000027, 25.230683000000056],
            [63.497699000000068, 25.230058000000042],
            [63.497783000000084, 25.230672000000027],
            [63.49804800000004, 25.230852000000027],
            [63.49868500000008, 25.230967000000078],
            [63.499350000000049, 25.230613000000062],
            [63.49974300000008, 25.230216000000041],
            [63.500709000000029, 25.229461000000072],
            [63.501681000000076, 25.228412000000048],
            [63.502676000000065, 25.227678000000026],
            [63.50201700000008, 25.22898900000007],
            [63.500546000000043, 25.230746000000067],
            [63.500049000000047, 25.231431000000043],
            [63.499118000000067, 25.232467000000042],
            [63.498411000000033, 25.233216000000027],
            [63.497679000000062, 25.233702000000051],
            [63.497331000000031, 25.23465600000003],
            [63.497164000000055, 25.235358000000076],
            [63.496702000000028, 25.236157000000048],
            [63.495491000000072, 25.237030000000061],
            [63.494352000000049, 25.23742100000004],
            [63.492572000000052, 25.238153000000068],
            [63.491544000000033, 25.238736000000074],
            [63.490423000000078, 25.239502000000073],
            [63.489400000000046, 25.240011000000038],
            [63.488185000000044, 25.240286000000026],
            [63.487149000000045, 25.240346000000045],
            [63.486764000000051, 25.240550000000042],
            [63.486778000000072, 25.240999000000045],
            [63.487103000000047, 25.24109100000004],
            [63.487682000000063, 25.241082000000063],
            [63.488384000000053, 25.241080000000068],
            [63.489217000000053, 25.240973000000054],
            [63.490203000000065, 25.241060000000061],
            [63.491336000000047, 25.240781000000027],
            [63.492365000000063, 25.24016000000006],
            [63.493432000000041, 25.239616000000069],
            [63.494644000000051, 25.238706000000036],
            [63.496244000000047, 25.237553000000048],
            [63.496799000000067, 25.237056000000052],
            [63.49733800000007, 25.236360000000047],
            [63.497922000000074, 25.235840000000053],
            [63.498454000000038, 25.235244000000023],
            [63.499010000000055, 25.234723000000031],
            [63.499636000000066, 25.233957000000032],
            [63.500027000000046, 25.23342800000006],
            [63.500621000000081, 25.232735000000048],
            [63.501130000000046, 25.232087000000035],
            [63.501618000000065, 25.231314000000054],
            [63.501954000000069, 25.230334000000028],
            [63.502264000000082, 25.229776000000072],
            [63.503058000000067, 25.228070000000059],
            [63.50350300000008, 25.227097000000072],
            [63.503936000000067, 25.227242000000047],
            [63.50425800000005, 25.227383000000032],
            [63.504377000000034, 25.227688000000057],
            [63.504755000000046, 25.227807000000041],
            [63.504882000000066, 25.227988000000039],
            [63.504977000000054, 25.228242000000023],
            [63.505232000000035, 25.228579000000025],
            [63.505352000000073, 25.228859000000057],
            [63.505561000000057, 25.229044000000044],
            [63.50584200000003, 25.228959000000032],
            [63.505963000000065, 25.22879000000006],
            [63.505978000000027, 25.228542000000061],
            [63.505853000000059, 25.22833600000007],
            [63.50559700000008, 25.228024000000062],
            [63.505310000000065, 25.227760000000046],
            [63.505047000000047, 25.227547000000072],
            [63.505056000000081, 25.227398000000051],
            [63.505154000000061, 25.227154000000041],
            [63.505131000000063, 25.22694400000006],
            [63.505408000000045, 25.226916000000074],
            [63.505663000000027, 25.226763000000062],
            [63.505848000000071, 25.226436000000035],
            [63.505946000000051, 25.22606600000006],
            [63.506035000000054, 25.225600000000043],
            [63.506203000000028, 25.225111000000027],
            [63.506372000000056, 25.225134000000025],
            [63.506725000000074, 25.22513900000007],
            [63.507000000000062, 25.225167000000056],
            [63.507391000000041, 25.225063000000034],
            [63.507755000000031, 25.224873000000059],
            [63.508072000000027, 25.224695000000054],
            [63.508336000000043, 25.224639000000025],
            [63.508604000000048, 25.224514000000056],
            [63.508861000000081, 25.224569000000031],
            [63.508938000000057, 25.224559000000056],
            [63.509025000000065, 25.224383000000046],
            [63.508877000000041, 25.224057000000073],
            [63.508820000000071, 25.223735000000033],
            [63.50865600000003, 25.223672000000079],
            [63.508369000000073, 25.223602000000028],
            [63.507989000000066, 25.223555000000033],
            [63.507534000000078, 25.223477000000059],
            [63.50718500000005, 25.223431000000062],
            [63.506906000000072, 25.223487000000034],
            [63.506561000000033, 25.223594000000048],
            [63.506236000000058, 25.223868000000039],
            [63.505939000000069, 25.224157000000048],
            [63.505673000000058, 25.224162000000035],
            [63.505188000000032, 25.224026000000038],
            [63.504983000000038, 25.223928000000058],
            [63.504702000000066, 25.223810000000071],
            [63.504585000000077, 25.223716000000024],
            [63.504099000000053, 25.223460000000046],
            [63.503968000000043, 25.223366000000055],
            [63.503882000000033, 25.223219000000029],
            [63.503827000000058, 25.222838000000024],
            [63.503815000000031, 25.222758000000056],
            [63.503723000000036, 25.222581000000048],
            [63.503349000000071, 25.222241000000054],
            [63.503186000000028, 25.222124000000065],
            [63.50280500000008, 25.221741000000065],
            [63.502646000000027, 25.221484000000032],
            [63.502479000000051, 25.220928000000072],
            [63.502138000000059, 25.220611000000076],
            [63.50194700000003, 25.220578000000046],
            [63.501580000000047, 25.220568000000071],
            [63.501269000000036, 25.220428000000027],
            [63.500886000000037, 25.220379000000037],
            [63.500360000000057, 25.22021200000006],
            [63.498274000000038, 25.219201000000055],
            [63.497683000000052, 25.218853000000024],
            [63.496178000000043, 25.217918000000054],
            [63.494672000000037, 25.21684300000004],
            [63.493813000000046, 25.216118000000051],
            [63.493257000000028, 25.215388000000075],
            [63.492666000000042, 25.214406000000054],
            [63.492182000000071, 25.213316000000077],
            [63.492019000000028, 25.212718000000052],
            [63.491913000000068, 25.211579000000029],
            [63.492074000000059, 25.210551000000066],
            [63.492307000000039, 25.20998000000003],
            [63.492401000000029, 25.209594000000038],
            [63.492602000000034, 25.20955900000007],
            [63.493000000000052, 25.209575000000029],
            [63.49334900000008, 25.209611000000052],
            [63.493652000000054, 25.209537000000068],
            [63.493903000000046, 25.209442000000024],
            [63.494176000000039, 25.209290000000067],
            [63.494499000000076, 25.209200000000067],
            [63.494874000000038, 25.20913100000007],
            [63.495334000000071, 25.209138000000053],
            [63.49590100000006, 25.208765000000028],
            [63.495865000000038, 25.208043000000032],
            [63.496129000000053, 25.207366000000036],
            [63.496393000000069, 25.206876000000022],
            [63.49652100000003, 25.206461000000047],
            [63.496242000000052, 25.206247000000076],
            [63.496038000000055, 25.205963000000054],
            [63.495879000000059, 25.205790000000036],
            [63.495270000000062, 25.205742000000043],
            [63.494568000000072, 25.205782000000056],
            [63.49418200000008, 25.205715000000055],
            [63.493422000000066, 25.205498000000034],
            [63.492266000000029, 25.205552000000068],
            [63.489008000000069, 25.205429000000038],
            [63.481293000000051, 25.205760000000055],
            [63.458643000000052, 25.209105000000022],
            [63.439120000000059, 25.212294000000043],
            [63.426648000000057, 25.21498900000006],
            [63.404919000000064, 25.218932000000052],
            [63.385208000000034, 25.222264000000052],
            [63.352972000000079, 25.226543000000049],
            [63.332367000000033, 25.228956000000039],
            [63.308473000000049, 25.231045000000051],
            [63.278196000000037, 25.23496700000004],
            [63.25206200000008, 25.23867000000007],
            [63.22536800000006, 25.242337000000077],
            [63.187925000000064, 25.247747000000061],
            [63.156762000000072, 25.253769000000034],
            [63.142246000000057, 25.255747000000042],
            [63.12837200000007, 25.257761000000073],
            [63.117139000000066, 25.258465000000058],
            [63.109407000000033, 25.257762000000071],
            [63.09549700000008, 25.255583000000058],
            [63.090463000000057, 25.254452000000072],
            [63.085020000000043, 25.252139000000057],
            [63.079417000000035, 25.247359000000074],
            [63.076150000000041, 25.243424000000061],
            [63.07607100000007, 25.24196900000004],
            [63.076432000000068, 25.241118000000029],
            [63.077551000000028, 25.237850000000037],
            [63.078687000000059, 25.23474200000004],
            [63.078250000000082, 25.234143000000074],
            [63.077005000000042, 25.233645000000024],
            [63.076207000000068, 25.233603000000073],
            [63.074533000000031, 25.233375000000024],
            [63.065577000000076, 25.231305000000077],
            [63.054410000000075, 25.228851000000077],
            [63.052857000000074, 25.226585000000057],
            [63.040711000000044, 25.223317000000065],
            [63.030325000000062, 25.223957000000041],
            [63.025751000000071, 25.223842000000047],
            [63.019020000000069, 25.222176000000047],
            [63.014720000000068, 25.21977000000004],
            [63.013251000000082, 25.217689000000064],
            [63.009169000000043, 25.211938000000032],
            [63.007305000000031, 25.211115000000063],
            [63.003815000000031, 25.211482000000046],
            [62.993509000000074, 25.211902000000066],
            [62.979008000000078, 25.213481000000058],
            [62.949524000000054, 25.220259000000055],
            [62.907993000000033, 25.229282000000069],
            [62.870287000000076, 25.237011000000052],
            [62.834284000000082, 25.243843000000027],
            [62.807046000000071, 25.249515000000031],
            [62.791997000000038, 25.251046000000031],
            [62.779639000000032, 25.250860000000046],
            [62.771000000000072, 25.249013000000048],
            [62.760741000000053, 25.248471000000052],
            [62.755765000000054, 25.24674600000003],
            [62.722864000000072, 25.253812000000039],
            [62.685913000000028, 25.259720000000073],
            [62.654949963000035, 25.263896948000024],
            [62.631866000000059, 25.267011000000025],
            [62.598962000000029, 25.270253000000025],
            [62.591021000000069, 25.270567000000028],
            [62.566968000000031, 25.269986000000074],
            [62.559664000000055, 25.269634000000053],
            [62.552497000000074, 25.268965000000037],
            [62.547686000000056, 25.268660000000068],
            [62.540257000000054, 25.268564000000026],
            [62.536921000000063, 25.268079000000057],
            [62.535172000000046, 25.267843000000028],
            [62.526474000000064, 25.266656000000069],
            [62.515132000000051, 25.265280000000075],
            [62.50703500000003, 25.26432200000005],
            [62.499643000000049, 25.263205000000028],
            [62.493327000000079, 25.262413000000038],
            [62.488810000000058, 25.261558000000036],
            [62.483967000000064, 25.26065200000005],
            [62.47844200000003, 25.259150000000034],
            [62.476629000000059, 25.258384000000035],
            [62.475467000000037, 25.257723000000055],
            [62.473989000000074, 25.256347000000062],
            [62.471889000000033, 25.254466000000036],
            [62.471019000000069, 25.253821000000073],
            [62.467287000000056, 25.25115900000003],
            [62.464411000000041, 25.248687000000075],
            [62.462872000000061, 25.247119000000055],
            [62.461961000000031, 25.246041000000048],
            [62.46035500000005, 25.243401000000063],
            [62.458921000000032, 25.239717000000041],
            [62.458704000000068, 25.237553000000048],
            [62.458659000000068, 25.233510000000024],
            [62.458885000000066, 25.231372000000079],
            [62.459201000000064, 25.230991000000074],
            [62.459412000000043, 25.229547000000025],
            [62.459686000000033, 25.228836000000058],
            [62.460162000000082, 25.228831000000071],
            [62.460182000000032, 25.228568000000053],
            [62.460470000000043, 25.227661000000069],
            [62.460763000000043, 25.227150000000051],
            [62.461213000000043, 25.226977000000034],
            [62.461916000000031, 25.226954000000035],
            [62.463541000000077, 25.226945000000057],
            [62.464500000000044, 25.226846000000023],
            [62.466381000000069, 25.226394000000028],
            [62.467476000000033, 25.226004000000046],
            [62.468167000000051, 25.225804000000039],
            [62.46888000000007, 25.225986000000034],
            [62.469952000000035, 25.225906000000066],
            [62.471029000000044, 25.22573600000004],
            [62.471865000000037, 25.225774000000058],
            [62.473402000000078, 25.225332000000037],
            [62.473806000000081, 25.225075000000061],
            [62.474415000000079, 25.225232000000062],
            [62.474706000000083, 25.225050000000067],
            [62.475106000000039, 25.224358000000052],
            [62.474735000000067, 25.223960000000034],
            [62.474188000000083, 25.223551000000043],
            [62.47369800000007, 25.223276000000055],
            [62.472734000000059, 25.222787000000039],
            [62.471476000000052, 25.222313000000042],
            [62.469552000000078, 25.221684000000039],
            [62.466130000000078, 25.221403000000066],
            [62.463156000000083, 25.221535000000074],
            [62.459257000000036, 25.221487000000025],
            [62.455201000000045, 25.221025000000054],
            [62.449749000000054, 25.219990000000053],
            [62.439475000000073, 25.218989000000079],
            [62.428752000000031, 25.217061000000058],
            [62.417718000000036, 25.214186000000041],
            [62.411590000000047, 25.211995000000059],
            [62.397968000000049, 25.207478000000037],
            [62.381223000000034, 25.200358000000051],
            [62.361279000000081, 25.188370000000077],
            [62.356041000000062, 25.184281000000055],
            [62.352464000000055, 25.180609000000061],
            [62.344643000000076, 25.171519000000046],
            [62.342585000000042, 25.169219000000055],
            [62.341458000000046, 25.167977000000064],
            [62.338618000000054, 25.164875000000052],
            [62.335648000000049, 25.160347000000058],
            [62.332169000000079, 25.153931000000057],
            [62.329859000000056, 25.147667000000069],
            [62.328779000000054, 25.143286000000046],
            [62.327267000000063, 25.132389000000046],
            [62.326993000000073, 25.126141000000075],
            [62.327789000000053, 25.122833000000071],
            [62.328579000000047, 25.119959000000051],
            [62.329340000000059, 25.117705000000058],
            [62.330024000000037, 25.11594800000006],
            [62.330347000000074, 25.115421000000026],
            [62.330592000000081, 25.115082000000029],
            [62.330626000000052, 25.114902000000029],
            [62.330417000000068, 25.114738000000045],
            [62.330517000000043, 25.114361000000031],
            [62.330613000000028, 25.114065000000039],
            [62.330804000000057, 25.113598000000025],
            [62.331578000000036, 25.113831000000062],
            [62.331646000000035, 25.11376800000005],
            [62.335489000000052, 25.115050000000053],
            [62.335676000000035, 25.114456000000075],
            [62.331218000000035, 25.112993000000074],
            [62.331326000000047, 25.112699000000077],
            [62.332122000000084, 25.112963000000036],
            [62.332335000000057, 25.112468000000035],
            [62.331757000000039, 25.112226000000078],
            [62.332690000000071, 25.110719000000074],
            [62.336772000000053, 25.112141000000065],
            [62.338186000000064, 25.112083000000041],
            [62.338151000000039, 25.112585000000024],
            [62.339445000000069, 25.11266100000006],
            [62.339433000000042, 25.11195900000007],
            [62.343087000000082, 25.111920000000055],
            [62.34308800000008, 25.112340000000074],
            [62.345462000000055, 25.112451000000078],
            [62.345499000000075, 25.111500000000035],
            [62.351482000000033, 25.110028000000057],
            [62.355308000000036, 25.109014000000059],
            [62.355855000000076, 25.108977000000039],
            [62.356199000000061, 25.109160000000031],
            [62.357915000000048, 25.108752000000038],
            [62.358960000000081, 25.108824000000027],
            [62.359401000000048, 25.10856700000005],
            [62.359557000000052, 25.108234000000039],
            [62.359854000000041, 25.107934000000057],
            [62.360381000000075, 25.10807600000004],
            [62.360907000000054, 25.107630000000029],
            [62.36116700000008, 25.107161000000076],
            [62.361459000000082, 25.106786000000056],
            [62.361825000000067, 25.106390000000033],
            [62.362224000000083, 25.10597800000005],
            [62.362561000000028, 25.105669000000034],
            [62.362777000000051, 25.105615000000057],
            [62.363562000000059, 25.105424000000028],
            [62.364679000000081, 25.105168000000049],
            [62.365149000000031, 25.105098000000055],
            [62.365930000000048, 25.104997000000026],
            [62.366811000000041, 25.105083000000036],
            [62.367259000000047, 25.105199000000027],
            [62.367245000000082, 25.104976000000079],
            [62.367446000000029, 25.104930000000024],
            [62.367680000000064, 25.105004000000065],
            [62.367822000000046, 25.105087000000026],
            [62.368655000000047, 25.105065000000025],
            [62.36933300000004, 25.10501800000003],
            [62.370328000000029, 25.105084000000033],
            [62.371033000000068, 25.104837000000032],
            [62.371824000000061, 25.104598000000067],
            [62.372364000000061, 25.104433000000029],
            [62.372488000000033, 25.104554000000064],
            [62.372467000000029, 25.104739000000052],
            [62.372792000000061, 25.104730000000075],
            [62.37308900000005, 25.104719000000046],
            [62.373394000000076, 25.104596000000072],
            [62.373522000000037, 25.104362000000037],
            [62.373821000000078, 25.104295000000036],
            [62.374392000000057, 25.104329000000064],
            [62.37480000000005, 25.104318000000035],
            [62.37518300000005, 25.104274000000032],
            [62.375679000000048, 25.104168000000072],
            [62.376078000000064, 25.104098000000079],
            [62.37641300000007, 25.104067000000043],
            [62.376533000000052, 25.104092000000037],
            [62.376562000000035, 25.104146000000071],
            [62.376608000000033, 25.104248000000041],
            [62.37661600000007, 25.104343000000028],
            [62.376600000000053, 25.104457000000025],
            [62.376570000000072, 25.104539000000045],
            [62.376535000000047, 25.104567000000031],
            [62.376481000000069, 25.104624000000058],
            [62.376209000000074, 25.104713000000061],
            [62.375964000000067, 25.104788000000042],
            [62.375871000000075, 25.104842000000076],
            [62.375850000000071, 25.104927000000032],
            [62.375879000000054, 25.105005000000062],
            [62.375962000000072, 25.10505100000006],
            [62.376045000000033, 25.105056000000047],
            [62.37619600000005, 25.105012000000045],
            [62.376723000000027, 25.104843000000074],
            [62.376731000000063, 25.105000000000075],
            [62.376771000000076, 25.105144000000053],
            [62.376826000000051, 25.105162000000064],
            [62.376847000000055, 25.105067000000076],
            [62.376864000000069, 25.104888000000074],
            [62.37685700000003, 25.104702000000032],
            [62.37688600000007, 25.104563000000041],
            [62.376860000000079, 25.104299000000026],
            [62.376859000000081, 25.104231000000027],
            [62.376900000000035, 25.104161000000033],
            [62.376979000000063, 25.104127000000062],
            [62.377067000000068, 25.104104000000063],
            [62.377361000000064, 25.104077000000075],
            [62.37767800000006, 25.104097000000024],
            [62.378160000000037, 25.104095000000029],
            [62.378681000000029, 25.104097000000024],
            [62.379125000000045, 25.104085000000055],
            [62.379508000000044, 25.104098000000079],
            [62.380044000000055, 25.104207000000031],
            [62.380638000000033, 25.104265000000055],
            [62.38115300000004, 25.104348000000073],
            [62.381595000000061, 25.104414000000077],
            [62.382037000000082, 25.104491000000053],
            [62.382539000000065, 25.10454100000004],
            [62.383057000000065, 25.104563000000041],
            [62.383454000000029, 25.104640000000074],
            [62.383916000000056, 25.104695000000049],
            [62.384236000000044, 25.104608000000042],
            [62.384423000000083, 25.104514000000052],
            [62.384661000000051, 25.104309000000057],
            [62.384702000000061, 25.104039000000057],
            [62.384670000000028, 25.103785000000073],
            [62.384569000000056, 25.103429000000062],
            [62.384621000000038, 25.103341000000057],
            [62.384530000000041, 25.102887000000067],
            [62.384609000000069, 25.102500000000077],
            [62.384552000000042, 25.10222600000003],
            [62.38463100000007, 25.10199300000005],
            [62.384802000000036, 25.101781000000074],
            [62.384289000000081, 25.100751000000059],
            [62.384268000000077, 25.100310000000036],
            [62.38402700000006, 25.100093000000072],
            [62.383846000000062, 25.09990700000003],
            [62.383766000000037, 25.099639000000025],
            [62.383589000000029, 25.099194000000068],
            [62.383453000000031, 25.099032000000022],
            [62.383291000000042, 25.09878800000007],
            [62.383066000000042, 25.098480000000052],
            [62.38281900000004, 25.09819200000004],
            [62.382384000000059, 25.097900000000038],
            [62.38207600000004, 25.097749000000078],
            [62.381538000000035, 25.097593000000074],
            [62.381309000000044, 25.097590000000025],
            [62.381088000000034, 25.097637000000077],
            [62.380943000000059, 25.097713000000056],
            [62.380796000000032, 25.097737000000052],
            [62.380570000000034, 25.097713000000056],
            [62.380062000000066, 25.097654000000034],
            [62.379519000000073, 25.097583000000043],
            [62.378979000000072, 25.097471000000041],
            [62.378418000000067, 25.097335000000044],
            [62.378106000000059, 25.097275000000025],
            [62.377767000000063, 25.097150000000056],
            [62.377618000000041, 25.097108000000048],
            [62.377496000000065, 25.097020000000043],
            [62.377270000000067, 25.096964000000071],
            [62.377269000000069, 25.096954000000039],
            [62.37702500000006, 25.096926000000053],
            [62.376977000000068, 25.096732000000031],
            [62.376814000000081, 25.096467000000075],
            [62.376624000000049, 25.096322000000043],
            [62.376299000000074, 25.096117000000049],
            [62.376131000000044, 25.09586100000007],
            [62.375916000000075, 25.095518000000027],
            [62.375709000000029, 25.095351000000051],
            [62.375140000000044, 25.094881000000044],
            [62.374956000000054, 25.09476600000005],
            [62.374562000000083, 25.094586000000049],
            [62.374226000000078, 25.094484000000023],
            [62.373752000000081, 25.09442400000006],
            [62.373420000000067, 25.094425000000058],
            [62.373029000000031, 25.09447300000005],
            [62.372874000000081, 25.094577000000072],
            [62.372768000000065, 25.094769000000042],
            [62.372690000000034, 25.095066000000031],
            [62.372709000000043, 25.095421000000044],
            [62.372870000000034, 25.095784000000037],
            [62.37290500000006, 25.096034000000031],
            [62.372727000000054, 25.096281000000033],
            [62.372531000000038, 25.096444000000076],
            [62.372229000000061, 25.096660000000043],
            [62.371860000000083, 25.09675100000004],
            [62.371340000000032, 25.096895000000075],
            [62.370938000000081, 25.096993000000055],
            [62.370619000000033, 25.097035000000062],
            [62.370268000000067, 25.097063000000048],
            [62.369878000000028, 25.097028000000023],
            [62.36956500000008, 25.09694200000007],
            [62.369394000000057, 25.096865000000037],
            [62.369181000000083, 25.096745000000055],
            [62.369068000000027, 25.096651000000065],
            [62.368910000000028, 25.096631000000059],
            [62.36872100000005, 25.096515000000068],
            [62.368615000000034, 25.096364000000051],
            [62.36845900000003, 25.096239000000025],
            [62.368334000000061, 25.096111000000064],
            [62.36816900000008, 25.096030000000042],
            [62.367890000000045, 25.096063000000072],
            [62.367640000000051, 25.096037000000024],
            [62.36743000000007, 25.095934000000057],
            [62.367098000000055, 25.095792000000074],
            [62.366731000000073, 25.095638000000065],
            [62.366496000000041, 25.095553000000052],
            [62.366251000000034, 25.09545600000007],
            [62.366102000000069, 25.095457000000067],
            [62.365824000000032, 25.095500000000072],
            [62.36566700000003, 25.09546000000006],
            [62.365480000000048, 25.095346000000063],
            [62.365317000000061, 25.095194000000049],
            [62.365218000000027, 25.09512600000005],
            [62.364913000000058, 25.095096000000069],
            [62.364814000000081, 25.09502800000007],
            [62.364769000000081, 25.094913000000076],
            [62.36468700000006, 25.094723000000045],
            [62.364465000000052, 25.094596000000024],
            [62.364388000000076, 25.094653000000051],
            [62.364242000000047, 25.094684000000029],
            [62.363979000000029, 25.094534000000067],
            [62.363386000000048, 25.094096000000036],
            [62.363052000000039, 25.093899000000079],
            [62.36274700000007, 25.093642000000045],
            [62.362195000000042, 25.09319400000004],
            [62.361825000000067, 25.092922000000044],
            [62.361475000000041, 25.092829000000052],
            [62.361300000000028, 25.092850000000055],
            [62.360998000000052, 25.092739000000051],
            [62.360421000000031, 25.092476000000033],
            [62.360149000000035, 25.092418000000066],
            [62.359988000000044, 25.092419000000064],
            [62.359849000000054, 25.09257800000006],
            [62.359792000000027, 25.092732000000069],
            [62.359574000000066, 25.092729000000077],
            [62.358763000000067, 25.092568000000028],
            [62.358349000000032, 25.092440000000067],
            [62.357941000000039, 25.092364000000032],
            [62.357367000000067, 25.092362000000037],
            [62.356928000000039, 25.092399000000057],
            [62.356737000000066, 25.092471000000046],
            [62.356532000000072, 25.092595000000074],
            [62.356386000000043, 25.092555000000061],
            [62.356188000000031, 25.092595000000074],
            [62.355932000000053, 25.092497000000037],
            [62.355658000000062, 25.092335000000048],
            [62.355304000000046, 25.092294000000038],
            [62.354881000000034, 25.092269000000044],
            [62.354425000000049, 25.092232000000024],
            [62.354148000000066, 25.092247000000043],
            [62.353942000000075, 25.092401000000052],
            [62.353678000000059, 25.092553000000066],
            [62.353109000000075, 25.092634000000032],
            [62.352909000000068, 25.092755000000068],
            [62.352631000000031, 25.092790000000036],
            [62.352523000000076, 25.092730000000074],
            [62.352419000000054, 25.09262700000005],
            [62.352269000000035, 25.092522000000031],
            [62.352147000000059, 25.092599000000064],
            [62.352009000000066, 25.092718000000048],
            [62.351839000000041, 25.092922000000044],
            [62.351627000000065, 25.092984000000058],
            [62.351418000000081, 25.093023000000073],
            [62.351218000000074, 25.093202000000076],
            [62.35106200000007, 25.093287000000032],
            [62.350916000000041, 25.093267000000026],
            [62.350757000000044, 25.093139000000065],
            [62.350563000000079, 25.092999000000077],
            [62.350381000000027, 25.092977000000076],
            [62.350132000000031, 25.093067000000076],
            [62.349776000000077, 25.093205000000069],
            [62.34958000000006, 25.093371000000047],
            [62.349436000000082, 25.09359500000005],
            [62.349169000000074, 25.093780000000038],
            [62.348906000000056, 25.093922000000077],
            [62.348689000000036, 25.093918000000031],
            [62.348470000000077, 25.093777000000046],
            [62.348153000000082, 25.093704000000059],
            [62.347845000000063, 25.093665000000044],
            [62.347567000000083, 25.093702000000064],
            [62.347390000000075, 25.093788000000075],
            [62.346959000000084, 25.093901000000074],
            [62.346492000000069, 25.093874000000028],
            [62.346083000000078, 25.093786000000023],
            [62.34583200000003, 25.093761000000029],
            [62.344569000000035, 25.093994000000066],
            [62.344455000000039, 25.093988000000024],
            [62.344200000000058, 25.093845000000044],
            [62.343785000000082, 25.093767000000071],
            [62.34318200000007, 25.093721000000073],
            [62.342311000000052, 25.093712000000039],
            [62.34156900000005, 25.093649000000028],
            [62.34100200000006, 25.093572000000052],
            [62.340423000000044, 25.093493000000024],
            [62.340144000000066, 25.093541000000073],
            [62.339867000000083, 25.093752000000052],
            [62.339631000000054, 25.093876000000023],
            [62.339300000000037, 25.093876000000023],
            [62.339120000000037, 25.093823000000043],
            [62.338929000000064, 25.093747000000064],
            [62.338561000000027, 25.09373400000004],
            [62.33821400000005, 25.093819000000053],
            [62.337957000000074, 25.093951000000061],
            [62.337668000000065, 25.094081000000074],
            [62.337521000000038, 25.09410500000007],
            [62.337341000000038, 25.09403100000003],
            [62.337203000000045, 25.093854000000078],
            [62.336982000000035, 25.093735000000038],
            [62.335714000000053, 25.093830000000025],
            [62.335390000000075, 25.093938000000037],
            [62.335238000000061, 25.093886000000055],
            [62.334970000000055, 25.09393300000005],
            [62.334668000000079, 25.093799000000047],
            [62.334264000000076, 25.093733000000043],
            [62.331266000000028, 25.094024000000047],
            [62.330680000000029, 25.094239000000073],
            [62.330139000000031, 25.094364000000041],
            [62.329558000000077, 25.09433000000007],
            [62.328752000000065, 25.094220000000064],
            [62.32756100000006, 25.094140000000039],
            [62.32704700000005, 25.094048000000043],
            [62.326431000000071, 25.094013000000075],
            [62.32598500000006, 25.094007000000033],
            [62.325592000000029, 25.093932000000052],
            [62.325239000000067, 25.093922000000077],
            [62.324802000000034, 25.093949000000066],
            [62.324381000000074, 25.093900000000076],
            [62.324243000000081, 25.093906000000061],
            [62.324053000000049, 25.093917000000033],
            [62.323820000000069, 25.093937000000039],
            [62.323402000000044, 25.094033000000024],
            [62.32319300000006, 25.094063000000062],
            [62.323007000000075, 25.094073000000037],
            [62.322801000000027, 25.094096000000036],
            [62.322621000000083, 25.094131000000061],
            [62.322457000000043, 25.094182000000046],
            [62.322253000000046, 25.094148000000075],
            [62.322101000000032, 25.094125000000076],
            [62.321893000000046, 25.094095000000038],
            [62.32171900000003, 25.094089000000054],
            [62.32139600000005, 25.09412100000003],
            [62.321300000000065, 25.094110000000057],
            [62.321179000000029, 25.094104000000073],
            [62.321056000000056, 25.094088000000056],
            [62.320937000000072, 25.094065000000057],
            [62.320799000000079, 25.094050000000038],
            [62.320623000000069, 25.094063000000062],
            [62.320525000000032, 25.094125000000076],
            [62.320460000000082, 25.094148000000075],
            [62.320401000000061, 25.094156000000055],
            [62.32033000000007, 25.094153000000063],
            [62.320280000000082, 25.094135000000051],
            [62.32022500000005, 25.094112000000052],
            [62.320178000000055, 25.094065000000057],
            [62.320065000000056, 25.094057000000078],
            [62.320040000000063, 25.09410500000007],
            [62.319997000000058, 25.094155000000057],
            [62.319931000000054, 25.09416600000003],
            [62.319874000000027, 25.09416600000003],
            [62.31982000000005, 25.094141000000036],
            [62.319801000000041, 25.094093000000044],
            [62.319747000000064, 25.094045000000051],
            [62.319620000000043, 25.094046000000048],
            [62.319440000000043, 25.094133000000056],
            [62.319336000000078, 25.094126000000074],
            [62.319211000000053, 25.094126000000074],
            [62.319072000000062, 25.094136000000049],
            [62.318932000000075, 25.094172000000071],
            [62.318797000000075, 25.094184000000041],
            [62.318767000000037, 25.094137000000046],
            [62.318706000000077, 25.094181000000049],
            [62.318646000000058, 25.09424400000006],
            [62.318508000000065, 25.094230000000039],
            [62.318417000000068, 25.094228000000044],
            [62.318361000000039, 25.094173000000069],
            [62.318366000000083, 25.094133000000056],
            [62.318328000000065, 25.094075000000032],
            [62.318207000000029, 25.094071000000042],
            [62.318156000000045, 25.094120000000032],
            [62.318168000000071, 25.094170000000076],
            [62.318100000000072, 25.094176000000061],
            [62.317715000000078, 25.09416600000003],
            [62.317623000000083, 25.094151000000068],
            [62.317512000000079, 25.094149000000073],
            [62.31741500000004, 25.09415800000005],
            [62.317284000000029, 25.094153000000063],
            [62.317165000000045, 25.094145000000026],
            [62.317068000000063, 25.094132000000059],
            [62.316961000000049, 25.094171000000074],
            [62.316861000000074, 25.094243000000063],
            [62.316716000000042, 25.094246000000055],
            [62.316493000000037, 25.094214000000079],
            [62.316380000000038, 25.094214000000079],
            [62.316263000000049, 25.094187000000034],
            [62.31615400000004, 25.094159000000047],
            [62.316143000000068, 25.094120000000032],
            [62.316096000000073, 25.094066000000055],
            [62.316087000000039, 25.094110000000057],
            [62.316059000000052, 25.094179000000054],
            [62.316033000000061, 25.094235000000026],
            [62.315965000000062, 25.094226000000049],
            [62.315889000000027, 25.094188000000031],
            [62.315793000000042, 25.094145000000026],
            [62.315569000000039, 25.094080000000076],
            [62.31545600000004, 25.094082000000071],
            [62.315412000000038, 25.09410900000006],
            [62.315320000000042, 25.09410500000007],
            [62.315232000000037, 25.094086000000061],
            [62.315160000000049, 25.094094000000041],
            [62.315147000000081, 25.094124000000079],
            [62.315113000000053, 25.094146000000023],
            [62.315060000000074, 25.094151000000068],
            [62.315001000000052, 25.094143000000031],
            [62.314991000000077, 25.094104000000073],
            [62.314959000000044, 25.094111000000055],
            [62.314965000000029, 25.094146000000023],
            [62.314916000000039, 25.094152000000065],
            [62.314901000000077, 25.094132000000059],
            [62.314841000000058, 25.094138000000044],
            [62.314777000000049, 25.094168000000025],
            [62.314725000000067, 25.094168000000025],
            [62.314651000000083, 25.094153000000063],
            [62.314602000000036, 25.094164000000035],
            [62.314559000000031, 25.094163000000037],
            [62.31452100000007, 25.094137000000046],
            [62.31449200000003, 25.094088000000056],
            [62.314426000000083, 25.094103000000075],
            [62.314412000000061, 25.094130000000064],
            [62.314385000000073, 25.094128000000069],
            [62.314333000000033, 25.09410500000007],
            [62.314315000000079, 25.09407600000003],
            [62.314294000000075, 25.094044000000054],
            [62.314272000000074, 25.094008000000031],
            [62.314232000000061, 25.093986000000029],
            [62.314232000000061, 25.09397400000006],
            [62.314230000000066, 25.093967000000077],
            [62.314227000000074, 25.093965000000026],
            [62.314215000000047, 25.093955000000051],
            [62.314168000000052, 25.093968000000075],
            [62.314166000000057, 25.093996000000061],
            [62.314179000000081, 25.09401900000006],
            [62.314195000000041, 25.094043000000056],
            [62.314218000000039, 25.09406000000007],
            [62.314219000000037, 25.094086000000061],
            [62.314181000000076, 25.094099000000028],
            [62.314140000000066, 25.094089000000054],
            [62.31410900000003, 25.09410500000007],
            [62.314066000000082, 25.094100000000026],
            [62.314031000000057, 25.094095000000038],
            [62.313976000000082, 25.094084000000066],
            [62.313948000000039, 25.094119000000035],
            [62.313909000000081, 25.094120000000032],
            [62.313881000000038, 25.094094000000041],
            [62.313879000000043, 25.094092000000046],
            [62.31388000000004, 25.094085000000064],
            [62.313845000000072, 25.094078000000025],
            [62.31378600000005, 25.094083000000069],
            [62.313742000000047, 25.094098000000031],
            [62.31370400000003, 25.094069000000047],
            [62.313683000000083, 25.094049000000041],
            [62.313642000000073, 25.094069000000047],
            [62.313595000000078, 25.09406800000005],
            [62.313580000000059, 25.094093000000044],
            [62.31351600000005, 25.094114000000047],
            [62.313526000000081, 25.094140000000039],
            [62.313462000000072, 25.094151000000068],
            [62.313400000000058, 25.094174000000066],
            [62.313359000000048, 25.094171000000074],
            [62.313363000000038, 25.094188000000031],
            [62.313370000000077, 25.094215000000077],
            [62.313354000000061, 25.094258000000025],
            [62.31327600000003, 25.094258000000025],
            [62.313203000000044, 25.094264000000067],
            [62.313126000000068, 25.094270000000051],
            [62.31305100000003, 25.094266000000061],
            [62.312980000000039, 25.094255000000032],
            [62.312937000000034, 25.094208000000037],
            [62.31282200000004, 25.094237000000078],
            [62.312746000000061, 25.094235000000026],
            [62.31267100000008, 25.094228000000044],
            [62.312611000000061, 25.094201000000055],
            [62.312562000000071, 25.094164000000035],
            [62.312492000000077, 25.094145000000026],
            [62.312407000000064, 25.094130000000064],
            [62.312294000000065, 25.094147000000078],
            [62.312211000000048, 25.09416600000003],
            [62.312175000000082, 25.094187000000034],
            [62.31216100000006, 25.094226000000049],
            [62.312175000000082, 25.094268000000056],
            [62.312095000000056, 25.094274000000041],
            [62.31211600000006, 25.09424000000007],
            [62.312091000000066, 25.094186000000036],
            [62.312043000000074, 25.094202000000053],
            [62.311894000000052, 25.094246000000055],
            [62.31180100000006, 25.09430100000003],
            [62.311711000000059, 25.09433000000007],
            [62.31165100000004, 25.094382000000053],
            [62.31169600000004, 25.094430000000045],
            [62.311657000000082, 25.094478000000038],
            [62.311587000000031, 25.094422000000066],
            [62.311488000000054, 25.094475000000045],
            [62.311413000000073, 25.094486000000074],
            [62.311374000000058, 25.094453000000044],
            [62.311359000000039, 25.094494000000054],
            [62.311342000000081, 25.094547000000034],
            [62.311325000000068, 25.094593000000032],
            [62.311273000000028, 25.09462700000006],
            [62.311225000000036, 25.094624000000067],
            [62.311176000000046, 25.094601000000068],
            [62.311161000000027, 25.094563000000051],
            [62.311123000000066, 25.09457100000003],
            [62.311044000000038, 25.094565000000046],
            [62.310968000000059, 25.094543000000044],
            [62.310865000000035, 25.094492000000059],
            [62.31077300000004, 25.094451000000049],
            [62.310566000000051, 25.094327000000078],
            [62.310538000000065, 25.094225000000051],
            [62.310421000000076, 25.094167000000027],
            [62.310339000000056, 25.09415800000005],
            [62.310354000000075, 25.094206000000042],
            [62.310314000000062, 25.094201000000055],
            [62.310245000000066, 25.094115000000045],
            [62.310184000000049, 25.094160000000045],
            [62.310141000000044, 25.094181000000049],
            [62.309952000000067, 25.094128000000069],
            [62.309767000000079, 25.094099000000028],
            [62.309586000000081, 25.094038000000069],
            [62.309417000000053, 25.09402300000005],
            [62.30923400000006, 25.093984000000034],
            [62.309232000000065, 25.093988000000024],
            [62.309084000000041, 25.09401500000007],
            [62.309048000000075, 25.094003000000043],
            [62.308955000000083, 25.094006000000036],
            [62.308860000000038, 25.094002000000046],
            [62.30878800000005, 25.093951000000061],
            [62.308725000000038, 25.093907000000058],
            [62.308705000000032, 25.09392900000006],
            [62.308667000000071, 25.093963000000031],
            [62.308615000000032, 25.093939000000034],
            [62.308561000000054, 25.093922000000077],
            [62.308537000000058, 25.093948000000069],
            [62.308508000000074, 25.093994000000066],
            [62.308465000000069, 25.093969000000072],
            [62.308480000000031, 25.093923000000075],
            [62.308429000000046, 25.093904000000066],
            [62.308387000000039, 25.093913000000043],
            [62.308316000000048, 25.093896000000029],
            [62.30827000000005, 25.093863000000056],
            [62.308219000000065, 25.093827000000033],
            [62.308170000000075, 25.093836000000067],
            [62.30812700000007, 25.093862000000058],
            [62.30809800000003, 25.093825000000038],
            [62.308085000000062, 25.093798000000049],
            [62.308025000000043, 25.093814000000066],
            [62.308011000000079, 25.093846000000042],
            [62.307964000000027, 25.093861000000061],
            [62.307888000000048, 25.093822000000046],
            [62.307862000000057, 25.093746000000067],
            [62.307807000000082, 25.093811000000073],
            [62.307757000000038, 25.093837000000065],
            [62.307712000000038, 25.093862000000058],
            [62.307683000000054, 25.093819000000053],
            [62.30762100000004, 25.093859000000066],
            [62.307624000000033, 25.093868000000043],
            [62.307626000000027, 25.09388000000007],
            [62.307557000000031, 25.093895000000032],
            [62.307537000000082, 25.093877000000077],
            [62.307501000000059, 25.093902000000071],
            [62.307493000000079, 25.093946000000074],
            [62.307477000000063, 25.093998000000056],
            [62.307469000000083, 25.094025000000045],
            [62.307436000000052, 25.094050000000038],
            [62.307402000000081, 25.093993000000069],
            [62.307374000000038, 25.093955000000051],
            [62.307353000000035, 25.093976000000055],
            [62.30735500000003, 25.094016000000067],
            [62.307320000000061, 25.094036000000074],
            [62.307315000000074, 25.094008000000031],
            [62.307297000000062, 25.09397800000005],
            [62.307260000000042, 25.093991000000074],
            [62.307231000000058, 25.093977000000052],
            [62.307181000000071, 25.094036000000074],
            [62.307175000000029, 25.094061000000067],
            [62.307143000000053, 25.094084000000066],
            [62.30711400000007, 25.09406800000005],
            [62.307101000000046, 25.094095000000038],
            [62.307068000000072, 25.094136000000049],
            [62.307026000000064, 25.09411300000005],
            [62.307003000000066, 25.094128000000069],
            [62.307012000000043, 25.094160000000045],
            [62.306969000000038, 25.094147000000078],
            [62.306918000000053, 25.094167000000027],
            [62.306910000000073, 25.09421100000003],
            [62.306917000000055, 25.094253000000037],
            [62.306859000000031, 25.09424000000007],
            [62.306875000000048, 25.094283000000075],
            [62.306857000000036, 25.094302000000027],
            [62.306811000000039, 25.094291000000055],
            [62.306816000000083, 25.094324000000029],
            [62.306786000000045, 25.094317000000046],
            [62.306759000000056, 25.094341000000043],
            [62.306790000000035, 25.094395000000077],
            [62.306740000000048, 25.094414000000029],
            [62.306672000000049, 25.094457000000034],
            [62.306648000000052, 25.094512000000066],
            [62.306618000000071, 25.094587000000047],
            [62.306609000000037, 25.094637000000034],
            [62.306594000000075, 25.094700000000046],
            [62.306595000000073, 25.094731000000024],
            [62.306569000000081, 25.09477000000004],
            [62.306572000000074, 25.094779000000074],
            [62.306487000000061, 25.094793000000038],
            [62.306387000000029, 25.09480300000007],
            [62.306342000000029, 25.094865000000027],
            [62.306314000000043, 25.094921000000056],
            [62.306199000000049, 25.094969000000049],
            [62.305933000000039, 25.095097000000067],
            [62.305829000000074, 25.095156000000031],
            [62.305756000000031, 25.095209000000068],
            [62.305711000000031, 25.095245000000034],
            [62.305650000000071, 25.095222000000035],
            [62.305546000000049, 25.09517500000004],
            [62.305453000000057, 25.095140000000072],
            [62.305332000000078, 25.095124000000055],
            [62.305224000000067, 25.095098000000064],
            [62.305136000000061, 25.09507300000007],
            [62.305105000000083, 25.095088000000032],
            [62.30502800000005, 25.095064000000036],
            [62.305021000000067, 25.095102000000054],
            [62.304958000000056, 25.095114000000024],
            [62.304903000000081, 25.09508500000004],
            [62.304901000000029, 25.095037000000048],
            [62.304842000000065, 25.095011000000056],
            [62.304818000000068, 25.09503200000006],
            [62.304803000000049, 25.09507300000007],
            [62.304760000000044, 25.095038000000045],
            [62.304696000000035, 25.095022000000029],
            [62.304633000000081, 25.095041000000037],
            [62.30460000000005, 25.095090000000027],
            [62.304574000000059, 25.095060000000046],
            [62.304565000000082, 25.095030000000065],
            [62.304542000000083, 25.095006000000069],
            [62.304505000000063, 25.095020000000034],
            [62.304477000000077, 25.095057000000054],
            [62.304413000000068, 25.09508900000003],
            [62.304390000000069, 25.095120000000065],
            [62.304355000000044, 25.095159000000024],
            [62.304287000000045, 25.095146000000057],
            [62.304261000000054, 25.095109000000036],
            [62.304238000000055, 25.095072000000073],
            [62.304193000000055, 25.095102000000054],
            [62.304121000000066, 25.095095000000072],
            [62.304102000000057, 25.095045000000027],
            [62.304035000000056, 25.095029000000068],
            [62.303966000000059, 25.09502800000007],
            [62.303741000000059, 25.09502800000007],
            [62.303638000000035, 25.095064000000036],
            [62.303507000000081, 25.095070000000078],
            [62.303398000000072, 25.095054000000061],
            [62.303351000000077, 25.095096000000069],
            [62.303342000000043, 25.095131000000038],
            [62.303332000000069, 25.095185000000072],
            [62.303245000000061, 25.095215000000053],
            [62.303165000000035, 25.095255000000066],
            [62.303067000000055, 25.095272000000023],
            [62.303000000000054, 25.09526500000004],
            [62.302957000000049, 25.095234000000062],
            [62.30293800000004, 25.095208000000071],
            [62.30288500000006, 25.095198000000039],
            [62.302811000000077, 25.095255000000066],
            [62.302654000000075, 25.095246000000031],
            [62.30252500000006, 25.095248000000026],
            [62.302423000000033, 25.095226000000025],
            [62.302319000000068, 25.095214000000055],
            [62.302249000000074, 25.095198000000039],
            [62.302185000000065, 25.095178000000033],
            [62.302123000000051, 25.095139000000074],
            [62.302074000000061, 25.095114000000024],
            [62.301997000000028, 25.095132000000035],
            [62.30191300000007, 25.095150000000046],
            [62.301849000000061, 25.095186000000069],
            [62.301765000000046, 25.095184000000074],
            [62.301702000000034, 25.095152000000041],
            [62.301671000000056, 25.095176000000038],
            [62.301614000000029, 25.095150000000046],
            [62.301580000000058, 25.095113000000026],
            [62.301547000000028, 25.09511800000007],
            [62.301500000000033, 25.095109000000036],
            [62.301464000000067, 25.095078000000058],
            [62.30145200000004, 25.095100000000059],
            [62.301455000000033, 25.095132000000035],
            [62.301450000000045, 25.095164000000068],
            [62.301379000000054, 25.095186000000069],
            [62.301326000000074, 25.095154000000036],
            [62.301335000000051, 25.095116000000075],
            [62.301343000000031, 25.095084000000043],
            [62.301293000000044, 25.095059000000049],
            [62.301255000000083, 25.095083000000045],
            [62.301235000000077, 25.095114000000024],
            [62.301175000000057, 25.095156000000031],
            [62.301167000000078, 25.095119000000068],
            [62.301149000000066, 25.095101000000057],
            [62.301105000000064, 25.095051000000069],
            [62.301071000000036, 25.095052000000067],
            [62.30103100000008, 25.09508100000005],
            [62.300995000000057, 25.095113000000026],
            [62.300973000000056, 25.095170000000053],
            [62.30094100000008, 25.095189000000062],
            [62.300921000000073, 25.095233000000064],
            [62.300935000000038, 25.095298000000071],
            [62.300940000000082, 25.095341000000076],
            [62.30089600000008, 25.095377000000042],
            [62.300831000000073, 25.095382000000029],
            [62.300790000000063, 25.095418000000052],
            [62.300746000000061, 25.095461000000057],
            [62.300708000000043, 25.095511000000045],
            [62.300639000000047, 25.095576000000051],
            [62.300647000000083, 25.09564400000005],
            [62.300637000000052, 25.095675000000028],
            [62.300596000000041, 25.09573400000005],
            [62.300536000000079, 25.09577900000005],
            [62.300470000000075, 25.095798000000059],
            [62.300421000000028, 25.095878000000027],
            [62.300321000000054, 25.095950000000073],
            [62.300272000000064, 25.096001000000058],
            [62.300235000000043, 25.096050000000048],
            [62.300186000000053, 25.096088000000066],
            [62.300131000000079, 25.09609800000004],
            [62.30010500000003, 25.096083000000078],
            [62.300055000000043, 25.096076000000039],
            [62.30001100000004, 25.096094000000051],
            [62.299976000000072, 25.096129000000076],
            [62.299938000000054, 25.096186000000046],
            [62.299867000000063, 25.096246000000065],
            [62.299851000000046, 25.096314000000064],
            [62.299869000000058, 25.096362000000056],
            [62.299898000000042, 25.096421000000078],
            [62.299908000000073, 25.09648500000003],
            [62.299884000000077, 25.096534000000077],
            [62.299842000000069, 25.096572000000037],
            [62.299797000000069, 25.096599000000026],
            [62.299727000000075, 25.096648000000073],
            [62.299727000000075, 25.096668000000022],
            [62.299713000000054, 25.096725000000049],
            [62.299689000000058, 25.096775000000036],
            [62.299648000000047, 25.096765000000062],
            [62.299607000000037, 25.09675500000003],
            [62.299558000000047, 25.096748000000048],
            [62.299481000000071, 25.096739000000071],
            [62.299446000000046, 25.096733000000029],
            [62.299361000000033, 25.096758000000023],
            [62.299312000000043, 25.096799000000033],
            [62.299229000000082, 25.096787000000063],
            [62.299190000000067, 25.096778000000029],
            [62.29912900000005, 25.096829000000071],
            [62.299094000000082, 25.096854000000064],
            [62.299057000000062, 25.096858000000054],
            [62.29904700000003, 25.09684100000004],
            [62.299016000000051, 25.096821000000034],
            [62.298981000000083, 25.096809000000064],
            [62.298940000000073, 25.096810000000062],
            [62.298903000000053, 25.096811000000059],
            [62.298881000000051, 25.096847000000025],
            [62.298842000000036, 25.096903000000054],
            [62.298804000000075, 25.096938000000023],
            [62.298783000000071, 25.096937000000025],
            [62.298739000000069, 25.096900000000062],
            [62.29867900000005, 25.096868000000029],
            [62.298588000000052, 25.096880000000056],
            [62.29848000000004, 25.096877000000063],
            [62.298254000000043, 25.096929000000046],
            [62.297356000000036, 25.097069000000033],
            [62.297264000000041, 25.097100000000069],
            [62.297038000000043, 25.097141000000079],
            [62.296955000000082, 25.097157000000038],
            [62.29686600000008, 25.097110000000043],
            [62.296710000000076, 25.097154000000046],
            [62.296558000000061, 25.097195000000056],
            [62.296411000000035, 25.097195000000056],
            [62.29631500000005, 25.097200000000043],
            [62.296210000000031, 25.097218000000055],
            [62.296134000000052, 25.097248000000036],
            [62.296068000000048, 25.097207000000026],
            [62.295987000000082, 25.097197000000051],
            [62.295892000000038, 25.09717500000005],
            [62.295811000000072, 25.09716700000007],
            [62.295736000000034, 25.097184000000027],
            [62.295669000000032, 25.097198000000049],
            [62.295607000000075, 25.097157000000038],
            [62.295537000000081, 25.097127000000057],
            [62.295448000000079, 25.097114000000033],
            [62.295436000000052, 25.097153000000048],
            [62.295398000000034, 25.097198000000049],
            [62.29533500000008, 25.097219000000052],
            [62.295264000000032, 25.097243000000049],
            [62.295231000000058, 25.097237000000064],
            [62.29519700000003, 25.097195000000056],
            [62.295171000000039, 25.097187000000076],
            [62.295146000000045, 25.097221000000047],
            [62.295105000000035, 25.097197000000051],
            [62.295020000000079, 25.097202000000038],
            [62.294943000000046, 25.097190000000069],
            [62.294894000000056, 25.097194000000059],
            [62.294852000000049, 25.097203000000036],
            [62.294798000000071, 25.097199000000046],
            [62.294728000000077, 25.097198000000049],
            [62.294653000000039, 25.097200000000043],
            [62.294609000000037, 25.09717500000005],
            [62.294581000000051, 25.097137000000032],
            [62.294536000000051, 25.097113000000036],
            [62.294472000000042, 25.097116000000028],
            [62.294395000000065, 25.097162000000026],
            [62.294351000000063, 25.097163000000023],
            [62.294300000000078, 25.097213000000067],
            [62.294265000000053, 25.09725700000007],
            [62.294233000000077, 25.097277000000076],
            [62.294187000000079, 25.09722800000003],
            [62.294143000000076, 25.097193000000061],
            [62.29408600000005, 25.097164000000078],
            [62.29408600000005, 25.097203000000036],
            [62.294111000000044, 25.097251000000028],
            [62.294060000000059, 25.097244000000046],
            [62.293995000000052, 25.097229000000027],
            [62.293914000000029, 25.09722400000004],
            [62.293843000000038, 25.097230000000025],
            [62.293766000000062, 25.097260000000063],
            [62.293681000000049, 25.09726900000004],
            [62.293603000000076, 25.097264000000052],
            [62.293551000000036, 25.097278000000074],
            [62.29351500000007, 25.09731400000004],
            [62.293439000000035, 25.09735500000005],
            [62.293369000000041, 25.09735100000006],
            [62.293312000000071, 25.097382000000039],
            [62.293238000000031, 25.097394000000065],
            [62.293166000000042, 25.097365000000025],
            [62.293056000000036, 25.097342000000026],
            [62.29296400000004, 25.097329000000059],
            [62.292939000000047, 25.097317000000032],
            [62.292891000000054, 25.097321000000079],
            [62.292865000000063, 25.097358000000042],
            [62.292809000000034, 25.09740000000005],
            [62.292750000000069, 25.097459000000072],
            [62.292703000000074, 25.097473000000036],
            [62.292638000000068, 25.097483000000068],
            [62.292558000000042, 25.09748200000007],
            [62.292495000000031, 25.097485000000063],
            [62.292429000000027, 25.097446000000048],
            [62.292355000000043, 25.097414000000072],
            [62.292310000000043, 25.097356000000048],
            [62.292272000000082, 25.097343000000023],
            [62.292214000000058, 25.097366000000022],
            [62.292162000000076, 25.097352000000058],
            [62.292118000000073, 25.09736300000003],
            [62.292055000000062, 25.097369000000072],
            [62.291986000000065, 25.097377000000051],
            [62.29193900000007, 25.097343000000023],
            [62.291903000000048, 25.097281000000066],
            [62.291922000000056, 25.097268000000042],
            [62.291949000000045, 25.09722800000003],
            [62.291880000000049, 25.097219000000052],
            [62.29182000000003, 25.097201000000041],
            [62.291806000000065, 25.097263000000055],
            [62.291769000000045, 25.097292000000039],
            [62.291713000000072, 25.097289000000046],
            [62.291671000000065, 25.097300000000075],
            [62.291618000000028, 25.097311000000047],
            [62.291564000000051, 25.097290000000044],
            [62.291513000000066, 25.097277000000076],
            [62.291452000000049, 25.097248000000036],
            [62.291408000000047, 25.097235000000069],
            [62.291368000000034, 25.097268000000042],
            [62.291329000000076, 25.097325000000069],
            [62.291297000000043, 25.097354000000053],
            [62.291260000000079, 25.097348000000068],
            [62.291235000000029, 25.097298000000023],
            [62.29125300000004, 25.097250000000031],
            [62.291234000000031, 25.097214000000065],
            [62.291194000000075, 25.097240000000056],
            [62.291172000000074, 25.097287000000051],
            [62.291177000000062, 25.097319000000027],
            [62.291180000000054, 25.097378000000049],
            [62.291149000000075, 25.097387000000026],
            [62.291119000000037, 25.097395000000063],
            [62.29107700000003, 25.097414000000072],
            [62.291052000000036, 25.097398000000055],
            [62.291018000000065, 25.09735500000005],
            [62.290991000000076, 25.097370000000069],
            [62.290999000000056, 25.097409000000027],
            [62.290968000000078, 25.097438000000068],
            [62.290929000000062, 25.097463000000062],
            [62.29088100000007, 25.097457000000077],
            [62.290857000000074, 25.097431000000029],
            [62.290817000000061, 25.097415000000069],
            [62.290753000000052, 25.097439000000065],
            [62.290729000000056, 25.097489000000053],
            [62.290675000000078, 25.097530000000063],
            [62.290619000000049, 25.097557000000052],
            [62.290574000000049, 25.097578000000055],
            [62.290523000000064, 25.097582000000045],
            [62.290490000000034, 25.09758400000004],
            [62.290449000000081, 25.09758800000003],
            [62.290380000000027, 25.097570000000076],
            [62.290292000000079, 25.097575000000063],
            [62.290208000000064, 25.097597000000064],
            [62.290098000000057, 25.097614000000078],
            [62.290069000000074, 25.097649000000047],
            [62.289971000000037, 25.097650000000044],
            [62.289891000000068, 25.097659000000078],
            [62.289789000000042, 25.097672000000046],
            [62.289727000000028, 25.097665000000063],
            [62.289646000000062, 25.097692000000052],
            [62.289596000000074, 25.097716000000048],
            [62.289555000000064, 25.097745000000032],
            [62.289516000000049, 25.097770000000025],
            [62.28949300000005, 25.097778000000062],
            [62.289479000000028, 25.097777000000065],
            [62.289457000000027, 25.097758000000056],
            [62.289433000000031, 25.097750000000076],
            [62.289378000000056, 25.097735000000057],
            [62.28931700000004, 25.097717000000046],
            [62.289226000000042, 25.097721000000035],
            [62.289114000000041, 25.097727000000077],
            [62.289006000000029, 25.097735000000057],
            [62.288797000000045, 25.097725000000025],
            [62.288689000000034, 25.097718000000043],
            [62.288592000000051, 25.097737000000052],
            [62.288522000000057, 25.097774000000072],
            [62.288415000000043, 25.097770000000025],
            [62.288282000000038, 25.097798000000068],
            [62.288165000000049, 25.097808000000043],
            [62.288099000000045, 25.097793000000024],
            [62.288039000000083, 25.097800000000063],
            [62.287997000000075, 25.097812000000033],
            [62.287942000000044, 25.097812000000033],
            [62.287911000000065, 25.097831000000042],
            [62.287877000000037, 25.097840000000076],
            [62.287833000000035, 25.097830000000044],
            [62.287796000000071, 25.097813000000031],
            [62.287767000000031, 25.09777500000007],
            [62.28772900000007, 25.097802000000058],
            [62.287710000000061, 25.097862000000077],
            [62.287690000000055, 25.09791400000006],
            [62.287628000000041, 25.09791000000007],
            [62.287576000000058, 25.09791400000006],
            [62.287510000000054, 25.097903000000031],
            [62.287436000000071, 25.097912000000065],
            [62.287373000000059, 25.097898000000043],
            [62.287287000000049, 25.097857000000033],
            [62.287143000000071, 25.097804000000053],
            [62.287063000000046, 25.097810000000038],
            [62.286997000000042, 25.097812000000033],
            [62.286936000000082, 25.097837000000027],
            [62.286895000000072, 25.097866000000067],
            [62.28684700000008, 25.097881000000029],
            [62.286834000000056, 25.097908000000075],
            [62.286800000000028, 25.09791000000007],
            [62.286781000000076, 25.097880000000032],
            [62.286749000000043, 25.097875000000045],
            [62.286676000000057, 25.097867000000065],
            [62.286624000000074, 25.097902000000033],
            [62.286556000000076, 25.097915000000057],
            [62.286504000000036, 25.097940000000051],
            [62.286467000000073, 25.097962000000052],
            [62.286422000000073, 25.097993000000031],
            [62.286388000000045, 25.097998000000075],
            [62.286359000000061, 25.097972000000027],
            [62.286320000000046, 25.097942000000046],
            [62.286262000000079, 25.097935000000064],
            [62.286212000000035, 25.097928000000024],
            [62.286171000000081, 25.097929000000079],
            [62.286138000000051, 25.097952000000078],
            [62.286115000000052, 25.097930000000076],
            [62.286086000000068, 25.097896000000048],
            [62.286064000000067, 25.097867000000065],
            [62.286038000000076, 25.097841000000074],
            [62.285998000000063, 25.097835000000032],
            [62.285965000000033, 25.097861000000023],
            [62.28591300000005, 25.097917000000052],
            [62.285875000000033, 25.097862000000077],
            [62.285841000000062, 25.097899000000041],
            [62.285802000000047, 25.09791800000005],
            [62.285759000000041, 25.097901000000036],
            [62.285709000000054, 25.097916000000055],
            [62.285642000000053, 25.097919000000047],
            [62.285566000000074, 25.097900000000038],
            [62.285534000000041, 25.097926000000029],
            [62.28550800000005, 25.097928000000024],
            [62.28545500000007, 25.097938000000056],
            [62.285394000000053, 25.097966000000042],
            [62.285358000000031, 25.097964000000047],
            [62.285310000000038, 25.097961000000055],
            [62.285253000000068, 25.097985000000051],
            [62.285194000000047, 25.098029000000054],
            [62.285158000000081, 25.098040000000026],
            [62.285140000000069, 25.098022000000071],
            [62.285115000000076, 25.098021000000074],
            [62.28508400000004, 25.097995000000026],
            [62.285036000000048, 25.097992000000033],
            [62.28498600000006, 25.098034000000041],
            [62.284920000000056, 25.098033000000044],
            [62.284885000000031, 25.098011000000042],
            [62.28486300000003, 25.097996000000023],
            [62.284839000000034, 25.09796700000004],
            [62.28480200000007, 25.097954000000072],
            [62.284759000000065, 25.097928000000024],
            [62.28467100000006, 25.097947000000033],
            [62.28457300000008, 25.097978000000069],
            [62.284534000000065, 25.097996000000023],
            [62.284432000000038, 25.097997000000078],
            [62.284347000000082, 25.098005000000057],
            [62.284250000000043, 25.098019000000079],
            [62.284186000000034, 25.098005000000057],
            [62.284115000000043, 25.098015000000032],
            [62.284041000000059, 25.098047000000065],
            [62.283984000000032, 25.098037000000033],
            [62.283899000000076, 25.098052000000052],
            [62.283882000000062, 25.098074000000054],
            [62.283826000000033, 25.098088000000075],
            [62.283775000000048, 25.098085000000026],
            [62.28374800000006, 25.098056000000042],
            [62.28370300000006, 25.098033000000044],
            [62.28364700000003, 25.098043000000075],
            [62.283599000000038, 25.098034000000041],
            [62.283550000000048, 25.098007000000052],
            [62.283527000000049, 25.098019000000079],
            [62.283464000000038, 25.098051000000055],
            [62.283384000000069, 25.098046000000068],
            [62.283318000000065, 25.098011000000042],
            [62.28323000000006, 25.098006000000055],
            [62.283146000000045, 25.097970000000032],
            [62.282995000000028, 25.09804900000006],
            [62.282967000000042, 25.098142000000053],
            [62.282885000000078, 25.098248000000069],
            [62.282736000000057, 25.098292000000072],
            [62.282564000000036, 25.098308000000031],
            [62.282484000000068, 25.098277000000053],
            [62.282459000000074, 25.098182000000065],
            [62.282361000000037, 25.098095000000058],
            [62.282259000000067, 25.098035000000039],
            [62.282054000000073, 25.097995000000026],
            [62.281780000000083, 25.098018000000025],
            [62.281442000000027, 25.098091000000068],
            [62.28109900000004, 25.09813500000007],
            [62.280899000000034, 25.098161000000061],
            [62.280632000000082, 25.098264000000029],
            [62.280462000000057, 25.098279000000048],
            [62.280256000000065, 25.098251000000062],
            [62.280179000000032, 25.098299000000054],
            [62.280035000000055, 25.098436000000049],
            [62.279837000000043, 25.098555000000033],
            [62.279551000000083, 25.098602000000028],
            [62.279398000000072, 25.098592000000053],
            [62.279210000000035, 25.098501000000056],
            [62.279010000000028, 25.098396000000037],
            [62.278888000000052, 25.098349000000042],
            [62.278737000000035, 25.098432000000059],
            [62.27860000000004, 25.098516000000075],
            [62.278389000000061, 25.098636000000056],
            [62.278219000000036, 25.098638000000051],
            [62.277971000000036, 25.098570000000052],
            [62.277782000000059, 25.098478000000057],
            [62.277457000000027, 25.098470000000077],
            [62.277233000000081, 25.098430000000064],
            [62.277002000000039, 25.098498000000063],
            [62.276960000000031, 25.098696000000075],
            [62.27673800000008, 25.098877000000073],
            [62.276426000000072, 25.098853000000076],
            [62.275892000000056, 25.098615000000052],
            [62.27552600000007, 25.098593000000051],
            [62.273640000000057, 25.098555000000033],
            [62.272667000000069, 25.098595000000046],
            [62.271740000000079, 25.098704000000055],
            [62.270863000000077, 25.098815000000059],
            [62.270333000000051, 25.09887800000007],
            [62.270165000000077, 25.099165000000028],
            [62.26979300000005, 25.099263000000064],
            [62.269403000000068, 25.099039000000062],
            [62.268925000000081, 25.099003000000039],
            [62.268544000000077, 25.099546000000032],
            [62.268469000000039, 25.099591000000032],
            [62.268169000000057, 25.099800000000073],
            [62.268043000000034, 25.099939000000063],
            [62.268006000000071, 25.100116000000071],
            [62.267955000000029, 25.100322000000062],
            [62.267836000000045, 25.100545000000068],
            [62.267787000000055, 25.100721000000078],
            [62.26779300000004, 25.101008000000036],
            [62.267727000000036, 25.10120500000005],
            [62.267629000000056, 25.101293000000055],
            [62.267537000000061, 25.101318000000049],
            [62.267394000000081, 25.101306000000022],
            [62.267299000000037, 25.101255000000037],
            [62.26723000000004, 25.10129100000006],
            [62.267262000000073, 25.101537000000064],
            [62.267176000000063, 25.101751000000036],
            [62.26709100000005, 25.101965000000064],
            [62.266937000000041, 25.102320000000077],
            [62.266753000000051, 25.102516000000037],
            [62.266528000000051, 25.102657000000079],
            [62.266332000000034, 25.102697000000035],
            [62.26621300000005, 25.102763000000039],
            [62.266265000000033, 25.102923000000033],
            [62.266092000000071, 25.103089000000068],
            [62.265895000000057, 25.103274000000056],
            [62.265662000000077, 25.103467000000023],
            [62.265356000000054, 25.103429000000062],
            [62.264849000000083, 25.103669000000025],
            [62.264327000000037, 25.103739000000076],
            [62.263663000000065, 25.103953000000047],
            [62.263135000000034, 25.104074000000026],
            [62.262406000000055, 25.104300000000023],
            [62.261956000000055, 25.104358000000047],
            [62.26140300000003, 25.104417000000069],
            [62.261100000000056, 25.104452000000038],
            [62.260626000000059, 25.104516000000046],
            [62.260510000000068, 25.104591000000028],
            [62.260246000000052, 25.104607000000044],
            [62.259664000000043, 25.104460000000074],
            [62.259058000000039, 25.104376000000059],
            [62.258799000000067, 25.104362000000037],
            [62.25861800000007, 25.104302000000075],
            [62.258497000000034, 25.104430000000036],
            [62.258344000000079, 25.104705000000024],
            [62.258142000000078, 25.104910000000075],
            [62.257993000000056, 25.105151000000035],
            [62.257874000000072, 25.105244000000027],
            [62.257507000000032, 25.105443000000037],
            [62.257063000000073, 25.105673000000024],
            [62.256750000000068, 25.105878000000075],
            [62.25661800000006, 25.106150000000071],
            [62.256856000000028, 25.106483000000026],
            [62.257303000000036, 25.106485000000077],
            [62.257592000000045, 25.106677000000047],
            [62.257947000000058, 25.106711000000075],
            [62.258103000000062, 25.106778000000077],
            [62.258297000000027, 25.106788000000051],
            [62.258713000000057, 25.106826000000069],
            [62.259220000000028, 25.106914000000074],
            [62.259634000000062, 25.106967000000054],
            [62.260088000000053, 25.106962000000067],
            [62.260490000000061, 25.107171000000051],
            [62.260902000000044, 25.107105000000047],
            [62.261500000000069, 25.107080000000053],
            [62.261943000000031, 25.107016000000044],
            [62.262395000000083, 25.106998000000033],
            [62.262970000000053, 25.107019000000037],
            [62.263198000000045, 25.106987000000061],
            [62.263458000000071, 25.106957000000023],
            [62.263748000000078, 25.106945000000053],
            [62.263927000000081, 25.106926000000044],
            [62.264385000000061, 25.106832000000054],
            [62.264802000000032, 25.106829000000062],
            [62.265076000000079, 25.106802000000073],
            [62.265420000000063, 25.106911000000025],
            [62.265982000000065, 25.106914000000074],
            [62.266181000000074, 25.106900000000053],
            [62.266387000000066, 25.106861000000038],
            [62.266623000000038, 25.106708000000026],
            [62.266881000000069, 25.106678000000045],
            [62.26709900000003, 25.106752000000029],
            [62.267356000000063, 25.106737000000066],
            [62.26764200000008, 25.106753000000026],
            [62.267974000000038, 25.106798000000026],
            [62.268339000000083, 25.107070000000078],
            [62.268694000000039, 25.107233000000065],
            [62.268873000000042, 25.107451000000026],
            [62.269175000000075, 25.107677000000024],
            [62.269354000000078, 25.107808000000034],
            [62.269893000000081, 25.108104000000026],
            [62.270278000000076, 25.108190000000036],
            [62.270727000000079, 25.108319000000051],
            [62.27117400000003, 25.108243000000073],
            [62.27168000000006, 25.108341000000053],
            [62.272009000000082, 25.108450000000062],
            [62.272531000000072, 25.108568000000048],
            [62.273114000000078, 25.108685000000037],
            [62.273779000000047, 25.108752000000038],
            [62.274166000000037, 25.108983000000023],
            [62.274482000000035, 25.108968000000061],
            [62.27479100000005, 25.108872000000076],
            [62.275028000000077, 25.10895800000003],
            [62.275276000000076, 25.109088000000042],
            [62.275552000000062, 25.109146000000067],
            [62.275803000000053, 25.108979000000033],
            [62.27605200000005, 25.109097000000077],
            [62.276307000000031, 25.109176000000048],
            [62.276384000000064, 25.109338000000037],
            [62.276665000000037, 25.109392000000071],
            [62.277856000000043, 25.109329000000059],
            [62.278748000000064, 25.109519000000034],
            [62.279093000000046, 25.109431000000029],
            [62.279449000000056, 25.109275000000025],
            [62.279968000000054, 25.109331000000054],
            [62.280941000000041, 25.109442000000058],
            [62.281817000000046, 25.109530000000063],
            [62.282910000000072, 25.109620000000064],
            [62.283711000000039, 25.109806000000049],
            [62.284815000000037, 25.109923000000038],
            [62.285132000000033, 25.109838000000025],
            [62.28541400000006, 25.10991000000007],
            [62.285677000000078, 25.110024000000067],
            [62.28583100000003, 25.110160000000064],
            [62.285715000000039, 25.110321000000056],
            [62.285933000000057, 25.110490000000027],
            [62.286264000000074, 25.110568000000058],
            [62.286538000000064, 25.110574000000042],
            [62.28675000000004, 25.110505000000046],
            [62.287008000000071, 25.110514000000023],
            [62.287255000000073, 25.110606000000075],
            [62.287490000000048, 25.110867000000042],
            [62.287707000000069, 25.111092000000042],
            [62.288047000000063, 25.111600000000067],
            [62.288283000000035, 25.112108000000035],
            [62.288488000000029, 25.11280800000003],
            [62.288786000000073, 25.113712000000078],
            [62.288969000000066, 25.114478000000076],
            [62.289047000000039, 25.114684000000068],
            [62.289084000000059, 25.115267000000074],
            [62.289361000000042, 25.11529500000006],
            [62.289671000000055, 25.115542000000062],
            [62.289955000000077, 25.115879000000064],
            [62.290261000000044, 25.116175000000055],
            [62.290617000000054, 25.116221000000053],
            [62.290988000000027, 25.116174000000058],
            [62.291365000000042, 25.116265000000055],
            [62.291794000000039, 25.116448000000048],
            [62.29208600000004, 25.116569000000027],
            [62.292758000000049, 25.116570000000024],
            [62.293053000000043, 25.116551000000072],
            [62.293272000000059, 25.116572000000076],
            [62.293490000000077, 25.116688000000067],
            [62.293855000000065, 25.11683400000004],
            [62.294268000000045, 25.116937000000064],
            [62.294548000000077, 25.117023000000074],
            [62.294526000000076, 25.117125000000044],
            [62.294532000000061, 25.117278000000056],
            [62.294676000000038, 25.117245000000025],
            [62.294810000000041, 25.11719800000003],
            [62.294910000000073, 25.117074000000059],
            [62.295117000000062, 25.117030000000057],
            [62.295415000000048, 25.117081000000042],
            [62.295615000000055, 25.117192000000045],
            [62.29589100000004, 25.117202000000077],
            [62.296024000000045, 25.117122000000052],
            [62.296248000000048, 25.116972000000032],
            [62.296684000000027, 25.116972000000032],
            [62.296900000000051, 25.11715300000003],
            [62.297068000000081, 25.11729500000007],
            [62.297180000000083, 25.11734000000007],
            [62.297372000000053, 25.117296000000067],
            [62.297660000000064, 25.117191000000048],
            [62.297908000000064, 25.117261000000042],
            [62.29834100000005, 25.117398000000037],
            [62.29877300000004, 25.117539000000079],
            [62.299065000000041, 25.117509000000041],
            [62.29941400000007, 25.117448000000024],
            [62.299648000000047, 25.117196000000035],
            [62.299787000000038, 25.117107000000033],
            [62.300087000000076, 25.11710000000005],
            [62.300399000000027, 25.117082000000039],
            [62.300587000000064, 25.117183000000068],
            [62.300666000000035, 25.11729500000007],
            [62.300865000000044, 25.117471000000023],
            [62.300983000000031, 25.117694000000029],
            [62.301292000000046, 25.117730000000051],
            [62.301570000000083, 25.117846000000043],
            [62.301438000000076, 25.118006000000037],
            [62.30188400000003, 25.118115000000046],
            [62.302273000000071, 25.117988000000025],
            [62.303014000000076, 25.118187000000034],
            [62.303374000000076, 25.118303000000026],
            [62.30367700000005, 25.118472000000054],
            [62.303804000000071, 25.118638000000033],
            [62.303986000000066, 25.118902000000048],
            [62.304079000000058, 25.119056000000057],
            [62.304213000000061, 25.119177000000036],
            [62.305209000000048, 25.119146000000057],
            [62.305470000000071, 25.118788000000052],
            [62.306156000000044, 25.118332000000066],
            [62.306906000000083, 25.117931000000056],
            [62.30743700000005, 25.11756500000007],
            [62.307923000000073, 25.117401000000029],
            [62.308403000000055, 25.117347000000052],
            [62.308881000000042, 25.117404000000079],
            [62.309294000000079, 25.117546000000061],
            [62.30974400000008, 25.117819000000054],
            [62.309893000000045, 25.117866000000049],
            [62.310332000000074, 25.118213000000026],
            [62.310726000000045, 25.118600000000072],
            [62.311082000000056, 25.118857000000048],
            [62.311469000000045, 25.119122000000061],
            [62.311659000000077, 25.119220000000041],
            [62.311894000000052, 25.119221000000039],
            [62.31207500000005, 25.119172000000049],
            [62.312629000000072, 25.119035000000054],
            [62.313216000000068, 25.118945000000053],
            [62.313487000000066, 25.118953000000033],
            [62.313848000000064, 25.119024000000024],
            [62.314314000000081, 25.118909000000031],
            [62.314625000000035, 25.118919000000062],
            [62.315021000000058, 25.118957000000023],
            [62.315408000000048, 25.118958000000077],
            [62.31573300000008, 25.119013000000052],
            [62.315911000000028, 25.11910400000005],
            [62.316089000000034, 25.119241000000045],
            [62.316219000000046, 25.119399000000044],
            [62.316362000000083, 25.119583000000034],
            [62.31652100000008, 25.119795000000067],
            [62.316653000000031, 25.120039000000077],
            [62.316864000000066, 25.120388000000048],
            [62.317049000000054, 25.120746000000054],
            [62.317200000000071, 25.121057000000064],
            [62.317396000000031, 25.121425000000045],
            [62.318185000000028, 25.123427000000049],
            [62.319060000000036, 25.130332000000067],
            [62.318053000000077, 25.143013000000053],
            [62.315429000000051, 25.156411000000048],
            [62.314513000000034, 25.158997000000056],
            [62.312583000000075, 25.163370000000043],
            [62.31119700000005, 25.165786000000026],
            [62.306376000000057, 25.17306300000007],
            [62.303273000000047, 25.176844000000074],
            [62.292631000000029, 25.186947000000032],
            [62.285482000000059, 25.192518000000064],
            [62.282288000000051, 25.195140000000038],
            [62.277760000000058, 25.198049000000026],
            [62.271380000000079, 25.202020000000061],
            [62.265579000000059, 25.204760000000078],
            [62.257958000000031, 25.207664000000022],
            [62.247963000000027, 25.211697000000072],
            [62.233573000000035, 25.215379000000041],
            [62.22281600000008, 25.217375000000061],
            [62.208103000000051, 25.218686000000048],
            [62.203138000000081, 25.219200000000058],
            [62.192668000000083, 25.219845000000078],
            [62.182262000000037, 25.219775000000027],
            [62.173240000000078, 25.219874000000061],
            [62.152885000000083, 25.218088000000023],
            [62.143095000000073, 25.216918000000078],
            [62.13217700000007, 25.214982000000077],
            [62.127129000000082, 25.213617000000056],
            [62.123197000000062, 25.212464000000068],
            [62.115665000000035, 25.210356000000047],
            [62.10130300000003, 25.205750000000023],
            [62.08994400000006, 25.201067000000023],
            [62.082849000000067, 25.197586000000058],
            [62.079478000000051, 25.195639000000028],
            [62.071145000000058, 25.189782000000037],
            [62.066998000000069, 25.186184000000026],
            [62.064284000000043, 25.183276000000035],
            [62.061764000000039, 25.179765000000032],
            [62.060873000000072, 25.178146000000027],
            [62.059816000000069, 25.176422000000059],
            [62.058938000000069, 25.174844000000064],
            [62.057810000000075, 25.172556000000043],
            [62.057162000000062, 25.171289000000058],
            [62.055203000000063, 25.166633000000047],
            [62.054691000000048, 25.165274000000068],
            [62.054179000000033, 25.163560000000075],
            [62.05391300000008, 25.162779000000057],
            [62.053494000000057, 25.161389000000042],
            [62.053232000000037, 25.160565000000076],
            [62.053078000000028, 25.159999000000028],
            [62.052747000000068, 25.158696000000077],
            [62.052572000000055, 25.157621000000063],
            [62.052572000000055, 25.157056000000068],
            [62.052595000000053, 25.156543000000056],
            [62.052651000000083, 25.156110000000069],
            [62.052794000000063, 25.155325000000062],
            [62.052927000000068, 25.154584000000057],
            [62.053139000000044, 25.153975000000059],
            [62.053327000000081, 25.153565000000071],
            [62.053519000000051, 25.153091000000074],
            [62.053790000000049, 25.15262800000005],
            [62.054083000000048, 25.152188000000024],
            [62.05436300000008, 25.151719000000071],
            [62.054627000000039, 25.151463000000035],
            [62.05506100000008, 25.151025000000061],
            [62.055214000000035, 25.150934000000063],
            [62.055518000000063, 25.150678000000028],
            [62.055854000000068, 25.150418000000059],
            [62.05609000000004, 25.150318000000027],
            [62.056404000000043, 25.150216000000057],
            [62.056701000000032, 25.150141000000076],
            [62.056913000000065, 25.150135000000034],
            [62.057289000000083, 25.15016600000007],
            [62.05749000000003, 25.150187000000074],
            [62.057773000000054, 25.150283000000059],
            [62.058025000000043, 25.150398000000052],
            [62.058271000000047, 25.150498000000027],
            [62.058414000000028, 25.150486000000058],
            [62.058575000000076, 25.150431000000026],
            [62.058771000000036, 25.150343000000078],
            [62.058983000000069, 25.150249000000031],
            [62.059228000000076, 25.150142000000073],
            [62.059522000000072, 25.150015000000053],
            [62.059990000000028, 25.149872000000073],
            [62.061899000000039, 25.149307000000078],
            [62.062364000000059, 25.149231000000043],
            [62.062638000000049, 25.149217000000078],
            [62.062976000000049, 25.149249000000054],
            [62.063179000000048, 25.149272000000053],
            [62.063442000000066, 25.149271000000056],
            [62.063715000000059, 25.149237000000028],
            [62.063957000000073, 25.149140000000045],
            [62.064106000000038, 25.149032000000034],
            [62.06425500000006, 25.148931000000061],
            [62.064421000000038, 25.148791000000074],
            [62.064690000000041, 25.148567000000071],
            [62.064902000000075, 25.148428000000024],
            [62.065005000000042, 25.148405000000025],
            [62.065147000000081, 25.148408000000074],
            [62.065252000000044, 25.148383000000024],
            [62.065341000000046, 25.14830900000004],
            [62.065393000000029, 25.14816200000007],
            [62.065579000000071, 25.147979000000078],
            [62.065739000000065, 25.147809000000052],
            [62.065923000000055, 25.147644000000071],
            [62.066108000000042, 25.147504000000026],
            [62.066404000000034, 25.147307000000069],
            [62.066602000000046, 25.147236000000078],
            [62.066954000000067, 25.147178000000054],
            [62.067157000000066, 25.147050000000036],
            [62.067185000000052, 25.146956000000046],
            [62.06740300000007, 25.146894000000032],
            [62.067604000000074, 25.146761000000026],
            [62.067743000000064, 25.14663100000007],
            [62.067928000000052, 25.146347000000048],
            [62.068205000000034, 25.146010000000047],
            [62.068629000000044, 25.145629000000042],
            [62.069320000000062, 25.144897000000071],
            [62.070219000000066, 25.143894000000046],
            [62.070561000000055, 25.143629000000033],
            [62.070710000000076, 25.143550000000062],
            [62.070897000000059, 25.143488000000048],
            [62.070995000000039, 25.143409000000077],
            [62.071101000000056, 25.143332000000044],
            [62.071414000000061, 25.143162000000075],
            [62.071627000000035, 25.14307800000006],
            [62.071929000000068, 25.14293200000003],
            [62.072244000000069, 25.142735000000073],
            [62.072607000000062, 25.142521000000045],
            [62.073055000000068, 25.142356000000063],
            [62.073289000000045, 25.142183000000045],
            [62.073373000000061, 25.141970000000072],
            [62.073530000000062, 25.141683000000057],
            [62.073580000000049, 25.141516000000024],
            [62.073685000000069, 25.141216000000043],
            [62.073744000000033, 25.141007000000059],
            [62.07371100000006, 25.140861000000029],
            [62.07389900000004, 25.140659000000028],
            [62.073908000000074, 25.140639000000078],
            [62.073927000000083, 25.140576000000067],
            [62.07403400000004, 25.140464000000065],
            [62.074042000000077, 25.140472000000045],
            [62.074306000000036, 25.140265000000056],
            [62.074719000000073, 25.139651000000072],
            [62.074842000000046, 25.139499000000058],
            [62.074968000000069, 25.139238000000034],
            [62.075098000000082, 25.139035000000035],
            [62.075254000000029, 25.138652000000036],
            [62.075320000000033, 25.138269000000037],
            [62.075325000000078, 25.137640000000033],
            [62.075347000000079, 25.137272000000053],
            [62.07528300000007, 25.136972000000071],
            [62.075125000000071, 25.136697000000026],
            [62.074792000000059, 25.136092000000076],
            [62.074709000000041, 25.135835000000043],
            [62.074645000000032, 25.135432000000037],
            [62.074237000000039, 25.133733000000063],
            [62.073031000000071, 25.130242000000067],
            [62.072371000000032, 25.128424000000052],
            [62.071928000000071, 25.126590000000078],
            [62.071840000000066, 25.125993000000051],
            [62.071421000000043, 25.123275000000035],
            [62.071402000000035, 25.122051000000056],
            [62.071450000000027, 25.121486000000061],
            [62.071554000000049, 25.121002000000033],
            [62.071864000000062, 25.120039000000077],
            [62.072146000000032, 25.119408000000078],
            [62.07244200000008, 25.118901000000051],
            [62.072618000000034, 25.118557000000067],
            [62.072701000000052, 25.118412000000035],
            [62.072883000000047, 25.118274000000042],
            [62.073104000000058, 25.118190000000027],
            [62.073364000000083, 25.118114000000048],
            [62.07374800000008, 25.11793700000004],
            [62.074246000000073, 25.117674000000022],
            [62.075849000000062, 25.11692000000005],
            [62.075972000000036, 25.116856000000041],
            [62.076160000000073, 25.116767000000038],
            [62.07635300000004, 25.116694000000052],
            [62.076472000000081, 25.116673000000048],
            [62.076730000000055, 25.116643000000067],
            [62.076837000000069, 25.116685000000075],
            [62.076878000000079, 25.116746000000035],
            [62.076865000000055, 25.116803000000061],
            [62.076804000000038, 25.116885000000025],
            [62.076706000000058, 25.117029000000059],
            [62.076727000000062, 25.117174000000034],
            [62.076799000000051, 25.117271000000073],
            [62.077017000000069, 25.117320000000063],
            [62.077393000000029, 25.117256000000054],
            [62.077658000000042, 25.117187000000058],
            [62.077842000000032, 25.11705500000005],
            [62.078001000000029, 25.117036000000041],
            [62.078160000000082, 25.117017000000033],
            [62.078382000000033, 25.117016000000035],
            [62.078580000000045, 25.116996000000029],
            [62.07909200000006, 25.11691200000007],
            [62.079404000000068, 25.116856000000041],
            [62.07954200000006, 25.11687100000006],
            [62.079705000000047, 25.116865000000075],
            [62.079870000000028, 25.116818000000023],
            [62.079971000000057, 25.116831000000047],
            [62.080157000000042, 25.116850000000056],
            [62.080320000000029, 25.116853000000049],
            [62.080633000000034, 25.116815000000031],
            [62.080836000000033, 25.116770000000031],
            [62.081203000000073, 25.116708000000074],
            [62.081616000000054, 25.116662000000076],
            [62.082029000000034, 25.116640000000075],
            [62.082348000000081, 25.116528000000073],
            [62.082861000000037, 25.116495000000043],
            [62.082969000000048, 25.116436000000078],
            [62.083067000000028, 25.116376000000059],
            [62.083152000000041, 25.116372000000069],
            [62.083205000000078, 25.11626700000005],
            [62.083192000000054, 25.11616900000007],
            [62.083153000000038, 25.116040000000055],
            [62.083043000000032, 25.115896000000077],
            [62.082969000000048, 25.115600000000029],
            [62.082957000000079, 25.115512000000024],
            [62.082936000000075, 25.115309000000025],
            [62.082958000000076, 25.115071000000057],
            [62.083101000000056, 25.114938000000052],
            [62.083200000000033, 25.114956000000063],
            [62.083325000000059, 25.114660000000072],
            [62.083472000000029, 25.114586000000031],
            [62.083601000000044, 25.11460500000004],
            [62.083702000000073, 25.11447000000004],
            [62.083793000000071, 25.114476000000025],
            [62.083897000000036, 25.114455000000078],
            [62.084009000000037, 25.114329000000055],
            [62.084051000000045, 25.114169000000061],
            [62.084069000000056, 25.11390300000005],
            [62.083512000000042, 25.111069000000043],
            [62.083268000000032, 25.109923000000038],
            [62.083160000000078, 25.109277000000077],
            [62.08288900000008, 25.106965000000059],
            [62.082989000000055, 25.105799000000047],
            [62.083168000000057, 25.105093000000068],
            [62.083239000000049, 25.104945000000043],
            [62.083332000000041, 25.104761000000053],
            [62.083495000000028, 25.104647000000057],
            [62.083719000000031, 25.104536000000053],
            [62.083990000000028, 25.104307000000063],
            [62.084758000000079, 25.103662000000043],
            [62.085187000000076, 25.103333000000077],
            [62.085609000000034, 25.103056000000038],
            [62.086096000000055, 25.102705000000071],
            [62.08641300000005, 25.102515000000039],
            [62.086675000000071, 25.102378000000044],
            [62.087048000000038, 25.102266000000043],
            [62.087533000000064, 25.102256000000068],
            [62.087958000000071, 25.102204000000029],
            [62.088400000000036, 25.10208700000004],
            [62.089027000000044, 25.102020000000039],
            [62.089304000000027, 25.101980000000026],
            [62.08952800000003, 25.101954000000035],
            [62.089682000000039, 25.102039000000048],
            [62.089765000000057, 25.102072000000078],
            [62.089930000000038, 25.101986000000068],
            [62.090032000000065, 25.101878000000056],
            [62.090104000000053, 25.101740000000063],
            [62.090117000000077, 25.101586000000054],
            [62.090105000000051, 25.101488000000074],
            [62.090041000000042, 25.101368000000036],
            [62.089889000000028, 25.101224000000059],
            [62.089683000000036, 25.101144000000033],
            [62.089260000000081, 25.101092000000051],
            [62.089075000000037, 25.101148000000023],
            [62.08825900000005, 25.101408000000049],
            [62.084904000000051, 25.101958000000025],
            [62.072797000000037, 25.102970000000028],
            [62.069777000000045, 25.103386000000057],
            [62.061749000000077, 25.103639000000044],
            [62.050818000000049, 25.104164000000026],
            [62.046009000000083, 25.104551000000072],
            [62.039441000000068, 25.105078000000049],
            [62.036400000000071, 25.105615000000057],
            [62.034865000000082, 25.105560000000025],
            [62.032678000000033, 25.105597000000046],
            [62.03099700000007, 25.105854000000079],
            [62.023714000000041, 25.10654500000004],
            [62.009457000000054, 25.107812000000024],
            [61.998983000000067, 25.108765000000062],
            [61.990796000000046, 25.109762000000046],
            [61.985757000000035, 25.110038000000031],
            [61.979067000000043, 25.110498000000064],
            [61.976632000000052, 25.110668000000032],
            [61.971334123000076, 25.111070974000029],
            [61.970190000000059, 25.111158000000046],
            [61.961166000000048, 25.111512000000062],
            [61.955028000000027, 25.111740000000054],
            [61.951782000000037, 25.111599000000069],
            [61.944624000000033, 25.111708000000078],
            [61.938833000000045, 25.11167400000005],
            [61.934983000000045, 25.111572000000024],
            [61.924972000000082, 25.111148000000071],
            [61.916788000000054, 25.110439000000042],
            [61.91540800000007, 25.110242000000028],
            [61.91130000000004, 25.109429000000034],
            [61.905971000000079, 25.108144000000038],
            [61.904011000000082, 25.107683000000065],
            [61.901189000000045, 25.107179000000031],
            [61.896794000000057, 25.106138000000044],
            [61.893664000000058, 25.105183000000068],
            [61.89150200000006, 25.104468000000054],
            [61.887625000000071, 25.103234000000043],
            [61.883817000000079, 25.101898000000062],
            [61.878187000000082, 25.100159000000076],
            [61.875838000000044, 25.099265000000059],
            [61.874866000000054, 25.098874000000023],
            [61.872497000000067, 25.097773000000075],
            [61.871373000000062, 25.097236000000066],
            [61.870110000000068, 25.09653000000003],
            [61.868784000000062, 25.09569300000004],
            [61.868090000000052, 25.095236000000057],
            [61.866945000000044, 25.094299000000035],
            [61.866122000000075, 25.093547000000058],
            [61.865470000000073, 25.09286000000003],
            [61.865004000000056, 25.092365000000029],
            [61.864859000000081, 25.092159000000038],
            [61.864550000000065, 25.091658000000052],
            [61.864104000000054, 25.091079000000036],
            [61.863717000000065, 25.090617000000066],
            [61.863471000000061, 25.090186000000074],
            [61.863277000000039, 25.089836000000048],
            [61.863037000000077, 25.089427000000057],
            [61.862829000000033, 25.089022000000057],
            [61.862757000000045, 25.088719000000026],
            [61.862798000000055, 25.088505000000055],
            [61.862917000000039, 25.088461000000052],
            [61.862856000000079, 25.088405000000023],
            [61.862768000000074, 25.088036000000045],
            [61.862746000000072, 25.087833000000046],
            [61.862463000000048, 25.087419000000068],
            [61.862334000000033, 25.086891000000037],
            [61.862377000000038, 25.08655600000003],
            [61.862469000000033, 25.086287000000027],
            [61.862620000000049, 25.085978000000068],
            [61.862795000000062, 25.085684000000072],
            [61.863011000000029, 25.085384000000033],
            [61.863108000000068, 25.085226000000034],
            [61.863246000000061, 25.085164000000077],
            [61.863354000000072, 25.085164000000077],
            [61.863513000000069, 25.084894000000077],
            [61.863624000000073, 25.08471000000003],
            [61.863913000000082, 25.084212000000036],
            [61.864059000000054, 25.084084000000075],
            [61.86422900000008, 25.084095000000048],
            [61.864385000000027, 25.083989000000031],
            [61.864543000000083, 25.083746000000076],
            [61.864685000000065, 25.083385000000078],
            [61.864656000000082, 25.083072000000072],
            [61.864633000000083, 25.082815000000039],
            [61.86463800000007, 25.082243000000062],
            [61.864874000000043, 25.081631000000073],
            [61.865135000000066, 25.081285000000037],
            [61.865317000000061, 25.081142000000057],
            [61.865525000000048, 25.08107300000006],
            [61.865716000000077, 25.081074000000058],
            [61.866074000000083, 25.081147000000044],
            [61.866470000000049, 25.081245000000024],
            [61.866771000000028, 25.081274000000064],
            [61.867109000000028, 25.081252000000063],
            [61.867439000000047, 25.081178000000023],
            [61.867632000000071, 25.081033000000048],
            [61.867784000000029, 25.080960000000061],
            [61.867904000000067, 25.081008000000054],
            [61.867988000000082, 25.081033000000048],
            [61.868145000000027, 25.080917000000056],
            [61.868344000000036, 25.080784000000051],
            [61.868531000000075, 25.080474000000038],
            [61.868690000000072, 25.080359000000044],
            [61.868931000000032, 25.08020700000003],
            [61.869039000000043, 25.080192000000068],
            [61.869229000000075, 25.08010900000005],
            [61.869502000000068, 25.079931000000045],
            [61.869736000000046, 25.079746000000057],
            [61.86988000000008, 25.079651000000069],
            [61.869940000000042, 25.079457000000048],
            [61.869958000000054, 25.079195000000027],
            [61.870070000000055, 25.079161000000056],
            [61.870337000000063, 25.079036000000031],
            [61.870548000000042, 25.078886000000068],
            [61.870655000000056, 25.078710000000058],
            [61.870975000000044, 25.078141000000073],
            [61.871009000000072, 25.077899000000059],
            [61.871000000000038, 25.077397000000076],
            [61.87105100000008, 25.07690800000006],
            [61.870831000000067, 25.076747000000069],
            [61.87068400000004, 25.076776000000052],
            [61.870412000000044, 25.076839000000064],
            [61.870240000000081, 25.076797000000056],
            [61.869952000000069, 25.076611000000071],
            [61.86966300000006, 25.076309000000037],
            [61.868953000000033, 25.076110000000028],
            [61.868301000000031, 25.075946000000044],
            [61.867739000000029, 25.075717000000054],
            [61.866673000000048, 25.075504000000024],
            [61.866347000000076, 25.075499000000036],
            [61.865294000000063, 25.074901000000068],
            [61.864489000000049, 25.074578000000031],
            [61.863748000000044, 25.073961000000054],
            [61.86325400000004, 25.073955000000069],
            [61.862070000000074, 25.07367000000005],
            [61.861499000000038, 25.073262000000057],
            [61.86108100000007, 25.072797000000037],
            [61.860600000000034, 25.072728000000041],
            [61.859855000000039, 25.072138000000052],
            [61.85913800000003, 25.071108000000038],
            [61.858867000000032, 25.069693000000029],
            [61.858447000000069, 25.068641000000071],
            [61.858201000000065, 25.068527000000074],
            [61.857740000000035, 25.068403000000046],
            [61.85765200000003, 25.068093000000033],
            [61.857941000000039, 25.067523000000051],
            [61.858047000000056, 25.066977000000065],
            [61.857778000000053, 25.06662300000005],
            [61.857767000000081, 25.066173000000049],
            [61.857785000000035, 25.06573000000003],
            [61.856938000000071, 25.064937000000043],
            [61.856653000000051, 25.063703000000032],
            [61.855947000000072, 25.062737000000027],
            [61.856070000000045, 25.06105400000007],
            [61.856089000000054, 25.058716000000061],
            [61.856142000000034, 25.058010000000024],
            [61.855626000000029, 25.056291000000044],
            [61.85589200000004, 25.055724000000055],
            [61.856343000000038, 25.055236000000036],
            [61.856334000000061, 25.054861000000074],
            [61.856693000000064, 25.054092000000026],
            [61.856219000000067, 25.052911000000051],
            [61.855960000000039, 25.052906000000064],
            [61.855841000000055, 25.051891000000069],
            [61.855697000000077, 25.050400000000025],
            [61.855265000000031, 25.048891000000026],
            [61.854964000000052, 25.046594000000027],
            [61.854343000000028, 25.044605000000047],
            [61.854874000000052, 25.043701000000056],
            [61.855281000000048, 25.042455000000075],
            [61.855499000000066, 25.040380000000027],
            [61.854571000000078, 25.038843000000043],
            [61.85245400000008, 25.037075000000073],
            [61.85088600000006, 25.035573000000056],
            [61.846326000000033, 25.034770000000037],
            [61.845287000000042, 25.033743000000072],
            [61.843503000000055, 25.034448000000054],
            [61.840069000000028, 25.033346000000051],
            [61.833213000000057, 25.031958000000031],
            [61.830377000000055, 25.031561000000067],
            [61.819169000000045, 25.030274000000077],
            [61.816223000000036, 25.030017000000043],
            [61.794193000000064, 25.025898000000041],
            [61.792929000000072, 25.024082000000078],
            [61.789679000000035, 25.02145100000007],
            [61.789108000000056, 25.022227000000044],
            [61.786395000000027, 25.01927900000004],
            [61.782796000000076, 25.017894000000069],
            [61.780457000000069, 25.016703000000064],
            [61.778816000000063, 25.014748000000054],
            [61.778044000000079, 25.014815000000056],
            [61.777321000000029, 25.015929000000028],
            [61.777482000000077, 25.017217000000073],
            [61.776896000000079, 25.018225000000029],
            [61.774665000000027, 25.019012000000032],
            [61.771971000000065, 25.01919300000003],
            [61.768180000000029, 25.018850000000043],
            [61.765385000000038, 25.018556000000046],
            [61.755347000000029, 25.017680000000041],
            [61.75393100000008, 25.016878000000077],
            [61.752412000000049, 25.017260000000078],
            [61.751385000000028, 25.018068000000028],
            [61.750788000000057, 25.018981000000053],
            [61.747716000000082, 25.020216000000062],
            [61.745405000000062, 25.021814000000063],
            [61.745150000000081, 25.021719000000076],
            [61.743788000000052, 25.021394000000043],
            [61.742323000000056, 25.021068000000071],
            [61.741940000000056, 25.020334000000048],
            [61.741497000000038, 25.019356000000073],
            [61.740456000000052, 25.019291000000067],
            [61.739755000000059, 25.020040000000051],
            [61.738822000000027, 25.020586000000037],
            [61.73784500000005, 25.020324000000073],
            [61.736781000000065, 25.019754000000034],
            [61.736270000000047, 25.020627000000047],
            [61.735744000000068, 25.021700000000067],
            [61.735573000000045, 25.022494000000052],
            [61.733328000000029, 25.022856000000047],
            [61.731768000000045, 25.022959000000071],
            [61.731360000000052, 25.023939000000041],
            [61.73088400000006, 25.025821000000064],
            [61.729946000000041, 25.027956000000074],
            [61.728796000000045, 25.028483000000051],
            [61.726518000000056, 25.029244000000062],
            [61.727474000000029, 25.029807000000062],
            [61.72795700000006, 25.030747000000076],
            [61.72852000000006, 25.032094000000029],
            [61.730849000000035, 25.033558000000028],
            [61.731514000000061, 25.035012000000052],
            [61.731337000000053, 25.03731700000003],
            [61.730942000000027, 25.039603000000056],
            [61.730057000000045, 25.041052000000036],
            [61.729768000000036, 25.041937000000075],
            [61.730340000000069, 25.043178000000069],
            [61.730634000000066, 25.043698000000063],
            [61.730832000000078, 25.044012000000066],
            [61.732090000000028, 25.044995000000029],
            [61.73326000000003, 25.045671000000027],
            [61.734761000000049, 25.046368000000029],
            [61.735983000000033, 25.046345000000031],
            [61.738199000000066, 25.046385000000043],
            [61.741426000000047, 25.046284000000071],
            [61.742239000000041, 25.047337000000027],
            [61.743008000000032, 25.048798000000033],
            [61.743470000000059, 25.050033000000042],
            [61.743780000000072, 25.051860000000033],
            [61.743154000000061, 25.055645000000027],
            [61.742455000000064, 25.059020000000032],
            [61.740790000000061, 25.063341000000037],
            [61.74019700000008, 25.065213000000028],
            [61.738679000000047, 25.067733000000032],
            [61.736468000000059, 25.070614000000035],
            [61.736377000000061, 25.071920000000034],
            [61.737177000000031, 25.073077000000069],
            [61.737825000000043, 25.073318000000029],
            [61.739121000000068, 25.07380100000006],
            [61.742869000000042, 25.074029000000053],
            [61.745131000000072, 25.074976000000049],
            [61.746191000000067, 25.075646000000063],
            [61.746784000000048, 25.076692000000037],
            [61.74905300000006, 25.078909000000067],
            [61.754075000000057, 25.08090100000004],
            [61.757591000000048, 25.080790000000036],
            [61.760713000000067, 25.083659000000068],
            [61.763515000000041, 25.085840000000076],
            [61.765146000000072, 25.086277000000052],
            [61.767636000000039, 25.087604000000056],
            [61.768256000000065, 25.089314000000059],
            [61.769336000000067, 25.092391000000077],
            [61.769242000000077, 25.096073000000047],
            [61.768226000000027, 25.099697000000049],
            [61.766557000000034, 25.104622000000063],
            [61.763469000000043, 25.111135000000047],
            [61.760220000000061, 25.117302000000052],
            [61.758507000000066, 25.122726000000057],
            [61.757860000000051, 25.126373000000058],
            [61.755794000000037, 25.131606000000033],
            [61.752914000000032, 25.132935000000032],
            [61.749398000000042, 25.132883000000049],
            [61.74683200000004, 25.132556000000022],
            [61.744836000000078, 25.132097000000044],
            [61.743557000000067, 25.132020000000068],
            [61.742067000000077, 25.129748000000063],
            [61.741558000000055, 25.126699000000031],
            [61.741570000000081, 25.121672000000046],
            [61.73992400000003, 25.121401000000048],
            [61.739231000000075, 25.123202000000049],
            [61.740255000000047, 25.126786000000038],
            [61.740173000000027, 25.130301000000031],
            [61.738966000000062, 25.136427000000026],
            [61.737536000000034, 25.138182000000029],
            [61.72634800000003, 25.144018000000074],
            [61.717951000000028, 25.149692000000073],
            [61.708897000000036, 25.154323000000034],
            [61.694371000000046, 25.160291000000029],
            [61.690754000000027, 25.161573000000033],
            [61.680247000000065, 25.165774000000056],
            [61.678512000000069, 25.166671000000065],
            [61.670540000000074, 25.169188000000076],
            [61.664934000000073, 25.169842000000074],
            [61.654063000000065, 25.171508000000074],
            [61.618322000000035, 25.176136000000042],
            [61.630719553000063, 25.226708884000061],
            [61.64080400000006, 25.267846000000077],
            [61.653208000000063, 25.311719000000039],
            [61.655663000000061, 25.347598000000062],
            [61.658052000000055, 25.387202000000059],
            [61.660315000000082, 25.42942400000004],
            [61.665544000000068, 25.487864000000059],
            [61.669384000000036, 25.541751000000033],
            [61.674607000000037, 25.602464000000055],
            [61.675582000000077, 25.639216000000033],
            [61.676675000000046, 25.645535000000052],
            [61.673357000000067, 25.647444000000064],
            [61.678472000000056, 25.652111000000048],
            [61.679006000000072, 25.653253000000063],
            [61.678992000000051, 25.658575000000042],
            [61.685562000000061, 25.662712000000056],
            [61.69018200000005, 25.664822000000072],
            [61.696314000000029, 25.667458000000067],
            [61.692184000000054, 25.674728000000073],
            [61.692513000000076, 25.679547000000071],
            [61.696487000000047, 25.68118400000003],
            [61.696280000000058, 25.684554000000048],
            [61.693691000000058, 25.688363000000038],
            [61.694029000000057, 25.691192000000058],
            [61.691430000000082, 25.693834000000038],
            [61.698573000000067, 25.690883000000042],
            [61.703491000000042, 25.690875000000062],
            [61.706086000000028, 25.693261000000064],
            [61.705693000000053, 25.697735000000023],
            [61.709130000000073, 25.701591000000064],
            [61.713852000000031, 25.707147000000077],
            [61.718942000000027, 25.709156000000064],
            [61.723482000000047, 25.710840000000076],
            [61.727456000000075, 25.711925000000065],
            [61.729872000000057, 25.713186000000064],
            [61.730265000000031, 25.716585000000066],
            [61.727303000000063, 25.718681000000061],
            [61.727285416000029, 25.718938420000029],
            [61.751971307000076, 25.714437051000061],
            [61.765996824000069, 25.707738297000049],
            [61.779231516000038, 25.704947149000077],
            [61.801909591000083, 25.703877209000041],
            [61.805863717000079, 25.701667550000025],
            [61.818796034000059, 25.699830045000056],
            [61.826587988000028, 25.694689681000057],
            [61.841043126000045, 25.688966761000074],
            [61.853628943000047, 25.684496624000076],
            [61.864610000000027, 25.686458000000073],
            [61.877483000000041, 25.686902000000032],
            [61.883233418000032, 25.687285476000056],
            [61.883136973000035, 25.687135450000028],
            [61.88431102800007, 25.687213744000076],
            [61.88629426600005, 25.687346000000048],
            [61.894284266000057, 25.68113100000005],
            [61.90012100000007, 25.68113100000005],
            [61.902274266000063, 25.68113100000005],
            [61.908489266000061, 25.675360000000069],
            [61.91470326600006, 25.669590000000028],
            [61.920918266000058, 25.66337500000003],
            [61.92719900000003, 25.66337500000003],
            [61.936076000000071, 25.662487000000056],
            [61.948949000000027, 25.661600000000078],
            [61.959603000000072, 25.661156000000062],
            [61.959883955000066, 25.661234028000024],
            [61.967593000000079, 25.66337500000003],
            [61.976027000000045, 25.668702000000053],
            [61.984017000000051, 25.675804000000028],
            [61.990675000000067, 25.683794000000034],
            [61.991563000000042, 25.692672000000073],
            [61.997778000000039, 25.701550000000054],
            [62.003992000000039, 25.706877000000077],
            [62.01153800000003, 25.709984000000077],
            [62.014202000000068, 25.714867000000027],
            [62.014646000000027, 25.721525000000042],
            [62.017753000000027, 25.731291000000056],
            [62.021748000000059, 25.735286000000031],
            [62.026631000000066, 25.741057000000069],
            [62.030626000000041, 25.746827000000053],
            [62.028406000000075, 25.752154000000075],
            [62.02929400000005, 25.756593000000066],
            [62.031514000000072, 25.764583000000073],
            [62.033289000000082, 25.769466000000023],
            [62.040391000000056, 25.770797000000073],
            [62.041123365000033, 25.770530706000045],
            [62.04254426600005, 25.770797000000073],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "16",
      properties: { name: "Harnai", count: 661 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.988877000000059, 30.297824000000048],
            [68.004335544000071, 30.283469249000063],
            [68.005308597000067, 30.282565675000058],
            [68.008730981000042, 30.27936799400004],
            [68.02723098000007, 30.272967994000055],
            [68.04782722200008, 30.272967994000055],
            [68.050342355000055, 30.272069840000029],
            [68.067662000000041, 30.265885000000026],
            [68.067680468000049, 30.265883880000047],
            [68.067687678000084, 30.265883443000064],
            [68.067730981000068, 30.265867994000075],
            [68.083807290000038, 30.264906163000035],
            [68.084256174000075, 30.264878949000035],
            [68.09108400000008, 30.26446500000003],
            [68.097744413000044, 30.25637759500006],
            [68.098661974000038, 30.255263446000072],
            [68.10102100000006, 30.252399000000025],
            [68.101023995000048, 30.252376533000074],
            [68.101673854000069, 30.247500528000046],
            [68.102438619000054, 30.241762357000027],
            [68.102439272000083, 30.241757460000031],
            [68.102440000000058, 30.241752000000076],
            [68.115403255000047, 30.232170706000034],
            [68.118765000000053, 30.229686000000072],
            [68.121470415000033, 30.228404510000075],
            [68.122196760000065, 30.228060457000026],
            [68.132251000000053, 30.223298000000057],
            [68.136519500000077, 30.216074348000063],
            [68.137193427000057, 30.214933851000069],
            [68.141478000000063, 30.207683000000031],
            [68.141520062000041, 30.207676270000036],
            [68.141526678000048, 30.207675212000026],
            [68.145147011000063, 30.207095965000065],
            [68.145671337000067, 30.20701207500008],
            [68.159222000000057, 30.204844000000037],
            [68.165548824000041, 30.200530496000056],
            [68.166545889000076, 30.199850717000061],
            [68.174837000000082, 30.194198000000029],
            [68.177690382000037, 30.190936869000041],
            [68.178214636000064, 30.190337698000064],
            [68.184774000000061, 30.182841000000053],
            [68.197920662000058, 30.179807593000078],
            [68.198393227000054, 30.179698555000073],
            [68.203228000000081, 30.17858300000006],
            [68.211745000000064, 30.171485000000075],
            [68.21176590400006, 30.171422476000032],
            [68.211768231000065, 30.17141551800006],
            [68.211813459000041, 30.171280205000073],
            [68.215252192000037, 30.160962970000071],
            [68.217382113000042, 30.146765204000076],
            [68.217415567000046, 30.146731743000032],
            [68.217422591000059, 30.14672471800003],
            [68.226610016000052, 30.137535498000034],
            [68.249324401000081, 30.129014357000074],
            [68.266360649000035, 30.11907362200003],
            [68.281266814000048, 30.109133133000057],
            [68.286921186000029, 30.106399262000025],
            [68.323855683000033, 30.088541550000059],
            [68.343717692000041, 30.076314839000077],
            [68.351538763000065, 30.071500322000077],
            [68.380464600000039, 30.058090656000047],
            [68.380641689000072, 30.058008560000076],
            [68.380644482000037, 30.058005068000057],
            [68.390597573000036, 30.045561746000033],
            [68.391765450000037, 30.044101669000042],
            [68.391998968000053, 30.043809726000063],
            [68.391999216000045, 30.043807910000055],
            [68.392000455000073, 30.04379883200005],
            [68.393037025000069, 30.036201869000024],
            [68.393340898000076, 30.033974805000071],
            [68.394129974000066, 30.028191708000065],
            [68.396733634000043, 30.025876797000024],
            [68.408982894000076, 30.014985997000053],
            [68.409620382000071, 30.01441920700006],
            [68.413295724000079, 30.011151466000058],
            [68.413423621000049, 30.011043438000058],
            [68.413410608000049, 30.011000023000065],
            [68.405656243000067, 29.985125759000027],
            [68.403630980000059, 29.978367993000063],
            [68.405730981000033, 29.967867993000027],
            [68.400130981000075, 29.953167994000069],
            [68.400134985000079, 29.935430000000053],
            [68.400129000000049, 29.935430007000036],
            [68.398889000000054, 29.935429000000056],
            [68.382400000000075, 29.935429000000056],
            [68.366818896000041, 29.933631278000064],
            [68.366508535000037, 29.933595469000068],
            [68.351779000000079, 29.931896000000052],
            [68.332935000000077, 29.931896000000052],
            [68.318802000000062, 29.931896000000052],
            [68.283469000000082, 29.931896000000052],
            [68.271261535000065, 29.931443786000045],
            [68.271177131000059, 29.931440659000032],
            [68.256619581000052, 29.930901389000041],
            [68.251669000000049, 29.93071800000007],
            [68.232825000000048, 29.924830000000043],
            [68.209497075000058, 29.917894309000076],
            [68.190341330000081, 29.912199061000024],
            [68.189248000000077, 29.911874000000068],
            [68.188648337000075, 29.911710453000069],
            [68.159386798000071, 29.903729897000062],
            [68.150382000000036, 29.901274000000058],
            [68.11976100000004, 29.900097000000073],
            [68.104450000000043, 29.900097000000073],
            [68.082973682000045, 29.897028334000026],
            [68.08225843200006, 29.896926134000068],
            [68.079722516000061, 29.896563787000048],
            [68.079717001000063, 29.896563000000071],
            [68.079065901000035, 29.896237469000027],
            [68.063228000000038, 29.888319000000024],
            [68.062051000000054, 29.870653000000061],
            [68.057340000000067, 29.855342000000064],
            [68.057178454000052, 29.853565401000026],
            [68.057092272000034, 29.852617623000071],
            [68.056162000000029, 29.842387000000031],
            [68.043206000000055, 29.830609000000038],
            [68.026718000000074, 29.820009000000027],
            [68.017296000000044, 29.805876000000069],
            [68.000807000000066, 29.80469800000003],
            [67.98667400000005, 29.79881000000006],
            [67.973719000000074, 29.794099000000074],
            [67.97607400000004, 29.769366000000048],
            [67.979608000000042, 29.758766000000037],
            [67.974897000000055, 29.74581100000006],
            [67.965570668000055, 29.743479169000068],
            [67.965524670000036, 29.74346766900004],
            [67.965474000000029, 29.74345500000004],
            [67.957230000000038, 29.735211000000049],
            [67.946630000000027, 29.725789000000077],
            [67.918364000000054, 29.710478000000023],
            [67.907903721000082, 29.70158665200006],
            [67.900727825000047, 29.695487064000076],
            [67.894826918000035, 29.690471230000071],
            [67.89482402200008, 29.690468769000063],
            [67.894809000000066, 29.69045600000004],
            [67.893815925000069, 29.691250425000078],
            [67.88865701900005, 29.695377375000078],
            [67.887437994000038, 29.696352554000043],
            [67.88601737700003, 29.697489000000076],
            [67.883033373000046, 29.699876103000065],
            [67.883031000000074, 29.699878000000069],
            [67.884058913000047, 29.703372799000078],
            [67.888920000000041, 29.719900000000052],
            [67.894377084000041, 29.727176114000031],
            [67.895508266000036, 29.728684356000031],
            [67.903053000000057, 29.738744000000054],
            [67.907164159000047, 29.748727460000055],
            [67.907939633000069, 29.750596072000064],
            [67.911330980000059, 29.758767993000049],
            [67.927830980000067, 29.775267994000046],
            [67.938430980000078, 29.792967993000048],
            [67.94139344000007, 29.800924317000067],
            [67.942223052000031, 29.803152415000056],
            [67.945453000000043, 29.811765000000037],
            [67.948915042000067, 29.813279597000076],
            [67.949631502000045, 29.813593038000079],
            [67.952697020000073, 29.814934162000043],
            [67.964297000000045, 29.820009000000027],
            [67.973719000000074, 29.837676000000044],
            [67.98667400000005, 29.854164000000026],
            [67.992563000000075, 29.874186000000066],
            [68.000401023000052, 29.882808025000031],
            [68.001000891000047, 29.883467895000024],
            [68.00434000000007, 29.887141000000042],
            [68.005944204000059, 29.892640822000033],
            [68.006211976000031, 29.893558847000065],
            [68.00687238900008, 29.89582299500006],
            [68.012585000000058, 29.91540800000007],
            [68.012585000000058, 29.941318000000024],
            [68.012585000000058, 29.960162000000025],
            [68.00347529700008, 29.977552420000052],
            [68.002211757000055, 29.979964516000052],
            [67.999629000000084, 29.984895000000051],
            [67.977252000000078, 30.011984000000041],
            [67.947808000000066, 30.026117000000056],
            [67.928776137000057, 30.028599264000036],
            [67.928446865000069, 30.028642210000044],
            [67.920720000000074, 30.029650000000061],
            [67.894809000000066, 30.029650000000061],
            [67.889931923000063, 30.027586695000025],
            [67.888499245000048, 30.026980583000068],
            [67.864187000000072, 30.016695000000027],
            [67.840632000000028, 30.016695000000027],
            [67.808967786000039, 30.01341872100005],
            [67.808227849000048, 30.013342159000047],
            [67.806477000000029, 30.013161000000025],
            [67.779389000000037, 30.013161000000025],
            [67.775587444000053, 30.016085350000026],
            [67.76407800000004, 30.024939000000074],
            [67.764129317000084, 30.024964660000023],
            [67.764132949000043, 30.024966476000031],
            [67.76482967100003, 30.025314862000073],
            [67.76566525000004, 30.025729688000069],
            [67.778230980000046, 30.03196799400007],
            [67.793530980000071, 30.047267994000038],
            [67.793514928000036, 30.047302943000034],
            [67.793513045000054, 30.047307044000036],
            [67.793522000000053, 30.047316000000023],
            [67.793361539000045, 30.047636923000027],
            [67.793294037000067, 30.047783896000055],
            [67.789035332000083, 30.056289337000067],
            [67.789035200000058, 30.056289600000071],
            [67.784751379000056, 30.064857242000073],
            [67.78410000000008, 30.066160000000025],
            [67.76836178800005, 30.077964160000079],
            [67.760565881000048, 30.083835971000042],
            [67.755689945000029, 30.087063703000069],
            [67.746412000000078, 30.093249000000071],
            [67.72357308100004, 30.10238410200003],
            [67.716965882000068, 30.105035971000063],
            [67.712646633000077, 30.105952176000073],
            [67.70047900000003, 30.108560000000068],
            [67.686346000000071, 30.123871000000065],
            [67.678259931000071, 30.122523036000075],
            [67.672213000000056, 30.121515000000045],
            [67.653999298000031, 30.120376402000034],
            [67.653369000000055, 30.120337000000063],
            [67.638058000000058, 30.120337000000063],
            [67.622747000000061, 30.11798200000004],
            [67.610970000000066, 30.103849000000025],
            [67.604838156000028, 30.102060447000042],
            [67.582703000000038, 30.095604000000037],
            [67.566215000000057, 30.095604000000037],
            [67.562408580000067, 30.087991805000058],
            [67.560326000000032, 30.083827000000042],
            [67.560942371000067, 30.078280712000037],
            [67.56150400000007, 30.073227000000031],
            [67.552082000000041, 30.067338000000063],
            [67.547731301000056, 30.064727876000063],
            [67.540304000000049, 30.060272000000055],
            [67.540299485000048, 30.060256190000075],
            [67.538747280000052, 30.054821827000069],
            [67.537949000000083, 30.052027000000066],
            [67.537219916000083, 30.047653422000053],
            [67.535593000000063, 30.037894000000051],
            [67.527233099000057, 30.035321765000049],
            [67.520282000000066, 30.033183000000065],
            [67.510046970000076, 30.030340231000025],
            [67.499083000000041, 30.027295000000038],
            [67.488508029000059, 30.027916653000034],
            [67.479061000000058, 30.028472000000079],
            [67.469001280000043, 30.030247599000063],
            [67.459065881000072, 30.032035971000028],
            [67.431965881000053, 30.047335971000052],
            [67.430665882000028, 30.053635971000062],
            [67.430667436000078, 30.053638658000068],
            [67.43099600000005, 30.053663000000029],
            [67.438094000000035, 30.066439000000059],
            [67.438094000000035, 30.086312000000078],
            [67.43099600000005, 30.107605000000035],
            [67.423898000000065, 30.133867000000066],
            [67.41751000000005, 30.167936000000054],
            [67.416269237000051, 30.169838637000055],
            [67.406732655000042, 30.183141070000033],
            [67.390532655000072, 30.184941070000036],
            [67.38763407700003, 30.180613615000027],
            [67.383441000000062, 30.17432400000007],
            [67.362858000000074, 30.17432400000007],
            [67.337306000000069, 30.173614000000043],
            [67.318852000000049, 30.172904000000074],
            [67.307927664000033, 30.175635245000024],
            [67.301817000000028, 30.177163000000064],
            [67.29046100000005, 30.191359000000034],
            [67.281944000000067, 30.204134000000067],
            [67.28123400000004, 30.219040000000064],
            [67.281944000000067, 30.230396000000042],
            [67.284073000000035, 30.241043000000047],
            [67.27764585400007, 30.248847162000061],
            [67.274136000000055, 30.253109000000052],
            [67.269404343000076, 30.246799569000075],
            [67.265619000000072, 30.241752000000076],
            [67.257102000000032, 30.233235000000036],
            [67.253665484000066, 30.228080630000079],
            [67.252843000000041, 30.226847000000078],
            [67.252836639000066, 30.226847000000078],
            [67.234415269000067, 30.226847000000078],
            [67.234389000000078, 30.226847000000078],
            [67.221613000000048, 30.231106000000068],
            [67.221334120000051, 30.231259797000064],
            [67.225297724000029, 30.233605183000066],
            [67.225872000000038, 30.233945000000062],
            [67.230840000000057, 30.246721000000036],
            [67.235199870000031, 30.248174062000032],
            [67.243632655000056, 30.250941070000067],
            [67.251432655000031, 30.258041070000047],
            [67.264835170000083, 30.26773328400003],
            [67.269168000000036, 30.270853000000045],
            [67.27816069000005, 30.281843987000059],
            [67.281932655000048, 30.286441070000024],
            [67.292632655000034, 30.296441070000071],
            [67.290790514000037, 30.304953030000036],
            [67.289751000000081, 30.30989100000005],
            [67.288605672000074, 30.318481280000071],
            [67.28711198700006, 30.329932860000042],
            [67.287032655000075, 30.330541070000038],
            [67.308932655000035, 30.338941070000033],
            [67.314079569000057, 30.336407824000048],
            [67.314191976000075, 30.336351619000027],
            [67.321689215000049, 30.332603000000063],
            [67.33446521500008, 30.325505000000078],
            [67.337624299000083, 30.323207411000055],
            [67.337999701000058, 30.322934382000028],
            [67.342252870000038, 30.319841070000052],
            [67.342272216000083, 30.319827000000032],
            [67.365695216000063, 30.319827000000032],
            [67.37273265500005, 30.319086231000028],
            [67.37280822200006, 30.319078277000074],
            [67.392666216000066, 30.316988000000038],
            [67.40494822900007, 30.313349028000061],
            [67.40506091900005, 30.313315639000052],
            [67.411830216000055, 30.311310000000049],
            [67.415635489000067, 30.310487278000039],
            [67.437237672000037, 30.305638378000026],
            [67.437238484000034, 30.305638763000047],
            [67.453933280000058, 30.313552532000074],
            [67.455126216000053, 30.314149000000043],
            [67.455127999000069, 30.314148999000054],
            [67.482064097000034, 30.314135972000031],
            [67.49462524300003, 30.31550965200006],
            [67.494865881000067, 30.315535971000031],
            [67.511195802000032, 30.306344372000069],
            [67.511200000000031, 30.306342000000029],
            [67.519919820000041, 30.295131397000034],
            [67.521137364000083, 30.293566000000055],
            [67.522573605000048, 30.292651861000024],
            [67.52894436400004, 30.288597000000038],
            [67.53731207900006, 30.284878062000075],
            [67.54806588100007, 30.280035971000075],
            [67.558773128000041, 30.276667400000065],
            [67.565853364000077, 30.274402000000066],
            [67.57933836400008, 30.27369200000004],
            [67.584552932000065, 30.275887485000055],
            [67.592865882000069, 30.279335971000023],
            [67.604165882000075, 30.286435972000049],
            [67.616965882000045, 30.292835972000034],
            [67.630955541000048, 30.295825215000036],
            [67.640379364000069, 30.297824000000048],
            [67.642653530000075, 30.297725222000054],
            [67.64265389500008, 30.297725206000052],
            [67.65670300000005, 30.297115000000076],
            [67.659532944000034, 30.296643195000058],
            [67.669465882000054, 30.294935971000029],
            [67.682265881000035, 30.29923597100003],
            [67.696465882000041, 30.307035971000062],
            [67.703237046000083, 30.308990066000035],
            [67.71135600000008, 30.311310000000049],
            [67.729810000000043, 30.31911800000006],
            [67.72982677300007, 30.319125547000056],
            [67.740539170000034, 30.323945559000038],
            [67.744005000000072, 30.325505000000078],
            [67.749365067000042, 30.331311844000027],
            [67.752565881000066, 30.334735971000043],
            [67.755490534000046, 30.340488012000037],
            [67.758224996000081, 30.346067994000066],
            [67.756824995000045, 30.362367994000067],
            [67.74332499500008, 30.378067994000048],
            [67.74654601800006, 30.379857450000031],
            [67.747793000000058, 30.380559000000062],
            [67.754624995000029, 30.384367994000058],
            [67.754629494000028, 30.384367165000072],
            [67.758055968000065, 30.383736143000078],
            [67.773811982000041, 30.380584644000066],
            [67.775945000000036, 30.380158000000051],
            [67.77916360100005, 30.37953499300005],
            [67.779782543000067, 30.379415188000053],
            [67.797948000000076, 30.375899000000061],
            [67.814200757000037, 30.374778528000036],
            [67.814403386000038, 30.374764559000027],
            [67.814546287000042, 30.374754708000069],
            [67.818531000000064, 30.374480000000062],
            [67.837127724000084, 30.369698135000078],
            [67.839502176000053, 30.369087581000031],
            [67.840064655000049, 30.36894294800004],
            [67.84337400000004, 30.368092000000047],
            [67.84776596100005, 30.367160241000079],
            [67.848227804000032, 30.367063531000042],
            [67.85512654200005, 30.365618924000046],
            [67.855124697000065, 30.365618057000063],
            [67.85509686000006, 30.365604984000072],
            [67.866796000000079, 30.36312300000003],
            [67.870108260000052, 30.359259345000055],
            [67.870405069000071, 30.358913127000051],
            [67.871055000000069, 30.358155000000068],
            [67.871055000000069, 30.347508000000062],
            [67.873936202000039, 30.343054998000071],
            [67.874278634000063, 30.342525758000079],
            [67.878862000000083, 30.335442000000057],
            [67.891559465000057, 30.32803595300004],
            [67.892268472000069, 30.32762241000006],
            [67.895897000000048, 30.325506000000075],
            [67.912133990000029, 30.323186336000049],
            [67.91553844200007, 30.322699967000062],
            [67.920739000000083, 30.321957000000054],
            [67.940612000000044, 30.319827000000032],
            [67.947761505000074, 30.313939591000064],
            [67.951809959000059, 30.310605807000059],
            [67.952678000000049, 30.30989100000005],
            [67.95271593700005, 30.309875480000073],
            [67.952727788000061, 30.30987063200007],
            [67.968293000000074, 30.303503000000035],
            [67.974388260000069, 30.301821354000026],
            [67.974790061000078, 30.30171050000007],
            [67.988877000000059, 30.297824000000048],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "17",
      properties: { name: "Jaffarabad", count: 1015 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.46376308300006, 28.432272110000042],
            [68.461379000000079, 28.432272000000069],
            [68.43851267000008, 28.41757216700006],
            [68.410821630000044, 28.399770725000053],
            [68.40198033300004, 28.394087015000025],
            [68.394985000000077, 28.389590000000055],
            [68.389467553000031, 28.376283168000043],
            [68.383948276000069, 28.362971923000032],
            [68.374024903000077, 28.339038994000077],
            [68.368318767000062, 28.325277085000039],
            [68.368287247000069, 28.325201068000069],
            [68.366834943000072, 28.321698439000045],
            [68.364424583000073, 28.315885197000057],
            [68.354674000000045, 28.292369000000065],
            [68.350093462000075, 28.290873298000065],
            [68.343522187000076, 28.288727553000058],
            [68.338242394000076, 28.287003520000042],
            [68.322801452000078, 28.281961526000032],
            [68.31764996000004, 28.280279388000054],
            [68.307210276000035, 28.276870475000067],
            [68.302260279000052, 28.275254132000043],
            [68.297102079000069, 28.273569804000033],
            [68.257792015000064, 28.260733726000069],
            [68.255944440000064, 28.260130430000061],
            [68.246080737000057, 28.256909593000046],
            [68.24193738300005, 28.255556647000049],
            [68.241795274000083, 28.25551091400007],
            [68.231441400000051, 28.252228234000029],
            [68.22802929900007, 28.251161975000059],
            [68.211762586000077, 28.246078734000037],
            [68.200544000000036, 28.24257300000005],
            [68.19538667300003, 28.238597982000044],
            [68.191982568000071, 28.23597426300006],
            [68.191982532000054, 28.235974235000072],
            [68.179906999000082, 28.226667000000077],
            [68.179276175000041, 28.226179914000056],
            [68.179193157000043, 28.226115923000066],
            [68.17859444100003, 28.225654429000031],
            [68.177882901000032, 28.225105013000075],
            [68.15384748300005, 28.206546069000069],
            [68.134108765000065, 28.191304820000028],
            [68.13319599700003, 28.190600026000027],
            [68.129941468000084, 28.188087042000063],
            [68.098610510000071, 28.163894846000062],
            [68.093035623000048, 28.159590197000057],
            [68.078988694000032, 28.14874386300005],
            [68.073945412000057, 28.144849694000072],
            [68.07234055300006, 28.143610502000058],
            [68.071405955000046, 28.142888852000056],
            [68.065384000000051, 28.138239000000056],
            [68.061155760000076, 28.134614819000035],
            [68.049030874000039, 28.124222129000032],
            [68.038113184000053, 28.114864173000058],
            [68.031635447000042, 28.109311864000063],
            [68.02740439400003, 28.105685271000027],
            [68.020926969000072, 28.100133229000051],
            [68.015588000000037, 28.095557000000042],
            [68.010751065000079, 28.093520407000028],
            [68.008051743000067, 28.092383857000073],
            [68.007102281000073, 28.091984086000025],
            [68.005540027000052, 28.09132629800007],
            [68.003787712000076, 28.090588485000069],
            [68.002162167000051, 28.08990404900004],
            [68.000791726000045, 28.089327024000056],
            [67.999123544000042, 28.088624636000077],
            [67.997911080000051, 28.088114128000029],
            [67.99629838900006, 28.087435103000075],
            [67.994616610000037, 28.086726990000045],
            [67.993308606000028, 28.086176254000065],
            [67.993136040000081, 28.086103595000054],
            [67.99196244500007, 28.085609453000075],
            [67.990483692000055, 28.084986823000065],
            [67.98900801700006, 28.084365490000039],
            [67.987605976000054, 28.083775160000073],
            [67.986442564000072, 28.083285305000061],
            [67.985322256000075, 28.082813599000076],
            [67.984156458000029, 28.082322740000052],
            [67.983108813000058, 28.081881629000065],
            [67.981834075000052, 28.081344900000033],
            [67.980936688000043, 28.080967055000031],
            [67.970534000000043, 28.076587000000075],
            [67.967796813000064, 28.074260405000075],
            [67.960701683000082, 28.068229582000072],
            [67.954206528000043, 28.062708735000058],
            [67.945402885000078, 28.055225685000039],
            [67.936326619000056, 28.04751090600007],
            [67.927558060000081, 28.04005767700005],
            [67.923109000000068, 28.036276000000043],
            [67.922822217000032, 28.035200473000032],
            [67.91967388900008, 28.023393246000069],
            [67.919317894000073, 28.022058153000046],
            [67.918970997000031, 28.020757178000054],
            [67.918636286000037, 28.019501906000073],
            [67.913625000000081, 28.000708001000078],
            [67.91067445300007, 28.001018550000026],
            [67.899973841000076, 28.002144805000057],
            [67.868571000000031, 28.005450000000053],
            [67.858382000000063, 28.001374457000054],
            [67.838677951000079, 27.993492949000029],
            [67.83442893800003, 27.991793367000071],
            [67.833003000000076, 27.991223000000048],
            [67.816435925000064, 27.989841974000058],
            [67.810343223000075, 27.989334088000078],
            [67.804548000000068, 27.988851000000068],
            [67.787949000000083, 27.981738000000064],
            [67.787937678000048, 27.981590800000049],
            [67.787919362000082, 27.981352666000078],
            [67.785578000000044, 27.950912000000073],
            [67.781292447000055, 27.950054890000047],
            [67.747541112000079, 27.943304623000074],
            [67.738153000000068, 27.941427000000033],
            [67.732628814000066, 27.941092290000029],
            [67.732628387000034, 27.94109226300003],
            [67.721113295000066, 27.940394563000041],
            [67.676206537000041, 27.93767366000003],
            [67.67620682300003, 27.937675125000055],
            [67.671978139000032, 27.937419008000063],
            [67.671978000000081, 27.937419000000034],
            [67.672216288000072, 27.945522652000079],
            [67.672270651000076, 27.947371407000048],
            [67.67274400000008, 27.963469000000032],
            [67.67274400000008, 27.966890552000052],
            [67.67274400000008, 27.976713333000077],
            [67.67274400000008, 27.984182000000033],
            [67.672475442000064, 27.985371438000072],
            [67.670783372000074, 27.992865583000025],
            [67.66736179600008, 28.008019681000064],
            [67.667168000000061, 28.008878000000038],
            [67.66440536600004, 28.015094254000076],
            [67.663335839000069, 28.017500816000052],
            [67.660795000000064, 28.023218000000043],
            [67.660298357000045, 28.023652538000078],
            [67.656631106000077, 28.026861203000067],
            [67.648048000000074, 28.034371000000078],
            [67.642670813000052, 28.040286175000062],
            [67.640082000000064, 28.043134000000066],
            [67.633970358000056, 28.052640715000052],
            [67.632912000000033, 28.054287000000045],
            [67.63293862300003, 28.054766612000037],
            [67.633230688000083, 28.060028196000076],
            [67.63370800000007, 28.068627000000049],
            [67.633895285000051, 28.07237105400003],
            [67.634383511000067, 28.082131282000034],
            [67.634505000000047, 28.084560000000067],
            [67.63479787600005, 28.087928715000032],
            [67.636098000000061, 28.102883000000077],
            [67.633994002000065, 28.116035299000032],
            [67.633368823000069, 28.119943354000043],
            [67.632912744000066, 28.122794353000074],
            [67.632912000000033, 28.122799000000043],
            [67.628676650000045, 28.132329036000044],
            [67.626539000000037, 28.137139000000047],
            [67.626539000000037, 28.143188683000062],
            [67.626525879000042, 28.149902344000054],
            [67.636108398000033, 28.154724121000072],
            [67.649719238000046, 28.154724121000072],
            [67.657602498000074, 28.15845512900006],
            [67.657615730000032, 28.158461392000049],
            [67.657620121000036, 28.158463470000072],
            [67.657636370000034, 28.158471160000033],
            [67.657643553000071, 28.158455165000078],
            [67.657725973000083, 28.158493356000065],
            [67.657746778000046, 28.158447697000042],
            [67.657762705000039, 28.15845513000005],
            [67.68549100000007, 28.171395000000075],
            [67.692629522000061, 28.174033132000034],
            [67.702217603000065, 28.177576531000057],
            [67.722137000000032, 28.184938000000045],
            [67.728433980000034, 28.186813707000056],
            [67.731759570000065, 28.187804314000061],
            [67.747870586000033, 28.19260336800005],
            [67.759579000000031, 28.196091000000024],
            [67.762691872000062, 28.196906246000026],
            [67.771921363000047, 28.199323400000026],
            [67.793039000000078, 28.204854000000068],
            [67.801060568000082, 28.206458358000077],
            [67.807061678000082, 28.207658614000024],
            [67.828888000000063, 28.212024000000042],
            [67.835308310000073, 28.213220995000029],
            [67.875890000000084, 28.22078700000003],
            [67.876342257000033, 28.22092544800006],
            [67.902839550000067, 28.22903700300003],
            [67.914926000000037, 28.232737000000043],
            [67.919906424000033, 28.234077865000074],
            [67.922003701000051, 28.234642509000025],
            [67.938888656000074, 28.239188395000042],
            [67.956352000000038, 28.243890000000079],
            [67.961850000000084, 28.246984000000054],
            [67.961873458000071, 28.246997194000073],
            [67.962162597000031, 28.247159817000068],
            [67.981845000000078, 28.258230000000026],
            [67.986138373000074, 28.264009565000038],
            [67.998888674000057, 28.281173502000058],
            [68.002558000000079, 28.286113000000057],
            [68.018035866000048, 28.298108274000072],
            [68.023064939000051, 28.302005781000048],
            [68.034424000000058, 28.310809000000063],
            [68.035419907000062, 28.311687726000059],
            [68.043066641000053, 28.318434728000057],
            [68.043215986000064, 28.318566502000067],
            [68.061510000000055, 28.334708000000035],
            [68.069665476000068, 28.344746418000057],
            [68.071866000000057, 28.347455000000025],
            [68.071866000000057, 28.368964000000062],
            [68.075849000000062, 28.388881000000026],
            [68.076328066000031, 28.389300159000072],
            [68.084209451000049, 28.39619598400003],
            [68.086432934000072, 28.398141423000027],
            [68.088596000000052, 28.400034000000062],
            [68.10452900000007, 28.409594000000027],
            [68.125242000000071, 28.420747000000063],
            [68.148345000000063, 28.43030600000003],
            [68.14937425100004, 28.430524348000063],
            [68.155279574000076, 28.431777114000056],
            [68.174634000000083, 28.435883000000047],
            [68.193840554000076, 28.439175592000026],
            [68.202517000000057, 28.440663000000029],
            [68.205689134000067, 28.440913435000027],
            [68.23279000000008, 28.443053000000077],
            [68.241645574000074, 28.442222747000073],
            [68.258282000000065, 28.440663000000029],
            [68.279792000000043, 28.443850000000054],
            [68.288990658000046, 28.443850000000054],
            [68.305285000000083, 28.443850000000054],
            [68.322015000000079, 28.442256000000043],
            [68.334761000000071, 28.438273000000038],
            [68.343072887000062, 28.439460199000052],
            [68.34591400000005, 28.439866000000052],
            [68.361847000000068, 28.438273000000038],
            [68.363084506000064, 28.438659708000046],
            [68.374593000000061, 28.442256000000043],
            [68.376983000000052, 28.453409000000079],
            [68.382560000000069, 28.462969000000044],
            [68.382609973000058, 28.463138920000063],
            [68.386543000000074, 28.476512000000071],
            [68.386543000000074, 28.478372056000069],
            [68.386543000000074, 28.496428000000037],
            [68.386543000000074, 28.518735000000049],
            [68.386447553000039, 28.519031937000079],
            [68.379373000000044, 28.541041000000064],
            [68.380636246000051, 28.548935197000048],
            [68.382560000000069, 28.56095700000003],
            [68.378577000000064, 28.582467000000065],
            [68.378577000000064, 28.598921508000046],
            [68.378577000000064, 28.604773000000023],
            [68.378832713000065, 28.611427000000049],
            [68.379023999000083, 28.616404501000034],
            [68.379085305000046, 28.617999787000031],
            [68.385783232000051, 28.616433272000052],
            [68.410442064000051, 28.623054179000064],
            [68.439338875000033, 28.619933863000028],
            [68.44631398000007, 28.618200267000077],
            [68.455570412000043, 28.611468181000077],
            [68.455523768000035, 28.608033250000062],
            [68.455271922000065, 28.602801867000039],
            [68.455271627000059, 28.602799576000052],
            [68.454466982000042, 28.596378102000074],
            [68.45390457600007, 28.592389084000047],
            [68.451610060000064, 28.57305758800004],
            [68.457202641000038, 28.556735509000077],
            [68.455212274000075, 28.554382919000034],
            [68.455210688000079, 28.554381045000071],
            [68.45179051100007, 28.550338429000078],
            [68.451306516000045, 28.549762906000069],
            [68.450999900000056, 28.549403935000043],
            [68.448450935000039, 28.546391084000049],
            [68.433156422000081, 28.528806942000074],
            [68.43058508300004, 28.514317891000076],
            [68.44279898700006, 28.497259669000073],
            [68.444145442000035, 28.49460253500007],
            [68.447136044000047, 28.488700791000042],
            [68.448273651000079, 28.482030501000054],
            [68.448992845000078, 28.477787019000061],
            [68.44202253900005, 28.470579348000058],
            [68.434519406000049, 28.460531740000079],
            [68.436477429000036, 28.452306190000058],
            [68.439610580000078, 28.449592039000038],
            [68.439810952000073, 28.44941846200004],
            [68.43988190400006, 28.449359779000076],
            [68.43988194800005, 28.44935974200007],
            [68.444204913000078, 28.445792823000033],
            [68.445904940000048, 28.444359899000062],
            [68.452785684000048, 28.438560235000068],
            [68.46376308300006, 28.432272110000042],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "18",
      properties: { name: "Jhal Magsi", count: 864 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.59947470000003, 28.826214922000077],
            [67.601651340000046, 28.826081270000032],
            [67.607968026000037, 28.828692963000037],
            [67.60833352700007, 28.828844083000035],
            [67.625851340000054, 28.836181270000054],
            [67.631083818000036, 28.837186639000038],
            [67.638848148000079, 28.838665853000066],
            [67.63942641400007, 28.838776021000058],
            [67.639643378000073, 28.83881735600005],
            [67.641009331000078, 28.839077589000055],
            [67.642224000000056, 28.839309000000071],
            [67.647479368000063, 28.840149730000064],
            [67.656032095000057, 28.841517955000029],
            [67.656957146000082, 28.841665940000041],
            [67.656957182000042, 28.841665946000035],
            [67.661727000000042, 28.842429000000038],
            [67.666990441000053, 28.841927812000051],
            [67.678110000000061, 28.840869000000055],
            [67.67839411600005, 28.840824051000027],
            [67.678851340000051, 28.840781270000036],
            [67.679467662000036, 28.840680735000035],
            [67.684315645000083, 28.839889930000027],
            [67.684490673000028, 28.83986137900007],
            [67.690244549000056, 28.838953187000072],
            [67.692932000000042, 28.838529000000051],
            [67.694673390000048, 28.838238613000044],
            [67.695648327000072, 28.838101233000032],
            [67.69950775600006, 28.837432455000055],
            [67.699507909000033, 28.837432430000035],
            [67.701699666000081, 28.837071994000041],
            [67.702251340000032, 28.836981270000024],
            [67.709341844000051, 28.834769033000043],
            [67.714751340000078, 28.833081270000037],
            [67.714769310000065, 28.833069778000038],
            [67.714775000000031, 28.833068000000026],
            [67.715284573000076, 28.832743743000037],
            [67.717252380000048, 28.831491565000078],
            [67.723357000000078, 28.827607000000057],
            [67.729597000000069, 28.821366000000069],
            [67.72986682700008, 28.821096173000058],
            [67.732851290000042, 28.818111710000039],
            [67.73290085800005, 28.818062142000031],
            [67.734278000000074, 28.816685000000064],
            [67.732073185000047, 28.814869378000026],
            [67.727303850000055, 28.81094192300003],
            [67.724171377000062, 28.808362392000049],
            [67.721051340000031, 28.805781270000068],
            [67.721025216000044, 28.80576564100005],
            [67.718727258000058, 28.804390794000028],
            [67.709314000000063, 28.798743000000059],
            [67.705552061000049, 28.794980622000026],
            [67.703593817000069, 28.79302215000007],
            [67.703436057000033, 28.792864372000054],
            [67.700751106000041, 28.79017910500005],
            [67.700138579000054, 28.784513229000027],
            [67.69917296400007, 28.775581294000062],
            [67.699151340000071, 28.775381270000025],
            [67.69917296400007, 28.775212330000045],
            [67.699845468000035, 28.769958390000056],
            [67.700751340000068, 28.762881270000037],
            [67.701351340000031, 28.760881270000027],
            [67.705451340000081, 28.746481270000061],
            [67.705477857000062, 28.746454441000026],
            [67.70915810300005, 28.742730898000048],
            [67.713951340000051, 28.737881270000059],
            [67.729551340000057, 28.720681270000057],
            [67.730951340000047, 28.719181270000036],
            [67.738151340000059, 28.711381270000061],
            [67.739773631000048, 28.708298919000072],
            [67.739778913000066, 28.708288881000044],
            [67.739804853000066, 28.708298919000072],
            [67.740644160000045, 28.706713547000049],
            [67.745200000000068, 28.698108000000047],
            [67.745636607000051, 28.694905655000071],
            [67.74558945800004, 28.694889264000039],
            [67.745636607000051, 28.694545753000057],
            [67.745851340000058, 28.692981270000075],
            [67.747118251000074, 28.684038369000064],
            [67.74755134000003, 28.680981270000075],
            [67.749314186000049, 28.67435540200006],
            [67.750451340000041, 28.670081270000026],
            [67.752951340000038, 28.660681270000055],
            [67.755551340000068, 28.654481270000076],
            [67.755622684000059, 28.654482488000042],
            [67.757126678000077, 28.650937456000065],
            [67.758582877000038, 28.647505082000066],
            [67.763851340000031, 28.635381270000039],
            [67.774846166000032, 28.609993217000067],
            [67.774846383000067, 28.609992716000079],
            [67.774851340000055, 28.609981270000048],
            [67.77155134000003, 28.605581270000073],
            [67.772951116000058, 28.60001132900004],
            [67.776351340000076, 28.586481270000036],
            [67.777955684000062, 28.581921554000076],
            [67.778251340000054, 28.581081270000027],
            [67.782651340000029, 28.568881270000077],
            [67.784010291000072, 28.561261437000041],
            [67.785451340000066, 28.553181270000039],
            [67.78665043500007, 28.546586253000044],
            [67.786651340000049, 28.546581270000047],
            [67.786151627000038, 28.545533262000049],
            [67.782322549000071, 28.537450008000064],
            [67.779495000000054, 28.531481000000042],
            [67.767546000000038, 28.516345000000058],
            [67.758718723000072, 28.504575296000041],
            [67.757451340000046, 28.502881270000046],
            [67.753251340000077, 28.497181270000056],
            [67.752666288000057, 28.494670420000034],
            [67.750851340000054, 28.486881270000026],
            [67.749186733000045, 28.483326284000043],
            [67.746959183000058, 28.478569063000066],
            [67.745020046000036, 28.474480388000075],
            [67.744451340000069, 28.47328127000003],
            [67.742404158000056, 28.46873908300006],
            [67.741251340000076, 28.46618127000005],
            [67.740735527000083, 28.452091462000055],
            [67.740460000000041, 28.444646000000034],
            [67.740434772000071, 28.444418957000039],
            [67.738070176000065, 28.423138587000039],
            [67.747451340000055, 28.401881270000047],
            [67.747559896000041, 28.401633763000063],
            [67.75245134000005, 28.390481270000066],
            [67.753994546000058, 28.378583651000042],
            [67.755551340000068, 28.36658127000004],
            [67.762439422000057, 28.353509570000028],
            [67.764351340000076, 28.349881270000026],
            [67.768251340000063, 28.337481270000069],
            [67.772351340000057, 28.32438127000006],
            [67.774116382000045, 28.318027121000057],
            [67.776351340000076, 28.309981270000037],
            [67.778887003000079, 28.304855994000036],
            [67.781051340000033, 28.300481270000034],
            [67.781051340000033, 28.290881270000057],
            [67.783851340000069, 28.284181270000033],
            [67.785051340000052, 28.281381270000054],
            [67.785053222000045, 28.281378069000027],
            [67.793051340000034, 28.267781270000057],
            [67.795451340000056, 28.254281270000035],
            [67.795781809000061, 28.251240956000061],
            [67.795951340000045, 28.249681270000053],
            [67.796330802000057, 28.246576575000063],
            [67.797051340000053, 28.240681270000039],
            [67.798551340000074, 28.230981270000029],
            [67.799451340000076, 28.224781270000051],
            [67.801051340000072, 28.20648127000004],
            [67.801003034000075, 28.206471608000072],
            [67.801005000000032, 28.206449000000077],
            [67.801060408000069, 28.206460079000067],
            [67.801060568000082, 28.206458358000077],
            [67.793039000000078, 28.204854000000068],
            [67.771921363000047, 28.199323400000026],
            [67.762691872000062, 28.196906246000026],
            [67.759579000000031, 28.196091000000024],
            [67.747870586000033, 28.19260336800005],
            [67.731759570000065, 28.187804314000061],
            [67.728433980000034, 28.186813707000056],
            [67.722137000000032, 28.184938000000045],
            [67.702217603000065, 28.177576531000057],
            [67.692629522000061, 28.174033132000034],
            [67.68549100000007, 28.171395000000075],
            [67.657762705000039, 28.15845513000005],
            [67.657746778000046, 28.158447697000042],
            [67.657725973000083, 28.158493356000065],
            [67.657643553000071, 28.158455165000078],
            [67.657636370000034, 28.158471160000033],
            [67.657620121000036, 28.158463470000072],
            [67.657615730000032, 28.158461392000049],
            [67.657602498000074, 28.15845512900006],
            [67.649719238000046, 28.154724121000072],
            [67.636108398000033, 28.154724121000072],
            [67.626525879000042, 28.149902344000054],
            [67.626539000000037, 28.143188683000062],
            [67.626539000000037, 28.137139000000047],
            [67.628676650000045, 28.132329036000044],
            [67.632912000000033, 28.122799000000043],
            [67.632912744000066, 28.122794353000074],
            [67.633368823000069, 28.119943354000043],
            [67.633994002000065, 28.116035299000032],
            [67.636098000000061, 28.102883000000077],
            [67.63479787600005, 28.087928715000032],
            [67.634505000000047, 28.084560000000067],
            [67.634383511000067, 28.082131282000034],
            [67.633895285000051, 28.07237105400003],
            [67.63370800000007, 28.068627000000049],
            [67.633230688000083, 28.060028196000076],
            [67.63293862300003, 28.054766612000037],
            [67.632912000000033, 28.054287000000045],
            [67.633970358000056, 28.052640715000052],
            [67.640082000000064, 28.043134000000066],
            [67.642670813000052, 28.040286175000062],
            [67.648048000000074, 28.034371000000078],
            [67.656631106000077, 28.026861203000067],
            [67.660298357000045, 28.023652538000078],
            [67.660795000000064, 28.023218000000043],
            [67.663335839000069, 28.017500816000052],
            [67.66440536600004, 28.015094254000076],
            [67.667168000000061, 28.008878000000038],
            [67.66736179600008, 28.008019681000064],
            [67.670783372000074, 27.992865583000025],
            [67.672475442000064, 27.985371438000072],
            [67.67274400000008, 27.984182000000033],
            [67.67274400000008, 27.976713333000077],
            [67.67274400000008, 27.966890552000052],
            [67.67274400000008, 27.963469000000032],
            [67.672270651000076, 27.947371407000048],
            [67.672216288000072, 27.945522652000079],
            [67.671978000000081, 27.937419000000034],
            [67.671978139000032, 27.937419008000063],
            [67.671978093000064, 27.937417459000073],
            [67.668773972000054, 27.937222836000046],
            [67.659903000000043, 27.936684000000071],
            [67.623886054000081, 27.927346731000057],
            [67.613047392000055, 27.924536846000024],
            [67.595879000000082, 27.920086000000026],
            [67.58825239500004, 27.918005924000056],
            [67.550717379000048, 27.907768644000043],
            [67.543712000000028, 27.905858000000023],
            [67.532932000000073, 27.912374000000057],
            [67.501367000000073, 27.902905000000032],
            [67.490077402000054, 27.901455617000067],
            [67.490071192000073, 27.901484025000059],
            [67.443115234000061, 27.895507812000062],
            [67.420104980000076, 27.895080566000047],
            [67.411499023000033, 27.906494141000053],
            [67.399569000000042, 27.912769000000026],
            [67.397556026000075, 27.912321881000025],
            [67.393367000000069, 27.930472000000066],
            [67.393360460000054, 27.930506880000053],
            [67.388713481000082, 27.955289439000069],
            [67.388671875000057, 27.955505371000072],
            [67.38930386100003, 27.97088489600003],
            [67.389303863000066, 27.970884932000047],
            [67.389466000000084, 27.974939000000063],
            [67.388983206000034, 27.996667805000072],
            [67.388904379000053, 28.000215537000031],
            [67.388671875000057, 28.01007080100004],
            [67.387878418000071, 28.034301758000026],
            [67.388300195000056, 28.036189710000031],
            [67.389504207000073, 28.041579097000067],
            [67.391723633000083, 28.051513672000056],
            [67.39613853600008, 28.068777073000035],
            [67.396484375000057, 28.070129395000038],
            [67.396700751000083, 28.071224390000054],
            [67.400388000000078, 28.090396000000055],
            [67.400719387000038, 28.098681957000053],
            [67.400719423000055, 28.098682857000028],
            [67.401168000000041, 28.109899000000041],
            [67.399608000000057, 28.12628200000006],
            [67.398407598000063, 28.135085973000059],
            [67.398407562000045, 28.135086237000053],
            [67.397268000000054, 28.143444000000045],
            [67.394147000000032, 28.165288000000032],
            [67.394794948000083, 28.189913349000051],
            [67.394897461000028, 28.194885254000042],
            [67.394400703000031, 28.200722168000027],
            [67.392587000000049, 28.220676000000026],
            [67.392483354000035, 28.222956482000029],
            [67.392483352000056, 28.222956518000046],
            [67.391807000000028, 28.237838000000067],
            [67.382445000000075, 28.247200000000078],
            [67.384006000000056, 28.263582000000042],
            [67.377755092000029, 28.272105432000046],
            [67.377755066000077, 28.272105468000063],
            [67.375424000000066, 28.275284000000056],
            [67.371262018000039, 28.282915191000029],
            [67.370727539000029, 28.283874512000068],
            [67.369869409000046, 28.285089443000061],
            [67.359130859000061, 28.300292969000054],
            [67.350524902000075, 28.31671142600004],
            [67.351318359000061, 28.329895020000038],
            [67.348964616000046, 28.341841377000037],
            [67.348085910000066, 28.346301225000047],
            [67.337979000000075, 28.354076000000077],
            [67.323937000000058, 28.371238000000062],
            [67.32393735900007, 28.371248047000051],
            [67.323913574000073, 28.371276855000076],
            [67.324707031000059, 28.393127441000047],
            [67.323913579000077, 28.410278232000053],
            [67.313795000000027, 28.42506700000007],
            [67.305986019000045, 28.431074024000054],
            [67.305958016000034, 28.431095566000067],
            [67.305951340000036, 28.43108127000005],
            [67.30449343500004, 28.432222239000055],
            [67.303651340000044, 28.432881270000053],
            [67.300749737000046, 28.432383853000033],
            [67.289651340000034, 28.43048127000003],
            [67.282252203000041, 28.431999043000076],
            [67.276554349000037, 28.433138832000054],
            [67.276554169000065, 28.433138868000071],
            [67.274009000000035, 28.433648000000062],
            [67.262035935000029, 28.439282436000042],
            [67.26075134000007, 28.439881270000058],
            [67.26075134000007, 28.439886957000056],
            [67.26075134000007, 28.446081270000036],
            [67.260747000000038, 28.446084965000068],
            [67.252275765000036, 28.453298096000026],
            [67.250606000000062, 28.454711000000032],
            [67.245145000000036, 28.465633000000025],
            [67.242654206000054, 28.468123795000054],
            [67.231103000000076, 28.479675000000043],
            [67.217061000000058, 28.489036000000056],
            [67.210755884000037, 28.493765089000078],
            [67.20455134000008, 28.498381270000039],
            [67.19055134000007, 28.497581270000069],
            [67.179651340000078, 28.495281270000078],
            [67.179640757000072, 28.495282518000067],
            [67.179615000000069, 28.495277000000044],
            [67.175405974000057, 28.495782005000024],
            [67.160151340000084, 28.497581270000069],
            [67.160151340000084, 28.497612280000055],
            [67.160112000000083, 28.497617000000048],
            [67.160112000000083, 28.510099000000025],
            [67.166722146000041, 28.518912999000065],
            [67.16945134000008, 28.522581270000046],
            [67.172486052000068, 28.524284081000076],
            [67.187451340000052, 28.532681270000069],
            [67.195298677000039, 28.539854602000048],
            [67.203751340000053, 28.547581270000023],
            [67.205276583000057, 28.553456281000024],
            [67.206451340000058, 28.557981270000028],
            [67.206951340000046, 28.559981270000037],
            [67.208935116000077, 28.569770774000062],
            [67.211551340000028, 28.582681270000023],
            [67.215451340000072, 28.606881270000031],
            [67.21935134000006, 28.634181270000056],
            [67.22505134000005, 28.665281270000037],
            [67.225452638000036, 28.667421528000034],
            [67.22564200000005, 28.668463000000031],
            [67.231103000000076, 28.690306000000078],
            [67.232292981000057, 28.71470400100003],
            [67.236316182000053, 28.713960906000068],
            [67.245951340000033, 28.712181270000031],
            [67.247675038000068, 28.711223106000034],
            [67.250352553000027, 28.70973473600003],
            [67.253503095000042, 28.708043857000064],
            [67.254551340000035, 28.707481270000073],
            [67.265365022000083, 28.704874895000046],
            [67.274051340000028, 28.70278127000006],
            [67.285418524000079, 28.702409443000079],
            [67.287337416000071, 28.702346675000058],
            [67.297508565000044, 28.702013972000032],
            [67.322376000000077, 28.703568000000075],
            [67.331623104000073, 28.709349023000073],
            [67.334851340000057, 28.711381270000061],
            [67.343440000000044, 28.720731000000058],
            [67.354751340000064, 28.725781270000027],
            [67.354778101000079, 28.725793163000048],
            [67.354803120000042, 28.72580428200007],
            [67.355033601000059, 28.725906719000079],
            [67.355079388000036, 28.725927069000079],
            [67.357451340000068, 28.726981270000067],
            [67.360945492000042, 28.728074095000068],
            [67.363381936000053, 28.728836113000057],
            [67.369485544000042, 28.730745066000054],
            [67.372084190000066, 28.73161846000005],
            [67.374651340000071, 28.732481270000051],
            [67.380798346000063, 28.736535678000052],
            [67.384051340000042, 28.738681270000029],
            [67.387451340000041, 28.739381270000024],
            [67.399881819000029, 28.741981109000051],
            [67.402740515000062, 28.742579006000028],
            [67.402751340000066, 28.742581270000073],
            [67.413751340000033, 28.746881270000074],
            [67.414356583000028, 28.747225632000038],
            [67.419492842000068, 28.750147986000059],
            [67.425352000000032, 28.753496000000041],
            [67.432473295000079, 28.756548046000034],
            [67.441734000000054, 28.76051700000005],
            [67.448835085000042, 28.76017891500004],
            [67.458151340000029, 28.759781270000076],
            [67.469051340000078, 28.751981270000044],
            [67.473020252000083, 28.747282442000028],
            [67.477123113000061, 28.742425032000028],
            [67.477751340000054, 28.741681270000072],
            [67.482251340000062, 28.736381270000038],
            [67.484907837000037, 28.732272967000029],
            [67.490851340000063, 28.723081270000023],
            [67.495551340000077, 28.715281270000048],
            [67.500251340000034, 28.712181270000031],
            [67.505202730000065, 28.71161539700006],
            [67.506745752000029, 28.71143905200006],
            [67.507251340000039, 28.711381270000061],
            [67.516651340000067, 28.712181270000031],
            [67.522051340000075, 28.71678127000007],
            [67.523351340000033, 28.720781270000032],
            [67.524433931000033, 28.723832210000069],
            [67.524727645000041, 28.725741723000056],
            [67.525951340000063, 28.733981270000072],
            [67.525667697000074, 28.741274959000066],
            [67.52520700000008, 28.75187274600006],
            [67.523647000000039, 28.783856746000026],
            [67.523647000000039, 28.802579151000032],
            [67.523651340000072, 28.802592791000052],
            [67.523651340000072, 28.802681270000051],
            [67.529107000000067, 28.819806000000028],
            [67.529345551000063, 28.820221464000042],
            [67.535349126000028, 28.830727000000024],
            [67.543150126000057, 28.83618800000005],
            [67.547730996000041, 28.83954751300007],
            [67.548320148000073, 28.839980565000076],
            [67.554851340000027, 28.844781270000055],
            [67.568151340000043, 28.853381270000057],
            [67.57825500000007, 28.846330000000023],
            [67.578580184000032, 28.844315334000044],
            [67.580151340000043, 28.834581270000058],
            [67.58015137600006, 28.834581061000051],
            [67.581351340000083, 28.827581270000053],
            [67.581369015000064, 28.827579662000062],
            [67.582948491000082, 28.827436075000037],
            [67.583551340000042, 28.827381270000046],
            [67.588054385000078, 28.827033483000037],
            [67.588810693000028, 28.82697507000006],
            [67.588831316000039, 28.826973477000024],
            [67.595465260000083, 28.826461112000061],
            [67.59947470000003, 28.826214922000077],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "19",
      properties: { name: "Kachhi", count: 812 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.932182000000068, 29.092936000000066],
            [67.923347000000035, 29.100667000000044],
            [67.904573000000028, 29.105084000000033],
            [67.89100473600007, 29.110360737000065],
            [67.884694000000081, 29.112815000000069],
            [67.869233000000065, 29.115024000000062],
            [67.859293000000036, 29.115024000000062],
            [67.853187715000047, 29.116855641000029],
            [67.848250000000064, 29.118337000000054],
            [67.837206000000037, 29.120546000000047],
            [67.820640000000083, 29.124963000000037],
            [67.817842883000083, 29.126361559000031],
            [67.815818393000029, 29.127373804000058],
            [67.809596000000056, 29.130485000000078],
            [67.798552000000029, 29.13379800000007],
            [67.779778000000078, 29.139320000000055],
            [67.778113962000077, 29.140776104000054],
            [67.777970587000084, 29.140901563000057],
            [67.774356714000078, 29.14406385500007],
            [67.77413812900005, 29.144255127000065],
            [67.772447769000053, 29.145734263000065],
            [67.770943000000045, 29.147051000000033],
            [67.760460796000075, 29.147051000000033],
            [67.754377000000034, 29.147051000000033],
            [67.743333000000064, 29.147051000000033],
            [67.732825000000048, 29.147051000000033],
            [67.732290000000035, 29.145947000000035],
            [67.734498000000031, 29.13379800000007],
            [67.734498000000031, 29.124764865000031],
            [67.734498000000031, 29.124247832000037],
            [67.734498000000031, 29.122905270000047],
            [67.734498000000031, 29.122008895000079],
            [67.736347299000045, 29.114022527000031],
            [67.738102511000079, 29.109927563000042],
            [67.741125000000068, 29.102876000000037],
            [67.741559965000079, 29.097654445000046],
            [67.742229000000066, 29.089623000000074],
            [67.737812000000076, 29.077475000000049],
            [67.723455000000058, 29.063118000000031],
            [67.723401126000056, 29.063049952000028],
            [67.719726420000029, 29.058408412000063],
            [67.719726158000071, 29.058408081000039],
            [67.70247100000006, 29.036613000000045],
            [67.690323000000035, 29.025569000000075],
            [67.685590154000067, 29.021782724000047],
            [67.674268324000082, 29.012725260000025],
            [67.673758000000078, 29.012317000000053],
            [67.672353787000077, 29.006349570000054],
            [67.670322193000061, 28.99771598500007],
            [67.670214804000068, 28.997259620000079],
            [67.669340000000034, 28.993542000000048],
            [67.668754818000082, 28.990762716000063],
            [67.668587668000043, 28.989968851000071],
            [67.668294399000047, 28.988575987000047],
            [67.666129457000068, 28.978293736000069],
            [67.666073096000048, 28.978026055000043],
            [67.664922000000047, 28.972559000000047],
            [67.66389618200003, 28.969275863000064],
            [67.662726940000084, 28.965533695000033],
            [67.661766798000031, 28.962460753000073],
            [67.659401000000059, 28.954889000000037],
            [67.648767535000047, 28.942322973000046],
            [67.647252000000037, 28.940532000000076],
            [67.647239342000034, 28.940304068000046],
            [67.646454809000033, 28.926177508000023],
            [67.646148000000039, 28.920653000000073],
            [67.646158424000078, 28.920619411000075],
            [67.650007156000072, 28.908217424000043],
            [67.650538166000047, 28.906506322000041],
            [67.650676048000037, 28.906062015000032],
            [67.653089410000064, 28.898285303000023],
            [67.654863444000057, 28.892568735000054],
            [67.65608700000007, 28.888626000000045],
            [67.657452981000063, 28.886284432000025],
            [67.658101402000057, 28.885172906000037],
            [67.658925150000073, 28.883760835000032],
            [67.659160572000076, 28.883357275000037],
            [67.659513725000068, 28.882751898000038],
            [67.663280641000028, 28.876294642000062],
            [67.667039273000057, 28.869851585000049],
            [67.668235390000063, 28.867801198000052],
            [67.671549000000084, 28.862121000000059],
            [67.67256838600008, 28.859790786000076],
            [67.672663174000036, 28.859574111000029],
            [67.674400497000079, 28.85560276700005],
            [67.679279000000065, 28.844451000000049],
            [67.679331528000034, 28.843401267000047],
            [67.679331696000077, 28.843397904000028],
            [67.679467662000036, 28.840680735000035],
            [67.678851340000051, 28.840781270000036],
            [67.67839411600005, 28.840824051000027],
            [67.678110000000061, 28.840869000000055],
            [67.666990441000053, 28.841927812000051],
            [67.661727000000042, 28.842429000000038],
            [67.656957182000042, 28.841665946000035],
            [67.656957146000082, 28.841665940000041],
            [67.656032095000057, 28.841517955000029],
            [67.647479368000063, 28.840149730000064],
            [67.642224000000056, 28.839309000000071],
            [67.641009331000078, 28.839077589000055],
            [67.639643378000073, 28.83881735600005],
            [67.63942641400007, 28.838776021000058],
            [67.638848148000079, 28.838665853000066],
            [67.631083818000036, 28.837186639000038],
            [67.625851340000054, 28.836181270000054],
            [67.60833352700007, 28.828844083000035],
            [67.607968026000037, 28.828692963000037],
            [67.601651340000046, 28.826081270000032],
            [67.59947470000003, 28.826214922000077],
            [67.595465260000083, 28.826461112000061],
            [67.588831316000039, 28.826973477000024],
            [67.588810693000028, 28.82697507000006],
            [67.588054385000078, 28.827033483000037],
            [67.583551340000042, 28.827381270000046],
            [67.582948491000082, 28.827436075000037],
            [67.581369015000064, 28.827579662000062],
            [67.581351340000083, 28.827581270000053],
            [67.58015137600006, 28.834581061000051],
            [67.580151340000043, 28.834581270000058],
            [67.578580184000032, 28.844315334000044],
            [67.57825500000007, 28.846330000000023],
            [67.568151340000043, 28.853381270000057],
            [67.554851340000027, 28.844781270000055],
            [67.548320148000073, 28.839980565000076],
            [67.547730996000041, 28.83954751300007],
            [67.543150126000057, 28.83618800000005],
            [67.535349126000028, 28.830727000000024],
            [67.529345551000063, 28.820221464000042],
            [67.529107000000067, 28.819806000000028],
            [67.523651340000072, 28.802681270000051],
            [67.523651340000072, 28.802592791000052],
            [67.523647000000039, 28.802579151000032],
            [67.523647000000039, 28.783856746000026],
            [67.52520700000008, 28.75187274600006],
            [67.525667697000074, 28.741274959000066],
            [67.525951340000063, 28.733981270000072],
            [67.524727645000041, 28.725741723000056],
            [67.524433931000033, 28.723832210000069],
            [67.523351340000033, 28.720781270000032],
            [67.522051340000075, 28.71678127000007],
            [67.516651340000067, 28.712181270000031],
            [67.507251340000039, 28.711381270000061],
            [67.506745752000029, 28.71143905200006],
            [67.505202730000065, 28.71161539700006],
            [67.500251340000034, 28.712181270000031],
            [67.495551340000077, 28.715281270000048],
            [67.490851340000063, 28.723081270000023],
            [67.484907837000037, 28.732272967000029],
            [67.482251340000062, 28.736381270000038],
            [67.477751340000054, 28.741681270000072],
            [67.477123113000061, 28.742425032000028],
            [67.473020252000083, 28.747282442000028],
            [67.469051340000078, 28.751981270000044],
            [67.458151340000029, 28.759781270000076],
            [67.448835085000042, 28.76017891500004],
            [67.441734000000054, 28.76051700000005],
            [67.432473295000079, 28.756548046000034],
            [67.425352000000032, 28.753496000000041],
            [67.419492842000068, 28.750147986000059],
            [67.414356583000028, 28.747225632000038],
            [67.413751340000033, 28.746881270000074],
            [67.402751340000066, 28.742581270000073],
            [67.402740515000062, 28.742579006000028],
            [67.399881819000029, 28.741981109000051],
            [67.387451340000041, 28.739381270000024],
            [67.384051340000042, 28.738681270000029],
            [67.380798346000063, 28.736535678000052],
            [67.374651340000071, 28.732481270000051],
            [67.372084190000066, 28.73161846000005],
            [67.369485544000042, 28.730745066000054],
            [67.363381936000053, 28.728836113000057],
            [67.360945492000042, 28.728074095000068],
            [67.357451340000068, 28.726981270000067],
            [67.355079388000036, 28.725927069000079],
            [67.355033601000059, 28.725906719000079],
            [67.354803120000042, 28.72580428200007],
            [67.354778101000079, 28.725793163000048],
            [67.354751340000064, 28.725781270000027],
            [67.343440000000044, 28.720731000000058],
            [67.334851340000057, 28.711381270000061],
            [67.331623104000073, 28.709349023000073],
            [67.322376000000077, 28.703568000000075],
            [67.297508565000044, 28.702013972000032],
            [67.287337416000071, 28.702346675000058],
            [67.285418524000079, 28.702409443000079],
            [67.274051340000028, 28.70278127000006],
            [67.265365022000083, 28.704874895000046],
            [67.254551340000035, 28.707481270000073],
            [67.253503095000042, 28.708043857000064],
            [67.250352553000027, 28.70973473600003],
            [67.247675038000068, 28.711223106000034],
            [67.245951340000033, 28.712181270000031],
            [67.236316182000053, 28.713960906000068],
            [67.232292981000057, 28.71470400100003],
            [67.232557371000041, 28.720125103000044],
            [67.232663000000059, 28.722291000000041],
            [67.232234472000073, 28.739434894000055],
            [67.232221250000066, 28.739963867000029],
            [67.231883000000039, 28.753496000000041],
            [67.230629118000081, 28.757414358000062],
            [67.22564200000005, 28.772999000000027],
            [67.222521000000029, 28.790161000000069],
            [67.229726906000053, 28.806536763000054],
            [67.231103000000076, 28.809664000000055],
            [67.242024000000072, 28.831508000000042],
            [67.252166000000045, 28.843209000000058],
            [67.264648000000079, 28.854131000000052],
            [67.265995589000056, 28.857427994000034],
            [67.265995795000038, 28.857428498000047],
            [67.26599600000003, 28.857429000000025],
            [67.276407000000063, 28.862981000000048],
            [67.28828100000004, 28.85110700000007],
            [67.301699063000058, 28.86184174400006],
            [67.306548000000078, 28.865721000000065],
            [67.317508000000032, 28.882161000000053],
            [67.326642000000049, 28.897688000000073],
            [67.327686333000031, 28.902701756000056],
            [67.331208000000061, 28.919609000000037],
            [67.339429000000052, 28.935136000000057],
            [67.341472205000059, 28.944671204000031],
            [67.34490900000003, 28.960710000000063],
            [67.350389000000064, 28.978064000000074],
            [67.362263000000041, 29.000898000000063],
            [67.36956900000007, 29.023732000000052],
            [67.372075859000063, 29.030249449000053],
            [67.37870300000003, 29.047479000000067],
            [67.383270000000039, 29.06300600000003],
            [67.392403000000058, 29.081273000000067],
            [67.404277000000036, 29.095887000000062],
            [67.417064000000039, 29.109587000000033],
            [67.429075000000068, 29.113662000000033],
            [67.439554000000044, 29.126388000000077],
            [67.448537000000044, 29.148096000000066],
            [67.459017000000074, 29.172798000000057],
            [67.460709378000047, 29.181542898000032],
            [67.463508000000047, 29.196004000000073],
            [67.464056704000029, 29.204509458000075],
            [67.465005000000076, 29.219209000000035],
            [67.461653451000075, 29.242004368000039],
            [67.461263000000031, 29.244660000000067],
            [67.459017000000074, 29.265619000000072],
            [67.453028000000074, 29.287327000000062],
            [67.452946281000038, 29.28818508300003],
            [67.452280782000059, 29.295173047000048],
            [67.451583000000028, 29.302500000000066],
            [67.451531000000045, 29.303047000000049],
            [67.450783000000058, 29.318767000000037],
            [67.445807875000071, 29.335556488000066],
            [67.445799203000036, 29.335585751000053],
            [67.444794000000059, 29.338978000000054],
            [67.446291000000031, 29.379400000000032],
            [67.435812000000055, 29.392874000000063],
            [67.413355000000081, 29.397365000000036],
            [67.402875000000051, 29.407845000000066],
            [67.385658000000035, 29.41533000000004],
            [67.372184000000061, 29.425062000000025],
            [67.369381000000033, 29.428885000000037],
            [67.363950000000045, 29.436290000000042],
            [67.352722000000028, 29.449764000000073],
            [67.34748200000007, 29.469226000000049],
            [67.346489143000042, 29.483127327000034],
            [67.345985000000042, 29.490186000000051],
            [67.345985000000042, 29.504408000000069],
            [67.335505000000069, 29.520877000000041],
            [67.319037000000037, 29.525368000000071],
            [67.300323000000049, 29.518631000000028],
            [67.283855000000074, 29.507403000000068],
            [67.271878000000072, 29.505157000000054],
            [67.257655000000057, 29.499917000000039],
            [67.231456000000037, 29.504408000000069],
            [67.217982000000063, 29.505157000000054],
            [67.19966448100007, 29.503800247000072],
            [67.197771000000046, 29.503660000000025],
            [67.185794000000044, 29.503660000000025],
            [67.166332000000068, 29.504408000000069],
            [67.143875000000037, 29.508151000000055],
            [67.128155000000049, 29.510397000000069],
            [67.127669755000056, 29.510583989000054],
            [67.12721300000004, 29.520624000000055],
            [67.126504000000068, 29.531270000000063],
            [67.141409000000067, 29.544046000000037],
            [67.175478000000055, 29.567469000000074],
            [67.192512000000079, 29.578115000000025],
            [67.195352000000071, 29.602248000000031],
            [67.197481000000039, 29.617863000000057],
            [67.206708000000049, 29.636317000000076],
            [67.224452000000042, 29.656190000000038],
            [67.249294000000077, 29.66754700000007],
            [67.270587000000035, 29.679613000000074],
            [67.279105000000072, 29.691679000000079],
            [67.275556000000051, 29.703745000000026],
            [67.259231000000057, 29.703035000000057],
            [67.247165000000052, 29.716521000000057],
            [67.195352000000071, 29.737104000000045],
            [67.17760700000008, 29.752010000000041],
            [67.167670000000044, 29.765495000000044],
            [67.154185000000041, 29.78536900000006],
            [67.147087000000056, 29.818018000000052],
            [67.147087000000056, 29.85208700000004],
            [67.135731000000078, 29.878349000000071],
            [67.119406000000083, 29.918806000000075],
            [67.126504000000068, 29.940809000000058],
            [67.147087000000056, 29.956424000000027],
            [67.166961000000072, 29.972749000000078],
            [67.19180300000005, 29.982686000000058],
            [67.210967000000039, 29.968490000000031],
            [67.218774000000053, 29.960683000000074],
            [67.223742000000072, 29.960683000000074],
            [67.230130000000031, 29.974168000000077],
            [67.249294000000077, 29.970619000000056],
            [67.262070000000051, 29.957134000000053],
            [67.277685000000076, 29.940809000000058],
            [67.279105000000072, 29.926614000000029],
            [67.284907061000069, 29.923132545000044],
            [67.285237260000031, 29.922934414000053],
            [67.289751000000081, 29.920226000000071],
            [67.303237000000081, 29.923774000000037],
            [67.306786000000045, 29.939389000000062],
            [67.318852000000049, 29.942938000000026],
            [67.328079000000059, 29.930162000000053],
            [67.334467000000075, 29.913128000000029],
            [67.342984000000058, 29.90745000000004],
            [67.359309000000053, 29.910289000000034],
            [67.374924000000078, 29.917387000000076],
            [67.373394000000076, 29.92159300000003],
            [67.373394404000067, 29.921592710000027],
            [67.373394805000032, 29.921592421000071],
            [67.374402000000032, 29.920869000000039],
            [67.397555000000068, 29.913794000000053],
            [67.415563000000077, 29.910578000000044],
            [67.441932000000065, 29.89450000000005],
            [67.460583000000042, 29.873276000000033],
            [67.461870000000033, 29.861056000000076],
            [67.468301000000054, 29.854625000000055],
            [67.477948000000083, 29.836617000000047],
            [67.486952000000031, 29.821181000000024],
            [67.494670000000042, 29.801244000000054],
            [67.498529000000076, 29.782592000000079],
            [67.499815000000069, 29.75815300000005],
            [67.501745000000028, 29.734357000000045],
            [67.511392000000058, 29.71763500000003],
            [67.518466000000046, 29.704772000000048],
            [67.528113000000076, 29.695125000000075],
            [67.53711700100007, 29.696411000000069],
            [67.546122000000082, 29.686764000000039],
            [67.550624000000028, 29.675830000000076],
            [67.559628000000032, 29.671328000000074],
            [67.573777000000064, 29.666826000000071],
            [67.593071000000066, 29.666183000000046],
            [67.611722000000043, 29.662967000000037],
            [67.625726203000056, 29.663506332000054],
            [67.628444000000059, 29.66361100000006],
            [67.632568000000049, 29.662694000000045],
            [67.632682000000045, 29.663102000000038],
            [67.641664000000048, 29.660108000000037],
            [67.652893000000063, 29.651874000000078],
            [67.664870000000064, 29.646634000000063],
            [67.67310400000008, 29.646634000000063],
            [67.680589000000055, 29.636903000000075],
            [67.685081000000082, 29.629417000000046],
            [67.68433200000004, 29.62118300000003],
            [67.688075000000083, 29.614446000000044],
            [67.691818000000069, 29.603966000000071],
            [67.694839373000036, 29.601951901000064],
            [67.698555000000056, 29.599475000000041],
            [67.710532000000057, 29.60097200000007],
            [67.718017000000032, 29.606212000000028],
            [67.726251000000047, 29.599475000000041],
            [67.738228000000049, 29.60097200000007],
            [67.750954000000036, 29.603218000000027],
            [67.765176000000054, 29.602469000000042],
            [67.775656000000083, 29.599475000000041],
            [67.783890000000042, 29.592738000000054],
            [67.787633000000028, 29.583755000000053],
            [67.787666712000032, 29.583248963000074],
            [67.787668533000044, 29.58322163400004],
            [67.788381000000072, 29.572527000000036],
            [67.790627000000029, 29.562047000000064],
            [67.79961000000003, 29.552316000000076],
            [67.800579016000029, 29.550377805000039],
            [67.805598000000032, 29.540339000000074],
            [67.80410100000006, 29.527614000000028],
            [67.807095000000061, 29.512643000000025],
            [67.807097250000083, 29.512613767000062],
            [67.807844000000046, 29.50291100000004],
            [67.81308400000006, 29.493929000000037],
            [67.815329000000077, 29.482700000000079],
            [67.813862786000072, 29.478510937000067],
            [67.810089000000062, 29.467729000000077],
            [67.813301893000073, 29.460768923000046],
            [67.814480151000055, 29.458216468000046],
            [67.814581000000032, 29.457998000000032],
            [67.825748787000066, 29.454729282000073],
            [67.827068919000055, 29.454342890000078],
            [67.835033959000043, 29.452011589000051],
            [67.84527200000008, 29.449015000000031],
            [67.849709421000057, 29.447804867000059],
            [67.861740000000054, 29.444524000000058],
            [67.874477994000074, 29.435425317000067],
            [67.874478103000058, 29.43542523800005],
            [67.877459000000044, 29.433296000000041],
            [67.883448000000044, 29.416827000000069],
            [67.882699000000059, 29.401108000000079],
            [67.886442000000045, 29.392125000000078],
            [67.890185000000031, 29.374160000000074],
            [67.88719100000003, 29.363680000000045],
            [67.887113518000035, 29.363344301000041],
            [67.884945000000073, 29.353949000000057],
            [67.871471000000042, 29.342720000000043],
            [67.873410160000049, 29.333508667000046],
            [67.87349604800005, 29.333100684000044],
            [67.874465000000043, 29.328498000000025],
            [67.890185000000031, 29.329246000000069],
            [67.896929115000034, 29.329246000000069],
            [67.905905000000075, 29.329246000000069],
            [67.91636154400004, 29.332383452000045],
            [67.920876000000078, 29.333738000000039],
            [67.935098000000039, 29.337481000000025],
            [67.945578000000069, 29.344966000000056],
            [67.958303000000058, 29.353200000000072],
            [67.962609684000029, 29.356071229000065],
            [67.971777000000031, 29.362183000000073],
            [67.979767000000038, 29.369602000000043],
            [67.979767002000074, 29.369601961000058],
            [67.980775000000051, 29.352466000000049],
            [67.975253000000066, 29.340317000000027],
            [67.972210090000033, 29.335969930000033],
            [67.967523000000028, 29.329274000000055],
            [67.967523000000028, 29.311604000000045],
            [67.967709032000073, 29.307695978000027],
            [67.96856553300006, 29.289703248000023],
            [67.968627000000083, 29.288412000000051],
            [67.969683119000081, 29.273620596000058],
            [67.969687007000061, 29.27356614200005],
            [67.969731000000081, 29.272950000000037],
            [67.97032540400005, 29.267898235000075],
            [67.971940000000075, 29.254176000000029],
            [67.971940000000075, 29.245358125000052],
            [67.971940000000075, 29.242028000000062],
            [67.97304500000007, 29.227671000000043],
            [67.974742497000079, 29.220879780000075],
            [67.978566000000058, 29.205583000000047],
            [67.979165264000073, 29.191208795000023],
            [67.979482087000065, 29.183609346000026],
            [67.979671000000053, 29.179078000000061],
            [67.979671000000053, 29.176112661000047],
            [67.979671000000053, 29.176075542000035],
            [67.979671000000053, 29.164905320000059],
            [67.979671000000053, 29.154782000000068],
            [67.978566000000058, 29.137112000000059],
            [67.975866546000077, 29.118215004000035],
            [67.975253000000066, 29.113920000000064],
            [67.975253000000066, 29.107802803000027],
            [67.975253000000066, 29.105084000000033],
            [67.972036326000079, 29.098007549000044],
            [67.969731000000081, 29.092936000000066],
            [67.95979200000005, 29.092936000000066],
            [67.945435000000032, 29.092936000000066],
            [67.932182000000068, 29.092936000000066],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "20",
      properties: { name: "Kalat", count: 581 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.640039380000076, 29.554035168000041],
            [66.640057484000067, 29.554011820000028],
            [66.640058381000074, 29.554014242000051],
            [66.640058557000032, 29.554014014000074],
            [66.640057745000036, 29.554011820000028],
            [66.638209286000063, 29.549021136000079],
            [66.635371487000043, 29.541792463000036],
            [66.628244000000052, 29.523463000000049],
            [66.620607851000045, 29.498205031000055],
            [66.619017000000042, 29.492943000000025],
            [66.619017000000042, 29.461991046000037],
            [66.619046285000081, 29.46029913700005],
            [66.630402285000059, 29.446814137000047],
            [66.634602701000063, 29.445833914000048],
            [66.651692832000037, 29.441845709000063],
            [66.651692967000031, 29.44184567700006],
            [66.651695286000063, 29.44184513600004],
            [66.651986731000079, 29.441918002000079],
            [66.668730286000027, 29.446104136000031],
            [66.688603286000046, 29.470946136000066],
            [66.70989628600006, 29.492239136000023],
            [66.711547214000063, 29.493100275000074],
            [66.711550312000043, 29.493110897000065],
            [66.711550359000057, 29.493111057000078],
            [66.726192000000083, 29.500750000000039],
            [66.746066000000042, 29.517075000000034],
            [66.766649000000029, 29.542627000000039],
            [66.80213800000007, 29.564630000000079],
            [66.820592000000033, 29.562500000000057],
            [66.858920000000069, 29.549725000000024],
            [66.867437000000052, 29.544046000000037],
            [66.871695000000045, 29.52772200000004],
            [66.867437000000052, 29.509268000000077],
            [66.858210000000042, 29.487974000000065],
            [66.836207000000059, 29.461713000000032],
            [66.819882000000064, 29.443259000000069],
            [66.817043000000069, 29.431193000000064],
            [66.818700843000045, 29.430423305000033],
            [66.836917000000028, 29.421966000000054],
            [66.884471000000076, 29.426934000000074],
            [66.91996000000006, 29.435451000000057],
            [66.950480000000027, 29.441839000000073],
            [66.980291000000079, 29.440420000000074],
            [67.021457000000055, 29.434741000000031],
            [67.051978000000076, 29.432612000000063],
            [67.072561000000064, 29.432612000000063],
            [67.076820000000055, 29.443969000000038],
            [67.078239000000053, 29.456744000000072],
            [67.091725000000054, 29.458164000000068],
            [67.100952000000063, 29.474489000000062],
            [67.116567000000032, 29.48584500000004],
            [67.127923000000067, 29.50500900000003],
            [67.127669755000056, 29.510583989000054],
            [67.128155000000049, 29.510397000000069],
            [67.143875000000037, 29.508151000000055],
            [67.166332000000068, 29.504408000000069],
            [67.185794000000044, 29.503660000000025],
            [67.197771000000046, 29.503660000000025],
            [67.19966448100007, 29.503800247000072],
            [67.217982000000063, 29.505157000000054],
            [67.231456000000037, 29.504408000000069],
            [67.257655000000057, 29.499917000000039],
            [67.271878000000072, 29.505157000000054],
            [67.283855000000074, 29.507403000000068],
            [67.300323000000049, 29.518631000000028],
            [67.319037000000037, 29.525368000000071],
            [67.335505000000069, 29.520877000000041],
            [67.345985000000042, 29.504408000000069],
            [67.345985000000042, 29.490186000000051],
            [67.346489143000042, 29.483127327000034],
            [67.34748200000007, 29.469226000000049],
            [67.352722000000028, 29.449764000000073],
            [67.363950000000045, 29.436290000000042],
            [67.369381000000033, 29.428885000000037],
            [67.372184000000061, 29.425062000000025],
            [67.385658000000035, 29.41533000000004],
            [67.402875000000051, 29.407845000000066],
            [67.413355000000081, 29.397365000000036],
            [67.435812000000055, 29.392874000000063],
            [67.446291000000031, 29.379400000000032],
            [67.444794000000059, 29.338978000000054],
            [67.445799203000036, 29.335585751000053],
            [67.445807875000071, 29.335556488000066],
            [67.450783000000058, 29.318767000000037],
            [67.451531000000045, 29.303047000000049],
            [67.451583000000028, 29.302500000000066],
            [67.452280782000059, 29.295173047000048],
            [67.452946281000038, 29.28818508300003],
            [67.453028000000074, 29.287327000000062],
            [67.459017000000074, 29.265619000000072],
            [67.461263000000031, 29.244660000000067],
            [67.461653451000075, 29.242004368000039],
            [67.465005000000076, 29.219209000000035],
            [67.464056704000029, 29.204509458000075],
            [67.463508000000047, 29.196004000000073],
            [67.460709378000047, 29.181542898000032],
            [67.459017000000074, 29.172798000000057],
            [67.448537000000044, 29.148096000000066],
            [67.439554000000044, 29.126388000000077],
            [67.429075000000068, 29.113662000000033],
            [67.417064000000039, 29.109587000000033],
            [67.404277000000036, 29.095887000000062],
            [67.392403000000058, 29.081273000000067],
            [67.383270000000039, 29.06300600000003],
            [67.37870300000003, 29.047479000000067],
            [67.372075859000063, 29.030249449000053],
            [67.36956900000007, 29.023732000000052],
            [67.362263000000041, 29.000898000000063],
            [67.350389000000064, 28.978064000000074],
            [67.34490900000003, 28.960710000000063],
            [67.341472205000059, 28.944671204000031],
            [67.339429000000052, 28.935136000000057],
            [67.331208000000061, 28.919609000000037],
            [67.327686333000031, 28.902701756000056],
            [67.326642000000049, 28.897688000000073],
            [67.317508000000032, 28.882161000000053],
            [67.306548000000078, 28.865721000000065],
            [67.301699063000058, 28.86184174400006],
            [67.28828100000004, 28.85110700000007],
            [67.276407000000063, 28.862981000000048],
            [67.26599600000003, 28.857429000000025],
            [67.265995795000038, 28.857428498000047],
            [67.262707000000034, 28.855674000000079],
            [67.24809300000004, 28.856587000000047],
            [67.224346000000082, 28.849280000000078],
            [67.206443835000073, 28.846393043000035],
            [67.196032000000059, 28.844714000000067],
            [67.16680400000007, 28.842887000000076],
            [67.146640661000049, 28.841926691000026],
            [67.146632562000036, 28.841926305000072],
            [67.128443000000061, 28.841060000000027],
            [67.11108900000005, 28.838320000000067],
            [67.08968462100006, 28.839966491000041],
            [67.075469000000055, 28.841060000000027],
            [67.048981000000083, 28.839233000000036],
            [67.021293099000047, 28.841541097000061],
            [67.016100000000051, 28.84197400000005],
            [66.990526000000045, 28.841060000000027],
            [66.969519000000048, 28.840147000000059],
            [66.939378000000033, 28.837407000000042],
            [66.900104000000056, 28.836493000000075],
            [66.870877000000064, 28.832840000000033],
            [66.850783000000035, 28.830100000000073],
            [66.835256000000072, 28.831013000000041],
            [66.815162000000043, 28.831927000000064],
            [66.787761000000046, 28.825533000000064],
            [66.771321000000057, 28.816400000000044],
            [66.756707000000063, 28.799959000000058],
            [66.743007000000034, 28.784432000000038],
            [66.72839300000004, 28.760685000000024],
            [66.717433000000028, 28.743331000000069],
            [66.71662697000005, 28.742152978000036],
            [66.705559000000051, 28.725977000000057],
            [66.697339000000056, 28.700403000000051],
            [66.679072000000076, 28.674829000000045],
            [66.669025000000033, 28.646515000000079],
            [66.645277000000078, 28.620941000000073],
            [66.63157700000005, 28.597194000000059],
            [66.614223000000038, 28.578013000000055],
            [66.596869000000083, 28.566140000000075],
            [66.585909000000072, 28.564313000000027],
            [66.578043702000059, 28.561035553000067],
            [66.574949000000061, 28.559746000000075],
            [66.553942000000063, 28.55883300000005],
            [66.530194000000051, 28.554266000000041],
            [66.514667000000031, 28.550613000000055],
            [66.510398959000042, 28.548102070000027],
            [66.499140000000068, 28.541479000000038],
            [66.479046000000039, 28.527779000000066],
            [66.470826000000045, 28.514992000000063],
            [66.454386000000056, 28.498551000000077],
            [66.451646000000039, 28.477544000000023],
            [66.439772000000062, 28.462930000000028],
            [66.432465000000036, 28.444663000000048],
            [66.423332000000073, 28.422743000000025],
            [66.42881200000005, 28.403562000000079],
            [66.439772000000062, 28.376161000000025],
            [66.451218485000084, 28.357307880000064],
            [66.455299000000082, 28.350587000000075],
            [66.481787000000054, 28.330494000000044],
            [66.500406124000051, 28.313736109000047],
            [66.509277344000054, 28.305908203000058],
            [66.516278817000057, 28.287217829000042],
            [66.51647949200003, 28.286682129000042],
            [66.518310547000056, 28.262878418000071],
            [66.518310547000056, 28.238281250000057],
            [66.51812890900004, 28.236660481000058],
            [66.516723633000083, 28.224121094000054],
            [66.514709473000039, 28.208129883000026],
            [66.504699707000043, 28.173522949000073],
            [66.504650212000058, 28.173500370000056],
            [66.484497070000032, 28.164306641000053],
            [66.469909668000071, 28.151489258000026],
            [66.445312500000057, 28.139709473000039],
            [66.425292969000054, 28.132080078000058],
            [66.411499023000033, 28.126892090000069],
            [66.389706842000066, 28.117493172000025],
            [66.379516602000081, 28.113098145000038],
            [66.368892006000067, 28.10719926400003],
            [66.340270996000072, 28.091308594000054],
            [66.329895020000038, 28.087890625000057],
            [66.310119629000042, 28.081298828000058],
            [66.286459703000048, 28.067863547000059],
            [66.280883789000029, 28.064697266000053],
            [66.265370009000037, 28.057254518000036],
            [66.261291504000042, 28.055297852000024],
            [66.256286621000072, 28.052917480000076],
            [66.232482910000044, 28.038330078000058],
            [66.210453495000081, 28.024689223000053],
            [66.196899414000029, 28.016296387000068],
            [66.179994073000046, 28.002533049000078],
            [66.166687012000068, 27.991699219000054],
            [66.161894790000076, 27.988142492000065],
            [66.158874512000068, 27.985900879000042],
            [66.152721610000071, 27.981438851000064],
            [66.14288330100004, 27.974304199000073],
            [66.134694394000064, 27.965532818000042],
            [66.128295898000033, 27.958679199000073],
            [66.121724565000079, 27.95579045900007],
            [66.110107422000056, 27.950683594000054],
            [66.079895020000038, 27.939697266000053],
            [66.078654609000068, 27.939315601000033],
            [66.075927734000061, 27.938476562000062],
            [66.062377337000044, 27.935924262000071],
            [66.058105469000054, 27.935119629000042],
            [66.03602141500005, 27.934225751000042],
            [66.032470703000058, 27.934082031000059],
            [66.029650966000077, 27.93396529000006],
            [66.013305664000029, 27.933288574000073],
            [65.988948981000078, 27.936296604000063],
            [65.977722168000071, 27.937683105000076],
            [65.956298828000058, 27.935729980000076],
            [65.955588668000075, 27.935377092000067],
            [65.955599000000063, 27.935420000000079],
            [65.973079000000041, 27.964552000000026],
            [65.976221000000066, 27.991258000000073],
            [65.977792000000079, 28.011681000000067],
            [65.969937000000073, 28.033674000000076],
            [65.954228000000057, 28.05252500000006],
            [65.930664000000036, 28.05252500000006],
            [65.913383000000067, 28.043100000000038],
            [65.891390000000058, 28.049383000000034],
            [65.883535000000052, 28.076089000000024],
            [65.881964000000039, 28.098083000000031],
            [65.886677000000077, 28.13264300000003],
            [65.896103000000039, 28.170346000000052],
            [65.894532000000083, 28.195481000000029],
            [65.877252000000055, 28.208049000000074],
            [65.856829000000062, 28.208049000000074],
            [65.834836000000053, 28.209620000000029],
            [65.826981000000046, 28.22847100000007],
            [65.837978000000078, 28.25517700000006],
            [65.867826000000036, 28.310160000000053],
            [65.883535000000052, 28.35728800000004],
            [65.883417966000081, 28.357925960000046],
            [65.883417951000069, 28.357926042000031],
            [65.900017000000048, 28.377181000000064],
            [65.924859000000083, 28.418348000000037],
            [65.948282000000063, 28.438931000000025],
            [65.970995000000073, 28.453836000000024],
            [65.98731900000007, 28.475130000000036],
            [66.007193000000029, 28.504230000000064],
            [66.017130000000066, 28.524104000000079],
            [66.041972000000044, 28.552495000000079],
            [66.059006000000068, 28.577337000000057],
            [66.059716000000037, 28.597210000000075],
            [66.070363000000043, 28.615664000000038],
            [66.078880000000083, 28.639797000000044],
            [66.088817000000063, 28.667478000000074],
            [66.091656000000057, 28.710064000000045],
            [66.088208970000039, 28.723850992000052],
            [66.08668700000004, 28.729938000000061],
            [66.068943000000047, 28.728518000000065],
            [66.059716000000037, 28.714323000000036],
            [66.03842300000008, 28.711484000000041],
            [66.015001000000041, 28.728518000000065],
            [66.012161000000049, 28.740584000000069],
            [66.017130000000066, 28.777492000000052],
            [66.025647000000049, 28.808722000000046],
            [66.034164000000032, 28.826467000000036],
            [66.048360000000059, 28.833564000000024],
            [66.070363000000043, 28.835694000000046],
            [66.079590000000053, 28.85343800000004],
            [66.089527000000032, 28.880409000000043],
            [66.098044000000073, 28.901703000000055],
            [66.107271000000082, 28.918027000000052],
            [66.11862700000006, 28.934352000000047],
            [66.122886000000051, 28.954226000000062],
            [66.124305000000049, 28.981907000000035],
            [66.136372000000051, 29.00745900000004],
            [66.140630000000044, 29.029462000000024],
            [66.141340000000071, 29.059272000000078],
            [66.140630000000044, 29.086953000000051],
            [66.116498000000036, 29.110376000000031],
            [66.112949000000071, 29.120312000000069],
            [66.108690000000081, 29.140896000000055],
            [66.102302000000066, 29.154381000000058],
            [66.104432000000031, 29.177804000000037],
            [66.112949000000071, 29.207614000000035],
            [66.125015000000076, 29.240264000000025],
            [66.144179000000065, 29.27859200000006],
            [66.154826000000071, 29.311951000000079],
            [66.156245000000069, 29.330405000000042],
            [66.155983960000071, 29.331413973000053],
            [66.155983923000065, 29.33141411500003],
            [66.174699000000032, 29.343891000000042],
            [66.178248000000053, 29.357376000000045],
            [66.187475000000063, 29.37370100000004],
            [66.194565053000076, 29.383006960000046],
            [66.226513000000068, 29.368733000000077],
            [66.24638600000003, 29.362345000000062],
            [66.264131000000077, 29.347439000000065],
            [66.294651000000044, 29.341761000000076],
            [66.313105000000064, 29.336083000000031],
            [66.339366000000041, 29.338212000000055],
            [66.375565000000051, 29.34531000000004],
            [66.386211001000049, 29.357375999000055],
            [66.391890000000046, 29.37725000000006],
            [66.396148000000039, 29.397124000000076],
            [66.396148000000039, 29.397861428000056],
            [66.396148000000039, 29.411319000000049],
            [66.40963400000004, 29.434741000000031],
            [66.417441000000053, 29.451066000000026],
            [66.433056000000079, 29.478747000000055],
            [66.453640000000064, 29.507138000000054],
            [66.484160000000031, 29.541917000000069],
            [66.51751900000005, 29.573857000000032],
            [66.537463428000081, 29.591141962000052],
            [66.537463720000062, 29.591142215000048],
            [66.549488286000042, 29.601544137000076],
            [66.582135823000044, 29.622126215000037],
            [66.582135935000053, 29.622126285000036],
            [66.582137285000044, 29.622127137000064],
            [66.608399286000065, 29.627096136000034],
            [66.613077751000048, 29.62532139700005],
            [66.626569177000079, 29.620203530000026],
            [66.628982286000053, 29.619288136000023],
            [66.645307285000058, 29.601544137000076],
            [66.646017286000074, 29.58734813600006],
            [66.645679734000055, 29.579014794000045],
            [66.645679464000068, 29.579014568000048],
            [66.645278000000076, 29.568179000000043],
            [66.640039436000052, 29.554035319000036],
            [66.640039380000076, 29.554035168000041],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "21",
      properties: { name: "Kech", count: 176 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [63.00634000000008, 26.649411000000043],
            [63.037642000000062, 26.641246000000024],
            [63.051705000000084, 26.635348000000079],
            [63.074841000000049, 26.641699000000074],
            [63.09072400000008, 26.62063500000005],
            [63.103599000000031, 26.605308000000036],
            [63.114022000000034, 26.596111000000064],
            [63.125058000000081, 26.588140000000067],
            [63.133029000000079, 26.577104000000077],
            [63.134255000000053, 26.56606800000003],
            [63.129963000000032, 26.557485000000042],
            [63.118314000000055, 26.548901000000058],
            [63.110957000000042, 26.54093000000006],
            [63.099920000000054, 26.530507000000057],
            [63.092563000000041, 26.521311000000026],
            [63.09255971500005, 26.521299175000024],
            [63.089497000000051, 26.510275000000036],
            [63.097468000000049, 26.507209000000046],
            [63.111570000000029, 26.506596000000059],
            [63.122606000000076, 26.506596000000059],
            [63.128737000000058, 26.499852000000033],
            [63.12689800000004, 26.493107000000066],
            [63.12076600000006, 26.485137000000066],
            [63.136094000000071, 26.48942900000003],
            [63.143452000000082, 26.49372100000005],
            [63.156940000000077, 26.494947000000025],
            [63.165524000000062, 26.488816000000043],
            [63.156940000000077, 26.472875000000045],
            [63.140999000000079, 26.463678000000073],
            [63.131964269000036, 26.452234531000045],
            [63.13180200000005, 26.452029000000039],
            [63.140999000000079, 26.442219000000023],
            [63.155714000000046, 26.438540000000046],
            [63.159266040000034, 26.438196326000025],
            [63.174721000000034, 26.436701000000028],
            [63.19250100000005, 26.436701000000028],
            [63.211508000000038, 26.444058000000041],
            [63.236645000000067, 26.450802000000067],
            [63.264849000000083, 26.459999000000039],
            [63.297155566000072, 26.471121442000026],
            [63.302249000000074, 26.472875000000045],
            [63.311039665000067, 26.47683087200005],
            [63.32677300000006, 26.483911000000035],
            [63.354977000000076, 26.494334000000038],
            [63.372144000000048, 26.500465000000077],
            [63.392377000000067, 26.501691000000051],
            [63.416288000000065, 26.502917000000025],
            [63.430390000000045, 26.499852000000033],
            [63.450010000000077, 26.490655000000061],
            [63.47146900000007, 26.483298000000048],
            [63.49354100000005, 26.475940000000037],
            [63.511934000000053, 26.469809000000055],
            [63.535846000000049, 26.457547000000034],
            [63.559757000000047, 26.445897000000059],
            [63.58428200000003, 26.436088000000041],
            [63.595931000000064, 26.429956000000061],
            [63.604515000000049, 26.419533000000058],
            [63.59777100000008, 26.415855000000079],
            [63.584895000000074, 26.415855000000079],
            [63.577538000000061, 26.413402000000076],
            [63.572633000000053, 26.410950000000071],
            [63.565275000000042, 26.412176000000045],
            [63.561597000000063, 26.415855000000079],
            [63.557918000000029, 26.412789000000032],
            [63.54810800000007, 26.412789000000032],
            [63.541364000000044, 26.410337000000027],
            [63.535846000000049, 26.404819000000032],
            [63.535233000000062, 26.390717000000052],
            [63.52910200000008, 26.388878000000034],
            [63.516839000000061, 26.395009000000073],
            [63.511934000000053, 26.385812000000044],
            [63.513774000000069, 26.375389000000041],
            [63.527262000000064, 26.368645000000072],
            [63.545043000000078, 26.373550000000023],
            [63.568954000000076, 26.376615000000072],
            [63.581829000000027, 26.371097000000077],
            [63.591026000000056, 26.364966000000038],
            [63.595931000000064, 26.355156000000079],
            [63.595931000000064, 26.344120000000032],
            [63.589800000000082, 26.333697000000029],
            [63.587348000000077, 26.314691000000039],
            [63.587348000000077, 26.302428000000077],
            [63.589800000000082, 26.286487000000079],
            [63.595318000000077, 26.26932000000005],
            [63.595346000000063, 26.269307000000026],
            [63.612485000000049, 26.26135000000005],
            [63.627200000000073, 26.268707000000063],
            [63.643141000000071, 26.269933000000037],
            [63.663987000000077, 26.276677000000063],
            [63.687899000000073, 26.280969000000027],
            [63.708744612000032, 26.272999149000043],
            [63.708745000000079, 26.272999000000027],
            [63.727216794000071, 26.277477260000069],
            [63.728977000000043, 26.277904000000035],
            [63.729968702000065, 26.278854322000029],
            [63.743692000000067, 26.292005000000074],
            [63.76515100000006, 26.303655000000049],
            [63.791515000000061, 26.313464000000067],
            [63.820332000000064, 26.316530000000057],
            [63.842032454000048, 26.316530000000057],
            [63.850374000000045, 26.316530000000057],
            [63.869381000000033, 26.311012000000062],
            [63.890840000000082, 26.301815000000033],
            [63.898810000000083, 26.294458000000077],
            [63.910521121000045, 26.285573725000063],
            [63.916591000000039, 26.280969000000027],
            [63.928853000000061, 26.268094000000076],
            [63.946633000000077, 26.250927000000047],
            [63.961961000000031, 26.236825000000067],
            [63.977289000000042, 26.226402000000064],
            [63.985260000000039, 26.217818000000022],
            [63.991391000000078, 26.207395000000076],
            [64.001063302000034, 26.198740660000055],
            [64.003040000000055, 26.196972000000073],
            [64.015302000000077, 26.183484000000078],
            [64.029404000000056, 26.169382000000041],
            [64.045345000000054, 26.157733000000064],
            [64.060060000000078, 26.150989000000038],
            [64.079066000000068, 26.149149000000023],
            [64.097460000000069, 26.154054000000031],
            [64.112175000000036, 26.156507000000033],
            [64.128756972000076, 26.160134597000024],
            [64.13179400000007, 26.160799000000054],
            [64.153253000000063, 26.165704000000062],
            [64.176552000000072, 26.171222000000057],
            [64.198011000000065, 26.173061000000075],
            [64.213952000000063, 26.170608000000072],
            [64.231732000000079, 26.166930000000036],
            [64.25809600000008, 26.171222000000057],
            [64.265258792000054, 26.174918524000077],
            [64.277103000000068, 26.181031000000075],
            [64.285687000000053, 26.193907000000024],
            [64.301628000000051, 26.201264000000037],
            [64.322473000000059, 26.206169000000045],
            [64.34086700000006, 26.217205000000035],
            [64.350064000000032, 26.22272300000003],
            [64.361100000000079, 26.229468000000054],
            [64.361100000000079, 26.241117000000031],
            [64.365392000000043, 26.255832000000055],
            [64.367844000000048, 26.266255000000058],
            [64.384398000000033, 26.257671000000073],
            [64.403405000000078, 26.246022000000039],
            [64.432221000000084, 26.235599000000036],
            [64.447549000000038, 26.230081000000041],
            [64.447933000000035, 26.230105000000037],
            [64.462528000000077, 26.221348000000035],
            [64.473911000000044, 26.207932000000028],
            [64.487328000000048, 26.192076000000043],
            [64.497085000000084, 26.178660000000036],
            [64.505623000000071, 26.162398000000053],
            [64.514973000000055, 26.135158000000047],
            [64.519446000000073, 26.116457000000025],
            [64.519039000000078, 26.106699000000049],
            [64.517006000000038, 26.090844000000061],
            [64.512128000000075, 26.076208000000065],
            [64.501557000000048, 26.062385000000063],
            [64.48854700000004, 26.053034000000025],
            [64.472692000000052, 26.045310000000029],
            [64.460088000000042, 26.037992000000031],
            [64.444233000000054, 26.035959000000048],
            [64.431629000000044, 26.035552000000052],
            [64.413334000000077, 26.034332000000063],
            [64.397479000000033, 26.024169000000029],
            [64.39513343200008, 26.022263159000033],
            [64.384469000000081, 26.013598000000059],
            [64.374712000000045, 25.997742000000073],
            [64.371138341000062, 25.988671963000058],
            [64.369426000000033, 25.984326000000067],
            [64.369122840000045, 25.982142409000062],
            [64.367394000000047, 25.969690000000071],
            [64.362108000000035, 25.95098900000005],
            [64.360482000000047, 25.932694000000026],
            [64.353571000000045, 25.920090000000073],
            [64.352758000000051, 25.902608000000043],
            [64.353571000000045, 25.887972000000048],
            [64.359262000000058, 25.879435000000058],
            [64.359669000000054, 25.866018000000054],
            [64.35885600000006, 25.857887000000062],
            [64.350318000000073, 25.856261000000075],
            [64.340154000000041, 25.855041000000028],
            [64.332430000000045, 25.850569000000064],
            [64.328364000000079, 25.842845000000068],
            [64.323892000000058, 25.829428000000064],
            [64.323486000000059, 25.814792000000068],
            [64.326274170000033, 25.803358786000047],
            [64.327551000000028, 25.798123000000032],
            [64.328364000000079, 25.787960000000055],
            [64.321453000000076, 25.77698300000003],
            [64.314135000000078, 25.766819000000055],
            [64.30112500000007, 25.756655000000023],
            [64.290961000000038, 25.755435000000034],
            [64.284456000000034, 25.75096300000007],
            [64.277138000000036, 25.742019000000028],
            [64.277545000000032, 25.729009000000076],
            [64.281567000000052, 25.714740000000063],
            [64.287661473000071, 25.704212796000036],
            [64.290171000000043, 25.699878000000069],
            [64.301904000000036, 25.685799000000031],
            [64.31129100000004, 25.685799000000031],
            [64.326153000000033, 25.676412000000028],
            [64.340233000000069, 25.670154000000025],
            [64.351966000000061, 25.658421000000033],
            [64.355877000000078, 25.648253000000068],
            [64.355877000000078, 25.636519000000078],
            [64.333975000000066, 25.629480000000058],
            [64.324588000000062, 25.623222000000055],
            [64.314420000000041, 25.618529000000024],
            [64.301904000000036, 25.617746000000068],
            [64.290171000000043, 25.62244000000004],
            [64.277656000000036, 25.613053000000036],
            [64.267487000000074, 25.615400000000022],
            [64.254972000000066, 25.621657000000027],
            [64.244803000000047, 25.631044000000031],
            [64.240892000000031, 25.624786000000029],
            [64.237763000000029, 25.614618000000064],
            [64.22837700000008, 25.613053000000036],
            [64.212733000000071, 25.61070600000005],
            [64.200217000000066, 25.597409000000027],
            [64.189266000000032, 25.577854000000059],
            [64.17362200000008, 25.560645000000079],
            [64.17152800000008, 25.556631000000039],
            [64.169467000000054, 25.55669400000005],
            [64.149914000000081, 25.55669400000005],
            [64.12624500000004, 25.557724000000064],
            [64.103604000000075, 25.571102000000053],
            [64.08919700000007, 25.593742000000077],
            [64.080964000000051, 25.600946000000079],
            [64.068615000000079, 25.605062000000032],
            [64.053178000000059, 25.614324000000067],
            [64.031567000000052, 25.614324000000067],
            [64.005840000000035, 25.614324000000067],
            [63.973937000000035, 25.606091000000049],
            [63.957472000000053, 25.601975000000039],
            [63.930715000000077, 25.59271300000006],
            [63.900871000000052, 25.587567000000035],
            [63.865882000000056, 25.579335000000071],
            [63.839125000000081, 25.570073000000036],
            [63.807223000000079, 25.564927000000068],
            [63.775321000000076, 25.55257800000004],
            [63.741360000000043, 25.531996000000049],
            [63.692993000000058, 25.513472000000036],
            [63.629188000000056, 25.487745000000075],
            [63.581850000000031, 25.468192000000045],
            [63.542744000000027, 25.445551000000023],
            [63.524220000000071, 25.433202000000051],
            [63.496434000000079, 25.422911000000056],
            [63.481197458000054, 25.411648872000058],
            [63.472765000000038, 25.405416000000059],
            [63.456299000000058, 25.385864000000026],
            [63.436746000000028, 25.37248500000004],
            [63.412048000000027, 25.361165000000028],
            [63.403815000000066, 25.373514000000057],
            [63.40999000000005, 25.384834000000069],
            [63.424397000000056, 25.394096000000047],
            [63.435717000000068, 25.405416000000059],
            [63.440863000000036, 25.417766000000029],
            [63.426455000000033, 25.42599800000005],
            [63.426455000000033, 25.434231000000068],
            [63.417193000000054, 25.452755000000025],
            [63.409075382000083, 25.453656860000024],
            [63.407931000000076, 25.453784000000041],
            [63.392495000000054, 25.453784000000041],
            [63.379117000000065, 25.460988000000043],
            [63.37294200000008, 25.471279000000038],
            [63.361622000000068, 25.489803000000052],
            [63.347215000000062, 25.49803600000007],
            [63.325603000000058, 25.500094000000047],
            [63.302963000000034, 25.500094000000047],
            [63.27855567000006, 25.499190123000062],
            [63.275177000000042, 25.49906500000003],
            [63.26282800000007, 25.500094000000047],
            [63.241217000000063, 25.504210000000057],
            [63.228868000000034, 25.508327000000065],
            [63.219606000000056, 25.512443000000076],
            [63.208286000000044, 25.507298000000048],
            [63.195682608000027, 25.509521874000029],
            [63.190791000000047, 25.510385000000042],
            [63.186675000000037, 25.516559000000029],
            [63.171238000000074, 25.514501000000052],
            [63.149627000000066, 25.514501000000052],
            [63.125958000000082, 25.515530000000069],
            [63.09920100000005, 25.516559000000029],
            [63.088910000000055, 25.521705000000054],
            [63.073473000000035, 25.528909000000056],
            [63.057008000000053, 25.528909000000056],
            [63.045688000000041, 25.526851000000079],
            [63.033338000000072, 25.531996000000049],
            [63.028193000000044, 25.545374000000038],
            [63.014815000000056, 25.560811000000058],
            [62.995262000000082, 25.580364000000031],
            [62.979825000000062, 25.593742000000077],
            [62.963359000000082, 25.597859000000028],
            [62.94122703000005, 25.59048137800005],
            [62.941227000000083, 25.590481000000068],
            [62.938661000000081, 25.589626000000067],
            [62.924254000000076, 25.586538000000075],
            [62.906759000000079, 25.582422000000065],
            [62.891322000000059, 25.582422000000065],
            [62.87897300000003, 25.578306000000055],
            [62.864566000000082, 25.571102000000053],
            [62.855304000000046, 25.563898000000052],
            [62.845013000000051, 25.55669400000005],
            [62.826489000000038, 25.55669400000005],
            [62.815169000000083, 25.564927000000068],
            [62.803849000000071, 25.566985000000045],
            [62.782238000000063, 25.565956000000028],
            [62.751365000000078, 25.562869000000035],
            [62.72666600000008, 25.559782000000041],
            [62.708609551000052, 25.559782000000041],
            [62.704026000000056, 25.559782000000041],
            [62.687560000000076, 25.55669400000005],
            [62.670065000000079, 25.554636000000073],
            [62.629931000000056, 25.55669400000005],
            [62.606261000000075, 25.55669400000005],
            [62.578475000000083, 25.55669400000005],
            [62.548631000000057, 25.555665000000033],
            [62.523235594000028, 25.555665000000033],
            [62.519817000000046, 25.555665000000033],
            [62.51140440000006, 25.555665000000033],
            [62.491002000000037, 25.555665000000033],
            [62.475565000000074, 25.555665000000033],
            [62.460129000000052, 25.551549000000023],
            [62.459920000000068, 25.551557000000059],
            [62.433372000000077, 25.55257800000004],
            [62.413819000000046, 25.553607000000056],
            [62.394266000000073, 25.553607000000056],
            [62.369568000000072, 25.547433000000069],
            [62.348986000000082, 25.544345000000078],
            [62.331491000000028, 25.538171000000034],
            [62.32443270400006, 25.53602270600004],
            [62.307822000000044, 25.530967000000032],
            [62.277978000000076, 25.521705000000054],
            [62.258425000000045, 25.513472000000036],
            [62.239901000000032, 25.509356000000025],
            [62.217261000000065, 25.507298000000048],
            [62.173009000000036, 25.488774000000035],
            [62.134933000000046, 25.475395000000049],
            [62.115380000000073, 25.475395000000049],
            [62.10097200000007, 25.483628000000067],
            [62.095827000000043, 25.495977000000039],
            [62.091710000000035, 25.501123000000064],
            [62.080682746000036, 25.50191060700007],
            [62.077303000000029, 25.502152000000024],
            [62.065983000000074, 25.503181000000041],
            [62.061866000000066, 25.523763000000031],
            [62.06392500000004, 25.544345000000078],
            [62.076274000000069, 25.553607000000056],
            [62.095827000000043, 25.554636000000073],
            [62.112292000000082, 25.557724000000064],
            [62.11743800000005, 25.57213100000007],
            [62.114350000000059, 25.581393000000048],
            [62.089652000000058, 25.596829000000071],
            [62.074216000000035, 25.617411000000061],
            [62.072157000000061, 25.635935000000075],
            [62.074216000000035, 25.655488000000048],
            [62.078332000000046, 25.674012000000062],
            [62.067012000000034, 25.693565000000035],
            [62.060367000000042, 25.703326000000061],
            [62.05326400000007, 25.711760000000027],
            [62.052821000000051, 25.721969000000058],
            [62.05015700000007, 25.728628000000072],
            [62.055040000000076, 25.739725000000078],
            [62.056816000000083, 25.745052000000044],
            [62.058147000000076, 25.751266000000044],
            [62.05770300000006, 25.763251000000025],
            [62.05015700000007, 25.767246000000057],
            [62.046880000000044, 25.76843800000006],
            [62.047112739000056, 25.769136185000036],
            [62.04254426600005, 25.770797000000073],
            [62.041123365000033, 25.770530706000045],
            [62.040391000000056, 25.770797000000073],
            [62.033289000000082, 25.769466000000023],
            [62.031514000000072, 25.764583000000073],
            [62.02929400000005, 25.756593000000066],
            [62.028406000000075, 25.752154000000075],
            [62.030626000000041, 25.746827000000053],
            [62.026631000000066, 25.741057000000069],
            [62.021748000000059, 25.735286000000031],
            [62.017753000000027, 25.731291000000056],
            [62.014646000000027, 25.721525000000042],
            [62.014202000000068, 25.714867000000027],
            [62.01153800000003, 25.709984000000077],
            [62.003992000000039, 25.706877000000077],
            [61.997778000000039, 25.701550000000054],
            [61.991563000000042, 25.692672000000073],
            [61.990675000000067, 25.683794000000034],
            [61.984017000000051, 25.675804000000028],
            [61.976027000000045, 25.668702000000053],
            [61.967593000000079, 25.66337500000003],
            [61.959883955000066, 25.661234028000024],
            [61.959603000000072, 25.661156000000062],
            [61.948949000000027, 25.661600000000078],
            [61.936076000000071, 25.662487000000056],
            [61.92719900000003, 25.66337500000003],
            [61.920918266000058, 25.66337500000003],
            [61.91470326600006, 25.669590000000028],
            [61.908489266000061, 25.675360000000069],
            [61.902274266000063, 25.68113100000005],
            [61.90012100000007, 25.68113100000005],
            [61.894284266000057, 25.68113100000005],
            [61.88629426600005, 25.687346000000048],
            [61.88431102800007, 25.687213744000076],
            [61.883136973000035, 25.687135450000028],
            [61.883233418000032, 25.687285476000056],
            [61.877483000000041, 25.686902000000032],
            [61.864610000000027, 25.686458000000073],
            [61.853628943000047, 25.684496624000076],
            [61.841043126000045, 25.688966761000074],
            [61.826587988000028, 25.694689681000057],
            [61.818796034000059, 25.699830045000056],
            [61.805863717000079, 25.701667550000025],
            [61.801909591000083, 25.703877209000041],
            [61.779231516000038, 25.704947149000077],
            [61.765996824000069, 25.707738297000049],
            [61.751971307000076, 25.714437051000061],
            [61.727285416000029, 25.718938420000029],
            [61.729226266000069, 25.722048000000029],
            [61.731491266000035, 25.725543000000073],
            [61.72610826600004, 25.726391000000035],
            [61.734152266000081, 25.732406000000026],
            [61.735561266000047, 25.73469300000005],
            [61.734480266000048, 25.736850000000061],
            [61.737307266000073, 25.741126000000065],
            [61.741666266000038, 25.741053000000079],
            [61.745070266000027, 25.736782000000062],
            [61.747856266000042, 25.732719000000031],
            [61.762928266000074, 25.731547000000035],
            [61.776062265000064, 25.730891000000042],
            [61.773464266000076, 25.728449000000069],
            [61.773064266000063, 25.727656000000025],
            [61.773173266000072, 25.727639000000067],
            [61.773259266000082, 25.727608000000032],
            [61.773315266000054, 25.727588000000026],
            [61.773355266000067, 25.727604000000042],
            [61.773431266000046, 25.727598000000057],
            [61.773477266000043, 25.727573000000064],
            [61.773496266000052, 25.727570000000071],
            [61.773584265000068, 25.727558000000045],
            [61.773611266000046, 25.727530000000058],
            [61.773695266000061, 25.727535000000046],
            [61.773724266000045, 25.727505000000065],
            [61.773838265000052, 25.727549000000067],
            [61.773976266000034, 25.727566000000024],
            [61.774102266000057, 25.727571000000069],
            [61.774156266000034, 25.727537000000041],
            [61.774237266000057, 25.727574000000061],
            [61.774499266000078, 25.727609000000029],
            [61.774689266000053, 25.727579000000048],
            [61.774779266000053, 25.727563000000032],
            [61.774794266000072, 25.727590000000077],
            [61.774846266000054, 25.727549000000067],
            [61.774853266000036, 25.727496000000031],
            [61.774895266000044, 25.727439000000061],
            [61.775030266000044, 25.72736800000007],
            [61.775115266000057, 25.727369000000067],
            [61.775225266000064, 25.727313000000038],
            [61.77533126600008, 25.727256000000068],
            [61.775414266000041, 25.727242000000047],
            [61.775497266000059, 25.727186000000074],
            [61.775629266000067, 25.727162000000078],
            [61.775670266000077, 25.727096000000074],
            [61.775762266000072, 25.72713600000003],
            [61.775912266000034, 25.72708700000004],
            [61.77595526600004, 25.72695600000003],
            [61.776137266000035, 25.726855000000057],
            [61.776195266000059, 25.726839000000041],
            [61.77640526600004, 25.726856000000055],
            [61.776583266000046, 25.726870000000076],
            [61.776625266000053, 25.726831000000061],
            [61.776807266000048, 25.726731000000029],
            [61.776851266000051, 25.726733000000024],
            [61.776918266000052, 25.726698000000056],
            [61.776982266000061, 25.726689000000079],
            [61.777012266000042, 25.726631000000054],
            [61.776795266000079, 25.726504000000034],
            [61.776567266000029, 25.726490000000069],
            [61.77660826500005, 25.726435000000038],
            [61.776618266000071, 25.726360000000057],
            [61.776602266000054, 25.726265000000069],
            [61.776572266000073, 25.726101000000028],
            [61.776602266000054, 25.725984000000039],
            [61.776721266000038, 25.725888000000054],
            [61.776892266000061, 25.725753000000054],
            [61.777075266000054, 25.72555200000005],
            [61.777137266000068, 25.72537600000004],
            [61.777093266000065, 25.725268000000028],
            [61.77705826600004, 25.725186000000065],
            [61.777137266000068, 25.72508700000003],
            [61.777174265000042, 25.725080000000048],
            [61.777348266000047, 25.725040000000035],
            [61.777346266000052, 25.724992000000043],
            [61.777346266000052, 25.724867000000074],
            [61.777372266000043, 25.724848000000065],
            [61.777393266000047, 25.724800000000073],
            [61.777391266000052, 25.724746000000039],
            [61.777409266000063, 25.724692000000061],
            [61.777449266000076, 25.724676000000045],
            [61.77742926600007, 25.72463700000003],
            [61.777466266000033, 25.724616000000026],
            [61.777483266000047, 25.724574000000075],
            [61.777505266000048, 25.724421000000063],
            [61.777611266000065, 25.724333000000058],
            [61.777683266000054, 25.724247000000048],
            [61.777823266000041, 25.724166000000025],
            [61.777926266000065, 25.724108000000058],
            [61.778058266000073, 25.724110000000053],
            [61.778119266000033, 25.724091000000044],
            [61.778298266000036, 25.724136000000044],
            [61.778334266000059, 25.724116000000038],
            [61.778358266000055, 25.724144000000024],
            [61.778444266000065, 25.724182000000042],
            [61.778565266000044, 25.724207000000035],
            [61.77867526600005, 25.724197000000061],
            [61.778851266000061, 25.724109000000055],
            [61.778918266000062, 25.724023000000045],
            [61.778917266000064, 25.723969000000068],
            [61.779023266000081, 25.723946000000069],
            [61.779190266000057, 25.723843000000045],
            [61.779331266000042, 25.723873000000026],
            [61.779360266000083, 25.723892000000035],
            [61.779370266000058, 25.723918000000026],
            [61.779531266000049, 25.723982000000035],
            [61.779579266000042, 25.723979000000043],
            [61.779759266000042, 25.723937000000035],
            [61.779852266000034, 25.723828000000026],
            [61.780185266000046, 25.723713000000032],
            [61.780295266000053, 25.723701000000062],
            [61.780384266000056, 25.72366500000004],
            [61.780425266000066, 25.723677000000066],
            [61.780526266000038, 25.723646000000031],
            [61.780601266000076, 25.723581000000024],
            [61.780646266000076, 25.723575000000039],
            [61.78072026600006, 25.72351800000007],
            [61.78072826600004, 25.723470000000077],
            [61.780756266000083, 25.723430000000064],
            [61.780744265000067, 25.723380000000077],
            [61.780763266000065, 25.723301000000049],
            [61.780744265000067, 25.723276000000055],
            [61.780791266000051, 25.723219000000029],
            [61.78081826600004, 25.723131000000024],
            [61.780792266000049, 25.723058000000037],
            [61.780762266000067, 25.723028000000056],
            [61.780807266000068, 25.723021000000074],
            [61.780820266000035, 25.722982000000059],
            [61.780866266000032, 25.72296300000005],
            [61.780977266000036, 25.722862000000077],
            [61.78094526600006, 25.72273000000007],
            [61.781011266000064, 25.72273000000007],
            [61.781247266000037, 25.722638000000074],
            [61.781331266000052, 25.722624000000053],
            [61.781347266000068, 25.722597000000064],
            [61.781451266000033, 25.722582000000045],
            [61.781601266000052, 25.722515000000044],
            [61.781663266000066, 25.72245300000003],
            [61.781777266000063, 25.722419000000059],
            [61.781833266000035, 25.722387000000026],
            [61.781950266000081, 25.72226900000004],
            [61.782095266000056, 25.72222800000003],
            [61.782128266000029, 25.72217100000006],
            [61.782257266000045, 25.722115000000031],
            [61.782314266000071, 25.722043000000042],
            [61.782357266000076, 25.722023000000036],
            [61.782365266000056, 25.721969000000058],
            [61.782409266000059, 25.721951000000047],
            [61.782388266000055, 25.721921000000066],
            [61.782428266000068, 25.721865000000037],
            [61.782456266000054, 25.721868000000029],
            [61.782553266000036, 25.72183700000005],
            [61.782680266000057, 25.721680000000049],
            [61.782728266000049, 25.721651000000065],
            [61.782752266000045, 25.721602000000075],
            [61.782852266000077, 25.721557000000075],
            [61.782831266000073, 25.721517000000063],
            [61.782942266000077, 25.721505000000036],
            [61.783033266000075, 25.721471000000065],
            [61.783040266000057, 25.721422000000075],
            [61.78313326600005, 25.721370000000036],
            [61.78314126600003, 25.721323000000041],
            [61.783253266000031, 25.721215000000029],
            [61.783361266000043, 25.721152000000075],
            [61.783373266000069, 25.721108000000072],
            [61.783611266000037, 25.721068000000059],
            [61.783631266000043, 25.721035000000029],
            [61.783696266000049, 25.721011000000033],
            [61.783770266000033, 25.721066000000064],
            [61.784001266000075, 25.721027000000049],
            [61.784121266000056, 25.721043000000066],
            [61.784286266000038, 25.72100400000005],
            [61.784385266000072, 25.72100400000005],
            [61.784404266000081, 25.721026000000052],
            [61.784441266000044, 25.721016000000077],
            [61.784487266000042, 25.721056000000033],
            [61.784536266000032, 25.721044000000063],
            [61.784681266000064, 25.721176000000071],
            [61.784801266000045, 25.721191000000033],
            [61.784854266000082, 25.721171000000027],
            [61.784880265000083, 25.721183000000053],
            [61.784904266000069, 25.721243000000072],
            [61.784940266000035, 25.721236000000033],
            [61.78507726600003, 25.721298000000047],
            [61.785135266000054, 25.721287000000075],
            [61.785171266000077, 25.721260000000029],
            [61.785189266000032, 25.721216000000027],
            [61.785240266000073, 25.721196000000077],
            [61.785401266000065, 25.721021000000064],
            [61.785525266000036, 25.720995000000073],
            [61.785725266000043, 25.72091400000005],
            [61.785736266000072, 25.720868000000053],
            [61.785792266000044, 25.720812000000024],
            [61.786057266000057, 25.720789000000025],
            [61.786579266000047, 25.720527000000061],
            [61.786598266000055, 25.720489000000043],
            [61.786714265000057, 25.720406000000025],
            [61.786847266000052, 25.720348000000058],
            [61.786917266000046, 25.720260000000053],
            [61.787051266000049, 25.720180000000028],
            [61.787113266000063, 25.720084000000043],
            [61.787193266000031, 25.720039000000043],
            [61.787153266000075, 25.720019000000036],
            [61.787215266000032, 25.719986000000063],
            [61.787404266000067, 25.719980000000078],
            [61.787439266000035, 25.71995000000004],
            [61.787659266000048, 25.719908000000032],
            [61.787751266000043, 25.719866000000025],
            [61.787893266000083, 25.71975800000007],
            [61.787970266000059, 25.719733000000076],
            [61.788205266000034, 25.719650000000058],
            [61.788302266000073, 25.719617000000028],
            [61.788357266000048, 25.719576000000075],
            [61.788407266000036, 25.719529000000023],
            [61.788541266000038, 25.719498000000044],
            [61.788613266000027, 25.719395000000077],
            [61.788742266000042, 25.719397000000072],
            [61.788798266000072, 25.719370000000026],
            [61.78887426600005, 25.719388000000038],
            [61.789079266000044, 25.719309000000067],
            [61.789129266000032, 25.719310000000064],
            [61.789179266000076, 25.719273000000044],
            [61.789185266000061, 25.719348000000025],
            [61.789293266000072, 25.719310000000064],
            [61.789417266000044, 25.719258000000025],
            [61.789524266000058, 25.719279000000029],
            [61.789520266000068, 25.719354000000067],
            [61.789619266000045, 25.719353000000069],
            [61.789793266000061, 25.719294000000048],
            [61.789904266000065, 25.719309000000067],
            [61.790072266000038, 25.719291000000055],
            [61.79059626600008, 25.719360000000052],
            [61.790857266000046, 25.719335000000058],
            [61.790958266000075, 25.71939100000003],
            [61.791119266000067, 25.719400000000064],
            [61.791427266000028, 25.719345000000033],
            [61.791537266000034, 25.71930100000003],
            [61.791645266000046, 25.719282000000078],
            [61.791663266000057, 25.719234000000029],
            [61.791943266000033, 25.719216000000074],
            [61.79219626500003, 25.719143000000031],
            [61.792377266000074, 25.71912100000003],
            [61.792470266000066, 25.719110000000057],
            [61.792692266000074, 25.719083000000069],
            [61.792791266000052, 25.719043000000056],
            [61.792972266000049, 25.719013000000075],
            [61.793034266000063, 25.71903100000003],
            [61.793186266000077, 25.719030000000032],
            [61.79325026600003, 25.719049000000041],
            [61.793375266000055, 25.718999000000053],
            [61.79350426600007, 25.719009000000028],
            [61.793573266000067, 25.718996000000061],
            [61.793837266000082, 25.718905000000063],
            [61.793896266000047, 25.718936000000042],
            [61.793962266000051, 25.718947000000071],
            [61.79401826600008, 25.718911000000048],
            [61.794182266000064, 25.718866000000048],
            [61.794221266000079, 25.718816000000061],
            [61.794559266000078, 25.718825000000038],
            [61.79463026600007, 25.718874000000028],
            [61.79476126600008, 25.718896000000029],
            [61.794874266000079, 25.718955000000051],
            [61.794995266000058, 25.718951000000061],
            [61.795136266000043, 25.719034000000079],
            [61.795462266000072, 25.719035000000076],
            [61.795587266000041, 25.719077000000027],
            [61.795757266000066, 25.718997000000059],
            [61.795805266000059, 25.719013000000075],
            [61.795919266000055, 25.718999000000053],
            [61.795940266000059, 25.719024000000047],
            [61.796120266000059, 25.718994000000066],
            [61.79615426600003, 25.718979000000047],
            [61.796184266000068, 25.718909000000053],
            [61.796490266000035, 25.718890000000044],
            [61.796544266000069, 25.718847000000039],
            [61.796661266000058, 25.718659000000059],
            [61.796655266000073, 25.718357000000026],
            [61.796766266000077, 25.71832100000006],
            [61.796765266000079, 25.718245000000024],
            [61.796732266000049, 25.718233000000055],
            [61.796784266000031, 25.718149000000039],
            [61.796769266000069, 25.718115000000068],
            [61.796789266000076, 25.718084000000033],
            [61.796846266000045, 25.718099000000052],
            [61.796976265000069, 25.718066000000078],
            [61.797189266000032, 25.718055000000049],
            [61.797453266000048, 25.71799500000003],
            [61.797588266000048, 25.717963000000054],
            [61.797651266000059, 25.71799100000004],
            [61.797745266000049, 25.71799500000003],
            [61.798129266000046, 25.718014000000039],
            [61.798278266000068, 25.718036000000041],
            [61.798399266000047, 25.718030000000056],
            [61.798602266000046, 25.718008000000054],
            [61.798636266000074, 25.718028000000061],
            [61.798834266000028, 25.718026000000066],
            [61.798875266000039, 25.717999000000077],
            [61.799017266000078, 25.718036000000041],
            [61.799206266000056, 25.718011000000047],
            [61.799413266000045, 25.718036000000041],
            [61.799573266000039, 25.718015000000037],
            [61.79968926600003, 25.717966000000047],
            [61.799742266000067, 25.717980000000068],
            [61.800269266000043, 25.717883000000029],
            [61.800331266000057, 25.71790500000003],
            [61.800403266000046, 25.717890000000068],
            [61.800513266000053, 25.717908000000023],
            [61.800519266000038, 25.717996000000028],
            [61.800482266000074, 25.718146000000047],
            [61.800481266000077, 25.718240000000037],
            [61.800527265000028, 25.71837400000004],
            [61.800574266000069, 25.718457000000058],
            [61.800521266000032, 25.718557000000033],
            [61.800537266000049, 25.71864800000003],
            [61.800577266000062, 25.718697000000077],
            [61.800547266000081, 25.718752000000052],
            [61.800479266000082, 25.718808000000024],
            [61.80051026600006, 25.718947000000071],
            [61.800813266000034, 25.71892900000006],
            [61.800845266000067, 25.718987000000027],
            [61.801013266000041, 25.719135000000051],
            [61.801103266000041, 25.719242000000065],
            [61.801103266000041, 25.719321000000036],
            [61.801116266000065, 25.719374000000073],
            [61.801147266000044, 25.719491000000062],
            [61.80120026600008, 25.719605000000058],
            [61.801251266000065, 25.719683000000032],
            [61.801279266000051, 25.719780000000071],
            [61.801281266000046, 25.719869000000074],
            [61.801341266000065, 25.719876000000056],
            [61.801379266000083, 25.719949000000042],
            [61.801395266000043, 25.720046000000025],
            [61.801353266000035, 25.720143000000064],
            [61.801397266000038, 25.720196000000044],
            [61.801432266000063, 25.720218000000045],
            [61.801555266000037, 25.720165000000065],
            [61.801579266000033, 25.720193000000052],
            [61.801661266000053, 25.720225000000028],
            [61.801719266000077, 25.720316000000025],
            [61.801909266000052, 25.720338000000027],
            [61.801994266000065, 25.720426000000032],
            [61.801999266000053, 25.720543000000077],
            [61.801933266000049, 25.720602000000042],
            [61.801821266000047, 25.720650000000035],
            [61.801882266000064, 25.720702000000074],
            [61.801894266000033, 25.72081600000007],
            [61.801958266000042, 25.72100000000006],
            [61.801967266000077, 25.721110000000067],
            [61.801762266000082, 25.721298000000047],
            [61.80168526600005, 25.721453000000054],
            [61.801674266000077, 25.721541000000059],
            [61.801741266000079, 25.72157100000004],
            [61.801752266000051, 25.721626000000072],
            [61.801951265000071, 25.721687000000031],
            [61.802093266000043, 25.721783000000073],
            [61.802099266000027, 25.721821000000034],
            [61.802026266000041, 25.721993000000055],
            [61.802136266000048, 25.722125000000062],
            [61.802194266000072, 25.722078000000067],
            [61.802240266000069, 25.722072000000026],
            [61.802398266000068, 25.722151000000054],
            [61.802535266000064, 25.722227000000032],
            [61.80264126600008, 25.72222400000004],
            [61.802720266000051, 25.722190000000069],
            [61.802775266000083, 25.722116000000028],
            [61.802838266000037, 25.722097000000076],
            [61.802885266000033, 25.722107000000051],
            [61.802939266000067, 25.722148000000061],
            [61.802983266000069, 25.722148000000061],
            [61.803034266000054, 25.722245000000044],
            [61.803077266000059, 25.722278000000074],
            [61.803189266000061, 25.722281000000066],
            [61.803337266000028, 25.722231000000079],
            [61.803373266000051, 25.722174000000052],
            [61.803427266000028, 25.72213000000005],
            [61.803505266000059, 25.722062000000051],
            [61.803785265000045, 25.72203200000007],
            [61.803911266000057, 25.721953000000042],
            [61.804033266000033, 25.721941000000072],
            [61.804142266000042, 25.721882000000051],
            [61.804169266000031, 25.721898000000067],
            [61.804194266000081, 25.721985000000075],
            [61.804376266000077, 25.722061000000053],
            [61.804692266000075, 25.722022000000038],
            [61.804759266000076, 25.721933000000035],
            [61.80483726600005, 25.721937000000025],
            [61.804991266000059, 25.721981000000028],
            [61.805272266000031, 25.72193500000003],
            [61.805526266000072, 25.721950000000049],
            [61.805668266000055, 25.721926000000053],
            [61.805933266000068, 25.722060000000056],
            [61.806101266000042, 25.722095000000024],
            [61.80612826600003, 25.722111000000041],
            [61.806145266000044, 25.722186000000079],
            [61.806260266000038, 25.722239000000059],
            [61.806383266000068, 25.722244000000046],
            [61.806577266000033, 25.722185000000025],
            [61.80681026600007, 25.722243000000049],
            [61.806881266000062, 25.722242000000051],
            [61.806949266000061, 25.722296000000028],
            [61.807090266000046, 25.722343000000023],
            [61.807276266000031, 25.722342000000026],
            [61.80728326600007, 25.722420000000056],
            [61.807269266000048, 25.722546000000023],
            [61.807248266000045, 25.722751000000073],
            [61.807278266000083, 25.722789000000034],
            [61.807309266000061, 25.722920000000045],
            [61.807390266000027, 25.722935000000064],
            [61.80738126600005, 25.72300400000006],
            [61.807293266000045, 25.723050000000057],
            [61.807174266000061, 25.723066000000074],
            [61.807140266000033, 25.723175000000026],
            [61.807145266000077, 25.723285000000033],
            [61.807204266000042, 25.723357000000078],
            [61.807233266000082, 25.723344000000054],
            [61.807270266000046, 25.723371000000043],
            [61.807364266000036, 25.723392000000047],
            [61.807346266000081, 25.723478000000057],
            [61.80743426600003, 25.723461000000043],
            [61.807468266000058, 25.723479000000054],
            [61.80747126600005, 25.723558000000025],
            [61.807432266000035, 25.723620000000039],
            [61.807322266000028, 25.723694000000023],
            [61.807372266000073, 25.723752000000047],
            [61.80752026600004, 25.723763000000076],
            [61.807489266000061, 25.723816000000056],
            [61.807409266000036, 25.723905000000059],
            [61.807338266000045, 25.723930000000053],
            [61.807268266000051, 25.723918000000026],
            [61.807112266000047, 25.724060000000065],
            [61.807203266000045, 25.724176000000057],
            [61.807165266000027, 25.724342000000036],
            [61.807140266000033, 25.724365000000034],
            [61.807060266000065, 25.724390000000028],
            [61.807233266000082, 25.724500000000035],
            [61.807280266000078, 25.724596000000076],
            [61.807394265000028, 25.724609000000044],
            [61.807545266000034, 25.724597000000074],
            [61.807624266000062, 25.724654000000044],
            [61.807665266000072, 25.724743000000046],
            [61.807825266000066, 25.724982000000068],
            [61.807899266000049, 25.72523700000005],
            [61.808027266000067, 25.725238000000047],
            [61.808127266000042, 25.725389000000064],
            [61.808271266000077, 25.725496000000078],
            [61.808291266000083, 25.725581000000034],
            [61.808379266000031, 25.725616000000059],
            [61.808396266000045, 25.725645000000043],
            [61.808387266000068, 25.725704000000064],
            [61.808440266000048, 25.725788000000023],
            [61.808428265000032, 25.725816000000066],
            [61.808453266000072, 25.725841000000059],
            [61.808465266000042, 25.725904000000071],
            [61.808534266000038, 25.726024000000052],
            [61.808526266000058, 25.72606200000007],
            [61.808568266000066, 25.726086000000066],
            [61.808587266000075, 25.726159000000052],
            [61.808616266000058, 25.726181000000054],
            [61.808618266000053, 25.726249000000053],
            [61.808664266000051, 25.726316000000054],
            [61.80862726600003, 25.726329000000078],
            [61.808637266000062, 25.726376000000073],
            [61.808614266000063, 25.726429000000053],
            [61.808513266000034, 25.726596000000029],
            [61.808508266000047, 25.726640000000032],
            [61.808396266000045, 25.726790000000051],
            [61.808324266000056, 25.726847000000078],
            [61.808314266000082, 25.726880000000051],
            [61.808279266000056, 25.726906000000042],
            [61.808289266000031, 25.727005000000077],
            [61.808364266000069, 25.727089000000035],
            [61.80826326600004, 25.727243000000044],
            [61.808167266000055, 25.727342000000078],
            [61.808045266000079, 25.727418000000057],
            [61.808009266000056, 25.727538000000038],
            [61.808049266000069, 25.727612000000079],
            [61.80813226600003, 25.727694000000042],
            [61.808174266000037, 25.727760000000046],
            [61.808240266000041, 25.727857000000029],
            [61.808289266000031, 25.727963000000045],
            [61.808278266000059, 25.727994000000024],
            [61.808360266000079, 25.728180000000066],
            [61.80829626600007, 25.728305000000034],
            [61.808331266000039, 25.728400000000079],
            [61.808233265000069, 25.728607000000068],
            [61.808310266000035, 25.728642000000036],
            [61.808498266000072, 25.728684000000044],
            [61.808565266000073, 25.728744000000063],
            [61.808617266000056, 25.728871000000026],
            [61.808692266000037, 25.72893300000004],
            [61.808693266000034, 25.728998000000047],
            [61.808692266000037, 25.729014000000063],
            [61.808711266000046, 25.729063000000053],
            [61.808691266000039, 25.729139000000032],
            [61.808726266000065, 25.729165000000023],
            [61.808800266000048, 25.729297000000031],
            [61.808848266000041, 25.729330000000061],
            [61.808860266000067, 25.729403000000048],
            [61.808723266000072, 25.729408000000035],
            [61.808703266000066, 25.729464000000064],
            [61.808637266000062, 25.729486000000065],
            [61.808587266000075, 25.729649000000052],
            [61.808535266000035, 25.729707000000076],
            [61.808512266000037, 25.729723000000035],
            [61.808463266000047, 25.729696000000047],
            [61.808268266000027, 25.729760000000056],
            [61.80831226600003, 25.729870000000062],
            [61.808256266000058, 25.729983000000061],
            [61.808230266000066, 25.730007000000057],
            [61.80816126600007, 25.729991000000041],
            [61.808032266000055, 25.73000200000007],
            [61.808004266000069, 25.730085000000031],
            [61.807953266000027, 25.730115000000069],
            [61.807917266000061, 25.730181000000073],
            [61.80788726600008, 25.73023100000006],
            [61.807854266000049, 25.730334000000028],
            [61.807826266000063, 25.730407000000071],
            [61.80779026600004, 25.730435000000057],
            [61.807782266000061, 25.730499000000066],
            [61.80780126600007, 25.730529000000047],
            [61.807770266000034, 25.73061000000007],
            [61.807818266000083, 25.730809000000079],
            [61.807810266000047, 25.730855000000076],
            [61.807713266000064, 25.730968000000075],
            [61.807717266000054, 25.731017000000065],
            [61.807690266000066, 25.731111000000055],
            [61.807770266000034, 25.731204000000048],
            [61.80775226600008, 25.731235000000027],
            [61.807725266000034, 25.731245000000058],
            [61.807701266000038, 25.73130100000003],
            [61.807546266000031, 25.731388000000038],
            [61.807546266000031, 25.731512000000066],
            [61.807580266000059, 25.731571000000031],
            [61.807594266000081, 25.731661000000031],
            [61.807573266000077, 25.73180700000006],
            [61.807664266000074, 25.731834000000049],
            [61.807681266000031, 25.731849000000068],
            [61.807783266000058, 25.731921000000057],
            [61.807836266000038, 25.731884000000036],
            [61.807938266000065, 25.731877000000054],
            [61.807998266000027, 25.731891000000076],
            [61.808051266000064, 25.731863000000033],
            [61.808209266000063, 25.731837000000041],
            [61.808221266000032, 25.731804000000068],
            [61.80829626600007, 25.731794000000036],
            [61.808355266000035, 25.731876000000057],
            [61.808606266000083, 25.732008000000064],
            [61.808980266000049, 25.731907000000035],
            [61.809012266000082, 25.731835000000046],
            [61.809098266000035, 25.73181900000003],
            [61.809159266000052, 25.731831000000057],
            [61.809361266000053, 25.73176600000005],
            [61.809476266000047, 25.731767000000048],
            [61.809615266000037, 25.731853000000058],
            [61.809689266000078, 25.731855000000053],
            [61.809672266000064, 25.732005000000072],
            [61.809623266000074, 25.732152000000042],
            [61.80964726600007, 25.732183000000077],
            [61.809678266000049, 25.732296000000076],
            [61.80973726600007, 25.732359000000031],
            [61.810229266000078, 25.732387000000074],
            [61.810311266000042, 25.732351000000051],
            [61.810342266000077, 25.732266000000038],
            [61.810434266000073, 25.732222000000036],
            [61.810563266000031, 25.732197000000042],
            [61.810622266000053, 25.732317000000023],
            [61.81067626600003, 25.732367000000067],
            [61.810699266000029, 25.73241900000005],
            [61.810690266000051, 25.732441000000051],
            [61.810667266000053, 25.732496000000026],
            [61.810640266000064, 25.73255400000005],
            [61.810637266000072, 25.732609000000025],
            [61.810769265000033, 25.732713000000047],
            [61.810923266000032, 25.732799000000057],
            [61.810972266000078, 25.732880000000023],
            [61.810993266000082, 25.73301900000007],
            [61.811094266000055, 25.733059000000026],
            [61.811105266000027, 25.733196000000078],
            [61.811068266000063, 25.733231000000046],
            [61.81109226600006, 25.733259000000032],
            [61.81104326600007, 25.733374000000026],
            [61.811004266000054, 25.733406000000059],
            [61.810870266000052, 25.733448000000067],
            [61.810818266000069, 25.733503000000042],
            [61.810842266000066, 25.733593000000042],
            [61.810806266000043, 25.733680000000049],
            [61.810902266000028, 25.733801000000028],
            [61.810847265000064, 25.733939000000078],
            [61.810878266000032, 25.734009000000071],
            [61.810872266000047, 25.734064000000046],
            [61.81081126600003, 25.73412600000006],
            [61.810854266000035, 25.734272000000033],
            [61.810976266000068, 25.734423000000049],
            [61.810960266000052, 25.734492000000046],
            [61.810997266000072, 25.734594000000072],
            [61.810957266000059, 25.734714000000054],
            [61.811037266000028, 25.734803000000056],
            [61.811104266000029, 25.734966000000043],
            [61.811044266000067, 25.735006000000055],
            [61.810843266000063, 25.735035000000039],
            [61.810723266000082, 25.734997000000078],
            [61.810659266000073, 25.735005000000058],
            [61.810404266000035, 25.735110000000077],
            [61.810261266000055, 25.73510200000004],
            [61.810173266000049, 25.735044000000073],
            [61.810082266000052, 25.735035000000039],
            [61.809898266000062, 25.73504900000006],
            [61.809846266000079, 25.735076000000049],
            [61.809679266000046, 25.735115000000064],
            [61.809589266000046, 25.735222000000078],
            [61.809609266000052, 25.735253000000057],
            [61.809591266000041, 25.735289000000023],
            [61.809587266000051, 25.735362000000066],
            [61.809556266000072, 25.735400000000027],
            [61.809575266000081, 25.735469000000023],
            [61.809537266000063, 25.73561600000005],
            [61.809546266000041, 25.735663000000045],
            [61.809542266000051, 25.735705000000053],
            [61.809525266000037, 25.735816000000057],
            [61.809399266000071, 25.735908000000052],
            [61.809322266000038, 25.736212000000023],
            [61.809253266000042, 25.736286000000064],
            [61.809252266000044, 25.736352000000068],
            [61.809218266000073, 25.736492000000055],
            [61.809188266000035, 25.736516000000051],
            [61.809237266000082, 25.736519000000044],
            [61.809344266000039, 25.736579000000063],
            [61.809368266000035, 25.736640000000023],
            [61.809425266000062, 25.736680000000035],
            [61.809461266000028, 25.736745000000042],
            [61.809517266000057, 25.736851000000058],
            [61.809580266000069, 25.736926000000039],
            [61.809617266000032, 25.736942000000056],
            [61.809637266000038, 25.737024000000076],
            [61.809694266000065, 25.736998000000028],
            [61.809806266000066, 25.73700800000006],
            [61.809923266000055, 25.737069000000076],
            [61.810046266000029, 25.737128000000041],
            [61.810131266000042, 25.737131000000034],
            [61.810138266000081, 25.737226000000078],
            [61.810106265000059, 25.73728600000004],
            [61.810207266000077, 25.73728600000004],
            [61.810508266000056, 25.737414000000058],
            [61.810518266000031, 25.73746600000004],
            [61.810665266000058, 25.737595000000056],
            [61.81076626600003, 25.737660000000062],
            [61.81080326600005, 25.737737000000038],
            [61.810778266000057, 25.737763000000029],
            [61.810787266000034, 25.737828000000036],
            [61.81071726600004, 25.73789000000005],
            [61.810985266000046, 25.737981000000048],
            [61.811091266000062, 25.73807800000003],
            [61.81123526600004, 25.738152000000071],
            [61.811356266000075, 25.738194000000078],
            [61.811503266000045, 25.738173000000074],
            [61.811584266000068, 25.738228000000049],
            [61.811834266000062, 25.738249000000053],
            [61.811888266000039, 25.738282000000027],
            [61.812061266000057, 25.738328000000024],
            [61.812141266000083, 25.738371000000029],
            [61.812217266000062, 25.738411000000042],
            [61.812300266000079, 25.738482000000033],
            [61.812333266000053, 25.738474000000053],
            [61.812392266000074, 25.738506000000029],
            [61.812596266000071, 25.738377000000071],
            [61.812740266000048, 25.738341000000048],
            [61.812771266000027, 25.738237000000026],
            [61.812828266000054, 25.738186000000042],
            [61.812933266000073, 25.738187000000039],
            [61.813105266000036, 25.738162000000045],
            [61.813117266000063, 25.738134000000059],
            [61.813283266000042, 25.738136000000054],
            [61.813354266000033, 25.738169000000028],
            [61.813507266000045, 25.738154000000065],
            [61.81358326600008, 25.738162000000045],
            [61.81372226600007, 25.738222000000064],
            [61.81386926600004, 25.738224000000059],
            [61.814003266000043, 25.738267000000064],
            [61.814075266000032, 25.738314000000059],
            [61.814137266000046, 25.738331000000073],
            [61.814287266000065, 25.738323000000037],
            [61.81429726600004, 25.738383000000056],
            [61.814460266000083, 25.738463000000024],
            [61.814517266000053, 25.738516000000061],
            [61.814723266000044, 25.738533000000075],
            [61.814809266000054, 25.738507000000027],
            [61.814832266000053, 25.738489000000072],
            [61.814900266000052, 25.738437000000033],
            [61.814917266000066, 25.738405000000057],
            [61.815154266000036, 25.738391000000036],
            [61.815241266000044, 25.738427000000058],
            [61.815303266000058, 25.738428000000056],
            [61.81539626600005, 25.738409000000047],
            [61.81553126600005, 25.738383000000056],
            [61.815618266000058, 25.738399000000072],
            [61.81568526600006, 25.738388000000043],
            [61.815719266000031, 25.738378000000068],
            [61.815789266000081, 25.738233000000037],
            [61.815896266000038, 25.738146000000029],
            [61.816002266000055, 25.738012000000026],
            [61.816213266000034, 25.738065000000063],
            [61.816352266000081, 25.738042000000064],
            [61.816503266000041, 25.738100000000031],
            [61.81678026600008, 25.738089000000059],
            [61.816950266000049, 25.738182000000052],
            [61.817027266000082, 25.738289000000066],
            [61.817075266000074, 25.738433000000043],
            [61.817045266000036, 25.738529000000028],
            [61.817120266000074, 25.738614000000041],
            [61.817201266000041, 25.738655000000051],
            [61.817289266000046, 25.738736000000074],
            [61.81730626600006, 25.738860000000045],
            [61.817548266000074, 25.738844000000029],
            [61.817622266000058, 25.73886600000003],
            [61.817713266000055, 25.738921000000062],
            [61.81794426600004, 25.738842000000034],
            [61.817989266000041, 25.738860000000045],
            [61.818164266000053, 25.738846000000024],
            [61.818335266000076, 25.738892000000078],
            [61.818378265000035, 25.738885000000039],
            [61.818403266000075, 25.738843000000031],
            [61.818497266000065, 25.738807000000065],
            [61.818670266000083, 25.738786000000061],
            [61.818851266000081, 25.738812000000053],
            [61.818985266000027, 25.738914000000022],
            [61.819135266000046, 25.738979000000029],
            [61.819140266000034, 25.739011000000062],
            [61.81920926600003, 25.739073000000076],
            [61.819260266000072, 25.739219000000048],
            [61.819528266000077, 25.739381000000037],
            [61.819622266000067, 25.739349000000061],
            [61.819951266000032, 25.73932300000007],
            [61.820053266000059, 25.739293000000032],
            [61.820176266000033, 25.739298000000076],
            [61.820347266000056, 25.739262000000053],
            [61.820434266000063, 25.739312000000041],
            [61.820488266000041, 25.739383000000032],
            [61.820591266000065, 25.739385000000027],
            [61.820704266000064, 25.73947400000003],
            [61.820855266000081, 25.739491000000044],
            [61.821026266000047, 25.739435000000071],
            [61.821104266000077, 25.739356000000043],
            [61.821210266000037, 25.739341000000024],
            [61.821431266000047, 25.739371000000062],
            [61.821646266000073, 25.739353000000051],
            [61.821889266000028, 25.739454000000023],
            [61.822093266000081, 25.739382000000035],
            [61.822182265000038, 25.739386000000025],
            [61.82222226600004, 25.739408000000026],
            [61.822287266000046, 25.739398000000051],
            [61.822383266000031, 25.739418000000057],
            [61.822460266000064, 25.739472000000035],
            [61.822771266000075, 25.739400000000046],
            [61.823167266000041, 25.739440000000059],
            [61.823348266000039, 25.739389000000074],
            [61.823418266000033, 25.739408000000026],
            [61.82360226600008, 25.73941700000006],
            [61.823684266000043, 25.739404000000036],
            [61.823736266000083, 25.739358000000038],
            [61.823911266000039, 25.739338000000032],
            [61.823983266000027, 25.739357000000041],
            [61.824047266000036, 25.739426000000037],
            [61.824106266000058, 25.739414000000068],
            [61.824181266000039, 25.739420000000052],
            [61.824401266000052, 25.739439000000061],
            [61.824464266000064, 25.739532000000054],
            [61.824701266000034, 25.73959300000007],
            [61.824740266000049, 25.739588000000026],
            [61.824790266000036, 25.73960100000005],
            [61.824863266000079, 25.739620000000059],
            [61.824896266000053, 25.739648000000045],
            [61.825153266000029, 25.739631000000031],
            [61.825309266000033, 25.739561000000037],
            [61.825447266000083, 25.739527000000066],
            [61.825489266000034, 25.739614000000074],
            [61.825553266000043, 25.739694000000043],
            [61.825606266000079, 25.739763000000039],
            [61.825660266000057, 25.739835000000028],
            [61.825722266000071, 25.739918000000046],
            [61.825755266000044, 25.739990000000034],
            [61.825815266000063, 25.740096000000051],
            [61.825886266000055, 25.740169000000037],
            [61.825926266000067, 25.74025800000004],
            [61.825996266000061, 25.74030300000004],
            [61.826030266000032, 25.740373000000034],
            [61.826119266000035, 25.740456000000052],
            [61.826463266000076, 25.740536000000077],
            [61.826697266000053, 25.740724000000057],
            [61.82685226600006, 25.740763000000072],
            [61.826863266000032, 25.740912000000037],
            [61.826966266000056, 25.741040000000055],
            [61.826956266000082, 25.741204000000039],
            [61.826982266000073, 25.74131600000004],
            [61.827145266000059, 25.741408000000035],
            [61.827214266000055, 25.741554000000065],
            [61.827295266000078, 25.741585000000043],
            [61.827344266000068, 25.741651000000047],
            [61.82754626600007, 25.74178100000006],
            [61.827660266000066, 25.741766000000041],
            [61.827780266000048, 25.741799000000071],
            [61.82777126600007, 25.741845000000069],
            [61.827793266000072, 25.741903000000036],
            [61.827772266000068, 25.741954000000078],
            [61.827785266000035, 25.741998000000024],
            [61.827766266000083, 25.742065000000025],
            [61.827794266000069, 25.74210800000003],
            [61.827813266000078, 25.742187000000058],
            [61.827840265000077, 25.742218000000037],
            [61.827837266000074, 25.742247000000077],
            [61.827886266000064, 25.742297000000065],
            [61.827954266000063, 25.742460000000051],
            [61.828119266000044, 25.74256900000006],
            [61.828174266000076, 25.742738000000031],
            [61.828255266000042, 25.742711000000043],
            [61.828259266000032, 25.74275700000004],
            [61.828326266000033, 25.742785000000026],
            [61.82839526600003, 25.742857000000072],
            [61.828460266000036, 25.742880000000071],
            [61.828572266000037, 25.742883000000063],
            [61.828609266000058, 25.742908000000057],
            [61.828768266000054, 25.742908000000057],
            [61.828855266000062, 25.742873000000031],
            [61.828937266000082, 25.742864000000054],
            [61.829042266000044, 25.742932000000053],
            [61.829107266000051, 25.743015000000071],
            [61.82922426600004, 25.743092000000047],
            [61.829387266000083, 25.743193000000076],
            [61.829478266000081, 25.743287000000066],
            [61.829656266000029, 25.743334000000061],
            [61.829699266000034, 25.74336900000003],
            [61.829811266000036, 25.743432000000041],
            [61.829859266000028, 25.743435000000034],
            [61.829978266000069, 25.743461000000025],
            [61.830030266000051, 25.743436000000031],
            [61.830128266000031, 25.743466000000069],
            [61.830213266000044, 25.743471000000056],
            [61.830330266000033, 25.743483000000026],
            [61.830430266000064, 25.743515000000059],
            [61.830470266000077, 25.743572000000029],
            [61.830614266000055, 25.743580000000065],
            [61.830729266000048, 25.743562000000054],
            [61.830762266000079, 25.743571000000031],
            [61.830948266000064, 25.743517000000054],
            [61.831155266000053, 25.743595000000028],
            [61.831534266000062, 25.74363100000005],
            [61.842169266000042, 25.744835000000023],
            [61.845391266000036, 25.760270000000048],
            [61.839735266000048, 25.762896000000069],
            [61.844206266000072, 25.781865000000039],
            [61.841559266000047, 25.781828000000075],
            [61.839730266000061, 25.783248000000071],
            [61.839503266000065, 25.784758000000068],
            [61.838538266000057, 25.785138000000074],
            [61.838688266000077, 25.786841000000038],
            [61.840087266000069, 25.787337000000036],
            [61.840145266000036, 25.788205000000062],
            [61.841189266000072, 25.788571000000047],
            [61.84103226600007, 25.790609000000075],
            [61.839214265000066, 25.790845000000047],
            [61.838188266000031, 25.791310000000067],
            [61.838131266000062, 25.793117000000052],
            [61.839098266000065, 25.794800000000066],
            [61.83853326600007, 25.795466000000033],
            [61.838662266000028, 25.795542000000069],
            [61.838409266000042, 25.795583000000079],
            [61.838372266000079, 25.795829000000026],
            [61.838329266000073, 25.796069000000045],
            [61.838299266000035, 25.796171000000072],
            [61.838161266000043, 25.796547000000032],
            [61.838111266000055, 25.79659300000003],
            [61.838260266000077, 25.796739000000059],
            [61.838198266000063, 25.796777000000077],
            [61.838230266000039, 25.79681400000004],
            [61.838292266000053, 25.796825000000069],
            [61.838355266000065, 25.79681000000005],
            [61.838379266000061, 25.796846000000073],
            [61.838330266000071, 25.796862000000033],
            [61.83830826600007, 25.796920000000057],
            [61.838374266000073, 25.797053000000062],
            [61.838365266000039, 25.797150000000045],
            [61.838335266000058, 25.79719300000005],
            [61.838350266000077, 25.797240000000045],
            [61.83839426600008, 25.797294000000079],
            [61.838435266000033, 25.797335000000032],
            [61.838462266000079, 25.79742600000003],
            [61.838626266000063, 25.797506000000055],
            [61.838643266000076, 25.797546000000068],
            [61.838677266000047, 25.797566000000074],
            [61.83872926600003, 25.797576000000049],
            [61.838787266000054, 25.797588000000076],
            [61.838873266000064, 25.797637000000066],
            [61.838971266000044, 25.797635000000071],
            [61.838970266000047, 25.797683000000063],
            [61.838956266000082, 25.797727000000066],
            [61.839036266000051, 25.797774000000061],
            [61.83889726600006, 25.797821000000056],
            [61.83876226600006, 25.797896000000037],
            [61.838724266000042, 25.797937000000047],
            [61.838737266000066, 25.798009000000036],
            [61.838685266000027, 25.798037000000079],
            [61.838648266000064, 25.798095000000046],
            [61.838628266000057, 25.798172000000079],
            [61.838544266000042, 25.798197000000073],
            [61.838517266000053, 25.79829200000006],
            [61.838462266000079, 25.798323000000039],
            [61.838481266000031, 25.798360000000059],
            [61.838535266000065, 25.798384000000055],
            [61.838381266000056, 25.798512000000073],
            [61.838438266000082, 25.798547000000042],
            [61.838485266000077, 25.798547000000042],
            [61.838542266000047, 25.798663000000033],
            [61.83859426600003, 25.798668000000077],
            [61.838682266000035, 25.798669000000075],
            [61.83872126600005, 25.798690000000079],
            [61.838763266000058, 25.798731000000032],
            [61.838854266000055, 25.798718000000065],
            [61.838887266000029, 25.798743000000059],
            [61.838934266000081, 25.798845000000028],
            [61.839000266000028, 25.799007000000074],
            [61.839054266000062, 25.799090000000035],
            [61.839140266000072, 25.79922700000003],
            [61.839180266000028, 25.799321000000077],
            [61.83923126600007, 25.799346000000071],
            [61.839298266000071, 25.799502000000075],
            [61.839283266000052, 25.799572000000069],
            [61.839326266000057, 25.79960600000004],
            [61.839381266000032, 25.799628000000041],
            [61.839402266000036, 25.799628000000041],
            [61.839457266000068, 25.799631000000034],
            [61.839515266000035, 25.799701000000027],
            [61.83960326600004, 25.799694000000045],
            [61.839724266000076, 25.79972900000007],
            [61.839827266000043, 25.799723000000029],
            [61.839887266000062, 25.799685000000068],
            [61.84003126600004, 25.799677000000031],
            [61.84006026600008, 25.79961000000003],
            [61.840196266000078, 25.799581000000046],
            [61.842828266000083, 25.798910000000035],
            [61.845260266000082, 25.798613000000046],
            [61.846490266000046, 25.799751000000072],
            [61.847257266000042, 25.800878000000068],
            [61.847122266000042, 25.802483000000052],
            [61.848623266000061, 25.80354600000004],
            [61.849024266000072, 25.804651000000035],
            [61.848889266000072, 25.806712000000061],
            [61.848656266000035, 25.808441000000073],
            [61.848661266000079, 25.810400000000072],
            [61.848251266000034, 25.811571000000072],
            [61.848090266000042, 25.813603000000057],
            [61.849123266000049, 25.815040000000067],
            [61.850432265000052, 25.815214000000026],
            [61.851827266000043, 25.815507000000025],
            [61.853504266000073, 25.816992000000027],
            [61.852844266000034, 25.817921000000069],
            [61.852555266000081, 25.818655000000035],
            [61.851393266000059, 25.819581000000028],
            [61.850788266000052, 25.82097600000003],
            [61.850872266000067, 25.822585000000061],
            [61.842995266000059, 25.825064000000054],
            [61.841097266000077, 25.824752000000046],
            [61.840520266000055, 25.826125000000047],
            [61.841054266000071, 25.829013000000032],
            [61.840444266000077, 25.829195000000027],
            [61.840302266000037, 25.82997400000005],
            [61.844145266000055, 25.831248000000073],
            [61.843206266000038, 25.833600000000047],
            [61.845536266000067, 25.836779000000035],
            [61.84206126600003, 25.837028000000032],
            [61.843091266000044, 25.837617000000023],
            [61.845436266000036, 25.838168000000053],
            [61.845095266000044, 25.839657000000045],
            [61.84247326600007, 25.839174000000071],
            [61.840316266000059, 25.839050000000043],
            [61.83835326600007, 25.839585000000056],
            [61.836620266000068, 25.84035300000005],
            [61.834398266000051, 25.842085000000054],
            [61.832996266000066, 25.842018000000053],
            [61.826363266000044, 25.840163000000075],
            [61.825199266000027, 25.840619000000061],
            [61.822749266000073, 25.849418000000071],
            [61.826751266000031, 25.850066000000027],
            [61.828106266000077, 25.851656000000048],
            [61.828632266000056, 25.85287500000004],
            [61.830961266000031, 25.852138000000025],
            [61.833374266000078, 25.853021000000069],
            [61.832991266000079, 25.853751000000045],
            [61.834175266000045, 25.854138000000034],
            [61.834193266000057, 25.855222000000026],
            [61.833330266000075, 25.856696000000056],
            [61.831918266000059, 25.85597400000006],
            [61.832095266000067, 25.858263000000079],
            [61.83223326600006, 25.858053000000041],
            [61.833714266000072, 25.861267000000055],
            [61.834444266000048, 25.865490000000023],
            [61.837077266000051, 25.865631000000064],
            [61.837905266000064, 25.867125000000044],
            [61.845961266000074, 25.866085000000055],
            [61.845669266000073, 25.867358000000024],
            [61.844407266000076, 25.867842000000053],
            [61.845148266000081, 25.868987000000061],
            [61.843874266000057, 25.869656000000077],
            [61.844131266000034, 25.871880000000033],
            [61.841059266000059, 25.871901000000037],
            [61.842113266000069, 25.875144000000034],
            [61.844559266000033, 25.875081000000023],
            [61.844597266000051, 25.878070000000037],
            [61.845428266000056, 25.877740000000074],
            [61.848685266000075, 25.877722000000063],
            [61.85166326600006, 25.878994000000034],
            [61.85551326600006, 25.879380000000026],
            [61.856569266000065, 25.878876000000048],
            [61.859749266000051, 25.880159000000049],
            [61.86196026600004, 25.880653000000052],
            [61.865786265000054, 25.881416000000058],
            [61.866703265000069, 25.879991000000075],
            [61.871532266000031, 25.880820000000028],
            [61.876819266000041, 25.88093200000003],
            [61.876532266000083, 25.879610000000071],
            [61.881641266000031, 25.879878000000076],
            [61.885047266000072, 25.880802000000074],
            [61.889275266000027, 25.882666000000029],
            [61.891361266000047, 25.882225000000062],
            [61.892399266000041, 25.883600000000058],
            [61.89129226600005, 25.884647000000029],
            [61.891456266000034, 25.886292000000026],
            [61.89223045600005, 25.887618038000028],
            [61.893002265000064, 25.888940000000048],
            [61.893124266000029, 25.890055000000075],
            [61.891408266000042, 25.891068000000075],
            [61.888152266000077, 25.890706000000023],
            [61.883400266000081, 25.891185000000064],
            [61.880310266000038, 25.891385000000071],
            [61.877951266000082, 25.892915000000073],
            [61.877640266000071, 25.894557000000077],
            [61.875212266000062, 25.894055000000037],
            [61.873698266000076, 25.895263000000057],
            [61.872941266000055, 25.89743500000003],
            [61.870680266000079, 25.900633000000028],
            [61.869929266000042, 25.905567000000076],
            [61.867875266000055, 25.905640000000062],
            [61.867212266000081, 25.909485000000075],
            [61.861526266000055, 25.908618000000047],
            [61.860112266000044, 25.911302000000035],
            [61.858420266000053, 25.915079000000048],
            [61.853747266000028, 25.91445200000004],
            [61.850915266000072, 25.913919000000078],
            [61.845448266000062, 25.913049000000058],
            [61.839496266000083, 25.913439000000039],
            [61.839416266000057, 25.911596000000031],
            [61.834016266000049, 25.910862000000066],
            [61.82712226600006, 25.961183000000062],
            [61.825260266000043, 25.961629000000073],
            [61.823849266000082, 25.961724000000061],
            [61.824599266000064, 25.962683000000027],
            [61.824248266000041, 25.964865000000032],
            [61.824793266000029, 25.965814000000023],
            [61.823306266000031, 25.966827000000023],
            [61.821000266000055, 25.967444000000057],
            [61.821740266000063, 25.96911700000004],
            [61.823661266000045, 25.970696000000032],
            [61.821763266000062, 25.971866000000034],
            [61.822185266000076, 25.97411100000005],
            [61.82448426600007, 25.974026000000038],
            [61.825813266000068, 25.975387000000069],
            [61.829808266000043, 25.976002000000051],
            [61.830561266000075, 25.976959000000079],
            [61.829834266000034, 25.979099000000076],
            [61.83245026600008, 25.982898000000034],
            [61.836127266000062, 25.981868000000077],
            [61.836144266000076, 25.985142000000053],
            [61.832613266000067, 25.987264000000039],
            [61.836121266000077, 25.992000000000075],
            [61.833724266000047, 25.992916000000037],
            [61.83542626600007, 25.99651700000004],
            [61.838892266000073, 25.997064000000023],
            [61.838244266000061, 26.000882000000047],
            [61.840652266000063, 26.005576000000076],
            [61.839592266000068, 26.00972100000007],
            [61.846482266000066, 26.011833000000024],
            [61.846737266000048, 26.013937000000055],
            [61.845459266000034, 26.015612000000033],
            [61.848374266000064, 26.018893000000048],
            [61.844764266000027, 26.020440000000065],
            [61.84173826600005, 26.025143000000071],
            [61.83692026600005, 26.027320000000032],
            [61.833313266000062, 26.02886600000005],
            [61.83120526600004, 26.031520000000057],
            [61.83394026600007, 26.031327000000033],
            [61.84044026600003, 26.033399000000031],
            [61.843720266000048, 26.03703500000006],
            [61.841561266000042, 26.040385000000072],
            [61.837440266000044, 26.043978000000038],
            [61.838886266000031, 26.045782000000031],
            [61.84039526600003, 26.052466000000038],
            [61.836246266000046, 26.056720000000041],
            [61.836517266000044, 26.063776000000075],
            [61.843115266000041, 26.06667200000004],
            [61.848737266000057, 26.098313000000076],
            [61.854172266000035, 26.131593000000066],
            [61.859988266000073, 26.159678000000042],
            [61.861300266000057, 26.175716000000023],
            [61.895026266000059, 26.232304000000056],
            [61.892048266000074, 26.251744000000031],
            [61.89783626600007, 26.253647000000058],
            [61.899977266000064, 26.262257000000034],
            [61.908283266000069, 26.26112500000005],
            [61.92161126600007, 26.253916000000061],
            [61.935775266000064, 26.256783000000041],
            [61.942193266000061, 26.262383000000057],
            [61.954102266000064, 26.264569000000051],
            [61.963772266000035, 26.261907000000065],
            [61.969820266000056, 26.268578000000048],
            [61.98268226600004, 26.274485000000027],
            [61.984911265000051, 26.280851000000041],
            [62.005471266000029, 26.286039000000073],
            [62.01357626600003, 26.300235000000043],
            [62.030354266000074, 26.314244000000031],
            [62.034991266000077, 26.333739000000037],
            [62.051827266000032, 26.331103000000041],
            [62.073699265000073, 26.325285000000065],
            [62.088987266000061, 26.315603000000067],
            [62.098549266000077, 26.303852000000063],
            [62.104737266000029, 26.283180000000073],
            [62.10975254400006, 26.28141843700007],
            [62.117768266000041, 26.278603000000032],
            [62.140795266000055, 26.271034000000043],
            [62.168351266000059, 26.268992000000026],
            [62.215412266000044, 26.269751000000042],
            [62.219229649000056, 26.271461375000058],
            [62.219948000000045, 26.272748000000036],
            [62.223014000000035, 26.286118000000045],
            [62.219442000000072, 26.295606000000078],
            [62.22847900000005, 26.307508000000041],
            [62.234725000000083, 26.316592000000071],
            [62.228938000000028, 26.330384000000038],
            [62.233866000000035, 26.329731000000038],
            [62.249145000000055, 26.319900000000075],
            [62.267416000000082, 26.323378000000048],
            [62.272910000000081, 26.329834000000062],
            [62.265708000000075, 26.33563300000003],
            [62.251393000000064, 26.345476000000076],
            [62.255355000000066, 26.36162900000005],
            [62.267319000000043, 26.37365600000004],
            [62.274622000000079, 26.367003000000068],
            [62.279802000000075, 26.37780900000007],
            [62.278487774000041, 26.386461051000026],
            [62.278081000000043, 26.389139000000057],
            [62.273694000000035, 26.402627000000052],
            [62.266435000000058, 26.418504000000041],
            [62.265074000000027, 26.433021000000053],
            [62.258723000000032, 26.445723000000044],
            [62.279591000000039, 26.44481600000006],
            [62.29492700000003, 26.455680000000029],
            [62.310440000000028, 26.471581000000072],
            [62.298191000000031, 26.492903000000069],
            [62.300913000000037, 26.50968800000004],
            [62.307430000000068, 26.520853000000045],
            [62.322234000000037, 26.527834000000041],
            [62.339927000000046, 26.529648000000066],
            [62.363970000000052, 26.537814000000026],
            [62.39799400000004, 26.536453000000051],
            [62.420822000000044, 26.550310000000025],
            [62.434757000000047, 26.566398000000049],
            [62.444266000000027, 26.569569000000058],
            [62.462866000000076, 26.566394000000059],
            [62.480642000000046, 26.569949000000065],
            [62.497398000000032, 26.574857000000065],
            [62.505056000000081, 26.580003000000033],
            [62.546338000000048, 26.57909600000005],
            [62.57673200000005, 26.580457000000024],
            [62.601943000000063, 26.584141000000045],
            [62.628853000000049, 26.594941000000063],
            [62.662926000000027, 26.613573000000031],
            [62.693774000000076, 26.61311900000004],
            [62.698490474000039, 26.614273043000026],
            [62.725280000000055, 26.620828000000074],
            [62.736888000000079, 26.635112000000049],
            [62.747602000000029, 26.644040000000075],
            [62.765600045000042, 26.649533643000041],
            [62.768173000000047, 26.650319000000025],
            [62.776489000000083, 26.652671000000055],
            [62.801743000000045, 26.651226000000065],
            [62.829870000000028, 26.650772000000075],
            [62.852099000000067, 26.643968000000029],
            [62.896103000000039, 26.645782000000054],
            [62.901270217000047, 26.645782000000054],
            [62.92241400000006, 26.645782000000054],
            [62.932259000000045, 26.649014000000079],
            [62.956892000000039, 26.651226000000065],
            [62.985926000000063, 26.65304100000003],
            [63.00634000000008, 26.649411000000043],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "22",
      properties: { name: "Kharan", count: 1297 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.117208000000062, 29.326856000000078],
            [66.135662000000082, 29.32259700000003],
            [66.143921783000053, 29.326137323000069],
            [66.155535000000043, 29.331115000000068],
            [66.155983923000065, 29.33141411500003],
            [66.155983960000071, 29.331413973000053],
            [66.156245000000069, 29.330405000000042],
            [66.154826000000071, 29.311951000000079],
            [66.144179000000065, 29.27859200000006],
            [66.125015000000076, 29.240264000000025],
            [66.112949000000071, 29.207614000000035],
            [66.104432000000031, 29.177804000000037],
            [66.102302000000066, 29.154381000000058],
            [66.108690000000081, 29.140896000000055],
            [66.112949000000071, 29.120312000000069],
            [66.116498000000036, 29.110376000000031],
            [66.140630000000044, 29.086953000000051],
            [66.141340000000071, 29.059272000000078],
            [66.140630000000044, 29.029462000000024],
            [66.136372000000051, 29.00745900000004],
            [66.124305000000049, 28.981907000000035],
            [66.122886000000051, 28.954226000000062],
            [66.11862700000006, 28.934352000000047],
            [66.107271000000082, 28.918027000000052],
            [66.098044000000073, 28.901703000000055],
            [66.089527000000032, 28.880409000000043],
            [66.079590000000053, 28.85343800000004],
            [66.070363000000043, 28.835694000000046],
            [66.048360000000059, 28.833564000000024],
            [66.034164000000032, 28.826467000000036],
            [66.025647000000049, 28.808722000000046],
            [66.017130000000066, 28.777492000000052],
            [66.012161000000049, 28.740584000000069],
            [66.015001000000041, 28.728518000000065],
            [66.03842300000008, 28.711484000000041],
            [66.059716000000037, 28.714323000000036],
            [66.068943000000047, 28.728518000000065],
            [66.08668700000004, 28.729938000000061],
            [66.088208970000039, 28.723850992000052],
            [66.091656000000057, 28.710064000000045],
            [66.088817000000063, 28.667478000000074],
            [66.078880000000083, 28.639797000000044],
            [66.070363000000043, 28.615664000000038],
            [66.059716000000037, 28.597210000000075],
            [66.059006000000068, 28.577337000000057],
            [66.041972000000044, 28.552495000000079],
            [66.017130000000066, 28.524104000000079],
            [66.007193000000029, 28.504230000000064],
            [65.98731900000007, 28.475130000000036],
            [65.970995000000073, 28.453836000000024],
            [65.948282000000063, 28.438931000000025],
            [65.924859000000083, 28.418348000000037],
            [65.900017000000048, 28.377181000000064],
            [65.883417951000069, 28.357926042000031],
            [65.883417966000081, 28.357925960000046],
            [65.882273000000055, 28.356598000000076],
            [65.857431000000076, 28.348080000000039],
            [65.817684000000042, 28.336724000000061],
            [65.776517000000069, 28.326077000000055],
            [65.736060000000066, 28.31046200000003],
            [65.704830000000072, 28.297687000000053],
            [65.681408000000033, 28.298396000000025],
            [65.64095100000003, 28.280652000000032],
            [65.60759100000007, 28.259359000000075],
            [65.560746000000051, 28.234517000000039],
            [65.51816000000008, 28.212514000000056],
            [65.487640000000056, 28.199738000000025],
            [65.45853900000003, 28.184833000000026],
            [65.442214000000035, 28.168508000000031],
            [65.386852000000033, 28.126631000000032],
            [65.337168000000077, 28.094692000000066],
            [65.294582000000048, 28.069140000000061],
            [65.278392506000046, 28.056784797000034],
            [65.277817105000054, 28.056345673000067],
            [65.277440411000043, 28.056376583000031],
            [65.229813155000045, 28.060284676000038],
            [65.201919277000059, 28.053599464000058],
            [65.137633660000063, 28.032878637000067],
            [65.061853245000066, 28.012181002000034],
            [65.048044495000056, 28.02145594600006],
            [64.981107940000072, 28.146481577000031],
            [64.891329462000044, 28.213711248000038],
            [64.840681331000042, 28.253120146000072],
            [64.810678320000079, 28.304065851000075],
            [64.78073627200007, 28.331876630000068],
            [64.73920548600006, 28.396724730000074],
            [64.754030449000084, 28.525514937000025],
            [64.761577898000041, 28.616506682000079],
            [64.745951906000073, 28.674947649000046],
            [64.745688415000075, 28.67829602300003],
            [64.747945231000074, 28.678934299000048],
            [64.769214326000053, 28.684949651000068],
            [64.809671326000057, 28.698435651000068],
            [64.830964326000071, 28.697725652000031],
            [64.854387326000051, 28.694886651000047],
            [64.879939325000066, 28.690628651000054],
            [64.91045932600008, 28.687079651000033],
            [64.931042326000068, 28.689918652000074],
            [64.945238325000048, 28.701984651000032],
            [64.977177325000071, 28.732504651000056],
            [65.024732326000048, 28.785027652000053],
            [65.047445326000059, 28.805611652000039],
            [65.072287326000037, 28.808450651000044],
            [65.114163326000039, 28.811999651000065],
            [65.143974326000034, 28.814128651000033],
            [65.171655326000064, 28.823355651000043],
            [65.194368326000074, 28.841809651000062],
            [65.214241326000035, 28.860263651000025],
            [65.229146326000034, 28.878007651000075],
            [65.24547132500004, 28.895752651000066],
            [65.26250632600005, 28.905689651000046],
            [65.288058325000065, 28.914916651000055],
            [65.316448325000067, 28.933370651000075],
            [65.364713325000082, 28.968858651000062],
            [65.40304132600005, 28.992281651000042],
            [65.449886326000069, 29.027769651000028],
            [65.470084001000032, 29.036426146000053],
            [65.472735000000057, 29.034430000000043],
            [65.474386005000042, 29.03460695800004],
            [65.492608000000075, 29.036559000000068],
            [65.51319200000006, 29.038689000000034],
            [65.540873000000033, 29.037979000000064],
            [65.561456000000078, 29.034430000000043],
            [65.563407735000055, 29.03414086500004],
            [65.580620000000067, 29.031591000000049],
            [65.596235000000036, 29.033010000000047],
            [65.609721000000036, 29.042947000000026],
            [65.620367000000044, 29.054304000000059],
            [65.630304000000081, 29.067080000000033],
            [65.652307000000064, 29.072048000000052],
            [65.675729000000047, 29.08340400000003],
            [65.68368186500004, 29.086938468000028],
            [65.688505000000077, 29.089082000000076],
            [65.692764000000068, 29.103988000000072],
            [65.693474000000037, 29.116054000000076],
            [65.69773200000003, 29.126700000000028],
            [65.710508000000061, 29.134508000000039],
            [65.72612300000003, 29.140896000000055],
            [65.749546000000066, 29.145154000000048],
            [65.766393201000028, 29.145908606000035],
            [65.797100000000057, 29.14728400000007],
            [65.821233000000063, 29.146574000000044],
            [65.835428000000036, 29.138766000000032],
            [65.850526777000027, 29.131217095000068],
            [65.851043000000061, 29.130959000000075],
            [65.864529000000061, 29.130249000000049],
            [65.882273000000055, 29.138766000000032],
            [65.895049000000029, 29.145154000000048],
            [65.910664000000054, 29.152962000000059],
            [65.929828000000043, 29.160060000000044],
            [65.951831000000084, 29.166448000000059],
            [65.965616963000059, 29.17603779500007],
            [65.968156000000079, 29.177804000000037],
            [65.983061000000077, 29.186321000000078],
            [65.986534198000072, 29.187671839000075],
            [65.995837000000051, 29.191290000000038],
            [66.005773000000033, 29.194838000000061],
            [66.013581000000045, 29.206195000000037],
            [66.024937000000079, 29.22393900000003],
            [66.033951094000031, 29.240035013000067],
            [66.034874000000059, 29.241683000000023],
            [66.048360000000059, 29.253040000000055],
            [66.049070000000029, 29.260847000000069],
            [66.049070000000029, 29.273623000000043],
            [66.049070000000029, 29.282850000000053],
            [66.054038000000048, 29.297046000000023],
            [66.060426000000064, 29.309112000000027],
            [66.063416336000046, 29.312102336000066],
            [66.074621000000036, 29.323307000000057],
            [66.079590000000053, 29.333244000000036],
            [66.090236000000061, 29.341761000000076],
            [66.098754000000042, 29.346020000000067],
            [66.112239000000045, 29.338212000000055],
            [66.117208000000062, 29.326856000000078],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "23",
      properties: { name: "Khuzdar", count: 1290 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.265995589000056, 28.857427994000034],
            [67.264648000000079, 28.854131000000052],
            [67.252166000000045, 28.843209000000058],
            [67.242024000000072, 28.831508000000042],
            [67.231103000000076, 28.809664000000055],
            [67.229726906000053, 28.806536763000054],
            [67.222521000000029, 28.790161000000069],
            [67.22564200000005, 28.772999000000027],
            [67.230629118000081, 28.757414358000062],
            [67.231883000000039, 28.753496000000041],
            [67.232221250000066, 28.739963867000029],
            [67.232234472000073, 28.739434894000055],
            [67.232663000000059, 28.722291000000041],
            [67.232557371000041, 28.720125103000044],
            [67.232292981000057, 28.71470400100003],
            [67.231103000000076, 28.690306000000078],
            [67.22564200000005, 28.668463000000031],
            [67.225452638000036, 28.667421528000034],
            [67.22505134000005, 28.665281270000037],
            [67.21935134000006, 28.634181270000056],
            [67.215451340000072, 28.606881270000031],
            [67.211551340000028, 28.582681270000023],
            [67.208935116000077, 28.569770774000062],
            [67.206951340000046, 28.559981270000037],
            [67.206451340000058, 28.557981270000028],
            [67.205276583000057, 28.553456281000024],
            [67.203751340000053, 28.547581270000023],
            [67.195298677000039, 28.539854602000048],
            [67.187451340000052, 28.532681270000069],
            [67.172486052000068, 28.524284081000076],
            [67.16945134000008, 28.522581270000046],
            [67.166722146000041, 28.518912999000065],
            [67.160112000000083, 28.510099000000025],
            [67.160112000000083, 28.497617000000048],
            [67.160151340000084, 28.497612280000055],
            [67.160151340000084, 28.497581270000069],
            [67.175405974000057, 28.495782005000024],
            [67.179615000000069, 28.495277000000044],
            [67.179640757000072, 28.495282518000067],
            [67.179651340000078, 28.495281270000078],
            [67.19055134000007, 28.497581270000069],
            [67.20455134000008, 28.498381270000039],
            [67.210755884000037, 28.493765089000078],
            [67.217061000000058, 28.489036000000056],
            [67.231103000000076, 28.479675000000043],
            [67.242654206000054, 28.468123795000054],
            [67.245145000000036, 28.465633000000025],
            [67.250606000000062, 28.454711000000032],
            [67.252275765000036, 28.453298096000026],
            [67.260747000000038, 28.446084965000068],
            [67.26075134000007, 28.446081270000036],
            [67.26075134000007, 28.439886957000056],
            [67.26075134000007, 28.439881270000058],
            [67.262035935000029, 28.439282436000042],
            [67.274009000000035, 28.433648000000062],
            [67.276554169000065, 28.433138868000071],
            [67.276554349000037, 28.433138832000054],
            [67.282252203000041, 28.431999043000076],
            [67.289651340000034, 28.43048127000003],
            [67.300749737000046, 28.432383853000033],
            [67.303651340000044, 28.432881270000053],
            [67.30449343500004, 28.432222239000055],
            [67.305951340000036, 28.43108127000005],
            [67.305958016000034, 28.431095566000067],
            [67.305986019000045, 28.431074024000054],
            [67.313795000000027, 28.42506700000007],
            [67.323913579000077, 28.410278232000053],
            [67.324707031000059, 28.393127441000047],
            [67.323913574000073, 28.371276855000076],
            [67.32393735900007, 28.371248047000051],
            [67.323937000000058, 28.371238000000062],
            [67.337979000000075, 28.354076000000077],
            [67.348085910000066, 28.346301225000047],
            [67.348964616000046, 28.341841377000037],
            [67.351318359000061, 28.329895020000038],
            [67.350524902000075, 28.31671142600004],
            [67.359130859000061, 28.300292969000054],
            [67.369869409000046, 28.285089443000061],
            [67.370727539000029, 28.283874512000068],
            [67.371262018000039, 28.282915191000029],
            [67.375424000000066, 28.275284000000056],
            [67.377755066000077, 28.272105468000063],
            [67.377755092000029, 28.272105432000046],
            [67.384006000000056, 28.263582000000042],
            [67.382445000000075, 28.247200000000078],
            [67.391807000000028, 28.237838000000067],
            [67.392483352000056, 28.222956518000046],
            [67.392483354000035, 28.222956482000029],
            [67.392587000000049, 28.220676000000026],
            [67.394400703000031, 28.200722168000027],
            [67.394897461000028, 28.194885254000042],
            [67.394794948000083, 28.189913349000051],
            [67.394147000000032, 28.165288000000032],
            [67.397268000000054, 28.143444000000045],
            [67.398407562000045, 28.135086237000053],
            [67.398407598000063, 28.135085973000059],
            [67.399608000000057, 28.12628200000006],
            [67.401168000000041, 28.109899000000041],
            [67.400719423000055, 28.098682857000028],
            [67.400719387000038, 28.098681957000053],
            [67.400388000000078, 28.090396000000055],
            [67.396700751000083, 28.071224390000054],
            [67.396484375000057, 28.070129395000038],
            [67.39613853600008, 28.068777073000035],
            [67.391723633000083, 28.051513672000056],
            [67.389504207000073, 28.041579097000067],
            [67.388300195000056, 28.036189710000031],
            [67.387878418000071, 28.034301758000026],
            [67.388671875000057, 28.01007080100004],
            [67.388904379000053, 28.000215537000031],
            [67.388983206000034, 27.996667805000072],
            [67.389466000000084, 27.974939000000063],
            [67.389303863000066, 27.970884932000047],
            [67.38930386100003, 27.97088489600003],
            [67.388671875000057, 27.955505371000072],
            [67.388713481000082, 27.955289439000069],
            [67.393360460000054, 27.930506880000053],
            [67.393367000000069, 27.930472000000066],
            [67.397556026000075, 27.912321881000025],
            [67.392467000000067, 27.911191000000031],
            [67.390888000000075, 27.895013000000063],
            [67.384970000000067, 27.870945000000063],
            [67.380730928000048, 27.862368418000074],
            [67.380697616000077, 27.862301020000075],
            [67.368003000000044, 27.836617000000047],
            [67.358139000000051, 27.803868000000023],
            [67.358139000000051, 27.780194000000051],
            [67.354983000000061, 27.757704000000047],
            [67.344724000000042, 27.740343000000053],
            [67.330914000000064, 27.719431000000043],
            [67.304543179000063, 27.697918729000037],
            [67.280409000000077, 27.681947000000036],
            [67.252395000000035, 27.659062000000063],
            [67.250260728000057, 27.654508548000024],
            [67.250220992000038, 27.654423773000076],
            [67.246477000000084, 27.646436000000051],
            [67.237402000000031, 27.609347000000071],
            [67.230694000000028, 27.58409400000005],
            [67.223592000000053, 27.568706000000077],
            [67.210134408000044, 27.559643320000077],
            [67.204258000000038, 27.555686000000037],
            [67.18591426100005, 27.540780684000026],
            [67.18531900000005, 27.540297000000066],
            [67.185318706000032, 27.540296196000043],
            [67.185095069000056, 27.539684172000079],
            [67.183640644000036, 27.53570385300003],
            [67.177822000000049, 27.519780000000026],
            [67.176244000000054, 27.469670000000065],
            [67.175489965000054, 27.462413105000053],
            [67.173129938000045, 27.439700006000066],
            [67.169494094000072, 27.422423187000049],
            [67.16756300000003, 27.413247000000069],
            [67.166305000000079, 27.399616273000049],
            [67.165196000000037, 27.387600000000077],
            [67.163223000000073, 27.367477000000065],
            [67.15335900000008, 27.347354000000053],
            [67.137182000000053, 27.331571000000054],
            [67.127317000000062, 27.309081000000049],
            [67.123372000000074, 27.275938000000053],
            [67.129685000000052, 27.244372000000055],
            [67.134845915000028, 27.22965324300003],
            [67.140338000000042, 27.213990000000024],
            [67.149019000000067, 27.168221000000074],
            [67.15417437900004, 27.136375561000023],
            [67.155151351000029, 27.130340679000028],
            [67.155700684000067, 27.126892090000069],
            [67.157470703000058, 27.092529297000056],
            [67.158281083000077, 27.078609838000034],
            [67.158450482000035, 27.075415477000035],
            [67.158469108000077, 27.075380214000063],
            [67.15850830100004, 27.074707031000059],
            [67.162451774000033, 27.067840749000027],
            [67.163618000000042, 27.065633000000048],
            [67.154462268000032, 27.044672029000026],
            [67.148624000000041, 27.031306000000029],
            [67.13797100000005, 26.996189000000072],
            [67.140911036000034, 26.980383978000077],
            [67.141053688000056, 26.980006096000068],
            [67.141113281000059, 26.979675293000071],
            [67.143281727000044, 26.974104056000044],
            [67.145073000000082, 26.969359000000054],
            [67.144284000000027, 26.95515400000005],
            [67.134814000000063, 26.941345000000069],
            [67.133584596000048, 26.934428743000069],
            [67.133490412000072, 26.933898890000023],
            [67.133490014000074, 26.933896651000055],
            [67.133483923000028, 26.933862388000023],
            [67.133260456000073, 26.932605228000057],
            [67.133029006000072, 26.931302999000025],
            [67.131658000000073, 26.92358900000005],
            [67.13195369500005, 26.917970886000035],
            [67.132537400000047, 26.906880705000049],
            [67.134326220000048, 26.872893777000058],
            [67.134478519000083, 26.871516118000045],
            [67.138529515000073, 26.834871569000029],
            [67.13865276100006, 26.833877160000043],
            [67.144964375000029, 26.78295242400003],
            [67.147043981000081, 26.76617329100003],
            [67.147046000000046, 26.766157000000078],
            [67.137576000000081, 26.755898000000059],
            [67.131263000000047, 26.744850000000042],
            [67.125754700000073, 26.738948820000076],
            [67.125739000000067, 26.738932000000034],
            [67.127942153000049, 26.717818956000031],
            [67.130474000000049, 26.693556000000058],
            [67.131807791000028, 26.685404882000057],
            [67.134025000000065, 26.67185500000005],
            [67.133695057000068, 26.671001042000057],
            [67.127546789000064, 26.655088081000031],
            [67.127546794000068, 26.655088000000035],
            [67.127254064000056, 26.655088000000035],
            [67.126907000000074, 26.655088000000035],
            [67.103052000000048, 26.650594000000069],
            [67.052921000000083, 26.623972000000037],
            [67.01523700000007, 26.604612000000031],
            [67.008755976000032, 26.601011299000049],
            [67.002140977000067, 26.597336165000058],
            [67.002140912000073, 26.597336129000041],
            [67.001969015000043, 26.597240627000076],
            [66.994529008000029, 26.593107137000061],
            [66.993456000000037, 26.592511000000059],
            [66.99113513900005, 26.59139198500003],
            [66.974121094000054, 26.583312988000046],
            [66.965515137000068, 26.575317383000026],
            [66.965497819000063, 26.575268066000035],
            [66.96545100000003, 26.575225000000046],
            [66.962686000000076, 26.567273000000057],
            [66.966834000000063, 26.557938000000036],
            [66.977898000000039, 26.543763000000069],
            [66.981695539000043, 26.534786908000058],
            [66.981701000000044, 26.534774000000027],
            [66.981723192000061, 26.534218054000064],
            [66.982046000000082, 26.526131000000078],
            [66.983156535000035, 26.522093777000066],
            [66.985850000000028, 26.512302000000034],
            [66.986789620000081, 26.506663370000069],
            [66.986883685000066, 26.506098894000047],
            [66.986887000000081, 26.506079000000057],
            [66.998987000000056, 26.496744000000035],
            [66.999066317000029, 26.496570512000062],
            [67.002041499000029, 26.490062973000079],
            [67.004519000000073, 26.48464400000006],
            [67.012994444000071, 26.473908329000039],
            [67.014891000000034, 26.471506000000034],
            [67.015174382000055, 26.470889951000061],
            [67.02284300000008, 26.454219000000023],
            [67.034252000000038, 26.443847000000062],
            [67.034950858000059, 26.443031684000061],
            [67.040475000000072, 26.436587000000031],
            [67.047389000000067, 26.423104000000023],
            [67.057070000000067, 26.40581700000007],
            [67.06363900000008, 26.394408000000055],
            [67.07001243600007, 26.388651271000072],
            [67.073731630000054, 26.385291954000024],
            [67.07435600000008, 26.384728000000052],
            [67.084728000000041, 26.377122000000043],
            [67.085629745000062, 26.375249298000028],
            [67.089216271000055, 26.367800974000033],
            [67.089223000000061, 26.367787000000078],
            [67.089223000000061, 26.353958000000034],
            [67.093026000000066, 26.341857000000061],
            [67.095446000000038, 26.32941100000005],
            [67.099249000000043, 26.31661900000006],
            [67.10443500000008, 26.307284000000038],
            [67.10468902100007, 26.306564193000042],
            [67.108583000000067, 26.295530000000042],
            [67.119301000000064, 26.288269000000071],
            [67.129327000000046, 26.283775000000048],
            [67.140736000000061, 26.279972000000043],
            [67.147651000000053, 26.27340300000003],
            [67.146268000000077, 26.256462000000056],
            [67.146131240000045, 26.250312560000054],
            [67.145874023000033, 26.240905762000068],
            [67.146602716000075, 26.236594330000059],
            [67.147997000000032, 26.227767000000028],
            [67.147997000000032, 26.201837000000069],
            [67.147997000000032, 26.193156618000046],
            [67.147997000000032, 26.189045000000078],
            [67.14108200000004, 26.173833000000059],
            [67.14108200000004, 26.161387000000047],
            [67.141428000000076, 26.139951000000053],
            [67.141428000000076, 26.121282000000065],
            [67.139015047000044, 26.077846851000061],
            [67.139008000000047, 26.077720000000056],
            [67.137970000000053, 26.063891000000069],
            [67.137191690000066, 26.06155569200007],
            [67.133822000000066, 26.051445000000058],
            [67.128636000000029, 26.031392000000039],
            [67.118610000000047, 26.009957000000043],
            [67.118610000000047, 25.994745000000023],
            [67.123796000000084, 25.977113000000031],
            [67.126846980000039, 25.960159516000033],
            [67.126907000000074, 25.959826000000078],
            [67.12707899600008, 25.957040237000058],
            [67.128636000000029, 25.931822000000068],
            [67.131056000000058, 25.916264000000069],
            [67.131856184000071, 25.907994410000072],
            [67.132093000000054, 25.90554700000007],
            [67.132038037000029, 25.904886966000049],
            [67.131402000000037, 25.897249000000045],
            [67.132027269000048, 25.885361660000058],
            [67.132093000000054, 25.884112000000073],
            [67.132093000000054, 25.879752955000072],
            [67.132093000000054, 25.876253943000052],
            [67.132093000000054, 25.876160000000027],
            [67.135205000000042, 25.869245000000035],
            [67.136588000000074, 25.861639000000025],
            [67.13555000000008, 25.856108000000063],
            [67.130710000000079, 25.848156000000074],
            [67.125178000000062, 25.842624000000058],
            [67.119301000000064, 25.838130000000035],
            [67.118610000000047, 25.828449000000035],
            [67.119901492000054, 25.818629773000055],
            [67.120338000000061, 25.815311000000065],
            [67.122413000000051, 25.801482000000078],
            [67.122594129000049, 25.792951088000052],
            [67.122758000000033, 25.785233000000062],
            [67.118862261000061, 25.775042878000079],
            [67.118264000000067, 25.773478000000068],
            [67.116610912000056, 25.770750584000041],
            [67.111349000000075, 25.762069000000054],
            [67.102706000000069, 25.752389000000051],
            [67.090260000000058, 25.747894000000031],
            [67.077122000000031, 25.745820000000037],
            [67.074365378000039, 25.745506681000052],
            [67.074349605000066, 25.745580790000076],
            [67.063223234000077, 25.744240262000062],
            [67.061910000000069, 25.744091000000026],
            [67.058435996000071, 25.744959502000029],
            [67.052307129000042, 25.746520996000072],
            [67.035278320000032, 25.745483398000033],
            [67.032946781000078, 25.744537067000067],
            [67.024917000000073, 25.741325000000074],
            [67.001062000000047, 25.73752200000007],
            [66.994284162000042, 25.737214119000043],
            [66.985850000000028, 25.736831000000052],
            [66.981070000000045, 25.740353000000027],
            [66.979638544000068, 25.741407589000062],
            [66.979281000000071, 25.741671000000053],
            [66.973967734000041, 25.744200673000023],
            [66.972020000000043, 25.745128000000022],
            [66.973667070000033, 25.750667072000056],
            [66.973693848000039, 25.750671387000068],
            [66.975891113000046, 25.757873535000044],
            [66.979309082000043, 25.770690918000071],
            [66.977478027000075, 25.785278320000032],
            [66.974487305000082, 25.800476074000073],
            [66.968322754000042, 25.818481445000032],
            [66.963500977000081, 25.836730957000043],
            [66.954101562000062, 25.860717773000033],
            [66.949279785000044, 25.879272461000028],
            [66.947509766000053, 25.895080566000047],
            [66.947082520000038, 25.897277832000043],
            [66.94030761700003, 25.916687012000068],
            [66.939086914000029, 25.923889160000044],
            [66.938903809000067, 25.925109863000046],
            [66.938559380000072, 25.925894396000047],
            [66.938476562000062, 25.926330567000036],
            [66.932312012000068, 25.940124512000068],
            [66.917114258000083, 25.963684082000043],
            [66.906127930000082, 25.985473633000026],
            [66.895874023000033, 26.009277344000054],
            [66.894470215000069, 26.01452636700003],
            [66.888122559000067, 26.037719727000024],
            [66.886108398000033, 26.045715332000043],
            [66.884220936000077, 26.052800159000071],
            [66.882690430000082, 26.058715820000032],
            [66.881896973000039, 26.061523438000052],
            [66.880493164000029, 26.085693359000061],
            [66.869689941000047, 26.113281250000057],
            [66.866882324000073, 26.129882813000052],
            [66.858886719000054, 26.15069580100004],
            [66.856689453000058, 26.157714844000054],
            [66.854068670000061, 26.165425266000057],
            [66.852478027000075, 26.170288086000028],
            [66.846496582000043, 26.193298340000069],
            [66.841125489000035, 26.212280274000022],
            [66.838928223000039, 26.23132324200003],
            [66.834106446000078, 26.247924805000025],
            [66.831298828000058, 26.256103516000053],
            [66.81811523500005, 26.26928711000005],
            [66.814697266000053, 26.272705078000058],
            [66.803710938000052, 26.288696289000029],
            [66.803100586000028, 26.28991699200003],
            [66.793884277000075, 26.302917480000076],
            [66.790283204000048, 26.312500000000057],
            [66.784729004000042, 26.326721192000036],
            [66.779113770000038, 26.337280274000022],
            [66.779479981000065, 26.348876953000058],
            [66.777099609000061, 26.360900879000042],
            [66.776123047000056, 26.37530517600004],
            [66.775329590000069, 26.384521484000061],
            [66.768127441000047, 26.402709961000028],
            [66.767700195000032, 26.410705566000047],
            [66.762084961000028, 26.426696777000075],
            [66.761474609000061, 26.437072754000042],
            [66.760925293000071, 26.453308106000065],
            [66.754699707000043, 26.468688965000069],
            [66.748718262000068, 26.470886231000065],
            [66.738281250000057, 26.46569824200003],
            [66.730712891000053, 26.460510254000042],
            [66.722106934000067, 26.460083008000026],
            [66.713500977000081, 26.463684082000043],
            [66.711120606000065, 26.459106445000032],
            [66.710876465000069, 26.459106445000032],
            [66.708051376000071, 26.45942490300007],
            [66.707551340000066, 26.459481270000026],
            [66.705302388000064, 26.459106445000032],
            [66.705078125000057, 26.459106445000032],
            [66.702270508000083, 26.452880859000061],
            [66.701294647000054, 26.443488199000058],
            [66.701293945000032, 26.443481445000032],
            [66.692321777000075, 26.444519043000071],
            [66.689730810000071, 26.447891414000026],
            [66.688476562000062, 26.44952392600004],
            [66.680101035000064, 26.446524829000055],
            [66.674499511000079, 26.444519043000071],
            [66.665100098000039, 26.444519043000071],
            [66.658264749000068, 26.443702431000077],
            [66.651306153000064, 26.442871094000054],
            [66.644627959000047, 26.441630858000053],
            [66.644475539000041, 26.441602552000063],
            [66.638488770000038, 26.440490723000039],
            [66.62670898500005, 26.43389892600004],
            [66.618286132000037, 26.421081543000071],
            [66.616027221000081, 26.416399434000027],
            [66.614929199000073, 26.414123535000044],
            [66.612487793000071, 26.410095215000069],
            [66.61108398500005, 26.408325195000032],
            [66.610186167000052, 26.408820542000058],
            [66.609326604000046, 26.409294785000043],
            [66.60929567800008, 26.409314041000073],
            [66.609294928000054, 26.409327519000044],
            [66.608170791000077, 26.410014442000033],
            [66.605699916000049, 26.411524311000051],
            [66.604856000000041, 26.412040000000047],
            [66.60010003900004, 26.420220826000048],
            [66.596213000000034, 26.426907000000028],
            [66.59447663800006, 26.430958288000056],
            [66.592569146000073, 26.435408859000063],
            [66.591516357000046, 26.437865230000057],
            [66.591051340000035, 26.438981270000056],
            [66.591028042000062, 26.43900456800003],
            [66.591027000000054, 26.439007000000061],
            [66.579618000000039, 26.450416000000075],
            [66.577588258000048, 26.451769162000062],
            [66.574151340000071, 26.454081270000074],
            [66.571351340000035, 26.455981270000052],
            [66.570065215000056, 26.45663247300007],
            [66.563714000000061, 26.460097000000076],
            [66.563402637000081, 26.460127378000038],
            [66.549790419000033, 26.461455470000033],
            [66.549547295000082, 26.461479191000024],
            [66.549539000000038, 26.461480000000051],
            [66.547148681000067, 26.460246258000041],
            [66.538821000000041, 26.455948000000035],
            [66.536829544000057, 26.454537395000045],
            [66.534167634000028, 26.452651888000048],
            [66.530541265000068, 26.450083229000029],
            [66.519751340000028, 26.45218127000004],
            [66.516351340000028, 26.452881270000034],
            [66.504033894000031, 26.449583156000074],
            [66.498025000000041, 26.447996000000046],
            [66.485377422000056, 26.438797989000022],
            [66.485103177000042, 26.438598543000069],
            [66.484251340000071, 26.437981270000023],
            [66.482851340000082, 26.436981270000047],
            [66.47836463200008, 26.434645215000046],
            [66.470713000000046, 26.430710000000033],
            [66.465236884000035, 26.42924963300004],
            [66.460480620000055, 26.427981234000072],
            [66.460341000000028, 26.427944000000025],
            [66.460247908000042, 26.427981234000072],
            [66.453663643000084, 26.43061472200003],
            [66.448240000000055, 26.432784000000026],
            [66.44404968200007, 26.434112781000067],
            [66.434065000000032, 26.437279000000046],
            [66.430475107000063, 26.440532391000033],
            [66.423002000000054, 26.447305000000028],
            [66.417307012000038, 26.454293827000072],
            [66.415396000000044, 26.456639000000052],
            [66.415383939000037, 26.456641474000037],
            [66.415077656000051, 26.456704307000052],
            [66.406292290000067, 26.458506601000067],
            [66.401913000000036, 26.459405000000061],
            [66.392494837000072, 26.460932728000046],
            [66.389121000000046, 26.461480000000051],
            [66.384984541000051, 26.462612464000074],
            [66.360079000000042, 26.469431000000043],
            [66.359835964000069, 26.469464168000059],
            [66.35772705100004, 26.470275879000042],
            [66.352478027000075, 26.470520020000038],
            [66.319970985000054, 26.472894130000043],
            [66.318136447000029, 26.475238081000043],
            [66.313903809000067, 26.480895996000072],
            [66.300720215000069, 26.489929199000073],
            [66.298811461000071, 26.491091050000023],
            [66.290893555000082, 26.495910645000038],
            [66.286926270000038, 26.498107910000044],
            [66.286855396000078, 26.498087880000071],
            [66.286790201000031, 26.498127000000068],
            [66.280416206000041, 26.496268110000074],
            [66.278492202000052, 26.495707000000039],
            [66.27713594100004, 26.494863895000037],
            [66.27654652800004, 26.494497494000029],
            [66.265700201000072, 26.48775500000005],
            [66.251285206000034, 26.480547502000036],
            [66.24854287800008, 26.484377320000078],
            [66.248477183000034, 26.484469067000077],
            [66.24829105200007, 26.484740728000077],
            [66.244011822000061, 26.490986302000067],
            [66.242199090000042, 26.493632000000048],
            [66.230431000000067, 26.50504200000006],
            [66.222492053000053, 26.515072641000074],
            [66.22248623400003, 26.515079825000043],
            [66.222466415000042, 26.515418960000034],
            [66.222318598000072, 26.517234715000029],
            [66.220750000000066, 26.536503000000039],
            [66.210083008000083, 26.546875000000057],
            [66.202843363000056, 26.554250892000027],
            [66.198536542000056, 26.558665608000069],
            [66.19839535300008, 26.558811196000079],
            [66.194657826000082, 26.562665278000054],
            [66.187280457000043, 26.570316116000072],
            [66.187215000000037, 26.570384000000047],
            [66.182189176000065, 26.570821358000046],
            [66.179263000000049, 26.571076000000062],
            [66.17723699000004, 26.570913787000052],
            [66.173803347000046, 26.570597211000063],
            [66.170715332000043, 26.570312500000057],
            [66.163330078000058, 26.565490723000039],
            [66.155090332000043, 26.559692383000026],
            [66.145690918000071, 26.556884766000053],
            [66.13647461000005, 26.548889160000044],
            [66.126708984000061, 26.538879395000038],
            [66.119873047000056, 26.529296875000057],
            [66.117667420000032, 26.524831161000066],
            [66.117664633000061, 26.524825518000057],
            [66.117664620000028, 26.524825493000037],
            [66.117664600000069, 26.524825451000027],
            [66.114929199000073, 26.519287109000061],
            [66.113788551000027, 26.516399392000039],
            [66.110107422000056, 26.507080078000058],
            [66.107482910000044, 26.49890136700003],
            [66.106079102000081, 26.490478516000053],
            [66.106079102000081, 26.48132324200003],
            [66.107286661000046, 26.477963078000073],
            [66.108886719000054, 26.47351074200003],
            [66.10975598400006, 26.472503668000058],
            [66.109770265000066, 26.472487124000054],
            [66.109774074000029, 26.472482709000076],
            [66.113891602000081, 26.467712402000075],
            [66.113281250000057, 26.457702637000068],
            [66.112915039000029, 26.452514648000033],
            [66.112658373000045, 26.449397999000041],
            [66.112487793000071, 26.447326660000044],
            [66.111087619000045, 26.440180948000034],
            [66.110717773000033, 26.438293457000043],
            [66.10968017600004, 26.43267822200005],
            [66.109518715000036, 26.431790190000072],
            [66.108703613000046, 26.427307129000042],
            [66.104579667000053, 26.42250456000005],
            [66.104311944000074, 26.422192781000035],
            [66.103881836000028, 26.421691895000038],
            [66.103873458000066, 26.421691692000024],
            [66.102541159000054, 26.421659459000068],
            [66.099829484000054, 26.421593854000037],
            [66.096313477000081, 26.421508789000029],
            [66.082092285000044, 26.413513184000067],
            [66.082048247000046, 26.413130390000049],
            [66.081723621000037, 26.410308648000068],
            [66.080505371000072, 26.399719238000046],
            [66.080688476000034, 26.386901856000065],
            [66.080688476000034, 26.38311767600004],
            [66.081726074000073, 26.377075195000032],
            [66.08428955100004, 26.361877442000036],
            [66.088317871000072, 26.353698730000076],
            [66.090881348000039, 26.346130371000072],
            [66.090881348000039, 26.333679199000073],
            [66.091491699000073, 26.319702149000022],
            [66.099121094000054, 26.299072266000053],
            [66.104309082000043, 26.283081055000025],
            [66.10772705100004, 26.275085449000073],
            [66.108563344000061, 26.270799447000059],
            [66.108620793000057, 26.270505021000076],
            [66.108703613000046, 26.270080566000047],
            [66.108664151000028, 26.270082340000044],
            [66.103271484000061, 26.270324707000043],
            [66.091125488000046, 26.293884277000075],
            [66.072875977000081, 26.320495605000076],
            [66.054504395000038, 26.355285645000038],
            [66.036499023000033, 26.385498047000056],
            [66.028680098000052, 26.396996467000065],
            [66.025085449000073, 26.402282715000069],
            [66.020343930000081, 26.409797576000074],
            [66.008911133000083, 26.427917480000076],
            [66.000915527000075, 26.44030761700003],
            [65.994323730000076, 26.450073243000077],
            [65.982299805000082, 26.468078613000046],
            [65.969116211000028, 26.488098145000038],
            [65.957092285000044, 26.505676270000038],
            [65.948730469000054, 26.520874023000033],
            [65.943547599000055, 26.527713274000064],
            [65.936889648000033, 26.536499023000033],
            [65.935848232000069, 26.539305366000065],
            [65.931349030000035, 26.551429533000032],
            [65.931091309000067, 26.552124024000022],
            [65.926861559000031, 26.560075952000034],
            [65.926513672000056, 26.560729980000076],
            [65.926112869000065, 26.562673265000058],
            [65.924499512000068, 26.570495605000076],
            [65.923522949000073, 26.580688477000024],
            [65.926879883000083, 26.587280273000033],
            [65.927307129000042, 26.598083496000072],
            [65.929687500000057, 26.601928711000028],
            [65.930580411000051, 26.603477639000062],
            [65.930725941000048, 26.603730087000031],
            [65.930820873000073, 26.603894766000053],
            [65.932678223000039, 26.607116699000073],
            [65.932581448000064, 26.607531018000032],
            [65.932483252000054, 26.607951417000038],
            [65.931849440000065, 26.610664924000048],
            [65.930725837000068, 26.615475352000033],
            [65.930725098000039, 26.615478516000053],
            [65.930891786000075, 26.616042314000026],
            [65.934875488000046, 26.629516602000024],
            [65.940124512000068, 26.645080567000036],
            [65.945678711000028, 26.665527344000054],
            [65.948536458000035, 26.675215800000046],
            [65.949068777000036, 26.677020493000043],
            [65.950683594000054, 26.68249511700003],
            [65.955137490000084, 26.692114296000057],
            [65.955876836000073, 26.693711079000025],
            [65.959472656000059, 26.70147705100004],
            [65.967712403000064, 26.734924316000047],
            [65.974487305000082, 26.757690430000025],
            [65.978515625000057, 26.771728516000053],
            [65.987121582000043, 26.802673340000069],
            [65.996924714000045, 26.833687676000068],
            [66.000915528000064, 26.846313477000024],
            [66.004089356000065, 26.863891602000024],
            [66.006976110000039, 26.874651325000059],
            [66.007506070000034, 26.876626626000075],
            [66.00811767600004, 26.878906250000057],
            [66.013305664000029, 26.897888184000067],
            [66.013880264000079, 26.899872465000044],
            [66.014755834000027, 26.902896102000057],
            [66.01788330100004, 26.913696289000029],
            [66.019287109000061, 26.928710937000062],
            [66.022094727000081, 26.946105957000043],
            [66.023632426000063, 26.961009810000064],
            [66.023679395000045, 26.961465047000047],
            [66.023681641000053, 26.961486816000047],
            [66.024093269000048, 26.963016998000057],
            [66.026489258000083, 26.971923828000058],
            [66.02749723800008, 26.974639774000025],
            [66.033081055000082, 26.989685059000067],
            [66.035522461000028, 27.001892090000069],
            [66.038330078000058, 27.017517090000069],
            [66.039757590000079, 27.028278336000028],
            [66.03991699200003, 27.029479980000076],
            [66.04132080100004, 27.03991699200003],
            [66.041415577000066, 27.040454662000059],
            [66.044494629000042, 27.057922364000035],
            [66.044988060000037, 27.072133196000038],
            [66.045104981000065, 27.075500489000035],
            [66.046430962000045, 27.081284843000049],
            [66.049316406000059, 27.093872070000032],
            [66.050109864000035, 27.106689453000058],
            [66.050278087000038, 27.122502481000026],
            [66.050292969000054, 27.12390136700003],
            [66.051255544000071, 27.144073607000053],
            [66.051696778000064, 27.153320312000062],
            [66.052124024000079, 27.17608642600004],
            [66.049657344000082, 27.18613930500004],
            [66.048890824000068, 27.189263237000034],
            [66.048889160000044, 27.189270020000038],
            [66.042857078000054, 27.193629841000075],
            [66.042724609000061, 27.193725586000028],
            [66.042714529000079, 27.193723508000062],
            [66.042260071000044, 27.193629841000075],
            [66.042259896000076, 27.193629805000057],
            [66.041495557000076, 27.193472270000029],
            [66.034729004000042, 27.192077637000068],
            [66.022277832000043, 27.178527832000043],
            [66.019333823000068, 27.173342860000048],
            [66.018047329000069, 27.171077095000044],
            [66.014099121000072, 27.164123535000044],
            [66.006896973000039, 27.153686523000033],
            [66.00670914300008, 27.153416047000064],
            [66.006669226000042, 27.153358567000055],
            [66.002319336000028, 27.147094727000024],
            [66.002255679000029, 27.147070626000072],
            [66.001153171000055, 27.146653215000072],
            [65.987487793000071, 27.14147949200003],
            [65.98542212600006, 27.141038817000037],
            [65.982910156000059, 27.140502930000025],
            [65.973693848000039, 27.138122559000067],
            [65.962406608000038, 27.137078538000026],
            [65.938720703000058, 27.134887695000032],
            [65.91687011700003, 27.133911133000026],
            [65.91685681000007, 27.133923134000042],
            [65.906921387000068, 27.14288330100004],
            [65.898681641000053, 27.151916504000042],
            [65.888305664000029, 27.166076660000044],
            [65.880310059000067, 27.174499512000068],
            [65.877929687000062, 27.186279297000056],
            [65.868896484000061, 27.20788574200003],
            [65.864074707000043, 27.224487305000025],
            [65.863708496000072, 27.239685059000067],
            [65.853698730000076, 27.258728027000075],
            [65.847473145000038, 27.273681641000053],
            [65.837280273000033, 27.290893555000025],
            [65.835249371000032, 27.294122168000058],
            [65.834899902000075, 27.294677734000061],
            [65.830816862000063, 27.300152719000039],
            [65.821786975000066, 27.312193016000037],
            [65.82143300000007, 27.312665000000038],
            [65.815457328000036, 27.31838655200005],
            [65.805184000000054, 27.328223000000037],
            [65.79342900000006, 27.341015000000027],
            [65.778563000000076, 27.352770000000078],
            [65.763350000000059, 27.364179000000036],
            [65.752979000000039, 27.367636000000061],
            [65.736729000000082, 27.374551000000054],
            [65.722900000000038, 27.379391000000055],
            [65.701811000000077, 27.386306000000047],
            [65.68694400000004, 27.391837000000066],
            [65.686709639000071, 27.391907313000047],
            [65.686709325000038, 27.391907408000066],
            [65.686706543000071, 27.391906738000046],
            [65.659301758000083, 27.400085449000073],
            [65.653320312000062, 27.401489258000026],
            [65.646686353000064, 27.403056871000047],
            [65.63543100000004, 27.405667000000051],
            [65.61019200000004, 27.412235000000067],
            [65.598890016000041, 27.420266022000078],
            [65.598889991000078, 27.420266040000058],
            [65.59888998100007, 27.420266084000048],
            [65.598599810000053, 27.421570036000048],
            [65.598570000000052, 27.421704000000034],
            [65.61044400000003, 27.44088400000004],
            [65.61046544800007, 27.440903969000033],
            [65.610473633000083, 27.440917969000054],
            [65.62898772300008, 27.458149328000047],
            [65.636901855000076, 27.465515137000068],
            [65.63852823600007, 27.466868385000055],
            [65.668884277000075, 27.492126465000069],
            [65.680380601000081, 27.504060744000071],
            [65.688110352000081, 27.512084961000028],
            [65.697547874000065, 27.522151652000048],
            [65.703577073000076, 27.52858279700007],
            [65.703601882000044, 27.528609260000053],
            [65.703674316000047, 27.528686523000033],
            [65.701904297000056, 27.54772949200003],
            [65.690917969000054, 27.564270020000038],
            [65.690085006000061, 27.580341309000062],
            [65.689880371000072, 27.58428955100004],
            [65.692111194000063, 27.589573077000068],
            [65.694519043000071, 27.595275879000042],
            [65.695418283000038, 27.597887958000058],
            [65.697082520000038, 27.602722168000071],
            [65.701336568000045, 27.614460190000045],
            [65.703674316000047, 27.620910645000038],
            [65.708312988000046, 27.640075684000067],
            [65.711914062000062, 27.658325195000032],
            [65.715515137000068, 27.664306641000053],
            [65.720092773000033, 27.671875000000057],
            [65.734678717000065, 27.687498437000045],
            [65.73468017600004, 27.687500000000057],
            [65.753906250000057, 27.696716309000067],
            [65.756265215000042, 27.699221305000037],
            [65.766723633000083, 27.710327148000033],
            [65.770324707000043, 27.717712402000075],
            [65.770324707000043, 27.734130859000061],
            [65.771301270000038, 27.753295898000033],
            [65.782287598000039, 27.775085449000073],
            [65.796857516000046, 27.802640272000076],
            [65.796875000000057, 27.802673340000069],
            [65.800476074000073, 27.805480957000043],
            [65.808860267000057, 27.811762447000035],
            [65.819702148000033, 27.819885254000042],
            [65.844299316000047, 27.832702637000068],
            [65.850864604000037, 27.836776482000062],
            [65.868103027000075, 27.847473145000038],
            [65.878169888000059, 27.854716374000077],
            [65.888122559000067, 27.861877441000047],
            [65.901916504000042, 27.881103516000053],
            [65.911926270000038, 27.900329590000069],
            [65.92468261700003, 27.916687012000068],
            [65.927805729000056, 27.919179191000069],
            [65.930725098000039, 27.921508789000029],
            [65.936523437000062, 27.925903320000032],
            [65.943255370000031, 27.929248508000057],
            [65.955588668000075, 27.935377092000067],
            [65.956298828000058, 27.935729980000076],
            [65.977722168000071, 27.937683105000076],
            [65.988948981000078, 27.936296604000063],
            [66.013305664000029, 27.933288574000073],
            [66.029650966000077, 27.93396529000006],
            [66.032470703000058, 27.934082031000059],
            [66.03602141500005, 27.934225751000042],
            [66.058105469000054, 27.935119629000042],
            [66.062377337000044, 27.935924262000071],
            [66.075927734000061, 27.938476562000062],
            [66.078654609000068, 27.939315601000033],
            [66.079895020000038, 27.939697266000053],
            [66.110107422000056, 27.950683594000054],
            [66.121724565000079, 27.95579045900007],
            [66.128295898000033, 27.958679199000073],
            [66.134694394000064, 27.965532818000042],
            [66.14288330100004, 27.974304199000073],
            [66.152721610000071, 27.981438851000064],
            [66.158874512000068, 27.985900879000042],
            [66.161894790000076, 27.988142492000065],
            [66.166687012000068, 27.991699219000054],
            [66.179994073000046, 28.002533049000078],
            [66.196899414000029, 28.016296387000068],
            [66.210453495000081, 28.024689223000053],
            [66.232482910000044, 28.038330078000058],
            [66.256286621000072, 28.052917480000076],
            [66.261291504000042, 28.055297852000024],
            [66.265370009000037, 28.057254518000036],
            [66.280883789000029, 28.064697266000053],
            [66.286459703000048, 28.067863547000059],
            [66.310119629000042, 28.081298828000058],
            [66.329895020000038, 28.087890625000057],
            [66.340270996000072, 28.091308594000054],
            [66.368892006000067, 28.10719926400003],
            [66.379516602000081, 28.113098145000038],
            [66.389706842000066, 28.117493172000025],
            [66.411499023000033, 28.126892090000069],
            [66.425292969000054, 28.132080078000058],
            [66.445312500000057, 28.139709473000039],
            [66.469909668000071, 28.151489258000026],
            [66.484497070000032, 28.164306641000053],
            [66.504650212000058, 28.173500370000056],
            [66.504699707000043, 28.173522949000073],
            [66.514709473000039, 28.208129883000026],
            [66.516723633000083, 28.224121094000054],
            [66.51812890900004, 28.236660481000058],
            [66.518310547000056, 28.238281250000057],
            [66.518310547000056, 28.262878418000071],
            [66.51647949200003, 28.286682129000042],
            [66.516278817000057, 28.287217829000042],
            [66.509277344000054, 28.305908203000058],
            [66.500406124000051, 28.313736109000047],
            [66.481787000000054, 28.330494000000044],
            [66.455299000000082, 28.350587000000075],
            [66.451218485000084, 28.357307880000064],
            [66.439772000000062, 28.376161000000025],
            [66.42881200000005, 28.403562000000079],
            [66.423332000000073, 28.422743000000025],
            [66.432465000000036, 28.444663000000048],
            [66.439772000000062, 28.462930000000028],
            [66.451646000000039, 28.477544000000023],
            [66.454386000000056, 28.498551000000077],
            [66.470826000000045, 28.514992000000063],
            [66.479046000000039, 28.527779000000066],
            [66.499140000000068, 28.541479000000038],
            [66.510398959000042, 28.548102070000027],
            [66.514667000000031, 28.550613000000055],
            [66.530194000000051, 28.554266000000041],
            [66.553942000000063, 28.55883300000005],
            [66.574949000000061, 28.559746000000075],
            [66.578043702000059, 28.561035553000067],
            [66.585909000000072, 28.564313000000027],
            [66.596869000000083, 28.566140000000075],
            [66.614223000000038, 28.578013000000055],
            [66.63157700000005, 28.597194000000059],
            [66.645277000000078, 28.620941000000073],
            [66.669025000000033, 28.646515000000079],
            [66.679072000000076, 28.674829000000045],
            [66.697339000000056, 28.700403000000051],
            [66.705559000000051, 28.725977000000057],
            [66.71662697000005, 28.742152978000036],
            [66.717433000000028, 28.743331000000069],
            [66.72839300000004, 28.760685000000024],
            [66.743007000000034, 28.784432000000038],
            [66.756707000000063, 28.799959000000058],
            [66.771321000000057, 28.816400000000044],
            [66.787761000000046, 28.825533000000064],
            [66.815162000000043, 28.831927000000064],
            [66.835256000000072, 28.831013000000041],
            [66.850783000000035, 28.830100000000073],
            [66.870877000000064, 28.832840000000033],
            [66.900104000000056, 28.836493000000075],
            [66.939378000000033, 28.837407000000042],
            [66.969519000000048, 28.840147000000059],
            [66.990526000000045, 28.841060000000027],
            [67.016100000000051, 28.84197400000005],
            [67.021293099000047, 28.841541097000061],
            [67.048981000000083, 28.839233000000036],
            [67.075469000000055, 28.841060000000027],
            [67.08968462100006, 28.839966491000041],
            [67.11108900000005, 28.838320000000067],
            [67.128443000000061, 28.841060000000027],
            [67.146632562000036, 28.841926305000072],
            [67.146640661000049, 28.841926691000026],
            [67.16680400000007, 28.842887000000076],
            [67.196032000000059, 28.844714000000067],
            [67.206443835000073, 28.846393043000035],
            [67.224346000000082, 28.849280000000078],
            [67.24809300000004, 28.856587000000047],
            [67.262707000000034, 28.855674000000079],
            [67.265995795000038, 28.857428498000047],
            [67.265995589000056, 28.857427994000034],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "24",
      properties: { name: "Killa Abdullah", count: 837 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.029053000000033, 31.309873000000039],
            [67.04177500000003, 31.305015000000026],
            [67.049638000000073, 31.29715200000004],
            [67.052259000000049, 31.291255000000035],
            [67.053569000000039, 31.280116000000078],
            [67.051604000000054, 31.274219000000073],
            [67.048993000000053, 31.266920000000027],
            [67.037807000000043, 31.264505000000042],
            [67.033652000000075, 31.255672000000061],
            [67.03518500000007, 31.249025000000074],
            [67.034684194000079, 31.243466133000027],
            [67.033913000000041, 31.234906000000024],
            [67.054880000000082, 31.226388000000043],
            [67.069950000000063, 31.225733000000048],
            [67.082739000000061, 31.22652400000004],
            [67.087049079000053, 31.227170491000038],
            [67.092966000000047, 31.228058000000033],
            [67.104076000000077, 31.23515100000003],
            [67.11543400000005, 31.235852000000079],
            [67.129575000000045, 31.237527000000057],
            [67.145300000000077, 31.238182000000052],
            [67.161026000000049, 31.238182000000052],
            [67.171509000000071, 31.236217000000067],
            [67.181427000000042, 31.232655000000079],
            [67.184614000000067, 31.220491000000038],
            [67.195097000000032, 31.215250000000026],
            [67.212133000000051, 31.215250000000026],
            [67.225913000000048, 31.216801000000032],
            [67.236651000000052, 31.216290000000072],
            [67.251446000000044, 31.208697000000029],
            [67.261274000000071, 31.203456000000074],
            [67.25984191200007, 31.198847187000069],
            [67.257768140000053, 31.192173279000031],
            [67.253897000000052, 31.179715000000044],
            [67.250801000000081, 31.16629800000004],
            [67.250801000000081, 31.162555518000033],
            [67.250801000000081, 31.155977000000064],
            [67.254929000000061, 31.146689000000038],
            [67.25699300000008, 31.135336000000052],
            [67.243576000000076, 31.127079000000037],
            [67.231191000000081, 31.118823000000077],
            [67.227240610000081, 31.108726709000052],
            [67.221903000000054, 31.09508500000004],
            [67.215811328000029, 31.076302385000076],
            [67.20951800000006, 31.056898000000047],
            [67.216742000000067, 31.042449000000033],
            [67.217874082000037, 31.037165583000046],
            [67.219838000000038, 31.028000000000077],
            [67.216742000000067, 31.014583000000073],
            [67.210288568000067, 31.01232440800004],
            [67.196101000000056, 31.007359000000065],
            [67.185244582000053, 31.004853051000055],
            [67.182684000000052, 31.00426200000004],
            [67.170299000000057, 30.99910200000005],
            [67.136240000000043, 30.977428000000032],
            [67.133319878000066, 30.975643498000068],
            [67.099086000000057, 30.954723000000058],
            [67.098210166000058, 30.954125855000029],
            [67.076380000000029, 30.939242000000036],
            [67.073676935000037, 30.938662305000037],
            [67.061931000000072, 30.936145000000067],
            [67.053698669000028, 30.948494493000055],
            [67.04179756700006, 30.956448076000072],
            [67.035095215000069, 30.960876465000069],
            [67.030319357000053, 30.964498305000063],
            [67.03009684500006, 30.96466705000006],
            [67.018676758000083, 30.973327637000068],
            [67.001098633000083, 30.973327637000068],
            [67.000525452000034, 30.973235810000062],
            [66.996232387000077, 30.972737247000055],
            [66.984497070000032, 30.971313477000024],
            [66.967219439000075, 30.960720306000042],
            [66.965881348000039, 30.959899902000075],
            [66.964916000000073, 30.935113000000058],
            [66.96229420700007, 30.935267214000078],
            [66.947509766000053, 30.936279297000056],
            [66.934082031000059, 30.921691895000038],
            [66.933397201000048, 30.92155953300005],
            [66.912292480000076, 30.917480469000054],
            [66.905037717000027, 30.91987258000006],
            [66.889709473000039, 30.924926758000026],
            [66.877319336000028, 30.946472168000071],
            [66.862141903000065, 30.950048550000076],
            [66.855517898000073, 30.951625539000077],
            [66.828672181000059, 30.932810066000059],
            [66.827721632000078, 30.932072576000053],
            [66.827697718000081, 30.932054022000045],
            [66.827650000000062, 30.93201700000003],
            [66.827697718000081, 30.931897719000062],
            [66.829665040000066, 30.926980016000073],
            [66.831711342000062, 30.922660546000031],
            [66.844299316000047, 30.914489746000072],
            [66.845275879000042, 30.906311035000044],
            [66.845196000000044, 30.886020440000038],
            [66.845275879000042, 30.884521484000061],
            [66.842671811000059, 30.875232316000051],
            [66.842236436000064, 30.873626573000024],
            [66.838287894000075, 30.854723979000028],
            [66.838076286000046, 30.853698730000076],
            [66.838074090000077, 30.853695191000043],
            [66.824554000000035, 30.83190600000006],
            [66.817374936000078, 30.823589836000053],
            [66.812642911000069, 30.818207758000028],
            [66.812642892000042, 30.818207736000033],
            [66.80391300000008, 30.808168000000023],
            [66.796893719000082, 30.798341278000066],
            [66.793705810000063, 30.79386932400007],
            [66.783260024000072, 30.779022959000031],
            [66.778543432000049, 30.771200810000039],
            [66.771426503000043, 30.759225807000064],
            [66.771426488000031, 30.759225781000055],
            [66.769341428000075, 30.755697277000024],
            [66.769287109000061, 30.755676270000038],
            [66.770965373000081, 30.744774929000073],
            [66.77232101900006, 30.737318548000076],
            [66.772950000000037, 30.733859000000052],
            [66.775909998000031, 30.727552950000074],
            [66.775914190000037, 30.72754401800006],
            [66.776274252000064, 30.726776934000043],
            [66.787088268000048, 30.703738496000028],
            [66.787489467000057, 30.702883771000074],
            [66.792552300000068, 30.692097793000073],
            [66.796760434000078, 30.68315560700006],
            [66.800553469000079, 30.675800483000046],
            [66.806489028000044, 30.664261619000058],
            [66.807185210000057, 30.662770146000071],
            [66.814380607000032, 30.649129566000056],
            [66.818386703000044, 30.641409857000042],
            [66.82627971800008, 30.625541583000029],
            [66.826869070000043, 30.624397545000079],
            [66.829806807000068, 30.61880975400004],
            [66.836914062000062, 30.604919434000067],
            [66.852080593000039, 30.580454078000059],
            [66.85551600000008, 30.574919000000079],
            [66.858682087000034, 30.571119736000071],
            [66.871093750000057, 30.556274414000029],
            [66.899902344000054, 30.515075684000067],
            [66.905277028000057, 30.505493128000069],
            [66.905300313000055, 30.505451614000037],
            [66.913848413000039, 30.490254664000076],
            [66.918473000000063, 30.482033000000058],
            [66.92672900000008, 30.464487000000076],
            [66.924853417000065, 30.464956251000046],
            [66.910216000000048, 30.468616000000054],
            [66.890607000000045, 30.460359000000039],
            [66.873062000000061, 30.456231000000059],
            [66.860677000000067, 30.461391000000049],
            [66.852420000000052, 30.439718000000028],
            [66.842174296000053, 30.426319243000023],
            [66.839003000000048, 30.422172000000046],
            [66.827650000000062, 30.407723000000033],
            [66.830746000000033, 30.391210000000058],
            [66.813201000000049, 30.382954000000041],
            [66.814042631000063, 30.380849990000058],
            [66.819394000000045, 30.367472000000078],
            [66.813201000000049, 30.36024800000007],
            [66.80391300000008, 30.341671000000076],
            [66.80187113900007, 30.326363466000032],
            [66.801848000000064, 30.326190000000054],
            [66.796674446000054, 30.318716531000064],
            [66.792560000000037, 30.31277300000005],
            [66.781207000000052, 30.296259000000077],
            [66.769604039000058, 30.291796656000031],
            [66.767790000000048, 30.291099000000031],
            [66.756437000000062, 30.279746000000046],
            [66.757213269000033, 30.268101211000044],
            [66.757469000000071, 30.264265000000023],
            [66.744052000000067, 30.259105000000034],
            [66.745084000000077, 30.246720000000039],
            [66.741391444000044, 30.238923808000038],
            [66.73579600000005, 30.227110000000039],
            [66.732348412000078, 30.224458206000065],
            [66.722379000000046, 30.21679000000006],
            [66.714122000000032, 30.202341000000047],
            [66.713050903000067, 30.198413298000048],
            [66.711026000000061, 30.190988000000061],
            [66.693230398000082, 30.180310811000027],
            [66.691889438000032, 30.179506248000052],
            [66.690384000000051, 30.178603000000066],
            [66.675935000000038, 30.177571000000057],
            [66.661486000000082, 30.17241000000007],
            [66.648069000000078, 30.168282000000033],
            [66.631556000000046, 30.176539000000048],
            [66.615043000000071, 30.183763000000056],
            [66.602658000000076, 30.195116000000041],
            [66.588209000000063, 30.205437000000074],
            [66.57788800000003, 30.205437000000074],
            [66.567568000000051, 30.202341000000047],
            [66.559311000000037, 30.210597000000064],
            [66.547958000000051, 30.215758000000051],
            [66.538669000000084, 30.215758000000051],
            [66.52628500000003, 30.218854000000078],
            [66.516996000000063, 30.212661000000026],
            [66.519060000000081, 30.204405000000065],
            [66.516996000000063, 30.189956000000052],
            [66.514932000000044, 30.177571000000057],
            [66.510803000000067, 30.164154000000053],
            [66.499451000000079, 30.160026000000073],
            [66.476745000000051, 30.157961000000057],
            [66.453007000000071, 30.156929000000048],
            [66.429270000000031, 30.156929000000048],
            [66.428595274000031, 30.157182033000026],
            [66.412756000000059, 30.163122000000044],
            [66.400372000000061, 30.166218000000072],
            [66.389019000000076, 30.166218000000072],
            [66.38282600000008, 30.17241000000007],
            [66.372506000000044, 30.179635000000076],
            [66.361153000000058, 30.179635000000076],
            [66.354960000000062, 30.179635000000076],
            [66.346704000000045, 30.170346000000052],
            [66.343607000000077, 30.161058000000025],
            [66.33431900000005, 30.161058000000025],
            [66.308517000000052, 30.139384000000064],
            [66.302324000000056, 30.12596700000006],
            [66.290972000000068, 30.116678000000036],
            [66.285811000000081, 30.108422000000076],
            [66.284040652000044, 30.10443828800004],
            [66.281683000000044, 30.099133000000052],
            [66.269298000000049, 30.088812000000075],
            [66.254849000000036, 30.077460000000031],
            [66.245644484000081, 30.069785443000058],
            [66.241096000000027, 30.065993000000049],
            [66.242407000000071, 30.077132000000063],
            [66.24818700000003, 30.087638000000027],
            [66.25753700000007, 30.102270000000033],
            [66.260753000000079, 30.117755000000045],
            [66.27058100000005, 30.136102000000051],
            [66.28754500000008, 30.153797000000054],
            [66.29089300000004, 30.164931000000024],
            [66.288927000000058, 30.189174000000037],
            [66.303400000000067, 30.207485000000077],
            [66.311514000000045, 30.221696000000065],
            [66.31791000000004, 30.231921000000057],
            [66.322832000000062, 30.241232000000025],
            [66.319723000000067, 30.248799000000076],
            [66.321688000000051, 30.260593000000028],
            [66.323654000000033, 30.270422000000053],
            [66.327436000000034, 30.27855900000003],
            [66.330505000000073, 30.287762000000043],
            [66.33172683500004, 30.292648861000032],
            [66.333062000000041, 30.29798900000003],
            [66.33664200000004, 30.306170000000066],
            [66.338069000000075, 30.315632000000051],
            [66.336758000000032, 30.325460000000078],
            [66.336103000000037, 30.333978000000059],
            [66.338069000000075, 30.34249600000004],
            [66.336103000000037, 30.351669000000072],
            [66.335448000000042, 30.365429000000063],
            [66.337413000000083, 30.378533000000061],
            [66.344316000000049, 30.38849300000004],
            [66.35301000000004, 30.400253000000077],
            [66.356415000000084, 30.416536000000065],
            [66.351173000000074, 30.42570900000004],
            [66.344621000000075, 30.438813000000039],
            [66.341345000000047, 30.452573000000029],
            [66.343310000000031, 30.469609000000048],
            [66.342073000000028, 30.483688000000029],
            [66.334277016000044, 30.48528794300006],
            [66.330861000000084, 30.485989000000075],
            [66.329770173000043, 30.486666081000067],
            [66.311860000000081, 30.497783000000027],
            [66.302032000000054, 30.503680000000031],
            [66.298755000000028, 30.518750000000068],
            [66.295479000000057, 30.532510000000059],
            [66.29089300000004, 30.54102800000004],
            [66.286306000000081, 30.549546000000078],
            [66.28565100000003, 30.563960000000066],
            [66.287617000000068, 30.573789000000033],
            [66.292858000000081, 30.590825000000052],
            [66.293598221000082, 30.594820883000068],
            [66.296135000000049, 30.608515000000068],
            [66.307273000000066, 30.633414000000073],
            [66.311205000000029, 30.650450000000035],
            [66.31195090600005, 30.652866998000036],
            [66.316206000000079, 30.666655000000048],
            [66.317210314000079, 30.669281353000031],
            [66.322854000000063, 30.684040000000039],
            [66.328295000000082, 30.704868000000033],
            [66.330861000000084, 30.71924800000005],
            [66.335567794000042, 30.73525124300005],
            [66.337413000000083, 30.741525000000024],
            [66.346959000000084, 30.761451000000079],
            [66.351173000000074, 30.779528000000028],
            [66.355586000000073, 30.796019000000058],
            [66.356001654000067, 30.798302381000042],
            [66.359489000000053, 30.81746000000004],
            [66.360748600000079, 30.821824302000039],
            [66.36712148600003, 30.843905290000066],
            [66.367124000000047, 30.843914000000041],
            [66.370830000000069, 30.862741000000028],
            [66.376557142000081, 30.876402464000023],
            [66.378482000000076, 30.880994000000044],
            [66.380003000000045, 30.896157000000073],
            [66.383279000000073, 30.908606000000077],
            [66.383296268000038, 30.908630531000028],
            [66.38693672800008, 30.91380196800003],
            [66.388672000000042, 30.916267000000062],
            [66.388521000000082, 30.929574000000059],
            [66.395504000000074, 30.936902000000032],
            [66.397439000000077, 30.945103000000074],
            [66.405928000000074, 30.94740100000007],
            [66.412109000000044, 30.945299000000034],
            [66.419971000000032, 30.945299000000034],
            [66.428375480000057, 30.946874239000067],
            [66.430455000000052, 30.947264000000075],
            [66.442249000000061, 30.950541000000044],
            [66.452750000000037, 30.954526000000044],
            [66.460420000000056, 30.956571000000054],
            [66.46867926200008, 30.955919540000025],
            [66.470423000000039, 30.955782000000056],
            [66.478286000000082, 30.957093000000043],
            [66.490080000000034, 30.959058000000027],
            [66.504495000000077, 30.961024000000066],
            [66.514323000000047, 30.961024000000066],
            [66.530048000000079, 30.964955000000032],
            [66.543153000000075, 30.968887000000052],
            [66.55232600000005, 30.968232000000057],
            [66.56115600000004, 30.973441000000037],
            [66.570871000000068, 30.978553000000034],
            [66.581099000000052, 30.984178000000043],
            [66.594394000000079, 30.994915000000049],
            [66.610246000000075, 31.007186000000047],
            [66.622008000000051, 31.015367000000026],
            [66.63226300000008, 31.029167000000029],
            [66.633309280000049, 31.030160982000041],
            [66.645367000000078, 31.041616000000033],
            [66.658471000000077, 31.052100000000053],
            [66.67723600000005, 31.066497000000027],
            [66.685991000000058, 31.076343000000065],
            [66.685991000000058, 31.081647885000052],
            [66.685991000000058, 31.08748200000008],
            [66.690577000000076, 31.099276000000032],
            [66.692578000000083, 31.105868000000044],
            [66.692543000000057, 31.115656000000058],
            [66.699227000000064, 31.124276000000066],
            [66.701716000000033, 31.135968000000048],
            [66.705647000000056, 31.149072000000046],
            [66.71154400000006, 31.159556000000066],
            [66.711635154000078, 31.160011733000033],
            [66.714165000000037, 31.172660000000064],
            [66.71678600000007, 31.18183300000004],
            [66.719407000000047, 31.194938000000036],
            [66.725821000000053, 31.203530000000057],
            [66.730935000000045, 31.208643000000052],
            [66.739116000000081, 31.207109000000059],
            [66.745764000000065, 31.208131000000037],
            [66.75510739300006, 31.206176647000063],
            [66.758720000000039, 31.205421000000058],
            [66.776411000000053, 31.20607700000005],
            [66.795364000000063, 31.211708000000044],
            [66.815307000000075, 31.223979000000043],
            [66.829626000000076, 31.246477000000027],
            [66.841388000000052, 31.263350000000059],
            [66.859797000000071, 31.274598000000026],
            [66.876671000000044, 31.280734000000052],
            [66.876985442000034, 31.280902421000064],
            [66.890989000000047, 31.288403000000073],
            [66.90888700000005, 31.294538000000045],
            [66.918602000000078, 31.298628000000065],
            [66.934966000000031, 31.301695000000052],
            [66.950306000000069, 31.305274000000054],
            [66.955799160000083, 31.30771500700007],
            [66.95951000000008, 31.309364000000073],
            [66.984116000000029, 31.308946000000049],
            [66.996565000000032, 31.308946000000049],
            [67.013601000000051, 31.308291000000054],
            [67.029053000000033, 31.309873000000039],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "25",
      properties: { name: "Killa Saifullah", count: 725 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.970836242000075, 31.517586810000068],
            [67.977587000000028, 31.515250000000037],
            [68.022123000000079, 31.49298200000004],
            [68.066659000000072, 31.458467000000041],
            [68.125668000000076, 31.408364000000063],
            [68.155730000000062, 31.381643000000054],
            [68.17688400000003, 31.359375000000057],
            [68.206946000000073, 31.342674000000045],
            [68.244801000000052, 31.320406000000048],
            [68.281543000000056, 31.309272000000078],
            [68.308265000000063, 31.301479000000029],
            [68.340553000000057, 31.293685000000039],
            [68.363934000000029, 31.284778000000074],
            [68.398223429000041, 31.281752596000047],
            [68.401789000000065, 31.281438000000037],
            [68.434078000000056, 31.298138000000051],
            [68.438954779000028, 31.300516937000054],
            [68.479727000000082, 31.320406000000048],
            [68.515355000000056, 31.332654000000048],
            [68.536510000000078, 31.338221000000033],
            [68.565458000000035, 31.346014000000025],
            [68.582159000000047, 31.353808000000072],
            [68.605886009000074, 31.345690939000065],
            [68.607190417000083, 31.345244698000045],
            [68.624468000000036, 31.339334000000065],
            [68.650348171000076, 31.328790291000075],
            [68.65049631100004, 31.328729938000038],
            [68.654529000000082, 31.327087000000063],
            [68.660096000000067, 31.310386000000051],
            [68.658983000000035, 31.279211000000032],
            [68.655643000000055, 31.24692200000004],
            [68.667890000000057, 31.236902000000043],
            [68.676797000000079, 31.223541000000068],
            [68.684591000000069, 31.203500000000076],
            [68.675013628000045, 31.192965234000042],
            [68.673457000000042, 31.191253000000074],
            [68.670154779000029, 31.172539093000069],
            [68.670117000000062, 31.172325000000058],
            [68.671157525000069, 31.16994677200006],
            [68.672876780000081, 31.166017235000027],
            [68.677911000000051, 31.15451100000007],
            [68.689044000000081, 31.145604000000048],
            [68.690158000000054, 31.122223000000076],
            [68.693498000000034, 31.104408000000035],
            [68.704632000000061, 31.097728000000075],
            [68.723560000000077, 31.09550100000007],
            [68.743601000000069, 31.097728000000075],
            [68.747340314000041, 31.099805417000027],
            [68.74801935000005, 31.100182663000055],
            [68.761935372000039, 31.107913864000068],
            [68.763642000000061, 31.108862000000045],
            [68.778116000000068, 31.121109000000047],
            [68.789250000000038, 31.133357000000046],
            [68.800384000000065, 31.146717000000024],
            [68.817084000000079, 31.146717000000024],
            [68.828218000000049, 31.146717000000024],
            [68.846033000000034, 31.147831000000053],
            [68.851932006000084, 31.143746853000039],
            [68.860507000000041, 31.137810000000059],
            [68.87943400000006, 31.134470000000078],
            [68.905042000000037, 31.140037000000063],
            [68.923970000000054, 31.134470000000078],
            [68.925083000000029, 31.121109000000047],
            [68.926197000000059, 31.11442900000003],
            [68.938444000000061, 31.109975000000077],
            [68.957372000000078, 31.112202000000025],
            [68.977694992000067, 31.126107407000063],
            [68.978526000000045, 31.126676000000032],
            [68.994114000000081, 31.141150000000039],
            [68.994823962000055, 31.138309991000028],
            [68.996167270000058, 31.132936097000027],
            [68.997874241000034, 31.126107371000046],
            [68.998567000000037, 31.123336000000052],
            [69.009701000000064, 31.116656000000035],
            [69.020835000000034, 31.127790000000061],
            [69.040876000000083, 31.136697000000026],
            [69.063144000000079, 31.134470000000078],
            [69.075391000000081, 31.131130000000041],
            [69.088752000000056, 31.141150000000039],
            [69.103226000000063, 31.142264000000068],
            [69.126607000000035, 31.128903000000037],
            [69.147762000000057, 31.112202000000025],
            [69.163349000000039, 31.09995500000008],
            [69.181163000000083, 31.093274000000065],
            [69.20343100000008, 31.091048000000058],
            [69.216600652000068, 31.087754848000031],
            [69.216792000000055, 31.087707000000023],
            [69.216904541000076, 31.087522837000051],
            [69.216905268000062, 31.087521648000063],
            [69.229039000000057, 31.067666000000031],
            [69.247967000000074, 31.046512000000064],
            [69.262441000000081, 31.032038000000057],
            [69.27802900000006, 31.023131000000035],
            [69.293749613000045, 31.021476003000032],
            [69.299183000000028, 31.02090400000003],
            [69.299314002000074, 31.020940390000078],
            [69.317025934000071, 31.025860420000072],
            [69.320554179000055, 31.027547841000057],
            [69.325861886000041, 31.031844676000048],
            [69.342605000000049, 31.045399000000032],
            [69.361533000000065, 31.059873000000039],
            [69.379347000000053, 31.059873000000039],
            [69.379780985000082, 31.051631581000038],
            [69.380461000000082, 31.038718000000074],
            [69.364873000000046, 31.01422400000007],
            [69.351685269000029, 30.999277747000065],
            [69.348172000000034, 30.995296000000053],
            [69.327018000000066, 30.988616000000036],
            [69.30809000000005, 30.985275000000058],
            [69.299183000000028, 30.964121000000034],
            [69.284709000000078, 30.942967000000067],
            [69.285441521000052, 30.939304173000039],
            [69.288043303000052, 30.926294486000074],
            [69.288044212000045, 30.926289940000061],
            [69.288049000000058, 30.926266000000055],
            [69.285822000000053, 30.908451000000071],
            [69.275802000000056, 30.878390000000024],
            [69.261328000000049, 30.85723500000006],
            [69.254647000000034, 30.836081000000036],
            [69.254543228000045, 30.836033104000023],
            [69.225699000000077, 30.822720000000061],
            [69.20127315600007, 30.816778818000046],
            [69.184504000000061, 30.812700000000063],
            [69.177577276000079, 30.810789247000059],
            [69.152215000000069, 30.803793000000042],
            [69.143549008000036, 30.798460128000045],
            [69.137741000000062, 30.794886000000076],
            [69.106566000000043, 30.773731000000055],
            [69.10236506900003, 30.772155815000076],
            [69.079845000000034, 30.763711000000058],
            [69.05868999900008, 30.752577000000031],
            [69.072051000000044, 30.731422000000066],
            [69.082072000000039, 30.708041000000037],
            [69.084298000000047, 30.69022700000005],
            [69.095432000000073, 30.691340000000025],
            [69.109380206000083, 30.694827248000024],
            [69.113247000000058, 30.695794000000035],
            [69.127721000000065, 30.700247000000047],
            [69.139968000000067, 30.696907000000067],
            [69.149988000000064, 30.684660000000065],
            [69.154655000000048, 30.684077000000059],
            [69.154654896000068, 30.684076731000062],
            [69.154654849000053, 30.684076611000023],
            [69.153946000000076, 30.682255000000055],
            [69.155577000000051, 30.678992000000051],
            [69.155616000000066, 30.678915000000075],
            [69.158074000000056, 30.673999000000038],
            [69.158074000000056, 30.664710000000071],
            [69.151513525000041, 30.662960653000027],
            [69.142593000000034, 30.660582000000034],
            [69.140356160000067, 30.660147034000033],
            [69.125887969000075, 30.657333612000059],
            [69.105438000000049, 30.653357000000028],
            [69.088682832000075, 30.643304029000035],
            [69.07963600000005, 30.637876000000063],
            [69.069442805000051, 30.62686741300007],
            [69.053834000000052, 30.610010000000045],
            [69.042482000000064, 30.59865700000006],
            [69.039561647000028, 30.597822602000065],
            [69.006359000000032, 30.588336000000027],
            [69.003861079000046, 30.587654739000072],
            [68.97620865500005, 30.580113058000052],
            [68.96094800000003, 30.575951000000032],
            [68.958939850000036, 30.575393241000029],
            [68.942370000000039, 30.570791000000042],
            [68.926889000000074, 30.558406000000048],
            [68.902119000000084, 30.543957000000034],
            [68.850098756000079, 30.521662381000056],
            [68.83709900000008, 30.516091000000074],
            [68.806347929000083, 30.508403046000069],
            [68.795816000000059, 30.505770000000041],
            [68.764854000000071, 30.50680200000005],
            [68.758661000000075, 30.525380000000041],
            [68.750810717000036, 30.538463242000034],
            [68.749372000000051, 30.540861001000053],
            [68.717378000000053, 30.552214000000049],
            [68.715431206000062, 30.553697293000027],
            [68.697705535000068, 30.567202760000043],
            [68.695705000000032, 30.568727000000024],
            [68.678159000000051, 30.564599000000044],
            [68.671209303000069, 30.566109740000059],
            [68.67104789800004, 30.566144826000027],
            [68.654422000000068, 30.569759000000033],
            [68.63171600000004, 30.571823000000052],
            [68.603850000000079, 30.566663000000062],
            [68.585924609000074, 30.557699871000068],
            [68.583209000000068, 30.556342000000029],
            [68.530573000000061, 30.555310000000077],
            [68.528701935000072, 30.555243181000037],
            [68.501675000000034, 30.554278000000068],
            [68.475873000000036, 30.543957000000034],
            [68.468650424000032, 30.535703769000065],
            [68.46864800000003, 30.535701000000074],
            [68.446975000000066, 30.537765000000036],
            [68.433558000000062, 30.554278000000068],
            [68.408788000000072, 30.562534500000027],
            [68.400791755000057, 30.571102143000076],
            [68.400513253000042, 30.571198935000041],
            [68.400514618000045, 30.571399084000063],
            [68.394339000000059, 30.578016000000048],
            [68.380969027000049, 30.580782017000047],
            [68.380186145000039, 30.580943982000065],
            [68.36440900000008, 30.584208000000046],
            [68.356285719000084, 30.580823354000074],
            [68.327254000000039, 30.568727000000024],
            [68.292138087000069, 30.555906811000057],
            [68.262233000000037, 30.544989000000044],
            [68.22611100000006, 30.547053000000062],
            [68.225305381000055, 30.547254416000044],
            [68.184828000000039, 30.557374000000038],
            [68.179265020000059, 30.566209451000077],
            [68.174159236000037, 30.574318758000061],
            [68.167283000000054, 30.585240000000056],
            [68.148560000000032, 30.606772000000035],
            [68.146641000000045, 30.608978000000036],
            [68.105358000000081, 30.611042000000054],
            [68.054806049000035, 30.606965484000057],
            [68.041370000000029, 30.605882000000065],
            [68.029724196000075, 30.604985802000044],
            [68.014536000000078, 30.603817000000049],
            [67.99183000000005, 30.584208001000036],
            [67.989364608000074, 30.585194174000037],
            [67.960868000000062, 30.596593000000041],
            [67.949515000000076, 30.619299000000069],
            [67.946656852000046, 30.628912426000056],
            [67.940200043000061, 30.650630010000043],
            [67.938162000000034, 30.657485000000065],
            [67.931265039000039, 30.655186013000048],
            [67.924610818000076, 30.652967939000064],
            [67.904972331000067, 30.646421777000057],
            [67.90100700000005, 30.64510000000007],
            [67.857660000000067, 30.630651000000057],
            [67.82363063300005, 30.617456049000054],
            [67.818726604000062, 30.615554503000055],
            [67.818393551000042, 30.615425361000064],
            [67.807089000000076, 30.611042000000054],
            [67.802296098000056, 30.611798716000067],
            [67.767870000000073, 30.617234000000053],
            [67.758020017000035, 30.618993145000047],
            [67.750850539000055, 30.620273568000073],
            [67.74558320400007, 30.62121428100005],
            [67.738972000000047, 30.62239500000004],
            [67.693916000000058, 30.616518000000042],
            [67.692798553000046, 30.61709443500007],
            [67.692528000000038, 30.617234000000053],
            [67.692528000000038, 30.626523000000077],
            [67.686279297000056, 30.636901855000076],
            [67.686692172000051, 30.640762242000051],
            [67.687368000000049, 30.648197000000039],
            [67.684272000000078, 30.659549000000027],
            [67.676015000000064, 30.66987000000006],
            [67.667759000000046, 30.680191000000036],
            [67.650213000000065, 30.685351000000026],
            [67.638582689000032, 30.68128058800005],
            [67.63817527100008, 30.681137999000043],
            [67.629572000000053, 30.678127000000075],
            [67.599642000000074, 30.679159000000027],
            [67.579746808000039, 30.674185069000032],
            [67.571615790000067, 30.672152259000029],
            [67.569750520000071, 30.671685928000045],
            [67.562487000000033, 30.66987000000006],
            [67.560776729000054, 30.669695496000031],
            [67.511915000000045, 30.664710000000071],
            [67.483017000000075, 30.671934000000078],
            [67.478889000000038, 30.69360800000004],
            [67.472746121000057, 30.703163223000047],
            [67.46960966100005, 30.708041973000036],
            [67.469609263000052, 30.708042591000037],
            [67.469600000000071, 30.708057000000053],
            [67.46932621600007, 30.708042591000037],
            [67.449991000000068, 30.707025000000044],
            [67.431414000000075, 30.701865000000055],
            [67.432446000000084, 30.717346000000077],
            [67.451023000000077, 30.723538000000076],
            [67.461344000000054, 30.733859000000052],
            [67.462359663000029, 30.736906236000038],
            [67.465472000000034, 30.746244000000047],
            [67.467536000000052, 30.762757000000079],
            [67.468774608000047, 30.76492445100007],
            [67.471799852000061, 30.770218354000065],
            [67.474409170000058, 30.774784422000039],
            [67.474651054000049, 30.775207698000031],
            [67.475793000000067, 30.777206000000035],
            [67.484049000000084, 30.788559000000078],
            [67.485146947000032, 30.794047316000047],
            [67.487146000000052, 30.804040000000043],
            [67.47166500000003, 30.817457000000047],
            [67.458248000000083, 30.817457000000047],
            [67.458191492000083, 30.817457000000047],
            [67.443374667000057, 30.823960831000079],
            [67.441734000000054, 30.824681000000055],
            [67.447022932000039, 30.837902047000057],
            [67.44792700000005, 30.840162000000078],
            [67.456183000000067, 30.850483000000054],
            [67.464440000000081, 30.854612000000031],
            [67.470633000000078, 30.866996000000029],
            [67.471712428000046, 30.867494278000038],
            [67.484049000000084, 30.873189000000025],
            [67.505723000000046, 30.873189000000025],
            [67.526365000000055, 30.880413000000033],
            [67.547006000000067, 30.886606000000029],
            [67.572808000000066, 30.896927000000062],
            [67.589321000000041, 30.900023000000033],
            [67.601706000000036, 30.911376000000075],
            [67.614469573000065, 30.919884590000038],
            [67.620283000000029, 30.923760000000073],
            [67.63014127100007, 30.939685181000073],
            [67.633700000000033, 30.945434000000034],
            [67.643699798000057, 30.953291132000061],
            [67.64445777800006, 30.953886699000066],
            [67.648149000000046, 30.956787000000077],
            [67.669823000000065, 30.97536400000007],
            [67.672923896000043, 30.977322518000051],
            [67.689432000000068, 30.987749001000054],
            [67.68943207600006, 30.987749054000062],
            [67.720394000000056, 31.009423000000027],
            [67.727454312000077, 31.013915842000074],
            [67.743100000000084, 31.02387200000004],
            [67.751904813000067, 31.041480684000078],
            [67.752389000000051, 31.042449006000027],
            [67.764774000000045, 31.055866005000041],
            [67.755485000000078, 31.064123006000045],
            [67.754453000000069, 31.073411006000072],
            [67.761677000000077, 31.079604005000078],
            [67.773030000000062, 31.078572005000069],
            [67.786447000000067, 31.08166800500004],
            [67.796768000000043, 31.089922727000044],
            [67.806057000000067, 31.099213003000045],
            [67.814313000000084, 31.116756728000041],
            [67.823602000000051, 31.143592000000069],
            [67.815345000000036, 31.144624000000078],
            [67.787479000000076, 31.144624000000078],
            [67.781949131000033, 31.144411329000036],
            [67.760645000000068, 31.143592000000069],
            [67.737940000000037, 31.14256000000006],
            [67.721427147000043, 31.143591991000051],
            [67.721427001000052, 31.143592002000048],
            [67.691496000000029, 31.146689000000038],
            [67.676015000000064, 31.146689000000038],
            [67.658470000000079, 31.149785000000065],
            [67.645053000000075, 31.157009000000073],
            [67.634832553000081, 31.159280638000041],
            [67.626476000000082, 31.161138000000051],
            [67.608930000000043, 31.153913000000045],
            [67.59035300000005, 31.153911727000036],
            [67.584161000000051, 31.168360728000039],
            [67.579000000000065, 31.183843000000024],
            [67.57832619800007, 31.184404511000025],
            [67.56661500000007, 31.194164000000058],
            [67.553198000000066, 31.206549000000052],
            [67.535653000000082, 31.207581000000062],
            [67.52122746100008, 31.206062600000052],
            [67.516044000000079, 31.205517000000043],
            [67.500563000000056, 31.203453000000025],
            [67.477857000000029, 31.202421000000072],
            [67.467536000000052, 31.206549000000052],
            [67.457216000000074, 31.220998000000066],
            [67.451440000000048, 31.233617000000038],
            [67.461240000000032, 31.237082000000044],
            [67.474419000000069, 31.23519900000008],
            [67.494361000000083, 31.23775500000005],
            [67.508948000000032, 31.246045000000038],
            [67.530570000000068, 31.255873000000065],
            [67.546006000000034, 31.260763000000054],
            [67.556744000000037, 31.262808000000064],
            [67.572084000000075, 31.266387000000066],
            [67.588446000000033, 31.270989000000043],
            [67.598115596000071, 31.270988650000049],
            [67.616058000000066, 31.270988000000045],
            [67.634977000000049, 31.278146000000049],
            [67.647250000000042, 31.283770000000061],
            [67.667703000000074, 31.291439000000025],
            [67.686111000000039, 31.30524500000007],
            [67.69889500000005, 31.313425000000052],
            [67.713212000000055, 31.317516000000069],
            [67.727232000000072, 31.320901000000049],
            [67.741336000000047, 31.322117000000048],
            [67.754563556000051, 31.322851425000067],
            [67.759743000000071, 31.323139000000026],
            [67.776106000000084, 31.33029700000003],
            [67.78676100000007, 31.339742000000058],
            [67.790037000000041, 31.351535000000069],
            [67.788072000000057, 31.364640000000065],
            [67.786106000000075, 31.373813000000041],
            [67.782175000000052, 31.384296000000063],
            [67.774967000000061, 31.391504000000054],
            [67.77201800000006, 31.398303000000055],
            [67.763326000000063, 31.405462000000057],
            [67.755459935000033, 31.408411895000029],
            [67.755145000000084, 31.408530000000042],
            [67.747986000000083, 31.410576000000049],
            [67.740828000000079, 31.409553000000074],
            [67.732646000000045, 31.410065000000031],
            [67.719352000000072, 31.405463000000054],
            [67.706584000000078, 31.404945000000055],
            [67.694375000000036, 31.402643000000069],
            [67.679482000000064, 31.400344000000075],
            [67.671059003000039, 31.396754183000041],
            [67.664890000000071, 31.394125000000031],
            [67.641957000000048, 31.391504000000054],
            [67.630163000000039, 31.404608000000053],
            [67.62230100000005, 31.41116100000005],
            [67.60890500000005, 31.415693000000033],
            [67.598058000000037, 31.429507000000058],
            [67.599368000000084, 31.444577000000038],
            [67.601334000000065, 31.457026000000042],
            [67.593471000000079, 31.469475000000045],
            [67.587574000000075, 31.48061400000006],
            [67.579056000000037, 31.496994000000029],
            [67.560055000000034, 31.514685000000043],
            [67.554813000000081, 31.528445000000033],
            [67.580594000000076, 31.531081000000029],
            [67.598668000000032, 31.529824000000076],
            [67.620200000000068, 31.530304000000058],
            [67.632430000000056, 31.525627000000043],
            [67.64316800000006, 31.522048000000041],
            [67.651860000000056, 31.513866000000064],
            [67.653151768000043, 31.514022553000075],
            [67.668734000000029, 31.515911000000074],
            [67.682029000000057, 31.515399000000059],
            [67.700437000000079, 31.517444000000069],
            [67.715896539000028, 31.516223468000078],
            [67.719867000000079, 31.515910000000076],
            [67.734696000000042, 31.523068000000023],
            [67.749014000000045, 31.532271000000037],
            [67.763314000000037, 31.550839000000053],
            [67.765042805000064, 31.551859742000033],
            [67.781228000000056, 31.561416000000065],
            [67.79912500000006, 31.572665000000029],
            [67.813443000000063, 31.582891000000075],
            [67.82162500000004, 31.594651000000056],
            [67.835073000000079, 31.577600000000075],
            [67.868475000000046, 31.557559000000026],
            [67.919691000000057, 31.535291000000029],
            [67.970836242000075, 31.517586810000068],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "26",
      properties: { name: "Kohlu", count: 413 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.494339000000082, 30.045614000000057],
            [69.494035960000076, 30.040794494000068],
            [69.493968598000038, 30.039683106000041],
            [69.493685205000077, 30.035007490000055],
            [69.493591000000038, 30.033637000000056],
            [69.489100001000054, 30.026900000000069],
            [69.482363000000078, 30.022409000000039],
            [69.474128000000064, 30.013426001000028],
            [69.47787100000005, 30.007438000000036],
            [69.483111000000065, 30.00070099900006],
            [69.479368000000079, 29.991718000000049],
            [69.469637000000034, 29.982735000000048],
            [69.460654000000034, 29.971507000000031],
            [69.453839456000082, 29.959921820000034],
            [69.453188401000034, 29.958818195000049],
            [69.453188538000063, 29.958815216000062],
            [69.453189743000053, 29.958789016000026],
            [69.440443001000062, 29.949799000000041],
            [69.429215000000056, 29.936325000000068],
            [69.420232000000055, 29.931834000000038],
            [69.407507000000066, 29.928091001000041],
            [69.403016000000036, 29.919108000000051],
            [69.40077000000008, 29.907880001000024],
            [69.395530000000065, 29.895903000000033],
            [69.391787000000079, 29.889166000000046],
            [69.370828000000074, 29.888417000000061],
            [69.360722062000036, 29.88793613200005],
            [69.35780425300004, 29.887797294000052],
            [69.355108001000076, 29.887669000000074],
            [69.350392665000072, 29.886725996000052],
            [69.347230026000034, 29.88609351100007],
            [69.340137000000084, 29.884675000000072],
            [69.334897000000069, 29.875692000000072],
            [69.332868189000067, 29.874050429000079],
            [69.327389655000047, 29.869681655000079],
            [69.312417655000047, 29.869681655000079],
            [69.298943655000073, 29.865938655000036],
            [69.295011518000081, 29.864702713000042],
            [69.287355636000029, 29.86229632800007],
            [69.287353886000062, 29.862311099000067],
            [69.282498000000032, 29.860721000000069],
            [69.273752021000064, 29.85588866300003],
            [69.265281000000073, 29.85024100000004],
            [69.264646255000059, 29.849979619000067],
            [69.260687851000057, 29.848349597000038],
            [69.254721333000077, 29.845892152000033],
            [69.252556000000084, 29.845001000000025],
            [69.248201237000046, 29.842213942000058],
            [69.241214993000028, 29.837742731000048],
            [69.233842000000038, 29.833024000000023],
            [69.219570383000075, 29.826907671000072],
            [69.207643000000076, 29.821796000000063],
            [69.195666000000074, 29.812813000000062],
            [69.181443000000058, 29.805328000000031],
            [69.179253566000057, 29.803138629000046],
            [69.173209000000043, 29.797094000000072],
            [69.16272900000007, 29.790357000000029],
            [69.158238001000029, 29.779877000000056],
            [69.152998000000082, 29.770894000000055],
            [69.144764000000066, 29.766403000000025],
            [69.132039000000077, 29.763409000000024],
            [69.119313000000034, 29.758169000000066],
            [69.105091000000073, 29.752180000000067],
            [69.086377000000084, 29.747689000000037],
            [69.076645000000042, 29.739455000000078],
            [69.066914000000054, 29.733466000000078],
            [69.068484000000069, 29.724569000000031],
            [69.069160000000068, 29.720741000000032],
            [69.08413100000007, 29.707267000000058],
            [69.089371000000028, 29.687805000000026],
            [69.103593000000046, 29.677325000000053],
            [69.109656530000052, 29.675051260000032],
            [69.152250000000038, 29.659359000000052],
            [69.160948329000064, 29.652551305000031],
            [69.167848113000048, 29.647151230000077],
            [69.169466000000057, 29.645885000000078],
            [69.180695000000071, 29.635406000000046],
            [69.209888000000035, 29.624926000000073],
            [69.21226428600005, 29.623935859000028],
            [69.227854000000036, 29.617440000000045],
            [69.251807000000042, 29.611452001000032],
            [69.269279883000081, 29.610991929000079],
            [69.280253000000073, 29.610703000000058],
            [69.294223842000065, 29.614451320000057],
            [69.310943000000066, 29.618937000000074],
            [69.320940049000058, 29.622910274000049],
            [69.329230155000062, 29.626205133000042],
            [69.34231279200003, 29.631404758000031],
            [69.357926472000031, 29.637610331000076],
            [69.369331000000045, 29.642143000000033],
            [69.387296000000049, 29.651125000000036],
            [69.412747000000081, 29.654868000000079],
            [69.42682717100007, 29.66648407100007],
            [69.437610789000075, 29.675380502000053],
            [69.44268900000003, 29.679570000000069],
            [69.467391000000077, 29.689302000000055],
            [69.475626000000034, 29.694542000000069],
            [69.481731272000047, 29.695813691000069],
            [69.486887687000035, 29.696887741000069],
            [69.494247279000035, 29.698393385000031],
            [69.517319394000083, 29.702208619000032],
            [69.537007000000074, 29.705021000000045],
            [69.563955000000078, 29.705021000000045],
            [69.596892000000082, 29.703524000000073],
            [69.61785100000003, 29.696039000000042],
            [69.651364000000058, 29.684582000000034],
            [69.64178000000004, 29.678482000000031],
            [69.63509749800005, 29.669765735000055],
            [69.631967732000078, 29.665683450000074],
            [69.631967363000058, 29.66568372100005],
            [69.622781964000069, 29.669292333000044],
            [69.611863000000028, 29.673582000000067],
            [69.58716000000004, 29.67807300000004],
            [69.568446000000051, 29.677325000000053],
            [69.551978000000076, 29.675828000000024],
            [69.533264000000031, 29.666096000000039],
            [69.516796000000056, 29.655617000000063],
            [69.498831000000052, 29.642891000000077],
            [69.477123000000063, 29.627920000000074],
            [69.459906000000046, 29.613698000000056],
            [69.44718000000006, 29.598726000000056],
            [69.435952000000043, 29.580013000000065],
            [69.430712000000028, 29.571030000000064],
            [69.429215000000056, 29.559802000000047],
            [69.429215000000056, 29.548573000000033],
            [69.433706000000029, 29.53809300000006],
            [69.446432000000073, 29.527614000000028],
            [69.46215200000006, 29.520877000000041],
            [69.473380000000077, 29.519380000000069],
            [69.483111000000065, 29.519380000000069],
            [69.495837000000051, 29.518631000000028],
            [69.503322000000082, 29.514140000000054],
            [69.507065000000068, 29.50890000000004],
            [69.507065000000068, 29.494176168000024],
            [69.507065000000068, 29.493929000000037],
            [69.501825000000053, 29.482700000000079],
            [69.493591000000038, 29.470723000000078],
            [69.48386000000005, 29.460244000000046],
            [69.471134000000063, 29.44602100000003],
            [69.45616300000006, 29.43703800000003],
            [69.445683000000031, 29.429553000000055],
            [69.430712000000028, 29.425062000000025],
            [69.40975300000008, 29.416827000000069],
            [69.369331000000045, 29.39961100000005],
            [69.367919020000045, 29.398070580000024],
            [69.367883714000072, 29.398032062000027],
            [69.361097000000029, 29.390628000000049],
            [69.350617000000057, 29.375657000000047],
            [69.339388000000042, 29.366674000000046],
            [69.328160000000082, 29.359937000000059],
            [69.313938000000064, 29.353949000000057],
            [69.301961000000063, 29.352452000000028],
            [69.289235000000076, 29.348709000000042],
            [69.278007000000059, 29.348709000000042],
            [69.263784000000044, 29.345715000000041],
            [69.251059000000055, 29.337481000000025],
            [69.242076000000054, 29.323258000000067],
            [69.236088000000052, 29.315024000000051],
            [69.225608000000079, 29.301550000000077],
            [69.212883000000033, 29.283585000000073],
            [69.204648000000077, 29.268613000000073],
            [69.188180000000045, 29.262625000000071],
            [69.175455000000056, 29.258134000000041],
            [69.165724000000068, 29.256637000000069],
            [69.14102100000008, 29.248402000000056],
            [69.111079000000075, 29.251397000000054],
            [69.097605000000044, 29.251397000000054],
            [69.08413100000007, 29.25364200000007],
            [69.065417000000082, 29.25963100000007],
            [69.044458000000077, 29.261876000000029],
            [69.031732000000034, 29.264122000000043],
            [69.016761000000031, 29.261876000000029],
            [69.001790000000028, 29.260379000000057],
            [68.990562000000068, 29.252894000000026],
            [68.976339000000053, 29.24016800000004],
            [68.960619000000065, 29.235677000000067],
            [68.945648000000062, 29.227443000000051],
            [68.929929000000072, 29.225946000000079],
            [68.917952000000071, 29.22444900000005],
            [68.899238000000082, 29.229689000000064],
            [68.886512000000039, 29.231186000000037],
            [68.859564000000034, 29.239420000000052],
            [68.844593000000032, 29.243911000000026],
            [68.828125000000057, 29.246157000000039],
            [68.803423000000066, 29.24915100000004],
            [68.784709000000078, 29.25364200000007],
            [68.757012000000032, 29.257385000000056],
            [68.74279000000007, 29.256637000000069],
            [68.716590000000053, 29.262625000000071],
            [68.696379000000036, 29.264122000000043],
            [68.674671000000046, 29.266368000000057],
            [68.659700000000043, 29.267116000000044],
            [68.646975000000054, 29.267116000000044],
            [68.629009000000053, 29.260379000000057],
            [68.617781000000036, 29.257385000000056],
            [68.596073000000047, 29.252145000000041],
            [68.566879000000029, 29.243911000000026],
            [68.516726000000062, 29.231186000000037],
            [68.484347390000039, 29.22223002000004],
            [68.481988152000042, 29.22157745100003],
            [68.481988728000033, 29.221577020000041],
            [68.481544000000042, 29.221454000000051],
            [68.480609805000029, 29.22149464000006],
            [68.48061000000007, 29.221495000000061],
            [68.464327000000083, 29.222203000000036],
            [68.44486500000005, 29.222952000000078],
            [68.418665000000033, 29.232683000000065],
            [68.39246600000007, 29.243911000000026],
            [68.353541000000064, 29.260379000000057],
            [68.333711050000034, 29.269302544000027],
            [68.333710964000034, 29.269302582000023],
            [68.323599000000058, 29.273853000000031],
            [68.297399000000041, 29.291819000000032],
            [68.293611646000045, 29.294368130000066],
            [68.258474000000035, 29.318018000000052],
            [68.247407877000057, 29.325290241000062],
            [68.232275000000072, 29.335235000000068],
            [68.223230027000056, 29.340595034000046],
            [68.212064000000055, 29.34721200000007],
            [68.179876000000036, 29.371914000000061],
            [68.179665000000057, 29.37173400000006],
            [68.17241800000005, 29.38139700000005],
            [68.162609000000032, 29.392607000000055],
            [68.15280000000007, 29.401015000000029],
            [68.15280000000007, 29.408722000000068],
            [68.142991000000052, 29.412225000000035],
            [68.138787000000036, 29.408021000000076],
            [68.134583000000077, 29.41152500000004],
            [68.128978000000075, 29.41853100000003],
            [68.124073000000067, 29.41853100000003],
            [68.119169000000056, 29.414327000000071],
            [68.107258000000058, 29.415729000000056],
            [68.102353000000051, 29.422034000000053],
            [68.105856000000074, 29.431843000000072],
            [68.112162000000069, 29.437449000000072],
            [68.127576000000033, 29.454264000000023],
            [68.129356976000054, 29.457825953000054],
            [68.142289000000062, 29.483690000000024],
            [68.14229000000006, 29.483692000000076],
            [68.143009023000047, 29.485037256000055],
            [68.162120232000063, 29.520793341000058],
            [68.164010000000076, 29.524329000000023],
            [68.178724000000045, 29.55445700000007],
            [68.197641000000033, 29.588088000000027],
            [68.197666439000045, 29.588088979000077],
            [68.215858000000082, 29.588789000000077],
            [68.230572000000052, 29.591592000000048],
            [68.24248300000005, 29.59929900000003],
            [68.248088000000052, 29.609108000000049],
            [68.262802000000079, 29.628025000000036],
            [68.27961700000003, 29.629427000000078],
            [68.296433000000036, 29.625923000000057],
            [68.322357000000068, 29.627325000000042],
            [68.344434109000076, 29.625362295000059],
            [68.353886000000045, 29.62452200000007],
            [68.388919000000044, 29.621720000000039],
            [68.41274100000004, 29.620318000000054],
            [68.436563000000035, 29.62452200000007],
            [68.44286900000003, 29.632229000000052],
            [68.450576000000069, 29.644841000000042],
            [68.457582000000059, 29.654650000000061],
            [68.46809200000007, 29.662357000000043],
            [68.487010000000055, 29.66936400000003],
            [68.49841957600006, 29.672511334000035],
            [68.507329000000084, 29.674969000000033],
            [68.529749000000038, 29.686179000000038],
            [68.543762000000072, 29.69458700000007],
            [68.554272000000083, 29.701594000000057],
            [68.560578000000078, 29.708600000000047],
            [68.566884000000073, 29.71770900000007],
            [68.569686000000047, 29.72681700000004],
            [68.575292000000047, 29.740129000000024],
            [68.571088000000032, 29.748537000000056],
            [68.566884000000073, 29.758346000000074],
            [68.564782000000037, 29.769557000000077],
            [68.567584000000068, 29.783570000000054],
            [68.576693000000034, 29.80529000000007],
            [68.578795000000071, 29.81439800000004],
            [68.574591000000055, 29.825609000000043],
            [68.55567300000007, 29.831915000000038],
            [68.538157000000069, 29.838921000000028],
            [68.524845000000028, 29.841724000000056],
            [68.508029000000079, 29.849431000000038],
            [68.492615000000058, 29.855737000000033],
            [68.472997000000078, 29.866947000000039],
            [68.456181000000072, 29.873953000000029],
            [68.432359000000076, 29.887966000000063],
            [68.413441000000034, 29.897776000000079],
            [68.403865574000065, 29.910343640000065],
            [68.402231000000029, 29.912489000000051],
            [68.400129000000049, 29.933509000000072],
            [68.400129000000049, 29.935430007000036],
            [68.400134985000079, 29.935430000000053],
            [68.400130981000075, 29.953167994000069],
            [68.405730981000033, 29.967867993000027],
            [68.403630980000059, 29.978367993000063],
            [68.405656243000067, 29.985125759000027],
            [68.413410608000049, 30.011000023000065],
            [68.413423621000049, 30.011043438000058],
            [68.445237557000041, 29.984171982000078],
            [68.448176916000079, 29.981232232000025],
            [68.470102000000054, 29.959263000000078],
            [68.495654000000059, 29.941519000000028],
            [68.495654367000043, 29.941518822000035],
            [68.519785998000032, 29.930161995000049],
            [68.519786365000073, 29.930161817000055],
            [68.528349113000047, 29.92883686700003],
            [68.528349192000064, 29.928836976000071],
            [68.537531000000058, 29.927323000000058],
            [68.549597000000063, 29.921645000000069],
            [68.564502000000061, 29.910999000000061],
            [68.592183000000034, 29.912418000000059],
            [68.632640000000038, 29.913128000000029],
            [68.685155386000076, 29.915256691000025],
            [68.685163000000045, 29.915257000000054],
            [68.721500303000028, 29.919642011000064],
            [68.725266412000053, 29.920093577000046],
            [68.73839147700005, 29.921644462000074],
            [68.73839600000008, 29.921645000000069],
            [68.769626000000073, 29.927323000000058],
            [68.801566000000037, 29.942229000000054],
            [68.81931000000003, 29.952875000000063],
            [68.821331367000028, 29.953998941000066],
            [68.834925000000055, 29.961392000000046],
            [68.838943638000046, 29.970355677000043],
            [68.841313000000071, 29.975588000000073],
            [68.851960000000076, 29.98339500000003],
            [68.86473600000005, 29.994752000000062],
            [68.870748275000039, 29.997912583000073],
            [68.881060000000048, 30.003269000000046],
            [68.920092216000057, 30.013933305000023],
            [68.936754366000059, 30.02047807100007],
            [68.939965525000048, 30.02173939000005],
            [68.952706595000052, 30.018906321000031],
            [68.952742189000048, 30.018898407000052],
            [68.971201000000065, 30.009657000000061],
            [68.980428000000074, 29.999720000000025],
            [68.985397000000034, 29.986234000000024],
            [68.989854000000037, 29.985279000000048],
            [68.995334000000071, 29.984105000000056],
            [69.010949000000039, 29.985525000000052],
            [69.011377960000061, 29.985262299000055],
            [69.018725673000063, 29.980738642000063],
            [69.020176000000049, 29.979846000000066],
            [69.03295200000008, 29.977717000000041],
            [69.047147000000052, 29.974878000000047],
            [69.057084000000032, 29.979846000000066],
            [69.070570000000032, 29.986234000000024],
            [69.080506000000071, 29.989783000000045],
            [69.092572000000075, 29.989783000000045],
            [69.098962874000051, 29.984806434000063],
            [69.108900301000062, 29.984095158000059],
            [69.121676755000067, 29.98480380500007],
            [69.137292151000054, 29.988351627000043],
            [69.150068647000069, 29.988350148000052],
            [69.15582227200008, 29.994584008000061],
            [69.158585432000052, 29.997577800000045],
            [69.170651648000046, 30.001835159000052],
            [69.186977114000058, 30.004672773000038],
            [69.197624485000063, 30.005381667000051],
            [69.210334929000055, 30.006793114000061],
            [69.210400897000056, 30.006800440000063],
            [69.225306433000071, 30.007508840000071],
            [69.243051163000075, 30.006796660000077],
            [69.260805429000072, 30.005525890000058],
            [69.26292601800003, 30.00537410700008],
            [69.282090594000067, 30.008211393000067],
            [69.296286144000078, 30.008209750000049],
            [69.313321805000044, 30.008207778000042],
            [69.321665637000081, 30.008206812000026],
            [69.326807328000029, 30.008206217000065],
            [69.339583824000044, 30.008204738000074],
            [69.345261835000031, 30.01175371000005],
            [69.353069844000061, 30.016722688000073],
            [69.358748148000075, 30.015301779000026],
            [69.359457546000044, 30.025950586000079],
            [69.367974877000051, 30.025949600000047],
            [69.380751289000045, 30.027367373000061],
            [69.396366349000061, 30.03659420300005],
            [69.399204956000062, 30.045112386000028],
            [69.404172394000057, 30.05789007900006],
            [69.412129547000063, 30.065298816000052],
            [69.416490000000067, 30.047860000000071],
            [69.429215000000056, 30.045614000000057],
            [69.453917000000047, 30.044117000000028],
            [69.453917214000057, 30.044117155000038],
            [69.453919781000081, 30.044117000000028],
            [69.462154781000038, 30.05010500000003],
            [69.462902781000082, 30.062082000000032],
            [69.466645781000068, 30.068071000000032],
            [69.476376781000056, 30.069568000000061],
            [69.48984839700006, 30.070315867000033],
            [69.503322000000082, 30.065825000000075],
            [69.49957900000004, 30.058340000000044],
            [69.494339000000082, 30.045614000000057],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "27",
      properties: { name: "Lasbela", count: 199 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.416959893000069, 26.096498904000043],
            [67.420086000000083, 26.093025000000068],
            [67.430345000000045, 26.076848000000041],
            [67.422059000000047, 26.047256000000061],
            [67.416535000000067, 26.023976000000062],
            [67.412195000000054, 26.000302000000033],
            [67.406577482000046, 25.967723432000071],
            [67.406276000000048, 25.965975000000071],
            [67.406671000000074, 25.934015000000045],
            [67.405093000000079, 25.904422000000068],
            [67.401792121000028, 25.892319894000025],
            [67.399174000000073, 25.88272100000006],
            [67.405882000000076, 25.85983600000003],
            [67.41574600000007, 25.842081000000064],
            [67.416535000000067, 25.825114000000042],
            [67.409827000000064, 25.804202000000032],
            [67.405394983000065, 25.795507764000035],
            [67.399569000000042, 25.784079000000077],
            [67.394607160000078, 25.780771361000063],
            [67.386548000000062, 25.77539900000005],
            [67.373660849000032, 25.763585173000024],
            [67.372344000000055, 25.762378000000069],
            [67.373177207000083, 25.758376747000057],
            [67.37323407100007, 25.75810367400004],
            [67.374316000000078, 25.752908000000048],
            [67.360901000000069, 25.745412000000044],
            [67.349853000000053, 25.733180000000061],
            [67.343146000000047, 25.716214000000036],
            [67.339080111000044, 25.70559849700004],
            [67.336043000000075, 25.697669000000076],
            [67.32973000000004, 25.668866000000037],
            [67.323934092000059, 25.642588980000028],
            [67.323872030000075, 25.642307379000044],
            [67.323812000000032, 25.642035000000078],
            [67.315711646000068, 25.623890092000067],
            [67.31486112400006, 25.621984910000037],
            [67.314490910000075, 25.621149960000025],
            [67.30409091000007, 25.597849960000076],
            [67.302762244000064, 25.595617802000049],
            [67.296587000000045, 25.585217000000057],
            [67.286796983000045, 25.580322583000054],
            [67.280015000000049, 25.576932000000056],
            [67.272717162000049, 25.575685995000072],
            [67.263990792000072, 25.574196087000075],
            [67.263838000000078, 25.574170000000038],
            [67.263833458000079, 25.57415977900007],
            [67.259103000000039, 25.56351600000005],
            [67.257216285000084, 25.55581214800003],
            [67.257216274000029, 25.55581210400004],
            [67.254390910000041, 25.544149960000027],
            [67.248490910000044, 25.526449960000036],
            [67.235790910000048, 25.514949960000024],
            [67.229657403000033, 25.51392770800004],
            [67.222014000000058, 25.512617000000034],
            [67.213127157000031, 25.506586527000024],
            [67.210966000000042, 25.505120000000034],
            [67.202757432000055, 25.496701257000041],
            [67.199055056000077, 25.492904084000031],
            [67.195590910000078, 25.489349960000027],
            [67.193496070000037, 25.484606472000053],
            [67.186897000000044, 25.469609000000048],
            [67.174665000000061, 25.462507000000073],
            [67.16203900000005, 25.452643000000023],
            [67.162268932000075, 25.431512552000072],
            [67.162434000000076, 25.41634300000004],
            [67.165590000000066, 25.400560000000041],
            [67.165589493000084, 25.400556957000049],
            [67.165590910000049, 25.400549960000035],
            [67.165347819000033, 25.399106606000032],
            [67.164012000000071, 25.391090000000077],
            [67.163991151000062, 25.39105139000003],
            [67.15335900000008, 25.371362000000033],
            [67.143889000000058, 25.361892000000068],
            [67.138789779000035, 25.338218097000038],
            [67.138365000000078, 25.336246000000074],
            [67.13922019000006, 25.326983601000052],
            [67.140423684000041, 25.313948843000048],
            [67.140428016000044, 25.313902001000031],
            [67.140627963000043, 25.311736559000053],
            [67.140686071000061, 25.311107247000052],
            [67.140733000000068, 25.310599000000025],
            [67.140686071000061, 25.310539559000063],
            [67.13681401100007, 25.305635212000027],
            [67.134814000000063, 25.303102000000024],
            [67.134513994000031, 25.302609973000074],
            [67.13009865600003, 25.295368577000033],
            [67.130142333000038, 25.295367403000057],
            [67.130077740000047, 25.295262141000023],
            [67.130032494000034, 25.295260068000061],
            [67.123158409000041, 25.283986194000079],
            [67.115112305000082, 25.270874024000022],
            [67.087890625000057, 25.240478516000053],
            [67.064697266000053, 25.222290039000029],
            [67.064610027000072, 25.222238630000049],
            [67.064581000000032, 25.22221600000006],
            [67.064365385000031, 25.222094467000034],
            [67.061279297000056, 25.220275879000042],
            [67.060569034000082, 25.219954613000027],
            [67.052630400000055, 25.21546187000007],
            [67.04289091000004, 25.209949960000074],
            [67.032155676000059, 25.197051656000042],
            [67.029466787000047, 25.193809155000054],
            [67.009390910000036, 25.159849960000031],
            [67.008690910000041, 25.149349960000052],
            [67.008496229000059, 25.146309939000048],
            [67.007390910000083, 25.129049960000032],
            [67.010056860000077, 25.119690774000048],
            [67.011257945000068, 25.115487103000078],
            [67.012104000000079, 25.112526000000059],
            [67.011286220000045, 25.10843710000006],
            [67.009390910000036, 25.098749960000077],
            [67.007556426000065, 25.093074525000077],
            [67.006208317000073, 25.088903810000033],
            [66.995910645000038, 25.085693359000061],
            [66.989731922000033, 25.079224556000042],
            [66.985752866000041, 25.075058689000059],
            [66.982364158000053, 25.071516044000077],
            [66.970066298000063, 25.058659533000025],
            [66.96990674400007, 25.058468881000067],
            [66.969890910000061, 25.058449960000075],
            [66.966016473000082, 25.054735624000045],
            [66.96601258000004, 25.054731891000074],
            [66.966007110000078, 25.054726648000042],
            [66.955903196000065, 25.045040251000046],
            [66.945698623000055, 25.035238593000031],
            [66.941936482000074, 25.031624995000072],
            [66.939890910000031, 25.029649960000029],
            [66.931685009000034, 25.023404624000023],
            [66.928766085000063, 25.021183094000037],
            [66.925578796000082, 25.018757316000062],
            [66.925578757000039, 25.018757347000076],
            [66.925502216000041, 25.018699032000029],
            [66.918099254000083, 25.013058808000039],
            [66.915759807000029, 25.011276412000029],
            [66.915040000000033, 25.010728000000029],
            [66.91422392000004, 25.010621550000053],
            [66.903216358000066, 25.009185715000058],
            [66.893995007000058, 25.007982875000039],
            [66.893953915000054, 25.00797751500005],
            [66.89395378200004, 25.007977399000026],
            [66.878690910000046, 25.005949960000066],
            [66.877692903000082, 25.005515185000036],
            [66.858311254000057, 24.996670876000053],
            [66.858332895000046, 24.996765639000046],
            [66.838073730000076, 24.987487793000071],
            [66.836090408000075, 24.986427266000078],
            [66.828209345000062, 24.982213087000048],
            [66.820495605000076, 24.978088379000042],
            [66.813625469000044, 24.975565528000061],
            [66.796623226000065, 24.969321966000052],
            [66.79576482300007, 24.969010353000044],
            [66.79115911100007, 24.967329784000071],
            [66.79110541700004, 24.967295716000024],
            [66.781540318000054, 24.96122682500004],
            [66.781385240000077, 24.961128432000066],
            [66.781336421000049, 24.961097456000061],
            [66.77669091000007, 24.958149960000071],
            [66.769680797000035, 24.953977274000067],
            [66.768699705000074, 24.953393290000065],
            [66.768267630000082, 24.953125115000034],
            [66.75639091000005, 24.940449960000024],
            [66.755688918000033, 24.939929008000036],
            [66.737832167000079, 24.92617982400003],
            [66.73391687000003, 24.923165157000028],
            [66.728997000000049, 24.919377000000054],
            [66.724458224000045, 24.917107755000075],
            [66.720947155000033, 24.915352331000065],
            [66.717319935000035, 24.913538835000054],
            [66.711035135000031, 24.910396633000062],
            [66.710866387000067, 24.910312264000027],
            [66.70886259200006, 24.909310430000062],
            [66.707233212000062, 24.908495791000064],
            [66.704595142000073, 24.907176839000044],
            [66.702034658000059, 24.905896678000033],
            [66.697241000000076, 24.903500000000065],
            [66.691210622000028, 24.897204055000032],
            [66.688482000000079, 24.902887000000078],
            [66.686813231000031, 24.907933125000056],
            [66.686135000000036, 24.909984000000065],
            [66.683388000000036, 24.916769000000045],
            [66.683210615000064, 24.919495048000044],
            [66.683032000000082, 24.922240000000045],
            [66.677793000000065, 24.933341000000041],
            [66.67777125300006, 24.933987415000047],
            [66.677724000000069, 24.935392000000036],
            [66.677437000000054, 24.935993000000053],
            [66.677898000000027, 24.937191000000041],
            [66.678209000000038, 24.938808000000051],
            [66.678878000000054, 24.939829000000032],
            [66.679463000000055, 24.941272000000026],
            [66.681154000000049, 24.942419000000029],
            [66.683963000000062, 24.944589000000065],
            [66.686270000000036, 24.945629000000054],
            [66.689749000000063, 24.946638000000064],
            [66.692626278000034, 24.947064854000075],
            [66.695113000000049, 24.94740100000007],
            [66.696364000000074, 24.947921000000065],
            [66.697998000000041, 24.948999000000072],
            [66.699576000000036, 24.950478000000032],
            [66.701101000000051, 24.952549000000033],
            [66.701748000000066, 24.954123000000038],
            [66.701981000000046, 24.95510100000007],
            [66.702817000000039, 24.96085800000003],
            [66.702705000000037, 24.967862000000025],
            [66.701951000000065, 24.974146000000076],
            [66.702228000000048, 24.976705000000038],
            [66.702529000000084, 24.978594000000044],
            [66.703094000000078, 24.984432000000027],
            [66.703090214000042, 24.984457361000068],
            [66.701107000000036, 24.997744000000068],
            [66.701878000000079, 25.00110200000006],
            [66.703783000000044, 25.002190000000041],
            [66.705102000000068, 25.002805000000023],
            [66.706660000000056, 25.005032000000028],
            [66.709149000000082, 25.008719000000042],
            [66.709915127000045, 25.010214426000061],
            [66.711375000000032, 25.013064000000043],
            [66.712081000000069, 25.017375000000072],
            [66.712320000000034, 25.019700000000057],
            [66.71198154800004, 25.022987339000053],
            [66.711620000000039, 25.026499000000058],
            [66.710483840000052, 25.031899868000039],
            [66.709684000000038, 25.035702000000072],
            [66.712195000000065, 25.037372000000062],
            [66.712553000000071, 25.049578000000054],
            [66.709448288000033, 25.06349442000004],
            [66.707254000000034, 25.073330000000055],
            [66.701823000000047, 25.086858000000063],
            [66.707593000000031, 25.090625000000045],
            [66.710982000000058, 25.090531000000055],
            [66.714830000000063, 25.094847000000073],
            [66.716601000000082, 25.102385000000027],
            [66.714712000000077, 25.111319000000037],
            [66.715571000000068, 25.114242000000047],
            [66.717119000000082, 25.115737000000024],
            [66.719472000000053, 25.117110000000025],
            [66.721221000000071, 25.117451000000074],
            [66.724223000000052, 25.118553000000077],
            [66.729415000000074, 25.121020000000044],
            [66.732135262000043, 25.122594592000041],
            [66.735218000000032, 25.124379000000033],
            [66.736624000000063, 25.129920000000027],
            [66.736468000000059, 25.138883000000078],
            [66.738915000000077, 25.14214000000004],
            [66.740954000000045, 25.147588000000042],
            [66.74158288700005, 25.150489041000071],
            [66.74261800000005, 25.155264000000045],
            [66.748579000000063, 25.167892000000052],
            [66.747979000000043, 25.175984000000028],
            [66.74497400000007, 25.184153000000038],
            [66.743079112000032, 25.18803738400004],
            [66.738904000000048, 25.195354000000066],
            [66.736120515000039, 25.199368623000055],
            [66.730299000000059, 25.207765000000052],
            [66.71846800000003, 25.222387000000026],
            [66.710336000000041, 25.231687000000079],
            [66.701573170000074, 25.239849473000049],
            [66.686703746000035, 25.253981176000025],
            [66.662416000000064, 25.276621000000034],
            [66.643539344000033, 25.291902064000055],
            [66.639059000000032, 25.295529000000045],
            [66.630501830000071, 25.301633548000041],
            [66.615770000000055, 25.312143000000049],
            [66.598247000000072, 25.325055000000077],
            [66.580113991000076, 25.335753086000068],
            [66.573041000000046, 25.339926000000048],
            [66.554702000000077, 25.351312000000064],
            [66.551845000000071, 25.355373000000043],
            [66.549060884000028, 25.362162371000068],
            [66.550102234000065, 25.365238190000071],
            [66.550180026000078, 25.368828877000055],
            [66.550247192000029, 25.371929169000055],
            [66.55101626000004, 25.375027030000069],
            [66.552157838000028, 25.379625388000079],
            [66.555274963000045, 25.392181396000069],
            [66.560485840000069, 25.38883209200003],
            [66.562721252000074, 25.375545502000023],
            [66.566146851000042, 25.371082306000062],
            [66.570396423000034, 25.368738174000043],
            [66.57361602800006, 25.368654251000066],
            [66.575157537000052, 25.369771376000074],
            [66.576447851000069, 25.370706461000054],
            [66.578137475000062, 25.371929631000057],
            [66.579389341000081, 25.372835644000077],
            [66.579393491000076, 25.372838647000037],
            [66.579401451000081, 25.372844408000049],
            [66.579460938000068, 25.372970955000028],
            [66.581703186000084, 25.377740860000074],
            [66.582038879000038, 25.385274887000037],
            [66.576568604000045, 25.40386772200003],
            [66.578277588000049, 25.411457062000068],
            [66.583976746000076, 25.417526245000033],
            [66.592315674000076, 25.421190262000039],
            [66.594200134000062, 25.425022125000055],
            [66.588203430000078, 25.435239792000061],
            [66.585664399000052, 25.437082722000071],
            [66.579976578000071, 25.441211169000042],
            [66.573440552000079, 25.445955276000063],
            [66.560005188000048, 25.452957153000057],
            [66.559768677000079, 25.462770462000037],
            [66.555030823000038, 25.480346680000025],
            [66.556617737000067, 25.485841751000066],
            [66.556165270000065, 25.495417441000029],
            [66.555777899000077, 25.503615509000042],
            [66.555671692000033, 25.505863190000071],
            [66.557220459000064, 25.51966857900004],
            [66.558593750000057, 25.531887054000038],
            [66.557609558000081, 25.538118362000034],
            [66.552734375000057, 25.543781281000065],
            [66.546737671000074, 25.53915977500003],
            [66.541450500000053, 25.538955688000044],
            [66.528862000000061, 25.542228699000077],
            [66.526908875000061, 25.54466056800004],
            [66.528579712000067, 25.548276901000065],
            [66.525771944000041, 25.550898742000072],
            [66.520629883000083, 25.555700302000048],
            [66.515884399000072, 25.563455582000074],
            [66.521333781000067, 25.578799443000037],
            [66.52198028600003, 25.580619812000066],
            [66.53119659400005, 25.585365295000031],
            [66.534660339000084, 25.590097427000046],
            [66.540222168000071, 25.606445312000062],
            [66.542953491000048, 25.614450455000053],
            [66.541620550000061, 25.618531017000066],
            [66.541336060000049, 25.619401932000073],
            [66.538970947000053, 25.620773315000065],
            [66.533714294000049, 25.619733810000071],
            [66.532707214000084, 25.621576309000034],
            [66.535513350000031, 25.625139157000035],
            [66.538139343000068, 25.628473282000073],
            [66.542152405000081, 25.641166687000066],
            [66.541389465000066, 25.642601013000046],
            [66.539567497000064, 25.643949658000054],
            [66.539245605000076, 25.644187927000075],
            [66.537687085000073, 25.643842602000063],
            [66.534683228000063, 25.64317703200004],
            [66.530212402000075, 25.640285492000032],
            [66.524986267000031, 25.633813858000053],
            [66.520362854000041, 25.624439240000072],
            [66.518867493000073, 25.607454300000029],
            [66.512353264000069, 25.604413478000026],
            [66.510078430000078, 25.60335159300007],
            [66.513565063000044, 25.597845078000034],
            [66.510520935000045, 25.593965530000048],
            [66.505584717000033, 25.59126472500003],
            [66.502868652000075, 25.585308075000057],
            [66.502792358000079, 25.577363968000043],
            [66.499481201000037, 25.574310303000061],
            [66.493949890000067, 25.574302673000034],
            [66.491744995000033, 25.577142715000036],
            [66.494903564000083, 25.583326340000042],
            [66.492965698000035, 25.585340500000029],
            [66.48360443100006, 25.583719254000073],
            [66.479949951000037, 25.587755203000029],
            [66.472206116000052, 25.581182480000052],
            [66.470985413000051, 25.577791214000058],
            [66.471363052000072, 25.575894294000022],
            [66.471893311000031, 25.573230743000067],
            [66.470634460000042, 25.570672989000059],
            [66.467216492000034, 25.569911957000045],
            [66.465209961000028, 25.573385239000061],
            [66.470687866000048, 25.588855743000067],
            [66.467041016000053, 25.602504730000078],
            [66.465599060000045, 25.603702545000033],
            [66.460113525000054, 25.602859497000054],
            [66.458129883000083, 25.605707169000027],
            [66.459350586000028, 25.613904953000031],
            [66.455169678000061, 25.619382858000051],
            [66.450263977000077, 25.616054535000046],
            [66.450912476000042, 25.607303619000049],
            [66.449127197000053, 25.60138130200005],
            [66.445404053000061, 25.597265244000027],
            [66.43640136700003, 25.59293937700005],
            [66.43188088200003, 25.592487764000055],
            [66.418081831000052, 25.591109187000029],
            [66.41327700800008, 25.590629167000031],
            [66.408660889000032, 25.590167999000073],
            [66.399085999000079, 25.583518982000044],
            [66.385221580000064, 25.593284049000033],
            [66.383102417000032, 25.594793320000065],
            [66.380226135000044, 25.601783752000074],
            [66.376670837000063, 25.599134445000061],
            [66.372535706000065, 25.598968506000062],
            [66.36676788300008, 25.608560562000036],
            [66.370002747000058, 25.61307716400006],
            [66.367561340000066, 25.615905762000068],
            [66.364089966000051, 25.616184235000048],
            [66.36242173200003, 25.615399539000066],
            [66.357780457000047, 25.613216400000056],
            [66.349180666000052, 25.615619165000055],
            [66.341430664000029, 25.617784500000027],
            [66.333981148000078, 25.613730659000055],
            [66.327743530000078, 25.610336304000043],
            [66.310478210000042, 25.610057831000063],
            [66.303970337000067, 25.615854263000074],
            [66.300132751000035, 25.623640060000071],
            [66.296890259000065, 25.623928070000034],
            [66.285903931000064, 25.617837906000034],
            [66.274299622000058, 25.614858627000046],
            [66.267608643000074, 25.61040687600007],
            [66.254264832000047, 25.605474472000026],
            [66.245803833000082, 25.599485397000024],
            [66.242378235000047, 25.598926544000051],
            [66.23858642600004, 25.601070404000041],
            [66.234497070000032, 25.599857330000077],
            [66.23396301300005, 25.592103958000052],
            [66.232208252000078, 25.590358734000063],
            [66.227622986000029, 25.589962006000064],
            [66.224106043000063, 25.591472143000033],
            [66.223625183000081, 25.591678619000049],
            [66.223222797000062, 25.591999772000065],
            [66.220936683000048, 25.59382436900006],
            [66.210426331000065, 25.602212906000034],
            [66.200056217000053, 25.601630046000025],
            [66.196411133000083, 25.601425171000074],
            [66.190063477000081, 25.599283218000039],
            [66.184555054000043, 25.594455719000052],
            [66.180740356000058, 25.583223343000043],
            [66.174629211000081, 25.576490402000047],
            [66.161514282000041, 25.567169189000026],
            [66.156127930000082, 25.559839249000049],
            [66.150817871000072, 25.555646896000042],
            [66.138916016000053, 25.549716949000072],
            [66.133659363000049, 25.544273376000035],
            [66.131889343000068, 25.538347244000079],
            [66.136650085000042, 25.530813217000059],
            [66.130683899000076, 25.521368027000051],
            [66.134170532000041, 25.511692047000054],
            [66.132751465000069, 25.507869720000031],
            [66.128791809000063, 25.504362106000031],
            [66.119569909000063, 25.501190957000063],
            [66.117027283000027, 25.500316620000035],
            [66.113967896000077, 25.497053146000042],
            [66.112754822000056, 25.489269257000046],
            [66.109848022000051, 25.487684250000029],
            [66.099698179000029, 25.487694457000032],
            [66.095657349000078, 25.486043930000051],
            [66.091850281000063, 25.479614258000026],
            [66.09619903600003, 25.475616455000079],
            [66.100830078000058, 25.474975586000028],
            [66.107307434000063, 25.478799820000063],
            [66.115653992000034, 25.48667335500005],
            [66.11908721900005, 25.487026215000071],
            [66.121475220000036, 25.485246658000051],
            [66.12081756200007, 25.480215270000031],
            [66.120651245000033, 25.478942871000072],
            [66.122039795000035, 25.474193573000036],
            [66.127960205000079, 25.475486755000077],
            [66.138435364000031, 25.482194901000071],
            [66.141906738000046, 25.481712341000048],
            [66.143989563000048, 25.476991653000027],
            [66.149719238000046, 25.477439880000077],
            [66.162956238000049, 25.484260559000063],
            [66.172325134000062, 25.485485077000078],
            [66.182220459000064, 25.490076065000039],
            [66.189521790000072, 25.491422653000029],
            [66.195625305000078, 25.488958359000037],
            [66.223655701000041, 25.499099731000058],
            [66.233421326000041, 25.506397247000052],
            [66.241737366000052, 25.510292053000057],
            [66.249130249000075, 25.509757996000076],
            [66.254524231000062, 25.512487411000052],
            [66.262268066000047, 25.518653870000037],
            [66.265419006000059, 25.524843216000079],
            [66.274116516000049, 25.53042221100003],
            [66.277465820000032, 25.53724670400004],
            [66.284820557000046, 25.542142868000042],
            [66.289817810000045, 25.543390274000046],
            [66.314048767000031, 25.53768539400005],
            [66.318092346000071, 25.539730072000054],
            [66.316589355000076, 25.542177200000026],
            [66.307472229000041, 25.544942856000034],
            [66.308784485000047, 25.546459198000036],
            [66.317520142000035, 25.546602249000046],
            [66.326431274000072, 25.552814484000066],
            [66.334930420000035, 25.553155899000046],
            [66.342849731000058, 25.551385880000055],
            [66.348381042000028, 25.546592712000063],
            [66.350524902000075, 25.535602570000037],
            [66.353363037000065, 25.529447556000036],
            [66.357963562000066, 25.524826050000058],
            [66.36358642600004, 25.522752762000039],
            [66.388290405000078, 25.526039124000079],
            [66.397254944000053, 25.521589279000068],
            [66.400375366000048, 25.514190674000076],
            [66.394294739000031, 25.506635666000022],
            [66.396255493000069, 25.504205704000071],
            [66.405822754000042, 25.506258011000057],
            [66.40424346900005, 25.496164322000027],
            [66.405570984000065, 25.492456436000055],
            [66.409584045000031, 25.490318298000034],
            [66.419784546000074, 25.488842010000042],
            [66.426528931000064, 25.482631683000022],
            [66.435035706000065, 25.482759476000069],
            [66.440704346000075, 25.484445572000027],
            [66.446147395000082, 25.487956885000074],
            [66.455886841000051, 25.494239807000042],
            [66.46833038300008, 25.498699188000046],
            [66.475189209000064, 25.499595642000031],
            [66.48085100000003, 25.500047000000052],
            [66.487113953000062, 25.500480652000078],
            [66.49568176300005, 25.499353409000037],
            [66.517433167000036, 25.492052078000029],
            [66.518341064000083, 25.487491608000028],
            [66.514419556000064, 25.482740402000047],
            [66.51345062300004, 25.474136353000063],
            [66.496459961000028, 25.458847046000074],
            [66.49284362800006, 25.447839737000038],
            [66.494956970000032, 25.437265396000043],
            [66.498420715000066, 25.43217659000004],
            [66.506172180000078, 25.428718567000033],
            [66.512191772000051, 25.427909851000038],
            [66.528404236000029, 25.41579437300004],
            [66.526977539000029, 25.411977768000042],
            [66.519882202000076, 25.406478882000044],
            [66.513298035000048, 25.404550552000046],
            [66.511150986000075, 25.398511324000026],
            [66.496823000000063, 25.402023000000042],
            [66.485113000000069, 25.406034000000034],
            [66.469216000000074, 25.413943000000074],
            [66.438617487000045, 25.429093163000061],
            [66.41201025600003, 25.44226713300003],
            [66.399091000000055, 25.448158000000035],
            [66.387072000000046, 25.45228000000003],
            [66.369657674000052, 25.456968360000076],
            [66.351362000000051, 25.461894000000029],
            [66.323686000000066, 25.466525000000047],
            [66.302658000000065, 25.468486000000041],
            [66.285300000000063, 25.468525000000056],
            [66.272169000000076, 25.467525000000023],
            [66.255266000000063, 25.464667000000077],
            [66.237555000000043, 25.462221000000056],
            [66.219036368000047, 25.459287991000053],
            [66.193327819000046, 25.456320647000041],
            [66.184769028000062, 25.455344784000033],
            [66.132306172000028, 25.449870322000038],
            [66.126419000000055, 25.449256000000048],
            [66.094222000000059, 25.442314000000067],
            [66.062914282000065, 25.434293220000029],
            [66.038956000000042, 25.428612000000044],
            [66.03236400000003, 25.425063000000023],
            [66.022676000000047, 25.422712000000047],
            [65.995471000000066, 25.418907000000047],
            [65.946581000000037, 25.41436200000004],
            [65.898165311000071, 25.415714226000034],
            [65.887269630000048, 25.415969254000061],
            [65.87692368200004, 25.416254385000059],
            [65.867693000000031, 25.417036000000053],
            [65.848298217000035, 25.417887744000041],
            [65.839890000000082, 25.41825700000004],
            [65.826619000000051, 25.417925000000025],
            [65.818354000000056, 25.416475000000048],
            [65.81299400000006, 25.414549000000079],
            [65.809122000000059, 25.412670000000048],
            [65.806446000000051, 25.411124000000029],
            [65.803532000000075, 25.408277000000055],
            [65.80099800000005, 25.404725000000042],
            [65.79805900000008, 25.402746000000036],
            [65.796046000000047, 25.398046000000079],
            [65.789974000000029, 25.39348700000005],
            [65.786305000000027, 25.392634000000044],
            [65.782101000000068, 25.39233900000005],
            [65.778664000000049, 25.390918000000056],
            [65.776949000000059, 25.389410000000055],
            [65.775276000000076, 25.387327000000028],
            [65.774786000000063, 25.38513400000005],
            [65.770027000000027, 25.382947000000058],
            [65.763546000000076, 25.381699000000026],
            [65.756829000000039, 25.38074800000004],
            [65.749831000000029, 25.37991500000004],
            [65.739906000000076, 25.379565000000071],
            [65.733159000000057, 25.376139000000023],
            [65.729323000000079, 25.375859000000048],
            [65.725150000000042, 25.376004000000023],
            [65.717984000000058, 25.374881000000073],
            [65.714784878000046, 25.372467146000076],
            [65.709586000000058, 25.368786000000057],
            [65.70728100000008, 25.368995000000041],
            [65.70664540000007, 25.369014807000042],
            [65.702949000000046, 25.369130000000041],
            [65.69788600000004, 25.367646000000036],
            [65.686530000000062, 25.366953000000024],
            [65.668500000000051, 25.364862000000073],
            [65.661583000000064, 25.361718000000053],
            [65.65722100000005, 25.358809000000065],
            [65.644739000000072, 25.354600000000062],
            [65.634050000000059, 25.353499000000056],
            [65.625377000000071, 25.353754000000038],
            [65.614204992000055, 25.355916096000044],
            [65.600161000000071, 25.358634000000052],
            [65.583812875000035, 25.362538647000065],
            [65.547307000000046, 25.371210000000076],
            [65.53839550400005, 25.372862131000034],
            [65.526347530000066, 25.374997962000066],
            [65.511161040000047, 25.377368494000052],
            [65.49917279500005, 25.379324784000062],
            [65.497377000000029, 25.379619000000048],
            [65.487342000000069, 25.381919000000039],
            [65.468263000000036, 25.38800800000007],
            [65.457399000000066, 25.389965000000075],
            [65.446422000000041, 25.385462000000075],
            [65.43624600000004, 25.382957000000033],
            [65.426565000000039, 25.378291000000047],
            [65.415139000000067, 25.37596300000007],
            [65.403673000000083, 25.377280000000042],
            [65.383720030000063, 25.380645365000078],
            [65.366826039000046, 25.383420039000043],
            [65.358542038000053, 25.381998039000052],
            [65.346300039000027, 25.378323039000065],
            [65.337366039000074, 25.376842039000053],
            [65.322617039000079, 25.378848039000047],
            [65.308070038000039, 25.379751039000041],
            [65.296217019000039, 25.380235451000033],
            [65.29178636000006, 25.38040421900007],
            [65.278100590000065, 25.379838943000038],
            [65.267336038000053, 25.37866303800007],
            [65.25360703900003, 25.374895039000023],
            [65.249250000000075, 25.373250000000041],
            [65.245716000000073, 25.370606000000066],
            [65.24288800000005, 25.365683000000047],
            [65.241370000000074, 25.358851000000072],
            [65.241588000000036, 25.352494000000036],
            [65.240812731000062, 25.350134001000072],
            [65.240799043000038, 25.350092335000056],
            [65.239630000000034, 25.353881000000058],
            [65.220936039000037, 25.367375039000024],
            [65.207638038000027, 25.383802039000045],
            [65.20842103800004, 25.397099039000068],
            [65.209203039000045, 25.411179039000046],
            [65.209267000000068, 25.427627556000061],
            [65.209267000000068, 25.433145000000025],
            [65.209267000000068, 25.455047000000036],
            [65.210831000000042, 25.48868200000004],
            [65.209267000000068, 25.521535000000029],
            [65.208485000000053, 25.547348000000056],
            [65.206138000000067, 25.564556000000039],
            [65.206784967000033, 25.564771988000075],
            [65.20678504600005, 25.564771862000043],
            [65.222564000000034, 25.570032000000026],
            [65.235080000000039, 25.568467000000055],
            [65.246031000000073, 25.560645000000079],
            [65.250739056000043, 25.561007087000064],
            [65.250887573000057, 25.561018509000064],
            [65.256199000000038, 25.561427000000037],
            [65.265586000000042, 25.571596000000056],
            [65.270279000000073, 25.584894000000077],
            [65.276004871000055, 25.588277152000046],
            [65.287488000000053, 25.595062000000041],
            [65.29843900000003, 25.597409000000027],
            [65.306261000000063, 25.602102000000059],
            [65.305479000000048, 25.612271000000078],
            [65.316430000000082, 25.606013000000075],
            [65.326598000000047, 25.601320000000044],
            [65.335203000000035, 25.595844500000055],
            [65.341460000000041, 25.588805000000036],
            [65.34354830400008, 25.590323480000052],
            [65.350065000000029, 25.595062000000041],
            [65.351629000000059, 25.602884000000074],
            [65.351629000000059, 25.616182000000038],
            [65.359451000000035, 25.62400400000007],
            [65.363307479000071, 25.624911424000061],
            [65.372749000000056, 25.627133000000072],
            [65.379788000000076, 25.627133000000072],
            [65.379788500000075, 25.627133000000072],
            [65.393086000000039, 25.627133000000072],
            [65.400908000000072, 25.627133000000072],
            [65.411859000000049, 25.631826000000046],
            [65.427503000000058, 25.637302000000034],
            [65.438454000000036, 25.64121300000005],
            [65.452534000000071, 25.653728000000058],
            [65.463485000000048, 25.661550000000034],
            [65.47208900000004, 25.66546100000005],
            [65.486951000000033, 25.66546100000005],
            [65.497120000000052, 25.670154000000025],
            [65.508853000000045, 25.674065000000041],
            [65.522151000000065, 25.681887000000074],
            [65.532288018000031, 25.688905550000072],
            [65.532291380000061, 25.688907877000076],
            [65.53231900000003, 25.688927000000035],
            [65.534208934000048, 25.687037368000063],
            [65.538696289000029, 25.68267822200005],
            [65.535522461000028, 25.674072265000063],
            [65.535522461000028, 25.662475586000028],
            [65.530883789000029, 25.652282715000069],
            [65.531677246000072, 25.645080567000036],
            [65.534729004000042, 25.639709473000039],
            [65.534656885000061, 25.639663951000045],
            [65.534666000000072, 25.639648000000022],
            [65.534622685000045, 25.639620437000076],
            [65.534632285000043, 25.63960363700005],
            [65.526028285000052, 25.63412863700006],
            [65.524803327000029, 25.632750609000027],
            [65.519897461000028, 25.627075195000032],
            [65.510498047000056, 25.627075195000032],
            [65.50688198000006, 25.623149180000041],
            [65.504161967000073, 25.620088785000064],
            [65.504126285000041, 25.620048638000071],
            [65.504397967000045, 25.619466418000059],
            [65.509704590000069, 25.608276368000077],
            [65.515075683000077, 25.600524902000075],
            [65.517517090000069, 25.588684082000043],
            [65.517535014000032, 25.588477271000045],
            [65.517483589000051, 25.588486231000047],
            [65.518240000000048, 25.579418000000032],
            [65.516675000000077, 25.570814000000041],
            [65.529190000000028, 25.561427000000037],
            [65.540924000000075, 25.555952000000048],
            [65.54561700000005, 25.548130000000072],
            [65.54561700000005, 25.534050000000036],
            [65.54561700000005, 25.518406000000027],
            [65.545431022000059, 25.516175094000062],
            [65.545400511000082, 25.516169170000069],
            [65.54539730700003, 25.516130732000079],
            [65.54401828500005, 25.499588637000045],
            [65.540639846000033, 25.474587672000041],
            [65.540108381000039, 25.470654758000023],
            [65.533101000000045, 25.462869000000069],
            [65.517457000000036, 25.457393000000025],
            [65.512071180000078, 25.453908034000051],
            [65.51163819900006, 25.453627868000069],
            [65.511472810000043, 25.453520850000075],
            [65.50416000000007, 25.448789000000033],
            [65.50413886900003, 25.448752780000063],
            [65.498742441000047, 25.439503170000023],
            [65.498718225000061, 25.439461663000031],
            [65.498684000000083, 25.439403000000027],
            [65.498718225000061, 25.439387543000066],
            [65.522765164000077, 25.428527796000026],
            [65.52293300000008, 25.42845200000005],
            [65.523301180000033, 25.428527796000026],
            [65.543989691000036, 25.432786852000049],
            [65.549528000000066, 25.43392700000004],
            [65.55460052400008, 25.436322484000073],
            [65.575224816000059, 25.44606224100005],
            [65.577687000000083, 25.44722500000006],
            [65.61132200000003, 25.467562000000044],
            [65.655908000000068, 25.48868200000004],
            [65.662888276000047, 25.491823113000066],
            [65.687197000000083, 25.502762000000075],
            [65.716138000000058, 25.512148000000025],
            [65.727288298000076, 25.515121477000037],
            [65.73960500000004, 25.518406000000027],
            [65.764635000000055, 25.510584000000051],
            [65.772940196000036, 25.506963765000023],
            [65.795142000000055, 25.497286000000031],
            [65.816261000000054, 25.48868200000004],
            [65.823194570000055, 25.487815269000066],
            [65.834008667000035, 25.486463453000056],
            [65.841292000000067, 25.485553000000039],
            [65.849886982000044, 25.485553000000039],
            [65.871016000000054, 25.485553000000039],
            [65.874833825000053, 25.488734656000076],
            [65.880402000000061, 25.493375000000071],
            [65.889436255000078, 25.497095227000045],
            [65.893700000000081, 25.498851000000059],
            [65.905433000000073, 25.498851000000059],
            [65.914229614000078, 25.491703356000073],
            [65.917948000000081, 25.48868200000004],
            [65.924820993000083, 25.485558203000039],
            [65.926553000000069, 25.484771000000023],
            [65.926577490000057, 25.484736013000031],
            [65.926696777000075, 25.48468017600004],
            [65.932112710000069, 25.476951823000036],
            [65.949488257000041, 25.477530860000059],
            [65.955494000000044, 25.477731000000063],
            [65.975050000000067, 25.487900000000025],
            [65.97505701700004, 25.487893897000049],
            [65.993040000000065, 25.472255000000075],
            [66.00693728400006, 25.464587723000022],
            [66.015724000000034, 25.459740000000068],
            [66.017248643000073, 25.459544541000071],
            [66.046231000000034, 25.455829000000051],
            [66.046955456000035, 25.456367698000065],
            [66.050939880000044, 25.459330482000041],
            [66.076724422000041, 25.478503647000025],
            [66.076737000000037, 25.478513000000078],
            [66.077924550000034, 25.480479931000048],
            [66.080829103000042, 25.485290720000023],
            [66.085494191000066, 25.493017470000041],
            [66.101767000000052, 25.519970000000058],
            [66.120325382000033, 25.548996557000066],
            [66.12664077200003, 25.558874253000056],
            [66.132274000000052, 25.56768500000004],
            [66.153519660000029, 25.610176320000051],
            [66.158869000000038, 25.620875000000069],
            [66.16165040900006, 25.62635103100007],
            [66.17700736300003, 25.656585763000066],
            [66.180560789000083, 25.663581739000051],
            [66.183899000000054, 25.670154000000025],
            [66.186367578000045, 25.675548287000026],
            [66.195491410000045, 25.695485503000043],
            [66.20501900000005, 25.716305000000034],
            [66.208225105000054, 25.72472100300007],
            [66.22379200000006, 25.765584000000047],
            [66.24100100000004, 25.803912000000025],
            [66.255080000000078, 25.832854000000054],
            [66.25977400000005, 25.846934000000033],
            [66.258209000000079, 25.875093000000049],
            [66.255264385000032, 25.888049758000022],
            [66.254298000000063, 25.892302000000029],
            [66.252734000000032, 25.905599000000052],
            [66.250195919000078, 25.913213244000076],
            [66.245694000000071, 25.926719000000048],
            [66.240218000000084, 25.962701000000038],
            [66.236963721000052, 25.979438393000066],
            [66.234743000000037, 25.990860000000055],
            [66.226139000000046, 26.022148000000072],
            [66.224455443000068, 26.035280259000046],
            [66.222964963000038, 26.046906458000024],
            [66.22222800000003, 26.052655000000073],
            [66.218317001000059, 26.073774001000061],
            [66.212841000000083, 26.091765000000066],
            [66.211600943000064, 26.102101449000031],
            [66.210652604000074, 26.110006296000051],
            [66.210495000000037, 26.111320000000035],
            [66.204237000000035, 26.137915000000078],
            [66.203418364000072, 26.150599413000066],
            [66.203148906000081, 26.154774544000077],
            [66.202992276000032, 26.15720146700005],
            [66.202672000000064, 26.162164000000075],
            [66.193286000000057, 26.187195000000031],
            [66.183997229000056, 26.206435843000065],
            [66.18233500000008, 26.209879000000058],
            [66.181505940000079, 26.211191752000047],
            [66.172949000000074, 26.224741000000051],
            [66.165909000000056, 26.239603000000045],
            [66.165905863000035, 26.239659399000061],
            [66.165126000000043, 26.253682000000026],
            [66.164604015000066, 26.256187555000054],
            [66.161215000000084, 26.272455000000036],
            [66.162218387000053, 26.278808785000024],
            [66.16356200000007, 26.28731700000003],
            [66.166501395000068, 26.307157209000025],
            [66.166687011000079, 26.308471680000025],
            [66.166687011000079, 26.336730957000043],
            [66.174637266000047, 26.344101151000075],
            [66.18077100000005, 26.349894000000063],
            [66.197569525000063, 26.35829364600005],
            [66.200807786000041, 26.359912851000047],
            [66.202672000000064, 26.36084500000004],
            [66.217518346000077, 26.368658761000063],
            [66.217534000000057, 26.368667000000073],
            [66.21842186300006, 26.370442726000078],
            [66.219406948000028, 26.372412897000061],
            [66.22129420400006, 26.376187409000067],
            [66.224462939000034, 26.382524877000037],
            [66.224574000000075, 26.382747000000052],
            [66.228656155000067, 26.39265941900004],
            [66.230050000000062, 26.396044000000074],
            [66.228485000000035, 26.411689000000024],
            [66.229155533000039, 26.422748291000062],
            [66.229160798000066, 26.422749343000078],
            [66.230055201000027, 26.437501000000054],
            [66.230055201000027, 26.450799000000075],
            [66.230424198000037, 26.45181387100007],
            [66.236312202000079, 26.468008000000054],
            [66.248046201000079, 26.483652000000063],
            [66.24829105200007, 26.484128307000049],
            [66.248464094000042, 26.484464926000044],
            [66.248533479000059, 26.484368026000027],
            [66.24854287800008, 26.484377320000078],
            [66.251285206000034, 26.480547502000036],
            [66.265700201000072, 26.48775500000005],
            [66.27654652800004, 26.494497494000029],
            [66.27713594100004, 26.494863895000037],
            [66.278492202000052, 26.495707000000039],
            [66.280416206000041, 26.496268110000074],
            [66.286790201000031, 26.498127000000068],
            [66.286855396000078, 26.498087880000071],
            [66.286926270000038, 26.498107910000044],
            [66.290893555000082, 26.495910645000038],
            [66.298811461000071, 26.491091050000023],
            [66.300720215000069, 26.489929199000073],
            [66.313903809000067, 26.480895996000072],
            [66.318136447000029, 26.475238081000043],
            [66.319970985000054, 26.472894130000043],
            [66.352478027000075, 26.470520020000038],
            [66.35772705100004, 26.470275879000042],
            [66.359835964000069, 26.469464168000059],
            [66.360079000000042, 26.469431000000043],
            [66.384984541000051, 26.462612464000074],
            [66.389121000000046, 26.461480000000051],
            [66.392494837000072, 26.460932728000046],
            [66.401913000000036, 26.459405000000061],
            [66.406292290000067, 26.458506601000067],
            [66.415077656000051, 26.456704307000052],
            [66.415383939000037, 26.456641474000037],
            [66.415396000000044, 26.456639000000052],
            [66.417307012000038, 26.454293827000072],
            [66.423002000000054, 26.447305000000028],
            [66.430475107000063, 26.440532391000033],
            [66.434065000000032, 26.437279000000046],
            [66.44404968200007, 26.434112781000067],
            [66.448240000000055, 26.432784000000026],
            [66.453663643000084, 26.43061472200003],
            [66.460247908000042, 26.427981234000072],
            [66.460341000000028, 26.427944000000025],
            [66.460480620000055, 26.427981234000072],
            [66.465236884000035, 26.42924963300004],
            [66.470713000000046, 26.430710000000033],
            [66.47836463200008, 26.434645215000046],
            [66.482851340000082, 26.436981270000047],
            [66.484251340000071, 26.437981270000023],
            [66.485103177000042, 26.438598543000069],
            [66.485377422000056, 26.438797989000022],
            [66.498025000000041, 26.447996000000046],
            [66.504033894000031, 26.449583156000074],
            [66.516351340000028, 26.452881270000034],
            [66.519751340000028, 26.45218127000004],
            [66.530541265000068, 26.450083229000029],
            [66.534167634000028, 26.452651888000048],
            [66.536829544000057, 26.454537395000045],
            [66.538821000000041, 26.455948000000035],
            [66.547148681000067, 26.460246258000041],
            [66.549539000000038, 26.461480000000051],
            [66.549547295000082, 26.461479191000024],
            [66.549790419000033, 26.461455470000033],
            [66.563402637000081, 26.460127378000038],
            [66.563714000000061, 26.460097000000076],
            [66.570065215000056, 26.45663247300007],
            [66.571351340000035, 26.455981270000052],
            [66.574151340000071, 26.454081270000074],
            [66.577588258000048, 26.451769162000062],
            [66.579618000000039, 26.450416000000075],
            [66.591027000000054, 26.439007000000061],
            [66.591028042000062, 26.43900456800003],
            [66.591051340000035, 26.438981270000056],
            [66.591516357000046, 26.437865230000057],
            [66.592569146000073, 26.435408859000063],
            [66.59447663800006, 26.430958288000056],
            [66.596213000000034, 26.426907000000028],
            [66.60010003900004, 26.420220826000048],
            [66.604856000000041, 26.412040000000047],
            [66.605699916000049, 26.411524311000051],
            [66.608170791000077, 26.410014442000033],
            [66.609294928000054, 26.409327519000044],
            [66.60929567800008, 26.409314041000073],
            [66.609326604000046, 26.409294785000043],
            [66.610186167000052, 26.408820542000058],
            [66.61108398500005, 26.408325195000032],
            [66.612487793000071, 26.410095215000069],
            [66.614929199000073, 26.414123535000044],
            [66.616027221000081, 26.416399434000027],
            [66.618286132000037, 26.421081543000071],
            [66.62670898500005, 26.43389892600004],
            [66.638488770000038, 26.440490723000039],
            [66.644475539000041, 26.441602552000063],
            [66.644627959000047, 26.441630858000053],
            [66.651306153000064, 26.442871094000054],
            [66.658264749000068, 26.443702431000077],
            [66.665100098000039, 26.444519043000071],
            [66.674499511000079, 26.444519043000071],
            [66.680101035000064, 26.446524829000055],
            [66.688476562000062, 26.44952392600004],
            [66.689730810000071, 26.447891414000026],
            [66.692321777000075, 26.444519043000071],
            [66.701293945000032, 26.443481445000032],
            [66.701294647000054, 26.443488199000058],
            [66.702270508000083, 26.452880859000061],
            [66.705078125000057, 26.459106445000032],
            [66.705302388000064, 26.459106445000032],
            [66.707551340000066, 26.459481270000026],
            [66.708051376000071, 26.45942490300007],
            [66.710876465000069, 26.459106445000032],
            [66.711120606000065, 26.459106445000032],
            [66.713500977000081, 26.463684082000043],
            [66.722106934000067, 26.460083008000026],
            [66.730712891000053, 26.460510254000042],
            [66.738281250000057, 26.46569824200003],
            [66.748718262000068, 26.470886231000065],
            [66.754699707000043, 26.468688965000069],
            [66.760925293000071, 26.453308106000065],
            [66.761474609000061, 26.437072754000042],
            [66.762084961000028, 26.426696777000075],
            [66.767700195000032, 26.410705566000047],
            [66.768127441000047, 26.402709961000028],
            [66.775329590000069, 26.384521484000061],
            [66.776123047000056, 26.37530517600004],
            [66.777099609000061, 26.360900879000042],
            [66.779479981000065, 26.348876953000058],
            [66.779113770000038, 26.337280274000022],
            [66.784729004000042, 26.326721192000036],
            [66.790283204000048, 26.312500000000057],
            [66.793884277000075, 26.302917480000076],
            [66.803100586000028, 26.28991699200003],
            [66.803710938000052, 26.288696289000029],
            [66.814697266000053, 26.272705078000058],
            [66.81811523500005, 26.26928711000005],
            [66.831298828000058, 26.256103516000053],
            [66.834106446000078, 26.247924805000025],
            [66.838928223000039, 26.23132324200003],
            [66.841125489000035, 26.212280274000022],
            [66.846496582000043, 26.193298340000069],
            [66.852478027000075, 26.170288086000028],
            [66.854068670000061, 26.165425266000057],
            [66.856689453000058, 26.157714844000054],
            [66.858886719000054, 26.15069580100004],
            [66.866882324000073, 26.129882813000052],
            [66.869689941000047, 26.113281250000057],
            [66.880493164000029, 26.085693359000061],
            [66.881896973000039, 26.061523438000052],
            [66.882690430000082, 26.058715820000032],
            [66.884220936000077, 26.052800159000071],
            [66.886108398000033, 26.045715332000043],
            [66.888122559000067, 26.037719727000024],
            [66.894470215000069, 26.01452636700003],
            [66.895874023000033, 26.009277344000054],
            [66.906127930000082, 25.985473633000026],
            [66.917114258000083, 25.963684082000043],
            [66.932312012000068, 25.940124512000068],
            [66.938476562000062, 25.926330567000036],
            [66.938559380000072, 25.925894396000047],
            [66.938903809000067, 25.925109863000046],
            [66.939086914000029, 25.923889160000044],
            [66.94030761700003, 25.916687012000068],
            [66.947082520000038, 25.897277832000043],
            [66.947509766000053, 25.895080566000047],
            [66.949279785000044, 25.879272461000028],
            [66.954101562000062, 25.860717773000033],
            [66.963500977000081, 25.836730957000043],
            [66.968322754000042, 25.818481445000032],
            [66.974487305000082, 25.800476074000073],
            [66.977478027000075, 25.785278320000032],
            [66.979309082000043, 25.770690918000071],
            [66.975891113000046, 25.757873535000044],
            [66.973693848000039, 25.750671387000068],
            [66.973667070000033, 25.750667072000056],
            [66.972020000000043, 25.745128000000022],
            [66.973967734000041, 25.744200673000023],
            [66.979281000000071, 25.741671000000053],
            [66.979638544000068, 25.741407589000062],
            [66.981070000000045, 25.740353000000027],
            [66.985850000000028, 25.736831000000052],
            [66.994284162000042, 25.737214119000043],
            [67.001062000000047, 25.73752200000007],
            [67.024917000000073, 25.741325000000074],
            [67.032946781000078, 25.744537067000067],
            [67.035278320000032, 25.745483398000033],
            [67.052307129000042, 25.746520996000072],
            [67.058435996000071, 25.744959502000029],
            [67.061910000000069, 25.744091000000026],
            [67.063223234000077, 25.744240262000062],
            [67.074349605000066, 25.745580790000076],
            [67.074365378000039, 25.745506681000052],
            [67.077122000000031, 25.745820000000037],
            [67.090260000000058, 25.747894000000031],
            [67.102706000000069, 25.752389000000051],
            [67.111349000000075, 25.762069000000054],
            [67.116610912000056, 25.770750584000041],
            [67.118264000000067, 25.773478000000068],
            [67.118862261000061, 25.775042878000079],
            [67.122758000000033, 25.785233000000062],
            [67.122594129000049, 25.792951088000052],
            [67.122413000000051, 25.801482000000078],
            [67.120338000000061, 25.815311000000065],
            [67.119901492000054, 25.818629773000055],
            [67.118610000000047, 25.828449000000035],
            [67.119301000000064, 25.838130000000035],
            [67.125178000000062, 25.842624000000058],
            [67.130710000000079, 25.848156000000074],
            [67.13555000000008, 25.856108000000063],
            [67.136588000000074, 25.861639000000025],
            [67.135205000000042, 25.869245000000035],
            [67.132093000000054, 25.876160000000027],
            [67.132093000000054, 25.876253943000052],
            [67.132093000000054, 25.879752955000072],
            [67.132093000000054, 25.884112000000073],
            [67.132027269000048, 25.885361660000058],
            [67.131402000000037, 25.897249000000045],
            [67.132038037000029, 25.904886966000049],
            [67.132093000000054, 25.90554700000007],
            [67.131856184000071, 25.907994410000072],
            [67.131056000000058, 25.916264000000069],
            [67.128636000000029, 25.931822000000068],
            [67.12707899600008, 25.957040237000058],
            [67.126907000000074, 25.959826000000078],
            [67.126846980000039, 25.960159516000033],
            [67.123796000000084, 25.977113000000031],
            [67.118610000000047, 25.994745000000023],
            [67.118610000000047, 26.009957000000043],
            [67.128636000000029, 26.031392000000039],
            [67.133822000000066, 26.051445000000058],
            [67.137191690000066, 26.06155569200007],
            [67.137970000000053, 26.063891000000069],
            [67.139008000000047, 26.077720000000056],
            [67.139015047000044, 26.077846851000061],
            [67.141428000000076, 26.121282000000065],
            [67.141428000000076, 26.139951000000053],
            [67.14108200000004, 26.161387000000047],
            [67.14108200000004, 26.173833000000059],
            [67.147997000000032, 26.189045000000078],
            [67.147997000000032, 26.193156618000046],
            [67.147997000000032, 26.201837000000069],
            [67.147997000000032, 26.227767000000028],
            [67.146602716000075, 26.236594330000059],
            [67.145874023000033, 26.240905762000068],
            [67.146131240000045, 26.250312560000054],
            [67.146268000000077, 26.256462000000056],
            [67.147651000000053, 26.27340300000003],
            [67.140736000000061, 26.279972000000043],
            [67.129327000000046, 26.283775000000048],
            [67.119301000000064, 26.288269000000071],
            [67.108583000000067, 26.295530000000042],
            [67.10468902100007, 26.306564193000042],
            [67.10443500000008, 26.307284000000038],
            [67.099249000000043, 26.31661900000006],
            [67.095446000000038, 26.32941100000005],
            [67.093026000000066, 26.341857000000061],
            [67.089223000000061, 26.353958000000034],
            [67.089223000000061, 26.367787000000078],
            [67.089216271000055, 26.367800974000033],
            [67.085629745000062, 26.375249298000028],
            [67.084728000000041, 26.377122000000043],
            [67.07435600000008, 26.384728000000052],
            [67.073731630000054, 26.385291954000024],
            [67.07001243600007, 26.388651271000072],
            [67.06363900000008, 26.394408000000055],
            [67.057070000000067, 26.40581700000007],
            [67.047389000000067, 26.423104000000023],
            [67.040475000000072, 26.436587000000031],
            [67.034950858000059, 26.443031684000061],
            [67.034252000000038, 26.443847000000062],
            [67.02284300000008, 26.454219000000023],
            [67.015174382000055, 26.470889951000061],
            [67.014891000000034, 26.471506000000034],
            [67.012994444000071, 26.473908329000039],
            [67.004519000000073, 26.48464400000006],
            [67.002041499000029, 26.490062973000079],
            [66.999066317000029, 26.496570512000062],
            [66.998987000000056, 26.496744000000035],
            [66.986887000000081, 26.506079000000057],
            [66.986883685000066, 26.506098894000047],
            [66.986789620000081, 26.506663370000069],
            [66.985850000000028, 26.512302000000034],
            [66.983156535000035, 26.522093777000066],
            [66.982046000000082, 26.526131000000078],
            [66.981723192000061, 26.534218054000064],
            [66.981701000000044, 26.534774000000027],
            [66.981695539000043, 26.534786908000058],
            [66.977898000000039, 26.543763000000069],
            [66.966834000000063, 26.557938000000036],
            [66.962686000000076, 26.567273000000057],
            [66.96545100000003, 26.575225000000046],
            [66.965497819000063, 26.575268066000035],
            [66.965515137000068, 26.575317383000026],
            [66.974121094000054, 26.583312988000046],
            [66.99113513900005, 26.59139198500003],
            [66.993456000000037, 26.592511000000059],
            [66.994529008000029, 26.593107137000061],
            [67.001969015000043, 26.597240627000076],
            [67.002140912000073, 26.597336129000041],
            [67.002140977000067, 26.597336165000058],
            [67.008755976000032, 26.601011299000049],
            [67.01523700000007, 26.604612000000031],
            [67.052921000000083, 26.623972000000037],
            [67.103052000000048, 26.650594000000069],
            [67.126907000000074, 26.655088000000035],
            [67.127254064000056, 26.655088000000035],
            [67.127546794000068, 26.655088000000035],
            [67.127546823000046, 26.655087543000036],
            [67.128896000000054, 26.633977000000073],
            [67.141744986000049, 26.596856602000059],
            [67.145606966000059, 26.58291342800004],
            [67.14931494700005, 26.56280543500003],
            [67.150597000000062, 26.55585300000007],
            [67.155985009000062, 26.537154005000048],
            [67.16203900000005, 26.514028000000053],
            [67.168157824000048, 26.499955947000046],
            [67.17742700000008, 26.477334000000042],
            [67.193605000000048, 26.45366000000007],
            [67.215306000000055, 26.42840700000005],
            [67.23240710400006, 26.404807164000033],
            [67.240132330000051, 26.393259626000031],
            [67.255157000000054, 26.370801000000029],
            [67.265611772000057, 26.347194002000037],
            [67.294103531000076, 26.293312430000071],
            [67.294103729000028, 26.293312062000041],
            [67.319866000000047, 26.245328000000029],
            [67.328547000000071, 26.223232000000053],
            [67.332115791000035, 26.218852009000045],
            [67.337227000000041, 26.212579000000062],
            [67.343540000000075, 26.203110000000038],
            [67.34606683100003, 26.192842636000023],
            [67.348275000000058, 26.183776000000023],
            [67.353438076000032, 26.17112905700003],
            [67.356551804000048, 26.162371852000035],
            [67.359323000000074, 26.154578000000072],
            [67.391677000000072, 26.135639000000026],
            [67.400367514000038, 26.11697160600005],
            [67.402331000000061, 26.112754000000052],
            [67.416959893000069, 26.096498904000043],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "28",
      properties: { name: "Loralai", count: 395 },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [69.619193296000049, 30.150618622000025],
              [69.619226609000066, 30.150595998000028],
              [69.619226559000083, 30.150595946000067],
              [69.619193296000049, 30.150618622000025],
            ],
          ],
          [
            [
              [69.619193296000049, 30.150618622000025],
              [69.616278659000045, 30.147583713000074],
              [69.607867000000056, 30.138835000000029],
              [69.60100871800006, 30.13830764000005],
              [69.598087786000065, 30.138083038000048],
              [69.593375649000052, 30.13772070400006],
              [69.589413000000036, 30.13741600000003],
              [69.581605000000081, 30.145223000000044],
              [69.572378000000072, 30.150192000000061],
              [69.565641688000028, 30.149155307000058],
              [69.563155419000054, 30.148772680000036],
              [69.563151000000062, 30.148772000000065],
              [69.562464112000043, 30.152207893000025],
              [69.561732000000063, 30.15587000000005],
              [69.564571000000058, 30.16509700000006],
              [69.565439764000075, 30.170310402000041],
              [69.566700000000083, 30.177873001000023],
              [69.569539000000077, 30.192068000000063],
              [69.579476000000056, 30.194907000000057],
              [69.587342542000044, 30.207114510000054],
              [69.594378960000029, 30.224003106000055],
              [69.594381000000055, 30.224008000000026],
              [69.589413000000036, 30.233234999000047],
              [69.577347000000032, 30.227557000000047],
              [69.568120000000079, 30.220459000000062],
              [69.55321500000008, 30.208393000000058],
              [69.543580753000072, 30.198759319000033],
              [69.540301470000031, 30.195480228000065],
              [69.536584273000074, 30.191763250000065],
              [69.532710777000034, 30.187815939000075],
              [69.523461299000076, 30.178369599000064],
              [69.513645262000068, 30.168344641000033],
              [69.508325847000037, 30.16291201100006],
              [69.505675473000053, 30.160205227000063],
              [69.504785525000045, 30.159296338000047],
              [69.502211100000068, 30.156725945000062],
              [69.493907687000046, 30.149081555000066],
              [69.490373801000032, 30.145828145000053],
              [69.487846058000059, 30.143501022000066],
              [69.477927419000082, 30.134369602000049],
              [69.476183098000035, 30.132763724000029],
              [69.466478965000078, 30.123829784000066],
              [69.458031073000029, 30.116033234000042],
              [69.446064611000054, 30.10202961300007],
              [69.434039408000046, 30.087957254000059],
              [69.424748781000062, 30.077085001000057],
              [69.412123781000048, 30.065333000000066],
              [69.412131803000079, 30.065300916000069],
              [69.412131682000052, 30.06530080400006],
              [69.412129547000063, 30.065298816000052],
              [69.404172394000057, 30.05789007900006],
              [69.399204956000062, 30.045112386000028],
              [69.396366349000061, 30.03659420300005],
              [69.380751289000045, 30.027367373000061],
              [69.367974877000051, 30.025949600000047],
              [69.359457546000044, 30.025950586000079],
              [69.358748148000075, 30.015301779000026],
              [69.353069844000061, 30.016722688000073],
              [69.345261835000031, 30.01175371000005],
              [69.339583824000044, 30.008204738000074],
              [69.326807328000029, 30.008206217000065],
              [69.321665637000081, 30.008206812000026],
              [69.313321805000044, 30.008207778000042],
              [69.296286144000078, 30.008209750000049],
              [69.282090594000067, 30.008211393000067],
              [69.26292601800003, 30.00537410700008],
              [69.260805429000072, 30.005525890000058],
              [69.243051163000075, 30.006796660000077],
              [69.225306433000071, 30.007508840000071],
              [69.210400897000056, 30.006800440000063],
              [69.210334929000055, 30.006793114000061],
              [69.197624485000063, 30.005381667000051],
              [69.186977114000058, 30.004672773000038],
              [69.170651648000046, 30.001835159000052],
              [69.158585432000052, 29.997577800000045],
              [69.15582227200008, 29.994584008000061],
              [69.150068647000069, 29.988350148000052],
              [69.137292151000054, 29.988351627000043],
              [69.121676755000067, 29.98480380500007],
              [69.108900301000062, 29.984095158000059],
              [69.098962874000051, 29.984806434000063],
              [69.092572000000075, 29.989783000000045],
              [69.080506000000071, 29.989783000000045],
              [69.070570000000032, 29.986234000000024],
              [69.057084000000032, 29.979846000000066],
              [69.047147000000052, 29.974878000000047],
              [69.03295200000008, 29.977717000000041],
              [69.020176000000049, 29.979846000000066],
              [69.018725673000063, 29.980738642000063],
              [69.011377960000061, 29.985262299000055],
              [69.010949000000039, 29.985525000000052],
              [68.995334000000071, 29.984105000000056],
              [68.989854000000037, 29.985279000000048],
              [68.985397000000034, 29.986234000000024],
              [68.980428000000074, 29.999720000000025],
              [68.971201000000065, 30.009657000000061],
              [68.952742189000048, 30.018898407000052],
              [68.952706595000052, 30.018906321000031],
              [68.939965525000048, 30.02173939000005],
              [68.936754366000059, 30.02047807100007],
              [68.920092216000057, 30.013933305000023],
              [68.881060000000048, 30.003269000000046],
              [68.870748275000039, 29.997912583000073],
              [68.86473600000005, 29.994752000000062],
              [68.851960000000076, 29.98339500000003],
              [68.841313000000071, 29.975588000000073],
              [68.838943638000046, 29.970355677000043],
              [68.834925000000055, 29.961392000000046],
              [68.821331367000028, 29.953998941000066],
              [68.81931000000003, 29.952875000000063],
              [68.801566000000037, 29.942229000000054],
              [68.769626000000073, 29.927323000000058],
              [68.73839600000008, 29.921645000000069],
              [68.73839147700005, 29.921644462000074],
              [68.725266412000053, 29.920093577000046],
              [68.721500303000028, 29.919642011000064],
              [68.685163000000045, 29.915257000000054],
              [68.685155386000076, 29.915256691000025],
              [68.632640000000038, 29.913128000000029],
              [68.592183000000034, 29.912418000000059],
              [68.564502000000061, 29.910999000000061],
              [68.549597000000063, 29.921645000000069],
              [68.537531000000058, 29.927323000000058],
              [68.528349192000064, 29.928836976000071],
              [68.528349113000047, 29.92883686700003],
              [68.519786365000073, 29.930161817000055],
              [68.519785998000032, 29.930161995000049],
              [68.495654367000043, 29.941518822000035],
              [68.495654000000059, 29.941519000000028],
              [68.470102000000054, 29.959263000000078],
              [68.448176916000079, 29.981232232000025],
              [68.445237557000041, 29.984171982000078],
              [68.413423621000049, 30.011043438000058],
              [68.413295724000079, 30.011151466000058],
              [68.409620382000071, 30.01441920700006],
              [68.408982894000076, 30.014985997000053],
              [68.396733634000043, 30.025876797000024],
              [68.394129974000066, 30.028191708000065],
              [68.393340898000076, 30.033974805000071],
              [68.393037025000069, 30.036201869000024],
              [68.392000455000073, 30.04379883200005],
              [68.391999216000045, 30.043807910000055],
              [68.391998968000053, 30.043809726000063],
              [68.391765450000037, 30.044101669000042],
              [68.390597573000036, 30.045561746000033],
              [68.380644482000037, 30.058005068000057],
              [68.380641689000072, 30.058008560000076],
              [68.380464600000039, 30.058090656000047],
              [68.351538763000065, 30.071500322000077],
              [68.343717692000041, 30.076314839000077],
              [68.323855683000033, 30.088541550000059],
              [68.286921186000029, 30.106399262000025],
              [68.281266814000048, 30.109133133000057],
              [68.266360649000035, 30.11907362200003],
              [68.249324401000081, 30.129014357000074],
              [68.226610016000052, 30.137535498000034],
              [68.217422591000059, 30.14672471800003],
              [68.217415567000046, 30.146731743000032],
              [68.217382113000042, 30.146765204000076],
              [68.215252192000037, 30.160962970000071],
              [68.211813459000041, 30.171280205000073],
              [68.21184609800008, 30.171304684000063],
              [68.211850886000036, 30.171308275000058],
              [68.221005398000045, 30.178174358000035],
              [68.242205220000073, 30.178171904000067],
              [68.266939319000073, 30.175813623000067],
              [68.275898617000053, 30.172366213000032],
              [68.282251261000056, 30.169921805000058],
              [68.303452640000046, 30.160495679000064],
              [68.313613968000084, 30.160494502000063],
              [68.318764234000071, 30.160493906000056],
              [68.337608826000064, 30.162848143000076],
              [68.351573492000057, 30.168956888000025],
              [68.356453070000043, 30.171091425000043],
              [68.368230248000032, 30.175801898000032],
              [68.374208722000049, 30.176430456000048],
              [68.390608977000056, 30.17815472500007],
              [68.410631545000058, 30.181687034000049],
              [68.423391864000052, 30.18624310000007],
              [68.427119837000077, 30.187574171000051],
              [68.444787592000068, 30.18639491700003],
              [68.460098255000048, 30.185214935000033],
              [68.469521342000064, 30.189925681000034],
              [68.483653986000036, 30.205237762000024],
              [68.49150439500005, 30.211661392000053],
              [68.496608862000073, 30.215838144000031],
              [68.504085481000061, 30.22043900500006],
              [68.507224218000033, 30.222370479000062],
              [68.511919899000077, 30.225260043000048],
              [68.530764213000054, 30.232325116000027],
              [68.544897622000065, 30.234679898000024],
              [68.544155712000077, 30.239129538000043],
              [68.543277180000075, 30.244398573000069],
              [68.542540695000071, 30.248815679000074],
              [68.548429297000041, 30.259416879000071],
              [68.573163118000082, 30.26176943400003],
              [68.57775173400006, 30.265593301000024],
              [68.577752070000031, 30.265593581000076],
              [68.577762168000049, 30.265472141000032],
              [68.577785880000079, 30.265491900000029],
              [68.577785873000039, 30.265491989000054],
              [68.576730000000055, 30.278157000000078],
              [68.563775000000078, 30.293468000000075],
              [68.539042000000052, 30.293468000000075],
              [68.522446194000054, 30.294257766000044],
              [68.514309000000083, 30.29464500000006],
              [68.498998000000029, 30.30524500000007],
              [68.496825892000061, 30.310132360000068],
              [68.489576000000056, 30.326445000000035],
              [68.48486500000007, 30.342933000000073],
              [68.494287000000043, 30.353533000000027],
              [68.506940193000048, 30.363022448000038],
              [68.508421000000055, 30.364133000000038],
              [68.513696395000068, 30.366051363000054],
              [68.521376000000032, 30.368844000000024],
              [68.523731000000055, 30.373555000000067],
              [68.516001695000057, 30.381284305000065],
              [68.512995057000069, 30.384290943000053],
              [68.50959800000004, 30.387688000000026],
              [68.490754000000038, 30.38533300000006],
              [68.473088000000075, 30.386510000000044],
              [68.457777000000078, 30.386510000000044],
              [68.440111000000059, 30.382977000000039],
              [68.415378000000032, 30.374733000000049],
              [68.413179477000028, 30.374733000000049],
              [68.406048440000063, 30.374733000000049],
              [68.391823000000045, 30.374733000000049],
              [68.357668000000047, 30.374733000000049],
              [68.349752314000057, 30.375342018000026],
              [68.34235700000005, 30.375911000000031],
              [68.333720067000058, 30.380229204000045],
              [68.325868000000071, 30.384155000000078],
              [68.31762400000008, 30.39122100000003],
              [68.316791959000057, 30.405362869000044],
              [68.316446000000042, 30.41124300000007],
              [68.31762400000008, 30.424199000000044],
              [68.316446000000042, 30.437154000000078],
              [68.31538357900007, 30.438134681000065],
              [68.301135657000032, 30.451286394000078],
              [68.301135000000045, 30.451287000000036],
              [68.288180000000068, 30.454820000000041],
              [68.266980000000046, 30.454820000000041],
              [68.254820635000044, 30.454820000000041],
              [68.25402500000007, 30.454820000000041],
              [68.250858826000069, 30.456281391000061],
              [68.238714000000073, 30.461887000000047],
              [68.23497453400006, 30.467229030000055],
              [68.230470000000082, 30.473664000000042],
              [68.230470000000082, 30.477494582000077],
              [68.230470000000082, 30.487001532000079],
              [68.230470000000082, 30.492509000000041],
              [68.230470000000082, 30.511353000000042],
              [68.22996020800008, 30.511862792000045],
              [68.229947438000067, 30.511875562000057],
              [68.22994740200005, 30.511875598000074],
              [68.211626000000081, 30.530197000000044],
              [68.201854251000043, 30.529473030000077],
              [68.179826000000048, 30.527841000000024],
              [68.177066368000055, 30.527644003000034],
              [68.163338000000067, 30.526664000000039],
              [68.161589194000044, 30.526664000000039],
              [68.161368615000072, 30.526664000000039],
              [68.161230466000063, 30.526664000000039],
              [68.161211983000044, 30.526664000000039],
              [68.14802700000007, 30.526664000000039],
              [68.138605000000041, 30.532552000000067],
              [68.125649000000067, 30.541974000000039],
              [68.115756819000069, 30.541150177000077],
              [68.111516000000051, 30.540797000000055],
              [68.100058470000079, 30.539915479000058],
              [68.096205000000054, 30.539619000000073],
              [68.085606000000041, 30.537263000000053],
              [68.071369120000043, 30.535073212000043],
              [68.070295000000044, 30.53490800000003],
              [68.052628000000084, 30.53490800000003],
              [68.052268556000058, 30.535015831000067],
              [68.04701932100005, 30.536590556000078],
              [68.040851000000032, 30.538441000000034],
              [68.026718000000074, 30.536086000000068],
              [68.017296000000044, 30.532552000000067],
              [68.015380898000046, 30.529679346000023],
              [68.007874000000072, 30.518419000000051],
              [67.999629000000084, 30.523130000000037],
              [67.998963458000048, 30.522686306000026],
              [67.985496000000069, 30.513708000000065],
              [67.98201791300005, 30.505013151000071],
              [67.980785000000083, 30.50193100000007],
              [67.969008000000031, 30.491331000000059],
              [67.969008000000031, 30.479553000000067],
              [67.967387444000053, 30.478627053000025],
              [67.960763000000043, 30.474842000000024],
              [67.938386000000037, 30.483087000000069],
              [67.926743634000047, 30.490251416000035],
              [67.92307500000004, 30.492509000000041],
              [67.901876000000073, 30.492509000000041],
              [67.888455505000081, 30.494822567000028],
              [67.880721103000042, 30.496155905000023],
              [67.867721000000074, 30.498397000000068],
              [67.850054000000057, 30.49957500000005],
              [67.848988625000061, 30.49957500000005],
              [67.835100858000033, 30.49957500000005],
              [67.831210000000056, 30.49957500000005],
              [67.804122000000064, 30.500753000000032],
              [67.784831008000083, 30.499266975000069],
              [67.784830900000031, 30.49926696700004],
              [67.784335500000054, 30.500753036000049],
              [67.78231900000003, 30.50680200000005],
              [67.780255000000068, 30.518155000000036],
              [67.776463044000081, 30.520133405000024],
              [67.756517000000031, 30.53054000000003],
              [67.754045075000079, 30.532500526000035],
              [67.726587000000052, 30.554278000000068],
              [67.707780914000068, 30.576218180000069],
              [67.701817000000062, 30.583176000000037],
              [67.699236877000033, 30.591432961000066],
              [67.699236866000035, 30.591433000000052],
              [67.69905262900005, 30.592022516000043],
              [67.691496000000029, 30.616202000000044],
              [67.69277956500008, 30.617081426000027],
              [67.692798553000046, 30.61709443500007],
              [67.693916000000058, 30.616518000000042],
              [67.738972000000047, 30.62239500000004],
              [67.74558320400007, 30.62121428100005],
              [67.750850539000055, 30.620273568000073],
              [67.758020017000035, 30.618993145000047],
              [67.767870000000073, 30.617234000000053],
              [67.802296098000056, 30.611798716000067],
              [67.807089000000076, 30.611042000000054],
              [67.818393551000042, 30.615425361000064],
              [67.818726604000062, 30.615554503000055],
              [67.82363063300005, 30.617456049000054],
              [67.857660000000067, 30.630651000000057],
              [67.90100700000005, 30.64510000000007],
              [67.904972331000067, 30.646421777000057],
              [67.924610818000076, 30.652967939000064],
              [67.931265039000039, 30.655186013000048],
              [67.938162000000034, 30.657485000000065],
              [67.940200043000061, 30.650630010000043],
              [67.946656852000046, 30.628912426000056],
              [67.949515000000076, 30.619299000000069],
              [67.960868000000062, 30.596593000000041],
              [67.989364608000074, 30.585194174000037],
              [67.99183000000005, 30.584208001000036],
              [68.014536000000078, 30.603817000000049],
              [68.029724196000075, 30.604985802000044],
              [68.041370000000029, 30.605882000000065],
              [68.054806049000035, 30.606965484000057],
              [68.105358000000081, 30.611042000000054],
              [68.146641000000045, 30.608978000000036],
              [68.148560000000032, 30.606772000000035],
              [68.167283000000054, 30.585240000000056],
              [68.174159236000037, 30.574318758000061],
              [68.179265020000059, 30.566209451000077],
              [68.184828000000039, 30.557374000000038],
              [68.225305381000055, 30.547254416000044],
              [68.22611100000006, 30.547053000000062],
              [68.262233000000037, 30.544989000000044],
              [68.292138087000069, 30.555906811000057],
              [68.327254000000039, 30.568727000000024],
              [68.356285719000084, 30.580823354000074],
              [68.36440900000008, 30.584208000000046],
              [68.380186145000039, 30.580943982000065],
              [68.380969027000049, 30.580782017000047],
              [68.394339000000059, 30.578016000000048],
              [68.400514618000045, 30.571399084000063],
              [68.400513253000042, 30.571198935000041],
              [68.400791755000057, 30.571102143000076],
              [68.408788000000072, 30.562534500000027],
              [68.433558000000062, 30.554278000000068],
              [68.446975000000066, 30.537765000000036],
              [68.46864800000003, 30.535701000000074],
              [68.468650424000032, 30.535703769000065],
              [68.475873000000036, 30.543957000000034],
              [68.501675000000034, 30.554278000000068],
              [68.528701935000072, 30.555243181000037],
              [68.530573000000061, 30.555310000000077],
              [68.583209000000068, 30.556342000000029],
              [68.585924609000074, 30.557699871000068],
              [68.603850000000079, 30.566663000000062],
              [68.63171600000004, 30.571823000000052],
              [68.654422000000068, 30.569759000000033],
              [68.67104789800004, 30.566144826000027],
              [68.671209303000069, 30.566109740000059],
              [68.678159000000051, 30.564599000000044],
              [68.695705000000032, 30.568727000000024],
              [68.697705535000068, 30.567202760000043],
              [68.715431206000062, 30.553697293000027],
              [68.717378000000053, 30.552214000000049],
              [68.749372000000051, 30.540861001000053],
              [68.750810717000036, 30.538463242000034],
              [68.758661000000075, 30.525380000000041],
              [68.764854000000071, 30.50680200000005],
              [68.795816000000059, 30.505770000000041],
              [68.806347929000083, 30.508403046000069],
              [68.83709900000008, 30.516091000000074],
              [68.850098756000079, 30.521662381000056],
              [68.902119000000084, 30.543957000000034],
              [68.926889000000074, 30.558406000000048],
              [68.942370000000039, 30.570791000000042],
              [68.958939850000036, 30.575393241000029],
              [68.96094800000003, 30.575951000000032],
              [68.97620865500005, 30.580113058000052],
              [69.003861079000046, 30.587654739000072],
              [69.006359000000032, 30.588336000000027],
              [69.039561647000028, 30.597822602000065],
              [69.042482000000064, 30.59865700000006],
              [69.053834000000052, 30.610010000000045],
              [69.069442805000051, 30.62686741300007],
              [69.07963600000005, 30.637876000000063],
              [69.088682832000075, 30.643304029000035],
              [69.105438000000049, 30.653357000000028],
              [69.125887969000075, 30.657333612000059],
              [69.140356160000067, 30.660147034000033],
              [69.142593000000034, 30.660582000000034],
              [69.151513525000041, 30.662960653000027],
              [69.158074000000056, 30.664710000000071],
              [69.158074000000056, 30.673999000000038],
              [69.155616000000066, 30.678915000000075],
              [69.155577000000051, 30.678992000000051],
              [69.153946000000076, 30.682255000000055],
              [69.154654849000053, 30.684076611000023],
              [69.154654896000068, 30.684076731000062],
              [69.154655000000048, 30.684077000000059],
              [69.156161612000062, 30.683888250000052],
              [69.156217429000037, 30.683881262000057],
              [69.156906000000049, 30.683795000000032],
              [69.157171595000079, 30.683762000000058],
              [69.15740500000004, 30.683733000000075],
              [69.167803000000049, 30.68243300000006],
              [69.181333874000075, 30.683021092000047],
              [69.193411000000083, 30.683546000000035],
              [69.197868587000073, 30.681972761000054],
              [69.212338000000045, 30.676866000000075],
              [69.21456500000005, 30.659052000000031],
              [69.214782180000043, 30.658400487000051],
              [69.218689080000047, 30.646680290000063],
              [69.22235900000004, 30.635671000000059],
              [69.226518741000064, 30.627350896000053],
              [69.226518877000046, 30.627350623000041],
              [69.229039000000057, 30.622310000000027],
              [69.245905156000049, 30.622765676000029],
              [69.270235000000071, 30.623423000000059],
              [69.273080504000063, 30.62274012000006],
              [69.298070000000052, 30.616743000000042],
              [69.29833463500006, 30.616765056000077],
              [69.299750644000028, 30.616883069000039],
              [69.324791000000062, 30.618970000000047],
              [69.344832000000054, 30.611176000000057],
              [69.362646000000041, 30.591135000000065],
              [69.371553000000063, 30.572207000000049],
              [69.400502000000074, 30.553280000000029],
              [69.417126598000038, 30.543048992000024],
              [69.417213596000067, 30.542995452000071],
              [69.429450000000031, 30.535465000000045],
              [69.468418000000042, 30.518764000000033],
              [69.482429142000058, 30.508756899000048],
              [69.491800000000069, 30.502064000000075],
              [69.536182808000035, 30.474195694000059],
              [69.536286414000074, 30.474318164000067],
              [69.536315918000071, 30.474304199000073],
              [69.539672852000081, 30.472106934000067],
              [69.562072754000042, 30.446472168000071],
              [69.573414709000076, 30.443747875000042],
              [69.574446722000062, 30.443509825000035],
              [69.574483110000074, 30.443501432000062],
              [69.574515928000039, 30.44350924400004],
              [69.574518218000037, 30.443509789000075],
              [69.590887076000058, 30.439729223000029],
              [69.599657515000047, 30.435342345000038],
              [69.61710430100004, 30.426615654000045],
              [69.617675781000059, 30.426330566000047],
              [69.629882813000052, 30.410888672000056],
              [69.650096849000079, 30.409833544000037],
              [69.650113428000054, 30.409868294000034],
              [69.650128689000042, 30.409831882000049],
              [69.652099609000061, 30.409729004000042],
              [69.657714844000054, 30.400878906000059],
              [69.657676540000068, 30.400775066000051],
              [69.65769497000008, 30.400745574000041],
              [69.655937101000063, 30.395973250000054],
              [69.650035296000055, 30.379950821000079],
              [69.649902724000071, 30.379590909000058],
              [69.663095380000073, 30.378893891000075],
              [69.663161814000034, 30.378735382000059],
              [69.666687012000068, 30.378479004000042],
              [69.690124512000068, 30.368530273000033],
              [69.706726074000073, 30.35968017600004],
              [69.72108804100003, 30.369660526000075],
              [69.721130371000072, 30.369689941000047],
              [69.740112305000082, 30.35968017600004],
              [69.744323730000076, 30.356872559000067],
              [69.744352454000079, 30.356854241000065],
              [69.742988788000048, 30.355323526000063],
              [69.733497000000057, 30.344669000000067],
              [69.724979000000076, 30.334733000000028],
              [69.724988490000044, 30.334717580000074],
              [69.730658000000062, 30.325505000000078],
              [69.730656062000037, 30.325498701000072],
              [69.727819000000068, 30.316278000000068],
              [69.71859100000006, 30.312020000000075],
              [69.713615343000072, 30.307013434000055],
              [69.711501720000058, 30.304899339000031],
              [69.711502431000042, 30.292861932000051],
              [69.711494000000073, 30.292856000000029],
              [69.707897108000054, 30.288605421000057],
              [69.703686000000062, 30.283629000000076],
              [69.701026117000083, 30.27618147700008],
              [69.700137000000041, 30.27369200000004],
              [69.69727847200005, 30.269601948000059],
              [69.695853868000029, 30.267563589000076],
              [69.695853843000066, 30.267563553000059],
              [69.694484546000069, 30.26564324800006],
              [69.691620000000057, 30.261626000000035],
              [69.684522000000072, 30.249560000000031],
              [69.667488000000048, 30.233234999000047],
              [69.666517427000031, 30.232163987000035],
              [69.646905000000061, 30.210522000000026],
              [69.646905000000061, 30.182132000000024],
              [69.640937060000056, 30.176500605000058],
              [69.63341900000006, 30.16935600000005],
              [69.625611000000049, 30.157290001000035],
              [69.619193296000049, 30.150618622000025],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "29",
      properties: { name: "Mastung", count: 953 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.281944000000067, 30.230396000000042],
            [67.28123400000004, 30.219040000000064],
            [67.281944000000067, 30.204134000000067],
            [67.29046100000005, 30.191359000000034],
            [67.301817000000028, 30.177163000000064],
            [67.307927664000033, 30.175635245000024],
            [67.318852000000049, 30.172904000000074],
            [67.337306000000069, 30.173614000000043],
            [67.362858000000074, 30.17432400000007],
            [67.383441000000062, 30.17432400000007],
            [67.38763407700003, 30.180613615000027],
            [67.390532655000072, 30.184941070000036],
            [67.406732655000042, 30.183141070000033],
            [67.416269237000051, 30.169838637000055],
            [67.41751000000005, 30.167936000000054],
            [67.423898000000065, 30.133867000000066],
            [67.43099600000005, 30.107605000000035],
            [67.438094000000035, 30.086312000000078],
            [67.438094000000035, 30.066439000000059],
            [67.43099600000005, 30.053663000000029],
            [67.430667436000078, 30.053638658000068],
            [67.430375000000083, 30.053616997000063],
            [67.411832000000061, 30.052243000000033],
            [67.396217000000036, 30.04301600000008],
            [67.41254200000003, 30.03378900000007],
            [67.413961000000029, 30.027401000000054],
            [67.40757300000007, 30.01533500000005],
            [67.385571000000084, 30.008947000000035],
            [67.364987000000042, 30.00610800000004],
            [67.355050000000062, 29.995461000000034],
            [67.357889000000057, 29.979846000000066],
            [67.367826000000036, 29.961392000000046],
            [67.368854295000062, 29.955566926000074],
            [67.369956000000059, 29.949326000000042],
            [67.369246000000032, 29.933002000000045],
            [67.373394000000076, 29.92159300000003],
            [67.374924000000078, 29.917387000000076],
            [67.359309000000053, 29.910289000000034],
            [67.342984000000058, 29.90745000000004],
            [67.334467000000075, 29.913128000000029],
            [67.328079000000059, 29.930162000000053],
            [67.318852000000049, 29.942938000000026],
            [67.306786000000045, 29.939389000000062],
            [67.303237000000081, 29.923774000000037],
            [67.289751000000081, 29.920226000000071],
            [67.285237260000031, 29.922934414000053],
            [67.284907061000069, 29.923132545000044],
            [67.279105000000072, 29.926614000000029],
            [67.277685000000076, 29.940809000000058],
            [67.262070000000051, 29.957134000000053],
            [67.249294000000077, 29.970619000000056],
            [67.230130000000031, 29.974168000000077],
            [67.223742000000072, 29.960683000000074],
            [67.218774000000053, 29.960683000000074],
            [67.210967000000039, 29.968490000000031],
            [67.19180300000005, 29.982686000000058],
            [67.166961000000072, 29.972749000000078],
            [67.147087000000056, 29.956424000000027],
            [67.126504000000068, 29.940809000000058],
            [67.119406000000083, 29.918806000000075],
            [67.135731000000078, 29.878349000000071],
            [67.147087000000056, 29.85208700000004],
            [67.147087000000056, 29.818018000000052],
            [67.154185000000041, 29.78536900000006],
            [67.167670000000044, 29.765495000000044],
            [67.17760700000008, 29.752010000000041],
            [67.195352000000071, 29.737104000000045],
            [67.247165000000052, 29.716521000000057],
            [67.259231000000057, 29.703035000000057],
            [67.275556000000051, 29.703745000000026],
            [67.279105000000072, 29.691679000000079],
            [67.270587000000035, 29.679613000000074],
            [67.249294000000077, 29.66754700000007],
            [67.224452000000042, 29.656190000000038],
            [67.206708000000049, 29.636317000000076],
            [67.197481000000039, 29.617863000000057],
            [67.195352000000071, 29.602248000000031],
            [67.192512000000079, 29.578115000000025],
            [67.175478000000055, 29.567469000000074],
            [67.141409000000067, 29.544046000000037],
            [67.126504000000068, 29.531270000000063],
            [67.12721300000004, 29.520624000000055],
            [67.127669755000056, 29.510583989000054],
            [67.127923000000067, 29.50500900000003],
            [67.116567000000032, 29.48584500000004],
            [67.100952000000063, 29.474489000000062],
            [67.091725000000054, 29.458164000000068],
            [67.078239000000053, 29.456744000000072],
            [67.076820000000055, 29.443969000000038],
            [67.072561000000064, 29.432612000000063],
            [67.051978000000076, 29.432612000000063],
            [67.021457000000055, 29.434741000000031],
            [66.980291000000079, 29.440420000000074],
            [66.950480000000027, 29.441839000000073],
            [66.91996000000006, 29.435451000000057],
            [66.884471000000076, 29.426934000000074],
            [66.836917000000028, 29.421966000000054],
            [66.818700843000045, 29.430423305000033],
            [66.817043000000069, 29.431193000000064],
            [66.819882000000064, 29.443259000000069],
            [66.836207000000059, 29.461713000000032],
            [66.858210000000042, 29.487974000000065],
            [66.867437000000052, 29.509268000000077],
            [66.871695000000045, 29.52772200000004],
            [66.867437000000052, 29.544046000000037],
            [66.858920000000069, 29.549725000000024],
            [66.820592000000033, 29.562500000000057],
            [66.80213800000007, 29.564630000000079],
            [66.766649000000029, 29.542627000000039],
            [66.746066000000042, 29.517075000000034],
            [66.726192000000083, 29.500750000000039],
            [66.711550359000057, 29.493111057000078],
            [66.711550312000043, 29.493110897000065],
            [66.711547214000063, 29.493100275000074],
            [66.70989628600006, 29.492239136000023],
            [66.688603286000046, 29.470946136000066],
            [66.668730286000027, 29.446104136000031],
            [66.651986731000079, 29.441918002000079],
            [66.651695286000063, 29.44184513600004],
            [66.651692967000031, 29.44184567700006],
            [66.651692832000037, 29.441845709000063],
            [66.634602701000063, 29.445833914000048],
            [66.630402285000059, 29.446814137000047],
            [66.619046285000081, 29.46029913700005],
            [66.619017000000042, 29.461991046000037],
            [66.619017000000042, 29.492943000000025],
            [66.620607851000045, 29.498205031000055],
            [66.628244000000052, 29.523463000000049],
            [66.635371487000043, 29.541792463000036],
            [66.638209286000063, 29.549021136000079],
            [66.640057745000036, 29.554011820000028],
            [66.640058557000032, 29.554014014000074],
            [66.640058381000074, 29.554014242000051],
            [66.640057484000067, 29.554011820000028],
            [66.640039380000076, 29.554035168000041],
            [66.640039436000052, 29.554035319000036],
            [66.645278000000076, 29.568179000000043],
            [66.645679464000068, 29.579014568000048],
            [66.645679734000055, 29.579014794000045],
            [66.646017286000074, 29.58734813600006],
            [66.645307285000058, 29.601544137000076],
            [66.628982286000053, 29.619288136000023],
            [66.626569177000079, 29.620203530000026],
            [66.613077751000048, 29.62532139700005],
            [66.608399286000065, 29.627096136000034],
            [66.582137285000044, 29.622127137000064],
            [66.582135935000053, 29.622126285000036],
            [66.582135823000044, 29.622126215000037],
            [66.549488286000042, 29.601544137000076],
            [66.537463720000062, 29.591142215000048],
            [66.537463428000081, 29.591141962000052],
            [66.51751900000005, 29.573857000000032],
            [66.484160000000031, 29.541917000000069],
            [66.453640000000064, 29.507138000000054],
            [66.433056000000079, 29.478747000000055],
            [66.417441000000053, 29.451066000000026],
            [66.40963400000004, 29.434741000000031],
            [66.396148000000039, 29.411319000000049],
            [66.396148000000039, 29.397861428000056],
            [66.396148000000039, 29.397124000000076],
            [66.391890000000046, 29.37725000000006],
            [66.386211001000049, 29.357375999000055],
            [66.375565000000051, 29.34531000000004],
            [66.339366000000041, 29.338212000000055],
            [66.313105000000064, 29.336083000000031],
            [66.294651000000044, 29.341761000000076],
            [66.264131000000077, 29.347439000000065],
            [66.24638600000003, 29.362345000000062],
            [66.226513000000068, 29.368733000000077],
            [66.194565053000076, 29.383006960000046],
            [66.198831000000041, 29.388606000000038],
            [66.223674000000074, 29.412739000000045],
            [66.227932000000067, 29.435451000000057],
            [66.242837000000065, 29.451066000000026],
            [66.247096000000056, 29.468811000000073],
            [66.254194000000041, 29.479457000000025],
            [66.264131000000077, 29.496492000000046],
            [66.264840000000049, 29.517075000000034],
            [66.267679000000044, 29.536239000000023],
            [66.270210365000082, 29.539824964000047],
            [66.284714000000065, 29.560371000000032],
            [66.298873817000072, 29.573823574000073],
            [66.298876017000055, 29.573825665000072],
            [66.298909000000037, 29.573857000000032],
            [66.298932742000034, 29.574331678000078],
            [66.298934321000047, 29.57436324400004],
            [66.299559545000079, 29.586863325000024],
            [66.299619000000064, 29.588052000000062],
            [66.299900371000035, 29.593396460000065],
            [66.300329000000033, 29.601538000000062],
            [66.300706246000061, 29.601999075000037],
            [66.300707691000071, 29.602000841000063],
            [66.313105000000064, 29.61715300000003],
            [66.313153161000059, 29.617474113000071],
            [66.315234000000032, 29.63134800000006],
            [66.312231049000047, 29.634601286000077],
            [66.306717000000049, 29.640575000000069],
            [66.303757374000043, 29.642689062000045],
            [66.296780000000069, 29.647673000000054],
            [66.296488206000049, 29.651174628000035],
            [66.29648813700004, 29.65117544800006],
            [66.294500283000048, 29.675030401000072],
            [66.293941000000075, 29.681742000000042],
            [66.289682000000028, 29.694518000000073],
            [66.290392000000054, 29.716521000000057],
            [66.286133000000063, 29.724329000000068],
            [66.280455000000075, 29.74349200000006],
            [66.280455000000075, 29.75755762700004],
            [66.280455000000075, 29.757577753000078],
            [66.280455000000075, 29.763366000000076],
            [66.28126356100006, 29.771448953000061],
            [66.281263696000053, 29.771450297000058],
            [66.28258500000004, 29.784659000000033],
            [66.283336422000048, 29.786287125000058],
            [66.285320442000057, 29.790585952000072],
            [66.285320677000072, 29.790586461000032],
            [66.290572289000067, 29.801965262000067],
            [66.290573108000046, 29.801967036000065],
            [66.29110200000008, 29.803113000000053],
            [66.304588000000081, 29.814470000000028],
            [66.308136000000047, 29.827955000000031],
            [66.307427000000075, 29.837892000000068],
            [66.297490000000039, 29.845700000000079],
            [66.287722896000048, 29.857558651000033],
            [66.298909000000037, 29.854217000000062],
            [66.31949300000008, 29.85208700000004],
            [66.347174000000052, 29.85208700000004],
            [66.357767501000069, 29.849121011000079],
            [66.364918000000046, 29.847119000000077],
            [66.373905887000035, 29.847119000000077],
            [66.373905950000051, 29.847119000000077],
            [66.386211010000068, 29.847119000000077],
            [66.411763010000072, 29.847119001000067],
            [66.435895008000045, 29.851377997000043],
            [66.449381009000035, 29.840021000000036],
            [66.464996008000071, 29.828665000000058],
            [66.480611011000065, 29.812340001000052],
            [66.501194008000027, 29.809501001000058],
            [66.528876008000054, 29.808791003000067],
            [66.549459008000042, 29.825825997000038],
            [66.557266009000045, 29.844280000000026],
            [66.565784009000083, 29.862734000000046],
            [66.568416665000029, 29.871685844000069],
            [66.572881000000052, 29.886866000000055],
            [66.573225385000057, 29.895473181000057],
            [66.573591000000079, 29.904611000000045],
            [66.577850000000069, 29.921645000000069],
            [66.592045000000041, 29.935841000000039],
            [66.604111000000046, 29.947197000000074],
            [66.611919000000057, 29.960683000000074],
            [66.614758000000052, 29.979846000000066],
            [66.617597000000046, 29.99688100000003],
            [66.62621265000007, 30.016139469000052],
            [66.62966300000005, 30.023852000000034],
            [66.655925000000082, 30.077085000000068],
            [66.678637000000037, 30.119672000000037],
            [66.694252000000063, 30.148772000000065],
            [66.702682334000031, 30.15060472600004],
            [66.710577000000058, 30.152321000000029],
            [66.729031000000077, 30.148062000000039],
            [66.742517000000078, 30.136706000000061],
            [66.746776000000068, 30.126769000000024],
            [66.761681000000067, 30.121801000000062],
            [66.77516600000007, 30.116123000000073],
            [66.787233000000072, 30.115413000000046],
            [66.799299000000076, 30.111154000000056],
            [66.799299000000076, 30.102637000000072],
            [66.79787900000008, 30.087732000000074],
            [66.80254136800005, 30.082137541000066],
            [66.804976994000072, 30.079215000000033],
            [66.815622989000076, 30.079214998000054],
            [66.827689990000067, 30.071407000000079],
            [66.833062730000051, 30.067185487000074],
            [66.835938854000062, 30.064925634000076],
            [66.837625990000049, 30.063600003000033],
            [66.83549699100007, 30.05721200000005],
            [66.82910898800003, 30.050113998000029],
            [66.813493994000055, 30.045855002000053],
            [66.809944991000066, 30.03875800000003],
            [66.810654992000082, 30.026690998000049],
            [66.826954139000065, 30.021632509000028],
            [66.831238000000042, 30.020303000000069],
            [66.848983000000032, 30.025982000000056],
            [66.863888000000031, 30.034499000000039],
            [66.883762000000047, 30.04727500000007],
            [66.89724700000005, 30.053663000000029],
            [66.917121000000066, 30.044436000000076],
            [66.943382000000042, 30.045146000000045],
            [66.953817996000055, 30.044636923000041],
            [66.960612135000076, 30.044305343000076],
            [66.960636814000054, 30.044304139000076],
            [66.963270438000052, 30.044175608000046],
            [66.965403265000077, 30.044071517000077],
            [66.972483000000068, 30.043726000000049],
            [66.974383333000048, 30.043726000000049],
            [66.99317201100007, 30.043726000000049],
            [66.998745000000042, 30.043726000000049],
            [67.005783431000054, 30.043726000000049],
            [67.01861800000006, 30.043726000000049],
            [67.026426000000072, 30.052953000000059],
            [67.030684000000065, 30.067858000000058],
            [67.033149325000068, 30.07919821400003],
            [67.034233000000029, 30.084183000000053],
            [67.035653000000082, 30.103347000000042],
            [67.05055800000008, 30.105476000000067],
            [67.04700900000006, 30.120381000000066],
            [67.03778200000005, 30.135996000000034],
            [67.04700900000006, 30.150192000000061],
            [67.049906049000072, 30.152211141000066],
            [67.070432000000039, 30.166517000000056],
            [67.100952000000063, 30.170775000000049],
            [67.11349672700004, 30.171105235000027],
            [67.127923000000067, 30.171485000000075],
            [67.148507000000052, 30.180712000000028],
            [67.170510000000036, 30.195617000000027],
            [67.19109300000008, 30.213361000000077],
            [67.221334120000051, 30.231259797000064],
            [67.221613000000048, 30.231106000000068],
            [67.234389000000078, 30.226847000000078],
            [67.234415269000067, 30.226847000000078],
            [67.252836639000066, 30.226847000000078],
            [67.252843000000041, 30.226847000000078],
            [67.253665484000066, 30.228080630000079],
            [67.257102000000032, 30.233235000000036],
            [67.265619000000072, 30.241752000000076],
            [67.269404343000076, 30.246799569000075],
            [67.274136000000055, 30.253109000000052],
            [67.27764585400007, 30.248847162000061],
            [67.284073000000035, 30.241043000000047],
            [67.281944000000067, 30.230396000000042],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "30",
      properties: { name: "Musa Khel", count: 291 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.272888184000067, 31.445678711000028],
            [70.279479980000076, 31.421691895000038],
            [70.278989598000067, 31.405018897000048],
            [70.278686523000033, 31.394714355000076],
            [70.278225230000032, 31.378661355000077],
            [70.278076172000056, 31.373474121000072],
            [70.271484375000057, 31.366882324000073],
            [70.270874023000033, 31.341674805000025],
            [70.267272949000073, 31.317687988000046],
            [70.269714355000076, 31.284484863000046],
            [70.269999453000082, 31.281348790000038],
            [70.272277832000043, 31.256286621000072],
            [70.273118207000039, 31.243977603000076],
            [70.273313217000066, 31.24112128400003],
            [70.268390774000068, 31.238800863000051],
            [70.259526571000038, 31.234562033000032],
            [70.259750392000058, 31.229364416000067],
            [70.26007080100004, 31.221923828000058],
            [70.265013340000053, 31.211403733000054],
            [70.268676758000083, 31.20147705100004],
            [70.266365582000049, 31.197703550000028],
            [70.26788549500003, 31.189839961000075],
            [70.269113633000075, 31.184682924000072],
            [70.279479980000076, 31.174499512000068],
            [70.282701060000079, 31.170297177000066],
            [70.288755805000051, 31.16236890600004],
            [70.295712595000055, 31.150022123000042],
            [70.295648306000032, 31.142115698000055],
            [70.294768440000041, 31.128180685000075],
            [70.294220951000057, 31.115515464000055],
            [70.293959533000077, 31.109467977000065],
            [70.293929842000068, 31.106127732000061],
            [70.294585203000054, 31.10106746200006],
            [70.294487307000054, 31.08634963600008],
            [70.289038336000033, 31.065942360000065],
            [70.287742000000037, 31.061111000000039],
            [70.27942838000007, 31.049812734000056],
            [70.277923999000052, 31.047239956000055],
            [70.276342360000058, 31.044366557000046],
            [70.268744000000083, 31.035371000000055],
            [70.26893474600007, 31.025411825000049],
            [70.268296226000075, 31.011581642000067],
            [70.267906871000037, 31.003148310000029],
            [70.26783275300005, 31.00029541300006],
            [70.267539825000028, 30.987368269000058],
            [70.267763281000043, 30.981431551000071],
            [70.268270328000028, 30.975182029000052],
            [70.268400645000042, 30.971108318000063],
            [70.268052301000068, 30.967880917000059],
            [70.268332818000033, 30.957223548000059],
            [70.268619938000029, 30.946315353000045],
            [70.268917214000055, 30.941437990000054],
            [70.267856599000083, 30.938126597000064],
            [70.266551604000028, 30.933015736000073],
            [70.264303685000073, 30.924212022000063],
            [70.262947516000054, 30.918794460000072],
            [70.261474609000061, 30.912902832000043],
            [70.257899868000038, 30.896720989000073],
            [70.253479004000042, 30.876708984000061],
            [70.257690430000082, 30.866882324000073],
            [70.273071289000029, 30.860717773000033],
            [70.271881670000084, 30.854366741000035],
            [70.269356003000041, 30.84047898700004],
            [70.229520000000036, 30.838027000000068],
            [70.226546960000064, 30.832892311000023],
            [70.222900391000053, 30.82647705100004],
            [70.219726563000052, 30.803710938000052],
            [70.218638180000028, 30.792428037000036],
            [70.218883651000056, 30.787581934000059],
            [70.218471424000029, 30.783879697000032],
            [70.203178775000083, 30.775804399000037],
            [70.174146113000063, 30.774500226000043],
            [70.168273719000069, 30.774291220000066],
            [70.161510840000062, 30.749128441000039],
            [70.158383184000058, 30.741814180000063],
            [70.154724121000072, 30.73327636700003],
            [70.123469825000029, 30.736279750000051],
            [70.111137798000073, 30.735309173000076],
            [70.108579183000074, 30.732430198000031],
            [70.103270902000077, 30.725882868000042],
            [70.102734995000048, 30.718295561000048],
            [70.101997790000041, 30.709250377000046],
            [70.097290039000029, 30.70007324200003],
            [70.088684082000043, 30.689086914000029],
            [70.087711196000043, 30.675931804000072],
            [70.087335000000053, 30.670102000000043],
            [70.09022432200004, 30.665373473000045],
            [70.094116211000028, 30.659118652000075],
            [70.095288264000033, 30.637657943000079],
            [70.095915000000048, 30.626588000000027],
            [70.093763009000043, 30.615993355000057],
            [70.088073730000076, 30.587280273000033],
            [70.085971204000032, 30.583726830000046],
            [70.077585971000076, 30.569555093000076],
            [70.073375398000053, 30.562438877000034],
            [70.051879883000083, 30.520080566000047],
            [70.048447229000033, 30.510568708000051],
            [70.047081064000054, 30.50678307700008],
            [70.043884277000075, 30.497924805000025],
            [70.01452636700003, 30.461730957000043],
            [70.004699707000043, 30.453125000000057],
            [69.999084473000039, 30.434082031000059],
            [69.999249509000038, 30.425978716000031],
            [69.999351035000075, 30.42099380600007],
            [69.999595125000042, 30.409009906000051],
            [69.999694878000071, 30.404115067000077],
            [69.998261136000053, 30.383475148000059],
            [69.996064407000063, 30.379003641000054],
            [69.984135630000083, 30.354710153000042],
            [69.980799079000064, 30.34791511700007],
            [69.973411442000042, 30.335616750000042],
            [69.96823353700006, 30.327197392000073],
            [69.964935882000077, 30.322013466000044],
            [69.955514529000084, 30.324261702000058],
            [69.95060856200007, 30.325450940000053],
            [69.947847252000031, 30.326120299000024],
            [69.942016850000073, 30.327533626000047],
            [69.937749351000036, 30.328568095000037],
            [69.92936300000008, 30.330601000000058],
            [69.925029222000035, 30.330595292000055],
            [69.921672626000031, 30.330601000000058],
            [69.910307045000081, 30.330593006000072],
            [69.900610119000078, 30.329987152000058],
            [69.892897151000057, 30.329505254000026],
            [69.885859671000048, 30.32610758900006],
            [69.878576463000059, 30.321697165000046],
            [69.874390442000049, 30.314894353000057],
            [69.87035300000008, 30.308333000000061],
            [69.866172375000076, 30.307497176000027],
            [69.855673796000076, 30.304050584000038],
            [69.848556067000061, 30.301574889000051],
            [69.83917800100005, 30.298313000000064],
            [69.830877701000077, 30.294162851000067],
            [69.824108812000077, 30.290778406000072],
            [69.821453011000074, 30.28949891600007],
            [69.800292969000054, 30.290527344000054],
            [69.79227205400008, 30.291577354000026],
            [69.788394990000029, 30.292094343000031],
            [69.78350800000004, 30.292746000000079],
            [69.779916576000062, 30.296011038000074],
            [69.771260999000049, 30.303880000000049],
            [69.770155195000029, 30.309684858000026],
            [69.766807000000028, 30.327261000000078],
            [69.764701229000082, 30.342826528000046],
            [69.75758029800005, 30.348438438000073],
            [69.749385123000081, 30.353692968000075],
            [69.744664239000031, 30.356682258000035],
            [69.740112305000082, 30.35968017600004],
            [69.721109206000051, 30.369675234000056],
            [69.706726074000073, 30.35968017600004],
            [69.690124512000068, 30.368530273000033],
            [69.666687012000068, 30.378479004000042],
            [69.663128597000082, 30.378814637000062],
            [69.649969010000063, 30.379770865000069],
            [69.655937101000063, 30.395973250000054],
            [69.657695451000052, 30.400799849000066],
            [69.651106299000048, 30.409786789000066],
            [69.629882813000052, 30.410888672000056],
            [69.617390041000078, 30.426473110000074],
            [69.599657515000047, 30.435342345000038],
            [69.590887076000058, 30.439729223000029],
            [69.57427573700005, 30.443555633000074],
            [69.562072754000042, 30.446472168000071],
            [69.539672852000081, 30.472106934000067],
            [69.536315918000071, 30.474304199000073],
            [69.541686349000031, 30.479871105000029],
            [69.55108642600004, 30.479125977000024],
            [69.555917691000047, 30.477547695000055],
            [69.560119629000042, 30.481506348000039],
            [69.562057000000038, 30.485683805000065],
            [69.562057000000038, 30.491403000000048],
            [69.555848000000083, 30.499999000000059],
            [69.545342000000062, 30.499999000000059],
            [69.539612000000034, 30.499999000000059],
            [69.536269000000061, 30.508118000000024],
            [69.537224000000037, 30.512893000000076],
            [69.534802060000061, 30.515853087000039],
            [69.532827035000082, 30.519071146000044],
            [69.53210449200003, 30.528686523000033],
            [69.52889005600008, 30.537758634000056],
            [69.523455092000063, 30.538685590000057],
            [69.515319824000073, 30.536682129000042],
            [69.506782576000035, 30.531447979000063],
            [69.500452000000053, 30.531040000000075],
            [69.492239261000066, 30.530152790000045],
            [69.488513000000069, 30.532950000000028],
            [69.483849060000068, 30.536682129000042],
            [69.476629003000028, 30.536741376000066],
            [69.472277000000076, 30.543456000000049],
            [69.467979000000071, 30.547754000000054],
            [69.466068000000064, 30.559216000000049],
            [69.466546000000051, 30.567334000000074],
            [69.467979000000071, 30.578318000000024],
            [69.468489231000035, 30.587479758000029],
            [69.472329823000052, 30.596074471000065],
            [69.476684570000032, 30.600280762000068],
            [69.480314393000072, 30.604464756000027],
            [69.484374494000065, 30.609018484000046],
            [69.499124481000081, 30.619530514000076],
            [69.510409712000069, 30.627238927000064],
            [69.514282227000081, 30.629882813000052],
            [69.521965874000045, 30.638111399000024],
            [69.527669270000047, 30.645748314000059],
            [69.53210449200003, 30.651916504000042],
            [69.53210449200003, 30.659190419000026],
            [69.53210449200003, 30.668090820000032],
            [69.53210449200003, 30.671081543000071],
            [69.53210449200003, 30.68389892600004],
            [69.534484863000046, 30.695312500000057],
            [69.534484863000046, 30.718322754000042],
            [69.53210449200003, 30.730285645000038],
            [69.53210449200003, 30.739318848000039],
            [69.537322952000068, 30.748530563000031],
            [69.538817958000038, 30.751169574000073],
            [69.550109863000046, 30.758911133000026],
            [69.559692383000083, 30.767089844000054],
            [69.567871094000054, 30.772277832000043],
            [69.57688543200004, 30.774714139000025],
            [69.577028436000035, 30.778112371000077],
            [69.577270508000083, 30.784729004000042],
            [69.580313883000031, 30.789656373000071],
            [69.583547000000067, 30.795127000000036],
            [69.59739600000006, 30.803245000000061],
            [69.605036000000041, 30.812319000000059],
            [69.622228000000064, 30.824258000000043],
            [69.629869000000042, 30.83571900000004],
            [69.639387787000032, 30.850161108000066],
            [69.643676758000083, 30.856689453000058],
            [69.65850830100004, 30.880676270000038],
            [69.661349106000046, 30.891068584000038],
            [69.663246743000059, 30.897662480000065],
            [69.671081543000071, 30.905517578000058],
            [69.676330566000047, 30.91687011700003],
            [69.678710938000052, 30.929321289000029],
            [69.68389892600004, 30.938903809000067],
            [69.692504883000083, 30.951293945000032],
            [69.704895020000038, 30.967529297000056],
            [69.708017117000054, 30.969454590000055],
            [69.715866371000061, 30.974294963000034],
            [69.720604000000037, 30.985193000000038],
            [69.72538000000003, 30.999520000000075],
            [69.725857000000076, 31.012891000000025],
            [69.728194114000075, 31.019522108000046],
            [69.730285645000038, 31.025329590000069],
            [69.732082924000053, 31.032430066000074],
            [69.727294922000056, 31.039672852000024],
            [69.709716797000056, 31.04772949200003],
            [69.704421669000055, 31.059601728000075],
            [69.705867541000032, 31.071267214000045],
            [69.711120605000076, 31.078918457000043],
            [69.715398626000081, 31.082935624000072],
            [69.721130371000072, 31.088317871000072],
            [69.72491455100004, 31.097900391000053],
            [69.718688965000069, 31.100891113000046],
            [69.710693359000061, 31.100891113000046],
            [69.701110840000069, 31.102294922000056],
            [69.699252883000042, 31.107474682000031],
            [69.70583642400004, 31.114649901000064],
            [69.706927962000066, 31.118840153000065],
            [69.707702637000068, 31.121887207000043],
            [69.707702637000068, 31.131286621000072],
            [69.707702637000068, 31.145678323000027],
            [69.705744995000032, 31.14958142100005],
            [69.703070466000042, 31.15489664200004],
            [69.702880859000061, 31.155273437000062],
            [69.702123699000083, 31.158331201000067],
            [69.699592000000052, 31.168096000000048],
            [69.69885696800003, 31.173058174000062],
            [69.697691669000051, 31.181041906000075],
            [69.698654615000066, 31.183907844000032],
            [69.699769858000082, 31.187253573000078],
            [69.701043042000038, 31.191043983000043],
            [69.704344799000069, 31.19561580900006],
            [69.707233000000031, 31.199615000000051],
            [69.715351000000055, 31.210121000000072],
            [69.71564423500007, 31.215980185000035],
            [69.715829000000042, 31.21967200000006],
            [69.713060023000082, 31.222025600000052],
            [69.706278000000054, 31.227790000000027],
            [69.698589981000055, 31.235133688000076],
            [69.698159000000032, 31.241162000000031],
            [69.701074297000048, 31.248846513000046],
            [69.713923849000082, 31.255490915000053],
            [69.721069347000082, 31.265418117000024],
            [69.721130371000072, 31.274108887000068],
            [69.722106934000067, 31.283691406000059],
            [69.722106934000067, 31.28991699200003],
            [69.72491455100004, 31.295104980000076],
            [69.729675293000071, 31.303283691000047],
            [69.737906571000053, 31.307917596000038],
            [69.742675781000059, 31.310485840000069],
            [69.753112793000071, 31.312927246000072],
            [69.757873535000044, 31.305725098000039],
            [69.762695312000062, 31.295715332000043],
            [69.767764457000055, 31.289002199000038],
            [69.774719238000046, 31.289489746000072],
            [69.784149958000057, 31.291605533000052],
            [69.790893555000082, 31.295715332000043],
            [69.799729343000081, 31.301403683000046],
            [69.800356000000079, 31.301811000000043],
            [69.806564000000037, 31.309929000000068],
            [69.811733931000049, 31.312922237000066],
            [69.815673828000058, 31.31530761700003],
            [69.822875977000081, 31.31671142600004],
            [69.840881348000039, 31.317687988000046],
            [69.853881836000028, 31.318115234000061],
            [69.857968098000072, 31.309761496000078],
            [69.866882324000073, 31.305297852000024],
            [69.883911133000083, 31.302307129000042],
            [69.896911621000072, 31.295715332000043],
            [69.914489746000072, 31.283691406000059],
            [69.924807833000045, 31.284974666000039],
            [69.937500000000057, 31.279907227000024],
            [69.945111743000041, 31.27844487200008],
            [69.949881284000071, 31.27752855600005],
            [69.955688477000081, 31.287475586000028],
            [69.961183755000036, 31.29507620000004],
            [69.965087891000053, 31.300476074000073],
            [69.973693848000039, 31.309509277000075],
            [69.983367628000053, 31.317348375000051],
            [69.998474121000072, 31.320495605000076],
            [70.019470215000069, 31.319091797000056],
            [70.03095697100008, 31.322222465000038],
            [70.042480469000054, 31.326293945000032],
            [70.046530530000041, 31.329743997000037],
            [70.050720215000069, 31.333312988000046],
            [70.048706055000082, 31.367309570000032],
            [70.048788469000044, 31.378789280000035],
            [70.056160439000053, 31.38503251800006],
            [70.07188345600008, 31.38452204500004],
            [70.080846037000072, 31.398884770000052],
            [70.086897103000069, 31.407257946000072],
            [70.088684082000043, 31.409729004000042],
            [70.096496582000043, 31.420471191000047],
            [70.094116211000028, 31.442077637000068],
            [70.103698730000076, 31.449279785000044],
            [70.116882324000073, 31.442687988000046],
            [70.116891662000057, 31.425295392000066],
            [70.13311767600004, 31.429504395000038],
            [70.136758274000044, 31.429925711000067],
            [70.153686523000033, 31.431884766000053],
            [70.157897949000073, 31.439086914000029],
            [70.172302246000072, 31.439086914000029],
            [70.178600945000028, 31.446486604000029],
            [70.187316895000038, 31.456726074000073],
            [70.197006971000064, 31.468440943000076],
            [70.203653919000033, 31.476476806000051],
            [70.222886402000029, 31.480709827000055],
            [70.243286133000083, 31.498107910000044],
            [70.255911344000083, 31.505304891000037],
            [70.266116881000073, 31.488490107000075],
            [70.267654229000073, 31.461007314000028],
            [70.272888184000067, 31.445678711000028],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "31",
      properties: { name: "Nasirabad", count: 1466 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.41135113100006, 28.980716801000028],
            [68.411352984000075, 28.980689232000032],
            [68.411378153000044, 28.980704713000023],
            [68.411393064000038, 28.980481085000065],
            [68.412833000000035, 28.958885559000066],
            [68.412824496000042, 28.958613559000071],
            [68.412832999000045, 28.95848600000005],
            [68.412722500000029, 28.955351058000076],
            [68.412722159000054, 28.955340159000059],
            [68.41240663800005, 28.944848297000078],
            [68.412314611000056, 28.941904721000071],
            [68.412302795000073, 28.941526776000046],
            [68.412153120000028, 28.937138804000028],
            [68.412152697000067, 28.937125242000036],
            [68.412050116000046, 28.933844109000063],
            [68.41205119500006, 28.933847950000029],
            [68.412024079000048, 28.932824843000049],
            [68.411862413000051, 28.930399351000062],
            [68.411862067000072, 28.930394152000076],
            [68.410443000000043, 28.909093000000041],
            [68.412039779000054, 28.893207238000059],
            [68.413419059000034, 28.88695173900004],
            [68.414876727000035, 28.879987464000067],
            [68.416040009000028, 28.874322304000032],
            [68.419206000000031, 28.858904000000052],
            [68.422908950000078, 28.849029981000058],
            [68.425716826000041, 28.841542704000062],
            [68.428759401000036, 28.833429597000077],
            [68.428765912000074, 28.833412234000036],
            [68.431337763000045, 28.825328197000033],
            [68.434342000000072, 28.816284560000042],
            [68.435139001000039, 28.793579000000079],
            [68.435141087000034, 28.79356858500006],
            [68.435312494000073, 28.793579000000079],
            [68.438797623000028, 28.776179114000058],
            [68.439528437000035, 28.77253044400004],
            [68.439277423000078, 28.749945719000038],
            [68.439295494000078, 28.74976300000003],
            [68.436928507000061, 28.721360144000073],
            [68.442031880000059, 28.693991270000026],
            [68.441879609000068, 28.693877458000031],
            [68.441879877000076, 28.693876031000059],
            [68.44230900000008, 28.691607999000041],
            [68.442324014000064, 28.691536670000062],
            [68.445495000000051, 28.676472000000047],
            [68.446179001000075, 28.675500001000046],
            [68.435440804000052, 28.673435109000025],
            [68.426376000000062, 28.671692000000064],
            [68.407256000000075, 28.663725000000056],
            [68.398096215000066, 28.657618659000036],
            [68.39583293000004, 28.656109848000028],
            [68.390526000000079, 28.652572000000077],
            [68.385529896000037, 28.648289497000064],
            [68.38298340800003, 28.646106727000074],
            [68.379372999000054, 28.643012000000056],
            [68.379373000000044, 28.631342816000029],
            [68.379372999000054, 28.626602687000059],
            [68.379373000000044, 28.625486000000024],
            [68.379085305000046, 28.617999787000031],
            [68.379023999000083, 28.616404501000034],
            [68.378832713000065, 28.611427000000049],
            [68.378577000000064, 28.604773000000023],
            [68.378577000000064, 28.598921508000046],
            [68.378577000000064, 28.582467000000065],
            [68.382560000000069, 28.56095700000003],
            [68.380636246000051, 28.548935197000048],
            [68.379373000000044, 28.541041000000064],
            [68.386447553000039, 28.519031937000079],
            [68.386543000000074, 28.518735000000049],
            [68.386543000000074, 28.496428000000037],
            [68.386543000000074, 28.478372056000069],
            [68.386543000000074, 28.476512000000071],
            [68.382609973000058, 28.463138920000063],
            [68.382560000000069, 28.462969000000044],
            [68.376983000000052, 28.453409000000079],
            [68.374593000000061, 28.442256000000043],
            [68.363084506000064, 28.438659708000046],
            [68.361847000000068, 28.438273000000038],
            [68.34591400000005, 28.439866000000052],
            [68.343072887000062, 28.439460199000052],
            [68.334761000000071, 28.438273000000038],
            [68.322015000000079, 28.442256000000043],
            [68.305285000000083, 28.443850000000054],
            [68.288990658000046, 28.443850000000054],
            [68.279792000000043, 28.443850000000054],
            [68.258282000000065, 28.440663000000029],
            [68.241645574000074, 28.442222747000073],
            [68.23279000000008, 28.443053000000077],
            [68.205689134000067, 28.440913435000027],
            [68.202517000000057, 28.440663000000029],
            [68.193840554000076, 28.439175592000026],
            [68.174634000000083, 28.435883000000047],
            [68.155279574000076, 28.431777114000056],
            [68.14937425100004, 28.430524348000063],
            [68.148345000000063, 28.43030600000003],
            [68.125242000000071, 28.420747000000063],
            [68.10452900000007, 28.409594000000027],
            [68.088596000000052, 28.400034000000062],
            [68.086432934000072, 28.398141423000027],
            [68.084209451000049, 28.39619598400003],
            [68.076328066000031, 28.389300159000072],
            [68.075849000000062, 28.388881000000026],
            [68.071866000000057, 28.368964000000062],
            [68.071866000000057, 28.347455000000025],
            [68.069665476000068, 28.344746418000057],
            [68.061510000000055, 28.334708000000035],
            [68.043215986000064, 28.318566502000067],
            [68.043066641000053, 28.318434728000057],
            [68.035419907000062, 28.311687726000059],
            [68.034424000000058, 28.310809000000063],
            [68.023064939000051, 28.302005781000048],
            [68.018035866000048, 28.298108274000072],
            [68.002558000000079, 28.286113000000057],
            [67.998888674000057, 28.281173502000058],
            [67.986138373000074, 28.264009565000038],
            [67.981845000000078, 28.258230000000026],
            [67.962162597000031, 28.247159817000068],
            [67.961873458000071, 28.246997194000073],
            [67.961850000000084, 28.246984000000054],
            [67.956352000000038, 28.243890000000079],
            [67.938888656000074, 28.239188395000042],
            [67.922003701000051, 28.234642509000025],
            [67.919906424000033, 28.234077865000074],
            [67.914926000000037, 28.232737000000043],
            [67.902839550000067, 28.22903700300003],
            [67.876342257000033, 28.22092544800006],
            [67.875890000000084, 28.22078700000003],
            [67.835308310000073, 28.213220995000029],
            [67.828888000000063, 28.212024000000042],
            [67.807061678000082, 28.207658614000024],
            [67.801060568000082, 28.206458358000077],
            [67.801060408000069, 28.206460079000067],
            [67.801005000000032, 28.206449000000077],
            [67.801003034000075, 28.206471608000072],
            [67.801051340000072, 28.20648127000004],
            [67.799451340000076, 28.224781270000051],
            [67.798551340000074, 28.230981270000029],
            [67.797051340000053, 28.240681270000039],
            [67.796330802000057, 28.246576575000063],
            [67.795951340000045, 28.249681270000053],
            [67.795781809000061, 28.251240956000061],
            [67.795451340000056, 28.254281270000035],
            [67.793051340000034, 28.267781270000057],
            [67.785053222000045, 28.281378069000027],
            [67.785051340000052, 28.281381270000054],
            [67.783851340000069, 28.284181270000033],
            [67.781051340000033, 28.290881270000057],
            [67.781051340000033, 28.300481270000034],
            [67.778887003000079, 28.304855994000036],
            [67.776351340000076, 28.309981270000037],
            [67.774116382000045, 28.318027121000057],
            [67.772351340000057, 28.32438127000006],
            [67.768251340000063, 28.337481270000069],
            [67.764351340000076, 28.349881270000026],
            [67.762439422000057, 28.353509570000028],
            [67.755551340000068, 28.36658127000004],
            [67.753994546000058, 28.378583651000042],
            [67.75245134000005, 28.390481270000066],
            [67.747559896000041, 28.401633763000063],
            [67.747451340000055, 28.401881270000047],
            [67.738070176000065, 28.423138587000039],
            [67.740434772000071, 28.444418957000039],
            [67.740460000000041, 28.444646000000034],
            [67.740735527000083, 28.452091462000055],
            [67.741251340000076, 28.46618127000005],
            [67.742404158000056, 28.46873908300006],
            [67.744451340000069, 28.47328127000003],
            [67.745020046000036, 28.474480388000075],
            [67.746959183000058, 28.478569063000066],
            [67.749186733000045, 28.483326284000043],
            [67.750851340000054, 28.486881270000026],
            [67.752666288000057, 28.494670420000034],
            [67.753251340000077, 28.497181270000056],
            [67.757451340000046, 28.502881270000046],
            [67.758718723000072, 28.504575296000041],
            [67.767546000000038, 28.516345000000058],
            [67.779495000000054, 28.531481000000042],
            [67.782322549000071, 28.537450008000064],
            [67.786151627000038, 28.545533262000049],
            [67.786651340000049, 28.546581270000047],
            [67.78665043500007, 28.546586253000044],
            [67.785451340000066, 28.553181270000039],
            [67.784010291000072, 28.561261437000041],
            [67.782651340000029, 28.568881270000077],
            [67.778251340000054, 28.581081270000027],
            [67.777955684000062, 28.581921554000076],
            [67.776351340000076, 28.586481270000036],
            [67.772951116000058, 28.60001132900004],
            [67.77155134000003, 28.605581270000073],
            [67.774851340000055, 28.609981270000048],
            [67.774846383000067, 28.609992716000079],
            [67.774846166000032, 28.609993217000067],
            [67.778234114000043, 28.614509313000042],
            [67.77829567100008, 28.61459136700006],
            [67.778699000000074, 28.615129000000024],
            [67.779587940000056, 28.61522261600004],
            [67.779672570000059, 28.615231528000038],
            [67.779715485000054, 28.615236047000053],
            [67.793835000000058, 28.616723000000036],
            [67.80931513400003, 28.619045069000038],
            [67.809768000000076, 28.619113000000027],
            [67.820921000000055, 28.619113000000027],
            [67.832871000000068, 28.619909000000064],
            [67.843373481000071, 28.61548693900005],
            [67.848007000000052, 28.613536000000067],
            [67.859161000000029, 28.613536000000067],
            [67.868720000000053, 28.613536000000067],
            [67.873500000000035, 28.620706000000041],
            [67.87562987900003, 28.624965377000024],
            [67.879077000000052, 28.631859000000077],
            [67.883478204000028, 28.640261618000068],
            [67.88784000000004, 28.648589000000072],
            [67.875553538000077, 28.656349212000066],
            [67.875402682000072, 28.656444494000027],
            [67.872704000000056, 28.658149000000037],
            [67.863940000000071, 28.658149000000037],
            [67.863144000000034, 28.666912000000025],
            [67.867127000000039, 28.677268000000026],
            [67.873500000000035, 28.684438000000057],
            [67.880670000000066, 28.686031000000071],
            [67.889515265000057, 28.695508353000037],
            [67.891823000000045, 28.697981000000027],
            [67.898637700000052, 28.705221252000058],
            [67.904570000000035, 28.711524000000054],
            [67.907755175000034, 28.718603006000023],
            [67.911739000000068, 28.727457000000072],
            [67.912916512000038, 28.729288631000031],
            [67.918909000000042, 28.738610000000051],
            [67.928469000000064, 28.751357000000041],
            [67.934484348000069, 28.757371876000036],
            [67.936959474000048, 28.759846809000067],
            [67.941216000000054, 28.764103000000034],
            [67.945199000000059, 28.773663000000056],
            [67.947714198000028, 28.777295830000071],
            [67.952369000000033, 28.784019000000058],
            [67.960515308000083, 28.786191758000029],
            [67.960538819000078, 28.786198029000047],
            [67.964318000000048, 28.787206000000026],
            [67.964805922000039, 28.784034889000054],
            [67.96481360000007, 28.783984988000043],
            [67.96575262500005, 28.777882061000071],
            [67.967505000000074, 28.766493000000025],
            [67.965638561000048, 28.753895855000053],
            [67.964318000000048, 28.744983000000047],
            [67.964318000000048, 28.724271000000044],
            [67.963901269000075, 28.721978325000066],
            [67.962831919000052, 28.716095221000046],
            [67.962725000000034, 28.715507000000059],
            [67.963522001000058, 28.706744000000072],
            [67.971488000000079, 28.701168000000052],
            [67.977861000000075, 28.701964000000032],
            [67.985031000000049, 28.705151000000058],
            [68.000167999000041, 28.709931001000029],
            [68.01583520500003, 28.720897946000036],
            [68.016101000000049, 28.721084000000076],
            [68.016263240000058, 28.721178635000058],
            [68.016622096000049, 28.721387936000042],
            [68.018473517000075, 28.722467803000029],
            [68.025661000000071, 28.726660000000038],
            [68.032830000000047, 28.735424000000023],
            [68.031852583000045, 28.741778132000036],
            [68.031843479000031, 28.741837308000072],
            [68.031237000000033, 28.745780000000025],
            [68.029644000000076, 28.755340000000047],
            [68.020881000000031, 28.76729000000006],
            [68.020588345000078, 28.76875313100004],
            [68.020583584000065, 28.768776932000037],
            [68.020510220000062, 28.769143709000048],
            [68.019274870000061, 28.775319689000071],
            [68.019235560000084, 28.775323062000041],
            [68.019270296000059, 28.775342556000055],
            [68.019212000000039, 28.775634000000025],
            [68.01849100000004, 28.779239000000075],
            [68.023271000000079, 28.784019000000058],
            [68.034424000000058, 28.781629000000066],
            [68.043983000000082, 28.781629000000066],
            [68.05498381700005, 28.783570225000062],
            [68.05752700000005, 28.784019000000058],
            [68.071070000000077, 28.786409000000049],
            [68.094172000000071, 28.794376000000057],
            [68.113292000000058, 28.803936000000078],
            [68.129225000000076, 28.811902000000032],
            [68.132221336000043, 28.813275268000041],
            [68.148345000000063, 28.820665000000076],
            [68.16268400000007, 28.827038000000073],
            [68.168027949000077, 28.829710127000055],
            [68.180211000000043, 28.835802000000058],
            [68.193754000000069, 28.840581999000051],
            [68.195955000000083, 28.841211000000044],
            [68.216060000000084, 28.846955000000037],
            [68.231196000000068, 28.853328000000033],
            [68.239960000000053, 28.873244000000057],
            [68.240930871000046, 28.879719043000023],
            [68.242349000000047, 28.889177000000075],
            [68.242896586000029, 28.890340471000059],
            [68.24824497000003, 28.901704317000053],
            [68.248791574000052, 28.902948576000028],
            [68.249119126000039, 28.90369419700005],
            [68.250161994000052, 28.907516547000057],
            [68.25350300000008, 28.918653000000063],
            [68.255096000000037, 28.932993000000067],
            [68.248723000000041, 28.940163000000041],
            [68.237570000000062, 28.940163000000041],
            [68.231196000000068, 28.940163000000041],
            [68.225620000000049, 28.942553000000032],
            [68.223230000000058, 28.952909000000034],
            [68.227213000000063, 28.957689000000073],
            [68.235180000000071, 28.962469000000056],
            [68.240756000000033, 28.968046000000072],
            [68.242314218000047, 28.969938131000049],
            [68.248139366000032, 28.97701156200003],
            [68.251909000000069, 28.981589000000042],
            [68.255014141000061, 28.984383510000043],
            [68.259876000000077, 28.988759000000073],
            [68.261367541000084, 28.988759000000073],
            [68.263321617000031, 28.988759000000073],
            [68.264997540000081, 28.988759000000073],
            [68.271826001000079, 28.988759000000073],
            [68.283140221000053, 28.992202297000063],
            [68.290148000000045, 28.994335000000035],
            [68.317235000000039, 29.004692000000034],
            [68.338744000000077, 29.017438000000027],
            [68.352287000000047, 29.02938800000004],
            [68.367424000000028, 29.038151000000028],
            [68.380967000000055, 29.052491000000032],
            [68.381763000000035, 29.051694000000055],
            [68.387940179000054, 29.036869391000039],
            [68.388228051000056, 29.036235365000039],
            [68.394117205000043, 29.023264803000075],
            [68.394834908000064, 29.021684099000026],
            [68.39585388100005, 29.019148568000048],
            [68.401680000000056, 29.007081999000036],
            [68.40182505000007, 29.005558663000045],
            [68.402254365000033, 29.001049903000023],
            [68.40225829600007, 29.001008623000075],
            [68.40327300000007, 28.99035200000003],
            [68.404653297000038, 28.988971530000072],
            [68.404653685000028, 28.988971142000025],
            [68.407409136000069, 28.986215344000072],
            [68.41123900000008, 28.982385000000079],
            [68.41135113100006, 28.980716801000028],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "32",
      properties: { name: "Nushki", count: 1195 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.287722896000048, 29.857558651000033],
            [66.297490000000039, 29.845700000000079],
            [66.307427000000075, 29.837892000000068],
            [66.308136000000047, 29.827955000000031],
            [66.304588000000081, 29.814470000000028],
            [66.29110200000008, 29.803113000000053],
            [66.290573108000046, 29.801967036000065],
            [66.290572289000067, 29.801965262000067],
            [66.285320677000072, 29.790586461000032],
            [66.285320442000057, 29.790585952000072],
            [66.283336422000048, 29.786287125000058],
            [66.28258500000004, 29.784659000000033],
            [66.281263696000053, 29.771450297000058],
            [66.28126356100006, 29.771448953000061],
            [66.280455000000075, 29.763366000000076],
            [66.280455000000075, 29.757577753000078],
            [66.280455000000075, 29.75755762700004],
            [66.280455000000075, 29.74349200000006],
            [66.286133000000063, 29.724329000000068],
            [66.290392000000054, 29.716521000000057],
            [66.289682000000028, 29.694518000000073],
            [66.293941000000075, 29.681742000000042],
            [66.294500283000048, 29.675030401000072],
            [66.29648813700004, 29.65117544800006],
            [66.296488206000049, 29.651174628000035],
            [66.296780000000069, 29.647673000000054],
            [66.303757374000043, 29.642689062000045],
            [66.306717000000049, 29.640575000000069],
            [66.312231049000047, 29.634601286000077],
            [66.315234000000032, 29.63134800000006],
            [66.313153161000059, 29.617474113000071],
            [66.313105000000064, 29.61715300000003],
            [66.300707691000071, 29.602000841000063],
            [66.300706246000061, 29.601999075000037],
            [66.300329000000033, 29.601538000000062],
            [66.299900371000035, 29.593396460000065],
            [66.299619000000064, 29.588052000000062],
            [66.299559545000079, 29.586863325000024],
            [66.298934321000047, 29.57436324400004],
            [66.298932742000034, 29.574331678000078],
            [66.298909000000037, 29.573857000000032],
            [66.298876017000055, 29.573825665000072],
            [66.298873817000072, 29.573823574000073],
            [66.284714000000065, 29.560371000000032],
            [66.270210365000082, 29.539824964000047],
            [66.267679000000044, 29.536239000000023],
            [66.264840000000049, 29.517075000000034],
            [66.264131000000077, 29.496492000000046],
            [66.254194000000041, 29.479457000000025],
            [66.247096000000056, 29.468811000000073],
            [66.242837000000065, 29.451066000000026],
            [66.227932000000067, 29.435451000000057],
            [66.223674000000074, 29.412739000000045],
            [66.198831000000041, 29.388606000000038],
            [66.194565053000076, 29.383006960000046],
            [66.187475000000063, 29.37370100000004],
            [66.178248000000053, 29.357376000000045],
            [66.174699000000032, 29.343891000000042],
            [66.155983923000065, 29.33141411500003],
            [66.155535000000043, 29.331115000000068],
            [66.143921783000053, 29.326137323000069],
            [66.135662000000082, 29.32259700000003],
            [66.117208000000062, 29.326856000000078],
            [66.112239000000045, 29.338212000000055],
            [66.098754000000042, 29.346020000000067],
            [66.090236000000061, 29.341761000000076],
            [66.079590000000053, 29.333244000000036],
            [66.074621000000036, 29.323307000000057],
            [66.063416336000046, 29.312102336000066],
            [66.060426000000064, 29.309112000000027],
            [66.054038000000048, 29.297046000000023],
            [66.049070000000029, 29.282850000000053],
            [66.049070000000029, 29.273623000000043],
            [66.049070000000029, 29.260847000000069],
            [66.048360000000059, 29.253040000000055],
            [66.034874000000059, 29.241683000000023],
            [66.033951094000031, 29.240035013000067],
            [66.024937000000079, 29.22393900000003],
            [66.013581000000045, 29.206195000000037],
            [66.005773000000033, 29.194838000000061],
            [65.995837000000051, 29.191290000000038],
            [65.986534198000072, 29.187671839000075],
            [65.983061000000077, 29.186321000000078],
            [65.968156000000079, 29.177804000000037],
            [65.965616963000059, 29.17603779500007],
            [65.951831000000084, 29.166448000000059],
            [65.929828000000043, 29.160060000000044],
            [65.910664000000054, 29.152962000000059],
            [65.895049000000029, 29.145154000000048],
            [65.882273000000055, 29.138766000000032],
            [65.864529000000061, 29.130249000000049],
            [65.851043000000061, 29.130959000000075],
            [65.850526777000027, 29.131217095000068],
            [65.835428000000036, 29.138766000000032],
            [65.821233000000063, 29.146574000000044],
            [65.797100000000057, 29.14728400000007],
            [65.766393201000028, 29.145908606000035],
            [65.749546000000066, 29.145154000000048],
            [65.72612300000003, 29.140896000000055],
            [65.710508000000061, 29.134508000000039],
            [65.69773200000003, 29.126700000000028],
            [65.693474000000037, 29.116054000000076],
            [65.692764000000068, 29.103988000000072],
            [65.688505000000077, 29.089082000000076],
            [65.68368186500004, 29.086938468000028],
            [65.675729000000047, 29.08340400000003],
            [65.652307000000064, 29.072048000000052],
            [65.630304000000081, 29.067080000000033],
            [65.620367000000044, 29.054304000000059],
            [65.609721000000036, 29.042947000000026],
            [65.596235000000036, 29.033010000000047],
            [65.580620000000067, 29.031591000000049],
            [65.563407735000055, 29.03414086500004],
            [65.561456000000078, 29.034430000000043],
            [65.540873000000033, 29.037979000000064],
            [65.51319200000006, 29.038689000000034],
            [65.492608000000075, 29.036559000000068],
            [65.474386005000042, 29.03460695800004],
            [65.472735000000057, 29.034430000000043],
            [65.470084001000032, 29.036426146000053],
            [65.464537326000084, 29.071158651000076],
            [65.402996658000063, 29.18092851800003],
            [65.362197325000068, 29.24899565100003],
            [65.315268848000073, 29.332609626000078],
            [65.293411326000069, 29.371468651000043],
            [65.266567326000029, 29.416766652000035],
            [65.259098805000065, 29.425593140000046],
            [65.229658326000049, 29.460386652000068],
            [65.170938326000055, 29.520783651000045],
            [65.131591325000045, 29.552505651000047],
            [65.174351340000044, 29.560781270000064],
            [65.24535134000007, 29.580181270000026],
            [65.30985134000008, 29.598681270000043],
            [65.334851340000057, 29.605881270000054],
            [65.348451340000054, 29.609281270000054],
            [65.379151340000078, 29.614581270000031],
            [65.427751340000043, 29.629881270000055],
            [65.476951340000028, 29.642081270000062],
            [65.532951340000068, 29.65738127000003],
            [65.540951340000049, 29.659681270000078],
            [65.581551340000033, 29.671181270000034],
            [65.633651340000029, 29.684181270000067],
            [65.64285134000005, 29.686481270000058],
            [65.704251340000042, 29.700681270000075],
            [65.745051340000032, 29.712181270000031],
            [65.789351340000053, 29.722281270000053],
            [65.800751340000033, 29.724581270000044],
            [65.838851340000076, 29.736881270000026],
            [65.853751340000031, 29.74158127000004],
            [65.860751340000036, 29.743781270000056],
            [65.882751340000084, 29.748281270000064],
            [65.891351340000028, 29.755081270000062],
            [65.904651340000044, 29.756381270000077],
            [65.921351340000058, 29.762481270000023],
            [65.925451340000052, 29.763481270000057],
            [65.975851340000077, 29.77588127000007],
            [65.997751340000036, 29.780781270000034],
            [66.007051340000032, 29.784081270000058],
            [66.007177026000079, 29.784018155000069],
            [66.033544000000063, 29.788884000000053],
            [66.064844000000051, 29.797013000000049],
            [66.086469453000063, 29.803565012000035],
            [66.10508800000008, 29.809206000000074],
            [66.13272900000004, 29.814895000000035],
            [66.145737000000054, 29.819366000000059],
            [66.156713000000082, 29.820992000000047],
            [66.171347000000083, 29.826682000000062],
            [66.172980753000047, 29.827090355000053],
            [66.190859000000046, 29.831559000000027],
            [66.210777000000064, 29.840907000000072],
            [66.228706000000045, 29.846749000000045],
            [66.238412000000039, 29.849127000000067],
            [66.246047000000033, 29.852519000000029],
            [66.253984000000059, 29.852472000000034],
            [66.258789000000036, 29.857979000000057],
            [66.265517000000045, 29.864190000000065],
            [66.287722896000048, 29.857558651000033],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "33",
      properties: { name: "Panjgur", count: 115 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [64.440936000000079, 27.263226000000031],
            [64.44577600000008, 27.250088000000062],
            [64.453728000000069, 27.251817000000074],
            [64.45649300000008, 27.244557000000043],
            [64.455802000000062, 27.235222000000078],
            [64.456148000000042, 27.226579000000072],
            [64.463408000000072, 27.222776000000067],
            [64.469631000000049, 27.216898000000072],
            [64.484843000000069, 27.205489000000057],
            [64.489338000000032, 27.194080000000042],
            [64.488992000000053, 27.186820000000068],
            [64.49002900000005, 27.179560000000038],
            [64.49210400000004, 27.172991000000025],
            [64.500055000000032, 27.168150000000026],
            [64.500055000000032, 27.157433000000026],
            [64.501093000000083, 27.146370000000047],
            [64.500055000000032, 27.137726000000043],
            [64.49521500000003, 27.128737000000058],
            [64.490721000000065, 27.120440000000031],
            [64.491758000000061, 27.113871000000074],
            [64.497290000000078, 27.111797000000024],
            [64.509390000000053, 27.105228000000068],
            [64.518725000000074, 27.10004200000003],
            [64.526331000000027, 27.095202000000029],
            [64.533937000000037, 27.090707000000066],
            [64.553989000000058, 27.081718000000023],
            [64.568856000000039, 27.07445800000005],
            [64.578082054000049, 27.069522874000029],
            [64.58372200000008, 27.066506000000061],
            [64.59962500000006, 27.058209000000033],
            [64.606886000000031, 27.048874000000069],
            [64.621752000000072, 27.038502000000051],
            [64.631087000000036, 27.029167000000029],
            [64.635927000000038, 27.024327000000028],
            [64.64284200000003, 27.023290000000031],
            [64.648719000000028, 27.020524000000023],
            [64.654942000000062, 27.020524000000023],
            [64.664968000000044, 27.016721000000075],
            [64.669117000000028, 27.011535000000038],
            [64.672229000000073, 27.00531200000006],
            [64.683983000000069, 27.004966000000024],
            [64.695392000000084, 27.004966000000024],
            [64.709913000000029, 27.006695000000036],
            [64.724088000000052, 27.012227000000053],
            [64.734114000000034, 27.018795000000068],
            [64.749672000000032, 27.028822000000048],
            [64.774564000000055, 27.040576000000044],
            [64.807409000000064, 27.061666000000059],
            [64.843019000000083, 27.083447000000035],
            [64.857885000000067, 27.092781000000059],
            [64.872752000000048, 27.101425000000063],
            [64.880012000000079, 27.109722000000033],
            [64.903867000000048, 27.120786000000066],
            [64.916314000000057, 27.130120000000034],
            [64.962987000000055, 27.159507000000076],
            [64.987880000000075, 27.167805000000044],
            [64.999289000000033, 27.170916000000034],
            [65.008969000000036, 27.178868000000023],
            [65.014501000000052, 27.183017000000063],
            [65.02798400000006, 27.175411000000054],
            [65.040430000000072, 27.161927000000048],
            [65.05183900000003, 27.150518000000034],
            [65.054605000000038, 27.137726000000043],
            [65.054951000000074, 27.122514000000024],
            [65.056680000000028, 27.106956000000025],
            [65.066014000000052, 27.093819000000053],
            [65.073966000000041, 27.08725000000004],
            [65.075003000000038, 27.081027000000063],
            [65.075349000000074, 27.073075000000074],
            [65.07638600000007, 27.065815000000043],
            [65.085029000000077, 27.062357000000077],
            [65.088487000000043, 27.054751000000067],
            [65.086412000000053, 27.048874000000069],
            [65.085721000000035, 27.038156000000072],
            [65.087795000000028, 27.029513000000065],
            [65.094364000000041, 27.023636000000067],
            [65.10439000000008, 27.023636000000067],
            [65.119257000000061, 27.023290000000031],
            [65.13032000000004, 27.026401000000078],
            [65.143112000000031, 27.025710000000061],
            [65.151064000000076, 27.026747000000057],
            [65.157633000000033, 27.027093000000036],
            [65.166276000000039, 27.025710000000061],
            [65.183562000000052, 27.017758000000072],
            [65.200503000000083, 27.006695000000036],
            [65.210183000000029, 26.993557000000067],
            [65.216407000000061, 26.981111000000055],
            [65.218135000000075, 26.965899000000036],
            [65.220901000000083, 26.949995000000058],
            [65.223667000000034, 26.937549000000047],
            [65.23473000000007, 26.928560000000061],
            [65.245448000000067, 26.916459000000032],
            [65.249251000000072, 26.907125000000065],
            [65.258931000000075, 26.899864000000036],
            [65.268612000000076, 26.891567000000066],
            [65.280021000000033, 26.89122100000003],
            [65.295579000000032, 26.888801000000058],
            [65.309754000000055, 26.893641000000059],
            [65.328423000000043, 26.893641000000059],
            [65.335683000000074, 26.895024000000035],
            [65.335808000000043, 26.895827000000054],
            [65.335808557000064, 26.895826443000033],
            [65.335808457000041, 26.895825803000037],
            [65.336217000000033, 26.884848000000034],
            [65.335190546000035, 26.881896927000071],
            [65.329712000000029, 26.866146000000072],
            [65.319954000000052, 26.848664000000042],
            [65.30938400000008, 26.829556000000025],
            [65.307530345000032, 26.824922006000065],
            [65.302879000000075, 26.813294000000042],
            [65.296374000000071, 26.798658000000046],
            [65.291089000000056, 26.78971400000006],
            [65.280907447000061, 26.782512272000076],
            [65.274420000000077, 26.777923500000043],
            [65.257345000000043, 26.759628000000077],
            [65.237424000000033, 26.737674000000027],
            [65.21303000000006, 26.712468000000058],
            [65.184413828000061, 26.681378729000073],
            [65.180099000000041, 26.676691000000062],
            [65.13415800000007, 26.633596000000068],
            [65.128737268000066, 26.629259373000025],
            [65.107732000000055, 26.612455000000068],
            [65.081713000000036, 26.589281500000027],
            [65.044716000000051, 26.559603000000038],
            [65.026827500000081, 26.542528000000061],
            [64.999588000000074, 26.526265000000024],
            [64.978447000000074, 26.510816000000034],
            [64.958933000000059, 26.493741000000057],
            [64.94023100000004, 26.478292000000067],
            [64.925188000000048, 26.462436000000025],
            [64.91787000000005, 26.452679000000046],
            [64.915025000000071, 26.439262000000042],
            [64.919497000000035, 26.423407000000054],
            [64.928847500000074, 26.408364000000063],
            [64.934133000000031, 26.40145300000006],
            [64.934946000000082, 26.385597000000075],
            [64.933726000000036, 26.37462000000005],
            [64.91909000000004, 26.368115000000046],
            [64.905674000000033, 26.365269000000069],
            [64.888598000000059, 26.363236000000029],
            [64.863392000000033, 26.364049000000023],
            [64.846723000000054, 26.365676000000065],
            [64.828022000000033, 26.366895000000056],
            [64.780048000000079, 26.365269000000069],
            [64.749556000000041, 26.363643000000025],
            [64.719471000000055, 26.363236000000029],
            [64.69507800000008, 26.361610000000042],
            [64.676783000000057, 26.364863000000071],
            [64.653202500000077, 26.366895000000056],
            [64.638973000000078, 26.368522000000041],
            [64.606042000000059, 26.369335000000035],
            [64.590999000000068, 26.372994000000062],
            [64.577583000000061, 26.372994000000062],
            [64.538960000000031, 26.372587000000067],
            [64.508062000000052, 26.372181000000069],
            [64.481636000000037, 26.371774000000073],
            [64.462934000000075, 26.370554000000027],
            [64.442200000000071, 26.362423000000035],
            [64.431629000000044, 26.351446000000067],
            [64.425125000000037, 26.342095000000029],
            [64.42227900000006, 26.33111800000006],
            [64.421872000000064, 26.308758000000068],
            [64.423905000000047, 26.292496000000028],
            [64.428784000000064, 26.276233000000047],
            [64.437321000000054, 26.249807000000033],
            [64.446265000000039, 26.231106000000068],
            [64.447933000000035, 26.230105000000037],
            [64.447549000000038, 26.230081000000041],
            [64.432221000000084, 26.235599000000036],
            [64.403405000000078, 26.246022000000039],
            [64.384398000000033, 26.257671000000073],
            [64.367844000000048, 26.266255000000058],
            [64.365392000000043, 26.255832000000055],
            [64.361100000000079, 26.241117000000031],
            [64.361100000000079, 26.229468000000054],
            [64.350064000000032, 26.22272300000003],
            [64.34086700000006, 26.217205000000035],
            [64.322473000000059, 26.206169000000045],
            [64.301628000000051, 26.201264000000037],
            [64.285687000000053, 26.193907000000024],
            [64.277103000000068, 26.181031000000075],
            [64.265258792000054, 26.174918524000077],
            [64.25809600000008, 26.171222000000057],
            [64.231732000000079, 26.166930000000036],
            [64.213952000000063, 26.170608000000072],
            [64.198011000000065, 26.173061000000075],
            [64.176552000000072, 26.171222000000057],
            [64.153253000000063, 26.165704000000062],
            [64.13179400000007, 26.160799000000054],
            [64.128756972000076, 26.160134597000024],
            [64.112175000000036, 26.156507000000033],
            [64.097460000000069, 26.154054000000031],
            [64.079066000000068, 26.149149000000023],
            [64.060060000000078, 26.150989000000038],
            [64.045345000000054, 26.157733000000064],
            [64.029404000000056, 26.169382000000041],
            [64.015302000000077, 26.183484000000078],
            [64.003040000000055, 26.196972000000073],
            [64.001063302000034, 26.198740660000055],
            [63.991391000000078, 26.207395000000076],
            [63.985260000000039, 26.217818000000022],
            [63.977289000000042, 26.226402000000064],
            [63.961961000000031, 26.236825000000067],
            [63.946633000000077, 26.250927000000047],
            [63.928853000000061, 26.268094000000076],
            [63.916591000000039, 26.280969000000027],
            [63.910521121000045, 26.285573725000063],
            [63.898810000000083, 26.294458000000077],
            [63.890840000000082, 26.301815000000033],
            [63.869381000000033, 26.311012000000062],
            [63.850374000000045, 26.316530000000057],
            [63.842032454000048, 26.316530000000057],
            [63.820332000000064, 26.316530000000057],
            [63.791515000000061, 26.313464000000067],
            [63.76515100000006, 26.303655000000049],
            [63.743692000000067, 26.292005000000074],
            [63.729968702000065, 26.278854322000029],
            [63.728977000000043, 26.277904000000035],
            [63.727216794000071, 26.277477260000069],
            [63.708745000000079, 26.272999000000027],
            [63.708744612000032, 26.272999149000043],
            [63.687899000000073, 26.280969000000027],
            [63.663987000000077, 26.276677000000063],
            [63.643141000000071, 26.269933000000037],
            [63.627200000000073, 26.268707000000063],
            [63.612485000000049, 26.26135000000005],
            [63.595346000000063, 26.269307000000026],
            [63.595318000000077, 26.26932000000005],
            [63.589800000000082, 26.286487000000079],
            [63.587348000000077, 26.302428000000077],
            [63.587348000000077, 26.314691000000039],
            [63.589800000000082, 26.333697000000029],
            [63.595931000000064, 26.344120000000032],
            [63.595931000000064, 26.355156000000079],
            [63.591026000000056, 26.364966000000038],
            [63.581829000000027, 26.371097000000077],
            [63.568954000000076, 26.376615000000072],
            [63.545043000000078, 26.373550000000023],
            [63.527262000000064, 26.368645000000072],
            [63.513774000000069, 26.375389000000041],
            [63.511934000000053, 26.385812000000044],
            [63.516839000000061, 26.395009000000073],
            [63.52910200000008, 26.388878000000034],
            [63.535233000000062, 26.390717000000052],
            [63.535846000000049, 26.404819000000032],
            [63.541364000000044, 26.410337000000027],
            [63.54810800000007, 26.412789000000032],
            [63.557918000000029, 26.412789000000032],
            [63.561597000000063, 26.415855000000079],
            [63.565275000000042, 26.412176000000045],
            [63.572633000000053, 26.410950000000071],
            [63.577538000000061, 26.413402000000076],
            [63.584895000000074, 26.415855000000079],
            [63.59777100000008, 26.415855000000079],
            [63.604515000000049, 26.419533000000058],
            [63.595931000000064, 26.429956000000061],
            [63.58428200000003, 26.436088000000041],
            [63.559757000000047, 26.445897000000059],
            [63.535846000000049, 26.457547000000034],
            [63.511934000000053, 26.469809000000055],
            [63.49354100000005, 26.475940000000037],
            [63.47146900000007, 26.483298000000048],
            [63.450010000000077, 26.490655000000061],
            [63.430390000000045, 26.499852000000033],
            [63.416288000000065, 26.502917000000025],
            [63.392377000000067, 26.501691000000051],
            [63.372144000000048, 26.500465000000077],
            [63.354977000000076, 26.494334000000038],
            [63.32677300000006, 26.483911000000035],
            [63.311039665000067, 26.47683087200005],
            [63.302249000000074, 26.472875000000045],
            [63.297155566000072, 26.471121442000026],
            [63.264849000000083, 26.459999000000039],
            [63.236645000000067, 26.450802000000067],
            [63.211508000000038, 26.444058000000041],
            [63.19250100000005, 26.436701000000028],
            [63.174721000000034, 26.436701000000028],
            [63.159266040000034, 26.438196326000025],
            [63.155714000000046, 26.438540000000046],
            [63.140999000000079, 26.442219000000023],
            [63.13180200000005, 26.452029000000039],
            [63.131964269000036, 26.452234531000045],
            [63.140999000000079, 26.463678000000073],
            [63.156940000000077, 26.472875000000045],
            [63.165524000000062, 26.488816000000043],
            [63.156940000000077, 26.494947000000025],
            [63.143452000000082, 26.49372100000005],
            [63.136094000000071, 26.48942900000003],
            [63.12076600000006, 26.485137000000066],
            [63.12689800000004, 26.493107000000066],
            [63.128737000000058, 26.499852000000033],
            [63.122606000000076, 26.506596000000059],
            [63.111570000000029, 26.506596000000059],
            [63.097468000000049, 26.507209000000046],
            [63.089497000000051, 26.510275000000036],
            [63.09255971500005, 26.521299175000024],
            [63.092563000000041, 26.521311000000026],
            [63.099920000000054, 26.530507000000057],
            [63.110957000000042, 26.54093000000006],
            [63.118314000000055, 26.548901000000058],
            [63.129963000000032, 26.557485000000042],
            [63.134255000000053, 26.56606800000003],
            [63.133029000000079, 26.577104000000077],
            [63.125058000000081, 26.588140000000067],
            [63.114022000000034, 26.596111000000064],
            [63.103599000000031, 26.605308000000036],
            [63.09072400000008, 26.62063500000005],
            [63.074841000000049, 26.641699000000074],
            [63.097070000000031, 26.644421000000023],
            [63.125650000000064, 26.644421000000023],
            [63.151962000000083, 26.642607000000055],
            [63.170561000000077, 26.645328000000063],
            [63.175552000000039, 26.668465000000026],
            [63.18462500000004, 26.691601000000048],
            [63.19369800000004, 26.717005000000029],
            [63.20231700000005, 26.742863000000057],
            [63.20231700000005, 26.765545000000031],
            [63.198688000000061, 26.790042000000028],
            [63.192337000000066, 26.814539000000025],
            [63.196706000000063, 26.845791000000077],
            [63.211844000000042, 26.85264600000005],
            [63.23770200000007, 26.858089000000064],
            [63.258570000000077, 26.86126500000006],
            [63.278077000000053, 26.862172000000044],
            [63.281252000000052, 26.881226000000026],
            [63.269911000000036, 26.898918000000037],
            [63.256755000000055, 26.927498000000071],
            [63.256301000000065, 26.953356000000042],
            [63.255782668000052, 26.98446565200004],
            [63.255394000000081, 27.007793000000049],
            [63.255394000000081, 27.063138000000038],
            [63.261895000000038, 27.086841000000049],
            [63.275288000000046, 27.098447000000078],
            [63.291360000000054, 27.114517000000035],
            [63.312560000000076, 27.12749400000007],
            [63.317544000000055, 27.133908000000076],
            [63.312554000000034, 27.150239000000056],
            [63.301667000000066, 27.167931000000067],
            [63.293047000000058, 27.183355000000063],
            [63.274447000000066, 27.194696000000079],
            [63.250858000000051, 27.206945000000076],
            [63.294948000000034, 27.211630000000071],
            [63.336891000000037, 27.199887000000047],
            [63.366980000000069, 27.203771000000074],
            [63.384706000000051, 27.20278600000006],
            [63.404400000000066, 27.194908000000055],
            [63.426065000000051, 27.194908000000055],
            [63.46053100000006, 27.207710000000077],
            [63.50189000000006, 27.208694000000037],
            [63.548173000000077, 27.209679000000051],
            [63.572791000000052, 27.216572000000042],
            [63.587562000000048, 27.220511000000045],
            [63.602333000000044, 27.241191000000072],
            [63.633845000000065, 27.231343000000038],
            [63.662402000000043, 27.231343000000038],
            [63.691945000000032, 27.233313000000066],
            [63.733304000000032, 27.236267000000055],
            [63.753983000000062, 27.239221000000043],
            [63.771709000000044, 27.251038000000051],
            [63.795342000000062, 27.25990100000007],
            [63.82291500000008, 27.25990100000007],
            [63.836701000000062, 27.239221000000043],
            [63.870183000000054, 27.214603000000068],
            [63.895786000000044, 27.188015000000064],
            [63.894801000000029, 27.162412000000074],
            [63.907603000000051, 27.143702000000076],
            [63.915842147000035, 27.138498258000027],
            [63.945023000000049, 27.12006800000006],
            [63.961763000000076, 27.108251000000052],
            [63.979489000000058, 27.098404000000073],
            [63.997214000000042, 27.085602000000051],
            [64.027741000000049, 27.082648000000063],
            [64.087810000000047, 27.07870900000006],
            [64.100612000000069, 27.079694000000075],
            [64.108490000000074, 27.059999000000062],
            [64.103566000000058, 27.03735000000006],
            [64.094703000000038, 27.023563000000024],
            [64.078947000000028, 27.010762000000057],
            [64.07775193100008, 27.005384037000056],
            [64.075008000000082, 26.993036000000075],
            [64.07599300000004, 26.985158000000069],
            [64.089313609000044, 26.993777686000044],
            [64.092734000000064, 26.99599100000006],
            [64.10750500000006, 27.008792000000028],
            [64.114398000000051, 27.023563000000024],
            [64.121291000000042, 27.038334000000077],
            [64.125230000000045, 27.060983000000078],
            [64.122276000000056, 27.07870900000006],
            [64.111444000000063, 27.095449000000031],
            [64.110459000000048, 27.100373000000047],
            [64.114398000000051, 27.113175000000069],
            [64.13310800000005, 27.122037000000034],
            [64.149849000000074, 27.125976000000037],
            [64.176437000000078, 27.127946000000065],
            [64.195147000000077, 27.12991500000004],
            [64.20597900000007, 27.134839000000056],
            [64.220750000000066, 27.147641000000078],
            [64.227643000000057, 27.162412000000074],
            [64.230985875000044, 27.166088892000062],
            [64.237491000000034, 27.173244000000068],
            [64.24733800000007, 27.175213000000042],
            [64.246353000000056, 27.165366000000063],
            [64.255216000000075, 27.164381000000049],
            [64.271956000000046, 27.176198000000056],
            [64.294623229000081, 27.201130955000053],
            [64.301499000000035, 27.208694000000037],
            [64.319930000000056, 27.22485000000006],
            [64.32857400000006, 27.239025000000026],
            [64.34447700000004, 27.246631000000036],
            [64.358652000000063, 27.25285400000007],
            [64.370407000000057, 27.254583000000025],
            [64.377321000000052, 27.263572000000067],
            [64.379050000000063, 27.272906000000035],
            [64.38458200000008, 27.27878400000003],
            [64.389422000000081, 27.28639000000004],
            [64.401868000000036, 27.294687000000067],
            [64.40809100000007, 27.29918200000003],
            [64.410567000000071, 27.299473071000023],
            [64.413969000000066, 27.299873000000048],
            [64.425724000000059, 27.292959000000053],
            [64.429872000000046, 27.283970000000068],
            [64.435404000000062, 27.274981000000025],
            [64.440936000000079, 27.263226000000031],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "34",
      properties: { name: "Pishin", count: 1258 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.457216000000074, 31.220998000000066],
            [67.467536000000052, 31.206549000000052],
            [67.477857000000029, 31.202421000000072],
            [67.500563000000056, 31.203453000000025],
            [67.516044000000079, 31.205517000000043],
            [67.52122746100008, 31.206062600000052],
            [67.535653000000082, 31.207581000000062],
            [67.553198000000066, 31.206549000000052],
            [67.56661500000007, 31.194164000000058],
            [67.57832619800007, 31.184404511000025],
            [67.579000000000065, 31.183843000000024],
            [67.584161000000051, 31.168360728000039],
            [67.59035300000005, 31.153911727000036],
            [67.608930000000043, 31.153913000000045],
            [67.626476000000082, 31.161138000000051],
            [67.634832553000081, 31.159280638000041],
            [67.645053000000075, 31.157009000000073],
            [67.658470000000079, 31.149785000000065],
            [67.676015000000064, 31.146689000000038],
            [67.691496000000029, 31.146689000000038],
            [67.721427001000052, 31.143592002000048],
            [67.721427147000043, 31.143591991000051],
            [67.737940000000037, 31.14256000000006],
            [67.760645000000068, 31.143592000000069],
            [67.781949131000033, 31.144411329000036],
            [67.787479000000076, 31.144624000000078],
            [67.815345000000036, 31.144624000000078],
            [67.823602000000051, 31.143592000000069],
            [67.814313000000084, 31.116756728000041],
            [67.806057000000067, 31.099213003000045],
            [67.796768000000043, 31.089922727000044],
            [67.786447000000067, 31.08166800500004],
            [67.773030000000062, 31.078572005000069],
            [67.761677000000077, 31.079604005000078],
            [67.754453000000069, 31.073411006000072],
            [67.755485000000078, 31.064123006000045],
            [67.764774000000045, 31.055866005000041],
            [67.752389000000051, 31.042449006000027],
            [67.751904813000067, 31.041480684000078],
            [67.743100000000084, 31.02387200000004],
            [67.727454312000077, 31.013915842000074],
            [67.720394000000056, 31.009423000000027],
            [67.68943207600006, 30.987749054000062],
            [67.689432000000068, 30.987749001000054],
            [67.672923896000043, 30.977322518000051],
            [67.669823000000065, 30.97536400000007],
            [67.648149000000046, 30.956787000000077],
            [67.64445777800006, 30.953886699000066],
            [67.643699798000057, 30.953291132000061],
            [67.633700000000033, 30.945434000000034],
            [67.63014127100007, 30.939685181000073],
            [67.620283000000029, 30.923760000000073],
            [67.614469573000065, 30.919884590000038],
            [67.601706000000036, 30.911376000000075],
            [67.589321000000041, 30.900023000000033],
            [67.572808000000066, 30.896927000000062],
            [67.547006000000067, 30.886606000000029],
            [67.526365000000055, 30.880413000000033],
            [67.505723000000046, 30.873189000000025],
            [67.484049000000084, 30.873189000000025],
            [67.471712428000046, 30.867494278000038],
            [67.470633000000078, 30.866996000000029],
            [67.464440000000081, 30.854612000000031],
            [67.456183000000067, 30.850483000000054],
            [67.44792700000005, 30.840162000000078],
            [67.447022932000039, 30.837902047000057],
            [67.441734000000054, 30.824681000000055],
            [67.443374667000057, 30.823960831000079],
            [67.458191492000083, 30.817457000000047],
            [67.458248000000083, 30.817457000000047],
            [67.47166500000003, 30.817457000000047],
            [67.487146000000052, 30.804040000000043],
            [67.485146947000032, 30.794047316000047],
            [67.484049000000084, 30.788559000000078],
            [67.475793000000067, 30.777206000000035],
            [67.474651054000049, 30.775207698000031],
            [67.474409170000058, 30.774784422000039],
            [67.471799852000061, 30.770218354000065],
            [67.468774608000047, 30.76492445100007],
            [67.467536000000052, 30.762757000000079],
            [67.465472000000034, 30.746244000000047],
            [67.462359663000029, 30.736906236000038],
            [67.461344000000054, 30.733859000000052],
            [67.451023000000077, 30.723538000000076],
            [67.432446000000084, 30.717346000000077],
            [67.431414000000075, 30.701865000000055],
            [67.449991000000068, 30.707025000000044],
            [67.46932621600007, 30.708042591000037],
            [67.469600000000071, 30.708057000000053],
            [67.469609263000052, 30.708042591000037],
            [67.46960966100005, 30.708041973000036],
            [67.472746121000057, 30.703163223000047],
            [67.478889000000038, 30.69360800000004],
            [67.483017000000075, 30.671934000000078],
            [67.511915000000045, 30.664710000000071],
            [67.560776729000054, 30.669695496000031],
            [67.562487000000033, 30.66987000000006],
            [67.569750520000071, 30.671685928000045],
            [67.571615790000067, 30.672152259000029],
            [67.579746808000039, 30.674185069000032],
            [67.599642000000074, 30.679159000000027],
            [67.629572000000053, 30.678127000000075],
            [67.63817527100008, 30.681137999000043],
            [67.638582689000032, 30.68128058800005],
            [67.650213000000065, 30.685351000000026],
            [67.667759000000046, 30.680191000000036],
            [67.676015000000064, 30.66987000000006],
            [67.684272000000078, 30.659549000000027],
            [67.687368000000049, 30.648197000000039],
            [67.686692172000051, 30.640762242000051],
            [67.686279297000056, 30.636901855000076],
            [67.692528000000038, 30.626523000000077],
            [67.692528000000038, 30.617234000000053],
            [67.692798553000046, 30.61709443500007],
            [67.69277956500008, 30.617081426000027],
            [67.691496000000029, 30.616202000000044],
            [67.69905262900005, 30.592022516000043],
            [67.699236866000035, 30.591433000000052],
            [67.69905262900005, 30.591433000000052],
            [67.698721000000035, 30.591433000000052],
            [67.690337775000046, 30.590703909000069],
            [67.671550753000076, 30.589069997000024],
            [67.656781002000059, 30.58778546700006],
            [67.651245000000074, 30.587304000000074],
            [67.622519657000055, 30.585765249000076],
            [67.606079102000081, 30.584899902000075],
            [67.593449000000078, 30.584208000000046],
            [67.586971214000073, 30.581750856000042],
            [67.563519000000042, 30.572855000000061],
            [67.56145500000008, 30.558406000000048],
            [67.558311888000048, 30.550023860000067],
            [67.557412400000032, 30.547625081000035],
            [67.556504683000071, 30.545204354000077],
            [67.555263000000082, 30.541893000000073],
            [67.538749000000053, 30.537765000000036],
            [67.536685000000034, 30.524348000000032],
            [67.53363679000006, 30.521299421000037],
            [67.528429000000074, 30.516091000000074],
            [67.513875362000078, 30.516091000000074],
            [67.510883000000035, 30.516091000000074],
            [67.49961920800007, 30.524351308000064],
            [67.49540200000007, 30.52744400000006],
            [67.470895080000048, 30.550918920000072],
            [67.467536000000052, 30.554278000000068],
            [67.457216000000074, 30.578016000000048],
            [67.44379900000007, 30.586272000000065],
            [67.411804000000075, 30.59349700000007],
            [67.392195000000072, 30.592465000000061],
            [67.38590358600004, 30.589319293000074],
            [67.363297000000046, 30.578016000000048],
            [67.354424164000079, 30.571190589000025],
            [67.336358060000066, 30.557306095000058],
            [67.318918000000053, 30.546021000000053],
            [67.313272195000081, 30.544310301000053],
            [67.299453526000036, 30.540123194000046],
            [67.28485900000004, 30.535701000000074],
            [67.25875555500005, 30.507526535000068],
            [67.250801000000081, 30.495450000000062],
            [67.238606484000059, 30.481900684000038],
            [67.234906064000029, 30.477789150000035],
            [67.232283609000035, 30.474875343000065],
            [67.232223000000033, 30.474808000000053],
            [67.228864000000044, 30.466596748000029],
            [67.222935000000064, 30.452103000000079],
            [67.217246027000044, 30.437880989000064],
            [67.19926300000003, 30.440525000000036],
            [67.186572000000069, 30.452665000000025],
            [67.17222600000008, 30.44438800000006],
            [67.166499479000038, 30.441842816000076],
            [67.155519862000062, 30.436962863000076],
            [67.142430000000047, 30.431145000000072],
            [67.131395000000055, 30.434456000000068],
            [67.123670000000061, 30.435560000000066],
            [67.11153100000007, 30.424524000000076],
            [67.10049500000008, 30.416247000000055],
            [67.089779225000029, 30.405877036000049],
            [67.083166735000077, 30.399478547000058],
            [67.079966368000044, 30.395628147000025],
            [67.074562000000071, 30.389210000000048],
            [67.065734000000077, 30.378175000000056],
            [67.055250000000058, 30.37155400000006],
            [67.040352000000041, 30.371002000000033],
            [67.029868000000079, 30.374864000000059],
            [67.020488000000057, 30.374312000000032],
            [67.013315000000034, 30.380934000000025],
            [66.998969000000045, 30.379278000000056],
            [66.983519000000058, 30.379278000000056],
            [66.975705685000037, 30.382495787000039],
            [66.974139000000037, 30.383141000000023],
            [66.961448000000075, 30.395832000000041],
            [66.955432746000042, 30.405756407000069],
            [66.950298623000037, 30.414258046000043],
            [66.947472620000042, 30.419692970000028],
            [66.943239000000062, 30.427835000000073],
            [66.942498138000076, 30.429390984000065],
            [66.939957961000061, 30.434725954000044],
            [66.939957916000083, 30.434726049000062],
            [66.93774447900006, 30.43942295100004],
            [66.937721793000037, 30.439422712000066],
            [66.933643947000064, 30.453468538000038],
            [66.93275600000004, 30.456527000000051],
            [66.924853417000065, 30.464956251000046],
            [66.92672900000008, 30.464487000000076],
            [66.918473000000063, 30.482033000000058],
            [66.913848413000039, 30.490254664000076],
            [66.905300313000055, 30.505451614000037],
            [66.905277028000057, 30.505493128000069],
            [66.899902344000054, 30.515075684000067],
            [66.871093750000057, 30.556274414000029],
            [66.858682087000034, 30.571119736000071],
            [66.85551600000008, 30.574919000000079],
            [66.852080593000039, 30.580454078000059],
            [66.836914062000062, 30.604919434000067],
            [66.829806807000068, 30.61880975400004],
            [66.826869070000043, 30.624397545000079],
            [66.82627971800008, 30.625541583000029],
            [66.818386703000044, 30.641409857000042],
            [66.814380607000032, 30.649129566000056],
            [66.807185210000057, 30.662770146000071],
            [66.806489028000044, 30.664261619000058],
            [66.800553469000079, 30.675800483000046],
            [66.796760434000078, 30.68315560700006],
            [66.792552300000068, 30.692097793000073],
            [66.787489467000057, 30.702883771000074],
            [66.787088268000048, 30.703738496000028],
            [66.776274252000064, 30.726776934000043],
            [66.775914190000037, 30.72754401800006],
            [66.775909998000031, 30.727552950000074],
            [66.772950000000037, 30.733859000000052],
            [66.77232101900006, 30.737318548000076],
            [66.770965373000081, 30.744774929000073],
            [66.769287109000061, 30.755676270000038],
            [66.769341428000075, 30.755697277000024],
            [66.771426488000031, 30.759225781000055],
            [66.771426503000043, 30.759225807000064],
            [66.778543432000049, 30.771200810000039],
            [66.783260024000072, 30.779022959000031],
            [66.793705810000063, 30.79386932400007],
            [66.796893719000082, 30.798341278000066],
            [66.80391300000008, 30.808168000000023],
            [66.812642892000042, 30.818207736000033],
            [66.812642911000069, 30.818207758000028],
            [66.817374936000078, 30.823589836000053],
            [66.824554000000035, 30.83190600000006],
            [66.838074090000077, 30.853695191000043],
            [66.838076286000046, 30.853698730000076],
            [66.838287894000075, 30.854723979000028],
            [66.842236436000064, 30.873626573000024],
            [66.842671811000059, 30.875232316000051],
            [66.845275879000042, 30.884521484000061],
            [66.845196000000044, 30.886020440000038],
            [66.845275879000042, 30.906311035000044],
            [66.844299316000047, 30.914489746000072],
            [66.831711342000062, 30.922660546000031],
            [66.829665040000066, 30.926980016000073],
            [66.827697718000081, 30.931897719000062],
            [66.827650000000062, 30.93201700000003],
            [66.827697718000081, 30.932054022000045],
            [66.827721632000078, 30.932072576000053],
            [66.828672181000059, 30.932810066000059],
            [66.855517898000073, 30.951625539000077],
            [66.862141903000065, 30.950048550000076],
            [66.877319336000028, 30.946472168000071],
            [66.889709473000039, 30.924926758000026],
            [66.905037717000027, 30.91987258000006],
            [66.912292480000076, 30.917480469000054],
            [66.933397201000048, 30.92155953300005],
            [66.934082031000059, 30.921691895000038],
            [66.947509766000053, 30.936279297000056],
            [66.96229420700007, 30.935267214000078],
            [66.964916000000073, 30.935113000000058],
            [66.965881348000039, 30.959899902000075],
            [66.967219439000075, 30.960720306000042],
            [66.984497070000032, 30.971313477000024],
            [66.996232387000077, 30.972737247000055],
            [67.000525452000034, 30.973235810000062],
            [67.001098633000083, 30.973327637000068],
            [67.018676758000083, 30.973327637000068],
            [67.03009684500006, 30.96466705000006],
            [67.030319357000053, 30.964498305000063],
            [67.035095215000069, 30.960876465000069],
            [67.04179756700006, 30.956448076000072],
            [67.053698669000028, 30.948494493000055],
            [67.061931000000072, 30.936145000000067],
            [67.073676935000037, 30.938662305000037],
            [67.076380000000029, 30.939242000000036],
            [67.098210166000058, 30.954125855000029],
            [67.099086000000057, 30.954723000000058],
            [67.133319878000066, 30.975643498000068],
            [67.136240000000043, 30.977428000000032],
            [67.170299000000057, 30.99910200000005],
            [67.182684000000052, 31.00426200000004],
            [67.185244582000053, 31.004853051000055],
            [67.196101000000056, 31.007359000000065],
            [67.210288568000067, 31.01232440800004],
            [67.216742000000067, 31.014583000000073],
            [67.219838000000038, 31.028000000000077],
            [67.217874082000037, 31.037165583000046],
            [67.216742000000067, 31.042449000000033],
            [67.20951800000006, 31.056898000000047],
            [67.215811328000029, 31.076302385000076],
            [67.221903000000054, 31.09508500000004],
            [67.227240610000081, 31.108726709000052],
            [67.231191000000081, 31.118823000000077],
            [67.243576000000076, 31.127079000000037],
            [67.25699300000008, 31.135336000000052],
            [67.254929000000061, 31.146689000000038],
            [67.250801000000081, 31.155977000000064],
            [67.250801000000081, 31.162555518000033],
            [67.250801000000081, 31.16629800000004],
            [67.253897000000052, 31.179715000000044],
            [67.257768140000053, 31.192173279000031],
            [67.25984191200007, 31.198847187000069],
            [67.261274000000071, 31.203456000000074],
            [67.272413000000029, 31.20214500000003],
            [67.286250000000052, 31.206572000000051],
            [67.303864000000033, 31.209353000000078],
            [67.319999000000053, 31.210661000000073],
            [67.333805000000041, 31.213217000000043],
            [67.347611000000029, 31.21577300000007],
            [67.363489000000072, 31.214594000000034],
            [67.380524000000037, 31.213284000000044],
            [67.388007000000073, 31.21525900000006],
            [67.394939000000079, 31.215250000000026],
            [67.404370000000029, 31.213725000000068],
            [67.417665000000056, 31.216281000000038],
            [67.428915000000075, 31.221905000000049],
            [67.440212000000031, 31.231223000000057],
            [67.451440000000048, 31.233617000000038],
            [67.457216000000074, 31.220998000000066],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "35",
      properties: { name: "Quetta", count: 1405 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.93275600000004, 30.456527000000051],
            [66.933643947000064, 30.453468538000038],
            [66.937721793000037, 30.439422712000066],
            [66.93774447900006, 30.43942295100004],
            [66.939957916000083, 30.434726049000062],
            [66.939957961000061, 30.434725954000044],
            [66.942498138000076, 30.429390984000065],
            [66.943239000000062, 30.427835000000073],
            [66.947472620000042, 30.419692970000028],
            [66.950298623000037, 30.414258046000043],
            [66.955432746000042, 30.405756407000069],
            [66.961448000000075, 30.395832000000041],
            [66.974139000000037, 30.383141000000023],
            [66.975705685000037, 30.382495787000039],
            [66.983519000000058, 30.379278000000056],
            [66.998969000000045, 30.379278000000056],
            [67.013315000000034, 30.380934000000025],
            [67.020488000000057, 30.374312000000032],
            [67.029868000000079, 30.374864000000059],
            [67.040352000000041, 30.371002000000033],
            [67.055250000000058, 30.37155400000006],
            [67.065734000000077, 30.378175000000056],
            [67.074562000000071, 30.389210000000048],
            [67.079966368000044, 30.395628147000025],
            [67.083166735000077, 30.399478547000058],
            [67.089779225000029, 30.405877036000049],
            [67.10049500000008, 30.416247000000055],
            [67.11153100000007, 30.424524000000076],
            [67.123670000000061, 30.435560000000066],
            [67.131395000000055, 30.434456000000068],
            [67.142430000000047, 30.431145000000072],
            [67.155519862000062, 30.436962863000076],
            [67.166499479000038, 30.441842816000076],
            [67.17222600000008, 30.44438800000006],
            [67.186572000000069, 30.452665000000025],
            [67.19926300000003, 30.440525000000036],
            [67.217246027000044, 30.437880989000064],
            [67.214678000000049, 30.43146100000007],
            [67.202293000000054, 30.415980000000047],
            [67.189908000000059, 30.396371000000045],
            [67.191467719000059, 30.386234523000041],
            [67.194037000000037, 30.369537000000037],
            [67.211582000000078, 30.348895000000027],
            [67.219046459000083, 30.343489814000066],
            [67.219046578000075, 30.343489728000065],
            [67.241512000000057, 30.327222000000063],
            [67.263185026000031, 30.318965371000047],
            [67.263186000000076, 30.318965000000048],
            [67.287080967000065, 30.329916991000061],
            [67.28711198700006, 30.329932860000042],
            [67.288605672000074, 30.318481280000071],
            [67.289751000000081, 30.30989100000005],
            [67.290790514000037, 30.304953030000036],
            [67.292632655000034, 30.296441070000071],
            [67.281932655000048, 30.286441070000024],
            [67.27816069000005, 30.281843987000059],
            [67.269168000000036, 30.270853000000045],
            [67.264835170000083, 30.26773328400003],
            [67.251432655000031, 30.258041070000047],
            [67.243632655000056, 30.250941070000067],
            [67.235199870000031, 30.248174062000032],
            [67.230840000000057, 30.246721000000036],
            [67.225872000000038, 30.233945000000062],
            [67.225297724000029, 30.233605183000066],
            [67.221334120000051, 30.231259797000064],
            [67.19109300000008, 30.213361000000077],
            [67.170510000000036, 30.195617000000027],
            [67.148507000000052, 30.180712000000028],
            [67.127923000000067, 30.171485000000075],
            [67.11349672700004, 30.171105235000027],
            [67.100952000000063, 30.170775000000049],
            [67.070432000000039, 30.166517000000056],
            [67.049906049000072, 30.152211141000066],
            [67.04700900000006, 30.150192000000061],
            [67.03778200000005, 30.135996000000034],
            [67.04700900000006, 30.120381000000066],
            [67.05055800000008, 30.105476000000067],
            [67.035653000000082, 30.103347000000042],
            [67.034233000000029, 30.084183000000053],
            [67.033149325000068, 30.07919821400003],
            [67.030684000000065, 30.067858000000058],
            [67.026426000000072, 30.052953000000059],
            [67.01861800000006, 30.043726000000049],
            [67.005783431000054, 30.043726000000049],
            [66.998745000000042, 30.043726000000049],
            [66.99317201100007, 30.043726000000049],
            [66.974383333000048, 30.043726000000049],
            [66.972483000000068, 30.043726000000049],
            [66.965403265000077, 30.044071517000077],
            [66.963270438000052, 30.044175608000046],
            [66.960636814000054, 30.044304139000076],
            [66.960612135000076, 30.044305343000076],
            [66.953817996000055, 30.044636923000041],
            [66.943382000000042, 30.045146000000045],
            [66.917121000000066, 30.044436000000076],
            [66.89724700000005, 30.053663000000029],
            [66.883762000000047, 30.04727500000007],
            [66.863888000000031, 30.034499000000039],
            [66.848983000000032, 30.025982000000056],
            [66.831238000000042, 30.020303000000069],
            [66.826954139000065, 30.021632509000028],
            [66.810654992000082, 30.026690998000049],
            [66.809944991000066, 30.03875800000003],
            [66.813493994000055, 30.045855002000053],
            [66.82910898800003, 30.050113998000029],
            [66.83549699100007, 30.05721200000005],
            [66.837625990000049, 30.063600003000033],
            [66.835938854000062, 30.064925634000076],
            [66.833062730000051, 30.067185487000074],
            [66.827689990000067, 30.071407000000079],
            [66.815622989000076, 30.079214998000054],
            [66.804976994000072, 30.079215000000033],
            [66.80254136800005, 30.082137541000066],
            [66.79787900000008, 30.087732000000074],
            [66.799299000000076, 30.102637000000072],
            [66.799299000000076, 30.111154000000056],
            [66.787233000000072, 30.115413000000046],
            [66.77516600000007, 30.116123000000073],
            [66.761681000000067, 30.121801000000062],
            [66.746776000000068, 30.126769000000024],
            [66.742517000000078, 30.136706000000061],
            [66.729031000000077, 30.148062000000039],
            [66.710577000000058, 30.152321000000029],
            [66.702682334000031, 30.15060472600004],
            [66.694252000000063, 30.148772000000065],
            [66.678637000000037, 30.119672000000037],
            [66.655925000000082, 30.077085000000068],
            [66.62966300000005, 30.023852000000034],
            [66.62621265000007, 30.016139469000052],
            [66.617597000000046, 29.99688100000003],
            [66.614758000000052, 29.979846000000066],
            [66.611919000000057, 29.960683000000074],
            [66.604111000000046, 29.947197000000074],
            [66.592045000000041, 29.935841000000039],
            [66.577850000000069, 29.921645000000069],
            [66.573591000000079, 29.904611000000045],
            [66.573225385000057, 29.895473181000057],
            [66.572881000000052, 29.886866000000055],
            [66.568416665000029, 29.871685844000069],
            [66.565784009000083, 29.862734000000046],
            [66.557266009000045, 29.844280000000026],
            [66.549459008000042, 29.825825997000038],
            [66.528876008000054, 29.808791003000067],
            [66.501194008000027, 29.809501001000058],
            [66.480611011000065, 29.812340001000052],
            [66.464996008000071, 29.828665000000058],
            [66.449381009000035, 29.840021000000036],
            [66.435895008000045, 29.851377997000043],
            [66.411763010000072, 29.847119001000067],
            [66.386211010000068, 29.847119000000077],
            [66.373905950000051, 29.847119000000077],
            [66.373905887000035, 29.847119000000077],
            [66.364918000000046, 29.847119000000077],
            [66.357767501000069, 29.849121011000079],
            [66.347174000000052, 29.85208700000004],
            [66.31949300000008, 29.85208700000004],
            [66.298909000000037, 29.854217000000062],
            [66.287722896000048, 29.857558651000033],
            [66.265517000000045, 29.864190000000065],
            [66.27052400000008, 29.867405000000076],
            [66.274067000000059, 29.87225200000006],
            [66.27608900000007, 29.871810000000039],
            [66.282211000000075, 29.87740400000007],
            [66.287019000000043, 29.882911000000036],
            [66.290106000000037, 29.885421000000065],
            [66.289248000000043, 29.887669000000074],
            [66.294358000000045, 29.889738000000079],
            [66.301697000000047, 29.896565000000066],
            [66.307165000000055, 29.902115000000038],
            [66.313623000000064, 29.902688000000069],
            [66.319721000000072, 29.907972000000029],
            [66.327038000000073, 29.912036000000057],
            [66.335575000000063, 29.919759000000056],
            [66.344518000000051, 29.931140000000028],
            [66.34939600000007, 29.944147000000044],
            [66.350944300000037, 29.947243178000065],
            [66.35305500000004, 29.951464000000044],
            [66.360372000000041, 29.95349600000003],
            [66.368909000000031, 29.961625000000026],
            [66.369316000000083, 29.970974000000069],
            [66.358381000000065, 29.974262000000067],
            [66.347897000000046, 29.980814000000066],
            [66.338069000000075, 29.988022000000058],
            [66.326930000000061, 29.994574000000057],
            [66.319067000000075, 29.999160000000074],
            [66.306618000000071, 30.005713000000071],
            [66.296790000000044, 30.014886000000047],
            [66.288927000000058, 30.020783000000051],
            [66.279099000000031, 30.026680000000056],
            [66.27058100000005, 30.03388700000005],
            [66.259442000000035, 30.039129000000059],
            [66.250924000000055, 30.045026000000064],
            [66.243062000000066, 30.055509000000029],
            [66.241096000000027, 30.065993000000049],
            [66.245644484000081, 30.069785443000058],
            [66.254849000000036, 30.077460000000031],
            [66.269298000000049, 30.088812000000075],
            [66.281683000000044, 30.099133000000052],
            [66.284040652000044, 30.10443828800004],
            [66.285811000000081, 30.108422000000076],
            [66.290972000000068, 30.116678000000036],
            [66.302324000000056, 30.12596700000006],
            [66.308517000000052, 30.139384000000064],
            [66.33431900000005, 30.161058000000025],
            [66.343607000000077, 30.161058000000025],
            [66.346704000000045, 30.170346000000052],
            [66.354960000000062, 30.179635000000076],
            [66.361153000000058, 30.179635000000076],
            [66.372506000000044, 30.179635000000076],
            [66.38282600000008, 30.17241000000007],
            [66.389019000000076, 30.166218000000072],
            [66.400372000000061, 30.166218000000072],
            [66.412756000000059, 30.163122000000044],
            [66.428595274000031, 30.157182033000026],
            [66.429270000000031, 30.156929000000048],
            [66.453007000000071, 30.156929000000048],
            [66.476745000000051, 30.157961000000057],
            [66.499451000000079, 30.160026000000073],
            [66.510803000000067, 30.164154000000053],
            [66.514932000000044, 30.177571000000057],
            [66.516996000000063, 30.189956000000052],
            [66.519060000000081, 30.204405000000065],
            [66.516996000000063, 30.212661000000026],
            [66.52628500000003, 30.218854000000078],
            [66.538669000000084, 30.215758000000051],
            [66.547958000000051, 30.215758000000051],
            [66.559311000000037, 30.210597000000064],
            [66.567568000000051, 30.202341000000047],
            [66.57788800000003, 30.205437000000074],
            [66.588209000000063, 30.205437000000074],
            [66.602658000000076, 30.195116000000041],
            [66.615043000000071, 30.183763000000056],
            [66.631556000000046, 30.176539000000048],
            [66.648069000000078, 30.168282000000033],
            [66.661486000000082, 30.17241000000007],
            [66.675935000000038, 30.177571000000057],
            [66.690384000000051, 30.178603000000066],
            [66.691889438000032, 30.179506248000052],
            [66.693230398000082, 30.180310811000027],
            [66.711026000000061, 30.190988000000061],
            [66.713050903000067, 30.198413298000048],
            [66.714122000000032, 30.202341000000047],
            [66.722379000000046, 30.21679000000006],
            [66.732348412000078, 30.224458206000065],
            [66.73579600000005, 30.227110000000039],
            [66.741391444000044, 30.238923808000038],
            [66.745084000000077, 30.246720000000039],
            [66.744052000000067, 30.259105000000034],
            [66.757469000000071, 30.264265000000023],
            [66.757213269000033, 30.268101211000044],
            [66.756437000000062, 30.279746000000046],
            [66.767790000000048, 30.291099000000031],
            [66.769604039000058, 30.291796656000031],
            [66.781207000000052, 30.296259000000077],
            [66.792560000000037, 30.31277300000005],
            [66.796674446000054, 30.318716531000064],
            [66.801848000000064, 30.326190000000054],
            [66.80187113900007, 30.326363466000032],
            [66.80391300000008, 30.341671000000076],
            [66.813201000000049, 30.36024800000007],
            [66.819394000000045, 30.367472000000078],
            [66.814042631000063, 30.380849990000058],
            [66.813201000000049, 30.382954000000041],
            [66.830746000000033, 30.391210000000058],
            [66.827650000000062, 30.407723000000033],
            [66.839003000000048, 30.422172000000046],
            [66.842174296000053, 30.426319243000023],
            [66.852420000000052, 30.439718000000028],
            [66.860677000000067, 30.461391000000049],
            [66.873062000000061, 30.456231000000059],
            [66.890607000000045, 30.460359000000039],
            [66.910216000000048, 30.468616000000054],
            [66.924853417000065, 30.464956251000046],
            [66.93275600000004, 30.456527000000051],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "36",
      properties: { name: "Sheerani", count: 939 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.850951000000066, 31.920486000000039],
            [69.853959000000032, 31.884384000000068],
            [69.86599300000006, 31.889198000000079],
            [69.881036000000051, 31.880172000000073],
            [69.89487500000007, 31.884986000000026],
            [69.907510000000059, 31.892808000000059],
            [69.91894300000007, 31.907850000000053],
            [69.923756000000083, 31.921088000000054],
            [69.938799000000074, 31.931317000000035],
            [69.956849143000056, 31.934926929000028],
            [69.962866000000076, 31.931918000000053],
            [69.962866000000076, 31.924698000000035],
            [69.962866000000076, 31.906647000000078],
            [69.959551789000045, 31.896228998000026],
            [69.958655000000078, 31.893410000000074],
            [69.956849000000034, 31.872952000000055],
            [69.949027000000058, 31.857308000000046],
            [69.938799000000074, 31.850689000000045],
            [69.939400000000035, 31.832037000000071],
            [69.944214000000045, 31.809172000000046],
            [69.941205000000082, 31.798943000000065],
            [69.957451000000049, 31.782697000000041],
            [69.955044000000044, 31.76885800000008],
            [69.94662100000005, 31.766451000000075],
            [69.952036000000078, 31.727943000000039],
            [69.950231000000031, 31.705680000000029],
            [69.949622584000053, 31.655637051000042],
            [69.944214000000045, 31.569696000000079],
            [69.94481600000006, 31.540213000000051],
            [69.955044000000044, 31.522162000000037],
            [69.97068900000005, 31.509526000000051],
            [69.983926000000054, 31.512535000000071],
            [70.011002000000076, 31.510730000000024],
            [70.023036000000047, 31.492077000000052],
            [70.045901000000072, 31.430102000000034],
            [70.050714000000028, 31.406034000000034],
            [70.056160439000053, 31.38503251800006],
            [70.048788469000044, 31.378789280000035],
            [70.048706055000082, 31.367309570000032],
            [70.050720215000069, 31.333312988000046],
            [70.046530530000041, 31.329743997000037],
            [70.042480469000054, 31.326293945000032],
            [70.03095697100008, 31.322222465000038],
            [70.019470215000069, 31.319091797000056],
            [69.998474121000072, 31.320495605000076],
            [69.983367628000053, 31.317348375000051],
            [69.973693848000039, 31.309509277000075],
            [69.965087891000053, 31.300476074000073],
            [69.961183755000036, 31.29507620000004],
            [69.955688477000081, 31.287475586000028],
            [69.949881284000071, 31.27752855600005],
            [69.945111743000041, 31.27844487200008],
            [69.937500000000057, 31.279907227000024],
            [69.924807833000045, 31.284974666000039],
            [69.914489746000072, 31.283691406000059],
            [69.896911621000072, 31.295715332000043],
            [69.883911133000083, 31.302307129000042],
            [69.866882324000073, 31.305297852000024],
            [69.857968098000072, 31.309761496000078],
            [69.853881836000028, 31.318115234000061],
            [69.840881348000039, 31.317687988000046],
            [69.822875977000081, 31.31671142600004],
            [69.815673828000058, 31.31530761700003],
            [69.811733931000049, 31.312922237000066],
            [69.806564000000037, 31.309929000000068],
            [69.800356000000079, 31.301811000000043],
            [69.799729343000081, 31.301403683000046],
            [69.790893555000082, 31.295715332000043],
            [69.784149958000057, 31.291605533000052],
            [69.774719238000046, 31.289489746000072],
            [69.767764457000055, 31.289002199000038],
            [69.762695312000062, 31.295715332000043],
            [69.757873535000044, 31.305725098000039],
            [69.753112793000071, 31.312927246000072],
            [69.742675781000059, 31.310485840000069],
            [69.737906571000053, 31.307917596000038],
            [69.734951865000028, 31.320313924000061],
            [69.714636296000037, 31.322537416000046],
            [69.693254000000081, 31.325827000000061],
            [69.67496200000005, 31.325827000000061],
            [69.656670000000076, 31.334973000000048],
            [69.648218449000069, 31.334973000000048],
            [69.640913658000045, 31.334973000000048],
            [69.631726000000072, 31.334973000000048],
            [69.625766637000083, 31.33476736800003],
            [69.607614000000069, 31.334141000000045],
            [69.595437261000029, 31.337185577000071],
            [69.584333000000072, 31.339962000000071],
            [69.570650651000051, 31.348885147000033],
            [69.565209000000038, 31.352434000000073],
            [69.563546000000031, 31.37322000000006],
            [69.558557000000064, 31.392344000000037],
            [69.556836798000063, 31.399869494000029],
            [69.551905000000033, 31.421445000000062],
            [69.541928000000041, 31.438906000000031],
            [69.540265000000034, 31.460524000000078],
            [69.535276000000067, 31.479648000000054],
            [69.53610800000007, 31.498772000000031],
            [69.53610800000007, 31.508512407000069],
            [69.53610800000007, 31.512274533000038],
            [69.53610800000007, 31.519558000000075],
            [69.53610800000007, 31.530367000000069],
            [69.542759000000046, 31.542008000000067],
            [69.553568000000041, 31.554480000000069],
            [69.556894000000057, 31.568615000000079],
            [69.562714000000028, 31.574435000000051],
            [69.563099131000058, 31.582902330000024],
            [69.563546000000031, 31.592727000000025],
            [69.569366000000059, 31.602705000000071],
            [69.570198000000062, 31.613514000000066],
            [69.571861000000069, 31.630143000000032],
            [69.575186000000031, 31.64344600000004],
            [69.581007000000056, 31.65758100000005],
            [69.581007000000056, 31.66755900000004],
            [69.583501000000069, 31.684188000000063],
            [69.581007000000056, 31.704143000000045],
            [69.588490000000036, 31.71744700000005],
            [69.590564994000033, 31.721596677000036],
            [69.595142000000067, 31.730750000000057],
            [69.600962000000038, 31.748211000000026],
            [69.60434882900006, 31.757242544000064],
            [69.605951000000061, 31.761515000000031],
            [69.614265000000046, 31.78147000000007],
            [69.622558361000074, 31.797895145000041],
            [69.621748000000082, 31.821380000000033],
            [69.615928000000054, 31.843830000000025],
            [69.611771000000033, 31.864616000000069],
            [69.605119000000059, 31.882077000000038],
            [69.587658000000033, 31.898706000000061],
            [69.580175000000054, 31.914504000000079],
            [69.566872000000046, 31.929470000000038],
            [69.553354322000075, 31.954249535000031],
            [69.562136000000066, 31.957791000000043],
            [69.572365000000048, 31.970427000000029],
            [69.587407000000042, 31.972232000000076],
            [69.60305100000005, 31.990283000000034],
            [69.618094000000042, 31.997503000000052],
            [69.628322000000082, 32.00111400000003],
            [69.644568000000049, 32.029995000000042],
            [69.657806000000051, 32.056470000000047],
            [69.690899000000059, 32.068504000000075],
            [69.717374000000063, 32.072114000000056],
            [69.744450000000029, 32.076326000000051],
            [69.74839877800008, 32.078232489000072],
            [69.752517084000033, 32.080220827000062],
            [69.761899000000028, 32.084750000000042],
            [69.788976000000048, 32.089563000000055],
            [69.849146000000076, 32.098589000000061],
            [69.850951000000066, 32.091368000000045],
            [69.837112000000047, 32.069707000000051],
            [69.826281000000051, 32.049851000000046],
            [69.820264000000066, 32.03541000000007],
            [69.802213000000052, 32.021571000000051],
            [69.805823000000032, 32.01134200000007],
            [69.823273000000029, 32.002317000000062],
            [69.844332000000065, 31.992088000000024],
            [69.86418800000007, 31.984266000000048],
            [69.879832000000079, 31.978851000000077],
            [69.878629000000046, 31.960198000000048],
            [69.873815000000036, 31.946359000000029],
            [69.858171000000084, 31.92710500000004],
            [69.850951000000066, 31.920486000000039],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "37",
      properties: { name: "Sibi", count: 160 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.179665000000057, 29.37173400000006],
            [68.174636000000078, 29.367423000000031],
            [68.159665000000075, 29.360686000000044],
            [68.143197000000043, 29.354697000000044],
            [68.111009000000081, 29.343469000000027],
            [68.095289000000037, 29.338229000000069],
            [68.084809000000064, 29.343469000000027],
            [68.073581000000047, 29.346463000000028],
            [68.06235300000003, 29.356194000000073],
            [68.051873000000057, 29.356943000000058],
            [68.048551906000057, 29.358121448000077],
            [68.028668000000039, 29.365177000000074],
            [68.024726045000079, 29.365669704000027],
            [68.016691000000037, 29.366674000000046],
            [68.006211000000064, 29.37790300000006],
            [67.991988000000049, 29.37790300000006],
            [67.982257000000061, 29.371914000000061],
            [67.979767002000074, 29.369601961000058],
            [67.979767000000038, 29.369602000000043],
            [67.971777000000031, 29.362183000000073],
            [67.962609684000029, 29.356071229000065],
            [67.958303000000058, 29.353200000000072],
            [67.945578000000069, 29.344966000000056],
            [67.935098000000039, 29.337481000000025],
            [67.920876000000078, 29.333738000000039],
            [67.91636154400004, 29.332383452000045],
            [67.905905000000075, 29.329246000000069],
            [67.896929115000034, 29.329246000000069],
            [67.890185000000031, 29.329246000000069],
            [67.874465000000043, 29.328498000000025],
            [67.87349604800005, 29.333100684000044],
            [67.873410160000049, 29.333508667000046],
            [67.871471000000042, 29.342720000000043],
            [67.884945000000073, 29.353949000000057],
            [67.887113518000035, 29.363344301000041],
            [67.88719100000003, 29.363680000000045],
            [67.890185000000031, 29.374160000000074],
            [67.886442000000045, 29.392125000000078],
            [67.882699000000059, 29.401108000000079],
            [67.883448000000044, 29.416827000000069],
            [67.877459000000044, 29.433296000000041],
            [67.874478103000058, 29.43542523800005],
            [67.874477994000074, 29.435425317000067],
            [67.861740000000054, 29.444524000000058],
            [67.849709421000057, 29.447804867000059],
            [67.84527200000008, 29.449015000000031],
            [67.835033959000043, 29.452011589000051],
            [67.827068919000055, 29.454342890000078],
            [67.825748787000066, 29.454729282000073],
            [67.814581000000032, 29.457998000000032],
            [67.814480151000055, 29.458216468000046],
            [67.813301893000073, 29.460768923000046],
            [67.810089000000062, 29.467729000000077],
            [67.813862786000072, 29.478510937000067],
            [67.815329000000077, 29.482700000000079],
            [67.81308400000006, 29.493929000000037],
            [67.807844000000046, 29.50291100000004],
            [67.807097250000083, 29.512613767000062],
            [67.807095000000061, 29.512643000000025],
            [67.80410100000006, 29.527614000000028],
            [67.805598000000032, 29.540339000000074],
            [67.800579016000029, 29.550377805000039],
            [67.79961000000003, 29.552316000000076],
            [67.790627000000029, 29.562047000000064],
            [67.788381000000072, 29.572527000000036],
            [67.787668533000044, 29.58322163400004],
            [67.787666712000032, 29.583248963000074],
            [67.787633000000028, 29.583755000000053],
            [67.783890000000042, 29.592738000000054],
            [67.775656000000083, 29.599475000000041],
            [67.765176000000054, 29.602469000000042],
            [67.750954000000036, 29.603218000000027],
            [67.738228000000049, 29.60097200000007],
            [67.726251000000047, 29.599475000000041],
            [67.718017000000032, 29.606212000000028],
            [67.710532000000057, 29.60097200000007],
            [67.698555000000056, 29.599475000000041],
            [67.694839373000036, 29.601951901000064],
            [67.691818000000069, 29.603966000000071],
            [67.688075000000083, 29.614446000000044],
            [67.68433200000004, 29.62118300000003],
            [67.685081000000082, 29.629417000000046],
            [67.680589000000055, 29.636903000000075],
            [67.67310400000008, 29.646634000000063],
            [67.664870000000064, 29.646634000000063],
            [67.652893000000063, 29.651874000000078],
            [67.641664000000048, 29.660108000000037],
            [67.632682000000045, 29.663102000000038],
            [67.632568000000049, 29.662694000000045],
            [67.628444000000059, 29.66361100000006],
            [67.625726203000056, 29.663506332000054],
            [67.611722000000043, 29.662967000000037],
            [67.593071000000066, 29.666183000000046],
            [67.573777000000064, 29.666826000000071],
            [67.559628000000032, 29.671328000000074],
            [67.550624000000028, 29.675830000000076],
            [67.546122000000082, 29.686764000000039],
            [67.53711700100007, 29.696411000000069],
            [67.528113000000076, 29.695125000000075],
            [67.518466000000046, 29.704772000000048],
            [67.511392000000058, 29.71763500000003],
            [67.501745000000028, 29.734357000000045],
            [67.499815000000069, 29.75815300000005],
            [67.498529000000076, 29.782592000000079],
            [67.494670000000042, 29.801244000000054],
            [67.486952000000031, 29.821181000000024],
            [67.477948000000083, 29.836617000000047],
            [67.468301000000054, 29.854625000000055],
            [67.461870000000033, 29.861056000000076],
            [67.460583000000042, 29.873276000000033],
            [67.441932000000065, 29.89450000000005],
            [67.415563000000077, 29.910578000000044],
            [67.397555000000068, 29.913794000000053],
            [67.374402000000032, 29.920869000000039],
            [67.373394805000032, 29.921592421000071],
            [67.373394404000067, 29.921592710000027],
            [67.373394000000076, 29.92159300000003],
            [67.369246000000032, 29.933002000000045],
            [67.369956000000059, 29.949326000000042],
            [67.368854295000062, 29.955566926000074],
            [67.367826000000036, 29.961392000000046],
            [67.357889000000057, 29.979846000000066],
            [67.355050000000062, 29.995461000000034],
            [67.364987000000042, 30.00610800000004],
            [67.385571000000084, 30.008947000000035],
            [67.40757300000007, 30.01533500000005],
            [67.413961000000029, 30.027401000000054],
            [67.41254200000003, 30.03378900000007],
            [67.396217000000036, 30.04301600000008],
            [67.411832000000061, 30.052243000000033],
            [67.430375000000083, 30.053616997000063],
            [67.430667436000078, 30.053638658000068],
            [67.430665882000028, 30.053635971000062],
            [67.431965881000053, 30.047335971000052],
            [67.459065881000072, 30.032035971000028],
            [67.469001280000043, 30.030247599000063],
            [67.479061000000058, 30.028472000000079],
            [67.488508029000059, 30.027916653000034],
            [67.499083000000041, 30.027295000000038],
            [67.510046970000076, 30.030340231000025],
            [67.520282000000066, 30.033183000000065],
            [67.527233099000057, 30.035321765000049],
            [67.535593000000063, 30.037894000000051],
            [67.537219916000083, 30.047653422000053],
            [67.537949000000083, 30.052027000000066],
            [67.538747280000052, 30.054821827000069],
            [67.540299485000048, 30.060256190000075],
            [67.540304000000049, 30.060272000000055],
            [67.547731301000056, 30.064727876000063],
            [67.552082000000041, 30.067338000000063],
            [67.56150400000007, 30.073227000000031],
            [67.560942371000067, 30.078280712000037],
            [67.560326000000032, 30.083827000000042],
            [67.562408580000067, 30.087991805000058],
            [67.566215000000057, 30.095604000000037],
            [67.582703000000038, 30.095604000000037],
            [67.604838156000028, 30.102060447000042],
            [67.610970000000066, 30.103849000000025],
            [67.622747000000061, 30.11798200000004],
            [67.638058000000058, 30.120337000000063],
            [67.653369000000055, 30.120337000000063],
            [67.653999298000031, 30.120376402000034],
            [67.672213000000056, 30.121515000000045],
            [67.678259931000071, 30.122523036000075],
            [67.686346000000071, 30.123871000000065],
            [67.70047900000003, 30.108560000000068],
            [67.712646633000077, 30.105952176000073],
            [67.716965882000068, 30.105035971000063],
            [67.72357308100004, 30.10238410200003],
            [67.746412000000078, 30.093249000000071],
            [67.755689945000029, 30.087063703000069],
            [67.760565881000048, 30.083835971000042],
            [67.76836178800005, 30.077964160000079],
            [67.78410000000008, 30.066160000000025],
            [67.784751379000056, 30.064857242000073],
            [67.789035200000058, 30.056289600000071],
            [67.789035332000083, 30.056289337000067],
            [67.793294037000067, 30.047783896000055],
            [67.793361539000045, 30.047636923000027],
            [67.793522000000053, 30.047316000000023],
            [67.793513045000054, 30.047307044000036],
            [67.793514928000036, 30.047302943000034],
            [67.793530980000071, 30.047267994000038],
            [67.778230980000046, 30.03196799400007],
            [67.76566525000004, 30.025729688000069],
            [67.76482967100003, 30.025314862000073],
            [67.764132949000043, 30.024966476000031],
            [67.764129317000084, 30.024964660000023],
            [67.76407800000004, 30.024939000000074],
            [67.775587444000053, 30.016085350000026],
            [67.779389000000037, 30.013161000000025],
            [67.806477000000029, 30.013161000000025],
            [67.808227849000048, 30.013342159000047],
            [67.808967786000039, 30.01341872100005],
            [67.840632000000028, 30.016695000000027],
            [67.864187000000072, 30.016695000000027],
            [67.888499245000048, 30.026980583000068],
            [67.889931923000063, 30.027586695000025],
            [67.894809000000066, 30.029650000000061],
            [67.920720000000074, 30.029650000000061],
            [67.928446865000069, 30.028642210000044],
            [67.928776137000057, 30.028599264000036],
            [67.947808000000066, 30.026117000000056],
            [67.977252000000078, 30.011984000000041],
            [67.999629000000084, 29.984895000000051],
            [68.002211757000055, 29.979964516000052],
            [68.00347529700008, 29.977552420000052],
            [68.012585000000058, 29.960162000000025],
            [68.012585000000058, 29.941318000000024],
            [68.012585000000058, 29.91540800000007],
            [68.00687238900008, 29.89582299500006],
            [68.006211976000031, 29.893558847000065],
            [68.005944204000059, 29.892640822000033],
            [68.00434000000007, 29.887141000000042],
            [68.001000891000047, 29.883467895000024],
            [68.000401023000052, 29.882808025000031],
            [67.992563000000075, 29.874186000000066],
            [67.98667400000005, 29.854164000000026],
            [67.973719000000074, 29.837676000000044],
            [67.964297000000045, 29.820009000000027],
            [67.952697020000073, 29.814934162000043],
            [67.949631502000045, 29.813593038000079],
            [67.948915042000067, 29.813279597000076],
            [67.945453000000043, 29.811765000000037],
            [67.942223052000031, 29.803152415000056],
            [67.94139344000007, 29.800924317000067],
            [67.938430980000078, 29.792967993000048],
            [67.927830980000067, 29.775267994000046],
            [67.911330980000059, 29.758767993000049],
            [67.907939633000069, 29.750596072000064],
            [67.907164159000047, 29.748727460000055],
            [67.903053000000057, 29.738744000000054],
            [67.895508266000036, 29.728684356000031],
            [67.894377084000041, 29.727176114000031],
            [67.888920000000041, 29.719900000000052],
            [67.884058913000047, 29.703372799000078],
            [67.883031000000074, 29.699878000000069],
            [67.883033373000046, 29.699876103000065],
            [67.88601737700003, 29.697489000000076],
            [67.887437994000038, 29.696352554000043],
            [67.88865701900005, 29.695377375000078],
            [67.893815925000069, 29.691250425000078],
            [67.894809000000066, 29.69045600000004],
            [67.89482402200008, 29.690468769000063],
            [67.894826918000035, 29.690471230000071],
            [67.900727825000047, 29.695487064000076],
            [67.907903721000082, 29.70158665200006],
            [67.918364000000054, 29.710478000000023],
            [67.946630000000027, 29.725789000000077],
            [67.957230000000038, 29.735211000000049],
            [67.965474000000029, 29.74345500000004],
            [67.965524670000036, 29.74346766900004],
            [67.965570668000055, 29.743479169000068],
            [67.974897000000055, 29.74581100000006],
            [67.979608000000042, 29.758766000000037],
            [67.97607400000004, 29.769366000000048],
            [67.973719000000074, 29.794099000000074],
            [67.98667400000005, 29.79881000000006],
            [68.000807000000066, 29.80469800000003],
            [68.017296000000044, 29.805876000000069],
            [68.026718000000074, 29.820009000000027],
            [68.043206000000055, 29.830609000000038],
            [68.056162000000029, 29.842387000000031],
            [68.057092272000034, 29.852617623000071],
            [68.057178454000052, 29.853565401000026],
            [68.057340000000067, 29.855342000000064],
            [68.062051000000054, 29.870653000000061],
            [68.063228000000038, 29.888319000000024],
            [68.079065901000035, 29.896237469000027],
            [68.079717001000063, 29.896563000000071],
            [68.079722516000061, 29.896563787000048],
            [68.08225843200006, 29.896926134000068],
            [68.082973682000045, 29.897028334000026],
            [68.104450000000043, 29.900097000000073],
            [68.11976100000004, 29.900097000000073],
            [68.150382000000036, 29.901274000000058],
            [68.159386798000071, 29.903729897000062],
            [68.188648337000075, 29.911710453000069],
            [68.189248000000077, 29.911874000000068],
            [68.190341330000081, 29.912199061000024],
            [68.209497075000058, 29.917894309000076],
            [68.232825000000048, 29.924830000000043],
            [68.251669000000049, 29.93071800000007],
            [68.256619581000052, 29.930901389000041],
            [68.271177131000059, 29.931440659000032],
            [68.271261535000065, 29.931443786000045],
            [68.283469000000082, 29.931896000000052],
            [68.318802000000062, 29.931896000000052],
            [68.332935000000077, 29.931896000000052],
            [68.351779000000079, 29.931896000000052],
            [68.366508535000037, 29.933595469000068],
            [68.366818896000041, 29.933631278000064],
            [68.382400000000075, 29.935429000000056],
            [68.398889000000054, 29.935429000000056],
            [68.400129000000049, 29.935430007000036],
            [68.400129000000049, 29.933509000000072],
            [68.402231000000029, 29.912489000000051],
            [68.403865574000065, 29.910343640000065],
            [68.413441000000034, 29.897776000000079],
            [68.432359000000076, 29.887966000000063],
            [68.456181000000072, 29.873953000000029],
            [68.472997000000078, 29.866947000000039],
            [68.492615000000058, 29.855737000000033],
            [68.508029000000079, 29.849431000000038],
            [68.524845000000028, 29.841724000000056],
            [68.538157000000069, 29.838921000000028],
            [68.55567300000007, 29.831915000000038],
            [68.574591000000055, 29.825609000000043],
            [68.578795000000071, 29.81439800000004],
            [68.576693000000034, 29.80529000000007],
            [68.567584000000068, 29.783570000000054],
            [68.564782000000037, 29.769557000000077],
            [68.566884000000073, 29.758346000000074],
            [68.571088000000032, 29.748537000000056],
            [68.575292000000047, 29.740129000000024],
            [68.569686000000047, 29.72681700000004],
            [68.566884000000073, 29.71770900000007],
            [68.560578000000078, 29.708600000000047],
            [68.554272000000083, 29.701594000000057],
            [68.543762000000072, 29.69458700000007],
            [68.529749000000038, 29.686179000000038],
            [68.507329000000084, 29.674969000000033],
            [68.49841957600006, 29.672511334000035],
            [68.487010000000055, 29.66936400000003],
            [68.46809200000007, 29.662357000000043],
            [68.457582000000059, 29.654650000000061],
            [68.450576000000069, 29.644841000000042],
            [68.44286900000003, 29.632229000000052],
            [68.436563000000035, 29.62452200000007],
            [68.41274100000004, 29.620318000000054],
            [68.388919000000044, 29.621720000000039],
            [68.353886000000045, 29.62452200000007],
            [68.344434109000076, 29.625362295000059],
            [68.322357000000068, 29.627325000000042],
            [68.296433000000036, 29.625923000000057],
            [68.27961700000003, 29.629427000000078],
            [68.262802000000079, 29.628025000000036],
            [68.248088000000052, 29.609108000000049],
            [68.24248300000005, 29.59929900000003],
            [68.230572000000052, 29.591592000000048],
            [68.215858000000082, 29.588789000000077],
            [68.197666439000045, 29.588088979000077],
            [68.197641000000033, 29.588088000000027],
            [68.178724000000045, 29.55445700000007],
            [68.164010000000076, 29.524329000000023],
            [68.162120232000063, 29.520793341000058],
            [68.143009023000047, 29.485037256000055],
            [68.14229000000006, 29.483692000000076],
            [68.142289000000062, 29.483690000000024],
            [68.129356976000054, 29.457825953000054],
            [68.127576000000033, 29.454264000000023],
            [68.112162000000069, 29.437449000000072],
            [68.105856000000074, 29.431843000000072],
            [68.102353000000051, 29.422034000000053],
            [68.107258000000058, 29.415729000000056],
            [68.119169000000056, 29.414327000000071],
            [68.124073000000067, 29.41853100000003],
            [68.128978000000075, 29.41853100000003],
            [68.134583000000077, 29.41152500000004],
            [68.138787000000036, 29.408021000000076],
            [68.142991000000052, 29.412225000000035],
            [68.15280000000007, 29.408722000000068],
            [68.15280000000007, 29.401015000000029],
            [68.162609000000032, 29.392607000000055],
            [68.167787112000042, 29.38668930800003],
            [68.17241800000005, 29.38139700000005],
            [68.179665000000057, 29.37173400000006],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "38",
      properties: { name: "Washuk", count: 567 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [64.745951906000073, 28.674947649000046],
            [64.761577898000041, 28.616506682000079],
            [64.754030449000084, 28.525514937000025],
            [64.73920548600006, 28.396724730000074],
            [64.78073627200007, 28.331876630000068],
            [64.810678320000079, 28.304065851000075],
            [64.840681331000042, 28.253120146000072],
            [64.891329462000044, 28.213711248000038],
            [64.981107940000072, 28.146481577000031],
            [65.048044495000056, 28.02145594600006],
            [65.061853245000066, 28.012181002000034],
            [65.137633660000063, 28.032878637000067],
            [65.201919277000059, 28.053599464000058],
            [65.229813155000045, 28.060284676000038],
            [65.277440411000043, 28.056376583000031],
            [65.277817105000054, 28.056345673000067],
            [65.278392506000046, 28.056784797000034],
            [65.294582000000048, 28.069140000000061],
            [65.337168000000077, 28.094692000000066],
            [65.386852000000033, 28.126631000000032],
            [65.442214000000035, 28.168508000000031],
            [65.45853900000003, 28.184833000000026],
            [65.487640000000056, 28.199738000000025],
            [65.51816000000008, 28.212514000000056],
            [65.560746000000051, 28.234517000000039],
            [65.60759100000007, 28.259359000000075],
            [65.64095100000003, 28.280652000000032],
            [65.681408000000033, 28.298396000000025],
            [65.704830000000072, 28.297687000000053],
            [65.736060000000066, 28.31046200000003],
            [65.776517000000069, 28.326077000000055],
            [65.817684000000042, 28.336724000000061],
            [65.857431000000076, 28.348080000000039],
            [65.882273000000055, 28.356598000000076],
            [65.883417966000081, 28.357925960000046],
            [65.883535000000052, 28.35728800000004],
            [65.867826000000036, 28.310160000000053],
            [65.837978000000078, 28.25517700000006],
            [65.826981000000046, 28.22847100000007],
            [65.834836000000053, 28.209620000000029],
            [65.856829000000062, 28.208049000000074],
            [65.877252000000055, 28.208049000000074],
            [65.894532000000083, 28.195481000000029],
            [65.896103000000039, 28.170346000000052],
            [65.886677000000077, 28.13264300000003],
            [65.881964000000039, 28.098083000000031],
            [65.883535000000052, 28.076089000000024],
            [65.891390000000058, 28.049383000000034],
            [65.913383000000067, 28.043100000000038],
            [65.930664000000036, 28.05252500000006],
            [65.954228000000057, 28.05252500000006],
            [65.969937000000073, 28.033674000000076],
            [65.977792000000079, 28.011681000000067],
            [65.976221000000066, 27.991258000000073],
            [65.973079000000041, 27.964552000000026],
            [65.955599000000063, 27.935420000000079],
            [65.955588668000075, 27.935377092000067],
            [65.943255370000031, 27.929248508000057],
            [65.936523437000062, 27.925903320000032],
            [65.930725098000039, 27.921508789000029],
            [65.927805729000056, 27.919179191000069],
            [65.92468261700003, 27.916687012000068],
            [65.911926270000038, 27.900329590000069],
            [65.901916504000042, 27.881103516000053],
            [65.888122559000067, 27.861877441000047],
            [65.878169888000059, 27.854716374000077],
            [65.868103027000075, 27.847473145000038],
            [65.850864604000037, 27.836776482000062],
            [65.844299316000047, 27.832702637000068],
            [65.819702148000033, 27.819885254000042],
            [65.808860267000057, 27.811762447000035],
            [65.800476074000073, 27.805480957000043],
            [65.796875000000057, 27.802673340000069],
            [65.796857516000046, 27.802640272000076],
            [65.782287598000039, 27.775085449000073],
            [65.771301270000038, 27.753295898000033],
            [65.770324707000043, 27.734130859000061],
            [65.770324707000043, 27.717712402000075],
            [65.766723633000083, 27.710327148000033],
            [65.756265215000042, 27.699221305000037],
            [65.753906250000057, 27.696716309000067],
            [65.73468017600004, 27.687500000000057],
            [65.734678717000065, 27.687498437000045],
            [65.720092773000033, 27.671875000000057],
            [65.715515137000068, 27.664306641000053],
            [65.711914062000062, 27.658325195000032],
            [65.708312988000046, 27.640075684000067],
            [65.703674316000047, 27.620910645000038],
            [65.701336568000045, 27.614460190000045],
            [65.697082520000038, 27.602722168000071],
            [65.695418283000038, 27.597887958000058],
            [65.694519043000071, 27.595275879000042],
            [65.692111194000063, 27.589573077000068],
            [65.689880371000072, 27.58428955100004],
            [65.690085006000061, 27.580341309000062],
            [65.690917969000054, 27.564270020000038],
            [65.701904297000056, 27.54772949200003],
            [65.703674316000047, 27.528686523000033],
            [65.703601882000044, 27.528609260000053],
            [65.703577073000076, 27.52858279700007],
            [65.697547874000065, 27.522151652000048],
            [65.688110352000081, 27.512084961000028],
            [65.680380601000081, 27.504060744000071],
            [65.668884277000075, 27.492126465000069],
            [65.63852823600007, 27.466868385000055],
            [65.636901855000076, 27.465515137000068],
            [65.62898772300008, 27.458149328000047],
            [65.610473633000083, 27.440917969000054],
            [65.61046544800007, 27.440903969000033],
            [65.61044400000003, 27.44088400000004],
            [65.598570000000052, 27.421704000000034],
            [65.598599810000053, 27.421570036000048],
            [65.59888998100007, 27.420266084000048],
            [65.598889991000078, 27.420266040000058],
            [65.597055000000069, 27.421570000000031],
            [65.58460800000006, 27.410161000000073],
            [65.581843000000049, 27.391837000000066],
            [65.58460800000006, 27.372822000000042],
            [65.581843000000049, 27.356919000000062],
            [65.58149700000007, 27.339978000000031],
            [65.575619000000074, 27.325457000000029],
            [65.56870500000008, 27.312665000000038],
            [65.56628500000005, 27.299873000000048],
            [65.557296000000065, 27.288119000000052],
            [65.547270000000083, 27.272215000000074],
            [65.543798614000082, 27.266815292000047],
            [65.52860000000004, 27.243174000000067],
            [65.509931000000051, 27.220356000000038],
            [65.495410000000049, 27.195809000000054],
            [65.479507000000069, 27.170225000000073],
            [65.477260081000054, 27.166671824000048],
            [65.464640000000031, 27.146715000000029],
            [65.45426800000007, 27.135306000000071],
            [65.432142000000056, 27.099696000000051],
            [65.425476531000072, 27.091038801000025],
            [65.402063000000055, 27.060629000000063],
            [65.383740000000046, 27.034699000000046],
            [65.364379000000042, 27.008769000000029],
            [65.362659927000038, 27.005832405000035],
            [65.356081000000074, 26.994594000000063],
            [65.351241000000073, 26.983531000000028],
            [65.344326500000079, 26.971430000000055],
            [65.341906000000051, 26.958293000000026],
            [65.341952822000053, 26.956374768000046],
            [65.34225200000003, 26.94411800000006],
            [65.341906000000051, 26.928214000000025],
            [65.338795000000061, 26.915077000000053],
            [65.335808557000064, 26.895826443000033],
            [65.335808000000043, 26.895827000000054],
            [65.335683000000074, 26.895024000000035],
            [65.328423000000043, 26.893641000000059],
            [65.309754000000055, 26.893641000000059],
            [65.295579000000032, 26.888801000000058],
            [65.280021000000033, 26.89122100000003],
            [65.268612000000076, 26.891567000000066],
            [65.258931000000075, 26.899864000000036],
            [65.249251000000072, 26.907125000000065],
            [65.245448000000067, 26.916459000000032],
            [65.23473000000007, 26.928560000000061],
            [65.223667000000034, 26.937549000000047],
            [65.220901000000083, 26.949995000000058],
            [65.218135000000075, 26.965899000000036],
            [65.216407000000061, 26.981111000000055],
            [65.210183000000029, 26.993557000000067],
            [65.200503000000083, 27.006695000000036],
            [65.183562000000052, 27.017758000000072],
            [65.166276000000039, 27.025710000000061],
            [65.157633000000033, 27.027093000000036],
            [65.151064000000076, 27.026747000000057],
            [65.143112000000031, 27.025710000000061],
            [65.13032000000004, 27.026401000000078],
            [65.119257000000061, 27.023290000000031],
            [65.10439000000008, 27.023636000000067],
            [65.094364000000041, 27.023636000000067],
            [65.087795000000028, 27.029513000000065],
            [65.085721000000035, 27.038156000000072],
            [65.086412000000053, 27.048874000000069],
            [65.088487000000043, 27.054751000000067],
            [65.085029000000077, 27.062357000000077],
            [65.07638600000007, 27.065815000000043],
            [65.075349000000074, 27.073075000000074],
            [65.075003000000038, 27.081027000000063],
            [65.073966000000041, 27.08725000000004],
            [65.066014000000052, 27.093819000000053],
            [65.056680000000028, 27.106956000000025],
            [65.054951000000074, 27.122514000000024],
            [65.054605000000038, 27.137726000000043],
            [65.05183900000003, 27.150518000000034],
            [65.040430000000072, 27.161927000000048],
            [65.02798400000006, 27.175411000000054],
            [65.014501000000052, 27.183017000000063],
            [65.008969000000036, 27.178868000000023],
            [64.999289000000033, 27.170916000000034],
            [64.987880000000075, 27.167805000000044],
            [64.962987000000055, 27.159507000000076],
            [64.916314000000057, 27.130120000000034],
            [64.903867000000048, 27.120786000000066],
            [64.880012000000079, 27.109722000000033],
            [64.872752000000048, 27.101425000000063],
            [64.857885000000067, 27.092781000000059],
            [64.843019000000083, 27.083447000000035],
            [64.807409000000064, 27.061666000000059],
            [64.774564000000055, 27.040576000000044],
            [64.749672000000032, 27.028822000000048],
            [64.734114000000034, 27.018795000000068],
            [64.724088000000052, 27.012227000000053],
            [64.709913000000029, 27.006695000000036],
            [64.695392000000084, 27.004966000000024],
            [64.683983000000069, 27.004966000000024],
            [64.672229000000073, 27.00531200000006],
            [64.669117000000028, 27.011535000000038],
            [64.664968000000044, 27.016721000000075],
            [64.654942000000062, 27.020524000000023],
            [64.648719000000028, 27.020524000000023],
            [64.64284200000003, 27.023290000000031],
            [64.635927000000038, 27.024327000000028],
            [64.631087000000036, 27.029167000000029],
            [64.621752000000072, 27.038502000000051],
            [64.606886000000031, 27.048874000000069],
            [64.59962500000006, 27.058209000000033],
            [64.58372200000008, 27.066506000000061],
            [64.578082054000049, 27.069522874000029],
            [64.568856000000039, 27.07445800000005],
            [64.553989000000058, 27.081718000000023],
            [64.533937000000037, 27.090707000000066],
            [64.526331000000027, 27.095202000000029],
            [64.518725000000074, 27.10004200000003],
            [64.509390000000053, 27.105228000000068],
            [64.497290000000078, 27.111797000000024],
            [64.491758000000061, 27.113871000000074],
            [64.490721000000065, 27.120440000000031],
            [64.49521500000003, 27.128737000000058],
            [64.500055000000032, 27.137726000000043],
            [64.501093000000083, 27.146370000000047],
            [64.500055000000032, 27.157433000000026],
            [64.500055000000032, 27.168150000000026],
            [64.49210400000004, 27.172991000000025],
            [64.49002900000005, 27.179560000000038],
            [64.488992000000053, 27.186820000000068],
            [64.489338000000032, 27.194080000000042],
            [64.484843000000069, 27.205489000000057],
            [64.469631000000049, 27.216898000000072],
            [64.463408000000072, 27.222776000000067],
            [64.456148000000042, 27.226579000000072],
            [64.455802000000062, 27.235222000000078],
            [64.45649300000008, 27.244557000000043],
            [64.453728000000069, 27.251817000000074],
            [64.44577600000008, 27.250088000000062],
            [64.440936000000079, 27.263226000000031],
            [64.435404000000062, 27.274981000000025],
            [64.429872000000046, 27.283970000000068],
            [64.425724000000059, 27.292959000000053],
            [64.413969000000066, 27.299873000000048],
            [64.410567000000071, 27.299473071000023],
            [64.40809100000007, 27.29918200000003],
            [64.401868000000036, 27.294687000000067],
            [64.389422000000081, 27.28639000000004],
            [64.38458200000008, 27.27878400000003],
            [64.379050000000063, 27.272906000000035],
            [64.377321000000052, 27.263572000000067],
            [64.370407000000057, 27.254583000000025],
            [64.358652000000063, 27.25285400000007],
            [64.34447700000004, 27.246631000000036],
            [64.32857400000006, 27.239025000000026],
            [64.319930000000056, 27.22485000000006],
            [64.301499000000035, 27.208694000000037],
            [64.294623229000081, 27.201130955000053],
            [64.271956000000046, 27.176198000000056],
            [64.255216000000075, 27.164381000000049],
            [64.246353000000056, 27.165366000000063],
            [64.24733800000007, 27.175213000000042],
            [64.237491000000034, 27.173244000000068],
            [64.230985875000044, 27.166088892000062],
            [64.227643000000057, 27.162412000000074],
            [64.220750000000066, 27.147641000000078],
            [64.20597900000007, 27.134839000000056],
            [64.195147000000077, 27.12991500000004],
            [64.176437000000078, 27.127946000000065],
            [64.149849000000074, 27.125976000000037],
            [64.13310800000005, 27.122037000000034],
            [64.114398000000051, 27.113175000000069],
            [64.110459000000048, 27.100373000000047],
            [64.111444000000063, 27.095449000000031],
            [64.122276000000056, 27.07870900000006],
            [64.125230000000045, 27.060983000000078],
            [64.121291000000042, 27.038334000000077],
            [64.114398000000051, 27.023563000000024],
            [64.10750500000006, 27.008792000000028],
            [64.092734000000064, 26.99599100000006],
            [64.089313609000044, 26.993777686000044],
            [64.07599300000004, 26.985158000000069],
            [64.075008000000082, 26.993036000000075],
            [64.07775193100008, 27.005384037000056],
            [64.078947000000028, 27.010762000000057],
            [64.094703000000038, 27.023563000000024],
            [64.103566000000058, 27.03735000000006],
            [64.108490000000074, 27.059999000000062],
            [64.100612000000069, 27.079694000000075],
            [64.087810000000047, 27.07870900000006],
            [64.027741000000049, 27.082648000000063],
            [63.997214000000042, 27.085602000000051],
            [63.979489000000058, 27.098404000000073],
            [63.961763000000076, 27.108251000000052],
            [63.945023000000049, 27.12006800000006],
            [63.915842147000035, 27.138498258000027],
            [63.907603000000051, 27.143702000000076],
            [63.894801000000029, 27.162412000000074],
            [63.895786000000044, 27.188015000000064],
            [63.870183000000054, 27.214603000000068],
            [63.836701000000062, 27.239221000000043],
            [63.82291500000008, 27.25990100000007],
            [63.795342000000062, 27.25990100000007],
            [63.771709000000044, 27.251038000000051],
            [63.753983000000062, 27.239221000000043],
            [63.733304000000032, 27.236267000000055],
            [63.691945000000032, 27.233313000000066],
            [63.662402000000043, 27.231343000000038],
            [63.633845000000065, 27.231343000000038],
            [63.602333000000044, 27.241191000000072],
            [63.587562000000048, 27.220511000000045],
            [63.572791000000052, 27.216572000000042],
            [63.548173000000077, 27.209679000000051],
            [63.50189000000006, 27.208694000000037],
            [63.46053100000006, 27.207710000000077],
            [63.426065000000051, 27.194908000000055],
            [63.404400000000066, 27.194908000000055],
            [63.384706000000051, 27.20278600000006],
            [63.366980000000069, 27.203771000000074],
            [63.336891000000037, 27.199887000000047],
            [63.294948000000034, 27.211630000000071],
            [63.250858000000051, 27.206945000000076],
            [63.211844000000042, 27.226905000000045],
            [63.181449000000043, 27.242783000000031],
            [63.15744200000006, 27.239513000000045],
            [63.127978000000041, 27.235943000000077],
            [63.089812000000052, 27.232803000000047],
            [63.060324000000037, 27.233257000000037],
            [63.045837000000063, 27.227018000000044],
            [63.022623000000067, 27.217199000000051],
            [62.997623000000033, 27.202915000000075],
            [62.971409000000051, 27.192428000000064],
            [62.947819000000038, 27.190160000000049],
            [62.928312000000062, 27.196057000000053],
            [62.912888000000066, 27.198779000000059],
            [62.894288000000074, 27.20830600000005],
            [62.879771000000062, 27.215564000000029],
            [62.853459000000043, 27.210574000000065],
            [62.829772000000048, 27.212743000000046],
            [62.805826000000081, 27.218740000000025],
            [62.787226000000032, 27.230081000000041],
            [62.78314400000005, 27.246412000000078],
            [62.792275000000075, 27.259169000000043],
            [62.808347000000083, 27.272561000000053],
            [62.827148000000079, 27.288602000000026],
            [62.833952000000068, 27.30311800000004],
            [62.827148000000079, 27.320811000000049],
            [62.809909000000061, 27.337142000000028],
            [62.823972000000083, 27.378878000000043],
            [62.84846900000008, 27.440120000000036],
            [62.85663500000004, 27.465524000000073],
            [62.851645000000076, 27.548088000000064],
            [62.84030400000006, 27.626570000000072],
            [62.835767000000033, 27.694617000000051],
            [62.830777000000069, 27.725465000000042],
            [62.829416000000037, 27.745879000000059],
            [62.823972000000083, 27.768561000000034],
            [62.820343000000037, 27.802131000000031],
            [62.81308400000006, 27.834794000000045],
            [62.807187000000056, 27.867003000000068],
            [62.80340300000006, 27.888377000000048],
            [62.821026000000074, 27.895246000000043],
            [62.845158000000083, 27.914410000000032],
            [62.863612000000046, 27.925766000000067],
            [62.881357000000037, 27.942800000000034],
            [62.897681000000034, 27.96267400000005],
            [62.921104000000071, 27.981838000000039],
            [62.935299000000043, 28.003131000000053],
            [62.94949500000007, 28.015197000000057],
            [62.976466000000073, 28.035071000000073],
            [63.009116000000063, 28.062752000000046],
            [63.046733000000074, 28.095401000000038],
            [63.056908376000081, 28.103064035000045],
            [63.104225000000042, 28.138698000000034],
            [63.129067000000077, 28.157861000000025],
            [63.151070000000061, 28.169928000000027],
            [63.174492000000043, 28.177735000000041],
            [63.195076000000029, 28.177025000000071],
            [63.206432000000063, 28.184833000000026],
            [63.214240000000075, 28.19689900000003],
            [63.217789000000039, 28.206836000000067],
            [63.221337000000062, 28.230258000000049],
            [63.230564000000072, 28.252261000000033],
            [63.253277000000082, 28.272844000000077],
            [63.267473000000052, 28.274974000000043],
            [63.30154200000004, 28.269296000000054],
            [63.344128000000069, 28.265747000000033],
            [63.373938000000066, 28.265037000000063],
            [63.402329000000066, 28.269296000000054],
            [63.435689000000082, 28.279942000000062],
            [63.467628000000047, 28.285620000000051],
            [63.496019000000047, 28.291299000000038],
            [63.520151000000055, 28.290589000000068],
            [63.55493000000007, 28.294138000000032],
            [63.602485000000058, 28.294138000000032],
            [63.645781000000056, 28.292718000000036],
            [63.675592000000051, 28.289169000000072],
            [63.708241000000044, 28.291299000000038],
            [63.733083000000079, 28.295557000000031],
            [63.760054000000082, 28.303365000000042],
            [63.782057000000066, 28.311882000000026],
            [63.791284000000076, 28.316850000000045],
            [63.79909200000003, 28.326787000000024],
            [63.804115051000053, 28.335159013000066],
            [63.805480000000045, 28.33743400000003],
            [63.818256000000076, 28.345241000000044],
            [63.822514000000069, 28.336014000000034],
            [63.822514000000069, 28.313302000000078],
            [63.82677300000006, 28.299106000000052],
            [63.827646000000072, 28.300212000000045],
            [63.837420000000066, 28.312592000000052],
            [63.856583000000057, 28.333885000000066],
            [63.879296000000068, 28.352339000000029],
            [63.901299000000051, 28.368664000000024],
            [63.926851000000056, 28.377891000000034],
            [63.94885400000004, 28.376471000000038],
            [63.968018000000029, 28.380730000000028],
            [63.979374000000064, 28.38782800000007],
            [63.991440000000068, 28.390667000000064],
            [63.996409000000028, 28.384988000000078],
            [64.012733000000082, 28.383569000000023],
            [64.019637731000046, 28.392199427000037],
            [64.026929000000052, 28.401313000000073],
            [64.041124000000082, 28.413379000000077],
            [64.051061000000061, 28.423316000000057],
            [64.07519300000007, 28.428285000000074],
            [64.091518000000065, 28.441770000000076],
            [64.107133000000033, 28.452417000000025],
            [64.119909000000064, 28.46448300000003],
            [64.13055600000007, 28.469451000000049],
            [64.147590000000037, 28.469451000000049],
            [64.160366000000067, 28.468742000000077],
            [64.171013000000073, 28.471581000000072],
            [64.18804700000004, 28.468742000000077],
            [64.205082000000061, 28.470161000000076],
            [64.222826000000055, 28.483647000000076],
            [64.237731000000053, 28.488615000000038],
            [64.249087000000031, 28.492874000000029],
            [64.263993000000028, 28.492874000000029],
            [64.279608000000053, 28.497132000000079],
            [64.280622308000034, 28.500851687000079],
            [64.281601325000054, 28.50111865100007],
            [64.285859326000036, 28.516733652000028],
            [64.284440326000038, 28.527380651000044],
            [64.285497863000046, 28.539547174000063],
            [64.297352000000046, 28.538299000000052],
            [64.315806000000066, 28.531911000000036],
            [64.332131000000061, 28.524104000000079],
            [64.364780000000053, 28.519845000000032],
            [64.396010000000047, 28.521265000000028],
            [64.412825334000047, 28.525748984000074],
            [64.417303000000061, 28.526943000000074],
            [64.438597000000073, 28.546817000000033],
            [64.440320880000058, 28.550552173000028],
            [64.440590326000063, 28.550803651000024],
            [64.447453378000034, 28.565674001000048],
            [64.469827000000066, 28.592242000000056],
            [64.493959000000075, 28.610696000000075],
            [64.520220000000052, 28.612825000000043],
            [64.547902000000079, 28.622052000000053],
            [64.569195000000036, 28.630570000000034],
            [64.611071000000038, 28.64618500000006],
            [64.638752000000068, 28.645475000000033],
            [64.66643300000004, 28.651153000000079],
            [64.696954000000062, 28.661090000000058],
            [64.745951906000073, 28.674947649000046],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "39",
      properties: { name: "Zhob", count: 730 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.523025000000075, 31.972232000000076],
            [69.535661000000061, 31.957791000000043],
            [69.547695000000033, 31.952376000000072],
            [69.553283099000055, 31.954471714000078],
            [69.55328318800008, 31.954471425000065],
            [69.553568000000041, 31.953583000000037],
            [69.566872000000046, 31.929470000000038],
            [69.580175000000054, 31.914504000000079],
            [69.587658000000033, 31.898706000000061],
            [69.605119000000059, 31.882077000000038],
            [69.611771000000033, 31.864616000000069],
            [69.615928000000054, 31.843830000000025],
            [69.621748000000082, 31.821380000000033],
            [69.622536721000074, 31.798522289000061],
            [69.622580000000028, 31.797268000000031],
            [69.614265000000046, 31.78147000000007],
            [69.605951000000061, 31.761515000000031],
            [69.60434882900006, 31.757242544000064],
            [69.600962000000038, 31.748211000000026],
            [69.595142000000067, 31.730750000000057],
            [69.590825994000056, 31.72211863800004],
            [69.590303994000067, 31.721074715000043],
            [69.588490000000036, 31.71744700000005],
            [69.581007000000056, 31.704143000000045],
            [69.583501000000069, 31.684188000000063],
            [69.581007000000056, 31.66755900000004],
            [69.581007000000056, 31.65758100000005],
            [69.575186000000031, 31.64344600000004],
            [69.571861000000069, 31.630143000000032],
            [69.570198000000062, 31.613514000000066],
            [69.569366000000059, 31.602705000000071],
            [69.563546000000031, 31.592727000000025],
            [69.563100047000034, 31.582922480000036],
            [69.563098215000082, 31.58288218000007],
            [69.562714000000028, 31.574435000000051],
            [69.556894000000057, 31.568615000000079],
            [69.553568000000041, 31.554480000000069],
            [69.542759000000046, 31.542008000000067],
            [69.53610800000007, 31.530367000000069],
            [69.53610800000007, 31.519558000000075],
            [69.53610800000007, 31.512274533000038],
            [69.53610800000007, 31.508512407000069],
            [69.53610800000007, 31.498772000000031],
            [69.535276000000067, 31.479648000000054],
            [69.540265000000034, 31.460524000000078],
            [69.541928000000041, 31.438906000000031],
            [69.551905000000033, 31.421445000000062],
            [69.556836798000063, 31.399869494000029],
            [69.558557000000064, 31.392344000000037],
            [69.563546000000031, 31.37322000000006],
            [69.565209000000038, 31.352434000000073],
            [69.570470347000082, 31.349002735000056],
            [69.570830955000076, 31.348767559000066],
            [69.584333000000072, 31.339962000000071],
            [69.595437261000029, 31.337185577000071],
            [69.607614000000069, 31.334141000000045],
            [69.625766637000083, 31.33476736800003],
            [69.631726000000072, 31.334973000000048],
            [69.656670000000076, 31.334973000000048],
            [69.67496200000005, 31.325827000000061],
            [69.693254000000081, 31.325827000000061],
            [69.714115546000073, 31.322617531000049],
            [69.714873000000068, 31.322501000000045],
            [69.734951865000028, 31.320313924000061],
            [69.737915039000029, 31.307922363000046],
            [69.737872699000036, 31.307898527000077],
            [69.729675293000071, 31.303283691000047],
            [69.72491455100004, 31.295104980000076],
            [69.722106934000067, 31.28991699200003],
            [69.722106934000067, 31.283691406000059],
            [69.721130371000072, 31.274108887000068],
            [69.721130371000072, 31.265502930000025],
            [69.721008323000035, 31.26533330500007],
            [69.713928223000039, 31.255493164000029],
            [69.713919475000068, 31.255488666000076],
            [69.701110840000069, 31.24890136700003],
            [69.701087050000069, 31.248835171000053],
            [69.701025000000072, 31.248803000000066],
            [69.698159000000032, 31.241162000000031],
            [69.698589981000055, 31.235133688000076],
            [69.706278000000054, 31.227790000000027],
            [69.715829000000042, 31.21967200000006],
            [69.71564423500007, 31.215980185000035],
            [69.715351000000055, 31.210121000000072],
            [69.707233000000031, 31.199615000000051],
            [69.704344799000069, 31.19561580900006],
            [69.701025000000072, 31.19101900000004],
            [69.69977717900008, 31.187275538000051],
            [69.699762536000037, 31.187231607000058],
            [69.698654615000066, 31.183907844000032],
            [69.697692871000072, 31.181091309000067],
            [69.697700137000083, 31.181044410000027],
            [69.697682000000043, 31.180990000000065],
            [69.699592000000052, 31.168096000000048],
            [69.702123699000083, 31.158331201000067],
            [69.702880859000061, 31.155273437000062],
            [69.705744995000032, 31.14958142100005],
            [69.707702637000068, 31.145690918000071],
            [69.707702637000068, 31.145665727000051],
            [69.707702637000068, 31.131286621000072],
            [69.707702637000068, 31.121887207000043],
            [69.706927962000066, 31.118840153000065],
            [69.705871582000043, 31.114685059000067],
            [69.705801265000048, 31.114614742000072],
            [69.699096680000082, 31.107910156000059],
            [69.69940908600006, 31.10703920800006],
            [69.701110840000069, 31.102294922000056],
            [69.710693359000061, 31.100891113000046],
            [69.718688965000069, 31.100891113000046],
            [69.72491455100004, 31.097900391000053],
            [69.721130371000072, 31.088317871000072],
            [69.715632762000041, 31.083155482000052],
            [69.715164491000053, 31.082715765000046],
            [69.711120605000076, 31.078918457000043],
            [69.705871582000043, 31.071289063000052],
            [69.705863499000031, 31.071245365000038],
            [69.703918457000043, 31.060729980000076],
            [69.704924880000078, 31.058473475000028],
            [69.709716797000056, 31.04772949200003],
            [69.727294922000056, 31.039672852000024],
            [69.732066036000049, 31.032546376000028],
            [69.732116699000073, 31.032470703000058],
            [69.732066036000049, 31.032273119000024],
            [69.730285645000038, 31.025329590000069],
            [69.728235939000058, 31.019522090000066],
            [69.728152288000047, 31.019522126000027],
            [69.725857000000076, 31.012891000000025],
            [69.72538000000003, 30.999520000000075],
            [69.720604000000037, 30.985193000000038],
            [69.715866371000061, 30.974294963000034],
            [69.708017117000054, 30.969454590000055],
            [69.704895020000038, 30.967529297000056],
            [69.692504883000083, 30.951293945000032],
            [69.68389892600004, 30.938903809000067],
            [69.678710938000052, 30.929321289000029],
            [69.676330566000047, 30.91687011700003],
            [69.671081543000071, 30.905517578000058],
            [69.66409537100003, 30.89902649000004],
            [69.663330078000058, 30.898315430000025],
            [69.663327741000046, 30.898306880000064],
            [69.662909090000028, 30.89677535900006],
            [69.661349106000046, 30.891068584000038],
            [69.65850830100004, 30.880676270000038],
            [69.643676758000083, 30.856689453000058],
            [69.639387787000032, 30.850161108000066],
            [69.629869000000042, 30.83571900000004],
            [69.622228000000064, 30.824258000000043],
            [69.605036000000041, 30.812319000000059],
            [69.59739600000006, 30.803245000000061],
            [69.583547000000067, 30.795127000000036],
            [69.580313883000031, 30.789656373000071],
            [69.577270508000083, 30.784729004000042],
            [69.577028436000035, 30.778112371000077],
            [69.576904297000056, 30.774719238000046],
            [69.576866567000081, 30.774709040000062],
            [69.567871094000054, 30.772277832000043],
            [69.559692383000083, 30.767089844000054],
            [69.550109863000046, 30.758911133000026],
            [69.539123535000044, 30.751708984000061],
            [69.538512381000032, 30.750630164000029],
            [69.537322952000068, 30.748530563000031],
            [69.53210449200003, 30.739318848000039],
            [69.53210449200003, 30.730285645000038],
            [69.534484863000046, 30.718322754000042],
            [69.534484863000046, 30.695312500000057],
            [69.53210449200003, 30.68389892600004],
            [69.53210449200003, 30.671081543000071],
            [69.53210449200003, 30.668090820000032],
            [69.53210449200003, 30.659190419000026],
            [69.53210449200003, 30.651916504000042],
            [69.527669270000047, 30.645748314000059],
            [69.522888184000067, 30.639099121000072],
            [69.521043564000081, 30.637123677000034],
            [69.514282227000081, 30.629882813000052],
            [69.510409712000069, 30.627238927000064],
            [69.500099963000082, 30.620200143000034],
            [69.499084473000039, 30.619506836000028],
            [69.498189006000075, 30.618884562000062],
            [69.485783255000058, 30.610263617000044],
            [69.48468017600004, 30.609497070000032],
            [69.483517272000029, 30.60815662400006],
            [69.480314393000072, 30.604464756000027],
            [69.476684570000032, 30.600280762000068],
            [69.473346168000035, 30.597056169000041],
            [69.471313477000081, 30.595092773000033],
            [69.468505859000061, 30.587524414000029],
            [69.468505834000041, 30.587523861000079],
            [69.46845600000006, 30.587391000000025],
            [69.467979000000071, 30.578318000000024],
            [69.466546000000051, 30.567334000000074],
            [69.466068000000064, 30.559216000000049],
            [69.467979000000071, 30.547754000000054],
            [69.472277000000076, 30.543456000000049],
            [69.476575000000082, 30.536771000000044],
            [69.476627439000083, 30.536771000000044],
            [69.476684570000032, 30.536682129000042],
            [69.483849060000068, 30.536682129000042],
            [69.488513000000069, 30.532950000000028],
            [69.491989090000061, 30.530343615000049],
            [69.492309570000032, 30.530090332000043],
            [69.492324383000039, 30.530092212000056],
            [69.492334000000028, 30.530085000000042],
            [69.500452000000053, 30.531040000000075],
            [69.505874722000044, 30.531401817000074],
            [69.507690430000082, 30.531494141000053],
            [69.515319824000073, 30.536682129000042],
            [69.523411648000035, 30.538674891000028],
            [69.523498535000044, 30.538696289000029],
            [69.528449812000076, 30.537835198000039],
            [69.529106587000058, 30.53772097600006],
            [69.529113770000038, 30.537719727000024],
            [69.53210449200003, 30.528686523000033],
            [69.532756121000034, 30.520014850000052],
            [69.532897949000073, 30.518127441000047],
            [69.534802060000061, 30.515853087000039],
            [69.537224000000037, 30.512893000000076],
            [69.536269000000061, 30.508118000000024],
            [69.539612000000034, 30.499999000000059],
            [69.545342000000062, 30.499999000000059],
            [69.555848000000083, 30.499999000000059],
            [69.562057000000038, 30.491403000000048],
            [69.562057000000038, 30.485683805000065],
            [69.560119629000042, 30.481506348000039],
            [69.556838388000074, 30.478397805000043],
            [69.555480957000043, 30.477111816000047],
            [69.555433728000082, 30.47713346300003],
            [69.55108642600004, 30.479125977000024],
            [69.542296037000028, 30.479822776000049],
            [69.541076660000044, 30.479919434000067],
            [69.536315918000071, 30.474304199000073],
            [69.536286414000074, 30.474318164000067],
            [69.536182808000035, 30.474195694000059],
            [69.491800000000069, 30.502064000000075],
            [69.482429142000058, 30.508756899000048],
            [69.468418000000042, 30.518764000000033],
            [69.429450000000031, 30.535465000000045],
            [69.417213596000067, 30.542995452000071],
            [69.417126598000038, 30.543048992000024],
            [69.400502000000074, 30.553280000000029],
            [69.371553000000063, 30.572207000000049],
            [69.362646000000041, 30.591135000000065],
            [69.344832000000054, 30.611176000000057],
            [69.324791000000062, 30.618970000000047],
            [69.299750644000028, 30.616883069000039],
            [69.29833463500006, 30.616765056000077],
            [69.298070000000052, 30.616743000000042],
            [69.273080504000063, 30.62274012000006],
            [69.270235000000071, 30.623423000000059],
            [69.245905156000049, 30.622765676000029],
            [69.229039000000057, 30.622310000000027],
            [69.226518877000046, 30.627350623000041],
            [69.226518741000064, 30.627350896000053],
            [69.22235900000004, 30.635671000000059],
            [69.218689080000047, 30.646680290000063],
            [69.214782180000043, 30.658400487000051],
            [69.21456500000005, 30.659052000000031],
            [69.212338000000045, 30.676866000000075],
            [69.197868587000073, 30.681972761000054],
            [69.193411000000083, 30.683546000000035],
            [69.181333874000075, 30.683021092000047],
            [69.167803000000049, 30.68243300000006],
            [69.15740500000004, 30.683733000000075],
            [69.157171595000079, 30.683762000000058],
            [69.156906000000049, 30.683795000000032],
            [69.156217429000037, 30.683881262000057],
            [69.156161612000062, 30.683888250000052],
            [69.154655000000048, 30.684077000000059],
            [69.149988000000064, 30.684660000000065],
            [69.139968000000067, 30.696907000000067],
            [69.127721000000065, 30.700247000000047],
            [69.113247000000058, 30.695794000000035],
            [69.109380206000083, 30.694827248000024],
            [69.095432000000073, 30.691340000000025],
            [69.084298000000047, 30.69022700000005],
            [69.082072000000039, 30.708041000000037],
            [69.072051000000044, 30.731422000000066],
            [69.05868999900008, 30.752577000000031],
            [69.079845000000034, 30.763711000000058],
            [69.10236506900003, 30.772155815000076],
            [69.106566000000043, 30.773731000000055],
            [69.137741000000062, 30.794886000000076],
            [69.143549008000036, 30.798460128000045],
            [69.152215000000069, 30.803793000000042],
            [69.177577276000079, 30.810789247000059],
            [69.184504000000061, 30.812700000000063],
            [69.20127315600007, 30.816778818000046],
            [69.225699000000077, 30.822720000000061],
            [69.254543228000045, 30.836033104000023],
            [69.254647000000034, 30.836081000000036],
            [69.261328000000049, 30.85723500000006],
            [69.275802000000056, 30.878390000000024],
            [69.285822000000053, 30.908451000000071],
            [69.288049000000058, 30.926266000000055],
            [69.288044212000045, 30.926289940000061],
            [69.288043303000052, 30.926294486000074],
            [69.285441521000052, 30.939304173000039],
            [69.284709000000078, 30.942967000000067],
            [69.299183000000028, 30.964121000000034],
            [69.30809000000005, 30.985275000000058],
            [69.327018000000066, 30.988616000000036],
            [69.348172000000034, 30.995296000000053],
            [69.351685269000029, 30.999277747000065],
            [69.364873000000046, 31.01422400000007],
            [69.380461000000082, 31.038718000000074],
            [69.379780985000082, 31.051631581000038],
            [69.379347000000053, 31.059873000000039],
            [69.361533000000065, 31.059873000000039],
            [69.342605000000049, 31.045399000000032],
            [69.325861886000041, 31.031844676000048],
            [69.320554179000055, 31.027547841000057],
            [69.317025934000071, 31.025860420000072],
            [69.299314002000074, 31.020940390000078],
            [69.299183000000028, 31.02090400000003],
            [69.293749613000045, 31.021476003000032],
            [69.27802900000006, 31.023131000000035],
            [69.262441000000081, 31.032038000000057],
            [69.247967000000074, 31.046512000000064],
            [69.229039000000057, 31.067666000000031],
            [69.216905268000062, 31.087521648000063],
            [69.216904541000076, 31.087522837000051],
            [69.216792000000055, 31.087707000000023],
            [69.216600652000068, 31.087754848000031],
            [69.20343100000008, 31.091048000000058],
            [69.181163000000083, 31.093274000000065],
            [69.163349000000039, 31.09995500000008],
            [69.147762000000057, 31.112202000000025],
            [69.126607000000035, 31.128903000000037],
            [69.103226000000063, 31.142264000000068],
            [69.088752000000056, 31.141150000000039],
            [69.075391000000081, 31.131130000000041],
            [69.063144000000079, 31.134470000000078],
            [69.040876000000083, 31.136697000000026],
            [69.020835000000034, 31.127790000000061],
            [69.009701000000064, 31.116656000000035],
            [68.998567000000037, 31.123336000000052],
            [68.997874241000034, 31.126107371000046],
            [68.996167270000058, 31.132936097000027],
            [68.994823962000055, 31.138309991000028],
            [68.994114000000081, 31.141150000000039],
            [68.978526000000045, 31.126676000000032],
            [68.977694992000067, 31.126107407000063],
            [68.957372000000078, 31.112202000000025],
            [68.938444000000061, 31.109975000000077],
            [68.926197000000059, 31.11442900000003],
            [68.925083000000029, 31.121109000000047],
            [68.923970000000054, 31.134470000000078],
            [68.905042000000037, 31.140037000000063],
            [68.87943400000006, 31.134470000000078],
            [68.860507000000041, 31.137810000000059],
            [68.851932006000084, 31.143746853000039],
            [68.846033000000034, 31.147831000000053],
            [68.828218000000049, 31.146717000000024],
            [68.817084000000079, 31.146717000000024],
            [68.800384000000065, 31.146717000000024],
            [68.789250000000038, 31.133357000000046],
            [68.778116000000068, 31.121109000000047],
            [68.763642000000061, 31.108862000000045],
            [68.761935372000039, 31.107913864000068],
            [68.74801935000005, 31.100182663000055],
            [68.747340314000041, 31.099805417000027],
            [68.743601000000069, 31.097728000000075],
            [68.723560000000077, 31.09550100000007],
            [68.704632000000061, 31.097728000000075],
            [68.693498000000034, 31.104408000000035],
            [68.690158000000054, 31.122223000000076],
            [68.689044000000081, 31.145604000000048],
            [68.677911000000051, 31.15451100000007],
            [68.672876780000081, 31.166017235000027],
            [68.671157525000069, 31.16994677200006],
            [68.670117000000062, 31.172325000000058],
            [68.670154779000029, 31.172539093000069],
            [68.673457000000042, 31.191253000000074],
            [68.675013628000045, 31.192965234000042],
            [68.684591000000069, 31.203500000000076],
            [68.676797000000079, 31.223541000000068],
            [68.667890000000057, 31.236902000000043],
            [68.655643000000055, 31.24692200000004],
            [68.658983000000035, 31.279211000000032],
            [68.660096000000067, 31.310386000000051],
            [68.654529000000082, 31.327087000000063],
            [68.65049631100004, 31.328729938000038],
            [68.650348171000076, 31.328790291000075],
            [68.624468000000036, 31.339334000000065],
            [68.607190417000083, 31.345244698000045],
            [68.605886009000074, 31.345690939000065],
            [68.582159000000047, 31.353808000000072],
            [68.565458000000035, 31.346014000000025],
            [68.536510000000078, 31.338221000000033],
            [68.515355000000056, 31.332654000000048],
            [68.479727000000082, 31.320406000000048],
            [68.438954779000028, 31.300516937000054],
            [68.434078000000056, 31.298138000000051],
            [68.401789000000065, 31.281438000000037],
            [68.398223429000041, 31.281752596000047],
            [68.363934000000029, 31.284778000000074],
            [68.340553000000057, 31.293685000000039],
            [68.308265000000063, 31.301479000000029],
            [68.281543000000056, 31.309272000000078],
            [68.244801000000052, 31.320406000000048],
            [68.206946000000073, 31.342674000000045],
            [68.17688400000003, 31.359375000000057],
            [68.155730000000062, 31.381643000000054],
            [68.125668000000076, 31.408364000000063],
            [68.066659000000072, 31.458467000000041],
            [68.022123000000079, 31.49298200000004],
            [67.977587000000028, 31.515250000000037],
            [67.970836242000075, 31.517586810000068],
            [67.919691000000057, 31.535291000000029],
            [67.868475000000046, 31.557559000000026],
            [67.835073000000079, 31.577600000000075],
            [67.82162500000004, 31.594651000000056],
            [67.831395000000043, 31.600890000000049],
            [67.841567000000055, 31.609479000000078],
            [67.84966200000008, 31.617555000000038],
            [67.861456000000032, 31.622142000000053],
            [67.872247000000073, 31.62379500000003],
            [67.885031000000083, 31.626351000000057],
            [67.905484000000058, 31.621749000000079],
            [67.919290000000046, 31.628396000000066],
            [67.934118000000069, 31.630440000000078],
            [67.954060000000084, 31.631463000000053],
            [67.963809000000083, 31.634467000000029],
            [67.97583700000007, 31.642063000000064],
            [67.990365000000054, 31.648847000000046],
            [68.013376000000051, 31.661118000000045],
            [68.03434100000004, 31.67390000000006],
            [68.062976000000049, 31.691796000000068],
            [68.069112000000075, 31.702533000000074],
            [68.072181000000057, 31.716339000000062],
            [68.078317000000084, 31.722986000000049],
            [68.088032000000055, 31.726054000000033],
            [68.101327000000083, 31.741905000000031],
            [68.110020000000077, 31.749063000000035],
            [68.122804000000031, 31.758266000000049],
            [68.132008000000042, 31.771561000000077],
            [68.134054000000049, 31.78281000000004],
            [68.140979182000081, 31.790599348000057],
            [68.141067216000067, 31.790446836000058],
            [68.141113281000059, 31.790710450000063],
            [68.143127442000036, 31.792907715000069],
            [68.148925781000059, 31.79968261700003],
            [68.151489258000083, 31.802673340000069],
            [68.15710449200003, 31.815124512000068],
            [68.158874512000068, 31.817871094000054],
            [68.164306641000053, 31.826293945000032],
            [68.172912598000039, 31.830871582000043],
            [68.175476074000073, 31.821289062000062],
            [68.187316895000038, 31.80889892600004],
            [68.19812011700003, 31.806884765000063],
            [68.211303711000028, 31.804870605000076],
            [68.229492188000052, 31.805297851000034],
            [68.245483398000033, 31.803894043000071],
            [68.259887695000032, 31.799072266000053],
            [68.254699707000043, 31.780090332000043],
            [68.261901855000076, 31.771484375000057],
            [68.27532958900008, 31.760314942000036],
            [68.298278808000077, 31.75170898500005],
            [68.321716309000067, 31.753723145000038],
            [68.328918457000043, 31.754272461000028],
            [68.348327637000068, 31.755676269000048],
            [68.377319336000028, 31.756896973000039],
            [68.393676758000083, 31.754882813000052],
            [68.393674975000067, 31.754820429000063],
            [68.39483000000007, 31.754679000000067],
            [68.41579500000006, 31.756724000000077],
            [68.431134000000043, 31.75058800000005],
            [68.445982000000072, 31.741900000000044],
            [68.472356000000048, 31.738636000000042],
            [68.49339400000008, 31.734322000000077],
            [68.514430000000061, 31.730005000000062],
            [68.534421000000066, 31.714792000000045],
            [68.556920000000048, 31.711212000000046],
            [68.570215000000076, 31.72041500000006],
            [68.574175994000029, 31.725507470000025],
            [68.574594952000041, 31.726046105000023],
            [68.584533000000079, 31.738823000000025],
            [68.581465000000037, 31.748538000000053],
            [68.554365000000075, 31.752118000000053],
            [68.54055900000003, 31.751607000000035],
            [68.511924000000079, 31.75211900000005],
            [68.476131000000066, 31.745473000000061],
            [68.460280000000068, 31.749564000000078],
            [68.441873000000044, 31.758257000000071],
            [68.444941000000028, 31.766438000000051],
            [68.462838000000033, 31.768994000000077],
            [68.475152000000037, 31.775761000000045],
            [68.490961000000084, 31.784845000000075],
            [68.50527900000003, 31.79046900000003],
            [68.525221000000045, 31.798138000000051],
            [68.546186000000034, 31.810921000000064],
            [68.563571000000081, 31.82165800000007],
            [68.577428000000054, 31.822585000000061],
            [68.589758000000074, 31.81396200000006],
            [68.605131000000029, 31.798396000000025],
            [68.622167000000047, 31.784636000000035],
            [68.636689000000047, 31.774103000000025],
            [68.655097000000069, 31.773079000000052],
            [68.677084000000036, 31.77154500000006],
            [68.698560000000043, 31.771033000000045],
            [68.711277000000052, 31.753841000000079],
            [68.715208000000075, 31.728942000000075],
            [68.717829000000052, 31.709941000000072],
            [68.717174000000057, 31.69421600000004],
            [68.71749496700005, 31.694023138000034],
            [68.717529297000056, 31.694091797000056],
            [68.727294922000056, 31.688293457000043],
            [68.740112305000082, 31.680114746000072],
            [68.752685547000056, 31.672485352000024],
            [68.765075684000067, 31.659118652000075],
            [68.778320312000062, 31.659484863000046],
            [68.781921387000068, 31.647888184000067],
            [68.785522461000028, 31.635681153000064],
            [68.793701172000056, 31.627929687000062],
            [68.79968261700003, 31.617919922000056],
            [68.803252606000058, 31.612353159000065],
            [68.803563237000049, 31.613393942000073],
            [68.803887000000032, 31.613030000000037],
            [68.814114000000075, 31.606894000000068],
            [68.823829000000046, 31.603826000000026],
            [68.837635000000034, 31.603314000000069],
            [68.852974000000074, 31.597689000000059],
            [68.868825000000072, 31.597689000000059],
            [68.891835000000071, 31.597177000000045],
            [68.914845000000071, 31.599222000000054],
            [68.939389000000062, 31.60995900000006],
            [68.943225000000041, 31.631315000000029],
            [68.952398000000073, 31.643109000000038],
            [68.97364900000008, 31.636035000000049],
            [68.991545000000031, 31.626831000000038],
            [69.026827000000083, 31.620694000000071],
            [69.030918000000042, 31.635522000000037],
            [69.040634000000068, 31.647282000000075],
            [69.055974000000049, 31.659042000000056],
            [69.07066760400005, 31.663235174000079],
            [69.073614000000077, 31.66407600000008],
            [69.086654000000067, 31.677961000000039],
            [69.106597000000079, 31.69176600000003],
            [69.122756000000038, 31.703389000000072],
            [69.123411000000033, 31.718459000000053],
            [69.136800306000055, 31.73168802400005],
            [69.137278000000038, 31.732160000000079],
            [69.142392000000029, 31.745454000000052],
            [69.156198000000074, 31.762327000000028],
            [69.157482000000073, 31.778084000000035],
            [69.164380000000051, 31.786359000000061],
            [69.171539000000053, 31.790450000000078],
            [69.182277000000056, 31.797097000000065],
            [69.185857000000055, 31.805789000000061],
            [69.18841400000008, 31.821640000000059],
            [69.198106000000053, 31.827226000000053],
            [69.199416000000042, 31.845572000000061],
            [69.213470000000029, 31.851296000000048],
            [69.219751948000066, 31.853895635000072],
            [69.219788164000079, 31.853910621000068],
            [69.224970000000042, 31.856055000000026],
            [69.238526000000036, 31.860499000000061],
            [69.253355000000056, 31.873793000000035],
            [69.268870000000049, 31.882264000000077],
            [69.273809000000028, 31.898336000000029],
            [69.282502000000079, 31.909074000000032],
            [69.293240000000083, 31.912653000000034],
            [69.304016869000066, 31.917626004000056],
            [69.324563000000069, 31.926164000000028],
            [69.323253000000079, 31.94713100000007],
            [69.336475244000042, 31.949019987000042],
            [69.343118000000061, 31.949969000000067],
            [69.356957000000079, 31.953580000000045],
            [69.375008000000037, 31.962003000000038],
            [69.387042000000065, 31.961402000000078],
            [69.401682520000065, 31.958787094000058],
            [69.403889000000049, 31.958393000000058],
            [69.422542000000078, 31.958393000000058],
            [69.448470479000036, 31.960313835000079],
            [69.455033000000071, 31.960800000000063],
            [69.486230173000081, 31.963315443000056],
            [69.492339000000072, 31.963808000000029],
            [69.50240713900007, 31.966571932000079],
            [69.515618564000079, 31.970198766000067],
            [69.523025000000075, 31.972232000000076],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "40",
      properties: { name: "Ziarat", count: 1354 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.49961920800007, 30.524351308000064],
            [67.510883000000035, 30.516091000000074],
            [67.513875362000078, 30.516091000000074],
            [67.528429000000074, 30.516091000000074],
            [67.53363679000006, 30.521299421000037],
            [67.536685000000034, 30.524348000000032],
            [67.538749000000053, 30.537765000000036],
            [67.555263000000082, 30.541893000000073],
            [67.556504683000071, 30.545204354000077],
            [67.557412400000032, 30.547625081000035],
            [67.558311888000048, 30.550023860000067],
            [67.56145500000008, 30.558406000000048],
            [67.563519000000042, 30.572855000000061],
            [67.586971214000073, 30.581750856000042],
            [67.593449000000078, 30.584208000000046],
            [67.606079102000081, 30.584899902000075],
            [67.622519657000055, 30.585765249000076],
            [67.651245000000074, 30.587304000000074],
            [67.656781002000059, 30.58778546700006],
            [67.671550753000076, 30.589069997000024],
            [67.690337775000046, 30.590703909000069],
            [67.698721000000035, 30.591433000000052],
            [67.69905262900005, 30.591433000000052],
            [67.699236866000035, 30.591433000000052],
            [67.699236877000033, 30.591432961000066],
            [67.701817000000062, 30.583176000000037],
            [67.707780914000068, 30.576218180000069],
            [67.726587000000052, 30.554278000000068],
            [67.754045075000079, 30.532500526000035],
            [67.756517000000031, 30.53054000000003],
            [67.776463044000081, 30.520133405000024],
            [67.780255000000068, 30.518155000000036],
            [67.78231900000003, 30.50680200000005],
            [67.784335500000054, 30.500753036000049],
            [67.784830900000031, 30.49926696700004],
            [67.784831008000083, 30.499266975000069],
            [67.804122000000064, 30.500753000000032],
            [67.831210000000056, 30.49957500000005],
            [67.835100858000033, 30.49957500000005],
            [67.848988625000061, 30.49957500000005],
            [67.850054000000057, 30.49957500000005],
            [67.867721000000074, 30.498397000000068],
            [67.880721103000042, 30.496155905000023],
            [67.888455505000081, 30.494822567000028],
            [67.901876000000073, 30.492509000000041],
            [67.92307500000004, 30.492509000000041],
            [67.926743634000047, 30.490251416000035],
            [67.938386000000037, 30.483087000000069],
            [67.960763000000043, 30.474842000000024],
            [67.967387444000053, 30.478627053000025],
            [67.969008000000031, 30.479553000000067],
            [67.969008000000031, 30.491331000000059],
            [67.980785000000083, 30.50193100000007],
            [67.98201791300005, 30.505013151000071],
            [67.985496000000069, 30.513708000000065],
            [67.998963458000048, 30.522686306000026],
            [67.999629000000084, 30.523130000000037],
            [68.007874000000072, 30.518419000000051],
            [68.015380898000046, 30.529679346000023],
            [68.017296000000044, 30.532552000000067],
            [68.026718000000074, 30.536086000000068],
            [68.040851000000032, 30.538441000000034],
            [68.04701932100005, 30.536590556000078],
            [68.052268556000058, 30.535015831000067],
            [68.052628000000084, 30.53490800000003],
            [68.070295000000044, 30.53490800000003],
            [68.071369120000043, 30.535073212000043],
            [68.085606000000041, 30.537263000000053],
            [68.096205000000054, 30.539619000000073],
            [68.100058470000079, 30.539915479000058],
            [68.111516000000051, 30.540797000000055],
            [68.115756819000069, 30.541150177000077],
            [68.125649000000067, 30.541974000000039],
            [68.138605000000041, 30.532552000000067],
            [68.14802700000007, 30.526664000000039],
            [68.161211983000044, 30.526664000000039],
            [68.161230466000063, 30.526664000000039],
            [68.161368615000072, 30.526664000000039],
            [68.161589194000044, 30.526664000000039],
            [68.163338000000067, 30.526664000000039],
            [68.177066368000055, 30.527644003000034],
            [68.179826000000048, 30.527841000000024],
            [68.201854251000043, 30.529473030000077],
            [68.211626000000081, 30.530197000000044],
            [68.22994740200005, 30.511875598000074],
            [68.229947438000067, 30.511875562000057],
            [68.22996020800008, 30.511862792000045],
            [68.230470000000082, 30.511353000000042],
            [68.230470000000082, 30.492509000000041],
            [68.230470000000082, 30.487001532000079],
            [68.230470000000082, 30.477494582000077],
            [68.230470000000082, 30.473664000000042],
            [68.23497453400006, 30.467229030000055],
            [68.238714000000073, 30.461887000000047],
            [68.250858826000069, 30.456281391000061],
            [68.25402500000007, 30.454820000000041],
            [68.254820635000044, 30.454820000000041],
            [68.266980000000046, 30.454820000000041],
            [68.288180000000068, 30.454820000000041],
            [68.301135000000045, 30.451287000000036],
            [68.301135657000032, 30.451286394000078],
            [68.31538357900007, 30.438134681000065],
            [68.316446000000042, 30.437154000000078],
            [68.31762400000008, 30.424199000000044],
            [68.316446000000042, 30.41124300000007],
            [68.316791959000057, 30.405362869000044],
            [68.31762400000008, 30.39122100000003],
            [68.325868000000071, 30.384155000000078],
            [68.333720067000058, 30.380229204000045],
            [68.34235700000005, 30.375911000000031],
            [68.349752314000057, 30.375342018000026],
            [68.357668000000047, 30.374733000000049],
            [68.391823000000045, 30.374733000000049],
            [68.406048440000063, 30.374733000000049],
            [68.413179477000028, 30.374733000000049],
            [68.415378000000032, 30.374733000000049],
            [68.440111000000059, 30.382977000000039],
            [68.457777000000078, 30.386510000000044],
            [68.473088000000075, 30.386510000000044],
            [68.490754000000038, 30.38533300000006],
            [68.50959800000004, 30.387688000000026],
            [68.512995057000069, 30.384290943000053],
            [68.516001695000057, 30.381284305000065],
            [68.523731000000055, 30.373555000000067],
            [68.521376000000032, 30.368844000000024],
            [68.513696395000068, 30.366051363000054],
            [68.508421000000055, 30.364133000000038],
            [68.506940193000048, 30.363022448000038],
            [68.494287000000043, 30.353533000000027],
            [68.48486500000007, 30.342933000000073],
            [68.489576000000056, 30.326445000000035],
            [68.496825892000061, 30.310132360000068],
            [68.498998000000029, 30.30524500000007],
            [68.514309000000083, 30.29464500000006],
            [68.522446194000054, 30.294257766000044],
            [68.539042000000052, 30.293468000000075],
            [68.563775000000078, 30.293468000000075],
            [68.576730000000055, 30.278157000000078],
            [68.577785873000039, 30.265491989000054],
            [68.577785880000079, 30.265491900000029],
            [68.577762168000049, 30.265472141000032],
            [68.577752070000031, 30.265593581000076],
            [68.57775173400006, 30.265593301000024],
            [68.573163118000082, 30.26176943400003],
            [68.548429297000041, 30.259416879000071],
            [68.542540695000071, 30.248815679000074],
            [68.543277180000075, 30.244398573000069],
            [68.544155712000077, 30.239129538000043],
            [68.544897622000065, 30.234679898000024],
            [68.530764213000054, 30.232325116000027],
            [68.511919899000077, 30.225260043000048],
            [68.507224218000033, 30.222370479000062],
            [68.504085481000061, 30.22043900500006],
            [68.496608862000073, 30.215838144000031],
            [68.49150439500005, 30.211661392000053],
            [68.483653986000036, 30.205237762000024],
            [68.469521342000064, 30.189925681000034],
            [68.460098255000048, 30.185214935000033],
            [68.444787592000068, 30.18639491700003],
            [68.427119837000077, 30.187574171000051],
            [68.423391864000052, 30.18624310000007],
            [68.410631545000058, 30.181687034000049],
            [68.390608977000056, 30.17815472500007],
            [68.374208722000049, 30.176430456000048],
            [68.368230248000032, 30.175801898000032],
            [68.356453070000043, 30.171091425000043],
            [68.351573492000057, 30.168956888000025],
            [68.337608826000064, 30.162848143000076],
            [68.318764234000071, 30.160493906000056],
            [68.313613968000084, 30.160494502000063],
            [68.303452640000046, 30.160495679000064],
            [68.282251261000056, 30.169921805000058],
            [68.275898617000053, 30.172366213000032],
            [68.266939319000073, 30.175813623000067],
            [68.242205220000073, 30.178171904000067],
            [68.221005398000045, 30.178174358000035],
            [68.211850886000036, 30.171308275000058],
            [68.21184609800008, 30.171304684000063],
            [68.211813459000041, 30.171280205000073],
            [68.211768231000065, 30.17141551800006],
            [68.21176590400006, 30.171422476000032],
            [68.211745000000064, 30.171485000000075],
            [68.203228000000081, 30.17858300000006],
            [68.198393227000054, 30.179698555000073],
            [68.197920662000058, 30.179807593000078],
            [68.184774000000061, 30.182841000000053],
            [68.178214636000064, 30.190337698000064],
            [68.177690382000037, 30.190936869000041],
            [68.174837000000082, 30.194198000000029],
            [68.166545889000076, 30.199850717000061],
            [68.165548824000041, 30.200530496000056],
            [68.159222000000057, 30.204844000000037],
            [68.145671337000067, 30.20701207500008],
            [68.145147011000063, 30.207095965000065],
            [68.141526678000048, 30.207675212000026],
            [68.141520062000041, 30.207676270000036],
            [68.141478000000063, 30.207683000000031],
            [68.137193427000057, 30.214933851000069],
            [68.136519500000077, 30.216074348000063],
            [68.132251000000053, 30.223298000000057],
            [68.122196760000065, 30.228060457000026],
            [68.121470415000033, 30.228404510000075],
            [68.118765000000053, 30.229686000000072],
            [68.115403255000047, 30.232170706000034],
            [68.102440000000058, 30.241752000000076],
            [68.102439272000083, 30.241757460000031],
            [68.102438619000054, 30.241762357000027],
            [68.101673854000069, 30.247500528000046],
            [68.101023995000048, 30.252376533000074],
            [68.10102100000006, 30.252399000000025],
            [68.098661974000038, 30.255263446000072],
            [68.097744413000044, 30.25637759500006],
            [68.09108400000008, 30.26446500000003],
            [68.084256174000075, 30.264878949000035],
            [68.083807290000038, 30.264906163000035],
            [68.067730981000068, 30.265867994000075],
            [68.067687678000084, 30.265883443000064],
            [68.067680468000049, 30.265883880000047],
            [68.067662000000041, 30.265885000000026],
            [68.050342355000055, 30.272069840000029],
            [68.04782722200008, 30.272967994000055],
            [68.02723098000007, 30.272967994000055],
            [68.008730981000042, 30.27936799400004],
            [68.005308597000067, 30.282565675000058],
            [68.004335544000071, 30.283469249000063],
            [67.988877000000059, 30.297824000000048],
            [67.974790061000078, 30.30171050000007],
            [67.974388260000069, 30.301821354000026],
            [67.968293000000074, 30.303503000000035],
            [67.952727788000061, 30.30987063200007],
            [67.95271593700005, 30.309875480000073],
            [67.952678000000049, 30.30989100000005],
            [67.951809959000059, 30.310605807000059],
            [67.947761505000074, 30.313939591000064],
            [67.940612000000044, 30.319827000000032],
            [67.920739000000083, 30.321957000000054],
            [67.91553844200007, 30.322699967000062],
            [67.912133990000029, 30.323186336000049],
            [67.895897000000048, 30.325506000000075],
            [67.892268472000069, 30.32762241000006],
            [67.891559465000057, 30.32803595300004],
            [67.878862000000083, 30.335442000000057],
            [67.874278634000063, 30.342525758000079],
            [67.873936202000039, 30.343054998000071],
            [67.871055000000069, 30.347508000000062],
            [67.871055000000069, 30.358155000000068],
            [67.870405069000071, 30.358913127000051],
            [67.870108260000052, 30.359259345000055],
            [67.866796000000079, 30.36312300000003],
            [67.85509686000006, 30.365604984000072],
            [67.855124697000065, 30.365618057000063],
            [67.85512654200005, 30.365618924000046],
            [67.848227804000032, 30.367063531000042],
            [67.84776596100005, 30.367160241000079],
            [67.84337400000004, 30.368092000000047],
            [67.840064655000049, 30.36894294800004],
            [67.839502176000053, 30.369087581000031],
            [67.837127724000084, 30.369698135000078],
            [67.818531000000064, 30.374480000000062],
            [67.814546287000042, 30.374754708000069],
            [67.814403386000038, 30.374764559000027],
            [67.814200757000037, 30.374778528000036],
            [67.797948000000076, 30.375899000000061],
            [67.779782543000067, 30.379415188000053],
            [67.77916360100005, 30.37953499300005],
            [67.775945000000036, 30.380158000000051],
            [67.773811982000041, 30.380584644000066],
            [67.758055968000065, 30.383736143000078],
            [67.754629494000028, 30.384367165000072],
            [67.754624995000029, 30.384367994000058],
            [67.747793000000058, 30.380559000000062],
            [67.74654601800006, 30.379857450000031],
            [67.74332499500008, 30.378067994000048],
            [67.756824995000045, 30.362367994000067],
            [67.758224996000081, 30.346067994000066],
            [67.755490534000046, 30.340488012000037],
            [67.752565881000066, 30.334735971000043],
            [67.749365067000042, 30.331311844000027],
            [67.744005000000072, 30.325505000000078],
            [67.740539170000034, 30.323945559000038],
            [67.72982677300007, 30.319125547000056],
            [67.729810000000043, 30.31911800000006],
            [67.71135600000008, 30.311310000000049],
            [67.703237046000083, 30.308990066000035],
            [67.696465882000041, 30.307035971000062],
            [67.682265881000035, 30.29923597100003],
            [67.669465882000054, 30.294935971000029],
            [67.659532944000034, 30.296643195000058],
            [67.65670300000005, 30.297115000000076],
            [67.64265389500008, 30.297725206000052],
            [67.642653530000075, 30.297725222000054],
            [67.640379364000069, 30.297824000000048],
            [67.630955541000048, 30.295825215000036],
            [67.616965882000045, 30.292835972000034],
            [67.604165882000075, 30.286435972000049],
            [67.592865882000069, 30.279335971000023],
            [67.584552932000065, 30.275887485000055],
            [67.57933836400008, 30.27369200000004],
            [67.565853364000077, 30.274402000000066],
            [67.558773128000041, 30.276667400000065],
            [67.54806588100007, 30.280035971000075],
            [67.53731207900006, 30.284878062000075],
            [67.52894436400004, 30.288597000000038],
            [67.522573605000048, 30.292651861000024],
            [67.521137364000083, 30.293566000000055],
            [67.519919820000041, 30.295131397000034],
            [67.511200000000031, 30.306342000000029],
            [67.511195802000032, 30.306344372000069],
            [67.494865881000067, 30.315535971000031],
            [67.49462524300003, 30.31550965200006],
            [67.482064097000034, 30.314135972000031],
            [67.455127999000069, 30.314148999000054],
            [67.455126216000053, 30.314149000000043],
            [67.453933280000058, 30.313552532000074],
            [67.437238484000034, 30.305638763000047],
            [67.437237672000037, 30.305638378000026],
            [67.415635489000067, 30.310487278000039],
            [67.411830216000055, 30.311310000000049],
            [67.40506091900005, 30.313315639000052],
            [67.40494822900007, 30.313349028000061],
            [67.392666216000066, 30.316988000000038],
            [67.37280822200006, 30.319078277000074],
            [67.37273265500005, 30.319086231000028],
            [67.365695216000063, 30.319827000000032],
            [67.342272216000083, 30.319827000000032],
            [67.342252870000038, 30.319841070000052],
            [67.337999701000058, 30.322934382000028],
            [67.337624299000083, 30.323207411000055],
            [67.33446521500008, 30.325505000000078],
            [67.321689215000049, 30.332603000000063],
            [67.314191976000075, 30.336351619000027],
            [67.314079569000057, 30.336407824000048],
            [67.308932655000035, 30.338941070000033],
            [67.287032655000075, 30.330541070000038],
            [67.28711198700006, 30.329932860000042],
            [67.287080967000065, 30.329916991000061],
            [67.263186000000076, 30.318965000000048],
            [67.263185026000031, 30.318965371000047],
            [67.241512000000057, 30.327222000000063],
            [67.219046578000075, 30.343489728000065],
            [67.219046459000083, 30.343489814000066],
            [67.211582000000078, 30.348895000000027],
            [67.194037000000037, 30.369537000000037],
            [67.191467719000059, 30.386234523000041],
            [67.189908000000059, 30.396371000000045],
            [67.202293000000054, 30.415980000000047],
            [67.214678000000049, 30.43146100000007],
            [67.217246027000044, 30.437880989000064],
            [67.222935000000064, 30.452103000000079],
            [67.228864000000044, 30.466596748000029],
            [67.232223000000033, 30.474808000000053],
            [67.232283609000035, 30.474875343000065],
            [67.234906064000029, 30.477789150000035],
            [67.238606484000059, 30.481900684000038],
            [67.250801000000081, 30.495450000000062],
            [67.25875555500005, 30.507526535000068],
            [67.28485900000004, 30.535701000000074],
            [67.299453526000036, 30.540123194000046],
            [67.313272195000081, 30.544310301000053],
            [67.318918000000053, 30.546021000000053],
            [67.336358060000066, 30.557306095000058],
            [67.354424164000079, 30.571190589000025],
            [67.363297000000046, 30.578016000000048],
            [67.38590358600004, 30.589319293000074],
            [67.392195000000072, 30.592465000000061],
            [67.411804000000075, 30.59349700000007],
            [67.44379900000007, 30.586272000000065],
            [67.457216000000074, 30.578016000000048],
            [67.467536000000052, 30.554278000000068],
            [67.470895080000048, 30.550918920000072],
            [67.49540200000007, 30.52744400000006],
            [67.49961920800007, 30.524351308000064],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "41",
      properties: { name: "Lehri", count: 980 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.084809000000064, 29.343469000000027],
            [68.095289000000037, 29.338229000000069],
            [68.111009000000081, 29.343469000000027],
            [68.143197000000043, 29.354697000000044],
            [68.159665000000075, 29.360686000000044],
            [68.174636000000078, 29.367423000000031],
            [68.179665000000057, 29.37173400000006],
            [68.179876000000036, 29.371914000000061],
            [68.212064000000055, 29.34721200000007],
            [68.223230027000056, 29.340595034000046],
            [68.232275000000072, 29.335235000000068],
            [68.247407877000057, 29.325290241000062],
            [68.258474000000035, 29.318018000000052],
            [68.293611646000045, 29.294368130000066],
            [68.297399000000041, 29.291819000000032],
            [68.323599000000058, 29.273853000000031],
            [68.333710964000034, 29.269302582000023],
            [68.333711050000034, 29.269302544000027],
            [68.353541000000064, 29.260379000000057],
            [68.39246600000007, 29.243911000000026],
            [68.418665000000033, 29.232683000000065],
            [68.44486500000005, 29.222952000000078],
            [68.464327000000083, 29.222203000000036],
            [68.48061000000007, 29.221495000000061],
            [68.480609805000029, 29.22149464000006],
            [68.480548000000056, 29.221381000000065],
            [68.467005000000029, 29.207041000000061],
            [68.435139000000049, 29.205447000000049],
            [68.404866000000084, 29.204651000000069],
            [68.376187000000073, 29.205448000000047],
            [68.356289229000083, 29.20863092500008],
            [68.356270000000052, 29.208634000000075],
            [68.342727000000082, 29.216601000000026],
            [68.319638276000035, 29.225358965000055],
            [68.31962500000003, 29.22536400000007],
            [68.298115000000053, 29.229347000000075],
            [68.288524948000031, 29.224232573000052],
            [68.28616500000004, 29.222974000000079],
            [68.278995000000066, 29.212617000000023],
            [68.27791783300006, 29.209077378000075],
            [68.273419000000047, 29.19429400000007],
            [68.274501555000029, 29.186716572000023],
            [68.275809000000038, 29.177565000000072],
            [68.280690211000035, 29.171381996000036],
            [68.283827430000031, 29.167408099000056],
            [68.287759000000051, 29.162428000000034],
            [68.302098000000058, 29.14729200000005],
            [68.327591000000041, 29.118613000000039],
            [68.34210345300005, 29.102649301000042],
            [68.351491000000067, 29.092323000000079],
            [68.365034000000037, 29.073204000000032],
            [68.381072000000074, 29.052583000000027],
            [68.380967000000055, 29.052491000000032],
            [68.367424000000028, 29.038151000000028],
            [68.352287000000047, 29.02938800000004],
            [68.338744000000077, 29.017438000000027],
            [68.317235000000039, 29.004692000000034],
            [68.290148000000045, 28.994335000000035],
            [68.283140221000053, 28.992202297000063],
            [68.271826001000079, 28.988759000000073],
            [68.264997540000081, 28.988759000000073],
            [68.263321617000031, 28.988759000000073],
            [68.261367541000084, 28.988759000000073],
            [68.259876000000077, 28.988759000000073],
            [68.255014141000061, 28.984383510000043],
            [68.251909000000069, 28.981589000000042],
            [68.248139366000032, 28.97701156200003],
            [68.242314218000047, 28.969938131000049],
            [68.240756000000033, 28.968046000000072],
            [68.235180000000071, 28.962469000000056],
            [68.227213000000063, 28.957689000000073],
            [68.223230000000058, 28.952909000000034],
            [68.225620000000049, 28.942553000000032],
            [68.231196000000068, 28.940163000000041],
            [68.237570000000062, 28.940163000000041],
            [68.248723000000041, 28.940163000000041],
            [68.255096000000037, 28.932993000000067],
            [68.25350300000008, 28.918653000000063],
            [68.250161994000052, 28.907516547000057],
            [68.249119126000039, 28.90369419700005],
            [68.248791574000052, 28.902948576000028],
            [68.24824497000003, 28.901704317000053],
            [68.242896586000029, 28.890340471000059],
            [68.242349000000047, 28.889177000000075],
            [68.240930871000046, 28.879719043000023],
            [68.239960000000053, 28.873244000000057],
            [68.231196000000068, 28.853328000000033],
            [68.216060000000084, 28.846955000000037],
            [68.195955000000083, 28.841211000000044],
            [68.193754000000069, 28.840581999000051],
            [68.180211000000043, 28.835802000000058],
            [68.168027949000077, 28.829710127000055],
            [68.16268400000007, 28.827038000000073],
            [68.148345000000063, 28.820665000000076],
            [68.132221336000043, 28.813275268000041],
            [68.129225000000076, 28.811902000000032],
            [68.113292000000058, 28.803936000000078],
            [68.094172000000071, 28.794376000000057],
            [68.071070000000077, 28.786409000000049],
            [68.05752700000005, 28.784019000000058],
            [68.05498381700005, 28.783570225000062],
            [68.043983000000082, 28.781629000000066],
            [68.034424000000058, 28.781629000000066],
            [68.023271000000079, 28.784019000000058],
            [68.01849100000004, 28.779239000000075],
            [68.019212000000039, 28.775634000000025],
            [68.019270296000059, 28.775342556000055],
            [68.019235560000084, 28.775323062000041],
            [68.019274870000061, 28.775319689000071],
            [68.020510220000062, 28.769143709000048],
            [68.020583584000065, 28.768776932000037],
            [68.020588345000078, 28.76875313100004],
            [68.020881000000031, 28.76729000000006],
            [68.029644000000076, 28.755340000000047],
            [68.031237000000033, 28.745780000000025],
            [68.031843479000031, 28.741837308000072],
            [68.031852583000045, 28.741778132000036],
            [68.032830000000047, 28.735424000000023],
            [68.025661000000071, 28.726660000000038],
            [68.018473517000075, 28.722467803000029],
            [68.016622096000049, 28.721387936000042],
            [68.016263240000058, 28.721178635000058],
            [68.016101000000049, 28.721084000000076],
            [68.01583520500003, 28.720897946000036],
            [68.000167999000041, 28.709931001000029],
            [67.985031000000049, 28.705151000000058],
            [67.977861000000075, 28.701964000000032],
            [67.971488000000079, 28.701168000000052],
            [67.963522001000058, 28.706744000000072],
            [67.962725000000034, 28.715507000000059],
            [67.962831919000052, 28.716095221000046],
            [67.963901269000075, 28.721978325000066],
            [67.964318000000048, 28.724271000000044],
            [67.964318000000048, 28.744983000000047],
            [67.965638561000048, 28.753895855000053],
            [67.967505000000074, 28.766493000000025],
            [67.96575262500005, 28.777882061000071],
            [67.96481360000007, 28.783984988000043],
            [67.964805922000039, 28.784034889000054],
            [67.964318000000048, 28.787206000000026],
            [67.960538819000078, 28.786198029000047],
            [67.960515308000083, 28.786191758000029],
            [67.952369000000033, 28.784019000000058],
            [67.947714198000028, 28.777295830000071],
            [67.945199000000059, 28.773663000000056],
            [67.941216000000054, 28.764103000000034],
            [67.936959474000048, 28.759846809000067],
            [67.934484348000069, 28.757371876000036],
            [67.928469000000064, 28.751357000000041],
            [67.918909000000042, 28.738610000000051],
            [67.912916512000038, 28.729288631000031],
            [67.911739000000068, 28.727457000000072],
            [67.907755175000034, 28.718603006000023],
            [67.904570000000035, 28.711524000000054],
            [67.898637700000052, 28.705221252000058],
            [67.891823000000045, 28.697981000000027],
            [67.889515265000057, 28.695508353000037],
            [67.880670000000066, 28.686031000000071],
            [67.873500000000035, 28.684438000000057],
            [67.867127000000039, 28.677268000000026],
            [67.863144000000034, 28.666912000000025],
            [67.863940000000071, 28.658149000000037],
            [67.872704000000056, 28.658149000000037],
            [67.875402682000072, 28.656444494000027],
            [67.875553538000077, 28.656349212000066],
            [67.88784000000004, 28.648589000000072],
            [67.883478204000028, 28.640261618000068],
            [67.879077000000052, 28.631859000000077],
            [67.87562987900003, 28.624965377000024],
            [67.873500000000035, 28.620706000000041],
            [67.868720000000053, 28.613536000000067],
            [67.859161000000029, 28.613536000000067],
            [67.848007000000052, 28.613536000000067],
            [67.843373481000071, 28.61548693900005],
            [67.832871000000068, 28.619909000000064],
            [67.820921000000055, 28.619113000000027],
            [67.809768000000076, 28.619113000000027],
            [67.80931513400003, 28.619045069000038],
            [67.793835000000058, 28.616723000000036],
            [67.779715485000054, 28.615236047000053],
            [67.779672570000059, 28.615231528000038],
            [67.779587940000056, 28.61522261600004],
            [67.778699000000074, 28.615129000000024],
            [67.77829567100008, 28.61459136700006],
            [67.778234114000043, 28.614509313000042],
            [67.774846166000032, 28.609993217000067],
            [67.763851340000031, 28.635381270000039],
            [67.758582877000038, 28.647505082000066],
            [67.757126678000077, 28.650937456000065],
            [67.755622684000059, 28.654482488000042],
            [67.755551340000068, 28.654481270000076],
            [67.752951340000038, 28.660681270000055],
            [67.750451340000041, 28.670081270000026],
            [67.749314186000049, 28.67435540200006],
            [67.74755134000003, 28.680981270000075],
            [67.747118251000074, 28.684038369000064],
            [67.745851340000058, 28.692981270000075],
            [67.745636607000051, 28.694545753000057],
            [67.74558945800004, 28.694889264000039],
            [67.745636607000051, 28.694905655000071],
            [67.745200000000068, 28.698108000000047],
            [67.740644160000045, 28.706713547000049],
            [67.739804853000066, 28.708298919000072],
            [67.739778913000066, 28.708288881000044],
            [67.739773631000048, 28.708298919000072],
            [67.738151340000059, 28.711381270000061],
            [67.730951340000047, 28.719181270000036],
            [67.729551340000057, 28.720681270000057],
            [67.713951340000051, 28.737881270000059],
            [67.70915810300005, 28.742730898000048],
            [67.705477857000062, 28.746454441000026],
            [67.705451340000081, 28.746481270000061],
            [67.701351340000031, 28.760881270000027],
            [67.700751340000068, 28.762881270000037],
            [67.699845468000035, 28.769958390000056],
            [67.69917296400007, 28.775212330000045],
            [67.699151340000071, 28.775381270000025],
            [67.69917296400007, 28.775581294000062],
            [67.700138579000054, 28.784513229000027],
            [67.700751106000041, 28.79017910500005],
            [67.703436057000033, 28.792864372000054],
            [67.703593817000069, 28.79302215000007],
            [67.705552061000049, 28.794980622000026],
            [67.709314000000063, 28.798743000000059],
            [67.718727258000058, 28.804390794000028],
            [67.721025216000044, 28.80576564100005],
            [67.721051340000031, 28.805781270000068],
            [67.724171377000062, 28.808362392000049],
            [67.727303850000055, 28.81094192300003],
            [67.732073185000047, 28.814869378000026],
            [67.734278000000074, 28.816685000000064],
            [67.73290085800005, 28.818062142000031],
            [67.732851290000042, 28.818111710000039],
            [67.72986682700008, 28.821096173000058],
            [67.729597000000069, 28.821366000000069],
            [67.723357000000078, 28.827607000000057],
            [67.717252380000048, 28.831491565000078],
            [67.715284573000076, 28.832743743000037],
            [67.714775000000031, 28.833068000000026],
            [67.714769310000065, 28.833069778000038],
            [67.714751340000078, 28.833081270000037],
            [67.709341844000051, 28.834769033000043],
            [67.702251340000032, 28.836981270000024],
            [67.701699666000081, 28.837071994000041],
            [67.699507909000033, 28.837432430000035],
            [67.69950775600006, 28.837432455000055],
            [67.695648327000072, 28.838101233000032],
            [67.694673390000048, 28.838238613000044],
            [67.692932000000042, 28.838529000000051],
            [67.690244549000056, 28.838953187000072],
            [67.684490673000028, 28.83986137900007],
            [67.684315645000083, 28.839889930000027],
            [67.679467662000036, 28.840680735000035],
            [67.679331696000077, 28.843397904000028],
            [67.679331528000034, 28.843401267000047],
            [67.679279000000065, 28.844451000000049],
            [67.674400497000079, 28.85560276700005],
            [67.672663174000036, 28.859574111000029],
            [67.67256838600008, 28.859790786000076],
            [67.671549000000084, 28.862121000000059],
            [67.668235390000063, 28.867801198000052],
            [67.667039273000057, 28.869851585000049],
            [67.663280641000028, 28.876294642000062],
            [67.659513725000068, 28.882751898000038],
            [67.659160572000076, 28.883357275000037],
            [67.658925150000073, 28.883760835000032],
            [67.658101402000057, 28.885172906000037],
            [67.657452981000063, 28.886284432000025],
            [67.65608700000007, 28.888626000000045],
            [67.654863444000057, 28.892568735000054],
            [67.653089410000064, 28.898285303000023],
            [67.650676048000037, 28.906062015000032],
            [67.650538166000047, 28.906506322000041],
            [67.650007156000072, 28.908217424000043],
            [67.646158424000078, 28.920619411000075],
            [67.646148000000039, 28.920653000000073],
            [67.646454809000033, 28.926177508000023],
            [67.647239342000034, 28.940304068000046],
            [67.647252000000037, 28.940532000000076],
            [67.648767535000047, 28.942322973000046],
            [67.659401000000059, 28.954889000000037],
            [67.661766798000031, 28.962460753000073],
            [67.662726940000084, 28.965533695000033],
            [67.66389618200003, 28.969275863000064],
            [67.664922000000047, 28.972559000000047],
            [67.666073096000048, 28.978026055000043],
            [67.666129457000068, 28.978293736000069],
            [67.668294399000047, 28.988575987000047],
            [67.668587668000043, 28.989968851000071],
            [67.668754818000082, 28.990762716000063],
            [67.669340000000034, 28.993542000000048],
            [67.670214804000068, 28.997259620000079],
            [67.670322193000061, 28.99771598500007],
            [67.672353787000077, 29.006349570000054],
            [67.673758000000078, 29.012317000000053],
            [67.674268324000082, 29.012725260000025],
            [67.685590154000067, 29.021782724000047],
            [67.690323000000035, 29.025569000000075],
            [67.70247100000006, 29.036613000000045],
            [67.719726158000071, 29.058408081000039],
            [67.719726420000029, 29.058408412000063],
            [67.723401126000056, 29.063049952000028],
            [67.723455000000058, 29.063118000000031],
            [67.737812000000076, 29.077475000000049],
            [67.742229000000066, 29.089623000000074],
            [67.741559965000079, 29.097654445000046],
            [67.741125000000068, 29.102876000000037],
            [67.738102511000079, 29.109927563000042],
            [67.736347299000045, 29.114022527000031],
            [67.734498000000031, 29.122008895000079],
            [67.734498000000031, 29.122905270000047],
            [67.734498000000031, 29.124247832000037],
            [67.734498000000031, 29.124764865000031],
            [67.734498000000031, 29.13379800000007],
            [67.732290000000035, 29.145947000000035],
            [67.732825000000048, 29.147051000000033],
            [67.743333000000064, 29.147051000000033],
            [67.754377000000034, 29.147051000000033],
            [67.760460796000075, 29.147051000000033],
            [67.770943000000045, 29.147051000000033],
            [67.772447769000053, 29.145734263000065],
            [67.77413812900005, 29.144255127000065],
            [67.774356714000078, 29.14406385500007],
            [67.777970587000084, 29.140901563000057],
            [67.778113962000077, 29.140776104000054],
            [67.779778000000078, 29.139320000000055],
            [67.798552000000029, 29.13379800000007],
            [67.809596000000056, 29.130485000000078],
            [67.815818393000029, 29.127373804000058],
            [67.817842883000083, 29.126361559000031],
            [67.820640000000083, 29.124963000000037],
            [67.837206000000037, 29.120546000000047],
            [67.848250000000064, 29.118337000000054],
            [67.853187715000047, 29.116855641000029],
            [67.859293000000036, 29.115024000000062],
            [67.869233000000065, 29.115024000000062],
            [67.884694000000081, 29.112815000000069],
            [67.89100473600007, 29.110360737000065],
            [67.904573000000028, 29.105084000000033],
            [67.923347000000035, 29.100667000000044],
            [67.932182000000068, 29.092936000000066],
            [67.945435000000032, 29.092936000000066],
            [67.95979200000005, 29.092936000000066],
            [67.969731000000081, 29.092936000000066],
            [67.972036326000079, 29.098007549000044],
            [67.975253000000066, 29.105084000000033],
            [67.975253000000066, 29.107802803000027],
            [67.975253000000066, 29.113920000000064],
            [67.975866546000077, 29.118215004000035],
            [67.978566000000058, 29.137112000000059],
            [67.979671000000053, 29.154782000000068],
            [67.979671000000053, 29.164905320000059],
            [67.979671000000053, 29.176075542000035],
            [67.979671000000053, 29.176112661000047],
            [67.979671000000053, 29.179078000000061],
            [67.979482087000065, 29.183609346000026],
            [67.979165264000073, 29.191208795000023],
            [67.978566000000058, 29.205583000000047],
            [67.974742497000079, 29.220879780000075],
            [67.97304500000007, 29.227671000000043],
            [67.971940000000075, 29.242028000000062],
            [67.971940000000075, 29.245358125000052],
            [67.971940000000075, 29.254176000000029],
            [67.97032540400005, 29.267898235000075],
            [67.969731000000081, 29.272950000000037],
            [67.969687007000061, 29.27356614200005],
            [67.969683119000081, 29.273620596000058],
            [67.968627000000083, 29.288412000000051],
            [67.96856553300006, 29.289703248000023],
            [67.967709032000073, 29.307695978000027],
            [67.967523000000028, 29.311604000000045],
            [67.967523000000028, 29.329274000000055],
            [67.972210090000033, 29.335969930000033],
            [67.975253000000066, 29.340317000000027],
            [67.980775000000051, 29.352466000000049],
            [67.979767002000074, 29.369601961000058],
            [67.982257000000061, 29.371914000000061],
            [67.991988000000049, 29.37790300000006],
            [68.006211000000064, 29.37790300000006],
            [68.016691000000037, 29.366674000000046],
            [68.024726045000079, 29.365669704000027],
            [68.028668000000039, 29.365177000000074],
            [68.048551906000057, 29.358121448000077],
            [68.051873000000057, 29.356943000000058],
            [68.06235300000003, 29.356194000000073],
            [68.073581000000047, 29.346463000000028],
            [68.084809000000064, 29.343469000000027],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "42",
      properties: { name: "Sohbatpur", count: 790 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.46376308300006, 28.432272110000042],
            [68.452785684000048, 28.438560235000068],
            [68.445904940000048, 28.444359899000062],
            [68.444204913000078, 28.445792823000033],
            [68.43988194800005, 28.44935974200007],
            [68.43988190400006, 28.449359779000076],
            [68.439810952000073, 28.44941846200004],
            [68.439610580000078, 28.449592039000038],
            [68.436477429000036, 28.452306190000058],
            [68.434519406000049, 28.460531740000079],
            [68.44202253900005, 28.470579348000058],
            [68.448992845000078, 28.477787019000061],
            [68.448273651000079, 28.482030501000054],
            [68.447136044000047, 28.488700791000042],
            [68.444145442000035, 28.49460253500007],
            [68.44279898700006, 28.497259669000073],
            [68.43058508300004, 28.514317891000076],
            [68.433156422000081, 28.528806942000074],
            [68.448450935000039, 28.546391084000049],
            [68.450999900000056, 28.549403935000043],
            [68.451306516000045, 28.549762906000069],
            [68.45179051100007, 28.550338429000078],
            [68.455210688000079, 28.554381045000071],
            [68.455212274000075, 28.554382919000034],
            [68.457202641000038, 28.556735509000077],
            [68.451610060000064, 28.57305758800004],
            [68.45390457600007, 28.592389084000047],
            [68.454466982000042, 28.596378102000074],
            [68.455271627000059, 28.602799576000052],
            [68.455271922000065, 28.602801867000039],
            [68.455523768000035, 28.608033250000062],
            [68.455570412000043, 28.611468181000077],
            [68.44631398000007, 28.618200267000077],
            [68.439338875000033, 28.619933863000028],
            [68.410442064000051, 28.623054179000064],
            [68.385783232000051, 28.616433272000052],
            [68.379085305000046, 28.617999787000031],
            [68.379235511000047, 28.621908349000023],
            [68.379373000000044, 28.625486000000024],
            [68.379372999000054, 28.626602687000059],
            [68.379373000000044, 28.631342816000029],
            [68.379372999000054, 28.643012000000056],
            [68.38298340800003, 28.646106727000074],
            [68.385529896000037, 28.648289497000064],
            [68.390526000000079, 28.652572000000077],
            [68.39583293000004, 28.656109848000028],
            [68.398096215000066, 28.657618659000036],
            [68.407256000000075, 28.663725000000056],
            [68.426376000000062, 28.671692000000064],
            [68.435440804000052, 28.673435109000025],
            [68.446179001000075, 28.675500001000046],
            [68.460632000000032, 28.654962000000069],
            [68.462375102000067, 28.653509313000029],
            [68.464002061000031, 28.652153420000047],
            [68.464002105000077, 28.65215338400003],
            [68.464176358000032, 28.652008162000072],
            [68.464176394000049, 28.652008133000038],
            [68.474971000000039, 28.643012000000056],
            [68.490904000000057, 28.639826000000028],
            [68.499267470000063, 28.632783049000068],
            [68.506041000000039, 28.627079000000037],
            [68.512373497000056, 28.623091784000053],
            [68.513230314000054, 28.622552295000048],
            [68.520753404000061, 28.617815432000043],
            [68.520763831000068, 28.617808867000065],
            [68.523349803000031, 28.616180627000062],
            [68.527550000000076, 28.613536000000067],
            [68.528206038000064, 28.61316983200004],
            [68.545156636000058, 28.603708850000032],
            [68.546365840000078, 28.603033932000073],
            [68.557153530000051, 28.597012778000078],
            [68.561806000000047, 28.594416000000024],
            [68.579425416000049, 28.585368385000038],
            [68.591282000000035, 28.57928000000004],
            [68.61661282700004, 28.562890290000041],
            [68.61836900000003, 28.561754000000064],
            [68.637488000000076, 28.549804000000051],
            [68.640618407000034, 28.547925755000051],
            [68.661388000000045, 28.535464000000047],
            [68.703610000000083, 28.515548000000024],
            [68.705298766000055, 28.514872475000061],
            [68.705630529000075, 28.514739766000048],
            [68.739459000000068, 28.501208000000076],
            [68.743411089000062, 28.499232033000055],
            [68.764952000000051, 28.488462000000027],
            [68.784028055000078, 28.485635622000075],
            [68.784030364000046, 28.485635280000054],
            [68.786462000000029, 28.485275000000058],
            [68.803040122000084, 28.481862182000043],
            [68.804282674000035, 28.481606387000056],
            [68.813548000000083, 28.479699000000039],
            [68.833208408000075, 28.473379394000062],
            [68.83585400000004, 28.472529000000065],
            [68.846004227000037, 28.472867482000026],
            [68.850674342000048, 28.473023218000037],
            [68.850674930000082, 28.473023238000053],
            [68.859754000000066, 28.473326000000043],
            [68.903645661000041, 28.467935355000066],
            [68.903652745000045, 28.467934485000058],
            [68.905163000000073, 28.467749000000026],
            [68.936232000000075, 28.46934200000004],
            [68.939146786000038, 28.469289047000075],
            [68.962737103000052, 28.46886048500005],
            [68.962738298000033, 28.468860463000055],
            [68.980048000000068, 28.46854600000006],
            [68.992441901000063, 28.467263853000077],
            [69.000154042000077, 28.466466034000064],
            [69.011287080000045, 28.465314324000076],
            [69.011288667000031, 28.465314160000048],
            [69.026254000000051, 28.463766000000078],
            [69.039515700000038, 28.460645197000076],
            [69.039797000000078, 28.460579000000052],
            [69.064493000000084, 28.486072000000036],
            [69.082019000000059, 28.499615000000063],
            [69.095559766000065, 28.494627003000062],
            [69.097156000000041, 28.494039000000043],
            [69.10147007900008, 28.490886132000071],
            [69.112852152000073, 28.48256774400005],
            [69.112871744000074, 28.482553426000038],
            [69.117868000000044, 28.478902000000062],
            [69.122741566000059, 28.467936988000076],
            [69.127428000000066, 28.457393000000025],
            [69.130799657000068, 28.440913292000062],
            [69.131035000000054, 28.439763000000028],
            [69.131034204000059, 28.439762964000067],
            [69.124496676000035, 28.439464675000067],
            [69.102797555000052, 28.438474601000053],
            [69.070786000000055, 28.437014000000033],
            [69.064428722000059, 28.437014000000033],
            [69.02685447600004, 28.437014000000033],
            [68.994645799000068, 28.437014000000033],
            [68.99253600000003, 28.437014000000033],
            [68.965308890000074, 28.436139572000059],
            [68.926845658000047, 28.434904282000048],
            [68.919766325000069, 28.434676921000062],
            [68.91601997500004, 28.434553624000046],
            [68.90969844500006, 28.434350815000073],
            [68.875329615000055, 28.433237586000075],
            [68.867140521000067, 28.432972336000034],
            [68.864633510000033, 28.432891132000066],
            [68.85444510800005, 28.432561123000028],
            [68.854419462000067, 28.432560292000062],
            [68.845519000000081, 28.432272000000069],
            [68.840335749000076, 28.432272000000069],
            [68.833877073000053, 28.432272000000069],
            [68.827308038000069, 28.432272000000069],
            [68.820194962000073, 28.432272000000069],
            [68.787138632000051, 28.432272000000069],
            [68.777665738000053, 28.432272000000069],
            [68.736720113000047, 28.432272000000069],
            [68.724310495000054, 28.432272000000069],
            [68.707391530000052, 28.432272000000069],
            [68.703245000000038, 28.432272000000069],
            [68.683316215000048, 28.432272000000069],
            [68.670150314000068, 28.432272000000069],
            [68.656230773000061, 28.432272000000069],
            [68.647841776000064, 28.432272000000069],
            [68.628953722000062, 28.432272000000069],
            [68.620196915000065, 28.432272000000069],
            [68.610767000000067, 28.432272000000069],
            [68.608437536000054, 28.432272000000069],
            [68.608372132000056, 28.432272000000069],
            [68.573947321000048, 28.432272000000069],
            [68.551222955000071, 28.432272000000069],
            [68.546060040000043, 28.432272000000069],
            [68.513067464000073, 28.432273280000061],
            [68.50487135700007, 28.432273993000024],
            [68.504792000000066, 28.432274000000064],
            [68.485565558000076, 28.432273114000054],
            [68.475796804000083, 28.432272664000038],
            [68.470937011000046, 28.432272440000077],
            [68.46376308300006, 28.432272110000042],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "43",
      properties: { name: "Bajaur Agency", count: 1259 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.556992000000037, 34.941965000000039],
            [71.56958300000008, 34.920761000000027],
            [71.574884000000054, 34.91612200000003],
            [71.592775000000074, 34.891605000000027],
            [71.593277327000067, 34.890554655000074],
            [71.600064000000032, 34.876364000000024],
            [71.605365000000063, 34.862448000000029],
            [71.61000400000006, 34.837931000000026],
            [71.609341000000029, 34.824678000000063],
            [71.612719584000047, 34.819272061000049],
            [71.615967000000069, 34.814076000000057],
            [71.624587942000062, 34.812201464000054],
            [71.631208000000072, 34.810762000000068],
            [71.660364000000072, 34.812088000000074],
            [71.682231542000068, 34.812749986000028],
            [71.682232000000056, 34.812750000000051],
            [71.696147000000053, 34.808774000000028],
            [71.702111000000059, 34.796847000000071],
            [71.726628000000062, 34.797510000000045],
            [71.734802202000083, 34.794289735000064],
            [71.735658002000037, 34.793952589000071],
            [71.748496000000046, 34.788895000000025],
            [71.759098000000051, 34.787570000000073],
            [71.771025000000066, 34.77232900000007],
            [71.772475000000043, 34.77141400000005],
            [71.783616000000052, 34.764377000000025],
            [71.791567000000043, 34.764377000000025],
            [71.804726401000039, 34.754508195000028],
            [71.804820000000063, 34.75443800000005],
            [71.804698255000062, 34.754406583000048],
            [71.802584951000028, 34.75386123100003],
            [71.784278000000029, 34.749137000000076],
            [71.773013000000049, 34.738534000000072],
            [71.762411000000043, 34.731908000000033],
            [71.761906147000047, 34.729257047000033],
            [71.759761000000083, 34.717993000000035],
            [71.765724000000034, 34.693475000000035],
            [71.767712000000074, 34.673596000000032],
            [71.764638152000032, 34.664758947000053],
            [71.763583029000074, 34.66172505000003],
            [71.762411000000043, 34.658355000000029],
            [71.759927000000062, 34.656163000000049],
            [71.751146000000062, 34.648415000000057],
            [71.738548963000028, 34.646224714000027],
            [71.735905000000059, 34.64576500000004],
            [71.729942000000051, 34.646427000000074],
            [71.729941909000047, 34.646426955000038],
            [71.715363000000082, 34.639138000000059],
            [71.708074000000067, 34.623898000000054],
            [71.688195000000064, 34.624560000000031],
            [71.678784779000068, 34.613973723000072],
            [71.678777127000046, 34.613965115000042],
            [71.67876947600007, 34.613956507000069],
            [71.677593000000059, 34.612633000000073],
            [71.675605000000076, 34.594079000000079],
            [71.66765300000003, 34.578175000000044],
            [71.668316000000061, 34.560947000000056],
            [71.656388000000049, 34.55962100000005],
            [71.658116455000084, 34.549684505000073],
            [71.659039000000064, 34.544381000000044],
            [71.642473000000052, 34.54173000000003],
            [71.64291640600004, 34.536411806000046],
            [71.641803196000069, 34.536343620000025],
            [71.641012395000075, 34.536253628000054],
            [71.639905946000056, 34.536253628000054],
            [71.638799497000036, 34.536182244000031],
            [71.638169493000078, 34.536182244000031],
            [71.638172480000037, 34.536175628000024],
            [71.638157042000046, 34.536182244000031],
            [71.63790719900004, 34.536182244000031],
            [71.637693047000084, 34.536110861000054],
            [71.637693047000084, 34.53599758200005],
            [71.637157187000071, 34.536249751000071],
            [71.636122122000074, 34.536285443000054],
            [71.634194758000035, 34.536463903000026],
            [71.632838466000067, 34.536678054000049],
            [71.631696324000075, 34.536785130000055],
            [71.630768335000084, 34.536820822000038],
            [71.629733269000042, 34.536856514000078],
            [71.62909081500004, 34.536856514000078],
            [71.628591128000039, 34.536856514000078],
            [71.627984365000032, 34.536535287000049],
            [71.627663138000059, 34.536249751000071],
            [71.627127759000075, 34.535964216000025],
            [71.626913608000052, 34.535321762000024],
            [71.626663765000046, 34.534822075000079],
            [71.626663765000046, 34.534179620000032],
            [71.626485305000074, 34.53293040300008],
            [71.626485305000074, 34.532145181000033],
            [71.626485305000074, 34.531431343000065],
            [71.62673514800008, 34.53025351000008],
            [71.626949300000035, 34.528932909000048],
            [71.627163451000058, 34.527683692000039],
            [71.627306219000047, 34.52675570200006],
            [71.627306219000047, 34.525863404000063],
            [71.627270527000064, 34.525149566000039],
            [71.627056376000041, 34.524221576000059],
            [71.62673514800008, 34.52315081900008],
            [71.626413921000051, 34.522222829000043],
            [71.626128386000062, 34.521437607000053],
            [71.625450239000031, 34.52029546600005],
            [71.624879169000053, 34.519581627000036],
            [71.623772719000044, 34.518653638000046],
            [71.622523502000035, 34.51776134000005],
            [71.621417053000073, 34.516904734000036],
            [71.620489063000036, 34.516619198000058],
            [71.61963245700008, 34.516297971000029],
            [71.618454624000037, 34.515905360000033],
            [71.617098331000079, 34.51572690100005],
            [71.616206034000072, 34.51572690100005],
            [71.615278044000036, 34.515798284000027],
            [71.614207286000067, 34.51601243600004],
            [71.613422064000076, 34.516155204000029],
            [71.612743918000035, 34.516547815000024],
            [71.612101463000045, 34.517225961000065],
            [71.611387625000077, 34.518153951000045],
            [71.610816555000042, 34.519296092000047],
            [71.610245484000075, 34.519902855000055],
            [71.60953164600005, 34.520366849000027],
            [71.608853499000077, 34.520509617000073],
            [71.60792550900004, 34.520331158000033],
            [71.606783368000038, 34.520009930000072],
            [71.60606953000007, 34.519724395000026],
            [71.605320000000063, 34.519403168000053],
            [71.604927389000068, 34.518903481000052],
            [71.604177858000071, 34.518189643000028],
            [71.603785247000076, 34.517582880000077],
            [71.602750182000079, 34.516904734000036],
            [71.601679424000054, 34.516369355000052],
            [71.600323132000028, 34.515976744000056],
            [71.598609920000058, 34.515441365000072],
            [71.597432087000072, 34.515227214000049],
            [71.596575481000059, 34.514834603000054],
            [71.595504723000033, 34.514334916000053],
            [71.594255506000081, 34.514049381000063],
            [71.593006289000073, 34.513763845000028],
            [71.591649997000047, 34.513656770000068],
            [71.590793391000034, 34.513656770000068],
            [71.590150936000043, 34.513406926000073],
            [71.589508482000042, 34.513085699000044],
            [71.588901719000035, 34.512550320000059],
            [71.588473416000056, 34.512086325000041],
            [71.587474043000043, 34.510837108000032],
            [71.586688820000063, 34.510123270000065],
            [71.586046366000062, 34.509552199000041],
            [71.585261144000071, 34.508909745000039],
            [71.584368846000075, 34.508695593000027],
            [71.583726392000074, 34.508374366000055],
            [71.583226705000072, 34.50798175500006],
            [71.582727018000071, 34.507696220000071],
            [71.582542343000057, 34.507592340000031],
            [71.582155947000047, 34.507374993000042],
            [71.581691952000028, 34.507410685000025],
            [71.581406417000039, 34.507803296000077],
            [71.581299341000033, 34.508374366000055],
            [71.581049498000084, 34.509159588000045],
            [71.580978114000061, 34.509873427000059],
            [71.580728271000055, 34.510622957000066],
            [71.580264276000037, 34.51144387100004],
            [71.580050124000081, 34.51222909300003],
            [71.579693205000069, 34.512978623000038],
            [71.579157826000028, 34.514013689000024],
            [71.578336912000054, 34.515048754000077],
            [71.57765876600007, 34.515833976000067],
            [71.57687354400008, 34.51679765800003],
            [71.576088322000032, 34.517404421000037],
            [71.575481559000082, 34.518189643000028],
            [71.574232342000073, 34.51936747600007],
            [71.573447120000083, 34.520081314000038],
            [71.572626206000052, 34.520973612000034],
            [71.572055135000028, 34.521616067000025],
            [71.571091454000054, 34.522579748000055],
            [71.57037761600003, 34.52315081900008],
            [71.569663777000073, 34.523472046000052],
            [71.568485944000031, 34.523650506000024],
            [71.567593646000034, 34.523793273000024],
            [71.566986884000073, 34.524114501000042],
            [71.566487197000072, 34.524649879000037],
            [71.566058894000037, 34.525399409000045],
            [71.565452131000029, 34.526291707000041],
            [71.565059520000034, 34.526934162000032],
            [71.564417066000033, 34.527648000000056],
            [71.563738919000059, 34.528254763000064],
            [71.562882313000046, 34.529004293000071],
            [71.56170448000006, 34.529753823000078],
            [71.560705107000047, 34.530324894000046],
            [71.559455890000038, 34.531324267000059],
            [71.557742678000068, 34.532859020000046],
            [71.556386385000053, 34.534072545000072],
            [71.555279936000034, 34.53464361500005],
            [71.554530406000083, 34.535107610000068],
            [71.553709491000063, 34.53542883700004],
            [71.552852886000039, 34.53560729700007],
            [71.552067663000059, 34.536071292000031],
            [71.551603669000031, 34.536570979000032],
            [71.551032598000063, 34.53735620100008],
            [71.550568603000045, 34.53814142300007],
            [71.55017599200005, 34.538712494000038],
            [71.549783381000054, 34.539176488000066],
            [71.54921231000003, 34.539640483000028],
            [71.548177245000034, 34.540175862000069],
            [71.547284947000037, 34.540604165000047],
            [71.546071422000068, 34.541282311000032],
            [71.544786513000076, 34.542495836000057],
            [71.544108366000046, 34.543316750000031],
            [71.543323144000055, 34.54428043200005],
            [71.54293053300006, 34.545137038000064],
            [71.542752074000077, 34.545922260000054],
            [71.542716382000037, 34.547278553000069],
            [71.542859149000037, 34.548313618000066],
            [71.543037609000066, 34.549634219000041],
            [71.543287452000072, 34.550954820000072],
            [71.543430220000062, 34.552096961000075],
            [71.543465912000045, 34.553774481000062],
            [71.543465912000045, 34.554880931000071],
            [71.543465912000045, 34.556058764000056],
            [71.543501604000085, 34.557165213000076],
            [71.543537296000068, 34.558057511000072],
            [71.543501604000085, 34.559199652000075],
            [71.543501604000085, 34.560056258000031],
            [71.543323144000055, 34.560591637000073],
            [71.543001917000083, 34.561269783000057],
            [71.542716382000037, 34.561876546000065],
            [71.542573614000048, 34.562661768000055],
            [71.542573614000048, 34.563339914000039],
            [71.54278776600006, 34.564446364000048],
            [71.542894841000077, 34.566123884000035],
            [71.542894841000077, 34.56748017700005],
            [71.542966225000043, 34.569193388000031],
            [71.543144685000073, 34.570442605000039],
            [71.543216069000039, 34.571441979000042],
            [71.543216069000039, 34.572477045000028],
            [71.543216069000039, 34.573262267000075],
            [71.543001917000083, 34.574011797000026],
            [71.542609306000031, 34.574654251000027],
            [71.542216695000036, 34.575225322000051],
            [71.541467165000029, 34.576117620000048],
            [71.540646251000055, 34.576974226000061],
            [71.53943272600003, 34.577902216000041],
            [71.53850473600005, 34.578544670000042],
            [71.53754105400003, 34.579187124000043],
            [71.536969984000052, 34.579758195000068],
            [71.536256145000038, 34.580329266000035],
            [71.536041994000072, 34.580828953000037],
            [71.535756459000083, 34.581435715000055],
            [71.535756459000083, 34.582006786000079],
            [71.53568507500006, 34.583505846000037],
            [71.535363847000042, 34.584683679000079],
            [71.53504262000007, 34.586147048000043],
            [71.534542933000068, 34.587503341000058],
            [71.534007555000073, 34.588859633000027],
            [71.533543560000055, 34.589823315000046],
            [71.533079565000037, 34.590858380000043],
            [71.532758338000065, 34.591786370000079],
            [71.532544186000052, 34.59257159200007],
            [71.532508494000069, 34.593321123000067],
            [71.532508494000069, 34.593999269000051],
            [71.532829722000031, 34.595034334000047],
            [71.533400792000066, 34.596426319000045],
            [71.533757711000078, 34.598317991000044],
            [71.53393617100005, 34.59967428300007],
            [71.534257398000079, 34.60142318700008],
            [71.534471550000035, 34.602993631000061],
            [71.534400166000069, 34.604314232000036],
            [71.534328782000046, 34.605563449000044],
            [71.53393617100005, 34.607276661000071],
            [71.533614944000078, 34.609560944000066],
            [71.533400792000066, 34.610845853000058],
            [71.532901105000064, 34.61309444300008],
            [71.532508494000069, 34.614950423000039],
            [71.53222295900008, 34.617091938000044],
            [71.53222295900008, 34.61844823000007],
            [71.53222295900008, 34.620125750000057],
            [71.53222295900008, 34.621517735000054],
            [71.532080191000034, 34.622410033000051],
            [71.531509121000056, 34.623338023000031],
            [71.530723899000066, 34.624158937000061],
            [71.529902985000035, 34.624979851000035],
            [71.529117762000055, 34.62555092100007],
            [71.528546692000077, 34.625943532000065],
            [71.528118389000042, 34.62633614300006],
            [71.528082697000059, 34.626800138000078],
            [71.528082697000059, 34.627335517000063],
            [71.528332540000065, 34.628120739000053],
            [71.52872515100006, 34.628691810000078],
            [71.52951037400004, 34.629084421000073],
            [71.530938050000032, 34.629619800000057],
            [71.53222295900008, 34.629905335000046],
            [71.534186014000056, 34.630119486000069],
            [71.535328156000048, 34.630190870000035],
            [71.536434605000068, 34.630262254000058],
            [71.537291211000081, 34.630405022000048],
            [71.537862281000059, 34.630833325000026],
            [71.539397034000046, 34.632082542000035],
            [71.540967478000084, 34.633652986000072],
            [71.542288079000059, 34.634937895000064],
            [71.543180377000056, 34.636008652000044],
            [71.543822831000057, 34.637150793000046],
            [71.544715129000053, 34.638078783000026],
            [71.545536043000084, 34.638435702000038],
            [71.546285573000034, 34.639220925000075],
            [71.54749909800006, 34.640755677000072],
            [71.549355078000076, 34.643254111000033],
            [71.551889204000076, 34.646966070000076],
            [71.553138421000028, 34.64857220600004],
            [71.554209178000065, 34.649535888000059],
            [71.555422703000033, 34.650285418000067],
            [71.556636228000059, 34.650999256000034],
            [71.557956829000034, 34.651463251000052],
            [71.55902758700006, 34.651891554000031],
            [71.559919885000056, 34.652355549000049],
            [71.560847874000046, 34.653426306000028],
            [71.560919258000069, 34.654104453000059],
            [71.560919258000069, 34.654925367000033],
            [71.56063372300008, 34.655639205000057],
            [71.559955577000039, 34.656602887000076],
            [71.559420198000055, 34.65742380100005],
            [71.558670668000048, 34.658316099000047],
            [71.557921137000051, 34.659493932000032],
            [71.556850380000071, 34.66106437600007],
            [71.556136542000047, 34.662277901000039],
            [71.55524424400005, 34.663669886000037],
            [71.554815941000072, 34.664776335000056],
            [71.55460178900006, 34.665775709000059],
            [71.554637481000043, 34.667132002000073],
            [71.554351946000054, 34.668274143000076],
            [71.553995027000042, 34.669380592000039],
            [71.553423956000074, 34.670237198000052],
            [71.552888577000033, 34.670986728000059],
            [71.552103355000042, 34.67177195000005],
            [71.551282441000069, 34.672521481000047],
            [71.550604295000028, 34.672914092000042],
            [71.549510776000034, 34.673426679000045],
            [71.549462154000082, 34.673449470000037],
            [71.547784634000038, 34.673699314000032],
            [71.546713876000069, 34.673984849000078],
            [71.545428967000078, 34.674591612000029],
            [71.544072675000052, 34.675126990000024],
            [71.543037609000066, 34.675519601000076],
            [71.541574241000035, 34.676090672000043],
            [71.53850473600005, 34.676697435000051],
            [71.536827216000063, 34.677161430000069],
            [71.535078312000053, 34.677375581000035],
            [71.532722646000082, 34.677625424000041],
            [71.531009434000055, 34.67794665200006],
            [71.529189146000078, 34.678089419000059],
            [71.527154707000079, 34.678089419000059],
            [71.525834106000048, 34.678089419000059],
            [71.524727657000028, 34.678196495000066],
            [71.523728283000082, 34.678089419000059],
            [71.522871677000069, 34.677910960000077],
            [71.522050763000038, 34.677518349000024],
            [71.520980006000059, 34.676947278000057],
            [71.520016324000039, 34.676090672000043],
            [71.51908833400006, 34.67523406600003],
            [71.518374496000035, 34.674555920000046],
            [71.517910501000074, 34.674270384000067],
            [71.517268047000073, 34.674127617000067],
            [71.516161598000053, 34.673984849000078],
            [71.514912381000045, 34.674056233000044],
            [71.513913007000042, 34.674199001000034],
            [71.513199169000075, 34.674270384000067],
            [71.512663790000033, 34.674056233000044],
            [71.511628724000047, 34.67352085400006],
            [71.51073642700004, 34.672735632000069],
            [71.509344442000042, 34.671486415000061],
            [71.508523528000069, 34.67066550100003],
            [71.507952457000044, 34.670165814000029],
            [71.507524154000066, 34.669773203000034],
            [71.507095851000031, 34.669594744000051],
            [71.50648908900007, 34.669523360000028],
            [71.505703867000079, 34.669523360000028],
            [71.504526033000047, 34.669559052000068],
            [71.503490968000051, 34.669559052000068],
            [71.502527286000031, 34.669380592000039],
            [71.501313761000063, 34.668987981000043],
            [71.500564231000055, 34.669023673000027],
            [71.500028852000071, 34.669023673000027],
            [71.499564857000053, 34.669059365000066],
            [71.498958095000035, 34.669059365000066],
            [71.498137181000061, 34.669023673000027],
            [71.497280575000048, 34.668559678000065],
            [71.494675065000081, 34.667274769000073],
            [71.491284333000067, 34.665204638000034],
            [71.489820965000035, 34.664276648000055],
            [71.487786526000036, 34.662991740000052],
            [71.485537935000082, 34.661635447000037],
            [71.484288718000073, 34.660921608000024],
            [71.483325036000053, 34.660279154000079],
            [71.482575506000046, 34.659708083000055],
            [71.481968743000039, 34.659351164000043],
            [71.481219213000031, 34.658958553000048],
            [71.480362607000075, 34.658887169000025],
            [71.479363234000061, 34.659208397000043],
            [71.47779279000008, 34.659529624000072],
            [71.476864800000044, 34.659636700000078],
            [71.475829734000058, 34.659850851000044],
            [71.473902371000065, 34.66010069400005],
            [71.472117775000072, 34.659993619000034],
            [71.470725790000074, 34.659743775000038],
            [71.469940568000084, 34.659493932000032],
            [71.469298114000082, 34.659279780000077],
            [71.468798427000081, 34.659493932000032],
            [71.467834745000062, 34.66010069400005],
            [71.466942448000054, 34.661135760000036],
            [71.465800306000062, 34.662313593000079],
            [71.464551089000054, 34.663277275000041],
            [71.46358740800008, 34.663991113000066],
            [71.462659418000044, 34.664562184000033],
            [71.461267433000046, 34.665097562000028],
            [71.459804065000071, 34.665668633000053],
            [71.458804691000068, 34.666239704000077],
            [71.458162237000067, 34.666989234000027],
            [71.457912393000072, 34.668345527000042],
            [71.457591166000043, 34.669380592000039],
            [71.456841636000036, 34.670951036000076],
            [71.455949338000039, 34.672450097000024],
            [71.454914272000053, 34.673627930000066],
            [71.453807823000034, 34.674377460000073],
            [71.452415838000036, 34.675126990000024],
            [71.450310015000071, 34.675698061000048],
            [71.448382652000078, 34.675983596000037],
            [71.446098369000083, 34.676304824000056],
            [71.443492860000049, 34.676483283000039],
            [71.441743956000039, 34.676483283000039],
            [71.440566123000053, 34.676483283000039],
            [71.43985228400004, 34.676518975000079],
            [71.439174138000055, 34.676304824000056],
            [71.438745835000077, 34.676090672000043],
            [71.438388916000065, 34.675519601000076],
            [71.438246148000076, 34.674877147000075],
            [71.438067689000036, 34.674234693000074],
            [71.437889229000064, 34.673806390000038],
            [71.437746462000064, 34.67337808700006],
            [71.437282467000045, 34.672985476000065],
            [71.436461553000072, 34.672807016000036],
            [71.434320038000067, 34.672485789000064],
            [71.433070821000058, 34.672307329000034],
            [71.431357609000031, 34.671986102000062],
            [71.429537321000055, 34.671986102000062],
            [71.427717034000068, 34.672093178000068],
            [71.426467817000059, 34.672200253000028],
            [71.425611211000046, 34.67259286500007],
            [71.424790297000072, 34.673128243000065],
            [71.424147842000082, 34.673413779000043],
            [71.422648782000067, 34.673592238000026],
            [71.421042646000046, 34.673592238000026],
            [71.418972515000064, 34.673699314000032],
            [71.417758989000049, 34.673735006000072],
            [71.416759616000036, 34.673770698000055],
            [71.416259929000034, 34.673770698000055],
            [71.415831626000056, 34.673592238000026],
            [71.415617475000033, 34.672985476000065],
            [71.415189172000055, 34.671593491000067],
            [71.414867944000036, 34.670808269000077],
            [71.414261182000075, 34.669737511000051],
            [71.413226116000033, 34.668916597000077],
            [71.412226743000076, 34.668381219000025],
            [71.411370137000063, 34.66816706700007],
            [71.410799066000038, 34.66816706700007],
            [71.410406455000043, 34.668059991000064],
            [71.410013844000048, 34.667703072000052],
            [71.409478465000063, 34.667381845000079],
            [71.408443400000067, 34.666953542000044],
            [71.407301259000064, 34.666703699000038],
            [71.405766506000077, 34.666525239000066],
            [71.404767133000064, 34.666489547000026],
            [71.404017602000067, 34.66641816300006],
            [71.40312530500006, 34.666168320000054],
            [71.40234008200008, 34.665668633000053],
            [71.401483476000067, 34.665061871000034],
            [71.400198568000064, 34.664240957000061],
            [71.398913659000073, 34.663420042000041],
            [71.397129063000079, 34.662456361000068],
            [71.394487861000073, 34.660921608000024],
            [71.393238644000064, 34.660136386000033],
            [71.392167887000028, 34.659458240000049],
            [71.391382665000037, 34.659101321000037],
            [71.390668826000081, 34.658851477000042],
            [71.389812220000067, 34.658780094000065],
            [71.38891992300006, 34.658780094000065],
            [71.387206711000033, 34.659029937000071],
            [71.386100261000081, 34.65917270500006],
            [71.385386423000057, 34.659244089000026],
            [71.384922428000039, 34.659244089000026],
            [71.384422741000037, 34.658780094000065],
            [71.38370890300007, 34.658423174000063],
            [71.382959373000062, 34.657923488000051],
            [71.381567388000065, 34.657459493000033],
            [71.381032010000069, 34.657245341000078],
            [71.379854176000038, 34.657031190000055],
            [71.378283732000057, 34.656924114000049],
            [71.376820364000082, 34.656781346000059],
            [71.375749606000056, 34.656602887000076],
            [71.375107152000055, 34.65631735200003],
            [71.374643157000037, 34.656031816000052],
            [71.374179162000075, 34.655639205000057],
            [71.373643783000034, 34.654961059000073],
            [71.373322556000062, 34.65460414000006],
            [71.372323182000059, 34.654247220000059],
            [71.371430885000052, 34.653890301000047],
            [71.370117422000078, 34.65332993800007],
            [71.369118048000075, 34.652873082000042],
            [71.367861693000066, 34.652244904000042],
            [71.366148481000039, 34.651616726000043],
            [71.364806465000072, 34.651045656000065],
            [71.363578663000055, 34.650674460000062],
            [71.362779164000074, 34.650303264000058],
            [71.362093880000032, 34.650131943000076],
            [71.36143714800005, 34.649760747000073],
            [71.360666203000051, 34.649161123000056],
            [71.360009472000058, 34.648475838000024],
            [71.359438401000034, 34.647933321000039],
            [71.358952991000081, 34.647533571000054],
            [71.358239153000056, 34.647248036000065],
            [71.357325440000068, 34.646991054000068],
            [71.356211852000058, 34.646762626000054],
            [71.355440907000059, 34.646705519000079],
            [71.354727069000035, 34.64667696500004],
            [71.354213105000042, 34.64667696500004],
            [71.353470713000036, 34.646791180000037],
            [71.352671214000054, 34.646991054000068],
            [71.35228391000004, 34.647218095000028],
            [71.35184316200008, 34.647476464000079],
            [71.350929449000034, 34.647933321000039],
            [71.349844415000064, 34.648447284000042],
            [71.348816488000068, 34.64890414100006],
            [71.347874221000041, 34.649360997000031],
            [71.347188936000066, 34.649675086000059],
            [71.346732080000038, 34.649932068000055],
            [71.346389437000084, 34.650303264000058],
            [71.346218116000045, 34.650731567000037],
            [71.346218116000045, 34.65124553000004],
            [71.346075349000046, 34.65158817300005],
            [71.345961134000049, 34.651959369000053],
            [71.345561385000053, 34.652273458000025],
            [71.345047421000061, 34.652473332000056],
            [71.344048048000047, 34.65270176100006],
            [71.343077228000084, 34.652873082000042],
            [71.341906533000042, 34.652958742000067],
            [71.340907159000039, 34.652987296000049],
            [71.339936339000076, 34.653015849000042],
            [71.339193947000069, 34.653158617000031],
            [71.338537216000077, 34.65332993800007],
            [71.337851931000046, 34.653558367000073],
            [71.337138093000078, 34.65381534800008],
            [71.33633859400004, 34.654272205000041],
            [71.335567649000041, 34.654729061000069],
            [71.33462538200007, 34.655328685000029],
            [71.33399720500006, 34.655699881000032],
            [71.333226259000071, 34.656128184000067],
            [71.332369653000057, 34.656270952000057],
            [71.331598708000058, 34.656270952000057],
            [71.33114185200003, 34.656328059000032],
            [71.330627888000038, 34.656499380000071],
            [71.330285246000074, 34.656727809000074],
            [71.329371533000028, 34.656813469000042],
            [71.328629141000079, 34.656899130000056],
            [71.327601214000083, 34.65712755800007],
            [71.32660184000008, 34.657213219000027],
            [71.325745234000067, 34.657241772000077],
            [71.32500284200006, 34.657384540000066],
            [71.324260450000054, 34.657555861000048],
            [71.323775040000044, 34.657612968000024],
            [71.323346737000065, 34.657612968000024],
            [71.322889881000037, 34.657384540000066],
            [71.322404471000084, 34.656984790000024],
            [71.322388234000073, 34.65696753900005],
            [71.321947614000067, 34.656499380000071],
            [71.32169063300006, 34.656213845000025],
            [71.321433651000063, 34.655842649000078],
            [71.321119562000035, 34.655557114000032],
            [71.320748366000032, 34.655357239000068],
            [71.320377170000029, 34.655300132000036],
            [71.319206475000044, 34.655300132000036],
            [71.317721692000077, 34.655442900000025],
            [71.316493890000061, 34.655614221000064],
            [71.315123320000055, 34.655728435000071],
            [71.313695644000063, 34.655814096000029],
            [71.312068092000061, 34.655899756000053],
            [71.310897398000066, 34.655985417000068],
            [71.309669596000049, 34.655871203000061],
            [71.308870097000067, 34.655871203000061],
            [71.308070598000029, 34.655899756000053],
            [71.307385313000054, 34.65601397000006],
            [71.306471600000066, 34.65615673800005],
            [71.305592327000056, 34.656383647000041],
            [71.305586441000059, 34.656385166000064],
            [71.304444300000057, 34.656585041000028],
            [71.303787568000075, 34.656842023000024],
            [71.302988069000037, 34.657070451000038],
            [71.302217124000038, 34.657156112000052],
            [71.301474732000031, 34.657298879000052],
            [71.300846555000078, 34.657355986000027],
            [71.300218377000078, 34.657355986000027],
            [71.299732967000068, 34.657355986000027],
            [71.299161896000044, 34.657070451000038],
            [71.298333844000069, 34.656756362000067],
            [71.297363024000049, 34.656213845000025],
            [71.296849060000056, 34.655814096000029],
            [71.29644931100006, 34.655671328000039],
            [71.295392830000083, 34.65552856000005],
            [71.293993707000084, 34.655328685000029],
            [71.293022887000063, 34.654957490000072],
            [71.292052067000043, 34.654357865000065],
            [71.290852819000065, 34.65332993800007],
            [71.290253194000059, 34.652673207000078],
            [71.289596463000066, 34.652159243000028],
            [71.289082500000063, 34.651873708000039],
            [71.288625643000046, 34.651702387000057],
            [71.288054572000078, 34.651645280000025],
            [71.287654823000082, 34.651645280000025],
            [71.287226520000047, 34.651759494000032],
            [71.286684003000062, 34.651845155000046],
            [71.286055825000062, 34.652187797000067],
            [71.285113559000081, 34.652815975000067],
            [71.284371167000074, 34.653358492000052],
            [71.28365732900005, 34.65392956200003],
            [71.282943490000036, 34.654472080000062],
            [71.282229652000069, 34.65490038300004],
            [71.281630028000052, 34.655271578000054],
            [71.281116064000059, 34.655500007000057],
            [71.280202351000071, 34.655699881000032],
            [71.27937429900004, 34.655814096000029],
            [71.278489139000044, 34.655814096000029],
            [71.277775301000077, 34.655785542000046],
            [71.276604606000035, 34.655671328000039],
            [71.275519572000064, 34.65538579300005],
            [71.274291770000048, 34.655214471000079],
            [71.27309252200007, 34.655014597000047],
            [71.271779059000039, 34.654814722000026],
            [71.270751132000044, 34.654729061000069],
            [71.270180062000065, 34.654729061000069],
            [71.269723205000048, 34.654786168000044],
            [71.269323456000052, 34.655014597000047],
            [71.269037920000073, 34.655300132000036],
            [71.269095027000049, 34.655785542000046],
            [71.269180688000063, 34.656213845000025],
            [71.269237795000038, 34.656813469000042],
            [71.269237795000038, 34.657270326000059],
            [71.269095027000049, 34.657698629000038],
            [71.269009367000081, 34.658184039000048],
            [71.268980813000042, 34.658640895000076],
            [71.268980813000042, 34.659126305000029],
            [71.269037920000073, 34.659697376000054],
            [71.26915213500007, 34.660239893000039],
            [71.26963754500008, 34.660839517000056],
            [71.270065848000058, 34.661353481000049],
            [71.270208615000058, 34.661895998000034],
            [71.270265722000033, 34.662467068000069],
            [71.270265722000033, 34.663152353000044],
            [71.270294276000072, 34.663609210000061],
            [71.270494151000037, 34.664094620000071],
            [71.27100811400004, 34.664608583000074],
            [71.271379310000043, 34.665465189000031],
            [71.271721952000064, 34.666321795000044],
            [71.271864720000053, 34.667007080000076],
            [71.272207362000074, 34.668063561000054],
            [71.272435791000078, 34.668977274000042],
            [71.272664219000035, 34.670119415000045],
            [71.272749880000049, 34.670918914000026],
            [71.272778433000042, 34.671547091000036],
            [71.273006861000056, 34.672660679000046],
            [71.273263843000052, 34.673717160000024],
            [71.273378057000059, 34.674402445000055],
            [71.273435164000034, 34.675259051000069],
            [71.273435164000034, 34.675744461000079],
            [71.273406611000041, 34.676029996000068],
            [71.27309252200007, 34.676344085000039],
            [71.272550005000028, 34.676515406000078],
            [71.271921827000028, 34.67668672700006],
            [71.271407864000082, 34.67682949500005],
            [71.270893900000033, 34.677029369000024],
            [71.27037993600004, 34.677172137000071],
            [71.269865973000037, 34.67745767200006],
            [71.269466223000052, 34.677743208000038],
            [71.269352009000045, 34.678028743000027],
            [71.268980813000042, 34.678885349000041],
            [71.268752385000084, 34.679770508000047],
            [71.268466850000038, 34.680969757000071],
            [71.268124207000028, 34.681912023000052],
            [71.267952886000046, 34.682511648000059],
            [71.267810119000046, 34.682939950000048],
            [71.267524583000068, 34.68345391400004],
            [71.267210494000039, 34.683853664000026],
            [71.266867852000075, 34.684196306000047],
            [71.266068353000037, 34.685167126000067],
            [71.265411622000045, 34.685880964000035],
            [71.26472633700007, 34.686594803000048],
            [71.264183820000028, 34.687337194000065],
            [71.26312733900005, 34.688165247000029],
            [71.260614629000031, 34.689907012000049],
            [71.259244059000082, 34.690763618000062],
            [71.257645061000062, 34.692019974000061],
            [71.256531474000042, 34.693047901000057],
            [71.255474993000064, 34.694075828000052],
            [71.254161531000079, 34.695246523000037],
            [71.252334104000056, 34.697188163000078],
            [71.251134856000078, 34.698415965000038],
            [71.250135483000065, 34.699186910000037],
            [71.249364537000076, 34.699643766000065],
            [71.248707806000084, 34.700271944000065],
            [71.247280129000046, 34.701699621000046],
            [71.246252202000051, 34.702927423000062],
            [71.245395596000037, 34.703841136000051],
            [71.244995847000041, 34.704554974000075],
            [71.244795972000077, 34.705126045000043],
            [71.244767419000084, 34.705668562000028],
            [71.244910186000084, 34.706468060000077],
            [71.245652578000033, 34.707353220000073],
            [71.245881006000047, 34.707810076000044],
            [71.24590956000003, 34.708324040000036],
            [71.245795346000079, 34.708838004000029],
            [71.245367043000044, 34.709351967000032],
            [71.244653205000077, 34.709837377000042],
            [71.243054207000057, 34.711236500000041],
            [71.242026280000061, 34.712264427000036],
            [71.240998352000076, 34.713206694000064],
            [71.240056086000038, 34.714006193000046],
            [71.238399981000043, 34.715433869000037],
            [71.23677243000003, 34.71691865300005],
            [71.235516074000031, 34.718003687000078],
            [71.234031291000065, 34.719402810000076],
            [71.232546507000052, 34.720659166000075],
            [71.230976063000071, 34.722058289000074],
            [71.229919582000036, 34.722772127000042],
            [71.228977316000055, 34.723371751000059],
            [71.228035049000084, 34.723885715000051],
            [71.227349764000053, 34.724199804000079],
            [71.226607373000036, 34.724399678000054],
            [71.225836427000047, 34.724456785000029],
            [71.225265357000069, 34.724456785000029],
            [71.224779947000059, 34.724456785000029],
            [71.22435164400008, 34.724285464000047],
            [71.223437931000035, 34.723600179000073],
            [71.223385172000064, 34.723555537000038],
            [71.222695539000028, 34.722972002000063],
            [71.221896040000047, 34.722172503000024],
            [71.221039434000033, 34.721144576000029],
            [71.220211382000059, 34.720030988000076],
            [71.218755151000039, 34.718374883000024],
            [71.218041313000072, 34.717346956000029],
            [71.216956279000044, 34.716347582000026],
            [71.215956905000041, 34.715376762000062],
            [71.215328728000031, 34.714891352000052],
            [71.21501463900006, 34.714377389000049],
            [71.214814764000039, 34.713720657000067],
            [71.214643443000057, 34.712835498000061],
            [71.214557782000043, 34.711779017000026],
            [71.214215140000078, 34.710693983000056],
            [71.213729730000068, 34.709723163000035],
            [71.213329980000083, 34.708923664000054],
            [71.212559035000083, 34.708466808000026],
            [71.210703056000057, 34.706896363000055],
            [71.209046951000062, 34.705640008000046],
            [71.208418773000062, 34.705040384000029],
            [71.207819149000045, 34.704926170000078],
            [71.207219525000085, 34.704983277000053],
            [71.20567763400004, 34.705554347000032],
            [71.204249957000059, 34.705925543000035],
            [71.203222030000063, 34.705954097000074],
            [71.200794980000069, 34.706153972000038],
            [71.199367303000031, 34.706239632000063],
            [71.197340003000079, 34.706582275000073],
            [71.195826666000073, 34.706810703000031],
            [71.194170561000078, 34.707324666000034],
            [71.192343135000044, 34.708181272000047],
            [71.190829798000038, 34.709037878000061],
            [71.189059479000036, 34.71029423400006],
            [71.187032178000038, 34.711836124000058],
            [71.184833556000058, 34.71360644300006],
            [71.18329166500007, 34.714805692000027],
            [71.181863989000078, 34.715919279000047],
            [71.180921722000051, 34.716832992000036],
            [71.179608260000066, 34.718945954000048],
            [71.178665993000038, 34.720773380000026],
            [71.178094922000071, 34.722058289000074],
            [71.176924228000075, 34.72417125000004],
            [71.17526812300008, 34.726912389000063],
            [71.173809023000047, 34.728997901000071],
            [71.172818000000063, 34.730916740000055],
            [71.184184000000073, 34.737716000000034],
            [71.199930000000052, 34.747104000000036],
            [71.211504000000048, 34.748572000000024],
            [71.223952000000054, 34.75351900000004],
            [71.224517475000084, 34.753900010000052],
            [71.224990061000028, 34.753335033000042],
            [71.225002840000059, 34.753353566000044],
            [71.224535322000065, 34.753912035000042],
            [71.224618845000066, 34.75396831200004],
            [71.224653399000033, 34.75399159400007],
            [71.234531000000061, 34.760647000000063],
            [71.240428000000065, 34.780959000000053],
            [71.254290000000083, 34.792850000000044],
            [71.264016000000083, 34.795374000000038],
            [71.272720124000045, 34.800596475000077],
            [71.274358611000082, 34.800699918000078],
            [71.272886993000043, 34.80069659600008],
            [71.273977069000068, 34.801350642000045],
            [71.274093583000081, 34.801420550000046],
            [71.280396000000053, 34.805202000000065],
            [71.289569000000029, 34.821583000000032],
            [71.292781000000048, 34.836034549000033],
            [71.293501000000049, 34.839274000000046],
            [71.294811000000038, 34.85041300000006],
            [71.30201900000003, 34.868104000000073],
            [71.311192000000062, 34.879242000000033],
            [71.324296000000061, 34.887105000000076],
            [71.340677000000028, 34.89955400000008],
            [71.35709200000008, 34.911790000000053],
            [71.375391000000036, 34.914480000000026],
            [71.395304000000067, 34.919323000000077],
            [71.416027000000042, 34.927073000000064],
            [71.42803069200005, 34.93352757100007],
            [71.428163278000056, 34.933598865000079],
            [71.428645795000079, 34.93304746900003],
            [71.428169810000043, 34.933602377000057],
            [71.429430000000082, 34.934280000000058],
            [71.445358000000056, 34.946232000000066],
            [71.464733000000081, 34.949999000000048],
            [71.480980000000045, 34.954702000000054],
            [71.491643000000067, 34.96237700000006],
            [71.502946000000065, 34.963453000000072],
            [71.505137000000047, 34.971628000000067],
            [71.50769125000005, 34.98120668200005],
            [71.510045921000028, 34.979791782000063],
            [71.526511000000085, 34.967808000000048],
            [71.543739000000073, 34.954556000000025],
            [71.556992000000037, 34.941965000000039],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "44",
      properties: { name: "FR Bannu", count: 1470 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.627969000000064, 33.261118452000062],
            [70.627969000000064, 33.256868000000054],
            [70.628112793000071, 33.256897963000029],
            [70.628238959000043, 33.256924252000033],
            [70.643920898000033, 33.260314941000047],
            [70.657897949000073, 33.255676270000038],
            [70.674499512000068, 33.254272461000028],
            [70.692321777000075, 33.254272461000028],
            [70.679077148000033, 33.244873047000056],
            [70.682312012000068, 33.235717773000033],
            [70.69171142600004, 33.228515625000057],
            [70.702270508000083, 33.225708008000026],
            [70.703254185000048, 33.218773087000045],
            [70.703510000000051, 33.217110000000048],
            [70.716763000000071, 33.217110000000048],
            [70.739293000000032, 33.213134000000025],
            [70.756921533000082, 33.215023021000036],
            [70.757507142000065, 33.215087871000037],
            [70.757507000000032, 33.215086000000042],
            [70.757847000000083, 33.215122000000065],
            [70.771762000000081, 33.215785000000039],
            [70.781039000000078, 33.207170000000076],
            [70.790092597000069, 33.209076210000035],
            [70.793630000000064, 33.209821000000034],
            [70.809533000000044, 33.209821000000034],
            [70.824111000000073, 33.209821000000034],
            [70.838027000000068, 33.201869000000045],
            [70.851942000000065, 33.193917000000056],
            [70.869171000000051, 33.189279000000056],
            [70.872484000000043, 33.183978000000025],
            [70.872484000000043, 33.168737000000078],
            [70.867133006000074, 33.165392993000069],
            [70.856580000000065, 33.158798000000047],
            [70.839352000000076, 33.153496000000075],
            [70.825436000000082, 33.134280000000047],
            [70.820798000000082, 33.117714000000035],
            [70.804894000000047, 33.118376000000069],
            [70.794292000000041, 33.111750000000029],
            [70.794955000000073, 33.096509000000026],
            [70.787351000000058, 33.092365000000029],
            [70.761713773000054, 33.078379265000024],
            [70.736642000000074, 33.064702000000068],
            [70.722245998000062, 33.059423442000025],
            [70.716763000000071, 33.057413000000054],
            [70.697810247000064, 33.057413000000054],
            [70.692908000000045, 33.057413000000054],
            [70.674354000000051, 33.054763000000037],
            [70.664638593000063, 33.046990967000056],
            [70.663762628000029, 33.046290222000039],
            [70.661101000000031, 33.044161000000031],
            [70.656632235000075, 33.044347023000057],
            [70.64519800000005, 33.044823000000065],
            [70.643444857000077, 33.044883485000071],
            [70.638929747000077, 33.045039259000077],
            [70.635685192000039, 33.045151199000031],
            [70.630304357000057, 33.045336841000051],
            [70.625981000000081, 33.045486000000039],
            [70.623170338000079, 33.047453379000046],
            [70.619355000000041, 33.050124000000039],
            [70.613541604000034, 33.050124000000039],
            [70.606765000000053, 33.050124000000039],
            [70.600784543000032, 33.046976541000049],
            [70.594175000000064, 33.043498000000056],
            [70.586339573000032, 33.042627397000047],
            [70.579068409000058, 33.04181949000008],
            [70.576283000000046, 33.041510000000073],
            [70.567115680000029, 33.043593368000074],
            [70.561705000000075, 33.044823000000065],
            [70.535200000000032, 33.054100000000062],
            [70.523272000000077, 33.053438000000028],
            [70.523272000000077, 33.048965088000045],
            [70.523272000000077, 33.046914000000072],
            [70.523272000000077, 33.030908000000068],
            [70.513995000000079, 33.017655000000047],
            [70.510361329000034, 33.01381966100007],
            [70.502067000000068, 33.005065000000059],
            [70.492305411000075, 33.000532496000062],
            [70.483513000000073, 32.996450000000038],
            [70.472911000000067, 32.984523000000024],
            [70.46164600000003, 32.974583000000052],
            [70.470923000000084, 32.959342000000049],
            [70.472222096000053, 32.951548080000066],
            [70.472911000000067, 32.947415000000035],
            [70.45899600000007, 32.938801000000069],
            [70.447731000000033, 32.930186000000049],
            [70.44663379900004, 32.928670922000038],
            [70.433815000000038, 32.910970000000077],
            [70.428970527000047, 32.902951257000041],
            [70.414599000000067, 32.879163000000062],
            [70.41099028800005, 32.872621912000056],
            [70.403997000000061, 32.859946000000036],
            [70.376828000000046, 32.82482600000003],
            [70.368877000000055, 32.800309000000027],
            [70.367551000000049, 32.780429000000026],
            [70.380141000000037, 32.774466000000075],
            [70.381618676000073, 32.76707873600003],
            [70.381237175000081, 32.767085936000058],
            [70.379757794000056, 32.76729305200007],
            [70.377645191000056, 32.767619609000064],
            [70.37650313100005, 32.767748647000076],
            [70.374939866000034, 32.767993128000057],
            [70.373755394000057, 32.768122939000079],
            [70.372065845000066, 32.76840560100004],
            [70.370327284000041, 32.768438266000032],
            [70.368293777000076, 32.768548127000031],
            [70.365536573000043, 32.768563995000079],
            [70.363242993000028, 32.768463596000061],
            [70.361426221000045, 32.768425919000038],
            [70.360197443000061, 32.768484747000059],
            [70.358416471000055, 32.76851804100005],
            [70.356417855000075, 32.768340322000029],
            [70.354211907000035, 32.768345649000025],
            [70.351876877000052, 32.768281658000035],
            [70.349925356000028, 32.768282169000031],
            [70.348356405000061, 32.768311373000074],
            [70.345438916000035, 32.768688205000046],
            [70.344002763000049, 32.768929938000042],
            [70.341482908000046, 32.769263450000039],
            [70.339786723000032, 32.769294908000063],
            [70.338726607000069, 32.76931455700003],
            [70.337705176000043, 32.769190113000036],
            [70.336980580000045, 32.769060165000042],
            [70.335638172000074, 32.768009759000051],
            [70.334376889000055, 32.766814470000043],
            [70.332978224000044, 32.765227447000029],
            [70.332109765000041, 32.764454977000071],
            [70.331030236000061, 32.763722241000039],
            [70.329146945000048, 32.763076018000049],
            [70.327480316000049, 32.762604980000049],
            [70.326029411000036, 32.762273312000048],
            [70.324494639000079, 32.761979015000065],
            [70.322720249000042, 32.762262579000037],
            [70.321365248000063, 32.762359195000045],
            [70.319671954000057, 32.762497858000074],
            [70.31797847100006, 32.762959077000062],
            [70.316793983000082, 32.763088353000057],
            [70.315776337000045, 32.76310703200005],
            [70.314755929000057, 32.763018227000032],
            [70.313682080000035, 32.762500293000073],
            [70.312902293000036, 32.761869434000062],
            [70.312498610000034, 32.761016629000039],
            [70.312052534000031, 32.760164599000063],
            [70.311362175000056, 32.759711302000028],
            [70.310498548000055, 32.759117807000052],
            [70.309493957000029, 32.757989259000055],
            [70.308317041000066, 32.75675633000003],
            [70.306898810000064, 32.754380864000041],
            [70.306178541000065, 32.753259688000071],
            [70.30593192200007, 32.752587091000066],
            [70.305467219000036, 32.751409843000033],
            [70.304909575000067, 32.750077694000026],
            [70.304568792000055, 32.749272208000036],
            [70.304320951000079, 32.748714565000057],
            [70.30348448500007, 32.747847119000028],
            [70.302586059000078, 32.746855752000045],
            [70.301563712000075, 32.745802425000079],
            [70.300572345000035, 32.744408315000044],
            [70.299240196000028, 32.742797344000053],
            [70.298062948000052, 32.741465195000046],
            [70.296390016000032, 32.739606382000034],
            [70.295088847000045, 32.738522075000049],
            [70.293694738000056, 32.736942084000077],
            [70.292052787000046, 32.73548601400006],
            [70.290658677000067, 32.734463667000057],
            [70.288923785000065, 32.733472300000074],
            [70.287622616000078, 32.732976617000077],
            [70.285175180000067, 32.73217113100003],
            [70.283099505000052, 32.731551527000079],
            [70.280930890000036, 32.731086824000045],
            [70.278721662000066, 32.730746942000053],
            [70.278514434000044, 32.730715061000069],
            [70.27575719500004, 32.730219378000072],
            [70.273154857000065, 32.73000251600007],
            [70.272145284000032, 32.730045174000054],
            [70.271832435000078, 32.729999584000041],
            [70.27068438200007, 32.729876887000046],
            [70.269285621000051, 32.729902056000071],
            [70.267293446000053, 32.729937877000054],
            [70.265597976000038, 32.729968338000049],
            [70.263690570000051, 32.730002580000075],
            [70.262207032000049, 32.730029192000075],
            [70.261930315000029, 32.730034153000076],
            [70.261390777000031, 32.730043825000052],
            [70.261390777000031, 32.730473490000065],
            [70.261499361000062, 32.730929542000069],
            [70.261825112000054, 32.731581045000041],
            [70.261977129000059, 32.732210831000032],
            [70.262281164000058, 32.732884051000042],
            [70.262498332000064, 32.733991606000075],
            [70.262824083000055, 32.735446629000023],
            [70.262954384000068, 32.73607641600006],
            [70.262997818000031, 32.737075387000061],
            [70.263128118000054, 32.737965774000031],
            [70.263280135000059, 32.738530410000067],
            [70.263519020000047, 32.739377364000063],
            [70.263736188000053, 32.740267752000079],
            [70.263823055000046, 32.742330845000026],
            [70.263823055000046, 32.743069215000048],
            [70.26401850600007, 32.743916169000045],
            [70.264105373000064, 32.744784839000033],
            [70.26432254000008, 32.745219175000045],
            [70.264539708000029, 32.745653510000068],
            [70.264691725000034, 32.74630501300004],
            [70.264735159000054, 32.747151967000036],
            [70.264583142000049, 32.74862870700008],
            [70.264409407000073, 32.749779696000076],
            [70.264409407000073, 32.75023574800008],
            [70.264431124000055, 32.750518066000041],
            [70.264865460000067, 32.750908968000033],
            [70.26592958100008, 32.751734205000048],
            [70.266950269000063, 32.752776610000069],
            [70.268209842000033, 32.753992749000076],
            [70.268991645000028, 32.755143738000072],
            [70.269968900000038, 32.756381593000071],
            [70.270294651000029, 32.756750778000026],
            [70.271771392000062, 32.757771467000055],
            [70.272422895000034, 32.758032068000034],
            [70.274551138000049, 32.759074473000055],
            [70.275571826000032, 32.759660825000026],
            [70.277178867000032, 32.760681513000065],
            [70.278112688000078, 32.761398167000038],
            [70.278829341000062, 32.762093103000041],
            [70.279328827000029, 32.76244057200006],
            [70.280284365000057, 32.76307035800005],
            [70.280914151000047, 32.763396109000041],
            [70.28197827200006, 32.763656711000067],
            [70.283607030000042, 32.763917312000046],
            [70.28484488600003, 32.764199630000064],
            [70.285887291000051, 32.764503664000074],
            [70.287972100000047, 32.765068300000053],
            [70.291251332000058, 32.766023838000024],
            [70.29229373700008, 32.766414740000073],
            [70.293705327000055, 32.767066243000045],
            [70.294921466000062, 32.767674313000043],
            [70.296050738000076, 32.76821723200004],
            [70.297201727000072, 32.768629850000025],
            [70.299134519000063, 32.76919448600006],
            [70.301697097000044, 32.769802556000059],
            [70.30289152000006, 32.770323758000075],
            [70.303933925000081, 32.770823244000042],
            [70.305193497000062, 32.771409597000059],
            [70.305671266000047, 32.771713631000068],
            [70.305931867000083, 32.772104533000061],
            [70.306474786000081, 32.772929770000076],
            [70.308060110000042, 32.775166597000066],
            [70.309189382000056, 32.776795355000047],
            [70.310774706000075, 32.77848926300004],
            [70.311795394000058, 32.77942308400003],
            [70.313967071000036, 32.780986691000066],
            [70.316399349000051, 32.782463431000053],
            [70.317832656000064, 32.783440686000063],
            [70.319374546000063, 32.784374507000052],
            [70.320373518000054, 32.785026010000024],
            [70.320916437000051, 32.785416912000073],
            [70.321459356000048, 32.785959831000071],
            [70.321806824000078, 32.786372450000044],
            [70.322045709000065, 32.786850218000041],
            [70.322349744000064, 32.787501721000069],
            [70.322805796000068, 32.788392109000029],
            [70.32313154700006, 32.789000178000038],
            [70.323544166000033, 32.78947794700008],
            [70.324716871000078, 32.790802670000062],
            [70.325411808000069, 32.791801641000063],
            [70.325824426000054, 32.792713746000061],
            [70.326193611000065, 32.793929885000068],
            [70.32667138000005, 32.79521117400003],
            [70.327040565000061, 32.796383879000075],
            [70.327713785000071, 32.798099504000049],
            [70.328017820000071, 32.799011608000058],
            [70.328604173000031, 32.800227747000065],
            [70.329125375000046, 32.801357019000079],
            [70.329994046000081, 32.802942343000041],
            [70.330319797000072, 32.803811014000075],
            [70.331014734000064, 32.805309471000044],
            [70.331253618000062, 32.805917540000053],
            [70.331601087000081, 32.806655911000064],
            [70.331948555000054, 32.80780689900007],
            [70.332274306000045, 32.808719003000078],
            [70.33275207500003, 32.81004372600006],
            [70.33314297700008, 32.810999264000031],
            [70.333229844000073, 32.811585617000048],
            [70.333294994000084, 32.812215403000039],
            [70.33342529500004, 32.813626993000071],
            [70.333599029000084, 32.814929999000071],
            [70.33372933000004, 32.815364334000037],
            [70.333946497000056, 32.816015837000066],
            [70.334250532000056, 32.817210260000024],
            [70.334880318000046, 32.818969318000029],
            [70.335336371000039, 32.820641509000041],
            [70.335531822000064, 32.821293012000069],
            [70.336205041000028, 32.822769752000056],
            [70.336769677000063, 32.824246492000043],
            [70.336899978000076, 32.825028296000028],
            [70.337160579000056, 32.826309585000047],
            [70.337725215000034, 32.827981776000058],
            [70.338116117000084, 32.829089332000024],
            [70.338420152000083, 32.830001436000032],
            [70.338832770000067, 32.830739806000054],
            [70.339419123000084, 32.832542298000078],
            [70.340092343000038, 32.833801870000059],
            [70.340287794000062, 32.834344789000056],
            [70.340374661000055, 32.834952859000055],
            [70.340591828000072, 32.836212431000035],
            [70.340765563000048, 32.837493721000044],
            [70.340917580000053, 32.838232091000066],
            [70.341156464000051, 32.839144195000074],
            [70.341373632000057, 32.839860848000058],
            [70.341721100000029, 32.841533039000069],
            [70.342025135000029, 32.842358277000073],
            [70.342112002000079, 32.842857762000051],
            [70.342242303000035, 32.843552699000043],
            [70.342372603000058, 32.843921884000054],
            [70.342611488000045, 32.844443086000069],
            [70.342958956000075, 32.844899138000073],
            [70.343176124000081, 32.845420341000079],
            [70.343349858000067, 32.846050127000069],
            [70.343415008000079, 32.846397595000042],
            [70.343610459000047, 32.846636480000029],
            [70.344044794000069, 32.84700566500004],
            [70.34441397900008, 32.84767888500005],
            [70.345260933000077, 32.848808157000065],
            [70.34602102000008, 32.849763694000046],
            [70.346368489000042, 32.850306614000033],
            [70.346477072000084, 32.850610648000043],
            [70.346477072000084, 32.850979833000054],
            [70.346477072000084, 32.851012408000031],
            [70.346498789000066, 32.851023267000073],
            [70.346515215000068, 32.851034560000073],
            [70.346781107000083, 32.85187022100007],
            [70.347063425000044, 32.852738892000048],
            [70.347476044000075, 32.853607562000036],
            [70.347780078000028, 32.855127736000043],
            [70.347910379000041, 32.85567065500004],
            [70.348018963000072, 32.85697366100004],
            [70.348170980000077, 32.859427656000037],
            [70.348605316000032, 32.863119506000032],
            [70.348757333000037, 32.865443201000062],
            [70.34893106700008, 32.86859213200006],
            [70.349126518000048, 32.869829988000049],
            [70.34982145500004, 32.872370849000049],
            [70.350234073000081, 32.873695572000031],
            [70.350690125000028, 32.874694544000079],
            [70.351059310000039, 32.876106134000054],
            [70.351363345000038, 32.877387423000073],
            [70.352079998000079, 32.879624250000063],
            [70.352514334000034, 32.881079273000068],
            [70.353165837000063, 32.88368528500007],
            [70.353860773000065, 32.88624786400004],
            [70.354403693000052, 32.888636708000035],
            [70.354577427000038, 32.889483662000032],
            [70.354686011000069, 32.891025553000077],
            [70.354838028000074, 32.892176542000072],
            [70.355011762000061, 32.894044184000052],
            [70.355076912000072, 32.896367878000035],
            [70.355250647000048, 32.89812693600004],
            [70.355228930000067, 32.899972861000037],
            [70.355532965000066, 32.902774324000063],
            [70.355793566000045, 32.904772267000055],
            [70.355967300000032, 32.906661626000073],
            [70.356054167000082, 32.908507551000071],
            [70.356119317000037, 32.909680256000058],
            [70.356336485000043, 32.910679228000049],
            [70.356857687000058, 32.911960517000068],
            [70.357574341000031, 32.913458974000037],
            [70.358660179000083, 32.915435200000047],
            [70.359224815000061, 32.916369021000037],
            [70.359854601000052, 32.917389709000076],
            [70.360310653000056, 32.917910911000035],
            [70.361157607000052, 32.918866449000063],
            [70.36237374600006, 32.919843704000073],
            [70.364067654000053, 32.921363877000033],
            [70.365457527000046, 32.922949201000051],
            [70.367346886000064, 32.925012294000055],
            [70.368389291000028, 32.926554185000043],
            [70.37006148200004, 32.928291526000066],
            [70.37140792200006, 32.930137451000064],
            [70.371798824000052, 32.930680371000051],
            [70.372363459000042, 32.931353590000072],
            [70.37271092800006, 32.93191822600005],
            [70.373427581000044, 32.932504579000067],
            [70.37368818200008, 32.932721747000073],
            [70.374013934000061, 32.933025782000072],
            [70.374491703000047, 32.933590418000051],
            [70.37457857000004, 32.933829302000049],
            [70.374535136000077, 32.934046470000055],
            [70.374231102000067, 32.93419848700006],
            [70.373861916000067, 32.934437371000058],
            [70.373709899000062, 32.934719689000076],
            [70.373557882000057, 32.93487170700007],
            [70.373543254000083, 32.934897712000065],
            [70.37381957100007, 32.935195284000031],
            [70.373914749000051, 32.935504614000024],
            [70.373914749000051, 32.936004301000025],
            [70.374152696000067, 32.936385015000042],
            [70.374937918000057, 32.937717513000052],
            [70.375961086000075, 32.939359341000056],
            [70.377983628000038, 32.942000543000063],
            [70.37995858000005, 32.944356209000034],
            [70.382314247000068, 32.946926027000075],
            [70.384408172000064, 32.948853390000068],
            [70.387096963000033, 32.951137673000062],
            [70.389190889000076, 32.953017447000036],
            [70.390713744000038, 32.954825837000044],
            [70.392260393000072, 32.956372487000067],
            [70.393735659000072, 32.957728780000025],
            [70.395758201000035, 32.959465786000067],
            [70.397495208000066, 32.960726901000044],
            [70.399684312000034, 32.962416318000066],
            [70.401587880000079, 32.963867789000062],
            [70.402634843000044, 32.96474819000008],
            [70.403348682000058, 32.965414439000028],
            [70.404609796000045, 32.966390018000027],
            [70.405133277000061, 32.967056267000032],
            [70.405537786000082, 32.967508364000025],
            [70.406537159000038, 32.968436354000062],
            [70.40689407800005, 32.969031219000044],
            [70.407298587000071, 32.969483317000027],
            [70.407417560000056, 32.969697468000049],
            [70.407417560000056, 32.970030593000047],
            [70.407060641000044, 32.970411307000063],
            [70.406441981000057, 32.970720637000056],
            [70.405513991000078, 32.970934788000079],
            [70.404919126000038, 32.971077556000068],
            [70.404276671000048, 32.971410680000076],
            [70.403800779000051, 32.971743805000074],
            [70.403158325000049, 32.972124519000033],
            [70.402349308000055, 32.972433849000026],
            [70.401849621000054, 32.972529027000064],
            [70.401207167000052, 32.97260041100003],
            [70.400612301000081, 32.972648000000049],
            [70.399922258000061, 32.972790768000038],
            [70.399256009000055, 32.972885946000076],
            [70.398423197000056, 32.973171481000065],
            [70.398113867000063, 32.97343322200004],
            [70.397709359000032, 32.973623579000048],
            [70.39701931500008, 32.973885320000079],
            [70.39632927200006, 32.974051882000026],
            [70.395353693000061, 32.974289828000053],
            [70.392831464000039, 32.975860272000034],
            [70.392260393000072, 32.976359959000035],
            [70.391689323000037, 32.976693084000033],
            [70.391023074000032, 32.977216565000049],
            [70.390689949000034, 32.977787636000073],
            [70.390499592000083, 32.978501474000041],
            [70.390499592000083, 32.979120134000027],
            [70.390499592000083, 32.979596026000024],
            [70.390499592000083, 32.980000534000055],
            [70.390547182000034, 32.980738167000027],
            [70.390547182000034, 32.981333033000055],
            [70.390547182000034, 32.981713746000025],
            [70.390594771000053, 32.982237228000031],
            [70.390594771000053, 32.982594147000043],
            [70.390594771000053, 32.98307003900004],
            [70.390785128000061, 32.983569726000042],
            [70.390927895000061, 32.983926645000054],
            [70.391094458000055, 32.984188386000028],
            [70.391332404000082, 32.984497716000078],
            [70.391522761000033, 32.985116375000075],
            [70.391951064000068, 32.985687446000043],
            [70.392284188000076, 32.98656784700006],
            [70.392498340000031, 32.98694856000003],
            [70.392783875000077, 32.987543426000059],
            [70.393212178000056, 32.988209675000064],
            [70.39361668600003, 32.988661772000057],
            [70.394068784000069, 32.989351816000067],
            [70.394616060000033, 32.989851503000068],
            [70.39509195200003, 32.990279806000046],
            [70.396091326000032, 32.991017439000075],
            [70.396947932000046, 32.991445742000053],
            [70.397328645000073, 32.991683688000023],
            [70.39780453700007, 32.99192163400005],
            [70.39849458100008, 32.99192163400005],
            [70.399303598000074, 32.992040607000035],
            [70.399636722000082, 32.992159580000077],
            [70.400374355000054, 32.992540294000037],
            [70.40099301500004, 32.992825829000026],
            [70.401873416000058, 32.993039980000049],
            [70.402420692000078, 32.993254132000061],
            [70.402801406000037, 32.993682435000039],
            [70.403086941000083, 32.994039354000051],
            [70.403420065000034, 32.994420068000068],
            [70.403539038000076, 32.994919755000069],
            [70.403610422000042, 32.995704977000059],
            [70.40384836800007, 32.996299842000042],
            [70.404419439000037, 32.996823323000058],
            [70.404895331000034, 32.997156448000055],
            [70.405442607000055, 32.997537162000071],
            [70.406037472000037, 32.997941670000046],
            [70.406679927000084, 32.998441357000047],
            [70.407108230000063, 32.998798276000059],
            [70.407512738000037, 32.999155195000071],
            [70.40813139800008, 32.999631087000068],
            [70.408607290000077, 32.999821444000077],
            [70.409178361000045, 33.000059390000047],
            [70.409725637000065, 33.000154569000074],
            [70.410177734000058, 33.000440104000063],
            [70.410296708000033, 33.00077322900006],
            [70.410337736000031, 33.001239543000054],
            [70.410194968000042, 33.003209736000031],
            [70.409795219000046, 33.005979429000035],
            [70.409366916000067, 33.008235158000048],
            [70.40891005900005, 33.010662208000042],
            [70.408481756000072, 33.012289760000044],
            [70.40828188200004, 33.01380309700005],
            [70.408292326000037, 33.01465952500007],
            [70.408310435000033, 33.01614448600003],
            [70.408367542000065, 33.017543609000029],
            [70.408653078000043, 33.019085500000074],
            [70.409138488000053, 33.020684498000037],
            [70.409566791000032, 33.022283496000057],
            [70.410137861000067, 33.023397083000077],
            [70.410423397000045, 33.024282243000073],
            [70.410908807000055, 33.025053188000072],
            [70.411851073000037, 33.026052562000075],
            [70.412764786000082, 33.027451685000074],
            [70.413421517000074, 33.02845105800003],
            [70.413764160000028, 33.029107790000069],
            [70.413764160000028, 33.029735967000079],
            [70.413764160000028, 33.030278484000064],
            [70.413478625000039, 33.030906662000064],
            [70.413107429000036, 33.031677607000063],
            [70.412650572000075, 33.032334339000045],
            [70.412050948000058, 33.033190945000058],
            [70.411422770000058, 33.033933336000075],
            [70.411165788000062, 33.034761389000039],
            [70.411137235000069, 33.035503781000045],
            [70.41110868100003, 33.036046298000031],
            [70.41110868100003, 33.036645922000048],
            [70.411137235000069, 33.037159885000051],
            [70.411308556000051, 33.038102152000079],
            [70.411765413000069, 33.038958758000035],
            [70.412536358000068, 33.040300774000059],
            [70.413507178000032, 33.04221386100005],
            [70.413992588000042, 33.043641537000042],
            [70.414249570000038, 33.045040660000041],
            [70.414363784000045, 33.047296389000053],
            [70.414392338000084, 33.048609852000027],
            [70.414221016000056, 33.050808474000064],
            [70.414278123000031, 33.053264077000051],
            [70.414392338000084, 33.055034396000053],
            [70.414706426000066, 33.056747608000023],
            [70.41493485500007, 33.057547107000062],
            [70.415477372000055, 33.05860358800004],
            [70.416676620000032, 33.060545228000024],
            [70.418075743000031, 33.063314920000039],
            [70.419931723000047, 33.066769898000075],
            [70.420645561000072, 33.068454556000063],
            [70.420988203000036, 33.069453930000066],
            [70.421416506000071, 33.070196321000026],
            [70.421930470000063, 33.070995820000064],
            [70.422787076000077, 33.071938087000035],
            [70.423757896000041, 33.072823246000041],
            [70.424814377000075, 33.073794066000062],
            [70.426470481000081, 33.07533595700005],
            [70.429782691000071, 33.078219864000062],
            [70.432780812000033, 33.080903896000052],
            [70.434379810000053, 33.08258855400004],
            [70.435864593000076, 33.084158998000078],
            [70.437549252000053, 33.085986424000055],
            [70.439034035000077, 33.08764252900005],
            [70.440518819000033, 33.089155866000056],
            [70.441718067000068, 33.090612097000076],
            [70.44383102900008, 33.092667951000067],
            [70.445744115000082, 33.094838019000065],
            [70.446543614000063, 33.095751732000053],
            [70.447228899000038, 33.096893873000056],
            [70.447628648000034, 33.097807586000044],
            [70.448428147000072, 33.09917815600005],
            [70.449199092000072, 33.100662940000063],
            [70.44962739500005, 33.101862188000041],
            [70.449855824000053, 33.102404705000026],
            [70.45011280500006, 33.102804455000069],
            [70.450484001000063, 33.103175650000026],
            [70.451169286000038, 33.103489739000054],
            [70.451911678000045, 33.10374672100005],
            [70.452882498000065, 33.104146470000046],
            [70.453310801000043, 33.104631881000046],
            [70.453739104000078, 33.105117291000056],
            [70.454167407000057, 33.105716915000073],
            [70.454567156000053, 33.106259432000058],
            [70.455081120000045, 33.107144591000065],
            [70.455595084000038, 33.107772769000064],
            [70.456337475000055, 33.108772143000067],
            [70.456937100000061, 33.109543088000066],
            [70.457336849000058, 33.109828623000055],
            [70.457822259000068, 33.110114159000034],
            [70.458279115000039, 33.110371140000041],
            [70.458764526000039, 33.110371140000041],
            [70.459164275000035, 33.110342587000048],
            [70.459821006000084, 33.110256926000034],
            [70.460534844000051, 33.110342587000048],
            [70.460991701000069, 33.110371140000041],
            [70.461391450000065, 33.110685229000069],
            [70.461876860000075, 33.111084979000054],
            [70.462248056000078, 33.111313407000068],
            [70.462733466000032, 33.111713156000064],
            [70.463447305000045, 33.112312781000071],
            [70.464361018000034, 33.113255047000052],
            [70.465388945000029, 33.114025992000052],
            [70.466502533000039, 33.114511402000062],
            [70.467644674000042, 33.115025366000054],
            [70.468701155000076, 33.115368008000075],
            [70.470014617000061, 33.115824865000036],
            [70.47141374000006, 33.116167507000057],
            [70.472184685000059, 33.116310275000046],
            [70.473098398000047, 33.116338828000039],
            [70.47409777200005, 33.116338828000039],
            [70.47521136000006, 33.116310275000046],
            [70.476667590000034, 33.116138954000064],
            [70.478095266000082, 33.115767758000061],
            [70.479094640000028, 33.11556788300004],
            [70.479922692000059, 33.11542511500005],
            [70.481264708000083, 33.115253794000068],
            [70.482406850000075, 33.115225241000076],
            [70.483491884000046, 33.115225241000076],
            [70.484576918000073, 33.115482223000072],
            [70.485462078000069, 33.115596437000079],
            [70.48640434400005, 33.115996186000075],
            [70.487689253000042, 33.116824238000049],
            [70.48911693000008, 33.117709398000045],
            [70.490487499000039, 33.118566004000058],
            [70.491515426000035, 33.11936550300004],
            [70.492771782000034, 33.120136448000039],
            [70.493942476000029, 33.12110726800006],
            [70.495827009000038, 33.122477838000066],
            [70.496797830000048, 33.123334444000079],
            [70.497911417000068, 33.124305264000043],
            [70.498482488000036, 33.124876334000078],
            [70.498682363000057, 33.125333191000038],
            [70.498682363000057, 33.125704387000042],
            [70.498196953000047, 33.125989922000031],
            [70.497625882000079, 33.126075583000045],
            [70.497083365000037, 33.12616124300007],
            [70.496255312000073, 33.126275457000077],
            [70.495370153000067, 33.126361118000034],
            [70.494799082000043, 33.126361118000034],
            [70.493885369000054, 33.126589546000048],
            [70.492343479000056, 33.126789421000069],
            [70.491172784000071, 33.12710351000004],
            [70.490230517000043, 33.127360492000037],
            [70.489516679000076, 33.127903009000079],
            [70.489088376000041, 33.128502633000039],
            [70.489031269000066, 33.129045150000024],
            [70.489031269000066, 33.12947345300006],
            [70.489202590000048, 33.130244398000059],
            [70.48923114400003, 33.13107245100008],
            [70.489059822000058, 33.131986164000068],
            [70.489015834000043, 33.132055812000033],
            [70.489017280000041, 33.132054922000066],
            [70.489288251000062, 33.132271699000057],
            [70.489488125000037, 33.132614341000078],
            [70.48971655400004, 33.132814216000043],
            [70.489813860000083, 33.132950445000063],
            [70.489891576000048, 33.133397312000056],
            [70.490177112000083, 33.13411115100007],
            [70.490376986000058, 33.135310399000048],
            [70.490491200000065, 33.137109272000032],
            [70.490462647000072, 33.13976475000004],
            [70.490519754000047, 33.141449408000028],
            [70.490519754000047, 33.142934192000041],
            [70.490519754000047, 33.143876459000069],
            [70.490091451000069, 33.145903759000078],
            [70.48992013000003, 33.146960240000055],
            [70.489349059000062, 33.148473577000061],
            [70.488977863000059, 33.14938729000005],
            [70.488178364000078, 33.150101128000074],
            [70.487293205000071, 33.150729306000073],
            [70.48660792000004, 33.151243270000066],
            [70.486265278000076, 33.15158591200003],
            [70.48621147700004, 33.152150822000067],
            [70.486208171000044, 33.152185536000047],
            [70.486379492000083, 33.152499625000075],
            [70.48646515300004, 33.152899374000071],
            [70.487321758000064, 33.153984409000032],
            [70.488378239000042, 33.155155103000027],
            [70.489748809000048, 33.156782655000029],
            [70.491262146000054, 33.158524420000049],
            [70.492432841000038, 33.160123418000069],
            [70.493517875000066, 33.161465434000036],
            [70.494688570000051, 33.163035878000073],
            [70.495630836000032, 33.164235126000051],
            [70.49643033500007, 33.165520035000043],
            [70.497315495000066, 33.167033373000038],
            [70.497943672000076, 33.167918532000044],
            [70.498686064000083, 33.168746584000075],
            [70.499628331000054, 33.169546083000057],
            [70.501569971000038, 33.171145081000077],
            [70.502740666000079, 33.172629865000033],
            [70.504339663000053, 33.174257416000046],
            [70.505853000000059, 33.17559943200007],
            [70.506938035000076, 33.176513145000058],
            [70.509222317000081, 33.177997929000071],
            [70.51073565400003, 33.178997302000028],
            [70.511820689000047, 33.179911015000073],
            [70.512505973000032, 33.180567747000055],
            [70.512791509000067, 33.181138817000033],
            [70.513019937000081, 33.181795548000025],
            [70.513191258000063, 33.182909136000035],
            [70.51330547200007, 33.183423100000027],
            [70.513562454000066, 33.183851403000062],
            [70.514162078000084, 33.184251152000058],
            [70.514818809000076, 33.184622348000062],
            [70.51613227200005, 33.185450400000036],
            [70.516674789000035, 33.185793043000047],
            [70.517045985000038, 33.186506881000071],
            [70.517302967000035, 33.186992291000024],
            [70.517674163000038, 33.188048772000059],
            [70.517988251000077, 33.189105252000047],
            [70.518416554000055, 33.190133180000032],
            [70.518702090000033, 33.190647143000035],
            [70.518987625000079, 33.191246767000052],
            [70.519558696000047, 33.191617963000056],
            [70.520129766000082, 33.19184639100007],
            [70.521329015000049, 33.192103373000066],
            [70.523499083000047, 33.192388909000044],
            [70.525098081000067, 33.192531676000044],
            [70.526982614000076, 33.192988533000062],
            [70.529038468000067, 33.193788032000043],
            [70.531608286000051, 33.194587531000025],
            [70.533806908000031, 33.195415583000056],
            [70.535577227000033, 33.196300742000062],
            [70.538004277000084, 33.197671312000068],
            [70.53963182800004, 33.198927667000078],
            [70.540231452000057, 33.199384524000038],
            [70.540945291000071, 33.199555845000077],
            [70.542087432000073, 33.199698613000066],
            [70.543629323000062, 33.199869934000048],
            [70.545199767000042, 33.200298237000027],
            [70.546341908000045, 33.200726540000062],
            [70.547655371000076, 33.201411825000037],
            [70.54973977800006, 33.202839501000028],
            [70.551881293000065, 33.204152964000059],
            [70.553994255000077, 33.205266551000079],
            [70.555650359000083, 33.206008943000029],
            [70.556906715000082, 33.207036870000024],
            [70.558791248000034, 33.208607315000052],
            [70.560561567000036, 33.209720902000072],
            [70.562560314000052, 33.210606062000068],
            [70.565187239000068, 33.211633989000063],
            [70.567671396000037, 33.212633362000076],
            [70.569812911000042, 33.213318647000051],
            [70.571640337000076, 33.213518522000072],
            [70.573296442000071, 33.213832611000043],
            [70.575152421000041, 33.214032485000075],
            [70.576094688000069, 33.214118146000033],
            [70.576637205000054, 33.214289467000071],
            [70.577151169000047, 33.214632110000025],
            [70.577465258000075, 33.215117520000035],
            [70.577636579000057, 33.215574376000063],
            [70.577750793000064, 33.215974126000049],
            [70.577807900000039, 33.21631676800007],
            [70.578093435000028, 33.216630857000041],
            [70.578464631000031, 33.216944946000069],
            [70.579035702000056, 33.217116267000051],
            [70.579549665000059, 33.217116267000051],
            [70.580491932000029, 33.217287588000033],
            [70.581491305000043, 33.217687338000076],
            [70.582747661000042, 33.218087087000072],
            [70.583461499000066, 33.218601051000064],
            [70.584346659000062, 33.219057907000035],
            [70.585517353000057, 33.219714638000028],
            [70.586059871000032, 33.220028727000056],
            [70.586659495000049, 33.220142941000063],
            [70.587516101000062, 33.220171495000045],
            [70.588658242000065, 33.220457030000034],
            [70.590257240000028, 33.220942440000044],
            [70.590628436000031, 33.220999547000076],
            [70.590856864000045, 33.221285083000055],
            [70.591228060000049, 33.221656278000069],
            [70.591370827000048, 33.222027474000072],
            [70.591513595000038, 33.222341563000043],
            [70.59157070200007, 33.222627098000032],
            [70.591856237000059, 33.222969741000043],
            [70.592255987000044, 33.223169616000064],
            [70.592969825000068, 33.223455151000053],
            [70.594169073000046, 33.223854900000049],
            [70.59596794600003, 33.224197543000059],
            [70.597195748000047, 33.224711506000062],
            [70.598423550000064, 33.225339684000062],
            [70.599194495000063, 33.225825094000072],
            [70.599708459000055, 33.225996415000054],
            [70.600336636000065, 33.225996415000054],
            [70.601164689000029, 33.225996415000054],
            [70.601992741000061, 33.226024969000036],
            [70.602535258000046, 33.226053522000029],
            [70.602849347000074, 33.226310504000026],
            [70.603477525000073, 33.226567486000079],
            [70.604448345000037, 33.227081449000025],
            [70.605704700000047, 33.227738181000063],
            [70.606247217000032, 33.227966609000077],
            [70.606961056000046, 33.228337805000024],
            [70.607903322000084, 33.228566233000038],
            [70.608931249000079, 33.228994536000073],
            [70.61050169400005, 33.229365732000076],
            [70.613928117000057, 33.230051017000051],
            [70.616497935000041, 33.230707748000043],
            [70.617925612000079, 33.231164604000071],
            [70.618296808000082, 33.231278819000067],
            [70.618668004000028, 33.231564354000056],
            [70.618753664000053, 33.231992657000035],
            [70.61886787800006, 33.232535174000077],
            [70.619010646000049, 33.233277566000027],
            [70.619153414000039, 33.234162725000033],
            [70.619381842000053, 33.235647509000046],
            [70.619524610000042, 33.236761097000056],
            [70.619952912000031, 33.238902611000071],
            [70.62043832300003, 33.241072680000059],
            [70.621095054000079, 33.243756712000049],
            [70.621494803000076, 33.245926780000048],
            [70.621551910000051, 33.247325903000046],
            [70.621780338000065, 33.248810687000059],
            [70.621837446000029, 33.249495972000034],
            [70.621894553000061, 33.250038489000076],
            [70.621923106000054, 33.250523899000029],
            [70.622237195000082, 33.251094969000064],
            [70.622636944000078, 33.251409058000036],
            [70.623208015000046, 33.251837361000071],
            [70.623693425000056, 33.252294218000031],
            [70.62392185300007, 33.252751074000059],
            [70.624007514000084, 33.253207931000077],
            [70.624007514000084, 33.253636234000055],
            [70.623807639000063, 33.254150197000058],
            [70.623721979000038, 33.254464286000029],
            [70.623464997000042, 33.255206678000036],
            [70.623407890000067, 33.255663534000064],
            [70.623407890000067, 33.256120391000024],
            [70.623493550000035, 33.256463033000045],
            [70.623721979000038, 33.256662908000067],
            [70.624207389000048, 33.257091211000045],
            [70.624635692000084, 33.257605175000037],
            [70.625063995000062, 33.257976371000041],
            [70.625806386000079, 33.258918637000079],
            [70.626491671000053, 33.26000367100005],
            [70.626913831000081, 33.260355471000025],
            [70.627969000000064, 33.261118452000062],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "45",
      properties: { name: "FR D.i.khan", count: 838 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.976629537000065, 31.898798816000067],
            [69.976986456000077, 31.89804928600006],
            [69.977379067000072, 31.897264064000069],
            [69.977735986000084, 31.896514533000072],
            [69.978021522000063, 31.895979155000077],
            [69.978449825000041, 31.895622235000076],
            [69.979056587000059, 31.895408084000053],
            [69.979877501000033, 31.89519393300003],
            [69.98066272300008, 31.895122549000064],
            [69.981376562000037, 31.894908397000052],
            [69.982304551000084, 31.894622862000062],
            [69.983089773000074, 31.894087483000078],
            [69.983625152000059, 31.893766256000049],
            [69.984338990000083, 31.893088110000065],
            [69.98505282900004, 31.892374271000051],
            [69.985623899000075, 31.891981660000056],
            [69.986230662000082, 31.891624741000044],
            [69.986873116000083, 31.891303514000072],
            [69.987408495000068, 31.891232130000049],
            [69.988050950000058, 31.891267822000032],
            [69.988657712000077, 31.891303514000072],
            [69.989335858000061, 31.891446281000071],
            [69.990228156000057, 31.89173181700005],
            [69.991298914000083, 31.892124428000045],
            [69.99219121200008, 31.892481347000057],
            [69.993547504000048, 31.892873958000052],
            [69.994761029000074, 31.893302261000031],
            [69.996081630000049, 31.89362348800006],
            [69.997437923000064, 31.89362348800006],
            [69.998258837000037, 31.893659180000043],
            [69.999365286000057, 31.893694872000026],
            [70.000293276000036, 31.893873332000055],
            [70.001506801000062, 31.893730564000066],
            [70.002434791000042, 31.893730564000066],
            [70.004148003000068, 31.893516413000043],
            [70.004897533000076, 31.893409337000037],
            [70.006003982000038, 31.892802574000029],
            [70.007217508000053, 31.892445655000074],
            [70.008645184000045, 31.892053044000079],
            [70.010251320000066, 31.891910276000033],
            [70.012214376000031, 31.89173181700005],
            [70.01439158200003, 31.891481973000054],
            [70.016354638000053, 31.891303514000072],
            [70.017996466000056, 31.89076813500003],
            [70.019174299000042, 31.890375524000035],
            [70.020209364000038, 31.889697378000051],
            [70.020923203000052, 31.889233383000033],
            [70.021744117000082, 31.888626620000025],
            [70.022565031000056, 31.887805706000051],
            [70.02324317700004, 31.887020484000061],
            [70.023707172000059, 31.886449413000037],
            [70.024456702000066, 31.885806959000035],
            [70.025313308000079, 31.884950353000079],
            [70.026419758000031, 31.884272207000038],
            [70.027062212000033, 31.883736828000053],
            [70.02784743400008, 31.883272833000035],
            [70.028882500000066, 31.882416227000078],
            [70.029774798000062, 31.881595313000048],
            [70.030845555000042, 31.880703015000051],
            [70.031488009000043, 31.880346096000039],
            [70.032380307000039, 31.879989177000027],
            [70.03305845400007, 31.879739334000078],
            [70.033807984000077, 31.879560874000049],
            [70.034450438000079, 31.879525182000066],
            [70.035307044000035, 31.879346722000037],
            [70.03573534700007, 31.879132571000071],
            [70.036235034000072, 31.878954111000041],
            [70.037127332000068, 31.878383041000063],
            [70.037912554000059, 31.877990430000068],
            [70.039161771000067, 31.877490743000067],
            [70.040089761000047, 31.877062440000032],
            [70.040839291000054, 31.876491369000064],
            [70.041910048000034, 31.87577753100004],
            [70.042302659000029, 31.875563380000074],
            [70.042873730000053, 31.875063693000072],
            [70.043087882000066, 31.874528314000031],
            [70.043337725000072, 31.873528940000028],
            [70.043337725000072, 31.872386799000026],
            [70.043480493000061, 31.870887739000068],
            [70.043694644000084, 31.869781289000059],
            [70.043944488000079, 31.868317921000028],
            [70.044301407000034, 31.866890244000047],
            [70.044622634000064, 31.865498260000038],
            [70.044872477000069, 31.864498886000035],
            [70.045086629000082, 31.863499512000033],
            [70.045443548000037, 31.862571523000042],
            [70.045871851000072, 31.86157214900004],
            [70.046407230000057, 31.860751235000066],
            [70.047049684000058, 31.85985893700007],
            [70.047870598000031, 31.858681104000027],
            [70.048727204000045, 31.85782449800007],
            [70.049583810000058, 31.857289119000029],
            [70.050297648000083, 31.856789433000074],
            [70.051261330000045, 31.856325438000056],
            [70.052724698000077, 31.855754367000031],
            [70.054080991000035, 31.855076221000047],
            [70.055187441000044, 31.854612226000029],
            [70.056722193000041, 31.853898388000061],
            [70.057792950000078, 31.85354146800006],
            [70.058720940000057, 31.853113165000025],
            [70.059756006000043, 31.852756246000069],
            [70.061076606000029, 31.852399327000057],
            [70.062218748000078, 31.851935332000039],
            [70.063218121000034, 31.851614105000067],
            [70.064467338000043, 31.851150110000049],
            [70.065466712000045, 31.850971651000066],
            [70.066751621000037, 31.850579039000024],
            [70.069142979000048, 31.850115045000052],
            [70.069821125000033, 31.850007969000046],
            [70.070713423000029, 31.850007969000046],
            [70.071320186000037, 31.850007969000046],
            [70.072141100000067, 31.850007969000046],
            [70.072747862000028, 31.849936585000023],
            [70.073461701000042, 31.849758125000051],
            [70.074532458000078, 31.849365514000056],
            [70.075674599000081, 31.849008595000043],
            [70.076209978000065, 31.848865828000044],
            [70.077387811000051, 31.848651676000031],
            [70.078137342000048, 31.848508908000042],
            [70.078744104000066, 31.848437525000065],
            [70.079350867000073, 31.848473217000048],
            [70.079993321000075, 31.848651676000031],
            [70.080814235000048, 31.848758752000037],
            [70.081278230000066, 31.848758752000037],
            [70.081813609000051, 31.848758752000037],
            [70.082563139000058, 31.848366141000042],
            [70.083491129000038, 31.847937838000064],
            [70.084561886000074, 31.847295383000073],
            [70.086310790000084, 31.846510161000026],
            [70.089130451000074, 31.845403712000063],
            [70.093377789000044, 31.844047419000049],
            [70.09630452600004, 31.843119429000069],
            [70.099231263000036, 31.842476975000068],
            [70.101836773000059, 31.841905904000043],
            [70.104121055000064, 31.841620369000054],
            [70.10597703500008, 31.841620369000054],
            [70.107511787000078, 31.841798829000027],
            [70.110260065000034, 31.842120056000056],
            [70.11225881200005, 31.842191440000079],
            [70.115506776000075, 31.842405591000045],
            [70.117077220000056, 31.842405591000045],
            [70.118362129000047, 31.842512667000051],
            [70.119432887000073, 31.842833894000023],
            [70.120717795000076, 31.843440657000031],
            [70.121824245000084, 31.843940343000043],
            [70.123858684000083, 31.844832641000039],
            [70.125429128000064, 31.845296636000057],
            [70.126856805000045, 31.845796323000059],
            [70.128462941000066, 31.846331702000043],
            [70.129997693000064, 31.846938464000061],
            [70.131461062000028, 31.847580919000052],
            [70.13292443000006, 31.84800922200003],
            [70.134066571000062, 31.848330449000059],
            [70.134816102000059, 31.848508908000042],
            [70.135494248000043, 31.848651676000031],
            [70.136707773000069, 31.848651676000031],
            [70.137814222000031, 31.848794444000077],
            [70.139348975000075, 31.848758752000037],
            [70.140990803000079, 31.848865828000044],
            [70.142739707000032, 31.848830136000061],
            [70.144203075000064, 31.848865828000044],
            [70.145416600000033, 31.848865828000044],
            [70.146273206000046, 31.848830136000061],
            [70.146772893000048, 31.848865828000044],
            [70.148129186000062, 31.84911567100005],
            [70.149414095000054, 31.849365514000056],
            [70.150199317000045, 31.849472590000062],
            [70.150948847000052, 31.849472590000062],
            [70.151734069000042, 31.849543974000028],
            [70.152840518000062, 31.849436898000079],
            [70.153768508000041, 31.849222747000056],
            [70.154696498000078, 31.848758752000037],
            [70.156052791000036, 31.848294757000076],
            [70.157623235000074, 31.847545227000069],
            [70.158265689000075, 31.846974156000044],
            [70.159015220000072, 31.846081858000048],
            [70.159764750000079, 31.844725566000079],
            [70.160193053000057, 31.844083111000032],
            [70.160764123000035, 31.842726818000074],
            [70.161370886000043, 31.841156374000036],
            [70.161763497000038, 31.839800081000078],
            [70.162405951000039, 31.838836400000048],
            [70.163012714000047, 31.837837026000045],
            [70.164226239000072, 31.836694885000043],
            [70.165439764000041, 31.835909663000052],
            [70.166617597000084, 31.835445668000034],
            [70.167795431000059, 31.834803213000043],
            [70.168901880000078, 31.834517678000054],
            [70.170508016000042, 31.834446294000031],
            [70.172292612000035, 31.834731829000077],
            [70.173684596000044, 31.835088749000079],
            [70.174826738000036, 31.83555274400004],
            [70.175790419000066, 31.836016738000069],
            [70.176896869000075, 31.83665919300006],
            [70.177575015000059, 31.837087496000038],
            [70.178253161000043, 31.837337339000044],
            [70.178967000000057, 31.837480107000033],
            [70.180394676000049, 31.837979794000034],
            [70.182571883000037, 31.83837240500003],
            [70.18357125700004, 31.838586556000052],
            [70.184178019000058, 31.838693632000059],
            [70.184891858000071, 31.838622248000036],
            [70.185677080000062, 31.838301021000063],
            [70.186355226000046, 31.837908410000068],
            [70.187425984000072, 31.837051804000055],
            [70.188032746000033, 31.836587809000036],
            [70.188710892000074, 31.83573120300008],
            [70.189281963000042, 31.834838905000026],
            [70.189960109000083, 31.833446921000075],
            [70.190602564000073, 31.832126320000043],
            [70.191530554000053, 31.830270340000027],
            [70.192351468000084, 31.828842664000035],
            [70.193172382000057, 31.827058068000042],
            [70.193743452000035, 31.826201462000029],
            [70.194385907000083, 31.825344856000072],
            [70.194849902000044, 31.823845796000057],
            [70.195492356000045, 31.823060573000078],
            [70.195992043000047, 31.821989816000041],
            [70.196634497000048, 31.821311670000057],
            [70.197348336000061, 31.820633523000026],
            [70.197955098000079, 31.819955377000042],
            [70.19856186100003, 31.819384306000075],
            [70.199347083000077, 31.819063079000045],
            [70.199953845000039, 31.819027387000062],
            [70.200417840000057, 31.819027387000062],
            [70.200881835000075, 31.819241539000075],
            [70.201310138000053, 31.819669842000053],
            [70.201988285000084, 31.820133836000025],
            [70.202416588000062, 31.82056213900006],
            [70.202951966000057, 31.821061826000062],
            [70.203415961000076, 31.821454437000057],
            [70.203879956000037, 31.821811356000069],
            [70.204772254000034, 31.821704281000052],
            [70.205093481000063, 31.82134736200004],
            [70.205486092000058, 31.820883367000079],
            [70.20580731900003, 31.820347988000037],
            [70.20584301100007, 31.819883993000076],
            [70.20584301100007, 31.819277231000058],
            [70.20584301100007, 31.818777544000056],
            [70.20580731900003, 31.818206473000032],
            [70.205557476000081, 31.817456943000025],
            [70.205450400000075, 31.81677879700004],
            [70.205164865000029, 31.816100650000067],
            [70.205129173000046, 31.815636655000048],
            [70.205164865000029, 31.81517266000003],
            [70.205271941000035, 31.814708665000069],
            [70.205414708000035, 31.81438743800004],
            [70.205985779000059, 31.814101903000051],
            [70.206449774000077, 31.813959135000061],
            [70.207127920000062, 31.813959135000061],
            [70.207913142000052, 31.814101903000051],
            [70.208841132000032, 31.814316054000074],
            [70.209733430000028, 31.814637282000035],
            [70.210946955000054, 31.81517266000003],
            [70.211767869000084, 31.815493888000049],
            [70.211892848000048, 31.815548566000075],
            [70.21213875400008, 31.81543051400007],
            [70.213252435000072, 31.814810967000028],
            [70.211831000000075, 31.802371000000051],
            [70.214481000000035, 31.783154000000025],
            [70.217132000000049, 31.76460000000003],
            [70.209843000000035, 31.746046000000035],
            [70.219120000000032, 31.736107000000061],
            [70.230385000000069, 31.712914000000069],
            [70.252914000000032, 31.69369800000004],
            [70.261552447000042, 31.688761744000033],
            [70.266830000000084, 31.685746000000051],
            [70.276769000000058, 31.674481000000071],
            [70.27663283000004, 31.673187125000027],
            [70.27544400000005, 31.661891000000026],
            [70.274119000000042, 31.647313000000054],
            [70.277148646000057, 31.636708952000049],
            [70.279420000000073, 31.628759000000059],
            [70.297974000000067, 31.59430100000003],
            [70.312501028000042, 31.55830035300005],
            [70.313215000000071, 31.556531000000064],
            [70.315865000000031, 31.543278000000043],
            [70.326468000000034, 31.524724000000049],
            [70.335744000000034, 31.510146000000077],
            [70.342650649000063, 31.505364658000076],
            [70.344359000000054, 31.504182000000071],
            [70.337732000000074, 31.488279000000034],
            [70.329781000000082, 31.478339000000062],
            [70.343034000000046, 31.466412000000048],
            [70.343461977000061, 31.463308442000027],
            [70.345684000000062, 31.447195000000079],
            [70.351648000000068, 31.426653000000044],
            [70.351678285000048, 31.426274352000064],
            [70.352973000000077, 31.410087000000033],
            [70.357912956000064, 31.403382888000067],
            [70.362250000000074, 31.397497000000044],
            [70.363552146000075, 31.38615100800007],
            [70.366889000000072, 31.357076000000063],
            [70.37139555300007, 31.343555587000026],
            [70.378816000000029, 31.321293000000026],
            [70.38120499300004, 31.312491298000054],
            [70.390985082000043, 31.276458776000027],
            [70.390988511000046, 31.276446147000058],
            [70.391406000000075, 31.274908000000039],
            [70.404659000000038, 31.251716000000044],
            [70.425614944000074, 31.24370288700004],
            [70.424795439000036, 31.242160284000079],
            [70.423555128000032, 31.239825577000033],
            [70.421617866000076, 31.236178955000071],
            [70.419259496000052, 31.231739659000027],
            [70.416597722000063, 31.22649995900008],
            [70.415515674000062, 31.224692446000063],
            [70.411575037000034, 31.219275492000065],
            [70.410469267000053, 31.217801166000072],
            [70.40803150000005, 31.214550884000062],
            [70.406992953000042, 31.213166186000024],
            [70.404187000000036, 31.209425000000067],
            [70.404187000000036, 31.200649982000073],
            [70.404187000000036, 31.198393000000067],
            [70.399461000000031, 31.195768000000044],
            [70.393156000000033, 31.192265000000077],
            [70.369254000000069, 31.189813000000072],
            [70.333095000000071, 31.178169000000025],
            [70.321951765000051, 31.17355703000004],
            [70.289126348000082, 31.162493965000067],
            [70.288756493000051, 31.162369314000046],
            [70.288696289000029, 31.162475586000028],
            [70.288682201000029, 31.162493965000067],
            [70.282701060000079, 31.170297177000066],
            [70.279479980000076, 31.174499512000068],
            [70.269113633000075, 31.184682924000072],
            [70.26788549500003, 31.189839961000075],
            [70.266129503000059, 31.19725813000008],
            [70.266113281000059, 31.197326660000044],
            [70.266853963000074, 31.198525860000075],
            [70.268676758000083, 31.20147705100004],
            [70.265134101000058, 31.211076509000065],
            [70.264892578000058, 31.211730957000043],
            [70.26007080100004, 31.221923828000058],
            [70.259750392000058, 31.229364416000067],
            [70.259526571000038, 31.234562033000032],
            [70.268390774000068, 31.238800863000051],
            [70.273315430000082, 31.24108886700003],
            [70.27331100400005, 31.241153700000041],
            [70.273118207000039, 31.243977603000076],
            [70.272277832000043, 31.256286621000072],
            [70.269999453000082, 31.281348790000038],
            [70.269714355000076, 31.284484863000046],
            [70.267272949000073, 31.317687988000046],
            [70.270874023000033, 31.341674805000025],
            [70.271484375000057, 31.366882324000073],
            [70.278076172000056, 31.373474121000072],
            [70.278225230000032, 31.378661355000077],
            [70.278686523000033, 31.394714355000076],
            [70.278989598000067, 31.405018897000048],
            [70.279479980000076, 31.421691895000038],
            [70.272888184000067, 31.445678711000028],
            [70.267982458000063, 31.460022627000058],
            [70.267326000000082, 31.461992000000066],
            [70.266122000000053, 31.488467000000071],
            [70.26611536300004, 31.488477930000045],
            [70.266113281000059, 31.488525391000053],
            [70.255920410000044, 31.505310059000067],
            [70.255902277000075, 31.505299722000075],
            [70.243286133000083, 31.498107910000044],
            [70.222900391000053, 31.480712891000053],
            [70.222872412000072, 31.480706762000068],
            [70.203674316000047, 31.476501465000069],
            [70.203633521000029, 31.476452147000032],
            [70.197006971000064, 31.468440943000076],
            [70.187316895000038, 31.456726074000073],
            [70.178600945000028, 31.446486604000029],
            [70.172302246000072, 31.439086914000029],
            [70.157897949000073, 31.439086914000029],
            [70.153686523000033, 31.431884766000053],
            [70.136758274000044, 31.429925711000067],
            [70.13311767600004, 31.429504395000038],
            [70.116901000000041, 31.425297814000032],
            [70.116882324000073, 31.425292969000054],
            [70.116882324000073, 31.442687988000046],
            [70.103698730000076, 31.449279785000044],
            [70.094116211000028, 31.442077637000068],
            [70.096496582000043, 31.420471191000047],
            [70.088684082000043, 31.409729004000042],
            [70.086897103000069, 31.407257946000072],
            [70.080871582000043, 31.398925781000059],
            [70.080820491000054, 31.398843758000055],
            [70.071899414000029, 31.384521484000061],
            [70.071867497000028, 31.384522606000075],
            [70.056274414000029, 31.38507080100004],
            [70.056158866000033, 31.384973889000037],
            [70.056130000000053, 31.384975000000054],
            [70.056080050000048, 31.385169013000052],
            [70.050714000000028, 31.406034000000034],
            [70.045901000000072, 31.430102000000034],
            [70.023036000000047, 31.492077000000052],
            [70.011002000000076, 31.510730000000024],
            [69.983926000000054, 31.512535000000071],
            [69.97068900000005, 31.509526000000051],
            [69.955044000000044, 31.522162000000037],
            [69.94481600000006, 31.540213000000051],
            [69.944214000000045, 31.569696000000079],
            [69.949616168000034, 31.655535102000044],
            [69.949629000000073, 31.65573900000004],
            [69.950231000000031, 31.705680000000029],
            [69.952036000000078, 31.727943000000039],
            [69.94662100000005, 31.766451000000075],
            [69.955044000000044, 31.76885800000008],
            [69.957451000000049, 31.782697000000041],
            [69.941205000000082, 31.798943000000065],
            [69.944214000000045, 31.809172000000046],
            [69.939400000000035, 31.832037000000071],
            [69.938799000000074, 31.850689000000045],
            [69.949027000000058, 31.857308000000046],
            [69.956849000000034, 31.872952000000055],
            [69.958655000000078, 31.893410000000074],
            [69.959551789000045, 31.896228998000026],
            [69.96013987200007, 31.896228998000026],
            [69.960675251000055, 31.896228998000026],
            [69.961210630000039, 31.896228998000026],
            [69.96174600900008, 31.896228998000026],
            [69.962174312000059, 31.896300382000049],
            [69.962566923000054, 31.896692993000045],
            [69.96281676600006, 31.897156988000063],
            [69.963209377000055, 31.897442523000052],
            [69.963958907000062, 31.898156361000076],
            [69.964494286000047, 31.898584664000055],
            [69.965422276000083, 31.898798816000067],
            [69.965850579000062, 31.898798816000067],
            [69.966849952000075, 31.89883450800005],
            [69.967849326000078, 31.89883450800005],
            [69.96991945700006, 31.898691740000061],
            [69.970561911000061, 31.898691740000061],
            [69.971025906000079, 31.898763124000027],
            [69.971596977000047, 31.899334195000051],
            [69.972132356000031, 31.899655422000023],
            [69.97245358300006, 31.899798189000023],
            [69.972917578000079, 31.899976649000052],
            [69.97341726500008, 31.899976649000052],
            [69.974166795000031, 31.899976649000052],
            [69.974844941000072, 31.899905265000029],
            [69.975558779000039, 31.899584038000057],
            [69.97620123400003, 31.899262811000028],
            [69.976629537000065, 31.898798816000067],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "46",
      properties: { name: "FR Kohat", count: 248 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.354089266000074, 33.764214080000045],
            [71.355002979000062, 33.764099866000038],
            [71.356630531000064, 33.764099866000038],
            [71.357772672000067, 33.764042758000073],
            [71.358971920000045, 33.763728670000035],
            [71.359771419000083, 33.763300367000056],
            [71.360685132000071, 33.763100492000035],
            [71.361855827000056, 33.76304338500006],
            [71.362626772000056, 33.762843510000039],
            [71.363654699000051, 33.762586528000043],
            [71.365110929000082, 33.762243886000078],
            [71.365881875000071, 33.762015458000064],
            [71.366709927000045, 33.761787029000061],
            [71.36773785400004, 33.761701369000036],
            [71.368623014000036, 33.761701369000036],
            [71.369279745000028, 33.761758476000068],
            [71.370136351000042, 33.761758476000068],
            [71.370650315000034, 33.761672815000054],
            [71.371249939000052, 33.761615708000079],
            [71.372706169000082, 33.761158852000051],
            [71.374590702000035, 33.760930424000037],
            [71.374647809000066, 33.76084476300008],
            [71.375732843000037, 33.760759102000065],
            [71.376989199000036, 33.760644888000058],
            [71.377988572000049, 33.760644888000058],
            [71.379244928000048, 33.760387906000062],
            [71.379730338000059, 33.759902496000052],
            [71.380072980000079, 33.759359979000067],
            [71.380101533000072, 33.758731802000057],
            [71.380158641000037, 33.758103624000057],
            [71.380529836000051, 33.757446893000065],
            [71.380843925000079, 33.756590287000051],
            [71.381500656000071, 33.755990663000034],
            [71.382128834000071, 33.755590913000049],
            [71.382614244000081, 33.755362485000035],
            [71.383670725000059, 33.755076950000046],
            [71.384755759000029, 33.754877075000024],
            [71.385698026000057, 33.754591540000035],
            [71.386754506000045, 33.754306004000057],
            [71.388210736000076, 33.753763487000072],
            [71.389638413000057, 33.753220970000029],
            [71.39112319700007, 33.75259279200003],
            [71.39249376600003, 33.752107382000077],
            [71.393664461000071, 33.751707633000024],
            [71.394720942000049, 33.751422098000035],
            [71.39554899400008, 33.751307883000038],
            [71.39631993900008, 33.751307883000038],
            [71.397033778000036, 33.751422098000035],
            [71.397633402000054, 33.751593419000073],
            [71.398432901000035, 33.751821847000031],
            [71.399660703000052, 33.751878954000063],
            [71.400745737000079, 33.751964615000077],
            [71.401602343000036, 33.751679079000041],
            [71.402715930000056, 33.751450651000027],
            [71.403629643000045, 33.751165116000038],
            [71.404486249000058, 33.750851027000067],
            [71.40514298100004, 33.750679706000028],
            [71.405999587000053, 33.750651152000046],
            [71.407455817000084, 33.750708259000078],
            [71.408940600000051, 33.750993795000056],
            [71.409740099000032, 33.751079455000024],
            [71.410568152000053, 33.751079455000024],
            [71.411510418000034, 33.751079455000024],
            [71.412110042000052, 33.750822473000028],
            [71.412624006000044, 33.750365617000057],
            [71.413052309000079, 33.74976599300004],
            [71.413452058000075, 33.749394797000036],
            [71.413851808000061, 33.749252029000047],
            [71.414651307000042, 33.749252029000047],
            [71.415336591000084, 33.749309136000079],
            [71.41622175100008, 33.749309136000079],
            [71.416792821000058, 33.749280583000029],
            [71.417363892000083, 33.749023601000033],
            [71.417763642000068, 33.748795173000076],
            [71.418249052000078, 33.74868095800008],
            [71.418705908000049, 33.74868095800008],
            [71.419105658000035, 33.748823726000069],
            [71.419733835000045, 33.749194922000072],
            [71.420590441000058, 33.749708886000064],
            [71.421190065000076, 33.750194296000075],
            [71.421846797000057, 33.750594045000071],
            [71.422674849000032, 33.750793920000035],
            [71.424416614000052, 33.751764740000056],
            [71.426643790000071, 33.753106756000079],
            [71.427500396000084, 33.75356361200005],
            [71.428299895000066, 33.753963362000036],
            [71.429327822000062, 33.754134683000075],
            [71.430070214000068, 33.754334558000039],
            [71.430612731000053, 33.754306004000057],
            [71.431012480000049, 33.753849148000029],
            [71.431212355000071, 33.753278077000061],
            [71.431469337000067, 33.752792667000051],
            [71.43184053300007, 33.752250150000066],
            [71.432211729000073, 33.751707633000024],
            [71.432611478000069, 33.75122222300007],
            [71.433296763000044, 33.750851027000067],
            [71.433753619000072, 33.750565492000078],
            [71.434381797000071, 33.750536938000039],
            [71.435067082000046, 33.750536938000039],
            [71.436037902000066, 33.75087958000006],
            [71.437437025000065, 33.751250776000063],
            [71.43812231000004, 33.751507758000059],
            [71.438950362000071, 33.751679079000041],
            [71.439778414000045, 33.75199316800007],
            [71.44034948500007, 33.752421471000048],
            [71.440920556000037, 33.752764113000069],
            [71.441491626000072, 33.752878328000065],
            [71.441948483000033, 33.752878328000065],
            [71.442662321000057, 33.752878328000065],
            [71.443261945000074, 33.752678453000044],
            [71.443975783000042, 33.752564239000037],
            [71.444689622000055, 33.752421471000048],
            [71.445489121000037, 33.752421471000048],
            [71.446374280000043, 33.752821221000033],
            [71.447373654000046, 33.753392291000068],
            [71.448287367000034, 33.75370638000004],
            [71.449058312000034, 33.753763487000072],
            [71.449914918000047, 33.753734934000079],
            [71.450628756000071, 33.753449398000043],
            [71.451571023000042, 33.752878328000065],
            [71.452113540000084, 33.752649899000062],
            [71.452855932000034, 33.752364364000073],
            [71.453855305000047, 33.75199316800007],
            [71.455397196000035, 33.751279330000045],
            [71.45596826700006, 33.751050902000031],
            [71.456824873000073, 33.751050902000031],
            [71.457767139000055, 33.751022348000049],
            [71.45865229900005, 33.750993795000056],
            [71.459537458000057, 33.750908134000042],
            [71.460336957000038, 33.750565492000078],
            [71.461279224000066, 33.750165742000036],
            [71.461964508000051, 33.749823100000071],
            [71.462764007000033, 33.749708886000064],
            [71.463649167000028, 33.749651779000033],
            [71.464277344000038, 33.749651779000033],
            [71.464905522000038, 33.749737439000057],
            [71.465590807000069, 33.749794546000032],
            [71.466304645000037, 33.749823100000071],
            [71.466961376000029, 33.74976599300004],
            [71.467760875000067, 33.749737439000057],
            [71.46841659100005, 33.74976594900005],
            [71.468781471000057, 33.750186964000079],
            [71.468782930000032, 33.750187520000054],
            [71.468788802000063, 33.750194296000075],
            [71.469388427000069, 33.750422724000032],
            [71.470159372000069, 33.750422724000032],
            [71.470987424000043, 33.750194296000075],
            [71.471472834000053, 33.749937314000078],
            [71.47207245900006, 33.749280583000029],
            [71.472329440000067, 33.749052154000026],
            [71.472729190000052, 33.748795173000076],
            [71.473471582000059, 33.748738066000044],
            [71.474014099000044, 33.748566744000073],
            [71.474356741000065, 33.748338316000059],
            [71.474842151000075, 33.748195548000069],
            [71.475299008000036, 33.748224102000052],
            [71.475841525000078, 33.748481084000048],
            [71.476155614000049, 33.74868095800008],
            [71.476898005000066, 33.74868095800008],
            [71.477554737000048, 33.74868095800008],
            [71.478040147000058, 33.748395423000034],
            [71.478554110000061, 33.748309763000066],
            [71.479210841000054, 33.748252656000034],
            [71.479867573000035, 33.748252656000034],
            [71.480238769000039, 33.748395423000034],
            [71.480895500000031, 33.748852280000051],
            [71.481464505000076, 33.749079882000046],
            [71.48194464900007, 33.74967300000003],
            [71.482287291000034, 33.750072750000072],
            [71.482658487000037, 33.75050105300005],
            [71.482659820000038, 33.750501463000035],
            [71.482665819000033, 33.750508384000057],
            [71.483037015000036, 33.750622599000053],
            [71.483693746000029, 33.750679706000028],
            [71.484264816000064, 33.750651152000046],
            [71.485035762000052, 33.750479831000064],
            [71.485663940000052, 33.750365617000057],
            [71.486177903000055, 33.750365617000057],
            [71.487091616000043, 33.750479831000064],
            [71.487605580000036, 33.750765366000053],
            [71.488148097000078, 33.751250776000063],
            [71.488576400000056, 33.751450651000027],
            [71.489176024000074, 33.751764740000056],
            [71.489604327000052, 33.751850400000023],
            [71.490261058000044, 33.75199316800007],
            [71.491060557000083, 33.751964615000077],
            [71.491917163000039, 33.751793293000048],
            [71.492802322000045, 33.751736186000073],
            [71.494201445000044, 33.751450651000027],
            [71.495058051000058, 33.75136499000007],
            [71.495857550000039, 33.75136499000007],
            [71.496856924000042, 33.75136499000007],
            [71.49779919000008, 33.751393544000052],
            [71.498912778000033, 33.751393544000052],
            [71.499455295000075, 33.751393544000052],
            [71.500112026000068, 33.75136499000007],
            [71.500654544000042, 33.751250776000063],
            [71.501311275000035, 33.751193669000031],
            [71.501853792000077, 33.751079455000024],
            [71.502453416000037, 33.750936687000035],
            [71.502649235000035, 33.75098119200004],
            [71.502967822000073, 33.751289591000045],
            [71.503020402000061, 33.751335485000027],
            [71.503020668000033, 33.751335612000048],
            [71.503100798000048, 33.751001749000068],
            [71.504644000000042, 33.744572000000062],
            [71.513957883000046, 33.739269713000056],
            [71.514079066000079, 33.739206309000053],
            [71.527211645000079, 33.731676761000074],
            [71.527836000000036, 33.731320000000039],
            [71.543238742000028, 33.729608297000027],
            [71.54360338500004, 33.727775931000053],
            [71.543660188000047, 33.727496706000068],
            [71.543692039000064, 33.727340132000052],
            [71.543799097000033, 33.727083194000045],
            [71.54391331100004, 33.726626337000027],
            [71.54391331100004, 33.725998159000028],
            [71.544227400000068, 33.725227214000029],
            [71.54439872100005, 33.724484822000079],
            [71.544655703000046, 33.723514002000059],
            [71.54502689900005, 33.721800790000032],
            [71.545455202000028, 33.72051588100004],
            [71.54599771900007, 33.718602795000038],
            [71.546254701000066, 33.717432100000053],
            [71.546511683000062, 33.716689708000047],
            [71.546511683000062, 33.716061530000047],
            [71.546368915000073, 33.71531913900003],
            [71.546140487000059, 33.714719514000024],
            [71.545912059000045, 33.714119890000063],
            [71.545969166000077, 33.713548820000028],
            [71.546283254000059, 33.712606553000057],
            [71.546511683000062, 33.711978375000058],
            [71.546711557000037, 33.711350198000048],
            [71.546568790000038, 33.710550699000066],
            [71.546254701000066, 33.709865414000035],
            [71.546054826000045, 33.709094469000036],
            [71.545769291000056, 33.708323523000047],
            [71.545569416000035, 33.707095722000076],
            [71.545398095000053, 33.70572515200007],
            [71.545398095000053, 33.705382510000049],
            [71.545455202000028, 33.704954207000071],
            [71.545797844000049, 33.704611564000061],
            [71.546111933000077, 33.704411690000029],
            [71.546568790000038, 33.704069047000075],
            [71.547025646000066, 33.703697851000072],
            [71.547282628000062, 33.703269548000037],
            [71.547339735000037, 33.702755585000034],
            [71.547425396000051, 33.702155961000074],
            [71.547425396000051, 33.701727658000038],
            [71.547625270000083, 33.701385015000028],
            [71.548053573000061, 33.701613444000031],
            [71.548453323000047, 33.701813318000063],
            [71.548938733000057, 33.702013193000028],
            [71.549823892000063, 33.702327282000056],
            [71.550509177000038, 33.702527157000077],
            [71.551137355000037, 33.702698478000059],
            [71.551879747000044, 33.702869799000041],
            [71.55310754900006, 33.703326655000069],
            [71.553964154000084, 33.703526530000033],
            [71.554706546000034, 33.703526530000033],
            [71.555277617000058, 33.70349797700004],
            [71.55573447300003, 33.703383762000044],
            [71.55621988300004, 33.702984013000048],
            [71.556819508000046, 33.702527157000077],
            [71.55719070400005, 33.702155961000074],
            [71.557733221000035, 33.701556336000067],
            [71.558218631000045, 33.701042373000064],
            [71.558475612000052, 33.700556963000054],
            [71.558589827000048, 33.700100106000036],
            [71.558589827000048, 33.699586143000033],
            [71.558761148000031, 33.698815197000044],
            [71.558932469000069, 33.698187020000034],
            [71.559189451000066, 33.697273307000046],
            [71.559275111000034, 33.696816450000028],
            [71.559303665000073, 33.696331040000075],
            [71.559389325000041, 33.695845630000065],
            [71.55953209300003, 33.695560095000076],
            [71.559931843000072, 33.695531541000037],
            [71.560445806000075, 33.695531541000037],
            [71.561216751000075, 33.695588648000069],
            [71.562130465000052, 33.695617202000051],
            [71.562987070000077, 33.695674309000026],
            [71.563558141000044, 33.695731416000058],
            [71.563929337000047, 33.69575997000004],
            [71.565014371000075, 33.695931291000079],
            [71.565756763000081, 33.696102612000061],
            [71.566384941000081, 33.696159719000036],
            [71.56712733300003, 33.696502361000057],
            [71.567641296000033, 33.696673683000029],
            [71.568041046000076, 33.696673683000029],
            [71.56838368800004, 33.696530915000039],
            [71.568640670000036, 33.696131165000054],
            [71.568840544000068, 33.695817077000072],
            [71.569040419000032, 33.695388774000037],
            [71.569297401000028, 33.694846257000052],
            [71.569325954000078, 33.694332293000059],
            [71.569325954000078, 33.693875436000042],
            [71.569325954000078, 33.693190152000057],
            [71.56935450800006, 33.692647635000071],
            [71.569525829000042, 33.69196235000004],
            [71.569725704000064, 33.691619707000029],
            [71.570096900000067, 33.691248512000072],
            [71.570211114000074, 33.690620334000073],
            [71.570725077000077, 33.689992156000073],
            [71.571039166000048, 33.68947819300007],
            [71.571153380000055, 33.689078443000028],
            [71.571296148000044, 33.688535926000043],
            [71.571296148000044, 33.688107623000064],
            [71.571410362000051, 33.68739378500004],
            [71.57172445100008, 33.686879821000048],
            [71.572095647000083, 33.686594286000059],
            [71.57266671800005, 33.686280197000031],
            [71.573237788000029, 33.685937555000066],
            [71.573951627000042, 33.685594912000056],
            [71.574522697000077, 33.685395038000024],
            [71.575293643000066, 33.685223716000053],
            [71.575921820000076, 33.685252270000035],
            [71.576692766000065, 33.685309377000067],
            [71.577292390000082, 33.685366484000042],
            [71.578291763000038, 33.685537805000024],
            [71.578834280000081, 33.685537805000024],
            [71.579348244000073, 33.685280823000028],
            [71.579747993000069, 33.684966735000046],
            [71.580033529000048, 33.684652646000075],
            [71.580261957000062, 33.684281450000071],
            [71.580576046000033, 33.683681826000054],
            [71.580833028000029, 33.683339183000044],
            [71.580890135000061, 33.682396917000062],
            [71.580861581000079, 33.681768739000063],
            [71.581118563000075, 33.680997794000064],
            [71.581261331000064, 33.68062659800006],
            [71.581718187000035, 33.680169741000043],
            [71.582260704000078, 33.679941313000029],
            [71.582831775000045, 33.679741438000065],
            [71.583288631000073, 33.679427350000026],
            [71.583802595000066, 33.679198921000079],
            [71.584288005000076, 33.679056154000079],
            [71.584716308000054, 33.679056154000079],
            [71.58511605700005, 33.679256028000054],
            [71.585572914000068, 33.679598671000065],
            [71.585575444000028, 33.679601596000055],
            [71.585579956000061, 33.679606813000078],
            [71.585581537000053, 33.679607999000041],
            [71.586488124000084, 33.680656240000076],
            [71.586490795000032, 33.680658183000048],
            [71.587428893000038, 33.681340436000028],
            [71.587431546000062, 33.68134348600006],
            [71.587436276000062, 33.681348926000055],
            [71.587437735000037, 33.681349987000033],
            [71.58800182300007, 33.681998688000078],
            [71.588005137000039, 33.682001400000047],
            [71.588942231000033, 33.682768113000066],
            [71.589513301000068, 33.683082202000037],
            [71.590084372000035, 33.683224969000037],
            [71.591055192000056, 33.683253523000076],
            [71.592311547000065, 33.68296798700004],
            [71.593796331000078, 33.682882327000073],
            [71.595252561000052, 33.682768113000066],
            [71.59619482800008, 33.682768113000066],
            [71.596823005000033, 33.682910880000065],
            [71.59742262900005, 33.683196416000044],
            [71.598136468000064, 33.683796040000061],
            [71.598764645000074, 33.684281450000071],
            [71.599307163000049, 33.684424218000061],
            [71.600335090000044, 33.684424218000061],
            [71.601277356000082, 33.684195789000057],
            [71.60233383700006, 33.684110129000032],
            [71.604161263000037, 33.684053022000057],
            [71.605588940000075, 33.684281450000071],
            [71.607302151000056, 33.684167236000064],
            [71.608986810000033, 33.683995915000025],
            [71.610814236000067, 33.683881700000029],
            [71.611556628000073, 33.683910254000068],
            [71.612241912000059, 33.683938807000061],
            [71.613384054000051, 33.684024468000075],
            [71.614354874000071, 33.684195789000057],
            [71.615725443000031, 33.684595539000043],
            [71.616610603000083, 33.684881074000032],
            [71.617267334000076, 33.685052395000071],
            [71.618152493000082, 33.685337931000049],
            [71.619123313000046, 33.685709126000063],
            [71.619637277000038, 33.685823341000059],
            [71.620037026000034, 33.685794787000077],
            [71.620493883000051, 33.685623466000038],
            [71.620865079000055, 33.685623466000038],
            [71.621436150000079, 33.685566359000063],
            [71.621978667000064, 33.685509252000031],
            [71.62237841600006, 33.685652019000031],
            [71.622721058000081, 33.685737680000045],
            [71.622723392000069, 33.685741347000032],
            [71.622727553000061, 33.685747886000058],
            [71.622729331000073, 33.685748330000024],
            [71.622924222000051, 33.686054588000047],
            [71.622930087000043, 33.686059615000033],
            [71.623320683000031, 33.686394411000038],
            [71.623324794000041, 33.686398522000047],
            [71.623332126000037, 33.686405854000043],
            [71.623663325000052, 33.686737054000048],
            [71.62426294900007, 33.687222464000058],
            [71.624776913000062, 33.687336678000065],
            [71.625547858000061, 33.687679320000029],
            [71.626804213000071, 33.687822088000075],
            [71.627518052000084, 33.68802196200005],
            [71.62752181500008, 33.688025976000063],
            [71.627528525000059, 33.688033134000079],
            [71.627528873000074, 33.688033231000077],
            [71.627946784000073, 33.688479003000054],
            [71.627947550000044, 33.688479331000053],
            [71.62854597900008, 33.688735801000064],
            [71.629031389000033, 33.689021336000053],
            [71.629402585000037, 33.689221211000074],
            [71.629830888000072, 33.689535300000045],
            [71.630344851000075, 33.689649514000052],
            [71.630915922000042, 33.689877942000066],
            [71.631601207000074, 33.690020710000056],
            [71.632115170000077, 33.690020710000056],
            [71.632543473000055, 33.689992156000073],
            [71.633057437000048, 33.689735174000077],
            [71.633942596000054, 33.689449639000031],
            [71.634513667000078, 33.689164104000042],
            [71.635256059000028, 33.688964229000078],
            [71.636141218000034, 33.688907122000046],
            [71.63691216400008, 33.688907122000046],
            [71.637626002000047, 33.689078443000028],
            [71.638025751000043, 33.689421086000038],
            [71.63802875600004, 33.68942424800008],
            [71.63803411300006, 33.689429888000063],
            [71.638035220000063, 33.689430837000032],
            [71.638572380000028, 33.689996267000026],
            [71.639086343000031, 33.690538784000069],
            [71.639769801000057, 33.691393106000078],
            [71.63977387400007, 33.69139636500006],
            [71.640338587000031, 33.691848136000033],
            [71.640340590000051, 33.691850560000034],
            [71.640344162000076, 33.691854884000065],
            [71.640346270000066, 33.691856571000073],
            [71.640885216000072, 33.692508978000035],
            [71.640999430000079, 33.693051495000077],
            [71.641113644000029, 33.693736780000052],
            [71.641113644000029, 33.694079422000073],
            [71.641170572000078, 33.694477921000043],
            [71.641177585000037, 33.694483021000053],
            [71.641480729000079, 33.694703489000062],
            [71.642280228000061, 33.694846257000052],
            [71.643108280000035, 33.694846257000052],
            [71.643765011000085, 33.694960471000059],
            [71.644535957000073, 33.695017578000034],
            [71.644850045000055, 33.695246006000048],
            [71.644852101000083, 33.695248622000065],
            [71.644855767000081, 33.69525328800006],
            [71.644857822000063, 33.695254783000053],
            [71.645168245000036, 33.695649867000043],
            [71.64539667400004, 33.696220937000078],
            [71.645596548000071, 33.696934776000035],
            [71.645853530000068, 33.697819935000041],
            [71.64602485100005, 33.698248238000076],
            [71.64627818200006, 33.698529716000053],
            [71.646279002000028, 33.69852981300005],
            [71.646763132000046, 33.69858676900003],
            [71.647419863000039, 33.69858676900003],
            [71.648818986000038, 33.698415448000048],
            [71.649390057000062, 33.698386894000066],
            [71.650161002000061, 33.698272680000059],
            [71.65093194800005, 33.698129913000059],
            [71.65141735800006, 33.697872931000063],
            [71.652388178000081, 33.69750173500006],
            [71.653073462000066, 33.697130539000057],
            [71.653644533000033, 33.696588022000071],
            [71.654101390000051, 33.696273933000043],
            [71.654786674000036, 33.695959844000072],
            [71.655329191000078, 33.695817077000072],
            [71.656556993000038, 33.695731416000058],
            [71.657213725000076, 33.695645755000044],
            [71.657216761000029, 33.69565000700004],
            [71.657222177000051, 33.695657588000074],
            [71.657223251000062, 33.695657448000077],
            [71.657360603000029, 33.695849741000075],
            [71.657360603000029, 33.696163830000046],
            [71.657246389000079, 33.696763454000063],
            [71.656703872000037, 33.69736307900007],
            [71.656446890000041, 33.697734274000027],
            [71.65599003400007, 33.698076917000037],
            [71.656046445000072, 33.698387179000065],
            [71.656052536000061, 33.698387687000036],
            [71.656385672000056, 33.698415448000048],
            [71.656928189000041, 33.698444002000031],
            [71.657385046000059, 33.698444002000031],
            [71.658070330000044, 33.698415448000048],
            [71.658498633000079, 33.698358341000073],
            [71.659526561000064, 33.698329787000034],
            [71.660440274000052, 33.698044252000045],
            [71.661097005000045, 33.697815824000031],
            [71.662239146000047, 33.697558842000035],
            [71.663409841000032, 33.697330414000078],
            [71.664266447000045, 33.697244753000064],
            [71.665123053000059, 33.697416074000046],
            [71.665836891000083, 33.697644503000049],
            [71.666750604000072, 33.698158466000052],
            [71.66766431700006, 33.698472555000023],
            [71.668977780000034, 33.69910073300008],
            [71.669634511000083, 33.699329161000037],
            [71.670662438000079, 33.699614696000026],
            [71.67174747200005, 33.699900232000061],
            [71.672775399000045, 33.700128660000075],
            [71.673375023000062, 33.700528409000071],
            [71.673376747000077, 33.700530929000024],
            [71.673379822000072, 33.700535423000076],
            [71.673382209000067, 33.700537014000076],
            [71.673750330000075, 33.701075038000056],
            [71.674007312000072, 33.701874537000037],
            [71.674007312000072, 33.70238850000004],
            [71.673407688000054, 33.703559195000025],
            [71.672893725000051, 33.704558568000039],
            [71.67257963600008, 33.705215300000077],
            [71.672804489000043, 33.705468260000032],
            [71.672805445000051, 33.705468419000056],
            [71.67331791600003, 33.705553831000032],
            [71.673917540000048, 33.705696599000078],
            [71.674602825000079, 33.706039241000042],
            [71.675602199000082, 33.70649609700007],
            [71.676630126000077, 33.707209936000027],
            [71.677115536000031, 33.707666792000055],
            [71.67762950000008, 33.707866667000076],
            [71.677633117000084, 33.70787058600007],
            [71.677639569000064, 33.707877576000044],
            [71.677640063000069, 33.70787776800006],
            [71.677972599000043, 33.708238015000063],
            [71.677973413000075, 33.708238287000029],
            [71.678657427000076, 33.708466291000036],
            [71.679571140000064, 33.708609059000025],
            [71.680199317000074, 33.708780380000064],
            [71.680741835000049, 33.709037362000061],
            [71.681455673000073, 33.709265790000075],
            [71.681969636000076, 33.709694093000053],
            [71.682397939000055, 33.709722646000046],
            [71.682969010000079, 33.709779754000067],
            [71.683682848000046, 33.709779754000067],
            [71.684653668000067, 33.710036735000074],
            [71.685281846000066, 33.710179503000063],
            [71.685824363000052, 33.710379378000027],
            [71.68613845200008, 33.710636359000034],
            [71.686538201000076, 33.710664913000073],
            [71.687023611000029, 33.710636359000034],
            [71.687423361000072, 33.71029371700007],
            [71.687908771000082, 33.709836861000042],
            [71.688279967000028, 33.709408558000064],
            [71.688708270000063, 33.708980255000029],
            [71.689336448000063, 33.708380630000079],
            [71.689707643000077, 33.708009435000065],
            [71.69007883900008, 33.707809560000044],
            [71.690507142000058, 33.707781006000062],
            [71.691021106000051, 33.707895220000069],
            [71.691849158000082, 33.708180756000047],
            [71.693105514000081, 33.708523398000068],
            [71.693619477000084, 33.70869471900005],
            [71.69450463700008, 33.708980255000029],
            [71.695475457000043, 33.709208683000043],
            [71.696246402000043, 33.709408558000064],
            [71.69670325900006, 33.709608432000039],
            [71.69733143600007, 33.709865414000035],
            [71.697616972000048, 33.709893968000074],
            [71.698016721000045, 33.70980830700006],
            [71.698616345000062, 33.709522772000071],
            [71.699101755000072, 33.709237236000035],
            [71.699615719000064, 33.709123022000028],
            [71.70015823600005, 33.709008808000078],
            [71.700814967000042, 33.708894594000071],
            [71.701500252000073, 33.708837487000039],
            [71.702071323000041, 33.708780380000064],
            [71.702642393000076, 33.708637612000075],
            [71.702956482000047, 33.708380630000079],
            [71.703156357000069, 33.708095095000033],
            [71.703242017000036, 33.707781006000062],
            [71.703384785000082, 33.707381257000065],
            [71.703527553000072, 33.707038615000045],
            [71.703813088000061, 33.706753079000066],
            [71.703841642000043, 33.706438990000038],
            [71.703698874000054, 33.706096348000074],
            [71.703498999000033, 33.705439617000025],
            [71.703184910000061, 33.704839993000064],
            [71.703013589000079, 33.704297475000033],
            [71.702927929000055, 33.703840619000061],
            [71.70298503600003, 33.703440870000065],
            [71.703042143000062, 33.703098227000055],
            [71.703356231000043, 33.702755585000034],
            [71.703755981000029, 33.702470049000055],
            [71.704469819000053, 33.702241621000042],
            [71.704869569000039, 33.701898979000077],
            [71.705097997000053, 33.701699104000056],
            [71.704881012000044, 33.701482119000048],
            [71.704873680000048, 33.701474787000052],
            [71.704388270000038, 33.70130346600007],
            [71.703874306000046, 33.701103591000049],
            [71.703538216000084, 33.700823516000071],
            [71.703537778000054, 33.700822466000034],
            [71.703531227000042, 33.700817006000079],
            [71.703527553000072, 33.70081394500005],
            [71.703384785000082, 33.700471302000039],
            [71.703384785000082, 33.70018576700005],
            [71.703413339000065, 33.699671803000058],
            [71.70361321300004, 33.69921494700003],
            [71.703698874000054, 33.698815197000044],
            [71.703424781000081, 33.698541105000061],
            [71.703417450000074, 33.698533773000065],
            [71.703017700000032, 33.698419559000058],
            [71.702503737000029, 33.698419559000058],
            [71.702132541000083, 33.698276792000058],
            [71.701590024000041, 33.698048363000055],
            [71.701585913000031, 33.698044252000045],
            [71.701083392000044, 33.697541731000058],
            [71.701076060000048, 33.697534400000052],
            [71.700105240000084, 33.696706347000031],
            [71.699162974000046, 33.696163830000046],
            [71.698192153000036, 33.695678420000036],
            [71.697278440000048, 33.695335778000072],
            [71.696478942000056, 33.694936028000029],
            [71.695279693000032, 33.694479172000058],
            [71.694166106000068, 33.694193637000069],
            [71.69350937400003, 33.693793887000027],
            [71.693505263000077, 33.693789776000074],
            [71.693316831000061, 33.693601344000058],
            [71.693309500000055, 33.693594012000062],
            [71.693305388000056, 33.693589901000053],
            [71.693333942000038, 33.693275812000024],
            [71.693733691000034, 33.692990277000035],
            [71.694076334000044, 33.692733295000039],
            [71.694418976000065, 33.69244776000005],
            [71.694418976000065, 33.692190778000054],
            [71.694247655000083, 33.691733922000026],
            [71.693996042000038, 33.691370481000035],
            [71.693992602000037, 33.691365512000061],
            [71.693195285000058, 33.690881427000079],
            [71.692367233000084, 33.690396017000069],
            [71.691710502000035, 33.689967714000034],
            [71.691053771000043, 33.689910607000058],
            [71.690397039000061, 33.689682179000044],
            [71.689911629000051, 33.689339536000034],
            [71.68942621900004, 33.68899689400007],
            [71.688712381000073, 33.688340163000078],
            [71.687598793000063, 33.687283682000043],
            [71.686028349000082, 33.685941666000076],
            [71.684971868000048, 33.684942292000073],
            [71.683572745000049, 33.683800151000071],
            [71.68231639000004, 33.682772224000075],
            [71.681719689000033, 33.682317595000029],
            [71.681717217000084, 33.682314733000055],
            [71.681714294000074, 33.682312505000027],
            [71.681712655000069, 33.682311256000048],
            [71.681170137000038, 33.681683078000049],
            [71.680749087000038, 33.681205888000079],
            [71.680744440000069, 33.681200622000063],
            [71.680381588000046, 33.680921505000072],
            [71.680381312000065, 33.680920343000025],
            [71.680374473000029, 33.680915083000059],
            [71.680370639000046, 33.680912133000049],
            [71.680227871000056, 33.680312509000032],
            [71.680284978000032, 33.679969867000068],
            [71.680399192000039, 33.679712885000072],
            [71.680913156000031, 33.679455903000076],
            [71.681826869000076, 33.679284582000037],
            [71.682540707000044, 33.67917036800003],
            [71.683083224000029, 33.679056154000079],
            [71.68391127700005, 33.678884832000051],
            [71.684682222000049, 33.678627851000044],
            [71.685253292000084, 33.67842797600008],
            [71.685767256000076, 33.678228101000059],
            [71.686195559000055, 33.677914012000031],
            [71.686538201000076, 33.677742691000049],
            [71.686966504000054, 33.677485709000052],
            [71.687137826000082, 33.67717162100007],
            [71.687137826000082, 33.676686210000071],
            [71.686995058000036, 33.676229354000043],
            [71.686766630000079, 33.67585815800004],
            [71.686481094000044, 33.67522998000004],
            [71.686195559000055, 33.674915892000058],
            [71.686024238000073, 33.674316267000052],
            [71.685710149000045, 33.673630983000066],
            [71.685567381000055, 33.672974251000028],
            [71.685567381000055, 33.672460288000025],
            [71.685653042000069, 33.671889217000057],
            [71.685681595000062, 33.671375254000054],
            [71.685483866000084, 33.670810313000061],
            [71.685482492000062, 33.670806385000049],
            [71.685481721000031, 33.67080418300003],
            [71.68488209700007, 33.670461541000066],
            [71.684425240000053, 33.670061791000023],
            [71.683569335000072, 33.669776489000071],
            [71.683568886000046, 33.669776340000055],
            [71.683568634000039, 33.669776256000034],
            [71.682911903000047, 33.66909097100006],
            [71.682434531000069, 33.668529357000068],
            [71.682429381000077, 33.668523298000025],
            [71.682426493000037, 33.668519900000035],
            [71.682095672000059, 33.668326922000062],
            [71.682088098000065, 33.66832250300007],
            [71.682083850000083, 33.66832002600006],
            [71.682112404000065, 33.66797738300005],
            [71.682712028000083, 33.667577634000054],
            [71.683340206000082, 33.667035117000069],
            [71.683854169000028, 33.666663921000065],
            [71.684082598000032, 33.666406939000069],
            [71.684453794000035, 33.665521780000063],
            [71.684824990000038, 33.664807941000049],
            [71.685253292000084, 33.663751461000061],
            [71.68625266600003, 33.661724160000063],
            [71.686566755000058, 33.66121019600007],
            [71.686795183000072, 33.660696233000067],
            [71.686823737000054, 33.66023937600005],
            [71.686823737000054, 33.659668306000071],
            [71.686766630000079, 33.659040128000072],
            [71.68673807600004, 33.658440504000055],
            [71.687052165000068, 33.658183522000058],
            [71.687680343000068, 33.658069308000051],
            [71.688308520000078, 33.657897987000069],
            [71.68896525200006, 33.657755219000023],
            [71.68945066200007, 33.657355470000027],
            [71.689878965000048, 33.657184148000056],
            [71.690364375000058, 33.65726980900007],
            [71.691049659000043, 33.657583898000041],
            [71.691906265000057, 33.657955094000044],
            [71.692448782000042, 33.658126415000027],
            [71.693419603000052, 33.658240629000034],
            [71.694104887000037, 33.658354843000041],
            [71.695047154000065, 33.658611825000037],
            [71.695818099000064, 33.65898302100004],
            [71.69718866900007, 33.659811073000071],
            [71.698188042000083, 33.660524912000028],
            [71.701500252000073, 33.661981142000059],
            [71.703213464000044, 33.66278064100004],
            [71.704698247000067, 33.66340881800005],
            [71.705840389000059, 33.663951335000036],
            [71.706754102000048, 33.664236871000071],
            [71.707553601000029, 33.66452240600006],
            [71.708295992000046, 33.664779388000056],
            [71.708867063000071, 33.665036370000053],
            [71.709323920000031, 33.66515058400006],
            [71.709609455000077, 33.665036370000053],
            [71.710151972000062, 33.664665174000049],
            [71.710808703000055, 33.664179764000039],
            [71.711265560000072, 33.663580140000079],
            [71.711579649000043, 33.663266051000051],
            [71.711950844000057, 33.662609319000069],
            [71.712293487000068, 33.662266677000048],
            [71.712407701000075, 33.661866928000052],
            [71.712721790000046, 33.66183837400007],
            [71.713150093000081, 33.661895481000045],
            [71.713635503000035, 33.662009695000052],
            [71.714149466000038, 33.662066802000027],
            [71.714720537000062, 33.662181016000034],
            [71.71529160800003, 33.662352338000062],
            [71.716205321000075, 33.66243799800003],
            [71.71674783800006, 33.662637873000051],
            [71.717147587000056, 33.662694980000026],
            [71.717775765000056, 33.662666426000044],
            [71.718432496000048, 33.662666426000044],
            [71.719260549000069, 33.662666426000044],
            [71.720574011000053, 33.662809194000033],
            [71.722230116000048, 33.663180390000036],
            [71.72305816800008, 33.663465925000025],
            [71.723629239000047, 33.663751461000061],
            [71.723633350000057, 33.663755572000071],
            [71.723640682000052, 33.663762904000066],
            [71.72400043500005, 33.664122657000064],
            [71.72462861200006, 33.664408192000053],
            [71.725142576000053, 33.664493853000067],
            [71.725656540000045, 33.664493853000067],
            [71.726170503000048, 33.66452240600006],
            [71.726570253000034, 33.664807941000049],
            [71.72745541200004, 33.665493226000024],
            [71.728768875000071, 33.666435493000051],
            [71.729168624000067, 33.66672102800004],
            [71.729853909000042, 33.666835242000047],
            [71.730596301000048, 33.66686379600003],
            [71.73153856700003, 33.667149331000076],
            [71.733537314000046, 33.667748955000036],
            [71.735022098000059, 33.668348579000053],
            [71.737135059000082, 33.66909097100006],
            [71.73867695000007, 33.669804809000027],
            [71.739476449000051, 33.670090345000062],
            [71.740447269000072, 33.670347326000069],
            [71.741303875000028, 33.670518648000041],
            [71.74184639200007, 33.670718522000072],
            [71.741849941000055, 33.670722427000044],
            [71.741856271000074, 33.67072938900003],
            [71.741856833000043, 33.670729596000058],
            [71.742136039000059, 33.671036722000053],
            [71.742164592000051, 33.671493579000071],
            [71.742136039000059, 33.672521506000066],
            [71.742364467000073, 33.67349232600003],
            [71.742621449000069, 33.674091950000047],
            [71.742964091000033, 33.674720128000047],
            [71.743192519000047, 33.67509132400005],
            [71.743306733000054, 33.675405413000078],
            [71.743249626000079, 33.675776609000025],
            [71.743106859000079, 33.675976483000056],
            [71.74259289500003, 33.676576108000063],
            [71.741793396000048, 33.677546928000027],
            [71.740965344000074, 33.678603408000072],
            [71.740308613000082, 33.67951712100006],
            [71.739851756000064, 33.680259513000067],
            [71.739480560000061, 33.681144673000063],
            [71.73913791800004, 33.683685937000064],
            [71.738966597000058, 33.684485436000045],
            [71.738880936000044, 33.684742418000042],
            [71.73850974000004, 33.684828078000066],
            [71.73802433000003, 33.684828078000066],
            [71.737396152000031, 33.685027953000031],
            [71.737110617000042, 33.685142167000038],
            [71.736996403000035, 33.685370595000052],
            [71.736996403000035, 33.68565613100003],
            [71.737053396000078, 33.685912598000073],
            [71.737060524000071, 33.685919014000035],
            [71.737334934000046, 33.686165983000024],
            [71.737877451000031, 33.686251644000038],
            [71.738562736000063, 33.686537179000027],
            [71.739476449000051, 33.68679416100008],
            [71.740304501000082, 33.687079696000069],
            [71.740932679000082, 33.687507999000047],
            [71.741903499000045, 33.688307498000029],
            [71.74261733700007, 33.68899278300006],
            [71.742618633000063, 33.688994191000063],
            [71.742620943000077, 33.688996701000065],
            [71.742623759000082, 33.688999405000061],
            [71.743278180000061, 33.689710732000037],
            [71.743820697000046, 33.690424570000062],
            [71.744363214000032, 33.69119551600005],
            [71.74450506800008, 33.691479224000034],
            [71.744510770000034, 33.691483297000048],
            [71.744901620000064, 33.691762475000075],
            [71.745272816000067, 33.691791029000058],
            [71.745872440000028, 33.691791029000058],
            [71.747043135000069, 33.692048010000065],
            [71.749470185000064, 33.69244776000005],
            [71.750954969000077, 33.692590528000039],
            [71.75192578900004, 33.692504867000025],
            [71.75255396600005, 33.692304992000061],
            [71.752982269000029, 33.69196235000004],
            [71.753410572000064, 33.691419833000054],
            [71.753895982000074, 33.690991530000076],
            [71.754809695000063, 33.690763102000062],
            [71.755723408000051, 33.690591780000034],
            [71.756380140000033, 33.690134924000063],
            [71.757265299000039, 33.689249764000067],
            [71.758179012000085, 33.688364605000061],
            [71.758892850000052, 33.687336678000065],
            [71.759492474000069, 33.686879821000048],
            [71.760177759000044, 33.686679947000073],
            [71.76092015100005, 33.686451518000069],
            [71.76169109600005, 33.686280197000031],
            [71.762576256000045, 33.686165983000024],
            [71.763375755000084, 33.686080322000066],
            [71.764460789000054, 33.685994662000041],
            [71.765260288000036, 33.685766234000027],
            [71.765945573000067, 33.685395038000024],
            [71.766516643000045, 33.684823967000057],
            [71.767230482000059, 33.684281450000071],
            [71.768029980000051, 33.683938807000061],
            [71.769057908000036, 33.683796040000061],
            [71.770000174000074, 33.683710379000047],
            [71.770999548000077, 33.683510505000072],
            [71.771970368000041, 33.683282076000069],
            [71.773112509000043, 33.683025094000072],
            [71.774226097000053, 33.682653899000059],
            [71.775339684000073, 33.682282703000055],
            [71.77605352300003, 33.682082828000034],
            [71.776824468000029, 33.681597418000024],
            [71.777766735000057, 33.680912133000049],
            [71.77813793100006, 33.680655151000053],
            [71.77832378100004, 33.680546738000032],
            [71.778327402000059, 33.680551136000076],
            [71.77832789200005, 33.680550850000031],
            [71.778324318000045, 33.680546509000067],
            [71.779345026000044, 33.680110194000065],
            [71.801464899000052, 33.67278648000007],
            [71.800555089000056, 33.663082992000056],
            [71.800181000000066, 33.659092000000044],
            [71.801507000000072, 33.641863000000058],
            [71.823374000000058, 33.641200000000026],
            [71.823994603000074, 33.640786276000028],
            [71.825169951000078, 33.640002733000074],
            [71.841265000000078, 33.629273000000069],
            [71.833976000000064, 33.615357000000074],
            [71.826024000000075, 33.606080000000077],
            [71.803495000000055, 33.597466000000054],
            [71.790962340000078, 33.592017446000057],
            [71.789630468000041, 33.591438417000063],
            [71.788510153000061, 33.590951362000055],
            [71.788254000000052, 33.590840000000071],
            [71.783600580000041, 33.592701368000064],
            [71.77831400000008, 33.594816000000037],
            [71.772599882000065, 33.591767780000055],
            [71.772596328000077, 33.591765885000029],
            [71.772592775000078, 33.591763988000025],
            [71.768375000000049, 33.589514000000065],
            [71.756447000000037, 33.576262000000042],
            [71.734580000000051, 33.576262000000042],
            [71.723978000000045, 33.566322000000071],
            [71.700785000000053, 33.560358000000065],
            [71.685894025000039, 33.552446906000057],
            [71.685892484000078, 33.552454241000078],
            [71.679579793000073, 33.549100512000052],
            [71.663013793000061, 33.55373951200005],
            [71.639820793000069, 33.55373951200005],
            [71.638903575000029, 33.553253891000054],
            [71.628555805000076, 33.547775498000078],
            [71.61331580500007, 33.545124498000064],
            [71.602050805000033, 33.543136498000024],
            [71.598967461000029, 33.546038646000056],
            [71.598965643000042, 33.546040357000038],
            [71.590785805000053, 33.553739498000027],
            [71.580140972000038, 33.564378020000049],
            [71.580140923000044, 33.564378068000053],
            [71.580104262000077, 33.564414738000039],
            [71.579522000000054, 33.564997000000062],
            [71.571570000000065, 33.570298000000037],
            [71.566932000000065, 33.579575000000034],
            [71.57885900000008, 33.582888000000025],
            [71.591339646000051, 33.582888000000025],
            [71.593437000000051, 33.582888000000025],
            [71.595767923000039, 33.585398037000061],
            [71.602052000000072, 33.59216500000008],
            [71.609341000000029, 33.601442000000077],
            [71.616630000000043, 33.616020000000049],
            [71.630545000000041, 33.629936000000043],
            [71.634521000000063, 33.643851000000041],
            [71.626569000000075, 33.643851000000041],
            [71.606690000000071, 33.635237000000075],
            [71.602052000000072, 33.634574000000043],
            [71.595652051000059, 33.634768044000054],
            [71.595639523000045, 33.634768424000072],
            [71.59562950000003, 33.63476872800004],
            [71.580185000000029, 33.635237000000075],
            [71.566269000000034, 33.637225000000058],
            [71.554342000000076, 33.643851000000041],
            [71.53976300000005, 33.636562000000026],
            [71.528093376000072, 33.633104358000026],
            [71.52187200000003, 33.631261000000052],
            [71.49470400000007, 33.631261000000052],
            [71.476150000000075, 33.630598000000077],
            [71.471975601000054, 33.633189051000045],
            [71.462756710000065, 33.638911221000058],
            [71.462749271000064, 33.638915838000059],
            [71.462741832000063, 33.638920455000061],
            [71.458234516000061, 33.641718149000042],
            [71.456933000000049, 33.642526000000032],
            [71.443681000000083, 33.648489000000041],
            [71.435193079000044, 33.655279337000024],
            [71.433741000000055, 33.656441000000029],
            [71.432294385000034, 33.655675123000037],
            [71.432083361000082, 33.655563401000052],
            [71.422476000000074, 33.650477000000024],
            [71.419163000000083, 33.643188000000066],
            [71.408561000000077, 33.645176000000049],
            [71.408109595000042, 33.645428788000061],
            [71.396248924000076, 33.652070792000075],
            [71.396294118000071, 33.652085254000042],
            [71.396744325000043, 33.65222932000006],
            [71.397372502000053, 33.65237208800005],
            [71.398086341000067, 33.652400642000032],
            [71.398885839000059, 33.652429195000025],
            [71.399257035000062, 33.652429195000025],
            [71.39968533800004, 33.652400642000032],
            [71.399828106000029, 33.652029446000029],
            [71.400142195000058, 33.651458375000061],
            [71.400399177000054, 33.651030072000026],
            [71.400713265000036, 33.650573215000065],
            [71.40128433600006, 33.650430448000066],
            [71.401798300000053, 33.650459001000058],
            [71.402226603000031, 33.650744537000037],
            [71.402232468000079, 33.65075089100003],
            [71.402569245000052, 33.65111573300004],
            [71.402570292000064, 33.65111625600008],
            [71.402968994000048, 33.651315607000072],
            [71.403540065000072, 33.651401268000029],
            [71.403882707000037, 33.651344161000054],
            [71.404196796000065, 33.651030072000026],
            [71.404653653000082, 33.650744537000037],
            [71.405139063000036, 33.650687430000062],
            [71.405653026000039, 33.65065887600008],
            [71.406024222000042, 33.650715983000055],
            [71.406566739000084, 33.65111573300004],
            [71.406569268000055, 33.65111952500007],
            [71.406795168000031, 33.651458375000061],
            [71.407166364000034, 33.652143660000036],
            [71.407166364000034, 33.652571963000071],
            [71.407194917000083, 33.653000266000049],
            [71.40756611300003, 33.653457122000077],
            [71.407568862000062, 33.653458814000032],
            [71.407937309000033, 33.653685550000034],
            [71.407938142000035, 33.653688883000029],
            [71.40805152300004, 33.654142407000052],
            [71.408022969000058, 33.65457071000003],
            [71.408022969000058, 33.655370209000068],
            [71.407908755000051, 33.655855619000079],
            [71.407908755000051, 33.656483797000078],
            [71.407908755000051, 33.657169081000063],
            [71.407908755000051, 33.657825813000045],
            [71.407937309000033, 33.658882293000033],
            [71.407880202000058, 33.659738899000047],
            [71.407880202000058, 33.660309970000071],
            [71.407823095000083, 33.660909594000032],
            [71.407794541000044, 33.661423557000035],
            [71.407480452000073, 33.661880414000052],
            [71.407194917000083, 33.662223056000073],
            [71.406652400000041, 33.662394378000045],
            [71.406109883000056, 33.662594252000076],
            [71.405396045000032, 33.66282268100008],
            [71.404796420000082, 33.66296544800008],
            [71.404225350000047, 33.663136769000062],
            [71.403882707000037, 33.66356507200004],
            [71.40376849300003, 33.66390771500005],
            [71.403739940000037, 33.664336018000029],
            [71.403739940000037, 33.66467866000005],
            [71.403454404000058, 33.664964195000039],
            [71.402712013000041, 33.665392498000074],
            [71.402255156000081, 33.665763694000077],
            [71.401883960000077, 33.666049230000056],
            [71.401712639000039, 33.666363318000037],
            [71.401712639000039, 33.666734514000041],
            [71.401741193000078, 33.667105710000044],
            [71.402083835000042, 33.667505460000029],
            [71.40239792400007, 33.668076530000064],
            [71.402403871000047, 33.668081345000076],
            [71.40299754800003, 33.668561940000075],
            [71.402999673000068, 33.668565057000023],
            [71.403425851000065, 33.669190118000074],
            [71.403430478000075, 33.669193483000072],
            [71.403739940000037, 33.669418546000031],
            [71.403747271000043, 33.669425878000027],
            [71.40411113600004, 33.669789742000034],
            [71.404567992000068, 33.670046724000031],
            [71.405024849000029, 33.670360813000059],
            [71.405032180000035, 33.670368145000054],
            [71.405310384000074, 33.670646348000048],
            [71.405767240000046, 33.670760562000055],
            [71.406224097000063, 33.670874776000062],
            [71.406795168000031, 33.671188865000033],
            [71.407423345000041, 33.671645722000051],
            [71.408251398000061, 33.67241666700005],
            [71.408936682000046, 33.673073398000042],
            [71.409621967000078, 33.673701576000042],
            [71.409625474000052, 33.673705401000063],
            [71.410250145000077, 33.674386861000073],
            [71.410255033000055, 33.674391526000079],
            [71.410878323000077, 33.674986485000034],
            [71.410878641000068, 33.674987122000061],
            [71.411192411000059, 33.675614663000033],
            [71.411649268000076, 33.676299947000075],
            [71.412020464000079, 33.676956679000057],
            [71.412049017000072, 33.677670517000024],
            [71.412049017000072, 33.678355802000056],
            [71.411792036000065, 33.679383729000051],
            [71.411620714000037, 33.680097567000075],
            [71.411220965000041, 33.680668638000043],
            [71.410592787000041, 33.681096941000078],
            [71.410107377000031, 33.681496690000074],
            [71.409479200000078, 33.681810779000045],
            [71.408793915000047, 33.682267635000073],
            [71.408108630000072, 33.682781599000066],
            [71.407594667000069, 33.683267009000076],
            [71.407080703000076, 33.683695312000054],
            [71.406309758000077, 33.683980847000043],
            [71.405824348000067, 33.684209276000047],
            [71.404482332000043, 33.684837453000057],
            [71.403739940000037, 33.685151542000028],
            [71.403168869000069, 33.685437077000074],
            [71.402312263000056, 33.685722613000053],
            [71.401455657000042, 33.685979595000049],
            [71.40079892600005, 33.686350791000052],
            [71.399970874000076, 33.686693433000073],
            [71.399314142000037, 33.686978968000062],
            [71.398571751000077, 33.687464378000072],
            [71.397857912000063, 33.687949788000026],
            [71.397058413000082, 33.688378091000061],
            [71.395973379000054, 33.688863501000071],
            [71.395059666000066, 33.68914903700005],
            [71.394231614000034, 33.689320358000032],
            [71.393403561000071, 33.68963444700006],
            [71.392661170000054, 33.689805768000042],
            [71.391861671000072, 33.690148410000063],
            [71.390890851000051, 33.690576713000041],
            [71.390405441000041, 33.690919356000052],
            [71.389720156000067, 33.69134765900003],
            [71.388863550000053, 33.691861622000033],
            [71.388092604000065, 33.692232818000036],
            [71.387207445000058, 33.69260401400004],
            [71.386322286000052, 33.693203638000057],
            [71.385465680000038, 33.693660495000074],
            [71.38469473400005, 33.69420301200006],
            [71.38392378900005, 33.694631315000038],
            [71.382895862000055, 33.69517383200008],
            [71.381896488000052, 33.695859117000055],
            [71.381096989000071, 33.696515848000047],
            [71.38061157900006, 33.696772830000043],
            [71.380268937000039, 33.697201132000032],
            [71.380211830000064, 33.697743650000064],
            [71.380211830000064, 33.698229060000074],
            [71.380468812000061, 33.698828684000034],
            [71.380840007000074, 33.699656736000065],
            [71.38172516700007, 33.700913092000064],
            [71.382553219000044, 33.701998126000035],
            [71.383324165000033, 33.702911839000024],
            [71.383781021000061, 33.70368278400008],
            [71.384380645000078, 33.704396622000047],
            [71.384609074000082, 33.704824925000025],
            [71.384666181000057, 33.705481657000064],
            [71.384666181000057, 33.706024174000049],
            [71.384494860000075, 33.706595244000027],
            [71.384237878000079, 33.707109208000077],
            [71.383980896000082, 33.707708832000037],
            [71.383781021000061, 33.70822279600003],
            [71.383695361000036, 33.708822420000047],
            [71.383695361000036, 33.70933638300005],
            [71.383838128000036, 33.709793240000067],
            [71.384095110000032, 33.710364311000035],
            [71.384637627000075, 33.711306577000073],
            [71.385151591000067, 33.712477272000058],
            [71.385465680000038, 33.713419538000039],
            [71.385637001000077, 33.714447466000024],
            [71.385637001000077, 33.715047090000041],
            [71.38552278700007, 33.715446839000037],
            [71.38518014400006, 33.71598935600008],
            [71.384808948000057, 33.716389106000065],
            [71.384294985000054, 33.716817409000043],
            [71.38358114600004, 33.716903069000068],
            [71.382753094000066, 33.717217158000039],
            [71.381753720000063, 33.717616908000025],
            [71.380897115000039, 33.71804521100006],
            [71.38012616900005, 33.718416406000074],
            [71.379298117000076, 33.71867338800007],
            [71.378555725000069, 33.718844709000052],
            [71.377756226000031, 33.718958924000049],
            [71.376928174000057, 33.719130245000031],
            [71.376014461000068, 33.719215905000056],
            [71.375186408000047, 33.71930156600007],
            [71.374215588000084, 33.719472887000052],
            [71.373501750000059, 33.719587101000059],
            [71.373102000000074, 33.719644208000034],
            [71.37273080500006, 33.719729869000048],
            [71.37238816200005, 33.71978697600008],
            [71.372045520000029, 33.71978697600008],
            [71.371731431000057, 33.719758422000041],
            [71.371475838000038, 33.719701624000038],
            [71.371167692000029, 33.719337451000058],
            [71.371166121000044, 33.719336927000029],
            [71.371160360000033, 33.719330119000062],
            [71.370817718000069, 33.719215905000056],
            [71.370417968000083, 33.719330119000062],
            [71.370018219000031, 33.719558548000066],
            [71.369618470000034, 33.71990119000003],
            [71.368961738000053, 33.720158172000026],
            [71.368504882000082, 33.720443707000072],
            [71.367990918000032, 33.720729242000061],
            [71.367562615000054, 33.721043331000033],
            [71.367077205000044, 33.721357420000061],
            [71.366506135000066, 33.721414527000036],
            [71.36607783200003, 33.721557295000025],
            [71.365192672000035, 33.721814277000078],
            [71.364307513000028, 33.722128366000049],
            [71.363279586000033, 33.722699436000028],
            [71.362023230000034, 33.72309918600007],
            [71.360881089000031, 33.723584596000023],
            [71.359938822000061, 33.723927238000044],
            [71.358596806000037, 33.724412648000055],
            [71.357426112000041, 33.725097933000029],
            [71.35662661300006, 33.725526236000064],
            [71.355598686000064, 33.726097306000042],
            [71.354627866000044, 33.72655416300006],
            [71.354056795000076, 33.726896805000024],
            [71.353542831000084, 33.727153787000077],
            [71.353028868000081, 33.727353662000041],
            [71.352714779000053, 33.72766775100007],
            [71.352572011000063, 33.728181714000073],
            [71.352572011000063, 33.728809892000072],
            [71.352629118000038, 33.729666498000029],
            [71.352800439000077, 33.730922853000038],
            [71.352943207000067, 33.732007887000066],
            [71.353000314000042, 33.733407010000064],
            [71.353114528000049, 33.734749026000031],
            [71.353457171000059, 33.736119596000037],
            [71.353828367000062, 33.739260484000056],
            [71.354285223000034, 33.744057478000059],
            [71.35442799100008, 33.746198993000064],
            [71.354684973000076, 33.749225667000076],
            [71.354799187000083, 33.750025166000057],
            [71.354827740000076, 33.75053912900006],
            [71.354827740000076, 33.751053093000053],
            [71.354770633000044, 33.75165271700007],
            [71.354684973000076, 33.752138127000023],
            [71.354684973000076, 33.752652091000073],
            [71.354742080000051, 33.753166054000076],
            [71.354770633000044, 33.753708571000061],
            [71.354884847000051, 33.754022660000032],
            [71.354970508000065, 33.754393856000036],
            [71.354941954000083, 33.754793606000078],
            [71.354742080000051, 33.755079141000067],
            [71.354085348000069, 33.755621658000052],
            [71.353657045000034, 33.755935747000024],
            [71.353057421000074, 33.756449710000027],
            [71.352514904000031, 33.75682090600003],
            [71.351943834000053, 33.75716354900004],
            [71.351629745000082, 33.757506191000061],
            [71.35157263800005, 33.758020155000054],
            [71.351544084000068, 33.758848207000028],
            [71.351458424000043, 33.759362171000078],
            [71.351115781000033, 33.759847581000031],
            [71.350687478000054, 33.760361544000034],
            [71.350173515000051, 33.760761294000076],
            [71.349545337000052, 33.760932615000058],
            [71.349145587000066, 33.761218150000047],
            [71.349002820000067, 33.761532239000076],
            [71.348974266000084, 33.76187488100004],
            [71.348974266000084, 33.762188970000068],
            [71.349002820000067, 33.76270293400006],
            [71.349117034000074, 33.76333111200006],
            [71.349231248000081, 33.76381652200007],
            [71.349286946000063, 33.764150708000045],
            [71.349292968000043, 33.764150184000073],
            [71.349294278000059, 33.764158040000041],
            [71.349634915000081, 33.764128419000031],
            [71.350462968000045, 33.764071312000056],
            [71.351433788000065, 33.764042758000073],
            [71.352347501000054, 33.764185526000063],
            [71.353204107000067, 33.764214080000045],
            [71.354089266000074, 33.764214080000045],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "47",
      properties: { name: "FR Lakki Marwat", count: 338 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.404261341000051, 32.706484330000023],
            [70.404470558000071, 32.704600911000057],
            [70.405322000000069, 32.696936000000051],
            [70.406875599000045, 32.695479556000066],
            [70.412705345000063, 32.690014376000079],
            [70.415924000000075, 32.686997000000076],
            [70.41902989700003, 32.683891103000065],
            [70.423107323000067, 32.679813677000027],
            [70.426426280000044, 32.676494720000051],
            [70.431165000000078, 32.671756000000073],
            [70.434274293000044, 32.667430205000073],
            [70.436579637000079, 32.664222901000073],
            [70.439261334000037, 32.660491997000065],
            [70.44459080200005, 32.653074068000024],
            [70.446406000000081, 32.650552000000062],
            [70.45008602300004, 32.64605801700003],
            [70.454192441000032, 32.641034104000028],
            [70.455782002000035, 32.639089388000059],
            [70.455779190000044, 32.639088522000065],
            [70.458333000000039, 32.635973000000035],
            [70.462385027000039, 32.634266900000057],
            [70.470923000000084, 32.630672000000061],
            [70.471952112000054, 32.629071216000057],
            [70.476859005000051, 32.621438546000036],
            [70.479361786000084, 32.617545471000028],
            [70.482295123000029, 32.612997066000048],
            [70.482289126000069, 32.612991994000026],
            [70.482851000000039, 32.612118000000066],
            [70.490105397000036, 32.606903983000052],
            [70.493521654000062, 32.604448586000046],
            [70.497410304000084, 32.601653661000057],
            [70.498983330000044, 32.600523066000051],
            [70.498917669000036, 32.600501179000048],
            [70.497870706000072, 32.599953903000028],
            [70.497323430000051, 32.599739752000062],
            [70.496847538000054, 32.599573190000058],
            [70.496395440000072, 32.599287654000079],
            [70.495919548000074, 32.599263860000065],
            [70.49534847700005, 32.599287654000079],
            [70.494848790000049, 32.599478011000031],
            [70.494230131000052, 32.599644574000024],
            [70.49363526500008, 32.599644574000024],
            [70.492850043000033, 32.599644574000024],
            [70.492136205000065, 32.599644574000024],
            [70.491446161000056, 32.599811136000028],
            [70.490875091000078, 32.60007287600007],
            [70.490422993000038, 32.600453590000029],
            [70.489851922000071, 32.600810509000041],
            [70.48920946800007, 32.601238812000076],
            [70.488709781000068, 32.601524348000055],
            [70.48773420200007, 32.602024034000067],
            [70.487234515000068, 32.602119213000037],
            [70.486663445000033, 32.602143008000041],
            [70.486187553000036, 32.602238186000079],
            [70.485854428000039, 32.60219059700006],
            [70.48533094700008, 32.602095418000033],
            [70.484783671000059, 32.602166802000056],
            [70.484450546000062, 32.602571311000077],
            [70.484283984000058, 32.602880640000024],
            [70.484069832000046, 32.603237560000025],
            [70.483736708000038, 32.603570684000033],
            [70.483355994000078, 32.603880014000026],
            [70.482975280000062, 32.603880014000026],
            [70.482523183000069, 32.603689657000075],
            [70.482404210000084, 32.602904435000028],
            [70.482404210000084, 32.602618900000039],
            [70.482356620000076, 32.60219059700006],
            [70.482213853000076, 32.60178608800004],
            [70.482118674000048, 32.601262607000024],
            [70.482023496000068, 32.600953277000031],
            [70.481761755000036, 32.600596358000075],
            [70.481523809000066, 32.600382206000063],
            [70.481071711000084, 32.60016805500004],
            [70.48021510600006, 32.599977698000032],
            [70.479334705000042, 32.599834930000043],
            [70.478620867000075, 32.599501806000035],
            [70.477621493000072, 32.599121092000075],
            [70.476526941000031, 32.598526227000036],
            [70.475813103000064, 32.598193102000039],
            [70.474813729000061, 32.597764799000061],
            [70.47417127500006, 32.597431675000053],
            [70.473933329000033, 32.59721752300004],
            [70.473505026000055, 32.596884399000032],
            [70.472862571000064, 32.596384712000031],
            [70.472220117000063, 32.595789847000049],
            [70.471791814000028, 32.595551901000078],
            [70.470911413000067, 32.595528106000074],
            [70.470173780000039, 32.595528106000074],
            [70.469459942000071, 32.59550431100007],
            [70.468865077000032, 32.595218776000024],
            [70.468008471000076, 32.594861857000069],
            [70.467128070000058, 32.594504938000057],
            [70.466271464000044, 32.594314581000049],
            [70.465676599000062, 32.594219403000068],
            [70.464225128000066, 32.593743510000024],
            [70.463273343000083, 32.593600743000025],
            [70.462345354000036, 32.593362797000054],
            [70.461060445000044, 32.592982083000038],
            [70.460275223000053, 32.592648958000041],
            [70.459632768000063, 32.592411012000071],
            [70.459109287000047, 32.592315834000033],
            [70.458514422000064, 32.592173066000043],
            [70.457871967000074, 32.592173066000043],
            [70.457300896000049, 32.592196861000048],
            [70.456848799000056, 32.592339628000047],
            [70.456396701000074, 32.592672753000045],
            [70.456206344000066, 32.593029672000057],
            [70.456087371000081, 32.593481770000039],
            [70.455920809000077, 32.593910073000075],
            [70.455635274000031, 32.594290786000045],
            [70.455373533000056, 32.594552527000076],
            [70.454707284000051, 32.594933241000035],
            [70.454302776000077, 32.595028419000073],
            [70.453731705000052, 32.595147392000058],
            [70.452851304000035, 32.595147392000058],
            [70.452137466000067, 32.595218776000024],
            [70.451019120000069, 32.595456722000051],
            [70.45018630800007, 32.595551901000078],
            [70.44949626500005, 32.595575695000036],
            [70.44816376600005, 32.595551901000078],
            [70.447545107000053, 32.59550431100007],
            [70.446474349000084, 32.595575695000036],
            [70.445284619000063, 32.595766052000045],
            [70.444665959000076, 32.595789847000049],
            [70.444190067000079, 32.59574225800003],
            [70.443714174000036, 32.595599490000041],
            [70.443333461000066, 32.595313955000051],
            [70.442786185000045, 32.595052214000077],
            [70.442405471000029, 32.594600116000038],
            [70.441977168000051, 32.593910073000075],
            [70.441763016000039, 32.593386591000069],
            [70.441072973000075, 32.591816147000031],
            [70.440430518000028, 32.59053123800004],
            [70.439907037000069, 32.589317713000071],
            [70.439502528000048, 32.588627669000061],
            [70.439050431000055, 32.587747269000033],
            [70.43852694900005, 32.586724101000073],
            [70.438170030000038, 32.586129235000044],
            [70.437908290000053, 32.585748522000074],
            [70.437479987000074, 32.585581959000024],
            [70.436789943000065, 32.585344013000054],
            [70.436195078000083, 32.585344013000054],
            [70.435505034000073, 32.585415397000077],
            [70.43464842800006, 32.585629548000043],
            [70.433839411000065, 32.586105441000029],
            [70.432602092000081, 32.586819279000053],
            [70.431840664000049, 32.587318966000055],
            [70.431055442000059, 32.587675885000067],
            [70.430389193000053, 32.587794858000052],
            [70.429603971000063, 32.588056599000026],
            [70.428723570000045, 32.58812798200006],
            [70.428104910000059, 32.588175572000068],
            [70.427438661000053, 32.588151777000064],
            [70.426938975000041, 32.587890036000033],
            [70.426558261000082, 32.587747269000033],
            [70.425963396000043, 32.587390350000078],
            [70.425439914000037, 32.586819279000053],
            [70.424369157000058, 32.585867495000059],
            [70.422989069000039, 32.584725353000067],
            [70.421846928000036, 32.583821158000035],
            [70.421252063000054, 32.583369061000042],
            [70.420776171000057, 32.583131114000025],
            [70.420276484000055, 32.582893168000055],
            [70.41951505600008, 32.58277419500007],
            [70.418087380000031, 32.582655222000028],
            [70.416683498000054, 32.582536249000043],
            [70.416136222000034, 32.582488660000024],
            [70.415351000000044, 32.582322098000077],
            [70.414756134000072, 32.58208415200005],
            [70.414232653000056, 32.58167964300003],
            [70.413590199000055, 32.581037189000028],
            [70.413090512000053, 32.58060888600005],
            [70.412828771000079, 32.580442324000046],
            [70.412257700000055, 32.580299556000057],
            [70.411472478000064, 32.580347145000076],
            [70.410377926000081, 32.58037094000008],
            [70.408950250000032, 32.580561297000031],
            [70.407308422000028, 32.580585091000046],
            [70.405737977000058, 32.580775448000054],
            [70.404762398000059, 32.580918216000043],
            [70.404310301000066, 32.580942010000058],
            [70.403596463000042, 32.580680270000073],
            [70.402549500000077, 32.580275761000053],
            [70.401193207000063, 32.579752280000037],
            [70.400407985000072, 32.579514334000066],
            [70.399432406000074, 32.579490539000062],
            [70.398004729000036, 32.579371566000077],
            [70.396981561000075, 32.579347772000062],
            [70.396124955000062, 32.579490539000062],
            [70.395125582000048, 32.579585718000033],
            [70.394483127000058, 32.579728485000032],
            [70.393935851000037, 32.580180583000072],
            [70.393507548000059, 32.580680270000073],
            [70.393007861000058, 32.58115616200007],
            [70.392460585000038, 32.581608259000063],
            [70.391770542000074, 32.582036562000042],
            [70.391151882000031, 32.582274509000058],
            [70.39041424900006, 32.582488660000024],
            [70.390009740000039, 32.582488660000024],
            [70.389652821000084, 32.582441071000062],
            [70.389367286000038, 32.582131741000069],
            [70.38879621500007, 32.581560670000044],
            [70.388391707000039, 32.58115616200007],
            [70.387892020000038, 32.580799243000058],
            [70.387654074000068, 32.580585091000046],
            [70.387416128000041, 32.58051370700008],
            [70.387083004000033, 32.580466118000061],
            [70.38663090600005, 32.580418529000042],
            [70.385726711000075, 32.580561297000031],
            [70.385179435000055, 32.580585091000046],
            [70.384727337000072, 32.580632680000065],
            [70.384299034000037, 32.580632680000065],
            [70.383799347000036, 32.580632680000065],
            [70.383394839000061, 32.580561297000031],
            [70.382966536000083, 32.58051370700008],
            [70.382371671000044, 32.580323351000061],
            [70.381919573000062, 32.580251967000038],
            [70.381181940000033, 32.58006161000003],
            [70.380372924000028, 32.579847458000074],
            [70.379873237000083, 32.579847458000074],
            [70.379325961000063, 32.579847458000074],
            [70.378635917000054, 32.579847458000074],
            [70.378017257000067, 32.579966431000059],
            [70.377303419000043, 32.580085404000044],
            [70.376732348000075, 32.580132994000053],
            [70.376256456000078, 32.580156788000068],
            [70.375899537000066, 32.58020437700003],
            [70.37551882300005, 32.58020437700003],
            [70.375233288000061, 32.580180583000072],
            [70.374947753000072, 32.580109199000049],
            [70.374709807000045, 32.579966431000059],
            [70.374400477000052, 32.579728485000032],
            [70.374019763000035, 32.579490539000062],
            [70.373567665000053, 32.579109825000046],
            [70.373091773000056, 32.578681522000068],
            [70.372711059000039, 32.578372193000064],
            [70.372330346000069, 32.577991479000048],
            [70.371973426000068, 32.577729738000073],
            [70.371830659000068, 32.577396614000065],
            [70.371759275000045, 32.577039694000064],
            [70.371449945000052, 32.576421035000067],
            [70.371188204000077, 32.576016526000046],
            [70.371045437000078, 32.575754785000072],
            [70.370736107000084, 32.575374072000045],
            [70.370426777000034, 32.575326482000037],
            [70.370046063000075, 32.57539786600006],
            [70.369570171000078, 32.575564429000053],
            [70.369046689000072, 32.575778580000076],
            [70.368428030000075, 32.576159294000036],
            [70.367809370000032, 32.576444829000025],
            [70.367143121000083, 32.576587597000071],
            [70.36652446100004, 32.576658981000037],
            [70.365786828000068, 32.576658981000037],
            [70.365191963000029, 32.576563802000067],
            [70.364430535000054, 32.576421035000067],
            [70.363264599000047, 32.576135499000031],
            [70.362289020000048, 32.575897553000061],
            [70.361503798000058, 32.575540634000049],
            [70.36076616500003, 32.575183715000037],
            [70.360266478000028, 32.574898180000048],
            [70.359505051000042, 32.574684028000036],
            [70.359100543000068, 32.574612644000069],
            [70.358696034000047, 32.574612644000069],
            [70.35822014200005, 32.574588850000055],
            [70.357601482000064, 32.57470782300004],
            [70.357173179000029, 32.574945769000067],
            [70.35674487600005, 32.575421661000064],
            [70.356340368000076, 32.575873759000046],
            [70.356031038000083, 32.576254472000073],
            [70.355697913000029, 32.576754159000075],
            [70.355317200000059, 32.577134873000034],
            [70.354984075000061, 32.57742040800008],
            [70.354365415000075, 32.577634560000035],
            [70.35393711200004, 32.57765835400005],
            [70.353366042000061, 32.577682149000054],
            [70.352890149000075, 32.577824917000044],
            [70.352390463000063, 32.578015273000062],
            [70.351962160000028, 32.578158041000052],
            [70.351319705000037, 32.578443576000041],
            [70.351034170000048, 32.578729112000076],
            [70.350463099000081, 32.579038442000069],
            [70.35005859100005, 32.579300182000054],
            [70.349582699000052, 32.579585718000033],
            [70.348773682000058, 32.579799869000055],
            [70.348226406000038, 32.579895048000026],
            [70.347464979000051, 32.580014021000068],
            [70.346846319000065, 32.580156788000068],
            [70.34627524800004, 32.580442324000046],
            [70.345632794000039, 32.580704064000031],
            [70.345180696000057, 32.581060983000043],
            [70.344681009000055, 32.581346519000078],
            [70.344157528000039, 32.581513081000026],
            [70.343515073000049, 32.58153687600003],
            [70.342967797000028, 32.58153687600003],
            [70.342325343000084, 32.581489286000078],
            [70.341706683000041, 32.581275135000055],
            [70.34068351500008, 32.581108573000051],
            [70.33973173000004, 32.580894421000039],
            [70.339041687000076, 32.580894421000039],
            [70.338470616000052, 32.580823037000073],
            [70.337804367000047, 32.580823037000073],
            [70.336900172000071, 32.580846832000077],
            [70.335900798000068, 32.580965805000062],
            [70.334758657000066, 32.581084778000047],
            [70.332974061000073, 32.581322724000074],
            [70.33183192000007, 32.581489286000078],
            [70.330570806000082, 32.581584465000049],
            [70.329143129000045, 32.58167964300003],
            [70.32740612300006, 32.581870000000038],
            [70.326573311000061, 32.581917589000057],
            [70.325669116000029, 32.582060357000046],
            [70.324883894000038, 32.582155535000027],
            [70.324217645000033, 32.582345892000035],
            [70.32352760100008, 32.582560044000047],
            [70.322742379000033, 32.58286937400004],
            [70.32205233500008, 32.583131114000025],
            [70.32095778300004, 32.583892542000058],
            [70.320291534000035, 32.584416023000074],
            [70.319577696000067, 32.58503468300006],
            [70.318887652000058, 32.585796111000036],
            [70.318007252000029, 32.587009636000062],
            [70.317317208000077, 32.587961420000056],
            [70.316579575000048, 32.589198740000029],
            [70.315937121000047, 32.590245703000051],
            [70.315342256000065, 32.591387844000053],
            [70.314985336000063, 32.59255378000006],
            [70.314699801000074, 32.593481770000039],
            [70.314485650000051, 32.594076635000079],
            [70.314104936000035, 32.59481426800005],
            [70.313866990000065, 32.595575695000036],
            [70.31358145400003, 32.596241944000042],
            [70.313391098000068, 32.596765426000047],
            [70.313034178000066, 32.59721752300004],
            [70.312748643000077, 32.597645826000075],
            [70.312320340000042, 32.598145513000077],
            [70.311939626000083, 32.59869278900004],
            [70.311535118000052, 32.599168681000037],
            [70.311273377000077, 32.599549395000054],
            [70.311083020000069, 32.599882520000051],
            [70.310987842000031, 32.600310823000029],
            [70.31079748500008, 32.600762920000079],
            [70.310678512000038, 32.601262607000024],
            [70.310511950000034, 32.602119213000037],
            [70.31048815500003, 32.602952024000047],
            [70.310464361000072, 32.603642068000056],
            [70.310440566000068, 32.604213139000024],
            [70.310059852000052, 32.604760415000044],
            [70.30970293300004, 32.605450458000064],
            [70.309393603000046, 32.606021529000031],
            [70.308965300000068, 32.606640189000075],
            [70.308584586000052, 32.607187465000038],
            [70.308322846000067, 32.607782330000077],
            [70.308180078000078, 32.608186838000051],
            [70.307965927000055, 32.608710320000057],
            [70.307751775000042, 32.609424158000024],
            [70.307632802000057, 32.609971434000045],
            [70.307418651000035, 32.610828040000058],
            [70.307204499000079, 32.611541878000025],
            [70.306966553000052, 32.612184333000073],
            [70.306704812000078, 32.612993350000067],
            [70.306347893000066, 32.61358821500005],
            [70.305967179000049, 32.613968929000066],
            [70.305491287000052, 32.614325848000078],
            [70.305158163000044, 32.614468615000078],
            [70.304563297000072, 32.614754151000056],
            [70.304063611000061, 32.615015891000041],
            [70.30351633500004, 32.615230043000054],
            [70.303040442000054, 32.615586962000066],
            [70.302540756000042, 32.616062854000063],
            [70.301993480000078, 32.616681514000049],
            [70.30139861400005, 32.617561915000067],
            [70.300351651000028, 32.618846823000069],
            [70.299209510000082, 32.620322089000069],
            [70.297424914000032, 32.622082890000058],
            [70.295830676000037, 32.623843692000037],
            [70.294617151000068, 32.625176190000047],
            [70.293260858000053, 32.627032169000074],
            [70.291500057000064, 32.62941163000005],
            [70.289382336000074, 32.632100421000075],
            [70.288216401000057, 32.633837428000049],
            [70.287002875000042, 32.635146131000056],
            [70.285789350000073, 32.636835548000079],
            [70.28502792300003, 32.637858717000029],
            [70.284337879000077, 32.638762912000061],
            [70.284028549000084, 32.639119831000073],
            [70.283838192000076, 32.639333982000039],
            [70.283730829000035, 32.639418339000031],
            [70.283880878000048, 32.63944443500003],
            [70.284428154000068, 32.639563408000072],
            [70.285094403000073, 32.63999171100005],
            [70.28571306300006, 32.640372424000077],
            [70.286379312000065, 32.640705549000074],
            [70.286878999000066, 32.641038674000072],
            [70.28742627500003, 32.64146697700005],
            [70.287806989000046, 32.641942869000047],
            [70.288068729000031, 32.642323582000074],
            [70.288282881000043, 32.642799475000061],
            [70.288473238000051, 32.643322956000077],
            [70.288782568000045, 32.643798848000074],
            [70.288949130000049, 32.644250946000057],
            [70.289401227000042, 32.644631660000073],
            [70.289758147000043, 32.645036168000047],
            [70.290353012000082, 32.645321703000036],
            [70.290947877000065, 32.645607239000071],
            [70.291661715000032, 32.645868979000056],
            [70.292185197000038, 32.646225898000068],
            [70.292899035000062, 32.646749380000074],
            [70.293969792000041, 32.647820137000053],
            [70.295254701000033, 32.648962279000045],
            [70.296492021000063, 32.650199598000029],
            [70.297681752000074, 32.651603480000063],
            [70.298823893000076, 32.652935978000073],
            [70.30015639100003, 32.65379258400003],
            [70.300965408000081, 32.654316066000035],
            [70.302036165000061, 32.655077493000078],
            [70.303511431000061, 32.656124456000043],
            [70.305081875000042, 32.657100035000042],
            [70.30581950800007, 32.657671106000066],
            [70.306461963000061, 32.658265971000048],
            [70.307033033000039, 32.658884631000035],
            [70.307366158000036, 32.659527085000036],
            [70.307627898000078, 32.66038369100005],
            [70.308698656000047, 32.662549001000059],
            [70.309579056000075, 32.664690516000064],
            [70.310197716000062, 32.666260960000045],
            [70.310602225000082, 32.667117566000059],
            [70.310959144000037, 32.667569663000052],
            [70.311387447000072, 32.668069350000053],
            [70.312410615000033, 32.669068724000056],
            [70.313457578000055, 32.669972919000031],
            [70.314219005000041, 32.670543989000066],
            [70.314599719000057, 32.670972292000044],
            [70.31490904900005, 32.671448185000031],
            [70.315004227000031, 32.672376174000078],
            [70.314980433000073, 32.672947245000046],
            [70.315004227000031, 32.673922824000044],
            [70.315242173000058, 32.675493268000025],
            [70.315551503000052, 32.676659204000032],
            [70.316074985000057, 32.677944113000024],
            [70.316598466000073, 32.679609736000032],
            [70.317121948000079, 32.681251564000036],
            [70.31745507200003, 32.682560267000042],
            [70.317883375000065, 32.683416873000056],
            [70.318335473000047, 32.684249685000054],
            [70.319025516000067, 32.684892139000056],
            [70.319739355000081, 32.685582183000065],
            [70.320500782000067, 32.686010486000043],
            [70.321190826000077, 32.686343610000051],
            [70.322095021000052, 32.686652940000045],
            [70.322999216000028, 32.686986065000042],
            [70.323879617000046, 32.687438162000035],
            [70.324902785000063, 32.687937849000036],
            [70.325902159000066, 32.688461331000042],
            [70.326687381000056, 32.689032401000077],
            [70.32792470000004, 32.689770034000048],
            [70.32870992200003, 32.690388694000035],
            [70.329423761000044, 32.690935970000055],
            [70.330066215000045, 32.691459451000071],
            [70.330780054000058, 32.692054317000043],
            [70.33127974000007, 32.692815744000029],
            [70.331922195000061, 32.69391029600007],
            [70.332659828000033, 32.695337973000051],
            [70.333302282000034, 32.696146989000056],
            [70.334087504000081, 32.697431898000048],
            [70.335039289000065, 32.698383683000031],
            [70.336181430000067, 32.699383056000045],
            [70.337204598000028, 32.700096895000058],
            [70.339274729000067, 32.701096268000072],
            [70.341130709000083, 32.702095642000074],
            [70.342843921000053, 32.703190194000058],
            [70.344200213000079, 32.703975416000048],
            [70.345342355000071, 32.704927200000043],
            [70.346103782000057, 32.705522066000071],
            [70.346532085000035, 32.705688628000075],
            [70.347222129000045, 32.705831396000065],
            [70.347983556000031, 32.705855190000079],
            [70.349030519000053, 32.705926574000046],
            [70.349839536000047, 32.706045547000031],
            [70.350648553000042, 32.706235904000039],
            [70.351814488000059, 32.706664207000074],
            [70.353194576000078, 32.707401840000045],
            [70.354384306000043, 32.707996705000028],
            [70.355811983000081, 32.708472597000025],
            [70.356549616000052, 32.708639159000029],
            [70.357168276000039, 32.708710543000052],
            [70.358024882000052, 32.708710543000052],
            [70.358738720000076, 32.708686749000037],
            [70.359309790000054, 32.708639159000029],
            [70.359738093000033, 32.708591570000067],
            [70.360095013000034, 32.708591570000067],
            [70.360475726000061, 32.708543981000048],
            [70.360826932000066, 32.708543981000048],
            [70.361442471000032, 32.708434433000036],
            [70.362608363000049, 32.708092193000027],
            [70.362712420000037, 32.707949116000066],
            [70.36292657100006, 32.707806348000076],
            [70.363331079000034, 32.707711170000039],
            [70.364473221000083, 32.707473224000069],
            [70.365900897000074, 32.707187688000033],
            [70.366947860000039, 32.706997331000025],
            [70.367804466000052, 32.706997331000025],
            [70.36828035800005, 32.707021126000029],
            [70.369113170000048, 32.707021126000029],
            [70.369660446000069, 32.707068715000048],
            [70.370421873000055, 32.707092510000052],
            [70.371064328000045, 32.707235277000052],
            [70.371564014000057, 32.707306661000075],
            [70.371992317000036, 32.707473224000069],
            [70.372468210000079, 32.707568402000049],
            [70.372619298000075, 32.707624066000051],
            [70.373913039000058, 32.707939234000037],
            [70.373515000000054, 32.705551000000071],
            [70.383455000000083, 32.716153000000077],
            [70.391406000000075, 32.724105000000066],
            [70.399358000000063, 32.726093000000049],
            [70.400066000000038, 32.723464000000035],
            [70.401745596000069, 32.717225865000046],
            [70.402053637000051, 32.716081780000025],
            [70.403997000000061, 32.708864000000062],
            [70.404261341000051, 32.706484330000023],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "48",
      properties: { name: "FR Peshawar", count: 1005 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.856506000000081, 33.745898000000068],
            [71.843916000000036, 33.718729000000053],
            [71.827350000000081, 33.718729000000053],
            [71.818735000000061, 33.708790000000079],
            [71.814659000000063, 33.705194000000063],
            [71.80747000000008, 33.69885000000005],
            [71.802169000000049, 33.680296000000055],
            [71.801537619000044, 33.673562066000045],
            [71.801503496000066, 33.673198135000064],
            [71.801464899000052, 33.67278648000007],
            [71.779345026000044, 33.680110194000065],
            [71.778324318000045, 33.680546509000067],
            [71.77832789200005, 33.680550850000031],
            [71.778327402000059, 33.680551136000076],
            [71.77832378100004, 33.680546738000032],
            [71.77813793100006, 33.680655151000053],
            [71.777766735000057, 33.680912133000049],
            [71.776824468000029, 33.681597418000024],
            [71.77605352300003, 33.682082828000034],
            [71.775339684000073, 33.682282703000055],
            [71.774226097000053, 33.682653899000059],
            [71.773112509000043, 33.683025094000072],
            [71.771970368000041, 33.683282076000069],
            [71.770999548000077, 33.683510505000072],
            [71.770000174000074, 33.683710379000047],
            [71.769057908000036, 33.683796040000061],
            [71.768029980000051, 33.683938807000061],
            [71.767230482000059, 33.684281450000071],
            [71.766516643000045, 33.684823967000057],
            [71.765945573000067, 33.685395038000024],
            [71.765260288000036, 33.685766234000027],
            [71.764460789000054, 33.685994662000041],
            [71.763375755000084, 33.686080322000066],
            [71.762576256000045, 33.686165983000024],
            [71.76169109600005, 33.686280197000031],
            [71.76092015100005, 33.686451518000069],
            [71.760177759000044, 33.686679947000073],
            [71.759492474000069, 33.686879821000048],
            [71.758892850000052, 33.687336678000065],
            [71.758179012000085, 33.688364605000061],
            [71.757265299000039, 33.689249764000067],
            [71.756380140000033, 33.690134924000063],
            [71.755723408000051, 33.690591780000034],
            [71.754809695000063, 33.690763102000062],
            [71.753895982000074, 33.690991530000076],
            [71.753410572000064, 33.691419833000054],
            [71.752982269000029, 33.69196235000004],
            [71.75255396600005, 33.692304992000061],
            [71.75192578900004, 33.692504867000025],
            [71.750954969000077, 33.692590528000039],
            [71.749470185000064, 33.69244776000005],
            [71.747043135000069, 33.692048010000065],
            [71.745872440000028, 33.691791029000058],
            [71.745272816000067, 33.691791029000058],
            [71.744901620000064, 33.691762475000075],
            [71.744510770000034, 33.691483297000048],
            [71.74450506800008, 33.691479224000034],
            [71.744363214000032, 33.69119551600005],
            [71.743820697000046, 33.690424570000062],
            [71.743278180000061, 33.689710732000037],
            [71.742623759000082, 33.688999405000061],
            [71.742620943000077, 33.688996701000065],
            [71.742618633000063, 33.688994191000063],
            [71.74261733700007, 33.68899278300006],
            [71.741903499000045, 33.688307498000029],
            [71.740932679000082, 33.687507999000047],
            [71.740304501000082, 33.687079696000069],
            [71.739476449000051, 33.68679416100008],
            [71.738562736000063, 33.686537179000027],
            [71.737877451000031, 33.686251644000038],
            [71.737334934000046, 33.686165983000024],
            [71.737060524000071, 33.685919014000035],
            [71.737053396000078, 33.685912598000073],
            [71.736996403000035, 33.68565613100003],
            [71.736996403000035, 33.685370595000052],
            [71.737110617000042, 33.685142167000038],
            [71.737396152000031, 33.685027953000031],
            [71.73802433000003, 33.684828078000066],
            [71.73850974000004, 33.684828078000066],
            [71.738880936000044, 33.684742418000042],
            [71.738966597000058, 33.684485436000045],
            [71.73913791800004, 33.683685937000064],
            [71.739480560000061, 33.681144673000063],
            [71.739851756000064, 33.680259513000067],
            [71.740308613000082, 33.67951712100006],
            [71.740965344000074, 33.678603408000072],
            [71.741793396000048, 33.677546928000027],
            [71.74259289500003, 33.676576108000063],
            [71.743106859000079, 33.675976483000056],
            [71.743249626000079, 33.675776609000025],
            [71.743306733000054, 33.675405413000078],
            [71.743192519000047, 33.67509132400005],
            [71.742964091000033, 33.674720128000047],
            [71.742621449000069, 33.674091950000047],
            [71.742364467000073, 33.67349232600003],
            [71.742136039000059, 33.672521506000066],
            [71.742164592000051, 33.671493579000071],
            [71.742136039000059, 33.671036722000053],
            [71.741856833000043, 33.670729596000058],
            [71.741856271000074, 33.67072938900003],
            [71.741849941000055, 33.670722427000044],
            [71.74184639200007, 33.670718522000072],
            [71.741303875000028, 33.670518648000041],
            [71.740447269000072, 33.670347326000069],
            [71.739476449000051, 33.670090345000062],
            [71.73867695000007, 33.669804809000027],
            [71.737135059000082, 33.66909097100006],
            [71.735022098000059, 33.668348579000053],
            [71.733537314000046, 33.667748955000036],
            [71.73153856700003, 33.667149331000076],
            [71.730596301000048, 33.66686379600003],
            [71.729853909000042, 33.666835242000047],
            [71.729168624000067, 33.66672102800004],
            [71.728768875000071, 33.666435493000051],
            [71.72745541200004, 33.665493226000024],
            [71.726570253000034, 33.664807941000049],
            [71.726170503000048, 33.66452240600006],
            [71.725656540000045, 33.664493853000067],
            [71.725142576000053, 33.664493853000067],
            [71.72462861200006, 33.664408192000053],
            [71.72400043500005, 33.664122657000064],
            [71.723640682000052, 33.663762904000066],
            [71.723633350000057, 33.663755572000071],
            [71.723629239000047, 33.663751461000061],
            [71.72305816800008, 33.663465925000025],
            [71.722230116000048, 33.663180390000036],
            [71.720574011000053, 33.662809194000033],
            [71.719260549000069, 33.662666426000044],
            [71.718432496000048, 33.662666426000044],
            [71.717775765000056, 33.662666426000044],
            [71.717147587000056, 33.662694980000026],
            [71.71674783800006, 33.662637873000051],
            [71.716205321000075, 33.66243799800003],
            [71.71529160800003, 33.662352338000062],
            [71.714720537000062, 33.662181016000034],
            [71.714149466000038, 33.662066802000027],
            [71.713635503000035, 33.662009695000052],
            [71.713150093000081, 33.661895481000045],
            [71.712721790000046, 33.66183837400007],
            [71.712407701000075, 33.661866928000052],
            [71.712293487000068, 33.662266677000048],
            [71.711950844000057, 33.662609319000069],
            [71.711579649000043, 33.663266051000051],
            [71.711265560000072, 33.663580140000079],
            [71.710808703000055, 33.664179764000039],
            [71.710151972000062, 33.664665174000049],
            [71.709609455000077, 33.665036370000053],
            [71.709323920000031, 33.66515058400006],
            [71.708867063000071, 33.665036370000053],
            [71.708295992000046, 33.664779388000056],
            [71.707553601000029, 33.66452240600006],
            [71.706754102000048, 33.664236871000071],
            [71.705840389000059, 33.663951335000036],
            [71.704698247000067, 33.66340881800005],
            [71.703213464000044, 33.66278064100004],
            [71.701500252000073, 33.661981142000059],
            [71.698188042000083, 33.660524912000028],
            [71.69718866900007, 33.659811073000071],
            [71.695818099000064, 33.65898302100004],
            [71.695047154000065, 33.658611825000037],
            [71.694104887000037, 33.658354843000041],
            [71.693419603000052, 33.658240629000034],
            [71.692448782000042, 33.658126415000027],
            [71.691906265000057, 33.657955094000044],
            [71.691049659000043, 33.657583898000041],
            [71.690364375000058, 33.65726980900007],
            [71.689878965000048, 33.657184148000056],
            [71.68945066200007, 33.657355470000027],
            [71.68896525200006, 33.657755219000023],
            [71.688308520000078, 33.657897987000069],
            [71.687680343000068, 33.658069308000051],
            [71.687052165000068, 33.658183522000058],
            [71.68673807600004, 33.658440504000055],
            [71.686766630000079, 33.659040128000072],
            [71.686823737000054, 33.659668306000071],
            [71.686823737000054, 33.66023937600005],
            [71.686795183000072, 33.660696233000067],
            [71.686566755000058, 33.66121019600007],
            [71.68625266600003, 33.661724160000063],
            [71.685253292000084, 33.663751461000061],
            [71.684824990000038, 33.664807941000049],
            [71.684453794000035, 33.665521780000063],
            [71.684082598000032, 33.666406939000069],
            [71.683854169000028, 33.666663921000065],
            [71.683340206000082, 33.667035117000069],
            [71.682712028000083, 33.667577634000054],
            [71.682112404000065, 33.66797738300005],
            [71.682083850000083, 33.66832002600006],
            [71.682088098000065, 33.66832250300007],
            [71.682095672000059, 33.668326922000062],
            [71.682426493000037, 33.668519900000035],
            [71.682429381000077, 33.668523298000025],
            [71.682434531000069, 33.668529357000068],
            [71.682911903000047, 33.66909097100006],
            [71.683568634000039, 33.669776256000034],
            [71.683568886000046, 33.669776340000055],
            [71.683569335000072, 33.669776489000071],
            [71.684425240000053, 33.670061791000023],
            [71.68488209700007, 33.670461541000066],
            [71.685481721000031, 33.67080418300003],
            [71.685482492000062, 33.670806385000049],
            [71.685483866000084, 33.670810313000061],
            [71.685681595000062, 33.671375254000054],
            [71.685653042000069, 33.671889217000057],
            [71.685567381000055, 33.672460288000025],
            [71.685567381000055, 33.672974251000028],
            [71.685710149000045, 33.673630983000066],
            [71.686024238000073, 33.674316267000052],
            [71.686195559000055, 33.674915892000058],
            [71.686481094000044, 33.67522998000004],
            [71.686766630000079, 33.67585815800004],
            [71.686995058000036, 33.676229354000043],
            [71.687137826000082, 33.676686210000071],
            [71.687137826000082, 33.67717162100007],
            [71.686966504000054, 33.677485709000052],
            [71.686538201000076, 33.677742691000049],
            [71.686195559000055, 33.677914012000031],
            [71.685767256000076, 33.678228101000059],
            [71.685253292000084, 33.67842797600008],
            [71.684682222000049, 33.678627851000044],
            [71.68391127700005, 33.678884832000051],
            [71.683083224000029, 33.679056154000079],
            [71.682540707000044, 33.67917036800003],
            [71.681826869000076, 33.679284582000037],
            [71.680913156000031, 33.679455903000076],
            [71.680399192000039, 33.679712885000072],
            [71.680284978000032, 33.679969867000068],
            [71.680227871000056, 33.680312509000032],
            [71.680370639000046, 33.680912133000049],
            [71.680374473000029, 33.680915083000059],
            [71.680381312000065, 33.680920343000025],
            [71.680381588000046, 33.680921505000072],
            [71.680744440000069, 33.681200622000063],
            [71.680749087000038, 33.681205888000079],
            [71.681170137000038, 33.681683078000049],
            [71.681712655000069, 33.682311256000048],
            [71.681714294000074, 33.682312505000027],
            [71.681717217000084, 33.682314733000055],
            [71.681719689000033, 33.682317595000029],
            [71.68231639000004, 33.682772224000075],
            [71.683572745000049, 33.683800151000071],
            [71.684971868000048, 33.684942292000073],
            [71.686028349000082, 33.685941666000076],
            [71.687598793000063, 33.687283682000043],
            [71.688712381000073, 33.688340163000078],
            [71.68942621900004, 33.68899689400007],
            [71.689911629000051, 33.689339536000034],
            [71.690397039000061, 33.689682179000044],
            [71.691053771000043, 33.689910607000058],
            [71.691710502000035, 33.689967714000034],
            [71.692367233000084, 33.690396017000069],
            [71.693195285000058, 33.690881427000079],
            [71.693992602000037, 33.691365512000061],
            [71.693996042000038, 33.691370481000035],
            [71.694247655000083, 33.691733922000026],
            [71.694418976000065, 33.692190778000054],
            [71.694418976000065, 33.69244776000005],
            [71.694076334000044, 33.692733295000039],
            [71.693733691000034, 33.692990277000035],
            [71.693333942000038, 33.693275812000024],
            [71.693305388000056, 33.693589901000053],
            [71.693309500000055, 33.693594012000062],
            [71.693316831000061, 33.693601344000058],
            [71.693505263000077, 33.693789776000074],
            [71.69350937400003, 33.693793887000027],
            [71.694166106000068, 33.694193637000069],
            [71.695279693000032, 33.694479172000058],
            [71.696478942000056, 33.694936028000029],
            [71.697278440000048, 33.695335778000072],
            [71.698192153000036, 33.695678420000036],
            [71.699162974000046, 33.696163830000046],
            [71.700105240000084, 33.696706347000031],
            [71.701076060000048, 33.697534400000052],
            [71.701083392000044, 33.697541731000058],
            [71.701585913000031, 33.698044252000045],
            [71.701590024000041, 33.698048363000055],
            [71.702132541000083, 33.698276792000058],
            [71.702503737000029, 33.698419559000058],
            [71.703017700000032, 33.698419559000058],
            [71.703417450000074, 33.698533773000065],
            [71.703424781000081, 33.698541105000061],
            [71.703698874000054, 33.698815197000044],
            [71.70361321300004, 33.69921494700003],
            [71.703413339000065, 33.699671803000058],
            [71.703384785000082, 33.70018576700005],
            [71.703384785000082, 33.700471302000039],
            [71.703527553000072, 33.70081394500005],
            [71.703531227000042, 33.700817006000079],
            [71.703537778000054, 33.700822466000034],
            [71.703538216000084, 33.700823516000071],
            [71.703874306000046, 33.701103591000049],
            [71.704388270000038, 33.70130346600007],
            [71.704873680000048, 33.701474787000052],
            [71.704881012000044, 33.701482119000048],
            [71.705097997000053, 33.701699104000056],
            [71.704869569000039, 33.701898979000077],
            [71.704469819000053, 33.702241621000042],
            [71.703755981000029, 33.702470049000055],
            [71.703356231000043, 33.702755585000034],
            [71.703042143000062, 33.703098227000055],
            [71.70298503600003, 33.703440870000065],
            [71.702927929000055, 33.703840619000061],
            [71.703013589000079, 33.704297475000033],
            [71.703184910000061, 33.704839993000064],
            [71.703498999000033, 33.705439617000025],
            [71.703698874000054, 33.706096348000074],
            [71.703841642000043, 33.706438990000038],
            [71.703813088000061, 33.706753079000066],
            [71.703527553000072, 33.707038615000045],
            [71.703384785000082, 33.707381257000065],
            [71.703242017000036, 33.707781006000062],
            [71.703156357000069, 33.708095095000033],
            [71.702956482000047, 33.708380630000079],
            [71.702642393000076, 33.708637612000075],
            [71.702071323000041, 33.708780380000064],
            [71.701500252000073, 33.708837487000039],
            [71.700814967000042, 33.708894594000071],
            [71.70015823600005, 33.709008808000078],
            [71.699615719000064, 33.709123022000028],
            [71.699101755000072, 33.709237236000035],
            [71.698616345000062, 33.709522772000071],
            [71.698016721000045, 33.70980830700006],
            [71.697616972000048, 33.709893968000074],
            [71.69733143600007, 33.709865414000035],
            [71.69670325900006, 33.709608432000039],
            [71.696246402000043, 33.709408558000064],
            [71.695475457000043, 33.709208683000043],
            [71.69450463700008, 33.708980255000029],
            [71.693619477000084, 33.70869471900005],
            [71.693105514000081, 33.708523398000068],
            [71.691849158000082, 33.708180756000047],
            [71.691021106000051, 33.707895220000069],
            [71.690507142000058, 33.707781006000062],
            [71.69007883900008, 33.707809560000044],
            [71.689707643000077, 33.708009435000065],
            [71.689336448000063, 33.708380630000079],
            [71.688708270000063, 33.708980255000029],
            [71.688279967000028, 33.709408558000064],
            [71.687908771000082, 33.709836861000042],
            [71.687423361000072, 33.71029371700007],
            [71.687023611000029, 33.710636359000034],
            [71.686538201000076, 33.710664913000073],
            [71.68613845200008, 33.710636359000034],
            [71.685824363000052, 33.710379378000027],
            [71.685281846000066, 33.710179503000063],
            [71.684653668000067, 33.710036735000074],
            [71.683682848000046, 33.709779754000067],
            [71.682969010000079, 33.709779754000067],
            [71.682397939000055, 33.709722646000046],
            [71.681969636000076, 33.709694093000053],
            [71.681455673000073, 33.709265790000075],
            [71.680741835000049, 33.709037362000061],
            [71.680199317000074, 33.708780380000064],
            [71.679571140000064, 33.708609059000025],
            [71.678657427000076, 33.708466291000036],
            [71.677973413000075, 33.708238287000029],
            [71.677972599000043, 33.708238015000063],
            [71.677640063000069, 33.70787776800006],
            [71.677639569000064, 33.707877576000044],
            [71.677633117000084, 33.70787058600007],
            [71.67762950000008, 33.707866667000076],
            [71.677115536000031, 33.707666792000055],
            [71.676630126000077, 33.707209936000027],
            [71.675602199000082, 33.70649609700007],
            [71.674602825000079, 33.706039241000042],
            [71.673917540000048, 33.705696599000078],
            [71.67331791600003, 33.705553831000032],
            [71.672805445000051, 33.705468419000056],
            [71.672804489000043, 33.705468260000032],
            [71.67257963600008, 33.705215300000077],
            [71.672893725000051, 33.704558568000039],
            [71.673407688000054, 33.703559195000025],
            [71.674007312000072, 33.70238850000004],
            [71.674007312000072, 33.701874537000037],
            [71.673750330000075, 33.701075038000056],
            [71.673382209000067, 33.700537014000076],
            [71.673379822000072, 33.700535423000076],
            [71.673376747000077, 33.700530929000024],
            [71.673375023000062, 33.700528409000071],
            [71.672775399000045, 33.700128660000075],
            [71.67174747200005, 33.699900232000061],
            [71.670662438000079, 33.699614696000026],
            [71.669634511000083, 33.699329161000037],
            [71.668977780000034, 33.69910073300008],
            [71.66766431700006, 33.698472555000023],
            [71.666750604000072, 33.698158466000052],
            [71.665836891000083, 33.697644503000049],
            [71.665123053000059, 33.697416074000046],
            [71.664266447000045, 33.697244753000064],
            [71.663409841000032, 33.697330414000078],
            [71.662239146000047, 33.697558842000035],
            [71.661097005000045, 33.697815824000031],
            [71.660440274000052, 33.698044252000045],
            [71.659526561000064, 33.698329787000034],
            [71.658498633000079, 33.698358341000073],
            [71.658070330000044, 33.698415448000048],
            [71.657385046000059, 33.698444002000031],
            [71.656928189000041, 33.698444002000031],
            [71.656385672000056, 33.698415448000048],
            [71.656052536000061, 33.698387687000036],
            [71.656046445000072, 33.698387179000065],
            [71.65599003400007, 33.698076917000037],
            [71.656446890000041, 33.697734274000027],
            [71.656703872000037, 33.69736307900007],
            [71.657246389000079, 33.696763454000063],
            [71.657360603000029, 33.696163830000046],
            [71.657360603000029, 33.695849741000075],
            [71.657223251000062, 33.695657448000077],
            [71.657222177000051, 33.695657588000074],
            [71.657216761000029, 33.69565000700004],
            [71.657213725000076, 33.695645755000044],
            [71.656556993000038, 33.695731416000058],
            [71.655329191000078, 33.695817077000072],
            [71.654786674000036, 33.695959844000072],
            [71.654101390000051, 33.696273933000043],
            [71.653644533000033, 33.696588022000071],
            [71.653073462000066, 33.697130539000057],
            [71.652388178000081, 33.69750173500006],
            [71.65141735800006, 33.697872931000063],
            [71.65093194800005, 33.698129913000059],
            [71.650161002000061, 33.698272680000059],
            [71.649390057000062, 33.698386894000066],
            [71.648818986000038, 33.698415448000048],
            [71.647419863000039, 33.69858676900003],
            [71.646763132000046, 33.69858676900003],
            [71.646279002000028, 33.69852981300005],
            [71.64627818200006, 33.698529716000053],
            [71.64602485100005, 33.698248238000076],
            [71.645853530000068, 33.697819935000041],
            [71.645596548000071, 33.696934776000035],
            [71.64539667400004, 33.696220937000078],
            [71.645168245000036, 33.695649867000043],
            [71.644857822000063, 33.695254783000053],
            [71.644855767000081, 33.69525328800006],
            [71.644852101000083, 33.695248622000065],
            [71.644850045000055, 33.695246006000048],
            [71.644535957000073, 33.695017578000034],
            [71.643765011000085, 33.694960471000059],
            [71.643108280000035, 33.694846257000052],
            [71.642280228000061, 33.694846257000052],
            [71.641480729000079, 33.694703489000062],
            [71.641177585000037, 33.694483021000053],
            [71.641170572000078, 33.694477921000043],
            [71.641113644000029, 33.694079422000073],
            [71.641113644000029, 33.693736780000052],
            [71.640999430000079, 33.693051495000077],
            [71.640885216000072, 33.692508978000035],
            [71.640346270000066, 33.691856571000073],
            [71.640344162000076, 33.691854884000065],
            [71.640340590000051, 33.691850560000034],
            [71.640338587000031, 33.691848136000033],
            [71.63977387400007, 33.69139636500006],
            [71.639769801000057, 33.691393106000078],
            [71.639086343000031, 33.690538784000069],
            [71.638572380000028, 33.689996267000026],
            [71.638035220000063, 33.689430837000032],
            [71.63803411300006, 33.689429888000063],
            [71.63802875600004, 33.68942424800008],
            [71.638025751000043, 33.689421086000038],
            [71.637626002000047, 33.689078443000028],
            [71.63691216400008, 33.688907122000046],
            [71.636141218000034, 33.688907122000046],
            [71.635256059000028, 33.688964229000078],
            [71.634513667000078, 33.689164104000042],
            [71.633942596000054, 33.689449639000031],
            [71.633057437000048, 33.689735174000077],
            [71.632543473000055, 33.689992156000073],
            [71.632115170000077, 33.690020710000056],
            [71.631601207000074, 33.690020710000056],
            [71.630915922000042, 33.689877942000066],
            [71.630344851000075, 33.689649514000052],
            [71.629830888000072, 33.689535300000045],
            [71.629402585000037, 33.689221211000074],
            [71.629031389000033, 33.689021336000053],
            [71.62854597900008, 33.688735801000064],
            [71.627947550000044, 33.688479331000053],
            [71.627946784000073, 33.688479003000054],
            [71.627528873000074, 33.688033231000077],
            [71.627528525000059, 33.688033134000079],
            [71.62752181500008, 33.688025976000063],
            [71.627518052000084, 33.68802196200005],
            [71.626804213000071, 33.687822088000075],
            [71.625547858000061, 33.687679320000029],
            [71.624776913000062, 33.687336678000065],
            [71.62426294900007, 33.687222464000058],
            [71.623663325000052, 33.686737054000048],
            [71.623332126000037, 33.686405854000043],
            [71.623324794000041, 33.686398522000047],
            [71.623320683000031, 33.686394411000038],
            [71.622930087000043, 33.686059615000033],
            [71.622924222000051, 33.686054588000047],
            [71.622729331000073, 33.685748330000024],
            [71.622727553000061, 33.685747886000058],
            [71.622723392000069, 33.685741347000032],
            [71.622721058000081, 33.685737680000045],
            [71.62237841600006, 33.685652019000031],
            [71.621978667000064, 33.685509252000031],
            [71.621436150000079, 33.685566359000063],
            [71.620865079000055, 33.685623466000038],
            [71.620493883000051, 33.685623466000038],
            [71.620037026000034, 33.685794787000077],
            [71.619637277000038, 33.685823341000059],
            [71.619123313000046, 33.685709126000063],
            [71.618152493000082, 33.685337931000049],
            [71.617267334000076, 33.685052395000071],
            [71.616610603000083, 33.684881074000032],
            [71.615725443000031, 33.684595539000043],
            [71.614354874000071, 33.684195789000057],
            [71.613384054000051, 33.684024468000075],
            [71.612241912000059, 33.683938807000061],
            [71.611556628000073, 33.683910254000068],
            [71.610814236000067, 33.683881700000029],
            [71.608986810000033, 33.683995915000025],
            [71.607302151000056, 33.684167236000064],
            [71.605588940000075, 33.684281450000071],
            [71.604161263000037, 33.684053022000057],
            [71.60233383700006, 33.684110129000032],
            [71.601277356000082, 33.684195789000057],
            [71.600335090000044, 33.684424218000061],
            [71.599307163000049, 33.684424218000061],
            [71.598764645000074, 33.684281450000071],
            [71.598136468000064, 33.683796040000061],
            [71.59742262900005, 33.683196416000044],
            [71.596823005000033, 33.682910880000065],
            [71.59619482800008, 33.682768113000066],
            [71.595252561000052, 33.682768113000066],
            [71.593796331000078, 33.682882327000073],
            [71.592311547000065, 33.68296798700004],
            [71.591055192000056, 33.683253523000076],
            [71.590084372000035, 33.683224969000037],
            [71.589513301000068, 33.683082202000037],
            [71.588942231000033, 33.682768113000066],
            [71.588005137000039, 33.682001400000047],
            [71.58800182300007, 33.681998688000078],
            [71.587437735000037, 33.681349987000033],
            [71.587436276000062, 33.681348926000055],
            [71.587431546000062, 33.68134348600006],
            [71.587428893000038, 33.681340436000028],
            [71.586490795000032, 33.680658183000048],
            [71.586488124000084, 33.680656240000076],
            [71.585581537000053, 33.679607999000041],
            [71.585579956000061, 33.679606813000078],
            [71.585575444000028, 33.679601596000055],
            [71.585572914000068, 33.679598671000065],
            [71.58511605700005, 33.679256028000054],
            [71.584716308000054, 33.679056154000079],
            [71.584288005000076, 33.679056154000079],
            [71.583802595000066, 33.679198921000079],
            [71.583288631000073, 33.679427350000026],
            [71.582831775000045, 33.679741438000065],
            [71.582260704000078, 33.679941313000029],
            [71.581718187000035, 33.680169741000043],
            [71.581261331000064, 33.68062659800006],
            [71.581118563000075, 33.680997794000064],
            [71.580861581000079, 33.681768739000063],
            [71.580890135000061, 33.682396917000062],
            [71.580833028000029, 33.683339183000044],
            [71.580576046000033, 33.683681826000054],
            [71.580261957000062, 33.684281450000071],
            [71.580033529000048, 33.684652646000075],
            [71.579747993000069, 33.684966735000046],
            [71.579348244000073, 33.685280823000028],
            [71.578834280000081, 33.685537805000024],
            [71.578291763000038, 33.685537805000024],
            [71.577292390000082, 33.685366484000042],
            [71.576692766000065, 33.685309377000067],
            [71.575921820000076, 33.685252270000035],
            [71.575293643000066, 33.685223716000053],
            [71.574522697000077, 33.685395038000024],
            [71.573951627000042, 33.685594912000056],
            [71.573237788000029, 33.685937555000066],
            [71.57266671800005, 33.686280197000031],
            [71.572095647000083, 33.686594286000059],
            [71.57172445100008, 33.686879821000048],
            [71.571410362000051, 33.68739378500004],
            [71.571296148000044, 33.688107623000064],
            [71.571296148000044, 33.688535926000043],
            [71.571153380000055, 33.689078443000028],
            [71.571039166000048, 33.68947819300007],
            [71.570725077000077, 33.689992156000073],
            [71.570211114000074, 33.690620334000073],
            [71.570096900000067, 33.691248512000072],
            [71.569725704000064, 33.691619707000029],
            [71.569525829000042, 33.69196235000004],
            [71.56935450800006, 33.692647635000071],
            [71.569325954000078, 33.693190152000057],
            [71.569325954000078, 33.693875436000042],
            [71.569325954000078, 33.694332293000059],
            [71.569297401000028, 33.694846257000052],
            [71.569040419000032, 33.695388774000037],
            [71.568840544000068, 33.695817077000072],
            [71.568640670000036, 33.696131165000054],
            [71.56838368800004, 33.696530915000039],
            [71.568041046000076, 33.696673683000029],
            [71.567641296000033, 33.696673683000029],
            [71.56712733300003, 33.696502361000057],
            [71.566384941000081, 33.696159719000036],
            [71.565756763000081, 33.696102612000061],
            [71.565014371000075, 33.695931291000079],
            [71.563929337000047, 33.69575997000004],
            [71.563558141000044, 33.695731416000058],
            [71.562987070000077, 33.695674309000026],
            [71.562130465000052, 33.695617202000051],
            [71.561216751000075, 33.695588648000069],
            [71.560445806000075, 33.695531541000037],
            [71.559931843000072, 33.695531541000037],
            [71.55953209300003, 33.695560095000076],
            [71.559389325000041, 33.695845630000065],
            [71.559303665000073, 33.696331040000075],
            [71.559275111000034, 33.696816450000028],
            [71.559189451000066, 33.697273307000046],
            [71.558932469000069, 33.698187020000034],
            [71.558761148000031, 33.698815197000044],
            [71.558589827000048, 33.699586143000033],
            [71.558589827000048, 33.700100106000036],
            [71.558475612000052, 33.700556963000054],
            [71.558218631000045, 33.701042373000064],
            [71.557733221000035, 33.701556336000067],
            [71.55719070400005, 33.702155961000074],
            [71.556819508000046, 33.702527157000077],
            [71.55621988300004, 33.702984013000048],
            [71.55573447300003, 33.703383762000044],
            [71.555277617000058, 33.70349797700004],
            [71.554706546000034, 33.703526530000033],
            [71.553964154000084, 33.703526530000033],
            [71.55310754900006, 33.703326655000069],
            [71.551879747000044, 33.702869799000041],
            [71.551137355000037, 33.702698478000059],
            [71.550509177000038, 33.702527157000077],
            [71.549823892000063, 33.702327282000056],
            [71.548938733000057, 33.702013193000028],
            [71.548453323000047, 33.701813318000063],
            [71.548053573000061, 33.701613444000031],
            [71.547625270000083, 33.701385015000028],
            [71.547425396000051, 33.701727658000038],
            [71.547425396000051, 33.702155961000074],
            [71.547339735000037, 33.702755585000034],
            [71.547282628000062, 33.703269548000037],
            [71.547025646000066, 33.703697851000072],
            [71.546568790000038, 33.704069047000075],
            [71.546111933000077, 33.704411690000029],
            [71.545797844000049, 33.704611564000061],
            [71.545455202000028, 33.704954207000071],
            [71.545398095000053, 33.705382510000049],
            [71.545398095000053, 33.70572515200007],
            [71.545569416000035, 33.707095722000076],
            [71.545769291000056, 33.708323523000047],
            [71.546054826000045, 33.709094469000036],
            [71.546254701000066, 33.709865414000035],
            [71.546568790000038, 33.710550699000066],
            [71.546711557000037, 33.711350198000048],
            [71.546511683000062, 33.711978375000058],
            [71.546283254000059, 33.712606553000057],
            [71.545969166000077, 33.713548820000028],
            [71.545912059000045, 33.714119890000063],
            [71.546140487000059, 33.714719514000024],
            [71.546368915000073, 33.71531913900003],
            [71.546511683000062, 33.716061530000047],
            [71.546511683000062, 33.716689708000047],
            [71.546254701000066, 33.717432100000053],
            [71.54599771900007, 33.718602795000038],
            [71.545455202000028, 33.72051588100004],
            [71.54502689900005, 33.721800790000032],
            [71.544655703000046, 33.723514002000059],
            [71.54439872100005, 33.724484822000079],
            [71.544227400000068, 33.725227214000029],
            [71.54391331100004, 33.725998159000028],
            [71.54391331100004, 33.726626337000027],
            [71.543799097000033, 33.727083194000045],
            [71.543692039000064, 33.727340132000052],
            [71.543660188000047, 33.727496706000068],
            [71.54360338500004, 33.727775931000053],
            [71.543238742000028, 33.729608297000027],
            [71.551691000000062, 33.728669000000025],
            [71.599401000000057, 33.731982000000073],
            [71.602201717000071, 33.732982292000031],
            [71.627232000000049, 33.741922000000045],
            [71.632012063000047, 33.744514502000072],
            [71.666328000000078, 33.763126000000057],
            [71.698135000000036, 33.780355000000043],
            [71.71402555700007, 33.787638297000058],
            [71.714038000000073, 33.787644000000057],
            [71.728828856000064, 33.816042693000043],
            [71.728852128000028, 33.816087376000041],
            [71.730604000000028, 33.819451000000072],
            [71.739881000000082, 33.834029000000044],
            [71.740582434000032, 33.835131242000045],
            [71.744330110000078, 33.84102039000004],
            [71.744372621000082, 33.841087193000078],
            [71.749158000000079, 33.848607000000072],
            [71.74924500000003, 33.847123000000067],
            [71.750483000000031, 33.826077000000055],
            [71.757772000000045, 33.813487000000066],
            [71.77168800000004, 33.805535000000077],
            [71.779640000000029, 33.794933000000071],
            [71.790242000000035, 33.78499400000004],
            [71.811446000000046, 33.783668000000034],
            [71.84110481700003, 33.782350117000078],
            [71.84115444400004, 33.782347913000024],
            [71.841265000000078, 33.782343000000026],
            [71.852530000000058, 33.771078000000045],
            [71.856506000000081, 33.757825000000025],
            [71.856506000000081, 33.751919732000033],
            [71.856506000000081, 33.751919282000074],
            [71.856506000000081, 33.751918832000058],
            [71.856506000000081, 33.745898000000068],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "49",
      properties: { name: "FR Tank", count: 303 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.283730829000035, 32.639418339000031],
            [70.283838192000076, 32.639333982000039],
            [70.284028549000084, 32.639119831000073],
            [70.284337879000077, 32.638762912000061],
            [70.28502792300003, 32.637858717000029],
            [70.285789350000073, 32.636835548000079],
            [70.287002875000042, 32.635146131000056],
            [70.288216401000057, 32.633837428000049],
            [70.289382336000074, 32.632100421000075],
            [70.291500057000064, 32.62941163000005],
            [70.293260858000053, 32.627032169000074],
            [70.294617151000068, 32.625176190000047],
            [70.295830676000037, 32.623843692000037],
            [70.297424914000032, 32.622082890000058],
            [70.299209510000082, 32.620322089000069],
            [70.300351651000028, 32.618846823000069],
            [70.30139861400005, 32.617561915000067],
            [70.301993480000078, 32.616681514000049],
            [70.302540756000042, 32.616062854000063],
            [70.303040442000054, 32.615586962000066],
            [70.30351633500004, 32.615230043000054],
            [70.304063611000061, 32.615015891000041],
            [70.304563297000072, 32.614754151000056],
            [70.305158163000044, 32.614468615000078],
            [70.305491287000052, 32.614325848000078],
            [70.305967179000049, 32.613968929000066],
            [70.306347893000066, 32.61358821500005],
            [70.306704812000078, 32.612993350000067],
            [70.306966553000052, 32.612184333000073],
            [70.307204499000079, 32.611541878000025],
            [70.307418651000035, 32.610828040000058],
            [70.307632802000057, 32.609971434000045],
            [70.307751775000042, 32.609424158000024],
            [70.307965927000055, 32.608710320000057],
            [70.308180078000078, 32.608186838000051],
            [70.308322846000067, 32.607782330000077],
            [70.308584586000052, 32.607187465000038],
            [70.308965300000068, 32.606640189000075],
            [70.309393603000046, 32.606021529000031],
            [70.30970293300004, 32.605450458000064],
            [70.310059852000052, 32.604760415000044],
            [70.310440566000068, 32.604213139000024],
            [70.310464361000072, 32.603642068000056],
            [70.31048815500003, 32.602952024000047],
            [70.310511950000034, 32.602119213000037],
            [70.310678512000038, 32.601262607000024],
            [70.31079748500008, 32.600762920000079],
            [70.310987842000031, 32.600310823000029],
            [70.311083020000069, 32.599882520000051],
            [70.311273377000077, 32.599549395000054],
            [70.311535118000052, 32.599168681000037],
            [70.311939626000083, 32.59869278900004],
            [70.312320340000042, 32.598145513000077],
            [70.312748643000077, 32.597645826000075],
            [70.313034178000066, 32.59721752300004],
            [70.313391098000068, 32.596765426000047],
            [70.31358145400003, 32.596241944000042],
            [70.313866990000065, 32.595575695000036],
            [70.314104936000035, 32.59481426800005],
            [70.314485650000051, 32.594076635000079],
            [70.314699801000074, 32.593481770000039],
            [70.314985336000063, 32.59255378000006],
            [70.315342256000065, 32.591387844000053],
            [70.315937121000047, 32.590245703000051],
            [70.316579575000048, 32.589198740000029],
            [70.317317208000077, 32.587961420000056],
            [70.318007252000029, 32.587009636000062],
            [70.318887652000058, 32.585796111000036],
            [70.319577696000067, 32.58503468300006],
            [70.320291534000035, 32.584416023000074],
            [70.32095778300004, 32.583892542000058],
            [70.32205233500008, 32.583131114000025],
            [70.322742379000033, 32.58286937400004],
            [70.32352760100008, 32.582560044000047],
            [70.324217645000033, 32.582345892000035],
            [70.324883894000038, 32.582155535000027],
            [70.325669116000029, 32.582060357000046],
            [70.326573311000061, 32.581917589000057],
            [70.32740612300006, 32.581870000000038],
            [70.329143129000045, 32.58167964300003],
            [70.330570806000082, 32.581584465000049],
            [70.33183192000007, 32.581489286000078],
            [70.332974061000073, 32.581322724000074],
            [70.334758657000066, 32.581084778000047],
            [70.335900798000068, 32.580965805000062],
            [70.336900172000071, 32.580846832000077],
            [70.337804367000047, 32.580823037000073],
            [70.338470616000052, 32.580823037000073],
            [70.339041687000076, 32.580894421000039],
            [70.33973173000004, 32.580894421000039],
            [70.34068351500008, 32.581108573000051],
            [70.341706683000041, 32.581275135000055],
            [70.342325343000084, 32.581489286000078],
            [70.342967797000028, 32.58153687600003],
            [70.343515073000049, 32.58153687600003],
            [70.344157528000039, 32.581513081000026],
            [70.344681009000055, 32.581346519000078],
            [70.345180696000057, 32.581060983000043],
            [70.345632794000039, 32.580704064000031],
            [70.34627524800004, 32.580442324000046],
            [70.346846319000065, 32.580156788000068],
            [70.347464979000051, 32.580014021000068],
            [70.348226406000038, 32.579895048000026],
            [70.348773682000058, 32.579799869000055],
            [70.349582699000052, 32.579585718000033],
            [70.35005859100005, 32.579300182000054],
            [70.350463099000081, 32.579038442000069],
            [70.351034170000048, 32.578729112000076],
            [70.351319705000037, 32.578443576000041],
            [70.351962160000028, 32.578158041000052],
            [70.352390463000063, 32.578015273000062],
            [70.352890149000075, 32.577824917000044],
            [70.353366042000061, 32.577682149000054],
            [70.35393711200004, 32.57765835400005],
            [70.354365415000075, 32.577634560000035],
            [70.354984075000061, 32.57742040800008],
            [70.355317200000059, 32.577134873000034],
            [70.355697913000029, 32.576754159000075],
            [70.356031038000083, 32.576254472000073],
            [70.356340368000076, 32.575873759000046],
            [70.35674487600005, 32.575421661000064],
            [70.357173179000029, 32.574945769000067],
            [70.357601482000064, 32.57470782300004],
            [70.35822014200005, 32.574588850000055],
            [70.358696034000047, 32.574612644000069],
            [70.359100543000068, 32.574612644000069],
            [70.359505051000042, 32.574684028000036],
            [70.360266478000028, 32.574898180000048],
            [70.36076616500003, 32.575183715000037],
            [70.361503798000058, 32.575540634000049],
            [70.362289020000048, 32.575897553000061],
            [70.363264599000047, 32.576135499000031],
            [70.364430535000054, 32.576421035000067],
            [70.365191963000029, 32.576563802000067],
            [70.365786828000068, 32.576658981000037],
            [70.36652446100004, 32.576658981000037],
            [70.367143121000083, 32.576587597000071],
            [70.367809370000032, 32.576444829000025],
            [70.368428030000075, 32.576159294000036],
            [70.369046689000072, 32.575778580000076],
            [70.369570171000078, 32.575564429000053],
            [70.370046063000075, 32.57539786600006],
            [70.370426777000034, 32.575326482000037],
            [70.370736107000084, 32.575374072000045],
            [70.371045437000078, 32.575754785000072],
            [70.371188204000077, 32.576016526000046],
            [70.371449945000052, 32.576421035000067],
            [70.371759275000045, 32.577039694000064],
            [70.371830659000068, 32.577396614000065],
            [70.371973426000068, 32.577729738000073],
            [70.372330346000069, 32.577991479000048],
            [70.372711059000039, 32.578372193000064],
            [70.373091773000056, 32.578681522000068],
            [70.373567665000053, 32.579109825000046],
            [70.374019763000035, 32.579490539000062],
            [70.374400477000052, 32.579728485000032],
            [70.374709807000045, 32.579966431000059],
            [70.374947753000072, 32.580109199000049],
            [70.375233288000061, 32.580180583000072],
            [70.37551882300005, 32.58020437700003],
            [70.375899537000066, 32.58020437700003],
            [70.376256456000078, 32.580156788000068],
            [70.376732348000075, 32.580132994000053],
            [70.377303419000043, 32.580085404000044],
            [70.378017257000067, 32.579966431000059],
            [70.378635917000054, 32.579847458000074],
            [70.379325961000063, 32.579847458000074],
            [70.379873237000083, 32.579847458000074],
            [70.380372924000028, 32.579847458000074],
            [70.381181940000033, 32.58006161000003],
            [70.381919573000062, 32.580251967000038],
            [70.382371671000044, 32.580323351000061],
            [70.382966536000083, 32.58051370700008],
            [70.383394839000061, 32.580561297000031],
            [70.383799347000036, 32.580632680000065],
            [70.384299034000037, 32.580632680000065],
            [70.384727337000072, 32.580632680000065],
            [70.385179435000055, 32.580585091000046],
            [70.385726711000075, 32.580561297000031],
            [70.38663090600005, 32.580418529000042],
            [70.387083004000033, 32.580466118000061],
            [70.387416128000041, 32.58051370700008],
            [70.387654074000068, 32.580585091000046],
            [70.387892020000038, 32.580799243000058],
            [70.388391707000039, 32.58115616200007],
            [70.38879621500007, 32.581560670000044],
            [70.389367286000038, 32.582131741000069],
            [70.389652821000084, 32.582441071000062],
            [70.390009740000039, 32.582488660000024],
            [70.39041424900006, 32.582488660000024],
            [70.391151882000031, 32.582274509000058],
            [70.391770542000074, 32.582036562000042],
            [70.392460585000038, 32.581608259000063],
            [70.393007861000058, 32.58115616200007],
            [70.393507548000059, 32.580680270000073],
            [70.393935851000037, 32.580180583000072],
            [70.394483127000058, 32.579728485000032],
            [70.395125582000048, 32.579585718000033],
            [70.396124955000062, 32.579490539000062],
            [70.396981561000075, 32.579347772000062],
            [70.398004729000036, 32.579371566000077],
            [70.399432406000074, 32.579490539000062],
            [70.400407985000072, 32.579514334000066],
            [70.401193207000063, 32.579752280000037],
            [70.402549500000077, 32.580275761000053],
            [70.403596463000042, 32.580680270000073],
            [70.404310301000066, 32.580942010000058],
            [70.404762398000059, 32.580918216000043],
            [70.405737977000058, 32.580775448000054],
            [70.407308422000028, 32.580585091000046],
            [70.408950250000032, 32.580561297000031],
            [70.410377926000081, 32.58037094000008],
            [70.411472478000064, 32.580347145000076],
            [70.412257700000055, 32.580299556000057],
            [70.412828771000079, 32.580442324000046],
            [70.413090512000053, 32.58060888600005],
            [70.413590199000055, 32.581037189000028],
            [70.414232653000056, 32.58167964300003],
            [70.414756134000072, 32.58208415200005],
            [70.415351000000044, 32.582322098000077],
            [70.416136222000034, 32.582488660000024],
            [70.416683498000054, 32.582536249000043],
            [70.418087380000031, 32.582655222000028],
            [70.41951505600008, 32.58277419500007],
            [70.420276484000055, 32.582893168000055],
            [70.420776171000057, 32.583131114000025],
            [70.421252063000054, 32.583369061000042],
            [70.421846928000036, 32.583821158000035],
            [70.422989069000039, 32.584725353000067],
            [70.424369157000058, 32.585867495000059],
            [70.425439914000037, 32.586819279000053],
            [70.425963396000043, 32.587390350000078],
            [70.426558261000082, 32.587747269000033],
            [70.426938975000041, 32.587890036000033],
            [70.427438661000053, 32.588151777000064],
            [70.428104910000059, 32.588175572000068],
            [70.428723570000045, 32.58812798200006],
            [70.429603971000063, 32.588056599000026],
            [70.430389193000053, 32.587794858000052],
            [70.431055442000059, 32.587675885000067],
            [70.431840664000049, 32.587318966000055],
            [70.432602092000081, 32.586819279000053],
            [70.433839411000065, 32.586105441000029],
            [70.43464842800006, 32.585629548000043],
            [70.435505034000073, 32.585415397000077],
            [70.436195078000083, 32.585344013000054],
            [70.436789943000065, 32.585344013000054],
            [70.437479987000074, 32.585581959000024],
            [70.437908290000053, 32.585748522000074],
            [70.438170030000038, 32.586129235000044],
            [70.43852694900005, 32.586724101000073],
            [70.439050431000055, 32.587747269000033],
            [70.439502528000048, 32.588627669000061],
            [70.439907037000069, 32.589317713000071],
            [70.440430518000028, 32.59053123800004],
            [70.441072973000075, 32.591816147000031],
            [70.441763016000039, 32.593386591000069],
            [70.441977168000051, 32.593910073000075],
            [70.442405471000029, 32.594600116000038],
            [70.442786185000045, 32.595052214000077],
            [70.443333461000066, 32.595313955000051],
            [70.443714174000036, 32.595599490000041],
            [70.444190067000079, 32.59574225800003],
            [70.444665959000076, 32.595789847000049],
            [70.445284619000063, 32.595766052000045],
            [70.446474349000084, 32.595575695000036],
            [70.447545107000053, 32.59550431100007],
            [70.44816376600005, 32.595551901000078],
            [70.44949626500005, 32.595575695000036],
            [70.45018630800007, 32.595551901000078],
            [70.451019120000069, 32.595456722000051],
            [70.452137466000067, 32.595218776000024],
            [70.452851304000035, 32.595147392000058],
            [70.453731705000052, 32.595147392000058],
            [70.454302776000077, 32.595028419000073],
            [70.454707284000051, 32.594933241000035],
            [70.455373533000056, 32.594552527000076],
            [70.455635274000031, 32.594290786000045],
            [70.455920809000077, 32.593910073000075],
            [70.456087371000081, 32.593481770000039],
            [70.456206344000066, 32.593029672000057],
            [70.456396701000074, 32.592672753000045],
            [70.456848799000056, 32.592339628000047],
            [70.457300896000049, 32.592196861000048],
            [70.457871967000074, 32.592173066000043],
            [70.458514422000064, 32.592173066000043],
            [70.459109287000047, 32.592315834000033],
            [70.459632768000063, 32.592411012000071],
            [70.460275223000053, 32.592648958000041],
            [70.461060445000044, 32.592982083000038],
            [70.462345354000036, 32.593362797000054],
            [70.463273343000083, 32.593600743000025],
            [70.464225128000066, 32.593743510000024],
            [70.465676599000062, 32.594219403000068],
            [70.466271464000044, 32.594314581000049],
            [70.467128070000058, 32.594504938000057],
            [70.468008471000076, 32.594861857000069],
            [70.468865077000032, 32.595218776000024],
            [70.469459942000071, 32.59550431100007],
            [70.470173780000039, 32.595528106000074],
            [70.470911413000067, 32.595528106000074],
            [70.471791814000028, 32.595551901000078],
            [70.472220117000063, 32.595789847000049],
            [70.472862571000064, 32.596384712000031],
            [70.473505026000055, 32.596884399000032],
            [70.473933329000033, 32.59721752300004],
            [70.47417127500006, 32.597431675000053],
            [70.474813729000061, 32.597764799000061],
            [70.475813103000064, 32.598193102000039],
            [70.476526941000031, 32.598526227000036],
            [70.477621493000072, 32.599121092000075],
            [70.478620867000075, 32.599501806000035],
            [70.479334705000042, 32.599834930000043],
            [70.48021510600006, 32.599977698000032],
            [70.481071711000084, 32.60016805500004],
            [70.481523809000066, 32.600382206000063],
            [70.481761755000036, 32.600596358000075],
            [70.482023496000068, 32.600953277000031],
            [70.482118674000048, 32.601262607000024],
            [70.482213853000076, 32.60178608800004],
            [70.482356620000076, 32.60219059700006],
            [70.482404210000084, 32.602618900000039],
            [70.482404210000084, 32.602904435000028],
            [70.482523183000069, 32.603689657000075],
            [70.482975280000062, 32.603880014000026],
            [70.483355994000078, 32.603880014000026],
            [70.483736708000038, 32.603570684000033],
            [70.484069832000046, 32.603237560000025],
            [70.484283984000058, 32.602880640000024],
            [70.484450546000062, 32.602571311000077],
            [70.484783671000059, 32.602166802000056],
            [70.48533094700008, 32.602095418000033],
            [70.485854428000039, 32.60219059700006],
            [70.486187553000036, 32.602238186000079],
            [70.486663445000033, 32.602143008000041],
            [70.487234515000068, 32.602119213000037],
            [70.48773420200007, 32.602024034000067],
            [70.488709781000068, 32.601524348000055],
            [70.48920946800007, 32.601238812000076],
            [70.489851922000071, 32.600810509000041],
            [70.490422993000038, 32.600453590000029],
            [70.490875091000078, 32.60007287600007],
            [70.491446161000056, 32.599811136000028],
            [70.492136205000065, 32.599644574000024],
            [70.492850043000033, 32.599644574000024],
            [70.49363526500008, 32.599644574000024],
            [70.494230131000052, 32.599644574000024],
            [70.494848790000049, 32.599478011000031],
            [70.49534847700005, 32.599287654000079],
            [70.495919548000074, 32.599263860000065],
            [70.496395440000072, 32.599287654000079],
            [70.496847538000054, 32.599573190000058],
            [70.497323430000051, 32.599739752000062],
            [70.497870706000072, 32.599953903000028],
            [70.498917669000036, 32.600501179000048],
            [70.498983330000044, 32.600523066000051],
            [70.503252991000068, 32.597454295000034],
            [70.507629161000068, 32.594308971000032],
            [70.511019460000057, 32.591872231000025],
            [70.51673442200007, 32.587764665000066],
            [70.518655969000065, 32.586383574000024],
            [70.52279044200003, 32.583411967000075],
            [70.52526000000006, 32.581637000000057],
            [70.529798249000066, 32.579587475000039],
            [70.534113709000053, 32.577638565000029],
            [70.539987170000074, 32.574986043000024],
            [70.539996607000035, 32.574981781000076],
            [70.543600300000037, 32.573354313000038],
            [70.545802000000037, 32.57236000000006],
            [70.551766000000043, 32.551818000000026],
            [70.562368000000049, 32.539228000000037],
            [70.568582550000031, 32.532607927000072],
            [70.57375274900005, 32.527100354000027],
            [70.575900810000064, 32.524812123000061],
            [70.578228763000084, 32.522332263000067],
            [70.58155191600008, 32.518792261000044],
            [70.583443611000064, 32.516777126000079],
            [70.586117472000069, 32.513928785000076],
            [70.58783255600008, 32.512101786000073],
            [70.589944194000054, 32.509852356000067],
            [70.592849000000058, 32.506758000000048],
            [70.593374206000078, 32.505357538000055],
            [70.595274772000039, 32.500289681000027],
            [70.596612404000041, 32.496722886000043],
            [70.598813000000064, 32.490855000000067],
            [70.599510987000031, 32.485969880000027],
            [70.600104481000074, 32.481816094000067],
            [70.600646124000036, 32.478025209000066],
            [70.601188558000047, 32.474228780000033],
            [70.601464000000078, 32.472301000000073],
            [70.59795500000007, 32.471622000000025],
            [70.580922000000044, 32.46832500000005],
            [70.557067000000075, 32.46368700000005],
            [70.55416094800006, 32.465338118000034],
            [70.527910000000077, 32.480253000000062],
            [70.506438943000035, 32.491737939000075],
            [70.499417000000051, 32.495494000000065],
            [70.485501000000056, 32.513385000000028],
            [70.45700800000003, 32.496156000000042],
            [70.43447800000007, 32.471638000000041],
            [70.43447800000007, 32.45507200000003],
            [70.430162089000078, 32.442740295000078],
            [70.42984000000007, 32.441820000000064],
            [70.419900000000041, 32.428567000000044],
            [70.405579963000037, 32.410991605000049],
            [70.405557767000062, 32.410964364000051],
            [70.40555579200003, 32.41096193900006],
            [70.405553817000055, 32.410959514000069],
            [70.405322000000069, 32.410675000000026],
            [70.395382000000041, 32.394109000000071],
            [70.374840000000063, 32.380857000000049],
            [70.357612000000074, 32.367604000000028],
            [70.344552829000065, 32.359948717000066],
            [70.338395000000048, 32.356339000000048],
            [70.319178000000079, 32.334472000000062],
            [70.318516000000045, 32.321219000000042],
            [70.308519037000053, 32.308097162000024],
            [70.308518855000045, 32.308096922000061],
            [70.307914000000039, 32.307303000000047],
            [70.303684445000044, 32.303979811000033],
            [70.298637000000042, 32.300014000000033],
            [70.276769000000058, 32.302665000000047],
            [70.252252000000055, 32.308629000000053],
            [70.220445000000041, 32.30664100000007],
            [70.20918000000006, 32.313930000000028],
            [70.202554000000077, 32.305315000000064],
            [70.183337000000051, 32.274171000000024],
            [70.16677100000004, 32.248991000000046],
            [70.153428174000055, 32.238984020000032],
            [70.153311041000052, 32.238896172000068],
            [70.142916000000071, 32.231100000000026],
            [70.120386000000053, 32.224473000000046],
            [70.095675239000059, 32.213417928000069],
            [70.096227395000028, 32.21606930300004],
            [70.096294297000043, 32.216325760000075],
            [70.096587323000051, 32.217297372000075],
            [70.096746395000082, 32.217824821000079],
            [70.096841573000063, 32.218395891000057],
            [70.097246081000037, 32.219085935000066],
            [70.09731746500006, 32.219561827000064],
            [70.097388849000083, 32.220013925000046],
            [70.097388849000083, 32.220394639000062],
            [70.09731746500006, 32.22087053100006],
            [70.096960546000048, 32.221441601000038],
            [70.096556038000074, 32.222012672000062],
            [70.096008762000054, 32.22272651000003],
            [70.095390102000067, 32.223416554000039],
            [70.094652469000039, 32.224201776000029],
            [70.09422416600006, 32.22460628400006],
            [70.093748274000063, 32.225224944000047],
            [70.093129614000077, 32.225748426000052],
            [70.092701311000042, 32.226010166000037],
            [70.092273008000063, 32.226367086000039],
            [70.091440197000054, 32.226747799000066],
            [70.090797742000063, 32.22700954000004],
            [70.090012520000073, 32.227104718000078],
            [70.089227298000083, 32.227223692000052],
            [70.088061362000076, 32.227318870000033],
            [70.087299935000033, 32.227342665000037],
            [70.08660989100008, 32.227342665000037],
            [70.085848463000048, 32.227318870000033],
            [70.085348777000036, 32.227247486000067],
            [70.084706322000045, 32.227247486000067],
            [70.084230430000048, 32.227176102000044],
            [70.083421413000053, 32.227271281000071],
            [70.082826548000071, 32.227366459000052],
            [70.081874764000077, 32.227533021000056],
            [70.08085159500007, 32.227770968000073],
            [70.079994989000056, 32.228080297000076],
            [70.079209767000066, 32.228270654000028],
            [70.078424545000075, 32.228603779000025],
            [70.078020037000044, 32.228722752000067],
            [70.077591734000066, 32.229079671000079],
            [70.077092047000065, 32.229507974000057],
            [70.076639949000082, 32.230079045000025],
            [70.076259236000055, 32.23050734800006],
            [70.075830933000077, 32.230959445000053],
            [70.075450219000061, 32.231316364000065],
            [70.075069505000044, 32.23157810500004],
            [70.074617408000051, 32.231649489000063],
            [70.073951159000046, 32.231816051000067],
            [70.073165936000066, 32.231887435000033],
            [70.072594866000031, 32.231887435000033],
            [70.072023795000064, 32.231863640000029],
            [70.071524108000062, 32.231863640000029],
            [70.071072011000069, 32.231768462000048],
            [70.07061991300003, 32.231744667000044],
            [70.070262994000075, 32.231744667000044],
            [70.069787102000078, 32.231768462000048],
            [70.06931121000008, 32.231958819000056],
            [70.068978085000083, 32.232244354000045],
            [70.068597371000067, 32.232577479000042],
            [70.068192863000036, 32.233148549000077],
            [70.067883533000042, 32.233672031000026],
            [70.067812149000076, 32.234076539000057],
            [70.067621792000068, 32.234576226000058],
            [70.067479025000068, 32.23521868000006],
            [70.067479025000068, 32.23576595600008],
            [70.06752661400003, 32.236194259000058],
            [70.06752661400003, 32.236479795000037],
            [70.067241079000041, 32.236860508000063],
            [70.066907954000044, 32.237146044000042],
            [70.066146527000058, 32.237502963000054],
            [70.065456483000048, 32.23788367700007],
            [70.064576082000031, 32.238074034000078],
            [70.063933628000029, 32.238216801000078],
            [70.063243584000077, 32.238216801000078],
            [70.062505951000048, 32.238240596000026],
            [70.06193488100007, 32.238240596000026],
            [70.061054480000053, 32.238240596000026],
            [70.060649972000078, 32.23826439000004],
            [70.060031312000035, 32.23826439000004],
            [70.059317474000068, 32.238335774000063],
            [70.058841581000081, 32.23840715800003],
            [70.058318100000065, 32.238883050000027],
            [70.058151538000061, 32.239192380000077],
            [70.058127743000057, 32.239620683000055],
            [70.058127743000057, 32.240144165000061],
            [70.058127743000057, 32.240953181000066],
            [70.058127743000057, 32.241809787000079],
            [70.058460868000054, 32.243118491000075],
            [70.058532252000077, 32.243903713000066],
            [70.058698814000081, 32.244355810000059],
            [70.058865376000028, 32.244879292000064],
            [70.059293679000064, 32.245593130000032],
            [70.05991233900005, 32.246521120000068],
            [70.060364436000043, 32.247377726000025],
            [70.060864123000044, 32.248162948000072],
            [70.061244837000061, 32.248876786000039],
            [70.061649345000035, 32.249400268000045],
            [70.061792113000081, 32.249757187000057],
            [70.061744524000062, 32.250018928000031],
            [70.061506578000035, 32.250304463000077],
            [70.061221042000057, 32.250708971000051],
            [70.060911712000063, 32.251089685000068],
            [70.060197874000039, 32.251589372000069],
            [70.059531625000034, 32.252041469000062],
            [70.058770198000047, 32.252517362000049],
            [70.058103949000042, 32.252993254000046],
            [70.057770824000045, 32.253421557000024],
            [70.057437699000047, 32.253730887000074],
            [70.056700067000065, 32.254706466000073],
            [70.055938639000033, 32.255801018000057],
            [70.055605515000082, 32.256562445000043],
            [70.05510582800008, 32.257252489000052],
            [70.054534757000056, 32.257918738000058],
            [70.054273016000081, 32.258466014000078],
            [70.054130249000082, 32.258870522000052],
            [70.05401127600004, 32.259346415000039],
            [70.05401127600004, 32.259941280000078],
            [70.053963686000031, 32.26077409100003],
            [70.05401127600004, 32.261511724000059],
            [70.054130249000082, 32.262106589000041],
            [70.054130249000082, 32.262677660000065],
            [70.054177838000044, 32.263415293000037],
            [70.054463373000033, 32.263843596000072],
            [70.054772703000083, 32.26427189900005],
            [70.055296185000032, 32.264938148000056],
            [70.055700693000063, 32.265556808000042],
            [70.055891050000071, 32.266175467000039],
            [70.055962434000037, 32.266627565000078],
            [70.056081407000079, 32.266984484000034],
            [70.05627176400003, 32.267103457000076],
            [70.056676272000061, 32.267198636000046],
            [70.057366316000071, 32.267317609000031],
            [70.058936760000051, 32.267555555000058],
            [70.060007517000031, 32.267769706000024],
            [70.061102069000071, 32.267983858000036],
            [70.06193488100007, 32.268198009000059],
            [70.063267379000081, 32.268816669000046],
            [70.064980591000051, 32.269459124000036],
            [70.066455857000051, 32.269935016000034],
            [70.068407015000048, 32.270625059000054],
            [70.069406388000061, 32.270767827000043],
            [70.070834065000042, 32.270910595000032],
            [70.072475893000046, 32.271172336000063],
            [70.073308704000056, 32.271196130000078],
            [70.074189105000073, 32.271267514000044],
            [70.074522229000081, 32.271386487000029],
            [70.074688791000028, 32.271957558000054],
            [70.074688791000028, 32.27248103900007],
            [70.074593613000047, 32.272909342000048],
            [70.074641202000066, 32.275288803000024],
            [70.074783970000055, 32.276787863000038],
            [70.074902943000041, 32.277811032000045],
            [70.074950532000059, 32.278643843000054],
            [70.074974327000064, 32.279500449000068],
            [70.075021916000082, 32.280618796000056],
            [70.07518847800003, 32.281451607000065],
            [70.075521603000084, 32.283307586000035],
            [70.075664370000084, 32.284378344000061],
            [70.075664370000084, 32.28521115500007],
            [70.075807138000073, 32.286567448000028],
            [70.075926111000058, 32.287780973000054],
            [70.075902317000043, 32.288185481000028],
            [70.075854727000035, 32.288518606000025],
            [70.075807138000073, 32.288994498000079],
            [70.075854727000035, 32.28977972000007],
            [70.075807138000073, 32.290969451000024],
            [70.075759549000054, 32.291992619000041],
            [70.075688165000031, 32.29296819800004],
            [70.075711960000035, 32.294276901000046],
            [70.075973700000077, 32.295300070000053],
            [70.076164057000028, 32.296204265000028],
            [70.076187852000032, 32.296941898000057],
            [70.076187852000032, 32.297703325000043],
            [70.07628303000007, 32.299131002000024],
            [70.07628303000007, 32.299940019000076],
            [70.076306825000074, 32.300820419000047],
            [70.076354414000036, 32.302842961000067],
            [70.076354414000036, 32.303937513000051],
            [70.076354414000036, 32.304603762000056],
            [70.076306825000074, 32.305079654000053],
            [70.076187852000032, 32.305341395000028],
            [70.075854727000035, 32.305603136000059],
            [70.075426424000057, 32.305674519000036],
            [70.074879148000036, 32.305983849000029],
            [70.074141515000065, 32.306412152000064],
            [70.073618034000049, 32.306602509000072],
            [70.073403883000083, 32.306769071000076],
            [70.073261115000037, 32.307007018000036],
            [70.073261115000037, 32.307292553000025],
            [70.073284909000051, 32.307697061000056],
            [70.073594239000045, 32.308886792000067],
            [70.07371321200003, 32.309862371000065],
            [70.073855980000076, 32.310600004000037],
            [70.073927364000042, 32.31105210100003],
            [70.074046337000084, 32.311385226000027],
            [70.074450845000058, 32.311837323000077],
            [70.074546024000028, 32.312051475000032],
            [70.07466499700007, 32.312455983000063],
            [70.074855354000078, 32.313027054000031],
            [70.074926738000045, 32.313383973000043],
            [70.074950532000059, 32.313812276000078],
            [70.074950532000059, 32.314192990000038],
            [70.075117094000063, 32.314645087000031],
            [70.075283657000057, 32.315002006000043],
            [70.075521603000084, 32.315168569000036],
            [70.075926111000058, 32.315644461000034],
            [70.076045084000043, 32.316001380000046],
            [70.076449593000063, 32.317143521000048],
            [70.076639949000082, 32.31764320800005],
            [70.076949279000075, 32.318309457000055],
            [70.077115842000069, 32.318571198000029],
            [70.077115842000069, 32.318928117000041],
            [70.077234815000054, 32.319475393000062],
            [70.077353788000039, 32.319879901000036],
            [70.07775829600007, 32.320617534000064],
            [70.078305572000033, 32.322140389000026],
            [70.078662491000046, 32.323044584000058],
            [70.079138383000043, 32.323806012000034],
            [70.079614276000029, 32.324353288000054],
            [70.079804633000037, 32.324829180000052],
            [70.080042579000065, 32.324995742000056],
            [70.080447087000039, 32.324995742000056],
            [70.080780212000036, 32.324852975000056],
            [70.081517844000075, 32.324567439000077],
            [70.081969942000057, 32.324258110000073],
            [70.082541013000082, 32.324043958000061],
            [70.08311208300006, 32.323829807000038],
            [70.083421413000053, 32.323806012000034],
            [70.083706948000042, 32.323972574000038],
            [70.084111457000063, 32.32418672600005],
            [70.08482529500003, 32.324781591000033],
            [70.085087036000061, 32.325090921000026],
            [70.085491544000035, 32.32525748300003],
            [70.085967436000033, 32.325590608000027],
            [70.086490918000038, 32.325828554000054],
            [70.086752659000069, 32.325995116000058],
            [70.087038194000058, 32.326233062000028],
            [70.087371318000066, 32.326518597000074],
            [70.087656854000045, 32.326851722000072],
            [70.087704443000064, 32.327089668000042],
            [70.088132746000042, 32.327613150000047],
            [70.088156540000057, 32.327898685000036],
            [70.088108951000038, 32.328231809000044],
            [70.08791859400003, 32.328636318000065],
            [70.087442702000033, 32.329588102000059],
            [70.087014399000054, 32.330349530000035],
            [70.08684783700005, 32.330635065000024],
            [70.08684783700005, 32.33101577900004],
            [70.087038194000058, 32.331515465000052],
            [70.087204756000062, 32.332038947000058],
            [70.087704443000064, 32.332776580000029],
            [70.088561049000077, 32.333894926000028],
            [70.08925109300003, 32.334775327000045],
            [70.090226672000028, 32.335560549000036],
            [70.091059483000038, 32.336179209000079],
            [70.092177830000082, 32.336845458000028],
            [70.093105819000073, 32.337392734000048],
            [70.093748274000063, 32.337963805000072],
            [70.094938004000028, 32.339153535000037],
            [70.095889789000069, 32.34001014100005],
            [70.09653224300007, 32.340414649000024],
            [70.097198492000075, 32.340866747000064],
            [70.097983714000065, 32.341318845000046],
            [70.098412017000044, 32.341794737000043],
            [70.098745142000041, 32.34203268300007],
            [70.098745142000041, 32.34217545100006],
            [70.098507196000071, 32.34241339700003],
            [70.097959920000051, 32.34255616400003],
            [70.096865368000067, 32.342698932000076],
            [70.096222913000076, 32.342746521000038],
            [70.095604253000033, 32.342841700000065],
            [70.09505697700007, 32.342841700000065],
            [70.094438317000083, 32.342817905000061],
            [70.093819658000029, 32.342675137000072],
            [70.093177203000039, 32.342460986000049],
            [70.091987473000074, 32.342389602000026],
            [70.090179082000077, 32.342389602000026],
            [70.088965557000051, 32.342389602000026],
            [70.08768064800006, 32.342484781000053],
            [70.086943015000031, 32.342579959000034],
            [70.086324356000034, 32.343032057000073],
            [70.085658106000039, 32.343555538000032],
            [70.085206009000046, 32.344079019000048],
            [70.085110830000076, 32.344602501000054],
            [70.08513462500008, 32.344935625000062],
            [70.085301187000084, 32.34536392800004],
            [70.085705696000048, 32.346220534000054],
            [70.08606261500006, 32.346886783000059],
            [70.086705069000061, 32.348100308000028],
            [70.087347524000052, 32.349432807000028],
            [70.087490291000051, 32.350003877000063],
            [70.087704443000064, 32.35047976900006],
            [70.088061362000076, 32.350836688000072],
            [70.08887037900007, 32.351360170000078],
            [70.089584217000038, 32.351598116000048],
            [70.090131493000058, 32.351836062000075],
            [70.090250466000043, 32.351978830000064],
            [70.090226672000028, 32.352240570000049],
            [70.090083904000039, 32.352454722000061],
            [70.089774574000046, 32.352668873000027],
            [70.089227298000083, 32.35288302500004],
            [70.088989352000056, 32.353097176000063],
            [70.088917968000032, 32.353335123000079],
            [70.088917968000032, 32.35366824700003],
            [70.089060736000079, 32.354643826000029],
            [70.089084530000036, 32.355452843000023],
            [70.089489039000057, 32.356285654000033],
            [70.089964931000054, 32.356999492000057],
            [70.090512207000074, 32.357665741000062],
            [70.090940510000053, 32.35823681200003],
            [70.091582964000054, 32.358522347000076],
            [70.092106446000059, 32.358950650000054],
            [70.092772695000065, 32.359117213000047],
            [70.093224792000058, 32.359164802000066],
            [70.09367689000004, 32.359283775000051],
            [70.094105193000075, 32.359259980000047],
            [70.094414523000069, 32.359307570000055],
            [70.094462112000031, 32.359616899000059],
            [70.09422416600006, 32.359973819000061],
            [70.093986220000033, 32.360283149000054],
            [70.093772068000078, 32.360568684000043],
            [70.093391355000051, 32.360854219000032],
            [70.093105819000073, 32.361163549000025],
            [70.092986846000031, 32.361425290000057],
            [70.09303443500005, 32.361639441000079],
            [70.093462738000028, 32.361687030000041],
            [70.093867247000048, 32.361568057000056],
            [70.094723853000062, 32.361520468000037],
            [70.095223540000063, 32.361449084000071],
            [70.095818405000045, 32.361639441000079],
            [70.097650590000057, 32.362353280000036],
            [70.098745142000041, 32.362829172000033],
            [70.099387596000042, 32.363328859000035],
            [70.100077640000052, 32.363876135000055],
            [70.100791478000076, 32.364375821000067],
            [70.101909825000064, 32.365042070000072],
            [70.102695047000054, 32.365351400000065],
            [70.103480269000045, 32.365660730000059],
            [70.104360670000062, 32.366303185000049],
            [70.105169686000067, 32.36685046100007],
            [70.106121471000051, 32.36763568300006],
            [70.106478390000063, 32.368040191000034],
            [70.107334996000077, 32.368873003000033],
            [70.107929861000059, 32.369658225000023],
            [70.108596110000065, 32.370538625000052],
            [70.109476511000082, 32.371490410000035],
            [70.110356911000054, 32.372109069000032],
            [70.110832803000051, 32.372513578000053],
            [70.111451463000037, 32.373417773000028],
            [70.111713204000068, 32.373750898000026],
            [70.11245083700004, 32.37436955700008],
            [70.112926729000037, 32.374774066000043],
            [70.113426416000038, 32.375511699000072],
            [70.113854719000074, 32.376011385000027],
            [70.114116460000048, 32.376463483000066],
            [70.114259227000048, 32.376963170000067],
            [70.114259227000048, 32.377367678000041],
            [70.114092665000044, 32.377867365000043],
            [70.113973692000059, 32.37820049000004],
            [70.113902308000036, 32.378700176000052],
            [70.11392610300004, 32.379152274000035],
            [70.114092665000044, 32.379842318000044],
            [70.114283022000052, 32.38060374500003],
            [70.114283022000052, 32.381269994000036],
            [70.114021281000078, 32.381888654000079],
            [70.113616773000047, 32.38276905500004],
            [70.113117086000045, 32.383578071000045],
            [70.112688783000067, 32.38424432000005],
            [70.111998739000057, 32.385100926000064],
            [70.111308696000037, 32.385671997000031],
            [70.110571063000066, 32.386433424000074],
            [70.109809635000033, 32.387123468000027],
            [70.109024413000043, 32.387861101000055],
            [70.108453342000075, 32.388503556000046],
            [70.107834683000078, 32.389050832000066],
            [70.107120844000065, 32.389669491000063],
            [70.106288033000055, 32.39028815100005],
            [70.105217276000076, 32.39083542700007],
            [70.104408259000081, 32.391097168000044],
            [70.103313707000041, 32.391382703000033],
            [70.102028798000049, 32.39162064900006],
            [70.101243576000059, 32.39162064900006],
            [70.100149024000075, 32.391739622000046],
            [70.098221660000036, 32.392144131000066],
            [70.09677018900004, 32.392572434000044],
            [70.095509075000052, 32.393024531000037],
            [70.094485907000035, 32.393524218000039],
            [70.093177203000039, 32.394000110000036],
            [70.092772695000065, 32.394214262000048],
            [70.09234439200003, 32.394309440000029],
            [70.091916089000051, 32.394357029000048],
            [70.091630553000073, 32.394357029000048],
            [70.091083277000052, 32.394190467000044],
            [70.090654974000074, 32.394000110000036],
            [70.090226672000028, 32.393976316000078],
            [70.090060109000035, 32.394071494000059],
            [70.090274261000047, 32.394357029000048],
            [70.090536001000032, 32.394761538000068],
            [70.090750153000045, 32.395356403000051],
            [70.090964304000067, 32.396141625000041],
            [70.091011894000076, 32.396998231000055],
            [70.09117845600008, 32.397545507000075],
            [70.090988099000072, 32.39790242600003],
            [70.091011894000076, 32.398497292000059],
            [70.091011894000076, 32.399805995000065],
            [70.090940510000053, 32.400995726000076],
            [70.090797742000063, 32.401471618000073],
            [70.09048841200007, 32.40223304500006],
            [70.090202877000081, 32.402732732000061],
            [70.089750779000042, 32.40342277600007],
            [70.089441449000049, 32.403922463000072],
            [70.08901314600007, 32.404445944000031],
            [70.088822790000052, 32.404802863000043],
            [70.088751406000029, 32.405112193000036],
            [70.088751406000029, 32.405397728000025],
            [70.088751406000029, 32.405802237000046],
            [70.08887037900007, 32.406159156000058],
            [70.089036941000074, 32.406801610000059],
            [70.089084530000036, 32.40720611900008],
            [70.089060736000079, 32.407919957000047],
            [70.088989352000056, 32.408443438000063],
            [70.088989352000056, 32.408895536000045],
            [70.089036941000074, 32.409181071000035],
            [70.089322476000063, 32.409490401000028],
            [70.089679396000065, 32.409656963000032],
            [70.090226672000028, 32.409894910000048],
            [70.090773948000049, 32.409918704000063],
            [70.091392607000046, 32.409918704000063],
            [70.092154035000078, 32.409752142000059],
            [70.092915462000065, 32.409157277000077],
            [70.093319971000028, 32.409014509000031],
            [70.093962425000029, 32.409109687000068],
            [70.095271129000082, 32.409371428000043],
            [70.096651216000055, 32.409514196000032],
            [70.097484027000064, 32.409561785000051],
            [70.09800750900007, 32.409561785000051],
            [70.098316839000063, 32.40970455300004],
            [70.098269250000044, 32.410037677000048],
            [70.098174071000074, 32.410370802000045],
            [70.097793357000057, 32.411560532000067],
            [70.097531617000072, 32.412512317000051],
            [70.09731746500006, 32.413487896000049],
            [70.097055724000029, 32.414106555000046],
            [70.097055724000029, 32.414534858000025],
            [70.097055724000029, 32.415105929000049],
            [70.097269876000041, 32.416509811000026],
            [70.097507822000068, 32.417818515000079],
            [70.097603001000039, 32.418865477000054],
            [70.097484027000064, 32.420126592000031],
            [70.097484027000064, 32.421125965000044],
            [70.097269876000041, 32.42243466900004],
            [70.096698805000074, 32.424671362000026],
            [70.096318092000047, 32.426146628000026],
            [70.095889789000069, 32.427360153000052],
            [70.095509075000052, 32.428407116000074],
            [70.095390102000067, 32.428978186000052],
            [70.095485280000048, 32.42940648900003],
            [70.095556664000071, 32.429644436000046],
            [70.095770816000083, 32.430191712000067],
            [70.096056351000072, 32.430524836000075],
            [70.09653224300007, 32.430857961000072],
            [70.097008135000067, 32.431310058000065],
            [70.09731746500006, 32.431857334000028],
            [70.09786474100008, 32.432856708000031],
            [70.098578580000037, 32.434474741000031],
            [70.099078266000049, 32.435497909000048],
            [70.099530364000032, 32.436521078000055],
            [70.099744515000054, 32.437282505000042],
            [70.099934872000063, 32.437877370000024],
            [70.100149024000075, 32.438281879000044],
            [70.100149024000075, 32.43866259300006],
            [70.099982461000081, 32.439067101000035],
            [70.099982461000081, 32.439566788000036],
            [70.099839694000082, 32.441494151000029],
            [70.099815899000077, 32.442112811000072],
            [70.099839694000082, 32.442945622000025],
            [70.099839694000082, 32.443397720000064],
            [70.099696926000036, 32.444254326000078],
            [70.099435185000061, 32.444968164000045],
            [70.099363802000084, 32.445586824000031],
            [70.099363802000084, 32.445943743000043],
            [70.099387596000042, 32.446419635000041],
            [70.099696926000036, 32.447133474000054],
            [70.100101435000056, 32.447942490000059],
            [70.10081527300008, 32.44913222100007],
            [70.101291165000077, 32.449893648000057],
            [70.101767057000075, 32.450655076000032],
            [70.10212397600003, 32.451416503000075],
            [70.10243330600008, 32.452487261000044],
            [70.102623663000031, 32.453462840000043],
            [70.102695047000054, 32.454152883000063],
            [70.102861609000058, 32.454890516000034],
            [70.103194734000056, 32.456080247000045],
            [70.103456474000041, 32.45688926400004],
            [70.103551653000068, 32.457365156000037],
            [70.103480269000045, 32.457650691000026],
            [70.103289912000037, 32.458055199000057],
            [70.103242323000075, 32.458412119000059],
            [70.103123350000033, 32.458888011000056],
            [70.103123350000033, 32.459387698000057],
            [70.103028171000062, 32.460339482000052],
            [70.103028171000062, 32.461267472000031],
            [70.102861609000058, 32.462076488000037],
            [70.102647458000035, 32.463290014000052],
            [70.102457101000084, 32.464003852000076],
            [70.102409512000065, 32.464527333000035],
            [70.102385717000061, 32.465312555000025],
            [70.102338128000042, 32.466026394000039],
            [70.102385717000061, 32.466692643000044],
            [70.10243330600008, 32.467335097000046],
            [70.102480895000042, 32.467929962000028],
            [70.102480895000042, 32.468501033000052],
            [70.102338128000042, 32.469143487000053],
            [70.102314333000038, 32.469690764000063],
            [70.102338128000042, 32.470357013000068],
            [70.102552279000065, 32.471665716000075],
            [70.10267125200005, 32.472450938000065],
            [70.102861609000058, 32.473164777000079],
            [70.103075761000071, 32.47394999900007],
            [70.103385091000064, 32.475496648000046],
            [70.103432680000083, 32.476091514000075],
            [70.103551653000068, 32.476710173000072],
            [70.103527858000064, 32.47768575200007],
            [70.103575448000072, 32.478851688000077],
            [70.103646831000049, 32.479636910000067],
            [70.103765804000034, 32.481540479000046],
            [70.103932367000084, 32.482825388000037],
            [70.103932367000084, 32.483777172000032],
            [70.104122724000035, 32.484395832000075],
            [70.104360670000062, 32.48520484900007],
            [70.104598616000033, 32.486489758000062],
            [70.104717589000074, 32.487251185000048],
            [70.104884151000078, 32.488702657000033],
            [70.105003124000064, 32.48986859200005],
            [70.105050713000082, 32.490963145000023],
            [70.105264865000038, 32.492533589000061],
            [70.105288659000053, 32.493223632000024],
            [70.105431427000042, 32.493985060000057],
            [70.105455222000046, 32.495032023000078],
            [70.105479016000061, 32.495603093000057],
            [70.105502811000065, 32.496197959000028],
            [70.105574195000031, 32.496888002000048],
            [70.105645579000054, 32.497839787000032],
            [70.105669373000069, 32.498434652000071],
            [70.105716962000031, 32.498815366000031],
            [70.105859730000077, 32.499243669000066],
            [70.105978703000062, 32.49964817700004],
            [70.106240444000036, 32.499933712000029],
            [70.106502185000068, 32.500100275000079],
            [70.106668747000072, 32.500243042000079],
            [70.106763925000052, 32.500647551000043],
            [70.106906693000042, 32.501337594000063],
            [70.10709705000005, 32.502003843000068],
            [70.107168434000073, 32.502789065000059],
            [70.107168434000073, 32.503193574000079],
            [70.107287407000058, 32.503693261000024],
            [70.107358790000035, 32.505287499000076],
            [70.107430174000058, 32.505834776000029],
            [70.107572942000047, 32.506429641000068],
            [70.107834683000078, 32.506929328000069],
            [70.108215396000048, 32.50757178200007],
            [70.108381959000042, 32.508119058000034],
            [70.108405753000056, 32.50849977200005],
            [70.108500932000084, 32.508975664000047],
            [70.108596110000065, 32.509594324000034],
            [70.108976824000081, 32.510308162000058],
            [70.108976824000081, 32.510855438000078],
            [70.108976824000081, 32.511426509000046],
            [70.109072002000062, 32.512687623000033],
            [70.109333743000036, 32.513686997000036],
            [70.109595484000067, 32.514424630000065],
            [70.109690662000048, 32.515067084000066],
            [70.109857224000052, 32.515804717000037],
            [70.109881019000056, 32.516280609000034],
            [70.109928608000075, 32.516685118000055],
            [70.109999992000041, 32.517517929000064],
            [70.11028552700003, 32.518350740000074],
            [70.110475884000039, 32.51896940000006],
            [70.110547268000062, 32.519469087000061],
            [70.110618652000028, 32.519873595000035],
            [70.111023160000059, 32.521158504000027],
            [70.111380079000071, 32.521800959000075],
            [70.111546642000064, 32.522372029000053],
            [70.111689409000064, 32.522919305000073],
            [70.111689409000064, 32.523442787000079],
            [70.111832177000053, 32.524061446000076],
            [70.112022534000062, 32.524489749000054],
            [70.11235565800007, 32.525013231000059],
            [70.112688783000067, 32.525584301000038],
            [70.113093291000041, 32.526202961000024],
            [70.113521594000076, 32.526702648000025],
            [70.113783335000051, 32.527226130000031],
            [70.114283022000052, 32.527987557000074],
            [70.114663736000068, 32.528511038000033],
            [70.115353779000031, 32.529772153000067],
            [70.115496547000078, 32.530414607000068],
            [70.115877261000037, 32.530985678000036],
            [70.116210385000045, 32.531437775000029],
            [70.116567304000057, 32.531866078000064],
            [70.117019402000039, 32.532508533000055],
            [70.117233553000062, 32.532936836000033],
            [70.117471500000079, 32.533269960000041],
            [70.118042570000057, 32.534031388000074],
            [70.118470873000035, 32.534578664000037],
            [70.118875382000056, 32.535054556000034],
            [70.119208506000064, 32.53543527000005],
            [70.119684398000061, 32.535792189000063],
            [70.120326853000051, 32.536315670000079],
            [70.12075515600003, 32.536791563000065],
            [70.12120725300008, 32.537196071000039],
            [70.121373816000073, 32.537838525000041],
            [70.121397610000031, 32.53829062300008],
            [70.121564172000035, 32.538742721000062],
            [70.121635556000058, 32.53921861300006],
            [70.12199247500007, 32.539861067000061],
            [70.122301805000063, 32.540146603000039],
            [70.122611135000056, 32.54055111100007],
            [70.123063233000039, 32.54093182500003],
            [70.123372563000032, 32.541264949000038],
            [70.123777071000063, 32.541764636000039],
            [70.124062606000052, 32.542145350000055],
            [70.124562293000054, 32.542835393000075],
            [70.124871623000047, 32.543406464000043],
            [70.124919212000066, 32.543787178000059],
            [70.125038185000051, 32.544263070000056],
            [70.125157158000036, 32.544738962000054],
            [70.125323721000029, 32.545048292000047],
            [70.125775818000079, 32.545524184000044],
            [70.126680013000055, 32.546023871000045],
            [70.127132111000037, 32.546166639000035],
            [70.127489030000049, 32.546356996000043],
            [70.12789353900007, 32.54659494200007],
            [70.12867876100006, 32.547094629000071],
            [70.129083269000034, 32.547475342000041],
            [70.129416394000032, 32.547951235000028],
            [70.129630545000055, 32.548260564000032],
            [70.129963670000052, 32.548641278000048],
            [70.130249205000041, 32.548974403000045],
            [70.13084407000008, 32.549355117000061],
            [70.131200989000035, 32.549759625000036],
            [70.131557908000048, 32.550211722000029],
            [70.131914828000049, 32.550735204000034],
            [70.132224157000053, 32.551211096000031],
            [70.132509693000031, 32.551686988000029],
            [70.133033174000047, 32.552258059000053],
            [70.133080763000066, 32.552614978000065],
            [70.133294915000079, 32.553019486000039],
            [70.133723218000057, 32.553352611000037],
            [70.134056342000065, 32.553590557000064],
            [70.134437056000081, 32.554018860000042],
            [70.135008127000049, 32.554399574000058],
            [70.135674376000054, 32.554827877000037],
            [70.13605509000007, 32.555161001000045],
            [70.136840312000061, 32.556065196000077],
            [70.137292409000054, 32.55637452600007],
            [70.138315578000061, 32.557159748000061],
            [70.138624907000064, 32.557659435000062],
            [70.138839059000077, 32.557968765000055],
            [70.139029416000028, 32.558563630000037],
            [70.13938633500004, 32.559039523000024],
            [70.13993361100006, 32.559444031000055],
            [70.140266736000058, 32.559610593000059],
            [70.140742628000055, 32.559800950000067],
            [70.141099547000067, 32.560038896000037],
            [70.141385082000056, 32.560324432000073],
            [70.141860974000053, 32.560728940000047],
            [70.142217894000055, 32.560990681000078],
            [70.142598607000082, 32.561300011000071],
            [70.14288414300006, 32.56158554600006],
            [70.143050705000064, 32.561942465000072],
            [70.143193473000053, 32.562323179000032],
            [70.143645570000047, 32.562870455000052],
            [70.143859722000059, 32.563274963000026],
            [70.144192846000067, 32.563631882000038],
            [70.144692533000068, 32.564060185000073],
            [70.145334987000069, 32.564726434000079],
            [70.145596728000044, 32.565035764000072],
            [70.145882263000033, 32.565487862000055],
            [70.146191593000083, 32.565892370000029],
            [70.146810253000069, 32.566534825000076],
            [70.147071994000044, 32.566915538000046],
            [70.147238556000048, 32.567272458000048],
            [70.147357529000033, 32.567700761000026],
            [70.14745270800006, 32.568105269000057],
            [70.147714448000045, 32.568533572000035],
            [70.147928600000057, 32.568819107000024],
            [70.148261724000065, 32.569009464000032],
            [70.148499670000035, 32.56934258900003],
            [70.14892797300007, 32.569770892000065],
            [70.149189714000045, 32.570151605000035],
            [70.149570428000061, 32.570556114000055],
            [70.149808374000031, 32.570913033000068],
            [70.150212882000062, 32.571412720000069],
            [70.150593596000078, 32.571959996000032],
            [70.15078395300003, 32.572364504000063],
            [70.150998104000053, 32.572769012000037],
            [70.151688148000062, 32.573292494000043],
            [70.152140246000044, 32.573720797000078],
            [70.152616138000042, 32.574172894000071],
            [70.153068236000081, 32.574672581000073],
            [70.153472744000055, 32.574981911000066],
            [70.154091404000042, 32.575743339000041],
            [70.15428176100005, 32.576052668000045],
            [70.15442452800005, 32.576504766000028],
            [70.154543501000035, 32.576885480000044],
            [70.15482903700007, 32.577218604000052],
            [70.155257340000048, 32.577480345000026],
            [70.155590464000056, 32.577789675000076],
            [70.155899794000049, 32.578027621000047],
            [70.156161535000081, 32.57833695100004],
            [70.156566043000055, 32.578741459000071],
            [70.156946757000071, 32.57912217300003],
            [70.157184703000041, 32.579502887000046],
            [70.157517828000039, 32.580121547000033],
            [70.157684390000043, 32.580668823000053],
            [70.158088898000074, 32.581025742000065],
            [70.158398228000067, 32.581406456000025],
            [70.158850326000049, 32.581906142000037],
            [70.15939760200007, 32.582239267000034],
            [70.159683137000059, 32.582477213000061],
            [70.159944878000033, 32.582881721000035],
            [70.160111440000037, 32.583262435000051],
            [70.160444564000045, 32.583785917000057],
            [70.160944251000046, 32.584404576000054],
            [70.161158403000059, 32.584999442000026],
            [70.161539117000075, 32.585546718000046],
            [70.161943625000049, 32.586308145000032],
            [70.162324339000065, 32.586688859000049],
            [70.162824025000077, 32.58695060000008],
            [70.163157150000075, 32.587164751000046],
            [70.163585453000053, 32.587450286000035],
            [70.163847194000084, 32.587688233000051],
            [70.164251702000058, 32.587997563000044],
            [70.164656210000032, 32.588402071000075],
            [70.164894156000059, 32.588711401000069],
            [70.165132103000076, 32.589092115000028],
            [70.165489022000031, 32.589472828000055],
            [70.165726968000058, 32.589829747000067],
            [70.165917325000066, 32.590210461000026],
            [70.166250449000074, 32.590519791000077],
            [70.166702547000057, 32.590852916000074],
            [70.167059466000069, 32.591043273000025],
            [70.167749510000078, 32.591590549000045],
            [70.168225402000076, 32.591995057000076],
            [70.168701294000073, 32.592375771000036],
            [70.168986829000062, 32.592732690000048],
            [70.169462722000048, 32.59308960900006],
            [70.170176560000073, 32.593827242000032],
            [70.170604863000051, 32.594255545000067],
            [70.170961782000063, 32.59456487500006],
            [70.171271112000056, 32.594945589000076],
            [70.171604236000064, 32.595397686000069],
            [70.171865977000039, 32.595754605000025],
            [70.172389459000044, 32.596325676000049],
            [70.172817761000033, 32.596658800000057],
            [70.173174681000035, 32.597063309000077],
            [70.17374575100007, 32.597491612000056],
            [70.17438820600006, 32.598467191000054],
            [70.174673741000049, 32.598824110000066],
            [70.175054455000065, 32.599181029000079],
            [70.175530347000063, 32.599609332000057],
            [70.175934855000037, 32.600037635000035],
            [70.176434542000038, 32.60070388400004],
            [70.17679146100005, 32.601203571000042],
            [70.177100791000044, 32.60153669500005],
            [70.177338737000071, 32.601893615000051],
            [70.177695656000083, 32.602226739000059],
            [70.178076370000042, 32.60301196100005],
            [70.178314316000069, 32.603392675000066],
            [70.178528468000081, 32.603725799000074],
            [70.17895677100006, 32.604154102000052],
            [70.179456458000061, 32.604534816000069],
            [70.179765788000054, 32.604867941000066],
            [70.180075117000058, 32.605248654000036],
            [70.18043203700006, 32.605676957000071],
            [70.180598599000064, 32.606010082000068],
            [70.180955518000076, 32.606533563000028],
            [70.181336232000035, 32.607057045000033],
            [70.18176453500007, 32.607556732000035],
            [70.181978686000036, 32.607770883000057],
            [70.18228801600003, 32.608008829000028],
            [70.182573551000075, 32.608341954000025],
            [70.182906676000073, 32.608675078000033],
            [70.183144622000043, 32.609031997000045],
            [70.183572925000078, 32.609579273000065],
            [70.18416779000006, 32.610102755000071],
            [70.184548504000077, 32.610531058000049],
            [70.184953012000051, 32.610911772000065],
            [70.185286137000048, 32.611197307000054],
            [70.185666851000065, 32.611482842000044],
            [70.185999975000072, 32.611696994000056],
            [70.186499662000074, 32.611934940000026],
            [70.187070733000041, 32.612410832000023],
            [70.187332473000083, 32.612743957000077],
            [70.187832160000028, 32.613124670000047],
            [70.188093901000059, 32.613386411000079],
            [70.188379436000048, 32.613529179000068],
            [70.188879123000049, 32.613648152000053],
            [70.189236042000061, 32.613695741000072],
            [70.189688140000044, 32.613790919000053],
            [70.19030680000003, 32.614266812000039],
            [70.190544746000057, 32.614480963000062],
            [70.19113961100004, 32.61505203400003],
            [70.191448941000033, 32.615432747000057],
            [70.191782065000041, 32.615884845000039],
            [70.192186574000061, 32.616503505000026],
            [70.19261487700004, 32.616955602000075],
            [70.192948001000047, 32.617621851000024],
            [70.193043180000075, 32.618026360000044],
            [70.193376304000083, 32.618645020000031],
            [70.193495277000068, 32.619097117000024],
            [70.193566661000034, 32.619549215000063],
            [70.193923580000046, 32.620001312000056],
            [70.194256705000043, 32.620429615000035],
            [70.19463741900006, 32.620762740000032],
            [70.194661213000074, 32.621119659000044],
            [70.19487536500003, 32.621524167000075],
            [70.195055282000055, 32.621923983000045],
            [70.195089516000053, 32.622000060000062],
            [70.195351257000084, 32.62233318400007],
            [70.195708176000039, 32.622690103000025],
            [70.195946122000066, 32.622832871000071],
            [70.196469603000082, 32.623189790000026],
            [70.196874112000046, 32.623475325000072],
            [70.197278620000077, 32.623713272000032],
            [70.19768312900004, 32.624070191000044],
            [70.198016253000048, 32.624355726000033],
            [70.198468351000031, 32.625045770000042],
            [70.198801475000039, 32.625402689000055],
            [70.199158394000051, 32.625712019000048],
            [70.199420135000082, 32.625949965000075],
            [70.19956639600008, 32.62597738900007],
            [70.199800849000042, 32.626021349000041],
            [70.200300536000043, 32.625973759000033],
            [70.200824017000059, 32.625902376000056],
            [70.201395088000083, 32.625712019000048],
            [70.201847185000076, 32.62552166200004],
            [70.202227899000036, 32.625426483000069],
            [70.202775175000056, 32.625331305000032],
            [70.203322451000076, 32.625117154000066],
            [70.203726959000051, 32.624617467000064],
            [70.204036289000044, 32.624403315000052],
            [70.204488387000083, 32.624284342000067],
            [70.204821511000034, 32.624141575000067],
            [70.205130841000084, 32.623879834000036],
            [70.205273609000074, 32.623665682000023],
            [70.205535350000048, 32.623070817000041],
            [70.205535350000048, 32.622618720000048],
            [70.205535350000048, 32.622261800000047],
            [70.205678117000048, 32.621857292000072],
            [70.205963653000083, 32.621595551000041],
            [70.206368161000057, 32.621262427000033],
            [70.206653696000046, 32.621048275000078],
            [70.206701286000055, 32.620667562000051],
            [70.206844053000054, 32.620191669000064],
            [70.206915437000077, 32.619525420000059],
            [70.207200972000066, 32.619073323000066],
            [70.207415124000079, 32.618740198000069],
            [70.207557892000068, 32.618359484000052],
            [70.207819632000053, 32.618026360000044],
            [70.208033784000065, 32.61776461900007],
            [70.208485881000058, 32.617740825000055],
            [70.209056952000083, 32.617360111000039],
            [70.209294898000053, 32.617074575000061],
            [70.209651817000065, 32.616693862000034],
            [70.210056326000029, 32.616479710000078],
            [70.21046083400006, 32.61614658600007],
            [70.210912931000053, 32.615980023000077],
            [70.211531591000039, 32.61574207700005],
            [70.212150251000082, 32.615623104000065],
            [70.212792706000073, 32.61550413100008],
            [70.213221009000051, 32.615456542000061],
            [70.213625517000082, 32.615408953000042],
            [70.213911052000071, 32.615075828000045],
            [70.214672480000047, 32.614933061000045],
            [70.21512457700004, 32.614766498000051],
            [70.215386318000071, 32.614433374000043],
            [70.215886005000073, 32.614076455000031],
            [70.216195335000066, 32.613671946000068],
            [70.216480870000055, 32.613434000000041],
            [70.216790200000048, 32.61319605400007],
            [70.217266092000045, 32.613029492000067],
            [70.217718190000028, 32.613029492000067],
            [70.218384439000033, 32.612981903000048],
            [70.218979304000072, 32.612839135000058],
            [70.219645553000078, 32.612791546000039],
            [70.220169035000083, 32.612934313000039],
            [70.220478365000076, 32.613005697000062],
            [70.220763900000065, 32.613029492000067],
            [70.221311176000029, 32.612981903000048],
            [70.221858452000049, 32.612910519000025],
            [70.222429523000073, 32.612767751000035],
            [70.222643674000039, 32.612601189000031],
            [70.222976799000037, 32.612172886000053],
            [70.223286128000041, 32.611720788000071],
            [70.223571664000076, 32.611363869000058],
            [70.224237913000081, 32.610673825000049],
            [70.22452344800007, 32.610221728000056],
            [70.224951751000049, 32.609769630000073],
            [70.225427643000046, 32.609507890000032],
            [70.225855946000081, 32.609484095000028],
            [70.226331839000068, 32.609722041000055],
            [70.226807731000065, 32.609983782000029],
            [70.227140855000073, 32.610174139000037],
            [70.227569158000051, 32.610459674000026],
            [70.228164023000033, 32.610840388000042],
            [70.228425764000065, 32.611221102000059],
            [70.228973040000028, 32.611530431000062],
            [70.229425138000067, 32.611696994000056],
            [70.22987723500006, 32.611887351000064],
            [70.230353128000047, 32.612268064000034],
            [70.230829020000044, 32.612672573000054],
            [70.231233528000075, 32.613005697000062],
            [70.231400090000079, 32.61333882200006],
            [70.231685626000058, 32.613743330000034],
            [70.232090134000032, 32.614147838000065],
            [70.232542232000071, 32.614433374000043],
            [70.23306571300003, 32.614576141000043],
            [70.233541605000084, 32.614718909000032],
            [70.233850935000078, 32.61505203400003],
            [70.233969908000063, 32.615527926000027],
            [70.234231649000037, 32.616027613000028],
            [70.234517184000083, 32.616241764000051],
            [70.235112049000065, 32.61652729900004],
            [70.235540352000044, 32.616646272000025],
            [70.235825888000079, 32.616884219000042],
            [70.236230396000053, 32.61721734300005],
            [70.236658699000031, 32.617407700000058],
            [70.237110797000071, 32.617574262000062],
            [70.237539100000049, 32.617717030000051],
            [70.238086376000069, 32.617836003000036],
            [70.238562268000067, 32.618050154000059],
            [70.239442668000038, 32.618288101000076],
            [70.239799588000039, 32.618526047000046],
            [70.240108917000043, 32.618787787000031],
            [70.240632399000049, 32.619168501000047],
            [70.241084496000042, 32.619549215000063],
            [70.241465210000058, 32.619858545000056],
            [70.242012486000078, 32.620191669000064],
            [70.242250432000048, 32.620524794000062],
            [70.242559762000042, 32.62085791800007],
            [70.242821503000073, 32.621167248000063],
            [70.243059449000043, 32.62150037300006],
            [70.243487752000078, 32.621785908000049],
            [70.243963644000075, 32.621928676000039],
            [70.244534715000043, 32.621952470000053],
            [70.245129580000082, 32.621952470000053],
            [70.245605472000079, 32.621952470000053],
            [70.246009981000043, 32.621904881000034],
            [70.246557257000063, 32.621904881000034],
            [70.246985560000041, 32.622047649000024],
            [70.247461452000039, 32.622238006000032],
            [70.24786596000007, 32.622309390000055],
            [70.248793950000049, 32.622737693000033],
            [70.249150869000061, 32.622951844000056],
            [70.249555378000082, 32.622999433000075],
            [70.249936091000052, 32.622999433000075],
            [70.250507162000076, 32.622904255000037],
            [70.25081649200007, 32.622809076000067],
            [70.251078233000044, 32.622642514000063],
            [70.251244795000048, 32.622475952000059],
            [70.251387562000048, 32.622475952000059],
            [70.251649303000079, 32.622475952000059],
            [70.251780238000038, 32.622541419000072],
            [70.251999916000045, 32.622537494000028],
            [70.252257842000063, 32.622431289000076],
            [70.252828912000041, 32.622312316000034],
            [70.253423778000069, 32.622264727000072],
            [70.254161411000041, 32.622264727000072],
            [70.254780070000038, 32.622336110000049],
            [70.255541498000071, 32.622526467000057],
            [70.256564666000031, 32.622812003000035],
            [70.256992969000066, 32.622907181000073],
            [70.257421272000045, 32.623097538000025],
            [70.257968548000065, 32.623335484000052],
            [70.258658592000074, 32.623787582000034],
            [70.259277252000061, 32.624334858000054],
            [70.260038679000047, 32.625048696000079],
            [70.261632918000032, 32.626761908000049],
            [70.262632292000035, 32.627856460000032],
            [70.263869611000075, 32.628665477000027],
            [70.264940369000044, 32.629474493000032],
            [70.266034921000028, 32.63035489400005],
            [70.266748759000052, 32.630711813000062],
            [70.267105678000064, 32.630997348000051],
            [70.267438803000061, 32.631211500000063],
            [70.267771927000069, 32.631259089000025],
            [70.268128846000081, 32.631259089000025],
            [70.26865232800003, 32.631282884000029],
            [70.269223398000065, 32.631163911000044],
            [70.269723085000066, 32.631163911000044],
            [70.270151388000045, 32.631163911000044],
            [70.27067487000005, 32.631163911000044],
            [70.271079378000081, 32.631425651000029],
            [70.271483886000055, 32.631544624000071],
            [70.271912189000034, 32.631711187000064],
            [70.272316698000054, 32.631972927000049],
            [70.272768795000047, 32.632353641000066],
            [70.273482634000061, 32.632996096000056],
            [70.273958526000058, 32.633376809000026],
            [70.274434418000055, 32.633852701000023],
            [70.274838926000029, 32.634495156000071],
            [70.27500548900008, 32.634923459000049],
            [70.275314819000073, 32.635446940000065],
            [70.275481381000077, 32.635827654000025],
            [70.275862095000036, 32.636232162000056],
            [70.276337987000034, 32.636565287000053],
            [70.276694906000046, 32.636993590000031],
            [70.277099414000077, 32.637326714000039],
            [70.277527717000055, 32.637755017000075],
            [70.277956020000033, 32.638064347000068],
            [70.278527091000058, 32.638373677000061],
            [70.278955394000036, 32.638587829000073],
            [70.279693027000064, 32.638873364000062],
            [70.28035927600007, 32.639039926000066],
            [70.281120703000056, 32.639230283000074],
            [70.281620390000057, 32.639325462000045],
            [70.282120077000059, 32.639325462000045],
            [70.282691148000083, 32.639325462000045],
            [70.28326442000008, 32.639391608000039],
            [70.283293717000049, 32.639346597000042],
            [70.283333602000084, 32.63934925600006],
            [70.283730829000035, 32.639418339000031],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "50",
      properties: { name: "Kurram Agency", count: 742 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.035592000000065, 34.040180000000078],
            [70.083319000000074, 34.01366500000006],
            [70.105305000000044, 34.008551000000068],
            [70.143525000000068, 34.010557000000063],
            [70.18507100000005, 33.992186000000061],
            [70.212170000000071, 33.982470000000035],
            [70.238810026000067, 33.973430589000031],
            [70.239340884000057, 33.973250460000031],
            [70.240200553000079, 33.97295876000004],
            [70.240804000000082, 33.972754000000066],
            [70.261768000000075, 33.97633200000007],
            [70.290914000000043, 33.974798000000078],
            [70.308298000000036, 33.969172000000071],
            [70.333353000000045, 33.96201300000007],
            [70.364032000000066, 33.962012000000072],
            [70.391643000000045, 33.96303400000005],
            [70.422323000000063, 33.962010000000078],
            [70.43154700000008, 33.95822529700007],
            [70.44226400000008, 33.953828000000044],
            [70.444398152000076, 33.953139505000024],
            [70.44437690500007, 33.952013694000073],
            [70.443772187000036, 33.949156655000024],
            [70.44371897700006, 33.949041832000034],
            [70.443690423000078, 33.948727743000063],
            [70.443547655000032, 33.94835654700006],
            [70.44337633400005, 33.947642709000036],
            [70.44323356700005, 33.946871763000047],
            [70.443119352000053, 33.946300693000069],
            [70.443033692000029, 33.945244212000034],
            [70.442948031000071, 33.944102071000032],
            [70.442948031000071, 33.943531000000064],
            [70.44323356700005, 33.942959930000029],
            [70.443461995000064, 33.94233175200003],
            [70.443633316000046, 33.941675021000037],
            [70.444033065000042, 33.940989736000063],
            [70.444118726000056, 33.940418665000038],
            [70.444118726000056, 33.939704827000071],
            [70.444118726000056, 33.939048096000079],
            [70.444290047000038, 33.937963062000051],
            [70.444261494000045, 33.937135009000031],
            [70.444090172000074, 33.936078528000053],
            [70.444090172000074, 33.93493638700005],
            [70.444090172000074, 33.933937014000037],
            [70.444232940000063, 33.933308836000037],
            [70.444404261000045, 33.932851979000077],
            [70.444661243000041, 33.932452230000024],
            [70.444918225000038, 33.932023927000046],
            [70.445175207000034, 33.93162417800005],
            [70.445517849000055, 33.931167321000032],
            [70.44591759900004, 33.930424929000026],
            [70.446288794000054, 33.929596877000051],
            [70.44654577600005, 33.928740271000038],
            [70.446945526000036, 33.92754102300006],
            [70.447316722000039, 33.926770077000072],
            [70.447316722000039, 33.926170453000054],
            [70.447316722000039, 33.925570829000037],
            [70.447031186000061, 33.925085419000027],
            [70.44654577600005, 33.924885544000063],
            [70.446031813000047, 33.924485795000066],
            [70.445317974000034, 33.924086045000024],
            [70.44468979700008, 33.923686296000028],
            [70.444004512000049, 33.92311522500006],
            [70.441891551000083, 33.92165899500003],
            [70.441234819000044, 33.921287799000027],
            [70.440949284000055, 33.920859496000048],
            [70.440920730000073, 33.920402640000077],
            [70.440977838000038, 33.920031444000074],
            [70.441320480000059, 33.919631694000032],
            [70.441862997000044, 33.919089177000046],
            [70.442376961000036, 33.918575214000043],
            [70.442948031000071, 33.917832822000037],
            [70.443547655000032, 33.917318858000044],
            [70.443975958000067, 33.916890555000066],
            [70.444318601000077, 33.916176717000042],
            [70.444375708000052, 33.915462879000074],
            [70.444404261000045, 33.914292184000033],
            [70.444489922000059, 33.91315004300003],
            [70.444518475000052, 33.912007901000038],
            [70.444518475000052, 33.911179849000064],
            [70.444632690000049, 33.910380350000025],
            [70.444746904000056, 33.909552298000051],
            [70.445060993000084, 33.908867013000076],
            [70.44546074200008, 33.908010407000063],
            [70.44557495600003, 33.90749644300007],
            [70.44591759900004, 33.906811159000029],
            [70.445946152000033, 33.906240088000061],
            [70.44606036600004, 33.905611910000061],
            [70.446174580000047, 33.904355555000052],
            [70.446574330000033, 33.903413288000024],
            [70.446774204000064, 33.902642343000025],
            [70.447088293000036, 33.90209982600004],
            [70.447459489000039, 33.901671523000061],
            [70.447802132000049, 33.901357434000033],
            [70.448344649000035, 33.901071899000044],
            [70.44891571900007, 33.900957685000037],
            [70.449486790000037, 33.90087202400008],
            [70.450571824000065, 33.90084347100003],
            [70.451913840000032, 33.901014792000069],
            [70.453113088000066, 33.901129006000076],
            [70.45505472900004, 33.901186113000051],
            [70.456225423000035, 33.901100452000037],
            [70.457310458000052, 33.90098623800003],
            [70.458681027000068, 33.900700703000041],
            [70.459708954000064, 33.900529382000059],
            [70.461108077000063, 33.900329507000038],
            [70.462821289000033, 33.900129632000073],
            [70.464134752000064, 33.900072525000041],
            [70.465533875000062, 33.899929758000042],
            [70.466304820000062, 33.899786990000052],
            [70.466704569000058, 33.899587115000031],
            [70.467104319000043, 33.899215919000028],
            [70.46721853300005, 33.898730509000075],
            [70.46721853300005, 33.898187992000032],
            [70.467132872000036, 33.897474154000065],
            [70.467047212000068, 33.896960190000073],
            [70.466961551000054, 33.896274906000031],
            [70.466904444000079, 33.895589621000056],
            [70.466818784000054, 33.894989997000039],
            [70.466790230000072, 33.894361819000039],
            [70.467161426000075, 33.894133391000025],
            [70.46770394300006, 33.894133391000025],
            [70.46833212100006, 33.894333265000057],
            [70.468874638000045, 33.894418926000071],
            [70.469417155000031, 33.894504587000029],
            [70.470302314000037, 33.89484722900005],
            [70.471358795000071, 33.895189871000071],
            [70.472586597000031, 33.895703835000063],
            [70.47338609600007, 33.895789495000031],
            [70.474014274000069, 33.895846603000052],
            [70.474728112000037, 33.895846603000052],
            [70.475613271000043, 33.895846603000052],
            [70.476441324000064, 33.895618174000049],
            [70.477412144000084, 33.895304085000078],
            [70.478382964000048, 33.894989997000039],
            [70.479353784000068, 33.894447480000053],
            [70.481152656000063, 33.893619427000033],
            [70.48332272500005, 33.892591500000037],
            [70.485093044000052, 33.891763448000063],
            [70.486520720000044, 33.890935395000042],
            [70.488034058000039, 33.890392878000057],
            [70.488947771000028, 33.889736147000065],
            [70.490032805000055, 33.889507719000051],
            [70.491089285000044, 33.888993755000058],
            [70.492060105000064, 33.888708220000069],
            [70.493173693000074, 33.888137149000045],
            [70.494230174000052, 33.887623185000052],
            [70.495315208000079, 33.887109222000049],
            [70.496000493000054, 33.886738026000046],
            [70.496799992000035, 33.886138402000029],
            [70.497542384000042, 33.88571009900005],
            [70.498370436000073, 33.884996261000026],
            [70.499055721000047, 33.884425190000059],
            [70.499598238000033, 33.883739905000027],
            [70.499883773000079, 33.883283049000056],
            [70.499883773000079, 33.882483550000075],
            [70.499826666000047, 33.881969586000025],
            [70.499683898000058, 33.881512730000054],
            [70.499655345000065, 33.88079889100004],
            [70.499969434000036, 33.879799518000027],
            [70.500254969000082, 33.879171340000028],
            [70.500312076000057, 33.878543162000028],
            [70.500426290000064, 33.876944165000054],
            [70.500226415000043, 33.87625888000008],
            [70.500169308000068, 33.875487935000024],
            [70.500397737000071, 33.874859757000024],
            [70.500397737000071, 33.873888937000061],
            [70.500312076000057, 33.872975224000072],
            [70.500312076000057, 33.872061511000027],
            [70.500140755000075, 33.871062137000024],
            [70.500312076000057, 33.870062764000068],
            [70.500511951000078, 33.868892069000026],
            [70.500511951000078, 33.868092570000044],
            [70.500511951000078, 33.86740728500007],
            [70.500254969000082, 33.866379358000074],
            [70.500026541000068, 33.865893948000064],
            [70.499883773000079, 33.865151556000058],
            [70.499883773000079, 33.864466271000026],
            [70.499855220000029, 33.863638219000052],
            [70.499512577000075, 33.863010041000052],
            [70.499284149000061, 33.862296203000028],
            [70.499284149000061, 33.861525258000029],
            [70.499255595000079, 33.860040474000073],
            [70.499119395000037, 33.858904262000067],
            [70.498948073000065, 33.857961995000039],
            [70.498576877000062, 33.857105389000026],
            [70.497834486000045, 33.856334444000026],
            [70.497034987000063, 33.85547783800007],
            [70.496463916000039, 33.854963874000077],
            [70.496007060000068, 33.854449911000074],
            [70.495721524000032, 33.853964501000064],
            [70.495664417000057, 33.853479091000054],
            [70.49569297100004, 33.853136448000043],
            [70.496121274000075, 33.852479717000051],
            [70.496692344000053, 33.85202286100008],
            [70.497406183000066, 33.85168021800007],
            [70.498091467000052, 33.851309022000066],
            [70.498576877000062, 33.850823612000056],
            [70.498948073000065, 33.850395309000078],
            [70.499290716000075, 33.849881346000075],
            [70.499719019000054, 33.849481596000032],
            [70.500347196000064, 33.849395936000064],
            [70.500775499000042, 33.84945304300004],
            [70.501346570000067, 33.849652917000071],
            [70.502345944000069, 33.850138327000025],
            [70.504487458000028, 33.850937826000063],
            [70.506400545000076, 33.851566004000063],
            [70.508056650000071, 33.85227984200003],
            [70.509684201000084, 33.852793806000079],
            [70.511083324000083, 33.853193555000075],
            [70.512882197000067, 33.853279216000033],
            [70.514652516000069, 33.85325066200005],
            [70.515994532000036, 33.853193555000075],
            [70.51856434900003, 33.853136448000043],
            [70.520534543000053, 33.853107895000051],
            [70.522276309000063, 33.853107895000051],
            [70.523818199000061, 33.853107895000051],
            [70.524846126000057, 33.853079341000068],
            [70.525674179000077, 33.852908020000029],
            [70.526159589000031, 33.85262248500004],
            [70.526473678000059, 33.852108521000048],
            [70.526559338000084, 33.851394683000024],
            [70.526388017000045, 33.85090927300007],
            [70.525902607000035, 33.850138327000025],
            [70.525445751000063, 33.849281722000057],
            [70.52473191200005, 33.848139580000066],
            [70.524303609000071, 33.847397188000059],
            [70.524075181000057, 33.846769011000049],
            [70.523846753000043, 33.845940958000028],
            [70.523846753000043, 33.845055799000079],
            [70.523903860000075, 33.844456175000062],
            [70.523989520000043, 33.843485355000041],
            [70.524160842000072, 33.842857177000042],
            [70.524474931000043, 33.842257553000024],
            [70.524960341000053, 33.841600822000032],
            [70.525588518000063, 33.841058304000057],
            [70.526245249000056, 33.840544341000054],
            [70.527216070000065, 33.840058931000044],
            [70.52804412200004, 33.839716288000034],
            [70.528786514000046, 33.839430753000045],
            [70.529557459000046, 33.839259432000063],
            [70.53061394000008, 33.839145218000056],
            [70.531727528000033, 33.838916790000042],
            [70.532784008000078, 33.838716915000077],
            [70.533469293000053, 33.838431380000031],
            [70.534582881000063, 33.837803202000032],
            [70.535924897000029, 33.83700370300005],
            [70.537352573000078, 33.836004329000048],
            [70.538723143000084, 33.835062063000066],
            [70.539922391000061, 33.834262564000028],
            [70.541035979000071, 33.83369149300006],
            [70.541635603000032, 33.833434512000053],
            [70.542178120000074, 33.833405958000071],
            [70.542692084000066, 33.833463065000046],
            [70.543320261000076, 33.833777154000074],
            [70.544091207000065, 33.834119796000039],
            [70.545376116000057, 33.834719420000056],
            [70.546803792000048, 33.835433259000069],
            [70.548802539000064, 33.836375525000051],
            [70.550572858000066, 33.837203578000072],
            [70.552571606000072, 33.83811729100006],
            [70.554084943000078, 33.838916790000042],
            [70.555826708000041, 33.83985905600008],
            [70.557882562000032, 33.841258179000079],
            [70.559966970000062, 33.842628749000028],
            [70.560709362000068, 33.842971391000049],
            [70.561280433000036, 33.842971391000049],
            [70.562022824000053, 33.842971391000049],
            [70.565592016000039, 33.842828623000059],
            [70.568104727000048, 33.842714409000052],
            [70.569646617000046, 33.842714409000052],
            [70.570217688000071, 33.842457427000056],
            [70.570560330000035, 33.842143339000074],
            [70.570645991000049, 33.841629375000025],
            [70.570674545000031, 33.84094409000005],
            [70.570760205000056, 33.840116038000076],
            [70.570788759000038, 33.83860270100007],
            [70.570788759000038, 33.836546846000033],
            [70.570560330000035, 33.833891368000025],
            [70.570531777000042, 33.832435138000051],
            [70.570474670000067, 33.831178783000041],
            [70.570474670000067, 33.829008714000054],
            [70.570531777000042, 33.827038520000031],
            [70.570617437000067, 33.826267575000031],
            [70.571045740000045, 33.82361209700008],
            [70.57133127600008, 33.821584796000025],
            [70.571445490000031, 33.819586049000065],
            [70.571559704000038, 33.817672962000074],
            [70.571531150000055, 33.815474340000037],
            [70.571616811000069, 33.81398955700007],
            [70.571588258000077, 33.812933076000036],
            [70.571731025000076, 33.812276345000043],
            [70.572187882000037, 33.811676720000037],
            [70.572730399000079, 33.811219864000066],
            [70.57381543300005, 33.810448919000066],
            [70.575128895000034, 33.809620866000046],
            [70.576585126000055, 33.808849921000046],
            [70.578612426000063, 33.807821994000051],
            [70.579868782000062, 33.807250923000026],
            [70.581325012000036, 33.806708406000041],
            [70.582238725000082, 33.806422871000052],
            [70.582695581000053, 33.806137335000074],
            [70.582952563000049, 33.80576613900007],
            [70.583038224000063, 33.80513796200006],
            [70.582952563000049, 33.804395570000054],
            [70.582952563000049, 33.803167768000037],
            [70.582781242000067, 33.801568770000074],
            [70.582467153000039, 33.799541470000065],
            [70.582210171000042, 33.797828258000038],
            [70.581753315000071, 33.796600456000078],
            [70.581125137000072, 33.795429761000037],
            [70.580611174000069, 33.794344727000066],
            [70.57984022800008, 33.793316800000071],
            [70.578640980000046, 33.792031891000079],
            [70.577727267000057, 33.791175285000065],
            [70.577013429000033, 33.790461447000041],
            [70.576356697000051, 33.789547734000053],
            [70.576099716000044, 33.788862449000078],
            [70.576156823000076, 33.788262825000061],
            [70.576470911000058, 33.787606093000079],
            [70.577041982000082, 33.786578166000027],
            [70.577698713000075, 33.785436025000024],
            [70.578555319000031, 33.784151116000032],
            [70.579526139000052, 33.783094635000054],
            [70.580611174000069, 33.782180922000066],
            [70.581382119000068, 33.781609852000031],
            [70.582210171000042, 33.780781799000067],
            [70.58426602600008, 33.779525444000058],
            [70.585293953000075, 33.778754499000058],
            [70.586321880000071, 33.778126321000059],
            [70.587207039000077, 33.777726571000073],
            [70.588177859000041, 33.777269715000045],
            [70.588977358000079, 33.777241161000063],
            [70.590490695000028, 33.777241161000063],
            [70.592175354000062, 33.777241161000063],
            [70.593545923000079, 33.777126947000056],
            [70.59605863400003, 33.777098394000063],
            [70.599913361000063, 33.776841412000067],
            [70.602283304000082, 33.776612984000053],
            [70.603939409000077, 33.776413109000032],
            [70.605566960000033, 33.776041913000029],
            [70.607594261000031, 33.775499396000043],
            [70.608308099000055, 33.775470842000061],
            [70.608764956000073, 33.775499396000043],
            [70.608767458000045, 33.775511073000075],
            [70.608861449000074, 33.775417082000047],
            [70.609061323000049, 33.775017332000061],
            [70.609032770000056, 33.774560476000033],
            [70.608975663000081, 33.77418928000003],
            [70.60863302000007, 33.773703870000077],
            [70.607776415000046, 33.772932924000031],
            [70.606605720000061, 33.772076318000074],
            [70.604921061000084, 33.770962731000054],
            [70.603864581000039, 33.770191785000065],
            [70.602693886000054, 33.769477947000041],
            [70.601608852000084, 33.768707002000042],
            [70.600837906000038, 33.767964610000035],
            [70.600038408000046, 33.766965236000033],
            [70.599610105000068, 33.766051523000044],
            [70.599410230000046, 33.765394792000052],
            [70.599124694000068, 33.764538186000038],
            [70.598953373000029, 33.763396045000036],
            [70.598782052000047, 33.761996922000037],
            [70.59866783800004, 33.761254530000031],
            [70.598296642000037, 33.760397924000074],
            [70.597954000000072, 33.759741193000025],
            [70.597582804000069, 33.759141569000064],
            [70.597268715000041, 33.758599051000033],
            [70.597068840000077, 33.758284963000051],
            [70.596897519000038, 33.758027981000055],
            [70.59695462600007, 33.757685338000044],
            [70.597068840000077, 33.757142821000059],
            [70.597611357000062, 33.756571751000024],
            [70.597839786000065, 33.756000680000056],
            [70.598011107000048, 33.755715145000067],
            [70.597982553000065, 33.755201181000075],
            [70.597468590000062, 33.753802058000076],
            [70.596640537000042, 33.752488596000035],
            [70.595812485000067, 33.751003812000079],
            [70.595269968000082, 33.75006154600004],
            [70.594584683000051, 33.749147832000062],
            [70.593042792000062, 33.747948584000028],
            [70.591472348000082, 33.746834996000075],
            [70.590330207000079, 33.746149712000033],
            [70.589416494000034, 33.745550088000073],
            [70.58844567400007, 33.744636375000027],
            [70.587103658000046, 33.74343712600006],
            [70.586304159000065, 33.74246630600004],
            [70.585847302000047, 33.74183812800004],
            [70.585390446000076, 33.741324165000037],
            [70.585190571000055, 33.740924415000052],
            [70.585190571000055, 33.740524666000056],
            [70.58524767800003, 33.740010702000063],
            [70.585904409000079, 33.738411705000033],
            [70.586618248000036, 33.737155349000034],
            [70.587731835000056, 33.735984654000049],
            [70.589102405000062, 33.734242889000029],
            [70.589844797000069, 33.733386283000073],
            [70.590672849000043, 33.731872946000067],
            [70.591957758000035, 33.72967432400003],
            [70.592842918000031, 33.72767557700007],
            [70.593756631000076, 33.726048025000068],
            [70.594184934000054, 33.725334187000044],
            [70.594584683000051, 33.72499154500008],
            [70.595070093000061, 33.724934438000048],
            [70.595641164000028, 33.724877331000073],
            [70.596811858000081, 33.724934438000048],
            [70.598325196000076, 33.724905884000066],
            [70.599010480000061, 33.724791670000059],
            [70.599467337000078, 33.724734563000027],
            [70.600095515000078, 33.724306260000048],
            [70.600495264000074, 33.723620975000074],
            [70.601066335000041, 33.722992798000064],
            [70.601694512000051, 33.722564495000029],
            [70.60260822500004, 33.72227895900005],
            [70.603607599000043, 33.721936317000029],
            [70.604235777000042, 33.721393800000044],
            [70.604806847000077, 33.720394426000041],
            [70.605149490000031, 33.719537820000028],
            [70.605577793000066, 33.719023857000025],
            [70.606634273000054, 33.718509893000032],
            [70.608318932000032, 33.717881715000033],
            [70.611745355000039, 33.716939449000051],
            [70.614515048000044, 33.71613995000007],
            [70.615914171000043, 33.715740201000074],
            [70.61699920500007, 33.715340451000031],
            [70.617570276000038, 33.715140576000067],
            [70.618027132000066, 33.714712273000032],
            [70.618084239000041, 33.714312524000036],
            [70.618084239000041, 33.713712900000075],
            [70.618084239000041, 33.712913401000037],
            [70.618084239000041, 33.711685599000077],
            [70.617998579000073, 33.710486351000043],
            [70.617998579000073, 33.709943834000057],
            [70.617998579000073, 33.709515531000079],
            [70.618084239000041, 33.709058674000062],
            [70.618369775000076, 33.708516157000076],
            [70.618969399000036, 33.708059301000048],
            [70.619283488000065, 33.707602444000031],
            [70.61948336200004, 33.707202695000035],
            [70.619597577000036, 33.706146214000057],
            [70.619768898000075, 33.704204574000073],
            [70.61982600500005, 33.70257702300006],
            [70.619911665000075, 33.700578275000055],
            [70.619940219000057, 33.698922171000049],
            [70.619768898000075, 33.698065565000036],
            [70.619768898000075, 33.697551601000043],
            [70.619597577000036, 33.696923423000044],
            [70.619055060000051, 33.696009710000055],
            [70.618655310000065, 33.695210211000074],
            [70.618369775000076, 33.694382159000043],
            [70.618141346000073, 33.693753981000043],
            [70.618084239000041, 33.693068697000058],
            [70.618084239000041, 33.69212643000003],
            [70.618084239000041, 33.691098503000035],
            [70.61822700700003, 33.690156236000064],
            [70.618483989000083, 33.689128309000068],
            [70.618569649000051, 33.68832881000003],
            [70.618541096000058, 33.68710100800007],
            [70.61871241700004, 33.686044528000025],
            [70.618826631000047, 33.685159368000029],
            [70.61919782700005, 33.684445530000062],
            [70.619854558000043, 33.68376024500003],
            [70.620482736000042, 33.683074961000045],
            [70.621310788000073, 33.682760872000074],
            [70.62216739400003, 33.682532443000071],
            [70.62313821500004, 33.682389676000071],
            [70.624166142000036, 33.682075587000043],
            [70.624708659000078, 33.681732945000078],
            [70.625336836000031, 33.680990553000072],
            [70.625907907000055, 33.680162500000051],
            [70.62659319200003, 33.679248787000063],
            [70.627221370000029, 33.678306521000025],
            [70.627820994000047, 33.677564129000075],
            [70.628506278000032, 33.67665041600003],
            [70.629105903000038, 33.676050792000069],
            [70.629505652000034, 33.67542261400007],
            [70.629676973000073, 33.675165632000073],
            [70.630105276000052, 33.674680222000063],
            [70.630733454000051, 33.674451794000049],
            [70.631047543000079, 33.674251919000028],
            [70.631304525000076, 33.673566635000043],
            [70.631704274000072, 33.673109778000025],
            [70.632303898000032, 33.673081224000043],
            [70.633160504000045, 33.673081224000043],
            [70.633817235000038, 33.672767136000061],
            [70.634559627000044, 33.67256726100004],
            [70.635159251000061, 33.67256726100004],
            [70.635787429000061, 33.672453047000033],
            [70.636301393000053, 33.672053297000048],
            [70.636929570000063, 33.671996190000073],
            [70.637357873000042, 33.67196763700008],
            [70.637843283000052, 33.671539334000045],
            [70.63812881900003, 33.671168138000041],
            [70.638414354000076, 33.67085404900007],
            [70.638528568000083, 33.670825495000031],
            [70.63878555000008, 33.670768388000056],
            [70.639413727000033, 33.67085404900007],
            [70.639813477000075, 33.670882603000052],
            [70.640241780000053, 33.670996817000059],
            [70.640727190000064, 33.670939710000027],
            [70.641155493000042, 33.67085404900007],
            [70.641441028000031, 33.670482853000067],
            [70.641983545000073, 33.669940336000025],
            [70.642154867000045, 33.669626247000053],
            [70.64269738400003, 33.669369265000057],
            [70.64318279400004, 33.669369265000057],
            [70.643782418000058, 33.669369265000057],
            [70.644382042000075, 33.669140837000043],
            [70.644410596000057, 33.668741088000047],
            [70.644610470000032, 33.668112910000048],
            [70.644781791000071, 33.667570393000062],
            [70.644867452000028, 33.667227751000041],
            [70.645181541000056, 33.66705642900007],
            [70.645609844000035, 33.666999322000038],
            [70.64618091400007, 33.667113536000045],
            [70.646552110000073, 33.667227751000041],
            [70.647037520000083, 33.667256304000034],
            [70.647437270000069, 33.667256304000034],
            [70.647979787000054, 33.667170643000077],
            [70.648293876000082, 33.666942215000063],
            [70.649064821000081, 33.666513912000028],
            [70.649578785000074, 33.666485359000035],
            [70.650092748000077, 33.666485359000035],
            [70.65057815800003, 33.666256930000031],
            [70.651149229000055, 33.665971395000042],
            [70.651948728000036, 33.665514539000071],
            [70.652605459000029, 33.665514539000071],
            [70.65329074400006, 33.665514539000071],
            [70.653804707000063, 33.665657306000071],
            [70.654461439000045, 33.66580007400006],
            [70.655089616000055, 33.66580007400006],
            [70.65563213300004, 33.665742967000028],
            [70.656003329000043, 33.665543092000064],
            [70.656403079000029, 33.665057682000054],
            [70.656831382000064, 33.664686486000051],
            [70.657059810000078, 33.664657933000058],
            [70.657630881000046, 33.664629379000075],
            [70.657916416000035, 33.664800700000058],
            [70.658373272000063, 33.664800700000058],
            [70.658773022000048, 33.66482925400004],
            [70.659001450000062, 33.665000575000079],
            [70.659087111000076, 33.665371771000025],
            [70.659286985000051, 33.66580007400006],
            [70.659486860000072, 33.666256930000031],
            [70.659772395000061, 33.66657101900006],
            [70.660286359000054, 33.666713787000049],
            [70.66102875100006, 33.666685233000067],
            [70.661542714000063, 33.666742340000042],
            [70.662170892000063, 33.666713787000049],
            [70.662856177000037, 33.666685233000067],
            [70.663455801000055, 33.66642825200006],
            [70.664112532000047, 33.665914288000067],
            [70.664883478000036, 33.665286110000068],
            [70.665397441000039, 33.664886361000072],
            [70.665939958000081, 33.664543719000051],
            [70.666482475000066, 33.664229630000079],
            [70.667110653000066, 33.663915541000051],
            [70.667538956000044, 33.663258810000059],
            [70.668709651000029, 33.662287990000038],
            [70.66953770300006, 33.661631258000057],
            [70.670965380000041, 33.660688992000075],
            [70.671821986000054, 33.66026068900004],
            [70.672621485000036, 33.660089368000058],
            [70.673563751000074, 33.659860939000055],
            [70.674763000000041, 33.65966106500008],
            [70.675876587000062, 33.659489743000051],
            [70.676676086000043, 33.65917565500007],
            [70.67727571000006, 33.658861566000041],
            [70.678303637000056, 33.658176281000067],
            [70.679445779000048, 33.657662317000074],
            [70.680388045000029, 33.657348229000036],
            [70.681301758000075, 33.657148354000071],
            [70.682472453000059, 33.656919926000057],
            [70.683500380000055, 33.656862819000025],
            [70.684528307000051, 33.656862819000025],
            [70.685185039000032, 33.656862819000025],
            [70.686212966000028, 33.656577283000047],
            [70.686869697000077, 33.656177534000051],
            [70.688754230000029, 33.655178160000048],
            [70.689639389000035, 33.654635643000063],
            [70.690381781000042, 33.654093126000078],
            [70.69129549400003, 33.653236520000064],
            [70.691952226000069, 33.652408468000033],
            [70.692951599000082, 33.651637522000044],
            [70.693465563000075, 33.650895130000038],
            [70.694579150000038, 33.650295506000077],
            [70.695321542000045, 33.649810096000067],
            [70.696663558000068, 33.649353240000039],
            [70.697748592000039, 33.648953490000054],
            [70.698548091000077, 33.64872506200004],
            [70.699518911000041, 33.64809688400004],
            [70.700461178000069, 33.647640028000069],
            [70.70140344400005, 33.647211725000034],
            [70.702774014000056, 33.646983297000077],
            [70.703830495000034, 33.646926190000045],
            [70.704829868000047, 33.646926190000045],
            [70.705800688000068, 33.646954743000038],
            [70.706685848000063, 33.646954743000038],
            [70.70757100700007, 33.647126064000076],
            [70.708456167000065, 33.647383046000073],
            [70.709112898000058, 33.647782796000058],
            [70.70976962900005, 33.648239652000029],
            [70.710426360000042, 33.64872506200004],
            [70.711054538000042, 33.649353240000039],
            [70.711682716000041, 33.649781543000074],
            [70.712682089000054, 33.650295506000077],
            [70.713338821000036, 33.650723809000056],
            [70.713995552000029, 33.651123559000041],
            [70.715080586000056, 33.651180666000073],
            [70.715965746000052, 33.651152112000034],
            [70.716708137000069, 33.650895130000038],
            [70.71736486900005, 33.650638149000031],
            [70.718050153000036, 33.650409720000027],
            [70.719249402000059, 33.649467454000046],
            [70.720048901000041, 33.648924937000061],
            [70.720991167000079, 33.648296759000061],
            [70.721961987000043, 33.647839903000033],
            [70.722761486000081, 33.64761147400003],
            [70.723760860000084, 33.647240278000027],
            [70.724389037000037, 33.646840529000031],
            [70.724988662000044, 33.646126691000063],
            [70.725731053000061, 33.645527067000046],
            [70.726473445000067, 33.644756121000057],
            [70.727044516000035, 33.644499139000061],
            [70.727929675000041, 33.644384925000054],
            [70.729100370000083, 33.644270711000047],
            [70.730499493000082, 33.644070836000026],
            [70.731927170000063, 33.644013729000051],
            [70.732926543000076, 33.644013729000051],
            [70.734011577000047, 33.644013729000051],
            [70.73598177100007, 33.643842408000069],
            [70.738066179000043, 33.643870962000051],
            [70.739179767000053, 33.643985176000058],
            [70.740093480000041, 33.644242158000054],
            [70.741521156000033, 33.64464190700005],
            [70.743063047000078, 33.644955996000078],
            [70.744405063000045, 33.645469959000025],
            [70.746603685000082, 33.646041030000049],
            [70.747403184000063, 33.646126691000063],
            [70.748316897000052, 33.646155244000056],
            [70.748973628000044, 33.646155244000056],
            [70.749801680000076, 33.646155244000056],
            [70.750401305000082, 33.645983923000074],
            [70.750601179000057, 33.645584174000078],
            [70.750601179000057, 33.645098764000068],
            [70.750601179000057, 33.644327818000079],
            [70.750458412000057, 33.642500392000045],
            [70.75048696500005, 33.641729447000046],
            [70.750515519000032, 33.641101269000046],
            [70.750686840000071, 33.63910252200003],
            [70.750772500000039, 33.638160255000059],
            [70.751000929000043, 33.636475597000072],
            [70.751000929000043, 33.635875973000054],
            [70.751000929000043, 33.63516213500003],
            [70.750943822000067, 33.634533957000031],
            [70.751000929000043, 33.633991440000045],
            [70.751172250000081, 33.633363262000046],
            [70.751543446000028, 33.632592317000046],
            [70.752400052000041, 33.631107533000034],
            [70.752771248000045, 33.630393695000066],
            [70.752999676000059, 33.629108786000074],
            [70.75331376500003, 33.627452681000079],
            [70.753485086000069, 33.626110665000056],
            [70.753627854000058, 33.62508273800006],
            [70.753599300000076, 33.624140471000032],
            [70.753599300000076, 33.623597954000047],
            [70.753256658000055, 33.622912670000062],
            [70.752999676000059, 33.622427260000052],
            [70.752171624000084, 33.621684868000045],
            [70.751771874000042, 33.621256565000067],
            [70.751400678000039, 33.620714048000025],
            [70.750886715000036, 33.620228638000071],
            [70.750515519000032, 33.619657567000047],
            [70.750144323000029, 33.618800961000034],
            [70.749716020000051, 33.61842976500003],
            [70.749173503000065, 33.617915802000027],
            [70.747631612000077, 33.617259070000046],
            [70.746717899000032, 33.616602339000053],
            [70.745004687000062, 33.615859947000047],
            [70.743805439000084, 33.615431644000068],
            [70.742920279000032, 33.61497478800004],
            [70.742092227000057, 33.614689252000062],
            [70.741464049000058, 33.61448937800003],
            [70.74100719300003, 33.614289503000066],
            [70.740721657000051, 33.613918307000063],
            [70.740693104000059, 33.613375790000077],
            [70.740950086000055, 33.612462077000032],
            [70.741264174000037, 33.611291382000047],
            [70.741264174000037, 33.610663205000037],
            [70.741464049000058, 33.609378296000045],
            [70.741578263000065, 33.608293261000028],
            [70.741578263000065, 33.607179674000065],
            [70.741549710000072, 33.606208854000045],
            [70.741349835000051, 33.604781177000064],
            [70.741121407000037, 33.603867464000075],
            [70.740664550000076, 33.602896644000054],
            [70.739922159000059, 33.601668842000038],
            [70.739208320000046, 33.600498147000053],
            [70.738123286000075, 33.599441667000065],
            [70.737266680000062, 33.598670721000076],
            [70.736181646000034, 33.597757008000031],
            [70.735267933000046, 33.596786188000067],
            [70.734611202000053, 33.595901029000061],
            [70.734068685000068, 33.595215744000029],
            [70.733668935000082, 33.594701780000037],
            [70.733554721000075, 33.594187817000034],
            [70.733526167000036, 33.593616746000066],
            [70.733583275000058, 33.593102783000063],
            [70.733868810000047, 33.592617373000053],
            [70.734211452000068, 33.592131963000043],
            [70.735553468000035, 33.590818500000069],
            [70.736638502000062, 33.59001900100003],
            [70.737495108000076, 33.589790573000073],
            [70.738294607000057, 33.589676359000066],
            [70.73977939100007, 33.589619252000034],
            [70.740921532000073, 33.58941937700007],
            [70.741949459000068, 33.589219502000049],
            [70.743148708000035, 33.588905414000067],
            [70.744776259000048, 33.588191575000053],
            [70.746489471000075, 33.587163648000057],
            [70.748088469000038, 33.586307042000044],
            [70.749544699000069, 33.585279115000048],
            [70.750364583000078, 33.584657134000054],
            [70.750372751000043, 33.584650937000049],
            [70.750379558000077, 33.584646243000066],
            [70.750554378000061, 33.584525677000045],
            [70.750555182000028, 33.584525123000049],
            [70.752759040000058, 33.582892571000059],
            [70.748718262000068, 33.582275391000053],
            [70.748716439000077, 33.582247886000061],
            [70.747314453000058, 33.561096191000047],
            [70.746229076000077, 33.55944071700003],
            [70.741271973000039, 33.551879883000026],
            [70.738668039000061, 33.544558236000057],
            [70.736083984000061, 33.537292480000076],
            [70.732116699000073, 33.527282715000069],
            [70.734130859000061, 33.511474609000061],
            [70.742675781000059, 33.496887207000043],
            [70.742675781000059, 33.489929199000073],
            [70.742675781000059, 33.482910156000059],
            [70.74260600000008, 33.482923529000061],
            [70.715438000000063, 33.488130000000069],
            [70.702185000000043, 33.490781000000027],
            [70.696472168000071, 33.486328125000057],
            [70.692099581000036, 33.482771754000055],
            [70.68727219200008, 33.47893812600006],
            [70.685479440000051, 33.477514428000063],
            [70.684692383000083, 33.476928711000028],
            [70.681030415000066, 33.473981274000039],
            [70.679687500000057, 33.472900391000053],
            [70.679670390000069, 33.472901222000075],
            [70.625319000000047, 33.475540000000024],
            [70.597488000000055, 33.431143000000077],
            [70.590552388000049, 33.426426733000028],
            [70.580922000000044, 33.41987800000004],
            [70.557729000000052, 33.422529000000054],
            [70.542489000000046, 33.422529000000054],
            [70.526585000000068, 33.41524000000004],
            [70.50736900000004, 33.413252000000057],
            [70.504221813000072, 33.414110363000077],
            [70.500080000000082, 33.41524000000004],
            [70.498092000000042, 33.403312000000028],
            [70.500080000000082, 33.385421000000065],
            [70.508031000000074, 33.378132000000051],
            [70.51177337300004, 33.376575461000073],
            [70.523925781000059, 33.371520996000072],
            [70.535862000000066, 33.36487900000003],
            [70.54315100000008, 33.352951000000076],
            [70.54283270500008, 33.348334879000049],
            [70.540960761000065, 33.348389596000061],
            [70.540756502000079, 33.348389596000061],
            [70.540613734000033, 33.348318212000038],
            [70.539935588000048, 33.34788990900006],
            [70.538936214000046, 33.346890535000057],
            [70.537579921000031, 33.346069621000026],
            [70.535616866000055, 33.345391475000042],
            [70.533903654000028, 33.34503455600003],
            [70.531405220000067, 33.344749021000041],
            [70.530013235000069, 33.344749021000041],
            [70.529013862000056, 33.344534869000029],
            [70.528228640000066, 33.344392101000039],
            [70.527300650000029, 33.343856723000044],
            [70.526444044000073, 33.343392728000026],
            [70.525159135000081, 33.342857349000042],
            [70.523017620000076, 33.342036435000068],
            [70.520340727000075, 33.341393981000067],
            [70.517199838000067, 33.34046599100003],
            [70.514879864000079, 33.33968076900004],
            [70.510810985000035, 33.338324476000025],
            [70.507455945000061, 33.33700387500005],
            [70.505814117000057, 33.336254345000043],
            [70.50413659700007, 33.335790350000025],
            [70.502637537000055, 33.335147896000024],
            [70.501852315000065, 33.334898052000028],
            [70.50113847700004, 33.334826668000062],
            [70.500424638000084, 33.334862360000045],
            [70.499675108000076, 33.334898052000028],
            [70.498854194000046, 33.334898052000028],
            [70.498318815000061, 33.334862360000045],
            [70.49781912900005, 33.334826668000062],
            [70.497105290000036, 33.334505441000033],
            [70.49607022500004, 33.333791603000066],
            [70.495463462000032, 33.33354175900007],
            [70.494357013000069, 33.33336330000003],
            [70.493143488000044, 33.333327608000047],
            [70.491394584000034, 33.333327608000047],
            [70.489360145000035, 33.333434684000053],
            [70.488432155000055, 33.333434684000053],
            [70.487789701000054, 33.333613143000036],
            [70.487361398000075, 33.333862987000032],
            [70.487040170000057, 33.334398365000027],
            [70.486718943000028, 33.334969436000051],
            [70.486433408000039, 33.335754658000042],
            [70.485683878000032, 33.337075259000073],
            [70.485112807000064, 33.337396486000046],
            [70.48457742800008, 33.337824789000024],
            [70.483827898000072, 33.338003249000053],
            [70.482364530000041, 33.338074633000076],
            [70.481365156000038, 33.338074633000076],
            [70.480472858000041, 33.338074633000076],
            [70.479580560000045, 33.338038941000036],
            [70.478902414000061, 33.337860481000064],
            [70.477903040000058, 33.337574946000075],
            [70.476653823000049, 33.337289410000039],
            [70.47558306600007, 33.336968183000067],
            [70.474369541000044, 33.336504188000049],
            [70.472692021000057, 33.335576198000069],
            [70.471799723000061, 33.33511220400004],
            [70.471085885000036, 33.334648209000079],
            [70.470264971000063, 33.334326981000061],
            [70.469194213000037, 33.334184214000061],
            [70.468230531000074, 33.334112830000038],
            [70.467552385000033, 33.333970062000049],
            [70.466981314000066, 33.333898679000072],
            [70.466374552000048, 33.333684527000059],
            [70.465446562000068, 33.333113456000035],
            [70.464946875000066, 33.333006381000075],
            [70.464161653000076, 33.333006381000075],
            [70.46312658800008, 33.333113456000035],
            [70.462127214000077, 33.333220532000041],
            [70.460128467000061, 33.334576825000056],
            [70.458914942000035, 33.33543343100007],
            [70.458593715000063, 33.336147269000037],
            [70.45852233100004, 33.336718340000061],
            [70.45852233100004, 33.337110951000057],
            [70.458807866000029, 33.337539254000035],
            [70.459271861000047, 33.338003249000053],
            [70.459878624000055, 33.339002622000066],
            [70.460021391000055, 33.339466617000028],
            [70.459593088000076, 33.33968076900004],
            [70.45870079000008, 33.339787844000057],
            [70.458022644000039, 33.339966304000029],
            [70.457308806000071, 33.340037688000052],
            [70.456630659000041, 33.340216147000035],
            [70.456238048000046, 33.34046599100003],
            [70.45598820500004, 33.340894294000066],
            [70.455631286000084, 33.34157244000005],
            [70.455631286000084, 33.342286278000074],
            [70.455631286000084, 33.342964425000048],
            [70.455809745000067, 33.343499804000032],
            [70.456095281000046, 33.344035182000027],
            [70.456666351000081, 33.34517732300003],
            [70.456737735000047, 33.346640692000051],
            [70.455952513000057, 33.348282520000055],
            [70.45584543700005, 33.348889283000062],
            [70.455524210000078, 33.34963881300007],
            [70.455024523000077, 33.349960040000042],
            [70.453703922000045, 33.350495419000026],
            [70.452990084000078, 33.350745262000032],
            [70.451990710000075, 33.351316333000057],
            [70.450919953000039, 33.351708944000052],
            [70.448707054000067, 33.352422782000076],
            [70.447743373000037, 33.352422782000076],
            [70.44688676700008, 33.352422782000076],
            [70.445744625000032, 33.35213724700003],
            [70.444852328000081, 33.352065863000064],
            [70.443246191000071, 33.35213724700003],
            [70.441854207000063, 33.352208631000053],
            [70.440569298000071, 33.352458474000059],
            [70.438320707000059, 33.352779701000031],
            [70.437071490000051, 33.353172312000027],
            [70.436107809000077, 33.353386464000039],
            [70.434608748000073, 33.353850459000057],
            [70.433502299000054, 33.353850459000057],
            [70.432859844000063, 33.353814767000074],
            [70.432360157000062, 33.353636307000045],
            [70.432110314000056, 33.353065237000067],
            [70.43200323800005, 33.352422782000076],
            [70.43186047100005, 33.351459100000056],
            [70.431574935000071, 33.350352651000037],
            [70.431325092000066, 33.349781580000069],
            [70.430968173000053, 33.349210510000034],
            [70.430432794000069, 33.348603747000027],
            [70.429826032000051, 33.348282520000055],
            [70.428933734000054, 33.348104060000026],
            [70.428112820000081, 33.347782833000053],
            [70.426863603000072, 33.347675757000047],
            [70.425864229000069, 33.34760437400007],
            [70.424936239000033, 33.347425914000041],
            [70.423544255000081, 33.347140379000052],
            [70.422330730000056, 33.346854843000074],
            [70.421474124000042, 33.346355157000062],
            [70.420474750000039, 33.345534243000031],
            [70.419511068000077, 33.34424933400004],
            [70.41844031100004, 33.342964425000048],
            [70.417619397000067, 33.342464738000047],
            [70.41669140700003, 33.34235766200004],
            [70.415834801000074, 33.34253612200007],
            [70.415049579000083, 33.342857349000042],
            [70.413978822000047, 33.343000117000031],
            [70.413157908000073, 33.342857349000042],
            [70.411587463000046, 33.342464738000047],
            [70.409160413000052, 33.342429046000063],
            [70.407233050000059, 33.342643198000076],
            [70.405162919000077, 33.342678889000069],
            [70.403878010000028, 33.342964425000048],
            [70.402307566000047, 33.343749647000038],
            [70.401058349000039, 33.344641945000035],
            [70.399487904000068, 33.346033929000043],
            [70.398381455000049, 33.346890535000057],
            [70.397631925000042, 33.347568682000031],
            [70.396703935000062, 33.347854217000076],
            [70.395561794000059, 33.34788990900006],
            [70.394776572000069, 33.34788990900006],
            [70.393741506000083, 33.348139752000066],
            [70.392385213000068, 33.348782207000056],
            [70.391028921000043, 33.349710197000036],
            [70.389137249000044, 33.350745262000032],
            [70.387638189000029, 33.35138771700008],
            [70.386496047000037, 33.351994479000041],
            [70.384425916000055, 33.352708317000065],
            [70.383069624000029, 33.352922469000077],
            [70.382391477000056, 33.35295816100006],
            [70.381677639000031, 33.352922469000077],
            [70.381392104000042, 33.352601242000048],
            [70.380892417000041, 33.351994479000041],
            [70.38039273000004, 33.35138771700008],
            [70.379536124000083, 33.350638186000026],
            [70.378715210000053, 33.350031424000065],
            [70.377751528000033, 33.349567429000047],
            [70.376466620000031, 33.349103434000028],
            [70.375574322000034, 33.348746515000073],
            [70.374396488000059, 33.348496672000067],
            [70.372861736000061, 33.348318212000038],
            [70.371755287000042, 33.348139752000066],
            [70.370291918000078, 33.348032677000049],
            [70.369578080000053, 33.34788990900006],
            [70.368578706000051, 33.34760437400007],
            [70.367722100000037, 33.347247455000058],
            [70.366722727000081, 33.34678346000004],
            [70.365687661000038, 33.346248081000056],
            [70.364509828000052, 33.345641318000048],
            [70.364481075000072, 33.345629522000024],
            [70.363117844000044, 33.34507024800007],
            [70.362225546000047, 33.344534869000029],
            [70.361154788000078, 33.344142258000034],
            [70.360119723000082, 33.343856723000044],
            [70.359370193000075, 33.343749647000038],
            [70.358549278000055, 33.343749647000038],
            [70.357621289000065, 33.343928106000078],
            [70.356621915000062, 33.344035182000027],
            [70.355836693000072, 33.34410656600005],
            [70.355229930000064, 33.344320718000063],
            [70.354444708000074, 33.344427793000079],
            [70.353695178000066, 33.344784712000035],
            [70.353017032000082, 33.344891788000041],
            [70.352267502000075, 33.344927480000024],
            [70.351625047000084, 33.344927480000024],
            [70.351089668000043, 33.344927480000024],
            [70.350447214000042, 33.344677637000075],
            [70.350054603000046, 33.344392101000039],
            [70.349590608000028, 33.343963798000061],
            [70.349055229000044, 33.343535495000026],
            [70.348519851000049, 33.342928733000065],
            [70.347734628000069, 33.342143511000074],
            [70.347056482000028, 33.341715208000039],
            [70.346271260000037, 33.341536748000067],
            [70.34552173000003, 33.341215521000038],
            [70.344450972000061, 33.341072753000049],
            [70.343308831000058, 33.340965678000032],
            [70.342309457000056, 33.340965678000032],
            [70.341203008000036, 33.340965678000032],
            [70.340275018000057, 33.340965678000032],
            [70.339418412000043, 33.340965678000032],
            [70.33859749800007, 33.340965678000032],
            [70.337919352000029, 33.340965678000032],
            [70.337383973000044, 33.34078721800006],
            [70.337134130000038, 33.340573067000037],
            [70.336955670000066, 33.340144764000058],
            [70.336777211000083, 33.33971646100008],
            [70.336670135000077, 33.339252466000062],
            [70.336491675000048, 33.338538627000048],
            [70.336384600000031, 33.337753405000058],
            [70.336134756000035, 33.336718340000061],
            [70.336134756000035, 33.336040193000031],
            [70.336134756000035, 33.335504815000036],
            [70.336170448000075, 33.334291290000067],
            [70.336170448000075, 33.33354175900007],
            [70.336134756000035, 33.33272084500004],
            [70.336099064000052, 33.332221159000028],
            [70.335991989000036, 33.331899931000066],
            [70.335599377000051, 33.33165008800006],
            [70.335313842000062, 33.33165008800006],
            [70.334778463000077, 33.331721472000027],
            [70.334350160000042, 33.331864239000026],
            [70.333636322000075, 33.331971315000033],
            [70.332958176000034, 33.332042699000056],
            [70.332387105000066, 33.332007007000072],
            [70.331923110000048, 33.331864239000026],
            [70.331530499000053, 33.331721472000027],
            [70.331244964000064, 33.331400245000054],
            [70.330780969000045, 33.331043325000053],
            [70.330281282000044, 33.330900558000053],
            [70.329794564000053, 33.330929188000027],
            [70.329674520000083, 33.330936250000036],
            [70.329103449000058, 33.331043325000053],
            [70.32867514600008, 33.331150401000059],
            [70.328202350000083, 33.331522661000065],
            [70.327782882000065, 33.331819739000025],
            [70.327140428000064, 33.332140966000054],
            [70.326248130000067, 33.332319426000026],
            [70.324856145000069, 33.332604961000072],
            [70.324159892000068, 33.332888620000062],
            [70.324000315000035, 33.332979229000046],
            [70.326038385000061, 33.334790773000066],
            [70.328723000000082, 33.337177000000054],
            [70.323610000000031, 33.354051000000027],
            [70.315468000000067, 33.37128400000006],
            [70.316567000000077, 33.39385800000008],
            [70.304773000000068, 33.405652000000032],
            [70.301626000000056, 33.417967000000033],
            [70.296002000000044, 33.429728000000068],
            [70.281685000000039, 33.431262000000061],
            [70.261232000000064, 33.430240000000026],
            [70.252540000000067, 33.445069000000046],
            [70.249547000000064, 33.456203000000073],
            [70.246405000000038, 33.463988000000029],
            [70.240781000000084, 33.469612000000041],
            [70.232600000000048, 33.475749000000064],
            [70.222885000000076, 33.478305000000034],
            [70.213681000000065, 33.481374000000073],
            [70.212659000000031, 33.489044000000035],
            [70.205501000000083, 33.496714000000054],
            [70.193230000000028, 33.506940000000043],
            [70.180958000000032, 33.519213000000036],
            [70.175334000000078, 33.527905000000032],
            [70.179937000000052, 33.544268000000045],
            [70.183557000000064, 33.574043000000074],
            [70.186178000000041, 33.58845800000006],
            [70.192075000000045, 33.614012000000059],
            [70.194041000000084, 33.627116000000058],
            [70.192075000000045, 33.638255000000072],
            [70.180964000000074, 33.642953000000034],
            [70.160625000000039, 33.646773000000053],
            [70.155433214000084, 33.652223880000065],
            [70.147520000000043, 33.660532000000046],
            [70.150141000000076, 33.682810000000075],
            [70.152332000000058, 33.693064000000049],
            [70.144662000000039, 33.704824000000031],
            [70.13801600000005, 33.714028000000042],
            [70.129323000000056, 33.721187000000043],
            [70.114495000000034, 33.718631000000073],
            [70.094553000000076, 33.715564000000029],
            [70.08177100000006, 33.720166000000063],
            [70.06234100000006, 33.72425700000008],
            [70.042911000000061, 33.730394000000047],
            [70.026037000000031, 33.731928000000039],
            [70.007630000000063, 33.734485000000063],
            [69.997915000000035, 33.738065000000063],
            [69.989734000000055, 33.741644000000065],
            [69.984110000000044, 33.748292000000049],
            [69.973232000000053, 33.756195000000048],
            [69.967335000000048, 33.764712000000031],
            [69.961438000000044, 33.782403000000045],
            [69.956851000000029, 33.806647000000055],
            [69.950877000000048, 33.822435000000041],
            [69.938094000000035, 33.831639000000052],
            [69.920814000000064, 33.843339000000071],
            [69.912951000000078, 33.867582000000027],
            [69.910996000000068, 33.883283000000063],
            [69.901282000000037, 33.892999000000032],
            [69.891056000000049, 33.905782000000045],
            [69.876148000000057, 33.918457000000046],
            [69.862499000000071, 33.933104000000071],
            [69.864465000000052, 33.952761000000066],
            [69.872516000000076, 33.958410000000072],
            [69.879535000000033, 33.969141000000036],
            [69.889014000000032, 33.97378800000007],
            [69.900263000000052, 33.976345000000038],
            [69.901157000000069, 33.991419000000064],
            [69.895916000000057, 34.013696000000039],
            [69.902822000000072, 34.030545000000075],
            [69.925321000000054, 34.037703000000079],
            [69.958046000000081, 34.037190000000066],
            [69.988725000000045, 34.036678000000052],
            [70.002531000000033, 34.04894900000005],
            [70.035592000000065, 34.040180000000078],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "51",
      properties: { name: "Mohmand Agency", count: 1252 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.207219525000085, 34.704983277000053],
            [71.207819149000045, 34.704926170000078],
            [71.208418773000062, 34.705040384000029],
            [71.209046951000062, 34.705640008000046],
            [71.210703056000057, 34.706896363000055],
            [71.212559035000083, 34.708466808000026],
            [71.213329980000083, 34.708923664000054],
            [71.213729730000068, 34.709723163000035],
            [71.214215140000078, 34.710693983000056],
            [71.214557782000043, 34.711779017000026],
            [71.214643443000057, 34.712835498000061],
            [71.214814764000039, 34.713720657000067],
            [71.21501463900006, 34.714377389000049],
            [71.215328728000031, 34.714891352000052],
            [71.215956905000041, 34.715376762000062],
            [71.216956279000044, 34.716347582000026],
            [71.218041313000072, 34.717346956000029],
            [71.218755151000039, 34.718374883000024],
            [71.220211382000059, 34.720030988000076],
            [71.221039434000033, 34.721144576000029],
            [71.221896040000047, 34.722172503000024],
            [71.222695539000028, 34.722972002000063],
            [71.223385172000064, 34.723555537000038],
            [71.223437931000035, 34.723600179000073],
            [71.22435164400008, 34.724285464000047],
            [71.224779947000059, 34.724456785000029],
            [71.225265357000069, 34.724456785000029],
            [71.225836427000047, 34.724456785000029],
            [71.226607373000036, 34.724399678000054],
            [71.227349764000053, 34.724199804000079],
            [71.228035049000084, 34.723885715000051],
            [71.228977316000055, 34.723371751000059],
            [71.229919582000036, 34.722772127000042],
            [71.230976063000071, 34.722058289000074],
            [71.232546507000052, 34.720659166000075],
            [71.234031291000065, 34.719402810000076],
            [71.235516074000031, 34.718003687000078],
            [71.23677243000003, 34.71691865300005],
            [71.238399981000043, 34.715433869000037],
            [71.240056086000038, 34.714006193000046],
            [71.240998352000076, 34.713206694000064],
            [71.242026280000061, 34.712264427000036],
            [71.243054207000057, 34.711236500000041],
            [71.244653205000077, 34.709837377000042],
            [71.245367043000044, 34.709351967000032],
            [71.245795346000079, 34.708838004000029],
            [71.24590956000003, 34.708324040000036],
            [71.245881006000047, 34.707810076000044],
            [71.245652578000033, 34.707353220000073],
            [71.244910186000084, 34.706468060000077],
            [71.244767419000084, 34.705668562000028],
            [71.244795972000077, 34.705126045000043],
            [71.244995847000041, 34.704554974000075],
            [71.245395596000037, 34.703841136000051],
            [71.246252202000051, 34.702927423000062],
            [71.247280129000046, 34.701699621000046],
            [71.248707806000084, 34.700271944000065],
            [71.249364537000076, 34.699643766000065],
            [71.250135483000065, 34.699186910000037],
            [71.251134856000078, 34.698415965000038],
            [71.252334104000056, 34.697188163000078],
            [71.254161531000079, 34.695246523000037],
            [71.255474993000064, 34.694075828000052],
            [71.256531474000042, 34.693047901000057],
            [71.257645061000062, 34.692019974000061],
            [71.259244059000082, 34.690763618000062],
            [71.260614629000031, 34.689907012000049],
            [71.26312733900005, 34.688165247000029],
            [71.264183820000028, 34.687337194000065],
            [71.26472633700007, 34.686594803000048],
            [71.265411622000045, 34.685880964000035],
            [71.266068353000037, 34.685167126000067],
            [71.266867852000075, 34.684196306000047],
            [71.267210494000039, 34.683853664000026],
            [71.267524583000068, 34.68345391400004],
            [71.267810119000046, 34.682939950000048],
            [71.267952886000046, 34.682511648000059],
            [71.268124207000028, 34.681912023000052],
            [71.268466850000038, 34.680969757000071],
            [71.268752385000084, 34.679770508000047],
            [71.268980813000042, 34.678885349000041],
            [71.269352009000045, 34.678028743000027],
            [71.269466223000052, 34.677743208000038],
            [71.269865973000037, 34.67745767200006],
            [71.27037993600004, 34.677172137000071],
            [71.270893900000033, 34.677029369000024],
            [71.271407864000082, 34.67682949500005],
            [71.271921827000028, 34.67668672700006],
            [71.272550005000028, 34.676515406000078],
            [71.27309252200007, 34.676344085000039],
            [71.273406611000041, 34.676029996000068],
            [71.273435164000034, 34.675744461000079],
            [71.273435164000034, 34.675259051000069],
            [71.273378057000059, 34.674402445000055],
            [71.273263843000052, 34.673717160000024],
            [71.273006861000056, 34.672660679000046],
            [71.272778433000042, 34.671547091000036],
            [71.272749880000049, 34.670918914000026],
            [71.272664219000035, 34.670119415000045],
            [71.272435791000078, 34.668977274000042],
            [71.272207362000074, 34.668063561000054],
            [71.271864720000053, 34.667007080000076],
            [71.271721952000064, 34.666321795000044],
            [71.271379310000043, 34.665465189000031],
            [71.27100811400004, 34.664608583000074],
            [71.270494151000037, 34.664094620000071],
            [71.270294276000072, 34.663609210000061],
            [71.270265722000033, 34.663152353000044],
            [71.270265722000033, 34.662467068000069],
            [71.270208615000058, 34.661895998000034],
            [71.270065848000058, 34.661353481000049],
            [71.26963754500008, 34.660839517000056],
            [71.26915213500007, 34.660239893000039],
            [71.269037920000073, 34.659697376000054],
            [71.268980813000042, 34.659126305000029],
            [71.268980813000042, 34.658640895000076],
            [71.269009367000081, 34.658184039000048],
            [71.269095027000049, 34.657698629000038],
            [71.269237795000038, 34.657270326000059],
            [71.269237795000038, 34.656813469000042],
            [71.269180688000063, 34.656213845000025],
            [71.269095027000049, 34.655785542000046],
            [71.269037920000073, 34.655300132000036],
            [71.269323456000052, 34.655014597000047],
            [71.269723205000048, 34.654786168000044],
            [71.270180062000065, 34.654729061000069],
            [71.270751132000044, 34.654729061000069],
            [71.271779059000039, 34.654814722000026],
            [71.27309252200007, 34.655014597000047],
            [71.274291770000048, 34.655214471000079],
            [71.275519572000064, 34.65538579300005],
            [71.276604606000035, 34.655671328000039],
            [71.277775301000077, 34.655785542000046],
            [71.278489139000044, 34.655814096000029],
            [71.27937429900004, 34.655814096000029],
            [71.280202351000071, 34.655699881000032],
            [71.281116064000059, 34.655500007000057],
            [71.281630028000052, 34.655271578000054],
            [71.282229652000069, 34.65490038300004],
            [71.282943490000036, 34.654472080000062],
            [71.28365732900005, 34.65392956200003],
            [71.284371167000074, 34.653358492000052],
            [71.285113559000081, 34.652815975000067],
            [71.286055825000062, 34.652187797000067],
            [71.286684003000062, 34.651845155000046],
            [71.287226520000047, 34.651759494000032],
            [71.287654823000082, 34.651645280000025],
            [71.288054572000078, 34.651645280000025],
            [71.288625643000046, 34.651702387000057],
            [71.289082500000063, 34.651873708000039],
            [71.289596463000066, 34.652159243000028],
            [71.290253194000059, 34.652673207000078],
            [71.290852819000065, 34.65332993800007],
            [71.292052067000043, 34.654357865000065],
            [71.293022887000063, 34.654957490000072],
            [71.293993707000084, 34.655328685000029],
            [71.295392830000083, 34.65552856000005],
            [71.29644931100006, 34.655671328000039],
            [71.296849060000056, 34.655814096000029],
            [71.297363024000049, 34.656213845000025],
            [71.298333844000069, 34.656756362000067],
            [71.299161896000044, 34.657070451000038],
            [71.299732967000068, 34.657355986000027],
            [71.300218377000078, 34.657355986000027],
            [71.300846555000078, 34.657355986000027],
            [71.301474732000031, 34.657298879000052],
            [71.302217124000038, 34.657156112000052],
            [71.302988069000037, 34.657070451000038],
            [71.303787568000075, 34.656842023000024],
            [71.304444300000057, 34.656585041000028],
            [71.305586441000059, 34.656385166000064],
            [71.305592327000056, 34.656383647000041],
            [71.306471600000066, 34.65615673800005],
            [71.307385313000054, 34.65601397000006],
            [71.308070598000029, 34.655899756000053],
            [71.308870097000067, 34.655871203000061],
            [71.309669596000049, 34.655871203000061],
            [71.310897398000066, 34.655985417000068],
            [71.312068092000061, 34.655899756000053],
            [71.313695644000063, 34.655814096000029],
            [71.315123320000055, 34.655728435000071],
            [71.316493890000061, 34.655614221000064],
            [71.317721692000077, 34.655442900000025],
            [71.319206475000044, 34.655300132000036],
            [71.320377170000029, 34.655300132000036],
            [71.320748366000032, 34.655357239000068],
            [71.321119562000035, 34.655557114000032],
            [71.321433651000063, 34.655842649000078],
            [71.32169063300006, 34.656213845000025],
            [71.321947614000067, 34.656499380000071],
            [71.322388234000073, 34.65696753900005],
            [71.322404471000084, 34.656984790000024],
            [71.322889881000037, 34.657384540000066],
            [71.323346737000065, 34.657612968000024],
            [71.323775040000044, 34.657612968000024],
            [71.324260450000054, 34.657555861000048],
            [71.32500284200006, 34.657384540000066],
            [71.325745234000067, 34.657241772000077],
            [71.32660184000008, 34.657213219000027],
            [71.327601214000083, 34.65712755800007],
            [71.328629141000079, 34.656899130000056],
            [71.329371533000028, 34.656813469000042],
            [71.330285246000074, 34.656727809000074],
            [71.330627888000038, 34.656499380000071],
            [71.33114185200003, 34.656328059000032],
            [71.331598708000058, 34.656270952000057],
            [71.332369653000057, 34.656270952000057],
            [71.333226259000071, 34.656128184000067],
            [71.33399720500006, 34.655699881000032],
            [71.33462538200007, 34.655328685000029],
            [71.335567649000041, 34.654729061000069],
            [71.33633859400004, 34.654272205000041],
            [71.337138093000078, 34.65381534800008],
            [71.337851931000046, 34.653558367000073],
            [71.338537216000077, 34.65332993800007],
            [71.339193947000069, 34.653158617000031],
            [71.339936339000076, 34.653015849000042],
            [71.340907159000039, 34.652987296000049],
            [71.341906533000042, 34.652958742000067],
            [71.343077228000084, 34.652873082000042],
            [71.344048048000047, 34.65270176100006],
            [71.345047421000061, 34.652473332000056],
            [71.345561385000053, 34.652273458000025],
            [71.345961134000049, 34.651959369000053],
            [71.346075349000046, 34.65158817300005],
            [71.346218116000045, 34.65124553000004],
            [71.346218116000045, 34.650731567000037],
            [71.346389437000084, 34.650303264000058],
            [71.346732080000038, 34.649932068000055],
            [71.347188936000066, 34.649675086000059],
            [71.347874221000041, 34.649360997000031],
            [71.348816488000068, 34.64890414100006],
            [71.349844415000064, 34.648447284000042],
            [71.350929449000034, 34.647933321000039],
            [71.35184316200008, 34.647476464000079],
            [71.35228391000004, 34.647218095000028],
            [71.352671214000054, 34.646991054000068],
            [71.353470713000036, 34.646791180000037],
            [71.354213105000042, 34.64667696500004],
            [71.354727069000035, 34.64667696500004],
            [71.355440907000059, 34.646705519000079],
            [71.356211852000058, 34.646762626000054],
            [71.357325440000068, 34.646991054000068],
            [71.358239153000056, 34.647248036000065],
            [71.358952991000081, 34.647533571000054],
            [71.359438401000034, 34.647933321000039],
            [71.360009472000058, 34.648475838000024],
            [71.360666203000051, 34.649161123000056],
            [71.36143714800005, 34.649760747000073],
            [71.362093880000032, 34.650131943000076],
            [71.362779164000074, 34.650303264000058],
            [71.363578663000055, 34.650674460000062],
            [71.364806465000072, 34.651045656000065],
            [71.366148481000039, 34.651616726000043],
            [71.367861693000066, 34.652244904000042],
            [71.369118048000075, 34.652873082000042],
            [71.370117422000078, 34.65332993800007],
            [71.371430885000052, 34.653890301000047],
            [71.372323182000059, 34.654247220000059],
            [71.373322556000062, 34.65460414000006],
            [71.373643783000034, 34.654961059000073],
            [71.374179162000075, 34.655639205000057],
            [71.374643157000037, 34.656031816000052],
            [71.375107152000055, 34.65631735200003],
            [71.375749606000056, 34.656602887000076],
            [71.376820364000082, 34.656781346000059],
            [71.378283732000057, 34.656924114000049],
            [71.379854176000038, 34.657031190000055],
            [71.381032010000069, 34.657245341000078],
            [71.381567388000065, 34.657459493000033],
            [71.382959373000062, 34.657923488000051],
            [71.38370890300007, 34.658423174000063],
            [71.384422741000037, 34.658780094000065],
            [71.384922428000039, 34.659244089000026],
            [71.385386423000057, 34.659244089000026],
            [71.386100261000081, 34.65917270500006],
            [71.387206711000033, 34.659029937000071],
            [71.38891992300006, 34.658780094000065],
            [71.389812220000067, 34.658780094000065],
            [71.390668826000081, 34.658851477000042],
            [71.391382665000037, 34.659101321000037],
            [71.392167887000028, 34.659458240000049],
            [71.393238644000064, 34.660136386000033],
            [71.394487861000073, 34.660921608000024],
            [71.397129063000079, 34.662456361000068],
            [71.398913659000073, 34.663420042000041],
            [71.400198568000064, 34.664240957000061],
            [71.401483476000067, 34.665061871000034],
            [71.40234008200008, 34.665668633000053],
            [71.40312530500006, 34.666168320000054],
            [71.404017602000067, 34.66641816300006],
            [71.404767133000064, 34.666489547000026],
            [71.405766506000077, 34.666525239000066],
            [71.407301259000064, 34.666703699000038],
            [71.408443400000067, 34.666953542000044],
            [71.409478465000063, 34.667381845000079],
            [71.410013844000048, 34.667703072000052],
            [71.410406455000043, 34.668059991000064],
            [71.410799066000038, 34.66816706700007],
            [71.411370137000063, 34.66816706700007],
            [71.412226743000076, 34.668381219000025],
            [71.413226116000033, 34.668916597000077],
            [71.414261182000075, 34.669737511000051],
            [71.414867944000036, 34.670808269000077],
            [71.415189172000055, 34.671593491000067],
            [71.415617475000033, 34.672985476000065],
            [71.415831626000056, 34.673592238000026],
            [71.416259929000034, 34.673770698000055],
            [71.416759616000036, 34.673770698000055],
            [71.417758989000049, 34.673735006000072],
            [71.418972515000064, 34.673699314000032],
            [71.421042646000046, 34.673592238000026],
            [71.422648782000067, 34.673592238000026],
            [71.424147842000082, 34.673413779000043],
            [71.424790297000072, 34.673128243000065],
            [71.425611211000046, 34.67259286500007],
            [71.426467817000059, 34.672200253000028],
            [71.427717034000068, 34.672093178000068],
            [71.429537321000055, 34.671986102000062],
            [71.431357609000031, 34.671986102000062],
            [71.433070821000058, 34.672307329000034],
            [71.434320038000067, 34.672485789000064],
            [71.436461553000072, 34.672807016000036],
            [71.437282467000045, 34.672985476000065],
            [71.437746462000064, 34.67337808700006],
            [71.437889229000064, 34.673806390000038],
            [71.438067689000036, 34.674234693000074],
            [71.438246148000076, 34.674877147000075],
            [71.438388916000065, 34.675519601000076],
            [71.438745835000077, 34.676090672000043],
            [71.439174138000055, 34.676304824000056],
            [71.43985228400004, 34.676518975000079],
            [71.440566123000053, 34.676483283000039],
            [71.441743956000039, 34.676483283000039],
            [71.443492860000049, 34.676483283000039],
            [71.446098369000083, 34.676304824000056],
            [71.448382652000078, 34.675983596000037],
            [71.450310015000071, 34.675698061000048],
            [71.452415838000036, 34.675126990000024],
            [71.453807823000034, 34.674377460000073],
            [71.454914272000053, 34.673627930000066],
            [71.455949338000039, 34.672450097000024],
            [71.456841636000036, 34.670951036000076],
            [71.457591166000043, 34.669380592000039],
            [71.457912393000072, 34.668345527000042],
            [71.458162237000067, 34.666989234000027],
            [71.458804691000068, 34.666239704000077],
            [71.459804065000071, 34.665668633000053],
            [71.461267433000046, 34.665097562000028],
            [71.462659418000044, 34.664562184000033],
            [71.46358740800008, 34.663991113000066],
            [71.464551089000054, 34.663277275000041],
            [71.465800306000062, 34.662313593000079],
            [71.466942448000054, 34.661135760000036],
            [71.467834745000062, 34.66010069400005],
            [71.468798427000081, 34.659493932000032],
            [71.469298114000082, 34.659279780000077],
            [71.469940568000084, 34.659493932000032],
            [71.470725790000074, 34.659743775000038],
            [71.472117775000072, 34.659993619000034],
            [71.473902371000065, 34.66010069400005],
            [71.475829734000058, 34.659850851000044],
            [71.476864800000044, 34.659636700000078],
            [71.47779279000008, 34.659529624000072],
            [71.479363234000061, 34.659208397000043],
            [71.480362607000075, 34.658887169000025],
            [71.481219213000031, 34.658958553000048],
            [71.481968743000039, 34.659351164000043],
            [71.482575506000046, 34.659708083000055],
            [71.483325036000053, 34.660279154000079],
            [71.484288718000073, 34.660921608000024],
            [71.485537935000082, 34.661635447000037],
            [71.487786526000036, 34.662991740000052],
            [71.489820965000035, 34.664276648000055],
            [71.491284333000067, 34.665204638000034],
            [71.494675065000081, 34.667274769000073],
            [71.497280575000048, 34.668559678000065],
            [71.498137181000061, 34.669023673000027],
            [71.498958095000035, 34.669059365000066],
            [71.499564857000053, 34.669059365000066],
            [71.500028852000071, 34.669023673000027],
            [71.500564231000055, 34.669023673000027],
            [71.501313761000063, 34.668987981000043],
            [71.502527286000031, 34.669380592000039],
            [71.503490968000051, 34.669559052000068],
            [71.504526033000047, 34.669559052000068],
            [71.505703867000079, 34.669523360000028],
            [71.50648908900007, 34.669523360000028],
            [71.507095851000031, 34.669594744000051],
            [71.507524154000066, 34.669773203000034],
            [71.507952457000044, 34.670165814000029],
            [71.508523528000069, 34.67066550100003],
            [71.509344442000042, 34.671486415000061],
            [71.51073642700004, 34.672735632000069],
            [71.511628724000047, 34.67352085400006],
            [71.512663790000033, 34.674056233000044],
            [71.513199169000075, 34.674270384000067],
            [71.513913007000042, 34.674199001000034],
            [71.514912381000045, 34.674056233000044],
            [71.516161598000053, 34.673984849000078],
            [71.517268047000073, 34.674127617000067],
            [71.517910501000074, 34.674270384000067],
            [71.518374496000035, 34.674555920000046],
            [71.51908833400006, 34.67523406600003],
            [71.520016324000039, 34.676090672000043],
            [71.520980006000059, 34.676947278000057],
            [71.522050763000038, 34.677518349000024],
            [71.522871677000069, 34.677910960000077],
            [71.523728283000082, 34.678089419000059],
            [71.524727657000028, 34.678196495000066],
            [71.525834106000048, 34.678089419000059],
            [71.527154707000079, 34.678089419000059],
            [71.529189146000078, 34.678089419000059],
            [71.531009434000055, 34.67794665200006],
            [71.532722646000082, 34.677625424000041],
            [71.535078312000053, 34.677375581000035],
            [71.536827216000063, 34.677161430000069],
            [71.53850473600005, 34.676697435000051],
            [71.541574241000035, 34.676090672000043],
            [71.543037609000066, 34.675519601000076],
            [71.544072675000052, 34.675126990000024],
            [71.545428967000078, 34.674591612000029],
            [71.546713876000069, 34.673984849000078],
            [71.547784634000038, 34.673699314000032],
            [71.549462154000082, 34.673449470000037],
            [71.549510776000034, 34.673426679000045],
            [71.550604295000028, 34.672914092000042],
            [71.551282441000069, 34.672521481000047],
            [71.552103355000042, 34.67177195000005],
            [71.552888577000033, 34.670986728000059],
            [71.553423956000074, 34.670237198000052],
            [71.553995027000042, 34.669380592000039],
            [71.554351946000054, 34.668274143000076],
            [71.554637481000043, 34.667132002000073],
            [71.55460178900006, 34.665775709000059],
            [71.554815941000072, 34.664776335000056],
            [71.55524424400005, 34.663669886000037],
            [71.556136542000047, 34.662277901000039],
            [71.556850380000071, 34.66106437600007],
            [71.557921137000051, 34.659493932000032],
            [71.558670668000048, 34.658316099000047],
            [71.559420198000055, 34.65742380100005],
            [71.559955577000039, 34.656602887000076],
            [71.56063372300008, 34.655639205000057],
            [71.560919258000069, 34.654925367000033],
            [71.560919258000069, 34.654104453000059],
            [71.560847874000046, 34.653426306000028],
            [71.559919885000056, 34.652355549000049],
            [71.55902758700006, 34.651891554000031],
            [71.557956829000034, 34.651463251000052],
            [71.556636228000059, 34.650999256000034],
            [71.555422703000033, 34.650285418000067],
            [71.554209178000065, 34.649535888000059],
            [71.553138421000028, 34.64857220600004],
            [71.551889204000076, 34.646966070000076],
            [71.549355078000076, 34.643254111000033],
            [71.54749909800006, 34.640755677000072],
            [71.546285573000034, 34.639220925000075],
            [71.545536043000084, 34.638435702000038],
            [71.544715129000053, 34.638078783000026],
            [71.543822831000057, 34.637150793000046],
            [71.543180377000056, 34.636008652000044],
            [71.542288079000059, 34.634937895000064],
            [71.540967478000084, 34.633652986000072],
            [71.539397034000046, 34.632082542000035],
            [71.537862281000059, 34.630833325000026],
            [71.537291211000081, 34.630405022000048],
            [71.536434605000068, 34.630262254000058],
            [71.535328156000048, 34.630190870000035],
            [71.534186014000056, 34.630119486000069],
            [71.53222295900008, 34.629905335000046],
            [71.530938050000032, 34.629619800000057],
            [71.52951037400004, 34.629084421000073],
            [71.52872515100006, 34.628691810000078],
            [71.528332540000065, 34.628120739000053],
            [71.528082697000059, 34.627335517000063],
            [71.528082697000059, 34.626800138000078],
            [71.528118389000042, 34.62633614300006],
            [71.528546692000077, 34.625943532000065],
            [71.529117762000055, 34.62555092100007],
            [71.529902985000035, 34.624979851000035],
            [71.530723899000066, 34.624158937000061],
            [71.531509121000056, 34.623338023000031],
            [71.532080191000034, 34.622410033000051],
            [71.53222295900008, 34.621517735000054],
            [71.53222295900008, 34.620125750000057],
            [71.53222295900008, 34.61844823000007],
            [71.53222295900008, 34.617091938000044],
            [71.532508494000069, 34.614950423000039],
            [71.532901105000064, 34.61309444300008],
            [71.533400792000066, 34.610845853000058],
            [71.533614944000078, 34.609560944000066],
            [71.53393617100005, 34.607276661000071],
            [71.534328782000046, 34.605563449000044],
            [71.534400166000069, 34.604314232000036],
            [71.534471550000035, 34.602993631000061],
            [71.534257398000079, 34.60142318700008],
            [71.53393617100005, 34.59967428300007],
            [71.533757711000078, 34.598317991000044],
            [71.533400792000066, 34.596426319000045],
            [71.532829722000031, 34.595034334000047],
            [71.532508494000069, 34.593999269000051],
            [71.532508494000069, 34.593321123000067],
            [71.532544186000052, 34.59257159200007],
            [71.532758338000065, 34.591786370000079],
            [71.533079565000037, 34.590858380000043],
            [71.533543560000055, 34.589823315000046],
            [71.534007555000073, 34.588859633000027],
            [71.534542933000068, 34.587503341000058],
            [71.53504262000007, 34.586147048000043],
            [71.535363847000042, 34.584683679000079],
            [71.53568507500006, 34.583505846000037],
            [71.535756459000083, 34.582006786000079],
            [71.535756459000083, 34.581435715000055],
            [71.536041994000072, 34.580828953000037],
            [71.536256145000038, 34.580329266000035],
            [71.536969984000052, 34.579758195000068],
            [71.53754105400003, 34.579187124000043],
            [71.53850473600005, 34.578544670000042],
            [71.53943272600003, 34.577902216000041],
            [71.540646251000055, 34.576974226000061],
            [71.541467165000029, 34.576117620000048],
            [71.542216695000036, 34.575225322000051],
            [71.542609306000031, 34.574654251000027],
            [71.543001917000083, 34.574011797000026],
            [71.543216069000039, 34.573262267000075],
            [71.543216069000039, 34.572477045000028],
            [71.543216069000039, 34.571441979000042],
            [71.543144685000073, 34.570442605000039],
            [71.542966225000043, 34.569193388000031],
            [71.542894841000077, 34.56748017700005],
            [71.542894841000077, 34.566123884000035],
            [71.54278776600006, 34.564446364000048],
            [71.542573614000048, 34.563339914000039],
            [71.542573614000048, 34.562661768000055],
            [71.542716382000037, 34.561876546000065],
            [71.543001917000083, 34.561269783000057],
            [71.543323144000055, 34.560591637000073],
            [71.543501604000085, 34.560056258000031],
            [71.543501604000085, 34.559199652000075],
            [71.543537296000068, 34.558057511000072],
            [71.543501604000085, 34.557165213000076],
            [71.543465912000045, 34.556058764000056],
            [71.543465912000045, 34.554880931000071],
            [71.543465912000045, 34.553774481000062],
            [71.543430220000062, 34.552096961000075],
            [71.543287452000072, 34.550954820000072],
            [71.543037609000066, 34.549634219000041],
            [71.542859149000037, 34.548313618000066],
            [71.542716382000037, 34.547278553000069],
            [71.542752074000077, 34.545922260000054],
            [71.54293053300006, 34.545137038000064],
            [71.543323144000055, 34.54428043200005],
            [71.544108366000046, 34.543316750000031],
            [71.544786513000076, 34.542495836000057],
            [71.546071422000068, 34.541282311000032],
            [71.547284947000037, 34.540604165000047],
            [71.548177245000034, 34.540175862000069],
            [71.54921231000003, 34.539640483000028],
            [71.549783381000054, 34.539176488000066],
            [71.55017599200005, 34.538712494000038],
            [71.550568603000045, 34.53814142300007],
            [71.551032598000063, 34.53735620100008],
            [71.551603669000031, 34.536570979000032],
            [71.552067663000059, 34.536071292000031],
            [71.552852886000039, 34.53560729700007],
            [71.553709491000063, 34.53542883700004],
            [71.554530406000083, 34.535107610000068],
            [71.555279936000034, 34.53464361500005],
            [71.556386385000053, 34.534072545000072],
            [71.557742678000068, 34.532859020000046],
            [71.559455890000038, 34.531324267000059],
            [71.560705107000047, 34.530324894000046],
            [71.56170448000006, 34.529753823000078],
            [71.562882313000046, 34.529004293000071],
            [71.563738919000059, 34.528254763000064],
            [71.564417066000033, 34.527648000000056],
            [71.565059520000034, 34.526934162000032],
            [71.565452131000029, 34.526291707000041],
            [71.566058894000037, 34.525399409000045],
            [71.566487197000072, 34.524649879000037],
            [71.566986884000073, 34.524114501000042],
            [71.567593646000034, 34.523793273000024],
            [71.568485944000031, 34.523650506000024],
            [71.569663777000073, 34.523472046000052],
            [71.57037761600003, 34.52315081900008],
            [71.571091454000054, 34.522579748000055],
            [71.572055135000028, 34.521616067000025],
            [71.572626206000052, 34.520973612000034],
            [71.573447120000083, 34.520081314000038],
            [71.574232342000073, 34.51936747600007],
            [71.575481559000082, 34.518189643000028],
            [71.576088322000032, 34.517404421000037],
            [71.57687354400008, 34.51679765800003],
            [71.57765876600007, 34.515833976000067],
            [71.578336912000054, 34.515048754000077],
            [71.579157826000028, 34.514013689000024],
            [71.579693205000069, 34.512978623000038],
            [71.580050124000081, 34.51222909300003],
            [71.580264276000037, 34.51144387100004],
            [71.580728271000055, 34.510622957000066],
            [71.580978114000061, 34.509873427000059],
            [71.581049498000084, 34.509159588000045],
            [71.581299341000033, 34.508374366000055],
            [71.581406417000039, 34.507803296000077],
            [71.581691952000028, 34.507410685000025],
            [71.582155947000047, 34.507374993000042],
            [71.582542343000057, 34.507592340000031],
            [71.582727018000071, 34.507696220000071],
            [71.583226705000072, 34.50798175500006],
            [71.583726392000074, 34.508374366000055],
            [71.584368846000075, 34.508695593000027],
            [71.585261144000071, 34.508909745000039],
            [71.586046366000062, 34.509552199000041],
            [71.586688820000063, 34.510123270000065],
            [71.587474043000043, 34.510837108000032],
            [71.588473416000056, 34.512086325000041],
            [71.588901719000035, 34.512550320000059],
            [71.589508482000042, 34.513085699000044],
            [71.590150936000043, 34.513406926000073],
            [71.590793391000034, 34.513656770000068],
            [71.591649997000047, 34.513656770000068],
            [71.593006289000073, 34.513763845000028],
            [71.594255506000081, 34.514049381000063],
            [71.595504723000033, 34.514334916000053],
            [71.596575481000059, 34.514834603000054],
            [71.597432087000072, 34.515227214000049],
            [71.598609920000058, 34.515441365000072],
            [71.600323132000028, 34.515976744000056],
            [71.601679424000054, 34.516369355000052],
            [71.602750182000079, 34.516904734000036],
            [71.603785247000076, 34.517582880000077],
            [71.604177858000071, 34.518189643000028],
            [71.604927389000068, 34.518903481000052],
            [71.605320000000063, 34.519403168000053],
            [71.60606953000007, 34.519724395000026],
            [71.606783368000038, 34.520009930000072],
            [71.60792550900004, 34.520331158000033],
            [71.608853499000077, 34.520509617000073],
            [71.60953164600005, 34.520366849000027],
            [71.610245484000075, 34.519902855000055],
            [71.610816555000042, 34.519296092000047],
            [71.611387625000077, 34.518153951000045],
            [71.612101463000045, 34.517225961000065],
            [71.612743918000035, 34.516547815000024],
            [71.613422064000076, 34.516155204000029],
            [71.614207286000067, 34.51601243600004],
            [71.615278044000036, 34.515798284000027],
            [71.616206034000072, 34.51572690100005],
            [71.617098331000079, 34.51572690100005],
            [71.618454624000037, 34.515905360000033],
            [71.61963245700008, 34.516297971000029],
            [71.620489063000036, 34.516619198000058],
            [71.621417053000073, 34.516904734000036],
            [71.622523502000035, 34.51776134000005],
            [71.623772719000044, 34.518653638000046],
            [71.624879169000053, 34.519581627000036],
            [71.625450239000031, 34.52029546600005],
            [71.626128386000062, 34.521437607000053],
            [71.626413921000051, 34.522222829000043],
            [71.62673514800008, 34.52315081900008],
            [71.627056376000041, 34.524221576000059],
            [71.627270527000064, 34.525149566000039],
            [71.627306219000047, 34.525863404000063],
            [71.627306219000047, 34.52675570200006],
            [71.627163451000058, 34.527683692000039],
            [71.626949300000035, 34.528932909000048],
            [71.62673514800008, 34.53025351000008],
            [71.626485305000074, 34.531431343000065],
            [71.626485305000074, 34.532145181000033],
            [71.626485305000074, 34.53293040300008],
            [71.626663765000046, 34.534179620000032],
            [71.626663765000046, 34.534822075000079],
            [71.626913608000052, 34.535321762000024],
            [71.627127759000075, 34.535964216000025],
            [71.627663138000059, 34.536249751000071],
            [71.627984365000032, 34.536535287000049],
            [71.628591128000039, 34.536856514000078],
            [71.62909081500004, 34.536856514000078],
            [71.629733269000042, 34.536856514000078],
            [71.630768335000084, 34.536820822000038],
            [71.631696324000075, 34.536785130000055],
            [71.632838466000067, 34.536678054000049],
            [71.634194758000035, 34.536463903000026],
            [71.636122122000074, 34.536285443000054],
            [71.637157187000071, 34.536249751000071],
            [71.637693047000084, 34.53599758200005],
            [71.637693047000084, 34.536110861000054],
            [71.63790719900004, 34.536182244000031],
            [71.638157042000046, 34.536182244000031],
            [71.638172480000037, 34.536175628000024],
            [71.638169493000078, 34.536182244000031],
            [71.638799497000036, 34.536182244000031],
            [71.639905946000056, 34.536253628000054],
            [71.641012395000075, 34.536253628000054],
            [71.641803196000069, 34.536343620000025],
            [71.64291640600004, 34.536411806000046],
            [71.643136000000084, 34.533778000000041],
            [71.647375625000052, 34.529153023000049],
            [71.647557330000041, 34.528954803000033],
            [71.657714000000055, 34.51787500000006],
            [71.658510964000072, 34.50671509600005],
            [71.659039000000064, 34.499321000000066],
            [71.662352000000055, 34.479442000000063],
            [71.662735830000031, 34.47023471600005],
            [71.66301500000003, 34.463538000000028],
            [71.674280000000067, 34.459562000000062],
            [71.687532608000083, 34.462212922000049],
            [71.70211050000006, 34.451611000000071],
            [71.70424173300006, 34.443086933000075],
            [71.704243724000037, 34.443078964000051],
            [71.704248973000063, 34.443057961000079],
            [71.704340580000064, 34.443054108000069],
            [71.704761036000036, 34.441367635000063],
            [71.704859040000031, 34.440974535000066],
            [71.704761036000036, 34.440928732000032],
            [71.703182408000032, 34.440190955000048],
            [71.701040487000057, 34.439225692000036],
            [71.698766771000066, 34.438127291000058],
            [71.69760788800005, 34.437585682000076],
            [71.695447812000054, 34.436576163000041],
            [71.693403581000041, 34.435620784000037],
            [71.691122818000053, 34.434554861000038],
            [71.68941202700006, 34.433755317000077],
            [71.688193242000068, 34.433185713000057],
            [71.686270585000045, 34.432287152000072],
            [71.684747877000063, 34.431575509000027],
            [71.683414130000074, 34.430952178000041],
            [71.680841783000062, 34.429749982000033],
            [71.675061089000053, 34.427048353000032],
            [71.67327526300005, 34.425863186000072],
            [71.672617670000079, 34.425412923000067],
            [71.672440008000081, 34.425291275000063],
            [71.672408572000052, 34.425269750000041],
            [71.671077337000042, 34.424358235000057],
            [71.670634566000047, 34.424055063000026],
            [71.670200867000062, 34.423758103000068],
            [71.668907309000076, 34.422872386000051],
            [71.66620935800006, 34.42102506100008],
            [71.664091303000077, 34.419574798000042],
            [71.662429178000082, 34.418437872000027],
            [71.662261879000084, 34.41812333300004],
            [71.661843238000074, 34.417328164000025],
            [71.661808797000049, 34.417262747000052],
            [71.661008717000072, 34.415743071000065],
            [71.659590431000083, 34.413049172000058],
            [71.656468972000084, 34.407120256000042],
            [71.655794542000081, 34.405839241000024],
            [71.656194466000045, 34.402751935000026],
            [71.656417916000066, 34.401026963000049],
            [71.65669052100003, 34.398922526000035],
            [71.657393063000029, 34.393499096000028],
            [71.657769630000075, 34.390592108000078],
            [71.653308863000063, 34.387775444000056],
            [71.650874840000029, 34.386238528000035],
            [71.64906039400006, 34.385092831000065],
            [71.647431877000031, 34.384071811000069],
            [71.646283444000062, 34.383339381000042],
            [71.645178270000031, 34.382641540000066],
            [71.641626315000053, 34.382642925000027],
            [71.639069432000042, 34.382643922000057],
            [71.635942585000066, 34.382645142000058],
            [71.63232371600003, 34.382646553000029],
            [71.631268211000076, 34.38264696400006],
            [71.630108230000076, 34.381359400000065],
            [71.628732011000068, 34.379831814000056],
            [71.627031857000077, 34.37794466500003],
            [71.625299505000044, 34.376021777000062],
            [71.62521412600006, 34.374514400000066],
            [71.62510611700003, 34.372607495000068],
            [71.625002463000044, 34.370777486000065],
            [71.624932500000057, 34.369542284000033],
            [71.62487888000004, 34.368595621000054],
            [71.624789089000046, 34.367010372000038],
            [71.624726710000061, 34.365909060000035],
            [71.624646172000041, 34.364487164000025],
            [71.624581889000069, 34.363677920000043],
            [71.624476777000041, 34.362638554000057],
            [71.624340086000075, 34.361286938000035],
            [71.624243353000054, 34.36033042400004],
            [71.624163458000055, 34.359540412000058],
            [71.62405203000003, 34.358438594000063],
            [71.623921657000039, 34.35714944700004],
            [71.623658852000062, 34.354550783000036],
            [71.623425481000083, 34.35224317400008],
            [71.623283516000072, 34.350839399000051],
            [71.621815689000073, 34.351604160000079],
            [71.620444268000028, 34.35231869200004],
            [71.616908432000059, 34.354160918000048],
            [71.616578048000065, 34.354333053000062],
            [71.61406437800008, 34.355642715000045],
            [71.613019647000044, 34.356187036000051],
            [71.608781693000083, 34.358395077000068],
            [71.606962177000071, 34.359343073000048],
            [71.606730187000039, 34.359463944000026],
            [71.606575840000062, 34.359532028000046],
            [71.604250663000073, 34.360557684000071],
            [71.595495791000076, 34.364419536000071],
            [71.590176771000074, 34.366765803000078],
            [71.587771337000049, 34.362653200000068],
            [71.585527576000061, 34.358817008000074],
            [71.584285772000044, 34.358108995000066],
            [71.582262150000076, 34.35695523000004],
            [71.579918583000051, 34.35561904900004],
            [71.576243397000042, 34.353523647000031],
            [71.575611337000055, 34.352893213000073],
            [71.574104931000079, 34.351390682000044],
            [71.572029728000075, 34.349320818000024],
            [71.569121952000046, 34.346420522000074],
            [71.566329870000061, 34.343635624000058],
            [71.564960326000062, 34.342269602000044],
            [71.564544728000044, 34.341315097000063],
            [71.56387624100006, 34.339841613000033],
            [71.563146771000049, 34.338207843000077],
            [71.563143185000058, 34.338199812000028],
            [71.563136314000076, 34.33818442300003],
            [71.562259493000056, 34.336220637000054],
            [71.560859968000045, 34.333086167000033],
            [71.560859850000043, 34.333086232000028],
            [71.560858881000058, 34.33308405300005],
            [71.560842790000038, 34.33304785200005],
            [71.560842834000084, 34.333047541000042],
            [71.556994626000062, 34.324368574000061],
            [71.542424109000081, 34.31840969600006],
            [71.53994040300006, 34.316634561000058],
            [71.539671254000041, 34.316442251000069],
            [71.538725869000075, 34.315766591000056],
            [71.536371363000058, 34.314083842000059],
            [71.533151266000061, 34.311782461000064],
            [71.534237438000048, 34.307696342000042],
            [71.53496503000008, 34.304959181000072],
            [71.535798972000066, 34.301821937000057],
            [71.535215943000082, 34.299487382000052],
            [71.534228440000049, 34.29553323500005],
            [71.533810607000078, 34.293860153000026],
            [71.533748208000077, 34.292925328000024],
            [71.533601803000067, 34.290731956000059],
            [71.533392028000037, 34.287589197000045],
            [71.533149004000052, 34.283948328000065],
            [71.533146253000041, 34.283907119000048],
            [71.533796280000047, 34.283110212000054],
            [71.535854000000029, 34.280587529000059],
            [71.53908944300008, 34.276621006000028],
            [71.539105950000078, 34.276600769000027],
            [71.538250767000079, 34.272751834000076],
            [71.537755892000064, 34.270524547000036],
            [71.537056530000029, 34.267376917000036],
            [71.536453606000066, 34.26466332900003],
            [71.535704015000078, 34.263914002000035],
            [71.535072944000035, 34.26309359000004],
            [71.531242973000076, 34.259454532000063],
            [71.528502544000048, 34.256715070000041],
            [71.521122219000063, 34.251908481000044],
            [71.51725396300003, 34.249389200000053],
            [71.516051698000069, 34.24860620000004],
            [71.514713477000043, 34.247734656000034],
            [71.514153234000048, 34.247369787000025],
            [71.513263512000037, 34.24679033700005],
            [71.512524474000031, 34.245867300000043],
            [71.509934184000031, 34.242617062000079],
            [71.509295462000068, 34.241834363000066],
            [71.508009284000082, 34.240227964000042],
            [71.507961425000076, 34.240168190000077],
            [71.507886958000029, 34.240227964000042],
            [71.505464665000034, 34.242172310000058],
            [71.503701413000044, 34.243587652000031],
            [71.50191469400005, 34.245021830000042],
            [71.499644370000055, 34.246844193000072],
            [71.497707682000055, 34.248398750000035],
            [71.495831624000061, 34.249904640000068],
            [71.494717282000067, 34.25079910900007],
            [71.492894955000054, 34.248675283000068],
            [71.49197609600003, 34.247604402000036],
            [71.491633614000079, 34.247198964000063],
            [71.490889813000081, 34.246338397000045],
            [71.490234801000042, 34.245575016000032],
            [71.489463350000051, 34.244675931000074],
            [71.488642090000042, 34.243718796000053],
            [71.488620648000051, 34.243733011000074],
            [71.488118519000068, 34.243147215000079],
            [71.486752000000081, 34.241553000000067],
            [71.486957870000083, 34.241141285000026],
            [71.486957927000049, 34.241141172000027],
            [71.487495956000032, 34.240062369000043],
            [71.489687837000076, 34.235667426000077],
            [71.491847116000031, 34.23133785400006],
            [71.494687058000068, 34.225643484000045],
            [71.477475000000084, 34.219024000000047],
            [71.469501125000079, 34.211492913000029],
            [71.475724828000068, 34.208619592000048],
            [71.47603033200005, 34.207917397000074],
            [71.476338987000076, 34.207070833000046],
            [71.476666213000044, 34.206451016000074],
            [71.476815184000031, 34.206187144000069],
            [71.476883417000067, 34.206006305000074],
            [71.476927811000053, 34.205858733000071],
            [71.476957839000079, 34.205492787000026],
            [71.476919206000048, 34.204107927000052],
            [71.47693275000006, 34.20385212900004],
            [71.47696578800003, 34.203711597000051],
            [71.477152982000064, 34.203233566000051],
            [71.477212179000048, 34.203073921000055],
            [71.47729898800003, 34.202540328000055],
            [71.47731611100005, 34.202076432000069],
            [71.477313406000064, 34.201682007000045],
            [71.477250211000069, 34.201663633000067],
            [71.477144086000067, 34.201674965000052],
            [71.477002166000034, 34.201734260000023],
            [71.476461758000084, 34.201983510000048],
            [71.476027100000067, 34.202162919000045],
            [71.475922507000064, 34.202186349000044],
            [71.475850711000078, 34.202148011000077],
            [71.47581374300006, 34.202094153000075],
            [71.475787186000048, 34.201960160000056],
            [71.475760692000051, 34.201827990000027],
            [71.47568625100007, 34.201706582000043],
            [71.475600501000031, 34.201630324000064],
            [71.475424760000067, 34.201569032000066],
            [71.475332949000062, 34.201557000000037],
            [71.475215697000067, 34.201581546000057],
            [71.475127430000043, 34.201615891000074],
            [71.475049647000048, 34.201614025000026],
            [71.474987983000062, 34.201586388000067],
            [71.474890213000037, 34.201489224000056],
            [71.474795036000046, 34.201417553000056],
            [71.474699647000079, 34.201379468000027],
            [71.474602897000068, 34.201378588000068],
            [71.47446106700005, 34.201412450000078],
            [71.474328925000066, 34.201463820000072],
            [71.474238789000083, 34.201501959000041],
            [71.47415593900007, 34.201517200000069],
            [71.473990952000065, 34.201537519000055],
            [71.473570409000047, 34.201588027000071],
            [71.473417780000034, 34.201610603000063],
            [71.473249324000051, 34.201645542000051],
            [71.473121762000062, 34.201660447000052],
            [71.473004095000078, 34.201660451000066],
            [71.472839086000079, 34.201645628000051],
            [71.472671296000044, 34.201640723000025],
            [71.47256053600006, 34.201645696000071],
            [71.472444005000057, 34.201662999000064],
            [71.472378491000029, 34.201690092000035],
            [71.47231355200006, 34.201744046000044],
            [71.47218966500003, 34.201804969000079],
            [71.472070161000033, 34.201846208000063],
            [71.471978234000062, 34.201853406000055],
            [71.471898443000043, 34.201843633000067],
            [71.471818934000055, 34.201802305000058],
            [71.47174547000003, 34.20172678800003],
            [71.471689468000079, 34.201640984000051],
            [71.471606214000076, 34.201532275000034],
            [71.471540348000076, 34.201467647000072],
            [71.471457101000055, 34.201437957000053],
            [71.471360347000029, 34.201423325000064],
            [71.471286687000031, 34.201443550000079],
            [71.471212881000042, 34.201490883000076],
            [71.471161964000032, 34.201550044000044],
            [71.471119332000058, 34.201663267000072],
            [71.471017411000048, 34.201881556000046],
            [71.47079773300004, 34.202275424000049],
            [71.470648337000057, 34.202520654000068],
            [71.470591146000061, 34.202601858000037],
            [71.470516666000037, 34.202657020000061],
            [71.470439172000056, 34.20267648500004],
            [71.470329855000045, 34.202653129000055],
            [71.470237575000056, 34.202600927000049],
            [71.47012185300008, 34.202519677000055],
            [71.469965537000064, 34.202424058000076],
            [71.469886540000061, 34.202407387000051],
            [71.469643593000058, 34.202356118000068],
            [71.469513284000072, 34.20233444400003],
            [71.469412420000083, 34.202299248000031],
            [71.469302704000029, 34.20221162200005],
            [71.469160327000054, 34.202070810000066],
            [71.468913684000029, 34.201846559000046],
            [71.468625689000078, 34.20165496900006],
            [71.468566710000061, 34.201591911000037],
            [71.468516476000048, 34.201531146000036],
            [71.468415490000041, 34.201250415000061],
            [71.468323674000032, 34.201003111000034],
            [71.468267205000075, 34.200887890000047],
            [71.46800389200007, 34.200566198000047],
            [71.467697807000036, 34.200242053000068],
            [71.467522905000067, 34.20005683100004],
            [71.467386236000038, 34.199919365000028],
            [71.467169573000035, 34.19959592400005],
            [71.466996808000033, 34.199285244000066],
            [71.466941594000048, 34.199211149000064],
            [71.46652173800004, 34.198854942000025],
            [71.466464478000034, 34.198790030000055],
            [71.466454255000031, 34.198778441000059],
            [71.466408149000074, 34.198683785000071],
            [71.46632379700003, 34.198505064000074],
            [71.466289116000041, 34.198405856000079],
            [71.466245002000051, 34.19813558900006],
            [71.466208881000057, 34.197964698000078],
            [71.466175063000037, 34.197902312000053],
            [71.465852010000049, 34.197697204000065],
            [71.46571811900003, 34.19763159200005],
            [71.465633677000028, 34.197599922000052],
            [71.465624219000063, 34.197599703000037],
            [71.464741609000043, 34.197579251000036],
            [71.464378668000052, 34.197596791000024],
            [71.464231314000074, 34.197609637000028],
            [71.463936290000049, 34.197670364000032],
            [71.463026238000054, 34.197887465000065],
            [71.462867004000032, 34.197911693000037],
            [71.46270688900006, 34.19791723700007],
            [71.462575944000037, 34.197898516000066],
            [71.462474640000039, 34.197871630000066],
            [71.462372874000039, 34.197820469000078],
            [71.461833675000037, 34.197513532000073],
            [71.461008563000064, 34.197022186000027],
            [71.460439873000041, 34.196614009000029],
            [71.460318015000041, 34.19652654500004],
            [71.460006282000052, 34.196306352000079],
            [71.459899195000048, 34.196220527000037],
            [71.459897380000029, 34.196209040000042],
            [71.459733816000039, 34.195889546000046],
            [71.459162473000049, 34.195143703000042],
            [71.458999807000055, 34.19492212800003],
            [71.458861579000029, 34.194802416000073],
            [71.458702972000083, 34.194756840000025],
            [71.458530972000062, 34.194762601000036],
            [71.458407259000069, 34.194756968000036],
            [71.457471231000056, 34.194329488000051],
            [71.454577508000057, 34.19295001200004],
            [71.452363889000083, 34.191894750000074],
            [71.448951338000029, 34.190127971000038],
            [71.445428222000032, 34.188309845000049],
            [71.445355484000061, 34.18823151600003],
            [71.445406191000075, 34.188141290000033],
            [71.445992641000032, 34.187501518000033],
            [71.447002190000035, 34.186315665000052],
            [71.446618197000078, 34.186107586000048],
            [71.446590531000084, 34.186083379000024],
            [71.446398015000057, 34.18591493100007],
            [71.446240599000078, 34.185690897000029],
            [71.445812285000045, 34.185034218000055],
            [71.445560790000059, 34.184832232000076],
            [71.445446962000062, 34.184747163000054],
            [71.445303998000043, 34.184640319000039],
            [71.444485439000061, 34.184232188000067],
            [71.442774536000059, 34.183364696000069],
            [71.442497072000037, 34.18322401100005],
            [71.442053752000049, 34.183000865000054],
            [71.441834697000047, 34.182855044000064],
            [71.44115839400007, 34.182649224000045],
            [71.439467813000078, 34.182199098000069],
            [71.439061781000078, 34.182083390000059],
            [71.438972376000038, 34.182558058000041],
            [71.43878201900003, 34.183224307000046],
            [71.438496484000041, 34.184138021000024],
            [71.438229984000031, 34.184480663000045],
            [71.437906377000047, 34.184613913000078],
            [71.437525664000077, 34.185051734000069],
            [71.437163985000041, 34.185641840000073],
            [71.436630986000068, 34.186117732000071],
            [71.436242064000055, 34.18659049300004],
            [71.43591973000008, 34.187111709000078],
            [71.435662980000075, 34.187735244000066],
            [71.435387891000062, 34.188138708000054],
            [71.435076123000044, 34.188743904000034],
            [71.43342558900008, 34.188707226000076],
            [71.433297214000049, 34.188138708000054],
            [71.433370571000069, 34.187698566000051],
            [71.433443929000077, 34.186964995000039],
            [71.433553964000055, 34.186598210000056],
            [71.433535625000047, 34.186378138000066],
            [71.433315554000046, 34.186231424000027],
            [71.433095482000056, 34.186029692000034],
            [71.432930429000066, 34.185772943000075],
            [71.432985447000078, 34.185497854000062],
            [71.433333893000054, 34.185204425000052],
            [71.433860200000083, 34.184709700000042],
            [71.43426708700008, 34.184252844000071],
            [71.434702529000049, 34.183731742000077],
            [71.43485957300004, 34.183439068000041],
            [71.435023756000078, 34.183003627000062],
            [71.435080863000053, 34.182582462000028],
            [71.434980926000037, 34.182132744000057],
            [71.43475249800008, 34.181654472000048],
            [71.434531208000067, 34.181254723000052],
            [71.434362384000053, 34.181088920000036],
            [71.433931681000047, 34.181093426000075],
            [71.433668689000058, 34.181183791000024],
            [71.433503229000053, 34.181252942000071],
            [71.433383478000053, 34.181349195000053],
            [71.433304157000066, 34.181470536000063],
            [71.433192237000071, 34.181686076000062],
            [71.433073019000062, 34.181846250000035],
            [71.43290124400005, 34.182025711000051],
            [71.432735507000075, 34.182116735000079],
            [71.43252534700008, 34.182173687000045],
            [71.431383181000058, 34.182527296000046],
            [71.431152312000052, 34.182598772000063],
            [71.43089139600005, 34.182675375000031],
            [71.429315778000046, 34.183137963000036],
            [71.428711639000028, 34.183255913000039],
            [71.428493682000067, 34.183262200000058],
            [71.428327685000056, 34.183271820000073],
            [71.428181702000074, 34.18326182900006],
            [71.428056459000061, 34.183248609000032],
            [71.427870625000082, 34.183212471000047],
            [71.427740898000081, 34.183171962000074],
            [71.427468782000062, 34.183073811000042],
            [71.427279787000032, 34.182982585000047],
            [71.425903037000069, 34.182119292000039],
            [71.424898289000055, 34.181667036000078],
            [71.424721368000064, 34.181553051000037],
            [71.424580766000076, 34.181400831000076],
            [71.424480957000071, 34.181244444000072],
            [71.424278058000084, 34.18069074400006],
            [71.424228707000054, 34.180556066000065],
            [71.424195607000058, 34.180477220000057],
            [71.424135831000058, 34.179816238000058],
            [71.424098531000084, 34.17971691200006],
            [71.423110822000069, 34.178368227000078],
            [71.423031639000044, 34.178260211000065],
            [71.422953606000078, 34.178219354000078],
            [71.422787590000041, 34.178184896000062],
            [71.422460069000067, 34.17819724800006],
            [71.422005099000046, 34.178175730000078],
            [71.421582047000072, 34.178164934000051],
            [71.421378988000072, 34.178141260000075],
            [71.421001647000082, 34.178079818000072],
            [71.420891035000068, 34.178057667000076],
            [71.420299585000066, 34.177939223000067],
            [71.41947489000006, 34.177769042000079],
            [71.419299512000066, 34.17774797900006],
            [71.417815222000058, 34.177663909000046],
            [71.416777248000074, 34.177560645000028],
            [71.416031031000045, 34.177495555000064],
            [71.415830651000078, 34.177462621000075],
            [71.41572069700004, 34.177438468000048],
            [71.415600700000084, 34.177381680000053],
            [71.415458894000039, 34.177300856000045],
            [71.41530528100003, 34.177216483000052],
            [71.414493661000051, 34.17688952900005],
            [71.413813486000038, 34.176656102000038],
            [71.413251694000053, 34.176459602000079],
            [71.412692417000073, 34.176282786000058],
            [71.412414639000076, 34.176175504000071],
            [71.412228352000056, 34.176085860000057],
            [71.41160431000003, 34.175684423000064],
            [71.410741612000038, 34.175186618000055],
            [71.41039345300004, 34.175029348000066],
            [71.41022668800008, 34.174988738000025],
            [71.410034801000052, 34.174948095000047],
            [71.41001205200007, 34.174945825000066],
            [71.409840146000079, 34.174928673000068],
            [71.409590131000073, 34.174891539000043],
            [71.409472836000077, 34.174875991000079],
            [71.409322652000071, 34.174839210000073],
            [71.409037174000048, 34.174748237000074],
            [71.409147011000073, 34.171214745000043],
            [71.407912319000047, 34.170773784000062],
            [71.406920156000069, 34.170222582000065],
            [71.405685464000044, 34.169825717000037],
            [71.404847637000046, 34.169428852000067],
            [71.404274387000044, 34.169164275000071],
            [71.40341451300003, 34.168855602000065],
            [71.403103384000076, 34.168598877000079],
            [71.401486791000082, 34.169074692000038],
            [71.401259273000051, 34.169141658000058],
            [71.400789756000052, 34.169164016000025],
            [71.400104471000077, 34.169164016000025],
            [71.399247865000063, 34.169164016000025],
            [71.398248491000061, 34.169049802000075],
            [71.397077796000076, 34.168907034000028],
            [71.396078423000063, 34.168707160000054],
            [71.39382269400005, 34.168278857000075],
            [71.392709106000041, 34.167936214000065],
            [71.391252876000067, 34.167650679000076],
            [71.390167842000039, 34.167479358000037],
            [71.38894004000008, 34.167222376000041],
            [71.387312489000067, 34.166965394000044],
            [71.385827705000054, 34.166851180000037],
            [71.384599903000037, 34.166651305000073],
            [71.382829584000035, 34.166537091000066],
            [71.380745176000062, 34.166422877000059],
            [71.379231839000056, 34.166422877000059],
            [71.377175985000065, 34.166594198000041],
            [71.375120131000074, 34.16673696600003],
            [71.37329270500004, 34.166794073000062],
            [71.37206490300008, 34.166794073000062],
            [71.369809174000068, 34.16673696600003],
            [71.367838980000045, 34.166679859000055],
            [71.366496964000078, 34.166394324000066],
            [71.364241235000065, 34.165766146000067],
            [71.362185381000074, 34.165223629000025],
            [71.360072420000051, 34.164738219000071],
            [71.35815933300006, 34.164367023000068],
            [71.356788763000054, 34.164138595000054],
            [71.355503854000062, 34.163767399000051],
            [71.353847750000057, 34.163281989000041],
            [71.352505734000033, 34.162967900000069],
            [71.351249378000034, 34.162853686000062],
            [71.349821702000042, 34.16279657900003],
            [71.348622453000075, 34.162853686000062],
            [71.34759452600008, 34.163281989000041],
            [71.346709367000074, 34.163795952000044],
            [71.345795654000028, 34.164395576000061],
            [71.345138923000036, 34.165052308000043],
            [71.344682066000075, 34.165594825000028],
            [71.343625585000041, 34.166679859000055],
            [71.342597658000045, 34.167422251000062],
            [71.341512624000075, 34.168107535000047],
            [71.340684572000043, 34.168621499000039],
            [71.339628091000066, 34.169164016000025],
            [71.337857772000064, 34.16959231900006],
            [71.336315881000075, 34.169735087000049],
            [71.33540216800003, 34.170020622000038],
            [71.334517009000081, 34.170363264000059],
            [71.333403421000071, 34.170534586000031],
            [71.332089959000029, 34.170534586000031],
            [71.330919264000045, 34.170506032000048],
            [71.329691462000028, 34.170506032000048],
            [71.32863498100005, 34.170534586000031],
            [71.32692176900008, 34.170791567000038],
            [71.325693968000053, 34.171077103000073],
            [71.324380505000079, 34.171391192000044],
            [71.323209810000037, 34.171533959000044],
            [71.32201056200006, 34.171562513000026],
            [71.320554332000029, 34.171591066000076],
            [71.319126655000048, 34.171619620000058],
            [71.318184389000066, 34.171619620000058],
            [71.317584765000049, 34.171505406000051],
            [71.317270676000078, 34.17119131700008],
            [71.317042248000064, 34.170791567000038],
            [71.316985140000043, 34.170277604000034],
            [71.316985140000043, 34.169649426000035],
            [71.317127908000032, 34.168507285000032],
            [71.31744199700006, 34.167051055000059],
            [71.317756086000031, 34.165737592000028],
            [71.318098728000052, 34.164566898000032],
            [71.318298603000073, 34.163224882000065],
            [71.318327156000066, 34.162425383000027],
            [71.318241496000041, 34.161911419000035],
            [71.31792740700007, 34.161426009000024],
            [71.317499104000035, 34.16122613400006],
            [71.316928033000067, 34.160969153000053],
            [71.316099981000036, 34.160912046000078],
            [71.315186268000048, 34.160997706000046],
            [71.314101234000077, 34.161111920000053],
            [71.312445129000082, 34.161511670000039],
            [71.311074559000076, 34.161968526000067],
            [71.309789651000074, 34.162311169000077],
            [71.308676063000064, 34.16282513200008],
            [71.308019332000072, 34.163224882000065],
            [71.30747681400004, 34.163710292000076],
            [71.30699140400003, 34.164481237000075],
            [71.306677316000048, 34.165252182000074],
            [71.306448887000045, 34.165994574000024],
            [71.305992031000073, 34.167136715000026],
            [71.305820710000035, 34.16799332100004],
            [71.305792156000052, 34.168849927000053],
            [71.305820710000035, 34.169820747000074],
            [71.306049138000049, 34.17130553100003],
            [71.30610624500008, 34.172333458000026],
            [71.306191906000038, 34.173190064000039],
            [71.30639178000007, 34.175931203000061],
            [71.306277566000063, 34.17718755900006],
            [71.306277566000063, 34.178072718000067],
            [71.306334673000038, 34.178986431000055],
            [71.306591655000034, 34.17967171600003],
            [71.307019958000069, 34.180613982000068],
            [71.30733404700004, 34.181442035000032],
            [71.30796222400005, 34.182498515000077],
            [71.30859040200005, 34.183212354000034],
            [71.309132919000035, 34.18409751300004],
            [71.309361348000039, 34.184697137000057],
            [71.309361348000039, 34.185296761000075],
            [71.309361348000039, 34.185953493000056],
            [71.309047259000067, 34.187009973000045],
            [71.308733170000039, 34.187780919000033],
            [71.308133546000079, 34.188723185000072],
            [71.307762350000075, 34.189579791000028],
            [71.307448261000047, 34.190579165000031],
            [71.307162726000058, 34.191492878000076],
            [71.30699140400003, 34.192206716000044],
            [71.306591655000034, 34.192663573000061],
            [71.306077691000041, 34.193320304000054],
            [71.305506621000063, 34.193463071000053],
            [71.304421587000036, 34.19357728600005],
            [71.303593534000072, 34.19357728600005],
            [71.302708375000066, 34.193520179000075],
            [71.300795288000074, 34.193006215000025],
            [71.29911063000003, 34.192692126000054],
            [71.297568739000042, 34.192063948000055],
            [71.296455151000032, 34.19186407400008],
            [71.295484331000068, 34.19186407400008],
            [71.294627725000055, 34.191921181000055],
            [71.293514138000035, 34.192492251000033],
            [71.292200675000061, 34.193006215000025],
            [71.290087714000038, 34.194319677000067],
            [71.289345322000031, 34.194833641000059],
            [71.28888846500007, 34.195347605000052],
            [71.288822054000036, 34.195513634000065],
            [71.288660037000056, 34.19591867500003],
            [71.28840305500006, 34.196746728000051],
            [71.288288841000053, 34.197660441000039],
            [71.288060413000039, 34.198745475000067],
            [71.288117520000071, 34.199630634000073],
            [71.288545823000049, 34.200687115000051],
            [71.28888846500007, 34.201600828000039],
            [71.289545197000052, 34.202257559000032],
            [71.291601051000043, 34.20428486000003],
            [71.293371370000045, 34.205598322000071],
            [71.29517024200004, 34.20699744500007],
            [71.296026848000054, 34.207711284000027],
            [71.296797794000042, 34.20871065700004],
            [71.297511632000067, 34.209881352000025],
            [71.298168363000059, 34.210423869000067],
            [71.299024969000072, 34.211394689000031],
            [71.300138557000082, 34.212451170000065],
            [71.300995163000039, 34.21327922200004],
            [71.301509126000042, 34.213878847000046],
            [71.301537680000081, 34.214649792000046],
            [71.301394912000035, 34.215335077000077],
            [71.300995163000039, 34.21596325400003],
            [71.300481199000046, 34.216505771000072],
            [71.299881575000029, 34.21707684200004],
            [71.299567486000058, 34.217647913000064],
            [71.299339058000044, 34.21804766200006],
            [71.299167737000062, 34.218561626000053],
            [71.299139183000079, 34.219189803000063],
            [71.299139183000079, 34.21978942800007],
            [71.299224844000037, 34.220588926000062],
            [71.300024343000075, 34.221788175000029],
            [71.300823842000057, 34.222816102000024],
            [71.301651894000031, 34.223615601000063],
            [71.30216585800008, 34.224043904000041],
            [71.303079571000069, 34.224757742000065],
            [71.303879069000061, 34.225500134000072],
            [71.304193158000032, 34.225956990000043],
            [71.304478694000068, 34.22641384700006],
            [71.30465001500005, 34.226984918000028],
            [71.304707122000082, 34.22767020200007],
            [71.304764229000057, 34.228612469000041],
            [71.304849890000071, 34.230097252000064],
            [71.304935550000039, 34.231068073000074],
            [71.305078318000028, 34.231896125000048],
            [71.305163978000053, 34.232809838000037],
            [71.305363853000074, 34.23366644400005],
            [71.305449514000031, 34.234408836000057],
            [71.305792156000052, 34.235008460000074],
            [71.306134798000073, 34.235465316000045],
            [71.306705869000041, 34.236236262000034],
            [71.307448261000047, 34.236835886000051],
            [71.308162099000072, 34.237435510000068],
            [71.308875937000039, 34.238606205000053],
            [71.309361348000039, 34.239776900000038],
            [71.309532669000077, 34.240776273000051],
            [71.309618329000045, 34.241604326000072],
            [71.30970399000006, 34.242403825000054],
            [71.309960972000056, 34.243117663000078],
            [71.310332168000059, 34.244259804000023],
            [71.310731917000055, 34.245144964000076],
            [71.311160220000033, 34.246001570000033],
            [71.31141720200003, 34.247257925000042],
            [71.311559969000029, 34.248228745000063],
            [71.311845505000065, 34.248999690000062],
            [71.311845505000065, 34.24957076100003],
            [71.311845505000065, 34.250227492000079],
            [71.311617077000051, 34.251312526000049],
            [71.311588523000069, 34.252197686000045],
            [71.311502862000054, 34.252654542000073],
            [71.311217327000065, 34.253339827000048],
            [71.311131666000051, 34.253882344000033],
            [71.311074559000076, 34.254596183000046],
            [71.311188774000072, 34.255224360000057],
            [71.311531416000037, 34.255823984000074],
            [71.312273808000043, 34.256537823000031],
            [71.313101860000074, 34.257365875000062],
            [71.314101234000077, 34.258450909000032],
            [71.31461519700008, 34.259221855000078],
            [71.315186268000048, 34.260221228000034],
            [71.315586017000044, 34.261449030000051],
            [71.315614571000083, 34.262191422000058],
            [71.315643124000076, 34.262905260000025],
            [71.315500357000076, 34.263847527000053],
            [71.31521482100004, 34.264618472000052],
            [71.314986393000083, 34.265417971000034],
            [71.314757965000069, 34.266331684000079],
            [71.314443876000041, 34.267502379000064],
            [71.314186894000045, 34.26838753800007],
            [71.313929913000038, 34.269015716000069],
            [71.313672931000042, 34.269672447000062],
            [71.313216074000081, 34.270214964000047],
            [71.312730664000071, 34.270643267000025],
            [71.312131040000054, 34.271157231000075],
            [71.311645630000044, 34.271471320000046],
            [71.310874685000044, 34.271956730000056],
            [71.30956122200007, 34.272699122000063],
            [71.308533295000075, 34.27344151300008],
            [71.307219833000033, 34.274155352000037],
            [71.306191906000038, 34.27466931500004],
            [71.305363853000074, 34.275183279000032],
            [71.304592908000075, 34.275725796000074],
            [71.303365106000058, 34.276525295000056],
            [71.302679821000083, 34.277096365000034],
            [71.302080197000066, 34.277695990000041],
            [71.301594787000056, 34.278324167000051],
            [71.301452019000067, 34.278809577000061],
            [71.301423466000074, 34.279352094000046],
            [71.30133780500006, 34.280180147000067],
            [71.301080823000063, 34.281036753000024],
            [71.300909502000081, 34.281722037000065],
            [71.300766735000082, 34.282464429000072],
            [71.300481199000046, 34.283006946000057],
            [71.299995789000036, 34.283834999000078],
            [71.299310504000061, 34.284377516000063],
            [71.29788282800007, 34.285205568000038],
            [71.296854901000074, 34.285776639000062],
            [71.296169616000043, 34.286376263000079],
            [71.295427224000036, 34.287118655000029],
            [71.294884707000051, 34.287746833000028],
            [71.294170869000084, 34.288546332000067],
            [71.293285709000031, 34.289231616000052],
            [71.292371996000043, 34.289888347000044],
            [71.29185803300004, 34.29028809700003],
            [71.291258409000079, 34.290602186000058],
            [71.290715891000048, 34.291087596000068],
            [71.290230481000037, 34.291487345000064],
            [71.28951664300007, 34.292058416000032],
            [71.288859912000078, 34.292458165000028],
            [71.287946199000032, 34.29300068200007],
            [71.287203807000083, 34.293314771000041],
            [71.286318648000076, 34.293600307000077],
            [71.285233613000059, 34.293857288000027],
            [71.284177133000071, 34.294199931000037],
            [71.283149206000076, 34.294485466000026],
            [71.282349707000037, 34.294771001000072],
            [71.281607315000031, 34.295027983000068],
            [71.280979137000031, 34.295656161000068],
            [71.280379513000071, 34.296113017000039],
            [71.279951210000036, 34.296855409000045],
            [71.279465800000082, 34.297540694000077],
            [71.278894729000058, 34.298797049000029],
            [71.278266552000048, 34.29962510200005],
            [71.277552713000034, 34.300710136000077],
            [71.277238625000052, 34.301423974000045],
            [71.276496233000046, 34.302623223000069],
            [71.276067930000067, 34.303337061000036],
            [71.275553966000075, 34.304108006000035],
            [71.274840128000051, 34.304878952000024],
            [71.274126290000083, 34.305535683000073],
            [71.273555219000059, 34.305992539000044],
            [71.272984148000035, 34.306449396000062],
            [71.272441631000049, 34.306820592000065],
            [71.271842007000032, 34.307306002000075],
            [71.271013955000058, 34.307648644000039],
            [71.270271563000051, 34.307819965000078],
            [71.268986654000059, 34.308019840000043],
            [71.268015834000039, 34.308048394000025],
            [71.266873693000036, 34.308162608000032],
            [71.26613130100003, 34.308276822000039],
            [71.265560230000062, 34.308419589000039],
            [71.265046267000059, 34.308648018000042],
            [71.264532303000067, 34.308933553000031],
            [71.264389535000078, 34.309247642000059],
            [71.264332428000046, 34.309761605000062],
            [71.264389535000078, 34.310646765000058],
            [71.264817838000056, 34.311474817000033],
            [71.265303248000066, 34.312302870000053],
            [71.26550312300003, 34.312816833000056],
            [71.265588784000045, 34.313445011000056],
            [71.265474570000038, 34.313987528000041],
            [71.264732178000031, 34.314644259000033],
            [71.264046893000057, 34.314787027000079],
            [71.262847645000079, 34.315101116000051],
            [71.261734057000069, 34.315186776000075],
            [71.260192166000081, 34.315272437000033],
            [71.258621722000044, 34.315529419000029],
            [71.257051278000063, 34.315557972000079],
            [71.25556649400005, 34.315643633000036],
            [71.254053157000044, 34.315814954000075],
            [71.252825355000084, 34.315957722000064],
            [71.251740321000057, 34.316271811000036],
            [71.250683840000079, 34.316643007000039],
            [71.250141323000037, 34.317014202000053],
            [71.249713020000058, 34.317528166000045],
            [71.249113396000041, 34.318042130000038],
            [71.248570879000056, 34.318670307000048],
            [71.248142576000077, 34.319498360000068],
            [71.247485845000028, 34.320640501000071],
            [71.246943328000043, 34.321468553000045],
            [71.246315150000044, 34.322296606000066],
            [71.245715526000083, 34.32312465800004],
            [71.24500168700007, 34.324152585000036],
            [71.244202189000077, 34.325323280000077],
            [71.243431243000032, 34.326236993000066],
            [71.242888726000047, 34.326979385000072],
            [71.242231995000054, 34.327835991000029],
            [71.24166092400003, 34.328492722000078],
            [71.241061300000069, 34.329006686000071],
            [71.240404569000077, 34.32929222100006],
            [71.239576516000056, 34.329349328000035],
            [71.238520036000068, 34.329206560000046],
            [71.237206573000037, 34.328863918000025],
            [71.235636129000056, 34.328435615000046],
            [71.23440832700004, 34.328150080000057],
            [71.233665935000033, 34.328064419000043],
            [71.232181152000067, 34.327493349000065],
            [71.231238885000039, 34.32720781300003],
            [71.230153851000068, 34.32692227800004],
            [71.229440013000044, 34.326779510000051],
            [71.22872617400003, 34.326750957000058],
            [71.227783908000049, 34.326808064000033],
            [71.226984409000067, 34.326865171000065],
            [71.226156357000036, 34.327093599000079],
            [71.22555673200003, 34.327379134000068],
            [71.224871448000044, 34.327835991000029],
            [71.22418616300007, 34.328207187000032],
            [71.223358111000039, 34.328778257000067],
            [71.222701379000057, 34.329320775000042],
            [71.22195898700005, 34.329749078000077],
            [71.221530685000062, 34.330034613000066],
            [71.220674079000048, 34.330634237000027],
            [71.220131561000073, 34.330777005000073],
            [71.219474830000081, 34.33100543300003],
            [71.218418350000036, 34.331119647000037],
            [71.21693356600008, 34.331205308000051],
            [71.214820605000057, 34.331148201000076],
            [71.212507769000069, 34.331062540000062],
            [71.21076600300006, 34.330862665000041],
            [71.210390339000071, 34.330829190000031],
            [71.207882096000048, 34.330605683000044],
            [71.205569260000061, 34.330491469000037],
            [71.202456925000035, 34.330462916000045],
            [71.200629499000058, 34.330348702000038],
            [71.199059055000077, 34.330291595000062],
            [71.197374397000033, 34.330434362000062],
            [71.196317916000055, 34.330634237000027],
            [71.195518417000073, 34.330834112000048],
            [71.19466181100006, 34.331148201000076],
            [71.193748098000071, 34.331547950000072],
            [71.192548850000037, 34.332204681000064],
            [71.191463816000066, 34.332775752000032],
            [71.190264567000042, 34.33357525100007],
            [71.189550729000075, 34.334060661000024],
            [71.188751230000037, 34.33480305300003],
            [71.187951731000055, 34.335459784000079],
            [71.186895251000067, 34.336630479000064],
            [71.186209966000035, 34.337230103000024],
            [71.185553235000043, 34.337744066000027],
            [71.184668075000047, 34.338372244000027],
            [71.183440273000031, 34.338971868000044],
            [71.182012597000039, 34.339400171000079],
            [71.180527813000083, 34.339685706000068],
            [71.179614100000038, 34.339685706000068],
            [71.178329191000046, 34.339714260000051],
            [71.176730194000072, 34.339600046000044],
            [71.175388178000048, 34.33937161800003],
            [71.174274590000039, 34.338943315000051],
            [71.173161002000029, 34.33862922600008],
            [71.17308414200005, 34.338576015000058],
            [71.174906000000078, 34.353756000000033],
            [71.169948088000069, 34.362184149000029],
            [71.169004047000044, 34.363788960000079],
            [71.168354000000079, 34.364894000000049],
            [71.148783000000037, 34.372569000000055],
            [71.130556285000068, 34.375786241000071],
            [71.130484000000081, 34.375799000000029],
            [71.130372458000068, 34.375835180000024],
            [71.11057100000005, 34.382258000000036],
            [71.094963000000064, 34.387102000000027],
            [71.085208609000063, 34.390354039000044],
            [71.083661000000063, 34.390870000000064],
            [71.080993093000075, 34.392838950000055],
            [71.075080551000042, 34.397202481000079],
            [71.072691000000077, 34.39896600000003],
            [71.065380907000076, 34.412006574000031],
            [71.054601000000048, 34.431237000000067],
            [71.039531000000068, 34.439310000000035],
            [71.025539000000038, 34.450613000000033],
            [71.012622000000079, 34.45922500000006],
            [71.003238000000067, 34.471695000000068],
            [71.001488797000036, 34.474668806000068],
            [70.996686000000068, 34.482834000000025],
            [70.994065000000035, 34.492007000000058],
            [70.992099000000053, 34.506422000000043],
            [70.993266008000035, 34.520034922000036],
            [70.994065000000035, 34.529355000000066],
            [70.994065000000035, 34.53987810700005],
            [70.994065000000035, 34.542459000000065],
            [70.994401645000039, 34.543235648000064],
            [71.00347800000003, 34.564175000000034],
            [71.022854000000052, 34.572194000000025],
            [71.041153000000065, 34.57542200000006],
            [71.061604000000045, 34.57542200000006],
            [71.076674000000082, 34.579727000000048],
            [71.089591000000041, 34.586185000000057],
            [71.09423253600005, 34.601166066000076],
            [71.094314000000054, 34.601429000000053],
            [71.098083754000072, 34.606938471000035],
            [71.102832000000035, 34.613878000000057],
            [71.103185690000032, 34.614585516000034],
            [71.108073000000047, 34.624362000000076],
            [71.104797000000076, 34.640087000000051],
            [71.096279000000038, 34.653191000000049],
            [71.096279000000038, 34.66006972200006],
            [71.096279000000038, 34.670227000000068],
            [71.098529605000067, 34.681114769000033],
            [71.098624739000059, 34.681575000000066],
            [71.098864445000061, 34.682734628000048],
            [71.099822000000074, 34.687367000000052],
            [71.105205000000069, 34.706742000000077],
            [71.113185232000035, 34.70864165200004],
            [71.119870220000053, 34.710232978000079],
            [71.127810000000068, 34.712123000000076],
            [71.142437055000073, 34.714509095000039],
            [71.144110000000069, 34.714782000000071],
            [71.155796000000066, 34.72073400000005],
            [71.172724839000068, 34.730861010000069],
            [71.172818000000063, 34.730916740000055],
            [71.173809023000047, 34.728997901000071],
            [71.17526812300008, 34.726912389000063],
            [71.176924228000075, 34.72417125000004],
            [71.178094922000071, 34.722058289000074],
            [71.178665993000038, 34.720773380000026],
            [71.179608260000066, 34.718945954000048],
            [71.180921722000051, 34.716832992000036],
            [71.181863989000078, 34.715919279000047],
            [71.18329166500007, 34.714805692000027],
            [71.184833556000058, 34.71360644300006],
            [71.187032178000038, 34.711836124000058],
            [71.189059479000036, 34.71029423400006],
            [71.190829798000038, 34.709037878000061],
            [71.192343135000044, 34.708181272000047],
            [71.194170561000078, 34.707324666000034],
            [71.195826666000073, 34.706810703000031],
            [71.197340003000079, 34.706582275000073],
            [71.199367303000031, 34.706239632000063],
            [71.200794980000069, 34.706153972000038],
            [71.203222030000063, 34.705954097000074],
            [71.204249957000059, 34.705925543000035],
            [71.20567763400004, 34.705554347000032],
            [71.207219525000085, 34.704983277000053],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "52",
      properties: { name: "North Waziristan Agency", count: 472 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.443246191000071, 33.35213724700003],
            [70.444852328000081, 33.352065863000064],
            [70.445744625000032, 33.35213724700003],
            [70.44688676700008, 33.352422782000076],
            [70.447743373000037, 33.352422782000076],
            [70.448707054000067, 33.352422782000076],
            [70.450919953000039, 33.351708944000052],
            [70.451990710000075, 33.351316333000057],
            [70.452990084000078, 33.350745262000032],
            [70.453703922000045, 33.350495419000026],
            [70.455024523000077, 33.349960040000042],
            [70.455524210000078, 33.34963881300007],
            [70.45584543700005, 33.348889283000062],
            [70.455952513000057, 33.348282520000055],
            [70.456737735000047, 33.346640692000051],
            [70.456666351000081, 33.34517732300003],
            [70.456095281000046, 33.344035182000027],
            [70.455809745000067, 33.343499804000032],
            [70.455631286000084, 33.342964425000048],
            [70.455631286000084, 33.342286278000074],
            [70.455631286000084, 33.34157244000005],
            [70.45598820500004, 33.340894294000066],
            [70.456238048000046, 33.34046599100003],
            [70.456630659000041, 33.340216147000035],
            [70.457308806000071, 33.340037688000052],
            [70.458022644000039, 33.339966304000029],
            [70.45870079000008, 33.339787844000057],
            [70.459593088000076, 33.33968076900004],
            [70.460021391000055, 33.339466617000028],
            [70.459878624000055, 33.339002622000066],
            [70.459271861000047, 33.338003249000053],
            [70.458807866000029, 33.337539254000035],
            [70.45852233100004, 33.337110951000057],
            [70.45852233100004, 33.336718340000061],
            [70.458593715000063, 33.336147269000037],
            [70.458914942000035, 33.33543343100007],
            [70.460128467000061, 33.334576825000056],
            [70.462127214000077, 33.333220532000041],
            [70.46312658800008, 33.333113456000035],
            [70.464161653000076, 33.333006381000075],
            [70.464946875000066, 33.333006381000075],
            [70.465446562000068, 33.333113456000035],
            [70.466374552000048, 33.333684527000059],
            [70.466981314000066, 33.333898679000072],
            [70.467552385000033, 33.333970062000049],
            [70.468230531000074, 33.334112830000038],
            [70.469194213000037, 33.334184214000061],
            [70.470264971000063, 33.334326981000061],
            [70.471085885000036, 33.334648209000079],
            [70.471799723000061, 33.33511220400004],
            [70.472692021000057, 33.335576198000069],
            [70.474369541000044, 33.336504188000049],
            [70.47558306600007, 33.336968183000067],
            [70.476653823000049, 33.337289410000039],
            [70.477903040000058, 33.337574946000075],
            [70.478902414000061, 33.337860481000064],
            [70.479580560000045, 33.338038941000036],
            [70.480472858000041, 33.338074633000076],
            [70.481365156000038, 33.338074633000076],
            [70.482364530000041, 33.338074633000076],
            [70.483827898000072, 33.338003249000053],
            [70.48457742800008, 33.337824789000024],
            [70.485112807000064, 33.337396486000046],
            [70.485683878000032, 33.337075259000073],
            [70.486433408000039, 33.335754658000042],
            [70.486718943000028, 33.334969436000051],
            [70.487040170000057, 33.334398365000027],
            [70.487361398000075, 33.333862987000032],
            [70.487789701000054, 33.333613143000036],
            [70.488432155000055, 33.333434684000053],
            [70.489360145000035, 33.333434684000053],
            [70.491394584000034, 33.333327608000047],
            [70.493143488000044, 33.333327608000047],
            [70.494357013000069, 33.33336330000003],
            [70.495463462000032, 33.33354175900007],
            [70.49607022500004, 33.333791603000066],
            [70.497105290000036, 33.334505441000033],
            [70.49781912900005, 33.334826668000062],
            [70.498318815000061, 33.334862360000045],
            [70.498854194000046, 33.334898052000028],
            [70.499675108000076, 33.334898052000028],
            [70.500424638000084, 33.334862360000045],
            [70.50113847700004, 33.334826668000062],
            [70.501852315000065, 33.334898052000028],
            [70.502637537000055, 33.335147896000024],
            [70.50413659700007, 33.335790350000025],
            [70.505814117000057, 33.336254345000043],
            [70.507455945000061, 33.33700387500005],
            [70.510810985000035, 33.338324476000025],
            [70.514879864000079, 33.33968076900004],
            [70.517199838000067, 33.34046599100003],
            [70.520340727000075, 33.341393981000067],
            [70.523017620000076, 33.342036435000068],
            [70.525159135000081, 33.342857349000042],
            [70.526444044000073, 33.343392728000026],
            [70.527300650000029, 33.343856723000044],
            [70.528228640000066, 33.344392101000039],
            [70.529013862000056, 33.344534869000029],
            [70.530013235000069, 33.344749021000041],
            [70.531405220000067, 33.344749021000041],
            [70.533903654000028, 33.34503455600003],
            [70.535616866000055, 33.345391475000042],
            [70.537579921000031, 33.346069621000026],
            [70.538936214000046, 33.346890535000057],
            [70.539935588000048, 33.34788990900006],
            [70.540613734000033, 33.348318212000038],
            [70.540756502000079, 33.348389596000061],
            [70.540960761000065, 33.348389596000061],
            [70.54283270500008, 33.348334879000049],
            [70.541826000000071, 33.333735000000047],
            [70.551766000000043, 33.332410000000039],
            [70.556424780000043, 33.326441423000063],
            [70.556404000000043, 33.321119123000074],
            [70.556404000000043, 33.321109249000074],
            [70.556404000000043, 33.32108660800003],
            [70.556404000000043, 33.309880000000078],
            [70.556518519000065, 33.309812630000067],
            [70.56014678300005, 33.307678187000079],
            [70.56766900000008, 33.303253000000041],
            [70.571645000000046, 33.290663000000052],
            [70.580259000000069, 33.283374000000038],
            [70.586468948000061, 33.281599817000028],
            [70.598813000000064, 33.278073000000063],
            [70.615379000000075, 33.27409700000004],
            [70.625445100000036, 33.267209837000053],
            [70.627969000000064, 33.265483000000074],
            [70.627969000000064, 33.264453056000036],
            [70.627969000000064, 33.261118452000062],
            [70.626913831000081, 33.260355471000025],
            [70.626491671000053, 33.26000367100005],
            [70.625806386000079, 33.258918637000079],
            [70.625063995000062, 33.257976371000041],
            [70.624635692000084, 33.257605175000037],
            [70.624207389000048, 33.257091211000045],
            [70.623721979000038, 33.256662908000067],
            [70.623493550000035, 33.256463033000045],
            [70.623407890000067, 33.256120391000024],
            [70.623407890000067, 33.255663534000064],
            [70.623464997000042, 33.255206678000036],
            [70.623721979000038, 33.254464286000029],
            [70.623807639000063, 33.254150197000058],
            [70.624007514000084, 33.253636234000055],
            [70.624007514000084, 33.253207931000077],
            [70.62392185300007, 33.252751074000059],
            [70.623693425000056, 33.252294218000031],
            [70.623208015000046, 33.251837361000071],
            [70.622636944000078, 33.251409058000036],
            [70.622237195000082, 33.251094969000064],
            [70.621923106000054, 33.250523899000029],
            [70.621894553000061, 33.250038489000076],
            [70.621837446000029, 33.249495972000034],
            [70.621780338000065, 33.248810687000059],
            [70.621551910000051, 33.247325903000046],
            [70.621494803000076, 33.245926780000048],
            [70.621095054000079, 33.243756712000049],
            [70.62043832300003, 33.241072680000059],
            [70.619952912000031, 33.238902611000071],
            [70.619524610000042, 33.236761097000056],
            [70.619381842000053, 33.235647509000046],
            [70.619153414000039, 33.234162725000033],
            [70.619010646000049, 33.233277566000027],
            [70.61886787800006, 33.232535174000077],
            [70.618753664000053, 33.231992657000035],
            [70.618668004000028, 33.231564354000056],
            [70.618296808000082, 33.231278819000067],
            [70.617925612000079, 33.231164604000071],
            [70.616497935000041, 33.230707748000043],
            [70.613928117000057, 33.230051017000051],
            [70.61050169400005, 33.229365732000076],
            [70.608931249000079, 33.228994536000073],
            [70.607903322000084, 33.228566233000038],
            [70.606961056000046, 33.228337805000024],
            [70.606247217000032, 33.227966609000077],
            [70.605704700000047, 33.227738181000063],
            [70.604448345000037, 33.227081449000025],
            [70.603477525000073, 33.226567486000079],
            [70.602849347000074, 33.226310504000026],
            [70.602535258000046, 33.226053522000029],
            [70.601992741000061, 33.226024969000036],
            [70.601164689000029, 33.225996415000054],
            [70.600336636000065, 33.225996415000054],
            [70.599708459000055, 33.225996415000054],
            [70.599194495000063, 33.225825094000072],
            [70.598423550000064, 33.225339684000062],
            [70.597195748000047, 33.224711506000062],
            [70.59596794600003, 33.224197543000059],
            [70.594169073000046, 33.223854900000049],
            [70.592969825000068, 33.223455151000053],
            [70.592255987000044, 33.223169616000064],
            [70.591856237000059, 33.222969741000043],
            [70.59157070200007, 33.222627098000032],
            [70.591513595000038, 33.222341563000043],
            [70.591370827000048, 33.222027474000072],
            [70.591228060000049, 33.221656278000069],
            [70.590856864000045, 33.221285083000055],
            [70.590628436000031, 33.220999547000076],
            [70.590257240000028, 33.220942440000044],
            [70.588658242000065, 33.220457030000034],
            [70.587516101000062, 33.220171495000045],
            [70.586659495000049, 33.220142941000063],
            [70.586059871000032, 33.220028727000056],
            [70.585517353000057, 33.219714638000028],
            [70.584346659000062, 33.219057907000035],
            [70.583461499000066, 33.218601051000064],
            [70.582747661000042, 33.218087087000072],
            [70.581491305000043, 33.217687338000076],
            [70.580491932000029, 33.217287588000033],
            [70.579549665000059, 33.217116267000051],
            [70.579035702000056, 33.217116267000051],
            [70.578464631000031, 33.216944946000069],
            [70.578093435000028, 33.216630857000041],
            [70.577807900000039, 33.21631676800007],
            [70.577750793000064, 33.215974126000049],
            [70.577636579000057, 33.215574376000063],
            [70.577465258000075, 33.215117520000035],
            [70.577151169000047, 33.214632110000025],
            [70.576637205000054, 33.214289467000071],
            [70.576094688000069, 33.214118146000033],
            [70.575152421000041, 33.214032485000075],
            [70.573296442000071, 33.213832611000043],
            [70.571640337000076, 33.213518522000072],
            [70.569812911000042, 33.213318647000051],
            [70.567671396000037, 33.212633362000076],
            [70.565187239000068, 33.211633989000063],
            [70.562560314000052, 33.210606062000068],
            [70.560561567000036, 33.209720902000072],
            [70.558791248000034, 33.208607315000052],
            [70.556906715000082, 33.207036870000024],
            [70.555650359000083, 33.206008943000029],
            [70.553994255000077, 33.205266551000079],
            [70.551881293000065, 33.204152964000059],
            [70.54973977800006, 33.202839501000028],
            [70.547655371000076, 33.201411825000037],
            [70.546341908000045, 33.200726540000062],
            [70.545199767000042, 33.200298237000027],
            [70.543629323000062, 33.199869934000048],
            [70.542087432000073, 33.199698613000066],
            [70.540945291000071, 33.199555845000077],
            [70.540231452000057, 33.199384524000038],
            [70.53963182800004, 33.198927667000078],
            [70.538004277000084, 33.197671312000068],
            [70.535577227000033, 33.196300742000062],
            [70.533806908000031, 33.195415583000056],
            [70.531608286000051, 33.194587531000025],
            [70.529038468000067, 33.193788032000043],
            [70.526982614000076, 33.192988533000062],
            [70.525098081000067, 33.192531676000044],
            [70.523499083000047, 33.192388909000044],
            [70.521329015000049, 33.192103373000066],
            [70.520129766000082, 33.19184639100007],
            [70.519558696000047, 33.191617963000056],
            [70.518987625000079, 33.191246767000052],
            [70.518702090000033, 33.190647143000035],
            [70.518416554000055, 33.190133180000032],
            [70.517988251000077, 33.189105252000047],
            [70.517674163000038, 33.188048772000059],
            [70.517302967000035, 33.186992291000024],
            [70.517045985000038, 33.186506881000071],
            [70.516674789000035, 33.185793043000047],
            [70.51613227200005, 33.185450400000036],
            [70.514818809000076, 33.184622348000062],
            [70.514162078000084, 33.184251152000058],
            [70.513562454000066, 33.183851403000062],
            [70.51330547200007, 33.183423100000027],
            [70.513191258000063, 33.182909136000035],
            [70.513019937000081, 33.181795548000025],
            [70.512791509000067, 33.181138817000033],
            [70.512505973000032, 33.180567747000055],
            [70.511820689000047, 33.179911015000073],
            [70.51073565400003, 33.178997302000028],
            [70.509222317000081, 33.177997929000071],
            [70.506938035000076, 33.176513145000058],
            [70.505853000000059, 33.17559943200007],
            [70.504339663000053, 33.174257416000046],
            [70.502740666000079, 33.172629865000033],
            [70.501569971000038, 33.171145081000077],
            [70.499628331000054, 33.169546083000057],
            [70.498686064000083, 33.168746584000075],
            [70.497943672000076, 33.167918532000044],
            [70.497315495000066, 33.167033373000038],
            [70.49643033500007, 33.165520035000043],
            [70.495630836000032, 33.164235126000051],
            [70.494688570000051, 33.163035878000073],
            [70.493517875000066, 33.161465434000036],
            [70.492432841000038, 33.160123418000069],
            [70.491262146000054, 33.158524420000049],
            [70.489748809000048, 33.156782655000029],
            [70.488378239000042, 33.155155103000027],
            [70.487321758000064, 33.153984409000032],
            [70.48646515300004, 33.152899374000071],
            [70.486379492000083, 33.152499625000075],
            [70.486208171000044, 33.152185536000047],
            [70.48621147700004, 33.152150822000067],
            [70.486265278000076, 33.15158591200003],
            [70.48660792000004, 33.151243270000066],
            [70.487293205000071, 33.150729306000073],
            [70.488178364000078, 33.150101128000074],
            [70.488977863000059, 33.14938729000005],
            [70.489349059000062, 33.148473577000061],
            [70.48992013000003, 33.146960240000055],
            [70.490091451000069, 33.145903759000078],
            [70.490519754000047, 33.143876459000069],
            [70.490519754000047, 33.142934192000041],
            [70.490519754000047, 33.141449408000028],
            [70.490462647000072, 33.13976475000004],
            [70.490491200000065, 33.137109272000032],
            [70.490376986000058, 33.135310399000048],
            [70.490177112000083, 33.13411115100007],
            [70.489891576000048, 33.133397312000056],
            [70.489813860000083, 33.132950445000063],
            [70.48971655400004, 33.132814216000043],
            [70.489488125000037, 33.132614341000078],
            [70.489288251000062, 33.132271699000057],
            [70.489017280000041, 33.132054922000066],
            [70.489015834000043, 33.132055812000033],
            [70.489059822000058, 33.131986164000068],
            [70.48923114400003, 33.13107245100008],
            [70.489202590000048, 33.130244398000059],
            [70.489031269000066, 33.12947345300006],
            [70.489031269000066, 33.129045150000024],
            [70.489088376000041, 33.128502633000039],
            [70.489516679000076, 33.127903009000079],
            [70.490230517000043, 33.127360492000037],
            [70.491172784000071, 33.12710351000004],
            [70.492343479000056, 33.126789421000069],
            [70.493885369000054, 33.126589546000048],
            [70.494799082000043, 33.126361118000034],
            [70.495370153000067, 33.126361118000034],
            [70.496255312000073, 33.126275457000077],
            [70.497083365000037, 33.12616124300007],
            [70.497625882000079, 33.126075583000045],
            [70.498196953000047, 33.125989922000031],
            [70.498682363000057, 33.125704387000042],
            [70.498682363000057, 33.125333191000038],
            [70.498482488000036, 33.124876334000078],
            [70.497911417000068, 33.124305264000043],
            [70.496797830000048, 33.123334444000079],
            [70.495827009000038, 33.122477838000066],
            [70.493942476000029, 33.12110726800006],
            [70.492771782000034, 33.120136448000039],
            [70.491515426000035, 33.11936550300004],
            [70.490487499000039, 33.118566004000058],
            [70.48911693000008, 33.117709398000045],
            [70.487689253000042, 33.116824238000049],
            [70.48640434400005, 33.115996186000075],
            [70.485462078000069, 33.115596437000079],
            [70.484576918000073, 33.115482223000072],
            [70.483491884000046, 33.115225241000076],
            [70.482406850000075, 33.115225241000076],
            [70.481264708000083, 33.115253794000068],
            [70.479922692000059, 33.11542511500005],
            [70.479094640000028, 33.11556788300004],
            [70.478095266000082, 33.115767758000061],
            [70.476667590000034, 33.116138954000064],
            [70.47521136000006, 33.116310275000046],
            [70.47409777200005, 33.116338828000039],
            [70.473098398000047, 33.116338828000039],
            [70.472184685000059, 33.116310275000046],
            [70.47141374000006, 33.116167507000057],
            [70.470014617000061, 33.115824865000036],
            [70.468701155000076, 33.115368008000075],
            [70.467644674000042, 33.115025366000054],
            [70.466502533000039, 33.114511402000062],
            [70.465388945000029, 33.114025992000052],
            [70.464361018000034, 33.113255047000052],
            [70.463447305000045, 33.112312781000071],
            [70.462733466000032, 33.111713156000064],
            [70.462248056000078, 33.111313407000068],
            [70.461876860000075, 33.111084979000054],
            [70.461391450000065, 33.110685229000069],
            [70.460991701000069, 33.110371140000041],
            [70.460534844000051, 33.110342587000048],
            [70.459821006000084, 33.110256926000034],
            [70.459164275000035, 33.110342587000048],
            [70.458764526000039, 33.110371140000041],
            [70.458279115000039, 33.110371140000041],
            [70.457822259000068, 33.110114159000034],
            [70.457336849000058, 33.109828623000055],
            [70.456937100000061, 33.109543088000066],
            [70.456337475000055, 33.108772143000067],
            [70.455595084000038, 33.107772769000064],
            [70.455081120000045, 33.107144591000065],
            [70.454567156000053, 33.106259432000058],
            [70.454167407000057, 33.105716915000073],
            [70.453739104000078, 33.105117291000056],
            [70.453310801000043, 33.104631881000046],
            [70.452882498000065, 33.104146470000046],
            [70.451911678000045, 33.10374672100005],
            [70.451169286000038, 33.103489739000054],
            [70.450484001000063, 33.103175650000026],
            [70.45011280500006, 33.102804455000069],
            [70.449855824000053, 33.102404705000026],
            [70.44962739500005, 33.101862188000041],
            [70.449199092000072, 33.100662940000063],
            [70.448428147000072, 33.09917815600005],
            [70.447628648000034, 33.097807586000044],
            [70.447228899000038, 33.096893873000056],
            [70.446543614000063, 33.095751732000053],
            [70.445744115000082, 33.094838019000065],
            [70.44383102900008, 33.092667951000067],
            [70.441718067000068, 33.090612097000076],
            [70.440518819000033, 33.089155866000056],
            [70.439034035000077, 33.08764252900005],
            [70.437549252000053, 33.085986424000055],
            [70.435864593000076, 33.084158998000078],
            [70.434379810000053, 33.08258855400004],
            [70.432780812000033, 33.080903896000052],
            [70.429782691000071, 33.078219864000062],
            [70.426470481000081, 33.07533595700005],
            [70.424814377000075, 33.073794066000062],
            [70.423757896000041, 33.072823246000041],
            [70.422787076000077, 33.071938087000035],
            [70.421930470000063, 33.070995820000064],
            [70.421416506000071, 33.070196321000026],
            [70.420988203000036, 33.069453930000066],
            [70.420645561000072, 33.068454556000063],
            [70.419931723000047, 33.066769898000075],
            [70.418075743000031, 33.063314920000039],
            [70.416676620000032, 33.060545228000024],
            [70.415477372000055, 33.05860358800004],
            [70.41493485500007, 33.057547107000062],
            [70.414706426000066, 33.056747608000023],
            [70.414392338000084, 33.055034396000053],
            [70.414278123000031, 33.053264077000051],
            [70.414221016000056, 33.050808474000064],
            [70.414392338000084, 33.048609852000027],
            [70.414363784000045, 33.047296389000053],
            [70.414249570000038, 33.045040660000041],
            [70.413992588000042, 33.043641537000042],
            [70.413507178000032, 33.04221386100005],
            [70.412536358000068, 33.040300774000059],
            [70.411765413000069, 33.038958758000035],
            [70.411308556000051, 33.038102152000079],
            [70.411137235000069, 33.037159885000051],
            [70.41110868100003, 33.036645922000048],
            [70.41110868100003, 33.036046298000031],
            [70.411137235000069, 33.035503781000045],
            [70.411165788000062, 33.034761389000039],
            [70.411422770000058, 33.033933336000075],
            [70.412050948000058, 33.033190945000058],
            [70.412650572000075, 33.032334339000045],
            [70.413107429000036, 33.031677607000063],
            [70.413478625000039, 33.030906662000064],
            [70.413764160000028, 33.030278484000064],
            [70.413764160000028, 33.029735967000079],
            [70.413764160000028, 33.029107790000069],
            [70.413421517000074, 33.02845105800003],
            [70.412764786000082, 33.027451685000074],
            [70.411851073000037, 33.026052562000075],
            [70.410908807000055, 33.025053188000072],
            [70.410423397000045, 33.024282243000073],
            [70.410137861000067, 33.023397083000077],
            [70.409566791000032, 33.022283496000057],
            [70.409138488000053, 33.020684498000037],
            [70.408653078000043, 33.019085500000074],
            [70.408367542000065, 33.017543609000029],
            [70.408310435000033, 33.01614448600003],
            [70.408292326000037, 33.01465952500007],
            [70.40828188200004, 33.01380309700005],
            [70.408481756000072, 33.012289760000044],
            [70.40891005900005, 33.010662208000042],
            [70.409366916000067, 33.008235158000048],
            [70.409795219000046, 33.005979429000035],
            [70.410194968000042, 33.003209736000031],
            [70.410337736000031, 33.001239543000054],
            [70.410296708000033, 33.00077322900006],
            [70.410177734000058, 33.000440104000063],
            [70.409725637000065, 33.000154569000074],
            [70.409178361000045, 33.000059390000047],
            [70.408607290000077, 32.999821444000077],
            [70.40813139800008, 32.999631087000068],
            [70.407512738000037, 32.999155195000071],
            [70.407108230000063, 32.998798276000059],
            [70.406679927000084, 32.998441357000047],
            [70.406037472000037, 32.997941670000046],
            [70.405442607000055, 32.997537162000071],
            [70.404895331000034, 32.997156448000055],
            [70.404419439000037, 32.996823323000058],
            [70.40384836800007, 32.996299842000042],
            [70.403610422000042, 32.995704977000059],
            [70.403539038000076, 32.994919755000069],
            [70.403420065000034, 32.994420068000068],
            [70.403086941000083, 32.994039354000051],
            [70.402801406000037, 32.993682435000039],
            [70.402420692000078, 32.993254132000061],
            [70.401873416000058, 32.993039980000049],
            [70.40099301500004, 32.992825829000026],
            [70.400374355000054, 32.992540294000037],
            [70.399636722000082, 32.992159580000077],
            [70.399303598000074, 32.992040607000035],
            [70.39849458100008, 32.99192163400005],
            [70.39780453700007, 32.99192163400005],
            [70.397328645000073, 32.991683688000023],
            [70.396947932000046, 32.991445742000053],
            [70.396091326000032, 32.991017439000075],
            [70.39509195200003, 32.990279806000046],
            [70.394616060000033, 32.989851503000068],
            [70.394068784000069, 32.989351816000067],
            [70.39361668600003, 32.988661772000057],
            [70.393212178000056, 32.988209675000064],
            [70.392783875000077, 32.987543426000059],
            [70.392498340000031, 32.98694856000003],
            [70.392284188000076, 32.98656784700006],
            [70.391951064000068, 32.985687446000043],
            [70.391522761000033, 32.985116375000075],
            [70.391332404000082, 32.984497716000078],
            [70.391094458000055, 32.984188386000028],
            [70.390927895000061, 32.983926645000054],
            [70.390785128000061, 32.983569726000042],
            [70.390594771000053, 32.98307003900004],
            [70.390594771000053, 32.982594147000043],
            [70.390594771000053, 32.982237228000031],
            [70.390547182000034, 32.981713746000025],
            [70.390547182000034, 32.981333033000055],
            [70.390547182000034, 32.980738167000027],
            [70.390499592000083, 32.980000534000055],
            [70.390499592000083, 32.979596026000024],
            [70.390499592000083, 32.979120134000027],
            [70.390499592000083, 32.978501474000041],
            [70.390689949000034, 32.977787636000073],
            [70.391023074000032, 32.977216565000049],
            [70.391689323000037, 32.976693084000033],
            [70.392260393000072, 32.976359959000035],
            [70.392831464000039, 32.975860272000034],
            [70.395353693000061, 32.974289828000053],
            [70.39632927200006, 32.974051882000026],
            [70.39701931500008, 32.973885320000079],
            [70.397709359000032, 32.973623579000048],
            [70.398113867000063, 32.97343322200004],
            [70.398423197000056, 32.973171481000065],
            [70.399256009000055, 32.972885946000076],
            [70.399922258000061, 32.972790768000038],
            [70.400612301000081, 32.972648000000049],
            [70.401207167000052, 32.97260041100003],
            [70.401849621000054, 32.972529027000064],
            [70.402349308000055, 32.972433849000026],
            [70.403158325000049, 32.972124519000033],
            [70.403800779000051, 32.971743805000074],
            [70.404276671000048, 32.971410680000076],
            [70.404919126000038, 32.971077556000068],
            [70.405513991000078, 32.970934788000079],
            [70.406441981000057, 32.970720637000056],
            [70.407060641000044, 32.970411307000063],
            [70.407417560000056, 32.970030593000047],
            [70.407417560000056, 32.969697468000049],
            [70.407298587000071, 32.969483317000027],
            [70.40689407800005, 32.969031219000044],
            [70.406537159000038, 32.968436354000062],
            [70.405537786000082, 32.967508364000025],
            [70.405133277000061, 32.967056267000032],
            [70.404609796000045, 32.966390018000027],
            [70.403348682000058, 32.965414439000028],
            [70.402634843000044, 32.96474819000008],
            [70.401587880000079, 32.963867789000062],
            [70.399684312000034, 32.962416318000066],
            [70.397495208000066, 32.960726901000044],
            [70.395758201000035, 32.959465786000067],
            [70.393735659000072, 32.957728780000025],
            [70.392260393000072, 32.956372487000067],
            [70.390713744000038, 32.954825837000044],
            [70.389190889000076, 32.953017447000036],
            [70.387096963000033, 32.951137673000062],
            [70.384408172000064, 32.948853390000068],
            [70.382314247000068, 32.946926027000075],
            [70.37995858000005, 32.944356209000034],
            [70.377983628000038, 32.942000543000063],
            [70.375961086000075, 32.939359341000056],
            [70.374937918000057, 32.937717513000052],
            [70.374152696000067, 32.936385015000042],
            [70.373914749000051, 32.936004301000025],
            [70.373914749000051, 32.935504614000024],
            [70.37381957100007, 32.935195284000031],
            [70.373543254000083, 32.934897712000065],
            [70.373557882000057, 32.93487170700007],
            [70.373709899000062, 32.934719689000076],
            [70.373861916000067, 32.934437371000058],
            [70.374231102000067, 32.93419848700006],
            [70.374535136000077, 32.934046470000055],
            [70.37457857000004, 32.933829302000049],
            [70.374491703000047, 32.933590418000051],
            [70.374013934000061, 32.933025782000072],
            [70.37368818200008, 32.932721747000073],
            [70.373427581000044, 32.932504579000067],
            [70.37271092800006, 32.93191822600005],
            [70.372363459000042, 32.931353590000072],
            [70.371798824000052, 32.930680371000051],
            [70.37140792200006, 32.930137451000064],
            [70.37006148200004, 32.928291526000066],
            [70.368389291000028, 32.926554185000043],
            [70.367346886000064, 32.925012294000055],
            [70.365457527000046, 32.922949201000051],
            [70.364067654000053, 32.921363877000033],
            [70.36237374600006, 32.919843704000073],
            [70.361157607000052, 32.918866449000063],
            [70.360310653000056, 32.917910911000035],
            [70.359854601000052, 32.917389709000076],
            [70.359224815000061, 32.916369021000037],
            [70.358660179000083, 32.915435200000047],
            [70.357574341000031, 32.913458974000037],
            [70.356857687000058, 32.911960517000068],
            [70.356336485000043, 32.910679228000049],
            [70.356119317000037, 32.909680256000058],
            [70.356054167000082, 32.908507551000071],
            [70.355967300000032, 32.906661626000073],
            [70.355793566000045, 32.904772267000055],
            [70.355532965000066, 32.902774324000063],
            [70.355228930000067, 32.899972861000037],
            [70.355250647000048, 32.89812693600004],
            [70.355076912000072, 32.896367878000035],
            [70.355011762000061, 32.894044184000052],
            [70.354838028000074, 32.892176542000072],
            [70.354686011000069, 32.891025553000077],
            [70.354577427000038, 32.889483662000032],
            [70.354403693000052, 32.888636708000035],
            [70.353860773000065, 32.88624786400004],
            [70.353165837000063, 32.88368528500007],
            [70.352514334000034, 32.881079273000068],
            [70.352079998000079, 32.879624250000063],
            [70.351363345000038, 32.877387423000073],
            [70.351059310000039, 32.876106134000054],
            [70.350690125000028, 32.874694544000079],
            [70.350234073000081, 32.873695572000031],
            [70.34982145500004, 32.872370849000049],
            [70.349126518000048, 32.869829988000049],
            [70.34893106700008, 32.86859213200006],
            [70.348757333000037, 32.865443201000062],
            [70.348605316000032, 32.863119506000032],
            [70.348170980000077, 32.859427656000037],
            [70.348018963000072, 32.85697366100004],
            [70.347910379000041, 32.85567065500004],
            [70.347780078000028, 32.855127736000043],
            [70.347476044000075, 32.853607562000036],
            [70.347063425000044, 32.852738892000048],
            [70.346781107000083, 32.85187022100007],
            [70.346515215000068, 32.851034560000073],
            [70.346498789000066, 32.851023267000073],
            [70.346477072000084, 32.851012408000031],
            [70.346477072000084, 32.850979833000054],
            [70.346477072000084, 32.850610648000043],
            [70.346368489000042, 32.850306614000033],
            [70.34602102000008, 32.849763694000046],
            [70.345260933000077, 32.848808157000065],
            [70.34441397900008, 32.84767888500005],
            [70.344044794000069, 32.84700566500004],
            [70.343610459000047, 32.846636480000029],
            [70.343415008000079, 32.846397595000042],
            [70.343349858000067, 32.846050127000069],
            [70.343176124000081, 32.845420341000079],
            [70.342958956000075, 32.844899138000073],
            [70.342611488000045, 32.844443086000069],
            [70.342372603000058, 32.843921884000054],
            [70.342242303000035, 32.843552699000043],
            [70.342112002000079, 32.842857762000051],
            [70.342025135000029, 32.842358277000073],
            [70.341721100000029, 32.841533039000069],
            [70.341373632000057, 32.839860848000058],
            [70.341156464000051, 32.839144195000074],
            [70.340917580000053, 32.838232091000066],
            [70.340765563000048, 32.837493721000044],
            [70.340591828000072, 32.836212431000035],
            [70.340374661000055, 32.834952859000055],
            [70.340287794000062, 32.834344789000056],
            [70.340092343000038, 32.833801870000059],
            [70.339419123000084, 32.832542298000078],
            [70.338832770000067, 32.830739806000054],
            [70.338420152000083, 32.830001436000032],
            [70.338116117000084, 32.829089332000024],
            [70.337725215000034, 32.827981776000058],
            [70.337160579000056, 32.826309585000047],
            [70.336899978000076, 32.825028296000028],
            [70.336769677000063, 32.824246492000043],
            [70.336205041000028, 32.822769752000056],
            [70.335531822000064, 32.821293012000069],
            [70.335336371000039, 32.820641509000041],
            [70.334880318000046, 32.818969318000029],
            [70.334250532000056, 32.817210260000024],
            [70.333946497000056, 32.816015837000066],
            [70.33372933000004, 32.815364334000037],
            [70.333599029000084, 32.814929999000071],
            [70.33342529500004, 32.813626993000071],
            [70.333294994000084, 32.812215403000039],
            [70.333229844000073, 32.811585617000048],
            [70.33314297700008, 32.810999264000031],
            [70.33275207500003, 32.81004372600006],
            [70.332274306000045, 32.808719003000078],
            [70.331948555000054, 32.80780689900007],
            [70.331601087000081, 32.806655911000064],
            [70.331253618000062, 32.805917540000053],
            [70.331014734000064, 32.805309471000044],
            [70.330319797000072, 32.803811014000075],
            [70.329994046000081, 32.802942343000041],
            [70.329125375000046, 32.801357019000079],
            [70.328604173000031, 32.800227747000065],
            [70.328017820000071, 32.799011608000058],
            [70.327713785000071, 32.798099504000049],
            [70.327040565000061, 32.796383879000075],
            [70.32667138000005, 32.79521117400003],
            [70.326193611000065, 32.793929885000068],
            [70.325824426000054, 32.792713746000061],
            [70.325411808000069, 32.791801641000063],
            [70.324716871000078, 32.790802670000062],
            [70.323544166000033, 32.78947794700008],
            [70.32313154700006, 32.789000178000038],
            [70.322805796000068, 32.788392109000029],
            [70.322349744000064, 32.787501721000069],
            [70.322045709000065, 32.786850218000041],
            [70.321806824000078, 32.786372450000044],
            [70.321459356000048, 32.785959831000071],
            [70.320916437000051, 32.785416912000073],
            [70.320373518000054, 32.785026010000024],
            [70.319374546000063, 32.784374507000052],
            [70.317832656000064, 32.783440686000063],
            [70.316399349000051, 32.782463431000053],
            [70.313967071000036, 32.780986691000066],
            [70.311795394000058, 32.77942308400003],
            [70.310774706000075, 32.77848926300004],
            [70.309189382000056, 32.776795355000047],
            [70.308060110000042, 32.775166597000066],
            [70.306474786000081, 32.772929770000076],
            [70.305931867000083, 32.772104533000061],
            [70.305671266000047, 32.771713631000068],
            [70.305193497000062, 32.771409597000059],
            [70.303933925000081, 32.770823244000042],
            [70.30289152000006, 32.770323758000075],
            [70.301697097000044, 32.769802556000059],
            [70.299134519000063, 32.76919448600006],
            [70.297201727000072, 32.768629850000025],
            [70.296050738000076, 32.76821723200004],
            [70.294921466000062, 32.767674313000043],
            [70.293705327000055, 32.767066243000045],
            [70.29229373700008, 32.766414740000073],
            [70.291251332000058, 32.766023838000024],
            [70.287972100000047, 32.765068300000053],
            [70.285887291000051, 32.764503664000074],
            [70.28484488600003, 32.764199630000064],
            [70.283607030000042, 32.763917312000046],
            [70.28197827200006, 32.763656711000067],
            [70.280914151000047, 32.763396109000041],
            [70.280284365000057, 32.76307035800005],
            [70.279328827000029, 32.76244057200006],
            [70.278829341000062, 32.762093103000041],
            [70.278112688000078, 32.761398167000038],
            [70.277178867000032, 32.760681513000065],
            [70.275571826000032, 32.759660825000026],
            [70.274551138000049, 32.759074473000055],
            [70.272422895000034, 32.758032068000034],
            [70.271771392000062, 32.757771467000055],
            [70.270294651000029, 32.756750778000026],
            [70.269968900000038, 32.756381593000071],
            [70.268991645000028, 32.755143738000072],
            [70.268209842000033, 32.753992749000076],
            [70.266950269000063, 32.752776610000069],
            [70.26592958100008, 32.751734205000048],
            [70.264865460000067, 32.750908968000033],
            [70.264431124000055, 32.750518066000041],
            [70.264409407000073, 32.75023574800008],
            [70.264409407000073, 32.749779696000076],
            [70.264583142000049, 32.74862870700008],
            [70.264735159000054, 32.747151967000036],
            [70.264691725000034, 32.74630501300004],
            [70.264539708000029, 32.745653510000068],
            [70.26432254000008, 32.745219175000045],
            [70.264105373000064, 32.744784839000033],
            [70.26401850600007, 32.743916169000045],
            [70.263823055000046, 32.743069215000048],
            [70.263823055000046, 32.742330845000026],
            [70.263736188000053, 32.740267752000079],
            [70.263519020000047, 32.739377364000063],
            [70.263280135000059, 32.738530410000067],
            [70.263128118000054, 32.737965774000031],
            [70.262997818000031, 32.737075387000061],
            [70.262954384000068, 32.73607641600006],
            [70.262824083000055, 32.735446629000023],
            [70.262498332000064, 32.733991606000075],
            [70.262281164000058, 32.732884051000042],
            [70.261977129000059, 32.732210831000032],
            [70.261825112000054, 32.731581045000041],
            [70.261499361000062, 32.730929542000069],
            [70.261390777000031, 32.730473490000065],
            [70.261390777000031, 32.730043825000052],
            [70.260850653000034, 32.730053508000026],
            [70.260545135000029, 32.73005898100007],
            [70.260087555000041, 32.730067180000049],
            [70.259993451000071, 32.730043654000042],
            [70.259826888000077, 32.729900886000053],
            [70.259826888000077, 32.729639145000078],
            [70.259826888000077, 32.729282226000066],
            [70.259874478000029, 32.728925307000054],
            [70.259898272000044, 32.728544593000038],
            [70.259850683000082, 32.728140085000064],
            [70.259755505000044, 32.727735577000033],
            [70.25935099600008, 32.727212095000027],
            [70.259065461000034, 32.726807587000053],
            [70.258589569000037, 32.726355489000071],
            [70.258208855000078, 32.725927186000035],
            [70.257804347000047, 32.725570267000023],
            [70.257685374000062, 32.725237143000072],
            [70.257542606000072, 32.724832634000052],
            [70.257518811000068, 32.724428126000078],
            [70.257471222000049, 32.723976028000038],
            [70.25732845400006, 32.723333574000037],
            [70.257233276000079, 32.723119422000025],
            [70.257019125000056, 32.722429379000062],
            [70.256519438000055, 32.721810719000075],
            [70.256091135000077, 32.721287238000059],
            [70.255377297000052, 32.720763756000053],
            [70.254806226000028, 32.720573399000045],
            [70.254092388000061, 32.720240275000037],
            [70.253640290000078, 32.720121302000052],
            [70.253307165000081, 32.719978534000063],
            [70.252926452000054, 32.719954739000059],
            [70.252450560000057, 32.719954739000059],
            [70.25221261300004, 32.719954739000059],
            [70.252085609000062, 32.719989377000047],
            [70.251820543000065, 32.719964307000055],
            [70.251184801000079, 32.719975655000042],
            [70.250590565000039, 32.719950415000028],
            [70.249949338000079, 32.719961854000076],
            [70.249067005000029, 32.720948682000028],
            [70.247974072000034, 32.722106065000048],
            [70.247106940000037, 32.723322070000052],
            [70.246877852000068, 32.723447660000033],
            [70.245557538000071, 32.724171476000038],
            [70.243995519000066, 32.724515363000023],
            [70.242264218000059, 32.725114614000063],
            [70.242070240000032, 32.725181755000051],
            [70.240891408000039, 32.725898081000025],
            [70.23927812800008, 32.727191062000031],
            [70.237733309000077, 32.72822993300008],
            [70.23610270100005, 32.728827796000076],
            [70.235289745000046, 32.729221506000044],
            [70.234492436000039, 32.730246529000055],
            [70.233307214000035, 32.730710036000062],
            [70.231142239000064, 32.730874769000025],
            [70.228974142000084, 32.730913095000062],
            [70.227176279000048, 32.730691991000072],
            [70.224545584000055, 32.73017002000006],
            [70.221305919000031, 32.729215646000057],
            [70.218806141000073, 32.727932104000047],
            [70.217673227000034, 32.727260048000062],
            [70.216529157000082, 32.726581373000045],
            [70.214336305000074, 32.725608426000065],
            [70.212581199000056, 32.724185246000047],
            [70.211299048000058, 32.723638787000027],
            [70.208671748000029, 32.723242864000042],
            [70.206254805000071, 32.722273701000063],
            [70.204207053000061, 32.721108402000027],
            [70.202987816000075, 32.720181460000049],
            [70.201703112000075, 32.719634940000049],
            [70.199529105000067, 32.719420007000053],
            [70.19729884000003, 32.719964606000076],
            [70.197134973000061, 32.720088950000047],
            [70.196869360000051, 32.720290501000079],
            [70.19414402700005, 32.722358518000078],
            [70.192385243000047, 32.723843061000025],
            [70.191283851000037, 32.724683440000035],
            [70.190177840000047, 32.725334808000071],
            [70.189384677000078, 32.726549685000066],
            [70.188959140000065, 32.727505307000058],
            [70.188071229000059, 32.727900005000038],
            [70.186798772000031, 32.727858849000029],
            [70.186052696000047, 32.727935001000048],
            [70.185387520000063, 32.728262603000076],
            [70.184121185000038, 32.728474184000049],
            [70.182384851000052, 32.727808854000045],
            [70.181702836000056, 32.727441362000036],
            [70.180726372000038, 32.727268597000034],
            [70.179691963000039, 32.727792196000053],
            [70.178525962000037, 32.728950222000037],
            [70.177059738000082, 32.730176634000031],
            [70.176333484000054, 32.731074183000032],
            [70.175459200000034, 32.732037497000078],
            [70.174130217000084, 32.732755221000048],
            [70.173319992000074, 32.733274902000062],
            [70.172209182000074, 32.733736546000046],
            [70.170576529000073, 32.734270366000032],
            [70.168917991000058, 32.733729955000058],
            [70.167558530000065, 32.733184375000064],
            [70.166725490000033, 32.73275618200006],
            [70.165974814000037, 32.732643204000055],
            [70.165231715000061, 32.732845049000048],
            [70.164416896000034, 32.733175098000061],
            [70.162390315000039, 32.732767911000053],
            [70.161557287000051, 32.732339675000048],
            [70.160368633000076, 32.73267611600005],
            [70.159553779000078, 32.733005583000079],
            [70.158646027000032, 32.732579171000054],
            [70.158109057000047, 32.732019423000054],
            [70.15735839000007, 32.731905830000073],
            [70.156853186000035, 32.732673051000063],
            [70.156721789000073, 32.73343331600006],
            [70.155969606000042, 32.733256525000058],
            [70.155428122000046, 32.732507759000043],
            [70.154595141000073, 32.732079486000032],
            [70.153022071000066, 32.731979910000064],
            [70.151221705000069, 32.73175776000005],
            [70.149497608000047, 32.731597500000078],
            [70.14830138700006, 32.73161787600003],
            [70.146276406000084, 32.731273057000067],
            [70.145286430000056, 32.730531305000056],
            [70.14411537400008, 32.728465109000069],
            [70.143267456000046, 32.727404857000067],
            [70.142489814000044, 32.726154331000032],
            [70.141507434000061, 32.725728501000049],
            [70.139188403000048, 32.725704633000078],
            [70.137012900000059, 32.725425436000023],
            [70.134837411000035, 32.725146192000068],
            [70.13356202500006, 32.724978123000028],
            [70.131690065000043, 32.724883338000041],
            [70.129971801000067, 32.724849147000043],
            [70.129061267000054, 32.724295586000039],
            [70.129420120000077, 32.723657371000058],
            [70.129925507000053, 32.722890258000064],
            [70.129533803000072, 32.722138291000078],
            [70.127328586000033, 32.720595115000037],
            [70.125881424000056, 32.71948221100007],
            [70.123704623000037, 32.71913958500005],
            [70.123096168000075, 32.718707322000057],
            [70.121740202000069, 32.718287632000056],
            [70.120627815000034, 32.718685633000064],
            [70.11921047800007, 32.718835876000071],
            [70.117712433000065, 32.718734613000038],
            [70.116195104000042, 32.71781184300005],
            [70.11527435000005, 32.716815845000042],
            [70.114433991000055, 32.715944924000041],
            [70.112679159000038, 32.714457171000049],
            [70.112311831000056, 32.713897707000058],
            [70.11198124200007, 32.713394201000028],
            [70.111284825000041, 32.712394982000035],
            [70.11140619400004, 32.711191861000032],
            [70.111675585000057, 32.709923045000039],
            [70.111572713000044, 32.708723686000042],
            [70.109774376000075, 32.708564134000028],
            [70.109166075000076, 32.708131798000068],
            [70.109291909000035, 32.707118250000065],
            [70.109636055000067, 32.705848187000072],
            [70.110138555000049, 32.704955333000044],
            [70.109744136000074, 32.704076919000045],
            [70.109055204000072, 32.703393077000044],
            [70.107923747000029, 32.702969483000061],
            [70.10621061300003, 32.703250969000067],
            [70.104500429000041, 32.703658813000061],
            [70.102862013000049, 32.703938996000034],
            [70.101441922000049, 32.703962667000042],
            [70.099423897000065, 32.703996277000044],
            [70.097546272000045, 32.703521803000058],
            [70.096115901000076, 32.703103075000058],
            [70.094446638000079, 32.702056151000079],
            [70.092872702000079, 32.701892642000075],
            [70.091204965000031, 32.700908867000066],
            [70.09014103700008, 32.700167917000044],
            [70.088306328000044, 32.698428861000025],
            [70.086477547000072, 32.696941970000069],
            [70.084641505000036, 32.695139099000073],
            [70.082142919000034, 32.693663213000036],
            [70.079493728000045, 32.692252977000066],
            [70.077675408000061, 32.691208867000057],
            [70.076317146000065, 32.690662298000063],
            [70.073692853000068, 32.690326189000075],
            [70.071292762000041, 32.689986352000062],
            [70.070019448000039, 32.68988083000005],
            [70.066946800000039, 32.689551940000058],
            [70.065282253000078, 32.688567775000024],
            [70.064583637000055, 32.687441346000071],
            [70.06433196200004, 32.686244389000024],
            [70.064075976000083, 32.68485841100005],
            [70.063676309000073, 32.683727082000075],
            [70.062895809000054, 32.68228591500008],
            [70.062106652000068, 32.680465581000078],
            [70.060331740000038, 32.678029781000077],
            [70.058207822000043, 32.676673737000044],
            [70.056690379000031, 32.67568706000003],
            [70.056063808000033, 32.674432986000056],
            [70.055743326000083, 32.673489978000077],
            [70.055339504000074, 32.672169053000061],
            [70.054261828000051, 32.670796447000043],
            [70.053263191000042, 32.669611627000052],
            [70.051974365000035, 32.668810818000054],
            [70.050921197000036, 32.668511887000079],
            [70.048772983000049, 32.669242179000037],
            [70.046633541000062, 32.670478016000061],
            [70.045235459000082, 32.671448929000064],
            [70.044521247000034, 32.672913888000039],
            [70.043597190000071, 32.675014962000034],
            [70.042209022000065, 32.676428186000066],
            [70.039399686000081, 32.677801164000073],
            [70.037030035000043, 32.678787702000079],
            [70.035174823000034, 32.679386027000078],
            [70.033605472000033, 32.679284918000064],
            [70.031353860000081, 32.678879281000036],
            [70.029570500000034, 32.679350499000066],
            [70.028396255000075, 32.680317046000027],
            [70.02653953600003, 32.680852629000071],
            [70.024462888000073, 32.681581347000076],
            [70.022893680000038, 32.681606532000046],
            [70.021242674000064, 32.681316934000051],
            [70.019904720000056, 32.681654451000043],
            [70.019250593000038, 32.682486716000028],
            [70.019646866000073, 32.683491801000059],
            [70.019071706000034, 32.684512444000063],
            [70.018244049000032, 32.684272834000069],
            [70.017492387000061, 32.683968798000024],
            [70.016671817000031, 32.684045139000034],
            [70.016085305000047, 32.684560230000045],
            [70.015047606000053, 32.684956108000051],
            [70.013266863000069, 32.685553478000031],
            [70.011477626000044, 32.685771679000027],
            [70.010596396000039, 32.68648108900004],
            [70.010321420000082, 32.687559553000028],
            [70.010194501000058, 32.68857300600007],
            [70.009613568000077, 32.689340835000053],
            [70.008128803000034, 32.689806993000047],
            [70.007088196000041, 32.690076420000025],
            [70.005896712000037, 32.690285019000044],
            [70.005762712000035, 32.690982509000037],
            [70.005028042000049, 32.691563117000044],
            [70.003388134000033, 32.691778825000029],
            [70.001765053000042, 32.692752809000069],
            [70.000539329000048, 32.694668688000036],
            [69.998529870000084, 32.695079838000026],
            [69.997264951000034, 32.69535217300006],
            [69.99570250000005, 32.695692973000064],
            [69.99436982900005, 32.696282988000064],
            [69.993491146000054, 32.697118662000037],
            [69.992303727000035, 32.697516714000074],
            [69.990064382000071, 32.697678501000041],
            [69.988723286000038, 32.69788929200007],
            [69.987465287000077, 32.698478055000066],
            [69.987565092000068, 32.699614333000056],
            [69.987138948000052, 32.700632471000063],
            [69.985287132000053, 32.701420201000076],
            [69.983220687000028, 32.702527345000078],
            [69.980700272000036, 32.703514601000052],
            [69.980514453000069, 32.703532336000023],
            [69.979341271000067, 32.703644306000058],
            [69.978412322000054, 32.70365888200007],
            [69.978938411000058, 32.702931005000039],
            [69.979627933000074, 32.702592821000053],
            [69.978997157000038, 32.702079519000051],
            [69.977519174000065, 32.70177534000004],
            [69.976046930000052, 32.70173294600005],
            [69.974504256000046, 32.701888049000047],
            [69.972723803000065, 32.701915912000061],
            [69.971098181000059, 32.701941330000068],
            [69.969466827000076, 32.701704934000077],
            [69.968453151000062, 32.70126246600006],
            [69.966818955000065, 32.700895130000049],
            [69.965726483000083, 32.700388962000034],
            [69.964321704000042, 32.699887077000028],
            [69.963757036000061, 32.698848897000062],
            [69.962961590000077, 32.697879213000078],
            [69.96168890000007, 32.696328832000063],
            [69.960808984000039, 32.695033651000074],
            [69.960092413000041, 32.69412819300004],
            [69.958838316000083, 32.693428058000052],
            [69.95742355200008, 32.692337017000057],
            [69.955938715000059, 32.691705906000038],
            [69.951747491000049, 32.691116131000058],
            [69.949651810000034, 32.690755727000067],
            [69.948012242000061, 32.690126920000068],
            [69.946903201000055, 32.68896612900005],
            [69.946103781000033, 32.687800545000073],
            [69.94521286500003, 32.685981663000064],
            [69.944708972000058, 32.684156805000043],
            [69.944350032000045, 32.681872544000043],
            [69.94462855200004, 32.680427877000056],
            [69.943822183000066, 32.678935037000031],
            [69.942742932000044, 32.679148078000026],
            [69.940516733000038, 32.67990254700004],
            [69.939125080000053, 32.679989428000056],
            [69.93665117900008, 32.680027451000058],
            [69.935327080000036, 32.679654959000061],
            [69.934140940000077, 32.678495241000064],
            [69.93350917500004, 32.677915692000056],
            [69.929847877000043, 32.67672839200003],
            [69.929529191000029, 32.676704437000069],
            [69.927676754000061, 32.676565194000034],
            [69.926352742000063, 32.676192607000075],
            [69.925502854000058, 32.676271060000033],
            [69.924125082000046, 32.676881328000036],
            [69.922978178000051, 32.677552976000072],
            [69.922613545000047, 32.678606072000036],
            [69.922400900000071, 32.679525345000059],
            [69.920337651000068, 32.680669237000075],
            [69.919037289000073, 32.681409218000056],
            [69.918268916000045, 32.681682792000061],
            [69.916497076000041, 32.681971617000045],
            [69.915575298000078, 32.682312985000067],
            [69.914350861000059, 32.682985720000033],
            [69.913286756000048, 32.683917899000051],
            [69.911510790000079, 32.684140691000039],
            [69.909737610000036, 32.684494910000069],
            [69.908409311000071, 32.683795968000027],
            [69.907086657000036, 32.683488629000067],
            [69.904540879000081, 32.683919929000069],
            [69.90316006200004, 32.684397950000061],
            [69.899631437000039, 32.685826040000052],
            [69.898371012000041, 32.68479749200003],
            [69.89717978300007, 32.683376190000047],
            [69.895003068000051, 32.682950632000029],
            [69.893455161000077, 32.68297388600007],
            [69.891227077000053, 32.683662034000065],
            [69.889457936000042, 32.684212322000064],
            [69.887463332000038, 32.684961240000064],
            [69.886142103000054, 32.684719695000069],
            [69.884599617000049, 32.685004074000062],
            [69.883055700000057, 32.685092610000027],
            [69.88091548400007, 32.682703833000062],
            [69.879410879000034, 32.68109006800006],
            [69.879456897000068, 32.67958356500003],
            [69.880124791000071, 32.678199284000073],
            [69.879800232000036, 32.677485073000071],
            [69.878632549000031, 32.677175127000055],
            [69.877615565000042, 32.676666508000039],
            [69.876275429000032, 32.675508554000032],
            [69.874787279000032, 32.674548634000075],
            [69.87313630400007, 32.673329790000025],
            [69.872658381000065, 32.672682181000027],
            [69.872556544000076, 32.671505789000037],
            [69.873058853000032, 32.669600808000041],
            [69.873858430000041, 32.667102166000063],
            [69.87406337300007, 32.665789700000062],
            [69.874420361000034, 32.664344607000032],
            [69.874223462000032, 32.662319062000051],
            [69.873337708000065, 32.661308407000035],
            [69.872628754000061, 32.659814238000024],
            [69.871594037000079, 32.658826090000048],
            [69.869382447000078, 32.658357155000033],
            [69.863869269000077, 32.65800871700003],
            [69.861665427000048, 32.658041284000035],
            [69.858687886000041, 32.657440113000064],
            [69.85757564000005, 32.656954757000051],
            [69.856295341000077, 32.656472994000069],
            [69.855358015000036, 32.656056713000055],
            [69.854167519000043, 32.655787517000078],
            [69.853232228000081, 32.655729596000072],
            [69.85223015300005, 32.656317751000074],
            [69.851571565000029, 32.657258126000045],
            [69.850824673000034, 32.658057564000046],
            [69.849980623000079, 32.658284997000067],
            [69.847517162000031, 32.657891050000046],
            [69.845303054000055, 32.657421731000056],
            [69.843349492000073, 32.657163597000078],
            [69.841406181000082, 32.657407033000027],
            [69.84083288100004, 32.658347233000029],
            [69.840526198000077, 32.659928654000055],
            [69.840467131000082, 32.661219169000049],
            [69.840335586000037, 32.66301306500003],
            [69.838092434000032, 32.665051670000025],
            [69.836673434000033, 32.666147525000042],
            [69.83551518400003, 32.667597975000035],
            [69.833434108000063, 32.669347981000044],
            [69.831526889000031, 32.671382705000042],
            [69.830727738000064, 32.673830258000066],
            [69.829136026000072, 32.674713497000027],
            [69.827789095000071, 32.675163100000077],
            [69.826027743000054, 32.675976525000067],
            [69.824184115000037, 32.677078390000077],
            [69.822089564000066, 32.678183854000054],
            [69.820926368000073, 32.679275834000066],
            [69.821030562000033, 32.680205020000074],
            [69.820452553000052, 32.680930153000077],
            [69.820041237000055, 32.681581195000035],
            [69.820145436000075, 32.682511508000061],
            [69.819996309000032, 32.683588272000065],
            [69.819331442000077, 32.684242970000071],
            [69.818339151000032, 32.685475815000075],
            [69.817339633000074, 32.686350358000027],
            [69.816002550000064, 32.687300874000073],
            [69.815178117000073, 32.688387353000053],
            [69.815121554000029, 32.689821737000045],
            [69.815316888000041, 32.691036890000078],
            [69.814155090000042, 32.692343813000036],
            [69.813148245000036, 32.692860037000059],
            [69.811796633000029, 32.693094504000044],
            [69.810966351000047, 32.693894887000056],
            [69.809718812000028, 32.695058513000049],
            [69.807628187000034, 32.69652205400007],
            [69.804773686000033, 32.697709794000048],
            [69.802255623000065, 32.699035434000052],
            [69.800241625000069, 32.700067689000036],
            [69.798462394000069, 32.700557010000068],
            [69.79555750600008, 32.701104092000037],
            [69.792946522000079, 32.701394088000029],
            [69.791300908000039, 32.701354260000073],
            [69.788450797000053, 32.700762585000064],
            [69.78612750700006, 32.700479458000075],
            [69.78500136200006, 32.700242552000077],
            [69.783716985000069, 32.699565398000061],
            [69.781685207000066, 32.698898782000072],
            [69.778978312000049, 32.698115244000064],
            [69.777482246000034, 32.698073127000043],
            [69.776742315000035, 32.698462827000071],
            [69.776012325000067, 32.699358073000042],
            [69.774978561000069, 32.699878325000043],
            [69.773193451000054, 32.700345913000035],
            [69.772147053000083, 32.700360621000073],
            [69.769532294000044, 32.700460548000024],
            [69.768031237000059, 32.700165539000068],
            [69.766896474000077, 32.699486117000049],
            [69.764862359000062, 32.698692850000043],
            [69.762829504000081, 32.697962745000041],
            [69.761988832000043, 32.697026320000077],
            [69.760933842000043, 32.696599135000042],
            [69.759726905000036, 32.696047082000064],
            [69.758749334000072, 32.695618234000051],
            [69.757554742000082, 32.695698105000076],
            [69.75628910100005, 32.695968581000045],
            [69.754881352000041, 32.696620291000045],
            [69.752351238000074, 32.697223808000047],
            [69.750417807000076, 32.697756355000024],
            [69.749154574000045, 32.698153155000057],
            [69.748314768000057, 32.699457808000034],
            [69.748333129000059, 32.700404596000055],
            [69.74894699400005, 32.701216703000057],
            [69.749113626000053, 32.702098223000064],
            [69.748978825000052, 32.702857498000071],
            [69.748618558000032, 32.703556697000067],
            [69.746924079000053, 32.704715719000035],
            [69.745820212000069, 32.705488419000062],
            [69.74500286600005, 32.705752004000033],
            [69.743150280000066, 32.706472389000055],
            [69.741087036000067, 32.707762850000051],
            [69.739828707000072, 32.708285344000046],
            [69.738120662000028, 32.708750803000044],
            [69.73716373600007, 32.709394976000056],
            [69.736137886000051, 32.710481990000062],
            [69.735562947000062, 32.711689224000054],
            [69.734924421000073, 32.713338673000067],
            [69.735072569000067, 32.716179441000065],
            [69.734963721000042, 32.716142595000065],
            [69.735038815000053, 32.716593156000044],
            [69.735229171000071, 32.716973869000071],
            [69.735229171000071, 32.717188021000027],
            [69.735310913000035, 32.717225486000075],
            [69.735061315000053, 32.717209886000035],
            [69.734169017000056, 32.716710199000033],
            [69.733026876000054, 32.715960669000026],
            [69.73031429100007, 32.714675760000034],
            [69.728672463000066, 32.713640694000048],
            [69.727102018000039, 32.712748396000052],
            [69.725781417000064, 32.71224871000004],
            [69.72492481200004, 32.71224871000004],
            [69.723711286000082, 32.712462861000063],
            [69.72239068600004, 32.712784088000035],
            [69.721034393000082, 32.713141008000036],
            [69.719535332000078, 32.713640694000048],
            [69.718607343000031, 32.713783462000038],
            [69.71796488800004, 32.713783462000038],
            [69.71717966600005, 32.713605002000065],
            [69.716180292000047, 32.71303393200003],
            [69.715466454000079, 32.71242716900008],
            [69.714574156000083, 32.711534871000026],
            [69.713896010000042, 32.710357038000041],
            [69.713146480000034, 32.709250589000078],
            [69.712040030000082, 32.707537377000051],
            [69.710969273000046, 32.706395236000048],
            [69.710041283000066, 32.705824165000024],
            [69.708542223000052, 32.704432180000026],
            [69.707649925000055, 32.703968185000065],
            [69.706543476000036, 32.703611266000053],
            [69.70550841000005, 32.70343280700007],
            [69.704223501000058, 32.703182963000074],
            [69.702760133000083, 32.702861736000045],
            [69.700582926000038, 32.702611893000039],
            [69.698477103000073, 32.702540509000073],
            [69.69665681500004, 32.702540509000073],
            [69.695300523000071, 32.702433433000067],
            [69.694265457000029, 32.702326357000061],
            [69.693159008000066, 32.702183590000061],
            [69.691909791000057, 32.701862363000032],
            [69.690696266000032, 32.701469751000047],
            [69.688840286000072, 32.701219908000041],
            [69.687448301000074, 32.701219908000041],
            [69.686270468000032, 32.701219908000041],
            [69.685092635000046, 32.701148524000075],
            [69.683593575000032, 32.70075591300008],
            [69.682522817000063, 32.700506070000074],
            [69.680880989000059, 32.700398994000068],
            [69.679274853000038, 32.700398994000068],
            [69.67788286800004, 32.700506070000074],
            [69.675884121000081, 32.701041449000058],
            [69.674741980000078, 32.701219908000041],
            [69.67349276300007, 32.701362676000031],
            [69.672921692000045, 32.701362676000031],
            [69.672350622000067, 32.701291292000064],
            [69.671636783000054, 32.700791605000063],
            [69.670066339000073, 32.699256853000065],
            [69.668602971000041, 32.69804332800004],
            [69.667425137000066, 32.697329489000026],
            [69.666532840000059, 32.696472883000069],
            [69.665497774000073, 32.695580586000062],
            [69.664248557000064, 32.694795363000026],
            [69.663213491000079, 32.694188601000064],
            [69.662463961000071, 32.693795990000069],
            [69.661464588000058, 32.693331995000051],
            [69.660143987000083, 32.692296929000065],
            [69.659394457000076, 32.691654475000064],
            [69.658145240000067, 32.690904945000057],
            [69.657645553000066, 32.690583718000028],
            [69.656931715000042, 32.690512334000061],
            [69.656324952000034, 32.690690793000044],
            [69.654968659000076, 32.690762177000067],
            [69.653648058000044, 32.69079786900005],
            [69.652612993000048, 32.69079786900005],
            [69.651756387000034, 32.690690793000044],
            [69.650614246000032, 32.690333874000032],
            [69.649757640000075, 32.689762804000054],
            [69.648972418000028, 32.68904896500004],
            [69.648222887000031, 32.688477895000062],
            [69.646331216000078, 32.686836067000058],
            [69.645760145000054, 32.686086536000062],
            [69.645438918000082, 32.685087163000048],
            [69.644653696000034, 32.683195491000049],
            [69.644011242000033, 32.681268128000056],
            [69.643618630000049, 32.680304446000036],
            [69.643083252000054, 32.678948153000078],
            [69.642190954000057, 32.677449093000064],
            [69.641726959000039, 32.676021417000072],
            [69.640870353000082, 32.673451599000032],
            [69.640192207000041, 32.671774079000045],
            [69.639514060000067, 32.66963256400004],
            [69.639335601000084, 32.668668882000077],
            [69.639299909000044, 32.668169195000075],
            [69.639406985000051, 32.667276897000079],
            [69.639478368000084, 32.666848594000044],
            [69.639799596000046, 32.666348908000032],
            [69.640156515000058, 32.66584922100003],
            [69.640584818000036, 32.66506399900004],
            [69.641155888000071, 32.663921858000037],
            [69.641262964000077, 32.663279403000047],
            [69.641262964000077, 32.662779716000045],
            [69.641048813000054, 32.66224433800005],
            [69.640442050000047, 32.662030186000038],
            [69.639478368000084, 32.661816035000072],
            [69.638407611000048, 32.661744651000049],
            [69.636801475000084, 32.661744651000049],
            [69.63512395500004, 32.661673267000026],
            [69.633981814000038, 32.661816035000072],
            [69.632875364000029, 32.661851726000066],
            [69.632411369000067, 32.662030186000038],
            [69.631162152000059, 32.662351413000067],
            [69.630376930000068, 32.662779716000045],
            [69.629591708000078, 32.662993868000058],
            [69.628913562000037, 32.663100943000074],
            [69.628092648000063, 32.662993868000058],
            [69.627164658000083, 32.662672641000029],
            [69.625879749000035, 32.661994494000055],
            [69.624737608000032, 32.661280656000031],
            [69.624023769000075, 32.660852353000053],
            [69.623238547000028, 32.660673893000023],
            [69.622025022000059, 32.66063820100004],
            [69.620561654000028, 32.660531126000024],
            [69.618669982000029, 32.660459742000057],
            [69.617242306000037, 32.660459742000057],
            [69.615778937000073, 32.660459742000057],
            [69.614743872000076, 32.660388358000034],
            [69.614208493000035, 32.660316974000068],
            [69.61381588200004, 32.65985297900005],
            [69.613637422000068, 32.659246217000032],
            [69.613494655000068, 32.658532378000075],
            [69.613387579000062, 32.657604389000028],
            [69.613137736000056, 32.656390864000059],
            [69.612780816000054, 32.655498566000063],
            [69.61206697800003, 32.654856111000072],
            [69.611460216000069, 32.654320733000077],
            [69.610639302000038, 32.653856738000059],
            [69.609854079000058, 32.653357051000057],
            [69.608676246000073, 32.652678904000027],
            [69.607605489000036, 32.651750915000036],
            [69.606677499000057, 32.65103707600008],
            [69.605820893000043, 32.650180470000066],
            [69.604678752000041, 32.649573708000048],
            [69.603822146000084, 32.648859870000024],
            [69.603358151000066, 32.648467259000029],
            [69.603179691000037, 32.647896188000061],
            [69.602894156000048, 32.647110966000071],
            [69.602751388000058, 32.646111592000068],
            [69.602394469000046, 32.644933759000025],
            [69.601823399000068, 32.643863002000046],
            [69.601680631000079, 32.643256239000038],
            [69.601537863000033, 32.642435325000065],
            [69.601537863000033, 32.641971330000047],
            [69.601537863000033, 32.641507335000028],
            [69.601894783000034, 32.641007648000027],
            [69.602430161000029, 32.640365194000026],
            [69.603215383000077, 32.639722740000025],
            [69.603857838000067, 32.639008901000068],
            [69.604535984000051, 32.638437831000033],
            [69.605249822000076, 32.637545533000036],
            [69.605999353000072, 32.636796003000029],
            [69.606391964000068, 32.636010780000049],
            [69.60671319100004, 32.635511094000037],
            [69.607177186000058, 32.634975715000053],
            [69.607569797000053, 32.634690180000064],
            [69.608390711000084, 32.634333260000062],
            [69.609104549000051, 32.63383357400005],
            [69.609889771000041, 32.633333887000049],
            [69.610425150000083, 32.632941276000054],
            [69.610889145000044, 32.632477281000035],
            [69.611246064000056, 32.631584983000039],
            [69.611531599000045, 32.630978221000078],
            [69.612209746000076, 32.629978847000075],
            [69.612495281000065, 32.629300701000034],
            [69.612780816000054, 32.628765322000049],
            [69.612923584000043, 32.628265635000048],
            [69.612923584000043, 32.628129991000037],
            [69.612923584000043, 32.627730256000063],
            [69.612709433000077, 32.627123494000045],
            [69.612138362000053, 32.626481039000055],
            [69.611067605000073, 32.625374590000035],
            [69.610068231000071, 32.624589368000045],
            [69.609140241000034, 32.624089681000044],
            [69.608069484000055, 32.623625686000025],
            [69.606927342000063, 32.622947540000041],
            [69.605463974000031, 32.621662631000049],
            [69.604071989000033, 32.62069894900003],
            [69.603465227000072, 32.620234954000068],
            [69.60325107500006, 32.61962819200005],
            [69.60310830800006, 32.618950045000076],
            [69.603144000000043, 32.618343283000058],
            [69.603286767000043, 32.617272525000033],
            [69.603500919000055, 32.616594379000048],
            [69.60389353000005, 32.615844849000041],
            [69.60403629700005, 32.61520239400005],
            [69.603964914000073, 32.614559940000049],
            [69.603679378000038, 32.613667642000053],
            [69.603643686000055, 32.612989496000068],
            [69.603572302000032, 32.611990122000066],
            [69.603536611000038, 32.610990748000063],
            [69.603715070000078, 32.609812915000077],
            [69.60403629700005, 32.608813542000064],
            [69.604393217000052, 32.607849860000044],
            [69.604571676000035, 32.606707719000042],
            [69.604678752000041, 32.605672653000056],
            [69.604678752000041, 32.604708971000036],
            [69.604892903000064, 32.603959441000029],
            [69.605178439000042, 32.603566830000034],
            [69.605963661000033, 32.603174219000039],
            [69.607070110000052, 32.60274591600006],
            [69.608140868000078, 32.602281921000042],
            [69.609175933000074, 32.601817926000024],
            [69.609818388000065, 32.601353932000052],
            [69.610532226000032, 32.600782861000027],
            [69.61099622100005, 32.600354558000049],
            [69.611317448000079, 32.599890563000031],
            [69.611531599000045, 32.59924810900003],
            [69.61210267000007, 32.598641346000079],
            [69.612638049000054, 32.598070275000055],
            [69.613102044000073, 32.597641972000076],
            [69.613708806000034, 32.597070902000041],
            [69.614172801000052, 32.596428447000051],
            [69.614244185000075, 32.595678917000043],
            [69.614244185000075, 32.594965079000076],
            [69.614015101000064, 32.594310553000071],
            [69.61399434100008, 32.594251241000052],
            [69.613280503000055, 32.593287559000032],
            [69.612423897000042, 32.592109726000047],
            [69.611638675000052, 32.591110352000044],
            [69.610924837000084, 32.590325130000053],
            [69.610139615000037, 32.589968211000041],
            [69.609497160000046, 32.589825443000052],
            [69.608497787000033, 32.589754059000029],
            [69.607105802000035, 32.589754059000029],
            [69.605463974000031, 32.589825443000052],
            [69.604000605000067, 32.589896827000075],
            [69.602751388000058, 32.589896827000075],
            [69.601180944000077, 32.589789751000069],
            [69.600467106000053, 32.589718367000046],
            [69.599217889000045, 32.589539908000063],
            [69.598325591000048, 32.589290064000068],
            [69.597326217000045, 32.588968837000039],
            [69.596326844000032, 32.588683302000049],
            [69.594934859000034, 32.588433459000044],
            [69.593257339000047, 32.588290691000054],
            [69.591294284000071, 32.588254999000071],
            [69.590223526000045, 32.588076539000042],
            [69.589438304000055, 32.587969464000025],
            [69.588046320000046, 32.587612544000024],
            [69.586797103000038, 32.587077166000029],
            [69.585726345000069, 32.586755939000057],
            [69.584548512000083, 32.586755939000057],
            [69.583477755000047, 32.586827322000033],
            [69.58272822400005, 32.586720247000073],
            [69.582014386000083, 32.586470403000078],
            [69.581229164000035, 32.586077792000026],
            [69.580693785000051, 32.585435338000025],
            [69.579373185000065, 32.584614424000051],
            [69.577552897000032, 32.583436590000076],
            [69.576482139000063, 32.582936904000064],
            [69.575090155000055, 32.582294449000074],
            [69.573626786000034, 32.581687687000056],
            [69.572413261000065, 32.580973848000042],
            [69.57012897900006, 32.579332020000038],
            [69.567237934000048, 32.577404657000045],
            [69.566524095000034, 32.576762202000054],
            [69.566095792000056, 32.575976980000064],
            [69.564810883000064, 32.574156693000077],
            [69.563882894000074, 32.573335779000047],
            [69.562883520000071, 32.573014551000028],
            [69.561384460000056, 32.572800400000062],
            [69.559135869000045, 32.572229329000038],
            [69.554817147000051, 32.570730269000023],
            [69.547928608000063, 32.568445986000029],
            [69.546465239000042, 32.568017683000051],
            [69.545001871000068, 32.56723246100006],
            [69.544359416000077, 32.566768466000042],
            [69.544002497000065, 32.565983244000051],
            [69.543752654000059, 32.565198022000061],
            [69.543502811000053, 32.564448492000054],
            [69.543003124000052, 32.563841729000046],
            [69.542324977000078, 32.563056507000056],
            [69.540861609000046, 32.562449745000038],
            [69.539790851000078, 32.562235593000025],
            [69.538505942000029, 32.562271285000065],
            [69.537649337000062, 32.562271285000065],
            [69.53730590300006, 32.562411781000037],
            [69.536864114000082, 32.562592512000037],
            [69.536400120000053, 32.562949432000039],
            [69.535936125000035, 32.563484810000034],
            [69.535222286000078, 32.564341416000048],
            [69.534793983000043, 32.565055255000061],
            [69.534044453000035, 32.565804785000068],
            [69.533544766000034, 32.56630447200007],
            [69.532830928000067, 32.566875542000048],
            [69.531974322000053, 32.567375229000049],
            [69.531046332000074, 32.567767840000045],
            [69.529618656000082, 32.567981992000057],
            [69.528583590000039, 32.567981992000057],
            [69.527976828000078, 32.568017683000051],
            [69.527298681000048, 32.568124759000057],
            [69.526763303000052, 32.568481678000069],
            [69.526370692000057, 32.569195517000026],
            [69.52576392900005, 32.569409668000048],
            [69.525228550000065, 32.569588128000078],
            [69.524443328000075, 32.569730895000077],
            [69.523693798000068, 32.569695203000038],
            [69.523051344000066, 32.569516744000055],
            [69.522230430000036, 32.569266900000059],
            [69.521052596000061, 32.568909981000047],
            [69.519981839000081, 32.568874289000064],
            [69.518911081000056, 32.568731522000064],
            [69.517983092000065, 32.568767214000047],
            [69.517126486000052, 32.568767214000047],
            [69.515127739000036, 32.568553062000035],
            [69.514342516000056, 32.568303219000029],
            [69.513557294000066, 32.567696456000078],
            [69.512772072000075, 32.566911234000031],
            [69.511951158000045, 32.565911861000075],
            [69.511344396000084, 32.564805411000066],
            [69.510916093000048, 32.563877421000029],
            [69.510345022000081, 32.563449118000051],
            [69.509202881000078, 32.563270659000068],
            [69.508274891000042, 32.563234967000028],
            [69.507632437000041, 32.563020815000073],
            [69.506775831000084, 32.562378361000071],
            [69.506133376000037, 32.56187867400007],
            [69.50509831100004, 32.560843609000074],
            [69.504241705000084, 32.560094078000077],
            [69.503634942000076, 32.559808543000031],
            [69.502849720000029, 32.559844235000071],
            [69.501921730000049, 32.560058387000026],
            [69.500922357000036, 32.560343922000072],
            [69.50017577400007, 32.560415025000054],
            [69.500189530000057, 32.560518199000057],
            [69.500092240000072, 32.560533166000027],
            [69.499271326000041, 32.561247005000041],
            [69.498664563000034, 32.561675308000076],
            [69.497915033000083, 32.562032227000032],
            [69.496737200000041, 32.562817449000079],
            [69.49627320500008, 32.563067292000028],
            [69.495666442000072, 32.563210060000074],
            [69.494738453000082, 32.563210060000074],
            [69.49356061900005, 32.56342421100004],
            [69.492561246000037, 32.563923898000041],
            [69.491990175000069, 32.564066666000031],
            [69.491383413000051, 32.564066666000031],
            [69.490633882000054, 32.563566979000029],
            [69.489884352000047, 32.56328144400004],
            [69.489170514000079, 32.563031600000045],
            [69.488456676000055, 32.563031600000045],
            [69.487707145000059, 32.563388520000046],
            [69.48710038300004, 32.563745439000058],
            [69.486243777000084, 32.564637737000055],
            [69.485280095000064, 32.565637110000068],
            [69.484387797000068, 32.566243873000076],
            [69.483352732000071, 32.566743559000031],
            [69.481996439000056, 32.567528782000068],
            [69.480711530000065, 32.568242620000035],
            [69.479890616000034, 32.568992150000042],
            [69.479498005000039, 32.569527529000027],
            [69.478784167000072, 32.570990897000058],
            [69.47842724800006, 32.571740427000066],
            [69.477963253000041, 32.572382882000056],
            [69.477320798000051, 32.572989644000074],
            [69.476678344000049, 32.573346564000076],
            [69.475500511000064, 32.573667791000048],
            [69.474286986000038, 32.574060402000043],
            [69.473680223000031, 32.574417321000055],
            [69.47293069300008, 32.574917008000057],
            [69.472466698000062, 32.575987765000036],
            [69.472038395000084, 32.577236982000045],
            [69.471824243000071, 32.578486199000054],
            [69.471503016000042, 32.580020952000041],
            [69.470789178000075, 32.583518759000071],
            [69.470539335000069, 32.584803668000063],
            [69.470503643000029, 32.585838734000049],
            [69.470182415000068, 32.586731032000046],
            [69.469575653000049, 32.587230718000058],
            [69.468933198000059, 32.587659021000036],
            [69.468147976000068, 32.588087324000071],
            [69.466684608000037, 32.588943930000028],
            [69.465078472000073, 32.590228839000076],
            [69.463151108000034, 32.591763591000074],
            [69.461259437000081, 32.593262652000078],
            [69.46043852300005, 32.593905106000079],
            [69.459724684000037, 32.594047874000069],
            [69.459010846000069, 32.594119258000035],
            [69.458082856000033, 32.594083566000052],
            [69.456690872000081, 32.594047874000069],
            [69.456012725000051, 32.594154950000075],
            [69.455334579000066, 32.594369101000041],
            [69.454370897000047, 32.595082940000054],
            [69.453549983000073, 32.596010929000045],
            [69.452764761000083, 32.596796151000035],
            [69.451979539000035, 32.597367222000059],
            [69.451158625000062, 32.597902601000044],
            [69.44969525700003, 32.598509363000062],
            [69.447732201000065, 32.59893766600004],
            [69.44637590800005, 32.599187510000036],
            [69.444912540000075, 32.599294585000052],
            [69.443484863000037, 32.599330277000035],
            [69.441843035000034, 32.599009050000063],
            [69.440772278000054, 32.598973358000023],
            [69.439879980000057, 32.599044742000046],
            [69.438595071000066, 32.599187510000036],
            [69.437238778000051, 32.599223202000076],
            [69.436168021000071, 32.599223202000076],
            [69.435132955000029, 32.599044742000046],
            [69.434454809000044, 32.598901974000057],
            [69.43391943000006, 32.598687823000034],
            [69.433384052000065, 32.598295212000039],
            [69.432884365000064, 32.597831217000078],
            [69.432491754000068, 32.597331530000076],
            [69.431992067000067, 32.596903227000041],
            [69.431528072000049, 32.596867535000058],
            [69.430992693000064, 32.596867535000058],
            [69.430457315000069, 32.596974611000064],
            [69.429993320000051, 32.597509990000049],
            [69.429457941000067, 32.597973985000067],
            [69.428815487000065, 32.59879489900004],
            [69.427994573000035, 32.599972732000026],
            [69.427066583000055, 32.601186257000052],
            [69.426102901000036, 32.602257014000031],
            [69.425317679000045, 32.602935161000062],
            [69.424461073000032, 32.60339915600008],
            [69.423640159000058, 32.603648999000029],
            [69.422747861000062, 32.603898842000035],
            [69.421855563000065, 32.603898842000035],
            [69.420963265000069, 32.603863150000052],
            [69.419928200000072, 32.603827459000058],
            [69.41875036700003, 32.603827459000058],
            [69.417893761000073, 32.603970226000058],
            [69.417358382000032, 32.604112994000047],
            [69.416430392000052, 32.604469913000059],
            [69.415752246000068, 32.604505605000043],
            [69.415109791000077, 32.604469913000059],
            [69.41421749400007, 32.604434221000076],
            [69.414203169000075, 32.604431152000075],
            [69.413998489000051, 32.604704058000038],
            [69.413262123000038, 32.604759659000024],
            [69.413402150000081, 32.604912334000062],
            [69.425191000000041, 32.617766000000074],
            [69.434907000000067, 32.631571000000065],
            [69.446434000000068, 32.646906000000058],
            [69.452331000000072, 32.658700000000067],
            [69.449711000000036, 32.667873000000043],
            [69.444469000000083, 32.675736000000029],
            [69.439227000000074, 32.685564000000056],
            [69.44333000000006, 32.704035000000033],
            [69.448716000000047, 32.720030000000065],
            [69.440024000000051, 32.729234000000076],
            [69.423662000000036, 32.73792700000007],
            [69.407811000000038, 32.746109000000047],
            [69.397585000000049, 32.756335000000036],
            [69.395437000000072, 32.76974800000005],
            [69.399711000000082, 32.779192000000023],
            [69.405457000000069, 32.784590000000037],
            [69.415994000000069, 32.792639000000065],
            [69.431846000000064, 32.802354000000037],
            [69.44709000000006, 32.81726400000008],
            [69.460849000000053, 32.834955000000036],
            [69.471333000000072, 32.84871400000003],
            [69.491161000000034, 32.847860000000026],
            [69.508546000000081, 32.847348000000068],
            [69.524397000000079, 32.852461000000062],
            [69.540249000000074, 32.862687000000051],
            [69.53309100000007, 32.872914000000037],
            [69.517198000000064, 32.884096000000056],
            [69.510646000000065, 32.900477000000024],
            [69.500818000000038, 32.915547000000061],
            [69.494266000000039, 32.941756000000055],
            [69.495412000000044, 32.963359000000025],
            [69.498324000000082, 32.970067000000029],
            [69.496886000000075, 32.980414000000053],
            [69.498852000000056, 32.992207000000064],
            [69.504094000000066, 33.014485000000036],
            [69.508680000000084, 33.024969000000056],
            [69.513267000000042, 33.032831000000044],
            [69.532924000000037, 33.048556000000076],
            [69.560709000000031, 33.070285000000069],
            [69.584742000000062, 33.096873000000073],
            [69.640476000000035, 33.093292000000076],
            [69.670644000000038, 33.094826000000069],
            [69.701323000000059, 33.091246000000069],
            [69.721264000000076, 33.092268000000047],
            [69.735070000000064, 33.102494000000036],
            [69.747342967000066, 33.112208974000055],
            [69.747343000000058, 33.112209000000064],
            [69.766261000000043, 33.111697000000049],
            [69.778022000000078, 33.118344000000036],
            [69.790805000000034, 33.12550200000004],
            [69.833755000000053, 33.101980000000026],
            [69.856253000000038, 33.092776000000072],
            [69.872104000000036, 33.092264000000057],
            [69.895625000000052, 33.097888000000069],
            [69.916590000000042, 33.102490000000046],
            [69.923389413000052, 33.107976934000078],
            [69.923445859000083, 33.108022485000049],
            [69.945736000000068, 33.126010000000065],
            [69.975393000000054, 33.133679000000029],
            [69.999936000000048, 33.135212000000024],
            [70.026525000000049, 33.143904000000077],
            [70.041354000000069, 33.163334000000077],
            [70.059763000000032, 33.19759300000004],
            [70.072546000000045, 33.214466000000073],
            [70.09248800000006, 33.208841000000064],
            [70.114298862000055, 33.210599519000027],
            [70.124190000000056, 33.211397000000034],
            [70.144642000000033, 33.214464000000078],
            [70.157426000000044, 33.221111000000064],
            [70.167141000000072, 33.23338300000006],
            [70.187594000000047, 33.24002900000005],
            [70.207025000000044, 33.246676000000036],
            [70.226147000000083, 33.263469000000043],
            [70.247114000000067, 33.280504000000064],
            [70.266347993000068, 33.298902008000027],
            [70.277254000000028, 33.309334000000035],
            [70.279276562000064, 33.312165648000075],
            [70.290358000000083, 33.327680000000043],
            [70.301622000000066, 33.328997000000072],
            [70.319518000000073, 33.328996000000075],
            [70.319519000000071, 33.328996000000075],
            [70.323915668000041, 33.332903990000034],
            [70.324000315000035, 33.332979229000046],
            [70.324159892000068, 33.332888620000062],
            [70.324856145000069, 33.332604961000072],
            [70.326248130000067, 33.332319426000026],
            [70.327140428000064, 33.332140966000054],
            [70.327782882000065, 33.331819739000025],
            [70.328202350000083, 33.331522661000065],
            [70.32867514600008, 33.331150401000059],
            [70.329103449000058, 33.331043325000053],
            [70.329674520000083, 33.330936250000036],
            [70.329794564000053, 33.330929188000027],
            [70.330281282000044, 33.330900558000053],
            [70.330780969000045, 33.331043325000053],
            [70.331244964000064, 33.331400245000054],
            [70.331530499000053, 33.331721472000027],
            [70.331923110000048, 33.331864239000026],
            [70.332387105000066, 33.332007007000072],
            [70.332958176000034, 33.332042699000056],
            [70.333636322000075, 33.331971315000033],
            [70.334350160000042, 33.331864239000026],
            [70.334778463000077, 33.331721472000027],
            [70.335313842000062, 33.33165008800006],
            [70.335599377000051, 33.33165008800006],
            [70.335991989000036, 33.331899931000066],
            [70.336099064000052, 33.332221159000028],
            [70.336134756000035, 33.33272084500004],
            [70.336170448000075, 33.33354175900007],
            [70.336170448000075, 33.334291290000067],
            [70.336134756000035, 33.335504815000036],
            [70.336134756000035, 33.336040193000031],
            [70.336134756000035, 33.336718340000061],
            [70.336384600000031, 33.337753405000058],
            [70.336491675000048, 33.338538627000048],
            [70.336670135000077, 33.339252466000062],
            [70.336777211000083, 33.33971646100008],
            [70.336955670000066, 33.340144764000058],
            [70.337134130000038, 33.340573067000037],
            [70.337383973000044, 33.34078721800006],
            [70.337919352000029, 33.340965678000032],
            [70.33859749800007, 33.340965678000032],
            [70.339418412000043, 33.340965678000032],
            [70.340275018000057, 33.340965678000032],
            [70.341203008000036, 33.340965678000032],
            [70.342309457000056, 33.340965678000032],
            [70.343308831000058, 33.340965678000032],
            [70.344450972000061, 33.341072753000049],
            [70.34552173000003, 33.341215521000038],
            [70.346271260000037, 33.341536748000067],
            [70.347056482000028, 33.341715208000039],
            [70.347734628000069, 33.342143511000074],
            [70.348519851000049, 33.342928733000065],
            [70.349055229000044, 33.343535495000026],
            [70.349590608000028, 33.343963798000061],
            [70.350054603000046, 33.344392101000039],
            [70.350447214000042, 33.344677637000075],
            [70.351089668000043, 33.344927480000024],
            [70.351625047000084, 33.344927480000024],
            [70.352267502000075, 33.344927480000024],
            [70.353017032000082, 33.344891788000041],
            [70.353695178000066, 33.344784712000035],
            [70.354444708000074, 33.344427793000079],
            [70.355229930000064, 33.344320718000063],
            [70.355836693000072, 33.34410656600005],
            [70.356621915000062, 33.344035182000027],
            [70.357621289000065, 33.343928106000078],
            [70.358549278000055, 33.343749647000038],
            [70.359370193000075, 33.343749647000038],
            [70.360119723000082, 33.343856723000044],
            [70.361154788000078, 33.344142258000034],
            [70.362225546000047, 33.344534869000029],
            [70.363117844000044, 33.34507024800007],
            [70.364481075000072, 33.345629522000024],
            [70.364509828000052, 33.345641318000048],
            [70.365687661000038, 33.346248081000056],
            [70.366722727000081, 33.34678346000004],
            [70.367722100000037, 33.347247455000058],
            [70.368578706000051, 33.34760437400007],
            [70.369578080000053, 33.34788990900006],
            [70.370291918000078, 33.348032677000049],
            [70.371755287000042, 33.348139752000066],
            [70.372861736000061, 33.348318212000038],
            [70.374396488000059, 33.348496672000067],
            [70.375574322000034, 33.348746515000073],
            [70.376466620000031, 33.349103434000028],
            [70.377751528000033, 33.349567429000047],
            [70.378715210000053, 33.350031424000065],
            [70.379536124000083, 33.350638186000026],
            [70.38039273000004, 33.35138771700008],
            [70.380892417000041, 33.351994479000041],
            [70.381392104000042, 33.352601242000048],
            [70.381677639000031, 33.352922469000077],
            [70.382391477000056, 33.35295816100006],
            [70.383069624000029, 33.352922469000077],
            [70.384425916000055, 33.352708317000065],
            [70.386496047000037, 33.351994479000041],
            [70.387638189000029, 33.35138771700008],
            [70.389137249000044, 33.350745262000032],
            [70.391028921000043, 33.349710197000036],
            [70.392385213000068, 33.348782207000056],
            [70.393741506000083, 33.348139752000066],
            [70.394776572000069, 33.34788990900006],
            [70.395561794000059, 33.34788990900006],
            [70.396703935000062, 33.347854217000076],
            [70.397631925000042, 33.347568682000031],
            [70.398381455000049, 33.346890535000057],
            [70.399487904000068, 33.346033929000043],
            [70.401058349000039, 33.344641945000035],
            [70.402307566000047, 33.343749647000038],
            [70.403878010000028, 33.342964425000048],
            [70.405162919000077, 33.342678889000069],
            [70.407233050000059, 33.342643198000076],
            [70.409160413000052, 33.342429046000063],
            [70.411587463000046, 33.342464738000047],
            [70.413157908000073, 33.342857349000042],
            [70.413978822000047, 33.343000117000031],
            [70.415049579000083, 33.342857349000042],
            [70.415834801000074, 33.34253612200007],
            [70.41669140700003, 33.34235766200004],
            [70.417619397000067, 33.342464738000047],
            [70.41844031100004, 33.342964425000048],
            [70.419511068000077, 33.34424933400004],
            [70.420474750000039, 33.345534243000031],
            [70.421474124000042, 33.346355157000062],
            [70.422330730000056, 33.346854843000074],
            [70.423544255000081, 33.347140379000052],
            [70.424936239000033, 33.347425914000041],
            [70.425864229000069, 33.34760437400007],
            [70.426863603000072, 33.347675757000047],
            [70.428112820000081, 33.347782833000053],
            [70.428933734000054, 33.348104060000026],
            [70.429826032000051, 33.348282520000055],
            [70.430432794000069, 33.348603747000027],
            [70.430968173000053, 33.349210510000034],
            [70.431325092000066, 33.349781580000069],
            [70.431574935000071, 33.350352651000037],
            [70.43186047100005, 33.351459100000056],
            [70.43200323800005, 33.352422782000076],
            [70.432110314000056, 33.353065237000067],
            [70.432360157000062, 33.353636307000045],
            [70.432859844000063, 33.353814767000074],
            [70.433502299000054, 33.353850459000057],
            [70.434608748000073, 33.353850459000057],
            [70.436107809000077, 33.353386464000039],
            [70.437071490000051, 33.353172312000027],
            [70.438320707000059, 33.352779701000031],
            [70.440569298000071, 33.352458474000059],
            [70.441854207000063, 33.352208631000053],
            [70.443246191000071, 33.35213724700003],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "53",
      properties: { name: "Orakzai Agency", count: 1412 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.096468943000048, 33.839487404000067],
            [71.097982280000053, 33.838059727000029],
            [71.099752599000055, 33.836460729000066],
            [71.101494365000065, 33.835347142000046],
            [71.103378898000074, 33.834090786000047],
            [71.105063556000061, 33.833034305000069],
            [71.107404946000031, 33.831578075000039],
            [71.10894683600003, 33.830550148000043],
            [71.110203192000029, 33.829750649000061],
            [71.111145458000067, 33.82940800700004],
            [71.112087725000038, 33.829293793000033],
            [71.113429741000061, 33.829151025000044],
            [71.115514149000035, 33.829265239000051],
            [71.11717025400003, 33.829236686000058],
            [71.118540823000046, 33.829208132000076],
            [71.120196928000041, 33.829208132000076],
            [71.121310516000051, 33.829379453000058],
            [71.122452657000053, 33.829579328000079],
            [71.123394923000035, 33.830093292000072],
            [71.124337190000062, 33.830464488000075],
            [71.124993921000055, 33.830778576000057],
            [71.125507885000047, 33.83100700500006],
            [71.126193169000032, 33.83100700500006],
            [71.127021222000053, 33.830807130000039],
            [71.12776361400006, 33.83052159500005],
            [71.128620220000073, 33.829921971000033],
            [71.129562486000054, 33.829122472000051],
            [71.130333432000043, 33.828151652000031],
            [71.131018716000028, 33.827380706000042],
            [71.131989536000049, 33.826210011000057],
            [71.133674195000083, 33.824468246000038],
            [71.135216085000081, 33.822840695000025],
            [71.136872190000076, 33.821241697000062],
            [71.137928671000054, 33.820327984000073],
            [71.139185026000064, 33.819585592000067],
            [71.140469935000056, 33.818900307000035],
            [71.141098113000055, 33.818443451000064],
            [71.141669184000079, 33.817586845000051],
            [71.14226880800004, 33.816530364000073],
            [71.142525790000036, 33.815845079000042],
            [71.143125414000053, 33.815102688000024],
            [71.144124787000067, 33.814360296000075],
            [71.145324036000034, 33.813418029000047],
            [71.146351963000029, 33.812504316000059],
            [71.147436997000057, 33.812047460000031],
            [71.148122282000031, 33.811876139000049],
            [71.149264423000034, 33.811876139000049],
            [71.150463671000068, 33.812190227000031],
            [71.152833614000031, 33.813018280000051],
            [71.154775255000061, 33.813760672000058],
            [71.156831109000052, 33.814531617000057],
            [71.158801303000075, 33.815302562000056],
            [71.159686462000082, 33.815702312000042],
            [71.160657282000045, 33.815959294000038],
            [71.161371120000069, 33.815959294000038],
            [71.162227726000083, 33.815873633000024],
            [71.163084332000039, 33.81553099100006],
            [71.164340688000038, 33.815159795000056],
            [71.165311508000059, 33.814760045000071],
            [71.166139560000033, 33.814531617000057],
            [71.166967613000054, 33.814474510000025],
            [71.168081200000074, 33.814474510000025],
            [71.169194788000084, 33.814503063000075],
            [71.170422590000044, 33.814560171000039],
            [71.17193592700005, 33.814959920000035],
            [71.172592658000042, 33.815216902000031],
            [71.173277943000073, 33.815673758000059],
            [71.174420084000076, 33.816787346000069],
            [71.17573354700005, 33.818072255000061],
            [71.178046383000037, 33.820185216000027],
            [71.179873809000071, 33.82229817800004],
            [71.18078752200006, 33.823497426000074],
            [71.181615574000034, 33.824582460000045],
            [71.18264350100003, 33.826409886000079],
            [71.183300233000068, 33.827323599000067],
            [71.184156839000082, 33.828151652000031],
            [71.185013444000049, 33.828408633000038],
            [71.186526782000044, 33.828694169000073],
            [71.188068672000043, 33.829036811000037],
            [71.189724777000038, 33.829293793000033],
            [71.191951953000057, 33.829664989000037],
            [71.193693718000077, 33.830321720000029],
            [71.195549698000036, 33.83114977200006],
            [71.197634105000077, 33.831835057000035],
            [71.201003422000042, 33.833148520000066],
            [71.20380166800004, 33.834290661000068],
            [71.205514880000067, 33.835033053000075],
            [71.207342306000044, 33.83571833700006],
            [71.208113251000043, 33.835946766000063],
            [71.208655769000075, 33.835918212000024],
            [71.209169732000078, 33.835404249000078],
            [71.209769356000038, 33.834918839000068],
            [71.210711623000066, 33.834005126000079],
            [71.211425461000033, 33.833348394000041],
            [71.212596156000075, 33.832406128000059],
            [71.21410949300008, 33.831492415000071],
            [71.215308741000058, 33.830835684000078],
            [71.216422329000068, 33.83017895200004],
            [71.217364596000039, 33.829722096000069],
            [71.218021327000031, 33.829493668000055],
            [71.219049254000083, 33.829208132000076],
            [71.220362717000057, 33.82892259700003],
            [71.221076555000082, 33.828522847000045],
            [71.221704732000035, 33.828008884000042],
            [71.222361464000073, 33.827380706000042],
            [71.222961088000034, 33.82672397500005],
            [71.223617819000083, 33.825953030000051],
            [71.224303104000057, 33.825410513000065],
            [71.224874174000036, 33.824611014000027],
            [71.22544524500006, 33.823954282000045],
            [71.226101976000052, 33.823354658000028],
            [71.226730154000052, 33.82292635500005],
            [71.227786635000029, 33.822583713000029],
            [71.228843115000075, 33.822069749000036],
            [71.23035645300007, 33.821555786000033],
            [71.232012557000076, 33.821270250000055],
            [71.233011931000078, 33.820813394000027],
            [71.233925644000067, 33.820527859000038],
            [71.235210553000059, 33.820327984000073],
            [71.236267034000036, 33.820042449000027],
            [71.237694710000028, 33.81995678800007],
            [71.239322261000041, 33.819899681000038],
            [71.241178241000057, 33.819928234000031],
            [71.243005667000034, 33.820013895000045],
            [71.245061521000082, 33.820356537000066],
            [71.247402911000052, 33.82055641200003],
            [71.248173856000051, 33.820642073000045],
            [71.248716373000036, 33.820727733000069],
            [71.249201783000046, 33.820956162000073],
            [71.249658640000064, 33.82121314300008],
            [71.250286818000063, 33.821270250000055],
            [71.250800781000066, 33.821270250000055],
            [71.251457512000059, 33.821156036000048],
            [71.252000029000044, 33.821041822000041],
            [71.252542547000076, 33.820727733000069],
            [71.252942296000072, 33.820327984000073],
            [71.253513367000039, 33.820099556000059],
            [71.254170098000031, 33.820013895000045],
            [71.254684061000034, 33.819671253000024],
            [71.255026704000045, 33.819271503000039],
            [71.255055257000038, 33.81884320000006],
            [71.25496959700007, 33.818443451000064],
            [71.254801305000058, 33.817858834000049],
            [71.254715645000033, 33.817116442000042],
            [71.254744198000083, 33.816659585000025],
            [71.255001180000079, 33.816231282000047],
            [71.255600804000039, 33.815888640000026],
            [71.256057661000057, 33.81548889100003],
            [71.256628731000035, 33.815146248000076],
            [71.257770873000084, 33.814460963000045],
            [71.258513264000044, 33.813832786000035],
            [71.259284210000033, 33.812947626000039],
            [71.260083709000071, 33.811805485000036],
            [71.26085465400007, 33.810549130000027],
            [71.261254403000066, 33.809406988000035],
            [71.262225224000076, 33.807807991000061],
            [71.262796294000054, 33.807208366000054],
            [71.263995542000032, 33.806151886000066],
            [71.265137684000081, 33.805095405000031],
            [71.26602284300003, 33.804096031000029],
            [71.267250645000047, 33.802497034000055],
            [71.267907376000039, 33.801554767000027],
            [71.268478447000064, 33.800526840000032],
            [71.268792536000035, 33.799584573000061],
            [71.269049518000031, 33.798385325000027],
            [71.269420713000045, 33.797414505000063],
            [71.269763356000055, 33.79690054200006],
            [71.270191659000034, 33.796243810000078],
            [71.271219586000029, 33.795615633000068],
            [71.272076192000043, 33.795073116000026],
            [71.272818584000049, 33.794616259000065],
            [71.273503868000034, 33.79418795600003],
            [71.274474689000044, 33.793788207000034],
            [71.275873812000043, 33.793274243000042],
            [71.277073060000077, 33.792845940000063],
            [71.278472183000076, 33.792017888000032],
            [71.280185395000046, 33.790961407000054],
            [71.281784393000066, 33.789990587000034],
            [71.28324062300004, 33.789191088000052],
            [71.284468425000057, 33.788277375000064],
            [71.285496352000052, 33.787477876000025],
            [71.286895475000051, 33.786307181000041],
            [71.287923402000047, 33.785507682000059],
            [71.288922776000049, 33.784365541000057],
            [71.289865042000031, 33.78348038200005],
            [71.290921523000065, 33.782566669000062],
            [71.292149325000082, 33.781338867000045],
            [71.293120145000046, 33.780539368000063],
            [71.29383398300007, 33.780111065000028],
            [71.294604928000069, 33.779739869000025],
            [71.295347320000076, 33.779597101000036],
            [71.296261033000064, 33.779454334000036],
            [71.297431728000049, 33.779111691000026],
            [71.298488209000084, 33.77856917400004],
            [71.299544689000072, 33.777684015000034],
            [71.300372742000036, 33.776827409000077],
            [71.301200794000067, 33.776256338000053],
            [71.30234293500007, 33.775685268000075],
            [71.303713505000076, 33.774800108000079],
            [71.305740806000074, 33.773572306000062],
            [71.307910874000072, 33.772116076000032],
            [71.309795407000081, 33.770888274000072],
            [71.311565726000083, 33.769660472000055],
            [71.31290774200005, 33.768546885000035],
            [71.31387856200007, 33.767604618000064],
            [71.31510636400003, 33.766690905000075],
            [71.31587730900003, 33.766034174000026],
            [71.316962343000057, 33.765320336000059],
            [71.318075931000067, 33.764463730000045],
            [71.319103858000062, 33.763607124000032],
            [71.319817697000076, 33.762893285000075],
            [71.320445874000029, 33.762407875000065],
            [71.320731410000064, 33.761951019000037],
            [71.321102606000068, 33.761408502000052],
            [71.321188266000036, 33.760894538000059],
            [71.321331034000082, 33.760437682000031],
            [71.321530909000046, 33.759952272000078],
            [71.321787890000053, 33.759466862000068],
            [71.322473175000084, 33.758781577000036],
            [71.323044246000052, 33.758210506000069],
            [71.323758084000076, 33.757582329000059],
            [71.324614690000033, 33.756582955000056],
            [71.325642617000028, 33.755869117000032],
            [71.326670544000081, 33.755183832000057],
            [71.327841239000065, 33.754612761000033],
            [71.328355203000058, 33.754298672000061],
            [71.328840613000068, 33.754127351000079],
            [71.329297469000039, 33.754127351000079],
            [71.329725772000074, 33.754241565000029],
            [71.330325396000035, 33.754698422000047],
            [71.331010681000066, 33.755298046000064],
            [71.331553198000051, 33.755783456000074],
            [71.332209929000044, 33.75632597300006],
            [71.332895214000075, 33.757239686000048],
            [71.333209303000046, 33.757839310000065],
            [71.333523392000075, 33.758210506000069],
            [71.334237230000042, 33.758895791000043],
            [71.335407925000084, 33.760037932000046],
            [71.336749941000051, 33.76123718100007],
            [71.337549440000032, 33.762065233000044],
            [71.33863447400006, 33.763635677000025],
            [71.340062151000041, 33.765320336000059],
            [71.340519007000069, 33.765691532000062],
            [71.34083309600004, 33.766148388000033],
            [71.341518381000071, 33.76626260200004],
            [71.342374987000028, 33.766234049000047],
            [71.342974611000045, 33.766148388000033],
            [71.343260146000034, 33.765948513000069],
            [71.344116752000048, 33.765577317000066],
            [71.345201786000075, 33.765348889000052],
            [71.34637248100006, 33.764892033000024],
            [71.347229087000073, 33.764577944000052],
            [71.348314121000044, 33.764235301000042],
            [71.349286946000063, 33.764150708000045],
            [71.349231248000081, 33.76381652200007],
            [71.349117034000074, 33.76333111200006],
            [71.349002820000067, 33.76270293400006],
            [71.348974266000084, 33.762188970000068],
            [71.348974266000084, 33.76187488100004],
            [71.349002820000067, 33.761532239000076],
            [71.349145587000066, 33.761218150000047],
            [71.349545337000052, 33.760932615000058],
            [71.350173515000051, 33.760761294000076],
            [71.350687478000054, 33.760361544000034],
            [71.351115781000033, 33.759847581000031],
            [71.351458424000043, 33.759362171000078],
            [71.351544084000068, 33.758848207000028],
            [71.35157263800005, 33.758020155000054],
            [71.351629745000082, 33.757506191000061],
            [71.351943834000053, 33.75716354900004],
            [71.352514904000031, 33.75682090600003],
            [71.353057421000074, 33.756449710000027],
            [71.353657045000034, 33.755935747000024],
            [71.354085348000069, 33.755621658000052],
            [71.354742080000051, 33.755079141000067],
            [71.354941954000083, 33.754793606000078],
            [71.354970508000065, 33.754393856000036],
            [71.354884847000051, 33.754022660000032],
            [71.354770633000044, 33.753708571000061],
            [71.354742080000051, 33.753166054000076],
            [71.354684973000076, 33.752652091000073],
            [71.354684973000076, 33.752138127000023],
            [71.354770633000044, 33.75165271700007],
            [71.354827740000076, 33.751053093000053],
            [71.354827740000076, 33.75053912900006],
            [71.354799187000083, 33.750025166000057],
            [71.354684973000076, 33.749225667000076],
            [71.35442799100008, 33.746198993000064],
            [71.354285223000034, 33.744057478000059],
            [71.353828367000062, 33.739260484000056],
            [71.353457171000059, 33.736119596000037],
            [71.353114528000049, 33.734749026000031],
            [71.353000314000042, 33.733407010000064],
            [71.352943207000067, 33.732007887000066],
            [71.352800439000077, 33.730922853000038],
            [71.352629118000038, 33.729666498000029],
            [71.352572011000063, 33.728809892000072],
            [71.352572011000063, 33.728181714000073],
            [71.352714779000053, 33.72766775100007],
            [71.353028868000081, 33.727353662000041],
            [71.353542831000084, 33.727153787000077],
            [71.354056795000076, 33.726896805000024],
            [71.354627866000044, 33.72655416300006],
            [71.355598686000064, 33.726097306000042],
            [71.35662661300006, 33.725526236000064],
            [71.357426112000041, 33.725097933000029],
            [71.358596806000037, 33.724412648000055],
            [71.359938822000061, 33.723927238000044],
            [71.360881089000031, 33.723584596000023],
            [71.362023230000034, 33.72309918600007],
            [71.363279586000033, 33.722699436000028],
            [71.364307513000028, 33.722128366000049],
            [71.365192672000035, 33.721814277000078],
            [71.36607783200003, 33.721557295000025],
            [71.366506135000066, 33.721414527000036],
            [71.367077205000044, 33.721357420000061],
            [71.367562615000054, 33.721043331000033],
            [71.367990918000032, 33.720729242000061],
            [71.368504882000082, 33.720443707000072],
            [71.368961738000053, 33.720158172000026],
            [71.369618470000034, 33.71990119000003],
            [71.370018219000031, 33.719558548000066],
            [71.370417968000083, 33.719330119000062],
            [71.370817718000069, 33.719215905000056],
            [71.371160360000033, 33.719330119000062],
            [71.371166121000044, 33.719336927000029],
            [71.371167692000029, 33.719337451000058],
            [71.371475838000038, 33.719701624000038],
            [71.371731431000057, 33.719758422000041],
            [71.372045520000029, 33.71978697600008],
            [71.37238816200005, 33.71978697600008],
            [71.37273080500006, 33.719729869000048],
            [71.373102000000074, 33.719644208000034],
            [71.373501750000059, 33.719587101000059],
            [71.374215588000084, 33.719472887000052],
            [71.375186408000047, 33.71930156600007],
            [71.376014461000068, 33.719215905000056],
            [71.376928174000057, 33.719130245000031],
            [71.377756226000031, 33.718958924000049],
            [71.378555725000069, 33.718844709000052],
            [71.379298117000076, 33.71867338800007],
            [71.38012616900005, 33.718416406000074],
            [71.380897115000039, 33.71804521100006],
            [71.381753720000063, 33.717616908000025],
            [71.382753094000066, 33.717217158000039],
            [71.38358114600004, 33.716903069000068],
            [71.384294985000054, 33.716817409000043],
            [71.384808948000057, 33.716389106000065],
            [71.38518014400006, 33.71598935600008],
            [71.38552278700007, 33.715446839000037],
            [71.385637001000077, 33.715047090000041],
            [71.385637001000077, 33.714447466000024],
            [71.385465680000038, 33.713419538000039],
            [71.385151591000067, 33.712477272000058],
            [71.384637627000075, 33.711306577000073],
            [71.384095110000032, 33.710364311000035],
            [71.383838128000036, 33.709793240000067],
            [71.383695361000036, 33.70933638300005],
            [71.383695361000036, 33.708822420000047],
            [71.383781021000061, 33.70822279600003],
            [71.383980896000082, 33.707708832000037],
            [71.384237878000079, 33.707109208000077],
            [71.384494860000075, 33.706595244000027],
            [71.384666181000057, 33.706024174000049],
            [71.384666181000057, 33.705481657000064],
            [71.384609074000082, 33.704824925000025],
            [71.384380645000078, 33.704396622000047],
            [71.383781021000061, 33.70368278400008],
            [71.383324165000033, 33.702911839000024],
            [71.382553219000044, 33.701998126000035],
            [71.38172516700007, 33.700913092000064],
            [71.380840007000074, 33.699656736000065],
            [71.380468812000061, 33.698828684000034],
            [71.380211830000064, 33.698229060000074],
            [71.380211830000064, 33.697743650000064],
            [71.380268937000039, 33.697201132000032],
            [71.38061157900006, 33.696772830000043],
            [71.381096989000071, 33.696515848000047],
            [71.381896488000052, 33.695859117000055],
            [71.382895862000055, 33.69517383200008],
            [71.38392378900005, 33.694631315000038],
            [71.38469473400005, 33.69420301200006],
            [71.385465680000038, 33.693660495000074],
            [71.386322286000052, 33.693203638000057],
            [71.387207445000058, 33.69260401400004],
            [71.388092604000065, 33.692232818000036],
            [71.388863550000053, 33.691861622000033],
            [71.389720156000067, 33.69134765900003],
            [71.390405441000041, 33.690919356000052],
            [71.390890851000051, 33.690576713000041],
            [71.391861671000072, 33.690148410000063],
            [71.392661170000054, 33.689805768000042],
            [71.393403561000071, 33.68963444700006],
            [71.394231614000034, 33.689320358000032],
            [71.395059666000066, 33.68914903700005],
            [71.395973379000054, 33.688863501000071],
            [71.397058413000082, 33.688378091000061],
            [71.397857912000063, 33.687949788000026],
            [71.398571751000077, 33.687464378000072],
            [71.399314142000037, 33.686978968000062],
            [71.399970874000076, 33.686693433000073],
            [71.40079892600005, 33.686350791000052],
            [71.401455657000042, 33.685979595000049],
            [71.402312263000056, 33.685722613000053],
            [71.403168869000069, 33.685437077000074],
            [71.403739940000037, 33.685151542000028],
            [71.404482332000043, 33.684837453000057],
            [71.405824348000067, 33.684209276000047],
            [71.406309758000077, 33.683980847000043],
            [71.407080703000076, 33.683695312000054],
            [71.407594667000069, 33.683267009000076],
            [71.408108630000072, 33.682781599000066],
            [71.408793915000047, 33.682267635000073],
            [71.409479200000078, 33.681810779000045],
            [71.410107377000031, 33.681496690000074],
            [71.410592787000041, 33.681096941000078],
            [71.411220965000041, 33.680668638000043],
            [71.411620714000037, 33.680097567000075],
            [71.411792036000065, 33.679383729000051],
            [71.412049017000072, 33.678355802000056],
            [71.412049017000072, 33.677670517000024],
            [71.412020464000079, 33.676956679000057],
            [71.411649268000076, 33.676299947000075],
            [71.411192411000059, 33.675614663000033],
            [71.410878641000068, 33.674987122000061],
            [71.410878323000077, 33.674986485000034],
            [71.410255033000055, 33.674391526000079],
            [71.410250145000077, 33.674386861000073],
            [71.409625474000052, 33.673705401000063],
            [71.409621967000078, 33.673701576000042],
            [71.408936682000046, 33.673073398000042],
            [71.408251398000061, 33.67241666700005],
            [71.407423345000041, 33.671645722000051],
            [71.406795168000031, 33.671188865000033],
            [71.406224097000063, 33.670874776000062],
            [71.405767240000046, 33.670760562000055],
            [71.405310384000074, 33.670646348000048],
            [71.405032180000035, 33.670368145000054],
            [71.405024849000029, 33.670360813000059],
            [71.404567992000068, 33.670046724000031],
            [71.40411113600004, 33.669789742000034],
            [71.403747271000043, 33.669425878000027],
            [71.403739940000037, 33.669418546000031],
            [71.403430478000075, 33.669193483000072],
            [71.403425851000065, 33.669190118000074],
            [71.402999673000068, 33.668565057000023],
            [71.40299754800003, 33.668561940000075],
            [71.402403871000047, 33.668081345000076],
            [71.40239792400007, 33.668076530000064],
            [71.402083835000042, 33.667505460000029],
            [71.401741193000078, 33.667105710000044],
            [71.401712639000039, 33.666734514000041],
            [71.401712639000039, 33.666363318000037],
            [71.401883960000077, 33.666049230000056],
            [71.402255156000081, 33.665763694000077],
            [71.402712013000041, 33.665392498000074],
            [71.403454404000058, 33.664964195000039],
            [71.403739940000037, 33.66467866000005],
            [71.403739940000037, 33.664336018000029],
            [71.40376849300003, 33.66390771500005],
            [71.403882707000037, 33.66356507200004],
            [71.404225350000047, 33.663136769000062],
            [71.404796420000082, 33.66296544800008],
            [71.405396045000032, 33.66282268100008],
            [71.406109883000056, 33.662594252000076],
            [71.406652400000041, 33.662394378000045],
            [71.407194917000083, 33.662223056000073],
            [71.407480452000073, 33.661880414000052],
            [71.407794541000044, 33.661423557000035],
            [71.407823095000083, 33.660909594000032],
            [71.407880202000058, 33.660309970000071],
            [71.407880202000058, 33.659738899000047],
            [71.407937309000033, 33.658882293000033],
            [71.407908755000051, 33.657825813000045],
            [71.407908755000051, 33.657169081000063],
            [71.407908755000051, 33.656483797000078],
            [71.407908755000051, 33.655855619000079],
            [71.408022969000058, 33.655370209000068],
            [71.408022969000058, 33.65457071000003],
            [71.40805152300004, 33.654142407000052],
            [71.407938142000035, 33.653688883000029],
            [71.407937309000033, 33.653685550000034],
            [71.407568862000062, 33.653458814000032],
            [71.40756611300003, 33.653457122000077],
            [71.407194917000083, 33.653000266000049],
            [71.407166364000034, 33.652571963000071],
            [71.407166364000034, 33.652143660000036],
            [71.406795168000031, 33.651458375000061],
            [71.406569268000055, 33.65111952500007],
            [71.406566739000084, 33.65111573300004],
            [71.406024222000042, 33.650715983000055],
            [71.405653026000039, 33.65065887600008],
            [71.405139063000036, 33.650687430000062],
            [71.404653653000082, 33.650744537000037],
            [71.404196796000065, 33.651030072000026],
            [71.403882707000037, 33.651344161000054],
            [71.403540065000072, 33.651401268000029],
            [71.402968994000048, 33.651315607000072],
            [71.402570292000064, 33.65111625600008],
            [71.402569245000052, 33.65111573300004],
            [71.402232468000079, 33.65075089100003],
            [71.402226603000031, 33.650744537000037],
            [71.401798300000053, 33.650459001000058],
            [71.40128433600006, 33.650430448000066],
            [71.400713265000036, 33.650573215000065],
            [71.400399177000054, 33.651030072000026],
            [71.400142195000058, 33.651458375000061],
            [71.399828106000029, 33.652029446000029],
            [71.39968533800004, 33.652400642000032],
            [71.399257035000062, 33.652429195000025],
            [71.398885839000059, 33.652429195000025],
            [71.398086341000067, 33.652400642000032],
            [71.397372502000053, 33.65237208800005],
            [71.396744325000043, 33.65222932000006],
            [71.396294118000071, 33.652085254000042],
            [71.396248924000076, 33.652070792000075],
            [71.396243259000073, 33.652073965000056],
            [71.391995000000065, 33.654453000000046],
            [71.39176481800007, 33.654395461000036],
            [71.391723909000063, 33.654385235000063],
            [71.375609434000069, 33.650357050000025],
            [71.375596603000076, 33.650353842000072],
            [71.375583773000074, 33.650350636000042],
            [71.373441000000071, 33.649815000000046],
            [71.363501000000042, 33.645839000000024],
            [71.359525000000076, 33.641200000000026],
            [71.359525000000076, 33.627285000000029],
            [71.352374595000072, 33.624901771000054],
            [71.349585079000065, 33.623972026000047],
            [71.349585000000047, 33.623972000000037],
            [71.338983000000042, 33.624634000000071],
            [71.330525453000064, 33.629870203000053],
            [71.330513920000044, 33.629877343000032],
            [71.325068000000044, 33.633249000000035],
            [71.315565508000077, 33.639727617000062],
            [71.315559118000067, 33.639731973000039],
            [71.315552729000046, 33.639736329000073],
            [71.310490000000073, 33.643188000000066],
            [71.297237000000052, 33.647164000000032],
            [71.289640274000078, 33.649696083000038],
            [71.289589869000054, 33.649712884000053],
            [71.281333000000075, 33.652465000000063],
            [71.266755000000046, 33.661742000000061],
            [71.248201000000051, 33.672345000000064],
            [71.247581583000056, 33.67294307800006],
            [71.247575687000051, 33.672948771000051],
            [71.247569790000057, 33.672954464000043],
            [71.22898500000008, 33.690899000000059],
            [71.223021000000074, 33.706802000000039],
            [71.222519204000037, 33.70746226600005],
            [71.222515173000033, 33.707467571000052],
            [71.214703051000072, 33.717746809000062],
            [71.210431000000028, 33.72336800000005],
            [71.202479000000039, 33.724693000000059],
            [71.189889000000051, 33.729994000000033],
            [71.17319838700007, 33.733332291000067],
            [71.170010000000048, 33.733970000000056],
            [71.154769000000044, 33.735295000000065],
            [71.14748000000003, 33.742585000000076],
            [71.134227000000067, 33.745235000000037],
            [71.124950000000069, 33.742585000000076],
            [71.123691543000064, 33.736292715000047],
            [71.12296200000003, 33.732645000000048],
            [71.126938000000052, 33.725356000000033],
            [71.132902000000058, 33.720717000000036],
            [71.138203000000033, 33.70746500000007],
            [71.137540000000058, 33.690236000000027],
            [71.136581505000038, 33.684804688000042],
            [71.135552000000075, 33.678971000000047],
            [71.150131000000044, 33.668369000000041],
            [71.166862104000074, 33.655355192000059],
            [71.167027051000048, 33.65522689200003],
            [71.168022000000065, 33.654453000000046],
            [71.17862400000007, 33.646502000000055],
            [71.168946413000072, 33.645385333000036],
            [71.168941372000063, 33.645384751000051],
            [71.168933697000057, 33.645383866000032],
            [71.161395000000084, 33.644514000000072],
            [71.142841000000033, 33.648489000000041],
            [71.128263000000061, 33.648489000000041],
            [71.112360000000081, 33.640538000000049],
            [71.092481000000078, 33.62794800000006],
            [71.086517000000072, 33.618671000000063],
            [71.091155000000072, 33.612707000000057],
            [71.105071000000066, 33.609394000000066],
            [71.107059000000049, 33.602767000000028],
            [71.117661000000055, 33.604092000000037],
            [71.127601000000084, 33.604755000000068],
            [71.132902000000058, 33.599454000000037],
            [71.131227000000081, 33.59777900000006],
            [71.126275000000078, 33.592828000000054],
            [71.124477099000046, 33.592763752000053],
            [71.107722000000081, 33.59216500000008],
            [71.091818000000046, 33.587526000000025],
            [71.085413450000033, 33.585503912000036],
            [71.079228000000057, 33.583551000000057],
            [71.060011000000031, 33.582225000000051],
            [71.050072000000057, 33.574936000000037],
            [71.037482000000068, 33.562346000000048],
            [71.036425548000068, 33.562032997000074],
            [71.019590000000051, 33.557045000000073],
            [71.00832500000007, 33.546443000000068],
            [70.989109000000042, 33.546443000000068],
            [70.969892000000073, 33.546443000000068],
            [70.950013000000069, 33.554394000000059],
            [70.931459000000075, 33.558370000000025],
            [70.916881000000046, 33.561021000000039],
            [70.898990000000083, 33.556382000000042],
            [70.877785000000074, 33.558370000000025],
            [70.861882000000037, 33.557708000000048],
            [70.85834785000003, 33.557994560000054],
            [70.837364000000036, 33.559696000000031],
            [70.837323641000069, 33.559715217000075],
            [70.837280273000033, 33.559692383000026],
            [70.836288812000078, 33.56020794300008],
            [70.832702637000068, 33.562072754000042],
            [70.824506209000049, 33.565818142000069],
            [70.823486328000058, 33.566284180000025],
            [70.822820583000066, 33.566728010000077],
            [70.812316895000038, 33.573730469000054],
            [70.800903320000032, 33.576293945000032],
            [70.789672852000081, 33.579711914000029],
            [70.779655182000056, 33.584633890000077],
            [70.779113770000038, 33.584899902000075],
            [70.773708879000083, 33.585257842000033],
            [70.769897461000028, 33.585510254000042],
            [70.756394224000076, 33.583447801000034],
            [70.752773411000078, 33.582881925000038],
            [70.752759040000058, 33.582892571000059],
            [70.750555182000028, 33.584525123000049],
            [70.750554378000061, 33.584525677000045],
            [70.750379558000077, 33.584646243000066],
            [70.750372751000043, 33.584650937000049],
            [70.750364583000078, 33.584657134000054],
            [70.749544699000069, 33.585279115000048],
            [70.748088469000038, 33.586307042000044],
            [70.746489471000075, 33.587163648000057],
            [70.744776259000048, 33.588191575000053],
            [70.743148708000035, 33.588905414000067],
            [70.741949459000068, 33.589219502000049],
            [70.740921532000073, 33.58941937700007],
            [70.73977939100007, 33.589619252000034],
            [70.738294607000057, 33.589676359000066],
            [70.737495108000076, 33.589790573000073],
            [70.736638502000062, 33.59001900100003],
            [70.735553468000035, 33.590818500000069],
            [70.734211452000068, 33.592131963000043],
            [70.733868810000047, 33.592617373000053],
            [70.733583275000058, 33.593102783000063],
            [70.733526167000036, 33.593616746000066],
            [70.733554721000075, 33.594187817000034],
            [70.733668935000082, 33.594701780000037],
            [70.734068685000068, 33.595215744000029],
            [70.734611202000053, 33.595901029000061],
            [70.735267933000046, 33.596786188000067],
            [70.736181646000034, 33.597757008000031],
            [70.737266680000062, 33.598670721000076],
            [70.738123286000075, 33.599441667000065],
            [70.739208320000046, 33.600498147000053],
            [70.739922159000059, 33.601668842000038],
            [70.740664550000076, 33.602896644000054],
            [70.741121407000037, 33.603867464000075],
            [70.741349835000051, 33.604781177000064],
            [70.741549710000072, 33.606208854000045],
            [70.741578263000065, 33.607179674000065],
            [70.741578263000065, 33.608293261000028],
            [70.741464049000058, 33.609378296000045],
            [70.741264174000037, 33.610663205000037],
            [70.741264174000037, 33.611291382000047],
            [70.740950086000055, 33.612462077000032],
            [70.740693104000059, 33.613375790000077],
            [70.740721657000051, 33.613918307000063],
            [70.74100719300003, 33.614289503000066],
            [70.741464049000058, 33.61448937800003],
            [70.742092227000057, 33.614689252000062],
            [70.742920279000032, 33.61497478800004],
            [70.743805439000084, 33.615431644000068],
            [70.745004687000062, 33.615859947000047],
            [70.746717899000032, 33.616602339000053],
            [70.747631612000077, 33.617259070000046],
            [70.749173503000065, 33.617915802000027],
            [70.749716020000051, 33.61842976500003],
            [70.750144323000029, 33.618800961000034],
            [70.750515519000032, 33.619657567000047],
            [70.750886715000036, 33.620228638000071],
            [70.751400678000039, 33.620714048000025],
            [70.751771874000042, 33.621256565000067],
            [70.752171624000084, 33.621684868000045],
            [70.752999676000059, 33.622427260000052],
            [70.753256658000055, 33.622912670000062],
            [70.753599300000076, 33.623597954000047],
            [70.753599300000076, 33.624140471000032],
            [70.753627854000058, 33.62508273800006],
            [70.753485086000069, 33.626110665000056],
            [70.75331376500003, 33.627452681000079],
            [70.752999676000059, 33.629108786000074],
            [70.752771248000045, 33.630393695000066],
            [70.752400052000041, 33.631107533000034],
            [70.751543446000028, 33.632592317000046],
            [70.751172250000081, 33.633363262000046],
            [70.751000929000043, 33.633991440000045],
            [70.750943822000067, 33.634533957000031],
            [70.751000929000043, 33.63516213500003],
            [70.751000929000043, 33.635875973000054],
            [70.751000929000043, 33.636475597000072],
            [70.750772500000039, 33.638160255000059],
            [70.750686840000071, 33.63910252200003],
            [70.750515519000032, 33.641101269000046],
            [70.75048696500005, 33.641729447000046],
            [70.750458412000057, 33.642500392000045],
            [70.750601179000057, 33.644327818000079],
            [70.750601179000057, 33.645098764000068],
            [70.750601179000057, 33.645584174000078],
            [70.750401305000082, 33.645983923000074],
            [70.749801680000076, 33.646155244000056],
            [70.748973628000044, 33.646155244000056],
            [70.748316897000052, 33.646155244000056],
            [70.747403184000063, 33.646126691000063],
            [70.746603685000082, 33.646041030000049],
            [70.744405063000045, 33.645469959000025],
            [70.743063047000078, 33.644955996000078],
            [70.741521156000033, 33.64464190700005],
            [70.740093480000041, 33.644242158000054],
            [70.739179767000053, 33.643985176000058],
            [70.738066179000043, 33.643870962000051],
            [70.73598177100007, 33.643842408000069],
            [70.734011577000047, 33.644013729000051],
            [70.732926543000076, 33.644013729000051],
            [70.731927170000063, 33.644013729000051],
            [70.730499493000082, 33.644070836000026],
            [70.729100370000083, 33.644270711000047],
            [70.727929675000041, 33.644384925000054],
            [70.727044516000035, 33.644499139000061],
            [70.726473445000067, 33.644756121000057],
            [70.725731053000061, 33.645527067000046],
            [70.724988662000044, 33.646126691000063],
            [70.724389037000037, 33.646840529000031],
            [70.723760860000084, 33.647240278000027],
            [70.722761486000081, 33.64761147400003],
            [70.721961987000043, 33.647839903000033],
            [70.720991167000079, 33.648296759000061],
            [70.720048901000041, 33.648924937000061],
            [70.719249402000059, 33.649467454000046],
            [70.718050153000036, 33.650409720000027],
            [70.71736486900005, 33.650638149000031],
            [70.716708137000069, 33.650895130000038],
            [70.715965746000052, 33.651152112000034],
            [70.715080586000056, 33.651180666000073],
            [70.713995552000029, 33.651123559000041],
            [70.713338821000036, 33.650723809000056],
            [70.712682089000054, 33.650295506000077],
            [70.711682716000041, 33.649781543000074],
            [70.711054538000042, 33.649353240000039],
            [70.710426360000042, 33.64872506200004],
            [70.70976962900005, 33.648239652000029],
            [70.709112898000058, 33.647782796000058],
            [70.708456167000065, 33.647383046000073],
            [70.70757100700007, 33.647126064000076],
            [70.706685848000063, 33.646954743000038],
            [70.705800688000068, 33.646954743000038],
            [70.704829868000047, 33.646926190000045],
            [70.703830495000034, 33.646926190000045],
            [70.702774014000056, 33.646983297000077],
            [70.70140344400005, 33.647211725000034],
            [70.700461178000069, 33.647640028000069],
            [70.699518911000041, 33.64809688400004],
            [70.698548091000077, 33.64872506200004],
            [70.697748592000039, 33.648953490000054],
            [70.696663558000068, 33.649353240000039],
            [70.695321542000045, 33.649810096000067],
            [70.694579150000038, 33.650295506000077],
            [70.693465563000075, 33.650895130000038],
            [70.692951599000082, 33.651637522000044],
            [70.691952226000069, 33.652408468000033],
            [70.69129549400003, 33.653236520000064],
            [70.690381781000042, 33.654093126000078],
            [70.689639389000035, 33.654635643000063],
            [70.688754230000029, 33.655178160000048],
            [70.686869697000077, 33.656177534000051],
            [70.686212966000028, 33.656577283000047],
            [70.685185039000032, 33.656862819000025],
            [70.684528307000051, 33.656862819000025],
            [70.683500380000055, 33.656862819000025],
            [70.682472453000059, 33.656919926000057],
            [70.681301758000075, 33.657148354000071],
            [70.680388045000029, 33.657348229000036],
            [70.679445779000048, 33.657662317000074],
            [70.678303637000056, 33.658176281000067],
            [70.67727571000006, 33.658861566000041],
            [70.676676086000043, 33.65917565500007],
            [70.675876587000062, 33.659489743000051],
            [70.674763000000041, 33.65966106500008],
            [70.673563751000074, 33.659860939000055],
            [70.672621485000036, 33.660089368000058],
            [70.671821986000054, 33.66026068900004],
            [70.670965380000041, 33.660688992000075],
            [70.66953770300006, 33.661631258000057],
            [70.668709651000029, 33.662287990000038],
            [70.667538956000044, 33.663258810000059],
            [70.667110653000066, 33.663915541000051],
            [70.666482475000066, 33.664229630000079],
            [70.665939958000081, 33.664543719000051],
            [70.665397441000039, 33.664886361000072],
            [70.664883478000036, 33.665286110000068],
            [70.664112532000047, 33.665914288000067],
            [70.663455801000055, 33.66642825200006],
            [70.662856177000037, 33.666685233000067],
            [70.662170892000063, 33.666713787000049],
            [70.661542714000063, 33.666742340000042],
            [70.66102875100006, 33.666685233000067],
            [70.660286359000054, 33.666713787000049],
            [70.659772395000061, 33.66657101900006],
            [70.659486860000072, 33.666256930000031],
            [70.659286985000051, 33.66580007400006],
            [70.659087111000076, 33.665371771000025],
            [70.659001450000062, 33.665000575000079],
            [70.658773022000048, 33.66482925400004],
            [70.658373272000063, 33.664800700000058],
            [70.657916416000035, 33.664800700000058],
            [70.657630881000046, 33.664629379000075],
            [70.657059810000078, 33.664657933000058],
            [70.656831382000064, 33.664686486000051],
            [70.656403079000029, 33.665057682000054],
            [70.656003329000043, 33.665543092000064],
            [70.65563213300004, 33.665742967000028],
            [70.655089616000055, 33.66580007400006],
            [70.654461439000045, 33.66580007400006],
            [70.653804707000063, 33.665657306000071],
            [70.65329074400006, 33.665514539000071],
            [70.652605459000029, 33.665514539000071],
            [70.651948728000036, 33.665514539000071],
            [70.651149229000055, 33.665971395000042],
            [70.65057815800003, 33.666256930000031],
            [70.650092748000077, 33.666485359000035],
            [70.649578785000074, 33.666485359000035],
            [70.649064821000081, 33.666513912000028],
            [70.648293876000082, 33.666942215000063],
            [70.647979787000054, 33.667170643000077],
            [70.647437270000069, 33.667256304000034],
            [70.647037520000083, 33.667256304000034],
            [70.646552110000073, 33.667227751000041],
            [70.64618091400007, 33.667113536000045],
            [70.645609844000035, 33.666999322000038],
            [70.645181541000056, 33.66705642900007],
            [70.644867452000028, 33.667227751000041],
            [70.644781791000071, 33.667570393000062],
            [70.644610470000032, 33.668112910000048],
            [70.644410596000057, 33.668741088000047],
            [70.644382042000075, 33.669140837000043],
            [70.643782418000058, 33.669369265000057],
            [70.64318279400004, 33.669369265000057],
            [70.64269738400003, 33.669369265000057],
            [70.642154867000045, 33.669626247000053],
            [70.641983545000073, 33.669940336000025],
            [70.641441028000031, 33.670482853000067],
            [70.641155493000042, 33.67085404900007],
            [70.640727190000064, 33.670939710000027],
            [70.640241780000053, 33.670996817000059],
            [70.639813477000075, 33.670882603000052],
            [70.639413727000033, 33.67085404900007],
            [70.63878555000008, 33.670768388000056],
            [70.638528568000083, 33.670825495000031],
            [70.638414354000076, 33.67085404900007],
            [70.63812881900003, 33.671168138000041],
            [70.637843283000052, 33.671539334000045],
            [70.637357873000042, 33.67196763700008],
            [70.636929570000063, 33.671996190000073],
            [70.636301393000053, 33.672053297000048],
            [70.635787429000061, 33.672453047000033],
            [70.635159251000061, 33.67256726100004],
            [70.634559627000044, 33.67256726100004],
            [70.633817235000038, 33.672767136000061],
            [70.633160504000045, 33.673081224000043],
            [70.632303898000032, 33.673081224000043],
            [70.631704274000072, 33.673109778000025],
            [70.631304525000076, 33.673566635000043],
            [70.631047543000079, 33.674251919000028],
            [70.630733454000051, 33.674451794000049],
            [70.630105276000052, 33.674680222000063],
            [70.629676973000073, 33.675165632000073],
            [70.629505652000034, 33.67542261400007],
            [70.629105903000038, 33.676050792000069],
            [70.628506278000032, 33.67665041600003],
            [70.627820994000047, 33.677564129000075],
            [70.627221370000029, 33.678306521000025],
            [70.62659319200003, 33.679248787000063],
            [70.625907907000055, 33.680162500000051],
            [70.625336836000031, 33.680990553000072],
            [70.624708659000078, 33.681732945000078],
            [70.624166142000036, 33.682075587000043],
            [70.62313821500004, 33.682389676000071],
            [70.62216739400003, 33.682532443000071],
            [70.621310788000073, 33.682760872000074],
            [70.620482736000042, 33.683074961000045],
            [70.619854558000043, 33.68376024500003],
            [70.61919782700005, 33.684445530000062],
            [70.618826631000047, 33.685159368000029],
            [70.61871241700004, 33.686044528000025],
            [70.618541096000058, 33.68710100800007],
            [70.618569649000051, 33.68832881000003],
            [70.618483989000083, 33.689128309000068],
            [70.61822700700003, 33.690156236000064],
            [70.618084239000041, 33.691098503000035],
            [70.618084239000041, 33.69212643000003],
            [70.618084239000041, 33.693068697000058],
            [70.618141346000073, 33.693753981000043],
            [70.618369775000076, 33.694382159000043],
            [70.618655310000065, 33.695210211000074],
            [70.619055060000051, 33.696009710000055],
            [70.619597577000036, 33.696923423000044],
            [70.619768898000075, 33.697551601000043],
            [70.619768898000075, 33.698065565000036],
            [70.619940219000057, 33.698922171000049],
            [70.619911665000075, 33.700578275000055],
            [70.61982600500005, 33.70257702300006],
            [70.619768898000075, 33.704204574000073],
            [70.619597577000036, 33.706146214000057],
            [70.61948336200004, 33.707202695000035],
            [70.619283488000065, 33.707602444000031],
            [70.618969399000036, 33.708059301000048],
            [70.618369775000076, 33.708516157000076],
            [70.618084239000041, 33.709058674000062],
            [70.617998579000073, 33.709515531000079],
            [70.617998579000073, 33.709943834000057],
            [70.617998579000073, 33.710486351000043],
            [70.618084239000041, 33.711685599000077],
            [70.618084239000041, 33.712913401000037],
            [70.618084239000041, 33.713712900000075],
            [70.618084239000041, 33.714312524000036],
            [70.618027132000066, 33.714712273000032],
            [70.617570276000038, 33.715140576000067],
            [70.61699920500007, 33.715340451000031],
            [70.615914171000043, 33.715740201000074],
            [70.614515048000044, 33.71613995000007],
            [70.611745355000039, 33.716939449000051],
            [70.608318932000032, 33.717881715000033],
            [70.606634273000054, 33.718509893000032],
            [70.605577793000066, 33.719023857000025],
            [70.605149490000031, 33.719537820000028],
            [70.604806847000077, 33.720394426000041],
            [70.604235777000042, 33.721393800000044],
            [70.603607599000043, 33.721936317000029],
            [70.60260822500004, 33.72227895900005],
            [70.601694512000051, 33.722564495000029],
            [70.601066335000041, 33.722992798000064],
            [70.600495264000074, 33.723620975000074],
            [70.600095515000078, 33.724306260000048],
            [70.599467337000078, 33.724734563000027],
            [70.599010480000061, 33.724791670000059],
            [70.598325196000076, 33.724905884000066],
            [70.596811858000081, 33.724934438000048],
            [70.595641164000028, 33.724877331000073],
            [70.595070093000061, 33.724934438000048],
            [70.594584683000051, 33.72499154500008],
            [70.594184934000054, 33.725334187000044],
            [70.593756631000076, 33.726048025000068],
            [70.592842918000031, 33.72767557700007],
            [70.591957758000035, 33.72967432400003],
            [70.590672849000043, 33.731872946000067],
            [70.589844797000069, 33.733386283000073],
            [70.589102405000062, 33.734242889000029],
            [70.587731835000056, 33.735984654000049],
            [70.586618248000036, 33.737155349000034],
            [70.585904409000079, 33.738411705000033],
            [70.58524767800003, 33.740010702000063],
            [70.585190571000055, 33.740524666000056],
            [70.585190571000055, 33.740924415000052],
            [70.585390446000076, 33.741324165000037],
            [70.585847302000047, 33.74183812800004],
            [70.586304159000065, 33.74246630600004],
            [70.587103658000046, 33.74343712600006],
            [70.58844567400007, 33.744636375000027],
            [70.589416494000034, 33.745550088000073],
            [70.590330207000079, 33.746149712000033],
            [70.591472348000082, 33.746834996000075],
            [70.593042792000062, 33.747948584000028],
            [70.594584683000051, 33.749147832000062],
            [70.595269968000082, 33.75006154600004],
            [70.595812485000067, 33.751003812000079],
            [70.596640537000042, 33.752488596000035],
            [70.597468590000062, 33.753802058000076],
            [70.597982553000065, 33.755201181000075],
            [70.598011107000048, 33.755715145000067],
            [70.597839786000065, 33.756000680000056],
            [70.597611357000062, 33.756571751000024],
            [70.597068840000077, 33.757142821000059],
            [70.59695462600007, 33.757685338000044],
            [70.596897519000038, 33.758027981000055],
            [70.597068840000077, 33.758284963000051],
            [70.597268715000041, 33.758599051000033],
            [70.597582804000069, 33.759141569000064],
            [70.597954000000072, 33.759741193000025],
            [70.598296642000037, 33.760397924000074],
            [70.59866783800004, 33.761254530000031],
            [70.598782052000047, 33.761996922000037],
            [70.598953373000029, 33.763396045000036],
            [70.599124694000068, 33.764538186000038],
            [70.599410230000046, 33.765394792000052],
            [70.599610105000068, 33.766051523000044],
            [70.600038408000046, 33.766965236000033],
            [70.600837906000038, 33.767964610000035],
            [70.601608852000084, 33.768707002000042],
            [70.602693886000054, 33.769477947000041],
            [70.603864581000039, 33.770191785000065],
            [70.604921061000084, 33.770962731000054],
            [70.606605720000061, 33.772076318000074],
            [70.607776415000046, 33.772932924000031],
            [70.60863302000007, 33.773703870000077],
            [70.608975663000081, 33.77418928000003],
            [70.609032770000056, 33.774560476000033],
            [70.609061323000049, 33.775017332000061],
            [70.608861449000074, 33.775417082000047],
            [70.608767458000045, 33.775511073000075],
            [70.608850616000041, 33.775899145000039],
            [70.60836520600003, 33.776470216000064],
            [70.607794136000052, 33.777041287000031],
            [70.607394386000067, 33.777441036000027],
            [70.606052370000043, 33.778269089000048],
            [70.604624694000051, 33.778954373000033],
            [70.603539659000035, 33.779725319000079],
            [70.602997142000049, 33.780267836000064],
            [70.602940035000074, 33.780781799000067],
            [70.602864043000068, 33.782751536000035],
            [70.602864043000068, 33.78429342700008],
            [70.602921151000032, 33.785778211000036],
            [70.602921151000032, 33.786406388000046],
            [70.603121025000064, 33.787063120000028],
            [70.603406561000043, 33.787405762000049],
            [70.604234613000074, 33.787834065000027],
            [70.604662916000052, 33.787948279000034],
            [70.605348201000083, 33.78820526100003],
            [70.606090593000033, 33.788605010000026],
            [70.606918645000064, 33.789118974000075],
            [70.607575376000057, 33.789575830000047],
            [70.608175000000074, 33.790118348000078],
            [70.608945946000063, 33.790717972000039],
            [70.60954557000008, 33.791374703000031],
            [70.610173748000079, 33.792202755000062],
            [70.61077337200004, 33.793145022000033],
            [70.611344442000075, 33.794058735000078],
            [70.612115388000063, 33.79485823400006],
            [70.61285778000007, 33.795714840000073],
            [70.613714385000037, 33.796685660000037],
            [70.614256903000069, 33.797171070000047],
            [70.614885080000079, 33.79768503300005],
            [70.615513258000078, 33.797970569000029],
            [70.616112882000039, 33.798141890000068],
            [70.61679816700007, 33.798198997000043],
            [70.61791175500008, 33.798227551000025],
            [70.619196664000071, 33.79817044300006],
            [70.620196037000028, 33.798113336000029],
            [70.62122396400008, 33.798113336000029],
            [70.622194784000044, 33.798113336000029],
            [70.623593907000043, 33.798113336000029],
            [70.624850263000042, 33.798141890000068],
            [70.62639215300004, 33.79831321100005],
            [70.627819830000078, 33.798398872000064],
            [70.628990525000063, 33.798455979000039],
            [70.630275434000055, 33.798655853000071],
            [70.631560343000046, 33.798741514000028],
            [70.632445502000053, 33.798770068000067],
            [70.633559090000062, 33.798855728000035],
            [70.635043874000075, 33.798827175000042],
            [70.636100354000064, 33.798855728000035],
            [70.637442370000031, 33.798627300000078],
            [70.638755833000062, 33.798227551000025],
            [70.639926527000057, 33.797970569000029],
            [70.640897347000077, 33.797485159000075],
            [70.641839614000048, 33.796885535000058],
            [70.642724774000044, 33.796314464000034],
            [70.643895468000039, 33.795457858000077],
            [70.644837735000067, 33.794458484000074],
            [70.645523020000041, 33.793801753000025],
            [70.646122644000059, 33.793030808000026],
            [70.646836482000083, 33.791917220000073],
            [70.647378999000068, 33.790889293000077],
            [70.647835856000029, 33.790004133000025],
            [70.648464033000039, 33.789147527000068],
            [70.649263532000077, 33.78820526100003],
            [70.650120138000034, 33.787320101000034],
            [70.651319386000068, 33.78600663900005],
            [70.651919011000075, 33.785264247000043],
            [70.65260429500006, 33.784436195000069],
            [70.653318134000074, 33.783608142000048],
            [70.653832097000077, 33.782894304000024],
            [70.654260400000055, 33.782237573000032],
            [70.654488828000069, 33.781209646000036],
            [70.655402542000047, 33.779896183000062],
            [70.656487576000075, 33.777783222000039],
            [70.657287075000056, 33.775755921000041],
            [70.657915252000066, 33.774356798000042],
            [70.658657644000073, 33.773271764000071],
            [70.659143054000083, 33.77244371200004],
            [70.65985689200005, 33.771587106000027],
            [70.660427963000075, 33.770644839000056],
            [70.661370230000045, 33.769245716000057],
            [70.661998407000056, 33.768332003000069],
            [70.662598031000073, 33.76741829000008],
            [70.662512371000048, 33.766533131000074],
            [70.662512371000048, 33.765904953000074],
            [70.662283943000034, 33.76519111500005],
            [70.661855640000056, 33.764505830000076],
            [70.661398783000038, 33.763934759000051],
            [70.660456517000057, 33.763278028000059],
            [70.659457143000054, 33.76299249300007],
            [70.658657644000073, 33.762449976000028],
            [70.658315002000052, 33.762107333000074],
            [70.658315002000052, 33.761650477000046],
            [70.658571984000048, 33.761193620000029],
            [70.659000286000037, 33.760822424000025],
            [70.65971412500005, 33.760451228000079],
            [70.661113248000049, 33.760108586000058],
            [70.662141175000045, 33.759994372000051],
            [70.663654512000051, 33.759765944000037],
            [70.664882314000067, 33.759508962000041],
            [70.665681813000049, 33.759109212000055],
            [70.666566972000055, 33.758738016000052],
            [70.667652007000072, 33.757995625000035],
            [70.668737041000043, 33.757481661000043],
            [70.669565093000074, 33.75696769700005],
            [70.670764341000051, 33.756253859000026],
            [70.67216346500004, 33.755425807000051],
            [70.673391266000067, 33.754597754000031],
            [70.674276426000063, 33.75399813000007],
            [70.67533290700004, 33.753227185000071],
            [70.676360834000036, 33.75254190000004],
            [70.677445868000063, 33.751942276000079],
            [70.678530902000034, 33.751399759000037],
            [70.679216187000065, 33.751057116000027],
            [70.67990147200004, 33.75082868800007],
            [70.680643863000057, 33.75068592100007],
            [70.681272041000057, 33.750543153000024],
            [70.681871665000074, 33.75020051000007],
            [70.682328522000034, 33.749772207000035],
            [70.682699718000038, 33.749315351000064],
            [70.682871039000077, 33.748829941000054],
            [70.683128021000073, 33.748230317000036],
            [70.683213681000041, 33.747744907000026],
            [70.683527770000069, 33.747230943000034],
            [70.683984627000029, 33.746716980000031],
            [70.684441483000057, 33.746317230000045],
            [70.684898340000075, 33.746088802000031],
            [70.685326643000053, 33.745660499000053],
            [70.685754946000031, 33.745317857000032],
            [70.686211802000059, 33.745032321000053],
            [70.68669721200007, 33.744975214000078],
            [70.68718262200008, 33.744632572000057],
            [70.68763947900004, 33.744375590000061],
            [70.68841042400004, 33.744347037000068],
            [70.689181369000039, 33.744347037000068],
            [70.68986665400007, 33.744347037000068],
            [70.69098024200008, 33.744375590000061],
            [70.691779741000062, 33.744375590000061],
            [70.692779114000075, 33.744375590000061],
            [70.693607167000039, 33.744347037000068],
            [70.694321005000063, 33.744061501000033],
            [70.694892076000031, 33.743775966000044],
            [70.695377486000041, 33.743490431000055],
            [70.695691575000069, 33.743090681000069],
            [70.696005663000051, 33.742491057000052],
            [70.696319752000079, 33.741748665000046],
            [70.696462520000068, 33.741091934000053],
            [70.69677660900004, 33.740520863000029],
            [70.697176358000036, 33.739978346000044],
            [70.697661768000046, 33.73946438300004],
            [70.69789019600006, 33.739264508000076],
            [70.698318499000038, 33.739207401000044],
            [70.698889570000063, 33.739207401000044],
            [70.699374980000073, 33.739264508000076],
            [70.699717622000037, 33.73960715000004],
            [70.700003158000072, 33.739807025000061],
            [70.700317247000044, 33.740092560000051],
            [70.700745550000079, 33.740549417000068],
            [70.701516495000078, 33.74120614800006],
            [70.701973351000049, 33.741605897000056],
            [70.702487315000042, 33.74183432600006],
            [70.703315367000073, 33.741862879000053],
            [70.704685937000079, 33.742005647000042],
            [70.706370595000067, 33.742262629000038],
            [70.707141541000055, 33.742348289000063],
            [70.707741165000073, 33.742519610000045],
            [70.70845500300004, 33.742748039000048],
            [70.709197395000047, 33.743204895000076],
            [70.709739912000032, 33.743518984000048],
            [70.710653625000077, 33.743804519000037],
            [70.711852873000055, 33.744318483000029],
            [70.71302356800004, 33.744632572000057],
            [70.71447979800007, 33.744946661000029],
            [70.716164457000048, 33.745432071000039],
            [70.717906222000067, 33.745917481000049],
            [70.71890559600007, 33.746117355000024],
            [70.719790755000076, 33.746431444000052],
            [70.720247612000037, 33.746574212000041],
            [70.720533147000083, 33.746973961000037],
            [70.720647361000033, 33.747459371000048],
            [70.72076157500004, 33.747944781000058],
            [70.720932896000079, 33.74845874500005],
            [70.721361199000057, 33.748944155000061],
            [70.721532521000029, 33.749515226000028],
            [70.721618181000053, 33.749914975000024],
            [70.721760949000043, 33.750400385000034],
            [70.721960824000064, 33.751000009000052],
            [70.722046484000032, 33.751199884000073],
            [70.72236057300006, 33.751599634000058],
            [70.722874537000052, 33.752199258000076],
            [70.723531268000045, 33.752484793000065],
            [70.72407378500003, 33.753084417000025],
            [70.724473534000083, 33.753341399000078],
            [70.725016051000068, 33.753427060000035],
            [70.72567278300005, 33.753369952000071],
            [70.726329514000042, 33.753341399000078],
            [70.727186120000056, 33.753312845000039],
            [70.728442475000065, 33.753284292000046],
            [70.729356188000054, 33.753112971000064],
            [70.730783865000035, 33.753055864000032],
            [70.732297202000041, 33.753141524000057],
            [70.733439343000043, 33.753170078000039],
            [70.734124628000075, 33.753170078000039],
            [70.734809913000049, 33.753170078000039],
            [70.735837840000045, 33.753569827000035],
            [70.737065642000061, 33.753883916000063],
            [70.737865141000043, 33.75399813000007],
            [70.738664639000035, 33.754083791000028],
            [70.739292817000035, 33.754112344000077],
            [70.740120870000055, 33.754055237000046],
            [70.741063136000037, 33.753769702000056],
            [70.74191974200005, 33.753684041000042],
            [70.742633580000074, 33.753484167000067],
            [70.743690061000052, 33.753112971000064],
            [70.744660881000073, 33.752741775000061],
            [70.745431826000072, 33.752599007000072],
            [70.746202772000061, 33.752427686000033],
            [70.747145038000042, 33.75239913200005],
            [70.748201519000077, 33.75254190000004],
            [70.748886804000051, 33.752741775000061],
            [70.74965774900005, 33.75302731000005],
            [70.750057499000036, 33.753455613000028],
            [70.750856997000028, 33.754026684000053],
            [70.75422631400005, 33.75585411000003],
            [70.756139401000041, 33.756596502000036],
            [70.757281542000044, 33.757139019000078],
            [70.75865211200005, 33.757652982000025],
            [70.75976569900007, 33.758195499000067],
            [70.760536645000059, 33.758338267000056],
            [70.76133614400004, 33.758538142000077],
            [70.762421178000068, 33.758538142000077],
            [70.764077283000063, 33.758595249000052],
            [70.765390745000047, 33.758595249000052],
            [70.766589993000082, 33.758595249000052],
            [70.767332385000032, 33.758852231000049],
            [70.768188991000045, 33.759080659000062],
            [70.768874276000076, 33.759451855000066],
            [70.769873649000033, 33.760022925000044],
            [70.770587488000047, 33.760308461000079],
            [70.771244219000039, 33.760337014000072],
            [70.771758183000031, 33.760251354000047],
            [70.772443467000073, 33.759994372000051],
            [70.773271520000037, 33.75979449700003],
            [70.774328000000082, 33.759737390000055],
            [70.775327374000028, 33.759823051000069],
            [70.775598079000076, 33.759868168000025],
            [70.775841338000077, 33.759908711000037],
            [70.776526622000063, 33.760165693000033],
            [70.777525996000065, 33.760651103000043],
            [70.779124994000028, 33.761479155000075],
            [70.781609151000055, 33.762906832000056],
            [70.78375066600006, 33.764734258000033],
            [70.785378217000073, 33.765847846000042],
            [70.786177716000054, 33.766704452000056],
            [70.786920108000061, 33.76693288000007],
            [70.787748160000035, 33.767075648000059],
            [70.789118730000041, 33.767275522000034],
            [70.791574333000028, 33.767275522000034],
            [70.793944277000037, 33.767304076000073],
            [70.795172078000064, 33.767189862000066],
            [70.796628308000038, 33.767104201000052],
            [70.797998878000044, 33.767075648000059],
            [70.79902680500004, 33.767218415000059],
            [70.799826304000078, 33.767246969000041],
            [70.800711464000074, 33.76753250400003],
            [70.801853605000076, 33.76816068200003],
            [70.802453229000037, 33.768531878000033],
            [70.803081407000036, 33.768674645000033],
            [70.804052227000057, 33.768674645000033],
            [70.805765439000083, 33.768703199000072],
            [70.807821293000075, 33.768674645000033],
            [70.810391111000058, 33.768731752000065],
            [70.81264684000007, 33.768931627000029],
            [70.813503446000084, 33.76913150200005],
            [70.814445712000065, 33.769559805000029],
            [70.815330872000061, 33.77024509000006],
            [70.816044710000028, 33.770901821000052],
            [70.816672888000028, 33.771558552000045],
            [70.817929243000037, 33.772615033000079],
            [70.818414653000048, 33.773157550000064],
            [70.818814402000044, 33.773357425000029],
            [70.819242705000079, 33.773357425000029],
            [70.819813776000046, 33.773014782000075],
            [70.820441954000046, 33.772643586000072],
            [70.821241453000084, 33.772358051000026],
            [70.822069505000059, 33.771929748000048],
            [70.823725610000054, 33.771273017000055],
            [70.824896305000038, 33.770958928000027],
            [70.825838571000077, 33.770930374000045],
            [70.82666662400004, 33.770930374000045],
            [70.827751658000068, 33.770987481000077],
            [70.828436943000042, 33.771101696000073],
            [70.828922353000053, 33.771158803000048],
            [70.829579084000045, 33.771587106000027],
            [70.830207262000044, 33.772215283000037],
            [70.830692672000055, 33.772872015000075],
            [70.831435063000072, 33.773785728000064],
            [70.832177455000078, 33.774528119000024],
            [70.832834186000071, 33.775013529000034],
            [70.833376704000045, 33.775384725000038],
            [70.83406198800003, 33.775584600000059],
            [70.834604505000073, 33.775870135000048],
            [70.835404004000054, 33.776184224000076],
            [70.83691734100006, 33.776983723000058],
            [70.838059483000052, 33.777526240000043],
            [70.83897319600004, 33.777925990000028],
            [70.839858355000047, 33.778497060000063],
            [70.840600747000053, 33.778782596000042],
            [70.841428799000084, 33.779182345000038],
            [70.842228298000066, 33.779781969000055],
            [70.84291358300004, 33.780295933000048],
            [70.843227672000069, 33.780895557000065],
            [70.843427547000033, 33.781352413000036],
            [70.843655975000047, 33.781894930000078],
            [70.843741636000061, 33.782494555000028],
            [70.843998617000068, 33.782780090000074],
            [70.844541134000053, 33.782951411000056],
            [70.845112205000078, 33.782894304000024],
            [70.845797490000052, 33.782608769000035],
            [70.846482775000084, 33.782266126000025],
            [70.847282273000076, 33.781980591000035],
            [70.848081772000057, 33.781695056000046],
            [70.849423788000081, 33.781152539000061],
            [70.850594483000066, 33.780895557000065],
            [70.851708071000076, 33.780610022000076],
            [70.852764551000064, 33.78035304000008],
            [70.854249335000077, 33.780038951000051],
            [70.855534244000069, 33.779981844000076],
            [70.856904814000075, 33.779753416000062],
            [70.858846454000059, 33.779467880000027],
            [70.860616773000061, 33.779153791000056],
            [70.861444825000035, 33.779068131000031],
            [70.862387092000063, 33.779011024000056],
            [70.863329358000044, 33.778811149000035],
            [70.864728481000043, 33.778382846000056],
            [70.866584461000059, 33.777954543000078],
            [70.867469620000065, 33.777754668000057],
            [70.868440440000029, 33.777526240000043],
            [70.869182832000035, 33.777212151000072],
            [70.870153652000056, 33.776726741000061],
            [70.87135290100008, 33.77638409900004],
            [70.872009632000072, 33.776098564000051],
            [70.872809131000054, 33.775984349000055],
            [70.873351648000039, 33.775955796000062],
            [70.874122593000038, 33.776041457000076],
            [70.87480787800007, 33.776127117000044],
            [70.875378948000048, 33.77638409900004],
            [70.875950019000072, 33.776583974000062],
            [70.876835179000068, 33.776955170000065],
            [70.877720338000074, 33.777440580000075],
            [70.878348516000074, 33.777583347000075],
            [70.879633425000065, 33.777925990000028],
            [70.880432924000047, 33.777954543000078],
            [70.881375190000028, 33.777954543000078],
            [70.882374564000031, 33.777954543000078],
            [70.882974188000048, 33.777925990000028],
            [70.883516705000034, 33.777583347000075],
            [70.883745133000048, 33.777069384000072],
            [70.883945008000069, 33.776555420000079],
            [70.884087776000058, 33.775955796000062],
            [70.884201990000065, 33.775184851000063],
            [70.884201990000065, 33.774385352000024],
            [70.884430418000079, 33.773614406000036],
            [70.884830167000075, 33.773014782000075],
            [70.885315577000028, 33.772472265000033],
            [70.88585809500006, 33.772043962000055],
            [70.886657593000052, 33.771701320000034],
            [70.887428539000041, 33.771644213000059],
            [70.888427912000054, 33.771615659000076],
            [70.889084644000036, 33.771729873000027],
            [70.889741375000028, 33.771786980000059],
            [70.890569427000059, 33.771872641000073],
            [70.892082764000065, 33.772158176000062],
            [70.893282013000032, 33.772872015000075],
            [70.894195726000078, 33.773043336000057],
            [70.89545208100003, 33.77307188900005],
            [70.896736990000079, 33.773157550000064],
            [70.897964792000039, 33.773414532000061],
            [70.89879284400007, 33.773557299000061],
            [70.899535236000077, 33.773928495000064],
            [70.90036328900004, 33.774271138000074],
            [70.901848072000064, 33.774471012000049],
            [70.902676125000085, 33.774556673000063],
            [70.904018141000051, 33.774842208000052],
            [70.905217389000029, 33.775127744000031],
            [70.905540708000046, 33.775304099000039],
            [70.905845567000028, 33.775470386000052],
            [70.906445191000046, 33.77578447500008],
            [70.907216136000045, 33.776498313000047],
            [70.907701546000055, 33.777412026000036],
            [70.908215510000048, 33.778325739000024],
            [70.908529599000076, 33.779039577000049],
            [70.908957902000054, 33.77983907600003],
            [70.909471865000057, 33.78046725400003],
            [70.909871615000043, 33.780867003000026],
            [70.910385578000046, 33.781095432000029],
            [70.911242184000059, 33.781295306000061],
            [70.911984576000066, 33.781352413000036],
            [70.912983950000068, 33.781380967000075],
            [70.913954770000032, 33.781609395000032],
            [70.915154018000067, 33.782009145000075],
            [70.916353266000044, 33.782437448000053],
            [70.91835201300006, 33.783151286000077],
            [70.921207367000079, 33.783836571000052],
            [70.924005613000077, 33.784578962000069],
            [70.927489144000049, 33.785549783000079],
            [70.931372424000074, 33.786263621000046],
            [70.934199223000064, 33.786863245000063],
            [70.936340738000069, 33.787205887000027],
            [70.938225271000078, 33.786891798000056],
            [70.940452447000041, 33.787034566000045],
            [70.942479748000039, 33.786806138000031],
            [70.945249440000055, 33.786720477000074],
            [70.947191080000039, 33.786920352000038],
            [70.94898995300008, 33.787091673000077],
            [70.950531843000078, 33.787405762000049],
            [70.952502037000045, 33.788005386000066],
            [70.954329463000079, 33.788176707000048],
            [70.956642299000066, 33.788405136000051],
            [70.958869475000029, 33.788319475000037],
            [70.96078256100003, 33.788262368000062],
            [70.962667094000039, 33.78809104700008],
            [70.96509414500008, 33.78823381400008],
            [70.967549748000067, 33.788262368000062],
            [70.96914874600003, 33.788319475000037],
            [70.970290887000033, 33.788776332000054],
            [70.972061206000035, 33.789347402000033],
            [70.974288382000054, 33.790204008000046],
            [70.976743985000041, 33.791374703000031],
            [70.979342357000064, 33.792488291000041],
            [70.981940728000041, 33.793830307000064],
            [70.983254191000071, 33.794487038000057],
            [70.984424885000067, 33.795315090000031],
            [70.984596207000038, 33.795743393000066],
            [70.984596207000038, 33.796143143000052],
            [70.984481992000042, 33.79659999900008],
            [70.984082243000046, 33.797342391000029],
            [70.983454065000046, 33.798227551000025],
            [70.982426138000051, 33.799826548000055],
            [70.98134110400008, 33.801825295000071],
            [70.980198963000078, 33.80430945300003],
            [70.97911392900005, 33.808506822000027],
            [70.978114555000047, 33.812018906000048],
            [70.976629771000034, 33.817586845000051],
            [70.976172915000063, 33.818814647000067],
            [70.975544737000064, 33.821470125000076],
            [70.975116434000029, 33.823554533000049],
            [70.974602471000082, 33.825439066000058],
            [70.973231901000077, 33.829208132000076],
            [70.972746491000066, 33.830807130000039],
            [70.971889885000053, 33.832891538000069],
            [70.971461582000074, 33.834404875000075],
            [70.971204600000078, 33.835861105000049],
            [70.971204600000078, 33.836632050000048],
            [70.97123315400006, 33.837117460000059],
            [70.971404475000043, 33.837402996000037],
            [70.971889885000053, 33.837945513000079],
            [70.972775045000049, 33.83840236900005],
            [70.974231275000079, 33.838916333000043],
            [70.976972414000045, 33.839344636000078],
            [70.979856320000067, 33.839430297000035],
            [70.982369031000076, 33.839573064000035],
            [70.984681867000063, 33.839458850000028],
            [70.987879863000046, 33.839544511000042],
            [70.992619749000085, 33.83951595700006],
            [70.997673724000038, 33.839458850000028],
            [70.999244168000075, 33.839373189000071],
            [71.000100774000032, 33.839373189000071],
            [71.000557631000049, 33.839458850000028],
            [71.001128701000084, 33.839658725000049],
            [71.002499271000033, 33.840572438000038],
            [71.003441537000072, 33.841200615000048],
            [71.00498342800006, 33.842199989000051],
            [71.00669664000003, 33.843342130000053],
            [71.007581799000036, 33.843656219000025],
            [71.008352745000082, 33.843741880000039],
            [71.009637654000073, 33.843741880000039],
            [71.012207472000057, 33.843656219000025],
            [71.015005718000054, 33.843656219000025],
            [71.017175786000053, 33.843427791000067],
            [71.020002586000032, 33.843256470000028],
            [71.023457563000079, 33.843085149000046],
            [71.025170775000049, 33.843085149000046],
            [71.026512791000073, 33.843227916000046],
            [71.027369397000029, 33.84345634400006],
            [71.027940467000064, 33.843627666000032],
            [71.029225376000056, 33.844170183000074],
            [71.029910661000031, 33.844455718000063],
            [71.030738714000051, 33.844484272000045],
            [71.031823748000079, 33.84442716500007],
            [71.033451299000035, 33.844170183000074],
            [71.034821869000041, 33.843884647000039],
            [71.036335206000047, 33.843342130000053],
            [71.038105525000049, 33.842771060000075],
            [71.039504648000047, 33.842685399000061],
            [71.041360627000074, 33.842257096000026],
            [71.042702643000041, 33.841914454000062],
            [71.04395899900004, 33.841286276000062],
            [71.044958372000053, 33.840886527000066],
            [71.045786425000074, 33.840515331000063],
            [71.046814352000069, 33.840429670000049],
            [71.048013600000047, 33.840401117000056],
            [71.049698259000081, 33.840458224000031],
            [71.052068202000044, 33.840486777000024],
            [71.054209716000059, 33.840486777000024],
            [71.05786456900006, 33.840458224000031],
            [71.060263065000072, 33.840258349000067],
            [71.061519421000071, 33.84000136700007],
            [71.062718669000049, 33.839573064000035],
            [71.063832257000058, 33.839230422000071],
            [71.064888737000047, 33.839144761000057],
            [71.065574022000078, 33.839201868000032],
            [71.066287860000045, 33.839230422000071],
            [71.067115913000066, 33.839601618000074],
            [71.068029626000055, 33.84000136700007],
            [71.069028999000068, 33.840486777000024],
            [71.070770765000077, 33.840943634000041],
            [71.073397690000036, 33.841543258000058],
            [71.07508234800008, 33.841914454000062],
            [71.078366004000031, 33.842742506000036],
            [71.08199230300005, 33.843827540000063],
            [71.084733442000072, 33.844455718000063],
            [71.086646528000074, 33.84456993200007],
            [71.088559615000065, 33.844027415000028],
            [71.091186540000081, 33.842856720000043],
            [71.093328055000029, 33.841600365000033],
            [71.095326802000045, 33.840315456000042],
            [71.096468943000048, 33.839487404000067],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "54",
      properties: { name: "South Waziristan Agency", count: 394 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.349925356000028, 32.768282169000031],
            [70.351876877000052, 32.768281658000035],
            [70.354211907000035, 32.768345649000025],
            [70.356417855000075, 32.768340322000029],
            [70.358416471000055, 32.76851804100005],
            [70.360197443000061, 32.768484747000059],
            [70.361426221000045, 32.768425919000038],
            [70.363242993000028, 32.768463596000061],
            [70.365536573000043, 32.768563995000079],
            [70.368293777000076, 32.768548127000031],
            [70.370327284000041, 32.768438266000032],
            [70.372065845000066, 32.76840560100004],
            [70.373755394000057, 32.768122939000079],
            [70.374939866000034, 32.767993128000057],
            [70.37650313100005, 32.767748647000076],
            [70.377645191000056, 32.767619609000064],
            [70.379757794000056, 32.76729305200007],
            [70.381237175000081, 32.767085936000058],
            [70.381618676000073, 32.76707873600003],
            [70.382792000000052, 32.761213000000055],
            [70.373913039000058, 32.707939234000037],
            [70.372619298000075, 32.707624066000051],
            [70.372468210000079, 32.707568402000049],
            [70.371992317000036, 32.707473224000069],
            [70.371564014000057, 32.707306661000075],
            [70.371064328000045, 32.707235277000052],
            [70.370421873000055, 32.707092510000052],
            [70.369660446000069, 32.707068715000048],
            [70.369113170000048, 32.707021126000029],
            [70.36828035800005, 32.707021126000029],
            [70.367804466000052, 32.706997331000025],
            [70.366947860000039, 32.706997331000025],
            [70.365900897000074, 32.707187688000033],
            [70.364473221000083, 32.707473224000069],
            [70.363331079000034, 32.707711170000039],
            [70.36292657100006, 32.707806348000076],
            [70.362712420000037, 32.707949116000066],
            [70.362608363000049, 32.708092193000027],
            [70.361442471000032, 32.708434433000036],
            [70.360826932000066, 32.708543981000048],
            [70.360475726000061, 32.708543981000048],
            [70.360095013000034, 32.708591570000067],
            [70.359738093000033, 32.708591570000067],
            [70.359309790000054, 32.708639159000029],
            [70.358738720000076, 32.708686749000037],
            [70.358024882000052, 32.708710543000052],
            [70.357168276000039, 32.708710543000052],
            [70.356549616000052, 32.708639159000029],
            [70.355811983000081, 32.708472597000025],
            [70.354384306000043, 32.707996705000028],
            [70.353194576000078, 32.707401840000045],
            [70.351814488000059, 32.706664207000074],
            [70.350648553000042, 32.706235904000039],
            [70.349839536000047, 32.706045547000031],
            [70.349030519000053, 32.705926574000046],
            [70.347983556000031, 32.705855190000079],
            [70.347222129000045, 32.705831396000065],
            [70.346532085000035, 32.705688628000075],
            [70.346103782000057, 32.705522066000071],
            [70.345342355000071, 32.704927200000043],
            [70.344200213000079, 32.703975416000048],
            [70.342843921000053, 32.703190194000058],
            [70.341130709000083, 32.702095642000074],
            [70.339274729000067, 32.701096268000072],
            [70.337204598000028, 32.700096895000058],
            [70.336181430000067, 32.699383056000045],
            [70.335039289000065, 32.698383683000031],
            [70.334087504000081, 32.697431898000048],
            [70.333302282000034, 32.696146989000056],
            [70.332659828000033, 32.695337973000051],
            [70.331922195000061, 32.69391029600007],
            [70.33127974000007, 32.692815744000029],
            [70.330780054000058, 32.692054317000043],
            [70.330066215000045, 32.691459451000071],
            [70.329423761000044, 32.690935970000055],
            [70.32870992200003, 32.690388694000035],
            [70.32792470000004, 32.689770034000048],
            [70.326687381000056, 32.689032401000077],
            [70.325902159000066, 32.688461331000042],
            [70.324902785000063, 32.687937849000036],
            [70.323879617000046, 32.687438162000035],
            [70.322999216000028, 32.686986065000042],
            [70.322095021000052, 32.686652940000045],
            [70.321190826000077, 32.686343610000051],
            [70.320500782000067, 32.686010486000043],
            [70.319739355000081, 32.685582183000065],
            [70.319025516000067, 32.684892139000056],
            [70.318335473000047, 32.684249685000054],
            [70.317883375000065, 32.683416873000056],
            [70.31745507200003, 32.682560267000042],
            [70.317121948000079, 32.681251564000036],
            [70.316598466000073, 32.679609736000032],
            [70.316074985000057, 32.677944113000024],
            [70.315551503000052, 32.676659204000032],
            [70.315242173000058, 32.675493268000025],
            [70.315004227000031, 32.673922824000044],
            [70.314980433000073, 32.672947245000046],
            [70.315004227000031, 32.672376174000078],
            [70.31490904900005, 32.671448185000031],
            [70.314599719000057, 32.670972292000044],
            [70.314219005000041, 32.670543989000066],
            [70.313457578000055, 32.669972919000031],
            [70.312410615000033, 32.669068724000056],
            [70.311387447000072, 32.668069350000053],
            [70.310959144000037, 32.667569663000052],
            [70.310602225000082, 32.667117566000059],
            [70.310197716000062, 32.666260960000045],
            [70.309579056000075, 32.664690516000064],
            [70.308698656000047, 32.662549001000059],
            [70.307627898000078, 32.66038369100005],
            [70.307366158000036, 32.659527085000036],
            [70.307033033000039, 32.658884631000035],
            [70.306461963000061, 32.658265971000048],
            [70.30581950800007, 32.657671106000066],
            [70.305081875000042, 32.657100035000042],
            [70.303511431000061, 32.656124456000043],
            [70.302036165000061, 32.655077493000078],
            [70.300965408000081, 32.654316066000035],
            [70.30015639100003, 32.65379258400003],
            [70.298823893000076, 32.652935978000073],
            [70.297681752000074, 32.651603480000063],
            [70.296492021000063, 32.650199598000029],
            [70.295254701000033, 32.648962279000045],
            [70.293969792000041, 32.647820137000053],
            [70.292899035000062, 32.646749380000074],
            [70.292185197000038, 32.646225898000068],
            [70.291661715000032, 32.645868979000056],
            [70.290947877000065, 32.645607239000071],
            [70.290353012000082, 32.645321703000036],
            [70.289758147000043, 32.645036168000047],
            [70.289401227000042, 32.644631660000073],
            [70.288949130000049, 32.644250946000057],
            [70.288782568000045, 32.643798848000074],
            [70.288473238000051, 32.643322956000077],
            [70.288282881000043, 32.642799475000061],
            [70.288068729000031, 32.642323582000074],
            [70.287806989000046, 32.641942869000047],
            [70.28742627500003, 32.64146697700005],
            [70.286878999000066, 32.641038674000072],
            [70.286379312000065, 32.640705549000074],
            [70.28571306300006, 32.640372424000077],
            [70.285094403000073, 32.63999171100005],
            [70.284428154000068, 32.639563408000072],
            [70.283880878000048, 32.63944443500003],
            [70.283730829000035, 32.639418339000031],
            [70.283333602000084, 32.63934925600006],
            [70.283293717000049, 32.639346597000042],
            [70.28326442000008, 32.639391608000039],
            [70.282691148000083, 32.639325462000045],
            [70.282120077000059, 32.639325462000045],
            [70.281620390000057, 32.639325462000045],
            [70.281120703000056, 32.639230283000074],
            [70.28035927600007, 32.639039926000066],
            [70.279693027000064, 32.638873364000062],
            [70.278955394000036, 32.638587829000073],
            [70.278527091000058, 32.638373677000061],
            [70.277956020000033, 32.638064347000068],
            [70.277527717000055, 32.637755017000075],
            [70.277099414000077, 32.637326714000039],
            [70.276694906000046, 32.636993590000031],
            [70.276337987000034, 32.636565287000053],
            [70.275862095000036, 32.636232162000056],
            [70.275481381000077, 32.635827654000025],
            [70.275314819000073, 32.635446940000065],
            [70.27500548900008, 32.634923459000049],
            [70.274838926000029, 32.634495156000071],
            [70.274434418000055, 32.633852701000023],
            [70.273958526000058, 32.633376809000026],
            [70.273482634000061, 32.632996096000056],
            [70.272768795000047, 32.632353641000066],
            [70.272316698000054, 32.631972927000049],
            [70.271912189000034, 32.631711187000064],
            [70.271483886000055, 32.631544624000071],
            [70.271079378000081, 32.631425651000029],
            [70.27067487000005, 32.631163911000044],
            [70.270151388000045, 32.631163911000044],
            [70.269723085000066, 32.631163911000044],
            [70.269223398000065, 32.631163911000044],
            [70.26865232800003, 32.631282884000029],
            [70.268128846000081, 32.631259089000025],
            [70.267771927000069, 32.631259089000025],
            [70.267438803000061, 32.631211500000063],
            [70.267105678000064, 32.630997348000051],
            [70.266748759000052, 32.630711813000062],
            [70.266034921000028, 32.63035489400005],
            [70.264940369000044, 32.629474493000032],
            [70.263869611000075, 32.628665477000027],
            [70.262632292000035, 32.627856460000032],
            [70.261632918000032, 32.626761908000049],
            [70.260038679000047, 32.625048696000079],
            [70.259277252000061, 32.624334858000054],
            [70.258658592000074, 32.623787582000034],
            [70.257968548000065, 32.623335484000052],
            [70.257421272000045, 32.623097538000025],
            [70.256992969000066, 32.622907181000073],
            [70.256564666000031, 32.622812003000035],
            [70.255541498000071, 32.622526467000057],
            [70.254780070000038, 32.622336110000049],
            [70.254161411000041, 32.622264727000072],
            [70.253423778000069, 32.622264727000072],
            [70.252828912000041, 32.622312316000034],
            [70.252257842000063, 32.622431289000076],
            [70.251999916000045, 32.622537494000028],
            [70.251780238000038, 32.622541419000072],
            [70.251649303000079, 32.622475952000059],
            [70.251387562000048, 32.622475952000059],
            [70.251244795000048, 32.622475952000059],
            [70.251078233000044, 32.622642514000063],
            [70.25081649200007, 32.622809076000067],
            [70.250507162000076, 32.622904255000037],
            [70.249936091000052, 32.622999433000075],
            [70.249555378000082, 32.622999433000075],
            [70.249150869000061, 32.622951844000056],
            [70.248793950000049, 32.622737693000033],
            [70.24786596000007, 32.622309390000055],
            [70.247461452000039, 32.622238006000032],
            [70.246985560000041, 32.622047649000024],
            [70.246557257000063, 32.621904881000034],
            [70.246009981000043, 32.621904881000034],
            [70.245605472000079, 32.621952470000053],
            [70.245129580000082, 32.621952470000053],
            [70.244534715000043, 32.621952470000053],
            [70.243963644000075, 32.621928676000039],
            [70.243487752000078, 32.621785908000049],
            [70.243059449000043, 32.62150037300006],
            [70.242821503000073, 32.621167248000063],
            [70.242559762000042, 32.62085791800007],
            [70.242250432000048, 32.620524794000062],
            [70.242012486000078, 32.620191669000064],
            [70.241465210000058, 32.619858545000056],
            [70.241084496000042, 32.619549215000063],
            [70.240632399000049, 32.619168501000047],
            [70.240108917000043, 32.618787787000031],
            [70.239799588000039, 32.618526047000046],
            [70.239442668000038, 32.618288101000076],
            [70.238562268000067, 32.618050154000059],
            [70.238086376000069, 32.617836003000036],
            [70.237539100000049, 32.617717030000051],
            [70.237110797000071, 32.617574262000062],
            [70.236658699000031, 32.617407700000058],
            [70.236230396000053, 32.61721734300005],
            [70.235825888000079, 32.616884219000042],
            [70.235540352000044, 32.616646272000025],
            [70.235112049000065, 32.61652729900004],
            [70.234517184000083, 32.616241764000051],
            [70.234231649000037, 32.616027613000028],
            [70.233969908000063, 32.615527926000027],
            [70.233850935000078, 32.61505203400003],
            [70.233541605000084, 32.614718909000032],
            [70.23306571300003, 32.614576141000043],
            [70.232542232000071, 32.614433374000043],
            [70.232090134000032, 32.614147838000065],
            [70.231685626000058, 32.613743330000034],
            [70.231400090000079, 32.61333882200006],
            [70.231233528000075, 32.613005697000062],
            [70.230829020000044, 32.612672573000054],
            [70.230353128000047, 32.612268064000034],
            [70.22987723500006, 32.611887351000064],
            [70.229425138000067, 32.611696994000056],
            [70.228973040000028, 32.611530431000062],
            [70.228425764000065, 32.611221102000059],
            [70.228164023000033, 32.610840388000042],
            [70.227569158000051, 32.610459674000026],
            [70.227140855000073, 32.610174139000037],
            [70.226807731000065, 32.609983782000029],
            [70.226331839000068, 32.609722041000055],
            [70.225855946000081, 32.609484095000028],
            [70.225427643000046, 32.609507890000032],
            [70.224951751000049, 32.609769630000073],
            [70.22452344800007, 32.610221728000056],
            [70.224237913000081, 32.610673825000049],
            [70.223571664000076, 32.611363869000058],
            [70.223286128000041, 32.611720788000071],
            [70.222976799000037, 32.612172886000053],
            [70.222643674000039, 32.612601189000031],
            [70.222429523000073, 32.612767751000035],
            [70.221858452000049, 32.612910519000025],
            [70.221311176000029, 32.612981903000048],
            [70.220763900000065, 32.613029492000067],
            [70.220478365000076, 32.613005697000062],
            [70.220169035000083, 32.612934313000039],
            [70.219645553000078, 32.612791546000039],
            [70.218979304000072, 32.612839135000058],
            [70.218384439000033, 32.612981903000048],
            [70.217718190000028, 32.613029492000067],
            [70.217266092000045, 32.613029492000067],
            [70.216790200000048, 32.61319605400007],
            [70.216480870000055, 32.613434000000041],
            [70.216195335000066, 32.613671946000068],
            [70.215886005000073, 32.614076455000031],
            [70.215386318000071, 32.614433374000043],
            [70.21512457700004, 32.614766498000051],
            [70.214672480000047, 32.614933061000045],
            [70.213911052000071, 32.615075828000045],
            [70.213625517000082, 32.615408953000042],
            [70.213221009000051, 32.615456542000061],
            [70.212792706000073, 32.61550413100008],
            [70.212150251000082, 32.615623104000065],
            [70.211531591000039, 32.61574207700005],
            [70.210912931000053, 32.615980023000077],
            [70.21046083400006, 32.61614658600007],
            [70.210056326000029, 32.616479710000078],
            [70.209651817000065, 32.616693862000034],
            [70.209294898000053, 32.617074575000061],
            [70.209056952000083, 32.617360111000039],
            [70.208485881000058, 32.617740825000055],
            [70.208033784000065, 32.61776461900007],
            [70.207819632000053, 32.618026360000044],
            [70.207557892000068, 32.618359484000052],
            [70.207415124000079, 32.618740198000069],
            [70.207200972000066, 32.619073323000066],
            [70.206915437000077, 32.619525420000059],
            [70.206844053000054, 32.620191669000064],
            [70.206701286000055, 32.620667562000051],
            [70.206653696000046, 32.621048275000078],
            [70.206368161000057, 32.621262427000033],
            [70.205963653000083, 32.621595551000041],
            [70.205678117000048, 32.621857292000072],
            [70.205535350000048, 32.622261800000047],
            [70.205535350000048, 32.622618720000048],
            [70.205535350000048, 32.623070817000041],
            [70.205273609000074, 32.623665682000023],
            [70.205130841000084, 32.623879834000036],
            [70.204821511000034, 32.624141575000067],
            [70.204488387000083, 32.624284342000067],
            [70.204036289000044, 32.624403315000052],
            [70.203726959000051, 32.624617467000064],
            [70.203322451000076, 32.625117154000066],
            [70.202775175000056, 32.625331305000032],
            [70.202227899000036, 32.625426483000069],
            [70.201847185000076, 32.62552166200004],
            [70.201395088000083, 32.625712019000048],
            [70.200824017000059, 32.625902376000056],
            [70.200300536000043, 32.625973759000033],
            [70.199800849000042, 32.626021349000041],
            [70.19956639600008, 32.62597738900007],
            [70.199420135000082, 32.625949965000075],
            [70.199158394000051, 32.625712019000048],
            [70.198801475000039, 32.625402689000055],
            [70.198468351000031, 32.625045770000042],
            [70.198016253000048, 32.624355726000033],
            [70.19768312900004, 32.624070191000044],
            [70.197278620000077, 32.623713272000032],
            [70.196874112000046, 32.623475325000072],
            [70.196469603000082, 32.623189790000026],
            [70.195946122000066, 32.622832871000071],
            [70.195708176000039, 32.622690103000025],
            [70.195351257000084, 32.62233318400007],
            [70.195089516000053, 32.622000060000062],
            [70.195055282000055, 32.621923983000045],
            [70.19487536500003, 32.621524167000075],
            [70.194661213000074, 32.621119659000044],
            [70.19463741900006, 32.620762740000032],
            [70.194256705000043, 32.620429615000035],
            [70.193923580000046, 32.620001312000056],
            [70.193566661000034, 32.619549215000063],
            [70.193495277000068, 32.619097117000024],
            [70.193376304000083, 32.618645020000031],
            [70.193043180000075, 32.618026360000044],
            [70.192948001000047, 32.617621851000024],
            [70.19261487700004, 32.616955602000075],
            [70.192186574000061, 32.616503505000026],
            [70.191782065000041, 32.615884845000039],
            [70.191448941000033, 32.615432747000057],
            [70.19113961100004, 32.61505203400003],
            [70.190544746000057, 32.614480963000062],
            [70.19030680000003, 32.614266812000039],
            [70.189688140000044, 32.613790919000053],
            [70.189236042000061, 32.613695741000072],
            [70.188879123000049, 32.613648152000053],
            [70.188379436000048, 32.613529179000068],
            [70.188093901000059, 32.613386411000079],
            [70.187832160000028, 32.613124670000047],
            [70.187332473000083, 32.612743957000077],
            [70.187070733000041, 32.612410832000023],
            [70.186499662000074, 32.611934940000026],
            [70.185999975000072, 32.611696994000056],
            [70.185666851000065, 32.611482842000044],
            [70.185286137000048, 32.611197307000054],
            [70.184953012000051, 32.610911772000065],
            [70.184548504000077, 32.610531058000049],
            [70.18416779000006, 32.610102755000071],
            [70.183572925000078, 32.609579273000065],
            [70.183144622000043, 32.609031997000045],
            [70.182906676000073, 32.608675078000033],
            [70.182573551000075, 32.608341954000025],
            [70.18228801600003, 32.608008829000028],
            [70.181978686000036, 32.607770883000057],
            [70.18176453500007, 32.607556732000035],
            [70.181336232000035, 32.607057045000033],
            [70.180955518000076, 32.606533563000028],
            [70.180598599000064, 32.606010082000068],
            [70.18043203700006, 32.605676957000071],
            [70.180075117000058, 32.605248654000036],
            [70.179765788000054, 32.604867941000066],
            [70.179456458000061, 32.604534816000069],
            [70.17895677100006, 32.604154102000052],
            [70.178528468000081, 32.603725799000074],
            [70.178314316000069, 32.603392675000066],
            [70.178076370000042, 32.60301196100005],
            [70.177695656000083, 32.602226739000059],
            [70.177338737000071, 32.601893615000051],
            [70.177100791000044, 32.60153669500005],
            [70.17679146100005, 32.601203571000042],
            [70.176434542000038, 32.60070388400004],
            [70.175934855000037, 32.600037635000035],
            [70.175530347000063, 32.599609332000057],
            [70.175054455000065, 32.599181029000079],
            [70.174673741000049, 32.598824110000066],
            [70.17438820600006, 32.598467191000054],
            [70.17374575100007, 32.597491612000056],
            [70.173174681000035, 32.597063309000077],
            [70.172817761000033, 32.596658800000057],
            [70.172389459000044, 32.596325676000049],
            [70.171865977000039, 32.595754605000025],
            [70.171604236000064, 32.595397686000069],
            [70.171271112000056, 32.594945589000076],
            [70.170961782000063, 32.59456487500006],
            [70.170604863000051, 32.594255545000067],
            [70.170176560000073, 32.593827242000032],
            [70.169462722000048, 32.59308960900006],
            [70.168986829000062, 32.592732690000048],
            [70.168701294000073, 32.592375771000036],
            [70.168225402000076, 32.591995057000076],
            [70.167749510000078, 32.591590549000045],
            [70.167059466000069, 32.591043273000025],
            [70.166702547000057, 32.590852916000074],
            [70.166250449000074, 32.590519791000077],
            [70.165917325000066, 32.590210461000026],
            [70.165726968000058, 32.589829747000067],
            [70.165489022000031, 32.589472828000055],
            [70.165132103000076, 32.589092115000028],
            [70.164894156000059, 32.588711401000069],
            [70.164656210000032, 32.588402071000075],
            [70.164251702000058, 32.587997563000044],
            [70.163847194000084, 32.587688233000051],
            [70.163585453000053, 32.587450286000035],
            [70.163157150000075, 32.587164751000046],
            [70.162824025000077, 32.58695060000008],
            [70.162324339000065, 32.586688859000049],
            [70.161943625000049, 32.586308145000032],
            [70.161539117000075, 32.585546718000046],
            [70.161158403000059, 32.584999442000026],
            [70.160944251000046, 32.584404576000054],
            [70.160444564000045, 32.583785917000057],
            [70.160111440000037, 32.583262435000051],
            [70.159944878000033, 32.582881721000035],
            [70.159683137000059, 32.582477213000061],
            [70.15939760200007, 32.582239267000034],
            [70.158850326000049, 32.581906142000037],
            [70.158398228000067, 32.581406456000025],
            [70.158088898000074, 32.581025742000065],
            [70.157684390000043, 32.580668823000053],
            [70.157517828000039, 32.580121547000033],
            [70.157184703000041, 32.579502887000046],
            [70.156946757000071, 32.57912217300003],
            [70.156566043000055, 32.578741459000071],
            [70.156161535000081, 32.57833695100004],
            [70.155899794000049, 32.578027621000047],
            [70.155590464000056, 32.577789675000076],
            [70.155257340000048, 32.577480345000026],
            [70.15482903700007, 32.577218604000052],
            [70.154543501000035, 32.576885480000044],
            [70.15442452800005, 32.576504766000028],
            [70.15428176100005, 32.576052668000045],
            [70.154091404000042, 32.575743339000041],
            [70.153472744000055, 32.574981911000066],
            [70.153068236000081, 32.574672581000073],
            [70.152616138000042, 32.574172894000071],
            [70.152140246000044, 32.573720797000078],
            [70.151688148000062, 32.573292494000043],
            [70.150998104000053, 32.572769012000037],
            [70.15078395300003, 32.572364504000063],
            [70.150593596000078, 32.571959996000032],
            [70.150212882000062, 32.571412720000069],
            [70.149808374000031, 32.570913033000068],
            [70.149570428000061, 32.570556114000055],
            [70.149189714000045, 32.570151605000035],
            [70.14892797300007, 32.569770892000065],
            [70.148499670000035, 32.56934258900003],
            [70.148261724000065, 32.569009464000032],
            [70.147928600000057, 32.568819107000024],
            [70.147714448000045, 32.568533572000035],
            [70.14745270800006, 32.568105269000057],
            [70.147357529000033, 32.567700761000026],
            [70.147238556000048, 32.567272458000048],
            [70.147071994000044, 32.566915538000046],
            [70.146810253000069, 32.566534825000076],
            [70.146191593000083, 32.565892370000029],
            [70.145882263000033, 32.565487862000055],
            [70.145596728000044, 32.565035764000072],
            [70.145334987000069, 32.564726434000079],
            [70.144692533000068, 32.564060185000073],
            [70.144192846000067, 32.563631882000038],
            [70.143859722000059, 32.563274963000026],
            [70.143645570000047, 32.562870455000052],
            [70.143193473000053, 32.562323179000032],
            [70.143050705000064, 32.561942465000072],
            [70.14288414300006, 32.56158554600006],
            [70.142598607000082, 32.561300011000071],
            [70.142217894000055, 32.560990681000078],
            [70.141860974000053, 32.560728940000047],
            [70.141385082000056, 32.560324432000073],
            [70.141099547000067, 32.560038896000037],
            [70.140742628000055, 32.559800950000067],
            [70.140266736000058, 32.559610593000059],
            [70.13993361100006, 32.559444031000055],
            [70.13938633500004, 32.559039523000024],
            [70.139029416000028, 32.558563630000037],
            [70.138839059000077, 32.557968765000055],
            [70.138624907000064, 32.557659435000062],
            [70.138315578000061, 32.557159748000061],
            [70.137292409000054, 32.55637452600007],
            [70.136840312000061, 32.556065196000077],
            [70.13605509000007, 32.555161001000045],
            [70.135674376000054, 32.554827877000037],
            [70.135008127000049, 32.554399574000058],
            [70.134437056000081, 32.554018860000042],
            [70.134056342000065, 32.553590557000064],
            [70.133723218000057, 32.553352611000037],
            [70.133294915000079, 32.553019486000039],
            [70.133080763000066, 32.552614978000065],
            [70.133033174000047, 32.552258059000053],
            [70.132509693000031, 32.551686988000029],
            [70.132224157000053, 32.551211096000031],
            [70.131914828000049, 32.550735204000034],
            [70.131557908000048, 32.550211722000029],
            [70.131200989000035, 32.549759625000036],
            [70.13084407000008, 32.549355117000061],
            [70.130249205000041, 32.548974403000045],
            [70.129963670000052, 32.548641278000048],
            [70.129630545000055, 32.548260564000032],
            [70.129416394000032, 32.547951235000028],
            [70.129083269000034, 32.547475342000041],
            [70.12867876100006, 32.547094629000071],
            [70.12789353900007, 32.54659494200007],
            [70.127489030000049, 32.546356996000043],
            [70.127132111000037, 32.546166639000035],
            [70.126680013000055, 32.546023871000045],
            [70.125775818000079, 32.545524184000044],
            [70.125323721000029, 32.545048292000047],
            [70.125157158000036, 32.544738962000054],
            [70.125038185000051, 32.544263070000056],
            [70.124919212000066, 32.543787178000059],
            [70.124871623000047, 32.543406464000043],
            [70.124562293000054, 32.542835393000075],
            [70.124062606000052, 32.542145350000055],
            [70.123777071000063, 32.541764636000039],
            [70.123372563000032, 32.541264949000038],
            [70.123063233000039, 32.54093182500003],
            [70.122611135000056, 32.54055111100007],
            [70.122301805000063, 32.540146603000039],
            [70.12199247500007, 32.539861067000061],
            [70.121635556000058, 32.53921861300006],
            [70.121564172000035, 32.538742721000062],
            [70.121397610000031, 32.53829062300008],
            [70.121373816000073, 32.537838525000041],
            [70.12120725300008, 32.537196071000039],
            [70.12075515600003, 32.536791563000065],
            [70.120326853000051, 32.536315670000079],
            [70.119684398000061, 32.535792189000063],
            [70.119208506000064, 32.53543527000005],
            [70.118875382000056, 32.535054556000034],
            [70.118470873000035, 32.534578664000037],
            [70.118042570000057, 32.534031388000074],
            [70.117471500000079, 32.533269960000041],
            [70.117233553000062, 32.532936836000033],
            [70.117019402000039, 32.532508533000055],
            [70.116567304000057, 32.531866078000064],
            [70.116210385000045, 32.531437775000029],
            [70.115877261000037, 32.530985678000036],
            [70.115496547000078, 32.530414607000068],
            [70.115353779000031, 32.529772153000067],
            [70.114663736000068, 32.528511038000033],
            [70.114283022000052, 32.527987557000074],
            [70.113783335000051, 32.527226130000031],
            [70.113521594000076, 32.526702648000025],
            [70.113093291000041, 32.526202961000024],
            [70.112688783000067, 32.525584301000038],
            [70.11235565800007, 32.525013231000059],
            [70.112022534000062, 32.524489749000054],
            [70.111832177000053, 32.524061446000076],
            [70.111689409000064, 32.523442787000079],
            [70.111689409000064, 32.522919305000073],
            [70.111546642000064, 32.522372029000053],
            [70.111380079000071, 32.521800959000075],
            [70.111023160000059, 32.521158504000027],
            [70.110618652000028, 32.519873595000035],
            [70.110547268000062, 32.519469087000061],
            [70.110475884000039, 32.51896940000006],
            [70.11028552700003, 32.518350740000074],
            [70.109999992000041, 32.517517929000064],
            [70.109928608000075, 32.516685118000055],
            [70.109881019000056, 32.516280609000034],
            [70.109857224000052, 32.515804717000037],
            [70.109690662000048, 32.515067084000066],
            [70.109595484000067, 32.514424630000065],
            [70.109333743000036, 32.513686997000036],
            [70.109072002000062, 32.512687623000033],
            [70.108976824000081, 32.511426509000046],
            [70.108976824000081, 32.510855438000078],
            [70.108976824000081, 32.510308162000058],
            [70.108596110000065, 32.509594324000034],
            [70.108500932000084, 32.508975664000047],
            [70.108405753000056, 32.50849977200005],
            [70.108381959000042, 32.508119058000034],
            [70.108215396000048, 32.50757178200007],
            [70.107834683000078, 32.506929328000069],
            [70.107572942000047, 32.506429641000068],
            [70.107430174000058, 32.505834776000029],
            [70.107358790000035, 32.505287499000076],
            [70.107287407000058, 32.503693261000024],
            [70.107168434000073, 32.503193574000079],
            [70.107168434000073, 32.502789065000059],
            [70.10709705000005, 32.502003843000068],
            [70.106906693000042, 32.501337594000063],
            [70.106763925000052, 32.500647551000043],
            [70.106668747000072, 32.500243042000079],
            [70.106502185000068, 32.500100275000079],
            [70.106240444000036, 32.499933712000029],
            [70.105978703000062, 32.49964817700004],
            [70.105859730000077, 32.499243669000066],
            [70.105716962000031, 32.498815366000031],
            [70.105669373000069, 32.498434652000071],
            [70.105645579000054, 32.497839787000032],
            [70.105574195000031, 32.496888002000048],
            [70.105502811000065, 32.496197959000028],
            [70.105479016000061, 32.495603093000057],
            [70.105455222000046, 32.495032023000078],
            [70.105431427000042, 32.493985060000057],
            [70.105288659000053, 32.493223632000024],
            [70.105264865000038, 32.492533589000061],
            [70.105050713000082, 32.490963145000023],
            [70.105003124000064, 32.48986859200005],
            [70.104884151000078, 32.488702657000033],
            [70.104717589000074, 32.487251185000048],
            [70.104598616000033, 32.486489758000062],
            [70.104360670000062, 32.48520484900007],
            [70.104122724000035, 32.484395832000075],
            [70.103932367000084, 32.483777172000032],
            [70.103932367000084, 32.482825388000037],
            [70.103765804000034, 32.481540479000046],
            [70.103646831000049, 32.479636910000067],
            [70.103575448000072, 32.478851688000077],
            [70.103527858000064, 32.47768575200007],
            [70.103551653000068, 32.476710173000072],
            [70.103432680000083, 32.476091514000075],
            [70.103385091000064, 32.475496648000046],
            [70.103075761000071, 32.47394999900007],
            [70.102861609000058, 32.473164777000079],
            [70.10267125200005, 32.472450938000065],
            [70.102552279000065, 32.471665716000075],
            [70.102338128000042, 32.470357013000068],
            [70.102314333000038, 32.469690764000063],
            [70.102338128000042, 32.469143487000053],
            [70.102480895000042, 32.468501033000052],
            [70.102480895000042, 32.467929962000028],
            [70.10243330600008, 32.467335097000046],
            [70.102385717000061, 32.466692643000044],
            [70.102338128000042, 32.466026394000039],
            [70.102385717000061, 32.465312555000025],
            [70.102409512000065, 32.464527333000035],
            [70.102457101000084, 32.464003852000076],
            [70.102647458000035, 32.463290014000052],
            [70.102861609000058, 32.462076488000037],
            [70.103028171000062, 32.461267472000031],
            [70.103028171000062, 32.460339482000052],
            [70.103123350000033, 32.459387698000057],
            [70.103123350000033, 32.458888011000056],
            [70.103242323000075, 32.458412119000059],
            [70.103289912000037, 32.458055199000057],
            [70.103480269000045, 32.457650691000026],
            [70.103551653000068, 32.457365156000037],
            [70.103456474000041, 32.45688926400004],
            [70.103194734000056, 32.456080247000045],
            [70.102861609000058, 32.454890516000034],
            [70.102695047000054, 32.454152883000063],
            [70.102623663000031, 32.453462840000043],
            [70.10243330600008, 32.452487261000044],
            [70.10212397600003, 32.451416503000075],
            [70.101767057000075, 32.450655076000032],
            [70.101291165000077, 32.449893648000057],
            [70.10081527300008, 32.44913222100007],
            [70.100101435000056, 32.447942490000059],
            [70.099696926000036, 32.447133474000054],
            [70.099387596000042, 32.446419635000041],
            [70.099363802000084, 32.445943743000043],
            [70.099363802000084, 32.445586824000031],
            [70.099435185000061, 32.444968164000045],
            [70.099696926000036, 32.444254326000078],
            [70.099839694000082, 32.443397720000064],
            [70.099839694000082, 32.442945622000025],
            [70.099815899000077, 32.442112811000072],
            [70.099839694000082, 32.441494151000029],
            [70.099982461000081, 32.439566788000036],
            [70.099982461000081, 32.439067101000035],
            [70.100149024000075, 32.43866259300006],
            [70.100149024000075, 32.438281879000044],
            [70.099934872000063, 32.437877370000024],
            [70.099744515000054, 32.437282505000042],
            [70.099530364000032, 32.436521078000055],
            [70.099078266000049, 32.435497909000048],
            [70.098578580000037, 32.434474741000031],
            [70.09786474100008, 32.432856708000031],
            [70.09731746500006, 32.431857334000028],
            [70.097008135000067, 32.431310058000065],
            [70.09653224300007, 32.430857961000072],
            [70.096056351000072, 32.430524836000075],
            [70.095770816000083, 32.430191712000067],
            [70.095556664000071, 32.429644436000046],
            [70.095485280000048, 32.42940648900003],
            [70.095390102000067, 32.428978186000052],
            [70.095509075000052, 32.428407116000074],
            [70.095889789000069, 32.427360153000052],
            [70.096318092000047, 32.426146628000026],
            [70.096698805000074, 32.424671362000026],
            [70.097269876000041, 32.42243466900004],
            [70.097484027000064, 32.421125965000044],
            [70.097484027000064, 32.420126592000031],
            [70.097603001000039, 32.418865477000054],
            [70.097507822000068, 32.417818515000079],
            [70.097269876000041, 32.416509811000026],
            [70.097055724000029, 32.415105929000049],
            [70.097055724000029, 32.414534858000025],
            [70.097055724000029, 32.414106555000046],
            [70.09731746500006, 32.413487896000049],
            [70.097531617000072, 32.412512317000051],
            [70.097793357000057, 32.411560532000067],
            [70.098174071000074, 32.410370802000045],
            [70.098269250000044, 32.410037677000048],
            [70.098316839000063, 32.40970455300004],
            [70.09800750900007, 32.409561785000051],
            [70.097484027000064, 32.409561785000051],
            [70.096651216000055, 32.409514196000032],
            [70.095271129000082, 32.409371428000043],
            [70.093962425000029, 32.409109687000068],
            [70.093319971000028, 32.409014509000031],
            [70.092915462000065, 32.409157277000077],
            [70.092154035000078, 32.409752142000059],
            [70.091392607000046, 32.409918704000063],
            [70.090773948000049, 32.409918704000063],
            [70.090226672000028, 32.409894910000048],
            [70.089679396000065, 32.409656963000032],
            [70.089322476000063, 32.409490401000028],
            [70.089036941000074, 32.409181071000035],
            [70.088989352000056, 32.408895536000045],
            [70.088989352000056, 32.408443438000063],
            [70.089060736000079, 32.407919957000047],
            [70.089084530000036, 32.40720611900008],
            [70.089036941000074, 32.406801610000059],
            [70.08887037900007, 32.406159156000058],
            [70.088751406000029, 32.405802237000046],
            [70.088751406000029, 32.405397728000025],
            [70.088751406000029, 32.405112193000036],
            [70.088822790000052, 32.404802863000043],
            [70.08901314600007, 32.404445944000031],
            [70.089441449000049, 32.403922463000072],
            [70.089750779000042, 32.40342277600007],
            [70.090202877000081, 32.402732732000061],
            [70.09048841200007, 32.40223304500006],
            [70.090797742000063, 32.401471618000073],
            [70.090940510000053, 32.400995726000076],
            [70.091011894000076, 32.399805995000065],
            [70.091011894000076, 32.398497292000059],
            [70.090988099000072, 32.39790242600003],
            [70.09117845600008, 32.397545507000075],
            [70.091011894000076, 32.396998231000055],
            [70.090964304000067, 32.396141625000041],
            [70.090750153000045, 32.395356403000051],
            [70.090536001000032, 32.394761538000068],
            [70.090274261000047, 32.394357029000048],
            [70.090060109000035, 32.394071494000059],
            [70.090226672000028, 32.393976316000078],
            [70.090654974000074, 32.394000110000036],
            [70.091083277000052, 32.394190467000044],
            [70.091630553000073, 32.394357029000048],
            [70.091916089000051, 32.394357029000048],
            [70.09234439200003, 32.394309440000029],
            [70.092772695000065, 32.394214262000048],
            [70.093177203000039, 32.394000110000036],
            [70.094485907000035, 32.393524218000039],
            [70.095509075000052, 32.393024531000037],
            [70.09677018900004, 32.392572434000044],
            [70.098221660000036, 32.392144131000066],
            [70.100149024000075, 32.391739622000046],
            [70.101243576000059, 32.39162064900006],
            [70.102028798000049, 32.39162064900006],
            [70.103313707000041, 32.391382703000033],
            [70.104408259000081, 32.391097168000044],
            [70.105217276000076, 32.39083542700007],
            [70.106288033000055, 32.39028815100005],
            [70.107120844000065, 32.389669491000063],
            [70.107834683000078, 32.389050832000066],
            [70.108453342000075, 32.388503556000046],
            [70.109024413000043, 32.387861101000055],
            [70.109809635000033, 32.387123468000027],
            [70.110571063000066, 32.386433424000074],
            [70.111308696000037, 32.385671997000031],
            [70.111998739000057, 32.385100926000064],
            [70.112688783000067, 32.38424432000005],
            [70.113117086000045, 32.383578071000045],
            [70.113616773000047, 32.38276905500004],
            [70.114021281000078, 32.381888654000079],
            [70.114283022000052, 32.381269994000036],
            [70.114283022000052, 32.38060374500003],
            [70.114092665000044, 32.379842318000044],
            [70.11392610300004, 32.379152274000035],
            [70.113902308000036, 32.378700176000052],
            [70.113973692000059, 32.37820049000004],
            [70.114092665000044, 32.377867365000043],
            [70.114259227000048, 32.377367678000041],
            [70.114259227000048, 32.376963170000067],
            [70.114116460000048, 32.376463483000066],
            [70.113854719000074, 32.376011385000027],
            [70.113426416000038, 32.375511699000072],
            [70.112926729000037, 32.374774066000043],
            [70.11245083700004, 32.37436955700008],
            [70.111713204000068, 32.373750898000026],
            [70.111451463000037, 32.373417773000028],
            [70.110832803000051, 32.372513578000053],
            [70.110356911000054, 32.372109069000032],
            [70.109476511000082, 32.371490410000035],
            [70.108596110000065, 32.370538625000052],
            [70.107929861000059, 32.369658225000023],
            [70.107334996000077, 32.368873003000033],
            [70.106478390000063, 32.368040191000034],
            [70.106121471000051, 32.36763568300006],
            [70.105169686000067, 32.36685046100007],
            [70.104360670000062, 32.366303185000049],
            [70.103480269000045, 32.365660730000059],
            [70.102695047000054, 32.365351400000065],
            [70.101909825000064, 32.365042070000072],
            [70.100791478000076, 32.364375821000067],
            [70.100077640000052, 32.363876135000055],
            [70.099387596000042, 32.363328859000035],
            [70.098745142000041, 32.362829172000033],
            [70.097650590000057, 32.362353280000036],
            [70.095818405000045, 32.361639441000079],
            [70.095223540000063, 32.361449084000071],
            [70.094723853000062, 32.361520468000037],
            [70.093867247000048, 32.361568057000056],
            [70.093462738000028, 32.361687030000041],
            [70.09303443500005, 32.361639441000079],
            [70.092986846000031, 32.361425290000057],
            [70.093105819000073, 32.361163549000025],
            [70.093391355000051, 32.360854219000032],
            [70.093772068000078, 32.360568684000043],
            [70.093986220000033, 32.360283149000054],
            [70.09422416600006, 32.359973819000061],
            [70.094462112000031, 32.359616899000059],
            [70.094414523000069, 32.359307570000055],
            [70.094105193000075, 32.359259980000047],
            [70.09367689000004, 32.359283775000051],
            [70.093224792000058, 32.359164802000066],
            [70.092772695000065, 32.359117213000047],
            [70.092106446000059, 32.358950650000054],
            [70.091582964000054, 32.358522347000076],
            [70.090940510000053, 32.35823681200003],
            [70.090512207000074, 32.357665741000062],
            [70.089964931000054, 32.356999492000057],
            [70.089489039000057, 32.356285654000033],
            [70.089084530000036, 32.355452843000023],
            [70.089060736000079, 32.354643826000029],
            [70.088917968000032, 32.35366824700003],
            [70.088917968000032, 32.353335123000079],
            [70.088989352000056, 32.353097176000063],
            [70.089227298000083, 32.35288302500004],
            [70.089774574000046, 32.352668873000027],
            [70.090083904000039, 32.352454722000061],
            [70.090226672000028, 32.352240570000049],
            [70.090250466000043, 32.351978830000064],
            [70.090131493000058, 32.351836062000075],
            [70.089584217000038, 32.351598116000048],
            [70.08887037900007, 32.351360170000078],
            [70.088061362000076, 32.350836688000072],
            [70.087704443000064, 32.35047976900006],
            [70.087490291000051, 32.350003877000063],
            [70.087347524000052, 32.349432807000028],
            [70.086705069000061, 32.348100308000028],
            [70.08606261500006, 32.346886783000059],
            [70.085705696000048, 32.346220534000054],
            [70.085301187000084, 32.34536392800004],
            [70.08513462500008, 32.344935625000062],
            [70.085110830000076, 32.344602501000054],
            [70.085206009000046, 32.344079019000048],
            [70.085658106000039, 32.343555538000032],
            [70.086324356000034, 32.343032057000073],
            [70.086943015000031, 32.342579959000034],
            [70.08768064800006, 32.342484781000053],
            [70.088965557000051, 32.342389602000026],
            [70.090179082000077, 32.342389602000026],
            [70.091987473000074, 32.342389602000026],
            [70.093177203000039, 32.342460986000049],
            [70.093819658000029, 32.342675137000072],
            [70.094438317000083, 32.342817905000061],
            [70.09505697700007, 32.342841700000065],
            [70.095604253000033, 32.342841700000065],
            [70.096222913000076, 32.342746521000038],
            [70.096865368000067, 32.342698932000076],
            [70.097959920000051, 32.34255616400003],
            [70.098507196000071, 32.34241339700003],
            [70.098745142000041, 32.34217545100006],
            [70.098745142000041, 32.34203268300007],
            [70.098412017000044, 32.341794737000043],
            [70.097983714000065, 32.341318845000046],
            [70.097198492000075, 32.340866747000064],
            [70.09653224300007, 32.340414649000024],
            [70.095889789000069, 32.34001014100005],
            [70.094938004000028, 32.339153535000037],
            [70.093748274000063, 32.337963805000072],
            [70.093105819000073, 32.337392734000048],
            [70.092177830000082, 32.336845458000028],
            [70.091059483000038, 32.336179209000079],
            [70.090226672000028, 32.335560549000036],
            [70.08925109300003, 32.334775327000045],
            [70.088561049000077, 32.333894926000028],
            [70.087704443000064, 32.332776580000029],
            [70.087204756000062, 32.332038947000058],
            [70.087038194000058, 32.331515465000052],
            [70.08684783700005, 32.33101577900004],
            [70.08684783700005, 32.330635065000024],
            [70.087014399000054, 32.330349530000035],
            [70.087442702000033, 32.329588102000059],
            [70.08791859400003, 32.328636318000065],
            [70.088108951000038, 32.328231809000044],
            [70.088156540000057, 32.327898685000036],
            [70.088132746000042, 32.327613150000047],
            [70.087704443000064, 32.327089668000042],
            [70.087656854000045, 32.326851722000072],
            [70.087371318000066, 32.326518597000074],
            [70.087038194000058, 32.326233062000028],
            [70.086752659000069, 32.325995116000058],
            [70.086490918000038, 32.325828554000054],
            [70.085967436000033, 32.325590608000027],
            [70.085491544000035, 32.32525748300003],
            [70.085087036000061, 32.325090921000026],
            [70.08482529500003, 32.324781591000033],
            [70.084111457000063, 32.32418672600005],
            [70.083706948000042, 32.323972574000038],
            [70.083421413000053, 32.323806012000034],
            [70.08311208300006, 32.323829807000038],
            [70.082541013000082, 32.324043958000061],
            [70.081969942000057, 32.324258110000073],
            [70.081517844000075, 32.324567439000077],
            [70.080780212000036, 32.324852975000056],
            [70.080447087000039, 32.324995742000056],
            [70.080042579000065, 32.324995742000056],
            [70.079804633000037, 32.324829180000052],
            [70.079614276000029, 32.324353288000054],
            [70.079138383000043, 32.323806012000034],
            [70.078662491000046, 32.323044584000058],
            [70.078305572000033, 32.322140389000026],
            [70.07775829600007, 32.320617534000064],
            [70.077353788000039, 32.319879901000036],
            [70.077234815000054, 32.319475393000062],
            [70.077115842000069, 32.318928117000041],
            [70.077115842000069, 32.318571198000029],
            [70.076949279000075, 32.318309457000055],
            [70.076639949000082, 32.31764320800005],
            [70.076449593000063, 32.317143521000048],
            [70.076045084000043, 32.316001380000046],
            [70.075926111000058, 32.315644461000034],
            [70.075521603000084, 32.315168569000036],
            [70.075283657000057, 32.315002006000043],
            [70.075117094000063, 32.314645087000031],
            [70.074950532000059, 32.314192990000038],
            [70.074950532000059, 32.313812276000078],
            [70.074926738000045, 32.313383973000043],
            [70.074855354000078, 32.313027054000031],
            [70.07466499700007, 32.312455983000063],
            [70.074546024000028, 32.312051475000032],
            [70.074450845000058, 32.311837323000077],
            [70.074046337000084, 32.311385226000027],
            [70.073927364000042, 32.31105210100003],
            [70.073855980000076, 32.310600004000037],
            [70.07371321200003, 32.309862371000065],
            [70.073594239000045, 32.308886792000067],
            [70.073284909000051, 32.307697061000056],
            [70.073261115000037, 32.307292553000025],
            [70.073261115000037, 32.307007018000036],
            [70.073403883000083, 32.306769071000076],
            [70.073618034000049, 32.306602509000072],
            [70.074141515000065, 32.306412152000064],
            [70.074879148000036, 32.305983849000029],
            [70.075426424000057, 32.305674519000036],
            [70.075854727000035, 32.305603136000059],
            [70.076187852000032, 32.305341395000028],
            [70.076306825000074, 32.305079654000053],
            [70.076354414000036, 32.304603762000056],
            [70.076354414000036, 32.303937513000051],
            [70.076354414000036, 32.302842961000067],
            [70.076306825000074, 32.300820419000047],
            [70.07628303000007, 32.299940019000076],
            [70.07628303000007, 32.299131002000024],
            [70.076187852000032, 32.297703325000043],
            [70.076187852000032, 32.296941898000057],
            [70.076164057000028, 32.296204265000028],
            [70.075973700000077, 32.295300070000053],
            [70.075711960000035, 32.294276901000046],
            [70.075688165000031, 32.29296819800004],
            [70.075759549000054, 32.291992619000041],
            [70.075807138000073, 32.290969451000024],
            [70.075854727000035, 32.28977972000007],
            [70.075807138000073, 32.288994498000079],
            [70.075854727000035, 32.288518606000025],
            [70.075902317000043, 32.288185481000028],
            [70.075926111000058, 32.287780973000054],
            [70.075807138000073, 32.286567448000028],
            [70.075664370000084, 32.28521115500007],
            [70.075664370000084, 32.284378344000061],
            [70.075521603000084, 32.283307586000035],
            [70.07518847800003, 32.281451607000065],
            [70.075021916000082, 32.280618796000056],
            [70.074974327000064, 32.279500449000068],
            [70.074950532000059, 32.278643843000054],
            [70.074902943000041, 32.277811032000045],
            [70.074783970000055, 32.276787863000038],
            [70.074641202000066, 32.275288803000024],
            [70.074593613000047, 32.272909342000048],
            [70.074688791000028, 32.27248103900007],
            [70.074688791000028, 32.271957558000054],
            [70.074522229000081, 32.271386487000029],
            [70.074189105000073, 32.271267514000044],
            [70.073308704000056, 32.271196130000078],
            [70.072475893000046, 32.271172336000063],
            [70.070834065000042, 32.270910595000032],
            [70.069406388000061, 32.270767827000043],
            [70.068407015000048, 32.270625059000054],
            [70.066455857000051, 32.269935016000034],
            [70.064980591000051, 32.269459124000036],
            [70.063267379000081, 32.268816669000046],
            [70.06193488100007, 32.268198009000059],
            [70.061102069000071, 32.267983858000036],
            [70.060007517000031, 32.267769706000024],
            [70.058936760000051, 32.267555555000058],
            [70.057366316000071, 32.267317609000031],
            [70.056676272000061, 32.267198636000046],
            [70.05627176400003, 32.267103457000076],
            [70.056081407000079, 32.266984484000034],
            [70.055962434000037, 32.266627565000078],
            [70.055891050000071, 32.266175467000039],
            [70.055700693000063, 32.265556808000042],
            [70.055296185000032, 32.264938148000056],
            [70.054772703000083, 32.26427189900005],
            [70.054463373000033, 32.263843596000072],
            [70.054177838000044, 32.263415293000037],
            [70.054130249000082, 32.262677660000065],
            [70.054130249000082, 32.262106589000041],
            [70.05401127600004, 32.261511724000059],
            [70.053963686000031, 32.26077409100003],
            [70.05401127600004, 32.259941280000078],
            [70.05401127600004, 32.259346415000039],
            [70.054130249000082, 32.258870522000052],
            [70.054273016000081, 32.258466014000078],
            [70.054534757000056, 32.257918738000058],
            [70.05510582800008, 32.257252489000052],
            [70.055605515000082, 32.256562445000043],
            [70.055938639000033, 32.255801018000057],
            [70.056700067000065, 32.254706466000073],
            [70.057437699000047, 32.253730887000074],
            [70.057770824000045, 32.253421557000024],
            [70.058103949000042, 32.252993254000046],
            [70.058770198000047, 32.252517362000049],
            [70.059531625000034, 32.252041469000062],
            [70.060197874000039, 32.251589372000069],
            [70.060911712000063, 32.251089685000068],
            [70.061221042000057, 32.250708971000051],
            [70.061506578000035, 32.250304463000077],
            [70.061744524000062, 32.250018928000031],
            [70.061792113000081, 32.249757187000057],
            [70.061649345000035, 32.249400268000045],
            [70.061244837000061, 32.248876786000039],
            [70.060864123000044, 32.248162948000072],
            [70.060364436000043, 32.247377726000025],
            [70.05991233900005, 32.246521120000068],
            [70.059293679000064, 32.245593130000032],
            [70.058865376000028, 32.244879292000064],
            [70.058698814000081, 32.244355810000059],
            [70.058532252000077, 32.243903713000066],
            [70.058460868000054, 32.243118491000075],
            [70.058127743000057, 32.241809787000079],
            [70.058127743000057, 32.240953181000066],
            [70.058127743000057, 32.240144165000061],
            [70.058127743000057, 32.239620683000055],
            [70.058151538000061, 32.239192380000077],
            [70.058318100000065, 32.238883050000027],
            [70.058841581000081, 32.23840715800003],
            [70.059317474000068, 32.238335774000063],
            [70.060031312000035, 32.23826439000004],
            [70.060649972000078, 32.23826439000004],
            [70.061054480000053, 32.238240596000026],
            [70.06193488100007, 32.238240596000026],
            [70.062505951000048, 32.238240596000026],
            [70.063243584000077, 32.238216801000078],
            [70.063933628000029, 32.238216801000078],
            [70.064576082000031, 32.238074034000078],
            [70.065456483000048, 32.23788367700007],
            [70.066146527000058, 32.237502963000054],
            [70.066907954000044, 32.237146044000042],
            [70.067241079000041, 32.236860508000063],
            [70.06752661400003, 32.236479795000037],
            [70.06752661400003, 32.236194259000058],
            [70.067479025000068, 32.23576595600008],
            [70.067479025000068, 32.23521868000006],
            [70.067621792000068, 32.234576226000058],
            [70.067812149000076, 32.234076539000057],
            [70.067883533000042, 32.233672031000026],
            [70.068192863000036, 32.233148549000077],
            [70.068597371000067, 32.232577479000042],
            [70.068978085000083, 32.232244354000045],
            [70.06931121000008, 32.231958819000056],
            [70.069787102000078, 32.231768462000048],
            [70.070262994000075, 32.231744667000044],
            [70.07061991300003, 32.231744667000044],
            [70.071072011000069, 32.231768462000048],
            [70.071524108000062, 32.231863640000029],
            [70.072023795000064, 32.231863640000029],
            [70.072594866000031, 32.231887435000033],
            [70.073165936000066, 32.231887435000033],
            [70.073951159000046, 32.231816051000067],
            [70.074617408000051, 32.231649489000063],
            [70.075069505000044, 32.23157810500004],
            [70.075450219000061, 32.231316364000065],
            [70.075830933000077, 32.230959445000053],
            [70.076259236000055, 32.23050734800006],
            [70.076639949000082, 32.230079045000025],
            [70.077092047000065, 32.229507974000057],
            [70.077591734000066, 32.229079671000079],
            [70.078020037000044, 32.228722752000067],
            [70.078424545000075, 32.228603779000025],
            [70.079209767000066, 32.228270654000028],
            [70.079994989000056, 32.228080297000076],
            [70.08085159500007, 32.227770968000073],
            [70.081874764000077, 32.227533021000056],
            [70.082826548000071, 32.227366459000052],
            [70.083421413000053, 32.227271281000071],
            [70.084230430000048, 32.227176102000044],
            [70.084706322000045, 32.227247486000067],
            [70.085348777000036, 32.227247486000067],
            [70.085848463000048, 32.227318870000033],
            [70.08660989100008, 32.227342665000037],
            [70.087299935000033, 32.227342665000037],
            [70.088061362000076, 32.227318870000033],
            [70.089227298000083, 32.227223692000052],
            [70.090012520000073, 32.227104718000078],
            [70.090797742000063, 32.22700954000004],
            [70.091440197000054, 32.226747799000066],
            [70.092273008000063, 32.226367086000039],
            [70.092701311000042, 32.226010166000037],
            [70.093129614000077, 32.225748426000052],
            [70.093748274000063, 32.225224944000047],
            [70.09422416600006, 32.22460628400006],
            [70.094652469000039, 32.224201776000029],
            [70.095390102000067, 32.223416554000039],
            [70.096008762000054, 32.22272651000003],
            [70.096556038000074, 32.222012672000062],
            [70.096960546000048, 32.221441601000038],
            [70.09731746500006, 32.22087053100006],
            [70.097388849000083, 32.220394639000062],
            [70.097388849000083, 32.220013925000046],
            [70.09731746500006, 32.219561827000064],
            [70.097246081000037, 32.219085935000066],
            [70.096841573000063, 32.218395891000057],
            [70.096746395000082, 32.217824821000079],
            [70.096587323000051, 32.217297372000075],
            [70.096294297000043, 32.216325760000075],
            [70.096227395000028, 32.21606930300004],
            [70.095675239000059, 32.213417928000069],
            [70.095206000000076, 32.213208000000066],
            [70.079302000000041, 32.20260600000006],
            [70.077314000000058, 32.192004000000054],
            [70.070688000000075, 32.180739000000074],
            [70.070128114000056, 32.167870995000044],
            [70.070025000000044, 32.165498000000071],
            [70.074000912000031, 32.150257337000028],
            [70.074001000000067, 32.150257000000067],
            [70.086591000000055, 32.137005000000045],
            [70.094361499000058, 32.138299757000027],
            [70.094543000000044, 32.138330000000053],
            [70.09456289600007, 32.138298912000039],
            [70.098376524000059, 32.132339983000065],
            [70.098402842000041, 32.132298860000049],
            [70.10514500000005, 32.121764000000042],
            [70.106471000000056, 32.103872000000024],
            [70.099844000000076, 32.094596000000024],
            [70.109784000000047, 32.083993000000078],
            [70.110446000000081, 32.068090000000041],
            [70.119061000000045, 32.046885000000032],
            [70.123037000000068, 32.026343000000054],
            [70.132314000000065, 32.015079000000071],
            [70.146892000000037, 32.009115000000065],
            [70.164783000000057, 31.994537000000037],
            [70.167648000000042, 31.992409000000066],
            [70.187975000000051, 31.97730800000005],
            [70.19128900000004, 31.964718000000062],
            [70.199240000000032, 31.932248000000072],
            [70.206011492000073, 31.899435147000077],
            [70.207855000000052, 31.890502000000026],
            [70.212493000000052, 31.872611000000063],
            [70.217771261000053, 31.84951748800006],
            [70.217794000000083, 31.849418000000071],
            [70.221770000000049, 31.836828000000025],
            [70.214481000000035, 31.825563000000045],
            [70.213252435000072, 31.814810967000028],
            [70.21213875400008, 31.81543051400007],
            [70.211892848000048, 31.815548566000075],
            [70.211767869000084, 31.815493888000049],
            [70.210946955000054, 31.81517266000003],
            [70.209733430000028, 31.814637282000035],
            [70.208841132000032, 31.814316054000074],
            [70.207913142000052, 31.814101903000051],
            [70.207127920000062, 31.813959135000061],
            [70.206449774000077, 31.813959135000061],
            [70.205985779000059, 31.814101903000051],
            [70.205414708000035, 31.81438743800004],
            [70.205271941000035, 31.814708665000069],
            [70.205164865000029, 31.81517266000003],
            [70.205129173000046, 31.815636655000048],
            [70.205164865000029, 31.816100650000067],
            [70.205450400000075, 31.81677879700004],
            [70.205557476000081, 31.817456943000025],
            [70.20580731900003, 31.818206473000032],
            [70.20584301100007, 31.818777544000056],
            [70.20584301100007, 31.819277231000058],
            [70.20584301100007, 31.819883993000076],
            [70.20580731900003, 31.820347988000037],
            [70.205486092000058, 31.820883367000079],
            [70.205093481000063, 31.82134736200004],
            [70.204772254000034, 31.821704281000052],
            [70.203879956000037, 31.821811356000069],
            [70.203415961000076, 31.821454437000057],
            [70.202951966000057, 31.821061826000062],
            [70.202416588000062, 31.82056213900006],
            [70.201988285000084, 31.820133836000025],
            [70.201310138000053, 31.819669842000053],
            [70.200881835000075, 31.819241539000075],
            [70.200417840000057, 31.819027387000062],
            [70.199953845000039, 31.819027387000062],
            [70.199347083000077, 31.819063079000045],
            [70.19856186100003, 31.819384306000075],
            [70.197955098000079, 31.819955377000042],
            [70.197348336000061, 31.820633523000026],
            [70.196634497000048, 31.821311670000057],
            [70.195992043000047, 31.821989816000041],
            [70.195492356000045, 31.823060573000078],
            [70.194849902000044, 31.823845796000057],
            [70.194385907000083, 31.825344856000072],
            [70.193743452000035, 31.826201462000029],
            [70.193172382000057, 31.827058068000042],
            [70.192351468000084, 31.828842664000035],
            [70.191530554000053, 31.830270340000027],
            [70.190602564000073, 31.832126320000043],
            [70.189960109000083, 31.833446921000075],
            [70.189281963000042, 31.834838905000026],
            [70.188710892000074, 31.83573120300008],
            [70.188032746000033, 31.836587809000036],
            [70.187425984000072, 31.837051804000055],
            [70.186355226000046, 31.837908410000068],
            [70.185677080000062, 31.838301021000063],
            [70.184891858000071, 31.838622248000036],
            [70.184178019000058, 31.838693632000059],
            [70.18357125700004, 31.838586556000052],
            [70.182571883000037, 31.83837240500003],
            [70.180394676000049, 31.837979794000034],
            [70.178967000000057, 31.837480107000033],
            [70.178253161000043, 31.837337339000044],
            [70.177575015000059, 31.837087496000038],
            [70.176896869000075, 31.83665919300006],
            [70.175790419000066, 31.836016738000069],
            [70.174826738000036, 31.83555274400004],
            [70.173684596000044, 31.835088749000079],
            [70.172292612000035, 31.834731829000077],
            [70.170508016000042, 31.834446294000031],
            [70.168901880000078, 31.834517678000054],
            [70.167795431000059, 31.834803213000043],
            [70.166617597000084, 31.835445668000034],
            [70.165439764000041, 31.835909663000052],
            [70.164226239000072, 31.836694885000043],
            [70.163012714000047, 31.837837026000045],
            [70.162405951000039, 31.838836400000048],
            [70.161763497000038, 31.839800081000078],
            [70.161370886000043, 31.841156374000036],
            [70.160764123000035, 31.842726818000074],
            [70.160193053000057, 31.844083111000032],
            [70.159764750000079, 31.844725566000079],
            [70.159015220000072, 31.846081858000048],
            [70.158265689000075, 31.846974156000044],
            [70.157623235000074, 31.847545227000069],
            [70.156052791000036, 31.848294757000076],
            [70.154696498000078, 31.848758752000037],
            [70.153768508000041, 31.849222747000056],
            [70.152840518000062, 31.849436898000079],
            [70.151734069000042, 31.849543974000028],
            [70.150948847000052, 31.849472590000062],
            [70.150199317000045, 31.849472590000062],
            [70.149414095000054, 31.849365514000056],
            [70.148129186000062, 31.84911567100005],
            [70.146772893000048, 31.848865828000044],
            [70.146273206000046, 31.848830136000061],
            [70.145416600000033, 31.848865828000044],
            [70.144203075000064, 31.848865828000044],
            [70.142739707000032, 31.848830136000061],
            [70.140990803000079, 31.848865828000044],
            [70.139348975000075, 31.848758752000037],
            [70.137814222000031, 31.848794444000077],
            [70.136707773000069, 31.848651676000031],
            [70.135494248000043, 31.848651676000031],
            [70.134816102000059, 31.848508908000042],
            [70.134066571000062, 31.848330449000059],
            [70.13292443000006, 31.84800922200003],
            [70.131461062000028, 31.847580919000052],
            [70.129997693000064, 31.846938464000061],
            [70.128462941000066, 31.846331702000043],
            [70.126856805000045, 31.845796323000059],
            [70.125429128000064, 31.845296636000057],
            [70.123858684000083, 31.844832641000039],
            [70.121824245000084, 31.843940343000043],
            [70.120717795000076, 31.843440657000031],
            [70.119432887000073, 31.842833894000023],
            [70.118362129000047, 31.842512667000051],
            [70.117077220000056, 31.842405591000045],
            [70.115506776000075, 31.842405591000045],
            [70.11225881200005, 31.842191440000079],
            [70.110260065000034, 31.842120056000056],
            [70.107511787000078, 31.841798829000027],
            [70.10597703500008, 31.841620369000054],
            [70.104121055000064, 31.841620369000054],
            [70.101836773000059, 31.841905904000043],
            [70.099231263000036, 31.842476975000068],
            [70.09630452600004, 31.843119429000069],
            [70.093377789000044, 31.844047419000049],
            [70.089130451000074, 31.845403712000063],
            [70.086310790000084, 31.846510161000026],
            [70.084561886000074, 31.847295383000073],
            [70.083491129000038, 31.847937838000064],
            [70.082563139000058, 31.848366141000042],
            [70.081813609000051, 31.848758752000037],
            [70.081278230000066, 31.848758752000037],
            [70.080814235000048, 31.848758752000037],
            [70.079993321000075, 31.848651676000031],
            [70.079350867000073, 31.848473217000048],
            [70.078744104000066, 31.848437525000065],
            [70.078137342000048, 31.848508908000042],
            [70.077387811000051, 31.848651676000031],
            [70.076209978000065, 31.848865828000044],
            [70.075674599000081, 31.849008595000043],
            [70.074532458000078, 31.849365514000056],
            [70.073461701000042, 31.849758125000051],
            [70.072747862000028, 31.849936585000023],
            [70.072141100000067, 31.850007969000046],
            [70.071320186000037, 31.850007969000046],
            [70.070713423000029, 31.850007969000046],
            [70.069821125000033, 31.850007969000046],
            [70.069142979000048, 31.850115045000052],
            [70.066751621000037, 31.850579039000024],
            [70.065466712000045, 31.850971651000066],
            [70.064467338000043, 31.851150110000049],
            [70.063218121000034, 31.851614105000067],
            [70.062218748000078, 31.851935332000039],
            [70.061076606000029, 31.852399327000057],
            [70.059756006000043, 31.852756246000069],
            [70.058720940000057, 31.853113165000025],
            [70.057792950000078, 31.85354146800006],
            [70.056722193000041, 31.853898388000061],
            [70.055187441000044, 31.854612226000029],
            [70.054080991000035, 31.855076221000047],
            [70.052724698000077, 31.855754367000031],
            [70.051261330000045, 31.856325438000056],
            [70.050297648000083, 31.856789433000074],
            [70.049583810000058, 31.857289119000029],
            [70.048727204000045, 31.85782449800007],
            [70.047870598000031, 31.858681104000027],
            [70.047049684000058, 31.85985893700007],
            [70.046407230000057, 31.860751235000066],
            [70.045871851000072, 31.86157214900004],
            [70.045443548000037, 31.862571523000042],
            [70.045086629000082, 31.863499512000033],
            [70.044872477000069, 31.864498886000035],
            [70.044622634000064, 31.865498260000038],
            [70.044301407000034, 31.866890244000047],
            [70.043944488000079, 31.868317921000028],
            [70.043694644000084, 31.869781289000059],
            [70.043480493000061, 31.870887739000068],
            [70.043337725000072, 31.872386799000026],
            [70.043337725000072, 31.873528940000028],
            [70.043087882000066, 31.874528314000031],
            [70.042873730000053, 31.875063693000072],
            [70.042302659000029, 31.875563380000074],
            [70.041910048000034, 31.87577753100004],
            [70.040839291000054, 31.876491369000064],
            [70.040089761000047, 31.877062440000032],
            [70.039161771000067, 31.877490743000067],
            [70.037912554000059, 31.877990430000068],
            [70.037127332000068, 31.878383041000063],
            [70.036235034000072, 31.878954111000041],
            [70.03573534700007, 31.879132571000071],
            [70.035307044000035, 31.879346722000037],
            [70.034450438000079, 31.879525182000066],
            [70.033807984000077, 31.879560874000049],
            [70.03305845400007, 31.879739334000078],
            [70.032380307000039, 31.879989177000027],
            [70.031488009000043, 31.880346096000039],
            [70.030845555000042, 31.880703015000051],
            [70.029774798000062, 31.881595313000048],
            [70.028882500000066, 31.882416227000078],
            [70.02784743400008, 31.883272833000035],
            [70.027062212000033, 31.883736828000053],
            [70.026419758000031, 31.884272207000038],
            [70.025313308000079, 31.884950353000079],
            [70.024456702000066, 31.885806959000035],
            [70.023707172000059, 31.886449413000037],
            [70.02324317700004, 31.887020484000061],
            [70.022565031000056, 31.887805706000051],
            [70.021744117000082, 31.888626620000025],
            [70.020923203000052, 31.889233383000033],
            [70.020209364000038, 31.889697378000051],
            [70.019174299000042, 31.890375524000035],
            [70.017996466000056, 31.89076813500003],
            [70.016354638000053, 31.891303514000072],
            [70.01439158200003, 31.891481973000054],
            [70.012214376000031, 31.89173181700005],
            [70.010251320000066, 31.891910276000033],
            [70.008645184000045, 31.892053044000079],
            [70.007217508000053, 31.892445655000074],
            [70.006003982000038, 31.892802574000029],
            [70.004897533000076, 31.893409337000037],
            [70.004148003000068, 31.893516413000043],
            [70.002434791000042, 31.893730564000066],
            [70.001506801000062, 31.893730564000066],
            [70.000293276000036, 31.893873332000055],
            [69.999365286000057, 31.893694872000026],
            [69.998258837000037, 31.893659180000043],
            [69.997437923000064, 31.89362348800006],
            [69.996081630000049, 31.89362348800006],
            [69.994761029000074, 31.893302261000031],
            [69.993547504000048, 31.892873958000052],
            [69.99219121200008, 31.892481347000057],
            [69.991298914000083, 31.892124428000045],
            [69.990228156000057, 31.89173181700005],
            [69.989335858000061, 31.891446281000071],
            [69.988657712000077, 31.891303514000072],
            [69.988050950000058, 31.891267822000032],
            [69.987408495000068, 31.891232130000049],
            [69.986873116000083, 31.891303514000072],
            [69.986230662000082, 31.891624741000044],
            [69.985623899000075, 31.891981660000056],
            [69.98505282900004, 31.892374271000051],
            [69.984338990000083, 31.893088110000065],
            [69.983625152000059, 31.893766256000049],
            [69.983089773000074, 31.894087483000078],
            [69.982304551000084, 31.894622862000062],
            [69.981376562000037, 31.894908397000052],
            [69.98066272300008, 31.895122549000064],
            [69.979877501000033, 31.89519393300003],
            [69.979056587000059, 31.895408084000053],
            [69.978449825000041, 31.895622235000076],
            [69.978021522000063, 31.895979155000077],
            [69.977735986000084, 31.896514533000072],
            [69.977379067000072, 31.897264064000069],
            [69.976986456000077, 31.89804928600006],
            [69.976629537000065, 31.898798816000067],
            [69.97620123400003, 31.899262811000028],
            [69.975558779000039, 31.899584038000057],
            [69.974844941000072, 31.899905265000029],
            [69.974166795000031, 31.899976649000052],
            [69.97341726500008, 31.899976649000052],
            [69.972917578000079, 31.899976649000052],
            [69.97245358300006, 31.899798189000023],
            [69.972132356000031, 31.899655422000023],
            [69.971596977000047, 31.899334195000051],
            [69.971025906000079, 31.898763124000027],
            [69.970561911000061, 31.898691740000061],
            [69.96991945700006, 31.898691740000061],
            [69.967849326000078, 31.89883450800005],
            [69.966849952000075, 31.89883450800005],
            [69.965850579000062, 31.898798816000067],
            [69.965422276000083, 31.898798816000067],
            [69.964494286000047, 31.898584664000055],
            [69.963958907000062, 31.898156361000076],
            [69.963209377000055, 31.897442523000052],
            [69.96281676600006, 31.897156988000063],
            [69.962566923000054, 31.896692993000045],
            [69.962174312000059, 31.896300382000049],
            [69.96174600900008, 31.896228998000026],
            [69.961210630000039, 31.896228998000026],
            [69.960675251000055, 31.896228998000026],
            [69.96013987200007, 31.896228998000026],
            [69.959551789000045, 31.896228998000026],
            [69.962866000000076, 31.906647000000078],
            [69.962866000000076, 31.924698000000035],
            [69.962866000000076, 31.931918000000053],
            [69.956849286000079, 31.93492685700005],
            [69.956849000000034, 31.934927000000073],
            [69.938799000000074, 31.931317000000035],
            [69.923756000000083, 31.921088000000054],
            [69.91894300000007, 31.907850000000053],
            [69.907510000000059, 31.892808000000059],
            [69.89487500000007, 31.884986000000026],
            [69.881036000000051, 31.880172000000073],
            [69.86599300000006, 31.889198000000079],
            [69.853959000000032, 31.884384000000068],
            [69.850951000000066, 31.920486000000039],
            [69.858171000000084, 31.92710500000004],
            [69.873815000000036, 31.946359000000029],
            [69.878629000000046, 31.960198000000048],
            [69.879832000000079, 31.978851000000077],
            [69.86418800000007, 31.984266000000048],
            [69.844332000000065, 31.992088000000024],
            [69.823273000000029, 32.002317000000062],
            [69.805823000000032, 32.01134200000007],
            [69.802213000000052, 32.021571000000051],
            [69.820264000000066, 32.03541000000007],
            [69.826281000000051, 32.049851000000046],
            [69.837112000000047, 32.069707000000051],
            [69.850951000000066, 32.091368000000045],
            [69.849146000000076, 32.098589000000061],
            [69.788976000000048, 32.089563000000055],
            [69.761899000000028, 32.084750000000042],
            [69.752517084000033, 32.080220827000062],
            [69.749159809000048, 32.078599918000066],
            [69.747637746000066, 32.077865059000032],
            [69.744450000000029, 32.076326000000051],
            [69.717374000000063, 32.072114000000056],
            [69.690899000000059, 32.068504000000075],
            [69.657806000000051, 32.056470000000047],
            [69.644568000000049, 32.029995000000042],
            [69.628322000000082, 32.00111400000003],
            [69.618094000000042, 31.997503000000052],
            [69.60305100000005, 31.990283000000034],
            [69.587407000000042, 31.972232000000076],
            [69.572365000000048, 31.970427000000029],
            [69.562136000000066, 31.957791000000043],
            [69.553283000000079, 31.954472000000067],
            [69.553283099000055, 31.954471714000078],
            [69.547695000000033, 31.952376000000072],
            [69.535661000000061, 31.957791000000043],
            [69.523025000000075, 31.972232000000076],
            [69.515618564000079, 31.970198766000067],
            [69.50240713900007, 31.966571932000079],
            [69.492339000000072, 31.963808000000029],
            [69.486230173000081, 31.963315443000056],
            [69.455033000000071, 31.960800000000063],
            [69.448470479000036, 31.960313835000079],
            [69.422542000000078, 31.958393000000058],
            [69.403889000000049, 31.958393000000058],
            [69.401682520000065, 31.958787094000058],
            [69.387042000000065, 31.961402000000078],
            [69.375008000000037, 31.962003000000038],
            [69.356957000000079, 31.953580000000045],
            [69.343118000000061, 31.949969000000067],
            [69.336475244000042, 31.949019987000042],
            [69.323253000000079, 31.94713100000007],
            [69.318667000000062, 31.961546000000055],
            [69.314735000000042, 31.980547000000058],
            [69.306026000000031, 31.988328000000024],
            [69.297044000000028, 32.003480000000025],
            [69.292458000000067, 32.025102000000061],
            [69.290492000000029, 32.043448000000069],
            [69.289667000000065, 32.067584000000068],
            [69.290269885000043, 32.08633629600007],
            [69.290492000000029, 32.093245000000024],
            [69.279353000000071, 32.121420000000057],
            [69.274767000000054, 32.139110000000073],
            [69.274767000000054, 32.158767000000068],
            [69.275422000000049, 32.17580300000003],
            [69.27738800000003, 32.195459000000028],
            [69.275422000000049, 32.215116000000023],
            [69.274845000000028, 32.237856000000079],
            [69.272275857000068, 32.254402826000046],
            [69.269525000000044, 32.272120000000029],
            [69.281319000000053, 32.284569000000033],
            [69.281319000000053, 32.306192000000067],
            [69.280664000000058, 32.324538000000075],
            [69.281974000000048, 32.354023000000041],
            [69.276077000000043, 32.364506000000063],
            [69.266249000000073, 32.381542000000024],
            [69.25838600000003, 32.399888000000033],
            [69.249213000000054, 32.413648000000023],
            [69.242661000000055, 32.433304000000078],
            [69.24200600000006, 32.458858000000077],
            [69.255425000000059, 32.477668000000051],
            [69.272299000000032, 32.501699000000031],
            [69.28356000000008, 32.521061000000032],
            [69.295829000000083, 32.538066000000072],
            [69.331615000000056, 32.541581000000065],
            [69.350023000000078, 32.549762000000044],
            [69.367920000000083, 32.552829000000031],
            [69.390930000000083, 32.565100000000029],
            [69.401880000000062, 32.587281000000075],
            [69.408249782000041, 32.597745692000046],
            [69.411053000000038, 32.602351000000056],
            [69.413262123000038, 32.604759659000024],
            [69.413998489000051, 32.604704058000038],
            [69.414203169000075, 32.604431152000075],
            [69.41421749400007, 32.604434221000076],
            [69.415109791000077, 32.604469913000059],
            [69.415752246000068, 32.604505605000043],
            [69.416430392000052, 32.604469913000059],
            [69.417358382000032, 32.604112994000047],
            [69.417893761000073, 32.603970226000058],
            [69.41875036700003, 32.603827459000058],
            [69.419928200000072, 32.603827459000058],
            [69.420963265000069, 32.603863150000052],
            [69.421855563000065, 32.603898842000035],
            [69.422747861000062, 32.603898842000035],
            [69.423640159000058, 32.603648999000029],
            [69.424461073000032, 32.60339915600008],
            [69.425317679000045, 32.602935161000062],
            [69.426102901000036, 32.602257014000031],
            [69.427066583000055, 32.601186257000052],
            [69.427994573000035, 32.599972732000026],
            [69.428815487000065, 32.59879489900004],
            [69.429457941000067, 32.597973985000067],
            [69.429993320000051, 32.597509990000049],
            [69.430457315000069, 32.596974611000064],
            [69.430992693000064, 32.596867535000058],
            [69.431528072000049, 32.596867535000058],
            [69.431992067000067, 32.596903227000041],
            [69.432491754000068, 32.597331530000076],
            [69.432884365000064, 32.597831217000078],
            [69.433384052000065, 32.598295212000039],
            [69.43391943000006, 32.598687823000034],
            [69.434454809000044, 32.598901974000057],
            [69.435132955000029, 32.599044742000046],
            [69.436168021000071, 32.599223202000076],
            [69.437238778000051, 32.599223202000076],
            [69.438595071000066, 32.599187510000036],
            [69.439879980000057, 32.599044742000046],
            [69.440772278000054, 32.598973358000023],
            [69.441843035000034, 32.599009050000063],
            [69.443484863000037, 32.599330277000035],
            [69.444912540000075, 32.599294585000052],
            [69.44637590800005, 32.599187510000036],
            [69.447732201000065, 32.59893766600004],
            [69.44969525700003, 32.598509363000062],
            [69.451158625000062, 32.597902601000044],
            [69.451979539000035, 32.597367222000059],
            [69.452764761000083, 32.596796151000035],
            [69.453549983000073, 32.596010929000045],
            [69.454370897000047, 32.595082940000054],
            [69.455334579000066, 32.594369101000041],
            [69.456012725000051, 32.594154950000075],
            [69.456690872000081, 32.594047874000069],
            [69.458082856000033, 32.594083566000052],
            [69.459010846000069, 32.594119258000035],
            [69.459724684000037, 32.594047874000069],
            [69.46043852300005, 32.593905106000079],
            [69.461259437000081, 32.593262652000078],
            [69.463151108000034, 32.591763591000074],
            [69.465078472000073, 32.590228839000076],
            [69.466684608000037, 32.588943930000028],
            [69.468147976000068, 32.588087324000071],
            [69.468933198000059, 32.587659021000036],
            [69.469575653000049, 32.587230718000058],
            [69.470182415000068, 32.586731032000046],
            [69.470503643000029, 32.585838734000049],
            [69.470539335000069, 32.584803668000063],
            [69.470789178000075, 32.583518759000071],
            [69.471503016000042, 32.580020952000041],
            [69.471824243000071, 32.578486199000054],
            [69.472038395000084, 32.577236982000045],
            [69.472466698000062, 32.575987765000036],
            [69.47293069300008, 32.574917008000057],
            [69.473680223000031, 32.574417321000055],
            [69.474286986000038, 32.574060402000043],
            [69.475500511000064, 32.573667791000048],
            [69.476678344000049, 32.573346564000076],
            [69.477320798000051, 32.572989644000074],
            [69.477963253000041, 32.572382882000056],
            [69.47842724800006, 32.571740427000066],
            [69.478784167000072, 32.570990897000058],
            [69.479498005000039, 32.569527529000027],
            [69.479890616000034, 32.568992150000042],
            [69.480711530000065, 32.568242620000035],
            [69.481996439000056, 32.567528782000068],
            [69.483352732000071, 32.566743559000031],
            [69.484387797000068, 32.566243873000076],
            [69.485280095000064, 32.565637110000068],
            [69.486243777000084, 32.564637737000055],
            [69.48710038300004, 32.563745439000058],
            [69.487707145000059, 32.563388520000046],
            [69.488456676000055, 32.563031600000045],
            [69.489170514000079, 32.563031600000045],
            [69.489884352000047, 32.56328144400004],
            [69.490633882000054, 32.563566979000029],
            [69.491383413000051, 32.564066666000031],
            [69.491990175000069, 32.564066666000031],
            [69.492561246000037, 32.563923898000041],
            [69.49356061900005, 32.56342421100004],
            [69.494738453000082, 32.563210060000074],
            [69.495666442000072, 32.563210060000074],
            [69.49627320500008, 32.563067292000028],
            [69.496737200000041, 32.562817449000079],
            [69.497915033000083, 32.562032227000032],
            [69.498664563000034, 32.561675308000076],
            [69.499271326000041, 32.561247005000041],
            [69.500092240000072, 32.560533166000027],
            [69.500189530000057, 32.560518199000057],
            [69.50017577400007, 32.560415025000054],
            [69.500922357000036, 32.560343922000072],
            [69.501921730000049, 32.560058387000026],
            [69.502849720000029, 32.559844235000071],
            [69.503634942000076, 32.559808543000031],
            [69.504241705000084, 32.560094078000077],
            [69.50509831100004, 32.560843609000074],
            [69.506133376000037, 32.56187867400007],
            [69.506775831000084, 32.562378361000071],
            [69.507632437000041, 32.563020815000073],
            [69.508274891000042, 32.563234967000028],
            [69.509202881000078, 32.563270659000068],
            [69.510345022000081, 32.563449118000051],
            [69.510916093000048, 32.563877421000029],
            [69.511344396000084, 32.564805411000066],
            [69.511951158000045, 32.565911861000075],
            [69.512772072000075, 32.566911234000031],
            [69.513557294000066, 32.567696456000078],
            [69.514342516000056, 32.568303219000029],
            [69.515127739000036, 32.568553062000035],
            [69.517126486000052, 32.568767214000047],
            [69.517983092000065, 32.568767214000047],
            [69.518911081000056, 32.568731522000064],
            [69.519981839000081, 32.568874289000064],
            [69.521052596000061, 32.568909981000047],
            [69.522230430000036, 32.569266900000059],
            [69.523051344000066, 32.569516744000055],
            [69.523693798000068, 32.569695203000038],
            [69.524443328000075, 32.569730895000077],
            [69.525228550000065, 32.569588128000078],
            [69.52576392900005, 32.569409668000048],
            [69.526370692000057, 32.569195517000026],
            [69.526763303000052, 32.568481678000069],
            [69.527298681000048, 32.568124759000057],
            [69.527976828000078, 32.568017683000051],
            [69.528583590000039, 32.567981992000057],
            [69.529618656000082, 32.567981992000057],
            [69.531046332000074, 32.567767840000045],
            [69.531974322000053, 32.567375229000049],
            [69.532830928000067, 32.566875542000048],
            [69.533544766000034, 32.56630447200007],
            [69.534044453000035, 32.565804785000068],
            [69.534793983000043, 32.565055255000061],
            [69.535222286000078, 32.564341416000048],
            [69.535936125000035, 32.563484810000034],
            [69.536400120000053, 32.562949432000039],
            [69.536864114000082, 32.562592512000037],
            [69.53730590300006, 32.562411781000037],
            [69.537649337000062, 32.562271285000065],
            [69.538505942000029, 32.562271285000065],
            [69.539790851000078, 32.562235593000025],
            [69.540861609000046, 32.562449745000038],
            [69.542324977000078, 32.563056507000056],
            [69.543003124000052, 32.563841729000046],
            [69.543502811000053, 32.564448492000054],
            [69.543752654000059, 32.565198022000061],
            [69.544002497000065, 32.565983244000051],
            [69.544359416000077, 32.566768466000042],
            [69.545001871000068, 32.56723246100006],
            [69.546465239000042, 32.568017683000051],
            [69.547928608000063, 32.568445986000029],
            [69.554817147000051, 32.570730269000023],
            [69.559135869000045, 32.572229329000038],
            [69.561384460000056, 32.572800400000062],
            [69.562883520000071, 32.573014551000028],
            [69.563882894000074, 32.573335779000047],
            [69.564810883000064, 32.574156693000077],
            [69.566095792000056, 32.575976980000064],
            [69.566524095000034, 32.576762202000054],
            [69.567237934000048, 32.577404657000045],
            [69.57012897900006, 32.579332020000038],
            [69.572413261000065, 32.580973848000042],
            [69.573626786000034, 32.581687687000056],
            [69.575090155000055, 32.582294449000074],
            [69.576482139000063, 32.582936904000064],
            [69.577552897000032, 32.583436590000076],
            [69.579373185000065, 32.584614424000051],
            [69.580693785000051, 32.585435338000025],
            [69.581229164000035, 32.586077792000026],
            [69.582014386000083, 32.586470403000078],
            [69.58272822400005, 32.586720247000073],
            [69.583477755000047, 32.586827322000033],
            [69.584548512000083, 32.586755939000057],
            [69.585726345000069, 32.586755939000057],
            [69.586797103000038, 32.587077166000029],
            [69.588046320000046, 32.587612544000024],
            [69.589438304000055, 32.587969464000025],
            [69.590223526000045, 32.588076539000042],
            [69.591294284000071, 32.588254999000071],
            [69.593257339000047, 32.588290691000054],
            [69.594934859000034, 32.588433459000044],
            [69.596326844000032, 32.588683302000049],
            [69.597326217000045, 32.588968837000039],
            [69.598325591000048, 32.589290064000068],
            [69.599217889000045, 32.589539908000063],
            [69.600467106000053, 32.589718367000046],
            [69.601180944000077, 32.589789751000069],
            [69.602751388000058, 32.589896827000075],
            [69.604000605000067, 32.589896827000075],
            [69.605463974000031, 32.589825443000052],
            [69.607105802000035, 32.589754059000029],
            [69.608497787000033, 32.589754059000029],
            [69.609497160000046, 32.589825443000052],
            [69.610139615000037, 32.589968211000041],
            [69.610924837000084, 32.590325130000053],
            [69.611638675000052, 32.591110352000044],
            [69.612423897000042, 32.592109726000047],
            [69.613280503000055, 32.593287559000032],
            [69.61399434100008, 32.594251241000052],
            [69.614015101000064, 32.594310553000071],
            [69.614244185000075, 32.594965079000076],
            [69.614244185000075, 32.595678917000043],
            [69.614172801000052, 32.596428447000051],
            [69.613708806000034, 32.597070902000041],
            [69.613102044000073, 32.597641972000076],
            [69.612638049000054, 32.598070275000055],
            [69.61210267000007, 32.598641346000079],
            [69.611531599000045, 32.59924810900003],
            [69.611317448000079, 32.599890563000031],
            [69.61099622100005, 32.600354558000049],
            [69.610532226000032, 32.600782861000027],
            [69.609818388000065, 32.601353932000052],
            [69.609175933000074, 32.601817926000024],
            [69.608140868000078, 32.602281921000042],
            [69.607070110000052, 32.60274591600006],
            [69.605963661000033, 32.603174219000039],
            [69.605178439000042, 32.603566830000034],
            [69.604892903000064, 32.603959441000029],
            [69.604678752000041, 32.604708971000036],
            [69.604678752000041, 32.605672653000056],
            [69.604571676000035, 32.606707719000042],
            [69.604393217000052, 32.607849860000044],
            [69.60403629700005, 32.608813542000064],
            [69.603715070000078, 32.609812915000077],
            [69.603536611000038, 32.610990748000063],
            [69.603572302000032, 32.611990122000066],
            [69.603643686000055, 32.612989496000068],
            [69.603679378000038, 32.613667642000053],
            [69.603964914000073, 32.614559940000049],
            [69.60403629700005, 32.61520239400005],
            [69.60389353000005, 32.615844849000041],
            [69.603500919000055, 32.616594379000048],
            [69.603286767000043, 32.617272525000033],
            [69.603144000000043, 32.618343283000058],
            [69.60310830800006, 32.618950045000076],
            [69.60325107500006, 32.61962819200005],
            [69.603465227000072, 32.620234954000068],
            [69.604071989000033, 32.62069894900003],
            [69.605463974000031, 32.621662631000049],
            [69.606927342000063, 32.622947540000041],
            [69.608069484000055, 32.623625686000025],
            [69.609140241000034, 32.624089681000044],
            [69.610068231000071, 32.624589368000045],
            [69.611067605000073, 32.625374590000035],
            [69.612138362000053, 32.626481039000055],
            [69.612709433000077, 32.627123494000045],
            [69.612923584000043, 32.627730256000063],
            [69.612923584000043, 32.628129991000037],
            [69.612923584000043, 32.628265635000048],
            [69.612780816000054, 32.628765322000049],
            [69.612495281000065, 32.629300701000034],
            [69.612209746000076, 32.629978847000075],
            [69.611531599000045, 32.630978221000078],
            [69.611246064000056, 32.631584983000039],
            [69.610889145000044, 32.632477281000035],
            [69.610425150000083, 32.632941276000054],
            [69.609889771000041, 32.633333887000049],
            [69.609104549000051, 32.63383357400005],
            [69.608390711000084, 32.634333260000062],
            [69.607569797000053, 32.634690180000064],
            [69.607177186000058, 32.634975715000053],
            [69.60671319100004, 32.635511094000037],
            [69.606391964000068, 32.636010780000049],
            [69.605999353000072, 32.636796003000029],
            [69.605249822000076, 32.637545533000036],
            [69.604535984000051, 32.638437831000033],
            [69.603857838000067, 32.639008901000068],
            [69.603215383000077, 32.639722740000025],
            [69.602430161000029, 32.640365194000026],
            [69.601894783000034, 32.641007648000027],
            [69.601537863000033, 32.641507335000028],
            [69.601537863000033, 32.641971330000047],
            [69.601537863000033, 32.642435325000065],
            [69.601680631000079, 32.643256239000038],
            [69.601823399000068, 32.643863002000046],
            [69.602394469000046, 32.644933759000025],
            [69.602751388000058, 32.646111592000068],
            [69.602894156000048, 32.647110966000071],
            [69.603179691000037, 32.647896188000061],
            [69.603358151000066, 32.648467259000029],
            [69.603822146000084, 32.648859870000024],
            [69.604678752000041, 32.649573708000048],
            [69.605820893000043, 32.650180470000066],
            [69.606677499000057, 32.65103707600008],
            [69.607605489000036, 32.651750915000036],
            [69.608676246000073, 32.652678904000027],
            [69.609854079000058, 32.653357051000057],
            [69.610639302000038, 32.653856738000059],
            [69.611460216000069, 32.654320733000077],
            [69.61206697800003, 32.654856111000072],
            [69.612780816000054, 32.655498566000063],
            [69.613137736000056, 32.656390864000059],
            [69.613387579000062, 32.657604389000028],
            [69.613494655000068, 32.658532378000075],
            [69.613637422000068, 32.659246217000032],
            [69.61381588200004, 32.65985297900005],
            [69.614208493000035, 32.660316974000068],
            [69.614743872000076, 32.660388358000034],
            [69.615778937000073, 32.660459742000057],
            [69.617242306000037, 32.660459742000057],
            [69.618669982000029, 32.660459742000057],
            [69.620561654000028, 32.660531126000024],
            [69.622025022000059, 32.66063820100004],
            [69.623238547000028, 32.660673893000023],
            [69.624023769000075, 32.660852353000053],
            [69.624737608000032, 32.661280656000031],
            [69.625879749000035, 32.661994494000055],
            [69.627164658000083, 32.662672641000029],
            [69.628092648000063, 32.662993868000058],
            [69.628913562000037, 32.663100943000074],
            [69.629591708000078, 32.662993868000058],
            [69.630376930000068, 32.662779716000045],
            [69.631162152000059, 32.662351413000067],
            [69.632411369000067, 32.662030186000038],
            [69.632875364000029, 32.661851726000066],
            [69.633981814000038, 32.661816035000072],
            [69.63512395500004, 32.661673267000026],
            [69.636801475000084, 32.661744651000049],
            [69.638407611000048, 32.661744651000049],
            [69.639478368000084, 32.661816035000072],
            [69.640442050000047, 32.662030186000038],
            [69.641048813000054, 32.66224433800005],
            [69.641262964000077, 32.662779716000045],
            [69.641262964000077, 32.663279403000047],
            [69.641155888000071, 32.663921858000037],
            [69.640584818000036, 32.66506399900004],
            [69.640156515000058, 32.66584922100003],
            [69.639799596000046, 32.666348908000032],
            [69.639478368000084, 32.666848594000044],
            [69.639406985000051, 32.667276897000079],
            [69.639299909000044, 32.668169195000075],
            [69.639335601000084, 32.668668882000077],
            [69.639514060000067, 32.66963256400004],
            [69.640192207000041, 32.671774079000045],
            [69.640870353000082, 32.673451599000032],
            [69.641726959000039, 32.676021417000072],
            [69.642190954000057, 32.677449093000064],
            [69.643083252000054, 32.678948153000078],
            [69.643618630000049, 32.680304446000036],
            [69.644011242000033, 32.681268128000056],
            [69.644653696000034, 32.683195491000049],
            [69.645438918000082, 32.685087163000048],
            [69.645760145000054, 32.686086536000062],
            [69.646331216000078, 32.686836067000058],
            [69.648222887000031, 32.688477895000062],
            [69.648972418000028, 32.68904896500004],
            [69.649757640000075, 32.689762804000054],
            [69.650614246000032, 32.690333874000032],
            [69.651756387000034, 32.690690793000044],
            [69.652612993000048, 32.69079786900005],
            [69.653648058000044, 32.69079786900005],
            [69.654968659000076, 32.690762177000067],
            [69.656324952000034, 32.690690793000044],
            [69.656931715000042, 32.690512334000061],
            [69.657645553000066, 32.690583718000028],
            [69.658145240000067, 32.690904945000057],
            [69.659394457000076, 32.691654475000064],
            [69.660143987000083, 32.692296929000065],
            [69.661464588000058, 32.693331995000051],
            [69.662463961000071, 32.693795990000069],
            [69.663213491000079, 32.694188601000064],
            [69.664248557000064, 32.694795363000026],
            [69.665497774000073, 32.695580586000062],
            [69.666532840000059, 32.696472883000069],
            [69.667425137000066, 32.697329489000026],
            [69.668602971000041, 32.69804332800004],
            [69.670066339000073, 32.699256853000065],
            [69.671636783000054, 32.700791605000063],
            [69.672350622000067, 32.701291292000064],
            [69.672921692000045, 32.701362676000031],
            [69.67349276300007, 32.701362676000031],
            [69.674741980000078, 32.701219908000041],
            [69.675884121000081, 32.701041449000058],
            [69.67788286800004, 32.700506070000074],
            [69.679274853000038, 32.700398994000068],
            [69.680880989000059, 32.700398994000068],
            [69.682522817000063, 32.700506070000074],
            [69.683593575000032, 32.70075591300008],
            [69.685092635000046, 32.701148524000075],
            [69.686270468000032, 32.701219908000041],
            [69.687448301000074, 32.701219908000041],
            [69.688840286000072, 32.701219908000041],
            [69.690696266000032, 32.701469751000047],
            [69.691909791000057, 32.701862363000032],
            [69.693159008000066, 32.702183590000061],
            [69.694265457000029, 32.702326357000061],
            [69.695300523000071, 32.702433433000067],
            [69.69665681500004, 32.702540509000073],
            [69.698477103000073, 32.702540509000073],
            [69.700582926000038, 32.702611893000039],
            [69.702760133000083, 32.702861736000045],
            [69.704223501000058, 32.703182963000074],
            [69.70550841000005, 32.70343280700007],
            [69.706543476000036, 32.703611266000053],
            [69.707649925000055, 32.703968185000065],
            [69.708542223000052, 32.704432180000026],
            [69.710041283000066, 32.705824165000024],
            [69.710969273000046, 32.706395236000048],
            [69.712040030000082, 32.707537377000051],
            [69.713146480000034, 32.709250589000078],
            [69.713896010000042, 32.710357038000041],
            [69.714574156000083, 32.711534871000026],
            [69.715466454000079, 32.71242716900008],
            [69.716180292000047, 32.71303393200003],
            [69.71717966600005, 32.713605002000065],
            [69.71796488800004, 32.713783462000038],
            [69.718607343000031, 32.713783462000038],
            [69.719535332000078, 32.713640694000048],
            [69.721034393000082, 32.713141008000036],
            [69.72239068600004, 32.712784088000035],
            [69.723711286000082, 32.712462861000063],
            [69.72492481200004, 32.71224871000004],
            [69.725781417000064, 32.71224871000004],
            [69.727102018000039, 32.712748396000052],
            [69.728672463000066, 32.713640694000048],
            [69.73031429100007, 32.714675760000034],
            [69.733026876000054, 32.715960669000026],
            [69.734169017000056, 32.716710199000033],
            [69.735061315000053, 32.717209886000035],
            [69.735310913000035, 32.717225486000075],
            [69.735229171000071, 32.717188021000027],
            [69.735229171000071, 32.716973869000071],
            [69.735038815000053, 32.716593156000044],
            [69.734963721000042, 32.716142595000065],
            [69.735072569000067, 32.716179441000065],
            [69.734924421000073, 32.713338673000067],
            [69.735562947000062, 32.711689224000054],
            [69.736137886000051, 32.710481990000062],
            [69.73716373600007, 32.709394976000056],
            [69.738120662000028, 32.708750803000044],
            [69.739828707000072, 32.708285344000046],
            [69.741087036000067, 32.707762850000051],
            [69.743150280000066, 32.706472389000055],
            [69.74500286600005, 32.705752004000033],
            [69.745820212000069, 32.705488419000062],
            [69.746924079000053, 32.704715719000035],
            [69.748618558000032, 32.703556697000067],
            [69.748978825000052, 32.702857498000071],
            [69.749113626000053, 32.702098223000064],
            [69.74894699400005, 32.701216703000057],
            [69.748333129000059, 32.700404596000055],
            [69.748314768000057, 32.699457808000034],
            [69.749154574000045, 32.698153155000057],
            [69.750417807000076, 32.697756355000024],
            [69.752351238000074, 32.697223808000047],
            [69.754881352000041, 32.696620291000045],
            [69.75628910100005, 32.695968581000045],
            [69.757554742000082, 32.695698105000076],
            [69.758749334000072, 32.695618234000051],
            [69.759726905000036, 32.696047082000064],
            [69.760933842000043, 32.696599135000042],
            [69.761988832000043, 32.697026320000077],
            [69.762829504000081, 32.697962745000041],
            [69.764862359000062, 32.698692850000043],
            [69.766896474000077, 32.699486117000049],
            [69.768031237000059, 32.700165539000068],
            [69.769532294000044, 32.700460548000024],
            [69.772147053000083, 32.700360621000073],
            [69.773193451000054, 32.700345913000035],
            [69.774978561000069, 32.699878325000043],
            [69.776012325000067, 32.699358073000042],
            [69.776742315000035, 32.698462827000071],
            [69.777482246000034, 32.698073127000043],
            [69.778978312000049, 32.698115244000064],
            [69.781685207000066, 32.698898782000072],
            [69.783716985000069, 32.699565398000061],
            [69.78500136200006, 32.700242552000077],
            [69.78612750700006, 32.700479458000075],
            [69.788450797000053, 32.700762585000064],
            [69.791300908000039, 32.701354260000073],
            [69.792946522000079, 32.701394088000029],
            [69.79555750600008, 32.701104092000037],
            [69.798462394000069, 32.700557010000068],
            [69.800241625000069, 32.700067689000036],
            [69.802255623000065, 32.699035434000052],
            [69.804773686000033, 32.697709794000048],
            [69.807628187000034, 32.69652205400007],
            [69.809718812000028, 32.695058513000049],
            [69.810966351000047, 32.693894887000056],
            [69.811796633000029, 32.693094504000044],
            [69.813148245000036, 32.692860037000059],
            [69.814155090000042, 32.692343813000036],
            [69.815316888000041, 32.691036890000078],
            [69.815121554000029, 32.689821737000045],
            [69.815178117000073, 32.688387353000053],
            [69.816002550000064, 32.687300874000073],
            [69.817339633000074, 32.686350358000027],
            [69.818339151000032, 32.685475815000075],
            [69.819331442000077, 32.684242970000071],
            [69.819996309000032, 32.683588272000065],
            [69.820145436000075, 32.682511508000061],
            [69.820041237000055, 32.681581195000035],
            [69.820452553000052, 32.680930153000077],
            [69.821030562000033, 32.680205020000074],
            [69.820926368000073, 32.679275834000066],
            [69.822089564000066, 32.678183854000054],
            [69.824184115000037, 32.677078390000077],
            [69.826027743000054, 32.675976525000067],
            [69.827789095000071, 32.675163100000077],
            [69.829136026000072, 32.674713497000027],
            [69.830727738000064, 32.673830258000066],
            [69.831526889000031, 32.671382705000042],
            [69.833434108000063, 32.669347981000044],
            [69.83551518400003, 32.667597975000035],
            [69.836673434000033, 32.666147525000042],
            [69.838092434000032, 32.665051670000025],
            [69.840335586000037, 32.66301306500003],
            [69.840467131000082, 32.661219169000049],
            [69.840526198000077, 32.659928654000055],
            [69.84083288100004, 32.658347233000029],
            [69.841406181000082, 32.657407033000027],
            [69.843349492000073, 32.657163597000078],
            [69.845303054000055, 32.657421731000056],
            [69.847517162000031, 32.657891050000046],
            [69.849980623000079, 32.658284997000067],
            [69.850824673000034, 32.658057564000046],
            [69.851571565000029, 32.657258126000045],
            [69.85223015300005, 32.656317751000074],
            [69.853232228000081, 32.655729596000072],
            [69.854167519000043, 32.655787517000078],
            [69.855358015000036, 32.656056713000055],
            [69.856295341000077, 32.656472994000069],
            [69.85757564000005, 32.656954757000051],
            [69.858687886000041, 32.657440113000064],
            [69.861665427000048, 32.658041284000035],
            [69.863869269000077, 32.65800871700003],
            [69.869382447000078, 32.658357155000033],
            [69.871594037000079, 32.658826090000048],
            [69.872628754000061, 32.659814238000024],
            [69.873337708000065, 32.661308407000035],
            [69.874223462000032, 32.662319062000051],
            [69.874420361000034, 32.664344607000032],
            [69.87406337300007, 32.665789700000062],
            [69.873858430000041, 32.667102166000063],
            [69.873058853000032, 32.669600808000041],
            [69.872556544000076, 32.671505789000037],
            [69.872658381000065, 32.672682181000027],
            [69.87313630400007, 32.673329790000025],
            [69.874787279000032, 32.674548634000075],
            [69.876275429000032, 32.675508554000032],
            [69.877615565000042, 32.676666508000039],
            [69.878632549000031, 32.677175127000055],
            [69.879800232000036, 32.677485073000071],
            [69.880124791000071, 32.678199284000073],
            [69.879456897000068, 32.67958356500003],
            [69.879410879000034, 32.68109006800006],
            [69.88091548400007, 32.682703833000062],
            [69.883055700000057, 32.685092610000027],
            [69.884599617000049, 32.685004074000062],
            [69.886142103000054, 32.684719695000069],
            [69.887463332000038, 32.684961240000064],
            [69.889457936000042, 32.684212322000064],
            [69.891227077000053, 32.683662034000065],
            [69.893455161000077, 32.68297388600007],
            [69.895003068000051, 32.682950632000029],
            [69.89717978300007, 32.683376190000047],
            [69.898371012000041, 32.68479749200003],
            [69.899631437000039, 32.685826040000052],
            [69.90316006200004, 32.684397950000061],
            [69.904540879000081, 32.683919929000069],
            [69.907086657000036, 32.683488629000067],
            [69.908409311000071, 32.683795968000027],
            [69.909737610000036, 32.684494910000069],
            [69.911510790000079, 32.684140691000039],
            [69.913286756000048, 32.683917899000051],
            [69.914350861000059, 32.682985720000033],
            [69.915575298000078, 32.682312985000067],
            [69.916497076000041, 32.681971617000045],
            [69.918268916000045, 32.681682792000061],
            [69.919037289000073, 32.681409218000056],
            [69.920337651000068, 32.680669237000075],
            [69.922400900000071, 32.679525345000059],
            [69.922613545000047, 32.678606072000036],
            [69.922978178000051, 32.677552976000072],
            [69.924125082000046, 32.676881328000036],
            [69.925502854000058, 32.676271060000033],
            [69.926352742000063, 32.676192607000075],
            [69.927676754000061, 32.676565194000034],
            [69.929529191000029, 32.676704437000069],
            [69.929847877000043, 32.67672839200003],
            [69.93350917500004, 32.677915692000056],
            [69.934140940000077, 32.678495241000064],
            [69.935327080000036, 32.679654959000061],
            [69.93665117900008, 32.680027451000058],
            [69.939125080000053, 32.679989428000056],
            [69.940516733000038, 32.67990254700004],
            [69.942742932000044, 32.679148078000026],
            [69.943822183000066, 32.678935037000031],
            [69.94462855200004, 32.680427877000056],
            [69.944350032000045, 32.681872544000043],
            [69.944708972000058, 32.684156805000043],
            [69.94521286500003, 32.685981663000064],
            [69.946103781000033, 32.687800545000073],
            [69.946903201000055, 32.68896612900005],
            [69.948012242000061, 32.690126920000068],
            [69.949651810000034, 32.690755727000067],
            [69.951747491000049, 32.691116131000058],
            [69.955938715000059, 32.691705906000038],
            [69.95742355200008, 32.692337017000057],
            [69.958838316000083, 32.693428058000052],
            [69.960092413000041, 32.69412819300004],
            [69.960808984000039, 32.695033651000074],
            [69.96168890000007, 32.696328832000063],
            [69.962961590000077, 32.697879213000078],
            [69.963757036000061, 32.698848897000062],
            [69.964321704000042, 32.699887077000028],
            [69.965726483000083, 32.700388962000034],
            [69.966818955000065, 32.700895130000049],
            [69.968453151000062, 32.70126246600006],
            [69.969466827000076, 32.701704934000077],
            [69.971098181000059, 32.701941330000068],
            [69.972723803000065, 32.701915912000061],
            [69.974504256000046, 32.701888049000047],
            [69.976046930000052, 32.70173294600005],
            [69.977519174000065, 32.70177534000004],
            [69.978997157000038, 32.702079519000051],
            [69.979627933000074, 32.702592821000053],
            [69.978938411000058, 32.702931005000039],
            [69.978412322000054, 32.70365888200007],
            [69.979341271000067, 32.703644306000058],
            [69.980514453000069, 32.703532336000023],
            [69.980700272000036, 32.703514601000052],
            [69.983220687000028, 32.702527345000078],
            [69.985287132000053, 32.701420201000076],
            [69.987138948000052, 32.700632471000063],
            [69.987565092000068, 32.699614333000056],
            [69.987465287000077, 32.698478055000066],
            [69.988723286000038, 32.69788929200007],
            [69.990064382000071, 32.697678501000041],
            [69.992303727000035, 32.697516714000074],
            [69.993491146000054, 32.697118662000037],
            [69.99436982900005, 32.696282988000064],
            [69.99570250000005, 32.695692973000064],
            [69.997264951000034, 32.69535217300006],
            [69.998529870000084, 32.695079838000026],
            [70.000539329000048, 32.694668688000036],
            [70.001765053000042, 32.692752809000069],
            [70.003388134000033, 32.691778825000029],
            [70.005028042000049, 32.691563117000044],
            [70.005762712000035, 32.690982509000037],
            [70.005896712000037, 32.690285019000044],
            [70.007088196000041, 32.690076420000025],
            [70.008128803000034, 32.689806993000047],
            [70.009613568000077, 32.689340835000053],
            [70.010194501000058, 32.68857300600007],
            [70.010321420000082, 32.687559553000028],
            [70.010596396000039, 32.68648108900004],
            [70.011477626000044, 32.685771679000027],
            [70.013266863000069, 32.685553478000031],
            [70.015047606000053, 32.684956108000051],
            [70.016085305000047, 32.684560230000045],
            [70.016671817000031, 32.684045139000034],
            [70.017492387000061, 32.683968798000024],
            [70.018244049000032, 32.684272834000069],
            [70.019071706000034, 32.684512444000063],
            [70.019646866000073, 32.683491801000059],
            [70.019250593000038, 32.682486716000028],
            [70.019904720000056, 32.681654451000043],
            [70.021242674000064, 32.681316934000051],
            [70.022893680000038, 32.681606532000046],
            [70.024462888000073, 32.681581347000076],
            [70.02653953600003, 32.680852629000071],
            [70.028396255000075, 32.680317046000027],
            [70.029570500000034, 32.679350499000066],
            [70.031353860000081, 32.678879281000036],
            [70.033605472000033, 32.679284918000064],
            [70.035174823000034, 32.679386027000078],
            [70.037030035000043, 32.678787702000079],
            [70.039399686000081, 32.677801164000073],
            [70.042209022000065, 32.676428186000066],
            [70.043597190000071, 32.675014962000034],
            [70.044521247000034, 32.672913888000039],
            [70.045235459000082, 32.671448929000064],
            [70.046633541000062, 32.670478016000061],
            [70.048772983000049, 32.669242179000037],
            [70.050921197000036, 32.668511887000079],
            [70.051974365000035, 32.668810818000054],
            [70.053263191000042, 32.669611627000052],
            [70.054261828000051, 32.670796447000043],
            [70.055339504000074, 32.672169053000061],
            [70.055743326000083, 32.673489978000077],
            [70.056063808000033, 32.674432986000056],
            [70.056690379000031, 32.67568706000003],
            [70.058207822000043, 32.676673737000044],
            [70.060331740000038, 32.678029781000077],
            [70.062106652000068, 32.680465581000078],
            [70.062895809000054, 32.68228591500008],
            [70.063676309000073, 32.683727082000075],
            [70.064075976000083, 32.68485841100005],
            [70.06433196200004, 32.686244389000024],
            [70.064583637000055, 32.687441346000071],
            [70.065282253000078, 32.688567775000024],
            [70.066946800000039, 32.689551940000058],
            [70.070019448000039, 32.68988083000005],
            [70.071292762000041, 32.689986352000062],
            [70.073692853000068, 32.690326189000075],
            [70.076317146000065, 32.690662298000063],
            [70.077675408000061, 32.691208867000057],
            [70.079493728000045, 32.692252977000066],
            [70.082142919000034, 32.693663213000036],
            [70.084641505000036, 32.695139099000073],
            [70.086477547000072, 32.696941970000069],
            [70.088306328000044, 32.698428861000025],
            [70.09014103700008, 32.700167917000044],
            [70.091204965000031, 32.700908867000066],
            [70.092872702000079, 32.701892642000075],
            [70.094446638000079, 32.702056151000079],
            [70.096115901000076, 32.703103075000058],
            [70.097546272000045, 32.703521803000058],
            [70.099423897000065, 32.703996277000044],
            [70.101441922000049, 32.703962667000042],
            [70.102862013000049, 32.703938996000034],
            [70.104500429000041, 32.703658813000061],
            [70.10621061300003, 32.703250969000067],
            [70.107923747000029, 32.702969483000061],
            [70.109055204000072, 32.703393077000044],
            [70.109744136000074, 32.704076919000045],
            [70.110138555000049, 32.704955333000044],
            [70.109636055000067, 32.705848187000072],
            [70.109291909000035, 32.707118250000065],
            [70.109166075000076, 32.708131798000068],
            [70.109774376000075, 32.708564134000028],
            [70.111572713000044, 32.708723686000042],
            [70.111675585000057, 32.709923045000039],
            [70.11140619400004, 32.711191861000032],
            [70.111284825000041, 32.712394982000035],
            [70.11198124200007, 32.713394201000028],
            [70.112311831000056, 32.713897707000058],
            [70.112679159000038, 32.714457171000049],
            [70.114433991000055, 32.715944924000041],
            [70.11527435000005, 32.716815845000042],
            [70.116195104000042, 32.71781184300005],
            [70.117712433000065, 32.718734613000038],
            [70.11921047800007, 32.718835876000071],
            [70.120627815000034, 32.718685633000064],
            [70.121740202000069, 32.718287632000056],
            [70.123096168000075, 32.718707322000057],
            [70.123704623000037, 32.71913958500005],
            [70.125881424000056, 32.71948221100007],
            [70.127328586000033, 32.720595115000037],
            [70.129533803000072, 32.722138291000078],
            [70.129925507000053, 32.722890258000064],
            [70.129420120000077, 32.723657371000058],
            [70.129061267000054, 32.724295586000039],
            [70.129971801000067, 32.724849147000043],
            [70.131690065000043, 32.724883338000041],
            [70.13356202500006, 32.724978123000028],
            [70.134837411000035, 32.725146192000068],
            [70.137012900000059, 32.725425436000023],
            [70.139188403000048, 32.725704633000078],
            [70.141507434000061, 32.725728501000049],
            [70.142489814000044, 32.726154331000032],
            [70.143267456000046, 32.727404857000067],
            [70.14411537400008, 32.728465109000069],
            [70.145286430000056, 32.730531305000056],
            [70.146276406000084, 32.731273057000067],
            [70.14830138700006, 32.73161787600003],
            [70.149497608000047, 32.731597500000078],
            [70.151221705000069, 32.73175776000005],
            [70.153022071000066, 32.731979910000064],
            [70.154595141000073, 32.732079486000032],
            [70.155428122000046, 32.732507759000043],
            [70.155969606000042, 32.733256525000058],
            [70.156721789000073, 32.73343331600006],
            [70.156853186000035, 32.732673051000063],
            [70.15735839000007, 32.731905830000073],
            [70.158109057000047, 32.732019423000054],
            [70.158646027000032, 32.732579171000054],
            [70.159553779000078, 32.733005583000079],
            [70.160368633000076, 32.73267611600005],
            [70.161557287000051, 32.732339675000048],
            [70.162390315000039, 32.732767911000053],
            [70.164416896000034, 32.733175098000061],
            [70.165231715000061, 32.732845049000048],
            [70.165974814000037, 32.732643204000055],
            [70.166725490000033, 32.73275618200006],
            [70.167558530000065, 32.733184375000064],
            [70.168917991000058, 32.733729955000058],
            [70.170576529000073, 32.734270366000032],
            [70.172209182000074, 32.733736546000046],
            [70.173319992000074, 32.733274902000062],
            [70.174130217000084, 32.732755221000048],
            [70.175459200000034, 32.732037497000078],
            [70.176333484000054, 32.731074183000032],
            [70.177059738000082, 32.730176634000031],
            [70.178525962000037, 32.728950222000037],
            [70.179691963000039, 32.727792196000053],
            [70.180726372000038, 32.727268597000034],
            [70.181702836000056, 32.727441362000036],
            [70.182384851000052, 32.727808854000045],
            [70.184121185000038, 32.728474184000049],
            [70.185387520000063, 32.728262603000076],
            [70.186052696000047, 32.727935001000048],
            [70.186798772000031, 32.727858849000029],
            [70.188071229000059, 32.727900005000038],
            [70.188959140000065, 32.727505307000058],
            [70.189384677000078, 32.726549685000066],
            [70.190177840000047, 32.725334808000071],
            [70.191283851000037, 32.724683440000035],
            [70.192385243000047, 32.723843061000025],
            [70.19414402700005, 32.722358518000078],
            [70.196869360000051, 32.720290501000079],
            [70.197134973000061, 32.720088950000047],
            [70.19729884000003, 32.719964606000076],
            [70.199529105000067, 32.719420007000053],
            [70.201703112000075, 32.719634940000049],
            [70.202987816000075, 32.720181460000049],
            [70.204207053000061, 32.721108402000027],
            [70.206254805000071, 32.722273701000063],
            [70.208671748000029, 32.723242864000042],
            [70.211299048000058, 32.723638787000027],
            [70.212581199000056, 32.724185246000047],
            [70.214336305000074, 32.725608426000065],
            [70.216529157000082, 32.726581373000045],
            [70.217673227000034, 32.727260048000062],
            [70.218806141000073, 32.727932104000047],
            [70.221305919000031, 32.729215646000057],
            [70.224545584000055, 32.73017002000006],
            [70.227176279000048, 32.730691991000072],
            [70.228974142000084, 32.730913095000062],
            [70.231142239000064, 32.730874769000025],
            [70.233307214000035, 32.730710036000062],
            [70.234492436000039, 32.730246529000055],
            [70.235289745000046, 32.729221506000044],
            [70.23610270100005, 32.728827796000076],
            [70.237733309000077, 32.72822993300008],
            [70.23927812800008, 32.727191062000031],
            [70.240891408000039, 32.725898081000025],
            [70.242070240000032, 32.725181755000051],
            [70.242264218000059, 32.725114614000063],
            [70.243995519000066, 32.724515363000023],
            [70.245557538000071, 32.724171476000038],
            [70.246877852000068, 32.723447660000033],
            [70.247106940000037, 32.723322070000052],
            [70.247974072000034, 32.722106065000048],
            [70.249067005000029, 32.720948682000028],
            [70.249949338000079, 32.719961854000076],
            [70.250590565000039, 32.719950415000028],
            [70.251184801000079, 32.719975655000042],
            [70.251820543000065, 32.719964307000055],
            [70.252085609000062, 32.719989377000047],
            [70.25221261300004, 32.719954739000059],
            [70.252450560000057, 32.719954739000059],
            [70.252926452000054, 32.719954739000059],
            [70.253307165000081, 32.719978534000063],
            [70.253640290000078, 32.720121302000052],
            [70.254092388000061, 32.720240275000037],
            [70.254806226000028, 32.720573399000045],
            [70.255377297000052, 32.720763756000053],
            [70.256091135000077, 32.721287238000059],
            [70.256519438000055, 32.721810719000075],
            [70.257019125000056, 32.722429379000062],
            [70.257233276000079, 32.723119422000025],
            [70.25732845400006, 32.723333574000037],
            [70.257471222000049, 32.723976028000038],
            [70.257518811000068, 32.724428126000078],
            [70.257542606000072, 32.724832634000052],
            [70.257685374000062, 32.725237143000072],
            [70.257804347000047, 32.725570267000023],
            [70.258208855000078, 32.725927186000035],
            [70.258589569000037, 32.726355489000071],
            [70.259065461000034, 32.726807587000053],
            [70.25935099600008, 32.727212095000027],
            [70.259755505000044, 32.727735577000033],
            [70.259850683000082, 32.728140085000064],
            [70.259898272000044, 32.728544593000038],
            [70.259874478000029, 32.728925307000054],
            [70.259826888000077, 32.729282226000066],
            [70.259826888000077, 32.729639145000078],
            [70.259826888000077, 32.729900886000053],
            [70.259993451000071, 32.730043654000042],
            [70.260087555000041, 32.730067180000049],
            [70.260545135000029, 32.73005898100007],
            [70.260850653000034, 32.730053508000026],
            [70.261390777000031, 32.730043825000052],
            [70.261930315000029, 32.730034153000076],
            [70.262207032000049, 32.730029192000075],
            [70.263690570000051, 32.730002580000075],
            [70.265597976000038, 32.729968338000049],
            [70.267293446000053, 32.729937877000054],
            [70.269285621000051, 32.729902056000071],
            [70.27068438200007, 32.729876887000046],
            [70.271832435000078, 32.729999584000041],
            [70.272145284000032, 32.730045174000054],
            [70.273154857000065, 32.73000251600007],
            [70.27575719500004, 32.730219378000072],
            [70.278514434000044, 32.730715061000069],
            [70.278721662000066, 32.730746942000053],
            [70.280930890000036, 32.731086824000045],
            [70.283099505000052, 32.731551527000079],
            [70.285175180000067, 32.73217113100003],
            [70.287622616000078, 32.732976617000077],
            [70.288923785000065, 32.733472300000074],
            [70.290658677000067, 32.734463667000057],
            [70.292052787000046, 32.73548601400006],
            [70.293694738000056, 32.736942084000077],
            [70.295088847000045, 32.738522075000049],
            [70.296390016000032, 32.739606382000034],
            [70.298062948000052, 32.741465195000046],
            [70.299240196000028, 32.742797344000053],
            [70.300572345000035, 32.744408315000044],
            [70.301563712000075, 32.745802425000079],
            [70.302586059000078, 32.746855752000045],
            [70.30348448500007, 32.747847119000028],
            [70.304320951000079, 32.748714565000057],
            [70.304568792000055, 32.749272208000036],
            [70.304909575000067, 32.750077694000026],
            [70.305467219000036, 32.751409843000033],
            [70.30593192200007, 32.752587091000066],
            [70.306178541000065, 32.753259688000071],
            [70.306898810000064, 32.754380864000041],
            [70.308317041000066, 32.75675633000003],
            [70.309493957000029, 32.757989259000055],
            [70.310498548000055, 32.759117807000052],
            [70.311362175000056, 32.759711302000028],
            [70.312052534000031, 32.760164599000063],
            [70.312498610000034, 32.761016629000039],
            [70.312902293000036, 32.761869434000062],
            [70.313682080000035, 32.762500293000073],
            [70.314755929000057, 32.763018227000032],
            [70.315776337000045, 32.76310703200005],
            [70.316793983000082, 32.763088353000057],
            [70.31797847100006, 32.762959077000062],
            [70.319671954000057, 32.762497858000074],
            [70.321365248000063, 32.762359195000045],
            [70.322720249000042, 32.762262579000037],
            [70.324494639000079, 32.761979015000065],
            [70.326029411000036, 32.762273312000048],
            [70.327480316000049, 32.762604980000049],
            [70.329146945000048, 32.763076018000049],
            [70.331030236000061, 32.763722241000039],
            [70.332109765000041, 32.764454977000071],
            [70.332978224000044, 32.765227447000029],
            [70.334376889000055, 32.766814470000043],
            [70.335638172000074, 32.768009759000051],
            [70.336980580000045, 32.769060165000042],
            [70.337705176000043, 32.769190113000036],
            [70.338726607000069, 32.76931455700003],
            [70.339786723000032, 32.769294908000063],
            [70.341482908000046, 32.769263450000039],
            [70.344002763000049, 32.768929938000042],
            [70.345438916000035, 32.768688205000046],
            [70.348356405000061, 32.768311373000074],
            [70.349925356000028, 32.768282169000031],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "55",
      properties: { name: "Khyber Agency", count: 671 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.178329191000046, 34.339714260000051],
            [71.179614100000038, 34.339685706000068],
            [71.180527813000083, 34.339685706000068],
            [71.182012597000039, 34.339400171000079],
            [71.183440273000031, 34.338971868000044],
            [71.184668075000047, 34.338372244000027],
            [71.185553235000043, 34.337744066000027],
            [71.186209966000035, 34.337230103000024],
            [71.186895251000067, 34.336630479000064],
            [71.187951731000055, 34.335459784000079],
            [71.188751230000037, 34.33480305300003],
            [71.189550729000075, 34.334060661000024],
            [71.190264567000042, 34.33357525100007],
            [71.191463816000066, 34.332775752000032],
            [71.192548850000037, 34.332204681000064],
            [71.193748098000071, 34.331547950000072],
            [71.19466181100006, 34.331148201000076],
            [71.195518417000073, 34.330834112000048],
            [71.196317916000055, 34.330634237000027],
            [71.197374397000033, 34.330434362000062],
            [71.199059055000077, 34.330291595000062],
            [71.200629499000058, 34.330348702000038],
            [71.202456925000035, 34.330462916000045],
            [71.205569260000061, 34.330491469000037],
            [71.207882096000048, 34.330605683000044],
            [71.210390339000071, 34.330829190000031],
            [71.21076600300006, 34.330862665000041],
            [71.212507769000069, 34.331062540000062],
            [71.214820605000057, 34.331148201000076],
            [71.21693356600008, 34.331205308000051],
            [71.218418350000036, 34.331119647000037],
            [71.219474830000081, 34.33100543300003],
            [71.220131561000073, 34.330777005000073],
            [71.220674079000048, 34.330634237000027],
            [71.221530685000062, 34.330034613000066],
            [71.22195898700005, 34.329749078000077],
            [71.222701379000057, 34.329320775000042],
            [71.223358111000039, 34.328778257000067],
            [71.22418616300007, 34.328207187000032],
            [71.224871448000044, 34.327835991000029],
            [71.22555673200003, 34.327379134000068],
            [71.226156357000036, 34.327093599000079],
            [71.226984409000067, 34.326865171000065],
            [71.227783908000049, 34.326808064000033],
            [71.22872617400003, 34.326750957000058],
            [71.229440013000044, 34.326779510000051],
            [71.230153851000068, 34.32692227800004],
            [71.231238885000039, 34.32720781300003],
            [71.232181152000067, 34.327493349000065],
            [71.233665935000033, 34.328064419000043],
            [71.23440832700004, 34.328150080000057],
            [71.235636129000056, 34.328435615000046],
            [71.237206573000037, 34.328863918000025],
            [71.238520036000068, 34.329206560000046],
            [71.239576516000056, 34.329349328000035],
            [71.240404569000077, 34.32929222100006],
            [71.241061300000069, 34.329006686000071],
            [71.24166092400003, 34.328492722000078],
            [71.242231995000054, 34.327835991000029],
            [71.242888726000047, 34.326979385000072],
            [71.243431243000032, 34.326236993000066],
            [71.244202189000077, 34.325323280000077],
            [71.24500168700007, 34.324152585000036],
            [71.245715526000083, 34.32312465800004],
            [71.246315150000044, 34.322296606000066],
            [71.246943328000043, 34.321468553000045],
            [71.247485845000028, 34.320640501000071],
            [71.248142576000077, 34.319498360000068],
            [71.248570879000056, 34.318670307000048],
            [71.249113396000041, 34.318042130000038],
            [71.249713020000058, 34.317528166000045],
            [71.250141323000037, 34.317014202000053],
            [71.250683840000079, 34.316643007000039],
            [71.251740321000057, 34.316271811000036],
            [71.252825355000084, 34.315957722000064],
            [71.254053157000044, 34.315814954000075],
            [71.25556649400005, 34.315643633000036],
            [71.257051278000063, 34.315557972000079],
            [71.258621722000044, 34.315529419000029],
            [71.260192166000081, 34.315272437000033],
            [71.261734057000069, 34.315186776000075],
            [71.262847645000079, 34.315101116000051],
            [71.264046893000057, 34.314787027000079],
            [71.264732178000031, 34.314644259000033],
            [71.265474570000038, 34.313987528000041],
            [71.265588784000045, 34.313445011000056],
            [71.26550312300003, 34.312816833000056],
            [71.265303248000066, 34.312302870000053],
            [71.264817838000056, 34.311474817000033],
            [71.264389535000078, 34.310646765000058],
            [71.264332428000046, 34.309761605000062],
            [71.264389535000078, 34.309247642000059],
            [71.264532303000067, 34.308933553000031],
            [71.265046267000059, 34.308648018000042],
            [71.265560230000062, 34.308419589000039],
            [71.26613130100003, 34.308276822000039],
            [71.266873693000036, 34.308162608000032],
            [71.268015834000039, 34.308048394000025],
            [71.268986654000059, 34.308019840000043],
            [71.270271563000051, 34.307819965000078],
            [71.271013955000058, 34.307648644000039],
            [71.271842007000032, 34.307306002000075],
            [71.272441631000049, 34.306820592000065],
            [71.272984148000035, 34.306449396000062],
            [71.273555219000059, 34.305992539000044],
            [71.274126290000083, 34.305535683000073],
            [71.274840128000051, 34.304878952000024],
            [71.275553966000075, 34.304108006000035],
            [71.276067930000067, 34.303337061000036],
            [71.276496233000046, 34.302623223000069],
            [71.277238625000052, 34.301423974000045],
            [71.277552713000034, 34.300710136000077],
            [71.278266552000048, 34.29962510200005],
            [71.278894729000058, 34.298797049000029],
            [71.279465800000082, 34.297540694000077],
            [71.279951210000036, 34.296855409000045],
            [71.280379513000071, 34.296113017000039],
            [71.280979137000031, 34.295656161000068],
            [71.281607315000031, 34.295027983000068],
            [71.282349707000037, 34.294771001000072],
            [71.283149206000076, 34.294485466000026],
            [71.284177133000071, 34.294199931000037],
            [71.285233613000059, 34.293857288000027],
            [71.286318648000076, 34.293600307000077],
            [71.287203807000083, 34.293314771000041],
            [71.287946199000032, 34.29300068200007],
            [71.288859912000078, 34.292458165000028],
            [71.28951664300007, 34.292058416000032],
            [71.290230481000037, 34.291487345000064],
            [71.290715891000048, 34.291087596000068],
            [71.291258409000079, 34.290602186000058],
            [71.29185803300004, 34.29028809700003],
            [71.292371996000043, 34.289888347000044],
            [71.293285709000031, 34.289231616000052],
            [71.294170869000084, 34.288546332000067],
            [71.294884707000051, 34.287746833000028],
            [71.295427224000036, 34.287118655000029],
            [71.296169616000043, 34.286376263000079],
            [71.296854901000074, 34.285776639000062],
            [71.29788282800007, 34.285205568000038],
            [71.299310504000061, 34.284377516000063],
            [71.299995789000036, 34.283834999000078],
            [71.300481199000046, 34.283006946000057],
            [71.300766735000082, 34.282464429000072],
            [71.300909502000081, 34.281722037000065],
            [71.301080823000063, 34.281036753000024],
            [71.30133780500006, 34.280180147000067],
            [71.301423466000074, 34.279352094000046],
            [71.301452019000067, 34.278809577000061],
            [71.301594787000056, 34.278324167000051],
            [71.302080197000066, 34.277695990000041],
            [71.302679821000083, 34.277096365000034],
            [71.303365106000058, 34.276525295000056],
            [71.304592908000075, 34.275725796000074],
            [71.305363853000074, 34.275183279000032],
            [71.306191906000038, 34.27466931500004],
            [71.307219833000033, 34.274155352000037],
            [71.308533295000075, 34.27344151300008],
            [71.30956122200007, 34.272699122000063],
            [71.310874685000044, 34.271956730000056],
            [71.311645630000044, 34.271471320000046],
            [71.312131040000054, 34.271157231000075],
            [71.312730664000071, 34.270643267000025],
            [71.313216074000081, 34.270214964000047],
            [71.313672931000042, 34.269672447000062],
            [71.313929913000038, 34.269015716000069],
            [71.314186894000045, 34.26838753800007],
            [71.314443876000041, 34.267502379000064],
            [71.314757965000069, 34.266331684000079],
            [71.314986393000083, 34.265417971000034],
            [71.31521482100004, 34.264618472000052],
            [71.315500357000076, 34.263847527000053],
            [71.315643124000076, 34.262905260000025],
            [71.315614571000083, 34.262191422000058],
            [71.315586017000044, 34.261449030000051],
            [71.315186268000048, 34.260221228000034],
            [71.31461519700008, 34.259221855000078],
            [71.314101234000077, 34.258450909000032],
            [71.313101860000074, 34.257365875000062],
            [71.312273808000043, 34.256537823000031],
            [71.311531416000037, 34.255823984000074],
            [71.311188774000072, 34.255224360000057],
            [71.311074559000076, 34.254596183000046],
            [71.311131666000051, 34.253882344000033],
            [71.311217327000065, 34.253339827000048],
            [71.311502862000054, 34.252654542000073],
            [71.311588523000069, 34.252197686000045],
            [71.311617077000051, 34.251312526000049],
            [71.311845505000065, 34.250227492000079],
            [71.311845505000065, 34.24957076100003],
            [71.311845505000065, 34.248999690000062],
            [71.311559969000029, 34.248228745000063],
            [71.31141720200003, 34.247257925000042],
            [71.311160220000033, 34.246001570000033],
            [71.310731917000055, 34.245144964000076],
            [71.310332168000059, 34.244259804000023],
            [71.309960972000056, 34.243117663000078],
            [71.30970399000006, 34.242403825000054],
            [71.309618329000045, 34.241604326000072],
            [71.309532669000077, 34.240776273000051],
            [71.309361348000039, 34.239776900000038],
            [71.308875937000039, 34.238606205000053],
            [71.308162099000072, 34.237435510000068],
            [71.307448261000047, 34.236835886000051],
            [71.306705869000041, 34.236236262000034],
            [71.306134798000073, 34.235465316000045],
            [71.305792156000052, 34.235008460000074],
            [71.305449514000031, 34.234408836000057],
            [71.305363853000074, 34.23366644400005],
            [71.305163978000053, 34.232809838000037],
            [71.305078318000028, 34.231896125000048],
            [71.304935550000039, 34.231068073000074],
            [71.304849890000071, 34.230097252000064],
            [71.304764229000057, 34.228612469000041],
            [71.304707122000082, 34.22767020200007],
            [71.30465001500005, 34.226984918000028],
            [71.304478694000068, 34.22641384700006],
            [71.304193158000032, 34.225956990000043],
            [71.303879069000061, 34.225500134000072],
            [71.303079571000069, 34.224757742000065],
            [71.30216585800008, 34.224043904000041],
            [71.301651894000031, 34.223615601000063],
            [71.300823842000057, 34.222816102000024],
            [71.300024343000075, 34.221788175000029],
            [71.299224844000037, 34.220588926000062],
            [71.299139183000079, 34.21978942800007],
            [71.299139183000079, 34.219189803000063],
            [71.299167737000062, 34.218561626000053],
            [71.299339058000044, 34.21804766200006],
            [71.299567486000058, 34.217647913000064],
            [71.299881575000029, 34.21707684200004],
            [71.300481199000046, 34.216505771000072],
            [71.300995163000039, 34.21596325400003],
            [71.301394912000035, 34.215335077000077],
            [71.301537680000081, 34.214649792000046],
            [71.301509126000042, 34.213878847000046],
            [71.300995163000039, 34.21327922200004],
            [71.300138557000082, 34.212451170000065],
            [71.299024969000072, 34.211394689000031],
            [71.298168363000059, 34.210423869000067],
            [71.297511632000067, 34.209881352000025],
            [71.296797794000042, 34.20871065700004],
            [71.296026848000054, 34.207711284000027],
            [71.29517024200004, 34.20699744500007],
            [71.293371370000045, 34.205598322000071],
            [71.291601051000043, 34.20428486000003],
            [71.289545197000052, 34.202257559000032],
            [71.28888846500007, 34.201600828000039],
            [71.288545823000049, 34.200687115000051],
            [71.288117520000071, 34.199630634000073],
            [71.288060413000039, 34.198745475000067],
            [71.288288841000053, 34.197660441000039],
            [71.28840305500006, 34.196746728000051],
            [71.288660037000056, 34.19591867500003],
            [71.288822054000036, 34.195513634000065],
            [71.28888846500007, 34.195347605000052],
            [71.289345322000031, 34.194833641000059],
            [71.290087714000038, 34.194319677000067],
            [71.292200675000061, 34.193006215000025],
            [71.293514138000035, 34.192492251000033],
            [71.294627725000055, 34.191921181000055],
            [71.295484331000068, 34.19186407400008],
            [71.296455151000032, 34.19186407400008],
            [71.297568739000042, 34.192063948000055],
            [71.29911063000003, 34.192692126000054],
            [71.300795288000074, 34.193006215000025],
            [71.302708375000066, 34.193520179000075],
            [71.303593534000072, 34.19357728600005],
            [71.304421587000036, 34.19357728600005],
            [71.305506621000063, 34.193463071000053],
            [71.306077691000041, 34.193320304000054],
            [71.306591655000034, 34.192663573000061],
            [71.30699140400003, 34.192206716000044],
            [71.307162726000058, 34.191492878000076],
            [71.307448261000047, 34.190579165000031],
            [71.307762350000075, 34.189579791000028],
            [71.308133546000079, 34.188723185000072],
            [71.308733170000039, 34.187780919000033],
            [71.309047259000067, 34.187009973000045],
            [71.309361348000039, 34.185953493000056],
            [71.309361348000039, 34.185296761000075],
            [71.309361348000039, 34.184697137000057],
            [71.309132919000035, 34.18409751300004],
            [71.30859040200005, 34.183212354000034],
            [71.30796222400005, 34.182498515000077],
            [71.30733404700004, 34.181442035000032],
            [71.307019958000069, 34.180613982000068],
            [71.306591655000034, 34.17967171600003],
            [71.306334673000038, 34.178986431000055],
            [71.306277566000063, 34.178072718000067],
            [71.306277566000063, 34.17718755900006],
            [71.30639178000007, 34.175931203000061],
            [71.306191906000038, 34.173190064000039],
            [71.30610624500008, 34.172333458000026],
            [71.306049138000049, 34.17130553100003],
            [71.305820710000035, 34.169820747000074],
            [71.305792156000052, 34.168849927000053],
            [71.305820710000035, 34.16799332100004],
            [71.305992031000073, 34.167136715000026],
            [71.306448887000045, 34.165994574000024],
            [71.306677316000048, 34.165252182000074],
            [71.30699140400003, 34.164481237000075],
            [71.30747681400004, 34.163710292000076],
            [71.308019332000072, 34.163224882000065],
            [71.308676063000064, 34.16282513200008],
            [71.309789651000074, 34.162311169000077],
            [71.311074559000076, 34.161968526000067],
            [71.312445129000082, 34.161511670000039],
            [71.314101234000077, 34.161111920000053],
            [71.315186268000048, 34.160997706000046],
            [71.316099981000036, 34.160912046000078],
            [71.316928033000067, 34.160969153000053],
            [71.317499104000035, 34.16122613400006],
            [71.31792740700007, 34.161426009000024],
            [71.318241496000041, 34.161911419000035],
            [71.318327156000066, 34.162425383000027],
            [71.318298603000073, 34.163224882000065],
            [71.318098728000052, 34.164566898000032],
            [71.317756086000031, 34.165737592000028],
            [71.31744199700006, 34.167051055000059],
            [71.317127908000032, 34.168507285000032],
            [71.316985140000043, 34.169649426000035],
            [71.316985140000043, 34.170277604000034],
            [71.317042248000064, 34.170791567000038],
            [71.317270676000078, 34.17119131700008],
            [71.317584765000049, 34.171505406000051],
            [71.318184389000066, 34.171619620000058],
            [71.319126655000048, 34.171619620000058],
            [71.320554332000029, 34.171591066000076],
            [71.32201056200006, 34.171562513000026],
            [71.323209810000037, 34.171533959000044],
            [71.324380505000079, 34.171391192000044],
            [71.325693968000053, 34.171077103000073],
            [71.32692176900008, 34.170791567000038],
            [71.32863498100005, 34.170534586000031],
            [71.329691462000028, 34.170506032000048],
            [71.330919264000045, 34.170506032000048],
            [71.332089959000029, 34.170534586000031],
            [71.333403421000071, 34.170534586000031],
            [71.334517009000081, 34.170363264000059],
            [71.33540216800003, 34.170020622000038],
            [71.336315881000075, 34.169735087000049],
            [71.337857772000064, 34.16959231900006],
            [71.339628091000066, 34.169164016000025],
            [71.340684572000043, 34.168621499000039],
            [71.341512624000075, 34.168107535000047],
            [71.342597658000045, 34.167422251000062],
            [71.343625585000041, 34.166679859000055],
            [71.344682066000075, 34.165594825000028],
            [71.345138923000036, 34.165052308000043],
            [71.345795654000028, 34.164395576000061],
            [71.346709367000074, 34.163795952000044],
            [71.34759452600008, 34.163281989000041],
            [71.348622453000075, 34.162853686000062],
            [71.349821702000042, 34.16279657900003],
            [71.351249378000034, 34.162853686000062],
            [71.352505734000033, 34.162967900000069],
            [71.353847750000057, 34.163281989000041],
            [71.355503854000062, 34.163767399000051],
            [71.356788763000054, 34.164138595000054],
            [71.35815933300006, 34.164367023000068],
            [71.360072420000051, 34.164738219000071],
            [71.362185381000074, 34.165223629000025],
            [71.364241235000065, 34.165766146000067],
            [71.366496964000078, 34.166394324000066],
            [71.367838980000045, 34.166679859000055],
            [71.369809174000068, 34.16673696600003],
            [71.37206490300008, 34.166794073000062],
            [71.37329270500004, 34.166794073000062],
            [71.375120131000074, 34.16673696600003],
            [71.377175985000065, 34.166594198000041],
            [71.379231839000056, 34.166422877000059],
            [71.380745176000062, 34.166422877000059],
            [71.382829584000035, 34.166537091000066],
            [71.384599903000037, 34.166651305000073],
            [71.385827705000054, 34.166851180000037],
            [71.387312489000067, 34.166965394000044],
            [71.38894004000008, 34.167222376000041],
            [71.390167842000039, 34.167479358000037],
            [71.391252876000067, 34.167650679000076],
            [71.392709106000041, 34.167936214000065],
            [71.39382269400005, 34.168278857000075],
            [71.396078423000063, 34.168707160000054],
            [71.397077796000076, 34.168907034000028],
            [71.398248491000061, 34.169049802000075],
            [71.399247865000063, 34.169164016000025],
            [71.400104471000077, 34.169164016000025],
            [71.400789756000052, 34.169164016000025],
            [71.401259273000051, 34.169141658000058],
            [71.401486791000082, 34.169074692000038],
            [71.403103384000076, 34.168598877000079],
            [71.405169110000031, 34.167582890000062],
            [71.405364149000036, 34.167492667000033],
            [71.408974317000059, 34.165822652000031],
            [71.409372587000064, 34.165638418000071],
            [71.41040051400006, 34.165095901000029],
            [71.410800263000056, 34.164824643000031],
            [71.411107447000063, 34.16443627600006],
            [71.411097444000063, 34.164404761000071],
            [71.411118639000051, 34.164335313000038],
            [71.411177887000065, 34.164194613000063],
            [71.411226000000056, 34.164064000000053],
            [71.411270000000059, 34.163936000000035],
            [71.41130000000004, 34.163866000000041],
            [71.41133893500006, 34.163771921000034],
            [71.411368290000041, 34.163694478000025],
            [71.411396596000031, 34.163580202000048],
            [71.411415158000068, 34.163506294000058],
            [71.411445421000053, 34.163441190000071],
            [71.411490625000056, 34.163354201000061],
            [71.411579298000049, 34.163208164000025],
            [71.411628645000064, 34.163097247000053],
            [71.411667228000056, 34.16301390700005],
            [71.411709821000045, 34.162895515000059],
            [71.411732251000046, 34.162827934000063],
            [71.411762328000066, 34.162727755000049],
            [71.411804800000084, 34.162650248000034],
            [71.411832342000082, 34.162582339000039],
            [71.411856736000061, 34.162498489000029],
            [71.411898000000065, 34.162400000000048],
            [71.411920000000066, 34.162327000000062],
            [71.411922385000082, 34.162320502000057],
            [71.411980878000065, 34.162161139000034],
            [71.411996884000075, 34.16209226400008],
            [71.41201645700005, 34.162024190000068],
            [71.412057609000044, 34.161911029000066],
            [71.412071659000048, 34.161865851000073],
            [71.412077988000078, 34.161845501000073],
            [71.412099349000073, 34.161772029000076],
            [71.412159557000052, 34.16158342500006],
            [71.41220037800008, 34.161470874000031],
            [71.41228400500006, 34.161203921000038],
            [71.412378733000082, 34.160919198000045],
            [71.412500429000033, 34.160559934000048],
            [71.412537156000042, 34.160463510000056],
            [71.412568958000065, 34.160380012000076],
            [71.412603919000048, 34.160280543000056],
            [71.41263000500004, 34.160203301000024],
            [71.412679733000061, 34.160075155000072],
            [71.412718342000062, 34.159977773000037],
            [71.412753302000056, 34.159855881000055],
            [71.412776433000033, 34.159773526000038],
            [71.412834491000069, 34.159611290000043],
            [71.412863801000071, 34.159490005000066],
            [71.412902268000039, 34.159311309000032],
            [71.412931804000038, 34.159129862000043],
            [71.412986470000078, 34.15879656900006],
            [71.413026774000059, 34.158587046000036],
            [71.413122000000044, 34.158092000000067],
            [71.413149000000033, 34.15791500000006],
            [71.413149597000029, 34.157910876000074],
            [71.413223000000073, 34.157404000000042],
            [71.413249000000064, 34.157245000000046],
            [71.413276000000053, 34.157102000000066],
            [71.413300000000049, 34.156975000000045],
            [71.41332600000004, 34.156911000000036],
            [71.413374000000033, 34.156833000000063],
            [71.413442000000032, 34.15671100000003],
            [71.413576000000035, 34.15647800000005],
            [71.413674000000071, 34.156321000000048],
            [71.413706000000047, 34.156261000000029],
            [71.41373800000008, 34.156184000000053],
            [71.413739164000049, 34.156181549000053],
            [71.413777155000048, 34.156101565000029],
            [71.41378700000007, 34.156023000000062],
            [71.413805000000082, 34.155933000000061],
            [71.413820000000044, 34.155843000000061],
            [71.41383200000007, 34.155760000000043],
            [71.413847000000032, 34.155625000000043],
            [71.413850000000082, 34.15555100000006],
            [71.413846000000035, 34.155415000000062],
            [71.413828609000063, 34.155348053000068],
            [71.413801516000035, 34.155276939000032],
            [71.413747340000043, 34.155152915000031],
            [71.41370433700007, 34.15504951500003],
            [71.413693264000074, 34.154986433000033],
            [71.413698747000069, 34.15490434000003],
            [71.413713827000038, 34.154843940000035],
            [71.413729337000063, 34.154735368000047],
            [71.41375800000003, 34.154596000000026],
            [71.41379088900004, 34.154406870000059],
            [71.413820825000073, 34.154212709000035],
            [71.413858394000044, 34.154023916000028],
            [71.413860895000084, 34.154011350000076],
            [71.413925093000046, 34.153721276000056],
            [71.413952091000056, 34.153599288000066],
            [71.413998812000045, 34.153400149000049],
            [71.414038413000071, 34.153223338000032],
            [71.41423594500003, 34.152341402000047],
            [71.414288950000071, 34.152009563000036],
            [71.414313297000035, 34.151890839000032],
            [71.414336687000059, 34.151776785000038],
            [71.414373147000049, 34.151612557000078],
            [71.414398085000073, 34.151514611000039],
            [71.41441440400007, 34.151404532000072],
            [71.41439786400008, 34.151326224000059],
            [71.414357707000079, 34.151257610000073],
            [71.41428680100006, 34.151175212000055],
            [71.414209833000029, 34.151134008000042],
            [71.414161269000033, 34.151126591000036],
            [71.414077819000056, 34.151113846000044],
            [71.41406220600004, 34.151081524000062],
            [71.414073970000061, 34.151030458000037],
            [71.414091000000042, 34.150932000000068],
            [71.414118108000082, 34.150813280000079],
            [71.414150339000059, 34.150607637000064],
            [71.414276498000049, 34.14980244000003],
            [71.414333773000067, 34.149486007000064],
            [71.414390943000058, 34.149197282000046],
            [71.414394523000055, 34.149178254000049],
            [71.414426794000065, 34.149006740000061],
            [71.414452163000078, 34.148884295000073],
            [71.414480437000066, 34.148746885000037],
            [71.414497002000076, 34.148651489000031],
            [71.414469782000083, 34.148594555000045],
            [71.414391720000083, 34.148551186000077],
            [71.414288000000056, 34.148529000000053],
            [71.414195000000063, 34.148503000000062],
            [71.41402800000003, 34.148469000000034],
            [71.413966000000073, 34.148453000000075],
            [71.413866000000041, 34.148418000000049],
            [71.413789795000071, 34.148386372000061],
            [71.413726759000042, 34.148351758000047],
            [71.413672674000054, 34.148314109000069],
            [71.413612869000076, 34.148265238000079],
            [71.413518336000038, 34.14820443900004],
            [71.413421020000044, 34.148126631000025],
            [71.41334489500008, 34.14805751800003],
            [71.413281873000074, 34.148008796000056],
            [71.413247645000069, 34.147949539000024],
            [71.413224858000035, 34.147866757000031],
            [71.413217872000075, 34.14778847000008],
            [71.413220932000058, 34.147698314000024],
            [71.413221100000044, 34.147560635000048],
            [71.413220000000081, 34.147502000000031],
            [71.413226000000066, 34.147398000000067],
            [71.41323729700008, 34.147188783000047],
            [71.41324615700006, 34.147103317000074],
            [71.413243414000078, 34.147014063000029],
            [71.413256398000044, 34.146915985000078],
            [71.413260611000055, 34.14679691200007],
            [71.413245834000065, 34.146709239000074],
            [71.413186543000052, 34.146643568000059],
            [71.41314200000005, 34.146584000000075],
            [71.413106135000078, 34.146537376000026],
            [71.413082000000031, 34.146506000000045],
            [71.412963068000067, 34.14637970900003],
            [71.412890000000061, 34.146308000000033],
            [71.412828596000054, 34.146249761000036],
            [71.41246521000005, 34.145917785000051],
            [71.412339706000068, 34.145820778000029],
            [71.412258521000069, 34.145760300000063],
            [71.41208789500007, 34.145661998000037],
            [71.412005134000083, 34.145601678000048],
            [71.411910503000058, 34.145547772000043],
            [71.411832656000058, 34.145513435000055],
            [71.411610966000069, 34.145373908000067],
            [71.411441945000036, 34.145271102000038],
            [71.411302956000043, 34.145187147000058],
            [71.411194214000034, 34.145131922000076],
            [71.411111577000042, 34.145072325000058],
            [71.411028803000079, 34.145021077000024],
            [71.410957631000031, 34.144957332000047],
            [71.41089499900005, 34.14488446200005],
            [71.410837232000063, 34.14478469200003],
            [71.410798286000045, 34.144704860000047],
            [71.410732290000055, 34.144604246000029],
            [71.410686248000047, 34.144527913000047],
            [71.410597573000075, 34.144381529000043],
            [71.410556276000079, 34.144304515000044],
            [71.41049828000007, 34.144205269000054],
            [71.410418551000078, 34.144026248000046],
            [71.410381519000055, 34.143947507000064],
            [71.410339113000077, 34.143856448000065],
            [71.410234027000058, 34.143679502000055],
            [71.410202458000072, 34.143613438000045],
            [71.410169689000043, 34.143549621000034],
            [71.41010616300008, 34.143467575000045],
            [71.410079806000056, 34.143400598000028],
            [71.410037603000035, 34.143334358000061],
            [71.409989507000034, 34.143265971000062],
            [71.409915641000055, 34.143179843000041],
            [71.409854820000078, 34.143124071000045],
            [71.409795442000075, 34.143082135000043],
            [71.409585288000073, 34.142958715000077],
            [71.40945088400008, 34.142886803000067],
            [71.409365837000053, 34.142825078000044],
            [71.409279199000082, 34.142763200000047],
            [71.409190132000049, 34.142701576000036],
            [71.40910000000008, 34.142652000000055],
            [71.409028000000035, 34.14261700000003],
            [71.408837000000062, 34.142535000000066],
            [71.408760000000029, 34.142495000000054],
            [71.408655632000034, 34.142429838000055],
            [71.408616854000059, 34.142365322000046],
            [71.408578000000034, 34.142269000000056],
            [71.408536975000061, 34.142057567000052],
            [71.408463558000051, 34.141812945000027],
            [71.408261498000059, 34.141022246000034],
            [71.408098820000077, 34.140392210000073],
            [71.408041098000069, 34.140153481000027],
            [71.407991060000029, 34.13991705400008],
            [71.407913496000049, 34.139691182000035],
            [71.407884000000081, 34.139615000000049],
            [71.40788113800005, 34.139475914000059],
            [71.407933000000071, 34.139378000000079],
            [71.407982000000061, 34.139298000000053],
            [71.408043000000077, 34.139226000000065],
            [71.408049873000039, 34.139203485000053],
            [71.408072000000061, 34.139131000000077],
            [71.40805000000006, 34.139054000000044],
            [71.407983000000058, 34.138975000000073],
            [71.407852000000048, 34.138822000000062],
            [71.407684000000074, 34.13862800000004],
            [71.407567000000029, 34.138495000000034],
            [71.407488000000058, 34.138410000000079],
            [71.407398000000057, 34.138316000000032],
            [71.407053905000055, 34.137956059000032],
            [71.406540476000032, 34.13740916900008],
            [71.406249456000069, 34.137091767000072],
            [71.40603806300004, 34.136877668000068],
            [71.405740308000077, 34.136556910000024],
            [71.405620579000072, 34.136437731000058],
            [71.405507795000062, 34.136315489000026],
            [71.405316433000053, 34.136105611000062],
            [71.405253365000078, 34.136036440000055],
            [71.405119735000028, 34.135910838000029],
            [71.405025987000045, 34.13581356800006],
            [71.404822000000081, 34.135598000000073],
            [71.404731000000083, 34.135491000000059],
            [71.404639352000061, 34.135391773000038],
            [71.404598000000078, 34.135347000000024],
            [71.404560436000054, 34.135282259000064],
            [71.404512067000041, 34.135219937000045],
            [71.404465169000048, 34.13516142900005],
            [71.404418595000038, 34.135086070000057],
            [71.404330895000044, 34.13495057800003],
            [71.404139175000068, 34.134647605000055],
            [71.403958154000065, 34.134392784000056],
            [71.403708317000053, 34.134037869000053],
            [71.403388327000073, 34.133598536000079],
            [71.403196566000076, 34.133311506000041],
            [71.40299744400005, 34.13302339300003],
            [71.402813184000081, 34.132753391000051],
            [71.402623778000077, 34.132463205000079],
            [71.402464675000033, 34.132260928000051],
            [71.402093239000067, 34.131715963000033],
            [71.40193400000004, 34.131507000000056],
            [71.40189300000003, 34.131453000000079],
            [71.401854000000071, 34.131406000000027],
            [71.401809000000071, 34.131357000000037],
            [71.401756000000034, 34.131305000000054],
            [71.401590639000062, 34.131138983000028],
            [71.401511212000059, 34.131071776000056],
            [71.401432334000049, 34.13100096900007],
            [71.401088779000077, 34.130727266000065],
            [71.400661359000082, 34.130355155000075],
            [71.400077983000074, 34.129858320000039],
            [71.399616940000044, 34.129465670000059],
            [71.39866138800005, 34.128659780000078],
            [71.398340000000076, 34.128390000000024],
            [71.398176067000065, 34.128253390000054],
            [71.398046000000079, 34.128145000000075],
            [71.397601000000066, 34.127775000000042],
            [71.397400729000083, 34.127594575000046],
            [71.39718008300008, 34.127420764000078],
            [71.397045201000083, 34.127339670000026],
            [71.396968556000047, 34.12730317200004],
            [71.396850892000032, 34.127256380000063],
            [71.396746088000043, 34.127216430000033],
            [71.396498489000066, 34.127146512000024],
            [71.395829793000075, 34.126949209000031],
            [71.395230110000057, 34.126782695000031],
            [71.39488097900005, 34.12668285500007],
            [71.394691341000055, 34.12661337000003],
            [71.394606167000063, 34.126561233000075],
            [71.394444585000031, 34.126391053000077],
            [71.394083033000072, 34.125934019000056],
            [71.393956535000029, 34.125742796000054],
            [71.393882857000051, 34.125635989000045],
            [71.393765435000034, 34.125452646000042],
            [71.393647954000073, 34.125266957000065],
            [71.393518411000059, 34.125049060000038],
            [71.393397941000046, 34.124835451000024],
            [71.393176574000051, 34.124417636000032],
            [71.393031000000065, 34.124187000000063],
            [71.392706479000083, 34.123654891000058],
            [71.392577250000045, 34.123406823000039],
            [71.392411020000054, 34.123122868000053],
            [71.392211193000037, 34.122798593000027],
            [71.391725277000035, 34.121953746000031],
            [71.391613757000073, 34.12175544300004],
            [71.391554542000051, 34.121665054000061],
            [71.391507971000067, 34.121592588000055],
            [71.391415558000062, 34.121427149000056],
            [71.391338493000035, 34.121308751000072],
            [71.391275983000071, 34.121228994000035],
            [71.391158463000068, 34.121127150000063],
            [71.39068410200008, 34.120781630000067],
            [71.390557665000074, 34.12070830600004],
            [71.390445849000059, 34.120643461000043],
            [71.390440452000064, 34.120606063000025],
            [71.39059052500005, 34.120460318000028],
            [71.390733326000031, 34.120305065000025],
            [71.390890332000083, 34.120117730000061],
            [71.390993434000052, 34.119993323000074],
            [71.391164566000043, 34.119704587000058],
            [71.391284579000057, 34.119467180000072],
            [71.391351573000065, 34.119302553000068],
            [71.391406801000073, 34.119074047000026],
            [71.391440000000046, 34.118813000000046],
            [71.391448000000082, 34.118711000000076],
            [71.391467000000034, 34.118302000000028],
            [71.39149100000003, 34.117954000000054],
            [71.391507647000083, 34.117731413000058],
            [71.391553000000044, 34.117125000000044],
            [71.39163942700003, 34.115478923000069],
            [71.39164476500008, 34.115377261000049],
            [71.39173123900008, 34.113929309000071],
            [71.391784610000059, 34.113168323000025],
            [71.391800738000029, 34.112938363000069],
            [71.391832763000082, 34.112382657000069],
            [71.39184012000004, 34.112112848000038],
            [71.391877738000062, 34.111768349000045],
            [71.391880167000068, 34.111630149000064],
            [71.391887249000035, 34.111376459000041],
            [71.391876671000034, 34.111254963000079],
            [71.391856587000063, 34.111141807000024],
            [71.391832545000057, 34.11103072700007],
            [71.391795604000038, 34.110890777000066],
            [71.391762823000079, 34.11076707400008],
            [71.391732637000075, 34.110691016000033],
            [71.391700434000029, 34.110613600000079],
            [71.39165538900005, 34.11053170200006],
            [71.391586697000037, 34.110393990000034],
            [71.391525410000042, 34.110268612000027],
            [71.391462048000051, 34.110180203000027],
            [71.391399704000037, 34.110091526000076],
            [71.39131496400006, 34.10998730600005],
            [71.391238214000055, 34.109892251000076],
            [71.391150444000061, 34.109800362000044],
            [71.391000624000071, 34.109625764000043],
            [71.39084889600008, 34.109485715000062],
            [71.390585642000076, 34.109228806000033],
            [71.390422414000057, 34.109057467000071],
            [71.389999815000067, 34.108625096000026],
            [71.389592305000065, 34.108210648000068],
            [71.389405148000037, 34.10801093300006],
            [71.389325832000054, 34.107935647000033],
            [71.389244230000031, 34.10785702700008],
            [71.389149909000082, 34.107730017000051],
            [71.389041729000041, 34.107609783000044],
            [71.38896300600004, 34.107502226000065],
            [71.388867927000035, 34.107360612000036],
            [71.388815041000043, 34.107261547000064],
            [71.388777320000031, 34.107186999000078],
            [71.388729707000039, 34.107098516000065],
            [71.388694015000056, 34.107021045000067],
            [71.388657673000068, 34.106926249000026],
            [71.388631646000078, 34.106833835000032],
            [71.388603715000045, 34.106746716000032],
            [71.388577329000043, 34.106640372000072],
            [71.388550609000049, 34.106503380000049],
            [71.388534269000047, 34.106400777000033],
            [71.388523680000048, 34.106299528000079],
            [71.388510993000068, 34.106181608000043],
            [71.388512189000039, 34.106083785000067],
            [71.388509372000044, 34.105970309000043],
            [71.388521148000052, 34.105719818000068],
            [71.388520691000053, 34.105488901000058],
            [71.388524293000046, 34.105292510000027],
            [71.388523203000034, 34.104494532000047],
            [71.388519362000068, 34.104017862000035],
            [71.388523255000052, 34.103689647000067],
            [71.388520629000084, 34.103542767000079],
            [71.388511891000064, 34.103411114000039],
            [71.388513229000068, 34.103277966000064],
            [71.388497376000032, 34.103155120000054],
            [71.388483508000036, 34.103070039000045],
            [71.388462356000048, 34.102980398000057],
            [71.388429827000039, 34.102883896000037],
            [71.388402258000042, 34.102773957000068],
            [71.388364092000074, 34.102668318000042],
            [71.388316703000044, 34.102566786000068],
            [71.38826372300008, 34.102453850000074],
            [71.388203586000031, 34.102336320000063],
            [71.388126782000029, 34.102215001000047],
            [71.388057737000054, 34.102084071000036],
            [71.387985309000044, 34.101983202000042],
            [71.387908280000033, 34.101852527000062],
            [71.387787372000048, 34.101656528000035],
            [71.387619856000072, 34.101400753000064],
            [71.387544845000036, 34.101274403000048],
            [71.387408106000066, 34.101044076000051],
            [71.387269685000035, 34.100872252000045],
            [71.387182587000041, 34.100749906000033],
            [71.387082901000042, 34.100617530000079],
            [71.387028631000078, 34.10051482700004],
            [71.386952700000052, 34.100396853000063],
            [71.386898685000062, 34.100295082000059],
            [71.386798768000062, 34.100140435000071],
            [71.38676590700004, 34.100040887000034],
            [71.386738616000059, 34.09995499200005],
            [71.386732797000036, 34.099884803000066],
            [71.386724251000032, 34.099801720000073],
            [71.386724452000067, 34.09972087500006],
            [71.38675822700003, 34.099624722000044],
            [71.386802616000068, 34.09954411700005],
            [71.386869000000047, 34.099447000000055],
            [71.386944000000028, 34.099330000000066],
            [71.38699500000007, 34.099270000000047],
            [71.38704000000007, 34.099227000000042],
            [71.38714600000003, 34.099147000000073],
            [71.387193000000082, 34.099112000000048],
            [71.387247000000059, 34.099076000000025],
            [71.387428000000057, 34.098960000000034],
            [71.387622000000079, 34.098840000000052],
            [71.387699000000055, 34.098791000000062],
            [71.387834000000055, 34.098697000000072],
            [71.387886000000037, 34.098658000000057],
            [71.387933000000032, 34.098621000000037],
            [71.387980000000084, 34.098581000000024],
            [71.388029000000074, 34.098537000000078],
            [71.388142000000073, 34.098428000000069],
            [71.388219000000049, 34.098349000000042],
            [71.388302000000067, 34.098260000000039],
            [71.388390000000072, 34.098161000000061],
            [71.388428000000033, 34.098115000000064],
            [71.388486046000082, 34.09804678100005],
            [71.388569181000037, 34.097929037000029],
            [71.388659706000055, 34.097755463000055],
            [71.388739001000033, 34.097564067000064],
            [71.388810131000071, 34.097422403000053],
            [71.388884497000049, 34.097261207000031],
            [71.388953360000073, 34.097095040000056],
            [71.389077047000058, 34.096815007000032],
            [71.38912905400008, 34.096659905000024],
            [71.389171024000063, 34.096566684000038],
            [71.389231090000067, 34.096397981000052],
            [71.389248748000057, 34.096331254000063],
            [71.389270802000055, 34.096251398000049],
            [71.38930515800007, 34.096132212000043],
            [71.389327579000053, 34.095986337000056],
            [71.389334151000071, 34.095861024000044],
            [71.389349239000069, 34.095714054000041],
            [71.389349293000066, 34.095607388000076],
            [71.389354680000054, 34.095542217000059],
            [71.389346456000055, 34.095469829000024],
            [71.389344636000033, 34.095381150000037],
            [71.389315456000077, 34.09517839800003],
            [71.389273113000058, 34.094891762000032],
            [71.389205000000061, 34.094350000000077],
            [71.389169000000038, 34.094040000000064],
            [71.389142000000049, 34.09383500000007],
            [71.389118474000043, 34.093591974000049],
            [71.388993993000042, 34.092694068000071],
            [71.388961807000044, 34.092406156000038],
            [71.38893881100006, 34.092130316000066],
            [71.388927943000056, 34.092042094000078],
            [71.388887486000044, 34.091713682000034],
            [71.388810609000075, 34.091135555000051],
            [71.388740837000057, 34.090558391000059],
            [71.388647509000066, 34.089896631000045],
            [71.388461481000036, 34.088514720000035],
            [71.388397112000064, 34.088001764000069],
            [71.388335020000056, 34.087487809000038],
            [71.38803914500005, 34.085187061000056],
            [71.387735759000066, 34.082703182000046],
            [71.387413768000044, 34.08024957300006],
            [71.387264168000058, 34.079118325000024],
            [71.387030399000082, 34.077314867000041],
            [71.386855819000061, 34.075908345000073],
            [71.386725873000046, 34.07503084700005],
            [71.386715913000046, 34.074948689000053],
            [71.386702586000069, 34.07477366300003],
            [71.386682288000031, 34.074628700000062],
            [71.386688259000039, 34.074451568000029],
            [71.386700977000032, 34.074329294000052],
            [71.386723486000051, 34.074199173000068],
            [71.386761504000049, 34.074110056000052],
            [71.386803798000074, 34.073998373000052],
            [71.386846157000036, 34.073914089000027],
            [71.386927703000083, 34.073791973000027],
            [71.387046305000069, 34.073648429000059],
            [71.387138021000055, 34.073561897000047],
            [71.38729177600004, 34.07344303800005],
            [71.387550000000033, 34.073298000000079],
            [71.387782000000072, 34.073208000000079],
            [71.387929000000042, 34.073149000000058],
            [71.388094000000081, 34.073086000000046],
            [71.388656000000083, 34.072879000000057],
            [71.388870000000054, 34.072799000000032],
            [71.389192000000037, 34.072674000000063],
            [71.390099000000077, 34.072314000000063],
            [71.390743000000043, 34.072067000000061],
            [71.392300000000034, 34.071477000000073],
            [71.39417700000007, 34.070754000000079],
            [71.395382000000041, 34.070293000000049],
            [71.397332000000063, 34.069548000000054],
            [71.398242000000039, 34.069200000000023],
            [71.399304000000029, 34.068802000000062],
            [71.399563000000057, 34.06870200000003],
            [71.399794000000043, 34.068611000000033],
            [71.400312000000042, 34.068396000000064],
            [71.40045107800006, 34.068339370000047],
            [71.399793835000082, 34.06608977500008],
            [71.399783944000035, 34.066055920000053],
            [71.399773183000036, 34.066019087000029],
            [71.399459860000036, 34.065001921000032],
            [71.398934441000051, 34.063392904000068],
            [71.398656646000063, 34.062469180000051],
            [71.398593965000032, 34.062260754000079],
            [71.398557150000045, 34.062138339000057],
            [71.398106272000064, 34.060613357000079],
            [71.397643348000031, 34.058985887000063],
            [71.397190457000079, 34.057533746000047],
            [71.39681078600006, 34.05625738100008],
            [71.396431262000078, 34.055000289000077],
            [71.396354605000056, 34.054724174000057],
            [71.396121056000084, 34.05388294200003],
            [71.395681847000048, 34.052462172000048],
            [71.395209355000077, 34.050893298000062],
            [71.394849290000082, 34.04970219300003],
            [71.394827777000046, 34.049631027000032],
            [71.394362880000074, 34.04801793300004],
            [71.394116386000064, 34.047177851000072],
            [71.394105703000037, 34.047141443000044],
            [71.393881694000072, 34.046475694000037],
            [71.393622209000057, 34.045762607000029],
            [71.393297363000045, 34.044905700000072],
            [71.392974378000076, 34.044079981000039],
            [71.392634949000069, 34.043228231000057],
            [71.392354467000075, 34.04248437800004],
            [71.392133704000059, 34.041903155000057],
            [71.391989643000045, 34.041569269000036],
            [71.391932562000079, 34.041489754000054],
            [71.391882369000029, 34.041420817000073],
            [71.391864124000051, 34.041360293000025],
            [71.391873240000052, 34.041297941000039],
            [71.391939389000072, 34.041188376000036],
            [71.391974417000029, 34.041126437000059],
            [71.391867000000047, 34.041119000000037],
            [71.391532000000041, 34.041102000000024],
            [71.39144600000003, 34.041099000000031],
            [71.391357000000085, 34.041098000000034],
            [71.39126200000004, 34.041099000000031],
            [71.391161000000068, 34.041103000000078],
            [71.390975000000083, 34.041113000000053],
            [71.390538000000049, 34.041143000000034],
            [71.39032300000008, 34.041153000000065],
            [71.390221000000054, 34.041154000000063],
            [71.390126000000066, 34.041154000000063],
            [71.390034000000071, 34.041150000000073],
            [71.389947000000063, 34.041145000000029],
            [71.389709000000039, 34.041124000000025],
            [71.389499000000058, 34.041099000000031],
            [71.389307000000031, 34.041070000000047],
            [71.389216000000033, 34.041053000000034],
            [71.389128000000028, 34.041035000000079],
            [71.389010000000042, 34.041008000000033],
            [71.388895000000048, 34.040980000000047],
            [71.388781000000051, 34.040949000000069],
            [71.38866639400004, 34.04092326500006],
            [71.388570702000038, 34.040907102000062],
            [71.388448433000065, 34.040878988000031],
            [71.388327459000038, 34.040833452000072],
            [71.388135010000042, 34.040750839000054],
            [71.38788849000008, 34.040628086000027],
            [71.387714496000058, 34.040521694000063],
            [71.387505592000082, 34.040383262000034],
            [71.387339098000041, 34.040271021000024],
            [71.38707048100008, 34.040079168000034],
            [71.386805338000045, 34.03987588800004],
            [71.386503516000062, 34.039671199000054],
            [71.38622108800007, 34.039485232000061],
            [71.385987219000071, 34.039342376000036],
            [71.38557226000006, 34.039019344000053],
            [71.385015226000064, 34.038658982000072],
            [71.384456577000037, 34.038286734000053],
            [71.383995069000036, 34.038013126000067],
            [71.383634099000062, 34.037812806000034],
            [71.383280554000066, 34.037682951000079],
            [71.383028000000081, 34.037582000000043],
            [71.382756000000029, 34.037418000000059],
            [71.381854000000033, 34.036884000000043],
            [71.381523000000072, 34.036686000000032],
            [71.381200000000035, 34.036489000000074],
            [71.38091200000008, 34.036308000000076],
            [71.38073600000007, 34.03619400000008],
            [71.380569000000037, 34.036083000000076],
            [71.380408000000045, 34.03597300000007],
            [71.380254000000036, 34.035865000000058],
            [71.380105000000071, 34.035758000000044],
            [71.379961000000037, 34.03565100000003],
            [71.379822000000047, 34.035544000000073],
            [71.379687000000047, 34.035437000000059],
            [71.379380455000046, 34.03524145800003],
            [71.379136374000041, 34.035063102000038],
            [71.37888627600006, 34.034882314000072],
            [71.378648961000067, 34.034700391000058],
            [71.378439797000055, 34.034473066000032],
            [71.378301304000047, 34.034259888000065],
            [71.378163623000034, 34.034109407000074],
            [71.377940264000074, 34.033832602000075],
            [71.377825636000068, 34.03358656100005],
            [71.377704398000049, 34.033436446000053],
            [71.37754788500007, 34.033147666000048],
            [71.377386332000071, 34.032802336000032],
            [71.377071857000033, 34.032386525000049],
            [71.376900105000061, 34.032213400000046],
            [71.376716559000045, 34.032033693000074],
            [71.376422588000082, 34.031798511000034],
            [71.376739000000043, 34.031635000000051],
            [71.377081658000066, 34.031441675000053],
            [71.377282284000046, 34.031280792000075],
            [71.377557807000073, 34.03094014800007],
            [71.377971000000059, 34.030308000000048],
            [71.378082420000055, 34.030170610000027],
            [71.378300157000069, 34.029927033000035],
            [71.378482750000046, 34.029778170000043],
            [71.378794456000037, 34.029571814000064],
            [71.379184619000057, 34.029343335000078],
            [71.379754705000039, 34.029036676000032],
            [71.379897258000028, 34.028960550000079],
            [71.380299207000064, 34.028750632000026],
            [71.380605120000041, 34.028576435000048],
            [71.381171270000038, 34.028257002000032],
            [71.381627595000054, 34.027976386000034],
            [71.382102394000071, 34.027697870000054],
            [71.382505602000037, 34.027478036000048],
            [71.382942580000076, 34.02722282600007],
            [71.383354071000042, 34.026984665000043],
            [71.385173211000051, 34.025936706000039],
            [71.385561971000072, 34.025727099000051],
            [71.385796153000058, 34.025475844000027],
            [71.385981171000083, 34.025166816000024],
            [71.386125633000063, 34.024950569000055],
            [71.38621469900005, 34.024843429000043],
            [71.386280286000044, 34.024784078000039],
            [71.386509368000077, 34.024662818000024],
            [71.386681236000072, 34.024598319000063],
            [71.386796615000037, 34.024572165000052],
            [71.386422058000051, 34.023325890000024],
            [71.386404914000082, 34.023270867000065],
            [71.386215748000041, 34.022663746000035],
            [71.38600272900004, 34.021980070000041],
            [71.385940030000029, 34.021778840000025],
            [71.385907708000047, 34.021678122000026],
            [71.385763756000074, 34.021229547000075],
            [71.385534067000037, 34.020513809000079],
            [71.385529280000071, 34.020498695000072],
            [71.385328366000067, 34.019864348000056],
            [71.385040454000034, 34.018955319000042],
            [71.385009984000078, 34.018857519000051],
            [71.384913716000028, 34.018548521000071],
            [71.384886384000083, 34.018472760000066],
            [71.384692549000079, 34.017935486000056],
            [71.384515285000077, 34.017511030000037],
            [71.38431171700006, 34.017023589000075],
            [71.384164278000071, 34.016704656000059],
            [71.383645302000048, 34.015582036000069],
            [71.383094199000084, 34.014320283000075],
            [71.382635227000037, 34.013264741000057],
            [71.382598015000042, 34.013179162000029],
            [71.382235779000041, 34.012372587000073],
            [71.381945247000033, 34.011725670000033],
            [71.381913869000073, 34.011654509000039],
            [71.381604335000077, 34.010952538000026],
            [71.381579786000032, 34.01089352200006],
            [71.381492521000041, 34.010683736000033],
            [71.381434130000059, 34.010544149000054],
            [71.381401750000066, 34.010417361000066],
            [71.381343751000031, 34.009311911000054],
            [71.381319549000068, 34.008850621000079],
            [71.381253202000039, 34.007512278000036],
            [71.381229533000067, 34.007034822000037],
            [71.381146806000061, 34.005634593000025],
            [71.381054920000054, 34.004085408000037],
            [71.381029144000081, 34.003698109000027],
            [71.380968777000078, 34.002414003000069],
            [71.381109637000066, 34.002392052000062],
            [71.381522572000051, 34.00231249400008],
            [71.382014704000028, 34.002195404000076],
            [71.382910154000058, 34.001965646000031],
            [71.383939245000079, 34.001685734000034],
            [71.384507961000054, 34.001526596000076],
            [71.384969673000057, 34.001377362000028],
            [71.386216887000046, 34.000963472000024],
            [71.386473886000033, 34.000867220000032],
            [71.386690996000084, 34.000775481000062],
            [71.386812821000035, 34.000718127000027],
            [71.386993989000075, 34.000610461000065],
            [71.387331059000076, 34.000402387000065],
            [71.388039265000032, 33.999979243000041],
            [71.388168834000055, 33.999903217000053],
            [71.388628632000064, 33.999633426000059],
            [71.388983323000048, 33.999436868000032],
            [71.389118721000045, 33.999381276000065],
            [71.389261601000044, 33.999320603000058],
            [71.389510983000037, 33.999240004000058],
            [71.389724009000076, 33.999197004000052],
            [71.389916123000035, 33.999176190000071],
            [71.390130816000067, 33.999164692000079],
            [71.390645413000072, 33.999192513000025],
            [71.391015461000052, 33.999232191000033],
            [71.391186159000029, 33.999262978000047],
            [71.391415061000032, 33.999338930000079],
            [71.391763646000072, 33.999458798000035],
            [71.392201023000041, 33.999636148000036],
            [71.393543510000029, 34.000211944000057],
            [71.394796807000034, 34.000745398000049],
            [71.39547814000008, 34.001011659000028],
            [71.395617491000053, 34.001066116000061],
            [71.396263543000032, 34.001340800000037],
            [71.396510669000065, 34.001444144000061],
            [71.39667242400003, 34.001520528000071],
            [71.396942016000082, 34.001583433000064],
            [71.397189142000059, 34.001592419000076],
            [71.39741829500008, 34.001578940000059],
            [71.397696873000029, 34.001542994000033],
            [71.397921533000044, 34.001475596000034],
            [71.398182138000038, 34.001363266000055],
            [71.398474196000052, 34.001179045000072],
            [71.398574612000061, 34.001122956000074],
            [71.398961077000081, 34.000863949000063],
            [71.399382978000062, 34.000548461000051],
            [71.399770829000033, 34.000252646000035],
            [71.400097606000031, 34.000002398000049],
            [71.400430559000029, 33.999760264000031],
            [71.400682876000076, 33.999581976000059],
            [71.401004213000078, 33.999354918000051],
            [71.401524512000037, 33.998974317000034],
            [71.401861908000058, 33.998735788000033],
            [71.401990362000049, 33.998653328000046],
            [71.402164024000058, 33.998566605000065],
            [71.402329003000034, 33.998497396000062],
            [71.402461867000056, 33.998449672000049],
            [71.402712474000054, 33.998340882000036],
            [71.403282060000038, 33.997671715000024],
            [71.403542231000074, 33.997366058000068],
            [71.403567164000037, 33.997336766000046],
            [71.403979864000064, 33.996868845000051],
            [71.404125499000031, 33.996703723000053],
            [71.404172586000072, 33.996650336000073],
            [71.404781922000041, 33.995950925000045],
            [71.40506227000003, 33.99560761400005],
            [71.405066135000084, 33.995602881000025],
            [71.405356048000044, 33.995247857000038],
            [71.40536361900007, 33.995238586000028],
            [71.405813434000038, 33.994727123000075],
            [71.405829351000079, 33.994709025000077],
            [71.406262302000073, 33.994216737000045],
            [71.406274197000073, 33.99420321100007],
            [71.40640690400005, 33.994052317000069],
            [71.40662130000004, 33.993796846000066],
            [71.407346878000055, 33.992932257000064],
            [71.40792635300005, 33.992227508000042],
            [71.407997279000028, 33.992141249000042],
            [71.408615860000054, 33.991388941000025],
            [71.40868355200007, 33.991306615000042],
            [71.408727765000037, 33.99125229200007],
            [71.408741572000054, 33.991235328000073],
            [71.40902998100006, 33.990880974000049],
            [71.409440203000031, 33.990376955000045],
            [71.409838176000051, 33.989887986000042],
            [71.409903547000056, 33.989807668000026],
            [71.410261768000055, 33.989379880000058],
            [71.410472330000061, 33.989128427000026],
            [71.41068540100008, 33.988833212000031],
            [71.410852286000079, 33.988580268000078],
            [71.410975607000069, 33.988393354000038],
            [71.411049365000054, 33.98824981000007],
            [71.411257581000029, 33.987844592000044],
            [71.411469755000041, 33.987431403000073],
            [71.411515689000055, 33.98734195000003],
            [71.411689237000076, 33.987003980000054],
            [71.411797699000033, 33.986681966000049],
            [71.411803985000063, 33.986658669000064],
            [71.411865024000065, 33.98643246000006],
            [71.411980951000032, 33.986002838000047],
            [71.411989743000049, 33.98597025600003],
            [71.412073539000062, 33.985574901000064],
            [71.412236071000052, 33.984707559000071],
            [71.412368592000064, 33.983913870000038],
            [71.412449201000072, 33.983431754000037],
            [71.412473856000076, 33.983284298000058],
            [71.412487446000057, 33.983203015000072],
            [71.412542690000066, 33.982911930000057],
            [71.412544780000076, 33.982900917000052],
            [71.412812814000063, 33.981488625000054],
            [71.412839397000084, 33.981349113000078],
            [71.412944701000072, 33.98079646900004],
            [71.412970254000072, 33.980690388000028],
            [71.41304038800007, 33.980399240000054],
            [71.413202854000076, 33.979977659000042],
            [71.413331017000075, 33.979633801000034],
            [71.413526321000063, 33.979256857000053],
            [71.413610242000061, 33.979094886000041],
            [71.413837881000063, 33.978658138000071],
            [71.414156966000064, 33.978045940000072],
            [71.41416816900005, 33.978024446000063],
            [71.414452961000052, 33.977478044000065],
            [71.414480701000059, 33.977424822000046],
            [71.41457001100008, 33.97725347100004],
            [71.414660433000051, 33.976967616000024],
            [71.414760620000038, 33.976649192000025],
            [71.414753502000053, 33.976428487000078],
            [71.414741166000056, 33.976325211000074],
            [71.414715127000079, 33.976107222000053],
            [71.414591542000039, 33.975714629000038],
            [71.414545729000054, 33.975569092000057],
            [71.414531370000077, 33.97552347900006],
            [71.414411775000076, 33.97514355800007],
            [71.414374853000083, 33.975026266000043],
            [71.414356548000058, 33.97496811700006],
            [71.41432773400004, 33.974884843000041],
            [71.414317990000029, 33.974856683000041],
            [71.413673323000069, 33.972993555000073],
            [71.41355291800005, 33.972636715000078],
            [71.413231175000078, 33.971683173000031],
            [71.413125105000063, 33.97137600800005],
            [71.412884892000079, 33.970680381000079],
            [71.412759759000039, 33.970318011000074],
            [71.412756462000061, 33.970308462000048],
            [71.412596327000074, 33.969771030000061],
            [71.412410968000074, 33.969148940000025],
            [71.412191667000059, 33.968505745000073],
            [71.412166822000074, 33.968432877000055],
            [71.412144840000053, 33.968297805000077],
            [71.412059999000064, 33.967776471000036],
            [71.411904457000048, 33.966764484000066],
            [71.411884658000076, 33.966114135000055],
            [71.411946132000082, 33.964875949000032],
            [71.411947282000028, 33.964313239000035],
            [71.411948566000035, 33.963685221000048],
            [71.41194581700006, 33.963685203000068],
            [71.411974288000067, 33.962564297000029],
            [71.411984989000075, 33.962081167000065],
            [71.412086269000042, 33.961669414000028],
            [71.412208072000055, 33.961343874000079],
            [71.412318183000082, 33.961049582000044],
            [71.412540657000079, 33.960686658000043],
            [71.412958810000077, 33.960194415000046],
            [71.413218266000058, 33.959959147000063],
            [71.414021364000064, 33.959230912000066],
            [71.414200264000044, 33.959068689000048],
            [71.41578445600004, 33.957504702000051],
            [71.416482057000053, 33.956815997000035],
            [71.416712453000059, 33.956588540000041],
            [71.417109742000036, 33.956191746000059],
            [71.417455958000062, 33.955937306000067],
            [71.417790982000042, 33.955742326000063],
            [71.418057614000077, 33.955590873000062],
            [71.41812010700005, 33.955555376000063],
            [71.418377713000041, 33.955409411000062],
            [71.418695340000056, 33.955281318000061],
            [71.419054186000039, 33.955083285000057],
            [71.419485652000049, 33.95480471500008],
            [71.420136577000051, 33.954384453000046],
            [71.422432760000049, 33.952993512000035],
            [71.423246084000084, 33.952483923000045],
            [71.424275702000045, 33.951838815000031],
            [71.425013106000051, 33.951376794000055],
            [71.42518563200008, 33.951253709000071],
            [71.426569731000029, 33.950266256000077],
            [71.426962596000067, 33.94998722400004],
            [71.426972774000035, 33.949987235000037],
            [71.427308731000039, 33.949734147000072],
            [71.427817362000042, 33.949183102000063],
            [71.428418584000042, 33.948525002000054],
            [71.428430442000035, 33.948512023000035],
            [71.428904627000065, 33.947992977000069],
            [71.429193742000052, 33.947672653000041],
            [71.430031443000075, 33.946744525000042],
            [71.430378533000066, 33.946359967000035],
            [71.430477714000062, 33.946250080000027],
            [71.431750543000078, 33.944857209000077],
            [71.432390492000081, 33.944160568000029],
            [71.43271922200006, 33.94380271700004],
            [71.433051736000039, 33.943454389000067],
            [71.433314835000033, 33.943151688000057],
            [71.433613018000074, 33.94280862100004],
            [71.43381725100005, 33.942567798000027],
            [71.433831275000045, 33.942543091000061],
            [71.43413617200008, 33.942005909000045],
            [71.434216406000075, 33.941841148000037],
            [71.434316089000049, 33.94163235700006],
            [71.434367249000047, 33.941493653000066],
            [71.434547129000066, 33.940695599000037],
            [71.434697778000043, 33.939380641000071],
            [71.434776935000059, 33.938711934000025],
            [71.434830909000084, 33.938255971000046],
            [71.434939479000036, 33.937018705000071],
            [71.434937052000066, 33.936919247000048],
            [71.434957927000085, 33.936806009000065],
            [71.435027871000045, 33.936658913000031],
            [71.435220202000039, 33.936371376000068],
            [71.435419366000076, 33.936149279000063],
            [71.435537387000068, 33.936026077000065],
            [71.435587380000072, 33.936000398000033],
            [71.435861009000064, 33.935930596000048],
            [71.436025979000078, 33.935888512000076],
            [71.436390903000074, 33.935793874000069],
            [71.43659628000006, 33.935758290000024],
            [71.436949645000084, 33.935756661000028],
            [71.437238717000071, 33.935789494000062],
            [71.437279648000072, 33.935794143000066],
            [71.437460750000071, 33.935827686000039],
            [71.43758151000003, 33.935868344000028],
            [71.437890717000073, 33.936022472000047],
            [71.437979824000081, 33.936077905000047],
            [71.438393097000073, 33.936334998000063],
            [71.438993911000068, 33.936708759000055],
            [71.439240386000051, 33.93684429800004],
            [71.439362732000063, 33.936883925000075],
            [71.439422887000035, 33.936903296000025],
            [71.43950643800008, 33.936915377000048],
            [71.439595697000073, 33.936922548000041],
            [71.439746999000079, 33.93690072000004],
            [71.441109065000035, 33.936496031000047],
            [71.442621304000056, 33.936109257000055],
            [71.443693300000064, 33.935834686000078],
            [71.443934520000084, 33.93577498500008],
            [71.444456507000041, 33.935645796000074],
            [71.44591812300007, 33.935284052000043],
            [71.447021405000044, 33.935005571000033],
            [71.447864991000074, 33.934768684000062],
            [71.44833636900006, 33.934547102000067],
            [71.448924114000079, 33.934227373000056],
            [71.449154607000082, 33.934079470000029],
            [71.449260720000041, 33.934007628000074],
            [71.449343876000057, 33.933951328000035],
            [71.449526078000076, 33.933800399000063],
            [71.450040232000049, 33.933328097000071],
            [71.451524285000062, 33.931975522000073],
            [71.452358736000065, 33.93121499800003],
            [71.453204735000043, 33.930484407000051],
            [71.454171317000032, 33.929607005000037],
            [71.455125711000051, 33.92869384200003],
            [71.456155685000056, 33.927726417000031],
            [71.458347708000076, 33.925610246000076],
            [71.45942380200006, 33.924582883000028],
            [71.459869404000074, 33.924201577000076],
            [71.460471733000077, 33.923572771000067],
            [71.460558092000042, 33.92348557300005],
            [71.46064390600003, 33.923375513000053],
            [71.460700659000054, 33.923268849000067],
            [71.460700961000043, 33.923267828000064],
            [71.46077822400008, 33.923006771000075],
            [71.460837622000042, 33.922766327000033],
            [71.460869805000073, 33.922624287000076],
            [71.460867256000029, 33.922568888000058],
            [71.460863619000065, 33.922489835000079],
            [71.460811026000044, 33.922006840000051],
            [71.460772252000083, 33.921247211000036],
            [71.460683688000074, 33.920515479000073],
            [71.460628512000028, 33.919785626000078],
            [71.46058425700005, 33.919248362000076],
            [71.460538616000065, 33.918843883000079],
            [71.460506842000029, 33.918434601000058],
            [71.460479916000054, 33.917735901000071],
            [71.460436888000061, 33.916974217000075],
            [71.460382313000082, 33.916458491000071],
            [71.460338877000083, 33.915953754000043],
            [71.460304850000057, 33.91537231500007],
            [71.460236498000029, 33.915336901000046],
            [71.458835782000051, 33.914604810000071],
            [71.457840237000084, 33.914040574000069],
            [71.458115914000075, 33.913649627000041],
            [71.458800790000055, 33.912684646000059],
            [71.459476686000073, 33.911739433000037],
            [71.459881577000033, 33.91122869000003],
            [71.460210975000052, 33.910858290000078],
            [71.460455447000072, 33.910655215000077],
            [71.460484476000033, 33.91063095100003],
            [71.460611523000068, 33.910524756000029],
            [71.460379000000046, 33.910337000000027],
            [71.460295089000056, 33.910227715000076],
            [71.460002000000031, 33.909846000000073],
            [71.459372666000036, 33.909058616000038],
            [71.459119000000044, 33.908493000000078],
            [71.458867000000055, 33.908211000000051],
            [71.458500000000072, 33.908034000000043],
            [71.457822000000078, 33.907897000000048],
            [71.456631000000073, 33.908025000000066],
            [71.456371972000056, 33.908029415000044],
            [71.45541515900004, 33.908045724000033],
            [71.454631000000063, 33.907978000000071],
            [71.454138000000057, 33.907745000000034],
            [71.45348000000007, 33.907439000000068],
            [71.453112000000033, 33.907366000000025],
            [71.452085000000068, 33.907655000000034],
            [71.451650000000029, 33.907695000000047],
            [71.451101855000047, 33.907564964000073],
            [71.450533000000064, 33.907278000000076],
            [71.449962000000085, 33.907020000000045],
            [71.449139000000059, 33.906867000000034],
            [71.448610387000031, 33.906699187000072],
            [71.448355000000049, 33.906569000000047],
            [71.448118811000029, 33.906258612000045],
            [71.448075000000074, 33.906127000000026],
            [71.448240000000055, 33.90587800000003],
            [71.449112000000071, 33.905212000000063],
            [71.449267000000077, 33.905003000000079],
            [71.449316000000067, 33.904826000000071],
            [71.449281099000075, 33.904591563000054],
            [71.44899300000003, 33.904361000000051],
            [71.44823800000006, 33.904087000000061],
            [71.447471588000042, 33.903987657000073],
            [71.447116363000077, 33.903873558000043],
            [71.446341000000075, 33.903154000000029],
            [71.445757368000045, 33.902707071000066],
            [71.445172000000071, 33.902329000000066],
            [71.444378000000029, 33.902248000000043],
            [71.444059000000038, 33.902231000000029],
            [71.442345270000033, 33.902525666000031],
            [71.442010000000039, 33.902479000000028],
            [71.441653000000031, 33.902213000000074],
            [71.441413911000041, 33.90191118000007],
            [71.441305000000057, 33.901626000000078],
            [71.441237000000058, 33.901361000000065],
            [71.441092000000083, 33.901160000000061],
            [71.440911583000059, 33.900915475000033],
            [71.440505287000065, 33.900418174000038],
            [71.440010283000049, 33.899775760000068],
            [71.439743896000039, 33.899569292000024],
            [71.439261591000047, 33.899532160000035],
            [71.438938764000056, 33.899474425000051],
            [71.43862865400007, 33.899185512000031],
            [71.438414148000049, 33.899034730000039],
            [71.43830156000007, 33.898955590000071],
            [71.437482135000039, 33.898764780000079],
            [71.436902996000072, 33.898670866000032],
            [71.435754632000055, 33.898176259000024],
            [71.434866000000056, 33.897201000000052],
            [71.434634000000074, 33.896807000000024],
            [71.434083000000044, 33.896220000000028],
            [71.433415000000082, 33.895906000000025],
            [71.432671000000084, 33.895359000000042],
            [71.43234654400004, 33.895100866000064],
            [71.431663348000029, 33.894886620000079],
            [71.431166102000077, 33.89495848100006],
            [71.43065484300007, 33.895015232000048],
            [71.429992021000032, 33.895005594000054],
            [71.429076405000046, 33.895005874000049],
            [71.428084467000076, 33.895066728000074],
            [71.427441000000044, 33.895356000000049],
            [71.426746102000038, 33.895649079000066],
            [71.426092460000064, 33.895813464000071],
            [71.425167377000037, 33.895879411000067],
            [71.424886127000036, 33.895746003000056],
            [71.424741623000045, 33.895558224000069],
            [71.424605000000042, 33.895144000000073],
            [71.424498000000028, 33.894856000000061],
            [71.424363000000028, 33.894743000000062],
            [71.424034000000063, 33.894727000000046],
            [71.423433000000045, 33.894807000000071],
            [71.42325900000003, 33.894758000000024],
            [71.423135085000069, 33.894669390000047],
            [71.42289100000005, 33.894662000000039],
            [71.422485000000052, 33.894460000000038],
            [71.422273000000075, 33.894243000000074],
            [71.422041000000036, 33.893680000000074],
            [71.421780000000069, 33.893286000000046],
            [71.42154800000003, 33.893053000000066],
            [71.421171000000072, 33.892876000000058],
            [71.420726000000059, 33.892779000000075],
            [71.419940483000062, 33.89260112200003],
            [71.418946000000062, 33.892175000000066],
            [71.417848000000049, 33.891589000000067],
            [71.417209000000071, 33.891195000000039],
            [71.416803000000073, 33.891090000000077],
            [71.416464000000076, 33.890961000000061],
            [71.416236703000038, 33.890750110000056],
            [71.41608448900007, 33.890343877000078],
            [71.415756918000056, 33.88987824000003],
            [71.415341000000069, 33.889436000000046],
            [71.415061000000037, 33.889227000000062],
            [71.41472200000004, 33.889170000000036],
            [71.413898633000031, 33.889029074000064],
            [71.413058000000035, 33.888871000000051],
            [71.412013282000032, 33.888480027000071],
            [71.411498831000074, 33.888405229000057],
            [71.411007000000041, 33.888314000000037],
            [71.410593791000053, 33.888280749000046],
            [71.410286118000045, 33.888138549000075],
            [71.409773542000039, 33.887705296000036],
            [71.409620000000075, 33.887369000000035],
            [71.409398000000067, 33.886782000000039],
            [71.409331000000066, 33.886396000000047],
            [71.40935236200005, 33.886014003000071],
            [71.409217000000069, 33.885255000000029],
            [71.40906300000006, 33.884668000000033],
            [71.408996000000059, 33.884135000000072],
            [71.408784000000082, 33.883693000000051],
            [71.408156000000076, 33.883178000000044],
            [71.40776900000003, 33.882856000000061],
            [71.407412000000079, 33.882470000000069],
            [71.407158000000038, 33.882174000000077],
            [71.406779475000064, 33.881939880000061],
            [71.406394340000077, 33.88171619700006],
            [71.405992687000037, 33.881485135000048],
            [71.405725602000075, 33.881265180000071],
            [71.405399000000045, 33.881031000000064],
            [71.405092560000071, 33.880731729000047],
            [71.404625143000032, 33.880506067000056],
            [71.404171000000076, 33.880347000000029],
            [71.403948000000071, 33.880282000000079],
            [71.403765000000078, 33.880137000000047],
            [71.403687000000048, 33.879969000000074],
            [71.403151115000071, 33.879576920000034],
            [71.402927691000059, 33.879124954000076],
            [71.402738963000047, 33.878859803000068],
            [71.402513000000056, 33.878486000000066],
            [71.40197100000006, 33.878027000000031],
            [71.402052000000083, 33.877614000000051],
            [71.402092000000039, 33.877341000000058],
            [71.402121000000079, 33.877060000000029],
            [71.402077030000044, 33.87688496800007],
            [71.401793000000055, 33.876642000000061],
            [71.40136700000005, 33.876312000000041],
            [71.401252000000056, 33.876087000000041],
            [71.410649798000065, 33.872703327000067],
            [71.410687065000047, 33.872659640000052],
            [71.411151294000035, 33.872327606000056],
            [71.411742188000062, 33.871882721000077],
            [71.412280895000038, 33.871497482000052],
            [71.412448224000059, 33.871357117000059],
            [71.412494628000047, 33.871319686000049],
            [71.412543869000046, 33.871302118000074],
            [71.412591883000061, 33.871295327000041],
            [71.412990133000051, 33.871235419000072],
            [71.413626889000057, 33.871137412000053],
            [71.414182268000047, 33.871077371000069],
            [71.414529590000029, 33.871037629000057],
            [71.415023448000056, 33.87097529600004],
            [71.415491084000053, 33.870922331000031],
            [71.415821418000064, 33.870884664000073],
            [71.416202321000071, 33.87084578200006],
            [71.416851115000043, 33.870757427000058],
            [71.417819964000046, 33.870616741000049],
            [71.417963562000068, 33.870596747000036],
            [71.41827937000005, 33.870536824000055],
            [71.419126276000043, 33.870400922000044],
            [71.419607921000079, 33.870317484000054],
            [71.420241538000084, 33.87020523700005],
            [71.420906374000083, 33.87009861000007],
            [71.421446800000069, 33.870009271000072],
            [71.422335280000084, 33.869869512000037],
            [71.423295513000085, 33.869715223000071],
            [71.42416729200005, 33.869579983000051],
            [71.424868826000079, 33.869458763000068],
            [71.425991873000044, 33.869285562000073],
            [71.426478419000034, 33.869211585000073],
            [71.427058242000044, 33.869118307000065],
            [71.427380650000032, 33.869054806000065],
            [71.427639472000067, 33.86901860100005],
            [71.428007391000051, 33.868967846000032],
            [71.428501616000062, 33.868888019000053],
            [71.428744990000041, 33.868849904000058],
            [71.429039197000066, 33.868803828000068],
            [71.429356169000073, 33.868748074000052],
            [71.429800206000039, 33.868671315000029],
            [71.430036391000044, 33.868633429000056],
            [71.430209240000067, 33.868612760000076],
            [71.430415763000042, 33.86858395400003],
            [71.430549023000083, 33.868558429000075],
            [71.430883631000029, 33.868498402000057],
            [71.431077069000082, 33.868467043000066],
            [71.431275174000064, 33.86843470000008],
            [71.431447097000046, 33.868411195000078],
            [71.43170830300005, 33.868390326000053],
            [71.431932416000052, 33.868367229000057],
            [71.432405774000074, 33.868332078000037],
            [71.432859296000061, 33.868301479000024],
            [71.433365101000049, 33.868264985000053],
            [71.433841867000069, 33.868222508000031],
            [71.434249152000064, 33.86819283400007],
            [71.434695374000057, 33.868157286000041],
            [71.435022189000051, 33.868131070000061],
            [71.435327832000041, 33.868109075000064],
            [71.435588525000071, 33.868087083000034],
            [71.435791522000045, 33.868068030000074],
            [71.435991407000074, 33.868042650000064],
            [71.43616964000006, 33.868008798000062],
            [71.436363868000058, 33.867966351000064],
            [71.436564236000038, 33.867914713000062],
            [71.436767589000056, 33.867850322000038],
            [71.43710821600007, 33.867731683000045],
            [71.437282706000076, 33.86765667800006],
            [71.437518805000082, 33.867562484000075],
            [71.437756369000056, 33.867435379000028],
            [71.437918060000072, 33.867340471000034],
            [71.438056730000028, 33.867240967000043],
            [71.438260098000057, 33.867108087000076],
            [71.438454652000075, 33.866963723000026],
            [71.438604603000044, 33.866836803000069],
            [71.439015728000072, 33.866431281000075],
            [71.439452981000045, 33.865991296000061],
            [71.439918448000071, 33.865524939000068],
            [71.440321008000069, 33.865116031000071],
            [71.440643248000072, 33.864792359000035],
            [71.440851696000038, 33.864571677000072],
            [71.440926434000062, 33.864514260000078],
            [71.441010262000077, 33.864458115000048],
            [71.441012919000059, 33.864455916000054],
            [71.44109521200005, 33.86439989400003],
            [71.441157687000043, 33.864347013000042],
            [71.441193883000039, 33.864276317000076],
            [71.44122715900005, 33.86420580500004],
            [71.441261721000046, 33.864141964000055],
            [71.44164551800003, 33.863743955000075],
            [71.441959663000034, 33.863440023000067],
            [71.442248043000063, 33.863147165000044],
            [71.442799318000084, 33.862583306000033],
            [71.443372224000029, 33.861998163000067],
            [71.443736024000032, 33.861614571000075],
            [71.444206690000044, 33.861160455000061],
            [71.444568446000062, 33.860789992000036],
            [71.444854934000034, 33.860500000000059],
            [71.445012057000042, 33.860342944000024],
            [71.445204448000084, 33.860174855000025],
            [71.445361442000035, 33.860049265000043],
            [71.445866293000051, 33.859675469000024],
            [71.446803212000077, 33.859019289000059],
            [71.447205714000063, 33.85873265500004],
            [71.447806247000074, 33.858288404000064],
            [71.448105418000068, 33.858071604000031],
            [71.448327850000055, 33.857899831000054],
            [71.448615725000082, 33.857700184000066],
            [71.449298549000048, 33.857224083000062],
            [71.449818661000052, 33.856837019000068],
            [71.450387999000043, 33.85640896600006],
            [71.450706593000064, 33.856181867000032],
            [71.450888174000056, 33.856055082000069],
            [71.45109192700005, 33.855925938000041],
            [71.451479526000071, 33.855700587000058],
            [71.451822485000037, 33.85550697900004],
            [71.451962248000029, 33.855431979000059],
            [71.452631518000032, 33.855147621000071],
            [71.453320820000044, 33.854864700000064],
            [71.453958919000058, 33.854596130000061],
            [71.454671726000072, 33.854288152000038],
            [71.455348769000068, 33.854015731000061],
            [71.455993557000056, 33.853737763000026],
            [71.456677939000031, 33.853452446000063],
            [71.457107050000047, 33.853280706000078],
            [71.457812419000049, 33.852970513000059],
            [71.458044025000049, 33.852871608000044],
            [71.458445724000057, 33.852693836000071],
            [71.458738528000083, 33.852544660000035],
            [71.459001577000038, 33.852388234000045],
            [71.459397065000076, 33.852115001000072],
            [71.459879343000068, 33.851731764000078],
            [71.460401379000075, 33.851307733000056],
            [71.460864628000081, 33.850926875000027],
            [71.461298726000052, 33.85058030700003],
            [71.461664985000084, 33.850295560000063],
            [71.461779945000046, 33.850206185000047],
            [71.462103665000029, 33.849938163000047],
            [71.462554357000045, 33.849573705000068],
            [71.462773793000054, 33.849408132000065],
            [71.463370478000058, 33.84891520900004],
            [71.463615582000045, 33.848737510000035],
            [71.463838219000081, 33.848591233000036],
            [71.464119966000055, 33.848421526000038],
            [71.464323867000076, 33.848300300000062],
            [71.464527353000051, 33.848200616000042],
            [71.464728020000052, 33.848103742000035],
            [71.465057737000052, 33.847963735000064],
            [71.465379856000084, 33.847861481000052],
            [71.465891502000034, 33.84769698100007],
            [71.466150873000061, 33.847617564000075],
            [71.466408856000044, 33.84755120300008],
            [71.466818170000067, 33.847443666000061],
            [71.467312557000071, 33.847290345000033],
            [71.467762742000048, 33.847128076000047],
            [71.467990016000044, 33.847037536000073],
            [71.468362093000053, 33.846851604000051],
            [71.469127416000049, 33.846487394000064],
            [71.47011707200005, 33.845984760000078],
            [71.470546259000059, 33.84574615300005],
            [71.471093314000029, 33.845487630000036],
            [71.471481308000079, 33.845287941000038],
            [71.471844357000066, 33.845099221000055],
            [71.472135598000079, 33.844915809000042],
            [71.472535658000083, 33.844626762000075],
            [71.472882516000084, 33.844355362000044],
            [71.473098372000038, 33.844184453000025],
            [71.473366118000058, 33.843909193000059],
            [71.473558909000076, 33.843680509000023],
            [71.473864574000061, 33.843301283000073],
            [71.473975575000054, 33.843146963000038],
            [71.47409474400007, 33.842972022000026],
            [71.474187696000058, 33.842817324000066],
            [71.474351464000051, 33.842449108000039],
            [71.474676540000075, 33.841705276000027],
            [71.474784602000057, 33.841470750000042],
            [71.474895244000038, 33.841230623000058],
            [71.475069026000028, 33.840853466000056],
            [71.475420521000046, 33.840068938000059],
            [71.475743948000058, 33.839335525000024],
            [71.476066121000031, 33.838605506000079],
            [71.47637678600006, 33.837916755000037],
            [71.476629499000069, 33.837356638000074],
            [71.476821976000053, 33.836998403000052],
            [71.476901252000062, 33.836846213000058],
            [71.476919941000062, 33.836790286000053],
            [71.476917668000056, 33.836732161000043],
            [71.476904375000061, 33.836650795000025],
            [71.476875003000032, 33.836554804000059],
            [71.476822779000031, 33.836423797000066],
            [71.476768058000062, 33.836304203000054],
            [71.476701444000071, 33.836198767000042],
            [71.476598591000084, 33.836055465000072],
            [71.476479103000031, 33.83590791000006],
            [71.476408380000066, 33.835814061000065],
            [71.476352790000078, 33.835736843000063],
            [71.476314648000084, 33.835668621000025],
            [71.476290318000054, 33.835612661000027],
            [71.476270904000046, 33.835544628000036],
            [71.476259060000075, 33.835483444000033],
            [71.47626137900005, 33.835423165000066],
            [71.476275551000072, 33.835380397000051],
            [71.476294945000063, 33.835348270000054],
            [71.476329943000053, 33.835322554000072],
            [71.476371507000067, 33.835300023000059],
            [71.476424866000059, 33.835280693000072],
            [71.476496729000075, 33.835253785000077],
            [71.476618428000052, 33.835213715000066],
            [71.476786172000061, 33.835157707000064],
            [71.476866440000038, 33.835118480000062],
            [71.476908152000078, 33.835082162000049],
            [71.476931162000028, 33.835045687000047],
            [71.476944778000075, 33.83501134200003],
            [71.477079273000072, 33.834516695000048],
            [71.477238267000075, 33.834080328000027],
            [71.47735384300006, 33.83379233200003],
            [71.477410681000038, 33.833713180000075],
            [71.477482659000032, 33.833652101000041],
            [71.477613427000051, 33.833560374000058],
            [71.477949915000067, 33.833307230000059],
            [71.478277949000073, 33.833080307000046],
            [71.478478840000037, 33.832959583000047],
            [71.478652624000063, 33.832868652000059],
            [71.478813900000034, 33.832786175000024],
            [71.478954989000044, 33.832735218000039],
            [71.479124363000039, 33.83268700900004],
            [71.479334950000066, 33.832647167000061],
            [71.479523660000041, 33.83261553400007],
            [71.479750620000061, 33.832567100000063],
            [71.480098944000076, 33.832475540000075],
            [71.480288405000067, 33.832420400000046],
            [71.480507620000083, 33.832344663000072],
            [71.480653742000072, 33.832299015000046],
            [71.480786481000052, 33.832259099000055],
            [71.480939689000081, 33.832227603000035],
            [71.481060740000032, 33.832216519000042],
            [71.481164963000083, 33.832212285000026],
            [71.481278582000073, 33.832220299000028],
            [71.481377102000067, 33.832242059000066],
            [71.48148347800003, 33.832274720000044],
            [71.481591971000057, 33.832305904000066],
            [71.48173115000003, 33.83233410400004],
            [71.481832160000067, 33.832337734000077],
            [71.481915472000082, 33.832336274000056],
            [71.48197478700007, 33.832322629000032],
            [71.482036974000039, 33.83229943200007],
            [71.482110469000077, 33.832244904000049],
            [71.482180489000029, 33.832179620000034],
            [71.482264733000079, 33.832069833000048],
            [71.482384614000068, 33.831888116000073],
            [71.482627704000038, 33.831511185000068],
            [71.482848551000075, 33.831171809000068],
            [71.483012615000064, 33.830934201000048],
            [71.483128183000076, 33.830748683000024],
            [71.483312640000065, 33.830479467000032],
            [71.483454533000042, 33.830254421000063],
            [71.483571306000044, 33.83006326800006],
            [71.483624338000084, 33.82995943800006],
            [71.483669530000043, 33.829873869000039],
            [71.483728787000075, 33.82979225400004],
            [71.483819574000051, 33.829699593000043],
            [71.483935122000048, 33.829614105000076],
            [71.484066552000058, 33.829545340000038],
            [71.484215616000029, 33.829473774000064],
            [71.484451699000033, 33.829384681000079],
            [71.484667714000068, 33.829307491000066],
            [71.484774088000052, 33.829268457000069],
            [71.484913124000059, 33.829213634000041],
            [71.485002011000063, 33.829184809000026],
            [71.485088026000028, 33.829163792000031],
            [71.485161531000074, 33.829157231000067],
            [71.485303767000062, 33.829150675000051],
            [71.485763492000046, 33.829150847000051],
            [71.486101614000063, 33.829136237000057],
            [71.486327591000077, 33.829132254000058],
            [71.486432803000071, 33.829137651000053],
            [71.486583809000081, 33.829155198000024],
            [71.487045587000068, 33.829227908000064],
            [71.487178533000076, 33.829246811000075],
            [71.487300161000064, 33.829252250000025],
            [71.487371556000028, 33.829245538000066],
            [71.487438227000041, 33.829228019000027],
            [71.487577006000038, 33.829172414000027],
            [71.48830130500005, 33.828824406000024],
            [71.48879061100007, 33.828598480000039],
            [71.48946356700003, 33.828295163000064],
            [71.489978554000061, 33.82806503300003],
            [71.490400426000065, 33.827863360000038],
            [71.490738084000043, 33.827719220000063],
            [71.491408024000066, 33.827486537000027],
            [71.492101909000041, 33.827267256000027],
            [71.492579312000032, 33.827119938000067],
            [71.492694576000076, 33.82708437000008],
            [71.493198335000045, 33.826934814000026],
            [71.493497625000032, 33.826842695000039],
            [71.493951541000058, 33.826694514000053],
            [71.494283560000042, 33.826592216000051],
            [71.494716554000036, 33.826474800000028],
            [71.495003512000039, 33.826399410000079],
            [71.495262687000036, 33.826328974000035],
            [71.495569816000057, 33.826238199000045],
            [71.495727816000056, 33.82617207100003],
            [71.495848555000066, 33.826112715000079],
            [71.495950246000064, 33.826051288000031],
            [71.496054828000069, 33.825966946000051],
            [71.496179951000045, 33.825855818000036],
            [71.496309266000083, 33.82573525600003],
            [71.496419296000056, 33.825627642000029],
            [71.496518776000073, 33.825543946000039],
            [71.496604284000057, 33.825482093000062],
            [71.496695251000062, 33.825437127000043],
            [71.496816764000073, 33.825388992000057],
            [71.496976975000052, 33.825319075000039],
            [71.497063229000048, 33.825276502000065],
            [71.497126760000072, 33.825236374000042],
            [71.497181913000077, 33.825182599000073],
            [71.49724240900008, 33.825102737000066],
            [71.497315231000073, 33.824998969000035],
            [71.497445719000041, 33.824803145000033],
            [71.497526728000082, 33.824716519000049],
            [71.497577387000035, 33.824682401000075],
            [71.497666280000033, 33.82464040900004],
            [71.497781002000067, 33.824599644000045],
            [71.497925580000071, 33.824560193000025],
            [71.498074460000055, 33.824511461000043],
            [71.498217408000073, 33.824444765000067],
            [71.498360149000064, 33.824372367000024],
            [71.498697848000063, 33.82416414100004],
            [71.498863232000076, 33.824046042000077],
            [71.498988069000063, 33.82395083800003],
            [71.499339399000064, 33.823659701000054],
            [71.499686385000075, 33.82338212600007],
            [71.499976479000054, 33.823174292000033],
            [71.500241584000037, 33.823003633000042],
            [71.500375144000031, 33.822922615000039],
            [71.500438079000048, 33.822869040000057],
            [71.500492080000072, 33.822803037000028],
            [71.500567556000078, 33.822686972000042],
            [71.500641083000062, 33.822581229000036],
            [71.500722039000038, 33.822472585000071],
            [71.500844119000078, 33.822352686000045],
            [71.500975691000065, 33.822244901000033],
            [71.501139568000042, 33.822133208000025],
            [71.501266756000064, 33.822059114000069],
            [71.501492030000065, 33.821935333000056],
            [71.50160906900004, 33.82185983200003],
            [71.501743273000045, 33.821754981000026],
            [71.501847026000064, 33.821671448000075],
            [71.502063546000045, 33.821454900000049],
            [71.502354595000043, 33.821148609000034],
            [71.502519279000069, 33.82096524800005],
            [71.502618116000065, 33.82085322100005],
            [71.502709890000062, 33.820733532000077],
            [71.502767565000056, 33.820640143000048],
            [71.502801715000032, 33.820562794000068],
            [71.502849766000054, 33.820437841000057],
            [71.502889971000059, 33.820322257000043],
            [71.502922291000061, 33.820207775000029],
            [71.502960963000078, 33.820079526000029],
            [71.502989199000069, 33.82001454300007],
            [71.503019835000032, 33.819973653000034],
            [71.503060019000031, 33.819943324000064],
            [71.503336165000064, 33.819770004000077],
            [71.50373800300008, 33.819538119000072],
            [71.50432061600003, 33.819191295000053],
            [71.504719474000069, 33.818943890000071],
            [71.505115463000038, 33.818698209000047],
            [71.505424865000066, 33.81851075700007],
            [71.505550672000084, 33.818439949000037],
            [71.505643810000038, 33.81837511100008],
            [71.505735112000082, 33.818292850000034],
            [71.506094546000043, 33.817975378000028],
            [71.50643763000005, 33.817659139000057],
            [71.506770365000079, 33.817356385000039],
            [71.507087830000046, 33.817085882000072],
            [71.507291418000079, 33.816928348000033],
            [71.507335596000075, 33.81687865400005],
            [71.507373303000065, 33.816839074000029],
            [71.507421708000038, 33.816772297000057],
            [71.507466685000054, 33.816691727000034],
            [71.50749660300005, 33.816625147000025],
            [71.507516276000047, 33.816539319000071],
            [71.507525871000041, 33.816456532000075],
            [71.507553707000056, 33.815840594000065],
            [71.507580795000081, 33.815499775000035],
            [71.507591363000074, 33.815318206000029],
            [71.507611531000066, 33.815236341000059],
            [71.507637322000051, 33.815177776000041],
            [71.507679777000078, 33.815116223000075],
            [71.507786488000079, 33.815027817000043],
            [71.507892139000035, 33.814955484000052],
            [71.508082352000031, 33.814846629000044],
            [71.508374084000081, 33.814690838000047],
            [71.508509934000074, 33.814622847000066],
            [71.508651549000035, 33.814560952000079],
            [71.50904848600004, 33.814411549000056],
            [71.509273225000072, 33.814331373000073],
            [71.509840451000059, 33.814156766000053],
            [71.510011622000036, 33.814103142000079],
            [71.510273475000076, 33.814024321000034],
            [71.510392372000069, 33.813969796000038],
            [71.510466764000057, 33.813924237000037],
            [71.510553360000074, 33.813857733000077],
            [71.510635775000083, 33.813764864000063],
            [71.51104108800007, 33.813290409000047],
            [71.511534889000075, 33.812728401000072],
            [71.511772545000042, 33.812456290000057],
            [71.511895877000029, 33.812315425000065],
            [71.511988884000061, 33.81224056700006],
            [71.512094232000038, 33.812175291000074],
            [71.512176157000056, 33.812130493000041],
            [71.512273969000034, 33.81208908900004],
            [71.512983290000079, 33.811870825000028],
            [71.513231528000063, 33.811786798000071],
            [71.513457810000034, 33.81169720500003],
            [71.513592761000041, 33.811631430000034],
            [71.513706332000083, 33.811565251000047],
            [71.513791784000034, 33.811510646000045],
            [71.514326289000053, 33.811107493000065],
            [71.514611824000042, 33.810890950000044],
            [71.514802295000038, 33.810741753000059],
            [71.514894205000076, 33.810677247000058],
            [71.514980582000078, 33.810626078000041],
            [71.515073779000033, 33.81059110800004],
            [71.515158025000062, 33.810579540000049],
            [71.515275389000067, 33.81057095400007],
            [71.51547669100006, 33.810559614000056],
            [71.515961931000049, 33.810543314000029],
            [71.516252897000072, 33.810520698000062],
            [71.516530774000046, 33.810505654000053],
            [71.51685116200008, 33.81048039600006],
            [71.517022694000048, 33.810468989000071],
            [71.51722871700008, 33.810462693000034],
            [71.517497787000082, 33.810440605000053],
            [71.517733065000073, 33.810416690000068],
            [71.517915541000036, 33.810385084000075],
            [71.518048563000036, 33.810358187000077],
            [71.518166618000066, 33.810325216000024],
            [71.51831560100004, 33.810269245000029],
            [71.518483291000052, 33.810191470000063],
            [71.51858863700005, 33.810135064000065],
            [71.518679182000028, 33.810076228000071],
            [71.518770766000046, 33.809993158000054],
            [71.51888083700004, 33.809887091000064],
            [71.519328020000046, 33.80940548500007],
            [71.519735089000051, 33.808958890000042],
            [71.51922200000007, 33.805535000000077],
            [71.51060700000005, 33.77836700000006],
            [71.507472707000034, 33.772933784000031],
            [71.504180722000058, 33.76722721200008],
            [71.500668000000076, 33.761138000000074],
            [71.503020668000033, 33.751335612000048],
            [71.503020402000061, 33.751335485000027],
            [71.502967822000073, 33.751289591000045],
            [71.502649235000035, 33.75098119200004],
            [71.502453416000037, 33.750936687000035],
            [71.501853792000077, 33.751079455000024],
            [71.501311275000035, 33.751193669000031],
            [71.500654544000042, 33.751250776000063],
            [71.500112026000068, 33.75136499000007],
            [71.499455295000075, 33.751393544000052],
            [71.498912778000033, 33.751393544000052],
            [71.49779919000008, 33.751393544000052],
            [71.496856924000042, 33.75136499000007],
            [71.495857550000039, 33.75136499000007],
            [71.495058051000058, 33.75136499000007],
            [71.494201445000044, 33.751450651000027],
            [71.492802322000045, 33.751736186000073],
            [71.491917163000039, 33.751793293000048],
            [71.491060557000083, 33.751964615000077],
            [71.490261058000044, 33.75199316800007],
            [71.489604327000052, 33.751850400000023],
            [71.489176024000074, 33.751764740000056],
            [71.488576400000056, 33.751450651000027],
            [71.488148097000078, 33.751250776000063],
            [71.487605580000036, 33.750765366000053],
            [71.487091616000043, 33.750479831000064],
            [71.486177903000055, 33.750365617000057],
            [71.485663940000052, 33.750365617000057],
            [71.485035762000052, 33.750479831000064],
            [71.484264816000064, 33.750651152000046],
            [71.483693746000029, 33.750679706000028],
            [71.483037015000036, 33.750622599000053],
            [71.482665819000033, 33.750508384000057],
            [71.482659820000038, 33.750501463000035],
            [71.482658487000037, 33.75050105300005],
            [71.482287291000034, 33.750072750000072],
            [71.48194464900007, 33.74967300000003],
            [71.481464505000076, 33.749079882000046],
            [71.480895500000031, 33.748852280000051],
            [71.480238769000039, 33.748395423000034],
            [71.479867573000035, 33.748252656000034],
            [71.479210841000054, 33.748252656000034],
            [71.478554110000061, 33.748309763000066],
            [71.478040147000058, 33.748395423000034],
            [71.477554737000048, 33.74868095800008],
            [71.476898005000066, 33.74868095800008],
            [71.476155614000049, 33.74868095800008],
            [71.475841525000078, 33.748481084000048],
            [71.475299008000036, 33.748224102000052],
            [71.474842151000075, 33.748195548000069],
            [71.474356741000065, 33.748338316000059],
            [71.474014099000044, 33.748566744000073],
            [71.473471582000059, 33.748738066000044],
            [71.472729190000052, 33.748795173000076],
            [71.472329440000067, 33.749052154000026],
            [71.47207245900006, 33.749280583000029],
            [71.471472834000053, 33.749937314000078],
            [71.470987424000043, 33.750194296000075],
            [71.470159372000069, 33.750422724000032],
            [71.469388427000069, 33.750422724000032],
            [71.468788802000063, 33.750194296000075],
            [71.468782930000032, 33.750187520000054],
            [71.468781471000057, 33.750186964000079],
            [71.46841659100005, 33.74976594900005],
            [71.467760875000067, 33.749737439000057],
            [71.466961376000029, 33.74976599300004],
            [71.466304645000037, 33.749823100000071],
            [71.465590807000069, 33.749794546000032],
            [71.464905522000038, 33.749737439000057],
            [71.464277344000038, 33.749651779000033],
            [71.463649167000028, 33.749651779000033],
            [71.462764007000033, 33.749708886000064],
            [71.461964508000051, 33.749823100000071],
            [71.461279224000066, 33.750165742000036],
            [71.460336957000038, 33.750565492000078],
            [71.459537458000057, 33.750908134000042],
            [71.45865229900005, 33.750993795000056],
            [71.457767139000055, 33.751022348000049],
            [71.456824873000073, 33.751050902000031],
            [71.45596826700006, 33.751050902000031],
            [71.455397196000035, 33.751279330000045],
            [71.453855305000047, 33.75199316800007],
            [71.452855932000034, 33.752364364000073],
            [71.452113540000084, 33.752649899000062],
            [71.451571023000042, 33.752878328000065],
            [71.450628756000071, 33.753449398000043],
            [71.449914918000047, 33.753734934000079],
            [71.449058312000034, 33.753763487000072],
            [71.448287367000034, 33.75370638000004],
            [71.447373654000046, 33.753392291000068],
            [71.446374280000043, 33.752821221000033],
            [71.445489121000037, 33.752421471000048],
            [71.444689622000055, 33.752421471000048],
            [71.443975783000042, 33.752564239000037],
            [71.443261945000074, 33.752678453000044],
            [71.442662321000057, 33.752878328000065],
            [71.441948483000033, 33.752878328000065],
            [71.441491626000072, 33.752878328000065],
            [71.440920556000037, 33.752764113000069],
            [71.44034948500007, 33.752421471000048],
            [71.439778414000045, 33.75199316800007],
            [71.438950362000071, 33.751679079000041],
            [71.43812231000004, 33.751507758000059],
            [71.437437025000065, 33.751250776000063],
            [71.436037902000066, 33.75087958000006],
            [71.435067082000046, 33.750536938000039],
            [71.434381797000071, 33.750536938000039],
            [71.433753619000072, 33.750565492000078],
            [71.433296763000044, 33.750851027000067],
            [71.432611478000069, 33.75122222300007],
            [71.432211729000073, 33.751707633000024],
            [71.43184053300007, 33.752250150000066],
            [71.431469337000067, 33.752792667000051],
            [71.431212355000071, 33.753278077000061],
            [71.431012480000049, 33.753849148000029],
            [71.430612731000053, 33.754306004000057],
            [71.430070214000068, 33.754334558000039],
            [71.429327822000062, 33.754134683000075],
            [71.428299895000066, 33.753963362000036],
            [71.427500396000084, 33.75356361200005],
            [71.426643790000071, 33.753106756000079],
            [71.424416614000052, 33.751764740000056],
            [71.422674849000032, 33.750793920000035],
            [71.421846797000057, 33.750594045000071],
            [71.421190065000076, 33.750194296000075],
            [71.420590441000058, 33.749708886000064],
            [71.419733835000045, 33.749194922000072],
            [71.419105658000035, 33.748823726000069],
            [71.418705908000049, 33.74868095800008],
            [71.418249052000078, 33.74868095800008],
            [71.417763642000068, 33.748795173000076],
            [71.417363892000083, 33.749023601000033],
            [71.416792821000058, 33.749280583000029],
            [71.41622175100008, 33.749309136000079],
            [71.415336591000084, 33.749309136000079],
            [71.414651307000042, 33.749252029000047],
            [71.413851808000061, 33.749252029000047],
            [71.413452058000075, 33.749394797000036],
            [71.413052309000079, 33.74976599300004],
            [71.412624006000044, 33.750365617000057],
            [71.412110042000052, 33.750822473000028],
            [71.411510418000034, 33.751079455000024],
            [71.410568152000053, 33.751079455000024],
            [71.409740099000032, 33.751079455000024],
            [71.408940600000051, 33.750993795000056],
            [71.407455817000084, 33.750708259000078],
            [71.405999587000053, 33.750651152000046],
            [71.40514298100004, 33.750679706000028],
            [71.404486249000058, 33.750851027000067],
            [71.403629643000045, 33.751165116000038],
            [71.402715930000056, 33.751450651000027],
            [71.401602343000036, 33.751679079000041],
            [71.400745737000079, 33.751964615000077],
            [71.399660703000052, 33.751878954000063],
            [71.398432901000035, 33.751821847000031],
            [71.397633402000054, 33.751593419000073],
            [71.397033778000036, 33.751422098000035],
            [71.39631993900008, 33.751307883000038],
            [71.39554899400008, 33.751307883000038],
            [71.394720942000049, 33.751422098000035],
            [71.393664461000071, 33.751707633000024],
            [71.39249376600003, 33.752107382000077],
            [71.39112319700007, 33.75259279200003],
            [71.389638413000057, 33.753220970000029],
            [71.388210736000076, 33.753763487000072],
            [71.386754506000045, 33.754306004000057],
            [71.385698026000057, 33.754591540000035],
            [71.384755759000029, 33.754877075000024],
            [71.383670725000059, 33.755076950000046],
            [71.382614244000081, 33.755362485000035],
            [71.382128834000071, 33.755590913000049],
            [71.381500656000071, 33.755990663000034],
            [71.380843925000079, 33.756590287000051],
            [71.380529836000051, 33.757446893000065],
            [71.380158641000037, 33.758103624000057],
            [71.380101533000072, 33.758731802000057],
            [71.380072980000079, 33.759359979000067],
            [71.379730338000059, 33.759902496000052],
            [71.379244928000048, 33.760387906000062],
            [71.377988572000049, 33.760644888000058],
            [71.376989199000036, 33.760644888000058],
            [71.375732843000037, 33.760759102000065],
            [71.374647809000066, 33.76084476300008],
            [71.374590702000035, 33.760930424000037],
            [71.372706169000082, 33.761158852000051],
            [71.371249939000052, 33.761615708000079],
            [71.370650315000034, 33.761672815000054],
            [71.370136351000042, 33.761758476000068],
            [71.369279745000028, 33.761758476000068],
            [71.368623014000036, 33.761701369000036],
            [71.36773785400004, 33.761701369000036],
            [71.366709927000045, 33.761787029000061],
            [71.365881875000071, 33.762015458000064],
            [71.365110929000082, 33.762243886000078],
            [71.363654699000051, 33.762586528000043],
            [71.362626772000056, 33.762843510000039],
            [71.361855827000056, 33.76304338500006],
            [71.360685132000071, 33.763100492000035],
            [71.359771419000083, 33.763300367000056],
            [71.358971920000045, 33.763728670000035],
            [71.357772672000067, 33.764042758000073],
            [71.356630531000064, 33.764099866000038],
            [71.355002979000062, 33.764099866000038],
            [71.354089266000074, 33.764214080000045],
            [71.353204107000067, 33.764214080000045],
            [71.352347501000054, 33.764185526000063],
            [71.351433788000065, 33.764042758000073],
            [71.350462968000045, 33.764071312000056],
            [71.349634915000081, 33.764128419000031],
            [71.349294278000059, 33.764158040000041],
            [71.349292968000043, 33.764150184000073],
            [71.349286946000063, 33.764150708000045],
            [71.348314121000044, 33.764235301000042],
            [71.347229087000073, 33.764577944000052],
            [71.34637248100006, 33.764892033000024],
            [71.345201786000075, 33.765348889000052],
            [71.344116752000048, 33.765577317000066],
            [71.343260146000034, 33.765948513000069],
            [71.342974611000045, 33.766148388000033],
            [71.342374987000028, 33.766234049000047],
            [71.341518381000071, 33.76626260200004],
            [71.34083309600004, 33.766148388000033],
            [71.340519007000069, 33.765691532000062],
            [71.340062151000041, 33.765320336000059],
            [71.33863447400006, 33.763635677000025],
            [71.337549440000032, 33.762065233000044],
            [71.336749941000051, 33.76123718100007],
            [71.335407925000084, 33.760037932000046],
            [71.334237230000042, 33.758895791000043],
            [71.333523392000075, 33.758210506000069],
            [71.333209303000046, 33.757839310000065],
            [71.332895214000075, 33.757239686000048],
            [71.332209929000044, 33.75632597300006],
            [71.331553198000051, 33.755783456000074],
            [71.331010681000066, 33.755298046000064],
            [71.330325396000035, 33.754698422000047],
            [71.329725772000074, 33.754241565000029],
            [71.329297469000039, 33.754127351000079],
            [71.328840613000068, 33.754127351000079],
            [71.328355203000058, 33.754298672000061],
            [71.327841239000065, 33.754612761000033],
            [71.326670544000081, 33.755183832000057],
            [71.325642617000028, 33.755869117000032],
            [71.324614690000033, 33.756582955000056],
            [71.323758084000076, 33.757582329000059],
            [71.323044246000052, 33.758210506000069],
            [71.322473175000084, 33.758781577000036],
            [71.321787890000053, 33.759466862000068],
            [71.321530909000046, 33.759952272000078],
            [71.321331034000082, 33.760437682000031],
            [71.321188266000036, 33.760894538000059],
            [71.321102606000068, 33.761408502000052],
            [71.320731410000064, 33.761951019000037],
            [71.320445874000029, 33.762407875000065],
            [71.319817697000076, 33.762893285000075],
            [71.319103858000062, 33.763607124000032],
            [71.318075931000067, 33.764463730000045],
            [71.316962343000057, 33.765320336000059],
            [71.31587730900003, 33.766034174000026],
            [71.31510636400003, 33.766690905000075],
            [71.31387856200007, 33.767604618000064],
            [71.31290774200005, 33.768546885000035],
            [71.311565726000083, 33.769660472000055],
            [71.309795407000081, 33.770888274000072],
            [71.307910874000072, 33.772116076000032],
            [71.305740806000074, 33.773572306000062],
            [71.303713505000076, 33.774800108000079],
            [71.30234293500007, 33.775685268000075],
            [71.301200794000067, 33.776256338000053],
            [71.300372742000036, 33.776827409000077],
            [71.299544689000072, 33.777684015000034],
            [71.298488209000084, 33.77856917400004],
            [71.297431728000049, 33.779111691000026],
            [71.296261033000064, 33.779454334000036],
            [71.295347320000076, 33.779597101000036],
            [71.294604928000069, 33.779739869000025],
            [71.29383398300007, 33.780111065000028],
            [71.293120145000046, 33.780539368000063],
            [71.292149325000082, 33.781338867000045],
            [71.290921523000065, 33.782566669000062],
            [71.289865042000031, 33.78348038200005],
            [71.288922776000049, 33.784365541000057],
            [71.287923402000047, 33.785507682000059],
            [71.286895475000051, 33.786307181000041],
            [71.285496352000052, 33.787477876000025],
            [71.284468425000057, 33.788277375000064],
            [71.28324062300004, 33.789191088000052],
            [71.281784393000066, 33.789990587000034],
            [71.280185395000046, 33.790961407000054],
            [71.278472183000076, 33.792017888000032],
            [71.277073060000077, 33.792845940000063],
            [71.275873812000043, 33.793274243000042],
            [71.274474689000044, 33.793788207000034],
            [71.273503868000034, 33.79418795600003],
            [71.272818584000049, 33.794616259000065],
            [71.272076192000043, 33.795073116000026],
            [71.271219586000029, 33.795615633000068],
            [71.270191659000034, 33.796243810000078],
            [71.269763356000055, 33.79690054200006],
            [71.269420713000045, 33.797414505000063],
            [71.269049518000031, 33.798385325000027],
            [71.268792536000035, 33.799584573000061],
            [71.268478447000064, 33.800526840000032],
            [71.267907376000039, 33.801554767000027],
            [71.267250645000047, 33.802497034000055],
            [71.26602284300003, 33.804096031000029],
            [71.265137684000081, 33.805095405000031],
            [71.263995542000032, 33.806151886000066],
            [71.262796294000054, 33.807208366000054],
            [71.262225224000076, 33.807807991000061],
            [71.261254403000066, 33.809406988000035],
            [71.26085465400007, 33.810549130000027],
            [71.260083709000071, 33.811805485000036],
            [71.259284210000033, 33.812947626000039],
            [71.258513264000044, 33.813832786000035],
            [71.257770873000084, 33.814460963000045],
            [71.256628731000035, 33.815146248000076],
            [71.256057661000057, 33.81548889100003],
            [71.255600804000039, 33.815888640000026],
            [71.255001180000079, 33.816231282000047],
            [71.254744198000083, 33.816659585000025],
            [71.254715645000033, 33.817116442000042],
            [71.254801305000058, 33.817858834000049],
            [71.25496959700007, 33.818443451000064],
            [71.255055257000038, 33.81884320000006],
            [71.255026704000045, 33.819271503000039],
            [71.254684061000034, 33.819671253000024],
            [71.254170098000031, 33.820013895000045],
            [71.253513367000039, 33.820099556000059],
            [71.252942296000072, 33.820327984000073],
            [71.252542547000076, 33.820727733000069],
            [71.252000029000044, 33.821041822000041],
            [71.251457512000059, 33.821156036000048],
            [71.250800781000066, 33.821270250000055],
            [71.250286818000063, 33.821270250000055],
            [71.249658640000064, 33.82121314300008],
            [71.249201783000046, 33.820956162000073],
            [71.248716373000036, 33.820727733000069],
            [71.248173856000051, 33.820642073000045],
            [71.247402911000052, 33.82055641200003],
            [71.245061521000082, 33.820356537000066],
            [71.243005667000034, 33.820013895000045],
            [71.241178241000057, 33.819928234000031],
            [71.239322261000041, 33.819899681000038],
            [71.237694710000028, 33.81995678800007],
            [71.236267034000036, 33.820042449000027],
            [71.235210553000059, 33.820327984000073],
            [71.233925644000067, 33.820527859000038],
            [71.233011931000078, 33.820813394000027],
            [71.232012557000076, 33.821270250000055],
            [71.23035645300007, 33.821555786000033],
            [71.228843115000075, 33.822069749000036],
            [71.227786635000029, 33.822583713000029],
            [71.226730154000052, 33.82292635500005],
            [71.226101976000052, 33.823354658000028],
            [71.22544524500006, 33.823954282000045],
            [71.224874174000036, 33.824611014000027],
            [71.224303104000057, 33.825410513000065],
            [71.223617819000083, 33.825953030000051],
            [71.222961088000034, 33.82672397500005],
            [71.222361464000073, 33.827380706000042],
            [71.221704732000035, 33.828008884000042],
            [71.221076555000082, 33.828522847000045],
            [71.220362717000057, 33.82892259700003],
            [71.219049254000083, 33.829208132000076],
            [71.218021327000031, 33.829493668000055],
            [71.217364596000039, 33.829722096000069],
            [71.216422329000068, 33.83017895200004],
            [71.215308741000058, 33.830835684000078],
            [71.21410949300008, 33.831492415000071],
            [71.212596156000075, 33.832406128000059],
            [71.211425461000033, 33.833348394000041],
            [71.210711623000066, 33.834005126000079],
            [71.209769356000038, 33.834918839000068],
            [71.209169732000078, 33.835404249000078],
            [71.208655769000075, 33.835918212000024],
            [71.208113251000043, 33.835946766000063],
            [71.207342306000044, 33.83571833700006],
            [71.205514880000067, 33.835033053000075],
            [71.20380166800004, 33.834290661000068],
            [71.201003422000042, 33.833148520000066],
            [71.197634105000077, 33.831835057000035],
            [71.195549698000036, 33.83114977200006],
            [71.193693718000077, 33.830321720000029],
            [71.191951953000057, 33.829664989000037],
            [71.189724777000038, 33.829293793000033],
            [71.188068672000043, 33.829036811000037],
            [71.186526782000044, 33.828694169000073],
            [71.185013444000049, 33.828408633000038],
            [71.184156839000082, 33.828151652000031],
            [71.183300233000068, 33.827323599000067],
            [71.18264350100003, 33.826409886000079],
            [71.181615574000034, 33.824582460000045],
            [71.18078752200006, 33.823497426000074],
            [71.179873809000071, 33.82229817800004],
            [71.178046383000037, 33.820185216000027],
            [71.17573354700005, 33.818072255000061],
            [71.174420084000076, 33.816787346000069],
            [71.173277943000073, 33.815673758000059],
            [71.172592658000042, 33.815216902000031],
            [71.17193592700005, 33.814959920000035],
            [71.170422590000044, 33.814560171000039],
            [71.169194788000084, 33.814503063000075],
            [71.168081200000074, 33.814474510000025],
            [71.166967613000054, 33.814474510000025],
            [71.166139560000033, 33.814531617000057],
            [71.165311508000059, 33.814760045000071],
            [71.164340688000038, 33.815159795000056],
            [71.163084332000039, 33.81553099100006],
            [71.162227726000083, 33.815873633000024],
            [71.161371120000069, 33.815959294000038],
            [71.160657282000045, 33.815959294000038],
            [71.159686462000082, 33.815702312000042],
            [71.158801303000075, 33.815302562000056],
            [71.156831109000052, 33.814531617000057],
            [71.154775255000061, 33.813760672000058],
            [71.152833614000031, 33.813018280000051],
            [71.150463671000068, 33.812190227000031],
            [71.149264423000034, 33.811876139000049],
            [71.148122282000031, 33.811876139000049],
            [71.147436997000057, 33.812047460000031],
            [71.146351963000029, 33.812504316000059],
            [71.145324036000034, 33.813418029000047],
            [71.144124787000067, 33.814360296000075],
            [71.143125414000053, 33.815102688000024],
            [71.142525790000036, 33.815845079000042],
            [71.14226880800004, 33.816530364000073],
            [71.141669184000079, 33.817586845000051],
            [71.141098113000055, 33.818443451000064],
            [71.140469935000056, 33.818900307000035],
            [71.139185026000064, 33.819585592000067],
            [71.137928671000054, 33.820327984000073],
            [71.136872190000076, 33.821241697000062],
            [71.135216085000081, 33.822840695000025],
            [71.133674195000083, 33.824468246000038],
            [71.131989536000049, 33.826210011000057],
            [71.131018716000028, 33.827380706000042],
            [71.130333432000043, 33.828151652000031],
            [71.129562486000054, 33.829122472000051],
            [71.128620220000073, 33.829921971000033],
            [71.12776361400006, 33.83052159500005],
            [71.127021222000053, 33.830807130000039],
            [71.126193169000032, 33.83100700500006],
            [71.125507885000047, 33.83100700500006],
            [71.124993921000055, 33.830778576000057],
            [71.124337190000062, 33.830464488000075],
            [71.123394923000035, 33.830093292000072],
            [71.122452657000053, 33.829579328000079],
            [71.121310516000051, 33.829379453000058],
            [71.120196928000041, 33.829208132000076],
            [71.118540823000046, 33.829208132000076],
            [71.11717025400003, 33.829236686000058],
            [71.115514149000035, 33.829265239000051],
            [71.113429741000061, 33.829151025000044],
            [71.112087725000038, 33.829293793000033],
            [71.111145458000067, 33.82940800700004],
            [71.110203192000029, 33.829750649000061],
            [71.10894683600003, 33.830550148000043],
            [71.107404946000031, 33.831578075000039],
            [71.105063556000061, 33.833034305000069],
            [71.103378898000074, 33.834090786000047],
            [71.101494365000065, 33.835347142000046],
            [71.099752599000055, 33.836460729000066],
            [71.097982280000053, 33.838059727000029],
            [71.096468943000048, 33.839487404000067],
            [71.095326802000045, 33.840315456000042],
            [71.093328055000029, 33.841600365000033],
            [71.091186540000081, 33.842856720000043],
            [71.088559615000065, 33.844027415000028],
            [71.086646528000074, 33.84456993200007],
            [71.084733442000072, 33.844455718000063],
            [71.08199230300005, 33.843827540000063],
            [71.078366004000031, 33.842742506000036],
            [71.07508234800008, 33.841914454000062],
            [71.073397690000036, 33.841543258000058],
            [71.070770765000077, 33.840943634000041],
            [71.069028999000068, 33.840486777000024],
            [71.068029626000055, 33.84000136700007],
            [71.067115913000066, 33.839601618000074],
            [71.066287860000045, 33.839230422000071],
            [71.065574022000078, 33.839201868000032],
            [71.064888737000047, 33.839144761000057],
            [71.063832257000058, 33.839230422000071],
            [71.062718669000049, 33.839573064000035],
            [71.061519421000071, 33.84000136700007],
            [71.060263065000072, 33.840258349000067],
            [71.05786456900006, 33.840458224000031],
            [71.054209716000059, 33.840486777000024],
            [71.052068202000044, 33.840486777000024],
            [71.049698259000081, 33.840458224000031],
            [71.048013600000047, 33.840401117000056],
            [71.046814352000069, 33.840429670000049],
            [71.045786425000074, 33.840515331000063],
            [71.044958372000053, 33.840886527000066],
            [71.04395899900004, 33.841286276000062],
            [71.042702643000041, 33.841914454000062],
            [71.041360627000074, 33.842257096000026],
            [71.039504648000047, 33.842685399000061],
            [71.038105525000049, 33.842771060000075],
            [71.036335206000047, 33.843342130000053],
            [71.034821869000041, 33.843884647000039],
            [71.033451299000035, 33.844170183000074],
            [71.031823748000079, 33.84442716500007],
            [71.030738714000051, 33.844484272000045],
            [71.029910661000031, 33.844455718000063],
            [71.029225376000056, 33.844170183000074],
            [71.027940467000064, 33.843627666000032],
            [71.027369397000029, 33.84345634400006],
            [71.026512791000073, 33.843227916000046],
            [71.025170775000049, 33.843085149000046],
            [71.023457563000079, 33.843085149000046],
            [71.020002586000032, 33.843256470000028],
            [71.017175786000053, 33.843427791000067],
            [71.015005718000054, 33.843656219000025],
            [71.012207472000057, 33.843656219000025],
            [71.009637654000073, 33.843741880000039],
            [71.008352745000082, 33.843741880000039],
            [71.007581799000036, 33.843656219000025],
            [71.00669664000003, 33.843342130000053],
            [71.00498342800006, 33.842199989000051],
            [71.003441537000072, 33.841200615000048],
            [71.002499271000033, 33.840572438000038],
            [71.001128701000084, 33.839658725000049],
            [71.000557631000049, 33.839458850000028],
            [71.000100774000032, 33.839373189000071],
            [70.999244168000075, 33.839373189000071],
            [70.997673724000038, 33.839458850000028],
            [70.992619749000085, 33.83951595700006],
            [70.987879863000046, 33.839544511000042],
            [70.984681867000063, 33.839458850000028],
            [70.982369031000076, 33.839573064000035],
            [70.979856320000067, 33.839430297000035],
            [70.976972414000045, 33.839344636000078],
            [70.974231275000079, 33.838916333000043],
            [70.972775045000049, 33.83840236900005],
            [70.971889885000053, 33.837945513000079],
            [70.971404475000043, 33.837402996000037],
            [70.97123315400006, 33.837117460000059],
            [70.971204600000078, 33.836632050000048],
            [70.971204600000078, 33.835861105000049],
            [70.971461582000074, 33.834404875000075],
            [70.971889885000053, 33.832891538000069],
            [70.972746491000066, 33.830807130000039],
            [70.973231901000077, 33.829208132000076],
            [70.974602471000082, 33.825439066000058],
            [70.975116434000029, 33.823554533000049],
            [70.975544737000064, 33.821470125000076],
            [70.976172915000063, 33.818814647000067],
            [70.976629771000034, 33.817586845000051],
            [70.978114555000047, 33.812018906000048],
            [70.97911392900005, 33.808506822000027],
            [70.980198963000078, 33.80430945300003],
            [70.98134110400008, 33.801825295000071],
            [70.982426138000051, 33.799826548000055],
            [70.983454065000046, 33.798227551000025],
            [70.984082243000046, 33.797342391000029],
            [70.984481992000042, 33.79659999900008],
            [70.984596207000038, 33.796143143000052],
            [70.984596207000038, 33.795743393000066],
            [70.984424885000067, 33.795315090000031],
            [70.983254191000071, 33.794487038000057],
            [70.981940728000041, 33.793830307000064],
            [70.979342357000064, 33.792488291000041],
            [70.976743985000041, 33.791374703000031],
            [70.974288382000054, 33.790204008000046],
            [70.972061206000035, 33.789347402000033],
            [70.970290887000033, 33.788776332000054],
            [70.96914874600003, 33.788319475000037],
            [70.967549748000067, 33.788262368000062],
            [70.96509414500008, 33.78823381400008],
            [70.962667094000039, 33.78809104700008],
            [70.96078256100003, 33.788262368000062],
            [70.958869475000029, 33.788319475000037],
            [70.956642299000066, 33.788405136000051],
            [70.954329463000079, 33.788176707000048],
            [70.952502037000045, 33.788005386000066],
            [70.950531843000078, 33.787405762000049],
            [70.94898995300008, 33.787091673000077],
            [70.947191080000039, 33.786920352000038],
            [70.945249440000055, 33.786720477000074],
            [70.942479748000039, 33.786806138000031],
            [70.940452447000041, 33.787034566000045],
            [70.938225271000078, 33.786891798000056],
            [70.936340738000069, 33.787205887000027],
            [70.934199223000064, 33.786863245000063],
            [70.931372424000074, 33.786263621000046],
            [70.927489144000049, 33.785549783000079],
            [70.924005613000077, 33.784578962000069],
            [70.921207367000079, 33.783836571000052],
            [70.91835201300006, 33.783151286000077],
            [70.916353266000044, 33.782437448000053],
            [70.915154018000067, 33.782009145000075],
            [70.913954770000032, 33.781609395000032],
            [70.912983950000068, 33.781380967000075],
            [70.911984576000066, 33.781352413000036],
            [70.911242184000059, 33.781295306000061],
            [70.910385578000046, 33.781095432000029],
            [70.909871615000043, 33.780867003000026],
            [70.909471865000057, 33.78046725400003],
            [70.908957902000054, 33.77983907600003],
            [70.908529599000076, 33.779039577000049],
            [70.908215510000048, 33.778325739000024],
            [70.907701546000055, 33.777412026000036],
            [70.907216136000045, 33.776498313000047],
            [70.906445191000046, 33.77578447500008],
            [70.905845567000028, 33.775470386000052],
            [70.905540708000046, 33.775304099000039],
            [70.905217389000029, 33.775127744000031],
            [70.904018141000051, 33.774842208000052],
            [70.902676125000085, 33.774556673000063],
            [70.901848072000064, 33.774471012000049],
            [70.90036328900004, 33.774271138000074],
            [70.899535236000077, 33.773928495000064],
            [70.89879284400007, 33.773557299000061],
            [70.897964792000039, 33.773414532000061],
            [70.896736990000079, 33.773157550000064],
            [70.89545208100003, 33.77307188900005],
            [70.894195726000078, 33.773043336000057],
            [70.893282013000032, 33.772872015000075],
            [70.892082764000065, 33.772158176000062],
            [70.890569427000059, 33.771872641000073],
            [70.889741375000028, 33.771786980000059],
            [70.889084644000036, 33.771729873000027],
            [70.888427912000054, 33.771615659000076],
            [70.887428539000041, 33.771644213000059],
            [70.886657593000052, 33.771701320000034],
            [70.88585809500006, 33.772043962000055],
            [70.885315577000028, 33.772472265000033],
            [70.884830167000075, 33.773014782000075],
            [70.884430418000079, 33.773614406000036],
            [70.884201990000065, 33.774385352000024],
            [70.884201990000065, 33.775184851000063],
            [70.884087776000058, 33.775955796000062],
            [70.883945008000069, 33.776555420000079],
            [70.883745133000048, 33.777069384000072],
            [70.883516705000034, 33.777583347000075],
            [70.882974188000048, 33.777925990000028],
            [70.882374564000031, 33.777954543000078],
            [70.881375190000028, 33.777954543000078],
            [70.880432924000047, 33.777954543000078],
            [70.879633425000065, 33.777925990000028],
            [70.878348516000074, 33.777583347000075],
            [70.877720338000074, 33.777440580000075],
            [70.876835179000068, 33.776955170000065],
            [70.875950019000072, 33.776583974000062],
            [70.875378948000048, 33.77638409900004],
            [70.87480787800007, 33.776127117000044],
            [70.874122593000038, 33.776041457000076],
            [70.873351648000039, 33.775955796000062],
            [70.872809131000054, 33.775984349000055],
            [70.872009632000072, 33.776098564000051],
            [70.87135290100008, 33.77638409900004],
            [70.870153652000056, 33.776726741000061],
            [70.869182832000035, 33.777212151000072],
            [70.868440440000029, 33.777526240000043],
            [70.867469620000065, 33.777754668000057],
            [70.866584461000059, 33.777954543000078],
            [70.864728481000043, 33.778382846000056],
            [70.863329358000044, 33.778811149000035],
            [70.862387092000063, 33.779011024000056],
            [70.861444825000035, 33.779068131000031],
            [70.860616773000061, 33.779153791000056],
            [70.858846454000059, 33.779467880000027],
            [70.856904814000075, 33.779753416000062],
            [70.855534244000069, 33.779981844000076],
            [70.854249335000077, 33.780038951000051],
            [70.852764551000064, 33.78035304000008],
            [70.851708071000076, 33.780610022000076],
            [70.850594483000066, 33.780895557000065],
            [70.849423788000081, 33.781152539000061],
            [70.848081772000057, 33.781695056000046],
            [70.847282273000076, 33.781980591000035],
            [70.846482775000084, 33.782266126000025],
            [70.845797490000052, 33.782608769000035],
            [70.845112205000078, 33.782894304000024],
            [70.844541134000053, 33.782951411000056],
            [70.843998617000068, 33.782780090000074],
            [70.843741636000061, 33.782494555000028],
            [70.843655975000047, 33.781894930000078],
            [70.843427547000033, 33.781352413000036],
            [70.843227672000069, 33.780895557000065],
            [70.84291358300004, 33.780295933000048],
            [70.842228298000066, 33.779781969000055],
            [70.841428799000084, 33.779182345000038],
            [70.840600747000053, 33.778782596000042],
            [70.839858355000047, 33.778497060000063],
            [70.83897319600004, 33.777925990000028],
            [70.838059483000052, 33.777526240000043],
            [70.83691734100006, 33.776983723000058],
            [70.835404004000054, 33.776184224000076],
            [70.834604505000073, 33.775870135000048],
            [70.83406198800003, 33.775584600000059],
            [70.833376704000045, 33.775384725000038],
            [70.832834186000071, 33.775013529000034],
            [70.832177455000078, 33.774528119000024],
            [70.831435063000072, 33.773785728000064],
            [70.830692672000055, 33.772872015000075],
            [70.830207262000044, 33.772215283000037],
            [70.829579084000045, 33.771587106000027],
            [70.828922353000053, 33.771158803000048],
            [70.828436943000042, 33.771101696000073],
            [70.827751658000068, 33.770987481000077],
            [70.82666662400004, 33.770930374000045],
            [70.825838571000077, 33.770930374000045],
            [70.824896305000038, 33.770958928000027],
            [70.823725610000054, 33.771273017000055],
            [70.822069505000059, 33.771929748000048],
            [70.821241453000084, 33.772358051000026],
            [70.820441954000046, 33.772643586000072],
            [70.819813776000046, 33.773014782000075],
            [70.819242705000079, 33.773357425000029],
            [70.818814402000044, 33.773357425000029],
            [70.818414653000048, 33.773157550000064],
            [70.817929243000037, 33.772615033000079],
            [70.816672888000028, 33.771558552000045],
            [70.816044710000028, 33.770901821000052],
            [70.815330872000061, 33.77024509000006],
            [70.814445712000065, 33.769559805000029],
            [70.813503446000084, 33.76913150200005],
            [70.81264684000007, 33.768931627000029],
            [70.810391111000058, 33.768731752000065],
            [70.807821293000075, 33.768674645000033],
            [70.805765439000083, 33.768703199000072],
            [70.804052227000057, 33.768674645000033],
            [70.803081407000036, 33.768674645000033],
            [70.802453229000037, 33.768531878000033],
            [70.801853605000076, 33.76816068200003],
            [70.800711464000074, 33.76753250400003],
            [70.799826304000078, 33.767246969000041],
            [70.79902680500004, 33.767218415000059],
            [70.797998878000044, 33.767075648000059],
            [70.796628308000038, 33.767104201000052],
            [70.795172078000064, 33.767189862000066],
            [70.793944277000037, 33.767304076000073],
            [70.791574333000028, 33.767275522000034],
            [70.789118730000041, 33.767275522000034],
            [70.787748160000035, 33.767075648000059],
            [70.786920108000061, 33.76693288000007],
            [70.786177716000054, 33.766704452000056],
            [70.785378217000073, 33.765847846000042],
            [70.78375066600006, 33.764734258000033],
            [70.781609151000055, 33.762906832000056],
            [70.779124994000028, 33.761479155000075],
            [70.777525996000065, 33.760651103000043],
            [70.776526622000063, 33.760165693000033],
            [70.775841338000077, 33.759908711000037],
            [70.775598079000076, 33.759868168000025],
            [70.775327374000028, 33.759823051000069],
            [70.774328000000082, 33.759737390000055],
            [70.773271520000037, 33.75979449700003],
            [70.772443467000073, 33.759994372000051],
            [70.771758183000031, 33.760251354000047],
            [70.771244219000039, 33.760337014000072],
            [70.770587488000047, 33.760308461000079],
            [70.769873649000033, 33.760022925000044],
            [70.768874276000076, 33.759451855000066],
            [70.768188991000045, 33.759080659000062],
            [70.767332385000032, 33.758852231000049],
            [70.766589993000082, 33.758595249000052],
            [70.765390745000047, 33.758595249000052],
            [70.764077283000063, 33.758595249000052],
            [70.762421178000068, 33.758538142000077],
            [70.76133614400004, 33.758538142000077],
            [70.760536645000059, 33.758338267000056],
            [70.75976569900007, 33.758195499000067],
            [70.75865211200005, 33.757652982000025],
            [70.757281542000044, 33.757139019000078],
            [70.756139401000041, 33.756596502000036],
            [70.75422631400005, 33.75585411000003],
            [70.750856997000028, 33.754026684000053],
            [70.750057499000036, 33.753455613000028],
            [70.74965774900005, 33.75302731000005],
            [70.748886804000051, 33.752741775000061],
            [70.748201519000077, 33.75254190000004],
            [70.747145038000042, 33.75239913200005],
            [70.746202772000061, 33.752427686000033],
            [70.745431826000072, 33.752599007000072],
            [70.744660881000073, 33.752741775000061],
            [70.743690061000052, 33.753112971000064],
            [70.742633580000074, 33.753484167000067],
            [70.74191974200005, 33.753684041000042],
            [70.741063136000037, 33.753769702000056],
            [70.740120870000055, 33.754055237000046],
            [70.739292817000035, 33.754112344000077],
            [70.738664639000035, 33.754083791000028],
            [70.737865141000043, 33.75399813000007],
            [70.737065642000061, 33.753883916000063],
            [70.735837840000045, 33.753569827000035],
            [70.734809913000049, 33.753170078000039],
            [70.734124628000075, 33.753170078000039],
            [70.733439343000043, 33.753170078000039],
            [70.732297202000041, 33.753141524000057],
            [70.730783865000035, 33.753055864000032],
            [70.729356188000054, 33.753112971000064],
            [70.728442475000065, 33.753284292000046],
            [70.727186120000056, 33.753312845000039],
            [70.726329514000042, 33.753341399000078],
            [70.72567278300005, 33.753369952000071],
            [70.725016051000068, 33.753427060000035],
            [70.724473534000083, 33.753341399000078],
            [70.72407378500003, 33.753084417000025],
            [70.723531268000045, 33.752484793000065],
            [70.722874537000052, 33.752199258000076],
            [70.72236057300006, 33.751599634000058],
            [70.722046484000032, 33.751199884000073],
            [70.721960824000064, 33.751000009000052],
            [70.721760949000043, 33.750400385000034],
            [70.721618181000053, 33.749914975000024],
            [70.721532521000029, 33.749515226000028],
            [70.721361199000057, 33.748944155000061],
            [70.720932896000079, 33.74845874500005],
            [70.72076157500004, 33.747944781000058],
            [70.720647361000033, 33.747459371000048],
            [70.720533147000083, 33.746973961000037],
            [70.720247612000037, 33.746574212000041],
            [70.719790755000076, 33.746431444000052],
            [70.71890559600007, 33.746117355000024],
            [70.717906222000067, 33.745917481000049],
            [70.716164457000048, 33.745432071000039],
            [70.71447979800007, 33.744946661000029],
            [70.71302356800004, 33.744632572000057],
            [70.711852873000055, 33.744318483000029],
            [70.710653625000077, 33.743804519000037],
            [70.709739912000032, 33.743518984000048],
            [70.709197395000047, 33.743204895000076],
            [70.70845500300004, 33.742748039000048],
            [70.707741165000073, 33.742519610000045],
            [70.707141541000055, 33.742348289000063],
            [70.706370595000067, 33.742262629000038],
            [70.704685937000079, 33.742005647000042],
            [70.703315367000073, 33.741862879000053],
            [70.702487315000042, 33.74183432600006],
            [70.701973351000049, 33.741605897000056],
            [70.701516495000078, 33.74120614800006],
            [70.700745550000079, 33.740549417000068],
            [70.700317247000044, 33.740092560000051],
            [70.700003158000072, 33.739807025000061],
            [70.699717622000037, 33.73960715000004],
            [70.699374980000073, 33.739264508000076],
            [70.698889570000063, 33.739207401000044],
            [70.698318499000038, 33.739207401000044],
            [70.69789019600006, 33.739264508000076],
            [70.697661768000046, 33.73946438300004],
            [70.697176358000036, 33.739978346000044],
            [70.69677660900004, 33.740520863000029],
            [70.696462520000068, 33.741091934000053],
            [70.696319752000079, 33.741748665000046],
            [70.696005663000051, 33.742491057000052],
            [70.695691575000069, 33.743090681000069],
            [70.695377486000041, 33.743490431000055],
            [70.694892076000031, 33.743775966000044],
            [70.694321005000063, 33.744061501000033],
            [70.693607167000039, 33.744347037000068],
            [70.692779114000075, 33.744375590000061],
            [70.691779741000062, 33.744375590000061],
            [70.69098024200008, 33.744375590000061],
            [70.68986665400007, 33.744347037000068],
            [70.689181369000039, 33.744347037000068],
            [70.68841042400004, 33.744347037000068],
            [70.68763947900004, 33.744375590000061],
            [70.68718262200008, 33.744632572000057],
            [70.68669721200007, 33.744975214000078],
            [70.686211802000059, 33.745032321000053],
            [70.685754946000031, 33.745317857000032],
            [70.685326643000053, 33.745660499000053],
            [70.684898340000075, 33.746088802000031],
            [70.684441483000057, 33.746317230000045],
            [70.683984627000029, 33.746716980000031],
            [70.683527770000069, 33.747230943000034],
            [70.683213681000041, 33.747744907000026],
            [70.683128021000073, 33.748230317000036],
            [70.682871039000077, 33.748829941000054],
            [70.682699718000038, 33.749315351000064],
            [70.682328522000034, 33.749772207000035],
            [70.681871665000074, 33.75020051000007],
            [70.681272041000057, 33.750543153000024],
            [70.680643863000057, 33.75068592100007],
            [70.67990147200004, 33.75082868800007],
            [70.679216187000065, 33.751057116000027],
            [70.678530902000034, 33.751399759000037],
            [70.677445868000063, 33.751942276000079],
            [70.676360834000036, 33.75254190000004],
            [70.67533290700004, 33.753227185000071],
            [70.674276426000063, 33.75399813000007],
            [70.673391266000067, 33.754597754000031],
            [70.67216346500004, 33.755425807000051],
            [70.670764341000051, 33.756253859000026],
            [70.669565093000074, 33.75696769700005],
            [70.668737041000043, 33.757481661000043],
            [70.667652007000072, 33.757995625000035],
            [70.666566972000055, 33.758738016000052],
            [70.665681813000049, 33.759109212000055],
            [70.664882314000067, 33.759508962000041],
            [70.663654512000051, 33.759765944000037],
            [70.662141175000045, 33.759994372000051],
            [70.661113248000049, 33.760108586000058],
            [70.65971412500005, 33.760451228000079],
            [70.659000286000037, 33.760822424000025],
            [70.658571984000048, 33.761193620000029],
            [70.658315002000052, 33.761650477000046],
            [70.658315002000052, 33.762107333000074],
            [70.658657644000073, 33.762449976000028],
            [70.659457143000054, 33.76299249300007],
            [70.660456517000057, 33.763278028000059],
            [70.661398783000038, 33.763934759000051],
            [70.661855640000056, 33.764505830000076],
            [70.662283943000034, 33.76519111500005],
            [70.662512371000048, 33.765904953000074],
            [70.662512371000048, 33.766533131000074],
            [70.662598031000073, 33.76741829000008],
            [70.661998407000056, 33.768332003000069],
            [70.661370230000045, 33.769245716000057],
            [70.660427963000075, 33.770644839000056],
            [70.65985689200005, 33.771587106000027],
            [70.659143054000083, 33.77244371200004],
            [70.658657644000073, 33.773271764000071],
            [70.657915252000066, 33.774356798000042],
            [70.657287075000056, 33.775755921000041],
            [70.656487576000075, 33.777783222000039],
            [70.655402542000047, 33.779896183000062],
            [70.654488828000069, 33.781209646000036],
            [70.654260400000055, 33.782237573000032],
            [70.653832097000077, 33.782894304000024],
            [70.653318134000074, 33.783608142000048],
            [70.65260429500006, 33.784436195000069],
            [70.651919011000075, 33.785264247000043],
            [70.651319386000068, 33.78600663900005],
            [70.650120138000034, 33.787320101000034],
            [70.649263532000077, 33.78820526100003],
            [70.648464033000039, 33.789147527000068],
            [70.647835856000029, 33.790004133000025],
            [70.647378999000068, 33.790889293000077],
            [70.646836482000083, 33.791917220000073],
            [70.646122644000059, 33.793030808000026],
            [70.645523020000041, 33.793801753000025],
            [70.644837735000067, 33.794458484000074],
            [70.643895468000039, 33.795457858000077],
            [70.642724774000044, 33.796314464000034],
            [70.641839614000048, 33.796885535000058],
            [70.640897347000077, 33.797485159000075],
            [70.639926527000057, 33.797970569000029],
            [70.638755833000062, 33.798227551000025],
            [70.637442370000031, 33.798627300000078],
            [70.636100354000064, 33.798855728000035],
            [70.635043874000075, 33.798827175000042],
            [70.633559090000062, 33.798855728000035],
            [70.632445502000053, 33.798770068000067],
            [70.631560343000046, 33.798741514000028],
            [70.630275434000055, 33.798655853000071],
            [70.628990525000063, 33.798455979000039],
            [70.627819830000078, 33.798398872000064],
            [70.62639215300004, 33.79831321100005],
            [70.624850263000042, 33.798141890000068],
            [70.623593907000043, 33.798113336000029],
            [70.622194784000044, 33.798113336000029],
            [70.62122396400008, 33.798113336000029],
            [70.620196037000028, 33.798113336000029],
            [70.619196664000071, 33.79817044300006],
            [70.61791175500008, 33.798227551000025],
            [70.61679816700007, 33.798198997000043],
            [70.616112882000039, 33.798141890000068],
            [70.615513258000078, 33.797970569000029],
            [70.614885080000079, 33.79768503300005],
            [70.614256903000069, 33.797171070000047],
            [70.613714385000037, 33.796685660000037],
            [70.61285778000007, 33.795714840000073],
            [70.612115388000063, 33.79485823400006],
            [70.611344442000075, 33.794058735000078],
            [70.61077337200004, 33.793145022000033],
            [70.610173748000079, 33.792202755000062],
            [70.60954557000008, 33.791374703000031],
            [70.608945946000063, 33.790717972000039],
            [70.608175000000074, 33.790118348000078],
            [70.607575376000057, 33.789575830000047],
            [70.606918645000064, 33.789118974000075],
            [70.606090593000033, 33.788605010000026],
            [70.605348201000083, 33.78820526100003],
            [70.604662916000052, 33.787948279000034],
            [70.604234613000074, 33.787834065000027],
            [70.603406561000043, 33.787405762000049],
            [70.603121025000064, 33.787063120000028],
            [70.602921151000032, 33.786406388000046],
            [70.602921151000032, 33.785778211000036],
            [70.602864043000068, 33.78429342700008],
            [70.602864043000068, 33.782751536000035],
            [70.602940035000074, 33.780781799000067],
            [70.602997142000049, 33.780267836000064],
            [70.603539659000035, 33.779725319000079],
            [70.604624694000051, 33.778954373000033],
            [70.606052370000043, 33.778269089000048],
            [70.607394386000067, 33.777441036000027],
            [70.607794136000052, 33.777041287000031],
            [70.60836520600003, 33.776470216000064],
            [70.608850616000041, 33.775899145000039],
            [70.608767458000045, 33.775511073000075],
            [70.608764956000073, 33.775499396000043],
            [70.608308099000055, 33.775470842000061],
            [70.607594261000031, 33.775499396000043],
            [70.605566960000033, 33.776041913000029],
            [70.603939409000077, 33.776413109000032],
            [70.602283304000082, 33.776612984000053],
            [70.599913361000063, 33.776841412000067],
            [70.59605863400003, 33.777098394000063],
            [70.593545923000079, 33.777126947000056],
            [70.592175354000062, 33.777241161000063],
            [70.590490695000028, 33.777241161000063],
            [70.588977358000079, 33.777241161000063],
            [70.588177859000041, 33.777269715000045],
            [70.587207039000077, 33.777726571000073],
            [70.586321880000071, 33.778126321000059],
            [70.585293953000075, 33.778754499000058],
            [70.58426602600008, 33.779525444000058],
            [70.582210171000042, 33.780781799000067],
            [70.581382119000068, 33.781609852000031],
            [70.580611174000069, 33.782180922000066],
            [70.579526139000052, 33.783094635000054],
            [70.578555319000031, 33.784151116000032],
            [70.577698713000075, 33.785436025000024],
            [70.577041982000082, 33.786578166000027],
            [70.576470911000058, 33.787606093000079],
            [70.576156823000076, 33.788262825000061],
            [70.576099716000044, 33.788862449000078],
            [70.576356697000051, 33.789547734000053],
            [70.577013429000033, 33.790461447000041],
            [70.577727267000057, 33.791175285000065],
            [70.578640980000046, 33.792031891000079],
            [70.57984022800008, 33.793316800000071],
            [70.580611174000069, 33.794344727000066],
            [70.581125137000072, 33.795429761000037],
            [70.581753315000071, 33.796600456000078],
            [70.582210171000042, 33.797828258000038],
            [70.582467153000039, 33.799541470000065],
            [70.582781242000067, 33.801568770000074],
            [70.582952563000049, 33.803167768000037],
            [70.582952563000049, 33.804395570000054],
            [70.583038224000063, 33.80513796200006],
            [70.582952563000049, 33.80576613900007],
            [70.582695581000053, 33.806137335000074],
            [70.582238725000082, 33.806422871000052],
            [70.581325012000036, 33.806708406000041],
            [70.579868782000062, 33.807250923000026],
            [70.578612426000063, 33.807821994000051],
            [70.576585126000055, 33.808849921000046],
            [70.575128895000034, 33.809620866000046],
            [70.57381543300005, 33.810448919000066],
            [70.572730399000079, 33.811219864000066],
            [70.572187882000037, 33.811676720000037],
            [70.571731025000076, 33.812276345000043],
            [70.571588258000077, 33.812933076000036],
            [70.571616811000069, 33.81398955700007],
            [70.571531150000055, 33.815474340000037],
            [70.571559704000038, 33.817672962000074],
            [70.571445490000031, 33.819586049000065],
            [70.57133127600008, 33.821584796000025],
            [70.571045740000045, 33.82361209700008],
            [70.570617437000067, 33.826267575000031],
            [70.570531777000042, 33.827038520000031],
            [70.570474670000067, 33.829008714000054],
            [70.570474670000067, 33.831178783000041],
            [70.570531777000042, 33.832435138000051],
            [70.570560330000035, 33.833891368000025],
            [70.570788759000038, 33.836546846000033],
            [70.570788759000038, 33.83860270100007],
            [70.570760205000056, 33.840116038000076],
            [70.570674545000031, 33.84094409000005],
            [70.570645991000049, 33.841629375000025],
            [70.570560330000035, 33.842143339000074],
            [70.570217688000071, 33.842457427000056],
            [70.569646617000046, 33.842714409000052],
            [70.568104727000048, 33.842714409000052],
            [70.565592016000039, 33.842828623000059],
            [70.562022824000053, 33.842971391000049],
            [70.561280433000036, 33.842971391000049],
            [70.560709362000068, 33.842971391000049],
            [70.559966970000062, 33.842628749000028],
            [70.557882562000032, 33.841258179000079],
            [70.555826708000041, 33.83985905600008],
            [70.554084943000078, 33.838916790000042],
            [70.552571606000072, 33.83811729100006],
            [70.550572858000066, 33.837203578000072],
            [70.548802539000064, 33.836375525000051],
            [70.546803792000048, 33.835433259000069],
            [70.545376116000057, 33.834719420000056],
            [70.544091207000065, 33.834119796000039],
            [70.543320261000076, 33.833777154000074],
            [70.542692084000066, 33.833463065000046],
            [70.542178120000074, 33.833405958000071],
            [70.541635603000032, 33.833434512000053],
            [70.541035979000071, 33.83369149300006],
            [70.539922391000061, 33.834262564000028],
            [70.538723143000084, 33.835062063000066],
            [70.537352573000078, 33.836004329000048],
            [70.535924897000029, 33.83700370300005],
            [70.534582881000063, 33.837803202000032],
            [70.533469293000053, 33.838431380000031],
            [70.532784008000078, 33.838716915000077],
            [70.531727528000033, 33.838916790000042],
            [70.53061394000008, 33.839145218000056],
            [70.529557459000046, 33.839259432000063],
            [70.528786514000046, 33.839430753000045],
            [70.52804412200004, 33.839716288000034],
            [70.527216070000065, 33.840058931000044],
            [70.526245249000056, 33.840544341000054],
            [70.525588518000063, 33.841058304000057],
            [70.524960341000053, 33.841600822000032],
            [70.524474931000043, 33.842257553000024],
            [70.524160842000072, 33.842857177000042],
            [70.523989520000043, 33.843485355000041],
            [70.523903860000075, 33.844456175000062],
            [70.523846753000043, 33.845055799000079],
            [70.523846753000043, 33.845940958000028],
            [70.524075181000057, 33.846769011000049],
            [70.524303609000071, 33.847397188000059],
            [70.52473191200005, 33.848139580000066],
            [70.525445751000063, 33.849281722000057],
            [70.525902607000035, 33.850138327000025],
            [70.526388017000045, 33.85090927300007],
            [70.526559338000084, 33.851394683000024],
            [70.526473678000059, 33.852108521000048],
            [70.526159589000031, 33.85262248500004],
            [70.525674179000077, 33.852908020000029],
            [70.524846126000057, 33.853079341000068],
            [70.523818199000061, 33.853107895000051],
            [70.522276309000063, 33.853107895000051],
            [70.520534543000053, 33.853107895000051],
            [70.51856434900003, 33.853136448000043],
            [70.515994532000036, 33.853193555000075],
            [70.514652516000069, 33.85325066200005],
            [70.512882197000067, 33.853279216000033],
            [70.511083324000083, 33.853193555000075],
            [70.509684201000084, 33.852793806000079],
            [70.508056650000071, 33.85227984200003],
            [70.506400545000076, 33.851566004000063],
            [70.504487458000028, 33.850937826000063],
            [70.502345944000069, 33.850138327000025],
            [70.501346570000067, 33.849652917000071],
            [70.500775499000042, 33.84945304300004],
            [70.500347196000064, 33.849395936000064],
            [70.499719019000054, 33.849481596000032],
            [70.499290716000075, 33.849881346000075],
            [70.498948073000065, 33.850395309000078],
            [70.498576877000062, 33.850823612000056],
            [70.498091467000052, 33.851309022000066],
            [70.497406183000066, 33.85168021800007],
            [70.496692344000053, 33.85202286100008],
            [70.496121274000075, 33.852479717000051],
            [70.49569297100004, 33.853136448000043],
            [70.495664417000057, 33.853479091000054],
            [70.495721524000032, 33.853964501000064],
            [70.496007060000068, 33.854449911000074],
            [70.496463916000039, 33.854963874000077],
            [70.497034987000063, 33.85547783800007],
            [70.497834486000045, 33.856334444000026],
            [70.498576877000062, 33.857105389000026],
            [70.498948073000065, 33.857961995000039],
            [70.499119395000037, 33.858904262000067],
            [70.499255595000079, 33.860040474000073],
            [70.499284149000061, 33.861525258000029],
            [70.499284149000061, 33.862296203000028],
            [70.499512577000075, 33.863010041000052],
            [70.499855220000029, 33.863638219000052],
            [70.499883773000079, 33.864466271000026],
            [70.499883773000079, 33.865151556000058],
            [70.500026541000068, 33.865893948000064],
            [70.500254969000082, 33.866379358000074],
            [70.500511951000078, 33.86740728500007],
            [70.500511951000078, 33.868092570000044],
            [70.500511951000078, 33.868892069000026],
            [70.500312076000057, 33.870062764000068],
            [70.500140755000075, 33.871062137000024],
            [70.500312076000057, 33.872061511000027],
            [70.500312076000057, 33.872975224000072],
            [70.500397737000071, 33.873888937000061],
            [70.500397737000071, 33.874859757000024],
            [70.500169308000068, 33.875487935000024],
            [70.500226415000043, 33.87625888000008],
            [70.500426290000064, 33.876944165000054],
            [70.500312076000057, 33.878543162000028],
            [70.500254969000082, 33.879171340000028],
            [70.499969434000036, 33.879799518000027],
            [70.499655345000065, 33.88079889100004],
            [70.499683898000058, 33.881512730000054],
            [70.499826666000047, 33.881969586000025],
            [70.499883773000079, 33.882483550000075],
            [70.499883773000079, 33.883283049000056],
            [70.499598238000033, 33.883739905000027],
            [70.499055721000047, 33.884425190000059],
            [70.498370436000073, 33.884996261000026],
            [70.497542384000042, 33.88571009900005],
            [70.496799992000035, 33.886138402000029],
            [70.496000493000054, 33.886738026000046],
            [70.495315208000079, 33.887109222000049],
            [70.494230174000052, 33.887623185000052],
            [70.493173693000074, 33.888137149000045],
            [70.492060105000064, 33.888708220000069],
            [70.491089285000044, 33.888993755000058],
            [70.490032805000055, 33.889507719000051],
            [70.488947771000028, 33.889736147000065],
            [70.488034058000039, 33.890392878000057],
            [70.486520720000044, 33.890935395000042],
            [70.485093044000052, 33.891763448000063],
            [70.48332272500005, 33.892591500000037],
            [70.481152656000063, 33.893619427000033],
            [70.479353784000068, 33.894447480000053],
            [70.478382964000048, 33.894989997000039],
            [70.477412144000084, 33.895304085000078],
            [70.476441324000064, 33.895618174000049],
            [70.475613271000043, 33.895846603000052],
            [70.474728112000037, 33.895846603000052],
            [70.474014274000069, 33.895846603000052],
            [70.47338609600007, 33.895789495000031],
            [70.472586597000031, 33.895703835000063],
            [70.471358795000071, 33.895189871000071],
            [70.470302314000037, 33.89484722900005],
            [70.469417155000031, 33.894504587000029],
            [70.468874638000045, 33.894418926000071],
            [70.46833212100006, 33.894333265000057],
            [70.46770394300006, 33.894133391000025],
            [70.467161426000075, 33.894133391000025],
            [70.466790230000072, 33.894361819000039],
            [70.466818784000054, 33.894989997000039],
            [70.466904444000079, 33.895589621000056],
            [70.466961551000054, 33.896274906000031],
            [70.467047212000068, 33.896960190000073],
            [70.467132872000036, 33.897474154000065],
            [70.46721853300005, 33.898187992000032],
            [70.46721853300005, 33.898730509000075],
            [70.467104319000043, 33.899215919000028],
            [70.466704569000058, 33.899587115000031],
            [70.466304820000062, 33.899786990000052],
            [70.465533875000062, 33.899929758000042],
            [70.464134752000064, 33.900072525000041],
            [70.462821289000033, 33.900129632000073],
            [70.461108077000063, 33.900329507000038],
            [70.459708954000064, 33.900529382000059],
            [70.458681027000068, 33.900700703000041],
            [70.457310458000052, 33.90098623800003],
            [70.456225423000035, 33.901100452000037],
            [70.45505472900004, 33.901186113000051],
            [70.453113088000066, 33.901129006000076],
            [70.451913840000032, 33.901014792000069],
            [70.450571824000065, 33.90084347100003],
            [70.449486790000037, 33.90087202400008],
            [70.44891571900007, 33.900957685000037],
            [70.448344649000035, 33.901071899000044],
            [70.447802132000049, 33.901357434000033],
            [70.447459489000039, 33.901671523000061],
            [70.447088293000036, 33.90209982600004],
            [70.446774204000064, 33.902642343000025],
            [70.446574330000033, 33.903413288000024],
            [70.446174580000047, 33.904355555000052],
            [70.44606036600004, 33.905611910000061],
            [70.445946152000033, 33.906240088000061],
            [70.44591759900004, 33.906811159000029],
            [70.44557495600003, 33.90749644300007],
            [70.44546074200008, 33.908010407000063],
            [70.445060993000084, 33.908867013000076],
            [70.444746904000056, 33.909552298000051],
            [70.444632690000049, 33.910380350000025],
            [70.444518475000052, 33.911179849000064],
            [70.444518475000052, 33.912007901000038],
            [70.444489922000059, 33.91315004300003],
            [70.444404261000045, 33.914292184000033],
            [70.444375708000052, 33.915462879000074],
            [70.444318601000077, 33.916176717000042],
            [70.443975958000067, 33.916890555000066],
            [70.443547655000032, 33.917318858000044],
            [70.442948031000071, 33.917832822000037],
            [70.442376961000036, 33.918575214000043],
            [70.441862997000044, 33.919089177000046],
            [70.441320480000059, 33.919631694000032],
            [70.440977838000038, 33.920031444000074],
            [70.440920730000073, 33.920402640000077],
            [70.440949284000055, 33.920859496000048],
            [70.441234819000044, 33.921287799000027],
            [70.441891551000083, 33.92165899500003],
            [70.444004512000049, 33.92311522500006],
            [70.44468979700008, 33.923686296000028],
            [70.445317974000034, 33.924086045000024],
            [70.446031813000047, 33.924485795000066],
            [70.44654577600005, 33.924885544000063],
            [70.447031186000061, 33.925085419000027],
            [70.447316722000039, 33.925570829000037],
            [70.447316722000039, 33.926170453000054],
            [70.447316722000039, 33.926770077000072],
            [70.446945526000036, 33.92754102300006],
            [70.44654577600005, 33.928740271000038],
            [70.446288794000054, 33.929596877000051],
            [70.44591759900004, 33.930424929000026],
            [70.445517849000055, 33.931167321000032],
            [70.445175207000034, 33.93162417800005],
            [70.444918225000038, 33.932023927000046],
            [70.444661243000041, 33.932452230000024],
            [70.444404261000045, 33.932851979000077],
            [70.444232940000063, 33.933308836000037],
            [70.444090172000074, 33.933937014000037],
            [70.444090172000074, 33.93493638700005],
            [70.444090172000074, 33.936078528000053],
            [70.444261494000045, 33.937135009000031],
            [70.444290047000038, 33.937963062000051],
            [70.444118726000056, 33.939048096000079],
            [70.444118726000056, 33.939704827000071],
            [70.444118726000056, 33.940418665000038],
            [70.444033065000042, 33.940989736000063],
            [70.443633316000046, 33.941675021000037],
            [70.443461995000064, 33.94233175200003],
            [70.44323356700005, 33.942959930000029],
            [70.442948031000071, 33.943531000000064],
            [70.442948031000071, 33.944102071000032],
            [70.443033692000029, 33.945244212000034],
            [70.443119352000053, 33.946300693000069],
            [70.44323356700005, 33.946871763000047],
            [70.44337633400005, 33.947642709000036],
            [70.443547655000032, 33.94835654700006],
            [70.443690423000078, 33.948727743000063],
            [70.44371897700006, 33.949041832000034],
            [70.443772187000036, 33.949156655000024],
            [70.44437690500007, 33.952013694000073],
            [70.444398152000076, 33.953139505000024],
            [70.444585633000031, 33.953079022000054],
            [70.473965000000078, 33.943601000000058],
            [70.504457702000082, 33.945079197000041],
            [70.505155000000059, 33.945113000000049],
            [70.520266388000039, 33.951023927000051],
            [70.526121000000046, 33.953314000000034],
            [70.534129919000065, 33.948115012000073],
            [70.538471000000072, 33.945297000000039],
            [70.563959000000068, 33.957403000000056],
            [70.578629002000071, 33.961376088000065],
            [70.588502000000062, 33.964050000000043],
            [70.590930166000078, 33.963750504000075],
            [70.605642247000048, 33.961935882000034],
            [70.625829000000067, 33.959446000000071],
            [70.651310469000066, 33.954376087000071],
            [70.655965000000037, 33.953450000000032],
            [70.683096000000035, 33.959445000000073],
            [70.708662243000049, 33.958932015000073],
            [70.708663000000058, 33.958932000000061],
            [70.732328611000071, 33.960410920000072],
            [70.741387000000032, 33.960977000000071],
            [70.744625682000049, 33.960449667000034],
            [70.757795630000032, 33.958305290000055],
            [70.763374000000056, 33.957397000000071],
            [70.773011002000032, 33.956166341000028],
            [70.779153000000065, 33.955382000000043],
            [70.788940000000082, 33.962509000000068],
            [70.801212000000078, 33.971712000000025],
            [70.821538000000032, 33.97861400000005],
            [70.842218418000073, 33.981094728000073],
            [70.848448000000076, 33.981842000000029],
            [70.855174341000065, 33.980160202000036],
            [70.872128760000066, 33.975921060000076],
            [70.872129000000029, 33.975921000000028],
            [70.873121827000034, 33.975999354000066],
            [70.873124241000028, 33.975999545000036],
            [70.883936663000043, 33.976852865000069],
            [70.885499996000078, 33.97697624400007],
            [70.892580000000066, 33.977535000000046],
            [70.903883000000064, 33.985069000000067],
            [70.923956000000032, 34.007799000000034],
            [70.937253000000055, 34.010364000000038],
            [70.963625000000036, 34.020051000000024],
            [70.983000000000061, 34.026509000000033],
            [70.993286125000054, 34.028732650000052],
            [71.002914000000032, 34.030814000000078],
            [71.003353401000084, 34.030704109000055],
            [71.013678000000084, 34.028122000000053],
            [71.01959800000003, 34.035119000000066],
            [71.034130000000062, 34.048035000000027],
            [71.04543300000006, 34.049649000000045],
            [71.06684800000005, 34.051813000000038],
            [71.078812000000084, 34.060427000000061],
            [71.077278000000035, 34.072011000000032],
            [71.077278000000035, 34.078549193000072],
            [71.077278000000035, 34.08006543700003],
            [71.077278000000035, 34.084460000000036],
            [71.074002000000064, 34.101496000000054],
            [71.084725000000049, 34.115847000000031],
            [71.099795000000029, 34.126611000000025],
            [71.109483000000068, 34.138989000000038],
            [71.117556000000036, 34.151906000000054],
            [71.127783000000079, 34.16374600000006],
            [71.12607134600006, 34.172131437000075],
            [71.125109000000066, 34.176846000000069],
            [71.123799000000076, 34.189950000000067],
            [71.124454000000071, 34.203055000000063],
            [71.12448399200008, 34.20309684700004],
            [71.136397000000045, 34.219719000000055],
            [71.134740737000072, 34.224413952000077],
            [71.13361860200007, 34.227594831000033],
            [71.12976800000007, 34.238510000000076],
            [71.125634000000048, 34.253626000000054],
            [71.124493486000063, 34.271243082000069],
            [71.124454000000071, 34.271853000000078],
            [71.126662242000066, 34.274834228000032],
            [71.13755800000007, 34.289544000000035],
            [71.151470000000074, 34.299911000000066],
            [71.153515274000029, 34.309234435000064],
            [71.153939000000037, 34.311166000000071],
            [71.157698477000054, 34.318685476000041],
            [71.161146000000031, 34.325581000000057],
            [71.17294000000004, 34.337375000000065],
            [71.17308414200005, 34.338576015000058],
            [71.173161002000029, 34.33862922600008],
            [71.174274590000039, 34.338943315000051],
            [71.175388178000048, 34.33937161800003],
            [71.176730194000072, 34.339600046000044],
            [71.178329191000046, 34.339714260000051],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "56",
      properties: { name: "Islamabad", count: 1050 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.20191376300005, 33.804261327000063],
            [73.210182985000074, 33.801237384000046],
            [73.210162274000083, 33.801238254000054],
            [73.21215189000003, 33.800509061000071],
            [73.214067012000044, 33.799807170000065],
            [73.216943573000037, 33.798752912000054],
            [73.226206238000032, 33.796420203000025],
            [73.226752219000048, 33.796282703000031],
            [73.232644747000052, 33.79356110100008],
            [73.23270532500004, 33.793533122000042],
            [73.234959016000062, 33.792492202000062],
            [73.237164510000071, 33.791491679000046],
            [73.23781512100004, 33.791173043000072],
            [73.239806908000048, 33.790197569000043],
            [73.24172363100007, 33.78909558600003],
            [73.246967237000035, 33.786080876000028],
            [73.25037573000003, 33.781193407000046],
            [73.251594143000034, 33.779387746000054],
            [73.253809146000037, 33.778721488000031],
            [73.256945778000045, 33.777778010000077],
            [73.264111524000043, 33.773791154000037],
            [73.268826993000062, 33.769040535000045],
            [73.270512739000083, 33.767342428000063],
            [73.271004970000035, 33.766699937000055],
            [73.271013010000047, 33.766689443000075],
            [73.271013310000058, 33.766689051000071],
            [73.272535798000035, 33.764701803000037],
            [73.274912487000051, 33.761599539000031],
            [73.276477213000078, 33.75955156200007],
            [73.281565476000083, 33.754015138000057],
            [73.28205328100006, 33.753455394000071],
            [73.282261599000037, 33.753216354000074],
            [73.28805563800006, 33.746567842000047],
            [73.290104635000034, 33.743568943000071],
            [73.291083136000054, 33.742136814000048],
            [73.295392225000057, 33.734705729000041],
            [73.298384200000044, 33.730609030000039],
            [73.298384236000061, 33.730608981000046],
            [73.29865772900007, 33.730234506000045],
            [73.301885066000068, 33.727960137000025],
            [73.30464535200008, 33.726014909000071],
            [73.312399631000062, 33.720941427000071],
            [73.320001541000067, 33.714578782000046],
            [73.320262921000051, 33.714360012000043],
            [73.325556522000056, 33.711215984000034],
            [73.327983998000036, 33.709779649000041],
            [73.327984059000073, 33.709779613000023],
            [73.328266916000075, 33.709612247000052],
            [73.330218785000056, 33.708460424000066],
            [73.331870210000034, 33.704705675000071],
            [73.331746591000069, 33.700377873000036],
            [73.331678422000039, 33.698032936000061],
            [73.332236867000063, 33.693100556000047],
            [73.337895171000071, 33.687256791000038],
            [73.345868177000057, 33.683539439000072],
            [73.345868254000038, 33.683539403000054],
            [73.358222065000064, 33.681965024000078],
            [73.371120270000063, 33.681444231000057],
            [73.372771682000064, 33.677311675000055],
            [73.365484888000083, 33.667834909000078],
            [73.364187439000034, 33.666147525000042],
            [73.363853648000031, 33.665628644000037],
            [73.363429803000031, 33.664969772000063],
            [73.358705708000059, 33.658485622000057],
            [73.357042320000062, 33.657369780000067],
            [73.353620230000047, 33.655179564000036],
            [73.351271179000037, 33.653221408000036],
            [73.347783349000053, 33.650310790000049],
            [73.345926218000045, 33.647779425000067],
            [73.344859700000029, 33.643735096000057],
            [73.344076241000039, 33.640781140000058],
            [73.343633399000055, 33.639776647000076],
            [73.343611808000048, 33.639793060000045],
            [73.341947377000054, 33.63592045200005],
            [73.339710859000036, 33.63280345700008],
            [73.336716857000056, 33.632222986000045],
            [73.334697944000084, 33.632318256000076],
            [73.334686760000068, 33.632300692000058],
            [73.331597993000059, 33.632438268000044],
            [73.329738723000048, 33.63252108100005],
            [73.32599172700003, 33.632846603000075],
            [73.324641073000066, 33.632964540000046],
            [73.317492423000033, 33.633584229000064],
            [73.317182949000085, 33.633564095000054],
            [73.316121097000064, 33.633495013000072],
            [73.316070710000076, 33.633491735000064],
            [73.312993383000048, 33.633158791000028],
            [73.306373656000062, 33.629853624000077],
            [73.301459632000046, 33.627137889000039],
            [73.293319986000029, 33.62444800600008],
            [73.291183903000046, 33.622821521000049],
            [73.289783129000057, 33.621096759000068],
            [73.289705826000045, 33.621001577000072],
            [73.290405975000056, 33.618290986000034],
            [73.289965014000074, 33.61741171400007],
            [73.288703984000051, 33.614897235000058],
            [73.287096450000035, 33.611830052000073],
            [73.286969365000061, 33.611587573000065],
            [73.286761091000074, 33.610836655000071],
            [73.28596714300005, 33.607974136000053],
            [73.285902561000057, 33.605797023000036],
            [73.286255936000032, 33.600711827000055],
            [73.285567056000048, 33.596253518000026],
            [73.284019897000064, 33.591441308000071],
            [73.283071280000058, 33.588490777000061],
            [73.281978695000078, 33.583898759000078],
            [73.282101341000043, 33.582778638000036],
            [73.282371157000057, 33.580314423000061],
            [73.282575030000032, 33.57845246100004],
            [73.281718934000082, 33.574045980000051],
            [73.281649037000079, 33.573857747000034],
            [73.281538557000033, 33.573560226000041],
            [73.280492493000054, 33.570743191000076],
            [73.279832001000045, 33.568964494000056],
            [73.279001980000032, 33.568074119000073],
            [73.27685533600004, 33.565771382000037],
            [73.274734240000043, 33.563496052000062],
            [73.27382526100007, 33.562520977000077],
            [73.272284881000076, 33.559965276000071],
            [73.271107191000056, 33.558002638000062],
            [73.271086377000074, 33.558020954000028],
            [73.271016732000078, 33.557905117000075],
            [73.271016710000083, 33.557905081000058],
            [73.270309102000056, 33.556728150000026],
            [73.26760110500004, 33.551552475000051],
            [73.268948021000028, 33.550036227000078],
            [73.270756530000028, 33.549128039000038],
            [73.270331193000061, 33.547871713000063],
            [73.269946233000042, 33.546732692000035],
            [73.269938608000075, 33.546710131000054],
            [73.269971161000058, 33.54670821600007],
            [73.269967089000033, 33.546695793000026],
            [73.262490753000066, 33.539400259000047],
            [73.256774604000043, 33.533091715000069],
            [73.256757419000053, 33.533097183000052],
            [73.250788229000079, 33.526496444000031],
            [73.248019108000051, 33.523434345000055],
            [73.248019072000034, 33.523434305000023],
            [73.247860862000039, 33.523259357000029],
            [73.247863595000069, 33.523257209000064],
            [73.247606699000073, 33.522973690000072],
            [73.245477032000053, 33.520623314000034],
            [73.242931725000062, 33.517814223000073],
            [73.239838967000082, 33.514400946000023],
            [73.239380299000061, 33.513894744000027],
            [73.232663906000084, 33.50645454000005],
            [73.227624528000035, 33.503492488000063],
            [73.225835114000063, 33.502444736000029],
            [73.225826838000046, 33.502439890000062],
            [73.22581531700007, 33.502433144000065],
            [73.223464382000031, 33.501056606000077],
            [73.222761306000052, 33.500646945000028],
            [73.214239497000051, 33.495681543000046],
            [73.207178161000058, 33.491567116000056],
            [73.191107278000061, 33.482368234000035],
            [73.17963992600005, 33.477691629000049],
            [73.176159053000049, 33.476272062000078],
            [73.168150257000036, 33.473005921000038],
            [73.162161887000082, 33.470563750000053],
            [73.162145777000035, 33.470557179000025],
            [73.156217587000071, 33.468139550000046],
            [73.148580218000063, 33.465024885000048],
            [73.135491547000072, 33.459687074000044],
            [73.134149921000073, 33.459139933000074],
            [73.134146693000048, 33.459160906000079],
            [73.127797231000045, 33.456549184000039],
            [73.125275030000068, 33.460302783000031],
            [73.125274994000051, 33.460302837000029],
            [73.123956669000052, 33.462264800000071],
            [73.11836390600007, 33.470588082000063],
            [73.11836388200004, 33.470588118000023],
            [73.116709287000049, 33.473050526000065],
            [73.115009463000035, 33.476306585000032],
            [73.11336366300003, 33.479459161000079],
            [73.113228474000039, 33.47972061300004],
            [73.111697089000074, 33.482682265000051],
            [73.109308510000062, 33.487785509000048],
            [73.105721707000043, 33.499957960000074],
            [73.105176028000074, 33.501809817000037],
            [73.10386595500006, 33.506255784000075],
            [73.101632710000047, 33.513910138000028],
            [73.115413239000077, 33.519985142000053],
            [73.159908773000041, 33.539600549000056],
            [73.163827423000043, 33.541328046000046],
            [73.163674227000058, 33.542563803000064],
            [73.163455763000059, 33.54363203500003],
            [73.163273964000041, 33.544588066000074],
            [73.163154508000048, 33.545291697000039],
            [73.164077555000063, 33.548450770000045],
            [73.168180945000074, 33.549146302000054],
            [73.16917823600005, 33.549110245000065],
            [73.169661332000032, 33.549986534000027],
            [73.170373328000039, 33.552062579000051],
            [73.170356155000036, 33.552087948000064],
            [73.167496279000034, 33.556312688000048],
            [73.167487128000062, 33.556305382000062],
            [73.167484053000067, 33.556309903000056],
            [73.165208990000053, 33.559494286000074],
            [73.164590212000064, 33.559570528000052],
            [73.164605075000054, 33.560339580000061],
            [73.160753653000029, 33.565730380000048],
            [73.160753617000069, 33.565730430000031],
            [73.154330372000061, 33.574720986000045],
            [73.144818918000055, 33.58746411900006],
            [73.144818891000057, 33.587464155000077],
            [73.141985112000043, 33.591260759000079],
            [73.14049086700004, 33.593303371000047],
            [73.140471508000076, 33.593288635000079],
            [73.140100011000072, 33.59300584500005],
            [73.135848446000068, 33.595847961000061],
            [73.133818284000029, 33.597665205000055],
            [73.133818248000068, 33.597665237000058],
            [73.133274013000062, 33.598152394000067],
            [73.132267998000032, 33.601770049000038],
            [73.131770323000069, 33.604822095000031],
            [73.132314615000041, 33.604103155000075],
            [73.132316830000036, 33.604214004000028],
            [73.132317563000072, 33.604250697000055],
            [73.131667303000029, 33.605094786000052],
            [73.131413837000082, 33.605423805000044],
            [73.129727995000053, 33.607612159000041],
            [73.129640867000035, 33.607634835000056],
            [73.127871514000049, 33.609971924000035],
            [73.121167983000078, 33.618496359000062],
            [73.115659874000073, 33.625500654000064],
            [73.115120870000055, 33.626186070000074],
            [73.115071675000081, 33.626249506000079],
            [73.115082526000037, 33.626251606000039],
            [73.104123263000076, 33.64050625200008],
            [73.104123227000059, 33.640506299000037],
            [73.100841563000074, 33.64477473900007],
            [73.098169970000072, 33.648249663000058],
            [73.086106026000039, 33.663941160000036],
            [73.085437658000046, 33.665563973000076],
            [73.066566327000032, 33.652010000000075],
            [73.034467232000054, 33.634953923000069],
            [73.025528626000039, 33.630262267000035],
            [73.000138001000039, 33.621045958000025],
            [72.988114076000045, 33.63404644600007],
            [72.984146790000068, 33.63123882900004],
            [72.980301576000045, 33.629651915000068],
            [72.975296693000075, 33.627271544000052],
            [72.97151251300005, 33.629651915000068],
            [72.968704896000077, 33.63123882900004],
            [72.967301087000067, 33.626661192000029],
            [72.971146302000079, 33.62483013800005],
            [72.975723939000034, 33.622632872000054],
            [72.983719544000053, 33.61366070400004],
            [72.978655119000052, 33.610733076000031],
            [72.978610531000072, 33.610764302000064],
            [72.947876000000065, 33.59355400000004],
            [72.94066411600005, 33.590439709000066],
            [72.92853302900005, 33.585207443000058],
            [72.922974083000042, 33.582800664000047],
            [72.909488000000067, 33.576977000000056],
            [72.907747278000045, 33.579477869000073],
            [72.896779457000036, 33.595224686000051],
            [72.889281179000079, 33.605992597000068],
            [72.880482380000046, 33.618625003000034],
            [72.87546100000003, 33.625835000000052],
            [72.873174715000062, 33.629509491000078],
            [72.860753513000077, 33.649474307000048],
            [72.858517327000072, 33.653066670000044],
            [72.856181691000074, 33.656820477000053],
            [72.852838054000074, 33.66219433200007],
            [72.851032000000032, 33.66509700000006],
            [72.848062869000046, 33.668362999000067],
            [72.845838606000029, 33.670809654000038],
            [72.842468248000046, 33.674516996000079],
            [72.839813541000069, 33.677437133000069],
            [72.834719437000047, 33.68304167000008],
            [72.82485900000006, 33.693888000000072],
            [72.832993854000051, 33.702023854000061],
            [72.834453025000073, 33.703483025000025],
            [72.839744643000074, 33.708774643000027],
            [72.844925000000046, 33.713955000000055],
            [72.84983644600004, 33.716410723000024],
            [72.860629000000074, 33.721807000000069],
            [72.867919922000056, 33.721923828000058],
            [72.87722524600008, 33.721804024000051],
            [72.886900161000028, 33.722345873000052],
            [72.892911000000083, 33.722680000000025],
            [72.900288206000084, 33.725925436000068],
            [72.904309546000036, 33.727694774000042],
            [72.907757728000036, 33.729212370000027],
            [72.910265287000072, 33.730316655000024],
            [72.914689988000077, 33.732262462000051],
            [72.918078338000043, 33.733715051000047],
            [72.920723998000028, 33.734848515000067],
            [72.924580189000039, 33.736501652000072],
            [72.924682000000075, 33.735958000000039],
            [72.927894102000039, 33.735155076000069],
            [72.934938832000057, 33.733394115000067],
            [72.936608469000078, 33.732976758000063],
            [72.936610001000076, 33.732976375000078],
            [72.948537000000044, 33.729995000000031],
            [72.973717000000079, 33.739271000000031],
            [72.977351573000078, 33.741283003000035],
            [72.998044108000045, 33.752737839000076],
            [73.010825000000068, 33.759813000000065],
            [73.014166294000063, 33.765063553000061],
            [73.020102000000065, 33.774391000000037],
            [73.021327951000046, 33.774729259000026],
            [73.028185094000037, 33.776621254000077],
            [73.039318000000037, 33.779693000000066],
            [73.040806964000069, 33.780481166000072],
            [73.050583000000074, 33.785656000000074],
            [73.050584146000062, 33.785655018000057],
            [73.055222000000072, 33.781681000000049],
            [73.055222321000031, 33.781681082000034],
            [73.06939539800004, 33.785323387000062],
            [73.074228069000071, 33.786554049000074],
            [73.081065000000081, 33.788307000000032],
            [73.091182218000029, 33.789430947000028],
            [73.092992000000038, 33.78963200000004],
            [73.092992611000057, 33.789631734000068],
            [73.108233000000041, 33.783006000000057],
            [73.108233824000081, 33.783006294000074],
            [73.110052329000041, 33.783655718000034],
            [73.11880681100007, 33.78678211700003],
            [73.128981048000071, 33.790415538000047],
            [73.129991736000079, 33.790786789000038],
            [73.131192008000085, 33.791205116000071],
            [73.136064000000033, 33.792945000000032],
            [73.140813277000063, 33.794528159000038],
            [73.159073952000028, 33.800615305000065],
            [73.168809401000033, 33.803860591000046],
            [73.175487544000077, 33.80608673200004],
            [73.183538884000029, 33.808770625000079],
            [73.183542936000038, 33.808771626000066],
            [73.18381458500005, 33.808838737000031],
            [73.183815086000038, 33.808838861000027],
            [73.191592583000045, 33.806871626000031],
            [73.19240280300005, 33.806666716000052],
            [73.20191376300005, 33.804261327000063],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "57",
      properties: { name: "Astore", count: 86 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.742063500000029, 35.729241000000059],
            [74.750861984000039, 35.724109204000058],
            [74.754100986000083, 35.72618850200007],
            [74.753793500000029, 35.725575000000049],
            [74.761125500000048, 35.718244000000027],
            [74.778720500000077, 35.718977000000052],
            [74.794850500000052, 35.718244000000027],
            [74.808779500000071, 35.717511000000059],
            [74.821976500000062, 35.722643000000062],
            [74.84177150000005, 35.720443000000046],
            [74.860833000000071, 35.714578000000074],
            [74.876229500000079, 35.695516000000055],
            [74.879161500000066, 35.669856000000038],
            [74.871830500000044, 35.658859000000064],
            [74.877695500000073, 35.640530000000069],
            [74.860099500000047, 35.632466000000079],
            [74.840305500000056, 35.617803000000038],
            [74.83443950000003, 35.614137000000028],
            [74.83810550000004, 35.59800800000005],
            [74.86523150000005, 35.58261200000004],
            [74.869630500000085, 35.576013000000046],
            [74.863765500000056, 35.562084000000027],
            [74.882094500000051, 35.551086000000055],
            [74.896757500000035, 35.53862300000003],
            [74.909953500000029, 35.530558000000042],
            [74.93634650000007, 35.522494000000052],
            [74.95174250000008, 35.512230000000045],
            [74.964206500000046, 35.490235000000041],
            [74.97886950000003, 35.479971000000035],
            [74.993532500000072, 35.472640000000069],
            [75.007461500000034, 35.477039000000048],
            [75.01772550000004, 35.468241000000035],
            [75.014060500000085, 35.447713000000078],
            [75.032388500000081, 35.439648000000034],
            [75.04998450000005, 35.438915000000065],
            [75.067579500000079, 35.43818200000004],
            [75.071245500000032, 35.421319500000038],
            [75.09250650000007, 35.416921000000059],
            [75.102037500000051, 35.405190000000061],
            [75.104970000000037, 35.386862000000065],
            [75.117433500000061, 35.377331000000027],
            [75.13649550000008, 35.358269000000064],
            [75.151158000000066, 35.350937500000043],
            [75.173885519000066, 35.338473819000058],
            [75.176085000000057, 35.317946000000063],
            [75.188548500000081, 35.301083500000061],
            [75.18561550000004, 35.290086000000031],
            [75.192214500000034, 35.27615650000007],
            [75.18928150000005, 35.26515900000004],
            [75.174618500000065, 35.242432000000065],
            [75.172419500000046, 35.228502000000049],
            [75.190748000000042, 35.216038500000025],
            [75.198812500000031, 35.200642000000073],
            [75.209076500000037, 35.188912000000073],
            [75.228871500000082, 35.192578000000026],
            [75.236202500000047, 35.193311000000051],
            [75.242801000000043, 35.180847500000027],
            [75.245733500000028, 35.166184500000043],
            [75.27212650000007, 35.158120000000054],
            [75.251598500000057, 35.153721000000075],
            [75.226672000000065, 35.144923000000063],
            [75.223006500000054, 35.129527000000053],
            [75.216408000000058, 35.117797000000053],
            [75.197346500000037, 35.109732000000065],
            [75.19661300000007, 35.090670000000046],
            [75.203944500000034, 35.07600750000006],
            [75.217874481000081, 35.073807913000053],
            [75.214941500000066, 35.060611000000051],
            [75.209076500000037, 35.047415000000058],
            [75.21787450000005, 35.03935000000007],
            [75.228138500000057, 35.019555000000025],
            [75.225939000000039, 35.001960000000054],
            [75.207610500000044, 34.985830000000078],
            [75.201745500000072, 34.971901000000059],
            [75.197346500000037, 34.953572000000065],
            [75.195880000000045, 34.938176000000055],
            [75.176818500000081, 34.935243000000071],
            [75.160689500000046, 34.935243000000071],
            [75.147492500000055, 34.924246000000039],
            [75.132829500000071, 34.911783000000071],
            [75.118166500000029, 34.911783000000071],
            [75.104970500000036, 34.899319000000048],
            [75.120366500000046, 34.884656000000064],
            [75.142360500000052, 34.872193000000038],
            [75.15042550000004, 34.859729000000073],
            [75.15042550000004, 34.83920100000006],
            [75.173152500000072, 34.83920100000006],
            [75.197346500000037, 34.826004500000067],
            [75.23327050000006, 34.811341500000026],
            [75.249399500000038, 34.79301300000003],
            [75.253798500000073, 34.758555000000058],
            [75.263329500000054, 34.737294000000077],
            [75.265528500000073, 34.719698000000051],
            [75.259663500000045, 34.707235000000026],
            [75.261130000000037, 34.689639000000057],
            [75.245734000000084, 34.681575000000066],
            [75.236203000000046, 34.672777000000053],
            [75.228138500000057, 34.656648000000075],
            [75.222650068000064, 34.647386331000064],
            [75.216481722000083, 34.636977312000056],
            [75.213683500000059, 34.637471500000061],
            [75.212482026000032, 34.646683148000079],
            [75.211380500000075, 34.655128500000046],
            [75.20063250000004, 34.672018000000037],
            [75.167621500000052, 34.68583650000005],
            [75.140752500000076, 34.692746000000056],
            [75.12386250000003, 34.681230500000026],
            [75.116185500000029, 34.666644000000076],
            [75.107741000000033, 34.660502500000064],
            [75.08499716700004, 34.655384989000027],
            [75.083837966000033, 34.65512416200005],
            [75.077033000000029, 34.653593000000058],
            [75.076552606000064, 34.653013207000072],
            [75.054770000000076, 34.626723500000026],
            [75.050931500000047, 34.619046500000024],
            [75.01408200000003, 34.619046500000024],
            [74.979535000000055, 34.635168000000078],
            [74.966484000000037, 34.635168000000078],
            [74.952666000000079, 34.634400500000027],
            [74.942686000000037, 34.641309500000034],
            [74.934241000000043, 34.652057500000069],
            [74.921958000000075, 34.652057500000069],
            [74.91121000000004, 34.652057500000069],
            [74.895088500000043, 34.656664000000035],
            [74.881270000000029, 34.676624000000061],
            [74.877808127000037, 34.68197417600004],
            [74.864380500000038, 34.702726000000041],
            [74.842885000000081, 34.726524500000039],
            [74.822157000000061, 34.748020500000052],
            [74.799789870000041, 34.756220889000076],
            [74.799702724000042, 34.756252732000064],
            [74.799126000000058, 34.756465000000048],
            [74.799153511000043, 34.756203124000024],
            [74.786117500000046, 34.755069000000049],
            [74.776017000000081, 34.762199000000066],
            [74.771858000000066, 34.773488000000043],
            [74.762945500000058, 34.787748000000079],
            [74.760569000000032, 34.806167000000073],
            [74.757598500000029, 34.814485000000047],
            [74.752845000000036, 34.822803000000079],
            [74.735614500000054, 34.833498000000077],
            [74.727890500000058, 34.838251000000071],
            [74.726702500000044, 34.849540000000047],
            [74.725514000000032, 34.855482000000052],
            [74.718384000000071, 34.856670000000065],
            [74.710660000000075, 34.850728500000059],
            [74.702936000000079, 34.853699000000063],
            [74.698777000000064, 34.869147500000054],
            [74.695212000000083, 34.868553000000077],
            [74.68392300000005, 34.872712000000035],
            [74.678575500000079, 34.88519000000008],
            [74.670257500000048, 34.889349000000038],
            [74.665504500000054, 34.900043500000038],
            [74.657186000000081, 34.897073000000034],
            [74.645897000000048, 34.896479000000056],
            [74.634608000000071, 34.903608500000075],
            [74.618566000000044, 34.910144000000059],
            [74.607277000000067, 34.911332500000071],
            [74.598959000000036, 34.910738000000038],
            [74.59301750000003, 34.907768000000033],
            [74.584105000000079, 34.904203000000052],
            [74.579352000000029, 34.897073000000034],
            [74.570439500000077, 34.896479000000056],
            [74.570439500000077, 34.906579000000079],
            [74.574004500000058, 34.914303000000075],
            [74.56865700000003, 34.92381000000006],
            [74.572816000000046, 34.940446000000065],
            [74.566280500000062, 34.948170000000061],
            [74.566280500000062, 34.955894000000058],
            [74.569251000000065, 34.959459000000038],
            [74.569845000000043, 34.969560000000058],
            [74.575787000000048, 34.974313000000052],
            [74.57519300000007, 34.990355000000079],
            [74.579352000000029, 34.989167000000066],
            [74.588858000000073, 34.998674000000051],
            [74.580540000000042, 35.006398000000047],
            [74.57875800000005, 35.012933000000032],
            [74.573410000000081, 35.018281000000059],
            [74.569251000000065, 35.011745000000076],
            [74.561527000000069, 35.009368500000051],
            [74.550832000000071, 35.020657000000028],
            [74.539543500000036, 35.026005000000055],
            [74.534790000000044, 35.035511000000042],
            [74.535978500000056, 35.04798900000003],
            [74.541326000000083, 35.053930000000037],
            [74.538355000000081, 35.068190000000072],
            [74.523501000000067, 35.076508000000047],
            [74.516371000000049, 35.084232000000043],
            [74.509836000000064, 35.101463000000024],
            [74.487258000000054, 35.111563000000046],
            [74.475375000000042, 35.110375000000033],
            [74.463492000000031, 35.107998500000065],
            [74.455767500000036, 35.13176500000003],
            [74.443884000000082, 35.135924000000045],
            [74.438822565000066, 35.130863435000037],
            [74.424454000000082, 35.143378000000041],
            [74.419762000000048, 35.148539000000028],
            [74.409909000000084, 35.148539000000028],
            [74.411786000000063, 35.155577000000051],
            [74.422108000000037, 35.164961000000062],
            [74.431492000000048, 35.171999000000028],
            [74.439938000000041, 35.18091400000003],
            [74.447445000000073, 35.187013000000036],
            [74.455421000000058, 35.196866000000057],
            [74.463397000000043, 35.20437400000003],
            [74.474189000000081, 35.212819000000025],
            [74.481696000000056, 35.217511000000059],
            [74.499056000000053, 35.225957000000051],
            [74.511725000000069, 35.225018000000034],
            [74.520170000000064, 35.224549000000025],
            [74.530493000000035, 35.225487000000044],
            [74.538469000000077, 35.22642600000006],
            [74.547384000000079, 35.227833000000032],
            [74.557237000000043, 35.230179000000078],
            [74.567559000000074, 35.233464000000026],
            [74.575066000000049, 35.236748000000034],
            [74.583512000000042, 35.239563000000032],
            [74.588673000000028, 35.244255000000067],
            [74.589611000000048, 35.24988600000006],
            [74.594303000000082, 35.255516000000057],
            [74.598995000000059, 35.259270000000072],
            [74.604157000000043, 35.26255400000008],
            [74.60978700000004, 35.264900000000068],
            [74.616356000000053, 35.264900000000068],
            [74.623394000000076, 35.264900000000068],
            [74.631370000000061, 35.266308000000038],
            [74.639816000000053, 35.266777000000047],
            [74.642162000000042, 35.27428400000008],
            [74.645915000000059, 35.280853000000036],
            [74.652015000000063, 35.286952000000042],
            [74.657645000000059, 35.291175000000067],
            [74.663745000000063, 35.299151000000052],
            [74.667967000000033, 35.308066000000053],
            [74.672190000000057, 35.314635000000067],
            [74.674067000000036, 35.323081000000059],
            [74.674536000000046, 35.330588000000034],
            [74.681574000000069, 35.334810000000061],
            [74.689081000000044, 35.340441000000055],
            [74.696119000000067, 35.341848000000027],
            [74.706442000000038, 35.34888600000005],
            [74.71582600000005, 35.351232000000039],
            [74.718172000000038, 35.357801000000052],
            [74.720518000000084, 35.362962000000039],
            [74.720518000000084, 35.370470000000068],
            [74.724271000000044, 35.375162000000046],
            [74.728025000000059, 35.379384000000073],
            [74.730371000000048, 35.386891000000048],
            [74.730371000000048, 35.39580600000005],
            [74.72755600000005, 35.405659000000071],
            [74.723333000000082, 35.414105000000063],
            [74.720987000000036, 35.423020000000065],
            [74.714887000000033, 35.43146500000006],
            [74.713480000000061, 35.440849000000071],
            [74.716295000000059, 35.446949000000075],
            [74.718172000000038, 35.453518000000031],
            [74.715356000000043, 35.461494000000073],
            [74.713010000000054, 35.468063000000029],
            [74.709726000000046, 35.475101000000052],
            [74.705972000000031, 35.484485000000063],
            [74.699873000000082, 35.493869000000075],
            [74.69095800000008, 35.500906000000043],
            [74.682513000000029, 35.507006000000047],
            [74.677351000000044, 35.509821000000045],
            [74.676413000000082, 35.520613000000026],
            [74.671252000000038, 35.527651000000048],
            [74.670313000000078, 35.535627000000034],
            [74.669375000000059, 35.544542000000035],
            [74.66749800000008, 35.55298700000003],
            [74.665621000000044, 35.561433000000079],
            [74.665621000000044, 35.568002000000035],
            [74.654361000000051, 35.57269400000007],
            [74.647792000000038, 35.581139000000064],
            [74.64356900000007, 35.587708000000077],
            [74.634185000000059, 35.594746000000043],
            [74.629962000000035, 35.593808000000024],
            [74.625740000000064, 35.598500000000058],
            [74.629493000000082, 35.601315000000056],
            [74.634185000000059, 35.608353000000079],
            [74.634185000000059, 35.613514000000066],
            [74.629493000000082, 35.620552000000032],
            [74.622455000000059, 35.626651000000038],
            [74.612602000000038, 35.632282000000032],
            [74.612447360000033, 35.632390717000078],
            [74.61596250000008, 35.636864500000058],
            [74.612297500000068, 35.652261000000067],
            [74.61596250000008, 35.664724000000035],
            [74.631358500000033, 35.677188000000058],
            [74.644555500000081, 35.694050000000061],
            [74.656285500000081, 35.705780000000061],
            [74.646021500000074, 35.713845000000049],
            [74.625493500000061, 35.729241000000059],
            [74.62182800000005, 35.748303000000078],
            [74.629892500000039, 35.75416800000005],
            [74.646754500000043, 35.761499000000072],
            [74.653353500000037, 35.773230000000069],
            [74.630625500000065, 35.785693000000038],
            [74.622561500000074, 35.797424000000035],
            [74.635024500000043, 35.81355300000007],
            [74.648954500000059, 35.827483000000029],
            [74.666549500000031, 35.839946000000054],
            [74.688544500000035, 35.841412000000048],
            [74.703940500000044, 35.848744000000067],
            [74.730333778000045, 35.859740769000041],
            [74.740597500000035, 35.838480000000061],
            [74.741330500000061, 35.810620000000029],
            [74.739131500000042, 35.801089000000047],
            [74.740597500000035, 35.791558500000065],
            [74.759659500000055, 35.781294000000059],
            [74.762591500000042, 35.764432000000056],
            [74.750861500000042, 35.749769000000072],
            [74.742063500000029, 35.729241000000059],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "58",
      properties: { name: "Diamir", count: 89 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.354104500000062, 35.940861000000041],
            [73.371095500000081, 35.930570000000046],
            [73.380429000000049, 35.921237000000076],
            [73.379471500000079, 35.907596000000069],
            [73.38377950000006, 35.901852000000076],
            [73.394070000000056, 35.904964000000064],
            [73.400052500000072, 35.900895000000048],
            [73.407471500000042, 35.892280000000028],
            [73.422548500000062, 35.884382500000072],
            [73.442172000000085, 35.884382500000072],
            [73.457009500000083, 35.878400000000056],
            [73.472565000000031, 35.871699000000035],
            [73.489077500000064, 35.872177000000079],
            [73.505590500000039, 35.875767000000053],
            [73.518274000000076, 35.856861000000038],
            [73.531915000000083, 35.856143000000031],
            [73.54220550000008, 35.860451000000069],
            [73.549385000000029, 35.872656000000063],
            [73.558479000000034, 35.881511000000046],
            [73.566376500000047, 35.875767000000053],
            [73.576906000000065, 35.870742000000064],
            [73.587914500000068, 35.87026300000008],
            [73.603470000000073, 35.867391000000055],
            [73.615914500000031, 35.865237000000036],
            [73.626922500000035, 35.860451000000069],
            [73.634820500000046, 35.865955000000042],
            [73.641999500000054, 35.873613000000034],
            [73.651333000000079, 35.878160000000037],
            [73.658751500000051, 35.874331000000041],
            [73.669999500000074, 35.878878000000043],
            [73.681007500000078, 35.882707000000039],
            [73.68651200000005, 35.878639000000078],
            [73.702306500000077, 35.869545000000073],
            [73.716187000000048, 35.86451900000003],
            [73.722409500000083, 35.860212000000047],
            [73.737486000000047, 35.855904000000066],
            [73.753041500000052, 35.853750000000048],
            [73.764289500000075, 35.853750000000048],
            [73.769477750000078, 35.863935127000047],
            [73.770750500000076, 35.866434000000027],
            [73.780084000000045, 35.874810000000025],
            [73.793725000000052, 35.869066000000032],
            [73.804015500000048, 35.866673000000048],
            [73.813588000000038, 35.866434000000027],
            [73.828904500000078, 35.866434000000027],
            [73.836801500000036, 35.873853000000054],
            [73.846374500000081, 35.870502000000045],
            [73.862647500000037, 35.863323000000037],
            [73.871980500000063, 35.854947000000038],
            [73.880356500000062, 35.845374000000049],
            [73.889211500000044, 35.844178000000056],
            [73.906202500000063, 35.846331500000076],
            [73.919604500000048, 35.853032000000042],
            [73.932048500000064, 35.852793000000077],
            [73.947604500000068, 35.851596000000029],
            [73.949209606000068, 35.853386756000077],
            [73.952467500000068, 35.843612000000064],
            [73.955400000000054, 35.831881000000067],
            [73.969329000000073, 35.820884000000035],
            [73.984725000000083, 35.819418000000042],
            [74.003787042000056, 35.816485045000036],
            [74.016984312000034, 35.830414978000078],
            [74.027248000000043, 35.829682000000048],
            [74.028714000000036, 35.819418000000042],
            [74.03018000000003, 35.803289000000063],
            [74.038978000000043, 35.793758000000025],
            [74.050708000000043, 35.778362000000072],
            [74.069770000000062, 35.771763000000078],
            [74.099829000000057, 35.769564000000059],
            [74.110826000000031, 35.755634000000043],
            [74.129888000000051, 35.741704000000027],
            [74.14088526900008, 35.725575094000078],
            [74.147145795000029, 35.727766751000047],
            [74.155548137000039, 35.730706931000043],
            [74.171677000000045, 35.722643000000062],
            [74.178275500000041, 35.705047000000036],
            [74.185607000000061, 35.688918000000058],
            [74.203935500000057, 35.67278900000008],
            [74.225930000000062, 35.649328000000025],
            [74.244992000000082, 35.63686400000006],
            [74.26112100000006, 35.63686400000006],
            [74.272851667000054, 35.644196000000079],
            [74.283848500000033, 35.644196000000079],
            [74.293379984000069, 35.633199019000074],
            [74.305842687000052, 35.633931982000036],
            [74.321972000000073, 35.619269000000031],
            [74.338834500000075, 35.604606000000047],
            [74.346166846000074, 35.599717991000034],
            [74.34983150000005, 35.597275000000025],
            [74.360828699000081, 35.598740960000043],
            [74.373292000000049, 35.583345000000065],
            [74.387956146000079, 35.576013070000045],
            [74.395286500000054, 35.572348000000034],
            [74.39821900000004, 35.586277000000052],
            [74.409949760000075, 35.589209940000046],
            [74.430477500000052, 35.576013000000046],
            [74.455404500000043, 35.568682000000024],
            [74.473732785000038, 35.563550153000051],
            [74.486196988000074, 35.579678985000044],
            [74.488396999000031, 35.579575871000031],
            [74.53311750000006, 35.577480000000037],
            [74.555111500000066, 35.606805500000064],
            [74.566843000000063, 35.623668000000066],
            [74.587370500000077, 35.623668000000066],
            [74.607898500000033, 35.626600000000053],
            [74.612447360000033, 35.632390717000078],
            [74.612602000000038, 35.632282000000032],
            [74.622455000000059, 35.626651000000038],
            [74.629493000000082, 35.620552000000032],
            [74.634185000000059, 35.613514000000066],
            [74.634185000000059, 35.608353000000079],
            [74.629493000000082, 35.601315000000056],
            [74.625740000000064, 35.598500000000058],
            [74.629962000000035, 35.593808000000024],
            [74.634185000000059, 35.594746000000043],
            [74.64356900000007, 35.587708000000077],
            [74.647792000000038, 35.581139000000064],
            [74.654361000000051, 35.57269400000007],
            [74.665621000000044, 35.568002000000035],
            [74.665621000000044, 35.561433000000079],
            [74.66749800000008, 35.55298700000003],
            [74.669375000000059, 35.544542000000035],
            [74.670313000000078, 35.535627000000034],
            [74.671252000000038, 35.527651000000048],
            [74.676413000000082, 35.520613000000026],
            [74.677351000000044, 35.509821000000045],
            [74.682513000000029, 35.507006000000047],
            [74.69095800000008, 35.500906000000043],
            [74.699873000000082, 35.493869000000075],
            [74.705972000000031, 35.484485000000063],
            [74.709726000000046, 35.475101000000052],
            [74.713010000000054, 35.468063000000029],
            [74.715356000000043, 35.461494000000073],
            [74.718172000000038, 35.453518000000031],
            [74.716295000000059, 35.446949000000075],
            [74.713480000000061, 35.440849000000071],
            [74.714887000000033, 35.43146500000006],
            [74.720987000000036, 35.423020000000065],
            [74.723333000000082, 35.414105000000063],
            [74.72755600000005, 35.405659000000071],
            [74.730371000000048, 35.39580600000005],
            [74.730371000000048, 35.386891000000048],
            [74.728025000000059, 35.379384000000073],
            [74.724271000000044, 35.375162000000046],
            [74.720518000000084, 35.370470000000068],
            [74.720518000000084, 35.362962000000039],
            [74.718172000000038, 35.357801000000052],
            [74.71582600000005, 35.351232000000039],
            [74.706442000000038, 35.34888600000005],
            [74.696119000000067, 35.341848000000027],
            [74.689081000000044, 35.340441000000055],
            [74.681574000000069, 35.334810000000061],
            [74.674536000000046, 35.330588000000034],
            [74.674067000000036, 35.323081000000059],
            [74.672190000000057, 35.314635000000067],
            [74.667967000000033, 35.308066000000053],
            [74.663745000000063, 35.299151000000052],
            [74.657645000000059, 35.291175000000067],
            [74.652015000000063, 35.286952000000042],
            [74.645915000000059, 35.280853000000036],
            [74.642162000000042, 35.27428400000008],
            [74.639816000000053, 35.266777000000047],
            [74.631370000000061, 35.266308000000038],
            [74.623394000000076, 35.264900000000068],
            [74.616356000000053, 35.264900000000068],
            [74.60978700000004, 35.264900000000068],
            [74.604157000000043, 35.26255400000008],
            [74.598995000000059, 35.259270000000072],
            [74.594303000000082, 35.255516000000057],
            [74.589611000000048, 35.24988600000006],
            [74.588673000000028, 35.244255000000067],
            [74.583512000000042, 35.239563000000032],
            [74.575066000000049, 35.236748000000034],
            [74.567559000000074, 35.233464000000026],
            [74.557237000000043, 35.230179000000078],
            [74.547384000000079, 35.227833000000032],
            [74.538469000000077, 35.22642600000006],
            [74.530493000000035, 35.225487000000044],
            [74.520170000000064, 35.224549000000025],
            [74.511725000000069, 35.225018000000034],
            [74.499056000000053, 35.225957000000051],
            [74.481696000000056, 35.217511000000059],
            [74.474189000000081, 35.212819000000025],
            [74.463397000000043, 35.20437400000003],
            [74.455421000000058, 35.196866000000057],
            [74.447445000000073, 35.187013000000036],
            [74.439938000000041, 35.18091400000003],
            [74.431492000000048, 35.171999000000028],
            [74.422108000000037, 35.164961000000062],
            [74.411786000000063, 35.155577000000051],
            [74.409909000000084, 35.148539000000028],
            [74.419762000000048, 35.148539000000028],
            [74.424454000000082, 35.143378000000041],
            [74.438822565000066, 35.130863435000037],
            [74.430813000000057, 35.12285200000008],
            [74.426654000000042, 35.107998500000065],
            [74.419524000000081, 35.106810000000053],
            [74.407641000000069, 35.096115000000054],
            [74.385063000000059, 35.077696500000059],
            [74.371992000000034, 35.063437000000079],
            [74.363079000000084, 35.057495000000074],
            [74.349414000000081, 35.056901000000039],
            [74.332777500000077, 35.062842500000045],
            [74.316141000000073, 35.066407500000025],
            [74.301287000000059, 35.065813000000048],
            [74.292969000000085, 35.062248000000068],
            [74.282274000000029, 35.046206000000041],
            [74.268014500000049, 35.042047000000025],
            [74.25732000000005, 35.039670000000058],
            [74.243060000000071, 35.045612000000062],
            [74.238901000000055, 35.052148000000045],
            [74.226423500000067, 35.068784000000051],
            [74.223453000000063, 35.08126100000004],
            [74.21038150000004, 35.084826000000078],
            [74.190180000000055, 35.084826000000078],
            [74.169385000000034, 35.078885000000071],
            [74.150966000000039, 35.087203000000045],
            [74.142053500000031, 35.099086000000057],
            [74.128388000000029, 35.104433500000027],
            [74.128577299000028, 35.105233168000041],
            [74.131948500000078, 35.119287500000041],
            [74.116871845000048, 35.123196697000026],
            [74.115906500000051, 35.123447000000056],
            [74.112935500000049, 35.133547500000077],
            [74.113250865000055, 35.13459868700005],
            [74.116500500000029, 35.145430500000032],
            [74.11234150000007, 35.153154500000028],
            [74.11233971300004, 35.153153675000056],
            [74.112339000000077, 35.153155000000027],
            [74.096891952000078, 35.146025440000074],
            [74.080257500000073, 35.15612550000003],
            [74.080256312000074, 35.156125203000045],
            [74.080255000000079, 35.156126000000029],
            [74.065995000000044, 35.152561000000048],
            [74.054707000000064, 35.152561000000048],
            [74.035694883000076, 35.149590138000065],
            [74.035694000000035, 35.149590000000046],
            [74.018463000000054, 35.167414000000065],
            [74.007768000000056, 35.170979000000045],
            [74.003015168000047, 35.184644518000027],
            [74.003015000000062, 35.184645000000046],
            [73.991726000000028, 35.181674000000044],
            [73.97509000000008, 35.174544000000026],
            [73.957860949000064, 35.165038523000078],
            [73.957860000000039, 35.165038000000038],
            [73.957839179000075, 35.165076510000063],
            [73.957839457000034, 35.165078419000054],
            [73.957748347000063, 35.165247546000046],
            [73.957747312000038, 35.16524946800007],
            [73.945385000000044, 35.188210000000026],
            [73.938983957000062, 35.195386892000045],
            [73.925778000000037, 35.210193500000059],
            [73.900824000000057, 35.21197600000005],
            [73.899252933000071, 35.211469214000033],
            [73.882405000000062, 35.206034500000044],
            [73.871116000000029, 35.204846000000032],
            [73.865924428000085, 35.208183573000042],
            [73.862798000000055, 35.210193500000059],
            [73.847350000000063, 35.226236000000029],
            [73.832496000000049, 35.227424000000042],
            [73.820019000000059, 35.23811900000004],
            [73.809335193000038, 35.242867025000066],
            [73.799970908000034, 35.235694382000077],
            [73.78507090800008, 35.233894382000074],
            [73.765470908000054, 35.22089438200004],
            [73.765366073000052, 35.22089438200004],
            [73.765356500000053, 35.220888000000059],
            [73.74990850000006, 35.220888000000059],
            [73.74990850000006, 35.22089438200004],
            [73.74990850000006, 35.227863877000061],
            [73.74990850000006, 35.230989000000079],
            [73.749870908000048, 35.23105948400007],
            [73.735648500000082, 35.257726000000048],
            [73.734861760000058, 35.260086370000067],
            [73.734058877000052, 35.262495170000079],
            [73.734058835000042, 35.26249529100005],
            [73.73027090800008, 35.273694382000031],
            [73.711870908000037, 35.279694382000059],
            [73.711402756000041, 35.281340986000032],
            [73.708911500000056, 35.289810500000044],
            [73.709665538000081, 35.298103646000072],
            [73.710100000000068, 35.302882000000068],
            [73.708416559000057, 35.304659021000077],
            [73.708416459000034, 35.304659129000072],
            [73.708416381000063, 35.304659212000047],
            [73.699405500000069, 35.314171000000044],
            [73.698139664000053, 35.317738233000057],
            [73.692869500000029, 35.332590000000039],
            [73.692869500000029, 35.339194914000075],
            [73.692869500000029, 35.349226000000044],
            [73.692870908000032, 35.349226650000048],
            [73.697927320000076, 35.351560429000074],
            [73.700593500000082, 35.352791000000025],
            [73.700585261000072, 35.352854853000053],
            [73.698296587000073, 35.370593162000034],
            [73.698217000000056, 35.371210000000076],
            [73.698271490000081, 35.371289918000059],
            [73.698425606000058, 35.371515951000049],
            [73.698510442000043, 35.371640376000073],
            [73.707129500000065, 35.384281500000043],
            [73.709902668000041, 35.390154148000079],
            [73.717230000000029, 35.405671000000041],
            [73.727330500000051, 35.419337000000041],
            [73.746445526000059, 35.429116537000027],
            [73.752879000000064, 35.432408000000066],
            [73.752886078000074, 35.432450470000049],
            [73.754472669000052, 35.441970015000038],
            [73.754661500000054, 35.443103000000065],
            [73.757108673000062, 35.443685693000077],
            [73.767139000000043, 35.446074000000067],
            [73.767224192000072, 35.446083259000034],
            [73.794470000000047, 35.44904450000007],
            [73.794440893000058, 35.449091072000044],
            [73.785558000000037, 35.463304000000051],
            [73.785897662000082, 35.472137754000073],
            [73.786152000000072, 35.478752500000041],
            [73.785811548000083, 35.480795036000075],
            [73.785489838000046, 35.48272514100006],
            [73.78318100000007, 35.496577000000059],
            [73.785101914000052, 35.519143098000029],
            [73.785122939000075, 35.519390090000059],
            [73.785556824000082, 35.524488677000079],
            [73.773670908000042, 35.523894382000037],
            [73.751070908000031, 35.523894382000037],
            [73.746694625000032, 35.526027820000024],
            [73.735055000000045, 35.531632500000057],
            [73.711883000000057, 35.532821000000069],
            [73.701678033000064, 35.531800435000036],
            [73.692869464000069, 35.530919519000065],
            [73.682175000000029, 35.529850000000067],
            [73.666267780000055, 35.530946763000031],
            [73.664944500000047, 35.531038000000024],
            [73.647714000000065, 35.534009000000026],
            [73.634606420000068, 35.537033892000068],
            [73.631670908000046, 35.537694382000041],
            [73.631670751000058, 35.537694418000058],
            [73.616870908000067, 35.54109438200004],
            [73.588270908000084, 35.54109438200004],
            [73.58557094400004, 35.540994383000054],
            [73.585570908000079, 35.540994382000065],
            [73.572370908000039, 35.540494382000077],
            [73.572189716000082, 35.540546151000058],
            [73.552032915000041, 35.540893592000032],
            [73.551507916000048, 35.540859558000079],
            [73.542062129000044, 35.541010288000052],
            [73.542070908000085, 35.540194382000038],
            [73.542038741000056, 35.541010662000076],
            [73.541870872000061, 35.541014034000057],
            [73.537870908000059, 35.54109438200004],
            [73.529470908000064, 35.541494382000053],
            [73.514070944000082, 35.542194380000069],
            [73.514070908000065, 35.542194382000048],
            [73.499870908000048, 35.542894382000043],
            [73.481870908000076, 35.54109438200004],
            [73.471594236000044, 35.538181742000063],
            [73.44451250000003, 35.530444000000045],
            [73.430062866000071, 35.527553965000038],
            [73.419970908000039, 35.525494382000034],
            [73.417870908000054, 35.525094382000077],
            [73.417831345000081, 35.525107569000056],
            [73.41777600000006, 35.525096500000075],
            [73.405298500000072, 35.529256000000032],
            [73.405277365000074, 35.529292230000067],
            [73.405057905000035, 35.529668437000055],
            [73.40505788300004, 35.529668474000061],
            [73.401139500000056, 35.536385500000051],
            [73.401568689000044, 35.540893245000063],
            [73.401594853000063, 35.541168046000053],
            [73.401594858000067, 35.541168109000068],
            [73.402270944000065, 35.548268989000064],
            [73.402327500000069, 35.54886300000004],
            [73.402270944000065, 35.549466429000063],
            [73.40179607500005, 35.554533018000029],
            [73.401796072000081, 35.554533066000033],
            [73.400545500000078, 35.567876000000069],
            [73.397950715000036, 35.577101739000057],
            [73.39519800000005, 35.586889000000042],
            [73.385097500000029, 35.593425000000025],
            [73.375505284000042, 35.596713583000053],
            [73.375505253000028, 35.596713593000061],
            [73.364270908000037, 35.600494382000079],
            [73.36069855900007, 35.60441525400006],
            [73.355983500000036, 35.609467000000052],
            [73.353013000000033, 35.621944000000042],
            [73.348854000000074, 35.629668000000038],
            [73.346290092000061, 35.632944300000077],
            [73.338070908000077, 35.643294382000079],
            [73.335259520000079, 35.649133419000066],
            [73.332811500000048, 35.654029000000037],
            [73.331029500000056, 35.662941000000046],
            [73.325306977000082, 35.663981141000079],
            [73.324493500000074, 35.664129000000059],
            [73.320437383000069, 35.662970064000035],
            [73.320437316000039, 35.662970045000066],
            [73.312016500000084, 35.660564000000079],
            [73.296568500000035, 35.662347000000068],
            [73.29062650000003, 35.670665000000042],
            [73.275178500000038, 35.669477000000029],
            [73.265955744000053, 35.666564508000079],
            [73.263870908000058, 35.665894382000033],
            [73.252670908000084, 35.661694382000064],
            [73.247518453000055, 35.660058682000056],
            [73.240123500000038, 35.657593500000075],
            [73.240080683000031, 35.657697486000075],
            [73.240070908000064, 35.657694382000045],
            [73.239695038000036, 35.658634057000029],
            [73.239695011000038, 35.658634125000049],
            [73.23596450000008, 35.66769400000004],
            [73.21873400000004, 35.668882500000052],
            [73.218700876000071, 35.66889231600004],
            [73.218670908000036, 35.668894382000076],
            [73.216821468000035, 35.669449215000043],
            [73.20269200000007, 35.673636000000045],
            [73.191997000000072, 35.677201000000025],
            [73.183084500000064, 35.689678000000072],
            [73.182765158000052, 35.689873156000033],
            [73.161695000000066, 35.702749500000039],
            [73.148623500000042, 35.71285000000006],
            [73.13495800000004, 35.715227000000027],
            [73.128344037000033, 35.719636061000074],
            [73.126046000000031, 35.721168000000034],
            [73.117637865000063, 35.734886614000061],
            [73.114851395000073, 35.739432987000043],
            [73.114757000000054, 35.739587000000029],
            [73.113180490000047, 35.742740019000053],
            [73.113180442000044, 35.742740117000039],
            [73.110003500000062, 35.749094000000071],
            [73.111786000000052, 35.759194000000036],
            [73.111856965000072, 35.759244694000074],
            [73.11186288600004, 35.759248924000076],
            [73.117516112000033, 35.763287331000072],
            [73.117516137000052, 35.763287349000052],
            [73.117516168000066, 35.763287371000047],
            [73.120104000000083, 35.765136000000041],
            [73.130205000000046, 35.765136000000041],
            [73.14149400000008, 35.764542000000063],
            [73.14669007100008, 35.763614042000029],
            [73.14669013300005, 35.763614031000031],
            [73.158130000000028, 35.76157100000006],
            [73.164009101000033, 35.764877743000056],
            [73.167636500000071, 35.766918000000032],
            [73.174172000000056, 35.773454000000072],
            [73.176494892000051, 35.775776892000067],
            [73.181870908000064, 35.781294382000056],
            [73.184901675000049, 35.783281770000031],
            [73.187838000000056, 35.785337000000027],
            [73.20026826700007, 35.787113322000039],
            [73.200315000000046, 35.787120000000073],
            [73.198339885000053, 35.800944475000051],
            [73.197344000000044, 35.807915000000037],
            [73.198841194000067, 35.811337004000052],
            [73.20147094400005, 35.817347589000065],
            [73.201503500000058, 35.817422000000079],
            [73.20147094400005, 35.817682396000066],
            [73.200315000000046, 35.826928000000066],
            [73.19793125800004, 35.83050412800003],
            [73.194131718000051, 35.83620425600003],
            [73.194204484000068, 35.836254008000026],
            [73.19424250000003, 35.836280000000045],
            [73.215062500000045, 35.846092000000056],
            [73.22750700000006, 35.854707000000076],
            [73.239951500000075, 35.862126000000046],
            [73.254310000000032, 35.86451900000003],
            [73.26819050000006, 35.867152000000033],
            [73.271780000000035, 35.872656000000063],
            [73.265558000000055, 35.882229000000052],
            [73.259814500000061, 35.891083000000037],
            [73.264840000000049, 35.894673000000068],
            [73.265318500000035, 35.907835000000034],
            [73.261489500000039, 35.921955000000025],
            [73.267233000000033, 35.932963000000029],
            [73.27465200000006, 35.938228000000038],
            [73.301934000000074, 35.941818000000069],
            [73.322754000000032, 35.940621000000078],
            [73.328258500000061, 35.943015000000059],
            [73.341899500000068, 35.946604000000036],
            [73.354104500000062, 35.940861000000041],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "59",
      properties: { name: "Ghanche", count: 1482 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [76.708998000000065, 35.42674100000005],
            [76.725549000000058, 35.425314000000071],
            [76.744097000000068, 35.451852000000031],
            [76.757223000000067, 35.473254000000054],
            [76.790324000000055, 35.522336000000053],
            [76.818574000000069, 35.555438000000038],
            [76.82913300000007, 35.577125000000024],
            [76.846539000000064, 35.588254000000063],
            [76.874219000000039, 35.606231000000037],
            [76.895707135000066, 35.623590678000028],
            [76.896455526000068, 35.628284049000058],
            [76.912480000000073, 35.617136000000073],
            [76.936015000000054, 35.604269000000045],
            [76.966035547000047, 35.596058867000067],
            [76.966057000000035, 35.59605300000004],
            [76.990404000000069, 35.61043200000006],
            [77.010902659000067, 35.615561409000065],
            [77.010925000000043, 35.615567000000055],
            [77.02283324800004, 35.601993137000079],
            [77.022836000000041, 35.601990000000058],
            [77.052971268000078, 35.603245969000056],
            [77.052996000000064, 35.603247000000067],
            [77.070440000000076, 35.589900000000057],
            [77.088342000000068, 35.573986000000048],
            [77.106355000000065, 35.562178000000074],
            [77.128357000000051, 35.55688200000003],
            [77.151503000000048, 35.542671000000041],
            [77.173050000000046, 35.533431000000064],
            [77.186390434000032, 35.522139864000053],
            [77.186395000000061, 35.522136000000046],
            [77.220254000000068, 35.523165000000063],
            [77.248981000000072, 35.532408000000032],
            [77.302319607000072, 35.543703376000053],
            [77.302332000000035, 35.543706000000043],
            [77.323878000000036, 35.538573000000042],
            [77.339268000000061, 35.521118000000058],
            [77.345781535000071, 35.497575682000047],
            [77.345782000000042, 35.497574000000043],
            [77.376180373000068, 35.496477321000043],
            [77.376217000000054, 35.49647600000003],
            [77.380322000000035, 35.477994000000024],
            [77.387507077000066, 35.467739164000079],
            [77.387517000000059, 35.46772500000003],
            [77.403917354000043, 35.47388150200004],
            [77.403932000000054, 35.473887000000047],
            [77.426504000000079, 35.465674000000035],
            [77.45108805700005, 35.462599745000034],
            [77.451126000000045, 35.462595000000078],
            [77.478824000000031, 35.482107000000042],
            [77.505497000000048, 35.48621600000007],
            [77.529647585000077, 35.484440000000063],
            [77.535930000000064, 35.483978000000036],
            [77.586345000000051, 35.478031000000044],
            [77.583467975000076, 35.465693470000076],
            [77.583466000000044, 35.465685000000065],
            [77.607104255000081, 35.468035241000052],
            [77.607132000000036, 35.468038000000035],
            [77.637792739000076, 35.456464330000074],
            [77.63783600000005, 35.45644800000008],
            [77.638860000000079, 35.474930000000029],
            [77.646040000000085, 35.482118000000071],
            [77.661384442000042, 35.483143157000029],
            [77.66142700000006, 35.483146000000033],
            [77.666557000000068, 35.468772000000058],
            [77.689835833000075, 35.451552609000032],
            [77.690160000000049, 35.451317000000074],
            [77.686452000000031, 35.430508000000032],
            [77.681845000000067, 35.422831000000031],
            [77.669562000000042, 35.416689000000076],
            [77.653441000000043, 35.407477000000029],
            [77.63194500000003, 35.389052000000049],
            [77.611985000000061, 35.371395000000064],
            [77.613769876000049, 35.359347815000035],
            [77.615056000000038, 35.350667000000044],
            [77.607379000000037, 35.340687000000059],
            [77.580509000000063, 35.324565000000064],
            [77.530609000000084, 35.299231000000077],
            [77.497598000000039, 35.273897000000034],
            [77.47917300000006, 35.264684000000045],
            [77.452303000000029, 35.253169000000071],
            [77.446896621000064, 35.251109204000045],
            [77.446920068000054, 35.251128749000031],
            [77.446880402000033, 35.251103024000031],
            [77.41161500000004, 35.221693000000073],
            [77.374766000000079, 35.192520000000059],
            [77.335497548000035, 35.171778136000057],
            [77.334057374000054, 35.171031601000038],
            [77.312557951000031, 35.155672912000057],
            [77.274942826000029, 35.135711826000033],
            [77.231952910000075, 35.110377225000036],
            [77.199709934000055, 35.085816775000069],
            [77.172067602000084, 35.059707780000053],
            [77.147093631000075, 35.056680609000068],
            [77.121405304000064, 35.053566849000049],
            [77.089929625000082, 35.046657788000061],
            [77.053849761000038, 35.037444801000049],
            [77.041666386000031, 35.030919447000031],
            [77.032352081000056, 35.025930752000079],
            [77.010442891000082, 35.018626722000079],
            [76.997807228000056, 35.014414276000025],
            [76.972565553000038, 35.004708962000052],
            [76.967870239000035, 35.002902376000065],
            [76.950207445000046, 34.982171784000059],
            [76.907986720000054, 34.965281763000064],
            [76.901400416000058, 34.963086116000056],
            [76.88495666700004, 34.957604338000067],
            [76.872673970000051, 34.952997622000055],
            [76.836593766000078, 34.935340514000075],
            [76.818166027000075, 34.917684306000069],
            [76.787459693000073, 34.900027169000055],
            [76.744470688000035, 34.878530541000032],
            [76.74203781600005, 34.876225888000079],
            [76.700709112000084, 34.837075305000042],
            [76.674609000000032, 34.822488500000077],
            [76.63929550000006, 34.810205500000052],
            [76.599375500000065, 34.802528000000052],
            [76.566364500000077, 34.791780500000073],
            [76.533353500000032, 34.793316000000061],
            [76.513393500000063, 34.800993000000062],
            [76.507435220000048, 34.800181384000041],
            [76.495265500000073, 34.810938000000078],
            [76.479000000000042, 34.821782000000042],
            [76.46216400000003, 34.832055000000025],
            [76.452176500000064, 34.850032000000056],
            [76.451605500000085, 34.872005000000058],
            [76.432201500000076, 34.904250000000047],
            [76.413368500000047, 34.933357000000058],
            [76.407946500000037, 34.953332000000046],
            [76.41308250000003, 34.979585000000043],
            [76.394249500000058, 34.996421000000055],
            [76.39481950000004, 35.010974000000033],
            [76.36856750000004, 35.026098000000047],
            [76.332326500000079, 35.04807100000005],
            [76.290094500000066, 35.04949750000003],
            [76.267551000000083, 35.073753000000067],
            [76.230169500000045, 35.070043000000055],
            [76.215045500000031, 35.076321000000064],
            [76.187651500000072, 35.080031000000076],
            [76.153408500000069, 35.092872000000057],
            [76.125158500000055, 35.110564000000068],
            [76.075221000000056, 35.131395000000055],
            [76.063852538000049, 35.133921352000073],
            [76.052107000000035, 35.136531500000046],
            [76.026140000000055, 35.144236000000035],
            [76.001884500000074, 35.144807000000071],
            [75.98764024500008, 35.15389338500006],
            [75.98533400000008, 35.155365000000074],
            [75.963932000000057, 35.172487000000046],
            [75.923411000000044, 35.213863500000059],
            [75.910988171000042, 35.226569493000056],
            [75.910857000000078, 35.226705000000038],
            [75.908859000000064, 35.247821000000044],
            [75.914281000000074, 35.264657000000057],
            [75.926551000000075, 35.282635000000028],
            [75.945385000000044, 35.308888000000024],
            [75.966787000000068, 35.335141000000078],
            [75.984193000000062, 35.357399000000044],
            [75.993325000000084, 35.375376000000074],
            [75.994466000000045, 35.40248500000007],
            [75.993895000000066, 35.43901100000005],
            [76.006166000000064, 35.461269000000073],
            [76.03356000000008, 35.477534000000048],
            [76.060383000000058, 35.494656000000077],
            [76.094626000000062, 35.507497000000058],
            [76.121450000000038, 35.510636000000034],
            [76.145181182000044, 35.520564781000076],
            [76.149415000000033, 35.522336000000053],
            [76.176809000000048, 35.550301000000047],
            [76.201064000000031, 35.574841000000049],
            [76.221610000000055, 35.593104000000039],
            [76.247863000000052, 35.607943000000034],
            [76.277825000000064, 35.617075000000057],
            [76.306361000000038, 35.639618000000041],
            [76.329189000000042, 35.651888000000042],
            [76.359151000000054, 35.663017000000025],
            [76.40452300000004, 35.679853000000037],
            [76.438195000000064, 35.687273000000062],
            [76.453319000000079, 35.680424000000073],
            [76.493839000000037, 35.639047000000062],
            [76.53407500000003, 35.612224000000026],
            [76.55233700000008, 35.596529000000032],
            [76.574881000000062, 35.58540000000005],
            [76.609979000000067, 35.564569000000063],
            [76.628813000000036, 35.540313000000026],
            [76.64450800000003, 35.516343000000063],
            [76.659061000000065, 35.489234000000067],
            [76.670190000000048, 35.459557000000075],
            [76.677609000000075, 35.450996000000032],
            [76.692447000000072, 35.443577000000062],
            [76.708998000000065, 35.42674100000005],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "60",
      properties: { name: "Ghinzer", count: 845 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.728568775000042, 36.90991907700004],
            [73.72856900000005, 36.909919000000059],
            [73.755250000000046, 36.91258700000003],
            [73.769644080000035, 36.910084080000047],
            [73.775706000000071, 36.90903000000003],
            [73.800607677000073, 36.900135115000069],
            [73.800608000000068, 36.900135000000034],
            [73.842407318000085, 36.907249884000066],
            [73.842408000000034, 36.907250000000033],
            [73.878872000000058, 36.893909000000065],
            [73.890433683000083, 36.885904220000043],
            [73.890434000000084, 36.885904000000039],
            [73.910887376000062, 36.888571788000036],
            [73.910889000000054, 36.888572000000067],
            [73.945574000000079, 36.869004000000075],
            [73.974922000000049, 36.840542000000028],
            [74.00249000000008, 36.840542000000028],
            [74.002492000000075, 36.840542000000028],
            [74.022056213000042, 36.841430919000061],
            [74.022058000000072, 36.841431000000057],
            [74.040733612000054, 36.834316148000028],
            [74.040734000000043, 36.834316000000058],
            [74.046054192000042, 36.835603803000026],
            [74.044494703000055, 36.828882687000032],
            [74.051938500000063, 36.818132000000048],
            [74.068868000000066, 36.817074000000048],
            [74.068868000000066, 36.804377000000045],
            [74.090030500000069, 36.800144000000046],
            [74.111192500000072, 36.800144000000046],
            [74.116483000000073, 36.77475000000004],
            [74.114367000000072, 36.757820000000038],
            [74.09955350000007, 36.731367000000034],
            [74.138703500000076, 36.735599000000036],
            [74.16515650000008, 36.737716000000034],
            [74.188434500000085, 36.728193000000033],
            [74.195841500000029, 36.728193000000033],
            [74.188434500000085, 36.704914000000031],
            [74.187376500000084, 36.691159000000027],
            [74.20430650000003, 36.686926000000028],
            [74.222294500000032, 36.690101000000027],
            [74.246630500000038, 36.682694000000026],
            [74.280490333000046, 36.659415000000024],
            [74.294245500000045, 36.654125000000079],
            [74.293187500000045, 36.646718000000078],
            [74.310117500000047, 36.641427000000078],
            [74.320698500000049, 36.641427000000078],
            [74.313291500000048, 36.628730000000076],
            [74.297420000000045, 36.624498000000074],
            [74.284080000000074, 36.606634000000042],
            [74.281208500000048, 36.59610500000008],
            [74.265892500000064, 36.592515000000049],
            [74.254883500000062, 36.598737000000028],
            [74.235738500000082, 36.601609000000053],
            [74.225687500000049, 36.598258000000044],
            [74.220661500000062, 36.588686000000052],
            [74.20989250000008, 36.582224000000053],
            [74.202474000000052, 36.580310000000054],
            [74.194337500000074, 36.573609000000033],
            [74.191944000000035, 36.568583000000046],
            [74.189551000000051, 36.558532000000071],
            [74.189311500000031, 36.545370000000048],
            [74.187397000000033, 36.538190000000043],
            [74.168491500000073, 36.536037000000078],
            [74.154611000000045, 36.533165000000054],
            [74.139534500000082, 36.53388300000006],
            [74.131397500000048, 36.53364300000004],
            [74.123979000000077, 36.52766100000008],
            [74.123739500000056, 36.512823000000026],
            [74.124936000000048, 36.503729000000078],
            [74.121346500000072, 36.493199000000061],
            [74.119432000000074, 36.484584000000041],
            [74.108902000000057, 36.473815000000059],
            [74.095500500000071, 36.466396000000032],
            [74.091671500000075, 36.456823000000043],
            [74.090714000000048, 36.445336000000054],
            [74.083774000000062, 36.43624200000005],
            [74.080663000000072, 36.430738000000076],
            [74.08233800000005, 36.420687000000044],
            [74.090953500000069, 36.413747000000058],
            [74.103398000000084, 36.403695500000026],
            [74.105312500000082, 36.396995000000061],
            [74.089278500000034, 36.389337000000069],
            [74.081143590000067, 36.384287434000044],
            [74.075398000000064, 36.380721000000051],
            [74.071091000000081, 36.369473000000028],
            [74.061039333000053, 36.359901000000036],
            [74.052423667000085, 36.359901000000036],
            [74.043808500000068, 36.350328000000047],
            [74.040936500000043, 36.339320000000043],
            [74.035193000000049, 36.332380000000057],
            [74.032799500000067, 36.32496100000003],
            [74.030646500000046, 36.312517000000071],
            [74.033757500000036, 36.298636000000045],
            [74.036150500000076, 36.288824000000034],
            [74.035760352000068, 36.276860627000076],
            [74.03543250000007, 36.266807500000027],
            [74.03854350000006, 36.257474000000059],
            [74.042133500000034, 36.249816000000067],
            [74.045483500000046, 36.239765000000034],
            [74.054098500000066, 36.233303000000035],
            [74.064628500000083, 36.227081000000055],
            [74.078748500000074, 36.219423000000063],
            [74.08449150000007, 36.205304000000069],
            [74.091910594000069, 36.189987483000039],
            [74.096218001000068, 36.18695103400006],
            [74.106508500000075, 36.179697000000033],
            [74.117995500000063, 36.173475000000053],
            [74.120389000000046, 36.167013000000054],
            [74.121346500000072, 36.155287000000044],
            [74.113927500000045, 36.146911000000046],
            [74.099807500000054, 36.130398000000071],
            [74.094543000000044, 36.12058600000006],
            [74.096218500000077, 36.101202000000058],
            [74.106508500000075, 36.087082000000066],
            [74.112730500000055, 36.072723000000053],
            [74.11440650000003, 36.060040000000072],
            [74.102919000000043, 36.051903000000038],
            [74.092628500000046, 36.041373000000078],
            [74.085209500000076, 36.027253500000029],
            [74.080902500000036, 36.009783500000026],
            [74.078748500000074, 35.990638000000047],
            [74.083534500000042, 35.974843500000077],
            [74.078269344000034, 35.96503202100007],
            [74.070850872000051, 35.965509976000078],
            [74.063193000000069, 35.964074000000039],
            [74.056253000000083, 35.955698000000041],
            [74.045962604000067, 35.953066027000034],
            [74.038064500000075, 35.954502000000048],
            [74.026818000000048, 35.954502000000048],
            [74.021791533000055, 35.952827011000068],
            [74.012458958000082, 35.953304977000073],
            [74.005519000000049, 35.949476000000061],
            [74.002408000000059, 35.942057000000034],
            [74.002647000000081, 35.921955000000025],
            [73.999775000000056, 35.898981000000049],
            [73.982783709000046, 35.898263009000061],
            [73.968185500000061, 35.901374000000033],
            [73.95717650000006, 35.905921000000035],
            [73.948800985000048, 35.906877945000076],
            [73.945929000000035, 35.896588000000065],
            [73.946647000000041, 35.882947000000058],
            [73.950955000000079, 35.870742000000064],
            [73.953827000000047, 35.858537000000069],
            [73.949209606000068, 35.853386756000077],
            [73.947604500000068, 35.851596000000029],
            [73.932048500000064, 35.852793000000077],
            [73.919604500000048, 35.853032000000042],
            [73.906202500000063, 35.846331500000076],
            [73.889211500000044, 35.844178000000056],
            [73.880356500000062, 35.845374000000049],
            [73.871980500000063, 35.854947000000038],
            [73.862647500000037, 35.863323000000037],
            [73.846374500000081, 35.870502000000045],
            [73.836801500000036, 35.873853000000054],
            [73.828904500000078, 35.866434000000027],
            [73.813588000000038, 35.866434000000027],
            [73.804015500000048, 35.866673000000048],
            [73.793725000000052, 35.869066000000032],
            [73.780084000000045, 35.874810000000025],
            [73.770750500000076, 35.866434000000027],
            [73.769477750000078, 35.863935127000047],
            [73.764289500000075, 35.853750000000048],
            [73.753041500000052, 35.853750000000048],
            [73.737486000000047, 35.855904000000066],
            [73.722409500000083, 35.860212000000047],
            [73.716187000000048, 35.86451900000003],
            [73.702306500000077, 35.869545000000073],
            [73.68651200000005, 35.878639000000078],
            [73.681007500000078, 35.882707000000039],
            [73.669999500000074, 35.878878000000043],
            [73.658751500000051, 35.874331000000041],
            [73.651333000000079, 35.878160000000037],
            [73.641999500000054, 35.873613000000034],
            [73.634820500000046, 35.865955000000042],
            [73.626922500000035, 35.860451000000069],
            [73.615914500000031, 35.865237000000036],
            [73.603470000000073, 35.867391000000055],
            [73.587914500000068, 35.87026300000008],
            [73.576906000000065, 35.870742000000064],
            [73.566376500000047, 35.875767000000053],
            [73.558479000000034, 35.881511000000046],
            [73.549385000000029, 35.872656000000063],
            [73.54220550000008, 35.860451000000069],
            [73.531915000000083, 35.856143000000031],
            [73.518274000000076, 35.856861000000038],
            [73.505590500000039, 35.875767000000053],
            [73.489077500000064, 35.872177000000079],
            [73.472565000000031, 35.871699000000035],
            [73.457009500000083, 35.878400000000056],
            [73.442172000000085, 35.884382500000072],
            [73.422548500000062, 35.884382500000072],
            [73.407471500000042, 35.892280000000028],
            [73.400052500000072, 35.900895000000048],
            [73.394070000000056, 35.904964000000064],
            [73.38377950000006, 35.901852000000076],
            [73.379471500000079, 35.907596000000069],
            [73.380429000000049, 35.921237000000076],
            [73.371095500000081, 35.930570000000046],
            [73.354104500000062, 35.940861000000041],
            [73.341899500000068, 35.946604000000036],
            [73.328258500000061, 35.943015000000059],
            [73.322754000000032, 35.940621000000078],
            [73.301934000000074, 35.941818000000069],
            [73.27465200000006, 35.938228000000038],
            [73.267233000000033, 35.932963000000029],
            [73.261489500000039, 35.921955000000025],
            [73.265318500000035, 35.907835000000034],
            [73.264840000000049, 35.894673000000068],
            [73.259814500000061, 35.891083000000037],
            [73.265558000000055, 35.882229000000052],
            [73.271780000000035, 35.872656000000063],
            [73.26819050000006, 35.867152000000033],
            [73.254310000000032, 35.86451900000003],
            [73.239951500000075, 35.862126000000046],
            [73.22750700000006, 35.854707000000076],
            [73.215062500000045, 35.846092000000056],
            [73.19424250000003, 35.836280000000045],
            [73.194204484000068, 35.836254008000026],
            [73.194131718000051, 35.83620425600003],
            [73.193185500000084, 35.837623000000065],
            [73.189620500000046, 35.845941000000039],
            [73.17833150000007, 35.860201000000075],
            [73.16941950000006, 35.857824000000051],
            [73.161101000000031, 35.850100000000054],
            [73.149812000000054, 35.843565000000069],
            [73.133831763000046, 35.845390830000042],
            [73.129070908000074, 35.84589438200004],
            [73.116470908000053, 35.853694382000072],
            [73.114480017000062, 35.855256465000025],
            [73.103468000000078, 35.863766000000055],
            [73.092179000000044, 35.875649000000067],
            [73.086861561000035, 35.873285860000067],
            [73.076033442000039, 35.863413163000075],
            [73.07603294300003, 35.863412707000066],
            [73.073166000000072, 35.860795000000053],
            [73.066630500000031, 35.861983500000065],
            [73.061844579000081, 35.861983500000065],
            [73.055935500000032, 35.861983500000065],
            [73.053857098000037, 35.859073667000075],
            [73.053070908000052, 35.85789438200004],
            [73.047770908000075, 35.850094382000066],
            [73.034530410000059, 35.848899751000033],
            [73.032310972000062, 35.84715589700005],
            [73.026270908000072, 35.842294382000034],
            [73.016070908000074, 35.84589438200004],
            [73.014670684000066, 35.848530098000026],
            [73.010779500000069, 35.855448000000024],
            [73.003473403000044, 35.859831782000072],
            [72.998896500000058, 35.862578000000042],
            [72.991338039000084, 35.862056425000048],
            [72.981666000000075, 35.861389000000031],
            [72.975724500000069, 35.854854000000046],
            [72.967550693000078, 35.851709984000024],
            [72.960370908000073, 35.848894382000026],
            [72.947370908000039, 35.848894382000026],
            [72.937940678000075, 35.845986382000035],
            [72.937940643000047, 35.845986371000038],
            [72.924627000000044, 35.84178200000008],
            [72.903831500000081, 35.84178200000008],
            [72.891948500000069, 35.849506000000076],
            [72.891229897000073, 35.853098716000034],
            [72.890760000000057, 35.855448000000024],
            [72.896107500000085, 35.865548000000047],
            [72.890760000000057, 35.877431500000057],
            [72.890702002000069, 35.877425286000062],
            [72.874124000000052, 35.875649000000067],
            [72.836692000000085, 35.873272500000041],
            [72.827185500000041, 35.879214000000047],
            [72.820261769000069, 35.887202129000059],
            [72.820261750000043, 35.887202150000064],
            [72.819470908000028, 35.888094382000077],
            [72.817658881000057, 35.884671665000042],
            [72.817658851000033, 35.884671609000065],
            [72.812332000000083, 35.874461000000053],
            [72.81129999500007, 35.870333326000036],
            [72.809470908000037, 35.862494382000079],
            [72.797470908000037, 35.866094382000028],
            [72.79640061300006, 35.864825884000027],
            [72.792130000000043, 35.85960700000004],
            [72.789748153000062, 35.852459515000078],
            [72.78972260200004, 35.85238286200007],
            [72.788979931000028, 35.850154825000061],
            [72.787454934000039, 35.853424764000067],
            [72.786325305000048, 35.84994765700003],
            [72.785718216000078, 35.848077587000034],
            [72.783907955000075, 35.84757036600007],
            [72.777192552000031, 35.845692983000049],
            [72.772264365000069, 35.844315069000061],
            [72.767214091000028, 35.842904087000079],
            [72.766532822000045, 35.842713006000054],
            [72.761153801000034, 35.841208949000077],
            [72.760137714000052, 35.840924804000053],
            [72.757408019000081, 35.84016106100006],
            [72.752782957000079, 35.838867157000038],
            [72.744269579000047, 35.836487104000071],
            [72.738174264000065, 35.834777461000044],
            [72.732376763000048, 35.833163998000032],
            [72.725440908000053, 35.831224203000033],
            [72.722669753000048, 35.830449234000071],
            [72.71859170700003, 35.829309518000059],
            [72.717734398000061, 35.829069735000076],
            [72.716920255000048, 35.828842112000075],
            [72.716369388000032, 35.828701445000036],
            [72.712925724000058, 35.827839568000059],
            [72.705264773000067, 35.825918737000052],
            [72.698026306000031, 35.824103369000056],
            [72.691893778000065, 35.822565849000057],
            [72.683368444000052, 35.820428002000028],
            [72.677251817000069, 35.821259869000073],
            [72.674189039000055, 35.821676641000067],
            [72.67040069400008, 35.822191960000055],
            [72.661038978000079, 35.826156260000062],
            [72.654813750000073, 35.828807321000056],
            [72.648121864000075, 35.831648664000056],
            [72.642475284000056, 35.833776879000027],
            [72.633905680000055, 35.836946019000038],
            [72.627419418000045, 35.839409696000075],
            [72.611426178000045, 35.843554156000039],
            [72.600436349000063, 35.844354549000059],
            [72.596040104000053, 35.844680949000065],
            [72.595527165000078, 35.844719037000061],
            [72.59520556800004, 35.844752468000024],
            [72.591566685000032, 35.845155211000076],
            [72.579607645000067, 35.846488843000031],
            [72.568500363000055, 35.851801117000036],
            [72.568462243000056, 35.851886876000037],
            [72.565286459000049, 35.859031471000037],
            [72.563974500000029, 35.861983000000066],
            [72.563773408000031, 35.862284647000024],
            [72.56361224300008, 35.862644655000054],
            [72.563317582000082, 35.863119983000047],
            [72.560263258000077, 35.867735090000053],
            [72.556917430000055, 35.872680316000071],
            [72.551996974000076, 35.880254765000075],
            [72.548431341000082, 35.88293862200004],
            [72.543827886000031, 35.886309027000038],
            [72.541756551000049, 35.887835523000035],
            [72.537657511000077, 35.89082250000007],
            [72.53547205600006, 35.892430458000035],
            [72.532534954000084, 35.894565486000033],
            [72.530350031000069, 35.89617585600007],
            [72.528246559000081, 35.897710974000063],
            [72.523807075000036, 35.899033623000037],
            [72.521623335000072, 35.899663391000047],
            [72.521625397000037, 35.899657756000067],
            [72.518392870000071, 35.900606435000043],
            [72.514625071000069, 35.901724859000069],
            [72.512130066000054, 35.902444436000053],
            [72.510675174000085, 35.902164295000034],
            [72.510675050000032, 35.902164331000051],
            [72.509971983000071, 35.902368800000033],
            [72.509905801000059, 35.902388047000045],
            [72.518223945000045, 35.924371956000073],
            [72.524759992000043, 35.934472928000048],
            [72.53333699600006, 35.945432199000038],
            [72.535454544000061, 35.948137897000038],
            [72.549714169000083, 35.972498820000055],
            [72.55129048100008, 35.977114801000027],
            [72.558032834000073, 35.99685871500003],
            [72.566939344000048, 36.010480457000028],
            [72.56812224600003, 36.012289603000056],
            [72.568133406000072, 36.012306672000079],
            [72.565757075000079, 36.028942561000065],
            [72.554468277000069, 36.042608426000072],
            [72.543273033000048, 36.047646187000055],
            [72.542584908000038, 36.047955838000064],
            [72.541952083000069, 36.048588691000077],
            [72.541879194000046, 36.048661584000058],
            [72.531436581000037, 36.059104671000057],
            [72.530108108000036, 36.060433204000049],
            [72.529854869000076, 36.060956597000029],
            [72.521212922000075, 36.078817773000026],
            [72.521196337000049, 36.078852052000059],
            [72.521790474000056, 36.094299963000026],
            [72.523261566000031, 36.108522582000035],
            [72.523572620000039, 36.111529869000037],
            [72.531891188000031, 36.125195828000074],
            [72.54020973400003, 36.136484800000062],
            [72.542585832000043, 36.148961738000025],
            [72.542585935000034, 36.160250670000039],
            [72.542585977000044, 36.164894417000028],
            [72.542586081000081, 36.176201977000062],
            [72.542586082000071, 36.176292575000048],
            [72.54169425200007, 36.18209132000004],
            [72.541692090000083, 36.182105375000049],
            [72.540549476000081, 36.189532676000056],
            [72.540525872000046, 36.189686640000048],
            [72.540524976000029, 36.18968681900003],
            [72.540381363000051, 36.190627817000063],
            [72.540381312000079, 36.190628146000051],
            [72.540210239000032, 36.191740472000049],
            [72.540963950000048, 36.198776629000065],
            [72.541708139000036, 36.205729808000058],
            [72.541802192000034, 36.206606030000046],
            [72.54180247100004, 36.206608632000041],
            [72.541992379000078, 36.208377381000048],
            [72.549088242000039, 36.232620048000058],
            [72.549121000000071, 36.232737000000043],
            [72.570328102000076, 36.253944102000048],
            [72.570331520000082, 36.25394639700005],
            [72.572294606000071, 36.255909243000076],
            [72.592147800000077, 36.268593415000055],
            [72.592172090000076, 36.268608934000042],
            [72.593684584000073, 36.269575264000025],
            [72.622203383000056, 36.268981404000044],
            [72.652480572000059, 36.263637914000071],
            [72.65250625200008, 36.263634160000038],
            [72.658598501000029, 36.264684240000065],
            [72.65860781300006, 36.264685845000031],
            [72.667661523000049, 36.266246943000056],
            [72.667663576000052, 36.266247297000064],
            [72.669736035000028, 36.26660464400004],
            [72.686372003000031, 36.275517671000046],
            [72.686372075000065, 36.275517776000072],
            [72.686372147000043, 36.275517881000042],
            [72.694096053000067, 36.286806640000066],
            [72.70020069800006, 36.293465584000046],
            [72.70020134400005, 36.293466289000037],
            [72.700219894000043, 36.293486524000059],
            [72.707168094000053, 36.301065618000052],
            [72.707595529000059, 36.301428914000041],
            [72.710944874000063, 36.304275670000038],
            [72.721755593000069, 36.313464176000025],
            [72.721755936000079, 36.313464468000063],
            [72.728364393000049, 36.315429055000038],
            [72.739474186000052, 36.318732134000072],
            [72.744006029000047, 36.320079361000069],
            [72.74959128200004, 36.322657262000064],
            [72.749591432000045, 36.322657331000073],
            [72.749591510000073, 36.322657367000033],
            [72.750031044000082, 36.322859774000051],
            [72.75924083600006, 36.32711056800008],
            [72.759453474000054, 36.327208712000072],
            [72.793914444000052, 36.349786742000049],
            [72.81814659500003, 36.364562527000032],
            [72.818274913000039, 36.364640770000051],
            [72.848344438000083, 36.376432715000078],
            [72.857226985000068, 36.388276262000034],
            [72.860792230000072, 36.40431910500007],
            [72.863169729000049, 36.433431782000071],
            [72.87148796300005, 36.452444635000063],
            [72.888123930000063, 36.463139667000064],
            [72.888233357000047, 36.463172496000027],
            [72.888385834000076, 36.463270518000058],
            [72.924035187000072, 36.473965624000073],
            [72.938213694000069, 36.473485187000051],
            [72.959089314000039, 36.472777820000033],
            [72.959089625000047, 36.472777809000036],
            [72.959089936000055, 36.472777798000038],
            [72.963636143000031, 36.475050915000054],
            [72.96740791600007, 36.476936813000066],
            [72.966220093000061, 36.495355698000026],
            [72.968024194000066, 36.501283170000079],
            [72.970343993000029, 36.508904992000055],
            [72.970379190000074, 36.509020636000059],
            [72.979291634000049, 36.50961467500008],
            [73.009951364000074, 36.524355177000075],
            [73.010187558000041, 36.524468734000038],
            [73.030983040000081, 36.538134752000076],
            [73.041083558000082, 36.547641244000033],
            [73.043414627000061, 36.552123901000073],
            [73.048807640000064, 36.56249469200003],
            [73.050796760000082, 36.570650079000075],
            [73.054749323000067, 36.586855576000062],
            [73.072683952000034, 36.606899930000054],
            [73.074950890000082, 36.609433538000076],
            [73.071386051000047, 36.624287433000063],
            [73.057720846000052, 36.646271237000065],
            [73.049402647000079, 36.673008038000035],
            [73.04940270600008, 36.67944723200003],
            [73.049402756000063, 36.684890968000047],
            [73.061757187000069, 36.697244862000048],
            [73.06188028400004, 36.697367953000025],
            [73.081487161000041, 36.698556039000039],
            [73.10822403700007, 36.705092128000047],
            [73.124711134000052, 36.710980831000029],
            [73.124860477000084, 36.711034172000041],
            [73.15199448900006, 36.729910162000067],
            [73.152191464000055, 36.730047189000061],
            [73.165262863000066, 36.728858259000049],
            [73.179522743000064, 36.726482341000064],
            [73.193782118000058, 36.723511426000073],
            [73.213983425000038, 36.717569558000037],
            [73.239531745000079, 36.716975683000044],
            [73.26805108700006, 36.721134795000069],
            [73.293005487000073, 36.728858868000032],
            [73.32687235700007, 36.740147962000037],
            [73.354797785000073, 36.753220017000046],
            [73.374404694000077, 36.757973083000024],
            [73.408865963000039, 36.758567243000073],
            [73.429660755000043, 36.751437385000031],
            [73.449267528000064, 36.741337538000039],
            [73.476004274000047, 36.733613712000079],
            [73.506900530000053, 36.730048880000027],
            [73.536013841000056, 36.731237012000065],
            [73.568692660000067, 36.73599013900008],
            [73.578792433000046, 36.718760290000034],
            [73.603747180000084, 36.709847462000027],
            [73.606690067000045, 36.709682185000077],
            [73.656626790000075, 36.706877732000066],
            [73.673107752000078, 36.709820129000036],
            [73.673262703000034, 36.709847793000051],
            [73.687398319000067, 36.722805742000048],
            [73.687522725000065, 36.722919784000055],
            [73.704752623000047, 36.724701855000035],
            [73.715447550000079, 36.724701906000064],
            [73.734460365000075, 36.718761032000032],
            [73.753473283000062, 36.724108091000062],
            [73.775457170000038, 36.728267171000027],
            [73.789717040000085, 36.724702260000072],
            [73.809917820000067, 36.715790409000078],
            [73.834277598000028, 36.709848561000058],
            [73.844318424000051, 36.713983815000063],
            [73.844378567000035, 36.714008584000055],
            [73.851508551000052, 36.71757309700007],
            [73.858754078000061, 36.723007245000076],
            [73.86077464300007, 36.724522669000066],
            [73.860895664000054, 36.724613435000037],
            [73.861014551000039, 36.724702600000057],
            [73.866956640000069, 36.73896254400006],
            [73.868144779000033, 36.755004454000073],
            [73.861608905000082, 36.763917370000058],
            [73.856856085000061, 36.779959252000026],
            [73.839032403000033, 36.801349040000048],
            [73.82655455400004, 36.808478438000066],
            [73.804571204000069, 36.808478333000039],
            [73.793282814000065, 36.812042758000075],
            [73.790905929000076, 36.822737683000071],
            [73.78496407800003, 36.834620584000049],
            [73.76595125700004, 36.839968462000058],
            [73.748127401000033, 36.842344363000052],
            [73.720796089000032, 36.842344232000073],
            [73.697029866000037, 36.854822045000049],
            [73.68395905400007, 36.865516919000072],
            [73.676235248000069, 36.880964790000064],
            [73.662569483000084, 36.896412633000068],
            [73.657010125000056, 36.905377368000075],
            [73.662568969000063, 36.896414269000047],
            [73.655026949000046, 36.908576062000066],
            [73.65075362500005, 36.915465785000038],
            [73.65072039100005, 36.915519366000069],
            [73.650573000000065, 36.915757000000042],
            [73.669869795000068, 36.922371930000054],
            [73.66987000000006, 36.922372000000053],
            [73.700109000000054, 36.919703000000027],
            [73.728568775000042, 36.90991907700004],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "61",
      properties: { name: "Gilgit", count: 274 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.218870000000038, 36.195096000000035],
            [74.228310500000077, 36.184606000000031],
            [74.24404450000003, 36.184606000000031],
            [74.256632000000081, 36.184606000000031],
            [74.270268000000044, 36.177264000000037],
            [74.293264234000048, 36.180138701000033],
            [74.295442500000036, 36.180411000000049],
            [74.311177000000043, 36.183557000000064],
            [74.325862500000085, 36.179362000000026],
            [74.341596500000037, 36.177264000000037],
            [74.362575500000048, 36.164676000000043],
            [74.397190500000079, 36.126914000000056],
            [74.411875500000065, 36.106984000000068],
            [74.421316000000047, 36.08915200000007],
            [74.427609500000074, 36.086006000000054],
            [74.438099000000079, 36.090201000000036],
            [74.456980000000044, 36.111180000000047],
            [74.472714500000052, 36.130061000000069],
            [74.480056500000046, 36.14369750000003],
            [74.489497500000084, 36.155236000000059],
            [74.507329500000083, 36.152089000000046],
            [74.537748500000077, 36.147893000000067],
            [74.570266000000061, 36.146844000000044],
            [74.583902500000079, 36.15104000000008],
            [74.606979000000081, 36.147893000000067],
            [74.623762500000055, 36.14369750000003],
            [74.637398500000074, 36.134257000000048],
            [74.65418150000005, 36.127963000000079],
            [74.658377500000029, 36.115376000000026],
            [74.673062500000071, 36.106984000000068],
            [74.684600500000045, 36.103838000000053],
            [74.694041500000083, 36.10069100000004],
            [74.70920342800008, 36.096555749000061],
            [74.712667848000081, 36.095610867000062],
            [74.717118500000083, 36.094397000000072],
            [74.720265078000068, 36.097544190000065],
            [74.726559000000066, 36.086006000000054],
            [74.730755000000045, 36.079712000000029],
            [74.735999000000049, 36.070271000000048],
            [74.741244000000052, 36.063978000000077],
            [74.743342000000041, 36.062929000000054],
            [74.752783000000079, 36.062929000000054],
            [74.75907600000005, 36.062929000000054],
            [74.766419000000042, 36.059782000000041],
            [74.773761000000036, 36.053488000000073],
            [74.791594000000032, 36.049292000000037],
            [74.804181000000028, 36.052439000000049],
            [74.816768000000081, 36.052439000000049],
            [74.829355000000078, 36.05034100000006],
            [74.841943000000072, 36.049293000000034],
            [74.853481000000045, 36.046146000000078],
            [74.862922000000083, 36.03880300000003],
            [74.867117000000064, 36.026216000000034],
            [74.869215000000054, 36.015726000000029],
            [74.877607000000069, 36.006286000000046],
            [74.894384709000065, 36.004421957000034],
            [74.895290500000044, 35.998306000000071],
            [74.915085500000032, 35.990974000000051],
            [74.931214500000067, 35.974112000000048],
            [74.943678500000033, 35.963848000000041],
            [74.927549000000056, 35.940387000000044],
            [74.934881977000032, 35.939165087000049],
            [74.949544067000033, 35.936721711000075],
            [74.962739500000055, 35.92719100000005],
            [74.968605000000082, 35.901531000000034],
            [74.970071500000074, 35.869272000000024],
            [74.948077500000068, 35.855342000000064],
            [74.915085500000032, 35.850210000000061],
            [74.904741568000077, 35.842588681000052],
            [74.901155000000074, 35.839946000000054],
            [74.896023000000071, 35.818685000000073],
            [74.880627000000061, 35.805488000000025],
            [74.848369000000048, 35.796691000000067],
            [74.830774000000076, 35.763699000000031],
            [74.808779000000072, 35.754901000000075],
            [74.789717500000052, 35.749036000000046],
            [74.754100986000083, 35.72618850200007],
            [74.750861984000039, 35.724109204000058],
            [74.742063500000029, 35.729241000000059],
            [74.750861500000042, 35.749769000000072],
            [74.762591500000042, 35.764432000000056],
            [74.759659500000055, 35.781294000000059],
            [74.740597500000035, 35.791558500000065],
            [74.739131500000042, 35.801089000000047],
            [74.741330500000061, 35.810620000000029],
            [74.740597500000035, 35.838480000000061],
            [74.730333778000045, 35.859740769000041],
            [74.703940500000044, 35.848744000000067],
            [74.688544500000035, 35.841412000000048],
            [74.666549500000031, 35.839946000000054],
            [74.648954500000059, 35.827483000000029],
            [74.635024500000043, 35.81355300000007],
            [74.622561500000074, 35.797424000000035],
            [74.630625500000065, 35.785693000000038],
            [74.653353500000037, 35.773230000000069],
            [74.646754500000043, 35.761499000000072],
            [74.629892500000039, 35.75416800000005],
            [74.62182800000005, 35.748303000000078],
            [74.625493500000061, 35.729241000000059],
            [74.646021500000074, 35.713845000000049],
            [74.656285500000081, 35.705780000000061],
            [74.644555500000081, 35.694050000000061],
            [74.631358500000033, 35.677188000000058],
            [74.61596250000008, 35.664724000000035],
            [74.612297500000068, 35.652261000000067],
            [74.61596250000008, 35.636864500000058],
            [74.612447360000033, 35.632390717000078],
            [74.607898500000033, 35.626600000000053],
            [74.587370500000077, 35.623668000000066],
            [74.566843000000063, 35.623668000000066],
            [74.555111500000066, 35.606805500000064],
            [74.53311750000006, 35.577480000000037],
            [74.488396999000031, 35.579575871000031],
            [74.486196988000074, 35.579678985000044],
            [74.473732785000038, 35.563550153000051],
            [74.455404500000043, 35.568682000000024],
            [74.430477500000052, 35.576013000000046],
            [74.409949760000075, 35.589209940000046],
            [74.39821900000004, 35.586277000000052],
            [74.395286500000054, 35.572348000000034],
            [74.387956146000079, 35.576013070000045],
            [74.373292000000049, 35.583345000000065],
            [74.360828699000081, 35.598740960000043],
            [74.34983150000005, 35.597275000000025],
            [74.346166846000074, 35.599717991000034],
            [74.338834500000075, 35.604606000000047],
            [74.321972000000073, 35.619269000000031],
            [74.305842687000052, 35.633931982000036],
            [74.293379984000069, 35.633199019000074],
            [74.283848500000033, 35.644196000000079],
            [74.272851667000054, 35.644196000000079],
            [74.26112100000006, 35.63686400000006],
            [74.244992000000082, 35.63686400000006],
            [74.225930000000062, 35.649328000000025],
            [74.203935500000057, 35.67278900000008],
            [74.185607000000061, 35.688918000000058],
            [74.178275500000041, 35.705047000000036],
            [74.171677000000045, 35.722643000000062],
            [74.155548137000039, 35.730706931000043],
            [74.147145795000029, 35.727766751000047],
            [74.14088526900008, 35.725575094000078],
            [74.129888000000051, 35.741704000000027],
            [74.110826000000031, 35.755634000000043],
            [74.099829000000057, 35.769564000000059],
            [74.069770000000062, 35.771763000000078],
            [74.050708000000043, 35.778362000000072],
            [74.038978000000043, 35.793758000000025],
            [74.03018000000003, 35.803289000000063],
            [74.028714000000036, 35.819418000000042],
            [74.027248000000043, 35.829682000000048],
            [74.016984312000034, 35.830414978000078],
            [74.003787042000056, 35.816485045000036],
            [73.984725000000083, 35.819418000000042],
            [73.969329000000073, 35.820884000000035],
            [73.955400000000054, 35.831881000000067],
            [73.952467500000068, 35.843612000000064],
            [73.949209606000068, 35.853386756000077],
            [73.953827000000047, 35.858537000000069],
            [73.950955000000079, 35.870742000000064],
            [73.946647000000041, 35.882947000000058],
            [73.945929000000035, 35.896588000000065],
            [73.948800985000048, 35.906877945000076],
            [73.95717650000006, 35.905921000000035],
            [73.968185500000061, 35.901374000000033],
            [73.982783709000046, 35.898263009000061],
            [73.999775000000056, 35.898981000000049],
            [74.002647000000081, 35.921955000000025],
            [74.002408000000059, 35.942057000000034],
            [74.005519000000049, 35.949476000000061],
            [74.012458958000082, 35.953304977000073],
            [74.021791533000055, 35.952827011000068],
            [74.026818000000048, 35.954502000000048],
            [74.038064500000075, 35.954502000000048],
            [74.045962604000067, 35.953066027000034],
            [74.056253000000083, 35.955698000000041],
            [74.063193000000069, 35.964074000000039],
            [74.070850872000051, 35.965509976000078],
            [74.078269344000034, 35.96503202100007],
            [74.083534500000042, 35.974843500000077],
            [74.078748500000074, 35.990638000000047],
            [74.080902500000036, 36.009783500000026],
            [74.085209500000076, 36.027253500000029],
            [74.092628500000046, 36.041373000000078],
            [74.102919000000043, 36.051903000000038],
            [74.11440650000003, 36.060040000000072],
            [74.112730500000055, 36.072723000000053],
            [74.106508500000075, 36.087082000000066],
            [74.096218500000077, 36.101202000000058],
            [74.094543000000044, 36.12058600000006],
            [74.099807500000054, 36.130398000000071],
            [74.113927500000045, 36.146911000000046],
            [74.121346500000072, 36.155287000000044],
            [74.120389000000046, 36.167013000000054],
            [74.117995500000063, 36.173475000000053],
            [74.106508500000075, 36.179697000000033],
            [74.096218001000068, 36.18695103400006],
            [74.091910594000069, 36.189987483000039],
            [74.08449150000007, 36.205304000000069],
            [74.078748500000074, 36.219423000000063],
            [74.064628500000083, 36.227081000000055],
            [74.054098500000066, 36.233303000000035],
            [74.045483500000046, 36.239765000000034],
            [74.042133500000034, 36.249816000000067],
            [74.03854350000006, 36.257474000000059],
            [74.03543250000007, 36.266807500000027],
            [74.035760352000068, 36.276860627000076],
            [74.036150500000076, 36.288824000000034],
            [74.033757500000036, 36.298636000000045],
            [74.030646500000046, 36.312517000000071],
            [74.032799500000067, 36.32496100000003],
            [74.035193000000049, 36.332380000000057],
            [74.040936500000043, 36.339320000000043],
            [74.043808500000068, 36.350328000000047],
            [74.052423667000085, 36.359901000000036],
            [74.061039333000053, 36.359901000000036],
            [74.071091000000081, 36.369473000000028],
            [74.075398000000064, 36.380721000000051],
            [74.081143590000067, 36.384287434000044],
            [74.082507500000077, 36.381808000000035],
            [74.085654000000034, 36.356633000000045],
            [74.088801000000046, 36.347193000000061],
            [74.089850000000069, 36.338801000000046],
            [74.107682000000068, 36.332507000000078],
            [74.123416000000077, 36.32621400000005],
            [74.136003500000072, 36.313626000000056],
            [74.149639500000035, 36.302088000000026],
            [74.152786500000047, 36.284256000000028],
            [74.156982500000083, 36.270620000000065],
            [74.163276000000053, 36.255935000000079],
            [74.164325000000076, 36.241249000000039],
            [74.177961000000039, 36.234956000000068],
            [74.196842000000061, 36.22341700000004],
            [74.209429500000056, 36.212928000000034],
            [74.218870000000038, 36.195096000000035],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "62",
      properties: { name: "Hunza Nagar", count: 801 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.780823748000046, 37.034333224000079],
            [74.780827000000045, 37.034333000000061],
            [74.791420000000073, 37.037511000000052],
            [74.793538000000069, 37.05711000000008],
            [74.812074000000052, 37.065055000000029],
            [74.840667044000043, 37.065584908000062],
            [74.84067200000004, 37.065585000000056],
            [74.846498000000054, 37.053402000000062],
            [74.846498000000054, 37.038571000000047],
            [74.842791000000034, 37.023739000000035],
            [74.860797000000048, 37.007849000000078],
            [74.870330000000081, 36.99831400000005],
            [74.888336000000038, 36.964414000000033],
            [74.893632000000082, 36.950113000000044],
            [74.902105280000058, 36.940578810000034],
            [74.90210600000006, 36.940578000000073],
            [74.921700000000044, 36.944286000000034],
            [74.924347695000051, 36.950112329000035],
            [74.924348000000066, 36.950113000000044],
            [74.939172000000042, 36.947994572000027],
            [74.939176000000032, 36.947994000000051],
            [74.939176000000032, 36.959118000000046],
            [74.923289000000068, 36.983483000000035],
            [74.937058000000036, 36.992488000000037],
            [74.960359000000039, 36.996196000000054],
            [74.978893542000037, 37.003611417000059],
            [74.97889500000008, 37.003612000000032],
            [74.993750000000034, 37.001164577000054],
            [74.996934000000067, 37.000640000000033],
            [75.016495000000077, 37.011028000000067],
            [75.033441000000039, 37.025859000000025],
            [75.035551002000034, 37.025486571000044],
            [75.051446000000055, 37.022681000000034],
            [75.063626320000083, 37.013147532000062],
            [75.063627000000054, 37.01314700000006],
            [75.084809000000064, 37.015266000000054],
            [75.105992000000072, 37.017385000000047],
            [75.130882000000042, 37.020563000000038],
            [75.137767000000053, 37.02956800000004],
            [75.14570875000004, 37.034333650000065],
            [75.145711000000063, 37.034335000000056],
            [75.158950000000061, 37.02956800000004],
            [75.170600000000036, 37.022682000000032],
            [75.168733000000032, 36.999313000000029],
            [75.183310000000063, 36.991430000000037],
            [75.200786000000051, 36.984544000000028],
            [75.219321000000036, 36.981366000000037],
            [75.228923000000066, 36.97620100000006],
            [75.241028313000072, 36.971833330000038],
            [75.241032000000075, 36.971832000000063],
            [75.259567000000061, 36.974481000000026],
            [75.281806893000066, 36.982955578000031],
            [75.281808000000069, 36.982956000000058],
            [75.312522000000058, 36.980308000000036],
            [75.339528273000042, 36.971833228000037],
            [75.339529000000084, 36.971833000000061],
            [75.357585902000039, 36.969189999000037],
            [75.361241000000064, 36.968655000000069],
            [75.378186000000085, 36.964948000000049],
            [75.39353600000004, 36.964948000000049],
            [75.393543000000079, 36.964948000000049],
            [75.406245497000043, 36.965477729000042],
            [75.406252000000052, 36.965478000000076],
            [75.417902000000083, 36.959121000000039],
            [75.418961000000081, 36.94482000000005],
            [75.400957000000062, 36.937934000000041],
            [75.394073000000049, 36.92839900000007],
            [75.397780000000068, 36.909330000000068],
            [75.423200000000065, 36.895558000000051],
            [75.433261000000073, 36.876490000000047],
            [75.432203000000072, 36.850005000000067],
            [75.42584800000003, 36.828288000000043],
            [75.42373200000003, 36.800743000000068],
            [75.426380000000051, 36.784323000000029],
            [75.447033000000033, 36.748304000000076],
            [75.460103000000061, 36.729847000000063],
            [75.460613999000032, 36.729844919000072],
            [75.478276000000051, 36.72977300000008],
            [75.49098500000008, 36.74618600000008],
            [75.50368698300008, 36.748833746000059],
            [75.503693000000055, 36.748835000000042],
            [75.515010000000075, 36.734387000000027],
            [75.534399016000066, 36.734004158000062],
            [75.534407000000044, 36.734004000000027],
            [75.53597300000007, 36.74904900000007],
            [75.535465000000045, 36.774260000000027],
            [75.539700434000054, 36.780086221000033],
            [75.539701000000036, 36.780087000000037],
            [75.556572598000059, 36.778041402000042],
            [75.557178000000079, 36.777968000000044],
            [75.570938501000057, 36.77955724900005],
            [75.570945000000052, 36.779558000000065],
            [75.585768600000051, 36.772143700000072],
            [75.585772000000077, 36.772142000000031],
            [75.610130000000083, 36.775850000000048],
            [75.633427307000034, 36.780087692000052],
            [75.633429000000035, 36.780088000000035],
            [75.676850000000059, 36.77108400000003],
            [75.703728925000064, 36.755507623000028],
            [75.703730000000064, 36.75550700000008],
            [75.71921100000003, 36.754664000000048],
            [75.735617256000069, 36.753075846000058],
            [75.735626000000082, 36.753075000000024],
            [75.759454000000062, 36.738774000000035],
            [75.780634000000077, 36.725003000000072],
            [75.810816000000045, 36.708053000000064],
            [75.846823000000029, 36.68633600000004],
            [75.867473000000075, 36.670446000000027],
            [75.897655000000043, 36.639195000000029],
            [75.909833000000049, 36.632839000000047],
            [75.941074000000071, 36.606355000000065],
            [75.933661000000029, 36.589934000000028],
            [75.935780000000079, 36.575633000000039],
            [75.942663000000039, 36.565569000000039],
            [75.951135000000079, 36.557094000000063],
            [75.960181000000034, 36.539107000000058],
            [75.969014000000072, 36.521542000000068],
            [75.982905000000073, 36.511541000000079],
            [75.99826100000007, 36.501477000000079],
            [75.997737000000029, 36.48876400000006],
            [76.007797000000039, 36.480289000000028],
            [76.014151000000084, 36.465988000000038],
            [76.013981000000058, 36.454866000000038],
            [76.02845300000007, 36.438973000000033],
            [76.038514000000077, 36.415137000000072],
            [76.032161000000031, 36.398717000000033],
            [76.023160000000075, 36.393420000000049],
            [76.007805000000076, 36.386533000000043],
            [75.994039000000043, 36.36428600000005],
            [75.987686000000053, 36.346276000000046],
            [75.992981000000043, 36.325089000000048],
            [76.02369200000004, 36.29807500000004],
            [76.031108000000074, 36.284303000000079],
            [76.03322600000007, 36.270002000000034],
            [76.044875000000047, 36.266824000000042],
            [76.060230000000047, 36.251463000000058],
            [76.067114000000061, 36.23398300000008],
            [76.059701000000075, 36.22815600000007],
            [76.05969282600006, 36.228159221000055],
            [76.04222800000008, 36.235042000000078],
            [76.026344000000051, 36.238220000000069],
            [76.026339915000051, 36.238219617000027],
            [76.009400000000085, 36.236630000000048],
            [76.013107000000048, 36.213854000000026],
            [76.013637000000074, 36.19902200000007],
            [76.016815000000065, 36.172538000000031],
            [75.998980000000074, 36.168981000000031],
            [75.970241000000044, 36.165900000000079],
            [75.97332300000005, 36.153578000000039],
            [75.957138000000043, 36.144759000000079],
            [75.935348000000033, 36.141256000000055],
            [75.93637700000005, 36.12790700000005],
            [75.947668000000078, 36.122773000000052],
            [75.942537000000073, 36.101210000000037],
            [75.956907000000058, 36.08580900000004],
            [75.948696000000041, 36.073487000000057],
            [75.948865692000084, 36.073338467000042],
            [75.948859764000076, 36.073334487000068],
            [75.948686333000069, 36.073486333000062],
            [75.921691500000065, 36.062090000000069],
            [75.911427500000059, 36.062090000000069],
            [75.90556250000003, 36.045961000000034],
            [75.874770500000068, 36.034230500000035],
            [75.848377000000085, 36.023233000000062],
            [75.821251000000075, 36.023966500000029],
            [75.80145600000003, 36.019567500000051],
            [75.784594000000084, 36.018101000000058],
            [75.766265000000033, 36.02396600000003],
            [75.766265000000033, 36.038629000000071],
            [75.758934000000068, 36.05035950000007],
            [75.753069000000039, 36.062823000000037],
            [75.749403000000029, 36.069421500000033],
            [75.733274000000051, 36.070888000000025],
            [75.72740900000008, 36.07162100000005],
            [75.726676000000054, 36.087750000000028],
            [75.731074000000035, 36.107545000000073],
            [75.709813000000054, 36.117809000000079],
            [75.688552000000072, 36.129539500000078],
            [75.680488000000082, 36.139803500000028],
            [75.662159000000031, 36.134671500000024],
            [75.632833000000062, 36.134671500000024],
            [75.605707000000052, 36.126607000000035],
            [75.576381500000082, 36.128073000000029],
            [75.549988000000042, 36.134671500000024],
            [75.530193500000053, 36.140536500000053],
            [75.485471500000074, 36.14640200000008],
            [75.456879000000072, 36.157399000000055],
            [75.426820500000076, 36.184525000000065],
            [75.413623500000028, 36.207986000000062],
            [75.389429500000062, 36.224848000000065],
            [75.355705500000056, 36.246843000000069],
            [75.339576500000078, 36.252708000000041],
            [75.296320500000036, 36.225581500000033],
            [75.289636680000058, 36.223539955000035],
            [75.269927000000052, 36.217517000000043],
            [75.236935500000072, 36.210185000000024],
            [75.216407500000059, 36.202854000000059],
            [75.195879500000046, 36.16839600000003],
            [75.179017000000044, 36.136871000000042],
            [75.161421500000074, 36.104612000000031],
            [75.15262400000006, 36.08335100000005],
            [75.135028500000033, 36.07162100000005],
            [75.110101500000042, 36.052559000000031],
            [75.087374500000067, 36.044494000000043],
            [75.070512000000065, 36.050359000000071],
            [75.060248000000058, 36.036430000000053],
            [75.049250500000085, 36.037896000000046],
            [75.04045300000007, 36.046694000000059],
            [75.041186500000038, 36.067222000000072],
            [75.028722500000072, 36.078952000000072],
            [75.01405950000003, 36.08701700000006],
            [75.004529000000048, 36.070154000000059],
            [74.993531500000074, 36.052559000000031],
            [74.975936500000046, 36.060623000000078],
            [74.956874500000083, 36.058424000000059],
            [74.929748500000073, 36.047427000000027],
            [74.910686500000054, 36.049626000000046],
            [74.904821500000082, 36.023233000000062],
            [74.892358500000057, 36.018101000000058],
            [74.894384709000065, 36.004421957000034],
            [74.877607000000069, 36.006286000000046],
            [74.869215000000054, 36.015726000000029],
            [74.867117000000064, 36.026216000000034],
            [74.862922000000083, 36.03880300000003],
            [74.853481000000045, 36.046146000000078],
            [74.841943000000072, 36.049293000000034],
            [74.829355000000078, 36.05034100000006],
            [74.816768000000081, 36.052439000000049],
            [74.804181000000028, 36.052439000000049],
            [74.791594000000032, 36.049292000000037],
            [74.773761000000036, 36.053488000000073],
            [74.766419000000042, 36.059782000000041],
            [74.75907600000005, 36.062929000000054],
            [74.752783000000079, 36.062929000000054],
            [74.743342000000041, 36.062929000000054],
            [74.741244000000052, 36.063978000000077],
            [74.735999000000049, 36.070271000000048],
            [74.730755000000045, 36.079712000000029],
            [74.726559000000066, 36.086006000000054],
            [74.720265078000068, 36.097544190000065],
            [74.717118500000083, 36.094397000000072],
            [74.712667848000081, 36.095610867000062],
            [74.70920342800008, 36.096555749000061],
            [74.694041500000083, 36.10069100000004],
            [74.684600500000045, 36.103838000000053],
            [74.673062500000071, 36.106984000000068],
            [74.658377500000029, 36.115376000000026],
            [74.65418150000005, 36.127963000000079],
            [74.637398500000074, 36.134257000000048],
            [74.623762500000055, 36.14369750000003],
            [74.606979000000081, 36.147893000000067],
            [74.583902500000079, 36.15104000000008],
            [74.570266000000061, 36.146844000000044],
            [74.537748500000077, 36.147893000000067],
            [74.507329500000083, 36.152089000000046],
            [74.489497500000084, 36.155236000000059],
            [74.480056500000046, 36.14369750000003],
            [74.472714500000052, 36.130061000000069],
            [74.456980000000044, 36.111180000000047],
            [74.438099000000079, 36.090201000000036],
            [74.427609500000074, 36.086006000000054],
            [74.421316000000047, 36.08915200000007],
            [74.411875500000065, 36.106984000000068],
            [74.397190500000079, 36.126914000000056],
            [74.362575500000048, 36.164676000000043],
            [74.341596500000037, 36.177264000000037],
            [74.325862500000085, 36.179362000000026],
            [74.311177000000043, 36.183557000000064],
            [74.295442500000036, 36.180411000000049],
            [74.293264234000048, 36.180138701000033],
            [74.270268000000044, 36.177264000000037],
            [74.256632000000081, 36.184606000000031],
            [74.24404450000003, 36.184606000000031],
            [74.228310500000077, 36.184606000000031],
            [74.218870000000038, 36.195096000000035],
            [74.209429500000056, 36.212928000000034],
            [74.196842000000061, 36.22341700000004],
            [74.177961000000039, 36.234956000000068],
            [74.164325000000076, 36.241249000000039],
            [74.163276000000053, 36.255935000000079],
            [74.156982500000083, 36.270620000000065],
            [74.152786500000047, 36.284256000000028],
            [74.149639500000035, 36.302088000000026],
            [74.136003500000072, 36.313626000000056],
            [74.123416000000077, 36.32621400000005],
            [74.107682000000068, 36.332507000000078],
            [74.089850000000069, 36.338801000000046],
            [74.088801000000046, 36.347193000000061],
            [74.085654000000034, 36.356633000000045],
            [74.082507500000077, 36.381808000000035],
            [74.081143590000067, 36.384287434000044],
            [74.089278500000034, 36.389337000000069],
            [74.105312500000082, 36.396995000000061],
            [74.103398000000084, 36.403695500000026],
            [74.090953500000069, 36.413747000000058],
            [74.08233800000005, 36.420687000000044],
            [74.080663000000072, 36.430738000000076],
            [74.083774000000062, 36.43624200000005],
            [74.090714000000048, 36.445336000000054],
            [74.091671500000075, 36.456823000000043],
            [74.095500500000071, 36.466396000000032],
            [74.108902000000057, 36.473815000000059],
            [74.119432000000074, 36.484584000000041],
            [74.121346500000072, 36.493199000000061],
            [74.124936000000048, 36.503729000000078],
            [74.123739500000056, 36.512823000000026],
            [74.123979000000077, 36.52766100000008],
            [74.131397500000048, 36.53364300000004],
            [74.139534500000082, 36.53388300000006],
            [74.154611000000045, 36.533165000000054],
            [74.168491500000073, 36.536037000000078],
            [74.187397000000033, 36.538190000000043],
            [74.189311500000031, 36.545370000000048],
            [74.189551000000051, 36.558532000000071],
            [74.191944000000035, 36.568583000000046],
            [74.194337500000074, 36.573609000000033],
            [74.202474000000052, 36.580310000000054],
            [74.20989250000008, 36.582224000000053],
            [74.220661500000062, 36.588686000000052],
            [74.225687500000049, 36.598258000000044],
            [74.235738500000082, 36.601609000000053],
            [74.254883500000062, 36.598737000000028],
            [74.265892500000064, 36.592515000000049],
            [74.281208500000048, 36.59610500000008],
            [74.284080000000074, 36.606634000000042],
            [74.297420000000045, 36.624498000000074],
            [74.313291500000048, 36.628730000000076],
            [74.320698500000049, 36.641427000000078],
            [74.310117500000047, 36.641427000000078],
            [74.293187500000045, 36.646718000000078],
            [74.294245500000045, 36.654125000000079],
            [74.280490333000046, 36.659415000000024],
            [74.246630500000038, 36.682694000000026],
            [74.222294500000032, 36.690101000000027],
            [74.20430650000003, 36.686926000000028],
            [74.187376500000084, 36.691159000000027],
            [74.188434500000085, 36.704914000000031],
            [74.195841500000029, 36.728193000000033],
            [74.188434500000085, 36.728193000000033],
            [74.16515650000008, 36.737716000000034],
            [74.138703500000076, 36.735599000000036],
            [74.09955350000007, 36.731367000000034],
            [74.114367000000072, 36.757820000000038],
            [74.116483000000073, 36.77475000000004],
            [74.111192500000072, 36.800144000000046],
            [74.090030500000069, 36.800144000000046],
            [74.068868000000066, 36.804377000000045],
            [74.068868000000066, 36.817074000000048],
            [74.051938500000063, 36.818132000000048],
            [74.044494703000055, 36.828882687000032],
            [74.046054192000042, 36.835603803000026],
            [74.064001000000076, 36.839948000000049],
            [74.08520100000004, 36.852104000000054],
            [74.115436000000045, 36.852104000000054],
            [74.115439000000038, 36.852104000000054],
            [74.129668000000038, 36.857441000000051],
            [74.135894000000064, 36.885903000000042],
            [74.151012927000068, 36.917922844000032],
            [74.151013000000034, 36.91792300000003],
            [74.21771300000006, 36.914365000000032],
            [74.242611112000077, 36.905471032000037],
            [74.24261400000006, 36.905470000000037],
            [74.27729800000003, 36.915254000000061],
            [74.324432000000058, 36.945495000000051],
            [74.358227000000056, 36.974847000000068],
            [74.383128000000056, 36.983741000000066],
            [74.406250000000057, 36.989078000000063],
            [74.424037000000055, 37.006866000000059],
            [74.500515471000028, 37.008644941000057],
            [74.500518000000056, 37.008645000000058],
            [74.521861000000058, 37.00597700000003],
            [74.531644000000028, 36.998861000000034],
            [74.536980000000028, 36.97662500000007],
            [74.547650339000029, 36.967731551000043],
            [74.54765100000003, 36.967731000000072],
            [74.56276900000006, 36.97128900000007],
            [74.576109000000031, 37.004198000000031],
            [74.573441000000059, 37.038886000000048],
            [74.601009000000033, 37.050449000000071],
            [74.623241000000064, 37.059343000000069],
            [74.631245000000035, 37.075353000000064],
            [74.67215200000004, 37.086027000000058],
            [74.689049000000068, 37.095811000000026],
            [74.704162241000063, 37.097589558000038],
            [74.704166000000043, 37.097590000000025],
            [74.716578000000084, 37.074847000000034],
            [74.720453000000077, 37.051813000000038],
            [74.734752000000071, 37.037511000000052],
            [74.780823748000046, 37.034333224000079],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "63",
      properties: { name: "Skardu", count: 1088 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [75.576381500000082, 36.128073000000029],
            [75.605707000000052, 36.126607000000035],
            [75.632833000000062, 36.134671500000024],
            [75.662159000000031, 36.134671500000024],
            [75.680488000000082, 36.139803500000028],
            [75.688552000000072, 36.129539500000078],
            [75.709813000000054, 36.117809000000079],
            [75.731074000000035, 36.107545000000073],
            [75.726676000000054, 36.087750000000028],
            [75.72740900000008, 36.07162100000005],
            [75.733274000000051, 36.070888000000025],
            [75.749403000000029, 36.069421500000033],
            [75.753069000000039, 36.062823000000037],
            [75.758934000000068, 36.05035950000007],
            [75.766265000000033, 36.038629000000071],
            [75.766265000000033, 36.02396600000003],
            [75.784594000000084, 36.018101000000058],
            [75.80145600000003, 36.019567500000051],
            [75.821251000000075, 36.023966500000029],
            [75.848377000000085, 36.023233000000062],
            [75.874770500000068, 36.034230500000035],
            [75.90556250000003, 36.045961000000034],
            [75.911427500000059, 36.062090000000069],
            [75.921691500000065, 36.062090000000069],
            [75.948686333000069, 36.073486333000062],
            [75.948859764000076, 36.073334487000068],
            [75.948865692000084, 36.073338467000042],
            [75.956908000000055, 36.066299000000072],
            [75.963066000000083, 36.050898000000075],
            [75.98051600000008, 36.034469000000058],
            [76.010282000000075, 36.023175000000037],
            [76.025193367000043, 36.018986268000049],
            [76.025205000000085, 36.018983000000048],
            [76.042101000000059, 36.030363000000079],
            [76.067747969000038, 36.029337521000059],
            [76.067761000000075, 36.029337000000055],
            [76.099579000000062, 36.024204000000054],
            [76.10603100000003, 36.009506000000044],
            [76.107791000000077, 35.996480000000076],
            [76.119082000000049, 35.975944000000027],
            [76.13755800000007, 35.957462000000078],
            [76.157060000000058, 35.933846000000074],
            [76.165271000000075, 35.90817700000008],
            [76.160822000000053, 35.884625000000028],
            [76.156782000000078, 35.863244000000066],
            [76.146799000000044, 35.843488000000036],
            [76.151931000000047, 35.829113000000063],
            [76.171425667000051, 35.82603400000005],
            [76.171432000000038, 35.826033000000052],
            [76.21761900000007, 35.82603400000005],
            [76.23404000000005, 35.840409000000079],
            [76.253541000000041, 35.840410000000077],
            [76.29150480800007, 35.844516789000068],
            [76.291516000000058, 35.844518000000051],
            [76.345912000000055, 35.82911800100004],
            [76.365415000000041, 35.828091000000029],
            [76.361309000000062, 35.843493000000024],
            [76.359256000000073, 35.857868000000053],
            [76.369515227000079, 35.864026735000039],
            [76.369519000000082, 35.864029000000073],
            [76.395173410000041, 35.856843006000076],
            [76.395177000000047, 35.856842000000029],
            [76.418308138000043, 35.855350138000063],
            [76.426973651000083, 35.854791248000026],
            [76.426993000000039, 35.854790000000037],
            [76.444833000000074, 35.861587000000043],
            [76.463938000000041, 35.886622000000045],
            [76.480352823000032, 35.894833910000045],
            [76.480359000000078, 35.894837000000052],
            [76.509848157000079, 35.886699372000066],
            [76.50986400000005, 35.886695000000032],
            [76.527567000000033, 35.89483800000005],
            [76.543227000000059, 35.908233000000052],
            [76.548091000000056, 35.925643000000036],
            [76.571678252000083, 35.929748085000028],
            [76.571695000000034, 35.929751000000067],
            [76.586063000000081, 35.918457000000046],
            [76.598379000000079, 35.901001000000065],
            [76.587794000000031, 35.884130000000027],
            [76.578659000000073, 35.869572000000062],
            [76.58914500000003, 35.853768000000059],
            [76.586066000000073, 35.841446000000076],
            [76.56759500000004, 35.823990000000038],
            [76.568315000000041, 35.812743000000069],
            [76.58196300000003, 35.796267000000057],
            [76.595306000000051, 35.773678000000075],
            [76.615822442000081, 35.766493997000055],
            [76.615831000000071, 35.76649100000003],
            [76.645576206000044, 35.768543634000025],
            [76.645596000000069, 35.768545000000074],
            [76.660990000000083, 35.756224000000032],
            [76.685619000000031, 35.753145000000075],
            [76.698961000000054, 35.744931000000065],
            [76.699988000000076, 35.725422000000037],
            [76.711276000000055, 35.719261000000074],
            [76.72667000000007, 35.71002100000004],
            [76.742248000000075, 35.693228000000033],
            [76.751300000000072, 35.681271000000038],
            [76.760537000000056, 35.664843000000076],
            [76.766691609000077, 35.655607090000046],
            [76.766695000000084, 35.655602000000044],
            [76.845710000000054, 35.666900000000055],
            [76.855946545000052, 35.666900000000055],
            [76.855972000000065, 35.666900000000055],
            [76.873418000000072, 35.644311000000073],
            [76.896455526000068, 35.628284049000058],
            [76.895707135000066, 35.623590678000028],
            [76.874219000000039, 35.606231000000037],
            [76.846539000000064, 35.588254000000063],
            [76.82913300000007, 35.577125000000024],
            [76.818574000000069, 35.555438000000038],
            [76.790324000000055, 35.522336000000053],
            [76.757223000000067, 35.473254000000054],
            [76.744097000000068, 35.451852000000031],
            [76.725549000000058, 35.425314000000071],
            [76.708998000000065, 35.42674100000005],
            [76.692447000000072, 35.443577000000062],
            [76.677609000000075, 35.450996000000032],
            [76.670190000000048, 35.459557000000075],
            [76.659061000000065, 35.489234000000067],
            [76.64450800000003, 35.516343000000063],
            [76.628813000000036, 35.540313000000026],
            [76.609979000000067, 35.564569000000063],
            [76.574881000000062, 35.58540000000005],
            [76.55233700000008, 35.596529000000032],
            [76.53407500000003, 35.612224000000026],
            [76.493839000000037, 35.639047000000062],
            [76.453319000000079, 35.680424000000073],
            [76.438195000000064, 35.687273000000062],
            [76.40452300000004, 35.679853000000037],
            [76.359151000000054, 35.663017000000025],
            [76.329189000000042, 35.651888000000042],
            [76.306361000000038, 35.639618000000041],
            [76.277825000000064, 35.617075000000057],
            [76.247863000000052, 35.607943000000034],
            [76.221610000000055, 35.593104000000039],
            [76.201064000000031, 35.574841000000049],
            [76.176809000000048, 35.550301000000047],
            [76.149415000000033, 35.522336000000053],
            [76.145181182000044, 35.520564781000076],
            [76.121450000000038, 35.510636000000034],
            [76.094626000000062, 35.507497000000058],
            [76.060383000000058, 35.494656000000077],
            [76.03356000000008, 35.477534000000048],
            [76.006166000000064, 35.461269000000073],
            [75.993895000000066, 35.43901100000005],
            [75.994466000000045, 35.40248500000007],
            [75.993325000000084, 35.375376000000074],
            [75.984193000000062, 35.357399000000044],
            [75.966787000000068, 35.335141000000078],
            [75.945385000000044, 35.308888000000024],
            [75.926551000000075, 35.282635000000028],
            [75.914281000000074, 35.264657000000057],
            [75.908859000000064, 35.247821000000044],
            [75.910857000000078, 35.226705000000038],
            [75.910988171000042, 35.226569493000056],
            [75.923411000000044, 35.213863500000059],
            [75.963932000000057, 35.172487000000046],
            [75.98533400000008, 35.155365000000074],
            [75.98764024500008, 35.15389338500006],
            [76.001884500000074, 35.144807000000071],
            [76.026140000000055, 35.144236000000035],
            [76.052107000000035, 35.136531500000046],
            [76.063852538000049, 35.133921352000073],
            [76.075221000000056, 35.131395000000055],
            [76.125158500000055, 35.110564000000068],
            [76.153408500000069, 35.092872000000057],
            [76.187651500000072, 35.080031000000076],
            [76.215045500000031, 35.076321000000064],
            [76.230169500000045, 35.070043000000055],
            [76.267551000000083, 35.073753000000067],
            [76.290094500000066, 35.04949750000003],
            [76.332326500000079, 35.04807100000005],
            [76.36856750000004, 35.026098000000047],
            [76.39481950000004, 35.010974000000033],
            [76.394249500000058, 34.996421000000055],
            [76.41308250000003, 34.979585000000043],
            [76.407946500000037, 34.953332000000046],
            [76.413368500000047, 34.933357000000058],
            [76.432201500000076, 34.904250000000047],
            [76.451605500000085, 34.872005000000058],
            [76.452176500000064, 34.850032000000056],
            [76.46216400000003, 34.832055000000025],
            [76.479000000000042, 34.821782000000042],
            [76.495265500000073, 34.810938000000078],
            [76.507435220000048, 34.800181384000041],
            [76.496504042000083, 34.798688369000047],
            [76.486549424000032, 34.792790300000036],
            [76.475775000000056, 34.786406500000055],
            [76.455047000000036, 34.771052500000053],
            [76.447370500000034, 34.750324500000033],
            [76.419733500000063, 34.728061000000025],
            [76.387490500000069, 34.692747000000054],
            [76.352944000000036, 34.659735500000068],
            [76.305347500000039, 34.638240000000053],
            [76.281548500000042, 34.622885500000052],
            [76.252376500000082, 34.618279500000028],
            [76.227042500000039, 34.610602500000027],
            [76.204012000000034, 34.603693000000078],
            [76.185587000000055, 34.601390000000038],
            [76.167162500000074, 34.606764000000055],
            [76.120332500000075, 34.622118000000057],
            [76.079645000000085, 34.636704000000066],
            [76.042028000000073, 34.660503000000062],
            [76.029286637000041, 34.666256931000078],
            [76.018229000000076, 34.671250500000042],
            [76.012571090000051, 34.665081168000029],
            [76.009785000000079, 34.662038500000051],
            [76.005946000000051, 34.646684500000049],
            [75.986754000000076, 34.636704000000066],
            [75.978856888000053, 34.630561897000064],
            [75.95911650000005, 34.615208500000051],
            [75.926106000000061, 34.592177500000048],
            [75.915358000000083, 34.576823500000046],
            [75.903843000000052, 34.544579500000054],
            [75.88848900000005, 34.53306450000008],
            [75.844730000000084, 34.529993500000046],
            [75.827073000000041, 34.51387150000005],
            [75.744162000000074, 34.511568500000067],
            [75.675069000000065, 34.510800500000073],
            [75.653574000000049, 34.510800500000073],
            [75.622098000000051, 34.514639500000044],
            [75.588319500000068, 34.523851500000035],
            [75.559147000000053, 34.529993500000046],
            [75.541328319000058, 34.538632754000048],
            [75.533813000000066, 34.542276500000071],
            [75.511549500000058, 34.543811500000061],
            [75.485864466000066, 34.538817059000053],
            [75.483912500000031, 34.538437500000043],
            [75.481178412000077, 34.539161311000043],
            [75.457811000000049, 34.545347500000048],
            [75.444309029000067, 34.544972296000026],
            [75.430174000000079, 34.544579500000054],
            [75.424555491000035, 34.546510923000028],
            [75.405607500000031, 34.553024500000049],
            [75.358778000000029, 34.565307500000074],
            [75.339931549000084, 34.569076626000026],
            [75.335747000000083, 34.569913500000041],
            [75.326534500000037, 34.569146000000046],
            [75.312111314000049, 34.584328614000071],
            [75.311948500000085, 34.584500000000048],
            [75.311511590000066, 34.584645626000054],
            [75.298129500000073, 34.589106000000072],
            [75.297250284000029, 34.592184114000077],
            [75.296594500000083, 34.594480000000033],
            [75.286611908000054, 34.597807531000058],
            [75.27356350000008, 34.602157000000034],
            [75.264351000000033, 34.606763500000056],
            [75.264351000000033, 34.609564557000056],
            [75.264351000000033, 34.628259000000071],
            [75.26384756300007, 34.628621472000077],
            [75.245158500000059, 34.642077500000028],
            [75.22673350000008, 34.635168500000077],
            [75.216481722000083, 34.636977312000056],
            [75.222650068000064, 34.647386331000064],
            [75.228138500000057, 34.656648000000075],
            [75.236203000000046, 34.672777000000053],
            [75.245734000000084, 34.681575000000066],
            [75.261130000000037, 34.689639000000057],
            [75.259663500000045, 34.707235000000026],
            [75.265528500000073, 34.719698000000051],
            [75.263329500000054, 34.737294000000077],
            [75.253798500000073, 34.758555000000058],
            [75.249399500000038, 34.79301300000003],
            [75.23327050000006, 34.811341500000026],
            [75.197346500000037, 34.826004500000067],
            [75.173152500000072, 34.83920100000006],
            [75.15042550000004, 34.83920100000006],
            [75.15042550000004, 34.859729000000073],
            [75.142360500000052, 34.872193000000038],
            [75.120366500000046, 34.884656000000064],
            [75.104970500000036, 34.899319000000048],
            [75.118166500000029, 34.911783000000071],
            [75.132829500000071, 34.911783000000071],
            [75.147492500000055, 34.924246000000039],
            [75.160689500000046, 34.935243000000071],
            [75.176818500000081, 34.935243000000071],
            [75.195880000000045, 34.938176000000055],
            [75.197346500000037, 34.953572000000065],
            [75.201745500000072, 34.971901000000059],
            [75.207610500000044, 34.985830000000078],
            [75.225939000000039, 35.001960000000054],
            [75.228138500000057, 35.019555000000025],
            [75.21787450000005, 35.03935000000007],
            [75.209076500000037, 35.047415000000058],
            [75.214941500000066, 35.060611000000051],
            [75.217874481000081, 35.073807913000053],
            [75.203944500000034, 35.07600750000006],
            [75.19661300000007, 35.090670000000046],
            [75.197346500000037, 35.109732000000065],
            [75.216408000000058, 35.117797000000053],
            [75.223006500000054, 35.129527000000053],
            [75.226672000000065, 35.144923000000063],
            [75.251598500000057, 35.153721000000075],
            [75.27212650000007, 35.158120000000054],
            [75.245733500000028, 35.166184500000043],
            [75.242801000000043, 35.180847500000027],
            [75.236202500000047, 35.193311000000051],
            [75.228871500000082, 35.192578000000026],
            [75.209076500000037, 35.188912000000073],
            [75.198812500000031, 35.200642000000073],
            [75.190748000000042, 35.216038500000025],
            [75.172419500000046, 35.228502000000049],
            [75.174618500000065, 35.242432000000065],
            [75.18928150000005, 35.26515900000004],
            [75.192214500000034, 35.27615650000007],
            [75.18561550000004, 35.290086000000031],
            [75.188548500000081, 35.301083500000061],
            [75.176085000000057, 35.317946000000063],
            [75.173885519000066, 35.338473819000058],
            [75.151158000000066, 35.350937500000043],
            [75.13649550000008, 35.358269000000064],
            [75.117433500000061, 35.377331000000027],
            [75.104970000000037, 35.386862000000065],
            [75.102037500000051, 35.405190000000061],
            [75.09250650000007, 35.416921000000059],
            [75.071245500000032, 35.421319500000038],
            [75.067579500000079, 35.43818200000004],
            [75.04998450000005, 35.438915000000065],
            [75.032388500000081, 35.439648000000034],
            [75.014060500000085, 35.447713000000078],
            [75.01772550000004, 35.468241000000035],
            [75.007461500000034, 35.477039000000048],
            [74.993532500000072, 35.472640000000069],
            [74.97886950000003, 35.479971000000035],
            [74.964206500000046, 35.490235000000041],
            [74.95174250000008, 35.512230000000045],
            [74.93634650000007, 35.522494000000052],
            [74.909953500000029, 35.530558000000042],
            [74.896757500000035, 35.53862300000003],
            [74.882094500000051, 35.551086000000055],
            [74.863765500000056, 35.562084000000027],
            [74.869630500000085, 35.576013000000046],
            [74.86523150000005, 35.58261200000004],
            [74.83810550000004, 35.59800800000005],
            [74.83443950000003, 35.614137000000028],
            [74.840305500000056, 35.617803000000038],
            [74.860099500000047, 35.632466000000079],
            [74.877695500000073, 35.640530000000069],
            [74.871830500000044, 35.658859000000064],
            [74.879161500000066, 35.669856000000038],
            [74.876229500000079, 35.695516000000055],
            [74.860833000000071, 35.714578000000074],
            [74.84177150000005, 35.720443000000046],
            [74.821976500000062, 35.722643000000062],
            [74.808779500000071, 35.717511000000059],
            [74.794850500000052, 35.718244000000027],
            [74.778720500000077, 35.718977000000052],
            [74.761125500000048, 35.718244000000027],
            [74.753793500000029, 35.725575000000049],
            [74.754100986000083, 35.72618850200007],
            [74.789717500000052, 35.749036000000046],
            [74.808779000000072, 35.754901000000075],
            [74.830774000000076, 35.763699000000031],
            [74.848369000000048, 35.796691000000067],
            [74.880627000000061, 35.805488000000025],
            [74.896023000000071, 35.818685000000073],
            [74.901155000000074, 35.839946000000054],
            [74.904741568000077, 35.842588681000052],
            [74.915085500000032, 35.850210000000061],
            [74.948077500000068, 35.855342000000064],
            [74.970071500000074, 35.869272000000024],
            [74.968605000000082, 35.901531000000034],
            [74.962739500000055, 35.92719100000005],
            [74.949544067000033, 35.936721711000075],
            [74.934881977000032, 35.939165087000049],
            [74.927549000000056, 35.940387000000044],
            [74.943678500000033, 35.963848000000041],
            [74.931214500000067, 35.974112000000048],
            [74.915085500000032, 35.990974000000051],
            [74.895290500000044, 35.998306000000071],
            [74.894384709000065, 36.004421957000034],
            [74.892358500000057, 36.018101000000058],
            [74.904821500000082, 36.023233000000062],
            [74.910686500000054, 36.049626000000046],
            [74.929748500000073, 36.047427000000027],
            [74.956874500000083, 36.058424000000059],
            [74.975936500000046, 36.060623000000078],
            [74.993531500000074, 36.052559000000031],
            [75.004529000000048, 36.070154000000059],
            [75.01405950000003, 36.08701700000006],
            [75.028722500000072, 36.078952000000072],
            [75.041186500000038, 36.067222000000072],
            [75.04045300000007, 36.046694000000059],
            [75.049250500000085, 36.037896000000046],
            [75.060248000000058, 36.036430000000053],
            [75.070512000000065, 36.050359000000071],
            [75.087374500000067, 36.044494000000043],
            [75.110101500000042, 36.052559000000031],
            [75.135028500000033, 36.07162100000005],
            [75.15262400000006, 36.08335100000005],
            [75.161421500000074, 36.104612000000031],
            [75.179017000000044, 36.136871000000042],
            [75.195879500000046, 36.16839600000003],
            [75.216407500000059, 36.202854000000059],
            [75.236935500000072, 36.210185000000024],
            [75.269927000000052, 36.217517000000043],
            [75.289636680000058, 36.223539955000035],
            [75.296320500000036, 36.225581500000033],
            [75.339576500000078, 36.252708000000041],
            [75.355705500000056, 36.246843000000069],
            [75.389429500000062, 36.224848000000065],
            [75.413623500000028, 36.207986000000062],
            [75.426820500000076, 36.184525000000065],
            [75.456879000000072, 36.157399000000055],
            [75.485471500000074, 36.14640200000008],
            [75.530193500000053, 36.140536500000053],
            [75.549988000000042, 36.134671500000024],
            [75.576381500000082, 36.128073000000029],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "64",
      properties: { name: "Abbottabad", count: 1012 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.401074053000059, 34.370762975000048],
            [73.401190000000042, 34.370728000000042],
            [73.401295000000061, 34.370735000000025],
            [73.402949000000035, 34.366394000000071],
            [73.407631855000034, 34.361711145000072],
            [73.416515000000061, 34.352828000000045],
            [73.425003023000045, 34.343329609000079],
            [73.426053619000072, 34.342153956000061],
            [73.439788554000074, 34.326784088000068],
            [73.440256000000034, 34.326261000000045],
            [73.449300000000051, 34.321173000000044],
            [73.451007765000043, 34.305376360000025],
            [73.451561000000083, 34.30025900000004],
            [73.459437511000033, 34.29113867500007],
            [73.462301000000082, 34.28782300000006],
            [73.46271002800006, 34.28738252100004],
            [73.469649000000061, 34.279910000000029],
            [73.469649749000041, 34.279910308000069],
            [73.479258000000073, 34.283866000000046],
            [73.480389000000059, 34.291215000000079],
            [73.480390219000071, 34.291213172000027],
            [73.488302000000033, 34.279344000000037],
            [73.484949748000076, 34.271382958000061],
            [73.483780000000081, 34.268605000000036],
            [73.473606000000075, 34.256169000000057],
            [73.489811601000042, 34.230239274000041],
            [73.490563000000066, 34.229037000000062],
            [73.499042000000031, 34.207557000000065],
            [73.495085000000074, 34.187208000000055],
            [73.495085000000074, 34.173024480000038],
            [73.495085000000074, 34.136024565000071],
            [73.495085000000074, 34.135204000000044],
            [73.500794056000075, 34.107178393000027],
            [73.501303000000064, 34.10468000000003],
            [73.501458451000076, 34.103281128000049],
            [73.501476095000044, 34.103122347000067],
            [73.502999000000045, 34.08941800000008],
            [73.502999000000045, 34.06906900000007],
            [73.508793733000061, 34.062033166000049],
            [73.510727719000045, 34.059684964000041],
            [73.510913000000073, 34.059460000000058],
            [73.514869000000033, 34.036850000000072],
            [73.510913000000073, 34.01876100000004],
            [73.511032372000045, 34.018706288000033],
            [73.51103263400006, 34.018706168000051],
            [73.511033000000054, 34.018706000000066],
            [73.501413387000071, 34.018002034000062],
            [73.499635935000072, 34.01787196600003],
            [73.490466287000061, 34.017200934000073],
            [73.486600000000067, 34.016918000000032],
            [73.48038968700007, 34.012185529000078],
            [73.474207126000067, 34.007475963000047],
            [73.472686000000067, 34.006316000000027],
            [73.471540466000079, 34.005200627000079],
            [73.468785414000081, 34.002518114000054],
            [73.468693672000029, 34.002428787000042],
            [73.46662400200006, 34.000413610000066],
            [73.465588102000083, 33.999404985000069],
            [73.462138629000037, 33.996046334000027],
            [73.457833415000039, 33.991855847000068],
            [73.455512984000052, 33.989595138000027],
            [73.452172336000046, 33.986342448000073],
            [73.448700170000052, 33.982961702000068],
            [73.448387933000049, 33.982658694000065],
            [73.447504000000038, 33.981798000000026],
            [73.44698945600004, 33.981197692000023],
            [73.444661155000063, 33.97847742600004],
            [73.440314379000029, 33.97341000800003],
            [73.436747317000084, 33.969247220000057],
            [73.435578000000078, 33.967883000000029],
            [73.435453327000062, 33.967835874000059],
            [73.433049236000045, 33.967078617000027],
            [73.430227018000039, 33.966180533000056],
            [73.42778802600003, 33.965404400000068],
            [73.427297415000055, 33.965248277000057],
            [73.420999375000065, 33.963244119000024],
            [73.420999000000052, 33.963244000000032],
            [73.41900892600006, 33.965134668000076],
            [73.415957515000059, 33.968033658000024],
            [73.413494723000042, 33.970373431000041],
            [73.413494764000063, 33.970373584000072],
            [73.413486341000066, 33.97038158600003],
            [73.41347791700008, 33.970389589000035],
            [73.413477785000055, 33.970389523000051],
            [73.411763506000057, 33.972018173000038],
            [73.409199253000054, 33.974454338000044],
            [73.40841585000004, 33.975198610000064],
            [73.408409000000063, 33.975205118000076],
            [73.408408503000032, 33.97520559000003],
            [73.407746000000031, 33.975835000000075],
            [73.400372814000036, 33.975835000000075],
            [73.399133000000063, 33.975834000000077],
            [73.398887383000044, 33.975588383000058],
            [73.396179916000051, 33.972880916000065],
            [73.39287203300006, 33.969573033000074],
            [73.392871997000043, 33.969572997000057],
            [73.390019006000045, 33.96672000600006],
            [73.388529000000062, 33.965232000000071],
            [73.388271343000042, 33.963299286000051],
            [73.387565874000074, 33.958007468000062],
            [73.387204000000054, 33.95529300000004],
            [73.383299951000083, 33.942279831000064],
            [73.383228000000031, 33.942040000000077],
            [73.382003100000077, 33.94091708600007],
            [73.38200306400006, 33.940917052000032],
            [73.380861749000076, 33.939869847000068],
            [73.375278000000037, 33.934751000000063],
            [73.373962163000044, 33.933983457000068],
            [73.37036469700007, 33.93188501000003],
            [73.368736156000068, 33.930935645000034],
            [73.365419321000047, 33.929000462000033],
            [73.36426188400003, 33.928325163000068],
            [73.359373000000062, 33.925474000000065],
            [73.359345528000063, 33.925460260000079],
            [73.356549508000057, 33.924061602000052],
            [73.352533700000038, 33.922053981000033],
            [73.350096000000065, 33.920835000000068],
            [73.347730226000067, 33.910187231000066],
            [73.347730218000038, 33.910187195000049],
            [73.347446000000048, 33.908908000000054],
            [73.343921593000061, 33.903973884000038],
            [73.343915540000069, 33.903964009000049],
            [73.342530069000077, 33.902024370000049],
            [73.337333367000042, 33.894749067000078],
            [73.334194000000082, 33.890354000000059],
            [73.334055396000053, 33.887878375000071],
            [73.333984711000085, 33.886595947000046],
            [73.333937052000067, 33.885731268000029],
            [73.333707470000036, 33.881600859000059],
            [73.333530000000053, 33.878426000000047],
            [73.330357805000062, 33.873666009000033],
            [73.329085897000084, 33.871758066000041],
            [73.328284771000028, 33.870556326000042],
            [73.325579000000062, 33.866499000000033],
            [73.318785001000037, 33.862902070000075],
            [73.314314000000081, 33.860535000000027],
            [73.307945027000073, 33.85161843800006],
            [73.305607040000041, 33.848345256000073],
            [73.304374000000053, 33.846619000000032],
            [73.294164579000039, 33.846000374000027],
            [73.288126172000034, 33.845634486000051],
            [73.282507000000066, 33.845294000000024],
            [73.28092771300004, 33.844316351000032],
            [73.277995588000067, 33.842500617000042],
            [73.277619938000043, 33.842268073000071],
            [73.27676223900005, 33.841737120000062],
            [73.27612914000008, 33.841345204000049],
            [73.274171961000036, 33.840133624000032],
            [73.270550074000084, 33.83789151600007],
            [73.268592354000077, 33.836680219000073],
            [73.268592000000069, 33.836680000000058],
            [73.260640780000074, 33.848605831000043],
            [73.260640260000059, 33.848606610000047],
            [73.258888471000034, 33.847684660000027],
            [73.255812947000038, 33.846066567000037],
            [73.25027816100004, 33.843153661000031],
            [73.248051000000032, 33.841981000000033],
            [73.246738663000031, 33.838699961000032],
            [73.245526419000043, 33.835669168000038],
            [73.24518336400007, 33.834811476000027],
            [73.243202439000072, 33.829858866000052],
            [73.241425000000049, 33.825415000000078],
            [73.240556947000073, 33.825085748000049],
            [73.234465105000083, 33.82277511500007],
            [73.228861638000069, 33.820650101000069],
            [73.222207000000083, 33.818126000000063],
            [73.21983300100004, 33.817553000000032],
            [73.214309115000049, 33.828996153000048],
            [73.212135000000046, 33.833500000000072],
            [73.212890514000037, 33.835821062000036],
            [73.216015000000084, 33.845420000000047],
            [73.214477439000063, 33.849136312000041],
            [73.214241106000031, 33.849707534000061],
            [73.214241003000041, 33.849707782000053],
            [73.212689000000069, 33.853459000000043],
            [73.202155000000062, 33.853459000000043],
            [73.201977725000063, 33.85367173000003],
            [73.201970029000051, 33.853680965000024],
            [73.200215309000043, 33.855786629000079],
            [73.199115405000043, 33.857106514000066],
            [73.198987232000036, 33.857260322000059],
            [73.196974299000033, 33.859675842000058],
            [73.19522500000005, 33.86177500000008],
            [73.19522500000005, 33.864915303000032],
            [73.19522500000005, 33.868784030000029],
            [73.19522500000005, 33.868982000000074],
            [73.201537210000083, 33.872275478000063],
            [73.203285640000047, 33.873187744000063],
            [73.207976000000031, 33.875635000000045],
            [73.212135000000046, 33.883952000000079],
            [73.199637490000043, 33.890072873000065],
            [73.199434066000038, 33.890172503000031],
            [73.198551000000066, 33.89060500000005],
            [73.196912931000043, 33.89122470600006],
            [73.188295000000039, 33.894485000000032],
            [73.18829510300003, 33.894485583000062],
            [73.188294000000042, 33.894486000000029],
            [73.188434231000031, 33.895280275000061],
            [73.188474899000084, 33.895510620000039],
            [73.189958000000047, 33.903911000000051],
            [73.196298994000074, 33.908138012000052],
            [73.196611000000075, 33.908346000000051],
            [73.196059147000028, 33.910884681000027],
            [73.19522500000005, 33.91472200000004],
            [73.194184857000039, 33.91468692400008],
            [73.191524842000035, 33.914597222000054],
            [73.180270459000042, 33.914217696000037],
            [73.179926502000058, 33.914206097000033],
            [73.170553000000041, 33.913890000000038],
            [73.167827874000068, 33.911990530000026],
            [73.16156025600003, 33.907621868000035],
            [73.158765802000062, 33.905674075000036],
            [73.154416595000043, 33.90264258600007],
            [73.152258000000074, 33.90113800000006],
            [73.151709075000042, 33.901123753000036],
            [73.13205802400006, 33.900613719000035],
            [73.130913000000078, 33.900584000000038],
            [73.125228932000084, 33.898918163000076],
            [73.11799731900004, 33.896798785000044],
            [73.114835000000085, 33.895872000000054],
            [73.098479000000054, 33.896703000000059],
            [73.103538023000056, 33.903026454000042],
            [73.106240998000033, 33.906405000000063],
            [73.112063000000035, 33.917771000000073],
            [73.111327670000037, 33.918833077000045],
            [73.104577998000082, 33.928582000000063],
            [73.101720956000065, 33.936667215000057],
            [73.100955025000076, 33.93884848700003],
            [73.106755183000075, 33.943198443000028],
            [73.10792858700006, 33.94407846200005],
            [73.108904364000068, 33.944810267000037],
            [73.109826025000075, 33.945501487000058],
            [73.110651422000046, 33.945815921000076],
            [73.111600292000048, 33.946177391000049],
            [73.121487000000059, 33.949927000000059],
            [73.126795429000083, 33.953273492000051],
            [73.12679535500007, 33.953273077000063],
            [73.13240576000004, 33.956810215000075],
            [73.134239000000036, 33.957966000000056],
            [73.135327786000062, 33.96031780900006],
            [73.137254660000053, 33.964479912000058],
            [73.137522768000053, 33.965059033000045],
            [73.141169000000048, 33.972935000000064],
            [73.141215357000078, 33.975015227000029],
            [73.14121519400004, 33.97501539600006],
            [73.141260220000049, 33.977043187000049],
            [73.14144600000003, 33.985410000000059],
            [73.147268000000054, 33.993726000000038],
            [73.14730306000007, 33.993801959000052],
            [73.149672226000064, 33.998934835000057],
            [73.152258000000074, 34.004537000000028],
            [73.15110340800004, 34.006194265000033],
            [73.150238927000032, 34.005985438000039],
            [73.141239340000084, 34.012441540000054],
            [73.13950600000004, 34.013685000000066],
            [73.133721088000073, 34.017992715000048],
            [73.13172380900005, 34.019479982000064],
            [73.126477923000039, 34.02338631300006],
            [73.126477000000079, 34.02338700100006],
            [73.123868057000038, 34.022414090000041],
            [73.120841935000044, 34.021285608000028],
            [73.12051243500008, 34.02116273300004],
            [73.118262686000037, 34.020323771000051],
            [73.116963008000084, 34.019839104000027],
            [73.114293015000044, 34.018843428000025],
            [73.110122000000047, 34.017288000000065],
            [73.107820357000037, 34.017575835000059],
            [73.106727095000053, 34.017709147000062],
            [73.105669670000054, 34.017838089000065],
            [73.098876442000062, 34.018676115000062],
            [73.096816000000047, 34.01895200000007],
            [73.090163000000075, 34.026714000000027],
            [73.089912415000072, 34.027104620000046],
            [73.086983124000028, 34.031607596000072],
            [73.087011613000072, 34.03162648600005],
            [73.084771529000079, 34.03511840200008],
            [73.080738000000053, 34.041406000000052],
            [73.070819004000043, 34.043593050000027],
            [73.061596664000035, 34.045626493000043],
            [73.055789000000061, 34.046950000000038],
            [73.050984476000053, 34.04702386200006],
            [73.042243959000075, 34.04715823500004],
            [73.037771000000077, 34.047227000000078],
            [73.033681427000033, 34.05267976500005],
            [73.029455000000041, 34.05831500000005],
            [73.028007568000078, 34.059854862000066],
            [73.027707981000049, 34.060173580000026],
            [73.023784100000057, 34.064348030000076],
            [73.020838547000039, 34.067481679000025],
            [73.019659202000071, 34.068724038000028],
            [73.019653672000061, 34.068742217000079],
            [73.019407933000082, 34.069003649000024],
            [73.016426000000081, 34.07217600000007],
            [73.01713056300008, 34.072719491000043],
            [73.017141861000084, 34.072728206000079],
            [73.019408147000036, 34.074476390000029],
            [73.023198801000035, 34.077400453000052],
            [73.024342896000064, 34.078282993000073],
            [73.024521291000042, 34.078420605000076],
            [73.026127999000039, 34.079660000000047],
            [73.025819517000059, 34.083978204000061],
            [73.025794959000052, 34.08432197500008],
            [73.025627271000076, 34.086669307000079],
            [73.025627260000078, 34.086669465000057],
            [73.025339518000067, 34.09069734600007],
            [73.025019000000043, 34.095184000000074],
            [73.021817104000036, 34.096199084000034],
            [73.015720961000056, 34.098131720000026],
            [73.015389893000076, 34.098236677000045],
            [73.013654000000031, 34.098787000000073],
            [73.013257931000055, 34.098759705000077],
            [73.009599783000056, 34.098507607000045],
            [73.009495837000031, 34.098500444000024],
            [73.00948646900008, 34.098499798000034],
            [73.005615000000034, 34.09823300000005],
            [73.005422353000029, 34.097669918000065],
            [73.004213628000059, 34.09413698000003],
            [73.003159151000034, 34.090981568000075],
            [73.002109403000077, 34.087913298000046],
            [73.002036085000043, 34.087699000000043],
            [73.002036049000083, 34.087698984000042],
            [73.000005140000042, 34.086814081000057],
            [72.999004871000068, 34.086372795000045],
            [72.997798769000042, 34.085840702000041],
            [72.993762864000075, 34.084056984000028],
            [72.992586000000074, 34.083541000000025],
            [72.982642800000065, 34.092187397000032],
            [72.979835000000037, 34.094629000000054],
            [72.979834337000057, 34.094628707000027],
            [72.979834000000039, 34.094629000000054],
            [72.980244079000045, 34.095492370000045],
            [72.980256291000046, 34.09551808100008],
            [72.980322184000045, 34.095656810000037],
            [72.980610352000042, 34.096263510000028],
            [72.981605773000069, 34.098359243000061],
            [72.98236649100005, 34.099960839000062],
            [72.983363758000053, 34.102060458000039],
            [72.983972802000039, 34.103342722000036],
            [72.98397281900003, 34.103342758000053],
            [72.984155039000029, 34.103726399000038],
            [72.985101000000043, 34.105718000000024],
            [72.985579631000064, 34.10614066800008],
            [72.987815384000044, 34.108109027000069],
            [72.988396378000061, 34.108620177000034],
            [72.989360100000056, 34.109469166000054],
            [72.989789955000049, 34.109847846000036],
            [72.990256152000029, 34.110258541000064],
            [72.991486085000076, 34.111342049000029],
            [72.992952005000063, 34.112633449000043],
            [72.993653610000081, 34.113251208000065],
            [72.993653539000036, 34.11325146400003],
            [72.994109210000033, 34.113652887000057],
            [72.996744000000035, 34.115974000000051],
            [72.996668958000043, 34.117174137000063],
            [72.996547278000037, 34.119120133000024],
            [72.996330224000076, 34.122591428000078],
            [72.996133778000058, 34.125733139000033],
            [72.995883500000048, 34.129593729000078],
            [72.995836164000082, 34.130114807000041],
            [72.995836097000051, 34.130115547000059],
            [72.995400962000076, 34.134905574000072],
            [72.995400926000059, 34.134905970000034],
            [72.995229905000031, 34.136788580000029],
            [72.995035443000063, 34.138929250000047],
            [72.994930581000062, 34.140083582000045],
            [72.994804000000045, 34.141477001000055],
            [72.994132109000077, 34.145004075000031],
            [72.993746085000055, 34.147030498000049],
            [72.993281225000032, 34.149470766000036],
            [72.992013505000045, 34.156125630000076],
            [72.991477000000032, 34.158942000000025],
            [72.992179328000077, 34.159878390000074],
            [72.992383261000043, 34.160150287000079],
            [72.993588748000036, 34.161757523000063],
            [72.995128227000066, 34.163810058000024],
            [72.99525744400006, 34.163982338000039],
            [72.996467000000052, 34.165595000000053],
            [72.992078475000028, 34.168626700000061],
            [72.988345410000079, 34.171205593000025],
            [72.981219998000029, 34.176128000000062],
            [72.980725784000072, 34.178105043000073],
            [72.978448000000071, 34.187217000000032],
            [72.978920753000068, 34.188116684000079],
            [72.979557300000067, 34.189328078000074],
            [72.980556584000055, 34.191229786000065],
            [72.982433676000085, 34.194802029000073],
            [72.984678968000082, 34.199074981000024],
            [72.984679192000044, 34.199074884000026],
            [72.98526660400006, 34.200192901000037],
            [72.987002792000055, 34.203497378000066],
            [72.987042000000031, 34.203572000000065],
            [72.987175826000055, 34.203866421000043],
            [72.987668162000034, 34.204949579000072],
            [72.989127175000078, 34.208159460000047],
            [72.990492135000068, 34.21116242100004],
            [72.991482949000044, 34.21334224800006],
            [72.991690168000048, 34.213798136000037],
            [72.991913873000044, 34.214290296000058],
            [72.992080606000059, 34.21465711500008],
            [72.992272358000037, 34.215078977000076],
            [72.992435251000074, 34.215437346000044],
            [72.992586000000074, 34.21576900000008],
            [72.992571000000055, 34.215825000000052],
            [72.997853000000077, 34.218818000000056],
            [73.003895680000085, 34.228259731000037],
            [73.003898039000035, 34.228263417000051],
            [73.004410675000031, 34.22906441300006],
            [73.006724000000077, 34.232679000000076],
            [73.00563224800004, 34.234654355000032],
            [73.005617868000058, 34.23468037400005],
            [73.000902000000053, 34.243213000000026],
            [73.005892000000074, 34.248202000000049],
            [73.006289361000029, 34.248573742000076],
            [73.007160097000053, 34.249388341000042],
            [73.014485000000036, 34.256241000000045],
            [73.014663993000056, 34.25629125100005],
            [73.016012325000077, 34.256669784000053],
            [73.01601680400006, 34.256671041000061],
            [73.025013161000061, 34.259196694000025],
            [73.030286000000046, 34.260677000000044],
            [73.030953163000049, 34.261016430000041],
            [73.041533487000038, 34.266399331000059],
            [73.046087000000057, 34.26871600000004],
            [73.046118709000041, 34.268603566000024],
            [73.047970622000037, 34.262037142000054],
            [73.049136000000033, 34.257905000000051],
            [73.049285240000074, 34.257784639000079],
            [73.053394256000047, 34.254470746000038],
            [73.057730000000049, 34.250974000000042],
            [73.059848639000052, 34.248911274000079],
            [73.060945514000082, 34.247843347000071],
            [73.060946229000081, 34.247842650000052],
            [73.063139405000072, 34.245707353000057],
            [73.067069596000067, 34.241880883000078],
            [73.068264000000056, 34.240718000000072],
            [73.075755451000077, 34.233610442000042],
            [73.079075000000046, 34.230461000000048],
            [73.081734833000041, 34.23089327100007],
            [73.089898131000041, 34.232219954000072],
            [73.089921229000083, 34.232223708000049],
            [73.101251000000048, 34.234065000000044],
            [73.101982031000034, 34.234342293000054],
            [73.112349189000042, 34.23827473700004],
            [73.112376303000076, 34.238285022000071],
            [73.113535702000036, 34.238724802000036],
            [73.116828798000029, 34.239973931000065],
            [73.12303980300004, 34.242329874000063],
            [73.125368000000037, 34.243213000000026],
            [73.129940896000051, 34.245192298000063],
            [73.130667601000084, 34.245506840000076],
            [73.130677706000029, 34.245511214000032],
            [73.13930715500004, 34.249246321000044],
            [73.139307622000047, 34.249246523000068],
            [73.143941000000041, 34.251252000000079],
            [73.15194740000004, 34.245298485000035],
            [73.154440893000071, 34.243444337000028],
            [73.15475200000003, 34.243213000000026],
            [73.15906725800005, 34.241911553000079],
            [73.160178374000054, 34.241576449000036],
            [73.160214613000051, 34.241565520000051],
            [73.166708089000053, 34.239607141000079],
            [73.172216000000049, 34.237946000000079],
            [73.182196000000033, 34.238500000000045],
            [73.187937871000031, 34.22779872600006],
            [73.188294000000042, 34.227135000000033],
            [73.19255412900003, 34.227135000000033],
            [73.192561778000083, 34.227135000000033],
            [73.197997000000044, 34.227135000000033],
            [73.198392604000048, 34.229360371000041],
            [73.198392736000073, 34.22936111100006],
            [73.201742954000053, 34.248206942000024],
            [73.202432000000044, 34.252083000000027],
            [73.202450768000062, 34.252142269000046],
            [73.205451656000037, 34.26161896800005],
            [73.207699000000048, 34.26871600000004],
            [73.207700155000055, 34.268720075000033],
            [73.210144343000081, 34.277345524000054],
            [73.212412000000029, 34.285348000000056],
            [73.217313751000063, 34.28620797800005],
            [73.219270856000037, 34.286551338000038],
            [73.219271133000063, 34.286551387000031],
            [73.224813466000057, 34.287523751000037],
            [73.22482093900004, 34.287525061000053],
            [73.228212000000042, 34.288120000000049],
            [73.235030567000081, 34.284118263000039],
            [73.235035506000031, 34.284115364000058],
            [73.240964000000076, 34.280636000000072],
            [73.247352343000045, 34.277284670000029],
            [73.257874000000072, 34.271765000000073],
            [73.264810427000043, 34.282902898000032],
            [73.277555000000064, 34.303367000000037],
            [73.282669908000059, 34.304503469000053],
            [73.282725244000062, 34.30451576400003],
            [73.300009000000045, 34.30835600000006],
            [73.305913049000083, 34.311198904000037],
            [73.305957026000044, 34.311220080000055],
            [73.311780654000074, 34.314024260000053],
            [73.318242249000036, 34.317135633000078],
            [73.318245585000057, 34.317137239000033],
            [73.322463000000084, 34.319168000000047],
            [73.327655616000072, 34.323915588000034],
            [73.341867000000036, 34.336909000000048],
            [73.351292000000058, 34.354096000000027],
            [73.35903875300005, 34.364945119000026],
            [73.371135160000051, 34.367515489000027],
            [73.371192959000041, 34.367527968000047],
            [73.371193023000046, 34.36752798200007],
            [73.371266798000079, 34.367543911000041],
            [73.371335280000039, 34.36755869700005],
            [73.383432753000079, 34.37021211900003],
            [73.401074053000059, 34.370762975000048],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "65",
      properties: { name: "Bannu", count: 487 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.81695078000007, 33.044973982000045],
            [70.822518000000059, 33.036370000000034],
            [70.838533806000044, 33.028856193000024],
            [70.851464000000078, 33.022790000000043],
            [70.851903855000046, 33.022738593000042],
            [70.878981000000067, 33.019574000000034],
            [70.902567000000033, 33.014928000000054],
            [70.915110000000084, 33.012793000000045],
            [70.919363000000033, 33.012069000000054],
            [70.91816398800006, 32.998079596000025],
            [70.917219000000046, 32.987054000000057],
            [70.917922265000072, 32.986102499000026],
            [70.932893390000061, 32.965846916000032],
            [70.935444000000075, 32.962396000000069],
            [70.943663672000071, 32.949780058000044],
            [70.950811000000044, 32.938810000000046],
            [70.952326209000034, 32.935192948000065],
            [70.961889000000042, 32.912365000000079],
            [70.961643541000058, 32.892518507000034],
            [70.961531000000036, 32.88341900000006],
            [70.957958000000076, 32.868052000000034],
            [70.953621215000055, 32.867267707000053],
            [70.953605306000043, 32.867237613000043],
            [70.952326173000074, 32.867006228000037],
            [70.948300611000036, 32.86627803500005],
            [70.943882692000045, 32.865478868000025],
            [70.940655348000064, 32.864895067000077],
            [70.93627284400003, 32.864102306000063],
            [70.931026565000082, 32.863153295000075],
            [70.926757323000061, 32.862378505000038],
            [70.92675106400003, 32.862405909000074],
            [70.926735334000057, 32.862403080000036],
            [70.924366000000077, 32.861977000000024],
            [70.914923762000058, 32.858421019000048],
            [70.907834598000079, 32.855751215000055],
            [70.896849000000032, 32.851614000000041],
            [70.895141655000032, 32.850682583000037],
            [70.893762806000041, 32.849930372000074],
            [70.893762664000064, 32.849930294000046],
            [70.888987000000043, 32.847325000000069],
            [70.885646104000045, 32.85276932000005],
            [70.883742598000083, 32.855871272000059],
            [70.883026700000073, 32.857037899000034],
            [70.881649627000058, 32.859281976000034],
            [70.880367700000079, 32.861371002000055],
            [70.879612207000037, 32.862602153000068],
            [70.879338000000075, 32.863049000000046],
            [70.877265097000077, 32.865181238000048],
            [70.874913291000041, 32.867600361000029],
            [70.872026210000058, 32.870570081000039],
            [70.869858552000039, 32.872799787000076],
            [70.868225196000083, 32.874479896000025],
            [70.866831000000047, 32.87591400000008],
            [70.863631198000064, 32.87399434200006],
            [70.861458758000083, 32.872691030000055],
            [70.859468617000061, 32.871497085000044],
            [70.858113266000032, 32.870683969000027],
            [70.855982440000048, 32.869405623000034],
            [70.852536000000043, 32.867338000000075],
            [70.84580079300008, 32.862787075000028],
            [70.839314000000059, 32.858404000000064],
            [70.832654964000028, 32.855691111000056],
            [70.829665000000034, 32.854473000000041],
            [70.817866673000083, 32.856208277000064],
            [70.817515000000071, 32.856260000000077],
            [70.808689635000064, 32.860881998000025],
            [70.802505000000053, 32.864121000000068],
            [70.800501926000038, 32.86509410900004],
            [70.797382111000047, 32.866609740000058],
            [70.789998000000082, 32.870197000000076],
            [70.78662896600008, 32.86632866900004],
            [70.784883279000042, 32.864324268000075],
            [70.780349000000058, 32.859118000000024],
            [70.77373288800004, 32.850738168000078],
            [70.77135607200006, 32.847727741000028],
            [70.769628000000068, 32.845539000000031],
            [70.761925405000056, 32.837929114000076],
            [70.756508136000036, 32.832577047000029],
            [70.753480466000042, 32.829585818000055],
            [70.745508218000055, 32.821709524000028],
            [70.739967000000036, 32.816235000000063],
            [70.726465694000069, 32.818606982000063],
            [70.720456822000074, 32.81966265300008],
            [70.713522000000069, 32.820881000000043],
            [70.713521922000041, 32.820881056000076],
            [70.704247361000057, 32.827523780000035],
            [70.69420963500005, 32.834713105000048],
            [70.691465183000048, 32.836678765000045],
            [70.687078000000042, 32.839821000000029],
            [70.686553580000066, 32.839932216000079],
            [70.675285000000031, 32.842322000000024],
            [70.675283876000037, 32.842320451000035],
            [70.666390696000065, 32.830063156000051],
            [70.664225692000059, 32.827079174000062],
            [70.662088833000041, 32.824130862000061],
            [70.662086613000042, 32.824130924000031],
            [70.662062000000049, 32.824097000000052],
            [70.64347900000007, 32.82588400000003],
            [70.641223839000077, 32.824398774000031],
            [70.636718598000073, 32.821431668000059],
            [70.634951602000058, 32.820267942000044],
            [70.628828000000055, 32.816235000000063],
            [70.622621682000045, 32.815783775000057],
            [70.60923925700007, 32.81481081700008],
            [70.609173000000055, 32.814806000000033],
            [70.60915589800004, 32.814863415000048],
            [70.607126746000063, 32.821675654000046],
            [70.605813726000065, 32.826083707000066],
            [70.604170000000067, 32.831602000000032],
            [70.593832266000049, 32.834303788000057],
            [70.572722000000056, 32.839821000000029],
            [70.561696918000052, 32.834760356000061],
            [70.557588700000053, 32.832874635000053],
            [70.550923000000068, 32.829815000000053],
            [70.546821388000069, 32.824097507000033],
            [70.539130000000057, 32.813376000000062],
            [70.53775231700007, 32.809105724000062],
            [70.535556000000042, 32.802298000000064],
            [70.534591376000037, 32.800517204000073],
            [70.530910000000063, 32.793721000000062],
            [70.514834050000047, 32.797650766000061],
            [70.514829000000077, 32.797652000000028],
            [70.516427409000073, 32.782127882000054],
            [70.516800885000066, 32.778500602000065],
            [70.51733100000007, 32.773352000000045],
            [70.51018300000004, 32.762988000000064],
            [70.503308335000042, 32.766112729000042],
            [70.494460330000038, 32.77013439600006],
            [70.494459000000063, 32.770135000000039],
            [70.482511462000048, 32.760809943000027],
            [70.482510469000033, 32.760809168000037],
            [70.482509965000077, 32.760808775000044],
            [70.465156000000036, 32.74726400000003],
            [70.40976500000005, 32.727967000000035],
            [70.400066000000038, 32.723464000000035],
            [70.399358000000063, 32.726093000000049],
            [70.391406000000075, 32.724105000000066],
            [70.383455000000083, 32.716153000000077],
            [70.373515000000054, 32.705551000000071],
            [70.373913039000058, 32.707939234000037],
            [70.382792000000052, 32.761213000000055],
            [70.381618676000073, 32.76707873600003],
            [70.380141000000037, 32.774466000000075],
            [70.367551000000049, 32.780429000000026],
            [70.368877000000055, 32.800309000000027],
            [70.376828000000046, 32.82482600000003],
            [70.403997000000061, 32.859946000000036],
            [70.41099028800005, 32.872621912000056],
            [70.414599000000067, 32.879163000000062],
            [70.428970527000047, 32.902951257000041],
            [70.433815000000038, 32.910970000000077],
            [70.44663379900004, 32.928670922000038],
            [70.447731000000033, 32.930186000000049],
            [70.45899600000007, 32.938801000000069],
            [70.472911000000067, 32.947415000000035],
            [70.472222096000053, 32.951548080000066],
            [70.470923000000084, 32.959342000000049],
            [70.46164600000003, 32.974583000000052],
            [70.472911000000067, 32.984523000000024],
            [70.483513000000073, 32.996450000000038],
            [70.492305411000075, 33.000532496000062],
            [70.502067000000068, 33.005065000000059],
            [70.510361329000034, 33.01381966100007],
            [70.513995000000079, 33.017655000000047],
            [70.523272000000077, 33.030908000000068],
            [70.523272000000077, 33.046914000000072],
            [70.523272000000077, 33.048965088000045],
            [70.523272000000077, 33.053438000000028],
            [70.535200000000032, 33.054100000000062],
            [70.561705000000075, 33.044823000000065],
            [70.567115680000029, 33.043593368000074],
            [70.576283000000046, 33.041510000000073],
            [70.579068409000058, 33.04181949000008],
            [70.586339573000032, 33.042627397000047],
            [70.594175000000064, 33.043498000000056],
            [70.600784543000032, 33.046976541000049],
            [70.606765000000053, 33.050124000000039],
            [70.613541604000034, 33.050124000000039],
            [70.619355000000041, 33.050124000000039],
            [70.623170338000079, 33.047453379000046],
            [70.625981000000081, 33.045486000000039],
            [70.630304357000057, 33.045336841000051],
            [70.635685192000039, 33.045151199000031],
            [70.638929747000077, 33.045039259000077],
            [70.643444857000077, 33.044883485000071],
            [70.64519800000005, 33.044823000000065],
            [70.656632235000075, 33.044347023000057],
            [70.661101000000031, 33.044161000000031],
            [70.663762628000029, 33.046290222000039],
            [70.664638593000063, 33.046990967000056],
            [70.674354000000051, 33.054763000000037],
            [70.692908000000045, 33.057413000000054],
            [70.697810247000064, 33.057413000000054],
            [70.716763000000071, 33.057413000000054],
            [70.722245998000062, 33.059423442000025],
            [70.736642000000074, 33.064702000000068],
            [70.761713773000054, 33.078379265000024],
            [70.787351000000058, 33.092365000000029],
            [70.787352038000051, 33.092363753000029],
            [70.787854000000038, 33.091761000000076],
            [70.806794000000082, 33.06067100000007],
            [70.81695078000007, 33.044973982000045],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "66",
      properties: { name: "Batagram", count: 1274 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.023443130000032, 34.583552544000042],
            [73.02325655900006, 34.583580392000044],
            [73.023257172000058, 34.583580227000027],
            [73.023443130000032, 34.583552544000042],
            [73.023443060000034, 34.58355240800006],
            [73.022440336000045, 34.583702063000032],
            [73.017959064000081, 34.584370888000024],
            [73.010960218000037, 34.585415459000046],
            [73.010935968000069, 34.585419078000029],
            [73.008109807000039, 34.585840880000035],
            [72.994804000000045, 34.577525000000037],
            [72.979557000000057, 34.575862000000029],
            [72.978036343000042, 34.576212942000041],
            [72.968746000000067, 34.57835700000004],
            [72.961943944000041, 34.573545585000033],
            [72.96194371200005, 34.573545421000063],
            [72.957381000000055, 34.570318000000043],
            [72.942412000000047, 34.566437000000064],
            [72.942411350000043, 34.566437091000068],
            [72.94241100000005, 34.566437000000064],
            [72.940712255000051, 34.566673998000056],
            [72.940712188000077, 34.566674147000072],
            [72.940259075000029, 34.566737362000026],
            [72.939997194000057, 34.566773898000065],
            [72.930492000000072, 34.568100000000072],
            [72.921886986000061, 34.575748288000057],
            [72.918017000000077, 34.579188000000045],
            [72.916915756000037, 34.580955164000045],
            [72.906097000000045, 34.598316000000068],
            [72.904989000000057, 34.614948000000027],
            [72.899999000000037, 34.626591000000076],
            [72.901734905000069, 34.636173296000038],
            [72.906929000000048, 34.664845000000071],
            [72.901662036000062, 34.672482074000072],
            [72.895840000000078, 34.680924000000061],
            [72.896277993000069, 34.68350990700003],
            [72.897160378000081, 34.688719495000043],
            [72.89902029600006, 34.699700420000056],
            [72.901662000000044, 34.715297000000078],
            [72.899493181000082, 34.730013788000065],
            [72.897781000000066, 34.741632000000038],
            [72.901609982000082, 34.765974883000069],
            [72.901610000000062, 34.765975000000026],
            [72.904434000000037, 34.765749000000028],
            [72.920512001000077, 34.771570000000054],
            [72.920982299000059, 34.772788627000068],
            [72.926610000000039, 34.787371000000064],
            [72.936867000000063, 34.805667000000028],
            [72.943823713000029, 34.806130905000032],
            [72.943823839000061, 34.806130913000061],
            [72.943854524000074, 34.80613296000007],
            [72.943857192000053, 34.806133138000064],
            [72.95181800000006, 34.806664000000069],
            [72.957658000000038, 34.807053000000053],
            [72.962370000000078, 34.813983000000064],
            [72.98398525600004, 34.813983000000064],
            [72.988705000000039, 34.813983000000064],
            [73.001369208000028, 34.819863539000039],
            [73.004228000000069, 34.821191000000056],
            [73.004377390000059, 34.830724556000064],
            [73.004506000000049, 34.838932000000057],
            [72.997853000000077, 34.846416000000033],
            [72.975122000000056, 34.851129000000071],
            [72.961716011000078, 34.856638202000056],
            [72.958472055000072, 34.85797130800006],
            [72.954886000000045, 34.859445000000051],
            [72.936313000000041, 34.867484000000047],
            [72.896395000000041, 34.881899000000033],
            [72.893885330000046, 34.882587458000046],
            [72.865070001000049, 34.890492000000052],
            [72.862021000000084, 34.905462000000057],
            [72.867859125000052, 34.908309665000047],
            [72.873387000000037, 34.911006000000043],
            [72.875609391000069, 34.914463415000057],
            [72.875722242000052, 34.914294346000077],
            [72.875882000000047, 34.914055000000076],
            [72.87750408200003, 34.914294346000077],
            [72.892791000000045, 34.916550000000029],
            [72.913205413000071, 34.927991329000065],
            [72.918017000000077, 34.930688000000032],
            [72.939362000000074, 34.942885000000047],
            [72.94017014700006, 34.942960526000036],
            [72.969070908000049, 34.945694382000056],
            [72.995770908000054, 34.957694382000057],
            [73.000070908000055, 34.958694382000033],
            [73.012115641000037, 34.961454633000074],
            [73.019198000000074, 34.963121000000058],
            [73.026045422000038, 34.963743437000062],
            [73.052740000000028, 34.966170000000034],
            [73.05857366400005, 34.963081407000061],
            [73.06216500000005, 34.96118000000007],
            [73.075870586000065, 34.956394346000025],
            [73.07606988100008, 34.956324757000061],
            [73.076079290000052, 34.95632147200007],
            [73.079629000000068, 34.955082000000061],
            [73.089153053000075, 34.945137575000047],
            [73.098479000000054, 34.935400000000072],
            [73.101254140000037, 34.93347297400004],
            [73.108459000000039, 34.928470000000061],
            [73.113620765000064, 34.928278829000078],
            [73.113617797000074, 34.928301717000068],
            [73.145870908000063, 34.927094382000064],
            [73.171170908000079, 34.928194382000072],
            [73.180270908000068, 34.928294382000047],
            [73.187677782000037, 34.931147850000059],
            [73.192470908000075, 34.932994382000061],
            [73.208661646000053, 34.937017535000052],
            [73.20881466000003, 34.937055557000065],
            [73.222945000000038, 34.921263000000067],
            [73.233756000000028, 34.915164000000061],
            [73.248726000000033, 34.923203000000058],
            [73.251751406000039, 34.922008806000065],
            [73.251751442000057, 34.922008792000042],
            [73.25926000000004, 34.91904500000004],
            [73.275370872000053, 34.922722269000076],
            [73.284763000000055, 34.924866000000065],
            [73.286748462000048, 34.924915690000034],
            [73.298498486000028, 34.92520975900004],
            [73.306939000000057, 34.925421000000028],
            [73.331333000000029, 34.930410000000052],
            [73.354132040000081, 34.93123440800008],
            [73.354342000000031, 34.931242000000054],
            [73.380122000000028, 34.925975000000051],
            [73.381499198000029, 34.926112703000058],
            [73.39467090800008, 34.926294382000037],
            [73.396674396000037, 34.927630041000043],
            [73.396754000000044, 34.927638000000059],
            [73.414773000000082, 34.918213000000037],
            [73.428633000000048, 34.907402000000047],
            [73.435998918000053, 34.904455878000078],
            [73.446652000000029, 34.900195000000053],
            [73.474095000000034, 34.902135000000044],
            [73.490840974000037, 34.898894346000077],
            [73.491282000000069, 34.898809000000028],
            [73.491301000000078, 34.89882300000005],
            [73.492391000000055, 34.88356200000004],
            [73.496549000000073, 34.874414000000058],
            [73.48268900000005, 34.859722000000033],
            [73.464948001000039, 34.848634000000061],
            [73.45607700000005, 34.836160000000064],
            [73.447206000000051, 34.828952000000072],
            [73.443603000000053, 34.81703200000004],
            [73.438059000000067, 34.810934000000032],
            [73.428911000000085, 34.80372600000004],
            [73.399804000000074, 34.80178600000005],
            [73.393151000000046, 34.791529000000025],
            [73.390656000000035, 34.777392000000077],
            [73.382340000000056, 34.771016000000031],
            [73.382339160000072, 34.771016123000038],
            [73.382339000000059, 34.771016000000031],
            [73.368328041000041, 34.77306608400005],
            [73.368327261000047, 34.773066197000048],
            [73.359608000000037, 34.774342000000047],
            [73.356443967000075, 34.776042159000042],
            [73.342653003000066, 34.783452584000031],
            [73.342652970000074, 34.783452602000068],
            [73.341035000000034, 34.784322000000031],
            [73.327066923000075, 34.789788151000039],
            [73.327019362000044, 34.789806762000069],
            [73.327019326000084, 34.789806776000034],
            [73.32701948600004, 34.789807105000079],
            [73.327019362000044, 34.789807154000073],
            [73.321909000000062, 34.791807000000063],
            [73.318098789000032, 34.792451727000071],
            [73.303890000000081, 34.794856000000038],
            [73.261755000000051, 34.79457900400007],
            [73.240687000000037, 34.79042000000004],
            [73.222114000000033, 34.782104000000061],
            [73.218798000000049, 34.771494000000075],
            [73.217956000000072, 34.768798000000061],
            [73.223966525000037, 34.757238978000032],
            [73.223966553000082, 34.757238924000035],
            [73.225163000000066, 34.754938000000038],
            [73.222946000000036, 34.743572000000029],
            [73.207422000000065, 34.729435000000024],
            [73.201601000000039, 34.711416000000042],
            [73.201046000000076, 34.694507000000044],
            [73.200648513000033, 34.693794833000027],
            [73.194393000000048, 34.682587000000069],
            [73.194170049000036, 34.682069741000078],
            [73.194166690000031, 34.682061947000079],
            [73.187610045000042, 34.666850154000031],
            [73.187609046000034, 34.666847837000034],
            [73.187463000000037, 34.666509000000076],
            [73.186123511000062, 34.662171367000042],
            [73.182318705000057, 34.649850352000044],
            [73.181642000000068, 34.647659000000033],
            [73.181641028000058, 34.647659092000026],
            [73.18164100000007, 34.647659000000033],
            [73.147287521000067, 34.650918231000048],
            [73.147274421000077, 34.650919474000034],
            [73.143664000000058, 34.651262000000031],
            [73.121152352000081, 34.645579686000076],
            [73.115111999000078, 34.644055000000037],
            [73.092104000000063, 34.624928000000068],
            [73.09177416700004, 34.621464637000031],
            [73.089789437000036, 34.600624254000024],
            [73.08933200000007, 34.595821000000058],
            [73.090331133000063, 34.58971282300007],
            [73.091826000000083, 34.58057400000007],
            [73.087547241000038, 34.568539447000035],
            [73.087391000000082, 34.568100000000072],
            [73.085299589000044, 34.566606157000024],
            [73.074275939000074, 34.558732237000072],
            [73.073808000000042, 34.558398000000068],
            [73.068033945000082, 34.558152041000028],
            [73.060779000000082, 34.557843000000048],
            [73.047196000000042, 34.557566000000065],
            [73.038696362000053, 34.561716704000048],
            [73.035276000000067, 34.563387000000034],
            [73.033228681000082, 34.565222685000037],
            [73.033227176000082, 34.565224034000039],
            [73.029997861000084, 34.568119531000036],
            [73.027237000000071, 34.570595000000026],
            [73.026683012000035, 34.583068733000061],
            [73.026682903000051, 34.58306876100005],
            [73.026683000000048, 34.58306900000008],
            [73.024481630000082, 34.583397552000065],
            [73.023465951000048, 34.583549141000049],
            [73.023443130000032, 34.583552544000042],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "67",
      properties: { name: "Buner", count: 646 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.527986000000055, 34.705318000000034],
            [72.528263000000038, 34.692843000000039],
            [72.527154964000033, 34.682872674000066],
            [72.527154000000053, 34.682864000000052],
            [72.527154964000033, 34.682862642000032],
            [72.530220912000061, 34.678542968000045],
            [72.53022098100007, 34.678543069000057],
            [72.530221023000081, 34.678543130000037],
            [72.533253000000059, 34.67427131900007],
            [72.540010255000084, 34.669693088000031],
            [72.541846000000078, 34.668449319000047],
            [72.541846000000078, 34.668449000000066],
            [72.54795069100004, 34.667040138000061],
            [72.547950800000081, 34.667040344000043],
            [72.552658000000065, 34.665954000000056],
            [72.553654524000081, 34.665213746000063],
            [72.553654572000084, 34.665213710000046],
            [72.562360000000069, 34.658747000000062],
            [72.56585803400003, 34.652663844000074],
            [72.565858070000047, 34.652663781000058],
            [72.568736000000058, 34.647659000000033],
            [72.569013000000041, 34.635184000000038],
            [72.57834263500007, 34.634147374000065],
            [72.581487000000038, 34.63379800000007],
            [72.596179000000063, 34.637679000000048],
            [72.606990000000053, 34.645441000000062],
            [72.621128000000056, 34.642669000000069],
            [72.636651000000029, 34.645441000000062],
            [72.640217162000056, 34.645726294000042],
            [72.650512000000049, 34.646550000000047],
            [72.650553908000063, 34.648101626000027],
            [72.650757469000041, 34.655638569000075],
            [72.650789000000032, 34.656806000000074],
            [72.654670000000067, 34.665677000000073],
            [72.666035000000079, 34.672884000000067],
            [72.667789521000032, 34.673315936000051],
            [72.68405400000006, 34.677320000000066],
            [72.686880762000044, 34.676982426000052],
            [72.698314523000079, 34.675616999000056],
            [72.702627000000064, 34.675102000000038],
            [72.711350525000057, 34.67167530200004],
            [72.711350956000047, 34.671675133000065],
            [72.718151000000034, 34.669004000000029],
            [72.718793702000085, 34.661713856000063],
            [72.718971765000049, 34.661571477000052],
            [72.718981040000074, 34.65974938100004],
            [72.718981955000061, 34.659579510000071],
            [72.718981958000029, 34.659578967000073],
            [72.71898200000004, 34.659578500000066],
            [72.718981959000075, 34.659578466000028],
            [72.714366895000069, 34.655717166000045],
            [72.705399000000057, 34.648213000000055],
            [72.70197879400007, 34.63810883900004],
            [72.699300000000051, 34.630195000000072],
            [72.699300127000072, 34.630194376000077],
            [72.699816101000067, 34.627661043000046],
            [72.700715582000043, 34.623246508000079],
            [72.701084540000068, 34.621435710000071],
            [72.701199219000046, 34.620872883000061],
            [72.702350000000081, 34.615225000000066],
            [72.703378078000071, 34.612987445000044],
            [72.711775000000046, 34.594712000000072],
            [72.714948023000034, 34.588976125000045],
            [72.717868743000054, 34.583696342000053],
            [72.718760738000071, 34.582083882000063],
            [72.72340704700008, 34.573684745000037],
            [72.726190000000031, 34.568654000000038],
            [72.731734000000074, 34.553408000000047],
            [72.731657746000053, 34.548905734000073],
            [72.731457000000034, 34.537053000000071],
            [72.729516000000046, 34.518480000000068],
            [72.73315264200005, 34.515988114000038],
            [72.744485000000054, 34.508223000000044],
            [72.751825316000065, 34.499847599000077],
            [72.751825347000079, 34.49984756300006],
            [72.751825712000084, 34.499847534000025],
            [72.766108000000031, 34.483552000000032],
            [72.770543000000032, 34.474958000000072],
            [72.760286000000065, 34.471077000000037],
            [72.751416000000063, 34.463316000000077],
            [72.741991000000041, 34.459157000000062],
            [72.74199017400008, 34.459157077000043],
            [72.741990000000044, 34.459157000000062],
            [72.730070558000079, 34.460265948000028],
            [72.720646000000045, 34.459157000000062],
            [72.719044066000038, 34.456815883000047],
            [72.717042000000049, 34.453890000000058],
            [72.734506000000067, 34.433100000000024],
            [72.749752000000058, 34.418408000000056],
            [72.749958168000035, 34.417495166000037],
            [72.749999135000053, 34.417313783000054],
            [72.750137088000031, 34.416702978000046],
            [72.751693000000046, 34.40981400000004],
            [72.75126427400005, 34.402957472000026],
            [72.750861000000043, 34.39650800000004],
            [72.743931000000032, 34.385420000000067],
            [72.743820572000061, 34.383359687000052],
            [72.743099000000029, 34.369897000000037],
            [72.744763000000034, 34.354927000000032],
            [72.744796938000036, 34.354858125000078],
            [72.744797000000062, 34.354858000000036],
            [72.743099000000029, 34.354373000000066],
            [72.741782674000035, 34.351881442000035],
            [72.739675650000038, 34.347893244000034],
            [72.739306211000041, 34.347193966000077],
            [72.738959254000065, 34.346537242000068],
            [72.735337000000072, 34.339681000000041],
            [72.724804000000063, 34.323048000000028],
            [72.723252994000063, 34.319481386000064],
            [72.719259000000079, 34.310297000000048],
            [72.712884000000031, 34.303644000000077],
            [72.712811000000045, 34.303488000000073],
            [72.712810964000084, 34.303487997000047],
            [72.700556905000042, 34.30230954700005],
            [72.700556951000067, 34.30230933100006],
            [72.693026616000054, 34.301585290000048],
            [72.688489036000078, 34.301149003000035],
            [72.688489000000061, 34.301149000000066],
            [72.685008708000055, 34.299719486000072],
            [72.672966000000031, 34.294773000000077],
            [72.662914701000034, 34.289273245000061],
            [72.658274000000063, 34.286734000000024],
            [72.644968000000063, 34.27952700000003],
            [72.643974894000053, 34.27865801400003],
            [72.640086242000052, 34.275255370000025],
            [72.631662000000063, 34.267884000000038],
            [72.630108126000039, 34.266283075000047],
            [72.626384224000049, 34.262446414000067],
            [72.622514000000081, 34.258459000000073],
            [72.622513964000063, 34.258458973000074],
            [72.612664232000043, 34.251147776000039],
            [72.60545200200005, 34.245794327000056],
            [72.58849697800008, 34.233209057000067],
            [72.588253634000068, 34.233028429000058],
            [72.588253351000048, 34.233028691000072],
            [72.568735000000061, 34.218541000000073],
            [72.570027767000056, 34.216799766000065],
            [72.576361203000033, 34.208269235000046],
            [72.582318000000043, 34.20024600000005],
            [72.584734336000054, 34.188606390000075],
            [72.585364865000031, 34.185568105000073],
            [72.585364970000057, 34.18556810900003],
            [72.585367915000063, 34.185553919000029],
            [72.58536795100008, 34.185553747000029],
            [72.585368000000074, 34.18555351200007],
            [72.58536795100008, 34.185553465000055],
            [72.572617000000037, 34.173356513000044],
            [72.572616423000056, 34.17335640500005],
            [72.572482121000064, 34.173330901000043],
            [72.57240562800007, 34.17331637500007],
            [72.554875000000038, 34.170030000000054],
            [72.546559000000059, 34.167535000000044],
            [72.538250715000061, 34.155072573000041],
            [72.538250117000075, 34.155073174000051],
            [72.538242000000082, 34.155061000000046],
            [72.529559937000045, 34.154440733000058],
            [72.529559908000067, 34.154440699000077],
            [72.522719000000052, 34.153952000000061],
            [72.512462000000085, 34.162822000000062],
            [72.510401010000066, 34.16398470200005],
            [72.501651000000038, 34.168921000000068],
            [72.488900000000058, 34.168921000000068],
            [72.478184640000052, 34.174278197000035],
            [72.478182071000049, 34.174279481000042],
            [72.477811000000031, 34.174465000000055],
            [72.470881000000077, 34.179455000000075],
            [72.470847323000044, 34.179459714000075],
            [72.470831229000055, 34.179471301000035],
            [72.456971228000043, 34.181411302000072],
            [72.435349229000053, 34.186678302000075],
            [72.413795718000074, 34.189170455000067],
            [72.42264700000004, 34.195533000000069],
            [72.419716821000065, 34.205123115000049],
            [72.419598000000065, 34.205512000000056],
            [72.419487645000061, 34.209149939000042],
            [72.419487462000063, 34.209155945000077],
            [72.419487845000049, 34.209155878000047],
            [72.419321119000074, 34.214662028000077],
            [72.423756000000083, 34.222699000000034],
            [72.427082000000041, 34.227966000000038],
            [72.441220000000044, 34.247094000000061],
            [72.443761374000076, 34.249588346000053],
            [72.451368511000055, 34.257054714000049],
            [72.456189000000052, 34.261786000000029],
            [72.460624000000053, 34.270102000000065],
            [72.457752839000079, 34.27518170500008],
            [72.450348895000047, 34.288280886000052],
            [72.449813000000063, 34.289229000000034],
            [72.450473095000063, 34.292693955000061],
            [72.45160402700003, 34.298630413000069],
            [72.453140000000076, 34.306693000000053],
            [72.464228000000048, 34.314178000000027],
            [72.470158980000065, 34.31787093500003],
            [72.478920000000073, 34.323326000000066],
            [72.485296000000062, 34.340790000000027],
            [72.48140552600006, 34.349574124000071],
            [72.476702000000046, 34.360194000000035],
            [72.469015608000063, 34.371104151000054],
            [72.468109000000084, 34.37239100000005],
            [72.447318000000053, 34.37266900000003],
            [72.434694814000068, 34.381169646000046],
            [72.434686105000083, 34.381175511000038],
            [72.43373500000007, 34.381816000000072],
            [72.430530975000067, 34.388661726000066],
            [72.427637000000061, 34.394845000000032],
            [72.423479501000031, 34.405932663000044],
            [72.421710518000054, 34.406346615000075],
            [72.416946356000039, 34.407458170000041],
            [72.398946925000075, 34.411657725000055],
            [72.398530000000051, 34.411755000000028],
            [72.38300600000008, 34.418408000000056],
            [72.376096675000042, 34.424953297000059],
            [72.372472000000073, 34.428387000000043],
            [72.370510646000071, 34.429601348000062],
            [72.364423735000059, 34.433369984000024],
            [72.360830000000078, 34.435595000000035],
            [72.327848024000048, 34.439197342000057],
            [72.299586447000081, 34.437812000000065],
            [72.280885294000029, 34.430812470000035],
            [72.269925448000038, 34.430605000000071],
            [72.269924292000042, 34.43060545700007],
            [72.269906000000049, 34.430605000000071],
            [72.257986000000074, 34.435318000000052],
            [72.25261460300004, 34.438935181000033],
            [72.244403000000034, 34.444465000000037],
            [72.232483000000059, 34.44973200000004],
            [72.220840000000067, 34.456940000000031],
            [72.221250968000049, 34.46927794000004],
            [72.221251042000063, 34.469280187000038],
            [72.221394000000032, 34.473572000000047],
            [72.221844625000074, 34.476331621000043],
            [72.22361200000006, 34.48715500000003],
            [72.219342468000036, 34.494942003000062],
            [72.218900000000076, 34.495749000000046],
            [72.207534000000066, 34.501570000000072],
            [72.203099000000066, 34.510995000000037],
            [72.201229233000049, 34.515535433000025],
            [72.201229000000069, 34.515536000000054],
            [72.210029000000077, 34.515153000000055],
            [72.215850000000046, 34.523192000000051],
            [72.222226000000035, 34.531509000000028],
            [72.234025084000052, 34.535274781000055],
            [72.235254000000054, 34.535667000000046],
            [72.229433000000029, 34.545923000000073],
            [72.220285000000047, 34.559784000000036],
            [72.221117000000049, 34.570040000000063],
            [72.221209720000047, 34.570327132000045],
            [72.221210000000042, 34.570328000000075],
            [72.226661000000036, 34.56976300000008],
            [72.237398577000079, 34.573479997000049],
            [72.241076000000078, 34.574753000000044],
            [72.258817000000079, 34.581406000000072],
            [72.273322094000036, 34.594493803000034],
            [72.280767036000043, 34.601211300000045],
            [72.292636000000073, 34.610790000000065],
            [72.29994362900004, 34.612084185000072],
            [72.319248000000073, 34.615503000000047],
            [72.330656848000046, 34.618614226000034],
            [72.336470825000049, 34.620199641000056],
            [72.347555102000058, 34.623222214000066],
            [72.352790500000083, 34.624650000000031],
            [72.358905074000063, 34.632192365000037],
            [72.361106000000063, 34.634907000000055],
            [72.36942300000004, 34.646550000000047],
            [72.377739000000076, 34.659856000000048],
            [72.389382000000069, 34.670667000000037],
            [72.404092355000046, 34.681962976000079],
            [72.404905000000042, 34.682587000000069],
            [72.418766000000062, 34.68674500000003],
            [72.422277545000043, 34.685831897000071],
            [72.424515186000065, 34.685250045000032],
            [72.424794383000062, 34.685177446000068],
            [72.432626000000084, 34.683141000000035],
            [72.446763000000033, 34.677597000000048],
            [72.448137333000034, 34.677389522000055],
            [72.461455000000058, 34.675379000000078],
            [72.468767856000056, 34.675013643000057],
            [72.478088000000071, 34.674548000000073],
            [72.478207620000035, 34.675013643000057],
            [72.478854220000073, 34.67753065200003],
            [72.478854220000073, 34.677530971000067],
            [72.482871729000067, 34.693169858000033],
            [72.482871809000073, 34.69316985100005],
            [72.485850000000084, 34.704763000000071],
            [72.48822033700003, 34.707924019000075],
            [72.490008000000046, 34.710308000000055],
            [72.495313005000071, 34.710440530000028],
            [72.496510967000063, 34.710470457000042],
            [72.49679406000007, 34.710477529000059],
            [72.499308864000056, 34.710540354000045],
            [72.501096000000075, 34.710585000000037],
            [72.501230709000083, 34.710552146000055],
            [72.512462000000085, 34.707813000000044],
            [72.52794375600007, 34.705536036000069],
            [72.527944001000037, 34.705536000000052],
            [72.527986000000055, 34.705318000000034],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "68",
      properties: { name: "Charsadda", count: 499 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.757904000000053, 34.444188000000054],
            [71.762339000000054, 34.42617000000007],
            [71.771798024000077, 34.41620402500007],
            [71.777863000000082, 34.40981400000004],
            [71.782647238000038, 34.407484900000043],
            [71.782619392000072, 34.407477310000047],
            [71.785768869000037, 34.405945068000051],
            [71.786815300000058, 34.405435973000067],
            [71.78772348800004, 34.404994133000059],
            [71.788900899000055, 34.404421315000036],
            [71.789709803000051, 34.404027778000057],
            [71.790374041000064, 34.403704622000078],
            [71.792347194000058, 34.402747215000034],
            [71.79414266200007, 34.401873708000039],
            [71.794929912000043, 34.401490706000061],
            [71.795610995000061, 34.401156809000042],
            [71.796915929000079, 34.400521950000041],
            [71.797586241000033, 34.400195839000048],
            [71.798012653000058, 34.399988387000064],
            [71.798331563000033, 34.399833235000074],
            [71.798552344000029, 34.399725824000029],
            [71.798900414000059, 34.399556485000062],
            [71.799130877000039, 34.399292198000069],
            [71.799332977000063, 34.39906043700006],
            [71.799539455000058, 34.398823655000058],
            [71.799772537000081, 34.398556365000047],
            [71.799897324000028, 34.398413264000055],
            [71.80008231100004, 34.39820112700005],
            [71.800261480000074, 34.397995662000028],
            [71.800443442000073, 34.397786994000057],
            [71.800543896000079, 34.397671797000044],
            [71.800640731000044, 34.397560751000071],
            [71.800783183000078, 34.397397391000027],
            [71.800889860000041, 34.397275057000058],
            [71.801090782000074, 34.397044648000076],
            [71.801247764000038, 34.396864626000024],
            [71.801379781000037, 34.396713233000071],
            [71.801547862000064, 34.396520484000064],
            [71.801714997000033, 34.396328819000075],
            [71.801849488000073, 34.396174589000054],
            [71.801966256000071, 34.39604068400007],
            [71.802066739000054, 34.395925453000075],
            [71.80218873900003, 34.395785549000038],
            [71.80224621900004, 34.395719633000056],
            [71.802305127000068, 34.395652079000058],
            [71.802351538000039, 34.395598856000049],
            [71.80372720400004, 34.394021290000069],
            [71.805657794000069, 34.391807357000062],
            [71.808323414000029, 34.388750517000062],
            [71.808826103000058, 34.387867476000054],
            [71.810500035000075, 34.384926989000064],
            [71.81172282700004, 34.382778991000066],
            [71.812744446000067, 34.380984380000029],
            [71.813769598000079, 34.379183562000037],
            [71.814960588000076, 34.377091428000028],
            [71.815774271000066, 34.375665385000048],
            [71.816083757000058, 34.375118432000079],
            [71.816700359000038, 34.374035287000027],
            [71.817473263000068, 34.372677577000047],
            [71.819336297000063, 34.37150061400007],
            [71.828169596000066, 34.365920216000063],
            [71.831053821000069, 34.364098120000051],
            [71.831054059000053, 34.364095209000027],
            [71.831055711000033, 34.364074964000054],
            [71.831057170000065, 34.364057091000063],
            [71.831097189000047, 34.363566762000062],
            [71.831116198000075, 34.363333859000079],
            [71.831124792000082, 34.363228565000043],
            [71.831130046000055, 34.363164187000052],
            [71.831137062000039, 34.363078232000078],
            [71.831145649000064, 34.362973021000073],
            [71.831152176000046, 34.362893044000032],
            [71.831158367000057, 34.362817195000048],
            [71.831164785000055, 34.362738554000032],
            [71.83117006100008, 34.362673922000056],
            [71.831175198000039, 34.362610980000056],
            [71.831181423000032, 34.362534709000045],
            [71.831193204000044, 34.362390361000053],
            [71.831208040000035, 34.362208592000059],
            [71.831228328000066, 34.361960014000033],
            [71.831238807000034, 34.361831621000078],
            [71.831249626000044, 34.361699064000049],
            [71.831253593000042, 34.361650455000074],
            [71.831260445000055, 34.361566508000067],
            [71.831267020000041, 34.361485946000073],
            [71.831278290000057, 34.361347866000074],
            [71.831289995000077, 34.361204454000074],
            [71.831311735000043, 34.360938092000026],
            [71.831607187000031, 34.35731813700005],
            [71.831890970000075, 34.353841148000072],
            [71.833303012000044, 34.350316261000046],
            [71.833713940000052, 34.349290460000077],
            [71.834811904000048, 34.346549606000053],
            [71.835975715000075, 34.343673258000024],
            [71.836337229000037, 34.342769430000033],
            [71.836339832000078, 34.34276292100003],
            [71.836353000000031, 34.342730000000074],
            [71.836352174000069, 34.342720626000073],
            [71.836351160000049, 34.342709126000045],
            [71.835930785000073, 34.338193068000066],
            [71.835757593000039, 34.336217081000029],
            [71.835642943000039, 34.33490901600004],
            [71.835504718000038, 34.333331985000029],
            [71.835040184000036, 34.332857163000028],
            [71.834165229000064, 34.331962832000045],
            [71.832567217000076, 34.330329430000063],
            [71.830107388000044, 34.327815126000075],
            [71.828295442000069, 34.325963052000077],
            [71.827365678000035, 34.325018039000042],
            [71.826666076000038, 34.324297602000058],
            [71.824252177000062, 34.321830246000047],
            [71.821950081000068, 34.319456819000038],
            [71.821949782000047, 34.319456511000055],
            [71.821938500000044, 34.31944500000003],
            [71.821935246000066, 34.319434091000062],
            [71.82193430500007, 34.319430949000036],
            [71.82112544000006, 34.316742281000074],
            [71.820740279000063, 34.315460979000079],
            [71.820057309000049, 34.313158474000033],
            [71.81897499400003, 34.309534281000026],
            [71.81794327700004, 34.306070436000027],
            [71.817223802000058, 34.303654898000048],
            [71.817302802000029, 34.30091832200003],
            [71.817406423000079, 34.297328931000038],
            [71.817421077000063, 34.296821321000039],
            [71.817446532000076, 34.295939531000045],
            [71.817505830000073, 34.293885468000042],
            [71.817633176000072, 34.289474233000078],
            [71.817698194000059, 34.287221991000024],
            [71.817735970000058, 34.285779827000056],
            [71.817736402000037, 34.285764672000028],
            [71.817737652000062, 34.285720843000036],
            [71.817720486000042, 34.285215826000069],
            [71.817683857000077, 34.284138217000077],
            [71.820645755000044, 34.281736635000073],
            [71.823582876000046, 34.279355151000061],
            [71.823582912000063, 34.279355122000027],
            [71.827943499000071, 34.275819438000042],
            [71.828399643000068, 34.272275492000063],
            [71.828878595000049, 34.268554336000079],
            [71.828975597000067, 34.268598823000048],
            [71.829423000000077, 34.265112000000045],
            [71.837185000000034, 34.257905000000051],
            [71.849382000000048, 34.252638000000047],
            [71.872945000000072, 34.252361000000064],
            [71.881538000000035, 34.247371000000044],
            [71.880706000000032, 34.240441000000033],
            [71.885586567000075, 34.239167678000058],
            [71.893458039000052, 34.237114039000062],
            [71.893460361000052, 34.237109976000056],
            [71.893482106000079, 34.237115839000069],
            [71.893460322000067, 34.237109937000071],
            [71.901156609000054, 34.223643043000038],
            [71.901156000000071, 34.223643000000038],
            [71.90122000000008, 34.223531000000037],
            [71.901232793000077, 34.223475308000047],
            [71.901157211000054, 34.223454427000036],
            [71.902528046000043, 34.217506705000062],
            [71.90339986500004, 34.213724095000032],
            [71.903926371000068, 34.211439716000029],
            [71.904947554000046, 34.207009052000046],
            [71.905711693000057, 34.203693639000051],
            [71.905882685000051, 34.202951747000043],
            [71.906770519000077, 34.201217443000075],
            [71.90964686600006, 34.195538893000048],
            [71.911186582000084, 34.192507859000045],
            [71.913165326000069, 34.188610054000037],
            [71.913383013000043, 34.188181246000056],
            [71.91572145400005, 34.183576122000034],
            [71.91681507800007, 34.181250879000061],
            [71.917871262000062, 34.178655685000024],
            [71.918580459000054, 34.176981976000036],
            [71.919250756000054, 34.175361118000069],
            [71.920041467000033, 34.173449082000047],
            [71.921113167000044, 34.170857583000043],
            [71.923150124000074, 34.165931976000024],
            [71.924078387000066, 34.163715453000066],
            [71.924897040000076, 34.161707724000053],
            [71.925905689000047, 34.159268689000044],
            [71.92703487600005, 34.15653818100003],
            [71.927221402000043, 34.156087138000032],
            [71.927542173000063, 34.15531147400003],
            [71.928168733000064, 34.154391842000052],
            [71.928723705000039, 34.15357728400005],
            [71.92937607600004, 34.152619769000069],
            [71.930652116000033, 34.15075028800004],
            [71.930652141000053, 34.15075025200008],
            [71.931247996000081, 34.149872264000066],
            [71.932186491000039, 34.148494790000029],
            [71.933893495000063, 34.145989340000028],
            [71.935287041000038, 34.143943968000031],
            [71.937534000000028, 34.140646000000061],
            [71.929055445000074, 34.137806857000044],
            [71.92852266400007, 34.137628449000033],
            [71.927120933000083, 34.13715906300007],
            [71.927114368000048, 34.137172557000042],
            [71.922565000000077, 34.13565600000004],
            [71.921520814000075, 34.135438468000075],
            [71.909986242000059, 34.133035504000077],
            [71.902606000000048, 34.131498000000079],
            [71.895485652000048, 34.12497125200008],
            [71.892626000000064, 34.12235000000004],
            [71.888518397000041, 34.114893030000076],
            [71.888542941000082, 34.114879529000063],
            [71.888541075000035, 34.114876142000071],
            [71.888362864000044, 34.114552661000062],
            [71.886099953000041, 34.110445123000034],
            [71.883284014000083, 34.105333751000046],
            [71.879211656000052, 34.097941784000056],
            [71.877850246000037, 34.095470612000042],
            [71.877836007000042, 34.095444766000071],
            [71.876748386000031, 34.09347056200005],
            [71.874958785000047, 34.090222156000038],
            [71.873973000000035, 34.088569952000057],
            [71.870336799000029, 34.082743618000052],
            [71.867743584000038, 34.078588474000071],
            [71.866080888000056, 34.075924314000076],
            [71.863259866000078, 34.07140415300006],
            [71.860450409000066, 34.066902522000078],
            [71.856056763000083, 34.059862523000049],
            [71.852519744000062, 34.05419511000008],
            [71.850923366000075, 34.05163721200006],
            [71.850706583000033, 34.051289857000029],
            [71.849464053000077, 34.049298646000068],
            [71.849382000000048, 34.049167000000068],
            [71.831245273000036, 34.047840117000078],
            [71.826651000000084, 34.04750400000006],
            [71.825480440000035, 34.050192294000055],
            [71.822766626000032, 34.056424811000056],
            [71.822726901000067, 34.056516041000066],
            [71.822709622000048, 34.056555725000067],
            [71.820270836000077, 34.06215661300007],
            [71.819991424000079, 34.077094479000039],
            [71.819225104000054, 34.077505629000029],
            [71.814854650000029, 34.079824967000036],
            [71.813791922000064, 34.080428027000039],
            [71.811041223000075, 34.081896491000066],
            [71.808082675000037, 34.083483828000055],
            [71.805779729000051, 34.084719418000077],
            [71.803923239000028, 34.085715472000061],
            [71.801944168000034, 34.086777295000047],
            [71.798360317000061, 34.088700123000024],
            [71.797791071000063, 34.088852046000056],
            [71.797961169000075, 34.088912952000044],
            [71.78314434300006, 34.092661593000059],
            [71.783137213000032, 34.092650372000037],
            [71.783139204000065, 34.092662893000067],
            [71.775368000000071, 34.094629000000054],
            [71.770924429000047, 34.094884419000039],
            [71.770923890000063, 34.094884450000052],
            [71.753021948000082, 34.095913465000024],
            [71.751347344000067, 34.09167971200003],
            [71.750962156000071, 34.091843895000068],
            [71.75058000000007, 34.092045000000041],
            [71.750471000000061, 34.092085000000054],
            [71.750369000000035, 34.092125000000067],
            [71.750274000000047, 34.092164000000025],
            [71.75018300000005, 34.092204000000038],
            [71.750099914000032, 34.092244478000055],
            [71.750066000000061, 34.092261000000065],
            [71.749863490000052, 34.092344700000069],
            [71.749405955000043, 34.092508861000056],
            [71.748603590000073, 34.092714871000055],
            [71.748196921000044, 34.092834959000072],
            [71.747969804000036, 34.092997332000039],
            [71.747644000000037, 34.093144000000052],
            [71.747037000000034, 34.093307000000038],
            [71.746796000000074, 34.09337000000005],
            [71.746548000000075, 34.093431000000066],
            [71.746324000000072, 34.093481000000054],
            [71.746074000000078, 34.093532000000039],
            [71.745822000000032, 34.093580000000031],
            [71.745569000000046, 34.093624000000034],
            [71.745313000000067, 34.093666000000042],
            [71.745056000000034, 34.093705000000057],
            [71.74479800000006, 34.093740000000025],
            [71.744538000000034, 34.093773000000056],
            [71.744277000000068, 34.093802000000039],
            [71.744105000000047, 34.093819000000053],
            [71.743914000000075, 34.09383500000007],
            [71.743672146000051, 34.093853295000031],
            [71.743253000000038, 34.093885000000057],
            [71.743205000000046, 34.093890000000044],
            [71.743152000000066, 34.093898000000024],
            [71.743094000000042, 34.093910000000051],
            [71.743029000000035, 34.09392500000007],
            [71.742906000000062, 34.093958000000043],
            [71.74261800000005, 34.094041000000061],
            [71.742488000000037, 34.094074000000035],
            [71.742384000000072, 34.094094000000041],
            [71.742339000000072, 34.09410100000008],
            [71.742297000000065, 34.09410500000007],
            [71.742242000000033, 34.094107000000065],
            [71.742193000000043, 34.094104000000073],
            [71.742151000000035, 34.094096000000036],
            [71.742114000000072, 34.094082000000071],
            [71.742098000000055, 34.094073000000037],
            [71.742083000000036, 34.09406400000006],
            [71.742070000000069, 34.094052000000033],
            [71.742058000000043, 34.094040000000064],
            [71.742047000000071, 34.094026000000042],
            [71.742038000000036, 34.09401100000008],
            [71.742024000000072, 34.093976000000055],
            [71.742020000000082, 34.093952000000058],
            [71.742019000000084, 34.09392100000008],
            [71.742025298000044, 34.093853295000031],
            [71.742031000000054, 34.093792000000064],
            [71.742032000000052, 34.093747000000064],
            [71.742029000000059, 34.093727000000058],
            [71.742023000000074, 34.093712000000039],
            [71.742015000000038, 34.093702000000064],
            [71.742004000000065, 34.093696000000079],
            [71.741991000000041, 34.093694000000028],
            [71.741974000000084, 34.093694000000028],
            [71.741933000000074, 34.093700000000069],
            [71.741894000000059, 34.093713000000037],
            [71.74187900000004, 34.093720000000076],
            [71.741868000000068, 34.093728000000056],
            [71.741859000000034, 34.093739000000028],
            [71.741853000000049, 34.093753000000049],
            [71.741851000000054, 34.093770000000063],
            [71.741852000000051, 34.093791000000067],
            [71.741861000000029, 34.093834000000072],
            [71.74186667500004, 34.093853295000031],
            [71.741891000000066, 34.093936000000042],
            [71.741898000000049, 34.093976000000055],
            [71.741907000000083, 34.094097000000033],
            [71.741918000000055, 34.094326000000024],
            [71.74192400000004, 34.094418000000076],
            [71.741925000000037, 34.094466000000068],
            [71.741919000000053, 34.094579000000067],
            [71.74192000000005, 34.094622000000072],
            [71.741923000000043, 34.094646000000068],
            [71.741929000000084, 34.094665000000077],
            [71.741937000000064, 34.094681000000037],
            [71.741947000000039, 34.094692000000066],
            [71.741959000000065, 34.094699000000048],
            [71.741974000000084, 34.094704000000036],
            [71.741992000000039, 34.094706000000031],
            [71.74201400000004, 34.094706000000031],
            [71.742058000000043, 34.094701000000043],
            [71.742161000000067, 34.094681000000037],
            [71.742200000000082, 34.094677000000047],
            [71.742256000000054, 34.094676000000049],
            [71.742319000000066, 34.094680000000039],
            [71.742392000000052, 34.094688000000076],
            [71.742548000000056, 34.094711000000075],
            [71.742615000000058, 34.094719000000055],
            [71.742684000000054, 34.094725000000039],
            [71.742744000000073, 34.094725000000039],
            [71.742778000000044, 34.094723000000045],
            [71.742811000000074, 34.094719000000055],
            [71.742841000000055, 34.09471300000007],
            [71.742870000000039, 34.094704000000036],
            [71.742897000000085, 34.094694000000061],
            [71.742922000000078, 34.094682000000034],
            [71.742946000000074, 34.09466800000007],
            [71.742968000000076, 34.094651000000056],
            [71.743012000000078, 34.094608000000051],
            [71.743030000000033, 34.094593000000032],
            [71.743062000000066, 34.094576000000075],
            [71.743094000000042, 34.094565000000046],
            [71.743116000000043, 34.094561000000056],
            [71.743138000000044, 34.094560000000058],
            [71.743160000000046, 34.094561000000056],
            [71.74318100000005, 34.094565000000046],
            [71.743212000000028, 34.094575000000077],
            [71.743239000000074, 34.094590000000039],
            [71.743256000000031, 34.094603000000063],
            [71.743270000000052, 34.09461600000003],
            [71.743281000000081, 34.094628000000057],
            [71.743288000000064, 34.094641000000024],
            [71.743291000000056, 34.094652000000053],
            [71.743293000000051, 34.094666000000075],
            [71.743291000000056, 34.09470900000008],
            [71.743289000000061, 34.094739000000061],
            [71.743285000000071, 34.09475800000007],
            [71.74327900000003, 34.094773000000032],
            [71.743270000000052, 34.094788000000051],
            [71.74325900000008, 34.094801000000075],
            [71.743248000000051, 34.094812000000047],
            [71.743236000000081, 34.094820000000027],
            [71.743220000000065, 34.094826000000069],
            [71.743161000000043, 34.094837000000041],
            [71.743131000000062, 34.094842000000028],
            [71.743097000000034, 34.094846000000075],
            [71.742970000000071, 34.094853000000057],
            [71.742925000000071, 34.094855000000052],
            [71.742876000000081, 34.094855000000052],
            [71.742822000000046, 34.09485200000006],
            [71.742763000000082, 34.094847000000073],
            [71.74262600000003, 34.094830000000059],
            [71.74233600000008, 34.094783000000064],
            [71.742216000000042, 34.09476600000005],
            [71.74215300000003, 34.094760000000065],
            [71.74209600000006, 34.094756000000075],
            [71.742045000000076, 34.09475400000008],
            [71.741999000000078, 34.094755000000077],
            [71.741946000000041, 34.094759000000067],
            [71.741900000000044, 34.094768000000045],
            [71.741860000000031, 34.094781000000069],
            [71.741827000000058, 34.094798000000026],
            [71.741799000000071, 34.09481900000003],
            [71.741787000000045, 34.094831000000056],
            [71.74177700000007, 34.09484400000008],
            [71.741761000000054, 34.094874000000061],
            [71.741750000000081, 34.09490900000003],
            [71.74174800000003, 34.094934000000023],
            [71.741750000000081, 34.094965000000059],
            [71.741757000000064, 34.09499900000003],
            [71.74177700000007, 34.095092000000079],
            [71.741782000000057, 34.095140000000072],
            [71.74178100000006, 34.095162000000073],
            [71.74177700000007, 34.095180000000028],
            [71.741770000000031, 34.095194000000049],
            [71.741759000000059, 34.095204000000024],
            [71.741741000000047, 34.095213000000058],
            [71.741715000000056, 34.095219000000043],
            [71.741648952000048, 34.095225672000026],
            [71.741616000000079, 34.095229000000074],
            [71.741535000000056, 34.095239000000049],
            [71.741475000000037, 34.095244000000037],
            [71.741422000000057, 34.095245000000034],
            [71.74140101100005, 34.095243715000038],
            [71.741373000000067, 34.095242000000042],
            [71.74132000000003, 34.095235000000059],
            [71.741280000000074, 34.095227000000079],
            [71.741249000000039, 34.09521600000005],
            [71.741216000000065, 34.095198000000039],
            [71.74118500000003, 34.095177000000035],
            [71.741108000000054, 34.095119000000068],
            [71.741085000000055, 34.095104000000049],
            [71.741064000000051, 34.095092000000079],
            [71.741003000000035, 34.095064000000036],
            [71.740935000000036, 34.095039000000043],
            [71.740857000000062, 34.095014000000049],
            [71.740759000000082, 34.094986000000063],
            [71.740688000000034, 34.094969000000049],
            [71.740613000000053, 34.094956000000025],
            [71.740534000000082, 34.094945000000052],
            [71.740448000000072, 34.09493800000007],
            [71.74029900000005, 34.094932000000028],
            [71.739987000000042, 34.094931000000031],
            [71.739853000000039, 34.094927000000041],
            [71.739705000000072, 34.094921000000056],
            [71.739370000000065, 34.094912000000079],
            [71.739219000000048, 34.094906000000037],
            [71.738871000000074, 34.094888000000026],
            [71.738642000000084, 34.094872000000066],
            [71.738434000000041, 34.09485200000006],
            [71.738246000000061, 34.094828000000064],
            [71.738061000000073, 34.094798000000026],
            [71.73787800000008, 34.094764000000055],
            [71.737697000000082, 34.094725000000039],
            [71.737519000000077, 34.094682000000034],
            [71.737296000000072, 34.094622000000072],
            [71.73709800000006, 34.094562000000053],
            [71.737005000000067, 34.094532000000072],
            [71.736916000000065, 34.094501000000037],
            [71.736830000000054, 34.094469000000061],
            [71.736747000000037, 34.09443600000003],
            [71.736546000000033, 34.094350000000077],
            [71.73645700000003, 34.094310000000064],
            [71.736375000000066, 34.094270000000051],
            [71.736296000000038, 34.094230000000039],
            [71.736221000000057, 34.094189000000028],
            [71.736150000000066, 34.094148000000075],
            [71.736083000000065, 34.094106000000068],
            [71.735993000000065, 34.094045000000051],
            [71.735898000000077, 34.093977000000052],
            [71.735798000000045, 34.093898000000024],
            [71.735682000000054, 34.093804000000034],
            [71.735570000000052, 34.093712000000039],
            [71.735511000000031, 34.093661000000054],
            [71.735457000000054, 34.093610000000069],
            [71.735411000000056, 34.093562000000077],
            [71.735372000000041, 34.093516000000079],
            [71.73533400000008, 34.093466000000035],
            [71.73529700000006, 34.093412000000058],
            [71.735260000000039, 34.093353000000036],
            [71.735224000000073, 34.093290000000025],
            [71.735187000000053, 34.093220000000031],
            [71.735100000000045, 34.09304300000008],
            [71.735020000000077, 34.092868000000067],
            [71.734991000000036, 34.09279900000007],
            [71.73496700000004, 34.092735000000062],
            [71.734945000000039, 34.092673000000048],
            [71.734928000000082, 34.092614000000026],
            [71.73491400000006, 34.092557000000056],
            [71.734904000000029, 34.092502000000025],
            [71.734897000000046, 34.092449000000045],
            [71.734893000000056, 34.092393000000072],
            [71.734892000000059, 34.092334000000051],
            [71.734894000000054, 34.092270000000042],
            [71.734897000000046, 34.092209000000025],
            [71.734903000000031, 34.092140000000029],
            [71.734930000000077, 34.091900000000066],
            [71.734945000000039, 34.091755000000035],
            [71.734952000000078, 34.091709000000037],
            [71.734960000000058, 34.091667000000029],
            [71.734976000000074, 34.091600000000028],
            [71.734994000000029, 34.091537000000073],
            [71.735015000000033, 34.091480000000047],
            [71.735037000000034, 34.09142600000007],
            [71.73504900000006, 34.091404000000068],
            [71.735065000000077, 34.091380000000072],
            [71.735129000000029, 34.091298000000052],
            [71.73515100000003, 34.091266000000076],
            [71.735160000000064, 34.091249000000062],
            [71.735167000000047, 34.091233000000045],
            [71.735172000000034, 34.091218000000026],
            [71.735174000000029, 34.091203000000064],
            [71.735173000000032, 34.091183000000058],
            [71.735169000000042, 34.091162000000054],
            [71.735160000000064, 34.09114100000005],
            [71.735148000000038, 34.091117000000054],
            [71.735117000000059, 34.091075000000046],
            [71.735031000000049, 34.090971000000025],
            [71.73501200000004, 34.090943000000038],
            [71.734997000000078, 34.090917000000047],
            [71.734979000000067, 34.090876000000037],
            [71.734964000000048, 34.090832000000034],
            [71.734950000000083, 34.090784000000042],
            [71.734939000000054, 34.09073200000006],
            [71.734929000000079, 34.090674000000035],
            [71.734921000000043, 34.090609000000029],
            [71.734900000000039, 34.09035700000004],
            [71.734887000000072, 34.09025500000007],
            [71.73487700000004, 34.090202000000033],
            [71.734866000000068, 34.090155000000038],
            [71.734852000000046, 34.090112000000033],
            [71.73483600000003, 34.090074000000072],
            [71.734817000000078, 34.090037000000052],
            [71.734795000000076, 34.090002000000027],
            [71.73477100000008, 34.089970000000051],
            [71.734744000000035, 34.08994000000007],
            [71.734716000000049, 34.089913000000024],
            [71.734684000000072, 34.08988800000003],
            [71.734651000000042, 34.089865000000032],
            [71.734615000000076, 34.089844000000028],
            [71.734577000000058, 34.089826000000073],
            [71.734536000000048, 34.089810000000057],
            [71.734492000000046, 34.089796000000035],
            [71.734446000000048, 34.089784000000066],
            [71.734398000000056, 34.089775000000031],
            [71.734346000000073, 34.089767000000052],
            [71.734292000000039, 34.089762000000064],
            [71.734235000000069, 34.089759000000072],
            [71.73413800000003, 34.089759000000072],
            [71.73403200000007, 34.089763000000062],
            [71.733916000000079, 34.089774000000034],
            [71.733787000000063, 34.089789000000053],
            [71.733671000000072, 34.089807000000064],
            [71.733538000000067, 34.089829000000066],
            [71.733056000000033, 34.089917000000071],
            [71.732861000000071, 34.089951000000042],
            [71.732665000000054, 34.089980000000025],
            [71.732492000000036, 34.089999000000034],
            [71.732398000000046, 34.090006000000074],
            [71.732309000000043, 34.090010000000063],
            [71.732224000000031, 34.090012000000058],
            [71.732144000000062, 34.090010000000063],
            [71.732068000000083, 34.090005000000076],
            [71.73199500000004, 34.089997000000039],
            [71.731925000000047, 34.089986000000067],
            [71.731859000000043, 34.089972000000046],
            [71.731783000000064, 34.089951000000042],
            [71.731575000000078, 34.08988800000003],
            [71.731511000000069, 34.089872000000071],
            [71.731453000000045, 34.089862000000039],
            [71.731421000000068, 34.089859000000047],
            [71.731386000000043, 34.089858000000049],
            [71.73134900000008, 34.089860000000044],
            [71.731309000000067, 34.089864000000034],
            [71.731232000000034, 34.089876000000061],
            [71.731048000000044, 34.089913000000024],
            [71.730999000000054, 34.089920000000063],
            [71.730956000000049, 34.089924000000053],
            [71.730911000000049, 34.089925000000051],
            [71.730870000000039, 34.089921000000061],
            [71.730833000000075, 34.089913000000024],
            [71.730799000000047, 34.089901000000054],
            [71.730770000000064, 34.089885000000038],
            [71.73074100000008, 34.089865000000032],
            [71.730713000000037, 34.08983900000004],
            [71.730685000000051, 34.089808000000062],
            [71.730660000000057, 34.089777000000026],
            [71.730634000000066, 34.089739000000066],
            [71.730539000000078, 34.089590000000044],
            [71.730497000000071, 34.089530000000025],
            [71.730474000000072, 34.089499000000046],
            [71.730450000000076, 34.089473000000055],
            [71.730427000000077, 34.089450000000056],
            [71.730404000000078, 34.08943000000005],
            [71.730345000000057, 34.089387000000045],
            [71.73028000000005, 34.089347000000032],
            [71.730208000000061, 34.089309000000071],
            [71.730129000000034, 34.089273000000048],
            [71.730045000000075, 34.089239000000077],
            [71.729950000000031, 34.089205000000049],
            [71.729846000000066, 34.089172000000076],
            [71.729691000000059, 34.089125000000024],
            [71.729602000000057, 34.089101000000028],
            [71.729510000000062, 34.089081000000078],
            [71.729415000000074, 34.089065000000062],
            [71.729317000000037, 34.089052000000038],
            [71.729216000000065, 34.089044000000058],
            [71.729111000000046, 34.089040000000068],
            [71.729001000000039, 34.089039000000071],
            [71.728886000000045, 34.089043000000061],
            [71.728789000000063, 34.089048000000048],
            [71.728686000000039, 34.089057000000025],
            [71.728578000000084, 34.089068000000054],
            [71.728461000000038, 34.089081000000078],
            [71.727404763000038, 34.08867243900005],
            [71.726630754000041, 34.088148760000024],
            [71.725232341000037, 34.087939977000076],
            [71.723192515000051, 34.087736631000041],
            [71.72172944600004, 34.087660559000028],
            [71.72016029100007, 34.087836361000029],
            [71.71958838300003, 34.087820050000062],
            [71.718988555000067, 34.087804577000043],
            [71.71837122900007, 34.087788652000029],
            [71.717712360000064, 34.087521414000037],
            [71.716779110000061, 34.087247047000062],
            [71.716673753000066, 34.087216073000036],
            [71.71578571200007, 34.087067533000038],
            [71.715287518000082, 34.086907954000026],
            [71.714955694000082, 34.08648026000003],
            [71.714602321000029, 34.08534615700006],
            [71.714253231000043, 34.084256406000065],
            [71.714095580000048, 34.083541557000046],
            [71.71361091700004, 34.082112711000036],
            [71.713239064000049, 34.08103101100005],
            [71.713018127000055, 34.080286123000064],
            [71.71271517200006, 34.079500929000062],
            [71.712434288000054, 34.078877282000064],
            [71.711997478000058, 34.078131949000067],
            [71.711989882000069, 34.078119383000057],
            [71.711739300000033, 34.077535539000053],
            [71.711527731000047, 34.077057340000067],
            [71.711310034000064, 34.07670078700005],
            [71.711181599000042, 34.076524382000059],
            [71.710744042000044, 34.076665879000075],
            [71.710716000000048, 34.076733000000047],
            [71.710676270000079, 34.076741967000032],
            [71.710522548000029, 34.076946568000039],
            [71.710445178000043, 34.077342113000043],
            [71.710560684000029, 34.077564898000048],
            [71.710616105000042, 34.077805410000053],
            [71.710725787000058, 34.078059727000038],
            [71.711229149000076, 34.078679935000025],
            [71.711623958000075, 34.079565652000042],
            [71.711780967000038, 34.080158706000077],
            [71.711974812000051, 34.081079415000033],
            [71.712261799000032, 34.082033784000032],
            [71.712577042000078, 34.082712841000046],
            [71.71273898000004, 34.083061666000049],
            [71.713037141000029, 34.083897440000044],
            [71.713257698000064, 34.084537411000042],
            [71.713444444000061, 34.085282437000046],
            [71.713586403000079, 34.086398426000073],
            [71.71356667200007, 34.08664946600004],
            [71.713494531000038, 34.086749620000035],
            [71.713262260000079, 34.086778041000059],
            [71.712557704000062, 34.086713064000037],
            [71.711911240000063, 34.086604503000046],
            [71.711453635000055, 34.086490771000058],
            [71.711199523000062, 34.086452466000026],
            [71.710693891000062, 34.086529134000045],
            [71.710315741000045, 34.086614286000042],
            [71.709708901000056, 34.086902850000058],
            [71.709395746000041, 34.087002700000028],
            [71.709108682000078, 34.086999564000052],
            [71.708762524000065, 34.086936749000074],
            [71.708089392000034, 34.087104688000068],
            [71.707653566000033, 34.08729300400006],
            [71.707127735000029, 34.087636630000077],
            [71.706810478000079, 34.087988649000067],
            [71.70650260900004, 34.088401365000038],
            [71.70620213400008, 34.088795878000042],
            [71.705877894000082, 34.089551701000062],
            [71.705653122000058, 34.089721408000059],
            [71.705507745000034, 34.089784816000076],
            [71.704892089000055, 34.089767744000028],
            [71.704251370000065, 34.089763333000064],
            [71.702906383000084, 34.089984989000072],
            [71.702172158000053, 34.090301728000043],
            [71.700754184000061, 34.090712986000028],
            [71.699801146000084, 34.091288457000076],
            [71.699276571000041, 34.091660266000076],
            [71.699375183000029, 34.092111755000076],
            [71.699706641000034, 34.092204694000031],
            [71.700151318000053, 34.092131202000076],
            [71.70063245800003, 34.091960574000041],
            [71.701972554000065, 34.09198869800008],
            [71.703292626000064, 34.09210472500007],
            [71.70387558200008, 34.093289599000059],
            [71.703408006000075, 34.093983674000071],
            [71.703019217000076, 34.094645958000058],
            [71.702621727000064, 34.096301541000059],
            [71.702463148000049, 34.096544109000035],
            [71.702404594000029, 34.096645093000063],
            [71.702391336000062, 34.096802227000069],
            [71.702391571000078, 34.096947764000049],
            [71.702354626000044, 34.097066512000026],
            [71.702246161000062, 34.097273088000065],
            [71.702052840000079, 34.097544413000037],
            [71.701914161000047, 34.09775017700008],
            [71.701667024000074, 34.097961244000032],
            [71.701456992000033, 34.098135398000068],
            [71.701319219000084, 34.098275116000025],
            [71.701265890000059, 34.098443948000067],
            [71.701248305000036, 34.09898696700003],
            [71.70121570200007, 34.09973506800003],
            [71.701205498000036, 34.09996921100003],
            [71.701204795000081, 34.099976845000072],
            [71.701194251000061, 34.100091406000047],
            [71.70118302000003, 34.100213429000064],
            [71.701162775000057, 34.100433382000062],
            [71.701120045000039, 34.100858887000072],
            [71.701142947000051, 34.10107690500007],
            [71.701378400000067, 34.101816601000053],
            [71.701647798000067, 34.102413372000058],
            [71.701741704000028, 34.102721340000073],
            [71.701786717000061, 34.102889634000064],
            [71.70191059900003, 34.103263825000056],
            [71.702004929000054, 34.103548753000041],
            [71.702083075000075, 34.103970118000063],
            [71.702095326000062, 34.104265546000079],
            [71.702094102000046, 34.104548459000057],
            [71.70218251600005, 34.10475143900004],
            [71.702373112000032, 34.105424802000073],
            [71.702545637000071, 34.106012786000065],
            [71.702603278000083, 34.106372369000042],
            [71.702343881000047, 34.106773402000044],
            [71.702128780000066, 34.107241167000041],
            [71.702046077000034, 34.107505680000031],
            [71.701938718000065, 34.107809932000066],
            [71.701813184000059, 34.108037211000067],
            [71.701607008000053, 34.108240023000064],
            [71.701577401000065, 34.108520303000034],
            [71.70155928500003, 34.108822745000055],
            [71.701542840000059, 34.108956005000039],
            [71.701528907000068, 34.109156687000052],
            [71.701514590000045, 34.109298307000074],
            [71.701470239000059, 34.109485629000062],
            [71.701423499000043, 34.109834371000034],
            [71.701446740000051, 34.110057021000046],
            [71.701521281000055, 34.110323875000063],
            [71.701618210000049, 34.110484431000032],
            [71.701838021000071, 34.110714105000056],
            [71.702025546000073, 34.11090082700008],
            [71.702151000000072, 34.111011000000076],
            [71.702183000000048, 34.111030000000028],
            [71.702212000000031, 34.111051000000032],
            [71.702253000000042, 34.111088000000052],
            [71.702292000000057, 34.111131000000057],
            [71.702331000000072, 34.111181000000045],
            [71.702368000000035, 34.111237000000074],
            [71.702403000000061, 34.111298000000033],
            [71.702437000000032, 34.111366000000032],
            [71.702464009000039, 34.111485807000065],
            [71.702428560000044, 34.111727877000078],
            [71.702417947000072, 34.111907874000053],
            [71.702410253000039, 34.112071729000036],
            [71.702384792000032, 34.112265299000057],
            [71.702347427000063, 34.112435671000071],
            [71.702318000000048, 34.112453000000073],
            [71.702302000000032, 34.112448000000029],
            [71.702278000000035, 34.112445000000037],
            [71.702252000000044, 34.112446000000034],
            [71.702224000000058, 34.112451000000078],
            [71.702195000000074, 34.112461000000053],
            [71.702142000000038, 34.112486000000047],
            [71.702038000000073, 34.112548000000061],
            [71.701995000000068, 34.112570000000062],
            [71.701878000000079, 34.112626000000034],
            [71.701788000000079, 34.112674000000027],
            [71.701710000000048, 34.112721000000079],
            [71.701643000000047, 34.112768000000074],
            [71.701602000000037, 34.11280000000005],
            [71.701562000000081, 34.112835000000075],
            [71.701522000000068, 34.112872000000038],
            [71.70148400000005, 34.112912000000051],
            [71.701446000000033, 34.112953000000061],
            [71.701410000000067, 34.112996000000066],
            [71.701374000000044, 34.113042000000064],
            [71.701340000000073, 34.113088000000062],
            [71.701307000000043, 34.113137000000052],
            [71.701276000000064, 34.113187000000039],
            [71.701246000000083, 34.113238000000024],
            [71.70121800000004, 34.113290000000063],
            [71.701192000000049, 34.113343000000043],
            [71.701168000000052, 34.11339600000008],
            [71.701146000000051, 34.113450000000057],
            [71.701126000000045, 34.113504000000034],
            [71.701106000000038, 34.113566000000048],
            [71.701088000000084, 34.113636000000042],
            [71.701072000000067, 34.113713000000075],
            [71.701047000000074, 34.113841000000036],
            [71.701032000000055, 34.113928000000044],
            [71.70102200000008, 34.114019000000042],
            [71.701015000000041, 34.114114000000029],
            [71.701011000000051, 34.114216000000056],
            [71.701012000000048, 34.114318000000026],
            [71.701015000000041, 34.114431000000025],
            [71.70103800000004, 34.114867000000061],
            [71.701044000000081, 34.115052000000048],
            [71.701043000000084, 34.11515200000008],
            [71.701040000000035, 34.115245000000073],
            [71.70103400000005, 34.115333000000078],
            [71.701024000000075, 34.115417000000036],
            [71.701005000000066, 34.115547000000049],
            [71.700986000000057, 34.11566700000003],
            [71.700965000000053, 34.115778000000034],
            [71.700943000000052, 34.115884000000051],
            [71.700928000000033, 34.115957000000037],
            [71.700888000000077, 34.116171000000065],
            [71.700873000000058, 34.116237000000069],
            [71.700857000000042, 34.116295000000036],
            [71.700837000000035, 34.116358000000048],
            [71.700814000000037, 34.116415000000075],
            [71.700788000000045, 34.116466000000059],
            [71.700759000000062, 34.116513000000054],
            [71.700712000000067, 34.116575000000068],
            [71.700635000000034, 34.116662000000076],
            [71.700465738000048, 34.116865464000057],
            [71.700323924000031, 34.11705340900005],
            [71.700086937000037, 34.117200225000033],
            [71.69977173400008, 34.117599812000037],
            [71.699481678000041, 34.117980825000075],
            [71.698860923000041, 34.118595182000035],
            [71.698607910000078, 34.11898436000007],
            [71.698422865000055, 34.119459302000053],
            [71.698254185000053, 34.119846534000033],
            [71.698150498000075, 34.120213020000051],
            [71.698151494000058, 34.12056805900005],
            [71.698029105000046, 34.121006674000057],
            [71.697837291000042, 34.121477191000054],
            [71.697788202000083, 34.121693712000024],
            [71.697872853000035, 34.121988438000074],
            [71.697912788000053, 34.122377733000064],
            [71.698101253000061, 34.122599498000056],
            [71.698209186000042, 34.122838524000031],
            [71.698058872000047, 34.123027622000052],
            [71.697714981000047, 34.123235648000048],
            [71.697375166000029, 34.123449046000076],
            [71.696696298000063, 34.123930038000026],
            [71.696516593000069, 34.124140306000072],
            [71.69626468000007, 34.124327492000077],
            [71.695888072000059, 34.124852797000074],
            [71.695739600000081, 34.125022115000036],
            [71.695404692000068, 34.12517364100006],
            [71.695182095000064, 34.125318544000038],
            [71.694978018000029, 34.125540583000031],
            [71.694839664000028, 34.125701553000056],
            [71.694780596000044, 34.125834256000076],
            [71.694466000000034, 34.125991000000056],
            [71.694291000000078, 34.126017000000047],
            [71.69410958900005, 34.126092533000076],
            [71.693923075000043, 34.126170306000063],
            [71.693672516000049, 34.12625752200006],
            [71.693547000000081, 34.12633900000003],
            [71.693524000000082, 34.126365000000078],
            [71.693494000000044, 34.126392000000067],
            [71.693460000000073, 34.126416000000063],
            [71.693422000000055, 34.126438000000064],
            [71.693378000000052, 34.126459000000068],
            [71.693339000000037, 34.12647400000003],
            [71.693294000000037, 34.126489000000049],
            [71.693108611000071, 34.126545178000072],
            [71.693096000000082, 34.126549000000068],
            [71.693051000000082, 34.126565000000028],
            [71.693012000000067, 34.126582000000042],
            [71.692787000000067, 34.126686000000063],
            [71.692641000000037, 34.126756000000057],
            [71.692510000000084, 34.126825000000053],
            [71.692394000000036, 34.126893000000052],
            [71.692304000000036, 34.126953000000071],
            [71.692109000000073, 34.127095000000054],
            [71.69202400000006, 34.127151000000026],
            [71.691976000000068, 34.127178000000072],
            [71.691925000000083, 34.127204000000063],
            [71.691870000000051, 34.127230000000054],
            [71.691809000000035, 34.127255000000048],
            [71.69144704100006, 34.127416587000027],
            [71.691155251000055, 34.127565829000048],
            [71.69083100000006, 34.127724000000057],
            [71.690779000000077, 34.127755000000036],
            [71.690750000000037, 34.12776800000006],
            [71.690722000000051, 34.127778000000035],
            [71.690695000000062, 34.127783000000079],
            [71.690383775000043, 34.127928775000044],
            [71.690076588000068, 34.128088998000067],
            [71.68953003300004, 34.128389825000056],
            [71.689016898000034, 34.128693375000068],
            [71.688768935000041, 34.128862803000061],
            [71.688481662000072, 34.129079665000063],
            [71.688324651000073, 34.129144997000026],
            [71.688240994000068, 34.129167719000066],
            [71.688110158000029, 34.129203256000039],
            [71.68806161100008, 34.129208175000031],
            [71.687938838000036, 34.129220615000065],
            [71.687846437000076, 34.129212797000037],
            [71.687707000000046, 34.12920100000008],
            [71.687669000000028, 34.12920500000007],
            [71.687592000000052, 34.12920500000007],
            [71.687454000000059, 34.129212000000052],
            [71.687192000000039, 34.129228000000069],
            [71.687081000000035, 34.129232000000059],
            [71.68702200000007, 34.129231000000061],
            [71.686967000000038, 34.129227000000071],
            [71.686916000000053, 34.12922100000003],
            [71.686867000000063, 34.129212000000052],
            [71.686788000000035, 34.129196000000036],
            [71.686764000000039, 34.129188000000056],
            [71.686743000000035, 34.129180000000076],
            [71.686654815000054, 34.129152700000077],
            [71.68657825300005, 34.129105689000028],
            [71.686496432000069, 34.129000906000044],
            [71.686482660000081, 34.128918079000073],
            [71.686512022000045, 34.128812414000038],
            [71.686568218000048, 34.128693877000046],
            [71.686511169000084, 34.128590357000064],
            [71.686374924000063, 34.128511702000026],
            [71.685934283000051, 34.128496249000079],
            [71.685636128000056, 34.128482783000038],
            [71.685233452000034, 34.128398269000058],
            [71.684463374000075, 34.128283245000034],
            [71.683998161000034, 34.128297051000061],
            [71.683681809000063, 34.128185343000041],
            [71.683440571000062, 34.127996354000061],
            [71.683288147000042, 34.127712143000053],
            [71.683100469000067, 34.127468285000077],
            [71.682816028000047, 34.127503800000056],
            [71.68230439000007, 34.127606821000029],
            [71.682239328000037, 34.12775335300006],
            [71.682186006000052, 34.127886143000069],
            [71.681916478000062, 34.128127246000076],
            [71.681478706000064, 34.128477733000068],
            [71.681136448000075, 34.128842022000072],
            [71.68073471200006, 34.129198857000063],
            [71.680377217000057, 34.129522503000032],
            [71.679889005000064, 34.130077198000038],
            [71.679597874000081, 34.130262626000047],
            [71.679458228000044, 34.130451288000074],
            [71.679439179000042, 34.130651427000032],
            [71.679199467000046, 34.131114290000028],
            [71.679033696000033, 34.131251242000076],
            [71.678661459000068, 34.131322770000054],
            [71.678167332000044, 34.131395206000036],
            [71.677906663000044, 34.131465087000038],
            [71.677710189000038, 34.131531233000032],
            [71.677371618000052, 34.131470767000053],
            [71.676581546000079, 34.131329272000073],
            [71.675993101000074, 34.131135923000045],
            [71.675449696000044, 34.13099705500008],
            [71.67509467900004, 34.130909131000067],
            [71.674591396000039, 34.13064620800003],
            [71.674254253000072, 34.130515973000058],
            [71.673919332000082, 34.130337429000065],
            [71.673580989000072, 34.130267227000047],
            [71.673364032000052, 34.130304195000065],
            [71.673101041000052, 34.130425242000058],
            [71.672827838000046, 34.130475110000077],
            [71.672630512000069, 34.130602244000045],
            [71.672174985000083, 34.130922475000034],
            [71.671828579000078, 34.131089818000078],
            [71.67138531300003, 34.131338835000065],
            [71.670761998000046, 34.131559453000079],
            [71.670336354000028, 34.131714310000064],
            [71.669931854000083, 34.131780080000055],
            [71.669541089000063, 34.131704762000027],
            [71.669307764000052, 34.131665346000034],
            [71.669182034000073, 34.131644107000056],
            [71.669108182000059, 34.131644630000039],
            [71.668915000000084, 34.131646000000046],
            [71.668701000000056, 34.131666000000052],
            [71.668497000000059, 34.131682000000069],
            [71.66839600000003, 34.131690000000049],
            [71.668289000000073, 34.131702000000075],
            [71.668173000000081, 34.131718000000035],
            [71.668046000000061, 34.131738000000041],
            [71.667838337000035, 34.131788090000043],
            [71.667647386000056, 34.131810629000029],
            [71.66748240700008, 34.131787317000033],
            [71.667306176000068, 34.131696625000075],
            [71.66718435100006, 34.131624274000046],
            [71.66700038700003, 34.131594001000053],
            [71.666823841000053, 34.131592598000054],
            [71.66654329000005, 34.131580590000056],
            [71.66631822800008, 34.131584432000068],
            [71.666071614000032, 34.131555419000051],
            [71.665747911000039, 34.13152539400005],
            [71.665624957000034, 34.131585648000055],
            [71.665462183000045, 34.131581733000075],
            [71.665280060000043, 34.131461923000074],
            [71.665215455000066, 34.131424104000075],
            [71.665020675000051, 34.131458173000055],
            [71.66476859100004, 34.131518726000024],
            [71.664518490000034, 34.131607808000069],
            [71.664302152000062, 34.131727493000028],
            [71.664086270000041, 34.131805329000031],
            [71.663703877000046, 34.131878242000028],
            [71.663537852000047, 34.131984688000045],
            [71.663396649000049, 34.132102324000073],
            [71.66293330600007, 34.132099284000049],
            [71.662713000000053, 34.132111000000066],
            [71.66254900000007, 34.132095000000049],
            [71.662475000000029, 34.132086000000072],
            [71.662345608000066, 34.132182065000052],
            [71.662026164000054, 34.132439022000028],
            [71.661354006000067, 34.133250894000071],
            [71.660853510000038, 34.133672244000024],
            [71.660454394000055, 34.133888389000049],
            [71.659648483000069, 34.134437944000069],
            [71.659176399000046, 34.134688781000079],
            [71.658807421000063, 34.134815632000027],
            [71.658790766000038, 34.134821166000052],
            [71.658167081000045, 34.135128162000058],
            [71.657436776000054, 34.135460427000055],
            [71.656891845000075, 34.135721691000072],
            [71.656456528000035, 34.135957162000068],
            [71.655964389000076, 34.135884250000061],
            [71.655577932000028, 34.135974641000075],
            [71.655332965000071, 34.136112486000059],
            [71.65521571000005, 34.136229713000034],
            [71.654952064000042, 34.136318077000055],
            [71.654805377000059, 34.136441287000025],
            [71.654752750000057, 34.136594385000024],
            [71.654762000000062, 34.136692000000039],
            [71.654727000000037, 34.136729000000059],
            [71.654690000000073, 34.136760000000038],
            [71.65465400000005, 34.136783000000037],
            [71.654619000000082, 34.136798000000056],
            [71.654602000000068, 34.136802000000046],
            [71.654585000000054, 34.136805000000038],
            [71.654551000000083, 34.136804000000041],
            [71.654512000000068, 34.136796000000061],
            [71.654473000000053, 34.136784000000034],
            [71.654386000000045, 34.136752000000058],
            [71.65434700000003, 34.136740000000032],
            [71.654203000000052, 34.136707000000058],
            [71.654053000000033, 34.136678000000074],
            [71.653763853000044, 34.136826603000031],
            [71.65355155900005, 34.136995565000063],
            [71.65325542800008, 34.137207821000061],
            [71.65301088800004, 34.137523914000042],
            [71.653003697000031, 34.137531667000076],
            [71.65273938200005, 34.137816660000055],
            [71.652731509000034, 34.137829673000056],
            [71.652353701000038, 34.138194031000069],
            [71.652070396000056, 34.138515956000049],
            [71.651850449000051, 34.138645562000079],
            [71.651565318000053, 34.138719704000039],
            [71.650793000000078, 34.138930000000073],
            [71.650625970000078, 34.138980815000025],
            [71.650388024000051, 34.139161910000041],
            [71.650230278000038, 34.139316313000052],
            [71.650133763000042, 34.139470939000034],
            [71.650065118000043, 34.139655749000042],
            [71.650030623000077, 34.139821276000077],
            [71.650006000000076, 34.140002000000038],
            [71.649907000000042, 34.140101000000072],
            [71.649805000000072, 34.140197000000057],
            [71.649537037000073, 34.140316269000039],
            [71.649414784000044, 34.140272275000029],
            [71.649315621000028, 34.140304683000068],
            [71.649271940000062, 34.140443868000034],
            [71.649426290000065, 34.140506830000049],
            [71.649541473000056, 34.140516587000036],
            [71.649987185000043, 34.140477049000026],
            [71.649989753000057, 34.140619981000043],
            [71.650009177000072, 34.140844066000056],
            [71.64994749300007, 34.141072582000049],
            [71.649887009000054, 34.141174799000055],
            [71.649704712000073, 34.141261763000045],
            [71.649395067000057, 34.141335353000045],
            [71.649141456000052, 34.141417355000044],
            [71.648829935000037, 34.141486240000063],
            [71.64854465500008, 34.141556372000025],
            [71.647986423000077, 34.141590300000075],
            [71.647974939000051, 34.141596010000058],
            [71.647618421000061, 34.141626949000056],
            [71.647292678000042, 34.141600700000026],
            [71.646813000000066, 34.141536000000031],
            [71.646573026000056, 34.14150169100003],
            [71.646208312000056, 34.141397892000043],
            [71.645913632000031, 34.141281132000074],
            [71.645703008000055, 34.141180385000041],
            [71.645525988000031, 34.141068822000079],
            [71.645326469000054, 34.140923991000079],
            [71.644913793000057, 34.140586806000044],
            [71.644891679000068, 34.14056927300004],
            [71.644664695000074, 34.14038931500005],
            [71.644656224000073, 34.14038447300004],
            [71.64417306200005, 34.140108344000055],
            [71.643617794000079, 34.139833147000047],
            [71.643367820000037, 34.139710755000067],
            [71.643157271000064, 34.139658438000026],
            [71.642924769000047, 34.139632263000067],
            [71.642690705000064, 34.139627530000041],
            [71.64249318800006, 34.139658574000066],
            [71.642309781000051, 34.139718493000032],
            [71.642129866000062, 34.139781510000034],
            [71.641925000000072, 34.139865000000043],
            [71.641872000000035, 34.139881000000059],
            [71.641820000000052, 34.139899000000071],
            [71.641769000000068, 34.139919000000077],
            [71.64171900000008, 34.139941000000078],
            [71.641650000000084, 34.139977000000044],
            [71.641575000000046, 34.140023000000042],
            [71.641499000000067, 34.140075000000024],
            [71.641288000000031, 34.140226000000041],
            [71.641222000000084, 34.140269000000046],
            [71.641161000000068, 34.140305000000069],
            [71.641036000000042, 34.14037200000007],
            [71.640916000000061, 34.140432000000033],
            [71.640816746000041, 34.140468146000046],
            [71.640630466000061, 34.140535389000036],
            [71.640433581000082, 34.140585225000052],
            [71.64026917800004, 34.140634886000043],
            [71.640071317000036, 34.14065731900007],
            [71.639684349000049, 34.140703109000071],
            [71.638976398000068, 34.14074037000006],
            [71.638648000000046, 34.140773000000024],
            [71.638605000000041, 34.140774000000079],
            [71.638495000000034, 34.140778000000068],
            [71.638437000000067, 34.140783000000056],
            [71.638382000000036, 34.140793000000031],
            [71.638358000000039, 34.14080000000007],
            [71.638336000000038, 34.14080800000005],
            [71.638293000000033, 34.140827000000058],
            [71.638266000000044, 34.140840000000026],
            [71.638246000000038, 34.140854000000047],
            [71.638231000000076, 34.140869000000066],
            [71.638225000000034, 34.140880000000038],
            [71.638220000000047, 34.140895000000057],
            [71.638212000000067, 34.140858000000037],
            [71.638205000000085, 34.14084100000008],
            [71.638198000000045, 34.140828000000056],
            [71.638188000000071, 34.140816000000029],
            [71.638175000000047, 34.14080400000006],
            [71.638160000000084, 34.140793000000031],
            [71.638143000000071, 34.140784000000053],
            [71.638103000000058, 34.140767000000039],
            [71.638055000000065, 34.14075500000007],
            [71.638002000000029, 34.140748000000031],
            [71.637948000000051, 34.140746000000036],
            [71.637895000000071, 34.140750000000025],
            [71.637849000000074, 34.140760000000057],
            [71.637830000000065, 34.140767000000039],
            [71.637809000000061, 34.140779000000066],
            [71.637745000000052, 34.140825000000063],
            [71.637723000000051, 34.140839000000028],
            [71.637705000000039, 34.140848000000062],
            [71.637687000000028, 34.140855000000045],
            [71.637669000000074, 34.140859000000034],
            [71.637649000000067, 34.140862000000027],
            [71.637571000000037, 34.140865000000076],
            [71.637540000000058, 34.140868000000069],
            [71.637437000000034, 34.140887000000077],
            [71.637400000000071, 34.140896000000055],
            [71.637369000000035, 34.14090600000003],
            [71.637339000000054, 34.140918000000056],
            [71.637313000000063, 34.14093100000008],
            [71.637290000000064, 34.140946000000042],
            [71.637271000000055, 34.140963000000056],
            [71.63726100000008, 34.140975000000026],
            [71.637252000000046, 34.140990000000045],
            [71.637225000000058, 34.141050000000064],
            [71.637215000000083, 34.14107000000007],
            [71.637202000000059, 34.141084000000035],
            [71.637196000000074, 34.141089000000079],
            [71.637189000000035, 34.141091000000074],
            [71.637178000000063, 34.141091000000074],
            [71.637164000000041, 34.141085000000032],
            [71.637118000000044, 34.141053000000056],
            [71.63710100000003, 34.141043000000025],
            [71.637087000000065, 34.141038000000037],
            [71.637075000000038, 34.14103700000004],
            [71.637055000000032, 34.14104100000003],
            [71.637026000000048, 34.141051000000061],
            [71.63700700000004, 34.141059000000041],
            [71.636987000000033, 34.141071000000068],
            [71.636921000000029, 34.141118000000063],
            [71.636859000000072, 34.141161000000068],
            [71.636801000000048, 34.141204000000073],
            [71.636745000000076, 34.141248000000076],
            [71.636693000000037, 34.141293000000076],
            [71.636666000000048, 34.141315000000077],
            [71.636595000000057, 34.141368000000057],
            [71.636562000000083, 34.14139700000004],
            [71.636547000000064, 34.141414000000054],
            [71.636535000000038, 34.141430000000071],
            [71.636527000000058, 34.14144600000003],
            [71.63652200000007, 34.141462000000047],
            [71.636521000000073, 34.141473000000076],
            [71.63652200000007, 34.141485000000046],
            [71.63653000000005, 34.141516000000024],
            [71.636545000000069, 34.141555000000039],
            [71.636584000000084, 34.141636000000062],
            [71.636588000000074, 34.141650000000027],
            [71.636587000000077, 34.141657000000066],
            [71.636584000000084, 34.141658000000064],
            [71.636581000000035, 34.141656000000069],
            [71.636569000000065, 34.141646000000037],
            [71.636507000000051, 34.141572000000053],
            [71.636474000000078, 34.14153900000008],
            [71.636447000000032, 34.141518000000076],
            [71.636435000000063, 34.141512000000034],
            [71.636424000000034, 34.141509000000042],
            [71.636410000000069, 34.141508000000044],
            [71.636396000000047, 34.141510000000039],
            [71.636380000000031, 34.141515000000027],
            [71.636363000000074, 34.141522000000066],
            [71.636331000000041, 34.14153900000008],
            [71.636258000000055, 34.141585000000077],
            [71.636226000000079, 34.141602000000034],
            [71.636167000000057, 34.141626000000031],
            [71.636101000000053, 34.141649000000029],
            [71.63602700000007, 34.141672000000028],
            [71.635804000000064, 34.141737000000035],
            [71.635735000000068, 34.141760000000033],
            [71.635674000000051, 34.141783000000032],
            [71.635613000000035, 34.141809000000023],
            [71.635547000000031, 34.141841000000056],
            [71.635274000000038, 34.141983000000039],
            [71.635209000000032, 34.142014000000074],
            [71.635151000000064, 34.142041000000063],
            [71.63509700000003, 34.142063000000064],
            [71.635036000000071, 34.142084000000068],
            [71.634803000000034, 34.142151000000069],
            [71.634709000000043, 34.142182000000048],
            [71.634660000000054, 34.142201000000057],
            [71.634616000000051, 34.142222000000061],
            [71.634575000000041, 34.142244000000062],
            [71.634538000000077, 34.142268000000058],
            [71.634369000000049, 34.14237600000007],
            [71.634327000000042, 34.142407000000048],
            [71.634294000000068, 34.142436000000032],
            [71.634265000000084, 34.142469000000062],
            [71.634254000000055, 34.142485000000079],
            [71.634246000000076, 34.142501000000038],
            [71.634240000000034, 34.142518000000052],
            [71.634235000000047, 34.142534000000069],
            [71.634234000000049, 34.142550000000028],
            [71.634234000000049, 34.142567000000042],
            [71.634237000000041, 34.142585000000054],
            [71.634249000000068, 34.142625000000066],
            [71.63425200000006, 34.142643000000078],
            [71.634251000000063, 34.142656000000045],
            [71.634247000000073, 34.142669000000069],
            [71.634240000000034, 34.142682000000036],
            [71.634229000000062, 34.14269500000006],
            [71.634200000000078, 34.142721000000051],
            [71.634128000000032, 34.142770000000041],
            [71.634100000000046, 34.142792000000043],
            [71.634088000000077, 34.142804000000069],
            [71.634078000000045, 34.142817000000036],
            [71.63407200000006, 34.142829000000063],
            [71.63406800000007, 34.14284200000003],
            [71.634067000000073, 34.142860000000042],
            [71.634069000000068, 34.142880000000048],
            [71.63407600000005, 34.142904000000044],
            [71.634101000000044, 34.142981000000077],
            [71.634105000000034, 34.143005000000073],
            [71.634105000000034, 34.143026000000077],
            [71.634101000000044, 34.143051000000071],
            [71.634094000000061, 34.14307800000006],
            [71.63408400000003, 34.143106000000046],
            [71.634071000000063, 34.143135000000029],
            [71.634058000000039, 34.143158000000028],
            [71.634043000000077, 34.143182000000024],
            [71.634024000000068, 34.14320500000008],
            [71.634006081000052, 34.143223773000045],
            [71.634003000000064, 34.143227000000024],
            [71.633979000000068, 34.14325000000008],
            [71.633952000000079, 34.143273000000079],
            [71.63388800000007, 34.143319000000076],
            [71.633858000000032, 34.143337000000031],
            [71.633822000000066, 34.143354000000045],
            [71.633683000000076, 34.143408000000079],
            [71.63366300000007, 34.143414000000064],
            [71.633605000000045, 34.143428000000029],
            [71.633582000000047, 34.143435000000068],
            [71.633572000000072, 34.143440000000055],
            [71.633565000000033, 34.14344600000004],
            [71.63356200000004, 34.143453000000079],
            [71.63356200000004, 34.143460000000061],
            [71.633567000000085, 34.143473000000029],
            [71.633579000000054, 34.143494000000032],
            [71.633597000000066, 34.143517000000031],
            [71.633643000000063, 34.143561000000034],
            [71.633648000000051, 34.143567000000075],
            [71.633648000000051, 34.143570000000068],
            [71.633643000000063, 34.143571000000065],
            [71.633633000000032, 34.143570000000068],
            [71.633603000000051, 34.143561000000034],
            [71.633575000000064, 34.143549000000064],
            [71.633556000000056, 34.143537000000038],
            [71.633547000000078, 34.143527000000063],
            [71.633530000000064, 34.143500000000074],
            [71.633522000000085, 34.143489000000045],
            [71.633501000000081, 34.143472000000031],
            [71.633474000000035, 34.143458000000066],
            [71.633442000000059, 34.14344600000004],
            [71.633389000000079, 34.143432000000075],
            [71.633361000000036, 34.143426000000034],
            [71.633332000000053, 34.143422000000044],
            [71.633301000000074, 34.143421000000046],
            [71.633267000000046, 34.143422000000044],
            [71.63319800000005, 34.143429000000026],
            [71.633061000000055, 34.143451000000027],
            [71.632999000000041, 34.143461000000059],
            [71.632936000000029, 34.143474000000026],
            [71.63287100000008, 34.143489000000045],
            [71.632804000000078, 34.143507000000056],
            [71.632736000000079, 34.14352800000006],
            [71.632666000000029, 34.143552000000057],
            [71.63259400000004, 34.143579000000045],
            [71.632520000000056, 34.143608000000029],
            [71.632469000000071, 34.143631000000028],
            [71.632419000000084, 34.143656000000078],
            [71.632368000000042, 34.143684000000064],
            [71.632318000000055, 34.14371600000004],
            [71.632268000000067, 34.143750000000068],
            [71.63221800000008, 34.143788000000029],
            [71.632167000000038, 34.143829000000039],
            [71.632116000000053, 34.143873000000042],
            [71.632028000000048, 34.14395600000006],
            [71.631934000000058, 34.144053000000042],
            [71.631919688000039, 34.14406858500007],
            [71.631844000000058, 34.144151000000079],
            [71.631617000000062, 34.144405000000063],
            [71.631551000000059, 34.144476000000054],
            [71.631490000000042, 34.144538000000068],
            [71.631421000000046, 34.14460500000007],
            [71.631355000000042, 34.144665000000032],
            [71.631291000000033, 34.144719000000066],
            [71.631227000000081, 34.144768000000056],
            [71.631141000000071, 34.144829000000072],
            [71.631043000000034, 34.144895000000076],
            [71.630694000000062, 34.145117000000027],
            [71.630615813000077, 34.145168935000072],
            [71.630612882000037, 34.14517088100007],
            [71.630557000000067, 34.145208000000025],
            [71.630473000000052, 34.145269000000042],
            [71.630286000000069, 34.145419000000061],
            [71.630201000000056, 34.14548300000007],
            [71.630153000000064, 34.145515000000046],
            [71.630108000000064, 34.145542000000034],
            [71.630063000000064, 34.145565000000033],
            [71.630020000000059, 34.14558500000004],
            [71.629975000000059, 34.145602000000054],
            [71.629926000000069, 34.145616000000075],
            [71.629874000000029, 34.145628000000045],
            [71.62981700000006, 34.145639000000074],
            [71.629710000000046, 34.145652000000041],
            [71.629456000000062, 34.145674000000042],
            [71.629391000000055, 34.145682000000079],
            [71.629334000000028, 34.145692000000054],
            [71.629112000000077, 34.145729000000074],
            [71.62902900000006, 34.145746000000031],
            [71.628941000000054, 34.145768000000032],
            [71.628902000000039, 34.145780000000059],
            [71.628865000000076, 34.145794000000024],
            [71.628693000000055, 34.145864000000074],
            [71.628569000000084, 34.145918000000052],
            [71.628469000000052, 34.145968000000039],
            [71.628428000000042, 34.14599000000004],
            [71.628394000000071, 34.146012000000042],
            [71.628366000000028, 34.146032000000048],
            [71.628345000000081, 34.146051000000057],
            [71.62833100000006, 34.146069000000068],
            [71.62832300000008, 34.146085000000028],
            [71.628322000000082, 34.146099000000049],
            [71.628328000000067, 34.146113000000071],
            [71.628341000000034, 34.14612500000004],
            [71.628360000000043, 34.146135000000072],
            [71.628375000000062, 34.146141000000057],
            [71.628403000000048, 34.146155000000078],
            [71.628422000000057, 34.146172000000035],
            [71.62842900000004, 34.14618100000007],
            [71.628434000000084, 34.146190000000047],
            [71.628437000000076, 34.146200000000078],
            [71.628438000000074, 34.146211000000051],
            [71.628437000000076, 34.14622200000008],
            [71.628435000000081, 34.146234000000049],
            [71.628423000000055, 34.146259000000043],
            [71.628404000000046, 34.146287000000029],
            [71.628377000000057, 34.146318000000065],
            [71.62832300000008, 34.146367000000055],
            [71.628251000000034, 34.146424000000025],
            [71.628158000000042, 34.146491000000026],
            [71.628112955000063, 34.146521472000074],
            [71.627954000000045, 34.146629000000075],
            [71.627872000000082, 34.146687000000043],
            [71.627794000000051, 34.146746000000064],
            [71.627738000000079, 34.146795000000054],
            [71.627689000000032, 34.146840000000054],
            [71.62763300000006, 34.146887000000049],
            [71.627569000000051, 34.146937000000037],
            [71.627380000000073, 34.147079000000076],
            [71.627323000000047, 34.147124000000076],
            [71.627275000000054, 34.147167000000024],
            [71.627222000000074, 34.147219000000064],
            [71.627178000000072, 34.147270000000049],
            [71.627140000000054, 34.147322000000031],
            [71.627117541000075, 34.147359673000039],
            [71.627109000000075, 34.14737400000007],
            [71.627092000000061, 34.147410000000036],
            [71.627075000000048, 34.147449000000051],
            [71.627060000000029, 34.147491000000059],
            [71.627046000000064, 34.147538000000054],
            [71.627024000000063, 34.147622000000069],
            [71.626987000000042, 34.147789000000046],
            [71.626970000000028, 34.147857000000045],
            [71.626952000000074, 34.14793700000007],
            [71.626920000000041, 34.148106000000041],
            [71.626904000000081, 34.14817800000003],
            [71.626885000000073, 34.148251000000073],
            [71.626862000000074, 34.14831300000003],
            [71.626832000000036, 34.148378000000037],
            [71.62679600000007, 34.148442000000045],
            [71.62675500000006, 34.148506000000054],
            [71.626708000000065, 34.148569000000066],
            [71.626655000000028, 34.14863100000008],
            [71.626598000000058, 34.14869200000004],
            [71.626535000000047, 34.148751000000061],
            [71.626468000000045, 34.148809000000028],
            [71.626396000000057, 34.148865000000058],
            [71.626320000000078, 34.148919000000035],
            [71.626240000000053, 34.148969000000079],
            [71.626158000000032, 34.149017000000072],
            [71.626074000000074, 34.149061000000074],
            [71.625988000000063, 34.14910100000003],
            [71.625903000000051, 34.149136000000055],
            [71.625818000000038, 34.149166000000037],
            [71.625743000000057, 34.14918700000004],
            [71.625662000000034, 34.149205000000052],
            [71.625575000000083, 34.149218000000076],
            [71.625481000000036, 34.149227000000053],
            [71.625388000000044, 34.14923200000004],
            [71.625285000000076, 34.149234000000035],
            [71.624909000000059, 34.149231000000043],
            [71.624858000000074, 34.149231000000043],
            [71.624816000000067, 34.149229000000048],
            [71.624768000000074, 34.149224000000061],
            [71.624607000000083, 34.149197000000072],
            [71.624544000000071, 34.149189000000035],
            [71.62448100000006, 34.149186000000043],
            [71.624452000000076, 34.14918700000004],
            [71.624424000000033, 34.149190000000033],
            [71.624331000000041, 34.149208000000044],
            [71.624271000000078, 34.149214000000029],
            [71.624209000000064, 34.149214000000029],
            [71.624143000000061, 34.149209000000042],
            [71.624073000000067, 34.149197000000072],
            [71.624017000000038, 34.149185000000045],
            [71.62395500000008, 34.149169000000029],
            [71.623698000000047, 34.149091000000055],
            [71.623639000000082, 34.149075000000039],
            [71.623586000000046, 34.149062000000072],
            [71.623436000000083, 34.149026000000049],
            [71.623100000000079, 34.148941000000036],
            [71.623059645000069, 34.148931708000077],
            [71.622948000000065, 34.148906000000068],
            [71.62262700000008, 34.148839000000066],
            [71.62239100000005, 34.148796000000061],
            [71.622281000000044, 34.14877800000005],
            [71.622176000000081, 34.148764000000028],
            [71.62207600000005, 34.148753000000056],
            [71.621979000000067, 34.148744000000079],
            [71.621740000000045, 34.148720000000026],
            [71.621637000000078, 34.148713000000043],
            [71.621583000000044, 34.148713000000043],
            [71.621533000000056, 34.148716000000036],
            [71.621487000000059, 34.14872100000008],
            [71.620746496000038, 34.14864930300007],
            [71.620290405000048, 34.148605143000054],
            [71.61928457700003, 34.148480036000024],
            [71.61879242100008, 34.148382760000061],
            [71.61873694500008, 34.148352660000057],
            [71.61818790600006, 34.148054764000051],
            [71.617453283000032, 34.147807052000076],
            [71.61698130700006, 34.147752031000039],
            [71.616845236000074, 34.147830310000074],
            [71.61690432000006, 34.147975211000073],
            [71.616973843000039, 34.148117316000025],
            [71.617161525000029, 34.148181469000065],
            [71.617410573000029, 34.148277589000031],
            [71.617717106000043, 34.148389294000026],
            [71.617811686000039, 34.148492027000032],
            [71.617785452000078, 34.148534056000074],
            [71.61743520400006, 34.148547316000077],
            [71.617159468000068, 34.148568082000054],
            [71.616872931000046, 34.148620099000027],
            [71.616679205000082, 34.148678170000039],
            [71.616420873000038, 34.148787036000044],
            [71.61612892100004, 34.148911998000074],
            [71.615982436000081, 34.148978439000075],
            [71.615801030000057, 34.149054000000035],
            [71.615563193000071, 34.149241221000068],
            [71.615556524000056, 34.149246471000026],
            [71.615374596000038, 34.149404790000062],
            [71.615238808000072, 34.149531859000035],
            [71.615107526000031, 34.149689718000047],
            [71.615097442000035, 34.149701844000049],
            [71.61498836800007, 34.149856073000024],
            [71.614913324000042, 34.149966358000029],
            [71.614781876000052, 34.150175379000075],
            [71.614669750000076, 34.15034005800004],
            [71.61458329800007, 34.150447937000024],
            [71.614527840000051, 34.150492571000029],
            [71.61446600000005, 34.150492000000042],
            [71.614358000000038, 34.15048500000006],
            [71.614318000000083, 34.15048100000007],
            [71.61427400000008, 34.150473000000034],
            [71.614134000000035, 34.15044000000006],
            [71.61408700000004, 34.150430000000028],
            [71.614045000000033, 34.150423000000046],
            [71.614006000000074, 34.150420000000054],
            [71.613954000000035, 34.150419000000056],
            [71.61389900000006, 34.150422000000049],
            [71.613842000000034, 34.150427000000036],
            [71.613783000000069, 34.15043600000007],
            [71.613721000000055, 34.15044800000004],
            [71.613655000000051, 34.150462000000061],
            [71.61358400000006, 34.150480000000073],
            [71.613505000000032, 34.150503000000072],
            [71.613444000000072, 34.150522000000024],
            [71.613386000000048, 34.15054200000003],
            [71.613329000000078, 34.150563000000034],
            [71.613275000000044, 34.150586000000033],
            [71.613211000000035, 34.150617000000068],
            [71.613141000000041, 34.150655000000029],
            [71.613076000000035, 34.150694000000044],
            [71.612915000000044, 34.150794000000076],
            [71.61274700000007, 34.150893000000053],
            [71.61272043200006, 34.150910712000041],
            [71.612699000000077, 34.150925000000029],
            [71.61265700000007, 34.150955000000067],
            [71.612603000000036, 34.15099900000007],
            [71.612549000000058, 34.15104800000006],
            [71.612495000000081, 34.151103000000035],
            [71.612441000000047, 34.151163000000054],
            [71.612387000000069, 34.151229000000058],
            [71.61233100000004, 34.151303000000041],
            [71.612271000000078, 34.151386000000059],
            [71.61219200000005, 34.151500000000055],
            [71.61215500000003, 34.151557000000025],
            [71.612117000000069, 34.151622000000032],
            [71.612076000000059, 34.151702000000057],
            [71.611990000000048, 34.151876000000073],
            [71.611951000000033, 34.151951000000054],
            [71.611907000000031, 34.152029000000027],
            [71.611864000000082, 34.152096000000029],
            [71.611822000000075, 34.152152000000058],
            [71.611709000000076, 34.152289000000053],
            [71.611680000000035, 34.152330000000063],
            [71.611656000000039, 34.152369000000078],
            [71.61163700000003, 34.152406000000042],
            [71.611619000000076, 34.152445000000057],
            [71.611602000000062, 34.152487000000065],
            [71.611585000000048, 34.152532000000065],
            [71.611555000000067, 34.15263200000004],
            [71.611520000000041, 34.15276700000004],
            [71.611507000000074, 34.152833000000044],
            [71.611497000000043, 34.152908000000025],
            [71.611492000000055, 34.152980000000071],
            [71.61148200000008, 34.153147000000047],
            [71.611475000000041, 34.153226000000075],
            [71.611465000000067, 34.153293000000076],
            [71.611452000000043, 34.153354000000036],
            [71.611442000000068, 34.153398000000038],
            [71.611416000000077, 34.153524000000061],
            [71.611407000000042, 34.153561000000025],
            [71.611397000000068, 34.153593000000058],
            [71.611383000000046, 34.153625000000034],
            [71.611368000000084, 34.153653000000077],
            [71.611350000000073, 34.153675000000078],
            [71.611330000000066, 34.153693000000032],
            [71.61130600000007, 34.153707000000054],
            [71.611275000000035, 34.153717000000029],
            [71.611242000000061, 34.153724000000068],
            [71.611149000000069, 34.153738000000033],
            [71.61112200000008, 34.153744000000074],
            [71.611100000000079, 34.153752000000054],
            [71.611070000000041, 34.153766000000076],
            [71.611039000000062, 34.153782000000035],
            [71.610975000000053, 34.15382100000005],
            [71.610907000000054, 34.153871000000038],
            [71.610813000000064, 34.153948000000071],
            [71.610775000000046, 34.153981000000044],
            [71.610735000000034, 34.154019000000062],
            [71.610583000000076, 34.154174000000069],
            [71.610498000000064, 34.154255000000035],
            [71.610299000000055, 34.154435000000035],
            [71.610212000000047, 34.154517000000055],
            [71.610123000000044, 34.154607000000055],
            [71.610047000000066, 34.154691000000071],
            [71.610005000000058, 34.154743000000053],
            [71.60995900000006, 34.154802000000075],
            [71.609804000000054, 34.155015000000049],
            [71.60968900000006, 34.155163000000073],
            [71.609660000000076, 34.155204000000026],
            [71.609637000000077, 34.155242000000044],
            [71.609620000000064, 34.155274000000077],
            [71.609604000000047, 34.155308000000048],
            [71.609575000000063, 34.155385000000024],
            [71.609548000000075, 34.155468000000042],
            [71.609482916000047, 34.155626569000049],
            [71.609348588000046, 34.155760358000066],
            [71.609224475000076, 34.155850913000052],
            [71.609125431000052, 34.155861346000052],
            [71.608948037000062, 34.155904957000075],
            [71.608773118000045, 34.156005815000071],
            [71.608712308000065, 34.156113515000072],
            [71.608734881000032, 34.156460889000073],
            [71.608725981000077, 34.156757192000043],
            [71.608680932000084, 34.156985665000036],
            [71.60855267900007, 34.157276574000036],
            [71.608383867000043, 34.157615711000062],
            [71.608230252000055, 34.157779453000046],
            [71.607797684000047, 34.158205498000029],
            [71.607295973000078, 34.158862090000071],
            [71.60723971200008, 34.158935720000045],
            [71.606993313000032, 34.159198445000072],
            [71.606160205000037, 34.159580449000032],
            [71.605918022000083, 34.159646203000079],
            [71.605764202000046, 34.159641720000025],
            [71.605543005000072, 34.159513437000044],
            [71.605193348000057, 34.159340594000071],
            [71.604882614000076, 34.159240339000064],
            [71.604694133000066, 34.159185365000042],
            [71.604429000000039, 34.159136000000046],
            [71.604354000000058, 34.15912000000003],
            [71.604276000000084, 34.159106000000065],
            [71.604192000000069, 34.159095000000036],
            [71.604103000000066, 34.159086000000059],
            [71.604015000000061, 34.159079000000077],
            [71.603916000000083, 34.159074000000032],
            [71.603599000000031, 34.159062000000063],
            [71.603511000000083, 34.159060000000068],
            [71.603414000000043, 34.159061000000065],
            [71.603099000000043, 34.159073000000035],
            [71.602989000000036, 34.15907500000003],
            [71.602886000000069, 34.15907500000003],
            [71.602793000000077, 34.15907100000004],
            [71.602725000000078, 34.159065000000055],
            [71.602570000000071, 34.159049000000039],
            [71.60249900000008, 34.159044000000051],
            [71.602438000000063, 34.159043000000054],
            [71.602371000000062, 34.159045000000049],
            [71.602151000000049, 34.159062000000063],
            [71.602072000000078, 34.159066000000053],
            [71.60199700000004, 34.159066000000053],
            [71.601930000000038, 34.159061000000065],
            [71.60185400000006, 34.159050000000036],
            [71.601770000000045, 34.159032000000025],
            [71.601690000000076, 34.159012000000075],
            [71.601514000000066, 34.158964000000026],
            [71.60143800000003, 34.158945000000074],
            [71.601318000000049, 34.158919000000026],
            [71.601057000000083, 34.158870000000036],
            [71.600943000000029, 34.158847000000037],
            [71.600824000000046, 34.158818000000053],
            [71.600719000000083, 34.158788000000072],
            [71.600665000000049, 34.158769000000063],
            [71.600606000000084, 34.15874400000007],
            [71.600401000000033, 34.158645000000035],
            [71.600367554000059, 34.158630135000067],
            [71.600320000000067, 34.15860900000007],
            [71.600277000000062, 34.158593000000053],
            [71.600236000000052, 34.158580000000029],
            [71.600197000000037, 34.158571000000052],
            [71.600159000000076, 34.158564000000069],
            [71.600082000000043, 34.15855700000003],
            [71.600002000000075, 34.158555000000035],
            [71.599918000000059, 34.158559000000025],
            [71.599832000000049, 34.158569000000057],
            [71.599744000000044, 34.158584000000076],
            [71.599656000000039, 34.15860500000008],
            [71.599568000000033, 34.158630000000073],
            [71.59948200000008, 34.158661000000052],
            [71.599433000000033, 34.158681000000058],
            [71.599384000000043, 34.15870300000006],
            [71.599335000000053, 34.158728000000053],
            [71.599287000000061, 34.158754000000044],
            [71.599239000000068, 34.158782000000031],
            [71.599192000000073, 34.158812000000069],
            [71.599146000000076, 34.158844000000045],
            [71.599101000000076, 34.158877000000075],
            [71.598995000000059, 34.158960000000036],
            [71.598958000000039, 34.158991000000071],
            [71.598926000000063, 34.159021000000052],
            [71.598897000000079, 34.159050000000036],
            [71.598872000000028, 34.159080000000074],
            [71.598850000000084, 34.159109000000058],
            [71.598832000000073, 34.159138000000041],
            [71.598818000000051, 34.159167000000025],
            [71.598806000000081, 34.15919800000006],
            [71.598797000000047, 34.159233000000029],
            [71.598789000000068, 34.159271000000047],
            [71.598648245000049, 34.15979677100006],
            [71.598601249000069, 34.16004532200003],
            [71.598522154000079, 34.160323736000066],
            [71.598421318000078, 34.160759385000063],
            [71.598366863000081, 34.161066239000036],
            [71.598265000000083, 34.161393000000032],
            [71.598255000000051, 34.161422000000073],
            [71.598247000000072, 34.161450000000059],
            [71.59824100000003, 34.161477000000048],
            [71.598238000000038, 34.161503000000039],
            [71.598235000000045, 34.161561000000063],
            [71.598236000000043, 34.161610000000053],
            [71.598243000000082, 34.161654000000055],
            [71.598254000000054, 34.161691000000076],
            [71.598268000000076, 34.161719000000062],
            [71.598287000000084, 34.161749000000043],
            [71.598311000000081, 34.161781000000076],
            [71.598345000000052, 34.161819000000037],
            [71.598383000000069, 34.161857000000055],
            [71.598401000000081, 34.161869000000024],
            [71.598445000000083, 34.16189200000008],
            [71.598463000000038, 34.161906000000045],
            [71.59847300000007, 34.161921000000063],
            [71.598478000000057, 34.161942000000067],
            [71.598480000000052, 34.161966000000064],
            [71.598478000000057, 34.162021000000038],
            [71.598478000000057, 34.162044000000037],
            [71.598482000000047, 34.162067000000036],
            [71.598490000000083, 34.162085000000047],
            [71.598501000000056, 34.162102000000061],
            [71.598515000000077, 34.162118000000078],
            [71.598533323000083, 34.162269388000027],
            [71.598543674000041, 34.162453633000041],
            [71.598551350000037, 34.162607562000062],
            [71.598557289000041, 34.162687019000032],
            [71.598517895000043, 34.162760226000046],
            [71.598471062000044, 34.162812129000031],
            [71.598428855000066, 34.162850204000051],
            [71.598236848000056, 34.162931566000054],
            [71.597928882000076, 34.163075539000033],
            [71.597804000000053, 34.163136000000065],
            [71.597309754000037, 34.163349981000067],
            [71.597097471000041, 34.163426657000059],
            [71.596922778000078, 34.163473852000038],
            [71.596396276000064, 34.163585365000074],
            [71.596059773000036, 34.16365991300006],
            [71.595838474000061, 34.163708940000049],
            [71.595465380000064, 34.16378697600004],
            [71.59512200000006, 34.163871000000029],
            [71.595061000000044, 34.163879000000065],
            [71.595001000000082, 34.16388900000004],
            [71.594947000000047, 34.163901000000067],
            [71.594822000000079, 34.163933000000043],
            [71.594760000000065, 34.163945000000069],
            [71.594652812000049, 34.163970711000047],
            [71.594489818000056, 34.163993651000055],
            [71.594136310000067, 34.164005736000036],
            [71.593675117000032, 34.164014692000023],
            [71.593305663000081, 34.164025965000064],
            [71.593099467000059, 34.164075156000024],
            [71.59268000000003, 34.164214000000072],
            [71.592647000000056, 34.164214000000072],
            [71.59261900000007, 34.164217000000065],
            [71.592540000000042, 34.164227000000039],
            [71.592505000000074, 34.164234000000079],
            [71.592473000000041, 34.164246000000048],
            [71.592460000000074, 34.164253000000031],
            [71.592455532000031, 34.164253448000068],
            [71.592322560000071, 34.164266789000067],
            [71.592177728000081, 34.164271284000051],
            [71.591943953000055, 34.164270979000037],
            [71.591769904000046, 34.16423702700007],
            [71.591574000000037, 34.164153000000056],
            [71.59127600000005, 34.163989000000072],
            [71.591240000000084, 34.16397100000006],
            [71.591198000000077, 34.163952000000052],
            [71.590953846000048, 34.16379489600007],
            [71.59082225800006, 34.163685181000062],
            [71.590802973000052, 34.163607672000069],
            [71.590823257000068, 34.163535937000063],
            [71.590817689000062, 34.163456263000057],
            [71.590770689000067, 34.163397527000029],
            [71.590595199000063, 34.163384201000042],
            [71.590147757000068, 34.163429488000077],
            [71.589830052000082, 34.163510870000039],
            [71.589459674000068, 34.16361796700005],
            [71.589311997000038, 34.163695927000049],
            [71.589114419000055, 34.163838750000025],
            [71.588905198000077, 34.163932266000074],
            [71.588648053000043, 34.16397951600004],
            [71.588451921000058, 34.163918511000077],
            [71.588219177000042, 34.163792919000059],
            [71.587900496000032, 34.163812189000055],
            [71.587890763000075, 34.163812858000028],
            [71.587518506000038, 34.163838461000068],
            [71.58720664100008, 34.163917812000079],
            [71.586905401000081, 34.164031385000044],
            [71.586682953000036, 34.164168371000073],
            [71.586611048000066, 34.164412125000069],
            [71.586636436000049, 34.164673974000038],
            [71.586603657000069, 34.165070734000039],
            [71.586591060000046, 34.165223216000072],
            [71.586365341000032, 34.165800141000034],
            [71.586210444000073, 34.166018884000039],
            [71.586011280000037, 34.16620886700008],
            [71.585420389000035, 34.166631869000071],
            [71.585391533000063, 34.166652526000064],
            [71.585313313000029, 34.166693675000033],
            [71.584558396000034, 34.167090818000077],
            [71.584077309000065, 34.167343906000042],
            [71.583338878000063, 34.16776370000008],
            [71.583186000000069, 34.167831000000035],
            [71.58288600000003, 34.167932000000064],
            [71.582833000000051, 34.167951000000073],
            [71.582778000000076, 34.167974000000072],
            [71.582718000000057, 34.168002000000058],
            [71.582654000000048, 34.168034000000034],
            [71.582534000000066, 34.168099000000041],
            [71.582260000000076, 34.168256000000042],
            [71.582134000000053, 34.168323000000044],
            [71.582030000000032, 34.168372000000033],
            [71.581982000000039, 34.168391000000042],
            [71.581937000000039, 34.168407000000059],
            [71.581874000000084, 34.16842500000007],
            [71.581815000000063, 34.168438000000037],
            [71.581760000000031, 34.168444000000079],
            [71.581707000000051, 34.168444000000079],
            [71.581657000000064, 34.168438000000037],
            [71.581609000000071, 34.168426000000068],
            [71.581565000000069, 34.168408000000056],
            [71.581522000000064, 34.16838400000006],
            [71.581489000000033, 34.168360000000064],
            [71.581454000000065, 34.168328000000031],
            [71.581420000000037, 34.16829400000006],
            [71.581330000000037, 34.168197000000077],
            [71.581303000000048, 34.168170000000032],
            [71.581277000000057, 34.168147000000033],
            [71.581230000000062, 34.168109000000072],
            [71.581181000000072, 34.168072000000052],
            [71.58113000000003, 34.168036000000029],
            [71.58107700000005, 34.168001000000061],
            [71.581022000000075, 34.167967000000033],
            [71.580965000000049, 34.167934000000059],
            [71.580906000000084, 34.167902000000026],
            [71.580845000000068, 34.167871000000048],
            [71.58071700000005, 34.167812000000026],
            [71.580582000000049, 34.167757000000051],
            [71.580441000000064, 34.167707000000064],
            [71.58029300000004, 34.167662000000064],
            [71.580213000000072, 34.167642000000058],
            [71.580129000000056, 34.167626000000041],
            [71.580039000000056, 34.167613000000074],
            [71.579942000000074, 34.167603000000042],
            [71.579856000000063, 34.167598000000055],
            [71.579759000000081, 34.167594000000065],
            [71.579637939000065, 34.167592138000032],
            [71.579434000000049, 34.167589000000078],
            [71.579311000000075, 34.167586000000028],
            [71.579191000000037, 34.167579000000046],
            [71.579083000000082, 34.167568000000074],
            [71.578936000000056, 34.16754700000007],
            [71.578424939000058, 34.167498274000025],
            [71.577077647000067, 34.16726628300006],
            [71.576688681000064, 34.167192509000074],
            [71.57582936700004, 34.167029527000068],
            [71.575204000000042, 34.166923000000054],
            [71.574551994000046, 34.166816245000064],
            [71.574090243000057, 34.166733130000068],
            [71.57372295700003, 34.166754474000072],
            [71.573315465000064, 34.16685633000003],
            [71.572574490000079, 34.167142109000054],
            [71.572227357000031, 34.167233476000035],
            [71.57197332100003, 34.167277642000045],
            [71.571529377000047, 34.167457736000074],
            [71.571210073000032, 34.167590373000053],
            [71.571115665000036, 34.167617576000055],
            [71.570915706000051, 34.167623414000047],
            [71.570721347000074, 34.167634321000037],
            [71.570696677000058, 34.167636150000078],
            [71.570640648000051, 34.167640302000052],
            [71.570527734000052, 34.167670705000035],
            [71.570480959000065, 34.167689804000076],
            [71.570330133000084, 34.16766989100006],
            [71.570108288000029, 34.167620620000037],
            [71.569950745000028, 34.167541842000048],
            [71.569805187000043, 34.167482156000062],
            [71.569625583000061, 34.167391810000026],
            [71.569356728000059, 34.167317353000044],
            [71.56932297600008, 34.167311254000026],
            [71.569113582000057, 34.167273416000057],
            [71.568879920000029, 34.167227650000029],
            [71.568724763000034, 34.167142334000062],
            [71.568544645000031, 34.167041969000024],
            [71.568306929000073, 34.166962208000029],
            [71.567949000000056, 34.166878000000054],
            [71.567906969000035, 34.166870187000029],
            [71.567793000000052, 34.16684900000007],
            [71.567633000000058, 34.166815000000042],
            [71.567454000000055, 34.166774000000032],
            [71.566913047000071, 34.166661401000056],
            [71.566281253000056, 34.166551022000078],
            [71.566032333000066, 34.166518491000033],
            [71.565510872000061, 34.16651672900008],
            [71.565098760000069, 34.166531552000038],
            [71.56460469700005, 34.166579744000046],
            [71.564279049000049, 34.166634441000042],
            [71.564062000000035, 34.166678000000047],
            [71.56393349700005, 34.166787228000032],
            [71.563922000000048, 34.166797000000031],
            [71.56387600000005, 34.166835000000049],
            [71.563836000000038, 34.166864000000032],
            [71.563798000000077, 34.166888000000029],
            [71.563757000000066, 34.16691000000003],
            [71.563713000000064, 34.166930000000036],
            [71.563668000000064, 34.16694700000005],
            [71.563621000000069, 34.166962000000069],
            [71.563571000000081, 34.166974000000039],
            [71.56352000000004, 34.16698400000007],
            [71.563465000000065, 34.166991000000053],
            [71.563408000000038, 34.16699600000004],
            [71.563303000000076, 34.16700000000003],
            [71.563184000000035, 34.166997000000038],
            [71.563065000000051, 34.166989000000058],
            [71.562750000000051, 34.166963000000067],
            [71.562658000000056, 34.166958000000079],
            [71.562575000000038, 34.166956000000027],
            [71.56248400000004, 34.166958000000079],
            [71.562400000000082, 34.166964000000064],
            [71.562322000000052, 34.166975000000036],
            [71.562249000000065, 34.166990000000055],
            [71.562193000000036, 34.167005000000074],
            [71.562135000000069, 34.167024000000026],
            [71.562074000000052, 34.16704500000003],
            [71.562009000000046, 34.167070000000024],
            [71.56189100000006, 34.167120000000068],
            [71.561620000000062, 34.167242000000044],
            [71.561520274000031, 34.16729237900006],
            [71.561406816000044, 34.167313917000058],
            [71.561149529000033, 34.167281857000035],
            [71.560793214000057, 34.167104621000078],
            [71.560607596000068, 34.167028115000051],
            [71.560281649000046, 34.166882753000039],
            [71.560062312000071, 34.166755806000026],
            [71.559992183000077, 34.166733962000023],
            [71.55987934500007, 34.166698816000064],
            [71.559486396000068, 34.16668495600004],
            [71.559072787000048, 34.166745796000043],
            [71.558548827000038, 34.16684861300007],
            [71.558275448000074, 34.166909453000073],
            [71.557959524000069, 34.167024831000049],
            [71.557537904000071, 34.167242525000063],
            [71.557172306000041, 34.167433213000038],
            [71.556828193000058, 34.167684030000032],
            [71.556484148000038, 34.167959774000053],
            [71.556244350000043, 34.168145280000033],
            [71.556061000000057, 34.168263000000024],
            [71.555918000000077, 34.168303000000037],
            [71.555781000000081, 34.16834300000005],
            [71.555671000000075, 34.168378000000075],
            [71.555560000000071, 34.168417000000034],
            [71.555447000000072, 34.168458000000044],
            [71.555332000000078, 34.168504000000041],
            [71.555215000000032, 34.168553000000031],
            [71.555096000000049, 34.168605000000071],
            [71.554973000000075, 34.16866200000004],
            [71.554846000000055, 34.168724000000054],
            [71.554631000000029, 34.168832000000066],
            [71.554394000000059, 34.168958000000032],
            [71.554168000000061, 34.16908200000006],
            [71.553634000000045, 34.169380000000046],
            [71.553364000000045, 34.169526000000076],
            [71.553106000000071, 34.169660000000079],
            [71.552986000000033, 34.169719000000043],
            [71.552871000000039, 34.169774000000075],
            [71.55275800000004, 34.169826000000057],
            [71.552648000000033, 34.16987400000005],
            [71.552540000000079, 34.16991900000005],
            [71.55243500000006, 34.169961000000058],
            [71.552331000000038, 34.169999000000075],
            [71.552228000000071, 34.170035000000041],
            [71.552126000000044, 34.170069000000069],
            [71.552026000000069, 34.17009900000005],
            [71.551953000000083, 34.170118000000059],
            [71.551875000000052, 34.170134000000076],
            [71.551791000000037, 34.17014800000004],
            [71.551699000000042, 34.170160000000067],
            [71.551608000000044, 34.170168000000047],
            [71.551506000000074, 34.170175000000029],
            [71.55112100000008, 34.170194000000038],
            [71.550964000000079, 34.170205000000067],
            [71.550880000000063, 34.170213000000047],
            [71.55080300000003, 34.170222000000024],
            [71.550732000000039, 34.170233000000053],
            [71.550666000000035, 34.170246000000077],
            [71.550588000000062, 34.170265000000029],
            [71.550516000000073, 34.17028700000003],
            [71.550450000000069, 34.170313000000078],
            [71.550389000000052, 34.170342000000062],
            [71.55033300000008, 34.170375000000035],
            [71.550281000000041, 34.170411000000058],
            [71.550250197000082, 34.170437215000049],
            [71.550234000000046, 34.170451000000071],
            [71.550191000000041, 34.170495000000074],
            [71.550166000000047, 34.170526000000052],
            [71.550143000000048, 34.17056000000008],
            [71.550120000000049, 34.170600000000036],
            [71.550098000000048, 34.170645000000036],
            [71.549988919000043, 34.170745384000043],
            [71.549841762000028, 34.170771913000067],
            [71.549645527000052, 34.170806024000058],
            [71.549462576000053, 34.170814718000031],
            [71.548965574000079, 34.17090642900007],
            [71.54859020300006, 34.170969684000056],
            [71.548299260000078, 34.17098582400007],
            [71.548050594000074, 34.170954315000074],
            [71.547903782000049, 34.170935712000073],
            [71.547691927000074, 34.170890450000059],
            [71.547501083000043, 34.170904440000072],
            [71.547042877000081, 34.171073913000043],
            [71.546838851000075, 34.171165896000048],
            [71.546746485000028, 34.171239429000025],
            [71.546433782000065, 34.171363052000061],
            [71.545877747000077, 34.171575606000033],
            [71.545599916000072, 34.17151679400007],
            [71.54519917500005, 34.171367755000063],
            [71.545024082000054, 34.171345745000053],
            [71.544826879000084, 34.171385272000066],
            [71.544527501000061, 34.171394903000078],
            [71.543955758000038, 34.171497162000037],
            [71.543667000000084, 34.171519000000046],
            [71.543555000000083, 34.171527000000026],
            [71.543451000000061, 34.171537000000058],
            [71.543354000000079, 34.171549000000027],
            [71.543262000000084, 34.171562000000051],
            [71.543158000000062, 34.171582000000058],
            [71.54306100000008, 34.171604000000059],
            [71.542969000000085, 34.17163000000005],
            [71.542883000000074, 34.171659000000034],
            [71.542801000000054, 34.171692000000064],
            [71.542724000000078, 34.171729000000028],
            [71.541823071000067, 34.172059774000047],
            [71.541697433000081, 34.172105113000043],
            [71.541282616000046, 34.172254808000048],
            [71.540881243000058, 34.172265019000065],
            [71.540424839000082, 34.172175251000056],
            [71.539952690000064, 34.172158326000044],
            [71.539638380000042, 34.172198125000079],
            [71.539302596000084, 34.172346421000043],
            [71.539023603000032, 34.172566290000077],
            [71.538806206000061, 34.172796091000066],
            [71.538730663000081, 34.173025265000035],
            [71.538785373000053, 34.173276226000041],
            [71.539002931000084, 34.173479034000025],
            [71.539194894000047, 34.173676778000072],
            [71.539140004000046, 34.173851695000053],
            [71.538630130000058, 34.174068371000033],
            [71.538281149000056, 34.174168959000042],
            [71.53804314000007, 34.174143793000042],
            [71.537907241000084, 34.174087219000057],
            [71.537732342000083, 34.174124302000052],
            [71.537592327000084, 34.17425484100005],
            [71.537398538000048, 34.17444243500006],
            [71.536980114000073, 34.174636508000049],
            [71.536047589000077, 34.175009569000053],
            [71.53567373900006, 34.175210317000051],
            [71.535309716000029, 34.175405787000045],
            [71.534721725000054, 34.17574415200005],
            [71.53416277000008, 34.175962782000056],
            [71.533792095000081, 34.176036937000049],
            [71.533375015000047, 34.176072923000049],
            [71.533191000000045, 34.176053000000024],
            [71.53310700000003, 34.175987000000077],
            [71.533020000000079, 34.175915000000032],
            [71.532876000000044, 34.175787000000071],
            [71.53271600000005, 34.175637000000052],
            [71.532628712000076, 34.175551470000073],
            [71.532567000000029, 34.175491000000079],
            [71.532224000000042, 34.175149000000033],
            [71.532061000000056, 34.174992000000032],
            [71.531920000000071, 34.174861000000078],
            [71.531790000000058, 34.174747000000025],
            [71.531715000000077, 34.174682000000075],
            [71.531637000000046, 34.174611000000027],
            [71.531468000000075, 34.174452000000031],
            [71.531310000000076, 34.174296000000027],
            [71.530949000000078, 34.173930000000041],
            [71.530778000000055, 34.173762000000067],
            [71.530631479000078, 34.173624483000026],
            [71.53049100000004, 34.173504000000037],
            [71.530396000000053, 34.173426000000063],
            [71.530304000000058, 34.173356000000069],
            [71.53021300000006, 34.173291000000063],
            [71.530124000000058, 34.173233000000039],
            [71.530036000000052, 34.173180000000059],
            [71.529948000000047, 34.173133000000064],
            [71.529861000000039, 34.173091000000056],
            [71.529774000000032, 34.173055000000033],
            [71.529713000000072, 34.173032000000035],
            [71.529650000000061, 34.173012000000028],
            [71.529586000000052, 34.172993000000076],
            [71.529521000000045, 34.172976000000062],
            [71.529454000000044, 34.172960000000046],
            [71.529386000000045, 34.172947000000079],
            [71.529316000000051, 34.172935000000052],
            [71.52924500000006, 34.172925000000077],
            [71.529172000000074, 34.172917000000041],
            [71.529097000000036, 34.172911000000056],
            [71.529021000000057, 34.172907000000066],
            [71.528942000000029, 34.172904000000074],
            [71.528779000000043, 34.172904000000074],
            [71.528608000000077, 34.172910000000059],
            [71.528463000000045, 34.172921000000031],
            [71.528310000000033, 34.17293600000005],
            [71.528149000000042, 34.172956000000056],
            [71.527975000000083, 34.17298100000005],
            [71.527817000000084, 34.173006000000044],
            [71.527642000000071, 34.173037000000079],
            [71.527407372000084, 34.173080262000042],
            [71.527040000000056, 34.173148000000026],
            [71.526809000000071, 34.173189000000036],
            [71.526577000000032, 34.173226000000057],
            [71.52637100000004, 34.173254000000043],
            [71.526257000000044, 34.173269000000062],
            [71.52614200000005, 34.173287000000073],
            [71.526024000000064, 34.173308000000077],
            [71.525903000000028, 34.173331000000076],
            [71.525779000000057, 34.173358000000064],
            [71.525652000000036, 34.173387000000048],
            [71.525521000000083, 34.173420000000078],
            [71.525384000000031, 34.173456000000044],
            [71.525143000000071, 34.173525000000041],
            [71.524875000000065, 34.173607000000061],
            [71.524612000000047, 34.173691000000076],
            [71.523942000000034, 34.173909000000037],
            [71.523749000000066, 34.173970000000054],
            [71.523576000000048, 34.174023000000034],
            [71.523382000000083, 34.17408000000006],
            [71.523200000000031, 34.17412900000005],
            [71.52302700000007, 34.174173000000053],
            [71.522862000000032, 34.174211000000071],
            [71.522669000000064, 34.174250000000029],
            [71.522483000000079, 34.174281000000065],
            [71.522303000000079, 34.174306000000058],
            [71.522128000000066, 34.174323000000072],
            [71.521958000000041, 34.174333000000047],
            [71.521791000000064, 34.174336000000039],
            [71.521628000000078, 34.174331000000052],
            [71.521567193000067, 34.174327960000028],
            [71.521548000000053, 34.174327000000062],
            [71.521468000000084, 34.17432000000008],
            [71.521359000000075, 34.174309000000051],
            [71.521248000000071, 34.174294000000032],
            [71.521135000000072, 34.174277000000075],
            [71.521019000000081, 34.174258000000066],
            [71.52090000000004, 34.174235000000067],
            [71.520778000000064, 34.174210000000073],
            [71.520652000000041, 34.17418200000003],
            [71.520521000000031, 34.174150000000054],
            [71.520271000000037, 34.17408400000005],
            [71.519992000000059, 34.174005000000079],
            [71.519729000000041, 34.173926000000051],
            [71.519068000000061, 34.173723000000052],
            [71.518889000000058, 34.173666000000026],
            [71.518702000000076, 34.173600000000079],
            [71.518508000000054, 34.173525000000041],
            [71.518301000000065, 34.173440000000028],
            [71.518118000000072, 34.173360000000059],
            [71.517915000000073, 34.173268000000064],
            [71.517226000000051, 34.172946000000024],
            [71.516961000000038, 34.172824000000048],
            [71.516691000000037, 34.172707000000059],
            [71.516567000000066, 34.172655000000077],
            [71.516447000000028, 34.172608000000025],
            [71.516310000000033, 34.172556000000043],
            [71.516177000000084, 34.172509000000048],
            [71.516048000000069, 34.172466000000043],
            [71.515922000000046, 34.172428000000025],
            [71.515798000000075, 34.172394000000054],
            [71.515677000000039, 34.172364000000073],
            [71.515557000000058, 34.172337000000027],
            [71.515439000000072, 34.172315000000026],
            [71.51519200000007, 34.17227500000007],
            [71.514942000000076, 34.172241000000042],
            [71.514689000000033, 34.172213000000056],
            [71.514436000000046, 34.172192000000052],
            [71.514181000000065, 34.172176000000036],
            [71.513927000000081, 34.172167000000059],
            [71.51367300000004, 34.172164000000066],
            [71.513421000000051, 34.172167000000059],
            [71.513171000000057, 34.172177000000033],
            [71.512924000000055, 34.172192000000052],
            [71.512680000000046, 34.172214000000054],
            [71.512441000000081, 34.17224200000004],
            [71.512205000000051, 34.172277000000065],
            [71.511974000000066, 34.172317000000078],
            [71.511749000000066, 34.172363000000075],
            [71.511529000000053, 34.172415000000058],
            [71.511418000000049, 34.172445000000039],
            [71.511306000000047, 34.172480000000064],
            [71.511193000000048, 34.172520000000077],
            [71.511078000000055, 34.172564000000079],
            [71.510962000000063, 34.172613000000069],
            [71.510844000000077, 34.172667000000047],
            [71.510752543000081, 34.172711595000067],
            [71.510723000000041, 34.172726000000068],
            [71.51059900000007, 34.172790000000077],
            [71.510481000000084, 34.172855000000027],
            [71.510359000000051, 34.172926000000075],
            [71.510231000000033, 34.173003000000051],
            [71.510096000000033, 34.173087000000066],
            [71.509826000000032, 34.173261000000025],
            [71.509303000000045, 34.173611000000051],
            [71.509085000000084, 34.173755000000028],
            [71.508856000000037, 34.17390000000006],
            [71.508648000000051, 34.174024000000031],
            [71.508525000000077, 34.174094000000025],
            [71.508407000000034, 34.174158000000034],
            [71.508291000000042, 34.174217000000056],
            [71.508178000000044, 34.17427200000003],
            [71.50806700000004, 34.174322000000075],
            [71.507957000000033, 34.174368000000072],
            [71.507849000000078, 34.174409000000026],
            [71.507742000000064, 34.174447000000043],
            [71.507634000000053, 34.174482000000069],
            [71.507523000000049, 34.174514000000045],
            [71.50741000000005, 34.17454500000008],
            [71.507292000000064, 34.174574000000064],
            [71.507172000000082, 34.174601000000052],
            [71.507047000000057, 34.174626000000046],
            [71.506919000000039, 34.174649000000045],
            [71.506785000000036, 34.174672000000044],
            [71.506654000000083, 34.174691000000053],
            [71.506518000000028, 34.174710000000061],
            [71.506220000000042, 34.174744000000032],
            [71.505910000000029, 34.17477400000007],
            [71.50542213600005, 34.174816528000065],
            [71.505302000000029, 34.17482700000005],
            [71.505049000000042, 34.174851000000046],
            [71.504786000000081, 34.174878000000035],
            [71.504551000000049, 34.174907000000076],
            [71.504414000000054, 34.174926000000028],
            [71.504283000000044, 34.174947000000031],
            [71.504157000000077, 34.174969000000033],
            [71.504035000000044, 34.174992000000032],
            [71.503917000000058, 34.175016000000028],
            [71.503802000000064, 34.175043000000073],
            [71.503690000000063, 34.175070000000062],
            [71.503581000000054, 34.175100000000043],
            [71.503442000000064, 34.175141000000053],
            [71.503298000000029, 34.175187000000051],
            [71.50314700000007, 34.175239000000033],
            [71.502986000000078, 34.175298000000055],
            [71.502693000000079, 34.175411000000054],
            [71.50202500000006, 34.175678000000062],
            [71.501862000000074, 34.175740000000076],
            [71.50171400000005, 34.175795000000051],
            [71.501580000000047, 34.175843000000043],
            [71.501451000000031, 34.175886000000048],
            [71.501328000000058, 34.175925000000063],
            [71.501209000000074, 34.175960000000032],
            [71.501127000000054, 34.175982000000033],
            [71.501042000000041, 34.17600200000004],
            [71.500955000000033, 34.176020000000051],
            [71.500865000000033, 34.176036000000067],
            [71.500773000000038, 34.176050000000032],
            [71.50067800000005, 34.176062000000059],
            [71.50058000000007, 34.176072000000033],
            [71.500479000000041, 34.17608000000007],
            [71.500375000000076, 34.176087000000052],
            [71.500268000000062, 34.176091000000042],
            [71.500158000000056, 34.176094000000035],
            [71.500045000000057, 34.176095000000032],
            [71.499929000000066, 34.176094000000035],
            [71.499810000000082, 34.176091000000042],
            [71.499559000000033, 34.176079000000072],
            [71.499333000000036, 34.176063000000056],
            [71.499094000000071, 34.176041000000055],
            [71.49884000000003, 34.176014000000066],
            [71.498565000000042, 34.175980000000038],
            [71.498316000000045, 34.175947000000065],
            [71.498038000000065, 34.175907000000052],
            [71.497108000000082, 34.175766000000067],
            [71.496766000000036, 34.175716000000079],
            [71.496426000000042, 34.175670000000025],
            [71.496128000000056, 34.175634000000059],
            [71.495961000000079, 34.175618000000043],
            [71.495804000000078, 34.175604000000078],
            [71.495656000000054, 34.175594000000046],
            [71.495514000000071, 34.175586000000067],
            [71.495380000000068, 34.175582000000077],
            [71.495253000000048, 34.175581000000079],
            [71.495131000000072, 34.175583000000074],
            [71.495098816000052, 34.175584399000059],
            [71.495016000000078, 34.175588000000062],
            [71.494877000000031, 34.175599000000034],
            [71.494747000000075, 34.175616000000048],
            [71.494626000000039, 34.175637000000052],
            [71.49451300000004, 34.175663000000043],
            [71.494460000000061, 34.175678000000062],
            [71.494408000000078, 34.175695000000076],
            [71.494359000000031, 34.17571300000003],
            [71.494311000000039, 34.175732000000039],
            [71.494266000000039, 34.175752000000045],
            [71.494222000000036, 34.175774000000047],
            [71.494180000000028, 34.175797000000046],
            [71.494140000000073, 34.175822000000039],
            [71.494102000000055, 34.17584800000003],
            [71.494066000000032, 34.175875000000076],
            [71.494032000000061, 34.17590400000006],
            [71.494000000000028, 34.175934000000041],
            [71.49396900000005, 34.175965000000076],
            [71.493941000000063, 34.17599800000005],
            [71.493914000000075, 34.176032000000077],
            [71.493889000000081, 34.176068000000043],
            [71.493866000000082, 34.176105000000064],
            [71.493845000000078, 34.176144000000079],
            [71.493825000000072, 34.176184000000035],
            [71.493808000000058, 34.176225000000045],
            [71.493792000000042, 34.17626800000005],
            [71.493778000000077, 34.176312000000053],
            [71.493766000000051, 34.17635800000005],
            [71.493756000000076, 34.176405000000045],
            [71.493734000000075, 34.176537000000053],
            [71.493717000000061, 34.176683000000025],
            [71.493705000000034, 34.176838000000032],
            [71.493698000000052, 34.177001000000075],
            [71.493697000000054, 34.177168000000052],
            [71.493701000000044, 34.177336000000025],
            [71.493710000000078, 34.177503000000058],
            [71.49372500000004, 34.177666000000045],
            [71.493745000000047, 34.177820000000054],
            [71.493769000000043, 34.177968000000078],
            [71.493798000000083, 34.178109000000063],
            [71.493831000000057, 34.178241000000071],
            [71.493868000000077, 34.178364000000045],
            [71.493909000000031, 34.178478000000041],
            [71.493931000000032, 34.178531000000078],
            [71.493954000000031, 34.178581000000065],
            [71.493978000000084, 34.178630000000055],
            [71.494003000000077, 34.178675000000055],
            [71.494028000000071, 34.178716000000065],
            [71.49405500000006, 34.178756000000078],
            [71.494085000000041, 34.178795000000036],
            [71.494116000000076, 34.178832000000057],
            [71.494150000000047, 34.178869000000077],
            [71.49418600000007, 34.178904000000045],
            [71.494224000000031, 34.178938000000073],
            [71.494265000000041, 34.178970000000049],
            [71.494308000000046, 34.179002000000025],
            [71.494353000000046, 34.179032000000063],
            [71.494401000000039, 34.179062000000044],
            [71.494451000000083, 34.179090000000031],
            [71.494504000000063, 34.179118000000074],
            [71.494559000000038, 34.179145000000062],
            [71.494617000000062, 34.179170000000056],
            [71.494678000000079, 34.17919500000005],
            [71.494785000000036, 34.179235000000062],
            [71.494901000000084, 34.17927300000008],
            [71.49502700000005, 34.179309000000046],
            [71.495098816000052, 34.179327600000079],
            [71.49516600000004, 34.179345000000069],
            [71.495295000000056, 34.17937500000005],
            [71.495441000000028, 34.179407000000026],
            [71.495980000000031, 34.179515000000038],
            [71.496012529000041, 34.17952198100005],
            [71.496199000000047, 34.179562000000033],
            [71.496421000000055, 34.179615000000069],
            [71.496522000000084, 34.179641000000061],
            [71.496616000000074, 34.179669000000047],
            [71.496724000000029, 34.179702000000077],
            [71.496825000000058, 34.179737000000046],
            [71.496921000000043, 34.179774000000066],
            [71.497012000000041, 34.179812000000027],
            [71.497098000000051, 34.179852000000039],
            [71.497178000000076, 34.179894000000047],
            [71.497255000000052, 34.17993800000005],
            [71.497327000000041, 34.179984000000047],
            [71.497452000000067, 34.180071000000055],
            [71.497581000000082, 34.180166000000042],
            [71.49771400000003, 34.180269000000067],
            [71.49785200000008, 34.180382000000066],
            [71.49799100000007, 34.180499000000054],
            [71.49814200000003, 34.180630000000065],
            [71.498574000000076, 34.181018000000051],
            [71.498718000000054, 34.181152000000054],
            [71.498874000000058, 34.181306000000063],
            [71.49902000000003, 34.181456000000026],
            [71.499289047000048, 34.181735996000043],
            [71.499364000000071, 34.181814000000031],
            [71.49953800000003, 34.181990000000042],
            [71.49962400000004, 34.182074000000057],
            [71.49970600000006, 34.182151000000033],
            [71.499785000000031, 34.182223000000079],
            [71.499863000000062, 34.182291000000077],
            [71.500265003000038, 34.182633739000039],
            [71.500270000000057, 34.182638000000054],
            [71.500415000000032, 34.182759000000033],
            [71.500438882000083, 34.182778393000035],
            [71.500418749000062, 34.182779255000071],
            [71.501470174000076, 34.188459327000032],
            [71.49953002500007, 34.188332108000054],
            [71.496962719000066, 34.188168572000052],
            [71.496660838000082, 34.188149903000067],
            [71.494442019000076, 34.188012687000025],
            [71.492352728000071, 34.187911962000044],
            [71.489828517000035, 34.187777505000042],
            [71.488789697000072, 34.187691681000047],
            [71.488969603000044, 34.188080493000029],
            [71.489065967000045, 34.188283150000075],
            [71.48917915800007, 34.188456393000024],
            [71.489354043000048, 34.18872066800003],
            [71.489461494000068, 34.188796598000067],
            [71.489511589000074, 34.188823142000047],
            [71.489971444000048, 34.189084513000068],
            [71.490555138000047, 34.18943190300007],
            [71.490969755000037, 34.189672284000039],
            [71.491950562000056, 34.190199111000027],
            [71.492029027000058, 34.190241959000048],
            [71.492062798000063, 34.19031467700006],
            [71.492038296000032, 34.190399089000039],
            [71.491977699000074, 34.190649070000063],
            [71.491982204000067, 34.19076620800007],
            [71.492000975000053, 34.190827932000047],
            [71.492074137000031, 34.190897473000064],
            [71.492382952000071, 34.191257295000071],
            [71.49242020500003, 34.191336479000029],
            [71.492587164000042, 34.19222256300003],
            [71.49263815900008, 34.192477574000065],
            [71.492695174000062, 34.192618627000058],
            [71.492785040000058, 34.192758333000029],
            [71.492968307000069, 34.193025180000063],
            [71.493035853000038, 34.193265878000034],
            [71.493052998000053, 34.193360906000066],
            [71.493103566000059, 34.193641193000076],
            [71.493091897000056, 34.193666970000038],
            [71.493049800000051, 34.193689017000054],
            [71.492939609000075, 34.193704296000078],
            [71.492862623000065, 34.193733168000051],
            [71.49285330400005, 34.193753832000027],
            [71.492799778000062, 34.19458428300004],
            [71.492748402000075, 34.194918181000048],
            [71.492722747000073, 34.194998610000027],
            [71.492566611000029, 34.195275301000038],
            [71.492553537000049, 34.195335653000029],
            [71.492476178000061, 34.195676275000039],
            [71.49184086200006, 34.195546247000038],
            [71.490821795000045, 34.195375739000042],
            [71.489534723000077, 34.195160388000033],
            [71.489515333000043, 34.195156835000034],
            [71.489384747000031, 34.195590027000037],
            [71.489331535000076, 34.195734696000045],
            [71.489299457000072, 34.195800523000059],
            [71.489265352000075, 34.195828782000035],
            [71.489210510000078, 34.195850537000069],
            [71.48906243700003, 34.195833363000077],
            [71.489006910000057, 34.19584695900005],
            [71.488976150000042, 34.195868277000045],
            [71.488890336000054, 34.19610192600004],
            [71.488814170000069, 34.196245919000035],
            [71.488781841000048, 34.196270870000035],
            [71.48861626200005, 34.196315148000053],
            [71.488461315000052, 34.196362902000033],
            [71.48843296900003, 34.196379173000025],
            [71.488413594000065, 34.196411771000044],
            [71.488360900000032, 34.197172917000046],
            [71.488343864000058, 34.197509754000066],
            [71.48833333500005, 34.197717932000046],
            [71.485288001000072, 34.200576169000044],
            [71.484838798000055, 34.20099777300004],
            [71.484276497000053, 34.201525528000047],
            [71.480867184000033, 34.204353734000051],
            [71.477672521000045, 34.207003877000034],
            [71.475724828000068, 34.208619592000048],
            [71.469501125000079, 34.211492913000029],
            [71.477475000000084, 34.219024000000047],
            [71.494687058000068, 34.225643484000045],
            [71.491847116000031, 34.23133785400006],
            [71.489687837000076, 34.235667426000077],
            [71.487495956000032, 34.240062369000043],
            [71.486957927000049, 34.241141172000027],
            [71.486957870000083, 34.241141285000026],
            [71.486752000000081, 34.241553000000067],
            [71.488118519000068, 34.243147215000079],
            [71.488620648000051, 34.243733011000074],
            [71.488642090000042, 34.243718796000053],
            [71.489463350000051, 34.244675931000074],
            [71.490234801000042, 34.245575016000032],
            [71.490889813000081, 34.246338397000045],
            [71.491633614000079, 34.247198964000063],
            [71.49197609600003, 34.247604402000036],
            [71.492894955000054, 34.248675283000068],
            [71.494717282000067, 34.25079910900007],
            [71.495831624000061, 34.249904640000068],
            [71.497707682000055, 34.248398750000035],
            [71.499644370000055, 34.246844193000072],
            [71.50191469400005, 34.245021830000042],
            [71.503701413000044, 34.243587652000031],
            [71.505464665000034, 34.242172310000058],
            [71.507886958000029, 34.240227964000042],
            [71.507961425000076, 34.240168190000077],
            [71.508009284000082, 34.240227964000042],
            [71.509295462000068, 34.241834363000066],
            [71.509934184000031, 34.242617062000079],
            [71.512524474000031, 34.245867300000043],
            [71.513263512000037, 34.24679033700005],
            [71.514153234000048, 34.247369787000025],
            [71.514713477000043, 34.247734656000034],
            [71.516051698000069, 34.24860620000004],
            [71.51725396300003, 34.249389200000053],
            [71.521122219000063, 34.251908481000044],
            [71.528502544000048, 34.256715070000041],
            [71.531242973000076, 34.259454532000063],
            [71.535072944000035, 34.26309359000004],
            [71.535704015000078, 34.263914002000035],
            [71.536453606000066, 34.26466332900003],
            [71.537056530000029, 34.267376917000036],
            [71.537755892000064, 34.270524547000036],
            [71.538250767000079, 34.272751834000076],
            [71.539105950000078, 34.276600769000027],
            [71.53908944300008, 34.276621006000028],
            [71.535854000000029, 34.280587529000059],
            [71.533796280000047, 34.283110212000054],
            [71.533146253000041, 34.283907119000048],
            [71.533149004000052, 34.283948328000065],
            [71.533392028000037, 34.287589197000045],
            [71.533601803000067, 34.290731956000059],
            [71.533748208000077, 34.292925328000024],
            [71.533810607000078, 34.293860153000026],
            [71.534228440000049, 34.29553323500005],
            [71.535215943000082, 34.299487382000052],
            [71.535798972000066, 34.301821937000057],
            [71.53496503000008, 34.304959181000072],
            [71.534237438000048, 34.307696342000042],
            [71.533151266000061, 34.311782461000064],
            [71.536371363000058, 34.314083842000059],
            [71.538725869000075, 34.315766591000056],
            [71.539671254000041, 34.316442251000069],
            [71.53994040300006, 34.316634561000058],
            [71.542424109000081, 34.31840969600006],
            [71.556994626000062, 34.324368574000061],
            [71.560842834000084, 34.333047541000042],
            [71.560842790000038, 34.33304785200005],
            [71.560858881000058, 34.33308405300005],
            [71.560859850000043, 34.333086232000028],
            [71.560859968000045, 34.333086167000033],
            [71.562259493000056, 34.336220637000054],
            [71.563136314000076, 34.33818442300003],
            [71.563143185000058, 34.338199812000028],
            [71.563146771000049, 34.338207843000077],
            [71.56387624100006, 34.339841613000033],
            [71.564544728000044, 34.341315097000063],
            [71.564960326000062, 34.342269602000044],
            [71.566329870000061, 34.343635624000058],
            [71.569121952000046, 34.346420522000074],
            [71.572029728000075, 34.349320818000024],
            [71.574104931000079, 34.351390682000044],
            [71.575611337000055, 34.352893213000073],
            [71.576243397000042, 34.353523647000031],
            [71.579918583000051, 34.35561904900004],
            [71.582262150000076, 34.35695523000004],
            [71.584285772000044, 34.358108995000066],
            [71.585527576000061, 34.358817008000074],
            [71.587771337000049, 34.362653200000068],
            [71.590176771000074, 34.366765803000078],
            [71.595495791000076, 34.364419536000071],
            [71.604250663000073, 34.360557684000071],
            [71.606575840000062, 34.359532028000046],
            [71.606730187000039, 34.359463944000026],
            [71.606962177000071, 34.359343073000048],
            [71.608781693000083, 34.358395077000068],
            [71.613019647000044, 34.356187036000051],
            [71.61406437800008, 34.355642715000045],
            [71.616578048000065, 34.354333053000062],
            [71.616908432000059, 34.354160918000048],
            [71.620444268000028, 34.35231869200004],
            [71.621815689000073, 34.351604160000079],
            [71.623283516000072, 34.350839399000051],
            [71.623425481000083, 34.35224317400008],
            [71.623658852000062, 34.354550783000036],
            [71.623921657000039, 34.35714944700004],
            [71.62405203000003, 34.358438594000063],
            [71.624163458000055, 34.359540412000058],
            [71.624243353000054, 34.36033042400004],
            [71.624340086000075, 34.361286938000035],
            [71.624476777000041, 34.362638554000057],
            [71.624581889000069, 34.363677920000043],
            [71.624646172000041, 34.364487164000025],
            [71.624726710000061, 34.365909060000035],
            [71.624789089000046, 34.367010372000038],
            [71.62487888000004, 34.368595621000054],
            [71.624932500000057, 34.369542284000033],
            [71.625002463000044, 34.370777486000065],
            [71.62510611700003, 34.372607495000068],
            [71.62521412600006, 34.374514400000066],
            [71.625299505000044, 34.376021777000062],
            [71.627031857000077, 34.37794466500003],
            [71.628732011000068, 34.379831814000056],
            [71.630108230000076, 34.381359400000065],
            [71.631268211000076, 34.38264696400006],
            [71.63232371600003, 34.382646553000029],
            [71.635942585000066, 34.382645142000058],
            [71.639069432000042, 34.382643922000057],
            [71.641626315000053, 34.382642925000027],
            [71.645178270000031, 34.382641540000066],
            [71.646283444000062, 34.383339381000042],
            [71.647431877000031, 34.384071811000069],
            [71.64906039400006, 34.385092831000065],
            [71.650874840000029, 34.386238528000035],
            [71.653308863000063, 34.387775444000056],
            [71.657769630000075, 34.390592108000078],
            [71.657393063000029, 34.393499096000028],
            [71.65669052100003, 34.398922526000035],
            [71.656417916000066, 34.401026963000049],
            [71.656194466000045, 34.402751935000026],
            [71.655794542000081, 34.405839241000024],
            [71.656468972000084, 34.407120256000042],
            [71.659590431000083, 34.413049172000058],
            [71.661008717000072, 34.415743071000065],
            [71.661808797000049, 34.417262747000052],
            [71.661843238000074, 34.417328164000025],
            [71.662261879000084, 34.41812333300004],
            [71.662429178000082, 34.418437872000027],
            [71.664091303000077, 34.419574798000042],
            [71.66620935800006, 34.42102506100008],
            [71.668907309000076, 34.422872386000051],
            [71.670200867000062, 34.423758103000068],
            [71.670634566000047, 34.424055063000026],
            [71.671077337000042, 34.424358235000057],
            [71.672408572000052, 34.425269750000041],
            [71.672440008000081, 34.425291275000063],
            [71.672617670000079, 34.425412923000067],
            [71.67327526300005, 34.425863186000072],
            [71.675061089000053, 34.427048353000032],
            [71.680841783000062, 34.429749982000033],
            [71.683414130000074, 34.430952178000041],
            [71.684747877000063, 34.431575509000027],
            [71.686270585000045, 34.432287152000072],
            [71.688193242000068, 34.433185713000057],
            [71.68941202700006, 34.433755317000077],
            [71.691122818000053, 34.434554861000038],
            [71.693403581000041, 34.435620784000037],
            [71.695447812000054, 34.436576163000041],
            [71.69760788800005, 34.437585682000076],
            [71.698766771000066, 34.438127291000058],
            [71.701040487000057, 34.439225692000036],
            [71.703182408000032, 34.440190955000048],
            [71.704761036000036, 34.440928732000032],
            [71.704859040000031, 34.440974535000066],
            [71.704761036000036, 34.441367635000063],
            [71.704340580000064, 34.443054108000069],
            [71.704501304000075, 34.443046813000024],
            [71.704555711000069, 34.443078964000051],
            [71.704761036000036, 34.443200299000068],
            [71.705520583000066, 34.443649145000052],
            [71.706168250000076, 34.444031878000033],
            [71.706687516000045, 34.444338733000052],
            [71.707104270000059, 34.444585010000026],
            [71.707558235000079, 34.44485327700005],
            [71.708012201000031, 34.445121543000027],
            [71.711080894000077, 34.446934958000043],
            [71.715302691000034, 34.449429788000032],
            [71.721759083000052, 34.449719304000041],
            [71.723607960000038, 34.449802211000076],
            [71.726632734000077, 34.449937848000047],
            [71.727714090000063, 34.449992133000023],
            [71.727814332000037, 34.450026063000053],
            [71.729510193000067, 34.450600074000079],
            [71.732956196000032, 34.451766470000052],
            [71.735775193000052, 34.452720638000073],
            [71.73735126300005, 34.453254103000063],
            [71.73881151900008, 34.453754505000063],
            [71.74020697900005, 34.454220699000075],
            [71.742815583000038, 34.455103654000027],
            [71.747031657000036, 34.456530701000077],
            [71.748520129000042, 34.457034516000078],
            [71.750696000000062, 34.457771000000037],
            [71.751956620000044, 34.45539597100003],
            [71.752828104000059, 34.453791329000069],
            [71.752864221000038, 34.453724828000077],
            [71.754842921000034, 34.449965438000049],
            [71.755798642000059, 34.448155774000043],
            [71.757904000000053, 34.444188000000054],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "69",
      properties: { name: "Chitral", count: 224 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.594010528000069, 36.916017012000054],
            [73.63847783600005, 36.911570489000042],
            [73.650309748000041, 36.915628554000079],
            [73.650309748000041, 36.915627554000025],
            [73.65072039100005, 36.915519366000069],
            [73.65075362500005, 36.915465785000038],
            [73.655026949000046, 36.908576062000066],
            [73.662568969000063, 36.896414269000047],
            [73.657010125000056, 36.905377368000075],
            [73.662569483000084, 36.896412633000068],
            [73.676235248000069, 36.880964790000064],
            [73.68395905400007, 36.865516919000072],
            [73.697029866000037, 36.854822045000049],
            [73.720796089000032, 36.842344232000073],
            [73.748127401000033, 36.842344363000052],
            [73.76595125700004, 36.839968462000058],
            [73.78496407800003, 36.834620584000049],
            [73.790905929000076, 36.822737683000071],
            [73.793282814000065, 36.812042758000075],
            [73.804571204000069, 36.808478333000039],
            [73.82655455400004, 36.808478438000066],
            [73.839032403000033, 36.801349040000048],
            [73.856856085000061, 36.779959252000026],
            [73.861608905000082, 36.763917370000058],
            [73.868144779000033, 36.755004454000073],
            [73.866956640000069, 36.73896254400006],
            [73.861014551000039, 36.724702600000057],
            [73.860895664000054, 36.724613435000037],
            [73.86077464300007, 36.724522669000066],
            [73.858754078000061, 36.723007245000076],
            [73.851508551000052, 36.71757309700007],
            [73.844378567000035, 36.714008584000055],
            [73.844318424000051, 36.713983815000063],
            [73.834277598000028, 36.709848561000058],
            [73.809917820000067, 36.715790409000078],
            [73.789717040000085, 36.724702260000072],
            [73.775457170000038, 36.728267171000027],
            [73.753473283000062, 36.724108091000062],
            [73.734460365000075, 36.718761032000032],
            [73.715447550000079, 36.724701906000064],
            [73.704752623000047, 36.724701855000035],
            [73.687522725000065, 36.722919784000055],
            [73.687398319000067, 36.722805742000048],
            [73.673262703000034, 36.709847793000051],
            [73.673107752000078, 36.709820129000036],
            [73.656626790000075, 36.706877732000066],
            [73.606690067000045, 36.709682185000077],
            [73.603747180000084, 36.709847462000027],
            [73.578792433000046, 36.718760290000034],
            [73.568692660000067, 36.73599013900008],
            [73.536013841000056, 36.731237012000065],
            [73.506900530000053, 36.730048880000027],
            [73.476004274000047, 36.733613712000079],
            [73.449267528000064, 36.741337538000039],
            [73.429660755000043, 36.751437385000031],
            [73.408865963000039, 36.758567243000073],
            [73.374404694000077, 36.757973083000024],
            [73.354797785000073, 36.753220017000046],
            [73.32687235700007, 36.740147962000037],
            [73.293005487000073, 36.728858868000032],
            [73.26805108700006, 36.721134795000069],
            [73.239531745000079, 36.716975683000044],
            [73.213983425000038, 36.717569558000037],
            [73.193782118000058, 36.723511426000073],
            [73.179522743000064, 36.726482341000064],
            [73.165262863000066, 36.728858259000049],
            [73.152191464000055, 36.730047189000061],
            [73.15199448900006, 36.729910162000067],
            [73.124860477000084, 36.711034172000041],
            [73.124711134000052, 36.710980831000029],
            [73.10822403700007, 36.705092128000047],
            [73.081487161000041, 36.698556039000039],
            [73.06188028400004, 36.697367953000025],
            [73.061757187000069, 36.697244862000048],
            [73.049402756000063, 36.684890968000047],
            [73.04940270600008, 36.67944723200003],
            [73.049402647000079, 36.673008038000035],
            [73.057720846000052, 36.646271237000065],
            [73.071386051000047, 36.624287433000063],
            [73.074950890000082, 36.609433538000076],
            [73.072683952000034, 36.606899930000054],
            [73.054749323000067, 36.586855576000062],
            [73.050796760000082, 36.570650079000075],
            [73.048807640000064, 36.56249469200003],
            [73.043414627000061, 36.552123901000073],
            [73.041083558000082, 36.547641244000033],
            [73.030983040000081, 36.538134752000076],
            [73.010187558000041, 36.524468734000038],
            [73.009951364000074, 36.524355177000075],
            [72.979291634000049, 36.50961467500008],
            [72.970379190000074, 36.509020636000059],
            [72.970343993000029, 36.508904992000055],
            [72.968024194000066, 36.501283170000079],
            [72.966220093000061, 36.495355698000026],
            [72.96740791600007, 36.476936813000066],
            [72.963636143000031, 36.475050915000054],
            [72.959089936000055, 36.472777798000038],
            [72.959089625000047, 36.472777809000036],
            [72.959089314000039, 36.472777820000033],
            [72.938213694000069, 36.473485187000051],
            [72.924035187000072, 36.473965624000073],
            [72.888385834000076, 36.463270518000058],
            [72.888233357000047, 36.463172496000027],
            [72.888123930000063, 36.463139667000064],
            [72.87148796300005, 36.452444635000063],
            [72.863169729000049, 36.433431782000071],
            [72.860792230000072, 36.40431910500007],
            [72.857226985000068, 36.388276262000034],
            [72.848344438000083, 36.376432715000078],
            [72.818274913000039, 36.364640770000051],
            [72.81814659500003, 36.364562527000032],
            [72.793914444000052, 36.349786742000049],
            [72.759453474000054, 36.327208712000072],
            [72.75924083600006, 36.32711056800008],
            [72.750031044000082, 36.322859774000051],
            [72.749591510000073, 36.322657367000033],
            [72.749591432000045, 36.322657331000073],
            [72.74959128200004, 36.322657262000064],
            [72.744006029000047, 36.320079361000069],
            [72.739474186000052, 36.318732134000072],
            [72.728364393000049, 36.315429055000038],
            [72.721755936000079, 36.313464468000063],
            [72.721755593000069, 36.313464176000025],
            [72.710944874000063, 36.304275670000038],
            [72.707595529000059, 36.301428914000041],
            [72.707168094000053, 36.301065618000052],
            [72.700219894000043, 36.293486524000059],
            [72.70020134400005, 36.293466289000037],
            [72.70020069800006, 36.293465584000046],
            [72.694096053000067, 36.286806640000066],
            [72.686372147000043, 36.275517881000042],
            [72.686372075000065, 36.275517776000072],
            [72.686372003000031, 36.275517671000046],
            [72.669736035000028, 36.26660464400004],
            [72.667663576000052, 36.266247297000064],
            [72.667661523000049, 36.266246943000056],
            [72.65860781300006, 36.264685845000031],
            [72.658598501000029, 36.264684240000065],
            [72.65250625200008, 36.263634160000038],
            [72.652480572000059, 36.263637914000071],
            [72.622203383000056, 36.268981404000044],
            [72.593684584000073, 36.269575264000025],
            [72.592172090000076, 36.268608934000042],
            [72.592147800000077, 36.268593415000055],
            [72.572294606000071, 36.255909243000076],
            [72.570331520000082, 36.25394639700005],
            [72.570328102000076, 36.253944102000048],
            [72.549121000000071, 36.232737000000043],
            [72.549088242000039, 36.232620048000058],
            [72.541992379000078, 36.208377381000048],
            [72.54180247100004, 36.206608632000041],
            [72.541802192000034, 36.206606030000046],
            [72.541708139000036, 36.205729808000058],
            [72.540963950000048, 36.198776629000065],
            [72.540210239000032, 36.191740472000049],
            [72.540381312000079, 36.190628146000051],
            [72.540381363000051, 36.190627817000063],
            [72.540524976000029, 36.18968681900003],
            [72.540525872000046, 36.189686640000048],
            [72.540549476000081, 36.189532676000056],
            [72.541692090000083, 36.182105375000049],
            [72.54169425200007, 36.18209132000004],
            [72.542586082000071, 36.176292575000048],
            [72.542586081000081, 36.176201977000062],
            [72.542585977000044, 36.164894417000028],
            [72.542585935000034, 36.160250670000039],
            [72.542585832000043, 36.148961738000025],
            [72.54020973400003, 36.136484800000062],
            [72.531891188000031, 36.125195828000074],
            [72.523572620000039, 36.111529869000037],
            [72.523261566000031, 36.108522582000035],
            [72.521790474000056, 36.094299963000026],
            [72.521196337000049, 36.078852052000059],
            [72.521212922000075, 36.078817773000026],
            [72.529854869000076, 36.060956597000029],
            [72.530108108000036, 36.060433204000049],
            [72.531436581000037, 36.059104671000057],
            [72.541879194000046, 36.048661584000058],
            [72.541952083000069, 36.048588691000077],
            [72.542584908000038, 36.047955838000064],
            [72.543273033000048, 36.047646187000055],
            [72.554468277000069, 36.042608426000072],
            [72.565757075000079, 36.028942561000065],
            [72.568133406000072, 36.012306672000079],
            [72.56812224600003, 36.012289603000056],
            [72.566939344000048, 36.010480457000028],
            [72.558032834000073, 35.99685871500003],
            [72.55129048100008, 35.977114801000027],
            [72.549714169000083, 35.972498820000055],
            [72.535454544000061, 35.948137897000038],
            [72.53333699600006, 35.945432199000038],
            [72.524759992000043, 35.934472928000048],
            [72.518223945000045, 35.924371956000073],
            [72.509905801000059, 35.902388047000045],
            [72.509971983000071, 35.902368800000033],
            [72.510675050000032, 35.902164331000051],
            [72.510675174000085, 35.902164295000034],
            [72.510558247000063, 35.902033620000054],
            [72.501518070000031, 35.891930464000041],
            [72.501372762000074, 35.891768070000069],
            [72.501344348000032, 35.890574314000048],
            [72.501272622000045, 35.887560939000025],
            [72.501224453000077, 35.885537209000063],
            [72.501180584000053, 35.883694143000071],
            [72.50114377500006, 35.88214769700005],
            [72.50111077400004, 35.880761248000056],
            [72.50095555200005, 35.878492367000035],
            [72.500699166000061, 35.875502639000047],
            [72.500493128000073, 35.873100025000042],
            [72.500301293000064, 35.870863029000077],
            [72.500291664000031, 35.870750745000066],
            [72.50026357400003, 35.870423192000032],
            [72.487789564000082, 35.859889195000051],
            [72.477532593000035, 35.855454172000066],
            [72.465614142000049, 35.849766072000079],
            [72.465335624000033, 35.849633149000056],
            [72.455833777000066, 35.837705325000059],
            [72.45267394900003, 35.833738741000047],
            [72.452307064000081, 35.833278184000051],
            [72.434842694000054, 35.834386094000024],
            [72.425695274000077, 35.836327039000025],
            [72.417634918000033, 35.830785053000056],
            [72.416824779000081, 35.830228033000026],
            [72.41155769900007, 35.817477083000028],
            [72.403518536000036, 35.793637187000058],
            [72.387994518000085, 35.780054194000058],
            [72.369635817000074, 35.766079317000049],
            [72.369421516000045, 35.765916189000052],
            [72.351126119000071, 35.763421117000064],
            [72.333662257000071, 35.765362022000033],
            [72.325135304000071, 35.763230139000029],
            [72.325059134000071, 35.763211095000031],
            [72.323682802000064, 35.762866989000031],
            [72.313148910000052, 35.766747916000043],
            [72.307997266000029, 35.778407075000075],
            [72.30788205500005, 35.778667820000067],
            [72.294022097000038, 35.772845788000041],
            [72.292989125000076, 35.772301755000058],
            [72.292465986000082, 35.772026234000066],
            [72.291291478000062, 35.771407658000044],
            [72.291291587000046, 35.771406783000032],
            [72.291311558000075, 35.771247086000074],
            [72.291311740000083, 35.771245627000042],
            [72.28349829900003, 35.767133403000059],
            [72.262708661000033, 35.771291155000029],
            [72.24219494700003, 35.771567957000059],
            [72.231107175000034, 35.775726801000076],
            [72.216138396000076, 35.77655764900004],
            [72.198396330000037, 35.759647681000047],
            [72.188417437000055, 35.757984605000047],
            [72.173528794000049, 35.769547938000073],
            [72.167251057000044, 35.784832357000028],
            [72.150270431000081, 35.786744987000077],
            [72.150270047000049, 35.786738852000042],
            [72.147121047000041, 35.78708385200008],
            [72.132152047000034, 35.780984852000074],
            [72.129847625000082, 35.780645897000056],
            [72.124615754000047, 35.779974379000066],
            [72.124564568000039, 35.780040865000046],
            [72.121883985000068, 35.773115991000054],
            [72.111903985000083, 35.759255991000032],
            [72.108022985000048, 35.747058991000074],
            [72.105804985000077, 35.733752991000074],
            [72.096656985000038, 35.721001991000037],
            [72.09271018000004, 35.714686533000076],
            [72.092704327000035, 35.714686786000073],
            [72.089723286000037, 35.709916690000057],
            [72.103306286000077, 35.70187769000006],
            [72.111068286000034, 35.690789690000031],
            [72.106355286000053, 35.674988690000077],
            [72.097644980000041, 35.661706829000025],
            [72.09764225400005, 35.661702672000047],
            [72.095266286000083, 35.658079690000079],
            [72.082515286000046, 35.650040690000026],
            [72.073367286000064, 35.643941690000077],
            [72.059784286000081, 35.645605690000025],
            [72.046755286000064, 35.646714690000067],
            [72.034558286000049, 35.642556690000049],
            [72.023747286000059, 35.628418690000046],
            [72.011549286000047, 35.62536969000007],
            [71.998123211000063, 35.620825778000039],
            [71.993530299000042, 35.619271101000038],
            [71.991841221000072, 35.618263598000055],
            [71.977730286000053, 35.60984669000004],
            [71.95915728600005, 35.591827690000059],
            [71.945700372000033, 35.577896189000057],
            [71.945699885000067, 35.577896164000038],
            [71.945653914000047, 35.577848568000036],
            [71.945653660000062, 35.577848305000032],
            [71.935597985000072, 35.567418301000032],
            [71.917855985000074, 35.539975301000027],
            [71.91757989000007, 35.536882873000025],
            [71.917578853000066, 35.536882950000063],
            [71.916469985000049, 35.524462991000064],
            [71.916162604000078, 35.506961138000065],
            [71.916162553000049, 35.506958207000025],
            [71.916162338000049, 35.506945979000079],
            [71.915914985000029, 35.492861991000041],
            [71.923398704000078, 35.480112174000055],
            [71.933376000000067, 35.477064000000041],
            [71.933373650000078, 35.477062621000073],
            [71.933378985000047, 35.47706099100003],
            [71.920626985000069, 35.469575991000056],
            [71.902885985000069, 35.470130991000076],
            [71.892074985000079, 35.465695991000075],
            [71.881263985000032, 35.45349899100006],
            [71.877659985000037, 35.444627991000061],
            [71.881817985000055, 35.429104991000031],
            [71.890015758000061, 35.421882473000039],
            [71.893458000000066, 35.418850000000077],
            [71.893457753000064, 35.418849957000077],
            [71.89345998500005, 35.418847991000064],
            [71.885420985000053, 35.417461991000039],
            [71.878490985000042, 35.415798991000031],
            [71.872669985000073, 35.401383991000046],
            [71.874054985000043, 35.381701991000057],
            [71.86629298500003, 35.368950991000077],
            [71.861887510000031, 35.362189006000051],
            [71.86188990800008, 35.362188372000048],
            [71.85853198500007, 35.357030991000045],
            [71.858258764000084, 35.357039279000048],
            [71.858235646000082, 35.357039980000025],
            [71.840235985000049, 35.357585991000064],
            [71.840234356000053, 35.357586989000026],
            [71.83164298500003, 35.362852991000068],
            [71.831642636000083, 35.362852997000061],
            [71.815564985000037, 35.363129991000051],
            [71.80741645300003, 35.359259640000062],
            [71.806820729000037, 35.358976686000062],
            [71.804475985000067, 35.357862991000047],
            [71.795050985000046, 35.350932991000036],
            [71.777031985000065, 35.332359991000033],
            [71.774536985000054, 35.318499991000067],
            [71.777308985000047, 35.314064991000066],
            [71.773427985000069, 35.305747991000032],
            [71.755963985000051, 35.295768991000045],
            [71.756240985000034, 35.277472991000025],
            [71.75374598500008, 35.262503991000074],
            [71.752082985000072, 35.250029991000076],
            [71.736558993000074, 35.246980996000048],
            [71.726578995000068, 35.245594993000054],
            [71.726543324000033, 35.245521675000077],
            [71.72327043100006, 35.238792859000057],
            [71.721589985000037, 35.235337991000051],
            [71.707451985000034, 35.226744991000032],
            [71.689710985000033, 35.227576991000035],
            [71.670044533000066, 35.223560034000059],
            [71.669260985000051, 35.223399991000065],
            [71.669259985000053, 35.223399991000065],
            [71.650961985000038, 35.227167991000044],
            [71.634816985000043, 35.230397991000075],
            [71.617834985000059, 35.242889991000027],
            [71.602764985000078, 35.255339991000028],
            [71.588533985000083, 35.266459991000033],
            [71.573464985000044, 35.275609991000067],
            [71.558210985000073, 35.288100991000078],
            [71.557858985000053, 35.30036799100003],
            [71.556783985000038, 35.310593991000076],
            [71.562703985000041, 35.318128991000037],
            [71.569349985000031, 35.332655991000024],
            [71.580488985000045, 35.349035991000051],
            [71.591627985000059, 35.364105991000031],
            [71.602533985000036, 35.378405991000079],
            [71.61545198500005, 35.393474991000062],
            [71.621910985000056, 35.405314991000068],
            [71.631598985000039, 35.413387991000036],
            [71.639671985000064, 35.420383991000051],
            [71.646668985000076, 35.430071991000034],
            [71.650598985000045, 35.442731991000073],
            [71.649123972000041, 35.447648953000055],
            [71.649111529000038, 35.447690432000059],
            [71.646667985000079, 35.455835991000072],
            [71.636839985000051, 35.464353991000053],
            [71.617182985000056, 35.477458991000049],
            [71.593595985000036, 35.497770991000039],
            [71.592284985000049, 35.521358991000056],
            [71.602768985000068, 35.53904999100007],
            [71.614563985000075, 35.566568991000054],
            [71.613392973000032, 35.569027702000028],
            [71.61932061400006, 35.570480957000029],
            [71.613356596000074, 35.569104080000045],
            [71.610619985000028, 35.574849991000065],
            [71.595550985000045, 35.582385991000024],
            [71.581019985000069, 35.584538991000045],
            [71.572408985000038, 35.589382991000036],
            [71.551419985000052, 35.596380991000046],
            [71.530430985000066, 35.606068991000029],
            [71.507289985000057, 35.620601991000058],
            [71.499900985000068, 35.631435991000046],
            [71.499900985000068, 35.644540991000042],
            [71.511694985000076, 35.661575991000063],
            [71.52086898500005, 35.670748991000039],
            [71.532587985000077, 35.677649991000067],
            [71.537993985000071, 35.687784991000058],
            [71.542766985000071, 35.69409599100004],
            [71.546572985000068, 35.703484991000039],
            [71.550340985000048, 35.713710991000028],
            [71.544420985000045, 35.727165991000049],
            [71.532042985000032, 35.734700991000068],
            [71.518587985000067, 35.739545991000057],
            [71.518529865000062, 35.739556100000073],
            [71.518480417000035, 35.739564700000074],
            [71.506208985000058, 35.741698991000078],
            [71.487910985000042, 35.757845991000067],
            [71.482528985000044, 35.768609991000062],
            [71.486296985000081, 35.780988991000072],
            [71.489526985000055, 35.796057991000055],
            [71.488988985000049, 35.809512991000076],
            [71.477148985000042, 35.821353991000024],
            [71.471767985000042, 35.828350991000036],
            [71.465308985000036, 35.835347991000049],
            [71.461003985000048, 35.848264991000065],
            [71.454545985000038, 35.851493991000041],
            [71.45024098500005, 35.860643991000074],
            [71.445934985000065, 35.864411991000054],
            [71.440014985000062, 35.874637991000043],
            [71.435377985000059, 35.880950991000077],
            [71.432480985000041, 35.887554991000059],
            [71.423869985000067, 35.894013991000065],
            [71.413643985000078, 35.895089991000077],
            [71.395344985000065, 35.896704991000036],
            [71.384580985000071, 35.902625991000036],
            [71.383504985000059, 35.915004991000046],
            [71.385119985000074, 35.925768991000041],
            [71.384554985000079, 35.938864991000059],
            [71.380831985000043, 35.949455991000036],
            [71.377048985000044, 35.960213991000046],
            [71.341527985000084, 35.96398299100008],
            [71.321613985000056, 35.966674991000048],
            [71.311388985000065, 35.978514991000054],
            [71.292201985000077, 35.989187991000051],
            [71.275820985000053, 35.994429991000061],
            [71.265337985000031, 35.996395991000043],
            [71.257131985000058, 36.002443991000064],
            [71.24531498500005, 36.011333991000072],
            [71.226976985000078, 36.024132991000045],
            [71.211457985000038, 36.030845991000035],
            [71.20052198500008, 36.042564991000063],
            [71.201060985000083, 36.056020991000025],
            [71.207519985000033, 36.070551991000059],
            [71.219898985000043, 36.082929991000071],
            [71.230125985000029, 36.09315599100006],
            [71.245195985000066, 36.097998991000054],
            [71.246272985000076, 36.107148991000031],
            [71.245196985000064, 36.119526991000043],
            [71.248964985000043, 36.128676991000077],
            [71.259728985000038, 36.13459699100008],
            [71.27910498500006, 36.145897991000027],
            [71.292559985000082, 36.156123991000072],
            [71.314850985000078, 36.161925991000032],
            [71.34375598500003, 36.174045991000071],
            [71.354012985000054, 36.188963991000037],
            [71.361471985000037, 36.201084991000073],
            [71.380525985000077, 36.20994999100003],
            [71.410766985000066, 36.220622991000027],
            [71.424998985000059, 36.240189991000079],
            [71.433003985000084, 36.260646991000044],
            [71.442787985000052, 36.275766991000069],
            [71.458894985000029, 36.275050991000057],
            [71.471961407000038, 36.290511614000025],
            [71.471942690000049, 36.29054775700007],
            [71.472077370000079, 36.290707115000032],
            [71.472980722000045, 36.291775991000065],
            [71.485432722000041, 36.299780991000034],
            [71.500552722000066, 36.304227991000062],
            [71.519521722000036, 36.310784991000048],
            [71.531683722000082, 36.326462991000028],
            [71.556661722000058, 36.33346699100008],
            [71.57081872200007, 36.333577991000027],
            [71.573487722000039, 36.353145991000076],
            [71.555699722000043, 36.366487991000042],
            [71.562815722000039, 36.38071799100004],
            [71.589535722000051, 36.396143991000031],
            [71.604619722000052, 36.406510991000061],
            [71.619740722000074, 36.424299991000055],
            [71.625966722000044, 36.442087991000051],
            [71.625078722000069, 36.463434991000042],
            [71.641977722000036, 36.480333991000066],
            [71.659766722000029, 36.483890991000067],
            [71.678444722000052, 36.475885991000041],
            [71.693564722000076, 36.462543991000075],
            [71.718468722000068, 36.459874991000049],
            [71.737120745000084, 36.446898997000062],
            [71.737228791000064, 36.446823831000074],
            [71.737170008000078, 36.447106592000068],
            [71.738409263000051, 36.446244457000034],
            [71.73897398500003, 36.44585158600006],
            [71.753204985000082, 36.434288586000037],
            [71.76565598500008, 36.42272558600007],
            [71.788780985000074, 36.409383586000047],
            [71.815463985000065, 36.40226758600005],
            [71.839478985000028, 36.401377586000024],
            [71.861713985000051, 36.405824586000051],
            [71.861714985000049, 36.405824586000051],
            [71.86705093900008, 36.422719377000078],
            [71.867048731000068, 36.422722321000037],
            [71.856378346000042, 36.43695010600004],
            [71.825248854000051, 36.441397756000072],
            [71.819023395000045, 36.466301401000067],
            [71.827028774000041, 36.492984160000049],
            [71.838591940000072, 36.51077305900003],
            [71.900851086000046, 36.510773652000069],
            [71.914192935000074, 36.512551758000029],
            [71.921308049000061, 36.524114689000044],
            [71.92664538300005, 36.546350475000054],
            [71.933761611000079, 36.564139332000025],
            [71.993353004000085, 36.575701762000051],
            [72.018256597000061, 36.572144042000048],
            [72.048497589000078, 36.594380066000042],
            [72.061838569000031, 36.603274087000045],
            [72.069835282000042, 36.603274164000027],
            [72.070317535000072, 36.603066574000025],
            [72.092968142000075, 36.603066789000025],
            [72.109867007000048, 36.608402887000068],
            [72.101863621000064, 36.635975483000038],
            [72.10542193100008, 36.655542284000035],
            [72.126767719000043, 36.659989435000057],
            [72.15522939300007, 36.663547664000077],
            [72.18191207600006, 36.666215887000078],
            [72.196141751000084, 36.659100107000029],
            [72.20948366500005, 36.664437171000031],
            [72.217488751000076, 36.675110120000056],
            [72.201480393000054, 36.698235693000072],
            [72.23883698700007, 36.758716330000027],
            [72.269077572000072, 36.758716618000051],
            [72.302874978000034, 36.75160102500007],
            [72.338451783000039, 36.767611174000024],
            [72.359797473000071, 36.766721388000065],
            [72.390927111000053, 36.770279642000048],
            [72.407826140000054, 36.784509634000074],
            [72.495876899000052, 36.782731495000064],
            [72.513666078000028, 36.805856389000041],
            [72.537680025000043, 36.820976439000049],
            [72.571477935000075, 36.841433518000031],
            [72.624842349000062, 36.849438931000066],
            [72.645299264000073, 36.860111999000026],
            [72.688879503000067, 36.851217521000024],
            [72.727123994000067, 36.852106875000061],
            [72.751376769000046, 36.858003036000071],
            [72.776274355000055, 36.854072320000057],
            [72.836519460000034, 36.850327939000067],
            [72.872848099000066, 36.848968784000078],
            [72.87266819000007, 36.848834317000069],
            [72.876259147000042, 36.848699970000041],
            [72.938654490000033, 36.859596435000071],
            [72.953657681000038, 36.881327320000025],
            [72.991012039000054, 36.874211760000037],
            [73.019471534000047, 36.867986106000046],
            [73.061273383000071, 36.891111229000046],
            [73.086177156000076, 36.897337393000043],
            [73.11997369200003, 36.897337715000049],
            [73.146655423000084, 36.902673906000075],
            [73.183647721000057, 36.89206538500008],
            [73.211579386000039, 36.894669620000059],
            [73.243596995000075, 36.897337894000032],
            [73.281993353000075, 36.891070334000062],
            [73.30229586400003, 36.87954966500007],
            [73.337871636000045, 36.89378083500003],
            [73.357437286000049, 36.889333074000035],
            [73.396570042000064, 36.905343257000027],
            [73.44007040200006, 36.90298770000004],
            [73.48906474000006, 36.903565160000028],
            [73.51129945100007, 36.904454361000035],
            [73.530865020000078, 36.895559654000067],
            [73.567328617000044, 36.900896938000074],
            [73.594010528000069, 36.916017012000054],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "70",
      properties: { name: "Dera Ismail Khan", count: 510 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.299926758000083, 32.533325195000032],
            [71.32312011700003, 32.531311035000044],
            [71.328298643000039, 32.520618637000041],
            [71.338820102000057, 32.506092489000025],
            [71.340308500000049, 32.504108000000031],
            [71.341776624000033, 32.503006845000073],
            [71.344676519000075, 32.50092188800005],
            [71.344676566000032, 32.500921852000033],
            [71.350911000000053, 32.496156000000042],
            [71.348836186000028, 32.480595418000064],
            [71.34693500000003, 32.466337000000067],
            [71.344580971000084, 32.460956509000027],
            [71.344182266000075, 32.460045206000075],
            [71.340769863000048, 32.452245638000079],
            [71.337658000000033, 32.445133000000055],
            [71.336828953000065, 32.441540534000069],
            [71.336738753000077, 32.441149669000026],
            [71.333682000000067, 32.427904000000069],
            [71.34428500000007, 32.415314000000023],
            [71.342943022000043, 32.414825991000043],
            [71.342942923000066, 32.414825955000026],
            [71.336995000000059, 32.412663000000066],
            [71.334066335000045, 32.412296986000058],
            [71.334093468000049, 32.412293538000029],
            [71.326447720000033, 32.411338000000057],
            [71.325784719000069, 32.399411000000043],
            [71.325748807000082, 32.399339750000024],
            [71.325748476000058, 32.399339091000058],
            [71.328381000000036, 32.38880800000004],
            [71.338983000000042, 32.382182000000057],
            [71.34229700000003, 32.37290500000006],
            [71.334406563000073, 32.366329305000079],
            [71.334345000000042, 32.366278000000079],
            [71.318485099000043, 32.362974082000051],
            [71.318441500000063, 32.362965000000031],
            [71.318441000000064, 32.362965000000031],
            [71.311193654000078, 32.362954577000039],
            [71.296080159000041, 32.362965000000031],
            [71.296078855000076, 32.362965000000031],
            [71.296078364000039, 32.362965000000031],
            [71.293737760000056, 32.363019874000031],
            [71.288913446000038, 32.362965000000031],
            [71.283322000000055, 32.362965000000031],
            [71.272230511000032, 32.35256675100004],
            [71.272230475000072, 32.352566718000048],
            [71.25151500000004, 32.333146000000056],
            [71.242819452000049, 32.326558428000055],
            [71.229648000000054, 32.316580000000044],
            [71.215069000000028, 32.302002000000073],
            [71.209979812000029, 32.283553814000072],
            [71.209768000000054, 32.282786000000044],
            [71.200491000000056, 32.273509000000047],
            [71.185690554000075, 32.266292981000049],
            [71.185690518000058, 32.266292927000052],
            [71.177299000000062, 32.253629000000046],
            [71.175763921000055, 32.25120518500006],
            [71.167224486000066, 32.237721830000055],
            [71.164709000000073, 32.233750000000043],
            [71.167101225000067, 32.224779046000037],
            [71.168330291000075, 32.22016998600003],
            [71.168766628000071, 32.218533705000027],
            [71.168766664000032, 32.218533570000034],
            [71.170010000000048, 32.21387100000004],
            [71.172820173000048, 32.207683399000075],
            [71.175464120000072, 32.201770409000062],
            [71.175661712000078, 32.201322943000037],
            [71.175946698000075, 32.200677565000035],
            [71.175973000000056, 32.200618000000077],
            [71.174899202000063, 32.197856640000055],
            [71.174899493000055, 32.197856705000049],
            [71.17489781900008, 32.197852401000034],
            [71.173108090000028, 32.193250972000044],
            [71.171335000000056, 32.188691000000063],
            [71.162613775000068, 32.17717852100003],
            [71.15723188100003, 32.17007413500005],
            [71.15479354100006, 32.166814819000024],
            [71.14357423000007, 32.161443509000037],
            [71.138203000000033, 32.158872000000031],
            [71.136389128000076, 32.159085350000055],
            [71.126407809000057, 32.160259362000033],
            [71.12123852600007, 32.160867377000045],
            [71.115673000000072, 32.161522000000048],
            [71.112378853000052, 32.14981021400007],
            [71.109862509000038, 32.140863774000024],
            [71.10985957500003, 32.140851612000063],
            [71.109856627000056, 32.140839391000043],
            [71.105734000000041, 32.123752000000025],
            [71.106695071000047, 32.117984071000024],
            [71.10684013100007, 32.117114549000064],
            [71.106866460000049, 32.116956724000033],
            [71.106894338000075, 32.116789619000031],
            [71.109047000000032, 32.103873000000078],
            [71.109046945000046, 32.103872831000047],
            [71.109046890000059, 32.103872663000061],
            [71.10845448300006, 32.102049986000054],
            [71.107005921000052, 32.097593160000031],
            [71.105986620000067, 32.094464777000042],
            [71.10426516900003, 32.089165949000062],
            [71.100432001000058, 32.077367000000038],
            [71.094840228000066, 32.061352103000047],
            [71.094778162000068, 32.06117533400004],
            [71.092403827000055, 32.054440195000041],
            [71.091234787000076, 32.050999513000079],
            [71.09123339100006, 32.050995403000059],
            [71.09034766700006, 32.048388568000064],
            [71.089670734000038, 32.046132180000029],
            [71.089007422000066, 32.043921196000042],
            [71.088391820000084, 32.041868773000033],
            [71.088391784000066, 32.041868653000051],
            [71.086517000000072, 32.035620000000051],
            [71.073264000000052, 32.030319000000077],
            [71.065312464000044, 32.025018309000075],
            [71.065312000000063, 32.025018000000046],
            [71.065312464000044, 32.025016247000053],
            [71.069187283000076, 32.01037847200007],
            [71.071275990000061, 32.002488036000045],
            [71.071276000000069, 32.002488000000028],
            [71.070992119000039, 31.993395262000035],
            [71.070834876000049, 31.988358709000067],
            [71.070866873000057, 31.988340136000033],
            [71.070646577000048, 31.981284000000073],
            [71.070302866000077, 31.979771969000069],
            [71.070297405000076, 31.979747947000078],
            [71.068812577000074, 31.973216000000036],
            [71.068952167000077, 31.973207940000066],
            [71.067721560000052, 31.967227530000059],
            [71.072602001000064, 31.958754000000056],
            [71.072064254000054, 31.95281714500004],
            [71.07141527400006, 31.946176670000057],
            [71.071289062000062, 31.944885254000042],
            [71.066101074000073, 31.922302246000072],
            [71.066968417000055, 31.919969077000076],
            [71.069168743000034, 31.914050153000062],
            [71.069158062000042, 31.914031888000068],
            [71.069288000000029, 31.913694000000078],
            [71.062075198000059, 31.901891685000066],
            [71.062072718000081, 31.901887626000075],
            [71.061999000000071, 31.901767000000063],
            [71.062072718000081, 31.900980685000036],
            [71.062516454000047, 31.896247579000033],
            [71.063897800000063, 31.881513445000053],
            [71.063987000000054, 31.880562000000054],
            [71.062664294000058, 31.880121120000069],
            [71.044108000000051, 31.873936000000072],
            [71.042906526000081, 31.87254957600004],
            [71.035511602000042, 31.864016311000057],
            [71.032802020000076, 31.849445918000072],
            [71.032287598000039, 31.846679687000062],
            [71.030517578000058, 31.84210205100004],
            [71.030517542000041, 31.842101968000065],
            [71.02569580100004, 31.830871582000043],
            [71.025690098000041, 31.830846222000048],
            [71.022277832000043, 31.815673828000058],
            [71.01849367300008, 31.809082067000077],
            [71.018493652000075, 31.809082031000059],
            [71.012329102000081, 31.799072266000053],
            [71.007292865000068, 31.792893053000057],
            [71.006263950000061, 31.791626677000068],
            [71.006263906000072, 31.79162662300007],
            [71.006261729000073, 31.791623944000037],
            [71.00371464400007, 31.788489024000057],
            [71.000669216000063, 31.77983359600006],
            [70.999048000000073, 31.775202000000036],
            [70.997640818000036, 31.775342774000023],
            [70.992401123000036, 31.775390625000057],
            [70.985900879000042, 31.776489258000026],
            [70.981065495000053, 31.763521638000043],
            [70.980494000000078, 31.76195000000007],
            [70.973857894000048, 31.75885261600007],
            [70.970555000000047, 31.757311000000072],
            [70.968251426000052, 31.755007426000077],
            [70.965647204000049, 31.752403205000064],
            [70.962010841000051, 31.74876684000003],
            [70.960661712000046, 31.747417711000026],
            [70.956726074000073, 31.737487793000071],
            [70.951463438000076, 31.730833207000046],
            [70.948778729000082, 31.728916294000044],
            [70.948688000000061, 31.728818000000047],
            [70.948143250000044, 31.728205176000074],
            [70.938109999000062, 31.716918123000028],
            [70.938085000000058, 31.716890000000035],
            [70.93726638000004, 31.714979714000037],
            [70.933400827000071, 31.705959281000048],
            [70.933325405000062, 31.705783281000038],
            [70.930114746000072, 31.698486328000058],
            [70.930173065000076, 31.698427160000051],
            [70.930134000000066, 31.69833600000004],
            [70.939115125000058, 31.689354875000049],
            [70.942686178000031, 31.685731816000043],
            [70.942687988000046, 31.685729980000076],
            [70.942616202000067, 31.685705574000053],
            [70.93818854400007, 31.68420016400006],
            [70.919205826000052, 31.686814909000077],
            [70.919196275000047, 31.686713766000025],
            [70.91919564300008, 31.686713868000027],
            [70.915001098000062, 31.687394083000072],
            [70.912902832000043, 31.687683105000076],
            [70.912605173000031, 31.687333007000063],
            [70.902451299000063, 31.675390330000027],
            [70.897827128000074, 31.670142675000079],
            [70.897081391000029, 31.66927262400003],
            [70.897002000000043, 31.66918000000004],
            [70.891005089000032, 31.651790133000077],
            [70.891002077000053, 31.651781399000072],
            [70.890999065000074, 31.651772666000056],
            [70.890375000000063, 31.649963000000071],
            [70.886395218000075, 31.639516165000032],
            [70.885433070000033, 31.636990550000064],
            [70.885092149000059, 31.636095641000054],
            [70.895080566000047, 31.630126953000058],
            [70.895042058000058, 31.629675438000049],
            [70.894888012000081, 31.627869216000079],
            [70.893729171000075, 31.620452630000045],
            [70.893679397000028, 31.620134074000077],
            [70.893676758000083, 31.620117187000062],
            [70.893188323000061, 31.619567464000056],
            [70.889441514000055, 31.615350509000052],
            [70.888247621000062, 31.614006808000056],
            [70.88507080100004, 31.610290527000075],
            [70.885096525000051, 31.610230988000069],
            [70.890502930000082, 31.597717285000044],
            [70.896133730000031, 31.591290773000026],
            [70.900329590000069, 31.586486816000047],
            [70.900230993000037, 31.586445206000064],
            [70.89437276700005, 31.583972927000048],
            [70.893688000000054, 31.583699000000024],
            [70.89368246600003, 31.583681608000063],
            [70.890828907000071, 31.574712398000031],
            [70.889563639000073, 31.570735451000076],
            [70.889099121000072, 31.569091797000056],
            [70.883643402000075, 31.568704867000065],
            [70.880507833000081, 31.568463529000041],
            [70.880490129000066, 31.568462167000064],
            [70.880436000000032, 31.568458000000078],
            [70.879196071000081, 31.560605534000047],
            [70.87858581100005, 31.556740757000057],
            [70.878448000000049, 31.555868000000032],
            [70.878448000000049, 31.555332774000078],
            [70.878448000000049, 31.551377340000045],
            [70.878448000000049, 31.547254000000066],
            [70.878478968000081, 31.54721197300006],
            [70.887725000000046, 31.534664000000078],
            [70.888158970000063, 31.534328654000035],
            [70.902269212000078, 31.523425110000062],
            [70.902303000000074, 31.52339900000004],
            [70.900315000000035, 31.512796000000037],
            [70.889151732000073, 31.509512920000077],
            [70.889050000000054, 31.509483000000046],
            [70.884438296000042, 31.510306465000042],
            [70.883300781000059, 31.510498047000056],
            [70.881688784000062, 31.510797418000038],
            [70.870527411000069, 31.512790391000067],
            [70.87049600000006, 31.512796000000037],
            [70.872918485000071, 31.507951336000076],
            [70.878448000000049, 31.496893000000057],
            [70.88176100000004, 31.486291000000051],
            [70.882349869000052, 31.486026002000074],
            [70.893710861000045, 31.480913427000075],
            [70.89501400000006, 31.480327000000045],
            [70.895011114000056, 31.480317478000075],
            [70.891937817000041, 31.470176570000035],
            [70.89119101700004, 31.467712366000058],
            [70.88838700000008, 31.458460000000059],
            [70.887237172000084, 31.450411204000034],
            [70.88639900000004, 31.444544000000064],
            [70.895676000000037, 31.437918000000025],
            [70.887062000000071, 31.431954000000076],
            [70.871821000000068, 31.427316000000076],
            [70.85260500000004, 31.427316000000076],
            [70.847681296000076, 31.424546504000034],
            [70.842622389000041, 31.421700958000031],
            [70.842002000000036, 31.42135200000007],
            [70.841337388000056, 31.413155347000043],
            [70.840911687000073, 31.407905170000049],
            [70.841125488000046, 31.407897949000073],
            [70.84027100000003, 31.399108923000028],
            [70.840270996000072, 31.399108887000068],
            [70.840087891000053, 31.396911621000072],
            [70.840016998000067, 31.396870977000049],
            [70.840014000000053, 31.396834000000069],
            [70.835376000000053, 31.394184000000052],
            [70.824211922000075, 31.390899690000026],
            [70.82418425700007, 31.390891551000038],
            [70.824157840000055, 31.390883779000035],
            [70.829800071000079, 31.386603466000054],
            [70.834713000000079, 31.382919000000072],
            [70.834693532000074, 31.382891184000073],
            [70.834716797000056, 31.382873535000044],
            [70.830078125000057, 31.376281738000046],
            [70.827823225000031, 31.376151397000058],
            [70.81948077100003, 31.375630486000034],
            [70.818940055000041, 31.375052621000066],
            [70.811521000000084, 31.367015000000038],
            [70.80846606800003, 31.36243276600004],
            [70.80713455800003, 31.360435573000075],
            [70.80711463800003, 31.360405694000065],
            [70.806983376000062, 31.360208808000039],
            [70.80274714400008, 31.353121410000028],
            [70.802747108000062, 31.353121398000042],
            [70.795098392000057, 31.350679676000027],
            [70.795098280000047, 31.350679640000067],
            [70.78634100000005, 31.347799000000066],
            [70.78608158600008, 31.344687597000075],
            [70.786079167000082, 31.344658585000047],
            [70.785678000000075, 31.339847000000077],
            [70.785678000000075, 31.320630000000051],
            [70.783690000000036, 31.310028000000045],
            [70.767787000000055, 31.305390000000045],
            [70.76769250600006, 31.30542655000005],
            [70.76736709000005, 31.305552420000026],
            [70.766584107000028, 31.305855276000045],
            [70.759155490000069, 31.308730723000053],
            [70.759144245000073, 31.308734880000031],
            [70.756635299000038, 31.309704843000077],
            [70.756635207000045, 31.309704879000037],
            [70.752873886000032, 31.311162095000043],
            [70.749236741000061, 31.312570010000059],
            [70.747245000000078, 31.313341000000037],
            [70.745810044000052, 31.316431445000035],
            [70.745072961000062, 31.31801888900003],
            [70.744130475000077, 31.320048709000048],
            [70.742824655000049, 31.322861033000038],
            [70.741455934000044, 31.32580882700006],
            [70.740217310000048, 31.328476434000038],
            [70.73940297200005, 31.330230262000043],
            [70.738630000000057, 31.331895000000031],
            [70.735832423000033, 31.332273153000074],
            [70.732277757000077, 31.332753643000046],
            [70.728548259000036, 31.333257765000042],
            [70.72477327200005, 31.333768035000048],
            [70.719033976000048, 31.334543825000026],
            [70.716886336000073, 31.334834124000054],
            [70.714113000000054, 31.335209000000077],
            [70.709344022000039, 31.334325678000027],
            [70.708252337000033, 31.334123474000023],
            [70.70480423600003, 31.333484808000037],
            [70.698619618000066, 31.332339278000063],
            [70.696221000000037, 31.331895000000031],
            [70.69027254100007, 31.32615910100003],
            [70.681458433000046, 31.317659952000042],
            [70.677667000000042, 31.314004000000068],
            [70.673565071000041, 31.31417457200007],
            [70.661719645000062, 31.314677866000068],
            [70.647638123000036, 31.315272889000028],
            [70.630620000000079, 31.315992000000051],
            [70.619355000000041, 31.32394400000004],
            [70.614239558000065, 31.319758447000027],
            [70.612066000000084, 31.317980000000034],
            [70.608080126000061, 31.320969405000028],
            [70.604777806000072, 31.323446146000038],
            [70.604114000000038, 31.32394400000004],
            [70.597445914000048, 31.325824729000033],
            [70.588291174000062, 31.32840681600004],
            [70.581117629000062, 31.330430110000066],
            [70.578271000000029, 31.331233000000054],
            [70.576456432000043, 31.332100816000036],
            [70.573660896000035, 31.333437779000064],
            [70.564653517000067, 31.337745554000037],
            [70.563030000000083, 31.338522000000069],
            [70.544476000000031, 31.32858200000004],
            [70.544149000000061, 31.32727200000005],
            [70.541826000000071, 31.317980000000034],
            [70.533212000000049, 31.309366000000068],
            [70.523246884000059, 31.30481031000005],
            [70.515331393000054, 31.301191634000077],
            [70.512009213000056, 31.299672853000061],
            [70.510019000000057, 31.298763000000065],
            [70.508415952000064, 31.297866442000043],
            [70.50670640800007, 31.298431724000068],
            [70.500487834000069, 31.297809836000056],
            [70.493656580000049, 31.297126676000062],
            [70.490306644000043, 31.296791552000059],
            [70.481488657000057, 31.295757421000076],
            [70.477399601000059, 31.29527646300005],
            [70.475181440000028, 31.294321340000067],
            [70.475181404000068, 31.294321324000066],
            [70.470886134000068, 31.29451034300007],
            [70.467610000000036, 31.294125000000065],
            [70.461952422000081, 31.29163568000007],
            [70.459768111000074, 31.290674588000059],
            [70.457691518000047, 31.289760892000061],
            [70.451639556000032, 31.287098043000071],
            [70.451044000000081, 31.286836000000051],
            [70.442745354000067, 31.275650987000063],
            [70.441335028000083, 31.273750133000078],
            [70.441333434000057, 31.273747984000067],
            [70.441331839000043, 31.273745835000057],
            [70.44131195600005, 31.273719036000045],
            [70.440959598000063, 31.27324412300004],
            [70.435803000000078, 31.266294000000073],
            [70.434426257000041, 31.262587150000058],
            [70.432874838000032, 31.258409990000075],
            [70.43167475000007, 31.255178781000041],
            [70.430698866000057, 31.252551234000066],
            [70.429715224000063, 31.249902800000029],
            [70.42874203000008, 31.247282496000025],
            [70.428051592000031, 31.245423508000044],
            [70.427415954000082, 31.243712067000047],
            [70.427189000000055, 31.243101000000024],
            [70.425614944000074, 31.24370288700004],
            [70.404659000000038, 31.251716000000044],
            [70.391406000000075, 31.274908000000039],
            [70.390988511000046, 31.276446147000058],
            [70.390985082000043, 31.276458776000027],
            [70.38120499300004, 31.312491298000054],
            [70.378816000000029, 31.321293000000026],
            [70.37139555300007, 31.343555587000026],
            [70.366889000000072, 31.357076000000063],
            [70.363552146000075, 31.38615100800007],
            [70.362250000000074, 31.397497000000044],
            [70.357912956000064, 31.403382888000067],
            [70.352973000000077, 31.410087000000033],
            [70.351678285000048, 31.426274352000064],
            [70.351648000000068, 31.426653000000044],
            [70.345684000000062, 31.447195000000079],
            [70.343461977000061, 31.463308442000027],
            [70.343034000000046, 31.466412000000048],
            [70.329781000000082, 31.478339000000062],
            [70.337732000000074, 31.488279000000034],
            [70.344359000000054, 31.504182000000071],
            [70.342650649000063, 31.505364658000076],
            [70.335744000000034, 31.510146000000077],
            [70.326468000000034, 31.524724000000049],
            [70.315865000000031, 31.543278000000043],
            [70.313215000000071, 31.556531000000064],
            [70.312501028000042, 31.55830035300005],
            [70.297974000000067, 31.59430100000003],
            [70.279420000000073, 31.628759000000059],
            [70.277148646000057, 31.636708952000049],
            [70.274119000000042, 31.647313000000054],
            [70.27544400000005, 31.661891000000026],
            [70.27663283000004, 31.673187125000027],
            [70.276769000000058, 31.674481000000071],
            [70.266830000000084, 31.685746000000051],
            [70.261552447000042, 31.688761744000033],
            [70.252914000000032, 31.69369800000004],
            [70.230385000000069, 31.712914000000069],
            [70.219120000000032, 31.736107000000061],
            [70.209843000000035, 31.746046000000035],
            [70.217132000000049, 31.76460000000003],
            [70.214481000000035, 31.783154000000025],
            [70.211831000000075, 31.802371000000051],
            [70.213252435000072, 31.814810967000028],
            [70.214481000000035, 31.825563000000045],
            [70.221770000000049, 31.836828000000025],
            [70.217794000000083, 31.849418000000071],
            [70.217771261000053, 31.84951748800006],
            [70.212493000000052, 31.872611000000063],
            [70.207855000000052, 31.890502000000026],
            [70.206011492000073, 31.899435147000077],
            [70.199240000000032, 31.932248000000072],
            [70.19128900000004, 31.964718000000062],
            [70.187975000000051, 31.97730800000005],
            [70.167648000000042, 31.992409000000066],
            [70.180864000000042, 31.999017000000038],
            [70.227205000000083, 32.032350000000065],
            [70.228671127000041, 32.034549191000053],
            [70.234750161000079, 32.043667742000025],
            [70.238587000000052, 32.049423000000047],
            [70.251612746000035, 32.073471147000078],
            [70.25658185900005, 32.08264512900007],
            [70.25972500000006, 32.088448000000028],
            [70.280864000000065, 32.080317000000036],
            [70.285943367000073, 32.081445922000057],
            [70.310132000000067, 32.086822000000041],
            [70.311987735000059, 32.086774417000072],
            [70.372420033000083, 32.085224871000037],
            [70.373546000000033, 32.085196000000053],
            [70.388828445000058, 32.084725780000042],
            [70.410167153000032, 32.084069216000046],
            [70.419551584000033, 32.083780470000079],
            [70.426392000000078, 32.083570000000066],
            [70.429418353000074, 32.084638125000026],
            [70.454034000000036, 32.093326000000047],
            [70.462680214000045, 32.095146256000078],
            [70.482800390000079, 32.099382082000034],
            [70.484928000000082, 32.099830000000054],
            [70.49579525200005, 32.099190750000048],
            [70.50304019400005, 32.098764577000054],
            [70.510523429000045, 32.098324387000048],
            [70.518968936000078, 32.097827592000044],
            [70.526391000000046, 32.097391000000073],
            [70.55159400000008, 32.111212000000023],
            [70.553485284000033, 32.113962805000028],
            [70.554636909000067, 32.115637803000027],
            [70.562664936000033, 32.127314280000064],
            [70.569481000000053, 32.13722800000005],
            [70.569481000000053, 32.176320080000039],
            [70.569481000000053, 32.179504000000065],
            [70.570894581000061, 32.184734250000076],
            [70.573205237000082, 32.193283676000078],
            [70.576868055000034, 32.206836102000068],
            [70.577611000000047, 32.209585000000061],
            [70.580545292000068, 32.212427708000064],
            [70.583699323000076, 32.215483297000048],
            [70.597474736000038, 32.228828758000077],
            [70.603627000000074, 32.234789000000035],
            [70.610192470000072, 32.238967026000068],
            [70.637355318000061, 32.256252475000053],
            [70.639399000000083, 32.25755300000003],
            [70.657416505000072, 32.267493693000063],
            [70.65947793600003, 32.268631034000066],
            [70.659478000000036, 32.268631000000028],
            [70.660537000000033, 32.268122000000062],
            [70.69874800000008, 32.263244000000043],
            [70.698752102000071, 32.26324517300003],
            [70.698761289000061, 32.263244000000043],
            [70.706551784000055, 32.265469807000045],
            [70.706552000000045, 32.265475000000038],
            [70.73609670500008, 32.273915113000044],
            [70.744277000000068, 32.276252000000056],
            [70.747184012000048, 32.278687604000027],
            [70.774358000000063, 32.301455000000033],
            [70.774396841000055, 32.301447232000044],
            [70.798748000000046, 32.29657700000007],
            [70.798782913000082, 32.29657700000007],
            [70.815008000000034, 32.29657700000007],
            [70.84508900000003, 32.314463000000046],
            [70.859724000000028, 32.324219000000028],
            [70.867996602000062, 32.326907596000069],
            [70.868021895000084, 32.326915816000053],
            [70.87513943600004, 32.329229017000046],
            [70.879790376000074, 32.330754742000067],
            [70.884516559000076, 32.332276582000077],
            [70.892244000000062, 32.33478800000006],
            [70.892667521000078, 32.334909006000032],
            [70.892670630000055, 32.334902208000074],
            [70.896980459000076, 32.336141274000056],
            [70.896980699000039, 32.336141343000065],
            [70.904319323000038, 32.338238093000029],
            [70.914744164000069, 32.341235598000026],
            [70.929210189000059, 32.345349769000052],
            [70.940079683000079, 32.348455338000065],
            [70.949154000000078, 32.351048000000048],
            [70.960925086000032, 32.356421757000078],
            [70.978276978000054, 32.364343273000031],
            [70.986552000000074, 32.368121000000031],
            [71.003625000000056, 32.375438000000031],
            [71.041837000000044, 32.386821000000054],
            [71.045399728000064, 32.388451401000054],
            [71.089804000000072, 32.408772000000056],
            [71.121511000000055, 32.437227000000064],
            [71.121678969000072, 32.437389436000046],
            [71.121936000000062, 32.437638000000049],
            [71.145901000000038, 32.460804000000053],
            [71.160536000000036, 32.449422000000027],
            [71.178422000000069, 32.449422000000027],
            [71.18393186600008, 32.456261834000031],
            [71.195748011000035, 32.471101309000062],
            [71.19958611900006, 32.47569469900003],
            [71.204547793000074, 32.481632757000057],
            [71.21096850400005, 32.489201467000044],
            [71.21096654300004, 32.489203671000041],
            [71.210923946000037, 32.489251523000064],
            [71.213627421000069, 32.492425347000051],
            [71.225488978000044, 32.506332000000043],
            [71.230812000000071, 32.510260000000073],
            [71.236936000000071, 32.500795000000039],
            [71.241726025000048, 32.497964486000058],
            [71.25151500000004, 32.492180000000076],
            [71.262695477000079, 32.488891566000063],
            [71.26277900000008, 32.488867000000027],
            [71.262779193000028, 32.488867090000042],
            [71.26283163800008, 32.488891566000063],
            [71.262850029000049, 32.488900149000074],
            [71.266953035000029, 32.490815024000028],
            [71.272719000000052, 32.493506000000025],
            [71.272617912000044, 32.493977726000026],
            [71.270731000000069, 32.502783000000079],
            [71.27273959200005, 32.505594786000074],
            [71.277357309000081, 32.512059033000071],
            [71.277358000000049, 32.512060000000076],
            [71.277582177000056, 32.511980870000059],
            [71.288696289000029, 32.50811767600004],
            [71.292352969000035, 32.508884399000067],
            [71.293329533000076, 32.509092793000036],
            [71.297899000000029, 32.510072000000036],
            [71.299887000000069, 32.533264000000031],
            [71.299887496000053, 32.533263957000031],
            [71.299887500000068, 32.533264000000031],
            [71.299921208000058, 32.533261111000058],
            [71.299926758000083, 32.533325195000032],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "71",
      properties: { name: "Hangu", count: 502 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.187596080000048, 33.598691046000056],
            [71.191673000000037, 33.595999000000063],
            [71.20162029100004, 33.593658044000051],
            [71.208899271000064, 33.591945037000073],
            [71.209898000000067, 33.591710000000035],
            [71.222406000000035, 33.595999000000063],
            [71.224995977000049, 33.586717239000052],
            [71.225003048000076, 33.586691901000052],
            [71.226694000000066, 33.580632000000037],
            [71.226656825000077, 33.580527037000024],
            [71.226433854000049, 33.57989746800007],
            [71.223510313000077, 33.571642736000058],
            [71.223509447000083, 33.571640291000051],
            [71.220619000000056, 33.563479000000029],
            [71.21002496300008, 33.556692073000079],
            [71.209967707000033, 33.556655392000039],
            [71.197748000000047, 33.548827000000074],
            [71.188456000000031, 33.537391000000071],
            [71.188818653000055, 33.536847021000028],
            [71.189063803000067, 33.536479296000039],
            [71.193824065000058, 33.529338902000063],
            [71.19384102500004, 33.529313462000061],
            [71.198462000000063, 33.52238200000005],
            [71.210255000000075, 33.520595000000071],
            [71.209564877000048, 33.512543354000059],
            [71.209185084000069, 33.508112312000037],
            [71.209183000000053, 33.508088000000043],
            [71.178566262000061, 33.505595466000045],
            [71.157900264000034, 33.50880200000006],
            [71.157851992000076, 33.508781986000031],
            [71.157723000000033, 33.50880200000006],
            [71.143071000000077, 33.50272700000005],
            [71.141710378000084, 33.498062048000065],
            [71.138068000000032, 33.485574000000042],
            [71.136996940000074, 33.474862395000059],
            [71.123774325000056, 33.475210000000061],
            [71.113640152000073, 33.475405245000047],
            [71.105192325000075, 33.475568000000067],
            [71.105189084000074, 33.475561159000051],
            [71.104834000000039, 33.475568000000067],
            [71.102533650000055, 33.470712739000078],
            [71.102527861000056, 33.470718528000077],
            [71.101611211000034, 33.469254259000024],
            [71.106409925000037, 33.462144962000025],
            [71.111260211000058, 33.454959259000077],
            [71.111378469000044, 33.454847237000024],
            [71.114629010000044, 33.451768079000033],
            [71.118050211000082, 33.448527259000059],
            [71.117940661000034, 33.44816025800003],
            [71.118056000000081, 33.448051000000078],
            [71.111006029000066, 33.424433055000065],
            [71.110948970000038, 33.424241904000041],
            [71.116831337000065, 33.415403385000047],
            [71.116984000000059, 33.415174000000036],
            [71.116477701000065, 33.414808978000053],
            [71.101617000000033, 33.404095000000041],
            [71.07588700000008, 33.403381000000024],
            [71.060163000000045, 33.396948000000066],
            [71.066238000000055, 33.382296000000053],
            [71.062804000000028, 33.378314000000046],
            [71.048370000000034, 33.361569000000031],
            [71.035505000000057, 33.362641000000053],
            [71.02947696800004, 33.363336709000066],
            [71.018811716000073, 33.364567609000062],
            [71.00763100000006, 33.36585800000006],
            [70.989406000000031, 33.365500000000054],
            [70.986329635000061, 33.364914936000048],
            [70.971654129000058, 33.362123945000064],
            [70.95303098100004, 33.35858219000005],
            [70.931156000000044, 33.354422000000056],
            [70.910071000000073, 33.354422000000056],
            [70.905136083000059, 33.346715342000039],
            [70.89911203500003, 33.337307833000068],
            [70.888485836000029, 33.320713333000072],
            [70.884009567000078, 33.313722927000072],
            [70.884008120000033, 33.313720667000041],
            [70.883984000000055, 33.313683000000026],
            [70.882400406000045, 33.313720667000041],
            [70.88022899300006, 33.313772316000041],
            [70.853966000000071, 33.314397000000042],
            [70.845897940000043, 33.316164435000076],
            [70.816443000000049, 33.322617000000037],
            [70.783620540000072, 33.33367628700006],
            [70.781947537000065, 33.333607534000066],
            [70.770324707000043, 33.333129883000026],
            [70.762933308000072, 33.315312207000034],
            [70.761051000000066, 33.310824000000025],
            [70.766769000000068, 33.300818000000049],
            [70.764035835000072, 33.281685838000044],
            [70.764035820000061, 33.281685742000036],
            [70.763919376000047, 33.280870630000038],
            [70.763910000000067, 33.280805000000043],
            [70.766054000000054, 33.272229000000038],
            [70.759622000000036, 33.249358000000029],
            [70.759265000000084, 33.238279000000034],
            [70.757507142000065, 33.215087871000037],
            [70.756921533000082, 33.215023021000036],
            [70.739293000000032, 33.213134000000025],
            [70.716763000000071, 33.217110000000048],
            [70.703510000000051, 33.217110000000048],
            [70.703254185000048, 33.218773087000045],
            [70.702270508000083, 33.225708008000026],
            [70.69171142600004, 33.228515625000057],
            [70.682312012000068, 33.235717773000033],
            [70.679077148000033, 33.244873047000056],
            [70.692321777000075, 33.254272461000028],
            [70.674499512000068, 33.254272461000028],
            [70.657897949000073, 33.255676270000038],
            [70.643920898000033, 33.260314941000047],
            [70.628238959000043, 33.256924252000033],
            [70.628112793000071, 33.256897963000029],
            [70.627969000000064, 33.256868000000054],
            [70.627969000000064, 33.261118452000062],
            [70.627969000000064, 33.264453056000036],
            [70.627969000000064, 33.265483000000074],
            [70.625445100000036, 33.267209837000053],
            [70.615379000000075, 33.27409700000004],
            [70.598813000000064, 33.278073000000063],
            [70.586468948000061, 33.281599817000028],
            [70.580259000000069, 33.283374000000038],
            [70.571645000000046, 33.290663000000052],
            [70.56766900000008, 33.303253000000041],
            [70.56014678300005, 33.307678187000079],
            [70.556518519000065, 33.309812630000067],
            [70.556404000000043, 33.309880000000078],
            [70.556404000000043, 33.32108660800003],
            [70.556404000000043, 33.321109249000074],
            [70.556404000000043, 33.321119123000074],
            [70.556424780000043, 33.326441423000063],
            [70.551766000000043, 33.332410000000039],
            [70.541826000000071, 33.333735000000047],
            [70.54283270500008, 33.348334879000049],
            [70.54315100000008, 33.352951000000076],
            [70.535862000000066, 33.36487900000003],
            [70.523925781000059, 33.371520996000072],
            [70.51177337300004, 33.376575461000073],
            [70.508031000000074, 33.378132000000051],
            [70.500080000000082, 33.385421000000065],
            [70.498092000000042, 33.403312000000028],
            [70.500080000000082, 33.41524000000004],
            [70.504221813000072, 33.414110363000077],
            [70.50736900000004, 33.413252000000057],
            [70.526585000000068, 33.41524000000004],
            [70.542489000000046, 33.422529000000054],
            [70.557729000000052, 33.422529000000054],
            [70.580922000000044, 33.41987800000004],
            [70.590552388000049, 33.426426733000028],
            [70.597488000000055, 33.431143000000077],
            [70.625319000000047, 33.475540000000024],
            [70.679670390000069, 33.472901222000075],
            [70.679687500000057, 33.472900391000053],
            [70.681030415000066, 33.473981274000039],
            [70.684692383000083, 33.476928711000028],
            [70.685479440000051, 33.477514428000063],
            [70.68727219200008, 33.47893812600006],
            [70.692099581000036, 33.482771754000055],
            [70.696472168000071, 33.486328125000057],
            [70.702185000000043, 33.490781000000027],
            [70.715438000000063, 33.488130000000069],
            [70.74260600000008, 33.482923529000061],
            [70.742675781000059, 33.482910156000059],
            [70.742675781000059, 33.489929199000073],
            [70.742675781000059, 33.496887207000043],
            [70.734130859000061, 33.511474609000061],
            [70.732116699000073, 33.527282715000069],
            [70.736083984000061, 33.537292480000076],
            [70.738668039000061, 33.544558236000057],
            [70.741271973000039, 33.551879883000026],
            [70.746229076000077, 33.55944071700003],
            [70.747314453000058, 33.561096191000047],
            [70.748716439000077, 33.582247886000061],
            [70.748718262000068, 33.582275391000053],
            [70.752759040000058, 33.582892571000059],
            [70.752773411000078, 33.582881925000038],
            [70.756394224000076, 33.583447801000034],
            [70.769897461000028, 33.585510254000042],
            [70.773708879000083, 33.585257842000033],
            [70.779113770000038, 33.584899902000075],
            [70.779655182000056, 33.584633890000077],
            [70.789672852000081, 33.579711914000029],
            [70.800903320000032, 33.576293945000032],
            [70.812316895000038, 33.573730469000054],
            [70.822820583000066, 33.566728010000077],
            [70.823486328000058, 33.566284180000025],
            [70.824506209000049, 33.565818142000069],
            [70.832702637000068, 33.562072754000042],
            [70.836288812000078, 33.56020794300008],
            [70.837280273000033, 33.559692383000026],
            [70.837323641000069, 33.559715217000075],
            [70.837364000000036, 33.559696000000031],
            [70.85834785000003, 33.557994560000054],
            [70.861882000000037, 33.557708000000048],
            [70.877785000000074, 33.558370000000025],
            [70.898990000000083, 33.556382000000042],
            [70.916881000000046, 33.561021000000039],
            [70.931459000000075, 33.558370000000025],
            [70.950013000000069, 33.554394000000059],
            [70.969892000000073, 33.546443000000068],
            [70.989109000000042, 33.546443000000068],
            [71.00832500000007, 33.546443000000068],
            [71.019590000000051, 33.557045000000073],
            [71.036425548000068, 33.562032997000074],
            [71.037482000000068, 33.562346000000048],
            [71.050072000000057, 33.574936000000037],
            [71.060011000000031, 33.582225000000051],
            [71.079228000000057, 33.583551000000057],
            [71.085413450000033, 33.585503912000036],
            [71.091818000000046, 33.587526000000025],
            [71.107722000000081, 33.59216500000008],
            [71.124477099000046, 33.592763752000053],
            [71.126275000000078, 33.592828000000054],
            [71.131227000000081, 33.59777900000006],
            [71.131636000000071, 33.597785000000044],
            [71.140998853000042, 33.597223271000075],
            [71.14102464900003, 33.597221723000075],
            [71.149504000000036, 33.596713000000079],
            [71.172732000000053, 33.608506000000034],
            [71.180050364000067, 33.603673585000024],
            [71.182394980000083, 33.602125403000059],
            [71.185214428000052, 33.600263683000037],
            [71.187596080000048, 33.598691046000056],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "72",
      properties: { name: "Haripur", count: 448 },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [73.011297570000067, 34.267097135000029],
              [73.008121502000051, 34.259886559000051],
              [73.004385452000065, 34.255440198000031],
              [73.003603627000075, 34.25189483500003],
              [73.003543458000081, 34.249184685000046],
              [73.001892364000071, 34.248418822000076],
              [73.000553305000039, 34.247797700000035],
              [73.000368322000043, 34.247711886000047],
              [72.998924230000057, 34.247293591000073],
              [72.99408061500003, 34.245890560000078],
              [72.987063876000036, 34.243249496000033],
              [72.986206448000075, 34.243048268000052],
              [72.98584823300007, 34.242964180000058],
              [72.985377580000034, 34.242853713000045],
              [72.983767262000072, 34.242475756000033],
              [72.983474080000065, 34.242406943000049],
              [72.981993383000031, 34.242059409000035],
              [72.981993347000071, 34.242059401000063],
              [72.979956845000061, 34.241581561000032],
              [72.979093133000049, 34.241378858000076],
              [72.975725479000062, 34.241589085000044],
              [72.975679695000053, 34.24159194300006],
              [72.975661942000045, 34.241593034000061],
              [72.975661905000038, 34.241593049000073],
              [72.972340641000073, 34.242377697000052],
              [72.971436705000031, 34.242406979000066],
              [72.971436724000057, 34.242407003000039],
              [72.974601333000066, 34.24638238700004],
              [72.978282971000056, 34.251731255000038],
              [72.982685623000066, 34.258503441000073],
              [72.983767226000055, 34.260636877000024],
              [72.984962100000075, 34.260922873000027],
              [72.991757736000068, 34.263516827000046],
              [72.993393512000068, 34.264227637000033],
              [72.993393548000029, 34.264227637000033],
              [72.993997039000078, 34.264225527000065],
              [72.99421314500006, 34.264224766000041],
              [72.996941699000047, 34.264736524000057],
              [72.998895891000075, 34.264154684000061],
              [73.000935745000049, 34.263702692000038],
              [73.006187953000051, 34.26507199100007],
              [73.007855343000074, 34.265681743000073],
              [73.009701221000057, 34.266356766000058],
              [73.011297608000064, 34.267097221000029],
              [73.011297570000067, 34.267097135000029],
            ],
          ],
          [
            [
              [72.800075216000039, 34.350031391000073],
              [72.804085000000043, 34.34827400000006],
              [72.811105180000084, 34.351261712000053],
              [72.817114000000061, 34.353819000000044],
              [72.82543000000004, 34.354927000000032],
              [72.83624100000003, 34.351046000000053],
              [72.838246538000078, 34.348037693000038],
              [72.842339000000038, 34.341899000000069],
              [72.848145295000052, 34.338435654000079],
              [72.852197663000084, 34.33601849300004],
              [72.858140000000049, 34.332474000000047],
              [72.861924348000059, 34.327988903000062],
              [72.864139314000056, 34.325363791000029],
              [72.865023350000058, 34.324316058000079],
              [72.865023429000075, 34.32431596400005],
              [72.865033650000044, 34.324303850000035],
              [72.86562500000008, 34.323603000000048],
              [72.863685000000032, 34.314178000000027],
              [72.859997959000054, 34.309494520000044],
              [72.853428000000065, 34.301149000000066],
              [72.854338717000076, 34.300610873000039],
              [72.854339000000039, 34.30061100000006],
              [72.856753667000078, 34.299184151000077],
              [72.862011216000042, 34.296077418000039],
              [72.865428292000047, 34.294058234000033],
              [72.86562500000008, 34.293942000000072],
              [72.871235575000071, 34.291502352000066],
              [72.874372100000073, 34.290138496000054],
              [72.878131804000077, 34.288503662000039],
              [72.879133326000044, 34.288068170000031],
              [72.881464145000052, 34.287054659000034],
              [72.883329890000084, 34.286243377000062],
              [72.883331989000055, 34.286242464000054],
              [72.883380965000072, 34.286221168000054],
              [72.884752000000049, 34.285625000000039],
              [72.885813711000083, 34.284357012000044],
              [72.886926245000041, 34.283028326000078],
              [72.888037423000071, 34.281701259000045],
              [72.888816948000056, 34.280770281000059],
              [72.890143557000044, 34.279185927000071],
              [72.892261500000075, 34.276656492000029],
              [72.893941175000066, 34.274650475000044],
              [72.89412687500004, 34.27442810000008],
              [72.894602678000069, 34.273860450000029],
              [72.894697116000032, 34.273747663000051],
              [72.894732000000033, 34.273706000000061],
              [72.895781568000075, 34.271855280000068],
              [72.897283781000056, 34.269206406000023],
              [72.898596362000035, 34.266891911000073],
              [72.901322221000044, 34.26208536300004],
              [72.903209931000049, 34.258756735000077],
              [72.905265000000043, 34.255133000000058],
              [72.906639271000074, 34.254403779000029],
              [72.906995402000064, 34.254215270000032],
              [72.907045477000054, 34.254188697000075],
              [72.90714656800003, 34.254135052000038],
              [72.907800762000079, 34.253787463000037],
              [72.91048945600005, 34.252360777000035],
              [72.914306004000082, 34.250335624000058],
              [72.91603490500006, 34.249418227000035],
              [72.916402509000079, 34.249223167000025],
              [72.917704308000054, 34.248532401000034],
              [72.917856261000054, 34.248451771000077],
              [72.91786477800008, 34.248447290000058],
              [72.918505203000052, 34.248107440000069],
              [72.91884900000008, 34.247925000000066],
              [72.918878648000032, 34.247913931000028],
              [72.92566103300004, 34.245381710000061],
              [72.929867625000043, 34.243811169000026],
              [72.931105532000061, 34.243348992000051],
              [72.931490200000042, 34.243205376000049],
              [72.931498323000028, 34.243202343000064],
              [72.934216408000054, 34.242187538000053],
              [72.934884265000051, 34.241938192000077],
              [72.93488681000008, 34.241937242000063],
              [72.937370194000039, 34.241010064000079],
              [72.939639000000057, 34.240163000000052],
              [72.943133139000054, 34.238351388000069],
              [72.947043061000045, 34.236324205000074],
              [72.947657941000045, 34.23600540700005],
              [72.951115414000071, 34.234212807000063],
              [72.951115483000081, 34.234212771000045],
              [72.953591514000038, 34.232929019000039],
              [72.953606347000061, 34.232921812000029],
              [72.954609000000062, 34.232402000000036],
              [72.954815448000033, 34.232334369000057],
              [72.961511628000039, 34.230140764000055],
              [72.961511741000038, 34.230140727000048],
              [72.962025777000065, 34.229972334000024],
              [72.969523173000084, 34.227516258000037],
              [72.970687000000055, 34.227135000000033],
              [72.975009234000083, 34.224147498000036],
              [72.975952178000057, 34.223495741000079],
              [72.975962801000037, 34.223488399000075],
              [72.985252622000075, 34.217067334000035],
              [72.987713185000075, 34.215366609000057],
              [72.987717258000032, 34.215363794000041],
              [72.989537000000041, 34.214106000000072],
              [72.989634547000037, 34.214161269000044],
              [72.989634591000083, 34.214161294000064],
              [72.992571000000055, 34.215825000000052],
              [72.992586000000074, 34.21576900000008],
              [72.992435251000074, 34.215437346000044],
              [72.992272358000037, 34.215078977000076],
              [72.992080606000059, 34.21465711500008],
              [72.991913873000044, 34.214290296000058],
              [72.991690168000048, 34.213798136000037],
              [72.991482949000044, 34.21334224800006],
              [72.990492135000068, 34.21116242100004],
              [72.989127175000078, 34.208159460000047],
              [72.987668162000034, 34.204949579000072],
              [72.987175826000055, 34.203866421000043],
              [72.987042000000031, 34.203572000000065],
              [72.987002792000055, 34.203497378000066],
              [72.98526660400006, 34.200192901000037],
              [72.984679192000044, 34.199074884000026],
              [72.984678968000082, 34.199074981000024],
              [72.982433676000085, 34.194802029000073],
              [72.980556584000055, 34.191229786000065],
              [72.979557300000067, 34.189328078000074],
              [72.978920753000068, 34.188116684000079],
              [72.978448000000071, 34.187217000000032],
              [72.980725784000072, 34.178105043000073],
              [72.981219998000029, 34.176128000000062],
              [72.988345410000079, 34.171205593000025],
              [72.992078475000028, 34.168626700000061],
              [72.996467000000052, 34.165595000000053],
              [72.99525744400006, 34.163982338000039],
              [72.995128227000066, 34.163810058000024],
              [72.993588748000036, 34.161757523000063],
              [72.992383261000043, 34.160150287000079],
              [72.992179328000077, 34.159878390000074],
              [72.991477000000032, 34.158942000000025],
              [72.992013505000045, 34.156125630000076],
              [72.993281225000032, 34.149470766000036],
              [72.993746085000055, 34.147030498000049],
              [72.994132109000077, 34.145004075000031],
              [72.994804000000045, 34.141477001000055],
              [72.994930581000062, 34.140083582000045],
              [72.995035443000063, 34.138929250000047],
              [72.995229905000031, 34.136788580000029],
              [72.995400926000059, 34.134905970000034],
              [72.995400962000076, 34.134905574000072],
              [72.995836097000051, 34.130115547000059],
              [72.995836164000082, 34.130114807000041],
              [72.995883500000048, 34.129593729000078],
              [72.996133778000058, 34.125733139000033],
              [72.996330224000076, 34.122591428000078],
              [72.996547278000037, 34.119120133000024],
              [72.996668958000043, 34.117174137000063],
              [72.996744000000035, 34.115974000000051],
              [72.994109210000033, 34.113652887000057],
              [72.993653539000036, 34.11325146400003],
              [72.993653610000081, 34.113251208000065],
              [72.992952005000063, 34.112633449000043],
              [72.991486085000076, 34.111342049000029],
              [72.990256152000029, 34.110258541000064],
              [72.989789955000049, 34.109847846000036],
              [72.989360100000056, 34.109469166000054],
              [72.988396378000061, 34.108620177000034],
              [72.987815384000044, 34.108109027000069],
              [72.985579631000064, 34.10614066800008],
              [72.985101000000043, 34.105718000000024],
              [72.984155039000029, 34.103726399000038],
              [72.98397281900003, 34.103342758000053],
              [72.983972802000039, 34.103342722000036],
              [72.983363758000053, 34.102060458000039],
              [72.98236649100005, 34.099960839000062],
              [72.981605773000069, 34.098359243000061],
              [72.980610352000042, 34.096263510000028],
              [72.980322184000045, 34.095656810000037],
              [72.980256291000046, 34.09551808100008],
              [72.980244079000045, 34.095492370000045],
              [72.979834000000039, 34.094629000000054],
              [72.979834337000057, 34.094628707000027],
              [72.979835000000037, 34.094629000000054],
              [72.982642800000065, 34.092187397000032],
              [72.992586000000074, 34.083541000000025],
              [72.993762864000075, 34.084056984000028],
              [72.997798769000042, 34.085840702000041],
              [72.999004871000068, 34.086372795000045],
              [73.000005140000042, 34.086814081000057],
              [73.002036049000083, 34.087698984000042],
              [73.002036085000043, 34.087699000000043],
              [73.002109403000077, 34.087913298000046],
              [73.003159151000034, 34.090981568000075],
              [73.004213628000059, 34.09413698000003],
              [73.005422353000029, 34.097669918000065],
              [73.005615000000034, 34.09823300000005],
              [73.00948646900008, 34.098499798000034],
              [73.009495837000031, 34.098500444000024],
              [73.009599783000056, 34.098507607000045],
              [73.013257931000055, 34.098759705000077],
              [73.013654000000031, 34.098787000000073],
              [73.015389893000076, 34.098236677000045],
              [73.015720961000056, 34.098131720000026],
              [73.021817104000036, 34.096199084000034],
              [73.025019000000043, 34.095184000000074],
              [73.025339518000067, 34.09069734600007],
              [73.025627260000078, 34.086669465000057],
              [73.025627271000076, 34.086669307000079],
              [73.025794959000052, 34.08432197500008],
              [73.025819517000059, 34.083978204000061],
              [73.026127999000039, 34.079660000000047],
              [73.024521291000042, 34.078420605000076],
              [73.024342896000064, 34.078282993000073],
              [73.023198801000035, 34.077400453000052],
              [73.019408147000036, 34.074476390000029],
              [73.017141861000084, 34.072728206000079],
              [73.01713056300008, 34.072719491000043],
              [73.016426000000081, 34.07217600000007],
              [73.019407933000082, 34.069003649000024],
              [73.019653672000061, 34.068742217000079],
              [73.019659202000071, 34.068724038000028],
              [73.020838547000039, 34.067481679000025],
              [73.023784100000057, 34.064348030000076],
              [73.027707981000049, 34.060173580000026],
              [73.028007568000078, 34.059854862000066],
              [73.029455000000041, 34.05831500000005],
              [73.033681427000033, 34.05267976500005],
              [73.037771000000077, 34.047227000000078],
              [73.042243959000075, 34.04715823500004],
              [73.050984476000053, 34.04702386200006],
              [73.055789000000061, 34.046950000000038],
              [73.061596664000035, 34.045626493000043],
              [73.070819004000043, 34.043593050000027],
              [73.080738000000053, 34.041406000000052],
              [73.084771529000079, 34.03511840200008],
              [73.087011613000072, 34.03162648600005],
              [73.086983124000028, 34.031607596000072],
              [73.089912415000072, 34.027104620000046],
              [73.090163000000075, 34.026714000000027],
              [73.096816000000047, 34.01895200000007],
              [73.098876442000062, 34.018676115000062],
              [73.105669670000054, 34.017838089000065],
              [73.106727095000053, 34.017709147000062],
              [73.107820357000037, 34.017575835000059],
              [73.110122000000047, 34.017288000000065],
              [73.114293015000044, 34.018843428000025],
              [73.116963008000084, 34.019839104000027],
              [73.118262686000037, 34.020323771000051],
              [73.12051243500008, 34.02116273300004],
              [73.120841935000044, 34.021285608000028],
              [73.123868057000038, 34.022414090000041],
              [73.126477000000079, 34.02338700100006],
              [73.126477923000039, 34.02338631300006],
              [73.13172380900005, 34.019479982000064],
              [73.133721088000073, 34.017992715000048],
              [73.13950600000004, 34.013685000000066],
              [73.141239340000084, 34.012441540000054],
              [73.150238927000032, 34.005985438000039],
              [73.15110340800004, 34.006194265000033],
              [73.152258000000074, 34.004537000000028],
              [73.149672226000064, 33.998934835000057],
              [73.14730306000007, 33.993801959000052],
              [73.147268000000054, 33.993726000000038],
              [73.14144600000003, 33.985410000000059],
              [73.141260220000049, 33.977043187000049],
              [73.14121519400004, 33.97501539600006],
              [73.141215357000078, 33.975015227000029],
              [73.141169000000048, 33.972935000000064],
              [73.137522768000053, 33.965059033000045],
              [73.137254660000053, 33.964479912000058],
              [73.135327786000062, 33.96031780900006],
              [73.134239000000036, 33.957966000000056],
              [73.13240576000004, 33.956810215000075],
              [73.12679535500007, 33.953273077000063],
              [73.126795429000083, 33.953273492000051],
              [73.121487000000059, 33.949927000000059],
              [73.111600292000048, 33.946177391000049],
              [73.110651422000046, 33.945815921000076],
              [73.109826025000075, 33.945501487000058],
              [73.108904364000068, 33.944810267000037],
              [73.10792858700006, 33.94407846200005],
              [73.106755183000075, 33.943198443000028],
              [73.100955025000076, 33.93884848700003],
              [73.101720956000065, 33.936667215000057],
              [73.104577998000082, 33.928582000000063],
              [73.111327670000037, 33.918833077000045],
              [73.112063000000035, 33.917771000000073],
              [73.106240998000033, 33.906405000000063],
              [73.103538023000056, 33.903026454000042],
              [73.098479000000054, 33.896703000000059],
              [73.114835000000085, 33.895872000000054],
              [73.11799731900004, 33.896798785000044],
              [73.125228932000084, 33.898918163000076],
              [73.130913000000078, 33.900584000000038],
              [73.13205802400006, 33.900613719000035],
              [73.151709075000042, 33.901123753000036],
              [73.152258000000074, 33.90113800000006],
              [73.154416595000043, 33.90264258600007],
              [73.158765802000062, 33.905674075000036],
              [73.16156025600003, 33.907621868000035],
              [73.167827874000068, 33.911990530000026],
              [73.170553000000041, 33.913890000000038],
              [73.179926502000058, 33.914206097000033],
              [73.180270459000042, 33.914217696000037],
              [73.191524842000035, 33.914597222000054],
              [73.194184857000039, 33.91468692400008],
              [73.19522500000005, 33.91472200000004],
              [73.196059147000028, 33.910884681000027],
              [73.196611000000075, 33.908346000000051],
              [73.196298994000074, 33.908138012000052],
              [73.189958000000047, 33.903911000000051],
              [73.188474899000084, 33.895510620000039],
              [73.188434231000031, 33.895280275000061],
              [73.188294000000042, 33.894486000000029],
              [73.18829510300003, 33.894485583000062],
              [73.188295000000039, 33.894485000000032],
              [73.196912931000043, 33.89122470600006],
              [73.198551000000066, 33.89060500000005],
              [73.199434066000038, 33.890172503000031],
              [73.199637490000043, 33.890072873000065],
              [73.212135000000046, 33.883952000000079],
              [73.207976000000031, 33.875635000000045],
              [73.203285640000047, 33.873187744000063],
              [73.201537210000083, 33.872275478000063],
              [73.19522500000005, 33.868982000000074],
              [73.19522500000005, 33.868784030000029],
              [73.19522500000005, 33.864915303000032],
              [73.19522500000005, 33.86177500000008],
              [73.196974299000033, 33.859675842000058],
              [73.198987232000036, 33.857260322000059],
              [73.199115405000043, 33.857106514000066],
              [73.200215309000043, 33.855786629000079],
              [73.201970029000051, 33.853680965000024],
              [73.201977725000063, 33.85367173000003],
              [73.202155000000062, 33.853459000000043],
              [73.212689000000069, 33.853459000000043],
              [73.214241003000041, 33.849707782000053],
              [73.214241106000031, 33.849707534000061],
              [73.214477439000063, 33.849136312000041],
              [73.216015000000084, 33.845420000000047],
              [73.212890514000037, 33.835821062000036],
              [73.212135000000046, 33.833500000000072],
              [73.214309115000049, 33.828996153000048],
              [73.21983300100004, 33.817553000000032],
              [73.217306945000075, 33.816943255000069],
              [73.214502370000048, 33.816266281000026],
              [73.21127436200004, 33.815487097000073],
              [73.21126694000003, 33.815485306000028],
              [73.210652108000033, 33.815336896000076],
              [73.200672968000049, 33.81292811000003],
              [73.197914731000083, 33.812262321000048],
              [73.195146419000082, 33.811594099000047],
              [73.190096398000037, 33.810375114000067],
              [73.189491363000059, 33.810229070000048],
              [73.187354490000075, 33.809713267000063],
              [73.187338257000079, 33.809709257000065],
              [73.187333668000065, 33.809708123000064],
              [73.183815086000038, 33.808838861000027],
              [73.18381458500005, 33.808838737000031],
              [73.183542936000038, 33.808771626000066],
              [73.183538884000029, 33.808770625000079],
              [73.175487544000077, 33.80608673200004],
              [73.168809401000033, 33.803860591000046],
              [73.159073952000028, 33.800615305000065],
              [73.140813277000063, 33.794528159000038],
              [73.136064000000033, 33.792945000000032],
              [73.131192008000085, 33.791205116000071],
              [73.129991736000079, 33.790786789000038],
              [73.128981048000071, 33.790415538000047],
              [73.11880681100007, 33.78678211700003],
              [73.110052329000041, 33.783655718000034],
              [73.108233824000081, 33.783006294000074],
              [73.108233000000041, 33.783006000000057],
              [73.092992611000057, 33.789631734000068],
              [73.092992000000038, 33.78963200000004],
              [73.091182218000029, 33.789430947000028],
              [73.081065000000081, 33.788307000000032],
              [73.074228069000071, 33.786554049000074],
              [73.06939539800004, 33.785323387000062],
              [73.055222321000031, 33.781681082000034],
              [73.055222000000072, 33.781681000000049],
              [73.050584146000062, 33.785655018000057],
              [73.050583000000074, 33.785656000000074],
              [73.040806964000069, 33.780481166000072],
              [73.039318000000037, 33.779693000000066],
              [73.028185094000037, 33.776621254000077],
              [73.021327951000046, 33.774729259000026],
              [73.020102000000065, 33.774391000000037],
              [73.014166294000063, 33.765063553000061],
              [73.010825000000068, 33.759813000000065],
              [72.998044108000045, 33.752737839000076],
              [72.977351573000078, 33.741283003000035],
              [72.973717000000079, 33.739271000000031],
              [72.948537000000044, 33.729995000000031],
              [72.936610001000076, 33.732976375000078],
              [72.936608469000078, 33.732976758000063],
              [72.934938832000057, 33.733394115000067],
              [72.927894102000039, 33.735155076000069],
              [72.924682000000075, 33.735958000000039],
              [72.924580189000039, 33.736501652000072],
              [72.924069016000033, 33.739231223000047],
              [72.923717986000042, 33.741105665000077],
              [72.923085584000034, 33.744478335000053],
              [72.922695045000069, 33.746561523000025],
              [72.915305784000054, 33.748311340000043],
              [72.913596126000073, 33.748716197000078],
              [72.911250433000077, 33.749271669000052],
              [72.908232747000056, 33.749986274000037],
              [72.907873669000082, 33.750070501000039],
              [72.901301557000068, 33.751627136000025],
              [72.89751300000006, 33.752524000000051],
              [72.89338706500007, 33.754308989000037],
              [72.883336468000039, 33.758655039000075],
              [72.883188673000063, 33.758718922000071],
              [72.882722684000044, 33.758920449000072],
              [72.882717317000072, 33.758922770000027],
              [72.882710650000035, 33.758925653000063],
              [72.882710146000079, 33.758925871000031],
              [72.881964529000072, 33.759248332000027],
              [72.872996000000057, 33.763127000000054],
              [72.868880839000042, 33.764155273000028],
              [72.866202247000047, 33.764825310000049],
              [72.863065343000073, 33.765609477000055],
              [72.863057251000043, 33.765611499000045],
              [72.863057001000072, 33.765611562000061],
              [72.863056501000074, 33.765611687000046],
              [72.860960162000083, 33.766135733000056],
              [72.85981028100008, 33.76642318200004],
              [72.859743250000065, 33.766439938000076],
              [72.85974312500008, 33.766439969000032],
              [72.859743000000037, 33.766440000000046],
              [72.859637579000037, 33.766436233000036],
              [72.854893018000041, 33.766266693000034],
              [72.851386705000039, 33.766141400000038],
              [72.845179833000032, 33.765919607000058],
              [72.841189413000052, 33.765777014000037],
              [72.841189000000043, 33.765777000000071],
              [72.839432891000058, 33.766069710000068],
              [72.837997801000029, 33.766308923000054],
              [72.835280923000084, 33.766761762000044],
              [72.832963167000059, 33.767148087000066],
              [72.829263002000062, 33.767764833000058],
              [72.829262000000028, 33.767765000000054],
              [72.829241785000079, 33.767816785000036],
              [72.829236054000035, 33.767831468000054],
              [72.827200196000035, 33.772920651000049],
              [72.827196436000065, 33.77293005000007],
              [72.826248969000062, 33.775298501000066],
              [72.820800934000033, 33.788917351000066],
              [72.819985000000031, 33.790957000000049],
              [72.819985185000064, 33.790957352000078],
              [72.821772709000072, 33.794354080000062],
              [72.822616124000035, 33.795956771000078],
              [72.822692258000075, 33.79610144600008],
              [72.822693191000042, 33.796103220000077],
              [72.822826509000038, 33.796356552000077],
              [72.822838316000059, 33.796379088000037],
              [72.822953642000073, 33.796599161000074],
              [72.822954036000056, 33.79659991300008],
              [72.823002219000045, 33.796691859000077],
              [72.823447384000076, 33.79754136400004],
              [72.824841173000038, 33.800185579000072],
              [72.825059331000034, 33.800599454000064],
              [72.826117277000037, 33.802609808000057],
              [72.826370590000067, 33.803091163000033],
              [72.826611000000071, 33.803548000000035],
              [72.826611494000076, 33.803547989000037],
              [72.829703038000048, 33.803444884000044],
              [72.834438805000048, 33.803286937000053],
              [72.834536122000031, 33.803283691000047],
              [72.836730957000043, 33.803283691000047],
              [72.846496582000043, 33.802917480000076],
              [72.853881836000085, 33.79968261700003],
              [72.863098145000038, 33.804321289000029],
              [72.864061575000051, 33.805672767000033],
              [72.870345000000043, 33.814813000000072],
              [72.86761191100004, 33.823012266000035],
              [72.867212772000073, 33.824209683000049],
              [72.867032000000052, 33.824752000000046],
              [72.865912706000074, 33.825344585000039],
              [72.863672867000048, 33.826530417000072],
              [72.859388231000082, 33.828798821000078],
              [72.855767000000071, 33.830716000000052],
              [72.851743317000057, 33.832476219000057],
              [72.848795365000058, 33.833765844000027],
              [72.845231661000071, 33.835324839000066],
              [72.845226299000046, 33.83532718400005],
              [72.845165080000072, 33.835353965000024],
              [72.845165000000065, 33.835354000000052],
              [72.844842883000069, 33.83532718400005],
              [72.844112324000037, 33.835266366000042],
              [72.837213000000077, 33.834692000000075],
              [72.83303630100005, 33.832353038000065],
              [72.82863323600003, 33.829887312000039],
              [72.827279691000058, 33.829129323000075],
              [72.823606432000076, 33.827072289000057],
              [72.821071798000048, 33.825652888000036],
              [72.82064745200006, 33.825415253000074],
              [72.820647000000065, 33.825415000000078],
              [72.818659000000082, 33.836017000000027],
              [72.822338501000047, 33.84232426300008],
              [72.823281343000076, 33.843940446000033],
              [72.823298001000069, 33.843969000000072],
              [72.823298500000078, 33.843969000000072],
              [72.826589321000029, 33.843969000000072],
              [72.833237000000054, 33.843969000000072],
              [72.833237239000084, 33.843969345000062],
              [72.83920100000006, 33.852583000000038],
              [72.840593467000076, 33.865578895000056],
              [72.841189000000043, 33.871137000000033],
              [72.838982682000051, 33.877755954000065],
              [72.838982615000077, 33.877756155000043],
              [72.83868411800006, 33.878651646000037],
              [72.838362013000051, 33.879617964000033],
              [72.837483096000028, 33.882254713000066],
              [72.835888000000068, 33.88704000000007],
              [72.832427358000075, 33.887525145000041],
              [72.821973000000071, 33.889028000000053],
              [72.817159367000045, 33.888315028000079],
              [72.816477346000056, 33.888214011000059],
              [72.816267779000043, 33.888182971000049],
              [72.804081500000052, 33.886378000000036],
              [72.804081258000053, 33.886378038000032],
              [72.804081000000053, 33.886378000000036],
              [72.803983850000066, 33.886393419000058],
              [72.800447443000053, 33.886954703000072],
              [72.79576938200006, 33.887690509000038],
              [72.795769004000078, 33.887690569000029],
              [72.795640500000047, 33.887710781000067],
              [72.791491000000065, 33.88836599800004],
              [72.790620755000077, 33.891325040000027],
              [72.788330078000058, 33.898681641000053],
              [72.788189748000036, 33.899591053000051],
              [72.788178000000073, 33.899631000000056],
              [72.78812537400006, 33.900701018000063],
              [72.788091660000077, 33.901323318000038],
              [72.787893719000067, 33.90541113200004],
              [72.787568805000035, 33.912017468000045],
              [72.787549973000068, 33.912385941000025],
              [72.787397385000077, 33.915456772000027],
              [72.787377216000039, 33.915905222000049],
              [72.787328184000046, 33.916898529000036],
              [72.787327987000083, 33.916902507000032],
              [72.786253681000062, 33.938666090000027],
              [72.786190000000033, 33.940052000000037],
              [72.77734530400005, 33.946589334000066],
              [72.770949118000033, 33.951316912000038],
              [72.770948999000041, 33.951317000000074],
              [72.767401003000032, 33.950497990000031],
              [72.765086122000071, 33.949963827000033],
              [72.762335000000064, 33.949329000000034],
              [72.762334275000057, 33.949328223000066],
              [72.762330578000046, 33.949324262000061],
              [72.758062979000044, 33.944751671000063],
              [72.757335178000062, 33.943971855000029],
              [72.753058000000067, 33.939389000000062],
              [72.75265792700003, 33.939180970000052],
              [72.749181155000031, 33.937373115000071],
              [72.748440045000052, 33.93698775200005],
              [72.748282755000048, 33.936905705000072],
              [72.746504513000048, 33.935978114000079],
              [72.742110625000066, 33.933696317000056],
              [72.74210987500004, 33.933695927000031],
              [72.741721975000075, 33.933494486000029],
              [72.737766774000079, 33.931437858000038],
              [72.736492595000072, 33.930775310000058],
              [72.731870651000065, 33.926729202000047],
              [72.72949028000005, 33.924646378000034],
              [72.727686683000059, 33.92307014000005],
              [72.725890244000084, 33.921498214000053],
              [72.725890000000049, 33.921498000000042],
              [72.733841000000041, 33.913546000000053],
              [72.735828635000075, 33.907583098000032],
              [72.73582900100007, 33.907582000000048],
              [72.725157243000069, 33.906692854000028],
              [72.719926000000044, 33.906257000000039],
              [72.719323367000072, 33.906056122000052],
              [72.714875049000057, 33.904573349000032],
              [72.709571524000069, 33.902805508000029],
              [72.708129847000066, 33.902324949000047],
              [72.707998000000032, 33.902281000000073],
              [72.708129847000066, 33.901709707000066],
              [72.709986000000072, 33.89366700000005],
              [72.709667705000072, 33.887931922000064],
              [72.709551255000065, 33.88583370300006],
              [72.709468069000081, 33.88433486100007],
              [72.709325790000037, 33.881738663000078],
              [72.707623405000049, 33.881550235000077],
              [72.703362815000048, 33.881077312000059],
              [72.703360000000032, 33.881077000000062],
              [72.701267073000054, 33.87619367800005],
              [72.70052207100008, 33.874455402000024],
              [72.699484228000074, 33.872033857000076],
              [72.699384689000055, 33.871801376000064],
              [72.699384581000061, 33.871801356000049],
              [72.699384000000066, 33.871800000000064],
              [72.699379538000073, 33.871799142000043],
              [72.68539957400003, 33.869110750000061],
              [72.682671182000036, 33.868586071000038],
              [72.682157516000075, 33.868486404000066],
              [72.681573868000044, 33.868387222000024],
              [72.678071188000047, 33.867810832000032],
              [72.674907794000035, 33.867278814000031],
              [72.666252000000043, 33.865836000000058],
              [72.666063185000041, 33.865823415000079],
              [72.664560297000037, 33.865723242000058],
              [72.659826068000029, 33.86540769100003],
              [72.65374799500006, 33.865002567000033],
              [72.646373435000044, 33.864511029000028],
              [72.64637300000004, 33.86451100000005],
              [72.644423907000032, 33.865377046000049],
              [72.64290679700008, 33.866051147000064],
              [72.640409000000034, 33.867161000000067],
              [72.638786147000076, 33.876356896000061],
              [72.63842100800008, 33.878425953000033],
              [72.628047305000052, 33.877438159000064],
              [72.624506500000052, 33.877101000000039],
              [72.606615000000033, 33.874450000000024],
              [72.606615669000064, 33.874452680000047],
              [72.606615067000064, 33.874452591000079],
              [72.606872559000067, 33.875488281000059],
              [72.606888391000041, 33.875544338000054],
              [72.607058623000057, 33.876225387000034],
              [72.608540098000049, 33.882154267000033],
              [72.608543350000048, 33.88216535600003],
              [72.611407908000047, 33.891934270000036],
              [72.611877441000047, 33.893676758000026],
              [72.614446128000054, 33.895276924000029],
              [72.619205000000079, 33.89830500000005],
              [72.623087727000041, 33.900523642000053],
              [72.628479004000042, 33.903686523000033],
              [72.628723145000038, 33.908874512000068],
              [72.62884796600008, 33.910566531000029],
              [72.629144000000053, 33.916197000000068],
              [72.629263881000043, 33.916204485000037],
              [72.639747000000057, 33.916859000000045],
              [72.639753134000046, 33.916862904000027],
              [72.64833993600007, 33.922327285000051],
              [72.652751304000049, 33.925134548000074],
              [72.65428174300007, 33.926108472000067],
              [72.654296911000074, 33.926118125000073],
              [72.654325000000028, 33.926136000000042],
              [72.654296911000074, 33.92617612600003],
              [72.651132861000065, 33.930696248000061],
              [72.645048000000031, 33.939389000000062],
              [72.643357939000055, 33.940169043000026],
              [72.62800930700007, 33.947253165000063],
              [72.627819000000045, 33.947341000000051],
              [72.625874536000083, 33.947793166000054],
              [72.608027686000071, 33.951943276000065],
              [72.599325117000035, 33.953966973000036],
              [72.599325000000078, 33.953967000000034],
              [72.598831547000032, 33.953931759000056],
              [72.580772000000081, 33.952642000000026],
              [72.568706494000082, 33.959277891000056],
              [72.567519000000061, 33.95993100000004],
              [72.55323971100006, 33.961120641000036],
              [72.55212405900005, 33.961213589000067],
              [72.55210955900003, 33.961214796000036],
              [72.551614999000037, 33.961256000000049],
              [72.547491000000036, 33.990130000000079],
              [72.547491215000036, 33.990130020000038],
              [72.547667268000055, 33.990143871000043],
              [72.564631076000069, 33.991478409000024],
              [72.572062000000074, 33.99206300000003],
              [72.581341647000045, 33.996989442000029],
              [72.588259024000081, 34.00066178600008],
              [72.594515000000058, 34.003983000000062],
              [72.606480834000081, 34.009437481000077],
              [72.606942773000071, 34.009648050000067],
              [72.613366000000042, 34.012576000000024],
              [72.624197553000045, 34.015516104000028],
              [72.63277000000005, 34.017843000000028],
              [72.635016343000075, 34.019535949000044],
              [72.636136953000062, 34.020380493000062],
              [72.641396851000081, 34.024344598000027],
              [72.641397096000048, 34.024344030000066],
              [72.646020487000044, 34.027828432000035],
              [72.646019824000064, 34.027828622000072],
              [72.648230071000057, 34.029494431000046],
              [72.64941495100004, 34.03038723800006],
              [72.651020216000063, 34.031597215000033],
              [72.651897000000076, 34.03225800000007],
              [72.652775940000083, 34.033001700000057],
              [72.655740600000058, 34.035510195000029],
              [72.660598508000078, 34.039620629000069],
              [72.662851811000053, 34.04152722200007],
              [72.664941612000064, 34.043295470000032],
              [72.666596011000081, 34.044695311000055],
              [72.675806311000031, 34.052488445000051],
              [72.680727000000047, 34.056652000000042],
              [72.683540209000057, 34.060505775000024],
              [72.68376733100007, 34.060816905000024],
              [72.684189124000056, 34.061394479000057],
              [72.684289867000075, 34.061532430000057],
              [72.684377798000071, 34.061652927000068],
              [72.684888477000072, 34.062352747000034],
              [72.688227912000059, 34.066927389000057],
              [72.693054346000054, 34.073539051000068],
              [72.69525217100005, 34.076549819000036],
              [72.695291765000036, 34.076604058000044],
              [72.700963000000058, 34.084373000000028],
              [72.714823000000081, 34.094907000000035],
              [72.719123643000046, 34.096818253000038],
              [72.726096613000038, 34.099917118000064],
              [72.734782001000042, 34.103777000000036],
              [72.738459048000038, 34.109360611000056],
              [72.742267000000084, 34.115143000000046],
              [72.754464000000041, 34.122073000000057],
              [72.754404717000057, 34.12416238600008],
              [72.754070453000054, 34.13594334000004],
              [72.753716581000049, 34.148415291000049],
              [72.753714435000063, 34.148490934000051],
              [72.753714433000084, 34.148491026000045],
              [72.753356388000043, 34.161127706000059],
              [72.753355502000034, 34.161159000000055],
              [72.753356367000038, 34.161160014000075],
              [72.753356587000042, 34.161160273000064],
              [72.753362591000041, 34.161167324000075],
              [72.753700443000071, 34.161562521000064],
              [72.766117301000065, 34.176088016000051],
              [72.766119827000068, 34.176090970000075],
              [72.768047810000041, 34.178345777000061],
              [72.768048000000078, 34.178346000000033],
              [72.756442263000054, 34.197073893000038],
              [72.755851000000064, 34.198028000000079],
              [72.756577887000049, 34.200743126000077],
              [72.760408892000044, 34.215052997000043],
              [72.76340997300008, 34.226262870000028],
              [72.764558699000077, 34.23055368100006],
              [72.765276000000028, 34.233233000000041],
              [72.770581375000063, 34.241357340000036],
              [72.77414600000003, 34.246816000000024],
              [72.771197928000049, 34.261800036000068],
              [72.770819000000074, 34.263726000000077],
              [72.76111700000007, 34.273706000000061],
              [72.760657865000042, 34.273968889000059],
              [72.757892071000072, 34.275552513000036],
              [72.74947493500008, 34.280358466000052],
              [72.749474000000077, 34.280359000000033],
              [72.74890726700005, 34.284397962000071],
              [72.747662403000049, 34.293269792000046],
              [72.747257000000047, 34.296159000000046],
              [72.745388365000053, 34.298089870000069],
              [72.738940036000031, 34.304752963000055],
              [72.738486590000036, 34.30472224500005],
              [72.728379993000033, 34.304333349000046],
              [72.728376383000068, 34.304346481000039],
              [72.71783023100005, 34.303940672000067],
              [72.717319000000032, 34.303921000000059],
              [72.714987466000082, 34.303696823000053],
              [72.714960708000035, 34.303694528000051],
              [72.712811000000045, 34.303488000000073],
              [72.712884000000031, 34.303644000000077],
              [72.719259000000079, 34.310297000000048],
              [72.723252994000063, 34.319481386000064],
              [72.724804000000063, 34.323048000000028],
              [72.735337000000072, 34.339681000000041],
              [72.738959254000065, 34.346537242000068],
              [72.739306211000041, 34.347193966000077],
              [72.739675650000038, 34.347893244000034],
              [72.741782674000035, 34.351881442000035],
              [72.743099000000029, 34.354373000000066],
              [72.744797000000062, 34.354858000000036],
              [72.744796938000036, 34.354858125000078],
              [72.752801000000034, 34.35714500000006],
              [72.758755970000038, 34.361561966000068],
              [72.764444000000083, 34.365738000000078],
              [72.772892541000033, 34.362731032000056],
              [72.780799000000059, 34.359917000000053],
              [72.792719000000034, 34.353264000000024],
              [72.800075216000039, 34.350031391000073],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "73",
      properties: { name: "Karak", count: 134 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.102675638000051, 33.369478826000034],
            [71.106978000000083, 33.368002000000047],
            [71.13485200000008, 33.369789000000026],
            [71.136295009000037, 33.369858521000026],
            [71.14023314700006, 33.370048252000061],
            [71.164513000000056, 33.371218000000056],
            [71.175150213000052, 33.370741740000028],
            [71.188456000000031, 33.370146000000034],
            [71.20328515500006, 33.367979792000028],
            [71.203311132000067, 33.36797599700003],
            [71.210523819000059, 33.366922385000066],
            [71.22026100000005, 33.365500000000054],
            [71.224192000000073, 33.35585100000003],
            [71.24170300000003, 33.356209000000035],
            [71.252026930000056, 33.356309131000046],
            [71.25203661300003, 33.356309225000075],
            [71.271980669000072, 33.356502662000025],
            [71.278511000000037, 33.356566000000043],
            [71.285022157000071, 33.354872983000064],
            [71.296379000000059, 33.351920000000064],
            [71.313705347000052, 33.351290594000034],
            [71.313818397000034, 33.351286487000039],
            [71.316034000000059, 33.351206000000047],
            [71.31789658100007, 33.334844769000028],
            [71.317902317000062, 33.33479438300003],
            [71.317902353000079, 33.334794067000075],
            [71.319290239000054, 33.322602636000056],
            [71.321037994000051, 33.307250054000065],
            [71.324937369000054, 33.307460055000035],
            [71.367495000000076, 33.30975200000006],
            [71.367809838000028, 33.30975200000006],
            [71.374292170000047, 33.30975200000006],
            [71.375212114000078, 33.30975200000006],
            [71.37521289600005, 33.30975200000006],
            [71.385676283000066, 33.30975200000006],
            [71.385720000000049, 33.30975200000006],
            [71.38320445800008, 33.294097915000066],
            [71.382529290000036, 33.289896380000073],
            [71.382615216000033, 33.289744444000064],
            [71.391714971000056, 33.290108068000052],
            [71.391894946000036, 33.29011526000005],
            [71.400372000000061, 33.290454000000068],
            [71.417168000000061, 33.286523000000045],
            [71.422828051000067, 33.291850435000072],
            [71.423142119000033, 33.292146046000028],
            [71.42727639800006, 33.296037374000036],
            [71.42931800000008, 33.297959000000048],
            [71.434532417000071, 33.298793342000067],
            [71.447186000000045, 33.300818000000049],
            [71.447295157000042, 33.294498192000049],
            [71.447536957000068, 33.280498752000028],
            [71.44754400000005, 33.280091000000027],
            [71.448258000000067, 33.264724000000058],
            [71.450595000000078, 33.248368000000028],
            [71.451117000000067, 33.24471200000005],
            [71.443317586000035, 33.236262649000025],
            [71.443317191000062, 33.236262220000071],
            [71.443317000000036, 33.236262000000067],
            [71.438379436000048, 33.240301643000066],
            [71.43837921800008, 33.240301822000049],
            [71.418500000000051, 33.227712000000054],
            [71.413199000000077, 33.217110000000048],
            [71.411211000000037, 33.206508000000042],
            [71.408044307000068, 33.195106760000044],
            [71.407898000000046, 33.19458000000003],
            [71.413199000000077, 33.188616000000025],
            [71.429765000000032, 33.18331500000005],
            [71.435035182000036, 33.17738567300006],
            [71.435050372000035, 33.177368583000032],
            [71.435066000000063, 33.177351000000044],
            [71.430428000000063, 33.168737000000078],
            [71.437717000000077, 33.156810000000064],
            [71.443018000000052, 33.150846000000058],
            [71.441030000000069, 33.139581000000078],
            [71.448319000000083, 33.129641000000049],
            [71.442355000000077, 33.123015000000066],
            [71.43655859100005, 33.114490960000069],
            [71.43109000000004, 33.106449000000055],
            [71.42740806900008, 33.099698525000065],
            [71.427408043000071, 33.099698476000071],
            [71.424229000000082, 33.093870000000038],
            [71.423139000000049, 33.091871000000026],
            [71.423183762000065, 33.091766548000066],
            [71.427114000000074, 33.082594000000029],
            [71.428605790000063, 33.080915789000073],
            [71.437717000000077, 33.070666000000074],
            [71.447656000000052, 33.068678000000034],
            [71.458921000000032, 33.069341000000065],
            [71.460909000000072, 33.062715000000026],
            [71.472174000000052, 33.057413000000054],
            [71.477475001000073, 33.048798999000041],
            [71.46356000000003, 33.039522000000034],
            [71.462551552000036, 33.032463370000073],
            [71.461572000000046, 33.025607000000036],
            [71.451632000000075, 33.01301600000005],
            [71.436392000000069, 33.007053000000042],
            [71.430841059000045, 33.004854428000044],
            [71.423139000000049, 33.003739000000053],
            [71.415850000000034, 33.009041000000025],
            [71.410052269000062, 33.007780342000046],
            [71.400609000000031, 33.005727000000036],
            [71.385368000000028, 32.998438000000078],
            [71.378079000000071, 33.004402000000027],
            [71.365489000000082, 32.996450000000038],
            [71.356875000000059, 32.99048700000003],
            [71.35687450000006, 32.99048700000003],
            [71.356874000000062, 32.99048700000003],
            [71.355039602000033, 32.99048700000003],
            [71.336995000000059, 32.99048700000003],
            [71.32241700000003, 32.999101000000053],
            [71.31446518000007, 33.015003641000078],
            [71.303863000000035, 33.010366000000033],
            [71.296185146000084, 33.009127721000027],
            [71.283321000000058, 33.007053000000042],
            [71.270344811000029, 33.001063526000053],
            [71.266093000000069, 32.999101000000053],
            [71.252177000000074, 32.999101000000053],
            [71.235611000000063, 32.993137000000047],
            [71.222102412000083, 32.981389670000056],
            [71.220371000000057, 32.979884000000027],
            [71.210431000000028, 32.977234000000067],
            [71.193202000000042, 32.976571000000035],
            [71.188432448000071, 32.973536128000035],
            [71.185913000000028, 32.971933000000035],
            [71.170672000000081, 32.961993000000064],
            [71.158744999000078, 32.947415001000024],
            [71.159408000000042, 32.938138000000038],
            [71.15410600000007, 32.936150000000055],
            [71.141516000000081, 32.922897000000034],
            [71.140853500000048, 32.908319000000063],
            [71.142179000000056, 32.893741000000034],
            [71.145367307000072, 32.875247280000053],
            [71.145492000000047, 32.874524000000065],
            [71.138866000000064, 32.867235000000051],
            [71.136635529000046, 32.853405140000064],
            [71.135553000000073, 32.846693000000073],
            [71.135461296000074, 32.845629013000064],
            [71.133564500000034, 32.823501000000078],
            [71.133564500000034, 32.823392962000071],
            [71.133564500000034, 32.80892300000005],
            [71.132442737000076, 32.808362083000077],
            [71.125613000000044, 32.804947000000027],
            [71.115010000000041, 32.806935000000067],
            [71.107116663000056, 32.806935000000067],
            [71.107059000000049, 32.806935000000067],
            [71.107116663000056, 32.806799141000056],
            [71.108890000000031, 32.802621000000045],
            [71.108889548000036, 32.802621123000051],
            [71.108765000000062, 32.802655000000073],
            [71.107163764000063, 32.802866514000073],
            [71.092404701000078, 32.804816104000054],
            [71.089824000000078, 32.805157000000065],
            [71.087696728000083, 32.805129678000071],
            [71.079617760000076, 32.805025916000034],
            [71.070929775000081, 32.804914332000067],
            [71.066267911000068, 32.804854457000033],
            [71.062125062000064, 32.804801248000047],
            [71.061950000000081, 32.80479900000006],
            [71.061039559000051, 32.804927061000058],
            [71.056920554000044, 32.805506434000051],
            [71.047091938000051, 32.806888912000034],
            [71.04179195100005, 32.80763440000004],
            [71.039079000000072, 32.808016000000066],
            [71.037503653000044, 32.808142989000032],
            [71.035453073000042, 32.808308287000045],
            [71.03230712800007, 32.808561882000049],
            [71.016923000000077, 32.809802000000047],
            [70.995481000000041, 32.814091000000076],
            [70.993919081000058, 32.815483561000065],
            [70.989252237000073, 32.819732075000047],
            [70.983702175000076, 32.824758486000064],
            [70.976668496000059, 32.831128533000026],
            [70.976541000000054, 32.831244000000027],
            [70.97629160200006, 32.831511220000039],
            [70.975432200000057, 32.832383829000037],
            [70.973117698000067, 32.834863719000055],
            [70.968288812000083, 32.840037664000079],
            [70.966519306000066, 32.841933613000037],
            [70.958541358000048, 32.857444913000052],
            [70.953653306000035, 32.866948613000034],
            [70.953605306000043, 32.867237613000043],
            [70.953621215000055, 32.867267707000053],
            [70.957958000000076, 32.868052000000034],
            [70.961531000000036, 32.88341900000006],
            [70.961643541000058, 32.892518507000034],
            [70.961889000000042, 32.912365000000079],
            [70.952326209000034, 32.935192948000065],
            [70.950811000000044, 32.938810000000046],
            [70.943663672000071, 32.949780058000044],
            [70.935444000000075, 32.962396000000069],
            [70.932893390000061, 32.965846916000032],
            [70.917922265000072, 32.986102499000026],
            [70.917219000000046, 32.987054000000057],
            [70.91816398800006, 32.998079596000025],
            [70.919363000000033, 33.012069000000054],
            [70.915110000000084, 33.012793000000045],
            [70.902567000000033, 33.014928000000054],
            [70.878981000000067, 33.019574000000034],
            [70.851903855000046, 33.022738593000042],
            [70.851464000000078, 33.022790000000043],
            [70.838533806000044, 33.028856193000024],
            [70.822518000000059, 33.036370000000034],
            [70.81695078000007, 33.044973982000045],
            [70.806794000000082, 33.06067100000007],
            [70.787854000000038, 33.091761000000076],
            [70.787352038000051, 33.092363753000029],
            [70.787351000000058, 33.092365000000029],
            [70.794955000000073, 33.096509000000026],
            [70.794292000000041, 33.111750000000029],
            [70.804894000000047, 33.118376000000069],
            [70.820798000000082, 33.117714000000035],
            [70.825436000000082, 33.134280000000047],
            [70.839352000000076, 33.153496000000075],
            [70.856580000000065, 33.158798000000047],
            [70.867133006000074, 33.165392993000069],
            [70.872484000000043, 33.168737000000078],
            [70.872484000000043, 33.183978000000025],
            [70.869171000000051, 33.189279000000056],
            [70.851942000000065, 33.193917000000056],
            [70.838027000000068, 33.201869000000045],
            [70.824111000000073, 33.209821000000034],
            [70.809533000000044, 33.209821000000034],
            [70.793630000000064, 33.209821000000034],
            [70.790092597000069, 33.209076210000035],
            [70.781039000000078, 33.207170000000076],
            [70.771762000000081, 33.215785000000039],
            [70.757847000000083, 33.215122000000065],
            [70.757507000000032, 33.215086000000042],
            [70.757507142000065, 33.215087871000037],
            [70.759265000000084, 33.238279000000034],
            [70.759622000000036, 33.249358000000029],
            [70.766054000000054, 33.272229000000038],
            [70.763910000000067, 33.280805000000043],
            [70.763919376000047, 33.280870630000038],
            [70.764035820000061, 33.281685742000036],
            [70.764035835000072, 33.281685838000044],
            [70.766769000000068, 33.300818000000049],
            [70.761051000000066, 33.310824000000025],
            [70.762933308000072, 33.315312207000034],
            [70.770324707000043, 33.333129883000026],
            [70.781947537000065, 33.333607534000066],
            [70.783620540000072, 33.33367628700006],
            [70.816443000000049, 33.322617000000037],
            [70.845897940000043, 33.316164435000076],
            [70.853966000000071, 33.314397000000042],
            [70.88022899300006, 33.313772316000041],
            [70.882400406000045, 33.313720667000041],
            [70.883984000000055, 33.313683000000026],
            [70.884008120000033, 33.313720667000041],
            [70.884009567000078, 33.313722927000072],
            [70.888485836000029, 33.320713333000072],
            [70.89911203500003, 33.337307833000068],
            [70.905136083000059, 33.346715342000039],
            [70.910071000000073, 33.354422000000056],
            [70.931156000000044, 33.354422000000056],
            [70.95303098100004, 33.35858219000005],
            [70.971654129000058, 33.362123945000064],
            [70.986329635000061, 33.364914936000048],
            [70.989406000000031, 33.365500000000054],
            [71.00763100000006, 33.36585800000006],
            [71.018811716000073, 33.364567609000062],
            [71.02947696800004, 33.363336709000066],
            [71.035505000000057, 33.362641000000053],
            [71.048370000000034, 33.361569000000031],
            [71.062804000000028, 33.378314000000046],
            [71.062805900000058, 33.378313803000026],
            [71.083034000000055, 33.376221000000044],
            [71.102675638000051, 33.369478826000034],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "74",
      properties: { name: "Kohat", count: 957 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.297237000000052, 33.647164000000032],
            [71.310490000000073, 33.643188000000066],
            [71.315552729000046, 33.639736329000073],
            [71.315559118000067, 33.639731973000039],
            [71.315565508000077, 33.639727617000062],
            [71.325068000000044, 33.633249000000035],
            [71.330513920000044, 33.629877343000032],
            [71.330525453000064, 33.629870203000053],
            [71.338983000000042, 33.624634000000071],
            [71.349585000000047, 33.623972000000037],
            [71.349585079000065, 33.623972026000047],
            [71.352374595000072, 33.624901771000054],
            [71.359525000000076, 33.627285000000029],
            [71.359525000000076, 33.641200000000026],
            [71.363501000000042, 33.645839000000024],
            [71.373441000000071, 33.649815000000046],
            [71.375583773000074, 33.650350636000042],
            [71.375596603000076, 33.650353842000072],
            [71.375609434000069, 33.650357050000025],
            [71.391723909000063, 33.654385235000063],
            [71.39176481800007, 33.654395461000036],
            [71.391995000000065, 33.654453000000046],
            [71.396243259000073, 33.652073965000056],
            [71.396248924000076, 33.652070792000075],
            [71.408109595000042, 33.645428788000061],
            [71.408561000000077, 33.645176000000049],
            [71.419163000000083, 33.643188000000066],
            [71.422476000000074, 33.650477000000024],
            [71.432083361000082, 33.655563401000052],
            [71.432294385000034, 33.655675123000037],
            [71.433741000000055, 33.656441000000029],
            [71.435193079000044, 33.655279337000024],
            [71.443681000000083, 33.648489000000041],
            [71.456933000000049, 33.642526000000032],
            [71.458234516000061, 33.641718149000042],
            [71.462741832000063, 33.638920455000061],
            [71.462749271000064, 33.638915838000059],
            [71.462756710000065, 33.638911221000058],
            [71.471975601000054, 33.633189051000045],
            [71.476150000000075, 33.630598000000077],
            [71.49470400000007, 33.631261000000052],
            [71.52187200000003, 33.631261000000052],
            [71.528093376000072, 33.633104358000026],
            [71.53976300000005, 33.636562000000026],
            [71.554342000000076, 33.643851000000041],
            [71.566269000000034, 33.637225000000058],
            [71.580185000000029, 33.635237000000075],
            [71.59562950000003, 33.63476872800004],
            [71.595639523000045, 33.634768424000072],
            [71.595652051000059, 33.634768044000054],
            [71.602052000000072, 33.634574000000043],
            [71.606690000000071, 33.635237000000075],
            [71.626569000000075, 33.643851000000041],
            [71.634521000000063, 33.643851000000041],
            [71.630545000000041, 33.629936000000043],
            [71.616630000000043, 33.616020000000049],
            [71.609341000000029, 33.601442000000077],
            [71.602052000000072, 33.59216500000008],
            [71.595767923000039, 33.585398037000061],
            [71.593437000000051, 33.582888000000025],
            [71.591339646000051, 33.582888000000025],
            [71.57885900000008, 33.582888000000025],
            [71.566932000000065, 33.579575000000034],
            [71.571570000000065, 33.570298000000037],
            [71.579522000000054, 33.564997000000062],
            [71.580104262000077, 33.564414738000039],
            [71.580140923000044, 33.564378068000053],
            [71.580140972000038, 33.564378020000049],
            [71.590785805000053, 33.553739498000027],
            [71.598965643000042, 33.546040357000038],
            [71.598967461000029, 33.546038646000056],
            [71.602050805000033, 33.543136498000024],
            [71.61331580500007, 33.545124498000064],
            [71.628555805000076, 33.547775498000078],
            [71.638903575000029, 33.553253891000054],
            [71.639820793000069, 33.55373951200005],
            [71.663013793000061, 33.55373951200005],
            [71.679579793000073, 33.549100512000052],
            [71.685892484000078, 33.552454241000078],
            [71.685894025000039, 33.552446906000057],
            [71.700785000000053, 33.560358000000065],
            [71.723978000000045, 33.566322000000071],
            [71.734580000000051, 33.576262000000042],
            [71.756447000000037, 33.576262000000042],
            [71.768375000000049, 33.589514000000065],
            [71.772592775000078, 33.591763988000025],
            [71.772596328000077, 33.591765885000029],
            [71.772599882000065, 33.591767780000055],
            [71.77831400000008, 33.594816000000037],
            [71.783600580000041, 33.592701368000064],
            [71.788254000000052, 33.590840000000071],
            [71.788510153000061, 33.590951362000055],
            [71.789630468000041, 33.591438417000063],
            [71.790962340000078, 33.592017446000057],
            [71.803495000000055, 33.597466000000054],
            [71.826024000000075, 33.606080000000077],
            [71.833976000000064, 33.615357000000074],
            [71.841265000000078, 33.629273000000069],
            [71.825169951000078, 33.640002733000074],
            [71.823994603000074, 33.640786276000028],
            [71.823374000000058, 33.641200000000026],
            [71.801507000000072, 33.641863000000058],
            [71.800181000000066, 33.659092000000044],
            [71.800555089000056, 33.663082992000056],
            [71.801464899000052, 33.67278648000007],
            [71.801503496000066, 33.673198135000064],
            [71.801537619000044, 33.673562066000045],
            [71.802169000000049, 33.680296000000055],
            [71.80747000000008, 33.69885000000005],
            [71.814659000000063, 33.705194000000063],
            [71.814659147000043, 33.705193842000028],
            [71.814857000000075, 33.704987000000074],
            [71.82671960700003, 33.698641910000049],
            [71.836473000000069, 33.693425000000047],
            [71.855072000000064, 33.695436000000029],
            [71.868632926000032, 33.700629180000078],
            [71.871208950000039, 33.701615673000049],
            [71.871237949000033, 33.701626778000048],
            [71.87869900000004, 33.704484000000036],
            [71.88679118500005, 33.70683381300006],
            [71.894282000000032, 33.709009000000037],
            [71.916401000000064, 33.72459200000003],
            [71.933363729000064, 33.729159088000074],
            [71.942541000000062, 33.731630000000052],
            [71.955062167000051, 33.730822520000061],
            [71.955079487000035, 33.73082140300005],
            [71.95623902300008, 33.730746626000041],
            [71.956239143000062, 33.730746618000069],
            [71.958125000000052, 33.730625000000032],
            [71.969435562000058, 33.726854562000028],
            [71.973205000000064, 33.725598000000048],
            [71.973206000000062, 33.725598000000048],
            [71.990297000000055, 33.725598000000048],
            [72.008394000000067, 33.720571000000064],
            [72.017315345000043, 33.71887164900005],
            [72.017360920000044, 33.718858731000068],
            [72.017357752000066, 33.718856803000051],
            [72.017573913000035, 33.718781131000071],
            [72.01619209100005, 33.717859916000066],
            [72.016113281000059, 33.717895508000026],
            [72.006286621000072, 33.712097168000071],
            [71.999328613000046, 33.696716309000067],
            [71.998925329000031, 33.69585524300004],
            [71.996986000000049, 33.691561000000036],
            [71.992299259000049, 33.681360423000058],
            [71.990905762000068, 33.678283691000047],
            [71.988345715000037, 33.67274678900003],
            [71.987715773000048, 33.671384343000057],
            [71.985721000000069, 33.667043000000035],
            [71.985385484000062, 33.661673731000064],
            [71.984396000000061, 33.645839000000024],
            [71.983653778000075, 33.636748109000052],
            [71.983646853000039, 33.636663288000079],
            [71.983270994000065, 33.632059692000041],
            [71.982137597000076, 33.61817760200006],
            [71.982121997000036, 33.617986533000078],
            [71.981949207000071, 33.615870163000068],
            [71.981875176000074, 33.614963432000025],
            [71.98178585200003, 33.613822521000031],
            [71.981745000000046, 33.613369000000034],
            [71.981716315000028, 33.613169030000051],
            [71.981634360000044, 33.612641952000047],
            [71.981475858000067, 33.611600389000046],
            [71.97876474900005, 33.593784858000049],
            [71.978489213000046, 33.591971922000027],
            [71.977107000000046, 33.582888000000025],
            [71.977106964000029, 33.582887983000035],
            [71.969832558000064, 33.579385762000072],
            [71.959215000000029, 33.574274000000059],
            [71.954577000000029, 33.564997000000062],
            [71.96257160600004, 33.551551276000055],
            [71.969003641000029, 33.540733562000071],
            [71.969155000000058, 33.540479000000062],
            [71.96869741200004, 33.539397400000041],
            [71.961866000000043, 33.523250000000075],
            [71.952800317000083, 33.512825115000055],
            [71.94861300000008, 33.50801000000007],
            [71.93536000000006, 33.497407000000067],
            [71.927838200000053, 33.49239255100008],
            [71.905541000000028, 33.477528000000063],
            [71.905059656000049, 33.477004775000069],
            [71.90501295100006, 33.476954006000028],
            [71.890301000000079, 33.460962000000052],
            [71.879692565000084, 33.456542264000063],
            [71.879692529000067, 33.456542249000051],
            [71.879547844000058, 33.456481970000027],
            [71.874397000000044, 33.454336000000069],
            [71.86710800000003, 33.447709000000032],
            [71.867326067000079, 33.442696098000056],
            [71.86733283500007, 33.442540525000027],
            [71.867771000000062, 33.432468000000028],
            [71.872409000000061, 33.423854000000063],
            [71.871105286000045, 33.41081390800008],
            [71.871084000000053, 33.410601000000042],
            [71.86895725800008, 33.409676402000059],
            [71.861083984000061, 33.405883789000029],
            [71.85713279600003, 33.404535736000071],
            [71.85584300000005, 33.403975000000059],
            [71.845241000000044, 33.405300000000068],
            [71.844482309000057, 33.40551676900003],
            [71.831325348000064, 33.409275901000058],
            [71.831325000000049, 33.409276000000034],
            [71.830356373000029, 33.408644232000029],
            [71.823987293000073, 33.404490120000048],
            [71.816085000000044, 33.399336000000062],
            [71.799519000000032, 33.391385000000071],
            [71.795724190000044, 33.389811570000063],
            [71.795685870000057, 33.389795681000066],
            [71.779195265000055, 33.382958232000078],
            [71.77875212500004, 33.382774494000046],
            [71.778700181000033, 33.382752957000037],
            [71.772350000000074, 33.380120000000034],
            [71.753058322000072, 33.372272069000076],
            [71.733255000000042, 33.364216000000056],
            [71.727515305000054, 33.356269703000066],
            [71.724670410000044, 33.352294922000056],
            [71.723348850000036, 33.350645702000065],
            [71.719925647000082, 33.346288855000068],
            [71.717351000000065, 33.343012000000044],
            [71.717960711000046, 33.342261663000045],
            [71.719909668000071, 33.339904785000044],
            [71.725280762000068, 33.333312988000046],
            [71.725354801000037, 33.333162169000047],
            [71.725948079000034, 33.332432054000037],
            [71.725966000000028, 33.332410000000039],
            [71.731929000000036, 33.319819000000052],
            [71.730574808000028, 33.314401210000028],
            [71.729007723000052, 33.308131689000049],
            [71.727954000000068, 33.303916000000072],
            [71.722586278000051, 33.300225976000036],
            [71.717351000000065, 33.296627000000058],
            [71.716628559000071, 33.295723835000047],
            [71.711387295000065, 33.289171432000046],
            [71.709400000000073, 33.286687000000029],
            [71.71006200000005, 33.274760000000072],
            [71.710496892000037, 33.271860791000051],
            [71.712065491000033, 33.26027282900003],
            [71.712120269000081, 33.25989166100004],
            [71.712712033000059, 33.246288242000048],
            [71.712712580000073, 33.246275647000061],
            [71.728090286000054, 33.231081009000036],
            [71.733076267000058, 33.227368643000034],
            [71.733917182000084, 33.226765707000027],
            [71.735391289000063, 33.225712803000079],
            [71.741869000000065, 33.221086000000071],
            [71.743725914000038, 33.219024126000079],
            [71.752247876000069, 33.209561534000045],
            [71.759760000000028, 33.201207000000068],
            [71.759913102000041, 33.19906472100007],
            [71.760572229000047, 33.189841924000064],
            [71.761086000000034, 33.182653000000073],
            [71.759760000000028, 33.168737000000078],
            [71.755851946000064, 33.158748293000031],
            [71.753797000000077, 33.153496000000075],
            [71.753739049000046, 33.153432255000041],
            [71.750954475000071, 33.150369287000046],
            [71.750776415000075, 33.150173425000048],
            [71.745267492000039, 33.144113735000076],
            [71.727922404000083, 33.12503453100004],
            [71.727291000000037, 33.124340000000075],
            [71.720041515000048, 33.11778128800006],
            [71.713500475000046, 33.111863518000064],
            [71.713375000000042, 33.111750000000029],
            [71.713355737000029, 33.111113715000045],
            [71.713354606000053, 33.111076362000063],
            [71.713237010000057, 33.107191943000032],
            [71.713235872000041, 33.107154356000024],
            [71.712745504000054, 33.090956651000056],
            [71.712713000000065, 33.089883000000043],
            [71.716129685000055, 33.075930947000074],
            [71.71863645500008, 33.065694535000034],
            [71.71926884100003, 33.06311218500008],
            [71.720189632000029, 33.059352128000057],
            [71.72066447800006, 33.057413088000033],
            [71.720664500000055, 33.057413000000054],
            [71.720663979000051, 33.057413087000043],
            [71.720664000000056, 33.057413000000054],
            [71.713085038000031, 33.058676399000035],
            [71.713085010000043, 33.05867640300005],
            [71.705561307000039, 33.059930590000079],
            [71.704761000000076, 33.060064000000068],
            [71.686870000000056, 33.062052000000051],
            [71.671629000000053, 33.073317000000031],
            [71.652412000000083, 33.073979000000065],
            [71.635184000000038, 33.089220000000068],
            [71.635046614000032, 33.089563466000072],
            [71.63502035700003, 33.089629105000029],
            [71.631208000000072, 33.09916000000004],
            [71.623256000000083, 33.108437000000038],
            [71.60431404600007, 33.131011467000064],
            [71.602769556000055, 33.132888692000051],
            [71.602714000000049, 33.132955000000038],
            [71.602472832000046, 33.134683708000068],
            [71.601578916000051, 33.141091343000028],
            [71.59873900000008, 33.161448000000064],
            [71.599401000000057, 33.187954000000047],
            [71.579522000000054, 33.199219000000028],
            [71.572896000000071, 33.209821000000034],
            [71.556992000000037, 33.219098000000031],
            [71.555667000000028, 33.227050000000077],
            [71.547053000000062, 33.229700000000037],
            [71.535788000000082, 33.223074000000054],
            [71.531938679000064, 33.221889645000033],
            [71.531925210000054, 33.221885501000031],
            [71.531911741000044, 33.221881357000029],
            [71.518559000000039, 33.217773000000079],
            [71.517896000000064, 33.210484000000065],
            [71.525185479000072, 33.19855603600007],
            [71.525185500000077, 33.198556000000053],
            [71.525184979000073, 33.198556035000024],
            [71.525185000000079, 33.198556000000053],
            [71.506663758000059, 33.199790505000067],
            [71.505306000000076, 33.199881000000062],
            [71.502293767000083, 33.20349604200004],
            [71.501993000000084, 33.203857000000028],
            [71.488740000000064, 33.215122000000065],
            [71.482066829000075, 33.216552017000026],
            [71.482008084000029, 33.21656460500003],
            [71.479463000000067, 33.217110000000048],
            [71.454945000000066, 33.219098000000031],
            [71.447656000000052, 33.225724000000071],
            [71.445668000000069, 33.234339000000034],
            [71.443317000000036, 33.236262000000067],
            [71.443317191000062, 33.236262220000071],
            [71.443317586000035, 33.236262649000025],
            [71.451117000000067, 33.24471200000005],
            [71.450595000000078, 33.248368000000028],
            [71.448258000000067, 33.264724000000058],
            [71.44754400000005, 33.280091000000027],
            [71.447536957000068, 33.280498752000028],
            [71.447295157000042, 33.294498192000049],
            [71.447186000000045, 33.300818000000049],
            [71.434532417000071, 33.298793342000067],
            [71.42931800000008, 33.297959000000048],
            [71.42727639800006, 33.296037374000036],
            [71.423142119000033, 33.292146046000028],
            [71.422828051000067, 33.291850435000072],
            [71.417168000000061, 33.286523000000045],
            [71.400372000000061, 33.290454000000068],
            [71.391894946000036, 33.29011526000005],
            [71.391714971000056, 33.290108068000052],
            [71.382615216000033, 33.289744444000064],
            [71.382529290000036, 33.289896380000073],
            [71.38320445800008, 33.294097915000066],
            [71.385720000000049, 33.30975200000006],
            [71.385676283000066, 33.30975200000006],
            [71.37521289600005, 33.30975200000006],
            [71.375212114000078, 33.30975200000006],
            [71.374292170000047, 33.30975200000006],
            [71.367809838000028, 33.30975200000006],
            [71.367495000000076, 33.30975200000006],
            [71.324937369000054, 33.307460055000035],
            [71.321037994000051, 33.307250054000065],
            [71.319290239000054, 33.322602636000056],
            [71.317902353000079, 33.334794067000075],
            [71.317902317000062, 33.33479438300003],
            [71.31789658100007, 33.334844769000028],
            [71.316034000000059, 33.351206000000047],
            [71.313818397000034, 33.351286487000039],
            [71.313705347000052, 33.351290594000034],
            [71.296379000000059, 33.351920000000064],
            [71.285022157000071, 33.354872983000064],
            [71.278511000000037, 33.356566000000043],
            [71.271980669000072, 33.356502662000025],
            [71.25203661300003, 33.356309225000075],
            [71.252026930000056, 33.356309131000046],
            [71.24170300000003, 33.356209000000035],
            [71.224192000000073, 33.35585100000003],
            [71.22026100000005, 33.365500000000054],
            [71.210523819000059, 33.366922385000066],
            [71.203311132000067, 33.36797599700003],
            [71.20328515500006, 33.367979792000028],
            [71.188456000000031, 33.370146000000034],
            [71.175150213000052, 33.370741740000028],
            [71.164513000000056, 33.371218000000056],
            [71.14023314700006, 33.370048252000061],
            [71.136295009000037, 33.369858521000026],
            [71.13485200000008, 33.369789000000026],
            [71.106978000000083, 33.368002000000047],
            [71.102675638000051, 33.369478826000034],
            [71.083034000000055, 33.376221000000044],
            [71.062805900000058, 33.378313803000026],
            [71.062804000000028, 33.378314000000046],
            [71.066238000000055, 33.382296000000053],
            [71.060163000000045, 33.396948000000066],
            [71.07588700000008, 33.403381000000024],
            [71.101617000000033, 33.404095000000041],
            [71.116477701000065, 33.414808978000053],
            [71.116984000000059, 33.415174000000036],
            [71.116831337000065, 33.415403385000047],
            [71.110948970000038, 33.424241904000041],
            [71.111006029000066, 33.424433055000065],
            [71.118056000000081, 33.448051000000078],
            [71.117940661000034, 33.44816025800003],
            [71.118050211000082, 33.448527259000059],
            [71.114629010000044, 33.451768079000033],
            [71.111378469000044, 33.454847237000024],
            [71.111260211000058, 33.454959259000077],
            [71.106409925000037, 33.462144962000025],
            [71.101611211000034, 33.469254259000024],
            [71.102527861000056, 33.470718528000077],
            [71.102533650000055, 33.470712739000078],
            [71.104834000000039, 33.475568000000067],
            [71.105189084000074, 33.475561159000051],
            [71.105192325000075, 33.475568000000067],
            [71.113640152000073, 33.475405245000047],
            [71.123774325000056, 33.475210000000061],
            [71.136996940000074, 33.474862395000059],
            [71.138068000000032, 33.485574000000042],
            [71.141710378000084, 33.498062048000065],
            [71.143071000000077, 33.50272700000005],
            [71.157723000000033, 33.50880200000006],
            [71.157851992000076, 33.508781986000031],
            [71.157900264000034, 33.50880200000006],
            [71.178566262000061, 33.505595466000045],
            [71.209183000000053, 33.508088000000043],
            [71.209185084000069, 33.508112312000037],
            [71.209564877000048, 33.512543354000059],
            [71.210255000000075, 33.520595000000071],
            [71.198462000000063, 33.52238200000005],
            [71.19384102500004, 33.529313462000061],
            [71.193824065000058, 33.529338902000063],
            [71.189063803000067, 33.536479296000039],
            [71.188818653000055, 33.536847021000028],
            [71.188456000000031, 33.537391000000071],
            [71.197748000000047, 33.548827000000074],
            [71.209967707000033, 33.556655392000039],
            [71.21002496300008, 33.556692073000079],
            [71.220619000000056, 33.563479000000029],
            [71.223509447000083, 33.571640291000051],
            [71.223510313000077, 33.571642736000058],
            [71.226433854000049, 33.57989746800007],
            [71.226656825000077, 33.580527037000024],
            [71.226694000000066, 33.580632000000037],
            [71.225003048000076, 33.586691901000052],
            [71.224995977000049, 33.586717239000052],
            [71.222406000000035, 33.595999000000063],
            [71.209898000000067, 33.591710000000035],
            [71.208899271000064, 33.591945037000073],
            [71.20162029100004, 33.593658044000051],
            [71.191673000000037, 33.595999000000063],
            [71.187596080000048, 33.598691046000056],
            [71.185214428000052, 33.600263683000037],
            [71.182394980000083, 33.602125403000059],
            [71.180050364000067, 33.603673585000024],
            [71.172732000000053, 33.608506000000034],
            [71.149504000000036, 33.596713000000079],
            [71.14102464900003, 33.597221723000075],
            [71.140998853000042, 33.597223271000075],
            [71.131636000000071, 33.597785000000044],
            [71.131227000000081, 33.59777900000006],
            [71.132902000000058, 33.599454000000037],
            [71.127601000000084, 33.604755000000068],
            [71.117661000000055, 33.604092000000037],
            [71.107059000000049, 33.602767000000028],
            [71.105071000000066, 33.609394000000066],
            [71.091155000000072, 33.612707000000057],
            [71.086517000000072, 33.618671000000063],
            [71.092481000000078, 33.62794800000006],
            [71.112360000000081, 33.640538000000049],
            [71.128263000000061, 33.648489000000041],
            [71.142841000000033, 33.648489000000041],
            [71.161395000000084, 33.644514000000072],
            [71.168933697000057, 33.645383866000032],
            [71.168941372000063, 33.645384751000051],
            [71.168946413000072, 33.645385333000036],
            [71.17862400000007, 33.646502000000055],
            [71.168022000000065, 33.654453000000046],
            [71.167027051000048, 33.65522689200003],
            [71.166862104000074, 33.655355192000059],
            [71.150131000000044, 33.668369000000041],
            [71.135552000000075, 33.678971000000047],
            [71.136581505000038, 33.684804688000042],
            [71.137540000000058, 33.690236000000027],
            [71.138203000000033, 33.70746500000007],
            [71.132902000000058, 33.720717000000036],
            [71.126938000000052, 33.725356000000033],
            [71.12296200000003, 33.732645000000048],
            [71.123691543000064, 33.736292715000047],
            [71.124950000000069, 33.742585000000076],
            [71.134227000000067, 33.745235000000037],
            [71.14748000000003, 33.742585000000076],
            [71.154769000000044, 33.735295000000065],
            [71.170010000000048, 33.733970000000056],
            [71.17319838700007, 33.733332291000067],
            [71.189889000000051, 33.729994000000033],
            [71.202479000000039, 33.724693000000059],
            [71.210431000000028, 33.72336800000005],
            [71.214703051000072, 33.717746809000062],
            [71.222515173000033, 33.707467571000052],
            [71.222519204000037, 33.70746226600005],
            [71.223021000000074, 33.706802000000039],
            [71.22898500000008, 33.690899000000059],
            [71.247569790000057, 33.672954464000043],
            [71.247575687000051, 33.672948771000051],
            [71.247581583000056, 33.67294307800006],
            [71.248201000000051, 33.672345000000064],
            [71.266755000000046, 33.661742000000061],
            [71.281333000000075, 33.652465000000063],
            [71.289589869000054, 33.649712884000053],
            [71.289640274000078, 33.649696083000038],
            [71.297237000000052, 33.647164000000032],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "75",
      properties: { name: "Upper Kohistan", count: 1101 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.559728001000053, 34.968043370000032],
            [73.54707090800008, 34.982494382000027],
            [73.54217090800006, 34.987994382000068],
            [73.531170908000036, 35.001194382000051],
            [73.515870908000068, 35.017794382000034],
            [73.498870908000072, 35.035094382000068],
            [73.481770908000044, 35.049494382000034],
            [73.478458315000069, 35.051602396000078],
            [73.456470908000028, 35.065594382000029],
            [73.439670908000039, 35.074094382000055],
            [73.422570908000068, 35.081294382000067],
            [73.403470908000031, 35.086294382000062],
            [73.390370908000079, 35.088394382000047],
            [73.384470908000083, 35.089294382000048],
            [73.372070908000069, 35.090394382000056],
            [73.366270908000047, 35.090894382000045],
            [73.358070908000059, 35.088694382000028],
            [73.334870908000084, 35.084494382000059],
            [73.314670908000039, 35.082894382000063],
            [73.299670908000053, 35.08309438200007],
            [73.282370908000075, 35.086394382000037],
            [73.26277090800005, 35.092794382000079],
            [73.246170908000067, 35.098894382000026],
            [73.238070908000054, 35.102594382000063],
            [73.233470908000072, 35.104694382000048],
            [73.230670908000036, 35.105994382000063],
            [73.201770908000071, 35.106994382000039],
            [73.17227090800003, 35.112194382000041],
            [73.169570908000082, 35.112694382000029],
            [73.156170908000036, 35.116094382000028],
            [73.140270908000048, 35.123094382000033],
            [73.125070908000055, 35.131294382000078],
            [73.119370908000064, 35.131294382000078],
            [73.112070908000078, 35.13239438200003],
            [73.105670908000036, 35.136294382000074],
            [73.101570908000042, 35.142494382000052],
            [73.102770908000082, 35.148194382000042],
            [73.105770908000068, 35.152594382000075],
            [73.110770908000063, 35.156194382000024],
            [73.108470908000072, 35.159494382000048],
            [73.10207090800003, 35.17039438200004],
            [73.101370908000035, 35.176994382000032],
            [73.103470908000077, 35.181194382000058],
            [73.107570908000071, 35.18639438200006],
            [73.10407090800004, 35.188994382000033],
            [73.102270908000037, 35.189594382000053],
            [73.096070908000058, 35.19469438200008],
            [73.087370908000082, 35.202194382000073],
            [73.083070908000082, 35.205394382000065],
            [73.074770908000062, 35.211394382000037],
            [73.06817090800007, 35.212994382000034],
            [73.058770908000042, 35.211794382000051],
            [73.053170908000084, 35.214894382000068],
            [73.046770908000042, 35.219394382000075],
            [73.043070908000061, 35.227494382000032],
            [73.041670908000071, 35.229594382000073],
            [73.04647090800006, 35.238794382000037],
            [73.055270908000068, 35.243894382000065],
            [73.069470908000028, 35.251394382000058],
            [73.082770908000043, 35.257794382000043],
            [73.099270908000051, 35.264094382000053],
            [73.117170908000048, 35.267094382000039],
            [73.120770908000054, 35.267694382000059],
            [73.130270908000057, 35.268894382000042],
            [73.121370908000074, 35.281794382000044],
            [73.117070908000073, 35.28969438200005],
            [73.114770908000082, 35.294394382000064],
            [73.112270908000085, 35.298094382000045],
            [73.106270908000056, 35.297294382000075],
            [73.102770908000082, 35.294094382000026],
            [73.096770908000053, 35.288894382000024],
            [73.093770908000067, 35.286294382000051],
            [73.085770908000029, 35.279394382000078],
            [73.075970908000045, 35.272394382000073],
            [73.072670908000077, 35.270094382000025],
            [73.054470908000042, 35.261094382000067],
            [73.049070908000033, 35.260494382000047],
            [73.035970908000081, 35.258994382000026],
            [73.028870908000044, 35.260794382000029],
            [73.024170908000031, 35.264794382000048],
            [73.017070908000051, 35.272494382000048],
            [73.017870908000077, 35.283594382000047],
            [73.009670908000032, 35.293194382000024],
            [73.005870908000077, 35.294994382000027],
            [72.998270908000052, 35.298594382000033],
            [72.989970908000032, 35.300094382000054],
            [72.985170908000043, 35.300794382000049],
            [72.982470908000039, 35.303894382000067],
            [72.981070908000049, 35.305594382000038],
            [72.980370908000054, 35.307894382000029],
            [72.977770908000082, 35.316794382000069],
            [72.976470908000067, 35.328294382000024],
            [72.974870908000071, 35.336994382000057],
            [72.975370908000059, 35.351194382000074],
            [72.977570908000075, 35.359194382000055],
            [72.967670908000059, 35.361994382000034],
            [72.95737090800003, 35.368594382000026],
            [72.951070908000077, 35.375394382000025],
            [72.947870908000084, 35.383894382000051],
            [72.949370908000049, 35.390894382000056],
            [72.949870908000037, 35.393494382000029],
            [72.942970908000063, 35.392194382000071],
            [72.932870908000041, 35.390094382000029],
            [72.921770908000042, 35.387594382000032],
            [72.909070908000047, 35.386894382000037],
            [72.897070908000046, 35.385294382000041],
            [72.89487090800003, 35.384994382000059],
            [72.892770908000045, 35.384394382000039],
            [72.888870908000058, 35.383194382000056],
            [72.884870908000039, 35.378894382000055],
            [72.883970908000038, 35.371994382000025],
            [72.881270908000033, 35.365994382000054],
            [72.876070908000031, 35.36189438200006],
            [72.868970908000051, 35.359394382000062],
            [72.856470908000063, 35.359394382000062],
            [72.849370908000083, 35.363694382000062],
            [72.847970908000036, 35.365394382000034],
            [72.843770908000067, 35.370294382000054],
            [72.835670908000054, 35.377994382000054],
            [72.827470908000066, 35.385994382000035],
            [72.810170908000032, 35.397294382000041],
            [72.802070908000076, 35.402894382000056],
            [72.792270908000035, 35.40899438200006],
            [72.780670908000047, 35.415894382000033],
            [72.773270908000029, 35.419994382000027],
            [72.765470908000054, 35.43009438200005],
            [72.749570908000067, 35.44409438200006],
            [72.749570922000032, 35.444094418000077],
            [72.755870908000077, 35.459894382000073],
            [72.752470908000078, 35.475094382000066],
            [72.752970908000066, 35.48429438200003],
            [72.753770908000035, 35.498694382000053],
            [72.75017090800003, 35.520294382000031],
            [72.747070908000069, 35.548694382000065],
            [72.74937090800006, 35.565794382000036],
            [72.752070908000064, 35.586294382000062],
            [72.758270908000043, 35.605094382000061],
            [72.759570908000057, 35.60909438200008],
            [72.76967090800008, 35.624894382000036],
            [72.773670908000042, 35.648094382000068],
            [72.77477090800005, 35.661994382000046],
            [72.775470908000045, 35.671694382000055],
            [72.782670908000057, 35.696694382000032],
            [72.788670908000029, 35.709594382000034],
            [72.795470908000084, 35.724294382000039],
            [72.795470908000084, 35.72659438200003],
            [72.79587090800004, 35.750094382000043],
            [72.796298355000033, 35.754052222000041],
            [72.798570908000045, 35.775094382000077],
            [72.798070908000057, 35.810894382000072],
            [72.79826964800003, 35.819797964000031],
            [72.798570908000045, 35.833294382000076],
            [72.78972260200004, 35.85238286200007],
            [72.789748153000062, 35.852459515000078],
            [72.792130000000043, 35.85960700000004],
            [72.79640061300006, 35.864825884000027],
            [72.797470908000037, 35.866094382000028],
            [72.809470908000037, 35.862494382000079],
            [72.81129999500007, 35.870333326000036],
            [72.812332000000083, 35.874461000000053],
            [72.817658851000033, 35.884671609000065],
            [72.817658881000057, 35.884671665000042],
            [72.819470908000028, 35.888094382000077],
            [72.820261750000043, 35.887202150000064],
            [72.820261769000069, 35.887202129000059],
            [72.827185500000041, 35.879214000000047],
            [72.836692000000085, 35.873272500000041],
            [72.874124000000052, 35.875649000000067],
            [72.890702002000069, 35.877425286000062],
            [72.890760000000057, 35.877431500000057],
            [72.896107500000085, 35.865548000000047],
            [72.890760000000057, 35.855448000000024],
            [72.891229897000073, 35.853098716000034],
            [72.891948500000069, 35.849506000000076],
            [72.903831500000081, 35.84178200000008],
            [72.924627000000044, 35.84178200000008],
            [72.937940643000047, 35.845986371000038],
            [72.937940678000075, 35.845986382000035],
            [72.947370908000039, 35.848894382000026],
            [72.960370908000073, 35.848894382000026],
            [72.967550693000078, 35.851709984000024],
            [72.975724500000069, 35.854854000000046],
            [72.981666000000075, 35.861389000000031],
            [72.991338039000084, 35.862056425000048],
            [72.998896500000058, 35.862578000000042],
            [73.003473403000044, 35.859831782000072],
            [73.010779500000069, 35.855448000000024],
            [73.014670684000066, 35.848530098000026],
            [73.016070908000074, 35.84589438200004],
            [73.026270908000072, 35.842294382000034],
            [73.032310972000062, 35.84715589700005],
            [73.034530410000059, 35.848899751000033],
            [73.047770908000075, 35.850094382000066],
            [73.053070908000052, 35.85789438200004],
            [73.053857098000037, 35.859073667000075],
            [73.055935500000032, 35.861983500000065],
            [73.061844579000081, 35.861983500000065],
            [73.066630500000031, 35.861983500000065],
            [73.073166000000072, 35.860795000000053],
            [73.07603294300003, 35.863412707000066],
            [73.076033442000039, 35.863413163000075],
            [73.086861561000035, 35.873285860000067],
            [73.092179000000044, 35.875649000000067],
            [73.103468000000078, 35.863766000000055],
            [73.114480017000062, 35.855256465000025],
            [73.116470908000053, 35.853694382000072],
            [73.129070908000074, 35.84589438200004],
            [73.133831763000046, 35.845390830000042],
            [73.149812000000054, 35.843565000000069],
            [73.161101000000031, 35.850100000000054],
            [73.16941950000006, 35.857824000000051],
            [73.17833150000007, 35.860201000000075],
            [73.189620500000046, 35.845941000000039],
            [73.193185500000084, 35.837623000000065],
            [73.194131718000051, 35.83620425600003],
            [73.19793125800004, 35.83050412800003],
            [73.200315000000046, 35.826928000000066],
            [73.20147094400005, 35.817682396000066],
            [73.201503500000058, 35.817422000000079],
            [73.20147094400005, 35.817347589000065],
            [73.198841194000067, 35.811337004000052],
            [73.197344000000044, 35.807915000000037],
            [73.198339885000053, 35.800944475000051],
            [73.200315000000046, 35.787120000000073],
            [73.20026826700007, 35.787113322000039],
            [73.187838000000056, 35.785337000000027],
            [73.184901675000049, 35.783281770000031],
            [73.181870908000064, 35.781294382000056],
            [73.176494892000051, 35.775776892000067],
            [73.174172000000056, 35.773454000000072],
            [73.167636500000071, 35.766918000000032],
            [73.164009101000033, 35.764877743000056],
            [73.158130000000028, 35.76157100000006],
            [73.14669013300005, 35.763614031000031],
            [73.14669007100008, 35.763614042000029],
            [73.14149400000008, 35.764542000000063],
            [73.130205000000046, 35.765136000000041],
            [73.120104000000083, 35.765136000000041],
            [73.117516168000066, 35.763287371000047],
            [73.117516137000052, 35.763287349000052],
            [73.117516112000033, 35.763287331000072],
            [73.11186288600004, 35.759248924000076],
            [73.111856965000072, 35.759244694000074],
            [73.111786000000052, 35.759194000000036],
            [73.110003500000062, 35.749094000000071],
            [73.113180442000044, 35.742740117000039],
            [73.113180490000047, 35.742740019000053],
            [73.114757000000054, 35.739587000000029],
            [73.114851395000073, 35.739432987000043],
            [73.117637865000063, 35.734886614000061],
            [73.126046000000031, 35.721168000000034],
            [73.128344037000033, 35.719636061000074],
            [73.13495800000004, 35.715227000000027],
            [73.148623500000042, 35.71285000000006],
            [73.161695000000066, 35.702749500000039],
            [73.182765158000052, 35.689873156000033],
            [73.183084500000064, 35.689678000000072],
            [73.191997000000072, 35.677201000000025],
            [73.20269200000007, 35.673636000000045],
            [73.216821468000035, 35.669449215000043],
            [73.218670908000036, 35.668894382000076],
            [73.218700876000071, 35.66889231600004],
            [73.21873400000004, 35.668882500000052],
            [73.23596450000008, 35.66769400000004],
            [73.239695011000038, 35.658634125000049],
            [73.239695038000036, 35.658634057000029],
            [73.240070908000064, 35.657694382000045],
            [73.240080683000031, 35.657697486000075],
            [73.240123500000038, 35.657593500000075],
            [73.247518453000055, 35.660058682000056],
            [73.252670908000084, 35.661694382000064],
            [73.263870908000058, 35.665894382000033],
            [73.265955744000053, 35.666564508000079],
            [73.275178500000038, 35.669477000000029],
            [73.29062650000003, 35.670665000000042],
            [73.296568500000035, 35.662347000000068],
            [73.312016500000084, 35.660564000000079],
            [73.320437316000039, 35.662970045000066],
            [73.320437383000069, 35.662970064000035],
            [73.324493500000074, 35.664129000000059],
            [73.325306977000082, 35.663981141000079],
            [73.331029500000056, 35.662941000000046],
            [73.332811500000048, 35.654029000000037],
            [73.335259520000079, 35.649133419000066],
            [73.338070908000077, 35.643294382000079],
            [73.346290092000061, 35.632944300000077],
            [73.348854000000074, 35.629668000000038],
            [73.353013000000033, 35.621944000000042],
            [73.355983500000036, 35.609467000000052],
            [73.36069855900007, 35.60441525400006],
            [73.364270908000037, 35.600494382000079],
            [73.375505253000028, 35.596713593000061],
            [73.375505284000042, 35.596713583000053],
            [73.385097500000029, 35.593425000000025],
            [73.39519800000005, 35.586889000000042],
            [73.397950715000036, 35.577101739000057],
            [73.400545500000078, 35.567876000000069],
            [73.401796072000081, 35.554533066000033],
            [73.40179607500005, 35.554533018000029],
            [73.402270944000065, 35.549466429000063],
            [73.402327500000069, 35.54886300000004],
            [73.402270944000065, 35.548268989000064],
            [73.401594858000067, 35.541168109000068],
            [73.401594853000063, 35.541168046000053],
            [73.401568689000044, 35.540893245000063],
            [73.401139500000056, 35.536385500000051],
            [73.40505788300004, 35.529668474000061],
            [73.405057905000035, 35.529668437000055],
            [73.405277365000074, 35.529292230000067],
            [73.405298500000072, 35.529256000000032],
            [73.41777600000006, 35.525096500000075],
            [73.417831345000081, 35.525107569000056],
            [73.417870908000054, 35.525094382000077],
            [73.419970908000039, 35.525494382000034],
            [73.430062866000071, 35.527553965000038],
            [73.44451250000003, 35.530444000000045],
            [73.471594236000044, 35.538181742000063],
            [73.481870908000076, 35.54109438200004],
            [73.499870908000048, 35.542894382000043],
            [73.514070908000065, 35.542194382000048],
            [73.514070944000082, 35.542194380000069],
            [73.529470908000064, 35.541494382000053],
            [73.537870908000059, 35.54109438200004],
            [73.541870872000061, 35.541014034000057],
            [73.542038741000056, 35.541010662000076],
            [73.542070908000085, 35.540194382000038],
            [73.542062129000044, 35.541010288000052],
            [73.551507916000048, 35.540859558000079],
            [73.552032915000041, 35.540893592000032],
            [73.572189716000082, 35.540546151000058],
            [73.572370908000039, 35.540494382000077],
            [73.585570908000079, 35.540994382000065],
            [73.58557094400004, 35.540994383000054],
            [73.588270908000084, 35.54109438200004],
            [73.616870908000067, 35.54109438200004],
            [73.631670751000058, 35.537694418000058],
            [73.631670908000046, 35.537694382000041],
            [73.634606420000068, 35.537033892000068],
            [73.647714000000065, 35.534009000000026],
            [73.664944500000047, 35.531038000000024],
            [73.666267780000055, 35.530946763000031],
            [73.682175000000029, 35.529850000000067],
            [73.692869464000069, 35.530919519000065],
            [73.701678033000064, 35.531800435000036],
            [73.711883000000057, 35.532821000000069],
            [73.735055000000045, 35.531632500000057],
            [73.746694625000032, 35.526027820000024],
            [73.751070908000031, 35.523894382000037],
            [73.773670908000042, 35.523894382000037],
            [73.785556824000082, 35.524488677000079],
            [73.785122939000075, 35.519390090000059],
            [73.785101914000052, 35.519143098000029],
            [73.78318100000007, 35.496577000000059],
            [73.785489838000046, 35.48272514100006],
            [73.785811548000083, 35.480795036000075],
            [73.786152000000072, 35.478752500000041],
            [73.785897662000082, 35.472137754000073],
            [73.785558000000037, 35.463304000000051],
            [73.794440893000058, 35.449091072000044],
            [73.794470000000047, 35.44904450000007],
            [73.767224192000072, 35.446083259000034],
            [73.767139000000043, 35.446074000000067],
            [73.757108673000062, 35.443685693000077],
            [73.754661500000054, 35.443103000000065],
            [73.754472669000052, 35.441970015000038],
            [73.752886078000074, 35.432450470000049],
            [73.752879000000064, 35.432408000000066],
            [73.746445526000059, 35.429116537000027],
            [73.727330500000051, 35.419337000000041],
            [73.717230000000029, 35.405671000000041],
            [73.709902668000041, 35.390154148000079],
            [73.707129500000065, 35.384281500000043],
            [73.698510442000043, 35.371640376000073],
            [73.698425606000058, 35.371515951000049],
            [73.698271490000081, 35.371289918000059],
            [73.698217000000056, 35.371210000000076],
            [73.698296587000073, 35.370593162000034],
            [73.700585261000072, 35.352854853000053],
            [73.700593500000082, 35.352791000000025],
            [73.697927320000076, 35.351560429000074],
            [73.692870908000032, 35.349226650000048],
            [73.692869500000029, 35.349226000000044],
            [73.692869500000029, 35.339194914000075],
            [73.692869500000029, 35.332590000000039],
            [73.698139664000053, 35.317738233000057],
            [73.699405500000069, 35.314171000000044],
            [73.708416381000063, 35.304659212000047],
            [73.708416459000034, 35.304659129000072],
            [73.708416559000057, 35.304659021000077],
            [73.710100000000068, 35.302882000000068],
            [73.709665538000081, 35.298103646000072],
            [73.708911500000056, 35.289810500000044],
            [73.711402756000041, 35.281340986000032],
            [73.711870908000037, 35.279694382000059],
            [73.73027090800008, 35.273694382000031],
            [73.734058835000042, 35.26249529100005],
            [73.734058877000052, 35.262495170000079],
            [73.734861760000058, 35.260086370000067],
            [73.735648500000082, 35.257726000000048],
            [73.749870908000048, 35.23105948400007],
            [73.74990850000006, 35.230989000000079],
            [73.74990850000006, 35.227863877000061],
            [73.74990850000006, 35.22089438200004],
            [73.74990850000006, 35.220888000000059],
            [73.765356500000053, 35.220888000000059],
            [73.765366073000052, 35.22089438200004],
            [73.765470908000054, 35.22089438200004],
            [73.78507090800008, 35.233894382000074],
            [73.799970908000034, 35.235694382000077],
            [73.809335193000038, 35.242867025000066],
            [73.820019000000059, 35.23811900000004],
            [73.832496000000049, 35.227424000000042],
            [73.847350000000063, 35.226236000000029],
            [73.862798000000055, 35.210193500000059],
            [73.865924428000085, 35.208183573000042],
            [73.871116000000029, 35.204846000000032],
            [73.882405000000062, 35.206034500000044],
            [73.899252933000071, 35.211469214000033],
            [73.900824000000057, 35.21197600000005],
            [73.925778000000037, 35.210193500000059],
            [73.938983957000062, 35.195386892000045],
            [73.945385000000044, 35.188210000000026],
            [73.957747312000038, 35.16524946800007],
            [73.95774700000004, 35.165247000000079],
            [73.954772000000048, 35.144692000000077],
            [73.93980300000004, 35.128891000000067],
            [73.920537317000083, 35.113724949000073],
            [73.913745000000063, 35.108378000000073],
            [73.903766000000076, 35.110041000000024],
            [73.897212772000046, 35.109917447000043],
            [73.889074000000051, 35.109764000000041],
            [73.882005493000065, 35.109228530000053],
            [73.870870908000029, 35.10849438200006],
            [73.849670908000064, 35.092094382000028],
            [73.847861549000072, 35.085460065000063],
            [73.84416600000003, 35.072341000000051],
            [73.843335000000081, 35.057649000000026],
            [73.842780000000062, 35.034363000000042],
            [73.840116324000064, 35.028591702000028],
            [73.834464000000082, 35.016345000000058],
            [73.82309900000007, 35.004425000000026],
            [73.80424800000003, 35.004979000000048],
            [73.78346988800007, 35.020494123000049],
            [73.783458000000053, 35.020503000000076],
            [73.774932484000033, 35.018329711000035],
            [73.76932000000005, 35.016899000000024],
            [73.769292610000036, 35.016899884000054],
            [73.768336457000032, 35.016930742000056],
            [73.743540000000053, 35.017731000000026],
            [73.732129112000052, 35.021806107000032],
            [73.73189700000006, 35.021889000000044],
            [73.731296106000059, 35.022042188000057],
            [73.717817026000034, 35.025478462000024],
            [73.712645981000037, 35.023950654000032],
            [73.700296000000037, 35.020226000000036],
            [73.684047093000061, 35.014441766000061],
            [73.683941000000061, 35.01440400000007],
            [73.675347000000045, 34.995554000000027],
            [73.662070459000063, 34.980894527000032],
            [73.662041000000045, 34.980862000000059],
            [73.649012000000084, 34.985020000000077],
            [73.633471868000072, 34.99800971600007],
            [73.630440000000078, 35.000544000000048],
            [73.609608224000056, 34.999430562000043],
            [73.595170908000057, 34.998694382000053],
            [73.59514147200008, 34.998657329000025],
            [73.594125000000076, 34.99860300000006],
            [73.581280743000036, 34.981210259000079],
            [73.580870908000065, 34.980694382000024],
            [73.566670908000049, 34.980694382000024],
            [73.563483414000075, 34.97482754400005],
            [73.55975200000006, 34.968111000000079],
            [73.559728001000053, 34.968043370000032],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "76",
      properties: { name: "Lakki Marwat", count: 885 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.883742598000083, 32.855871272000059],
            [70.885646104000045, 32.85276932000005],
            [70.888987000000043, 32.847325000000069],
            [70.893762664000064, 32.849930294000046],
            [70.893762806000041, 32.849930372000074],
            [70.895141655000032, 32.850682583000037],
            [70.896849000000032, 32.851614000000041],
            [70.907834598000079, 32.855751215000055],
            [70.914923762000058, 32.858421019000048],
            [70.924366000000077, 32.861977000000024],
            [70.926735334000057, 32.862403080000036],
            [70.92675106400003, 32.862405909000074],
            [70.926757323000061, 32.862378505000038],
            [70.931026565000082, 32.863153295000075],
            [70.93627284400003, 32.864102306000063],
            [70.940655348000064, 32.864895067000077],
            [70.943882692000045, 32.865478868000025],
            [70.948300611000036, 32.86627803500005],
            [70.952326173000074, 32.867006228000037],
            [70.953605306000043, 32.867237613000043],
            [70.953653306000035, 32.866948613000034],
            [70.958541358000048, 32.857444913000052],
            [70.966519306000066, 32.841933613000037],
            [70.968288812000083, 32.840037664000079],
            [70.973117698000067, 32.834863719000055],
            [70.975432200000057, 32.832383829000037],
            [70.97629160200006, 32.831511220000039],
            [70.976541000000054, 32.831244000000027],
            [70.976668496000059, 32.831128533000026],
            [70.983702175000076, 32.824758486000064],
            [70.989252237000073, 32.819732075000047],
            [70.993919081000058, 32.815483561000065],
            [70.995481000000041, 32.814091000000076],
            [71.016923000000077, 32.809802000000047],
            [71.03230712800007, 32.808561882000049],
            [71.035453073000042, 32.808308287000045],
            [71.037503653000044, 32.808142989000032],
            [71.039079000000072, 32.808016000000066],
            [71.04179195100005, 32.80763440000004],
            [71.047091938000051, 32.806888912000034],
            [71.056920554000044, 32.805506434000051],
            [71.061039559000051, 32.804927061000058],
            [71.061950000000081, 32.80479900000006],
            [71.062125062000064, 32.804801248000047],
            [71.066267911000068, 32.804854457000033],
            [71.070929775000081, 32.804914332000067],
            [71.079617760000076, 32.805025916000034],
            [71.087696728000083, 32.805129678000071],
            [71.089824000000078, 32.805157000000065],
            [71.092404701000078, 32.804816104000054],
            [71.107163764000063, 32.802866514000073],
            [71.108765000000062, 32.802655000000073],
            [71.108889548000036, 32.802621123000051],
            [71.108890000000031, 32.802621000000045],
            [71.109176137000077, 32.801946509000061],
            [71.124918424000043, 32.764838252000061],
            [71.124930137000035, 32.764810644000079],
            [71.125613000000044, 32.763201000000038],
            [71.125541923000071, 32.762703534000025],
            [71.125479214000052, 32.762264646000062],
            [71.125285210000072, 32.760906838000039],
            [71.124749405000046, 32.757156812000062],
            [71.124287500000037, 32.75392400000004],
            [71.124315772000045, 32.753881589000059],
            [71.125626788000034, 32.751949118000027],
            [71.128408671000045, 32.74784855200005],
            [71.128772109000067, 32.747312835000059],
            [71.130999325000062, 32.743878616000075],
            [71.137512207000043, 32.734130859000061],
            [71.137986033000061, 32.732960984000044],
            [71.142179000000056, 32.722780000000057],
            [71.142167918000041, 32.722635910000065],
            [71.142268497000032, 32.722387583000057],
            [71.14093017600004, 32.705688477000024],
            [71.139650101000029, 32.70301377100003],
            [71.134560520000036, 32.693437446000075],
            [71.134836972000073, 32.692716148000045],
            [71.13952636700003, 32.680480957000043],
            [71.152099609000061, 32.669128418000071],
            [71.152099073000045, 32.66912338000003],
            [71.152118500000029, 32.669106000000056],
            [71.15193105700007, 32.667544038000074],
            [71.151106982000044, 32.659797769000079],
            [71.150383526000041, 32.654648468000062],
            [71.150130500000046, 32.652540000000045],
            [71.15651562000005, 32.637375301000077],
            [71.16073300000005, 32.62735900000007],
            [71.163148105000062, 32.617701860000068],
            [71.163418518000071, 32.616697410000029],
            [71.164159813000083, 32.613943877000054],
            [71.165522148000036, 32.608883497000079],
            [71.162236077000045, 32.594951964000074],
            [71.16442617000007, 32.593858674000046],
            [71.172362594000049, 32.589896306000071],
            [71.179509826000071, 32.586326589000066],
            [71.195409150000046, 32.578385601000036],
            [71.196993503000044, 32.577318766000076],
            [71.201526646000048, 32.574266345000069],
            [71.223947992000035, 32.559163578000039],
            [71.236595097000077, 32.55151621400006],
            [71.241699219000054, 32.548522949000073],
            [71.246250076000081, 32.53596258400006],
            [71.229675293000071, 32.512084961000028],
            [71.230837458000053, 32.510282621000044],
            [71.230812000000071, 32.510260000000073],
            [71.225488978000044, 32.506332000000043],
            [71.213627421000069, 32.492425347000051],
            [71.210923946000037, 32.489251523000064],
            [71.21096654300004, 32.489203671000041],
            [71.21096850400005, 32.489201467000044],
            [71.204547793000074, 32.481632757000057],
            [71.19958611900006, 32.47569469900003],
            [71.195748011000035, 32.471101309000062],
            [71.18393186600008, 32.456261834000031],
            [71.178422000000069, 32.449422000000027],
            [71.160536000000036, 32.449422000000027],
            [71.145901000000038, 32.460804000000053],
            [71.121936000000062, 32.437638000000049],
            [71.121678969000072, 32.437389436000046],
            [71.121511000000055, 32.437227000000064],
            [71.089804000000072, 32.408772000000056],
            [71.045399728000064, 32.388451401000054],
            [71.041837000000044, 32.386821000000054],
            [71.003625000000056, 32.375438000000031],
            [70.986552000000074, 32.368121000000031],
            [70.978276978000054, 32.364343273000031],
            [70.960925086000032, 32.356421757000078],
            [70.949154000000078, 32.351048000000048],
            [70.940079683000079, 32.348455338000065],
            [70.929210189000059, 32.345349769000052],
            [70.914744164000069, 32.341235598000026],
            [70.904319323000038, 32.338238093000029],
            [70.896980699000039, 32.336141343000065],
            [70.896980459000076, 32.336141274000056],
            [70.892670630000055, 32.334902208000074],
            [70.892667521000078, 32.334909006000032],
            [70.892244000000062, 32.33478800000006],
            [70.884516559000076, 32.332276582000077],
            [70.879790376000074, 32.330754742000067],
            [70.87513943600004, 32.329229017000046],
            [70.868021895000084, 32.326915816000053],
            [70.867996602000062, 32.326907596000069],
            [70.859724000000028, 32.324219000000028],
            [70.84508900000003, 32.314463000000046],
            [70.815008000000034, 32.29657700000007],
            [70.798782913000082, 32.29657700000007],
            [70.798748000000046, 32.29657700000007],
            [70.774396841000055, 32.301447232000044],
            [70.774358000000063, 32.301455000000033],
            [70.747184012000048, 32.278687604000027],
            [70.744277000000068, 32.276252000000056],
            [70.73609670500008, 32.273915113000044],
            [70.706552000000045, 32.265475000000038],
            [70.706551784000055, 32.265469807000045],
            [70.698761289000061, 32.263244000000043],
            [70.698752102000071, 32.26324517300003],
            [70.69874800000008, 32.263244000000043],
            [70.660537000000033, 32.268122000000062],
            [70.659478000000036, 32.268631000000028],
            [70.65947793600003, 32.268631034000066],
            [70.659491254000045, 32.268638382000063],
            [70.662976000000072, 32.270561000000043],
            [70.662976000000072, 32.280490564000047],
            [70.662976000000072, 32.292512000000045],
            [70.675171000000034, 32.325845000000072],
            [70.708505000000059, 32.345357000000035],
            [70.702000000000055, 32.357552000000055],
            [70.704439000000036, 32.371373000000062],
            [70.69143100000008, 32.390886000000023],
            [70.678538269000057, 32.391925049000065],
            [70.645744000000036, 32.394568000000049],
            [70.643428510000035, 32.396368925000047],
            [70.616001654000058, 32.417700787000058],
            [70.611319384000069, 32.421342529000071],
            [70.608815589000073, 32.423289913000076],
            [70.604642184000056, 32.426535874000024],
            [70.601339000000053, 32.42910500000005],
            [70.601889935000031, 32.43152910200007],
            [70.602410587000065, 32.433819959000061],
            [70.603081168000074, 32.436770498000044],
            [70.603824405000069, 32.440040724000028],
            [70.604766319000078, 32.444185124000057],
            [70.605859069000076, 32.448993198000039],
            [70.606523116000062, 32.451914986000077],
            [70.609562000000039, 32.465286000000049],
            [70.607029375000081, 32.466668503000051],
            [70.597955124000066, 32.471621933000051],
            [70.59795500000007, 32.471622000000025],
            [70.601464000000078, 32.472301000000073],
            [70.601188558000047, 32.474228780000033],
            [70.600646124000036, 32.478025209000066],
            [70.600104481000074, 32.481816094000067],
            [70.599510987000031, 32.485969880000027],
            [70.598813000000064, 32.490855000000067],
            [70.596612404000041, 32.496722886000043],
            [70.595274772000039, 32.500289681000027],
            [70.593374206000078, 32.505357538000055],
            [70.592849000000058, 32.506758000000048],
            [70.589944194000054, 32.509852356000067],
            [70.58783255600008, 32.512101786000073],
            [70.586117472000069, 32.513928785000076],
            [70.583443611000064, 32.516777126000079],
            [70.58155191600008, 32.518792261000044],
            [70.578228763000084, 32.522332263000067],
            [70.575900810000064, 32.524812123000061],
            [70.57375274900005, 32.527100354000027],
            [70.568582550000031, 32.532607927000072],
            [70.562368000000049, 32.539228000000037],
            [70.551766000000043, 32.551818000000026],
            [70.545802000000037, 32.57236000000006],
            [70.543600300000037, 32.573354313000038],
            [70.539996607000035, 32.574981781000076],
            [70.539987170000074, 32.574986043000024],
            [70.534113709000053, 32.577638565000029],
            [70.529798249000066, 32.579587475000039],
            [70.52526000000006, 32.581637000000057],
            [70.52279044200003, 32.583411967000075],
            [70.518655969000065, 32.586383574000024],
            [70.51673442200007, 32.587764665000066],
            [70.511019460000057, 32.591872231000025],
            [70.507629161000068, 32.594308971000032],
            [70.503252991000068, 32.597454295000034],
            [70.498983330000044, 32.600523066000051],
            [70.497410304000084, 32.601653661000057],
            [70.493521654000062, 32.604448586000046],
            [70.490105397000036, 32.606903983000052],
            [70.482851000000039, 32.612118000000066],
            [70.482289126000069, 32.612991994000026],
            [70.482295123000029, 32.612997066000048],
            [70.479361786000084, 32.617545471000028],
            [70.476859005000051, 32.621438546000036],
            [70.471952112000054, 32.629071216000057],
            [70.470923000000084, 32.630672000000061],
            [70.462385027000039, 32.634266900000057],
            [70.458333000000039, 32.635973000000035],
            [70.455779190000044, 32.639088522000065],
            [70.455782002000035, 32.639089388000059],
            [70.454192441000032, 32.641034104000028],
            [70.45008602300004, 32.64605801700003],
            [70.446406000000081, 32.650552000000062],
            [70.44459080200005, 32.653074068000024],
            [70.439261334000037, 32.660491997000065],
            [70.436579637000079, 32.664222901000073],
            [70.434274293000044, 32.667430205000073],
            [70.431165000000078, 32.671756000000073],
            [70.426426280000044, 32.676494720000051],
            [70.423107323000067, 32.679813677000027],
            [70.41902989700003, 32.683891103000065],
            [70.415924000000075, 32.686997000000076],
            [70.412705345000063, 32.690014376000079],
            [70.406875599000045, 32.695479556000066],
            [70.405322000000069, 32.696936000000051],
            [70.404470558000071, 32.704600911000057],
            [70.404261341000051, 32.706484330000023],
            [70.403997000000061, 32.708864000000062],
            [70.402053637000051, 32.716081780000025],
            [70.401745596000069, 32.717225865000046],
            [70.400066000000038, 32.723464000000035],
            [70.40976500000005, 32.727967000000035],
            [70.465156000000036, 32.74726400000003],
            [70.482509965000077, 32.760808775000044],
            [70.482510469000033, 32.760809168000037],
            [70.482511462000048, 32.760809943000027],
            [70.494459000000063, 32.770135000000039],
            [70.494460330000038, 32.77013439600006],
            [70.503308335000042, 32.766112729000042],
            [70.51018300000004, 32.762988000000064],
            [70.51733100000007, 32.773352000000045],
            [70.516800885000066, 32.778500602000065],
            [70.516427409000073, 32.782127882000054],
            [70.514829000000077, 32.797652000000028],
            [70.514834050000047, 32.797650766000061],
            [70.530910000000063, 32.793721000000062],
            [70.534591376000037, 32.800517204000073],
            [70.535556000000042, 32.802298000000064],
            [70.53775231700007, 32.809105724000062],
            [70.539130000000057, 32.813376000000062],
            [70.546821388000069, 32.824097507000033],
            [70.550923000000068, 32.829815000000053],
            [70.557588700000053, 32.832874635000053],
            [70.561696918000052, 32.834760356000061],
            [70.572722000000056, 32.839821000000029],
            [70.593832266000049, 32.834303788000057],
            [70.604170000000067, 32.831602000000032],
            [70.605813726000065, 32.826083707000066],
            [70.607126746000063, 32.821675654000046],
            [70.60915589800004, 32.814863415000048],
            [70.609173000000055, 32.814806000000033],
            [70.60923925700007, 32.81481081700008],
            [70.622621682000045, 32.815783775000057],
            [70.628828000000055, 32.816235000000063],
            [70.634951602000058, 32.820267942000044],
            [70.636718598000073, 32.821431668000059],
            [70.641223839000077, 32.824398774000031],
            [70.64347900000007, 32.82588400000003],
            [70.662062000000049, 32.824097000000052],
            [70.662086613000042, 32.824130924000031],
            [70.662088833000041, 32.824130862000061],
            [70.664225692000059, 32.827079174000062],
            [70.666390696000065, 32.830063156000051],
            [70.675283876000037, 32.842320451000035],
            [70.675285000000031, 32.842322000000024],
            [70.686553580000066, 32.839932216000079],
            [70.687078000000042, 32.839821000000029],
            [70.691465183000048, 32.836678765000045],
            [70.69420963500005, 32.834713105000048],
            [70.704247361000057, 32.827523780000035],
            [70.713521922000041, 32.820881056000076],
            [70.713522000000069, 32.820881000000043],
            [70.720456822000074, 32.81966265300008],
            [70.726465694000069, 32.818606982000063],
            [70.739967000000036, 32.816235000000063],
            [70.745508218000055, 32.821709524000028],
            [70.753480466000042, 32.829585818000055],
            [70.756508136000036, 32.832577047000029],
            [70.761925405000056, 32.837929114000076],
            [70.769628000000068, 32.845539000000031],
            [70.77135607200006, 32.847727741000028],
            [70.77373288800004, 32.850738168000078],
            [70.780349000000058, 32.859118000000024],
            [70.784883279000042, 32.864324268000075],
            [70.78662896600008, 32.86632866900004],
            [70.789998000000082, 32.870197000000076],
            [70.797382111000047, 32.866609740000058],
            [70.800501926000038, 32.86509410900004],
            [70.802505000000053, 32.864121000000068],
            [70.808689635000064, 32.860881998000025],
            [70.817515000000071, 32.856260000000077],
            [70.817866673000083, 32.856208277000064],
            [70.829665000000034, 32.854473000000041],
            [70.832654964000028, 32.855691111000056],
            [70.839314000000059, 32.858404000000064],
            [70.84580079300008, 32.862787075000028],
            [70.852536000000043, 32.867338000000075],
            [70.855982440000048, 32.869405623000034],
            [70.858113266000032, 32.870683969000027],
            [70.859468617000061, 32.871497085000044],
            [70.861458758000083, 32.872691030000055],
            [70.863631198000064, 32.87399434200006],
            [70.866831000000047, 32.87591400000008],
            [70.868225196000083, 32.874479896000025],
            [70.869858552000039, 32.872799787000076],
            [70.872026210000058, 32.870570081000039],
            [70.874913291000041, 32.867600361000029],
            [70.877265097000077, 32.865181238000048],
            [70.879338000000075, 32.863049000000046],
            [70.879612207000037, 32.862602153000068],
            [70.880367700000079, 32.861371002000055],
            [70.881649627000058, 32.859281976000034],
            [70.883026700000073, 32.857037899000034],
            [70.883742598000083, 32.855871272000059],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "77",
      properties: { name: "Lower Dir", count: 38 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.850547258000063, 35.062046297000052],
            [71.866292000000044, 35.051550000000077],
            [71.872124000000042, 35.047431000000074],
            [71.87957194300003, 35.042168484000058],
            [71.891795000000059, 35.033532000000037],
            [71.897420795000073, 35.022983105000037],
            [71.89844800000003, 35.021057000000042],
            [71.901243916000055, 35.01381212900003],
            [71.905398104000028, 35.003047656000035],
            [71.912208947000067, 34.994951306000075],
            [71.91307865400006, 34.99463288000004],
            [71.915099252000061, 34.993893079000031],
            [71.930414481000071, 34.988285714000028],
            [71.94631408500004, 34.985044047000031],
            [71.970240673000035, 34.980721824000057],
            [71.986757737000062, 34.980104364000056],
            [71.999570040000037, 34.973312300000032],
            [72.009758135000084, 34.96219801400008],
            [72.012227977000066, 34.95911071200004],
            [72.01761493600003, 34.957274249000079],
            [72.017889270000069, 34.950609288000066],
            [72.01806468500007, 34.945552569000029],
            [72.017423587000053, 34.938093730000048],
            [72.017275476000066, 34.936370526000076],
            [72.017273816000056, 34.936351218000027],
            [72.01725198500003, 34.93609722900004],
            [72.034200371000054, 34.926657868000063],
            [72.047891346000029, 34.918802741000036],
            [72.04789837900006, 34.918798706000075],
            [72.047940316000052, 34.918774645000042],
            [72.053960304000043, 34.911623860000077],
            [72.059653571000069, 34.904861168000025],
            [72.064128175000064, 34.89948559100003],
            [72.064136765000057, 34.899479639000049],
            [72.070386880000058, 34.89514915400008],
            [72.083195358000069, 34.895181045000072],
            [72.083298683000066, 34.89508300500006],
            [72.083299571000055, 34.895083011000054],
            [72.088296876000072, 34.89034104600006],
            [72.088299205000055, 34.890338836000069],
            [72.088399613000036, 34.890243558000066],
            [72.097410085000035, 34.886904841000046],
            [72.097571340000059, 34.886903163000056],
            [72.097575081000059, 34.886903124000071],
            [72.106824095000036, 34.886806851000074],
            [72.106831745000079, 34.886806771000067],
            [72.106831832000069, 34.88680679600003],
            [72.114966984000034, 34.889146753000034],
            [72.114970224000047, 34.889148123000041],
            [72.119985788000065, 34.891269745000045],
            [72.119988301000035, 34.891269270000066],
            [72.119989424000039, 34.891269745000045],
            [72.127532086000031, 34.889843203000055],
            [72.127532553000037, 34.889830172000075],
            [72.127532725000037, 34.889829624000072],
            [72.132241451000084, 34.874874203000047],
            [72.127910884000073, 34.865836795000064],
            [72.12791377800005, 34.865835247000064],
            [72.139341000000059, 34.859722000000033],
            [72.143185929000083, 34.855787722000059],
            [72.14321368700007, 34.855759319000072],
            [72.142753343000038, 34.855434153000033],
            [72.144515402000081, 34.853486216000078],
            [72.150753201000043, 34.847300578000045],
            [72.153205142000047, 34.83905076800005],
            [72.166869375000033, 34.834026619000042],
            [72.175169473000039, 34.830434398000079],
            [72.175195075000033, 34.830421517000048],
            [72.180359530000032, 34.813869927000042],
            [72.186276907000035, 34.808221573000026],
            [72.186281722000047, 34.808228235000058],
            [72.18626807000004, 34.808241162000058],
            [72.186667098000044, 34.808789060000038],
            [72.18667892700006, 34.808777822000025],
            [72.192288000000076, 34.803449000000057],
            [72.190681091000044, 34.793485813000075],
            [72.190658165000059, 34.793465821000041],
            [72.190672233000043, 34.793430895000029],
            [72.189516000000083, 34.786262000000079],
            [72.179720790000033, 34.778915762000054],
            [72.179329252000059, 34.779269148000026],
            [72.177420551000068, 34.777850697000076],
            [72.174743121000063, 34.775913565000053],
            [72.174752775000059, 34.775879628000041],
            [72.174075314000049, 34.775377795000054],
            [72.171935949000044, 34.774319175000073],
            [72.169185467000034, 34.772962771000039],
            [72.166247887000054, 34.771502378000037],
            [72.164100135000069, 34.770435340000063],
            [72.161140316000058, 34.768951562000041],
            [72.159582146000048, 34.768185276000054],
            [72.15557663900006, 34.766225661000078],
            [72.153722444000039, 34.765322274000027],
            [72.152648917000079, 34.764782483000033],
            [72.148229231000073, 34.762581105000038],
            [72.138338761000057, 34.757691727000065],
            [72.137551502000065, 34.756826023000031],
            [72.13755141200005, 34.756825252000056],
            [72.123739805000071, 34.741621438000038],
            [72.117839164000031, 34.728913945000045],
            [72.116924340000082, 34.724904263000042],
            [72.11692352700004, 34.724904275000029],
            [72.116369325000051, 34.72253708900007],
            [72.115606317000072, 34.719255632000056],
            [72.115347522000036, 34.718142638000074],
            [72.114080329000046, 34.712692838000066],
            [72.11273146700006, 34.706478213000025],
            [72.110918677000029, 34.699421650000033],
            [72.109291133000056, 34.69325526800003],
            [72.107586077000065, 34.686634508000054],
            [72.104164572000059, 34.68359754800008],
            [72.098871104000068, 34.678887785000029],
            [72.094593490000079, 34.670587488000024],
            [72.09662155500007, 34.67045700400007],
            [72.096580421000056, 34.670375972000045],
            [72.096580000000074, 34.670376000000033],
            [72.093426782000051, 34.664165078000053],
            [72.091661000000045, 34.660687000000053],
            [72.075650878000033, 34.657323549000068],
            [72.058674000000053, 34.653757000000041],
            [72.048659945000054, 34.649783260000049],
            [72.023746000000074, 34.639897000000076],
            [72.012946981000084, 34.639497047000077],
            [72.009618098000033, 34.639373758000033],
            [72.008343923000041, 34.639326568000058],
            [71.986323000000084, 34.638511000000051],
            [71.948798370000077, 34.641445908000037],
            [71.938413127000047, 34.642258168000069],
            [71.937792892000061, 34.642306678000068],
            [71.936702819000061, 34.642391936000024],
            [71.936702000000082, 34.642392000000029],
            [71.918062925000072, 34.635769629000038],
            [71.915635000000066, 34.634907000000055],
            [71.894290000000069, 34.630472000000054],
            [71.872945000000072, 34.634075000000053],
            [71.859759952000047, 34.632169073000057],
            [71.849936000000071, 34.630749000000037],
            [71.827760000000069, 34.624650000000031],
            [71.826445792000072, 34.624904401000038],
            [71.810573000000034, 34.627977000000044],
            [71.807715641000073, 34.63047724200004],
            [71.802944955000044, 34.634651683000072],
            [71.80284288200005, 34.634741000000076],
            [71.797267000000033, 34.639620000000036],
            [71.783961000000033, 34.646550000000047],
            [71.759927000000062, 34.656163000000049],
            [71.762411000000043, 34.658355000000029],
            [71.763583029000074, 34.66172505000003],
            [71.764638152000032, 34.664758947000053],
            [71.767712000000074, 34.673596000000032],
            [71.765724000000034, 34.693475000000035],
            [71.759761000000083, 34.717993000000035],
            [71.761906147000047, 34.729257047000033],
            [71.762411000000043, 34.731908000000033],
            [71.773013000000049, 34.738534000000072],
            [71.784278000000029, 34.749137000000076],
            [71.802584951000028, 34.75386123100003],
            [71.804698255000062, 34.754406583000048],
            [71.804820000000063, 34.75443800000005],
            [71.804726401000039, 34.754508195000028],
            [71.791567000000043, 34.764377000000025],
            [71.783616000000052, 34.764377000000025],
            [71.772475000000043, 34.77141400000005],
            [71.771025000000066, 34.77232900000007],
            [71.759098000000051, 34.787570000000073],
            [71.748496000000046, 34.788895000000025],
            [71.735658002000037, 34.793952589000071],
            [71.734802202000083, 34.794289735000064],
            [71.726628000000062, 34.797510000000045],
            [71.702111000000059, 34.796847000000071],
            [71.696147000000053, 34.808774000000028],
            [71.682232000000056, 34.812750000000051],
            [71.682231542000068, 34.812749986000028],
            [71.660364000000072, 34.812088000000074],
            [71.631208000000072, 34.810762000000068],
            [71.624587942000062, 34.812201464000054],
            [71.615967000000069, 34.814076000000057],
            [71.612719584000047, 34.819272061000049],
            [71.609341000000029, 34.824678000000063],
            [71.61000400000006, 34.837931000000026],
            [71.605365000000063, 34.862448000000029],
            [71.600064000000032, 34.876364000000024],
            [71.593277327000067, 34.890554655000074],
            [71.592775000000074, 34.891605000000027],
            [71.574884000000054, 34.91612200000003],
            [71.56958300000008, 34.920761000000027],
            [71.556992000000037, 34.941965000000039],
            [71.543739000000073, 34.954556000000025],
            [71.526511000000085, 34.967808000000048],
            [71.510045921000028, 34.979791782000063],
            [71.507758000000081, 34.981457000000034],
            [71.508413000000076, 34.996527000000071],
            [71.520207000000084, 35.005045000000052],
            [71.532010000000071, 35.008661000000075],
            [71.537763771000073, 35.010578627000029],
            [71.54492700000003, 35.012966000000063],
            [71.554933000000062, 35.020770000000027],
            [71.554933000000062, 35.027322000000026],
            [71.55084800000003, 35.034494000000052],
            [71.539863000000082, 35.043048000000056],
            [71.537244000000044, 35.054574000000059],
            [71.566076000000066, 35.052104000000043],
            [71.584095000000048, 35.04961000000003],
            [71.597123000000067, 35.038798000000043],
            [71.607031738000046, 35.036321039000029],
            [71.607184208000035, 35.03628292500008],
            [71.608212000000037, 35.036026000000049],
            [71.625953000000038, 35.033532000000037],
            [71.63537800000006, 35.026324000000045],
            [71.65062400000005, 35.032146000000068],
            [71.675813685000037, 35.032416647000048],
            [71.676405000000045, 35.032423000000051],
            [71.689210896000077, 35.028687867000031],
            [71.689676691000045, 35.02855200700003],
            [71.689711000000045, 35.028542000000073],
            [71.707729000000029, 35.027156000000048],
            [71.727604000000042, 35.027156000000048],
            [71.727688000000057, 35.027156000000048],
            [71.72786045600003, 35.029485708000038],
            [71.72824200000008, 35.034640000000024],
            [71.747647000000029, 35.049887000000069],
            [71.761507000000051, 35.054045000000031],
            [71.778140000000064, 35.058203000000049],
            [71.787010000000066, 35.056540000000041],
            [71.796840551000059, 35.061250627000049],
            [71.796961854000074, 35.061308753000048],
            [71.800316000000066, 35.06291600000003],
            [71.816394000000059, 35.067905000000053],
            [71.830255000000079, 35.073727000000076],
            [71.839680000000044, 35.069291000000078],
            [71.850449886000035, 35.062111210000069],
            [71.850547258000063, 35.062046297000052],
          ],
          [
            [71.979175224000073, 34.955507825000041],
            [71.979204668000079, 34.955500464000067],
            [71.979523791000076, 34.955507825000041],
            [71.979175224000073, 34.955507825000041],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "78",
      properties: { name: "Malakand Protected Area", count: 1320 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.09662155500007, 34.67045700400007],
            [72.098554356000079, 34.670332650000034],
            [72.099836878000076, 34.670206915000051],
            [72.105701790000069, 34.669619797000053],
            [72.113932355000031, 34.668795862000024],
            [72.115984845000071, 34.668640774000039],
            [72.121993148000058, 34.668068739000034],
            [72.123818000000028, 34.667895000000044],
            [72.125350314000059, 34.666688603000068],
            [72.136846000000048, 34.657638000000077],
            [72.135073452000029, 34.654329073000042],
            [72.133146605000036, 34.654286670000033],
            [72.133098696000047, 34.654289394000045],
            [72.12656961700003, 34.642375241000025],
            [72.126464527000053, 34.642210980000073],
            [72.125710637000054, 34.64105561100007],
            [72.117273620000049, 34.628012417000036],
            [72.118325956000035, 34.622142368000027],
            [72.119401524000068, 34.616142733000061],
            [72.133376069000064, 34.599287458000049],
            [72.139894717000061, 34.581832741000028],
            [72.159449722000033, 34.575549003000049],
            [72.159687208000037, 34.575712821000025],
            [72.160155192000047, 34.575561217000029],
            [72.173748318000037, 34.577371951000032],
            [72.173748621000072, 34.577371563000042],
            [72.177912123000056, 34.577934214000038],
            [72.178089127000078, 34.577958134000028],
            [72.17995118400006, 34.577525036000054],
            [72.180002957000056, 34.577512994000074],
            [72.183090173000039, 34.576794934000077],
            [72.187041727000064, 34.575876390000076],
            [72.192092467000066, 34.574709513000073],
            [72.192299794000064, 34.574661075000051],
            [72.202544000000046, 34.572258000000033],
            [72.221209720000047, 34.570327132000045],
            [72.221117000000049, 34.570040000000063],
            [72.220285000000047, 34.559784000000036],
            [72.229433000000029, 34.545923000000073],
            [72.235254000000054, 34.535667000000046],
            [72.234025084000052, 34.535274781000055],
            [72.222226000000035, 34.531509000000028],
            [72.215850000000046, 34.523192000000051],
            [72.210029000000077, 34.515153000000055],
            [72.201229000000069, 34.515536000000054],
            [72.201229233000049, 34.515535433000025],
            [72.197326039000075, 34.515705309000055],
            [72.19732319600007, 34.515706033000072],
            [72.182080039000084, 34.519586309000033],
            [72.170160039000052, 34.524575309000056],
            [72.152696039000034, 34.529288309000037],
            [72.152653093000083, 34.529289356000049],
            [72.141416634000052, 34.529563198000062],
            [72.125530039000068, 34.529565309000077],
            [72.113332039000056, 34.518477309000048],
            [72.102486522000049, 34.506152130000032],
            [72.102437769000062, 34.505988999000067],
            [72.102438842000083, 34.506154822000042],
            [72.094989359000067, 34.497689000000037],
            [72.080574359000082, 34.488264000000072],
            [72.080573565000066, 34.488264369000035],
            [72.078964093000081, 34.489012935000062],
            [72.06865435900005, 34.493808000000058],
            [72.05784335900006, 34.499907000000064],
            [72.041210359000047, 34.514599000000032],
            [72.038754161000043, 34.515758155000071],
            [72.019966055000054, 34.524624837000033],
            [72.016539359000035, 34.526242000000025],
            [72.016538046000051, 34.526241979000076],
            [72.016538000000082, 34.526242000000025],
            [72.001586000000032, 34.525997000000075],
            [71.999629359000039, 34.525964004000059],
            [71.992591643000083, 34.517392581000024],
            [71.991249448000076, 34.515757887000063],
            [71.990989806000073, 34.515441662000057],
            [71.988626887000066, 34.512563801000056],
            [71.98758424600004, 34.511293941000076],
            [71.98575730400006, 34.50906886100006],
            [71.984218745000078, 34.507195009000043],
            [71.97800735900006, 34.499630000000025],
            [71.969158105000076, 34.493158271000027],
            [71.969157757000062, 34.493158662000042],
            [71.969157721000045, 34.493158636000032],
            [71.959434000000044, 34.486047000000042],
            [71.961047369000084, 34.462071003000062],
            [71.961047284000074, 34.462071000000037],
            [71.961122913000054, 34.460948364000046],
            [71.96121222000005, 34.459622683000077],
            [71.961374284000044, 34.457217000000071],
            [71.960966850000034, 34.450597024000047],
            [71.960265284000059, 34.439198000000033],
            [71.936148284000069, 34.434486000000049],
            [71.931556410000042, 34.432649364000042],
            [71.921329791000062, 34.428558969000051],
            [71.915357000000029, 34.42617000000007],
            [71.910700919000078, 34.417827826000064],
            [71.908704000000057, 34.414250000000038],
            [71.902918532000058, 34.408575312000039],
            [71.899173223000048, 34.404901718000076],
            [71.899140471000067, 34.404869594000047],
            [71.898113705000071, 34.403862488000073],
            [71.894290000000069, 34.400112000000036],
            [71.885615272000052, 34.39389527700007],
            [71.877657000000056, 34.38819200000006],
            [71.857698000000084, 34.38625200000007],
            [71.838294000000076, 34.375163000000043],
            [71.834328837000044, 34.369063426000025],
            [71.831086000000084, 34.364075000000071],
            [71.831085942000072, 34.364074964000054],
            [71.831057170000065, 34.364057091000063],
            [71.831055711000033, 34.364074964000054],
            [71.831054059000053, 34.364095209000027],
            [71.831053821000069, 34.364098120000051],
            [71.828169596000066, 34.365920216000063],
            [71.819336297000063, 34.37150061400007],
            [71.817473263000068, 34.372677577000047],
            [71.816700359000038, 34.374035287000027],
            [71.816083757000058, 34.375118432000079],
            [71.815774271000066, 34.375665385000048],
            [71.814960588000076, 34.377091428000028],
            [71.813769598000079, 34.379183562000037],
            [71.812744446000067, 34.380984380000029],
            [71.81172282700004, 34.382778991000066],
            [71.810500035000075, 34.384926989000064],
            [71.808826103000058, 34.387867476000054],
            [71.808323414000029, 34.388750517000062],
            [71.805657794000069, 34.391807357000062],
            [71.80372720400004, 34.394021290000069],
            [71.802351538000039, 34.395598856000049],
            [71.802305127000068, 34.395652079000058],
            [71.80224621900004, 34.395719633000056],
            [71.80218873900003, 34.395785549000038],
            [71.802066739000054, 34.395925453000075],
            [71.801966256000071, 34.39604068400007],
            [71.801849488000073, 34.396174589000054],
            [71.801714997000033, 34.396328819000075],
            [71.801547862000064, 34.396520484000064],
            [71.801379781000037, 34.396713233000071],
            [71.801247764000038, 34.396864626000024],
            [71.801090782000074, 34.397044648000076],
            [71.800889860000041, 34.397275057000058],
            [71.800783183000078, 34.397397391000027],
            [71.800640731000044, 34.397560751000071],
            [71.800543896000079, 34.397671797000044],
            [71.800443442000073, 34.397786994000057],
            [71.800261480000074, 34.397995662000028],
            [71.80008231100004, 34.39820112700005],
            [71.799897324000028, 34.398413264000055],
            [71.799772537000081, 34.398556365000047],
            [71.799539455000058, 34.398823655000058],
            [71.799332977000063, 34.39906043700006],
            [71.799130877000039, 34.399292198000069],
            [71.798900414000059, 34.399556485000062],
            [71.798552344000029, 34.399725824000029],
            [71.798331563000033, 34.399833235000074],
            [71.798012653000058, 34.399988387000064],
            [71.797586241000033, 34.400195839000048],
            [71.796915929000079, 34.400521950000041],
            [71.795610995000061, 34.401156809000042],
            [71.794929912000043, 34.401490706000061],
            [71.79414266200007, 34.401873708000039],
            [71.792347194000058, 34.402747215000034],
            [71.790374041000064, 34.403704622000078],
            [71.789709803000051, 34.404027778000057],
            [71.788900899000055, 34.404421315000036],
            [71.78772348800004, 34.404994133000059],
            [71.786815300000058, 34.405435973000067],
            [71.785768869000037, 34.405945068000051],
            [71.782619392000072, 34.407477310000047],
            [71.782647238000038, 34.407484900000043],
            [71.777863000000082, 34.40981400000004],
            [71.771798024000077, 34.41620402500007],
            [71.762339000000054, 34.42617000000007],
            [71.757904000000053, 34.444188000000054],
            [71.755798642000059, 34.448155774000043],
            [71.754842921000034, 34.449965438000049],
            [71.752864221000038, 34.453724828000077],
            [71.752828104000059, 34.453791329000069],
            [71.751956620000044, 34.45539597100003],
            [71.750696000000062, 34.457771000000037],
            [71.748520129000042, 34.457034516000078],
            [71.747031657000036, 34.456530701000077],
            [71.742815583000038, 34.455103654000027],
            [71.74020697900005, 34.454220699000075],
            [71.73881151900008, 34.453754505000063],
            [71.73735126300005, 34.453254103000063],
            [71.735775193000052, 34.452720638000073],
            [71.732956196000032, 34.451766470000052],
            [71.729510193000067, 34.450600074000079],
            [71.727814332000037, 34.450026063000053],
            [71.727714090000063, 34.449992133000023],
            [71.726632734000077, 34.449937848000047],
            [71.723607960000038, 34.449802211000076],
            [71.721759083000052, 34.449719304000041],
            [71.715302691000034, 34.449429788000032],
            [71.711080894000077, 34.446934958000043],
            [71.708012201000031, 34.445121543000027],
            [71.707558235000079, 34.44485327700005],
            [71.707104270000059, 34.444585010000026],
            [71.706687516000045, 34.444338733000052],
            [71.706168250000076, 34.444031878000033],
            [71.705520583000066, 34.443649145000052],
            [71.704761036000036, 34.443200299000068],
            [71.704555711000069, 34.443078964000051],
            [71.704501304000075, 34.443046813000024],
            [71.704340580000064, 34.443054108000069],
            [71.704248973000063, 34.443057961000079],
            [71.704243724000037, 34.443078964000051],
            [71.70424173300006, 34.443086933000075],
            [71.70211050000006, 34.451611000000071],
            [71.687532608000083, 34.462212922000049],
            [71.674280000000067, 34.459562000000062],
            [71.66301500000003, 34.463538000000028],
            [71.662735830000031, 34.47023471600005],
            [71.662352000000055, 34.479442000000063],
            [71.659039000000064, 34.499321000000066],
            [71.658510964000072, 34.50671509600005],
            [71.657714000000055, 34.51787500000006],
            [71.647557333000066, 34.528954806000058],
            [71.647375628000077, 34.529153026000074],
            [71.643136000000084, 34.533778000000041],
            [71.64291640600004, 34.536411806000046],
            [71.642473000000052, 34.54173000000003],
            [71.659039000000064, 34.544381000000044],
            [71.658116455000084, 34.549684505000073],
            [71.656388000000049, 34.55962100000005],
            [71.668316000000061, 34.560947000000056],
            [71.66765300000003, 34.578175000000044],
            [71.675605000000076, 34.594079000000079],
            [71.677593000000059, 34.612633000000073],
            [71.67876947600007, 34.613956507000069],
            [71.678777127000046, 34.613965115000042],
            [71.678784779000068, 34.613973723000072],
            [71.688195000000064, 34.624560000000031],
            [71.708074000000067, 34.623898000000054],
            [71.715363000000082, 34.639138000000059],
            [71.729941909000047, 34.646426955000038],
            [71.729942000000051, 34.646427000000074],
            [71.735905000000059, 34.64576500000004],
            [71.738548963000028, 34.646224714000027],
            [71.751146000000062, 34.648415000000057],
            [71.759927000000062, 34.656163000000049],
            [71.783961000000033, 34.646550000000047],
            [71.797267000000033, 34.639620000000036],
            [71.80284288200005, 34.634741000000076],
            [71.802944955000044, 34.634651683000072],
            [71.807715641000073, 34.63047724200004],
            [71.810573000000034, 34.627977000000044],
            [71.826445792000072, 34.624904401000038],
            [71.827760000000069, 34.624650000000031],
            [71.849936000000071, 34.630749000000037],
            [71.859759952000047, 34.632169073000057],
            [71.872945000000072, 34.634075000000053],
            [71.894290000000069, 34.630472000000054],
            [71.915635000000066, 34.634907000000055],
            [71.918062925000072, 34.635769629000038],
            [71.936702000000082, 34.642392000000029],
            [71.936702819000061, 34.642391936000024],
            [71.937792892000061, 34.642306678000068],
            [71.938413127000047, 34.642258168000069],
            [71.948798370000077, 34.641445908000037],
            [71.986323000000084, 34.638511000000051],
            [72.008343923000041, 34.639326568000058],
            [72.009618098000033, 34.639373758000033],
            [72.012946981000084, 34.639497047000077],
            [72.023746000000074, 34.639897000000076],
            [72.048659945000054, 34.649783260000049],
            [72.058674000000053, 34.653757000000041],
            [72.075650878000033, 34.657323549000068],
            [72.091661000000045, 34.660687000000053],
            [72.093426782000051, 34.664165078000053],
            [72.096580000000074, 34.670376000000033],
            [72.096580421000056, 34.670375972000045],
            [72.09662155500007, 34.67045700400007],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "79",
      properties: { name: "Mansehra", count: 1498 },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [73.023443130000032, 34.583552544000042],
              [73.023257172000058, 34.583580227000027],
              [73.02325655900006, 34.583580392000044],
              [73.023443130000032, 34.583552544000042],
            ],
          ],
          [
            [
              [73.023443130000032, 34.583552544000042],
              [73.023465951000048, 34.583549141000049],
              [73.024481630000082, 34.583397552000065],
              [73.026683000000048, 34.58306900000008],
              [73.026682903000051, 34.58306876100005],
              [73.026683012000035, 34.583068733000061],
              [73.027237000000071, 34.570595000000026],
              [73.029997861000084, 34.568119531000036],
              [73.033227176000082, 34.565224034000039],
              [73.033228681000082, 34.565222685000037],
              [73.035276000000067, 34.563387000000034],
              [73.038696362000053, 34.561716704000048],
              [73.047196000000042, 34.557566000000065],
              [73.060779000000082, 34.557843000000048],
              [73.068033945000082, 34.558152041000028],
              [73.073808000000042, 34.558398000000068],
              [73.074275939000074, 34.558732237000072],
              [73.085299589000044, 34.566606157000024],
              [73.087391000000082, 34.568100000000072],
              [73.087547241000038, 34.568539447000035],
              [73.091826000000083, 34.58057400000007],
              [73.090331133000063, 34.58971282300007],
              [73.08933200000007, 34.595821000000058],
              [73.089789437000036, 34.600624254000024],
              [73.09177416700004, 34.621464637000031],
              [73.092104000000063, 34.624928000000068],
              [73.115111999000078, 34.644055000000037],
              [73.121152352000081, 34.645579686000076],
              [73.143664000000058, 34.651262000000031],
              [73.147274421000077, 34.650919474000034],
              [73.147287521000067, 34.650918231000048],
              [73.18164100000007, 34.647659000000033],
              [73.181641028000058, 34.647659092000026],
              [73.181642000000068, 34.647659000000033],
              [73.182318705000057, 34.649850352000044],
              [73.186123511000062, 34.662171367000042],
              [73.187463000000037, 34.666509000000076],
              [73.187609046000034, 34.666847837000034],
              [73.187610045000042, 34.666850154000031],
              [73.194166690000031, 34.682061947000079],
              [73.194170049000036, 34.682069741000078],
              [73.194393000000048, 34.682587000000069],
              [73.200648513000033, 34.693794833000027],
              [73.201046000000076, 34.694507000000044],
              [73.201601000000039, 34.711416000000042],
              [73.207422000000065, 34.729435000000024],
              [73.222946000000036, 34.743572000000029],
              [73.225163000000066, 34.754938000000038],
              [73.223966553000082, 34.757238924000035],
              [73.223966525000037, 34.757238978000032],
              [73.217956000000072, 34.768798000000061],
              [73.218798000000049, 34.771494000000075],
              [73.222114000000033, 34.782104000000061],
              [73.240687000000037, 34.79042000000004],
              [73.261755000000051, 34.79457900400007],
              [73.303890000000081, 34.794856000000038],
              [73.318098789000032, 34.792451727000071],
              [73.321909000000062, 34.791807000000063],
              [73.327019362000044, 34.789807154000073],
              [73.32701948600004, 34.789807105000079],
              [73.327019326000084, 34.789806776000034],
              [73.327019362000044, 34.789806762000069],
              [73.327066923000075, 34.789788151000039],
              [73.341035000000034, 34.784322000000031],
              [73.342652970000074, 34.783452602000068],
              [73.342653003000066, 34.783452584000031],
              [73.356443967000075, 34.776042159000042],
              [73.359608000000037, 34.774342000000047],
              [73.368327261000047, 34.773066197000048],
              [73.368328041000041, 34.77306608400005],
              [73.382339000000059, 34.771016000000031],
              [73.382339160000072, 34.771016123000038],
              [73.382340000000056, 34.771016000000031],
              [73.390656000000035, 34.777392000000077],
              [73.393151000000046, 34.791529000000025],
              [73.399804000000074, 34.80178600000005],
              [73.428911000000085, 34.80372600000004],
              [73.438059000000067, 34.810934000000032],
              [73.443603000000053, 34.81703200000004],
              [73.447206000000051, 34.828952000000072],
              [73.45607700000005, 34.836160000000064],
              [73.464948001000039, 34.848634000000061],
              [73.48268900000005, 34.859722000000033],
              [73.496549000000073, 34.874414000000058],
              [73.492391000000055, 34.88356200000004],
              [73.491301000000078, 34.89882300000005],
              [73.491395394000051, 34.898894346000077],
              [73.515121000000079, 34.916827000000069],
              [73.525655000000029, 34.917659000000072],
              [73.531789402000072, 34.920663651000041],
              [73.539270908000049, 34.924294382000028],
              [73.546952480000073, 34.933398467000075],
              [73.54697565500004, 34.93342593400007],
              [73.546968244000084, 34.933429411000077],
              [73.549104949000082, 34.93594954200006],
              [73.550049000000058, 34.93706300000008],
              [73.55005103600007, 34.937070830000039],
              [73.553653000000054, 34.950924000000043],
              [73.559728001000053, 34.968043370000032],
              [73.55975200000006, 34.968111000000079],
              [73.563483414000075, 34.97482754400005],
              [73.566670908000049, 34.980694382000024],
              [73.580870908000065, 34.980694382000024],
              [73.581280743000036, 34.981210259000079],
              [73.594125000000076, 34.99860300000006],
              [73.59514147200008, 34.998657329000025],
              [73.595170908000057, 34.998694382000053],
              [73.609608224000056, 34.999430562000043],
              [73.630440000000078, 35.000544000000048],
              [73.633471868000072, 34.99800971600007],
              [73.649012000000084, 34.985020000000077],
              [73.662041000000045, 34.980862000000059],
              [73.662070459000063, 34.980894527000032],
              [73.675347000000045, 34.995554000000027],
              [73.683941000000061, 35.01440400000007],
              [73.684047093000061, 35.014441766000061],
              [73.700296000000037, 35.020226000000036],
              [73.712645981000037, 35.023950654000032],
              [73.717817026000034, 35.025478462000024],
              [73.731296106000059, 35.022042188000057],
              [73.73189700000006, 35.021889000000044],
              [73.732129112000052, 35.021806107000032],
              [73.743540000000053, 35.017731000000026],
              [73.768336457000032, 35.016930742000056],
              [73.769292610000036, 35.016899884000054],
              [73.76932000000005, 35.016899000000024],
              [73.774932484000033, 35.018329711000035],
              [73.783458000000053, 35.020503000000076],
              [73.78346988800007, 35.020494123000049],
              [73.80424800000003, 35.004979000000048],
              [73.82309900000007, 35.004425000000026],
              [73.834464000000082, 35.016345000000058],
              [73.840116324000064, 35.028591702000028],
              [73.842780000000062, 35.034363000000042],
              [73.843335000000081, 35.057649000000026],
              [73.84416600000003, 35.072341000000051],
              [73.847861549000072, 35.085460065000063],
              [73.849670908000064, 35.092094382000028],
              [73.870870908000029, 35.10849438200006],
              [73.882005493000065, 35.109228530000053],
              [73.889074000000051, 35.109764000000041],
              [73.897212772000046, 35.109917447000043],
              [73.903766000000076, 35.110041000000024],
              [73.913745000000063, 35.108378000000073],
              [73.920537317000083, 35.113724949000073],
              [73.93980300000004, 35.128891000000067],
              [73.954772000000048, 35.144692000000077],
              [73.95774700000004, 35.165247000000079],
              [73.957747312000038, 35.16524946800007],
              [73.957748347000063, 35.165247546000046],
              [73.957839457000034, 35.165078419000054],
              [73.957839179000075, 35.165076510000063],
              [73.957860000000039, 35.165038000000038],
              [73.957860949000064, 35.165038523000078],
              [73.97509000000008, 35.174544000000026],
              [73.991726000000028, 35.181674000000044],
              [74.003015000000062, 35.184645000000046],
              [74.003015168000047, 35.184644518000027],
              [74.007768000000056, 35.170979000000045],
              [74.018463000000054, 35.167414000000065],
              [74.035694000000035, 35.149590000000046],
              [74.035694883000076, 35.149590138000065],
              [74.054707000000064, 35.152561000000048],
              [74.065995000000044, 35.152561000000048],
              [74.080255000000079, 35.156126000000029],
              [74.080256312000074, 35.156125203000045],
              [74.080257500000073, 35.15612550000003],
              [74.096891952000078, 35.146025440000074],
              [74.112339000000077, 35.153155000000027],
              [74.11233971300004, 35.153153675000056],
              [74.11234150000007, 35.153154500000028],
              [74.116500500000029, 35.145430500000032],
              [74.113250865000055, 35.13459868700005],
              [74.112935500000049, 35.133547500000077],
              [74.115906500000051, 35.123447000000056],
              [74.116871845000048, 35.123196697000026],
              [74.131948500000078, 35.119287500000041],
              [74.128577299000028, 35.105233168000041],
              [74.118561500000055, 35.101227000000051],
              [74.094821000000081, 35.091052500000046],
              [74.087472500000047, 35.082573500000024],
              [74.081255000000056, 35.062789500000065],
              [74.076733000000047, 35.047527500000058],
              [74.082950500000038, 35.037353000000053],
              [74.095386000000076, 35.031700500000056],
              [74.107821500000057, 35.02604800000006],
              [74.111213500000076, 35.017569000000037],
              [74.106691500000068, 35.010220500000059],
              [74.097082000000057, 35.000611500000048],
              [74.087472500000047, 34.998915500000066],
              [74.075602500000059, 34.997785000000079],
              [74.069384500000069, 34.996654500000034],
              [74.060906000000045, 34.988741000000061],
              [74.060340500000052, 34.981958000000077],
              [74.061829374000069, 34.976374668000062],
              [74.061838710000075, 34.976339658000029],
              [74.067123500000037, 34.956521500000065],
              [74.071645500000045, 34.937868000000037],
              [74.064297000000067, 34.930519500000059],
              [74.048470000000066, 34.922040500000037],
              [74.038861000000054, 34.914127000000065],
              [74.039991000000043, 34.899430500000051],
              [74.045079000000044, 34.890386500000034],
              [74.02868600000005, 34.877385500000059],
              [74.021903000000066, 34.873994000000039],
              [74.02190114800004, 34.873994074000052],
              [74.021901000000071, 34.873994000000039],
              [74.007771910000031, 34.874558884000066],
              [74.003815000000031, 34.86947200000003],
              [73.994771000000071, 34.864950000000078],
              [73.994769235000035, 34.864950118000024],
              [73.994769000000076, 34.864950000000078],
              [73.989935781000042, 34.865272348000076],
              [73.977811000000031, 34.866081000000065],
              [73.971593000000041, 34.850253000000066],
              [73.968202000000076, 34.835557000000051],
              [73.945592000000033, 34.834991000000059],
              [73.93428700000004, 34.831600000000037],
              [73.93428700000004, 34.817468000000076],
              [73.927504064000061, 34.811251059000028],
              [73.927504000000056, 34.811251000000027],
              [73.914110493000067, 34.815114170000072],
              [73.898111276000066, 34.819728920000045],
              [73.898111000000029, 34.819729000000052],
              [73.88737100000003, 34.814642000000049],
              [73.870978000000036, 34.814077000000054],
              [73.865326697000057, 34.808990627000071],
              [73.865326000000039, 34.808990000000051],
              [73.86080400000003, 34.811816000000078],
              [73.852890243000047, 34.81464191300006],
              [73.838196000000039, 34.814076500000056],
              [73.837630500000046, 34.802206500000068],
              [73.830847500000061, 34.79146650000007],
              [73.815596527000082, 34.792933070000061],
              [73.801454172000035, 34.794292791000032],
              [73.80145200000004, 34.794293000000039],
              [73.797287683000036, 34.791642492000051],
              [73.795235433000073, 34.790336275000072],
              [73.795235000000048, 34.790336000000025],
              [73.777713442000049, 34.794857628000045],
              [73.777712000000065, 34.794858000000033],
              [73.753517964000082, 34.778728643000079],
              [73.75058000000007, 34.776770000000056],
              [73.743797000000029, 34.773378000000037],
              [73.72796900000003, 34.764334000000076],
              [73.72796900000003, 34.75416000000007],
              [73.723447000000078, 34.746811000000037],
              [73.715537047000055, 34.744550871000058],
              [73.715534000000048, 34.744550000000061],
              [73.715534000000048, 34.736071000000038],
              [73.70366400000006, 34.719114000000047],
              [73.696315000000084, 34.713461000000052],
              [73.685576000000083, 34.705548000000078],
              [73.686706000000072, 34.691981000000055],
              [73.673140000000046, 34.682372000000044],
              [73.664662931000066, 34.669938832000071],
              [73.664661516000081, 34.669936756000027],
              [73.664661000000081, 34.669936000000064],
              [73.663531000000035, 34.662588000000028],
              [73.670125312000039, 34.660609707000049],
              [73.670148857000072, 34.660602643000061],
              [73.686141000000077, 34.655805000000043],
              [73.699707000000046, 34.64789200000007],
              [73.693489000000056, 34.639413000000047],
              [73.666339570000048, 34.607811630000072],
              [73.666334135000056, 34.607805305000056],
              [73.659009000000083, 34.599279000000024],
              [73.662400000000048, 34.593627000000026],
              [73.663389387000052, 34.58967003600003],
              [73.665792000000067, 34.580061000000057],
              [73.663531000000035, 34.571582000000035],
              [73.65674800000005, 34.562538000000075],
              [73.655268871000033, 34.562403480000057],
              [73.655268982000052, 34.56240319300008],
              [73.644313500000067, 34.561407000000031],
              [73.644313237000063, 34.56140711300003],
              [73.64431200000007, 34.561407000000031],
              [73.63639900000004, 34.56479900000005],
              [73.627920000000074, 34.577234000000033],
              [73.617745545000048, 34.582321727000078],
              [73.61774500000007, 34.582322000000033],
              [73.614361854000038, 34.583127457000046],
              [73.605875000000083, 34.585148000000061],
              [73.591461841000068, 34.585148000000061],
              [73.590614500000072, 34.585148000000061],
              [73.590613000000076, 34.585148000000061],
              [73.574221089000048, 34.584018006000065],
              [73.57422100000008, 34.584018000000071],
              [73.567728704000046, 34.587624335000044],
              [73.567723362000038, 34.587627302000044],
              [73.564046440000084, 34.589669755000045],
              [73.556699000000037, 34.587974000000031],
              [73.551455984000029, 34.581607647000055],
              [73.548785500000065, 34.578365000000076],
              [73.537480500000072, 34.571582000000035],
              [73.523349000000053, 34.569886000000054],
              [73.523348616000078, 34.569886074000067],
              [73.523348000000055, 34.569886000000054],
              [73.508651000000043, 34.572712000000024],
              [73.495651061000046, 34.57723363100007],
              [73.484162989000083, 34.572720784000069],
              [73.479824500000063, 34.571016500000042],
              [73.479823503000034, 34.57101719800005],
              [73.479823000000067, 34.57101700000004],
              [73.474171000000069, 34.574973000000057],
              [73.465693000000044, 34.574973000000057],
              [73.465692000000047, 34.574973000000057],
              [73.453256000000067, 34.569321000000059],
              [73.452379411000038, 34.567348578000065],
              [73.448734000000059, 34.559146000000055],
              [73.448292090000052, 34.541019861000052],
              [73.448292045000073, 34.541018001000054],
              [73.448169000000064, 34.535971000000075],
              [73.438213252000082, 34.52873016500007],
              [73.438211772000045, 34.528729089000024],
              [73.435734000000082, 34.526927000000057],
              [73.436662370000079, 34.524574867000069],
              [73.44421200000005, 34.505447000000061],
              [73.442517544000054, 34.494710447000045],
              [73.442517000000066, 34.494707000000062],
              [73.442516729000033, 34.494706413000074],
              [73.435734000000082, 34.48001000000005],
              [73.434794107000073, 34.478521801000056],
              [73.42895100000004, 34.469270000000051],
              [73.428951186000063, 34.469268405000037],
              [73.431917476000081, 34.443838243000073],
              [73.431920772000069, 34.443809988000055],
              [73.432907000000057, 34.435355000000072],
              [73.428847794000035, 34.420470713000043],
              [73.428822753000077, 34.420378891000041],
              [73.427820000000054, 34.416702000000043],
              [73.424222263000047, 34.407107505000056],
              [73.424220840000032, 34.407103709000069],
              [73.42103763800003, 34.398614701000042],
              [73.421036808000054, 34.398612706000051],
              [73.412558000000047, 34.385613000000035],
              [73.398428795000029, 34.378264934000072],
              [73.398427964000064, 34.378264595000076],
              [73.398426305000044, 34.378263918000073],
              [73.398427964000064, 34.378259563000029],
              [73.40127975300004, 34.370773119000035],
              [73.401174753000078, 34.370766119000052],
              [73.401074053000059, 34.370762975000048],
              [73.383432753000079, 34.37021211900003],
              [73.371335280000039, 34.36755869700005],
              [73.371266798000079, 34.367543911000041],
              [73.371193023000046, 34.36752798200007],
              [73.371192959000041, 34.367527968000047],
              [73.371135160000051, 34.367515489000027],
              [73.35903875300005, 34.364945119000026],
              [73.351292000000058, 34.354096000000027],
              [73.341867000000036, 34.336909000000048],
              [73.327655616000072, 34.323915588000034],
              [73.322463000000084, 34.319168000000047],
              [73.318245585000057, 34.317137239000033],
              [73.318242249000036, 34.317135633000078],
              [73.311780654000074, 34.314024260000053],
              [73.305957026000044, 34.311220080000055],
              [73.305913049000083, 34.311198904000037],
              [73.300009000000045, 34.30835600000006],
              [73.282725244000062, 34.30451576400003],
              [73.282669908000059, 34.304503469000053],
              [73.277555000000064, 34.303367000000037],
              [73.264810427000043, 34.282902898000032],
              [73.257874000000072, 34.271765000000073],
              [73.247352343000045, 34.277284670000029],
              [73.240964000000076, 34.280636000000072],
              [73.235035506000031, 34.284115364000058],
              [73.235030567000081, 34.284118263000039],
              [73.228212000000042, 34.288120000000049],
              [73.22482093900004, 34.287525061000053],
              [73.224813466000057, 34.287523751000037],
              [73.219271133000063, 34.286551387000031],
              [73.219270856000037, 34.286551338000038],
              [73.217313751000063, 34.28620797800005],
              [73.212412000000029, 34.285348000000056],
              [73.210144343000081, 34.277345524000054],
              [73.207700155000055, 34.268720075000033],
              [73.207699000000048, 34.26871600000004],
              [73.205451656000037, 34.26161896800005],
              [73.202450768000062, 34.252142269000046],
              [73.202432000000044, 34.252083000000027],
              [73.201742954000053, 34.248206942000024],
              [73.198392736000073, 34.22936111100006],
              [73.198392604000048, 34.229360371000041],
              [73.197997000000044, 34.227135000000033],
              [73.192561778000083, 34.227135000000033],
              [73.19255412900003, 34.227135000000033],
              [73.188294000000042, 34.227135000000033],
              [73.187937871000031, 34.22779872600006],
              [73.182196000000033, 34.238500000000045],
              [73.172216000000049, 34.237946000000079],
              [73.166708089000053, 34.239607141000079],
              [73.160214613000051, 34.241565520000051],
              [73.160178374000054, 34.241576449000036],
              [73.15906725800005, 34.241911553000079],
              [73.15475200000003, 34.243213000000026],
              [73.154440893000071, 34.243444337000028],
              [73.15194740000004, 34.245298485000035],
              [73.143941000000041, 34.251252000000079],
              [73.139307622000047, 34.249246523000068],
              [73.13930715500004, 34.249246321000044],
              [73.130677706000029, 34.245511214000032],
              [73.130667601000084, 34.245506840000076],
              [73.129940896000051, 34.245192298000063],
              [73.125368000000037, 34.243213000000026],
              [73.12303980300004, 34.242329874000063],
              [73.116828798000029, 34.239973931000065],
              [73.113535702000036, 34.238724802000036],
              [73.112376303000076, 34.238285022000071],
              [73.112349189000042, 34.23827473700004],
              [73.101982031000034, 34.234342293000054],
              [73.101251000000048, 34.234065000000044],
              [73.089921229000083, 34.232223708000049],
              [73.089898131000041, 34.232219954000072],
              [73.081734833000041, 34.23089327100007],
              [73.079075000000046, 34.230461000000048],
              [73.075755451000077, 34.233610442000042],
              [73.068264000000056, 34.240718000000072],
              [73.067069596000067, 34.241880883000078],
              [73.063139405000072, 34.245707353000057],
              [73.060946229000081, 34.247842650000052],
              [73.060945514000082, 34.247843347000071],
              [73.059848639000052, 34.248911274000079],
              [73.057730000000049, 34.250974000000042],
              [73.053394256000047, 34.254470746000038],
              [73.049285240000074, 34.257784639000079],
              [73.049136000000033, 34.257905000000051],
              [73.047970622000037, 34.262037142000054],
              [73.046118709000041, 34.268603566000024],
              [73.046087000000057, 34.26871600000004],
              [73.041533487000038, 34.266399331000059],
              [73.030953163000049, 34.261016430000041],
              [73.030286000000046, 34.260677000000044],
              [73.025013161000061, 34.259196694000025],
              [73.01601680400006, 34.256671041000061],
              [73.016012325000077, 34.256669784000053],
              [73.014663993000056, 34.25629125100005],
              [73.014485000000036, 34.256241000000045],
              [73.007160097000053, 34.249388341000042],
              [73.006289361000029, 34.248573742000076],
              [73.005892000000074, 34.248202000000049],
              [73.000902000000053, 34.243213000000026],
              [73.005617868000058, 34.23468037400005],
              [73.00563224800004, 34.234654355000032],
              [73.006724000000077, 34.232679000000076],
              [73.004410675000031, 34.22906441300006],
              [73.003898039000035, 34.228263417000051],
              [73.003895680000085, 34.228259731000037],
              [72.997853000000077, 34.218818000000056],
              [72.992571000000055, 34.215825000000052],
              [72.989634591000083, 34.214161294000064],
              [72.989634547000037, 34.214161269000044],
              [72.989537000000041, 34.214106000000072],
              [72.987717258000032, 34.215363794000041],
              [72.987713185000075, 34.215366609000057],
              [72.985252622000075, 34.217067334000035],
              [72.975962801000037, 34.223488399000075],
              [72.975952178000057, 34.223495741000079],
              [72.975009234000083, 34.224147498000036],
              [72.970687000000055, 34.227135000000033],
              [72.969523173000084, 34.227516258000037],
              [72.962025777000065, 34.229972334000024],
              [72.961511741000038, 34.230140727000048],
              [72.961511628000039, 34.230140764000055],
              [72.954815448000033, 34.232334369000057],
              [72.954609000000062, 34.232402000000036],
              [72.953606347000061, 34.232921812000029],
              [72.953591514000038, 34.232929019000039],
              [72.951115483000081, 34.234212771000045],
              [72.951115414000071, 34.234212807000063],
              [72.947657941000045, 34.23600540700005],
              [72.947043061000045, 34.236324205000074],
              [72.943133139000054, 34.238351388000069],
              [72.939639000000057, 34.240163000000052],
              [72.937370194000039, 34.241010064000079],
              [72.93488681000008, 34.241937242000063],
              [72.934884265000051, 34.241938192000077],
              [72.934216408000054, 34.242187538000053],
              [72.931498323000028, 34.243202343000064],
              [72.931490200000042, 34.243205376000049],
              [72.931105532000061, 34.243348992000051],
              [72.929867625000043, 34.243811169000026],
              [72.92566103300004, 34.245381710000061],
              [72.918878648000032, 34.247913931000028],
              [72.91884900000008, 34.247925000000066],
              [72.918505203000052, 34.248107440000069],
              [72.91786477800008, 34.248447290000058],
              [72.917856261000054, 34.248451771000077],
              [72.917704308000054, 34.248532401000034],
              [72.916402509000079, 34.249223167000025],
              [72.91603490500006, 34.249418227000035],
              [72.914306004000082, 34.250335624000058],
              [72.91048945600005, 34.252360777000035],
              [72.907800762000079, 34.253787463000037],
              [72.90714656800003, 34.254135052000038],
              [72.907045477000054, 34.254188697000075],
              [72.906995402000064, 34.254215270000032],
              [72.906639271000074, 34.254403779000029],
              [72.905265000000043, 34.255133000000058],
              [72.903209931000049, 34.258756735000077],
              [72.901322221000044, 34.26208536300004],
              [72.898596362000035, 34.266891911000073],
              [72.897283781000056, 34.269206406000023],
              [72.895781568000075, 34.271855280000068],
              [72.894732000000033, 34.273706000000061],
              [72.894697116000032, 34.273747663000051],
              [72.894602678000069, 34.273860450000029],
              [72.89412687500004, 34.27442810000008],
              [72.893941175000066, 34.274650475000044],
              [72.892261500000075, 34.276656492000029],
              [72.890143557000044, 34.279185927000071],
              [72.888816948000056, 34.280770281000059],
              [72.888037423000071, 34.281701259000045],
              [72.886926245000041, 34.283028326000078],
              [72.885813711000083, 34.284357012000044],
              [72.884752000000049, 34.285625000000039],
              [72.883380965000072, 34.286221168000054],
              [72.883331989000055, 34.286242464000054],
              [72.883329890000084, 34.286243377000062],
              [72.881464145000052, 34.287054659000034],
              [72.879133326000044, 34.288068170000031],
              [72.878131804000077, 34.288503662000039],
              [72.874372100000073, 34.290138496000054],
              [72.871235575000071, 34.291502352000066],
              [72.86562500000008, 34.293942000000072],
              [72.865428292000047, 34.294058234000033],
              [72.862011216000042, 34.296077418000039],
              [72.856753667000078, 34.299184151000077],
              [72.854339000000039, 34.30061100000006],
              [72.854338717000076, 34.300610873000039],
              [72.853428000000065, 34.301149000000066],
              [72.859997959000054, 34.309494520000044],
              [72.863685000000032, 34.314178000000027],
              [72.86562500000008, 34.323603000000048],
              [72.865033650000044, 34.324303850000035],
              [72.865105949000053, 34.32431596400005],
              [72.866088316000059, 34.324480564000055],
              [72.866304712000044, 34.324516765000055],
              [72.868699036000066, 34.329893080000033],
              [72.868707377000078, 34.329911809000066],
              [72.868707680000057, 34.329912489000037],
              [72.868699036000066, 34.329929732000039],
              [72.868178313000044, 34.330968460000065],
              [72.862701259000062, 34.341893990000074],
              [72.86134011200005, 34.34569741100006],
              [72.859698865000041, 34.350283895000075],
              [72.854894154000078, 34.356871500000068],
              [72.852146092000055, 34.359112146000029],
              [72.84833546200008, 34.362260032000052],
              [72.843104513000071, 34.365438576000031],
              [72.843104441000037, 34.365438620000077],
              [72.839436873000068, 34.367667188000041],
              [72.835284568000077, 34.371874515000059],
              [72.82756802800003, 34.391670574000045],
              [72.827263423000034, 34.392696062000027],
              [72.821911000000057, 34.409707000000026],
              [72.817112000000066, 34.421704000000034],
              [72.817712000000029, 34.427102000000048],
              [72.824910000000045, 34.431901000000039],
              [72.830309000000057, 34.433101000000079],
              [72.833908000000065, 34.43790000000007],
              [72.837507000000073, 34.444498000000067],
              [72.842060723000031, 34.453333293000071],
              [72.842060859000071, 34.453333560000033],
              [72.842414203000033, 34.454045299000029],
              [72.842414948000055, 34.454045534000045],
              [72.845571065000058, 34.45504223100005],
              [72.850091760000055, 34.456472114000064],
              [72.850103252000054, 34.456464142000073],
              [72.850105346000078, 34.456462688000045],
              [72.857900693000033, 34.451054477000071],
              [72.865710319000073, 34.447439913000039],
              [72.865710370000045, 34.447439935000034],
              [72.868699036000066, 34.448760306000054],
              [72.869786747000035, 34.449240849000034],
              [72.869790822000084, 34.449242649000041],
              [72.871117231000085, 34.449828645000025],
              [72.871117836000053, 34.449829951000027],
              [72.872167827000055, 34.452098308000075],
              [72.872168472000055, 34.452099700000076],
              [72.874722745000042, 34.457617341000059],
              [72.880736076000062, 34.466603570000075],
              [72.884857698000076, 34.468962329000078],
              [72.884879346000048, 34.468974718000027],
              [72.884980569000049, 34.469032647000063],
              [72.889204535000033, 34.471449975000041],
              [72.89285626000003, 34.479854598000031],
              [72.89465630400008, 34.48703703700005],
              [72.89456203900005, 34.496555340000043],
              [72.901026048000062, 34.50681518600004],
              [72.902907257000038, 34.50980260800003],
              [72.909551002000057, 34.515867811000078],
              [72.915748827000073, 34.519732386000044],
              [72.917495663000068, 34.520821604000048],
              [72.920106192000048, 34.522406112000056],
              [72.920106254000075, 34.522406131000025],
              [72.926374752000072, 34.526208077000035],
              [72.926673041000072, 34.526388994000058],
              [72.932082466000054, 34.534249206000027],
              [72.934253726000065, 34.54139007200007],
              [72.938393591000079, 34.556701812000028],
              [72.938881000000038, 34.558469000000059],
              [72.93999715800004, 34.565165945000047],
              [72.939997194000057, 34.565166161000036],
              [72.940027650000047, 34.565348902000039],
              [72.94024885400006, 34.566676126000061],
              [72.940259000000083, 34.566737000000046],
              [72.940259075000029, 34.566737362000026],
              [72.940712188000077, 34.566674147000072],
              [72.940712255000051, 34.566673998000056],
              [72.94241100000005, 34.566437000000064],
              [72.942411350000043, 34.566437091000068],
              [72.942412000000047, 34.566437000000064],
              [72.957381000000055, 34.570318000000043],
              [72.96194371200005, 34.573545421000063],
              [72.961943944000041, 34.573545585000033],
              [72.968746000000067, 34.57835700000004],
              [72.978036343000042, 34.576212942000041],
              [72.979557000000057, 34.575862000000029],
              [72.994804000000045, 34.577525000000037],
              [73.008109807000039, 34.585840880000035],
              [73.010935968000069, 34.585419078000029],
              [73.010960218000037, 34.585415459000046],
              [73.017959064000081, 34.584370888000024],
              [73.022440336000045, 34.583702063000032],
              [73.023443060000034, 34.58355240800006],
              [73.023443130000032, 34.583552544000042],
            ],
            [
              [73.006187953000051, 34.26507199100007],
              [73.000935745000049, 34.263702692000038],
              [72.998895891000075, 34.264154684000061],
              [72.996941699000047, 34.264736524000057],
              [72.99421314500006, 34.264224766000041],
              [72.993997039000078, 34.264225527000065],
              [72.993393548000029, 34.264227637000033],
              [72.993393512000068, 34.264227637000033],
              [72.991757736000068, 34.263516827000046],
              [72.984962100000075, 34.260922873000027],
              [72.983767226000055, 34.260636877000024],
              [72.982685623000066, 34.258503441000073],
              [72.978282971000056, 34.251731255000038],
              [72.974601333000066, 34.24638238700004],
              [72.971436724000057, 34.242407003000039],
              [72.971436705000031, 34.242406979000066],
              [72.972340641000073, 34.242377697000052],
              [72.975661905000038, 34.241593049000073],
              [72.975661942000045, 34.241593034000061],
              [72.975679695000053, 34.24159194300006],
              [72.975725479000062, 34.241589085000044],
              [72.979093133000049, 34.241378858000076],
              [72.979956845000061, 34.241581561000032],
              [72.981993347000071, 34.242059401000063],
              [72.981993383000031, 34.242059409000035],
              [72.983474080000065, 34.242406943000049],
              [72.983767262000072, 34.242475756000033],
              [72.985377580000034, 34.242853713000045],
              [72.98584823300007, 34.242964180000058],
              [72.986206448000075, 34.243048268000052],
              [72.987063876000036, 34.243249496000033],
              [72.99408061500003, 34.245890560000078],
              [72.998924230000057, 34.247293591000073],
              [73.000368322000043, 34.247711886000047],
              [73.000553305000039, 34.247797700000035],
              [73.001892364000071, 34.248418822000076],
              [73.003543458000081, 34.249184685000046],
              [73.003603627000075, 34.25189483500003],
              [73.004385452000065, 34.255440198000031],
              [73.008121502000051, 34.259886559000051],
              [73.011297570000067, 34.267097135000029],
              [73.011297608000064, 34.267097221000029],
              [73.009701221000057, 34.266356766000058],
              [73.007855343000074, 34.265681743000073],
              [73.006187953000051, 34.26507199100007],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "80",
      properties: { name: "Mardan", count: 477 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.203099000000066, 34.510995000000037],
            [72.207534000000066, 34.501570000000072],
            [72.218900000000076, 34.495749000000046],
            [72.219342468000036, 34.494942003000062],
            [72.22361200000006, 34.48715500000003],
            [72.221844625000074, 34.476331621000043],
            [72.221394000000032, 34.473572000000047],
            [72.221251042000063, 34.469280187000038],
            [72.221250968000049, 34.46927794000004],
            [72.220840000000067, 34.456940000000031],
            [72.232483000000059, 34.44973200000004],
            [72.244403000000034, 34.444465000000037],
            [72.25261460300004, 34.438935181000033],
            [72.257986000000074, 34.435318000000052],
            [72.269906000000049, 34.430605000000071],
            [72.269924292000042, 34.43060545700007],
            [72.269925448000038, 34.430605000000071],
            [72.280885294000029, 34.430812470000035],
            [72.299586447000081, 34.437812000000065],
            [72.327848024000048, 34.439197342000057],
            [72.360830000000078, 34.435595000000035],
            [72.364423735000059, 34.433369984000024],
            [72.370510646000071, 34.429601348000062],
            [72.372472000000073, 34.428387000000043],
            [72.376096675000042, 34.424953297000059],
            [72.38300600000008, 34.418408000000056],
            [72.398530000000051, 34.411755000000028],
            [72.398946925000075, 34.411657725000055],
            [72.416946356000039, 34.407458170000041],
            [72.421710518000054, 34.406346615000075],
            [72.423479501000031, 34.405932663000044],
            [72.427637000000061, 34.394845000000032],
            [72.430530975000067, 34.388661726000066],
            [72.43373500000007, 34.381816000000072],
            [72.434686105000083, 34.381175511000038],
            [72.434694814000068, 34.381169646000046],
            [72.41583786800004, 34.370869902000038],
            [72.407955000000072, 34.366564246000053],
            [72.385458569000036, 34.348042425000074],
            [72.38181322500003, 34.344933150000031],
            [72.37854202300008, 34.342142996000064],
            [72.376631000000032, 34.340507246000072],
            [72.363602000000071, 34.34161524600006],
            [72.351973844000042, 34.336827278000044],
            [72.351960668000061, 34.33682185300006],
            [72.349541654000063, 34.335825809000028],
            [72.349533287000042, 34.335828118000052],
            [72.349465000000066, 34.335800000000063],
            [72.341516132000038, 34.325031014000047],
            [72.34087100000005, 34.324157000000071],
            [72.335327000000063, 34.324157000000071],
            [72.340594000000067, 34.309188000000063],
            [72.335930733000055, 34.300638794000065],
            [72.333941000000038, 34.296991000000048],
            [72.333406955000044, 34.296366082000077],
            [72.320912000000078, 34.281745000000058],
            [72.320081000000073, 34.271211000000051],
            [72.326708626000084, 34.265370079000036],
            [72.327610375000063, 34.264577363000058],
            [72.329202303000045, 34.263174821000064],
            [72.331723000000068, 34.260954000000027],
            [72.331626233000065, 34.256596346000038],
            [72.331555999000045, 34.253433524000059],
            [72.331479153000032, 34.249972986000046],
            [72.331446000000028, 34.248480000000029],
            [72.320635000000038, 34.248757000000069],
            [72.308161000000041, 34.253747000000033],
            [72.299861940000028, 34.255048387000045],
            [72.294023000000038, 34.255964000000063],
            [72.277113000000043, 34.257073000000048],
            [72.272298287000069, 34.256318818000068],
            [72.265133224000067, 34.255196474000059],
            [72.254105000000038, 34.253469000000052],
            [72.249098810000078, 34.252433387000053],
            [72.238027000000045, 34.250143000000037],
            [72.22869157000008, 34.238331619000064],
            [72.228691542000036, 34.238331583000047],
            [72.224444000000062, 34.232956000000058],
            [72.219942357000036, 34.215700903000027],
            [72.219454000000042, 34.213829000000032],
            [72.216619983000044, 34.211736959000064],
            [72.207812000000047, 34.205235000000073],
            [72.208734839000044, 34.198775129000069],
            [72.209847661000083, 34.190985373000046],
            [72.210242000000051, 34.188225000000045],
            [72.21071610000007, 34.184905828000069],
            [72.210719650000044, 34.184898904000079],
            [72.212247000000048, 34.174188000000072],
            [72.211844072000076, 34.173583587000053],
            [72.202822000000083, 34.160050000000069],
            [72.204155099000047, 34.145108180000079],
            [72.205915946000061, 34.125085450000029],
            [72.205940424000062, 34.124787587000071],
            [72.205942152000034, 34.124787454000057],
            [72.20652009500003, 34.117733930000043],
            [72.207257000000084, 34.108767000000057],
            [72.207534000000066, 34.090749000000073],
            [72.207533949000037, 34.090748000000076],
            [72.191733000000056, 34.090748000000076],
            [72.18599187600006, 34.091347985000027],
            [72.173160721000045, 34.092688925000061],
            [72.173160000000053, 34.092689000000064],
            [72.17262592700007, 34.092545158000064],
            [72.17044947100004, 34.091958974000079],
            [72.159853091000059, 34.089098284000045],
            [72.155696000000034, 34.087976000000026],
            [72.143499000000077, 34.078829000000042],
            [72.143176968000034, 34.078865583000038],
            [72.143131891000053, 34.078870704000053],
            [72.124342775000059, 34.081005023000046],
            [72.122553372000084, 34.081209146000049],
            [72.106908000000033, 34.08298700000006],
            [72.105040431000077, 34.08291618100003],
            [72.105009976000076, 34.08291502600008],
            [72.10116030100005, 34.082769045000077],
            [72.094639077000068, 34.08251996100006],
            [72.092852700000037, 34.082452302000036],
            [72.074194736000038, 34.08174563700004],
            [72.06143264800005, 34.081262277000064],
            [72.049468343000058, 34.080808845000035],
            [72.049468182000055, 34.080808839000042],
            [72.048417000000029, 34.080769000000032],
            [72.03796200000005, 34.093567564000068],
            [72.035524293000037, 34.096551702000056],
            [72.035519912000041, 34.096557066000059],
            [72.034693445000073, 34.097568790000025],
            [72.032339000000036, 34.100451000000078],
            [72.027559716000042, 34.112539925000078],
            [72.026987907000034, 34.113986283000031],
            [72.02683978500005, 34.114364158000058],
            [72.026839770000038, 34.114364193000029],
            [72.026836545000037, 34.114369144000079],
            [72.026716987000043, 34.114671562000069],
            [72.026716964000059, 34.114671618000045],
            [72.026716950000036, 34.114671654000063],
            [72.022914000000071, 34.124291000000028],
            [72.017083054000068, 34.129344346000039],
            [72.017082909000067, 34.129344569000068],
            [72.014598112000044, 34.131498000000079],
            [72.014598002000071, 34.131497998000043],
            [72.006309759000032, 34.131133627000054],
            [72.00630964700008, 34.13113362200005],
            [71.998561690000031, 34.130796219000047],
            [71.998561654000071, 34.130796217000068],
            [71.994017307000036, 34.130594846000065],
            [71.993812573000071, 34.130585774000053],
            [71.993812579000064, 34.13058421900007],
            [71.993811638000068, 34.130584178000049],
            [71.98937200000006, 34.130389000000037],
            [71.988797207000061, 34.130377512000052],
            [71.980816916000038, 34.130218027000069],
            [71.975512000000037, 34.130112000000054],
            [71.975511918000052, 34.130112018000034],
            [71.97551100000004, 34.130112000000054],
            [71.959581903000071, 34.133696334000035],
            [71.953335000000038, 34.135102000000074],
            [71.952861247000044, 34.135281683000073],
            [71.94952201500007, 34.13654817400004],
            [71.945669809000037, 34.13800922300004],
            [71.937257000000045, 34.141200000000026],
            [71.937140000000056, 34.141225000000077],
            [71.937534000000028, 34.140646000000061],
            [71.935287041000038, 34.143943968000031],
            [71.933893495000063, 34.145989340000028],
            [71.932186491000039, 34.148494790000029],
            [71.931247996000081, 34.149872264000066],
            [71.930652141000053, 34.15075025200008],
            [71.930652116000033, 34.15075028800004],
            [71.92937607600004, 34.152619769000069],
            [71.928723705000039, 34.15357728400005],
            [71.928168733000064, 34.154391842000052],
            [71.927542173000063, 34.15531147400003],
            [71.927221402000043, 34.156087138000032],
            [71.92703487600005, 34.15653818100003],
            [71.925905689000047, 34.159268689000044],
            [71.924897040000076, 34.161707724000053],
            [71.924078387000066, 34.163715453000066],
            [71.923150124000074, 34.165931976000024],
            [71.921113167000044, 34.170857583000043],
            [71.920041467000033, 34.173449082000047],
            [71.919250756000054, 34.175361118000069],
            [71.918580459000054, 34.176981976000036],
            [71.917871262000062, 34.178655685000024],
            [71.91681507800007, 34.181250879000061],
            [71.91572145400005, 34.183576122000034],
            [71.913383013000043, 34.188181246000056],
            [71.913165326000069, 34.188610054000037],
            [71.911186582000084, 34.192507859000045],
            [71.90964686600006, 34.195538893000048],
            [71.906770519000077, 34.201217443000075],
            [71.905882685000051, 34.202951747000043],
            [71.905711693000057, 34.203693639000051],
            [71.904947554000046, 34.207009052000046],
            [71.903926371000068, 34.211439716000029],
            [71.90339986500004, 34.213724095000032],
            [71.902528046000043, 34.217506705000062],
            [71.901157211000054, 34.223454427000036],
            [71.901232793000077, 34.223475308000047],
            [71.90122000000008, 34.223531000000037],
            [71.901156000000071, 34.223643000000038],
            [71.901156609000054, 34.223643043000038],
            [71.893460322000067, 34.237109937000071],
            [71.893482106000079, 34.237115839000069],
            [71.893460361000052, 34.237109976000056],
            [71.893458039000052, 34.237114039000062],
            [71.885586567000075, 34.239167678000058],
            [71.880706000000032, 34.240441000000033],
            [71.881538000000035, 34.247371000000044],
            [71.872945000000072, 34.252361000000064],
            [71.849382000000048, 34.252638000000047],
            [71.837185000000034, 34.257905000000051],
            [71.829423000000077, 34.265112000000045],
            [71.828975597000067, 34.268598823000048],
            [71.828878595000049, 34.268554336000079],
            [71.828399643000068, 34.272275492000063],
            [71.827943499000071, 34.275819438000042],
            [71.823582912000063, 34.279355122000027],
            [71.823582876000046, 34.279355151000061],
            [71.820645755000044, 34.281736635000073],
            [71.817683857000077, 34.284138217000077],
            [71.817720486000042, 34.285215826000069],
            [71.817737652000062, 34.285720843000036],
            [71.817736402000037, 34.285764672000028],
            [71.817735970000058, 34.285779827000056],
            [71.817698194000059, 34.287221991000024],
            [71.817633176000072, 34.289474233000078],
            [71.817505830000073, 34.293885468000042],
            [71.817446532000076, 34.295939531000045],
            [71.817421077000063, 34.296821321000039],
            [71.817406423000079, 34.297328931000038],
            [71.817302802000029, 34.30091832200003],
            [71.817223802000058, 34.303654898000048],
            [71.81794327700004, 34.306070436000027],
            [71.81897499400003, 34.309534281000026],
            [71.820057309000049, 34.313158474000033],
            [71.820740279000063, 34.315460979000079],
            [71.82112544000006, 34.316742281000074],
            [71.82193430500007, 34.319430949000036],
            [71.821935246000066, 34.319434091000062],
            [71.821938500000044, 34.31944500000003],
            [71.821949782000047, 34.319456511000055],
            [71.821950081000068, 34.319456819000038],
            [71.824252177000062, 34.321830246000047],
            [71.826666076000038, 34.324297602000058],
            [71.827365678000035, 34.325018039000042],
            [71.828295442000069, 34.325963052000077],
            [71.830107388000044, 34.327815126000075],
            [71.832567217000076, 34.330329430000063],
            [71.834165229000064, 34.331962832000045],
            [71.835040184000036, 34.332857163000028],
            [71.835504718000038, 34.333331985000029],
            [71.835642943000039, 34.33490901600004],
            [71.835757593000039, 34.336217081000029],
            [71.835930785000073, 34.338193068000066],
            [71.836351160000049, 34.342709126000045],
            [71.836352174000069, 34.342720626000073],
            [71.836353000000031, 34.342730000000074],
            [71.836339832000078, 34.34276292100003],
            [71.836337229000037, 34.342769430000033],
            [71.835975715000075, 34.343673258000024],
            [71.834811904000048, 34.346549606000053],
            [71.833713940000052, 34.349290460000077],
            [71.833303012000044, 34.350316261000046],
            [71.831890970000075, 34.353841148000072],
            [71.831607187000031, 34.35731813700005],
            [71.831311735000043, 34.360938092000026],
            [71.831289995000077, 34.361204454000074],
            [71.831278290000057, 34.361347866000074],
            [71.831267020000041, 34.361485946000073],
            [71.831260445000055, 34.361566508000067],
            [71.831253593000042, 34.361650455000074],
            [71.831249626000044, 34.361699064000049],
            [71.831238807000034, 34.361831621000078],
            [71.831228328000066, 34.361960014000033],
            [71.831208040000035, 34.362208592000059],
            [71.831193204000044, 34.362390361000053],
            [71.831181423000032, 34.362534709000045],
            [71.831175198000039, 34.362610980000056],
            [71.83117006100008, 34.362673922000056],
            [71.831164785000055, 34.362738554000032],
            [71.831158367000057, 34.362817195000048],
            [71.831152176000046, 34.362893044000032],
            [71.831145649000064, 34.362973021000073],
            [71.831137062000039, 34.363078232000078],
            [71.831130046000055, 34.363164187000052],
            [71.831124792000082, 34.363228565000043],
            [71.831116198000075, 34.363333859000079],
            [71.831097189000047, 34.363566762000062],
            [71.831057170000065, 34.364057091000063],
            [71.831085942000072, 34.364074964000054],
            [71.831086000000084, 34.364075000000071],
            [71.834328837000044, 34.369063426000025],
            [71.838294000000076, 34.375163000000043],
            [71.857698000000084, 34.38625200000007],
            [71.877657000000056, 34.38819200000006],
            [71.885615272000052, 34.39389527700007],
            [71.894290000000069, 34.400112000000036],
            [71.898113705000071, 34.403862488000073],
            [71.899140471000067, 34.404869594000047],
            [71.899173223000048, 34.404901718000076],
            [71.902918532000058, 34.408575312000039],
            [71.908704000000057, 34.414250000000038],
            [71.910700919000078, 34.417827826000064],
            [71.915357000000029, 34.42617000000007],
            [71.921329791000062, 34.428558969000051],
            [71.931556410000042, 34.432649364000042],
            [71.936148284000069, 34.434486000000049],
            [71.960265284000059, 34.439198000000033],
            [71.960966850000034, 34.450597024000047],
            [71.961374284000044, 34.457217000000071],
            [71.96121222000005, 34.459622683000077],
            [71.961122913000054, 34.460948364000046],
            [71.961047284000074, 34.462071000000037],
            [71.961047369000084, 34.462071003000062],
            [71.959434000000044, 34.486047000000042],
            [71.969157721000045, 34.493158636000032],
            [71.969157757000062, 34.493158662000042],
            [71.969158105000076, 34.493158271000027],
            [71.97800735900006, 34.499630000000025],
            [71.984218745000078, 34.507195009000043],
            [71.98575730400006, 34.50906886100006],
            [71.98758424600004, 34.511293941000076],
            [71.988626887000066, 34.512563801000056],
            [71.990989806000073, 34.515441662000057],
            [71.991249448000076, 34.515757887000063],
            [71.992591643000083, 34.517392581000024],
            [71.999629359000039, 34.525964004000059],
            [72.001586000000032, 34.525997000000075],
            [72.016538000000082, 34.526242000000025],
            [72.016538046000051, 34.526241979000076],
            [72.016539359000035, 34.526242000000025],
            [72.019966055000054, 34.524624837000033],
            [72.038754161000043, 34.515758155000071],
            [72.041210359000047, 34.514599000000032],
            [72.05784335900006, 34.499907000000064],
            [72.06865435900005, 34.493808000000058],
            [72.078964093000081, 34.489012935000062],
            [72.080573565000066, 34.488264369000035],
            [72.080574359000082, 34.488264000000072],
            [72.094989359000067, 34.497689000000037],
            [72.102438842000083, 34.506154822000042],
            [72.102437769000062, 34.505988999000067],
            [72.102486522000049, 34.506152130000032],
            [72.113332039000056, 34.518477309000048],
            [72.125530039000068, 34.529565309000077],
            [72.141416634000052, 34.529563198000062],
            [72.152653093000083, 34.529289356000049],
            [72.152696039000034, 34.529288309000037],
            [72.170160039000052, 34.524575309000056],
            [72.182080039000084, 34.519586309000033],
            [72.19732319600007, 34.515706033000072],
            [72.197326039000075, 34.515705309000055],
            [72.201229233000049, 34.515535433000025],
            [72.203099000000066, 34.510995000000037],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "81",
      properties: { name: "Nowshera", count: 75 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.945669809000037, 34.13800922300004],
            [71.94952201500007, 34.13654817400004],
            [71.952861247000044, 34.135281683000073],
            [71.953335000000038, 34.135102000000074],
            [71.959581903000071, 34.133696334000035],
            [71.97551100000004, 34.130112000000054],
            [71.975511918000052, 34.130112018000034],
            [71.975512000000037, 34.130112000000054],
            [71.980816916000038, 34.130218027000069],
            [71.988797207000061, 34.130377512000052],
            [71.98937200000006, 34.130389000000037],
            [71.993811638000068, 34.130584178000049],
            [71.993812579000064, 34.13058421900007],
            [71.993812573000071, 34.130585774000053],
            [71.994017307000036, 34.130594846000065],
            [71.998561654000071, 34.130796217000068],
            [71.998561690000031, 34.130796219000047],
            [72.00630964700008, 34.13113362200005],
            [72.006309759000032, 34.131133627000054],
            [72.014598002000071, 34.131497998000043],
            [72.014598112000044, 34.131498000000079],
            [72.017082909000067, 34.129344569000068],
            [72.017083054000068, 34.129344346000039],
            [72.022914000000071, 34.124291000000028],
            [72.026716950000036, 34.114671654000063],
            [72.026716964000059, 34.114671618000045],
            [72.026716987000043, 34.114671562000069],
            [72.026836545000037, 34.114369144000079],
            [72.026839770000038, 34.114364193000029],
            [72.02683978500005, 34.114364158000058],
            [72.026987907000034, 34.113986283000031],
            [72.027559716000042, 34.112539925000078],
            [72.032339000000036, 34.100451000000078],
            [72.034693445000073, 34.097568790000025],
            [72.035519912000041, 34.096557066000059],
            [72.035524293000037, 34.096551702000056],
            [72.03796200000005, 34.093567564000068],
            [72.048417000000029, 34.080769000000032],
            [72.049468182000055, 34.080808839000042],
            [72.049468343000058, 34.080808845000035],
            [72.06143264800005, 34.081262277000064],
            [72.074194736000038, 34.08174563700004],
            [72.092852700000037, 34.082452302000036],
            [72.094639077000068, 34.08251996100006],
            [72.10116030100005, 34.082769045000077],
            [72.105009976000076, 34.08291502600008],
            [72.105040431000077, 34.08291618100003],
            [72.106908000000033, 34.08298700000006],
            [72.122553372000084, 34.081209146000049],
            [72.124342775000059, 34.081005023000046],
            [72.143131891000053, 34.078870704000053],
            [72.143176968000034, 34.078865583000038],
            [72.143499000000077, 34.078829000000042],
            [72.155696000000034, 34.087976000000026],
            [72.159853091000059, 34.089098284000045],
            [72.17044947100004, 34.091958974000079],
            [72.17262592700007, 34.092545158000064],
            [72.173160000000053, 34.092689000000064],
            [72.173160721000045, 34.092688925000061],
            [72.18599187600006, 34.091347985000027],
            [72.191733000000056, 34.090748000000076],
            [72.207533949000037, 34.090748000000076],
            [72.211655679000046, 34.090748000000076],
            [72.211661197000069, 34.090748000000076],
            [72.212247000000048, 34.090748000000076],
            [72.213552157000038, 34.089888537000036],
            [72.214017779000073, 34.089581919000068],
            [72.22361200000006, 34.083264000000042],
            [72.225120109000045, 34.083535095000059],
            [72.225188139000068, 34.083547324000051],
            [72.23102347400004, 34.084596275000024],
            [72.248284000000069, 34.087699000000043],
            [72.248179465000078, 34.08732006200006],
            [72.241631000000041, 34.063582000000054],
            [72.235809000000074, 34.038911000000041],
            [72.23747300000008, 34.021169000000043],
            [72.235809000000074, 34.00259600000004],
            [72.234894221000047, 34.001819322000074],
            [72.221117000000049, 33.990122000000042],
            [72.214085004000083, 33.983591944000068],
            [72.214002313000037, 33.983515156000067],
            [72.209475000000054, 33.979311000000052],
            [72.20950020600003, 33.979271888000028],
            [72.209520491000035, 33.979240412000024],
            [72.217514000000051, 33.966837000000055],
            [72.219383365000056, 33.964056901000049],
            [72.227638712000044, 33.951779639000051],
            [72.228325000000041, 33.950759000000062],
            [72.228888937000079, 33.949123543000042],
            [72.233869000000084, 33.934681000000069],
            [72.233861112000056, 33.934627761000058],
            [72.231651000000056, 33.919711000000063],
            [72.231673581000052, 33.919644165000079],
            [72.232575323000049, 33.916975214000047],
            [72.238512000000071, 33.899404000000061],
            [72.238512358000037, 33.899403493000079],
            [72.238551484000084, 33.899347857000066],
            [72.231689453000058, 33.894287109000061],
            [72.232249140000079, 33.892400423000026],
            [72.235027332000072, 33.883035229000029],
            [72.235473597000066, 33.881530883000039],
            [72.235473633000083, 33.881530762000068],
            [72.239328923000073, 33.867786561000059],
            [72.239512000000047, 33.867161000000067],
            [72.239444035000076, 33.86500267100007],
            [72.239431216000071, 33.864595585000075],
            [72.240112305000082, 33.857116699000073],
            [72.240036582000073, 33.855895043000032],
            [72.239938127000073, 33.854306654000027],
            [72.238294835000033, 33.827795079000055],
            [72.237915039000029, 33.810913086000028],
            [72.237597469000036, 33.800909640000043],
            [72.237304688000052, 33.791687048000028],
            [72.237304687000062, 33.791687012000068],
            [72.23691864500006, 33.783911002000025],
            [72.237283963000039, 33.783688375000054],
            [72.236918574000072, 33.783909591000054],
            [72.236890980000055, 33.78392629800004],
            [72.236862000000031, 33.783006000000057],
            [72.24353248500006, 33.770915196000033],
            [72.243532549000065, 33.770915079000076],
            [72.24353261400006, 33.770914961000074],
            [72.247375150000039, 33.763950049000073],
            [72.247464000000036, 33.763789000000031],
            [72.229751277000048, 33.768849959000079],
            [72.220180571000071, 33.771584545000053],
            [72.219633000000044, 33.771741000000077],
            [72.209693000000073, 33.761139000000071],
            [72.208928365000077, 33.761108398000033],
            [72.206273444000033, 33.761002143000042],
            [72.19936462000004, 33.760725640000032],
            [72.199356961000035, 33.760725334000028],
            [72.193127450000077, 33.760476018000077],
            [72.193127000000061, 33.76047600000004],
            [72.183850000000064, 33.763789000000031],
            [72.165297000000066, 33.769753000000037],
            [72.15911587800008, 33.771179440000026],
            [72.152991419000045, 33.772592804000055],
            [72.152340391000052, 33.772743044000038],
            [72.14806800000008, 33.77372900000006],
            [72.127526000000046, 33.775717000000043],
            [72.124722813000062, 33.776481348000061],
            [72.121792582000069, 33.777280337000036],
            [72.112948000000074, 33.779692000000068],
            [72.103671000000077, 33.785656000000074],
            [72.085117000000082, 33.78698200000008],
            [72.071905314000048, 33.783678331000033],
            [72.071864000000062, 33.783668000000034],
            [72.076649357000065, 33.776067927000042],
            [72.076649403000033, 33.776067853000029],
            [72.076649450000048, 33.776067780000062],
            [72.079484038000032, 33.771565906000035],
            [72.083129000000042, 33.765777000000071],
            [72.069214000000045, 33.758488000000057],
            [72.057286000000033, 33.758488000000057],
            [72.047827544000029, 33.755577833000075],
            [72.040057000000047, 33.753187000000025],
            [72.034756375000029, 33.737947076000069],
            [72.034756000000073, 33.737946000000079],
            [72.021503000000052, 33.721380000000067],
            [72.021149248000029, 33.721164688000044],
            [72.021144184000036, 33.721161312000049],
            [72.017573913000035, 33.718781131000071],
            [72.017357752000066, 33.718856803000051],
            [72.017360920000044, 33.718858731000068],
            [72.017315345000043, 33.71887164900005],
            [72.008394000000067, 33.720571000000064],
            [71.990297000000055, 33.725598000000048],
            [71.973206000000062, 33.725598000000048],
            [71.973205000000064, 33.725598000000048],
            [71.969435562000058, 33.726854562000028],
            [71.958125000000052, 33.730625000000032],
            [71.956239143000062, 33.730746618000069],
            [71.95623902300008, 33.730746626000041],
            [71.955079487000035, 33.73082140300005],
            [71.955062167000051, 33.730822520000061],
            [71.942541000000062, 33.731630000000052],
            [71.933363729000064, 33.729159088000074],
            [71.916401000000064, 33.72459200000003],
            [71.894282000000032, 33.709009000000037],
            [71.88679118500005, 33.70683381300006],
            [71.87869900000004, 33.704484000000036],
            [71.871237949000033, 33.701626778000048],
            [71.871208950000039, 33.701615673000049],
            [71.868632926000032, 33.700629180000078],
            [71.855072000000064, 33.695436000000029],
            [71.836473000000069, 33.693425000000047],
            [71.82671960700003, 33.698641910000049],
            [71.814857000000075, 33.704987000000074],
            [71.814659146000054, 33.705193840000049],
            [71.814659000000063, 33.705194000000063],
            [71.818735000000061, 33.708790000000079],
            [71.827350000000081, 33.718729000000053],
            [71.843916000000036, 33.718729000000053],
            [71.856506000000081, 33.745898000000068],
            [71.856506000000081, 33.751918832000058],
            [71.856506000000081, 33.751919282000074],
            [71.856506000000081, 33.751919732000033],
            [71.856506000000081, 33.757825000000025],
            [71.852530000000058, 33.771078000000045],
            [71.841265000000078, 33.782343000000026],
            [71.84115444400004, 33.782347913000024],
            [71.84110481700003, 33.782350117000078],
            [71.811446000000046, 33.783668000000034],
            [71.790242000000035, 33.78499400000004],
            [71.779640000000029, 33.794933000000071],
            [71.77168800000004, 33.805535000000077],
            [71.757772000000045, 33.813487000000066],
            [71.750483000000031, 33.826077000000055],
            [71.74924500000003, 33.847123000000067],
            [71.749245113000029, 33.84712341200003],
            [71.749310000000037, 33.847360000000037],
            [71.758277450000037, 33.854624453000042],
            [71.755333388000054, 33.855721686000038],
            [71.754881290000071, 33.856221373000039],
            [71.754786112000033, 33.858648423000034],
            [71.750943282000037, 33.858814986000027],
            [71.75008667600008, 33.859041034000029],
            [71.749836833000074, 33.859243289000062],
            [71.749575092000043, 33.859552618000066],
            [71.747183734000032, 33.861218241000074],
            [71.746005901000046, 33.86197966900005],
            [71.745803647000059, 33.862372280000045],
            [71.745732263000036, 33.862550739000028],
            [71.745720366000057, 33.862848172000042],
            [71.745720366000057, 33.863324064000039],
            [71.746017798000082, 33.865881985000044],
            [71.745791749000034, 33.86736914800008],
            [71.745494317000066, 33.86788073200006],
            [71.744483046000084, 33.868451803000028],
            [71.742746039000053, 33.869379792000075],
            [71.74216307100005, 33.869974658000046],
            [71.74203916600004, 33.870320865000053],
            [71.741758563000076, 33.87110490200007],
            [71.740521243000046, 33.874567017000061],
            [71.740200016000074, 33.876304024000035],
            [71.740747292000037, 33.878790561000073],
            [71.741187492000051, 33.880075470000065],
            [71.743305213000042, 33.883371023000052],
            [71.744221305000053, 33.886404836000054],
            [71.744233202000032, 33.887808718000031],
            [71.743709721000073, 33.890128692000076],
            [71.743210034000072, 33.891020990000072],
            [71.741799704000073, 33.892911936000075],
            [71.742487155000049, 33.893031255000039],
            [71.743227758000046, 33.893185563000031],
            [71.74367731600006, 33.893296586000076],
            [71.743991597000047, 33.89341965400007],
            [71.744587136000064, 33.893719392000037],
            [71.74512526500007, 33.893986683000037],
            [71.745803962000082, 33.894312047000028],
            [71.746618136000052, 33.894708344000037],
            [71.748920460000079, 33.895796978000078],
            [71.750597325000058, 33.896647422000058],
            [71.751888575000066, 33.897324893000075],
            [71.753232943000057, 33.898023628000033],
            [71.753371921000053, 33.898096236000072],
            [71.753469689000042, 33.89813089900008],
            [71.753634626000064, 33.898157647000062],
            [71.753992491000076, 33.898195828000041],
            [71.755241227000056, 33.898302724000075],
            [71.756159414000081, 33.898404119000077],
            [71.757395244000065, 33.898586657000067],
            [71.758304361000057, 33.898710240000071],
            [71.75841934500005, 33.898733664000076],
            [71.758533570000054, 33.898767341000053],
            [71.758741030000067, 33.898828163000076],
            [71.759013881000044, 33.89891889900008],
            [71.759405178000065, 33.899036100000046],
            [71.759920151000074, 33.89918579600004],
            [71.760572787000058, 33.899375391000035],
            [71.761022702000048, 33.899508856000068],
            [71.761097731000064, 33.899525108000034],
            [71.761168129000055, 33.899526190000074],
            [71.761242666000044, 33.899512360000074],
            [71.761326977000067, 33.89949735700003],
            [71.761407185000053, 33.899472321000076],
            [71.761421718000065, 33.899535671000024],
            [71.761444681000057, 33.899598273000038],
            [71.761477182000078, 33.899634718000073],
            [71.761521113000072, 33.899663263000036],
            [71.761559273000046, 33.899682290000044],
            [71.761622116000069, 33.899696606000077],
            [71.761707569000066, 33.899723558000062],
            [71.761787071000072, 33.899745689000042],
            [71.761871968000037, 33.899780340000063],
            [71.761996397000075, 33.899852589000034],
            [71.762169817000029, 33.899987555000052],
            [71.762951673000032, 33.900637156000073],
            [71.763095324000062, 33.900746595000044],
            [71.763273525000045, 33.900864962000071],
            [71.763451512000074, 33.900974397000027],
            [71.763566367000067, 33.901022157000057],
            [71.763793634000081, 33.901085009000042],
            [71.764405400000044, 33.901211886000056],
            [71.765623643000083, 33.901487565000025],
            [71.766654832000029, 33.901696461000029],
            [71.767627688000061, 33.901914698000041],
            [71.768478488000028, 33.902107410000042],
            [71.76856239600005, 33.902526517000069],
            [71.768738449000068, 33.902641335000055],
            [71.768922157000077, 33.902725534000069],
            [71.76916710100005, 33.902870970000038],
            [71.769442663000063, 33.903085296000029],
            [71.769649335000054, 33.903307276000078],
            [71.769810079000081, 33.903559875000042],
            [71.769917242000076, 33.903866055000037],
            [71.769901933000028, 33.904126308000059],
            [71.769702916000028, 33.904386561000024],
            [71.769500317000052, 33.904472177000059],
            [71.769072014000074, 33.904625142000043],
            [71.76873549000004, 33.904696526000066],
            [71.768307187000062, 33.904829096000071],
            [71.767970664000075, 33.904900480000038],
            [71.767797303000066, 33.905012655000064],
            [71.767715721000059, 33.905196213000067],
            [71.767746315000068, 33.905400167000039],
            [71.76791967500003, 33.905624516000046],
            [71.76824600100008, 33.906124203000047],
            [71.768674304000058, 33.906848239000055],
            [71.768959840000036, 33.907327530000032],
            [71.769224980000047, 33.907602868000026],
            [71.769592096000054, 33.90820453200007],
            [71.769765457000062, 33.908806195000068],
            [71.769653283000082, 33.909254894000071],
            [71.769469724000032, 33.909591417000058],
            [71.769388143000072, 33.909907546000056],
            [71.769428933000029, 33.91021347700007],
            [71.769469724000032, 33.910825338000052],
            [71.769581899000059, 33.911488188000078],
            [71.769847039000069, 33.911865502000069],
            [71.770040795000057, 33.912405980000074],
            [71.770275342000048, 33.912630329000024],
            [71.770509889000039, 33.912864876000071],
            [71.770622063000076, 33.91318100400008],
            [71.77060166800004, 33.913619505000042],
            [71.770326330000046, 33.914139587000079],
            [71.77011217900008, 33.914496506000035],
            [71.770122376000074, 33.914884019000056],
            [71.770464339000057, 33.915785494000033],
            [71.770883124000079, 33.916242351000051],
            [71.771016374000055, 33.91688956400003],
            [71.77107348100003, 33.917650992000063],
            [71.771168659000068, 33.918526633000056],
            [71.771397088000072, 33.919440346000044],
            [71.771911051000075, 33.920525381000061],
            [71.772405979000041, 33.921115487000066],
            [71.77257730000008, 33.921534272000031],
            [71.77257730000008, 33.922238593000031],
            [71.772634407000055, 33.922771592000061],
            [71.772805728000037, 33.923095199000045],
            [71.772958014000039, 33.923380734000034],
            [71.773034157000041, 33.923837590000062],
            [71.772786693000057, 33.92438962500006],
            [71.772501157000079, 33.924675161000039],
            [71.77232983600004, 33.924884553000027],
            [71.772234658000059, 33.925170089000062],
            [71.772101408000083, 33.925474660000077],
            [71.771758766000062, 33.925874409000073],
            [71.771720694000066, 33.926198016000058],
            [71.771720694000066, 33.926559694000048],
            [71.77170165900003, 33.927130764000026],
            [71.771644551000065, 33.927644728000075],
            [71.771758766000062, 33.927911228000028],
            [71.771987194000076, 33.928139656000042],
            [71.772215622000033, 33.928234834000079],
            [71.772520193000048, 33.928368084000056],
            [71.772615372000075, 33.928615548000039],
            [71.772558265000043, 33.928863012000079],
            [71.772291765000034, 33.929186619000063],
            [71.772063337000077, 33.929510225000058],
            [71.771720694000066, 33.929890939000074],
            [71.771416123000051, 33.930423938000047],
            [71.771263838000039, 33.930804652000063],
            [71.771225766000043, 33.931147295000073],
            [71.77132094500007, 33.931337651000035],
            [71.771511302000079, 33.931528008000043],
            [71.771720694000066, 33.931699330000072],
            [71.771930087000044, 33.931984865000061],
            [71.772044301000051, 33.932346543000051],
            [71.772044301000051, 33.932746292000047],
            [71.77196815800005, 33.933088935000058],
            [71.771739730000036, 33.933488684000054],
            [71.771511302000079, 33.933983612000077],
            [71.770957826000028, 33.934777161000056],
            [71.770429586000034, 33.935219741000026],
            [71.770058390000031, 33.935490999000024],
            [71.769730025000058, 33.935705151000036],
            [71.769515873000046, 33.935919302000059],
            [71.769473043000062, 33.936133454000071],
            [71.769501596000055, 33.936547480000058],
            [71.76960153400006, 33.936761631000024],
            [71.769772855000042, 33.936990060000028],
            [71.769915623000031, 33.937147104000076],
            [71.770029837000038, 33.937332702000049],
            [71.77016784500006, 33.937603960000047],
            [71.770215435000068, 33.937746728000036],
            [71.770186881000029, 33.937937085000044],
            [71.77016784500006, 33.938070335000077],
            [71.769939417000046, 33.938203585000053],
            [71.769606293000038, 33.938346352000053],
            [71.769235097000035, 33.938517673000035],
            [71.768978115000039, 33.938650923000068],
            [71.768730651000055, 33.93882224400005],
            [71.768644990000041, 33.939031637000028],
            [71.768740169000068, 33.939174405000074],
            [71.768844865000062, 33.939298137000037],
            [71.768921008000063, 33.939583672000026],
            [71.768949561000056, 33.939897761000054],
            [71.768930526000076, 33.940287992000037],
            [71.768930526000076, 33.940611599000079],
            [71.768892454000081, 33.940982795000025],
            [71.768997151000065, 33.94150627700003],
            [71.769168472000047, 33.941953615000045],
            [71.769520632000081, 33.942838775000041],
            [71.769777614000077, 33.943438399000058],
            [71.770015560000047, 33.94414271900007],
            [71.770272542000043, 33.944980290000046],
            [71.770558077000032, 33.945817860000034],
            [71.770688687000074, 33.946206791000066],
            [71.770867146000057, 33.94646853200004],
            [71.770843352000043, 33.946623197000065],
            [71.770403151000039, 33.946813554000073],
            [71.769772594000074, 33.946718375000046],
            [71.769142037000051, 33.946528018000038],
            [71.768844605000083, 33.946480429000076],
            [71.768594761000031, 33.946968219000041],
            [71.768511480000029, 33.94752739200004],
            [71.768309226000042, 33.94812225700008],
            [71.768047485000068, 33.948788506000028],
            [71.767880923000064, 33.949490447000073],
            [71.767940409000062, 33.949966339000071],
            [71.76795230700003, 33.95075156200005],
            [71.767869026000028, 33.951370221000047],
            [71.767809539000041, 33.951703346000045],
            [71.767785744000037, 33.952036470000053],
            [71.767892820000043, 33.952417184000069],
            [71.767999896000049, 33.952726514000062],
            [71.768118869000034, 33.953119125000057],
            [71.768154561000074, 33.953630709000038],
            [71.768333020000057, 33.955022694000036],
            [71.768606658000067, 33.956450371000074],
            [71.768737529000077, 33.957283182000026],
            [71.768701837000037, 33.957913739000048],
            [71.768689940000058, 33.958377734000067],
            [71.768594761000031, 33.958794140000066],
            [71.768118869000034, 33.95892501000003],
            [71.767666771000052, 33.95896070200007],
            [71.766393760000028, 33.959055880000051],
            [71.764680548000058, 33.959115367000038],
            [71.763693071000034, 33.959329518000061],
            [71.762443854000082, 33.959412800000052],
            [71.761789503000045, 33.959496081000054],
            [71.760052496000071, 33.95988869200005],
            [71.758196517000044, 33.960471660000053],
            [71.756566586000076, 33.960614427000053],
            [71.755650493000076, 33.960757195000042],
            [71.755353061000051, 33.960888065000063],
            [71.75522219000004, 33.96149482800007],
            [71.754734401000064, 33.962280050000061],
            [71.754532147000077, 33.962863018000064],
            [71.754544044000056, 33.963338910000061],
            [71.754592807000051, 33.963867460000074],
            [71.754702450000082, 33.964396324000063],
            [71.754625055000076, 33.964905839000039],
            [71.754547660000071, 33.965325061000044],
            [71.754386421000049, 33.965647539000031],
            [71.754167136000035, 33.965957118000063],
            [71.753760814000032, 33.966221550000057],
            [71.753025564000041, 33.966666570000029],
            [71.752612792000036, 33.967137388000026],
            [71.752361259000054, 33.967685600000038],
            [71.75225161700007, 33.968046776000051],
            [71.752245167000069, 33.968465997000067],
            [71.75225161700007, 33.969078706000062],
            [71.752238717000068, 33.969717212000035],
            [71.75214842400004, 33.970271875000037],
            [71.751257630000055, 33.971983202000047],
            [71.750879296000051, 33.972325844000068],
            [71.750529515000039, 33.972561411000072],
            [71.750108350000062, 33.972739870000055],
            [71.749637217000043, 33.972925468000028],
            [71.749173222000081, 33.973182450000024],
            [71.748694951000061, 33.973589338000068],
            [71.748623567000038, 33.973782074000042],
            [71.753077918000031, 33.975116952000064],
            [71.753337897000051, 33.975232532000064],
            [71.752933782000071, 33.975959939000063],
            [71.745278079000059, 33.974441029000047],
            [71.734895000000051, 33.972381000000041],
            [71.725104550000083, 33.974359981000077],
            [71.719507195000062, 33.975491395000063],
            [71.710040470000081, 33.977404940000042],
            [71.708838000000071, 33.977648000000045],
            [71.707469644000071, 33.97787805400003],
            [71.70750982800007, 33.978090523000049],
            [71.707634188000043, 33.978380003000041],
            [71.707736705000059, 33.97867741400006],
            [71.707743984000047, 33.978981223000062],
            [71.707758614000056, 33.979133128000058],
            [71.707831862000035, 33.979291117000059],
            [71.707751292000069, 33.979529255000045],
            [71.707487357000048, 33.979684158000055],
            [71.707390652000072, 33.979919087000042],
            [71.707258788000047, 33.980134202000045],
            [71.707178362000036, 33.980206838000072],
            [71.707146875000035, 33.980389721000051],
            [71.707202279000057, 33.980906252000068],
            [71.707253533000085, 33.981137152000031],
            [71.707223646000045, 33.981409001000031],
            [71.707093787000076, 33.981674933000079],
            [71.706844696000076, 33.98180248400007],
            [71.70646791300004, 33.981920903000059],
            [71.70575999600004, 33.98242224300003],
            [71.705466140000055, 33.982787616000053],
            [71.704422000000079, 33.983787000000063],
            [71.70419269000007, 33.983954629000038],
            [71.703975000000071, 33.984141000000079],
            [71.703866000000062, 33.98435900000004],
            [71.703757000000053, 33.984975000000077],
            [71.703878000000032, 33.985411000000056],
            [71.704120000000046, 33.986027000000036],
            [71.704514147000054, 33.988264242000071],
            [71.704791000000057, 33.989223000000038],
            [71.70470470600003, 33.990550390000067],
            [71.704762000000073, 33.991350000000068],
            [71.705070979000084, 33.992416695000031],
            [71.705323557000042, 33.992972945000076],
            [71.705506163000052, 33.993398532000072],
            [71.705593158000056, 33.993572673000074],
            [71.705871794000075, 33.99388274100005],
            [71.706152609000071, 33.994181223000055],
            [71.706306631000075, 33.99431869600005],
            [71.707068245000073, 33.995001964000039],
            [71.707530897000083, 33.995953996000026],
            [71.707647125000051, 33.996217505000061],
            [71.707829566000044, 33.996728292000057],
            [71.707949000000042, 33.996953000000076],
            [71.708662000000061, 33.997959000000037],
            [71.709428000000059, 33.998647000000062],
            [71.71056363100007, 33.999204201000055],
            [71.711406730000078, 33.999469864000048],
            [71.712295058000052, 33.999683989000062],
            [71.713436540000032, 33.999705063000079],
            [71.713990023000065, 33.99964466800003],
            [71.714378699000065, 33.999611927000046],
            [71.714866307000079, 33.999506436000047],
            [71.715656337000041, 33.99920304300008],
            [71.716446576000067, 33.998860781000076],
            [71.716743310000084, 33.998802402000024],
            [71.71715400000005, 34.006200000000035],
            [71.716001942000048, 34.007167696000067],
            [71.713671671000043, 34.009125056000073],
            [71.713671635000082, 34.00912508600004],
            [71.710224000000039, 34.012021000000061],
            [71.710224192000055, 34.012021839000056],
            [71.710224000000039, 34.012022000000059],
            [71.711238776000073, 34.016450203000034],
            [71.710607665000055, 34.016460511000048],
            [71.708316354000033, 34.016454710000062],
            [71.707250969000029, 34.016447879000054],
            [71.706604652000067, 34.01647220600006],
            [71.705647505000059, 34.016448940000032],
            [71.704914022000082, 34.016441803000077],
            [71.704812527000058, 34.016450319000057],
            [71.704660200000035, 34.016463100000067],
            [71.703589299000043, 34.016552955000066],
            [71.703601167000045, 34.016662207000024],
            [71.703614670000036, 34.016786524000054],
            [71.703592356000058, 34.017043322000063],
            [71.703490790000046, 34.017326411000056],
            [71.703346013000044, 34.017588514000067],
            [71.703166352000039, 34.017909737000025],
            [71.703081688000054, 34.018082629000048],
            [71.703014332000066, 34.018212546000029],
            [71.702928132000068, 34.018410097000071],
            [71.702817692000053, 34.018587569000033],
            [71.70274123400003, 34.018770018000055],
            [71.702716466000084, 34.019110253000065],
            [71.702761731000066, 34.019406357000037],
            [71.702804516000072, 34.01963513100003],
            [71.702878302000045, 34.019837103000043],
            [71.703106446000049, 34.020269102000043],
            [71.703456000000074, 34.021047000000067],
            [71.703468289000057, 34.02129659600007],
            [71.703506926000045, 34.021660312000051],
            [71.70346816700004, 34.021831464000059],
            [71.703551976000028, 34.02220053700006],
            [71.703680946000077, 34.022632559000044],
            [71.703757303000032, 34.023159605000046],
            [71.70381186000003, 34.023629410000069],
            [71.703873238000028, 34.024110137000037],
            [71.703936967000061, 34.02445143500006],
            [71.704119095000067, 34.025247144000048],
            [71.704286000000081, 34.025746000000026],
            [71.704425000000072, 34.026074000000051],
            [71.704584000000068, 34.026332000000025],
            [71.704761000000076, 34.02655100000004],
            [71.704888000000039, 34.026669000000027],
            [71.705065000000047, 34.026818000000048],
            [71.705350000000067, 34.026985000000025],
            [71.705646000000058, 34.027125000000069],
            [71.705819000000076, 34.027211000000079],
            [71.706052000000057, 34.027345000000025],
            [71.706273000000067, 34.027500000000032],
            [71.706611000000066, 34.027711000000068],
            [71.706945000000076, 34.027912000000072],
            [71.707207000000039, 34.028071000000068],
            [71.707417000000078, 34.028195000000039],
            [71.707575000000077, 34.02828100000005],
            [71.707766000000049, 34.028368000000057],
            [71.708044000000029, 34.028445000000033],
            [71.70835500000004, 34.028548000000058],
            [71.70862900000003, 34.028675000000078],
            [71.708933000000059, 34.028811000000076],
            [71.70922900000005, 34.028994000000068],
            [71.709451000000058, 34.029174000000069],
            [71.709623000000079, 34.029316000000051],
            [71.709798000000035, 34.029491000000064],
            [71.709873000000073, 34.029615000000035],
            [71.709963000000073, 34.029801000000077],
            [71.709960000000081, 34.029920000000061],
            [71.709930000000043, 34.030038000000047],
            [71.709851000000072, 34.03013100000004],
            [71.709676466000076, 34.030256524000038],
            [71.709491188000072, 34.030403054000033],
            [71.70928269500007, 34.030605029000071],
            [71.709160388000043, 34.030790038000077],
            [71.709015209000029, 34.031001691000029],
            [71.708820504000073, 34.031271569000069],
            [71.708699608000074, 34.031534673000067],
            [71.708693762000053, 34.031760667000071],
            [71.708791775000066, 34.032134098000029],
            [71.708940078000069, 34.03257282800007],
            [71.708998860000065, 34.032756677000066],
            [71.709020000000066, 34.032890000000066],
            [71.708983000000046, 34.032995000000028],
            [71.708912000000055, 34.033086000000026],
            [71.708834000000081, 34.033160000000066],
            [71.708635000000072, 34.033241000000032],
            [71.708369000000062, 34.033307000000036],
            [71.70788900000008, 34.033407000000068],
            [71.707551014000046, 34.033459894000032],
            [71.707259651000072, 34.033543107000071],
            [71.707018728000037, 34.033687723000071],
            [71.70701873400003, 34.033690039000078],
            [71.706890333000047, 34.033843008000076],
            [71.706862763000061, 34.034068337000065],
            [71.706846693000045, 34.034340383000028],
            [71.705754262000085, 34.036136351000039],
            [71.705717997000079, 34.03618848800005],
            [71.704571800000053, 34.037836323000079],
            [71.694615209000062, 34.036806108000064],
            [71.694710039000029, 34.036914961000036],
            [71.695040424000069, 34.03725898700003],
            [71.695306137000046, 34.03752477200004],
            [71.694942950000041, 34.038051282000026],
            [71.694771628000069, 34.038336818000062],
            [71.69470500400007, 34.03854621000005],
            [71.694695486000057, 34.038717531000032],
            [71.694628861000069, 34.038984031000041],
            [71.694514647000062, 34.039231495000024],
            [71.694409950000079, 34.039440887000069],
            [71.694295736000072, 34.039678834000028],
            [71.694181522000065, 34.039802566000048],
            [71.694038754000076, 34.039897744000029],
            [71.69400068300007, 34.040002440000023],
            [71.693991165000057, 34.040192797000032],
            [71.693962612000064, 34.040345083000034],
            [71.693838880000044, 34.040706761000024],
            [71.693648523000036, 34.041182653000078],
            [71.693591416000061, 34.04144915300003],
            [71.693486719000077, 34.041791795000051],
            [71.693410577000066, 34.042115402000036],
            [71.693267809000076, 34.04246756200007],
            [71.693096488000037, 34.042886347000035],
            [71.69276336300004, 34.043685846000074],
            [71.692496864000077, 34.044247399000028],
            [71.692109078000044, 34.044798430000071],
            [71.692863137000074, 34.045109063000041],
            [71.693416487000036, 34.045399688000032],
            [71.693864097000073, 34.045638557000075],
            [71.694259276000082, 34.045882935000066],
            [71.694713793000062, 34.04620581100005],
            [71.695384684000032, 34.046751521000033],
            [71.696016463000035, 34.047281564000059],
            [71.696523133000028, 34.047776139000064],
            [71.696785759000079, 34.048032496000076],
            [71.697313136000048, 34.04853266300006],
            [71.697654197000077, 34.048826939000037],
            [71.698040585000058, 34.049194290000059],
            [71.698675236000042, 34.049827894000032],
            [71.698831699000038, 34.049971071000073],
            [71.698411944000043, 34.04999508700007],
            [71.698530151000057, 34.050107662000073],
            [71.699020834000066, 34.050557678000075],
            [71.699717571000065, 34.051219837000076],
            [71.700322893000077, 34.051807261000079],
            [71.70039717800006, 34.051880710000034],
            [71.700429350000036, 34.051912519000041],
            [71.700559094000084, 34.052039534000073],
            [71.701002517000063, 34.052510687000051],
            [71.701420109000082, 34.05298416800008],
            [71.701703523000049, 34.053335649000076],
            [71.702279628000042, 34.054067307000025],
            [71.702780884000049, 34.054773454000042],
            [71.703222382000035, 34.055469567000046],
            [71.703233388000058, 34.055486920000078],
            [71.703685607000068, 34.056277106000039],
            [71.704292201000044, 34.057523892000063],
            [71.704700990000049, 34.058414465000055],
            [71.705291971000065, 34.059674782000059],
            [71.705719800000054, 34.060588317000054],
            [71.706256058000065, 34.061731678000058],
            [71.706327547000058, 34.061884100000043],
            [71.706775937000032, 34.062836406000031],
            [71.706913189000034, 34.06312790700008],
            [71.707383933000074, 34.064148593000027],
            [71.70816545200006, 34.065841624000029],
            [71.708712039000034, 34.067043598000055],
            [71.709439582000073, 34.068595376000076],
            [71.709714347000045, 34.069196500000032],
            [71.710061826000072, 34.069940819000067],
            [71.710241800000063, 34.070326333000025],
            [71.710732015000076, 34.071371343000067],
            [71.711068579000084, 34.072019412000031],
            [71.711584038000069, 34.073021116000064],
            [71.712238160000084, 34.07416764900006],
            [71.712898335000034, 34.075252102000036],
            [71.713555992000067, 34.076276603000053],
            [71.714223480000044, 34.077307361000067],
            [71.714698369000075, 34.078037637000079],
            [71.715118463000067, 34.078690295000058],
            [71.715830869000058, 34.079640200000028],
            [71.716324289000056, 34.080207512000072],
            [71.716912293000064, 34.080820682000024],
            [71.717496392000044, 34.081374550000078],
            [71.718001170000036, 34.081800287000078],
            [71.718216458000029, 34.08197272600006],
            [71.718440283000064, 34.082152004000079],
            [71.719087070000057, 34.082602919000067],
            [71.719699646000038, 34.083013894000032],
            [71.720145012000046, 34.083284392000053],
            [71.720731600000079, 34.083612235000032],
            [71.721161552000069, 34.083824961000062],
            [71.72142438700007, 34.083953806000068],
            [71.721813232000045, 34.084138619000043],
            [71.722106650000057, 34.084270945000071],
            [71.722663698000076, 34.084502100000066],
            [71.72314762600007, 34.084687794000047],
            [71.723730834000037, 34.08488815000004],
            [71.724060507000047, 34.084989127000028],
            [71.724157469000033, 34.085018826000066],
            [71.724560848000067, 34.085141693000025],
            [71.724881190000076, 34.085219302000041],
            [71.725373575000049, 34.085342786000069],
            [71.725761006000084, 34.085425666000049],
            [71.726352919000078, 34.085546014000045],
            [71.727152902000057, 34.085705167000071],
            [71.727602946000047, 34.08579942800003],
            [71.728119382000045, 34.085899030000064],
            [71.728648207000049, 34.085996990000069],
            [71.729256086000078, 34.086119552000071],
            [71.729530795000073, 34.08617123700003],
            [71.729881635000083, 34.086237246000053],
            [71.730496773000084, 34.086357927000051],
            [71.730819605000079, 34.086422004000042],
            [71.731185369000059, 34.086491289000037],
            [71.731567195000082, 34.086567807000051],
            [71.732117587000062, 34.086680757000067],
            [71.732853908000038, 34.086816724000073],
            [71.733701229000076, 34.086981208000054],
            [71.734479514000043, 34.087141091000035],
            [71.734922532000041, 34.087240010000073],
            [71.735276214000066, 34.087319342000058],
            [71.735806921000062, 34.087468254000044],
            [71.736154158000033, 34.087579348000077],
            [71.73656634300005, 34.087731047000034],
            [71.737072648000037, 34.087938820000034],
            [71.73728757400005, 34.088043654000046],
            [71.737506411000084, 34.088160083000048],
            [71.737716241000044, 34.088275194000062],
            [71.738034010000035, 34.088463721000039],
            [71.738290676000076, 34.088631545000055],
            [71.738581731000068, 34.088837456000078],
            [71.738832862000038, 34.089022911000029],
            [71.739044037000042, 34.089191560000074],
            [71.739230005000081, 34.089359850000051],
            [71.73952044300006, 34.089621155000032],
            [71.739689257000066, 34.089801142000056],
            [71.73989990900003, 34.090029885000035],
            [71.740085165000039, 34.090246928000056],
            [71.740226444000029, 34.090427686000055],
            [71.740377145000082, 34.090621961000068],
            [71.74051881500003, 34.090839833000075],
            [71.740629341000044, 34.091021785000066],
            [71.740692007000064, 34.091130417000045],
            [71.740822421000075, 34.091369089000068],
            [71.740912103000028, 34.091542643000025],
            [71.741013830000043, 34.091755240000055],
            [71.741080049000061, 34.091912114000024],
            [71.741174981000029, 34.092152715000054],
            [71.741247501000032, 34.092359653000074],
            [71.741325970000048, 34.092596313000058],
            [71.741380357000082, 34.092789832000051],
            [71.741425586000048, 34.092965897000056],
            [71.741473194000037, 34.093190814000025],
            [71.741501769000081, 34.093337426000062],
            [71.741534337000076, 34.093563505000077],
            [71.741572346000055, 34.093961062000062],
            [71.741609840000081, 34.094376794000027],
            [71.741631085000051, 34.094799088000059],
            [71.741648952000048, 34.095225672000026],
            [71.741715000000056, 34.095219000000043],
            [71.741741000000047, 34.095213000000058],
            [71.741759000000059, 34.095204000000024],
            [71.741770000000031, 34.095194000000049],
            [71.74177700000007, 34.095180000000028],
            [71.74178100000006, 34.095162000000073],
            [71.741782000000057, 34.095140000000072],
            [71.74177700000007, 34.095092000000079],
            [71.741757000000064, 34.09499900000003],
            [71.741750000000081, 34.094965000000059],
            [71.74174800000003, 34.094934000000023],
            [71.741750000000081, 34.09490900000003],
            [71.741761000000054, 34.094874000000061],
            [71.74177700000007, 34.09484400000008],
            [71.741787000000045, 34.094831000000056],
            [71.741799000000071, 34.09481900000003],
            [71.741827000000058, 34.094798000000026],
            [71.741860000000031, 34.094781000000069],
            [71.741900000000044, 34.094768000000045],
            [71.741946000000041, 34.094759000000067],
            [71.741999000000078, 34.094755000000077],
            [71.742045000000076, 34.09475400000008],
            [71.74209600000006, 34.094756000000075],
            [71.74215300000003, 34.094760000000065],
            [71.742216000000042, 34.09476600000005],
            [71.74233600000008, 34.094783000000064],
            [71.74262600000003, 34.094830000000059],
            [71.742763000000082, 34.094847000000073],
            [71.742822000000046, 34.09485200000006],
            [71.742876000000081, 34.094855000000052],
            [71.742925000000071, 34.094855000000052],
            [71.742970000000071, 34.094853000000057],
            [71.743097000000034, 34.094846000000075],
            [71.743131000000062, 34.094842000000028],
            [71.743161000000043, 34.094837000000041],
            [71.743220000000065, 34.094826000000069],
            [71.743236000000081, 34.094820000000027],
            [71.743248000000051, 34.094812000000047],
            [71.74325900000008, 34.094801000000075],
            [71.743270000000052, 34.094788000000051],
            [71.74327900000003, 34.094773000000032],
            [71.743285000000071, 34.09475800000007],
            [71.743289000000061, 34.094739000000061],
            [71.743291000000056, 34.09470900000008],
            [71.743293000000051, 34.094666000000075],
            [71.743291000000056, 34.094652000000053],
            [71.743288000000064, 34.094641000000024],
            [71.743281000000081, 34.094628000000057],
            [71.743270000000052, 34.09461600000003],
            [71.743256000000031, 34.094603000000063],
            [71.743239000000074, 34.094590000000039],
            [71.743212000000028, 34.094575000000077],
            [71.74318100000005, 34.094565000000046],
            [71.743160000000046, 34.094561000000056],
            [71.743138000000044, 34.094560000000058],
            [71.743116000000043, 34.094561000000056],
            [71.743094000000042, 34.094565000000046],
            [71.743062000000066, 34.094576000000075],
            [71.743030000000033, 34.094593000000032],
            [71.743012000000078, 34.094608000000051],
            [71.742968000000076, 34.094651000000056],
            [71.742946000000074, 34.09466800000007],
            [71.742922000000078, 34.094682000000034],
            [71.742897000000085, 34.094694000000061],
            [71.742870000000039, 34.094704000000036],
            [71.742841000000055, 34.09471300000007],
            [71.742811000000074, 34.094719000000055],
            [71.742778000000044, 34.094723000000045],
            [71.742744000000073, 34.094725000000039],
            [71.742684000000054, 34.094725000000039],
            [71.742615000000058, 34.094719000000055],
            [71.742548000000056, 34.094711000000075],
            [71.742392000000052, 34.094688000000076],
            [71.742319000000066, 34.094680000000039],
            [71.742256000000054, 34.094676000000049],
            [71.742200000000082, 34.094677000000047],
            [71.742161000000067, 34.094681000000037],
            [71.742058000000043, 34.094701000000043],
            [71.74201400000004, 34.094706000000031],
            [71.741992000000039, 34.094706000000031],
            [71.741974000000084, 34.094704000000036],
            [71.741959000000065, 34.094699000000048],
            [71.741947000000039, 34.094692000000066],
            [71.741937000000064, 34.094681000000037],
            [71.741929000000084, 34.094665000000077],
            [71.741923000000043, 34.094646000000068],
            [71.74192000000005, 34.094622000000072],
            [71.741919000000053, 34.094579000000067],
            [71.741925000000037, 34.094466000000068],
            [71.74192400000004, 34.094418000000076],
            [71.741918000000055, 34.094326000000024],
            [71.741907000000083, 34.094097000000033],
            [71.741898000000049, 34.093976000000055],
            [71.741891000000066, 34.093936000000042],
            [71.74186667500004, 34.093853295000031],
            [71.741861000000029, 34.093834000000072],
            [71.741852000000051, 34.093791000000067],
            [71.741851000000054, 34.093770000000063],
            [71.741853000000049, 34.093753000000049],
            [71.741859000000034, 34.093739000000028],
            [71.741868000000068, 34.093728000000056],
            [71.74187900000004, 34.093720000000076],
            [71.741894000000059, 34.093713000000037],
            [71.741933000000074, 34.093700000000069],
            [71.741974000000084, 34.093694000000028],
            [71.741991000000041, 34.093694000000028],
            [71.742004000000065, 34.093696000000079],
            [71.742015000000038, 34.093702000000064],
            [71.742023000000074, 34.093712000000039],
            [71.742029000000059, 34.093727000000058],
            [71.742032000000052, 34.093747000000064],
            [71.742031000000054, 34.093792000000064],
            [71.742025298000044, 34.093853295000031],
            [71.742019000000084, 34.09392100000008],
            [71.742020000000082, 34.093952000000058],
            [71.742024000000072, 34.093976000000055],
            [71.742038000000036, 34.09401100000008],
            [71.742047000000071, 34.094026000000042],
            [71.742058000000043, 34.094040000000064],
            [71.742070000000069, 34.094052000000033],
            [71.742083000000036, 34.09406400000006],
            [71.742098000000055, 34.094073000000037],
            [71.742114000000072, 34.094082000000071],
            [71.742151000000035, 34.094096000000036],
            [71.742193000000043, 34.094104000000073],
            [71.742242000000033, 34.094107000000065],
            [71.742297000000065, 34.09410500000007],
            [71.742339000000072, 34.09410100000008],
            [71.742384000000072, 34.094094000000041],
            [71.742488000000037, 34.094074000000035],
            [71.74261800000005, 34.094041000000061],
            [71.742906000000062, 34.093958000000043],
            [71.743029000000035, 34.09392500000007],
            [71.743094000000042, 34.093910000000051],
            [71.743152000000066, 34.093898000000024],
            [71.743205000000046, 34.093890000000044],
            [71.743253000000038, 34.093885000000057],
            [71.743672146000051, 34.093853295000031],
            [71.743914000000075, 34.09383500000007],
            [71.744105000000047, 34.093819000000053],
            [71.744277000000068, 34.093802000000039],
            [71.744538000000034, 34.093773000000056],
            [71.74479800000006, 34.093740000000025],
            [71.745056000000034, 34.093705000000057],
            [71.745313000000067, 34.093666000000042],
            [71.745569000000046, 34.093624000000034],
            [71.745822000000032, 34.093580000000031],
            [71.746074000000078, 34.093532000000039],
            [71.746324000000072, 34.093481000000054],
            [71.746548000000075, 34.093431000000066],
            [71.746796000000074, 34.09337000000005],
            [71.747037000000034, 34.093307000000038],
            [71.747644000000037, 34.093144000000052],
            [71.747969804000036, 34.092997332000039],
            [71.748196921000044, 34.092834959000072],
            [71.748603590000073, 34.092714871000055],
            [71.749405955000043, 34.092508861000056],
            [71.749863490000052, 34.092344700000069],
            [71.750066000000061, 34.092261000000065],
            [71.750099914000032, 34.092244478000055],
            [71.75018300000005, 34.092204000000038],
            [71.750274000000047, 34.092164000000025],
            [71.750369000000035, 34.092125000000067],
            [71.750471000000061, 34.092085000000054],
            [71.75058000000007, 34.092045000000041],
            [71.750962156000071, 34.091843895000068],
            [71.751347344000067, 34.09167971200003],
            [71.753021948000082, 34.095913465000024],
            [71.770923890000063, 34.094884450000052],
            [71.770924429000047, 34.094884419000039],
            [71.775368000000071, 34.094629000000054],
            [71.783139204000065, 34.092662893000067],
            [71.783137213000032, 34.092650372000037],
            [71.78314434300006, 34.092661593000059],
            [71.797961169000075, 34.088912952000044],
            [71.797791071000063, 34.088852046000056],
            [71.798360317000061, 34.088700123000024],
            [71.801944168000034, 34.086777295000047],
            [71.803923239000028, 34.085715472000061],
            [71.805779729000051, 34.084719418000077],
            [71.808082675000037, 34.083483828000055],
            [71.811041223000075, 34.081896491000066],
            [71.813791922000064, 34.080428027000039],
            [71.814854650000029, 34.079824967000036],
            [71.819225104000054, 34.077505629000029],
            [71.819991424000079, 34.077094479000039],
            [71.820270836000077, 34.06215661300007],
            [71.822709622000048, 34.056555725000067],
            [71.822726901000067, 34.056516041000066],
            [71.822766626000032, 34.056424811000056],
            [71.825480440000035, 34.050192294000055],
            [71.826651000000084, 34.04750400000006],
            [71.831245273000036, 34.047840117000078],
            [71.849382000000048, 34.049167000000068],
            [71.849464053000077, 34.049298646000068],
            [71.850706583000033, 34.051289857000029],
            [71.850923366000075, 34.05163721200006],
            [71.852519744000062, 34.05419511000008],
            [71.856056763000083, 34.059862523000049],
            [71.860450409000066, 34.066902522000078],
            [71.863259866000078, 34.07140415300006],
            [71.866080888000056, 34.075924314000076],
            [71.867743584000038, 34.078588474000071],
            [71.870336799000029, 34.082743618000052],
            [71.873973000000035, 34.088569952000057],
            [71.874958785000047, 34.090222156000038],
            [71.876748386000031, 34.09347056200005],
            [71.877836007000042, 34.095444766000071],
            [71.877850246000037, 34.095470612000042],
            [71.879211656000052, 34.097941784000056],
            [71.883284014000083, 34.105333751000046],
            [71.886099953000041, 34.110445123000034],
            [71.888362864000044, 34.114552661000062],
            [71.888541075000035, 34.114876142000071],
            [71.888542941000082, 34.114879529000063],
            [71.888518397000041, 34.114893030000076],
            [71.892626000000064, 34.12235000000004],
            [71.895485652000048, 34.12497125200008],
            [71.902606000000048, 34.131498000000079],
            [71.909986242000059, 34.133035504000077],
            [71.921520814000075, 34.135438468000075],
            [71.922565000000077, 34.13565600000004],
            [71.927114368000048, 34.137172557000042],
            [71.927120933000083, 34.13715906300007],
            [71.92852266400007, 34.137628449000033],
            [71.929055445000074, 34.137806857000044],
            [71.937534000000028, 34.140646000000061],
            [71.937140000000056, 34.141225000000077],
            [71.937257000000045, 34.141200000000026],
            [71.945669809000037, 34.13800922300004],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "82",
      properties: { name: "Shangla", count: 694 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.720970908000083, 35.159494382000048],
            [72.746570908000081, 35.158294382000065],
            [72.755770908000045, 35.143794382000067],
            [72.761070908000079, 35.135094382000034],
            [72.775070908000032, 35.134294382000064],
            [72.777770908000036, 35.131994382000073],
            [72.781388134000053, 35.12602830700007],
            [72.785470908000036, 35.119294382000078],
            [72.790347901000075, 35.112069208000037],
            [72.790870884000071, 35.111294418000057],
            [72.790870908000045, 35.11129438200004],
            [72.793070908000061, 35.107094382000071],
            [72.792975455000033, 35.10703329200004],
            [72.792997000000071, 35.106992000000048],
            [72.787670380000066, 35.103638044000036],
            [72.785570908000068, 35.102294382000025],
            [72.786470908000069, 35.092694382000047],
            [72.788270908000072, 35.073694382000042],
            [72.788670264000075, 35.071403337000049],
            [72.788692586000082, 35.071275279000076],
            [72.790170902000057, 35.062794418000067],
            [72.79017090800005, 35.06279438200005],
            [72.789894692000075, 35.056257274000075],
            [72.789947000000041, 35.055708000000038],
            [72.790078944000072, 35.055543083000032],
            [72.791268433000084, 35.054056341000035],
            [72.791287136000051, 35.054032964000044],
            [72.799927000000082, 35.043234000000041],
            [72.802810957000077, 35.037728377000064],
            [72.80906591300004, 35.025787347000062],
            [72.809075000000064, 35.02577000000008],
            [72.808109839000053, 35.004921557000046],
            [72.807690938000064, 34.995872868000049],
            [72.807689000000039, 34.995831000000067],
            [72.81513196800006, 34.98781592000006],
            [72.818500000000029, 34.984189000000072],
            [72.842047518000072, 34.977963559000045],
            [72.842309313000044, 34.977894346000028],
            [72.842617000000075, 34.977813000000026],
            [72.843229214000075, 34.977894346000028],
            [72.871514102000049, 34.981652582000038],
            [72.871570908000081, 34.981694382000057],
            [72.882138571000041, 34.983064264000063],
            [72.882257000000038, 34.983080000000029],
            [72.882257376000041, 34.983079665000048],
            [72.882370908000041, 34.983094382000047],
            [72.889501247000055, 34.976612256000067],
            [72.890070908000041, 34.976094382000042],
            [72.891604749000066, 34.975344504000077],
            [72.899070908000056, 34.971694382000067],
            [72.90747090800005, 34.967294382000034],
            [72.907463428000028, 34.967288942000039],
            [72.90748300000007, 34.967279000000076],
            [72.904633963000038, 34.965231149000033],
            [72.889870908000034, 34.954494382000064],
            [72.882870908000029, 34.94269438200007],
            [72.881035000000054, 34.924335305000056],
            [72.88087100000007, 34.922649000000035],
            [72.880504600000052, 34.922078985000041],
            [72.875609391000069, 34.914463415000057],
            [72.873387000000037, 34.911006000000043],
            [72.867859125000052, 34.908309665000047],
            [72.862021000000084, 34.905462000000057],
            [72.865070001000049, 34.890492000000052],
            [72.893885330000046, 34.882587458000046],
            [72.896395000000041, 34.881899000000033],
            [72.936313000000041, 34.867484000000047],
            [72.954886000000045, 34.859445000000051],
            [72.958472055000072, 34.85797130800006],
            [72.961716011000078, 34.856638202000056],
            [72.975122000000056, 34.851129000000071],
            [72.997853000000077, 34.846416000000033],
            [73.004506000000049, 34.838932000000057],
            [73.004377390000059, 34.830724556000064],
            [73.004228000000069, 34.821191000000056],
            [73.001369208000028, 34.819863539000039],
            [72.988705000000039, 34.813983000000064],
            [72.98398525600004, 34.813983000000064],
            [72.962370000000078, 34.813983000000064],
            [72.957658000000038, 34.807053000000053],
            [72.95181800000006, 34.806664000000069],
            [72.943857192000053, 34.806133138000064],
            [72.943854524000074, 34.80613296000007],
            [72.943823839000061, 34.806130913000061],
            [72.943823713000029, 34.806130905000032],
            [72.936867000000063, 34.805667000000028],
            [72.926610000000039, 34.787371000000064],
            [72.920982299000059, 34.772788627000068],
            [72.920512001000077, 34.771570000000054],
            [72.904434000000037, 34.765749000000028],
            [72.901610000000062, 34.765975000000026],
            [72.901609982000082, 34.765974883000069],
            [72.893977989000064, 34.766585340000063],
            [72.883643000000063, 34.767412000000036],
            [72.883584048000046, 34.767417360000024],
            [72.881280218000029, 34.767626807000056],
            [72.881279739000036, 34.767626851000045],
            [72.881279501000051, 34.767626872000051],
            [72.866092983000044, 34.769007513000076],
            [72.866092947000084, 34.769007517000034],
            [72.856199393000054, 34.769906964000029],
            [72.853454831000079, 34.769745493000073],
            [72.837540002000082, 34.768809179000073],
            [72.837522688000035, 34.768808160000049],
            [72.837350000000072, 34.768798000000061],
            [72.829338125000049, 34.760906375000047],
            [72.829337824000049, 34.760906735000049],
            [72.822632851000037, 34.75430212200007],
            [72.821825833000048, 34.753507183000067],
            [72.818776000000071, 34.750503000000037],
            [72.811292000000037, 34.729712000000063],
            [72.804767052000045, 34.714092210000047],
            [72.802144000000055, 34.707813000000044],
            [72.798244166000075, 34.68953082400003],
            [72.797709000000054, 34.68702200000007],
            [72.804362000000083, 34.679260000000056],
            [72.804917000000046, 34.666231000000039],
            [72.805194000000029, 34.652648000000056],
            [72.793551000000036, 34.632135000000062],
            [72.788441643000056, 34.62738424500003],
            [72.782013582000047, 34.621407340000076],
            [72.777750000000083, 34.617443000000037],
            [72.783849000000032, 34.604137000000037],
            [72.785910370000067, 34.602566421000063],
            [72.785909822000065, 34.602566077000063],
            [72.787072024000054, 34.601680583000075],
            [72.787072949000049, 34.60168064100003],
            [72.795965654000042, 34.59490519700006],
            [72.796425500000055, 34.594554835000054],
            [72.80131300000005, 34.590831000000037],
            [72.811847000000057, 34.58029700000003],
            [72.819609000000071, 34.563942000000054],
            [72.814847584000063, 34.557551559000046],
            [72.809075000000064, 34.549804000000051],
            [72.805085709000082, 34.547580519000064],
            [72.805085568000038, 34.547580998000058],
            [72.80351270400007, 34.546704342000055],
            [72.803511872000058, 34.546703321000052],
            [72.792165000000068, 34.54037900000003],
            [72.773592000000065, 34.535389000000066],
            [72.753910000000076, 34.531509000000028],
            [72.731457000000034, 34.537053000000071],
            [72.731657746000053, 34.548905734000073],
            [72.731734000000074, 34.553408000000047],
            [72.726190000000031, 34.568654000000038],
            [72.72340704700008, 34.573684745000037],
            [72.718760738000071, 34.582083882000063],
            [72.717868743000054, 34.583696342000053],
            [72.714948023000034, 34.588976125000045],
            [72.711775000000046, 34.594712000000072],
            [72.703378078000071, 34.612987445000044],
            [72.702350000000081, 34.615225000000066],
            [72.701199219000046, 34.620872883000061],
            [72.701084540000068, 34.621435710000071],
            [72.700715582000043, 34.623246508000079],
            [72.699816101000067, 34.627661043000046],
            [72.699300127000072, 34.630194376000077],
            [72.699300000000051, 34.630195000000072],
            [72.70197879400007, 34.63810883900004],
            [72.705399000000057, 34.648213000000055],
            [72.714366895000069, 34.655717166000045],
            [72.718981959000075, 34.659578466000028],
            [72.71898200000004, 34.659578500000066],
            [72.718981958000029, 34.659578967000073],
            [72.718981955000061, 34.659579510000071],
            [72.718981040000074, 34.65974938100004],
            [72.718971765000049, 34.661571477000052],
            [72.718793702000085, 34.661713856000063],
            [72.718151000000034, 34.669004000000029],
            [72.711350956000047, 34.671675133000065],
            [72.711350525000057, 34.67167530200004],
            [72.702627000000064, 34.675102000000038],
            [72.698314523000079, 34.675616999000056],
            [72.686880762000044, 34.676982426000052],
            [72.68405400000006, 34.677320000000066],
            [72.667789521000032, 34.673315936000051],
            [72.666035000000079, 34.672884000000067],
            [72.654670000000067, 34.665677000000073],
            [72.650789000000032, 34.656806000000074],
            [72.650757469000041, 34.655638569000075],
            [72.650553908000063, 34.648101626000027],
            [72.650512000000049, 34.646550000000047],
            [72.640217162000056, 34.645726294000042],
            [72.636651000000029, 34.645441000000062],
            [72.621128000000056, 34.642669000000069],
            [72.606990000000053, 34.645441000000062],
            [72.596179000000063, 34.637679000000048],
            [72.581487000000038, 34.63379800000007],
            [72.57834263500007, 34.634147374000065],
            [72.569013000000041, 34.635184000000038],
            [72.568736000000058, 34.647659000000033],
            [72.565858070000047, 34.652663781000058],
            [72.56585803400003, 34.652663844000074],
            [72.562360000000069, 34.658747000000062],
            [72.553654572000084, 34.665213710000046],
            [72.553654524000081, 34.665213746000063],
            [72.552658000000065, 34.665954000000056],
            [72.547950800000081, 34.667040344000043],
            [72.54795069100004, 34.667040138000061],
            [72.541846000000078, 34.668449000000066],
            [72.541846000000078, 34.668449319000047],
            [72.540010255000084, 34.669693088000031],
            [72.533253000000059, 34.67427131900007],
            [72.530221023000081, 34.678543130000037],
            [72.53022098100007, 34.678543069000057],
            [72.530220912000061, 34.678542968000045],
            [72.527154964000033, 34.682862642000032],
            [72.527154000000053, 34.682864000000052],
            [72.527154964000033, 34.682872674000066],
            [72.528263000000038, 34.692843000000039],
            [72.527986000000055, 34.705318000000034],
            [72.527944001000037, 34.705536000000052],
            [72.531312000000071, 34.705041000000051],
            [72.531770391000066, 34.705536036000069],
            [72.538242000000082, 34.712525000000028],
            [72.549735865000059, 34.726834156000052],
            [72.549735901000076, 34.726834201000031],
            [72.549771045000057, 34.726877953000042],
            [72.551825000000065, 34.729435000000024],
            [72.556783392000057, 34.738111487000026],
            [72.560696000000064, 34.744958000000054],
            [72.568735000000061, 34.746899000000042],
            [72.576497000000074, 34.753829000000053],
            [72.576392275000046, 34.753946143000064],
            [72.57678566800007, 34.75429424400005],
            [72.575848686000029, 34.755354198000077],
            [72.575764117000062, 34.75544790500004],
            [72.574721569000076, 34.756603102000042],
            [72.573284364000074, 34.758224572000074],
            [72.57206222700006, 34.75954358000007],
            [72.571713185000078, 34.764352593000069],
            [72.571702060000064, 34.764578591000031],
            [72.570687272000043, 34.778048842000032],
            [72.571140068000034, 34.77921563700005],
            [72.571140317000072, 34.779216279000025],
            [72.570972572000073, 34.779155962000061],
            [72.575111000000049, 34.789866000000075],
            [72.580431231000034, 34.811874551000074],
            [72.580431293000061, 34.811874809000074],
            [72.581209000000058, 34.81509200000005],
            [72.581443062000062, 34.81737374000005],
            [72.583427000000029, 34.836714000000029],
            [72.582828241000072, 34.838510472000053],
            [72.582827933000033, 34.838511397000048],
            [72.580378000000053, 34.845862000000068],
            [72.583759568000062, 34.853301571000031],
            [72.583760983000047, 34.853304685000069],
            [72.58614274200005, 34.858544639000058],
            [72.591466000000082, 34.87025600000004],
            [72.591454699000053, 34.870719702000031],
            [72.59145467500008, 34.870720703000075],
            [72.591196334000074, 34.881321080000077],
            [72.591196323000077, 34.881321526000079],
            [72.591189000000043, 34.88162200000005],
            [72.591734503000055, 34.888439557000027],
            [72.591867561000072, 34.890102485000057],
            [72.591867753000031, 34.890104885000028],
            [72.592298000000028, 34.895482000000072],
            [72.587585000000047, 34.905184000000077],
            [72.587350160000028, 34.906984409000074],
            [72.58734460200003, 34.907027023000069],
            [72.586701313000049, 34.911958819000063],
            [72.587116352000066, 34.912164403000077],
            [72.586678805000076, 34.915493446000028],
            [72.586188592000042, 34.919117100000051],
            [72.585466068000073, 34.92429504200004],
            [72.589034911000056, 34.931551729000034],
            [72.591144286000031, 34.935856006000051],
            [72.594044841000084, 34.941689064000059],
            [72.594195619000061, 34.941994266000052],
            [72.593814343000076, 34.942001960000027],
            [72.595070000000078, 34.944548000000054],
            [72.600337000000081, 34.96367500000008],
            [72.589890823000076, 34.970706512000049],
            [72.589889999000036, 34.970707067000035],
            [72.587091265000083, 34.972590946000025],
            [72.585922000000039, 34.973378000000025],
            [72.583291845000076, 34.978298599000027],
            [72.583291421000069, 34.978299392000054],
            [72.57732800000008, 34.989456000000075],
            [72.576788126000054, 35.011877358000049],
            [72.576774000000057, 35.01246400000008],
            [72.577042533000053, 35.012573861000078],
            [72.577042568000081, 35.012573876000033],
            [72.588971000000072, 35.017454000000043],
            [72.600891000000047, 35.029374000000075],
            [72.60149176300007, 35.029974764000031],
            [72.601734739000051, 35.029762626000036],
            [72.604614470000058, 35.032618229000036],
            [72.607769959000052, 35.03573824700004],
            [72.615417706000073, 35.043442130000074],
            [72.619503616000031, 35.047549938000031],
            [72.625086868000039, 35.053043549000051],
            [72.628283918000079, 35.056116531000043],
            [72.631900175000055, 35.059739548000039],
            [72.634395890000064, 35.064728710000054],
            [72.634763351000061, 35.065660576000028],
            [72.634823064000045, 35.065645833000076],
            [72.635552400000051, 35.06709658300008],
            [72.637285256000041, 35.070632179000029],
            [72.638232690000052, 35.070536524000033],
            [72.645791286000076, 35.069722743000057],
            [72.654946500000051, 35.068737000000056],
            [72.668252000000052, 35.075667000000067],
            [72.668776391000051, 35.076787230000036],
            [72.668776484000034, 35.076787430000024],
            [72.674351000000058, 35.088696000000027],
            [72.681051657000069, 35.100980538000044],
            [72.682742285000074, 35.103914376000034],
            [72.682668000000035, 35.103941999000028],
            [72.682666661000042, 35.103941380000037],
            [72.682667000000038, 35.103942000000075],
            [72.694329845000084, 35.109894689000043],
            [72.698526076000064, 35.118614579000052],
            [72.703475593000064, 35.128037419000066],
            [72.699549409000042, 35.143603233000078],
            [72.709556000000077, 35.144415000000038],
            [72.709653976000084, 35.14454796800004],
            [72.720375000000047, 35.159098000000029],
            [72.720397953000031, 35.159097275000079],
            [72.720850966000057, 35.159717529000034],
            [72.720970908000083, 35.159494382000048],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "83",
      properties: { name: "Swabi", count: 93 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.485296000000062, 34.340790000000027],
            [72.478920000000073, 34.323326000000066],
            [72.470158980000065, 34.31787093500003],
            [72.464228000000048, 34.314178000000027],
            [72.453140000000076, 34.306693000000053],
            [72.45160402700003, 34.298630413000069],
            [72.450473095000063, 34.292693955000061],
            [72.449813000000063, 34.289229000000034],
            [72.450348895000047, 34.288280886000052],
            [72.457752839000079, 34.27518170500008],
            [72.460624000000053, 34.270102000000065],
            [72.456189000000052, 34.261786000000029],
            [72.451368511000055, 34.257054714000049],
            [72.443761374000076, 34.249588346000053],
            [72.441220000000044, 34.247094000000061],
            [72.427082000000041, 34.227966000000038],
            [72.423756000000083, 34.222699000000034],
            [72.419321119000074, 34.214662028000077],
            [72.419487845000049, 34.209155878000047],
            [72.419487462000063, 34.209155945000077],
            [72.419487645000061, 34.209149939000042],
            [72.419598000000065, 34.205512000000056],
            [72.419716821000065, 34.205123115000049],
            [72.42264700000004, 34.195533000000069],
            [72.413795718000074, 34.189170455000067],
            [72.435349229000053, 34.186678302000075],
            [72.456971228000043, 34.181411302000072],
            [72.470831229000055, 34.179471301000035],
            [72.470847323000044, 34.179459714000075],
            [72.470881000000077, 34.179455000000075],
            [72.477811000000031, 34.174465000000055],
            [72.478182071000049, 34.174279481000042],
            [72.478184640000052, 34.174278197000035],
            [72.488900000000058, 34.168921000000068],
            [72.501651000000038, 34.168921000000068],
            [72.510401010000066, 34.16398470200005],
            [72.512462000000085, 34.162822000000062],
            [72.522719000000052, 34.153952000000061],
            [72.529559908000067, 34.154440699000077],
            [72.529559937000045, 34.154440733000058],
            [72.538242000000082, 34.155061000000046],
            [72.538250117000075, 34.155073174000051],
            [72.538250715000061, 34.155072573000041],
            [72.546559000000059, 34.167535000000044],
            [72.554875000000038, 34.170030000000054],
            [72.57240562800007, 34.17331637500007],
            [72.572482121000064, 34.173330901000043],
            [72.572616423000056, 34.17335640500005],
            [72.572617000000037, 34.173356513000044],
            [72.58536795100008, 34.185553465000055],
            [72.585368000000074, 34.18555351200007],
            [72.58536795100008, 34.185553747000029],
            [72.585367915000063, 34.185553919000029],
            [72.585364970000057, 34.18556810900003],
            [72.585364865000031, 34.185568105000073],
            [72.584734336000054, 34.188606390000075],
            [72.582318000000043, 34.20024600000005],
            [72.576361203000033, 34.208269235000046],
            [72.570027767000056, 34.216799766000065],
            [72.568735000000061, 34.218541000000073],
            [72.588253351000048, 34.233028691000072],
            [72.588253634000068, 34.233028429000058],
            [72.58849697800008, 34.233209057000067],
            [72.60545200200005, 34.245794327000056],
            [72.612664232000043, 34.251147776000039],
            [72.622513964000063, 34.258458973000074],
            [72.622514000000081, 34.258459000000073],
            [72.626384224000049, 34.262446414000067],
            [72.630108126000039, 34.266283075000047],
            [72.631662000000063, 34.267884000000038],
            [72.640086242000052, 34.275255370000025],
            [72.643974894000053, 34.27865801400003],
            [72.644968000000063, 34.27952700000003],
            [72.658274000000063, 34.286734000000024],
            [72.662914701000034, 34.289273245000061],
            [72.672966000000031, 34.294773000000077],
            [72.685008708000055, 34.299719486000072],
            [72.688489000000061, 34.301149000000066],
            [72.688489036000078, 34.301149003000035],
            [72.693026616000054, 34.301585290000048],
            [72.700556951000067, 34.30230933100006],
            [72.700556905000042, 34.30230954700005],
            [72.712810964000084, 34.303487997000047],
            [72.712811000000045, 34.303488000000073],
            [72.714960708000035, 34.303694528000051],
            [72.714987466000082, 34.303696823000053],
            [72.717319000000032, 34.303921000000059],
            [72.71783023100005, 34.303940672000067],
            [72.728376383000068, 34.304346481000039],
            [72.728379993000033, 34.304333349000046],
            [72.738486590000036, 34.30472224500005],
            [72.738940036000031, 34.304752963000055],
            [72.745388365000053, 34.298089870000069],
            [72.747257000000047, 34.296159000000046],
            [72.747662403000049, 34.293269792000046],
            [72.74890726700005, 34.284397962000071],
            [72.749474000000077, 34.280359000000033],
            [72.74947493500008, 34.280358466000052],
            [72.757892071000072, 34.275552513000036],
            [72.760657865000042, 34.273968889000059],
            [72.76111700000007, 34.273706000000061],
            [72.770819000000074, 34.263726000000077],
            [72.771197928000049, 34.261800036000068],
            [72.77414600000003, 34.246816000000024],
            [72.770581375000063, 34.241357340000036],
            [72.765276000000028, 34.233233000000041],
            [72.764558699000077, 34.23055368100006],
            [72.76340997300008, 34.226262870000028],
            [72.760408892000044, 34.215052997000043],
            [72.756577887000049, 34.200743126000077],
            [72.755851000000064, 34.198028000000079],
            [72.756442263000054, 34.197073893000038],
            [72.768048000000078, 34.178346000000033],
            [72.768047810000041, 34.178345777000061],
            [72.766119827000068, 34.176090970000075],
            [72.766117301000065, 34.176088016000051],
            [72.753700443000071, 34.161562521000064],
            [72.753362591000041, 34.161167324000075],
            [72.753356587000042, 34.161160273000064],
            [72.753356367000038, 34.161160014000075],
            [72.753355502000034, 34.161159000000055],
            [72.753356388000043, 34.161127706000059],
            [72.753714433000084, 34.148491026000045],
            [72.753714435000063, 34.148490934000051],
            [72.753716581000049, 34.148415291000049],
            [72.754070453000054, 34.13594334000004],
            [72.754404717000057, 34.12416238600008],
            [72.754464000000041, 34.122073000000057],
            [72.742267000000084, 34.115143000000046],
            [72.738459048000038, 34.109360611000056],
            [72.734782001000042, 34.103777000000036],
            [72.726096613000038, 34.099917118000064],
            [72.719123643000046, 34.096818253000038],
            [72.714823000000081, 34.094907000000035],
            [72.700963000000058, 34.084373000000028],
            [72.695291765000036, 34.076604058000044],
            [72.69525217100005, 34.076549819000036],
            [72.693054346000054, 34.073539051000068],
            [72.688227912000059, 34.066927389000057],
            [72.684888477000072, 34.062352747000034],
            [72.684377798000071, 34.061652927000068],
            [72.684289867000075, 34.061532430000057],
            [72.684189124000056, 34.061394479000057],
            [72.68376733100007, 34.060816905000024],
            [72.683540209000057, 34.060505775000024],
            [72.680727000000047, 34.056652000000042],
            [72.675806311000031, 34.052488445000051],
            [72.666596011000081, 34.044695311000055],
            [72.664941612000064, 34.043295470000032],
            [72.662851811000053, 34.04152722200007],
            [72.660598508000078, 34.039620629000069],
            [72.655740600000058, 34.035510195000029],
            [72.652775940000083, 34.033001700000057],
            [72.651897000000076, 34.03225800000007],
            [72.651020216000063, 34.031597215000033],
            [72.64941495100004, 34.03038723800006],
            [72.648230071000057, 34.029494431000046],
            [72.646019824000064, 34.027828622000072],
            [72.646020487000044, 34.027828432000035],
            [72.641397096000048, 34.024344030000066],
            [72.641396851000081, 34.024344598000027],
            [72.636136953000062, 34.020380493000062],
            [72.635016343000075, 34.019535949000044],
            [72.63277000000005, 34.017843000000028],
            [72.624197553000045, 34.015516104000028],
            [72.613366000000042, 34.012576000000024],
            [72.606942773000071, 34.009648050000067],
            [72.606480834000081, 34.009437481000077],
            [72.594515000000058, 34.003983000000062],
            [72.588259024000081, 34.00066178600008],
            [72.581341647000045, 33.996989442000029],
            [72.572062000000074, 33.99206300000003],
            [72.564631076000069, 33.991478409000024],
            [72.547667268000055, 33.990143871000043],
            [72.547491215000036, 33.990130020000038],
            [72.547491000000036, 33.990130000000079],
            [72.546977000000084, 33.993726000000038],
            [72.545033950000061, 33.996317222000073],
            [72.543002000000058, 33.999027000000069],
            [72.532399320000081, 34.001014940000061],
            [72.532399000000055, 34.001015000000052],
            [72.531108808000056, 34.000584936000052],
            [72.525512695000032, 33.998474121000072],
            [72.525512659000071, 33.998474112000054],
            [72.525549248000061, 33.998731750000047],
            [72.525512659000071, 33.998719553000058],
            [72.522460000000081, 33.997702000000061],
            [72.517726254000081, 33.997702000000061],
            [72.505492471000082, 33.997702000000061],
            [72.505494835000036, 33.997695079000039],
            [72.499994835000052, 33.997295079000025],
            [72.495994835000033, 33.997695079000039],
            [72.470745668000063, 33.995813769000051],
            [72.470494835000068, 33.995795079000061],
            [72.470194871000047, 33.995777435000036],
            [72.46879483500004, 33.995695079000029],
            [72.458294835000061, 33.994695079000053],
            [72.449094835000039, 33.99349507900007],
            [72.44689483500008, 33.993695079000076],
            [72.445158796000044, 33.99636590700004],
            [72.444291897000085, 33.997666061000075],
            [72.440292087000046, 34.00366487000008],
            [72.439687034000031, 34.003473892000045],
            [72.438628292000033, 34.003139564000037],
            [72.436994835000064, 34.002595079000059],
            [72.435294799000076, 34.00205746000006],
            [72.425294948000044, 33.998895115000039],
            [72.425294835000045, 33.998895079000079],
            [72.423983184000065, 33.998514968000052],
            [72.405604638000057, 33.992711435000047],
            [72.405574430000058, 33.992701896000028],
            [72.40252448800004, 33.991738786000042],
            [72.402761723000083, 33.988648056000045],
            [72.403847000000042, 33.974509000000069],
            [72.403601128000048, 33.974487305000025],
            [72.398869792000085, 33.974069821000057],
            [72.398869852000075, 33.974070106000056],
            [72.396890161000044, 33.973895533000075],
            [72.387133199000061, 33.973035146000029],
            [72.381875037000043, 33.972571177000077],
            [72.38130637900008, 33.972521000000029],
            [72.38130637900008, 33.972496593000074],
            [72.38130637900008, 33.970086999000046],
            [72.38130637900008, 33.97007928000005],
            [72.38130637900008, 33.961462040000072],
            [72.38130637900008, 33.957943000000057],
            [72.381294817000082, 33.957940983000071],
            [72.377594592000037, 33.957295486000078],
            [72.366077000000075, 33.955292000000043],
            [72.361194799000032, 33.953308729000071],
            [72.361188607000031, 33.953306213000076],
            [72.361076314000059, 33.953260597000053],
            [72.360715220000031, 33.953113911000059],
            [72.349765504000061, 33.948665864000077],
            [72.344872000000066, 33.946678000000077],
            [72.33880590800004, 33.947057059000031],
            [72.324267408000082, 33.947965544000056],
            [72.323895993000065, 33.947988753000061],
            [72.32386434600005, 33.947990731000061],
            [72.313512109000044, 33.941589730000032],
            [72.311077000000068, 33.940052000000037],
            [72.310561883000048, 33.939791337000031],
            [72.306397214000071, 33.937683901000071],
            [72.306394799000032, 33.937682678000044],
            [72.306305684000051, 33.937637584000072],
            [72.305879796000056, 33.93742207300005],
            [72.281641441000033, 33.92515680200006],
            [72.265384900000072, 33.91693054700005],
            [72.263354016000051, 33.915902864000032],
            [72.256371928000078, 33.912369736000073],
            [72.256078000000059, 33.912221000000045],
            [72.249371046000078, 33.907327284000075],
            [72.238551484000084, 33.899347857000066],
            [72.238512000000071, 33.899404000000061],
            [72.232575323000049, 33.916975214000047],
            [72.231673581000052, 33.919644165000079],
            [72.231651000000056, 33.919711000000063],
            [72.233861112000056, 33.934627761000058],
            [72.233869000000084, 33.934681000000069],
            [72.228888937000079, 33.949123543000042],
            [72.228325000000041, 33.950759000000062],
            [72.227638712000044, 33.951779639000051],
            [72.219383365000056, 33.964056901000049],
            [72.217514000000051, 33.966837000000055],
            [72.209520491000035, 33.979240412000024],
            [72.20950020600003, 33.979271888000028],
            [72.209475000000054, 33.979311000000052],
            [72.214002313000037, 33.983515156000067],
            [72.214085004000083, 33.983591944000068],
            [72.221117000000049, 33.990122000000042],
            [72.234894221000047, 34.001819322000074],
            [72.235809000000074, 34.00259600000004],
            [72.23747300000008, 34.021169000000043],
            [72.235809000000074, 34.038911000000041],
            [72.241631000000041, 34.063582000000054],
            [72.248179465000078, 34.08732006200006],
            [72.248284000000069, 34.087699000000043],
            [72.23102347400004, 34.084596275000024],
            [72.225188139000068, 34.083547324000051],
            [72.225120109000045, 34.083535095000059],
            [72.22361200000006, 34.083264000000042],
            [72.214017779000073, 34.089581919000068],
            [72.213552157000038, 34.089888537000036],
            [72.212247000000048, 34.090748000000076],
            [72.211661197000069, 34.090748000000076],
            [72.211655679000046, 34.090748000000076],
            [72.207533949000037, 34.090748000000076],
            [72.207534000000066, 34.090749000000073],
            [72.207257000000084, 34.108767000000057],
            [72.20652009500003, 34.117733930000043],
            [72.205942152000034, 34.124787454000057],
            [72.205940424000062, 34.124787587000071],
            [72.205915946000061, 34.125085450000029],
            [72.204155099000047, 34.145108180000079],
            [72.202822000000083, 34.160050000000069],
            [72.211844072000076, 34.173583587000053],
            [72.212247000000048, 34.174188000000072],
            [72.210719650000044, 34.184898904000079],
            [72.21071610000007, 34.184905828000069],
            [72.210242000000051, 34.188225000000045],
            [72.209847661000083, 34.190985373000046],
            [72.208734839000044, 34.198775129000069],
            [72.207812000000047, 34.205235000000073],
            [72.216619983000044, 34.211736959000064],
            [72.219454000000042, 34.213829000000032],
            [72.219942357000036, 34.215700903000027],
            [72.224444000000062, 34.232956000000058],
            [72.228691542000036, 34.238331583000047],
            [72.22869157000008, 34.238331619000064],
            [72.238027000000045, 34.250143000000037],
            [72.249098810000078, 34.252433387000053],
            [72.254105000000038, 34.253469000000052],
            [72.265133224000067, 34.255196474000059],
            [72.272298287000069, 34.256318818000068],
            [72.277113000000043, 34.257073000000048],
            [72.294023000000038, 34.255964000000063],
            [72.299861940000028, 34.255048387000045],
            [72.308161000000041, 34.253747000000033],
            [72.320635000000038, 34.248757000000069],
            [72.331446000000028, 34.248480000000029],
            [72.331479153000032, 34.249972986000046],
            [72.331555999000045, 34.253433524000059],
            [72.331626233000065, 34.256596346000038],
            [72.331723000000068, 34.260954000000027],
            [72.329202303000045, 34.263174821000064],
            [72.327610375000063, 34.264577363000058],
            [72.326708626000084, 34.265370079000036],
            [72.320081000000073, 34.271211000000051],
            [72.320912000000078, 34.281745000000058],
            [72.333406955000044, 34.296366082000077],
            [72.333941000000038, 34.296991000000048],
            [72.335930733000055, 34.300638794000065],
            [72.340594000000067, 34.309188000000063],
            [72.335327000000063, 34.324157000000071],
            [72.34087100000005, 34.324157000000071],
            [72.341516132000038, 34.325031014000047],
            [72.349465000000066, 34.335800000000063],
            [72.349533287000042, 34.335828118000052],
            [72.349541654000063, 34.335825809000028],
            [72.351960668000061, 34.33682185300006],
            [72.351973844000042, 34.336827278000044],
            [72.363602000000071, 34.34161524600006],
            [72.376631000000032, 34.340507246000072],
            [72.37854202300008, 34.342142996000064],
            [72.38181322500003, 34.344933150000031],
            [72.385458569000036, 34.348042425000074],
            [72.407955000000072, 34.366564246000053],
            [72.41583786800004, 34.370869902000038],
            [72.434694814000068, 34.381169646000046],
            [72.447318000000053, 34.37266900000003],
            [72.468109000000084, 34.37239100000005],
            [72.469015608000063, 34.371104151000054],
            [72.476702000000046, 34.360194000000035],
            [72.48140552600006, 34.349574124000071],
            [72.485296000000062, 34.340790000000027],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "84",
      properties: { name: "Swat", count: 212 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.518392870000071, 35.900606435000043],
            [72.521625397000037, 35.899657756000067],
            [72.521623335000072, 35.899663391000047],
            [72.523807075000036, 35.899033623000037],
            [72.528246559000081, 35.897710974000063],
            [72.530350031000069, 35.89617585600007],
            [72.532534954000084, 35.894565486000033],
            [72.53547205600006, 35.892430458000035],
            [72.537657511000077, 35.89082250000007],
            [72.541756551000049, 35.887835523000035],
            [72.543827886000031, 35.886309027000038],
            [72.548431341000082, 35.88293862200004],
            [72.551996974000076, 35.880254765000075],
            [72.556917430000055, 35.872680316000071],
            [72.560263258000077, 35.867735090000053],
            [72.563317582000082, 35.863119983000047],
            [72.56361224300008, 35.862644655000054],
            [72.563773408000031, 35.862284647000024],
            [72.563974500000029, 35.861983000000066],
            [72.565286459000049, 35.859031471000037],
            [72.568462243000056, 35.851886876000037],
            [72.568500363000055, 35.851801117000036],
            [72.579607645000067, 35.846488843000031],
            [72.591566685000032, 35.845155211000076],
            [72.59520556800004, 35.844752468000024],
            [72.595527165000078, 35.844719037000061],
            [72.596040104000053, 35.844680949000065],
            [72.600436349000063, 35.844354549000059],
            [72.611426178000045, 35.843554156000039],
            [72.627419418000045, 35.839409696000075],
            [72.633905680000055, 35.836946019000038],
            [72.642475284000056, 35.833776879000027],
            [72.648121864000075, 35.831648664000056],
            [72.654813750000073, 35.828807321000056],
            [72.661038978000079, 35.826156260000062],
            [72.67040069400008, 35.822191960000055],
            [72.674189039000055, 35.821676641000067],
            [72.677251817000069, 35.821259869000073],
            [72.683368444000052, 35.820428002000028],
            [72.691893778000065, 35.822565849000057],
            [72.698026306000031, 35.824103369000056],
            [72.705264773000067, 35.825918737000052],
            [72.712925724000058, 35.827839568000059],
            [72.716369388000032, 35.828701445000036],
            [72.716920255000048, 35.828842112000075],
            [72.717734398000061, 35.829069735000076],
            [72.71859170700003, 35.829309518000059],
            [72.722669753000048, 35.830449234000071],
            [72.725440908000053, 35.831224203000033],
            [72.732376763000048, 35.833163998000032],
            [72.738174264000065, 35.834777461000044],
            [72.744269579000047, 35.836487104000071],
            [72.752782957000079, 35.838867157000038],
            [72.757408019000081, 35.84016106100006],
            [72.760137714000052, 35.840924804000053],
            [72.761153801000034, 35.841208949000077],
            [72.766532822000045, 35.842713006000054],
            [72.767214091000028, 35.842904087000079],
            [72.772264365000069, 35.844315069000061],
            [72.777192552000031, 35.845692983000049],
            [72.783907955000075, 35.84757036600007],
            [72.785718216000078, 35.848077587000034],
            [72.786325305000048, 35.84994765700003],
            [72.787454934000039, 35.853424764000067],
            [72.788979931000028, 35.850154825000061],
            [72.78972260200004, 35.85238286200007],
            [72.798570908000045, 35.833294382000076],
            [72.79826964800003, 35.819797964000031],
            [72.798070908000057, 35.810894382000072],
            [72.798570908000045, 35.775094382000077],
            [72.796298355000033, 35.754052222000041],
            [72.79587090800004, 35.750094382000043],
            [72.795470908000084, 35.72659438200003],
            [72.795470908000084, 35.724294382000039],
            [72.788670908000029, 35.709594382000034],
            [72.782670908000057, 35.696694382000032],
            [72.775470908000045, 35.671694382000055],
            [72.77477090800005, 35.661994382000046],
            [72.773670908000042, 35.648094382000068],
            [72.76967090800008, 35.624894382000036],
            [72.759570908000057, 35.60909438200008],
            [72.758270908000043, 35.605094382000061],
            [72.752070908000064, 35.586294382000062],
            [72.74937090800006, 35.565794382000036],
            [72.747070908000069, 35.548694382000065],
            [72.75017090800003, 35.520294382000031],
            [72.753770908000035, 35.498694382000053],
            [72.752970908000066, 35.48429438200003],
            [72.752470908000078, 35.475094382000066],
            [72.755870908000077, 35.459894382000073],
            [72.749570922000032, 35.444094418000077],
            [72.749570908000067, 35.44409438200006],
            [72.748670908000065, 35.441694382000037],
            [72.730870908000043, 35.428494382000054],
            [72.724870908000071, 35.422694382000032],
            [72.725289786000076, 35.420376590000046],
            [72.727870908000057, 35.406094382000049],
            [72.741025813000078, 35.392540843000063],
            [72.764343435000058, 35.382783543000073],
            [72.768043670000054, 35.38123956000004],
            [72.784967626000082, 35.375901884000029],
            [72.784962896000081, 35.375883703000056],
            [72.785570908000068, 35.375694382000063],
            [72.775470908000045, 35.336894382000025],
            [72.774867823000079, 35.336075909000044],
            [72.774488237000071, 35.334709839000027],
            [72.77292787500005, 35.32909434100003],
            [72.772904118000042, 35.329064083000048],
            [72.772912289000033, 35.329060379000055],
            [72.772046999000054, 35.325952602000029],
            [72.771660652000037, 35.324556616000052],
            [72.770320742000081, 35.319754548000049],
            [72.768753394000044, 35.314072481000039],
            [72.768561525000052, 35.313395097000068],
            [72.765270908000048, 35.300494382000068],
            [72.762270908000062, 35.283594382000047],
            [72.762070908000055, 35.278994382000064],
            [72.762470908000068, 35.274494382000057],
            [72.753070908000041, 35.261494382000024],
            [72.730070908000073, 35.26229438200005],
            [72.719070908000049, 35.259694382000077],
            [72.719442786000059, 35.259041842000045],
            [72.724553237000066, 35.250391918000048],
            [72.725344151000058, 35.249053222000043],
            [72.725168253000049, 35.24548808600008],
            [72.725087571000074, 35.244004780000068],
            [72.725085166000042, 35.244007014000033],
            [72.725069846000054, 35.24368564100007],
            [72.725013083000078, 35.242577666000045],
            [72.724870908000071, 35.239094382000076],
            [72.724833923000062, 35.239080597000054],
            [72.724832172000049, 35.239046424000037],
            [72.70715689900004, 35.232491887000037],
            [72.702991461000067, 35.230939315000057],
            [72.700568647000068, 35.212977716000069],
            [72.69601360300004, 35.205743450000057],
            [72.690071706000083, 35.19627335000007],
            [72.688835829000084, 35.192782530000045],
            [72.687259462000043, 35.188329974000055],
            [72.685378522000065, 35.182971768000073],
            [72.687843060000034, 35.180401355000072],
            [72.688728899000068, 35.179509809000024],
            [72.688583646000041, 35.179489624000041],
            [72.688681816000042, 35.17938872700006],
            [72.692470908000075, 35.175494382000068],
            [72.706270908000079, 35.172694382000032],
            [72.716670908000083, 35.167494382000029],
            [72.720850966000057, 35.159717529000034],
            [72.720397953000031, 35.159097275000079],
            [72.720375000000047, 35.159098000000029],
            [72.709653976000084, 35.14454796800004],
            [72.709556000000077, 35.144415000000038],
            [72.699549409000042, 35.143603233000078],
            [72.703475593000064, 35.128037419000066],
            [72.698526076000064, 35.118614579000052],
            [72.694329845000084, 35.109894689000043],
            [72.682667000000038, 35.103942000000075],
            [72.682666661000042, 35.103941380000037],
            [72.682668000000035, 35.103941999000028],
            [72.682742285000074, 35.103914376000034],
            [72.681051657000069, 35.100980538000044],
            [72.674351000000058, 35.088696000000027],
            [72.668776484000034, 35.076787430000024],
            [72.668776391000051, 35.076787230000036],
            [72.668252000000052, 35.075667000000067],
            [72.654946500000051, 35.068737000000056],
            [72.645791286000076, 35.069722743000057],
            [72.638232690000052, 35.070536524000033],
            [72.637285256000041, 35.070632179000029],
            [72.635552400000051, 35.06709658300008],
            [72.634823064000045, 35.065645833000076],
            [72.634763351000061, 35.065660576000028],
            [72.634395890000064, 35.064728710000054],
            [72.631900175000055, 35.059739548000039],
            [72.628283918000079, 35.056116531000043],
            [72.625086868000039, 35.053043549000051],
            [72.619503616000031, 35.047549938000031],
            [72.615417706000073, 35.043442130000074],
            [72.607769959000052, 35.03573824700004],
            [72.604614470000058, 35.032618229000036],
            [72.601734739000051, 35.029762626000036],
            [72.60149176300007, 35.029974764000031],
            [72.600891000000047, 35.029374000000075],
            [72.588971000000072, 35.017454000000043],
            [72.577042568000081, 35.012573876000033],
            [72.577042533000053, 35.012573861000078],
            [72.576774000000057, 35.01246400000008],
            [72.576788126000054, 35.011877358000049],
            [72.57732800000008, 34.989456000000075],
            [72.583291421000069, 34.978299392000054],
            [72.583291845000076, 34.978298599000027],
            [72.585922000000039, 34.973378000000025],
            [72.587091265000083, 34.972590946000025],
            [72.589889999000036, 34.970707067000035],
            [72.589890823000076, 34.970706512000049],
            [72.600337000000081, 34.96367500000008],
            [72.595070000000078, 34.944548000000054],
            [72.593814343000076, 34.942001960000027],
            [72.594195619000061, 34.941994266000052],
            [72.594044841000084, 34.941689064000059],
            [72.591144286000031, 34.935856006000051],
            [72.589034911000056, 34.931551729000034],
            [72.585466068000073, 34.92429504200004],
            [72.586188592000042, 34.919117100000051],
            [72.586678805000076, 34.915493446000028],
            [72.587116352000066, 34.912164403000077],
            [72.586701313000049, 34.911958819000063],
            [72.58734460200003, 34.907027023000069],
            [72.587350160000028, 34.906984409000074],
            [72.587585000000047, 34.905184000000077],
            [72.592298000000028, 34.895482000000072],
            [72.591867753000031, 34.890104885000028],
            [72.591867561000072, 34.890102485000057],
            [72.591734503000055, 34.888439557000027],
            [72.591189000000043, 34.88162200000005],
            [72.591196323000077, 34.881321526000079],
            [72.591196334000074, 34.881321080000077],
            [72.59145467500008, 34.870720703000075],
            [72.591454699000053, 34.870719702000031],
            [72.591466000000082, 34.87025600000004],
            [72.58614274200005, 34.858544639000058],
            [72.583760983000047, 34.853304685000069],
            [72.583759568000062, 34.853301571000031],
            [72.580378000000053, 34.845862000000068],
            [72.582827933000033, 34.838511397000048],
            [72.582828241000072, 34.838510472000053],
            [72.583427000000029, 34.836714000000029],
            [72.581443062000062, 34.81737374000005],
            [72.581209000000058, 34.81509200000005],
            [72.580431293000061, 34.811874809000074],
            [72.580431231000034, 34.811874551000074],
            [72.575111000000049, 34.789866000000075],
            [72.570972572000073, 34.779155962000061],
            [72.571140317000072, 34.779216279000025],
            [72.571140068000034, 34.77921563700005],
            [72.570687272000043, 34.778048842000032],
            [72.571702060000064, 34.764578591000031],
            [72.571713185000078, 34.764352593000069],
            [72.57206222700006, 34.75954358000007],
            [72.573284364000074, 34.758224572000074],
            [72.574721569000076, 34.756603102000042],
            [72.575764117000062, 34.75544790500004],
            [72.575848686000029, 34.755354198000077],
            [72.57678566800007, 34.75429424400005],
            [72.576392275000046, 34.753946143000064],
            [72.576497000000074, 34.753829000000053],
            [72.568735000000061, 34.746899000000042],
            [72.560696000000064, 34.744958000000054],
            [72.556783392000057, 34.738111487000026],
            [72.551825000000065, 34.729435000000024],
            [72.549771045000057, 34.726877953000042],
            [72.549735901000076, 34.726834201000031],
            [72.549735865000059, 34.726834156000052],
            [72.538242000000082, 34.712525000000028],
            [72.531770391000066, 34.705536036000069],
            [72.531312000000071, 34.705041000000051],
            [72.527944001000037, 34.705536000000052],
            [72.52794375600007, 34.705536036000069],
            [72.512462000000085, 34.707813000000044],
            [72.501230709000083, 34.710552146000055],
            [72.501096000000075, 34.710585000000037],
            [72.499308864000056, 34.710540354000045],
            [72.49679406000007, 34.710477529000059],
            [72.496510967000063, 34.710470457000042],
            [72.495313005000071, 34.710440530000028],
            [72.490008000000046, 34.710308000000055],
            [72.48822033700003, 34.707924019000075],
            [72.485850000000084, 34.704763000000071],
            [72.482871809000073, 34.69316985100005],
            [72.482871729000067, 34.693169858000033],
            [72.478854220000073, 34.677530971000067],
            [72.478854220000073, 34.67753065200003],
            [72.478207620000035, 34.675013643000057],
            [72.478088000000071, 34.674548000000073],
            [72.468767856000056, 34.675013643000057],
            [72.461455000000058, 34.675379000000078],
            [72.448137333000034, 34.677389522000055],
            [72.446763000000033, 34.677597000000048],
            [72.432626000000084, 34.683141000000035],
            [72.424794383000062, 34.685177446000068],
            [72.424515186000065, 34.685250045000032],
            [72.422277545000043, 34.685831897000071],
            [72.418766000000062, 34.68674500000003],
            [72.404905000000042, 34.682587000000069],
            [72.404092355000046, 34.681962976000079],
            [72.389382000000069, 34.670667000000037],
            [72.377739000000076, 34.659856000000048],
            [72.36942300000004, 34.646550000000047],
            [72.361106000000063, 34.634907000000055],
            [72.358905074000063, 34.632192365000037],
            [72.352790500000083, 34.624650000000031],
            [72.347555102000058, 34.623222214000066],
            [72.336470825000049, 34.620199641000056],
            [72.330656848000046, 34.618614226000034],
            [72.319248000000073, 34.615503000000047],
            [72.29994362900004, 34.612084185000072],
            [72.292636000000073, 34.610790000000065],
            [72.280767036000043, 34.601211300000045],
            [72.273322094000036, 34.594493803000034],
            [72.258817000000079, 34.581406000000072],
            [72.241076000000078, 34.574753000000044],
            [72.237398577000079, 34.573479997000049],
            [72.226661000000036, 34.56976300000008],
            [72.221210000000042, 34.570328000000075],
            [72.221209720000047, 34.570327132000045],
            [72.202544000000046, 34.572258000000033],
            [72.192299794000064, 34.574661075000051],
            [72.192092467000066, 34.574709513000073],
            [72.187041727000064, 34.575876390000076],
            [72.183090173000039, 34.576794934000077],
            [72.180002957000056, 34.577512994000074],
            [72.17995118400006, 34.577525036000054],
            [72.178089127000078, 34.577958134000028],
            [72.177912123000056, 34.577934214000038],
            [72.173748621000072, 34.577371563000042],
            [72.173748318000037, 34.577371951000032],
            [72.160155192000047, 34.575561217000029],
            [72.159687208000037, 34.575712821000025],
            [72.159449722000033, 34.575549003000049],
            [72.139894717000061, 34.581832741000028],
            [72.133376069000064, 34.599287458000049],
            [72.119401524000068, 34.616142733000061],
            [72.118325956000035, 34.622142368000027],
            [72.117273620000049, 34.628012417000036],
            [72.125710637000054, 34.64105561100007],
            [72.126464527000053, 34.642210980000073],
            [72.12656961700003, 34.642375241000025],
            [72.133098696000047, 34.654289394000045],
            [72.133146605000036, 34.654286670000033],
            [72.135073452000029, 34.654329073000042],
            [72.136846000000048, 34.657638000000077],
            [72.125350314000059, 34.666688603000068],
            [72.123818000000028, 34.667895000000044],
            [72.121993148000058, 34.668068739000034],
            [72.115984845000071, 34.668640774000039],
            [72.113932355000031, 34.668795862000024],
            [72.105701790000069, 34.669619797000053],
            [72.099836878000076, 34.670206915000051],
            [72.098554356000079, 34.670332650000034],
            [72.09662155500007, 34.67045700400007],
            [72.094593490000079, 34.670587488000024],
            [72.098871104000068, 34.678887785000029],
            [72.104164572000059, 34.68359754800008],
            [72.107586077000065, 34.686634508000054],
            [72.109291133000056, 34.69325526800003],
            [72.110918677000029, 34.699421650000033],
            [72.11273146700006, 34.706478213000025],
            [72.114080329000046, 34.712692838000066],
            [72.115347522000036, 34.718142638000074],
            [72.115606317000072, 34.719255632000056],
            [72.116369325000051, 34.72253708900007],
            [72.11692352700004, 34.724904275000029],
            [72.116924340000082, 34.724904263000042],
            [72.117839164000031, 34.728913945000045],
            [72.123739805000071, 34.741621438000038],
            [72.13755141200005, 34.756825252000056],
            [72.137551502000065, 34.756826023000031],
            [72.138338761000057, 34.757691727000065],
            [72.148229231000073, 34.762581105000038],
            [72.152648917000079, 34.764782483000033],
            [72.153722444000039, 34.765322274000027],
            [72.15557663900006, 34.766225661000078],
            [72.159582146000048, 34.768185276000054],
            [72.161140316000058, 34.768951562000041],
            [72.164100135000069, 34.770435340000063],
            [72.166247887000054, 34.771502378000037],
            [72.169185467000034, 34.772962771000039],
            [72.171935949000044, 34.774319175000073],
            [72.174075314000049, 34.775377795000054],
            [72.174752775000059, 34.775879628000041],
            [72.174743121000063, 34.775913565000053],
            [72.177420551000068, 34.777850697000076],
            [72.179329252000059, 34.779269148000026],
            [72.179720790000033, 34.778915762000054],
            [72.189516000000083, 34.786262000000079],
            [72.190672233000043, 34.793430895000029],
            [72.190658165000059, 34.793465821000041],
            [72.190681091000044, 34.793485813000075],
            [72.192288000000076, 34.803449000000057],
            [72.18667892700006, 34.808777822000025],
            [72.186667098000044, 34.808789060000038],
            [72.18626807000004, 34.808241162000058],
            [72.186281722000047, 34.808228235000058],
            [72.186276907000035, 34.808221573000026],
            [72.180359530000032, 34.813869927000042],
            [72.175195075000033, 34.830421517000048],
            [72.175169473000039, 34.830434398000079],
            [72.166869375000033, 34.834026619000042],
            [72.153205142000047, 34.83905076800005],
            [72.150753201000043, 34.847300578000045],
            [72.144515402000081, 34.853486216000078],
            [72.142753343000038, 34.855434153000033],
            [72.14321368700007, 34.855759319000072],
            [72.143185929000083, 34.855787722000059],
            [72.139341000000059, 34.859722000000033],
            [72.12791377800005, 34.865835247000064],
            [72.127910884000073, 34.865836795000064],
            [72.132241451000084, 34.874874203000047],
            [72.127532725000037, 34.889829624000072],
            [72.127532553000037, 34.889830172000075],
            [72.127532086000031, 34.889843203000055],
            [72.129271523000057, 34.89023409400005],
            [72.134169153000073, 34.891017743000077],
            [72.134352000000035, 34.891047000000071],
            [72.135326933000044, 34.891504861000044],
            [72.146352920000083, 34.896680383000046],
            [72.14663220500006, 34.896811477000028],
            [72.146908698000061, 34.896941261000052],
            [72.147935000000075, 34.89742300000006],
            [72.147983701000044, 34.897448906000079],
            [72.154111847000081, 34.900708658000042],
            [72.154111905000036, 34.900708688000066],
            [72.15424869800006, 34.900781453000036],
            [72.155774535000035, 34.901593093000031],
            [72.157370481000044, 34.902442027000063],
            [72.157372907000081, 34.902443317000063],
            [72.160479117000079, 34.904095608000034],
            [72.160485962000052, 34.904099249000069],
            [72.160963000000038, 34.904353000000071],
            [72.163181000000066, 34.925975000000051],
            [72.16501063100003, 34.94139556600004],
            [72.165121500000055, 34.942330000000027],
            [72.172734695000031, 34.959188579000056],
            [72.17288300000007, 34.959517000000062],
            [72.182031000000052, 34.964230000000043],
            [72.185750950000056, 34.972466417000078],
            [72.185772244000077, 34.972513564000053],
            [72.18591200000003, 34.972823000000062],
            [72.181754000000069, 34.983080000000029],
            [72.179857114000072, 34.996357679000027],
            [72.179814064000084, 34.996659013000055],
            [72.179813689000071, 34.996661648000043],
            [72.184803000000045, 35.003039000000058],
            [72.188774046000049, 35.006329091000055],
            [72.18877651400004, 35.006327980000037],
            [72.18911703200007, 35.006174704000045],
            [72.19077001800008, 35.007534593000059],
            [72.194469350000077, 35.011031599000034],
            [72.194494258000077, 35.011065169000062],
            [72.194602885000052, 35.011211573000026],
            [72.195186604000071, 35.011998289000076],
            [72.196825048000051, 35.014329881000037],
            [72.200745220000044, 35.019809606000024],
            [72.202089942000043, 35.021670993000043],
            [72.203093283000044, 35.022932083000057],
            [72.204381700000056, 35.02450036700003],
            [72.205273371000033, 35.025640338000073],
            [72.206436289000067, 35.027089836000073],
            [72.207429544000036, 35.028332056000067],
            [72.208574596000062, 35.029753807000077],
            [72.210063266000077, 35.031607282000039],
            [72.210824371000058, 35.032550961000027],
            [72.21174839400004, 35.033701931000053],
            [72.211961730000041, 35.03397566700005],
            [72.213077507000037, 35.035364684000058],
            [72.213092964000055, 35.035383926000065],
            [72.213077507000037, 35.035682789000077],
            [72.212991008000074, 35.037355256000069],
            [72.212762306000059, 35.041633852000075],
            [72.212438931000065, 35.048057036000046],
            [72.212327004000031, 35.050176319000059],
            [72.212302344000079, 35.05065290400006],
            [72.212257444000045, 35.051491038000052],
            [72.21220280700004, 35.051980419000074],
            [72.212085966000075, 35.053044928000077],
            [72.21142922000007, 35.058943634000059],
            [72.211420926000073, 35.058973388000027],
            [72.207605064000063, 35.072662552000054],
            [72.204950033000046, 35.082171201000051],
            [72.204767347000029, 35.08282643900003],
            [72.204675416000043, 35.083041182000045],
            [72.204182348000074, 35.084176943000045],
            [72.203630113000031, 35.085450396000056],
            [72.20337457800008, 35.086042719000034],
            [72.203261288000078, 35.086301913000057],
            [72.202960333000078, 35.086995804000026],
            [72.202364377000038, 35.088369757000066],
            [72.201976019000028, 35.089266518000045],
            [72.198679999000035, 35.096864463000031],
            [72.19852116800007, 35.097242300000062],
            [72.19784622800006, 35.098796626000023],
            [72.197513996000055, 35.099553157000059],
            [72.197381208000081, 35.099867525000036],
            [72.196307747000048, 35.102339605000054],
            [72.196196718000067, 35.102594329000055],
            [72.195945395000081, 35.103174069000033],
            [72.195613471000058, 35.103938459000062],
            [72.195613715000036, 35.103939171000036],
            [72.195610877000036, 35.103945718000034],
            [72.196313454000062, 35.105999634000057],
            [72.196314622000045, 35.106003049000037],
            [72.196424169000068, 35.106323299000053],
            [72.198923405000073, 35.11362676400006],
            [72.205871000000059, 35.121406000000036],
            [72.211310023000067, 35.12199963300003],
            [72.211389611000072, 35.122008320000077],
            [72.211389647000033, 35.122008323000045],
            [72.221117000000049, 35.123070000000041],
            [72.235809000000074, 35.125564000000054],
            [72.247452000000067, 35.130277000000035],
            [72.257986000000074, 35.14302900000007],
            [72.262657425000043, 35.152745293000066],
            [72.262664468000082, 35.152759942000046],
            [72.262664485000073, 35.152759978000063],
            [72.262664597000082, 35.152759942000046],
            [72.262722977000067, 35.152741315000071],
            [72.262731900000063, 35.152759942000046],
            [72.263391376000072, 35.154136658000027],
            [72.264936551000062, 35.157361416000072],
            [72.265671710000049, 35.160221127000057],
            [72.265671342000076, 35.160221276000073],
            [72.266362453000056, 35.162900773000047],
            [72.26840941100005, 35.170869030000063],
            [72.269363338000062, 35.174571081000067],
            [72.277922244000081, 35.180647523000061],
            [72.289877132000072, 35.186752769000066],
            [72.295740232000071, 35.186806644000058],
            [72.296584869000071, 35.186814199000025],
            [72.303571461000047, 35.186876308000024],
            [72.31198960100005, 35.186955128000079],
            [72.312571940000055, 35.186960543000055],
            [72.312918611000043, 35.186963684000034],
            [72.313702743000078, 35.186971093000068],
            [72.31343057600003, 35.18710180000005],
            [72.313705000000084, 35.187105000000031],
            [72.315812873000084, 35.187947975000043],
            [72.323407000000032, 35.190985000000069],
            [72.325591837000047, 35.193684230000031],
            [72.327040164000039, 35.195473548000052],
            [72.328119500000071, 35.196807000000035],
            [72.322298000000046, 35.206232000000057],
            [72.315922000000057, 35.217043000000047],
            [72.312310704000083, 35.221277035000071],
            [72.311932936000062, 35.221719946000064],
            [72.307883000000061, 35.226468000000068],
            [72.308160500000042, 35.238942000000065],
            [72.29820415100005, 35.245069055000044],
            [72.297349690000033, 35.245594883000024],
            [72.297349584000074, 35.245594948000075],
            [72.298181000000056, 35.251694000000043],
            [72.284321000000034, 35.264168000000041],
            [72.286538000000064, 35.271930000000054],
            [72.292360000000031, 35.283018000000027],
            [72.301507500000071, 35.283018000000027],
            [72.303508438000051, 35.289020487000073],
            [72.304557000000045, 35.292166000000066],
            [72.312319000000059, 35.305472000000066],
            [72.310101000000031, 35.320164000000034],
            [72.315645000000075, 35.335688000000061],
            [72.324793000000057, 35.346776000000034],
            [72.347524000000078, 35.347053000000074],
            [72.348047983000072, 35.347227681000049],
            [72.349012908000077, 35.347549377000064],
            [72.357503500000064, 35.35038000000003],
            [72.36434481200007, 35.364882919000024],
            [72.364345429000082, 35.36488422900004],
            [72.364434000000074, 35.365072000000055],
            [72.360275500000057, 35.377269000000069],
            [72.353900000000067, 35.386971000000074],
            [72.351682000000039, 35.40582100000006],
            [72.359721000000036, 35.42162200000007],
            [72.370532000000082, 35.442967000000067],
            [72.371074282000052, 35.452048879000074],
            [72.371641000000068, 35.46154000000007],
            [72.364584973000035, 35.472982225000067],
            [72.364569265000057, 35.473007697000071],
            [72.361384000000044, 35.478173000000027],
            [72.356395000000077, 35.499240000000043],
            [72.341565943000035, 35.514973244000032],
            [72.340919256000063, 35.515659363000054],
            [72.340914847000079, 35.515664040000047],
            [72.34090823400004, 35.515671056000031],
            [72.333746036000036, 35.523269962000029],
            [72.333664000000056, 35.523357000000033],
            [72.315819178000083, 35.526202395000041],
            [72.314536000000032, 35.526407000000063],
            [72.306497000000036, 35.538049000000058],
            [72.290974000000062, 35.548306000000025],
            [72.272678000000042, 35.559117000000072],
            [72.258540000000039, 35.575195000000065],
            [72.251056000000062, 35.589333000000067],
            [72.227770000000078, 35.597926000000029],
            [72.214187000000038, 35.608183000000054],
            [72.210029000000077, 35.622321000000056],
            [72.212247000000048, 35.632577000000026],
            [72.214140998000062, 35.632705428000065],
            [72.22860200000008, 35.633686000000068],
            [72.241076000000078, 35.645329000000061],
            [72.249115000000074, 35.658358000000078],
            [72.259649000000081, 35.673881000000051],
            [72.25957384000003, 35.674099677000072],
            [72.259524991000035, 35.674241801000051],
            [72.256600000000049, 35.68275200000005],
            [72.264639000000045, 35.698553000000061],
            [72.274064000000067, 35.721006000000045],
            [72.287370000000067, 35.746232000000077],
            [72.292082500000049, 35.765082000000064],
            [72.291311740000083, 35.771245627000042],
            [72.291311558000075, 35.771247086000074],
            [72.291291587000046, 35.771406783000032],
            [72.291291478000062, 35.771407658000044],
            [72.292465986000082, 35.772026234000066],
            [72.292989125000076, 35.772301755000058],
            [72.294022097000038, 35.772845788000041],
            [72.30788205500005, 35.778667820000067],
            [72.307997266000029, 35.778407075000075],
            [72.313148910000052, 35.766747916000043],
            [72.323682802000064, 35.762866989000031],
            [72.325059134000071, 35.763211095000031],
            [72.325135304000071, 35.763230139000029],
            [72.333662257000071, 35.765362022000033],
            [72.351126119000071, 35.763421117000064],
            [72.369421516000045, 35.765916189000052],
            [72.369635817000074, 35.766079317000049],
            [72.387994518000085, 35.780054194000058],
            [72.403518536000036, 35.793637187000058],
            [72.41155769900007, 35.817477083000028],
            [72.416824779000081, 35.830228033000026],
            [72.417634918000033, 35.830785053000056],
            [72.425695274000077, 35.836327039000025],
            [72.434842694000054, 35.834386094000024],
            [72.452307064000081, 35.833278184000051],
            [72.45267394900003, 35.833738741000047],
            [72.455833777000066, 35.837705325000059],
            [72.465335624000033, 35.849633149000056],
            [72.465614142000049, 35.849766072000079],
            [72.477532593000035, 35.855454172000066],
            [72.487789564000082, 35.859889195000051],
            [72.50026357400003, 35.870423192000032],
            [72.500291664000031, 35.870750745000066],
            [72.500301293000064, 35.870863029000077],
            [72.500493128000073, 35.873100025000042],
            [72.500699166000061, 35.875502639000047],
            [72.50095555200005, 35.878492367000035],
            [72.50111077400004, 35.880761248000056],
            [72.50114377500006, 35.88214769700005],
            [72.501180584000053, 35.883694143000071],
            [72.501224453000077, 35.885537209000063],
            [72.501272622000045, 35.887560939000025],
            [72.501344348000032, 35.890574314000048],
            [72.501372762000074, 35.891768070000069],
            [72.501518070000031, 35.891930464000041],
            [72.510558247000063, 35.902033620000054],
            [72.510675174000085, 35.902164295000034],
            [72.512130066000054, 35.902444436000053],
            [72.514625071000069, 35.901724859000069],
            [72.518392870000071, 35.900606435000043],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "85",
      properties: { name: "Tank", count: 538 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.55416094800006, 32.465338118000034],
            [70.557067000000075, 32.46368700000005],
            [70.580922000000044, 32.46832500000005],
            [70.59795500000007, 32.471622000000025],
            [70.597955124000066, 32.471621933000051],
            [70.607029375000081, 32.466668503000051],
            [70.609562000000039, 32.465286000000049],
            [70.606523116000062, 32.451914986000077],
            [70.605859069000076, 32.448993198000039],
            [70.604766319000078, 32.444185124000057],
            [70.603824405000069, 32.440040724000028],
            [70.603081168000074, 32.436770498000044],
            [70.602410587000065, 32.433819959000061],
            [70.601889935000031, 32.43152910200007],
            [70.601339000000053, 32.42910500000005],
            [70.604642184000056, 32.426535874000024],
            [70.608815589000073, 32.423289913000076],
            [70.611319384000069, 32.421342529000071],
            [70.616001654000058, 32.417700787000058],
            [70.643428510000035, 32.396368925000047],
            [70.645744000000036, 32.394568000000049],
            [70.678538269000057, 32.391925049000065],
            [70.69143100000008, 32.390886000000023],
            [70.704439000000036, 32.371373000000062],
            [70.702000000000055, 32.357552000000055],
            [70.708505000000059, 32.345357000000035],
            [70.675171000000034, 32.325845000000072],
            [70.662976000000072, 32.292512000000045],
            [70.662976000000072, 32.280490564000047],
            [70.662976000000072, 32.270561000000043],
            [70.659491254000045, 32.268638382000063],
            [70.65947793600003, 32.268631034000066],
            [70.657416505000072, 32.267493693000063],
            [70.639399000000083, 32.25755300000003],
            [70.637355318000061, 32.256252475000053],
            [70.610192470000072, 32.238967026000068],
            [70.603627000000074, 32.234789000000035],
            [70.597474736000038, 32.228828758000077],
            [70.583699323000076, 32.215483297000048],
            [70.580545292000068, 32.212427708000064],
            [70.577611000000047, 32.209585000000061],
            [70.576868055000034, 32.206836102000068],
            [70.573205237000082, 32.193283676000078],
            [70.570894581000061, 32.184734250000076],
            [70.569481000000053, 32.179504000000065],
            [70.569481000000053, 32.176320080000039],
            [70.569481000000053, 32.13722800000005],
            [70.562664936000033, 32.127314280000064],
            [70.554636909000067, 32.115637803000027],
            [70.553485284000033, 32.113962805000028],
            [70.55159400000008, 32.111212000000023],
            [70.526391000000046, 32.097391000000073],
            [70.518968936000078, 32.097827592000044],
            [70.510523429000045, 32.098324387000048],
            [70.50304019400005, 32.098764577000054],
            [70.49579525200005, 32.099190750000048],
            [70.484928000000082, 32.099830000000054],
            [70.482800390000079, 32.099382082000034],
            [70.462680214000045, 32.095146256000078],
            [70.454034000000036, 32.093326000000047],
            [70.429418353000074, 32.084638125000026],
            [70.426392000000078, 32.083570000000066],
            [70.419551584000033, 32.083780470000079],
            [70.410167153000032, 32.084069216000046],
            [70.388828445000058, 32.084725780000042],
            [70.373546000000033, 32.085196000000053],
            [70.372420033000083, 32.085224871000037],
            [70.311987735000059, 32.086774417000072],
            [70.310132000000067, 32.086822000000041],
            [70.285943367000073, 32.081445922000057],
            [70.280864000000065, 32.080317000000036],
            [70.25972500000006, 32.088448000000028],
            [70.25658185900005, 32.08264512900007],
            [70.251612746000035, 32.073471147000078],
            [70.238587000000052, 32.049423000000047],
            [70.234750161000079, 32.043667742000025],
            [70.228671127000041, 32.034549191000053],
            [70.227205000000083, 32.032350000000065],
            [70.180864000000042, 31.999017000000038],
            [70.167648000000042, 31.992409000000066],
            [70.164783000000057, 31.994537000000037],
            [70.146892000000037, 32.009115000000065],
            [70.132314000000065, 32.015079000000071],
            [70.123037000000068, 32.026343000000054],
            [70.119061000000045, 32.046885000000032],
            [70.110446000000081, 32.068090000000041],
            [70.109784000000047, 32.083993000000078],
            [70.099844000000076, 32.094596000000024],
            [70.106471000000056, 32.103872000000024],
            [70.10514500000005, 32.121764000000042],
            [70.098402842000041, 32.132298860000049],
            [70.098376524000059, 32.132339983000065],
            [70.09456289600007, 32.138298912000039],
            [70.094543000000044, 32.138330000000053],
            [70.094361499000058, 32.138299757000027],
            [70.086591000000055, 32.137005000000045],
            [70.074001000000067, 32.150257000000067],
            [70.074000912000031, 32.150257337000028],
            [70.070025000000044, 32.165498000000071],
            [70.070128114000056, 32.167870995000044],
            [70.070688000000075, 32.180739000000074],
            [70.077314000000058, 32.192004000000054],
            [70.079302000000041, 32.20260600000006],
            [70.095206000000076, 32.213208000000066],
            [70.095675239000059, 32.213417928000069],
            [70.120386000000053, 32.224473000000046],
            [70.142916000000071, 32.231100000000026],
            [70.153311041000052, 32.238896172000068],
            [70.153428174000055, 32.238984020000032],
            [70.16677100000004, 32.248991000000046],
            [70.183337000000051, 32.274171000000024],
            [70.202554000000077, 32.305315000000064],
            [70.20918000000006, 32.313930000000028],
            [70.220445000000041, 32.30664100000007],
            [70.252252000000055, 32.308629000000053],
            [70.276769000000058, 32.302665000000047],
            [70.298637000000042, 32.300014000000033],
            [70.303684445000044, 32.303979811000033],
            [70.307914000000039, 32.307303000000047],
            [70.308518855000045, 32.308096922000061],
            [70.308519037000053, 32.308097162000024],
            [70.318516000000045, 32.321219000000042],
            [70.319178000000079, 32.334472000000062],
            [70.338395000000048, 32.356339000000048],
            [70.344552829000065, 32.359948717000066],
            [70.357612000000074, 32.367604000000028],
            [70.374840000000063, 32.380857000000049],
            [70.395382000000041, 32.394109000000071],
            [70.405322000000069, 32.410675000000026],
            [70.405553817000055, 32.410959514000069],
            [70.40555579200003, 32.41096193900006],
            [70.405557767000062, 32.410964364000051],
            [70.405579963000037, 32.410991605000049],
            [70.419900000000041, 32.428567000000044],
            [70.42984000000007, 32.441820000000064],
            [70.430162089000078, 32.442740295000078],
            [70.43447800000007, 32.45507200000003],
            [70.43447800000007, 32.471638000000041],
            [70.45700800000003, 32.496156000000042],
            [70.485501000000056, 32.513385000000028],
            [70.499417000000051, 32.495494000000065],
            [70.506438943000035, 32.491737939000075],
            [70.527910000000077, 32.480253000000062],
            [70.55416094800006, 32.465338118000034],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "86",
      properties: { name: "Torghar", count: 995 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.901609982000082, 34.765974883000069],
            [72.897781000000066, 34.741632000000038],
            [72.899493181000082, 34.730013788000065],
            [72.901662000000044, 34.715297000000078],
            [72.89902029600006, 34.699700420000056],
            [72.897160378000081, 34.688719495000043],
            [72.896277993000069, 34.68350990700003],
            [72.895840000000078, 34.680924000000061],
            [72.901662036000062, 34.672482074000072],
            [72.906929000000048, 34.664845000000071],
            [72.901734905000069, 34.636173296000038],
            [72.899999000000037, 34.626591000000076],
            [72.904989000000057, 34.614948000000027],
            [72.906097000000045, 34.598316000000068],
            [72.916915756000037, 34.580955164000045],
            [72.918017000000077, 34.579188000000045],
            [72.921886986000061, 34.575748288000057],
            [72.930492000000072, 34.568100000000072],
            [72.939997194000057, 34.566773898000065],
            [72.940259075000029, 34.566737362000026],
            [72.940259000000083, 34.566737000000046],
            [72.94024885400006, 34.566676126000061],
            [72.940027650000047, 34.565348902000039],
            [72.939997194000057, 34.565166161000036],
            [72.93999715800004, 34.565165945000047],
            [72.938881000000038, 34.558469000000059],
            [72.938393591000079, 34.556701812000028],
            [72.934253726000065, 34.54139007200007],
            [72.932082466000054, 34.534249206000027],
            [72.926673041000072, 34.526388994000058],
            [72.926374752000072, 34.526208077000035],
            [72.920106254000075, 34.522406131000025],
            [72.920106192000048, 34.522406112000056],
            [72.917495663000068, 34.520821604000048],
            [72.915748827000073, 34.519732386000044],
            [72.909551002000057, 34.515867811000078],
            [72.902907257000038, 34.50980260800003],
            [72.901026048000062, 34.50681518600004],
            [72.89456203900005, 34.496555340000043],
            [72.89465630400008, 34.48703703700005],
            [72.89285626000003, 34.479854598000031],
            [72.889204535000033, 34.471449975000041],
            [72.884980569000049, 34.469032647000063],
            [72.884879346000048, 34.468974718000027],
            [72.884857698000076, 34.468962329000078],
            [72.880736076000062, 34.466603570000075],
            [72.874722745000042, 34.457617341000059],
            [72.872168472000055, 34.452099700000076],
            [72.872167827000055, 34.452098308000075],
            [72.871117836000053, 34.449829951000027],
            [72.871117231000085, 34.449828645000025],
            [72.869790822000084, 34.449242649000041],
            [72.869786747000035, 34.449240849000034],
            [72.868699036000066, 34.448760306000054],
            [72.865710370000045, 34.447439935000034],
            [72.865710319000073, 34.447439913000039],
            [72.857900693000033, 34.451054477000071],
            [72.850105346000078, 34.456462688000045],
            [72.850103252000054, 34.456464142000073],
            [72.850091760000055, 34.456472114000064],
            [72.845571065000058, 34.45504223100005],
            [72.842414948000055, 34.454045534000045],
            [72.842414203000033, 34.454045299000029],
            [72.842060859000071, 34.453333560000033],
            [72.842060723000031, 34.453333293000071],
            [72.837507000000073, 34.444498000000067],
            [72.833908000000065, 34.43790000000007],
            [72.830309000000057, 34.433101000000079],
            [72.824910000000045, 34.431901000000039],
            [72.817712000000029, 34.427102000000048],
            [72.817112000000066, 34.421704000000034],
            [72.821911000000057, 34.409707000000026],
            [72.827263423000034, 34.392696062000027],
            [72.82756802800003, 34.391670574000045],
            [72.835284568000077, 34.371874515000059],
            [72.839436873000068, 34.367667188000041],
            [72.843104441000037, 34.365438620000077],
            [72.843104513000071, 34.365438576000031],
            [72.84833546200008, 34.362260032000052],
            [72.852146092000055, 34.359112146000029],
            [72.854894154000078, 34.356871500000068],
            [72.859698865000041, 34.350283895000075],
            [72.86134011200005, 34.34569741100006],
            [72.862701259000062, 34.341893990000074],
            [72.868178313000044, 34.330968460000065],
            [72.868699036000066, 34.329929732000039],
            [72.868707680000057, 34.329912489000037],
            [72.868707377000078, 34.329911809000066],
            [72.868699036000066, 34.329893080000033],
            [72.866304712000044, 34.324516765000055],
            [72.866088316000059, 34.324480564000055],
            [72.865105949000053, 34.32431596400005],
            [72.865033650000044, 34.324303850000035],
            [72.865023429000075, 34.32431596400005],
            [72.865023350000058, 34.324316058000079],
            [72.864139314000056, 34.325363791000029],
            [72.861924348000059, 34.327988903000062],
            [72.858140000000049, 34.332474000000047],
            [72.852197663000084, 34.33601849300004],
            [72.848145295000052, 34.338435654000079],
            [72.842339000000038, 34.341899000000069],
            [72.838246538000078, 34.348037693000038],
            [72.83624100000003, 34.351046000000053],
            [72.82543000000004, 34.354927000000032],
            [72.817114000000061, 34.353819000000044],
            [72.811105180000084, 34.351261712000053],
            [72.804085000000043, 34.34827400000006],
            [72.800075216000039, 34.350031391000073],
            [72.792719000000034, 34.353264000000024],
            [72.780799000000059, 34.359917000000053],
            [72.772892541000033, 34.362731032000056],
            [72.764444000000083, 34.365738000000078],
            [72.758755970000038, 34.361561966000068],
            [72.752801000000034, 34.35714500000006],
            [72.744796938000036, 34.354858125000078],
            [72.744763000000034, 34.354927000000032],
            [72.743099000000029, 34.369897000000037],
            [72.743820572000061, 34.383359687000052],
            [72.743931000000032, 34.385420000000067],
            [72.750861000000043, 34.39650800000004],
            [72.75126427400005, 34.402957472000026],
            [72.751693000000046, 34.40981400000004],
            [72.750137088000031, 34.416702978000046],
            [72.749999135000053, 34.417313783000054],
            [72.749958168000035, 34.417495166000037],
            [72.749752000000058, 34.418408000000056],
            [72.734506000000067, 34.433100000000024],
            [72.717042000000049, 34.453890000000058],
            [72.719044066000038, 34.456815883000047],
            [72.720646000000045, 34.459157000000062],
            [72.730070558000079, 34.460265948000028],
            [72.741990000000044, 34.459157000000062],
            [72.74199017400008, 34.459157077000043],
            [72.741991000000041, 34.459157000000062],
            [72.751416000000063, 34.463316000000077],
            [72.760286000000065, 34.471077000000037],
            [72.770543000000032, 34.474958000000072],
            [72.766108000000031, 34.483552000000032],
            [72.751825712000084, 34.499847534000025],
            [72.751825347000079, 34.49984756300006],
            [72.751825316000065, 34.499847599000077],
            [72.744485000000054, 34.508223000000044],
            [72.73315264200005, 34.515988114000038],
            [72.729516000000046, 34.518480000000068],
            [72.731457000000034, 34.537053000000071],
            [72.753910000000076, 34.531509000000028],
            [72.773592000000065, 34.535389000000066],
            [72.792165000000068, 34.54037900000003],
            [72.803511872000058, 34.546703321000052],
            [72.80351270400007, 34.546704342000055],
            [72.805085568000038, 34.547580998000058],
            [72.805085709000082, 34.547580519000064],
            [72.809075000000064, 34.549804000000051],
            [72.814847584000063, 34.557551559000046],
            [72.819609000000071, 34.563942000000054],
            [72.811847000000057, 34.58029700000003],
            [72.80131300000005, 34.590831000000037],
            [72.796425500000055, 34.594554835000054],
            [72.795965654000042, 34.59490519700006],
            [72.787072949000049, 34.60168064100003],
            [72.787072024000054, 34.601680583000075],
            [72.785909822000065, 34.602566077000063],
            [72.785910370000067, 34.602566421000063],
            [72.783849000000032, 34.604137000000037],
            [72.777750000000083, 34.617443000000037],
            [72.782013582000047, 34.621407340000076],
            [72.788441643000056, 34.62738424500003],
            [72.793551000000036, 34.632135000000062],
            [72.805194000000029, 34.652648000000056],
            [72.804917000000046, 34.666231000000039],
            [72.804362000000083, 34.679260000000056],
            [72.797709000000054, 34.68702200000007],
            [72.798244166000075, 34.68953082400003],
            [72.802144000000055, 34.707813000000044],
            [72.804767052000045, 34.714092210000047],
            [72.811292000000037, 34.729712000000063],
            [72.818776000000071, 34.750503000000037],
            [72.821825833000048, 34.753507183000067],
            [72.822632851000037, 34.75430212200007],
            [72.829337824000049, 34.760906735000049],
            [72.829338125000049, 34.760906375000047],
            [72.837350000000072, 34.768798000000061],
            [72.837522688000035, 34.768808160000049],
            [72.837540002000082, 34.768809179000073],
            [72.853454831000079, 34.769745493000073],
            [72.856199393000054, 34.769906964000029],
            [72.866092947000084, 34.769007517000034],
            [72.866092983000044, 34.769007513000076],
            [72.881279501000051, 34.767626872000051],
            [72.881279739000036, 34.767626851000045],
            [72.881280218000029, 34.767626807000056],
            [72.883584048000046, 34.767417360000024],
            [72.883643000000063, 34.767412000000036],
            [72.893977989000064, 34.766585340000063],
            [72.901609982000082, 34.765974883000069],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "87",
      properties: { name: "Upper Dir", count: 1282 },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [71.979523791000076, 34.955507825000041],
              [71.979204668000079, 34.955500464000067],
              [71.979175224000073, 34.955507825000041],
              [71.979523791000076, 34.955507825000041],
            ],
          ],
          [
            [
              [72.147121047000041, 35.78708385200008],
              [72.150270047000049, 35.786738852000042],
              [72.150270431000081, 35.786744987000077],
              [72.167251057000044, 35.784832357000028],
              [72.173528794000049, 35.769547938000073],
              [72.188417437000055, 35.757984605000047],
              [72.198396330000037, 35.759647681000047],
              [72.216138396000076, 35.77655764900004],
              [72.231107175000034, 35.775726801000076],
              [72.24219494700003, 35.771567957000059],
              [72.262708661000033, 35.771291155000029],
              [72.28349829900003, 35.767133403000059],
              [72.291311740000083, 35.771245627000042],
              [72.292082500000049, 35.765082000000064],
              [72.287370000000067, 35.746232000000077],
              [72.274064000000067, 35.721006000000045],
              [72.264639000000045, 35.698553000000061],
              [72.256600000000049, 35.68275200000005],
              [72.259524991000035, 35.674241801000051],
              [72.25957384000003, 35.674099677000072],
              [72.259649000000081, 35.673881000000051],
              [72.249115000000074, 35.658358000000078],
              [72.241076000000078, 35.645329000000061],
              [72.22860200000008, 35.633686000000068],
              [72.214140998000062, 35.632705428000065],
              [72.212247000000048, 35.632577000000026],
              [72.210029000000077, 35.622321000000056],
              [72.214187000000038, 35.608183000000054],
              [72.227770000000078, 35.597926000000029],
              [72.251056000000062, 35.589333000000067],
              [72.258540000000039, 35.575195000000065],
              [72.272678000000042, 35.559117000000072],
              [72.290974000000062, 35.548306000000025],
              [72.306497000000036, 35.538049000000058],
              [72.314536000000032, 35.526407000000063],
              [72.315819178000083, 35.526202395000041],
              [72.333664000000056, 35.523357000000033],
              [72.333746036000036, 35.523269962000029],
              [72.34090823400004, 35.515671056000031],
              [72.340914847000079, 35.515664040000047],
              [72.340919256000063, 35.515659363000054],
              [72.341565943000035, 35.514973244000032],
              [72.356395000000077, 35.499240000000043],
              [72.361384000000044, 35.478173000000027],
              [72.364569265000057, 35.473007697000071],
              [72.364584973000035, 35.472982225000067],
              [72.371641000000068, 35.46154000000007],
              [72.371074282000052, 35.452048879000074],
              [72.370532000000082, 35.442967000000067],
              [72.359721000000036, 35.42162200000007],
              [72.351682000000039, 35.40582100000006],
              [72.353900000000067, 35.386971000000074],
              [72.360275500000057, 35.377269000000069],
              [72.364434000000074, 35.365072000000055],
              [72.364345429000082, 35.36488422900004],
              [72.36434481200007, 35.364882919000024],
              [72.357503500000064, 35.35038000000003],
              [72.349012908000077, 35.347549377000064],
              [72.348047983000072, 35.347227681000049],
              [72.347524000000078, 35.347053000000074],
              [72.324793000000057, 35.346776000000034],
              [72.315645000000075, 35.335688000000061],
              [72.310101000000031, 35.320164000000034],
              [72.312319000000059, 35.305472000000066],
              [72.304557000000045, 35.292166000000066],
              [72.303508438000051, 35.289020487000073],
              [72.301507500000071, 35.283018000000027],
              [72.292360000000031, 35.283018000000027],
              [72.286538000000064, 35.271930000000054],
              [72.284321000000034, 35.264168000000041],
              [72.298181000000056, 35.251694000000043],
              [72.297349584000074, 35.245594948000075],
              [72.297349690000033, 35.245594883000024],
              [72.29820415100005, 35.245069055000044],
              [72.308160500000042, 35.238942000000065],
              [72.307883000000061, 35.226468000000068],
              [72.311932936000062, 35.221719946000064],
              [72.312310704000083, 35.221277035000071],
              [72.315922000000057, 35.217043000000047],
              [72.322298000000046, 35.206232000000057],
              [72.328119500000071, 35.196807000000035],
              [72.327040164000039, 35.195473548000052],
              [72.325591837000047, 35.193684230000031],
              [72.323407000000032, 35.190985000000069],
              [72.315812873000084, 35.187947975000043],
              [72.313705000000084, 35.187105000000031],
              [72.31343057600003, 35.18710180000005],
              [72.313702743000078, 35.186971093000068],
              [72.312918611000043, 35.186963684000034],
              [72.312571940000055, 35.186960543000055],
              [72.31198960100005, 35.186955128000079],
              [72.303571461000047, 35.186876308000024],
              [72.296584869000071, 35.186814199000025],
              [72.295740232000071, 35.186806644000058],
              [72.289877132000072, 35.186752769000066],
              [72.277922244000081, 35.180647523000061],
              [72.269363338000062, 35.174571081000067],
              [72.26840941100005, 35.170869030000063],
              [72.266362453000056, 35.162900773000047],
              [72.265671342000076, 35.160221276000073],
              [72.265671710000049, 35.160221127000057],
              [72.264936551000062, 35.157361416000072],
              [72.263391376000072, 35.154136658000027],
              [72.262731900000063, 35.152759942000046],
              [72.262722977000067, 35.152741315000071],
              [72.262664597000082, 35.152759942000046],
              [72.262664485000073, 35.152759978000063],
              [72.262664468000082, 35.152759942000046],
              [72.262657425000043, 35.152745293000066],
              [72.257986000000074, 35.14302900000007],
              [72.247452000000067, 35.130277000000035],
              [72.235809000000074, 35.125564000000054],
              [72.221117000000049, 35.123070000000041],
              [72.211389647000033, 35.122008323000045],
              [72.211389611000072, 35.122008320000077],
              [72.211310023000067, 35.12199963300003],
              [72.205871000000059, 35.121406000000036],
              [72.198923405000073, 35.11362676400006],
              [72.196424169000068, 35.106323299000053],
              [72.196314622000045, 35.106003049000037],
              [72.196313454000062, 35.105999634000057],
              [72.195610877000036, 35.103945718000034],
              [72.195613715000036, 35.103939171000036],
              [72.195613471000058, 35.103938459000062],
              [72.195945395000081, 35.103174069000033],
              [72.196196718000067, 35.102594329000055],
              [72.196307747000048, 35.102339605000054],
              [72.197381208000081, 35.099867525000036],
              [72.197513996000055, 35.099553157000059],
              [72.19784622800006, 35.098796626000023],
              [72.19852116800007, 35.097242300000062],
              [72.198679999000035, 35.096864463000031],
              [72.201976019000028, 35.089266518000045],
              [72.202364377000038, 35.088369757000066],
              [72.202960333000078, 35.086995804000026],
              [72.203261288000078, 35.086301913000057],
              [72.20337457800008, 35.086042719000034],
              [72.203630113000031, 35.085450396000056],
              [72.204182348000074, 35.084176943000045],
              [72.204675416000043, 35.083041182000045],
              [72.204767347000029, 35.08282643900003],
              [72.204950033000046, 35.082171201000051],
              [72.207605064000063, 35.072662552000054],
              [72.211420926000073, 35.058973388000027],
              [72.21142922000007, 35.058943634000059],
              [72.212085966000075, 35.053044928000077],
              [72.21220280700004, 35.051980419000074],
              [72.212257444000045, 35.051491038000052],
              [72.212302344000079, 35.05065290400006],
              [72.212327004000031, 35.050176319000059],
              [72.212438931000065, 35.048057036000046],
              [72.212762306000059, 35.041633852000075],
              [72.212991008000074, 35.037355256000069],
              [72.213077507000037, 35.035682789000077],
              [72.213092964000055, 35.035383926000065],
              [72.213077507000037, 35.035364684000058],
              [72.211961730000041, 35.03397566700005],
              [72.21174839400004, 35.033701931000053],
              [72.210824371000058, 35.032550961000027],
              [72.210063266000077, 35.031607282000039],
              [72.208574596000062, 35.029753807000077],
              [72.207429544000036, 35.028332056000067],
              [72.206436289000067, 35.027089836000073],
              [72.205273371000033, 35.025640338000073],
              [72.204381700000056, 35.02450036700003],
              [72.203093283000044, 35.022932083000057],
              [72.202089942000043, 35.021670993000043],
              [72.200745220000044, 35.019809606000024],
              [72.196825048000051, 35.014329881000037],
              [72.195186604000071, 35.011998289000076],
              [72.194602885000052, 35.011211573000026],
              [72.194494258000077, 35.011065169000062],
              [72.194469350000077, 35.011031599000034],
              [72.19077001800008, 35.007534593000059],
              [72.18911703200007, 35.006174704000045],
              [72.18877651400004, 35.006327980000037],
              [72.188774046000049, 35.006329091000055],
              [72.184803000000045, 35.003039000000058],
              [72.179813689000071, 34.996661648000043],
              [72.179814064000084, 34.996659013000055],
              [72.179857114000072, 34.996357679000027],
              [72.181754000000069, 34.983080000000029],
              [72.18591200000003, 34.972823000000062],
              [72.185772244000077, 34.972513564000053],
              [72.185750950000056, 34.972466417000078],
              [72.182031000000052, 34.964230000000043],
              [72.17288300000007, 34.959517000000062],
              [72.172734695000031, 34.959188579000056],
              [72.165121500000055, 34.942330000000027],
              [72.16501063100003, 34.94139556600004],
              [72.163181000000066, 34.925975000000051],
              [72.160963000000038, 34.904353000000071],
              [72.160485962000052, 34.904099249000069],
              [72.160479117000079, 34.904095608000034],
              [72.157372907000081, 34.902443317000063],
              [72.157370481000044, 34.902442027000063],
              [72.155774535000035, 34.901593093000031],
              [72.15424869800006, 34.900781453000036],
              [72.154111905000036, 34.900708688000066],
              [72.154111847000081, 34.900708658000042],
              [72.147983701000044, 34.897448906000079],
              [72.147935000000075, 34.89742300000006],
              [72.146908698000061, 34.896941261000052],
              [72.14663220500006, 34.896811477000028],
              [72.146352920000083, 34.896680383000046],
              [72.135326933000044, 34.891504861000044],
              [72.134352000000035, 34.891047000000071],
              [72.134169153000073, 34.891017743000077],
              [72.129271523000057, 34.89023409400005],
              [72.127532086000031, 34.889843203000055],
              [72.119989424000039, 34.891269745000045],
              [72.119988301000035, 34.891269270000066],
              [72.119985788000065, 34.891269745000045],
              [72.114970224000047, 34.889148123000041],
              [72.114966984000034, 34.889146753000034],
              [72.106831832000069, 34.88680679600003],
              [72.106831745000079, 34.886806771000067],
              [72.106824095000036, 34.886806851000074],
              [72.097575081000059, 34.886903124000071],
              [72.097571340000059, 34.886903163000056],
              [72.097410085000035, 34.886904841000046],
              [72.088399613000036, 34.890243558000066],
              [72.088299205000055, 34.890338836000069],
              [72.088296876000072, 34.89034104600006],
              [72.083299571000055, 34.895083011000054],
              [72.083298683000066, 34.89508300500006],
              [72.083195358000069, 34.895181045000072],
              [72.070386880000058, 34.89514915400008],
              [72.064136765000057, 34.899479639000049],
              [72.064128175000064, 34.89948559100003],
              [72.059653571000069, 34.904861168000025],
              [72.053960304000043, 34.911623860000077],
              [72.047940316000052, 34.918774645000042],
              [72.04789837900006, 34.918798706000075],
              [72.047891346000029, 34.918802741000036],
              [72.034200371000054, 34.926657868000063],
              [72.01725198500003, 34.93609722900004],
              [72.017273816000056, 34.936351218000027],
              [72.017275476000066, 34.936370526000076],
              [72.017423587000053, 34.938093730000048],
              [72.01806468500007, 34.945552569000029],
              [72.017889270000069, 34.950609288000066],
              [72.01761493600003, 34.957274249000079],
              [72.012227977000066, 34.95911071200004],
              [72.009758135000084, 34.96219801400008],
              [71.999570040000037, 34.973312300000032],
              [71.986757737000062, 34.980104364000056],
              [71.970240673000035, 34.980721824000057],
              [71.94631408500004, 34.985044047000031],
              [71.930414481000071, 34.988285714000028],
              [71.915099252000061, 34.993893079000031],
              [71.91307865400006, 34.99463288000004],
              [71.912208947000067, 34.994951306000075],
              [71.905398104000028, 35.003047656000035],
              [71.901243916000055, 35.01381212900003],
              [71.89844800000003, 35.021057000000042],
              [71.897420795000073, 35.022983105000037],
              [71.891795000000059, 35.033532000000037],
              [71.87957194300003, 35.042168484000058],
              [71.872124000000042, 35.047431000000074],
              [71.866292000000044, 35.051550000000077],
              [71.850547258000063, 35.062046297000052],
              [71.850449886000035, 35.062111210000069],
              [71.839680000000044, 35.069291000000078],
              [71.830255000000079, 35.073727000000076],
              [71.816394000000059, 35.067905000000053],
              [71.800316000000066, 35.06291600000003],
              [71.796961854000074, 35.061308753000048],
              [71.796840551000059, 35.061250627000049],
              [71.787010000000066, 35.056540000000041],
              [71.778140000000064, 35.058203000000049],
              [71.761507000000051, 35.054045000000031],
              [71.747647000000029, 35.049887000000069],
              [71.72824200000008, 35.034640000000024],
              [71.72786045600003, 35.029485708000038],
              [71.727688000000057, 35.027156000000048],
              [71.727604000000042, 35.027156000000048],
              [71.707729000000029, 35.027156000000048],
              [71.689711000000045, 35.028542000000073],
              [71.689676691000045, 35.02855200700003],
              [71.689210896000077, 35.028687867000031],
              [71.676405000000045, 35.032423000000051],
              [71.675813685000037, 35.032416647000048],
              [71.65062400000005, 35.032146000000068],
              [71.63537800000006, 35.026324000000045],
              [71.625953000000038, 35.033532000000037],
              [71.608212000000037, 35.036026000000049],
              [71.607184208000035, 35.03628292500008],
              [71.607031738000046, 35.036321039000029],
              [71.597123000000067, 35.038798000000043],
              [71.584095000000048, 35.04961000000003],
              [71.566076000000066, 35.052104000000043],
              [71.537244000000044, 35.054574000000059],
              [71.536587000000054, 35.057462000000044],
              [71.532001000000037, 35.069256000000053],
              [71.532001000000037, 35.083671000000038],
              [71.543795000000046, 35.102017000000046],
              [71.558865000000083, 35.113811000000055],
              [71.578301000000067, 35.124912000000052],
              [71.594447000000059, 35.134061000000031],
              [71.615975000000049, 35.136751000000061],
              [71.635889000000077, 35.153435000000059],
              [71.63948190800005, 35.161093614000038],
              [71.645681922000051, 35.174026663000063],
              [71.664088010000057, 35.192432751000069],
              [71.666717452000057, 35.210838839000075],
              [71.670044533000066, 35.223560034000059],
              [71.689710985000033, 35.227576991000035],
              [71.707451985000034, 35.226744991000032],
              [71.721589985000037, 35.235337991000051],
              [71.72327043100006, 35.238792859000057],
              [71.726543324000033, 35.245521675000077],
              [71.726578995000068, 35.245594993000054],
              [71.736558993000074, 35.246980996000048],
              [71.752082985000072, 35.250029991000076],
              [71.75374598500008, 35.262503991000074],
              [71.756240985000034, 35.277472991000025],
              [71.755963985000051, 35.295768991000045],
              [71.773427985000069, 35.305747991000032],
              [71.777308985000047, 35.314064991000066],
              [71.774536985000054, 35.318499991000067],
              [71.777031985000065, 35.332359991000033],
              [71.795050985000046, 35.350932991000036],
              [71.804475985000067, 35.357862991000047],
              [71.806820729000037, 35.358976686000062],
              [71.80741645300003, 35.359259640000062],
              [71.815564985000037, 35.363129991000051],
              [71.831642636000083, 35.362852997000061],
              [71.83164298500003, 35.362852991000068],
              [71.840234356000053, 35.357586989000026],
              [71.840235985000049, 35.357585991000064],
              [71.858235646000082, 35.357039980000025],
              [71.858258764000084, 35.357039279000048],
              [71.85853198500007, 35.357030991000045],
              [71.86188990800008, 35.362188372000048],
              [71.861887510000031, 35.362189006000051],
              [71.86629298500003, 35.368950991000077],
              [71.874054985000043, 35.381701991000057],
              [71.872669985000073, 35.401383991000046],
              [71.878490985000042, 35.415798991000031],
              [71.885420985000053, 35.417461991000039],
              [71.89345998500005, 35.418847991000064],
              [71.893457753000064, 35.418849957000077],
              [71.893458000000066, 35.418850000000077],
              [71.890015758000061, 35.421882473000039],
              [71.881817985000055, 35.429104991000031],
              [71.877659985000037, 35.444627991000061],
              [71.881263985000032, 35.45349899100006],
              [71.892074985000079, 35.465695991000075],
              [71.902885985000069, 35.470130991000076],
              [71.920626985000069, 35.469575991000056],
              [71.933378985000047, 35.47706099100003],
              [71.933373650000078, 35.477062621000073],
              [71.933376000000067, 35.477064000000041],
              [71.923398704000078, 35.480112174000055],
              [71.915914985000029, 35.492861991000041],
              [71.916162338000049, 35.506945979000079],
              [71.916162553000049, 35.506958207000025],
              [71.916162604000078, 35.506961138000065],
              [71.916469985000049, 35.524462991000064],
              [71.917578853000066, 35.536882950000063],
              [71.91757989000007, 35.536882873000025],
              [71.917855985000074, 35.539975301000027],
              [71.935597985000072, 35.567418301000032],
              [71.945653660000062, 35.577848305000032],
              [71.945653914000047, 35.577848568000036],
              [71.945699885000067, 35.577896164000038],
              [71.945700372000033, 35.577896189000057],
              [71.95915728600005, 35.591827690000059],
              [71.977730286000053, 35.60984669000004],
              [71.991841221000072, 35.618263598000055],
              [71.993530299000042, 35.619271101000038],
              [71.998123211000063, 35.620825778000039],
              [72.011549286000047, 35.62536969000007],
              [72.023747286000059, 35.628418690000046],
              [72.034558286000049, 35.642556690000049],
              [72.046755286000064, 35.646714690000067],
              [72.059784286000081, 35.645605690000025],
              [72.073367286000064, 35.643941690000077],
              [72.082515286000046, 35.650040690000026],
              [72.095266286000083, 35.658079690000079],
              [72.09764225400005, 35.661702672000047],
              [72.097644980000041, 35.661706829000025],
              [72.106355286000053, 35.674988690000077],
              [72.111068286000034, 35.690789690000031],
              [72.103306286000077, 35.70187769000006],
              [72.089723286000037, 35.709916690000057],
              [72.092704327000035, 35.714686786000073],
              [72.09271018000004, 35.714686533000076],
              [72.096656985000038, 35.721001991000037],
              [72.105804985000077, 35.733752991000074],
              [72.108022985000048, 35.747058991000074],
              [72.111903985000083, 35.759255991000032],
              [72.121883985000068, 35.773115991000054],
              [72.124564568000039, 35.780040865000046],
              [72.124615754000047, 35.779974379000066],
              [72.129847625000082, 35.780645897000056],
              [72.132152047000034, 35.780984852000074],
              [72.147121047000041, 35.78708385200008],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "88",
      properties: { name: "Peshawar", count: 1220 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.480867184000033, 34.204353734000051],
            [71.484276497000053, 34.201525528000047],
            [71.484838798000055, 34.20099777300004],
            [71.485288001000072, 34.200576169000044],
            [71.48833333500005, 34.197717932000046],
            [71.488343864000058, 34.197509754000066],
            [71.488360900000032, 34.197172917000046],
            [71.488413594000065, 34.196411771000044],
            [71.48843296900003, 34.196379173000025],
            [71.488461315000052, 34.196362902000033],
            [71.48861626200005, 34.196315148000053],
            [71.488781841000048, 34.196270870000035],
            [71.488814170000069, 34.196245919000035],
            [71.488890336000054, 34.19610192600004],
            [71.488976150000042, 34.195868277000045],
            [71.489006910000057, 34.19584695900005],
            [71.48906243700003, 34.195833363000077],
            [71.489210510000078, 34.195850537000069],
            [71.489265352000075, 34.195828782000035],
            [71.489299457000072, 34.195800523000059],
            [71.489331535000076, 34.195734696000045],
            [71.489384747000031, 34.195590027000037],
            [71.489515333000043, 34.195156835000034],
            [71.489534723000077, 34.195160388000033],
            [71.490821795000045, 34.195375739000042],
            [71.49184086200006, 34.195546247000038],
            [71.492476178000061, 34.195676275000039],
            [71.492553537000049, 34.195335653000029],
            [71.492566611000029, 34.195275301000038],
            [71.492722747000073, 34.194998610000027],
            [71.492748402000075, 34.194918181000048],
            [71.492799778000062, 34.19458428300004],
            [71.49285330400005, 34.193753832000027],
            [71.492862623000065, 34.193733168000051],
            [71.492939609000075, 34.193704296000078],
            [71.493049800000051, 34.193689017000054],
            [71.493091897000056, 34.193666970000038],
            [71.493103566000059, 34.193641193000076],
            [71.493052998000053, 34.193360906000066],
            [71.493035853000038, 34.193265878000034],
            [71.492968307000069, 34.193025180000063],
            [71.492785040000058, 34.192758333000029],
            [71.492695174000062, 34.192618627000058],
            [71.49263815900008, 34.192477574000065],
            [71.492587164000042, 34.19222256300003],
            [71.49242020500003, 34.191336479000029],
            [71.492382952000071, 34.191257295000071],
            [71.492074137000031, 34.190897473000064],
            [71.492000975000053, 34.190827932000047],
            [71.491982204000067, 34.19076620800007],
            [71.491977699000074, 34.190649070000063],
            [71.492038296000032, 34.190399089000039],
            [71.492062798000063, 34.19031467700006],
            [71.492029027000058, 34.190241959000048],
            [71.491950562000056, 34.190199111000027],
            [71.490969755000037, 34.189672284000039],
            [71.490555138000047, 34.18943190300007],
            [71.489971444000048, 34.189084513000068],
            [71.489511589000074, 34.188823142000047],
            [71.489461494000068, 34.188796598000067],
            [71.489354043000048, 34.18872066800003],
            [71.48917915800007, 34.188456393000024],
            [71.489065967000045, 34.188283150000075],
            [71.488969603000044, 34.188080493000029],
            [71.488789697000072, 34.187691681000047],
            [71.489828517000035, 34.187777505000042],
            [71.492352728000071, 34.187911962000044],
            [71.494442019000076, 34.188012687000025],
            [71.496660838000082, 34.188149903000067],
            [71.496962719000066, 34.188168572000052],
            [71.49953002500007, 34.188332108000054],
            [71.501470174000076, 34.188459327000032],
            [71.500418749000062, 34.182779255000071],
            [71.500438882000083, 34.182778393000035],
            [71.500415000000032, 34.182759000000033],
            [71.500270000000057, 34.182638000000054],
            [71.500265003000038, 34.182633739000039],
            [71.499863000000062, 34.182291000000077],
            [71.499785000000031, 34.182223000000079],
            [71.49970600000006, 34.182151000000033],
            [71.49962400000004, 34.182074000000057],
            [71.49953800000003, 34.181990000000042],
            [71.499364000000071, 34.181814000000031],
            [71.499289047000048, 34.181735996000043],
            [71.49902000000003, 34.181456000000026],
            [71.498874000000058, 34.181306000000063],
            [71.498718000000054, 34.181152000000054],
            [71.498574000000076, 34.181018000000051],
            [71.49814200000003, 34.180630000000065],
            [71.49799100000007, 34.180499000000054],
            [71.49785200000008, 34.180382000000066],
            [71.49771400000003, 34.180269000000067],
            [71.497581000000082, 34.180166000000042],
            [71.497452000000067, 34.180071000000055],
            [71.497327000000041, 34.179984000000047],
            [71.497255000000052, 34.17993800000005],
            [71.497178000000076, 34.179894000000047],
            [71.497098000000051, 34.179852000000039],
            [71.497012000000041, 34.179812000000027],
            [71.496921000000043, 34.179774000000066],
            [71.496825000000058, 34.179737000000046],
            [71.496724000000029, 34.179702000000077],
            [71.496616000000074, 34.179669000000047],
            [71.496522000000084, 34.179641000000061],
            [71.496421000000055, 34.179615000000069],
            [71.496199000000047, 34.179562000000033],
            [71.496012529000041, 34.17952198100005],
            [71.495980000000031, 34.179515000000038],
            [71.495441000000028, 34.179407000000026],
            [71.495295000000056, 34.17937500000005],
            [71.49516600000004, 34.179345000000069],
            [71.495098816000052, 34.179327600000079],
            [71.49502700000005, 34.179309000000046],
            [71.494901000000084, 34.17927300000008],
            [71.494785000000036, 34.179235000000062],
            [71.494678000000079, 34.17919500000005],
            [71.494617000000062, 34.179170000000056],
            [71.494559000000038, 34.179145000000062],
            [71.494504000000063, 34.179118000000074],
            [71.494451000000083, 34.179090000000031],
            [71.494401000000039, 34.179062000000044],
            [71.494353000000046, 34.179032000000063],
            [71.494308000000046, 34.179002000000025],
            [71.494265000000041, 34.178970000000049],
            [71.494224000000031, 34.178938000000073],
            [71.49418600000007, 34.178904000000045],
            [71.494150000000047, 34.178869000000077],
            [71.494116000000076, 34.178832000000057],
            [71.494085000000041, 34.178795000000036],
            [71.49405500000006, 34.178756000000078],
            [71.494028000000071, 34.178716000000065],
            [71.494003000000077, 34.178675000000055],
            [71.493978000000084, 34.178630000000055],
            [71.493954000000031, 34.178581000000065],
            [71.493931000000032, 34.178531000000078],
            [71.493909000000031, 34.178478000000041],
            [71.493868000000077, 34.178364000000045],
            [71.493831000000057, 34.178241000000071],
            [71.493798000000083, 34.178109000000063],
            [71.493769000000043, 34.177968000000078],
            [71.493745000000047, 34.177820000000054],
            [71.49372500000004, 34.177666000000045],
            [71.493710000000078, 34.177503000000058],
            [71.493701000000044, 34.177336000000025],
            [71.493697000000054, 34.177168000000052],
            [71.493698000000052, 34.177001000000075],
            [71.493705000000034, 34.176838000000032],
            [71.493717000000061, 34.176683000000025],
            [71.493734000000075, 34.176537000000053],
            [71.493756000000076, 34.176405000000045],
            [71.493766000000051, 34.17635800000005],
            [71.493778000000077, 34.176312000000053],
            [71.493792000000042, 34.17626800000005],
            [71.493808000000058, 34.176225000000045],
            [71.493825000000072, 34.176184000000035],
            [71.493845000000078, 34.176144000000079],
            [71.493866000000082, 34.176105000000064],
            [71.493889000000081, 34.176068000000043],
            [71.493914000000075, 34.176032000000077],
            [71.493941000000063, 34.17599800000005],
            [71.49396900000005, 34.175965000000076],
            [71.494000000000028, 34.175934000000041],
            [71.494032000000061, 34.17590400000006],
            [71.494066000000032, 34.175875000000076],
            [71.494102000000055, 34.17584800000003],
            [71.494140000000073, 34.175822000000039],
            [71.494180000000028, 34.175797000000046],
            [71.494222000000036, 34.175774000000047],
            [71.494266000000039, 34.175752000000045],
            [71.494311000000039, 34.175732000000039],
            [71.494359000000031, 34.17571300000003],
            [71.494408000000078, 34.175695000000076],
            [71.494460000000061, 34.175678000000062],
            [71.49451300000004, 34.175663000000043],
            [71.494626000000039, 34.175637000000052],
            [71.494747000000075, 34.175616000000048],
            [71.494877000000031, 34.175599000000034],
            [71.495016000000078, 34.175588000000062],
            [71.495098816000052, 34.175584399000059],
            [71.495131000000072, 34.175583000000074],
            [71.495253000000048, 34.175581000000079],
            [71.495380000000068, 34.175582000000077],
            [71.495514000000071, 34.175586000000067],
            [71.495656000000054, 34.175594000000046],
            [71.495804000000078, 34.175604000000078],
            [71.495961000000079, 34.175618000000043],
            [71.496128000000056, 34.175634000000059],
            [71.496426000000042, 34.175670000000025],
            [71.496766000000036, 34.175716000000079],
            [71.497108000000082, 34.175766000000067],
            [71.498038000000065, 34.175907000000052],
            [71.498316000000045, 34.175947000000065],
            [71.498565000000042, 34.175980000000038],
            [71.49884000000003, 34.176014000000066],
            [71.499094000000071, 34.176041000000055],
            [71.499333000000036, 34.176063000000056],
            [71.499559000000033, 34.176079000000072],
            [71.499810000000082, 34.176091000000042],
            [71.499929000000066, 34.176094000000035],
            [71.500045000000057, 34.176095000000032],
            [71.500158000000056, 34.176094000000035],
            [71.500268000000062, 34.176091000000042],
            [71.500375000000076, 34.176087000000052],
            [71.500479000000041, 34.17608000000007],
            [71.50058000000007, 34.176072000000033],
            [71.50067800000005, 34.176062000000059],
            [71.500773000000038, 34.176050000000032],
            [71.500865000000033, 34.176036000000067],
            [71.500955000000033, 34.176020000000051],
            [71.501042000000041, 34.17600200000004],
            [71.501127000000054, 34.175982000000033],
            [71.501209000000074, 34.175960000000032],
            [71.501328000000058, 34.175925000000063],
            [71.501451000000031, 34.175886000000048],
            [71.501580000000047, 34.175843000000043],
            [71.50171400000005, 34.175795000000051],
            [71.501862000000074, 34.175740000000076],
            [71.50202500000006, 34.175678000000062],
            [71.502693000000079, 34.175411000000054],
            [71.502986000000078, 34.175298000000055],
            [71.50314700000007, 34.175239000000033],
            [71.503298000000029, 34.175187000000051],
            [71.503442000000064, 34.175141000000053],
            [71.503581000000054, 34.175100000000043],
            [71.503690000000063, 34.175070000000062],
            [71.503802000000064, 34.175043000000073],
            [71.503917000000058, 34.175016000000028],
            [71.504035000000044, 34.174992000000032],
            [71.504157000000077, 34.174969000000033],
            [71.504283000000044, 34.174947000000031],
            [71.504414000000054, 34.174926000000028],
            [71.504551000000049, 34.174907000000076],
            [71.504786000000081, 34.174878000000035],
            [71.505049000000042, 34.174851000000046],
            [71.505302000000029, 34.17482700000005],
            [71.50542213600005, 34.174816528000065],
            [71.505910000000029, 34.17477400000007],
            [71.506220000000042, 34.174744000000032],
            [71.506518000000028, 34.174710000000061],
            [71.506654000000083, 34.174691000000053],
            [71.506785000000036, 34.174672000000044],
            [71.506919000000039, 34.174649000000045],
            [71.507047000000057, 34.174626000000046],
            [71.507172000000082, 34.174601000000052],
            [71.507292000000064, 34.174574000000064],
            [71.50741000000005, 34.17454500000008],
            [71.507523000000049, 34.174514000000045],
            [71.507634000000053, 34.174482000000069],
            [71.507742000000064, 34.174447000000043],
            [71.507849000000078, 34.174409000000026],
            [71.507957000000033, 34.174368000000072],
            [71.50806700000004, 34.174322000000075],
            [71.508178000000044, 34.17427200000003],
            [71.508291000000042, 34.174217000000056],
            [71.508407000000034, 34.174158000000034],
            [71.508525000000077, 34.174094000000025],
            [71.508648000000051, 34.174024000000031],
            [71.508856000000037, 34.17390000000006],
            [71.509085000000084, 34.173755000000028],
            [71.509303000000045, 34.173611000000051],
            [71.509826000000032, 34.173261000000025],
            [71.510096000000033, 34.173087000000066],
            [71.510231000000033, 34.173003000000051],
            [71.510359000000051, 34.172926000000075],
            [71.510481000000084, 34.172855000000027],
            [71.51059900000007, 34.172790000000077],
            [71.510723000000041, 34.172726000000068],
            [71.510752543000081, 34.172711595000067],
            [71.510844000000077, 34.172667000000047],
            [71.510962000000063, 34.172613000000069],
            [71.511078000000055, 34.172564000000079],
            [71.511193000000048, 34.172520000000077],
            [71.511306000000047, 34.172480000000064],
            [71.511418000000049, 34.172445000000039],
            [71.511529000000053, 34.172415000000058],
            [71.511749000000066, 34.172363000000075],
            [71.511974000000066, 34.172317000000078],
            [71.512205000000051, 34.172277000000065],
            [71.512441000000081, 34.17224200000004],
            [71.512680000000046, 34.172214000000054],
            [71.512924000000055, 34.172192000000052],
            [71.513171000000057, 34.172177000000033],
            [71.513421000000051, 34.172167000000059],
            [71.51367300000004, 34.172164000000066],
            [71.513927000000081, 34.172167000000059],
            [71.514181000000065, 34.172176000000036],
            [71.514436000000046, 34.172192000000052],
            [71.514689000000033, 34.172213000000056],
            [71.514942000000076, 34.172241000000042],
            [71.51519200000007, 34.17227500000007],
            [71.515439000000072, 34.172315000000026],
            [71.515557000000058, 34.172337000000027],
            [71.515677000000039, 34.172364000000073],
            [71.515798000000075, 34.172394000000054],
            [71.515922000000046, 34.172428000000025],
            [71.516048000000069, 34.172466000000043],
            [71.516177000000084, 34.172509000000048],
            [71.516310000000033, 34.172556000000043],
            [71.516447000000028, 34.172608000000025],
            [71.516567000000066, 34.172655000000077],
            [71.516691000000037, 34.172707000000059],
            [71.516961000000038, 34.172824000000048],
            [71.517226000000051, 34.172946000000024],
            [71.517915000000073, 34.173268000000064],
            [71.518118000000072, 34.173360000000059],
            [71.518301000000065, 34.173440000000028],
            [71.518508000000054, 34.173525000000041],
            [71.518702000000076, 34.173600000000079],
            [71.518889000000058, 34.173666000000026],
            [71.519068000000061, 34.173723000000052],
            [71.519729000000041, 34.173926000000051],
            [71.519992000000059, 34.174005000000079],
            [71.520271000000037, 34.17408400000005],
            [71.520521000000031, 34.174150000000054],
            [71.520652000000041, 34.17418200000003],
            [71.520778000000064, 34.174210000000073],
            [71.52090000000004, 34.174235000000067],
            [71.521019000000081, 34.174258000000066],
            [71.521135000000072, 34.174277000000075],
            [71.521248000000071, 34.174294000000032],
            [71.521359000000075, 34.174309000000051],
            [71.521468000000084, 34.17432000000008],
            [71.521548000000053, 34.174327000000062],
            [71.521567193000067, 34.174327960000028],
            [71.521628000000078, 34.174331000000052],
            [71.521791000000064, 34.174336000000039],
            [71.521958000000041, 34.174333000000047],
            [71.522128000000066, 34.174323000000072],
            [71.522303000000079, 34.174306000000058],
            [71.522483000000079, 34.174281000000065],
            [71.522669000000064, 34.174250000000029],
            [71.522862000000032, 34.174211000000071],
            [71.52302700000007, 34.174173000000053],
            [71.523200000000031, 34.17412900000005],
            [71.523382000000083, 34.17408000000006],
            [71.523576000000048, 34.174023000000034],
            [71.523749000000066, 34.173970000000054],
            [71.523942000000034, 34.173909000000037],
            [71.524612000000047, 34.173691000000076],
            [71.524875000000065, 34.173607000000061],
            [71.525143000000071, 34.173525000000041],
            [71.525384000000031, 34.173456000000044],
            [71.525521000000083, 34.173420000000078],
            [71.525652000000036, 34.173387000000048],
            [71.525779000000057, 34.173358000000064],
            [71.525903000000028, 34.173331000000076],
            [71.526024000000064, 34.173308000000077],
            [71.52614200000005, 34.173287000000073],
            [71.526257000000044, 34.173269000000062],
            [71.52637100000004, 34.173254000000043],
            [71.526577000000032, 34.173226000000057],
            [71.526809000000071, 34.173189000000036],
            [71.527040000000056, 34.173148000000026],
            [71.527407372000084, 34.173080262000042],
            [71.527642000000071, 34.173037000000079],
            [71.527817000000084, 34.173006000000044],
            [71.527975000000083, 34.17298100000005],
            [71.528149000000042, 34.172956000000056],
            [71.528310000000033, 34.17293600000005],
            [71.528463000000045, 34.172921000000031],
            [71.528608000000077, 34.172910000000059],
            [71.528779000000043, 34.172904000000074],
            [71.528942000000029, 34.172904000000074],
            [71.529021000000057, 34.172907000000066],
            [71.529097000000036, 34.172911000000056],
            [71.529172000000074, 34.172917000000041],
            [71.52924500000006, 34.172925000000077],
            [71.529316000000051, 34.172935000000052],
            [71.529386000000045, 34.172947000000079],
            [71.529454000000044, 34.172960000000046],
            [71.529521000000045, 34.172976000000062],
            [71.529586000000052, 34.172993000000076],
            [71.529650000000061, 34.173012000000028],
            [71.529713000000072, 34.173032000000035],
            [71.529774000000032, 34.173055000000033],
            [71.529861000000039, 34.173091000000056],
            [71.529948000000047, 34.173133000000064],
            [71.530036000000052, 34.173180000000059],
            [71.530124000000058, 34.173233000000039],
            [71.53021300000006, 34.173291000000063],
            [71.530304000000058, 34.173356000000069],
            [71.530396000000053, 34.173426000000063],
            [71.53049100000004, 34.173504000000037],
            [71.530631479000078, 34.173624483000026],
            [71.530778000000055, 34.173762000000067],
            [71.530949000000078, 34.173930000000041],
            [71.531310000000076, 34.174296000000027],
            [71.531468000000075, 34.174452000000031],
            [71.531637000000046, 34.174611000000027],
            [71.531715000000077, 34.174682000000075],
            [71.531790000000058, 34.174747000000025],
            [71.531920000000071, 34.174861000000078],
            [71.532061000000056, 34.174992000000032],
            [71.532224000000042, 34.175149000000033],
            [71.532567000000029, 34.175491000000079],
            [71.532628712000076, 34.175551470000073],
            [71.53271600000005, 34.175637000000052],
            [71.532876000000044, 34.175787000000071],
            [71.533020000000079, 34.175915000000032],
            [71.53310700000003, 34.175987000000077],
            [71.533191000000045, 34.176053000000024],
            [71.533375015000047, 34.176072923000049],
            [71.533792095000081, 34.176036937000049],
            [71.53416277000008, 34.175962782000056],
            [71.534721725000054, 34.17574415200005],
            [71.535309716000029, 34.175405787000045],
            [71.53567373900006, 34.175210317000051],
            [71.536047589000077, 34.175009569000053],
            [71.536980114000073, 34.174636508000049],
            [71.537398538000048, 34.17444243500006],
            [71.537592327000084, 34.17425484100005],
            [71.537732342000083, 34.174124302000052],
            [71.537907241000084, 34.174087219000057],
            [71.53804314000007, 34.174143793000042],
            [71.538281149000056, 34.174168959000042],
            [71.538630130000058, 34.174068371000033],
            [71.539140004000046, 34.173851695000053],
            [71.539194894000047, 34.173676778000072],
            [71.539002931000084, 34.173479034000025],
            [71.538785373000053, 34.173276226000041],
            [71.538730663000081, 34.173025265000035],
            [71.538806206000061, 34.172796091000066],
            [71.539023603000032, 34.172566290000077],
            [71.539302596000084, 34.172346421000043],
            [71.539638380000042, 34.172198125000079],
            [71.539952690000064, 34.172158326000044],
            [71.540424839000082, 34.172175251000056],
            [71.540881243000058, 34.172265019000065],
            [71.541282616000046, 34.172254808000048],
            [71.541697433000081, 34.172105113000043],
            [71.541823071000067, 34.172059774000047],
            [71.542724000000078, 34.171729000000028],
            [71.542801000000054, 34.171692000000064],
            [71.542883000000074, 34.171659000000034],
            [71.542969000000085, 34.17163000000005],
            [71.54306100000008, 34.171604000000059],
            [71.543158000000062, 34.171582000000058],
            [71.543262000000084, 34.171562000000051],
            [71.543354000000079, 34.171549000000027],
            [71.543451000000061, 34.171537000000058],
            [71.543555000000083, 34.171527000000026],
            [71.543667000000084, 34.171519000000046],
            [71.543955758000038, 34.171497162000037],
            [71.544527501000061, 34.171394903000078],
            [71.544826879000084, 34.171385272000066],
            [71.545024082000054, 34.171345745000053],
            [71.54519917500005, 34.171367755000063],
            [71.545599916000072, 34.17151679400007],
            [71.545877747000077, 34.171575606000033],
            [71.546433782000065, 34.171363052000061],
            [71.546746485000028, 34.171239429000025],
            [71.546838851000075, 34.171165896000048],
            [71.547042877000081, 34.171073913000043],
            [71.547501083000043, 34.170904440000072],
            [71.547691927000074, 34.170890450000059],
            [71.547903782000049, 34.170935712000073],
            [71.548050594000074, 34.170954315000074],
            [71.548299260000078, 34.17098582400007],
            [71.54859020300006, 34.170969684000056],
            [71.548965574000079, 34.17090642900007],
            [71.549462576000053, 34.170814718000031],
            [71.549645527000052, 34.170806024000058],
            [71.549841762000028, 34.170771913000067],
            [71.549988919000043, 34.170745384000043],
            [71.550098000000048, 34.170645000000036],
            [71.550120000000049, 34.170600000000036],
            [71.550143000000048, 34.17056000000008],
            [71.550166000000047, 34.170526000000052],
            [71.550191000000041, 34.170495000000074],
            [71.550234000000046, 34.170451000000071],
            [71.550250197000082, 34.170437215000049],
            [71.550281000000041, 34.170411000000058],
            [71.55033300000008, 34.170375000000035],
            [71.550389000000052, 34.170342000000062],
            [71.550450000000069, 34.170313000000078],
            [71.550516000000073, 34.17028700000003],
            [71.550588000000062, 34.170265000000029],
            [71.550666000000035, 34.170246000000077],
            [71.550732000000039, 34.170233000000053],
            [71.55080300000003, 34.170222000000024],
            [71.550880000000063, 34.170213000000047],
            [71.550964000000079, 34.170205000000067],
            [71.55112100000008, 34.170194000000038],
            [71.551506000000074, 34.170175000000029],
            [71.551608000000044, 34.170168000000047],
            [71.551699000000042, 34.170160000000067],
            [71.551791000000037, 34.17014800000004],
            [71.551875000000052, 34.170134000000076],
            [71.551953000000083, 34.170118000000059],
            [71.552026000000069, 34.17009900000005],
            [71.552126000000044, 34.170069000000069],
            [71.552228000000071, 34.170035000000041],
            [71.552331000000038, 34.169999000000075],
            [71.55243500000006, 34.169961000000058],
            [71.552540000000079, 34.16991900000005],
            [71.552648000000033, 34.16987400000005],
            [71.55275800000004, 34.169826000000057],
            [71.552871000000039, 34.169774000000075],
            [71.552986000000033, 34.169719000000043],
            [71.553106000000071, 34.169660000000079],
            [71.553364000000045, 34.169526000000076],
            [71.553634000000045, 34.169380000000046],
            [71.554168000000061, 34.16908200000006],
            [71.554394000000059, 34.168958000000032],
            [71.554631000000029, 34.168832000000066],
            [71.554846000000055, 34.168724000000054],
            [71.554973000000075, 34.16866200000004],
            [71.555096000000049, 34.168605000000071],
            [71.555215000000032, 34.168553000000031],
            [71.555332000000078, 34.168504000000041],
            [71.555447000000072, 34.168458000000044],
            [71.555560000000071, 34.168417000000034],
            [71.555671000000075, 34.168378000000075],
            [71.555781000000081, 34.16834300000005],
            [71.555918000000077, 34.168303000000037],
            [71.556061000000057, 34.168263000000024],
            [71.556244350000043, 34.168145280000033],
            [71.556484148000038, 34.167959774000053],
            [71.556828193000058, 34.167684030000032],
            [71.557172306000041, 34.167433213000038],
            [71.557537904000071, 34.167242525000063],
            [71.557959524000069, 34.167024831000049],
            [71.558275448000074, 34.166909453000073],
            [71.558548827000038, 34.16684861300007],
            [71.559072787000048, 34.166745796000043],
            [71.559486396000068, 34.16668495600004],
            [71.55987934500007, 34.166698816000064],
            [71.559992183000077, 34.166733962000023],
            [71.560062312000071, 34.166755806000026],
            [71.560281649000046, 34.166882753000039],
            [71.560607596000068, 34.167028115000051],
            [71.560793214000057, 34.167104621000078],
            [71.561149529000033, 34.167281857000035],
            [71.561406816000044, 34.167313917000058],
            [71.561520274000031, 34.16729237900006],
            [71.561620000000062, 34.167242000000044],
            [71.56189100000006, 34.167120000000068],
            [71.562009000000046, 34.167070000000024],
            [71.562074000000052, 34.16704500000003],
            [71.562135000000069, 34.167024000000026],
            [71.562193000000036, 34.167005000000074],
            [71.562249000000065, 34.166990000000055],
            [71.562322000000052, 34.166975000000036],
            [71.562400000000082, 34.166964000000064],
            [71.56248400000004, 34.166958000000079],
            [71.562575000000038, 34.166956000000027],
            [71.562658000000056, 34.166958000000079],
            [71.562750000000051, 34.166963000000067],
            [71.563065000000051, 34.166989000000058],
            [71.563184000000035, 34.166997000000038],
            [71.563303000000076, 34.16700000000003],
            [71.563408000000038, 34.16699600000004],
            [71.563465000000065, 34.166991000000053],
            [71.56352000000004, 34.16698400000007],
            [71.563571000000081, 34.166974000000039],
            [71.563621000000069, 34.166962000000069],
            [71.563668000000064, 34.16694700000005],
            [71.563713000000064, 34.166930000000036],
            [71.563757000000066, 34.16691000000003],
            [71.563798000000077, 34.166888000000029],
            [71.563836000000038, 34.166864000000032],
            [71.56387600000005, 34.166835000000049],
            [71.563922000000048, 34.166797000000031],
            [71.56393349700005, 34.166787228000032],
            [71.564062000000035, 34.166678000000047],
            [71.564279049000049, 34.166634441000042],
            [71.56460469700005, 34.166579744000046],
            [71.565098760000069, 34.166531552000038],
            [71.565510872000061, 34.16651672900008],
            [71.566032333000066, 34.166518491000033],
            [71.566281253000056, 34.166551022000078],
            [71.566913047000071, 34.166661401000056],
            [71.567454000000055, 34.166774000000032],
            [71.567633000000058, 34.166815000000042],
            [71.567793000000052, 34.16684900000007],
            [71.567906969000035, 34.166870187000029],
            [71.567949000000056, 34.166878000000054],
            [71.568306929000073, 34.166962208000029],
            [71.568544645000031, 34.167041969000024],
            [71.568724763000034, 34.167142334000062],
            [71.568879920000029, 34.167227650000029],
            [71.569113582000057, 34.167273416000057],
            [71.56932297600008, 34.167311254000026],
            [71.569356728000059, 34.167317353000044],
            [71.569625583000061, 34.167391810000026],
            [71.569805187000043, 34.167482156000062],
            [71.569950745000028, 34.167541842000048],
            [71.570108288000029, 34.167620620000037],
            [71.570330133000084, 34.16766989100006],
            [71.570480959000065, 34.167689804000076],
            [71.570527734000052, 34.167670705000035],
            [71.570640648000051, 34.167640302000052],
            [71.570696677000058, 34.167636150000078],
            [71.570721347000074, 34.167634321000037],
            [71.570915706000051, 34.167623414000047],
            [71.571115665000036, 34.167617576000055],
            [71.571210073000032, 34.167590373000053],
            [71.571529377000047, 34.167457736000074],
            [71.57197332100003, 34.167277642000045],
            [71.572227357000031, 34.167233476000035],
            [71.572574490000079, 34.167142109000054],
            [71.573315465000064, 34.16685633000003],
            [71.57372295700003, 34.166754474000072],
            [71.574090243000057, 34.166733130000068],
            [71.574551994000046, 34.166816245000064],
            [71.575204000000042, 34.166923000000054],
            [71.57582936700004, 34.167029527000068],
            [71.576688681000064, 34.167192509000074],
            [71.577077647000067, 34.16726628300006],
            [71.578424939000058, 34.167498274000025],
            [71.578936000000056, 34.16754700000007],
            [71.579083000000082, 34.167568000000074],
            [71.579191000000037, 34.167579000000046],
            [71.579311000000075, 34.167586000000028],
            [71.579434000000049, 34.167589000000078],
            [71.579637939000065, 34.167592138000032],
            [71.579759000000081, 34.167594000000065],
            [71.579856000000063, 34.167598000000055],
            [71.579942000000074, 34.167603000000042],
            [71.580039000000056, 34.167613000000074],
            [71.580129000000056, 34.167626000000041],
            [71.580213000000072, 34.167642000000058],
            [71.58029300000004, 34.167662000000064],
            [71.580441000000064, 34.167707000000064],
            [71.580582000000049, 34.167757000000051],
            [71.58071700000005, 34.167812000000026],
            [71.580845000000068, 34.167871000000048],
            [71.580906000000084, 34.167902000000026],
            [71.580965000000049, 34.167934000000059],
            [71.581022000000075, 34.167967000000033],
            [71.58107700000005, 34.168001000000061],
            [71.58113000000003, 34.168036000000029],
            [71.581181000000072, 34.168072000000052],
            [71.581230000000062, 34.168109000000072],
            [71.581277000000057, 34.168147000000033],
            [71.581303000000048, 34.168170000000032],
            [71.581330000000037, 34.168197000000077],
            [71.581420000000037, 34.16829400000006],
            [71.581454000000065, 34.168328000000031],
            [71.581489000000033, 34.168360000000064],
            [71.581522000000064, 34.16838400000006],
            [71.581565000000069, 34.168408000000056],
            [71.581609000000071, 34.168426000000068],
            [71.581657000000064, 34.168438000000037],
            [71.581707000000051, 34.168444000000079],
            [71.581760000000031, 34.168444000000079],
            [71.581815000000063, 34.168438000000037],
            [71.581874000000084, 34.16842500000007],
            [71.581937000000039, 34.168407000000059],
            [71.581982000000039, 34.168391000000042],
            [71.582030000000032, 34.168372000000033],
            [71.582134000000053, 34.168323000000044],
            [71.582260000000076, 34.168256000000042],
            [71.582534000000066, 34.168099000000041],
            [71.582654000000048, 34.168034000000034],
            [71.582718000000057, 34.168002000000058],
            [71.582778000000076, 34.167974000000072],
            [71.582833000000051, 34.167951000000073],
            [71.58288600000003, 34.167932000000064],
            [71.583186000000069, 34.167831000000035],
            [71.583338878000063, 34.16776370000008],
            [71.584077309000065, 34.167343906000042],
            [71.584558396000034, 34.167090818000077],
            [71.585313313000029, 34.166693675000033],
            [71.585391533000063, 34.166652526000064],
            [71.585420389000035, 34.166631869000071],
            [71.586011280000037, 34.16620886700008],
            [71.586210444000073, 34.166018884000039],
            [71.586365341000032, 34.165800141000034],
            [71.586591060000046, 34.165223216000072],
            [71.586603657000069, 34.165070734000039],
            [71.586636436000049, 34.164673974000038],
            [71.586611048000066, 34.164412125000069],
            [71.586682953000036, 34.164168371000073],
            [71.586905401000081, 34.164031385000044],
            [71.58720664100008, 34.163917812000079],
            [71.587518506000038, 34.163838461000068],
            [71.587890763000075, 34.163812858000028],
            [71.587900496000032, 34.163812189000055],
            [71.588219177000042, 34.163792919000059],
            [71.588451921000058, 34.163918511000077],
            [71.588648053000043, 34.16397951600004],
            [71.588905198000077, 34.163932266000074],
            [71.589114419000055, 34.163838750000025],
            [71.589311997000038, 34.163695927000049],
            [71.589459674000068, 34.16361796700005],
            [71.589830052000082, 34.163510870000039],
            [71.590147757000068, 34.163429488000077],
            [71.590595199000063, 34.163384201000042],
            [71.590770689000067, 34.163397527000029],
            [71.590817689000062, 34.163456263000057],
            [71.590823257000068, 34.163535937000063],
            [71.590802973000052, 34.163607672000069],
            [71.59082225800006, 34.163685181000062],
            [71.590953846000048, 34.16379489600007],
            [71.591198000000077, 34.163952000000052],
            [71.591240000000084, 34.16397100000006],
            [71.59127600000005, 34.163989000000072],
            [71.591574000000037, 34.164153000000056],
            [71.591769904000046, 34.16423702700007],
            [71.591943953000055, 34.164270979000037],
            [71.592177728000081, 34.164271284000051],
            [71.592322560000071, 34.164266789000067],
            [71.592455532000031, 34.164253448000068],
            [71.592460000000074, 34.164253000000031],
            [71.592473000000041, 34.164246000000048],
            [71.592505000000074, 34.164234000000079],
            [71.592540000000042, 34.164227000000039],
            [71.59261900000007, 34.164217000000065],
            [71.592647000000056, 34.164214000000072],
            [71.59268000000003, 34.164214000000072],
            [71.593099467000059, 34.164075156000024],
            [71.593305663000081, 34.164025965000064],
            [71.593675117000032, 34.164014692000023],
            [71.594136310000067, 34.164005736000036],
            [71.594489818000056, 34.163993651000055],
            [71.594652812000049, 34.163970711000047],
            [71.594760000000065, 34.163945000000069],
            [71.594822000000079, 34.163933000000043],
            [71.594947000000047, 34.163901000000067],
            [71.595001000000082, 34.16388900000004],
            [71.595061000000044, 34.163879000000065],
            [71.59512200000006, 34.163871000000029],
            [71.595465380000064, 34.16378697600004],
            [71.595838474000061, 34.163708940000049],
            [71.596059773000036, 34.16365991300006],
            [71.596396276000064, 34.163585365000074],
            [71.596922778000078, 34.163473852000038],
            [71.597097471000041, 34.163426657000059],
            [71.597309754000037, 34.163349981000067],
            [71.597804000000053, 34.163136000000065],
            [71.597928882000076, 34.163075539000033],
            [71.598236848000056, 34.162931566000054],
            [71.598428855000066, 34.162850204000051],
            [71.598471062000044, 34.162812129000031],
            [71.598517895000043, 34.162760226000046],
            [71.598557289000041, 34.162687019000032],
            [71.598551350000037, 34.162607562000062],
            [71.598543674000041, 34.162453633000041],
            [71.598533323000083, 34.162269388000027],
            [71.598515000000077, 34.162118000000078],
            [71.598501000000056, 34.162102000000061],
            [71.598490000000083, 34.162085000000047],
            [71.598482000000047, 34.162067000000036],
            [71.598478000000057, 34.162044000000037],
            [71.598478000000057, 34.162021000000038],
            [71.598480000000052, 34.161966000000064],
            [71.598478000000057, 34.161942000000067],
            [71.59847300000007, 34.161921000000063],
            [71.598463000000038, 34.161906000000045],
            [71.598445000000083, 34.16189200000008],
            [71.598401000000081, 34.161869000000024],
            [71.598383000000069, 34.161857000000055],
            [71.598345000000052, 34.161819000000037],
            [71.598311000000081, 34.161781000000076],
            [71.598287000000084, 34.161749000000043],
            [71.598268000000076, 34.161719000000062],
            [71.598254000000054, 34.161691000000076],
            [71.598243000000082, 34.161654000000055],
            [71.598236000000043, 34.161610000000053],
            [71.598235000000045, 34.161561000000063],
            [71.598238000000038, 34.161503000000039],
            [71.59824100000003, 34.161477000000048],
            [71.598247000000072, 34.161450000000059],
            [71.598255000000051, 34.161422000000073],
            [71.598265000000083, 34.161393000000032],
            [71.598366863000081, 34.161066239000036],
            [71.598421318000078, 34.160759385000063],
            [71.598522154000079, 34.160323736000066],
            [71.598601249000069, 34.16004532200003],
            [71.598648245000049, 34.15979677100006],
            [71.598789000000068, 34.159271000000047],
            [71.598797000000047, 34.159233000000029],
            [71.598806000000081, 34.15919800000006],
            [71.598818000000051, 34.159167000000025],
            [71.598832000000073, 34.159138000000041],
            [71.598850000000084, 34.159109000000058],
            [71.598872000000028, 34.159080000000074],
            [71.598897000000079, 34.159050000000036],
            [71.598926000000063, 34.159021000000052],
            [71.598958000000039, 34.158991000000071],
            [71.598995000000059, 34.158960000000036],
            [71.599101000000076, 34.158877000000075],
            [71.599146000000076, 34.158844000000045],
            [71.599192000000073, 34.158812000000069],
            [71.599239000000068, 34.158782000000031],
            [71.599287000000061, 34.158754000000044],
            [71.599335000000053, 34.158728000000053],
            [71.599384000000043, 34.15870300000006],
            [71.599433000000033, 34.158681000000058],
            [71.59948200000008, 34.158661000000052],
            [71.599568000000033, 34.158630000000073],
            [71.599656000000039, 34.15860500000008],
            [71.599744000000044, 34.158584000000076],
            [71.599832000000049, 34.158569000000057],
            [71.599918000000059, 34.158559000000025],
            [71.600002000000075, 34.158555000000035],
            [71.600082000000043, 34.15855700000003],
            [71.600159000000076, 34.158564000000069],
            [71.600197000000037, 34.158571000000052],
            [71.600236000000052, 34.158580000000029],
            [71.600277000000062, 34.158593000000053],
            [71.600320000000067, 34.15860900000007],
            [71.600367554000059, 34.158630135000067],
            [71.600401000000033, 34.158645000000035],
            [71.600606000000084, 34.15874400000007],
            [71.600665000000049, 34.158769000000063],
            [71.600719000000083, 34.158788000000072],
            [71.600824000000046, 34.158818000000053],
            [71.600943000000029, 34.158847000000037],
            [71.601057000000083, 34.158870000000036],
            [71.601318000000049, 34.158919000000026],
            [71.60143800000003, 34.158945000000074],
            [71.601514000000066, 34.158964000000026],
            [71.601690000000076, 34.159012000000075],
            [71.601770000000045, 34.159032000000025],
            [71.60185400000006, 34.159050000000036],
            [71.601930000000038, 34.159061000000065],
            [71.60199700000004, 34.159066000000053],
            [71.602072000000078, 34.159066000000053],
            [71.602151000000049, 34.159062000000063],
            [71.602371000000062, 34.159045000000049],
            [71.602438000000063, 34.159043000000054],
            [71.60249900000008, 34.159044000000051],
            [71.602570000000071, 34.159049000000039],
            [71.602725000000078, 34.159065000000055],
            [71.602793000000077, 34.15907100000004],
            [71.602886000000069, 34.15907500000003],
            [71.602989000000036, 34.15907500000003],
            [71.603099000000043, 34.159073000000035],
            [71.603414000000043, 34.159061000000065],
            [71.603511000000083, 34.159060000000068],
            [71.603599000000031, 34.159062000000063],
            [71.603916000000083, 34.159074000000032],
            [71.604015000000061, 34.159079000000077],
            [71.604103000000066, 34.159086000000059],
            [71.604192000000069, 34.159095000000036],
            [71.604276000000084, 34.159106000000065],
            [71.604354000000058, 34.15912000000003],
            [71.604429000000039, 34.159136000000046],
            [71.604694133000066, 34.159185365000042],
            [71.604882614000076, 34.159240339000064],
            [71.605193348000057, 34.159340594000071],
            [71.605543005000072, 34.159513437000044],
            [71.605764202000046, 34.159641720000025],
            [71.605918022000083, 34.159646203000079],
            [71.606160205000037, 34.159580449000032],
            [71.606993313000032, 34.159198445000072],
            [71.60723971200008, 34.158935720000045],
            [71.607295973000078, 34.158862090000071],
            [71.607797684000047, 34.158205498000029],
            [71.608230252000055, 34.157779453000046],
            [71.608383867000043, 34.157615711000062],
            [71.60855267900007, 34.157276574000036],
            [71.608680932000084, 34.156985665000036],
            [71.608725981000077, 34.156757192000043],
            [71.608734881000032, 34.156460889000073],
            [71.608712308000065, 34.156113515000072],
            [71.608773118000045, 34.156005815000071],
            [71.608948037000062, 34.155904957000075],
            [71.609125431000052, 34.155861346000052],
            [71.609224475000076, 34.155850913000052],
            [71.609348588000046, 34.155760358000066],
            [71.609482916000047, 34.155626569000049],
            [71.609548000000075, 34.155468000000042],
            [71.609575000000063, 34.155385000000024],
            [71.609604000000047, 34.155308000000048],
            [71.609620000000064, 34.155274000000077],
            [71.609637000000077, 34.155242000000044],
            [71.609660000000076, 34.155204000000026],
            [71.60968900000006, 34.155163000000073],
            [71.609804000000054, 34.155015000000049],
            [71.60995900000006, 34.154802000000075],
            [71.610005000000058, 34.154743000000053],
            [71.610047000000066, 34.154691000000071],
            [71.610123000000044, 34.154607000000055],
            [71.610212000000047, 34.154517000000055],
            [71.610299000000055, 34.154435000000035],
            [71.610498000000064, 34.154255000000035],
            [71.610583000000076, 34.154174000000069],
            [71.610735000000034, 34.154019000000062],
            [71.610775000000046, 34.153981000000044],
            [71.610813000000064, 34.153948000000071],
            [71.610907000000054, 34.153871000000038],
            [71.610975000000053, 34.15382100000005],
            [71.611039000000062, 34.153782000000035],
            [71.611070000000041, 34.153766000000076],
            [71.611100000000079, 34.153752000000054],
            [71.61112200000008, 34.153744000000074],
            [71.611149000000069, 34.153738000000033],
            [71.611242000000061, 34.153724000000068],
            [71.611275000000035, 34.153717000000029],
            [71.61130600000007, 34.153707000000054],
            [71.611330000000066, 34.153693000000032],
            [71.611350000000073, 34.153675000000078],
            [71.611368000000084, 34.153653000000077],
            [71.611383000000046, 34.153625000000034],
            [71.611397000000068, 34.153593000000058],
            [71.611407000000042, 34.153561000000025],
            [71.611416000000077, 34.153524000000061],
            [71.611442000000068, 34.153398000000038],
            [71.611452000000043, 34.153354000000036],
            [71.611465000000067, 34.153293000000076],
            [71.611475000000041, 34.153226000000075],
            [71.61148200000008, 34.153147000000047],
            [71.611492000000055, 34.152980000000071],
            [71.611497000000043, 34.152908000000025],
            [71.611507000000074, 34.152833000000044],
            [71.611520000000041, 34.15276700000004],
            [71.611555000000067, 34.15263200000004],
            [71.611585000000048, 34.152532000000065],
            [71.611602000000062, 34.152487000000065],
            [71.611619000000076, 34.152445000000057],
            [71.61163700000003, 34.152406000000042],
            [71.611656000000039, 34.152369000000078],
            [71.611680000000035, 34.152330000000063],
            [71.611709000000076, 34.152289000000053],
            [71.611822000000075, 34.152152000000058],
            [71.611864000000082, 34.152096000000029],
            [71.611907000000031, 34.152029000000027],
            [71.611951000000033, 34.151951000000054],
            [71.611990000000048, 34.151876000000073],
            [71.612076000000059, 34.151702000000057],
            [71.612117000000069, 34.151622000000032],
            [71.61215500000003, 34.151557000000025],
            [71.61219200000005, 34.151500000000055],
            [71.612271000000078, 34.151386000000059],
            [71.61233100000004, 34.151303000000041],
            [71.612387000000069, 34.151229000000058],
            [71.612441000000047, 34.151163000000054],
            [71.612495000000081, 34.151103000000035],
            [71.612549000000058, 34.15104800000006],
            [71.612603000000036, 34.15099900000007],
            [71.61265700000007, 34.150955000000067],
            [71.612699000000077, 34.150925000000029],
            [71.61272043200006, 34.150910712000041],
            [71.61274700000007, 34.150893000000053],
            [71.612915000000044, 34.150794000000076],
            [71.613076000000035, 34.150694000000044],
            [71.613141000000041, 34.150655000000029],
            [71.613211000000035, 34.150617000000068],
            [71.613275000000044, 34.150586000000033],
            [71.613329000000078, 34.150563000000034],
            [71.613386000000048, 34.15054200000003],
            [71.613444000000072, 34.150522000000024],
            [71.613505000000032, 34.150503000000072],
            [71.61358400000006, 34.150480000000073],
            [71.613655000000051, 34.150462000000061],
            [71.613721000000055, 34.15044800000004],
            [71.613783000000069, 34.15043600000007],
            [71.613842000000034, 34.150427000000036],
            [71.61389900000006, 34.150422000000049],
            [71.613954000000035, 34.150419000000056],
            [71.614006000000074, 34.150420000000054],
            [71.614045000000033, 34.150423000000046],
            [71.61408700000004, 34.150430000000028],
            [71.614134000000035, 34.15044000000006],
            [71.61427400000008, 34.150473000000034],
            [71.614318000000083, 34.15048100000007],
            [71.614358000000038, 34.15048500000006],
            [71.61446600000005, 34.150492000000042],
            [71.614527840000051, 34.150492571000029],
            [71.61458329800007, 34.150447937000024],
            [71.614669750000076, 34.15034005800004],
            [71.614781876000052, 34.150175379000075],
            [71.614913324000042, 34.149966358000029],
            [71.61498836800007, 34.149856073000024],
            [71.615097442000035, 34.149701844000049],
            [71.615107526000031, 34.149689718000047],
            [71.615238808000072, 34.149531859000035],
            [71.615374596000038, 34.149404790000062],
            [71.615556524000056, 34.149246471000026],
            [71.615563193000071, 34.149241221000068],
            [71.615801030000057, 34.149054000000035],
            [71.615982436000081, 34.148978439000075],
            [71.61612892100004, 34.148911998000074],
            [71.616420873000038, 34.148787036000044],
            [71.616679205000082, 34.148678170000039],
            [71.616872931000046, 34.148620099000027],
            [71.617159468000068, 34.148568082000054],
            [71.61743520400006, 34.148547316000077],
            [71.617785452000078, 34.148534056000074],
            [71.617811686000039, 34.148492027000032],
            [71.617717106000043, 34.148389294000026],
            [71.617410573000029, 34.148277589000031],
            [71.617161525000029, 34.148181469000065],
            [71.616973843000039, 34.148117316000025],
            [71.61690432000006, 34.147975211000073],
            [71.616845236000074, 34.147830310000074],
            [71.61698130700006, 34.147752031000039],
            [71.617453283000032, 34.147807052000076],
            [71.61818790600006, 34.148054764000051],
            [71.61873694500008, 34.148352660000057],
            [71.61879242100008, 34.148382760000061],
            [71.61928457700003, 34.148480036000024],
            [71.620290405000048, 34.148605143000054],
            [71.620746496000038, 34.14864930300007],
            [71.621487000000059, 34.14872100000008],
            [71.621533000000056, 34.148716000000036],
            [71.621583000000044, 34.148713000000043],
            [71.621637000000078, 34.148713000000043],
            [71.621740000000045, 34.148720000000026],
            [71.621979000000067, 34.148744000000079],
            [71.62207600000005, 34.148753000000056],
            [71.622176000000081, 34.148764000000028],
            [71.622281000000044, 34.14877800000005],
            [71.62239100000005, 34.148796000000061],
            [71.62262700000008, 34.148839000000066],
            [71.622948000000065, 34.148906000000068],
            [71.623059645000069, 34.148931708000077],
            [71.623100000000079, 34.148941000000036],
            [71.623436000000083, 34.149026000000049],
            [71.623586000000046, 34.149062000000072],
            [71.623639000000082, 34.149075000000039],
            [71.623698000000047, 34.149091000000055],
            [71.62395500000008, 34.149169000000029],
            [71.624017000000038, 34.149185000000045],
            [71.624073000000067, 34.149197000000072],
            [71.624143000000061, 34.149209000000042],
            [71.624209000000064, 34.149214000000029],
            [71.624271000000078, 34.149214000000029],
            [71.624331000000041, 34.149208000000044],
            [71.624424000000033, 34.149190000000033],
            [71.624452000000076, 34.14918700000004],
            [71.62448100000006, 34.149186000000043],
            [71.624544000000071, 34.149189000000035],
            [71.624607000000083, 34.149197000000072],
            [71.624768000000074, 34.149224000000061],
            [71.624816000000067, 34.149229000000048],
            [71.624858000000074, 34.149231000000043],
            [71.624909000000059, 34.149231000000043],
            [71.625285000000076, 34.149234000000035],
            [71.625388000000044, 34.14923200000004],
            [71.625481000000036, 34.149227000000053],
            [71.625575000000083, 34.149218000000076],
            [71.625662000000034, 34.149205000000052],
            [71.625743000000057, 34.14918700000004],
            [71.625818000000038, 34.149166000000037],
            [71.625903000000051, 34.149136000000055],
            [71.625988000000063, 34.14910100000003],
            [71.626074000000074, 34.149061000000074],
            [71.626158000000032, 34.149017000000072],
            [71.626240000000053, 34.148969000000079],
            [71.626320000000078, 34.148919000000035],
            [71.626396000000057, 34.148865000000058],
            [71.626468000000045, 34.148809000000028],
            [71.626535000000047, 34.148751000000061],
            [71.626598000000058, 34.14869200000004],
            [71.626655000000028, 34.14863100000008],
            [71.626708000000065, 34.148569000000066],
            [71.62675500000006, 34.148506000000054],
            [71.62679600000007, 34.148442000000045],
            [71.626832000000036, 34.148378000000037],
            [71.626862000000074, 34.14831300000003],
            [71.626885000000073, 34.148251000000073],
            [71.626904000000081, 34.14817800000003],
            [71.626920000000041, 34.148106000000041],
            [71.626952000000074, 34.14793700000007],
            [71.626970000000028, 34.147857000000045],
            [71.626987000000042, 34.147789000000046],
            [71.627024000000063, 34.147622000000069],
            [71.627046000000064, 34.147538000000054],
            [71.627060000000029, 34.147491000000059],
            [71.627075000000048, 34.147449000000051],
            [71.627092000000061, 34.147410000000036],
            [71.627109000000075, 34.14737400000007],
            [71.627117541000075, 34.147359673000039],
            [71.627140000000054, 34.147322000000031],
            [71.627178000000072, 34.147270000000049],
            [71.627222000000074, 34.147219000000064],
            [71.627275000000054, 34.147167000000024],
            [71.627323000000047, 34.147124000000076],
            [71.627380000000073, 34.147079000000076],
            [71.627569000000051, 34.146937000000037],
            [71.62763300000006, 34.146887000000049],
            [71.627689000000032, 34.146840000000054],
            [71.627738000000079, 34.146795000000054],
            [71.627794000000051, 34.146746000000064],
            [71.627872000000082, 34.146687000000043],
            [71.627954000000045, 34.146629000000075],
            [71.628112955000063, 34.146521472000074],
            [71.628158000000042, 34.146491000000026],
            [71.628251000000034, 34.146424000000025],
            [71.62832300000008, 34.146367000000055],
            [71.628377000000057, 34.146318000000065],
            [71.628404000000046, 34.146287000000029],
            [71.628423000000055, 34.146259000000043],
            [71.628435000000081, 34.146234000000049],
            [71.628437000000076, 34.14622200000008],
            [71.628438000000074, 34.146211000000051],
            [71.628437000000076, 34.146200000000078],
            [71.628434000000084, 34.146190000000047],
            [71.62842900000004, 34.14618100000007],
            [71.628422000000057, 34.146172000000035],
            [71.628403000000048, 34.146155000000078],
            [71.628375000000062, 34.146141000000057],
            [71.628360000000043, 34.146135000000072],
            [71.628341000000034, 34.14612500000004],
            [71.628328000000067, 34.146113000000071],
            [71.628322000000082, 34.146099000000049],
            [71.62832300000008, 34.146085000000028],
            [71.62833100000006, 34.146069000000068],
            [71.628345000000081, 34.146051000000057],
            [71.628366000000028, 34.146032000000048],
            [71.628394000000071, 34.146012000000042],
            [71.628428000000042, 34.14599000000004],
            [71.628469000000052, 34.145968000000039],
            [71.628569000000084, 34.145918000000052],
            [71.628693000000055, 34.145864000000074],
            [71.628865000000076, 34.145794000000024],
            [71.628902000000039, 34.145780000000059],
            [71.628941000000054, 34.145768000000032],
            [71.62902900000006, 34.145746000000031],
            [71.629112000000077, 34.145729000000074],
            [71.629334000000028, 34.145692000000054],
            [71.629391000000055, 34.145682000000079],
            [71.629456000000062, 34.145674000000042],
            [71.629710000000046, 34.145652000000041],
            [71.62981700000006, 34.145639000000074],
            [71.629874000000029, 34.145628000000045],
            [71.629926000000069, 34.145616000000075],
            [71.629975000000059, 34.145602000000054],
            [71.630020000000059, 34.14558500000004],
            [71.630063000000064, 34.145565000000033],
            [71.630108000000064, 34.145542000000034],
            [71.630153000000064, 34.145515000000046],
            [71.630201000000056, 34.14548300000007],
            [71.630286000000069, 34.145419000000061],
            [71.630473000000052, 34.145269000000042],
            [71.630557000000067, 34.145208000000025],
            [71.630612882000037, 34.14517088100007],
            [71.630615813000077, 34.145168935000072],
            [71.630694000000062, 34.145117000000027],
            [71.631043000000034, 34.144895000000076],
            [71.631141000000071, 34.144829000000072],
            [71.631227000000081, 34.144768000000056],
            [71.631291000000033, 34.144719000000066],
            [71.631355000000042, 34.144665000000032],
            [71.631421000000046, 34.14460500000007],
            [71.631490000000042, 34.144538000000068],
            [71.631551000000059, 34.144476000000054],
            [71.631617000000062, 34.144405000000063],
            [71.631844000000058, 34.144151000000079],
            [71.631919688000039, 34.14406858500007],
            [71.631934000000058, 34.144053000000042],
            [71.632028000000048, 34.14395600000006],
            [71.632116000000053, 34.143873000000042],
            [71.632167000000038, 34.143829000000039],
            [71.63221800000008, 34.143788000000029],
            [71.632268000000067, 34.143750000000068],
            [71.632318000000055, 34.14371600000004],
            [71.632368000000042, 34.143684000000064],
            [71.632419000000084, 34.143656000000078],
            [71.632469000000071, 34.143631000000028],
            [71.632520000000056, 34.143608000000029],
            [71.63259400000004, 34.143579000000045],
            [71.632666000000029, 34.143552000000057],
            [71.632736000000079, 34.14352800000006],
            [71.632804000000078, 34.143507000000056],
            [71.63287100000008, 34.143489000000045],
            [71.632936000000029, 34.143474000000026],
            [71.632999000000041, 34.143461000000059],
            [71.633061000000055, 34.143451000000027],
            [71.63319800000005, 34.143429000000026],
            [71.633267000000046, 34.143422000000044],
            [71.633301000000074, 34.143421000000046],
            [71.633332000000053, 34.143422000000044],
            [71.633361000000036, 34.143426000000034],
            [71.633389000000079, 34.143432000000075],
            [71.633442000000059, 34.14344600000004],
            [71.633474000000035, 34.143458000000066],
            [71.633501000000081, 34.143472000000031],
            [71.633522000000085, 34.143489000000045],
            [71.633530000000064, 34.143500000000074],
            [71.633547000000078, 34.143527000000063],
            [71.633556000000056, 34.143537000000038],
            [71.633575000000064, 34.143549000000064],
            [71.633603000000051, 34.143561000000034],
            [71.633633000000032, 34.143570000000068],
            [71.633643000000063, 34.143571000000065],
            [71.633648000000051, 34.143570000000068],
            [71.633648000000051, 34.143567000000075],
            [71.633643000000063, 34.143561000000034],
            [71.633597000000066, 34.143517000000031],
            [71.633579000000054, 34.143494000000032],
            [71.633567000000085, 34.143473000000029],
            [71.63356200000004, 34.143460000000061],
            [71.63356200000004, 34.143453000000079],
            [71.633565000000033, 34.14344600000004],
            [71.633572000000072, 34.143440000000055],
            [71.633582000000047, 34.143435000000068],
            [71.633605000000045, 34.143428000000029],
            [71.63366300000007, 34.143414000000064],
            [71.633683000000076, 34.143408000000079],
            [71.633822000000066, 34.143354000000045],
            [71.633858000000032, 34.143337000000031],
            [71.63388800000007, 34.143319000000076],
            [71.633952000000079, 34.143273000000079],
            [71.633979000000068, 34.14325000000008],
            [71.634003000000064, 34.143227000000024],
            [71.634006081000052, 34.143223773000045],
            [71.634024000000068, 34.14320500000008],
            [71.634043000000077, 34.143182000000024],
            [71.634058000000039, 34.143158000000028],
            [71.634071000000063, 34.143135000000029],
            [71.63408400000003, 34.143106000000046],
            [71.634094000000061, 34.14307800000006],
            [71.634101000000044, 34.143051000000071],
            [71.634105000000034, 34.143026000000077],
            [71.634105000000034, 34.143005000000073],
            [71.634101000000044, 34.142981000000077],
            [71.63407600000005, 34.142904000000044],
            [71.634069000000068, 34.142880000000048],
            [71.634067000000073, 34.142860000000042],
            [71.63406800000007, 34.14284200000003],
            [71.63407200000006, 34.142829000000063],
            [71.634078000000045, 34.142817000000036],
            [71.634088000000077, 34.142804000000069],
            [71.634100000000046, 34.142792000000043],
            [71.634128000000032, 34.142770000000041],
            [71.634200000000078, 34.142721000000051],
            [71.634229000000062, 34.14269500000006],
            [71.634240000000034, 34.142682000000036],
            [71.634247000000073, 34.142669000000069],
            [71.634251000000063, 34.142656000000045],
            [71.63425200000006, 34.142643000000078],
            [71.634249000000068, 34.142625000000066],
            [71.634237000000041, 34.142585000000054],
            [71.634234000000049, 34.142567000000042],
            [71.634234000000049, 34.142550000000028],
            [71.634235000000047, 34.142534000000069],
            [71.634240000000034, 34.142518000000052],
            [71.634246000000076, 34.142501000000038],
            [71.634254000000055, 34.142485000000079],
            [71.634265000000084, 34.142469000000062],
            [71.634294000000068, 34.142436000000032],
            [71.634327000000042, 34.142407000000048],
            [71.634369000000049, 34.14237600000007],
            [71.634538000000077, 34.142268000000058],
            [71.634575000000041, 34.142244000000062],
            [71.634616000000051, 34.142222000000061],
            [71.634660000000054, 34.142201000000057],
            [71.634709000000043, 34.142182000000048],
            [71.634803000000034, 34.142151000000069],
            [71.635036000000071, 34.142084000000068],
            [71.63509700000003, 34.142063000000064],
            [71.635151000000064, 34.142041000000063],
            [71.635209000000032, 34.142014000000074],
            [71.635274000000038, 34.141983000000039],
            [71.635547000000031, 34.141841000000056],
            [71.635613000000035, 34.141809000000023],
            [71.635674000000051, 34.141783000000032],
            [71.635735000000068, 34.141760000000033],
            [71.635804000000064, 34.141737000000035],
            [71.63602700000007, 34.141672000000028],
            [71.636101000000053, 34.141649000000029],
            [71.636167000000057, 34.141626000000031],
            [71.636226000000079, 34.141602000000034],
            [71.636258000000055, 34.141585000000077],
            [71.636331000000041, 34.14153900000008],
            [71.636363000000074, 34.141522000000066],
            [71.636380000000031, 34.141515000000027],
            [71.636396000000047, 34.141510000000039],
            [71.636410000000069, 34.141508000000044],
            [71.636424000000034, 34.141509000000042],
            [71.636435000000063, 34.141512000000034],
            [71.636447000000032, 34.141518000000076],
            [71.636474000000078, 34.14153900000008],
            [71.636507000000051, 34.141572000000053],
            [71.636569000000065, 34.141646000000037],
            [71.636581000000035, 34.141656000000069],
            [71.636584000000084, 34.141658000000064],
            [71.636587000000077, 34.141657000000066],
            [71.636588000000074, 34.141650000000027],
            [71.636584000000084, 34.141636000000062],
            [71.636545000000069, 34.141555000000039],
            [71.63653000000005, 34.141516000000024],
            [71.63652200000007, 34.141485000000046],
            [71.636521000000073, 34.141473000000076],
            [71.63652200000007, 34.141462000000047],
            [71.636527000000058, 34.14144600000003],
            [71.636535000000038, 34.141430000000071],
            [71.636547000000064, 34.141414000000054],
            [71.636562000000083, 34.14139700000004],
            [71.636595000000057, 34.141368000000057],
            [71.636666000000048, 34.141315000000077],
            [71.636693000000037, 34.141293000000076],
            [71.636745000000076, 34.141248000000076],
            [71.636801000000048, 34.141204000000073],
            [71.636859000000072, 34.141161000000068],
            [71.636921000000029, 34.141118000000063],
            [71.636987000000033, 34.141071000000068],
            [71.63700700000004, 34.141059000000041],
            [71.637026000000048, 34.141051000000061],
            [71.637055000000032, 34.14104100000003],
            [71.637075000000038, 34.14103700000004],
            [71.637087000000065, 34.141038000000037],
            [71.63710100000003, 34.141043000000025],
            [71.637118000000044, 34.141053000000056],
            [71.637164000000041, 34.141085000000032],
            [71.637178000000063, 34.141091000000074],
            [71.637189000000035, 34.141091000000074],
            [71.637196000000074, 34.141089000000079],
            [71.637202000000059, 34.141084000000035],
            [71.637215000000083, 34.14107000000007],
            [71.637225000000058, 34.141050000000064],
            [71.637252000000046, 34.140990000000045],
            [71.63726100000008, 34.140975000000026],
            [71.637271000000055, 34.140963000000056],
            [71.637290000000064, 34.140946000000042],
            [71.637313000000063, 34.14093100000008],
            [71.637339000000054, 34.140918000000056],
            [71.637369000000035, 34.14090600000003],
            [71.637400000000071, 34.140896000000055],
            [71.637437000000034, 34.140887000000077],
            [71.637540000000058, 34.140868000000069],
            [71.637571000000037, 34.140865000000076],
            [71.637649000000067, 34.140862000000027],
            [71.637669000000074, 34.140859000000034],
            [71.637687000000028, 34.140855000000045],
            [71.637705000000039, 34.140848000000062],
            [71.637723000000051, 34.140839000000028],
            [71.637745000000052, 34.140825000000063],
            [71.637809000000061, 34.140779000000066],
            [71.637830000000065, 34.140767000000039],
            [71.637849000000074, 34.140760000000057],
            [71.637895000000071, 34.140750000000025],
            [71.637948000000051, 34.140746000000036],
            [71.638002000000029, 34.140748000000031],
            [71.638055000000065, 34.14075500000007],
            [71.638103000000058, 34.140767000000039],
            [71.638143000000071, 34.140784000000053],
            [71.638160000000084, 34.140793000000031],
            [71.638175000000047, 34.14080400000006],
            [71.638188000000071, 34.140816000000029],
            [71.638198000000045, 34.140828000000056],
            [71.638205000000085, 34.14084100000008],
            [71.638212000000067, 34.140858000000037],
            [71.638220000000047, 34.140895000000057],
            [71.638225000000034, 34.140880000000038],
            [71.638231000000076, 34.140869000000066],
            [71.638246000000038, 34.140854000000047],
            [71.638266000000044, 34.140840000000026],
            [71.638293000000033, 34.140827000000058],
            [71.638336000000038, 34.14080800000005],
            [71.638358000000039, 34.14080000000007],
            [71.638382000000036, 34.140793000000031],
            [71.638437000000067, 34.140783000000056],
            [71.638495000000034, 34.140778000000068],
            [71.638605000000041, 34.140774000000079],
            [71.638648000000046, 34.140773000000024],
            [71.638976398000068, 34.14074037000006],
            [71.639684349000049, 34.140703109000071],
            [71.640071317000036, 34.14065731900007],
            [71.64026917800004, 34.140634886000043],
            [71.640433581000082, 34.140585225000052],
            [71.640630466000061, 34.140535389000036],
            [71.640816746000041, 34.140468146000046],
            [71.640916000000061, 34.140432000000033],
            [71.641036000000042, 34.14037200000007],
            [71.641161000000068, 34.140305000000069],
            [71.641222000000084, 34.140269000000046],
            [71.641288000000031, 34.140226000000041],
            [71.641499000000067, 34.140075000000024],
            [71.641575000000046, 34.140023000000042],
            [71.641650000000084, 34.139977000000044],
            [71.64171900000008, 34.139941000000078],
            [71.641769000000068, 34.139919000000077],
            [71.641820000000052, 34.139899000000071],
            [71.641872000000035, 34.139881000000059],
            [71.641925000000072, 34.139865000000043],
            [71.642129866000062, 34.139781510000034],
            [71.642309781000051, 34.139718493000032],
            [71.64249318800006, 34.139658574000066],
            [71.642690705000064, 34.139627530000041],
            [71.642924769000047, 34.139632263000067],
            [71.643157271000064, 34.139658438000026],
            [71.643367820000037, 34.139710755000067],
            [71.643617794000079, 34.139833147000047],
            [71.64417306200005, 34.140108344000055],
            [71.644656224000073, 34.14038447300004],
            [71.644664695000074, 34.14038931500005],
            [71.644891679000068, 34.14056927300004],
            [71.644913793000057, 34.140586806000044],
            [71.645326469000054, 34.140923991000079],
            [71.645525988000031, 34.141068822000079],
            [71.645703008000055, 34.141180385000041],
            [71.645913632000031, 34.141281132000074],
            [71.646208312000056, 34.141397892000043],
            [71.646573026000056, 34.14150169100003],
            [71.646813000000066, 34.141536000000031],
            [71.647292678000042, 34.141600700000026],
            [71.647618421000061, 34.141626949000056],
            [71.647974939000051, 34.141596010000058],
            [71.647986423000077, 34.141590300000075],
            [71.64854465500008, 34.141556372000025],
            [71.648829935000037, 34.141486240000063],
            [71.649141456000052, 34.141417355000044],
            [71.649395067000057, 34.141335353000045],
            [71.649704712000073, 34.141261763000045],
            [71.649887009000054, 34.141174799000055],
            [71.64994749300007, 34.141072582000049],
            [71.650009177000072, 34.140844066000056],
            [71.649989753000057, 34.140619981000043],
            [71.649987185000043, 34.140477049000026],
            [71.649541473000056, 34.140516587000036],
            [71.649426290000065, 34.140506830000049],
            [71.649271940000062, 34.140443868000034],
            [71.649315621000028, 34.140304683000068],
            [71.649414784000044, 34.140272275000029],
            [71.649537037000073, 34.140316269000039],
            [71.649805000000072, 34.140197000000057],
            [71.649907000000042, 34.140101000000072],
            [71.650006000000076, 34.140002000000038],
            [71.650030623000077, 34.139821276000077],
            [71.650065118000043, 34.139655749000042],
            [71.650133763000042, 34.139470939000034],
            [71.650230278000038, 34.139316313000052],
            [71.650388024000051, 34.139161910000041],
            [71.650625970000078, 34.138980815000025],
            [71.650793000000078, 34.138930000000073],
            [71.651565318000053, 34.138719704000039],
            [71.651850449000051, 34.138645562000079],
            [71.652070396000056, 34.138515956000049],
            [71.652353701000038, 34.138194031000069],
            [71.652731509000034, 34.137829673000056],
            [71.65273938200005, 34.137816660000055],
            [71.653003697000031, 34.137531667000076],
            [71.65301088800004, 34.137523914000042],
            [71.65325542800008, 34.137207821000061],
            [71.65355155900005, 34.136995565000063],
            [71.653763853000044, 34.136826603000031],
            [71.654053000000033, 34.136678000000074],
            [71.654203000000052, 34.136707000000058],
            [71.65434700000003, 34.136740000000032],
            [71.654386000000045, 34.136752000000058],
            [71.654473000000053, 34.136784000000034],
            [71.654512000000068, 34.136796000000061],
            [71.654551000000083, 34.136804000000041],
            [71.654585000000054, 34.136805000000038],
            [71.654602000000068, 34.136802000000046],
            [71.654619000000082, 34.136798000000056],
            [71.65465400000005, 34.136783000000037],
            [71.654690000000073, 34.136760000000038],
            [71.654727000000037, 34.136729000000059],
            [71.654762000000062, 34.136692000000039],
            [71.654752750000057, 34.136594385000024],
            [71.654805377000059, 34.136441287000025],
            [71.654952064000042, 34.136318077000055],
            [71.65521571000005, 34.136229713000034],
            [71.655332965000071, 34.136112486000059],
            [71.655577932000028, 34.135974641000075],
            [71.655964389000076, 34.135884250000061],
            [71.656456528000035, 34.135957162000068],
            [71.656891845000075, 34.135721691000072],
            [71.657436776000054, 34.135460427000055],
            [71.658167081000045, 34.135128162000058],
            [71.658790766000038, 34.134821166000052],
            [71.658807421000063, 34.134815632000027],
            [71.659176399000046, 34.134688781000079],
            [71.659648483000069, 34.134437944000069],
            [71.660454394000055, 34.133888389000049],
            [71.660853510000038, 34.133672244000024],
            [71.661354006000067, 34.133250894000071],
            [71.662026164000054, 34.132439022000028],
            [71.662345608000066, 34.132182065000052],
            [71.662475000000029, 34.132086000000072],
            [71.66254900000007, 34.132095000000049],
            [71.662713000000053, 34.132111000000066],
            [71.66293330600007, 34.132099284000049],
            [71.663396649000049, 34.132102324000073],
            [71.663537852000047, 34.131984688000045],
            [71.663703877000046, 34.131878242000028],
            [71.664086270000041, 34.131805329000031],
            [71.664302152000062, 34.131727493000028],
            [71.664518490000034, 34.131607808000069],
            [71.66476859100004, 34.131518726000024],
            [71.665020675000051, 34.131458173000055],
            [71.665215455000066, 34.131424104000075],
            [71.665280060000043, 34.131461923000074],
            [71.665462183000045, 34.131581733000075],
            [71.665624957000034, 34.131585648000055],
            [71.665747911000039, 34.13152539400005],
            [71.666071614000032, 34.131555419000051],
            [71.66631822800008, 34.131584432000068],
            [71.66654329000005, 34.131580590000056],
            [71.666823841000053, 34.131592598000054],
            [71.66700038700003, 34.131594001000053],
            [71.66718435100006, 34.131624274000046],
            [71.667306176000068, 34.131696625000075],
            [71.66748240700008, 34.131787317000033],
            [71.667647386000056, 34.131810629000029],
            [71.667838337000035, 34.131788090000043],
            [71.668046000000061, 34.131738000000041],
            [71.668173000000081, 34.131718000000035],
            [71.668289000000073, 34.131702000000075],
            [71.66839600000003, 34.131690000000049],
            [71.668497000000059, 34.131682000000069],
            [71.668701000000056, 34.131666000000052],
            [71.668915000000084, 34.131646000000046],
            [71.669108182000059, 34.131644630000039],
            [71.669182034000073, 34.131644107000056],
            [71.669307764000052, 34.131665346000034],
            [71.669541089000063, 34.131704762000027],
            [71.669931854000083, 34.131780080000055],
            [71.670336354000028, 34.131714310000064],
            [71.670761998000046, 34.131559453000079],
            [71.67138531300003, 34.131338835000065],
            [71.671828579000078, 34.131089818000078],
            [71.672174985000083, 34.130922475000034],
            [71.672630512000069, 34.130602244000045],
            [71.672827838000046, 34.130475110000077],
            [71.673101041000052, 34.130425242000058],
            [71.673364032000052, 34.130304195000065],
            [71.673580989000072, 34.130267227000047],
            [71.673919332000082, 34.130337429000065],
            [71.674254253000072, 34.130515973000058],
            [71.674591396000039, 34.13064620800003],
            [71.67509467900004, 34.130909131000067],
            [71.675449696000044, 34.13099705500008],
            [71.675993101000074, 34.131135923000045],
            [71.676581546000079, 34.131329272000073],
            [71.677371618000052, 34.131470767000053],
            [71.677710189000038, 34.131531233000032],
            [71.677906663000044, 34.131465087000038],
            [71.678167332000044, 34.131395206000036],
            [71.678661459000068, 34.131322770000054],
            [71.679033696000033, 34.131251242000076],
            [71.679199467000046, 34.131114290000028],
            [71.679439179000042, 34.130651427000032],
            [71.679458228000044, 34.130451288000074],
            [71.679597874000081, 34.130262626000047],
            [71.679889005000064, 34.130077198000038],
            [71.680377217000057, 34.129522503000032],
            [71.68073471200006, 34.129198857000063],
            [71.681136448000075, 34.128842022000072],
            [71.681478706000064, 34.128477733000068],
            [71.681916478000062, 34.128127246000076],
            [71.682186006000052, 34.127886143000069],
            [71.682239328000037, 34.12775335300006],
            [71.68230439000007, 34.127606821000029],
            [71.682816028000047, 34.127503800000056],
            [71.683100469000067, 34.127468285000077],
            [71.683288147000042, 34.127712143000053],
            [71.683440571000062, 34.127996354000061],
            [71.683681809000063, 34.128185343000041],
            [71.683998161000034, 34.128297051000061],
            [71.684463374000075, 34.128283245000034],
            [71.685233452000034, 34.128398269000058],
            [71.685636128000056, 34.128482783000038],
            [71.685934283000051, 34.128496249000079],
            [71.686374924000063, 34.128511702000026],
            [71.686511169000084, 34.128590357000064],
            [71.686568218000048, 34.128693877000046],
            [71.686512022000045, 34.128812414000038],
            [71.686482660000081, 34.128918079000073],
            [71.686496432000069, 34.129000906000044],
            [71.68657825300005, 34.129105689000028],
            [71.686654815000054, 34.129152700000077],
            [71.686743000000035, 34.129180000000076],
            [71.686764000000039, 34.129188000000056],
            [71.686788000000035, 34.129196000000036],
            [71.686867000000063, 34.129212000000052],
            [71.686916000000053, 34.12922100000003],
            [71.686967000000038, 34.129227000000071],
            [71.68702200000007, 34.129231000000061],
            [71.687081000000035, 34.129232000000059],
            [71.687192000000039, 34.129228000000069],
            [71.687454000000059, 34.129212000000052],
            [71.687592000000052, 34.12920500000007],
            [71.687669000000028, 34.12920500000007],
            [71.687707000000046, 34.12920100000008],
            [71.687846437000076, 34.129212797000037],
            [71.687938838000036, 34.129220615000065],
            [71.68806161100008, 34.129208175000031],
            [71.688110158000029, 34.129203256000039],
            [71.688240994000068, 34.129167719000066],
            [71.688324651000073, 34.129144997000026],
            [71.688481662000072, 34.129079665000063],
            [71.688768935000041, 34.128862803000061],
            [71.689016898000034, 34.128693375000068],
            [71.68953003300004, 34.128389825000056],
            [71.690076588000068, 34.128088998000067],
            [71.690383775000043, 34.127928775000044],
            [71.690695000000062, 34.127783000000079],
            [71.690722000000051, 34.127778000000035],
            [71.690750000000037, 34.12776800000006],
            [71.690779000000077, 34.127755000000036],
            [71.69083100000006, 34.127724000000057],
            [71.691155251000055, 34.127565829000048],
            [71.69144704100006, 34.127416587000027],
            [71.691809000000035, 34.127255000000048],
            [71.691870000000051, 34.127230000000054],
            [71.691925000000083, 34.127204000000063],
            [71.691976000000068, 34.127178000000072],
            [71.69202400000006, 34.127151000000026],
            [71.692109000000073, 34.127095000000054],
            [71.692304000000036, 34.126953000000071],
            [71.692394000000036, 34.126893000000052],
            [71.692510000000084, 34.126825000000053],
            [71.692641000000037, 34.126756000000057],
            [71.692787000000067, 34.126686000000063],
            [71.693012000000067, 34.126582000000042],
            [71.693051000000082, 34.126565000000028],
            [71.693096000000082, 34.126549000000068],
            [71.693108611000071, 34.126545178000072],
            [71.693294000000037, 34.126489000000049],
            [71.693339000000037, 34.12647400000003],
            [71.693378000000052, 34.126459000000068],
            [71.693422000000055, 34.126438000000064],
            [71.693460000000073, 34.126416000000063],
            [71.693494000000044, 34.126392000000067],
            [71.693524000000082, 34.126365000000078],
            [71.693547000000081, 34.12633900000003],
            [71.693672516000049, 34.12625752200006],
            [71.693923075000043, 34.126170306000063],
            [71.69410958900005, 34.126092533000076],
            [71.694291000000078, 34.126017000000047],
            [71.694466000000034, 34.125991000000056],
            [71.694780596000044, 34.125834256000076],
            [71.694839664000028, 34.125701553000056],
            [71.694978018000029, 34.125540583000031],
            [71.695182095000064, 34.125318544000038],
            [71.695404692000068, 34.12517364100006],
            [71.695739600000081, 34.125022115000036],
            [71.695888072000059, 34.124852797000074],
            [71.69626468000007, 34.124327492000077],
            [71.696516593000069, 34.124140306000072],
            [71.696696298000063, 34.123930038000026],
            [71.697375166000029, 34.123449046000076],
            [71.697714981000047, 34.123235648000048],
            [71.698058872000047, 34.123027622000052],
            [71.698209186000042, 34.122838524000031],
            [71.698101253000061, 34.122599498000056],
            [71.697912788000053, 34.122377733000064],
            [71.697872853000035, 34.121988438000074],
            [71.697788202000083, 34.121693712000024],
            [71.697837291000042, 34.121477191000054],
            [71.698029105000046, 34.121006674000057],
            [71.698151494000058, 34.12056805900005],
            [71.698150498000075, 34.120213020000051],
            [71.698254185000053, 34.119846534000033],
            [71.698422865000055, 34.119459302000053],
            [71.698607910000078, 34.11898436000007],
            [71.698860923000041, 34.118595182000035],
            [71.699481678000041, 34.117980825000075],
            [71.69977173400008, 34.117599812000037],
            [71.700086937000037, 34.117200225000033],
            [71.700323924000031, 34.11705340900005],
            [71.700465738000048, 34.116865464000057],
            [71.700635000000034, 34.116662000000076],
            [71.700712000000067, 34.116575000000068],
            [71.700759000000062, 34.116513000000054],
            [71.700788000000045, 34.116466000000059],
            [71.700814000000037, 34.116415000000075],
            [71.700837000000035, 34.116358000000048],
            [71.700857000000042, 34.116295000000036],
            [71.700873000000058, 34.116237000000069],
            [71.700888000000077, 34.116171000000065],
            [71.700928000000033, 34.115957000000037],
            [71.700943000000052, 34.115884000000051],
            [71.700965000000053, 34.115778000000034],
            [71.700986000000057, 34.11566700000003],
            [71.701005000000066, 34.115547000000049],
            [71.701024000000075, 34.115417000000036],
            [71.70103400000005, 34.115333000000078],
            [71.701040000000035, 34.115245000000073],
            [71.701043000000084, 34.11515200000008],
            [71.701044000000081, 34.115052000000048],
            [71.70103800000004, 34.114867000000061],
            [71.701015000000041, 34.114431000000025],
            [71.701012000000048, 34.114318000000026],
            [71.701011000000051, 34.114216000000056],
            [71.701015000000041, 34.114114000000029],
            [71.70102200000008, 34.114019000000042],
            [71.701032000000055, 34.113928000000044],
            [71.701047000000074, 34.113841000000036],
            [71.701072000000067, 34.113713000000075],
            [71.701088000000084, 34.113636000000042],
            [71.701106000000038, 34.113566000000048],
            [71.701126000000045, 34.113504000000034],
            [71.701146000000051, 34.113450000000057],
            [71.701168000000052, 34.11339600000008],
            [71.701192000000049, 34.113343000000043],
            [71.70121800000004, 34.113290000000063],
            [71.701246000000083, 34.113238000000024],
            [71.701276000000064, 34.113187000000039],
            [71.701307000000043, 34.113137000000052],
            [71.701340000000073, 34.113088000000062],
            [71.701374000000044, 34.113042000000064],
            [71.701410000000067, 34.112996000000066],
            [71.701446000000033, 34.112953000000061],
            [71.70148400000005, 34.112912000000051],
            [71.701522000000068, 34.112872000000038],
            [71.701562000000081, 34.112835000000075],
            [71.701602000000037, 34.11280000000005],
            [71.701643000000047, 34.112768000000074],
            [71.701710000000048, 34.112721000000079],
            [71.701788000000079, 34.112674000000027],
            [71.701878000000079, 34.112626000000034],
            [71.701995000000068, 34.112570000000062],
            [71.702038000000073, 34.112548000000061],
            [71.702142000000038, 34.112486000000047],
            [71.702195000000074, 34.112461000000053],
            [71.702224000000058, 34.112451000000078],
            [71.702252000000044, 34.112446000000034],
            [71.702278000000035, 34.112445000000037],
            [71.702302000000032, 34.112448000000029],
            [71.702318000000048, 34.112453000000073],
            [71.702347427000063, 34.112435671000071],
            [71.702384792000032, 34.112265299000057],
            [71.702410253000039, 34.112071729000036],
            [71.702417947000072, 34.111907874000053],
            [71.702428560000044, 34.111727877000078],
            [71.702464009000039, 34.111485807000065],
            [71.702437000000032, 34.111366000000032],
            [71.702403000000061, 34.111298000000033],
            [71.702368000000035, 34.111237000000074],
            [71.702331000000072, 34.111181000000045],
            [71.702292000000057, 34.111131000000057],
            [71.702253000000042, 34.111088000000052],
            [71.702212000000031, 34.111051000000032],
            [71.702183000000048, 34.111030000000028],
            [71.702151000000072, 34.111011000000076],
            [71.702025546000073, 34.11090082700008],
            [71.701838021000071, 34.110714105000056],
            [71.701618210000049, 34.110484431000032],
            [71.701521281000055, 34.110323875000063],
            [71.701446740000051, 34.110057021000046],
            [71.701423499000043, 34.109834371000034],
            [71.701470239000059, 34.109485629000062],
            [71.701514590000045, 34.109298307000074],
            [71.701528907000068, 34.109156687000052],
            [71.701542840000059, 34.108956005000039],
            [71.70155928500003, 34.108822745000055],
            [71.701577401000065, 34.108520303000034],
            [71.701607008000053, 34.108240023000064],
            [71.701813184000059, 34.108037211000067],
            [71.701938718000065, 34.107809932000066],
            [71.702046077000034, 34.107505680000031],
            [71.702128780000066, 34.107241167000041],
            [71.702343881000047, 34.106773402000044],
            [71.702603278000083, 34.106372369000042],
            [71.702545637000071, 34.106012786000065],
            [71.702373112000032, 34.105424802000073],
            [71.70218251600005, 34.10475143900004],
            [71.702094102000046, 34.104548459000057],
            [71.702095326000062, 34.104265546000079],
            [71.702083075000075, 34.103970118000063],
            [71.702004929000054, 34.103548753000041],
            [71.70191059900003, 34.103263825000056],
            [71.701786717000061, 34.102889634000064],
            [71.701741704000028, 34.102721340000073],
            [71.701647798000067, 34.102413372000058],
            [71.701378400000067, 34.101816601000053],
            [71.701142947000051, 34.10107690500007],
            [71.701120045000039, 34.100858887000072],
            [71.701162775000057, 34.100433382000062],
            [71.70118302000003, 34.100213429000064],
            [71.701194251000061, 34.100091406000047],
            [71.701204795000081, 34.099976845000072],
            [71.701205498000036, 34.09996921100003],
            [71.70121570200007, 34.09973506800003],
            [71.701248305000036, 34.09898696700003],
            [71.701265890000059, 34.098443948000067],
            [71.701319219000084, 34.098275116000025],
            [71.701456992000033, 34.098135398000068],
            [71.701667024000074, 34.097961244000032],
            [71.701914161000047, 34.09775017700008],
            [71.702052840000079, 34.097544413000037],
            [71.702246161000062, 34.097273088000065],
            [71.702354626000044, 34.097066512000026],
            [71.702391571000078, 34.096947764000049],
            [71.702391336000062, 34.096802227000069],
            [71.702404594000029, 34.096645093000063],
            [71.702463148000049, 34.096544109000035],
            [71.702621727000064, 34.096301541000059],
            [71.703019217000076, 34.094645958000058],
            [71.703408006000075, 34.093983674000071],
            [71.70387558200008, 34.093289599000059],
            [71.703292626000064, 34.09210472500007],
            [71.701972554000065, 34.09198869800008],
            [71.70063245800003, 34.091960574000041],
            [71.700151318000053, 34.092131202000076],
            [71.699706641000034, 34.092204694000031],
            [71.699375183000029, 34.092111755000076],
            [71.699276571000041, 34.091660266000076],
            [71.699801146000084, 34.091288457000076],
            [71.700754184000061, 34.090712986000028],
            [71.702172158000053, 34.090301728000043],
            [71.702906383000084, 34.089984989000072],
            [71.704251370000065, 34.089763333000064],
            [71.704892089000055, 34.089767744000028],
            [71.705507745000034, 34.089784816000076],
            [71.705653122000058, 34.089721408000059],
            [71.705877894000082, 34.089551701000062],
            [71.70620213400008, 34.088795878000042],
            [71.70650260900004, 34.088401365000038],
            [71.706810478000079, 34.087988649000067],
            [71.707127735000029, 34.087636630000077],
            [71.707653566000033, 34.08729300400006],
            [71.708089392000034, 34.087104688000068],
            [71.708762524000065, 34.086936749000074],
            [71.709108682000078, 34.086999564000052],
            [71.709395746000041, 34.087002700000028],
            [71.709708901000056, 34.086902850000058],
            [71.710315741000045, 34.086614286000042],
            [71.710693891000062, 34.086529134000045],
            [71.711199523000062, 34.086452466000026],
            [71.711453635000055, 34.086490771000058],
            [71.711911240000063, 34.086604503000046],
            [71.712557704000062, 34.086713064000037],
            [71.713262260000079, 34.086778041000059],
            [71.713494531000038, 34.086749620000035],
            [71.71356667200007, 34.08664946600004],
            [71.713586403000079, 34.086398426000073],
            [71.713444444000061, 34.085282437000046],
            [71.713257698000064, 34.084537411000042],
            [71.713037141000029, 34.083897440000044],
            [71.71273898000004, 34.083061666000049],
            [71.712577042000078, 34.082712841000046],
            [71.712261799000032, 34.082033784000032],
            [71.711974812000051, 34.081079415000033],
            [71.711780967000038, 34.080158706000077],
            [71.711623958000075, 34.079565652000042],
            [71.711229149000076, 34.078679935000025],
            [71.710725787000058, 34.078059727000038],
            [71.710616105000042, 34.077805410000053],
            [71.710560684000029, 34.077564898000048],
            [71.710445178000043, 34.077342113000043],
            [71.710522548000029, 34.076946568000039],
            [71.710676270000079, 34.076741967000032],
            [71.710716000000048, 34.076733000000047],
            [71.710744042000044, 34.076665879000075],
            [71.711181599000042, 34.076524382000059],
            [71.711310034000064, 34.07670078700005],
            [71.711527731000047, 34.077057340000067],
            [71.711739300000033, 34.077535539000053],
            [71.711989882000069, 34.078119383000057],
            [71.711997478000058, 34.078131949000067],
            [71.712434288000054, 34.078877282000064],
            [71.71271517200006, 34.079500929000062],
            [71.713018127000055, 34.080286123000064],
            [71.713239064000049, 34.08103101100005],
            [71.71361091700004, 34.082112711000036],
            [71.714095580000048, 34.083541557000046],
            [71.714253231000043, 34.084256406000065],
            [71.714602321000029, 34.08534615700006],
            [71.714955694000082, 34.08648026000003],
            [71.715287518000082, 34.086907954000026],
            [71.71578571200007, 34.087067533000038],
            [71.716673753000066, 34.087216073000036],
            [71.716779110000061, 34.087247047000062],
            [71.717712360000064, 34.087521414000037],
            [71.71837122900007, 34.087788652000029],
            [71.718988555000067, 34.087804577000043],
            [71.71958838300003, 34.087820050000062],
            [71.72016029100007, 34.087836361000029],
            [71.72172944600004, 34.087660559000028],
            [71.723192515000051, 34.087736631000041],
            [71.725232341000037, 34.087939977000076],
            [71.726630754000041, 34.088148760000024],
            [71.727404763000038, 34.08867243900005],
            [71.728461000000038, 34.089081000000078],
            [71.728578000000084, 34.089068000000054],
            [71.728686000000039, 34.089057000000025],
            [71.728789000000063, 34.089048000000048],
            [71.728886000000045, 34.089043000000061],
            [71.729001000000039, 34.089039000000071],
            [71.729111000000046, 34.089040000000068],
            [71.729216000000065, 34.089044000000058],
            [71.729317000000037, 34.089052000000038],
            [71.729415000000074, 34.089065000000062],
            [71.729510000000062, 34.089081000000078],
            [71.729602000000057, 34.089101000000028],
            [71.729691000000059, 34.089125000000024],
            [71.729846000000066, 34.089172000000076],
            [71.729950000000031, 34.089205000000049],
            [71.730045000000075, 34.089239000000077],
            [71.730129000000034, 34.089273000000048],
            [71.730208000000061, 34.089309000000071],
            [71.73028000000005, 34.089347000000032],
            [71.730345000000057, 34.089387000000045],
            [71.730404000000078, 34.08943000000005],
            [71.730427000000077, 34.089450000000056],
            [71.730450000000076, 34.089473000000055],
            [71.730474000000072, 34.089499000000046],
            [71.730497000000071, 34.089530000000025],
            [71.730539000000078, 34.089590000000044],
            [71.730634000000066, 34.089739000000066],
            [71.730660000000057, 34.089777000000026],
            [71.730685000000051, 34.089808000000062],
            [71.730713000000037, 34.08983900000004],
            [71.73074100000008, 34.089865000000032],
            [71.730770000000064, 34.089885000000038],
            [71.730799000000047, 34.089901000000054],
            [71.730833000000075, 34.089913000000024],
            [71.730870000000039, 34.089921000000061],
            [71.730911000000049, 34.089925000000051],
            [71.730956000000049, 34.089924000000053],
            [71.730999000000054, 34.089920000000063],
            [71.731048000000044, 34.089913000000024],
            [71.731232000000034, 34.089876000000061],
            [71.731309000000067, 34.089864000000034],
            [71.73134900000008, 34.089860000000044],
            [71.731386000000043, 34.089858000000049],
            [71.731421000000068, 34.089859000000047],
            [71.731453000000045, 34.089862000000039],
            [71.731511000000069, 34.089872000000071],
            [71.731575000000078, 34.08988800000003],
            [71.731783000000064, 34.089951000000042],
            [71.731859000000043, 34.089972000000046],
            [71.731925000000047, 34.089986000000067],
            [71.73199500000004, 34.089997000000039],
            [71.732068000000083, 34.090005000000076],
            [71.732144000000062, 34.090010000000063],
            [71.732224000000031, 34.090012000000058],
            [71.732309000000043, 34.090010000000063],
            [71.732398000000046, 34.090006000000074],
            [71.732492000000036, 34.089999000000034],
            [71.732665000000054, 34.089980000000025],
            [71.732861000000071, 34.089951000000042],
            [71.733056000000033, 34.089917000000071],
            [71.733538000000067, 34.089829000000066],
            [71.733671000000072, 34.089807000000064],
            [71.733787000000063, 34.089789000000053],
            [71.733916000000079, 34.089774000000034],
            [71.73403200000007, 34.089763000000062],
            [71.73413800000003, 34.089759000000072],
            [71.734235000000069, 34.089759000000072],
            [71.734292000000039, 34.089762000000064],
            [71.734346000000073, 34.089767000000052],
            [71.734398000000056, 34.089775000000031],
            [71.734446000000048, 34.089784000000066],
            [71.734492000000046, 34.089796000000035],
            [71.734536000000048, 34.089810000000057],
            [71.734577000000058, 34.089826000000073],
            [71.734615000000076, 34.089844000000028],
            [71.734651000000042, 34.089865000000032],
            [71.734684000000072, 34.08988800000003],
            [71.734716000000049, 34.089913000000024],
            [71.734744000000035, 34.08994000000007],
            [71.73477100000008, 34.089970000000051],
            [71.734795000000076, 34.090002000000027],
            [71.734817000000078, 34.090037000000052],
            [71.73483600000003, 34.090074000000072],
            [71.734852000000046, 34.090112000000033],
            [71.734866000000068, 34.090155000000038],
            [71.73487700000004, 34.090202000000033],
            [71.734887000000072, 34.09025500000007],
            [71.734900000000039, 34.09035700000004],
            [71.734921000000043, 34.090609000000029],
            [71.734929000000079, 34.090674000000035],
            [71.734939000000054, 34.09073200000006],
            [71.734950000000083, 34.090784000000042],
            [71.734964000000048, 34.090832000000034],
            [71.734979000000067, 34.090876000000037],
            [71.734997000000078, 34.090917000000047],
            [71.73501200000004, 34.090943000000038],
            [71.735031000000049, 34.090971000000025],
            [71.735117000000059, 34.091075000000046],
            [71.735148000000038, 34.091117000000054],
            [71.735160000000064, 34.09114100000005],
            [71.735169000000042, 34.091162000000054],
            [71.735173000000032, 34.091183000000058],
            [71.735174000000029, 34.091203000000064],
            [71.735172000000034, 34.091218000000026],
            [71.735167000000047, 34.091233000000045],
            [71.735160000000064, 34.091249000000062],
            [71.73515100000003, 34.091266000000076],
            [71.735129000000029, 34.091298000000052],
            [71.735065000000077, 34.091380000000072],
            [71.73504900000006, 34.091404000000068],
            [71.735037000000034, 34.09142600000007],
            [71.735015000000033, 34.091480000000047],
            [71.734994000000029, 34.091537000000073],
            [71.734976000000074, 34.091600000000028],
            [71.734960000000058, 34.091667000000029],
            [71.734952000000078, 34.091709000000037],
            [71.734945000000039, 34.091755000000035],
            [71.734930000000077, 34.091900000000066],
            [71.734903000000031, 34.092140000000029],
            [71.734897000000046, 34.092209000000025],
            [71.734894000000054, 34.092270000000042],
            [71.734892000000059, 34.092334000000051],
            [71.734893000000056, 34.092393000000072],
            [71.734897000000046, 34.092449000000045],
            [71.734904000000029, 34.092502000000025],
            [71.73491400000006, 34.092557000000056],
            [71.734928000000082, 34.092614000000026],
            [71.734945000000039, 34.092673000000048],
            [71.73496700000004, 34.092735000000062],
            [71.734991000000036, 34.09279900000007],
            [71.735020000000077, 34.092868000000067],
            [71.735100000000045, 34.09304300000008],
            [71.735187000000053, 34.093220000000031],
            [71.735224000000073, 34.093290000000025],
            [71.735260000000039, 34.093353000000036],
            [71.73529700000006, 34.093412000000058],
            [71.73533400000008, 34.093466000000035],
            [71.735372000000041, 34.093516000000079],
            [71.735411000000056, 34.093562000000077],
            [71.735457000000054, 34.093610000000069],
            [71.735511000000031, 34.093661000000054],
            [71.735570000000052, 34.093712000000039],
            [71.735682000000054, 34.093804000000034],
            [71.735798000000045, 34.093898000000024],
            [71.735898000000077, 34.093977000000052],
            [71.735993000000065, 34.094045000000051],
            [71.736083000000065, 34.094106000000068],
            [71.736150000000066, 34.094148000000075],
            [71.736221000000057, 34.094189000000028],
            [71.736296000000038, 34.094230000000039],
            [71.736375000000066, 34.094270000000051],
            [71.73645700000003, 34.094310000000064],
            [71.736546000000033, 34.094350000000077],
            [71.736747000000037, 34.09443600000003],
            [71.736830000000054, 34.094469000000061],
            [71.736916000000065, 34.094501000000037],
            [71.737005000000067, 34.094532000000072],
            [71.73709800000006, 34.094562000000053],
            [71.737296000000072, 34.094622000000072],
            [71.737519000000077, 34.094682000000034],
            [71.737697000000082, 34.094725000000039],
            [71.73787800000008, 34.094764000000055],
            [71.738061000000073, 34.094798000000026],
            [71.738246000000061, 34.094828000000064],
            [71.738434000000041, 34.09485200000006],
            [71.738642000000084, 34.094872000000066],
            [71.738871000000074, 34.094888000000026],
            [71.739219000000048, 34.094906000000037],
            [71.739370000000065, 34.094912000000079],
            [71.739705000000072, 34.094921000000056],
            [71.739853000000039, 34.094927000000041],
            [71.739987000000042, 34.094931000000031],
            [71.74029900000005, 34.094932000000028],
            [71.740448000000072, 34.09493800000007],
            [71.740534000000082, 34.094945000000052],
            [71.740613000000053, 34.094956000000025],
            [71.740688000000034, 34.094969000000049],
            [71.740759000000082, 34.094986000000063],
            [71.740857000000062, 34.095014000000049],
            [71.740935000000036, 34.095039000000043],
            [71.741003000000035, 34.095064000000036],
            [71.741064000000051, 34.095092000000079],
            [71.741085000000055, 34.095104000000049],
            [71.741108000000054, 34.095119000000068],
            [71.74118500000003, 34.095177000000035],
            [71.741216000000065, 34.095198000000039],
            [71.741249000000039, 34.09521600000005],
            [71.741280000000074, 34.095227000000079],
            [71.74132000000003, 34.095235000000059],
            [71.741373000000067, 34.095242000000042],
            [71.74140101100005, 34.095243715000038],
            [71.741422000000057, 34.095245000000034],
            [71.741475000000037, 34.095244000000037],
            [71.741535000000056, 34.095239000000049],
            [71.741616000000079, 34.095229000000074],
            [71.741648952000048, 34.095225672000026],
            [71.741631085000051, 34.094799088000059],
            [71.741609840000081, 34.094376794000027],
            [71.741572346000055, 34.093961062000062],
            [71.741534337000076, 34.093563505000077],
            [71.741501769000081, 34.093337426000062],
            [71.741473194000037, 34.093190814000025],
            [71.741425586000048, 34.092965897000056],
            [71.741380357000082, 34.092789832000051],
            [71.741325970000048, 34.092596313000058],
            [71.741247501000032, 34.092359653000074],
            [71.741174981000029, 34.092152715000054],
            [71.741080049000061, 34.091912114000024],
            [71.741013830000043, 34.091755240000055],
            [71.740912103000028, 34.091542643000025],
            [71.740822421000075, 34.091369089000068],
            [71.740692007000064, 34.091130417000045],
            [71.740629341000044, 34.091021785000066],
            [71.74051881500003, 34.090839833000075],
            [71.740377145000082, 34.090621961000068],
            [71.740226444000029, 34.090427686000055],
            [71.740085165000039, 34.090246928000056],
            [71.73989990900003, 34.090029885000035],
            [71.739689257000066, 34.089801142000056],
            [71.73952044300006, 34.089621155000032],
            [71.739230005000081, 34.089359850000051],
            [71.739044037000042, 34.089191560000074],
            [71.738832862000038, 34.089022911000029],
            [71.738581731000068, 34.088837456000078],
            [71.738290676000076, 34.088631545000055],
            [71.738034010000035, 34.088463721000039],
            [71.737716241000044, 34.088275194000062],
            [71.737506411000084, 34.088160083000048],
            [71.73728757400005, 34.088043654000046],
            [71.737072648000037, 34.087938820000034],
            [71.73656634300005, 34.087731047000034],
            [71.736154158000033, 34.087579348000077],
            [71.735806921000062, 34.087468254000044],
            [71.735276214000066, 34.087319342000058],
            [71.734922532000041, 34.087240010000073],
            [71.734479514000043, 34.087141091000035],
            [71.733701229000076, 34.086981208000054],
            [71.732853908000038, 34.086816724000073],
            [71.732117587000062, 34.086680757000067],
            [71.731567195000082, 34.086567807000051],
            [71.731185369000059, 34.086491289000037],
            [71.730819605000079, 34.086422004000042],
            [71.730496773000084, 34.086357927000051],
            [71.729881635000083, 34.086237246000053],
            [71.729530795000073, 34.08617123700003],
            [71.729256086000078, 34.086119552000071],
            [71.728648207000049, 34.085996990000069],
            [71.728119382000045, 34.085899030000064],
            [71.727602946000047, 34.08579942800003],
            [71.727152902000057, 34.085705167000071],
            [71.726352919000078, 34.085546014000045],
            [71.725761006000084, 34.085425666000049],
            [71.725373575000049, 34.085342786000069],
            [71.724881190000076, 34.085219302000041],
            [71.724560848000067, 34.085141693000025],
            [71.724157469000033, 34.085018826000066],
            [71.724060507000047, 34.084989127000028],
            [71.723730834000037, 34.08488815000004],
            [71.72314762600007, 34.084687794000047],
            [71.722663698000076, 34.084502100000066],
            [71.722106650000057, 34.084270945000071],
            [71.721813232000045, 34.084138619000043],
            [71.72142438700007, 34.083953806000068],
            [71.721161552000069, 34.083824961000062],
            [71.720731600000079, 34.083612235000032],
            [71.720145012000046, 34.083284392000053],
            [71.719699646000038, 34.083013894000032],
            [71.719087070000057, 34.082602919000067],
            [71.718440283000064, 34.082152004000079],
            [71.718216458000029, 34.08197272600006],
            [71.718001170000036, 34.081800287000078],
            [71.717496392000044, 34.081374550000078],
            [71.716912293000064, 34.080820682000024],
            [71.716324289000056, 34.080207512000072],
            [71.715830869000058, 34.079640200000028],
            [71.715118463000067, 34.078690295000058],
            [71.714698369000075, 34.078037637000079],
            [71.714223480000044, 34.077307361000067],
            [71.713555992000067, 34.076276603000053],
            [71.712898335000034, 34.075252102000036],
            [71.712238160000084, 34.07416764900006],
            [71.711584038000069, 34.073021116000064],
            [71.711068579000084, 34.072019412000031],
            [71.710732015000076, 34.071371343000067],
            [71.710241800000063, 34.070326333000025],
            [71.710061826000072, 34.069940819000067],
            [71.709714347000045, 34.069196500000032],
            [71.709439582000073, 34.068595376000076],
            [71.708712039000034, 34.067043598000055],
            [71.70816545200006, 34.065841624000029],
            [71.707383933000074, 34.064148593000027],
            [71.706913189000034, 34.06312790700008],
            [71.706775937000032, 34.062836406000031],
            [71.706327547000058, 34.061884100000043],
            [71.706256058000065, 34.061731678000058],
            [71.705719800000054, 34.060588317000054],
            [71.705291971000065, 34.059674782000059],
            [71.704700990000049, 34.058414465000055],
            [71.704292201000044, 34.057523892000063],
            [71.703685607000068, 34.056277106000039],
            [71.703233388000058, 34.055486920000078],
            [71.703222382000035, 34.055469567000046],
            [71.702780884000049, 34.054773454000042],
            [71.702279628000042, 34.054067307000025],
            [71.701703523000049, 34.053335649000076],
            [71.701420109000082, 34.05298416800008],
            [71.701002517000063, 34.052510687000051],
            [71.700559094000084, 34.052039534000073],
            [71.700429350000036, 34.051912519000041],
            [71.70039717800006, 34.051880710000034],
            [71.700322893000077, 34.051807261000079],
            [71.699717571000065, 34.051219837000076],
            [71.699020834000066, 34.050557678000075],
            [71.698530151000057, 34.050107662000073],
            [71.698411944000043, 34.04999508700007],
            [71.698831699000038, 34.049971071000073],
            [71.698675236000042, 34.049827894000032],
            [71.698040585000058, 34.049194290000059],
            [71.697654197000077, 34.048826939000037],
            [71.697313136000048, 34.04853266300006],
            [71.696785759000079, 34.048032496000076],
            [71.696523133000028, 34.047776139000064],
            [71.696016463000035, 34.047281564000059],
            [71.695384684000032, 34.046751521000033],
            [71.694713793000062, 34.04620581100005],
            [71.694259276000082, 34.045882935000066],
            [71.693864097000073, 34.045638557000075],
            [71.693416487000036, 34.045399688000032],
            [71.692863137000074, 34.045109063000041],
            [71.692109078000044, 34.044798430000071],
            [71.692496864000077, 34.044247399000028],
            [71.69276336300004, 34.043685846000074],
            [71.693096488000037, 34.042886347000035],
            [71.693267809000076, 34.04246756200007],
            [71.693410577000066, 34.042115402000036],
            [71.693486719000077, 34.041791795000051],
            [71.693591416000061, 34.04144915300003],
            [71.693648523000036, 34.041182653000078],
            [71.693838880000044, 34.040706761000024],
            [71.693962612000064, 34.040345083000034],
            [71.693991165000057, 34.040192797000032],
            [71.69400068300007, 34.040002440000023],
            [71.694038754000076, 34.039897744000029],
            [71.694181522000065, 34.039802566000048],
            [71.694295736000072, 34.039678834000028],
            [71.694409950000079, 34.039440887000069],
            [71.694514647000062, 34.039231495000024],
            [71.694628861000069, 34.038984031000041],
            [71.694695486000057, 34.038717531000032],
            [71.69470500400007, 34.03854621000005],
            [71.694771628000069, 34.038336818000062],
            [71.694942950000041, 34.038051282000026],
            [71.695306137000046, 34.03752477200004],
            [71.695040424000069, 34.03725898700003],
            [71.694710039000029, 34.036914961000036],
            [71.694615209000062, 34.036806108000064],
            [71.704571800000053, 34.037836323000079],
            [71.705717997000079, 34.03618848800005],
            [71.705754262000085, 34.036136351000039],
            [71.706846693000045, 34.034340383000028],
            [71.706862763000061, 34.034068337000065],
            [71.706890333000047, 34.033843008000076],
            [71.70701873400003, 34.033690039000078],
            [71.707018728000037, 34.033687723000071],
            [71.707259651000072, 34.033543107000071],
            [71.707551014000046, 34.033459894000032],
            [71.70788900000008, 34.033407000000068],
            [71.708369000000062, 34.033307000000036],
            [71.708635000000072, 34.033241000000032],
            [71.708834000000081, 34.033160000000066],
            [71.708912000000055, 34.033086000000026],
            [71.708983000000046, 34.032995000000028],
            [71.709020000000066, 34.032890000000066],
            [71.708998860000065, 34.032756677000066],
            [71.708940078000069, 34.03257282800007],
            [71.708791775000066, 34.032134098000029],
            [71.708693762000053, 34.031760667000071],
            [71.708699608000074, 34.031534673000067],
            [71.708820504000073, 34.031271569000069],
            [71.709015209000029, 34.031001691000029],
            [71.709160388000043, 34.030790038000077],
            [71.70928269500007, 34.030605029000071],
            [71.709491188000072, 34.030403054000033],
            [71.709676466000076, 34.030256524000038],
            [71.709851000000072, 34.03013100000004],
            [71.709930000000043, 34.030038000000047],
            [71.709960000000081, 34.029920000000061],
            [71.709963000000073, 34.029801000000077],
            [71.709873000000073, 34.029615000000035],
            [71.709798000000035, 34.029491000000064],
            [71.709623000000079, 34.029316000000051],
            [71.709451000000058, 34.029174000000069],
            [71.70922900000005, 34.028994000000068],
            [71.708933000000059, 34.028811000000076],
            [71.70862900000003, 34.028675000000078],
            [71.70835500000004, 34.028548000000058],
            [71.708044000000029, 34.028445000000033],
            [71.707766000000049, 34.028368000000057],
            [71.707575000000077, 34.02828100000005],
            [71.707417000000078, 34.028195000000039],
            [71.707207000000039, 34.028071000000068],
            [71.706945000000076, 34.027912000000072],
            [71.706611000000066, 34.027711000000068],
            [71.706273000000067, 34.027500000000032],
            [71.706052000000057, 34.027345000000025],
            [71.705819000000076, 34.027211000000079],
            [71.705646000000058, 34.027125000000069],
            [71.705350000000067, 34.026985000000025],
            [71.705065000000047, 34.026818000000048],
            [71.704888000000039, 34.026669000000027],
            [71.704761000000076, 34.02655100000004],
            [71.704584000000068, 34.026332000000025],
            [71.704425000000072, 34.026074000000051],
            [71.704286000000081, 34.025746000000026],
            [71.704119095000067, 34.025247144000048],
            [71.703936967000061, 34.02445143500006],
            [71.703873238000028, 34.024110137000037],
            [71.70381186000003, 34.023629410000069],
            [71.703757303000032, 34.023159605000046],
            [71.703680946000077, 34.022632559000044],
            [71.703551976000028, 34.02220053700006],
            [71.70346816700004, 34.021831464000059],
            [71.703506926000045, 34.021660312000051],
            [71.703468289000057, 34.02129659600007],
            [71.703456000000074, 34.021047000000067],
            [71.703106446000049, 34.020269102000043],
            [71.702878302000045, 34.019837103000043],
            [71.702804516000072, 34.01963513100003],
            [71.702761731000066, 34.019406357000037],
            [71.702716466000084, 34.019110253000065],
            [71.70274123400003, 34.018770018000055],
            [71.702817692000053, 34.018587569000033],
            [71.702928132000068, 34.018410097000071],
            [71.703014332000066, 34.018212546000029],
            [71.703081688000054, 34.018082629000048],
            [71.703166352000039, 34.017909737000025],
            [71.703346013000044, 34.017588514000067],
            [71.703490790000046, 34.017326411000056],
            [71.703592356000058, 34.017043322000063],
            [71.703614670000036, 34.016786524000054],
            [71.703601167000045, 34.016662207000024],
            [71.703589299000043, 34.016552955000066],
            [71.704660200000035, 34.016463100000067],
            [71.704812527000058, 34.016450319000057],
            [71.704914022000082, 34.016441803000077],
            [71.705647505000059, 34.016448940000032],
            [71.706604652000067, 34.01647220600006],
            [71.707250969000029, 34.016447879000054],
            [71.708316354000033, 34.016454710000062],
            [71.710607665000055, 34.016460511000048],
            [71.711238776000073, 34.016450203000034],
            [71.710224000000039, 34.012022000000059],
            [71.710224192000055, 34.012021839000056],
            [71.710224000000039, 34.012021000000061],
            [71.713671635000082, 34.00912508600004],
            [71.713671671000043, 34.009125056000073],
            [71.716001942000048, 34.007167696000067],
            [71.71715400000005, 34.006200000000035],
            [71.716743310000084, 33.998802402000024],
            [71.716446576000067, 33.998860781000076],
            [71.715656337000041, 33.99920304300008],
            [71.714866307000079, 33.999506436000047],
            [71.714378699000065, 33.999611927000046],
            [71.713990023000065, 33.99964466800003],
            [71.713436540000032, 33.999705063000079],
            [71.712295058000052, 33.999683989000062],
            [71.711406730000078, 33.999469864000048],
            [71.71056363100007, 33.999204201000055],
            [71.709428000000059, 33.998647000000062],
            [71.708662000000061, 33.997959000000037],
            [71.707949000000042, 33.996953000000076],
            [71.707829566000044, 33.996728292000057],
            [71.707647125000051, 33.996217505000061],
            [71.707530897000083, 33.995953996000026],
            [71.707068245000073, 33.995001964000039],
            [71.706306631000075, 33.99431869600005],
            [71.706152609000071, 33.994181223000055],
            [71.705871794000075, 33.99388274100005],
            [71.705593158000056, 33.993572673000074],
            [71.705506163000052, 33.993398532000072],
            [71.705323557000042, 33.992972945000076],
            [71.705070979000084, 33.992416695000031],
            [71.704762000000073, 33.991350000000068],
            [71.70470470600003, 33.990550390000067],
            [71.704791000000057, 33.989223000000038],
            [71.704514147000054, 33.988264242000071],
            [71.704120000000046, 33.986027000000036],
            [71.703878000000032, 33.985411000000056],
            [71.703757000000053, 33.984975000000077],
            [71.703866000000062, 33.98435900000004],
            [71.703975000000071, 33.984141000000079],
            [71.70419269000007, 33.983954629000038],
            [71.704422000000079, 33.983787000000063],
            [71.705466140000055, 33.982787616000053],
            [71.70575999600004, 33.98242224300003],
            [71.70646791300004, 33.981920903000059],
            [71.706844696000076, 33.98180248400007],
            [71.707093787000076, 33.981674933000079],
            [71.707223646000045, 33.981409001000031],
            [71.707253533000085, 33.981137152000031],
            [71.707202279000057, 33.980906252000068],
            [71.707146875000035, 33.980389721000051],
            [71.707178362000036, 33.980206838000072],
            [71.707258788000047, 33.980134202000045],
            [71.707390652000072, 33.979919087000042],
            [71.707487357000048, 33.979684158000055],
            [71.707751292000069, 33.979529255000045],
            [71.707831862000035, 33.979291117000059],
            [71.707758614000056, 33.979133128000058],
            [71.707743984000047, 33.978981223000062],
            [71.707736705000059, 33.97867741400006],
            [71.707634188000043, 33.978380003000041],
            [71.70750982800007, 33.978090523000049],
            [71.707469644000071, 33.97787805400003],
            [71.708838000000071, 33.977648000000045],
            [71.710040470000081, 33.977404940000042],
            [71.719507195000062, 33.975491395000063],
            [71.725104550000083, 33.974359981000077],
            [71.734895000000051, 33.972381000000041],
            [71.745278079000059, 33.974441029000047],
            [71.752933782000071, 33.975959939000063],
            [71.753337897000051, 33.975232532000064],
            [71.753077918000031, 33.975116952000064],
            [71.748623567000038, 33.973782074000042],
            [71.748694951000061, 33.973589338000068],
            [71.749173222000081, 33.973182450000024],
            [71.749637217000043, 33.972925468000028],
            [71.750108350000062, 33.972739870000055],
            [71.750529515000039, 33.972561411000072],
            [71.750879296000051, 33.972325844000068],
            [71.751257630000055, 33.971983202000047],
            [71.75214842400004, 33.970271875000037],
            [71.752238717000068, 33.969717212000035],
            [71.75225161700007, 33.969078706000062],
            [71.752245167000069, 33.968465997000067],
            [71.75225161700007, 33.968046776000051],
            [71.752361259000054, 33.967685600000038],
            [71.752612792000036, 33.967137388000026],
            [71.753025564000041, 33.966666570000029],
            [71.753760814000032, 33.966221550000057],
            [71.754167136000035, 33.965957118000063],
            [71.754386421000049, 33.965647539000031],
            [71.754547660000071, 33.965325061000044],
            [71.754625055000076, 33.964905839000039],
            [71.754702450000082, 33.964396324000063],
            [71.754592807000051, 33.963867460000074],
            [71.754544044000056, 33.963338910000061],
            [71.754532147000077, 33.962863018000064],
            [71.754734401000064, 33.962280050000061],
            [71.75522219000004, 33.96149482800007],
            [71.755353061000051, 33.960888065000063],
            [71.755650493000076, 33.960757195000042],
            [71.756566586000076, 33.960614427000053],
            [71.758196517000044, 33.960471660000053],
            [71.760052496000071, 33.95988869200005],
            [71.761789503000045, 33.959496081000054],
            [71.762443854000082, 33.959412800000052],
            [71.763693071000034, 33.959329518000061],
            [71.764680548000058, 33.959115367000038],
            [71.766393760000028, 33.959055880000051],
            [71.767666771000052, 33.95896070200007],
            [71.768118869000034, 33.95892501000003],
            [71.768594761000031, 33.958794140000066],
            [71.768689940000058, 33.958377734000067],
            [71.768701837000037, 33.957913739000048],
            [71.768737529000077, 33.957283182000026],
            [71.768606658000067, 33.956450371000074],
            [71.768333020000057, 33.955022694000036],
            [71.768154561000074, 33.953630709000038],
            [71.768118869000034, 33.953119125000057],
            [71.767999896000049, 33.952726514000062],
            [71.767892820000043, 33.952417184000069],
            [71.767785744000037, 33.952036470000053],
            [71.767809539000041, 33.951703346000045],
            [71.767869026000028, 33.951370221000047],
            [71.76795230700003, 33.95075156200005],
            [71.767940409000062, 33.949966339000071],
            [71.767880923000064, 33.949490447000073],
            [71.768047485000068, 33.948788506000028],
            [71.768309226000042, 33.94812225700008],
            [71.768511480000029, 33.94752739200004],
            [71.768594761000031, 33.946968219000041],
            [71.768844605000083, 33.946480429000076],
            [71.769142037000051, 33.946528018000038],
            [71.769772594000074, 33.946718375000046],
            [71.770403151000039, 33.946813554000073],
            [71.770843352000043, 33.946623197000065],
            [71.770867146000057, 33.94646853200004],
            [71.770688687000074, 33.946206791000066],
            [71.770558077000032, 33.945817860000034],
            [71.770272542000043, 33.944980290000046],
            [71.770015560000047, 33.94414271900007],
            [71.769777614000077, 33.943438399000058],
            [71.769520632000081, 33.942838775000041],
            [71.769168472000047, 33.941953615000045],
            [71.768997151000065, 33.94150627700003],
            [71.768892454000081, 33.940982795000025],
            [71.768930526000076, 33.940611599000079],
            [71.768930526000076, 33.940287992000037],
            [71.768949561000056, 33.939897761000054],
            [71.768921008000063, 33.939583672000026],
            [71.768844865000062, 33.939298137000037],
            [71.768740169000068, 33.939174405000074],
            [71.768644990000041, 33.939031637000028],
            [71.768730651000055, 33.93882224400005],
            [71.768978115000039, 33.938650923000068],
            [71.769235097000035, 33.938517673000035],
            [71.769606293000038, 33.938346352000053],
            [71.769939417000046, 33.938203585000053],
            [71.77016784500006, 33.938070335000077],
            [71.770186881000029, 33.937937085000044],
            [71.770215435000068, 33.937746728000036],
            [71.77016784500006, 33.937603960000047],
            [71.770029837000038, 33.937332702000049],
            [71.769915623000031, 33.937147104000076],
            [71.769772855000042, 33.936990060000028],
            [71.76960153400006, 33.936761631000024],
            [71.769501596000055, 33.936547480000058],
            [71.769473043000062, 33.936133454000071],
            [71.769515873000046, 33.935919302000059],
            [71.769730025000058, 33.935705151000036],
            [71.770058390000031, 33.935490999000024],
            [71.770429586000034, 33.935219741000026],
            [71.770957826000028, 33.934777161000056],
            [71.771511302000079, 33.933983612000077],
            [71.771739730000036, 33.933488684000054],
            [71.77196815800005, 33.933088935000058],
            [71.772044301000051, 33.932746292000047],
            [71.772044301000051, 33.932346543000051],
            [71.771930087000044, 33.931984865000061],
            [71.771720694000066, 33.931699330000072],
            [71.771511302000079, 33.931528008000043],
            [71.77132094500007, 33.931337651000035],
            [71.771225766000043, 33.931147295000073],
            [71.771263838000039, 33.930804652000063],
            [71.771416123000051, 33.930423938000047],
            [71.771720694000066, 33.929890939000074],
            [71.772063337000077, 33.929510225000058],
            [71.772291765000034, 33.929186619000063],
            [71.772558265000043, 33.928863012000079],
            [71.772615372000075, 33.928615548000039],
            [71.772520193000048, 33.928368084000056],
            [71.772215622000033, 33.928234834000079],
            [71.771987194000076, 33.928139656000042],
            [71.771758766000062, 33.927911228000028],
            [71.771644551000065, 33.927644728000075],
            [71.77170165900003, 33.927130764000026],
            [71.771720694000066, 33.926559694000048],
            [71.771720694000066, 33.926198016000058],
            [71.771758766000062, 33.925874409000073],
            [71.772101408000083, 33.925474660000077],
            [71.772234658000059, 33.925170089000062],
            [71.77232983600004, 33.924884553000027],
            [71.772501157000079, 33.924675161000039],
            [71.772786693000057, 33.92438962500006],
            [71.773034157000041, 33.923837590000062],
            [71.772958014000039, 33.923380734000034],
            [71.772805728000037, 33.923095199000045],
            [71.772634407000055, 33.922771592000061],
            [71.77257730000008, 33.922238593000031],
            [71.77257730000008, 33.921534272000031],
            [71.772405979000041, 33.921115487000066],
            [71.771911051000075, 33.920525381000061],
            [71.771397088000072, 33.919440346000044],
            [71.771168659000068, 33.918526633000056],
            [71.77107348100003, 33.917650992000063],
            [71.771016374000055, 33.91688956400003],
            [71.770883124000079, 33.916242351000051],
            [71.770464339000057, 33.915785494000033],
            [71.770122376000074, 33.914884019000056],
            [71.77011217900008, 33.914496506000035],
            [71.770326330000046, 33.914139587000079],
            [71.77060166800004, 33.913619505000042],
            [71.770622063000076, 33.91318100400008],
            [71.770509889000039, 33.912864876000071],
            [71.770275342000048, 33.912630329000024],
            [71.770040795000057, 33.912405980000074],
            [71.769847039000069, 33.911865502000069],
            [71.769581899000059, 33.911488188000078],
            [71.769469724000032, 33.910825338000052],
            [71.769428933000029, 33.91021347700007],
            [71.769388143000072, 33.909907546000056],
            [71.769469724000032, 33.909591417000058],
            [71.769653283000082, 33.909254894000071],
            [71.769765457000062, 33.908806195000068],
            [71.769592096000054, 33.90820453200007],
            [71.769224980000047, 33.907602868000026],
            [71.768959840000036, 33.907327530000032],
            [71.768674304000058, 33.906848239000055],
            [71.76824600100008, 33.906124203000047],
            [71.76791967500003, 33.905624516000046],
            [71.767746315000068, 33.905400167000039],
            [71.767715721000059, 33.905196213000067],
            [71.767797303000066, 33.905012655000064],
            [71.767970664000075, 33.904900480000038],
            [71.768307187000062, 33.904829096000071],
            [71.76873549000004, 33.904696526000066],
            [71.769072014000074, 33.904625142000043],
            [71.769500317000052, 33.904472177000059],
            [71.769702916000028, 33.904386561000024],
            [71.769901933000028, 33.904126308000059],
            [71.769917242000076, 33.903866055000037],
            [71.769810079000081, 33.903559875000042],
            [71.769649335000054, 33.903307276000078],
            [71.769442663000063, 33.903085296000029],
            [71.76916710100005, 33.902870970000038],
            [71.768922157000077, 33.902725534000069],
            [71.768738449000068, 33.902641335000055],
            [71.76856239600005, 33.902526517000069],
            [71.768478488000028, 33.902107410000042],
            [71.767627688000061, 33.901914698000041],
            [71.766654832000029, 33.901696461000029],
            [71.765623643000083, 33.901487565000025],
            [71.764405400000044, 33.901211886000056],
            [71.763793634000081, 33.901085009000042],
            [71.763566367000067, 33.901022157000057],
            [71.763451512000074, 33.900974397000027],
            [71.763273525000045, 33.900864962000071],
            [71.763095324000062, 33.900746595000044],
            [71.762951673000032, 33.900637156000073],
            [71.762169817000029, 33.899987555000052],
            [71.761996397000075, 33.899852589000034],
            [71.761871968000037, 33.899780340000063],
            [71.761787071000072, 33.899745689000042],
            [71.761707569000066, 33.899723558000062],
            [71.761622116000069, 33.899696606000077],
            [71.761559273000046, 33.899682290000044],
            [71.761521113000072, 33.899663263000036],
            [71.761477182000078, 33.899634718000073],
            [71.761444681000057, 33.899598273000038],
            [71.761421718000065, 33.899535671000024],
            [71.761407185000053, 33.899472321000076],
            [71.761326977000067, 33.89949735700003],
            [71.761242666000044, 33.899512360000074],
            [71.761168129000055, 33.899526190000074],
            [71.761097731000064, 33.899525108000034],
            [71.761022702000048, 33.899508856000068],
            [71.760572787000058, 33.899375391000035],
            [71.759920151000074, 33.89918579600004],
            [71.759405178000065, 33.899036100000046],
            [71.759013881000044, 33.89891889900008],
            [71.758741030000067, 33.898828163000076],
            [71.758533570000054, 33.898767341000053],
            [71.75841934500005, 33.898733664000076],
            [71.758304361000057, 33.898710240000071],
            [71.757395244000065, 33.898586657000067],
            [71.756159414000081, 33.898404119000077],
            [71.755241227000056, 33.898302724000075],
            [71.753992491000076, 33.898195828000041],
            [71.753634626000064, 33.898157647000062],
            [71.753469689000042, 33.89813089900008],
            [71.753371921000053, 33.898096236000072],
            [71.753232943000057, 33.898023628000033],
            [71.751888575000066, 33.897324893000075],
            [71.750597325000058, 33.896647422000058],
            [71.748920460000079, 33.895796978000078],
            [71.746618136000052, 33.894708344000037],
            [71.745803962000082, 33.894312047000028],
            [71.74512526500007, 33.893986683000037],
            [71.744587136000064, 33.893719392000037],
            [71.743991597000047, 33.89341965400007],
            [71.74367731600006, 33.893296586000076],
            [71.743227758000046, 33.893185563000031],
            [71.742487155000049, 33.893031255000039],
            [71.741799704000073, 33.892911936000075],
            [71.743210034000072, 33.891020990000072],
            [71.743709721000073, 33.890128692000076],
            [71.744233202000032, 33.887808718000031],
            [71.744221305000053, 33.886404836000054],
            [71.743305213000042, 33.883371023000052],
            [71.741187492000051, 33.880075470000065],
            [71.740747292000037, 33.878790561000073],
            [71.740200016000074, 33.876304024000035],
            [71.740521243000046, 33.874567017000061],
            [71.741758563000076, 33.87110490200007],
            [71.74203916600004, 33.870320865000053],
            [71.74216307100005, 33.869974658000046],
            [71.742746039000053, 33.869379792000075],
            [71.744483046000084, 33.868451803000028],
            [71.745494317000066, 33.86788073200006],
            [71.745791749000034, 33.86736914800008],
            [71.746017798000082, 33.865881985000044],
            [71.745720366000057, 33.863324064000039],
            [71.745720366000057, 33.862848172000042],
            [71.745732263000036, 33.862550739000028],
            [71.745803647000059, 33.862372280000045],
            [71.746005901000046, 33.86197966900005],
            [71.747183734000032, 33.861218241000074],
            [71.749575092000043, 33.859552618000066],
            [71.749836833000074, 33.859243289000062],
            [71.75008667600008, 33.859041034000029],
            [71.750943282000037, 33.858814986000027],
            [71.754786112000033, 33.858648423000034],
            [71.754881290000071, 33.856221373000039],
            [71.755333388000054, 33.855721686000038],
            [71.758277450000037, 33.854624453000042],
            [71.749310000000037, 33.847360000000037],
            [71.749245113000029, 33.84712341200003],
            [71.74924500000003, 33.847123000000067],
            [71.749158000000079, 33.848607000000072],
            [71.744372621000082, 33.841087193000078],
            [71.744330110000078, 33.84102039000004],
            [71.740582434000032, 33.835131242000045],
            [71.739881000000082, 33.834029000000044],
            [71.730604000000028, 33.819451000000072],
            [71.728852128000028, 33.816087376000041],
            [71.728828856000064, 33.816042693000043],
            [71.720943036000051, 33.800901786000054],
            [71.714038000000073, 33.787644000000057],
            [71.71402555700007, 33.787638297000058],
            [71.698135000000036, 33.780355000000043],
            [71.697984577000057, 33.780273520000037],
            [71.666328000000078, 33.763126000000057],
            [71.632012063000047, 33.744514502000072],
            [71.627232000000049, 33.741922000000045],
            [71.602201717000071, 33.732982292000031],
            [71.599401000000057, 33.731982000000073],
            [71.551691000000062, 33.728669000000025],
            [71.543238742000028, 33.729608297000027],
            [71.527836000000036, 33.731320000000039],
            [71.527211645000079, 33.731676762000063],
            [71.514079066000079, 33.739206309000053],
            [71.513957883000046, 33.739269713000056],
            [71.504644000000042, 33.744572000000062],
            [71.503100798000048, 33.751001749000068],
            [71.503020668000033, 33.751335612000048],
            [71.500668000000076, 33.761138000000074],
            [71.504180722000058, 33.76722721200008],
            [71.507472707000034, 33.772933784000031],
            [71.51060700000005, 33.77836700000006],
            [71.51922200000007, 33.805535000000077],
            [71.519735089000051, 33.808958890000042],
            [71.519328020000046, 33.80940548500007],
            [71.51888083700004, 33.809887091000064],
            [71.518770766000046, 33.809993158000054],
            [71.518679182000028, 33.810076228000071],
            [71.51858863700005, 33.810135064000065],
            [71.518483291000052, 33.810191470000063],
            [71.51831560100004, 33.810269245000029],
            [71.518166618000066, 33.810325216000024],
            [71.518048563000036, 33.810358187000077],
            [71.517915541000036, 33.810385084000075],
            [71.517733065000073, 33.810416690000068],
            [71.517497787000082, 33.810440605000053],
            [71.51722871700008, 33.810462693000034],
            [71.517022694000048, 33.810468989000071],
            [71.51685116200008, 33.81048039600006],
            [71.516530774000046, 33.810505654000053],
            [71.516252897000072, 33.810520698000062],
            [71.515961931000049, 33.810543314000029],
            [71.51547669100006, 33.810559614000056],
            [71.515275389000067, 33.81057095400007],
            [71.515158025000062, 33.810579540000049],
            [71.515073779000033, 33.81059110800004],
            [71.514980582000078, 33.810626078000041],
            [71.514894205000076, 33.810677247000058],
            [71.514802295000038, 33.810741753000059],
            [71.514611824000042, 33.810890950000044],
            [71.514326289000053, 33.811107493000065],
            [71.513791784000034, 33.811510646000045],
            [71.513706332000083, 33.811565251000047],
            [71.513592761000041, 33.811631430000034],
            [71.513457810000034, 33.81169720500003],
            [71.513231528000063, 33.811786798000071],
            [71.512983290000079, 33.811870825000028],
            [71.512273969000034, 33.81208908900004],
            [71.512176157000056, 33.812130493000041],
            [71.512094232000038, 33.812175291000074],
            [71.511988884000061, 33.81224056700006],
            [71.511895877000029, 33.812315425000065],
            [71.511772545000042, 33.812456290000057],
            [71.511534889000075, 33.812728401000072],
            [71.51104108800007, 33.813290409000047],
            [71.510635775000083, 33.813764864000063],
            [71.510553360000074, 33.813857733000077],
            [71.510466764000057, 33.813924237000037],
            [71.510392372000069, 33.813969796000038],
            [71.510273475000076, 33.814024321000034],
            [71.510011622000036, 33.814103142000079],
            [71.509840451000059, 33.814156766000053],
            [71.509273225000072, 33.814331373000073],
            [71.50904848600004, 33.814411549000056],
            [71.508651549000035, 33.814560952000079],
            [71.508509934000074, 33.814622847000066],
            [71.508374084000081, 33.814690838000047],
            [71.508082352000031, 33.814846629000044],
            [71.507892139000035, 33.814955484000052],
            [71.507786488000079, 33.815027817000043],
            [71.507679777000078, 33.815116223000075],
            [71.507637322000051, 33.815177776000041],
            [71.507611531000066, 33.815236341000059],
            [71.507591363000074, 33.815318206000029],
            [71.507580795000081, 33.815499775000035],
            [71.507553707000056, 33.815840594000065],
            [71.507525871000041, 33.816456532000075],
            [71.507516276000047, 33.816539319000071],
            [71.50749660300005, 33.816625147000025],
            [71.507466685000054, 33.816691727000034],
            [71.507421708000038, 33.816772297000057],
            [71.507373303000065, 33.816839074000029],
            [71.507335596000075, 33.81687865400005],
            [71.507291418000079, 33.816928348000033],
            [71.507087830000046, 33.817085882000072],
            [71.506770365000079, 33.817356385000039],
            [71.50643763000005, 33.817659139000057],
            [71.506094546000043, 33.817975378000028],
            [71.505735112000082, 33.818292850000034],
            [71.505643810000038, 33.81837511100008],
            [71.505550672000084, 33.818439949000037],
            [71.505424865000066, 33.81851075700007],
            [71.505115463000038, 33.818698209000047],
            [71.504719474000069, 33.818943890000071],
            [71.50432061600003, 33.819191295000053],
            [71.50373800300008, 33.819538119000072],
            [71.503336165000064, 33.819770004000077],
            [71.503060019000031, 33.819943324000064],
            [71.503019835000032, 33.819973653000034],
            [71.502989199000069, 33.82001454300007],
            [71.502960963000078, 33.820079526000029],
            [71.502922291000061, 33.820207775000029],
            [71.502889971000059, 33.820322257000043],
            [71.502849766000054, 33.820437841000057],
            [71.502801715000032, 33.820562794000068],
            [71.502767565000056, 33.820640143000048],
            [71.502709890000062, 33.820733532000077],
            [71.502618116000065, 33.82085322100005],
            [71.502519279000069, 33.82096524800005],
            [71.502354595000043, 33.821148609000034],
            [71.502063546000045, 33.821454900000049],
            [71.501847026000064, 33.821671448000075],
            [71.501743273000045, 33.821754981000026],
            [71.50160906900004, 33.82185983200003],
            [71.501492030000065, 33.821935333000056],
            [71.501266756000064, 33.822059114000069],
            [71.501139568000042, 33.822133208000025],
            [71.500975691000065, 33.822244901000033],
            [71.500844119000078, 33.822352686000045],
            [71.500722039000038, 33.822472585000071],
            [71.500641083000062, 33.822581229000036],
            [71.500567556000078, 33.822686972000042],
            [71.500492080000072, 33.822803037000028],
            [71.500438079000048, 33.822869040000057],
            [71.500375144000031, 33.822922615000039],
            [71.500241584000037, 33.823003633000042],
            [71.499976479000054, 33.823174292000033],
            [71.499686385000075, 33.82338212600007],
            [71.499339399000064, 33.823659701000054],
            [71.498988069000063, 33.82395083800003],
            [71.498863232000076, 33.824046042000077],
            [71.498697848000063, 33.82416414100004],
            [71.498360149000064, 33.824372367000024],
            [71.498217408000073, 33.824444765000067],
            [71.498074460000055, 33.824511461000043],
            [71.497925580000071, 33.824560193000025],
            [71.497781002000067, 33.824599644000045],
            [71.497666280000033, 33.82464040900004],
            [71.497577387000035, 33.824682401000075],
            [71.497526728000082, 33.824716519000049],
            [71.497445719000041, 33.824803145000033],
            [71.497315231000073, 33.824998969000035],
            [71.49724240900008, 33.825102737000066],
            [71.497181913000077, 33.825182599000073],
            [71.497126760000072, 33.825236374000042],
            [71.497063229000048, 33.825276502000065],
            [71.496976975000052, 33.825319075000039],
            [71.496816764000073, 33.825388992000057],
            [71.496695251000062, 33.825437127000043],
            [71.496604284000057, 33.825482093000062],
            [71.496518776000073, 33.825543946000039],
            [71.496419296000056, 33.825627642000029],
            [71.496309266000083, 33.82573525600003],
            [71.496179951000045, 33.825855818000036],
            [71.496054828000069, 33.825966946000051],
            [71.495950246000064, 33.826051288000031],
            [71.495848555000066, 33.826112715000079],
            [71.495727816000056, 33.82617207100003],
            [71.495569816000057, 33.826238199000045],
            [71.495262687000036, 33.826328974000035],
            [71.495003512000039, 33.826399410000079],
            [71.494716554000036, 33.826474800000028],
            [71.494283560000042, 33.826592216000051],
            [71.493951541000058, 33.826694514000053],
            [71.493497625000032, 33.826842695000039],
            [71.493198335000045, 33.826934814000026],
            [71.492694576000076, 33.82708437000008],
            [71.492579312000032, 33.827119938000067],
            [71.492101909000041, 33.827267256000027],
            [71.491408024000066, 33.827486537000027],
            [71.490738084000043, 33.827719220000063],
            [71.490400426000065, 33.827863360000038],
            [71.489978554000061, 33.82806503300003],
            [71.48946356700003, 33.828295163000064],
            [71.48879061100007, 33.828598480000039],
            [71.48830130500005, 33.828824406000024],
            [71.487577006000038, 33.829172414000027],
            [71.487438227000041, 33.829228019000027],
            [71.487371556000028, 33.829245538000066],
            [71.487300161000064, 33.829252250000025],
            [71.487178533000076, 33.829246811000075],
            [71.487045587000068, 33.829227908000064],
            [71.486583809000081, 33.829155198000024],
            [71.486432803000071, 33.829137651000053],
            [71.486327591000077, 33.829132254000058],
            [71.486101614000063, 33.829136237000057],
            [71.485763492000046, 33.829150847000051],
            [71.485303767000062, 33.829150675000051],
            [71.485161531000074, 33.829157231000067],
            [71.485088026000028, 33.829163792000031],
            [71.485002011000063, 33.829184809000026],
            [71.484913124000059, 33.829213634000041],
            [71.484774088000052, 33.829268457000069],
            [71.484667714000068, 33.829307491000066],
            [71.484451699000033, 33.829384681000079],
            [71.484215616000029, 33.829473774000064],
            [71.484066552000058, 33.829545340000038],
            [71.483935122000048, 33.829614105000076],
            [71.483819574000051, 33.829699593000043],
            [71.483728787000075, 33.82979225400004],
            [71.483669530000043, 33.829873869000039],
            [71.483624338000084, 33.82995943800006],
            [71.483571306000044, 33.83006326800006],
            [71.483454533000042, 33.830254421000063],
            [71.483312640000065, 33.830479467000032],
            [71.483128183000076, 33.830748683000024],
            [71.483012615000064, 33.830934201000048],
            [71.482848551000075, 33.831171809000068],
            [71.482627704000038, 33.831511185000068],
            [71.482384614000068, 33.831888116000073],
            [71.482264733000079, 33.832069833000048],
            [71.482180489000029, 33.832179620000034],
            [71.482110469000077, 33.832244904000049],
            [71.482036974000039, 33.83229943200007],
            [71.48197478700007, 33.832322629000032],
            [71.481915472000082, 33.832336274000056],
            [71.481832160000067, 33.832337734000077],
            [71.48173115000003, 33.83233410400004],
            [71.481591971000057, 33.832305904000066],
            [71.48148347800003, 33.832274720000044],
            [71.481377102000067, 33.832242059000066],
            [71.481278582000073, 33.832220299000028],
            [71.481164963000083, 33.832212285000026],
            [71.481060740000032, 33.832216519000042],
            [71.480939689000081, 33.832227603000035],
            [71.480786481000052, 33.832259099000055],
            [71.480653742000072, 33.832299015000046],
            [71.480507620000083, 33.832344663000072],
            [71.480288405000067, 33.832420400000046],
            [71.480098944000076, 33.832475540000075],
            [71.479750620000061, 33.832567100000063],
            [71.479523660000041, 33.83261553400007],
            [71.479334950000066, 33.832647167000061],
            [71.479124363000039, 33.83268700900004],
            [71.478954989000044, 33.832735218000039],
            [71.478813900000034, 33.832786175000024],
            [71.478652624000063, 33.832868652000059],
            [71.478478840000037, 33.832959583000047],
            [71.478277949000073, 33.833080307000046],
            [71.477949915000067, 33.833307230000059],
            [71.477613427000051, 33.833560374000058],
            [71.477482659000032, 33.833652101000041],
            [71.477410681000038, 33.833713180000075],
            [71.47735384300006, 33.83379233200003],
            [71.477238267000075, 33.834080328000027],
            [71.477079273000072, 33.834516695000048],
            [71.476944778000075, 33.83501134200003],
            [71.476931162000028, 33.835045687000047],
            [71.476908152000078, 33.835082162000049],
            [71.476866440000038, 33.835118480000062],
            [71.476786172000061, 33.835157707000064],
            [71.476618428000052, 33.835213715000066],
            [71.476496729000075, 33.835253785000077],
            [71.476424866000059, 33.835280693000072],
            [71.476371507000067, 33.835300023000059],
            [71.476329943000053, 33.835322554000072],
            [71.476294945000063, 33.835348270000054],
            [71.476275551000072, 33.835380397000051],
            [71.47626137900005, 33.835423165000066],
            [71.476259060000075, 33.835483444000033],
            [71.476270904000046, 33.835544628000036],
            [71.476290318000054, 33.835612661000027],
            [71.476314648000084, 33.835668621000025],
            [71.476352790000078, 33.835736843000063],
            [71.476408380000066, 33.835814061000065],
            [71.476479103000031, 33.83590791000006],
            [71.476598591000084, 33.836055465000072],
            [71.476701444000071, 33.836198767000042],
            [71.476768058000062, 33.836304203000054],
            [71.476822779000031, 33.836423797000066],
            [71.476875003000032, 33.836554804000059],
            [71.476904375000061, 33.836650795000025],
            [71.476917668000056, 33.836732161000043],
            [71.476919941000062, 33.836790286000053],
            [71.476901252000062, 33.836846213000058],
            [71.476821976000053, 33.836998403000052],
            [71.476629499000069, 33.837356638000074],
            [71.47637678600006, 33.837916755000037],
            [71.476066121000031, 33.838605506000079],
            [71.475743948000058, 33.839335525000024],
            [71.475420521000046, 33.840068938000059],
            [71.475069026000028, 33.840853466000056],
            [71.474895244000038, 33.841230623000058],
            [71.474784602000057, 33.841470750000042],
            [71.474676540000075, 33.841705276000027],
            [71.474351464000051, 33.842449108000039],
            [71.474187696000058, 33.842817324000066],
            [71.47409474400007, 33.842972022000026],
            [71.473975575000054, 33.843146963000038],
            [71.473864574000061, 33.843301283000073],
            [71.473558909000076, 33.843680509000023],
            [71.473366118000058, 33.843909193000059],
            [71.473098372000038, 33.844184453000025],
            [71.472882516000084, 33.844355362000044],
            [71.472535658000083, 33.844626762000075],
            [71.472135598000079, 33.844915809000042],
            [71.471844357000066, 33.845099221000055],
            [71.471481308000079, 33.845287941000038],
            [71.471093314000029, 33.845487630000036],
            [71.470546259000059, 33.84574615300005],
            [71.47011707200005, 33.845984760000078],
            [71.469127416000049, 33.846487394000064],
            [71.468362093000053, 33.846851604000051],
            [71.467990016000044, 33.847037536000073],
            [71.467762742000048, 33.847128076000047],
            [71.467312557000071, 33.847290345000033],
            [71.466818170000067, 33.847443666000061],
            [71.466408856000044, 33.84755120300008],
            [71.466150873000061, 33.847617564000075],
            [71.465891502000034, 33.84769698100007],
            [71.465379856000084, 33.847861481000052],
            [71.465057737000052, 33.847963735000064],
            [71.464728020000052, 33.848103742000035],
            [71.464527353000051, 33.848200616000042],
            [71.464323867000076, 33.848300300000062],
            [71.464119966000055, 33.848421526000038],
            [71.463838219000081, 33.848591233000036],
            [71.463615582000045, 33.848737510000035],
            [71.463370478000058, 33.84891520900004],
            [71.462773793000054, 33.849408132000065],
            [71.462554357000045, 33.849573705000068],
            [71.462103665000029, 33.849938163000047],
            [71.461779945000046, 33.850206185000047],
            [71.461664985000084, 33.850295560000063],
            [71.461298726000052, 33.85058030700003],
            [71.460864628000081, 33.850926875000027],
            [71.460401379000075, 33.851307733000056],
            [71.459879343000068, 33.851731764000078],
            [71.459397065000076, 33.852115001000072],
            [71.459001577000038, 33.852388234000045],
            [71.458738528000083, 33.852544660000035],
            [71.458445724000057, 33.852693836000071],
            [71.458044025000049, 33.852871608000044],
            [71.457812419000049, 33.852970513000059],
            [71.457107050000047, 33.853280706000078],
            [71.456677939000031, 33.853452446000063],
            [71.455993557000056, 33.853737763000026],
            [71.455348769000068, 33.854015731000061],
            [71.454671726000072, 33.854288152000038],
            [71.453958919000058, 33.854596130000061],
            [71.453320820000044, 33.854864700000064],
            [71.452631518000032, 33.855147621000071],
            [71.451962248000029, 33.855431979000059],
            [71.451822485000037, 33.85550697900004],
            [71.451479526000071, 33.855700587000058],
            [71.45109192700005, 33.855925938000041],
            [71.450888174000056, 33.856055082000069],
            [71.450706593000064, 33.856181867000032],
            [71.450387999000043, 33.85640896600006],
            [71.449818661000052, 33.856837019000068],
            [71.449298549000048, 33.857224083000062],
            [71.448615725000082, 33.857700184000066],
            [71.448327850000055, 33.857899831000054],
            [71.448105418000068, 33.858071604000031],
            [71.447806247000074, 33.858288404000064],
            [71.447205714000063, 33.85873265500004],
            [71.446803212000077, 33.859019289000059],
            [71.445866293000051, 33.859675469000024],
            [71.445361442000035, 33.860049265000043],
            [71.445204448000084, 33.860174855000025],
            [71.445012057000042, 33.860342944000024],
            [71.444854934000034, 33.860500000000059],
            [71.444568446000062, 33.860789992000036],
            [71.444206690000044, 33.861160455000061],
            [71.443736024000032, 33.861614571000075],
            [71.443372224000029, 33.861998163000067],
            [71.442799318000084, 33.862583306000033],
            [71.442248043000063, 33.863147165000044],
            [71.441959663000034, 33.863440023000067],
            [71.44164551800003, 33.863743955000075],
            [71.441261721000046, 33.864141964000055],
            [71.44122715900005, 33.86420580500004],
            [71.441193883000039, 33.864276317000076],
            [71.441157687000043, 33.864347013000042],
            [71.44109521200005, 33.86439989400003],
            [71.441012919000059, 33.864455916000054],
            [71.441010262000077, 33.864458115000048],
            [71.440926434000062, 33.864514260000078],
            [71.440851696000038, 33.864571677000072],
            [71.440643248000072, 33.864792359000035],
            [71.440321008000069, 33.865116031000071],
            [71.439918448000071, 33.865524939000068],
            [71.439452981000045, 33.865991296000061],
            [71.439015728000072, 33.866431281000075],
            [71.438604603000044, 33.866836803000069],
            [71.438454652000075, 33.866963723000026],
            [71.438260098000057, 33.867108087000076],
            [71.438056730000028, 33.867240967000043],
            [71.437918060000072, 33.867340471000034],
            [71.437756369000056, 33.867435379000028],
            [71.437518805000082, 33.867562484000075],
            [71.437282706000076, 33.86765667800006],
            [71.43710821600007, 33.867731683000045],
            [71.436767589000056, 33.867850322000038],
            [71.436564236000038, 33.867914713000062],
            [71.436363868000058, 33.867966351000064],
            [71.43616964000006, 33.868008798000062],
            [71.435991407000074, 33.868042650000064],
            [71.435791522000045, 33.868068030000074],
            [71.435588525000071, 33.868087083000034],
            [71.435327832000041, 33.868109075000064],
            [71.435022189000051, 33.868131070000061],
            [71.434695374000057, 33.868157286000041],
            [71.434249152000064, 33.86819283400007],
            [71.433841867000069, 33.868222508000031],
            [71.433365101000049, 33.868264985000053],
            [71.432859296000061, 33.868301479000024],
            [71.432405774000074, 33.868332078000037],
            [71.431932416000052, 33.868367229000057],
            [71.43170830300005, 33.868390326000053],
            [71.431447097000046, 33.868411195000078],
            [71.431275174000064, 33.86843470000008],
            [71.431077069000082, 33.868467043000066],
            [71.430883631000029, 33.868498402000057],
            [71.430549023000083, 33.868558429000075],
            [71.430415763000042, 33.86858395400003],
            [71.430209240000067, 33.868612760000076],
            [71.430036391000044, 33.868633429000056],
            [71.429800206000039, 33.868671315000029],
            [71.429356169000073, 33.868748074000052],
            [71.429039197000066, 33.868803828000068],
            [71.428744990000041, 33.868849904000058],
            [71.428501616000062, 33.868888019000053],
            [71.428007391000051, 33.868967846000032],
            [71.427639472000067, 33.86901860100005],
            [71.427380650000032, 33.869054806000065],
            [71.427058242000044, 33.869118307000065],
            [71.426478419000034, 33.869211585000073],
            [71.425991873000044, 33.869285562000073],
            [71.424868826000079, 33.869458763000068],
            [71.42416729200005, 33.869579983000051],
            [71.423295513000085, 33.869715223000071],
            [71.422335280000084, 33.869869512000037],
            [71.421446800000069, 33.870009271000072],
            [71.420906374000083, 33.87009861000007],
            [71.420241538000084, 33.87020523700005],
            [71.419607921000079, 33.870317484000054],
            [71.419126276000043, 33.870400922000044],
            [71.41827937000005, 33.870536824000055],
            [71.417963562000068, 33.870596747000036],
            [71.417819964000046, 33.870616741000049],
            [71.416851115000043, 33.870757427000058],
            [71.416202321000071, 33.87084578200006],
            [71.415821418000064, 33.870884664000073],
            [71.415491084000053, 33.870922331000031],
            [71.415023448000056, 33.87097529600004],
            [71.414529590000029, 33.871037629000057],
            [71.414182268000047, 33.871077371000069],
            [71.413626889000057, 33.871137412000053],
            [71.412990133000051, 33.871235419000072],
            [71.412591883000061, 33.871295327000041],
            [71.412543869000046, 33.871302118000074],
            [71.412494628000047, 33.871319686000049],
            [71.412448224000059, 33.871357117000059],
            [71.412280895000038, 33.871497482000052],
            [71.411742188000062, 33.871882721000077],
            [71.411151294000035, 33.872327606000056],
            [71.410687065000047, 33.872659640000052],
            [71.410649798000065, 33.872703327000067],
            [71.401252000000056, 33.876087000000041],
            [71.40136700000005, 33.876312000000041],
            [71.401793000000055, 33.876642000000061],
            [71.402077030000044, 33.87688496800007],
            [71.402121000000079, 33.877060000000029],
            [71.402092000000039, 33.877341000000058],
            [71.402052000000083, 33.877614000000051],
            [71.40197100000006, 33.878027000000031],
            [71.402513000000056, 33.878486000000066],
            [71.402738963000047, 33.878859803000068],
            [71.402927691000059, 33.879124954000076],
            [71.403151115000071, 33.879576920000034],
            [71.403687000000048, 33.879969000000074],
            [71.403765000000078, 33.880137000000047],
            [71.403948000000071, 33.880282000000079],
            [71.404171000000076, 33.880347000000029],
            [71.404625143000032, 33.880506067000056],
            [71.405092560000071, 33.880731729000047],
            [71.405399000000045, 33.881031000000064],
            [71.405725602000075, 33.881265180000071],
            [71.405992687000037, 33.881485135000048],
            [71.406394340000077, 33.88171619700006],
            [71.406779475000064, 33.881939880000061],
            [71.407158000000038, 33.882174000000077],
            [71.407412000000079, 33.882470000000069],
            [71.40776900000003, 33.882856000000061],
            [71.408156000000076, 33.883178000000044],
            [71.408784000000082, 33.883693000000051],
            [71.408996000000059, 33.884135000000072],
            [71.40906300000006, 33.884668000000033],
            [71.409217000000069, 33.885255000000029],
            [71.40935236200005, 33.886014003000071],
            [71.409331000000066, 33.886396000000047],
            [71.409398000000067, 33.886782000000039],
            [71.409620000000075, 33.887369000000035],
            [71.409773542000039, 33.887705296000036],
            [71.410286118000045, 33.888138549000075],
            [71.410593791000053, 33.888280749000046],
            [71.411007000000041, 33.888314000000037],
            [71.411498831000074, 33.888405229000057],
            [71.412013282000032, 33.888480027000071],
            [71.413058000000035, 33.888871000000051],
            [71.413898633000031, 33.889029074000064],
            [71.41472200000004, 33.889170000000036],
            [71.415061000000037, 33.889227000000062],
            [71.415341000000069, 33.889436000000046],
            [71.415756918000056, 33.88987824000003],
            [71.41608448900007, 33.890343877000078],
            [71.416236703000038, 33.890750110000056],
            [71.416464000000076, 33.890961000000061],
            [71.416803000000073, 33.891090000000077],
            [71.417209000000071, 33.891195000000039],
            [71.417848000000049, 33.891589000000067],
            [71.418946000000062, 33.892175000000066],
            [71.419940483000062, 33.89260112200003],
            [71.420726000000059, 33.892779000000075],
            [71.421171000000072, 33.892876000000058],
            [71.42154800000003, 33.893053000000066],
            [71.421780000000069, 33.893286000000046],
            [71.422041000000036, 33.893680000000074],
            [71.422273000000075, 33.894243000000074],
            [71.422485000000052, 33.894460000000038],
            [71.42289100000005, 33.894662000000039],
            [71.423135085000069, 33.894669390000047],
            [71.42325900000003, 33.894758000000024],
            [71.423433000000045, 33.894807000000071],
            [71.424034000000063, 33.894727000000046],
            [71.424363000000028, 33.894743000000062],
            [71.424498000000028, 33.894856000000061],
            [71.424605000000042, 33.895144000000073],
            [71.424741623000045, 33.895558224000069],
            [71.424886127000036, 33.895746003000056],
            [71.425167377000037, 33.895879411000067],
            [71.426092460000064, 33.895813464000071],
            [71.426746102000038, 33.895649079000066],
            [71.427441000000044, 33.895356000000049],
            [71.428084467000076, 33.895066728000074],
            [71.429076405000046, 33.895005874000049],
            [71.429992021000032, 33.895005594000054],
            [71.43065484300007, 33.895015232000048],
            [71.431166102000077, 33.89495848100006],
            [71.431663348000029, 33.894886620000079],
            [71.43234654400004, 33.895100866000064],
            [71.432671000000084, 33.895359000000042],
            [71.433415000000082, 33.895906000000025],
            [71.434083000000044, 33.896220000000028],
            [71.434634000000074, 33.896807000000024],
            [71.434866000000056, 33.897201000000052],
            [71.435754632000055, 33.898176259000024],
            [71.436902996000072, 33.898670866000032],
            [71.437482135000039, 33.898764780000079],
            [71.43830156000007, 33.898955590000071],
            [71.438414148000049, 33.899034730000039],
            [71.43862865400007, 33.899185512000031],
            [71.438938764000056, 33.899474425000051],
            [71.439261591000047, 33.899532160000035],
            [71.439743896000039, 33.899569292000024],
            [71.440010283000049, 33.899775760000068],
            [71.440505287000065, 33.900418174000038],
            [71.440911583000059, 33.900915475000033],
            [71.441092000000083, 33.901160000000061],
            [71.441237000000058, 33.901361000000065],
            [71.441305000000057, 33.901626000000078],
            [71.441413911000041, 33.90191118000007],
            [71.441653000000031, 33.902213000000074],
            [71.442010000000039, 33.902479000000028],
            [71.442345270000033, 33.902525666000031],
            [71.444059000000038, 33.902231000000029],
            [71.444378000000029, 33.902248000000043],
            [71.445172000000071, 33.902329000000066],
            [71.445757368000045, 33.902707071000066],
            [71.446341000000075, 33.903154000000029],
            [71.447116363000077, 33.903873558000043],
            [71.447471588000042, 33.903987657000073],
            [71.44823800000006, 33.904087000000061],
            [71.44899300000003, 33.904361000000051],
            [71.449281099000075, 33.904591563000054],
            [71.449316000000067, 33.904826000000071],
            [71.449267000000077, 33.905003000000079],
            [71.449112000000071, 33.905212000000063],
            [71.448240000000055, 33.90587800000003],
            [71.448075000000074, 33.906127000000026],
            [71.448118811000029, 33.906258612000045],
            [71.448355000000049, 33.906569000000047],
            [71.448610387000031, 33.906699187000072],
            [71.449139000000059, 33.906867000000034],
            [71.449962000000085, 33.907020000000045],
            [71.450533000000064, 33.907278000000076],
            [71.451101855000047, 33.907564964000073],
            [71.451650000000029, 33.907695000000047],
            [71.452085000000068, 33.907655000000034],
            [71.453112000000033, 33.907366000000025],
            [71.45348000000007, 33.907439000000068],
            [71.454138000000057, 33.907745000000034],
            [71.454631000000063, 33.907978000000071],
            [71.45541515900004, 33.908045724000033],
            [71.456371972000056, 33.908029415000044],
            [71.456631000000073, 33.908025000000066],
            [71.457822000000078, 33.907897000000048],
            [71.458500000000072, 33.908034000000043],
            [71.458867000000055, 33.908211000000051],
            [71.459119000000044, 33.908493000000078],
            [71.459372666000036, 33.909058616000038],
            [71.460002000000031, 33.909846000000073],
            [71.460295089000056, 33.910227715000076],
            [71.460379000000046, 33.910337000000027],
            [71.460611523000068, 33.910524756000029],
            [71.460484476000033, 33.91063095100003],
            [71.460455447000072, 33.910655215000077],
            [71.460210975000052, 33.910858290000078],
            [71.459881577000033, 33.91122869000003],
            [71.459476686000073, 33.911739433000037],
            [71.458800790000055, 33.912684646000059],
            [71.458115914000075, 33.913649627000041],
            [71.457840237000084, 33.914040574000069],
            [71.458835782000051, 33.914604810000071],
            [71.460236498000029, 33.915336901000046],
            [71.460304850000057, 33.91537231500007],
            [71.460338877000083, 33.915953754000043],
            [71.460382313000082, 33.916458491000071],
            [71.460436888000061, 33.916974217000075],
            [71.460479916000054, 33.917735901000071],
            [71.460506842000029, 33.918434601000058],
            [71.460538616000065, 33.918843883000079],
            [71.46058425700005, 33.919248362000076],
            [71.460628512000028, 33.919785626000078],
            [71.460683688000074, 33.920515479000073],
            [71.460772252000083, 33.921247211000036],
            [71.460811026000044, 33.922006840000051],
            [71.460863619000065, 33.922489835000079],
            [71.460867256000029, 33.922568888000058],
            [71.460869805000073, 33.922624287000076],
            [71.460837622000042, 33.922766327000033],
            [71.46077822400008, 33.923006771000075],
            [71.460700961000043, 33.923267828000064],
            [71.460700659000054, 33.923268849000067],
            [71.46064390600003, 33.923375513000053],
            [71.460558092000042, 33.92348557300005],
            [71.460471733000077, 33.923572771000067],
            [71.459869404000074, 33.924201577000076],
            [71.45942380200006, 33.924582883000028],
            [71.458347708000076, 33.925610246000076],
            [71.456155685000056, 33.927726417000031],
            [71.455125711000051, 33.92869384200003],
            [71.454171317000032, 33.929607005000037],
            [71.453204735000043, 33.930484407000051],
            [71.452358736000065, 33.93121499800003],
            [71.451524285000062, 33.931975522000073],
            [71.450040232000049, 33.933328097000071],
            [71.449526078000076, 33.933800399000063],
            [71.449343876000057, 33.933951328000035],
            [71.449260720000041, 33.934007628000074],
            [71.449154607000082, 33.934079470000029],
            [71.448924114000079, 33.934227373000056],
            [71.44833636900006, 33.934547102000067],
            [71.447864991000074, 33.934768684000062],
            [71.447021405000044, 33.935005571000033],
            [71.44591812300007, 33.935284052000043],
            [71.444456507000041, 33.935645796000074],
            [71.443934520000084, 33.93577498500008],
            [71.443693300000064, 33.935834686000078],
            [71.442621304000056, 33.936109257000055],
            [71.441109065000035, 33.936496031000047],
            [71.439746999000079, 33.93690072000004],
            [71.439595697000073, 33.936922548000041],
            [71.43950643800008, 33.936915377000048],
            [71.439422887000035, 33.936903296000025],
            [71.439362732000063, 33.936883925000075],
            [71.439240386000051, 33.93684429800004],
            [71.438993911000068, 33.936708759000055],
            [71.438393097000073, 33.936334998000063],
            [71.437979824000081, 33.936077905000047],
            [71.437890717000073, 33.936022472000047],
            [71.43758151000003, 33.935868344000028],
            [71.437460750000071, 33.935827686000039],
            [71.437279648000072, 33.935794143000066],
            [71.437238717000071, 33.935789494000062],
            [71.436949645000084, 33.935756661000028],
            [71.43659628000006, 33.935758290000024],
            [71.436390903000074, 33.935793874000069],
            [71.436025979000078, 33.935888512000076],
            [71.435861009000064, 33.935930596000048],
            [71.435587380000072, 33.936000398000033],
            [71.435537387000068, 33.936026077000065],
            [71.435419366000076, 33.936149279000063],
            [71.435220202000039, 33.936371376000068],
            [71.435027871000045, 33.936658913000031],
            [71.434957927000085, 33.936806009000065],
            [71.434937052000066, 33.936919247000048],
            [71.434939479000036, 33.937018705000071],
            [71.434830909000084, 33.938255971000046],
            [71.434776935000059, 33.938711934000025],
            [71.434697778000043, 33.939380641000071],
            [71.434547129000066, 33.940695599000037],
            [71.434367249000047, 33.941493653000066],
            [71.434316089000049, 33.94163235700006],
            [71.434216406000075, 33.941841148000037],
            [71.43413617200008, 33.942005909000045],
            [71.433831275000045, 33.942543091000061],
            [71.43381725100005, 33.942567798000027],
            [71.433613018000074, 33.94280862100004],
            [71.433314835000033, 33.943151688000057],
            [71.433051736000039, 33.943454389000067],
            [71.43271922200006, 33.94380271700004],
            [71.432390492000081, 33.944160568000029],
            [71.431750543000078, 33.944857209000077],
            [71.430477714000062, 33.946250080000027],
            [71.430378533000066, 33.946359967000035],
            [71.430031443000075, 33.946744525000042],
            [71.429193742000052, 33.947672653000041],
            [71.428904627000065, 33.947992977000069],
            [71.428430442000035, 33.948512023000035],
            [71.428418584000042, 33.948525002000054],
            [71.427817362000042, 33.949183102000063],
            [71.427308731000039, 33.949734147000072],
            [71.426972774000035, 33.949987235000037],
            [71.426962596000067, 33.94998722400004],
            [71.426569731000029, 33.950266256000077],
            [71.42518563200008, 33.951253709000071],
            [71.425013106000051, 33.951376794000055],
            [71.424275702000045, 33.951838815000031],
            [71.423246084000084, 33.952483923000045],
            [71.422432760000049, 33.952993512000035],
            [71.420136577000051, 33.954384453000046],
            [71.419485652000049, 33.95480471500008],
            [71.419054186000039, 33.955083285000057],
            [71.418695340000056, 33.955281318000061],
            [71.418377713000041, 33.955409411000062],
            [71.41812010700005, 33.955555376000063],
            [71.418057614000077, 33.955590873000062],
            [71.417790982000042, 33.955742326000063],
            [71.417455958000062, 33.955937306000067],
            [71.417109742000036, 33.956191746000059],
            [71.416712453000059, 33.956588540000041],
            [71.416482057000053, 33.956815997000035],
            [71.41578445600004, 33.957504702000051],
            [71.414200264000044, 33.959068689000048],
            [71.414021364000064, 33.959230912000066],
            [71.413218266000058, 33.959959147000063],
            [71.412958810000077, 33.960194415000046],
            [71.412540657000079, 33.960686658000043],
            [71.412318183000082, 33.961049582000044],
            [71.412208072000055, 33.961343874000079],
            [71.412086269000042, 33.961669414000028],
            [71.411984989000075, 33.962081167000065],
            [71.411974288000067, 33.962564297000029],
            [71.41194581700006, 33.963685203000068],
            [71.411948566000035, 33.963685221000048],
            [71.411947282000028, 33.964313239000035],
            [71.411946132000082, 33.964875949000032],
            [71.411884658000076, 33.966114135000055],
            [71.411904457000048, 33.966764484000066],
            [71.412059999000064, 33.967776471000036],
            [71.412144840000053, 33.968297805000077],
            [71.412166822000074, 33.968432877000055],
            [71.412191667000059, 33.968505745000073],
            [71.412410968000074, 33.969148940000025],
            [71.412596327000074, 33.969771030000061],
            [71.412756462000061, 33.970308462000048],
            [71.412759759000039, 33.970318011000074],
            [71.412884892000079, 33.970680381000079],
            [71.413125105000063, 33.97137600800005],
            [71.413231175000078, 33.971683173000031],
            [71.41355291800005, 33.972636715000078],
            [71.413673323000069, 33.972993555000073],
            [71.414317990000029, 33.974856683000041],
            [71.41432773400004, 33.974884843000041],
            [71.414356548000058, 33.97496811700006],
            [71.414374853000083, 33.975026266000043],
            [71.414411775000076, 33.97514355800007],
            [71.414531370000077, 33.97552347900006],
            [71.414545729000054, 33.975569092000057],
            [71.414591542000039, 33.975714629000038],
            [71.414715127000079, 33.976107222000053],
            [71.414741166000056, 33.976325211000074],
            [71.414753502000053, 33.976428487000078],
            [71.414760620000038, 33.976649192000025],
            [71.414660433000051, 33.976967616000024],
            [71.41457001100008, 33.97725347100004],
            [71.414480701000059, 33.977424822000046],
            [71.414452961000052, 33.977478044000065],
            [71.41416816900005, 33.978024446000063],
            [71.414156966000064, 33.978045940000072],
            [71.413837881000063, 33.978658138000071],
            [71.413610242000061, 33.979094886000041],
            [71.413526321000063, 33.979256857000053],
            [71.413331017000075, 33.979633801000034],
            [71.413202854000076, 33.979977659000042],
            [71.41304038800007, 33.980399240000054],
            [71.412970254000072, 33.980690388000028],
            [71.412944701000072, 33.98079646900004],
            [71.412839397000084, 33.981349113000078],
            [71.412812814000063, 33.981488625000054],
            [71.412544780000076, 33.982900917000052],
            [71.412542690000066, 33.982911930000057],
            [71.412487446000057, 33.983203015000072],
            [71.412473856000076, 33.983284298000058],
            [71.412449201000072, 33.983431754000037],
            [71.412368592000064, 33.983913870000038],
            [71.412236071000052, 33.984707559000071],
            [71.412073539000062, 33.985574901000064],
            [71.411989743000049, 33.98597025600003],
            [71.411980951000032, 33.986002838000047],
            [71.411865024000065, 33.98643246000006],
            [71.411803985000063, 33.986658669000064],
            [71.411797699000033, 33.986681966000049],
            [71.411689237000076, 33.987003980000054],
            [71.411515689000055, 33.98734195000003],
            [71.411469755000041, 33.987431403000073],
            [71.411257581000029, 33.987844592000044],
            [71.411049365000054, 33.98824981000007],
            [71.410975607000069, 33.988393354000038],
            [71.410852286000079, 33.988580268000078],
            [71.41068540100008, 33.988833212000031],
            [71.410472330000061, 33.989128427000026],
            [71.410261768000055, 33.989379880000058],
            [71.409903547000056, 33.989807668000026],
            [71.409838176000051, 33.989887986000042],
            [71.409440203000031, 33.990376955000045],
            [71.40902998100006, 33.990880974000049],
            [71.408741572000054, 33.991235328000073],
            [71.408727765000037, 33.99125229200007],
            [71.40868355200007, 33.991306615000042],
            [71.408615860000054, 33.991388941000025],
            [71.407997279000028, 33.992141249000042],
            [71.40792635300005, 33.992227508000042],
            [71.407346878000055, 33.992932257000064],
            [71.40662130000004, 33.993796846000066],
            [71.40640690400005, 33.994052317000069],
            [71.406274197000073, 33.99420321100007],
            [71.406262302000073, 33.994216737000045],
            [71.405829351000079, 33.994709025000077],
            [71.405813434000038, 33.994727123000075],
            [71.40536361900007, 33.995238586000028],
            [71.405356048000044, 33.995247857000038],
            [71.405066135000084, 33.995602881000025],
            [71.40506227000003, 33.99560761400005],
            [71.404781922000041, 33.995950925000045],
            [71.404172586000072, 33.996650336000073],
            [71.404125499000031, 33.996703723000053],
            [71.403979864000064, 33.996868845000051],
            [71.403567164000037, 33.997336766000046],
            [71.403542231000074, 33.997366058000068],
            [71.403282060000038, 33.997671715000024],
            [71.402712474000054, 33.998340882000036],
            [71.402461867000056, 33.998449672000049],
            [71.402329003000034, 33.998497396000062],
            [71.402164024000058, 33.998566605000065],
            [71.401990362000049, 33.998653328000046],
            [71.401861908000058, 33.998735788000033],
            [71.401524512000037, 33.998974317000034],
            [71.401004213000078, 33.999354918000051],
            [71.400682876000076, 33.999581976000059],
            [71.400430559000029, 33.999760264000031],
            [71.400097606000031, 34.000002398000049],
            [71.399770829000033, 34.000252646000035],
            [71.399382978000062, 34.000548461000051],
            [71.398961077000081, 34.000863949000063],
            [71.398574612000061, 34.001122956000074],
            [71.398474196000052, 34.001179045000072],
            [71.398182138000038, 34.001363266000055],
            [71.397921533000044, 34.001475596000034],
            [71.397696873000029, 34.001542994000033],
            [71.39741829500008, 34.001578940000059],
            [71.397189142000059, 34.001592419000076],
            [71.396942016000082, 34.001583433000064],
            [71.39667242400003, 34.001520528000071],
            [71.396510669000065, 34.001444144000061],
            [71.396263543000032, 34.001340800000037],
            [71.395617491000053, 34.001066116000061],
            [71.39547814000008, 34.001011659000028],
            [71.394796807000034, 34.000745398000049],
            [71.393543510000029, 34.000211944000057],
            [71.392201023000041, 33.999636148000036],
            [71.391763646000072, 33.999458798000035],
            [71.391415061000032, 33.999338930000079],
            [71.391186159000029, 33.999262978000047],
            [71.391015461000052, 33.999232191000033],
            [71.390645413000072, 33.999192513000025],
            [71.390130816000067, 33.999164692000079],
            [71.389916123000035, 33.999176190000071],
            [71.389724009000076, 33.999197004000052],
            [71.389510983000037, 33.999240004000058],
            [71.389261601000044, 33.999320603000058],
            [71.389118721000045, 33.999381276000065],
            [71.388983323000048, 33.999436868000032],
            [71.388628632000064, 33.999633426000059],
            [71.388168834000055, 33.999903217000053],
            [71.388039265000032, 33.999979243000041],
            [71.387331059000076, 34.000402387000065],
            [71.386993989000075, 34.000610461000065],
            [71.386812821000035, 34.000718127000027],
            [71.386690996000084, 34.000775481000062],
            [71.386473886000033, 34.000867220000032],
            [71.386216887000046, 34.000963472000024],
            [71.384969673000057, 34.001377362000028],
            [71.384507961000054, 34.001526596000076],
            [71.383939245000079, 34.001685734000034],
            [71.382910154000058, 34.001965646000031],
            [71.382014704000028, 34.002195404000076],
            [71.381522572000051, 34.00231249400008],
            [71.381109637000066, 34.002392052000062],
            [71.380968777000078, 34.002414003000069],
            [71.381029144000081, 34.003698109000027],
            [71.381054920000054, 34.004085408000037],
            [71.381146806000061, 34.005634593000025],
            [71.381229533000067, 34.007034822000037],
            [71.381253202000039, 34.007512278000036],
            [71.381319549000068, 34.008850621000079],
            [71.381343751000031, 34.009311911000054],
            [71.381401750000066, 34.010417361000066],
            [71.381434130000059, 34.010544149000054],
            [71.381492521000041, 34.010683736000033],
            [71.381579786000032, 34.01089352200006],
            [71.381604335000077, 34.010952538000026],
            [71.381913869000073, 34.011654509000039],
            [71.381945247000033, 34.011725670000033],
            [71.382235779000041, 34.012372587000073],
            [71.382598015000042, 34.013179162000029],
            [71.382635227000037, 34.013264741000057],
            [71.383094199000084, 34.014320283000075],
            [71.383645302000048, 34.015582036000069],
            [71.384164278000071, 34.016704656000059],
            [71.38431171700006, 34.017023589000075],
            [71.384515285000077, 34.017511030000037],
            [71.384692549000079, 34.017935486000056],
            [71.384886384000083, 34.018472760000066],
            [71.384913716000028, 34.018548521000071],
            [71.385009984000078, 34.018857519000051],
            [71.385040454000034, 34.018955319000042],
            [71.385328366000067, 34.019864348000056],
            [71.385529280000071, 34.020498695000072],
            [71.385534067000037, 34.020513809000079],
            [71.385763756000074, 34.021229547000075],
            [71.385907708000047, 34.021678122000026],
            [71.385940030000029, 34.021778840000025],
            [71.38600272900004, 34.021980070000041],
            [71.386215748000041, 34.022663746000035],
            [71.386404914000082, 34.023270867000065],
            [71.386422058000051, 34.023325890000024],
            [71.386796615000037, 34.024572165000052],
            [71.386681236000072, 34.024598319000063],
            [71.386509368000077, 34.024662818000024],
            [71.386280286000044, 34.024784078000039],
            [71.38621469900005, 34.024843429000043],
            [71.386125633000063, 34.024950569000055],
            [71.385981171000083, 34.025166816000024],
            [71.385796153000058, 34.025475844000027],
            [71.385561971000072, 34.025727099000051],
            [71.385173211000051, 34.025936706000039],
            [71.383354071000042, 34.026984665000043],
            [71.382942580000076, 34.02722282600007],
            [71.382505602000037, 34.027478036000048],
            [71.382102394000071, 34.027697870000054],
            [71.381627595000054, 34.027976386000034],
            [71.381171270000038, 34.028257002000032],
            [71.380605120000041, 34.028576435000048],
            [71.380299207000064, 34.028750632000026],
            [71.379897258000028, 34.028960550000079],
            [71.379754705000039, 34.029036676000032],
            [71.379184619000057, 34.029343335000078],
            [71.378794456000037, 34.029571814000064],
            [71.378482750000046, 34.029778170000043],
            [71.378300157000069, 34.029927033000035],
            [71.378082420000055, 34.030170610000027],
            [71.377971000000059, 34.030308000000048],
            [71.377557807000073, 34.03094014800007],
            [71.377282284000046, 34.031280792000075],
            [71.377081658000066, 34.031441675000053],
            [71.376739000000043, 34.031635000000051],
            [71.376422588000082, 34.031798511000034],
            [71.376716559000045, 34.032033693000074],
            [71.376900105000061, 34.032213400000046],
            [71.377071857000033, 34.032386525000049],
            [71.377386332000071, 34.032802336000032],
            [71.37754788500007, 34.033147666000048],
            [71.377704398000049, 34.033436446000053],
            [71.377825636000068, 34.03358656100005],
            [71.377940264000074, 34.033832602000075],
            [71.378163623000034, 34.034109407000074],
            [71.378301304000047, 34.034259888000065],
            [71.378439797000055, 34.034473066000032],
            [71.378648961000067, 34.034700391000058],
            [71.37888627600006, 34.034882314000072],
            [71.379136374000041, 34.035063102000038],
            [71.379380455000046, 34.03524145800003],
            [71.379687000000047, 34.035437000000059],
            [71.379822000000047, 34.035544000000073],
            [71.379961000000037, 34.03565100000003],
            [71.380105000000071, 34.035758000000044],
            [71.380254000000036, 34.035865000000058],
            [71.380408000000045, 34.03597300000007],
            [71.380569000000037, 34.036083000000076],
            [71.38073600000007, 34.03619400000008],
            [71.38091200000008, 34.036308000000076],
            [71.381200000000035, 34.036489000000074],
            [71.381523000000072, 34.036686000000032],
            [71.381854000000033, 34.036884000000043],
            [71.382756000000029, 34.037418000000059],
            [71.383028000000081, 34.037582000000043],
            [71.383280554000066, 34.037682951000079],
            [71.383634099000062, 34.037812806000034],
            [71.383995069000036, 34.038013126000067],
            [71.384456577000037, 34.038286734000053],
            [71.385015226000064, 34.038658982000072],
            [71.38557226000006, 34.039019344000053],
            [71.385987219000071, 34.039342376000036],
            [71.38622108800007, 34.039485232000061],
            [71.386503516000062, 34.039671199000054],
            [71.386805338000045, 34.03987588800004],
            [71.38707048100008, 34.040079168000034],
            [71.387339098000041, 34.040271021000024],
            [71.387505592000082, 34.040383262000034],
            [71.387714496000058, 34.040521694000063],
            [71.38788849000008, 34.040628086000027],
            [71.388135010000042, 34.040750839000054],
            [71.388327459000038, 34.040833452000072],
            [71.388448433000065, 34.040878988000031],
            [71.388570702000038, 34.040907102000062],
            [71.38866639400004, 34.04092326500006],
            [71.388781000000051, 34.040949000000069],
            [71.388895000000048, 34.040980000000047],
            [71.389010000000042, 34.041008000000033],
            [71.389128000000028, 34.041035000000079],
            [71.389216000000033, 34.041053000000034],
            [71.389307000000031, 34.041070000000047],
            [71.389499000000058, 34.041099000000031],
            [71.389709000000039, 34.041124000000025],
            [71.389947000000063, 34.041145000000029],
            [71.390034000000071, 34.041150000000073],
            [71.390126000000066, 34.041154000000063],
            [71.390221000000054, 34.041154000000063],
            [71.39032300000008, 34.041153000000065],
            [71.390538000000049, 34.041143000000034],
            [71.390975000000083, 34.041113000000053],
            [71.391161000000068, 34.041103000000078],
            [71.39126200000004, 34.041099000000031],
            [71.391357000000085, 34.041098000000034],
            [71.39144600000003, 34.041099000000031],
            [71.391532000000041, 34.041102000000024],
            [71.391867000000047, 34.041119000000037],
            [71.391974417000029, 34.041126437000059],
            [71.391939389000072, 34.041188376000036],
            [71.391873240000052, 34.041297941000039],
            [71.391864124000051, 34.041360293000025],
            [71.391882369000029, 34.041420817000073],
            [71.391932562000079, 34.041489754000054],
            [71.391989643000045, 34.041569269000036],
            [71.392133704000059, 34.041903155000057],
            [71.392354467000075, 34.04248437800004],
            [71.392634949000069, 34.043228231000057],
            [71.392974378000076, 34.044079981000039],
            [71.393297363000045, 34.044905700000072],
            [71.393622209000057, 34.045762607000029],
            [71.393881694000072, 34.046475694000037],
            [71.394105703000037, 34.047141443000044],
            [71.394116386000064, 34.047177851000072],
            [71.394362880000074, 34.04801793300004],
            [71.394827777000046, 34.049631027000032],
            [71.394849290000082, 34.04970219300003],
            [71.395209355000077, 34.050893298000062],
            [71.395681847000048, 34.052462172000048],
            [71.396121056000084, 34.05388294200003],
            [71.396354605000056, 34.054724174000057],
            [71.396431262000078, 34.055000289000077],
            [71.39681078600006, 34.05625738100008],
            [71.397190457000079, 34.057533746000047],
            [71.397643348000031, 34.058985887000063],
            [71.398106272000064, 34.060613357000079],
            [71.398557150000045, 34.062138339000057],
            [71.398593965000032, 34.062260754000079],
            [71.398656646000063, 34.062469180000051],
            [71.398934441000051, 34.063392904000068],
            [71.399459860000036, 34.065001921000032],
            [71.399773183000036, 34.066019087000029],
            [71.399783944000035, 34.066055920000053],
            [71.399793835000082, 34.06608977500008],
            [71.40045107800006, 34.068339370000047],
            [71.400312000000042, 34.068396000000064],
            [71.399794000000043, 34.068611000000033],
            [71.399563000000057, 34.06870200000003],
            [71.399304000000029, 34.068802000000062],
            [71.398242000000039, 34.069200000000023],
            [71.397332000000063, 34.069548000000054],
            [71.395382000000041, 34.070293000000049],
            [71.39417700000007, 34.070754000000079],
            [71.392300000000034, 34.071477000000073],
            [71.390743000000043, 34.072067000000061],
            [71.390099000000077, 34.072314000000063],
            [71.389192000000037, 34.072674000000063],
            [71.388870000000054, 34.072799000000032],
            [71.388656000000083, 34.072879000000057],
            [71.388094000000081, 34.073086000000046],
            [71.387929000000042, 34.073149000000058],
            [71.387782000000072, 34.073208000000079],
            [71.387550000000033, 34.073298000000079],
            [71.38729177600004, 34.07344303800005],
            [71.387138021000055, 34.073561897000047],
            [71.387046305000069, 34.073648429000059],
            [71.386927703000083, 34.073791973000027],
            [71.386846157000036, 34.073914089000027],
            [71.386803798000074, 34.073998373000052],
            [71.386761504000049, 34.074110056000052],
            [71.386723486000051, 34.074199173000068],
            [71.386700977000032, 34.074329294000052],
            [71.386688259000039, 34.074451568000029],
            [71.386682288000031, 34.074628700000062],
            [71.386702586000069, 34.07477366300003],
            [71.386715913000046, 34.074948689000053],
            [71.386725873000046, 34.07503084700005],
            [71.386855819000061, 34.075908345000073],
            [71.387030399000082, 34.077314867000041],
            [71.387264168000058, 34.079118325000024],
            [71.387413768000044, 34.08024957300006],
            [71.387735759000066, 34.082703182000046],
            [71.38803914500005, 34.085187061000056],
            [71.388335020000056, 34.087487809000038],
            [71.388397112000064, 34.088001764000069],
            [71.388461481000036, 34.088514720000035],
            [71.388647509000066, 34.089896631000045],
            [71.388740837000057, 34.090558391000059],
            [71.388810609000075, 34.091135555000051],
            [71.388887486000044, 34.091713682000034],
            [71.388927943000056, 34.092042094000078],
            [71.38893881100006, 34.092130316000066],
            [71.388961807000044, 34.092406156000038],
            [71.388993993000042, 34.092694068000071],
            [71.389118474000043, 34.093591974000049],
            [71.389142000000049, 34.09383500000007],
            [71.389169000000038, 34.094040000000064],
            [71.389205000000061, 34.094350000000077],
            [71.389273113000058, 34.094891762000032],
            [71.389315456000077, 34.09517839800003],
            [71.389344636000033, 34.095381150000037],
            [71.389346456000055, 34.095469829000024],
            [71.389354680000054, 34.095542217000059],
            [71.389349293000066, 34.095607388000076],
            [71.389349239000069, 34.095714054000041],
            [71.389334151000071, 34.095861024000044],
            [71.389327579000053, 34.095986337000056],
            [71.38930515800007, 34.096132212000043],
            [71.389270802000055, 34.096251398000049],
            [71.389248748000057, 34.096331254000063],
            [71.389231090000067, 34.096397981000052],
            [71.389171024000063, 34.096566684000038],
            [71.38912905400008, 34.096659905000024],
            [71.389077047000058, 34.096815007000032],
            [71.388953360000073, 34.097095040000056],
            [71.388884497000049, 34.097261207000031],
            [71.388810131000071, 34.097422403000053],
            [71.388739001000033, 34.097564067000064],
            [71.388659706000055, 34.097755463000055],
            [71.388569181000037, 34.097929037000029],
            [71.388486046000082, 34.09804678100005],
            [71.388428000000033, 34.098115000000064],
            [71.388390000000072, 34.098161000000061],
            [71.388302000000067, 34.098260000000039],
            [71.388219000000049, 34.098349000000042],
            [71.388142000000073, 34.098428000000069],
            [71.388029000000074, 34.098537000000078],
            [71.387980000000084, 34.098581000000024],
            [71.387933000000032, 34.098621000000037],
            [71.387886000000037, 34.098658000000057],
            [71.387834000000055, 34.098697000000072],
            [71.387699000000055, 34.098791000000062],
            [71.387622000000079, 34.098840000000052],
            [71.387428000000057, 34.098960000000034],
            [71.387247000000059, 34.099076000000025],
            [71.387193000000082, 34.099112000000048],
            [71.38714600000003, 34.099147000000073],
            [71.38704000000007, 34.099227000000042],
            [71.38699500000007, 34.099270000000047],
            [71.386944000000028, 34.099330000000066],
            [71.386869000000047, 34.099447000000055],
            [71.386802616000068, 34.09954411700005],
            [71.38675822700003, 34.099624722000044],
            [71.386724452000067, 34.09972087500006],
            [71.386724251000032, 34.099801720000073],
            [71.386732797000036, 34.099884803000066],
            [71.386738616000059, 34.09995499200005],
            [71.38676590700004, 34.100040887000034],
            [71.386798768000062, 34.100140435000071],
            [71.386898685000062, 34.100295082000059],
            [71.386952700000052, 34.100396853000063],
            [71.387028631000078, 34.10051482700004],
            [71.387082901000042, 34.100617530000079],
            [71.387182587000041, 34.100749906000033],
            [71.387269685000035, 34.100872252000045],
            [71.387408106000066, 34.101044076000051],
            [71.387544845000036, 34.101274403000048],
            [71.387619856000072, 34.101400753000064],
            [71.387787372000048, 34.101656528000035],
            [71.387908280000033, 34.101852527000062],
            [71.387985309000044, 34.101983202000042],
            [71.388057737000054, 34.102084071000036],
            [71.388126782000029, 34.102215001000047],
            [71.388203586000031, 34.102336320000063],
            [71.38826372300008, 34.102453850000074],
            [71.388316703000044, 34.102566786000068],
            [71.388364092000074, 34.102668318000042],
            [71.388402258000042, 34.102773957000068],
            [71.388429827000039, 34.102883896000037],
            [71.388462356000048, 34.102980398000057],
            [71.388483508000036, 34.103070039000045],
            [71.388497376000032, 34.103155120000054],
            [71.388513229000068, 34.103277966000064],
            [71.388511891000064, 34.103411114000039],
            [71.388520629000084, 34.103542767000079],
            [71.388523255000052, 34.103689647000067],
            [71.388519362000068, 34.104017862000035],
            [71.388523203000034, 34.104494532000047],
            [71.388524293000046, 34.105292510000027],
            [71.388520691000053, 34.105488901000058],
            [71.388521148000052, 34.105719818000068],
            [71.388509372000044, 34.105970309000043],
            [71.388512189000039, 34.106083785000067],
            [71.388510993000068, 34.106181608000043],
            [71.388523680000048, 34.106299528000079],
            [71.388534269000047, 34.106400777000033],
            [71.388550609000049, 34.106503380000049],
            [71.388577329000043, 34.106640372000072],
            [71.388603715000045, 34.106746716000032],
            [71.388631646000078, 34.106833835000032],
            [71.388657673000068, 34.106926249000026],
            [71.388694015000056, 34.107021045000067],
            [71.388729707000039, 34.107098516000065],
            [71.388777320000031, 34.107186999000078],
            [71.388815041000043, 34.107261547000064],
            [71.388867927000035, 34.107360612000036],
            [71.38896300600004, 34.107502226000065],
            [71.389041729000041, 34.107609783000044],
            [71.389149909000082, 34.107730017000051],
            [71.389244230000031, 34.10785702700008],
            [71.389325832000054, 34.107935647000033],
            [71.389405148000037, 34.10801093300006],
            [71.389592305000065, 34.108210648000068],
            [71.389999815000067, 34.108625096000026],
            [71.390422414000057, 34.109057467000071],
            [71.390585642000076, 34.109228806000033],
            [71.39084889600008, 34.109485715000062],
            [71.391000624000071, 34.109625764000043],
            [71.391150444000061, 34.109800362000044],
            [71.391238214000055, 34.109892251000076],
            [71.39131496400006, 34.10998730600005],
            [71.391399704000037, 34.110091526000076],
            [71.391462048000051, 34.110180203000027],
            [71.391525410000042, 34.110268612000027],
            [71.391586697000037, 34.110393990000034],
            [71.39165538900005, 34.11053170200006],
            [71.391700434000029, 34.110613600000079],
            [71.391732637000075, 34.110691016000033],
            [71.391762823000079, 34.11076707400008],
            [71.391795604000038, 34.110890777000066],
            [71.391832545000057, 34.11103072700007],
            [71.391856587000063, 34.111141807000024],
            [71.391876671000034, 34.111254963000079],
            [71.391887249000035, 34.111376459000041],
            [71.391880167000068, 34.111630149000064],
            [71.391877738000062, 34.111768349000045],
            [71.39184012000004, 34.112112848000038],
            [71.391832763000082, 34.112382657000069],
            [71.391800738000029, 34.112938363000069],
            [71.391784610000059, 34.113168323000025],
            [71.39173123900008, 34.113929309000071],
            [71.39164476500008, 34.115377261000049],
            [71.39163942700003, 34.115478923000069],
            [71.391553000000044, 34.117125000000044],
            [71.391507647000083, 34.117731413000058],
            [71.39149100000003, 34.117954000000054],
            [71.391467000000034, 34.118302000000028],
            [71.391448000000082, 34.118711000000076],
            [71.391440000000046, 34.118813000000046],
            [71.391406801000073, 34.119074047000026],
            [71.391351573000065, 34.119302553000068],
            [71.391284579000057, 34.119467180000072],
            [71.391164566000043, 34.119704587000058],
            [71.390993434000052, 34.119993323000074],
            [71.390890332000083, 34.120117730000061],
            [71.390733326000031, 34.120305065000025],
            [71.39059052500005, 34.120460318000028],
            [71.390440452000064, 34.120606063000025],
            [71.390445849000059, 34.120643461000043],
            [71.390557665000074, 34.12070830600004],
            [71.39068410200008, 34.120781630000067],
            [71.391158463000068, 34.121127150000063],
            [71.391275983000071, 34.121228994000035],
            [71.391338493000035, 34.121308751000072],
            [71.391415558000062, 34.121427149000056],
            [71.391507971000067, 34.121592588000055],
            [71.391554542000051, 34.121665054000061],
            [71.391613757000073, 34.12175544300004],
            [71.391725277000035, 34.121953746000031],
            [71.392211193000037, 34.122798593000027],
            [71.392411020000054, 34.123122868000053],
            [71.392577250000045, 34.123406823000039],
            [71.392706479000083, 34.123654891000058],
            [71.393031000000065, 34.124187000000063],
            [71.393176574000051, 34.124417636000032],
            [71.393397941000046, 34.124835451000024],
            [71.393518411000059, 34.125049060000038],
            [71.393647954000073, 34.125266957000065],
            [71.393765435000034, 34.125452646000042],
            [71.393882857000051, 34.125635989000045],
            [71.393956535000029, 34.125742796000054],
            [71.394083033000072, 34.125934019000056],
            [71.394444585000031, 34.126391053000077],
            [71.394606167000063, 34.126561233000075],
            [71.394691341000055, 34.12661337000003],
            [71.39488097900005, 34.12668285500007],
            [71.395230110000057, 34.126782695000031],
            [71.395829793000075, 34.126949209000031],
            [71.396498489000066, 34.127146512000024],
            [71.396746088000043, 34.127216430000033],
            [71.396850892000032, 34.127256380000063],
            [71.396968556000047, 34.12730317200004],
            [71.397045201000083, 34.127339670000026],
            [71.39718008300008, 34.127420764000078],
            [71.397400729000083, 34.127594575000046],
            [71.397601000000066, 34.127775000000042],
            [71.398046000000079, 34.128145000000075],
            [71.398176067000065, 34.128253390000054],
            [71.398340000000076, 34.128390000000024],
            [71.39866138800005, 34.128659780000078],
            [71.399616940000044, 34.129465670000059],
            [71.400077983000074, 34.129858320000039],
            [71.400661359000082, 34.130355155000075],
            [71.401088779000077, 34.130727266000065],
            [71.401432334000049, 34.13100096900007],
            [71.401511212000059, 34.131071776000056],
            [71.401590639000062, 34.131138983000028],
            [71.401756000000034, 34.131305000000054],
            [71.401809000000071, 34.131357000000037],
            [71.401854000000071, 34.131406000000027],
            [71.40189300000003, 34.131453000000079],
            [71.40193400000004, 34.131507000000056],
            [71.402093239000067, 34.131715963000033],
            [71.402464675000033, 34.132260928000051],
            [71.402623778000077, 34.132463205000079],
            [71.402813184000081, 34.132753391000051],
            [71.40299744400005, 34.13302339300003],
            [71.403196566000076, 34.133311506000041],
            [71.403388327000073, 34.133598536000079],
            [71.403708317000053, 34.134037869000053],
            [71.403958154000065, 34.134392784000056],
            [71.404139175000068, 34.134647605000055],
            [71.404330895000044, 34.13495057800003],
            [71.404418595000038, 34.135086070000057],
            [71.404465169000048, 34.13516142900005],
            [71.404512067000041, 34.135219937000045],
            [71.404560436000054, 34.135282259000064],
            [71.404598000000078, 34.135347000000024],
            [71.404639352000061, 34.135391773000038],
            [71.404731000000083, 34.135491000000059],
            [71.404822000000081, 34.135598000000073],
            [71.405025987000045, 34.13581356800006],
            [71.405119735000028, 34.135910838000029],
            [71.405253365000078, 34.136036440000055],
            [71.405316433000053, 34.136105611000062],
            [71.405507795000062, 34.136315489000026],
            [71.405620579000072, 34.136437731000058],
            [71.405740308000077, 34.136556910000024],
            [71.40603806300004, 34.136877668000068],
            [71.406249456000069, 34.137091767000072],
            [71.406540476000032, 34.13740916900008],
            [71.407053905000055, 34.137956059000032],
            [71.407398000000057, 34.138316000000032],
            [71.407488000000058, 34.138410000000079],
            [71.407567000000029, 34.138495000000034],
            [71.407684000000074, 34.13862800000004],
            [71.407852000000048, 34.138822000000062],
            [71.407983000000058, 34.138975000000073],
            [71.40805000000006, 34.139054000000044],
            [71.408072000000061, 34.139131000000077],
            [71.408049873000039, 34.139203485000053],
            [71.408043000000077, 34.139226000000065],
            [71.407982000000061, 34.139298000000053],
            [71.407933000000071, 34.139378000000079],
            [71.40788113800005, 34.139475914000059],
            [71.407884000000081, 34.139615000000049],
            [71.407913496000049, 34.139691182000035],
            [71.407991060000029, 34.13991705400008],
            [71.408041098000069, 34.140153481000027],
            [71.408098820000077, 34.140392210000073],
            [71.408261498000059, 34.141022246000034],
            [71.408463558000051, 34.141812945000027],
            [71.408536975000061, 34.142057567000052],
            [71.408578000000034, 34.142269000000056],
            [71.408616854000059, 34.142365322000046],
            [71.408655632000034, 34.142429838000055],
            [71.408760000000029, 34.142495000000054],
            [71.408837000000062, 34.142535000000066],
            [71.409028000000035, 34.14261700000003],
            [71.40910000000008, 34.142652000000055],
            [71.409190132000049, 34.142701576000036],
            [71.409279199000082, 34.142763200000047],
            [71.409365837000053, 34.142825078000044],
            [71.40945088400008, 34.142886803000067],
            [71.409585288000073, 34.142958715000077],
            [71.409795442000075, 34.143082135000043],
            [71.409854820000078, 34.143124071000045],
            [71.409915641000055, 34.143179843000041],
            [71.409989507000034, 34.143265971000062],
            [71.410037603000035, 34.143334358000061],
            [71.410079806000056, 34.143400598000028],
            [71.41010616300008, 34.143467575000045],
            [71.410169689000043, 34.143549621000034],
            [71.410202458000072, 34.143613438000045],
            [71.410234027000058, 34.143679502000055],
            [71.410339113000077, 34.143856448000065],
            [71.410381519000055, 34.143947507000064],
            [71.410418551000078, 34.144026248000046],
            [71.41049828000007, 34.144205269000054],
            [71.410556276000079, 34.144304515000044],
            [71.410597573000075, 34.144381529000043],
            [71.410686248000047, 34.144527913000047],
            [71.410732290000055, 34.144604246000029],
            [71.410798286000045, 34.144704860000047],
            [71.410837232000063, 34.14478469200003],
            [71.41089499900005, 34.14488446200005],
            [71.410957631000031, 34.144957332000047],
            [71.411028803000079, 34.145021077000024],
            [71.411111577000042, 34.145072325000058],
            [71.411194214000034, 34.145131922000076],
            [71.411302956000043, 34.145187147000058],
            [71.411441945000036, 34.145271102000038],
            [71.411610966000069, 34.145373908000067],
            [71.411832656000058, 34.145513435000055],
            [71.411910503000058, 34.145547772000043],
            [71.412005134000083, 34.145601678000048],
            [71.41208789500007, 34.145661998000037],
            [71.412258521000069, 34.145760300000063],
            [71.412339706000068, 34.145820778000029],
            [71.41246521000005, 34.145917785000051],
            [71.412828596000054, 34.146249761000036],
            [71.412890000000061, 34.146308000000033],
            [71.412963068000067, 34.14637970900003],
            [71.413082000000031, 34.146506000000045],
            [71.413106135000078, 34.146537376000026],
            [71.41314200000005, 34.146584000000075],
            [71.413186543000052, 34.146643568000059],
            [71.413245834000065, 34.146709239000074],
            [71.413260611000055, 34.14679691200007],
            [71.413256398000044, 34.146915985000078],
            [71.413243414000078, 34.147014063000029],
            [71.41324615700006, 34.147103317000074],
            [71.41323729700008, 34.147188783000047],
            [71.413226000000066, 34.147398000000067],
            [71.413220000000081, 34.147502000000031],
            [71.413221100000044, 34.147560635000048],
            [71.413220932000058, 34.147698314000024],
            [71.413217872000075, 34.14778847000008],
            [71.413224858000035, 34.147866757000031],
            [71.413247645000069, 34.147949539000024],
            [71.413281873000074, 34.148008796000056],
            [71.41334489500008, 34.14805751800003],
            [71.413421020000044, 34.148126631000025],
            [71.413518336000038, 34.14820443900004],
            [71.413612869000076, 34.148265238000079],
            [71.413672674000054, 34.148314109000069],
            [71.413726759000042, 34.148351758000047],
            [71.413789795000071, 34.148386372000061],
            [71.413866000000041, 34.148418000000049],
            [71.413966000000073, 34.148453000000075],
            [71.41402800000003, 34.148469000000034],
            [71.414195000000063, 34.148503000000062],
            [71.414288000000056, 34.148529000000053],
            [71.414391720000083, 34.148551186000077],
            [71.414469782000083, 34.148594555000045],
            [71.414497002000076, 34.148651489000031],
            [71.414480437000066, 34.148746885000037],
            [71.414452163000078, 34.148884295000073],
            [71.414426794000065, 34.149006740000061],
            [71.414394523000055, 34.149178254000049],
            [71.414390943000058, 34.149197282000046],
            [71.414333773000067, 34.149486007000064],
            [71.414276498000049, 34.14980244000003],
            [71.414150339000059, 34.150607637000064],
            [71.414118108000082, 34.150813280000079],
            [71.414091000000042, 34.150932000000068],
            [71.414073970000061, 34.151030458000037],
            [71.41406220600004, 34.151081524000062],
            [71.414077819000056, 34.151113846000044],
            [71.414161269000033, 34.151126591000036],
            [71.414209833000029, 34.151134008000042],
            [71.41428680100006, 34.151175212000055],
            [71.414357707000079, 34.151257610000073],
            [71.41439786400008, 34.151326224000059],
            [71.41441440400007, 34.151404532000072],
            [71.414398085000073, 34.151514611000039],
            [71.414373147000049, 34.151612557000078],
            [71.414336687000059, 34.151776785000038],
            [71.414313297000035, 34.151890839000032],
            [71.414288950000071, 34.152009563000036],
            [71.41423594500003, 34.152341402000047],
            [71.414038413000071, 34.153223338000032],
            [71.413998812000045, 34.153400149000049],
            [71.413952091000056, 34.153599288000066],
            [71.413925093000046, 34.153721276000056],
            [71.413860895000084, 34.154011350000076],
            [71.413858394000044, 34.154023916000028],
            [71.413820825000073, 34.154212709000035],
            [71.41379088900004, 34.154406870000059],
            [71.41375800000003, 34.154596000000026],
            [71.413729337000063, 34.154735368000047],
            [71.413713827000038, 34.154843940000035],
            [71.413698747000069, 34.15490434000003],
            [71.413693264000074, 34.154986433000033],
            [71.41370433700007, 34.15504951500003],
            [71.413747340000043, 34.155152915000031],
            [71.413801516000035, 34.155276939000032],
            [71.413828609000063, 34.155348053000068],
            [71.413846000000035, 34.155415000000062],
            [71.413850000000082, 34.15555100000006],
            [71.413847000000032, 34.155625000000043],
            [71.41383200000007, 34.155760000000043],
            [71.413820000000044, 34.155843000000061],
            [71.413805000000082, 34.155933000000061],
            [71.41378700000007, 34.156023000000062],
            [71.413777155000048, 34.156101565000029],
            [71.413739164000049, 34.156181549000053],
            [71.41373800000008, 34.156184000000053],
            [71.413706000000047, 34.156261000000029],
            [71.413674000000071, 34.156321000000048],
            [71.413576000000035, 34.15647800000005],
            [71.413442000000032, 34.15671100000003],
            [71.413374000000033, 34.156833000000063],
            [71.41332600000004, 34.156911000000036],
            [71.413300000000049, 34.156975000000045],
            [71.413276000000053, 34.157102000000066],
            [71.413249000000064, 34.157245000000046],
            [71.413223000000073, 34.157404000000042],
            [71.413149597000029, 34.157910876000074],
            [71.413149000000033, 34.15791500000006],
            [71.413122000000044, 34.158092000000067],
            [71.413026774000059, 34.158587046000036],
            [71.412986470000078, 34.15879656900006],
            [71.412931804000038, 34.159129862000043],
            [71.412902268000039, 34.159311309000032],
            [71.412863801000071, 34.159490005000066],
            [71.412834491000069, 34.159611290000043],
            [71.412776433000033, 34.159773526000038],
            [71.412753302000056, 34.159855881000055],
            [71.412718342000062, 34.159977773000037],
            [71.412679733000061, 34.160075155000072],
            [71.41263000500004, 34.160203301000024],
            [71.412603919000048, 34.160280543000056],
            [71.412568958000065, 34.160380012000076],
            [71.412537156000042, 34.160463510000056],
            [71.412500429000033, 34.160559934000048],
            [71.412378733000082, 34.160919198000045],
            [71.41228400500006, 34.161203921000038],
            [71.41220037800008, 34.161470874000031],
            [71.412159557000052, 34.16158342500006],
            [71.412099349000073, 34.161772029000076],
            [71.412077988000078, 34.161845501000073],
            [71.412071659000048, 34.161865851000073],
            [71.412057609000044, 34.161911029000066],
            [71.41201645700005, 34.162024190000068],
            [71.411996884000075, 34.16209226400008],
            [71.411980878000065, 34.162161139000034],
            [71.411922385000082, 34.162320502000057],
            [71.411920000000066, 34.162327000000062],
            [71.411898000000065, 34.162400000000048],
            [71.411856736000061, 34.162498489000029],
            [71.411832342000082, 34.162582339000039],
            [71.411804800000084, 34.162650248000034],
            [71.411762328000066, 34.162727755000049],
            [71.411732251000046, 34.162827934000063],
            [71.411709821000045, 34.162895515000059],
            [71.411667228000056, 34.16301390700005],
            [71.411628645000064, 34.163097247000053],
            [71.411579298000049, 34.163208164000025],
            [71.411490625000056, 34.163354201000061],
            [71.411445421000053, 34.163441190000071],
            [71.411415158000068, 34.163506294000058],
            [71.411396596000031, 34.163580202000048],
            [71.411368290000041, 34.163694478000025],
            [71.41133893500006, 34.163771921000034],
            [71.41130000000004, 34.163866000000041],
            [71.411270000000059, 34.163936000000035],
            [71.411226000000056, 34.164064000000053],
            [71.411177887000065, 34.164194613000063],
            [71.411118639000051, 34.164335313000038],
            [71.411097444000063, 34.164404761000071],
            [71.411107447000063, 34.16443627600006],
            [71.410800263000056, 34.164824643000031],
            [71.41040051400006, 34.165095901000029],
            [71.409372587000064, 34.165638418000071],
            [71.408974317000059, 34.165822652000031],
            [71.405364149000036, 34.167492667000033],
            [71.405169110000031, 34.167582890000062],
            [71.403103384000076, 34.168598877000079],
            [71.40341451300003, 34.168855602000065],
            [71.404274387000044, 34.169164275000071],
            [71.404847637000046, 34.169428852000067],
            [71.405685464000044, 34.169825717000037],
            [71.406920156000069, 34.170222582000065],
            [71.407912319000047, 34.170773784000062],
            [71.409147011000073, 34.171214745000043],
            [71.409037174000048, 34.174748237000074],
            [71.409322652000071, 34.174839210000073],
            [71.409472836000077, 34.174875991000079],
            [71.409590131000073, 34.174891539000043],
            [71.409840146000079, 34.174928673000068],
            [71.41001205200007, 34.174945825000066],
            [71.410034801000052, 34.174948095000047],
            [71.41022668800008, 34.174988738000025],
            [71.41039345300004, 34.175029348000066],
            [71.410741612000038, 34.175186618000055],
            [71.41160431000003, 34.175684423000064],
            [71.412228352000056, 34.176085860000057],
            [71.412414639000076, 34.176175504000071],
            [71.412692417000073, 34.176282786000058],
            [71.413251694000053, 34.176459602000079],
            [71.413813486000038, 34.176656102000038],
            [71.414493661000051, 34.17688952900005],
            [71.41530528100003, 34.177216483000052],
            [71.415458894000039, 34.177300856000045],
            [71.415600700000084, 34.177381680000053],
            [71.41572069700004, 34.177438468000048],
            [71.415830651000078, 34.177462621000075],
            [71.416031031000045, 34.177495555000064],
            [71.416777248000074, 34.177560645000028],
            [71.417815222000058, 34.177663909000046],
            [71.419299512000066, 34.17774797900006],
            [71.41947489000006, 34.177769042000079],
            [71.420299585000066, 34.177939223000067],
            [71.420891035000068, 34.178057667000076],
            [71.421001647000082, 34.178079818000072],
            [71.421378988000072, 34.178141260000075],
            [71.421582047000072, 34.178164934000051],
            [71.422005099000046, 34.178175730000078],
            [71.422460069000067, 34.17819724800006],
            [71.422787590000041, 34.178184896000062],
            [71.422953606000078, 34.178219354000078],
            [71.423031639000044, 34.178260211000065],
            [71.423110822000069, 34.178368227000078],
            [71.424098531000084, 34.17971691200006],
            [71.424135831000058, 34.179816238000058],
            [71.424195607000058, 34.180477220000057],
            [71.424228707000054, 34.180556066000065],
            [71.424278058000084, 34.18069074400006],
            [71.424480957000071, 34.181244444000072],
            [71.424580766000076, 34.181400831000076],
            [71.424721368000064, 34.181553051000037],
            [71.424898289000055, 34.181667036000078],
            [71.425903037000069, 34.182119292000039],
            [71.427279787000032, 34.182982585000047],
            [71.427468782000062, 34.183073811000042],
            [71.427740898000081, 34.183171962000074],
            [71.427870625000082, 34.183212471000047],
            [71.428056459000061, 34.183248609000032],
            [71.428181702000074, 34.18326182900006],
            [71.428327685000056, 34.183271820000073],
            [71.428493682000067, 34.183262200000058],
            [71.428711639000028, 34.183255913000039],
            [71.429315778000046, 34.183137963000036],
            [71.43089139600005, 34.182675375000031],
            [71.431152312000052, 34.182598772000063],
            [71.431383181000058, 34.182527296000046],
            [71.43252534700008, 34.182173687000045],
            [71.432735507000075, 34.182116735000079],
            [71.43290124400005, 34.182025711000051],
            [71.433073019000062, 34.181846250000035],
            [71.433192237000071, 34.181686076000062],
            [71.433304157000066, 34.181470536000063],
            [71.433383478000053, 34.181349195000053],
            [71.433503229000053, 34.181252942000071],
            [71.433668689000058, 34.181183791000024],
            [71.433931681000047, 34.181093426000075],
            [71.434362384000053, 34.181088920000036],
            [71.434531208000067, 34.181254723000052],
            [71.43475249800008, 34.181654472000048],
            [71.434980926000037, 34.182132744000057],
            [71.435080863000053, 34.182582462000028],
            [71.435023756000078, 34.183003627000062],
            [71.43485957300004, 34.183439068000041],
            [71.434702529000049, 34.183731742000077],
            [71.43426708700008, 34.184252844000071],
            [71.433860200000083, 34.184709700000042],
            [71.433333893000054, 34.185204425000052],
            [71.432985447000078, 34.185497854000062],
            [71.432930429000066, 34.185772943000075],
            [71.433095482000056, 34.186029692000034],
            [71.433315554000046, 34.186231424000027],
            [71.433535625000047, 34.186378138000066],
            [71.433553964000055, 34.186598210000056],
            [71.433443929000077, 34.186964995000039],
            [71.433370571000069, 34.187698566000051],
            [71.433297214000049, 34.188138708000054],
            [71.43342558900008, 34.188707226000076],
            [71.435076123000044, 34.188743904000034],
            [71.435387891000062, 34.188138708000054],
            [71.435662980000075, 34.187735244000066],
            [71.43591973000008, 34.187111709000078],
            [71.436242064000055, 34.18659049300004],
            [71.436630986000068, 34.186117732000071],
            [71.437163985000041, 34.185641840000073],
            [71.437525664000077, 34.185051734000069],
            [71.437906377000047, 34.184613913000078],
            [71.438229984000031, 34.184480663000045],
            [71.438496484000041, 34.184138021000024],
            [71.43878201900003, 34.183224307000046],
            [71.438972376000038, 34.182558058000041],
            [71.439061781000078, 34.182083390000059],
            [71.439467813000078, 34.182199098000069],
            [71.44115839400007, 34.182649224000045],
            [71.441834697000047, 34.182855044000064],
            [71.442053752000049, 34.183000865000054],
            [71.442497072000037, 34.18322401100005],
            [71.442774536000059, 34.183364696000069],
            [71.444485439000061, 34.184232188000067],
            [71.445303998000043, 34.184640319000039],
            [71.445446962000062, 34.184747163000054],
            [71.445560790000059, 34.184832232000076],
            [71.445812285000045, 34.185034218000055],
            [71.446240599000078, 34.185690897000029],
            [71.446398015000057, 34.18591493100007],
            [71.446590531000084, 34.186083379000024],
            [71.446618197000078, 34.186107586000048],
            [71.447002190000035, 34.186315665000052],
            [71.445992641000032, 34.187501518000033],
            [71.445406191000075, 34.188141290000033],
            [71.445355484000061, 34.18823151600003],
            [71.445428222000032, 34.188309845000049],
            [71.448951338000029, 34.190127971000038],
            [71.452363889000083, 34.191894750000074],
            [71.454577508000057, 34.19295001200004],
            [71.457471231000056, 34.194329488000051],
            [71.458407259000069, 34.194756968000036],
            [71.458530972000062, 34.194762601000036],
            [71.458702972000083, 34.194756840000025],
            [71.458861579000029, 34.194802416000073],
            [71.458999807000055, 34.19492212800003],
            [71.459162473000049, 34.195143703000042],
            [71.459733816000039, 34.195889546000046],
            [71.459897380000029, 34.196209040000042],
            [71.459899195000048, 34.196220527000037],
            [71.460006282000052, 34.196306352000079],
            [71.460318015000041, 34.19652654500004],
            [71.460439873000041, 34.196614009000029],
            [71.461008563000064, 34.197022186000027],
            [71.461833675000037, 34.197513532000073],
            [71.462372874000039, 34.197820469000078],
            [71.462474640000039, 34.197871630000066],
            [71.462575944000037, 34.197898516000066],
            [71.46270688900006, 34.19791723700007],
            [71.462867004000032, 34.197911693000037],
            [71.463026238000054, 34.197887465000065],
            [71.463936290000049, 34.197670364000032],
            [71.464231314000074, 34.197609637000028],
            [71.464378668000052, 34.197596791000024],
            [71.464741609000043, 34.197579251000036],
            [71.465624219000063, 34.197599703000037],
            [71.465633677000028, 34.197599922000052],
            [71.46571811900003, 34.19763159200005],
            [71.465852010000049, 34.197697204000065],
            [71.466175063000037, 34.197902312000053],
            [71.466208881000057, 34.197964698000078],
            [71.466245002000051, 34.19813558900006],
            [71.466289116000041, 34.198405856000079],
            [71.46632379700003, 34.198505064000074],
            [71.466408149000074, 34.198683785000071],
            [71.466454255000031, 34.198778441000059],
            [71.466464478000034, 34.198790030000055],
            [71.46652173800004, 34.198854942000025],
            [71.466941594000048, 34.199211149000064],
            [71.466996808000033, 34.199285244000066],
            [71.467169573000035, 34.19959592400005],
            [71.467386236000038, 34.199919365000028],
            [71.467522905000067, 34.20005683100004],
            [71.467697807000036, 34.200242053000068],
            [71.46800389200007, 34.200566198000047],
            [71.468267205000075, 34.200887890000047],
            [71.468323674000032, 34.201003111000034],
            [71.468415490000041, 34.201250415000061],
            [71.468516476000048, 34.201531146000036],
            [71.468566710000061, 34.201591911000037],
            [71.468625689000078, 34.20165496900006],
            [71.468913684000029, 34.201846559000046],
            [71.469160327000054, 34.202070810000066],
            [71.469302704000029, 34.20221162200005],
            [71.469412420000083, 34.202299248000031],
            [71.469513284000072, 34.20233444400003],
            [71.469643593000058, 34.202356118000068],
            [71.469886540000061, 34.202407387000051],
            [71.469965537000064, 34.202424058000076],
            [71.47012185300008, 34.202519677000055],
            [71.470237575000056, 34.202600927000049],
            [71.470329855000045, 34.202653129000055],
            [71.470439172000056, 34.20267648500004],
            [71.470516666000037, 34.202657020000061],
            [71.470591146000061, 34.202601858000037],
            [71.470648337000057, 34.202520654000068],
            [71.47079773300004, 34.202275424000049],
            [71.471017411000048, 34.201881556000046],
            [71.471119332000058, 34.201663267000072],
            [71.471161964000032, 34.201550044000044],
            [71.471212881000042, 34.201490883000076],
            [71.471286687000031, 34.201443550000079],
            [71.471360347000029, 34.201423325000064],
            [71.471457101000055, 34.201437957000053],
            [71.471540348000076, 34.201467647000072],
            [71.471606214000076, 34.201532275000034],
            [71.471689468000079, 34.201640984000051],
            [71.47174547000003, 34.20172678800003],
            [71.471818934000055, 34.201802305000058],
            [71.471898443000043, 34.201843633000067],
            [71.471978234000062, 34.201853406000055],
            [71.472070161000033, 34.201846208000063],
            [71.47218966500003, 34.201804969000079],
            [71.47231355200006, 34.201744046000044],
            [71.472378491000029, 34.201690092000035],
            [71.472444005000057, 34.201662999000064],
            [71.47256053600006, 34.201645696000071],
            [71.472671296000044, 34.201640723000025],
            [71.472839086000079, 34.201645628000051],
            [71.473004095000078, 34.201660451000066],
            [71.473121762000062, 34.201660447000052],
            [71.473249324000051, 34.201645542000051],
            [71.473417780000034, 34.201610603000063],
            [71.473570409000047, 34.201588027000071],
            [71.473990952000065, 34.201537519000055],
            [71.47415593900007, 34.201517200000069],
            [71.474238789000083, 34.201501959000041],
            [71.474328925000066, 34.201463820000072],
            [71.47446106700005, 34.201412450000078],
            [71.474602897000068, 34.201378588000068],
            [71.474699647000079, 34.201379468000027],
            [71.474795036000046, 34.201417553000056],
            [71.474890213000037, 34.201489224000056],
            [71.474987983000062, 34.201586388000067],
            [71.475049647000048, 34.201614025000026],
            [71.475127430000043, 34.201615891000074],
            [71.475215697000067, 34.201581546000057],
            [71.475332949000062, 34.201557000000037],
            [71.475424760000067, 34.201569032000066],
            [71.475600501000031, 34.201630324000064],
            [71.47568625100007, 34.201706582000043],
            [71.475760692000051, 34.201827990000027],
            [71.475787186000048, 34.201960160000056],
            [71.47581374300006, 34.202094153000075],
            [71.475850711000078, 34.202148011000077],
            [71.475922507000064, 34.202186349000044],
            [71.476027100000067, 34.202162919000045],
            [71.476461758000084, 34.201983510000048],
            [71.477002166000034, 34.201734260000023],
            [71.477144086000067, 34.201674965000052],
            [71.477250211000069, 34.201663633000067],
            [71.477313406000064, 34.201682007000045],
            [71.47731611100005, 34.202076432000069],
            [71.47729898800003, 34.202540328000055],
            [71.477212179000048, 34.203073921000055],
            [71.477152982000064, 34.203233566000051],
            [71.47696578800003, 34.203711597000051],
            [71.47693275000006, 34.20385212900004],
            [71.476919206000048, 34.204107927000052],
            [71.476957839000079, 34.205492787000026],
            [71.476927811000053, 34.205858733000071],
            [71.476883417000067, 34.206006305000074],
            [71.476815184000031, 34.206187144000069],
            [71.476666213000044, 34.206451016000074],
            [71.476338987000076, 34.207070833000046],
            [71.47603033200005, 34.207917397000074],
            [71.475724828000068, 34.208619592000048],
            [71.477672521000045, 34.207003877000034],
            [71.480867184000033, 34.204353734000051],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "89",
      properties: { name: "Lower Kohistan", count: 587 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.559728001000053, 34.968043370000032],
            [73.553653000000054, 34.950924000000043],
            [73.55005103600007, 34.937070830000039],
            [73.550049000000058, 34.93706300000008],
            [73.549104949000082, 34.93594954200006],
            [73.546968244000084, 34.933429411000077],
            [73.54697565500004, 34.93342593400007],
            [73.546952480000073, 34.933398467000075],
            [73.539270908000049, 34.924294382000028],
            [73.531789402000072, 34.920663651000041],
            [73.525655000000029, 34.917659000000072],
            [73.515121000000079, 34.916827000000069],
            [73.491395394000051, 34.898894346000077],
            [73.491301000000078, 34.89882300000005],
            [73.491282000000069, 34.898809000000028],
            [73.490840974000037, 34.898894346000077],
            [73.474095000000034, 34.902135000000044],
            [73.446652000000029, 34.900195000000053],
            [73.435998918000053, 34.904455878000078],
            [73.428633000000048, 34.907402000000047],
            [73.414773000000082, 34.918213000000037],
            [73.396754000000044, 34.927638000000059],
            [73.396674396000037, 34.927630041000043],
            [73.39467090800008, 34.926294382000037],
            [73.381499198000029, 34.926112703000058],
            [73.380122000000028, 34.925975000000051],
            [73.354342000000031, 34.931242000000054],
            [73.354132040000081, 34.93123440800008],
            [73.331333000000029, 34.930410000000052],
            [73.306939000000057, 34.925421000000028],
            [73.298498486000028, 34.92520975900004],
            [73.286748462000048, 34.924915690000034],
            [73.284763000000055, 34.924866000000065],
            [73.275370872000053, 34.922722269000076],
            [73.25926000000004, 34.91904500000004],
            [73.251751442000057, 34.922008792000042],
            [73.251751406000039, 34.922008806000065],
            [73.248726000000033, 34.923203000000058],
            [73.233756000000028, 34.915164000000061],
            [73.222945000000038, 34.921263000000067],
            [73.20881466000003, 34.937055557000065],
            [73.208661646000053, 34.937017535000052],
            [73.192470908000075, 34.932994382000061],
            [73.187677782000037, 34.931147850000059],
            [73.180270908000068, 34.928294382000047],
            [73.171170908000079, 34.928194382000072],
            [73.145870908000063, 34.927094382000064],
            [73.113617797000074, 34.928301717000068],
            [73.113620765000064, 34.928278829000078],
            [73.108459000000039, 34.928470000000061],
            [73.101254140000037, 34.93347297400004],
            [73.098479000000054, 34.935400000000072],
            [73.089153053000075, 34.945137575000047],
            [73.079629000000068, 34.955082000000061],
            [73.076079290000052, 34.95632147200007],
            [73.07606988100008, 34.956324757000061],
            [73.075870586000065, 34.956394346000025],
            [73.06216500000005, 34.96118000000007],
            [73.05857366400005, 34.963081407000061],
            [73.052740000000028, 34.966170000000034],
            [73.026045422000038, 34.963743437000062],
            [73.019198000000074, 34.963121000000058],
            [73.012115641000037, 34.961454633000074],
            [73.000070908000055, 34.958694382000033],
            [72.995770908000054, 34.957694382000057],
            [72.969070908000049, 34.945694382000056],
            [72.94017014700006, 34.942960526000036],
            [72.939362000000074, 34.942885000000047],
            [72.918017000000077, 34.930688000000032],
            [72.913205413000071, 34.927991329000065],
            [72.892791000000045, 34.916550000000029],
            [72.87750408200003, 34.914294346000077],
            [72.875882000000047, 34.914055000000076],
            [72.875722242000052, 34.914294346000077],
            [72.875609391000069, 34.914463415000057],
            [72.880504600000052, 34.922078985000041],
            [72.88087100000007, 34.922649000000035],
            [72.881035000000054, 34.924335305000056],
            [72.882870908000029, 34.94269438200007],
            [72.889870908000034, 34.954494382000064],
            [72.904633963000038, 34.965231149000033],
            [72.90748300000007, 34.967279000000076],
            [72.907463428000028, 34.967288942000039],
            [72.90747090800005, 34.967294382000034],
            [72.899070908000056, 34.971694382000067],
            [72.891604749000066, 34.975344504000077],
            [72.890070908000041, 34.976094382000042],
            [72.889501247000055, 34.976612256000067],
            [72.882370908000041, 34.983094382000047],
            [72.882257376000041, 34.983079665000048],
            [72.882257000000038, 34.983080000000029],
            [72.882138571000041, 34.983064264000063],
            [72.871570908000081, 34.981694382000057],
            [72.871514102000049, 34.981652582000038],
            [72.843229214000075, 34.977894346000028],
            [72.842617000000075, 34.977813000000026],
            [72.842309313000044, 34.977894346000028],
            [72.842047518000072, 34.977963559000045],
            [72.818500000000029, 34.984189000000072],
            [72.81513196800006, 34.98781592000006],
            [72.807689000000039, 34.995831000000067],
            [72.807690938000064, 34.995872868000049],
            [72.808109839000053, 35.004921557000046],
            [72.809075000000064, 35.02577000000008],
            [72.80906591300004, 35.025787347000062],
            [72.802810957000077, 35.037728377000064],
            [72.799927000000082, 35.043234000000041],
            [72.791287136000051, 35.054032964000044],
            [72.791268433000084, 35.054056341000035],
            [72.790078944000072, 35.055543083000032],
            [72.789947000000041, 35.055708000000038],
            [72.789894692000075, 35.056257274000075],
            [72.79017090800005, 35.06279438200005],
            [72.790170902000057, 35.062794418000067],
            [72.788692586000082, 35.071275279000076],
            [72.788670264000075, 35.071403337000049],
            [72.788270908000072, 35.073694382000042],
            [72.786470908000069, 35.092694382000047],
            [72.785570908000068, 35.102294382000025],
            [72.787670380000066, 35.103638044000036],
            [72.792997000000071, 35.106992000000048],
            [72.792975455000033, 35.10703329200004],
            [72.793070908000061, 35.107094382000071],
            [72.790870908000045, 35.11129438200004],
            [72.790870884000071, 35.111294418000057],
            [72.790347901000075, 35.112069208000037],
            [72.785470908000036, 35.119294382000078],
            [72.781388134000053, 35.12602830700007],
            [72.777770908000036, 35.131994382000073],
            [72.775070908000032, 35.134294382000064],
            [72.761070908000079, 35.135094382000034],
            [72.755770908000045, 35.143794382000067],
            [72.746570908000081, 35.158294382000065],
            [72.720970908000083, 35.159494382000048],
            [72.720850966000057, 35.159717529000034],
            [72.716670908000083, 35.167494382000029],
            [72.706270908000079, 35.172694382000032],
            [72.692470908000075, 35.175494382000068],
            [72.688681816000042, 35.17938872700006],
            [72.688583646000041, 35.179489624000041],
            [72.688728899000068, 35.179509809000024],
            [72.687843060000034, 35.180401355000072],
            [72.685378522000065, 35.182971768000073],
            [72.687259462000043, 35.188329974000055],
            [72.688835829000084, 35.192782530000045],
            [72.690071706000083, 35.19627335000007],
            [72.69601360300004, 35.205743450000057],
            [72.700568647000068, 35.212977716000069],
            [72.702991461000067, 35.230939315000057],
            [72.70715689900004, 35.232491887000037],
            [72.724832172000049, 35.239046424000037],
            [72.724833923000062, 35.239080597000054],
            [72.724870908000071, 35.239094382000076],
            [72.725013083000078, 35.242577666000045],
            [72.725069846000054, 35.24368564100007],
            [72.725085166000042, 35.244007014000033],
            [72.725087571000074, 35.244004780000068],
            [72.725168253000049, 35.24548808600008],
            [72.725344151000058, 35.249053222000043],
            [72.724553237000066, 35.250391918000048],
            [72.719442786000059, 35.259041842000045],
            [72.719070908000049, 35.259694382000077],
            [72.730070908000073, 35.26229438200005],
            [72.753070908000041, 35.261494382000024],
            [72.762470908000068, 35.274494382000057],
            [72.762070908000055, 35.278994382000064],
            [72.762270908000062, 35.283594382000047],
            [72.765270908000048, 35.300494382000068],
            [72.768561525000052, 35.313395097000068],
            [72.768753394000044, 35.314072481000039],
            [72.770320742000081, 35.319754548000049],
            [72.771660652000037, 35.324556616000052],
            [72.772046999000054, 35.325952602000029],
            [72.772912289000033, 35.329060379000055],
            [72.772904118000042, 35.329064083000048],
            [72.77292787500005, 35.32909434100003],
            [72.774488237000071, 35.334709839000027],
            [72.774867823000079, 35.336075909000044],
            [72.775470908000045, 35.336894382000025],
            [72.785570908000068, 35.375694382000063],
            [72.784962896000081, 35.375883703000056],
            [72.784967626000082, 35.375901884000029],
            [72.768043670000054, 35.38123956000004],
            [72.764343435000058, 35.382783543000073],
            [72.741025813000078, 35.392540843000063],
            [72.727870908000057, 35.406094382000049],
            [72.725289786000076, 35.420376590000046],
            [72.724870908000071, 35.422694382000032],
            [72.730870908000043, 35.428494382000054],
            [72.748670908000065, 35.441694382000037],
            [72.749570908000067, 35.44409438200006],
            [72.765470908000054, 35.43009438200005],
            [72.773270908000029, 35.419994382000027],
            [72.780670908000047, 35.415894382000033],
            [72.792270908000035, 35.40899438200006],
            [72.802070908000076, 35.402894382000056],
            [72.810170908000032, 35.397294382000041],
            [72.827470908000066, 35.385994382000035],
            [72.835670908000054, 35.377994382000054],
            [72.843770908000067, 35.370294382000054],
            [72.847970908000036, 35.365394382000034],
            [72.849370908000083, 35.363694382000062],
            [72.856470908000063, 35.359394382000062],
            [72.868970908000051, 35.359394382000062],
            [72.876070908000031, 35.36189438200006],
            [72.881270908000033, 35.365994382000054],
            [72.883970908000038, 35.371994382000025],
            [72.884870908000039, 35.378894382000055],
            [72.888870908000058, 35.383194382000056],
            [72.892770908000045, 35.384394382000039],
            [72.89487090800003, 35.384994382000059],
            [72.897070908000046, 35.385294382000041],
            [72.909070908000047, 35.386894382000037],
            [72.921770908000042, 35.387594382000032],
            [72.932870908000041, 35.390094382000029],
            [72.942970908000063, 35.392194382000071],
            [72.949870908000037, 35.393494382000029],
            [72.949370908000049, 35.390894382000056],
            [72.947870908000084, 35.383894382000051],
            [72.951070908000077, 35.375394382000025],
            [72.95737090800003, 35.368594382000026],
            [72.967670908000059, 35.361994382000034],
            [72.977570908000075, 35.359194382000055],
            [72.975370908000059, 35.351194382000074],
            [72.974870908000071, 35.336994382000057],
            [72.976470908000067, 35.328294382000024],
            [72.977770908000082, 35.316794382000069],
            [72.980370908000054, 35.307894382000029],
            [72.981070908000049, 35.305594382000038],
            [72.982470908000039, 35.303894382000067],
            [72.985170908000043, 35.300794382000049],
            [72.989970908000032, 35.300094382000054],
            [72.998270908000052, 35.298594382000033],
            [73.005870908000077, 35.294994382000027],
            [73.009670908000032, 35.293194382000024],
            [73.017870908000077, 35.283594382000047],
            [73.017070908000051, 35.272494382000048],
            [73.024170908000031, 35.264794382000048],
            [73.028870908000044, 35.260794382000029],
            [73.035970908000081, 35.258994382000026],
            [73.049070908000033, 35.260494382000047],
            [73.054470908000042, 35.261094382000067],
            [73.072670908000077, 35.270094382000025],
            [73.075970908000045, 35.272394382000073],
            [73.085770908000029, 35.279394382000078],
            [73.093770908000067, 35.286294382000051],
            [73.096770908000053, 35.288894382000024],
            [73.102770908000082, 35.294094382000026],
            [73.106270908000056, 35.297294382000075],
            [73.112270908000085, 35.298094382000045],
            [73.114770908000082, 35.294394382000064],
            [73.117070908000073, 35.28969438200005],
            [73.121370908000074, 35.281794382000044],
            [73.130270908000057, 35.268894382000042],
            [73.120770908000054, 35.267694382000059],
            [73.117170908000048, 35.267094382000039],
            [73.099270908000051, 35.264094382000053],
            [73.082770908000043, 35.257794382000043],
            [73.069470908000028, 35.251394382000058],
            [73.055270908000068, 35.243894382000065],
            [73.04647090800006, 35.238794382000037],
            [73.041670908000071, 35.229594382000073],
            [73.043070908000061, 35.227494382000032],
            [73.046770908000042, 35.219394382000075],
            [73.053170908000084, 35.214894382000068],
            [73.058770908000042, 35.211794382000051],
            [73.06817090800007, 35.212994382000034],
            [73.074770908000062, 35.211394382000037],
            [73.083070908000082, 35.205394382000065],
            [73.087370908000082, 35.202194382000073],
            [73.096070908000058, 35.19469438200008],
            [73.102270908000037, 35.189594382000053],
            [73.10407090800004, 35.188994382000033],
            [73.107570908000071, 35.18639438200006],
            [73.103470908000077, 35.181194382000058],
            [73.101370908000035, 35.176994382000032],
            [73.10207090800003, 35.17039438200004],
            [73.108470908000072, 35.159494382000048],
            [73.110770908000063, 35.156194382000024],
            [73.105770908000068, 35.152594382000075],
            [73.102770908000082, 35.148194382000042],
            [73.101570908000042, 35.142494382000052],
            [73.105670908000036, 35.136294382000074],
            [73.112070908000078, 35.13239438200003],
            [73.119370908000064, 35.131294382000078],
            [73.125070908000055, 35.131294382000078],
            [73.140270908000048, 35.123094382000033],
            [73.156170908000036, 35.116094382000028],
            [73.169570908000082, 35.112694382000029],
            [73.17227090800003, 35.112194382000041],
            [73.201770908000071, 35.106994382000039],
            [73.230670908000036, 35.105994382000063],
            [73.233470908000072, 35.104694382000048],
            [73.238070908000054, 35.102594382000063],
            [73.246170908000067, 35.098894382000026],
            [73.26277090800005, 35.092794382000079],
            [73.282370908000075, 35.086394382000037],
            [73.299670908000053, 35.08309438200007],
            [73.314670908000039, 35.082894382000063],
            [73.334870908000084, 35.084494382000059],
            [73.358070908000059, 35.088694382000028],
            [73.366270908000047, 35.090894382000045],
            [73.372070908000069, 35.090394382000056],
            [73.384470908000083, 35.089294382000048],
            [73.390370908000079, 35.088394382000047],
            [73.403470908000031, 35.086294382000062],
            [73.422570908000068, 35.081294382000067],
            [73.439670908000039, 35.074094382000055],
            [73.456470908000028, 35.065594382000029],
            [73.478458315000069, 35.051602396000078],
            [73.481770908000044, 35.049494382000034],
            [73.498870908000072, 35.035094382000068],
            [73.515870908000068, 35.017794382000034],
            [73.531170908000036, 35.001194382000051],
            [73.54217090800006, 34.987994382000068],
            [73.54707090800008, 34.982494382000027],
            [73.559728001000053, 34.968043370000032],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "90",
      properties: { name: "Attock", count: 355 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.44689483500008, 33.993695079000076],
            [72.449094835000039, 33.99349507900007],
            [72.458294835000061, 33.994695079000053],
            [72.46879483500004, 33.995695079000029],
            [72.470194871000047, 33.995777435000036],
            [72.470494835000068, 33.995795079000061],
            [72.470745668000063, 33.995813769000051],
            [72.495994835000033, 33.997695079000039],
            [72.499994835000052, 33.997295079000025],
            [72.505494835000036, 33.997695079000039],
            [72.505492471000082, 33.997702000000061],
            [72.517726254000081, 33.997702000000061],
            [72.522460000000081, 33.997702000000061],
            [72.525512659000071, 33.998719553000058],
            [72.525549248000061, 33.998731750000047],
            [72.525512659000071, 33.998474112000054],
            [72.525512695000032, 33.998474121000072],
            [72.531108808000056, 34.000584936000052],
            [72.532399000000055, 34.001015000000052],
            [72.532399320000081, 34.001014940000061],
            [72.543002000000058, 33.999027000000069],
            [72.545033950000061, 33.996317222000073],
            [72.546977000000084, 33.993726000000038],
            [72.547491000000036, 33.990130000000079],
            [72.551614999000037, 33.961256000000049],
            [72.55210955900003, 33.961214796000036],
            [72.55212405900005, 33.961213589000067],
            [72.55323971100006, 33.961120641000036],
            [72.567519000000061, 33.95993100000004],
            [72.568706494000082, 33.959277891000056],
            [72.580772000000081, 33.952642000000026],
            [72.598831547000032, 33.953931759000056],
            [72.599325000000078, 33.953967000000034],
            [72.599325117000035, 33.953966973000036],
            [72.608027686000071, 33.951943276000065],
            [72.625874536000083, 33.947793166000054],
            [72.627819000000045, 33.947341000000051],
            [72.62800930700007, 33.947253165000063],
            [72.643357939000055, 33.940169043000026],
            [72.645048000000031, 33.939389000000062],
            [72.651132861000065, 33.930696248000061],
            [72.654296911000074, 33.92617612600003],
            [72.654325000000028, 33.926136000000042],
            [72.654296911000074, 33.926118125000073],
            [72.65428174300007, 33.926108472000067],
            [72.652751304000049, 33.925134548000074],
            [72.64833993600007, 33.922327285000051],
            [72.639753134000046, 33.916862904000027],
            [72.639747000000057, 33.916859000000045],
            [72.629263881000043, 33.916204485000037],
            [72.629144000000053, 33.916197000000068],
            [72.62884796600008, 33.910566531000029],
            [72.628723145000038, 33.908874512000068],
            [72.628479004000042, 33.903686523000033],
            [72.623087727000041, 33.900523642000053],
            [72.619205000000079, 33.89830500000005],
            [72.614446128000054, 33.895276924000029],
            [72.611877441000047, 33.893676758000026],
            [72.611407908000047, 33.891934270000036],
            [72.608543350000048, 33.88216535600003],
            [72.608540098000049, 33.882154267000033],
            [72.607058623000057, 33.876225387000034],
            [72.606888391000041, 33.875544338000054],
            [72.606872559000067, 33.875488281000059],
            [72.606615067000064, 33.874452591000079],
            [72.606615669000064, 33.874452680000047],
            [72.606615000000033, 33.874450000000024],
            [72.624506500000052, 33.877101000000039],
            [72.628047305000052, 33.877438159000064],
            [72.63842100800008, 33.878425953000033],
            [72.638786147000076, 33.876356896000061],
            [72.640409000000034, 33.867161000000067],
            [72.64290679700008, 33.866051147000064],
            [72.644423907000032, 33.865377046000049],
            [72.64637300000004, 33.86451100000005],
            [72.646373435000044, 33.864511029000028],
            [72.65374799500006, 33.865002567000033],
            [72.659826068000029, 33.86540769100003],
            [72.664560297000037, 33.865723242000058],
            [72.666063185000041, 33.865823415000079],
            [72.666252000000043, 33.865836000000058],
            [72.674907794000035, 33.867278814000031],
            [72.678071188000047, 33.867810832000032],
            [72.681573868000044, 33.868387222000024],
            [72.682157516000075, 33.868486404000066],
            [72.682671182000036, 33.868586071000038],
            [72.68539957400003, 33.869110750000061],
            [72.699379538000073, 33.871799142000043],
            [72.699384000000066, 33.871800000000064],
            [72.699384581000061, 33.871801356000049],
            [72.699384689000055, 33.871801376000064],
            [72.699484228000074, 33.872033857000076],
            [72.70052207100008, 33.874455402000024],
            [72.701267073000054, 33.87619367800005],
            [72.703360000000032, 33.881077000000062],
            [72.703362815000048, 33.881077312000059],
            [72.707623405000049, 33.881550235000077],
            [72.709325790000037, 33.881738663000078],
            [72.709468069000081, 33.88433486100007],
            [72.709551255000065, 33.88583370300006],
            [72.709667705000072, 33.887931922000064],
            [72.709986000000072, 33.89366700000005],
            [72.708129847000066, 33.901709707000066],
            [72.707998000000032, 33.902281000000073],
            [72.708129847000066, 33.902324949000047],
            [72.709571524000069, 33.902805508000029],
            [72.714875049000057, 33.904573349000032],
            [72.719323367000072, 33.906056122000052],
            [72.719926000000044, 33.906257000000039],
            [72.725157243000069, 33.906692854000028],
            [72.73582900100007, 33.907582000000048],
            [72.735828635000075, 33.907583098000032],
            [72.733841000000041, 33.913546000000053],
            [72.725890000000049, 33.921498000000042],
            [72.725890244000084, 33.921498214000053],
            [72.727686683000059, 33.92307014000005],
            [72.72949028000005, 33.924646378000034],
            [72.731870651000065, 33.926729202000047],
            [72.736492595000072, 33.930775310000058],
            [72.737766774000079, 33.931437858000038],
            [72.741721975000075, 33.933494486000029],
            [72.74210987500004, 33.933695927000031],
            [72.742110625000066, 33.933696317000056],
            [72.746504513000048, 33.935978114000079],
            [72.748282755000048, 33.936905705000072],
            [72.748440045000052, 33.93698775200005],
            [72.749181155000031, 33.937373115000071],
            [72.75265792700003, 33.939180970000052],
            [72.753058000000067, 33.939389000000062],
            [72.757335178000062, 33.943971855000029],
            [72.758062979000044, 33.944751671000063],
            [72.762330578000046, 33.949324262000061],
            [72.762334275000057, 33.949328223000066],
            [72.762335000000064, 33.949329000000034],
            [72.765086122000071, 33.949963827000033],
            [72.767401003000032, 33.950497990000031],
            [72.770948999000041, 33.951317000000074],
            [72.770949118000033, 33.951316912000038],
            [72.77734530400005, 33.946589334000066],
            [72.786190000000033, 33.940052000000037],
            [72.786253681000062, 33.938666090000027],
            [72.787327987000083, 33.916902507000032],
            [72.787328184000046, 33.916898529000036],
            [72.787377216000039, 33.915905222000049],
            [72.787397385000077, 33.915456772000027],
            [72.787549973000068, 33.912385941000025],
            [72.787568805000035, 33.912017468000045],
            [72.787893719000067, 33.90541113200004],
            [72.788091660000077, 33.901323318000038],
            [72.78812537400006, 33.900701018000063],
            [72.788178000000073, 33.899631000000056],
            [72.788189748000036, 33.899591053000051],
            [72.788330078000058, 33.898681641000053],
            [72.790620755000077, 33.891325040000027],
            [72.791491000000065, 33.88836599800004],
            [72.795640500000047, 33.887710781000067],
            [72.795769004000078, 33.887690569000029],
            [72.79576938200006, 33.887690509000038],
            [72.800447443000053, 33.886954703000072],
            [72.803983850000066, 33.886393419000058],
            [72.804081000000053, 33.886378000000036],
            [72.804081258000053, 33.886378038000032],
            [72.804081500000052, 33.886378000000036],
            [72.816267779000043, 33.888182971000049],
            [72.816477346000056, 33.888214011000059],
            [72.817159367000045, 33.888315028000079],
            [72.821973000000071, 33.889028000000053],
            [72.832427358000075, 33.887525145000041],
            [72.835888000000068, 33.88704000000007],
            [72.837483096000028, 33.882254713000066],
            [72.838362013000051, 33.879617964000033],
            [72.83868411800006, 33.878651646000037],
            [72.838982615000077, 33.877756155000043],
            [72.838982682000051, 33.877755954000065],
            [72.841189000000043, 33.871137000000033],
            [72.840593467000076, 33.865578895000056],
            [72.83920100000006, 33.852583000000038],
            [72.833237239000084, 33.843969345000062],
            [72.833237000000054, 33.843969000000072],
            [72.826589321000029, 33.843969000000072],
            [72.823298500000078, 33.843969000000072],
            [72.823298001000069, 33.843969000000072],
            [72.823281343000076, 33.843940446000033],
            [72.822338501000047, 33.84232426300008],
            [72.818659000000082, 33.836017000000027],
            [72.820647000000065, 33.825415000000078],
            [72.82064745200006, 33.825415253000074],
            [72.821071798000048, 33.825652888000036],
            [72.823606432000076, 33.827072289000057],
            [72.827279691000058, 33.829129323000075],
            [72.82863323600003, 33.829887312000039],
            [72.83303630100005, 33.832353038000065],
            [72.837213000000077, 33.834692000000075],
            [72.844112324000037, 33.835266366000042],
            [72.844842883000069, 33.83532718400005],
            [72.845165000000065, 33.835354000000052],
            [72.845165080000072, 33.835353965000024],
            [72.845226299000046, 33.83532718400005],
            [72.845231661000071, 33.835324839000066],
            [72.848795365000058, 33.833765844000027],
            [72.851743317000057, 33.832476219000057],
            [72.855767000000071, 33.830716000000052],
            [72.859388231000082, 33.828798821000078],
            [72.863672867000048, 33.826530417000072],
            [72.865912706000074, 33.825344585000039],
            [72.867032000000052, 33.824752000000046],
            [72.867212772000073, 33.824209683000049],
            [72.86761191100004, 33.823012266000035],
            [72.870345000000043, 33.814813000000072],
            [72.864061575000051, 33.805672767000033],
            [72.863098145000038, 33.804321289000029],
            [72.853881836000085, 33.79968261700003],
            [72.846496582000043, 33.802917480000076],
            [72.836730957000043, 33.803283691000047],
            [72.834536122000031, 33.803283691000047],
            [72.834438805000048, 33.803286937000053],
            [72.829703038000048, 33.803444884000044],
            [72.826611494000076, 33.803547989000037],
            [72.826611000000071, 33.803548000000035],
            [72.826370590000067, 33.803091163000033],
            [72.826117277000037, 33.802609808000057],
            [72.825059331000034, 33.800599454000064],
            [72.824841173000038, 33.800185579000072],
            [72.822875977000081, 33.796691895000038],
            [72.812500000000057, 33.801513672000056],
            [72.810729980000076, 33.805725098000039],
            [72.807922363000046, 33.812683105000076],
            [72.799423469000033, 33.820619159000046],
            [72.798706055000082, 33.821289062000062],
            [72.797485352000081, 33.825927734000061],
            [72.796349274000079, 33.829632335000042],
            [72.796081543000071, 33.830505371000072],
            [72.796088831000077, 33.83052559600003],
            [72.792856301000029, 33.83440405500005],
            [72.790526000000057, 33.837200000000053],
            [72.789463568000031, 33.838055629000053],
            [72.783838624000055, 33.842631886000049],
            [72.781302561000075, 33.845143836000034],
            [72.780883789000029, 33.845520020000038],
            [72.779898321000076, 33.846534722000058],
            [72.779898286000048, 33.846534758000075],
            [72.777479420000077, 33.849385242000039],
            [72.777456218000054, 33.849413085000037],
            [72.776319504000071, 33.850782394000078],
            [72.775930405000054, 33.851247787000034],
            [72.775846481000031, 33.851331711000057],
            [72.775653817000034, 33.851533054000072],
            [72.767646943000045, 33.860046330000046],
            [72.767373010000028, 33.860334746000035],
            [72.76700900000003, 33.860718000000077],
            [72.756131924000044, 33.864013852000028],
            [72.74854649200006, 33.866312306000054],
            [72.748548396000047, 33.866315066000027],
            [72.748533249000047, 33.86631965600003],
            [72.74853321300003, 33.866319643000054],
            [72.740587270000049, 33.863538755000036],
            [72.740587234000031, 33.86353874200006],
            [72.737856006000072, 33.862581371000033],
            [72.737332000000038, 33.862398000000042],
            [72.736736090000079, 33.861503604000063],
            [72.736031048000029, 33.860463647000074],
            [72.735001830000044, 33.858888729000057],
            [72.73496855500008, 33.858837812000047],
            [72.734908261000044, 33.858745550000037],
            [72.73477095800007, 33.85854217800005],
            [72.734025870000039, 33.857438558000069],
            [72.731620220000082, 33.853828203000035],
            [72.731229116000065, 33.853241241000035],
            [72.731105202000037, 33.853055273000052],
            [72.730636125000046, 33.852360891000046],
            [72.725093529000048, 33.842383457000039],
            [72.72435185300003, 33.841048339000054],
            [72.723454329000049, 33.839432711000029],
            [72.722214000000065, 33.837200000000053],
            [72.72172689100006, 33.83657026700007],
            [72.721549950000053, 33.836341517000051],
            [72.720091091000029, 33.834423798000046],
            [72.71916318500007, 33.833210344000065],
            [72.716937125000072, 33.830299248000074],
            [72.714893741000083, 33.827613022000037],
            [72.712602136000044, 33.824271603000057],
            [72.711352084000055, 33.822440443000062],
            [72.707346715000028, 33.816573112000071],
            [72.705757288000029, 33.814244813000073],
            [72.704699738000045, 33.812686271000075],
            [72.703674316000047, 33.811279297000056],
            [72.703674291000084, 33.811279261000038],
            [72.699890137000068, 33.805725098000039],
            [72.697640785000033, 33.802291748000073],
            [72.697640749000072, 33.802291693000029],
            [72.697639121000066, 33.802280837000069],
            [72.697017000000073, 33.801364000000035],
            [72.697258674000068, 33.79976864200006],
            [72.697440174000064, 33.798570506000033],
            [72.697443044000067, 33.798551560000078],
            [72.697711926000068, 33.796776593000061],
            [72.698298730000033, 33.79290293300005],
            [72.698482776000048, 33.791687995000075],
            [72.699028048000059, 33.788088497000047],
            [72.699456420000047, 33.785265569000046],
            [72.699817000000053, 33.782886000000076],
            [72.702012595000042, 33.779405932000031],
            [72.702036852000049, 33.779367483000044],
            [72.70445314300008, 33.775537604000078],
            [72.705692796000051, 33.773573347000024],
            [72.705702390000056, 33.773558145000038],
            [72.706769732000055, 33.771866347000071],
            [72.708640096000067, 33.768901713000048],
            [72.712169593000056, 33.763307823000048],
            [72.712297355000032, 33.763105333000055],
            [72.713734964000082, 33.760826870000074],
            [72.715634187000035, 33.757817070000044],
            [72.717011018000051, 33.755634906000068],
            [72.719004341000073, 33.752475448000041],
            [72.721579911000049, 33.748393591000024],
            [72.722054882000066, 33.747640801000045],
            [72.723030927000082, 33.746093722000069],
            [72.724264597000058, 33.744138482000039],
            [72.729493000000048, 33.735852000000079],
            [72.733132037000075, 33.732534088000079],
            [72.734053062000044, 33.731694339000057],
            [72.735118647000036, 33.730722785000069],
            [72.736240745000032, 33.729699707000066],
            [72.737427742000079, 33.728619303000073],
            [72.741109062000078, 33.725260992000074],
            [72.748531000000071, 33.718494000000078],
            [72.748531000000071, 33.716371812000034],
            [72.748531000000071, 33.712114148000069],
            [72.748531000000071, 33.71069583600007],
            [72.748531000000071, 33.709421040000052],
            [72.748531000000071, 33.707718859000067],
            [72.748531000000071, 33.70566094000003],
            [72.748531000000071, 33.70561500000008],
            [72.748056562000045, 33.705039235000072],
            [72.747579664000057, 33.704455589000077],
            [72.744497810000041, 33.700699676000056],
            [72.742589058000078, 33.698373445000072],
            [72.740319101000068, 33.695607005000056],
            [72.736389554000084, 33.690817994000042],
            [72.733378234000043, 33.687148042000047],
            [72.730636454000035, 33.683806584000024],
            [72.730613000000062, 33.683778000000075],
            [72.732215878000034, 33.682105426000078],
            [72.73380734300008, 33.680444761000047],
            [72.736076222000065, 33.678077228000063],
            [72.737967291000075, 33.676103933000036],
            [72.740328712000064, 33.673639833000038],
            [72.74349200000006, 33.67033900000007],
            [72.744669836000071, 33.662386108000078],
            [72.745731000000035, 33.65522100000004],
            [72.746718558000055, 33.650848034000035],
            [72.748620007000056, 33.642428300000063],
            [72.749051077000047, 33.640520176000052],
            [72.749651000000028, 33.637863000000038],
            [72.760051719000046, 33.637729642000068],
            [72.762645263000081, 33.637696388000052],
            [72.770549687000084, 33.637595038000029],
            [72.779580858000031, 33.637479240000062],
            [72.790021832000036, 33.637359772000025],
            [72.793326000000036, 33.637303000000031],
            [72.801071000000036, 33.636528408000061],
            [72.806707141000061, 33.635964727000044],
            [72.810102136000069, 33.635625187000073],
            [72.81012400000003, 33.635623000000066],
            [72.80793043400007, 33.631235868000033],
            [72.804550808000045, 33.624476617000028],
            [72.803416815000048, 33.622208630000046],
            [72.803405000000055, 33.622185000000059],
            [72.806205000000034, 33.614905000000078],
            [72.810275842000067, 33.611470298000029],
            [72.815123567000057, 33.607380115000069],
            [72.822184992000075, 33.601422161000073],
            [72.824115401000029, 33.59979257100008],
            [72.824121511000044, 33.599787413000058],
            [72.824122000000045, 33.599787000000049],
            [72.824121511000044, 33.599783474000048],
            [72.823498047000044, 33.595287787000075],
            [72.822994171000062, 33.591660235000063],
            [72.82247046100008, 33.587889897000025],
            [72.821327583000084, 33.57966199100008],
            [72.821323000000064, 33.579629000000068],
            [72.823123481000039, 33.568827724000073],
            [72.823562000000038, 33.56619100000006],
            [72.825630104000084, 33.565501659000063],
            [72.82563672200007, 33.565499453000029],
            [72.829822303000071, 33.564104315000066],
            [72.83884400200003, 33.561097202000042],
            [72.839933476000056, 33.560734058000037],
            [72.842451779000044, 33.559894657000029],
            [72.848760000000084, 33.557792000000063],
            [72.85385042300004, 33.552135357000054],
            [72.858112173000052, 33.547399705000032],
            [72.858108469000058, 33.547398427000076],
            [72.858787512000049, 33.54664370900008],
            [72.858531129000085, 33.54280172700004],
            [72.85852754800004, 33.542747912000038],
            [72.857830999000043, 33.532280756000034],
            [72.857829224000056, 33.532254089000048],
            [72.857829025000058, 33.53225397500006],
            [72.857828962000042, 33.532253939000043],
            [72.857823726000049, 33.532174767000072],
            [72.857877469000073, 33.532205432000069],
            [72.857721329000071, 33.529844284000035],
            [72.857722280000075, 33.529844234000052],
            [72.857722055000067, 33.529840862000071],
            [72.857719000000031, 33.529795000000036],
            [72.859470705000035, 33.529705157000024],
            [72.863125369000045, 33.529517712000029],
            [72.868592369000055, 33.529233688000033],
            [72.879556000000036, 33.528675000000078],
            [72.893555000000049, 33.523636000000067],
            [72.896235123000054, 33.52033730200003],
            [72.901957003000064, 33.513294807000079],
            [72.90292278000004, 33.512106127000038],
            [72.908113000000071, 33.505718000000059],
            [72.916994808000084, 33.498612712000067],
            [72.919312000000048, 33.496759000000054],
            [72.930084303000058, 33.488924836000024],
            [72.931581258000051, 33.487835471000039],
            [72.931630000000041, 33.48780000000005],
            [72.927544243000057, 33.486749012000075],
            [72.915167006000047, 33.483565970000029],
            [72.91385709900004, 33.483229102000053],
            [72.912033000000065, 33.482760000000042],
            [72.907196369000076, 33.482432267000036],
            [72.90381095500004, 33.482202822000033],
            [72.902655802000083, 33.482124484000053],
            [72.900982722000037, 33.482011095000075],
            [72.898452344000077, 33.481839605000062],
            [72.89077962600004, 33.48131963000003],
            [72.888224521000041, 33.481146440000032],
            [72.884090016000073, 33.480866234000075],
            [72.878996000000029, 33.480521000000067],
            [72.87220613900007, 33.477337720000037],
            [72.866257055000062, 33.474548620000064],
            [72.861079000000075, 33.472121000000072],
            [72.860548875000063, 33.472025550000069],
            [72.860534551000057, 33.47206852100004],
            [72.856473923000067, 33.471311569000079],
            [72.856473717000028, 33.471311533000062],
            [72.853448478000075, 33.470782592000035],
            [72.853448868000044, 33.470781326000065],
            [72.846536875000083, 33.46952482100005],
            [72.842599869000082, 33.468809128000032],
            [72.824682236000058, 33.467130661000056],
            [72.813486099000045, 33.460969925000029],
            [72.804079056000035, 33.457094193000046],
            [72.785486221000042, 33.449213028000031],
            [72.784265554000058, 33.448743834000027],
            [72.784265518000041, 33.448743820000061],
            [72.77820777900007, 33.446413040000039],
            [72.776541617000078, 33.440997302000028],
            [72.775968552000052, 33.439134598000066],
            [72.772607803000028, 33.433534622000025],
            [72.772607803000028, 33.42345619200006],
            [72.769811630000049, 33.417295456000033],
            [72.769821168000078, 33.417274775000067],
            [72.769809000000066, 33.417248000000029],
            [72.771916224000051, 33.412680988000034],
            [72.772526224000046, 33.411358928000027],
            [72.773160673000064, 33.409983880000027],
            [72.773168000000055, 33.409968000000049],
            [72.772683184000073, 33.407931394000059],
            [72.772507683000072, 33.407194149000077],
            [72.771876500000076, 33.40454268600007],
            [72.771127160000049, 33.401394869000057],
            [72.770535829000039, 33.398920151000027],
            [72.770369000000073, 33.398210000000063],
            [72.759730000000047, 33.401569000000052],
            [72.754685624000047, 33.39646652600004],
            [72.750573993000046, 33.392413993000048],
            [72.748020117000067, 33.389860117000069],
            [72.747971000000064, 33.389811000000066],
            [72.747185214000069, 33.389860117000069],
            [72.744687393000049, 33.390016248000052],
            [72.742293976000042, 33.390165853000042],
            [72.739012000000059, 33.390371000000073],
            [72.732853000000034, 33.38421100000005],
            [72.731535501000053, 33.382281726000031],
            [72.727960368000083, 33.377046496000048],
            [72.72795830900003, 33.377043481000044],
            [72.727596189000053, 33.376513213000067],
            [72.724428923000062, 33.372227595000027],
            [72.722267919000046, 33.369254994000073],
            [72.721381458000053, 33.368035610000049],
            [72.720534000000043, 33.366853000000049],
            [72.720529536000072, 33.366841374000046],
            [72.71936712400003, 33.363814382000044],
            [72.719171935000077, 33.362877035000054],
            [72.719685671000036, 33.361124858000039],
            [72.719776076000073, 33.360212646000036],
            [72.719776102000083, 33.360212385000068],
            [72.719823930000075, 33.359729790000074],
            [72.719537639000066, 33.357833485000072],
            [72.714309443000047, 33.354457492000051],
            [72.712694168000041, 33.353464127000052],
            [72.710943222000083, 33.349454880000053],
            [72.708776474000047, 33.344503403000033],
            [72.705690384000036, 33.34070777900007],
            [72.705613450000044, 33.340612381000028],
            [72.70561409000004, 33.340612411000052],
            [72.705599084000085, 33.340594567000039],
            [72.705595016000075, 33.340589523000062],
            [72.705594836000046, 33.34058951600008],
            [72.701478958000052, 33.335695267000062],
            [72.69952810500007, 33.331395429000054],
            [72.698373178000054, 33.32898950200007],
            [72.694777000000045, 33.321498000000076],
            [72.693606621000072, 33.320258246000037],
            [72.692682565000041, 33.319274600000028],
            [72.691697141000077, 33.31822562800005],
            [72.688216173000058, 33.314527076000047],
            [72.687362045000043, 33.313619348000032],
            [72.685906953000028, 33.312072942000043],
            [72.678539000000058, 33.304700000000025],
            [72.67970949100004, 33.300486652000075],
            [72.679911469000047, 33.299759600000073],
            [72.681339000000037, 33.294621000000063],
            [72.683124282000051, 33.292277955000031],
            [72.685665586000084, 33.288942688000077],
            [72.688758036000081, 33.284884084000055],
            [72.689339641000061, 33.284129893000056],
            [72.690280232000077, 33.282895708000069],
            [72.691805671000054, 33.280932091000068],
            [72.692555341000059, 33.279959963000067],
            [72.694217000000037, 33.277823000000069],
            [72.691911413000071, 33.277054536000037],
            [72.689862081000058, 33.276371484000038],
            [72.682460990000038, 33.273904663000053],
            [72.682459000000051, 33.273904000000073],
            [72.676126243000056, 33.276570079000066],
            [72.671820000000082, 33.278383000000076],
            [72.671818733000066, 33.278382513000054],
            [72.668079990000081, 33.276944851000053],
            [72.665118857000039, 33.275806205000038],
            [72.665104258000042, 33.275800591000063],
            [72.66504425100004, 33.275777516000062],
            [72.664541000000042, 33.275584000000038],
            [72.664178522000043, 33.274997294000059],
            [72.663648088000059, 33.27414152800003],
            [72.663567254000043, 33.274010943000064],
            [72.657643324000048, 33.26444101800007],
            [72.65726200000006, 33.263825000000054],
            [72.657233365000081, 33.263786093000078],
            [72.657065509000063, 33.263558024000076],
            [72.655315483000038, 33.261180241000034],
            [72.651374710000084, 33.255825859000026],
            [72.644383000000062, 33.245907000000045],
            [72.642649967000068, 33.234051376000025],
            [72.642386716000033, 33.232250487000044],
            [72.641071166000074, 33.224357971000074],
            [72.641024865000077, 33.224080190000052],
            [72.641023000000075, 33.224069000000043],
            [72.63799934900004, 33.220829346000073],
            [72.633184000000028, 33.215670000000046],
            [72.63259154800005, 33.212904755000068],
            [72.631237501000044, 33.206584793000047],
            [72.631234416000041, 33.206570395000028],
            [72.629825000000039, 33.199992000000066],
            [72.629796268000064, 33.199184535000029],
            [72.629622177000044, 33.199250634000066],
            [72.62955482700005, 33.198756732000049],
            [72.620529088000069, 33.202189205000025],
            [72.597689400000036, 33.202183522000041],
            [72.582854703000066, 33.213904133000028],
            [72.578226121000057, 33.217368577000059],
            [72.570493088000035, 33.223156670000037],
            [72.560500049000041, 33.226330448000056],
            [72.56028225600005, 33.226887030000057],
            [72.560249707000082, 33.226897442000052],
            [72.559231325000042, 33.226733393000075],
            [72.55690690800003, 33.227471625000078],
            [72.543951366000044, 33.224383057000068],
            [72.529556451000076, 33.213706565000052],
            [72.527308036000079, 33.212038949000032],
            [72.51067047600003, 33.196608224000045],
            [72.508970356000077, 33.188779929000077],
            [72.508766202000061, 33.187839891000067],
            [72.506381986000065, 33.176861643000052],
            [72.49818186300007, 33.165904393000062],
            [72.498182321000058, 33.165903714000024],
            [72.497144467000055, 33.164516884000079],
            [72.484073186000046, 33.161325531000045],
            [72.477432590000035, 33.159704229000056],
            [72.471848264000073, 33.15834084800008],
            [72.44777965000003, 33.157100903000071],
            [72.447516433000033, 33.157087686000068],
            [72.442496554000058, 33.156835614000045],
            [72.424474124000028, 33.155930623000074],
            [72.421969649000062, 33.155804861000036],
            [72.411440907000042, 33.155276164000043],
            [72.410369048000064, 33.155222341000069],
            [72.410365848000083, 33.155222180000067],
            [72.399289820000035, 33.154666000000077],
            [72.398410874000035, 33.154621864000035],
            [72.369014374000074, 33.154615444000058],
            [72.368171271000051, 33.154615260000071],
            [72.363294755000084, 33.15179858700003],
            [72.363254425000036, 33.151775292000025],
            [72.356097611000052, 33.147641520000036],
            [72.352138988000036, 33.145355105000078],
            [72.33620802300004, 33.143360407000046],
            [72.32252297000008, 33.141646918000049],
            [72.303359466000074, 33.137449263000065],
            [72.302988312000082, 33.137367964000077],
            [72.302781440000047, 33.137322650000044],
            [72.302637121000032, 33.137367964000077],
            [72.300081293000062, 33.138170458000047],
            [72.281312097000068, 33.14406376200003],
            [72.281175808000057, 33.144106555000064],
            [72.279575777000048, 33.143871993000062],
            [72.257607947000054, 33.140651544000036],
            [72.256656949000046, 33.140512129000058],
            [72.256593954000039, 33.140502894000065],
            [72.255882051000071, 33.140398530000027],
            [72.255171351000058, 33.140502894000065],
            [72.251932383000053, 33.140978526000026],
            [72.251830172000041, 33.141168486000026],
            [72.241900342000065, 33.142628838000064],
            [72.235664000000043, 33.143546000000072],
            [72.235269483000081, 33.143837591000079],
            [72.228365372000042, 33.148940482000057],
            [72.222198057000071, 33.153498800000079],
            [72.219073450000053, 33.155808225000044],
            [72.215243042000054, 33.158639314000027],
            [72.210693323000044, 33.162009959000045],
            [72.207868574000031, 33.16410266500003],
            [72.207242800000074, 33.16463811400007],
            [72.207242764000057, 33.164638145000026],
            [72.206279358000074, 33.159554811000078],
            [72.205285371000059, 33.154418318000069],
            [72.204508590000046, 33.14943351200003],
            [72.203504751000082, 33.142991621000078],
            [72.202930511000034, 33.139306576000024],
            [72.202475152000034, 33.134754830000077],
            [72.202304000000083, 33.133044000000041],
            [72.198659938000048, 33.130181080000057],
            [72.193655000000035, 33.12624900000003],
            [72.193095235000044, 33.125689235000038],
            [72.183153000000061, 33.115747000000056],
            [72.177310257000045, 33.112671558000045],
            [72.171508789000029, 33.10968017600004],
            [72.17027261800007, 33.109869238000044],
            [72.166425649000075, 33.110879402000023],
            [72.159855872000037, 33.111972925000032],
            [72.159839783000052, 33.111577806000071],
            [72.15983046100007, 33.111348892000024],
            [72.154113734000077, 33.112300459000039],
            [72.154000701000029, 33.112319274000072],
            [72.152988504000064, 33.112487757000054],
            [72.148552738000035, 33.113226104000034],
            [72.12701282300003, 33.113812553000059],
            [72.113612695000029, 33.114177396000059],
            [72.113630116000081, 33.114320346000056],
            [72.11132808900004, 33.114364334000072],
            [72.104125977000081, 33.114501953000058],
            [72.100280762000068, 33.115722656000059],
            [72.095886230000076, 33.117309570000032],
            [72.090881348000039, 33.118896484000061],
            [72.086730957000043, 33.120117188000052],
            [72.080688477000081, 33.122070313000052],
            [72.071289063000052, 33.125122070000032],
            [72.070867285000077, 33.125086405000047],
            [72.054687500000057, 33.123718262000068],
            [72.054683695000051, 33.123732742000072],
            [72.043117363000079, 33.122842509000066],
            [72.03836072200005, 33.122501791000047],
            [72.028595381000059, 33.122010947000035],
            [72.026857000000064, 33.121924000000035],
            [72.022671929000069, 33.121846468000058],
            [71.997471690000054, 33.12137961600007],
            [71.997465469000076, 33.121518217000073],
            [71.997465433000059, 33.121519019000061],
            [71.993530273000033, 33.121276855000076],
            [71.993275900000071, 33.121221711000032],
            [71.993088954000029, 33.121218211000041],
            [71.986580508000031, 33.119770263000078],
            [71.976074219000054, 33.11749267600004],
            [71.970760427000073, 33.111467589000029],
            [71.94746536100007, 33.086656728000037],
            [71.945374377000064, 33.085361033000027],
            [71.942811728000038, 33.083773067000038],
            [71.936987907000059, 33.080164290000027],
            [71.936997164000047, 33.080159662000028],
            [71.936986261000072, 33.080152886000064],
            [71.93697682700008, 33.080157418000056],
            [71.908908058000065, 33.062764297000058],
            [71.908756127000061, 33.062670151000077],
            [71.908618198000056, 33.062584682000079],
            [71.868679927000073, 33.058275858000059],
            [71.868528266000055, 33.058259496000062],
            [71.861646636000046, 33.055602386000032],
            [71.858305853000047, 33.054312454000069],
            [71.825360232000037, 33.041591609000079],
            [71.793291345000057, 33.025766970000063],
            [71.782158250000066, 33.02028970300006],
            [71.766628694000076, 33.012805365000077],
            [71.764305000000036, 33.019374000000028],
            [71.751575000000059, 33.025535000000048],
            [71.74515400000007, 33.02864100000005],
            [71.736416179000059, 33.031961530000046],
            [71.714266000000066, 33.04037900000003],
            [71.713085038000031, 33.058676399000035],
            [71.720664000000056, 33.057413000000054],
            [71.720663979000051, 33.057413087000043],
            [71.720664500000055, 33.057413000000054],
            [71.72066447800006, 33.057413088000033],
            [71.720189632000029, 33.059352128000057],
            [71.71926884100003, 33.06311218500008],
            [71.71863645500008, 33.065694535000034],
            [71.716129685000055, 33.075930947000074],
            [71.712713000000065, 33.089883000000043],
            [71.712745504000054, 33.090956651000056],
            [71.713235872000041, 33.107154356000024],
            [71.713237010000057, 33.107191943000032],
            [71.713354606000053, 33.111076362000063],
            [71.713355737000029, 33.111113715000045],
            [71.713375000000042, 33.111750000000029],
            [71.713500475000046, 33.111863518000064],
            [71.720041515000048, 33.11778128800006],
            [71.727291000000037, 33.124340000000075],
            [71.727922404000083, 33.12503453100004],
            [71.745267492000039, 33.144113735000076],
            [71.750776415000075, 33.150173425000048],
            [71.750954475000071, 33.150369287000046],
            [71.753739049000046, 33.153432255000041],
            [71.753797000000077, 33.153496000000075],
            [71.755851946000064, 33.158748293000031],
            [71.759760000000028, 33.168737000000078],
            [71.761086000000034, 33.182653000000073],
            [71.760572229000047, 33.189841924000064],
            [71.759913102000041, 33.19906472100007],
            [71.759760000000028, 33.201207000000068],
            [71.752247876000069, 33.209561534000045],
            [71.743725914000038, 33.219024126000079],
            [71.741869000000065, 33.221086000000071],
            [71.735391289000063, 33.225712803000079],
            [71.733917182000084, 33.226765707000027],
            [71.733076267000058, 33.227368643000034],
            [71.728090286000054, 33.231081009000036],
            [71.712712580000073, 33.246275647000061],
            [71.712712033000059, 33.246288242000048],
            [71.712120269000081, 33.25989166100004],
            [71.712065491000033, 33.26027282900003],
            [71.710496892000037, 33.271860791000051],
            [71.71006200000005, 33.274760000000072],
            [71.709400000000073, 33.286687000000029],
            [71.711387295000065, 33.289171432000046],
            [71.716628559000071, 33.295723835000047],
            [71.717351000000065, 33.296627000000058],
            [71.722586278000051, 33.300225976000036],
            [71.727954000000068, 33.303916000000072],
            [71.729007723000052, 33.308131689000049],
            [71.730574808000028, 33.314401210000028],
            [71.731929000000036, 33.319819000000052],
            [71.725966000000028, 33.332410000000039],
            [71.725948079000034, 33.332432054000037],
            [71.725354801000037, 33.333162169000047],
            [71.725280762000068, 33.333312988000046],
            [71.719909668000071, 33.339904785000044],
            [71.717960711000046, 33.342261663000045],
            [71.717351000000065, 33.343012000000044],
            [71.719925647000082, 33.346288855000068],
            [71.723348850000036, 33.350645702000065],
            [71.724670410000044, 33.352294922000056],
            [71.727515305000054, 33.356269703000066],
            [71.733255000000042, 33.364216000000056],
            [71.753058322000072, 33.372272069000076],
            [71.772350000000074, 33.380120000000034],
            [71.778700181000033, 33.382752957000037],
            [71.77875212500004, 33.382774494000046],
            [71.779195265000055, 33.382958232000078],
            [71.795685870000057, 33.389795681000066],
            [71.795724190000044, 33.389811570000063],
            [71.799519000000032, 33.391385000000071],
            [71.816085000000044, 33.399336000000062],
            [71.823987293000073, 33.404490120000048],
            [71.830356373000029, 33.408644232000029],
            [71.831325000000049, 33.409276000000034],
            [71.831325348000064, 33.409275901000058],
            [71.844482309000057, 33.40551676900003],
            [71.845241000000044, 33.405300000000068],
            [71.85584300000005, 33.403975000000059],
            [71.85713279600003, 33.404535736000071],
            [71.861083984000061, 33.405883789000029],
            [71.86895725800008, 33.409676402000059],
            [71.871084000000053, 33.410601000000042],
            [71.871105286000045, 33.41081390800008],
            [71.872409000000061, 33.423854000000063],
            [71.867771000000062, 33.432468000000028],
            [71.86733283500007, 33.442540525000027],
            [71.867326067000079, 33.442696098000056],
            [71.86710800000003, 33.447709000000032],
            [71.874397000000044, 33.454336000000069],
            [71.879547844000058, 33.456481970000027],
            [71.879692529000067, 33.456542249000051],
            [71.879692565000084, 33.456542264000063],
            [71.890301000000079, 33.460962000000052],
            [71.90501295100006, 33.476954006000028],
            [71.905059656000049, 33.477004775000069],
            [71.905541000000028, 33.477528000000063],
            [71.927838200000053, 33.49239255100008],
            [71.93536000000006, 33.497407000000067],
            [71.94861300000008, 33.50801000000007],
            [71.952800317000083, 33.512825115000055],
            [71.961866000000043, 33.523250000000075],
            [71.96869741200004, 33.539397400000041],
            [71.969155000000058, 33.540479000000062],
            [71.969003641000029, 33.540733562000071],
            [71.96257160600004, 33.551551276000055],
            [71.954577000000029, 33.564997000000062],
            [71.959215000000029, 33.574274000000059],
            [71.969832558000064, 33.579385762000072],
            [71.977106964000029, 33.582887983000035],
            [71.977107000000046, 33.582888000000025],
            [71.978489213000046, 33.591971922000027],
            [71.97876474900005, 33.593784858000049],
            [71.981475858000067, 33.611600389000046],
            [71.981634360000044, 33.612641952000047],
            [71.981716315000028, 33.613169030000051],
            [71.981745000000046, 33.613369000000034],
            [71.98178585200003, 33.613822521000031],
            [71.981875176000074, 33.614963432000025],
            [71.981949207000071, 33.615870163000068],
            [71.982121997000036, 33.617986533000078],
            [71.982137597000076, 33.61817760200006],
            [71.983270994000065, 33.632059692000041],
            [71.983646853000039, 33.636663288000079],
            [71.983653778000075, 33.636748109000052],
            [71.984396000000061, 33.645839000000024],
            [71.985385484000062, 33.661673731000064],
            [71.985721000000069, 33.667043000000035],
            [71.987715773000048, 33.671384343000057],
            [71.988345715000037, 33.67274678900003],
            [71.990905762000068, 33.678283691000047],
            [71.992299259000049, 33.681360423000058],
            [71.996986000000049, 33.691561000000036],
            [71.998925329000031, 33.69585524300004],
            [71.999328613000046, 33.696716309000067],
            [72.006286621000072, 33.712097168000071],
            [72.016113281000059, 33.717895508000026],
            [72.01619209100005, 33.717859916000066],
            [72.017573913000035, 33.718781131000071],
            [72.021149248000029, 33.721164688000044],
            [72.021503000000052, 33.721380000000067],
            [72.034756000000073, 33.737946000000079],
            [72.034756375000029, 33.737947076000069],
            [72.040057000000047, 33.753187000000025],
            [72.047827544000029, 33.755577833000075],
            [72.057286000000033, 33.758488000000057],
            [72.069214000000045, 33.758488000000057],
            [72.083129000000042, 33.765777000000071],
            [72.079484038000032, 33.771565906000035],
            [72.076649450000048, 33.776067780000062],
            [72.076649403000033, 33.776067853000029],
            [72.076649357000065, 33.776067927000042],
            [72.071864000000062, 33.783668000000034],
            [72.071905314000048, 33.783678331000033],
            [72.085117000000082, 33.78698200000008],
            [72.103671000000077, 33.785656000000074],
            [72.112948000000074, 33.779692000000068],
            [72.121792582000069, 33.777280337000036],
            [72.124722813000062, 33.776481348000061],
            [72.127526000000046, 33.775717000000043],
            [72.14806800000008, 33.77372900000006],
            [72.152340391000052, 33.772743044000038],
            [72.152991419000045, 33.772592804000055],
            [72.15911587800008, 33.771179440000026],
            [72.165297000000066, 33.769753000000037],
            [72.183850000000064, 33.763789000000031],
            [72.193127000000061, 33.76047600000004],
            [72.193127450000077, 33.760476018000077],
            [72.199356961000035, 33.760725334000028],
            [72.19936462000004, 33.760725640000032],
            [72.206273444000033, 33.761002143000042],
            [72.208928365000077, 33.761108398000033],
            [72.209693000000073, 33.761139000000071],
            [72.219633000000044, 33.771741000000077],
            [72.220180571000071, 33.771584545000053],
            [72.229751277000048, 33.768849959000079],
            [72.247464000000036, 33.763789000000031],
            [72.247375150000039, 33.763950049000073],
            [72.24353261400006, 33.770914961000074],
            [72.243532549000065, 33.770915079000076],
            [72.24353248500006, 33.770915196000033],
            [72.236862000000031, 33.783006000000057],
            [72.236890980000055, 33.78392629800004],
            [72.236918574000072, 33.783909591000054],
            [72.237283963000039, 33.783688375000054],
            [72.23691864500006, 33.783911002000025],
            [72.237304687000062, 33.791687012000068],
            [72.237304688000052, 33.791687048000028],
            [72.237597469000036, 33.800909640000043],
            [72.237915039000029, 33.810913086000028],
            [72.238294835000033, 33.827795079000055],
            [72.239938127000073, 33.854306654000027],
            [72.240036582000073, 33.855895043000032],
            [72.240112305000082, 33.857116699000073],
            [72.239431216000071, 33.864595585000075],
            [72.239444035000076, 33.86500267100007],
            [72.239512000000047, 33.867161000000067],
            [72.239328923000073, 33.867786561000059],
            [72.235473633000083, 33.881530762000068],
            [72.235473597000066, 33.881530883000039],
            [72.235027332000072, 33.883035229000029],
            [72.232249140000079, 33.892400423000026],
            [72.231689453000058, 33.894287109000061],
            [72.238551484000084, 33.899347857000066],
            [72.249371046000078, 33.907327284000075],
            [72.256078000000059, 33.912221000000045],
            [72.256371928000078, 33.912369736000073],
            [72.263354016000051, 33.915902864000032],
            [72.265384900000072, 33.91693054700005],
            [72.281641441000033, 33.92515680200006],
            [72.305879796000056, 33.93742207300005],
            [72.306305684000051, 33.937637584000072],
            [72.306394799000032, 33.937682678000044],
            [72.306397214000071, 33.937683901000071],
            [72.310561883000048, 33.939791337000031],
            [72.311077000000068, 33.940052000000037],
            [72.313512109000044, 33.941589730000032],
            [72.32386434600005, 33.947990731000061],
            [72.323895993000065, 33.947988753000061],
            [72.324267408000082, 33.947965544000056],
            [72.33880590800004, 33.947057059000031],
            [72.344872000000066, 33.946678000000077],
            [72.349765504000061, 33.948665864000077],
            [72.360715220000031, 33.953113911000059],
            [72.361076314000059, 33.953260597000053],
            [72.361188607000031, 33.953306213000076],
            [72.361194799000032, 33.953308729000071],
            [72.366077000000075, 33.955292000000043],
            [72.377594592000037, 33.957295486000078],
            [72.381294817000082, 33.957940983000071],
            [72.38130637900008, 33.957943000000057],
            [72.38130637900008, 33.961462040000072],
            [72.38130637900008, 33.97007928000005],
            [72.38130637900008, 33.970086999000046],
            [72.38130637900008, 33.972496593000074],
            [72.38130637900008, 33.972521000000029],
            [72.381875037000043, 33.972571177000077],
            [72.387133199000061, 33.973035146000029],
            [72.396890161000044, 33.973895533000075],
            [72.398869852000075, 33.974070106000056],
            [72.398869792000085, 33.974069821000057],
            [72.403601128000048, 33.974487305000025],
            [72.403847000000042, 33.974509000000069],
            [72.402761723000083, 33.988648056000045],
            [72.40252448800004, 33.991738786000042],
            [72.405574430000058, 33.992701896000028],
            [72.405604638000057, 33.992711435000047],
            [72.423983184000065, 33.998514968000052],
            [72.425294835000045, 33.998895079000079],
            [72.425294948000044, 33.998895115000039],
            [72.435294799000076, 34.00205746000006],
            [72.436994835000064, 34.002595079000059],
            [72.438628292000033, 34.003139564000037],
            [72.439687034000031, 34.003473892000045],
            [72.440292087000046, 34.00366487000008],
            [72.444291897000085, 33.997666061000075],
            [72.445158796000044, 33.99636590700004],
            [72.44689483500008, 33.993695079000076],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "91",
      properties: { name: "Bahawalnagar", count: 853 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.941295000000082, 30.298310000000072],
            [73.945446081000057, 30.291287573000034],
            [73.946446000000037, 30.289596000000074],
            [73.954058000000032, 30.278613000000064],
            [73.958090000000084, 30.272556000000066],
            [73.961201000000074, 30.264688000000035],
            [73.961720969000055, 30.264082846000065],
            [73.96196400000008, 30.26380000000006],
            [73.961823000000038, 30.261372000000051],
            [73.964094000000046, 30.255175000000065],
            [73.964085000000068, 30.247681000000057],
            [73.967181000000039, 30.235723000000064],
            [73.967315000000042, 30.232606000000033],
            [73.967524534000063, 30.228189101000055],
            [73.967654000000039, 30.225460000000055],
            [73.968956140000046, 30.219801205000067],
            [73.969638000000032, 30.216838000000052],
            [73.970280000000059, 30.210081000000059],
            [73.971086000000071, 30.206117000000063],
            [73.972530000000063, 30.198311000000047],
            [73.952778913000031, 30.182994154000028],
            [73.943876000000046, 30.176090000000045],
            [73.937397000000033, 30.171181000000047],
            [73.932632673000057, 30.167735288000074],
            [73.911099921000073, 30.152162125000075],
            [73.904644000000076, 30.147493000000054],
            [73.902404000000047, 30.145895000000053],
            [73.901677025000083, 30.145363625000073],
            [73.896305000000041, 30.141437000000053],
            [73.877301117000059, 30.127753546000065],
            [73.85365080300005, 30.11072450100005],
            [73.842034000000069, 30.102360000000033],
            [73.822992000000056, 30.088647000000037],
            [73.820075000000031, 30.085413000000074],
            [73.817734706000067, 30.082412694000027],
            [73.806576000000064, 30.068107000000055],
            [73.800195770000073, 30.066627679000078],
            [73.778472330000056, 30.06159087900005],
            [73.773910108000052, 30.060533082000063],
            [73.75512966000008, 30.056178644000056],
            [73.751435000000072, 30.055322000000046],
            [73.730812084000036, 30.050391457000046],
            [73.708372841000084, 30.045026664000034],
            [73.692488313000069, 30.041228979000039],
            [73.679422158000079, 30.038105112000039],
            [73.664707000000078, 30.034587000000045],
            [73.662928216000068, 30.034198037000067],
            [73.634026851000044, 30.027878233000024],
            [73.608329688000083, 30.022259086000076],
            [73.598776000000043, 30.020170000000064],
            [73.592493313000034, 30.017828997000038],
            [73.583596439000075, 30.014513917000045],
            [73.567342828000051, 30.008457630000066],
            [73.533076774000051, 29.995689694000077],
            [73.498653629000046, 29.982863225000074],
            [73.48880900000006, 29.979195000000061],
            [73.457385392000049, 29.967751705000069],
            [73.431414723000046, 29.958294165000041],
            [73.431255000000078, 29.958236000000056],
            [73.416313917000082, 29.952969997000025],
            [73.39776100000006, 29.946431000000075],
            [73.394301175000066, 29.936020388000031],
            [73.383205000000032, 29.90263200000004],
            [73.380381846000034, 29.892790581000042],
            [73.371094969000069, 29.860416837000059],
            [73.363363582000034, 29.83346547900004],
            [73.356054087000075, 29.807984823000027],
            [73.347692341000084, 29.778836054000067],
            [73.341091000000063, 29.755824000000075],
            [73.34006979000003, 29.752658655000062],
            [73.330335455000068, 29.722486089000029],
            [73.320367088000069, 29.691588121000052],
            [73.31785222600007, 29.68379304900003],
            [73.308170000000075, 29.653782000000035],
            [73.302005805000078, 29.635616654000046],
            [73.294600930000058, 29.613795129000039],
            [73.283776000000046, 29.581895000000031],
            [73.282983582000043, 29.577936314000056],
            [73.281447000000071, 29.570260000000076],
            [73.268033571000046, 29.548114111000075],
            [73.263228000000083, 29.540180000000078],
            [73.25655038900004, 29.528834090000032],
            [73.245937620000063, 29.510801965000041],
            [73.23630139800008, 29.494429087000071],
            [73.233102000000031, 29.48899300000005],
            [73.211743374000037, 29.451558939000051],
            [73.201423000000034, 29.433471000000054],
            [73.192336767000029, 29.418707392000044],
            [73.189111288000049, 29.413466529000061],
            [73.172824239000079, 29.387002800000062],
            [73.168563000000063, 29.380079000000023],
            [73.156193124000083, 29.358970377000048],
            [73.133549820000042, 29.320330625000054],
            [73.123892000000069, 29.303850000000068],
            [73.117670993000047, 29.29285430300007],
            [73.117128069000046, 29.291894680000041],
            [73.101577000000077, 29.26440800000006],
            [73.09775965700004, 29.258041890000072],
            [73.084488000000078, 29.235909000000049],
            [73.068879816000049, 29.21993629800005],
            [73.050019000000077, 29.200635000000034],
            [73.026816401000076, 29.176588023000079],
            [73.022650000000056, 29.172270000000026],
            [73.020840264000071, 29.170351811000046],
            [73.006347000000062, 29.154990000000055],
            [73.001478231000078, 29.144094782000025],
            [72.997347626000078, 29.134851410000067],
            [72.991624277000028, 29.122043830000052],
            [72.984810000000039, 29.106795000000034],
            [72.982697014000053, 29.102403632000062],
            [72.971419182000034, 29.078965181000058],
            [72.969633000000044, 29.075253000000032],
            [72.957255000000032, 29.048444000000075],
            [72.94704900000005, 29.026562000000069],
            [72.917128714000057, 29.015811854000049],
            [72.902944606000062, 29.01071560500003],
            [72.898910000000058, 29.009266000000025],
            [72.894163491000029, 29.007575882000026],
            [72.843068000000073, 28.989382000000035],
            [72.780367000000069, 28.963742000000025],
            [72.716123000000039, 28.938911000000076],
            [72.68396400000006, 28.921779000000072],
            [72.642513000000065, 28.898486000000048],
            [72.580675000000042, 28.866307000000063],
            [72.521205000000066, 28.834703000000047],
            [72.491847791000055, 28.820237079000037],
            [72.48070800000005, 28.835071000000028],
            [72.471593664000068, 28.851546817000042],
            [72.468223500000079, 28.857639000000063],
            [72.461021000000073, 28.873004000000037],
            [72.453819000000067, 28.889810000000068],
            [72.447096500000043, 28.909017000000063],
            [72.436053000000072, 28.929664000000059],
            [72.433652000000052, 28.945029000000034],
            [72.431251000000032, 28.965196000000049],
            [72.428370000000029, 28.978160000000059],
            [72.425009000000045, 28.991125000000068],
            [72.414925500000038, 29.011292000000026],
            [72.404362000000049, 29.030018000000041],
            [72.391877500000078, 29.046344000000033],
            [72.385703745000058, 29.057456245000026],
            [72.382274000000052, 29.063629500000047],
            [72.369310000000041, 29.080915000000061],
            [72.361627000000055, 29.087638000000027],
            [72.351544000000047, 29.09388000000007],
            [72.343381000000079, 29.100602000000038],
            [72.337859378000076, 29.106123622000041],
            [72.337498704000041, 29.106484296000076],
            [72.335218000000054, 29.108765000000062],
            [72.331377000000032, 29.107324000000062],
            [72.334738000000073, 29.095800000000054],
            [72.335218000000054, 29.085237000000063],
            [72.331377000000032, 29.079475000000059],
            [72.326095000000066, 29.08043500000008],
            [72.322879232000048, 29.08043500000008],
            [72.318413000000078, 29.08043500000008],
            [72.31745200000006, 29.076114000000075],
            [72.312650500000075, 29.066510000000051],
            [72.309289000000035, 29.060749000000044],
            [72.303047000000049, 29.060268000000065],
            [72.297766000000081, 29.067471000000069],
            [72.297599269000045, 29.071955957000057],
            [72.297285000000045, 29.08043500000008],
            [72.297079328000052, 29.081875238000066],
            [72.295699846000048, 29.091535208000039],
            [72.295365000000061, 29.09388000000007],
            [72.291043000000059, 29.096281000000033],
            [72.289158096000051, 29.096281000000033],
            [72.286722000000054, 29.096281000000033],
            [72.276638000000048, 29.095320000000072],
            [72.275198000000046, 29.099162000000035],
            [72.277598000000069, 29.107324000000062],
            [72.280479000000071, 29.11692800000003],
            [72.27951900000005, 29.123170000000073],
            [72.273277000000064, 29.123650000000055],
            [72.267995000000042, 29.129412000000059],
            [72.26946677400008, 29.13226340600005],
            [72.273629665000044, 29.14032856700004],
            [72.275678000000084, 29.144297000000051],
            [72.273757000000046, 29.149099000000035],
            [72.270329033000053, 29.153098096000065],
            [72.267995000000042, 29.15582100000006],
            [72.261753000000056, 29.161103000000026],
            [72.264154000000076, 29.168785000000071],
            [72.268557134000048, 29.173628264000058],
            [72.26895600000006, 29.174067000000036],
            [72.273914655000056, 29.17836468400003],
            [72.276158000000066, 29.180309000000079],
            [72.27629728900007, 29.182817064000062],
            [72.276638000000048, 29.188952000000029],
            [72.26895600000006, 29.193754000000069],
            [72.263674000000037, 29.199036000000035],
            [72.268475464000062, 29.205277953000063],
            [72.268475500000079, 29.205278000000078],
            [72.278079000000048, 29.204318000000058],
            [72.276158000000066, 29.210560000000044],
            [72.273277000000064, 29.21776200000005],
            [72.275678000000084, 29.224004000000036],
            [72.282400000000052, 29.224004000000036],
            [72.29296400000004, 29.224965000000054],
            [72.297595908000062, 29.223806902000035],
            [72.302567000000067, 29.222564000000034],
            [72.313611000000037, 29.226405000000057],
            [72.320333000000062, 29.234088000000042],
            [72.32452061400005, 29.234553129000062],
            [72.328976000000068, 29.235048000000063],
            [72.333778000000052, 29.236008000000027],
            [72.337684668000065, 29.234157654000057],
            [72.34290100000004, 29.231687000000079],
            [72.34290100000004, 29.224645898000063],
            [72.34290100000004, 29.221603000000073],
            [72.343189490000043, 29.221202325000036],
            [72.351544000000047, 29.209599000000026],
            [72.353996761000076, 29.208080589000076],
            [72.361627000000055, 29.20335700000004],
            [72.368055840000068, 29.200988554000048],
            [72.370750000000044, 29.199996000000056],
            [72.380354000000068, 29.20575800000006],
            [72.383753459000047, 29.20615039200004],
            [72.39283800000004, 29.20719900000006],
            [72.398206618000074, 29.207413673000076],
            [72.404842000000031, 29.207679000000041],
            [72.41972700000008, 29.205278000000078],
            [72.423636115000079, 29.205175162000046],
            [72.437973000000056, 29.204798000000039],
            [72.449977000000047, 29.204798000000039],
            [72.449978000000044, 29.204798000000039],
            [72.45434244900008, 29.206045036000035],
            [72.463422000000037, 29.208639000000062],
            [72.474192426000059, 29.209381521000068],
            [72.477347000000066, 29.209599000000026],
            [72.482148000000052, 29.21920300000005],
            [72.484069000000034, 29.229766000000041],
            [72.484302276000051, 29.22985084000004],
            [72.489351000000056, 29.231687000000079],
            [72.496073000000081, 29.232167000000061],
            [72.508557000000053, 29.229286000000059],
            [72.507117000000051, 29.241290000000049],
            [72.518641000000059, 29.244171000000051],
            [72.528244000000029, 29.245131000000072],
            [72.53304600000007, 29.241770000000031],
            [72.544569053000032, 29.241290039000035],
            [72.544570000000078, 29.241290000000049],
            [72.548411000000044, 29.244171000000051],
            [72.558014000000071, 29.245612000000051],
            [72.569058000000041, 29.246092000000033],
            [72.585864000000072, 29.248012000000074],
            [72.596427000000062, 29.248012000000074],
            [72.603977469000029, 29.244729693000068],
            [72.607471000000032, 29.243211000000031],
            [72.615634000000057, 29.236969000000045],
            [72.628118000000029, 29.227365000000077],
            [72.633880000000033, 29.222084000000052],
            [72.643003000000078, 29.21680200000003],
            [72.649536435000073, 29.215097651000065],
            [72.654047000000048, 29.213921000000028],
            [72.664130000000057, 29.214401000000066],
            [72.664131000000054, 29.214401000000066],
            [72.670853000000079, 29.214401000000066],
            [72.676134000000047, 29.214401000000066],
            [72.676135000000045, 29.214401000000066],
            [72.677575000000047, 29.210080000000062],
            [72.684297000000072, 29.208159000000023],
            [72.689579000000037, 29.212000000000046],
            [72.693413068000041, 29.212294834000033],
            [72.69582100000008, 29.212480000000028],
            [72.701103000000046, 29.218242000000032],
            [72.709266000000071, 29.21680200000003],
            [72.717428577000078, 29.213921149000043],
            [72.717429000000038, 29.213921000000028],
            [72.722715394000033, 29.215291531000048],
            [72.730393000000049, 29.217282000000068],
            [72.735675000000072, 29.218722000000071],
            [72.740476848000071, 29.222083894000036],
            [72.740477000000055, 29.222084000000052],
            [72.744318000000078, 29.221603000000073],
            [72.750080000000082, 29.221603000000073],
            [72.757429811000065, 29.220553339000048],
            [72.760163000000034, 29.22016300000007],
            [72.763670277000074, 29.223318965000033],
            [72.764965000000075, 29.224484000000075],
            [72.763524007000058, 29.229765973000042],
            [72.757421841000053, 29.229601124000055],
            [72.753765789000056, 29.22950234800004],
            [72.74575800000008, 29.229286000000059],
            [72.737596000000053, 29.229286000000059],
            [72.736155000000053, 29.238409000000047],
            [72.732794000000069, 29.244171000000051],
            [72.729913000000067, 29.252814000000058],
            [72.723671000000081, 29.251374000000055],
            [72.72031000000004, 29.249453000000074],
            [72.716717853000034, 29.248255369000049],
            [72.715988000000038, 29.248012000000074],
            [72.712627000000055, 29.243691000000069],
            [72.706385000000068, 29.247052000000053],
            [72.707345000000032, 29.25569500000006],
            [72.70446400000003, 29.256655000000023],
            [72.69582100000008, 29.252814000000058],
            [72.689099000000056, 29.249453000000074],
            [72.679495000000031, 29.249933000000055],
            [72.683337000000051, 29.254735000000039],
            [72.69246000000004, 29.258096000000023],
            [72.690059000000076, 29.264338000000066],
            [72.689924938000047, 29.264927971000077],
            [72.689924912000038, 29.264927964000037],
            [72.689019235000046, 29.268912791000048],
            [72.687658000000056, 29.274902000000054],
            [72.690059000000076, 29.282104000000061],
            [72.693457383000066, 29.284447603000046],
            [72.703984000000048, 29.291707000000031],
            [72.717909000000077, 29.295069000000069],
            [72.729433000000029, 29.294588000000033],
            [72.73384167100005, 29.294168251000031],
            [72.735999857000081, 29.293962771000054],
            [72.739516000000037, 29.293628000000069],
            [72.749600000000044, 29.290747000000067],
            [72.75536100000005, 29.290747000000067],
            [72.755362000000048, 29.290747000000067],
            [72.763044000000036, 29.296029000000033],
            [72.771069993000083, 29.296029000000033],
            [72.773127000000045, 29.296029000000033],
            [72.773128000000042, 29.296029000000033],
            [72.782041149000065, 29.303159814000026],
            [72.782731000000069, 29.303711000000078],
            [72.772755000000075, 29.301574000000073],
            [72.769286000000079, 29.300831000000073],
            [72.768806000000041, 29.304192000000057],
            [72.772167000000081, 29.312835000000064],
            [72.777929000000029, 29.320997000000034],
            [72.771207000000061, 29.31907700000005],
            [72.761123000000055, 29.310914000000025],
            [72.753921000000048, 29.307073000000059],
            [72.748159000000044, 29.308033000000023],
            [72.743068000000051, 29.313487000000066],
            [72.743068058000063, 29.313487188000067],
            [72.741437000000076, 29.31523500000003],
            [72.737115000000074, 29.311874000000046],
            [72.729433000000029, 29.310434000000043],
            [72.723190000000045, 29.303711000000078],
            [72.716468000000077, 29.299870000000055],
            [72.713107000000036, 29.309954000000062],
            [72.714067000000057, 29.321958000000052],
            [72.707825000000071, 29.333001000000024],
            [72.704944000000069, 29.341164000000049],
            [72.69582100000008, 29.344045000000051],
            [72.69342000000006, 29.338283000000047],
            [72.693282741000075, 29.338130493000051],
            [72.689099000000056, 29.33348200000006],
            [72.679495000000031, 29.328200000000038],
            [72.672773000000063, 29.324839000000054],
            [72.667011000000059, 29.326279000000056],
            [72.663650000000075, 29.33108100000004],
            [72.658849000000032, 29.336843000000044],
            [72.651646000000028, 29.343565000000069],
            [72.651513641000065, 29.343565000000069],
            [72.651512129000082, 29.343565000000069],
            [72.642043000000058, 29.343565000000069],
            [72.639642000000038, 29.350287000000037],
            [72.633880000000033, 29.359891000000061],
            [72.632873535000044, 29.363708496000072],
            [72.632863091000047, 29.363705468000035],
            [72.632863081000039, 29.363705507000077],
            [72.631960000000049, 29.367093000000068],
            [72.635801000000072, 29.374776000000054],
            [72.637241000000074, 29.38101800000004],
            [72.638201000000038, 29.395903000000033],
            [72.638201000000038, 29.405506000000059],
            [72.64108200000004, 29.411748000000046],
            [72.641083000000037, 29.411748000000046],
            [72.643963000000042, 29.411748000000046],
            [72.648285000000044, 29.415590000000066],
            [72.648672010000041, 29.415804982000054],
            [72.649778367000067, 29.416419547000032],
            [72.65692800000005, 29.420391000000052],
            [72.661087271000042, 29.42135085700005],
            [72.667854352000063, 29.422912533000044],
            [72.669412000000079, 29.423272000000054],
            [72.679976000000067, 29.431435000000079],
            [72.68596720000005, 29.434071248000066],
            [72.69030299700006, 29.435979085000042],
            [72.690305881000029, 29.435980354000037],
            [72.691980000000058, 29.436717000000044],
            [72.703984000000048, 29.438638000000026],
            [72.703984000000048, 29.444880000000069],
            [72.703984000000048, 29.445260748000067],
            [72.703984000000048, 29.449850382000079],
            [72.703984000000048, 29.449850501000071],
            [72.703984000000048, 29.451602000000037],
            [72.706385000000068, 29.458804000000043],
            [72.712627000000055, 29.459765000000061],
            [72.718123786000035, 29.46159751700003],
            [72.719829000000061, 29.462166000000025],
            [72.719829290000064, 29.462166103000072],
            [72.726552000000083, 29.464566000000048],
            [72.729740697000068, 29.468552494000051],
            [72.729749286000072, 29.468563232000065],
            [72.730393000000049, 29.469368000000031],
            [72.730681631000039, 29.475142419000065],
            [72.730873000000031, 29.478971000000058],
            [72.730873000000031, 29.479351748000056],
            [72.730873000000031, 29.488575000000026],
            [72.730873000000031, 29.488955748000024],
            [72.730873000000031, 29.497698000000071],
            [72.730873000000031, 29.498078748000069],
            [72.730873000000031, 29.506412190000049],
            [72.730873000000031, 29.506412899000054],
            [72.730873000000031, 29.507301000000041],
            [72.730399184000078, 29.510775762000037],
            [72.72943250000003, 29.517865000000029],
            [72.728709718000061, 29.523647253000036],
            [72.727992000000029, 29.529389000000037],
            [72.721270000000061, 29.532750000000078],
            [72.716319002000034, 29.538574382000036],
            [72.716317963000051, 29.538575604000073],
            [72.713107000000036, 29.542353000000048],
            [72.705424500000049, 29.541393000000028],
            [72.703504000000066, 29.546674000000053],
            [72.707825000000071, 29.548595000000034],
            [72.714067000000057, 29.548595000000034],
            [72.714403585000071, 29.549478427000054],
            [72.717909000000077, 29.558679000000041],
            [72.719349000000079, 29.562040000000025],
            [72.717429000000038, 29.567321000000049],
            [72.717284465000034, 29.569345393000049],
            [72.716949000000056, 29.574044000000072],
            [72.717909000000077, 29.578365000000076],
            [72.715210131000049, 29.57862220100003],
            [72.715186650000078, 29.578624439000066],
            [72.707825000000071, 29.579326000000037],
            [72.704944000000069, 29.582687000000078],
            [72.701103000000046, 29.589409000000046],
            [72.700882781000075, 29.58958919500003],
            [72.69582100000008, 29.593731000000048],
            [72.690539500000057, 29.589889000000028],
            [72.680936000000031, 29.591810000000066],
            [72.673734000000081, 29.593731000000048],
            [72.674214000000063, 29.599973000000034],
            [72.669412000000079, 29.601413000000036],
            [72.671333000000061, 29.605254000000059],
            [72.676134000000047, 29.605735000000038],
            [72.677454541000031, 29.609694791000038],
            [72.677454581000063, 29.609694783000066],
            [72.677454990000058, 29.609696009000061],
            [72.677454950000083, 29.609696017000033],
            [72.677575000000047, 29.610056000000043],
            [72.676762330000031, 29.616556512000045],
            [72.676614500000085, 29.617739000000029],
            [72.676134500000046, 29.621100000000069],
            [72.676134500000046, 29.625421000000074],
            [72.67949550000003, 29.628782000000058],
            [72.686218000000054, 29.630223000000058],
            [72.683869538000067, 29.637737882000067],
            [72.683868347000043, 29.637741692000077],
            [72.683817000000033, 29.637906000000044],
            [72.682377000000031, 29.64270700000003],
            [72.676615000000083, 29.643668000000048],
            [72.673734000000081, 29.645588000000032],
            [72.672293000000082, 29.642227000000048],
            [72.667972000000077, 29.636465000000044],
            [72.664864571000066, 29.642679140000041],
            [72.664802462000068, 29.642803343000026],
            [72.663650000000075, 29.64510800000005],
            [72.663650000000075, 29.645720003000065],
            [72.663650000000075, 29.647393718000046],
            [72.663650000000075, 29.654711000000077],
            [72.659329000000071, 29.653271000000075],
            [72.655577644000061, 29.651082818000077],
            [72.653567000000066, 29.649910000000034],
            [72.645404000000042, 29.648469000000034],
            [72.645404000000042, 29.654231000000038],
            [72.646311357000059, 29.666938727000058],
            [72.646364000000062, 29.667676000000029],
            [72.646629227000062, 29.674295105000056],
            [72.646629679000057, 29.674306373000036],
            [72.646845000000042, 29.679680000000076],
            [72.639642000000038, 29.685442000000023],
            [72.632803573000047, 29.687639839000042],
            [72.626197500000046, 29.689763000000028],
            [72.617395246000058, 29.694022355000072],
            [72.611312000000055, 29.696966000000032],
            [72.605070000000069, 29.703688000000056],
            [72.599729300000035, 29.705927614000075],
            [72.590185000000076, 29.709930000000043],
            [72.586299460000077, 29.712105824000048],
            [72.578181000000029, 29.716652000000067],
            [72.578661000000068, 29.724335000000053],
            [72.581062000000031, 29.732018000000039],
            [72.577221000000065, 29.734419000000059],
            [72.569058000000041, 29.737300000000062],
            [72.568606598000031, 29.742718702000047],
            [72.568606268000053, 29.742722658000048],
            [72.568578000000059, 29.743062000000066],
            [72.56953800000008, 29.752185000000054],
            [72.563776000000075, 29.75842700000004],
            [72.560895000000073, 29.764189000000044],
            [72.560528658000067, 29.766167033000045],
            [72.560514993000083, 29.76624081500006],
            [72.559598700000038, 29.771188265000035],
            [72.558723549000035, 29.775913570000057],
            [72.558721731000048, 29.775923385000056],
            [72.558494000000053, 29.777153000000055],
            [72.562336000000073, 29.785316000000023],
            [72.565216000000078, 29.796840000000032],
            [72.563446000000056, 29.80834799400003],
            [72.563661000000081, 29.808343000000036],
            [72.566563000000031, 29.821401000000037],
            [72.565046644000063, 29.824433713000076],
            [72.56221000000005, 29.830107000000055],
            [72.563385915000083, 29.834221790000072],
            [72.564145000000053, 29.83687800000007],
            [72.569948000000068, 29.839779000000078],
            [72.579621000000031, 29.839779000000078],
            [72.582200196000031, 29.839521240000067],
            [72.584845767000047, 29.839256847000058],
            [72.592190673000061, 29.838522813000054],
            [72.594130000000064, 29.838329000000044],
            [72.604771000000028, 29.838812000000075],
            [72.605586201000051, 29.840197977000059],
            [72.60960700000004, 29.847034000000065],
            [72.61059798000008, 29.847914795000065],
            [72.618313000000057, 29.854772000000025],
            [72.621870536000074, 29.856221387000062],
            [72.631371000000058, 29.860092000000066],
            [72.638158660000045, 29.859570038000072],
            [72.643945047000045, 29.85912507300003],
            [72.643946000000028, 29.859125000000063],
            [72.644494989000066, 29.859973457000024],
            [72.644494919000067, 29.859974735000037],
            [72.649266000000068, 29.867347000000052],
            [72.655553000000054, 29.87073300000003],
            [72.665226000000075, 29.869282000000055],
            [72.672964000000036, 29.863478000000043],
            [72.679735000000051, 29.857674000000031],
            [72.681401379000079, 29.856841026000041],
            [72.683604000000059, 29.855740000000026],
            [72.683604000000059, 29.86154300000004],
            [72.685539000000063, 29.866380000000049],
            [72.686704012000064, 29.868127669000046],
            [72.689408000000071, 29.872184000000061],
            [72.692310000000077, 29.873634000000038],
            [72.693829952000044, 29.878574140000069],
            [72.696179000000029, 29.886209000000065],
            [72.695944052000073, 29.887350354000034],
            [72.694739291000076, 29.893202966000047],
            [72.692794000000049, 29.902653000000043],
            [72.694244000000083, 29.910391000000061],
            [72.703434000000072, 29.914744000000042],
            [72.709721000000059, 29.922482000000059],
            [72.713903000000073, 29.922715000000039],
            [72.717957226000067, 29.922939936000034],
            [72.718427000000077, 29.922966000000031],
            [72.72713200000004, 29.923450000000059],
            [72.73245200000008, 29.923450000000059],
            [72.739223000000038, 29.916195000000073],
            [72.742609000000073, 29.908457000000055],
            [72.747929000000056, 29.898784000000035],
            [72.751798000000065, 29.892496000000051],
            [72.760802086000069, 29.892496000000051],
            [72.761471000000029, 29.892496000000051],
            [72.770660000000078, 29.892496000000051],
            [72.773562000000084, 29.898784000000035],
            [72.770660000000078, 29.906522000000052],
            [72.762438000000031, 29.912809000000038],
            [72.762438108000083, 29.912809917000061],
            [72.763405000000034, 29.921031000000028],
            [72.767221004000078, 29.925228053000069],
            [72.768242000000043, 29.926351000000068],
            [72.773562000000084, 29.932155000000023],
            [72.773562000000084, 29.938649704000056],
            [72.773562000000084, 29.938650224000071],
            [72.773562000000084, 29.940377000000069],
            [72.772252318000028, 29.946924058000036],
            [72.77162700000008, 29.950050000000033],
            [72.767758000000072, 29.955854000000045],
            [72.767275000000041, 29.961657000000059],
            [72.767515586000059, 29.961897586000077],
            [72.772595000000081, 29.966977000000043],
            [72.782751000000076, 29.973748000000057],
            [72.782898814000077, 29.973940155000037],
            [72.784619135000071, 29.976176537000072],
            [72.78493796500004, 29.97659101000005],
            [72.787588000000085, 29.980036000000041],
            [72.792615856000054, 29.980492949000052],
            [72.798227488000066, 29.981002953000029],
            [72.798228000000051, 29.981003000000044],
            [72.804563266000059, 29.980399790000035],
            [72.807629948000056, 29.980107794000048],
            [72.808384000000046, 29.980036000000041],
            [72.819508000000042, 29.980519000000072],
            [72.826279000000056, 29.977134000000035],
            [72.828458366000064, 29.975477711000053],
            [72.83032047100005, 29.974062536000076],
            [72.838370000000054, 29.967945000000043],
            [72.84127200000006, 29.95778800000005],
            [72.845270097000082, 29.947127325000054],
            [72.845625000000041, 29.946181000000024],
            [72.847413391000032, 29.942961452000077],
            [72.850461000000053, 29.937475000000063],
            [72.852623159000075, 29.934919832000048],
            [72.852758320000078, 29.934760103000031],
            [72.854991731000041, 29.932120732000044],
            [72.855781000000036, 29.931188000000077],
            [72.860134000000073, 29.924417000000062],
            [72.861101000000076, 29.934090000000026],
            [72.861056000000076, 29.934631000000024],
            [72.860134000000073, 29.945697000000052],
            [72.860134000000073, 29.954886000000045],
            [72.863519000000053, 29.960206000000028],
            [72.867872000000034, 29.967945000000043],
            [72.873676000000046, 29.971330000000023],
            [72.880447000000061, 29.97568300100005],
            [72.882865000000038, 29.983421000000078],
            [72.88866900000005, 29.991160000000036],
            [72.893989000000033, 29.998898000000054],
            [72.900759472000061, 30.00131581100004],
            [72.908014000000037, 29.998414000000025],
            [72.917321266000044, 29.993760848000079],
            [72.917687000000058, 29.99357800000007],
            [72.925909000000047, 29.994061000000045],
            [72.927768275000062, 29.991086622000068],
            [72.928870159000041, 29.989323881000075],
            [72.930746000000056, 29.986323000000027],
            [72.934615000000065, 29.979069000000038],
            [72.939451000000076, 29.968428000000074],
            [72.946222000000034, 29.960690000000056],
            [72.952993000000049, 29.960690000000056],
            [72.955347983000081, 29.960690000000056],
            [72.96266600000007, 29.960690000000056],
            [72.968954000000053, 29.965043000000037],
            [72.971721323000054, 29.970182779000027],
            [72.971797000000038, 29.970131000000038],
            [72.980639229000076, 29.964356930000065],
            [72.982293000000084, 29.963277000000062],
            [72.985086185000057, 29.963277000000062],
            [72.995573000000036, 29.963277000000062],
            [73.001602669000079, 29.962711815000034],
            [73.009282000000042, 29.961992000000066],
            [73.011759451000046, 29.963190803000032],
            [73.011764335000066, 29.963193166000053],
            [73.015922000000046, 29.965205000000026],
            [73.015944648000072, 29.965357830000073],
            [73.016906738000046, 29.971130371000072],
            [73.013502993000031, 29.975126071000034],
            [73.012695312000062, 29.976074219000054],
            [73.012709000000029, 29.976658239000074],
            [73.012873346000049, 29.983670305000032],
            [73.012878418000071, 29.983886719000054],
            [73.014282227000081, 29.985900879000042],
            [73.019276787000081, 29.987484520000066],
            [73.019287109000061, 29.987487793000071],
            [73.024108887000068, 29.987304687000062],
            [73.031988394000052, 29.990071538000052],
            [73.03210449200003, 29.990112305000025],
            [73.032287598000039, 29.993103027000075],
            [73.032093694000082, 29.997659780000049],
            [73.031921387000068, 30.001708984000061],
            [73.029724121000072, 30.008911133000026],
            [73.021484375000057, 30.018310547000056],
            [73.019714355000076, 30.023681641000053],
            [73.020874023000033, 30.031677246000072],
            [73.020920999000055, 30.032058579000079],
            [73.021911621000072, 30.040100098000039],
            [73.023071289000029, 30.038696289000029],
            [73.028686523000033, 30.040283203000058],
            [73.039597466000032, 30.040968431000067],
            [73.04132080100004, 30.041076660000044],
            [73.048095703000058, 30.038696289000029],
            [73.054321289000029, 30.035705566000047],
            [73.057922363000046, 30.031311035000044],
            [73.060218553000084, 30.028430360000073],
            [73.061279297000056, 30.027099609000061],
            [73.066974321000032, 30.022846831000038],
            [73.070678711000085, 30.020080566000047],
            [73.074241176000044, 30.017544575000045],
            [73.074277119000044, 30.017450569000061],
            [73.076373000000046, 30.015937000000065],
            [73.083016000000043, 30.015304000000071],
            [73.08460238400005, 30.014799221000033],
            [73.089974000000041, 30.013090000000034],
            [73.091556000000082, 29.999805000000038],
            [73.094768753000039, 30.001009862000046],
            [73.096679688000052, 30.001708984000061],
            [73.096899891000078, 30.001744904000077],
            [73.105175793000058, 30.003094874000055],
            [73.105285645000038, 30.003112793000071],
            [73.106506348000039, 30.011474609000061],
            [73.106418677000079, 30.011485568000069],
            [73.105747674000042, 30.011569443000042],
            [73.103088379000042, 30.011901855000076],
            [73.103037120000067, 30.011816424000074],
            [73.098876953000058, 30.004882813000052],
            [73.098851155000034, 30.004900109000062],
            [73.093505859000061, 30.008483887000068],
            [73.094726563000052, 30.013671875000057],
            [73.094693936000056, 30.013955920000058],
            [73.093688965000069, 30.022705078000058],
            [73.094027035000067, 30.025409639000031],
            [73.094108949000031, 30.026064949000045],
            [73.097750361000067, 30.027008876000025],
            [73.102627000000041, 30.02827300000007],
            [73.108953000000042, 30.031120000000044],
            [73.118442000000073, 30.03365000000008],
            [73.118492992000029, 30.033681496000042],
            [73.118530273000033, 30.033691406000059],
            [73.121846478000066, 30.035752830000035],
            [73.122483840000029, 30.036146508000058],
            [73.122482408000053, 30.036148138000044],
            [73.12530517600004, 30.037902832000043],
            [73.126079555000047, 30.038367460000075],
            [73.129272461000085, 30.040283203000058],
            [73.131796460000032, 30.041737246000025],
            [73.134887695000032, 30.043518066000047],
            [73.139709473000039, 30.046691895000038],
            [73.146301270000038, 30.05108642600004],
            [73.150742082000079, 30.052535202000058],
            [73.151692316000037, 30.052845207000075],
            [73.154892247000078, 30.053889156000025],
            [73.154907227000081, 30.053894043000071],
            [73.156797272000063, 30.056136897000044],
            [73.159484863000046, 30.059326172000056],
            [73.163048360000062, 30.062611271000037],
            [73.167289068000059, 30.06652067300007],
            [73.167297363000046, 30.066528320000032],
            [73.169465397000067, 30.066158168000072],
            [73.169512771000029, 30.066150080000057],
            [73.169527011000071, 30.066101031000073],
            [73.172215000000051, 30.065597000000025],
            [73.176011000000074, 30.058006000000034],
            [73.176756983000075, 30.056663090000029],
            [73.179174000000046, 30.052312000000029],
            [73.182337886000084, 30.050378545000058],
            [73.184867000000054, 30.048833000000059],
            [73.189061201000072, 30.050157558000024],
            [73.190917969000054, 30.050720215000069],
            [73.192035764000082, 30.051773522000076],
            [73.19204703500003, 30.051784143000077],
            [73.19403154500003, 30.053654162000043],
            [73.194091797000056, 30.053710938000052],
            [73.193481445000032, 30.059326172000056],
            [73.194699148000041, 30.068458946000078],
            [73.199046856000052, 30.070944052000073],
            [73.199101000000041, 30.070975000000033],
            [73.202601301000072, 30.069419106000055],
            [73.204795000000047, 30.068444000000056],
            [73.210489000000052, 30.067179000000067],
            [73.211867310000059, 30.066542806000029],
            [73.214601000000073, 30.065281000000027],
            [73.219345000000033, 30.062434000000053],
            [73.219456650000041, 30.062515199000075],
            [73.219482422000056, 30.062500000000057],
            [73.226318359000061, 30.067504883000026],
            [73.226654844000052, 30.068548557000042],
            [73.229919434000067, 30.078674316000047],
            [73.232044307000081, 30.083087514000056],
            [73.233886719000054, 30.086914063000052],
            [73.237121582000043, 30.088073730000076],
            [73.240285612000037, 30.089247483000065],
            [73.240905762000068, 30.089477539000029],
            [73.24097905800005, 30.089514187000077],
            [73.242120000000057, 30.089953000000037],
            [73.24633134000004, 30.089279250000061],
            [73.250027000000046, 30.088688000000047],
            [73.250058434000039, 30.088695255000061],
            [73.250122070000032, 30.088684082000043],
            [73.250962996000055, 30.088904017000061],
            [73.254139000000066, 30.089637000000039],
            [73.25888400000008, 30.094382000000053],
            [73.262561478000066, 30.10142985300007],
            [73.262681285000042, 30.101657874000068],
            [73.270588000000032, 30.107034000000056],
            [73.270613308000065, 30.107045073000052],
            [73.275648000000047, 30.109248000000036],
            [73.275679575000083, 30.109248000000036],
            [73.281026000000054, 30.109248000000036],
            [73.281734125000071, 30.108539875000076],
            [73.286012000000085, 30.104262000000062],
            [73.286087000000066, 30.104187000000024],
            [73.289566000000036, 30.100392000000056],
            [73.29425374300007, 30.100587117000032],
            [73.296962007000047, 30.10069984200004],
            [73.297302246000072, 30.100708008000026],
            [73.298455018000084, 30.100996201000044],
            [73.302851000000032, 30.101973000000044],
            [73.302916666000044, 30.102111613000034],
            [73.302917480000076, 30.102111816000047],
            [73.302994885000032, 30.102276723000045],
            [73.304619000000059, 30.105705000000057],
            [73.304619331000083, 30.105705699000055],
            [73.304606905000071, 30.105711025000062],
            [73.305725098000039, 30.108093262000068],
            [73.309426138000049, 30.111478360000035],
            [73.310729980000076, 30.112670898000033],
            [73.321899414000029, 30.110473633000026],
            [73.322115232000044, 30.110404822000078],
            [73.326110840000069, 30.109130859000061],
            [73.331115723000039, 30.107116699000073],
            [73.341794392000054, 30.104820785000072],
            [73.343322754000042, 30.104492187000062],
            [73.344844530000046, 30.103912463000029],
            [73.345787666000035, 30.103553173000023],
            [73.352294922000056, 30.101074219000054],
            [73.363708496000072, 30.096923828000058],
            [73.365966636000053, 30.094629266000027],
            [73.36749267600004, 30.093078613000046],
            [73.370483398000033, 30.089721680000025],
            [73.370489263000081, 30.089692132000039],
            [73.370534950000035, 30.089461941000025],
            [73.370576644000039, 30.089463715000079],
            [73.372123000000045, 30.081729000000053],
            [73.372376338000038, 30.080652590000057],
            [73.372337757000082, 30.080643855000062],
            [73.373190816000033, 30.07719194200007],
            [73.374654000000078, 30.070975000000033],
            [73.379715000000033, 30.068760000000054],
            [73.381296000000077, 30.07445400000006],
            [73.382732617000045, 30.075800917000038],
            [73.385239457000068, 30.078151234000075],
            [73.386357000000032, 30.079199000000074],
            [73.386568586000067, 30.083632289000036],
            [73.386636126000042, 30.083645607000051],
            [73.386718750000057, 30.085876465000069],
            [73.386558394000076, 30.086205196000037],
            [73.384277344000054, 30.090881348000039],
            [73.38356428000003, 30.093221089000053],
            [73.382324219000054, 30.097290039000029],
            [73.381424827000046, 30.100781794000056],
            [73.381309442000031, 30.101281756000049],
            [73.381296000000077, 30.10134000000005],
            [73.381272652000064, 30.101760700000057],
            [73.381185154000036, 30.103337335000049],
            [73.381222446000038, 30.10335057900005],
            [73.381103516000053, 30.107116699000073],
            [73.378604832000065, 30.113892791000069],
            [73.377502441000047, 30.116882324000073],
            [73.377367568000068, 30.12335626600003],
            [73.377319336000085, 30.125671387000068],
            [73.37780964500007, 30.132120843000052],
            [73.377997730000061, 30.134594884000023],
            [73.378021941000043, 30.134913345000029],
            [73.378133000000048, 30.136135000000024],
            [73.378255732000071, 30.137280323000027],
            [73.379035778000059, 30.144559662000063],
            [73.379082000000039, 30.144991000000061],
            [73.379402283000047, 30.146043422000048],
            [73.379882813000052, 30.147521973000039],
            [73.380835632000071, 30.150753273000078],
            [73.381286621000072, 30.152282715000069],
            [73.388305664000029, 30.152709961000028],
            [73.389557456000034, 30.152709961000028],
            [73.400329590000069, 30.152709961000028],
            [73.402709961000085, 30.152099609000061],
            [73.406921387000068, 30.150085449000073],
            [73.414145442000063, 30.147339114000033],
            [73.413951927000085, 30.147880760000078],
            [73.414105960000029, 30.147826864000024],
            [73.411071777000075, 30.156127930000025],
            [73.412109375000057, 30.163330078000058],
            [73.412109375000057, 30.164090927000075],
            [73.412109375000057, 30.167466526000055],
            [73.412109375000057, 30.167480469000054],
            [73.416687012000068, 30.168090820000032],
            [73.42272949200003, 30.168518066000047],
            [73.423278809000067, 30.169494629000042],
            [73.424926758000083, 30.171875000000057],
            [73.424941476000072, 30.171869317000073],
            [73.42525609900008, 30.171747829000026],
            [73.427110735000042, 30.171031682000034],
            [73.431091309000067, 30.169494629000042],
            [73.436706543000071, 30.165527344000054],
            [73.442077637000068, 30.162719727000024],
            [73.447082520000038, 30.15710449200003],
            [73.45182034700008, 30.157236099000045],
            [73.453674316000047, 30.157287598000039],
            [73.454759945000035, 30.157690134000063],
            [73.459061719000033, 30.159285174000047],
            [73.459106445000032, 30.159301758000026],
            [73.455688477000081, 30.163696289000029],
            [73.455322266000053, 30.168701172000056],
            [73.455267802000037, 30.169899384000075],
            [73.455078125000057, 30.174072266000053],
            [73.455078125000057, 30.178210234000062],
            [73.455078125000057, 30.178283691000047],
            [73.459106445000032, 30.178527832000043],
            [73.461006654000073, 30.178527832000043],
            [73.461120605000076, 30.178527832000043],
            [73.461157080000078, 30.179281630000048],
            [73.461277596000059, 30.181772296000076],
            [73.46132300000005, 30.182316000000071],
            [73.461278017000041, 30.18334834500007],
            [73.461120605000076, 30.189697266000053],
            [73.459757165000042, 30.192218345000072],
            [73.45788574200003, 30.195678711000028],
            [73.453491211000085, 30.20007324200003],
            [73.453491211000085, 30.20330152400004],
            [73.453491211000085, 30.20646269100007],
            [73.453955502000042, 30.207112668000036],
            [73.456726074000073, 30.210876465000069],
            [73.459472656000059, 30.214904785000044],
            [73.461486816000047, 30.220703125000057],
            [73.462097168000071, 30.222473145000038],
            [73.463591938000036, 30.22437035400003],
            [73.463684082000043, 30.224487305000025],
            [73.46761125200004, 30.223298819000036],
            [73.468322754000042, 30.223083496000072],
            [73.468773458000044, 30.222956123000074],
            [73.471128000000078, 30.22217100000006],
            [73.473975000000053, 30.219641000000024],
            [73.475873000000036, 30.214263000000074],
            [73.478404000000069, 30.20825300000007],
            [73.479542863000063, 30.206686950000062],
            [73.480934000000047, 30.204774000000043],
            [73.487260000000049, 30.206039000000033],
            [73.487256052000077, 30.206106187000046],
            [73.487304688000052, 30.206115723000039],
            [73.487116435000075, 30.208482321000076],
            [73.486877441000047, 30.211486816000047],
            [73.487137842000038, 30.214909220000038],
            [73.487304688000052, 30.21710205100004],
            [73.487801025000067, 30.217146030000038],
            [73.488926990000039, 30.217221015000064],
            [73.491950634000034, 30.217422379000027],
            [73.492005000000063, 30.217426000000046],
            [73.496433000000081, 30.217743000000041],
            [73.500077973000032, 30.220021018000068],
            [73.501525879000042, 30.220886230000076],
            [73.50380202100007, 30.227118526000027],
            [73.503802535000034, 30.227253332000032],
            [73.50402500000007, 30.227865000000065],
            [73.510311556000033, 30.235294676000024],
            [73.510314941000047, 30.235290527000075],
            [73.511555306000048, 30.23676458400007],
            [73.514463000000035, 30.24020100000007],
            [73.517222694000054, 30.242536091000034],
            [73.519213611000055, 30.244220688000041],
            [73.526916504000042, 30.250671387000068],
            [73.532470703000058, 30.258483887000068],
            [73.540100098000039, 30.262329102000024],
            [73.543536700000061, 30.26243377000003],
            [73.544540719000054, 30.262464349000027],
            [73.552124023000033, 30.262695313000052],
            [73.553635786000029, 30.262002421000034],
            [73.556518555000082, 30.260681152000075],
            [73.558465570000067, 30.259471953000059],
            [73.562226000000067, 30.256965000000037],
            [73.563438495000071, 30.256041116000063],
            [73.568868000000066, 30.251904000000025],
            [73.56887133400005, 30.251921492000065],
            [73.568908691000047, 30.251892090000069],
            [73.570129395000038, 30.258483887000068],
            [73.571552175000079, 30.26144559200003],
            [73.573073943000054, 30.26461335700003],
            [73.58043919000005, 30.269031759000029],
            [73.580871582000043, 30.269287109000061],
            [73.581572221000044, 30.269472078000035],
            [73.582817175000059, 30.269783358000041],
            [73.588479000000063, 30.271199000000024],
            [73.595438000000058, 30.26835200000005],
            [73.598193140000035, 30.265597441000068],
            [73.600183000000072, 30.263608000000033],
            [73.603346000000045, 30.264557000000025],
            [73.606999104000067, 30.268490839000037],
            [73.60698181600003, 30.268581123000047],
            [73.607028788000036, 30.268630137000059],
            [73.607482910000044, 30.269104004000042],
            [73.609269728000072, 30.270947546000059],
            [73.611328125000057, 30.273071289000029],
            [73.612251067000045, 30.273500376000072],
            [73.617043787000057, 30.275728570000069],
            [73.618213000000082, 30.276260000000036],
            [73.620626651000066, 30.276712589000056],
            [73.628479004000042, 30.278076172000056],
            [73.629375405000076, 30.277926772000058],
            [73.630580773000077, 30.277725877000023],
            [73.630583000000058, 30.277659000000028],
            [73.636875000000032, 30.276260000000036],
            [73.638140000000078, 30.270567000000028],
            [73.637508000000082, 30.26708700000006],
            [73.63686612500004, 30.264092034000043],
            [73.636559000000034, 30.262659000000042],
            [73.635610000000042, 30.258547000000078],
            [73.636800467000057, 30.254777398000044],
            [73.637508000000082, 30.252537000000075],
            [73.639415052000061, 30.253490337000073],
            [73.642569000000037, 30.255067000000054],
            [73.649211000000037, 30.262026000000048],
            [73.65095675200007, 30.264994275000049],
            [73.652374000000066, 30.267404000000056],
            [73.655421878000084, 30.269435704000045],
            [73.65711900000008, 30.270567000000028],
            [73.660042402000045, 30.273644111000067],
            [73.663129000000083, 30.27689300000003],
            [73.672934358000077, 30.277841938000051],
            [73.672935000000052, 30.277842000000078],
            [73.680210000000045, 30.276577000000032],
            [73.680825402000039, 30.274628336000035],
            [73.68082482300008, 30.27462700500007],
            [73.68210700000003, 30.270567000000028],
            [73.685275656000044, 30.268889580000064],
            [73.687485000000038, 30.267720000000054],
            [73.69410034100008, 30.268381604000069],
            [73.696974000000068, 30.268669000000045],
            [73.708994000000075, 30.271199000000024],
            [73.71958483700007, 30.27241009200003],
            [73.720065000000034, 30.272465000000068],
            [73.729238000000066, 30.26708700000006],
            [73.736262760000045, 30.266243993000046],
            [73.73714534700008, 30.26613807800004],
            [73.737146000000052, 30.266138000000069],
            [73.738150093000058, 30.266364727000052],
            [73.746951000000081, 30.26835200000005],
            [73.754543000000069, 30.274046000000055],
            [73.759488946000033, 30.277755850000062],
            [73.760869000000071, 30.278791000000069],
            [73.769093000000055, 30.279423000000065],
            [73.770630119000032, 30.284547001000078],
            [73.770991000000038, 30.285750000000064],
            [73.776684000000046, 30.290178000000026],
            [73.78016400000007, 30.296820000000025],
            [73.77859649100003, 30.300999749000027],
            [73.776368000000048, 30.306942000000049],
            [73.777001000000041, 30.313269000000048],
            [73.78490800000003, 30.317381000000069],
            [73.792816000000073, 30.32180900000003],
            [73.797257042000069, 30.322631589000025],
            [73.801357000000053, 30.323391000000072],
            [73.80831500000005, 30.32370700000007],
            [73.82223300000004, 30.319595000000049],
            [73.823320503000048, 30.319304979000037],
            [73.830572130000064, 30.317371076000029],
            [73.830826063000075, 30.317303356000025],
            [73.834714527000074, 30.316266359000053],
            [73.836467000000084, 30.315799000000027],
            [73.842477000000031, 30.311371000000065],
            [73.842718937000029, 30.311162427000056],
            [73.848697955000034, 30.306007944000044],
            [73.851650000000063, 30.303463000000079],
            [73.85449600000004, 30.298086000000069],
            [73.854496712000071, 30.298086544000057],
            [73.854497000000038, 30.298086000000069],
            [73.859874000000048, 30.302198000000033],
            [73.86367000000007, 30.308524000000034],
            [73.869680000000074, 30.313585000000046],
            [73.877271000000064, 30.317064000000073],
            [73.885165751000045, 30.322327419000032],
            [73.885612789000049, 30.322625459000051],
            [73.88570575500006, 30.322687440000038],
            [73.887709000000029, 30.324023000000068],
            [73.896250000000066, 30.329717000000073],
            [73.904268669000032, 30.33660373400005],
            [73.909455791000084, 30.341058629000031],
            [73.913509000000033, 30.336594000000048],
            [73.931087000000048, 30.318936000000065],
            [73.93451900000008, 30.311909000000071],
            [73.934953087000054, 30.310836811000058],
            [73.936565622000046, 30.306853868000076],
            [73.936968000000036, 30.305860000000052],
            [73.941295000000082, 30.298310000000072],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "92",
      properties: { name: "Bahawalpur", count: 71 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.426306000000068, 29.839779000000078],
            [72.434045000000083, 29.838812000000075],
            [72.440816000000041, 29.839779000000078],
            [72.44203617900007, 29.841221196000049],
            [72.446136000000081, 29.846067000000062],
            [72.449586340000053, 29.84666186100003],
            [72.456330131000072, 29.84782453400004],
            [72.458941980000077, 29.848274833000062],
            [72.460161000000085, 29.848485000000039],
            [72.462981407000029, 29.844254389000071],
            [72.460161000000085, 29.835323748000064],
            [72.460193629000059, 29.835046318000025],
            [72.460161000000085, 29.834943000000067],
            [72.461129000000028, 29.826721000000077],
            [72.470318000000077, 29.821401000000037],
            [72.476122000000032, 29.825754000000074],
            [72.479676922000067, 29.827531767000039],
            [72.481925000000047, 29.828656000000024],
            [72.48724500000003, 29.830590000000029],
            [72.48787261700005, 29.830590000000029],
            [72.489551973000061, 29.830590000000029],
            [72.49217410600005, 29.830590000000029],
            [72.49256500000007, 29.830590000000029],
            [72.493049000000042, 29.823819000000071],
            [72.496918000000051, 29.817532000000028],
            [72.502457691000075, 29.817532000000028],
            [72.501650778000055, 29.823585930000036],
            [72.502617778000058, 29.831323930000053],
            [72.503651980000029, 29.832228757000053],
            [72.506486778000067, 29.834708930000033],
            [72.506908740000028, 29.835341960000051],
            [72.507558000000074, 29.835910000000069],
            [72.511539940000034, 29.841882498000075],
            [72.512395000000083, 29.843165000000056],
            [72.520133000000044, 29.84026300000005],
            [72.524486000000081, 29.835910000000069],
            [72.524662178000028, 29.835777878000044],
            [72.531548602000044, 29.830613505000031],
            [72.532224000000042, 29.830107000000055],
            [72.536577000000079, 29.823819000000071],
            [72.539143717000059, 29.819256343000063],
            [72.54093000000006, 29.816081000000054],
            [72.541926658000079, 29.812342247000061],
            [72.542864000000066, 29.808826000000067],
            [72.563445969000043, 29.80834799400003],
            [72.563446000000056, 29.80834799400003],
            [72.565216000000078, 29.796840000000032],
            [72.562336000000073, 29.785316000000023],
            [72.558494000000053, 29.777153000000055],
            [72.558721731000048, 29.775923385000056],
            [72.558723549000035, 29.775913570000057],
            [72.559598700000038, 29.771188265000035],
            [72.560514993000083, 29.76624081500006],
            [72.560528658000067, 29.766167033000045],
            [72.560895000000073, 29.764189000000044],
            [72.563776000000075, 29.75842700000004],
            [72.56953800000008, 29.752185000000054],
            [72.568578000000059, 29.743062000000066],
            [72.568606268000053, 29.742722658000048],
            [72.568606598000031, 29.742718702000047],
            [72.569058000000041, 29.737300000000062],
            [72.577221000000065, 29.734419000000059],
            [72.581062000000031, 29.732018000000039],
            [72.578661000000068, 29.724335000000053],
            [72.578181000000029, 29.716652000000067],
            [72.586299460000077, 29.712105824000048],
            [72.590185000000076, 29.709930000000043],
            [72.599729300000035, 29.705927614000075],
            [72.605070000000069, 29.703688000000056],
            [72.611312000000055, 29.696966000000032],
            [72.617395246000058, 29.694022355000072],
            [72.626197500000046, 29.689763000000028],
            [72.632803573000047, 29.687639839000042],
            [72.639642000000038, 29.685442000000023],
            [72.646845000000042, 29.679680000000076],
            [72.646629679000057, 29.674306373000036],
            [72.646629227000062, 29.674295105000056],
            [72.646364000000062, 29.667676000000029],
            [72.646311357000059, 29.666938727000058],
            [72.645404000000042, 29.654231000000038],
            [72.645404000000042, 29.648469000000034],
            [72.653567000000066, 29.649910000000034],
            [72.655577644000061, 29.651082818000077],
            [72.659329000000071, 29.653271000000075],
            [72.663650000000075, 29.654711000000077],
            [72.663650000000075, 29.647393718000046],
            [72.663650000000075, 29.645720003000065],
            [72.663650000000075, 29.64510800000005],
            [72.664802462000068, 29.642803343000026],
            [72.664864571000066, 29.642679140000041],
            [72.667972000000077, 29.636465000000044],
            [72.672293000000082, 29.642227000000048],
            [72.673734000000081, 29.645588000000032],
            [72.676615000000083, 29.643668000000048],
            [72.682377000000031, 29.64270700000003],
            [72.683817000000033, 29.637906000000044],
            [72.683868347000043, 29.637741692000077],
            [72.683869538000067, 29.637737882000067],
            [72.686218000000054, 29.630223000000058],
            [72.67949550000003, 29.628782000000058],
            [72.676134500000046, 29.625421000000074],
            [72.676134500000046, 29.621100000000069],
            [72.676614500000085, 29.617739000000029],
            [72.676762330000031, 29.616556512000045],
            [72.677575000000047, 29.610056000000043],
            [72.677454950000083, 29.609696017000033],
            [72.677454990000058, 29.609696009000061],
            [72.677454581000063, 29.609694783000066],
            [72.677454541000031, 29.609694791000038],
            [72.676134000000047, 29.605735000000038],
            [72.671333000000061, 29.605254000000059],
            [72.669412000000079, 29.601413000000036],
            [72.674214000000063, 29.599973000000034],
            [72.673734000000081, 29.593731000000048],
            [72.680936000000031, 29.591810000000066],
            [72.690539500000057, 29.589889000000028],
            [72.69582100000008, 29.593731000000048],
            [72.700882781000075, 29.58958919500003],
            [72.701103000000046, 29.589409000000046],
            [72.704944000000069, 29.582687000000078],
            [72.707825000000071, 29.579326000000037],
            [72.715186650000078, 29.578624439000066],
            [72.715210131000049, 29.57862220100003],
            [72.717909000000077, 29.578365000000076],
            [72.716949000000056, 29.574044000000072],
            [72.717284465000034, 29.569345393000049],
            [72.717429000000038, 29.567321000000049],
            [72.719349000000079, 29.562040000000025],
            [72.717909000000077, 29.558679000000041],
            [72.714403585000071, 29.549478427000054],
            [72.714067000000057, 29.548595000000034],
            [72.707825000000071, 29.548595000000034],
            [72.703504000000066, 29.546674000000053],
            [72.705424500000049, 29.541393000000028],
            [72.713107000000036, 29.542353000000048],
            [72.716317963000051, 29.538575604000073],
            [72.716319002000034, 29.538574382000036],
            [72.721270000000061, 29.532750000000078],
            [72.727992000000029, 29.529389000000037],
            [72.728709718000061, 29.523647253000036],
            [72.72943250000003, 29.517865000000029],
            [72.730399184000078, 29.510775762000037],
            [72.730873000000031, 29.507301000000041],
            [72.730873000000031, 29.506412899000054],
            [72.730873000000031, 29.506412190000049],
            [72.730873000000031, 29.498078748000069],
            [72.730873000000031, 29.497698000000071],
            [72.730873000000031, 29.488955748000024],
            [72.730873000000031, 29.488575000000026],
            [72.730873000000031, 29.479351748000056],
            [72.730873000000031, 29.478971000000058],
            [72.730681631000039, 29.475142419000065],
            [72.730393000000049, 29.469368000000031],
            [72.729749286000072, 29.468563232000065],
            [72.729740697000068, 29.468552494000051],
            [72.726552000000083, 29.464566000000048],
            [72.719829290000064, 29.462166103000072],
            [72.719829000000061, 29.462166000000025],
            [72.718123786000035, 29.46159751700003],
            [72.712627000000055, 29.459765000000061],
            [72.706385000000068, 29.458804000000043],
            [72.703984000000048, 29.451602000000037],
            [72.703984000000048, 29.449850501000071],
            [72.703984000000048, 29.449850382000079],
            [72.703984000000048, 29.445260748000067],
            [72.703984000000048, 29.444880000000069],
            [72.703984000000048, 29.438638000000026],
            [72.691980000000058, 29.436717000000044],
            [72.690305881000029, 29.435980354000037],
            [72.69030299700006, 29.435979085000042],
            [72.68596720000005, 29.434071248000066],
            [72.679976000000067, 29.431435000000079],
            [72.669412000000079, 29.423272000000054],
            [72.667854352000063, 29.422912533000044],
            [72.661087271000042, 29.42135085700005],
            [72.65692800000005, 29.420391000000052],
            [72.649778367000067, 29.416419547000032],
            [72.648672010000041, 29.415804982000054],
            [72.648285000000044, 29.415590000000066],
            [72.643963000000042, 29.411748000000046],
            [72.641083000000037, 29.411748000000046],
            [72.64108200000004, 29.411748000000046],
            [72.638201000000038, 29.405506000000059],
            [72.638201000000038, 29.395903000000033],
            [72.637241000000074, 29.38101800000004],
            [72.635801000000072, 29.374776000000054],
            [72.631960000000049, 29.367093000000068],
            [72.632863081000039, 29.363705507000077],
            [72.632863091000047, 29.363705468000035],
            [72.632873535000044, 29.363708496000072],
            [72.633880000000033, 29.359891000000061],
            [72.639642000000038, 29.350287000000037],
            [72.642043000000058, 29.343565000000069],
            [72.651512129000082, 29.343565000000069],
            [72.651513641000065, 29.343565000000069],
            [72.651646000000028, 29.343565000000069],
            [72.658849000000032, 29.336843000000044],
            [72.663650000000075, 29.33108100000004],
            [72.667011000000059, 29.326279000000056],
            [72.672773000000063, 29.324839000000054],
            [72.679495000000031, 29.328200000000038],
            [72.689099000000056, 29.33348200000006],
            [72.693282741000075, 29.338130493000051],
            [72.69342000000006, 29.338283000000047],
            [72.69582100000008, 29.344045000000051],
            [72.704944000000069, 29.341164000000049],
            [72.707825000000071, 29.333001000000024],
            [72.714067000000057, 29.321958000000052],
            [72.713107000000036, 29.309954000000062],
            [72.716468000000077, 29.299870000000055],
            [72.723190000000045, 29.303711000000078],
            [72.729433000000029, 29.310434000000043],
            [72.737115000000074, 29.311874000000046],
            [72.741437000000076, 29.31523500000003],
            [72.743068058000063, 29.313487188000067],
            [72.743068000000051, 29.313487000000066],
            [72.748159000000044, 29.308033000000023],
            [72.753921000000048, 29.307073000000059],
            [72.761123000000055, 29.310914000000025],
            [72.771207000000061, 29.31907700000005],
            [72.777929000000029, 29.320997000000034],
            [72.772167000000081, 29.312835000000064],
            [72.768806000000041, 29.304192000000057],
            [72.769286000000079, 29.300831000000073],
            [72.772755000000075, 29.301574000000073],
            [72.782731000000069, 29.303711000000078],
            [72.782041149000065, 29.303159814000026],
            [72.773128000000042, 29.296029000000033],
            [72.773127000000045, 29.296029000000033],
            [72.771069993000083, 29.296029000000033],
            [72.763044000000036, 29.296029000000033],
            [72.755362000000048, 29.290747000000067],
            [72.75536100000005, 29.290747000000067],
            [72.749600000000044, 29.290747000000067],
            [72.739516000000037, 29.293628000000069],
            [72.735999857000081, 29.293962771000054],
            [72.73384167100005, 29.294168251000031],
            [72.729433000000029, 29.294588000000033],
            [72.717909000000077, 29.295069000000069],
            [72.703984000000048, 29.291707000000031],
            [72.693457383000066, 29.284447603000046],
            [72.690059000000076, 29.282104000000061],
            [72.687658000000056, 29.274902000000054],
            [72.689019235000046, 29.268912791000048],
            [72.689924912000038, 29.264927964000037],
            [72.689924938000047, 29.264927971000077],
            [72.690059000000076, 29.264338000000066],
            [72.69246000000004, 29.258096000000023],
            [72.683337000000051, 29.254735000000039],
            [72.679495000000031, 29.249933000000055],
            [72.689099000000056, 29.249453000000074],
            [72.69582100000008, 29.252814000000058],
            [72.70446400000003, 29.256655000000023],
            [72.707345000000032, 29.25569500000006],
            [72.706385000000068, 29.247052000000053],
            [72.712627000000055, 29.243691000000069],
            [72.715988000000038, 29.248012000000074],
            [72.716717853000034, 29.248255369000049],
            [72.72031000000004, 29.249453000000074],
            [72.723671000000081, 29.251374000000055],
            [72.729913000000067, 29.252814000000058],
            [72.732794000000069, 29.244171000000051],
            [72.736155000000053, 29.238409000000047],
            [72.737596000000053, 29.229286000000059],
            [72.74575800000008, 29.229286000000059],
            [72.753765789000056, 29.22950234800004],
            [72.757421841000053, 29.229601124000055],
            [72.763524007000058, 29.229765973000042],
            [72.764965000000075, 29.224484000000075],
            [72.763670277000074, 29.223318965000033],
            [72.760163000000034, 29.22016300000007],
            [72.757429811000065, 29.220553339000048],
            [72.750080000000082, 29.221603000000073],
            [72.744318000000078, 29.221603000000073],
            [72.740477000000055, 29.222084000000052],
            [72.740476848000071, 29.222083894000036],
            [72.735675000000072, 29.218722000000071],
            [72.730393000000049, 29.217282000000068],
            [72.722715394000033, 29.215291531000048],
            [72.717429000000038, 29.213921000000028],
            [72.717428577000078, 29.213921149000043],
            [72.709266000000071, 29.21680200000003],
            [72.701103000000046, 29.218242000000032],
            [72.69582100000008, 29.212480000000028],
            [72.693413068000041, 29.212294834000033],
            [72.689579000000037, 29.212000000000046],
            [72.684297000000072, 29.208159000000023],
            [72.677575000000047, 29.210080000000062],
            [72.676135000000045, 29.214401000000066],
            [72.676134000000047, 29.214401000000066],
            [72.670853000000079, 29.214401000000066],
            [72.664131000000054, 29.214401000000066],
            [72.664130000000057, 29.214401000000066],
            [72.654047000000048, 29.213921000000028],
            [72.649536435000073, 29.215097651000065],
            [72.643003000000078, 29.21680200000003],
            [72.633880000000033, 29.222084000000052],
            [72.628118000000029, 29.227365000000077],
            [72.615634000000057, 29.236969000000045],
            [72.607471000000032, 29.243211000000031],
            [72.603977469000029, 29.244729693000068],
            [72.596427000000062, 29.248012000000074],
            [72.585864000000072, 29.248012000000074],
            [72.569058000000041, 29.246092000000033],
            [72.558014000000071, 29.245612000000051],
            [72.548411000000044, 29.244171000000051],
            [72.544570000000078, 29.241290000000049],
            [72.544569053000032, 29.241290039000035],
            [72.53304600000007, 29.241770000000031],
            [72.528244000000029, 29.245131000000072],
            [72.518641000000059, 29.244171000000051],
            [72.507117000000051, 29.241290000000049],
            [72.508557000000053, 29.229286000000059],
            [72.496073000000081, 29.232167000000061],
            [72.489351000000056, 29.231687000000079],
            [72.484302276000051, 29.22985084000004],
            [72.484069000000034, 29.229766000000041],
            [72.482148000000052, 29.21920300000005],
            [72.477347000000066, 29.209599000000026],
            [72.474192426000059, 29.209381521000068],
            [72.463422000000037, 29.208639000000062],
            [72.45434244900008, 29.206045036000035],
            [72.449978000000044, 29.204798000000039],
            [72.449977000000047, 29.204798000000039],
            [72.437973000000056, 29.204798000000039],
            [72.423636115000079, 29.205175162000046],
            [72.41972700000008, 29.205278000000078],
            [72.404842000000031, 29.207679000000041],
            [72.398206618000074, 29.207413673000076],
            [72.39283800000004, 29.20719900000006],
            [72.383753459000047, 29.20615039200004],
            [72.380354000000068, 29.20575800000006],
            [72.370750000000044, 29.199996000000056],
            [72.368055840000068, 29.200988554000048],
            [72.361627000000055, 29.20335700000004],
            [72.353996761000076, 29.208080589000076],
            [72.351544000000047, 29.209599000000026],
            [72.343189490000043, 29.221202325000036],
            [72.34290100000004, 29.221603000000073],
            [72.34290100000004, 29.224645898000063],
            [72.34290100000004, 29.231687000000079],
            [72.337684668000065, 29.234157654000057],
            [72.333778000000052, 29.236008000000027],
            [72.328976000000068, 29.235048000000063],
            [72.32452061400005, 29.234553129000062],
            [72.320333000000062, 29.234088000000042],
            [72.313611000000037, 29.226405000000057],
            [72.302567000000067, 29.222564000000034],
            [72.297595908000062, 29.223806902000035],
            [72.29296400000004, 29.224965000000054],
            [72.282400000000052, 29.224004000000036],
            [72.275678000000084, 29.224004000000036],
            [72.273277000000064, 29.21776200000005],
            [72.276158000000066, 29.210560000000044],
            [72.278079000000048, 29.204318000000058],
            [72.268475500000079, 29.205278000000078],
            [72.268475464000062, 29.205277953000063],
            [72.263674000000037, 29.199036000000035],
            [72.26895600000006, 29.193754000000069],
            [72.276638000000048, 29.188952000000029],
            [72.27629728900007, 29.182817064000062],
            [72.276158000000066, 29.180309000000079],
            [72.273914655000056, 29.17836468400003],
            [72.26895600000006, 29.174067000000036],
            [72.268557134000048, 29.173628264000058],
            [72.264154000000076, 29.168785000000071],
            [72.261753000000056, 29.161103000000026],
            [72.267995000000042, 29.15582100000006],
            [72.270329033000053, 29.153098096000065],
            [72.273757000000046, 29.149099000000035],
            [72.275678000000084, 29.144297000000051],
            [72.273629665000044, 29.14032856700004],
            [72.26946677400008, 29.13226340600005],
            [72.267995000000042, 29.129412000000059],
            [72.273277000000064, 29.123650000000055],
            [72.27951900000005, 29.123170000000073],
            [72.280479000000071, 29.11692800000003],
            [72.277598000000069, 29.107324000000062],
            [72.275198000000046, 29.099162000000035],
            [72.276638000000048, 29.095320000000072],
            [72.286722000000054, 29.096281000000033],
            [72.289158096000051, 29.096281000000033],
            [72.291043000000059, 29.096281000000033],
            [72.295365000000061, 29.09388000000007],
            [72.295699846000048, 29.091535208000039],
            [72.297079328000052, 29.081875238000066],
            [72.297285000000045, 29.08043500000008],
            [72.297599269000045, 29.071955957000057],
            [72.297766000000081, 29.067471000000069],
            [72.303047000000049, 29.060268000000065],
            [72.309289000000035, 29.060749000000044],
            [72.312650500000075, 29.066510000000051],
            [72.31745200000006, 29.076114000000075],
            [72.318413000000078, 29.08043500000008],
            [72.322879232000048, 29.08043500000008],
            [72.326095000000066, 29.08043500000008],
            [72.331377000000032, 29.079475000000059],
            [72.335218000000054, 29.085237000000063],
            [72.334738000000073, 29.095800000000054],
            [72.331377000000032, 29.107324000000062],
            [72.335218000000054, 29.108765000000062],
            [72.337498704000041, 29.106484296000076],
            [72.337859378000076, 29.106123622000041],
            [72.343381000000079, 29.100602000000038],
            [72.351544000000047, 29.09388000000007],
            [72.361627000000055, 29.087638000000027],
            [72.369310000000041, 29.080915000000061],
            [72.382274000000052, 29.063629500000047],
            [72.385703745000058, 29.057456245000026],
            [72.391877500000078, 29.046344000000033],
            [72.404362000000049, 29.030018000000041],
            [72.414925500000038, 29.011292000000026],
            [72.425009000000045, 28.991125000000068],
            [72.428370000000029, 28.978160000000059],
            [72.431251000000032, 28.965196000000049],
            [72.433652000000052, 28.945029000000034],
            [72.436053000000072, 28.929664000000059],
            [72.447096500000043, 28.909017000000063],
            [72.453819000000067, 28.889810000000068],
            [72.461021000000073, 28.873004000000037],
            [72.468223500000079, 28.857639000000063],
            [72.471593664000068, 28.851546817000042],
            [72.48070800000005, 28.835071000000028],
            [72.491847791000055, 28.820237079000037],
            [72.492158000000074, 28.81982400000004],
            [72.460773000000074, 28.803733000000079],
            [72.423759000000075, 28.794669000000056],
            [72.39278900000005, 28.779562000000055],
            [72.365595000000042, 28.743304000000023],
            [72.315740000000062, 28.692694000000074],
            [72.297863000000063, 28.664661000000024],
            [72.283090000000072, 28.610946000000069],
            [72.261604000000034, 28.561259000000064],
            [72.252203000000065, 28.527687000000071],
            [72.240117000000055, 28.487400000000036],
            [72.218983000000037, 28.445519000000047],
            [72.209493000000066, 28.412363000000028],
            [72.198486000000059, 28.383998000000076],
            [72.17699900000008, 28.35579800000005],
            [72.155513000000042, 28.334312000000068],
            [72.131341000000077, 28.31282600000003],
            [72.09776800000003, 28.288654000000065],
            [72.073596000000066, 28.271197000000029],
            [72.056138000000033, 28.255082000000073],
            [72.021223000000077, 28.236282000000074],
            [72.005108000000064, 28.216139000000055],
            [71.983622000000082, 28.200024000000042],
            [71.962135000000046, 28.169138000000032],
            [71.925373000000036, 28.123984000000064],
            [71.918323000000044, 28.101826000000074],
            [71.916308000000072, 28.078661000000068],
            [71.912279000000069, 28.052475000000072],
            [71.902582000000052, 28.025584000000038],
            [71.898649000000034, 28.00077600000003],
            [71.901200000000074, 27.98398700000007],
            [71.897302000000082, 27.966756000000032],
            [71.87661200000008, 27.960109000000045],
            [71.857085000000041, 27.953485000000057],
            [71.832954000000029, 27.945723000000044],
            [71.814652000000081, 27.937061000000028],
            [71.792876000000035, 27.92831700000005],
            [71.766470000000083, 27.919461000000069],
            [71.741196000000059, 27.91165600000005],
            [71.722876000000042, 27.904013000000077],
            [71.705808000000047, 27.900394000000063],
            [71.682709000000045, 27.890694000000053],
            [71.655038000000047, 27.885906000000034],
            [71.636539000000084, 27.884426000000076],
            [71.610127000000034, 27.883273000000031],
            [71.586962000000028, 27.879245000000026],
            [71.561783000000048, 27.878238000000067],
            [71.525525000000073, 27.878238000000067],
            [71.500345000000038, 27.877231000000052],
            [71.480441000000042, 27.873323000000028],
            [71.453845000000058, 27.871612000000027],
            [71.433006000000034, 27.871075000000076],
            [71.403995000000066, 27.872381000000075],
            [71.38087500000006, 27.870747000000051],
            [71.378662000000077, 27.867600000000039],
            [71.356565000000046, 27.870107000000075],
            [71.325379000000055, 27.867220000000032],
            [71.295493000000079, 27.860243000000025],
            [71.264348000000041, 27.856315000000052],
            [71.237836000000073, 27.852505000000065],
            [71.206251000000066, 27.841983000000027],
            [71.18308600000006, 27.830904000000032],
            [71.153878000000077, 27.818818000000078],
            [71.122655000000066, 27.802704000000062],
            [71.108518000000061, 27.795375000000035],
            [71.101080000000081, 27.823202000000038],
            [71.09335800000008, 27.861812000000043],
            [71.089926000000048, 27.886694000000034],
            [71.088210000000061, 27.914150000000063],
            [71.071908000000064, 27.949329000000034],
            [71.069334000000083, 27.97592700000007],
            [71.05818000000005, 28.022259000000076],
            [71.034156000000053, 28.089183000000048],
            [71.034156000000053, 28.114065000000039],
            [71.021286000000032, 28.13894700000003],
            [71.004126000000042, 28.158682000000056],
            [70.992971000000068, 28.180132000000071],
            [70.979243000000054, 28.211020000000076],
            [70.97323700000004, 28.241908000000024],
            [70.966373000000033, 28.272796000000028],
            [70.966373000000033, 28.294246000000044],
            [70.968947000000071, 28.325134000000048],
            [70.971521000000052, 28.351732000000027],
            [70.965515000000039, 28.393775000000062],
            [70.955219000000056, 28.44782900000007],
            [70.948355000000049, 28.500167000000033],
            [70.942349000000036, 28.547357000000034],
            [70.955219000000056, 28.59368900000004],
            [70.96637400000003, 28.627152000000024],
            [70.969806000000062, 28.652034000000072],
            [70.981818000000032, 28.694076000000052],
            [70.983897612000078, 28.698581827000055],
            [70.997262000000035, 28.727538000000038],
            [71.008809984000038, 28.747334545000058],
            [71.015280000000075, 28.758426000000043],
            [71.019961764000072, 28.769350115000066],
            [71.024473614000044, 28.779877766000027],
            [71.025576000000058, 28.78245000000004],
            [71.040162000000066, 28.805616000000043],
            [71.041369520000046, 28.809372808000035],
            [71.051004387000035, 28.839348573000052],
            [71.055606000000068, 28.853665000000035],
            [71.056464000000062, 28.878547000000026],
            [71.060754000000031, 28.898281000000054],
            [71.053790391000064, 28.90292340600007],
            [71.051840786000071, 28.904223143000024],
            [71.047884000000067, 28.906861000000049],
            [71.04220943200005, 28.920338099000048],
            [71.04102000000006, 28.923163000000045],
            [71.049600000000055, 28.924879000000033],
            [71.052604784000039, 28.932090483000025],
            [71.053890000000081, 28.935175000000072],
            [71.05339120800005, 28.943654468000034],
            [71.05303200000003, 28.949761000000024],
            [71.053055166000036, 28.94998107400005],
            [71.053416049000077, 28.953409458000067],
            [71.053443506000065, 28.953670301000045],
            [71.054121852000037, 28.960114590000046],
            [71.054748000000075, 28.966063000000076],
            [71.058260211000061, 28.974843528000065],
            [71.058926036000059, 28.976508090000038],
            [71.061612000000082, 28.983223000000066],
            [71.057396573000062, 28.988140998000063],
            [71.05731518500005, 28.988235951000036],
            [71.051316000000043, 28.995235000000037],
            [71.04278245900008, 29.000449942000046],
            [71.035931303000041, 29.00463675900005],
            [71.03587200000004, 29.004673000000025],
            [71.042736000000048, 29.009821000000045],
            [71.055863215000045, 29.020631648000062],
            [71.057322000000056, 29.021833000000072],
            [71.056984830000033, 29.031948095000075],
            [71.056922121000071, 29.033829371000024],
            [71.056850654000073, 29.035973375000026],
            [71.056464000000062, 29.047573000000057],
            [71.05988174600003, 29.050136420000058],
            [71.078602065000041, 29.064177266000058],
            [71.085726312000077, 29.069520682000075],
            [71.087352000000067, 29.070740000000058],
            [71.086574045000077, 29.074694840000063],
            [71.086463894000076, 29.075254806000032],
            [71.086410890000082, 29.07552425800003],
            [71.082474578000074, 29.095535033000033],
            [71.081933388000039, 29.098286245000054],
            [71.079604000000074, 29.110128000000032],
            [71.077056000000084, 29.123078000000078],
            [71.076716715000032, 29.127827997000054],
            [71.07644865900005, 29.131580779000046],
            [71.076198000000034, 29.135090000000048],
            [71.081346000000053, 29.144528000000037],
            [71.080931960000044, 29.145045550000077],
            [71.076123132000077, 29.151056586000038],
            [71.074482000000046, 29.153108000000032],
            [71.060754000000031, 29.156540000000064],
            [71.056730816000083, 29.154528408000033],
            [71.042708162000054, 29.147517081000046],
            [71.038446000000079, 29.14538600000003],
            [71.037525468000069, 29.144677899000044],
            [71.029174604000048, 29.138254156000073],
            [71.028810830000054, 29.137974330000077],
            [71.016138000000069, 29.128226000000041],
            [71.014360633000081, 29.123190126000054],
            [71.01301750500005, 29.119384598000067],
            [71.005882601000053, 29.099169037000024],
            [71.00584200000003, 29.099054000000024],
            [70.994687226000053, 29.076746452000066],
            [70.987990041000046, 29.080653143000063],
            [70.981663138000044, 29.084343836000073],
            [70.974096000000031, 29.088758000000041],
            [70.959319802000039, 29.105324668000037],
            [70.950476305000052, 29.115239753000026],
            [70.949646281000071, 29.11617035300003],
            [70.945781000000068, 29.120504000000039],
            [70.936820958000055, 29.13282405700005],
            [70.932816249000041, 29.138330533000044],
            [70.932053000000053, 29.139380000000074],
            [70.930733907000047, 29.148173953000025],
            [70.929987252000046, 29.153151655000045],
            [70.929479000000072, 29.156540000000064],
            [70.923285857000053, 29.163097446000052],
            [70.919008541000039, 29.167626368000072],
            [70.914893000000063, 29.171984000000066],
            [70.912451630000078, 29.17861057600004],
            [70.90888700000005, 29.188286000000062],
            [70.895395488000077, 29.180191093000076],
            [70.89172700000006, 29.177990000000079],
            [70.887402812000062, 29.181773665000037],
            [70.884863000000053, 29.183996000000036],
            [70.886210263000066, 29.185848486000054],
            [70.899010084000054, 29.203448241000046],
            [70.912319000000082, 29.221748000000048],
            [70.912425299000063, 29.223076733000028],
            [70.913888969000084, 29.241372613000067],
            [70.914035000000069, 29.243198000000064],
            [70.910970000000077, 29.244973000000073],
            [70.913576000000035, 29.249813000000074],
            [70.918648000000076, 29.254495000000077],
            [70.918648000000076, 29.263079000000062],
            [70.915527000000054, 29.273614000000066],
            [70.911235000000033, 29.279076000000032],
            [70.909174783000083, 29.284748609000076],
            [70.912015000000054, 29.286879000000056],
            [70.919819000000075, 29.28063700000007],
            [70.927622000000042, 29.27946600000007],
            [70.938547000000028, 29.274784000000068],
            [70.942615852000074, 29.274590316000058],
            [70.946740000000034, 29.274394000000029],
            [70.952983000000074, 29.275565000000029],
            [70.955324000000076, 29.281417000000033],
            [70.955324000000076, 29.291952000000038],
            [70.952203000000054, 29.298975000000041],
            [70.944399000000033, 29.303267000000062],
            [70.935815000000048, 29.308729000000028],
            [70.924891000000059, 29.314972000000068],
            [70.916362723000077, 29.321297858000037],
            [70.916375139000081, 29.321403445000044],
            [70.917087000000038, 29.327457000000038],
            [70.919937177000065, 29.334011969000073],
            [70.920989000000077, 29.336431000000061],
            [70.924670589000073, 29.344161204000045],
            [70.934645000000046, 29.351257000000032],
            [70.94166800000005, 29.34930600000007],
            [70.951422000000036, 29.347746000000029],
            [70.958674691000056, 29.350712472000055],
            [70.960006000000078, 29.351257000000032],
            [70.964298000000042, 29.355939000000035],
            [70.965497630000073, 29.362061922000066],
            [70.966047462000063, 29.362245231000031],
            [70.970540000000028, 29.363743000000056],
            [70.979514000000052, 29.359841000000074],
            [70.986537000000055, 29.359451000000035],
            [70.992390000000057, 29.364133000000038],
            [70.999023000000079, 29.371156000000042],
            [71.005656000000045, 29.371936000000062],
            [71.01306900000003, 29.371936000000062],
            [71.022103732000062, 29.373182418000056],
            [71.024384000000055, 29.373497000000043],
            [71.03257700000006, 29.380520000000047],
            [71.042722000000083, 29.385202000000049],
            [71.041852579000079, 29.387603747000071],
            [71.040771000000063, 29.391835000000071],
            [71.039990000000046, 29.401199000000076],
            [71.038430000000062, 29.407051000000024],
            [71.038430000000062, 29.415138418000026],
            [71.038430000000062, 29.415245000000027],
            [71.041551000000084, 29.419147000000066],
            [71.045843000000048, 29.421878000000049],
            [71.05130500000007, 29.423048000000051],
            [71.055207000000053, 29.424219000000051],
            [71.060669000000075, 29.427340000000072],
            [71.063791000000037, 29.432803000000035],
            [71.064994990000059, 29.441945085000043],
            [71.06574696000007, 29.441979265000043],
            [71.071140022000066, 29.442224404000058],
            [71.076943000000028, 29.438840000000027],
            [71.079845000000034, 29.431101000000069],
            [71.085649000000046, 29.430618000000038],
            [71.085893379000083, 29.430618000000038],
            [71.092417982000029, 29.430617366000035],
            [71.10063099100006, 29.432065541000043],
            [71.100630995000074, 29.432067970000048],
            [71.10064125100007, 29.432069778000027],
            [71.10064125100007, 29.437928037000063],
            [71.10064125100007, 29.438356400000032],
            [71.103544235000072, 29.444162369000026],
            [71.107896805000053, 29.450448990000041],
            [71.113060174000054, 29.451855499000033],
            [71.113216139000031, 29.451897985000073],
            [71.113424914000063, 29.451874771000064],
            [71.11756900000006, 29.451414000000057],
            [71.121438000000069, 29.447061000000076],
            [71.122270213000036, 29.447310647000052],
            [71.126276016000077, 29.448511124000049],
            [71.122080842000059, 29.45783536700003],
            [71.121923447000029, 29.458185196000045],
            [71.122720718000039, 29.460123062000036],
            [71.123869902000081, 29.46291635700004],
            [71.125307000000078, 29.466407000000061],
            [71.129265006000082, 29.470365006000065],
            [71.131111135000083, 29.472209922000047],
            [71.132562000000064, 29.460603000000049],
            [71.13933292300004, 29.452381976000026],
            [71.142566730000055, 29.453998628000079],
            [71.146104813000079, 29.455766678000032],
            [71.147821426000064, 29.457578659000035],
            [71.153119513000036, 29.463171727000031],
            [71.154810000000055, 29.464956000000029],
            [71.155907208000031, 29.465599226000052],
            [71.168835000000058, 29.473178000000075],
            [71.168848841000056, 29.473182259000055],
            [71.182736813000076, 29.477455708000036],
            [71.187697000000071, 29.47898200000003],
            [71.199305000000038, 29.480916000000036],
            [71.205749307000076, 29.481948356000032],
            [71.210642600000028, 29.482730429000071],
            [71.211396519000061, 29.482850924000047],
            [71.211397171000044, 29.482851028000027],
            [71.220858132000046, 29.481459645000029],
            [71.227840369000035, 29.480432798000038],
            [71.233160000000055, 29.477531000000056],
            [71.233160000000055, 29.472211000000073],
            [71.233440639000037, 29.467995608000024],
            [71.233643000000029, 29.464956000000029],
            [71.233770305000064, 29.463620346000027],
            [71.234558074000063, 29.455355283000074],
            [71.234611000000029, 29.454800000000034],
            [71.24348745900005, 29.447324223000066],
            [71.243799210000077, 29.44706153900006],
            [71.243799360000082, 29.44706153900006],
            [71.247488022000084, 29.44706153900006],
            [71.256860733000053, 29.44706153900006],
            [71.267984390000038, 29.44706153900006],
            [71.270262896000077, 29.446758401000068],
            [71.270261941000058, 29.446757113000047],
            [71.270261976000029, 29.446757108000043],
            [71.274681133000058, 29.446168090000072],
            [71.275237000000061, 29.446094000000073],
            [71.285393000000056, 29.446094000000073],
            [71.289746000000036, 29.441258000000062],
            [71.294392049000066, 29.440984504000028],
            [71.297968000000083, 29.440774000000033],
            [71.304738999000051, 29.444643011000039],
            [71.314664841000081, 29.448080063000077],
            [71.315980035000052, 29.448534231000053],
            [71.317316055000049, 29.448995590000038],
            [71.32222811400004, 29.455355962000056],
            [71.329889110000067, 29.462537591000057],
            [71.329889297000079, 29.462537766000025],
            [71.339078903000029, 29.459154129000069],
            [71.339074847000063, 29.459115188000055],
            [71.338449068000045, 29.453107446000047],
            [71.338409333000072, 29.452725977000057],
            [71.336924411000041, 29.450566352000067],
            [71.33520657400004, 29.447703290000049],
            [71.334241000000077, 29.446094000000073],
            [71.329888000000039, 29.438356000000056],
            [71.325052000000028, 29.433519000000047],
            [71.323117000000082, 29.426748000000032],
            [71.318765000000042, 29.425298000000055],
            [71.318319305000045, 29.424257942000054],
            [71.317314000000067, 29.421912000000077],
            [71.317269531000079, 29.41709577000006],
            [71.323601000000053, 29.41417400000006],
            [71.327470000000062, 29.419010000000071],
            [71.329329868000059, 29.420683938000025],
            [71.337143000000083, 29.427716000000032],
            [71.338372674000084, 29.426486326000031],
            [71.341496000000063, 29.423363000000052],
            [71.346332000000075, 29.419494000000043],
            [71.355021000000079, 29.422873000000038],
            [71.355132000000083, 29.422712000000047],
            [71.36024800000007, 29.420325000000048],
            [71.36604600000004, 29.413504000000046],
            [71.369797000000062, 29.408729000000051],
            [71.37118102800008, 29.404774634000034],
            [71.372184000000061, 29.401909000000046],
            [71.372184000000061, 29.395196705000046],
            [71.372184000000061, 29.394065000000069],
            [71.375838887000043, 29.391323667000052],
            [71.37764100000004, 29.389972000000057],
            [71.38412100000005, 29.397816000000034],
            [71.385308758000065, 29.398766439000042],
            [71.389236000000039, 29.401909000000046],
            [71.389577000000031, 29.407365000000027],
            [71.393329000000051, 29.415550000000053],
            [71.39946800000007, 29.414186000000029],
            [71.409017000000063, 29.414186000000029],
            [71.41393578800006, 29.416153284000075],
            [71.41754300000008, 29.41759600000006],
            [71.424363000000028, 29.419643000000065],
            [71.432548000000054, 29.419643000000065],
            [71.438346000000081, 29.418961000000024],
            [71.444485000000043, 29.415891000000045],
            [71.452329000000077, 29.407706000000076],
            [71.45491233000007, 29.399632147000034],
            [71.455057000000068, 29.399180000000058],
            [71.458453423000037, 29.391175112000042],
            [71.459832000000063, 29.38792600000005],
            [71.467334000000051, 29.382128000000023],
            [71.474155000000053, 29.37257900000003],
            [71.480635000000063, 29.372238000000038],
            [71.481317000000047, 29.381787000000031],
            [71.485750000000053, 29.381105000000048],
            [71.485750000000053, 29.381105154000068],
            [71.48575100000005, 29.381105000000048],
            [71.48575100000005, 29.386903000000075],
            [71.482340000000079, 29.392018000000064],
            [71.481658000000039, 29.393042000000037],
            [71.480635000000063, 29.398839000000066],
            [71.48381334700008, 29.401336939000032],
            [71.485409000000061, 29.402591000000029],
            [71.49086600000004, 29.402591000000029],
            [71.495481799000061, 29.403616566000039],
            [71.497005000000058, 29.403955000000053],
            [71.502120000000048, 29.406342000000052],
            [71.504707558000064, 29.408120620000034],
            [71.50757645300007, 29.410092624000072],
            [71.507577000000083, 29.410093000000074],
            [71.507918000000075, 29.417255000000068],
            [71.508337972000049, 29.42124534900006],
            [71.508600000000058, 29.423735000000079],
            [71.509282000000042, 29.431579000000056],
            [71.510987000000057, 29.438059000000067],
            [71.518490000000043, 29.446244000000036],
            [71.524571986000069, 29.445073833000038],
            [71.527357000000052, 29.44453800000008],
            [71.534860000000037, 29.441469000000041],
            [71.544750000000079, 29.43226100000004],
            [71.542704000000072, 29.426122000000078],
            [71.545773000000054, 29.419302000000073],
            [71.546455081000033, 29.419484971000031],
            [71.54645898800004, 29.419486019000033],
            [71.55975600000005, 29.423053000000039],
            [71.57135100000005, 29.426804000000061],
            [71.572082893000072, 29.427861072000042],
            [71.574421000000029, 29.431238000000064],
            [71.57497791600008, 29.431832152000027],
            [71.578133845000082, 29.435199094000041],
            [71.579536000000076, 29.436695000000043],
            [71.586357000000078, 29.441810000000032],
            [71.593519000000072, 29.446244000000036],
            [71.593714623000039, 29.446093476000044],
            [71.597952000000078, 29.442833000000064],
            [71.606478000000038, 29.437036000000035],
            [71.609207000000083, 29.434648000000038],
            [71.615687000000037, 29.438059000000067],
            [71.61554132100008, 29.442852783000035],
            [71.615347893000035, 29.449217805000046],
            [71.615345000000048, 29.449313000000075],
            [71.616209036000043, 29.452891441000077],
            [71.617733000000044, 29.459203000000059],
            [71.618756000000076, 29.463637000000062],
            [71.623526997000056, 29.466738270000064],
            [71.628803499000071, 29.470168132000026],
            [71.632397000000083, 29.472504000000072],
            [71.633106687000065, 29.472759457000052],
            [71.646065892000081, 29.47742422400006],
            [71.649449000000061, 29.478642000000036],
            [71.659681000000035, 29.478983000000028],
            [71.669230000000084, 29.475914000000046],
            [71.675973601000067, 29.473416641000028],
            [71.678438000000028, 29.472504000000072],
            [71.680460641000082, 29.471492256000033],
            [71.682051948000037, 29.47069626900003],
            [71.683212000000083, 29.470116000000075],
            [71.683347901000047, 29.470227184000066],
            [71.694467000000031, 29.479324000000076],
            [71.699582000000078, 29.485463000000038],
            [71.702015091000078, 29.484398718000079],
            [71.705039000000056, 29.48307600000004],
            [71.707830969000042, 29.481505406000053],
            [71.715952000000073, 29.476937000000078],
            [71.715270000000032, 29.482394000000056],
            [71.720727000000068, 29.489897000000042],
            [71.721645203000037, 29.490953068000067],
            [71.727547000000072, 29.497741000000076],
            [71.734368000000075, 29.500128000000075],
            [71.733004000000051, 29.504561000000024],
            [71.733004000000051, 29.507255459000078],
            [71.733004000000051, 29.510359000000051],
            [71.733004000000051, 29.517521000000045],
            [71.734683809000046, 29.522001312000043],
            [71.735050000000058, 29.522978000000023],
            [71.751761000000045, 29.527411000000029],
            [71.752814107000063, 29.526967655000078],
            [71.758241000000055, 29.524683000000039],
            [71.765062000000057, 29.518885000000068],
            [71.768200609000075, 29.516292166000028],
            [71.772906000000035, 29.512405000000058],
            [71.776998000000049, 29.505926000000045],
            [71.778364310000029, 29.505242762000023],
            [71.785183000000075, 29.501833000000033],
            [71.789834098000028, 29.501622000000054],
            [71.792686366000055, 29.501492215000042],
            [71.792691098000034, 29.501492000000042],
            [71.798489098000061, 29.504902000000072],
            [71.802922098000067, 29.507972000000052],
            [71.807697098000062, 29.514793000000054],
            [71.808523906000062, 29.516115893000062],
            [71.811107098000036, 29.520249000000035],
            [71.813494098000035, 29.528434000000061],
            [71.814361290000079, 29.529416795000031],
            [71.81751545000003, 29.53299142700007],
            [71.818610098000079, 29.534232000000031],
            [71.826454098000056, 29.536960000000079],
            [71.826904178000063, 29.536977309000065],
            [71.835321098000065, 29.53730100000007],
            [71.84350100000006, 29.53730100000007],
            [71.846418229000051, 29.537174180000079],
            [71.851345000000038, 29.536960000000079],
            [71.854755000000068, 29.532186000000024],
            [71.855778000000043, 29.526388000000054],
            [71.857106885000064, 29.522900469000035],
            [71.858507000000031, 29.51922600000006],
            [71.863622000000078, 29.515475000000038],
            [71.863627098000052, 29.515475000000038],
            [71.86976100000004, 29.515475000000038],
            [71.872713440000041, 29.515327400000047],
            [71.876582000000042, 29.515134000000046],
            [71.876582036000059, 29.515134253000042],
            [71.876587098000073, 29.515134000000046],
            [71.877269098000056, 29.519908000000044],
            [71.876508453000042, 29.521505109000032],
            [71.876016531000062, 29.522537988000067],
            [71.875677417000077, 29.523250016000077],
            [71.873858098000028, 29.527070000000037],
            [71.872835098000053, 29.535937000000047],
            [71.877269098000056, 29.542417000000057],
            [71.878578468000057, 29.544308312000055],
            [71.880338098000038, 29.546850000000063],
            [71.884846973000037, 29.546850000000063],
            [71.886472000000083, 29.546850000000063],
            [71.893903632000047, 29.543444252000029],
            [71.894657000000052, 29.543099000000041],
            [71.900795000000073, 29.53661900000003],
            [71.900795000000073, 29.535874620000072],
            [71.900795000000073, 29.530139000000077],
            [71.900795000000073, 29.518203000000028],
            [71.900454000000082, 29.509677000000067],
            [71.901819000000046, 29.501833000000033],
            [71.902499188000036, 29.50166295300005],
            [71.902501820000055, 29.501662295000074],
            [71.907275000000084, 29.500469000000066],
            [71.907276200000069, 29.500469975000044],
            [71.907280098000058, 29.500469000000066],
            [71.912737098000036, 29.504902000000072],
            [71.913763304000042, 29.509520681000026],
            [71.91410109800006, 29.511041000000034],
            [71.91410109800006, 29.515816000000029],
            [71.913760098000068, 29.522978000000023],
            [71.91410109800006, 29.530139000000077],
            [71.917506931000048, 29.530820167000059],
            [71.92398600000007, 29.52502400000003],
            [71.929443000000049, 29.51922600000006],
            [71.933535000000063, 29.514793000000054],
            [71.93701175800004, 29.513799381000069],
            [71.943084000000056, 29.512064000000066],
            [71.943084949000081, 29.512065186000029],
            [71.94308909800003, 29.512064000000066],
            [71.945818098000075, 29.515475000000038],
            [71.945136098000035, 29.523319000000072],
            [71.94308909800003, 29.528093000000069],
            [71.936269098000082, 29.533550000000048],
            [71.93149409800003, 29.537983000000054],
            [71.925014098000076, 29.544804000000056],
            [71.925009286000034, 29.544803699000056],
            [71.919558098000039, 29.544463000000064],
            [71.919557943000029, 29.544463309000037],
            [71.917590931000063, 29.548396853000042],
            [71.916944553000064, 29.549689451000063],
            [71.915465098000084, 29.552648000000033],
            [71.912055098000053, 29.557423000000028],
            [71.916488098000059, 29.561856000000034],
            [71.923649717000046, 29.566289764000032],
            [71.930466000000081, 29.565949000000046],
            [71.930471098000055, 29.565949000000046],
            [71.933247453000035, 29.565949000000046],
            [71.939333000000033, 29.565949000000046],
            [71.942743000000064, 29.561174000000051],
            [71.949564000000066, 29.558105000000069],
            [71.949565912000082, 29.558106434000024],
            [71.94956909800004, 29.558105000000069],
            [71.949948225000071, 29.558389293000062],
            [71.955026098000076, 29.562197000000026],
            [71.960823098000049, 29.564584000000025],
            [71.964600434000033, 29.566473014000053],
            [71.966276893000042, 29.567311397000026],
            [71.970708000000059, 29.56356100000005],
            [71.972081653000032, 29.556695956000056],
            [71.972414000000072, 29.555035000000032],
            [71.975483000000054, 29.550261000000035],
            [71.981622000000073, 29.547532000000047],
            [71.98162650200004, 29.547532265000029],
            [71.981627098000047, 29.547532000000047],
            [71.987104006000038, 29.54785417100004],
            [71.987424098000076, 29.547873000000038],
            [71.993222098000047, 29.54821400000003],
            [72.000379000000066, 29.54821400000003],
            [72.00038409800004, 29.54821400000003],
            [72.005841098000076, 29.549238000000059],
            [72.011974000000066, 29.549238000000059],
            [72.01197909800004, 29.549238000000059],
            [72.014289010000084, 29.559794143000033],
            [72.014303617000053, 29.559860897000078],
            [72.014367098000037, 29.560151000000076],
            [72.017095098000084, 29.565949000000046],
            [72.020505098000058, 29.574816000000055],
            [72.028006130000051, 29.579588748000049],
            [72.030436574000078, 29.578616713000031],
            [72.034824000000071, 29.576862000000062],
            [72.037552000000062, 29.571746000000076],
            [72.041061998000032, 29.565266673000053],
            [72.041986000000065, 29.56356100000005],
            [72.04300900000004, 29.557081000000039],
            [72.05187600000005, 29.554012000000057],
            [72.060061000000076, 29.553671000000065],
            [72.060062835000053, 29.553671136000048],
            [72.06006609800005, 29.553671000000065],
            [72.069274098000051, 29.554353000000049],
            [72.074288157000069, 29.55825294400006],
            [72.078482098000052, 29.561515000000043],
            [72.07541309800007, 29.572087000000067],
            [72.075892797000051, 29.577364394000028],
            [72.075970056000074, 29.578214356000046],
            [72.076082736000046, 29.579453995000051],
            [72.076529838000056, 29.584372776000066],
            [72.076777098000036, 29.587093000000038],
            [72.07890285700006, 29.591193124000029],
            [72.081551098000034, 29.596301000000039],
            [72.073025098000073, 29.600052000000062],
            [72.072999947000028, 29.600580254000079],
            [72.07268416800008, 29.607212524000033],
            [72.072684098000082, 29.607214000000056],
            [72.075777051000046, 29.606330422000042],
            [72.079846098000075, 29.605168000000049],
            [72.08303152700006, 29.603452603000051],
            [72.088713098000028, 29.600393000000054],
            [72.091387226000052, 29.600096001000054],
            [72.094916519000037, 29.599704024000062],
            [72.09491683400006, 29.599703423000051],
            [72.099848000000065, 29.599409000000037],
            [72.111939000000064, 29.593122000000051],
            [72.117742000000078, 29.593605000000025],
            [72.119014538000044, 29.593754820000072],
            [72.125964000000067, 29.594573000000025],
            [72.137088000000062, 29.596507000000031],
            [72.141441000000043, 29.602794000000074],
            [72.144594832000053, 29.606210832000045],
            [72.144805364000035, 29.606438921000063],
            [72.147245000000055, 29.609082000000058],
            [72.155467000000044, 29.61488600000007],
            [72.161270000000059, 29.61827100000005],
            [72.166107000000068, 29.618755000000078],
            [72.166107000000068, 29.624066821000042],
            [72.16610600000007, 29.624066890000051],
            [72.16610600000007, 29.624075000000062],
            [72.16514826100007, 29.627428079000026],
            [72.16514877700007, 29.627428036000026],
            [72.164172000000065, 29.630846000000076],
            [72.163688000000036, 29.637133000000063],
            [72.163785393000069, 29.638398107000057],
            [72.164172000000065, 29.643420000000049],
            [72.167789404000075, 29.645973565000077],
            [72.172394000000054, 29.649224000000061],
            [72.17273146000008, 29.649245108000059],
            [72.180132000000071, 29.649708000000032],
            [72.183381560000043, 29.652493063000065],
            [72.183518000000049, 29.652610000000038],
            [72.183518000000049, 29.661315000000059],
            [72.183518000000049, 29.661822375000042],
            [72.183518000000049, 29.667603000000042],
            [72.186903047000044, 29.670564588000047],
            [72.187387000000058, 29.670988000000079],
            [72.19222300000007, 29.686465000000055],
            [72.19222300000007, 29.688686264000069],
            [72.19222300000007, 29.69323600000007],
            [72.192453644000068, 29.694927280000059],
            [72.19250581700004, 29.69530985800003],
            [72.193674000000044, 29.703876000000037],
            [72.196540979000076, 29.711042459000055],
            [72.19657600000005, 29.711130000000026],
            [72.202380000000062, 29.709196000000077],
            [72.204957384000068, 29.708068186000048],
            [72.207320272000061, 29.707034232000069],
            [72.21011800000008, 29.705810000000042],
            [72.215438000000063, 29.703876000000037],
            [72.223923465000041, 29.704229074000068],
            [72.22704600000003, 29.704359000000068],
            [72.23369165500003, 29.704359000000068],
            [72.234300000000076, 29.704359000000068],
            [72.244457000000068, 29.708229000000074],
            [72.245283645000029, 29.712637392000033],
            [72.245908000000043, 29.715967000000035],
            [72.245758824000063, 29.716513945000031],
            [72.244457000000068, 29.721287000000075],
            [72.244038990000035, 29.721496005000063],
            [72.238653000000056, 29.724189000000024],
            [72.234300000000076, 29.730960000000039],
            [72.232863351000049, 29.735630225000079],
            [72.23236600000007, 29.737247000000025],
            [72.231776982000042, 29.737920039000073],
            [72.228980000000035, 29.741116000000034],
            [72.228521060000048, 29.741804411000032],
            [72.22607800000003, 29.745469000000071],
            [72.223251644000072, 29.749237150000056],
            [72.223252079000076, 29.749237218000076],
            [72.222755737000057, 29.749899065000079],
            [72.220275000000072, 29.753207000000032],
            [72.217373000000066, 29.758044000000041],
            [72.217373000000066, 29.761456543000065],
            [72.217373000000066, 29.763848000000053],
            [72.218767771000046, 29.766079749000028],
            [72.219791000000043, 29.767717000000061],
            [72.228013000000033, 29.767233000000033],
            [72.230431000000067, 29.772069000000045],
            [72.231661773000042, 29.776254036000068],
            [72.232849000000044, 29.780291000000034],
            [72.233575177000034, 29.787310542000057],
            [72.234300000000076, 29.794317000000035],
            [72.237643192000064, 29.795859930000063],
            [72.239293965000059, 29.79662178500007],
            [72.240588000000059, 29.797219000000041],
            [72.24524156800004, 29.802204523000057],
            [72.245240297000066, 29.802204921000055],
            [72.247358000000077, 29.804474000000027],
            [72.248809000000051, 29.810277000000042],
            [72.255580000000066, 29.812212000000045],
            [72.258482000000072, 29.812695000000076],
            [72.264770000000055, 29.81076100000007],
            [72.267466642000045, 29.809705760000043],
            [72.275894000000051, 29.806408000000033],
            [72.28314800000004, 29.80544100000003],
            [72.285326234000081, 29.805097043000046],
            [72.292337000000032, 29.804370748000053],
            [72.297657000000072, 29.804370748000053],
            [72.302018114000077, 29.803498886000057],
            [72.294756000000064, 29.81076100000007],
            [72.294864487000041, 29.811032275000059],
            [72.295723000000066, 29.813179000000048],
            [72.299592000000075, 29.814146000000051],
            [72.302010000000053, 29.81076100000007],
            [72.310232000000042, 29.803506000000027],
            [72.314173396000058, 29.809199430000035],
            [72.314585000000079, 29.809794000000068],
            [72.314528113000051, 29.810092574000066],
            [72.312650000000076, 29.819950000000063],
            [72.312714550000067, 29.819991954000045],
            [72.32232300000004, 29.826237000000049],
            [72.327160000000049, 29.832525000000032],
            [72.328965160000052, 29.833156704000032],
            [72.33683300000007, 29.835910000000069],
            [72.340539057000058, 29.842859456000042],
            [72.340702000000078, 29.843165000000056],
            [72.348984872000074, 29.844742302000043],
            [72.350858000000073, 29.845099000000062],
            [72.355695000000082, 29.842198000000053],
            [72.363433000000043, 29.838328000000047],
            [72.372622000000035, 29.837361000000044],
            [72.374065931000075, 29.839165914000034],
            [72.378426000000047, 29.84461600000003],
            [72.383746000000031, 29.852354000000048],
            [72.390033000000074, 29.857191000000057],
            [72.392390295000041, 29.857644275000041],
            [72.402608000000043, 29.859609000000034],
            [72.406477000000052, 29.854289000000051],
            [72.414087000000052, 29.847592000000077],
            [72.41856800000005, 29.843649000000028],
            [72.426306000000068, 29.839779000000078],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "93",
      properties: { name: "Bhakkar", count: 584 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.586387000000059, 32.184149000000048],
            [71.602503422000041, 32.178363608000041],
            [71.610480500000051, 32.175500000000056],
            [71.651253000000054, 32.179825000000051],
            [71.66547726500005, 32.181247369000062],
            [71.700675000000047, 32.184767000000079],
            [71.715805610000075, 32.184046217000059],
            [71.72662100000008, 32.183531000000073],
            [71.72734969000004, 32.186826788000076],
            [71.73390100000006, 32.181451000000038],
            [71.745939973000077, 32.176569121000057],
            [71.749104885000065, 32.17530400000004],
            [71.755251885000064, 32.170035000000041],
            [71.761398885000062, 32.155985000000044],
            [71.776327885000057, 32.144569000000047],
            [71.775455424000029, 32.134969943000044],
            [71.772837773000049, 32.112106734000065],
            [71.771630740000035, 32.101361677000057],
            [71.771058885000059, 32.096271000000058],
            [71.769302885000059, 32.085733000000062],
            [71.769580079000036, 32.082513256000027],
            [71.770783000000051, 32.060267000000067],
            [71.766392000000053, 32.059389000000067],
            [71.765969871000038, 32.051789720000045],
            [71.765514000000053, 32.043583000000069],
            [71.761123000000055, 32.030411000000072],
            [71.749708000000055, 32.020751000000075],
            [71.742305781000084, 32.017951560000029],
            [71.730388000000062, 32.013726000000077],
            [71.725900384000056, 32.005312720000063],
            [71.723628906000044, 32.001037595000071],
            [71.72060323200003, 31.995017165000036],
            [71.714594757000043, 31.982888926000044],
            [71.715412866000065, 31.976343127000064],
            [71.718164427000033, 31.967312573000072],
            [71.719750218000058, 31.96223847400006],
            [71.718973000000062, 31.950500000000034],
            [71.711069000000066, 31.943475000000035],
            [71.704922000000067, 31.92854600000004],
            [71.69262800000007, 31.916252000000043],
            [71.691649710000036, 31.912093566000067],
            [71.689700775000063, 31.903847589000065],
            [71.688260350000064, 31.897852963000048],
            [71.67069516600003, 31.886434805000079],
            [71.649640165000051, 31.882028435000052],
            [71.645865789000084, 31.87944206700007],
            [71.635591582000075, 31.872397204000038],
            [71.625905344000046, 31.86183000300008],
            [71.625904820000073, 31.861822896000035],
            [71.625067035000029, 31.850458451000065],
            [71.637840010000048, 31.844173907000027],
            [71.666278365000039, 31.833840229000032],
            [71.68911600000007, 31.818779000000063],
            [71.711518561000048, 31.814623802000028],
            [71.717216000000064, 31.806485000000066],
            [71.724727078000058, 31.788082579000047],
            [71.726876000000061, 31.782775000000072],
            [71.73565700000006, 31.766090000000077],
            [71.744439000000057, 31.757309000000078],
            [71.744818130000056, 31.747829028000069],
            [71.745317000000057, 31.735355000000027],
            [71.747951000000057, 31.716914000000031],
            [71.755854500000055, 31.696717000000035],
            [71.760730038000077, 31.689891264000039],
            [71.764636000000053, 31.684423000000038],
            [71.766471650000028, 31.682395314000075],
            [71.769298893000041, 31.679288429000053],
            [71.773417000000052, 31.674763000000041],
            [71.795753352000077, 31.66359510500007],
            [71.797831621000057, 31.66269687700003],
            [71.807665000000043, 31.654566000000045],
            [71.836643000000038, 31.63700300000005],
            [71.863730627000052, 31.611663539000062],
            [71.878080142000044, 31.609576737000054],
            [71.889332000000081, 31.608025000000055],
            [71.906763094000041, 31.59906019500005],
            [71.920067000000074, 31.592218000000059],
            [71.932361001000061, 31.579046000000062],
            [71.934995000000072, 31.567630000000065],
            [71.94202000000007, 31.554458000000068],
            [71.949045500000068, 31.551824000000067],
            [71.961339500000065, 31.534261000000072],
            [71.989185841000051, 31.531999986000073],
            [71.991396909000059, 31.530210074000024],
            [71.991396724000083, 31.53020981700007],
            [71.981337790000055, 31.516198964000068],
            [71.981289000000061, 31.51613100000003],
            [71.979468000000054, 31.496099000000072],
            [71.984020000000044, 31.489725000000078],
            [72.008605000000045, 31.481075000000033],
            [72.008748535000052, 31.45748158400005],
            [72.008825242000057, 31.444791716000054],
            [72.008825715000057, 31.444713511000032],
            [72.008825242000057, 31.444713333000038],
            [72.008844876000069, 31.441471750000062],
            [72.009060000000034, 31.405955000000063],
            [72.011623994000047, 31.400929734000044],
            [72.014917750000052, 31.394474181000078],
            [72.018226080000034, 31.387990064000064],
            [72.018226549000076, 31.387990139000067],
            [72.02044200000006, 31.383647000000053],
            [72.020833038000035, 31.36956106100007],
            [72.020889378000049, 31.367531574000054],
            [72.020897000000048, 31.367257000000052],
            [72.02044200000006, 31.349046000000044],
            [72.020897000000048, 31.334933000000035],
            [72.022718000000054, 31.320364000000041],
            [72.022718000000054, 31.31216900000004],
            [72.012703000000045, 31.281211000000042],
            [72.010635497000067, 31.265192796000065],
            [72.009060090000048, 31.25298470000007],
            [72.009060000000034, 31.252984000000026],
            [71.996346556000049, 31.252586922000035],
            [71.994492000000037, 31.252529000000038],
            [71.986337099000082, 31.254492631000062],
            [71.969907000000035, 31.258447000000046],
            [71.940138413000057, 31.263028353000038],
            [71.928477000000044, 31.26482100000004],
            [71.919211050000058, 31.265330047000077],
            [71.88704800000005, 31.267097000000035],
            [71.877895377000073, 31.262919675000035],
            [71.866105000000061, 31.25753700000007],
            [71.851992000000052, 31.244334000000038],
            [71.835207417000049, 31.239196077000031],
            [71.829683000000045, 31.237505000000056],
            [71.803733000000079, 31.227489000000048],
            [71.793717000000072, 31.206091000000072],
            [71.790530000000047, 31.177409000000068],
            [71.773738500000036, 31.16108150000008],
            [71.764925000000062, 31.167720000000031],
            [71.754424000000029, 31.177054000000055],
            [71.725838000000067, 31.177054000000055],
            [71.690251000000046, 31.172387000000072],
            [71.680815740000071, 31.179521753000074],
            [71.646718586000077, 31.205305331000034],
            [71.626251241000034, 31.219838681000056],
            [71.610910000000047, 31.230726000000061],
            [71.575323000000083, 31.23714300000006],
            [71.542070000000081, 31.239477000000079],
            [71.529399580000074, 31.236942482000075],
            [71.524568000000045, 31.235976000000051],
            [71.50531600000005, 31.233059000000026],
            [71.495397999000033, 31.224309000000062],
            [71.49539800000008, 31.207974000000036],
            [71.484897000000046, 31.184055000000058],
            [71.470313000000033, 31.163636000000054],
            [71.455728000000079, 31.150801000000058],
            [71.432404972000029, 31.149652563000075],
            [71.422621465000077, 31.149205908000056],
            [71.406577586000083, 31.148489597000037],
            [71.388054000000068, 31.148468000000037],
            [71.345467000000042, 31.144967000000065],
            [71.327382000000057, 31.137383000000057],
            [71.310463001000073, 31.143801000000053],
            [71.304351932000031, 31.150965942000028],
            [71.293545000000051, 31.163636000000054],
            [71.292378001000031, 31.188138000000038],
            [71.291820466000047, 31.191929307000066],
            [71.289377827000067, 31.208413700000051],
            [71.28839054000008, 31.213283313000034],
            [71.285219324000082, 31.229849840000043],
            [71.281877000000065, 31.235976000000051],
            [71.271610543000065, 31.231718253000054],
            [71.262625000000071, 31.227225000000033],
            [71.261092805000033, 31.22827602600006],
            [71.245707000000039, 31.240643000000034],
            [71.238139758000045, 31.253051923000044],
            [71.232617785000059, 31.261540611000044],
            [71.231122000000084, 31.264562500000068],
            [71.229222279000055, 31.266721646000065],
            [71.226420197000039, 31.269650753000064],
            [71.202881894000029, 31.299982021000062],
            [71.198840233000055, 31.304233177000071],
            [71.196702000000073, 31.307733000000042],
            [71.196269948000065, 31.310931868000068],
            [71.196078268000065, 31.317713709000031],
            [71.194455946000062, 31.344619968000075],
            [71.180440302000079, 31.338679546000037],
            [71.177511495000033, 31.338412909000056],
            [71.167610807000074, 31.340323198000078],
            [71.155277219000084, 31.345678563000035],
            [71.146402451000029, 31.342572614000062],
            [71.143645379000077, 31.341622403000031],
            [71.137788115000035, 31.339722451000057],
            [71.12550830400005, 31.337505371000077],
            [71.121093968000082, 31.341919163000057],
            [71.117930985000044, 31.345079946000055],
            [71.111566994000043, 31.348254128000065],
            [71.108050520000063, 31.351500618000046],
            [71.091121990000033, 31.354792133000046],
            [71.088382486000057, 31.35447386900006],
            [71.084793951000051, 31.354603374000078],
            [71.075433520000047, 31.354906924000034],
            [71.068547658000057, 31.354380976000073],
            [71.056314471000064, 31.355398511000033],
            [71.050285646000077, 31.355665594000072],
            [71.040055920000043, 31.355279430000053],
            [71.017053922000059, 31.354448594000075],
            [71.003112793000071, 31.354454997000062],
            [70.988302180000062, 31.354371308000054],
            [70.981876664000083, 31.35366765200007],
            [70.967905213000051, 31.351855236000063],
            [70.962047969000082, 31.350905285000067],
            [70.959016224000038, 31.350596819000032],
            [70.956918590000043, 31.350300881000067],
            [70.951426496000067, 31.349404170000071],
            [70.942277940000054, 31.347910523000053],
            [70.935919543000068, 31.346872414000075],
            [70.933813041000064, 31.347353909000049],
            [70.924287703000061, 31.351494733000038],
            [70.926060629000062, 31.363238833000025],
            [70.931874325000081, 31.372892398000033],
            [70.931266707000077, 31.385319471000059],
            [70.920233050000036, 31.385905750000063],
            [70.905530207000083, 31.380696210000053],
            [70.902094805000047, 31.379503801000055],
            [70.892928808000079, 31.369674510000038],
            [70.886911928000075, 31.364917600000069],
            [70.872923203000084, 31.357908437000049],
            [70.849612079000053, 31.350299028000052],
            [70.827465324000059, 31.353851418000033],
            [70.80713455800003, 31.360435573000075],
            [70.80846606800003, 31.36243276600004],
            [70.811521000000084, 31.367015000000038],
            [70.818940055000041, 31.375052621000066],
            [70.81948077100003, 31.375630486000034],
            [70.827823225000031, 31.376151397000058],
            [70.830078125000057, 31.376281738000046],
            [70.834716797000056, 31.382873535000044],
            [70.834693532000074, 31.382891184000073],
            [70.834713000000079, 31.382919000000072],
            [70.829800071000079, 31.386603466000054],
            [70.824157840000055, 31.390883779000035],
            [70.82418425700007, 31.390891551000038],
            [70.824211922000075, 31.390899690000026],
            [70.835376000000053, 31.394184000000052],
            [70.840014000000053, 31.396834000000069],
            [70.840016998000067, 31.396870977000049],
            [70.840087891000053, 31.396911621000072],
            [70.840270996000072, 31.399108887000068],
            [70.84027100000003, 31.399108923000028],
            [70.841125488000046, 31.407897949000073],
            [70.840911687000073, 31.407905170000049],
            [70.841337388000056, 31.413155347000043],
            [70.842002000000036, 31.42135200000007],
            [70.842622389000041, 31.421700958000031],
            [70.847681296000076, 31.424546504000034],
            [70.85260500000004, 31.427316000000076],
            [70.871821000000068, 31.427316000000076],
            [70.887062000000071, 31.431954000000076],
            [70.895676000000037, 31.437918000000025],
            [70.88639900000004, 31.444544000000064],
            [70.887237172000084, 31.450411204000034],
            [70.88838700000008, 31.458460000000059],
            [70.89119101700004, 31.467712366000058],
            [70.891937817000041, 31.470176570000035],
            [70.895011114000056, 31.480317478000075],
            [70.89501400000006, 31.480327000000045],
            [70.893710861000045, 31.480913427000075],
            [70.882349869000052, 31.486026002000074],
            [70.88176100000004, 31.486291000000051],
            [70.878448000000049, 31.496893000000057],
            [70.872918485000071, 31.507951336000076],
            [70.87049600000006, 31.512796000000037],
            [70.870527411000069, 31.512790391000067],
            [70.881688784000062, 31.510797418000038],
            [70.883300781000059, 31.510498047000056],
            [70.884438296000042, 31.510306465000042],
            [70.889050000000054, 31.509483000000046],
            [70.889151732000073, 31.509512920000077],
            [70.900315000000035, 31.512796000000037],
            [70.902303000000074, 31.52339900000004],
            [70.902269212000078, 31.523425110000062],
            [70.888158970000063, 31.534328654000035],
            [70.887725000000046, 31.534664000000078],
            [70.878478968000081, 31.54721197300006],
            [70.878448000000049, 31.547254000000066],
            [70.878448000000049, 31.551377340000045],
            [70.878448000000049, 31.555332774000078],
            [70.878448000000049, 31.555868000000032],
            [70.87858581100005, 31.556740757000057],
            [70.879196071000081, 31.560605534000047],
            [70.880436000000032, 31.568458000000078],
            [70.880490129000066, 31.568462167000064],
            [70.880507833000081, 31.568463529000041],
            [70.883643402000075, 31.568704867000065],
            [70.889099121000072, 31.569091797000056],
            [70.889563639000073, 31.570735451000076],
            [70.890828907000071, 31.574712398000031],
            [70.89368246600003, 31.583681608000063],
            [70.893688000000054, 31.583699000000024],
            [70.89437276700005, 31.583972927000048],
            [70.900230993000037, 31.586445206000064],
            [70.900329590000069, 31.586486816000047],
            [70.896133730000031, 31.591290773000026],
            [70.890502930000082, 31.597717285000044],
            [70.885096525000051, 31.610230988000069],
            [70.88507080100004, 31.610290527000075],
            [70.888247621000062, 31.614006808000056],
            [70.889441514000055, 31.615350509000052],
            [70.893188323000061, 31.619567464000056],
            [70.893676758000083, 31.620117187000062],
            [70.893679397000028, 31.620134074000077],
            [70.893729171000075, 31.620452630000045],
            [70.894888012000081, 31.627869216000079],
            [70.895042058000058, 31.629675438000049],
            [70.895080566000047, 31.630126953000058],
            [70.885092149000059, 31.636095641000054],
            [70.885433070000033, 31.636990550000064],
            [70.886395218000075, 31.639516165000032],
            [70.890375000000063, 31.649963000000071],
            [70.890999065000074, 31.651772666000056],
            [70.891002077000053, 31.651781399000072],
            [70.891005089000032, 31.651790133000077],
            [70.897002000000043, 31.66918000000004],
            [70.897081391000029, 31.66927262400003],
            [70.897827128000074, 31.670142675000079],
            [70.902451299000063, 31.675390330000027],
            [70.912605173000031, 31.687333007000063],
            [70.912902832000043, 31.687683105000076],
            [70.915001098000062, 31.687394083000072],
            [70.91919564300008, 31.686713868000027],
            [70.919196275000047, 31.686713766000025],
            [70.919205826000052, 31.686814909000077],
            [70.93818854400007, 31.68420016400006],
            [70.942616202000067, 31.685705574000053],
            [70.942687988000046, 31.685729980000076],
            [70.942686178000031, 31.685731816000043],
            [70.939115125000058, 31.689354875000049],
            [70.930134000000066, 31.69833600000004],
            [70.930173065000076, 31.698427160000051],
            [70.930114746000072, 31.698486328000058],
            [70.933325405000062, 31.705783281000038],
            [70.933400827000071, 31.705959281000048],
            [70.93726638000004, 31.714979714000037],
            [70.938085000000058, 31.716890000000035],
            [70.938109999000062, 31.716918123000028],
            [70.948143250000044, 31.728205176000074],
            [70.948688000000061, 31.728818000000047],
            [70.948778729000082, 31.728916294000044],
            [70.951463438000076, 31.730833207000046],
            [70.956726074000073, 31.737487793000071],
            [70.960661712000046, 31.747417711000026],
            [70.962010841000051, 31.74876684000003],
            [70.965647204000049, 31.752403205000064],
            [70.968251426000052, 31.755007426000077],
            [70.970555000000047, 31.757311000000072],
            [70.973857894000048, 31.75885261600007],
            [70.980494000000078, 31.76195000000007],
            [70.981065495000053, 31.763521638000043],
            [70.985900879000042, 31.776489258000026],
            [70.992401123000036, 31.775390625000057],
            [70.997640818000036, 31.775342774000023],
            [70.999048000000073, 31.775202000000036],
            [71.000669216000063, 31.77983359600006],
            [71.00371464400007, 31.788489024000057],
            [71.006261729000073, 31.791623944000037],
            [71.006263906000072, 31.79162662300007],
            [71.006263950000061, 31.791626677000068],
            [71.007292865000068, 31.792893053000057],
            [71.012329102000081, 31.799072266000053],
            [71.018493652000075, 31.809082031000059],
            [71.01849367300008, 31.809082067000077],
            [71.022277832000043, 31.815673828000058],
            [71.025690098000041, 31.830846222000048],
            [71.02569580100004, 31.830871582000043],
            [71.030517542000041, 31.842101968000065],
            [71.030517578000058, 31.84210205100004],
            [71.032287598000039, 31.846679687000062],
            [71.032802020000076, 31.849445918000072],
            [71.035511602000042, 31.864016311000057],
            [71.042906526000081, 31.87254957600004],
            [71.044108000000051, 31.873936000000072],
            [71.062664294000058, 31.880121120000069],
            [71.063987000000054, 31.880562000000054],
            [71.063897800000063, 31.881513445000053],
            [71.062516454000047, 31.896247579000033],
            [71.062072718000081, 31.900980685000036],
            [71.061999000000071, 31.901767000000063],
            [71.062072718000081, 31.901887626000075],
            [71.062075198000059, 31.901891685000066],
            [71.069288000000029, 31.913694000000078],
            [71.069158062000042, 31.914031888000068],
            [71.069168743000034, 31.914050153000062],
            [71.066968417000055, 31.919969077000076],
            [71.066101074000073, 31.922302246000072],
            [71.071289062000062, 31.944885254000042],
            [71.07141527400006, 31.946176670000057],
            [71.072064254000054, 31.95281714500004],
            [71.072602001000064, 31.958754000000056],
            [71.067721560000052, 31.967227530000059],
            [71.068952167000077, 31.973207940000066],
            [71.068812577000074, 31.973216000000036],
            [71.070297405000076, 31.979747947000078],
            [71.070302866000077, 31.979771969000069],
            [71.070646577000048, 31.981284000000073],
            [71.070866873000057, 31.988340136000033],
            [71.070834876000049, 31.988358709000067],
            [71.070992119000039, 31.993395262000035],
            [71.071276000000069, 32.002488000000028],
            [71.071275990000061, 32.002488036000045],
            [71.069187283000076, 32.01037847200007],
            [71.065312464000044, 32.025016247000053],
            [71.065312000000063, 32.025018000000046],
            [71.065312464000044, 32.025018309000075],
            [71.073264000000052, 32.030319000000077],
            [71.086517000000072, 32.035620000000051],
            [71.088391784000066, 32.041868653000051],
            [71.088391820000084, 32.041868773000033],
            [71.089007422000066, 32.043921196000042],
            [71.089670734000038, 32.046132180000029],
            [71.09034766700006, 32.048388568000064],
            [71.09123339100006, 32.050995403000059],
            [71.091234787000076, 32.050999513000079],
            [71.092403827000055, 32.054440195000041],
            [71.094778162000068, 32.06117533400004],
            [71.094840228000066, 32.061352103000047],
            [71.100432001000058, 32.077367000000038],
            [71.10426516900003, 32.089165949000062],
            [71.105986620000067, 32.094464777000042],
            [71.107005921000052, 32.097593160000031],
            [71.10845448300006, 32.102049986000054],
            [71.109046890000059, 32.103872663000061],
            [71.109046945000046, 32.103872831000047],
            [71.109047000000032, 32.103873000000078],
            [71.106894338000075, 32.116789619000031],
            [71.106866460000049, 32.116956724000033],
            [71.10684013100007, 32.117114549000064],
            [71.106695071000047, 32.117984071000024],
            [71.105734000000041, 32.123752000000025],
            [71.109856627000056, 32.140839391000043],
            [71.10985957500003, 32.140851612000063],
            [71.109862509000038, 32.140863774000024],
            [71.112378853000052, 32.14981021400007],
            [71.115673000000072, 32.161522000000048],
            [71.12123852600007, 32.160867377000045],
            [71.126407809000057, 32.160259362000033],
            [71.136389128000076, 32.159085350000055],
            [71.138203000000033, 32.158872000000031],
            [71.14357423000007, 32.161443509000037],
            [71.15479354100006, 32.166814819000024],
            [71.15723188100003, 32.17007413500005],
            [71.162613775000068, 32.17717852100003],
            [71.171335000000056, 32.188691000000063],
            [71.173108090000028, 32.193250972000044],
            [71.184219000000041, 32.190327000000025],
            [71.195789920000038, 32.181932012000061],
            [71.19763253900004, 32.180602343000032],
            [71.202134000000058, 32.177354000000037],
            [71.218737087000079, 32.170832303000054],
            [71.225610000000074, 32.174265000000048],
            [71.22931600000004, 32.189091000000076],
            [71.235494000000074, 32.195887000000027],
            [71.250321000000042, 32.190327000000025],
            [71.26319075400005, 32.184387298000047],
            [71.266383000000076, 32.182914000000039],
            [71.286151000000075, 32.178589000000045],
            [71.301595000000077, 32.17117600000006],
            [71.321364000000074, 32.166851000000065],
            [71.325151679000044, 32.174017847000073],
            [71.330102216000057, 32.184328315000073],
            [71.331248000000073, 32.186620000000062],
            [71.340894700000035, 32.192045964000044],
            [71.34472872300006, 32.19216909000005],
            [71.360900744000048, 32.192123007000077],
            [71.363526714000045, 32.192084563000037],
            [71.36728983900008, 32.191947784000035],
            [71.372299497000029, 32.19176569800004],
            [71.39487900000006, 32.190945000000056],
            [71.401933014000065, 32.189317128000027],
            [71.412318482000046, 32.186920448000023],
            [71.418972000000053, 32.185385000000053],
            [71.434417820000078, 32.184436583000036],
            [71.436567198000034, 32.184324176000075],
            [71.449242255000058, 32.18353103100003],
            [71.462477984000031, 32.186256287000049],
            [71.466566155000066, 32.187098083000024],
            [71.470247000000029, 32.187856000000068],
            [71.478278000000046, 32.200829000000056],
            [71.502371000000039, 32.205153000000053],
            [71.504908927000031, 32.204903410000043],
            [71.524844375000043, 32.202942876000066],
            [71.540055000000052, 32.20144700000003],
            [71.576585783000041, 32.18780827300003],
            [71.579665399000078, 32.186658502000057],
            [71.586387000000059, 32.184149000000048],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "94",
      properties: { name: "Chakwal", count: 1384 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.55690690800003, 33.227471625000078],
            [72.559231325000042, 33.226733393000075],
            [72.560249707000082, 33.226897442000052],
            [72.56028225600005, 33.226887030000057],
            [72.560500049000041, 33.226330448000056],
            [72.570493088000035, 33.223156670000037],
            [72.578226121000057, 33.217368577000059],
            [72.582854703000066, 33.213904133000028],
            [72.597689400000036, 33.202183522000041],
            [72.620529088000069, 33.202189205000025],
            [72.62955482700005, 33.198756732000049],
            [72.629622177000044, 33.199250634000066],
            [72.629796268000064, 33.199184535000029],
            [72.629796000000056, 33.199177000000077],
            [72.634129987000051, 33.197525582000026],
            [72.634743000000071, 33.197292000000061],
            [72.637383299000078, 33.198423586000047],
            [72.637704899000084, 33.198561418000054],
            [72.641164337000077, 33.200044072000026],
            [72.647716000000059, 33.202852000000064],
            [72.651932212000077, 33.205135699000039],
            [72.655401803000075, 33.206989342000043],
            [72.662461703000076, 33.210839213000042],
            [72.66254200000003, 33.210883000000024],
            [72.668676422000033, 33.210883000000024],
            [72.672808562000057, 33.210883000000024],
            [72.678912294000042, 33.210883000000024],
            [72.680185962000053, 33.210883000000024],
            [72.681076000000076, 33.210883000000024],
            [72.692441146000078, 33.211965374000044],
            [72.701173159000064, 33.212796978000029],
            [72.705844499000079, 33.213239299000065],
            [72.71373125000008, 33.209001385000079],
            [72.713990113000079, 33.208662799000024],
            [72.723635513000033, 33.196044432000065],
            [72.724606098000038, 33.195876062000025],
            [72.724606080000058, 33.195875993000072],
            [72.724606134000055, 33.195875984000054],
            [72.737840900000037, 33.193579395000029],
            [72.74436074700003, 33.190715974000057],
            [72.744509109000035, 33.190650816000073],
            [72.755653729000073, 33.18575626300003],
            [72.768740292000075, 33.180008838000049],
            [72.777301413000032, 33.177085444000056],
            [72.777020136000033, 33.176958416000048],
            [72.777184021000039, 33.176902454000071],
            [72.791568633000054, 33.171990494000056],
            [72.79669504900005, 33.167866229000026],
            [72.796695094000029, 33.167866193000066],
            [72.797207542000081, 33.16745392200005],
            [72.810745195000038, 33.156562712000039],
            [72.811134315000061, 33.156775229000061],
            [72.811465640000051, 33.156956182000044],
            [72.811729412000034, 33.156743454000036],
            [72.812007723000079, 33.15651900000006],
            [72.81239742300005, 33.15673157100008],
            [72.812661000000048, 33.15651900000006],
            [72.825058213000034, 33.163281337000058],
            [72.832580312000061, 33.167384435000031],
            [72.836529647000077, 33.165985767000052],
            [72.836529682000048, 33.165985765000073],
            [72.836545404000049, 33.165980187000059],
            [72.836545450000074, 33.165980171000058],
            [72.850909977000072, 33.160892974000035],
            [72.851591940000048, 33.16105903700003],
            [72.852198000000044, 33.160844000000054],
            [72.855518094000047, 33.161651583000037],
            [72.859726306000084, 33.162675192000052],
            [72.871443555000042, 33.165525511000055],
            [72.872844436000037, 33.165866277000077],
            [72.874612485000057, 33.166296358000068],
            [72.880542343000059, 33.16522013000008],
            [72.881324482000082, 33.165263759000027],
            [72.88185100000004, 33.165168000000051],
            [72.892971000000045, 33.165786000000026],
            [72.90312437700004, 33.171108812000057],
            [72.905629697000052, 33.172416895000026],
            [72.905944000000034, 33.172581000000037],
            [72.915257086000054, 33.170623167000031],
            [72.920213453000031, 33.169554359000074],
            [72.923511075000079, 33.168854912000029],
            [72.925337986000045, 33.168461548000039],
            [72.925422586000082, 33.168443332000038],
            [72.925429093000048, 33.168458211000029],
            [72.926330000000064, 33.16825700000004],
            [72.92706301100003, 33.166546685000071],
            [72.927332879000062, 33.165917010000044],
            [72.928228252000054, 33.163827860000026],
            [72.929775582000048, 33.160210982000024],
            [72.930766407000078, 33.157894931000044],
            [72.930847072000063, 33.157706378000057],
            [72.931890000000067, 33.155284000000051],
            [72.932295225000075, 33.146777550000024],
            [72.932508000000041, 33.142311000000063],
            [72.933101000000079, 33.141052000000059],
            [72.937061621000055, 33.132634429000063],
            [72.935847454000054, 33.132656535000024],
            [72.935878271000036, 33.132591424000054],
            [72.93620723500004, 33.131896286000028],
            [72.943363997000063, 33.119295976000046],
            [72.944272048000073, 33.117697206000059],
            [72.949405064000075, 33.116813811000043],
            [72.949481022000043, 33.116800739000041],
            [72.962192352000045, 33.114613216000066],
            [72.989379533000033, 33.111530911000045],
            [72.993331515000079, 33.111126601000024],
            [73.004310116000056, 33.110003407000079],
            [73.013477505000083, 33.109065514000065],
            [73.017746677000048, 33.097941062000075],
            [73.017746653000074, 33.097941037000055],
            [73.018453488000034, 33.096099172000038],
            [73.019222338000077, 33.094380439000076],
            [73.019675967000069, 33.095837502000052],
            [73.019691203000036, 33.095797583000035],
            [73.02480980200005, 33.084298444000069],
            [73.027027000000032, 33.079298000000051],
            [73.027920685000083, 33.07215141100005],
            [73.027997654000046, 33.071535907000055],
            [73.028263000000038, 33.069414000000052],
            [73.033221236000031, 33.068311575000052],
            [73.033822000000043, 33.068178000000046],
            [73.035909524000033, 33.068178000000046],
            [73.043089000000066, 33.068178000000046],
            [73.048603566000054, 33.068913513000041],
            [73.052356000000032, 33.069414000000052],
            [73.060266065000064, 33.072803767000039],
            [73.061004000000082, 33.073120000000074],
            [73.077684000000033, 33.085476000000028],
            [73.079334008000046, 33.087882183000033],
            [73.089808307000055, 33.103156702000035],
            [73.092511000000059, 33.107098000000065],
            [73.094655468000042, 33.107232146000058],
            [73.101344190000077, 33.107650947000025],
            [73.105548941000052, 33.101672292000046],
            [73.105908261000081, 33.091127898000025],
            [73.106222374000083, 33.081910095000069],
            [73.120446614000059, 33.076971808000053],
            [73.120704474000036, 33.077119362000076],
            [73.121545000000083, 33.076827000000037],
            [73.130195000000072, 33.081769000000065],
            [73.131914609000034, 33.089683580000042],
            [73.133283000000063, 33.095978000000059],
            [73.139155275000064, 33.096778627000049],
            [73.140033703000029, 33.096898392000071],
            [73.146874000000082, 33.097831000000042],
            [73.153581885000051, 33.097582664000072],
            [73.155368657000054, 33.097516515000052],
            [73.160888672000056, 33.097290039000029],
            [73.179065435000041, 33.096745824000038],
            [73.181274414000029, 33.096679687000062],
            [73.185043179000047, 33.096455652000031],
            [73.193462876000069, 33.095955143000026],
            [73.197893531000034, 33.095734253000046],
            [73.204559944000039, 33.095408904000067],
            [73.205058000000065, 33.095012000000054],
            [73.203925255000058, 33.095165701000042],
            [73.203043948000072, 33.085153222000031],
            [73.198927853000043, 33.079990388000056],
            [73.198927824000066, 33.079990352000038],
            [73.195832462000055, 33.076108016000035],
            [73.193970230000048, 33.07377232500005],
            [73.19486508500006, 33.067210270000032],
            [73.195687527000075, 33.061179564000042],
            [73.195832426000038, 33.060117035000076],
            [73.195772580000039, 33.059953336000035],
            [73.195768242000042, 33.059941471000059],
            [73.195748045000073, 33.059953336000035],
            [73.194477388000053, 33.056477670000049],
            [73.194497363000039, 33.05646519700008],
            [73.192670055000065, 33.051466695000045],
            [73.193115989000034, 33.04946308600006],
            [73.193116135000082, 33.049462430000062],
            [73.193513412000073, 33.047677436000072],
            [73.196823977000065, 33.03280281900004],
            [73.197674527000061, 33.032047755000065],
            [73.198265566000032, 33.032202201000075],
            [73.198872504000065, 33.031661650000046],
            [73.20553787700004, 33.025725334000072],
            [73.22691100000003, 33.006690000000049],
            [73.225381475000063, 33.003875658000027],
            [73.21552900000006, 32.98574700000006],
            [73.214908024000067, 32.984504972000025],
            [73.207335000000057, 32.969358000000057],
            [73.204737912000041, 32.967066254000031],
            [73.199595000000045, 32.962528000000077],
            [73.192832355000064, 32.955524514000047],
            [73.187242814000058, 32.949735911000062],
            [73.186847000000057, 32.949326000000042],
            [73.181063187000063, 32.93814373500004],
            [73.180018000000075, 32.936123000000066],
            [73.176431642000068, 32.926559204000057],
            [73.173998687000051, 32.920071205000056],
            [73.173189000000036, 32.917912000000058],
            [73.170225936000065, 32.906598161000034],
            [73.170225927000047, 32.906598125000073],
            [73.168181000000061, 32.898790000000076],
            [73.155889000000059, 32.896514000000025],
            [73.155889000000059, 32.896491596000033],
            [73.155889000000059, 32.886830871000029],
            [73.155889000000059, 32.88194500000003],
            [73.155434000000071, 32.864645000000053],
            [73.152702000000033, 32.847800000000063],
            [73.153775988000064, 32.844935836000047],
            [73.153845415000035, 32.84493580000003],
            [73.159365677000039, 32.847163378000062],
            [73.160638299000084, 32.847714065000048],
            [73.162468381000053, 32.847318474000076],
            [73.164282987000036, 32.846324767000056],
            [73.16606383900006, 32.846521620000033],
            [73.167655018000062, 32.847558157000037],
            [73.16954744800006, 32.84955042200005],
            [73.171785469000042, 32.851053877000027],
            [73.174018319000083, 32.85127847900003],
            [73.177025310000033, 32.85155184000007],
            [73.181883045000063, 32.850493667000023],
            [73.184936932000085, 32.849747386000047],
            [73.187654397000074, 32.848810108000066],
            [73.190075638000053, 32.847765120000076],
            [73.192499744000031, 32.847142443000052],
            [73.194420951000041, 32.846659849000048],
            [73.195462115000055, 32.847834808000073],
            [73.195600555000055, 32.849884222000071],
            [73.196154174000071, 32.852158577000068],
            [73.197392730000047, 32.853882106000071],
            [73.199968105000039, 32.856877972000063],
            [73.202027604000079, 32.858799160000046],
            [73.204233289000058, 32.860078896000061],
            [73.207212025000047, 32.861634380000055],
            [73.208568281000055, 32.860586260000048],
            [73.211474986000042, 32.85947617000005],
            [73.213614757000073, 32.858563864000075],
            [73.216028100000074, 32.85776327700006],
            [73.217615587000068, 32.856991477000065],
            [73.218005449000032, 32.856801935000078],
            [73.219288464000044, 32.855985555000075],
            [73.220763974000079, 32.855417433000071],
            [73.223267854000085, 32.855428000000074],
            [73.225881082000058, 32.855266269000026],
            [73.22750663000005, 32.855036226000038],
            [73.232608338000034, 32.853459460000067],
            [73.23599538700006, 32.851973187000056],
            [73.238733985000067, 32.850148961000059],
            [73.243917814000042, 32.845560254000077],
            [73.245133800000076, 32.844479220000039],
            [73.247974026000065, 32.841954206000025],
            [73.251870546000077, 32.839495174000035],
            [73.252923344000067, 32.838312631000065],
            [73.255029054000033, 32.835789632000058],
            [73.257053084000063, 32.833389897000075],
            [73.261981883000033, 32.828232401000037],
            [73.266803029000073, 32.823394004000079],
            [73.270668933000081, 32.818985667000049],
            [73.273228732000064, 32.81600250200006],
            [73.276484005000043, 32.813679604000072],
            [73.281043982000028, 32.811804146000043],
            [73.281913831000054, 32.80859533000006],
            [73.283460210000044, 32.806535503000077],
            [73.28339408100004, 32.806516467000051],
            [73.28337434000008, 32.798103038000079],
            [73.283154918000037, 32.798443975000055],
            [73.279543794000062, 32.799381800000049],
            [73.275802760000033, 32.800353363000056],
            [73.272276304000059, 32.798980886000038],
            [73.269385384000032, 32.792958652000038],
            [73.26711411000008, 32.788775024000074],
            [73.263582020000058, 32.779368005000038],
            [73.249665910000033, 32.785019183000031],
            [73.249945000000082, 32.783484000000044],
            [73.250585000000058, 32.779965000000061],
            [73.254961641000079, 32.766106973000035],
            [73.256049000000075, 32.762664000000029],
            [73.264212883000084, 32.745880914000054],
            [73.264243000000079, 32.74581900000004],
            [73.264212883000084, 32.74572859500006],
            [73.263130000000046, 32.742478000000062],
            [73.260601000000065, 32.734893000000056],
            [73.257809886000075, 32.735960111000054],
            [73.245122000000038, 32.740811000000065],
            [73.245108541000036, 32.740810746000079],
            [73.245086891000028, 32.740819016000046],
            [73.220935395000083, 32.740365524000026],
            [73.220353723000073, 32.738932790000035],
            [73.220353681000063, 32.738932687000045],
            [73.21976797800005, 32.737490023000078],
            [73.216864631000078, 32.730338700000061],
            [73.212552598000059, 32.729735055000049],
            [73.194108131000064, 32.727153000000044],
            [73.187933036000061, 32.727153000000044],
            [73.183346187000041, 32.727153000000044],
            [73.183346346000064, 32.727151185000025],
            [73.183215662000066, 32.727151197000069],
            [73.183215540000049, 32.727153000000044],
            [73.175693028000069, 32.727153000000044],
            [73.16681600000004, 32.727153000000044],
            [73.166813782000077, 32.727152723000074],
            [73.16039796900003, 32.726350801000024],
            [73.148416370000064, 32.724853204000055],
            [73.139830822000079, 32.723780084000055],
            [73.139759481000056, 32.723771167000052],
            [73.137678000000051, 32.72351100000003],
            [73.124931000000061, 32.709397000000024],
            [73.124877416000061, 32.709385094000027],
            [73.115802810000048, 32.707368761000055],
            [73.096249000000057, 32.703024000000028],
            [73.096246172000065, 32.703017214000056],
            [73.092431462000036, 32.693862076000073],
            [73.09169600000007, 32.692097000000047],
            [73.092408277000061, 32.678199774000063],
            [73.092606000000046, 32.674342000000024],
            [73.087598000000071, 32.668423000000075],
            [73.087038288000031, 32.668681341000024],
            [73.069843000000049, 32.676618000000076],
            [73.047079000000053, 32.689366000000064],
            [73.037063000000046, 32.689366000000064],
            [73.024771000000044, 32.710763000000043],
            [73.024674105000031, 32.710680353000043],
            [73.024628757000073, 32.710758954000028],
            [73.013472879000062, 32.701220030000059],
            [73.01347254500007, 32.70121976400003],
            [73.009172855000031, 32.697543267000071],
            [73.004354679000073, 32.695452984000042],
            [72.995520105000082, 32.691620248000049],
            [72.994677650000028, 32.690523427000073],
            [72.993280153000057, 32.677034743000036],
            [72.985593597000047, 32.667507564000061],
            [72.970594000000062, 32.664781000000062],
            [72.962187406000055, 32.66521184000004],
            [72.952838000000042, 32.665691000000038],
            [72.939180000000079, 32.66796800000003],
            [72.939170665000063, 32.667911983000067],
            [72.939085337000051, 32.667926220000027],
            [72.939036627000064, 32.667629375000047],
            [72.936851919000048, 32.654315384000029],
            [72.930916280000076, 32.654929044000028],
            [72.923701000000051, 32.655675000000031],
            [72.915132719000042, 32.654948988000058],
            [72.896840000000054, 32.653399000000036],
            [72.877718000000073, 32.653399000000036],
            [72.877683321000063, 32.653348713000071],
            [72.87705515600004, 32.652437821000035],
            [72.877035963000083, 32.652409990000024],
            [72.868613000000039, 32.64019600000006],
            [72.868591730000048, 32.640210180000054],
            [72.856143919000033, 32.648508974000038],
            [72.852223000000038, 32.651123000000041],
            [72.84781932900006, 32.652423107000061],
            [72.816237646000047, 32.661747047000063],
            [72.80442000000005, 32.66523600000005],
            [72.795365281000045, 32.675888163000025],
            [72.79343736900006, 32.678156200000046],
            [72.788940000000082, 32.683447000000058],
            [72.769364000000053, 32.695284000000072],
            [72.769177579000029, 32.695252934000052],
            [72.755706000000032, 32.693008000000077],
            [72.741137000000037, 32.685723000000053],
            [72.738498918000062, 32.682464593000077],
            [72.733397000000082, 32.676163000000031],
            [72.740352271000063, 32.65808051700003],
            [72.741422726000053, 32.655297522000069],
            [72.742503000000056, 32.65248900000006],
            [72.743221963000053, 32.649756856000067],
            [72.749332000000038, 32.626538000000039],
            [72.743221963000053, 32.617552931000034],
            [72.742277112000068, 32.616163487000051],
            [72.742271269000071, 32.616154895000079],
            [72.742268971000044, 32.616151516000059],
            [72.742263106000053, 32.616142891000038],
            [72.741592000000082, 32.61515600000007],
            [72.741538373000083, 32.61514022800003],
            [72.718373000000042, 32.608327000000031],
            [72.707048076000035, 32.606357495000054],
            [72.702410266000072, 32.605550939000068],
            [72.697431000000051, 32.604685000000075],
            [72.67284600000005, 32.598311000000024],
            [72.650993000000028, 32.59193700000003],
            [72.650993000000028, 32.591876194000065],
            [72.650993000000028, 32.59160600000007],
            [72.650903410000069, 32.591611270000044],
            [72.650903583000058, 32.591518562000033],
            [72.648317205000069, 32.591671243000064],
            [72.626135616000056, 32.591930743000034],
            [72.604711508000037, 32.596782737000069],
            [72.595062393000035, 32.602485092000052],
            [72.58786682200008, 32.60673754000004],
            [72.587642229000039, 32.607176262000053],
            [72.581727501000046, 32.618730148000054],
            [72.575100651000071, 32.628024315000062],
            [72.574690960000055, 32.628598907000026],
            [72.570993933000068, 32.633784066000032],
            [72.567152691000047, 32.64660002100004],
            [72.566635287000054, 32.648326402000066],
            [72.56220815000006, 32.659793763000039],
            [72.56151748800005, 32.661582858000031],
            [72.549781000000053, 32.66133300000007],
            [72.549760977000062, 32.661343012000032],
            [72.549534580000056, 32.661338221000051],
            [72.541619424000032, 32.66529612100004],
            [72.539329383000052, 32.666441235000036],
            [72.542807900000071, 32.674127104000036],
            [72.544408769000029, 32.677664267000068],
            [72.544081923000078, 32.67967765800006],
            [72.542105297000035, 32.691853470000069],
            [72.542095302000064, 32.691915036000069],
            [72.542090059000031, 32.691947334000076],
            [72.541940616000034, 32.691915036000069],
            [72.53841516600005, 32.691153109000027],
            [72.53266276800008, 32.689908840000044],
            [72.514077186000065, 32.672523582000053],
            [72.509760852000056, 32.668486012000074],
            [72.509660129000054, 32.668464280000023],
            [72.509555277000061, 32.668367921000026],
            [72.500850624000066, 32.666490775000057],
            [72.497026000000062, 32.665666000000044],
            [72.490447113000073, 32.66511558600007],
            [72.484029000000078, 32.665666000000044],
            [72.464660000000038, 32.651139000000057],
            [72.458176926000078, 32.645231368000054],
            [72.449369000000047, 32.638142000000073],
            [72.439939000000038, 32.633044000000041],
            [72.433568000000037, 32.616989000000046],
            [72.419941609000034, 32.613755496000067],
            [72.419064472000059, 32.613547354000048],
            [72.419046852000065, 32.613566668000033],
            [72.418348710000032, 32.613400908000074],
            [72.399202821000074, 32.635086860000058],
            [72.383636705000072, 32.649628940000071],
            [72.369505353000079, 32.663948623000067],
            [72.365008301000046, 32.668505608000032],
            [72.352765936000083, 32.676668298000038],
            [72.338791699000069, 32.694187150000062],
            [72.326904614000057, 32.693039423000073],
            [72.30206400000003, 32.690641000000028],
            [72.278873000000033, 32.690131000000065],
            [72.278659460000028, 32.690194655000028],
            [72.278603635000081, 32.690193433000047],
            [72.25232335700008, 32.698034861000053],
            [72.239308019000077, 32.698869006000052],
            [72.239307457000052, 32.698869042000069],
            [72.212611000000038, 32.700580000000059],
            [72.200123000000076, 32.702109000000064],
            [72.186361000000034, 32.702874000000065],
            [72.178182989000049, 32.703044472000045],
            [72.177377454000066, 32.703061264000041],
            [72.174128000000053, 32.703129000000047],
            [72.174054573000035, 32.703207019000047],
            [72.17388444200003, 32.703210588000047],
            [72.165791431000059, 32.711809650000077],
            [72.151192000000037, 32.713068000000078],
            [72.151139163000039, 32.712807593000036],
            [72.147624000000064, 32.695483000000024],
            [72.159347000000082, 32.674585000000036],
            [72.157818000000077, 32.658530000000042],
            [72.125197000000071, 32.633554000000061],
            [72.123360391000062, 32.63313163600003],
            [72.09971100000007, 32.627693000000079],
            [72.071678000000077, 32.611382000000049],
            [72.065040641000053, 32.607315917000051],
            [72.043389000000047, 32.594052000000033],
            [72.017394000000081, 32.589720000000057],
            [72.002358000000072, 32.585387000000026],
            [71.988086000000067, 32.580545001000075],
            [71.981460000000084, 32.570351000000073],
            [71.981553754000061, 32.567540044000054],
            [71.981970000000047, 32.555060000000026],
            [71.964660748000028, 32.555060000000026],
            [71.961072000000058, 32.555060000000026],
            [71.955272841000067, 32.545283996000023],
            [71.952152000000069, 32.540023000000076],
            [71.93966400000005, 32.53467100000006],
            [71.933808441000053, 32.538249432000043],
            [71.933777829000064, 32.53827353500003],
            [71.930490582000061, 32.540285441000037],
            [71.929902463000076, 32.541090632000078],
            [71.930248551000034, 32.54105239200004],
            [71.930288375000032, 32.541307559000074],
            [71.931711160000077, 32.550423953000063],
            [71.931721000000039, 32.550487000000032],
            [71.940987000000064, 32.559136000000024],
            [71.936663000000067, 32.573962000000051],
            [71.936652664000064, 32.573964297000032],
            [71.936642998000082, 32.573997423000037],
            [71.925536106000038, 32.576470918000041],
            [71.923072000000047, 32.590024000000028],
            [71.926779000000067, 32.607939000000044],
            [71.926742638000064, 32.607981781000035],
            [71.916277000000036, 32.620295000000056],
            [71.910717000000034, 32.628944000000047],
            [71.910718311000039, 32.628970208000055],
            [71.911335000000065, 32.64129900000006],
            [71.911292871000057, 32.641336944000045],
            [71.91044000800008, 32.642105077000053],
            [71.899551430000031, 32.651911903000041],
            [71.898979000000054, 32.661068000000057],
            [71.914423000000056, 32.667863000000068],
            [71.913806000000079, 32.684543000000076],
            [71.913745944000084, 32.684586675000048],
            [71.91374367700007, 32.684647194000036],
            [71.893430334000072, 32.699434172000053],
            [71.895890000000065, 32.713578000000041],
            [71.89585439800004, 32.713691916000073],
            [71.89585156000004, 32.713700996000057],
            [71.892801000000077, 32.72346200000004],
            [71.892371432000061, 32.728904271000033],
            [71.892061202000036, 32.732834635000074],
            [71.891197134000038, 32.743781673000058],
            [71.890948000000037, 32.746938000000057],
            [71.894037000000083, 32.758058000000062],
            [71.910998013000039, 32.76849522200007],
            [71.934192000000053, 32.782768000000033],
            [71.973112000000071, 32.803773000000035],
            [71.96952660900007, 32.825284701000044],
            [71.967552000000069, 32.837132000000054],
            [71.962079968000069, 32.841185503000077],
            [71.950872000000061, 32.849488000000065],
            [71.95081663600007, 32.849503098000071],
            [71.950685852000049, 32.849600018000046],
            [71.946063869000056, 32.850861366000061],
            [71.916875351000044, 32.85882674100003],
            [71.910717000000034, 32.882847000000027],
            [71.910562106000043, 32.882934547000048],
            [71.910558654000056, 32.882947997000031],
            [71.896504993000065, 32.890895036000074],
            [71.89645792400006, 32.891161729000032],
            [71.892801000000077, 32.911882000000048],
            [71.885388000000034, 32.932269000000076],
            [71.885388000000034, 32.934862414000065],
            [71.885388000000034, 32.936623417000078],
            [71.885388000000034, 32.970571000000064],
            [71.877357000000075, 32.983544000000052],
            [71.860060000000033, 32.992192000000045],
            [71.84646900000007, 32.995281000000034],
            [71.833495000000084, 32.999606000000028],
            [71.833456267000031, 32.999604893000026],
            [71.811874000000046, 32.998988000000054],
            [71.787163000000078, 33.002695000000074],
            [71.787141690000055, 33.002705977000062],
            [71.778517945000033, 33.007148082000072],
            [71.778470469000069, 33.00717067000005],
            [71.76662900000008, 33.012804500000072],
            [71.766628694000076, 33.012805365000077],
            [71.782158250000066, 33.02028970300006],
            [71.793291345000057, 33.025766970000063],
            [71.825360232000037, 33.041591609000079],
            [71.858305853000047, 33.054312454000069],
            [71.861646636000046, 33.055602386000032],
            [71.868528266000055, 33.058259496000062],
            [71.868679927000073, 33.058275858000059],
            [71.908618198000056, 33.062584682000079],
            [71.908756127000061, 33.062670151000077],
            [71.908908058000065, 33.062764297000058],
            [71.93697682700008, 33.080157418000056],
            [71.936986261000072, 33.080152886000064],
            [71.936997164000047, 33.080159662000028],
            [71.936987907000059, 33.080164290000027],
            [71.942811728000038, 33.083773067000038],
            [71.945374377000064, 33.085361033000027],
            [71.94746536100007, 33.086656728000037],
            [71.970760427000073, 33.111467589000029],
            [71.976074219000054, 33.11749267600004],
            [71.986580508000031, 33.119770263000078],
            [71.993088954000029, 33.121218211000041],
            [71.993275900000071, 33.121221711000032],
            [71.993530273000033, 33.121276855000076],
            [71.997465433000059, 33.121519019000061],
            [71.997465469000076, 33.121518217000073],
            [71.997471690000054, 33.12137961600007],
            [72.022671929000069, 33.121846468000058],
            [72.026857000000064, 33.121924000000035],
            [72.028595381000059, 33.122010947000035],
            [72.03836072200005, 33.122501791000047],
            [72.043117363000079, 33.122842509000066],
            [72.054683695000051, 33.123732742000072],
            [72.054687500000057, 33.123718262000068],
            [72.070867285000077, 33.125086405000047],
            [72.071289063000052, 33.125122070000032],
            [72.080688477000081, 33.122070313000052],
            [72.086730957000043, 33.120117188000052],
            [72.090881348000039, 33.118896484000061],
            [72.095886230000076, 33.117309570000032],
            [72.100280762000068, 33.115722656000059],
            [72.104125977000081, 33.114501953000058],
            [72.11132808900004, 33.114364334000072],
            [72.113630116000081, 33.114320346000056],
            [72.113612695000029, 33.114177396000059],
            [72.12701282300003, 33.113812553000059],
            [72.148552738000035, 33.113226104000034],
            [72.152988504000064, 33.112487757000054],
            [72.154000701000029, 33.112319274000072],
            [72.154113734000077, 33.112300459000039],
            [72.15983046100007, 33.111348892000024],
            [72.159839783000052, 33.111577806000071],
            [72.159855872000037, 33.111972925000032],
            [72.166425649000075, 33.110879402000023],
            [72.17027261800007, 33.109869238000044],
            [72.171508789000029, 33.10968017600004],
            [72.177310257000045, 33.112671558000045],
            [72.183153000000061, 33.115747000000056],
            [72.193095235000044, 33.125689235000038],
            [72.193655000000035, 33.12624900000003],
            [72.198659938000048, 33.130181080000057],
            [72.202304000000083, 33.133044000000041],
            [72.202475152000034, 33.134754830000077],
            [72.202930511000034, 33.139306576000024],
            [72.203504751000082, 33.142991621000078],
            [72.204508590000046, 33.14943351200003],
            [72.205285371000059, 33.154418318000069],
            [72.206279358000074, 33.159554811000078],
            [72.207242764000057, 33.164638145000026],
            [72.207242800000074, 33.16463811400007],
            [72.207868574000031, 33.16410266500003],
            [72.210693323000044, 33.162009959000045],
            [72.215243042000054, 33.158639314000027],
            [72.219073450000053, 33.155808225000044],
            [72.222198057000071, 33.153498800000079],
            [72.228365372000042, 33.148940482000057],
            [72.235269483000081, 33.143837591000079],
            [72.235664000000043, 33.143546000000072],
            [72.241900342000065, 33.142628838000064],
            [72.251830172000041, 33.141168486000026],
            [72.251932383000053, 33.140978526000026],
            [72.255171351000058, 33.140502894000065],
            [72.255882051000071, 33.140398530000027],
            [72.256593954000039, 33.140502894000065],
            [72.256656949000046, 33.140512129000058],
            [72.257607947000054, 33.140651544000036],
            [72.279575777000048, 33.143871993000062],
            [72.281175808000057, 33.144106555000064],
            [72.281312097000068, 33.14406376200003],
            [72.300081293000062, 33.138170458000047],
            [72.302637121000032, 33.137367964000077],
            [72.302781440000047, 33.137322650000044],
            [72.302988312000082, 33.137367964000077],
            [72.303359466000074, 33.137449263000065],
            [72.32252297000008, 33.141646918000049],
            [72.33620802300004, 33.143360407000046],
            [72.352138988000036, 33.145355105000078],
            [72.356097611000052, 33.147641520000036],
            [72.363254425000036, 33.151775292000025],
            [72.363294755000084, 33.15179858700003],
            [72.368171271000051, 33.154615260000071],
            [72.369014374000074, 33.154615444000058],
            [72.398410874000035, 33.154621864000035],
            [72.399289820000035, 33.154666000000077],
            [72.410365848000083, 33.155222180000067],
            [72.410369048000064, 33.155222341000069],
            [72.411440907000042, 33.155276164000043],
            [72.421969649000062, 33.155804861000036],
            [72.424474124000028, 33.155930623000074],
            [72.442496554000058, 33.156835614000045],
            [72.447516433000033, 33.157087686000068],
            [72.44777965000003, 33.157100903000071],
            [72.471848264000073, 33.15834084800008],
            [72.477432590000035, 33.159704229000056],
            [72.484073186000046, 33.161325531000045],
            [72.497144467000055, 33.164516884000079],
            [72.498182321000058, 33.165903714000024],
            [72.49818186300007, 33.165904393000062],
            [72.506381986000065, 33.176861643000052],
            [72.508766202000061, 33.187839891000067],
            [72.508970356000077, 33.188779929000077],
            [72.51067047600003, 33.196608224000045],
            [72.527308036000079, 33.212038949000032],
            [72.529556451000076, 33.213706565000052],
            [72.543951366000044, 33.224383057000068],
            [72.55690690800003, 33.227471625000078],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "95",
      properties: { name: "Chiniot", count: 1081 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.968447291000075, 31.960553885000024],
            [72.977976256000034, 31.955968519000066],
            [72.981902160000061, 31.957452702000069],
            [72.986177594000083, 31.959028046000071],
            [72.988962000000072, 31.960054000000071],
            [72.992068000000074, 31.960217000000057],
            [72.999960000000044, 31.96063300000003],
            [73.015587000000039, 31.951951000000065],
            [73.031215000000032, 31.945584000000053],
            [73.035468475000073, 31.943457058000035],
            [73.04014197500004, 31.941120084000033],
            [73.041634000000045, 31.940374000000077],
            [73.046456295000041, 31.93651649800006],
            [73.049946145000035, 31.933724858000062],
            [73.050316000000066, 31.933429000000046],
            [73.061313000000041, 31.929377000000045],
            [73.06133883800004, 31.929382961000044],
            [73.068838000000085, 31.931113000000039],
            [73.071681666000075, 31.928269334000049],
            [73.071732000000054, 31.92821900000007],
            [73.073976292000054, 31.928100938000057],
            [73.074005990000046, 31.928099376000034],
            [73.083915522000041, 31.927578083000071],
            [73.09117868900006, 31.927196002000073],
            [73.091700451000065, 31.927168555000037],
            [73.093128427000067, 31.927093436000064],
            [73.093726000000061, 31.927062000000035],
            [73.102408000000082, 31.927062000000035],
            [73.109805319000031, 31.927062000000035],
            [73.116300000000081, 31.927062000000035],
            [73.116391355000076, 31.926810743000033],
            [73.11701682100005, 31.925090510000075],
            [73.118615000000034, 31.920695000000023],
            [73.118666841000049, 31.920695000000023],
            [73.118676256000072, 31.92066851900006],
            [73.126176256000065, 31.92066851900006],
            [73.126653370000042, 31.920633763000069],
            [73.131934379000029, 31.920249054000067],
            [73.141188000000056, 31.919537000000048],
            [73.141819503000079, 31.919068485000025],
            [73.142711291000069, 31.918247635000057],
            [73.146220548000031, 31.915017523000074],
            [73.150076256000034, 31.911468519000039],
            [73.159274914000036, 31.905910996000046],
            [73.159517235000067, 31.905382282000062],
            [73.159676256000068, 31.905668519000074],
            [73.163576256000056, 31.896668519000059],
            [73.166022497000029, 31.892500849000044],
            [73.16627625600006, 31.892068519000077],
            [73.166123894000066, 31.890163994000034],
            [73.164435789000038, 31.886941295000042],
            [73.164433172000031, 31.886936300000059],
            [73.162991943000065, 31.884184905000041],
            [73.161253695000084, 31.880866481000055],
            [73.160287000000039, 31.87902100000008],
            [73.160287000000039, 31.872471018000056],
            [73.160287000000039, 31.87246970700005],
            [73.160376256000063, 31.87244925400006],
            [73.160376256000063, 31.871168519000037],
            [73.160376256000063, 31.863368519000062],
            [73.159776256000043, 31.855868519000069],
            [73.159776256000043, 31.85548865100003],
            [73.159776256000043, 31.855485750000071],
            [73.159776256000043, 31.848893110000063],
            [73.159776256000043, 31.848868519000064],
            [73.164976256000045, 31.846568519000073],
            [73.170776256000067, 31.843668519000062],
            [73.177752313000042, 31.842087279000054],
            [73.178276256000061, 31.841968519000034],
            [73.179545183000073, 31.840889931000049],
            [73.188276256000051, 31.833468519000064],
            [73.190476256000068, 31.831568519000029],
            [73.191576256000076, 31.826968519000047],
            [73.203776256000083, 31.826968519000047],
            [73.208376256000065, 31.82166851900007],
            [73.213576256000067, 31.814168519000077],
            [73.21397625600008, 31.811968519000061],
            [73.215276256000038, 31.804968519000056],
            [73.218154150000032, 31.802275106000025],
            [73.219662682000035, 31.800882460000025],
            [73.222798000000068, 31.797988000000032],
            [73.223108597000078, 31.79767743900004],
            [73.226617774000033, 31.794168666000076],
            [73.231576256000039, 31.789268519000075],
            [73.228547052000067, 31.783661269000049],
            [73.228546712000082, 31.783660639000061],
            [73.226876256000082, 31.780568519000042],
            [73.21417625600003, 31.77536851900004],
            [73.203176256000063, 31.762668519000044],
            [73.199189648000072, 31.758322218000046],
            [73.189876256000048, 31.748168519000046],
            [73.175591338000061, 31.737474345000066],
            [73.171576256000037, 31.734468519000075],
            [73.150453646000074, 31.718675008000048],
            [73.149776256000052, 31.718168519000074],
            [73.148176256000056, 31.716968519000034],
            [73.142976256000054, 31.709268519000034],
            [73.137176256000032, 31.700768519000064],
            [73.135089125000036, 31.69725834500008],
            [73.126176256000065, 31.682268519000047],
            [73.12347625600006, 31.677468519000058],
            [73.119905593000055, 31.671092336000072],
            [73.115076256000066, 31.662468519000072],
            [73.114076256000033, 31.660768519000044],
            [73.11394269300007, 31.660522194000066],
            [73.108753316000048, 31.650951622000036],
            [73.101876256000082, 31.638268519000064],
            [73.085076256000036, 31.629468519000056],
            [73.084637595000061, 31.628038104000041],
            [73.080476256000054, 31.61446851900007],
            [73.075631634000047, 31.606849250000039],
            [73.071665644000063, 31.600611829000059],
            [73.06947625600003, 31.597168519000036],
            [73.069216898000036, 31.596966796000061],
            [73.067676256000084, 31.595768519000046],
            [73.056112019000068, 31.585317875000044],
            [73.054176256000062, 31.583568519000039],
            [73.049817827000084, 31.579606311000077],
            [73.048676256000078, 31.578568519000044],
            [73.039029846000062, 31.576705764000053],
            [73.03833310400006, 31.576571221000052],
            [73.034129373000042, 31.575730475000057],
            [73.021976256000073, 31.565868519000048],
            [73.014349914000036, 31.566308500000048],
            [73.011576256000069, 31.566468519000068],
            [73.011567495000065, 31.566463138000074],
            [72.998876256000074, 31.558668519000037],
            [72.988976256000058, 31.552568519000033],
            [72.984976256000039, 31.551468519000025],
            [72.984948628000041, 31.551489815000025],
            [72.979388641000071, 31.549987074000057],
            [72.977441006000049, 31.549460671000077],
            [72.977438133000078, 31.549432359000036],
            [72.967576256000029, 31.546768519000068],
            [72.953076256000031, 31.540968519000046],
            [72.951798818000043, 31.539881338000043],
            [72.934276256000032, 31.524968519000026],
            [72.929976256000032, 31.521268519000046],
            [72.926980850000064, 31.519353187000036],
            [72.90917625600008, 31.50796851900003],
            [72.897316931000034, 31.502038857000059],
            [72.89717625600008, 31.501968519000059],
            [72.892976256000054, 31.499868519000074],
            [72.884276256000078, 31.486568519000059],
            [72.874833225000032, 31.47712548800007],
            [72.873876256000074, 31.476168519000055],
            [72.868496556000082, 31.464324138000052],
            [72.861976256000048, 31.449968519000038],
            [72.856812318000038, 31.438484538000068],
            [72.855276256000082, 31.435068519000026],
            [72.844976256000052, 31.42896851900008],
            [72.839676256000075, 31.42576851900003],
            [72.833990098000072, 31.409886491000066],
            [72.833876256000053, 31.409568519000061],
            [72.829076256000064, 31.402268519000074],
            [72.824076256000069, 31.394568519000075],
            [72.822698504000073, 31.388427681000053],
            [72.820576256000038, 31.378968519000068],
            [72.820848900000044, 31.378308116000028],
            [72.825076256000045, 31.368068519000076],
            [72.809900964000065, 31.367398125000079],
            [72.800176256000043, 31.366968519000068],
            [72.782776256000034, 31.373268519000078],
            [72.772593522000079, 31.374462357000027],
            [72.768276256000036, 31.374968519000049],
            [72.762176256000032, 31.374868519000074],
            [72.755776256000047, 31.375268519000031],
            [72.754394155000057, 31.375346384000068],
            [72.748676256000067, 31.375668519000044],
            [72.742376256000057, 31.37726851900004],
            [72.741150331000028, 31.376649104000023],
            [72.732813000000078, 31.372480000000053],
            [72.726480000000038, 31.36218800000006],
            [72.725080388000038, 31.36218800000006],
            [72.714603000000068, 31.36218800000006],
            [72.713799612000059, 31.36218800000006],
            [72.706565424000075, 31.362348760000032],
            [72.705836907000048, 31.363320065000039],
            [72.701980032000051, 31.368462294000039],
            [72.701975498000081, 31.36846833900006],
            [72.701936000000046, 31.368521000000044],
            [72.700369649000038, 31.368129569000075],
            [72.69668703800005, 31.367209181000078],
            [72.69567625600007, 31.366968519000068],
            [72.689282708000064, 31.368396204000078],
            [72.685309000000075, 31.369313000000034],
            [72.678236435000031, 31.370099061000076],
            [72.678183000000047, 31.370105000000024],
            [72.67266268000003, 31.37435172000005],
            [72.667891000000054, 31.378022000000044],
            [72.66710370100003, 31.383532101000071],
            [72.667180985000073, 31.383535417000076],
            [72.667176256000062, 31.38356851900005],
            [72.665476256000034, 31.38696851900005],
            [72.663276256000074, 31.391368519000025],
            [72.662376256000073, 31.394268519000036],
            [72.662348000000065, 31.394293206000043],
            [72.662348000000065, 31.395441000000062],
            [72.658159469000054, 31.398582627000053],
            [72.653067885000041, 31.402401095000073],
            [72.652848000000063, 31.402566000000036],
            [72.645174707000081, 31.403845346000026],
            [72.643347000000063, 31.404150000000072],
            [72.634376220000036, 31.406910173000028],
            [72.633054000000072, 31.407317000000035],
            [72.631702575000077, 31.408668483000042],
            [72.630308624000065, 31.410062493000055],
            [72.628484136000054, 31.411886932000073],
            [72.627512000000081, 31.412859000000026],
            [72.622976411000082, 31.418905816000063],
            [72.622775901000068, 31.419173134000062],
            [72.622761000000082, 31.419193000000064],
            [72.621976220000079, 31.429405061000068],
            [72.621970000000033, 31.429486000000054],
            [72.621970000000033, 31.429567127000041],
            [72.621970000000033, 31.440570000000037],
            [72.621976220000079, 31.440657162000036],
            [72.62217684500007, 31.443468555000038],
            [72.622287784000036, 31.44502316300003],
            [72.622761000000082, 31.451654000000076],
            [72.620231798000077, 31.457134648000078],
            [72.620216184000071, 31.457168483000032],
            [72.618011000000081, 31.461947000000066],
            [72.616942092000045, 31.462659686000052],
            [72.608523550000029, 31.468271967000078],
            [72.608510000000081, 31.468281000000047],
            [72.608385474000045, 31.468281000000047],
            [72.599009000000081, 31.468281000000047],
            [72.597876220000046, 31.468281000000047],
            [72.597843965000038, 31.468281000000047],
            [72.595911265000041, 31.468281000000047],
            [72.594082697000033, 31.468281000000047],
            [72.593976292000036, 31.468281000000047],
            [72.59135099000008, 31.468281000000047],
            [72.59109200000006, 31.468281000000047],
            [72.587471355000048, 31.466729357000077],
            [72.584226473000058, 31.465338620000068],
            [72.581770741000071, 31.46428600400003],
            [72.580065950000062, 31.46355526800005],
            [72.58000700000008, 31.463530000000048],
            [72.57791166100003, 31.460836303000065],
            [72.575121325000055, 31.457248861000039],
            [72.574465000000032, 31.456405000000075],
            [72.568923000000041, 31.454821000000038],
            [72.561031927000045, 31.454821000000038],
            [72.552376256000059, 31.465068519000056],
            [72.546268032000057, 31.475662470000032],
            [72.545962000000031, 31.476198000000068],
            [72.545340020000083, 31.476908867000077],
            [72.54042000000004, 31.482532000000049],
            [72.537801627000078, 31.484823096000071],
            [72.537798147000046, 31.484826140000052],
            [72.537797607000073, 31.484826613000052],
            [72.534086000000059, 31.48807400000004],
            [72.53171100000003, 31.495200000000068],
            [72.52143454000003, 31.503895005000061],
            [72.52141800000004, 31.503909000000078],
            [72.521064460000048, 31.504129977000048],
            [72.515176256000075, 31.507868519000056],
            [72.507976256000063, 31.507868519000056],
            [72.506616488000077, 31.508574552000027],
            [72.502781477000042, 31.510592885000051],
            [72.496501250000051, 31.513898114000028],
            [72.492916000000037, 31.515785000000051],
            [72.491675833000045, 31.51826582700005],
            [72.487922618000084, 31.525772804000042],
            [72.487374000000045, 31.526870000000031],
            [72.487374006000039, 31.529732233000061],
            [72.487374000000045, 31.535569503000033],
            [72.486171144000082, 31.536094386000059],
            [72.481876256000078, 31.53796851900006],
            [72.480452182000079, 31.53796851900006],
            [72.478076256000065, 31.53796851900006],
            [72.473976256000071, 31.53796851900006],
            [72.469021270000042, 31.537410211000065],
            [72.466875177000077, 31.537171689000047],
            [72.466846331000056, 31.537168483000073],
            [72.466788000000065, 31.537162000000023],
            [72.466785840000057, 31.537168483000073],
            [72.465460153000038, 31.541147256000045],
            [72.46521176300007, 31.541892704000077],
            [72.458876256000053, 31.54426851900007],
            [72.458878630000072, 31.544285139000067],
            [72.458871000000045, 31.544288000000051],
            [72.459663000000035, 31.549830000000043],
            [72.45967552500008, 31.549863404000064],
            [72.459751719000053, 31.550066610000044],
            [72.462076256000046, 31.556168519000039],
            [72.462088591000054, 31.55646764200003],
            [72.463621000000046, 31.565665000000024],
            [72.463613070000065, 31.565760251000029],
            [72.462860428000056, 31.574800518000075],
            [72.462830000000054, 31.575166000000024],
            [72.451745000000074, 31.583083000000045],
            [72.451542090000032, 31.583430861000068],
            [72.446203000000082, 31.592584000000045],
            [72.445031226000083, 31.593990208000037],
            [72.434327000000053, 31.606836000000044],
            [72.434327006000046, 31.60954354100005],
            [72.434327000000053, 31.615545000000054],
            [72.434362754000063, 31.615723787000036],
            [72.435200953000049, 31.619915133000063],
            [72.435472966000077, 31.62127531200008],
            [72.436601784000061, 31.626919876000045],
            [72.43677625600003, 31.627368519000072],
            [72.438009458000067, 31.633189234000042],
            [72.439276256000085, 31.639168519000066],
            [72.439076660000069, 31.642461848000039],
            [72.439077000000054, 31.642464000000075],
            [72.439077019000081, 31.65266598900007],
            [72.439077019000081, 31.652668703000074],
            [72.439077000000054, 31.657507000000066],
            [72.439077000000054, 31.663668519000055],
            [72.439077000000054, 31.66370500000005],
            [72.44129029000004, 31.66370500000005],
            [72.446620000000053, 31.66370500000005],
            [72.458709888000044, 31.66370500000005],
            [72.46109000000007, 31.66370500000005],
            [72.47002532700003, 31.666831901000023],
            [72.470049017000065, 31.666840191000063],
            [72.471317719000069, 31.667284171000063],
            [72.472666000000061, 31.667756000000054],
            [72.473198585000034, 31.668229397000061],
            [72.477776256000084, 31.672268519000056],
            [72.477751926000053, 31.672276715000066],
            [72.477776220000067, 31.672298309000041],
            [72.477876000000037, 31.672387000000072],
            [72.484821000000068, 31.679911000000061],
            [72.485522295000067, 31.68061229500006],
            [72.489372158000037, 31.684462158000031],
            [72.489452000000028, 31.684542000000079],
            [72.498713000000066, 31.684542000000079],
            [72.498739519000083, 31.684568519000038],
            [72.498820865000084, 31.68464986500004],
            [72.503166975000056, 31.688995975000068],
            [72.503922000000046, 31.689751000000058],
            [72.504030253000053, 31.690021656000056],
            [72.506191084000079, 31.695424199000058],
            [72.506237000000056, 31.695539000000053],
            [72.509710000000041, 31.701327000000049],
            [72.510929623000038, 31.700716925000052],
            [72.512025000000051, 31.700169000000074],
            [72.521865000000048, 31.705957000000069],
            [72.521863142000029, 31.70596226300006],
            [72.521876256000041, 31.705968519000066],
            [72.521715564000033, 31.706441667000036],
            [72.520076256000038, 31.711268519000043],
            [72.518476256000042, 31.715768519000051],
            [72.51957625600005, 31.724468519000027],
            [72.520233200000064, 31.72512546300004],
            [72.520213200000057, 31.72514220100004],
            [72.526496000000066, 31.731425000000058],
            [72.526537527000073, 31.73142979000005],
            [72.535085064000043, 31.732415805000073],
            [72.541545000000042, 31.733161000000052],
            [72.541614791000029, 31.733161000000052],
            [72.545676256000036, 31.732368519000033],
            [72.554376256000069, 31.73316851900006],
            [72.55667625600006, 31.741268519000073],
            [72.554876256000057, 31.749368519000029],
            [72.557097035000083, 31.757768858000077],
            [72.557172000000037, 31.758050000000026],
            [72.557974921000039, 31.758605803000023],
            [72.562676256000032, 31.761768519000043],
            [72.562676306000071, 31.76176855500006],
            [72.564776256000073, 31.763268519000064],
            [72.570569157000079, 31.764484313000025],
            [72.570538593000038, 31.764511233000064],
            [72.572193740000046, 31.764866039000026],
            [72.572219716000063, 31.76483072700006],
            [72.572876256000029, 31.764968519000035],
            [72.585576256000081, 31.765568519000055],
            [72.597776256000031, 31.770168519000038],
            [72.600176256000054, 31.770668519000026],
            [72.605273022000063, 31.771614311000064],
            [72.609876256000064, 31.772468519000029],
            [72.616876256000069, 31.772468519000029],
            [72.633076256000038, 31.773668519000068],
            [72.644076256000062, 31.773668519000068],
            [72.64907622000004, 31.77716849400008],
            [72.649076256000058, 31.777168519000043],
            [72.648795586000062, 31.777579809000031],
            [72.64907622000004, 31.777807807000045],
            [72.651645272000053, 31.779895006000061],
            [72.653212834000044, 31.781168555000079],
            [72.653254000000061, 31.781202000000064],
            [72.653443573000061, 31.781168555000079],
            [72.659556982000083, 31.780090011000027],
            [72.663094000000058, 31.77946600000007],
            [72.677950413000076, 31.775221834000035],
            [72.679301000000066, 31.77483600000005],
            [72.679358734000061, 31.774873525000032],
            [72.679376256000069, 31.774868519000051],
            [72.686799849000067, 31.77970999300004],
            [72.690877000000057, 31.78236000000004],
            [72.691227180000055, 31.78261012400003],
            [72.698683796000068, 31.787936191000028],
            [72.703076256000031, 31.791068519000078],
            [72.711776256000064, 31.796868519000043],
            [72.716946737000058, 31.802039000000036],
            [72.721109560000059, 31.802039000000036],
            [72.725026000000071, 31.802039000000036],
            [72.728499000000056, 31.798567000000048],
            [72.736831621000078, 31.794678059000034],
            [72.73727625600003, 31.794468519000077],
            [72.737976256000081, 31.79996851900006],
            [72.738197312000068, 31.802068555000062],
            [72.738376256000038, 31.803768519000073],
            [72.745876256000031, 31.805468519000044],
            [72.749976256000082, 31.813068519000069],
            [72.756376256000067, 31.816468519000068],
            [72.761419637000074, 31.818499881000037],
            [72.770776256000033, 31.822268519000033],
            [72.772976220000032, 31.825668463000056],
            [72.772976256000049, 31.825668519000033],
            [72.780076256000029, 31.833268519000057],
            [72.784162012000081, 31.835175205000041],
            [72.787575144000073, 31.836768000000063],
            [72.796219000000065, 31.836768000000063],
            [72.796221769000056, 31.836768519000032],
            [72.80534583900004, 31.838478851000048],
            [72.805480000000045, 31.838504000000057],
            [72.81342610400003, 31.839563847000079],
            [72.814162000000067, 31.839662000000033],
            [72.817458409000039, 31.839442163000058],
            [72.822844000000032, 31.839083000000073],
            [72.822863664000067, 31.839069387000052],
            [72.824454997000032, 31.837967752000054],
            [72.827007408000043, 31.836200790000078],
            [72.837893000000065, 31.828665000000058],
            [72.83804695200007, 31.828584357000068],
            [72.850048000000072, 31.822298000000046],
            [72.859888000000069, 31.825771000000032],
            [72.851785000000064, 31.839662000000033],
            [72.854348888000061, 31.847034147000045],
            [72.856415000000084, 31.852975000000072],
            [72.865097000000048, 31.857605000000035],
            [72.872383533000061, 31.867320377000055],
            [72.87377900000007, 31.869181000000026],
            [72.876951624000071, 31.870422358000042],
            [72.887037192000037, 31.874368555000046],
            [72.887092000000052, 31.874390000000062],
            [72.887192097000082, 31.874368555000046],
            [72.887961997000048, 31.874203610000052],
            [72.892459364000047, 31.873240087000056],
            [72.895195000000058, 31.872654000000068],
            [72.901562000000069, 31.865129000000024],
            [72.901566808000041, 31.865103367000074],
            [72.903161579000084, 31.85660159400004],
            [72.903299000000061, 31.855869000000041],
            [72.90850800000004, 31.848344000000054],
            [72.911973936000038, 31.845442314000024],
            [72.915044232000071, 31.84287185900007],
            [72.915876256000047, 31.841968519000034],
            [72.923576256000047, 31.846568519000073],
            [72.936376256000074, 31.850068519000047],
            [72.936651617000052, 31.850104049000038],
            [72.944973000000061, 31.850659000000064],
            [72.950088795000056, 31.847979258000066],
            [72.957128000000068, 31.844292000000053],
            [72.957165768000038, 31.844027297000025],
            [72.956976256000075, 31.839668519000043],
            [72.964076256000055, 31.833868519000077],
            [72.974276256000053, 31.839368519000061],
            [72.97441301300006, 31.83946383500006],
            [72.978809571000056, 31.841015587000072],
            [72.983174000000076, 31.842556000000059],
            [72.983169253000028, 31.842567394000071],
            [72.981666759000063, 31.846173587000067],
            [72.98028000000005, 31.84950200000003],
            [72.978912277000063, 31.857249283000044],
            [72.978543000000059, 31.859341000000029],
            [72.977992838000034, 31.862368555000046],
            [72.977990521000038, 31.862381307000078],
            [72.977873156000044, 31.863027171000056],
            [72.977386000000081, 31.865708000000041],
            [72.984908398000073, 31.868023507000032],
            [72.98491000000007, 31.868024000000048],
            [72.984738003000075, 31.868161585000053],
            [72.979122000000075, 31.872654000000068],
            [72.979109884000081, 31.87266165300008],
            [72.977505756000085, 31.873674863000076],
            [72.968179730000031, 31.87956543100006],
            [72.968175107000036, 31.879568351000046],
            [72.968125000000043, 31.879600000000039],
            [72.964073000000042, 31.87902100000008],
            [72.963110579000045, 31.879324945000064],
            [72.953076000000067, 31.882494000000065],
            [72.94555100000008, 31.890597000000071],
            [72.943595051000045, 31.890675611000063],
            [72.941876256000057, 31.890768519000062],
            [72.940307467000082, 31.889722660000075],
            [72.938876256000071, 31.888768519000052],
            [72.932554578000065, 31.889439000000039],
            [72.93227625600008, 31.889468519000047],
            [72.929124941000055, 31.889678607000064],
            [72.924714000000051, 31.890018000000055],
            [72.924525512000059, 31.890108993000069],
            [72.909165505000033, 31.897524074000046],
            [72.907929000000081, 31.89812100000006],
            [72.907830442000034, 31.898229417000039],
            [72.902141000000029, 31.904488000000072],
            [72.897576220000076, 31.908482922000076],
            [72.897511000000065, 31.908540000000073],
            [72.897576220000076, 31.909083625000051],
            [72.899033020000047, 31.921226424000054],
            [72.899247000000059, 31.923010000000033],
            [72.904839443000071, 31.924300621000043],
            [72.904804670000033, 31.924229385000046],
            [72.910676256000045, 31.925568519000024],
            [72.914348127000039, 31.926461677000077],
            [72.927030000000059, 31.921274000000039],
            [72.927027789000078, 31.921288364000077],
            [72.927076256000078, 31.92126851900008],
            [72.925883645000056, 31.928722340000036],
            [72.92587650300004, 31.928768741000056],
            [72.925872000000084, 31.928798000000029],
            [72.928110420000053, 31.930812888000048],
            [72.931660000000079, 31.934008000000063],
            [72.934783128000049, 31.934677104000059],
            [72.937426780000067, 31.935243484000068],
            [72.939763000000084, 31.935744000000057],
            [72.950026152000078, 31.933463518000053],
            [72.960600000000056, 31.931114000000036],
            [72.94323600000007, 31.944426000000078],
            [72.94555100000008, 31.961211000000048],
            [72.946155186000055, 31.963174378000076],
            [72.949745976000031, 31.974843085000032],
            [72.950182000000041, 31.976260000000025],
            [72.950264291000053, 31.976230079000061],
            [72.962916000000064, 31.971630000000062],
            [72.962982157000056, 31.971277073000067],
            [72.964652000000058, 31.962369000000024],
            [72.968447291000075, 31.960553885000024],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "96",
      properties: { name: "Dera Ghazi Khan", count: 179 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.608080126000061, 31.320969405000028],
            [70.612066000000084, 31.317980000000034],
            [70.614239558000065, 31.319758447000027],
            [70.619355000000041, 31.32394400000004],
            [70.630620000000079, 31.315992000000051],
            [70.647638123000036, 31.315272889000028],
            [70.661719645000062, 31.314677866000068],
            [70.673565071000041, 31.31417457200007],
            [70.677667000000042, 31.314004000000068],
            [70.681458433000046, 31.317659952000042],
            [70.69027254100007, 31.32615910100003],
            [70.696221000000037, 31.331895000000031],
            [70.698619618000066, 31.332339278000063],
            [70.70480423600003, 31.333484808000037],
            [70.708252337000033, 31.334123474000023],
            [70.709344022000039, 31.334325678000027],
            [70.714113000000054, 31.335209000000077],
            [70.716886336000073, 31.334834124000054],
            [70.719033976000048, 31.334543825000026],
            [70.72477327200005, 31.333768035000048],
            [70.728548259000036, 31.333257765000042],
            [70.732277757000077, 31.332753643000046],
            [70.735832423000033, 31.332273153000074],
            [70.738630000000057, 31.331895000000031],
            [70.73940297200005, 31.330230262000043],
            [70.740217310000048, 31.328476434000038],
            [70.741455934000044, 31.32580882700006],
            [70.742824655000049, 31.322861033000038],
            [70.744130475000077, 31.320048709000048],
            [70.745072961000062, 31.31801888900003],
            [70.745810044000052, 31.316431445000035],
            [70.747245000000078, 31.313341000000037],
            [70.749236741000061, 31.312570010000059],
            [70.752873886000032, 31.311162095000043],
            [70.756635207000045, 31.309704879000037],
            [70.756635299000038, 31.309704843000077],
            [70.759144245000073, 31.308734880000031],
            [70.759155490000069, 31.308730723000053],
            [70.766584107000028, 31.305855276000045],
            [70.76736709000005, 31.305552420000026],
            [70.76664813800005, 31.302847363000069],
            [70.766743000000076, 31.298399000000074],
            [70.763903413000037, 31.29527669600003],
            [70.760989714000061, 31.29239018800007],
            [70.752583119000064, 31.283610223000039],
            [70.747198195000067, 31.277946149000059],
            [70.740490000000079, 31.270980000000066],
            [70.743541969000034, 31.266284919000043],
            [70.74807450000003, 31.259312000000079],
            [70.759159000000068, 31.258729000000073],
            [70.761100174000035, 31.25678782600005],
            [70.763819480000052, 31.254068520000033],
            [70.76616000000007, 31.251728000000071],
            [70.764800427000068, 31.246742855000036],
            [70.763707792000048, 31.242566055000054],
            [70.761017672000037, 31.234455117000039],
            [70.75971372500004, 31.228091452000058],
            [70.758254371000078, 31.222740443000077],
            [70.756745986000055, 31.21720964800005],
            [70.755659000000037, 31.213224000000025],
            [70.754872330000069, 31.211243331000048],
            [70.754478210000059, 31.207438247000027],
            [70.752770575000056, 31.199071011000058],
            [70.751803513000084, 31.194313557000044],
            [70.750464234000049, 31.189531148000071],
            [70.749825000000044, 31.184638000000064],
            [70.751552304000029, 31.181183145000034],
            [70.753342922000058, 31.177601654000057],
            [70.755430195000031, 31.173433253000042],
            [70.756753779000064, 31.170703692000075],
            [70.762237277000054, 31.161706836000064],
            [70.763915902000065, 31.157219088000033],
            [70.76631885900008, 31.15497229500005],
            [70.768541002000063, 31.150768842000048],
            [70.768210707000037, 31.146050613000057],
            [70.767862083000068, 31.138564940000037],
            [70.765788900000075, 31.133056736000071],
            [70.764350084000057, 31.129233990000046],
            [70.767977195000071, 31.123307572000044],
            [70.768931681000083, 31.12178635500004],
            [70.772577000000069, 31.115798000000041],
            [70.778994000000068, 31.113464000000079],
            [70.778822773000059, 31.108747251000068],
            [70.778487337000058, 31.101740204000066],
            [70.778397580000046, 31.09703354800007],
            [70.778039484000033, 31.087146044000065],
            [70.77783002700005, 31.081362277000039],
            [70.77760403700006, 31.075122035000049],
            [70.777252119000082, 31.065400596000075],
            [70.777131083000029, 31.06184674800005],
            [70.776896253000075, 31.055578018000062],
            [70.776665883000078, 31.049216796000053],
            [70.776596611000059, 31.047304005000058],
            [70.776494917000036, 31.044494535000069],
            [70.776078229000063, 31.035956682000062],
            [70.776077000000043, 31.03295600000007],
            [70.778004864000081, 31.024666251000042],
            [70.778514222000069, 31.022472176000065],
            [70.779147981000051, 31.019750887000043],
            [70.779963205000058, 31.016245452000078],
            [70.780760781000083, 31.012815903000046],
            [70.781833190000043, 31.007814202000077],
            [70.781482843000049, 31.004587141000059],
            [70.781071844000053, 31.001435833000073],
            [70.780743004000044, 30.998914478000074],
            [70.78026086300008, 30.995217694000075],
            [70.780215246000068, 30.995157576000054],
            [70.778406601000029, 30.992773959000033],
            [70.776604257000031, 30.991049847000056],
            [70.773849968000036, 30.988415647000068],
            [70.770864946000074, 30.985560544000066],
            [70.76935192500008, 30.984113376000039],
            [70.767336068000077, 30.982185256000037],
            [70.768341744000054, 30.978220455000042],
            [70.771414520000064, 30.971690263000028],
            [70.77358859800006, 30.971768150000059],
            [70.776167606000058, 30.971848826000041],
            [70.779154403000064, 30.971942259000059],
            [70.790079000000048, 30.972284000000059],
            [70.790578542000048, 30.969786004000071],
            [70.792057021000062, 30.962392763000025],
            [70.793579000000079, 30.95478200000008],
            [70.792658593000056, 30.948340334000079],
            [70.791776781000067, 30.942168778000052],
            [70.791249805000064, 30.938897808000036],
            [70.783545751000077, 30.935238842000047],
            [70.776676966000082, 30.932170389000078],
            [70.775220463000039, 30.930405524000037],
            [70.773314977000041, 30.928326829000071],
            [70.770711175000031, 30.925486343000046],
            [70.767123416000061, 30.921572458000071],
            [70.765282726000066, 30.919539130000032],
            [70.761703669000042, 30.915660058000071],
            [70.760542774000044, 30.914393638000035],
            [70.759294952000062, 30.913032390000069],
            [70.757992000000058, 30.91161100000005],
            [70.759675851000054, 30.905717343000049],
            [70.760650527000053, 30.902305868000042],
            [70.761655201000053, 30.898789403000023],
            [70.763119784000082, 30.89471925600003],
            [70.764894745000049, 30.89257464800005],
            [70.767464549000067, 30.889469667000071],
            [70.769256047000056, 30.887305078000054],
            [70.776664134000043, 30.878362336000066],
            [70.783661000000052, 30.88069100000007],
            [70.785708943000031, 30.876887964000048],
            [70.787745000000029, 30.873107000000061],
            [70.78469934900005, 30.864307680000024],
            [70.78249500000004, 30.857939000000044],
            [70.779163788000062, 30.854132284000059],
            [70.774327000000028, 30.848605000000077],
            [70.770827000000054, 30.841021000000069],
            [70.756825000000049, 30.841021000000069],
            [70.75040800000005, 30.841604000000075],
            [70.741074000000083, 30.844521000000043],
            [70.739260748000049, 30.840376805000062],
            [70.738622423000038, 30.83875869700006],
            [70.736990000000048, 30.835187000000076],
            [70.740817028000038, 30.828930639000077],
            [70.743768957000043, 30.822224953000045],
            [70.744868868000083, 30.819554088000075],
            [70.74488468100003, 30.815436104000071],
            [70.744628431000081, 30.812233528000036],
            [70.744263072000081, 30.807414988000062],
            [70.743814133000058, 30.802056542000059],
            [70.743403708000073, 30.796927120000078],
            [70.743288859000074, 30.79499505900003],
            [70.742995288000031, 30.791821989000027],
            [70.742720620000057, 30.788389972000061],
            [70.742517473000078, 30.78585107400005],
            [70.742104204000043, 30.780683083000042],
            [70.741870611000081, 30.777766682000049],
            [70.741657000000032, 30.775097000000073],
            [70.754072523000048, 30.772840233000068],
            [70.762178160000076, 30.771676881000076],
            [70.770462363000036, 30.770296211000073],
            [70.776763512000059, 30.769246043000066],
            [70.783048032000067, 30.76819864600003],
            [70.787264512000036, 30.768311987000061],
            [70.790437510000061, 30.762272408000058],
            [70.795749164000028, 30.75424109800008],
            [70.796036173000061, 30.749952525000026],
            [70.796129978000067, 30.747219025000049],
            [70.796312098000044, 30.741937022000059],
            [70.796509180000044, 30.736401174000036],
            [70.79668335000008, 30.731169636000061],
            [70.796876603000044, 30.72556475500005],
            [70.796981495000068, 30.722522585000036],
            [70.79711439600004, 30.718668071000025],
            [70.797228919000077, 30.715336155000045],
            [70.79742539700004, 30.70964814000007],
            [70.79799749700004, 30.706294982000031],
            [70.797663000000057, 30.702757000000076],
            [70.797241636000081, 30.699627103000068],
            [70.796835811000051, 30.696495279000032],
            [70.795980806000046, 30.690261639000028],
            [70.795291312000074, 30.685189477000051],
            [70.794892270000048, 30.682083445000046],
            [70.79425890300007, 30.677471326000045],
            [70.793579000000079, 30.672421000000043],
            [70.794793613000081, 30.668216507000068],
            [70.795629958000063, 30.665321423000023],
            [70.797931596000069, 30.657354096000063],
            [70.799307142000032, 30.652592521000031],
            [70.801064000000054, 30.646511000000032],
            [70.796217500000068, 30.643375000000049],
            [70.796230473000037, 30.639276858000073],
            [70.796217384000045, 30.635266719000072],
            [70.796401826000078, 30.629978922000078],
            [70.796217500000068, 30.625165000000038],
            [70.802919114000076, 30.622391442000037],
            [70.809420000000046, 30.619701000000077],
            [70.809509399000035, 30.619554139000059],
            [70.815794000000039, 30.609230000000025],
            [70.813868407000029, 30.60566917400007],
            [70.812607000000071, 30.60422200000005],
            [70.803957000000082, 30.598304000000041],
            [70.803957000000082, 30.593229725000072],
            [70.803957000000082, 30.585556000000054],
            [70.813883885000052, 30.578938319000031],
            [70.817615000000046, 30.576451000000077],
            [70.816152192000061, 30.571703245000037],
            [70.815143853000052, 30.56904331100003],
            [70.808575205000068, 30.563181672000042],
            [70.801435088000062, 30.557438690000026],
            [70.793941000000075, 30.55141100000003],
            [70.795535288000053, 30.541314813000042],
            [70.796673000000055, 30.534110000000055],
            [70.800293821000082, 30.524876410000047],
            [70.803774970000063, 30.515999002000058],
            [70.805778000000032, 30.510891000000072],
            [70.800840678000043, 30.503441799000029],
            [70.798077726000031, 30.499340287000052],
            [70.793031000000042, 30.491770000000031],
            [70.793211300000053, 30.487433013000043],
            [70.79384265300007, 30.472277764000069],
            [70.793941000000075, 30.469917000000066],
            [70.798340179000036, 30.463957194000045],
            [70.808055000000081, 30.450796000000025],
            [70.812760181000044, 30.449617713000066],
            [70.815186495000034, 30.448615302000064],
            [70.819374006000032, 30.447736533000068],
            [70.824899500000072, 30.446243000000038],
            [70.824889540000072, 30.428519191000078],
            [70.825286313000049, 30.425283388000025],
            [70.825355000000059, 30.418471000000068],
            [70.828358895000065, 30.411672821000025],
            [70.831606205000071, 30.404323764000026],
            [70.834005000000047, 30.398895000000039],
            [70.835139729000048, 30.391177246000041],
            [70.836281000000042, 30.38341500000007],
            [70.835225202000061, 30.381430032000026],
            [70.82919828100006, 30.370099039000024],
            [70.824900000000071, 30.362018000000035],
            [70.824900000000071, 30.347673133000058],
            [70.824892495000029, 30.343932296000048],
            [70.824525635000043, 30.327816218000066],
            [70.824444000000028, 30.324230000000057],
            [70.824049200000047, 30.315555735000032],
            [70.825143406000052, 30.307103393000034],
            [70.825355000000059, 30.301922000000047],
            [70.825810000000047, 30.287808000000041],
            [70.826347316000067, 30.28489128800004],
            [70.828997000000072, 30.270508000000063],
            [70.825755378000053, 30.263117197000042],
            [70.824444000000028, 30.260947000000044],
            [70.81670500000007, 30.260037000000068],
            [70.814349302000039, 30.258379407000064],
            [70.80441200000007, 30.251387000000079],
            [70.80459710100007, 30.245807755000044],
            [70.805323000000044, 30.237729000000058],
            [70.811938328000053, 30.231113376000053],
            [70.821038227000031, 30.222013068000024],
            [70.827631000000054, 30.215420000000051],
            [70.830781577000039, 30.211558233000062],
            [70.837942263000059, 30.202781142000049],
            [70.84174500000006, 30.198120000000074],
            [70.84402250200003, 30.18875594900004],
            [70.845668603000036, 30.18200369300007],
            [70.846312692000083, 30.168144685000073],
            [70.846460396000055, 30.164590255000064],
            [70.846734168000069, 30.157144248000066],
            [70.846866747000036, 30.153475103000062],
            [70.847343471000045, 30.138238892000061],
            [70.847663000000068, 30.128008000000079],
            [70.849377343000072, 30.124579315000062],
            [70.850975399000049, 30.121383202000061],
            [70.856313000000057, 30.110708000000045],
            [70.857751958000051, 30.107767782000053],
            [70.864429822000034, 30.094122913000035],
            [70.86678500000005, 30.089310000000069],
            [70.87199176200005, 30.080805596000062],
            [70.877036034000071, 30.074974649000069],
            [70.878166000000078, 30.073376000000053],
            [70.882441846000063, 30.064468216000023],
            [70.888958225000067, 30.051719651000042],
            [70.874069000000077, 30.049246000000039],
            [70.87271307900005, 30.044274040000062],
            [70.868624530000034, 30.028741192000041],
            [70.869724591000079, 30.019902793000028],
            [70.86964210800005, 30.013112813000077],
            [70.869548126000041, 30.005376309000042],
            [70.870472287000041, 30.003194553000071],
            [70.875890000000084, 29.987329000000045],
            [70.878289857000084, 29.976050253000039],
            [70.879695989000084, 29.969441771000049],
            [70.880443000000071, 29.965931000000069],
            [70.864963500000044, 29.964110000000062],
            [70.868456404000028, 29.960355419000052],
            [70.874524000000065, 29.953184000000078],
            [70.885679193000044, 29.944904526000073],
            [70.887727000000041, 29.943168000000071],
            [70.886361000000079, 29.936339000000032],
            [70.856865906000053, 29.932069883000054],
            [70.851761000000067, 29.931331000000057],
            [70.833094500000072, 29.909478000000036],
            [70.845504314000038, 29.899155441000062],
            [70.856958451000082, 29.888698233000071],
            [70.858134000000064, 29.887625000000071],
            [70.85204475900008, 29.868849004000026],
            [70.849053778000041, 29.85962640300005],
            [70.84720800000008, 29.853935000000035],
            [70.846424073000037, 29.851303296000026],
            [70.840834000000029, 29.832537000000059],
            [70.828610468000079, 29.823548807000066],
            [70.825355000000059, 29.821155000000033],
            [70.822845768000036, 29.811954380000032],
            [70.821257500000058, 29.80613100000005],
            [70.808965000000057, 29.801123000000075],
            [70.805108056000051, 29.796827950000079],
            [70.793801500000029, 29.784707407000042],
            [70.788933000000043, 29.778815000000066],
            [70.780749731000071, 29.767833143000075],
            [70.767535000000066, 29.746946000000037],
            [70.75250118200006, 29.726944926000044],
            [70.742495500000075, 29.720995000000073],
            [70.727471500000036, 29.707793000000038],
            [70.735666000000037, 29.693224000000043],
            [70.736103627000034, 29.680008312000041],
            [70.73612200000008, 29.677745000000073],
            [70.725526449000029, 29.669131245000074],
            [70.722159519000058, 29.67500932300004],
            [70.720594141000049, 29.677943891000041],
            [70.71791941500004, 29.682963223000058],
            [70.717145377000065, 29.684496310000043],
            [70.715494104000072, 29.687799006000034],
            [70.713201540000057, 29.692384344000061],
            [70.711390876000053, 29.696005838000076],
            [70.708238040000083, 29.70231179700005],
            [70.706899000000078, 29.704990000000066],
            [70.705948813000077, 29.707840561000069],
            [70.705087692000063, 29.710423924000054],
            [70.704503543000044, 29.712176371000055],
            [70.702804551000042, 29.717401667000047],
            [70.703272208000044, 29.720989521000035],
            [70.703732871000057, 29.723551315000066],
            [70.703624571000034, 29.727246706000074],
            [70.704163000000051, 29.733711000000028],
            [70.701243159000057, 29.73600515000004],
            [70.697078734000058, 29.739277182000023],
            [70.694740348000039, 29.741114477000053],
            [70.691878262000046, 29.743363248000037],
            [70.685016000000076, 29.748755000000074],
            [70.688282592000064, 29.752430739000033],
            [70.690354036000031, 29.753771479000079],
            [70.693418540000039, 29.758805556000027],
            [70.695957000000078, 29.761064000000033],
            [70.693410749000066, 29.767611959000078],
            [70.692561192000028, 29.769796687000053],
            [70.691736727000034, 29.771916888000078],
            [70.690536575000067, 29.775003206000065],
            [70.689620364000064, 29.777359343000057],
            [70.688729353000042, 29.779650672000059],
            [70.687824891000048, 29.781976594000071],
            [70.687025234000032, 29.784032998000043],
            [70.686384000000032, 29.785682000000065],
            [70.684800403000054, 29.78657276000007],
            [70.682232449000082, 29.788017213000046],
            [70.67926602700004, 29.78968579900004],
            [70.67657594700006, 29.791198099000042],
            [70.671935074000032, 29.793813128000068],
            [70.669051654000043, 29.796691193000072],
            [70.66450100000003, 29.797991000000025],
            [70.662694184000031, 29.798743913000067],
            [70.659368721000078, 29.80012965800006],
            [70.655998172000068, 29.801534190000041],
            [70.65165027300003, 29.803347055000074],
            [70.647498240000061, 29.805098759000032],
            [70.643181944000048, 29.807060600000057],
            [70.63995426200006, 29.80852765800006],
            [70.63789513200004, 29.809463453000035],
            [70.635307917000034, 29.810639429000048],
            [70.633045000000038, 29.811668000000054],
            [70.627412273000061, 29.809350191000078],
            [70.625183807000042, 29.808432205000031],
            [70.620765141000049, 29.806611557000053],
            [70.619183743000065, 29.805960388000074],
            [70.614007113000071, 29.803829276000045],
            [70.609794000000079, 29.802094000000068],
            [70.600736131000076, 29.79530059800004],
            [70.59545600000007, 29.791340500000047],
            [70.593382000000076, 29.789785000000052],
            [70.588040638000052, 29.784443638000027],
            [70.586037899000075, 29.782440899000051],
            [70.584305336000057, 29.780708336000032],
            [70.582894777000035, 29.779297777000068],
            [70.581438693000052, 29.777841693000028],
            [70.58107300000006, 29.774506046000056],
            [70.58107300000006, 29.771191929000054],
            [70.58107300000006, 29.767479782000066],
            [70.58107300000006, 29.76451551100007],
            [70.58107300000006, 29.762324256000056],
            [70.581072714000072, 29.758899608000036],
            [70.580912697000031, 29.755762860000061],
            [70.580784728000083, 29.753714330000037],
            [70.580651203000059, 29.751576852000028],
            [70.580433095000046, 29.748085370000069],
            [70.580017549000047, 29.741433291000078],
            [70.579869074000044, 29.739056503000029],
            [70.579333462000079, 29.735807385000044],
            [70.576729514000078, 29.731343631000072],
            [70.575862348000044, 29.729857112000047],
            [70.574757729000055, 29.726672319000045],
            [70.571753064000063, 29.722812870000041],
            [70.570435481000061, 29.720550358000025],
            [70.568576832000076, 29.717311013000028],
            [70.564697525000042, 29.710523545000058],
            [70.563049965000062, 29.707640277000053],
            [70.561270127000057, 29.704525519000072],
            [70.559191000000055, 29.700887000000023],
            [70.556448400000079, 29.695010204000027],
            [70.555527547000054, 29.693037016000062],
            [70.553121511000029, 29.687881404000052],
            [70.552132738000068, 29.68576267900005],
            [70.550447115000054, 29.682150757000045],
            [70.549617000000069, 29.680372000000034],
            [70.54700619700003, 29.677761197000052],
            [70.544346763000078, 29.675101763000043],
            [70.541275599000073, 29.672030599000038],
            [70.536012043000028, 29.66676704300005],
            [70.532255705000068, 29.663010705000033],
            [70.530470000000037, 29.661225000000059],
            [70.525828260000083, 29.658237511000038],
            [70.520423458000039, 29.654761703000077],
            [70.516939293000064, 29.65252386800006],
            [70.514456405000033, 29.650927162000073],
            [70.51104700500008, 29.648718789000043],
            [70.50630740400004, 29.64544006400007],
            [70.50129361300003, 29.641968946000077],
            [70.496236972000077, 29.638469668000027],
            [70.493543000000045, 29.636607000000026],
            [70.490444685000057, 29.635621149000031],
            [70.486471007000034, 29.634356767000043],
            [70.484144313000058, 29.633616438000047],
            [70.480863839000051, 29.632572626000069],
            [70.478153430000077, 29.631710203000068],
            [70.475081484000043, 29.630732742000077],
            [70.471596886000043, 29.629623980000076],
            [70.467482267000037, 29.628314752000051],
            [70.464117520000059, 29.627244125000061],
            [70.461071815000082, 29.626503625000055],
            [70.45843147000005, 29.62591688200007],
            [70.455545710000081, 29.625275602000045],
            [70.453648551000072, 29.624854011000025],
            [70.450678481000068, 29.624193996000031],
            [70.448664486000041, 29.623746442000026],
            [70.446196374000067, 29.623197972000071],
            [70.441424707000067, 29.622137602000066],
            [70.437888992000069, 29.62135188700006],
            [70.434002086000078, 29.620488131000059],
            [70.432947238000054, 29.620253720000051],
            [70.429012024000031, 29.619379228000071],
            [70.426527000000078, 29.618827000000067],
            [70.426079418000029, 29.620890143000054],
            [70.425595817000044, 29.62311930900006],
            [70.425052058000063, 29.625625782000043],
            [70.424346068000034, 29.628873898000052],
            [70.423463466000044, 29.632926072000032],
            [70.422985530000062, 29.635118384000066],
            [70.422104965000074, 29.639169136000078],
            [70.421617426000068, 29.641411899000047],
            [70.419689000000062, 29.650283000000059],
            [70.411166896000054, 29.655964601000051],
            [70.407988121000074, 29.658083858000055],
            [70.404474291000042, 29.660426493000045],
            [70.400798188000067, 29.662877313000024],
            [70.390968000000044, 29.669431000000031],
            [70.396791053000072, 29.67525405300006],
            [70.401909000000046, 29.680372000000034],
            [70.399947707000081, 29.68485486700007],
            [70.398033032000058, 29.689231181000025],
            [70.396986290000029, 29.69162368800005],
            [70.395932816000084, 29.694031696000025],
            [70.394084371000076, 29.698256516000072],
            [70.392380002000039, 29.702153812000063],
            [70.390734545000043, 29.703573018000043],
            [70.38849627500008, 29.705416293000042],
            [70.385502744000064, 29.707881547000056],
            [70.38335025300006, 29.709654181000076],
            [70.379379823000079, 29.712923937000028],
            [70.377934390000064, 29.714114289000065],
            [70.374626040000066, 29.716838804000076],
            [70.372081100000059, 29.718934631000025],
            [70.369085000000041, 29.721402000000069],
            [70.366856832000053, 29.722320140000079],
            [70.364416481000035, 29.723311135000074],
            [70.361222670000075, 29.724719202000074],
            [70.359378545000084, 29.725530050000032],
            [70.356901881000056, 29.726655821000065],
            [70.354532135000056, 29.727732993000075],
            [70.351568347000068, 29.729080187000079],
            [70.349162271000068, 29.730173873000069],
            [70.346126295000033, 29.731553880000035],
            [70.343093945000078, 29.732932239000036],
            [70.340751300000079, 29.733997092000038],
            [70.338943824000069, 29.734818683000071],
            [70.333201446000032, 29.735016601000041],
            [70.330288192000069, 29.735117010000067],
            [70.32579185700007, 29.735271915000055],
            [70.323234081000066, 29.735414910000031],
            [70.321327674000031, 29.73537314500004],
            [70.319336405000058, 29.735565520000023],
            [70.315588247000051, 29.735654971000031],
            [70.313434971000049, 29.735697952000066],
            [70.308822040000052, 29.73591682700004],
            [70.303832517000046, 29.736196322000069],
            [70.299172121000083, 29.736337491000029],
            [70.295114614000056, 29.736350386000026],
            [70.289070145000039, 29.736382503000073],
            [70.284976802000074, 29.736351005000074],
            [70.280795583000042, 29.736351270000057],
            [70.276657096000065, 29.736351503000037],
            [70.272921928000073, 29.736351727000056],
            [70.269153878000054, 29.736351953000053],
            [70.265678047000051, 29.73661616000004],
            [70.259823263000044, 29.736447056000031],
            [70.251299241000083, 29.736447007000038],
            [70.242897320000054, 29.736446292000039],
            [70.236380890000078, 29.736452321000058],
            [70.22927405400003, 29.737198361000026],
            [70.217222728000081, 29.738467007000054],
            [70.204438261000064, 29.739812831000052],
            [70.196538409000084, 29.740644450000048],
            [70.184450000000083, 29.741917000000058],
            [70.174148822000063, 29.74577994200007],
            [70.163980516000038, 29.749593056000037],
            [70.154738559000066, 29.753058790000068],
            [70.151626000000078, 29.754226000000074],
            [70.147916506000058, 29.757248924000066],
            [70.14583729800006, 29.758943321000061],
            [70.143431821000036, 29.759888423000064],
            [70.137031646000082, 29.766118495000057],
            [70.12893690900006, 29.772714006000058],
            [70.119329271000083, 29.780542266000054],
            [70.11469900000003, 29.784315000000049],
            [70.109395818000053, 29.792473490000077],
            [70.104508509000084, 29.799992193000037],
            [70.100113840000063, 29.806753014000037],
            [70.096919000000071, 29.811668000000054],
            [70.08976433600003, 29.814467681000053],
            [70.083945837000044, 29.816744509000046],
            [70.080077815000038, 29.818258099000047],
            [70.073138623000034, 29.820973464000076],
            [70.065463000000079, 29.82397700000007],
            [70.053884154000059, 29.827219158000048],
            [70.049717298000076, 29.82838590700004],
            [70.047039126000072, 29.82913581400004],
            [70.036503480000079, 29.832085869000025],
            [70.031271000000061, 29.833551000000057],
            [70.016551211000035, 29.833551000000057],
            [70.008021000000042, 29.833551000000057],
            [69.996908336000047, 29.836819177000052],
            [69.990572888000031, 29.838682400000039],
            [69.985487762000048, 29.840023597000027],
            [69.968645747000039, 29.843972058000077],
            [69.957032843000036, 29.846552625000072],
            [69.953435593000052, 29.84735198900006],
            [69.950401666000062, 29.84802617400004],
            [69.935534000000075, 29.851330000000075],
            [69.91994829600003, 29.858257402000049],
            [69.915416730000061, 29.860271554000064],
            [69.910916000000043, 29.862272000000075],
            [69.895872000000054, 29.875948000000051],
            [69.880828000000065, 29.893728000000067],
            [69.878830853000068, 29.895271476000062],
            [69.887651301000062, 29.903580937000072],
            [69.888766000000032, 29.904630000000054],
            [69.898761039000078, 29.925869712000065],
            [69.903422757000044, 29.935775981000063],
            [69.903475000000071, 29.935887000000037],
            [69.907795967000084, 29.947409265000033],
            [69.908782533000078, 29.950040036000075],
            [69.912668000000053, 29.960401000000047],
            [69.913857227000051, 29.965157911000063],
            [69.917049744000053, 29.977927976000046],
            [69.918427883000049, 29.983440533000078],
            [69.920627999000033, 29.992240994000042],
            [69.921335549000048, 29.995079176000047],
            [69.921861000000035, 29.997173000000032],
            [69.924068746000046, 30.002518308000049],
            [69.924680293000051, 30.003998962000026],
            [69.926329094000039, 30.007990974000052],
            [69.927573146000043, 30.011003026000026],
            [69.929280557000084, 30.015136943000073],
            [69.930689471000051, 30.018548151000061],
            [69.931947537000042, 30.02159413000004],
            [69.933505000000082, 30.025365000000079],
            [69.937128886000039, 30.02898887300006],
            [69.946309260000078, 30.038169252000046],
            [69.959246000000064, 30.051106000000061],
            [69.962049144000048, 30.053108277000035],
            [69.972116000000028, 30.060299000000043],
            [69.979997535000052, 30.078512243000034],
            [69.981922000000054, 30.082975000000033],
            [69.983402519000038, 30.087192779000077],
            [69.988663000000031, 30.105038000000036],
            [69.988051116000065, 30.12132790000004],
            [69.988050999000052, 30.121380943000077],
            [69.988629625000044, 30.134886429000062],
            [69.988061592000065, 30.13926955900007],
            [69.988061720000076, 30.139345601000059],
            [69.996630000000039, 30.152229000000034],
            [69.997228095000082, 30.155133337000052],
            [69.998204151000039, 30.159873041000026],
            [69.999660867000046, 30.166946823000046],
            [70.001573422000035, 30.172681455000031],
            [70.00227095300005, 30.176344591000031],
            [70.003406083000073, 30.179101451000065],
            [70.004183904000058, 30.180990521000069],
            [70.005211000000031, 30.183485000000076],
            [70.00990575000003, 30.183484999000029],
            [70.013221121000072, 30.183484999000029],
            [70.017468000000065, 30.183484999000029],
            [70.016315220000081, 30.193903547000048],
            [70.015094622000049, 30.204935000000035],
            [70.018288797000082, 30.211033832000055],
            [70.020880564000038, 30.215982449000023],
            [70.021835622000083, 30.217806000000053],
            [70.032867622000083, 30.222709000000066],
            [70.03804232400006, 30.223543533000054],
            [70.044120061000058, 30.224523700000077],
            [70.044113849000041, 30.224535216000049],
            [70.051789000000042, 30.225773000000061],
            [70.054985353000063, 30.228668846000062],
            [70.054985388000034, 30.228668882000079],
            [70.063433000000032, 30.23741700000005],
            [70.068516178000039, 30.246088504000056],
            [70.073852000000045, 30.255191000000025],
            [70.073239000000058, 30.274189000000035],
            [70.046433924000041, 30.278453399000057],
            [70.046273000000042, 30.278479000000061],
            [70.026681757000063, 30.278479000000061],
            [70.026681721000045, 30.278479000000061],
            [70.015017000000057, 30.278479000000061],
            [69.99995320000005, 30.26154484400007],
            [69.994845536000071, 30.255803000000071],
            [69.993269969000039, 30.253814654000053],
            [69.992417622000062, 30.252739000000076],
            [69.979470001000038, 30.249062000000038],
            [69.975189044000047, 30.253352000000064],
            [69.975189044000047, 30.261932000000058],
            [69.980562040000052, 30.27088666800006],
            [69.980608755000048, 30.270979596000075],
            [69.98069600000008, 30.27112500000004],
            [69.983313176000081, 30.276359351000053],
            [69.984995045000062, 30.279705000000035],
            [69.986834044000034, 30.292575000000056],
            [69.981955131000063, 30.298091001000046],
            [69.981937122000033, 30.298073987000066],
            [69.981922000000054, 30.298091001000046],
            [69.976406000000054, 30.29380100000003],
            [69.961084000000028, 30.291963000000067],
            [69.963536000000033, 30.319542000000069],
            [69.96501842300006, 30.321957847000078],
            [69.965094457000077, 30.322081756000046],
            [69.96823353700006, 30.327197392000073],
            [69.973411442000042, 30.335616750000042],
            [69.980102539000029, 30.346496582000043],
            [69.980105296000033, 30.346502197000063],
            [69.980537950000041, 30.347383317000038],
            [69.981349562000048, 30.349036201000047],
            [69.984135630000083, 30.354710153000042],
            [69.996064407000063, 30.379003641000054],
            [69.998154353000075, 30.383253216000071],
            [69.998154935000059, 30.383261096000069],
            [69.998474121000072, 30.383911133000026],
            [69.999694824000073, 30.404113770000038],
            [69.999694810000051, 30.404114431000039],
            [69.999695000000031, 30.404117000000042],
            [69.999603283000056, 30.40861022100006],
            [69.999586966000038, 30.409409590000053],
            [69.999351035000075, 30.42099380600007],
            [69.999249509000038, 30.425978716000031],
            [69.999084473000039, 30.434082031000059],
            [70.004699707000043, 30.453125000000057],
            [70.01452636700003, 30.461730957000043],
            [70.043884277000075, 30.497924805000025],
            [70.047081064000054, 30.50678307700008],
            [70.048447229000033, 30.510568708000051],
            [70.051879883000083, 30.520080566000047],
            [70.073303223000039, 30.562316895000038],
            [70.073447573000067, 30.56256085900003],
            [70.077585971000076, 30.569555093000076],
            [70.085971204000032, 30.583726830000046],
            [70.088073730000076, 30.587280273000033],
            [70.093763009000043, 30.615993355000057],
            [70.095915000000048, 30.626588000000027],
            [70.095288264000033, 30.637657943000079],
            [70.094116211000028, 30.659118652000075],
            [70.09022432200004, 30.665373473000045],
            [70.087335000000053, 30.670102000000043],
            [70.087711196000043, 30.675931804000072],
            [70.088684082000043, 30.689086914000029],
            [70.097290039000029, 30.70007324200003],
            [70.101836216000038, 30.708935410000038],
            [70.102045338000039, 30.709343065000041],
            [70.102111816000047, 30.709472656000059],
            [70.102734995000048, 30.718295561000048],
            [70.103270319000046, 30.725874623000038],
            [70.103271484000061, 30.725891113000046],
            [70.108579183000074, 30.732430198000031],
            [70.110900879000042, 30.735290527000075],
            [70.110923572000047, 30.735292313000059],
            [70.111588942000083, 30.735344680000026],
            [70.12285517600003, 30.736231375000045],
            [70.124084473000039, 30.736328125000057],
            [70.154724121000072, 30.73327636700003],
            [70.158383184000058, 30.741814180000063],
            [70.161499023000033, 30.749084473000039],
            [70.161522657000035, 30.749172409000039],
            [70.168273511000052, 30.774290447000055],
            [70.16827392600004, 30.77429199200003],
            [70.174146113000063, 30.774500226000043],
            [70.202697754000042, 30.775512695000032],
            [70.203659796000068, 30.776096102000054],
            [70.218471424000029, 30.783879697000032],
            [70.218883651000056, 30.787581934000059],
            [70.218638180000028, 30.792428037000036],
            [70.219726563000052, 30.803710938000052],
            [70.222900391000053, 30.82647705100004],
            [70.226546960000064, 30.832892311000023],
            [70.229520000000036, 30.838027000000068],
            [70.269356003000041, 30.84047898700004],
            [70.271881670000084, 30.854366741000035],
            [70.273071289000029, 30.860717773000033],
            [70.257690430000082, 30.866882324000073],
            [70.253479004000042, 30.876708984000061],
            [70.257899868000038, 30.896720989000073],
            [70.261474609000061, 30.912902832000043],
            [70.262947516000054, 30.918794460000072],
            [70.264218647000064, 30.92387898100003],
            [70.264388722000035, 30.92454506200005],
            [70.266551604000028, 30.933015736000073],
            [70.267856599000083, 30.938126597000064],
            [70.268917215000045, 30.941437972000074],
            [70.268917213000066, 30.941438008000034],
            [70.268619938000029, 30.946315353000045],
            [70.268332818000033, 30.957223548000059],
            [70.268052301000068, 30.967880917000059],
            [70.268400645000042, 30.971108318000063],
            [70.268711861000043, 30.973991728000044],
            [70.267828795000071, 30.976372329000071],
            [70.267763281000043, 30.981431551000071],
            [70.267540415000042, 30.987344571000051],
            [70.267539235000072, 30.987391967000065],
            [70.26783275300005, 31.00029541300006],
            [70.267906870000047, 31.003148292000049],
            [70.267906871000037, 31.003148328000066],
            [70.268296226000075, 31.011581642000067],
            [70.26893474600007, 31.025411825000049],
            [70.268744000000083, 31.035371000000055],
            [70.276342360000058, 31.044366557000046],
            [70.277923999000052, 31.047239956000055],
            [70.27942838000007, 31.049812734000056],
            [70.287742000000037, 31.061111000000039],
            [70.289038331000029, 31.065942342000028],
            [70.289038341000037, 31.065942378000045],
            [70.294484000000068, 31.086238000000037],
            [70.294484184000055, 31.086264845000073],
            [70.294494629000042, 31.086303711000028],
            [70.294486416000041, 31.086591988000066],
            [70.294585203000054, 31.10106746200006],
            [70.293936773000041, 31.105884446000061],
            [70.293922911000038, 31.106371017000072],
            [70.293959533000077, 31.109467977000065],
            [70.294220951000057, 31.115515464000055],
            [70.294761679000032, 31.128024287000073],
            [70.29477520100005, 31.12833708200003],
            [70.295648306000032, 31.142115698000055],
            [70.295709857000077, 31.149958796000078],
            [70.295715332000043, 31.150085449000073],
            [70.288888236000048, 31.162136758000031],
            [70.288756493000051, 31.162369314000046],
            [70.289126348000082, 31.162493965000067],
            [70.321951765000051, 31.17355703000004],
            [70.333095000000071, 31.178169000000025],
            [70.369254000000069, 31.189813000000072],
            [70.393156000000033, 31.192265000000077],
            [70.399461000000031, 31.195768000000044],
            [70.404187000000036, 31.198393000000067],
            [70.404187000000036, 31.200649982000073],
            [70.404187000000036, 31.209425000000067],
            [70.406992953000042, 31.213166186000024],
            [70.40803150000005, 31.214550884000062],
            [70.410469267000053, 31.217801166000072],
            [70.411575037000034, 31.219275492000065],
            [70.415515674000062, 31.224692446000063],
            [70.416597722000063, 31.22649995900008],
            [70.419259496000052, 31.231739659000027],
            [70.421617866000076, 31.236178955000071],
            [70.423555128000032, 31.239825577000033],
            [70.424795439000036, 31.242160284000079],
            [70.425614944000074, 31.24370288700004],
            [70.427189000000055, 31.243101000000024],
            [70.427415954000082, 31.243712067000047],
            [70.428051592000031, 31.245423508000044],
            [70.42874203000008, 31.247282496000025],
            [70.429715224000063, 31.249902800000029],
            [70.430698866000057, 31.252551234000066],
            [70.43167475000007, 31.255178781000041],
            [70.432874838000032, 31.258409990000075],
            [70.434426257000041, 31.262587150000058],
            [70.435803000000078, 31.266294000000073],
            [70.440959598000063, 31.27324412300004],
            [70.44131195600005, 31.273719036000045],
            [70.441331839000043, 31.273745835000057],
            [70.441333434000057, 31.273747984000067],
            [70.441335028000083, 31.273750133000078],
            [70.442745354000067, 31.275650987000063],
            [70.451044000000081, 31.286836000000051],
            [70.451639556000032, 31.287098043000071],
            [70.457691518000047, 31.289760892000061],
            [70.459768111000074, 31.290674588000059],
            [70.461952422000081, 31.29163568000007],
            [70.467610000000036, 31.294125000000065],
            [70.470886134000068, 31.29451034300007],
            [70.475181404000068, 31.294321324000066],
            [70.475181440000028, 31.294321340000067],
            [70.477399601000059, 31.29527646300005],
            [70.481488657000057, 31.295757421000076],
            [70.490306644000043, 31.296791552000059],
            [70.493656580000049, 31.297126676000062],
            [70.500487834000069, 31.297809836000056],
            [70.50670640800007, 31.298431724000068],
            [70.508415952000064, 31.297866442000043],
            [70.510019000000057, 31.298763000000065],
            [70.512009213000056, 31.299672853000061],
            [70.515331393000054, 31.301191634000077],
            [70.523246884000059, 31.30481031000005],
            [70.533212000000049, 31.309366000000068],
            [70.541826000000071, 31.317980000000034],
            [70.544149000000061, 31.32727200000005],
            [70.544476000000031, 31.32858200000004],
            [70.563030000000083, 31.338522000000069],
            [70.564653517000067, 31.337745554000037],
            [70.573660896000035, 31.333437779000064],
            [70.576456432000043, 31.332100816000036],
            [70.578271000000029, 31.331233000000054],
            [70.581117629000062, 31.330430110000066],
            [70.588291174000062, 31.32840681600004],
            [70.597445914000048, 31.325824729000033],
            [70.604114000000038, 31.32394400000004],
            [70.604777806000072, 31.323446146000038],
            [70.608080126000061, 31.320969405000028],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "97",
      properties: { name: "Faisalabad", count: 1201 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.253197797000041, 31.761549964000039],
            [73.253200800000059, 31.761549063000075],
            [73.253203850000034, 31.761549964000039],
            [73.269216800000038, 31.766280063000067],
            [73.27049839600005, 31.766376918000049],
            [73.270504718000041, 31.766377393000027],
            [73.279044000000056, 31.767010000000028],
            [73.279044372000044, 31.76700995300007],
            [73.281853242000068, 31.766658845000052],
            [73.290692000000035, 31.765554000000066],
            [73.293427810000082, 31.765116431000024],
            [73.296838000000037, 31.764571000000046],
            [73.296837991000075, 31.764570802000037],
            [73.296829153000033, 31.764384775000053],
            [73.296777000000077, 31.763287000000048],
            [73.296777000000077, 31.750742000000059],
            [73.289934000000073, 31.742759000000035],
            [73.293049688000053, 31.737046755000051],
            [73.293355000000076, 31.736487000000068],
            [73.302479000000062, 31.73534600000005],
            [73.305190290000041, 31.733776385000056],
            [73.30786788100005, 31.732226279000031],
            [73.313313000000051, 31.729074000000026],
            [73.323006000000078, 31.722231000000079],
            [73.325683310000045, 31.721160151000049],
            [73.337262000000067, 31.716529000000037],
            [73.342192645000068, 31.719184577000078],
            [73.344674000000055, 31.720521000000076],
            [73.357789000000082, 31.720521000000076],
            [73.363423306000072, 31.720275694000065],
            [73.370904000000053, 31.71995000000004],
            [73.373977849000084, 31.710729351000055],
            [73.376048673000071, 31.704517483000075],
            [73.377747000000056, 31.699423000000024],
            [73.376283645000058, 31.69398820300006],
            [73.376274202000047, 31.693953133000036],
            [73.373755000000074, 31.684597000000053],
            [73.371797491000052, 31.676278657000069],
            [73.371538823000037, 31.675179461000027],
            [73.371474000000035, 31.674904000000026],
            [73.377176000000077, 31.667491000000041],
            [73.386870000000044, 31.669202000000041],
            [73.380598000000077, 31.661789000000056],
            [73.378407000000038, 31.655218000000048],
            [73.378317000000038, 31.654946000000052],
            [73.382817848000059, 31.654946000000052],
            [73.387550117000046, 31.654946000000052],
            [73.392002000000048, 31.654946000000052],
            [73.403406000000075, 31.648674000000028],
            [73.40772729400004, 31.646513353000046],
            [73.410349951000057, 31.64520202500006],
            [73.419372000000067, 31.640691000000061],
            [73.42546132800004, 31.639102621000063],
            [73.429637823000064, 31.638013197000078],
            [73.432487000000037, 31.637270000000058],
            [73.432994953000048, 31.637046472000065],
            [73.446742000000029, 31.630997000000036],
            [73.454242880000038, 31.625103787000057],
            [73.454725000000053, 31.624725000000069],
            [73.455051744000059, 31.624300210000058],
            [73.460427000000038, 31.617312000000027],
            [73.460427000000038, 31.604716805000066],
            [73.460427000000038, 31.603057000000035],
            [73.459287000000074, 31.588231000000064],
            [73.45718148900005, 31.580651233000026],
            [73.457152823000058, 31.580548036000039],
            [73.453585000000032, 31.567704000000049],
            [73.454624677000083, 31.567184096000062],
            [73.461568000000057, 31.563712000000066],
            [73.472402000000045, 31.558010000000024],
            [73.465559000000042, 31.554589000000078],
            [73.468805261000057, 31.551884020000045],
            [73.470233873000041, 31.550693614000068],
            [73.472402000000045, 31.548887000000036],
            [73.484376000000054, 31.541474000000051],
            [73.488539571000047, 31.536175452000066],
            [73.490649000000076, 31.533491000000026],
            [73.486657000000037, 31.524938000000077],
            [73.486802658000045, 31.521295264000059],
            [73.487227000000075, 31.510683000000029],
            [73.487227000000075, 31.510254000000032],
            [73.487228000000073, 31.493576036000036],
            [73.487228000000073, 31.493576000000076],
            [73.487227000000075, 31.493576719000032],
            [73.487227000000075, 31.493576036000036],
            [73.487227000000075, 31.493576000000076],
            [73.482889669000031, 31.491097913000033],
            [73.479244000000051, 31.489015000000052],
            [73.477193217000035, 31.487403505000032],
            [73.471261000000084, 31.48274200000003],
            [73.469668899000055, 31.479557799000077],
            [73.467755382000064, 31.475730765000037],
            [73.466157223000039, 31.472503110000048],
            [73.47810400000003, 31.461074000000053],
            [73.49178900000004, 31.450810000000047],
            [73.494870942000034, 31.448070663000067],
            [73.496657223000057, 31.446503110000037],
            [73.498013504000085, 31.445297527000037],
            [73.502057223000065, 31.441703110000049],
            [73.515157223000074, 31.424603110000078],
            [73.515266177000058, 31.424550812000064],
            [73.518036399000039, 31.423707600000057],
            [73.520013381000069, 31.423105838000026],
            [73.528093969000054, 31.42064623400006],
            [73.528235763000055, 31.420603074000041],
            [73.528282000000047, 31.420589000000064],
            [73.528380503000051, 31.420603074000041],
            [73.528428782000049, 31.420609972000079],
            [73.540009400000031, 31.422264623000046],
            [73.540257000000054, 31.422300000000064],
            [73.543809877000058, 31.423879133000071],
            [73.550387514000079, 31.426802670000029],
            [73.55052100000006, 31.426862000000028],
            [73.558504000000084, 31.433134000000052],
            [73.55868061700005, 31.43329884700006],
            [73.564409608000062, 31.438646039000048],
            [73.566075725000076, 31.44020112000004],
            [73.567057000000034, 31.441117000000077],
            [73.570666653000046, 31.443776762000027],
            [73.577891000000079, 31.449100000000044],
            [73.585303000000067, 31.450240000000065],
            [73.593966867000063, 31.445289451000065],
            [73.596774622000055, 31.443685095000035],
            [73.598399251000046, 31.442756779000035],
            [73.601269000000059, 31.441117000000077],
            [73.605309725000041, 31.442912965000062],
            [73.611557223000034, 31.445703110000068],
            [73.61575722300006, 31.447003110000026],
            [73.62005722300006, 31.448003110000059],
            [73.62635722300007, 31.453103110000029],
            [73.643457223000041, 31.452003110000078],
            [73.643699883000068, 31.452068442000041],
            [73.64672958500006, 31.453583293000065],
            [73.653673244000061, 31.457055122000043],
            [73.653757223000071, 31.457103110000048],
            [73.659440614000061, 31.452516514000024],
            [73.660182410000061, 31.452170377000073],
            [73.66795722300003, 31.448603110000079],
            [73.67545722300008, 31.446303110000031],
            [73.678057223000053, 31.442403110000043],
            [73.67990562500006, 31.439484580000055],
            [73.679958000000056, 31.439406000000076],
            [73.679956047000076, 31.439404966000041],
            [73.679957223000031, 31.439403110000057],
            [73.679283879000081, 31.439049084000033],
            [73.670265000000029, 31.434274000000073],
            [73.670265000000029, 31.426893975000041],
            [73.670265000000029, 31.426862000000028],
            [73.673397707000049, 31.423159392000059],
            [73.675468262000038, 31.420712162000029],
            [73.675516930000072, 31.420725041000026],
            [73.676557223000032, 31.419503110000051],
            [73.67885722300008, 31.411503110000069],
            [73.67765722300004, 31.409403110000028],
            [73.677657202000034, 31.409403074000068],
            [73.67485722300006, 31.404603110000039],
            [73.678664885000046, 31.400103146000049],
            [73.679407203000039, 31.399225861000048],
            [73.68255722300006, 31.39550311000005],
            [73.68395722300005, 31.393803110000079],
            [73.68395722300005, 31.388409826000043],
            [73.683950000000038, 31.388409808000063],
            [73.683950000000038, 31.382994480000036],
            [73.683950000000038, 31.382955000000038],
            [73.678509969000061, 31.376494702000059],
            [73.677682269000059, 31.375505762000046],
            [73.67625722300005, 31.37380311000004],
            [73.67485722300006, 31.372103110000069],
            [73.673257223000064, 31.370603110000047],
            [73.670467449000057, 31.368052460000058],
            [73.666273000000047, 31.364138000000025],
            [73.665423185000066, 31.361466970000038],
            [73.665423172000033, 31.361466930000063],
            [73.662282000000062, 31.351594000000034],
            [73.663238815000057, 31.348882699000058],
            [73.665703000000065, 31.341900000000066],
            [73.665966079000043, 31.341406770000049],
            [73.670257223000078, 31.333361581000077],
            [73.670265000000029, 31.33334700000006],
            [73.670257223000078, 31.333265307000033],
            [73.670257223000078, 31.321903110000051],
            [73.666747568000062, 31.316401489000043],
            [73.66659545400006, 31.316083463000041],
            [73.662852000000044, 31.308257000000026],
            [73.659366011000031, 31.302280769000049],
            [73.658857223000041, 31.301403110000024],
            [73.652057223000043, 31.297403110000062],
            [73.653757223000071, 31.287803110000027],
            [73.653457223000032, 31.282403110000075],
            [73.653157223000051, 31.280303110000034],
            [73.651403635000065, 31.279236739000055],
            [73.648890895000079, 31.277690385000028],
            [73.645746000000031, 31.275755000000061],
            [73.639506209000047, 31.272875420000048],
            [73.638301262000084, 31.27231107800003],
            [73.638132375000055, 31.272189102000027],
            [73.628810324000028, 31.265456409000024],
            [73.628069000000039, 31.264921000000072],
            [73.628059022000059, 31.264918279000028],
            [73.628057187000081, 31.264917779000029],
            [73.628003534000072, 31.264903146000051],
            [73.623682154000051, 31.263724619000072],
            [73.623614571000076, 31.263706188000072],
            [73.615525000000048, 31.261500000000069],
            [73.613600001000066, 31.258982673000048],
            [73.613138067000079, 31.25837860200005],
            [73.608112000000062, 31.251806000000045],
            [73.60427584100006, 31.249585177000029],
            [73.601857093000035, 31.248184920000028],
            [73.598574213000063, 31.246284401000025],
            [73.597278000000074, 31.245534000000077],
            [73.590452170000049, 31.248377846000039],
            [73.588757223000073, 31.239903110000057],
            [73.588716872000077, 31.239787319000072],
            [73.587584000000049, 31.233560000000068],
            [73.586463018000074, 31.233319739000024],
            [73.585838525000042, 31.233185891000062],
            [73.585009294000031, 31.233008161000043],
            [73.581055608000042, 31.23216076600005],
            [73.580982271000039, 31.232145048000064],
            [73.579601000000082, 31.231849000000068],
            [73.57389900000004, 31.226717000000065],
            [73.568987975000084, 31.220650291000027],
            [73.56420600000007, 31.214743000000055],
            [73.563651136000033, 31.214077215000032],
            [73.558227690000081, 31.207569587000023],
            [73.555652000000066, 31.204479000000049],
            [73.552360855000074, 31.201187373000039],
            [73.552357223000058, 31.201203110000051],
            [73.548857223000084, 31.197703110000077],
            [73.542557223000074, 31.197703110000077],
            [73.536257223000064, 31.197703110000077],
            [73.533957223000073, 31.192103110000062],
            [73.533975362000035, 31.192075124000041],
            [73.532274000000029, 31.187943000000075],
            [73.532047728000066, 31.187829848000035],
            [73.52918756400004, 31.186399557000072],
            [73.525431000000083, 31.184521000000075],
            [73.518554551000079, 31.184521000000075],
            [73.512887000000035, 31.184521000000075],
            [73.511130129000037, 31.184960282000077],
            [73.506044000000031, 31.186232000000075],
            [73.502692705000072, 31.188365031000046],
            [73.500066754000045, 31.19003639500005],
            [73.500014204000081, 31.190069842000071],
            [73.492965307000077, 31.18461869500004],
            [73.492560952000076, 31.184285709000051],
            [73.49007800000004, 31.182241000000033],
            [73.487007360000064, 31.182241000000033],
            [73.483806000000072, 31.182241000000033],
            [73.476336329000048, 31.18423221300003],
            [73.475519091000081, 31.184450067000057],
            [73.475333076000084, 31.184499654000035],
            [73.475253000000066, 31.184521000000075],
            [73.462373177000075, 31.173702888000037],
            [73.46189548500007, 31.173301662000028],
            [73.460997000000077, 31.172547000000066],
            [73.450649615000032, 31.159181218000072],
            [73.449289797000063, 31.157424732000038],
            [73.447390795000047, 31.154971780000039],
            [73.447312000000068, 31.154870000000074],
            [73.447008612000047, 31.154371616000049],
            [73.44699120100006, 31.154343014000062],
            [73.446984713000063, 31.154332356000054],
            [73.442884000000049, 31.147596000000078],
            [73.439772744000038, 31.142483525000046],
            [73.439356945000043, 31.141800277000073],
            [73.43885722300007, 31.136703110000042],
            [73.438535588000036, 31.133808399000031],
            [73.438191757000084, 31.130368576000023],
            [73.443857223000066, 31.124703110000041],
            [73.443854223000073, 31.124686777000079],
            [73.443892000000062, 31.124649000000034],
            [73.442751000000044, 31.114385000000027],
            [73.442363463000049, 31.11417834100007],
            [73.43643340400007, 31.111016059000065],
            [73.434198000000038, 31.10982400000006],
            [73.433528172000081, 31.10929274700004],
            [73.432226660000083, 31.108260495000025],
            [73.421357187000069, 31.099639720000027],
            [73.421327057000042, 31.099615823000079],
            [73.421311073000084, 31.099603146000049],
            [73.417662000000064, 31.096709000000033],
            [73.417248650000033, 31.096321476000071],
            [73.408539000000076, 31.088156000000026],
            [73.408396190000076, 31.087913250000042],
            [73.407557223000083, 31.086403110000049],
            [73.404791402000058, 31.08175417700005],
            [73.403122832000065, 31.07894955900008],
            [73.402856217000078, 31.078496365000035],
            [73.402836000000036, 31.078462000000059],
            [73.401955238000085, 31.072588193000058],
            [73.40187614000007, 31.072060691000047],
            [73.401132702000041, 31.067102694000027],
            [73.401126000000033, 31.067058000000031],
            [73.396564000000069, 31.066488000000049],
            [73.395042856000032, 31.059643187000063],
            [73.394957223000063, 31.056703110000058],
            [73.39945722300007, 31.049403110000071],
            [73.397903566000082, 31.046694170000023],
            [73.395589543000028, 31.042580601000054],
            [73.39428300000003, 31.040258000000051],
            [73.393713000000048, 31.031705000000045],
            [73.395844973000067, 31.026374563000047],
            [73.395926585000041, 31.026170513000068],
            [73.39794100000006, 31.021134000000075],
            [73.397741575000055, 31.021009775000039],
            [73.397460000000081, 31.020835000000034],
            [73.401813000000061, 31.008261000000061],
            [73.403263999000046, 30.999071000000072],
            [73.397961597000062, 30.995788908000065],
            [73.395779128000072, 30.99443799900007],
            [73.393107238000084, 30.992784147000066],
            [73.393107000000043, 30.992784000000029],
            [73.378114000000039, 30.995686000000035],
            [73.372311000000082, 30.999071000000072],
            [73.370726571000034, 30.997882755000035],
            [73.36210066700005, 30.991413745000045],
            [73.356834000000049, 30.987464000000045],
            [73.351385794000066, 30.985094980000042],
            [73.35106710000008, 30.984956404000059],
            [73.345710223000083, 30.982627097000034],
            [73.334104000000082, 30.984078000000068],
            [73.324431000000061, 30.986013000000071],
            [73.324430763000066, 30.98601284700004],
            [73.324430060000054, 30.986012394000056],
            [73.324430000000064, 30.986013000000071],
            [73.320579819000045, 30.983531717000062],
            [73.30746398000008, 30.975079100000073],
            [73.307464268000047, 30.975078641000039],
            [73.302667000000042, 30.97198700000007],
            [73.298412259000031, 30.968914457000039],
            [73.298412054000039, 30.968914679000079],
            [73.293961000000081, 30.965700000000027],
            [73.290575000000047, 30.952642000000026],
            [73.28896607300004, 30.95202312300006],
            [73.282954818000064, 30.949710881000044],
            [73.278000000000077, 30.947805000000074],
            [73.276627684000061, 30.945256355000026],
            [73.271229000000062, 30.935230000000047],
            [73.26932800000003, 30.935099000000037],
            [73.269328032000033, 30.935098872000026],
            [73.257204000000058, 30.934263000000044],
            [73.253961342000082, 30.933398306000072],
            [73.243215010000029, 30.93053266700008],
            [73.242695000000083, 30.930394000000035],
            [73.236891000000071, 30.918787000000066],
            [73.232650646000081, 30.914757962000067],
            [73.230715811000039, 30.912919549000037],
            [73.227219000000048, 30.909597000000076],
            [73.215641213000083, 30.904773588000069],
            [73.215611000000081, 30.904761000000065],
            [73.215551633000075, 30.904824610000048],
            [73.215423706000081, 30.904961683000067],
            [73.211087017000068, 30.909608363000075],
            [73.208840000000066, 30.912016000000051],
            [73.212226000000044, 30.917336000000034],
            [73.201586000000077, 30.922656000000075],
            [73.201585111000043, 30.922655944000041],
            [73.193847000000062, 30.922172000000046],
            [73.191429000000085, 30.912499000000025],
            [73.195879355000045, 30.902262078000035],
            [73.196265000000039, 30.90137500000003],
            [73.189384071000063, 30.90041939300005],
            [73.188849077000043, 30.900345093000055],
            [73.188837913000043, 30.900343543000076],
            [73.182385602000068, 30.899447460000033],
            [73.179441405000034, 30.899038577000056],
            [73.178854000000058, 30.898957000000053],
            [73.177881561000049, 30.894093464000036],
            [73.176738900000032, 30.888378583000076],
            [73.176738287000035, 30.88837916600005],
            [73.175952000000052, 30.884448000000077],
            [73.183691000000067, 30.877193000000034],
            [73.183973454000068, 30.87622447800004],
            [73.186034387000063, 30.869157639000036],
            [73.187068628000077, 30.865611279000063],
            [73.187076000000047, 30.865586000000064],
            [73.184776618000058, 30.85523848400004],
            [73.183276503000059, 30.848487771000066],
            [73.183207000000039, 30.848175000000026],
            [73.182931426000039, 30.847841396000035],
            [73.179249154000047, 30.84338371900003],
            [73.176560977000065, 30.840129471000068],
            [73.174018000000046, 30.837051000000031],
            [73.174017564000053, 30.837051399000075],
            [73.17007181200006, 30.84066811200006],
            [73.170051203000071, 30.840687002000038],
            [73.168214000000034, 30.842371000000071],
            [73.164828000000057, 30.850109000000032],
            [73.164828000000057, 30.853439152000078],
            [73.164828000000057, 30.854334111000071],
            [73.164828000000057, 30.860266000000024],
            [73.158057080000049, 30.867036920000032],
            [73.146934000000044, 30.866069000000039],
            [73.136777000000052, 30.869455000000073],
            [73.131457000000069, 30.86316800000003],
            [73.134359000000074, 30.852528000000063],
            [73.134687965000069, 30.851869934000035],
            [73.135804317000066, 30.84963676700005],
            [73.136281728000029, 30.848681748000047],
            [73.136777000000052, 30.847691000000054],
            [73.141130000000032, 30.838985000000037],
            [73.141130000000032, 30.833763890000057],
            [73.141130000000032, 30.832607864000067],
            [73.141130000000032, 30.831247000000076],
            [73.131457000000069, 30.823509000000058],
            [73.130408608000039, 30.822515715000065],
            [73.123716573000081, 30.81617543200008],
            [73.122268000000076, 30.81480300000004],
            [73.120022696000035, 30.812397530000055],
            [73.115497000000062, 30.807549000000051],
            [73.107759000000044, 30.809000000000026],
            [73.105742642000052, 30.810152162000065],
            [73.100988000000029, 30.812869000000035],
            [73.095668000000046, 30.801745000000039],
            [73.094319556000073, 30.800621297000077],
            [73.086962000000028, 30.794490000000053],
            [73.083329393000042, 30.79581091700004],
            [73.081060029000071, 30.796636121000063],
            [73.080928878000066, 30.796683811000037],
            [73.078287960000068, 30.797644122000065],
            [73.076322000000062, 30.798359000000062],
            [73.071590989000072, 30.800882989000058],
            [73.069068000000073, 30.802229000000068],
            [73.063286437000045, 30.806358322000051],
            [73.062770896000075, 30.806726533000074],
            [73.062297000000058, 30.80706500000008],
            [73.06229641200008, 30.807064706000062],
            [73.058818906000056, 30.805326065000031],
            [73.046820000000082, 30.799327000000062],
            [73.044063829000038, 30.793814658000031],
            [73.042467000000045, 30.790621000000044],
            [73.042467000000045, 30.778668478000043],
            [73.042467000000045, 30.778047000000072],
            [73.042467000000045, 30.778046000000074],
            [73.029892000000075, 30.779014000000075],
            [73.028650817000084, 30.778556644000048],
            [73.022804437000048, 30.776402346000054],
            [73.020703000000083, 30.77562800000004],
            [73.018081274000053, 30.773589282000046],
            [73.01659193100005, 30.772431132000065],
            [73.016350000000045, 30.77224300000006],
            [73.008122318000062, 30.77224300000006],
            [73.003292000000044, 30.77224300000006],
            [73.000068166000062, 30.772959161000074],
            [72.994586967000032, 30.774176785000066],
            [72.992652000000078, 30.761603000000036],
            [72.988850887000069, 30.763123367000048],
            [72.986827752000067, 30.763932579000027],
            [72.982979000000057, 30.765472000000045],
            [72.977659000000074, 30.769341000000054],
            [72.971372000000031, 30.763537000000042],
            [72.965084000000047, 30.75773300000003],
            [72.961505269000043, 30.757136887000058],
            [72.956379000000084, 30.756283000000053],
            [72.948175092000042, 30.757194231000028],
            [72.947673000000066, 30.757250000000056],
            [72.946963288000063, 30.755594006000024],
            [72.946960906000072, 30.755588448000026],
            [72.945363718000067, 30.751861676000033],
            [72.943547633000037, 30.747624144000042],
            [72.943320000000028, 30.747093000000064],
            [72.943033836000041, 30.742508446000045],
            [72.942993793000085, 30.741866927000046],
            [72.94298595600003, 30.741741374000071],
            [72.942837000000054, 30.739355000000046],
            [72.934960138000065, 30.739818683000067],
            [72.934615000000065, 30.739839000000075],
            [72.926393000000076, 30.742257000000052],
            [72.922637455000029, 30.733618315000058],
            [72.921557000000064, 30.731133000000057],
            [72.928291738000041, 30.727765341000065],
            [72.931724147000068, 30.726048989000049],
            [72.933164000000033, 30.725329000000045],
            [72.937566205000053, 30.720926795000025],
            [72.939935000000048, 30.71855800000003],
            [72.939321694000057, 30.717681926000068],
            [72.938168897000082, 30.716035220000037],
            [72.937525160000064, 30.715115677000028],
            [72.936937253000053, 30.714275885000063],
            [72.934266119000029, 30.710460316000024],
            [72.933164000000033, 30.708886000000064],
            [72.932912290000047, 30.702849116000039],
            [72.932687137000073, 30.697449175000031],
            [72.932680000000062, 30.69727800000004],
            [72.92784400000005, 30.694376000000034],
            [72.920106000000033, 30.690507000000025],
            [72.920106000000033, 30.677449000000024],
            [72.912367000000074, 30.673096000000044],
            [72.90185677900007, 30.67477801800004],
            [72.900276000000076, 30.675031000000047],
            [72.896698359000084, 30.677075215000059],
            [72.890120000000081, 30.680834000000061],
            [72.885133619000044, 30.683535100000029],
            [72.878512000000057, 30.687122000000045],
            [72.876059182000063, 30.68820123200004],
            [72.866421000000059, 30.692442000000028],
            [72.857496810000043, 30.688385296000035],
            [72.852074994000077, 30.685920680000038],
            [72.85134806800005, 30.685590238000032],
            [72.850461000000053, 30.685187000000042],
            [72.841755000000035, 30.677932000000055],
            [72.841738834000068, 30.677946146000068],
            [72.83685108700007, 30.682223294000039],
            [72.830148000000065, 30.688089000000048],
            [72.826241132000064, 30.692386878000036],
            [72.825312000000054, 30.693409000000031],
            [72.81467200000003, 30.682769000000064],
            [72.807620973000041, 30.680955843000049],
            [72.79774400000008, 30.678416000000027],
            [72.788723455000081, 30.678023520000067],
            [72.788724000000059, 30.67802400000005],
            [72.788649831000043, 30.67802075700007],
            [72.786620000000084, 30.677932000000055],
            [72.783145587000035, 30.690092895000078],
            [72.782751000000076, 30.691474000000028],
            [72.790973000000065, 30.700180000000046],
            [72.802580000000034, 30.707435000000032],
            [72.80654413700006, 30.709999973000038],
            [72.810802000000081, 30.712755000000072],
            [72.830148000000065, 30.714206000000047],
            [72.83201723500008, 30.714918098000055],
            [72.83362620500003, 30.715531046000024],
            [72.833626245000062, 30.715530932000036],
            [72.840305000000058, 30.718075000000056],
            [72.840209888000061, 30.719072889000074],
            [72.83936338400008, 30.727954189000059],
            [72.839337000000057, 30.728231000000051],
            [72.836396118000039, 30.735583814000051],
            [72.834501000000046, 30.740322000000049],
            [72.832217107000076, 30.741279855000073],
            [72.829206552000073, 30.742542469000057],
            [72.819508000000042, 30.746610000000032],
            [72.814332260000072, 30.746610000000032],
            [72.805966000000069, 30.746610000000032],
            [72.795954902000062, 30.750185075000047],
            [72.79242400000004, 30.751446000000044],
            [72.790830148000055, 30.749852305000047],
            [72.782267000000047, 30.741290000000049],
            [72.782145269000068, 30.741237064000074],
            [72.782133682000051, 30.741232026000034],
            [72.771143135000045, 30.736453059000041],
            [72.771143000000052, 30.73645300000004],
            [72.762930862000076, 30.737009877000048],
            [72.762417180000057, 30.737044711000067],
            [72.745199056000047, 30.738212297000075],
            [72.742608000000075, 30.738388000000043],
            [72.740104000000031, 30.74411200000003],
            [72.73583700000006, 30.753864000000078],
            [72.734870000000058, 30.763537000000042],
            [72.73702306000007, 30.766151452000031],
            [72.741641000000072, 30.771759000000031],
            [72.747319409000056, 30.779183917000069],
            [72.747929000000056, 30.779981000000078],
            [72.74067400000007, 30.785785000000033],
            [72.750450755000031, 30.790673799000047],
            [72.752281000000039, 30.791589000000045],
            [72.757601000000079, 30.794490000000053],
            [72.757601577000059, 30.794489769000052],
            [72.759674061000055, 30.793661061000023],
            [72.764856000000066, 30.791588000000047],
            [72.767758000000072, 30.797876000000031],
            [72.772111000000052, 30.801261000000068],
            [72.769306161000031, 30.806683561000057],
            [72.764856000000066, 30.815287000000069],
            [72.765213888000062, 30.818150844000058],
            [72.765823000000069, 30.82302500000003],
            [72.770660000000078, 30.82931300000007],
            [72.772111000000052, 30.835600000000056],
            [72.771576867000078, 30.836160865000068],
            [72.762582000000066, 30.845606000000032],
            [72.762581665000084, 30.845606147000069],
            [72.762438000000031, 30.845757000000049],
            [72.760508003000041, 30.84658395200006],
            [72.760456172000033, 30.846536978000074],
            [72.753146160000028, 30.849738303000038],
            [72.752281000000039, 30.850109000000032],
            [72.743576000000076, 30.857364000000075],
            [72.733903000000055, 30.861233000000027],
            [72.73057708500005, 30.861441031000027],
            [72.730489695000074, 30.861446497000031],
            [72.729363847000059, 30.861516917000074],
            [72.729303494000078, 30.861520692000056],
            [72.72714523500008, 30.861655688000042],
            [72.726165000000037, 30.861717000000056],
            [72.724078232000068, 30.861811728000077],
            [72.715525000000071, 30.86220000000003],
            [72.711556299000051, 30.867711781000025],
            [72.706819000000053, 30.874291000000028],
            [72.705507242000067, 30.88609682200007],
            [72.705368000000078, 30.887350000000026],
            [72.705507242000067, 30.88751243300004],
            [72.707527448000064, 30.889869108000028],
            [72.714074000000039, 30.897506000000078],
            [72.714391800000044, 30.897823800000026],
            [72.716422291000072, 30.899854291000054],
            [72.726165000000037, 30.909597000000076],
            [72.73030671600003, 30.91482902100006],
            [72.734425983000051, 30.920032683000045],
            [72.735475540000039, 30.921327591000079],
            [72.738256142000068, 30.934747019000042],
            [72.744212056000038, 30.935523970000077],
            [72.747454997000034, 30.935947013000032],
            [72.749379000000033, 30.936198000000047],
            [72.760503000000028, 30.942001000000062],
            [72.76756509300003, 30.943536707000078],
            [72.76819419900005, 30.943666458000052],
            [72.768495560000076, 30.94372749300004],
            [72.768910694000056, 30.943829318000041],
            [72.77162700000008, 30.944420000000036],
            [72.77167561300007, 30.944507506000036],
            [72.771730423000065, 30.944520950000026],
            [72.776491165000039, 30.953126907000069],
            [72.776358965000043, 30.954633989000058],
            [72.775892833000057, 30.959947898000053],
            [72.775880814000061, 30.960084915000039],
            [72.775835708000045, 30.960972000000027],
            [72.775514603000033, 30.967287064000061],
            [72.787111282000069, 30.978273392000062],
            [72.787891418000072, 30.978848742000025],
            [72.788085953000063, 30.978647731000024],
            [72.796938132000037, 30.98528617900007],
            [72.797421132000068, 30.995926179000037],
            [72.797763962000033, 31.003805602000057],
            [72.797767764000071, 31.003892988000075],
            [72.79783126600006, 31.005352486000049],
            [72.797905132000039, 31.007050179000032],
            [72.800807132000045, 31.018174179000027],
            [72.802741132000051, 31.023010179000039],
            [72.813891413000078, 31.028434842000024],
            [72.81996515700007, 31.031389746000059],
            [72.820636132000061, 31.031716179000057],
            [72.820483205000073, 31.032098517000065],
            [72.820495605000076, 31.03210449200003],
            [72.820312500000057, 31.032897949000073],
            [72.81671142600004, 31.041694697000025],
            [72.815502954000067, 31.045813366000061],
            [72.813865132000046, 31.051545179000072],
            [72.817251132000081, 31.059284179000031],
            [72.817889674000071, 31.060212945000046],
            [72.820310879000033, 31.063734615000044],
            [72.822491756000034, 31.06690672600007],
            [72.822509766000053, 31.066932922000035],
            [72.822509766000053, 31.067061626000054],
            [72.817640331000064, 31.070191771000054],
            [72.809029132000035, 31.075727179000069],
            [72.79803918500005, 31.082515363000027],
            [72.792585132000056, 31.085884179000061],
            [72.790494222000063, 31.084103086000027],
            [72.779526132000058, 31.074760179000066],
            [72.776952100000074, 31.073473163000074],
            [72.769413814000075, 31.069704020000074],
            [72.768886132000034, 31.069440179000026],
            [72.768418409000049, 31.069704020000074],
            [72.766460271000028, 31.070808600000078],
            [72.750024132000078, 31.080080179000049],
            [72.744813037000029, 31.084423257000026],
            [72.743317583000078, 31.085669612000061],
            [72.741319132000058, 31.087335179000036],
            [72.741462157000058, 31.087397363000036],
            [72.741913689000057, 31.087593677000029],
            [72.752442132000056, 31.092171179000047],
            [72.757572871000036, 31.093087680000053],
            [72.765984132000028, 31.094590179000079],
            [72.769645239000056, 31.097865256000034],
            [72.769649842000035, 31.097869374000027],
            [72.775174132000075, 31.102811179000071],
            [72.77752193200007, 31.109464155000069],
            [72.780246371000032, 31.117184416000043],
            [72.780977132000032, 31.119255179000049],
            [72.780711143000076, 31.124848659000065],
            [72.780494132000058, 31.129412179000042],
            [72.778366093000045, 31.140048856000078],
            [72.778075132000083, 31.14150317900004],
            [72.785519763000082, 31.154064490000053],
            [72.785814132000041, 31.154561179000041],
            [72.785675144000038, 31.154854577000037],
            [72.785109214000045, 31.156049231000054],
            [72.781461132000061, 31.163750179000033],
            [72.776625132000049, 31.174391179000054],
            [72.775174132000075, 31.190351179000061],
            [72.779383848000066, 31.196665753000048],
            [72.77913148500005, 31.196771727000055],
            [72.77984900000007, 31.197848000000079],
            [72.789522000000034, 31.202201000000059],
            [72.798711000000083, 31.203652000000034],
            [72.81121137000008, 31.212134196000079],
            [72.811635071000069, 31.212069543000041],
            [72.812414132000072, 31.212598179000054],
            [72.815273771000079, 31.214885938000066],
            [72.82450513200007, 31.222271179000074],
            [72.827911521000033, 31.226939377000065],
            [72.829920849000075, 31.229693008000027],
            [72.836551385000064, 31.238779656000077],
            [72.837563132000071, 31.240166179000028],
            [72.840465132000077, 31.251773179000054],
            [72.84887801800005, 31.256680454000048],
            [72.852073132000044, 31.258544179000069],
            [72.854090841000072, 31.265269642000078],
            [72.85497513200005, 31.268217179000033],
            [72.859811132000061, 31.278374179000025],
            [72.859621541000081, 31.278557228000068],
            [72.859650000000045, 31.278617000000054],
            [72.857506624000052, 31.280686414000058],
            [72.853868412000054, 31.284199081000054],
            [72.853459499000053, 31.28459388300007],
            [72.853422902000034, 31.284541968000042],
            [72.849611524000068, 31.28822182600004],
            [72.84578513200006, 31.291916179000054],
            [72.838357505000033, 31.299937844000056],
            [72.835530256000084, 31.302991208000037],
            [72.833694132000062, 31.304974179000055],
            [72.822060839000073, 31.314453291000063],
            [72.821803899000031, 31.314662652000038],
            [72.821875160000047, 31.314716113000031],
            [72.821875116000058, 31.314716149000049],
            [72.820483495000076, 31.31585007800004],
            [72.819429053000079, 31.317479707000075],
            [72.814456909000057, 31.325164099000062],
            [72.810066191000033, 31.331949907000023],
            [72.810062368000047, 31.331955815000072],
            [72.810062128000084, 31.331956176000062],
            [72.810059760000058, 31.331953638000073],
            [72.809902262000037, 31.332197050000048],
            [72.821108000000038, 31.34420300000005],
            [72.830369000000076, 31.359831000000042],
            [72.830031921000057, 31.360485335000078],
            [72.826322000000062, 31.367687000000046],
            [72.826320739000039, 31.367686964000029],
            [72.826320481000039, 31.367687465000074],
            [72.825076256000045, 31.368068519000076],
            [72.820848900000044, 31.378308116000028],
            [72.820576256000038, 31.378968519000068],
            [72.822698504000073, 31.388427681000053],
            [72.824076256000069, 31.394568519000075],
            [72.829076256000064, 31.402268519000074],
            [72.833876256000053, 31.409568519000061],
            [72.833990098000072, 31.409886491000066],
            [72.839676256000075, 31.42576851900003],
            [72.844976256000052, 31.42896851900008],
            [72.855276256000082, 31.435068519000026],
            [72.856812318000038, 31.438484538000068],
            [72.861976256000048, 31.449968519000038],
            [72.868496556000082, 31.464324138000052],
            [72.873876256000074, 31.476168519000055],
            [72.874833225000032, 31.47712548800007],
            [72.884276256000078, 31.486568519000059],
            [72.892976256000054, 31.499868519000074],
            [72.89717625600008, 31.501968519000059],
            [72.897316931000034, 31.502038857000059],
            [72.90917625600008, 31.50796851900003],
            [72.926980850000064, 31.519353187000036],
            [72.929976256000032, 31.521268519000046],
            [72.934276256000032, 31.524968519000026],
            [72.951798818000043, 31.539881338000043],
            [72.953076256000031, 31.540968519000046],
            [72.967576256000029, 31.546768519000068],
            [72.977438133000078, 31.549432359000036],
            [72.977441006000049, 31.549460671000077],
            [72.979388641000071, 31.549987074000057],
            [72.984948628000041, 31.551489815000025],
            [72.984976256000039, 31.551468519000025],
            [72.988976256000058, 31.552568519000033],
            [72.998876256000074, 31.558668519000037],
            [73.011567495000065, 31.566463138000074],
            [73.011576256000069, 31.566468519000068],
            [73.014349914000036, 31.566308500000048],
            [73.021976256000073, 31.565868519000048],
            [73.034129373000042, 31.575730475000057],
            [73.03833310400006, 31.576571221000052],
            [73.039029846000062, 31.576705764000053],
            [73.048676256000078, 31.578568519000044],
            [73.049817827000084, 31.579606311000077],
            [73.054176256000062, 31.583568519000039],
            [73.056112019000068, 31.585317875000044],
            [73.067676256000084, 31.595768519000046],
            [73.069216898000036, 31.596966796000061],
            [73.06947625600003, 31.597168519000036],
            [73.071665644000063, 31.600611829000059],
            [73.075631634000047, 31.606849250000039],
            [73.080476256000054, 31.61446851900007],
            [73.084637595000061, 31.628038104000041],
            [73.085076256000036, 31.629468519000056],
            [73.101876256000082, 31.638268519000064],
            [73.108753316000048, 31.650951622000036],
            [73.11394269300007, 31.660522194000066],
            [73.114076256000033, 31.660768519000044],
            [73.115076256000066, 31.662468519000072],
            [73.119905593000055, 31.671092336000072],
            [73.12347625600006, 31.677468519000058],
            [73.126176256000065, 31.682268519000047],
            [73.135089125000036, 31.69725834500008],
            [73.137176256000032, 31.700768519000064],
            [73.142976256000054, 31.709268519000034],
            [73.148176256000056, 31.716968519000034],
            [73.149776256000052, 31.718168519000074],
            [73.150453646000074, 31.718675008000048],
            [73.171576256000037, 31.734468519000075],
            [73.175591338000061, 31.737474345000066],
            [73.189876256000048, 31.748168519000046],
            [73.199189648000072, 31.758322218000046],
            [73.203176256000063, 31.762668519000044],
            [73.21417625600003, 31.77536851900004],
            [73.226876256000082, 31.780568519000042],
            [73.228546712000082, 31.783660639000061],
            [73.228547052000067, 31.783661269000049],
            [73.236458000000084, 31.776110000000074],
            [73.24182311800007, 31.769093335000036],
            [73.245336814000041, 31.764498873000036],
            [73.245921000000067, 31.763735000000054],
            [73.253142772000047, 31.761568469000053],
            [73.253143003000048, 31.761567399000057],
            [73.25314323300006, 31.761566334000065],
            [73.253197797000041, 31.761549964000039],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "98",
      properties: { name: "Gujranwala", count: 248 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.208953895000036, 32.53772108000004],
            [74.206106127000055, 32.531432260000031],
            [74.20287872800003, 32.524305087000073],
            [74.202136500000051, 32.522666001000061],
            [74.20614050000006, 32.506650001000025],
            [74.207024595000064, 32.502450550000049],
            [74.20801534900005, 32.497744468000064],
            [74.209052500000041, 32.492818001000046],
            [74.20838027700006, 32.488485898000079],
            [74.208289600000057, 32.487901532000024],
            [74.206743202000041, 32.477935850000051],
            [74.20577650000007, 32.47170600100003],
            [74.204031498000063, 32.465404869000054],
            [74.203032275000055, 32.461796715000048],
            [74.199592238000037, 32.449374888000079],
            [74.19922450100006, 32.448047000000031],
            [74.200469355000052, 32.444810378000057],
            [74.201620711000032, 32.441816853000034],
            [74.202971201000082, 32.43830557800004],
            [74.203628501000082, 32.436596597000062],
            [74.204684501000031, 32.433851000000061],
            [74.205915550000043, 32.430685444000062],
            [74.206322709000062, 32.42963846300006],
            [74.207464739000045, 32.426701815000058],
            [74.209780501000068, 32.420747001000052],
            [74.209926659000075, 32.418262322000032],
            [74.209995412000069, 32.417093516000079],
            [74.210118907000037, 32.414994093000075],
            [74.21050850000006, 32.408371000000045],
            [74.20650450100004, 32.399999000000037],
            [74.207984459000045, 32.397489557000029],
            [74.211324760000082, 32.39182568700005],
            [74.214489857000046, 32.386458895000032],
            [74.214876500000059, 32.385803001000056],
            [74.216470031000028, 32.385030379000057],
            [74.222649392000051, 32.382034325000063],
            [74.224416740000038, 32.381177429000047],
            [74.226692077000052, 32.380074235000052],
            [74.226888501000076, 32.379979000000048],
            [74.226888501000076, 32.372766237000064],
            [74.22688850000003, 32.362432650000073],
            [74.226888501000076, 32.357048001000067],
            [74.226784029000044, 32.356699764000041],
            [74.226766389000034, 32.356640964000064],
            [74.225122750000082, 32.351162167000041],
            [74.224704501000076, 32.349768001000029],
            [74.230994571000053, 32.341058671000042],
            [74.234168501000056, 32.336664001000031],
            [74.235085707000053, 32.332832691000078],
            [74.235858857000039, 32.329603125000062],
            [74.236413141000071, 32.327288058000079],
            [74.239127914000051, 32.315949326000066],
            [74.239127127000074, 32.315948562000074],
            [74.240355000000079, 32.310820000000035],
            [74.241927462000035, 32.297567013000048],
            [74.242608089000043, 32.291830555000047],
            [74.242903000000069, 32.289345000000026],
            [74.243369720000032, 32.287898168000027],
            [74.246543000000031, 32.278061000000037],
            [74.248993409000036, 32.275181769000028],
            [74.254497481000044, 32.268714484000043],
            [74.261103000000048, 32.260953000000029],
            [74.266637124000056, 32.256629466000049],
            [74.268036801000051, 32.255535968000061],
            [74.268882457000075, 32.254875299000048],
            [74.272751000000085, 32.25185300000004],
            [74.272751500000084, 32.251852996000025],
            [74.290951000000064, 32.251489005000053],
            [74.292489075000049, 32.251330666000058],
            [74.296153405000041, 32.250953437000078],
            [74.30567099700005, 32.249973645000068],
            [74.305670976000044, 32.249973729000033],
            [74.315703000000042, 32.248941000000059],
            [74.319526403000054, 32.247520879000035],
            [74.32844300000005, 32.244209001000058],
            [74.328836484000078, 32.238946221000049],
            [74.329654972000071, 32.227999086000068],
            [74.330539329000032, 32.216170970000064],
            [74.33053936500005, 32.216170489000035],
            [74.33095576900007, 32.21060114900007],
            [74.331354992000058, 32.205261603000054],
            [74.331355215000031, 32.205261514000028],
            [74.334974716000033, 32.203813713000045],
            [74.337901539000029, 32.202642984000079],
            [74.342274000000032, 32.200894000000062],
            [74.350706363000029, 32.190437871000029],
            [74.351374000000078, 32.189610000000073],
            [74.353738411000052, 32.188736196000036],
            [74.353738841000052, 32.188736037000069],
            [74.35373891200004, 32.188736196000036],
            [74.363818311000045, 32.185011201000066],
            [74.368118501000083, 32.183422000000064],
            [74.368268767000075, 32.183379067000033],
            [74.375762500000064, 32.181238000000064],
            [74.375763234000033, 32.18123477000006],
            [74.376235379000036, 32.179157337000049],
            [74.376606843000047, 32.17902676600005],
            [74.376649514000064, 32.178915083000049],
            [74.380256813000074, 32.169473587000027],
            [74.384721286000058, 32.168564157000048],
            [74.397665898000071, 32.164824963000058],
            [74.397665962000076, 32.164824954000039],
            [74.39924196700008, 32.164790701000072],
            [74.400213902000075, 32.164769566000075],
            [74.400213997000037, 32.164769570000033],
            [74.412890001000051, 32.164494000000047],
            [74.423937002000059, 32.159505004000039],
            [74.424126775000047, 32.159313418000067],
            [74.432909000000052, 32.161218000000076],
            [74.433664183000076, 32.162106451000056],
            [74.435516158000041, 32.16428524500003],
            [74.43903481600006, 32.168424842000036],
            [74.439097000000061, 32.168498000000056],
            [74.439097355000058, 32.168497829000046],
            [74.448925000000031, 32.163766000000066],
            [74.451546510000071, 32.159623024000041],
            [74.45155587000005, 32.15963096400003],
            [74.45248768700003, 32.158166392000055],
            [74.452524018000076, 32.158109290000027],
            [74.454020000000071, 32.155758000000048],
            [74.462810723000075, 32.154455671000051],
            [74.462843128000031, 32.154450869000073],
            [74.472504915000059, 32.153019494000034],
            [74.472505958000056, 32.153019339000025],
            [74.47250592000006, 32.153019494000034],
            [74.473677000000066, 32.152846001000057],
            [74.490421001000072, 32.149934001000076],
            [74.490421001000072, 32.143962976000068],
            [74.490421001000072, 32.137168734000056],
            [74.490421001000072, 32.136830000000032],
            [74.489311809000071, 32.134858104000045],
            [74.487145001000044, 32.13100600000007],
            [74.491513000000054, 32.125910500000032],
            [74.491641519000041, 32.124561060000076],
            [74.491641555000058, 32.124560682000038],
            [74.491847128000074, 32.122402162000071],
            [74.491846189000057, 32.122402024000053],
            [74.492240000000038, 32.11826700000006],
            [74.492240996000078, 32.118266538000057],
            [74.492843402000062, 32.117987375000041],
            [74.494252703000029, 32.117334284000037],
            [74.500262773000031, 32.114549238000052],
            [74.507164501000034, 32.11135100000007],
            [74.516725793000035, 32.102882428000044],
            [74.517934349000029, 32.101811993000069],
            [74.519904501000042, 32.10006700100007],
            [74.519903920000047, 32.100067071000069],
            [74.519904000000054, 32.100067000000024],
            [74.513032657000053, 32.100891561000026],
            [74.510804477000079, 32.10115894300003],
            [74.510804000000064, 32.101159000000052],
            [74.507164465000074, 32.092060163000042],
            [74.507164000000046, 32.092059000000063],
            [74.507164465000074, 32.092058044000055],
            [74.511986172000036, 32.082146757000032],
            [74.513716000000045, 32.078591000000074],
            [74.522876886000063, 32.070152731000064],
            [74.522877562000076, 32.070152109000048],
            [74.525321960000042, 32.067900526000074],
            [74.527547000000084, 32.065851000000066],
            [74.535421552000059, 32.061257512000054],
            [74.535855065000078, 32.061004629000024],
            [74.536283000000083, 32.060755000000029],
            [74.540429423000035, 32.059372859000064],
            [74.548193087000072, 32.056784970000024],
            [74.549387000000081, 32.056387000000029],
            [74.554380424000044, 32.055469840000058],
            [74.55630138600003, 32.05511701000006],
            [74.56722300000007, 32.053111000000058],
            [74.567223186000035, 32.053111150000063],
            [74.57027335500004, 32.055570964000026],
            [74.576387895000039, 32.060502045000078],
            [74.576388069000075, 32.060501378000026],
            [74.576837846000046, 32.058772730000044],
            [74.575281457000074, 32.055570406000072],
            [74.575079498000036, 32.055154868000045],
            [74.573358799000061, 32.054000142000064],
            [74.568986143000075, 32.051065738000034],
            [74.557734691000064, 32.043515109000055],
            [74.556304000000068, 32.04255500000005],
            [74.548719508000033, 32.037712141000043],
            [74.546414715000083, 32.036240483000029],
            [74.541967140000054, 32.033400612000037],
            [74.536114511000051, 32.029663585000037],
            [74.528619075000051, 32.024877591000063],
            [74.526092000000062, 32.02326400000004],
            [74.526035164000064, 32.022766685000079],
            [74.524962837000032, 32.013383825000062],
            [74.524636000000044, 32.010524000000032],
            [74.518557085000054, 32.007778684000073],
            [74.513352000000054, 32.005428000000052],
            [74.50477988800003, 32.005428000000052],
            [74.497969686000033, 32.005428000000052],
            [74.493172151000067, 32.005428000000052],
            [74.490784000000076, 32.005428000000052],
            [74.477131942000028, 31.996894878000035],
            [74.476225000000056, 31.996328000000062],
            [74.47644527500006, 31.994477689000064],
            [74.478045000000066, 31.981040000000064],
            [74.477332282000077, 31.979456179000067],
            [74.476506670000049, 31.977621487000079],
            [74.476469800000075, 31.977539553000042],
            [74.475007041000083, 31.97428897900005],
            [74.471762408000075, 31.967078684000057],
            [74.471493000000066, 31.966480000000047],
            [74.464348501000075, 31.965600677000054],
            [74.460798226000065, 31.965163720000078],
            [74.44783300000006, 31.963568000000066],
            [74.444291294000038, 31.959609624000052],
            [74.443359397000052, 31.958568091000075],
            [74.442921245000036, 31.958078391000072],
            [74.435457000000042, 31.94973600000003],
            [74.434458711000048, 31.948937381000064],
            [74.431986713000072, 31.946959813000035],
            [74.429748037000081, 31.945168899000066],
            [74.42585301500003, 31.942052929000056],
            [74.419996593000064, 31.937367863000077],
            [74.41907800000007, 31.936633000000029],
            [74.411101681000048, 31.927904198000078],
            [74.399786000000063, 31.915521000000069],
            [74.397495862000085, 31.914757621000035],
            [74.393953900000042, 31.91357696700004],
            [74.385590000000036, 31.910789000000079],
            [74.383030879000046, 31.908307428000057],
            [74.383019365000052, 31.908296263000068],
            [74.378450474000033, 31.903865823000046],
            [74.375745596000058, 31.901242911000054],
            [74.374977052000077, 31.900497656000027],
            [74.373578000000066, 31.899141000000043],
            [74.372008789000063, 31.897519482000064],
            [74.364320498000041, 31.889574914000036],
            [74.363756570000078, 31.888992189000078],
            [74.362658000000067, 31.887857000000054],
            [74.352020974000084, 31.88684388300004],
            [74.351975507000077, 31.886839553000073],
            [74.350791917000038, 31.88672682300006],
            [74.347371000000066, 31.886401000000035],
            [74.340904266000052, 31.887093864000065],
            [74.328052846000048, 31.888470802000029],
            [74.326987000000031, 31.888585000000035],
            [74.320435000000032, 31.894773000000043],
            [74.319203607000077, 31.894333217000053],
            [74.310243000000071, 31.891133000000025],
            [74.309173566000084, 31.889434487000074],
            [74.304055000000062, 31.881305000000054],
            [74.302897313000074, 31.881340081000076],
            [74.292497411000056, 31.881655230000035],
            [74.292258525000079, 31.881662469000048],
            [74.292043000000035, 31.881669000000045],
            [74.284867791000067, 31.881669000000045],
            [74.277484000000072, 31.881669000000045],
            [74.274704055000029, 31.883232719000034],
            [74.269538970000042, 31.886138080000023],
            [74.265886458000068, 31.888192617000072],
            [74.265836000000036, 31.888221000000044],
            [74.252004000000056, 31.881669000000045],
            [74.246351496000045, 31.881548734000035],
            [74.234896000000049, 31.881305000000054],
            [74.224543332000053, 31.886120194000057],
            [74.223156329000062, 31.88676531200008],
            [74.21924400000006, 31.888585000000035],
            [74.20468500000004, 31.883125000000064],
            [74.199401500000079, 31.874539312000024],
            [74.198995297000067, 31.873879232000036],
            [74.198861000000079, 31.873661000000027],
            [74.198216484000056, 31.869955040000036],
            [74.197496948000037, 31.86581770500004],
            [74.19740500000006, 31.865289000000075],
            [74.197046035000085, 31.864375361000043],
            [74.193401000000051, 31.855098000000055],
            [74.192302860000041, 31.854658744000062],
            [74.184301000000062, 31.851458000000036],
            [74.176273865000042, 31.851171317000023],
            [74.175708246000056, 31.85115111600004],
            [74.174180754000076, 31.851096563000056],
            [74.174109000000044, 31.851094000000046],
            [74.165737000000036, 31.850002000000075],
            [74.163829488000033, 31.845590879000042],
            [74.159913000000074, 31.836534000000029],
            [74.148629000000085, 31.82998200000003],
            [74.141535842000053, 31.822580444000039],
            [74.140708796000069, 31.821717439000054],
            [74.140257000000076, 31.821246000000031],
            [74.135985434000077, 31.819867953000028],
            [74.133554132000029, 31.819083593000073],
            [74.128974000000085, 31.817606000000069],
            [74.127978911000071, 31.817719792000048],
            [74.127977958000031, 31.817719901000032],
            [74.122386999000071, 31.818358781000029],
            [74.116232000000082, 31.819062000000031],
            [74.11291063300007, 31.820279835000065],
            [74.112910597000052, 31.820279848000041],
            [74.10667249800008, 31.822567151000044],
            [74.105312321000042, 31.823065882000037],
            [74.101849630000061, 31.82282301500004],
            [74.090257312000062, 31.822009950000052],
            [74.090257037000072, 31.82200946100005],
            [74.088427816000035, 31.821881088000055],
            [74.085664674000043, 31.821687174000033],
            [74.084566000000052, 31.821610000000078],
            [74.084565435000059, 31.821610031000034],
            [74.084565000000055, 31.821610000000078],
            [74.071097000000066, 31.822338000000059],
            [74.060827848000031, 31.827472576000048],
            [74.060858218000078, 31.827508895000051],
            [74.060250715000052, 31.82781264700003],
            [74.057380910000063, 31.829764114000056],
            [74.051150715000063, 31.834000647000039],
            [74.044598715000063, 31.838368647000038],
            [74.041589900000076, 31.838798499000063],
            [74.034510684000054, 31.839809865000063],
            [74.034407215000044, 31.839824647000057],
            [74.034381347000078, 31.839809865000063],
            [74.034371868000051, 31.839804448000052],
            [74.027136490000032, 31.835669784000061],
            [74.021667715000035, 31.832544647000077],
            [74.021598854000047, 31.832532202000039],
            [74.021595000000048, 31.832530000000077],
            [74.001411576000066, 31.828882393000072],
            [73.991383000000042, 31.827070000000049],
            [73.991382835000081, 31.827070151000044],
            [73.991382000000044, 31.827070000000049],
            [73.985882397000069, 31.83209137700004],
            [73.983010000000036, 31.834714000000076],
            [73.982113721000076, 31.838199528000075],
            [73.982113685000058, 31.838199668000073],
            [73.982178851000072, 31.838247563000039],
            [73.982113721000076, 31.838500846000045],
            [73.981126732000064, 31.842339137000067],
            [73.979807715000049, 31.847468647000028],
            [73.977629592000085, 31.852913457000056],
            [73.97762371500005, 31.852928147000057],
            [73.977480443000047, 31.852913457000056],
            [73.963428215000079, 31.851472647000037],
            [73.963422373000071, 31.851464857000053],
            [73.960681545000057, 31.847810140000036],
            [73.956876715000078, 31.842736647000038],
            [73.945228715000042, 31.835456647000058],
            [73.945185693000042, 31.835460558000079],
            [73.945157232000042, 31.835442770000043],
            [73.945156000000054, 31.835442000000057],
            [73.945155127000078, 31.835442079000074],
            [73.945155000000057, 31.835442000000057],
            [73.93954026800003, 31.835952430000077],
            [73.929139000000077, 31.836898000000076],
            [73.919183693000036, 31.839539204000062],
            [73.911303095000051, 31.841629975000046],
            [73.911303000000032, 31.841629972000078],
            [73.898200000000031, 31.841266000000076],
            [73.898199954000063, 31.841266026000028],
            [73.898199000000034, 31.841266000000076],
            [73.895147371000064, 31.843009959000028],
            [73.88800800000007, 31.847090000000037],
            [73.88145650000007, 31.853296684000043],
            [73.881092500000079, 31.853641500000037],
            [73.881092499000033, 31.853641528000026],
            [73.881092000000081, 31.853642000000036],
            [73.880440410000062, 31.866998706000061],
            [73.880512296000063, 31.867049744000042],
            [73.880437715000085, 31.868579647000047],
            [73.880437715000085, 31.874767647000056],
            [73.880377042000077, 31.875111463000053],
            [73.879345715000056, 31.880955647000064],
            [73.878243663000035, 31.88343526400007],
            [73.876433715000076, 31.887507647000064],
            [73.871337715000038, 31.890055647000054],
            [73.865513715000077, 31.895151647000034],
            [73.865197849000083, 31.895263129000057],
            [73.859325715000068, 31.897335647000034],
            [73.855500253000059, 31.901400200000069],
            [73.85350171500005, 31.903523647000043],
            [73.85540701900004, 31.906035184000075],
            [73.861509715000068, 31.914079647000051],
            [73.866425741000057, 31.917684733000044],
            [73.866442584000083, 31.917697084000054],
            [73.872429715000067, 31.922087647000069],
            [73.880437215000029, 31.931915647000039],
            [73.880789072000084, 31.943174581000051],
            [73.880801215000076, 31.943563147000077],
            [73.87601903500007, 31.944519627000034],
            [73.876022053000042, 31.944489832000045],
            [73.872283451000044, 31.945237587000065],
            [73.869809000000032, 31.945732500000076],
            [73.869443000000047, 31.945909172000029],
            [73.859252000000083, 31.950828000000058],
            [73.854476513000066, 31.952287177000073],
            [73.854510892000064, 31.95231384300007],
            [73.85225663600005, 31.953002643000048],
            [73.85220146100005, 31.952982332000033],
            [73.847558089000074, 31.954401140000073],
            [73.846148000000085, 31.954832000000067],
            [73.840215043000057, 31.956059509000056],
            [73.84021500700004, 31.956059516000039],
            [73.840173931000038, 31.956068014000039],
            [73.840177874000062, 31.956097097000054],
            [73.835665715000061, 31.957030647000067],
            [73.829113715000062, 31.962854647000029],
            [73.826571443000034, 31.966244343000028],
            [73.826562827000032, 31.96625583000008],
            [73.823653715000034, 31.970134647000066],
            [73.822245879000036, 31.97114631900007],
            [73.809763624000084, 31.980116079000027],
            [73.809095583000044, 31.980850882000027],
            [73.809095600000035, 31.980877103000068],
            [73.809095715000069, 31.981054647000065],
            [73.810176770000055, 31.98997335200005],
            [73.810243739000043, 31.990525843000057],
            [73.810549468000033, 31.993048111000064],
            [73.810551715000031, 31.993066647000035],
            [73.811696943000072, 31.99478448900004],
            [73.817831715000068, 32.003986647000033],
            [73.82474671500006, 32.014906647000032],
            [73.824739846000057, 32.014973072000032],
            [73.824626069000033, 32.016073372000051],
            [73.822563715000058, 32.036017647000051],
            [73.821623212000077, 32.038604029000055],
            [73.821586189000072, 32.038630598000054],
            [73.82108035300007, 32.04002164700006],
            [73.824405347000038, 32.043839621000075],
            [73.830907353000043, 32.051305647000049],
            [73.828820676000078, 32.05237033800006],
            [73.828824000000054, 32.05238260200008],
            [73.826537854000037, 32.053549069000042],
            [73.813099715000078, 32.060405647000039],
            [73.813044003000073, 32.060441981000054],
            [73.811553225000068, 32.057310371000028],
            [73.809686690000035, 32.059266602000037],
            [73.801419151000061, 32.06793143200008],
            [73.801407780000034, 32.067938880000042],
            [73.801506854000081, 32.06796620800003],
            [73.796355715000061, 32.071325647000037],
            [73.798344100000065, 32.077524729000061],
            [73.800458914000046, 32.084117973000048],
            [73.801305163000052, 32.086756278000053],
            [73.802471036000043, 32.090391060000059],
            [73.80254371500007, 32.090617647000045],
            [73.802538536000043, 32.090641659000028],
            [73.802471036000043, 32.090954596000074],
            [73.802363332000084, 32.091453924000064],
            [73.800349703000052, 32.100789336000048],
            [73.800341709000065, 32.100826398000038],
            [73.798539715000061, 32.109180647000073],
            [73.799780264000049, 32.111868503000039],
            [73.800302130000034, 32.112999212000034],
            [73.800341461000073, 32.113084429000025],
            [73.80290771500006, 32.118644647000053],
            [73.80727571500006, 32.131748647000052],
            [73.807231862000037, 32.13262499800004],
            [73.806956092000064, 32.138135998000052],
            [73.806882644000041, 32.138135998000052],
            [73.806838500000083, 32.139014000000032],
            [73.80380424100008, 32.139182576000053],
            [73.800870257000042, 32.139345589000072],
            [73.793735000000083, 32.139742000000069],
            [73.791219734000038, 32.142701137000074],
            [73.787547000000075, 32.14702200000005],
            [73.784551406000048, 32.152114509000057],
            [73.780267000000038, 32.159398000000067],
            [73.776627000000076, 32.167042000000038],
            [73.766799000000049, 32.174686000000065],
            [73.763210919000073, 32.175246638000033],
            [73.762172642000053, 32.17540886900008],
            [73.755151000000069, 32.176506000000074],
            [73.754787000000078, 32.186334000000045],
            [73.753446346000032, 32.191696307000029],
            [73.752603000000079, 32.195069500000045],
            [73.745323000000042, 32.200893000000065],
            [73.733160569000063, 32.206232783000075],
            [73.730399500000033, 32.207445000000064],
            [73.731231712000067, 32.216183222000041],
            [73.731855500000052, 32.222733000000062],
            [73.727541215000031, 32.228706625000029],
            [73.726645894000058, 32.229946301000041],
            [73.722391500000072, 32.23583700000006],
            [73.713655999000082, 32.244209011000066],
            [73.708196000000044, 32.260953011000026],
            [73.708034611000073, 32.261291160000042],
            [73.708034594000083, 32.261291196000059],
            [73.704121481000072, 32.269490087000065],
            [73.702292148000083, 32.273322975000042],
            [73.702291430000059, 32.273324481000031],
            [73.700552000000073, 32.276969000000065],
            [73.693331876000059, 32.286157647000039],
            [73.688540000000046, 32.292256000000066],
            [73.687496877000058, 32.299706878000052],
            [73.686667378000038, 32.305631874000028],
            [73.685992000000056, 32.310456000000045],
            [73.681892662000052, 32.327830361000053],
            [73.680778157000077, 32.332553988000029],
            [73.678519250000079, 32.342127991000041],
            [73.678519102000052, 32.342128669000033],
            [73.685843500000033, 32.344382000000053],
            [73.687983959000064, 32.344546705000027],
            [73.693368000000078, 32.344961000000069],
            [73.702629000000059, 32.346118500000046],
            [73.704466313000069, 32.346966467000072],
            [73.705669065000052, 32.347521568000047],
            [73.717678000000035, 32.353064000000074],
            [73.71768000000003, 32.353064000000074],
            [73.719031533000077, 32.353064000000074],
            [73.719907768000041, 32.353064000000074],
            [73.724044500000048, 32.353064000000074],
            [73.724574401000041, 32.353196504000039],
            [73.728675500000065, 32.35422200000005],
            [73.729606725000053, 32.358258025000055],
            [73.730411500000059, 32.361746000000039],
            [73.730414000000053, 32.361746000000039],
            [73.730467212000065, 32.361746000000039],
            [73.733114350000051, 32.361746000000039],
            [73.741408500000034, 32.361746000000039],
            [73.741411000000085, 32.361746000000039],
            [73.743091317000051, 32.356032691000053],
            [73.743091353000068, 32.356032569000035],
            [73.743101510000031, 32.355998035000027],
            [73.743100145000028, 32.355995457000063],
            [73.743690499000081, 32.353988621000042],
            [73.744303000000059, 32.351906500000041],
            [73.748874427000032, 32.348410621000028],
            [73.754142500000057, 32.344382000000053],
            [73.759351500000037, 32.343224500000076],
            [73.759351568000056, 32.343224541000041],
            [73.768034000000057, 32.348434000000054],
            [73.769567416000029, 32.351719973000058],
            [73.77208550000006, 32.357116000000076],
            [73.77440050000007, 32.368113000000051],
            [73.774402414000065, 32.368110216000048],
            [73.780767500000081, 32.35885200000007],
            [73.780768046000048, 32.358854842000028],
            [73.782997339000076, 32.370447316000025],
            [73.783018059000028, 32.37055506400003],
            [73.78343359400003, 32.372715873000061],
            [73.783661500000051, 32.373901000000046],
            [73.783663889000081, 32.373900423000066],
            [73.800447500000075, 32.369849500000043],
            [73.800447840000061, 32.369849521000049],
            [73.809708000000057, 32.37042850000006],
            [73.818960074000074, 32.374324088000037],
            [73.820705500000031, 32.375059000000078],
            [73.82662163100008, 32.377550102000043],
            [73.831702500000063, 32.37968950000004],
            [73.835976919000075, 32.384319915000049],
            [73.838648500000033, 32.387214000000029],
            [73.84064662600008, 32.388768119000076],
            [73.848700166000071, 32.395032069000024],
            [73.849066500000049, 32.395317000000034],
            [73.849468654000077, 32.39534214300005],
            [73.859755671000073, 32.395985290000056],
            [73.865855251000085, 32.396366637000028],
            [73.867588500000068, 32.396475000000066],
            [73.867589919000068, 32.396474932000046],
            [73.867591000000061, 32.396475000000066],
            [73.876872137000078, 32.396032896000065],
            [73.876894926000034, 32.396031810000068],
            [73.879744190000054, 32.39589608600005],
            [73.893634500000076, 32.397632500000043],
            [73.893950845000063, 32.397916701000042],
            [73.893951462000075, 32.397917255000038],
            [73.904953995000085, 32.399445631000049],
            [73.912735000000055, 32.400526500000069],
            [73.912964311000053, 32.400598688000059],
            [73.913481949000072, 32.400761642000077],
            [73.920914466000056, 32.403101425000045],
            [73.931045331000064, 32.406290658000046],
            [73.934759823000036, 32.407459993000032],
            [73.939680800000076, 32.40900913400003],
            [73.94399100000004, 32.410366000000067],
            [73.945851993000076, 32.410366000000067],
            [73.950104535000037, 32.410366000000067],
            [73.956145500000048, 32.410366000000067],
            [73.956324566000035, 32.410401813000078],
            [73.966208822000056, 32.412378665000062],
            [73.970451340000068, 32.413281328000039],
            [73.970454681000035, 32.413281973000039],
            [73.970498614000064, 32.413236624000035],
            [73.970721956000034, 32.413281292000079],
            [73.977012383000044, 32.41453937700004],
            [73.978394783000056, 32.414815857000065],
            [73.979271752000045, 32.414991250000071],
            [73.979298000000028, 32.414996500000029],
            [73.986213106000037, 32.418365519000076],
            [73.993646572000046, 32.421987082000044],
            [74.001871000000051, 32.42599400000006],
            [74.002137019000031, 32.426271584000062],
            [74.003776607000077, 32.427982452000037],
            [74.020799563000082, 32.445745467000052],
            [74.027299454000058, 32.452527935000035],
            [74.028451340000061, 32.453781328000048],
            [74.031458981000071, 32.457684862000065],
            [74.033151340000074, 32.459881328000051],
            [74.036429158000033, 32.462026809000065],
            [74.038150589000054, 32.463817059000064],
            [74.042966500000034, 32.468825500000037],
            [74.046988456000065, 32.472847584000078],
            [74.049836571000071, 32.475695790000032],
            [74.055286386000034, 32.481145780000077],
            [74.05827868800003, 32.484138178000023],
            [74.058594000000085, 32.484453500000029],
            [74.067851480000058, 32.472881275000077],
            [74.067854500000067, 32.472877500000038],
            [74.069538928000043, 32.472203714000045],
            [74.078748055000062, 32.468519984000068],
            [74.078844783000079, 32.468481292000035],
            [74.079430500000058, 32.468247000000076],
            [74.079381690000048, 32.468481292000035],
            [74.079375706000064, 32.468510017000028],
            [74.079300173000036, 32.468872584000053],
            [74.076051340000049, 32.477281328000061],
            [74.076051340000049, 32.479381328000045],
            [74.075251340000079, 32.481781328000068],
            [74.082013352000047, 32.485971871000061],
            [74.082325000000083, 32.486190000000079],
            [74.08237328000007, 32.486209312000028],
            [74.089011812000081, 32.488864725000042],
            [74.091007500000046, 32.489663000000064],
            [74.096142459000077, 32.490786281000055],
            [74.09726167000008, 32.491031110000051],
            [74.102865048000069, 32.492256859000065],
            [74.109528500000067, 32.493714500000067],
            [74.117771708000078, 32.498385469000027],
            [74.126893000000052, 32.503554000000065],
            [74.127025896000077, 32.503723155000046],
            [74.128335157000038, 32.505389628000046],
            [74.133013464000044, 32.511344337000025],
            [74.133259500000065, 32.511657500000069],
            [74.139999404000037, 32.514720996000051],
            [74.14432901400005, 32.516688939000062],
            [74.144342223000081, 32.516694943000061],
            [74.146579892000034, 32.517712033000066],
            [74.149465779000082, 32.519023759000049],
            [74.152360500000043, 32.520339500000034],
            [74.154748131000076, 32.521874411000056],
            [74.158408002000044, 32.524227194000048],
            [74.162732481000035, 32.527007225000034],
            [74.165402664000055, 32.528723777000039],
            [74.168551340000079, 32.530781328000046],
            [74.170004854000069, 32.535316291000072],
            [74.171051340000076, 32.538581328000078],
            [74.171448917000077, 32.539724360000037],
            [74.171825291000062, 32.540895354000043],
            [74.172036394000031, 32.541552148000051],
            [74.172471949000055, 32.542907270000057],
            [74.172730527000056, 32.543711771000062],
            [74.173775510000041, 32.546962976000032],
            [74.173776000000032, 32.546964500000058],
            [74.179151340000033, 32.542991493000045],
            [74.185388325000076, 32.538381629000071],
            [74.187088500000073, 32.53712500000006],
            [74.189982500000042, 32.552174000000036],
            [74.190132630000051, 32.552177755000059],
            [74.190151376000074, 32.552178224000045],
            [74.194272567000041, 32.552281292000032],
            [74.195194865000076, 32.552304358000072],
            [74.211572002000082, 32.552713939000057],
            [74.211600500000031, 32.552513001000079],
            [74.211591273000067, 32.552379227000074],
            [74.21087250100004, 32.541958001000069],
            [74.208953895000036, 32.53772108000004],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "99",
      properties: { name: "Gujrat", count: 983 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.950615064000033, 33.011576406000074],
            [73.951698012000065, 33.009951984000054],
            [73.955939041000079, 33.00359043900005],
            [73.958596000000057, 32.999605000000031],
            [73.972207948000062, 32.98206027100008],
            [73.98403200000007, 32.966820000000041],
            [74.000990000000058, 32.954384000000061],
            [74.001853460000063, 32.953979815000025],
            [74.01949632600008, 32.945721198000058],
            [74.027557000000058, 32.941948000000025],
            [74.034189737000077, 32.939001422000047],
            [74.035466848000056, 32.938433811000039],
            [74.035466531000054, 32.938433414000031],
            [74.035466705000033, 32.93843333600006],
            [74.035571646000051, 32.938386691000062],
            [74.035651340000072, 32.93848132800008],
            [74.03815134000007, 32.938381328000048],
            [74.05295134000005, 32.930681328000048],
            [74.074751340000034, 32.931081328000062],
            [74.083151340000029, 32.927981328000044],
            [74.092551340000057, 32.922181328000079],
            [74.092629422000073, 32.922126534000029],
            [74.098251340000047, 32.91818132800006],
            [74.108325777000061, 32.913531589000058],
            [74.108651340000051, 32.913381328000071],
            [74.116351340000051, 32.909181328000045],
            [74.11665675900008, 32.909019636000039],
            [74.123151340000049, 32.905581328000039],
            [74.131851340000082, 32.901981328000034],
            [74.140166436000072, 32.898816065000062],
            [74.146946992000039, 32.896234947000039],
            [74.164324941000075, 32.889619776000075],
            [74.164951340000073, 32.88938132800007],
            [74.169751340000062, 32.886381328000027],
            [74.172841186000085, 32.882593775000032],
            [74.172851340000079, 32.882581328000072],
            [74.176751340000067, 32.88168132800007],
            [74.185151340000061, 32.878681328000027],
            [74.191771823000067, 32.876582151000036],
            [74.201551340000037, 32.873481328000025],
            [74.201651340000069, 32.871781328000054],
            [74.201642593000031, 32.871747708000044],
            [74.201651376000029, 32.871743406000064],
            [74.201778039000033, 32.87168136400004],
            [74.201876823000077, 32.871632978000036],
            [74.212960500000065, 32.866204000000039],
            [74.236664406000045, 32.85574622200005],
            [74.250522434000061, 32.84963228600003],
            [74.250951340000029, 32.849881328000038],
            [74.274851340000055, 32.838081328000044],
            [74.287651340000082, 32.831381328000077],
            [74.289019379000081, 32.830686451000076],
            [74.293951340000035, 32.828181328000028],
            [74.309351340000035, 32.821381328000029],
            [74.325451340000029, 32.814181328000075],
            [74.341251340000042, 32.809681328000067],
            [74.34435134000006, 32.798381328000062],
            [74.345751340000049, 32.793281328000035],
            [74.347723248000079, 32.787501600000041],
            [74.348650495000072, 32.784783804000028],
            [74.34865134000006, 32.784781328000065],
            [74.349351340000055, 32.782581328000049],
            [74.35602679200008, 32.778882768000074],
            [74.356598827000084, 32.778425140000024],
            [74.35714734000004, 32.777986328000054],
            [74.358751340000083, 32.775981328000057],
            [74.360230831000081, 32.775603585000056],
            [74.366943573000071, 32.772087320000026],
            [74.366951340000071, 32.77208132800007],
            [74.367595463000043, 32.771745847000034],
            [74.37175134000006, 32.769581328000072],
            [74.373851340000044, 32.769281328000034],
            [74.382451340000046, 32.768281328000057],
            [74.388629540000068, 32.767300662000025],
            [74.388751340000056, 32.767281328000024],
            [74.391551340000035, 32.767381328000056],
            [74.406512184000064, 32.771050970000033],
            [74.40745134000008, 32.771281328000043],
            [74.407510890000083, 32.771294355000066],
            [74.410651340000072, 32.771981328000038],
            [74.413251340000045, 32.773481328000059],
            [74.422751340000048, 32.77918132800005],
            [74.422936992000075, 32.779297670000062],
            [74.430251340000041, 32.783881328000064],
            [74.430426462000071, 32.783292281000058],
            [74.43131522200008, 32.780302815000027],
            [74.431351340000049, 32.780181328000026],
            [74.433881062000069, 32.771732057000065],
            [74.436351340000044, 32.763481328000069],
            [74.43903872900006, 32.758202528000027],
            [74.439151322000043, 32.757981364000045],
            [74.43915134000008, 32.757981328000028],
            [74.440425925000056, 32.755262214000027],
            [74.440538957000058, 32.75495137200005],
            [74.440610000000049, 32.754756000000043],
            [74.444002810000029, 32.747970179000049],
            [74.447479318000035, 32.741016957000056],
            [74.44885898800004, 32.738257535000059],
            [74.452261528000065, 32.731452251000064],
            [74.455164327000034, 32.725646481000069],
            [74.456318492000037, 32.723338081000065],
            [74.456987274000085, 32.722000478000041],
            [74.457395000000076, 32.721185000000048],
            [74.460868000000062, 32.707873000000063],
            [74.461062566000066, 32.704955511000037],
            [74.46110257600003, 32.704996165000068],
            [74.461067163000052, 32.704886587000033],
            [74.461339386000077, 32.70080465500007],
            [74.461369509000065, 32.70035297000004],
            [74.462026000000037, 32.690509000000077],
            [74.461992081000062, 32.690407254000036],
            [74.45965134000005, 32.687481328000047],
            [74.45625605500004, 32.673201160000076],
            [74.456253364000077, 32.673193087000072],
            [74.456247977000032, 32.673176929000078],
            [74.456237000000044, 32.673144000000036],
            [74.455907508000053, 32.672714215000042],
            [74.455906531000039, 32.672712940000054],
            [74.45145592700004, 32.666907630000026],
            [74.446177857000066, 32.660022983000033],
            [74.446189506000053, 32.660017725000046],
            [74.446183409000071, 32.660009749000039],
            [74.446171747000051, 32.660015013000077],
            [74.443233064000083, 32.656181834000051],
            [74.442925000000059, 32.65578000000005],
            [74.440643353000041, 32.655323710000062],
            [74.436060624000049, 32.654407243000037],
            [74.43600880200006, 32.654396879000046],
            [74.431349000000068, 32.65346500000004],
            [74.42874495500007, 32.651579372000072],
            [74.426506888000063, 32.649958753000078],
            [74.426385026000048, 32.649870511000074],
            [74.424466521000056, 32.648481292000042],
            [74.424451376000036, 32.648470325000062],
            [74.424320207000051, 32.648375344000044],
            [74.414563000000044, 32.641310000000033],
            [74.411539454000035, 32.639392620000024],
            [74.400885506000066, 32.632636426000033],
            [74.400423004000061, 32.632343130000038],
            [74.39418304700007, 32.628386065000029],
            [74.391454051000039, 32.626655473000028],
            [74.391051340000047, 32.626481328000068],
            [74.390807142000028, 32.626265384000078],
            [74.390180959000077, 32.625711652000064],
            [74.385734753000065, 32.62195994800004],
            [74.382648242000073, 32.619355554000038],
            [74.378721976000065, 32.616042575000051],
            [74.374288338000042, 32.612301476000027],
            [74.372400541000047, 32.610892672000034],
            [74.372486743000081, 32.61078129200007],
            [74.372311000000082, 32.610633000000064],
            [74.361833098000034, 32.603739831000041],
            [74.358051376000049, 32.601251924000053],
            [74.350633403000074, 32.596371812000029],
            [74.350333830000068, 32.59617473000003],
            [74.350316000000078, 32.596163000000047],
            [74.350087849000033, 32.596082041000045],
            [74.343443176000051, 32.593724206000047],
            [74.332373000000075, 32.589796000000035],
            [74.332168038000077, 32.589778462000027],
            [74.332259996000062, 32.589656131000027],
            [74.332256243000074, 32.589655939000068],
            [74.332164073000058, 32.589778123000031],
            [74.328868017000048, 32.589496096000062],
            [74.328642798000033, 32.589476794000063],
            [74.324251340000046, 32.589381328000059],
            [74.322193880000043, 32.588924115000054],
            [74.319077554000046, 32.588657042000079],
            [74.318827679000037, 32.588635627000031],
            [74.312111000000073, 32.588060001000031],
            [74.312032412000065, 32.588034852000078],
            [74.309702919000074, 32.587289398000053],
            [74.30204576400007, 32.584839055000032],
            [74.29894395000008, 32.583846453000035],
            [74.297892862000083, 32.583510098000033],
            [74.297641000000056, 32.583429500000079],
            [74.296246778000068, 32.583997520000025],
            [74.296000283000069, 32.584097944000064],
            [74.286874174000047, 32.587816011000029],
            [74.282053007000059, 32.589780202000043],
            [74.282050790000085, 32.589781105000043],
            [74.282013000000063, 32.589796501000023],
            [74.277835829000082, 32.588092663000054],
            [74.273568651000062, 32.586352111000053],
            [74.273563086000081, 32.586336550000055],
            [74.273562157000072, 32.586349462000044],
            [74.267798930000083, 32.583998684000051],
            [74.258483711000054, 32.580199073000074],
            [74.253551647000052, 32.57818732000004],
            [74.253544401000056, 32.578184364000037],
            [74.249480597000058, 32.576526768000065],
            [74.246075131000055, 32.575137703000053],
            [74.244095936000065, 32.574330403000033],
            [74.238034307000078, 32.571857908000027],
            [74.238023500000054, 32.571853500000032],
            [74.23795814600004, 32.571763249000071],
            [74.236268100000075, 32.569429373000048],
            [74.23078444500004, 32.561856697000053],
            [74.227688466000075, 32.557581292000066],
            [74.22765137600004, 32.557530072000077],
            [74.225868500000047, 32.555068001000052],
            [74.224663068000041, 32.554848840000034],
            [74.216448796000066, 32.55335539400005],
            [74.213283782000076, 32.552779959000077],
            [74.213284520000059, 32.552776881000057],
            [74.213282217000028, 32.552779675000068],
            [74.213135501000068, 32.552753000000052],
            [74.211873174000061, 32.552721463000069],
            [74.211572002000082, 32.552713939000057],
            [74.195194865000076, 32.552304358000072],
            [74.194272567000041, 32.552281292000032],
            [74.190151376000074, 32.552178224000045],
            [74.190132630000051, 32.552177755000059],
            [74.189982500000042, 32.552174000000036],
            [74.187088500000073, 32.53712500000006],
            [74.185388325000076, 32.538381629000071],
            [74.179151340000033, 32.542991493000045],
            [74.173776000000032, 32.546964500000058],
            [74.173775510000041, 32.546962976000032],
            [74.172730527000056, 32.543711771000062],
            [74.172471949000055, 32.542907270000057],
            [74.172036394000031, 32.541552148000051],
            [74.171825291000062, 32.540895354000043],
            [74.171448917000077, 32.539724360000037],
            [74.171051340000076, 32.538581328000078],
            [74.170004854000069, 32.535316291000072],
            [74.168551340000079, 32.530781328000046],
            [74.165402664000055, 32.528723777000039],
            [74.162732481000035, 32.527007225000034],
            [74.158408002000044, 32.524227194000048],
            [74.154748131000076, 32.521874411000056],
            [74.152360500000043, 32.520339500000034],
            [74.149465779000082, 32.519023759000049],
            [74.146579892000034, 32.517712033000066],
            [74.144342223000081, 32.516694943000061],
            [74.14432901400005, 32.516688939000062],
            [74.139999404000037, 32.514720996000051],
            [74.133259500000065, 32.511657500000069],
            [74.133013464000044, 32.511344337000025],
            [74.128335157000038, 32.505389628000046],
            [74.127025896000077, 32.503723155000046],
            [74.126893000000052, 32.503554000000065],
            [74.117771708000078, 32.498385469000027],
            [74.109528500000067, 32.493714500000067],
            [74.102865048000069, 32.492256859000065],
            [74.09726167000008, 32.491031110000051],
            [74.096142459000077, 32.490786281000055],
            [74.091007500000046, 32.489663000000064],
            [74.089011812000081, 32.488864725000042],
            [74.08237328000007, 32.486209312000028],
            [74.082325000000083, 32.486190000000079],
            [74.082013352000047, 32.485971871000061],
            [74.075251340000079, 32.481781328000068],
            [74.076051340000049, 32.479381328000045],
            [74.076051340000049, 32.477281328000061],
            [74.079300173000036, 32.468872584000053],
            [74.079375706000064, 32.468510017000028],
            [74.079381690000048, 32.468481292000035],
            [74.079430500000058, 32.468247000000076],
            [74.078844783000079, 32.468481292000035],
            [74.078748055000062, 32.468519984000068],
            [74.069538928000043, 32.472203714000045],
            [74.067854500000067, 32.472877500000038],
            [74.067851480000058, 32.472881275000077],
            [74.058594000000085, 32.484453500000029],
            [74.05827868800003, 32.484138178000023],
            [74.055286386000034, 32.481145780000077],
            [74.049836571000071, 32.475695790000032],
            [74.046988456000065, 32.472847584000078],
            [74.042966500000034, 32.468825500000037],
            [74.038150589000054, 32.463817059000064],
            [74.036429158000033, 32.462026809000065],
            [74.033151340000074, 32.459881328000051],
            [74.031458981000071, 32.457684862000065],
            [74.028451340000061, 32.453781328000048],
            [74.027299454000058, 32.452527935000035],
            [74.020799563000082, 32.445745467000052],
            [74.003776607000077, 32.427982452000037],
            [74.002137019000031, 32.426271584000062],
            [74.001871000000051, 32.42599400000006],
            [73.993646572000046, 32.421987082000044],
            [73.986213106000037, 32.418365519000076],
            [73.979298000000028, 32.414996500000029],
            [73.979271752000045, 32.414991250000071],
            [73.978394783000056, 32.414815857000065],
            [73.977012383000044, 32.41453937700004],
            [73.970721956000034, 32.413281292000079],
            [73.970498614000064, 32.413236624000035],
            [73.970454681000035, 32.413281973000039],
            [73.970451340000068, 32.413281328000039],
            [73.966208822000056, 32.412378665000062],
            [73.956324566000035, 32.410401813000078],
            [73.956145500000048, 32.410366000000067],
            [73.950104535000037, 32.410366000000067],
            [73.945851993000076, 32.410366000000067],
            [73.94399100000004, 32.410366000000067],
            [73.939680800000076, 32.40900913400003],
            [73.934759823000036, 32.407459993000032],
            [73.931045331000064, 32.406290658000046],
            [73.920914466000056, 32.403101425000045],
            [73.913481949000072, 32.400761642000077],
            [73.912964311000053, 32.400598688000059],
            [73.912735000000055, 32.400526500000069],
            [73.904953995000085, 32.399445631000049],
            [73.893951462000075, 32.397917255000038],
            [73.893950845000063, 32.397916701000042],
            [73.891901000000075, 32.397632000000044],
            [73.889007000000049, 32.412681000000077],
            [73.882661435000045, 32.423064923000027],
            [73.882651364000083, 32.423081404000072],
            [73.885251340000082, 32.431281328000068],
            [73.880951340000081, 32.442181328000061],
            [73.880531818000065, 32.442491659000041],
            [73.873934359000032, 32.447675668000045],
            [73.872800000000041, 32.448567000000025],
            [73.864256389000047, 32.453906642000049],
            [73.86353900000006, 32.454355000000078],
            [73.863131527000064, 32.457005158000072],
            [73.862382000000082, 32.461880000000065],
            [73.862110721000079, 32.462271767000061],
            [73.857172000000048, 32.469404000000054],
            [73.856002379000074, 32.469487575000073],
            [73.849426536000067, 32.469957452000074],
            [73.84878023300007, 32.470003634000079],
            [73.844551304000049, 32.470305812000049],
            [73.844243774000063, 32.470327787000031],
            [73.842326677000074, 32.47046477300006],
            [73.840966000000037, 32.470562000000029],
            [73.836125785000036, 32.474018955000076],
            [73.832862000000034, 32.476350000000025],
            [73.830961406000029, 32.476550135000025],
            [73.821892805000061, 32.477505072000042],
            [73.821865000000059, 32.477508000000057],
            [73.821825015000059, 32.477581292000025],
            [73.821557827000049, 32.478071047000071],
            [73.821444239000073, 32.47803975100004],
            [73.821452316000034, 32.478067311000075],
            [73.821496197000044, 32.478184016000057],
            [73.818867108000063, 32.483003128000064],
            [73.818392000000074, 32.483874000000071],
            [73.818221687000062, 32.483997018000025],
            [73.811851340000032, 32.489681328000074],
            [73.809815055000058, 32.490069192000078],
            [73.807651340000064, 32.490481328000044],
            [73.806368569000028, 32.490828023000063],
            [73.804255954000041, 32.491399000000058],
            [73.803951340000083, 32.491481328000077],
            [73.803956123000034, 32.491399000000058],
            [73.802765000000079, 32.491399000000058],
            [73.798285705000069, 32.496997796000073],
            [73.795819000000051, 32.50008100000008],
            [73.790610000000072, 32.511078000000055],
            [73.790604871000085, 32.511142095000025],
            [73.790421301000038, 32.513436080000076],
            [73.790372899000033, 32.514040940000029],
            [73.789645380000081, 32.523132417000056],
            [73.790751340000043, 32.525581328000044],
            [73.78945200000004, 32.531878130000052],
            [73.78945200000004, 32.531915000000026],
            [73.78945200000004, 32.541755000000023],
            [73.789460277000046, 32.541821199000026],
            [73.789727180000057, 32.543955967000045],
            [73.790031000000056, 32.546386000000041],
            [73.790614122000079, 32.546969122000064],
            [73.791426207000029, 32.547781207000071],
            [73.791495549000047, 32.547850549000032],
            [73.791554231000077, 32.547909231000062],
            [73.794300884000052, 32.550655884000037],
            [73.79525134000005, 32.551581328000054],
            [73.798151340000061, 32.556181328000037],
            [73.799234803000047, 32.558794385000056],
            [73.799870000000055, 32.560277000000042],
            [73.799870000000055, 32.56223090800006],
            [73.799870000000055, 32.563750000000027],
            [73.799851340000032, 32.563773327000035],
            [73.799834401000055, 32.563794503000054],
            [73.798484921000068, 32.565481499000043],
            [73.795430302000057, 32.569300102000057],
            [73.79525134000005, 32.569581328000027],
            [73.790712427000074, 32.570361454000079],
            [73.788873000000081, 32.570696000000055],
            [73.788828885000044, 32.570703783000056],
            [73.785525155000073, 32.571286636000025],
            [73.779372678000072, 32.572372073000054],
            [73.779033000000084, 32.572432000000049],
            [73.777952031000041, 32.572432000000049],
            [73.773268827000038, 32.572432000000049],
            [73.763406000000032, 32.572432000000049],
            [73.759866506000037, 32.573847879000027],
            [73.755867828000078, 32.57544744300003],
            [73.754951304000031, 32.57581407300006],
            [73.754724000000067, 32.575905000000034],
            [73.754860179000048, 32.57674152900006],
            [73.749651340000071, 32.580181328000037],
            [73.743751340000074, 32.582281328000079],
            [73.741351379000037, 32.584481292000078],
            [73.741351340000051, 32.584481328000038],
            [73.737251340000057, 32.588281328000051],
            [73.737206835000052, 32.588552808000031],
            [73.737181322000083, 32.588549836000027],
            [73.73710200000005, 32.589027000000044],
            [73.736201000000051, 32.594427000000053],
            [73.73617603200006, 32.594486302000064],
            [73.734111172000041, 32.599390680000056],
            [73.731571000000031, 32.605424000000028],
            [73.72607060200005, 32.614132303000076],
            [73.724951340000075, 32.617181328000072],
            [73.715235656000061, 32.624706221000054],
            [73.714786000000061, 32.625103000000024],
            [73.706682000000058, 32.62568200000004],
            [73.700834368000073, 32.62568200000004],
            [73.700780842000029, 32.62568200000004],
            [73.698579000000052, 32.62568200000004],
            [73.69856061400003, 32.625704064000047],
            [73.692791000000057, 32.632628000000068],
            [73.688880310000059, 32.635365753000031],
            [73.687736213000051, 32.636166700000047],
            [73.687003000000061, 32.636680000000069],
            [73.68365130400008, 32.638595137000038],
            [73.68321895400004, 32.638842179000051],
            [73.682625469000072, 32.639181292000046],
            [73.680298503000074, 32.640510905000042],
            [73.679273139000031, 32.641096791000052],
            [73.678900000000056, 32.641310000000033],
            [73.67753437600004, 32.643515869000055],
            [73.676333939000074, 32.645454914000027],
            [73.675051340000039, 32.647581328000058],
            [73.672409440000081, 32.651794087000042],
            [73.671351340000058, 32.653481328000055],
            [73.671451340000033, 32.658781328000032],
            [73.671947155000055, 32.662152868000078],
            [73.671951340000078, 32.662181328000031],
            [73.665973990000055, 32.667273144000035],
            [73.663852129000077, 32.669092032000037],
            [73.663851000000079, 32.669093000000032],
            [73.663851000000079, 32.676287785000056],
            [73.663851000000079, 32.676309442000047],
            [73.663851000000079, 32.68066900000008],
            [73.663851833000081, 32.680679848000068],
            [73.663866607000045, 32.680872166000029],
            [73.664451340000085, 32.688181328000042],
            [73.659973773000047, 32.692360390000033],
            [73.659681732000081, 32.692145473000039],
            [73.654640885000049, 32.696297000000072],
            [73.644800885000052, 32.698612000000026],
            [73.64475716700008, 32.698610314000064],
            [73.644750000000045, 32.698612000000026],
            [73.64194993600006, 32.698504270000058],
            [73.641950000000065, 32.698504000000071],
            [73.63047292400006, 32.698062691000075],
            [73.629701000000068, 32.698033000000066],
            [73.627842800000053, 32.700355950000073],
            [73.625071000000048, 32.703821000000062],
            [73.624381521000032, 32.705406873000072],
            [73.619283000000053, 32.717134000000044],
            [73.609443000000056, 32.72523700000005],
            [73.608236980000072, 32.725639065000053],
            [73.608233836000068, 32.725640113000054],
            [73.605012474000034, 32.726714055000059],
            [73.60501247500008, 32.726713948000054],
            [73.595551000000057, 32.729868000000067],
            [73.595551000000057, 32.739707000000067],
            [73.592089157000032, 32.746054713000035],
            [73.59274170100008, 32.746163460000048],
            [73.593405000000075, 32.746274000000028],
            [73.600563163000061, 32.759213966000061],
            [73.605003465000038, 32.767240795000077],
            [73.605242000000032, 32.767672000000061],
            [73.607658086000072, 32.772284499000079],
            [73.616185348000045, 32.788563714000077],
            [73.620266000000072, 32.796354000000065],
            [73.621057416000042, 32.799717699000041],
            [73.622400593000066, 32.805426511000064],
            [73.623073839000028, 32.80828796000003],
            [73.623072949000061, 32.808288427000036],
            [73.623081487000036, 32.808324718000051],
            [73.62308237700006, 32.808324248000076],
            [73.624649845000079, 32.814986346000069],
            [73.625723512000036, 32.819549677000055],
            [73.625729000000035, 32.819573000000048],
            [73.631801650000057, 32.831338578000043],
            [73.63180225800005, 32.831339755000045],
            [73.634856825000043, 32.837257885000042],
            [73.635725692000051, 32.838941289000047],
            [73.638767703000042, 32.844835095000064],
            [73.638951506000069, 32.845191207000028],
            [73.64029800000003, 32.847800000000063],
            [73.642772471000058, 32.850745916000051],
            [73.643806538000035, 32.851976997000065],
            [73.646226199000068, 32.85485766000005],
            [73.646357501000068, 32.855013978000045],
            [73.650055892000069, 32.859417000000064],
            [73.65103281100005, 32.860580045000063],
            [73.651600339000083, 32.861255700000072],
            [73.653240923000055, 32.86320885400005],
            [73.654665083000054, 32.864904350000074],
            [73.657289354000056, 32.868028606000053],
            [73.659419000000071, 32.870564000000059],
            [73.661555178000071, 32.871880473000033],
            [73.666859635000037, 32.875149475000057],
            [73.669767981000064, 32.876941814000077],
            [73.67073973600003, 32.877540683000063],
            [73.670905618000063, 32.877642912000056],
            [73.673997541000062, 32.879548385000078],
            [73.673997549000035, 32.879548777000025],
            [73.674011399000051, 32.879557312000031],
            [73.674011403000065, 32.879556928000056],
            [73.676406947000032, 32.881033240000079],
            [73.678735474000064, 32.88246825300007],
            [73.680521515000066, 32.883568944000046],
            [73.681059592000054, 32.883900547000053],
            [73.685709705000079, 32.88676629400004],
            [73.689198572000066, 32.888916395000024],
            [73.690748331000066, 32.889871472000038],
            [73.694815561000041, 32.892378002000044],
            [73.697102405000066, 32.893787337000049],
            [73.697118733000082, 32.89379739900005],
            [73.69857200000007, 32.894693000000075],
            [73.698915083000031, 32.894963853000036],
            [73.70218684200006, 32.897546762000047],
            [73.702475125000035, 32.897774349000031],
            [73.706585683000071, 32.901019457000075],
            [73.710417087000053, 32.904044186000078],
            [73.715603498000064, 32.908138634000068],
            [73.724862468000083, 32.915448193000032],
            [73.728904812000053, 32.918639449000068],
            [73.730593448000036, 32.919972555000072],
            [73.73135364500007, 32.920572698000058],
            [73.733173000000079, 32.92200900000006],
            [73.740535349000083, 32.929126081000049],
            [73.740535429000033, 32.929127125000036],
            [73.742529140000045, 32.931054418000031],
            [73.742533135000031, 32.931057313000053],
            [73.747894598000073, 32.936240166000061],
            [73.748537934000069, 32.936862069000028],
            [73.748622298000043, 32.936943623000047],
            [73.749682227000051, 32.937968241000078],
            [73.75074967200004, 32.939000127000043],
            [73.754110819000061, 32.942249301000061],
            [73.760489000000064, 32.948415000000068],
            [73.76341910900004, 32.951034331000074],
            [73.765119674000061, 32.952554528000064],
            [73.767748263000044, 32.954904319000036],
            [73.769823712000061, 32.956759638000051],
            [73.771055555000032, 32.957860828000037],
            [73.771054980000031, 32.957861208000054],
            [73.771059920000084, 32.957865624000078],
            [73.771060500000033, 32.957865248000076],
            [73.776628877000064, 32.962843023000062],
            [73.783655000000067, 32.969123930000023],
            [73.784943281000039, 32.970275571000059],
            [73.790537000000029, 32.975276000000065],
            [73.79084957300006, 32.976031429000045],
            [73.790848802000085, 32.976031843000044],
            [73.790857178000067, 32.97605208300007],
            [73.79085807000007, 32.976051964000078],
            [73.791405789000066, 32.977375692000066],
            [73.79178134600005, 32.978283340000075],
            [73.793532830000061, 32.982516334000024],
            [73.793560979000063, 32.982584364000047],
            [73.795763204000082, 32.987906709000072],
            [73.796000000000049, 32.988479000000041],
            [73.79568482600007, 32.992578687000048],
            [73.795239757000047, 32.998368012000071],
            [73.795188013000029, 32.999041078000062],
            [73.795090000000073, 33.000316000000055],
            [73.783253000000059, 33.003048000000035],
            [73.782797000000073, 33.018072000000075],
            [73.782884959000057, 33.018862629000068],
            [73.782884983000031, 33.018862849000072],
            [73.782885000000078, 33.018863000000067],
            [73.794974263000029, 33.014951335000035],
            [73.79523800000004, 33.01486600000004],
            [73.816152000000045, 33.010344000000032],
            [73.816152316000057, 33.010344039000074],
            [73.816152634000048, 33.010344080000039],
            [73.816153267000061, 33.010344159000056],
            [73.816153452000037, 33.010344119000024],
            [73.816153634000045, 33.010344080000039],
            [73.81615400000004, 33.010344000000032],
            [73.816154634000043, 33.010344080000039],
            [73.816154267000059, 33.010344159000056],
            [73.816154085000051, 33.010344199000031],
            [73.816153901000064, 33.010344238000073],
            [73.829718000000071, 33.01204000000007],
            [73.846670255000049, 33.020516127000064],
            [73.85528174600006, 33.024822000000029],
            [73.856850000000065, 33.025606000000039],
            [73.877642705000085, 33.034597118000079],
            [73.877765000000068, 33.034650000000056],
            [73.882869866000078, 33.03551890500006],
            [73.887581723000039, 33.036320916000079],
            [73.895578157000045, 33.037681998000039],
            [73.904332000000068, 33.039172000000065],
            [73.904332836000037, 33.039171802000055],
            [73.904333254000051, 33.039171703000079],
            [73.925811000000067, 33.034085000000061],
            [73.945030000000031, 33.019954000000041],
            [73.947839035000072, 33.015740448000031],
            [73.950615064000033, 33.011576406000074],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "100",
      properties: { name: "Hafizabad", count: 1063 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.713655999000082, 32.244209011000066],
            [73.722391500000072, 32.23583700000006],
            [73.726645894000058, 32.229946301000041],
            [73.727541215000031, 32.228706625000029],
            [73.731855500000052, 32.222733000000062],
            [73.731231712000067, 32.216183222000041],
            [73.730399500000033, 32.207445000000064],
            [73.733160569000063, 32.206232783000075],
            [73.745323000000042, 32.200893000000065],
            [73.752603000000079, 32.195069500000045],
            [73.753446346000032, 32.191696307000029],
            [73.754787000000078, 32.186334000000045],
            [73.755151000000069, 32.176506000000074],
            [73.762172642000053, 32.17540886900008],
            [73.763210919000073, 32.175246638000033],
            [73.766799000000049, 32.174686000000065],
            [73.776627000000076, 32.167042000000038],
            [73.780267000000038, 32.159398000000067],
            [73.784551406000048, 32.152114509000057],
            [73.787547000000075, 32.14702200000005],
            [73.791219734000038, 32.142701137000074],
            [73.793735000000083, 32.139742000000069],
            [73.800870257000042, 32.139345589000072],
            [73.80380424100008, 32.139182576000053],
            [73.806838500000083, 32.139014000000032],
            [73.806882644000041, 32.138135998000052],
            [73.806956092000064, 32.138135998000052],
            [73.807231862000037, 32.13262499800004],
            [73.80727571500006, 32.131748647000052],
            [73.80290771500006, 32.118644647000053],
            [73.800341461000073, 32.113084429000025],
            [73.800302130000034, 32.112999212000034],
            [73.799780264000049, 32.111868503000039],
            [73.798539715000061, 32.109180647000073],
            [73.800341709000065, 32.100826398000038],
            [73.800349703000052, 32.100789336000048],
            [73.802363332000084, 32.091453924000064],
            [73.802471036000043, 32.090954596000074],
            [73.802538536000043, 32.090641659000028],
            [73.80254371500007, 32.090617647000045],
            [73.802471036000043, 32.090391060000059],
            [73.801305163000052, 32.086756278000053],
            [73.800458914000046, 32.084117973000048],
            [73.798344100000065, 32.077524729000061],
            [73.796355715000061, 32.071325647000037],
            [73.801506854000081, 32.06796620800003],
            [73.801407780000034, 32.067938880000042],
            [73.801419151000061, 32.06793143200008],
            [73.809686690000035, 32.059266602000037],
            [73.811553225000068, 32.057310371000028],
            [73.813044003000073, 32.060441981000054],
            [73.813099715000078, 32.060405647000039],
            [73.826537854000037, 32.053549069000042],
            [73.828824000000054, 32.05238260200008],
            [73.828820676000078, 32.05237033800006],
            [73.830907353000043, 32.051305647000049],
            [73.824405347000038, 32.043839621000075],
            [73.82108035300007, 32.04002164700006],
            [73.821586189000072, 32.038630598000054],
            [73.821623212000077, 32.038604029000055],
            [73.822563715000058, 32.036017647000051],
            [73.824626069000033, 32.016073372000051],
            [73.824739846000057, 32.014973072000032],
            [73.82474671500006, 32.014906647000032],
            [73.817831715000068, 32.003986647000033],
            [73.811696943000072, 31.99478448900004],
            [73.810551715000031, 31.993066647000035],
            [73.810549468000033, 31.993048111000064],
            [73.810243739000043, 31.990525843000057],
            [73.810176770000055, 31.98997335200005],
            [73.809095715000069, 31.981054647000065],
            [73.809095600000035, 31.980877103000068],
            [73.809023000000082, 31.98083630900004],
            [73.809022868000056, 31.980836235000027],
            [73.794462000000067, 31.972668000000056],
            [73.791237188000082, 31.971168087000024],
            [73.778810911000051, 31.965388423000036],
            [73.778810000000078, 31.965388000000075],
            [73.778051360000063, 31.964861166000048],
            [73.76570600000008, 31.956288000000029],
            [73.758790000000033, 31.946824500000048],
            [73.756742372000076, 31.945104398000069],
            [73.749690500000042, 31.939180500000077],
            [73.749680280000064, 31.939160063000031],
            [73.746414500000071, 31.932629000000077],
            [73.743138500000043, 31.92534900000004],
            [73.74313576600008, 31.925347070000043],
            [73.740240044000075, 31.923303031000046],
            [73.736950500000034, 31.92098100000004],
            [73.736950075000038, 31.920981053000048],
            [73.736950000000036, 31.92098100000004],
            [73.734102913000072, 31.921336886000063],
            [73.731231465000064, 31.921695817000057],
            [73.725575535000075, 31.922402808000072],
            [73.725302451000061, 31.922436944000026],
            [73.720207000000073, 31.916613000000041],
            [73.720183213000041, 31.916494065000052],
            [73.720099429000072, 31.916398293000043],
            [73.718674660000033, 31.909274449000065],
            [73.713450708000039, 31.90526778800006],
            [73.713462724000067, 31.90526129400007],
            [73.71294006100004, 31.904860697000061],
            [73.712939789000075, 31.904860489000043],
            [73.712925127000062, 31.904864678000024],
            [73.712925398000039, 31.904864886000041],
            [73.712925000000041, 31.904865000000029],
            [73.692179176000082, 31.888949135000075],
            [73.692179000000067, 31.888949000000025],
            [73.689604115000066, 31.889031180000075],
            [73.689604126000063, 31.889031317000047],
            [73.673832172000061, 31.889534678000075],
            [73.67383221700004, 31.889534782000055],
            [73.673832136000044, 31.889534785000023],
            [73.671428816000059, 31.889611487000025],
            [73.67127664700007, 31.889616343000057],
            [73.65796400000005, 31.890041000000053],
            [73.65014962500004, 31.887892047000037],
            [73.644323725000049, 31.886289924000039],
            [73.641648033000081, 31.885554109000054],
            [73.628844000000072, 31.882033000000035],
            [73.623471719000065, 31.878567012000076],
            [73.606276000000037, 31.867473000000075],
            [73.597056786000053, 31.869715511000038],
            [73.592808000000048, 31.870749000000046],
            [73.581161000000066, 31.879849000000036],
            [73.568785000000048, 31.891861000000063],
            [73.559321000000068, 31.889313000000072],
            [73.558957000000078, 31.879121000000055],
            [73.560644384000057, 31.874227586000075],
            [73.562597000000039, 31.868565000000046],
            [73.562597000000039, 31.858009000000038],
            [73.560947763000058, 31.848349832000054],
            [73.560049019000076, 31.843086109000069],
            [73.560049000000049, 31.843086000000028],
            [73.559401230000049, 31.842506416000049],
            [73.54621700000007, 31.830710000000067],
            [73.534205000000043, 31.819426000000078],
            [73.527335581000045, 31.814591964000044],
            [73.524377000000072, 31.812510000000032],
            [73.522193636000054, 31.805959909000023],
            [73.522193167000069, 31.805958502000067],
            [73.512498624000045, 31.811806005000051],
            [73.512498588000028, 31.811806027000046],
            [73.499262000000044, 31.819790000000069],
            [73.498926637000068, 31.820010634000027],
            [73.49348119900003, 31.82359315900004],
            [73.491606377000039, 31.824826594000058],
            [73.485430000000065, 31.828890000000058],
            [73.482156820000057, 31.830568672000027],
            [73.471235000000036, 31.836170000000038],
            [73.470572064000066, 31.836390979000043],
            [73.470572028000049, 31.83639099100003],
            [73.462499000000037, 31.839082000000076],
            [73.462498917000062, 31.839081694000072],
            [73.462498000000039, 31.839082000000076],
            [73.459714015000031, 31.828802672000052],
            [73.457766000000049, 31.821610000000078],
            [73.453229211000064, 31.812930926000035],
            [73.450152247000062, 31.807044559000076],
            [73.449394000000041, 31.805594000000042],
            [73.436274344000083, 31.804500633000032],
            [73.43192300000004, 31.804138000000023],
            [73.426262294000082, 31.805338756000026],
            [73.41991100000007, 31.80668600000007],
            [73.417741422000063, 31.809217175000072],
            [73.411175000000071, 31.816878000000031],
            [73.407525067000051, 31.820325159000049],
            [73.404623000000072, 31.82306600000004],
            [73.399527000000035, 31.807414000000051],
            [73.396251000000063, 31.802318000000071],
            [73.388607897000043, 31.798314470000037],
            [73.388607094000065, 31.798314049000055],
            [73.388607000000036, 31.798314000000062],
            [73.38650460100007, 31.798430800000062],
            [73.375503889000072, 31.799041951000049],
            [73.375503000000037, 31.799042000000043],
            [73.374166830000036, 31.798448147000045],
            [73.359123158000045, 31.791762070000061],
            [73.359123000000068, 31.791762000000062],
            [73.358033323000029, 31.791852813000048],
            [73.346020000000067, 31.792854000000034],
            [73.345308444000068, 31.792880354000033],
            [73.33619200000004, 31.793218000000024],
            [73.329872184000067, 31.78569440900003],
            [73.328548000000069, 31.784118000000035],
            [73.327042155000072, 31.773828061000074],
            [73.326364000000069, 31.769194000000027],
            [73.32476717000003, 31.769194000000027],
            [73.313988996000035, 31.769194001000074],
            [73.313988000000052, 31.769194000000027],
            [73.305980000000034, 31.764826000000028],
            [73.299792000000082, 31.764099000000044],
            [73.296838000000037, 31.764571000000046],
            [73.293427810000082, 31.765116431000024],
            [73.290692000000035, 31.765554000000066],
            [73.281853242000068, 31.766658845000052],
            [73.279044372000044, 31.76700995300007],
            [73.279044000000056, 31.767010000000028],
            [73.270504718000041, 31.766377393000027],
            [73.27049839600005, 31.766376918000049],
            [73.269216800000038, 31.766280063000067],
            [73.253203850000034, 31.761549964000039],
            [73.253200800000059, 31.761549063000075],
            [73.253197797000041, 31.761549964000039],
            [73.25314323300006, 31.761566334000065],
            [73.253143003000048, 31.761567399000057],
            [73.253142772000047, 31.761568469000053],
            [73.245921000000067, 31.763735000000054],
            [73.245336814000041, 31.764498873000036],
            [73.24182311800007, 31.769093335000036],
            [73.236458000000084, 31.776110000000074],
            [73.228547052000067, 31.783661269000049],
            [73.231576256000039, 31.789268519000075],
            [73.226617774000033, 31.794168666000076],
            [73.223108597000078, 31.79767743900004],
            [73.222798000000068, 31.797988000000032],
            [73.219662682000035, 31.800882460000025],
            [73.218154150000032, 31.802275106000025],
            [73.215276256000038, 31.804968519000056],
            [73.21397625600008, 31.811968519000061],
            [73.213576256000067, 31.814168519000077],
            [73.208376256000065, 31.82166851900007],
            [73.203776256000083, 31.826968519000047],
            [73.191576256000076, 31.826968519000047],
            [73.190476256000068, 31.831568519000029],
            [73.188276256000051, 31.833468519000064],
            [73.179545183000073, 31.840889931000049],
            [73.178276256000061, 31.841968519000034],
            [73.177752313000042, 31.842087279000054],
            [73.170776256000067, 31.843668519000062],
            [73.164976256000045, 31.846568519000073],
            [73.159776256000043, 31.848868519000064],
            [73.159776256000043, 31.848893110000063],
            [73.159776256000043, 31.855485750000071],
            [73.159776256000043, 31.85548865100003],
            [73.159776256000043, 31.855868519000069],
            [73.160376256000063, 31.863368519000062],
            [73.160376256000063, 31.871168519000037],
            [73.160376256000063, 31.87244925400006],
            [73.160287000000039, 31.87246970700005],
            [73.160287000000039, 31.872471018000056],
            [73.160287000000039, 31.87902100000008],
            [73.161253695000084, 31.880866481000055],
            [73.162991943000065, 31.884184905000041],
            [73.164433172000031, 31.886936300000059],
            [73.164435789000038, 31.886941295000042],
            [73.166123894000066, 31.890163994000034],
            [73.16627625600006, 31.892068519000077],
            [73.166022497000029, 31.892500849000044],
            [73.166654000000051, 31.892702942000028],
            [73.173528152000074, 31.894902804000026],
            [73.173528153000063, 31.89490300600005],
            [73.179969000000028, 31.896964000000025],
            [73.179976840000052, 31.897000595000065],
            [73.181705000000079, 31.905067000000031],
            [73.184599000000048, 31.913170000000036],
            [73.187570836000077, 31.918689021000034],
            [73.187797681000063, 31.919110296000042],
            [73.18865100000005, 31.920695000000023],
            [73.191735799000071, 31.928921329000048],
            [73.192482110000071, 31.930911538000032],
            [73.193860001000076, 31.934586000000024],
            [73.190387000000044, 31.946162000000072],
            [73.191969626000059, 31.948588802000074],
            [73.194665538000038, 31.952722721000043],
            [73.194665564000047, 31.952722760000029],
            [73.196008402000075, 31.95478187100008],
            [73.199069000000065, 31.959475000000054],
            [73.200244465000083, 31.96061588300006],
            [73.207211377000078, 31.96737782200006],
            [73.207209714000044, 31.967378551000024],
            [73.207209812000031, 31.967378646000043],
            [73.207211478000033, 31.967377920000047],
            [73.216675757000075, 31.97656375300005],
            [73.218749000000059, 31.978576000000032],
            [73.219138103000034, 31.978790685000035],
            [73.223725295000065, 31.981321634000039],
            [73.230935930000044, 31.985300049000045],
            [73.23553400000003, 31.98783700000007],
            [73.23616908200006, 31.990631227000051],
            [73.236726333000036, 31.993083015000025],
            [73.238066871000058, 31.998981105000041],
            [73.238428000000056, 32.000570000000039],
            [73.237852858000053, 32.01206985500005],
            [73.23784900000004, 32.012147000000027],
            [73.236652038000045, 32.016335852000054],
            [73.236029218000056, 32.018515452000031],
            [73.235130664000053, 32.021660005000058],
            [73.234376000000054, 32.024301000000037],
            [73.234014234000028, 32.028286063000053],
            [73.233798000000036, 32.030668000000048],
            [73.238428000000056, 32.032984000000056],
            [73.240318951000063, 32.034604282000032],
            [73.242480000000057, 32.036456000000044],
            [73.252898000000073, 32.03703500000006],
            [73.253569281000068, 32.040166871000054],
            [73.254635000000064, 32.045139000000063],
            [73.255176177000067, 32.045763469000065],
            [73.262159000000054, 32.053821000000028],
            [73.261946254000065, 32.05421993300007],
            [73.261589403000073, 32.05488908500007],
            [73.257529000000034, 32.062503000000049],
            [73.257529000000034, 32.063989848000062],
            [73.257529000000034, 32.067735751000043],
            [73.257529000000034, 32.067940026000031],
            [73.257529000000034, 32.073500000000024],
            [73.265333319000035, 32.084518376000062],
            [73.267368000000033, 32.087391000000025],
            [73.282417000000066, 32.096652000000063],
            [73.289729464000061, 32.101624678000064],
            [73.296887000000083, 32.10649200000006],
            [73.30553614300004, 32.109375048000061],
            [73.30730600000004, 32.109965000000045],
            [73.309741949000056, 32.120196494000027],
            [73.310192962000031, 32.122090841000045],
            [73.319461000000047, 32.123856000000046],
            [73.324187302000041, 32.12600425200003],
            [73.331755630000032, 32.129444293000063],
            [73.340581347000068, 32.133455856000069],
            [73.344929000000036, 32.135432000000037],
            [73.35211167600005, 32.139472417000036],
            [73.363697107000064, 32.145989482000061],
            [73.372711000000038, 32.15106000000003],
            [73.380638348000048, 32.157436417000042],
            [73.382968797000046, 32.159310930000061],
            [73.393057594000084, 32.167425921000074],
            [73.39330298200008, 32.167623300000059],
            [73.399336000000062, 32.172476000000074],
            [73.410325193000062, 32.175929684000039],
            [73.419595000000072, 32.178843000000029],
            [73.42976161100006, 32.182730066000033],
            [73.439274000000069, 32.186367000000075],
            [73.446906843000079, 32.192303602000038],
            [73.454902000000061, 32.198522000000025],
            [73.457466938000039, 32.201471811000033],
            [73.462490153000033, 32.207248770000035],
            [73.463573095000072, 32.208494209000037],
            [73.466478000000052, 32.211835000000065],
            [73.468483454000079, 32.215559320000068],
            [73.472517479000032, 32.223050891000071],
            [73.47803207700008, 32.23329202900004],
            [73.478633000000059, 32.23440800000003],
            [73.480610640000066, 32.235961947000078],
            [73.482964160000051, 32.237811245000046],
            [73.486736000000064, 32.240775000000042],
            [73.494054858000084, 32.240043209000078],
            [73.498561126000084, 32.239592641000058],
            [73.50147230400006, 32.239301561000048],
            [73.509885049000047, 32.238460395000061],
            [73.509889000000044, 32.238460000000032],
            [73.510093160000054, 32.240093750000028],
            [73.510093663000077, 32.240097773000059],
            [73.510300624000081, 32.241753940000024],
            [73.511625000000038, 32.25235200000003],
            [73.511860525000031, 32.252927723000028],
            [73.512042290000068, 32.253372033000062],
            [73.516396215000043, 32.264014867000071],
            [73.516834000000074, 32.265085000000056],
            [73.526492021000081, 32.272078521000026],
            [73.532035695000047, 32.276092781000045],
            [73.533620000000042, 32.277240000000063],
            [73.545136907000028, 32.283126684000024],
            [73.55966600000005, 32.290553000000045],
            [73.560835415000042, 32.291012410000064],
            [73.575873000000058, 32.296920000000057],
            [73.587690657000053, 32.299700413000039],
            [73.595552000000055, 32.301550000000077],
            [73.598913007000078, 32.303015127000037],
            [73.606306086000075, 32.306237911000039],
            [73.618125000000077, 32.311390000000074],
            [73.630199626000035, 32.317829460000041],
            [73.639381470000046, 32.322726185000079],
            [73.644172000000083, 32.325281000000075],
            [73.652521531000048, 32.329256902000054],
            [73.656327000000033, 32.33106900000007],
            [73.658609273000081, 32.331606224000041],
            [73.665206620000049, 32.333159172000023],
            [73.666166000000032, 32.333385000000078],
            [73.678321000000039, 32.342067000000043],
            [73.678519250000079, 32.342127991000041],
            [73.680778157000077, 32.332553988000029],
            [73.681892662000052, 32.327830361000053],
            [73.685992000000056, 32.310456000000045],
            [73.686667378000038, 32.305631874000028],
            [73.687496877000058, 32.299706878000052],
            [73.688540000000046, 32.292256000000066],
            [73.693331876000059, 32.286157647000039],
            [73.700552000000073, 32.276969000000065],
            [73.702291430000059, 32.273324481000031],
            [73.702292148000083, 32.273322975000042],
            [73.704121481000072, 32.269490087000065],
            [73.708034594000083, 32.261291196000059],
            [73.708034611000073, 32.261291160000042],
            [73.708196000000044, 32.260953011000026],
            [73.713655999000082, 32.244209011000066],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "101",
      properties: { name: "Jhang", count: 1102 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.310831497000038, 31.74750378400006],
            [72.315597792000062, 31.744855359000042],
            [72.315670671000078, 31.744814863000045],
            [72.32222732200006, 31.741681178000078],
            [72.325410095000052, 31.740165335000029],
            [72.327964000000065, 31.738949000000048],
            [72.328379487000063, 31.738573161000033],
            [72.328531160000068, 31.738435937000077],
            [72.340087891000053, 31.728088379000042],
            [72.343355517000077, 31.724722841000073],
            [72.348776223000073, 31.719296444000065],
            [72.348775581000041, 31.719295577000025],
            [72.349959000000069, 31.718112000000076],
            [72.35921950200003, 31.711746343000073],
            [72.35922000000005, 31.711746000000062],
            [72.358528179000075, 31.710189029000048],
            [72.35690500000004, 31.706536000000028],
            [72.355737034000072, 31.704978788000062],
            [72.355736998000054, 31.704978740000058],
            [72.351696000000061, 31.699591000000055],
            [72.349469107000061, 31.698184563000041],
            [72.340698000000032, 31.692645000000027],
            [72.334910000000036, 31.68512000000004],
            [72.334652848000076, 31.68396303600008],
            [72.333693742000037, 31.679647869000064],
            [72.333694163000075, 31.679647683000042],
            [72.333111684000073, 31.677026026000078],
            [72.331438000000048, 31.669493000000045],
            [72.331437053000059, 31.66949323700004],
            [72.331437000000051, 31.669493000000045],
            [72.324492000000078, 31.671229000000039],
            [72.318125000000066, 31.677017000000035],
            [72.31233700000007, 31.683963000000062],
            [72.304233964000048, 31.682226207000042],
            [72.304233000000067, 31.682226000000071],
            [72.304233013000044, 31.677500894000048],
            [72.304234000000065, 31.677500140000063],
            [72.304234000000065, 31.670650000000023],
            [72.31002200000006, 31.655601000000047],
            [72.309263035000072, 31.653866356000037],
            [72.309229062000043, 31.653788709000025],
            [72.308541781000031, 31.652217902000075],
            [72.305970000000059, 31.646340000000066],
            [72.303673696000033, 31.644719286000054],
            [72.298849564000079, 31.64131444800006],
            [72.296130000000062, 31.639395000000036],
            [72.285066787000062, 31.634126965000064],
            [72.283975000000055, 31.63360700000004],
            [72.280952471000035, 31.631016048000049],
            [72.275872000000049, 31.62666100000007],
            [72.272480286000075, 31.623008984000023],
            [72.268347000000063, 31.618558000000064],
            [72.265181013000074, 31.615391841000076],
            [72.265181372000029, 31.615391372000033],
            [72.264862417000074, 31.615072417000079],
            [72.264862381000057, 31.615072381000061],
            [72.260244000000057, 31.610454000000061],
            [72.25438777800008, 31.601670508000041],
            [72.251517971000055, 31.597365551000053],
            [72.250983000000076, 31.59656300000006],
            [72.24577400000004, 31.590196000000049],
            [72.247937633000049, 31.582263369000032],
            [72.248389804000055, 31.580605284000058],
            [72.249247000000082, 31.577462000000025],
            [72.260823000000073, 31.562992000000065],
            [72.264449237000065, 31.561745755000061],
            [72.279345000000035, 31.556625000000054],
            [72.286290000000065, 31.566465000000051],
            [72.286290000000065, 31.574568000000056],
            [72.287088825000069, 31.576736285000038],
            [72.28861607500005, 31.580881767000051],
            [72.290342000000066, 31.585566000000028],
            [72.304233000000067, 31.588460000000055],
            [72.309655177000081, 31.593881396000029],
            [72.311179000000038, 31.595405000000028],
            [72.31708223000004, 31.598726146000047],
            [72.320440000000076, 31.600615000000062],
            [72.329701000000057, 31.607560000000035],
            [72.339541000000054, 31.611033000000077],
            [72.348223000000075, 31.611033000000077],
            [72.35922000000005, 31.614506000000063],
            [72.363128785000072, 31.616282871000067],
            [72.364944815000058, 31.617108271000063],
            [72.369437754000046, 31.619150347000073],
            [72.371954000000073, 31.620294000000058],
            [72.373385192000057, 31.626019575000043],
            [72.374269000000083, 31.629555000000039],
            [72.370796000000041, 31.639395000000036],
            [72.370046830000035, 31.642242088000046],
            [72.368135837000068, 31.649503550000077],
            [72.368007340000077, 31.649991767000074],
            [72.367902000000072, 31.650392000000068],
            [72.371385982000049, 31.656973612000058],
            [72.373112000000049, 31.660232000000065],
            [72.375418275000072, 31.659418018000054],
            [72.375419218000047, 31.659417591000079],
            [72.379273957000066, 31.658056934000058],
            [72.382951000000048, 31.656759000000079],
            [72.386351259000037, 31.656003604000034],
            [72.390748121000058, 31.655026648000046],
            [72.393370000000061, 31.654444000000069],
            [72.398379706000071, 31.654694269000061],
            [72.404946000000052, 31.655022000000031],
            [72.412835418000043, 31.655739501000028],
            [72.417679000000078, 31.656180000000063],
            [72.424625000000049, 31.658495000000073],
            [72.43330700000007, 31.658495000000073],
            [72.437359000000072, 31.66370500000005],
            [72.439077000000054, 31.66370500000005],
            [72.439077000000054, 31.663668519000055],
            [72.439077000000054, 31.657507000000066],
            [72.439077019000081, 31.652668703000074],
            [72.439077019000081, 31.65266598900007],
            [72.439077000000054, 31.642464000000075],
            [72.439076660000069, 31.642461848000039],
            [72.439276256000085, 31.639168519000066],
            [72.438009458000067, 31.633189234000042],
            [72.43677625600003, 31.627368519000072],
            [72.436601784000061, 31.626919876000045],
            [72.435472966000077, 31.62127531200008],
            [72.435200953000049, 31.619915133000063],
            [72.434362754000063, 31.615723787000036],
            [72.434327000000053, 31.615545000000054],
            [72.434327006000046, 31.60954354100005],
            [72.434327000000053, 31.606836000000044],
            [72.445031226000083, 31.593990208000037],
            [72.446203000000082, 31.592584000000045],
            [72.451542090000032, 31.583430861000068],
            [72.451745000000074, 31.583083000000045],
            [72.462830000000054, 31.575166000000024],
            [72.462860428000056, 31.574800518000075],
            [72.463613070000065, 31.565760251000029],
            [72.463621000000046, 31.565665000000024],
            [72.462088591000054, 31.55646764200003],
            [72.462076256000046, 31.556168519000039],
            [72.459751719000053, 31.550066610000044],
            [72.45967552500008, 31.549863404000064],
            [72.459663000000035, 31.549830000000043],
            [72.458871000000045, 31.544288000000051],
            [72.458878630000072, 31.544285139000067],
            [72.458876256000053, 31.54426851900007],
            [72.46521176300007, 31.541892704000077],
            [72.465460153000038, 31.541147256000045],
            [72.466785840000057, 31.537168483000073],
            [72.466788000000065, 31.537162000000023],
            [72.466846331000056, 31.537168483000073],
            [72.466875177000077, 31.537171689000047],
            [72.469021270000042, 31.537410211000065],
            [72.473976256000071, 31.53796851900006],
            [72.478076256000065, 31.53796851900006],
            [72.480452182000079, 31.53796851900006],
            [72.481876256000078, 31.53796851900006],
            [72.486171144000082, 31.536094386000059],
            [72.487374000000045, 31.535569503000033],
            [72.487374006000039, 31.529732233000061],
            [72.487374000000045, 31.526870000000031],
            [72.487922618000084, 31.525772804000042],
            [72.491675833000045, 31.51826582700005],
            [72.492916000000037, 31.515785000000051],
            [72.496501250000051, 31.513898114000028],
            [72.502781477000042, 31.510592885000051],
            [72.506616488000077, 31.508574552000027],
            [72.507976256000063, 31.507868519000056],
            [72.515176256000075, 31.507868519000056],
            [72.521064460000048, 31.504129977000048],
            [72.52141800000004, 31.503909000000078],
            [72.52143454000003, 31.503895005000061],
            [72.53171100000003, 31.495200000000068],
            [72.534086000000059, 31.48807400000004],
            [72.537797607000073, 31.484826613000052],
            [72.537798147000046, 31.484826140000052],
            [72.537801627000078, 31.484823096000071],
            [72.54042000000004, 31.482532000000049],
            [72.545340020000083, 31.476908867000077],
            [72.545962000000031, 31.476198000000068],
            [72.546268032000057, 31.475662470000032],
            [72.552376256000059, 31.465068519000056],
            [72.561031927000045, 31.454821000000038],
            [72.568923000000041, 31.454821000000038],
            [72.574465000000032, 31.456405000000075],
            [72.575121325000055, 31.457248861000039],
            [72.57791166100003, 31.460836303000065],
            [72.58000700000008, 31.463530000000048],
            [72.580065950000062, 31.46355526800005],
            [72.581770741000071, 31.46428600400003],
            [72.584226473000058, 31.465338620000068],
            [72.587471355000048, 31.466729357000077],
            [72.59109200000006, 31.468281000000047],
            [72.59135099000008, 31.468281000000047],
            [72.593976292000036, 31.468281000000047],
            [72.594082697000033, 31.468281000000047],
            [72.595911265000041, 31.468281000000047],
            [72.597843965000038, 31.468281000000047],
            [72.597876220000046, 31.468281000000047],
            [72.599009000000081, 31.468281000000047],
            [72.608385474000045, 31.468281000000047],
            [72.608510000000081, 31.468281000000047],
            [72.608523550000029, 31.468271967000078],
            [72.616942092000045, 31.462659686000052],
            [72.618011000000081, 31.461947000000066],
            [72.620216184000071, 31.457168483000032],
            [72.620231798000077, 31.457134648000078],
            [72.622761000000082, 31.451654000000076],
            [72.622287784000036, 31.44502316300003],
            [72.62217684500007, 31.443468555000038],
            [72.621976220000079, 31.440657162000036],
            [72.621970000000033, 31.440570000000037],
            [72.621970000000033, 31.429567127000041],
            [72.621970000000033, 31.429486000000054],
            [72.621976220000079, 31.429405061000068],
            [72.622761000000082, 31.419193000000064],
            [72.622775901000068, 31.419173134000062],
            [72.622976411000082, 31.418905816000063],
            [72.627512000000081, 31.412859000000026],
            [72.628484136000054, 31.411886932000073],
            [72.630308624000065, 31.410062493000055],
            [72.631702575000077, 31.408668483000042],
            [72.633054000000072, 31.407317000000035],
            [72.634376220000036, 31.406910173000028],
            [72.643347000000063, 31.404150000000072],
            [72.645174707000081, 31.403845346000026],
            [72.652848000000063, 31.402566000000036],
            [72.653067885000041, 31.402401095000073],
            [72.658159469000054, 31.398582627000053],
            [72.662348000000065, 31.395441000000062],
            [72.662348000000065, 31.394293206000043],
            [72.662376256000073, 31.394268519000036],
            [72.663276256000074, 31.391368519000025],
            [72.665476256000034, 31.38696851900005],
            [72.667176256000062, 31.38356851900005],
            [72.667180985000073, 31.383535417000076],
            [72.66710370100003, 31.383532101000071],
            [72.667891000000054, 31.378022000000044],
            [72.67266268000003, 31.37435172000005],
            [72.678183000000047, 31.370105000000024],
            [72.678236435000031, 31.370099061000076],
            [72.685309000000075, 31.369313000000034],
            [72.689282708000064, 31.368396204000078],
            [72.69567625600007, 31.366968519000068],
            [72.69668703800005, 31.367209181000078],
            [72.700369649000038, 31.368129569000075],
            [72.701936000000046, 31.368521000000044],
            [72.701975498000081, 31.36846833900006],
            [72.701980032000051, 31.368462294000039],
            [72.705836907000048, 31.363320065000039],
            [72.706565424000075, 31.362348760000032],
            [72.713799612000059, 31.36218800000006],
            [72.714603000000068, 31.36218800000006],
            [72.725080388000038, 31.36218800000006],
            [72.726480000000038, 31.36218800000006],
            [72.732813000000078, 31.372480000000053],
            [72.741150331000028, 31.376649104000023],
            [72.742376256000057, 31.37726851900004],
            [72.748676256000067, 31.375668519000044],
            [72.754394155000057, 31.375346384000068],
            [72.755776256000047, 31.375268519000031],
            [72.762176256000032, 31.374868519000074],
            [72.768276256000036, 31.374968519000049],
            [72.772593522000079, 31.374462357000027],
            [72.782776256000034, 31.373268519000078],
            [72.800176256000043, 31.366968519000068],
            [72.809900964000065, 31.367398125000079],
            [72.825076256000045, 31.368068519000076],
            [72.826320481000039, 31.367687465000074],
            [72.826320739000039, 31.367686964000029],
            [72.826322000000062, 31.367687000000046],
            [72.830031921000057, 31.360485335000078],
            [72.830369000000076, 31.359831000000042],
            [72.821108000000038, 31.34420300000005],
            [72.809902262000037, 31.332197050000048],
            [72.809900964000065, 31.332195659000035],
            [72.802163218000032, 31.323905353000043],
            [72.796798000000081, 31.318157000000042],
            [72.777697000000046, 31.30252900000005],
            [72.77548461300006, 31.299842933000036],
            [72.775772435000079, 31.299754596000071],
            [72.765466543000059, 31.287239480000039],
            [72.761651132000054, 31.282606179000027],
            [72.75461828400006, 31.275573331000032],
            [72.750075132000063, 31.271030179000036],
            [72.741884847000051, 31.267150825000044],
            [72.74176093300008, 31.267092133000062],
            [72.739050845000065, 31.26580849100003],
            [72.728080132000059, 31.260612179000077],
            [72.702613132000067, 31.249614179000048],
            [72.70039838100007, 31.24711062700004],
            [72.700734967000074, 31.247184274000062],
            [72.700396478000073, 31.247108476000051],
            [72.695761726000057, 31.241869357000041],
            [72.691673035000065, 31.237247506000074],
            [72.689300132000028, 31.234565179000072],
            [72.674364541000045, 31.233071964000032],
            [72.671936132000042, 31.232829179000078],
            [72.67106209800005, 31.233071964000032],
            [72.66266068200008, 31.235405668000055],
            [72.662939667000046, 31.235526873000026],
            [72.659433842000055, 31.236500847000059],
            [72.659433806000038, 31.236500857000067],
            [72.657146350000062, 31.237136188000079],
            [72.657224513000074, 31.236915701000044],
            [72.656705567000074, 31.237059851000026],
            [72.65109913200007, 31.238617179000073],
            [72.63838964100006, 31.239772950000031],
            [72.638365132000047, 31.239775179000048],
            [72.636747056000047, 31.239843997000037],
            [72.612242442000081, 31.240886190000026],
            [72.611161132000063, 31.240932179000026],
            [72.594576816000028, 31.236898386000064],
            [72.590427419000036, 31.235889131000079],
            [72.589745132000076, 31.235723179000047],
            [72.575275132000058, 31.24614217900006],
            [72.561963132000074, 31.240932179000026],
            [72.561943057000065, 31.24089439100004],
            [72.553965948000041, 31.225878943000055],
            [72.552225004000036, 31.222601934000068],
            [72.552123132000077, 31.222410179000065],
            [72.532443132000083, 31.220095179000054],
            [72.518025376000082, 31.215481895000039],
            [72.518075793000037, 31.215792087000068],
            [72.517813000000046, 31.215708000000063],
            [72.515744731000041, 31.216095961000065],
            [72.511382963000074, 31.216914011000028],
            [72.507766791000051, 31.217591967000033],
            [72.505888860000084, 31.217944040000077],
            [72.500585998000076, 31.218938215000037],
            [72.499291000000028, 31.219181000000049],
            [72.498951109000075, 31.218938215000037],
            [72.491188000000079, 31.213393000000053],
            [72.490541138000083, 31.207573111000045],
            [72.490030000000047, 31.20297400000004],
            [72.490191241000048, 31.202732161000029],
            [72.492764319000059, 31.198872914000049],
            [72.493499185000076, 31.19777072100004],
            [72.493502551000063, 31.197765673000049],
            [72.493503000000032, 31.197765000000061],
            [72.493502117000048, 31.197763749000046],
            [72.490687943000069, 31.193777346000047],
            [72.490266336000047, 31.193180088000076],
            [72.490235159000065, 31.193135922000067],
            [72.488726667000037, 31.19099888900007],
            [72.48798182400003, 31.189943653000057],
            [72.486718096000061, 31.188153237000051],
            [72.486557000000062, 31.187925000000064],
            [72.486718096000061, 31.187731675000066],
            [72.486737029000039, 31.187708954000072],
            [72.488987814000041, 31.185007877000032],
            [72.489451000000031, 31.184452000000078],
            [72.503342469000074, 31.181558111000072],
            [72.503343000000029, 31.181558000000052],
            [72.503331492000029, 31.181350938000037],
            [72.503503132000048, 31.181315179000023],
            [72.502924132000032, 31.170897179000065],
            [72.498294132000069, 31.162215179000043],
            [72.494531825000081, 31.161701976000074],
            [72.485560132000046, 31.160478179000052],
            [72.484971126000062, 31.160517460000051],
            [72.476878132000081, 31.161057179000068],
            [72.475721250000049, 31.15179712500003],
            [72.482506000000058, 31.141620000000046],
            [72.489091272000053, 31.135975830000064],
            [72.490609000000063, 31.134675000000072],
            [72.490602340000066, 31.134575135000034],
            [72.490769132000082, 31.134432179000044],
            [72.490340588000038, 31.127995114000043],
            [72.490191132000064, 31.125750179000079],
            [72.488976926000078, 31.125622393000071],
            [72.486865105000049, 31.125400139000078],
            [72.479917714000067, 31.124668977000056],
            [72.479721206000079, 31.124897103000023],
            [72.47900420600007, 31.124821103000045],
            [72.472078472000078, 31.125398497000049],
            [72.469439349000083, 31.120600595000042],
            [72.465720000000033, 31.113838000000044],
            [72.465699850000078, 31.113838000000044],
            [72.465692206000028, 31.11382410300007],
            [72.454116206000037, 31.11382410300007],
            [72.452100673000075, 31.113091110000028],
            [72.452091842000073, 31.113091629000053],
            [72.441411000000073, 31.109207000000026],
            [72.440962196000044, 31.106851754000047],
            [72.439095000000066, 31.097052000000076],
            [72.436994000000084, 31.083917000000042],
            [72.436780000000056, 31.082582000000059],
            [72.438517000000047, 31.07505800000007],
            [72.439176795000037, 31.06911679500007],
            [72.439674000000082, 31.064639000000057],
            [72.439669031000051, 31.064602550000075],
            [72.437938000000031, 31.051905000000033],
            [72.437916905000066, 31.051888870000028],
            [72.428098000000034, 31.044381000000044],
            [72.425086075000081, 31.043140837000067],
            [72.418258000000037, 31.040329000000042],
            [72.412132342000064, 31.03604114500007],
            [72.409733562000042, 31.034362041000065],
            [72.406682000000046, 31.032226000000037],
            [72.395474458000081, 31.028302994000057],
            [72.395106000000055, 31.028174000000035],
            [72.385845000000074, 31.022965000000056],
            [72.380057000000079, 31.011389000000065],
            [72.379837767000083, 31.007443945000034],
            [72.379478000000063, 31.000970000000052],
            [72.378121074000035, 30.99486354000004],
            [72.377163000000053, 30.990552000000037],
            [72.374720775000071, 30.98273671100003],
            [72.374269000000083, 30.981291000000056],
            [72.369992165000042, 30.978847493000046],
            [72.369229980000057, 30.97841199800007],
            [72.368530555000063, 30.978012363000062],
            [72.358062000000075, 30.972030000000075],
            [72.358062062000045, 30.959339016000058],
            [72.358062036000035, 30.954310703000033],
            [72.358062000000075, 30.947141000000045],
            [72.355615562000082, 30.943582139000057],
            [72.353684864000058, 30.940773531000048],
            [72.351696000000061, 30.937880000000064],
            [72.345156105000058, 30.93134073300007],
            [72.341277000000048, 30.927462000000048],
            [72.335792986000058, 30.921978052000043],
            [72.334910000000036, 30.921095000000037],
            [72.334910000000036, 30.919131579000066],
            [72.334910000000036, 30.911834000000056],
            [72.324784621000049, 30.912813955000047],
            [72.316967000000034, 30.91357000000005],
            [72.316504975000043, 30.906180717000041],
            [72.315995672000042, 30.898034669000026],
            [72.315809000000058, 30.895049000000029],
            [72.307122219000064, 30.895348134000074],
            [72.299497638000048, 30.895610690000069],
            [72.299024000000031, 30.895627000000047],
            [72.28281700000008, 30.890997000000027],
            [72.277594744000055, 30.887515607000068],
            [72.274989700000049, 30.885778633000029],
            [72.270892358000083, 30.883046634000038],
            [72.270892262000075, 30.883046920000027],
            [72.26718900000003, 30.880578000000071],
            [72.266212125000038, 30.879508140000041],
            [72.26621269900005, 30.879507842000066],
            [72.263552437000044, 30.876594316000023],
            [72.255035000000078, 30.867266000000029],
            [72.253728408000029, 30.86145958000003],
            [72.249826000000041, 30.844114000000047],
            [72.257910467000045, 30.842288348000068],
            [72.266866126000082, 30.840265961000057],
            [72.267769000000044, 30.840062000000046],
            [72.274544097000046, 30.834133425000061],
            [72.277029000000084, 30.83195900000004],
            [72.287447000000043, 30.838325000000054],
            [72.287447428000064, 30.838324650000061],
            [72.28744800000004, 30.838325000000054],
            [72.294008279000082, 30.832957874000044],
            [72.300182000000063, 30.827907000000039],
            [72.30979754100008, 30.817329676000043],
            [72.316926541000043, 30.809487607000051],
            [72.31754600000005, 30.808806000000061],
            [72.329700000000059, 30.808806000000061],
            [72.329701000000057, 30.808806000000061],
            [72.337225000000046, 30.808806000000061],
            [72.338467183000034, 30.812194076000026],
            [72.339050783000062, 30.813785854000059],
            [72.343108289000043, 30.824850861000073],
            [72.343592000000058, 30.826170000000047],
            [72.344141476000061, 30.824082316000045],
            [72.34426047900007, 30.823630173000026],
            [72.344260978000079, 30.823628275000033],
            [72.344715072000042, 30.821902986000055],
            [72.346060364000039, 30.816791666000029],
            [72.346472245000029, 30.815226758000051],
            [72.348853161000079, 30.806178222000028],
            [72.349380000000053, 30.804176000000041],
            [72.348223000000075, 30.793757000000028],
            [72.345937212000081, 30.782332006000047],
            [72.345907000000068, 30.782181000000037],
            [72.349380000000053, 30.776393000000041],
            [72.353062756000043, 30.77184382400003],
            [72.353062574000035, 30.771843585000056],
            [72.359219000000053, 30.764238000000034],
            [72.361621468000067, 30.76250307600003],
            [72.361621737000064, 30.762503604000074],
            [72.369639000000063, 30.756714000000045],
            [72.354011000000071, 30.75555600000007],
            [72.350538000000029, 30.745137000000057],
            [72.354011000000071, 30.738192000000026],
            [72.36442900000003, 30.735877000000073],
            [72.364331824000033, 30.73567515700006],
            [72.35690500000004, 30.720249000000024],
            [72.343013000000042, 30.713882000000069],
            [72.330858000000035, 30.714461000000028],
            [72.323002741000039, 30.720633335000059],
            [72.322755000000029, 30.72082800000004],
            [72.312915000000032, 30.717934000000071],
            [72.302496370000028, 30.724299774000031],
            [72.279923000000053, 30.716197000000079],
            [72.259665000000041, 30.723722000000066],
            [72.250264242000071, 30.724661805000039],
            [72.242300833000058, 30.725457917000028],
            [72.237538536000045, 30.723076768000055],
            [72.229567000000031, 30.719091000000049],
            [72.221155533000058, 30.717289212000026],
            [72.218460194000045, 30.716711574000044],
            [72.217689608000057, 30.716546430000051],
            [72.216743674000043, 30.716343707000078],
            [72.216624913000032, 30.716317848000074],
            [72.205290910000031, 30.713849960000061],
            [72.204808587000059, 30.713849966000055],
            [72.194448260000058, 30.713109982000049],
            [72.187834566000049, 30.712637597000025],
            [72.185463001000073, 30.712467983000067],
            [72.18546129200007, 30.712467556000036],
            [72.182590910000044, 30.711749960000077],
            [72.181499345000077, 30.712184466000053],
            [72.18149926500007, 30.712184498000056],
            [72.172290910000072, 30.715849960000071],
            [72.171685704000083, 30.715849960000071],
            [72.167923140000084, 30.717355165000072],
            [72.167424481000069, 30.717554603000053],
            [72.164355536000073, 30.718782025000053],
            [72.163583000000074, 30.719091000000049],
            [72.152837806000036, 30.722567870000034],
            [72.152833902000054, 30.72256879400004],
            [72.152831867000032, 30.722569275000069],
            [72.15249091000004, 30.722649960000069],
            [72.143890910000039, 30.725449960000049],
            [72.134390910000036, 30.725649960000055],
            [72.128391521000083, 30.726446339000063],
            [72.12309091000003, 30.727149960000077],
            [72.118190910000067, 30.725949960000037],
            [72.113631444000077, 30.724859653000067],
            [72.114636842000039, 30.72622180500008],
            [72.101324502000068, 30.724686581000071],
            [72.093738203000044, 30.723810822000075],
            [72.093400580000036, 30.723771836000026],
            [72.092969000000039, 30.723722000000066],
            [72.079163239000081, 30.725694001000079],
            [72.076762000000031, 30.726037000000076],
            [72.069568377000053, 30.728250202000027],
            [72.061713000000054, 30.730667000000039],
            [72.061695775000032, 30.730648479000024],
            [72.060882501000037, 30.729774184000064],
            [72.059618819000036, 30.728415681000058],
            [72.053271001000041, 30.721588634000057],
            [72.037251563000041, 30.704359808000049],
            [72.031036000000029, 30.697675000000061],
            [72.031013465000058, 30.697674133000078],
            [72.030990910000071, 30.697649960000035],
            [72.026190910000082, 30.696649960000059],
            [72.015990910000085, 30.697049960000072],
            [72.015553407000084, 30.697065044000055],
            [72.013090910000074, 30.697149960000047],
            [71.999790910000058, 30.695949960000064],
            [71.999790910000058, 30.695939779000071],
            [71.999790910000058, 30.695939708000026],
            [71.999781000000041, 30.695939000000067],
            [71.999781055000028, 30.690750296000033],
            [71.999781033000033, 30.681357493000064],
            [71.999781000000041, 30.667577000000051],
            [71.986474345000033, 30.660056586000053],
            [71.97699091000004, 30.66384996000005],
            [71.972778404000053, 30.664615870000034],
            [71.972577000000058, 30.664683000000025],
            [71.971485373000064, 30.665714104000074],
            [71.969919653000034, 30.667192791000048],
            [71.962158000000045, 30.674523000000079],
            [71.94653100000005, 30.673944000000063],
            [71.946106711000084, 30.673731888000077],
            [71.939889726000047, 30.670623379000062],
            [71.933797000000084, 30.667577000000051],
            [71.927321534000043, 30.663260644000047],
            [71.926851000000056, 30.662947000000031],
            [71.920404419000079, 30.664236349000078],
            [71.915546932000041, 30.66520762600004],
            [71.915546721000055, 30.665207665000025],
            [71.915287095000053, 30.665259582000033],
            [71.915275000000065, 30.665262000000041],
            [71.912960000000055, 30.671050000000037],
            [71.906720647000043, 30.676438468000072],
            [71.902881456000046, 30.679754092000053],
            [71.900829968000039, 30.681525506000071],
            [71.900226000000032, 30.682047000000068],
            [71.900195910000036, 30.682045663000054],
            [71.886488641000028, 30.681436770000062],
            [71.886490910000077, 30.681449960000066],
            [71.885261041000035, 30.681382261000067],
            [71.885258624000073, 30.681382128000052],
            [71.87558686300008, 30.680952495000042],
            [71.874180000000081, 30.680890000000034],
            [71.865127710000081, 30.673561778000078],
            [71.862300708000078, 30.671273198000051],
            [71.862025000000074, 30.671050000000037],
            [71.858387729000071, 30.66918269100006],
            [71.858387145000052, 30.669182818000024],
            [71.858374275000074, 30.669190942000057],
            [71.858367925000039, 30.669187678000071],
            [71.858381092000059, 30.669179284000052],
            [71.840609000000029, 30.660053000000062],
            [71.837297613000032, 30.655085716000031],
            [71.832565442000032, 30.647987166000064],
            [71.83250600000008, 30.647898000000055],
            [71.827556274000074, 30.648189344000059],
            [71.822666000000083, 30.648477000000071],
            [71.819440323000038, 30.648477068000034],
            [71.813984000000062, 30.648477000000071],
            [71.81210178300006, 30.647033878000059],
            [71.812113876000069, 30.647026750000066],
            [71.812180627000032, 30.64698740700004],
            [71.810432538000043, 30.645724328000028],
            [71.806226122000055, 30.642517928000075],
            [71.800860958000044, 30.638359133000051],
            [71.795162456000071, 30.633993984000028],
            [71.791455237000037, 30.631146744000034],
            [71.791410000000042, 30.63111200000003],
            [71.790837766000038, 30.629648570000029],
            [71.790687000000048, 30.629263000000037],
            [71.790130630000078, 30.62786690300004],
            [71.789177226000049, 30.625415292000071],
            [71.788575050000077, 30.623866840000062],
            [71.786201000000062, 30.617800000000045],
            [71.785070390000044, 30.614191693000066],
            [71.783919283000046, 30.610634203000075],
            [71.782790910000074, 30.60704996000004],
            [71.782745490000082, 30.607006605000038],
            [71.782744000000037, 30.60700200000008],
            [71.782555700000046, 30.606825442000059],
            [71.782555663000039, 30.606825406000041],
            [71.780590910000058, 30.604949960000056],
            [71.780588020000039, 30.604946864000055],
            [71.773590910000053, 30.597449960000063],
            [71.771499249000044, 30.596453925000048],
            [71.771257280000043, 30.596169604000067],
            [71.770037666000064, 30.594706067000061],
            [71.769990910000047, 30.594649960000027],
            [71.764329304000057, 30.593168418000062],
            [71.764296655000066, 30.593159874000037],
            [71.76239399800005, 30.592647443000033],
            [71.759311773000036, 30.591817085000059],
            [71.752630000000067, 30.590017000000046],
            [71.746399375000067, 30.587791763000041],
            [71.737741266000057, 30.584699562000026],
            [71.736424000000056, 30.58422900000005],
            [71.728320000000053, 30.574968000000069],
            [71.728310985000064, 30.574973009000075],
            [71.728106199000081, 30.575086782000028],
            [71.72564464900006, 30.576454363000039],
            [71.717902000000038, 30.580756000000065],
            [71.71790100000004, 30.580756000000065],
            [71.705455097000083, 30.580233883000062],
            [71.70343100000008, 30.580756000000065],
            [71.69648600000005, 30.590596000000062],
            [71.695510327000079, 30.592311658000028],
            [71.693592000000081, 30.595805000000041],
            [71.684658266000042, 30.600507179000033],
            [71.682595000000049, 30.601593000000037],
            [71.671019000000058, 30.605066000000079],
            [71.671018341000035, 30.605066659000045],
            [71.667385111000044, 30.608700356000043],
            [71.658864000000051, 30.617221000000029],
            [71.65871358000004, 30.617360724000036],
            [71.661688000000083, 30.620612000000051],
            [71.671704000000034, 30.641554000000042],
            [71.672101703000067, 30.65902501000005],
            [71.672160000000076, 30.661586000000057],
            [71.668062000000077, 30.680252000000053],
            [71.673526000000038, 30.686171000000058],
            [71.696289000000036, 30.692090000000064],
            [71.698090931000081, 30.694192198000053],
            [71.698111077000078, 30.69421569900004],
            [71.698348352000039, 30.694492491000062],
            [71.701752000000056, 30.698463000000061],
            [71.698110000000042, 30.721682000000044],
            [71.700603532000059, 30.728482892000045],
            [71.703118000000075, 30.735340000000065],
            [71.707636980000075, 30.740461077000077],
            [71.716776000000038, 30.748088000000052],
            [71.735898000000077, 30.756283000000053],
            [71.738359127000081, 30.76489858900004],
            [71.740450000000067, 30.772217000000069],
            [71.751832000000036, 30.784054000000026],
            [71.765121299000043, 30.788341015000071],
            [71.765121417000046, 30.788341000000059],
            [71.765946000000042, 30.78860700000007],
            [71.774450190000039, 30.788655026000072],
            [71.783701000000065, 30.78860700000007],
            [71.797359000000029, 30.792704000000072],
            [71.798410969000031, 30.796243057000027],
            [71.802367000000061, 30.809550000000058],
            [71.802711769000041, 30.812169719000053],
            [71.804644000000053, 30.826850000000036],
            [71.807375000000036, 30.836866000000043],
            [71.80841530500004, 30.836504250000075],
            [71.817847000000029, 30.83322400000003],
            [71.830594000000076, 30.836866000000043],
            [71.839460451000036, 30.846117728000024],
            [71.841065000000071, 30.847792000000027],
            [71.850261997000075, 30.855734777000066],
            [71.851081000000079, 30.856442000000072],
            [71.855965993000041, 30.865906543000051],
            [71.85663670200006, 30.867206023000051],
            [71.858366000000046, 30.870556000000079],
            [71.844357001000049, 30.87230700300006],
            [71.843797000000052, 30.872377000000029],
            [71.829684000000043, 30.872377000000029],
            [71.818757000000062, 30.877385000000061],
            [71.812383000000068, 30.884669000000031],
            [71.809652000000028, 30.900604000000044],
            [71.822399000000075, 30.915173000000038],
            [71.826041000000032, 30.929286000000047],
            [71.810562000000061, 30.937936000000036],
            [71.789164000000028, 30.957968000000051],
            [71.783512636000069, 30.962408928000059],
            [71.782791000000032, 30.962976000000026],
            [71.780059000000051, 30.982553000000053],
            [71.79690400000004, 31.000308000000075],
            [71.807831000000078, 31.013511000000051],
            [71.814659606000077, 31.032175924000057],
            [71.81466000000006, 31.032177000000047],
            [71.815981495000074, 31.05818189200005],
            [71.816025000000081, 31.059038000000044],
            [71.815981495000074, 31.059089999000037],
            [71.80110144300005, 31.07687528200006],
            [71.797359000000029, 31.081347000000051],
            [71.782335000000046, 31.112305000000049],
            [71.770857184000079, 31.136027566000053],
            [71.768677000000082, 31.140532000000064],
            [71.772319000000039, 31.158743000000072],
            [71.773738500000036, 31.16108150000008],
            [71.790530000000047, 31.177409000000068],
            [71.793717000000072, 31.206091000000072],
            [71.803733000000079, 31.227489000000048],
            [71.829683000000045, 31.237505000000056],
            [71.835207417000049, 31.239196077000031],
            [71.851992000000052, 31.244334000000038],
            [71.866105000000061, 31.25753700000007],
            [71.877895377000073, 31.262919675000035],
            [71.88704800000005, 31.267097000000035],
            [71.919211050000058, 31.265330047000077],
            [71.928477000000044, 31.26482100000004],
            [71.940138413000057, 31.263028353000038],
            [71.969907000000035, 31.258447000000046],
            [71.986337099000082, 31.254492631000062],
            [71.994492000000037, 31.252529000000038],
            [71.996346556000049, 31.252586922000035],
            [72.009060000000034, 31.252984000000026],
            [72.009060090000048, 31.25298470000007],
            [72.010635497000067, 31.265192796000065],
            [72.012703000000045, 31.281211000000042],
            [72.022718000000054, 31.31216900000004],
            [72.022718000000054, 31.320364000000041],
            [72.020897000000048, 31.334933000000035],
            [72.02044200000006, 31.349046000000044],
            [72.020897000000048, 31.367257000000052],
            [72.020889378000049, 31.367531574000054],
            [72.020833038000035, 31.36956106100007],
            [72.02044200000006, 31.383647000000053],
            [72.018226549000076, 31.387990139000067],
            [72.018226080000034, 31.387990064000064],
            [72.014917750000052, 31.394474181000078],
            [72.011623994000047, 31.400929734000044],
            [72.009060000000034, 31.405955000000063],
            [72.008844876000069, 31.441471750000062],
            [72.008825242000057, 31.444713333000038],
            [72.008825715000057, 31.444713511000032],
            [72.008825242000057, 31.444791716000054],
            [72.008748535000052, 31.45748158400005],
            [72.008605000000045, 31.481075000000033],
            [71.984020000000044, 31.489725000000078],
            [71.979468000000054, 31.496099000000072],
            [71.981289000000061, 31.51613100000003],
            [71.981337790000055, 31.516198964000068],
            [71.991396724000083, 31.53020981700007],
            [71.991396909000059, 31.530210074000024],
            [71.994036000000051, 31.533886000000052],
            [72.022718000000054, 31.565300000000036],
            [72.028461431000039, 31.592376108000053],
            [72.02852615200004, 31.592681139000035],
            [72.029092000000048, 31.595348000000058],
            [72.031824000000029, 31.615835000000061],
            [72.04639300000008, 31.636778000000049],
            [72.067790000000059, 31.652712000000065],
            [72.097383000000036, 31.680029000000047],
            [72.098632017000057, 31.681989390000069],
            [72.111449582000034, 31.698179107000044],
            [72.111496000000045, 31.698239000000058],
            [72.125610000000052, 31.715085000000045],
            [72.142455000000041, 31.741490000000056],
            [72.147208633000048, 31.742178674000058],
            [72.147208669000065, 31.742178678000073],
            [72.148918929000047, 31.742355793000058],
            [72.149756910000065, 31.742442575000041],
            [72.152926000000036, 31.742856000000074],
            [72.172503000000063, 31.734206000000029],
            [72.174684250000041, 31.733790697000074],
            [72.177915084000063, 31.733175479000067],
            [72.191624000000047, 31.730564000000072],
            [72.192723855000054, 31.731422584000029],
            [72.196501763000072, 31.734371560000056],
            [72.203306041000076, 31.739682147000053],
            [72.204594891000056, 31.740687960000059],
            [72.210290000000043, 31.745132000000069],
            [72.212917364000077, 31.749493002000065],
            [72.217046000000039, 31.756345000000067],
            [72.217048016000035, 31.756344194000064],
            [72.227252000000078, 31.75226200000003],
            [72.235352411000065, 31.748580111000024],
            [72.239986000000044, 31.746474000000035],
            [72.246094200000073, 31.747413855000048],
            [72.252527986000075, 31.748403407000069],
            [72.255035000000078, 31.748789000000045],
            [72.255485230000033, 31.752843490000032],
            [72.256192000000055, 31.759208000000058],
            [72.266032000000052, 31.763259000000062],
            [72.270678260000068, 31.762329971000042],
            [72.277608000000043, 31.760944000000052],
            [72.277635057000055, 31.760937236000075],
            [72.277647144000071, 31.760934215000077],
            [72.277704363000055, 31.760919912000077],
            [72.277769222000074, 31.760903699000039],
            [72.278202657000065, 31.760795352000059],
            [72.279592359000048, 31.760447964000036],
            [72.284710049000068, 31.759168679000027],
            [72.284710421000057, 31.759168586000044],
            [72.286869000000081, 31.758629000000042],
            [72.300182000000063, 31.753420000000062],
            [72.300205893000054, 31.753406726000037],
            [72.310831497000038, 31.74750378400006],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "102",
      properties: { name: "Jhelum", count: 1338 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.644880000000057, 33.234186000000079],
            [73.645545528000071, 33.23085836100006],
            [73.647141000000033, 33.222881000000029],
            [73.649588643000072, 33.222598297000047],
            [73.649788031000071, 33.222575268000071],
            [73.655368382000063, 33.221931433000066],
            [73.661838000000046, 33.221185000000048],
            [73.669461149000028, 33.212037042000077],
            [73.670317000000068, 33.211010000000044],
            [73.671102063000035, 33.177629553000031],
            [73.671447000000057, 33.162963000000047],
            [73.670779779000043, 33.156622039000069],
            [73.670317000000068, 33.152224000000047],
            [73.656751000000042, 33.145440000000065],
            [73.640059286000053, 33.139876751000031],
            [73.639793000000054, 33.139788000000067],
            [73.637792603000037, 33.138710831000026],
            [73.637603784000078, 33.138609157000076],
            [73.631327283000076, 33.135229404000029],
            [73.625096000000042, 33.131874000000039],
            [73.623966000000053, 33.114351000000056],
            [73.624083510000048, 33.114109467000048],
            [73.625544714000057, 33.111106065000058],
            [73.629268362000062, 33.103452366000056],
            [73.629286501000081, 33.103415083000073],
            [73.634141000000056, 33.093437000000051],
            [73.635878253000044, 33.092261747000066],
            [73.640883189000078, 33.088875901000051],
            [73.641686512000035, 33.088332452000031],
            [73.644878020000078, 33.086173393000024],
            [73.647167358000047, 33.084624653000049],
            [73.648224408000033, 33.083909557000027],
            [73.648239601000057, 33.083899279000036],
            [73.65335900000008, 33.080436000000077],
            [73.655863048000072, 33.080070803000069],
            [73.662897546000067, 33.079044874000033],
            [73.666666337000038, 33.078495224000051],
            [73.674343703000034, 33.07737553700008],
            [73.680491000000075, 33.076479000000063],
            [73.692376255000056, 33.07358823800007],
            [73.693278293000049, 33.073368842000036],
            [73.695810609000034, 33.072752925000032],
            [73.695966334000047, 33.072715049000067],
            [73.701406000000077, 33.07139200000006],
            [73.702579101000083, 33.071087009000053],
            [73.711016828000083, 33.068893314000036],
            [73.720081136000033, 33.06653671600003],
            [73.726623184000061, 33.064835871000071],
            [73.729669000000058, 33.064044000000024],
            [73.737530378000031, 33.060248555000044],
            [73.744192902000066, 33.057031911000024],
            [73.746061000000054, 33.056130000000053],
            [73.74876856700007, 33.053591581000035],
            [73.75370272400005, 33.048965672000065],
            [73.754553440000052, 33.04816810300008],
            [73.755105000000071, 33.04765100000003],
            [73.763456482000038, 33.035369307000053],
            [73.764714000000083, 33.033520000000067],
            [73.769034348000048, 33.028767426000059],
            [73.770281796000063, 33.027395179000052],
            [73.773850024000069, 33.023469970000065],
            [73.773987102000035, 33.023319178000065],
            [73.776019000000076, 33.02108400000003],
            [73.782885000000078, 33.018863000000067],
            [73.782884983000031, 33.018862849000072],
            [73.782884959000057, 33.018862629000068],
            [73.782797000000073, 33.018072000000075],
            [73.783253000000059, 33.003048000000035],
            [73.795090000000073, 33.000316000000055],
            [73.795188013000029, 32.999041078000062],
            [73.795239757000047, 32.998368012000071],
            [73.79568482600007, 32.992578687000048],
            [73.796000000000049, 32.988479000000041],
            [73.795763204000082, 32.987906709000072],
            [73.793560979000063, 32.982584364000047],
            [73.793532830000061, 32.982516334000024],
            [73.79178134600005, 32.978283340000075],
            [73.791405789000066, 32.977375692000066],
            [73.79085807000007, 32.976051964000078],
            [73.790857178000067, 32.97605208300007],
            [73.790848802000085, 32.976031843000044],
            [73.79084957300006, 32.976031429000045],
            [73.790537000000029, 32.975276000000065],
            [73.784943281000039, 32.970275571000059],
            [73.783655000000067, 32.969123930000023],
            [73.776628877000064, 32.962843023000062],
            [73.771060500000033, 32.957865248000076],
            [73.771059920000084, 32.957865624000078],
            [73.771054980000031, 32.957861208000054],
            [73.771055555000032, 32.957860828000037],
            [73.769823712000061, 32.956759638000051],
            [73.767748263000044, 32.954904319000036],
            [73.765119674000061, 32.952554528000064],
            [73.76341910900004, 32.951034331000074],
            [73.760489000000064, 32.948415000000068],
            [73.754110819000061, 32.942249301000061],
            [73.75074967200004, 32.939000127000043],
            [73.749682227000051, 32.937968241000078],
            [73.748622298000043, 32.936943623000047],
            [73.748537934000069, 32.936862069000028],
            [73.747894598000073, 32.936240166000061],
            [73.742533135000031, 32.931057313000053],
            [73.742529140000045, 32.931054418000031],
            [73.740535429000033, 32.929127125000036],
            [73.740535349000083, 32.929126081000049],
            [73.733173000000079, 32.92200900000006],
            [73.73135364500007, 32.920572698000058],
            [73.730593448000036, 32.919972555000072],
            [73.728904812000053, 32.918639449000068],
            [73.724862468000083, 32.915448193000032],
            [73.715603498000064, 32.908138634000068],
            [73.710417087000053, 32.904044186000078],
            [73.706585683000071, 32.901019457000075],
            [73.702475125000035, 32.897774349000031],
            [73.70218684200006, 32.897546762000047],
            [73.698915083000031, 32.894963853000036],
            [73.69857200000007, 32.894693000000075],
            [73.697118733000082, 32.89379739900005],
            [73.697102405000066, 32.893787337000049],
            [73.694815561000041, 32.892378002000044],
            [73.690748331000066, 32.889871472000038],
            [73.689198572000066, 32.888916395000024],
            [73.685709705000079, 32.88676629400004],
            [73.681059592000054, 32.883900547000053],
            [73.680521515000066, 32.883568944000046],
            [73.678735474000064, 32.88246825300007],
            [73.676406947000032, 32.881033240000079],
            [73.674011403000065, 32.879556928000056],
            [73.674011399000051, 32.879557312000031],
            [73.673997549000035, 32.879548777000025],
            [73.673997541000062, 32.879548385000078],
            [73.670905618000063, 32.877642912000056],
            [73.67073973600003, 32.877540683000063],
            [73.669767981000064, 32.876941814000077],
            [73.666859635000037, 32.875149475000057],
            [73.661555178000071, 32.871880473000033],
            [73.659419000000071, 32.870564000000059],
            [73.657289354000056, 32.868028606000053],
            [73.654665083000054, 32.864904350000074],
            [73.653240923000055, 32.86320885400005],
            [73.651600339000083, 32.861255700000072],
            [73.65103281100005, 32.860580045000063],
            [73.650055892000069, 32.859417000000064],
            [73.646357501000068, 32.855013978000045],
            [73.646226199000068, 32.85485766000005],
            [73.643806538000035, 32.851976997000065],
            [73.642772471000058, 32.850745916000051],
            [73.64029800000003, 32.847800000000063],
            [73.638951506000069, 32.845191207000028],
            [73.638767703000042, 32.844835095000064],
            [73.635725692000051, 32.838941289000047],
            [73.634856825000043, 32.837257885000042],
            [73.63180225800005, 32.831339755000045],
            [73.631801650000057, 32.831338578000043],
            [73.625729000000035, 32.819573000000048],
            [73.625723512000036, 32.819549677000055],
            [73.624649845000079, 32.814986346000069],
            [73.62308237700006, 32.808324248000076],
            [73.623081487000036, 32.808324718000051],
            [73.623072949000061, 32.808288427000036],
            [73.623073839000028, 32.80828796000003],
            [73.622400593000066, 32.805426511000064],
            [73.621057416000042, 32.799717699000041],
            [73.620266000000072, 32.796354000000065],
            [73.616185348000045, 32.788563714000077],
            [73.607658086000072, 32.772284499000079],
            [73.605242000000032, 32.767672000000061],
            [73.605003465000038, 32.767240795000077],
            [73.600563163000061, 32.759213966000061],
            [73.593405000000075, 32.746274000000028],
            [73.59274170100008, 32.746163460000048],
            [73.592089157000032, 32.746054713000035],
            [73.582478000000037, 32.744453000000078],
            [73.575923971000066, 32.732874279000043],
            [73.568820000000073, 32.720324000000062],
            [73.554452711000067, 32.709830869000029],
            [73.548327500000084, 32.705357329000037],
            [73.535779037000054, 32.696192575000055],
            [73.535762902000045, 32.696180791000074],
            [73.533306683000035, 32.694386894000047],
            [73.528301000000056, 32.690731000000028],
            [73.527766517000032, 32.690468625000051],
            [73.524865897000041, 32.689044727000066],
            [73.524865752000039, 32.689045147000058],
            [73.52482948200003, 32.689027342000031],
            [73.524829627000031, 32.689026922000039],
            [73.503261000000066, 32.678439000000026],
            [73.496107022000047, 32.674563892000037],
            [73.490923112000075, 32.671755914000073],
            [73.48012828800006, 32.665908663000039],
            [73.470726282000044, 32.660815865000075],
            [73.470481000000063, 32.660683000000063],
            [73.463094600000034, 32.657728602000077],
            [73.444461070000045, 32.650275599000054],
            [73.441022531000044, 32.648900259000072],
            [73.44102244100003, 32.648900223000055],
            [73.435926117000065, 32.646861806000061],
            [73.434060000000045, 32.646115000000066],
            [73.414420889000041, 32.640550250000047],
            [73.40674400000006, 32.638375000000053],
            [73.377842537000049, 32.630008853000049],
            [73.375281220000034, 32.629267425000023],
            [73.372143000000051, 32.628359000000046],
            [73.369785161000038, 32.627515159000041],
            [73.362658159000034, 32.624964492000061],
            [73.359952318000069, 32.62399610500006],
            [73.354730047000032, 32.622127119000027],
            [73.347933327000078, 32.619694657000025],
            [73.345598916000029, 32.618859200000031],
            [73.334755079000047, 32.614978324000049],
            [73.328892000000053, 32.612880000000075],
            [73.328545557000041, 32.612743755000054],
            [73.327102951000029, 32.612176430000034],
            [73.326935709000054, 32.612110655000038],
            [73.31581652300008, 32.607737777000068],
            [73.312554284000043, 32.606454829000029],
            [73.301512711000044, 32.602112484000031],
            [73.288373000000036, 32.596945000000062],
            [73.280726310000034, 32.594523567000067],
            [73.277695101000063, 32.593563692000032],
            [73.269851567000046, 32.591079925000031],
            [73.261057000000051, 32.588295000000073],
            [73.257222427000045, 32.588073743000052],
            [73.237383000000079, 32.586929000000055],
            [73.23557986700007, 32.585726985000065],
            [73.233400607000078, 32.584274233000031],
            [73.229188000000079, 32.581466000000034],
            [73.229187714000034, 32.58146647600006],
            [73.229187000000081, 32.581466000000034],
            [73.22509000000008, 32.588295000000073],
            [73.218309081000029, 32.588295000000073],
            [73.210067000000038, 32.588295000000073],
            [73.209859771000083, 32.588212111000075],
            [73.209859549000043, 32.588212420000048],
            [73.209726805000059, 32.58815932400006],
            [73.195642316000033, 32.582525601000043],
            [73.195642378000059, 32.582525375000046],
            [73.17136800000003, 32.572816000000046],
            [73.165151605000062, 32.571277984000062],
            [73.165169519000074, 32.571247885000048],
            [73.165150964000077, 32.571243294000055],
            [73.161774088000072, 32.570407729000067],
            [73.148747324000055, 32.56718442500005],
            [73.148722496000062, 32.567178282000043],
            [73.13792184600004, 32.564505801000053],
            [73.129248120000057, 32.562359599000047],
            [73.127350267000054, 32.561890000000062],
            [73.126791715000081, 32.561714453000036],
            [73.126593196000044, 32.561652060000029],
            [73.122506589000068, 32.560367682000049],
            [73.10923155200004, 32.55619547200007],
            [73.101910760000067, 32.553894620000051],
            [73.101405958000043, 32.553735966000033],
            [73.095461258000057, 32.551867607000077],
            [73.093796289000068, 32.55134432400007],
            [73.093790905000048, 32.551362328000039],
            [73.084265973000072, 32.548387204000051],
            [73.084265858000038, 32.548387168000033],
            [73.080724632000056, 32.547281061000035],
            [73.079404000000068, 32.54686600000008],
            [73.071578790000046, 32.545897816000036],
            [73.068169067000042, 32.545475944000032],
            [73.065519082000037, 32.545148071000028],
            [73.064530267000066, 32.545025729000031],
            [73.055988159000037, 32.543968845000052],
            [73.052075313000046, 32.543484723000063],
            [73.045622191000064, 32.54268630200005],
            [73.03645044700005, 32.541551517000073],
            [73.03642628800003, 32.541548528000078],
            [73.035242000000039, 32.541402000000062],
            [73.021387115000039, 32.540142528000047],
            [73.020275731000083, 32.540041498000051],
            [73.016536881000036, 32.539701620000073],
            [73.015210000000081, 32.539581000000055],
            [72.997083968000084, 32.545472707000044],
            [72.997000000000071, 32.545500000000061],
            [72.990849323000077, 32.542424402000051],
            [72.990829159000043, 32.542414319000045],
            [72.990852048000079, 32.542354518000025],
            [72.989607351000075, 32.541731724000044],
            [72.989607315000058, 32.541731706000064],
            [72.985411471000077, 32.539636017000078],
            [72.981408431000034, 32.526625953000064],
            [72.979948471000057, 32.521881017000055],
            [72.972649261000072, 32.519359226000063],
            [72.963908266000033, 32.516362000000072],
            [72.95434726600007, 32.514997000000051],
            [72.950675937000028, 32.509183222000047],
            [72.950674650000053, 32.509181184000056],
            [72.950674627000069, 32.509181148000039],
            [72.94888426600005, 32.506346000000065],
            [72.937322718000075, 32.503917258000058],
            [72.93732078000005, 32.503917802000046],
            [72.937273049000055, 32.503907775000073],
            [72.927041880000047, 32.501758506000044],
            [72.91416126300004, 32.499052666000068],
            [72.894708004000051, 32.494966107000039],
            [72.87457558400007, 32.487857638000037],
            [72.874419068000066, 32.487801985000033],
            [72.874260312000047, 32.487745536000034],
            [72.874076000000059, 32.487680000000069],
            [72.872219056000063, 32.485203974000058],
            [72.871963469000036, 32.484863177000079],
            [72.861784000000057, 32.471290000000067],
            [72.855571056000031, 32.466670531000034],
            [72.854260624000062, 32.465696194000031],
            [72.846078225000042, 32.459612390000075],
            [72.844028000000037, 32.458088000000032],
            [72.836874245000047, 32.454419056000063],
            [72.826273000000072, 32.448982000000058],
            [72.823725526000032, 32.446842095000079],
            [72.822849145000077, 32.44610592600003],
            [72.817799025000056, 32.441863772000033],
            [72.817492923000032, 32.441606643000057],
            [72.814891000000046, 32.439421000000038],
            [72.810280111000054, 32.435363482000071],
            [72.803556546000038, 32.429446840000026],
            [72.803509000000076, 32.429405000000031],
            [72.797656541000038, 32.428994313000032],
            [72.777945708000061, 32.427611137000042],
            [72.777559000000053, 32.427584000000024],
            [72.761991676000036, 32.426190224000038],
            [72.747265158000062, 32.424871726000049],
            [72.747056000000043, 32.424853000000041],
            [72.737225034000062, 32.415778347000071],
            [72.732402052000054, 32.411326405000068],
            [72.732257665000077, 32.411193126000057],
            [72.732212420000053, 32.411151362000055],
            [72.729365210000083, 32.408523193000065],
            [72.72930000000008, 32.40846300000004],
            [72.710870268000065, 32.408912241000053],
            [72.699971394000045, 32.395770017000075],
            [72.699730558000056, 32.395743464000077],
            [72.699707000000046, 32.395715000000052],
            [72.698948591000033, 32.395630750000066],
            [72.692305150000038, 32.394892740000046],
            [72.687034406000066, 32.394307221000076],
            [72.675123000000042, 32.39298400000007],
            [72.675122590000058, 32.392984066000054],
            [72.675122000000044, 32.39298400000007],
            [72.663740000000075, 32.394805000000076],
            [72.66328500000003, 32.395271000000037],
            [72.66328500000003, 32.41392600000006],
            [72.663225965000038, 32.414130122000074],
            [72.656457000000046, 32.437145000000044],
            [72.656423202000042, 32.437651714000026],
            [72.656413127000064, 32.437802762000047],
            [72.655546000000072, 32.450803000000064],
            [72.656449726000062, 32.454568084000073],
            [72.656509620000065, 32.454817612000056],
            [72.658278000000053, 32.462185000000034],
            [72.664196000000061, 32.47356700000006],
            [72.654635000000042, 32.497696000000076],
            [72.651565118000065, 32.50882495500008],
            [72.647351000000072, 32.524102000000028],
            [72.648309926000081, 32.530462276000037],
            [72.65053800000004, 32.540492000000029],
            [72.650993000000028, 32.556426000000044],
            [72.650993000000028, 32.577658819000078],
            [72.650993000000028, 32.59160600000007],
            [72.650993000000028, 32.591876194000065],
            [72.650993000000028, 32.59193700000003],
            [72.67284600000005, 32.598311000000024],
            [72.697431000000051, 32.604685000000075],
            [72.702410266000072, 32.605550939000068],
            [72.707048076000035, 32.606357495000054],
            [72.718373000000042, 32.608327000000031],
            [72.741538373000083, 32.61514022800003],
            [72.741592000000082, 32.61515600000007],
            [72.742263106000053, 32.616142891000038],
            [72.742268971000044, 32.616151516000059],
            [72.742271269000071, 32.616154895000079],
            [72.742277112000068, 32.616163487000051],
            [72.743221963000053, 32.617552931000034],
            [72.749332000000038, 32.626538000000039],
            [72.743221963000053, 32.649756856000067],
            [72.742503000000056, 32.65248900000006],
            [72.741422726000053, 32.655297522000069],
            [72.740352271000063, 32.65808051700003],
            [72.733397000000082, 32.676163000000031],
            [72.738498918000062, 32.682464593000077],
            [72.741137000000037, 32.685723000000053],
            [72.755706000000032, 32.693008000000077],
            [72.769177579000029, 32.695252934000052],
            [72.769364000000053, 32.695284000000072],
            [72.788940000000082, 32.683447000000058],
            [72.79343736900006, 32.678156200000046],
            [72.795365281000045, 32.675888163000025],
            [72.80442000000005, 32.66523600000005],
            [72.816237646000047, 32.661747047000063],
            [72.84781932900006, 32.652423107000061],
            [72.852223000000038, 32.651123000000041],
            [72.856143919000033, 32.648508974000038],
            [72.868591730000048, 32.640210180000054],
            [72.868613000000039, 32.64019600000006],
            [72.877035963000083, 32.652409990000024],
            [72.87705515600004, 32.652437821000035],
            [72.877683321000063, 32.653348713000071],
            [72.877718000000073, 32.653399000000036],
            [72.896840000000054, 32.653399000000036],
            [72.915132719000042, 32.654948988000058],
            [72.923701000000051, 32.655675000000031],
            [72.930916280000076, 32.654929044000028],
            [72.936851919000048, 32.654315384000029],
            [72.939036627000064, 32.667629375000047],
            [72.939085337000051, 32.667926220000027],
            [72.939170665000063, 32.667911983000067],
            [72.939180000000079, 32.66796800000003],
            [72.952838000000042, 32.665691000000038],
            [72.962187406000055, 32.66521184000004],
            [72.970594000000062, 32.664781000000062],
            [72.985593597000047, 32.667507564000061],
            [72.993280153000057, 32.677034743000036],
            [72.994677650000028, 32.690523427000073],
            [72.995520105000082, 32.691620248000049],
            [73.004354679000073, 32.695452984000042],
            [73.009172855000031, 32.697543267000071],
            [73.01347254500007, 32.70121976400003],
            [73.013472879000062, 32.701220030000059],
            [73.024628757000073, 32.710758954000028],
            [73.024674105000031, 32.710680353000043],
            [73.024771000000044, 32.710763000000043],
            [73.037063000000046, 32.689366000000064],
            [73.047079000000053, 32.689366000000064],
            [73.069843000000049, 32.676618000000076],
            [73.087038288000031, 32.668681341000024],
            [73.087598000000071, 32.668423000000075],
            [73.092606000000046, 32.674342000000024],
            [73.092408277000061, 32.678199774000063],
            [73.09169600000007, 32.692097000000047],
            [73.092431462000036, 32.693862076000073],
            [73.096246172000065, 32.703017214000056],
            [73.096249000000057, 32.703024000000028],
            [73.115802810000048, 32.707368761000055],
            [73.124877416000061, 32.709385094000027],
            [73.124931000000061, 32.709397000000024],
            [73.137678000000051, 32.72351100000003],
            [73.139759481000056, 32.723771167000052],
            [73.139830822000079, 32.723780084000055],
            [73.148416370000064, 32.724853204000055],
            [73.16039796900003, 32.726350801000024],
            [73.166813782000077, 32.727152723000074],
            [73.16681600000004, 32.727153000000044],
            [73.175693028000069, 32.727153000000044],
            [73.183215540000049, 32.727153000000044],
            [73.183215662000066, 32.727151197000069],
            [73.183346346000064, 32.727151185000025],
            [73.183346187000041, 32.727153000000044],
            [73.187933036000061, 32.727153000000044],
            [73.194108131000064, 32.727153000000044],
            [73.212552598000059, 32.729735055000049],
            [73.216864631000078, 32.730338700000061],
            [73.21976797800005, 32.737490023000078],
            [73.220353681000063, 32.738932687000045],
            [73.220353723000073, 32.738932790000035],
            [73.220935395000083, 32.740365524000026],
            [73.245086891000028, 32.740819016000046],
            [73.245108541000036, 32.740810746000079],
            [73.245122000000038, 32.740811000000065],
            [73.257809886000075, 32.735960111000054],
            [73.260601000000065, 32.734893000000056],
            [73.263130000000046, 32.742478000000062],
            [73.264212883000084, 32.74572859500006],
            [73.264243000000079, 32.74581900000004],
            [73.264212883000084, 32.745880914000054],
            [73.256049000000075, 32.762664000000029],
            [73.254961641000079, 32.766106973000035],
            [73.250585000000058, 32.779965000000061],
            [73.249945000000082, 32.783484000000044],
            [73.249665910000033, 32.785019183000031],
            [73.263582020000058, 32.779368005000038],
            [73.26711411000008, 32.788775024000074],
            [73.269385384000032, 32.792958652000038],
            [73.272276304000059, 32.798980886000038],
            [73.275802760000033, 32.800353363000056],
            [73.279543794000062, 32.799381800000049],
            [73.283154918000037, 32.798443975000055],
            [73.28337434000008, 32.798103038000079],
            [73.28339408100004, 32.806516467000051],
            [73.283460210000044, 32.806535503000077],
            [73.281913831000054, 32.80859533000006],
            [73.281043982000028, 32.811804146000043],
            [73.276484005000043, 32.813679604000072],
            [73.273228732000064, 32.81600250200006],
            [73.270668933000081, 32.818985667000049],
            [73.266803029000073, 32.823394004000079],
            [73.261981883000033, 32.828232401000037],
            [73.257053084000063, 32.833389897000075],
            [73.255029054000033, 32.835789632000058],
            [73.252923344000067, 32.838312631000065],
            [73.251870546000077, 32.839495174000035],
            [73.247974026000065, 32.841954206000025],
            [73.245133800000076, 32.844479220000039],
            [73.243917814000042, 32.845560254000077],
            [73.238733985000067, 32.850148961000059],
            [73.23599538700006, 32.851973187000056],
            [73.232608338000034, 32.853459460000067],
            [73.22750663000005, 32.855036226000038],
            [73.225881082000058, 32.855266269000026],
            [73.223267854000085, 32.855428000000074],
            [73.220763974000079, 32.855417433000071],
            [73.219288464000044, 32.855985555000075],
            [73.218005449000032, 32.856801935000078],
            [73.217615587000068, 32.856991477000065],
            [73.216028100000074, 32.85776327700006],
            [73.213614757000073, 32.858563864000075],
            [73.211474986000042, 32.85947617000005],
            [73.208568281000055, 32.860586260000048],
            [73.207212025000047, 32.861634380000055],
            [73.204233289000058, 32.860078896000061],
            [73.202027604000079, 32.858799160000046],
            [73.199968105000039, 32.856877972000063],
            [73.197392730000047, 32.853882106000071],
            [73.196154174000071, 32.852158577000068],
            [73.195600555000055, 32.849884222000071],
            [73.195462115000055, 32.847834808000073],
            [73.194420951000041, 32.846659849000048],
            [73.192499744000031, 32.847142443000052],
            [73.190075638000053, 32.847765120000076],
            [73.187654397000074, 32.848810108000066],
            [73.184936932000085, 32.849747386000047],
            [73.181883045000063, 32.850493667000023],
            [73.177025310000033, 32.85155184000007],
            [73.174018319000083, 32.85127847900003],
            [73.171785469000042, 32.851053877000027],
            [73.16954744800006, 32.84955042200005],
            [73.167655018000062, 32.847558157000037],
            [73.16606383900006, 32.846521620000033],
            [73.164282987000036, 32.846324767000056],
            [73.162468381000053, 32.847318474000076],
            [73.160638299000084, 32.847714065000048],
            [73.159365677000039, 32.847163378000062],
            [73.153845415000035, 32.84493580000003],
            [73.153775988000064, 32.844935836000047],
            [73.152702000000033, 32.847800000000063],
            [73.155434000000071, 32.864645000000053],
            [73.155889000000059, 32.88194500000003],
            [73.155889000000059, 32.886830871000029],
            [73.155889000000059, 32.896491596000033],
            [73.155889000000059, 32.896514000000025],
            [73.168181000000061, 32.898790000000076],
            [73.170225927000047, 32.906598125000073],
            [73.170225936000065, 32.906598161000034],
            [73.173189000000036, 32.917912000000058],
            [73.173998687000051, 32.920071205000056],
            [73.176431642000068, 32.926559204000057],
            [73.180018000000075, 32.936123000000066],
            [73.181063187000063, 32.93814373500004],
            [73.186847000000057, 32.949326000000042],
            [73.187242814000058, 32.949735911000062],
            [73.192832355000064, 32.955524514000047],
            [73.199595000000045, 32.962528000000077],
            [73.204737912000041, 32.967066254000031],
            [73.207335000000057, 32.969358000000057],
            [73.214908024000067, 32.984504972000025],
            [73.21552900000006, 32.98574700000006],
            [73.225381475000063, 33.003875658000027],
            [73.22691100000003, 33.006690000000049],
            [73.20553787700004, 33.025725334000072],
            [73.198872504000065, 33.031661650000046],
            [73.198265566000032, 33.032202201000075],
            [73.197674527000061, 33.032047755000065],
            [73.196823977000065, 33.03280281900004],
            [73.193513412000073, 33.047677436000072],
            [73.193116135000082, 33.049462430000062],
            [73.193115989000034, 33.04946308600006],
            [73.192670055000065, 33.051466695000045],
            [73.194497363000039, 33.05646519700008],
            [73.194477388000053, 33.056477670000049],
            [73.195748045000073, 33.059953336000035],
            [73.195768242000042, 33.059941471000059],
            [73.195772580000039, 33.059953336000035],
            [73.195832426000038, 33.060117035000076],
            [73.195687527000075, 33.061179564000042],
            [73.19486508500006, 33.067210270000032],
            [73.193970230000048, 33.07377232500005],
            [73.195832462000055, 33.076108016000035],
            [73.198927824000066, 33.079990352000038],
            [73.198927853000043, 33.079990388000056],
            [73.203043948000072, 33.085153222000031],
            [73.203925255000058, 33.095165701000042],
            [73.205058000000065, 33.095012000000054],
            [73.205058036000082, 33.09501199500005],
            [73.211632408000071, 33.094115699000042],
            [73.215845721000051, 33.093541290000076],
            [73.22006336100003, 33.092966291000039],
            [73.22509000000008, 33.092281000000071],
            [73.226912334000076, 33.092043278000062],
            [73.227276804000041, 33.091995733000033],
            [73.246033000000068, 33.089549000000034],
            [73.261502878000044, 33.095463922000079],
            [73.267728394000073, 33.09634045100006],
            [73.267728576000081, 33.096340477000069],
            [73.269878443000039, 33.096642204000034],
            [73.281394099000067, 33.09825838900008],
            [73.28687043900004, 33.099026976000061],
            [73.287462000000062, 33.099110000000053],
            [73.294952921000061, 33.100955665000072],
            [73.301077805000034, 33.102464757000064],
            [73.30945354000005, 33.104528429000027],
            [73.312967852000043, 33.105394310000065],
            [73.316212876000066, 33.106193841000049],
            [73.318876000000046, 33.106850000000065],
            [73.326985982000053, 33.106850000000065],
            [73.331323725000061, 33.106850000000065],
            [73.33344500000004, 33.106850000000065],
            [73.339029339000035, 33.107447993000051],
            [73.344238473000075, 33.10800580800003],
            [73.34619200000003, 33.10821500000003],
            [73.352842718000034, 33.106179060000045],
            [73.365873214000032, 33.102190121000035],
            [73.36850000000004, 33.101386000000048],
            [73.36932033100004, 33.103245136000055],
            [73.372740209000028, 33.110995692000074],
            [73.373288890000083, 33.112239182000053],
            [73.375330000000076, 33.116865000000075],
            [73.37581238100006, 33.117284486000074],
            [73.39354000000003, 33.132800000000032],
            [73.39315035900006, 33.13361825100003],
            [73.38824786400005, 33.143913563000069],
            [73.382889638000051, 33.155165915000055],
            [73.379882000000066, 33.161482000000035],
            [73.377596032000042, 33.163647723000054],
            [73.368847071000062, 33.171936479000067],
            [73.362582000000032, 33.177872000000036],
            [73.363840306000043, 33.179565829000069],
            [73.372726399000044, 33.191527559000065],
            [73.374419000000046, 33.193806000000052],
            [73.380067736000058, 33.193392921000054],
            [73.38350065700007, 33.193141880000042],
            [73.393085000000042, 33.192441000000031],
            [73.394417163000071, 33.192751753000039],
            [73.406743000000063, 33.195627000000059],
            [73.413117000000057, 33.200635000000034],
            [73.413117642000032, 33.200649437000038],
            [73.413120442000036, 33.200651643000072],
            [73.413191153000071, 33.202253408000047],
            [73.413493995000067, 33.20911344600006],
            [73.413948338000068, 33.219331436000061],
            [73.41402800000003, 33.221123000000034],
            [73.416035513000054, 33.225042689000077],
            [73.421858402000055, 33.236411942000075],
            [73.422836919000076, 33.238322506000031],
            [73.423588000000052, 33.23978900000003],
            [73.42888827400003, 33.241163113000027],
            [73.435881000000052, 33.242976000000056],
            [73.437645797000073, 33.243943743000045],
            [73.441600550000032, 33.246112370000048],
            [73.44160058600005, 33.246112390000064],
            [73.449994000000061, 33.250715000000071],
            [73.460465000000056, 33.252992000000063],
            [73.46177334500004, 33.247976320000078],
            [73.463806172000034, 33.240183257000069],
            [73.464308849000076, 33.238256189000026],
            [73.465920377000032, 33.232070116000045],
            [73.466316273000075, 33.230865102000053],
            [73.467914131000043, 33.226091573000076],
            [73.467955706000055, 33.225966870000036],
            [73.467955718000042, 33.225966834000076],
            [73.470026000000075, 33.219757000000072],
            [73.475995954000041, 33.224021252000057],
            [73.475995990000058, 33.224021278000066],
            [73.482773000000066, 33.228862000000049],
            [73.495325223000066, 33.228503590000059],
            [73.501030823000065, 33.228290923000031],
            [73.507813000000056, 33.227952000000073],
            [73.507814727000039, 33.227952128000027],
            [73.507824336000056, 33.227951648000044],
            [73.507824550000066, 33.227952380000033],
            [73.516662582000038, 33.22860696500004],
            [73.520106000000055, 33.228862000000049],
            [73.522194187000082, 33.231576758000074],
            [73.523313838000036, 33.233032366000032],
            [73.524936986000057, 33.235142547000066],
            [73.528122695000036, 33.239284143000077],
            [73.529211000000032, 33.240699000000063],
            [73.532670453000037, 33.234933606000027],
            [73.538772000000051, 33.224765000000048],
            [73.546056000000078, 33.217025000000035],
            [73.545448835000059, 33.211965883000062],
            [73.54469000000006, 33.205643000000066],
            [73.546565139000052, 33.205241436000051],
            [73.547148569000058, 33.205116494000038],
            [73.551064000000054, 33.204278000000045],
            [73.55288500000006, 33.211107000000027],
            [73.554706000000067, 33.221578000000079],
            [73.557893000000036, 33.232960000000048],
            [73.559520724000038, 33.233192556000063],
            [73.563092049000034, 33.233702799000071],
            [73.567454000000055, 33.234326000000067],
            [73.572191991000068, 33.234172913000066],
            [73.572193105000053, 33.234172877000049],
            [73.581553800000052, 33.233870426000067],
            [73.581567385000085, 33.23386886000003],
            [73.593404000000078, 33.232504000000063],
            [73.598412000000053, 33.243886000000032],
            [73.606592214000045, 33.252520394000044],
            [73.614380253000036, 33.253702310000051],
            [73.62468165000007, 33.255247946000054],
            [73.624682000000064, 33.255248000000051],
            [73.627357000000075, 33.241534000000058],
            [73.644880000000057, 33.234186000000079],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "103",
      properties: { name: "Kasur", count: 781 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.493718000000058, 31.358371000000034],
            [74.497253000000057, 31.345240000000047],
            [74.504828000000032, 31.348775000000046],
            [74.515434000000084, 31.352815000000078],
            [74.515809248000039, 31.352985552000064],
            [74.520933430000071, 31.355314516000078],
            [74.526545000000056, 31.357864999000071],
            [74.551839434000044, 31.353897400000051],
            [74.551252000000034, 31.353186000000051],
            [74.551198000000056, 31.352295000000026],
            [74.551077000000078, 31.351957000000027],
            [74.550863000000049, 31.351575000000025],
            [74.550677000000064, 31.350884000000065],
            [74.550491000000079, 31.350528000000054],
            [74.550373000000036, 31.350195000000042],
            [74.550207000000057, 31.349653000000046],
            [74.550102000000038, 31.349039000000062],
            [74.549945000000037, 31.348370000000045],
            [74.549686000000065, 31.347562000000039],
            [74.546194000000071, 31.345011000000056],
            [74.54686300000003, 31.344041000000061],
            [74.547787000000085, 31.342883000000029],
            [74.547513000000038, 31.341870000000029],
            [74.546649000000059, 31.341526000000044],
            [74.545856000000072, 31.340799000000061],
            [74.546722000000045, 31.339205000000049],
            [74.546402000000057, 31.338902000000076],
            [74.547071000000074, 31.337960000000066],
            [74.548062000000073, 31.336530000000039],
            [74.548658000000046, 31.33484100000004],
            [74.548250000000053, 31.334772000000044],
            [74.548065000000065, 31.334795000000042],
            [74.547690000000046, 31.334816000000046],
            [74.54746300000005, 31.334805000000074],
            [74.547036000000048, 31.334725000000049],
            [74.546586000000048, 31.334737000000075],
            [74.546177000000057, 31.334933000000035],
            [74.54574000000008, 31.335037000000057],
            [74.545327000000043, 31.335061000000053],
            [74.544966000000045, 31.334981000000028],
            [74.544511000000057, 31.334899000000064],
            [74.54421700000006, 31.334989000000064],
            [74.544004000000029, 31.335161000000028],
            [74.543724000000054, 31.335171000000059],
            [74.543582000000072, 31.335061000000053],
            [74.543566000000055, 31.334700000000055],
            [74.543255000000045, 31.334479000000044],
            [74.542932000000064, 31.334259000000031],
            [74.54177500000003, 31.332724000000042],
            [74.539610000000039, 31.331405000000075],
            [74.539328000000069, 31.331046000000072],
            [74.538765000000069, 31.330964000000051],
            [74.53873800000008, 31.330720000000042],
            [74.537282000000062, 31.330703000000028],
            [74.537259000000063, 31.330299000000025],
            [74.536781000000076, 31.330171000000064],
            [74.536866000000032, 31.32955400000003],
            [74.537153000000046, 31.328824000000054],
            [74.537479000000076, 31.327997000000039],
            [74.537540000000035, 31.326796000000058],
            [74.53679900000003, 31.326265000000035],
            [74.535841000000062, 31.325968000000046],
            [74.534919000000059, 31.325926000000038],
            [74.534640000000081, 31.325302000000079],
            [74.533873000000028, 31.325067000000047],
            [74.534176000000059, 31.324493000000075],
            [74.534037000000069, 31.323634000000027],
            [74.534073000000035, 31.322883000000047],
            [74.534383000000048, 31.321985000000041],
            [74.535116000000073, 31.321479000000068],
            [74.536047000000053, 31.321095000000071],
            [74.536666000000082, 31.321037000000047],
            [74.536706000000038, 31.320687000000078],
            [74.537579000000051, 31.320685000000026],
            [74.537743000000034, 31.319234000000051],
            [74.537411000000077, 31.319293000000073],
            [74.537138000000084, 31.317847000000029],
            [74.536099000000036, 31.31773000000004],
            [74.535865000000058, 31.317250000000058],
            [74.535167000000058, 31.317320000000052],
            [74.534856000000048, 31.316948000000025],
            [74.534641000000079, 31.316890000000058],
            [74.534296424000047, 31.316477079000038],
            [74.533915000000036, 31.316020000000037],
            [74.534955000000082, 31.315534000000071],
            [74.534833000000049, 31.31517800000006],
            [74.535353000000043, 31.314985000000036],
            [74.534800000000075, 31.313498000000038],
            [74.534033000000079, 31.31383100000005],
            [74.533814000000064, 31.313620000000071],
            [74.532995000000085, 31.313987000000054],
            [74.532309000000055, 31.313618000000076],
            [74.531996000000049, 31.312966000000074],
            [74.53123400000004, 31.313297000000034],
            [74.529064000000062, 31.314171000000044],
            [74.52854700000006, 31.313260000000071],
            [74.527967000000046, 31.313412000000028],
            [74.527392000000077, 31.312470000000076],
            [74.526376000000084, 31.31243500000005],
            [74.526311000000078, 31.311743000000035],
            [74.52724100000006, 31.311149000000057],
            [74.527932000000078, 31.310161000000051],
            [74.52844900000008, 31.30892300000005],
            [74.528779000000043, 31.308879000000047],
            [74.529320000000041, 31.308712000000071],
            [74.53017600000004, 31.308468000000062],
            [74.531463000000031, 31.30822900000004],
            [74.532588000000032, 31.307389000000057],
            [74.532670000000053, 31.307174000000032],
            [74.532588000000032, 31.306856000000039],
            [74.533065000000079, 31.306256000000076],
            [74.533653000000072, 31.305508000000032],
            [74.534007000000031, 31.305310000000077],
            [74.534252000000038, 31.304954000000066],
            [74.534548000000029, 31.304896000000042],
            [74.535004000000072, 31.304941000000042],
            [74.535677000000078, 31.304676000000029],
            [74.536505000000034, 31.30469800000003],
            [74.53698200000008, 31.304918000000043],
            [74.537261000000058, 31.30506900000006],
            [74.537701000000084, 31.305280000000039],
            [74.539457000000084, 31.305851000000075],
            [74.540953000000059, 31.306100000000072],
            [74.541551000000084, 31.305585000000065],
            [74.541997000000038, 31.30391300000008],
            [74.541859000000045, 31.303559000000064],
            [74.541379000000063, 31.303320000000042],
            [74.540685000000053, 31.302967000000024],
            [74.53981900000008, 31.302535000000034],
            [74.538712000000032, 31.302272000000073],
            [74.538068000000067, 31.301336000000049],
            [74.537530000000061, 31.301082000000065],
            [74.537534000000051, 31.300474000000065],
            [74.53739900000005, 31.300291000000072],
            [74.537286000000051, 31.299870000000055],
            [74.537158000000034, 31.299325000000067],
            [74.537033000000065, 31.29867100000007],
            [74.536952000000042, 31.298492000000067],
            [74.536102000000085, 31.296668000000068],
            [74.535708000000056, 31.295856000000072],
            [74.535197000000039, 31.295313000000078],
            [74.535116000000073, 31.294946000000039],
            [74.534930000000031, 31.294854000000043],
            [74.534544000000039, 31.294091000000037],
            [74.534557000000063, 31.293310000000076],
            [74.533166000000051, 31.291144000000031],
            [74.532766000000038, 31.29045300000007],
            [74.532525000000078, 31.289965000000052],
            [74.532549000000074, 31.289743000000044],
            [74.532670000000053, 31.289435000000026],
            [74.53290400000003, 31.289082000000064],
            [74.532567000000029, 31.288083000000029],
            [74.532088000000044, 31.288155000000074],
            [74.532077000000072, 31.287504000000069],
            [74.531958000000031, 31.286648000000071],
            [74.531427000000065, 31.285468000000037],
            [74.531173000000081, 31.28439000000003],
            [74.53082100000006, 31.283865000000048],
            [74.530234000000064, 31.283319000000063],
            [74.529399000000069, 31.282612000000029],
            [74.528733000000045, 31.282180000000039],
            [74.52853900000008, 31.281253000000049],
            [74.527663000000075, 31.280207000000075],
            [74.527333000000056, 31.279558000000065],
            [74.526722000000063, 31.277978000000076],
            [74.526345000000049, 31.27664500000003],
            [74.525273000000084, 31.276041000000077],
            [74.52364300000005, 31.274874000000068],
            [74.522691988000076, 31.274017833000073],
            [74.522530000000074, 31.27387200000004],
            [74.521654000000069, 31.272274000000039],
            [74.520867000000067, 31.271994000000063],
            [74.520921000000044, 31.270920000000046],
            [74.520882000000029, 31.269809000000066],
            [74.520564000000036, 31.268979000000058],
            [74.521029000000055, 31.268269000000032],
            [74.521539000000075, 31.267640000000029],
            [74.521909000000051, 31.267207000000042],
            [74.522281000000078, 31.266227000000072],
            [74.522837000000038, 31.264858000000061],
            [74.523115000000075, 31.263649000000044],
            [74.523654000000079, 31.262473000000057],
            [74.523961000000043, 31.261544000000072],
            [74.524331000000075, 31.260954000000027],
            [74.526086000000078, 31.259831000000077],
            [74.52702800000003, 31.258977000000073],
            [74.527382000000046, 31.258124000000066],
            [74.528128000000038, 31.25578900000005],
            [74.529024000000049, 31.25490700000006],
            [74.529858000000047, 31.254182000000071],
            [74.530929000000071, 31.253459000000078],
            [74.531997000000047, 31.252527000000043],
            [74.532293000000038, 31.251909000000069],
            [74.53252800000007, 31.250984000000074],
            [74.532762000000048, 31.24975100000006],
            [74.532403000000045, 31.248724000000038],
            [74.531868000000031, 31.247700000000066],
            [74.531630000000064, 31.246986000000049],
            [74.531155000000069, 31.245812000000058],
            [74.530870943000082, 31.245219126000052],
            [74.530742000000032, 31.244950000000074],
            [74.530329000000052, 31.244442000000049],
            [74.529563000000053, 31.243380000000059],
            [74.528976000000057, 31.242116000000067],
            [74.528741000000082, 31.241157000000044],
            [74.528214000000048, 31.240200000000073],
            [74.527688000000069, 31.239395000000059],
            [74.526578000000029, 31.238241000000073],
            [74.525551000000064, 31.237525000000062],
            [74.524708000000032, 31.237527000000057],
            [74.524093746000062, 31.237349549000044],
            [74.523125038000046, 31.237720517000071],
            [74.523126099000081, 31.23766609200004],
            [74.523111509000046, 31.237323923000076],
            [74.521775000000048, 31.236071000000038],
            [74.520886000000075, 31.235034000000041],
            [74.519049000000052, 31.233328000000029],
            [74.519779000000028, 31.23194200000006],
            [74.51690300000007, 31.228408000000059],
            [74.517101000000082, 31.226830000000064],
            [74.518714000000045, 31.226019000000065],
            [74.520936000000063, 31.22509000000008],
            [74.521239000000037, 31.224778000000072],
            [74.52196900000007, 31.22379200000006],
            [74.522694000000058, 31.223171000000036],
            [74.522893000000067, 31.220797000000061],
            [74.523551000000054, 31.220746000000076],
            [74.524510000000078, 31.220182000000079],
            [74.524279000000035, 31.21864400000004],
            [74.523567000000071, 31.218029000000058],
            [74.524229000000048, 31.217008000000078],
            [74.525010000000066, 31.215530000000058],
            [74.525315000000035, 31.213955000000055],
            [74.525480093000056, 31.213298040000041],
            [74.525557000000049, 31.212992000000042],
            [74.525144000000068, 31.212435000000028],
            [74.525932217000047, 31.212004891000049],
            [74.525898056000074, 31.211716621000051],
            [74.525754000000063, 31.211228000000062],
            [74.525757000000056, 31.210552000000064],
            [74.524803000000077, 31.209307000000024],
            [74.523787000000084, 31.208841000000064],
            [74.523553000000049, 31.208169000000055],
            [74.525359000000037, 31.206059000000039],
            [74.52536200000003, 31.205393000000072],
            [74.524706000000037, 31.205290000000048],
            [74.525130000000047, 31.203755000000058],
            [74.522335000000055, 31.202834000000053],
            [74.521441000000038, 31.202681000000041],
            [74.521514000000082, 31.201204000000075],
            [74.521523000000059, 31.20034000000004],
            [74.520651000000043, 31.19876800000003],
            [74.520958000000064, 31.197556000000077],
            [74.521615000000054, 31.19685000000004],
            [74.52127900000005, 31.195951000000036],
            [74.520553000000064, 31.194946000000073],
            [74.520009000000073, 31.194210000000055],
            [74.520491225000058, 31.193121321000035],
            [74.520569633000036, 31.193109274000051],
            [74.52063989100003, 31.19295065600005],
            [74.521592071000043, 31.190801000000079],
            [74.521111071000064, 31.189340000000072],
            [74.520090071000084, 31.18778100000003],
            [74.520211071000062, 31.186900000000037],
            [74.520091071000081, 31.186172000000056],
            [74.519914071000073, 31.184773000000064],
            [74.519138071000043, 31.183434000000034],
            [74.517821071000071, 31.18374300000005],
            [74.517104071000063, 31.183640000000025],
            [74.516090071000065, 31.182971000000066],
            [74.513401071000033, 31.182917000000032],
            [74.51274507100004, 31.182762000000025],
            [74.511554071000035, 31.182350000000042],
            [74.510916071000054, 31.181624000000056],
            [74.510563071000036, 31.180563000000063],
            [74.510517071000038, 31.179342000000076],
            [74.510534071000052, 31.177708000000052],
            [74.510183071000029, 31.176550000000077],
            [74.509892071000081, 31.175603000000024],
            [74.509899867000058, 31.175398663000067],
            [74.509900198000082, 31.175389989000053],
            [74.509982071000081, 31.173244000000068],
            [74.51089107100006, 31.172672000000034],
            [74.510362071000031, 31.171371000000079],
            [74.51126607100008, 31.171061000000066],
            [74.510815071000081, 31.16820800000005],
            [74.51274907100003, 31.166659000000038],
            [74.511751071000049, 31.164652000000046],
            [74.512893071000065, 31.164037000000064],
            [74.512241071000062, 31.162800000000061],
            [74.511891071000036, 31.161735000000078],
            [74.51177307100005, 31.160903000000076],
            [74.511439071000041, 31.158991000000071],
            [74.511147071000039, 31.158480000000054],
            [74.51086596500005, 31.15805041200008],
            [74.510757071000057, 31.157884000000024],
            [74.511061071000029, 31.157658000000026],
            [74.512068071000044, 31.157207000000028],
            [74.513109071000031, 31.156581000000074],
            [74.513764071000082, 31.15578400000004],
            [74.513802071000043, 31.155429000000026],
            [74.513545071000067, 31.154391000000032],
            [74.512914071000068, 31.153650000000027],
            [74.513196071000038, 31.153030000000058],
            [74.515205071000082, 31.152005000000031],
            [74.514940071000069, 31.151450000000068],
            [74.515281071000061, 31.151245000000074],
            [74.515149071000053, 31.150983000000053],
            [74.514946071000054, 31.15095400000007],
            [74.514812071000051, 31.150867000000062],
            [74.514521071000047, 31.14987700000006],
            [74.51382207100005, 31.149068000000057],
            [74.513870071000042, 31.14869200000004],
            [74.513775071000055, 31.146988000000078],
            [74.514151071000072, 31.144888000000037],
            [74.514163366000048, 31.144668048000028],
            [74.514091464000046, 31.144647129000077],
            [74.514223000000072, 31.142294000000049],
            [74.513524000000075, 31.13922400000007],
            [74.512507000000085, 31.137076000000036],
            [74.511347000000058, 31.135645000000068],
            [74.510292000000049, 31.13198200000005],
            [74.511679000000072, 31.132180000000062],
            [74.514807000000076, 31.131027000000074],
            [74.515543000000036, 31.130971000000045],
            [74.517034328000079, 31.130523126000071],
            [74.519622000000084, 31.129746000000068],
            [74.520942000000048, 31.129517000000078],
            [74.522870000000069, 31.129315000000076],
            [74.523985000000039, 31.129403000000025],
            [74.525139000000081, 31.12925400000006],
            [74.525683000000072, 31.128876000000048],
            [74.526565000000062, 31.128498000000036],
            [74.528155000000083, 31.128033000000073],
            [74.529446000000064, 31.12684100000007],
            [74.53095200000007, 31.126127000000054],
            [74.534258000000079, 31.124756000000048],
            [74.534670000000062, 31.124263000000042],
            [74.537446000000045, 31.123307000000068],
            [74.537451867000073, 31.123306119000063],
            [74.53879100000006, 31.123105000000066],
            [74.53869700000007, 31.122499000000062],
            [74.540749000000062, 31.122124000000042],
            [74.541940000000068, 31.121499000000028],
            [74.543822000000034, 31.12114100000008],
            [74.544388000000083, 31.121672000000046],
            [74.545303000000047, 31.121848000000057],
            [74.545460000000048, 31.122441000000038],
            [74.54685900000004, 31.122058000000038],
            [74.548458000000039, 31.121882000000028],
            [74.548273373000029, 31.120211823000034],
            [74.548220000000072, 31.119729000000063],
            [74.548336000000063, 31.118733000000077],
            [74.548446000000069, 31.11780200000004],
            [74.548209000000043, 31.115696000000071],
            [74.547617000000059, 31.11469500000004],
            [74.54977800000006, 31.113015000000075],
            [74.549648000000047, 31.109457000000077],
            [74.549873000000048, 31.105912000000046],
            [74.550065000000075, 31.103548000000046],
            [74.549163000000078, 31.101935000000026],
            [74.549380000000042, 31.100532000000044],
            [74.549540206000074, 31.100171590000059],
            [74.549949967000032, 31.099249761000067],
            [74.550906000000055, 31.097099000000071],
            [74.550583000000074, 31.095774000000063],
            [74.550370000000044, 31.093571000000054],
            [74.550321000000054, 31.089133000000061],
            [74.550763000000074, 31.087929000000031],
            [74.551234000000079, 31.086812000000066],
            [74.553603000000066, 31.085344000000077],
            [74.555215584000052, 31.085051101000033],
            [74.556064000000049, 31.084897000000069],
            [74.557130000000029, 31.084393000000034],
            [74.55780100000004, 31.08372200000008],
            [74.557974000000058, 31.083248000000026],
            [74.560152000000073, 31.083109000000036],
            [74.560882000000049, 31.082804000000067],
            [74.562293000000068, 31.083471000000031],
            [74.563057000000072, 31.082633000000044],
            [74.563780000000065, 31.083193000000051],
            [74.564326000000051, 31.082434000000035],
            [74.567613000000051, 31.082111000000054],
            [74.571735000000047, 31.081614000000059],
            [74.572660000000042, 31.083667000000048],
            [74.573083000000054, 31.084142000000043],
            [74.57379300000008, 31.08585400000004],
            [74.575478000000032, 31.085815000000025],
            [74.577207000000044, 31.085964000000047],
            [74.577530612000032, 31.086162719000072],
            [74.57779000000005, 31.086322000000052],
            [74.578774000000067, 31.085705000000075],
            [74.579979000000037, 31.086918000000026],
            [74.580571000000077, 31.08711500000004],
            [74.581871000000035, 31.08728700000006],
            [74.582073000000037, 31.087145000000078],
            [74.582339000000047, 31.087231000000031],
            [74.582475000000045, 31.086919000000023],
            [74.583439000000055, 31.087317000000041],
            [74.583770000000072, 31.086560000000077],
            [74.585659000000078, 31.086737000000028],
            [74.58734000000004, 31.087318000000039],
            [74.588653000000079, 31.087584000000049],
            [74.588285000000042, 31.088699000000076],
            [74.58659700000004, 31.090622000000053],
            [74.586648000000082, 31.091249000000062],
            [74.587755000000072, 31.091969000000063],
            [74.589182000000051, 31.092464000000064],
            [74.590715000000046, 31.092509000000064],
            [74.591017000000079, 31.091490000000078],
            [74.590839000000074, 31.089424000000065],
            [74.591303000000039, 31.08859700000005],
            [74.591939000000082, 31.088853000000029],
            [74.592374000000063, 31.088968000000079],
            [74.592941000000053, 31.088996000000066],
            [74.593775000000051, 31.088882000000069],
            [74.594225231000053, 31.088688681000065],
            [74.594306000000074, 31.088654000000076],
            [74.594873000000064, 31.088626000000033],
            [74.595764000000031, 31.088652000000025],
            [74.596580000000074, 31.089012000000025],
            [74.597808000000043, 31.087726000000032],
            [74.599798000000078, 31.087982000000068],
            [74.600721000000078, 31.087953000000027],
            [74.600581000000034, 31.089240000000075],
            [74.600239000000045, 31.090274000000079],
            [74.599320000000034, 31.093097000000057],
            [74.600616000000059, 31.09318200000007],
            [74.602280000000064, 31.093125000000043],
            [74.602978000000064, 31.093238000000042],
            [74.604110000000048, 31.093068000000073],
            [74.604142000000081, 31.093863000000056],
            [74.604140000000029, 31.094974000000036],
            [74.604474000000039, 31.095060000000046],
            [74.60490800000008, 31.094974000000036],
            [74.605777000000046, 31.094489000000067],
            [74.606077000000084, 31.094517000000053],
            [74.606279000000029, 31.094831000000056],
            [74.607216000000051, 31.094802000000072],
            [74.608055000000036, 31.095946000000026],
            [74.608492000000069, 31.096778000000029],
            [74.609067000000039, 31.097353000000055],
            [74.609574000000066, 31.097671000000048],
            [74.60940800000003, 31.097902000000033],
            [74.609578000000056, 31.098104000000035],
            [74.609780000000058, 31.098133000000075],
            [74.61005300000005, 31.098566000000062],
            [74.61056300000007, 31.09911100000005],
            [74.609713000000056, 31.100046000000077],
            [74.610047000000066, 31.100541000000078],
            [74.610254000000054, 31.101301000000035],
            [74.610434000000055, 31.102771000000075],
            [74.61033400000008, 31.104599000000064],
            [74.61014700000004, 31.105764000000079],
            [74.60973000000007, 31.107043000000033],
            [74.609548000000075, 31.108888000000036],
            [74.610051000000055, 31.111702000000037],
            [74.608516000000066, 31.113361000000054],
            [74.606934000000081, 31.114118000000076],
            [74.604860000000031, 31.114452000000028],
            [74.604343000000028, 31.11626300000006],
            [74.603729000000044, 31.11785100000003],
            [74.602809000000036, 31.118944000000056],
            [74.599878000000047, 31.122671000000025],
            [74.596909000000039, 31.126027000000079],
            [74.59646500000008, 31.127383000000066],
            [74.596591000000046, 31.128400000000056],
            [74.596921000000066, 31.129420000000039],
            [74.598928000000058, 31.128895000000057],
            [74.599989000000051, 31.130039000000068],
            [74.60027400000007, 31.130497000000048],
            [74.600500000000068, 31.131345000000067],
            [74.600908000000061, 31.132116000000053],
            [74.601747000000046, 31.132905000000051],
            [74.602494000000036, 31.13341500000007],
            [74.603080000000034, 31.13375700000006],
            [74.60337700000008, 31.13370100000003],
            [74.60408300000006, 31.132957000000033],
            [74.604722000000038, 31.132694000000072],
            [74.60505100000006, 31.132707000000039],
            [74.605657000000065, 31.132791000000054],
            [74.606176000000062, 31.132990000000063],
            [74.607806000000039, 31.133460000000071],
            [74.608333000000073, 31.133629000000042],
            [74.609483000000068, 31.133854000000042],
            [74.610058000000038, 31.133755000000065],
            [74.611964000000057, 31.133002000000033],
            [74.612265000000036, 31.132513000000074],
            [74.613023000000055, 31.132058000000029],
            [74.614311000000043, 31.131620000000055],
            [74.614856000000032, 31.131510000000048],
            [74.615460000000041, 31.131726000000072],
            [74.616064000000051, 31.132002000000057],
            [74.616584000000046, 31.131943000000035],
            [74.61719400000004, 31.131727000000069],
            [74.618007000000034, 31.131374000000051],
            [74.618615000000034, 31.131412000000068],
            [74.618930000000034, 31.131469000000038],
            [74.619446000000039, 31.131724000000077],
            [74.619987000000037, 31.132041000000072],
            [74.620551000000034, 31.132301000000041],
            [74.621004000000084, 31.132443000000023],
            [74.62141100000008, 31.132347000000038],
            [74.622003000000063, 31.132999000000041],
            [74.624358000000029, 31.132262000000026],
            [74.626777000000061, 31.131078000000059],
            [74.629604000000029, 31.131944000000033],
            [74.630975000000035, 31.132764000000066],
            [74.632360000000062, 31.131408000000079],
            [74.631809000000032, 31.130678000000046],
            [74.631432000000075, 31.129869000000042],
            [74.631318000000078, 31.129467000000034],
            [74.631090000000029, 31.128750000000025],
            [74.630960000000073, 31.128311000000053],
            [74.631186000000071, 31.127286000000026],
            [74.631846000000053, 31.126619000000062],
            [74.632331000000079, 31.125230000000045],
            [74.632745000000057, 31.123719000000051],
            [74.632480000000044, 31.122880000000066],
            [74.632233000000042, 31.121500000000026],
            [74.633983000000057, 31.119795000000067],
            [74.636398000000042, 31.118224000000055],
            [74.638365000000078, 31.118524000000036],
            [74.640223000000049, 31.119654000000025],
            [74.640500000000031, 31.119821000000059],
            [74.640826000000061, 31.120100000000036],
            [74.641235000000052, 31.120505000000037],
            [74.641563000000076, 31.120925000000057],
            [74.642040000000065, 31.121741000000043],
            [74.64255200000008, 31.122390000000053],
            [74.643249000000083, 31.122945000000072],
            [74.643664000000058, 31.123145000000079],
            [74.644197000000077, 31.123418000000072],
            [74.644899000000066, 31.123835000000042],
            [74.645473000000038, 31.124382000000026],
            [74.645912000000067, 31.124900000000025],
            [74.646371000000045, 31.126080000000059],
            [74.646794000000057, 31.126958000000059],
            [74.647622000000069, 31.127362000000062],
            [74.648548000000062, 31.128090000000043],
            [74.650401000000045, 31.128876000000048],
            [74.651338000000067, 31.129271000000074],
            [74.652885000000083, 31.129426000000024],
            [74.654094589000067, 31.130294860000049],
            [74.655057265000039, 31.130986360000065],
            [74.656841000000043, 31.132514000000072],
            [74.659122000000082, 31.134029000000055],
            [74.661081000000081, 31.131144000000063],
            [74.66439500000007, 31.126495000000034],
            [74.66643700000003, 31.124449000000027],
            [74.671162000000038, 31.123772000000031],
            [74.673267000000067, 31.123685000000023],
            [74.676140000000032, 31.124741000000029],
            [74.679125000000056, 31.125882000000047],
            [74.683079000000077, 31.127381000000071],
            [74.687707000000046, 31.12796000000003],
            [74.689306000000045, 31.127973000000054],
            [74.690122000000031, 31.123639000000026],
            [74.689891000000046, 31.117411000000061],
            [74.693663000000072, 31.116765000000044],
            [74.696546829000056, 31.113995698000053],
            [74.697994000000051, 31.112606000000028],
            [74.698701000000028, 31.108695000000068],
            [74.698226000000034, 31.107605000000035],
            [74.69822410900008, 31.106165856000075],
            [74.698221000000046, 31.103800000000035],
            [74.697376000000077, 31.102022000000034],
            [74.701339000000075, 31.09716700000007],
            [74.702441000000078, 31.092271000000039],
            [74.697468000000072, 31.084992000000057],
            [74.695256670000049, 31.083846575000052],
            [74.690794000000039, 31.081535000000031],
            [74.696320000000071, 31.074139000000059],
            [74.691698777000056, 31.070335957000054],
            [74.671401894000041, 31.05363260200005],
            [74.669447726000044, 31.053123202000052],
            [74.666168931000072, 31.05197144400006],
            [74.663965000000076, 31.051348000000075],
            [74.662056624000058, 31.051010289000033],
            [74.658105000000035, 31.050311000000079],
            [74.657753000000071, 31.049940000000049],
            [74.650248000000033, 31.048981000000026],
            [74.646064000000081, 31.049722000000031],
            [74.638213078000035, 31.048132782000039],
            [74.61785100000003, 31.044011000000069],
            [74.615668000000085, 31.044883000000027],
            [74.613106000000073, 31.044842000000074],
            [74.60415495500007, 31.041059497000049],
            [74.60209711300007, 31.040189901000076],
            [74.596806000000072, 31.03795400000007],
            [74.596171000000083, 31.039487000000065],
            [74.595774000000063, 31.04242000000005],
            [74.593914000000041, 31.044108000000051],
            [74.590529906000029, 31.049576302000048],
            [74.590524562000041, 31.049584937000077],
            [74.590572745000031, 31.049586733000069],
            [74.590261295000062, 31.050090000000068],
            [74.583511867000084, 31.05294697800008],
            [74.583063452000033, 31.053139562000069],
            [74.574497000000065, 31.056794000000025],
            [74.572556378000058, 31.055703686000072],
            [74.57223300000004, 31.055522000000053],
            [74.57160542500003, 31.054665774000057],
            [74.571600029000081, 31.054658412000038],
            [74.570427000000052, 31.053058000000078],
            [74.568303000000071, 31.054773000000068],
            [74.564966000000084, 31.051326000000074],
            [74.561844000000065, 31.048966000000064],
            [74.560899000000063, 31.043845000000033],
            [74.559269000000029, 31.039255000000026],
            [74.558162527000036, 31.038596497000071],
            [74.558160790000045, 31.038595463000036],
            [74.558170020000034, 31.038596497000071],
            [74.558127135000063, 31.038570975000027],
            [74.550972315000081, 31.034312876000058],
            [74.550884666000059, 31.034483289000036],
            [74.550964557000043, 31.034313811000061],
            [74.550609457000064, 31.034101385000042],
            [74.547959000000048, 31.032524000000024],
            [74.539639000000079, 31.009016000000031],
            [74.540275000000065, 31.007254000000046],
            [74.541126000000077, 31.006204000000025],
            [74.54133900000005, 31.002800000000036],
            [74.543567000000053, 30.998364000000038],
            [74.543063000000075, 30.996878000000038],
            [74.543927000000053, 30.994418000000053],
            [74.543799000000035, 30.99112400000007],
            [74.531614000000047, 30.986459000000025],
            [74.530999844000064, 30.986185737000028],
            [74.527582000000052, 30.984665000000064],
            [74.52399900000006, 30.983542000000057],
            [74.51616000000007, 30.979523000000029],
            [74.512868986000058, 30.974050778000048],
            [74.512501855000039, 30.973440320000066],
            [74.511018000000036, 30.970973000000072],
            [74.508552000000066, 30.967247000000043],
            [74.506405000000029, 30.966300000000047],
            [74.502635000000055, 30.964552000000026],
            [74.498240000000067, 30.964033000000029],
            [74.492047000000071, 30.974552000000074],
            [74.48993100000007, 30.972420000000056],
            [74.486278000000084, 30.972560000000044],
            [74.486270197000067, 30.972556343000065],
            [74.486240357000042, 30.972542360000034],
            [74.481579000000067, 30.970358000000033],
            [74.479817000000082, 30.974016000000063],
            [74.475397000000044, 30.97226100000006],
            [74.472221000000047, 30.97081600000007],
            [74.471869000000083, 30.970360000000028],
            [74.470723000000078, 30.970284000000049],
            [74.467820000000074, 30.968765000000076],
            [74.466680000000054, 30.968007000000057],
            [74.465889000000061, 30.967855000000043],
            [74.464218000000074, 30.967702000000031],
            [74.463618190000034, 30.967471655000054],
            [74.461062000000084, 30.966490000000078],
            [74.454677000000061, 30.96414800000008],
            [74.466221000000075, 30.956759000000034],
            [74.459670310000035, 30.956638152000039],
            [74.455904472000043, 30.956474614000058],
            [74.453779330000032, 30.956145984000045],
            [74.452464809000048, 30.95592689700004],
            [74.451532271000076, 30.955743976000065],
            [74.454770243000041, 30.951433000000065],
            [74.442201243000056, 30.95154000000008],
            [74.441743243000076, 30.944917000000032],
            [74.440174243000058, 30.944182000000069],
            [74.439171243000033, 30.941740000000038],
            [74.437164384000084, 30.942001984000058],
            [74.437155738000058, 30.942003113000055],
            [74.433625243000051, 30.94246400000003],
            [74.433618523000064, 30.942463671000041],
            [74.42850324300008, 30.942213000000038],
            [74.425421623000034, 30.941801784000063],
            [74.425422672000082, 30.941803157000038],
            [74.425412381000058, 30.941801784000063],
            [74.425295779000066, 30.941786224000055],
            [74.424550431000057, 30.941686764000053],
            [74.424551853000082, 30.941738886000053],
            [74.424550431000057, 30.941738696000073],
            [74.41925400000008, 30.941031932000044],
            [74.413522000000057, 30.929505932000041],
            [74.417616572000043, 30.92956029100003],
            [74.423992000000055, 30.929644932000031],
            [74.428657000000044, 30.931219932000033],
            [74.423737000000074, 30.922789932000057],
            [74.423604000000068, 30.920753932000025],
            [74.414772883000069, 30.922026596000023],
            [74.411734270000068, 30.922952248000058],
            [74.410187000000064, 30.923483932000067],
            [74.410260183000048, 30.923006125000029],
            [74.410252184000058, 30.923006417000067],
            [74.410792000000072, 30.919482000000073],
            [74.418822000000034, 30.919257000000073],
            [74.424326000000065, 30.916164000000038],
            [74.423351000000082, 30.913777000000039],
            [74.424059000000057, 30.912474000000032],
            [74.423790000000054, 30.909505000000024],
            [74.422537000000034, 30.907726000000025],
            [74.408665764000034, 30.909608229000071],
            [74.401546386000064, 30.909462097000073],
            [74.401493000000073, 30.909461001000068],
            [74.397020000000055, 30.909347000000025],
            [74.396267030000047, 30.908608087000061],
            [74.395615297000063, 30.908501719000071],
            [74.395597251000083, 30.908492477000038],
            [74.387252954000076, 30.899770757000056],
            [74.385801954000044, 30.89876375700004],
            [74.383186075000083, 30.895379207000076],
            [74.380339485000036, 30.895495757000049],
            [74.372848485000077, 30.895359757000051],
            [74.369535485000029, 30.894977757000049],
            [74.36593548500008, 30.893220757000051],
            [74.366223485000035, 30.892096757000047],
            [74.366657485000076, 30.888979757000072],
            [74.366802485000051, 30.885622757000078],
            [74.365943485000059, 30.883016757000064],
            [74.364371485000049, 30.88041475700004],
            [74.362229485000057, 30.878433757000039],
            [74.36166148500007, 30.876829757000053],
            [74.361032485000067, 30.873770757000045],
            [74.365761411000051, 30.869976480000048],
            [74.365751954000075, 30.869954757000073],
            [74.370846330000063, 30.867040109000072],
            [74.373991485000033, 30.865015757000037],
            [74.376859642000056, 30.86350311700005],
            [74.381983954000077, 30.860474757000077],
            [74.381976362000046, 30.860453850000056],
            [74.378810485000031, 30.858953757000052],
            [74.376191485000049, 30.857939757000054],
            [74.375778485000069, 30.859199757000056],
            [74.373906485000077, 30.859700757000041],
            [74.37243548500004, 30.85805775700004],
            [74.371281485000054, 30.858181757000068],
            [74.369393485000046, 30.857547757000077],
            [74.36476848500007, 30.857411757000079],
            [74.365548857000078, 30.856356284000071],
            [74.365571443000078, 30.856325736000031],
            [74.365552587000082, 30.856335480000041],
            [74.365552957000034, 30.856334979000053],
            [74.367164000000059, 30.854156000000046],
            [74.368428000000051, 30.851649000000066],
            [74.366808000000049, 30.848644000000036],
            [74.363193000000081, 30.847136000000035],
            [74.362336000000084, 30.847635000000025],
            [74.361191000000076, 30.848133000000075],
            [74.359035000000063, 30.848502000000053],
            [74.35792723600008, 30.848104017000026],
            [74.356580000000065, 30.847620000000063],
            [74.356296000000043, 30.848119000000054],
            [74.355147000000045, 30.848492000000078],
            [74.354868000000067, 30.849616000000026],
            [74.354874000000052, 30.850492000000031],
            [74.354028000000085, 30.85299800100006],
            [74.347838000000081, 30.854996000000028],
            [74.343469975000062, 30.854056554000067],
            [74.341887072000077, 30.853718912000033],
            [74.341889574000049, 30.85371665100007],
            [74.340766000000031, 30.85347500000006],
            [74.338602000000037, 30.852592000000072],
            [74.338890000000049, 30.85121300000003],
            [74.339754000000084, 30.849963000000059],
            [74.340185000000076, 30.848963000000026],
            [74.339321000000041, 30.849086000000057],
            [74.336010000000044, 30.850206000000071],
            [74.334136000000058, 30.850953000000061],
            [74.333779442000036, 30.850921141000072],
            [74.329816000000051, 30.850567000000069],
            [74.32547805400003, 30.849478636000072],
            [74.324782000000084, 30.849304000000075],
            [74.32292300000006, 30.847550000000069],
            [74.322221675000037, 30.847286654000072],
            [74.321919000000037, 30.847173000000055],
            [74.320489000000066, 30.846047000000056],
            [74.320101000000079, 30.844194000000073],
            [74.319931000000054, 30.842149000000063],
            [74.319994819000044, 30.842109682000057],
            [74.321374000000048, 30.841260000000034],
            [74.32106600000003, 30.839858000000049],
            [74.321773000000064, 30.838332000000037],
            [74.320880000000045, 30.836550000000045],
            [74.31998800000008, 30.834771000000046],
            [74.319091000000071, 30.832363000000043],
            [74.319349000000045, 30.829584000000068],
            [74.320196000000067, 30.827821000000029],
            [74.319311000000084, 30.826306000000045],
            [74.317560000000071, 30.824790000000064],
            [74.315663000000029, 30.822900000000061],
            [74.311015000000054, 30.819373000000041],
            [74.310848338000085, 30.819193017000032],
            [74.309276000000068, 30.817495000000065],
            [74.304780000000051, 30.810757000000024],
            [74.300932482000064, 30.806861538000078],
            [74.298962824000057, 30.804779393000047],
            [74.293783999000084, 30.799410000000023],
            [74.299402440000051, 30.791847095000037],
            [74.29932991000004, 30.791823948000058],
            [74.29944002700006, 30.791796500000032],
            [74.301181000000042, 30.789453000000037],
            [74.306550000000072, 30.789212000000077],
            [74.306100000000072, 30.786921000000063],
            [74.30419500000005, 30.782858000000033],
            [74.302300000000059, 30.779693000000066],
            [74.300131000000079, 30.779435000000035],
            [74.296803000000068, 30.777031000000079],
            [74.294205000000034, 30.776898000000074],
            [74.292544434000035, 30.776272822000067],
            [74.290165000000059, 30.775377000000049],
            [74.288867000000039, 30.775123000000065],
            [74.286270000000059, 30.775746000000026],
            [74.283087913000031, 30.774181924000061],
            [74.28267100000005, 30.773977000000059],
            [74.281228000000056, 30.774225000000058],
            [74.278777000000048, 30.774219000000073],
            [74.276039000000083, 30.774087000000065],
            [74.273735000000045, 30.773703000000069],
            [74.267426965000084, 30.776662080000051],
            [74.267069000000049, 30.776830000000075],
            [74.263858122000045, 30.774324001000025],
            [74.259644000000037, 30.77103500000004],
            [74.26037800000006, 30.770159000000035],
            [74.261695000000032, 30.768659000000071],
            [74.26344400000005, 30.767037000000073],
            [74.265630000000044, 30.764670000000024],
            [74.269135000000063, 30.759085000000027],
            [74.273761000000036, 30.753779000000065],
            [74.274219841000047, 30.746764577000079],
            [74.274412000000041, 30.743827000000067],
            [74.281542000000059, 30.740181000000064],
            [74.28652500000004, 30.735446000000024],
            [74.281600000000083, 30.730703000000062],
            [74.274760000000072, 30.736842000000024],
            [74.274072000000046, 30.734556000000055],
            [74.272735000000068, 30.733640000000037],
            [74.271530000000041, 30.732384000000025],
            [74.268886000000066, 30.734086000000048],
            [74.268861000000072, 30.730101000000047],
            [74.267923000000053, 30.728509000000031],
            [74.267780000000073, 30.727035000000058],
            [74.266713000000038, 30.725900000000024],
            [74.265250000000037, 30.72453900000005],
            [74.26353000000006, 30.724081000000069],
            [74.260220000000061, 30.722830000000044],
            [74.254801000000043, 30.723717000000079],
            [74.252153000000078, 30.724954000000025],
            [74.248312000000055, 30.725961000000041],
            [74.245566000000053, 30.72573200000005],
            [74.245003283000074, 30.723061795000035],
            [74.244993000000079, 30.723013000000037],
            [74.232024000000081, 30.724387000000036],
            [74.230664000000047, 30.722496000000035],
            [74.229716000000053, 30.721905000000049],
            [74.228765000000067, 30.720254000000068],
            [74.228222000000073, 30.71919500000007],
            [74.227277000000072, 30.719075000000032],
            [74.226735000000076, 30.718016000000034],
            [74.226596000000029, 30.71637400000003],
            [74.227394000000061, 30.712750000000028],
            [74.225108000000034, 30.712743000000046],
            [74.22446142900003, 30.711844027000041],
            [74.224459673000069, 30.711841586000048],
            [74.223758000000032, 30.710866000000067],
            [74.223083000000031, 30.708765000000028],
            [74.223350000000039, 30.707254000000034],
            [74.222840894000058, 30.705165531000034],
            [74.222812000000033, 30.705047000000036],
            [74.221472000000063, 30.701338000000078],
            [74.221472000000063, 30.698568000000023],
            [74.219472000000053, 30.695569000000035],
            [74.219243014000028, 30.694628950000038],
            [74.218940000000032, 30.693385000000035],
            [74.216412000000048, 30.691657000000077],
            [74.216147000000035, 30.690854000000058],
            [74.214288000000067, 30.689704000000063],
            [74.211769000000061, 30.687754000000041],
            [74.208612000000073, 30.682165000000055],
            [74.20891608900007, 30.681227954000065],
            [74.209017000000074, 30.680917000000079],
            [74.206004000000064, 30.676267000000053],
            [74.203239000000053, 30.674903000000029],
            [74.201008000000058, 30.673317000000054],
            [74.199102000000039, 30.671757000000071],
            [74.191298000000074, 30.664619000000073],
            [74.187635000000057, 30.663307000000032],
            [74.183434000000034, 30.66175700000008],
            [74.180862000000047, 30.660804000000041],
            [74.179908000000069, 30.659034000000077],
            [74.178552000000082, 30.657501000000025],
            [74.177465000000041, 30.655151000000046],
            [74.175444000000084, 30.653859000000068],
            [74.173964000000069, 30.653155000000027],
            [74.172347000000059, 30.652092000000039],
            [74.17140500000005, 30.650572000000068],
            [74.170599000000038, 30.650104000000056],
            [74.169257000000073, 30.648818000000063],
            [74.166536733000044, 30.64914729000003],
            [74.165133515000036, 30.650652942000079],
            [74.165133481000055, 30.650652978000039],
            [74.162772345000064, 30.653187200000048],
            [74.159956000000079, 30.656210000000044],
            [74.156091764000053, 30.660846983000056],
            [74.152215000000069, 30.665499000000068],
            [74.146409000000062, 30.668983000000026],
            [74.139829000000077, 30.676337000000046],
            [74.139308007000068, 30.678525441000033],
            [74.138061676000063, 30.683760676000077],
            [74.137894000000074, 30.684465000000046],
            [74.135811765000028, 30.687142049000045],
            [74.13580709200005, 30.687148057000059],
            [74.132475000000056, 30.691432000000077],
            [74.122412000000054, 30.693367000000023],
            [74.122412000000054, 30.693368000000078],
            [74.122412000000054, 30.693439865000073],
            [74.122412000000054, 30.699690402000044],
            [74.122412000000054, 30.703431000000023],
            [74.122077672000046, 30.704386346000035],
            [74.119922275000079, 30.710545419000027],
            [74.119773016000067, 30.710971928000049],
            [74.119772089000037, 30.710971793000056],
            [74.119702000000075, 30.711172000000033],
            [74.118928000000039, 30.719687000000079],
            [74.112348000000054, 30.723558000000025],
            [74.110700737000059, 30.724813106000056],
            [74.104220000000055, 30.729751000000078],
            [74.103339417000029, 30.730946019000044],
            [74.098801000000037, 30.737105000000042],
            [74.094217579000031, 30.741251958000078],
            [74.090673000000038, 30.744459000000063],
            [74.082158000000049, 30.749878000000024],
            [74.077283872000066, 30.753669144000071],
            [74.075191000000075, 30.755297000000041],
            [74.076869976000069, 30.757695536000028],
            [74.077183996000031, 30.758144138000034],
            [74.077900000000056, 30.759167000000048],
            [74.079836000000057, 30.766909000000055],
            [74.079066261000037, 30.77152495100006],
            [74.078287000000046, 30.776198000000079],
            [74.075965569000061, 30.784034328000075],
            [74.075191000000075, 30.786649000000068],
            [74.074034912000059, 30.788427474000059],
            [74.070159000000046, 30.794390000000078],
            [74.068826917000081, 30.794574985000054],
            [74.068761677000055, 30.794584045000079],
            [74.05622500000004, 30.796325000000024],
            [74.043452000000059, 30.79709900000006],
            [74.032227000000034, 30.79942200000005],
            [74.02719600000006, 30.80484000000007],
            [74.027042379000079, 30.804922296000029],
            [74.026848088000065, 30.805026379000026],
            [74.016358000000082, 30.810646000000077],
            [74.016358000000082, 30.816057599000032],
            [74.016358000000082, 30.816065000000037],
            [74.01751900000005, 30.821097000000066],
            [74.01751900000005, 30.821761957000035],
            [74.01751900000005, 30.826516000000026],
            [74.014809029000048, 30.83154694600006],
            [74.014809000000071, 30.831547000000057],
            [74.01480893400003, 30.831546941000056],
            [74.007843000000037, 30.831160000000068],
            [74.003198000000054, 30.829225000000065],
            [74.002531987000054, 30.828142513000046],
            [74.000102000000084, 30.824193000000037],
            [73.993909000000031, 30.823419000000058],
            [73.990812000000062, 30.81800000000004],
            [73.988877000000059, 30.821484000000055],
            [73.988877000000059, 30.823466798000027],
            [73.988877000000059, 30.826516000000026],
            [73.984232000000077, 30.829612000000054],
            [73.983561475000045, 30.830000237000036],
            [73.976878000000056, 30.833870000000047],
            [73.968750000000057, 30.833870000000047],
            [73.967956233000052, 30.833828231000041],
            [73.961396000000036, 30.833483000000058],
            [73.961395893000031, 30.833483047000072],
            [73.961395000000039, 30.833483000000058],
            [73.95520300000004, 30.83619200000004],
            [73.949397000000033, 30.838901000000078],
            [73.94746200000003, 30.846643000000029],
            [73.947083684000063, 30.85572356800003],
            [73.947075003000066, 30.855931931000043],
            [73.947075000000041, 30.855932000000053],
            [73.943204000000037, 30.856706000000031],
            [73.935757199000079, 30.860291523000058],
            [73.932753000000048, 30.861738000000059],
            [73.930252322000058, 30.861738000000059],
            [73.927722000000074, 30.861738000000059],
            [73.926602444000082, 30.858005184000035],
            [73.926561000000049, 30.857867000000056],
            [73.921142000000032, 30.85361000000006],
            [73.921141000000034, 30.85361000000006],
            [73.913014000000032, 30.85361000000006],
            [73.904885000000036, 30.853223000000071],
            [73.897918000000061, 30.852836000000025],
            [73.896634144000075, 30.853670399000066],
            [73.890225953000083, 30.857835185000056],
            [73.890177000000051, 30.857867000000056],
            [73.884599017000085, 30.857988240000054],
            [73.881272556000056, 30.858060542000032],
            [73.881182942000066, 30.858062490000066],
            [73.872372000000041, 30.858254000000045],
            [73.869630541000049, 30.858470588000046],
            [73.857664000000057, 30.859416000000067],
            [73.853793000000053, 30.867544000000066],
            [73.854180000000042, 30.883413000000075],
            [73.853878914000063, 30.883879744000069],
            [73.848373000000038, 30.892415000000028],
            [73.846439000000032, 30.895412000000078],
            [73.833279000000061, 30.912056000000064],
            [73.827443010000081, 30.922521161000077],
            [73.827443163000055, 30.922521748000065],
            [73.824144785000044, 30.928435909000029],
            [73.824071443000037, 30.928567410000028],
            [73.822055000000034, 30.932183000000066],
            [73.787994000000083, 30.924828000000048],
            [73.784943394000038, 30.925884072000031],
            [73.777930000000083, 30.928312000000062],
            [73.770482403000074, 30.934665102000054],
            [73.76905000000005, 30.935887000000037],
            [73.765109480000035, 30.93924749100006],
            [73.764770000000055, 30.93953700000003],
            [73.766706000000056, 30.950374000000068],
            [73.767867000000081, 30.963534000000038],
            [73.767867000000081, 30.964375884000049],
            [73.767867000000081, 30.964738304000036],
            [73.767867000000081, 30.967906087000074],
            [73.767867000000081, 30.975146000000052],
            [73.767867000000081, 30.979508862000046],
            [73.767867000000081, 30.979617153000049],
            [73.767867000000081, 30.980161616000032],
            [73.767867000000081, 30.980510836000064],
            [73.767867000000081, 30.986758000000066],
            [73.76568995000008, 30.989269914000033],
            [73.762835000000052, 30.992564000000073],
            [73.760059522000063, 30.99247728000006],
            [73.75044900000006, 30.992177000000027],
            [73.742477377000057, 30.997643037000046],
            [73.736902000000043, 31.00146600000005],
            [73.731702536000057, 31.009141735000071],
            [73.728774000000044, 31.013465000000053],
            [73.728326528000082, 31.015422349000062],
            [73.727259672000059, 31.020089024000072],
            [73.725677000000076, 31.02701200000007],
            [73.725676964000058, 31.02701204300007],
            [73.722098927000047, 31.031305564000036],
            [73.719871000000069, 31.033979000000045],
            [73.715614000000073, 31.041720000000055],
            [73.715430187000038, 31.041777868000054],
            [73.710872038000048, 31.043212853000057],
            [73.694713000000036, 31.04830000000004],
            [73.692348203000051, 31.049718829000028],
            [73.689746011000068, 31.051280091000024],
            [73.685036000000082, 31.054106000000047],
            [73.676650991000031, 31.053795474000026],
            [73.674586000000033, 31.053719000000058],
            [73.668780000000083, 31.059138000000075],
            [73.668691201000058, 31.059359954000058],
            [73.665683000000058, 31.066879000000029],
            [73.668780000000083, 31.070749000000035],
            [73.668780000000083, 31.070750000000032],
            [73.668780000000083, 31.076168000000052],
            [73.667465569000058, 31.07863218600005],
            [73.665683000000058, 31.081974000000059],
            [73.671489000000065, 31.081974000000059],
            [73.673424000000068, 31.087393000000077],
            [73.676908000000083, 31.088167000000055],
            [73.681553000000065, 31.088554000000045],
            [73.681569649000039, 31.088820432000034],
            [73.681940000000054, 31.094747000000041],
            [73.680391000000043, 31.099392000000023],
            [73.679746566000063, 31.101601726000069],
            [73.677682000000061, 31.108681000000047],
            [73.672007822000069, 31.117192726000042],
            [73.671489000000065, 31.117971000000068],
            [73.671292593000032, 31.119542509000041],
            [73.67107026900004, 31.121321388000069],
            [73.67071500000003, 31.124164000000064],
            [73.664844199000072, 31.129055632000075],
            [73.663748000000055, 31.129969000000074],
            [73.663007123000057, 31.132099500000038],
            [73.660652000000084, 31.138872000000049],
            [73.660612093000054, 31.139121406000072],
            [73.660612000000071, 31.139122000000043],
            [73.660557431000029, 31.139107098000068],
            [73.660530390000076, 31.139174320000052],
            [73.665657223000039, 31.140603110000029],
            [73.670257223000078, 31.141803110000069],
            [73.676093492000064, 31.143872052000063],
            [73.676100406000046, 31.143851898000037],
            [73.681148133000079, 31.145663909000064],
            [73.689096000000063, 31.148517000000027],
            [73.691530073000081, 31.151194404000023],
            [73.694029958000044, 31.153944201000058],
            [73.69471569600006, 31.154698491000033],
            [73.698769000000084, 31.15915700000005],
            [73.699868210000034, 31.16135564800004],
            [73.700349361000065, 31.162318049000078],
            [73.703605000000039, 31.168830000000071],
            [73.703625435000049, 31.168839535000075],
            [73.70365722300005, 31.168903110000031],
            [73.709571759000085, 31.171613939000054],
            [73.710846076000053, 31.172208503000036],
            [73.710847622000074, 31.172209174000045],
            [73.710860228000058, 31.17221464000005],
            [73.710862501000065, 31.172211041000025],
            [73.711999960000071, 31.170410195000045],
            [73.716657223000084, 31.163103110000065],
            [73.719865329000072, 31.163400769000077],
            [73.719864624000081, 31.163346294000064],
            [73.726337000000058, 31.163994000000059],
            [73.726355995000063, 31.164002996000079],
            [73.726457753000034, 31.164051190000066],
            [73.735547626000084, 31.168398520000039],
            [73.736654116000068, 31.17108573400003],
            [73.737331405000077, 31.17273059300004],
            [73.737782032000041, 31.173824982000042],
            [73.743020279000064, 31.186815362000061],
            [73.744430464000061, 31.19076423000007],
            [73.744715000000042, 31.191561000000036],
            [73.745532069000035, 31.192080960000055],
            [73.750105064000081, 31.194991087000062],
            [73.753994065000029, 31.19746593900004],
            [73.755355000000066, 31.19833200000005],
            [73.75671195700005, 31.198956216000056],
            [73.766667855000037, 31.203536044000032],
            [73.772433939000052, 31.206188510000061],
            [73.772450768000056, 31.206196251000051],
            [73.779537000000062, 31.209456000000046],
            [73.79259600000006, 31.211391000000049],
            [73.794385954000063, 31.211902397000074],
            [73.79582816900006, 31.212314443000025],
            [73.806138000000033, 31.215260000000058],
            [73.817260941000029, 31.222030964000055],
            [73.817261379000058, 31.222030622000034],
            [73.821614000000068, 31.217678000000035],
            [73.821614946000068, 31.217678054000032],
            [73.827760784000077, 31.218029131000037],
            [73.827757223000049, 31.218103110000072],
            [73.836944023000058, 31.218613488000074],
            [73.838557223000066, 31.218703110000035],
            [73.844950908000044, 31.219874472000072],
            [73.851657223000075, 31.221103110000058],
            [73.863657223000075, 31.221603110000046],
            [73.874649823000084, 31.225078712000027],
            [73.874649937000072, 31.225078748000044],
            [73.876369564000072, 31.225622453000028],
            [73.876446245000068, 31.225647102000039],
            [73.877233000000047, 31.225900000000024],
            [73.877234000000044, 31.225900000000024],
            [73.877247387000068, 31.225900000000024],
            [73.877409060000048, 31.225900000000024],
            [73.877481812000042, 31.225723019000043],
            [73.877527456000053, 31.225660056000038],
            [73.877427433000037, 31.225900000000024],
            [73.891259000000048, 31.225900000000024],
            [73.89737916200005, 31.225900000000024],
            [73.902382000000046, 31.225900000000024],
            [73.906272756000078, 31.230477388000054],
            [73.906491419000076, 31.230734640000037],
            [73.910413406000032, 31.235318167000059],
            [73.910396210000044, 31.235328539000079],
            [73.910604000000035, 31.235573000000045],
            [73.911239906000048, 31.236470709000059],
            [73.918826000000081, 31.247180000000071],
            [73.919310000000053, 31.257820000000038],
            [73.91261733500005, 31.260372119000067],
            [73.912625061000028, 31.260377172000062],
            [73.909157223000079, 31.261703110000042],
            [73.90915411800006, 31.261705756000026],
            [73.909637000000032, 31.268944000000033],
            [73.909653774000049, 31.268952752000075],
            [73.920761000000084, 31.274748000000045],
            [73.920761504000041, 31.274748186000068],
            [73.920762000000082, 31.274748000000045],
            [73.920762377000074, 31.274748507000027],
            [73.926950600000055, 31.277028095000048],
            [73.929950000000076, 31.278133000000025],
            [73.929957259000048, 31.278148325000075],
            [73.92997017700003, 31.278175598000075],
            [73.929983192000066, 31.278203074000032],
            [73.930265336000048, 31.278798734000077],
            [73.931232874000045, 31.280841389000045],
            [73.934303000000057, 31.287323000000072],
            [73.941285736000054, 31.290179097000077],
            [73.944957223000074, 31.291703110000071],
            [73.95565068500008, 31.291703110000071],
            [73.955666170000029, 31.291675000000055],
            [73.959936000000084, 31.291675000000055],
            [73.959937000000082, 31.291675000000055],
            [73.971316151000053, 31.291675000000055],
            [73.971333316000084, 31.291632312000047],
            [73.971544000000051, 31.291675000000055],
            [73.971543846000031, 31.291675376000057],
            [73.977055218000032, 31.294124664000037],
            [73.977179063000051, 31.293944241000077],
            [73.977097287000049, 31.294143359000032],
            [73.980249000000072, 31.295544000000064],
            [73.985822948000077, 31.299724667000078],
            [73.988727348000054, 31.301903074000052],
            [73.992026477000081, 31.304377542000054],
            [73.993791000000044, 31.305701000000056],
            [73.994864492000033, 31.30566519000007],
            [74.008300000000077, 31.305217000000027],
            [74.008323663000056, 31.30520425900005],
            [74.009841803000029, 31.304386874000045],
            [74.010006513000064, 31.304298192000033],
            [74.011054614000045, 31.303733881000028],
            [74.011463241000058, 31.303549907000047],
            [74.011978623000061, 31.303317869000068],
            [74.01233656900007, 31.303085071000055],
            [74.012706442000081, 31.30284451600005],
            [74.014454993000072, 31.301903074000052],
            [74.014587000000063, 31.301832000000047],
            [74.014587332000076, 31.301832360000049],
            [74.014652613000067, 31.301903074000052],
            [74.020383024000068, 31.308110360000057],
            [74.020391000000075, 31.308119000000033],
            [74.022978528000067, 31.308843619000072],
            [74.027135528000031, 31.31000775800004],
            [74.032457223000051, 31.311503110000046],
            [74.03276076800006, 31.31177062900008],
            [74.032800166000072, 31.311748612000031],
            [74.032800604000045, 31.311748926000064],
            [74.041479773000049, 31.30689878700008],
            [74.041479837000054, 31.306898751000062],
            [74.041485823000073, 31.306895406000024],
            [74.048784000000069, 31.302817000000061],
            [74.06090400000005, 31.291201000000058],
            [74.061678676000042, 31.290135857000053],
            [74.06740295700007, 31.282265236000057],
            [74.068985000000055, 31.28009000000003],
            [74.073087266000073, 31.275645878000034],
            [74.075045000000046, 31.273525000000063],
            [74.087671000000057, 31.277565000000038],
            [74.089179383000044, 31.290135857000053],
            [74.089186000000041, 31.29019100000005],
            [74.089268742000058, 31.290135857000053],
            [74.092217000000062, 31.288171000000034],
            [74.09221589100008, 31.288163245000078],
            [74.091206000000057, 31.281100000000038],
            [74.091225149000081, 31.281066507000048],
            [74.091222048000077, 31.281044821000023],
            [74.092690374000028, 31.278476522000062],
            [74.093160663000049, 31.277650478000055],
            [74.093173744000069, 31.277658153000061],
            [74.09322700000007, 31.277565000000038],
            [74.093255114000044, 31.277563520000058],
            [74.093300187000068, 31.277484682000079],
            [74.102895187000058, 31.276979682000047],
            [74.111968559000047, 31.274963599000046],
            [74.113261047000037, 31.273186268000075],
            [74.11332012400004, 31.273105030000067],
            [74.11650028400004, 31.268731916000036],
            [74.119993000000079, 31.263929000000076],
            [74.12553213800004, 31.270698922000065],
            [74.128011804000039, 31.273729564000064],
            [74.129084000000034, 31.275040000000047],
            [74.132114000000058, 31.283626000000027],
            [74.133629000000042, 31.295746000000065],
            [74.138322749000054, 31.296528206000062],
            [74.142720000000054, 31.297261000000049],
            [74.145750000000078, 31.292211000000066],
            [74.151810000000069, 31.291706000000033],
            [74.159018241000069, 31.291255541000055],
            [74.159018563000075, 31.291255521000039],
            [74.159891000000073, 31.291201000000058],
            [74.159908289000043, 31.291172704000076],
            [74.159908361000078, 31.291172587000062],
            [74.165446000000031, 31.282110000000046],
            [74.172012000000052, 31.285646000000042],
            [74.173279835000073, 31.285938577000024],
            [74.176474541000061, 31.286639832000048],
            [74.176680379000061, 31.286687333000032],
            [74.178732937000063, 31.287161000000026],
            [74.188257540000052, 31.293175912000038],
            [74.195748000000037, 31.289181000000042],
            [74.199283000000037, 31.280595000000062],
            [74.199378519000049, 31.28049949900003],
            [74.204716219000034, 31.275162759000068],
            [74.20483900000005, 31.275040000000047],
            [74.209427378000044, 31.274122325000064],
            [74.209889000000032, 31.274030000000039],
            [74.21494000000007, 31.262414000000035],
            [74.225955158000033, 31.254402976000051],
            [74.226050000000043, 31.254334000000028],
            [74.230090000000075, 31.259384000000068],
            [74.238171000000079, 31.265949000000035],
            [74.241566836000061, 31.267835742000045],
            [74.241574575000072, 31.267840042000046],
            [74.247262000000035, 31.271000000000072],
            [74.25285520500006, 31.271329012000024],
            [74.255847000000074, 31.271505000000047],
            [74.26140300000003, 31.28160500000007],
            [74.267463000000078, 31.287666000000058],
            [74.270675501000085, 31.289914528000054],
            [74.277528008000047, 31.294710808000048],
            [74.277564000000041, 31.294736000000057],
            [74.284873103000052, 31.291310069000076],
            [74.293725000000052, 31.287161000000026],
            [74.296317410000029, 31.286642552000046],
            [74.296319141000083, 31.286636042000055],
            [74.297793912000031, 31.286347271000068],
            [74.300513521000084, 31.285803385000065],
            [74.30164132200008, 31.285401869000054],
            [74.312841224000067, 31.283266102000027],
            [74.313974702000053, 31.282842272000039],
            [74.314760368000066, 31.282953350000071],
            [74.316451000000029, 31.282615000000078],
            [74.327562000000057, 31.282615000000078],
            [74.334311881000076, 31.289365772000053],
            [74.334317739000085, 31.289371631000051],
            [74.335137000000032, 31.29019100000005],
            [74.340142000000071, 31.287533000000053],
            [74.351298000000043, 31.28160500000007],
            [74.357265882000036, 31.282600194000054],
            [74.360389000000055, 31.283121000000051],
            [74.373520000000042, 31.29019100000005],
            [74.381139363000045, 31.29476261800005],
            [74.383620000000064, 31.296251000000041],
            [74.39422600000006, 31.294736000000057],
            [74.399528369000052, 31.296645122000029],
            [74.402928968000083, 31.297869510000055],
            [74.403725205000057, 31.298156196000036],
            [74.405809627000053, 31.298906693000049],
            [74.406852000000072, 31.299282000000062],
            [74.407439087000057, 31.299282000000062],
            [74.41494257800008, 31.299282000000062],
            [74.41603219600006, 31.299282000000062],
            [74.416448000000059, 31.299282000000062],
            [74.430316589000029, 31.293553319000068],
            [74.439679000000069, 31.289685999000028],
            [74.439703981000036, 31.289728356000069],
            [74.439910621000081, 31.290689766000071],
            [74.440667482000038, 31.293969748000052],
            [74.442709000000036, 31.302817000000061],
            [74.442709000000036, 31.304122955000025],
            [74.442709000000036, 31.306802822000066],
            [74.442709000000036, 31.311402000000044],
            [74.449267616000043, 31.320302648000052],
            [74.449780000000032, 31.320998000000031],
            [74.450260725000078, 31.323161500000026],
            [74.451800000000048, 31.330089000000044],
            [74.455335000000048, 31.34675500000003],
            [74.456508064000047, 31.350665212000024],
            [74.456850000000031, 31.35180500000007],
            [74.458179507000068, 31.351931608000029],
            [74.467456000000084, 31.352815000000078],
            [74.470971575000078, 31.362483701000031],
            [74.471496000000059, 31.363926000000049],
            [74.481597000000079, 31.363926000000049],
            [74.488667000000078, 31.363421000000073],
            [74.493659402000048, 31.358429587000046],
            [74.493718000000058, 31.358371000000034],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "104",
      properties: { name: "Khushab", count: 1293 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.25232335700008, 32.698034861000053],
            [72.278603635000081, 32.690193433000047],
            [72.278659460000028, 32.690194655000028],
            [72.278873000000033, 32.690131000000065],
            [72.30206400000003, 32.690641000000028],
            [72.326904614000057, 32.693039423000073],
            [72.338791699000069, 32.694187150000062],
            [72.352765936000083, 32.676668298000038],
            [72.365008301000046, 32.668505608000032],
            [72.369505353000079, 32.663948623000067],
            [72.383636705000072, 32.649628940000071],
            [72.399202821000074, 32.635086860000058],
            [72.418348710000032, 32.613400908000074],
            [72.419046852000065, 32.613566668000033],
            [72.419064472000059, 32.613547354000048],
            [72.419941609000034, 32.613755496000067],
            [72.433568000000037, 32.616989000000046],
            [72.439939000000038, 32.633044000000041],
            [72.449369000000047, 32.638142000000073],
            [72.458176926000078, 32.645231368000054],
            [72.464660000000038, 32.651139000000057],
            [72.484029000000078, 32.665666000000044],
            [72.490447113000073, 32.66511558600007],
            [72.497026000000062, 32.665666000000044],
            [72.500850624000066, 32.666490775000057],
            [72.509555277000061, 32.668367921000026],
            [72.509660129000054, 32.668464280000023],
            [72.509760852000056, 32.668486012000074],
            [72.514077186000065, 32.672523582000053],
            [72.53266276800008, 32.689908840000044],
            [72.53841516600005, 32.691153109000027],
            [72.541940616000034, 32.691915036000069],
            [72.542090059000031, 32.691947334000076],
            [72.542095302000064, 32.691915036000069],
            [72.542105297000035, 32.691853470000069],
            [72.544081923000078, 32.67967765800006],
            [72.544408769000029, 32.677664267000068],
            [72.542807900000071, 32.674127104000036],
            [72.539329383000052, 32.666441235000036],
            [72.541619424000032, 32.66529612100004],
            [72.549534580000056, 32.661338221000051],
            [72.549760977000062, 32.661343012000032],
            [72.549781000000053, 32.66133300000007],
            [72.56151748800005, 32.661582858000031],
            [72.56220815000006, 32.659793763000039],
            [72.566635287000054, 32.648326402000066],
            [72.567152691000047, 32.64660002100004],
            [72.570993933000068, 32.633784066000032],
            [72.574690960000055, 32.628598907000026],
            [72.575100651000071, 32.628024315000062],
            [72.581727501000046, 32.618730148000054],
            [72.587642229000039, 32.607176262000053],
            [72.58786682200008, 32.60673754000004],
            [72.595062393000035, 32.602485092000052],
            [72.604711508000037, 32.596782737000069],
            [72.626135616000056, 32.591930743000034],
            [72.648317205000069, 32.591671243000064],
            [72.650903583000058, 32.591518562000033],
            [72.650903410000069, 32.591611270000044],
            [72.650993000000028, 32.59160600000007],
            [72.650993000000028, 32.577658819000078],
            [72.650993000000028, 32.556426000000044],
            [72.65053800000004, 32.540492000000029],
            [72.648309926000081, 32.530462276000037],
            [72.647351000000072, 32.524102000000028],
            [72.651565118000065, 32.50882495500008],
            [72.654635000000042, 32.497696000000076],
            [72.664196000000061, 32.47356700000006],
            [72.658278000000053, 32.462185000000034],
            [72.656509620000065, 32.454817612000056],
            [72.656449726000062, 32.454568084000073],
            [72.655546000000072, 32.450803000000064],
            [72.656413127000064, 32.437802762000047],
            [72.656423202000042, 32.437651714000026],
            [72.656457000000046, 32.437145000000044],
            [72.663225965000038, 32.414130122000074],
            [72.66328500000003, 32.41392600000006],
            [72.66328500000003, 32.395271000000037],
            [72.66328500000003, 32.393894000000046],
            [72.652359000000047, 32.398447000000033],
            [72.634148000000039, 32.39298400000007],
            [72.625554678000071, 32.390119559000027],
            [72.624189183000055, 32.389664394000079],
            [72.619124000000056, 32.387976000000037],
            [72.601037160000033, 32.379836872000055],
            [72.600913000000048, 32.379781000000037],
            [72.591352000000029, 32.369765000000029],
            [72.579048596000064, 32.371271764000028],
            [72.569044000000076, 32.372497000000067],
            [72.550833000000068, 32.361570000000029],
            [72.548095741000054, 32.359745160000045],
            [72.543119755000077, 32.35642783600008],
            [72.540298032000067, 32.354546688000028],
            [72.535809000000029, 32.351554000000078],
            [72.529599030000043, 32.34964332100003],
            [72.523972000000072, 32.347912000000065],
            [72.51946453100004, 32.33646890700004],
            [72.518054000000063, 32.332888000000025],
            [72.511568116000035, 32.330172986000036],
            [72.511568077000049, 32.330172970000035],
            [72.508401794000065, 32.32884755300006],
            [72.498962530000028, 32.324896244000058],
            [72.498477000000037, 32.324693000000025],
            [72.480266000000029, 32.313767000000041],
            [72.472191174000045, 32.301653430000044],
            [72.472124638000082, 32.301553615000046],
            [72.471161000000052, 32.30010800000008],
            [72.457958000000076, 32.289637000000027],
            [72.450737544000049, 32.287082159000079],
            [72.446817981000038, 32.285695286000077],
            [72.42923584600004, 32.279474135000044],
            [72.428365000000042, 32.279166000000032],
            [72.420998122000071, 32.275649890000068],
            [72.408333000000084, 32.26960500000007],
            [72.406111610000039, 32.267644942000061],
            [72.393261562000077, 32.256306615000028],
            [72.392854000000057, 32.255947000000049],
            [72.392807340000047, 32.255945804000078],
            [72.375098000000037, 32.255492000000061],
            [72.374863030000029, 32.255309235000027],
            [72.363822947000074, 32.246722005000038],
            [72.363822393000078, 32.246721574000048],
            [72.362806000000035, 32.245931000000041],
            [72.353899514000034, 32.237311658000067],
            [72.348693000000083, 32.232273000000077],
            [72.346708457000034, 32.224005040000065],
            [72.345961000000045, 32.220891000000051],
            [72.345961000000045, 32.205713603000049],
            [72.345961000000045, 32.204957000000036],
            [72.340920284000049, 32.193194836000032],
            [72.339132000000063, 32.18902200000008],
            [72.33805385800008, 32.186745995000024],
            [72.336614104000034, 32.183706612000037],
            [72.335284773000069, 32.180900337000026],
            [72.334026280000046, 32.178243604000045],
            [72.330937000000063, 32.171722000000045],
            [72.327324823000083, 32.159243831000026],
            [72.325929000000031, 32.154422000000068],
            [72.327166883000075, 32.150033051000037],
            [72.329867894000074, 32.140456544000074],
            [72.330937000000063, 32.136666000000048],
            [72.329394886000046, 32.127069663000043],
            [72.328890035000029, 32.123928053000043],
            [72.326620602000048, 32.111020964000033],
            [72.327145483000038, 32.095276468000066],
            [72.327295000000049, 32.087497000000042],
            [72.326824411000075, 32.084108178000065],
            [72.325649679000037, 32.075648663000038],
            [72.325019000000054, 32.07110700000004],
            [72.325627994000058, 32.065825928000038],
            [72.326153698000041, 32.061267128000054],
            [72.326384000000076, 32.059270000000026],
            [72.325961000000063, 32.055986000000075],
            [72.324606544000062, 32.045493323000073],
            [72.324328993000051, 32.045215484000039],
            [72.311659228000053, 32.032532584000023],
            [72.304093249000061, 32.025130983000054],
            [72.30409320800004, 32.025130950000062],
            [72.303700882000044, 32.024747147000028],
            [72.298613000000046, 32.022848000000067],
            [72.294971000000032, 32.01556400000004],
            [72.284495589000073, 32.010789226000043],
            [72.284495553000056, 32.010789210000041],
            [72.276760000000081, 32.006003000000078],
            [72.265833000000043, 32.006442494000055],
            [72.260065240000074, 32.000674735000075],
            [72.26005549000007, 32.000680472000056],
            [72.259850000000029, 32.000475000000051],
            [72.255817000000036, 31.996442000000059],
            [72.255075872000077, 31.992142741000066],
            [72.255075860000034, 31.992142674000036],
            [72.254725802000053, 31.990111997000042],
            [72.253541000000041, 31.983239000000026],
            [72.260825000000068, 31.97686600000003],
            [72.260831658000029, 31.976858290000052],
            [72.26290713800006, 31.974030321000043],
            [72.266461206000031, 31.970339729000045],
            [72.266469984000082, 31.970329565000043],
            [72.269475000000057, 31.966850000000079],
            [72.268565000000081, 31.953192000000058],
            [72.263233498000034, 31.943156515000055],
            [72.263233318000061, 31.943156177000049],
            [72.260825000000068, 31.938623000000064],
            [72.260798532000081, 31.938517125000033],
            [72.258605365000051, 31.929744053000036],
            [72.258604817000048, 31.929741862000071],
            [72.256447235000053, 31.921111137000025],
            [72.255362000000048, 31.916770000000042],
            [72.260070259000031, 31.907004538000024],
            [72.260070602000042, 31.907004621000056],
            [72.264469244000054, 31.897882080000045],
            [72.264468504000035, 31.897882080000045],
            [72.26765400000005, 31.891275000000064],
            [72.270341526000038, 31.883437202000039],
            [72.273118000000068, 31.875340000000051],
            [72.279442878000054, 31.869771169000046],
            [72.281874243000061, 31.867637453000043],
            [72.281878513000038, 31.867641315000071],
            [72.281880123000064, 31.867639892000057],
            [72.282533110000031, 31.867059245000064],
            [72.288085938000052, 31.862121582000043],
            [72.284044000000051, 31.848024000000066],
            [72.269931000000042, 31.852121000000068],
            [72.25763900000004, 31.85439800000006],
            [72.25763896400008, 31.854397821000077],
            [72.257638000000043, 31.85439800000006],
            [72.256273000000078, 31.847569000000078],
            [72.256728000000066, 31.834366000000045],
            [72.268565000000081, 31.82116300000007],
            [72.263516376000041, 31.811696484000038],
            [72.263516289000052, 31.811696320000067],
            [72.261281000000054, 31.807505000000049],
            [72.242615000000058, 31.804773000000068],
            [72.237607000000082, 31.790204000000074],
            [72.232627137000065, 31.782112491000078],
            [72.232624474000033, 31.782108165000068],
            [72.23204478200006, 31.781166254000027],
            [72.230322000000058, 31.77836700000006],
            [72.217046000000039, 31.756345000000067],
            [72.212917364000077, 31.749493002000065],
            [72.210290000000043, 31.745132000000069],
            [72.204594891000056, 31.740687960000059],
            [72.203306041000076, 31.739682147000053],
            [72.196501763000072, 31.734371560000056],
            [72.192723855000054, 31.731422584000029],
            [72.191624000000047, 31.730564000000072],
            [72.177915084000063, 31.733175479000067],
            [72.174684250000041, 31.733790697000074],
            [72.172503000000063, 31.734206000000029],
            [72.152926000000036, 31.742856000000074],
            [72.149756910000065, 31.742442575000041],
            [72.148918929000047, 31.742355793000058],
            [72.147208669000065, 31.742178678000073],
            [72.147208633000048, 31.742178674000058],
            [72.142455000000041, 31.741490000000056],
            [72.125610000000052, 31.715085000000045],
            [72.111496000000045, 31.698239000000058],
            [72.111449582000034, 31.698179107000044],
            [72.098632017000057, 31.681989390000069],
            [72.097383000000036, 31.680029000000047],
            [72.067790000000059, 31.652712000000065],
            [72.04639300000008, 31.636778000000049],
            [72.031824000000029, 31.615835000000061],
            [72.029092000000048, 31.595348000000058],
            [72.02852615200004, 31.592681139000035],
            [72.028461431000039, 31.592376108000053],
            [72.022718000000054, 31.565300000000036],
            [71.994036000000051, 31.533886000000052],
            [71.991396909000059, 31.530210074000024],
            [71.989185841000051, 31.531999986000073],
            [71.961339500000065, 31.534261000000072],
            [71.949045500000068, 31.551824000000067],
            [71.94202000000007, 31.554458000000068],
            [71.934995000000072, 31.567630000000065],
            [71.932361001000061, 31.579046000000062],
            [71.920067000000074, 31.592218000000059],
            [71.906763094000041, 31.59906019500005],
            [71.889332000000081, 31.608025000000055],
            [71.878080142000044, 31.609576737000054],
            [71.863730627000052, 31.611663539000062],
            [71.836643000000038, 31.63700300000005],
            [71.807665000000043, 31.654566000000045],
            [71.797831621000057, 31.66269687700003],
            [71.795753352000077, 31.66359510500007],
            [71.773417000000052, 31.674763000000041],
            [71.769298893000041, 31.679288429000053],
            [71.766471650000028, 31.682395314000075],
            [71.764636000000053, 31.684423000000038],
            [71.760730038000077, 31.689891264000039],
            [71.755854500000055, 31.696717000000035],
            [71.747951000000057, 31.716914000000031],
            [71.745317000000057, 31.735355000000027],
            [71.744818130000056, 31.747829028000069],
            [71.744439000000057, 31.757309000000078],
            [71.73565700000006, 31.766090000000077],
            [71.726876000000061, 31.782775000000072],
            [71.724727078000058, 31.788082579000047],
            [71.717216000000064, 31.806485000000066],
            [71.711518561000048, 31.814623802000028],
            [71.68911600000007, 31.818779000000063],
            [71.666278365000039, 31.833840229000032],
            [71.637840010000048, 31.844173907000027],
            [71.625067035000029, 31.850458451000065],
            [71.625904820000073, 31.861822896000035],
            [71.625905344000046, 31.86183000300008],
            [71.635591582000075, 31.872397204000038],
            [71.645865789000084, 31.87944206700007],
            [71.649640165000051, 31.882028435000052],
            [71.67069516600003, 31.886434805000079],
            [71.688260350000064, 31.897852963000048],
            [71.689700775000063, 31.903847589000065],
            [71.691649710000036, 31.912093566000067],
            [71.69262800000007, 31.916252000000043],
            [71.704922000000067, 31.92854600000004],
            [71.711069000000066, 31.943475000000035],
            [71.718973000000062, 31.950500000000034],
            [71.719750218000058, 31.96223847400006],
            [71.718164427000033, 31.967312573000072],
            [71.715412866000065, 31.976343127000064],
            [71.714594757000043, 31.982888926000044],
            [71.72060323200003, 31.995017165000036],
            [71.723628906000044, 32.001037595000071],
            [71.725900384000056, 32.005312720000063],
            [71.730388000000062, 32.013726000000077],
            [71.742305781000084, 32.017951560000029],
            [71.749708000000055, 32.020751000000075],
            [71.761123000000055, 32.030411000000072],
            [71.765514000000053, 32.043583000000069],
            [71.765969871000038, 32.051789720000045],
            [71.766392000000053, 32.059389000000067],
            [71.770783000000051, 32.060267000000067],
            [71.769580079000036, 32.082513256000027],
            [71.769302885000059, 32.085733000000062],
            [71.771058885000059, 32.096271000000058],
            [71.771630740000035, 32.101361677000057],
            [71.772837773000049, 32.112106734000065],
            [71.775455424000029, 32.134969943000044],
            [71.776327885000057, 32.144569000000047],
            [71.761398885000062, 32.155985000000044],
            [71.755251885000064, 32.170035000000041],
            [71.749104885000065, 32.17530400000004],
            [71.745939973000077, 32.176569121000057],
            [71.73390100000006, 32.181451000000038],
            [71.72734969000004, 32.186826788000076],
            [71.728104567000059, 32.194406789000027],
            [71.72847500000006, 32.197122000000036],
            [71.722414832000084, 32.215300871000068],
            [71.721061000000077, 32.219362000000046],
            [71.721491878000052, 32.231422402000078],
            [71.721679000000051, 32.236660000000029],
            [71.726706015000048, 32.240115978000063],
            [71.731563000000051, 32.24345500000004],
            [71.730327123000052, 32.252283635000026],
            [71.72980821200008, 32.255990535000024],
            [71.727266735000057, 32.274145872000076],
            [71.727239000000054, 32.274344000000042],
            [71.735398590000045, 32.282788180000068],
            [71.746538483000052, 32.294759127000077],
            [71.757304769000029, 32.305458404000035],
            [71.77453321400003, 32.323287743000037],
            [71.780367000000069, 32.32932500000004],
            [71.786910758000033, 32.337013803000048],
            [71.78755546900004, 32.337771327000041],
            [71.805078000000037, 32.358360000000062],
            [71.835967000000039, 32.385542000000044],
            [71.845233000000064, 32.406546000000048],
            [71.843127198000047, 32.417916919000049],
            [71.842144000000076, 32.423226000000057],
            [71.826700000000073, 32.425697000000071],
            [71.821140000000071, 32.434346000000062],
            [71.81063800000004, 32.442995000000053],
            [71.795848807000084, 32.452187857000069],
            [71.787780500000054, 32.45720300000005],
            [71.789016186000083, 32.46803443400006],
            [71.790869000000043, 32.478208000000052],
            [71.81063800000004, 32.480679000000066],
            [71.822993000000054, 32.490563000000066],
            [71.832260000000076, 32.502301000000045],
            [71.835476693000032, 32.522204543000043],
            [71.858824000000084, 32.523923000000025],
            [71.880446000000063, 32.533807000000024],
            [71.881702157000063, 32.538203041000031],
            [71.884153000000083, 32.546780000000069],
            [71.894037000000083, 32.54492700000003],
            [71.911952000000042, 32.543074000000047],
            [71.929902463000076, 32.541090632000078],
            [71.930490582000061, 32.540285441000037],
            [71.933777829000064, 32.53827353500003],
            [71.933808441000053, 32.538249432000043],
            [71.93966400000005, 32.53467100000006],
            [71.952152000000069, 32.540023000000076],
            [71.955272841000067, 32.545283996000023],
            [71.961072000000058, 32.555060000000026],
            [71.964660748000028, 32.555060000000026],
            [71.981970000000047, 32.555060000000026],
            [71.981553754000061, 32.567540044000054],
            [71.981460000000084, 32.570351000000073],
            [71.988086000000067, 32.580545001000075],
            [72.002358000000072, 32.585387000000026],
            [72.017394000000081, 32.589720000000057],
            [72.043389000000047, 32.594052000000033],
            [72.065040641000053, 32.607315917000051],
            [72.071678000000077, 32.611382000000049],
            [72.09971100000007, 32.627693000000079],
            [72.123360391000062, 32.63313163600003],
            [72.125197000000071, 32.633554000000061],
            [72.157818000000077, 32.658530000000042],
            [72.159347000000082, 32.674585000000036],
            [72.147624000000064, 32.695483000000024],
            [72.151139163000039, 32.712807593000036],
            [72.151192000000037, 32.713068000000078],
            [72.165791431000059, 32.711809650000077],
            [72.17388444200003, 32.703210588000047],
            [72.174054573000035, 32.703207019000047],
            [72.174128000000053, 32.703129000000047],
            [72.177377454000066, 32.703061264000041],
            [72.178182989000049, 32.703044472000045],
            [72.186361000000034, 32.702874000000065],
            [72.200123000000076, 32.702109000000064],
            [72.212611000000038, 32.700580000000059],
            [72.239307457000052, 32.698869042000069],
            [72.239308019000077, 32.698869006000052],
            [72.25232335700008, 32.698034861000053],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "105",
      properties: { name: "Lahore", count: 240 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.498297000000036, 31.705711000000065],
            [74.499185000000068, 31.703093000000024],
            [74.501110766000068, 31.703724779000027],
            [74.501590000000078, 31.703882000000078],
            [74.50332300000008, 31.702536000000066],
            [74.50249800000006, 31.700913000000071],
            [74.502710000000036, 31.700513000000058],
            [74.502204000000063, 31.699359000000072],
            [74.502815000000055, 31.698908000000074],
            [74.501626000000044, 31.697526000000039],
            [74.502138000000059, 31.696999000000062],
            [74.501691000000051, 31.696277000000066],
            [74.502241000000083, 31.695941000000062],
            [74.50202500000006, 31.695594000000028],
            [74.502725000000055, 31.695264000000066],
            [74.502964000000077, 31.695179000000053],
            [74.503858000000037, 31.694830000000024],
            [74.504906000000062, 31.694479000000058],
            [74.505485000000078, 31.694289000000026],
            [74.50684700000005, 31.693797000000075],
            [74.50759800000003, 31.693023000000039],
            [74.508130000000051, 31.692791000000057],
            [74.508793000000082, 31.692500000000052],
            [74.509534000000031, 31.692131000000074],
            [74.510626000000059, 31.691670000000045],
            [74.511456000000067, 31.691372000000058],
            [74.512075000000038, 31.691102000000058],
            [74.512816000000043, 31.690821000000028],
            [74.513349000000062, 31.69059800000008],
            [74.514248000000066, 31.690201000000059],
            [74.514712000000031, 31.689982000000043],
            [74.515229000000033, 31.689646000000039],
            [74.515659000000085, 31.689380000000028],
            [74.516353000000038, 31.688874000000055],
            [74.516702000000066, 31.688772000000029],
            [74.517362000000048, 31.68852000000004],
            [74.51802500000008, 31.688273000000038],
            [74.518681000000072, 31.688112000000046],
            [74.519547000000046, 31.687854000000073],
            [74.519652068000084, 31.687824843000044],
            [74.520156000000043, 31.687685000000045],
            [74.520791000000031, 31.687517000000071],
            [74.52150000000006, 31.687319000000059],
            [74.521788000000072, 31.687227000000064],
            [74.522117000000037, 31.687111000000073],
            [74.522520000000043, 31.686969000000033],
            [74.522971000000041, 31.686799000000065],
            [74.523099000000059, 31.686716000000047],
            [74.523292000000083, 31.686751000000072],
            [74.523470000000032, 31.686810000000037],
            [74.523649000000034, 31.686857000000032],
            [74.523895000000039, 31.686916000000053],
            [74.524128000000076, 31.686964000000046],
            [74.524308000000076, 31.686905000000024],
            [74.524463000000082, 31.686727000000076],
            [74.524590000000046, 31.686573000000067],
            [74.524773000000039, 31.686301000000071],
            [74.524942000000067, 31.686065000000042],
            [74.525193000000058, 31.685854000000063],
            [74.525414000000069, 31.685689000000025],
            [74.525608000000034, 31.685514000000069],
            [74.525748000000078, 31.685268000000065],
            [74.525872000000049, 31.684990000000028],
            [74.526053000000047, 31.684652000000028],
            [74.526205000000061, 31.684443000000044],
            [74.526492334000068, 31.684133048000035],
            [74.526548000000048, 31.684073000000069],
            [74.527176000000054, 31.683496000000048],
            [74.529033000000084, 31.68211400000007],
            [74.529367000000036, 31.682006000000058],
            [74.529854000000057, 31.681909000000076],
            [74.530473000000029, 31.681759000000056],
            [74.530916000000047, 31.681609000000037],
            [74.531525000000045, 31.681299000000024],
            [74.531866000000036, 31.681150000000059],
            [74.532055000000071, 31.681043000000045],
            [74.532195000000058, 31.680683000000045],
            [74.532334000000048, 31.680372000000034],
            [74.532460000000071, 31.680094000000054],
            [74.532522000000029, 31.679859000000079],
            [74.53258500000004, 31.67951800000003],
            [74.532597000000067, 31.679284000000052],
            [74.53257700000006, 31.679006000000072],
            [74.532602000000054, 31.678758000000073],
            [74.532665000000065, 31.678575000000023],
            [74.532727000000079, 31.678467000000069],
            [74.53318100000007, 31.678382000000056],
            [74.533993000000066, 31.678371000000027],
            [74.534334000000058, 31.678339000000051],
            [74.534562000000051, 31.678285000000074],
            [74.534599000000071, 31.678200000000061],
            [74.534572000000082, 31.678060000000073],
            [74.534621000000072, 31.677878000000078],
            [74.534670000000062, 31.677728000000059],
            [74.534719000000052, 31.677621000000045],
            [74.534921000000054, 31.677590000000066],
            [74.535186000000067, 31.677600000000041],
            [74.535171000000048, 31.67747200000008],
            [74.535142000000064, 31.677196000000038],
            [74.535077000000058, 31.676678000000038],
            [74.534812000000045, 31.675879000000066],
            [74.534132000000056, 31.675007000000051],
            [74.534229000000039, 31.674615000000074],
            [74.534314000000052, 31.674255000000073],
            [74.534314000000052, 31.673979000000031],
            [74.534413000000029, 31.673820000000035],
            [74.53448700000007, 31.673641000000032],
            [74.534510000000068, 31.673515000000066],
            [74.534422000000063, 31.673431000000051],
            [74.534267000000057, 31.673343000000045],
            [74.533901000000071, 31.67315300000007],
            [74.533486000000039, 31.672933000000057],
            [74.533160000000066, 31.672734000000048],
            [74.532869000000062, 31.672511000000043],
            [74.532379000000049, 31.672128000000043],
            [74.532039000000054, 31.671860000000038],
            [74.531648000000075, 31.671475000000044],
            [74.531316000000061, 31.671173000000067],
            [74.530925000000082, 31.670817000000056],
            [74.530619000000058, 31.670479000000057],
            [74.530275000000074, 31.670057000000043],
            [74.53019149000005, 31.669963436000046],
            [74.529868000000079, 31.669601000000057],
            [74.529603000000066, 31.669255000000078],
            [74.529541000000052, 31.669019000000048],
            [74.529454000000044, 31.668869000000029],
            [74.529354000000069, 31.668720000000064],
            [74.529393000000084, 31.668410000000051],
            [74.529344000000037, 31.668176000000074],
            [74.529158000000052, 31.667983000000049],
            [74.529074000000037, 31.667651000000035],
            [74.529002000000048, 31.667344000000071],
            [74.529028000000039, 31.667039000000045],
            [74.528953000000058, 31.666757000000075],
            [74.528729000000055, 31.666353000000072],
            [74.529342000000042, 31.66624500000006],
            [74.529583000000059, 31.666256000000033],
            [74.530492000000038, 31.666027000000042],
            [74.531164000000047, 31.66606100000007],
            [74.531488000000081, 31.665784000000031],
            [74.531853000000069, 31.665486000000044],
            [74.532555000000059, 31.664966000000049],
            [74.533621000000039, 31.664116000000035],
            [74.533890000000042, 31.663974000000053],
            [74.534858000000042, 31.663508000000036],
            [74.535443000000043, 31.663205000000062],
            [74.536380000000065, 31.66274100000004],
            [74.537060000000054, 31.662377000000049],
            [74.537713861000043, 31.661961207000047],
            [74.537851000000046, 31.661874000000068],
            [74.538314000000071, 31.661501000000044],
            [74.538735000000031, 31.661140000000046],
            [74.539044000000047, 31.660865000000058],
            [74.539640000000077, 31.660388000000069],
            [74.540300000000059, 31.659906000000035],
            [74.539746000000036, 31.658413000000053],
            [74.538912000000039, 31.657312000000047],
            [74.53805600000004, 31.656504000000041],
            [74.538202000000069, 31.655879000000027],
            [74.537699000000032, 31.655396000000053],
            [74.537408000000084, 31.655439000000058],
            [74.537270000000035, 31.655396000000053],
            [74.537515000000042, 31.654862000000037],
            [74.537230000000079, 31.654670000000067],
            [74.537569000000076, 31.653889000000049],
            [74.537388000000078, 31.653116000000068],
            [74.537678000000085, 31.652233000000024],
            [74.537846000000059, 31.651766000000066],
            [74.53804000000008, 31.651106000000027],
            [74.538400000000081, 31.64992600000005],
            [74.538639000000046, 31.649399000000074],
            [74.539162000000033, 31.648261000000048],
            [74.53962431900004, 31.64802910700007],
            [74.541052000000036, 31.647313000000054],
            [74.541284000000076, 31.646677000000068],
            [74.542144000000064, 31.646990000000073],
            [74.543260000000032, 31.647454000000039],
            [74.544973000000084, 31.648411000000067],
            [74.545898000000079, 31.646955000000048],
            [74.546209000000033, 31.646289000000024],
            [74.546560000000056, 31.645701000000031],
            [74.546564000000046, 31.644911000000036],
            [74.546629000000053, 31.64483400000006],
            [74.546899000000053, 31.644353000000024],
            [74.547203000000081, 31.643733000000054],
            [74.54754900000006, 31.642923000000053],
            [74.547858000000076, 31.642301000000032],
            [74.547921000000031, 31.641561000000024],
            [74.547962000000041, 31.640484000000072],
            [74.547884000000067, 31.639616000000046],
            [74.547840197000085, 31.638868713000079],
            [74.54783400000008, 31.63876300000004],
            [74.547297000000071, 31.637705000000039],
            [74.546793000000036, 31.63667300000003],
            [74.546771000000035, 31.63584000000003],
            [74.546748000000036, 31.634941000000026],
            [74.546854000000053, 31.634217000000035],
            [74.547123000000056, 31.633674000000042],
            [74.54621700000007, 31.633263000000056],
            [74.546347000000083, 31.633121000000074],
            [74.54636326800005, 31.633104943000035],
            [74.546655000000044, 31.632817000000045],
            [74.547022000000084, 31.632371000000035],
            [74.547228000000075, 31.632229000000052],
            [74.547473000000082, 31.63203500000003],
            [74.547679000000073, 31.631797000000063],
            [74.547807000000034, 31.631625000000042],
            [74.548180000000059, 31.630853000000059],
            [74.548914000000082, 31.628898000000049],
            [74.549960000000056, 31.62637200000006],
            [74.550344000000052, 31.625829000000067],
            [74.550869000000034, 31.625112000000058],
            [74.551262818000055, 31.624553748000039],
            [74.551633000000038, 31.624029000000064],
            [74.551742000000047, 31.623512000000062],
            [74.551851000000056, 31.623074000000031],
            [74.551957000000073, 31.622826000000032],
            [74.551898000000051, 31.622312000000079],
            [74.551967000000047, 31.621768000000031],
            [74.551855000000046, 31.62146800000005],
            [74.551825000000065, 31.620849000000078],
            [74.551473000000044, 31.620344000000046],
            [74.551158000000044, 31.61905100000007],
            [74.55117800000005, 31.617691000000036],
            [74.551511000000062, 31.617375000000038],
            [74.551744000000042, 31.617113000000074],
            [74.551976000000082, 31.616906000000029],
            [74.552349000000049, 31.61655600000006],
            [74.552899000000082, 31.616155000000049],
            [74.55340700000005, 31.61571200000003],
            [74.553736611000033, 31.615455015000066],
            [74.55405600000006, 31.615206000000057],
            [74.554520000000082, 31.614755000000059],
            [74.554830000000038, 31.614468000000045],
            [74.555074000000047, 31.61413900000008],
            [74.555293000000063, 31.61385400000006],
            [74.555217000000084, 31.613679000000047],
            [74.55502800000005, 31.613526000000036],
            [74.554599000000053, 31.613233000000037],
            [74.555070000000057, 31.612703000000067],
            [74.555358000000069, 31.612406000000078],
            [74.555516000000068, 31.612260000000049],
            [74.555754000000036, 31.612067000000025],
            [74.556056000000069, 31.611874000000057],
            [74.55622900000003, 31.611693000000059],
            [74.556335000000047, 31.611548000000028],
            [74.55636400000003, 31.611336000000051],
            [74.556187000000079, 31.610668000000032],
            [74.556013000000064, 31.609854000000041],
            [74.555402000000072, 31.607837000000075],
            [74.55577500000004, 31.607814000000076],
            [74.556477000000029, 31.607758000000047],
            [74.556846000000064, 31.607726000000071],
            [74.559257000000059, 31.607349000000056],
            [74.559600000000046, 31.607305000000053],
            [74.559886000000063, 31.607294000000024],
            [74.560114000000056, 31.607316000000026],
            [74.560367000000042, 31.607349000000056],
            [74.560798000000034, 31.607435000000066],
            [74.561772000000076, 31.607653000000028],
            [74.563069000000041, 31.60755400000005],
            [74.564429000000075, 31.60723900000005],
            [74.566391000000067, 31.607101000000057],
            [74.567821000000038, 31.607001000000025],
            [74.569090000000074, 31.606595000000027],
            [74.569157000000075, 31.606194000000073],
            [74.56995500000005, 31.606184000000042],
            [74.570247000000052, 31.606152000000066],
            [74.571087000000034, 31.606030000000032],
            [74.571835000000078, 31.60575300000005],
            [74.572448000000065, 31.605600000000038],
            [74.573151000000053, 31.60537000000005],
            [74.573041000000046, 31.604955000000075],
            [74.573083000000054, 31.604736000000059],
            [74.573202000000038, 31.604056000000071],
            [74.573606000000041, 31.604110000000048],
            [74.574060000000031, 31.604175000000055],
            [74.574628000000075, 31.604197000000056],
            [74.57485794400003, 31.604207916000064],
            [74.575281000000075, 31.604228000000035],
            [74.575699000000043, 31.600808000000029],
            [74.576208000000065, 31.600863000000061],
            [74.576286000000039, 31.600482000000056],
            [74.576778000000047, 31.600477000000069],
            [74.577515000000062, 31.600377000000037],
            [74.578405000000032, 31.600082000000043],
            [74.578911000000062, 31.599729000000025],
            [74.577388000000042, 31.599420000000066],
            [74.577090000000055, 31.598525000000052],
            [74.574893000000031, 31.598557000000028],
            [74.574853000000076, 31.597770000000025],
            [74.575182000000041, 31.597337000000039],
            [74.575636000000031, 31.596681000000046],
            [74.576172000000042, 31.596119000000044],
            [74.57708400000007, 31.595155000000034],
            [74.577814000000046, 31.59494200000006],
            [74.57806400000004, 31.595145000000059],
            [74.579352000000029, 31.594994000000042],
            [74.579582000000073, 31.594543000000044],
            [74.579864000000043, 31.594434000000035],
            [74.580018000000052, 31.594423000000063],
            [74.58037500000006, 31.59437900000006],
            [74.580773000000079, 31.594378000000063],
            [74.580955000000074, 31.594235000000026],
            [74.580926000000034, 31.593665000000044],
            [74.58089300000006, 31.593208000000061],
            [74.580772000000081, 31.59263100000004],
            [74.58111800000006, 31.592413000000079],
            [74.581237000000044, 31.591810000000066],
            [74.581443000000036, 31.591690000000028],
            [74.581508000000042, 31.59147100000007],
            [74.581361000000072, 31.591011000000037],
            [74.580822000000069, 31.591066000000069],
            [74.58076200000005, 31.590879000000029],
            [74.581004191000034, 31.590037312000049],
            [74.581339359000083, 31.59004681600004],
            [74.581121706000033, 31.589553098000067],
            [74.581228000000067, 31.588958000000048],
            [74.581508000000042, 31.589045000000056],
            [74.582335000000057, 31.588135000000079],
            [74.582337000000052, 31.587971000000039],
            [74.582045000000051, 31.587839000000031],
            [74.581800000000044, 31.587972000000036],
            [74.581633000000068, 31.587972000000036],
            [74.581368000000055, 31.587699000000043],
            [74.580952000000082, 31.587285000000065],
            [74.581033000000048, 31.587077000000079],
            [74.581850000000031, 31.586392000000046],
            [74.582817000000034, 31.586111000000074],
            [74.583135000000084, 31.585917000000052],
            [74.584606000000065, 31.584990000000062],
            [74.585140000000081, 31.585602000000051],
            [74.585754000000065, 31.585091000000034],
            [74.585229000000083, 31.584590000000048],
            [74.586971000000062, 31.583414000000062],
            [74.586354000000028, 31.58294200000006],
            [74.586127000000033, 31.58269400000006],
            [74.585270000000037, 31.581289000000027],
            [74.586785000000077, 31.580223000000046],
            [74.588138000000072, 31.579597000000035],
            [74.589072000000044, 31.57918200000006],
            [74.589358000000061, 31.578872000000047],
            [74.589388306000046, 31.578842369000029],
            [74.589717000000064, 31.578521000000023],
            [74.590472000000034, 31.577964000000065],
            [74.590816000000075, 31.577357000000063],
            [74.591873000000078, 31.576527000000056],
            [74.592355000000055, 31.574933000000044],
            [74.592111000000045, 31.574016000000029],
            [74.592840000000081, 31.573884000000078],
            [74.59341500000005, 31.57376400000004],
            [74.594384000000048, 31.573588000000029],
            [74.595374000000049, 31.573405000000037],
            [74.596491000000071, 31.573320000000024],
            [74.597101000000066, 31.573277000000076],
            [74.597646000000054, 31.573244000000045],
            [74.598330000000033, 31.573178000000041],
            [74.599082000000067, 31.573158000000035],
            [74.599822000000074, 31.573079000000064],
            [74.600453000000073, 31.572999000000038],
            [74.600725904000058, 31.572928500000046],
            [74.600933000000055, 31.572875000000067],
            [74.601701000000048, 31.572667000000024],
            [74.602887000000067, 31.572146000000032],
            [74.603746000000058, 31.57171900000003],
            [74.604713000000061, 31.571219000000042],
            [74.60580200000004, 31.570691000000068],
            [74.606372000000079, 31.570243000000062],
            [74.607032000000061, 31.569966000000079],
            [74.607907000000068, 31.569550000000049],
            [74.608386000000053, 31.569197000000031],
            [74.608899000000065, 31.569075000000055],
            [74.609015000000056, 31.568923000000041],
            [74.609296000000029, 31.568825000000061],
            [74.609335000000044, 31.568511000000058],
            [74.609284000000059, 31.568411000000026],
            [74.609350000000063, 31.568233000000077],
            [74.609467000000052, 31.568044000000043],
            [74.609792000000084, 31.567720000000065],
            [74.610697000000073, 31.567499000000055],
            [74.610621000000037, 31.567123000000038],
            [74.61122400000005, 31.567068000000063],
            [74.611326000000076, 31.567101000000036],
            [74.611365000000035, 31.567201000000068],
            [74.611416000000077, 31.567400000000077],
            [74.611467000000061, 31.56764400000003],
            [74.611506000000077, 31.567877000000067],
            [74.611570000000029, 31.568366000000026],
            [74.611815000000036, 31.568367000000023],
            [74.61288600000006, 31.568175000000053],
            [74.613175000000069, 31.568850000000054],
            [74.615873000000079, 31.569079000000045],
            [74.615860000000055, 31.56810500000006],
            [74.615012000000036, 31.568084000000056],
            [74.614743000000033, 31.567359000000067],
            [74.613936000000081, 31.567461000000037],
            [74.613545000000045, 31.566894000000048],
            [74.612918000000036, 31.567170000000033],
            [74.612161000000071, 31.567470000000071],
            [74.611891000000071, 31.567436000000043],
            [74.611750000000029, 31.567302000000041],
            [74.61168500000008, 31.567114000000061],
            [74.611493000000053, 31.56684800000005],
            [74.611442000000068, 31.566651000000036],
            [74.611390000000029, 31.566520000000025],
            [74.611288000000059, 31.566488000000049],
            [74.611211000000083, 31.566466000000048],
            [74.609686000000067, 31.566827000000046],
            [74.609560000000045, 31.566616000000067],
            [74.609497000000033, 31.566571000000067],
            [74.608724000000052, 31.566783000000044],
            [74.608625000000075, 31.566562000000033],
            [74.608501000000047, 31.566178000000036],
            [74.608377000000075, 31.565881000000047],
            [74.60830100000004, 31.565331000000072],
            [74.608177000000069, 31.565146000000027],
            [74.608002000000056, 31.565026000000046],
            [74.607801000000052, 31.564928000000066],
            [74.607538000000034, 31.56475400000005],
            [74.607340000000079, 31.564538000000027],
            [74.607068000000083, 31.564213000000052],
            [74.606872000000067, 31.563955000000078],
            [74.606737000000066, 31.563772000000029],
            [74.606741000000056, 31.563590000000033],
            [74.606696000000056, 31.563354000000061],
            [74.606712000000073, 31.563226000000043],
            [74.606665000000078, 31.562976000000049],
            [74.606605000000059, 31.562799000000041],
            [74.606520000000046, 31.562621000000036],
            [74.60666400000008, 31.562510000000032],
            [74.607315000000028, 31.56230400000004],
            [74.608393000000035, 31.561737000000051],
            [74.609000000000037, 31.561384000000032],
            [74.609306000000061, 31.561297000000025],
            [74.609703000000081, 31.560862000000043],
            [74.610162000000059, 31.560407000000055],
            [74.610569000000055, 31.560363000000052],
            [74.611140000000034, 31.560113000000058],
            [74.610913000000039, 31.559414000000061],
            [74.610873000000083, 31.558909000000028],
            [74.611462000000074, 31.55868600000008],
            [74.61260800000008, 31.558239000000071],
            [74.613838000000044, 31.557759000000033],
            [74.614091072000065, 31.557710973000042],
            [74.615129000000081, 31.557514000000026],
            [74.615884000000051, 31.557516000000078],
            [74.615986000000078, 31.55738400000007],
            [74.616125000000068, 31.557187000000056],
            [74.616251000000034, 31.556956000000071],
            [74.616400000000056, 31.556638000000078],
            [74.616537000000051, 31.556299000000024],
            [74.616621000000066, 31.555842000000041],
            [74.616714000000059, 31.555300000000045],
            [74.616682000000083, 31.554888000000062],
            [74.616768000000036, 31.554634000000078],
            [74.616855000000044, 31.554349000000059],
            [74.616814000000034, 31.553998000000036],
            [74.616750000000081, 31.553205000000048],
            [74.617956000000049, 31.552714000000037],
            [74.618277000000035, 31.552683000000059],
            [74.618364000000042, 31.552333000000033],
            [74.61845100000005, 31.552059000000042],
            [74.618460000000084, 31.551721000000043],
            [74.618408000000045, 31.551568000000032],
            [74.618175000000065, 31.551371000000074],
            [74.617966000000081, 31.551217000000065],
            [74.617263000000037, 31.55126000000007],
            [74.616959000000065, 31.551391000000024],
            [74.616234000000077, 31.551467000000059],
            [74.615445000000079, 31.55150100000003],
            [74.615241000000083, 31.551512000000059],
            [74.615037000000029, 31.551480000000026],
            [74.614845000000059, 31.551425000000052],
            [74.61458900000008, 31.551337000000046],
            [74.614322000000072, 31.551281000000074],
            [74.614092000000085, 31.551161000000036],
            [74.614130000000046, 31.550780000000032],
            [74.61414300000007, 31.550131000000079],
            [74.614307000000053, 31.549322000000075],
            [74.614307000000053, 31.549065000000041],
            [74.614369000000067, 31.548756000000026],
            [74.61423300000007, 31.547737000000041],
            [74.61423300000007, 31.547594000000061],
            [74.614297000000079, 31.547462000000053],
            [74.614751000000069, 31.546295000000043],
            [74.614766000000031, 31.545330000000035],
            [74.614731000000063, 31.544781000000057],
            [74.614723000000083, 31.544275000000027],
            [74.614566000000082, 31.543965000000071],
            [74.61446200000006, 31.543833000000063],
            [74.614208000000076, 31.543879000000061],
            [74.61414300000007, 31.543791000000056],
            [74.614064000000042, 31.543656000000055],
            [74.613972000000047, 31.543488000000025],
            [74.613818000000038, 31.543399000000079],
            [74.61362500000007, 31.543332000000078],
            [74.613422000000071, 31.543333000000075],
            [74.613218000000074, 31.543401000000074],
            [74.613026000000048, 31.543456000000049],
            [74.612784000000033, 31.543502000000046],
            [74.612656000000072, 31.543481000000043],
            [74.612617000000057, 31.542973000000075],
            [74.612617000000057, 31.542755000000056],
            [74.612630000000081, 31.542406000000028],
            [74.612617000000057, 31.542024000000026],
            [74.612471000000085, 31.54079900000005],
            [74.612780000000043, 31.540776000000051],
            [74.61313844700004, 31.538802825000062],
            [74.613511000000074, 31.536752000000035],
            [74.613751000000036, 31.535974000000067],
            [74.613965000000064, 31.535336000000029],
            [74.613978000000031, 31.535120000000063],
            [74.613960000000077, 31.535056000000054],
            [74.613793000000044, 31.534815000000037],
            [74.613550000000032, 31.534400000000062],
            [74.613332000000071, 31.534092000000044],
            [74.613051000000041, 31.533572000000049],
            [74.612900000000081, 31.533013000000039],
            [74.612776000000054, 31.532371000000069],
            [74.612564000000077, 31.531766000000061],
            [74.612349000000052, 31.531409000000053],
            [74.612118000000066, 31.531070000000057],
            [74.612041000000033, 31.530687000000057],
            [74.611975000000029, 31.530302000000063],
            [74.612087000000031, 31.529809000000057],
            [74.612256000000059, 31.529269000000056],
            [74.612419000000045, 31.52891500000004],
            [74.61260500000003, 31.528386000000069],
            [74.612700000000075, 31.527363000000037],
            [74.612688000000048, 31.527374000000066],
            [74.612672000000032, 31.527188000000024],
            [74.612457000000063, 31.527145000000075],
            [74.61210200000005, 31.527081000000067],
            [74.611745000000042, 31.527080000000069],
            [74.610778000000039, 31.52720000000005],
            [74.610065000000077, 31.527123000000074],
            [74.609925000000032, 31.52706100000006],
            [74.608655000000056, 31.52665200000007],
            [74.608024000000057, 31.526341000000059],
            [74.607335000000035, 31.52572200000003],
            [74.605736000000036, 31.52488500000004],
            [74.605505000000051, 31.524637000000041],
            [74.603978000000041, 31.523985000000039],
            [74.603713000000084, 31.524000000000058],
            [74.603465000000028, 31.524118000000044],
            [74.602854000000036, 31.524345000000039],
            [74.602356000000043, 31.52443100000005],
            [74.601700000000051, 31.524485000000027],
            [74.600573000000054, 31.524506000000031],
            [74.60040900000007, 31.524561000000062],
            [74.599705000000029, 31.524809000000062],
            [74.598576000000037, 31.525233000000071],
            [74.597702000000083, 31.525549000000069],
            [74.596750000000043, 31.525876000000039],
            [74.596254000000044, 31.525920000000042],
            [74.595706000000064, 31.525800000000061],
            [74.595448000000033, 31.525613000000078],
            [74.595025000000078, 31.52530500000006],
            [74.594784000000061, 31.525089000000037],
            [74.594201000000055, 31.524549000000036],
            [74.593584000000078, 31.523979000000054],
            [74.593372000000045, 31.523612000000071],
            [74.593069000000071, 31.523526000000061],
            [74.592904000000033, 31.523408000000074],
            [74.592386000000033, 31.523160000000075],
            [74.591789000000063, 31.522885000000031],
            [74.591166000000044, 31.522734000000071],
            [74.590643000000057, 31.52246400000007],
            [74.589324000000033, 31.522302000000025],
            [74.589272000000051, 31.52187200000003],
            [74.588981000000047, 31.521840000000054],
            [74.588944000000083, 31.521363000000065],
            [74.58636000000007, 31.521462000000042],
            [74.584687000000031, 31.520768000000032],
            [74.584170000000029, 31.520801000000063],
            [74.584101237000084, 31.520547279000027],
            [74.583867000000055, 31.519683000000043],
            [74.583666000000051, 31.51900500000005],
            [74.583581000000038, 31.518791000000078],
            [74.583316000000082, 31.517999000000032],
            [74.583071000000075, 31.517373000000077],
            [74.582876000000056, 31.516761000000031],
            [74.582851000000062, 31.516607000000079],
            [74.582807000000059, 31.516197000000034],
            [74.582763000000057, 31.515855000000045],
            [74.58274300000005, 31.515526000000079],
            [74.582744000000048, 31.515330000000063],
            [74.58307300000007, 31.514865000000043],
            [74.582845000000077, 31.514050000000054],
            [74.582349000000079, 31.512888000000032],
            [74.581670000000031, 31.512214000000029],
            [74.580614000000082, 31.511784000000034],
            [74.580217000000061, 31.511195000000043],
            [74.580271000000039, 31.511022000000025],
            [74.580476000000033, 31.510946000000047],
            [74.580706000000077, 31.510967000000051],
            [74.581283000000042, 31.511055000000056],
            [74.581995000000063, 31.51092600000004],
            [74.581964000000085, 31.510556000000065],
            [74.583635000000072, 31.510361000000046],
            [74.583729000000062, 31.509157000000073],
            [74.583236000000056, 31.50912500000004],
            [74.583143000000064, 31.508430000000033],
            [74.583301935000065, 31.507814708000069],
            [74.58331400000003, 31.507768000000056],
            [74.584033000000034, 31.507746000000054],
            [74.584123000000034, 31.507480000000044],
            [74.584126000000083, 31.506762000000037],
            [74.583418000000052, 31.506594000000064],
            [74.583005000000071, 31.506816000000072],
            [74.582980000000077, 31.506968000000029],
            [74.582866000000081, 31.507217000000026],
            [74.58256700000004, 31.507673000000068],
            [74.582551618000082, 31.50769193800005],
            [74.582381000000055, 31.507902000000058],
            [74.58205300000003, 31.508042000000046],
            [74.580999000000077, 31.508017000000052],
            [74.580148000000065, 31.507947000000058],
            [74.58012885900007, 31.507295518000035],
            [74.580120000000079, 31.506994000000077],
            [74.580307000000062, 31.506325000000061],
            [74.580956000000072, 31.506415000000061],
            [74.581100000000049, 31.506729000000064],
            [74.582509000000073, 31.506273000000078],
            [74.582963000000063, 31.505994000000044],
            [74.58395100000007, 31.505660000000034],
            [74.584932000000038, 31.505366000000038],
            [74.585821000000067, 31.505037000000073],
            [74.586528000000044, 31.504624000000035],
            [74.587284000000068, 31.504181000000074],
            [74.586746000000062, 31.503127000000063],
            [74.586196000000029, 31.50211900000005],
            [74.585659000000078, 31.501088000000038],
            [74.585285000000056, 31.500880000000052],
            [74.584697000000062, 31.500123000000031],
            [74.583430000000078, 31.500781000000075],
            [74.582900000000052, 31.500362000000052],
            [74.582073000000037, 31.500659000000041],
            [74.58149700000007, 31.500690000000077],
            [74.581188000000054, 31.498752000000025],
            [74.580453000000034, 31.498904000000039],
            [74.580465000000061, 31.499412000000063],
            [74.580096000000083, 31.499879000000078],
            [74.580032000000074, 31.500129000000072],
            [74.57991700000008, 31.500520000000051],
            [74.579266000000075, 31.500500000000045],
            [74.577113000000054, 31.501146000000062],
            [74.576558000000034, 31.501518000000033],
            [74.575622000000067, 31.501603000000046],
            [74.575391000000081, 31.501482000000067],
            [74.575338000000045, 31.500886000000037],
            [74.57556100000005, 31.500709000000029],
            [74.575853000000052, 31.500411000000042],
            [74.57625100000007, 31.500079000000028],
            [74.576580000000035, 31.499826000000041],
            [74.577376000000072, 31.499261000000047],
            [74.577686000000028, 31.498871000000065],
            [74.578080000000057, 31.49831000000006],
            [74.578759000000048, 31.498396000000071],
            [74.57913700000006, 31.498396000000071],
            [74.580111000000045, 31.498461000000077],
            [74.580670000000055, 31.498084000000063],
            [74.581351000000041, 31.497795000000053],
            [74.582042000000058, 31.497644000000037],
            [74.582927000000041, 31.496967000000041],
            [74.583104000000048, 31.497021000000075],
            [74.583670000000041, 31.496810000000039],
            [74.583982000000049, 31.496585000000039],
            [74.584357000000068, 31.496530000000064],
            [74.584834000000058, 31.496435000000076],
            [74.58534700000007, 31.496393000000069],
            [74.585762000000045, 31.496394000000066],
            [74.586487000000034, 31.49646800000005],
            [74.586763000000076, 31.496394000000066],
            [74.587416000000076, 31.496108000000049],
            [74.588928000000067, 31.496571000000074],
            [74.588366000000065, 31.497152000000028],
            [74.588775000000055, 31.497096000000056],
            [74.589688000000081, 31.496959000000061],
            [74.590437000000065, 31.496849000000054],
            [74.591359000000068, 31.496659000000079],
            [74.593580000000031, 31.496122000000071],
            [74.594179000000054, 31.496844000000067],
            [74.594572000000085, 31.498099000000025],
            [74.594292000000053, 31.498236000000077],
            [74.594464000000073, 31.498802000000069],
            [74.594323000000031, 31.499038000000041],
            [74.594368000000031, 31.499831000000029],
            [74.594439000000079, 31.500567000000046],
            [74.594609000000048, 31.501024000000029],
            [74.59471700000006, 31.501482000000067],
            [74.596376000000078, 31.501122000000066],
            [74.597076000000072, 31.501067000000035],
            [74.597898000000043, 31.500980000000027],
            [74.597995000000083, 31.500664000000029],
            [74.598076000000049, 31.500512000000072],
            [74.598325000000045, 31.500231000000042],
            [74.598597000000041, 31.499983000000043],
            [74.599040000000059, 31.499907000000064],
            [74.599456000000032, 31.499821000000054],
            [74.59994800000004, 31.499789000000078],
            [74.600491000000034, 31.49988600000006],
            [74.600907000000063, 31.499983000000043],
            [74.601210000000037, 31.500026000000048],
            [74.601551000000029, 31.500102000000027],
            [74.601879000000054, 31.500188000000037],
            [74.602247000000034, 31.500350000000026],
            [74.602640000000065, 31.500500000000045],
            [74.602918000000045, 31.500523000000044],
            [74.602994000000081, 31.500166000000036],
            [74.603095000000053, 31.500080000000025],
            [74.603335000000072, 31.499929000000066],
            [74.603576000000032, 31.499854000000028],
            [74.60366300000004, 31.49962800000003],
            [74.603725416000032, 31.499460258000056],
            [74.603775000000041, 31.499327000000051],
            [74.603900000000067, 31.498995000000036],
            [74.60388400000005, 31.498709000000076],
            [74.604996000000028, 31.498478000000034],
            [74.606268000000057, 31.498391000000026],
            [74.606511000000069, 31.499386000000072],
            [74.60654500000004, 31.49979200000007],
            [74.607340000000079, 31.499713000000042],
            [74.607494000000031, 31.499582000000032],
            [74.607903000000078, 31.499625000000037],
            [74.608171000000084, 31.499658000000068],
            [74.608490000000074, 31.499735000000044],
            [74.608796000000041, 31.49974700000007],
            [74.608962000000076, 31.499880000000076],
            [74.609345000000076, 31.499803000000043],
            [74.609588000000031, 31.499815000000069],
            [74.609867000000065, 31.499576000000047],
            [74.610371000000043, 31.499190000000056],
            [74.610901000000069, 31.498672000000056],
            [74.611324000000081, 31.498225000000048],
            [74.611754000000076, 31.497680000000059],
            [74.612179000000083, 31.497135000000071],
            [74.612523000000067, 31.496604000000048],
            [74.612884000000065, 31.495862000000045],
            [74.61306600000006, 31.495410000000049],
            [74.613379000000066, 31.494540000000029],
            [74.613510000000076, 31.494152000000042],
            [74.613469000000066, 31.493224000000055],
            [74.613375000000076, 31.49249100000003],
            [74.613399000000072, 31.491725000000031],
            [74.613645000000076, 31.490788000000066],
            [74.613715100000036, 31.49056267900005],
            [74.613889025000049, 31.490003634000061],
            [74.613981000000081, 31.489708000000064],
            [74.614356000000043, 31.48895600000003],
            [74.614997000000074, 31.488084000000072],
            [74.615355000000079, 31.487669000000039],
            [74.615731000000039, 31.487212000000056],
            [74.616084000000058, 31.486752000000024],
            [74.616498000000036, 31.486380000000054],
            [74.617007000000058, 31.485948000000064],
            [74.617889000000048, 31.485561000000075],
            [74.61848400000008, 31.485443000000032],
            [74.618473000000051, 31.485139000000061],
            [74.618562000000054, 31.484999000000073],
            [74.618778000000077, 31.484999000000073],
            [74.621104000000059, 31.485083000000031],
            [74.622108000000082, 31.485145000000045],
            [74.621903000000032, 31.484346000000073],
            [74.621567000000084, 31.483099000000038],
            [74.62152100000003, 31.482938000000047],
            [74.621574000000066, 31.482820000000061],
            [74.621678000000031, 31.482724000000076],
            [74.621956000000068, 31.482627000000036],
            [74.622143000000051, 31.482563000000027],
            [74.622233000000051, 31.482327000000055],
            [74.622185000000059, 31.482221000000038],
            [74.621941000000049, 31.481944000000055],
            [74.622700000000066, 31.481562000000054],
            [74.622720000000072, 31.48184800000007],
            [74.622788000000071, 31.482252000000074],
            [74.622969000000069, 31.48264800000004],
            [74.623376000000064, 31.48330100000004],
            [74.624337000000082, 31.483155000000068],
            [74.624868000000049, 31.482862000000068],
            [74.625631000000055, 31.482619000000057],
            [74.625875000000065, 31.483629000000064],
            [74.624848000000043, 31.483810000000062],
            [74.624866000000054, 31.484150000000056],
            [74.624911000000054, 31.484586000000036],
            [74.624823000000049, 31.485025000000064],
            [74.624772000000064, 31.485531000000037],
            [74.624777000000051, 31.485856000000069],
            [74.626003000000082, 31.485781000000031],
            [74.62615500000004, 31.485694000000024],
            [74.626422000000048, 31.485619000000042],
            [74.626675000000034, 31.485586000000069],
            [74.626840000000072, 31.485597000000041],
            [74.626979000000063, 31.485597000000041],
            [74.627129000000082, 31.485739000000024],
            [74.627383000000066, 31.485923000000071],
            [74.628127000000063, 31.485380000000077],
            [74.62838000000005, 31.485617000000047],
            [74.628746000000035, 31.485905000000059],
            [74.62916800000005, 31.486226000000045],
            [74.629312000000084, 31.486302000000023],
            [74.629359000000079, 31.486331000000064],
            [74.629474000000073, 31.486350000000073],
            [74.629588000000069, 31.486347000000023],
            [74.629708000000051, 31.486292000000049],
            [74.630486000000076, 31.485926000000063],
            [74.63078500000006, 31.485655000000065],
            [74.630903000000046, 31.485757000000035],
            [74.631259000000057, 31.486076000000025],
            [74.631508000000053, 31.48626500000006],
            [74.631747000000075, 31.486499000000038],
            [74.632019000000071, 31.486451000000045],
            [74.632117000000051, 31.486038000000065],
            [74.632247000000064, 31.48593500000004],
            [74.632431000000054, 31.485860000000059],
            [74.632648000000074, 31.485776000000044],
            [74.632939000000079, 31.485570000000052],
            [74.633252000000084, 31.485431000000062],
            [74.633501000000081, 31.485393000000045],
            [74.634139000000062, 31.485412000000053],
            [74.634389000000056, 31.485523000000057],
            [74.635143000000085, 31.485347000000047],
            [74.635379000000057, 31.485226000000068],
            [74.63582800000006, 31.484921000000043],
            [74.63602300000008, 31.484857000000034],
            [74.636312000000032, 31.484709000000066],
            [74.636220000000037, 31.484534000000053],
            [74.636110000000031, 31.484478000000024],
            [74.635937000000069, 31.484442000000058],
            [74.635826000000066, 31.484340000000032],
            [74.635726000000034, 31.484239000000059],
            [74.635564000000045, 31.484239000000059],
            [74.635404000000051, 31.484258000000068],
            [74.635181000000046, 31.48435900000004],
            [74.63476600000007, 31.484598000000062],
            [74.634700000000066, 31.484561000000042],
            [74.63440600000007, 31.484286000000054],
            [74.634276000000057, 31.48413000000005],
            [74.633878000000038, 31.483701000000053],
            [74.633631000000037, 31.482679000000076],
            [74.633555000000058, 31.482502000000068],
            [74.633185000000083, 31.482400000000041],
            [74.633044000000041, 31.482344000000069],
            [74.632750000000044, 31.482214000000056],
            [74.632317000000057, 31.48218600000007],
            [74.631959000000052, 31.481983000000071],
            [74.631482000000062, 31.481568000000038],
            [74.631245000000035, 31.481284000000073],
            [74.630772000000036, 31.481724000000042],
            [74.630600000000072, 31.481844000000024],
            [74.630567000000042, 31.482056000000057],
            [74.630578000000071, 31.48237000000006],
            [74.63065400000005, 31.482508000000053],
            [74.630730000000028, 31.482703000000072],
            [74.630741000000057, 31.482861000000071],
            [74.63068700000008, 31.483216000000027],
            [74.630524000000037, 31.483394000000033],
            [74.63036100000005, 31.483467000000076],
            [74.63017700000006, 31.483512000000076],
            [74.629851000000031, 31.483626000000072],
            [74.629457000000059, 31.483818000000042],
            [74.629554000000041, 31.484091000000035],
            [74.629225000000076, 31.48412200000007],
            [74.628660000000082, 31.482931000000065],
            [74.628499000000033, 31.482524000000069],
            [74.628738000000055, 31.482412000000068],
            [74.629229000000066, 31.482176000000038],
            [74.62926200000004, 31.482129000000043],
            [74.629207000000065, 31.482035000000053],
            [74.628538000000049, 31.481029000000035],
            [74.627621000000033, 31.480034000000046],
            [74.628246000000047, 31.479201000000046],
            [74.629095000000063, 31.478759000000025],
            [74.628767000000039, 31.478372000000036],
            [74.62847800000003, 31.478020000000072],
            [74.628116000000034, 31.47755200000006],
            [74.627917000000082, 31.477298000000076],
            [74.627795000000049, 31.476833000000056],
            [74.628782000000058, 31.476370000000031],
            [74.629593000000057, 31.477232000000072],
            [74.630471000000057, 31.476673000000062],
            [74.630652000000055, 31.476999000000035],
            [74.63124700000003, 31.476556000000073],
            [74.631595000000061, 31.476778000000024],
            [74.632057000000032, 31.476810000000057],
            [74.632355000000075, 31.477063000000044],
            [74.632313000000067, 31.477518000000032],
            [74.632512000000077, 31.477848000000051],
            [74.63268800000003, 31.477848000000051],
            [74.632786000000067, 31.478147000000035],
            [74.634254000000055, 31.477607000000035],
            [74.634564000000069, 31.477584000000036],
            [74.63463500000006, 31.477874000000043],
            [74.636263000000042, 31.47763800000007],
            [74.636191000000053, 31.477325000000064],
            [74.636029000000065, 31.477081000000055],
            [74.636390000000063, 31.476827000000071],
            [74.636688000000049, 31.476661000000036],
            [74.636501000000067, 31.476307000000077],
            [74.636914000000047, 31.476065000000062],
            [74.636865000000057, 31.475922000000025],
            [74.637602000000072, 31.474883000000034],
            [74.638104000000055, 31.474103000000071],
            [74.638480000000072, 31.472827000000052],
            [74.638633000000084, 31.472551000000067],
            [74.638854000000038, 31.472374000000059],
            [74.639357000000075, 31.472208000000023],
            [74.639941000000078, 31.471811000000059],
            [74.64015100000006, 31.471491000000071],
            [74.640626000000054, 31.471348000000035],
            [74.640990000000045, 31.470953000000065],
            [74.641649000000029, 31.470438000000058],
            [74.642262000000073, 31.469992000000047],
            [74.643230000000074, 31.469429000000048],
            [74.643610000000081, 31.469192000000078],
            [74.643636000000072, 31.468988000000024],
            [74.64400100000006, 31.468966000000023],
            [74.644664000000034, 31.468406000000073],
            [74.645218000000057, 31.467915000000062],
            [74.645431000000031, 31.467768000000035],
            [74.645850000000053, 31.467099000000076],
            [74.646237000000042, 31.466857000000061],
            [74.646355000000028, 31.466693000000078],
            [74.64687200000003, 31.466201000000069],
            [74.647294000000045, 31.465984000000049],
            [74.647666000000072, 31.465636000000075],
            [74.64802700000007, 31.465043000000037],
            [74.64822300000003, 31.464421000000073],
            [74.648418000000049, 31.463877000000025],
            [74.648642000000052, 31.463437000000056],
            [74.649030000000039, 31.462987000000055],
            [74.649264000000073, 31.462581000000057],
            [74.649540000000059, 31.46193500000004],
            [74.649715000000072, 31.461119000000053],
            [74.650560000000041, 31.459744000000057],
            [74.651227000000063, 31.458931000000064],
            [74.651758000000029, 31.458140000000071],
            [74.652678000000037, 31.456841000000054],
            [74.653243000000032, 31.455257000000074],
            [74.653178000000082, 31.455008000000078],
            [74.653177000000085, 31.454717000000073],
            [74.653202000000078, 31.454430000000059],
            [74.652905000000032, 31.454451000000063],
            [74.652846000000068, 31.453891000000056],
            [74.651998000000049, 31.452791000000047],
            [74.651327000000038, 31.451910000000055],
            [74.650105000000053, 31.451956000000052],
            [74.649646000000075, 31.451835000000074],
            [74.64923900000008, 31.451802000000043],
            [74.648390000000063, 31.451788000000079],
            [74.64775700000007, 31.451677000000075],
            [74.647127000000069, 31.45157800000004],
            [74.646534000000031, 31.451346000000058],
            [74.645901000000038, 31.451133000000027],
            [74.645398000000057, 31.451040000000035],
            [74.644770000000051, 31.450908000000027],
            [74.644552000000033, 31.450820000000078],
            [74.643963000000042, 31.45055700000006],
            [74.643681000000072, 31.450403000000051],
            [74.642764000000057, 31.449873000000025],
            [74.642492363000031, 31.449689529000068],
            [74.642117000000042, 31.449436000000048],
            [74.640816000000029, 31.448465000000056],
            [74.640213000000074, 31.447997000000044],
            [74.639766000000066, 31.447431000000051],
            [74.639553000000035, 31.446855000000028],
            [74.639289000000076, 31.446513000000039],
            [74.638617000000067, 31.444063000000028],
            [74.638982000000055, 31.443125000000066],
            [74.639354000000083, 31.442062000000078],
            [74.639455000000055, 31.440593000000035],
            [74.640040000000056, 31.440334000000064],
            [74.640986000000055, 31.440166000000033],
            [74.64116000000007, 31.439535000000035],
            [74.642166000000032, 31.439246000000026],
            [74.642126000000076, 31.437605000000076],
            [74.642942000000062, 31.437463000000037],
            [74.642944000000057, 31.436645000000055],
            [74.643273000000079, 31.436505000000068],
            [74.643934000000058, 31.435987000000068],
            [74.644314000000065, 31.435697000000062],
            [74.644567000000052, 31.43558900000005],
            [74.645249000000035, 31.435257000000036],
            [74.64562600000005, 31.434905000000072],
            [74.645752000000073, 31.434831000000031],
            [74.645956000000069, 31.43480100000005],
            [74.646075000000053, 31.433779000000072],
            [74.646979000000044, 31.433901000000048],
            [74.647146000000077, 31.433241000000066],
            [74.647488000000067, 31.43327400000004],
            [74.647671000000059, 31.432344000000057],
            [74.648066000000028, 31.432279000000051],
            [74.648425000000032, 31.431855000000041],
            [74.649204000000054, 31.431519000000037],
            [74.650007000000073, 31.430968000000064],
            [74.651090000000067, 31.430070000000057],
            [74.651841000000047, 31.429481000000067],
            [74.652589000000034, 31.42859100000004],
            [74.654099000000031, 31.427755000000047],
            [74.654049000000043, 31.42747200000008],
            [74.654075000000034, 31.427277000000061],
            [74.654252000000042, 31.42709300000007],
            [74.654468000000065, 31.42697400000003],
            [74.654644000000076, 31.425888000000043],
            [74.654217000000074, 31.425717000000077],
            [74.65364500000004, 31.425409000000059],
            [74.651977000000045, 31.426436000000024],
            [74.651574000000039, 31.426062000000059],
            [74.65110100000004, 31.425724000000059],
            [74.650568000000078, 31.425367000000051],
            [74.650389000000075, 31.425216000000034],
            [74.65017400000005, 31.424989000000039],
            [74.64998200000008, 31.424698000000035],
            [74.649717000000066, 31.424515000000042],
            [74.64918700000004, 31.424289000000044],
            [74.648506000000054, 31.423999000000038],
            [74.648067000000083, 31.42367900000005],
            [74.647520000000043, 31.423098000000039],
            [74.647324000000083, 31.422833000000026],
            [74.64677800000004, 31.421946000000048],
            [74.646763000000078, 31.421718000000055],
            [74.646461000000045, 31.42093600000004],
            [74.645543000000032, 31.420418000000041],
            [74.645564193000041, 31.420139463000055],
            [74.645565942000076, 31.420116473000064],
            [74.645655000000033, 31.418946000000062],
            [74.64505900000006, 31.417909000000066],
            [74.644528000000037, 31.417791000000079],
            [74.644213000000036, 31.417856000000029],
            [74.643880000000081, 31.417288000000042],
            [74.643748000000073, 31.416670000000067],
            [74.643454000000077, 31.416088000000059],
            [74.642692000000068, 31.416172000000074],
            [74.642367000000036, 31.416098000000034],
            [74.641904000000068, 31.416299000000038],
            [74.641165000000058, 31.416554000000076],
            [74.640607000000045, 31.416740000000061],
            [74.640097000000083, 31.417115000000024],
            [74.639747000000057, 31.416711000000078],
            [74.639124000000038, 31.417059000000052],
            [74.63894700000003, 31.417419000000052],
            [74.638725000000079, 31.417654000000027],
            [74.63760700000006, 31.41787800000003],
            [74.637561000000062, 31.418360000000064],
            [74.636738000000037, 31.418358000000069],
            [74.635770000000036, 31.418811000000062],
            [74.634748000000059, 31.418778000000032],
            [74.633579000000054, 31.418668000000025],
            [74.632943000000068, 31.42061700000005],
            [74.632792000000052, 31.420944000000077],
            [74.632637000000045, 31.421646000000067],
            [74.632441000000028, 31.421812000000045],
            [74.632178000000067, 31.424579000000051],
            [74.631567000000075, 31.424702000000025],
            [74.631369000000063, 31.425558000000024],
            [74.62976400000008, 31.42548800000003],
            [74.628891000000067, 31.426042000000052],
            [74.627458000000047, 31.429451000000029],
            [74.62651900000003, 31.429333000000042],
            [74.622557047000043, 31.430071412000075],
            [74.622037461000048, 31.428638053000043],
            [74.621935295000071, 31.428356214000075],
            [74.621844028000055, 31.427943344000028],
            [74.621582000000046, 31.426758000000063],
            [74.621031000000073, 31.424361000000033],
            [74.619541000000083, 31.421254000000033],
            [74.619403000000034, 31.419826000000057],
            [74.617307000000039, 31.420366000000058],
            [74.615878000000066, 31.420909000000051],
            [74.615710000000036, 31.421143000000029],
            [74.614710466000076, 31.42157402600003],
            [74.614365000000078, 31.421723000000043],
            [74.612040000000036, 31.422351000000049],
            [74.610854000000074, 31.422475000000077],
            [74.610862000000054, 31.422769000000073],
            [74.60974600000003, 31.42333700000006],
            [74.609098000000074, 31.423553000000027],
            [74.608854000000065, 31.423132000000066],
            [74.608796000000041, 31.422656000000075],
            [74.608771000000047, 31.422158000000024],
            [74.608687000000032, 31.42200900000006],
            [74.60829700000005, 31.421770000000038],
            [74.607764000000032, 31.421463000000074],
            [74.607538000000034, 31.421271000000047],
            [74.607391026000073, 31.420998258000054],
            [74.60733700000003, 31.420898000000079],
            [74.607216000000051, 31.420639000000051],
            [74.607162000000073, 31.420549000000051],
            [74.607095000000072, 31.42047000000008],
            [74.606407000000047, 31.420211000000052],
            [74.605392000000052, 31.420010000000048],
            [74.605365000000063, 31.419628000000046],
            [74.604842000000076, 31.419697000000042],
            [74.603861000000052, 31.420027000000061],
            [74.603375000000028, 31.420266000000026],
            [74.602644000000055, 31.420504000000051],
            [74.602417000000059, 31.420435000000055],
            [74.602456000000075, 31.420378000000028],
            [74.60245100000003, 31.419980000000066],
            [74.60119300000008, 31.420003000000065],
            [74.602772000000073, 31.417835000000025],
            [74.60283000000004, 31.417347000000063],
            [74.60263800000007, 31.415578000000039],
            [74.60050300000006, 31.415544000000068],
            [74.599348000000077, 31.41592600000007],
            [74.597417000000064, 31.416044000000056],
            [74.596516000000065, 31.416287000000068],
            [74.596352000000081, 31.415104000000042],
            [74.59628500000008, 31.414322000000027],
            [74.596258000000034, 31.413523000000055],
            [74.59583200000003, 31.412572000000068],
            [74.595577000000048, 31.412582000000043],
            [74.595086000000038, 31.41190400000005],
            [74.594212000000084, 31.410679000000073],
            [74.594197000000065, 31.410442000000046],
            [74.594177000000059, 31.409886000000029],
            [74.59424800000005, 31.409140000000036],
            [74.594204000000047, 31.408419000000038],
            [74.594133000000056, 31.407870000000059],
            [74.593931000000055, 31.407505000000072],
            [74.593450000000075, 31.406971000000055],
            [74.59299800000008, 31.406485000000032],
            [74.592628000000047, 31.40600100000006],
            [74.592391000000077, 31.405609000000027],
            [74.592193000000066, 31.405273000000079],
            [74.591718000000071, 31.403867000000048],
            [74.591387850000046, 31.403177071000073],
            [74.591328000000033, 31.403052000000059],
            [74.591004000000055, 31.401957000000039],
            [74.590751000000068, 31.401444000000026],
            [74.590432000000078, 31.400897000000043],
            [74.590180000000032, 31.400931000000071],
            [74.588956000000053, 31.399703000000045],
            [74.587953000000084, 31.398686000000055],
            [74.58720500000004, 31.398001000000079],
            [74.586954000000048, 31.397036000000071],
            [74.586841000000049, 31.39618500000006],
            [74.586670000000083, 31.394942000000071],
            [74.586735000000033, 31.394039000000078],
            [74.586740000000077, 31.393108000000041],
            [74.586722000000066, 31.392373000000077],
            [74.586506000000043, 31.391379000000029],
            [74.586517000000072, 31.390938000000062],
            [74.586528000000044, 31.390603000000056],
            [74.586419000000035, 31.390051000000028],
            [74.586268000000075, 31.389261000000033],
            [74.58609400000006, 31.388457000000074],
            [74.586172000000033, 31.387955000000034],
            [74.586224000000072, 31.387539000000061],
            [74.586303000000044, 31.386963000000037],
            [74.586328000000037, 31.38657100000006],
            [74.586271000000067, 31.385985000000062],
            [74.586173000000031, 31.385073000000034],
            [74.585338000000036, 31.38259400000004],
            [74.584549000000038, 31.380158000000051],
            [74.583448000000033, 31.379720000000077],
            [74.583484000000055, 31.379077000000052],
            [74.580796000000078, 31.377788000000066],
            [74.580541000000039, 31.377624000000026],
            [74.580414000000076, 31.377296000000058],
            [74.580020000000047, 31.377132000000074],
            [74.577819000000034, 31.375970000000052],
            [74.57835700000004, 31.374956000000054],
            [74.57732800000008, 31.374647000000039],
            [74.575560000000053, 31.374488000000042],
            [74.575117000000034, 31.374364000000071],
            [74.574285000000032, 31.374169000000052],
            [74.573269000000039, 31.374019000000033],
            [74.572279000000037, 31.373504000000025],
            [74.571943000000033, 31.373125000000073],
            [74.571588000000077, 31.371520000000032],
            [74.570944000000054, 31.371497000000033],
            [74.56991000000005, 31.372254000000055],
            [74.569536000000085, 31.372371000000044],
            [74.568505757000082, 31.372139922000031],
            [74.567445000000077, 31.371902000000034],
            [74.567429000000061, 31.371493000000044],
            [74.56597000000005, 31.370716000000073],
            [74.56535400000007, 31.371109000000047],
            [74.564663000000053, 31.370737000000076],
            [74.563958000000071, 31.370337000000063],
            [74.563680000000033, 31.370132000000069],
            [74.56309600000003, 31.369746000000077],
            [74.562652000000071, 31.369573000000059],
            [74.56212800000003, 31.369401000000039],
            [74.561309000000051, 31.369181000000026],
            [74.560914000000082, 31.368925000000047],
            [74.560563000000059, 31.368751000000032],
            [74.560134000000062, 31.368600000000072],
            [74.559239000000048, 31.368303000000026],
            [74.558892000000071, 31.368097000000034],
            [74.558478000000036, 31.367948000000069],
            [74.557999000000052, 31.367605000000026],
            [74.557399000000032, 31.367323000000056],
            [74.556396000000063, 31.367062000000033],
            [74.556152000000054, 31.366991000000041],
            [74.55512600000003, 31.366625000000056],
            [74.553889000000083, 31.366497000000038],
            [74.553193000000078, 31.365959000000032],
            [74.552846000000045, 31.365588000000059],
            [74.551656000000037, 31.36427800000007],
            [74.551698000000044, 31.364107000000047],
            [74.551631000000043, 31.36271400000004],
            [74.550938000000031, 31.361848000000066],
            [74.550698000000068, 31.361158000000046],
            [74.550444000000084, 31.361192000000074],
            [74.55006300000008, 31.360173000000032],
            [74.550132000000076, 31.359480000000076],
            [74.550148000000036, 31.358927000000051],
            [74.550129000000084, 31.358515000000068],
            [74.550632000000064, 31.357077000000061],
            [74.551477000000034, 31.356996000000038],
            [74.552483000000052, 31.356779000000074],
            [74.553081000000077, 31.356382000000053],
            [74.55279500000006, 31.355679000000066],
            [74.552727000000061, 31.355507000000046],
            [74.552364000000068, 31.354992000000038],
            [74.551948000000039, 31.354002000000037],
            [74.551839434000044, 31.353897400000051],
            [74.526545000000056, 31.357864999000071],
            [74.520933430000071, 31.355314516000078],
            [74.515809248000039, 31.352985552000064],
            [74.515434000000084, 31.352815000000078],
            [74.504828000000032, 31.348775000000046],
            [74.497253000000057, 31.345240000000047],
            [74.493718000000058, 31.358371000000034],
            [74.493659402000048, 31.358429587000046],
            [74.488667000000078, 31.363421000000073],
            [74.481597000000079, 31.363926000000049],
            [74.471496000000059, 31.363926000000049],
            [74.470971575000078, 31.362483701000031],
            [74.467456000000084, 31.352815000000078],
            [74.458179507000068, 31.351931608000029],
            [74.456850000000031, 31.35180500000007],
            [74.456508064000047, 31.350665212000024],
            [74.455335000000048, 31.34675500000003],
            [74.451800000000048, 31.330089000000044],
            [74.450260725000078, 31.323161500000026],
            [74.449780000000032, 31.320998000000031],
            [74.449267616000043, 31.320302648000052],
            [74.442709000000036, 31.311402000000044],
            [74.442709000000036, 31.306802822000066],
            [74.442709000000036, 31.304122955000025],
            [74.442709000000036, 31.302817000000061],
            [74.440667482000038, 31.293969748000052],
            [74.439910621000081, 31.290689766000071],
            [74.439703981000036, 31.289728356000069],
            [74.439679000000069, 31.289685999000028],
            [74.430316589000029, 31.293553319000068],
            [74.416448000000059, 31.299282000000062],
            [74.41603219600006, 31.299282000000062],
            [74.41494257800008, 31.299282000000062],
            [74.407439087000057, 31.299282000000062],
            [74.406852000000072, 31.299282000000062],
            [74.405809627000053, 31.298906693000049],
            [74.403725205000057, 31.298156196000036],
            [74.402928968000083, 31.297869510000055],
            [74.399528369000052, 31.296645122000029],
            [74.39422600000006, 31.294736000000057],
            [74.383620000000064, 31.296251000000041],
            [74.381139363000045, 31.29476261800005],
            [74.373520000000042, 31.29019100000005],
            [74.360389000000055, 31.283121000000051],
            [74.357265882000036, 31.282600194000054],
            [74.351298000000043, 31.28160500000007],
            [74.340142000000071, 31.287533000000053],
            [74.335137000000032, 31.29019100000005],
            [74.334317739000085, 31.289371631000051],
            [74.334311881000076, 31.289365772000053],
            [74.327562000000057, 31.282615000000078],
            [74.316451000000029, 31.282615000000078],
            [74.314760368000066, 31.282953350000071],
            [74.313974702000053, 31.282842272000039],
            [74.312841224000067, 31.283266102000027],
            [74.30164132200008, 31.285401869000054],
            [74.300513521000084, 31.285803385000065],
            [74.297793912000031, 31.286347271000068],
            [74.296319141000083, 31.286636042000055],
            [74.296317410000029, 31.286642552000046],
            [74.293725000000052, 31.287161000000026],
            [74.284873103000052, 31.291310069000076],
            [74.277564000000041, 31.294736000000057],
            [74.277528008000047, 31.294710808000048],
            [74.270675501000085, 31.289914528000054],
            [74.267463000000078, 31.287666000000058],
            [74.26140300000003, 31.28160500000007],
            [74.255847000000074, 31.271505000000047],
            [74.25285520500006, 31.271329012000024],
            [74.247262000000035, 31.271000000000072],
            [74.241574575000072, 31.267840042000046],
            [74.241566836000061, 31.267835742000045],
            [74.238171000000079, 31.265949000000035],
            [74.230090000000075, 31.259384000000068],
            [74.226050000000043, 31.254334000000028],
            [74.225955158000033, 31.254402976000051],
            [74.21494000000007, 31.262414000000035],
            [74.209889000000032, 31.274030000000039],
            [74.209427378000044, 31.274122325000064],
            [74.20483900000005, 31.275040000000047],
            [74.204716219000034, 31.275162759000068],
            [74.199378519000049, 31.28049949900003],
            [74.199283000000037, 31.280595000000062],
            [74.195748000000037, 31.289181000000042],
            [74.188257540000052, 31.293175912000038],
            [74.178732937000063, 31.287161000000026],
            [74.176680379000061, 31.286687333000032],
            [74.176474541000061, 31.286639832000048],
            [74.173279835000073, 31.285938577000024],
            [74.172012000000052, 31.285646000000042],
            [74.165446000000031, 31.282110000000046],
            [74.159908361000078, 31.291172587000062],
            [74.159908289000043, 31.291172704000076],
            [74.159891000000073, 31.291201000000058],
            [74.159018563000075, 31.291255521000039],
            [74.159018241000069, 31.291255541000055],
            [74.151810000000069, 31.291706000000033],
            [74.145750000000078, 31.292211000000066],
            [74.142720000000054, 31.297261000000049],
            [74.138322749000054, 31.296528206000062],
            [74.133629000000042, 31.295746000000065],
            [74.132114000000058, 31.283626000000027],
            [74.129084000000034, 31.275040000000047],
            [74.128011804000039, 31.273729564000064],
            [74.12553213800004, 31.270698922000065],
            [74.119993000000079, 31.263929000000076],
            [74.11650028400004, 31.268731916000036],
            [74.11332012400004, 31.273105030000067],
            [74.113261047000037, 31.273186268000075],
            [74.111968559000047, 31.274963599000046],
            [74.102895187000058, 31.276979682000047],
            [74.093300187000068, 31.277484682000079],
            [74.093255114000044, 31.277563520000058],
            [74.09322700000007, 31.277565000000038],
            [74.093173744000069, 31.277658153000061],
            [74.093160663000049, 31.277650478000055],
            [74.092690374000028, 31.278476522000062],
            [74.091222048000077, 31.281044821000023],
            [74.091225149000081, 31.281066507000048],
            [74.091206000000057, 31.281100000000038],
            [74.09221589100008, 31.288163245000078],
            [74.092217000000062, 31.288171000000034],
            [74.089268742000058, 31.290135857000053],
            [74.089186000000041, 31.29019100000005],
            [74.089179383000044, 31.290135857000053],
            [74.087671000000057, 31.277565000000038],
            [74.075045000000046, 31.273525000000063],
            [74.073087266000073, 31.275645878000034],
            [74.068985000000055, 31.28009000000003],
            [74.06740295700007, 31.282265236000057],
            [74.061678676000042, 31.290135857000053],
            [74.06090400000005, 31.291201000000058],
            [74.048784000000069, 31.302817000000061],
            [74.041485823000073, 31.306895406000024],
            [74.041479837000054, 31.306898751000062],
            [74.041479773000049, 31.30689878700008],
            [74.032800604000045, 31.311748926000064],
            [74.032800166000072, 31.311748612000031],
            [74.03276076800006, 31.31177062900008],
            [74.032457223000051, 31.311503110000046],
            [74.032113206000076, 31.313720106000062],
            [74.03151500000007, 31.317308000000025],
            [74.025228000000084, 31.326981000000046],
            [74.01288056900006, 31.346452138000075],
            [74.012654807000047, 31.346808151000062],
            [74.012653000000057, 31.346811000000059],
            [74.006849000000045, 31.358902000000057],
            [74.014104000000032, 31.368091000000049],
            [74.01414761400008, 31.368091000000049],
            [74.023686000000055, 31.368091000000049],
            [74.024260000000083, 31.368091000000049],
            [74.024260500000082, 31.368091000000049],
            [74.024261000000081, 31.368091000000049],
            [74.030725127000039, 31.366280766000045],
            [74.031524904000037, 31.366056794000031],
            [74.036352000000079, 31.364705000000072],
            [74.04941000000008, 31.364705000000072],
            [74.055331726000077, 31.367806815000051],
            [74.059566500000074, 31.370025000000055],
            [74.068046236000043, 31.370631142000036],
            [74.06852075900008, 31.370665062000057],
            [74.073108500000046, 31.370993000000055],
            [74.075434237000081, 31.38169206200007],
            [74.07552650000008, 31.382116500000052],
            [74.080647239000029, 31.390081702000032],
            [74.080647553000063, 31.390082190000044],
            [74.084232500000041, 31.395658500000025],
            [74.082297526000048, 31.401461921000077],
            [74.082297053000048, 31.40146184200006],
            [74.08229700000004, 31.401462000000038],
            [74.076494000000082, 31.400495000000035],
            [74.073592000000076, 31.408233000000052],
            [74.073623349000059, 31.408499446000064],
            [74.074559500000078, 31.416455000000042],
            [74.075012042000083, 31.416869840000061],
            [74.080248499000049, 31.421670038000059],
            [74.086166500000047, 31.427095000000065],
            [74.088619312000048, 31.430216803000064],
            [74.09148650000003, 31.433866000000023],
            [74.094873129000064, 31.438321925000025],
            [74.100676000000078, 31.445957000000078],
            [74.106347451000033, 31.454818344000046],
            [74.108414363000065, 31.458047785000076],
            [74.108414500000038, 31.458048000000076],
            [74.108414863000064, 31.458047785000076],
            [74.108415000000036, 31.458048000000076],
            [74.121473000000037, 31.450310000000059],
            [74.125245060000054, 31.442294006000054],
            [74.125342000000046, 31.442088000000069],
            [74.125104839000073, 31.435215721000077],
            [74.124858000000074, 31.428063000000066],
            [74.128243632000078, 31.419357948000027],
            [74.128243816000065, 31.419357474000037],
            [74.133080000000064, 31.426612000000034],
            [74.140334000000053, 31.426612000000034],
            [74.140334500000051, 31.426612000000034],
            [74.140334500000051, 31.435801000000026],
            [74.143236500000057, 31.444023000000072],
            [74.145153850000042, 31.446625057000062],
            [74.150007500000072, 31.453212000000065],
            [74.16209850000007, 31.456114000000071],
            [74.169527566000056, 31.460653961000048],
            [74.170804000000032, 31.461434000000054],
            [74.17999350000008, 31.467238000000066],
            [74.178832285000055, 31.469689275000064],
            [74.176302091000082, 31.475030408000066],
            [74.175640500000043, 31.476427000000058],
            [74.18289500000003, 31.484165000000075],
            [74.18310277900008, 31.48631195400003],
            [74.183718424000062, 31.492673337000042],
            [74.183718433000081, 31.492673431000071],
            [74.184346000000062, 31.499158000000079],
            [74.184202754000069, 31.500733340000068],
            [74.184053521000067, 31.502374516000032],
            [74.183957298000053, 31.503432717000067],
            [74.18337850000006, 31.509798000000046],
            [74.185163464000084, 31.511237472000062],
            [74.188144043000079, 31.513641139000072],
            [74.196470174000069, 31.520355689000041],
            [74.198371500000064, 31.521889000000044],
            [74.199555108000084, 31.523254718000032],
            [74.20451199300004, 31.52897426800007],
            [74.205581752000057, 31.530208620000053],
            [74.205818724000039, 31.530482053000071],
            [74.205905801000029, 31.530582527000035],
            [74.21655252100004, 31.542867350000051],
            [74.216754172000037, 31.543100028000026],
            [74.216754051000066, 31.543100044000028],
            [74.216771290000054, 31.543119936000039],
            [74.220863239000039, 31.547841378000044],
            [74.223521000000062, 31.550908000000049],
            [74.224016792000043, 31.551354195000044],
            [74.22975371900003, 31.556517404000033],
            [74.232267776000072, 31.558779764000064],
            [74.238030500000036, 31.56396600000005],
            [74.238030565000031, 31.563966029000028],
            [74.240934398000036, 31.56527279900007],
            [74.247703500000057, 31.568319000000031],
            [74.243350500000076, 31.582345000000032],
            [74.246420894000039, 31.587718123000059],
            [74.24908137400007, 31.592373911000038],
            [74.249154000000033, 31.592501000000027],
            [74.244317500000079, 31.604109000000051],
            [74.242138514000032, 31.608194387000026],
            [74.24044850000007, 31.61136300000004],
            [74.248465167000063, 31.611995941000032],
            [74.249552521000055, 31.612081791000037],
            [74.254154500000084, 31.612445131000072],
            [74.258826500000055, 31.612814000000071],
            [74.260761500000058, 31.618134000000055],
            [74.26275722500003, 31.620247206000045],
            [74.268983500000047, 31.626840000000072],
            [74.268983500000047, 31.630967147000035],
            [74.268983500000047, 31.634094000000061],
            [74.269445670000039, 31.635282671000027],
            [74.272368500000084, 31.642800000000079],
            [74.272857913000053, 31.644050686000071],
            [74.273306106000064, 31.645196033000047],
            [74.276721500000065, 31.653924000000075],
            [74.279588507000028, 31.667029832000026],
            [74.280107000000044, 31.669400000000053],
            [74.288240529000063, 31.669690698000068],
            [74.293649000000073, 31.669884000000025],
            [74.296431041000062, 31.669287819000033],
            [74.305990224000084, 31.667239322000057],
            [74.307191000000046, 31.666982000000075],
            [74.310211752000043, 31.662618807000058],
            [74.31040134400007, 31.662344959000052],
            [74.312935995000032, 31.658683894000035],
            [74.315897000000064, 31.654407000000049],
            [74.324226708000083, 31.651683759000036],
            [74.327421002000051, 31.650639506000061],
            [74.341045500000064, 31.646185500000058],
            [74.344313846000034, 31.648636736000071],
            [74.358457000000044, 31.659244000000058],
            [74.359491977000062, 31.660328247000052],
            [74.363005863000069, 31.664009411000052],
            [74.368613379000067, 31.66988387400005],
            [74.368613500000038, 31.669884000000025],
            [74.368613879000065, 31.66988387400005],
            [74.368614000000036, 31.669884000000025],
            [74.372776790000046, 31.668496403000063],
            [74.377320000000054, 31.666982000000075],
            [74.383992305000049, 31.660556495000037],
            [74.390378000000055, 31.654407000000049],
            [74.39068646100003, 31.654151422000041],
            [74.395823036000081, 31.649895472000026],
            [74.395823242000063, 31.649895301000072],
            [74.395823562000032, 31.649895036000032],
            [74.39814152200006, 31.647974472000044],
            [74.40239026200004, 31.644454145000054],
            [74.40730456600005, 31.640382360000046],
            [74.407304783000029, 31.640382180000074],
            [74.415982666000048, 31.645904543000029],
            [74.416202512000041, 31.646044447000065],
            [74.417944500000033, 31.64715300000006],
            [74.419374058000074, 31.648582486000066],
            [74.437680774000057, 31.666888279000034],
            [74.437774500000046, 31.666982000000075],
            [74.450832500000047, 31.671819000000028],
            [74.448203250000063, 31.674837765000063],
            [74.447065423000083, 31.676144157000067],
            [74.445386877000033, 31.678071374000069],
            [74.441808413000047, 31.682179976000043],
            [74.437774500000046, 31.686811500000033],
            [74.438257487000044, 31.692614838000054],
            [74.438257500000077, 31.692615000000046],
            [74.438257987000043, 31.692614838000054],
            [74.438258000000076, 31.692615000000046],
            [74.446963778000054, 31.689713074000053],
            [74.446963889000074, 31.689713037000047],
            [74.457119500000033, 31.690681000000041],
            [74.457119611000053, 31.69068119700006],
            [74.459154194000064, 31.694297923000079],
            [74.460208305000037, 31.696171736000053],
            [74.460879165000051, 31.697364272000073],
            [74.46147250000007, 31.698419000000058],
            [74.467868330000044, 31.700246380000067],
            [74.469127277000041, 31.700606079000067],
            [74.471629500000063, 31.701321000000064],
            [74.484843883000053, 31.710249976000057],
            [74.489523500000075, 31.713412000000062],
            [74.489899546000061, 31.713431145000072],
            [74.489829644000054, 31.713469498000052],
            [74.489901111000052, 31.713431225000079],
            [74.492437363000079, 31.712072978000037],
            [74.49266700000004, 31.711950000000058],
            [74.495190000000036, 31.711117000000058],
            [74.496841000000074, 31.710596000000066],
            [74.498297000000036, 31.705711000000065],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "106",
      properties: { name: "Layyah", count: 804 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.924287703000061, 31.351494733000038],
            [70.933813041000064, 31.347353909000049],
            [70.935919543000068, 31.346872414000075],
            [70.942277940000054, 31.347910523000053],
            [70.951426496000067, 31.349404170000071],
            [70.956918590000043, 31.350300881000067],
            [70.959016224000038, 31.350596819000032],
            [70.962047969000082, 31.350905285000067],
            [70.967905213000051, 31.351855236000063],
            [70.981876664000083, 31.35366765200007],
            [70.988302180000062, 31.354371308000054],
            [71.003112793000071, 31.354454997000062],
            [71.017053922000059, 31.354448594000075],
            [71.040055920000043, 31.355279430000053],
            [71.050285646000077, 31.355665594000072],
            [71.056314471000064, 31.355398511000033],
            [71.068547658000057, 31.354380976000073],
            [71.075433520000047, 31.354906924000034],
            [71.084793951000051, 31.354603374000078],
            [71.088382486000057, 31.35447386900006],
            [71.091121990000033, 31.354792133000046],
            [71.108050520000063, 31.351500618000046],
            [71.111566994000043, 31.348254128000065],
            [71.117930985000044, 31.345079946000055],
            [71.121093968000082, 31.341919163000057],
            [71.12550830400005, 31.337505371000077],
            [71.137788115000035, 31.339722451000057],
            [71.143645379000077, 31.341622403000031],
            [71.146402451000029, 31.342572614000062],
            [71.155277219000084, 31.345678563000035],
            [71.167610807000074, 31.340323198000078],
            [71.177511495000033, 31.338412909000056],
            [71.180440302000079, 31.338679546000037],
            [71.194455946000062, 31.344619968000075],
            [71.196078268000065, 31.317713709000031],
            [71.196269948000065, 31.310931868000068],
            [71.196702000000073, 31.307733000000042],
            [71.198840233000055, 31.304233177000071],
            [71.202881894000029, 31.299982021000062],
            [71.226420197000039, 31.269650753000064],
            [71.229222279000055, 31.266721646000065],
            [71.231122000000084, 31.264562500000068],
            [71.232617785000059, 31.261540611000044],
            [71.238139758000045, 31.253051923000044],
            [71.245707000000039, 31.240643000000034],
            [71.261092805000033, 31.22827602600006],
            [71.262625000000071, 31.227225000000033],
            [71.271610543000065, 31.231718253000054],
            [71.281877000000065, 31.235976000000051],
            [71.285219324000082, 31.229849840000043],
            [71.28839054000008, 31.213283313000034],
            [71.289377827000067, 31.208413700000051],
            [71.291820466000047, 31.191929307000066],
            [71.292378001000031, 31.188138000000038],
            [71.293545000000051, 31.163636000000054],
            [71.304351932000031, 31.150965942000028],
            [71.310463001000073, 31.143801000000053],
            [71.327382000000057, 31.137383000000057],
            [71.345467000000042, 31.144967000000065],
            [71.388054000000068, 31.148468000000037],
            [71.406577586000083, 31.148489597000037],
            [71.422621465000077, 31.149205908000056],
            [71.432404972000029, 31.149652563000075],
            [71.455728000000079, 31.150801000000058],
            [71.470313000000033, 31.163636000000054],
            [71.484897000000046, 31.184055000000058],
            [71.49539800000008, 31.207974000000036],
            [71.495397999000033, 31.224309000000062],
            [71.50531600000005, 31.233059000000026],
            [71.524568000000045, 31.235976000000051],
            [71.529399580000074, 31.236942482000075],
            [71.542070000000081, 31.239477000000079],
            [71.575323000000083, 31.23714300000006],
            [71.610910000000047, 31.230726000000061],
            [71.626251241000034, 31.219838681000056],
            [71.646718586000077, 31.205305331000034],
            [71.680815740000071, 31.179521753000074],
            [71.690251000000046, 31.172387000000072],
            [71.725838000000067, 31.177054000000055],
            [71.754424000000029, 31.177054000000055],
            [71.764925000000062, 31.167720000000031],
            [71.773738500000036, 31.16108150000008],
            [71.772319000000039, 31.158743000000072],
            [71.768677000000082, 31.140532000000064],
            [71.770857184000079, 31.136027566000053],
            [71.782335000000046, 31.112305000000049],
            [71.797359000000029, 31.081347000000051],
            [71.80110144300005, 31.07687528200006],
            [71.815981495000074, 31.059089999000037],
            [71.816025000000081, 31.059038000000044],
            [71.815981495000074, 31.05818189200005],
            [71.81466000000006, 31.032177000000047],
            [71.814659606000077, 31.032175924000057],
            [71.807831000000078, 31.013511000000051],
            [71.79690400000004, 31.000308000000075],
            [71.780059000000051, 30.982553000000053],
            [71.782791000000032, 30.962976000000026],
            [71.783512636000069, 30.962408928000059],
            [71.789164000000028, 30.957968000000051],
            [71.810562000000061, 30.937936000000036],
            [71.826041000000032, 30.929286000000047],
            [71.822399000000075, 30.915173000000038],
            [71.809652000000028, 30.900604000000044],
            [71.812383000000068, 30.884669000000031],
            [71.818757000000062, 30.877385000000061],
            [71.829684000000043, 30.872377000000029],
            [71.843797000000052, 30.872377000000029],
            [71.844357001000049, 30.87230700300006],
            [71.858366000000046, 30.870556000000079],
            [71.85663670200006, 30.867206023000051],
            [71.855965993000041, 30.865906543000051],
            [71.851081000000079, 30.856442000000072],
            [71.850261997000075, 30.855734777000066],
            [71.841065000000071, 30.847792000000027],
            [71.839460451000036, 30.846117728000024],
            [71.830594000000076, 30.836866000000043],
            [71.817847000000029, 30.83322400000003],
            [71.80841530500004, 30.836504250000075],
            [71.807375000000036, 30.836866000000043],
            [71.804644000000053, 30.826850000000036],
            [71.802711769000041, 30.812169719000053],
            [71.802367000000061, 30.809550000000058],
            [71.798410969000031, 30.796243057000027],
            [71.797359000000029, 30.792704000000072],
            [71.783701000000065, 30.78860700000007],
            [71.774450190000039, 30.788655026000072],
            [71.765946000000042, 30.78860700000007],
            [71.765121417000046, 30.788341000000059],
            [71.765121299000043, 30.788341015000071],
            [71.751832000000036, 30.784054000000026],
            [71.740450000000067, 30.772217000000069],
            [71.738359127000081, 30.76489858900004],
            [71.735898000000077, 30.756283000000053],
            [71.716776000000038, 30.748088000000052],
            [71.707636980000075, 30.740461077000077],
            [71.703118000000075, 30.735340000000065],
            [71.700603532000059, 30.728482892000045],
            [71.698110000000042, 30.721682000000044],
            [71.701752000000056, 30.698463000000061],
            [71.698348352000039, 30.694492491000062],
            [71.698111077000078, 30.69421569900004],
            [71.698090931000081, 30.694192198000053],
            [71.696289000000036, 30.692090000000064],
            [71.673526000000038, 30.686171000000058],
            [71.668062000000077, 30.680252000000053],
            [71.672160000000076, 30.661586000000057],
            [71.672101703000067, 30.65902501000005],
            [71.671704000000034, 30.641554000000042],
            [71.661688000000083, 30.620612000000051],
            [71.65871358000004, 30.617360724000036],
            [71.658713222000074, 30.617360333000079],
            [71.65602926300005, 30.614059199000053],
            [71.651217000000031, 30.60968500000007],
            [71.641235035000079, 30.609775465000041],
            [71.630730000000028, 30.60968500000007],
            [71.609655459000066, 30.615246802000058],
            [71.597950000000083, 30.618336000000056],
            [71.592032000000074, 30.602401000000043],
            [71.589300000000037, 30.591475000000059],
            [71.573365000000081, 30.585100990000058],
            [71.569645844000036, 30.584993758000053],
            [71.561092101000042, 30.584660218000067],
            [71.546848985000054, 30.584104829000069],
            [71.542666805000067, 30.584308756000041],
            [71.52601750000008, 30.583735000000047],
            [71.508436306000078, 30.591426554000066],
            [71.490181565000057, 30.599451561000024],
            [71.474740662000045, 30.607871749000026],
            [71.473372307000034, 30.609205472000042],
            [71.45135300000004, 30.620612000000051],
            [71.448769609000067, 30.622538504000033],
            [71.432482940000057, 30.634908221000046],
            [71.42045947500003, 30.644121675000065],
            [71.401979191000066, 30.661522121000075],
            [71.397217087000058, 30.665835577000053],
            [71.391633318000061, 30.672331303000078],
            [71.380779543000074, 30.683435348000046],
            [71.369859500000075, 30.695732000000078],
            [71.354290138000067, 30.71014798300007],
            [71.343208668000045, 30.720408442000064],
            [71.339639727000076, 30.723465515000044],
            [71.337517336000076, 30.72542764700006],
            [71.335000059000038, 30.72747503800008],
            [71.332982500000071, 30.729877000000045],
            [71.328512605000071, 30.732174194000038],
            [71.32611111600005, 30.733212842000057],
            [71.310706929000048, 30.74132499500007],
            [71.302191582000034, 30.745193650000033],
            [71.300203000000067, 30.746722000000034],
            [71.297604849000038, 30.747486976000062],
            [71.285132247000035, 30.746641370000077],
            [71.280405601000041, 30.746722000000034],
            [71.273077416000035, 30.750445103000061],
            [71.268824281000036, 30.754881366000063],
            [71.263326000000063, 30.758559000000048],
            [71.259237651000035, 30.759325526000055],
            [71.241220939000073, 30.762569686000063],
            [71.234958056000039, 30.764003862000038],
            [71.218954903000053, 30.765343975000064],
            [71.21688800000004, 30.765843000000075],
            [71.202457354000046, 30.761758677000046],
            [71.197767000000056, 30.760380000000055],
            [71.184351745000072, 30.763502892000076],
            [71.181937757000071, 30.764241060000074],
            [71.17910100000006, 30.764933000000042],
            [71.164159188000042, 30.759219567000059],
            [71.163863809000077, 30.743930941000031],
            [71.163905083000031, 30.741724488000045],
            [71.164077000000077, 30.731243000000063],
            [71.15397820000004, 30.72004531400006],
            [71.150418500000058, 30.715764000000036],
            [71.141428282000049, 30.698217816000067],
            [71.145044200000029, 30.69156505400008],
            [71.150419000000056, 30.682529000000045],
            [71.148652561000063, 30.676446989000056],
            [71.144768209000063, 30.666126539000061],
            [71.134842493000065, 30.65789421900007],
            [71.125379000000066, 30.652026000000035],
            [71.123405670000068, 30.651240312000027],
            [71.106257000000028, 30.645652000000041],
            [71.102121054000065, 30.646679196000036],
            [71.081235159000073, 30.652686189000065],
            [71.074710693000043, 30.651198081000075],
            [71.066611684000065, 30.650329383000042],
            [71.059364000000073, 30.649294000000054],
            [71.046524013000067, 30.642596555000068],
            [71.03887700000007, 30.638823000000059],
            [71.035430484000074, 30.639136361000055],
            [71.024182388000042, 30.640087750000077],
            [71.01172405300008, 30.653429801000073],
            [71.009591178000051, 30.654207867000025],
            [71.005564574000061, 30.654939725000077],
            [70.996082000000058, 30.655212000000063],
            [70.976126157000067, 30.654772150000042],
            [70.970586500000081, 30.654757000000075],
            [70.965097805000084, 30.660932167000055],
            [70.959660000000042, 30.667050000000074],
            [70.949591819000034, 30.667050000000074],
            [70.940994000000046, 30.667050000000074],
            [70.929294937000066, 30.665357477000043],
            [70.922505728000033, 30.66263119000007],
            [70.909151627000028, 30.661779670000044],
            [70.905478690000052, 30.661833959000035],
            [70.90275100000008, 30.661586000000057],
            [70.873286848000077, 30.666095002000077],
            [70.851761000000067, 30.657034000000067],
            [70.840366752000079, 30.654139167000039],
            [70.822168000000033, 30.651115000000061],
            [70.803957000000082, 30.648383000000024],
            [70.801064000000054, 30.646511000000032],
            [70.799307142000032, 30.652592521000031],
            [70.797931596000069, 30.657354096000063],
            [70.795629958000063, 30.665321423000023],
            [70.794793613000081, 30.668216507000068],
            [70.793579000000079, 30.672421000000043],
            [70.79425890300007, 30.677471326000045],
            [70.794892270000048, 30.682083445000046],
            [70.795291312000074, 30.685189477000051],
            [70.795980806000046, 30.690261639000028],
            [70.796835811000051, 30.696495279000032],
            [70.797241636000081, 30.699627103000068],
            [70.797663000000057, 30.702757000000076],
            [70.79799749700004, 30.706294982000031],
            [70.79742539700004, 30.70964814000007],
            [70.797228919000077, 30.715336155000045],
            [70.79711439600004, 30.718668071000025],
            [70.796981495000068, 30.722522585000036],
            [70.796876603000044, 30.72556475500005],
            [70.79668335000008, 30.731169636000061],
            [70.796509180000044, 30.736401174000036],
            [70.796312098000044, 30.741937022000059],
            [70.796129978000067, 30.747219025000049],
            [70.796036173000061, 30.749952525000026],
            [70.795749164000028, 30.75424109800008],
            [70.790437510000061, 30.762272408000058],
            [70.787264512000036, 30.768311987000061],
            [70.783048032000067, 30.76819864600003],
            [70.776763512000059, 30.769246043000066],
            [70.770462363000036, 30.770296211000073],
            [70.762178160000076, 30.771676881000076],
            [70.754072523000048, 30.772840233000068],
            [70.741657000000032, 30.775097000000073],
            [70.741870611000081, 30.777766682000049],
            [70.742104204000043, 30.780683083000042],
            [70.742517473000078, 30.78585107400005],
            [70.742720620000057, 30.788389972000061],
            [70.742995288000031, 30.791821989000027],
            [70.743288859000074, 30.79499505900003],
            [70.743403708000073, 30.796927120000078],
            [70.743814133000058, 30.802056542000059],
            [70.744263072000081, 30.807414988000062],
            [70.744628431000081, 30.812233528000036],
            [70.74488468100003, 30.815436104000071],
            [70.744868868000083, 30.819554088000075],
            [70.743768957000043, 30.822224953000045],
            [70.740817028000038, 30.828930639000077],
            [70.736990000000048, 30.835187000000076],
            [70.738622423000038, 30.83875869700006],
            [70.739260748000049, 30.840376805000062],
            [70.741074000000083, 30.844521000000043],
            [70.75040800000005, 30.841604000000075],
            [70.756825000000049, 30.841021000000069],
            [70.770827000000054, 30.841021000000069],
            [70.774327000000028, 30.848605000000077],
            [70.779163788000062, 30.854132284000059],
            [70.78249500000004, 30.857939000000044],
            [70.78469934900005, 30.864307680000024],
            [70.787745000000029, 30.873107000000061],
            [70.785708943000031, 30.876887964000048],
            [70.783661000000052, 30.88069100000007],
            [70.776664134000043, 30.878362336000066],
            [70.769256047000056, 30.887305078000054],
            [70.767464549000067, 30.889469667000071],
            [70.764894745000049, 30.89257464800005],
            [70.763119784000082, 30.89471925600003],
            [70.761655201000053, 30.898789403000023],
            [70.760650527000053, 30.902305868000042],
            [70.759675851000054, 30.905717343000049],
            [70.757992000000058, 30.91161100000005],
            [70.759294952000062, 30.913032390000069],
            [70.760542774000044, 30.914393638000035],
            [70.761703669000042, 30.915660058000071],
            [70.765282726000066, 30.919539130000032],
            [70.767123416000061, 30.921572458000071],
            [70.770711175000031, 30.925486343000046],
            [70.773314977000041, 30.928326829000071],
            [70.775220463000039, 30.930405524000037],
            [70.776676966000082, 30.932170389000078],
            [70.783545751000077, 30.935238842000047],
            [70.791249805000064, 30.938897808000036],
            [70.791776781000067, 30.942168778000052],
            [70.792658593000056, 30.948340334000079],
            [70.793579000000079, 30.95478200000008],
            [70.792057021000062, 30.962392763000025],
            [70.790578542000048, 30.969786004000071],
            [70.790079000000048, 30.972284000000059],
            [70.779154403000064, 30.971942259000059],
            [70.776167606000058, 30.971848826000041],
            [70.77358859800006, 30.971768150000059],
            [70.771414520000064, 30.971690263000028],
            [70.768341744000054, 30.978220455000042],
            [70.767336068000077, 30.982185256000037],
            [70.76935192500008, 30.984113376000039],
            [70.770864946000074, 30.985560544000066],
            [70.773849968000036, 30.988415647000068],
            [70.776604257000031, 30.991049847000056],
            [70.778406601000029, 30.992773959000033],
            [70.780215246000068, 30.995157576000054],
            [70.78026086300008, 30.995217694000075],
            [70.780743004000044, 30.998914478000074],
            [70.781071844000053, 31.001435833000073],
            [70.781482843000049, 31.004587141000059],
            [70.781833190000043, 31.007814202000077],
            [70.780760781000083, 31.012815903000046],
            [70.779963205000058, 31.016245452000078],
            [70.779147981000051, 31.019750887000043],
            [70.778514222000069, 31.022472176000065],
            [70.778004864000081, 31.024666251000042],
            [70.776077000000043, 31.03295600000007],
            [70.776078229000063, 31.035956682000062],
            [70.776494917000036, 31.044494535000069],
            [70.776596611000059, 31.047304005000058],
            [70.776665883000078, 31.049216796000053],
            [70.776896253000075, 31.055578018000062],
            [70.777131083000029, 31.06184674800005],
            [70.777252119000082, 31.065400596000075],
            [70.77760403700006, 31.075122035000049],
            [70.77783002700005, 31.081362277000039],
            [70.778039484000033, 31.087146044000065],
            [70.778397580000046, 31.09703354800007],
            [70.778487337000058, 31.101740204000066],
            [70.778822773000059, 31.108747251000068],
            [70.778994000000068, 31.113464000000079],
            [70.772577000000069, 31.115798000000041],
            [70.768931681000083, 31.12178635500004],
            [70.767977195000071, 31.123307572000044],
            [70.764350084000057, 31.129233990000046],
            [70.765788900000075, 31.133056736000071],
            [70.767862083000068, 31.138564940000037],
            [70.768210707000037, 31.146050613000057],
            [70.768541002000063, 31.150768842000048],
            [70.76631885900008, 31.15497229500005],
            [70.763915902000065, 31.157219088000033],
            [70.762237277000054, 31.161706836000064],
            [70.756753779000064, 31.170703692000075],
            [70.755430195000031, 31.173433253000042],
            [70.753342922000058, 31.177601654000057],
            [70.751552304000029, 31.181183145000034],
            [70.749825000000044, 31.184638000000064],
            [70.750464234000049, 31.189531148000071],
            [70.751803513000084, 31.194313557000044],
            [70.752770575000056, 31.199071011000058],
            [70.754478210000059, 31.207438247000027],
            [70.754872330000069, 31.211243331000048],
            [70.755659000000037, 31.213224000000025],
            [70.756745986000055, 31.21720964800005],
            [70.758254371000078, 31.222740443000077],
            [70.75971372500004, 31.228091452000058],
            [70.761017672000037, 31.234455117000039],
            [70.763707792000048, 31.242566055000054],
            [70.764800427000068, 31.246742855000036],
            [70.76616000000007, 31.251728000000071],
            [70.763819480000052, 31.254068520000033],
            [70.761100174000035, 31.25678782600005],
            [70.759159000000068, 31.258729000000073],
            [70.74807450000003, 31.259312000000079],
            [70.743541969000034, 31.266284919000043],
            [70.740490000000079, 31.270980000000066],
            [70.747198195000067, 31.277946149000059],
            [70.752583119000064, 31.283610223000039],
            [70.760989714000061, 31.29239018800007],
            [70.763903413000037, 31.29527669600003],
            [70.766743000000076, 31.298399000000074],
            [70.76664813800005, 31.302847363000069],
            [70.76736709000005, 31.305552420000026],
            [70.76769250600006, 31.30542655000005],
            [70.767787000000055, 31.305390000000045],
            [70.783690000000036, 31.310028000000045],
            [70.785678000000075, 31.320630000000051],
            [70.785678000000075, 31.339847000000077],
            [70.786079167000082, 31.344658585000047],
            [70.78608158600008, 31.344687597000075],
            [70.78634100000005, 31.347799000000066],
            [70.795098280000047, 31.350679640000067],
            [70.795098392000057, 31.350679676000027],
            [70.802747108000062, 31.353121398000042],
            [70.80274714400008, 31.353121410000028],
            [70.806983376000062, 31.360208808000039],
            [70.80711463800003, 31.360405694000065],
            [70.80713455800003, 31.360435573000075],
            [70.827465324000059, 31.353851418000033],
            [70.849612079000053, 31.350299028000052],
            [70.872923203000084, 31.357908437000049],
            [70.886911928000075, 31.364917600000069],
            [70.892928808000079, 31.369674510000038],
            [70.902094805000047, 31.379503801000055],
            [70.905530207000083, 31.380696210000053],
            [70.920233050000036, 31.385905750000063],
            [70.931266707000077, 31.385319471000059],
            [70.931874325000081, 31.372892398000033],
            [70.926060629000062, 31.363238833000025],
            [70.924287703000061, 31.351494733000038],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "107",
      properties: { name: "Lodhran", count: 338 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.628822706000051, 29.911727964000079],
            [71.641838000000064, 29.906522000000052],
            [71.646217185000069, 29.906690551000054],
            [71.646941498000047, 29.90671842900008],
            [71.654413000000034, 29.907006000000024],
            [71.66263500000008, 29.904104000000075],
            [71.672791000000075, 29.900235000000066],
            [71.679924439000047, 29.902216238000051],
            [71.681497000000036, 29.902653000000043],
            [71.681497000000036, 29.907220452000047],
            [71.681497000000036, 29.907720393000034],
            [71.681497000000036, 29.910391000000061],
            [71.676660000000084, 29.916195000000073],
            [71.682948000000067, 29.921031000000028],
            [71.674726000000078, 29.928286000000071],
            [71.675951677000057, 29.932883871000058],
            [71.676660000000084, 29.935541000000057],
            [71.691653000000031, 29.945214000000078],
            [71.692790224000078, 29.945432673000028],
            [71.696348400000034, 29.946116861000064],
            [71.698577642000032, 29.946545514000036],
            [71.704228000000057, 29.947632000000056],
            [71.703744000000029, 29.955370000000073],
            [71.710031000000072, 29.955370000000073],
            [71.710032000000069, 29.955370000000073],
            [71.71777000000003, 29.947632000000056],
            [71.722870819000036, 29.948155232000033],
            [71.722871020000071, 29.948155029000077],
            [71.729228251000052, 29.948806862000026],
            [71.729230646000076, 29.94880710800004],
            [71.736156132000076, 29.949517207000042],
            [71.737599000000046, 29.940861000000041],
            [71.737599000000046, 29.93650800000006],
            [71.743887000000029, 29.937475000000063],
            [71.749207000000069, 29.93650800000006],
            [71.750478465000072, 29.93650800000006],
            [71.754527000000053, 29.93650800000006],
            [71.758396000000062, 29.942795000000046],
            [71.760030314000062, 29.936665057000027],
            [71.760330000000067, 29.935541000000057],
            [71.751625000000047, 29.931672000000049],
            [71.752673926000057, 29.928734746000032],
            [71.754043000000081, 29.924901000000034],
            [71.758879000000036, 29.91861300000005],
            [71.771446092000076, 29.913493394000056],
            [71.771938000000034, 29.913293000000067],
            [71.785963000000038, 29.908457000000055],
            [71.798538000000065, 29.899751000000037],
            [71.804366734000041, 29.902548946000024],
            [71.810629000000063, 29.905555000000049],
            [71.819335000000081, 29.912326000000064],
            [71.820418538000069, 29.912790396000048],
            [71.832877000000053, 29.918130000000076],
            [71.834126672000082, 29.918130000000076],
            [71.842550000000074, 29.918130000000076],
            [71.848723950000078, 29.91504244500004],
            [71.853190000000041, 29.912809000000038],
            [71.863830000000064, 29.90894000000003],
            [71.864745442000071, 29.90894000000003],
            [71.869150000000047, 29.90894000000003],
            [71.872286093000071, 29.909320222000076],
            [71.885110000000054, 29.910875000000033],
            [71.894783000000075, 29.91522800000007],
            [71.896718000000078, 29.911359000000061],
            [71.892849000000069, 29.906038000000024],
            [71.887528000000032, 29.903137000000072],
            [71.885110000000054, 29.89733300000006],
            [71.88545381800003, 29.891492364000044],
            [71.885594000000083, 29.889111000000071],
            [71.891398000000038, 29.884275000000059],
            [71.895086610000078, 29.883692546000077],
            [71.900358248000032, 29.882860121000078],
            [71.90058700000003, 29.882824000000028],
            [71.903177764000077, 29.883341992000055],
            [71.910260000000051, 29.884758000000033],
            [71.910389193000071, 29.884690325000065],
            [71.920416000000046, 29.87943800000005],
            [71.920625044000076, 29.878131770000039],
            [71.922202847000051, 29.868272744000024],
            [71.922351000000049, 29.867347000000052],
            [71.917039784000053, 29.872326051000073],
            [71.914612000000034, 29.874602000000039],
            [71.910260000000051, 29.877020000000073],
            [71.904456000000039, 29.877987000000076],
            [71.903394909000042, 29.877848593000067],
            [71.901372992000063, 29.877584857000045],
            [71.894553268000038, 29.876695301000041],
            [71.89447888400008, 29.876685598000051],
            [71.893332000000044, 29.876536000000044],
            [71.890320742000029, 29.873094180000066],
            [71.889947000000063, 29.872667000000035],
            [71.888450424000041, 29.868178045000036],
            [71.88801200000006, 29.866863000000023],
            [71.887555994000081, 29.85820546900004],
            [71.887551544000075, 29.858120987000063],
            [71.887528000000032, 29.857674000000031],
            [71.887718110000037, 29.857553037000059],
            [71.891318692000084, 29.855262065000034],
            [71.892848000000072, 29.854289000000051],
            [71.892848488000084, 29.854289325000025],
            [71.895750000000078, 29.856223000000057],
            [71.896660601000065, 29.857133225000041],
            [71.896711660000051, 29.857184262000033],
            [71.898169000000053, 29.858641000000034],
            [71.900103000000058, 29.861060000000066],
            [71.900172309000084, 29.861071548000041],
            [71.903380550000065, 29.86160607000005],
            [71.90590700000007, 29.862027000000069],
            [71.907841000000076, 29.856707000000029],
            [71.907841000000076, 29.851870000000076],
            [71.907841000000076, 29.847732980000046],
            [71.907841000000076, 29.846067000000062],
            [71.912194000000056, 29.84461600000003],
            [71.914612000000034, 29.845583000000033],
            [71.919932000000074, 29.846067000000062],
            [71.921867000000077, 29.846550000000036],
            [71.925253000000055, 29.848969000000068],
            [71.92979426900007, 29.848142694000046],
            [71.930573000000038, 29.848001000000068],
            [71.930231744000082, 29.846550969000077],
            [71.928638000000035, 29.839779000000078],
            [71.928638000000035, 29.837361000000044],
            [71.930089000000066, 29.834459000000038],
            [71.93204238800007, 29.834909830000072],
            [71.936376000000052, 29.835910000000069],
            [71.941622825000081, 29.832762339000055],
            [71.946049000000073, 29.830107000000055],
            [71.950312490000044, 29.82291267100004],
            [71.950312331000077, 29.822912010000039],
            [71.951976039000044, 29.820104260000051],
            [71.953787000000034, 29.817048000000057],
            [71.957771337000054, 29.812399836000054],
            [71.958198741000047, 29.811901223000064],
            [71.959591000000046, 29.810277000000042],
            [71.965878000000032, 29.80447300000003],
            [71.973872310000047, 29.80099758800003],
            [71.973893643000054, 29.800988314000051],
            [71.977002000000084, 29.799637000000075],
            [71.989093000000082, 29.795768000000066],
            [71.989093000000082, 29.790984647000073],
            [71.989093000000082, 29.787062000000049],
            [71.985224000000073, 29.788030000000049],
            [71.980871000000036, 29.790932000000055],
            [71.974900415000036, 29.791727913000045],
            [71.973617000000047, 29.791899000000058],
            [71.970715000000041, 29.787062000000049],
            [71.967330000000061, 29.776906000000054],
            [71.965051474000063, 29.773614622000025],
            [71.964582764000056, 29.772937560000059],
            [71.96297700000008, 29.77061800000007],
            [71.958140000000071, 29.768684000000064],
            [71.955543390000059, 29.767385471000068],
            [71.952337000000057, 29.765782000000058],
            [71.947017000000073, 29.76529800000003],
            [71.941724263000083, 29.762496492000025],
            [71.939921165000044, 29.761541911000052],
            [71.939878646000068, 29.761519401000044],
            [71.939881098000058, 29.761518000000024],
            [71.938800098000058, 29.760946000000047],
            [71.937349098000084, 29.75369100000006],
            [71.934930098000052, 29.746436000000074],
            [71.937844799000061, 29.74557881800007],
            [71.937843890000067, 29.745577586000024],
            [71.943147000000067, 29.74401800000004],
            [71.943148256000029, 29.744019130000027],
            [71.943152098000041, 29.74401800000004],
            [71.94798909800005, 29.748371000000077],
            [71.953788000000031, 29.748371000000077],
            [71.953792098000065, 29.748371000000077],
            [71.953792098000065, 29.745469000000071],
            [71.953792098000065, 29.740554561000067],
            [71.953792098000065, 29.740542768000068],
            [71.953792054000075, 29.740542724000079],
            [71.953788000000031, 29.740538650000076],
            [71.953788000000031, 29.739451237000026],
            [71.953787000000034, 29.739182000000028],
            [71.956206000000066, 29.73579600000005],
            [71.959107000000074, 29.736280000000079],
            [71.960075000000074, 29.739182000000028],
            [71.962493000000052, 29.744502000000068],
            [71.966846000000032, 29.745469000000071],
            [71.968244384000059, 29.745469000000071],
            [71.968780000000038, 29.745469000000071],
            [71.972649000000047, 29.736280000000079],
            [71.977002000000084, 29.729025000000036],
            [71.982806000000039, 29.730476000000067],
            [71.989513338000052, 29.729687141000056],
            [71.991028000000028, 29.729509000000064],
            [71.990544000000057, 29.722738000000049],
            [71.991004642000064, 29.715373445000068],
            [71.991028000000028, 29.715000000000032],
            [71.991438151000068, 29.708851971000058],
            [71.991460725000081, 29.708513593000077],
            [71.991512000000057, 29.707745000000045],
            [71.992962000000034, 29.699523000000056],
            [71.99387992700008, 29.699452345000054],
            [71.999250000000075, 29.699039000000028],
            [72.007472000000064, 29.698072000000025],
            [72.011781416000076, 29.695088242000054],
            [72.01375900000005, 29.693719000000044],
            [72.017145000000085, 29.69323600000007],
            [72.020543462000035, 29.693613520000042],
            [72.025850000000048, 29.694203000000073],
            [72.036007000000041, 29.691301000000067],
            [72.036102654000047, 29.691165473000069],
            [72.040659308000045, 29.684709362000035],
            [72.041810000000055, 29.683079000000077],
            [72.047614000000067, 29.681628000000046],
            [72.052534152000078, 29.681935748000058],
            [72.055352000000084, 29.682112000000075],
            [72.062123000000042, 29.682112000000075],
            [72.069862000000057, 29.679210000000069],
            [72.069287436000081, 29.675954268000055],
            [72.068411000000083, 29.670988000000079],
            [72.06115600000004, 29.673406000000057],
            [72.056803000000059, 29.674374000000057],
            [72.053418000000079, 29.669537000000048],
            [72.053829502000042, 29.667993547000037],
            [72.055256252000049, 29.662642130000052],
            [72.055352000000084, 29.662283000000059],
            [72.06115600000004, 29.653577000000041],
            [72.06242078300005, 29.65363203000004],
            [72.066494208000051, 29.653809263000028],
            [72.072280000000035, 29.65406100000007],
            [72.084371000000033, 29.653577000000041],
            [72.089528457000029, 29.656390334000037],
            [72.089529083000059, 29.656390676000058],
            [72.089530651000075, 29.656391531000054],
            [72.089691000000073, 29.656479000000047],
            [72.09210900000005, 29.66179900000003],
            [72.094527000000085, 29.665184000000068],
            [72.096048258000053, 29.667139710000072],
            [72.097913000000062, 29.669537000000048],
            [72.098397000000034, 29.662283000000059],
            [72.103220455000042, 29.659313894000036],
            [72.104684000000077, 29.658413000000053],
            [72.104837835000069, 29.658303127000067],
            [72.111455000000035, 29.653577000000041],
            [72.107648875000052, 29.648343455000031],
            [72.107586000000083, 29.648257000000058],
            [72.10457445600008, 29.647252979000029],
            [72.103868825000063, 29.647017728000037],
            [72.101782000000071, 29.646322000000055],
            [72.100426179000067, 29.642706788000055],
            [72.097429000000034, 29.634715000000028],
            [72.097164879000047, 29.630483597000079],
            [72.096946000000059, 29.626977000000068],
            [72.102749000000074, 29.618755000000078],
            [72.10591335600003, 29.614641402000075],
            [72.107586000000083, 29.612467000000038],
            [72.103233000000046, 29.605213000000049],
            [72.10079853700006, 29.60103881200007],
            [72.099848000000065, 29.599409000000037],
            [72.09491683400006, 29.599703423000051],
            [72.094916519000037, 29.599704024000062],
            [72.091387226000052, 29.600096001000054],
            [72.088713098000028, 29.600393000000054],
            [72.08303152700006, 29.603452603000051],
            [72.079846098000075, 29.605168000000049],
            [72.075777051000046, 29.606330422000042],
            [72.072684098000082, 29.607214000000056],
            [72.07268416800008, 29.607212524000033],
            [72.072999947000028, 29.600580254000079],
            [72.073025098000073, 29.600052000000062],
            [72.081551098000034, 29.596301000000039],
            [72.07890285700006, 29.591193124000029],
            [72.076777098000036, 29.587093000000038],
            [72.076529838000056, 29.584372776000066],
            [72.076082736000046, 29.579453995000051],
            [72.075970056000074, 29.578214356000046],
            [72.075892797000051, 29.577364394000028],
            [72.07541309800007, 29.572087000000067],
            [72.078482098000052, 29.561515000000043],
            [72.074288157000069, 29.55825294400006],
            [72.069274098000051, 29.554353000000049],
            [72.06006609800005, 29.553671000000065],
            [72.060062835000053, 29.553671136000048],
            [72.060061000000076, 29.553671000000065],
            [72.05187600000005, 29.554012000000057],
            [72.04300900000004, 29.557081000000039],
            [72.041986000000065, 29.56356100000005],
            [72.041061998000032, 29.565266673000053],
            [72.037552000000062, 29.571746000000076],
            [72.034824000000071, 29.576862000000062],
            [72.030436574000078, 29.578616713000031],
            [72.028006130000051, 29.579588748000049],
            [72.020505098000058, 29.574816000000055],
            [72.017095098000084, 29.565949000000046],
            [72.014367098000037, 29.560151000000076],
            [72.014303617000053, 29.559860897000078],
            [72.014289010000084, 29.559794143000033],
            [72.01197909800004, 29.549238000000059],
            [72.011974000000066, 29.549238000000059],
            [72.005841098000076, 29.549238000000059],
            [72.00038409800004, 29.54821400000003],
            [72.000379000000066, 29.54821400000003],
            [71.993222098000047, 29.54821400000003],
            [71.987424098000076, 29.547873000000038],
            [71.987104006000038, 29.54785417100004],
            [71.981627098000047, 29.547532000000047],
            [71.98162650200004, 29.547532265000029],
            [71.981622000000073, 29.547532000000047],
            [71.975483000000054, 29.550261000000035],
            [71.972414000000072, 29.555035000000032],
            [71.972081653000032, 29.556695956000056],
            [71.970708000000059, 29.56356100000005],
            [71.966276893000042, 29.567311397000026],
            [71.964600434000033, 29.566473014000053],
            [71.960823098000049, 29.564584000000025],
            [71.955026098000076, 29.562197000000026],
            [71.949948225000071, 29.558389293000062],
            [71.94956909800004, 29.558105000000069],
            [71.949565912000082, 29.558106434000024],
            [71.949564000000066, 29.558105000000069],
            [71.942743000000064, 29.561174000000051],
            [71.939333000000033, 29.565949000000046],
            [71.933247453000035, 29.565949000000046],
            [71.930471098000055, 29.565949000000046],
            [71.930466000000081, 29.565949000000046],
            [71.923649717000046, 29.566289764000032],
            [71.916488098000059, 29.561856000000034],
            [71.912055098000053, 29.557423000000028],
            [71.915465098000084, 29.552648000000033],
            [71.916944553000064, 29.549689451000063],
            [71.917590931000063, 29.548396853000042],
            [71.919557943000029, 29.544463309000037],
            [71.919558098000039, 29.544463000000064],
            [71.925009286000034, 29.544803699000056],
            [71.925014098000076, 29.544804000000056],
            [71.93149409800003, 29.537983000000054],
            [71.936269098000082, 29.533550000000048],
            [71.94308909800003, 29.528093000000069],
            [71.945136098000035, 29.523319000000072],
            [71.945818098000075, 29.515475000000038],
            [71.94308909800003, 29.512064000000066],
            [71.943084949000081, 29.512065186000029],
            [71.943084000000056, 29.512064000000066],
            [71.93701175800004, 29.513799381000069],
            [71.933535000000063, 29.514793000000054],
            [71.929443000000049, 29.51922600000006],
            [71.92398600000007, 29.52502400000003],
            [71.917506931000048, 29.530820167000059],
            [71.91410109800006, 29.530139000000077],
            [71.913760098000068, 29.522978000000023],
            [71.91410109800006, 29.515816000000029],
            [71.91410109800006, 29.511041000000034],
            [71.913763304000042, 29.509520681000026],
            [71.912737098000036, 29.504902000000072],
            [71.907280098000058, 29.500469000000066],
            [71.907276200000069, 29.500469975000044],
            [71.907275000000084, 29.500469000000066],
            [71.902501820000055, 29.501662295000074],
            [71.902499188000036, 29.50166295300005],
            [71.901819000000046, 29.501833000000033],
            [71.900454000000082, 29.509677000000067],
            [71.900795000000073, 29.518203000000028],
            [71.900795000000073, 29.530139000000077],
            [71.900795000000073, 29.535874620000072],
            [71.900795000000073, 29.53661900000003],
            [71.894657000000052, 29.543099000000041],
            [71.893903632000047, 29.543444252000029],
            [71.886472000000083, 29.546850000000063],
            [71.884846973000037, 29.546850000000063],
            [71.880338098000038, 29.546850000000063],
            [71.878578468000057, 29.544308312000055],
            [71.877269098000056, 29.542417000000057],
            [71.872835098000053, 29.535937000000047],
            [71.873858098000028, 29.527070000000037],
            [71.875677417000077, 29.523250016000077],
            [71.876016531000062, 29.522537988000067],
            [71.876508453000042, 29.521505109000032],
            [71.877269098000056, 29.519908000000044],
            [71.876587098000073, 29.515134000000046],
            [71.876582036000059, 29.515134253000042],
            [71.876582000000042, 29.515134000000046],
            [71.872713440000041, 29.515327400000047],
            [71.86976100000004, 29.515475000000038],
            [71.863627098000052, 29.515475000000038],
            [71.863622000000078, 29.515475000000038],
            [71.858507000000031, 29.51922600000006],
            [71.857106885000064, 29.522900469000035],
            [71.855778000000043, 29.526388000000054],
            [71.854755000000068, 29.532186000000024],
            [71.851345000000038, 29.536960000000079],
            [71.846418229000051, 29.537174180000079],
            [71.84350100000006, 29.53730100000007],
            [71.835321098000065, 29.53730100000007],
            [71.826904178000063, 29.536977309000065],
            [71.826454098000056, 29.536960000000079],
            [71.818610098000079, 29.534232000000031],
            [71.81751545000003, 29.53299142700007],
            [71.814361290000079, 29.529416795000031],
            [71.813494098000035, 29.528434000000061],
            [71.811107098000036, 29.520249000000035],
            [71.808523906000062, 29.516115893000062],
            [71.807697098000062, 29.514793000000054],
            [71.802922098000067, 29.507972000000052],
            [71.798489098000061, 29.504902000000072],
            [71.792691098000034, 29.501492000000042],
            [71.792686366000055, 29.501492215000042],
            [71.789834098000028, 29.501622000000054],
            [71.785183000000075, 29.501833000000033],
            [71.778364310000029, 29.505242762000023],
            [71.776998000000049, 29.505926000000045],
            [71.772906000000035, 29.512405000000058],
            [71.768200609000075, 29.516292166000028],
            [71.765062000000057, 29.518885000000068],
            [71.758241000000055, 29.524683000000039],
            [71.752814107000063, 29.526967655000078],
            [71.751761000000045, 29.527411000000029],
            [71.735050000000058, 29.522978000000023],
            [71.734683809000046, 29.522001312000043],
            [71.733004000000051, 29.517521000000045],
            [71.733004000000051, 29.510359000000051],
            [71.733004000000051, 29.507255459000078],
            [71.733004000000051, 29.504561000000024],
            [71.734368000000075, 29.500128000000075],
            [71.727547000000072, 29.497741000000076],
            [71.721645203000037, 29.490953068000067],
            [71.720727000000068, 29.489897000000042],
            [71.715270000000032, 29.482394000000056],
            [71.715952000000073, 29.476937000000078],
            [71.707830969000042, 29.481505406000053],
            [71.705039000000056, 29.48307600000004],
            [71.702015091000078, 29.484398718000079],
            [71.699582000000078, 29.485463000000038],
            [71.694467000000031, 29.479324000000076],
            [71.683347901000047, 29.470227184000066],
            [71.683212000000083, 29.470116000000075],
            [71.682051948000037, 29.47069626900003],
            [71.680460641000082, 29.471492256000033],
            [71.678438000000028, 29.472504000000072],
            [71.675973601000067, 29.473416641000028],
            [71.669230000000084, 29.475914000000046],
            [71.659681000000035, 29.478983000000028],
            [71.649449000000061, 29.478642000000036],
            [71.646065892000081, 29.47742422400006],
            [71.633106687000065, 29.472759457000052],
            [71.632397000000083, 29.472504000000072],
            [71.628803499000071, 29.470168132000026],
            [71.623526997000056, 29.466738270000064],
            [71.618756000000076, 29.463637000000062],
            [71.617733000000044, 29.459203000000059],
            [71.616209036000043, 29.452891441000077],
            [71.615345000000048, 29.449313000000075],
            [71.615347893000035, 29.449217805000046],
            [71.61554132100008, 29.442852783000035],
            [71.615687000000037, 29.438059000000067],
            [71.609207000000083, 29.434648000000038],
            [71.606478000000038, 29.437036000000035],
            [71.597952000000078, 29.442833000000064],
            [71.593714623000039, 29.446093476000044],
            [71.593519000000072, 29.446244000000036],
            [71.586357000000078, 29.441810000000032],
            [71.579536000000076, 29.436695000000043],
            [71.578133845000082, 29.435199094000041],
            [71.57497791600008, 29.431832152000027],
            [71.574421000000029, 29.431238000000064],
            [71.572082893000072, 29.427861072000042],
            [71.57135100000005, 29.426804000000061],
            [71.55975600000005, 29.423053000000039],
            [71.54645898800004, 29.419486019000033],
            [71.546455081000033, 29.419484971000031],
            [71.545773000000054, 29.419302000000073],
            [71.542704000000072, 29.426122000000078],
            [71.544750000000079, 29.43226100000004],
            [71.534860000000037, 29.441469000000041],
            [71.527357000000052, 29.44453800000008],
            [71.524571986000069, 29.445073833000038],
            [71.518490000000043, 29.446244000000036],
            [71.510987000000057, 29.438059000000067],
            [71.509282000000042, 29.431579000000056],
            [71.508600000000058, 29.423735000000079],
            [71.508337972000049, 29.42124534900006],
            [71.507918000000075, 29.417255000000068],
            [71.507577000000083, 29.410093000000074],
            [71.50757645300007, 29.410092624000072],
            [71.504707558000064, 29.408120620000034],
            [71.502120000000048, 29.406342000000052],
            [71.497005000000058, 29.403955000000053],
            [71.495481799000061, 29.403616566000039],
            [71.49086600000004, 29.402591000000029],
            [71.485409000000061, 29.402591000000029],
            [71.48381334700008, 29.401336939000032],
            [71.480635000000063, 29.398839000000066],
            [71.481658000000039, 29.393042000000037],
            [71.482340000000079, 29.392018000000064],
            [71.48575100000005, 29.386903000000075],
            [71.48575100000005, 29.381105000000048],
            [71.485750000000053, 29.381105154000068],
            [71.485750000000053, 29.381105000000048],
            [71.481317000000047, 29.381787000000031],
            [71.480635000000063, 29.372238000000038],
            [71.474155000000053, 29.37257900000003],
            [71.467334000000051, 29.382128000000023],
            [71.459832000000063, 29.38792600000005],
            [71.458453423000037, 29.391175112000042],
            [71.455057000000068, 29.399180000000058],
            [71.45491233000007, 29.399632147000034],
            [71.452329000000077, 29.407706000000076],
            [71.444485000000043, 29.415891000000045],
            [71.438346000000081, 29.418961000000024],
            [71.432548000000054, 29.419643000000065],
            [71.424363000000028, 29.419643000000065],
            [71.41754300000008, 29.41759600000006],
            [71.41393578800006, 29.416153284000075],
            [71.409017000000063, 29.414186000000029],
            [71.39946800000007, 29.414186000000029],
            [71.393329000000051, 29.415550000000053],
            [71.389577000000031, 29.407365000000027],
            [71.389236000000039, 29.401909000000046],
            [71.385308758000065, 29.398766439000042],
            [71.38412100000005, 29.397816000000034],
            [71.37764100000004, 29.389972000000057],
            [71.375838887000043, 29.391323667000052],
            [71.372184000000061, 29.394065000000069],
            [71.372184000000061, 29.395196705000046],
            [71.372184000000061, 29.401909000000046],
            [71.37118102800008, 29.404774634000034],
            [71.369797000000062, 29.408729000000051],
            [71.36604600000004, 29.413504000000046],
            [71.36024800000007, 29.420325000000048],
            [71.355132000000083, 29.422712000000047],
            [71.355021000000079, 29.422873000000038],
            [71.355038000000036, 29.422879000000023],
            [71.35522859200006, 29.423201554000059],
            [71.357900401000052, 29.427723274000073],
            [71.361325000000079, 29.433519000000047],
            [71.362890244000084, 29.435686111000052],
            [71.36761283900006, 29.442224503000034],
            [71.37157970100003, 29.449797086000046],
            [71.372934058000055, 29.452382501000045],
            [71.382122000000038, 29.45673400000004],
            [71.382282820000057, 29.456882465000035],
            [71.382284164000055, 29.456883112000071],
            [71.38841056800004, 29.462537766000025],
            [71.388891393000051, 29.463073918000077],
            [71.389802933000055, 29.464090347000024],
            [71.39243798800004, 29.467014037000069],
            [71.392763138000078, 29.467374802000052],
            [71.388493417000063, 29.474359392000054],
            [71.387442000000078, 29.476080000000024],
            [71.383089000000041, 29.482367000000067],
            [71.380241356000056, 29.483316215000059],
            [71.374383926000064, 29.48526954700003],
            [71.370456167000043, 29.49233827300003],
            [71.370090497000035, 29.492996571000049],
            [71.369547000000068, 29.493975000000034],
            [71.36966181400004, 29.494388339000068],
            [71.371965408000051, 29.50267982500003],
            [71.372786681000036, 29.503059217000043],
            [71.376176834000034, 29.504625320000059],
            [71.377733272000057, 29.50534213800006],
            [71.378255102000082, 29.505582467000067],
            [71.38695800000005, 29.507517000000064],
            [71.384372455000062, 29.510898319000034],
            [71.380670547000079, 29.515741348000063],
            [71.371484756000029, 29.522512436000056],
            [71.364229202000047, 29.522512436000056],
            [71.361471176000066, 29.534462611000038],
            [71.361631801000044, 29.542449447000024],
            [71.361747106000053, 29.548182806000057],
            [71.362020493000045, 29.551790237000034],
            [71.362188065000055, 29.557708049000041],
            [71.362283651000041, 29.55809855900003],
            [71.364227000000028, 29.566038000000049],
            [71.37003100000004, 29.567972000000054],
            [71.37029246000003, 29.568015588000037],
            [71.376847292000036, 29.56910834200005],
            [71.381638000000066, 29.569907000000057],
            [71.381638000000066, 29.572788015000071],
            [71.380714191000038, 29.575252115000069],
            [71.377396565000083, 29.580383092000034],
            [71.378560401000072, 29.584988410000051],
            [71.379379914000083, 29.588231239000038],
            [71.384540000000072, 29.591671000000076],
            [71.391489800000045, 29.59171327100006],
            [71.39663100000007, 29.591671000000076],
            [71.398561121000057, 29.592333935000056],
            [71.400699985000074, 29.592807793000077],
            [71.405337000000031, 29.594089000000054],
            [71.403809673000069, 29.598187292000034],
            [71.402390933000049, 29.600971580000078],
            [71.40164671000008, 29.603495270000053],
            [71.413458906000074, 29.608104892000028],
            [71.414866000000075, 29.608654000000058],
            [71.420330000000035, 29.611016000000063],
            [71.420712483000045, 29.61445834500006],
            [71.421686904000069, 29.623228137000069],
            [71.421781000000067, 29.624075000000062],
            [71.42613300000005, 29.633264000000054],
            [71.426719339000044, 29.638246819000074],
            [71.428068000000053, 29.649708000000032],
            [71.430241359000036, 29.657532723000031],
            [71.432904000000065, 29.667119000000071],
            [71.431188922000047, 29.68170159500005],
            [71.430970000000059, 29.683563000000049],
            [71.425650000000076, 29.690817000000038],
            [71.424682000000075, 29.702909000000034],
            [71.431937000000062, 29.705810000000042],
            [71.437257000000045, 29.705810000000042],
            [71.441719608000028, 29.714392375000045],
            [71.443544000000031, 29.71790100000004],
            [71.44480197300004, 29.722932241000024],
            [71.445479000000034, 29.725640000000055],
            [71.445963000000063, 29.735313000000076],
            [71.44064300000008, 29.744502000000068],
            [71.434355000000039, 29.740633000000059],
            [71.427584000000081, 29.737247000000025],
            [71.422005642000045, 29.744587072000058],
            [71.422005180000042, 29.744587680000052],
            [71.418395000000032, 29.749338000000023],
            [71.422264000000041, 29.757560000000069],
            [71.422569287000044, 29.761019784000041],
            [71.423715000000072, 29.774004000000048],
            [71.431453000000033, 29.778357000000028],
            [71.427584000000081, 29.785611000000074],
            [71.430002000000059, 29.790931000000057],
            [71.43051416600008, 29.795199165000042],
            [71.431453000000033, 29.803023000000053],
            [71.434658961000082, 29.809833389000062],
            [71.436026096000035, 29.811431551000055],
            [71.442577000000028, 29.813179000000048],
            [71.451283000000046, 29.813663000000076],
            [71.455519000000038, 29.812967000000071],
            [71.458054000000061, 29.811728000000073],
            [71.463374000000044, 29.803506000000027],
            [71.468210000000056, 29.800604000000078],
            [71.475305419000051, 29.799658270000066],
            [71.475465000000042, 29.799637000000075],
            [71.483687000000032, 29.799637000000075],
            [71.485362382000062, 29.800102373000072],
            [71.492392000000052, 29.802055000000053],
            [71.493360000000052, 29.798670000000072],
            [71.494327000000055, 29.793350000000032],
            [71.499647000000039, 29.789964000000055],
            [71.50381795800007, 29.789744763000044],
            [71.508836000000031, 29.78948100100007],
            [71.509024000000068, 29.789692000000059],
            [71.512705000000039, 29.793833000000063],
            [71.511182451000082, 29.802055899000038],
            [71.510287000000062, 29.806892000000062],
            [71.505698514000073, 29.813447076000045],
            [71.503516000000047, 29.816565000000026],
            [71.500606889000039, 29.821656132000044],
            [71.499647000000039, 29.82333600100003],
            [71.500614000000041, 29.829623000000026],
            [71.498196000000064, 29.835910000000069],
            [71.497712000000035, 29.845099000000062],
            [71.496992600000056, 29.847955419000073],
            [71.497739724000041, 29.853336627000033],
            [71.497979249000082, 29.85764462000003],
            [71.498196000000064, 29.86154300000004],
            [71.504000000000076, 29.86154300000004],
            [71.51367300000004, 29.857674000000031],
            [71.514300125000034, 29.86206517100004],
            [71.513959853000074, 29.863955959000066],
            [71.522149050000053, 29.872124682000049],
            [71.533018000000084, 29.867347000000052],
            [71.537371000000064, 29.862027000000069],
            [71.536030717000074, 29.853092085000071],
            [71.535920000000033, 29.852354000000048],
            [71.545721850000064, 29.848620284000049],
            [71.546077000000082, 29.848485000000039],
            [71.549503479000066, 29.85428390800007],
            [71.550621887000034, 29.856176680000033],
            [71.552364000000068, 29.859125000000063],
            [71.545529465000072, 29.872648942000069],
            [71.544526189000067, 29.880006528000024],
            [71.544521674000066, 29.880039642000042],
            [71.544142000000079, 29.882824000000028],
            [71.540545042000076, 29.887619531000041],
            [71.540537260000065, 29.887629906000029],
            [71.538788756000031, 29.889961044000074],
            [71.538338000000067, 29.890562000000045],
            [71.538143883000032, 29.892642635000072],
            [71.538338000000067, 29.899267000000066],
            [71.543275818000041, 29.903914747000044],
            [71.54597300100005, 29.906453484000053],
            [71.546560000000056, 29.907006000000024],
            [71.546555384000044, 29.907075330000055],
            [71.546077000000082, 29.91426000000007],
            [71.546427323000046, 29.91545121300004],
            [71.548495000000059, 29.922482000000059],
            [71.550913000000037, 29.92683500000004],
            [71.557077699000047, 29.934929852000039],
            [71.557160450000083, 29.935005277000073],
            [71.563173684000049, 29.940486174000057],
            [71.563620270000058, 29.940893224000035],
            [71.563620448000052, 29.940893387000074],
            [71.564765046000048, 29.942211801000042],
            [71.567841000000044, 29.94473000000005],
            [71.572443926000062, 29.947448266000038],
            [71.576605939000046, 29.949476922000031],
            [71.581279969000036, 29.95175514500005],
            [71.589121000000034, 29.955854000000045],
            [71.591382000000067, 29.958115000000078],
            [71.594415809000054, 29.961148809000065],
            [71.594452708000063, 29.961185708000073],
            [71.598310000000083, 29.965043000000037],
            [71.604597000000069, 29.969396000000074],
            [71.610026317000063, 29.96964243900004],
            [71.615238000000033, 29.969879000000049],
            [71.616334014000074, 29.965275560000066],
            [71.616334207000079, 29.965274751000038],
            [71.617656000000068, 29.959723000000054],
            [71.617354983000041, 29.957515680000029],
            [71.616205000000036, 29.94908300000003],
            [71.611486442000057, 29.945712203000028],
            [71.611391661000084, 29.945644495000067],
            [71.609434000000078, 29.944246000000078],
            [71.608099866000032, 29.938108323000051],
            [71.607016000000044, 29.933122000000026],
            [71.612816316000078, 29.92500123800005],
            [71.614270000000033, 29.922966000000031],
            [71.624910000000057, 29.913293000000067],
            [71.627107499000033, 29.912414027000068],
            [71.627151575000084, 29.912396397000066],
            [71.628822706000051, 29.911727964000079],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "108",
      properties: { name: "Mandi Bahauddin", count: 456 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.595551000000057, 32.729868000000067],
            [73.60501247500008, 32.726713948000054],
            [73.605012474000034, 32.726714055000059],
            [73.608233836000068, 32.725640113000054],
            [73.608236980000072, 32.725639065000053],
            [73.609443000000056, 32.72523700000005],
            [73.619283000000053, 32.717134000000044],
            [73.624381521000032, 32.705406873000072],
            [73.625071000000048, 32.703821000000062],
            [73.627842800000053, 32.700355950000073],
            [73.629701000000068, 32.698033000000066],
            [73.63047292400006, 32.698062691000075],
            [73.641950000000065, 32.698504000000071],
            [73.64194993600006, 32.698504270000058],
            [73.644750000000045, 32.698612000000026],
            [73.64475716700008, 32.698610314000064],
            [73.644800885000052, 32.698612000000026],
            [73.654640885000049, 32.696297000000072],
            [73.659681732000081, 32.692145473000039],
            [73.659973773000047, 32.692360390000033],
            [73.664451340000085, 32.688181328000042],
            [73.663866607000045, 32.680872166000029],
            [73.663851833000081, 32.680679848000068],
            [73.663851000000079, 32.68066900000008],
            [73.663851000000079, 32.676309442000047],
            [73.663851000000079, 32.676287785000056],
            [73.663851000000079, 32.669093000000032],
            [73.663852129000077, 32.669092032000037],
            [73.665973990000055, 32.667273144000035],
            [73.671951340000078, 32.662181328000031],
            [73.671947155000055, 32.662152868000078],
            [73.671451340000033, 32.658781328000032],
            [73.671351340000058, 32.653481328000055],
            [73.672409440000081, 32.651794087000042],
            [73.675051340000039, 32.647581328000058],
            [73.676333939000074, 32.645454914000027],
            [73.67753437600004, 32.643515869000055],
            [73.678900000000056, 32.641310000000033],
            [73.679273139000031, 32.641096791000052],
            [73.680298503000074, 32.640510905000042],
            [73.682625469000072, 32.639181292000046],
            [73.68321895400004, 32.638842179000051],
            [73.68365130400008, 32.638595137000038],
            [73.687003000000061, 32.636680000000069],
            [73.687736213000051, 32.636166700000047],
            [73.688880310000059, 32.635365753000031],
            [73.692791000000057, 32.632628000000068],
            [73.69856061400003, 32.625704064000047],
            [73.698579000000052, 32.62568200000004],
            [73.700780842000029, 32.62568200000004],
            [73.700834368000073, 32.62568200000004],
            [73.706682000000058, 32.62568200000004],
            [73.714786000000061, 32.625103000000024],
            [73.715235656000061, 32.624706221000054],
            [73.724951340000075, 32.617181328000072],
            [73.72607060200005, 32.614132303000076],
            [73.731571000000031, 32.605424000000028],
            [73.734111172000041, 32.599390680000056],
            [73.73617603200006, 32.594486302000064],
            [73.736201000000051, 32.594427000000053],
            [73.73710200000005, 32.589027000000044],
            [73.737181322000083, 32.588549836000027],
            [73.737206835000052, 32.588552808000031],
            [73.737251340000057, 32.588281328000051],
            [73.741351340000051, 32.584481328000038],
            [73.741351379000037, 32.584481292000078],
            [73.743751340000074, 32.582281328000079],
            [73.749651340000071, 32.580181328000037],
            [73.754860179000048, 32.57674152900006],
            [73.754724000000067, 32.575905000000034],
            [73.754951304000031, 32.57581407300006],
            [73.755867828000078, 32.57544744300003],
            [73.759866506000037, 32.573847879000027],
            [73.763406000000032, 32.572432000000049],
            [73.773268827000038, 32.572432000000049],
            [73.777952031000041, 32.572432000000049],
            [73.779033000000084, 32.572432000000049],
            [73.779372678000072, 32.572372073000054],
            [73.785525155000073, 32.571286636000025],
            [73.788828885000044, 32.570703783000056],
            [73.788873000000081, 32.570696000000055],
            [73.790712427000074, 32.570361454000079],
            [73.79525134000005, 32.569581328000027],
            [73.795430302000057, 32.569300102000057],
            [73.798484921000068, 32.565481499000043],
            [73.799834401000055, 32.563794503000054],
            [73.799851340000032, 32.563773327000035],
            [73.799870000000055, 32.563750000000027],
            [73.799870000000055, 32.56223090800006],
            [73.799870000000055, 32.560277000000042],
            [73.799234803000047, 32.558794385000056],
            [73.798151340000061, 32.556181328000037],
            [73.79525134000005, 32.551581328000054],
            [73.794300884000052, 32.550655884000037],
            [73.791554231000077, 32.547909231000062],
            [73.791495549000047, 32.547850549000032],
            [73.791426207000029, 32.547781207000071],
            [73.790614122000079, 32.546969122000064],
            [73.790031000000056, 32.546386000000041],
            [73.789727180000057, 32.543955967000045],
            [73.789460277000046, 32.541821199000026],
            [73.78945200000004, 32.541755000000023],
            [73.78945200000004, 32.531915000000026],
            [73.78945200000004, 32.531878130000052],
            [73.790751340000043, 32.525581328000044],
            [73.789645380000081, 32.523132417000056],
            [73.790372899000033, 32.514040940000029],
            [73.790421301000038, 32.513436080000076],
            [73.790604871000085, 32.511142095000025],
            [73.790610000000072, 32.511078000000055],
            [73.795819000000051, 32.50008100000008],
            [73.798285705000069, 32.496997796000073],
            [73.802765000000079, 32.491399000000058],
            [73.803956123000034, 32.491399000000058],
            [73.803951340000083, 32.491481328000077],
            [73.804255954000041, 32.491399000000058],
            [73.806368569000028, 32.490828023000063],
            [73.807651340000064, 32.490481328000044],
            [73.809815055000058, 32.490069192000078],
            [73.811851340000032, 32.489681328000074],
            [73.818221687000062, 32.483997018000025],
            [73.818392000000074, 32.483874000000071],
            [73.818867108000063, 32.483003128000064],
            [73.821496197000044, 32.478184016000057],
            [73.821452316000034, 32.478067311000075],
            [73.821444239000073, 32.47803975100004],
            [73.821557827000049, 32.478071047000071],
            [73.821825015000059, 32.477581292000025],
            [73.821865000000059, 32.477508000000057],
            [73.821892805000061, 32.477505072000042],
            [73.830961406000029, 32.476550135000025],
            [73.832862000000034, 32.476350000000025],
            [73.836125785000036, 32.474018955000076],
            [73.840966000000037, 32.470562000000029],
            [73.842326677000074, 32.47046477300006],
            [73.844243774000063, 32.470327787000031],
            [73.844551304000049, 32.470305812000049],
            [73.84878023300007, 32.470003634000079],
            [73.849426536000067, 32.469957452000074],
            [73.856002379000074, 32.469487575000073],
            [73.857172000000048, 32.469404000000054],
            [73.862110721000079, 32.462271767000061],
            [73.862382000000082, 32.461880000000065],
            [73.863131527000064, 32.457005158000072],
            [73.86353900000006, 32.454355000000078],
            [73.864256389000047, 32.453906642000049],
            [73.872800000000041, 32.448567000000025],
            [73.873934359000032, 32.447675668000045],
            [73.880531818000065, 32.442491659000041],
            [73.880951340000081, 32.442181328000061],
            [73.885251340000082, 32.431281328000068],
            [73.882651364000083, 32.423081404000072],
            [73.882661435000045, 32.423064923000027],
            [73.889007000000049, 32.412681000000077],
            [73.891901000000075, 32.397632000000044],
            [73.893950845000063, 32.397916701000042],
            [73.893634500000076, 32.397632500000043],
            [73.879744190000054, 32.39589608600005],
            [73.876894926000034, 32.396031810000068],
            [73.876872137000078, 32.396032896000065],
            [73.867591000000061, 32.396475000000066],
            [73.867589919000068, 32.396474932000046],
            [73.867588500000068, 32.396475000000066],
            [73.865855251000085, 32.396366637000028],
            [73.859755671000073, 32.395985290000056],
            [73.849468654000077, 32.39534214300005],
            [73.849066500000049, 32.395317000000034],
            [73.848700166000071, 32.395032069000024],
            [73.84064662600008, 32.388768119000076],
            [73.838648500000033, 32.387214000000029],
            [73.835976919000075, 32.384319915000049],
            [73.831702500000063, 32.37968950000004],
            [73.82662163100008, 32.377550102000043],
            [73.820705500000031, 32.375059000000078],
            [73.818960074000074, 32.374324088000037],
            [73.809708000000057, 32.37042850000006],
            [73.800447840000061, 32.369849521000049],
            [73.800447500000075, 32.369849500000043],
            [73.783663889000081, 32.373900423000066],
            [73.783661500000051, 32.373901000000046],
            [73.78343359400003, 32.372715873000061],
            [73.783018059000028, 32.37055506400003],
            [73.782997339000076, 32.370447316000025],
            [73.780768046000048, 32.358854842000028],
            [73.780767500000081, 32.35885200000007],
            [73.774402414000065, 32.368110216000048],
            [73.77440050000007, 32.368113000000051],
            [73.77208550000006, 32.357116000000076],
            [73.769567416000029, 32.351719973000058],
            [73.768034000000057, 32.348434000000054],
            [73.759351568000056, 32.343224541000041],
            [73.759351500000037, 32.343224500000076],
            [73.754142500000057, 32.344382000000053],
            [73.748874427000032, 32.348410621000028],
            [73.744303000000059, 32.351906500000041],
            [73.743690499000081, 32.353988621000042],
            [73.743100145000028, 32.355995457000063],
            [73.743101510000031, 32.355998035000027],
            [73.743091353000068, 32.356032569000035],
            [73.743091317000051, 32.356032691000053],
            [73.741411000000085, 32.361746000000039],
            [73.741408500000034, 32.361746000000039],
            [73.733114350000051, 32.361746000000039],
            [73.730467212000065, 32.361746000000039],
            [73.730414000000053, 32.361746000000039],
            [73.730411500000059, 32.361746000000039],
            [73.729606725000053, 32.358258025000055],
            [73.728675500000065, 32.35422200000005],
            [73.724574401000041, 32.353196504000039],
            [73.724044500000048, 32.353064000000074],
            [73.719907768000041, 32.353064000000074],
            [73.719031533000077, 32.353064000000074],
            [73.71768000000003, 32.353064000000074],
            [73.717678000000035, 32.353064000000074],
            [73.705669065000052, 32.347521568000047],
            [73.704466313000069, 32.346966467000072],
            [73.702629000000059, 32.346118500000046],
            [73.693368000000078, 32.344961000000069],
            [73.687983959000064, 32.344546705000027],
            [73.685843500000033, 32.344382000000053],
            [73.678519102000052, 32.342128669000033],
            [73.678519250000079, 32.342127991000041],
            [73.678321000000039, 32.342067000000043],
            [73.666166000000032, 32.333385000000078],
            [73.665206620000049, 32.333159172000023],
            [73.658609273000081, 32.331606224000041],
            [73.656327000000033, 32.33106900000007],
            [73.652521531000048, 32.329256902000054],
            [73.644172000000083, 32.325281000000075],
            [73.639381470000046, 32.322726185000079],
            [73.630199626000035, 32.317829460000041],
            [73.618125000000077, 32.311390000000074],
            [73.606306086000075, 32.306237911000039],
            [73.598913007000078, 32.303015127000037],
            [73.595552000000055, 32.301550000000077],
            [73.587690657000053, 32.299700413000039],
            [73.575873000000058, 32.296920000000057],
            [73.560835415000042, 32.291012410000064],
            [73.55966600000005, 32.290553000000045],
            [73.545136907000028, 32.283126684000024],
            [73.533620000000042, 32.277240000000063],
            [73.532035695000047, 32.276092781000045],
            [73.526492021000081, 32.272078521000026],
            [73.516834000000074, 32.265085000000056],
            [73.516396215000043, 32.264014867000071],
            [73.512042290000068, 32.253372033000062],
            [73.511860525000031, 32.252927723000028],
            [73.511625000000038, 32.25235200000003],
            [73.510300624000081, 32.241753940000024],
            [73.510093663000077, 32.240097773000059],
            [73.510093160000054, 32.240093750000028],
            [73.509889000000044, 32.238460000000032],
            [73.509885049000047, 32.238460395000061],
            [73.50147230400006, 32.239301561000048],
            [73.498561126000084, 32.239592641000058],
            [73.494054858000084, 32.240043209000078],
            [73.486736000000064, 32.240775000000042],
            [73.482964160000051, 32.237811245000046],
            [73.480610640000066, 32.235961947000078],
            [73.478633000000059, 32.23440800000003],
            [73.47803207700008, 32.23329202900004],
            [73.472517479000032, 32.223050891000071],
            [73.468483454000079, 32.215559320000068],
            [73.466478000000052, 32.211835000000065],
            [73.463573095000072, 32.208494209000037],
            [73.462490153000033, 32.207248770000035],
            [73.457466938000039, 32.201471811000033],
            [73.454902000000061, 32.198522000000025],
            [73.446906843000079, 32.192303602000038],
            [73.439274000000069, 32.186367000000075],
            [73.42976161100006, 32.182730066000033],
            [73.419595000000072, 32.178843000000029],
            [73.410325193000062, 32.175929684000039],
            [73.399336000000062, 32.172476000000074],
            [73.39330298200008, 32.167623300000059],
            [73.393057594000084, 32.167425921000074],
            [73.382968797000046, 32.159310930000061],
            [73.380638348000048, 32.157436417000042],
            [73.372711000000038, 32.15106000000003],
            [73.363697107000064, 32.145989482000061],
            [73.35211167600005, 32.139472417000036],
            [73.344929000000036, 32.135432000000037],
            [73.340581347000068, 32.133455856000069],
            [73.331755630000032, 32.129444293000063],
            [73.324187302000041, 32.12600425200003],
            [73.319461000000047, 32.123856000000046],
            [73.310192962000031, 32.122090841000045],
            [73.310193000000083, 32.122091000000069],
            [73.31019090500007, 32.122090601000025],
            [73.30730600000004, 32.121541000000036],
            [73.305504196000072, 32.12355466200006],
            [73.297466000000043, 32.132538000000068],
            [73.292657118000079, 32.145897441000045],
            [73.292257000000063, 32.147009000000025],
            [73.287626000000046, 32.14816600000006],
            [73.285960991000081, 32.149581314000045],
            [73.276050000000055, 32.158006000000057],
            [73.276050000000055, 32.163215000000037],
            [73.270841000000075, 32.169003000000032],
            [73.267220405000046, 32.168801786000074],
            [73.260422968000057, 32.16842405400007],
            [73.260422000000062, 32.168424000000073],
            [73.253740057000073, 32.179562497000063],
            [73.253477000000032, 32.180001000000061],
            [73.254635000000064, 32.187525000000051],
            [73.254635000000064, 32.189678815000036],
            [73.254635000000064, 32.189691276000076],
            [73.254635000000064, 32.197365000000048],
            [73.254634984000063, 32.197365227000034],
            [73.254056000000048, 32.205468000000053],
            [73.248268000000053, 32.21530800000005],
            [73.246073935000084, 32.220135096000035],
            [73.242480000000057, 32.228042000000073],
            [73.237271000000078, 32.240775000000042],
            [73.23264000000006, 32.248879000000045],
            [73.229854930000045, 32.253211391000036],
            [73.227431000000081, 32.25698200000005],
            [73.226870537000082, 32.257654464000041],
            [73.226200000000063, 32.258459000000073],
            [73.221643000000029, 32.263928000000078],
            [73.221642455000051, 32.263927455000044],
            [73.221642000000031, 32.263928000000078],
            [73.213539000000083, 32.255824000000075],
            [73.209487000000081, 32.251194000000055],
            [73.207888403000084, 32.249115658000051],
            [73.203699000000029, 32.243669000000068],
            [73.196754000000055, 32.239039000000048],
            [73.194010999000056, 32.239039000000048],
            [73.187493000000075, 32.239039000000048],
            [73.185746056000085, 32.239039000000048],
            [73.176496000000043, 32.239039000000048],
            [73.176495000000045, 32.239039000000048],
            [73.165498000000071, 32.237881000000073],
            [73.16086800000005, 32.237881000000073],
            [73.15160700000007, 32.248879000000045],
            [73.15062088500008, 32.258084637000024],
            [73.150620849000063, 32.258084973000052],
            [73.149871000000076, 32.265085000000056],
            [73.143563037000035, 32.277127295000071],
            [73.143504000000064, 32.277240000000063],
            [73.136919146000082, 32.285343964000049],
            [73.135979000000077, 32.286501000000044],
            [73.136594417000083, 32.292038156000046],
            [73.137137000000052, 32.296920001000046],
            [73.136847059000047, 32.296902422000073],
            [73.118036000000075, 32.295762000000025],
            [73.115566089000083, 32.293983732000072],
            [73.103566000000058, 32.285344000000066],
            [73.087596218000044, 32.285913540000024],
            [73.087656194000033, 32.286020194000059],
            [73.082820391000041, 32.294443793000028],
            [73.081596835000084, 32.296575134000079],
            [73.081596115000082, 32.296576386000027],
            [73.079392977000055, 32.300395210000033],
            [73.07899582500005, 32.301083615000039],
            [73.080138804000057, 32.309653000000026],
            [73.08678100000003, 32.309653000000026],
            [73.087022294000064, 32.30976561500006],
            [73.087098825000055, 32.30976561500006],
            [73.091998101000058, 32.312051907000068],
            [73.09197671000004, 32.312077904000034],
            [73.091976872000032, 32.312077980000026],
            [73.09199828900006, 32.312051994000058],
            [73.095607267000048, 32.313705000000027],
            [73.10175776300008, 32.321786393000025],
            [73.102726825000047, 32.323078615000043],
            [73.106199825000033, 32.334075615000074],
            [73.106678569000053, 32.345562160000043],
            [73.106690424000078, 32.345846601000062],
            [73.106778825000049, 32.347967615000073],
            [73.103305825000064, 32.359543615000064],
            [73.101579136000055, 32.362067326000044],
            [73.098226318000059, 32.366967769000041],
            [73.09570519600004, 32.370639134000044],
            [73.087677825000071, 32.380959615000052],
            [73.086247502000049, 32.384433559000058],
            [73.083626825000067, 32.390798615000051],
            [73.081889825000076, 32.397744615000079],
            [73.059370729000079, 32.398899850000078],
            [73.059316825000053, 32.398902615000054],
            [73.050634825000031, 32.41395161500003],
            [73.050733854000043, 32.416629176000072],
            [73.05121282500005, 32.42957961500008],
            [73.055330523000066, 32.431637956000031],
            [73.059129716000029, 32.433536823000054],
            [73.069298287000038, 32.434989954000059],
            [73.071153000000038, 32.435255000000041],
            [73.074047000000064, 32.435255000000041],
            [73.074047000000064, 32.435366938000072],
            [73.074365018000037, 32.435366938000072],
            [73.074365018000037, 32.442281753000032],
            [73.074365018000037, 32.445205938000072],
            [73.074943018000056, 32.452151938000043],
            [73.076695936000078, 32.453585228000065],
            [73.076687932000084, 32.45361269600005],
            [73.076696226000081, 32.453585321000048],
            [73.077160281000033, 32.453968322000037],
            [73.081142914000054, 32.457255331000056],
            [73.088405366000075, 32.458372928000074],
            [73.088626774000033, 32.458407000000079],
            [73.08862700800006, 32.45840703600004],
            [73.088667914000041, 32.458413331000031],
            [73.100512563000052, 32.458413331000031],
            [73.100511986000072, 32.45840703600004],
            [73.100511983000047, 32.458407000000079],
            [73.101830000000064, 32.458407000000079],
            [73.101973266000073, 32.458407000000079],
            [73.101977520000048, 32.45840703600004],
            [73.111224911000079, 32.458485292000034],
            [73.111292638000066, 32.458485867000036],
            [73.113178934000075, 32.458501826000031],
            [73.11318089100007, 32.458501846000047],
            [73.113182155000061, 32.458501863000038],
            [73.11318645800003, 32.458496282000056],
            [73.113533143000041, 32.458496282000056],
            [73.118288293000035, 32.458496282000056],
            [73.118288451000069, 32.458501839000064],
            [73.126406409000083, 32.458501839000064],
            [73.135867011000073, 32.459027579000065],
            [73.137034873000061, 32.459093164000024],
            [73.137365536000061, 32.459112620000042],
            [73.146611800000073, 32.459656684000038],
            [73.147672019000083, 32.459702784000058],
            [73.159855492000077, 32.460232538000071],
            [73.159977409000078, 32.460237839000058],
            [73.160077212000033, 32.460299259000067],
            [73.160077269000055, 32.460299294000038],
            [73.161992303000034, 32.461477835000039],
            [73.167502409000065, 32.464868839000076],
            [73.167530769000052, 32.464953919000038],
            [73.169269004000057, 32.470168624000053],
            [73.16921764500006, 32.470240209000053],
            [73.169272591000038, 32.470179386000041],
            [73.170396409000034, 32.47355083900004],
            [73.171486339000069, 32.478638226000044],
            [73.172132409000028, 32.481653839000046],
            [73.172132409000028, 32.490335839000068],
            [73.158241409000084, 32.490914839000027],
            [73.154678546000071, 32.493506266000054],
            [73.154658436000034, 32.493520893000039],
            [73.151915509000048, 32.495515945000079],
            [73.15795638700007, 32.496787622000056],
            [73.162748266000051, 32.49776600000007],
            [73.165192576000038, 32.499543470000049],
            [73.169115267000052, 32.502396000000033],
            [73.177797267000074, 32.504711000000043],
            [73.185900267000079, 32.509342000000061],
            [73.189698770000064, 32.512274361000038],
            [73.203965735000054, 32.523333201000071],
            [73.205299627000045, 32.532222744000023],
            [73.205702735000045, 32.534909202000051],
            [73.205702735000045, 32.540063995000025],
            [73.205702735000045, 32.54359220200007],
            [73.204550965000067, 32.552263282000069],
            [73.198177735000058, 32.561535202000073],
            [73.184268848000045, 32.563585682000053],
            [73.184268811000038, 32.563585687000057],
            [73.18255696500006, 32.56383928200006],
            [73.172397450000062, 32.56736070900007],
            [73.172396930000048, 32.567360893000057],
            [73.16795726600003, 32.568959000000063],
            [73.165642266000077, 32.570696000000055],
            [73.165295267000033, 32.571279000000061],
            [73.165169519000074, 32.571247885000048],
            [73.165151605000062, 32.571277984000062],
            [73.17136800000003, 32.572816000000046],
            [73.195642378000059, 32.582525375000046],
            [73.195642316000033, 32.582525601000043],
            [73.209726805000059, 32.58815932400006],
            [73.209859549000043, 32.588212420000048],
            [73.209859771000083, 32.588212111000075],
            [73.210067000000038, 32.588295000000073],
            [73.218309081000029, 32.588295000000073],
            [73.22509000000008, 32.588295000000073],
            [73.229187000000081, 32.581466000000034],
            [73.229187714000034, 32.58146647600006],
            [73.229188000000079, 32.581466000000034],
            [73.233400607000078, 32.584274233000031],
            [73.23557986700007, 32.585726985000065],
            [73.237383000000079, 32.586929000000055],
            [73.257222427000045, 32.588073743000052],
            [73.261057000000051, 32.588295000000073],
            [73.269851567000046, 32.591079925000031],
            [73.277695101000063, 32.593563692000032],
            [73.280726310000034, 32.594523567000067],
            [73.288373000000036, 32.596945000000062],
            [73.301512711000044, 32.602112484000031],
            [73.312554284000043, 32.606454829000029],
            [73.31581652300008, 32.607737777000068],
            [73.326935709000054, 32.612110655000038],
            [73.327102951000029, 32.612176430000034],
            [73.328545557000041, 32.612743755000054],
            [73.328892000000053, 32.612880000000075],
            [73.334755079000047, 32.614978324000049],
            [73.345598916000029, 32.618859200000031],
            [73.347933327000078, 32.619694657000025],
            [73.354730047000032, 32.622127119000027],
            [73.359952318000069, 32.62399610500006],
            [73.362658159000034, 32.624964492000061],
            [73.369785161000038, 32.627515159000041],
            [73.372143000000051, 32.628359000000046],
            [73.375281220000034, 32.629267425000023],
            [73.377842537000049, 32.630008853000049],
            [73.40674400000006, 32.638375000000053],
            [73.414420889000041, 32.640550250000047],
            [73.434060000000045, 32.646115000000066],
            [73.435926117000065, 32.646861806000061],
            [73.44102244100003, 32.648900223000055],
            [73.441022531000044, 32.648900259000072],
            [73.444461070000045, 32.650275599000054],
            [73.463094600000034, 32.657728602000077],
            [73.470481000000063, 32.660683000000063],
            [73.470726282000044, 32.660815865000075],
            [73.48012828800006, 32.665908663000039],
            [73.490923112000075, 32.671755914000073],
            [73.496107022000047, 32.674563892000037],
            [73.503261000000066, 32.678439000000026],
            [73.524829627000031, 32.689026922000039],
            [73.52482948200003, 32.689027342000031],
            [73.524865752000039, 32.689045147000058],
            [73.524865897000041, 32.689044727000066],
            [73.527766517000032, 32.690468625000051],
            [73.528301000000056, 32.690731000000028],
            [73.533306683000035, 32.694386894000047],
            [73.535762902000045, 32.696180791000074],
            [73.535779037000054, 32.696192575000055],
            [73.548327500000084, 32.705357329000037],
            [73.554452711000067, 32.709830869000029],
            [73.568820000000073, 32.720324000000062],
            [73.575923971000066, 32.732874279000043],
            [73.582478000000037, 32.744453000000078],
            [73.592089157000032, 32.746054713000035],
            [73.595551000000057, 32.739707000000067],
            [73.595551000000057, 32.729868000000067],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "109",
      properties: { name: "Mianwali", count: 793 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.506663758000059, 33.199790505000067],
            [71.525185000000079, 33.198556000000053],
            [71.525184979000073, 33.198556035000024],
            [71.525185500000077, 33.198556000000053],
            [71.525185479000072, 33.19855603600007],
            [71.517896000000064, 33.210484000000065],
            [71.518559000000039, 33.217773000000079],
            [71.531911741000044, 33.221881357000029],
            [71.531925210000054, 33.221885501000031],
            [71.531938679000064, 33.221889645000033],
            [71.535788000000082, 33.223074000000054],
            [71.547053000000062, 33.229700000000037],
            [71.555667000000028, 33.227050000000077],
            [71.556992000000037, 33.219098000000031],
            [71.572896000000071, 33.209821000000034],
            [71.579522000000054, 33.199219000000028],
            [71.599401000000057, 33.187954000000047],
            [71.59873900000008, 33.161448000000064],
            [71.601578916000051, 33.141091343000028],
            [71.602472832000046, 33.134683708000068],
            [71.602714000000049, 33.132955000000038],
            [71.602769556000055, 33.132888692000051],
            [71.60431404600007, 33.131011467000064],
            [71.623256000000083, 33.108437000000038],
            [71.631208000000072, 33.09916000000004],
            [71.63502035700003, 33.089629105000029],
            [71.635046614000032, 33.089563466000072],
            [71.635184000000038, 33.089220000000068],
            [71.652412000000083, 33.073979000000065],
            [71.671629000000053, 33.073317000000031],
            [71.686870000000056, 33.062052000000051],
            [71.704761000000076, 33.060064000000068],
            [71.705561307000039, 33.059930590000079],
            [71.713085010000043, 33.05867640300005],
            [71.713085038000031, 33.058676399000035],
            [71.714266000000066, 33.04037900000003],
            [71.736416179000059, 33.031961530000046],
            [71.74515400000007, 33.02864100000005],
            [71.751575000000059, 33.025535000000048],
            [71.764305000000036, 33.019374000000028],
            [71.766628694000076, 33.012805365000077],
            [71.76662900000008, 33.012804500000072],
            [71.778470469000069, 33.00717067000005],
            [71.778517945000033, 33.007148082000072],
            [71.787141690000055, 33.002705977000062],
            [71.787163000000078, 33.002695000000074],
            [71.811874000000046, 32.998988000000054],
            [71.833456267000031, 32.999604893000026],
            [71.833495000000084, 32.999606000000028],
            [71.84646900000007, 32.995281000000034],
            [71.860060000000033, 32.992192000000045],
            [71.877357000000075, 32.983544000000052],
            [71.885388000000034, 32.970571000000064],
            [71.885388000000034, 32.936623417000078],
            [71.885388000000034, 32.934862414000065],
            [71.885388000000034, 32.932269000000076],
            [71.892801000000077, 32.911882000000048],
            [71.89645792400006, 32.891161729000032],
            [71.896504993000065, 32.890895036000074],
            [71.910558654000056, 32.882947997000031],
            [71.910562106000043, 32.882934547000048],
            [71.910717000000034, 32.882847000000027],
            [71.916875351000044, 32.85882674100003],
            [71.946063869000056, 32.850861366000061],
            [71.950685852000049, 32.849600018000046],
            [71.95081663600007, 32.849503098000071],
            [71.950872000000061, 32.849488000000065],
            [71.962079968000069, 32.841185503000077],
            [71.967552000000069, 32.837132000000054],
            [71.96952660900007, 32.825284701000044],
            [71.973112000000071, 32.803773000000035],
            [71.934192000000053, 32.782768000000033],
            [71.910998013000039, 32.76849522200007],
            [71.894037000000083, 32.758058000000062],
            [71.890948000000037, 32.746938000000057],
            [71.891197134000038, 32.743781673000058],
            [71.892061202000036, 32.732834635000074],
            [71.892371432000061, 32.728904271000033],
            [71.892801000000077, 32.72346200000004],
            [71.89585156000004, 32.713700996000057],
            [71.89585439800004, 32.713691916000073],
            [71.895890000000065, 32.713578000000041],
            [71.893430334000072, 32.699434172000053],
            [71.91374367700007, 32.684647194000036],
            [71.913745944000084, 32.684586675000048],
            [71.913806000000079, 32.684543000000076],
            [71.914423000000056, 32.667863000000068],
            [71.898979000000054, 32.661068000000057],
            [71.899551430000031, 32.651911903000041],
            [71.91044000800008, 32.642105077000053],
            [71.911292871000057, 32.641336944000045],
            [71.911335000000065, 32.64129900000006],
            [71.910718311000039, 32.628970208000055],
            [71.910717000000034, 32.628944000000047],
            [71.916277000000036, 32.620295000000056],
            [71.926742638000064, 32.607981781000035],
            [71.926779000000067, 32.607939000000044],
            [71.923072000000047, 32.590024000000028],
            [71.925536106000038, 32.576470918000041],
            [71.936642998000082, 32.573997423000037],
            [71.936652664000064, 32.573964297000032],
            [71.936663000000067, 32.573962000000051],
            [71.940987000000064, 32.559136000000024],
            [71.931721000000039, 32.550487000000032],
            [71.931711160000077, 32.550423953000063],
            [71.930288375000032, 32.541307559000074],
            [71.930248551000034, 32.54105239200004],
            [71.929902463000076, 32.541090632000078],
            [71.911952000000042, 32.543074000000047],
            [71.894037000000083, 32.54492700000003],
            [71.884153000000083, 32.546780000000069],
            [71.881702157000063, 32.538203041000031],
            [71.880446000000063, 32.533807000000024],
            [71.858824000000084, 32.523923000000025],
            [71.835476693000032, 32.522204543000043],
            [71.832260000000076, 32.502301000000045],
            [71.822993000000054, 32.490563000000066],
            [71.81063800000004, 32.480679000000066],
            [71.790869000000043, 32.478208000000052],
            [71.789016186000083, 32.46803443400006],
            [71.787780500000054, 32.45720300000005],
            [71.795848807000084, 32.452187857000069],
            [71.81063800000004, 32.442995000000053],
            [71.821140000000071, 32.434346000000062],
            [71.826700000000073, 32.425697000000071],
            [71.842144000000076, 32.423226000000057],
            [71.843127198000047, 32.417916919000049],
            [71.845233000000064, 32.406546000000048],
            [71.835967000000039, 32.385542000000044],
            [71.805078000000037, 32.358360000000062],
            [71.78755546900004, 32.337771327000041],
            [71.786910758000033, 32.337013803000048],
            [71.780367000000069, 32.32932500000004],
            [71.77453321400003, 32.323287743000037],
            [71.757304769000029, 32.305458404000035],
            [71.746538483000052, 32.294759127000077],
            [71.735398590000045, 32.282788180000068],
            [71.727239000000054, 32.274344000000042],
            [71.727266735000057, 32.274145872000076],
            [71.72980821200008, 32.255990535000024],
            [71.730327123000052, 32.252283635000026],
            [71.731563000000051, 32.24345500000004],
            [71.726706015000048, 32.240115978000063],
            [71.721679000000051, 32.236660000000029],
            [71.721491878000052, 32.231422402000078],
            [71.721061000000077, 32.219362000000046],
            [71.722414832000084, 32.215300871000068],
            [71.72847500000006, 32.197122000000036],
            [71.728104567000059, 32.194406789000027],
            [71.72734969000004, 32.186826788000076],
            [71.72662100000008, 32.183531000000073],
            [71.715805610000075, 32.184046217000059],
            [71.700675000000047, 32.184767000000079],
            [71.66547726500005, 32.181247369000062],
            [71.651253000000054, 32.179825000000051],
            [71.610480500000051, 32.175500000000056],
            [71.602503422000041, 32.178363608000041],
            [71.586387000000059, 32.184149000000048],
            [71.579665399000078, 32.186658502000057],
            [71.576585783000041, 32.18780827300003],
            [71.540055000000052, 32.20144700000003],
            [71.524844375000043, 32.202942876000066],
            [71.504908927000031, 32.204903410000043],
            [71.502371000000039, 32.205153000000053],
            [71.478278000000046, 32.200829000000056],
            [71.470247000000029, 32.187856000000068],
            [71.466566155000066, 32.187098083000024],
            [71.462477984000031, 32.186256287000049],
            [71.449242255000058, 32.18353103100003],
            [71.436567198000034, 32.184324176000075],
            [71.434417820000078, 32.184436583000036],
            [71.418972000000053, 32.185385000000053],
            [71.412318482000046, 32.186920448000023],
            [71.401933014000065, 32.189317128000027],
            [71.39487900000006, 32.190945000000056],
            [71.372299497000029, 32.19176569800004],
            [71.36728983900008, 32.191947784000035],
            [71.363526714000045, 32.192084563000037],
            [71.360900744000048, 32.192123007000077],
            [71.34472872300006, 32.19216909000005],
            [71.340894700000035, 32.192045964000044],
            [71.331248000000073, 32.186620000000062],
            [71.330102216000057, 32.184328315000073],
            [71.325151679000044, 32.174017847000073],
            [71.321364000000074, 32.166851000000065],
            [71.301595000000077, 32.17117600000006],
            [71.286151000000075, 32.178589000000045],
            [71.266383000000076, 32.182914000000039],
            [71.26319075400005, 32.184387298000047],
            [71.250321000000042, 32.190327000000025],
            [71.235494000000074, 32.195887000000027],
            [71.22931600000004, 32.189091000000076],
            [71.225610000000074, 32.174265000000048],
            [71.218737087000079, 32.170832303000054],
            [71.202134000000058, 32.177354000000037],
            [71.19763253900004, 32.180602343000032],
            [71.195789920000038, 32.181932012000061],
            [71.184219000000041, 32.190327000000025],
            [71.173108090000028, 32.193250972000044],
            [71.17489781900008, 32.197852401000034],
            [71.174899493000055, 32.197856705000049],
            [71.174899202000063, 32.197856640000055],
            [71.175973000000056, 32.200618000000077],
            [71.175946698000075, 32.200677565000035],
            [71.175661712000078, 32.201322943000037],
            [71.175464120000072, 32.201770409000062],
            [71.172820173000048, 32.207683399000075],
            [71.170010000000048, 32.21387100000004],
            [71.168766664000032, 32.218533570000034],
            [71.168766628000071, 32.218533705000027],
            [71.168330291000075, 32.22016998600003],
            [71.167101225000067, 32.224779046000037],
            [71.164709000000073, 32.233750000000043],
            [71.167224486000066, 32.237721830000055],
            [71.175763921000055, 32.25120518500006],
            [71.177299000000062, 32.253629000000046],
            [71.185690518000058, 32.266292927000052],
            [71.185690554000075, 32.266292981000049],
            [71.200491000000056, 32.273509000000047],
            [71.209768000000054, 32.282786000000044],
            [71.209979812000029, 32.283553814000072],
            [71.215069000000028, 32.302002000000073],
            [71.229648000000054, 32.316580000000044],
            [71.242819452000049, 32.326558428000055],
            [71.25151500000004, 32.333146000000056],
            [71.272230475000072, 32.352566718000048],
            [71.272230511000032, 32.35256675100004],
            [71.283322000000055, 32.362965000000031],
            [71.288913446000038, 32.362965000000031],
            [71.293737760000056, 32.363019874000031],
            [71.296078364000039, 32.362965000000031],
            [71.296078855000076, 32.362965000000031],
            [71.296080159000041, 32.362965000000031],
            [71.311193654000078, 32.362954577000039],
            [71.318441000000064, 32.362965000000031],
            [71.318441500000063, 32.362965000000031],
            [71.318485099000043, 32.362974082000051],
            [71.334345000000042, 32.366278000000079],
            [71.334406563000073, 32.366329305000079],
            [71.34229700000003, 32.37290500000006],
            [71.338983000000042, 32.382182000000057],
            [71.328381000000036, 32.38880800000004],
            [71.325748476000058, 32.399339091000058],
            [71.325748807000082, 32.399339750000024],
            [71.325784719000069, 32.399411000000043],
            [71.326447720000033, 32.411338000000057],
            [71.334093468000049, 32.412293538000029],
            [71.334066335000045, 32.412296986000058],
            [71.336995000000059, 32.412663000000066],
            [71.342942923000066, 32.414825955000026],
            [71.342943022000043, 32.414825991000043],
            [71.34428500000007, 32.415314000000023],
            [71.333682000000067, 32.427904000000069],
            [71.336738753000077, 32.441149669000026],
            [71.336828953000065, 32.441540534000069],
            [71.337658000000033, 32.445133000000055],
            [71.340769863000048, 32.452245638000079],
            [71.344182266000075, 32.460045206000075],
            [71.344580971000084, 32.460956509000027],
            [71.34693500000003, 32.466337000000067],
            [71.348836186000028, 32.480595418000064],
            [71.350911000000053, 32.496156000000042],
            [71.344676566000032, 32.500921852000033],
            [71.344676519000075, 32.50092188800005],
            [71.341776624000033, 32.503006845000073],
            [71.340308500000049, 32.504108000000031],
            [71.338820102000057, 32.506092489000025],
            [71.328298643000039, 32.520618637000041],
            [71.32312011700003, 32.531311035000044],
            [71.299926758000083, 32.533325195000032],
            [71.299921208000058, 32.533261111000058],
            [71.299887500000068, 32.533264000000031],
            [71.299887496000053, 32.533263957000031],
            [71.299887000000069, 32.533264000000031],
            [71.297899000000029, 32.510072000000036],
            [71.293329533000076, 32.509092793000036],
            [71.292352969000035, 32.508884399000067],
            [71.288696289000029, 32.50811767600004],
            [71.277582177000056, 32.511980870000059],
            [71.277358000000049, 32.512060000000076],
            [71.277357309000081, 32.512059033000071],
            [71.27273959200005, 32.505594786000074],
            [71.270731000000069, 32.502783000000079],
            [71.272617912000044, 32.493977726000026],
            [71.272719000000052, 32.493506000000025],
            [71.266953035000029, 32.490815024000028],
            [71.262850029000049, 32.488900149000074],
            [71.26283163800008, 32.488891566000063],
            [71.262779193000028, 32.488867090000042],
            [71.26277900000008, 32.488867000000027],
            [71.262695477000079, 32.488891566000063],
            [71.25151500000004, 32.492180000000076],
            [71.241726025000048, 32.497964486000058],
            [71.236936000000071, 32.500795000000039],
            [71.230812000000071, 32.510260000000073],
            [71.230837458000053, 32.510282621000044],
            [71.229675293000071, 32.512084961000028],
            [71.246250076000081, 32.53596258400006],
            [71.241699219000054, 32.548522949000073],
            [71.236595097000077, 32.55151621400006],
            [71.223947992000035, 32.559163578000039],
            [71.201526646000048, 32.574266345000069],
            [71.196993503000044, 32.577318766000076],
            [71.195409150000046, 32.578385601000036],
            [71.179509826000071, 32.586326589000066],
            [71.172362594000049, 32.589896306000071],
            [71.16442617000007, 32.593858674000046],
            [71.162236077000045, 32.594951964000074],
            [71.165522148000036, 32.608883497000079],
            [71.164159813000083, 32.613943877000054],
            [71.163418518000071, 32.616697410000029],
            [71.163148105000062, 32.617701860000068],
            [71.16073300000005, 32.62735900000007],
            [71.15651562000005, 32.637375301000077],
            [71.150130500000046, 32.652540000000045],
            [71.150383526000041, 32.654648468000062],
            [71.151106982000044, 32.659797769000079],
            [71.15193105700007, 32.667544038000074],
            [71.152118500000029, 32.669106000000056],
            [71.152099073000045, 32.66912338000003],
            [71.152099609000061, 32.669128418000071],
            [71.13952636700003, 32.680480957000043],
            [71.134836972000073, 32.692716148000045],
            [71.134560520000036, 32.693437446000075],
            [71.139650101000029, 32.70301377100003],
            [71.14093017600004, 32.705688477000024],
            [71.142268497000032, 32.722387583000057],
            [71.142167918000041, 32.722635910000065],
            [71.142179000000056, 32.722780000000057],
            [71.137986033000061, 32.732960984000044],
            [71.137512207000043, 32.734130859000061],
            [71.130999325000062, 32.743878616000075],
            [71.128772109000067, 32.747312835000059],
            [71.128408671000045, 32.74784855200005],
            [71.125626788000034, 32.751949118000027],
            [71.124315772000045, 32.753881589000059],
            [71.124287500000037, 32.75392400000004],
            [71.124749405000046, 32.757156812000062],
            [71.125285210000072, 32.760906838000039],
            [71.125479214000052, 32.762264646000062],
            [71.125541923000071, 32.762703534000025],
            [71.125613000000044, 32.763201000000038],
            [71.124930137000035, 32.764810644000079],
            [71.124918424000043, 32.764838252000061],
            [71.109176137000077, 32.801946509000061],
            [71.108890000000031, 32.802621000000045],
            [71.107116663000056, 32.806799141000056],
            [71.107059000000049, 32.806935000000067],
            [71.107116663000056, 32.806935000000067],
            [71.115010000000041, 32.806935000000067],
            [71.125613000000044, 32.804947000000027],
            [71.132442737000076, 32.808362083000077],
            [71.133564500000034, 32.80892300000005],
            [71.133564500000034, 32.823392962000071],
            [71.133564500000034, 32.823501000000078],
            [71.135461296000074, 32.845629013000064],
            [71.135553000000073, 32.846693000000073],
            [71.136635529000046, 32.853405140000064],
            [71.138866000000064, 32.867235000000051],
            [71.145492000000047, 32.874524000000065],
            [71.145367307000072, 32.875247280000053],
            [71.142179000000056, 32.893741000000034],
            [71.140853500000048, 32.908319000000063],
            [71.141516000000081, 32.922897000000034],
            [71.15410600000007, 32.936150000000055],
            [71.159408000000042, 32.938138000000038],
            [71.158744999000078, 32.947415001000024],
            [71.170672000000081, 32.961993000000064],
            [71.185913000000028, 32.971933000000035],
            [71.188432448000071, 32.973536128000035],
            [71.193202000000042, 32.976571000000035],
            [71.210431000000028, 32.977234000000067],
            [71.220371000000057, 32.979884000000027],
            [71.222102412000083, 32.981389670000056],
            [71.235611000000063, 32.993137000000047],
            [71.252177000000074, 32.999101000000053],
            [71.266093000000069, 32.999101000000053],
            [71.270344811000029, 33.001063526000053],
            [71.283321000000058, 33.007053000000042],
            [71.296185146000084, 33.009127721000027],
            [71.303863000000035, 33.010366000000033],
            [71.31446518000007, 33.015003641000078],
            [71.32241700000003, 32.999101000000053],
            [71.336995000000059, 32.99048700000003],
            [71.355039602000033, 32.99048700000003],
            [71.356874000000062, 32.99048700000003],
            [71.35687450000006, 32.99048700000003],
            [71.356875000000059, 32.99048700000003],
            [71.365489000000082, 32.996450000000038],
            [71.378079000000071, 33.004402000000027],
            [71.385368000000028, 32.998438000000078],
            [71.400609000000031, 33.005727000000036],
            [71.410052269000062, 33.007780342000046],
            [71.415850000000034, 33.009041000000025],
            [71.423139000000049, 33.003739000000053],
            [71.430841059000045, 33.004854428000044],
            [71.436392000000069, 33.007053000000042],
            [71.451632000000075, 33.01301600000005],
            [71.461572000000046, 33.025607000000036],
            [71.462551552000036, 33.032463370000073],
            [71.46356000000003, 33.039522000000034],
            [71.477475001000073, 33.048798999000041],
            [71.472174000000052, 33.057413000000054],
            [71.460909000000072, 33.062715000000026],
            [71.458921000000032, 33.069341000000065],
            [71.447656000000052, 33.068678000000034],
            [71.437717000000077, 33.070666000000074],
            [71.428605790000063, 33.080915789000073],
            [71.427114000000074, 33.082594000000029],
            [71.423183762000065, 33.091766548000066],
            [71.423139000000049, 33.091871000000026],
            [71.424229000000082, 33.093870000000038],
            [71.427408043000071, 33.099698476000071],
            [71.42740806900008, 33.099698525000065],
            [71.43109000000004, 33.106449000000055],
            [71.43655859100005, 33.114490960000069],
            [71.442355000000077, 33.123015000000066],
            [71.448319000000083, 33.129641000000049],
            [71.441030000000069, 33.139581000000078],
            [71.443018000000052, 33.150846000000058],
            [71.437717000000077, 33.156810000000064],
            [71.430428000000063, 33.168737000000078],
            [71.435066000000063, 33.177351000000044],
            [71.435050372000035, 33.177368583000032],
            [71.435035182000036, 33.17738567300006],
            [71.429765000000032, 33.18331500000005],
            [71.413199000000077, 33.188616000000025],
            [71.407898000000046, 33.19458000000003],
            [71.408044307000068, 33.195106760000044],
            [71.411211000000037, 33.206508000000042],
            [71.413199000000077, 33.217110000000048],
            [71.418500000000051, 33.227712000000054],
            [71.43837921800008, 33.240301822000049],
            [71.438379436000048, 33.240301643000066],
            [71.443317000000036, 33.236262000000067],
            [71.445668000000069, 33.234339000000034],
            [71.447656000000052, 33.225724000000071],
            [71.454945000000066, 33.219098000000031],
            [71.479463000000067, 33.217110000000048],
            [71.482008084000029, 33.21656460500003],
            [71.482066829000075, 33.216552017000026],
            [71.488740000000064, 33.215122000000065],
            [71.501993000000084, 33.203857000000028],
            [71.502293767000083, 33.20349604200004],
            [71.505306000000076, 33.199881000000062],
            [71.506663758000059, 33.199790505000067],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "110",
      properties: { name: "Multan", count: 1285 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.568990910000082, 30.428149960000042],
            [71.57029091000004, 30.425149960000056],
            [71.575535951000063, 30.420696623000026],
            [71.575590910000074, 30.420649960000048],
            [71.57708133400007, 30.416427093000038],
            [71.577081350000071, 30.416427046000024],
            [71.577929399000084, 30.414024242000039],
            [71.57799091000004, 30.41384996000005],
            [71.580990910000082, 30.415049960000033],
            [71.585290910000083, 30.416749960000061],
            [71.588190910000037, 30.422049960000038],
            [71.590629339000031, 30.420966214000032],
            [71.59629091000005, 30.418449960000032],
            [71.602620526000067, 30.417263157000036],
            [71.602690910000035, 30.417249960000049],
            [71.60428445000008, 30.416276130000028],
            [71.609890910000047, 30.412849960000074],
            [71.611799089000044, 30.411270777000027],
            [71.612757028000033, 30.410478000000069],
            [71.607983000000047, 30.410478000000069],
            [71.607969493000041, 30.410366813000053],
            [71.607937031000063, 30.410240782000074],
            [71.606290910000041, 30.403849960000059],
            [71.606744568000067, 30.403209979000053],
            [71.611580697000079, 30.396387583000035],
            [71.611852000000056, 30.395969000000036],
            [71.611872159000029, 30.395950858000049],
            [71.61551492600006, 30.392672594000032],
            [71.616689000000065, 30.391616000000056],
            [71.625878000000057, 30.388230000000078],
            [71.624690460000068, 30.381105578000074],
            [71.624427000000082, 30.379525000000058],
            [71.62314950800004, 30.374925395000048],
            [71.622845587000029, 30.373831129000052],
            [71.622735818000081, 30.373435905000065],
            [71.622485733000076, 30.372535476000053],
            [71.622442659000058, 30.372527886000057],
            [71.621990910000079, 30.370849960000044],
            [71.623190910000062, 30.364949960000047],
            [71.633923012000082, 30.361700791000032],
            [71.634100000000046, 30.361630000000048],
            [71.643289000000038, 30.35921200000007],
            [71.652478000000031, 30.355343000000062],
            [71.652486159000034, 30.35535197400003],
            [71.65249091000004, 30.355349960000069],
            [71.654170007000062, 30.357203963000075],
            [71.654170026000031, 30.357203984000023],
            [71.65731500000004, 30.360663000000045],
            [71.663105763000033, 30.36452384100005],
            [71.663118000000054, 30.364532000000054],
            [71.665997076000053, 30.359493431000033],
            [71.665990910000062, 30.359449960000063],
            [71.666985066000052, 30.357759895000072],
            [71.660216000000048, 30.35389200000003],
            [71.660210957000061, 30.353849125000067],
            [71.66019091000004, 30.353849960000048],
            [71.659790910000083, 30.350349960000074],
            [71.659710319000055, 30.349592400000063],
            [71.659290910000038, 30.34564996000006],
            [71.656390910000084, 30.339849960000038],
            [71.652790910000078, 30.334149960000047],
            [71.652832128000057, 30.332336385000076],
            [71.652990910000028, 30.32534996000004],
            [71.654871167000067, 30.323933663000048],
            [71.654866592000076, 30.323928434000038],
            [71.654943922000029, 30.323870431000046],
            [71.657592965000049, 30.321883478000075],
            [71.660690910000028, 30.319549960000074],
            [71.671790910000084, 30.316649960000063],
            [71.672994251000034, 30.316396625000039],
            [71.675590910000039, 30.315849960000037],
            [71.682990910000058, 30.314249960000041],
            [71.683179893000045, 30.314233000000058],
            [71.690790910000032, 30.313549960000046],
            [71.693647198000065, 30.314140915000053],
            [71.695628465000084, 30.31105910000008],
            [71.695646946000068, 30.311029618000077],
            [71.697890910000069, 30.307449960000042],
            [71.702890910000065, 30.304449960000056],
            [71.702928876000044, 30.304468571000029],
            [71.702929143000063, 30.304468702000065],
            [71.706126949000065, 30.30254975400004],
            [71.706113018000053, 30.302530406000074],
            [71.707590910000079, 30.301649960000077],
            [71.709435295000048, 30.301796340000067],
            [71.710240097000053, 30.301860213000054],
            [71.713901000000078, 30.30214200000006],
            [71.719221000000061, 30.307462000000044],
            [71.722399862000032, 30.310187007000025],
            [71.722399897000059, 30.310187037000048],
            [71.72938836700007, 30.316147791000049],
            [71.729390910000063, 30.316149960000075],
            [71.729911800000082, 30.315217204000078],
            [71.733690910000064, 30.308449960000075],
            [71.733725101000061, 30.308422101000076],
            [71.737101659000075, 30.305670831000043],
            [71.739050000000077, 30.304077000000063],
            [71.739061483000057, 30.304073937000055],
            [71.739090910000073, 30.304049960000043],
            [71.744802797000034, 30.30254265700006],
            [71.746305000000064, 30.30214200000006],
            [71.747342135000054, 30.302535409000029],
            [71.747874077000063, 30.302730454000027],
            [71.74929091000007, 30.303249960000073],
            [71.754090910000059, 30.305049960000076],
            [71.759090193000077, 30.306985166000061],
            [71.759932332000062, 30.307311155000036],
            [71.760296486000072, 30.307449287000054],
            [71.760296836000066, 30.307449245000043],
            [71.766090910000059, 30.306749960000047],
            [71.768417130000046, 30.306491491000031],
            [71.768426138000052, 30.306490490000044],
            [71.772422000000063, 30.306011000000069],
            [71.774118086000044, 30.304678379000052],
            [71.774211470000068, 30.304604221000034],
            [71.779182879000075, 30.300656337000078],
            [71.779183703000058, 30.300655683000059],
            [71.779190910000068, 30.300649960000044],
            [71.777890910000053, 30.295649960000048],
            [71.776790910000045, 30.291549960000054],
            [71.777290910000033, 30.280349960000024],
            [71.769390910000084, 30.269849960000045],
            [71.76663512600004, 30.268275226000071],
            [71.766644189000033, 30.268268995000028],
            [71.767357993000076, 30.267778243000066],
            [71.768885227000055, 30.266920995000078],
            [71.769410988000061, 30.266616452000051],
            [71.769532100000049, 30.266546299000026],
            [71.769531592000078, 30.266558185000065],
            [71.774890910000067, 30.263549960000034],
            [71.78129436100005, 30.263127755000028],
            [71.783169626000074, 30.263004111000043],
            [71.783732470000075, 30.26296700000006],
            [71.783990910000057, 30.262949960000071],
            [71.790790910000055, 30.263149960000078],
            [71.800990910000053, 30.263449960000059],
            [71.801199691000079, 30.263469265000026],
            [71.801199963000045, 30.263469287000078],
            [71.818276654000044, 30.265048642000068],
            [71.818092000000036, 30.264736000000028],
            [71.820302000000083, 30.264901000000066],
            [71.823505742000066, 30.259866616000068],
            [71.824665221000032, 30.258044604000077],
            [71.825087185000029, 30.257381524000039],
            [71.827073000000041, 30.254261000000042],
            [71.832393000000081, 30.242654000000073],
            [71.824680718000081, 30.233976935000044],
            [71.824655000000064, 30.233948000000055],
            [71.818851000000052, 30.22814500000004],
            [71.81401500000004, 30.222825000000057],
            [71.81365500000004, 30.216703000000052],
            [71.813531000000069, 30.214603000000068],
            [71.812564000000066, 30.209766000000059],
            [71.811597000000063, 30.202995000000044],
            [71.804826000000048, 30.202995000000044],
            [71.799989000000039, 30.200577000000067],
            [71.799989000000039, 30.191388000000074],
            [71.79883286300003, 30.186761660000059],
            [71.798820836000061, 30.186713533000045],
            [71.798055000000033, 30.183649000000059],
            [71.794669000000056, 30.179780000000051],
            [71.793588035000084, 30.175725543000055],
            [71.793574572000068, 30.175675045000048],
            [71.79273500000005, 30.172526000000062],
            [71.786150077000059, 30.166114378000032],
            [71.784996000000035, 30.163820000000044],
            [71.770971000000031, 30.157533000000058],
            [71.770016806000058, 30.156300148000071],
            [71.770012802000053, 30.156301031000055],
            [71.760814000000039, 30.144474000000059],
            [71.760505180000052, 30.144010793000064],
            [71.758573536000029, 30.141113469000061],
            [71.754043000000081, 30.134318000000064],
            [71.750174000000072, 30.124161000000072],
            [71.753559000000052, 30.114005000000077],
            [71.753161000000034, 30.110421768000037],
            [71.753160901000058, 30.110420877000024],
            [71.75259200000005, 30.105299000000059],
            [71.747168879000071, 30.098791105000032],
            [71.746391699000071, 30.097796747000075],
            [71.744930386000078, 30.095927079000035],
            [71.74001700000008, 30.087888000000078],
            [71.736632000000043, 30.079666000000032],
            [71.730345000000057, 30.074346000000048],
            [71.729145731000074, 30.072787223000034],
            [71.728664261000063, 30.072161421000033],
            [71.725508000000048, 30.068059000000062],
            [71.722681861000069, 30.06063974500006],
            [71.722681105000049, 30.060637761000066],
            [71.721639000000039, 30.05790200000007],
            [71.720188000000064, 30.04532800000004],
            [71.712450000000047, 30.046779000000072],
            [71.710458261000042, 30.045451083000046],
            [71.70519500000006, 30.041942000000063],
            [71.703261000000055, 30.044844000000069],
            [71.697941000000071, 30.043393000000037],
            [71.696272441000076, 30.04144644400003],
            [71.692137000000059, 30.036622000000079],
            [71.691526968000062, 30.036164476000067],
            [71.686333000000047, 30.032269000000042],
            [71.674242000000049, 30.031302000000039],
            [71.672108589000061, 30.029680636000023],
            [71.662151000000051, 30.022113000000047],
            [71.658765000000074, 30.015825000000063],
            [71.652687441000069, 30.014305610000065],
            [71.64909300000005, 30.013407000000029],
            [71.641838000000064, 30.010505000000023],
            [71.640762033000044, 30.010624634000067],
            [71.640761535000081, 30.010624689000053],
            [71.639799698000047, 30.010731634000024],
            [71.633132000000046, 30.011473000000024],
            [71.624300854000069, 30.011755797000035],
            [71.621525000000076, 30.011473000000024],
            [71.618623000000071, 30.006636000000071],
            [71.616205000000036, 29.999865000000057],
            [71.615403589000039, 30.000025282000024],
            [71.60895000000005, 30.001316000000031],
            [71.607762639000043, 29.997951948000036],
            [71.606048000000044, 29.993094000000042],
            [71.603306407000048, 29.989255430000071],
            [71.601212000000032, 29.986323000000027],
            [71.596014613000079, 29.981125613000074],
            [71.593474000000072, 29.978585000000066],
            [71.58767000000006, 29.980519000000072],
            [71.584768000000054, 29.983421000000078],
            [71.57944800000007, 29.986323000000027],
            [71.578726361000065, 29.986563588000024],
            [71.573644000000058, 29.98825800000003],
            [71.564939000000038, 29.986807000000056],
            [71.565422000000069, 29.976650000000063],
            [71.568808000000047, 29.972781000000055],
            [71.575095000000033, 29.969396000000074],
            [71.582350000000076, 29.968912000000046],
            [71.586880442000052, 29.964381558000071],
            [71.590088000000037, 29.961174000000028],
            [71.591539000000068, 29.958756000000051],
            [71.591460500000039, 29.958435500000064],
            [71.591382000000067, 29.958115000000078],
            [71.589121000000034, 29.955854000000045],
            [71.581279969000036, 29.95175514500005],
            [71.576605939000046, 29.949476922000031],
            [71.572443926000062, 29.947448266000038],
            [71.567841000000044, 29.94473000000005],
            [71.564765046000048, 29.942211801000042],
            [71.563620448000052, 29.940893387000074],
            [71.563620270000058, 29.940893224000035],
            [71.563173684000049, 29.940486174000057],
            [71.557160450000083, 29.935005277000073],
            [71.557077699000047, 29.934929852000039],
            [71.550913000000037, 29.92683500000004],
            [71.548495000000059, 29.922482000000059],
            [71.546427323000046, 29.91545121300004],
            [71.546077000000082, 29.91426000000007],
            [71.546555384000044, 29.907075330000055],
            [71.546560000000056, 29.907006000000024],
            [71.54597300100005, 29.906453484000053],
            [71.543275818000041, 29.903914747000044],
            [71.538338000000067, 29.899267000000066],
            [71.538143883000032, 29.892642635000072],
            [71.538338000000067, 29.890562000000045],
            [71.538788756000031, 29.889961044000074],
            [71.540537260000065, 29.887629906000029],
            [71.540545042000076, 29.887619531000041],
            [71.544142000000079, 29.882824000000028],
            [71.544521674000066, 29.880039642000042],
            [71.544526189000067, 29.880006528000024],
            [71.545529465000072, 29.872648942000069],
            [71.552364000000068, 29.859125000000063],
            [71.550621887000034, 29.856176680000033],
            [71.549503479000066, 29.85428390800007],
            [71.546077000000082, 29.848485000000039],
            [71.545721850000064, 29.848620284000049],
            [71.535920000000033, 29.852354000000048],
            [71.536030717000074, 29.853092085000071],
            [71.537371000000064, 29.862027000000069],
            [71.533018000000084, 29.867347000000052],
            [71.522149050000053, 29.872124682000049],
            [71.513959853000074, 29.863955959000066],
            [71.514300125000034, 29.86206517100004],
            [71.51367300000004, 29.857674000000031],
            [71.504000000000076, 29.86154300000004],
            [71.498196000000064, 29.86154300000004],
            [71.497979249000082, 29.85764462000003],
            [71.497739724000041, 29.853336627000033],
            [71.496992600000056, 29.847955419000073],
            [71.497712000000035, 29.845099000000062],
            [71.498196000000064, 29.835910000000069],
            [71.500614000000041, 29.829623000000026],
            [71.499647000000039, 29.82333600100003],
            [71.500606889000039, 29.821656132000044],
            [71.503516000000047, 29.816565000000026],
            [71.505698514000073, 29.813447076000045],
            [71.510287000000062, 29.806892000000062],
            [71.511182451000082, 29.802055899000038],
            [71.512705000000039, 29.793833000000063],
            [71.509024000000068, 29.789692000000059],
            [71.508836000000031, 29.78948100100007],
            [71.50381795800007, 29.789744763000044],
            [71.499647000000039, 29.789964000000055],
            [71.494327000000055, 29.793350000000032],
            [71.493360000000052, 29.798670000000072],
            [71.492392000000052, 29.802055000000053],
            [71.485362382000062, 29.800102373000072],
            [71.483687000000032, 29.799637000000075],
            [71.475465000000042, 29.799637000000075],
            [71.475305419000051, 29.799658270000066],
            [71.468210000000056, 29.800604000000078],
            [71.463374000000044, 29.803506000000027],
            [71.458054000000061, 29.811728000000073],
            [71.455519000000038, 29.812967000000071],
            [71.451283000000046, 29.813663000000076],
            [71.442577000000028, 29.813179000000048],
            [71.436026096000035, 29.811431551000055],
            [71.434658961000082, 29.809833389000062],
            [71.431453000000033, 29.803023000000053],
            [71.43051416600008, 29.795199165000042],
            [71.430002000000059, 29.790931000000057],
            [71.427584000000081, 29.785611000000074],
            [71.431453000000033, 29.778357000000028],
            [71.423715000000072, 29.774004000000048],
            [71.422569287000044, 29.761019784000041],
            [71.422264000000041, 29.757560000000069],
            [71.418395000000032, 29.749338000000023],
            [71.422005180000042, 29.744587680000052],
            [71.422005642000045, 29.744587072000058],
            [71.427584000000081, 29.737247000000025],
            [71.434355000000039, 29.740633000000059],
            [71.44064300000008, 29.744502000000068],
            [71.445963000000063, 29.735313000000076],
            [71.445479000000034, 29.725640000000055],
            [71.44480197300004, 29.722932241000024],
            [71.443544000000031, 29.71790100000004],
            [71.441719608000028, 29.714392375000045],
            [71.437257000000045, 29.705810000000042],
            [71.431937000000062, 29.705810000000042],
            [71.424682000000075, 29.702909000000034],
            [71.425650000000076, 29.690817000000038],
            [71.430970000000059, 29.683563000000049],
            [71.431188922000047, 29.68170159500005],
            [71.432904000000065, 29.667119000000071],
            [71.430241359000036, 29.657532723000031],
            [71.428068000000053, 29.649708000000032],
            [71.426719339000044, 29.638246819000074],
            [71.42613300000005, 29.633264000000054],
            [71.421781000000067, 29.624075000000062],
            [71.421686904000069, 29.623228137000069],
            [71.420712483000045, 29.61445834500006],
            [71.420330000000035, 29.611016000000063],
            [71.414866000000075, 29.608654000000058],
            [71.413458906000074, 29.608104892000028],
            [71.40164671000008, 29.603495270000053],
            [71.402390933000049, 29.600971580000078],
            [71.403809673000069, 29.598187292000034],
            [71.405337000000031, 29.594089000000054],
            [71.400699985000074, 29.592807793000077],
            [71.398561121000057, 29.592333935000056],
            [71.39663100000007, 29.591671000000076],
            [71.391489800000045, 29.59171327100006],
            [71.384540000000072, 29.591671000000076],
            [71.379379914000083, 29.588231239000038],
            [71.378560401000072, 29.584988410000051],
            [71.377396565000083, 29.580383092000034],
            [71.380714191000038, 29.575252115000069],
            [71.381638000000066, 29.572788015000071],
            [71.381638000000066, 29.569907000000057],
            [71.376847292000036, 29.56910834200005],
            [71.37029246000003, 29.568015588000037],
            [71.37003100000004, 29.567972000000054],
            [71.364227000000028, 29.566038000000049],
            [71.362283651000041, 29.55809855900003],
            [71.362188065000055, 29.557708049000041],
            [71.362020493000045, 29.551790237000034],
            [71.361747106000053, 29.548182806000057],
            [71.361631801000044, 29.542449447000024],
            [71.361471176000066, 29.534462611000038],
            [71.364229202000047, 29.522512436000056],
            [71.371484756000029, 29.522512436000056],
            [71.380670547000079, 29.515741348000063],
            [71.384372455000062, 29.510898319000034],
            [71.38695800000005, 29.507517000000064],
            [71.378255102000082, 29.505582467000067],
            [71.377733272000057, 29.50534213800006],
            [71.376176834000034, 29.504625320000059],
            [71.372786681000036, 29.503059217000043],
            [71.371965408000051, 29.50267982500003],
            [71.36966181400004, 29.494388339000068],
            [71.369547000000068, 29.493975000000034],
            [71.370090497000035, 29.492996571000049],
            [71.370456167000043, 29.49233827300003],
            [71.374383926000064, 29.48526954700003],
            [71.380241356000056, 29.483316215000059],
            [71.383089000000041, 29.482367000000067],
            [71.387442000000078, 29.476080000000024],
            [71.388493417000063, 29.474359392000054],
            [71.392763138000078, 29.467374802000052],
            [71.39243798800004, 29.467014037000069],
            [71.389802933000055, 29.464090347000024],
            [71.388891393000051, 29.463073918000077],
            [71.38841056800004, 29.462537766000025],
            [71.382284164000055, 29.456883112000071],
            [71.382282820000057, 29.456882465000035],
            [71.382122000000038, 29.45673400000004],
            [71.372934058000055, 29.452382501000045],
            [71.37157970100003, 29.449797086000046],
            [71.36761283900006, 29.442224503000034],
            [71.362890244000084, 29.435686111000052],
            [71.361325000000079, 29.433519000000047],
            [71.357900401000052, 29.427723274000073],
            [71.35522859200006, 29.423201554000059],
            [71.355038000000036, 29.422879000000023],
            [71.355021000000079, 29.422873000000038],
            [71.346332000000075, 29.419494000000043],
            [71.341496000000063, 29.423363000000052],
            [71.338372674000084, 29.426486326000031],
            [71.337143000000083, 29.427716000000032],
            [71.329329868000059, 29.420683938000025],
            [71.327470000000062, 29.419010000000071],
            [71.323601000000053, 29.41417400000006],
            [71.317269531000079, 29.41709577000006],
            [71.317314000000067, 29.421912000000077],
            [71.318319305000045, 29.424257942000054],
            [71.318765000000042, 29.425298000000055],
            [71.323117000000082, 29.426748000000032],
            [71.325052000000028, 29.433519000000047],
            [71.329888000000039, 29.438356000000056],
            [71.334241000000077, 29.446094000000073],
            [71.33520657400004, 29.447703290000049],
            [71.336924411000041, 29.450566352000067],
            [71.338409333000072, 29.452725977000057],
            [71.338449068000045, 29.453107446000047],
            [71.339074847000063, 29.459115188000055],
            [71.339078903000029, 29.459154129000069],
            [71.329889297000079, 29.462537766000025],
            [71.329889110000067, 29.462537591000057],
            [71.32222811400004, 29.455355962000056],
            [71.317316055000049, 29.448995590000038],
            [71.315980035000052, 29.448534231000053],
            [71.314664841000081, 29.448080063000077],
            [71.304738999000051, 29.444643011000039],
            [71.297968000000083, 29.440774000000033],
            [71.294392049000066, 29.440984504000028],
            [71.289746000000036, 29.441258000000062],
            [71.285393000000056, 29.446094000000073],
            [71.275237000000061, 29.446094000000073],
            [71.274681133000058, 29.446168090000072],
            [71.270261976000029, 29.446757108000043],
            [71.270261941000058, 29.446757113000047],
            [71.270262896000077, 29.446758401000068],
            [71.267984390000038, 29.44706153900006],
            [71.256860733000053, 29.44706153900006],
            [71.247488022000084, 29.44706153900006],
            [71.243799360000082, 29.44706153900006],
            [71.243799210000077, 29.44706153900006],
            [71.24348745900005, 29.447324223000066],
            [71.234611000000029, 29.454800000000034],
            [71.234558074000063, 29.455355283000074],
            [71.233770305000064, 29.463620346000027],
            [71.233643000000029, 29.464956000000029],
            [71.233440639000037, 29.467995608000024],
            [71.233160000000055, 29.472211000000073],
            [71.233160000000055, 29.477531000000056],
            [71.227840369000035, 29.480432798000038],
            [71.220858132000046, 29.481459645000029],
            [71.211397171000044, 29.482851028000027],
            [71.211396519000061, 29.482850924000047],
            [71.210642600000028, 29.482730429000071],
            [71.205749307000076, 29.481948356000032],
            [71.199305000000038, 29.480916000000036],
            [71.187697000000071, 29.47898200000003],
            [71.182736813000076, 29.477455708000036],
            [71.168848841000056, 29.473182259000055],
            [71.168835000000058, 29.473178000000075],
            [71.155907208000031, 29.465599226000052],
            [71.154810000000055, 29.464956000000029],
            [71.153119513000036, 29.463171727000031],
            [71.147821426000064, 29.457578659000035],
            [71.146104813000079, 29.455766678000032],
            [71.142566730000055, 29.453998628000079],
            [71.13933292300004, 29.452381976000026],
            [71.132562000000064, 29.460603000000049],
            [71.131111135000083, 29.472209922000047],
            [71.129265006000082, 29.470365006000065],
            [71.125307000000078, 29.466407000000061],
            [71.123869902000081, 29.46291635700004],
            [71.122720718000039, 29.460123062000036],
            [71.121923447000029, 29.458185196000045],
            [71.122080842000059, 29.45783536700003],
            [71.126276016000077, 29.448511124000049],
            [71.122270213000036, 29.447310647000052],
            [71.121438000000069, 29.447061000000076],
            [71.11756900000006, 29.451414000000057],
            [71.113424914000063, 29.451874771000064],
            [71.113216139000031, 29.451897985000073],
            [71.113060174000054, 29.451855499000033],
            [71.107896805000053, 29.450448990000041],
            [71.103544235000072, 29.444162369000026],
            [71.10064125100007, 29.438356400000032],
            [71.10064125100007, 29.437928037000063],
            [71.10064125100007, 29.432069778000027],
            [71.100630995000074, 29.432067970000048],
            [71.10063099100006, 29.432065541000043],
            [71.092417982000029, 29.430617366000035],
            [71.085893379000083, 29.430618000000038],
            [71.085649000000046, 29.430618000000038],
            [71.079845000000034, 29.431101000000069],
            [71.076943000000028, 29.438840000000027],
            [71.071140022000066, 29.442224404000058],
            [71.06574696000007, 29.441979265000043],
            [71.064994990000059, 29.441945085000043],
            [71.060499096000058, 29.442224752000072],
            [71.057478142000036, 29.441578929000059],
            [71.053728000000035, 29.440774000000033],
            [71.047925000000077, 29.434970000000078],
            [71.042194819000031, 29.437223593000056],
            [71.037284500000055, 29.439323000000059],
            [71.031964000000073, 29.450931000000026],
            [71.030880771000056, 29.459583049000059],
            [71.030415824000045, 29.464066992000028],
            [71.028579000000036, 29.47462900000005],
            [71.027128000000062, 29.483335000000068],
            [71.026166613000044, 29.491834420000032],
            [71.025193000000058, 29.500746000000049],
            [71.02478818600008, 29.504187968000053],
            [71.024226000000056, 29.508968000000038],
            [71.024226000000056, 29.515739000000053],
            [71.017939000000069, 29.525895000000048],
            [71.011651000000029, 29.537986000000046],
            [71.01033994200003, 29.540290466000044],
            [71.007782000000077, 29.546208000000036],
            [71.008750000000077, 29.551528000000076],
            [71.015521000000035, 29.554914000000053],
            [71.018422000000044, 29.549110000000042],
            [71.025193000000058, 29.549110000000042],
            [71.030030000000068, 29.551528000000076],
            [71.03051350000004, 29.554430000000025],
            [71.030021738000073, 29.556400877000044],
            [71.029546369000059, 29.558298556000068],
            [71.028064813000071, 29.560703198000056],
            [71.027128110000035, 29.566521799000043],
            [71.025757497000029, 29.569720358000041],
            [71.025769105000052, 29.579635752000058],
            [71.026643877000083, 29.581514180000056],
            [71.031480895000072, 29.587319164000064],
            [71.035833680000053, 29.590219749000028],
            [71.037273395000057, 29.592202931000031],
            [71.04212239800006, 29.595056267000075],
            [71.04526833500006, 29.598203333000072],
            [71.047441242000048, 29.600377018000074],
            [71.054212285000062, 29.602311544000031],
            [71.060983329000067, 29.603277603000038],
            [71.064369555000042, 29.605214037000053],
            [71.064367599000036, 29.608844368000064],
            [71.058080837000034, 29.616819691000046],
            [71.051794026000039, 29.621656709000035],
            [71.048660598000083, 29.627924502000042],
            [71.047500281000055, 29.630244746000074],
            [71.04889200000008, 29.637617000000034],
            [71.049859000000083, 29.644388000000049],
            [71.05144109500003, 29.648167120000039],
            [71.053245000000061, 29.653577000000041],
            [71.060983000000078, 29.653577000000041],
            [71.06485200000003, 29.65406100000007],
            [71.068721000000039, 29.656962000000078],
            [71.069689000000039, 29.662283000000059],
            [71.069689000000039, 29.670021000000077],
            [71.071140658000047, 29.672221801000035],
            [71.072590000000048, 29.674374000000057],
            [71.078129970000077, 29.677356459000066],
            [71.080812000000037, 29.672439000000054],
            [71.086764611000035, 29.670149971000058],
            [71.092903000000035, 29.668570000000045],
            [71.106445000000065, 29.669054000000074],
            [71.114667000000054, 29.669054000000074],
            [71.116411295000034, 29.669215263000069],
            [71.119504000000063, 29.669054000000074],
            [71.122889000000043, 29.672923000000026],
            [71.125791000000049, 29.681628000000046],
            [71.127242000000081, 29.685497000000055],
            [71.138366000000076, 29.684046000000023],
            [71.142016646000059, 29.684290499000042],
            [71.147008163000066, 29.685121949000063],
            [71.152875450000067, 29.68501436300005],
            [71.158957832000056, 29.682841819000032],
            [71.167868000000055, 29.686465000000055],
            [71.175606000000073, 29.689850000000035],
            [71.181894000000057, 29.69323600000007],
            [71.183345000000031, 29.700974000000031],
            [71.175123000000042, 29.70967950000005],
            [71.163032000000044, 29.718869000000041],
            [71.163515000000075, 29.729992000000038],
            [71.164818260000061, 29.734149560000048],
            [71.170770323000056, 29.736763954000025],
            [71.173296786000037, 29.740455054000051],
            [71.175685453000028, 29.743901634000053],
            [71.177442486000075, 29.746173180000028],
            [71.180443000000082, 29.747887000000048],
            [71.17878475100008, 29.751057916000036],
            [71.175123000000042, 29.762397000000078],
            [71.175039361000074, 29.765608101000055],
            [71.173068166000064, 29.770987120000029],
            [71.171253500000034, 29.775939000000051],
            [71.166901000000053, 29.782710000000066],
            [71.166901000000053, 29.789288702000079],
            [71.169803000000059, 29.791899000000058],
            [71.177510238000082, 29.797074726000062],
            [71.181155186000069, 29.796051048000038],
            [71.183229662000031, 29.795424017000073],
            [71.178508000000079, 29.806892000000062],
            [71.177328752000051, 29.814750746000072],
            [71.178130008000039, 29.81884179900004],
            [71.178024333000053, 29.828172000000052],
            [71.184312000000034, 29.833492000000035],
            [71.189405000000079, 29.839048348000063],
            [71.190922326000077, 29.845746740000038],
            [71.191511870000056, 29.848373250000066],
            [71.19216126300006, 29.851134012000045],
            [71.193017000000054, 29.854772000000025],
            [71.197245376000069, 29.861334552000073],
            [71.197840038000038, 29.869957248000048],
            [71.191566500000079, 29.878471000000047],
            [71.176090000000045, 29.877987000000076],
            [71.174037688000055, 29.884705063000069],
            [71.173672000000067, 29.888144000000068],
            [71.175606000000073, 29.894915000000026],
            [71.180443000000082, 29.896366000000057],
            [71.181426760000079, 29.899036537000029],
            [71.182730811000056, 29.902576545000045],
            [71.183828000000062, 29.905555000000049],
            [71.184795500000064, 29.910875000000033],
            [71.189357305000044, 29.92445619800003],
            [71.191387654000039, 29.928807473000063],
            [71.192633397000066, 29.932531090000055],
            [71.193985000000055, 29.936024000000032],
            [71.197024371000055, 29.942393674000073],
            [71.199190427000076, 29.947268555000051],
            [71.203760711000029, 29.954747670000074],
            [71.208474082000066, 29.963642765000031],
            [71.209461000000033, 29.965527000000066],
            [71.209461000000033, 29.972781000000055],
            [71.212363000000039, 29.978585000000066],
            [71.215265000000045, 29.984872000000053],
            [71.220101000000056, 29.985356000000024],
            [71.226393999000038, 29.98073805000007],
            [71.229774000000077, 29.977618000000064],
            [71.236062000000061, 29.977618000000064],
            [71.239077790000067, 29.979937765000045],
            [71.242349000000047, 29.982454000000075],
            [71.250571000000036, 29.985840000000053],
            [71.252205338000067, 29.987240689000032],
            [71.256905105000044, 29.991268564000052],
            [71.261695000000032, 29.994061000000045],
            [71.265708393000068, 29.992388839000057],
            [71.273302000000058, 29.989225000000033],
            [71.276204000000064, 29.994545000000073],
            [71.276204000000064, 30.003251000000034],
            [71.271368000000052, 30.009538000000077],
            [71.268167724000079, 30.012232640000036],
            [71.262178000000063, 30.017276000000038],
            [71.259276500000055, 30.023564000000079],
            [71.263146000000063, 30.029851000000065],
            [71.263489807000042, 30.031621212000061],
            [71.267498000000046, 30.035655000000077],
            [71.269433000000049, 30.043877000000066],
            [71.266246615000057, 30.051766761000067],
            [71.272801173000062, 30.053710906000049],
            [71.275720000000035, 30.05355000000003],
            [71.277600176000078, 30.056135447000031],
            [71.283942000000081, 30.063222000000053],
            [71.29168100000004, 30.073379000000045],
            [71.291197000000068, 30.084019000000069],
            [71.290460832000065, 30.088966061000065],
            [71.290230000000065, 30.09272500000003],
            [71.289420885000084, 30.095960125000033],
            [71.287811000000033, 30.102397000000053],
            [71.286985220000076, 30.104424591000054],
            [71.286844000000031, 30.107234000000062],
            [71.290230000000065, 30.113521000000048],
            [71.288162230000069, 30.122873319000064],
            [71.285877000000085, 30.13190000000003],
            [71.290230000000065, 30.144474000000059],
            [71.292680119000067, 30.147904575000041],
            [71.294099000000074, 30.151729000000046],
            [71.299419000000057, 30.15850000000006],
            [71.298935000000029, 30.169140000000027],
            [71.305706000000043, 30.173009000000036],
            [71.307641000000046, 30.181715000000054],
            [71.311510000000055, 30.184133000000031],
            [71.312687614000083, 30.185397186000046],
            [71.31828100000007, 30.18800200000004],
            [71.317797000000041, 30.199126000000035],
            [71.321098704000065, 30.199301098000035],
            [71.324568000000056, 30.199126000000035],
            [71.327470000000062, 30.203479000000073],
            [71.327470000000062, 30.21025000000003],
            [71.333757500000047, 30.20589700000005],
            [71.336659000000054, 30.212668000000065],
            [71.338432690000047, 30.214118864000056],
            [71.339664194000079, 30.216202377000059],
            [71.341979000000038, 30.218472000000077],
            [71.346581643000036, 30.222601432000033],
            [71.347496129000035, 30.224370957000076],
            [71.35213600000003, 30.22717700000004],
            [71.360841500000049, 30.227661000000069],
            [71.361815198000045, 30.235732879000068],
            [71.362292000000082, 30.238785000000064],
            [71.369547000000068, 30.247490000000028],
            [71.376802000000055, 30.253778000000068],
            [71.376802000000055, 30.258130000000051],
            [71.366645000000062, 30.264418000000035],
            [71.365294403000064, 30.268703997000046],
            [71.365194000000031, 30.272640000000024],
            [71.372651671000028, 30.276664826000058],
            [71.37385336300008, 30.278104773000052],
            [71.381154500000036, 30.271189000000049],
            [71.388409000000081, 30.271189000000049],
            [71.392803525000033, 30.273197484000036],
            [71.394213000000036, 30.283280000000047],
            [71.397361781000029, 30.286625733000051],
            [71.402310797000041, 30.291964566000047],
            [71.403544907000082, 30.293858016000058],
            [71.408722000000068, 30.300207000000057],
            [71.408802834000028, 30.309133872000075],
            [71.410093609000057, 30.318834649000053],
            [71.40920600000004, 30.328742000000034],
            [71.408557655000038, 30.334808143000032],
            [71.412108000000046, 30.341801000000032],
            [71.424888044000056, 30.346756163000066],
            [71.427580355000032, 30.349285000000066],
            [71.436290000000042, 30.354859000000033],
            [71.449538686000039, 30.361529208000036],
            [71.451682555000048, 30.362144813000043],
            [71.456424178000077, 30.368775671000037],
            [71.459392501000082, 30.373511841000038],
            [71.462825597000062, 30.375410614000032],
            [71.469565174000081, 30.381138957000076],
            [71.473530000000039, 30.387747000000047],
            [71.488559426000052, 30.388344618000076],
            [71.489974000000075, 30.395969000000036],
            [71.489974000000075, 30.399065591000067],
            [71.489974000000075, 30.404191000000026],
            [71.484815724000043, 30.405288271000074],
            [71.476432000000045, 30.407092000000034],
            [71.474974047000046, 30.408167831000071],
            [71.463374000000044, 30.416765000000055],
            [71.463374000000044, 30.422693657000025],
            [71.465928260000055, 30.424202381000043],
            [71.474497500000041, 30.426438000000076],
            [71.482771400000047, 30.425551449000068],
            [71.48803950000007, 30.424987000000044],
            [71.489490000000046, 30.428856000000053],
            [71.493360000000052, 30.431275000000028],
            [71.503516000000047, 30.436595000000068],
            [71.512222000000065, 30.430791000000056],
            [71.523518922000051, 30.430333826000037],
            [71.530116694000071, 30.42547055600005],
            [71.532299636000062, 30.427872126000068],
            [71.53495300000003, 30.430791000000056],
            [71.529150929000082, 30.432237805000057],
            [71.526644473000033, 30.437568169000031],
            [71.526735643000052, 30.445772740000052],
            [71.528670671000043, 30.451575618000049],
            [71.532384152000077, 30.453936226000053],
            [71.541241349000074, 30.454967528000054],
            [71.546762756000078, 30.451529131000029],
            [71.549946000000034, 30.450620000000072],
            [71.555749500000047, 30.44578400000006],
            [71.560565337000071, 30.445248023000033],
            [71.560603356000058, 30.445151424000073],
            [71.56059091000003, 30.445149960000037],
            [71.563502691000053, 30.437784868000051],
            [71.563971000000038, 30.436595000000068],
            [71.563975733000063, 30.436588350000079],
            [71.56399091000003, 30.436549960000036],
            [71.568220766000081, 30.429443802000037],
            [71.568990910000082, 30.428149960000042],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "111",
      properties: { name: "Muzaffargarh", count: 1013 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [71.241220939000073, 30.762569686000063],
            [71.259237651000035, 30.759325526000055],
            [71.263326000000063, 30.758559000000048],
            [71.268824281000036, 30.754881366000063],
            [71.273077416000035, 30.750445103000061],
            [71.280405601000041, 30.746722000000034],
            [71.285132247000035, 30.746641370000077],
            [71.297604849000038, 30.747486976000062],
            [71.300203000000067, 30.746722000000034],
            [71.302191582000034, 30.745193650000033],
            [71.310706929000048, 30.74132499500007],
            [71.32611111600005, 30.733212842000057],
            [71.328512605000071, 30.732174194000038],
            [71.332982500000071, 30.729877000000045],
            [71.335000059000038, 30.72747503800008],
            [71.337517336000076, 30.72542764700006],
            [71.339639727000076, 30.723465515000044],
            [71.343208668000045, 30.720408442000064],
            [71.354290138000067, 30.71014798300007],
            [71.369859500000075, 30.695732000000078],
            [71.380779543000074, 30.683435348000046],
            [71.391633318000061, 30.672331303000078],
            [71.397217087000058, 30.665835577000053],
            [71.401979191000066, 30.661522121000075],
            [71.42045947500003, 30.644121675000065],
            [71.432482940000057, 30.634908221000046],
            [71.448769609000067, 30.622538504000033],
            [71.45135300000004, 30.620612000000051],
            [71.473372307000034, 30.609205472000042],
            [71.474740662000045, 30.607871749000026],
            [71.490181565000057, 30.599451561000024],
            [71.508436306000078, 30.591426554000066],
            [71.52601750000008, 30.583735000000047],
            [71.542666805000067, 30.584308756000041],
            [71.546848985000054, 30.584104829000069],
            [71.561092101000042, 30.584660218000067],
            [71.569645844000036, 30.584993758000053],
            [71.573365000000081, 30.585100990000058],
            [71.589300000000037, 30.591475000000059],
            [71.592032000000074, 30.602401000000043],
            [71.597950000000083, 30.618336000000056],
            [71.609655459000066, 30.615246802000058],
            [71.630730000000028, 30.60968500000007],
            [71.641235035000079, 30.609775465000041],
            [71.651217000000031, 30.60968500000007],
            [71.65602926300005, 30.614059199000053],
            [71.658713222000074, 30.617360333000079],
            [71.65871358000004, 30.617360724000036],
            [71.658864000000051, 30.617221000000029],
            [71.667385111000044, 30.608700356000043],
            [71.671018341000035, 30.605066659000045],
            [71.671019000000058, 30.605066000000079],
            [71.682595000000049, 30.601593000000037],
            [71.684658266000042, 30.600507179000033],
            [71.693592000000081, 30.595805000000041],
            [71.695510327000079, 30.592311658000028],
            [71.69648600000005, 30.590596000000062],
            [71.70343100000008, 30.580756000000065],
            [71.705455097000083, 30.580233883000062],
            [71.71790100000004, 30.580756000000065],
            [71.717902000000038, 30.580756000000065],
            [71.72564464900006, 30.576454363000039],
            [71.725644659000068, 30.576454268000077],
            [71.72647500000005, 30.57599300000004],
            [71.72647500000005, 30.568796686000042],
            [71.72647500000005, 30.568629000000044],
            [71.720830166000042, 30.56040719300006],
            [71.718889764000039, 30.557580960000053],
            [71.717123081000068, 30.554652268000041],
            [71.716790910000043, 30.554149960000075],
            [71.716567415000043, 30.55384977600005],
            [71.716469071000063, 30.553717686000027],
            [71.716460364000056, 30.553705991000072],
            [71.712288830000034, 30.54810304800003],
            [71.706590910000045, 30.540449960000046],
            [71.704680437000036, 30.539802060000056],
            [71.695090910000033, 30.536549960000059],
            [71.686943711000083, 30.532427281000025],
            [71.686802846000035, 30.53235600000005],
            [71.68679091000007, 30.532349960000033],
            [71.68049091000006, 30.532349960000033],
            [71.680478569000059, 30.532351412000025],
            [71.678564513000083, 30.532177276000027],
            [71.678790910000032, 30.53254996000004],
            [71.677874071000076, 30.532114461000049],
            [71.67479091000007, 30.530649960000062],
            [71.674681228000054, 30.530552298000032],
            [71.669006989000081, 30.525499893000074],
            [71.669009651000067, 30.525494107000043],
            [71.66774511400007, 30.524376306000079],
            [71.667490910000083, 30.524149960000045],
            [71.667488738000031, 30.524147937000066],
            [71.666018461000078, 30.522778364000033],
            [71.66019091000004, 30.517349960000047],
            [71.653490910000073, 30.513049960000046],
            [71.648090910000064, 30.509649960000047],
            [71.644970389000036, 30.509361912000031],
            [71.641590910000048, 30.509049960000027],
            [71.636508263000053, 30.508651321000059],
            [71.63646403000007, 30.508625524000024],
            [71.635390910000069, 30.507649960000037],
            [71.630690903000072, 30.505249960000071],
            [71.630691655000078, 30.505272251000065],
            [71.625712415000066, 30.505328234000046],
            [71.623943000000054, 30.505272000000048],
            [71.620129578000046, 30.504391886000064],
            [71.617690910000078, 30.503849960000025],
            [71.608490910000057, 30.500949960000071],
            [71.608489553000084, 30.500926437000032],
            [71.608466000000078, 30.500919000000067],
            [71.608317199000055, 30.497938976000057],
            [71.608190910000076, 30.495749960000069],
            [71.608053367000082, 30.492655229000036],
            [71.607983000000047, 30.491246000000046],
            [71.605089991000057, 30.483049473000051],
            [71.605081000000041, 30.483024000000057],
            [71.604983665000077, 30.482993183000076],
            [71.603333244000055, 30.482470643000056],
            [71.597570966000035, 30.479068813000026],
            [71.594441000000074, 30.477221000000043],
            [71.590677437000068, 30.46969290100003],
            [71.59059091000006, 30.469449960000077],
            [71.590156844000035, 30.469232927000064],
            [71.590115629000081, 30.469212319000064],
            [71.587547202000053, 30.467694575000053],
            [71.586855833000072, 30.467286029000036],
            [71.581069307000064, 30.463791223000044],
            [71.578481000000068, 30.462228000000039],
            [71.571710000000053, 30.453039000000047],
            [71.568834337000055, 30.450492642000029],
            [71.566990910000072, 30.449049960000025],
            [71.56631644600003, 30.448263086000054],
            [71.565737713000033, 30.447750626000072],
            [71.563175871000055, 30.445454073000064],
            [71.562278956000057, 30.445348553000031],
            [71.562277958000038, 30.445348436000074],
            [71.561614526000028, 30.445270385000072],
            [71.560603356000058, 30.445151424000073],
            [71.560565337000071, 30.445248023000033],
            [71.555749500000047, 30.44578400000006],
            [71.549946000000034, 30.450620000000072],
            [71.546762756000078, 30.451529131000029],
            [71.541241349000074, 30.454967528000054],
            [71.532384152000077, 30.453936226000053],
            [71.528670671000043, 30.451575618000049],
            [71.526735643000052, 30.445772740000052],
            [71.526644473000033, 30.437568169000031],
            [71.529150929000082, 30.432237805000057],
            [71.53495300000003, 30.430791000000056],
            [71.532299636000062, 30.427872126000068],
            [71.530116694000071, 30.42547055600005],
            [71.523518922000051, 30.430333826000037],
            [71.512222000000065, 30.430791000000056],
            [71.503516000000047, 30.436595000000068],
            [71.493360000000052, 30.431275000000028],
            [71.489490000000046, 30.428856000000053],
            [71.48803950000007, 30.424987000000044],
            [71.482771400000047, 30.425551449000068],
            [71.474497500000041, 30.426438000000076],
            [71.465928260000055, 30.424202381000043],
            [71.463374000000044, 30.422693657000025],
            [71.463374000000044, 30.416765000000055],
            [71.474974047000046, 30.408167831000071],
            [71.476432000000045, 30.407092000000034],
            [71.484815724000043, 30.405288271000074],
            [71.489974000000075, 30.404191000000026],
            [71.489974000000075, 30.399065591000067],
            [71.489974000000075, 30.395969000000036],
            [71.488559426000052, 30.388344618000076],
            [71.473530000000039, 30.387747000000047],
            [71.469565174000081, 30.381138957000076],
            [71.462825597000062, 30.375410614000032],
            [71.459392501000082, 30.373511841000038],
            [71.456424178000077, 30.368775671000037],
            [71.451682555000048, 30.362144813000043],
            [71.449538686000039, 30.361529208000036],
            [71.436290000000042, 30.354859000000033],
            [71.427580355000032, 30.349285000000066],
            [71.424888044000056, 30.346756163000066],
            [71.412108000000046, 30.341801000000032],
            [71.408557655000038, 30.334808143000032],
            [71.40920600000004, 30.328742000000034],
            [71.410093609000057, 30.318834649000053],
            [71.408802834000028, 30.309133872000075],
            [71.408722000000068, 30.300207000000057],
            [71.403544907000082, 30.293858016000058],
            [71.402310797000041, 30.291964566000047],
            [71.397361781000029, 30.286625733000051],
            [71.394213000000036, 30.283280000000047],
            [71.392803525000033, 30.273197484000036],
            [71.388409000000081, 30.271189000000049],
            [71.381154500000036, 30.271189000000049],
            [71.37385336300008, 30.278104773000052],
            [71.372651671000028, 30.276664826000058],
            [71.365194000000031, 30.272640000000024],
            [71.365294403000064, 30.268703997000046],
            [71.366645000000062, 30.264418000000035],
            [71.376802000000055, 30.258130000000051],
            [71.376802000000055, 30.253778000000068],
            [71.369547000000068, 30.247490000000028],
            [71.362292000000082, 30.238785000000064],
            [71.361815198000045, 30.235732879000068],
            [71.360841500000049, 30.227661000000069],
            [71.35213600000003, 30.22717700000004],
            [71.347496129000035, 30.224370957000076],
            [71.346581643000036, 30.222601432000033],
            [71.341979000000038, 30.218472000000077],
            [71.339664194000079, 30.216202377000059],
            [71.338432690000047, 30.214118864000056],
            [71.336659000000054, 30.212668000000065],
            [71.333757500000047, 30.20589700000005],
            [71.327470000000062, 30.21025000000003],
            [71.327470000000062, 30.203479000000073],
            [71.324568000000056, 30.199126000000035],
            [71.321098704000065, 30.199301098000035],
            [71.317797000000041, 30.199126000000035],
            [71.31828100000007, 30.18800200000004],
            [71.312687614000083, 30.185397186000046],
            [71.311510000000055, 30.184133000000031],
            [71.307641000000046, 30.181715000000054],
            [71.305706000000043, 30.173009000000036],
            [71.298935000000029, 30.169140000000027],
            [71.299419000000057, 30.15850000000006],
            [71.294099000000074, 30.151729000000046],
            [71.292680119000067, 30.147904575000041],
            [71.290230000000065, 30.144474000000059],
            [71.285877000000085, 30.13190000000003],
            [71.288162230000069, 30.122873319000064],
            [71.290230000000065, 30.113521000000048],
            [71.286844000000031, 30.107234000000062],
            [71.286985220000076, 30.104424591000054],
            [71.287811000000033, 30.102397000000053],
            [71.289420885000084, 30.095960125000033],
            [71.290230000000065, 30.09272500000003],
            [71.290460832000065, 30.088966061000065],
            [71.291197000000068, 30.084019000000069],
            [71.29168100000004, 30.073379000000045],
            [71.283942000000081, 30.063222000000053],
            [71.277600176000078, 30.056135447000031],
            [71.275720000000035, 30.05355000000003],
            [71.272801173000062, 30.053710906000049],
            [71.266246615000057, 30.051766761000067],
            [71.269433000000049, 30.043877000000066],
            [71.267498000000046, 30.035655000000077],
            [71.263489807000042, 30.031621212000061],
            [71.263146000000063, 30.029851000000065],
            [71.259276500000055, 30.023564000000079],
            [71.262178000000063, 30.017276000000038],
            [71.268167724000079, 30.012232640000036],
            [71.271368000000052, 30.009538000000077],
            [71.276204000000064, 30.003251000000034],
            [71.276204000000064, 29.994545000000073],
            [71.273302000000058, 29.989225000000033],
            [71.265708393000068, 29.992388839000057],
            [71.261695000000032, 29.994061000000045],
            [71.256905105000044, 29.991268564000052],
            [71.252205338000067, 29.987240689000032],
            [71.250571000000036, 29.985840000000053],
            [71.242349000000047, 29.982454000000075],
            [71.239077790000067, 29.979937765000045],
            [71.236062000000061, 29.977618000000064],
            [71.229774000000077, 29.977618000000064],
            [71.226393999000038, 29.98073805000007],
            [71.220101000000056, 29.985356000000024],
            [71.215265000000045, 29.984872000000053],
            [71.212363000000039, 29.978585000000066],
            [71.209461000000033, 29.972781000000055],
            [71.209461000000033, 29.965527000000066],
            [71.208474082000066, 29.963642765000031],
            [71.203760711000029, 29.954747670000074],
            [71.199190427000076, 29.947268555000051],
            [71.197024371000055, 29.942393674000073],
            [71.193985000000055, 29.936024000000032],
            [71.192633397000066, 29.932531090000055],
            [71.191387654000039, 29.928807473000063],
            [71.189357305000044, 29.92445619800003],
            [71.184795500000064, 29.910875000000033],
            [71.183828000000062, 29.905555000000049],
            [71.182730811000056, 29.902576545000045],
            [71.181426760000079, 29.899036537000029],
            [71.180443000000082, 29.896366000000057],
            [71.175606000000073, 29.894915000000026],
            [71.173672000000067, 29.888144000000068],
            [71.174037688000055, 29.884705063000069],
            [71.176090000000045, 29.877987000000076],
            [71.191566500000079, 29.878471000000047],
            [71.197840038000038, 29.869957248000048],
            [71.197245376000069, 29.861334552000073],
            [71.193017000000054, 29.854772000000025],
            [71.19216126300006, 29.851134012000045],
            [71.191511870000056, 29.848373250000066],
            [71.190922326000077, 29.845746740000038],
            [71.189405000000079, 29.839048348000063],
            [71.184312000000034, 29.833492000000035],
            [71.178024333000053, 29.828172000000052],
            [71.178130008000039, 29.81884179900004],
            [71.177328752000051, 29.814750746000072],
            [71.178508000000079, 29.806892000000062],
            [71.183229662000031, 29.795424017000073],
            [71.181155186000069, 29.796051048000038],
            [71.177510238000082, 29.797074726000062],
            [71.169803000000059, 29.791899000000058],
            [71.166901000000053, 29.789288702000079],
            [71.166901000000053, 29.782710000000066],
            [71.171253500000034, 29.775939000000051],
            [71.173068166000064, 29.770987120000029],
            [71.175039361000074, 29.765608101000055],
            [71.175123000000042, 29.762397000000078],
            [71.17878475100008, 29.751057916000036],
            [71.180443000000082, 29.747887000000048],
            [71.177442486000075, 29.746173180000028],
            [71.175685453000028, 29.743901634000053],
            [71.173296786000037, 29.740455054000051],
            [71.170770323000056, 29.736763954000025],
            [71.164818260000061, 29.734149560000048],
            [71.163515000000075, 29.729992000000038],
            [71.163032000000044, 29.718869000000041],
            [71.175123000000042, 29.70967950000005],
            [71.183345000000031, 29.700974000000031],
            [71.181894000000057, 29.69323600000007],
            [71.175606000000073, 29.689850000000035],
            [71.167868000000055, 29.686465000000055],
            [71.158957832000056, 29.682841819000032],
            [71.152875450000067, 29.68501436300005],
            [71.147008163000066, 29.685121949000063],
            [71.142016646000059, 29.684290499000042],
            [71.138366000000076, 29.684046000000023],
            [71.127242000000081, 29.685497000000055],
            [71.125791000000049, 29.681628000000046],
            [71.122889000000043, 29.672923000000026],
            [71.119504000000063, 29.669054000000074],
            [71.116411295000034, 29.669215263000069],
            [71.114667000000054, 29.669054000000074],
            [71.106445000000065, 29.669054000000074],
            [71.092903000000035, 29.668570000000045],
            [71.086764611000035, 29.670149971000058],
            [71.080812000000037, 29.672439000000054],
            [71.078129970000077, 29.677356459000066],
            [71.072590000000048, 29.674374000000057],
            [71.071140658000047, 29.672221801000035],
            [71.069689000000039, 29.670021000000077],
            [71.069689000000039, 29.662283000000059],
            [71.068721000000039, 29.656962000000078],
            [71.06485200000003, 29.65406100000007],
            [71.060983000000078, 29.653577000000041],
            [71.053245000000061, 29.653577000000041],
            [71.05144109500003, 29.648167120000039],
            [71.049859000000083, 29.644388000000049],
            [71.04889200000008, 29.637617000000034],
            [71.047500281000055, 29.630244746000074],
            [71.048660598000083, 29.627924502000042],
            [71.051794026000039, 29.621656709000035],
            [71.058080837000034, 29.616819691000046],
            [71.064367599000036, 29.608844368000064],
            [71.064369555000042, 29.605214037000053],
            [71.060983329000067, 29.603277603000038],
            [71.054212285000062, 29.602311544000031],
            [71.047441242000048, 29.600377018000074],
            [71.04526833500006, 29.598203333000072],
            [71.04212239800006, 29.595056267000075],
            [71.037273395000057, 29.592202931000031],
            [71.035833680000053, 29.590219749000028],
            [71.031480895000072, 29.587319164000064],
            [71.026643877000083, 29.581514180000056],
            [71.025769105000052, 29.579635752000058],
            [71.025757497000029, 29.569720358000041],
            [71.027128110000035, 29.566521799000043],
            [71.028064813000071, 29.560703198000056],
            [71.029546369000059, 29.558298556000068],
            [71.030021738000073, 29.556400877000044],
            [71.03051350000004, 29.554430000000025],
            [71.030030000000068, 29.551528000000076],
            [71.025193000000058, 29.549110000000042],
            [71.018422000000044, 29.549110000000042],
            [71.015521000000035, 29.554914000000053],
            [71.008750000000077, 29.551528000000076],
            [71.007782000000077, 29.546208000000036],
            [71.01033994200003, 29.540290466000044],
            [71.011651000000029, 29.537986000000046],
            [71.017939000000069, 29.525895000000048],
            [71.024226000000056, 29.515739000000053],
            [71.024226000000056, 29.508968000000038],
            [71.02478818600008, 29.504187968000053],
            [71.025193000000058, 29.500746000000049],
            [71.026166613000044, 29.491834420000032],
            [71.027128000000062, 29.483335000000068],
            [71.028579000000036, 29.47462900000005],
            [71.030415824000045, 29.464066992000028],
            [71.030880771000056, 29.459583049000059],
            [71.031964000000073, 29.450931000000026],
            [71.037284500000055, 29.439323000000059],
            [71.042194819000031, 29.437223593000056],
            [71.047925000000077, 29.434970000000078],
            [71.053728000000035, 29.440774000000033],
            [71.057478142000036, 29.441578929000059],
            [71.060499096000058, 29.442224752000072],
            [71.064994990000059, 29.441945085000043],
            [71.063791000000037, 29.432803000000035],
            [71.060669000000075, 29.427340000000072],
            [71.055207000000053, 29.424219000000051],
            [71.05130500000007, 29.423048000000051],
            [71.045843000000048, 29.421878000000049],
            [71.041551000000084, 29.419147000000066],
            [71.038430000000062, 29.415245000000027],
            [71.038430000000062, 29.415138418000026],
            [71.038430000000062, 29.407051000000024],
            [71.039990000000046, 29.401199000000076],
            [71.040771000000063, 29.391835000000071],
            [71.041852579000079, 29.387603747000071],
            [71.042722000000083, 29.385202000000049],
            [71.03257700000006, 29.380520000000047],
            [71.024384000000055, 29.373497000000043],
            [71.022103732000062, 29.373182418000056],
            [71.01306900000003, 29.371936000000062],
            [71.005656000000045, 29.371936000000062],
            [70.999023000000079, 29.371156000000042],
            [70.992390000000057, 29.364133000000038],
            [70.986537000000055, 29.359451000000035],
            [70.979514000000052, 29.359841000000074],
            [70.970540000000028, 29.363743000000056],
            [70.966047462000063, 29.362245231000031],
            [70.965497630000073, 29.362061922000066],
            [70.964298000000042, 29.355939000000035],
            [70.960006000000078, 29.351257000000032],
            [70.958674691000056, 29.350712472000055],
            [70.951422000000036, 29.347746000000029],
            [70.94166800000005, 29.34930600000007],
            [70.934645000000046, 29.351257000000032],
            [70.924670589000073, 29.344161204000045],
            [70.920989000000077, 29.336431000000061],
            [70.919937177000065, 29.334011969000073],
            [70.917087000000038, 29.327457000000038],
            [70.916375139000081, 29.321403445000044],
            [70.916362723000077, 29.321297858000037],
            [70.924891000000059, 29.314972000000068],
            [70.935815000000048, 29.308729000000028],
            [70.944399000000033, 29.303267000000062],
            [70.952203000000054, 29.298975000000041],
            [70.955324000000076, 29.291952000000038],
            [70.955324000000076, 29.281417000000033],
            [70.952983000000074, 29.275565000000029],
            [70.946740000000034, 29.274394000000029],
            [70.942615852000074, 29.274590316000058],
            [70.938547000000028, 29.274784000000068],
            [70.927622000000042, 29.27946600000007],
            [70.919819000000075, 29.28063700000007],
            [70.912015000000054, 29.286879000000056],
            [70.909174783000083, 29.284748609000076],
            [70.911235000000033, 29.279076000000032],
            [70.915527000000054, 29.273614000000066],
            [70.918648000000076, 29.263079000000062],
            [70.918648000000076, 29.254495000000077],
            [70.913576000000035, 29.249813000000074],
            [70.910970000000077, 29.244973000000073],
            [70.906483023000078, 29.247570545000031],
            [70.906482978000042, 29.247570223000025],
            [70.897733000000073, 29.252636000000052],
            [70.881066360000034, 29.257969325000033],
            [70.876283000000058, 29.25950000000006],
            [70.863146805000042, 29.266872181000053],
            [70.85654900000003, 29.269796000000042],
            [70.842821000000072, 29.266364000000067],
            [70.843679000000066, 29.240624000000025],
            [70.838671232000081, 29.239983594000023],
            [70.835112967000043, 29.23952855400006],
            [70.832614620000072, 29.239209059000075],
            [70.824803000000031, 29.238050000000044],
            [70.813232833000029, 29.244066487000055],
            [70.803353000000072, 29.249204000000077],
            [70.786002265000036, 29.252508902000045],
            [70.775897000000043, 29.232902000000024],
            [70.774023407000072, 29.227047023000068],
            [70.773180477000039, 29.224412865000033],
            [70.769837780000046, 29.213966936000077],
            [70.769033000000036, 29.211452000000065],
            [70.758190054000067, 29.183570139000039],
            [70.757021000000066, 29.180564000000061],
            [70.756502332000082, 29.179785998000057],
            [70.751249303000066, 29.171906454000066],
            [70.745359816000075, 29.163072225000064],
            [70.745359903000065, 29.163073629000053],
            [70.743292000000054, 29.159972000000039],
            [70.740367669000079, 29.160946777000049],
            [70.740271396000082, 29.160978868000029],
            [70.740271394000047, 29.160979137000027],
            [70.727848000000051, 29.165120000000059],
            [70.713262000000043, 29.165120000000059],
            [70.708273872000063, 29.159134247000054],
            [70.704901755000037, 29.155223291000027],
            [70.697227822000059, 29.14523276400007],
            [70.697265261000041, 29.145225868000068],
            [70.69009600000004, 29.135948000000042],
            [70.685625653000045, 29.119060023000031],
            [70.682374000000038, 29.106776000000025],
            [70.681053973000076, 29.105533621000063],
            [70.675787600000035, 29.100577035000072],
            [70.66778800000003, 29.093048000000067],
            [70.651736523000068, 29.08930265500004],
            [70.642048000000045, 29.087042000000054],
            [70.629266869000048, 29.086270280000065],
            [70.620348295000042, 29.08573178000006],
            [70.619391509000081, 29.083998860000065],
            [70.612018000000035, 29.075888000000077],
            [70.612018000000035, 29.06061070100003],
            [70.612018000000035, 29.055295000000058],
            [70.604996634000031, 29.049277206000056],
            [70.602363000000082, 29.047020000000032],
            [70.600006000000064, 29.044999000000075],
            [70.586253179000039, 29.04105271800006],
            [70.579980941000031, 29.039163743000074],
            [70.579414000000043, 29.038993000000062],
            [70.558822000000077, 29.032129000000054],
            [70.543435084000066, 29.020563035000066],
            [70.538501446000055, 29.016463689000034],
            [70.538284763000036, 29.022080196000047],
            [70.538251747000061, 29.022935992000043],
            [70.537890253000057, 29.02488571400005],
            [70.537669788000073, 29.027664129000073],
            [70.539180918000056, 29.03457204800003],
            [70.540856807000068, 29.042233143000033],
            [70.545454175000032, 29.053780645000074],
            [70.54718364200005, 29.05811996400007],
            [70.54805188000006, 29.062026734000028],
            [70.548625271000049, 29.064607215000024],
            [70.550825665000048, 29.074509978000037],
            [70.550231126000028, 29.081351146000031],
            [70.549915671000065, 29.084980987000051],
            [70.55403827400005, 29.095409712000048],
            [70.55547714000005, 29.099049535000063],
            [70.557554644000049, 29.104305085000078],
            [70.557199709000031, 29.112297006000063],
            [70.575410752000039, 29.117305004000059],
            [70.575188075000028, 29.120296507000035],
            [70.573134757000048, 29.130964014000028],
            [70.573056749000045, 29.132983076000073],
            [70.572707134000041, 29.143145177000065],
            [70.572556082000062, 29.147447999000065],
            [70.572476796000046, 29.149706558000048],
            [70.57238295500008, 29.152377339000054],
            [70.572247192000077, 29.156247020000023],
            [70.572091637000028, 29.15858754900006],
            [70.571745472000032, 29.16297073100003],
            [70.570857787000079, 29.174214040000038],
            [70.577298430000042, 29.186540725000043],
            [70.578142312000068, 29.188328045000048],
            [70.57544759700005, 29.191924974000074],
            [70.575162938000062, 29.194557795000037],
            [70.571346663000043, 29.204292253000062],
            [70.569273899000052, 29.206871493000051],
            [70.568126810000081, 29.209270053000068],
            [70.565629716000046, 29.212016689000052],
            [70.559020800000042, 29.219286056000044],
            [70.553262927000048, 29.218575897000051],
            [70.54900470900003, 29.218376075000037],
            [70.554460701000039, 29.223114180000039],
            [70.560176700000056, 29.228078166000046],
            [70.563429283000062, 29.232230575000074],
            [70.566304826000078, 29.233400058000029],
            [70.573116556000059, 29.23508408400005],
            [70.575410844000032, 29.236587059000044],
            [70.575953807000076, 29.241691484000057],
            [70.576554061000081, 29.24705761000007],
            [70.576936986000078, 29.250735750000047],
            [70.577686866000079, 29.257984062000048],
            [70.585565793000058, 29.264591873000029],
            [70.58876146800003, 29.267271987000072],
            [70.591844144000049, 29.269864195000025],
            [70.596335576000058, 29.274096753000038],
            [70.603711563000047, 29.280999252000072],
            [70.608058143000051, 29.285246615000062],
            [70.610584365000079, 29.288385217000041],
            [70.613588188000051, 29.292117193000024],
            [70.621505839000065, 29.301954152000064],
            [70.62275898300004, 29.30351107000007],
            [70.620805707000045, 29.314085384000066],
            [70.618862966000052, 29.322600976000047],
            [70.618205989000046, 29.325820067000052],
            [70.616400508000083, 29.331555425000033],
            [70.61452478700005, 29.338259552000068],
            [70.610843395000074, 29.350907569000071],
            [70.613544168000033, 29.360664365000048],
            [70.614564008000059, 29.364063055000031],
            [70.620629826000084, 29.367773047000071],
            [70.627606732000061, 29.372268904000066],
            [70.627578333000031, 29.376704548000077],
            [70.626995062000049, 29.38032129100003],
            [70.62679539800007, 29.382777008000062],
            [70.625946044000045, 29.386826051000071],
            [70.613308538000069, 29.393998929000077],
            [70.609101021000072, 29.396387036000078],
            [70.60920760700003, 29.400119563000032],
            [70.608665807000079, 29.406679012000041],
            [70.609556033000047, 29.412321027000075],
            [70.619377461000056, 29.411838320000072],
            [70.635569165000049, 29.411022038000056],
            [70.637320627000065, 29.410956414000054],
            [70.638692218000074, 29.438795836000054],
            [70.638502900000049, 29.456085202000054],
            [70.638252325000053, 29.464751493000051],
            [70.64292516900008, 29.474962011000059],
            [70.644925038000054, 29.478961575000028],
            [70.646850247000032, 29.482811763000029],
            [70.649165000000039, 29.487441000000047],
            [70.653938029000074, 29.487658064000072],
            [70.660719981000057, 29.48796648900003],
            [70.669544988000041, 29.488364166000054],
            [70.672507649000067, 29.48839821100006],
            [70.68220601400003, 29.488852051000038],
            [70.692871000000082, 29.489262000000053],
            [70.695128773000079, 29.493150494000076],
            [70.698355106000065, 29.498707109000065],
            [70.701066000000083, 29.50337600000006],
            [70.705645847000028, 29.509991211000056],
            [70.709261000000083, 29.515213000000074],
            [70.709901610000031, 29.522261892000074],
            [70.710626500000046, 29.530237000000056],
            [70.708886692000078, 29.532339816000047],
            [70.707180483000059, 29.53368312300006],
            [70.705460545000051, 29.535403111000051],
            [70.700597917000039, 29.540265931000079],
            [70.697424000000069, 29.543440000000032],
            [70.691233259000057, 29.551435783000045],
            [70.688772666000034, 29.554613815000039],
            [70.686497000000031, 29.557553000000041],
            [70.689055049000046, 29.561709742000062],
            [70.692222097000069, 29.566856087000076],
            [70.695345343000042, 29.571931254000049],
            [70.697424000000069, 29.575309000000061],
            [70.699059183000031, 29.577510149000034],
            [70.700576843000078, 29.579553099000066],
            [70.703101753000055, 29.582951927000067],
            [70.705230671000038, 29.585817702000043],
            [70.706975840000041, 29.588035082000033],
            [70.709261000000083, 29.591243000000077],
            [70.708928660000083, 29.602204736000033],
            [70.708797622000077, 29.606526826000049],
            [70.708691265000084, 29.610034868000071],
            [70.708562230000041, 29.614290900000071],
            [70.708435211000051, 29.618480448000071],
            [70.708350000000053, 29.621291000000042],
            [70.715634500000078, 29.624933000000055],
            [70.715760491000083, 29.628708404000065],
            [70.716090000000065, 29.638591000000076],
            [70.714067322000062, 29.642636356000025],
            [70.71209759900006, 29.64657580100004],
            [70.709715877000065, 29.651343815000075],
            [70.713645654000061, 29.654022501000043],
            [70.716086216000065, 29.654405949000079],
            [70.722008000000073, 29.657257000000072],
            [70.723550499000055, 29.662462996000045],
            [70.725526449000029, 29.669131245000074],
            [70.73612200000008, 29.677745000000073],
            [70.736103627000034, 29.680008312000041],
            [70.735666000000037, 29.693224000000043],
            [70.727471500000036, 29.707793000000038],
            [70.742495500000075, 29.720995000000073],
            [70.75250118200006, 29.726944926000044],
            [70.767535000000066, 29.746946000000037],
            [70.780749731000071, 29.767833143000075],
            [70.788933000000043, 29.778815000000066],
            [70.793801500000029, 29.784707407000042],
            [70.805108056000051, 29.796827950000079],
            [70.808965000000057, 29.801123000000075],
            [70.821257500000058, 29.80613100000005],
            [70.822845768000036, 29.811954380000032],
            [70.825355000000059, 29.821155000000033],
            [70.828610468000079, 29.823548807000066],
            [70.840834000000029, 29.832537000000059],
            [70.846424073000037, 29.851303296000026],
            [70.84720800000008, 29.853935000000035],
            [70.849053778000041, 29.85962640300005],
            [70.85204475900008, 29.868849004000026],
            [70.858134000000064, 29.887625000000071],
            [70.856958451000082, 29.888698233000071],
            [70.845504314000038, 29.899155441000062],
            [70.833094500000072, 29.909478000000036],
            [70.851761000000067, 29.931331000000057],
            [70.856865906000053, 29.932069883000054],
            [70.886361000000079, 29.936339000000032],
            [70.887727000000041, 29.943168000000071],
            [70.885679193000044, 29.944904526000073],
            [70.874524000000065, 29.953184000000078],
            [70.868456404000028, 29.960355419000052],
            [70.864963500000044, 29.964110000000062],
            [70.880443000000071, 29.965931000000069],
            [70.879695989000084, 29.969441771000049],
            [70.878289857000084, 29.976050253000039],
            [70.875890000000084, 29.987329000000045],
            [70.870472287000041, 30.003194553000071],
            [70.869548126000041, 30.005376309000042],
            [70.86964210800005, 30.013112813000077],
            [70.869724591000079, 30.019902793000028],
            [70.868624530000034, 30.028741192000041],
            [70.87271307900005, 30.044274040000062],
            [70.874069000000077, 30.049246000000039],
            [70.888958225000067, 30.051719651000042],
            [70.882441846000063, 30.064468216000023],
            [70.878166000000078, 30.073376000000053],
            [70.877036034000071, 30.074974649000069],
            [70.87199176200005, 30.080805596000062],
            [70.86678500000005, 30.089310000000069],
            [70.864429822000034, 30.094122913000035],
            [70.857751958000051, 30.107767782000053],
            [70.856313000000057, 30.110708000000045],
            [70.850975399000049, 30.121383202000061],
            [70.849377343000072, 30.124579315000062],
            [70.847663000000068, 30.128008000000079],
            [70.847343471000045, 30.138238892000061],
            [70.846866747000036, 30.153475103000062],
            [70.846734168000069, 30.157144248000066],
            [70.846460396000055, 30.164590255000064],
            [70.846312692000083, 30.168144685000073],
            [70.845668603000036, 30.18200369300007],
            [70.84402250200003, 30.18875594900004],
            [70.84174500000006, 30.198120000000074],
            [70.837942263000059, 30.202781142000049],
            [70.830781577000039, 30.211558233000062],
            [70.827631000000054, 30.215420000000051],
            [70.821038227000031, 30.222013068000024],
            [70.811938328000053, 30.231113376000053],
            [70.805323000000044, 30.237729000000058],
            [70.80459710100007, 30.245807755000044],
            [70.80441200000007, 30.251387000000079],
            [70.814349302000039, 30.258379407000064],
            [70.81670500000007, 30.260037000000068],
            [70.824444000000028, 30.260947000000044],
            [70.825755378000053, 30.263117197000042],
            [70.828997000000072, 30.270508000000063],
            [70.826347316000067, 30.28489128800004],
            [70.825810000000047, 30.287808000000041],
            [70.825355000000059, 30.301922000000047],
            [70.825143406000052, 30.307103393000034],
            [70.824049200000047, 30.315555735000032],
            [70.824444000000028, 30.324230000000057],
            [70.824525635000043, 30.327816218000066],
            [70.824892495000029, 30.343932296000048],
            [70.824900000000071, 30.347673133000058],
            [70.824900000000071, 30.362018000000035],
            [70.82919828100006, 30.370099039000024],
            [70.835225202000061, 30.381430032000026],
            [70.836281000000042, 30.38341500000007],
            [70.835139729000048, 30.391177246000041],
            [70.834005000000047, 30.398895000000039],
            [70.831606205000071, 30.404323764000026],
            [70.828358895000065, 30.411672821000025],
            [70.825355000000059, 30.418471000000068],
            [70.825286313000049, 30.425283388000025],
            [70.824889540000072, 30.428519191000078],
            [70.824899500000072, 30.446243000000038],
            [70.819374006000032, 30.447736533000068],
            [70.815186495000034, 30.448615302000064],
            [70.812760181000044, 30.449617713000066],
            [70.808055000000081, 30.450796000000025],
            [70.798340179000036, 30.463957194000045],
            [70.793941000000075, 30.469917000000066],
            [70.79384265300007, 30.472277764000069],
            [70.793211300000053, 30.487433013000043],
            [70.793031000000042, 30.491770000000031],
            [70.798077726000031, 30.499340287000052],
            [70.800840678000043, 30.503441799000029],
            [70.805778000000032, 30.510891000000072],
            [70.803774970000063, 30.515999002000058],
            [70.800293821000082, 30.524876410000047],
            [70.796673000000055, 30.534110000000055],
            [70.795535288000053, 30.541314813000042],
            [70.793941000000075, 30.55141100000003],
            [70.801435088000062, 30.557438690000026],
            [70.808575205000068, 30.563181672000042],
            [70.815143853000052, 30.56904331100003],
            [70.816152192000061, 30.571703245000037],
            [70.817615000000046, 30.576451000000077],
            [70.813883885000052, 30.578938319000031],
            [70.803957000000082, 30.585556000000054],
            [70.803957000000082, 30.593229725000072],
            [70.803957000000082, 30.598304000000041],
            [70.812607000000071, 30.60422200000005],
            [70.813868407000029, 30.60566917400007],
            [70.815794000000039, 30.609230000000025],
            [70.809509399000035, 30.619554139000059],
            [70.809420000000046, 30.619701000000077],
            [70.802919114000076, 30.622391442000037],
            [70.796217500000068, 30.625165000000038],
            [70.796401826000078, 30.629978922000078],
            [70.796217384000045, 30.635266719000072],
            [70.796230473000037, 30.639276858000073],
            [70.796217500000068, 30.643375000000049],
            [70.801064000000054, 30.646511000000032],
            [70.803957000000082, 30.648383000000024],
            [70.822168000000033, 30.651115000000061],
            [70.840366752000079, 30.654139167000039],
            [70.851761000000067, 30.657034000000067],
            [70.873286848000077, 30.666095002000077],
            [70.90275100000008, 30.661586000000057],
            [70.905478690000052, 30.661833959000035],
            [70.909151627000028, 30.661779670000044],
            [70.922505728000033, 30.66263119000007],
            [70.929294937000066, 30.665357477000043],
            [70.940994000000046, 30.667050000000074],
            [70.949591819000034, 30.667050000000074],
            [70.959660000000042, 30.667050000000074],
            [70.965097805000084, 30.660932167000055],
            [70.970586500000081, 30.654757000000075],
            [70.976126157000067, 30.654772150000042],
            [70.996082000000058, 30.655212000000063],
            [71.005564574000061, 30.654939725000077],
            [71.009591178000051, 30.654207867000025],
            [71.01172405300008, 30.653429801000073],
            [71.024182388000042, 30.640087750000077],
            [71.035430484000074, 30.639136361000055],
            [71.03887700000007, 30.638823000000059],
            [71.046524013000067, 30.642596555000068],
            [71.059364000000073, 30.649294000000054],
            [71.066611684000065, 30.650329383000042],
            [71.074710693000043, 30.651198081000075],
            [71.081235159000073, 30.652686189000065],
            [71.102121054000065, 30.646679196000036],
            [71.106257000000028, 30.645652000000041],
            [71.123405670000068, 30.651240312000027],
            [71.125379000000066, 30.652026000000035],
            [71.134842493000065, 30.65789421900007],
            [71.144768209000063, 30.666126539000061],
            [71.148652561000063, 30.676446989000056],
            [71.150419000000056, 30.682529000000045],
            [71.145044200000029, 30.69156505400008],
            [71.141428282000049, 30.698217816000067],
            [71.150418500000058, 30.715764000000036],
            [71.15397820000004, 30.72004531400006],
            [71.164077000000077, 30.731243000000063],
            [71.163905083000031, 30.741724488000045],
            [71.163863809000077, 30.743930941000031],
            [71.164159188000042, 30.759219567000059],
            [71.17910100000006, 30.764933000000042],
            [71.181937757000071, 30.764241060000074],
            [71.184351745000072, 30.763502892000076],
            [71.197767000000056, 30.760380000000055],
            [71.202457354000046, 30.761758677000046],
            [71.21688800000004, 30.765843000000075],
            [71.218954903000053, 30.765343975000064],
            [71.234958056000039, 30.764003862000038],
            [71.241220939000073, 30.762569686000063],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "112",
      properties: { name: "Nankana Sahib", count: 266 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.73378050000008, 31.890231000000028],
            [73.736915088000046, 31.885528783000041],
            [73.738451500000053, 31.883224000000041],
            [73.733001500000057, 31.871545000000026],
            [73.723658500000056, 31.862202000000025],
            [73.720544500000074, 31.848966000000075],
            [73.717907209000032, 31.841431091000061],
            [73.716612225000063, 31.83773123800006],
            [73.715094500000077, 31.833395000000053],
            [73.711373003000062, 31.824835462000067],
            [73.707308500000067, 31.815487000000076],
            [73.702637000000038, 31.801473000000044],
            [73.704194500000028, 31.795244000000025],
            [73.715873000000045, 31.787458000000072],
            [73.718630262000033, 31.785987585000044],
            [73.718632337000031, 31.78598647900003],
            [73.727551500000061, 31.78123000000005],
            [73.730429214000083, 31.78046246200006],
            [73.73464083600004, 31.779339146000041],
            [73.739230353000039, 31.778115039000056],
            [73.739230706000058, 31.778115078000042],
            [73.739231000000075, 31.778115000000071],
            [73.746237557000029, 31.778893951000043],
            [73.746744861000082, 31.778459112000064],
            [73.750914520000038, 31.774885065000035],
            [73.751687000000061, 31.774223000000063],
            [73.751686998000082, 31.774222931000054],
            [73.75168750000006, 31.774222500000064],
            [73.751687498000081, 31.774222431000055],
            [73.750909000000036, 31.752422000000024],
            [73.750130538000064, 31.743858414000044],
            [73.75013107500007, 31.743858827000054],
            [73.754655336000042, 31.747338959000047],
            [73.760252000000037, 31.751644000000056],
            [73.761788499000033, 31.749871064000047],
            [73.761788482000043, 31.749871172000041],
            [73.761797969000042, 31.749860225000077],
            [73.761797988000069, 31.749860115000047],
            [73.770373500000062, 31.739965000000041],
            [73.768037500000048, 31.729843000000074],
            [73.76183569300008, 31.72088503100008],
            [73.761030500000061, 31.719722000000047],
            [73.759855809000044, 31.714141654000059],
            [73.757916500000078, 31.70492900000005],
            [73.757916500000078, 31.694029000000057],
            [73.758284017000051, 31.687040980000063],
            [73.758694500000047, 31.67923600000006],
            [73.758592187000033, 31.678724368000076],
            [73.756359000000032, 31.667557000000045],
            [73.754802000000041, 31.660550000000057],
            [73.754211443000031, 31.653462557000068],
            [73.754024000000072, 31.651207000000056],
            [73.759966945000031, 31.64913955600008],
            [73.764397869000049, 31.647598247000076],
            [73.771930500000053, 31.644978000000037],
            [73.774947111000074, 31.64252717800008],
            [73.775926953000067, 31.641731388000039],
            [73.775927354000032, 31.641731063000066],
            [73.782913328000063, 31.636055086000056],
            [73.784388000000035, 31.634857000000068],
            [73.784359681000069, 31.63470974300003],
            [73.78049500000003, 31.614614000000074],
            [73.778258500000049, 31.608744000000058],
            [73.772796000000085, 31.603281000000038],
            [73.75546532900006, 31.607280163000041],
            [73.754803000000038, 31.607433000000071],
            [73.72435100000007, 31.611586000000045],
            [73.721600434000038, 31.612556781000023],
            [73.718907547000072, 31.613507205000076],
            [73.71247182500008, 31.615778620000071],
            [73.707633269000041, 31.617486334000034],
            [73.700820168000064, 31.619890941000051],
            [73.700820084000043, 31.619890970000029],
            [73.68144250000006, 31.618506500000024],
            [73.673944512000048, 31.613507688000027],
            [73.667886071000055, 31.609468603000039],
            [73.656528500000036, 31.601897000000065],
            [73.654521288000069, 31.599889788000041],
            [73.642686500000082, 31.588055000000054],
            [73.63740561700007, 31.581453856000053],
            [73.626077001000056, 31.567292999000074],
            [73.627181583000038, 31.555143586000042],
            [73.627918107000085, 31.547042487000056],
            [73.627918427000054, 31.547042625000074],
            [73.628455138000049, 31.541140350000035],
            [73.628454726000029, 31.541140158000076],
            [73.628845500000068, 31.536842000000036],
            [73.638534500000048, 31.517464000000075],
            [73.638707540000041, 31.517303318000074],
            [73.652777495000066, 31.504238257000054],
            [73.657912500000066, 31.499470000000031],
            [73.661758246000034, 31.495625490000066],
            [73.661758334000069, 31.495625666000024],
            [73.661870000000079, 31.495848000000024],
            [73.669135402000052, 31.505026431000033],
            [73.670719000000076, 31.507027000000051],
            [73.670724348000078, 31.507061739000051],
            [73.670757223000066, 31.50710311000006],
            [73.671654251000064, 31.513083295000058],
            [73.679569000000072, 31.51867100000004],
            [73.686541866000084, 31.519135126000037],
            [73.686556000000053, 31.51913600000006],
            [73.694009000000051, 31.520068000000038],
            [73.69426511100005, 31.520288398000048],
            [73.700528000000077, 31.525657000000024],
            [73.707385069000054, 31.528638446000059],
            [73.711257223000075, 31.530303110000034],
            [73.720457223000039, 31.533803110000065],
            [73.721023000000059, 31.534041000000059],
            [73.72105894200007, 31.534044516000051],
            [73.730803000000037, 31.534972000000039],
            [73.73080307400005, 31.534971959000075],
            [73.730803500000036, 31.534972000000039],
            [73.741050458000075, 31.529383023000037],
            [73.741050917000052, 31.529383046000078],
            [73.741051000000084, 31.529383000000053],
            [73.741059550000045, 31.529383478000057],
            [73.750365000000045, 31.52984900000007],
            [73.750365000000045, 31.534507000000076],
            [73.756885900000043, 31.53869893600006],
            [73.755485831000044, 31.539717155000062],
            [73.751762000000042, 31.542425000000037],
            [73.747798068000066, 31.542707660000076],
            [73.745241000000078, 31.542890000000057],
            [73.738721000000055, 31.544288000000051],
            [73.733132000000069, 31.546616000000029],
            [73.727077000000065, 31.552206000000069],
            [73.727077000000065, 31.552586089000044],
            [73.727077000000065, 31.560115353000072],
            [73.728167444000064, 31.560790390000079],
            [73.729706997000051, 31.56173123800005],
            [73.735460000000046, 31.565247000000056],
            [73.740794176000065, 31.567580210000074],
            [73.742957223000076, 31.568503110000051],
            [73.746008177000078, 31.572316803000035],
            [73.748968000000048, 31.575960000000066],
            [73.755488000000071, 31.57875400000006],
            [73.765735000000063, 31.582480000000032],
            [73.767259000000081, 31.583242000000041],
            [73.767836000000045, 31.58353100000005],
            [73.773185925000064, 31.586205463000056],
            [73.773187000000064, 31.586206000000061],
            [73.77327667000003, 31.586212901000067],
            [73.779242000000067, 31.586672000000078],
            [73.779242187000079, 31.586671976000048],
            [73.779242500000066, 31.586672000000078],
            [73.78170502200004, 31.586354272000051],
            [73.789019605000078, 31.585410504000038],
            [73.790566106000028, 31.585210966000034],
            [73.793681500000048, 31.584809000000064],
            [73.800759571000071, 31.57896192700008],
            [73.80439350000006, 31.575960000000066],
            [73.806694957000047, 31.575521543000036],
            [73.806712400000038, 31.575518221000038],
            [73.807757223000067, 31.57530311000005],
            [73.813176350000049, 31.574287024000057],
            [73.814174002000073, 31.574097004000066],
            [73.818564337000055, 31.572500163000029],
            [73.820331730000078, 31.571857388000069],
            [73.824421500000028, 31.570370000000025],
            [73.832805440000072, 31.566644027000052],
            [73.848175000000083, 31.567576000000031],
            [73.852435232000062, 31.569173301000035],
            [73.855627000000084, 31.570370000000025],
            [73.864943000000039, 31.570836000000043],
            [73.875177287000042, 31.571301467000069],
            [73.875189000000034, 31.57130200000006],
            [73.876699424000037, 31.571721582000066],
            [73.883573000000069, 31.573631000000034],
            [73.883573313000056, 31.573630948000073],
            [73.883573500000068, 31.573631000000034],
            [73.884970500000065, 31.573398139000062],
            [73.891957000000048, 31.572234000000037],
            [73.895217500000058, 31.570728947000077],
            [73.898012188000052, 31.569439144000057],
            [73.90371418400008, 31.573799536000024],
            [73.90595722300003, 31.575503110000056],
            [73.908157223000046, 31.578703110000049],
            [73.908337161000077, 31.579105603000073],
            [73.908724000000063, 31.579686000000038],
            [73.911985000000072, 31.587138000000039],
            [73.912744847000056, 31.589037821000034],
            [73.913655877000053, 31.59131564200004],
            [73.913856084000031, 31.591800352000064],
            [73.915713291000031, 31.592800269000065],
            [73.919903000000033, 31.595056000000056],
            [73.928752000000031, 31.595987000000036],
            [73.928752025000051, 31.595986950000054],
            [73.92875250000003, 31.595987000000036],
            [73.932478500000059, 31.588535000000036],
            [73.936045143000058, 31.579262340000071],
            [73.937136500000065, 31.576425000000029],
            [73.937153124000076, 31.576413918000071],
            [73.938513737000051, 31.575506842000038],
            [73.942269082000053, 31.573003278000044],
            [73.942725500000051, 31.572699000000057],
            [73.948314500000038, 31.568507000000068],
            [73.949246500000072, 31.561055000000067],
            [73.951575000000048, 31.552671000000032],
            [73.951575840000032, 31.552670847000059],
            [73.960285869000074, 31.551087206000034],
            [73.961821162000035, 31.550808062000044],
            [73.96182400500004, 31.550809145000073],
            [73.961857223000038, 31.550803110000061],
            [73.964600593000057, 31.551866866000069],
            [73.971602000000075, 31.554534000000046],
            [73.979986000000054, 31.556398000000058],
            [73.986582908000059, 31.558376718000034],
            [73.98935722300007, 31.559203110000055],
            [73.991584923000062, 31.559831436000024],
            [73.992952380000077, 31.560214286000075],
            [73.993257973000084, 31.560299844000042],
            [73.993257994000032, 31.560299754000027],
            [73.993216000000075, 31.560288000000071],
            [73.993810265000036, 31.557696092000072],
            [73.994661965000034, 31.553981372000067],
            [73.994715978000045, 31.553949420000038],
            [73.997426638000036, 31.542142992000038],
            [73.997757223000065, 31.540703110000038],
            [73.996426104000079, 31.538062001000071],
            [73.996108538000044, 31.537426818000029],
            [73.991855859000054, 31.528920777000053],
            [73.991491000000053, 31.528191000000049],
            [73.98840322500007, 31.524588679000033],
            [73.986678188000042, 31.522576181000034],
            [73.985262000000034, 31.520924000000036],
            [73.98410050800004, 31.520633650000036],
            [73.982734806000053, 31.520292252000047],
            [73.979486550000047, 31.51948025300004],
            [73.973145914000042, 31.51789522200005],
            [73.972899839000036, 31.517833708000069],
            [73.972805000000051, 31.517810000000054],
            [73.969094695000081, 31.512244840000051],
            [73.968556782000064, 31.511438013000031],
            [73.966576000000032, 31.508467000000053],
            [73.964227764000043, 31.507162564000055],
            [73.959249077000038, 31.504396923000058],
            [73.957235461000039, 31.503278367000064],
            [73.957233000000031, 31.503277000000026],
            [73.956994813000051, 31.503004754000074],
            [73.955558691000078, 31.501363273000038],
            [73.951961167000036, 31.497251322000068],
            [73.949967000000072, 31.494972000000075],
            [73.949693443000058, 31.493878037000059],
            [73.947843890000058, 31.48648160700003],
            [73.947608129000059, 31.485538789000032],
            [73.946852000000035, 31.482515000000035],
            [73.946974674000046, 31.481410819000075],
            [73.947890000000029, 31.473172000000034],
            [73.947958910000068, 31.473103090000052],
            [73.951477333000071, 31.469584667000049],
            [73.951960720000045, 31.469101280000075],
            [73.953912973000058, 31.467149027000062],
            [73.957233000000031, 31.463829000000032],
            [73.960092208000049, 31.465973148000046],
            [73.965538000000038, 31.470057000000054],
            [73.967440740000029, 31.470057000000054],
            [73.977995000000078, 31.470057000000054],
            [73.979845325000042, 31.469710106000036],
            [73.980495925000071, 31.469588133000059],
            [73.987476727000058, 31.468279390000077],
            [73.994605000000035, 31.466943000000072],
            [73.994670467000049, 31.46671389100004],
            [73.994748582000057, 31.466440516000034],
            [73.994990148000056, 31.465595121000035],
            [73.997050542000068, 31.458384485000067],
            [73.998611612000047, 31.452921304000029],
            [73.998757223000041, 31.452403110000034],
            [73.999865443000033, 31.451966065000079],
            [74.003556555000046, 31.450489763000064],
            [74.005852692000076, 31.449571396000067],
            [74.009139000000062, 31.448257000000069],
            [74.023672000000033, 31.451371000000051],
            [74.026187404000041, 31.44813700800006],
            [74.028111095000042, 31.445663766000052],
            [74.030380081000033, 31.442746588000034],
            [74.030939000000046, 31.44202800000005],
            [74.033125626000071, 31.436560908000047],
            [74.034277450000047, 31.43368107200007],
            [74.035057259000041, 31.431731361000061],
            [74.035091000000079, 31.431647000000055],
            [74.035091000000079, 31.43021712500007],
            [74.035091000000079, 31.425903146000053],
            [74.035091000000079, 31.423342000000048],
            [74.026786000000072, 31.416076000000032],
            [74.02456556900006, 31.414299228000061],
            [74.023585598000068, 31.413515062000045],
            [74.021596000000045, 31.411923000000058],
            [74.025656217000062, 31.409081122000032],
            [74.030059781000034, 31.405998924000073],
            [74.03197700000004, 31.404657000000043],
            [74.038206000000059, 31.401542000000063],
            [74.037636676000034, 31.397558377000053],
            [74.036877881000066, 31.39224899900006],
            [74.036525059000041, 31.389780267000049],
            [74.036228178000044, 31.387702959000023],
            [74.036129000000074, 31.387009000000035],
            [74.034929229000056, 31.385609074000058],
            [74.034153047000075, 31.384703405000039],
            [74.029901000000052, 31.379742000000078],
            [74.02743317900007, 31.375115679000032],
            [74.025686449000034, 31.371841158000052],
            [74.023686000000055, 31.368091000000049],
            [74.01414761400008, 31.368091000000049],
            [74.014104000000032, 31.368091000000049],
            [74.006849000000045, 31.358902000000057],
            [74.012653000000057, 31.346811000000059],
            [74.012654807000047, 31.346808151000062],
            [74.01288056900006, 31.346452138000075],
            [74.025228000000084, 31.326981000000046],
            [74.03151500000007, 31.317308000000025],
            [74.032113206000076, 31.313720106000062],
            [74.032457223000051, 31.311503110000046],
            [74.027135528000031, 31.31000775800004],
            [74.022978528000067, 31.308843619000072],
            [74.020391000000075, 31.308119000000033],
            [74.020383024000068, 31.308110360000057],
            [74.014652613000067, 31.301903074000052],
            [74.014587332000076, 31.301832360000049],
            [74.014587000000063, 31.301832000000047],
            [74.014454993000072, 31.301903074000052],
            [74.012706442000081, 31.30284451600005],
            [74.01233656900007, 31.303085071000055],
            [74.011978623000061, 31.303317869000068],
            [74.011463241000058, 31.303549907000047],
            [74.011054614000045, 31.303733881000028],
            [74.010006513000064, 31.304298192000033],
            [74.009841803000029, 31.304386874000045],
            [74.008323663000056, 31.30520425900005],
            [74.008300000000077, 31.305217000000027],
            [73.994864492000033, 31.30566519000007],
            [73.993791000000044, 31.305701000000056],
            [73.992026477000081, 31.304377542000054],
            [73.988727348000054, 31.301903074000052],
            [73.985822948000077, 31.299724667000078],
            [73.980249000000072, 31.295544000000064],
            [73.977097287000049, 31.294143359000032],
            [73.977179063000051, 31.293944241000077],
            [73.977055218000032, 31.294124664000037],
            [73.971543846000031, 31.291675376000057],
            [73.971544000000051, 31.291675000000055],
            [73.971333316000084, 31.291632312000047],
            [73.971316151000053, 31.291675000000055],
            [73.959937000000082, 31.291675000000055],
            [73.959936000000084, 31.291675000000055],
            [73.955666170000029, 31.291675000000055],
            [73.95565068500008, 31.291703110000071],
            [73.944957223000074, 31.291703110000071],
            [73.941285736000054, 31.290179097000077],
            [73.934303000000057, 31.287323000000072],
            [73.931232874000045, 31.280841389000045],
            [73.930265336000048, 31.278798734000077],
            [73.929983192000066, 31.278203074000032],
            [73.92997017700003, 31.278175598000075],
            [73.929957259000048, 31.278148325000075],
            [73.929950000000076, 31.278133000000025],
            [73.926950600000055, 31.277028095000048],
            [73.920762377000074, 31.274748507000027],
            [73.920762000000082, 31.274748000000045],
            [73.920761504000041, 31.274748186000068],
            [73.920761000000084, 31.274748000000045],
            [73.909653774000049, 31.268952752000075],
            [73.909637000000032, 31.268944000000033],
            [73.90915411800006, 31.261705756000026],
            [73.909157223000079, 31.261703110000042],
            [73.912625061000028, 31.260377172000062],
            [73.91261733500005, 31.260372119000067],
            [73.919310000000053, 31.257820000000038],
            [73.918826000000081, 31.247180000000071],
            [73.911239906000048, 31.236470709000059],
            [73.910604000000035, 31.235573000000045],
            [73.910396210000044, 31.235328539000079],
            [73.910413406000032, 31.235318167000059],
            [73.906491419000076, 31.230734640000037],
            [73.906272756000078, 31.230477388000054],
            [73.902382000000046, 31.225900000000024],
            [73.89737916200005, 31.225900000000024],
            [73.891259000000048, 31.225900000000024],
            [73.877427433000037, 31.225900000000024],
            [73.877527456000053, 31.225660056000038],
            [73.877481812000042, 31.225723019000043],
            [73.877409060000048, 31.225900000000024],
            [73.877247387000068, 31.225900000000024],
            [73.877234000000044, 31.225900000000024],
            [73.877233000000047, 31.225900000000024],
            [73.876446245000068, 31.225647102000039],
            [73.876369564000072, 31.225622453000028],
            [73.874649937000072, 31.225078748000044],
            [73.874649823000084, 31.225078712000027],
            [73.863657223000075, 31.221603110000046],
            [73.851657223000075, 31.221103110000058],
            [73.844950908000044, 31.219874472000072],
            [73.838557223000066, 31.218703110000035],
            [73.836944023000058, 31.218613488000074],
            [73.827757223000049, 31.218103110000072],
            [73.827760784000077, 31.218029131000037],
            [73.821614946000068, 31.217678054000032],
            [73.821614000000068, 31.217678000000035],
            [73.817261379000058, 31.222030622000034],
            [73.817260941000029, 31.222030964000055],
            [73.806138000000033, 31.215260000000058],
            [73.79582816900006, 31.212314443000025],
            [73.794385954000063, 31.211902397000074],
            [73.79259600000006, 31.211391000000049],
            [73.779537000000062, 31.209456000000046],
            [73.772450768000056, 31.206196251000051],
            [73.772433939000052, 31.206188510000061],
            [73.766667855000037, 31.203536044000032],
            [73.75671195700005, 31.198956216000056],
            [73.755355000000066, 31.19833200000005],
            [73.753994065000029, 31.19746593900004],
            [73.750105064000081, 31.194991087000062],
            [73.745532069000035, 31.192080960000055],
            [73.744715000000042, 31.191561000000036],
            [73.744430464000061, 31.19076423000007],
            [73.743020279000064, 31.186815362000061],
            [73.737782032000041, 31.173824982000042],
            [73.737331405000077, 31.17273059300004],
            [73.736654116000068, 31.17108573400003],
            [73.735547626000084, 31.168398520000039],
            [73.726457753000034, 31.164051190000066],
            [73.726355995000063, 31.164002996000079],
            [73.726337000000058, 31.163994000000059],
            [73.719864624000081, 31.163346294000064],
            [73.719865329000072, 31.163400769000077],
            [73.716657223000084, 31.163103110000065],
            [73.711999960000071, 31.170410195000045],
            [73.710862501000065, 31.172211041000025],
            [73.710860228000058, 31.17221464000005],
            [73.710847622000074, 31.172209174000045],
            [73.710846076000053, 31.172208503000036],
            [73.709571759000085, 31.171613939000054],
            [73.70365722300005, 31.168903110000031],
            [73.703625435000049, 31.168839535000075],
            [73.703605000000039, 31.168830000000071],
            [73.700349361000065, 31.162318049000078],
            [73.699868210000034, 31.16135564800004],
            [73.698769000000084, 31.15915700000005],
            [73.69471569600006, 31.154698491000033],
            [73.694029958000044, 31.153944201000058],
            [73.691530073000081, 31.151194404000023],
            [73.689096000000063, 31.148517000000027],
            [73.681148133000079, 31.145663909000064],
            [73.676100406000046, 31.143851898000037],
            [73.676093492000064, 31.143872052000063],
            [73.670257223000078, 31.141803110000069],
            [73.665657223000039, 31.140603110000029],
            [73.660530390000076, 31.139174320000052],
            [73.659557223000036, 31.138903110000058],
            [73.658740234000049, 31.138630780000028],
            [73.65085722300006, 31.136003110000047],
            [73.641472296000074, 31.133980496000049],
            [73.641297216000055, 31.133944023000026],
            [73.63928100000004, 31.133524000000079],
            [73.639132114000063, 31.133458864000033],
            [73.635288496000044, 31.132099536000055],
            [73.635288394000042, 31.132099500000038],
            [73.628260997000041, 31.129614201000038],
            [73.628833160000056, 31.128953197000044],
            [73.623914103000061, 31.126801169000032],
            [73.623804000000064, 31.126753000000065],
            [73.621144155000081, 31.123428022000041],
            [73.616066000000046, 31.117080000000044],
            [73.616066000000046, 31.110261661000038],
            [73.616066000000046, 31.10305500000004],
            [73.613648072000046, 31.094349258000079],
            [73.613648000000069, 31.094349000000079],
            [73.611185442000078, 31.092871415000047],
            [73.603975000000048, 31.088545000000067],
            [73.596170779000033, 31.092187042000035],
            [73.596174574000031, 31.092199230000062],
            [73.59617449600006, 31.09219926600008],
            [73.593144385000073, 31.09359938700004],
            [73.589466000000073, 31.095316000000025],
            [73.587460395000051, 31.096875838000074],
            [73.58436213400006, 31.09928547800007],
            [73.580757223000035, 31.10210311000003],
            [73.570623462000071, 31.102478434000034],
            [73.56725722300007, 31.102603110000075],
            [73.565957187000038, 31.10253778200007],
            [73.56437201500006, 31.102458125000055],
            [73.557070076000059, 31.102091193000035],
            [73.547357223000063, 31.101603110000042],
            [73.532457223000051, 31.09440311000003],
            [73.528957259000038, 31.093331247000037],
            [73.528189678000047, 31.093096175000028],
            [73.52775562100004, 31.092963245000078],
            [73.52773933900005, 31.092937997000035],
            [73.521490590000042, 31.091044579000027],
            [73.516436000000056, 31.089513000000068],
            [73.513109156000041, 31.089768830000025],
            [73.511911231000056, 31.089863903000037],
            [73.505789978000053, 31.090349717000038],
            [73.505776831000048, 31.09033267500007],
            [73.503861000000029, 31.090480000000071],
            [73.489097156000071, 31.089793491000023],
            [73.485157032000075, 31.089610278000066],
            [73.483065000000067, 31.089513000000068],
            [73.482925662000071, 31.089479012000027],
            [73.477534553000055, 31.088163995000059],
            [73.477433126000051, 31.088139255000044],
            [73.477390766000042, 31.088128922000067],
            [73.463791034000053, 31.084811630000047],
            [73.463235000000054, 31.084676000000059],
            [73.461303054000041, 31.081364215000065],
            [73.45646400000004, 31.073069000000032],
            [73.445791396000061, 31.067964419000077],
            [73.445357259000048, 31.067756776000067],
            [73.445341000000042, 31.067749000000049],
            [73.438570000000084, 31.058559000000059],
            [73.438539117000062, 31.058373764000066],
            [73.437258592000035, 31.050693263000028],
            [73.436958438000033, 31.048892958000067],
            [73.436151000000052, 31.044050000000027],
            [73.431677820000061, 31.040952855000057],
            [73.429911493000077, 31.039729883000064],
            [73.429864000000066, 31.039697000000046],
            [73.426157987000067, 31.038638035000076],
            [73.423235911000063, 31.037803074000067],
            [73.420137874000034, 31.036917833000075],
            [73.419708000000071, 31.036795000000041],
            [73.411486000000082, 31.029541000000052],
            [73.403264035000063, 31.024437590000048],
            [73.397741575000055, 31.021009775000039],
            [73.39794100000006, 31.021134000000075],
            [73.395926585000041, 31.026170513000068],
            [73.395844973000067, 31.026374563000047],
            [73.393713000000048, 31.031705000000045],
            [73.39428300000003, 31.040258000000051],
            [73.395589543000028, 31.042580601000054],
            [73.397903566000082, 31.046694170000023],
            [73.39945722300007, 31.049403110000071],
            [73.394957223000063, 31.056703110000058],
            [73.395042856000032, 31.059643187000063],
            [73.396564000000069, 31.066488000000049],
            [73.401126000000033, 31.067058000000031],
            [73.401132702000041, 31.067102694000027],
            [73.40187614000007, 31.072060691000047],
            [73.401955238000085, 31.072588193000058],
            [73.402836000000036, 31.078462000000059],
            [73.402856217000078, 31.078496365000035],
            [73.403122832000065, 31.07894955900008],
            [73.404791402000058, 31.08175417700005],
            [73.407557223000083, 31.086403110000049],
            [73.408396190000076, 31.087913250000042],
            [73.408539000000076, 31.088156000000026],
            [73.417248650000033, 31.096321476000071],
            [73.417662000000064, 31.096709000000033],
            [73.421311073000084, 31.099603146000049],
            [73.421327057000042, 31.099615823000079],
            [73.421357187000069, 31.099639720000027],
            [73.432226660000083, 31.108260495000025],
            [73.433528172000081, 31.10929274700004],
            [73.434198000000038, 31.10982400000006],
            [73.43643340400007, 31.111016059000065],
            [73.442363463000049, 31.11417834100007],
            [73.442751000000044, 31.114385000000027],
            [73.443892000000062, 31.124649000000034],
            [73.443854223000073, 31.124686777000079],
            [73.443857223000066, 31.124703110000041],
            [73.438191757000084, 31.130368576000023],
            [73.438535588000036, 31.133808399000031],
            [73.43885722300007, 31.136703110000042],
            [73.439356945000043, 31.141800277000073],
            [73.439772744000038, 31.142483525000046],
            [73.442884000000049, 31.147596000000078],
            [73.446984713000063, 31.154332356000054],
            [73.44699120100006, 31.154343014000062],
            [73.447008612000047, 31.154371616000049],
            [73.447312000000068, 31.154870000000074],
            [73.447390795000047, 31.154971780000039],
            [73.449289797000063, 31.157424732000038],
            [73.450649615000032, 31.159181218000072],
            [73.460997000000077, 31.172547000000066],
            [73.46189548500007, 31.173301662000028],
            [73.462373177000075, 31.173702888000037],
            [73.475253000000066, 31.184521000000075],
            [73.475333076000084, 31.184499654000035],
            [73.475519091000081, 31.184450067000057],
            [73.476336329000048, 31.18423221300003],
            [73.483806000000072, 31.182241000000033],
            [73.487007360000064, 31.182241000000033],
            [73.49007800000004, 31.182241000000033],
            [73.492560952000076, 31.184285709000051],
            [73.492965307000077, 31.18461869500004],
            [73.500014204000081, 31.190069842000071],
            [73.500066754000045, 31.19003639500005],
            [73.502692705000072, 31.188365031000046],
            [73.506044000000031, 31.186232000000075],
            [73.511130129000037, 31.184960282000077],
            [73.512887000000035, 31.184521000000075],
            [73.518554551000079, 31.184521000000075],
            [73.525431000000083, 31.184521000000075],
            [73.52918756400004, 31.186399557000072],
            [73.532047728000066, 31.187829848000035],
            [73.532274000000029, 31.187943000000075],
            [73.533975362000035, 31.192075124000041],
            [73.533957223000073, 31.192103110000062],
            [73.536257223000064, 31.197703110000077],
            [73.542557223000074, 31.197703110000077],
            [73.548857223000084, 31.197703110000077],
            [73.552357223000058, 31.201203110000051],
            [73.552360855000074, 31.201187373000039],
            [73.555652000000066, 31.204479000000049],
            [73.558227690000081, 31.207569587000023],
            [73.563651136000033, 31.214077215000032],
            [73.56420600000007, 31.214743000000055],
            [73.568987975000084, 31.220650291000027],
            [73.57389900000004, 31.226717000000065],
            [73.579601000000082, 31.231849000000068],
            [73.580982271000039, 31.232145048000064],
            [73.581055608000042, 31.23216076600005],
            [73.585009294000031, 31.233008161000043],
            [73.585838525000042, 31.233185891000062],
            [73.586463018000074, 31.233319739000024],
            [73.587584000000049, 31.233560000000068],
            [73.588716872000077, 31.239787319000072],
            [73.588757223000073, 31.239903110000057],
            [73.590452170000049, 31.248377846000039],
            [73.597278000000074, 31.245534000000077],
            [73.598574213000063, 31.246284401000025],
            [73.601857093000035, 31.248184920000028],
            [73.60427584100006, 31.249585177000029],
            [73.608112000000062, 31.251806000000045],
            [73.613138067000079, 31.25837860200005],
            [73.613600001000066, 31.258982673000048],
            [73.615525000000048, 31.261500000000069],
            [73.623614571000076, 31.263706188000072],
            [73.623682154000051, 31.263724619000072],
            [73.628003534000072, 31.264903146000051],
            [73.628057187000081, 31.264917779000029],
            [73.628059022000059, 31.264918279000028],
            [73.628069000000039, 31.264921000000072],
            [73.628810324000028, 31.265456409000024],
            [73.638132375000055, 31.272189102000027],
            [73.638301262000084, 31.27231107800003],
            [73.639506209000047, 31.272875420000048],
            [73.645746000000031, 31.275755000000061],
            [73.648890895000079, 31.277690385000028],
            [73.651403635000065, 31.279236739000055],
            [73.653157223000051, 31.280303110000034],
            [73.653457223000032, 31.282403110000075],
            [73.653757223000071, 31.287803110000027],
            [73.652057223000043, 31.297403110000062],
            [73.658857223000041, 31.301403110000024],
            [73.659366011000031, 31.302280769000049],
            [73.662852000000044, 31.308257000000026],
            [73.66659545400006, 31.316083463000041],
            [73.666747568000062, 31.316401489000043],
            [73.670257223000078, 31.321903110000051],
            [73.670257223000078, 31.333265307000033],
            [73.670265000000029, 31.33334700000006],
            [73.670257223000078, 31.333361581000077],
            [73.665966079000043, 31.341406770000049],
            [73.665703000000065, 31.341900000000066],
            [73.663238815000057, 31.348882699000058],
            [73.662282000000062, 31.351594000000034],
            [73.665423172000033, 31.361466930000063],
            [73.665423185000066, 31.361466970000038],
            [73.666273000000047, 31.364138000000025],
            [73.670467449000057, 31.368052460000058],
            [73.673257223000064, 31.370603110000047],
            [73.67485722300006, 31.372103110000069],
            [73.67625722300005, 31.37380311000004],
            [73.677682269000059, 31.375505762000046],
            [73.678509969000061, 31.376494702000059],
            [73.683950000000038, 31.382955000000038],
            [73.683950000000038, 31.382994480000036],
            [73.683950000000038, 31.388409808000063],
            [73.68395722300005, 31.388409826000043],
            [73.68395722300005, 31.393803110000079],
            [73.68255722300006, 31.39550311000005],
            [73.679407203000039, 31.399225861000048],
            [73.678664885000046, 31.400103146000049],
            [73.67485722300006, 31.404603110000039],
            [73.677657202000034, 31.409403074000068],
            [73.67765722300004, 31.409403110000028],
            [73.67885722300008, 31.411503110000069],
            [73.676557223000032, 31.419503110000051],
            [73.675516930000072, 31.420725041000026],
            [73.675468262000038, 31.420712162000029],
            [73.673397707000049, 31.423159392000059],
            [73.670265000000029, 31.426862000000028],
            [73.670265000000029, 31.426893975000041],
            [73.670265000000029, 31.434274000000073],
            [73.679283879000081, 31.439049084000033],
            [73.679957223000031, 31.439403110000057],
            [73.679956047000076, 31.439404966000041],
            [73.679958000000056, 31.439406000000076],
            [73.67990562500006, 31.439484580000055],
            [73.678057223000053, 31.442403110000043],
            [73.67545722300008, 31.446303110000031],
            [73.66795722300003, 31.448603110000079],
            [73.660182410000061, 31.452170377000073],
            [73.659440614000061, 31.452516514000024],
            [73.653757223000071, 31.457103110000048],
            [73.653673244000061, 31.457055122000043],
            [73.64672958500006, 31.453583293000065],
            [73.643699883000068, 31.452068442000041],
            [73.643457223000041, 31.452003110000078],
            [73.62635722300007, 31.453103110000029],
            [73.62005722300006, 31.448003110000059],
            [73.61575722300006, 31.447003110000026],
            [73.611557223000034, 31.445703110000068],
            [73.605309725000041, 31.442912965000062],
            [73.601269000000059, 31.441117000000077],
            [73.598399251000046, 31.442756779000035],
            [73.596774622000055, 31.443685095000035],
            [73.593966867000063, 31.445289451000065],
            [73.585303000000067, 31.450240000000065],
            [73.577891000000079, 31.449100000000044],
            [73.570666653000046, 31.443776762000027],
            [73.567057000000034, 31.441117000000077],
            [73.566075725000076, 31.44020112000004],
            [73.564409608000062, 31.438646039000048],
            [73.55868061700005, 31.43329884700006],
            [73.558504000000084, 31.433134000000052],
            [73.55052100000006, 31.426862000000028],
            [73.550387514000079, 31.426802670000029],
            [73.543809877000058, 31.423879133000071],
            [73.540257000000054, 31.422300000000064],
            [73.540009400000031, 31.422264623000046],
            [73.528428782000049, 31.420609972000079],
            [73.528380503000051, 31.420603074000041],
            [73.528282000000047, 31.420589000000064],
            [73.528235763000055, 31.420603074000041],
            [73.528093969000054, 31.42064623400006],
            [73.520013381000069, 31.423105838000026],
            [73.518036399000039, 31.423707600000057],
            [73.515266177000058, 31.424550812000064],
            [73.515157223000074, 31.424603110000078],
            [73.502057223000065, 31.441703110000049],
            [73.498013504000085, 31.445297527000037],
            [73.496657223000057, 31.446503110000037],
            [73.494870942000034, 31.448070663000067],
            [73.49178900000004, 31.450810000000047],
            [73.47810400000003, 31.461074000000053],
            [73.466157223000039, 31.472503110000048],
            [73.467755382000064, 31.475730765000037],
            [73.469668899000055, 31.479557799000077],
            [73.471261000000084, 31.48274200000003],
            [73.477193217000035, 31.487403505000032],
            [73.479244000000051, 31.489015000000052],
            [73.482889669000031, 31.491097913000033],
            [73.487227000000075, 31.493576000000076],
            [73.487227000000075, 31.493576036000036],
            [73.487227000000075, 31.493576719000032],
            [73.487228000000073, 31.493576000000076],
            [73.487228000000073, 31.493576036000036],
            [73.487227000000075, 31.510254000000032],
            [73.487227000000075, 31.510683000000029],
            [73.486802658000045, 31.521295264000059],
            [73.486657000000037, 31.524938000000077],
            [73.490649000000076, 31.533491000000026],
            [73.488539571000047, 31.536175452000066],
            [73.484376000000054, 31.541474000000051],
            [73.472402000000045, 31.548887000000036],
            [73.470233873000041, 31.550693614000068],
            [73.468805261000057, 31.551884020000045],
            [73.465559000000042, 31.554589000000078],
            [73.472402000000045, 31.558010000000024],
            [73.461568000000057, 31.563712000000066],
            [73.454624677000083, 31.567184096000062],
            [73.453585000000032, 31.567704000000049],
            [73.457152823000058, 31.580548036000039],
            [73.45718148900005, 31.580651233000026],
            [73.459287000000074, 31.588231000000064],
            [73.460427000000038, 31.603057000000035],
            [73.460427000000038, 31.604716805000066],
            [73.460427000000038, 31.617312000000027],
            [73.455051744000059, 31.624300210000058],
            [73.454725000000053, 31.624725000000069],
            [73.454242880000038, 31.625103787000057],
            [73.446742000000029, 31.630997000000036],
            [73.432994953000048, 31.637046472000065],
            [73.432487000000037, 31.637270000000058],
            [73.429637823000064, 31.638013197000078],
            [73.42546132800004, 31.639102621000063],
            [73.419372000000067, 31.640691000000061],
            [73.410349951000057, 31.64520202500006],
            [73.40772729400004, 31.646513353000046],
            [73.403406000000075, 31.648674000000028],
            [73.392002000000048, 31.654946000000052],
            [73.387550117000046, 31.654946000000052],
            [73.382817848000059, 31.654946000000052],
            [73.378317000000038, 31.654946000000052],
            [73.378407000000038, 31.655218000000048],
            [73.380598000000077, 31.661789000000056],
            [73.386870000000044, 31.669202000000041],
            [73.377176000000077, 31.667491000000041],
            [73.371474000000035, 31.674904000000026],
            [73.371538823000037, 31.675179461000027],
            [73.371797491000052, 31.676278657000069],
            [73.373755000000074, 31.684597000000053],
            [73.376274202000047, 31.693953133000036],
            [73.376283645000058, 31.69398820300006],
            [73.377747000000056, 31.699423000000024],
            [73.376048673000071, 31.704517483000075],
            [73.373977849000084, 31.710729351000055],
            [73.370904000000053, 31.71995000000004],
            [73.363423306000072, 31.720275694000065],
            [73.357789000000082, 31.720521000000076],
            [73.344674000000055, 31.720521000000076],
            [73.342192645000068, 31.719184577000078],
            [73.337262000000067, 31.716529000000037],
            [73.325683310000045, 31.721160151000049],
            [73.323006000000078, 31.722231000000079],
            [73.313313000000051, 31.729074000000026],
            [73.30786788100005, 31.732226279000031],
            [73.305190290000041, 31.733776385000056],
            [73.302479000000062, 31.73534600000005],
            [73.293355000000076, 31.736487000000068],
            [73.293049688000053, 31.737046755000051],
            [73.289934000000073, 31.742759000000035],
            [73.296777000000077, 31.750742000000059],
            [73.296777000000077, 31.763287000000048],
            [73.296829153000033, 31.764384775000053],
            [73.296837991000075, 31.764570802000037],
            [73.296838000000037, 31.764571000000046],
            [73.299792000000082, 31.764099000000044],
            [73.305980000000034, 31.764826000000028],
            [73.313988000000052, 31.769194000000027],
            [73.313988996000035, 31.769194001000074],
            [73.32476717000003, 31.769194000000027],
            [73.326364000000069, 31.769194000000027],
            [73.327042155000072, 31.773828061000074],
            [73.328548000000069, 31.784118000000035],
            [73.329872184000067, 31.78569440900003],
            [73.33619200000004, 31.793218000000024],
            [73.345308444000068, 31.792880354000033],
            [73.346020000000067, 31.792854000000034],
            [73.358033323000029, 31.791852813000048],
            [73.359123000000068, 31.791762000000062],
            [73.359123158000045, 31.791762070000061],
            [73.374166830000036, 31.798448147000045],
            [73.375503000000037, 31.799042000000043],
            [73.375503889000072, 31.799041951000049],
            [73.38650460100007, 31.798430800000062],
            [73.388607000000036, 31.798314000000062],
            [73.388607094000065, 31.798314049000055],
            [73.388607897000043, 31.798314470000037],
            [73.396251000000063, 31.802318000000071],
            [73.399527000000035, 31.807414000000051],
            [73.404623000000072, 31.82306600000004],
            [73.407525067000051, 31.820325159000049],
            [73.411175000000071, 31.816878000000031],
            [73.417741422000063, 31.809217175000072],
            [73.41991100000007, 31.80668600000007],
            [73.426262294000082, 31.805338756000026],
            [73.43192300000004, 31.804138000000023],
            [73.436274344000083, 31.804500633000032],
            [73.449394000000041, 31.805594000000042],
            [73.450152247000062, 31.807044559000076],
            [73.453229211000064, 31.812930926000035],
            [73.457766000000049, 31.821610000000078],
            [73.459714015000031, 31.828802672000052],
            [73.462498000000039, 31.839082000000076],
            [73.462498917000062, 31.839081694000072],
            [73.462499000000037, 31.839082000000076],
            [73.470572028000049, 31.83639099100003],
            [73.470572064000066, 31.836390979000043],
            [73.471235000000036, 31.836170000000038],
            [73.482156820000057, 31.830568672000027],
            [73.485430000000065, 31.828890000000058],
            [73.491606377000039, 31.824826594000058],
            [73.49348119900003, 31.82359315900004],
            [73.498926637000068, 31.820010634000027],
            [73.499262000000044, 31.819790000000069],
            [73.512498588000028, 31.811806027000046],
            [73.512498624000045, 31.811806005000051],
            [73.522193167000069, 31.805958502000067],
            [73.522193636000054, 31.805959909000023],
            [73.524377000000072, 31.812510000000032],
            [73.527335581000045, 31.814591964000044],
            [73.534205000000043, 31.819426000000078],
            [73.54621700000007, 31.830710000000067],
            [73.559401230000049, 31.842506416000049],
            [73.560049000000049, 31.843086000000028],
            [73.560049019000076, 31.843086109000069],
            [73.560947763000058, 31.848349832000054],
            [73.562597000000039, 31.858009000000038],
            [73.562597000000039, 31.868565000000046],
            [73.560644384000057, 31.874227586000075],
            [73.558957000000078, 31.879121000000055],
            [73.559321000000068, 31.889313000000072],
            [73.568785000000048, 31.891861000000063],
            [73.581161000000066, 31.879849000000036],
            [73.592808000000048, 31.870749000000046],
            [73.597056786000053, 31.869715511000038],
            [73.606276000000037, 31.867473000000075],
            [73.623471719000065, 31.878567012000076],
            [73.628844000000072, 31.882033000000035],
            [73.641648033000081, 31.885554109000054],
            [73.644323725000049, 31.886289924000039],
            [73.65014962500004, 31.887892047000037],
            [73.65796400000005, 31.890041000000053],
            [73.67127664700007, 31.889616343000057],
            [73.671428816000059, 31.889611487000025],
            [73.673832136000044, 31.889534785000023],
            [73.67383221700004, 31.889534782000055],
            [73.673832172000061, 31.889534678000075],
            [73.689604126000063, 31.889031317000047],
            [73.689604115000066, 31.889031180000075],
            [73.692179000000067, 31.888949000000025],
            [73.692179176000082, 31.888949135000075],
            [73.712925000000041, 31.904865000000029],
            [73.712925398000039, 31.904864886000041],
            [73.712925127000062, 31.904864678000024],
            [73.712939789000075, 31.904860489000043],
            [73.714003747000049, 31.904556501000059],
            [73.715094500000077, 31.90424500000006],
            [73.72599450000007, 31.894902000000059],
            [73.73378050000008, 31.890231000000028],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "113",
      properties: { name: "Narowal", count: 90 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [75.033480000000054, 32.491899000000046],
            [75.036435655000048, 32.490483467000047],
            [75.039409500000033, 32.492509000000041],
            [75.041020977000073, 32.49173859800004],
            [75.042078169000035, 32.491233184000066],
            [75.042789255000059, 32.490893234000055],
            [75.044016354000064, 32.490306593000071],
            [75.04622683100007, 32.489249826000048],
            [75.055518438000036, 32.484807769000042],
            [75.056403991000082, 32.484384412000054],
            [75.057064723000053, 32.484068534000073],
            [75.058336401000076, 32.484004588000062],
            [75.060068293000029, 32.483917500000075],
            [75.062233135000042, 32.483808642000042],
            [75.063458657000069, 32.483747017000042],
            [75.065578367000057, 32.483640427000068],
            [75.068939724000074, 32.483471402000077],
            [75.07241995000004, 32.483296400000029],
            [75.074272069000074, 32.482370353000078],
            [75.074753232000035, 32.482129775000033],
            [75.079639215000043, 32.479686817000072],
            [75.082704308000075, 32.478154292000056],
            [75.087133108000046, 32.475939922000066],
            [75.090908857000045, 32.474052073000053],
            [75.093912514000067, 32.472550265000052],
            [75.094179219000068, 32.472259802000053],
            [75.094268000000056, 32.472370000000069],
            [75.095434000000068, 32.473005000000057],
            [75.097427000000039, 32.474783000000059],
            [75.097835000000032, 32.476006000000041],
            [75.098666000000037, 32.47736800000007],
            [75.100255000000061, 32.478079000000037],
            [75.101597000000083, 32.477424000000042],
            [75.102826334000042, 32.476854624000055],
            [75.102857590000042, 32.476840148000065],
            [75.103022000000067, 32.47676400000006],
            [75.105534000000034, 32.475660000000062],
            [75.108786000000066, 32.474501000000032],
            [75.108866000000035, 32.47393100000005],
            [75.10935900000004, 32.472293000000036],
            [75.109017000000051, 32.470520000000079],
            [75.108845000000031, 32.469317000000046],
            [75.109424000000047, 32.468677000000071],
            [75.109751000000074, 32.467686000000072],
            [75.110914000000037, 32.46768000000003],
            [75.110995000000059, 32.467327000000068],
            [75.111243000000059, 32.467114000000038],
            [75.111150000000066, 32.465564000000029],
            [75.110645000000034, 32.464227000000051],
            [75.109807000000046, 32.462544000000037],
            [75.109018000000049, 32.460300000000075],
            [75.112533000000042, 32.458688000000052],
            [75.117286000000036, 32.456423000000029],
            [75.11886974500004, 32.455874477000066],
            [75.119000906000053, 32.455829050000034],
            [75.120693000000074, 32.455243000000053],
            [75.120245000000068, 32.452886000000035],
            [75.119715000000042, 32.450399000000061],
            [75.119192000000055, 32.448415000000068],
            [75.118751000000032, 32.446154000000035],
            [75.119400000000041, 32.444811000000072],
            [75.119208855000068, 32.444364645000064],
            [75.119036000000051, 32.443961000000058],
            [75.120184712000082, 32.443277328000079],
            [75.120433590000061, 32.443129205000048],
            [75.122551000000044, 32.441869000000054],
            [75.12453400000004, 32.436555000000055],
            [75.124703356000055, 32.436530742000059],
            [75.125505363000059, 32.436415864000026],
            [75.125541310000074, 32.436410715000079],
            [75.126035000000059, 32.43634000000003],
            [75.126360000000034, 32.434984000000043],
            [75.127778000000035, 32.434967000000029],
            [75.12985400000008, 32.434168000000057],
            [75.12925400000006, 32.432255000000055],
            [75.129685464000033, 32.432060018000072],
            [75.130516798000031, 32.431684330000053],
            [75.131323000000066, 32.431320000000028],
            [75.132175123000081, 32.429933263000066],
            [75.132640771000069, 32.429200004000052],
            [75.134529000000043, 32.428161000000046],
            [75.13310800000005, 32.425993000000062],
            [75.131361000000084, 32.426003000000037],
            [75.131293756000048, 32.426005744000065],
            [75.130063000000064, 32.426055961000031],
            [75.128853735000064, 32.426105301000064],
            [75.128567000000032, 32.426117000000033],
            [75.127619000000038, 32.423952000000043],
            [75.127628470000047, 32.423641140000029],
            [75.127659321000067, 32.422628363000058],
            [75.127676410000049, 32.422067414000026],
            [75.12767672800004, 32.422056953000038],
            [75.132113018000041, 32.418617980000079],
            [75.133836391000045, 32.417278069000076],
            [75.135550883000064, 32.416826744000048],
            [75.141846481000073, 32.415169485000035],
            [75.143682258000069, 32.414682658000061],
            [75.145105112000067, 32.415247621000049],
            [75.146079000000043, 32.415626000000032],
            [75.146254000000056, 32.415914000000043],
            [75.146818000000053, 32.416526000000033],
            [75.147556000000066, 32.417103000000054],
            [75.148431000000073, 32.418225000000064],
            [75.149388268000052, 32.418116812000051],
            [75.149394839000081, 32.418116070000053],
            [75.149422000000072, 32.418113000000062],
            [75.150192000000061, 32.419156000000044],
            [75.151909000000046, 32.418929000000048],
            [75.152337000000045, 32.419650000000047],
            [75.15340800000007, 32.421238000000073],
            [75.155304000000058, 32.420683000000054],
            [75.156902000000059, 32.420386000000065],
            [75.158800000000042, 32.420085000000029],
            [75.159662000000083, 32.419864000000075],
            [75.159876000000054, 32.419610000000034],
            [75.160261000000048, 32.419210000000078],
            [75.161253000000045, 32.419242000000054],
            [75.162070000000028, 32.419057000000066],
            [75.163032000000044, 32.420320000000061],
            [75.163340000000062, 32.420790000000068],
            [75.164475000000039, 32.421767000000045],
            [75.16486800000007, 32.421983000000068],
            [75.166084000000069, 32.422013000000049],
            [75.167304000000058, 32.422153000000037],
            [75.167963000000043, 32.422442000000046],
            [75.168321000000049, 32.42287900000008],
            [75.170920000000081, 32.422537000000034],
            [75.175911000000042, 32.42112300000008],
            [75.176997000000028, 32.422470000000033],
            [75.177695000000028, 32.423420000000078],
            [75.17835000000008, 32.424336000000039],
            [75.178522501000032, 32.424470167000038],
            [75.17896200000007, 32.424812000000031],
            [75.179573000000062, 32.424919000000045],
            [75.181493000000046, 32.425129000000027],
            [75.182190000000048, 32.425162000000057],
            [75.183583000000056, 32.425191000000041],
            [75.185068000000058, 32.425771000000054],
            [75.186113000000034, 32.425912000000039],
            [75.186597000000063, 32.426388000000031],
            [75.186910000000069, 32.426977000000079],
            [75.188546000000031, 32.426373000000069],
            [75.189021000000082, 32.426296000000036],
            [75.189530000000047, 32.426328000000069],
            [75.190903000000048, 32.426353000000063],
            [75.191609000000085, 32.426050000000032],
            [75.19152500000007, 32.425516000000073],
            [75.192010000000039, 32.425402000000076],
            [75.192101000000036, 32.424983000000054],
            [75.193291000000045, 32.424149000000057],
            [75.192637000000047, 32.422304000000054],
            [75.197017000000073, 32.420510000000036],
            [75.197942000000069, 32.421682000000033],
            [75.198339000000033, 32.421719000000053],
            [75.199483000000043, 32.421264000000065],
            [75.199779220000039, 32.421197112000073],
            [75.200847000000067, 32.420956000000047],
            [75.200973000000033, 32.420203000000072],
            [75.200573000000077, 32.419569000000024],
            [75.199632000000065, 32.416764000000057],
            [75.200068000000044, 32.416465000000073],
            [75.201459000000057, 32.41527300000007],
            [75.201493000000085, 32.414126000000067],
            [75.197308000000078, 32.414068000000043],
            [75.196696000000031, 32.413482000000045],
            [75.194729000000052, 32.412574000000063],
            [75.194366311000067, 32.412461975000042],
            [75.193557000000055, 32.412212000000068],
            [75.192957000000035, 32.411239000000023],
            [75.192603000000076, 32.409758000000068],
            [75.193189000000075, 32.407958000000065],
            [75.193419000000063, 32.405758000000048],
            [75.193196000000057, 32.404038000000071],
            [75.192940000000078, 32.402127000000064],
            [75.19281200000006, 32.400972000000024],
            [75.194172000000037, 32.400295000000028],
            [75.196754000000055, 32.398946000000024],
            [75.200194000000067, 32.397226000000046],
            [75.202909702000056, 32.396272528000054],
            [75.206124000000045, 32.395144000000073],
            [75.209164000000044, 32.394655000000057],
            [75.209638000000041, 32.396956000000046],
            [75.210246000000041, 32.398200000000031],
            [75.210463000000061, 32.398236000000054],
            [75.210826350000048, 32.39885354300003],
            [75.211415000000045, 32.399854000000062],
            [75.212591000000032, 32.401925000000062],
            [75.213118000000065, 32.402740000000051],
            [75.215123000000062, 32.401658000000054],
            [75.216577795000035, 32.400849574000063],
            [75.217475000000036, 32.400351000000057],
            [75.21808800000008, 32.400460000000066],
            [75.219002000000046, 32.399973000000045],
            [75.22022000000004, 32.399668000000077],
            [75.221912000000032, 32.399212000000034],
            [75.222475000000031, 32.39909700000004],
            [75.22300748300006, 32.398850542000048],
            [75.223146497000073, 32.398786199000028],
            [75.225957365000056, 32.398849814000073],
            [75.235463026000048, 32.395048054000029],
            [75.237471808000066, 32.39424464700005],
            [75.237852347000057, 32.39397991800007],
            [75.237853010000038, 32.393979457000057],
            [75.246951204000084, 32.387648675000037],
            [75.246951807000073, 32.387649708000026],
            [75.247416000000044, 32.388445000000047],
            [75.249116000000072, 32.389132000000075],
            [75.250978000000032, 32.390967000000046],
            [75.252575000000036, 32.390999000000079],
            [75.252927000000057, 32.390922000000046],
            [75.253454000000033, 32.390846000000067],
            [75.254632511000068, 32.390389385000049],
            [75.254729000000054, 32.390352000000064],
            [75.256880000000081, 32.389440000000036],
            [75.257182000000057, 32.388461000000063],
            [75.256212000000062, 32.387871000000075],
            [75.255814000000044, 32.38704800000005],
            [75.256163000000072, 32.386710000000051],
            [75.256601000000046, 32.386594000000059],
            [75.256686000000059, 32.386221000000035],
            [75.256379000000038, 32.386149000000046],
            [75.255545000000041, 32.385482000000025],
            [75.255565000000047, 32.38509700000003],
            [75.255262000000073, 32.38477800000004],
            [75.254807000000085, 32.384332000000029],
            [75.255478000000039, 32.382925000000057],
            [75.25532400000003, 32.382352000000026],
            [75.255772000000036, 32.381776000000059],
            [75.255869664000045, 32.380998757000043],
            [75.255881241000054, 32.380906623000044],
            [75.26260491000005, 32.372252999000068],
            [75.262813491000031, 32.371984547000068],
            [75.262820550000072, 32.37197546200008],
            [75.263248526000041, 32.371925548000036],
            [75.26326664100003, 32.371931019000044],
            [75.264750000000049, 32.372379000000024],
            [75.265879000000041, 32.373125000000073],
            [75.266548000000057, 32.372996000000057],
            [75.267300000000034, 32.373369000000025],
            [75.268035000000054, 32.372739000000024],
            [75.270512000000053, 32.373438000000078],
            [75.271358000000077, 32.374537000000032],
            [75.272772000000032, 32.376124000000061],
            [75.272792675000062, 32.376117546000046],
            [75.275213000000065, 32.375362000000052],
            [75.281645000000083, 32.373233000000027],
            [75.28990600000003, 32.370017000000075],
            [75.289770000000033, 32.367812000000072],
            [75.289205000000038, 32.36695700000007],
            [75.288498000000061, 32.366105000000061],
            [75.28779000000003, 32.365012000000036],
            [75.287508000000059, 32.363800000000026],
            [75.287367000000074, 32.362589000000071],
            [75.28708400000005, 32.361258000000078],
            [75.287934000000064, 32.360045000000071],
            [75.289065000000051, 32.358470000000068],
            [75.288924000000065, 32.357625000000041],
            [75.289630000000045, 32.356658000000039],
            [75.289348000000075, 32.355815000000064],
            [75.290620000000047, 32.355687000000046],
            [75.291186000000039, 32.355804000000035],
            [75.291430797000032, 32.355739816000039],
            [75.292601000000047, 32.355433000000062],
            [75.293986019000045, 32.35483358700003],
            [75.294122380000033, 32.354774572000053],
            [75.294297000000029, 32.354699000000039],
            [75.295569000000057, 32.354089000000045],
            [75.296981000000073, 32.353719000000069],
            [75.298254000000043, 32.353831000000071],
            [75.299807000000044, 32.353218000000027],
            [75.300224000000071, 32.351772000000039],
            [75.299794000000077, 32.35009400000007],
            [75.299508203000073, 32.349274797000078],
            [75.299083000000053, 32.348056000000042],
            [75.298797000000036, 32.346378000000072],
            [75.303176146000055, 32.344814306000046],
            [75.308624000000066, 32.342869000000064],
            [75.309555173000035, 32.343185207000033],
            [75.313854000000049, 32.344645000000071],
            [75.315116000000046, 32.344039000000066],
            [75.317933000000039, 32.343784000000028],
            [75.321874000000037, 32.343519000000072],
            [75.324090000000069, 32.340877000000035],
            [75.325603000000058, 32.338602000000037],
            [75.325578000000064, 32.336936000000037],
            [75.325285734000033, 32.334843748000026],
            [75.325263000000064, 32.334681000000046],
            [75.325649000000055, 32.332426000000055],
            [75.32672800000006, 32.329697000000067],
            [75.326698000000079, 32.327808000000061],
            [75.32740037800005, 32.327704845000028],
            [75.329274240000075, 32.327429639000059],
            [75.330048000000033, 32.327316000000053],
            [75.331741522000073, 32.326618013000029],
            [75.333241000000044, 32.326000000000079],
            [75.334896000000072, 32.324812000000065],
            [75.335413000000074, 32.324159000000066],
            [75.336385000000064, 32.323277000000076],
            [75.337349000000074, 32.321921000000032],
            [75.337519000000043, 32.320535000000064],
            [75.337220000000059, 32.318726000000026],
            [75.336448000000075, 32.31666400000006],
            [75.335831000000042, 32.316465000000051],
            [75.33483700000005, 32.316227000000026],
            [75.333468000000039, 32.316035000000056],
            [75.332622000000072, 32.316162000000077],
            [75.331287000000032, 32.314882000000068],
            [75.330034000000069, 32.312631000000067],
            [75.333721000000082, 32.311682000000076],
            [75.332036412000036, 32.309682676000079],
            [75.330911000000071, 32.308347000000026],
            [75.33075800000006, 32.30747400000007],
            [75.328643000000056, 32.305787000000066],
            [75.327380000000062, 32.30484800000005],
            [75.325675600000068, 32.303639538000027],
            [75.324909000000048, 32.303096000000039],
            [75.323671000000047, 32.302126000000044],
            [75.322706000000039, 32.301131000000055],
            [75.322425000000067, 32.300083000000029],
            [75.321791000000076, 32.299156000000039],
            [75.321260000000052, 32.298208000000045],
            [75.321238000000051, 32.297104000000047],
            [75.325645000000065, 32.29574400000007],
            [75.330181000000039, 32.297380000000032],
            [75.330990222000082, 32.297074432000045],
            [75.335790000000031, 32.295262000000037],
            [75.337804000000062, 32.294924000000037],
            [75.338712000000044, 32.293946000000062],
            [75.339144000000033, 32.293018000000075],
            [75.341064000000074, 32.292651000000035],
            [75.344445000000064, 32.291868000000079],
            [75.347023000000036, 32.291092000000049],
            [75.349225000000047, 32.290316000000075],
            [75.349878000000047, 32.288593000000049],
            [75.349221000000057, 32.287604000000044],
            [75.349160937000079, 32.287502179000057],
            [75.348568000000057, 32.286497000000054],
            [75.347937849000061, 32.285547849000068],
            [75.34768500000007, 32.285167000000058],
            [75.347162000000083, 32.284191000000078],
            [75.346640000000036, 32.283219000000031],
            [75.346309000000076, 32.282530000000065],
            [75.345977000000062, 32.281805000000077],
            [75.34526900000003, 32.281044000000065],
            [75.344612000000041, 32.279997000000037],
            [75.343912000000046, 32.278911000000051],
            [75.344287000000065, 32.278468000000032],
            [75.34569300000004, 32.278418000000045],
            [75.347897000000046, 32.278605000000027],
            [75.349820000000079, 32.278918000000033],
            [75.354506000000072, 32.279126000000076],
            [75.356388000000038, 32.278990000000078],
            [75.360418000000038, 32.278807000000029],
            [75.364311000000043, 32.278861000000063],
            [75.365906000000052, 32.279054000000031],
            [75.369992000000082, 32.279509000000076],
            [75.371681000000081, 32.279458000000034],
            [75.372287000000028, 32.279172000000074],
            [75.372749000000056, 32.278725000000065],
            [75.372880000000066, 32.278081000000043],
            [75.372872000000029, 32.277521000000036],
            [75.374039000000039, 32.277392000000077],
            [75.374501000000066, 32.277029000000027],
            [75.375467000000071, 32.276066000000071],
            [75.37688200000008, 32.27438600000005],
            [75.377926000000059, 32.272993000000042],
            [75.378309000000058, 32.270173000000057],
            [75.381386000000077, 32.268815000000075],
            [75.381324000000063, 32.268288000000041],
            [75.380771000000038, 32.266959000000043],
            [75.380442000000073, 32.266881000000069],
            [75.379972000000066, 32.266845000000046],
            [75.37917200000004, 32.266730000000052],
            [75.378191000000072, 32.266978000000051],
            [75.377170000000035, 32.267510000000073],
            [75.376574000000062, 32.268241000000046],
            [75.37620400000003, 32.268646000000047],
            [75.37559200000004, 32.268610000000024],
            [75.374931000000061, 32.268453000000079],
            [75.374500000000069, 32.267972000000043],
            [75.374212000000057, 32.267651000000058],
            [75.373552000000075, 32.267416000000026],
            [75.37331400000005, 32.267178000000058],
            [75.373072000000036, 32.266696000000024],
            [75.373057000000074, 32.265848000000062],
            [75.373046000000045, 32.265085000000056],
            [75.373250676000055, 32.264510420000079],
            [75.37340400000005, 32.264080000000035],
            [75.37239900000003, 32.262530000000027],
            [75.371775000000071, 32.26122300000003],
            [75.37095800000003, 32.259250000000065],
            [75.370738062000044, 32.25853531100006],
            [75.370449000000065, 32.257596000000035],
            [75.370562000000064, 32.255199000000061],
            [75.370618000000036, 32.252024000000063],
            [75.370621506000077, 32.250570868000068],
            [75.37063100000006, 32.246636000000024],
            [75.370272000000057, 32.242268000000024],
            [75.372229000000061, 32.241779000000065],
            [75.373098369000047, 32.237848194000037],
            [75.373099882000076, 32.237841354000068],
            [75.375050000000044, 32.229024000000038],
            [75.37475900000004, 32.226970000000051],
            [75.374138000000073, 32.226383000000055],
            [75.372552000000042, 32.224274000000037],
            [75.370634000000052, 32.225266000000033],
            [75.368707000000029, 32.226182000000051],
            [75.365746000000058, 32.223771000000056],
            [75.365388000000053, 32.22143600000004],
            [75.365606000000071, 32.220620000000054],
            [75.365600000000029, 32.220195000000047],
            [75.365499000000057, 32.219655000000046],
            [75.364394000000061, 32.219546000000037],
            [75.364359335000074, 32.219538642000032],
            [75.363334000000066, 32.219321000000036],
            [75.361952000000031, 32.218983000000037],
            [75.360435000000052, 32.218762000000027],
            [75.358413000000041, 32.218351000000041],
            [75.35738200000003, 32.218023000000073],
            [75.356870000000072, 32.218171000000041],
            [75.356295000000046, 32.218320000000062],
            [75.355442000000039, 32.218355000000031],
            [75.354512000000057, 32.218215000000043],
            [75.353245000000072, 32.218136000000072],
            [75.351804000000072, 32.217855000000043],
            [75.350501000000065, 32.217659000000026],
            [75.348996000000056, 32.217553000000066],
            [75.34893100000005, 32.217814000000033],
            [75.348215000000039, 32.217877000000044],
            [75.347530000000063, 32.217764000000045],
            [75.346472000000063, 32.217800000000068],
            [75.345958000000053, 32.217688000000066],
            [75.344829000000061, 32.217435000000023],
            [75.344714750000037, 32.217410589000053],
            [75.343907000000058, 32.217238000000066],
            [75.342307000000062, 32.217017000000055],
            [75.341702000000055, 32.21725200000003],
            [75.337181246000057, 32.219377125000051],
            [75.336939000000029, 32.219491000000062],
            [75.335231000000078, 32.21966800000007],
            [75.331326000000047, 32.21957400000008],
            [75.331248000000073, 32.219279000000029],
            [75.330783000000054, 32.218661000000054],
            [75.329713000000083, 32.217313000000047],
            [75.328807893000032, 32.216163786000038],
            [75.328152000000046, 32.215331000000049],
            [75.327070000000049, 32.213902000000076],
            [75.326147000000049, 32.213133000000028],
            [75.325540000000046, 32.212567000000035],
            [75.324049000000059, 32.211005000000057],
            [75.323210000000074, 32.210508000000061],
            [75.322330137000051, 32.209980654000049],
            [75.321363000000076, 32.209401000000071],
            [75.320214000000078, 32.208645000000047],
            [75.319418000000042, 32.208239000000049],
            [75.31907300000006, 32.208154000000036],
            [75.316119000000072, 32.208055000000058],
            [75.318323000000078, 32.205857000000037],
            [75.319977898000047, 32.204261537000036],
            [75.320105000000069, 32.204139000000055],
            [75.320055172000082, 32.204109024000047],
            [75.31972600000006, 32.203911000000062],
            [75.318149000000062, 32.203058000000055],
            [75.316200000000038, 32.201952000000063],
            [75.315506000000084, 32.201530000000048],
            [75.314920000000029, 32.201184000000069],
            [75.314336000000083, 32.200898000000052],
            [75.313716000000056, 32.200495000000046],
            [75.312824000000035, 32.200066000000049],
            [75.311899000000039, 32.199522000000059],
            [75.311419000000058, 32.199006000000054],
            [75.311008000000072, 32.198489000000052],
            [75.311075000000073, 32.198230000000024],
            [75.310775000000035, 32.197848000000079],
            [75.310533000000078, 32.197410000000048],
            [75.310291000000063, 32.196797000000061],
            [75.310049000000049, 32.196097000000066],
            [75.310114000000056, 32.194934000000046],
            [75.310250000000053, 32.194586000000072],
            [75.31188300000008, 32.192093000000057],
            [75.313412087000074, 32.189696275000074],
            [75.313559000000055, 32.189466000000039],
            [75.315071000000046, 32.188863000000026],
            [75.318325000000073, 32.187520000000063],
            [75.318276000000083, 32.186641000000066],
            [75.317886000000044, 32.185854000000063],
            [75.317635000000053, 32.185213000000033],
            [75.317483910000078, 32.184765824000067],
            [75.317164000000048, 32.183819000000028],
            [75.31614200000007, 32.180927000000054],
            [75.316865000000064, 32.180894000000023],
            [75.320847554000068, 32.17967456100007],
            [75.321078000000057, 32.17960400000004],
            [75.32079600000003, 32.179254000000071],
            [75.320650000000057, 32.178874000000064],
            [75.320502000000033, 32.178378000000066],
            [75.319942000000083, 32.17794200000003],
            [75.319418000000042, 32.177478000000065],
            [75.318580000000054, 32.176581000000056],
            [75.317467000000079, 32.175515000000075],
            [75.315756000000079, 32.173902000000055],
            [75.31361000000004, 32.172288000000037],
            [75.312137000000064, 32.171204000000046],
            [75.311492000000044, 32.171768000000043],
            [75.311070000000029, 32.171242000000063],
            [75.310166000000038, 32.170604000000026],
            [75.308451000000048, 32.169342000000029],
            [75.307274000000064, 32.168379000000073],
            [75.306021000000044, 32.167092000000025],
            [75.305057000000033, 32.165903000000071],
            [75.303994000000046, 32.164837000000034],
            [75.303490969000052, 32.16379730500006],
            [75.303306000000077, 32.163415000000043],
            [75.30266000000006, 32.162033000000065],
            [75.302308000000039, 32.161478000000045],
            [75.301671000000056, 32.160571000000061],
            [75.300857000000065, 32.158941000000027],
            [75.300188000000048, 32.157695000000047],
            [75.299791000000084, 32.156507000000033],
            [75.300401000000079, 32.155962000000045],
            [75.299443000000053, 32.153351000000043],
            [75.298718000000065, 32.153415000000052],
            [75.298065000000065, 32.153480000000059],
            [75.296965000000057, 32.15355500000004],
            [75.295992000000069, 32.153467000000035],
            [75.294880000000035, 32.15331800000007],
            [75.293736000000081, 32.153201000000024],
            [75.292665000000056, 32.153090000000077],
            [75.291474000000051, 32.153069000000073],
            [75.287916000000052, 32.152883000000031],
            [75.287411000000077, 32.151560000000075],
            [75.285309000000041, 32.151570000000049],
            [75.283252187000073, 32.148404511000024],
            [75.28231100000005, 32.146956000000046],
            [75.283422878000067, 32.146476009000025],
            [75.283592000000056, 32.146403000000078],
            [75.282643000000064, 32.145094000000029],
            [75.281804000000079, 32.14417700000007],
            [75.280761000000041, 32.143174000000045],
            [75.280271000000084, 32.142117000000042],
            [75.279836767000063, 32.141378176000046],
            [75.279648000000066, 32.141057000000046],
            [75.278891000000044, 32.139768000000061],
            [75.278271000000075, 32.138459000000068],
            [75.277446416000032, 32.136925107000025],
            [75.27738400000004, 32.136809000000028],
            [75.278327000000047, 32.135745000000043],
            [75.279459000000031, 32.134401000000025],
            [75.280246000000034, 32.133626000000049],
            [75.28161700000004, 32.132849000000078],
            [75.283358000000078, 32.131664000000058],
            [75.282470000000046, 32.129696000000024],
            [75.28189100000003, 32.12846600000006],
            [75.281670000000076, 32.127835000000061],
            [75.281504000000041, 32.126308000000051],
            [75.281549000000041, 32.124880000000076],
            [75.28148200000004, 32.123073000000034],
            [75.281295000000057, 32.122306000000037],
            [75.281205391000071, 32.122118156000056],
            [75.280973000000074, 32.121631000000036],
            [75.28069000000005, 32.12113400000004],
            [75.280229000000077, 32.120143000000041],
            [75.279728000000034, 32.118719000000056],
            [75.279407000000049, 32.117850000000033],
            [75.278610000000072, 32.116958000000068],
            [75.277955000000077, 32.11627100000004],
            [75.276891000000035, 32.115271000000064],
            [75.275885000000073, 32.114538000000039],
            [75.274855000000059, 32.113856000000055],
            [75.274688000000083, 32.114036000000056],
            [75.274299000000042, 32.113829000000067],
            [75.273667000000046, 32.11353500000007],
            [75.272090000000048, 32.112772000000064],
            [75.267922000000056, 32.112315000000024],
            [75.266338416000053, 32.107133474000079],
            [75.266242000000034, 32.106818000000032],
            [75.26138700000007, 32.101513000000068],
            [75.258264000000054, 32.102578000000051],
            [75.258149857000035, 32.102523423000036],
            [75.255679000000043, 32.101342000000045],
            [75.249977000000058, 32.100321000000065],
            [75.247969000000069, 32.099978000000078],
            [75.24557627300004, 32.099534672000061],
            [75.244898000000035, 32.099409000000037],
            [75.244293000000084, 32.09833100000003],
            [75.243477000000041, 32.096794000000045],
            [75.242599000000041, 32.095239000000049],
            [75.242283000000043, 32.094693000000063],
            [75.240357000000074, 32.091046000000063],
            [75.236139000000037, 32.091410000000053],
            [75.234491000000048, 32.091817000000049],
            [75.233640000000037, 32.091737000000023],
            [75.232886000000065, 32.091344000000049],
            [75.231231000000037, 32.09146700000008],
            [75.229502000000082, 32.093386000000066],
            [75.230456000000061, 32.094962000000066],
            [75.232335000000035, 32.09814300000005],
            [75.23362300000008, 32.100249000000076],
            [75.23524800000007, 32.102139000000079],
            [75.234763293000071, 32.102619257000072],
            [75.233396000000084, 32.103974000000051],
            [75.23116600000003, 32.106234000000029],
            [75.22711600000008, 32.106809000000055],
            [75.226546000000042, 32.10570000000007],
            [75.226387000000045, 32.104417000000069],
            [75.226230000000044, 32.103257000000042],
            [75.225430000000074, 32.102220000000045],
            [75.224455000000034, 32.100900000000024],
            [75.223834000000068, 32.100186000000065],
            [75.223387000000059, 32.099759000000063],
            [75.222499000000084, 32.099336000000051],
            [75.221117000000049, 32.10037200000005],
            [75.220236000000057, 32.100979000000052],
            [75.219558000000063, 32.101559000000066],
            [75.217687000000069, 32.103245000000072],
            [75.219930000000033, 32.106760000000065],
            [75.219407000000047, 32.109575000000063],
            [75.218927000000065, 32.113414000000034],
            [75.218871000000036, 32.115439000000038],
            [75.217629000000045, 32.116378000000054],
            [75.217756854000072, 32.118805042000076],
            [75.217805000000055, 32.119719000000032],
            [75.216023858000085, 32.11986401200005],
            [75.211983000000032, 32.120193000000029],
            [75.209877000000063, 32.120179000000064],
            [75.202549000000033, 32.120075000000043],
            [75.202607726000053, 32.119870123000055],
            [75.204225000000065, 32.114228000000026],
            [75.204014000000029, 32.113694000000066],
            [75.204830000000072, 32.111945000000048],
            [75.205132000000049, 32.111002000000042],
            [75.205385256000056, 32.109454499000037],
            [75.205458000000078, 32.109010000000069],
            [75.205447463000041, 32.107871961000058],
            [75.205444000000057, 32.107498000000078],
            [75.205464000000063, 32.105907000000059],
            [75.205676000000039, 32.10412800000006],
            [75.205800000000067, 32.102672000000041],
            [75.205624000000057, 32.102261000000055],
            [75.205448000000047, 32.101849000000072],
            [75.205028000000084, 32.101202000000058],
            [75.204643000000033, 32.100381000000027],
            [75.204226000000062, 32.099708000000078],
            [75.203777000000059, 32.099330000000066],
            [75.20380700000004, 32.098600000000033],
            [75.204075000000046, 32.097696000000042],
            [75.204081000000031, 32.096691000000078],
            [75.20220267600007, 32.097079575000066],
            [75.202086441000063, 32.097103621000031],
            [75.201999797000042, 32.097121546000039],
            [75.199600000000032, 32.097618000000068],
            [75.197897000000069, 32.098564000000067],
            [75.196931000000063, 32.093296000000066],
            [75.197239000000081, 32.09258200000005],
            [75.197249194000051, 32.09236506700006],
            [75.197271000000057, 32.091901000000064],
            [75.19723300000004, 32.091222000000073],
            [75.197126000000083, 32.090577000000053],
            [75.19717200000008, 32.088651000000027],
            [75.197161000000051, 32.086870000000033],
            [75.196777000000054, 32.086902000000066],
            [75.196585000000084, 32.086211000000048],
            [75.196632000000079, 32.085398000000055],
            [75.197806000000071, 32.085209000000077],
            [75.198448000000042, 32.08266100000003],
            [75.195912000000078, 32.08353900000003],
            [75.192747000000054, 32.083021000000031],
            [75.189511957000036, 32.08245276100007],
            [75.189445000000035, 32.082441000000074],
            [75.188957000000073, 32.082235000000026],
            [75.186174000000051, 32.080565000000036],
            [75.183889000000079, 32.079248000000064],
            [75.181546000000083, 32.077854000000059],
            [75.179440000000056, 32.076562000000024],
            [75.178572000000031, 32.075498000000039],
            [75.177533000000039, 32.074410000000057],
            [75.177264000000036, 32.073586000000034],
            [75.176808000000051, 32.072298000000046],
            [75.176529000000073, 32.071102000000053],
            [75.175589000000059, 32.069452000000069],
            [75.174405000000036, 32.067381000000069],
            [75.172113000000081, 32.068400000000054],
            [75.171651000000054, 32.068864000000076],
            [75.170436000000052, 32.069419000000039],
            [75.16842900000006, 32.070038000000068],
            [75.168354150000084, 32.070091211000033],
            [75.167035000000055, 32.071029000000067],
            [75.166367000000037, 32.071317000000079],
            [75.166015000000073, 32.071800000000053],
            [75.16584400000005, 32.072798000000034],
            [75.165253000000064, 32.073400000000049],
            [75.164611000000036, 32.073518000000036],
            [75.164326000000074, 32.074178000000074],
            [75.164743000000044, 32.074463000000037],
            [75.157590000000084, 32.081832000000077],
            [75.154641000000083, 32.081198000000029],
            [75.153441000000043, 32.081264000000033],
            [75.152512000000058, 32.081328000000042],
            [75.152082805000077, 32.081382374000043],
            [75.151549000000045, 32.081450000000075],
            [75.147467590000076, 32.081469430000027],
            [75.144827000000078, 32.081482000000051],
            [75.143546000000072, 32.080810000000042],
            [75.142326000000082, 32.080847000000063],
            [75.141220000000033, 32.08076200000005],
            [75.140632000000039, 32.080648000000053],
            [75.14001200000007, 32.080564000000038],
            [75.13935900000007, 32.080509000000063],
            [75.138189000000068, 32.080514000000051],
            [75.136710000000051, 32.080609000000038],
            [75.135058000000072, 32.080705000000023],
            [75.132800595000049, 32.080844725000077],
            [75.131019000000038, 32.080955000000074],
            [75.123702000000037, 32.081390000000056],
            [75.122715000000085, 32.07904700000006],
            [75.12012100000004, 32.077313000000061],
            [75.118864940000037, 32.076459938000028],
            [75.118840000000034, 32.07644300000004],
            [75.118838245000063, 32.076441888000033],
            [75.118461859000035, 32.076203371000076],
            [75.117131000000029, 32.075360000000046],
            [75.115782000000081, 32.074432000000058],
            [75.114057000000059, 32.073219000000051],
            [75.11239547100007, 32.072115119000046],
            [75.110127000000034, 32.07060800000005],
            [75.107479000000069, 32.068853000000047],
            [75.108880918000068, 32.065606866000053],
            [75.109086000000048, 32.065132000000062],
            [75.108267000000069, 32.063901000000044],
            [75.106981204000078, 32.061852308000027],
            [75.10487500000005, 32.058741000000055],
            [75.10296900000003, 32.058893000000069],
            [75.101777000000084, 32.059216000000049],
            [75.101221900000041, 32.060011230000043],
            [75.099372000000074, 32.063128000000063],
            [75.097553000000062, 32.066506000000061],
            [75.096995510000056, 32.067471333000071],
            [75.096305000000029, 32.068667000000062],
            [75.094833000000051, 32.071164000000067],
            [75.094865000000084, 32.072587000000055],
            [75.093289000000084, 32.073399000000052],
            [75.092567828000028, 32.073529927000038],
            [75.091593203000059, 32.073706868000045],
            [75.091273000000058, 32.073765000000037],
            [75.090946000000031, 32.073290000000043],
            [75.090946440000039, 32.073209100000042],
            [75.090951000000075, 32.072370000000035],
            [75.091082000000029, 32.070922000000053],
            [75.09110400000003, 32.06726100000003],
            [75.091495000000066, 32.065895000000069],
            [75.090309000000047, 32.064947000000075],
            [75.088629842000046, 32.063755891000028],
            [75.088052000000062, 32.063346000000024],
            [75.086389000000054, 32.062065000000075],
            [75.08557600000006, 32.062068000000068],
            [75.083847000000048, 32.062132000000076],
            [75.074473582000053, 32.063473728000076],
            [75.072358283000028, 32.063776516000075],
            [75.072306000000083, 32.063784000000055],
            [75.067719000000068, 32.062196000000029],
            [75.065479053000047, 32.060106379000047],
            [75.065452512000036, 32.060081620000062],
            [75.065022000000056, 32.059680000000071],
            [75.065066000000058, 32.058584000000053],
            [75.064692000000036, 32.058245000000056],
            [75.06452100000007, 32.057934000000046],
            [75.064352000000042, 32.057640000000049],
            [75.063752000000079, 32.057039000000032],
            [75.06318200000004, 32.056463000000065],
            [75.062466000000029, 32.055840000000046],
            [75.062164000000053, 32.055440000000033],
            [75.061072000000081, 32.054895000000045],
            [75.060602000000074, 32.054747000000077],
            [75.058715000000063, 32.052872000000036],
            [75.05371400000007, 32.054405000000031],
            [75.050643000000036, 32.052703000000065],
            [75.05192900000003, 32.051301000000024],
            [75.05127200000004, 32.050800000000038],
            [75.050406000000066, 32.050175000000024],
            [75.049466937000034, 32.049429990000078],
            [75.049172000000056, 32.049196000000052],
            [75.048212000000035, 32.048371000000031],
            [75.046979000000078, 32.047558000000038],
            [75.044793000000084, 32.046125000000075],
            [75.043211000000042, 32.045069000000069],
            [75.040854000000081, 32.042799000000059],
            [75.039087000000052, 32.042977000000064],
            [75.037662000000068, 32.044057000000066],
            [75.037613035000049, 32.044079895000039],
            [75.036922000000061, 32.044403000000045],
            [75.035569000000066, 32.046379000000059],
            [75.035272000000077, 32.047621000000049],
            [75.03398500000003, 32.049274000000025],
            [75.034399000000064, 32.049424000000045],
            [75.034011000000078, 32.050736000000029],
            [75.033538000000078, 32.050734000000034],
            [75.032542000000035, 32.051239000000066],
            [75.031389000000047, 32.050665000000038],
            [75.03009000000003, 32.049940000000049],
            [75.028766000000076, 32.049493000000041],
            [75.027445000000057, 32.049045000000035],
            [75.025456000000077, 32.048329000000024],
            [75.023798000000056, 32.047661000000062],
            [75.021717000000081, 32.048642000000029],
            [75.021036000000038, 32.048170000000027],
            [75.018875000000037, 32.046980000000076],
            [75.01752300000004, 32.043912000000034],
            [75.018572000000063, 32.043088000000068],
            [75.017961000000071, 32.041929000000039],
            [75.016732000000047, 32.042921000000035],
            [75.014722263000067, 32.039641665000033],
            [75.01236300000005, 32.035792000000072],
            [75.006053000000065, 32.037428000000034],
            [75.004610000000071, 32.037476000000026],
            [75.003712000000064, 32.039839000000029],
            [75.002967000000069, 32.04371100000003],
            [75.002104000000031, 32.042349000000058],
            [75.000582000000065, 32.043457000000046],
            [74.995261396000046, 32.04719730000005],
            [74.993952687000046, 32.048117301000048],
            [74.993377000000066, 32.048522000000048],
            [74.989389000000074, 32.047773000000063],
            [74.986166000000082, 32.047247000000027],
            [74.984636353000042, 32.046972015000051],
            [74.981727000000035, 32.046449000000052],
            [74.979094000000032, 32.045821000000046],
            [74.978308000000084, 32.045461000000046],
            [74.97763400000008, 32.045056000000045],
            [74.976966000000061, 32.044770000000028],
            [74.976102000000083, 32.044312000000048],
            [74.97444500000006, 32.043316000000061],
            [74.973884000000055, 32.043912000000034],
            [74.978495000000066, 32.04727500000007],
            [74.978245685000047, 32.047614018000047],
            [74.974107711000045, 32.053240821000031],
            [74.973973000000058, 32.053424000000064],
            [74.971507000000031, 32.054207000000076],
            [74.968820000000051, 32.055048000000056],
            [74.967593677000082, 32.055436280000038],
            [74.964771000000042, 32.05633000000006],
            [74.957057000000077, 32.058883000000037],
            [74.953676536000046, 32.059963173000028],
            [74.95302300000003, 32.06017200000008],
            [74.951983000000041, 32.058321000000035],
            [74.948343000000079, 32.059388000000069],
            [74.949436000000048, 32.062224000000072],
            [74.94810700000005, 32.063085000000058],
            [74.947990000000061, 32.062963000000025],
            [74.947701000000052, 32.063135000000045],
            [74.947504000000038, 32.062744000000066],
            [74.946644000000049, 32.063114000000041],
            [74.94495100000006, 32.063682000000028],
            [74.943850000000054, 32.063808000000051],
            [74.94286900000003, 32.063933000000077],
            [74.941291000000035, 32.064036000000044],
            [74.940302000000031, 32.064014000000043],
            [74.939881487000036, 32.064033636000033],
            [74.939167000000054, 32.06406700000008],
            [74.93734100000006, 32.064638000000059],
            [74.934455000000071, 32.065644000000077],
            [74.93363400000004, 32.065622000000076],
            [74.932692000000031, 32.065697000000057],
            [74.931346000000076, 32.065852000000064],
            [74.931075153000052, 32.065897785000061],
            [74.927418000000046, 32.066516000000036],
            [74.926889000000074, 32.066543000000024],
            [74.925624000000084, 32.066597000000058],
            [74.924708000000066, 32.066246000000035],
            [74.92382200000003, 32.065496000000053],
            [74.92399800000004, 32.065345000000036],
            [74.924025000000029, 32.065045000000055],
            [74.923819000000037, 32.064921000000027],
            [74.923231000000044, 32.064573000000053],
            [74.922615000000064, 32.064301000000057],
            [74.921882000000039, 32.063953000000026],
            [74.920389000000057, 32.063329000000067],
            [74.919971000000032, 32.06300200000004],
            [74.919374000000062, 32.062700000000063],
            [74.918625000000077, 32.062293000000068],
            [74.917732642000033, 32.059622023000031],
            [74.917518869000048, 32.058982164000042],
            [74.917454000000077, 32.05878800000005],
            [74.917704000000072, 32.058067000000051],
            [74.917146000000059, 32.057746000000066],
            [74.916453000000047, 32.058244000000059],
            [74.913424000000077, 32.05687800000004],
            [74.912366000000077, 32.056017000000054],
            [74.912763000000041, 32.053876000000059],
            [74.911699449000082, 32.053201068000078],
            [74.90976900000004, 32.051976000000025],
            [74.908482000000049, 32.051583000000051],
            [74.904666000000077, 32.050352000000032],
            [74.903352928000061, 32.050633020000078],
            [74.901741000000072, 32.050978000000043],
            [74.898543000000075, 32.051581000000056],
            [74.898078000000055, 32.051757000000066],
            [74.897641000000078, 32.051956000000075],
            [74.896796000000052, 32.052504000000056],
            [74.895731000000069, 32.053211000000033],
            [74.894682000000046, 32.053964000000065],
            [74.893719000000033, 32.054546000000073],
            [74.892633000000046, 32.054851000000042],
            [74.89193743800007, 32.05495551100006],
            [74.891455000000064, 32.05502800000005],
            [74.891365000000064, 32.054676000000029],
            [74.890570000000082, 32.054854000000034],
            [74.889717000000076, 32.055183000000056],
            [74.888949000000082, 32.054030000000068],
            [74.888597000000061, 32.052726000000064],
            [74.887658000000044, 32.052878000000078],
            [74.886456000000067, 32.052855000000079],
            [74.885812000000044, 32.052959000000044],
            [74.884698000000071, 32.053061000000071],
            [74.883902000000035, 32.053037000000074],
            [74.883193000000063, 32.05298700000003],
            [74.882520000000056, 32.053064000000063],
            [74.88158100000004, 32.053140000000042],
            [74.879396000000042, 32.053800000000024],
            [74.877935000000036, 32.052177000000029],
            [74.876843000000065, 32.051432000000034],
            [74.875345000000038, 32.050814000000059],
            [74.874775000000056, 32.04820200000006],
            [74.873315000000048, 32.045281000000045],
            [74.871910000000071, 32.042392000000063],
            [74.873677000000043, 32.038994000000059],
            [74.874024157000065, 32.038354740000045],
            [74.876275000000078, 32.03421000000003],
            [74.876866000000064, 32.03421000000003],
            [74.879841000000056, 32.03197300000005],
            [74.878012000000069, 32.030359000000033],
            [74.870944000000065, 32.024057000000028],
            [74.868656000000044, 32.021982000000037],
            [74.865782299000045, 32.022015128000078],
            [74.86084900000003, 32.022072000000037],
            [74.858784000000071, 32.021535000000029],
            [74.84823300000005, 32.020546000000024],
            [74.852574215000061, 32.013925151000024],
            [74.85305100000005, 32.013198000000045],
            [74.845091000000082, 32.011325000000056],
            [74.840147487000081, 32.009312689000069],
            [74.835687000000064, 32.007497000000058],
            [74.828736000000049, 31.998358000000053],
            [74.829281000000037, 31.994550000000061],
            [74.830650000000048, 31.984572000000071],
            [74.831214000000045, 31.980805000000032],
            [74.830802000000062, 31.980071000000066],
            [74.830034000000069, 31.978834000000063],
            [74.829315000000065, 31.977689000000055],
            [74.828601000000049, 31.976283000000024],
            [74.827767000000051, 31.974640000000079],
            [74.826573000000053, 31.972276000000079],
            [74.825744000000043, 31.97064800000004],
            [74.825379000000055, 31.970155000000034],
            [74.824127000000033, 31.96859100000006],
            [74.823354000000052, 31.967458000000079],
            [74.822636000000045, 31.966487000000029],
            [74.821997000000067, 31.965224000000035],
            [74.821424000000036, 31.964222000000063],
            [74.818561000000045, 31.958913000000052],
            [74.817621810000048, 31.958914114000038],
            [74.804229000000078, 31.958930000000066],
            [74.803562000000056, 31.958568000000071],
            [74.802130000000034, 31.957968000000051],
            [74.801187000000084, 31.957990000000052],
            [74.800302000000045, 31.957969000000048],
            [74.798865000000035, 31.957861000000037],
            [74.798195000000078, 31.957680000000039],
            [74.794557000000054, 31.95830200000006],
            [74.793205000000057, 31.957691000000068],
            [74.792716000000041, 31.957485000000077],
            [74.792065000000036, 31.957279000000028],
            [74.791393000000085, 31.95714300000003],
            [74.790802000000042, 31.957075000000032],
            [74.790792313000054, 31.957074146000025],
            [74.789781000000062, 31.956985000000032],
            [74.789189000000079, 31.956803000000036],
            [74.788302000000044, 31.956576000000041],
            [74.787416000000064, 31.956440000000043],
            [74.785808000000031, 31.956305000000043],
            [74.784549000000084, 31.95621600000004],
            [74.782942000000048, 31.956015000000036],
            [74.78165800000005, 31.955878000000041],
            [74.780216000000053, 31.955061000000057],
            [74.776817000000051, 31.953100000000063],
            [74.774320000000046, 31.951698000000079],
            [74.774049000000048, 31.951515000000029],
            [74.77326800000003, 31.950946000000044],
            [74.772379000000058, 31.950354000000061],
            [74.771518000000071, 31.949740000000077],
            [74.770715000000052, 31.949152000000026],
            [74.770195607000062, 31.948815855000078],
            [74.769808000000069, 31.948565000000031],
            [74.768530000000055, 31.947733000000028],
            [74.768211000000065, 31.948004000000026],
            [74.76700900000003, 31.948976000000073],
            [74.765614000000085, 31.950112000000047],
            [74.76426900000007, 31.951339000000075],
            [74.762803000000076, 31.952146000000027],
            [74.762222000000065, 31.952635000000043],
            [74.761294000000078, 31.953290000000038],
            [74.760060000000067, 31.954173000000026],
            [74.758660000000077, 31.954830000000072],
            [74.758064000000047, 31.954717000000073],
            [74.75730800000008, 31.955141000000026],
            [74.756439000000057, 31.954932000000042],
            [74.755461000000082, 31.954532000000029],
            [74.755804000000069, 31.952346000000034],
            [74.756115000000079, 31.949518000000069],
            [74.756471000000033, 31.946139000000073],
            [74.75638500000008, 31.944949000000065],
            [74.756399000000044, 31.943692000000055],
            [74.755248000000051, 31.944224000000077],
            [74.753810000000044, 31.942920000000072],
            [74.753004000000033, 31.942761000000075],
            [74.751662000000067, 31.942490000000078],
            [74.749922000000083, 31.942084000000079],
            [74.749386000000072, 31.941903000000025],
            [74.747810000000072, 31.941384000000028],
            [74.746447000000046, 31.940956000000028],
            [74.741359000000045, 31.940985000000069],
            [74.740966000000071, 31.941302000000064],
            [74.740383000000065, 31.94157400000006],
            [74.740010000000041, 31.941800000000057],
            [74.739167681000083, 31.94233910500003],
            [74.738446000000067, 31.942801000000031],
            [74.734387000000083, 31.945391000000029],
            [74.733312000000069, 31.94495500000005],
            [74.732910000000061, 31.944269000000077],
            [74.730942000000084, 31.941131000000041],
            [74.727285000000052, 31.942783000000077],
            [74.726507050000066, 31.942234590000055],
            [74.725970000000075, 31.94185600000003],
            [74.72574886700005, 31.941895678000037],
            [74.724142000000029, 31.942184000000054],
            [74.723635000000058, 31.942605000000071],
            [74.722641000000067, 31.942912000000035],
            [74.72226100000006, 31.942912000000035],
            [74.721852000000069, 31.942748000000051],
            [74.720627000000036, 31.941908000000069],
            [74.71984800000007, 31.941378000000043],
            [74.720193000000052, 31.941075000000069],
            [74.719949000000042, 31.940731000000028],
            [74.72013400000003, 31.940477000000044],
            [74.71859100000006, 31.939375000000041],
            [74.71858700000007, 31.938984000000062],
            [74.71823500000005, 31.938709000000074],
            [74.718151000000034, 31.938411000000031],
            [74.716703000000052, 31.937706000000048],
            [74.716033000000039, 31.937207000000058],
            [74.715617553000072, 31.93678417600006],
            [74.71513200000004, 31.936290000000042],
            [74.714492000000064, 31.934528000000057],
            [74.71443400000004, 31.93410700000004],
            [74.714319000000046, 31.933243000000061],
            [74.713825000000043, 31.932242000000031],
            [74.715230000000076, 31.929753000000062],
            [74.716819000000044, 31.927134000000024],
            [74.719009000000028, 31.923878000000059],
            [74.713584000000083, 31.923083000000076],
            [74.707717000000059, 31.923082000000079],
            [74.70658700000007, 31.921206000000041],
            [74.700926000000038, 31.923489000000075],
            [74.700813000000039, 31.925862000000052],
            [74.699829000000079, 31.926156000000049],
            [74.69916500000005, 31.926359000000048],
            [74.698499000000083, 31.926405000000045],
            [74.697248000000059, 31.926631000000043],
            [74.696822000000054, 31.926654000000042],
            [74.695196000000067, 31.926723000000038],
            [74.693573000000072, 31.926747000000034],
            [74.69226800000007, 31.926770000000033],
            [74.691542467000033, 31.926748703000044],
            [74.688435000000084, 31.931173000000058],
            [74.688192556000047, 31.93146256700004],
            [74.688172570000063, 31.931486438000036],
            [74.682824452000034, 31.937874059000023],
            [74.681778515000076, 31.93912329300008],
            [74.679074530000037, 31.942352847000052],
            [74.678937548000079, 31.942516454000042],
            [74.675331000000028, 31.946824000000049],
            [74.673573519000058, 31.948330454000029],
            [74.673572895000063, 31.948330225000063],
            [74.664553984000065, 31.956060685000068],
            [74.664373690000048, 31.956215222000026],
            [74.664374502000044, 31.956215547000056],
            [74.66307906600008, 31.957325952000076],
            [74.654398301000072, 31.964766817000054],
            [74.654397043000074, 31.964766593000036],
            [74.652876509000066, 31.966069903000061],
            [74.649835395000082, 31.968676559000073],
            [74.649787280000055, 31.968717800000036],
            [74.649788249000039, 31.968718401000046],
            [74.640836340000078, 31.976391680000063],
            [74.640835254000081, 31.976390930000036],
            [74.639658000000054, 31.977400000000046],
            [74.637722428000075, 31.98049691600005],
            [74.63771765100006, 31.980504560000043],
            [74.637719247000064, 31.980505205000043],
            [74.635345138000048, 31.984303780000062],
            [74.632366926000032, 31.989068918000044],
            [74.632365201000084, 31.989068479000025],
            [74.632320981000078, 31.989139231000024],
            [74.626918000000046, 31.997784000000024],
            [74.621462644000076, 32.006110596000042],
            [74.621464161000063, 32.006111334000025],
            [74.621440187000076, 32.00614792500005],
            [74.620004000000051, 32.008340000000032],
            [74.612875192000047, 32.01529907400004],
            [74.612175945000047, 32.015981673000056],
            [74.604716000000053, 32.02326400000004],
            [74.602292629000033, 32.025323865000075],
            [74.602292095000053, 32.025322620000054],
            [74.602268930000037, 32.025342310000042],
            [74.597434000000078, 32.029452000000049],
            [74.596989678000057, 32.032395479000058],
            [74.596980632000054, 32.032455405000064],
            [74.596593725000048, 32.035018534000073],
            [74.596595684000079, 32.03501880400006],
            [74.596003260000032, 32.038943413000027],
            [74.595389376000071, 32.043010183000035],
            [74.594524000000035, 32.048743000000059],
            [74.594255758000031, 32.049607335000076],
            [74.594255747000034, 32.049607371000036],
            [74.591248000000064, 32.059299000000067],
            [74.583795000000066, 32.061003000000028],
            [74.583968000000084, 32.061119000000076],
            [74.58396891600006, 32.061118944000043],
            [74.601804000000072, 32.060027000000048],
            [74.601804046000041, 32.06002705800006],
            [74.603580628000032, 32.06227739600007],
            [74.607264000000043, 32.066943000000038],
            [74.61454400000008, 32.068763000000047],
            [74.619166196000037, 32.066573539000046],
            [74.62146000000007, 32.065487000000076],
            [74.622821656000042, 32.07311227200006],
            [74.623874877000048, 32.079010311000047],
            [74.624161036000032, 32.080612801000029],
            [74.624162765000051, 32.080622486000038],
            [74.624751164000031, 32.083917521000046],
            [74.625100000000032, 32.085871000000054],
            [74.626535749000084, 32.088639944000079],
            [74.630186792000075, 32.095681242000069],
            [74.630196000000069, 32.095699000000025],
            [74.640243146000046, 32.101281173000075],
            [74.64329900000007, 32.102979000000062],
            [74.646374084000058, 32.10651189500004],
            [74.64636316800005, 32.106539638000072],
            [74.646388215000059, 32.106528130000072],
            [74.650982070000055, 32.111805908000065],
            [74.65488124400008, 32.116285582000046],
            [74.660407000000077, 32.122634000000062],
            [74.664759383000046, 32.12514499100007],
            [74.666771164000068, 32.126305634000062],
            [74.669871000000057, 32.128094000000033],
            [74.671588967000048, 32.129283362000024],
            [74.671589340000082, 32.129282928000066],
            [74.675642915000083, 32.132089249000046],
            [74.675642951000043, 32.132089274000066],
            [74.676207795000039, 32.13248032000007],
            [74.676207289000047, 32.132480662000034],
            [74.679335000000037, 32.134646000000032],
            [74.679335748000085, 32.134645825000064],
            [74.682283312000038, 32.133958060000055],
            [74.68814517800007, 32.132590292000032],
            [74.690255000000036, 32.132098000000042],
            [74.691901500000029, 32.134043864000034],
            [74.694259000000045, 32.136830000000032],
            [74.698263000000054, 32.139742000000069],
            [74.700280294000038, 32.139943730000027],
            [74.700617805000036, 32.139977481000074],
            [74.709183000000053, 32.140834000000041],
            [74.715719559000036, 32.143285350000042],
            [74.716918238000062, 32.143734880000068],
            [74.71692044100007, 32.143735706000029],
            [74.726654000000053, 32.14738600000004],
            [74.733934000000033, 32.148478000000068],
            [74.737028251000083, 32.15015773600004],
            [74.741037340000048, 32.152334099000029],
            [74.744950445000029, 32.154458356000077],
            [74.746674000000041, 32.155394000000058],
            [74.753922523000028, 32.162392574000023],
            [74.753923112000052, 32.162392177000072],
            [74.754248802000063, 32.162706636000053],
            [74.754565250000041, 32.163012172000037],
            [74.757231000000047, 32.165586000000076],
            [74.757581021000078, 32.171186338000041],
            [74.758310646000041, 32.182860341000037],
            [74.758734008000033, 32.189634137000041],
            [74.758797687000083, 32.190653000000054],
            [74.759051000000056, 32.194706000000053],
            [74.75405932700005, 32.199999783000067],
            [74.754058816000054, 32.199999264000041],
            [74.753146185000048, 32.200967130000038],
            [74.747038000000032, 32.207445000000064],
            [74.745404649000079, 32.210493921000079],
            [74.745116447000044, 32.211031898000044],
            [74.743146620000061, 32.214708909000024],
            [74.741578000000061, 32.217637000000025],
            [74.741700421000075, 32.218319061000045],
            [74.742285286000083, 32.221577595000042],
            [74.74314748900008, 32.226381296000056],
            [74.743592908000039, 32.228862914000047],
            [74.744126000000051, 32.231833000000051],
            [74.750777188000029, 32.236900572000025],
            [74.751770000000079, 32.23765700000007],
            [74.751832124000032, 32.238014212000053],
            [74.75231790600003, 32.240807459000052],
            [74.753702715000031, 32.248770110000066],
            [74.754623486000071, 32.254064549000077],
            [74.754682000000059, 32.25440100000003],
            [74.765966000000049, 32.255857000000049],
            [74.766113403000077, 32.256372910000039],
            [74.766684246000068, 32.258370862000049],
            [74.76724667600007, 32.260339368000075],
            [74.767942216000051, 32.262773755000069],
            [74.767942300000072, 32.262774049000029],
            [74.768150000000048, 32.263501000000076],
            [74.768479709000076, 32.264028534000033],
            [74.770342795000033, 32.267009471000051],
            [74.770343034000064, 32.267009854000037],
            [74.771790000000067, 32.269325000000038],
            [74.770594814000049, 32.272739818000048],
            [74.770692096000062, 32.27271879400007],
            [74.769287109000061, 32.276672363000046],
            [74.769396881000034, 32.277922072000024],
            [74.769877519000033, 32.283393947000036],
            [74.770071303000066, 32.285600113000044],
            [74.770080566000047, 32.285705566000047],
            [74.773855687000037, 32.289928109000073],
            [74.774751244000072, 32.290901433000045],
            [74.778342000000066, 32.294804000000056],
            [74.779481435000037, 32.294433021000032],
            [74.783675075000076, 32.293067650000069],
            [74.785621976000073, 32.292433775000063],
            [74.786864819000073, 32.29202912900007],
            [74.786865003000059, 32.29202906900008],
            [74.792284820000077, 32.290264477000051],
            [74.792285725000056, 32.290264783000055],
            [74.792337305000046, 32.290247992000047],
            [74.792336980000073, 32.290247495000074],
            [74.793994000000055, 32.289708000000076],
            [74.794107260000033, 32.289681668000071],
            [74.794181322000043, 32.289656980000075],
            [74.794213445000082, 32.289656980000075],
            [74.794632634000038, 32.289559521000058],
            [74.794688009000083, 32.289546647000066],
            [74.798974743000031, 32.288550006000037],
            [74.803332830000045, 32.287536776000024],
            [74.803332985000054, 32.287536740000064],
            [74.805381096000076, 32.287060566000036],
            [74.806006000000082, 32.286915279000027],
            [74.806508639000072, 32.286798386000044],
            [74.806884766000053, 32.286682129000042],
            [74.808782736000069, 32.286269526000069],
            [74.809645000000046, 32.286069000000055],
            [74.809645473000046, 32.286069123000061],
            [74.809646000000043, 32.286069000000055],
            [74.809672981000062, 32.286075995000033],
            [74.809692383000083, 32.286071777000075],
            [74.810191766000059, 32.286210495000034],
            [74.814086914000029, 32.287220348000062],
            [74.814086950000046, 32.287220357000024],
            [74.814086899000074, 32.287220604000026],
            [74.819473000000073, 32.288617000000045],
            [74.821289892000038, 32.293960507000065],
            [74.822132471000032, 32.296438544000068],
            [74.822578295000085, 32.297749719000024],
            [74.822692835000055, 32.298086583000043],
            [74.824523317000057, 32.303470059000063],
            [74.825661000000082, 32.306816000000026],
            [74.825661898000078, 32.306815701000062],
            [74.829574723000064, 32.305511426000066],
            [74.831909216000042, 32.30473326200007],
            [74.831925043000069, 32.304727986000046],
            [74.831926069000076, 32.304727644000025],
            [74.832213000000081, 32.304632000000026],
            [74.832214000000079, 32.304632000000026],
            [74.842769000000033, 32.304632000000026],
            [74.851868614000068, 32.305281972000046],
            [74.85296100000005, 32.305360000000064],
            [74.853076934000057, 32.310577046000049],
            [74.853145925000035, 32.313681625000072],
            [74.853221458000064, 32.317080607000037],
            [74.853281548000041, 32.319784659000049],
            [74.853325000000041, 32.321740000000034],
            [74.859619414000065, 32.327404972000068],
            [74.864245000000039, 32.331568000000061],
            [74.864907774000073, 32.335213258000067],
            [74.865602032000083, 32.339031674000069],
            [74.866429000000039, 32.343580000000031],
            [74.866331737000053, 32.345622516000049],
            [74.866086275000043, 32.350777219000065],
            [74.866080845000056, 32.350891254000032],
            [74.865929436000044, 32.354070833000037],
            [74.865928416000031, 32.354071281000074],
            [74.86570096500003, 32.35884773500004],
            [74.865700000000061, 32.358868000000029],
            [74.86570096500003, 32.35886791300004],
            [74.865701004000073, 32.358867909000026],
            [74.868394066000064, 32.358623085000033],
            [74.86970400000007, 32.358504000000039],
            [74.869704087000059, 32.358504083000071],
            [74.87698400000005, 32.365420000000029],
            [74.876964185000077, 32.365667694000024],
            [74.876733690000037, 32.368548875000045],
            [74.876591394000059, 32.370327573000054],
            [74.876335847000064, 32.373521917000062],
            [74.876326697000081, 32.373636288000057],
            [74.876319497000054, 32.373726293000061],
            [74.876256000000069, 32.374520000000075],
            [74.878076000000078, 32.382891000000029],
            [74.882998092000037, 32.383506262000026],
            [74.882998167000039, 32.383506146000059],
            [74.895521454000061, 32.38507155700006],
            [74.898461000000054, 32.385439000000076],
            [74.904042872000048, 32.389904687000069],
            [74.913259106000055, 32.397277985000073],
            [74.913258971000062, 32.397278176000043],
            [74.91874856000004, 32.40166984800004],
            [74.922120000000064, 32.404367000000036],
            [74.923331449000045, 32.405757923000067],
            [74.923331333000078, 32.405757931000039],
            [74.92348839400006, 32.405938279000054],
            [74.931947000000036, 32.415651000000025],
            [74.933698469000035, 32.420905406000031],
            [74.934051952000061, 32.421965855000053],
            [74.935210752000046, 32.425442257000043],
            [74.935951000000046, 32.427663000000052],
            [74.936177733000079, 32.428238133000036],
            [74.937194551000061, 32.430817406000074],
            [74.937988980000057, 32.432832566000059],
            [74.941314297000076, 32.441267606000054],
            [74.942099170000063, 32.443258525000033],
            [74.942099184000028, 32.44325856100005],
            [74.944954144000064, 32.450500488000046],
            [74.945141284000044, 32.450968315000068],
            [74.945685027000081, 32.450270323000041],
            [74.946453487000042, 32.449241005000033],
            [74.947757982000041, 32.448544932000061],
            [74.948659349000081, 32.448115278000046],
            [74.951030654000078, 32.446977097000058],
            [74.951998765000042, 32.448381423000058],
            [74.953658717000053, 32.450806838000062],
            [74.954158218000032, 32.451538585000037],
            [74.954308225000034, 32.451748906000034],
            [74.955773684000064, 32.452997272000061],
            [74.956522948000043, 32.45331580900006],
            [74.957745673000034, 32.453932617000078],
            [74.95793649500007, 32.454095555000038],
            [74.959929112000054, 32.453559082000027],
            [74.96137077800006, 32.453131322000047],
            [74.963389345000053, 32.452576176000036],
            [74.964248116000078, 32.452339997000024],
            [74.969443721000061, 32.450911103000067],
            [74.969443817000069, 32.450911077000058],
            [74.974939729000084, 32.449439731000041],
            [74.980167562000076, 32.447961835000058],
            [74.98021071200003, 32.447949968000046],
            [74.983158102000061, 32.447139378000031],
            [74.983159426000043, 32.447157259000051],
            [74.983159430000057, 32.44715730300004],
            [74.983659554000042, 32.453912030000026],
            [74.988507000000084, 32.455415000000073],
            [74.991358153000078, 32.456240862000072],
            [74.992056000000048, 32.456443000000036],
            [74.995032000000037, 32.457266000000061],
            [74.996503598000061, 32.457876891000069],
            [74.997188000000051, 32.458161000000075],
            [74.998597000000075, 32.458574000000056],
            [75.000914538000075, 32.458623537000051],
            [75.001404000000036, 32.458634000000075],
            [75.004206000000067, 32.458624000000043],
            [75.005004878000079, 32.459669278000035],
            [75.00597300000004, 32.460936000000061],
            [75.009394000000043, 32.463311000000033],
            [75.010947000000044, 32.463238000000047],
            [75.011519000000078, 32.463578000000041],
            [75.012337000000059, 32.463917000000038],
            [75.014138000000059, 32.465417000000059],
            [75.014954000000046, 32.464592000000039],
            [75.015690000000063, 32.464316000000053],
            [75.016380275000074, 32.465033844000061],
            [75.018319000000076, 32.467050000000029],
            [75.019884000000047, 32.468349000000046],
            [75.019475000000057, 32.468626000000029],
            [75.017754000000082, 32.468977000000052],
            [75.016526000000056, 32.46994600000005],
            [75.015050000000031, 32.470849000000044],
            [75.01505460900006, 32.472763610000072],
            [75.015056000000072, 32.473341000000062],
            [75.015144000000078, 32.475493000000029],
            [75.017297000000042, 32.478204000000062],
            [75.01797300000004, 32.481422000000066],
            [75.018811000000085, 32.483456000000047],
            [75.019485000000032, 32.485286000000031],
            [75.021183000000065, 32.490734000000032],
            [75.022626000000059, 32.49400600000007],
            [75.023456000000067, 32.496437000000071],
            [75.024515000000065, 32.497798000000046],
            [75.026616000000047, 32.497573000000045],
            [75.02943700000003, 32.494196000000045],
            [75.031459000000041, 32.493118000000038],
            [75.033480000000054, 32.491899000000046],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "114",
      properties: { name: "Okara", count: 1005 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.660530390000076, 31.139174320000052],
            [73.660557431000029, 31.139107098000068],
            [73.660612000000071, 31.139122000000043],
            [73.660612093000054, 31.139121406000072],
            [73.660652000000084, 31.138872000000049],
            [73.663007123000057, 31.132099500000038],
            [73.663748000000055, 31.129969000000074],
            [73.664844199000072, 31.129055632000075],
            [73.67071500000003, 31.124164000000064],
            [73.67107026900004, 31.121321388000069],
            [73.671292593000032, 31.119542509000041],
            [73.671489000000065, 31.117971000000068],
            [73.672007822000069, 31.117192726000042],
            [73.677682000000061, 31.108681000000047],
            [73.679746566000063, 31.101601726000069],
            [73.680391000000043, 31.099392000000023],
            [73.681940000000054, 31.094747000000041],
            [73.681569649000039, 31.088820432000034],
            [73.681553000000065, 31.088554000000045],
            [73.676908000000083, 31.088167000000055],
            [73.673424000000068, 31.087393000000077],
            [73.671489000000065, 31.081974000000059],
            [73.665683000000058, 31.081974000000059],
            [73.667465569000058, 31.07863218600005],
            [73.668780000000083, 31.076168000000052],
            [73.668780000000083, 31.070750000000032],
            [73.668780000000083, 31.070749000000035],
            [73.665683000000058, 31.066879000000029],
            [73.668691201000058, 31.059359954000058],
            [73.668780000000083, 31.059138000000075],
            [73.674586000000033, 31.053719000000058],
            [73.676650991000031, 31.053795474000026],
            [73.685036000000082, 31.054106000000047],
            [73.689746011000068, 31.051280091000024],
            [73.692348203000051, 31.049718829000028],
            [73.694713000000036, 31.04830000000004],
            [73.710872038000048, 31.043212853000057],
            [73.715430187000038, 31.041777868000054],
            [73.715614000000073, 31.041720000000055],
            [73.719871000000069, 31.033979000000045],
            [73.722098927000047, 31.031305564000036],
            [73.725676964000058, 31.02701204300007],
            [73.725677000000076, 31.02701200000007],
            [73.727259672000059, 31.020089024000072],
            [73.728326528000082, 31.015422349000062],
            [73.728774000000044, 31.013465000000053],
            [73.731702536000057, 31.009141735000071],
            [73.736902000000043, 31.00146600000005],
            [73.742477377000057, 30.997643037000046],
            [73.75044900000006, 30.992177000000027],
            [73.760059522000063, 30.99247728000006],
            [73.762835000000052, 30.992564000000073],
            [73.76568995000008, 30.989269914000033],
            [73.767867000000081, 30.986758000000066],
            [73.767867000000081, 30.980510836000064],
            [73.767867000000081, 30.980161616000032],
            [73.767867000000081, 30.979617153000049],
            [73.767867000000081, 30.979508862000046],
            [73.767867000000081, 30.975146000000052],
            [73.767867000000081, 30.967906087000074],
            [73.767867000000081, 30.964738304000036],
            [73.767867000000081, 30.964375884000049],
            [73.767867000000081, 30.963534000000038],
            [73.766706000000056, 30.950374000000068],
            [73.764770000000055, 30.93953700000003],
            [73.765109480000035, 30.93924749100006],
            [73.76905000000005, 30.935887000000037],
            [73.770482403000074, 30.934665102000054],
            [73.777930000000083, 30.928312000000062],
            [73.784943394000038, 30.925884072000031],
            [73.787994000000083, 30.924828000000048],
            [73.822055000000034, 30.932183000000066],
            [73.824071443000037, 30.928567410000028],
            [73.824144785000044, 30.928435909000029],
            [73.827443163000055, 30.922521748000065],
            [73.827443010000081, 30.922521161000077],
            [73.833279000000061, 30.912056000000064],
            [73.846439000000032, 30.895412000000078],
            [73.848373000000038, 30.892415000000028],
            [73.853878914000063, 30.883879744000069],
            [73.854180000000042, 30.883413000000075],
            [73.853793000000053, 30.867544000000066],
            [73.857664000000057, 30.859416000000067],
            [73.869630541000049, 30.858470588000046],
            [73.872372000000041, 30.858254000000045],
            [73.881182942000066, 30.858062490000066],
            [73.881272556000056, 30.858060542000032],
            [73.884599017000085, 30.857988240000054],
            [73.890177000000051, 30.857867000000056],
            [73.890225953000083, 30.857835185000056],
            [73.896634144000075, 30.853670399000066],
            [73.897918000000061, 30.852836000000025],
            [73.904885000000036, 30.853223000000071],
            [73.913014000000032, 30.85361000000006],
            [73.921141000000034, 30.85361000000006],
            [73.921142000000032, 30.85361000000006],
            [73.926561000000049, 30.857867000000056],
            [73.926602444000082, 30.858005184000035],
            [73.927722000000074, 30.861738000000059],
            [73.930252322000058, 30.861738000000059],
            [73.932753000000048, 30.861738000000059],
            [73.935757199000079, 30.860291523000058],
            [73.943204000000037, 30.856706000000031],
            [73.947075000000041, 30.855932000000053],
            [73.947075003000066, 30.855931931000043],
            [73.947083684000063, 30.85572356800003],
            [73.94746200000003, 30.846643000000029],
            [73.949397000000033, 30.838901000000078],
            [73.95520300000004, 30.83619200000004],
            [73.961395000000039, 30.833483000000058],
            [73.961395893000031, 30.833483047000072],
            [73.961396000000036, 30.833483000000058],
            [73.967956233000052, 30.833828231000041],
            [73.968750000000057, 30.833870000000047],
            [73.976878000000056, 30.833870000000047],
            [73.983561475000045, 30.830000237000036],
            [73.984232000000077, 30.829612000000054],
            [73.988877000000059, 30.826516000000026],
            [73.988877000000059, 30.823466798000027],
            [73.988877000000059, 30.821484000000055],
            [73.990812000000062, 30.81800000000004],
            [73.993909000000031, 30.823419000000058],
            [74.000102000000084, 30.824193000000037],
            [74.002531987000054, 30.828142513000046],
            [74.003198000000054, 30.829225000000065],
            [74.007843000000037, 30.831160000000068],
            [74.01480893400003, 30.831546941000056],
            [74.014809000000071, 30.831547000000057],
            [74.014809029000048, 30.83154694600006],
            [74.01751900000005, 30.826516000000026],
            [74.01751900000005, 30.821761957000035],
            [74.01751900000005, 30.821097000000066],
            [74.016358000000082, 30.816065000000037],
            [74.016358000000082, 30.816057599000032],
            [74.016358000000082, 30.810646000000077],
            [74.026848088000065, 30.805026379000026],
            [74.027042379000079, 30.804922296000029],
            [74.02719600000006, 30.80484000000007],
            [74.032227000000034, 30.79942200000005],
            [74.043452000000059, 30.79709900000006],
            [74.05622500000004, 30.796325000000024],
            [74.068761677000055, 30.794584045000079],
            [74.068826917000081, 30.794574985000054],
            [74.070159000000046, 30.794390000000078],
            [74.074034912000059, 30.788427474000059],
            [74.075191000000075, 30.786649000000068],
            [74.075965569000061, 30.784034328000075],
            [74.078287000000046, 30.776198000000079],
            [74.079066261000037, 30.77152495100006],
            [74.079836000000057, 30.766909000000055],
            [74.077900000000056, 30.759167000000048],
            [74.077183996000031, 30.758144138000034],
            [74.076869976000069, 30.757695536000028],
            [74.075191000000075, 30.755297000000041],
            [74.077283872000066, 30.753669144000071],
            [74.082158000000049, 30.749878000000024],
            [74.090673000000038, 30.744459000000063],
            [74.094217579000031, 30.741251958000078],
            [74.098801000000037, 30.737105000000042],
            [74.103339417000029, 30.730946019000044],
            [74.104220000000055, 30.729751000000078],
            [74.110700737000059, 30.724813106000056],
            [74.112348000000054, 30.723558000000025],
            [74.118928000000039, 30.719687000000079],
            [74.119702000000075, 30.711172000000033],
            [74.119772089000037, 30.710971793000056],
            [74.119773016000067, 30.710971928000049],
            [74.119922275000079, 30.710545419000027],
            [74.122077672000046, 30.704386346000035],
            [74.122412000000054, 30.703431000000023],
            [74.122412000000054, 30.699690402000044],
            [74.122412000000054, 30.693439865000073],
            [74.122412000000054, 30.693368000000078],
            [74.122412000000054, 30.693367000000023],
            [74.132475000000056, 30.691432000000077],
            [74.13580709200005, 30.687148057000059],
            [74.135811765000028, 30.687142049000045],
            [74.137894000000074, 30.684465000000046],
            [74.138061676000063, 30.683760676000077],
            [74.139308007000068, 30.678525441000033],
            [74.139829000000077, 30.676337000000046],
            [74.146409000000062, 30.668983000000026],
            [74.152215000000069, 30.665499000000068],
            [74.156091764000053, 30.660846983000056],
            [74.159956000000079, 30.656210000000044],
            [74.162772345000064, 30.653187200000048],
            [74.165133481000055, 30.650652978000039],
            [74.165133515000036, 30.650652942000079],
            [74.166536733000044, 30.64914729000003],
            [74.16644000000008, 30.649159000000054],
            [74.162952000000075, 30.64844800000003],
            [74.163228000000061, 30.646006000000057],
            [74.160162000000071, 30.642400000000066],
            [74.158565000000067, 30.640663000000075],
            [74.156026000000054, 30.640538000000049],
            [74.155371000000059, 30.638687000000061],
            [74.153785000000084, 30.636378000000036],
            [74.153393000000051, 30.635341000000039],
            [74.152468000000056, 30.634303000000045],
            [74.151807000000076, 30.633726000000024],
            [74.150351000000057, 30.632687000000033],
            [74.148634000000072, 30.631305000000054],
            [74.142536000000064, 30.629223000000025],
            [74.13949000000008, 30.628412000000026],
            [74.137645000000077, 30.627377000000024],
            [74.127402000000075, 30.634899000000075],
            [74.128390000000081, 30.632147000000032],
            [74.126404000000036, 30.623998000000029],
            [74.121136000000035, 30.622439000000043],
            [74.117724000000067, 30.607976000000065],
            [74.104955000000075, 30.619070000000079],
            [74.095510000000047, 30.611275000000035],
            [74.102104000000054, 30.610951000000057],
            [74.102706000000069, 30.606516000000056],
            [74.101145000000031, 30.603136000000063],
            [74.095285000000047, 30.601139000000046],
            [74.09827000000007, 30.590419000000054],
            [74.097883122000042, 30.589796672000034],
            [74.096198000000072, 30.587086000000056],
            [74.098112000000071, 30.584007000000042],
            [74.102032000000065, 30.579352000000029],
            [74.091597000000036, 30.570477000000039],
            [74.073441000000059, 30.554507000000058],
            [74.079760000000078, 30.548220000000072],
            [74.086001000000067, 30.549350000000061],
            [74.090836000000081, 30.549370000000067],
            [74.078653000000031, 30.531116000000054],
            [74.071753000000058, 30.522698000000048],
            [74.069930000000056, 30.523375000000044],
            [74.066137000000083, 30.519968000000063],
            [74.05686600000007, 30.518274000000076],
            [74.054734000000053, 30.520862000000079],
            [74.053794946000039, 30.522866347000047],
            [74.051275000000032, 30.528245000000027],
            [74.050395000000037, 30.535569000000066],
            [74.049846751000075, 30.536430121000024],
            [74.047933000000057, 30.539436000000023],
            [74.039195000000063, 30.543125000000032],
            [74.037391000000071, 30.532370000000071],
            [74.036816061000081, 30.531922503000033],
            [74.029460000000029, 30.526197000000025],
            [74.022469000000058, 30.526671000000078],
            [74.011627000000033, 30.533898000000079],
            [74.011488064000048, 30.531444280000073],
            [74.01100856100004, 30.522975896000048],
            [74.010837000000038, 30.519946000000061],
            [74.019495000000063, 30.513720000000035],
            [74.001447000000042, 30.514124000000038],
            [73.999694265000073, 30.514146646000029],
            [73.992469000000028, 30.514240000000029],
            [73.98876984900005, 30.510133276000033],
            [73.982921000000033, 30.503640000000075],
            [73.978451589000031, 30.497978314000079],
            [73.976016000000072, 30.494893000000047],
            [73.965822178000053, 30.486547770000072],
            [73.963162000000068, 30.484370000000069],
            [73.966729029000078, 30.481338150000056],
            [73.968914000000041, 30.479481000000078],
            [73.970283000000052, 30.475958000000048],
            [73.964290000000062, 30.460525000000075],
            [73.964163585000051, 30.46010306900007],
            [73.963061000000039, 30.456423000000029],
            [73.954263000000083, 30.457887000000028],
            [73.949349252000047, 30.460766035000063],
            [73.949194000000034, 30.460857000000033],
            [73.945994000000042, 30.464738000000068],
            [73.946065464000071, 30.465404013000068],
            [73.947014000000081, 30.474244000000056],
            [73.948392000000069, 30.483425000000068],
            [73.939239906000068, 30.487935467000057],
            [73.934227000000078, 30.490406000000064],
            [73.932625976000054, 30.484316489000037],
            [73.931321000000082, 30.47935300000006],
            [73.931842000000074, 30.472037000000057],
            [73.931268000000046, 30.465062000000046],
            [73.92900300000008, 30.463206000000071],
            [73.932552853000061, 30.457897689000049],
            [73.936632777000057, 30.451796731000059],
            [73.936763000000042, 30.451602000000037],
            [73.937330000000031, 30.445873000000063],
            [73.926826000000062, 30.439434000000062],
            [73.935984767000036, 30.436163909000072],
            [73.936301000000071, 30.436051000000077],
            [73.937839000000054, 30.42501100000004],
            [73.916497000000049, 30.425794000000053],
            [73.914828811000064, 30.42223230500008],
            [73.91295800000006, 30.418238000000031],
            [73.90920200000005, 30.415775000000053],
            [73.907250000000033, 30.412003000000027],
            [73.906367260000081, 30.408147240000062],
            [73.904717000000062, 30.400939000000051],
            [73.899804000000074, 30.396599000000037],
            [73.893107000000043, 30.394203000000061],
            [73.891098895000084, 30.393621564000057],
            [73.884939000000031, 30.391838000000064],
            [73.880074000000036, 30.38908500000008],
            [73.87123047700004, 30.385166283000046],
            [73.867768000000069, 30.383632000000034],
            [73.87300300000004, 30.373673000000053],
            [73.86799300000007, 30.365840000000048],
            [73.870224206000046, 30.364763233000076],
            [73.90174600000006, 30.349551000000076],
            [73.909455353000055, 30.34105911100005],
            [73.90945557200007, 30.34105887000004],
            [73.909455791000084, 30.341058629000031],
            [73.904268669000032, 30.33660373400005],
            [73.896250000000066, 30.329717000000073],
            [73.887709000000029, 30.324023000000068],
            [73.88570575500006, 30.322687440000038],
            [73.885612789000049, 30.322625459000051],
            [73.885165751000045, 30.322327419000032],
            [73.877271000000064, 30.317064000000073],
            [73.869680000000074, 30.313585000000046],
            [73.86367000000007, 30.308524000000034],
            [73.859874000000048, 30.302198000000033],
            [73.854497000000038, 30.298086000000069],
            [73.854496712000071, 30.298086544000057],
            [73.85449600000004, 30.298086000000069],
            [73.851650000000063, 30.303463000000079],
            [73.848697955000034, 30.306007944000044],
            [73.842718937000029, 30.311162427000056],
            [73.842477000000031, 30.311371000000065],
            [73.836467000000084, 30.315799000000027],
            [73.834714527000074, 30.316266359000053],
            [73.830826063000075, 30.317303356000025],
            [73.830572130000064, 30.317371076000029],
            [73.823320503000048, 30.319304979000037],
            [73.82223300000004, 30.319595000000049],
            [73.80831500000005, 30.32370700000007],
            [73.801357000000053, 30.323391000000072],
            [73.797257042000069, 30.322631589000025],
            [73.792816000000073, 30.32180900000003],
            [73.78490800000003, 30.317381000000069],
            [73.777001000000041, 30.313269000000048],
            [73.776368000000048, 30.306942000000049],
            [73.77859649100003, 30.300999749000027],
            [73.78016400000007, 30.296820000000025],
            [73.776684000000046, 30.290178000000026],
            [73.770991000000038, 30.285750000000064],
            [73.770630119000032, 30.284547001000078],
            [73.769093000000055, 30.279423000000065],
            [73.760869000000071, 30.278791000000069],
            [73.759488946000033, 30.277755850000062],
            [73.754543000000069, 30.274046000000055],
            [73.746951000000081, 30.26835200000005],
            [73.738150093000058, 30.266364727000052],
            [73.737146000000052, 30.266138000000069],
            [73.73714534700008, 30.26613807800004],
            [73.736262760000045, 30.266243993000046],
            [73.729238000000066, 30.26708700000006],
            [73.720065000000034, 30.272465000000068],
            [73.71958483700007, 30.27241009200003],
            [73.708994000000075, 30.271199000000024],
            [73.696974000000068, 30.268669000000045],
            [73.69410034100008, 30.268381604000069],
            [73.687485000000038, 30.267720000000054],
            [73.685275656000044, 30.268889580000064],
            [73.68210700000003, 30.270567000000028],
            [73.68082482300008, 30.27462700500007],
            [73.680825402000039, 30.274628336000035],
            [73.680210000000045, 30.276577000000032],
            [73.672935000000052, 30.277842000000078],
            [73.672934358000077, 30.277841938000051],
            [73.663129000000083, 30.27689300000003],
            [73.660042402000045, 30.273644111000067],
            [73.65711900000008, 30.270567000000028],
            [73.655421878000084, 30.269435704000045],
            [73.652374000000066, 30.267404000000056],
            [73.65095675200007, 30.264994275000049],
            [73.649211000000037, 30.262026000000048],
            [73.642569000000037, 30.255067000000054],
            [73.639415052000061, 30.253490337000073],
            [73.637508000000082, 30.252537000000075],
            [73.636800467000057, 30.254777398000044],
            [73.635610000000042, 30.258547000000078],
            [73.636559000000034, 30.262659000000042],
            [73.63686612500004, 30.264092034000043],
            [73.637508000000082, 30.26708700000006],
            [73.638140000000078, 30.270567000000028],
            [73.636875000000032, 30.276260000000036],
            [73.630583000000058, 30.277659000000028],
            [73.630580773000077, 30.277725877000023],
            [73.630070000000046, 30.29306500000007],
            [73.630070000000046, 30.305195000000026],
            [73.630028895000066, 30.306303520000029],
            [73.629985463000082, 30.307474786000057],
            [73.629630481000049, 30.317047872000046],
            [73.629542000000072, 30.319434000000058],
            [73.629542000000072, 30.327841766000063],
            [73.629542000000072, 30.327843844000029],
            [73.629542000000072, 30.332619000000079],
            [73.629542000000072, 30.346858000000054],
            [73.629542000000072, 30.351841020000052],
            [73.629542000000072, 30.355070501000057],
            [73.629542000000072, 30.359512442000039],
            [73.629542000000072, 30.359515000000044],
            [73.636398000000042, 30.363734000000079],
            [73.64641800000004, 30.365317000000061],
            [73.646750771000029, 30.367091919000075],
            [73.648000000000081, 30.373755000000074],
            [73.64709802200008, 30.377062444000046],
            [73.64641800000004, 30.379556000000036],
            [73.641145000000051, 30.385357000000056],
            [73.63928594500004, 30.387046875000067],
            [73.635343000000034, 30.390631000000042],
            [73.635340115000076, 30.39066420000006],
            [73.635314941000047, 30.390686035000044],
            [73.634288561000062, 30.402761100000077],
            [73.634277344000054, 30.402893066000047],
            [73.622680664000029, 30.405517578000058],
            [73.61749267600004, 30.405517578000058],
            [73.613792818000036, 30.405687037000064],
            [73.613844121000056, 30.405635708000034],
            [73.611491949000083, 30.405792421000058],
            [73.609502000000077, 30.405925000000025],
            [73.609068883000077, 30.404698039000039],
            [73.606337000000053, 30.396959000000038],
            [73.600878609000063, 30.394775540000069],
            [73.595790000000079, 30.39274000000006],
            [73.585649846000081, 30.395782623000059],
            [73.585329435000062, 30.395878765000077],
            [73.585242000000051, 30.395905000000027],
            [73.58616885400005, 30.397758708000026],
            [73.588064468000084, 30.401549936000038],
            [73.589461000000028, 30.40434300000004],
            [73.593680000000063, 30.415418000000045],
            [73.595504320000032, 30.416390924000041],
            [73.601040061000049, 30.419343180000055],
            [73.601591000000042, 30.419637000000023],
            [73.604571915000065, 30.425930462000053],
            [73.605046619000063, 30.426932681000039],
            [73.60632324200003, 30.429687500000057],
            [73.610872138000047, 30.431100052000033],
            [73.617253327000071, 30.433130505000065],
            [73.617940000000033, 30.433349000000078],
            [73.622009104000028, 30.433719014000076],
            [73.629054981000081, 30.434359714000038],
            [73.629542000000072, 30.434404000000029],
            [73.635343000000034, 30.443369000000075],
            [73.635221406000085, 30.443956755000045],
            [73.632179000000065, 30.458663000000058],
            [73.634357424000029, 30.462148313000057],
            [73.63745300000005, 30.467101000000071],
            [73.637525960000062, 30.467417189000059],
            [73.639029979000043, 30.473935238000024],
            [73.639035000000035, 30.473957000000041],
            [73.638256815000034, 30.474586996000028],
            [73.63171386700003, 30.480102539000029],
            [73.628482420000068, 30.482500064000078],
            [73.62796000000003, 30.482923000000028],
            [73.627943840000057, 30.48289965500004],
            [73.626866240000084, 30.481342971000061],
            [73.623279639000032, 30.476161821000062],
            [73.623214000000075, 30.476067000000057],
            [73.622751261000076, 30.480230333000065],
            [73.622565551000037, 30.481901193000056],
            [73.622252893000052, 30.484714231000055],
            [73.622161750000032, 30.485534260000065],
            [73.622159000000067, 30.485559000000023],
            [73.633220100000074, 30.49293373200004],
            [73.633234000000073, 30.492943000000025],
            [73.632560412000032, 30.493046652000032],
            [73.626378000000045, 30.493998000000033],
            [73.618995000000041, 30.495580000000075],
            [73.61480179800003, 30.493343731000039],
            [73.611083984000061, 30.491271973000039],
            [73.609481753000068, 30.492028582000046],
            [73.608886719000054, 30.492309570000032],
            [73.608866196000065, 30.492285056000071],
            [73.604755000000068, 30.493998000000033],
            [73.597115673000076, 30.494447071000025],
            [73.595886951000068, 30.494519301000025],
            [73.595790000000079, 30.494525000000067],
            [73.595896925000034, 30.496022967000044],
            [73.596303268000042, 30.501715627000067],
            [73.596317000000056, 30.501908000000071],
            [73.595981962000053, 30.50228031100005],
            [73.591571000000044, 30.507182000000057],
            [73.585770000000082, 30.510346000000027],
            [73.575222000000053, 30.512983000000077],
            [73.569521014000031, 30.51583379300007],
            [73.567936166000038, 30.516626300000041],
            [73.565729000000033, 30.517730000000029],
            [73.564864615000033, 30.517424838000068],
            [73.560812184000042, 30.515994169000066],
            [73.560834011000054, 30.516053313000043],
            [73.556884766000053, 30.514709473000039],
            [73.551127102000066, 30.512907755000072],
            [73.547912598000039, 30.511901855000076],
            [73.539489746000072, 30.511901855000076],
            [73.531677246000072, 30.512695312000062],
            [73.523681641000053, 30.513488770000038],
            [73.523661724000078, 30.513498728000059],
            [73.523539000000085, 30.513511000000051],
            [73.519651433000035, 30.515454616000056],
            [73.511936000000048, 30.51931200000007],
            [73.508851186000072, 30.525096301000076],
            [73.507717000000071, 30.527223000000049],
            [73.507688206000068, 30.527246392000052],
            [73.499279000000058, 30.534078000000079],
            [73.496425534000082, 30.534893497000041],
            [73.491896000000054, 30.536188000000038],
            [73.491889706000052, 30.536313740000026],
            [73.491875920000041, 30.536589139000057],
            [73.491511196000033, 30.543875344000071],
            [73.491507038000066, 30.543958412000052],
            [73.49136800000008, 30.546736000000067],
            [73.486095000000034, 30.55042700000007],
            [73.482450430000029, 30.549906770000064],
            [73.478515625000057, 30.547485352000024],
            [73.475562643000046, 30.545719982000037],
            [73.47291000000007, 30.544099000000074],
            [73.469218000000069, 30.552537000000029],
            [73.469654232000039, 30.553347078000058],
            [73.470445362000078, 30.554848884000023],
            [73.472900391000053, 30.559509277000075],
            [73.474487305000082, 30.568481445000032],
            [73.475280762000068, 30.569702148000033],
            [73.478916986000058, 30.575615604000063],
            [73.48132324200003, 30.579528809000067],
            [73.490295410000044, 30.587890625000057],
            [73.489922956000044, 30.589767222000035],
            [73.489822816000071, 30.590271772000051],
            [73.488727658000073, 30.595789682000031],
            [73.482640829000047, 30.606558823000057],
            [73.481876000000057, 30.607912000000056],
            [73.481728002000068, 30.607976738000048],
            [73.481651192000072, 30.608010337000053],
            [73.473438000000044, 30.611603000000059],
            [73.47047062300004, 30.609230791000073],
            [73.46993408000003, 30.608796006000034],
            [73.462890625000057, 30.603088379000042],
            [73.462890625000057, 30.601684570000032],
            [73.465643168000042, 30.594105103000061],
            [73.46710205100004, 30.590087891000053],
            [73.467063660000065, 30.590105682000058],
            [73.46710900000005, 30.589981000000023],
            [73.463075314000037, 30.591891566000072],
            [73.463057860000049, 30.591962028000069],
            [73.457092285000044, 30.594726562000062],
            [73.451293945000032, 30.595886230000076],
            [73.451302097000053, 30.595779436000043],
            [73.451288000000034, 30.595782000000042],
            [73.451815000000067, 30.587871000000064],
            [73.452142259000084, 30.586889535000068],
            [73.452864629000032, 30.584723109000038],
            [73.452870000000075, 30.584707000000037],
            [73.446297066000056, 30.587171655000077],
            [73.440263662000064, 30.589434003000065],
            [73.43389892600004, 30.583129883000026],
            [73.43389892600004, 30.575317383000026],
            [73.433884000000035, 30.575281651000068],
            [73.433884000000035, 30.575214000000074],
            [73.433517684000037, 30.57438965800003],
            [73.433412836000059, 30.574153713000044],
            [73.431861463000075, 30.57066257300005],
            [73.431775000000073, 30.570468000000062],
            [73.430185908000055, 30.569541009000034],
            [73.425446000000079, 30.566776000000061],
            [73.422302246000072, 30.565204123000058],
            [73.422302246000072, 30.559509277000075],
            [73.421325684000067, 30.551513672000056],
            [73.424083633000066, 30.547540355000024],
            [73.424926758000083, 30.546325684000067],
            [73.424833483000043, 30.546330161000071],
            [73.424919000000045, 30.546208000000036],
            [73.417349867000041, 30.546586887000046],
            [73.414489746000072, 30.546730056000058],
            [73.414371000000074, 30.546736000000067],
            [73.414371000000074, 30.545639860000051],
            [73.414371000000074, 30.541462000000024],
            [73.410778021000056, 30.535816586000067],
            [73.410705827000072, 30.535703152000053],
            [73.41106534100004, 30.532377653000026],
            [73.411734000000081, 30.526695000000075],
            [73.411676750000083, 30.52672212300007],
            [73.411682129000042, 30.526672363000046],
            [73.405747272000042, 30.529531227000064],
            [73.401714000000084, 30.531442000000027],
            [73.395913000000064, 30.526168000000041],
            [73.396087464000061, 30.523723518000054],
            [73.396484375000057, 30.518920898000033],
            [73.399299709000047, 30.515044714000055],
            [73.40069580100004, 30.513122559000067],
            [73.400619784000071, 30.513036920000047],
            [73.400659000000076, 30.512983000000077],
            [73.395913000000064, 30.507709000000034],
            [73.395893406000084, 30.507712266000055],
            [73.395073840000066, 30.507848882000076],
            [73.38958400000007, 30.508764000000042],
            [73.382468627000037, 30.511926305000031],
            [73.38009100000005, 30.512983000000077],
            [73.378927890000057, 30.513481611000032],
            [73.378924998000059, 30.513634868000054],
            [73.372680664000029, 30.516296387000068],
            [73.365905762000068, 30.514892578000058],
            [73.364739406000069, 30.512835074000066],
            [73.362807018000069, 30.517962541000031],
            [73.361595171000033, 30.519292585000073],
            [73.360578000000032, 30.518784000000039],
            [73.351085000000069, 30.520367000000078],
            [73.346024015000069, 30.520826772000078],
            [73.345284000000049, 30.520894000000055],
            [73.345284000000049, 30.520873510000058],
            [73.345284000000049, 30.514038000000028],
            [73.345049483000082, 30.513381405000075],
            [73.344242881000071, 30.511123103000045],
            [73.342712402000075, 30.50671386700003],
            [73.34264990500003, 30.506663134000064],
            [73.342647000000056, 30.506655000000023],
            [73.34215762000008, 30.506263514000068],
            [73.341755403000036, 30.505937009000036],
            [73.337524414000029, 30.502502441000047],
            [73.335429882000028, 30.496992911000063],
            [73.334716797000056, 30.495117187000062],
            [73.330305217000046, 30.496141304000048],
            [73.327880859000061, 30.496704102000024],
            [73.326904297000056, 30.501525879000042],
            [73.326904297000056, 30.508300781000059],
            [73.327130452000063, 30.508510972000067],
            [73.332100000000082, 30.512983000000077],
            [73.332047341000077, 30.513080787000035],
            [73.330716688000052, 30.515551794000032],
            [73.328408000000081, 30.519839000000047],
            [73.323357247000047, 30.521642987000064],
            [73.321025000000077, 30.52247600000004],
            [73.314696000000083, 30.52827700000006],
            [73.309452960000044, 30.520413435000023],
            [73.30942200000004, 30.520367000000078],
            [73.303312136000045, 30.516136614000061],
            [73.302661373000035, 30.515686035000044],
            [73.30256600000007, 30.515620000000069],
            [73.301718528000038, 30.515620000000069],
            [73.297820000000058, 30.515620000000069],
            [73.290135732000067, 30.518608551000057],
            [73.289979885000037, 30.518667118000053],
            [73.288330078000058, 30.519287109000061],
            [73.287852398000041, 30.520083243000045],
            [73.28410800000006, 30.526168000000041],
            [73.284120300000041, 30.526303405000078],
            [73.284126980000053, 30.526376939000045],
            [73.284727150000037, 30.531478380000067],
            [73.28912500000007, 30.530905000000075],
            [73.295896000000084, 30.530421000000047],
            [73.303150000000073, 30.533323000000053],
            [73.303150000000073, 30.53670900000003],
            [73.299281000000065, 30.540578000000039],
            [73.294928324000068, 30.542995820000044],
            [73.287674000000038, 30.541062000000068],
            [73.288641000000041, 30.544931000000076],
            [73.293871849000084, 30.548068788000023],
            [73.295896000000084, 30.549283000000059],
            [73.303150000000073, 30.55025100000006],
            [73.309438000000057, 30.551218000000063],
            [73.315321088000076, 30.553695056000038],
            [73.318627000000049, 30.555087000000071],
            [73.323947000000032, 30.557505000000049],
            [73.327816000000041, 30.562342000000058],
            [73.334103000000084, 30.565727000000038],
            [73.334189115000072, 30.565748535000068],
            [73.343776000000048, 30.56814600000007],
            [73.347162000000083, 30.565244000000064],
            [73.349602834000052, 30.566305213000078],
            [73.349602870000069, 30.566305133000071],
            [73.358286000000078, 30.570080000000075],
            [73.361675352000077, 30.574975212000027],
            [73.361674293000078, 30.574975127000073],
            [73.362638000000061, 30.576367000000062],
            [73.358769000000052, 30.582655000000045],
            [73.355185232000053, 30.584566178000046],
            [73.351514000000066, 30.586524000000054],
            [73.347162000000083, 30.591360000000066],
            [73.348355876000085, 30.593217475000074],
            [73.351514000000066, 30.598131000000024],
            [73.360704000000055, 30.602484000000061],
            [73.368030927000063, 30.603777040000068],
            [73.368926000000044, 30.603935000000035],
            [73.372260907000054, 30.606967132000079],
            [73.372469763000083, 30.607157027000028],
            [73.374246000000085, 30.608772000000044],
            [73.376664000000062, 30.621830000000045],
            [73.377147000000036, 30.632470000000069],
            [73.375948428000072, 30.633945195000024],
            [73.37086000000005, 30.64020800000003],
            [73.370554365000032, 30.640906763000032],
            [73.370186087000036, 30.641748743000051],
            [73.36747500000007, 30.647947000000045],
            [73.367305011000042, 30.648432567000043],
            [73.367056460000072, 30.649142544000028],
            [73.364089000000035, 30.657619000000068],
            [73.36191003700003, 30.662849233000031],
            [73.361671000000058, 30.663423000000023],
            [73.35365489000003, 30.674492603000033],
            [73.351514000000066, 30.677449000000024],
            [73.347645000000057, 30.686638000000073],
            [73.348602182000036, 30.689668857000072],
            [73.350547000000063, 30.695827000000065],
            [73.355608891000031, 30.700888891000034],
            [73.357802000000049, 30.703082000000052],
            [73.36184034200005, 30.70592380800008],
            [73.362258214000065, 30.706217867000078],
            [73.37086000000005, 30.712271000000044],
            [73.370530880000047, 30.716057074000048],
            [73.370342718000074, 30.718221613000026],
            [73.369893000000047, 30.723395000000039],
            [73.369969777000051, 30.724625026000069],
            [73.370376000000078, 30.731133000000057],
            [73.370883535000075, 30.739754797000046],
            [73.370884033000038, 30.739755185000035],
            [73.371015900000032, 30.741997597000079],
            [73.371344000000079, 30.747577000000035],
            [73.37079678200007, 30.753778555000054],
            [73.370341565000047, 30.75893747300006],
            [73.369893000000047, 30.764021000000071],
            [73.362519182000028, 30.770656902000042],
            [73.360220000000083, 30.772726000000034],
            [73.347645000000057, 30.781432000000052],
            [73.341732369000056, 30.785654934000036],
            [73.340995489000079, 30.786181230000068],
            [73.340995674000055, 30.786181812000052],
            [73.34087500000004, 30.786268000000064],
            [73.340874113000041, 30.786267919000068],
            [73.340874000000042, 30.786268000000064],
            [73.330234000000075, 30.785301000000061],
            [73.32298000000003, 30.783367000000055],
            [73.322065417000033, 30.782681092000075],
            [73.315241000000071, 30.777563000000043],
            [73.309723047000034, 30.773742473000027],
            [73.308954000000028, 30.773210000000063],
            [73.297830000000033, 30.773210000000063],
            [73.297015818000034, 30.774067066000043],
            [73.288641000000041, 30.782883000000027],
            [73.28562938500005, 30.794497195000076],
            [73.285255000000063, 30.795941000000028],
            [73.283852772000046, 30.799213269000063],
            [73.279652764000048, 30.809014494000053],
            [73.279652798000029, 30.809014495000042],
            [73.279452000000049, 30.809483000000057],
            [73.288157000000069, 30.809483000000057],
            [73.288598172000036, 30.810218337000038],
            [73.291059000000075, 30.814320000000066],
            [73.289608000000044, 30.820123000000024],
            [73.283804000000032, 30.82931300000007],
            [73.283804000000032, 30.838035782000077],
            [73.283804000000032, 30.838986000000034],
            [73.289608000000044, 30.841887000000042],
            [73.292355187000055, 30.843718537000029],
            [73.301216000000068, 30.849626000000058],
            [73.307987000000082, 30.851560000000063],
            [73.303150000000073, 30.857364000000075],
            [73.298314000000062, 30.859782000000052],
            [73.297880946000078, 30.859876132000068],
            [73.287190000000066, 30.86220000000003],
            [73.290204819000053, 30.863707632000057],
            [73.293961000000081, 30.865586000000064],
            [73.297219175000066, 30.869035575000055],
            [73.301579668000045, 30.873652226000047],
            [73.30218300000007, 30.874291000000028],
            [73.302107254000077, 30.875427580000064],
            [73.301419896000084, 30.885741508000024],
            [73.301216000000068, 30.888801000000058],
            [73.299281000000065, 30.884448000000077],
            [73.290092000000072, 30.881062000000043],
            [73.283805000000029, 30.879611000000068],
            [73.275540192000051, 30.881907413000079],
            [73.275099000000068, 30.882030000000043],
            [73.274002324000037, 30.88521049700006],
            [73.273760296000034, 30.885912408000024],
            [73.270415745000037, 30.895612021000034],
            [73.270348733000048, 30.895806363000077],
            [73.270263000000057, 30.896055000000047],
            [73.270372137000038, 30.899224268000069],
            [73.270662190000053, 30.907647207000025],
            [73.270731473000069, 30.909659135000027],
            [73.270746000000031, 30.910081000000048],
            [73.271387423000078, 30.91606732300005],
            [73.272197000000062, 30.923623000000077],
            [73.270935393000059, 30.928669429000024],
            [73.269328032000033, 30.935098872000026],
            [73.26932800000003, 30.935099000000037],
            [73.271229000000062, 30.935230000000047],
            [73.276627684000061, 30.945256355000026],
            [73.278000000000077, 30.947805000000074],
            [73.282954818000064, 30.949710881000044],
            [73.28896607300004, 30.95202312300006],
            [73.290575000000047, 30.952642000000026],
            [73.293961000000081, 30.965700000000027],
            [73.298412054000039, 30.968914679000079],
            [73.298412259000031, 30.968914457000039],
            [73.302667000000042, 30.97198700000007],
            [73.307464268000047, 30.975078641000039],
            [73.30746398000008, 30.975079100000073],
            [73.320579819000045, 30.983531717000062],
            [73.324430000000064, 30.986013000000071],
            [73.324430060000054, 30.986012394000056],
            [73.324430763000066, 30.98601284700004],
            [73.324431000000061, 30.986013000000071],
            [73.334104000000082, 30.984078000000068],
            [73.345710223000083, 30.982627097000034],
            [73.35106710000008, 30.984956404000059],
            [73.351385794000066, 30.985094980000042],
            [73.356834000000049, 30.987464000000045],
            [73.36210066700005, 30.991413745000045],
            [73.370726571000034, 30.997882755000035],
            [73.372311000000082, 30.999071000000072],
            [73.378114000000039, 30.995686000000035],
            [73.393107000000043, 30.992784000000029],
            [73.393107238000084, 30.992784147000066],
            [73.395779128000072, 30.99443799900007],
            [73.397961597000062, 30.995788908000065],
            [73.403263999000046, 30.999071000000072],
            [73.401813000000061, 31.008261000000061],
            [73.397460000000081, 31.020835000000034],
            [73.397741575000055, 31.021009775000039],
            [73.403264035000063, 31.024437590000048],
            [73.411486000000082, 31.029541000000052],
            [73.419708000000071, 31.036795000000041],
            [73.420137874000034, 31.036917833000075],
            [73.423235911000063, 31.037803074000067],
            [73.426157987000067, 31.038638035000076],
            [73.429864000000066, 31.039697000000046],
            [73.429911493000077, 31.039729883000064],
            [73.431677820000061, 31.040952855000057],
            [73.436151000000052, 31.044050000000027],
            [73.436958438000033, 31.048892958000067],
            [73.437258592000035, 31.050693263000028],
            [73.438539117000062, 31.058373764000066],
            [73.438570000000084, 31.058559000000059],
            [73.445341000000042, 31.067749000000049],
            [73.445357259000048, 31.067756776000067],
            [73.445791396000061, 31.067964419000077],
            [73.45646400000004, 31.073069000000032],
            [73.461303054000041, 31.081364215000065],
            [73.463235000000054, 31.084676000000059],
            [73.463791034000053, 31.084811630000047],
            [73.477390766000042, 31.088128922000067],
            [73.477433126000051, 31.088139255000044],
            [73.477534553000055, 31.088163995000059],
            [73.482925662000071, 31.089479012000027],
            [73.483065000000067, 31.089513000000068],
            [73.485157032000075, 31.089610278000066],
            [73.489097156000071, 31.089793491000023],
            [73.503861000000029, 31.090480000000071],
            [73.505776831000048, 31.09033267500007],
            [73.505789978000053, 31.090349717000038],
            [73.511911231000056, 31.089863903000037],
            [73.513109156000041, 31.089768830000025],
            [73.516436000000056, 31.089513000000068],
            [73.521490590000042, 31.091044579000027],
            [73.52773933900005, 31.092937997000035],
            [73.52775562100004, 31.092963245000078],
            [73.528189678000047, 31.093096175000028],
            [73.528957259000038, 31.093331247000037],
            [73.532457223000051, 31.09440311000003],
            [73.547357223000063, 31.101603110000042],
            [73.557070076000059, 31.102091193000035],
            [73.56437201500006, 31.102458125000055],
            [73.565957187000038, 31.10253778200007],
            [73.56725722300007, 31.102603110000075],
            [73.570623462000071, 31.102478434000034],
            [73.580757223000035, 31.10210311000003],
            [73.58436213400006, 31.09928547800007],
            [73.587460395000051, 31.096875838000074],
            [73.589466000000073, 31.095316000000025],
            [73.593144385000073, 31.09359938700004],
            [73.59617449600006, 31.09219926600008],
            [73.596174574000031, 31.092199230000062],
            [73.596170779000033, 31.092187042000035],
            [73.603975000000048, 31.088545000000067],
            [73.611185442000078, 31.092871415000047],
            [73.613648000000069, 31.094349000000079],
            [73.613648072000046, 31.094349258000079],
            [73.616066000000046, 31.10305500000004],
            [73.616066000000046, 31.110261661000038],
            [73.616066000000046, 31.117080000000044],
            [73.621144155000081, 31.123428022000041],
            [73.623804000000064, 31.126753000000065],
            [73.623914103000061, 31.126801169000032],
            [73.628833160000056, 31.128953197000044],
            [73.628260997000041, 31.129614201000038],
            [73.635288394000042, 31.132099500000038],
            [73.635288496000044, 31.132099536000055],
            [73.639132114000063, 31.133458864000033],
            [73.63928100000004, 31.133524000000079],
            [73.641297216000055, 31.133944023000026],
            [73.641472296000074, 31.133980496000049],
            [73.65085722300006, 31.136003110000047],
            [73.658740234000049, 31.138630780000028],
            [73.659557223000036, 31.138903110000058],
            [73.660530390000076, 31.139174320000052],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "115",
      properties: { name: "Pak Pattan", count: 771 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.489822816000071, 30.590271772000051],
            [73.489922956000044, 30.589767222000035],
            [73.490295410000044, 30.587890625000057],
            [73.48132324200003, 30.579528809000067],
            [73.478916986000058, 30.575615604000063],
            [73.475280762000068, 30.569702148000033],
            [73.474487305000082, 30.568481445000032],
            [73.472900391000053, 30.559509277000075],
            [73.470445362000078, 30.554848884000023],
            [73.469654232000039, 30.553347078000058],
            [73.469218000000069, 30.552537000000029],
            [73.47291000000007, 30.544099000000074],
            [73.475562643000046, 30.545719982000037],
            [73.478515625000057, 30.547485352000024],
            [73.482450430000029, 30.549906770000064],
            [73.486095000000034, 30.55042700000007],
            [73.49136800000008, 30.546736000000067],
            [73.491507038000066, 30.543958412000052],
            [73.491511196000033, 30.543875344000071],
            [73.491875920000041, 30.536589139000057],
            [73.491889706000052, 30.536313740000026],
            [73.491896000000054, 30.536188000000038],
            [73.496425534000082, 30.534893497000041],
            [73.499279000000058, 30.534078000000079],
            [73.507688206000068, 30.527246392000052],
            [73.507717000000071, 30.527223000000049],
            [73.508851186000072, 30.525096301000076],
            [73.511936000000048, 30.51931200000007],
            [73.519651433000035, 30.515454616000056],
            [73.523539000000085, 30.513511000000051],
            [73.523661724000078, 30.513498728000059],
            [73.523681641000053, 30.513488770000038],
            [73.531677246000072, 30.512695312000062],
            [73.539489746000072, 30.511901855000076],
            [73.547912598000039, 30.511901855000076],
            [73.551127102000066, 30.512907755000072],
            [73.556884766000053, 30.514709473000039],
            [73.560834011000054, 30.516053313000043],
            [73.560812184000042, 30.515994169000066],
            [73.564864615000033, 30.517424838000068],
            [73.565729000000033, 30.517730000000029],
            [73.567936166000038, 30.516626300000041],
            [73.569521014000031, 30.51583379300007],
            [73.575222000000053, 30.512983000000077],
            [73.585770000000082, 30.510346000000027],
            [73.591571000000044, 30.507182000000057],
            [73.595981962000053, 30.50228031100005],
            [73.596317000000056, 30.501908000000071],
            [73.596303268000042, 30.501715627000067],
            [73.595896925000034, 30.496022967000044],
            [73.595790000000079, 30.494525000000067],
            [73.595886951000068, 30.494519301000025],
            [73.597115673000076, 30.494447071000025],
            [73.604755000000068, 30.493998000000033],
            [73.608866196000065, 30.492285056000071],
            [73.608886719000054, 30.492309570000032],
            [73.609481753000068, 30.492028582000046],
            [73.611083984000061, 30.491271973000039],
            [73.61480179800003, 30.493343731000039],
            [73.618995000000041, 30.495580000000075],
            [73.626378000000045, 30.493998000000033],
            [73.632560412000032, 30.493046652000032],
            [73.633234000000073, 30.492943000000025],
            [73.633220100000074, 30.49293373200004],
            [73.622159000000067, 30.485559000000023],
            [73.622161750000032, 30.485534260000065],
            [73.622252893000052, 30.484714231000055],
            [73.622565551000037, 30.481901193000056],
            [73.622751261000076, 30.480230333000065],
            [73.623214000000075, 30.476067000000057],
            [73.623279639000032, 30.476161821000062],
            [73.626866240000084, 30.481342971000061],
            [73.627943840000057, 30.48289965500004],
            [73.62796000000003, 30.482923000000028],
            [73.628482420000068, 30.482500064000078],
            [73.63171386700003, 30.480102539000029],
            [73.638256815000034, 30.474586996000028],
            [73.639035000000035, 30.473957000000041],
            [73.639029979000043, 30.473935238000024],
            [73.637525960000062, 30.467417189000059],
            [73.63745300000005, 30.467101000000071],
            [73.634357424000029, 30.462148313000057],
            [73.632179000000065, 30.458663000000058],
            [73.635221406000085, 30.443956755000045],
            [73.635343000000034, 30.443369000000075],
            [73.629542000000072, 30.434404000000029],
            [73.629054981000081, 30.434359714000038],
            [73.622009104000028, 30.433719014000076],
            [73.617940000000033, 30.433349000000078],
            [73.617253327000071, 30.433130505000065],
            [73.610872138000047, 30.431100052000033],
            [73.60632324200003, 30.429687500000057],
            [73.605046619000063, 30.426932681000039],
            [73.604571915000065, 30.425930462000053],
            [73.601591000000042, 30.419637000000023],
            [73.601040061000049, 30.419343180000055],
            [73.595504320000032, 30.416390924000041],
            [73.593680000000063, 30.415418000000045],
            [73.589461000000028, 30.40434300000004],
            [73.588064468000084, 30.401549936000038],
            [73.58616885400005, 30.397758708000026],
            [73.585242000000051, 30.395905000000027],
            [73.585329435000062, 30.395878765000077],
            [73.585649846000081, 30.395782623000059],
            [73.595790000000079, 30.39274000000006],
            [73.600878609000063, 30.394775540000069],
            [73.606337000000053, 30.396959000000038],
            [73.609068883000077, 30.404698039000039],
            [73.609502000000077, 30.405925000000025],
            [73.611491949000083, 30.405792421000058],
            [73.613844121000056, 30.405635708000034],
            [73.613792818000036, 30.405687037000064],
            [73.61749267600004, 30.405517578000058],
            [73.622680664000029, 30.405517578000058],
            [73.634277344000054, 30.402893066000047],
            [73.634288561000062, 30.402761100000077],
            [73.635314941000047, 30.390686035000044],
            [73.635340115000076, 30.39066420000006],
            [73.635343000000034, 30.390631000000042],
            [73.63928594500004, 30.387046875000067],
            [73.641145000000051, 30.385357000000056],
            [73.64641800000004, 30.379556000000036],
            [73.64709802200008, 30.377062444000046],
            [73.648000000000081, 30.373755000000074],
            [73.646750771000029, 30.367091919000075],
            [73.64641800000004, 30.365317000000061],
            [73.636398000000042, 30.363734000000079],
            [73.629542000000072, 30.359515000000044],
            [73.629542000000072, 30.359512442000039],
            [73.629542000000072, 30.355070501000057],
            [73.629542000000072, 30.351841020000052],
            [73.629542000000072, 30.346858000000054],
            [73.629542000000072, 30.332619000000079],
            [73.629542000000072, 30.327843844000029],
            [73.629542000000072, 30.327841766000063],
            [73.629542000000072, 30.319434000000058],
            [73.629630481000049, 30.317047872000046],
            [73.629985463000082, 30.307474786000057],
            [73.630028895000066, 30.306303520000029],
            [73.630070000000046, 30.305195000000026],
            [73.630070000000046, 30.29306500000007],
            [73.630580773000077, 30.277725877000023],
            [73.629375405000076, 30.277926772000058],
            [73.628479004000042, 30.278076172000056],
            [73.620626651000066, 30.276712589000056],
            [73.618213000000082, 30.276260000000036],
            [73.617043787000057, 30.275728570000069],
            [73.612251067000045, 30.273500376000072],
            [73.611328125000057, 30.273071289000029],
            [73.609269728000072, 30.270947546000059],
            [73.607482910000044, 30.269104004000042],
            [73.607028788000036, 30.268630137000059],
            [73.60698181600003, 30.268581123000047],
            [73.606999104000067, 30.268490839000037],
            [73.603346000000045, 30.264557000000025],
            [73.600183000000072, 30.263608000000033],
            [73.598193140000035, 30.265597441000068],
            [73.595438000000058, 30.26835200000005],
            [73.588479000000063, 30.271199000000024],
            [73.582817175000059, 30.269783358000041],
            [73.581572221000044, 30.269472078000035],
            [73.580871582000043, 30.269287109000061],
            [73.58043919000005, 30.269031759000029],
            [73.573073943000054, 30.26461335700003],
            [73.571552175000079, 30.26144559200003],
            [73.570129395000038, 30.258483887000068],
            [73.568908691000047, 30.251892090000069],
            [73.56887133400005, 30.251921492000065],
            [73.568868000000066, 30.251904000000025],
            [73.563438495000071, 30.256041116000063],
            [73.562226000000067, 30.256965000000037],
            [73.558465570000067, 30.259471953000059],
            [73.556518555000082, 30.260681152000075],
            [73.553635786000029, 30.262002421000034],
            [73.552124023000033, 30.262695313000052],
            [73.544540719000054, 30.262464349000027],
            [73.543536700000061, 30.26243377000003],
            [73.540100098000039, 30.262329102000024],
            [73.532470703000058, 30.258483887000068],
            [73.526916504000042, 30.250671387000068],
            [73.519213611000055, 30.244220688000041],
            [73.517222694000054, 30.242536091000034],
            [73.514463000000035, 30.24020100000007],
            [73.511555306000048, 30.23676458400007],
            [73.510314941000047, 30.235290527000075],
            [73.510311556000033, 30.235294676000024],
            [73.50402500000007, 30.227865000000065],
            [73.503802535000034, 30.227253332000032],
            [73.50380202100007, 30.227118526000027],
            [73.501525879000042, 30.220886230000076],
            [73.500077973000032, 30.220021018000068],
            [73.496433000000081, 30.217743000000041],
            [73.492005000000063, 30.217426000000046],
            [73.491950634000034, 30.217422379000027],
            [73.488926990000039, 30.217221015000064],
            [73.487801025000067, 30.217146030000038],
            [73.487304688000052, 30.21710205100004],
            [73.487137842000038, 30.214909220000038],
            [73.486877441000047, 30.211486816000047],
            [73.487116435000075, 30.208482321000076],
            [73.487304688000052, 30.206115723000039],
            [73.487256052000077, 30.206106187000046],
            [73.487260000000049, 30.206039000000033],
            [73.480934000000047, 30.204774000000043],
            [73.479542863000063, 30.206686950000062],
            [73.478404000000069, 30.20825300000007],
            [73.475873000000036, 30.214263000000074],
            [73.473975000000053, 30.219641000000024],
            [73.471128000000078, 30.22217100000006],
            [73.468773458000044, 30.222956123000074],
            [73.468322754000042, 30.223083496000072],
            [73.46761125200004, 30.223298819000036],
            [73.463684082000043, 30.224487305000025],
            [73.463591938000036, 30.22437035400003],
            [73.462097168000071, 30.222473145000038],
            [73.461486816000047, 30.220703125000057],
            [73.459472656000059, 30.214904785000044],
            [73.456726074000073, 30.210876465000069],
            [73.453955502000042, 30.207112668000036],
            [73.453491211000085, 30.20646269100007],
            [73.453491211000085, 30.20330152400004],
            [73.453491211000085, 30.20007324200003],
            [73.45788574200003, 30.195678711000028],
            [73.459757165000042, 30.192218345000072],
            [73.461120605000076, 30.189697266000053],
            [73.461278017000041, 30.18334834500007],
            [73.46132300000005, 30.182316000000071],
            [73.461277596000059, 30.181772296000076],
            [73.461157080000078, 30.179281630000048],
            [73.461120605000076, 30.178527832000043],
            [73.461006654000073, 30.178527832000043],
            [73.459106445000032, 30.178527832000043],
            [73.455078125000057, 30.178283691000047],
            [73.455078125000057, 30.178210234000062],
            [73.455078125000057, 30.174072266000053],
            [73.455267802000037, 30.169899384000075],
            [73.455322266000053, 30.168701172000056],
            [73.455688477000081, 30.163696289000029],
            [73.459106445000032, 30.159301758000026],
            [73.459061719000033, 30.159285174000047],
            [73.454759945000035, 30.157690134000063],
            [73.453674316000047, 30.157287598000039],
            [73.45182034700008, 30.157236099000045],
            [73.447082520000038, 30.15710449200003],
            [73.442077637000068, 30.162719727000024],
            [73.436706543000071, 30.165527344000054],
            [73.431091309000067, 30.169494629000042],
            [73.427110735000042, 30.171031682000034],
            [73.42525609900008, 30.171747829000026],
            [73.424941476000072, 30.171869317000073],
            [73.424926758000083, 30.171875000000057],
            [73.423278809000067, 30.169494629000042],
            [73.42272949200003, 30.168518066000047],
            [73.416687012000068, 30.168090820000032],
            [73.412109375000057, 30.167480469000054],
            [73.412109375000057, 30.167466526000055],
            [73.412109375000057, 30.164090927000075],
            [73.412109375000057, 30.163330078000058],
            [73.411071777000075, 30.156127930000025],
            [73.414105960000029, 30.147826864000024],
            [73.413951927000085, 30.147880760000078],
            [73.414145442000063, 30.147339114000033],
            [73.406921387000068, 30.150085449000073],
            [73.402709961000085, 30.152099609000061],
            [73.400329590000069, 30.152709961000028],
            [73.389557456000034, 30.152709961000028],
            [73.388305664000029, 30.152709961000028],
            [73.381286621000072, 30.152282715000069],
            [73.380835632000071, 30.150753273000078],
            [73.379882813000052, 30.147521973000039],
            [73.379402283000047, 30.146043422000048],
            [73.379082000000039, 30.144991000000061],
            [73.379035778000059, 30.144559662000063],
            [73.378255732000071, 30.137280323000027],
            [73.378133000000048, 30.136135000000024],
            [73.378021941000043, 30.134913345000029],
            [73.377997730000061, 30.134594884000023],
            [73.37780964500007, 30.132120843000052],
            [73.377632299000084, 30.129788057000042],
            [73.377319336000085, 30.125671387000068],
            [73.377367568000068, 30.12335626600003],
            [73.377502441000047, 30.116882324000073],
            [73.378604832000065, 30.113892791000069],
            [73.381103516000053, 30.107116699000073],
            [73.381222446000038, 30.10335057900005],
            [73.381185154000036, 30.103337335000049],
            [73.381272652000064, 30.101760700000057],
            [73.381296000000077, 30.10134000000005],
            [73.381309442000031, 30.101281756000049],
            [73.381424827000046, 30.100781794000056],
            [73.382324219000054, 30.097290039000029],
            [73.38356428000003, 30.093221089000053],
            [73.384277344000054, 30.090881348000039],
            [73.386558394000076, 30.086205196000037],
            [73.386718750000057, 30.085876465000069],
            [73.386636126000042, 30.083645607000051],
            [73.386568586000067, 30.083632289000036],
            [73.386357000000032, 30.079199000000074],
            [73.385239457000068, 30.078151234000075],
            [73.382732617000045, 30.075800917000038],
            [73.381296000000077, 30.07445400000006],
            [73.379715000000033, 30.068760000000054],
            [73.374654000000078, 30.070975000000033],
            [73.373190816000033, 30.07719194200007],
            [73.372337757000082, 30.080643855000062],
            [73.372376338000038, 30.080652590000057],
            [73.372123000000045, 30.081729000000053],
            [73.370576644000039, 30.089463715000079],
            [73.370534950000035, 30.089461941000025],
            [73.370489263000081, 30.089692132000039],
            [73.370483398000033, 30.089721680000025],
            [73.36749267600004, 30.093078613000046],
            [73.365966636000053, 30.094629266000027],
            [73.363708496000072, 30.096923828000058],
            [73.352294922000056, 30.101074219000054],
            [73.345787666000035, 30.103553173000023],
            [73.344844530000046, 30.103912463000029],
            [73.343322754000042, 30.104492187000062],
            [73.341794392000054, 30.104820785000072],
            [73.331115723000039, 30.107116699000073],
            [73.326110840000069, 30.109130859000061],
            [73.322115232000044, 30.110404822000078],
            [73.321899414000029, 30.110473633000026],
            [73.310729980000076, 30.112670898000033],
            [73.309426138000049, 30.111478360000035],
            [73.305725098000039, 30.108093262000068],
            [73.304606905000071, 30.105711025000062],
            [73.304619331000083, 30.105705699000055],
            [73.304619000000059, 30.105705000000057],
            [73.302994885000032, 30.102276723000045],
            [73.302917480000076, 30.102111816000047],
            [73.302916666000044, 30.102111613000034],
            [73.302851000000032, 30.101973000000044],
            [73.298455018000084, 30.100996201000044],
            [73.297302246000072, 30.100708008000026],
            [73.296962007000047, 30.10069984200004],
            [73.29425374300007, 30.100587117000032],
            [73.289566000000036, 30.100392000000056],
            [73.286087000000066, 30.104187000000024],
            [73.286012000000085, 30.104262000000062],
            [73.281734125000071, 30.108539875000076],
            [73.281026000000054, 30.109248000000036],
            [73.275679575000083, 30.109248000000036],
            [73.275648000000047, 30.109248000000036],
            [73.270613308000065, 30.107045073000052],
            [73.270588000000032, 30.107034000000056],
            [73.262681285000042, 30.101657874000068],
            [73.262561478000066, 30.10142985300007],
            [73.25888400000008, 30.094382000000053],
            [73.254139000000066, 30.089637000000039],
            [73.250962996000055, 30.088904017000061],
            [73.250122070000032, 30.088684082000043],
            [73.250058434000039, 30.088695255000061],
            [73.250027000000046, 30.088688000000047],
            [73.24633134000004, 30.089279250000061],
            [73.242120000000057, 30.089953000000037],
            [73.24097905800005, 30.089514187000077],
            [73.240905762000068, 30.089477539000029],
            [73.240285612000037, 30.089247483000065],
            [73.237121582000043, 30.088073730000076],
            [73.233886719000054, 30.086914063000052],
            [73.232044307000081, 30.083087514000056],
            [73.229919434000067, 30.078674316000047],
            [73.226654844000052, 30.068548557000042],
            [73.226318359000061, 30.067504883000026],
            [73.219482422000056, 30.062500000000057],
            [73.219456650000041, 30.062515199000075],
            [73.219345000000033, 30.062434000000053],
            [73.214601000000073, 30.065281000000027],
            [73.211867310000059, 30.066542806000029],
            [73.210489000000052, 30.067179000000067],
            [73.204795000000047, 30.068444000000056],
            [73.202601301000072, 30.069419106000055],
            [73.199101000000041, 30.070975000000033],
            [73.199046856000052, 30.070944052000073],
            [73.194699148000041, 30.068458946000078],
            [73.193481445000032, 30.059326172000056],
            [73.194091797000056, 30.053710938000052],
            [73.19403154500003, 30.053654162000043],
            [73.19204703500003, 30.051784143000077],
            [73.192035764000082, 30.051773522000076],
            [73.190917969000054, 30.050720215000069],
            [73.189061201000072, 30.050157558000024],
            [73.184867000000054, 30.048833000000059],
            [73.182337886000084, 30.050378545000058],
            [73.179174000000046, 30.052312000000029],
            [73.176756983000075, 30.056663090000029],
            [73.176011000000074, 30.058006000000034],
            [73.172215000000051, 30.065597000000025],
            [73.169527011000071, 30.066101031000073],
            [73.169512771000029, 30.066150080000057],
            [73.169465397000067, 30.066158168000072],
            [73.167297363000046, 30.066528320000032],
            [73.167289068000059, 30.06652067300007],
            [73.163048360000062, 30.062611271000037],
            [73.159484863000046, 30.059326172000056],
            [73.156797272000063, 30.056136897000044],
            [73.154907227000081, 30.053894043000071],
            [73.154892247000078, 30.053889156000025],
            [73.151692316000037, 30.052845207000075],
            [73.150742082000079, 30.052535202000058],
            [73.146301270000038, 30.05108642600004],
            [73.139709473000039, 30.046691895000038],
            [73.134887695000032, 30.043518066000047],
            [73.131796460000032, 30.041737246000025],
            [73.129272461000085, 30.040283203000058],
            [73.126079555000047, 30.038367460000075],
            [73.12530517600004, 30.037902832000043],
            [73.122482408000053, 30.036148138000044],
            [73.122483840000029, 30.036146508000058],
            [73.121846478000066, 30.035752830000035],
            [73.118530273000033, 30.033691406000059],
            [73.118492992000029, 30.033681496000042],
            [73.118442000000073, 30.03365000000008],
            [73.108953000000042, 30.031120000000044],
            [73.102627000000041, 30.02827300000007],
            [73.097750361000067, 30.027008876000025],
            [73.094108949000031, 30.026064949000045],
            [73.094027035000067, 30.025409639000031],
            [73.093688965000069, 30.022705078000058],
            [73.094693936000056, 30.013955920000058],
            [73.094726563000052, 30.013671875000057],
            [73.093505859000061, 30.008483887000068],
            [73.098851155000034, 30.004900109000062],
            [73.098876953000058, 30.004882813000052],
            [73.103037120000067, 30.011816424000074],
            [73.103088379000042, 30.011901855000076],
            [73.105747674000042, 30.011569443000042],
            [73.106418677000079, 30.011485568000069],
            [73.106506348000039, 30.011474609000061],
            [73.105285645000038, 30.003112793000071],
            [73.105175793000058, 30.003094874000055],
            [73.096899891000078, 30.001744904000077],
            [73.096679688000052, 30.001708984000061],
            [73.094768753000039, 30.001009862000046],
            [73.091556000000082, 29.999805000000038],
            [73.089974000000041, 30.013090000000034],
            [73.08460238400005, 30.014799221000033],
            [73.083016000000043, 30.015304000000071],
            [73.076373000000046, 30.015937000000065],
            [73.074277119000044, 30.017450569000061],
            [73.074241176000044, 30.017544575000045],
            [73.070678711000085, 30.020080566000047],
            [73.066974321000032, 30.022846831000038],
            [73.061279297000056, 30.027099609000061],
            [73.060218553000084, 30.028430360000073],
            [73.057922363000046, 30.031311035000044],
            [73.054321289000029, 30.035705566000047],
            [73.048095703000058, 30.038696289000029],
            [73.04132080100004, 30.041076660000044],
            [73.039597466000032, 30.040968431000067],
            [73.028686523000033, 30.040283203000058],
            [73.023071289000029, 30.038696289000029],
            [73.021911621000072, 30.040100098000039],
            [73.020920999000055, 30.032058579000079],
            [73.020874023000033, 30.031677246000072],
            [73.019714355000076, 30.023681641000053],
            [73.021484375000057, 30.018310547000056],
            [73.029724121000072, 30.008911133000026],
            [73.031921387000068, 30.001708984000061],
            [73.032093694000082, 29.997659780000049],
            [73.032287598000039, 29.993103027000075],
            [73.03210449200003, 29.990112305000025],
            [73.031988394000052, 29.990071538000052],
            [73.024108887000068, 29.987304687000062],
            [73.019287109000061, 29.987487793000071],
            [73.019276787000081, 29.987484520000066],
            [73.014282227000081, 29.985900879000042],
            [73.012878418000071, 29.983886719000054],
            [73.012873346000049, 29.983670305000032],
            [73.012709000000029, 29.976658239000074],
            [73.012695312000062, 29.976074219000054],
            [73.013502993000031, 29.975126071000034],
            [73.016906738000046, 29.971130371000072],
            [73.015944648000072, 29.965357830000073],
            [73.015922000000046, 29.965205000000026],
            [73.011764335000066, 29.963193166000053],
            [73.011759451000046, 29.963190803000032],
            [73.009282000000042, 29.961992000000066],
            [73.001602669000079, 29.962711815000034],
            [72.995573000000036, 29.963277000000062],
            [72.985086185000057, 29.963277000000062],
            [72.982293000000084, 29.963277000000062],
            [72.980639229000076, 29.964356930000065],
            [72.971797000000038, 29.970131000000038],
            [72.971721323000054, 29.970182779000027],
            [72.972197723000079, 29.971067603000051],
            [72.968652556000052, 29.978032815000063],
            [72.96710205100004, 29.981079102000024],
            [72.96710205100004, 29.988525391000053],
            [72.96710205100004, 29.993103027000075],
            [72.968027426000049, 29.997378900000058],
            [72.968608533000065, 30.000064017000057],
            [72.968872070000032, 30.001281738000046],
            [72.970515621000061, 30.006938609000031],
            [72.971496582000043, 30.010314941000047],
            [72.972473145000038, 30.017272949000073],
            [72.973209705000045, 30.017319567000072],
            [72.982116699000073, 30.01788330100004],
            [72.984430000000032, 30.020255917000043],
            [72.984497070000032, 30.020324707000043],
            [72.984497070000032, 30.024475098000039],
            [72.984430000000032, 30.024525401000062],
            [72.984183829000074, 30.024710030000051],
            [72.979125977000081, 30.028503418000071],
            [72.971679688000052, 30.029724121000072],
            [72.971414777000064, 30.029738571000053],
            [72.97125384800006, 30.029766564000056],
            [72.967986000000053, 30.030335000000036],
            [72.961312488000033, 30.032559760000026],
            [72.959281000000033, 30.033237000000042],
            [72.949608000000069, 30.033237000000042],
            [72.944778748000033, 30.033237000000042],
            [72.943320000000028, 30.033237000000042],
            [72.93654900000007, 30.029367000000036],
            [72.935784259000059, 30.026498630000049],
            [72.934692383000083, 30.022094727000024],
            [72.934623009000063, 30.022143041000049],
            [72.932601309000063, 30.023551011000052],
            [72.932320450000077, 30.023746609000057],
            [72.932323560000043, 30.023749356000053],
            [72.93126992100008, 30.024501778000058],
            [72.93122900000003, 30.024531000000025],
            [72.930471336000039, 30.02907854800003],
            [72.930262000000084, 30.030335000000036],
            [72.935099000000037, 30.036138000000051],
            [72.936844447000055, 30.042248871000027],
            [72.937033000000042, 30.042909000000066],
            [72.942107342000043, 30.051674640000044],
            [72.942353000000082, 30.052099000000055],
            [72.93894507300007, 30.058488532000069],
            [72.938484000000074, 30.059353000000044],
            [72.938405586000044, 30.059501996000051],
            [72.936691997000082, 30.062758028000076],
            [72.933715820000032, 30.068481445000032],
            [72.932922363000046, 30.076721191000047],
            [72.932680000000062, 30.077872418000027],
            [72.932678223000039, 30.077880859000061],
            [72.932678223000039, 30.084472656000059],
            [72.932680000000062, 30.084474689000047],
            [72.93530614000008, 30.08747917200003],
            [72.937566500000059, 30.090065177000042],
            [72.939880371000072, 30.092712402000075],
            [72.943418125000051, 30.096250157000043],
            [72.946224103000077, 30.09905613400008],
            [72.946401413000046, 30.102776712000036],
            [72.946706000000063, 30.109168000000068],
            [72.946706000000063, 30.113246951000065],
            [72.946706000000063, 30.120581918000028],
            [72.946706000000063, 30.123678000000041],
            [72.946473837000042, 30.126000354000041],
            [72.946237480000036, 30.128224886000055],
            [72.945678711000085, 30.133483887000068],
            [72.946022830000061, 30.134722715000066],
            [72.947213255000065, 30.140475894000076],
            [72.947292731000061, 30.140859990000024],
            [72.947446132000039, 30.141601356000024],
            [72.948641000000066, 30.147376000000065],
            [72.946227578000048, 30.157833881000045],
            [72.946068268000033, 30.158490237000024],
            [72.945678711000085, 30.160095215000069],
            [72.945678711000085, 30.16520003200003],
            [72.945678711000085, 30.170104980000076],
            [72.946218283000064, 30.172982697000066],
            [72.947190000000035, 30.178813000000048],
            [72.947227523000038, 30.178834649000066],
            [72.947326199000031, 30.178891583000052],
            [72.947326660000044, 30.178894043000071],
            [72.947852923000084, 30.179195495000045],
            [72.951946529000054, 30.181557442000042],
            [72.951987686000052, 30.181581189000042],
            [72.951992325000049, 30.181566608000026],
            [72.959899902000075, 30.186096191000047],
            [72.959976198000049, 30.188167069000031],
            [72.960327148000033, 30.197692871000072],
            [72.960327148000033, 30.199111592000065],
            [72.960327148000033, 30.208923340000069],
            [72.959950577000029, 30.211747628000069],
            [72.959716797000056, 30.213500977000024],
            [72.958923340000069, 30.217529297000056],
            [72.958923340000069, 30.223327637000068],
            [72.954101563000052, 30.230712891000053],
            [72.95412940500006, 30.231651549000048],
            [72.954528809000067, 30.245117187000062],
            [72.95506646900003, 30.246043157000031],
            [72.956726074000073, 30.24890136700003],
            [72.961493978000078, 30.257512785000074],
            [72.962707520000038, 30.259704590000069],
            [72.965823768000064, 30.262388026000053],
            [72.971372000000031, 30.267320000000041],
            [72.977075310000032, 30.268688870000062],
            [72.983463000000029, 30.270222000000047],
            [72.986698938000075, 30.272995320000064],
            [72.990234000000044, 30.276025000000061],
            [72.990264791000072, 30.276096841000026],
            [72.990295410000044, 30.276123047000056],
            [72.992309570000032, 30.280700684000067],
            [72.992406982000034, 30.280919861000029],
            [72.993286133000083, 30.282897949000073],
            [72.993103027000075, 30.290283203000058],
            [72.992675781000059, 30.296325684000067],
            [72.992652088000057, 30.296335537000061],
            [72.992574326000067, 30.296367874000055],
            [72.986511230000076, 30.298889160000044],
            [72.980529785000044, 30.296081543000071],
            [72.973327637000068, 30.292480469000054],
            [72.962707520000038, 30.292480469000054],
            [72.950683594000054, 30.292480469000054],
            [72.942504883000083, 30.292480469000054],
            [72.942480130000035, 30.292498840000064],
            [72.940270306000059, 30.294138944000053],
            [72.940242771000044, 30.294159380000053],
            [72.934692383000083, 30.298278809000067],
            [72.931701660000044, 30.307128906000059],
            [72.925401349000083, 30.308556321000026],
            [72.923889160000044, 30.30889892600004],
            [72.921494539000037, 30.30889892600004],
            [72.913879395000038, 30.30889892600004],
            [72.903320312000062, 30.30889892600004],
            [72.898666153000079, 30.30889892600004],
            [72.898661736000065, 30.308913000000075],
            [72.891579909000029, 30.308913000000075],
            [72.891575939000063, 30.30889892600004],
            [72.890686035000044, 30.30889892600004],
            [72.890604839000048, 30.308913000000075],
            [72.890603000000056, 30.308913000000075],
            [72.881414000000063, 30.31036400000005],
            [72.873712512000054, 30.31469646000005],
            [72.871814270000073, 30.314430523000055],
            [72.869520139000031, 30.314109123000037],
            [72.866545313000074, 30.313692360000061],
            [72.866580138000074, 30.313625112000068],
            [72.866489923000074, 30.313611230000049],
            [72.861101000000076, 30.312782000000027],
            [72.85287900000003, 30.312298000000055],
            [72.84949400000005, 30.318586000000039],
            [72.847236379000037, 30.322650278000026],
            [72.847106934000067, 30.322875977000024],
            [72.847104281000043, 30.322888086000034],
            [72.845703125000057, 30.329284668000071],
            [72.843690000000038, 30.33752018000007],
            [72.843688965000069, 30.337524414000029],
            [72.843688965000069, 30.348083496000072],
            [72.843690000000038, 30.348086601000034],
            [72.845092773000033, 30.352294922000056],
            [72.846679687000062, 30.356872559000067],
            [72.848083496000072, 30.366516113000046],
            [72.847900391000053, 30.36749267600004],
            [72.847290014000066, 30.370186755000077],
            [72.846130371000072, 30.37530517600004],
            [72.839477539000029, 30.383483887000068],
            [72.839470087000052, 30.383494222000024],
            [72.832357906000084, 30.39335783100006],
            [72.831115723000039, 30.395080566000047],
            [72.830642594000039, 30.398364638000032],
            [72.830119237000076, 30.402301558000033],
            [72.830113968000035, 30.402341195000076],
            [72.833156965000057, 30.403694893000079],
            [72.833217578000074, 30.403721631000053],
            [72.834468472000083, 30.404273432000025],
            [72.841230930000052, 30.39992238800005],
            [72.841937703000042, 30.399639552000053],
            [72.846130371000072, 30.397888184000067],
            [72.851214884000058, 30.399399796000068],
            [72.852905273000033, 30.399902344000054],
            [72.857003813000063, 30.404000883000037],
            [72.85772705100004, 30.404724121000072],
            [72.86345405700007, 30.402734740000028],
            [72.862582900000064, 30.395779885000024],
            [72.862487793000071, 30.395080566000047],
            [72.867045267000037, 30.394552164000061],
            [72.870708748000084, 30.394120942000029],
            [72.870718633000081, 30.394126266000058],
            [72.870910645000038, 30.394104004000042],
            [72.871408061000068, 30.394379799000035],
            [72.877075195000032, 30.397521973000039],
            [72.880493164000029, 30.399475098000039],
            [72.882873535000044, 30.402282715000069],
            [72.882873535000044, 30.410095215000069],
            [72.887293214000067, 30.412880218000055],
            [72.888110795000046, 30.412334328000043],
            [72.888591676000033, 30.41201324900004],
            [72.891010864000066, 30.409593707000056],
            [72.891009659000076, 30.409588485000029],
            [72.88956009900005, 30.403307836000067],
            [72.892458840000074, 30.392189138000049],
            [72.892493010000067, 30.39218616200003],
            [72.892517090000069, 30.392089844000054],
            [72.900027793000049, 30.391433170000028],
            [72.903686523000033, 30.391113281000059],
            [72.903320312000062, 30.396484375000057],
            [72.899902344000054, 30.401916504000042],
            [72.89929199200003, 30.408691406000059],
            [72.901306152000075, 30.413879395000038],
            [72.901489258000083, 30.41491699200003],
            [72.901490501000069, 30.414922920000038],
            [72.901407285000062, 30.415179503000047],
            [72.902126742000064, 30.418780875000039],
            [72.902586044000032, 30.419701650000036],
            [72.902586213000063, 30.419701369000052],
            [72.904058884000051, 30.422648165000055],
            [72.909374232000062, 30.422164377000058],
            [72.912377634000052, 30.421891620000054],
            [72.91469058000007, 30.421681567000064],
            [72.916593965000061, 30.42159496000005],
            [72.918471132000036, 30.421486453000057],
            [72.925476074000073, 30.421081543000071],
            [72.92881952700003, 30.421738293000033],
            [72.935729980000076, 30.423095703000058],
            [72.935119629000042, 30.429321289000029],
            [72.939514160000044, 30.435119629000042],
            [72.948517030000062, 30.432734763000042],
            [72.948516072000075, 30.427966905000062],
            [72.946097441000063, 30.417814370000031],
            [72.946853478000037, 30.416782466000029],
            [72.951410886000076, 30.410562124000023],
            [72.951419392000048, 30.410602505000043],
            [72.95147705100004, 30.410522461000028],
            [72.953491211000085, 30.419677734000061],
            [72.954101563000052, 30.425476074000073],
            [72.958286695000083, 30.431844756000032],
            [72.960453640000082, 30.432051404000049],
            [72.968326893000039, 30.432802225000046],
            [72.963845869000068, 30.438781779000067],
            [72.962605479000047, 30.440558425000063],
            [72.960674959000073, 30.448293815000056],
            [72.960878670000056, 30.448293814000067],
            [72.964214557000048, 30.448293804000059],
            [72.966535000000079, 30.448202000000038],
            [72.969755110000051, 30.443371418000027],
            [72.97040400000003, 30.442398000000026],
            [72.973790000000065, 30.437078000000042],
            [72.974152630000049, 30.436624736000056],
            [72.977659000000074, 30.432242000000031],
            [72.983463000000029, 30.42450400000007],
            [72.983530533000078, 30.424199997000073],
            [72.983540338000068, 30.424155858000063],
            [72.984430000000032, 30.420151000000033],
            [72.985306035000065, 30.419832367000026],
            [72.989750000000072, 30.418216000000029],
            [73.001266602000044, 30.418216000000029],
            [73.001358000000039, 30.418216000000029],
            [72.999991922000049, 30.413337900000045],
            [72.997972000000061, 30.406125000000031],
            [73.002808000000073, 30.400805000000048],
            [73.002901239000039, 30.400909876000071],
            [73.002929688000052, 30.400878906000059],
            [73.003104242000063, 30.401081614000077],
            [73.004655623000076, 30.402883218000056],
            [73.006678000000079, 30.405158000000029],
            [73.012183026000059, 30.405944200000079],
            [73.013324001000058, 30.406107148000046],
            [73.013488770000038, 30.406127930000025],
            [73.013488770000038, 30.410095215000069],
            [73.014516342000036, 30.417650897000044],
            [73.01973600000008, 30.413380000000075],
            [73.026023000000066, 30.40999400000004],
            [73.029764211000042, 30.409313972000064],
            [73.031343000000049, 30.409027000000037],
            [73.03210449200003, 30.409074624000027],
            [73.036351651000075, 30.409340243000031],
            [73.039082000000064, 30.409511000000066],
            [73.03231100000005, 30.415798000000052],
            [73.03231100000005, 30.415871616000061],
            [73.03231100000005, 30.422085000000038],
            [73.035117962000072, 30.42285058300007],
            [73.037631000000033, 30.42353600000007],
            [73.037705870000082, 30.423519368000029],
            [73.038196571000071, 30.423410361000037],
            [73.04198400000007, 30.422569000000067],
            [73.04195844000003, 30.422522142000048],
            [73.039082000000064, 30.417249000000027],
            [73.045853000000079, 30.410478000000069],
            [73.049553174000039, 30.409068393000041],
            [73.056009000000074, 30.40660900000006],
            [73.057121950000067, 30.406887166000047],
            [73.059878000000083, 30.407576000000063],
            [73.059964084000057, 30.408264140000028],
            [73.060485840000069, 30.411499023000033],
            [73.065294747000053, 30.418255842000065],
            [73.065335446000063, 30.418272603000048],
            [73.071901788000048, 30.42097676700007],
            [73.073420000000056, 30.421602000000064],
            [73.073469799000065, 30.421684988000038],
            [73.073891347000028, 30.422387472000025],
            [73.075893688000065, 30.425724246000073],
            [73.076322000000062, 30.426438000000076],
            [73.076322000000062, 30.431275000000028],
            [73.076597414000048, 30.434029708000025],
            [73.077289000000064, 30.440947000000051],
            [73.077560986000037, 30.442985492000048],
            [73.078257000000065, 30.448202000000038],
            [73.077773000000036, 30.455940000000055],
            [73.079445039000063, 30.457333750000032],
            [73.080675000000042, 30.45835900000003],
            [73.082610000000045, 30.449653000000069],
            [73.089864000000034, 30.445300000000032],
            [73.092282000000068, 30.445300000000032],
            [73.092281343000082, 30.445312500000057],
            [73.091799000000037, 30.454489000000024],
            [73.090839386000084, 30.461845823000033],
            [73.090348000000063, 30.465613000000076],
            [73.095345071000054, 30.466945966000026],
            [73.097717285000044, 30.467529297000056],
            [73.101928711000085, 30.467529297000056],
            [73.102905273000033, 30.467529297000056],
            [73.103774233000081, 30.465951800000028],
            [73.106792000000041, 30.460293000000036],
            [73.107759000000044, 30.451588000000072],
            [73.111054702000047, 30.453647707000073],
            [73.111628000000053, 30.454006000000049],
            [73.111628000000053, 30.454059835000066],
            [73.111694336000085, 30.454101562000062],
            [73.111694336000085, 30.454799960000059],
            [73.111694336000085, 30.462707520000038],
            [73.116066695000029, 30.470480603000055],
            [73.122752000000048, 30.472868000000062],
            [73.126724846000059, 30.474192282000047],
            [73.127075195000032, 30.474304199000073],
            [73.127078348000055, 30.474310116000026],
            [73.128365841000061, 30.476725974000033],
            [73.129473489000077, 30.478840498000068],
            [73.12947815900003, 30.478849413000034],
            [73.132425000000069, 30.484475000000032],
            [73.132502888000033, 30.484488745000078],
            [73.134066919000077, 30.484764762000054],
            [73.14066060600004, 30.485896514000046],
            [73.140686035000044, 30.485900879000042],
            [73.143676758000083, 30.479675293000071],
            [73.136901855000076, 30.471496582000043],
            [73.142681065000033, 30.469124064000027],
            [73.142700195000032, 30.469116211000028],
            [73.148498535000044, 30.476318359000061],
            [73.150222018000079, 30.478319823000049],
            [73.152254000000084, 30.480606000000023],
            [73.159025000000042, 30.483024000000057],
            [73.160310796000033, 30.482645862000027],
            [73.167247000000032, 30.480606000000023],
            [73.171517687000062, 30.481033422000053],
            [73.172119141000053, 30.481079102000024],
            [73.177860263000071, 30.482610068000042],
            [73.181273000000033, 30.483508000000029],
            [73.185142000000042, 30.488828000000069],
            [73.192880000000059, 30.489312000000041],
            [73.193446794000067, 30.489399178000042],
            [73.195495605000076, 30.489685059000067],
            [73.199279785000044, 30.490295410000044],
            [73.198412262000033, 30.492566743000054],
            [73.195922852000081, 30.499084473000039],
            [73.193481445000032, 30.504272461000028],
            [73.189273427000046, 30.512893766000047],
            [73.188527000000079, 30.51446100000004],
            [73.188734085000078, 30.517565139000055],
            [73.18901100000005, 30.521716000000026],
            [73.189086251000049, 30.521720697000035],
            [73.196749000000068, 30.522199000000057],
            [73.198665683000058, 30.522390807000079],
            [73.206422000000032, 30.523167000000058],
            [73.207389000000035, 30.523167000000058],
            [73.210007585000028, 30.519566361000045],
            [73.211258000000043, 30.517847000000074],
            [73.213677000000075, 30.516879000000074],
            [73.219964000000061, 30.523167000000058],
            [73.21998147100004, 30.523204128000032],
            [73.220092773000033, 30.523315430000025],
            [73.223552677000043, 30.530793287000051],
            [73.22383300000007, 30.531389000000047],
            [73.228363671000068, 30.533977859000061],
            [73.230604000000028, 30.535258000000056],
            [73.230619650000051, 30.535226704000024],
            [73.235441000000037, 30.525585000000035],
            [73.235464830000069, 30.525561162000031],
            [73.237684804000082, 30.523340422000047],
            [73.238292701000034, 30.522732316000031],
            [73.238342000000046, 30.522683000000029],
            [73.239722251000046, 30.526132913000026],
            [73.242212000000052, 30.53235600000005],
            [73.249716729000056, 30.533857411000042],
            [73.250989767000078, 30.534112098000037],
            [73.251884000000075, 30.534291000000053],
            [73.254090336000047, 30.534291000000053],
            [73.260590000000036, 30.534291000000053],
            [73.265710029000047, 30.534291000000053],
            [73.266908962000059, 30.534291000000053],
            [73.269296000000054, 30.534291000000053],
            [73.27056338400007, 30.534009278000042],
            [73.278076172000056, 30.532287598000039],
            [73.280591513000047, 30.532018097000048],
            [73.281487315000049, 30.531901250000033],
            [73.284727181000051, 30.531478646000039],
            [73.284727150000037, 30.531478380000067],
            [73.284126980000053, 30.526376939000045],
            [73.284120300000041, 30.526303405000078],
            [73.28410800000006, 30.526168000000041],
            [73.287852398000041, 30.520083243000045],
            [73.288330078000058, 30.519287109000061],
            [73.289979885000037, 30.518667118000053],
            [73.290135732000067, 30.518608551000057],
            [73.297820000000058, 30.515620000000069],
            [73.301718528000038, 30.515620000000069],
            [73.30256600000007, 30.515620000000069],
            [73.302661373000035, 30.515686035000044],
            [73.303312136000045, 30.516136614000061],
            [73.30942200000004, 30.520367000000078],
            [73.309452960000044, 30.520413435000023],
            [73.314696000000083, 30.52827700000006],
            [73.321025000000077, 30.52247600000004],
            [73.323357247000047, 30.521642987000064],
            [73.328408000000081, 30.519839000000047],
            [73.330716688000052, 30.515551794000032],
            [73.332047341000077, 30.513080787000035],
            [73.332100000000082, 30.512983000000077],
            [73.327130452000063, 30.508510972000067],
            [73.326904297000056, 30.508300781000059],
            [73.326904297000056, 30.501525879000042],
            [73.327880859000061, 30.496704102000024],
            [73.330305217000046, 30.496141304000048],
            [73.334716797000056, 30.495117187000062],
            [73.335429882000028, 30.496992911000063],
            [73.337524414000029, 30.502502441000047],
            [73.341755403000036, 30.505937009000036],
            [73.34215762000008, 30.506263514000068],
            [73.342647000000056, 30.506655000000023],
            [73.34264990500003, 30.506663134000064],
            [73.342712402000075, 30.50671386700003],
            [73.344242881000071, 30.511123103000045],
            [73.345049483000082, 30.513381405000075],
            [73.345284000000049, 30.514038000000028],
            [73.345284000000049, 30.520873510000058],
            [73.345284000000049, 30.520894000000055],
            [73.346024015000069, 30.520826772000078],
            [73.351085000000069, 30.520367000000078],
            [73.360578000000032, 30.518784000000039],
            [73.361595171000033, 30.519292585000073],
            [73.362807018000069, 30.517962541000031],
            [73.364739406000069, 30.512835074000066],
            [73.365905762000068, 30.514892578000058],
            [73.372680664000029, 30.516296387000068],
            [73.378924998000059, 30.513634868000054],
            [73.378927890000057, 30.513481611000032],
            [73.38009100000005, 30.512983000000077],
            [73.382468627000037, 30.511926305000031],
            [73.38958400000007, 30.508764000000042],
            [73.395073840000066, 30.507848882000076],
            [73.395893406000084, 30.507712266000055],
            [73.395913000000064, 30.507709000000034],
            [73.400659000000076, 30.512983000000077],
            [73.400619784000071, 30.513036920000047],
            [73.40069580100004, 30.513122559000067],
            [73.399299709000047, 30.515044714000055],
            [73.396484375000057, 30.518920898000033],
            [73.396087464000061, 30.523723518000054],
            [73.395913000000064, 30.526168000000041],
            [73.401714000000084, 30.531442000000027],
            [73.405747272000042, 30.529531227000064],
            [73.411682129000042, 30.526672363000046],
            [73.411676750000083, 30.52672212300007],
            [73.411734000000081, 30.526695000000075],
            [73.41106534100004, 30.532377653000026],
            [73.410705827000072, 30.535703152000053],
            [73.410778021000056, 30.535816586000067],
            [73.414371000000074, 30.541462000000024],
            [73.414371000000074, 30.545639860000051],
            [73.414371000000074, 30.546736000000067],
            [73.414489746000072, 30.546730056000058],
            [73.417349867000041, 30.546586887000046],
            [73.424919000000045, 30.546208000000036],
            [73.424833483000043, 30.546330161000071],
            [73.424926758000083, 30.546325684000067],
            [73.424083633000066, 30.547540355000024],
            [73.421325684000067, 30.551513672000056],
            [73.422302246000072, 30.559509277000075],
            [73.422302246000072, 30.565204123000058],
            [73.425446000000079, 30.566776000000061],
            [73.430185908000055, 30.569541009000034],
            [73.431775000000073, 30.570468000000062],
            [73.431861463000075, 30.57066257300005],
            [73.433412836000059, 30.574153713000044],
            [73.433517684000037, 30.57438965800003],
            [73.433884000000035, 30.575214000000074],
            [73.433884000000035, 30.575281651000068],
            [73.43389892600004, 30.575317383000026],
            [73.43389892600004, 30.583129883000026],
            [73.440263662000064, 30.589434003000065],
            [73.446297066000056, 30.587171655000077],
            [73.452870000000075, 30.584707000000037],
            [73.452864629000032, 30.584723109000038],
            [73.452142259000084, 30.586889535000068],
            [73.451815000000067, 30.587871000000064],
            [73.451288000000034, 30.595782000000042],
            [73.451302097000053, 30.595779436000043],
            [73.451293945000032, 30.595886230000076],
            [73.457092285000044, 30.594726562000062],
            [73.463057860000049, 30.591962028000069],
            [73.463075314000037, 30.591891566000072],
            [73.46710900000005, 30.589981000000023],
            [73.467063660000065, 30.590105682000058],
            [73.46710205100004, 30.590087891000053],
            [73.465643168000042, 30.594105103000061],
            [73.462890625000057, 30.601684570000032],
            [73.462890625000057, 30.603088379000042],
            [73.46993408000003, 30.608796006000034],
            [73.47047062300004, 30.609230791000073],
            [73.473438000000044, 30.611603000000059],
            [73.481651192000072, 30.608010337000053],
            [73.481728002000068, 30.607976738000048],
            [73.481876000000057, 30.607912000000056],
            [73.482640829000047, 30.606558823000057],
            [73.488727658000073, 30.595789682000031],
            [73.489822816000071, 30.590271772000051],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "116",
      properties: { name: "Rahim Yar Khan", count: 1080 },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [69.553256545000067, 28.132983241000034],
              [69.55126591800007, 28.129959333000045],
              [69.537613800000031, 28.129995389000044],
              [69.530528867000044, 28.13429446400005],
              [69.524996810000061, 28.137651267000024],
              [69.524787353000079, 28.137778364000042],
              [69.524117688000047, 28.144516940000074],
              [69.522688221000067, 28.15890111300007],
              [69.522317412000064, 28.162632410000072],
              [69.521925705000058, 28.166574002000061],
              [69.520807712000078, 28.17782393300007],
              [69.514725421000037, 28.182735816000047],
              [69.51218625000007, 28.184786377000023],
              [69.504125114000033, 28.19129631800007],
              [69.499246514000049, 28.195236135000073],
              [69.493422283000029, 28.199939617000041],
              [69.488719007000043, 28.203737846000024],
              [69.477559000000042, 28.205031000000076],
              [69.475800425000045, 28.206866112000057],
              [69.465749000000073, 28.217355000000055],
              [69.476018000000067, 28.231732000000079],
              [69.475931224000078, 28.237898894000068],
              [69.475874315000056, 28.241943150000054],
              [69.475505000000055, 28.268189000000064],
              [69.480731504000062, 28.264958084000057],
              [69.486126821000028, 28.261622811000052],
              [69.503746000000035, 28.25073100000003],
              [69.515043000000048, 28.242515000000026],
              [69.520476435000035, 28.230590936000056],
              [69.525773122000032, 28.221720816000072],
              [69.527115207000065, 28.220704351000052],
              [69.530981187000066, 28.217776344000072],
              [69.534063217000039, 28.215442084000074],
              [69.53603944200006, 28.213945335000062],
              [69.543849987000044, 28.213924587000065],
              [69.552676741000084, 28.213901140000075],
              [69.560383349000062, 28.206131347000053],
              [69.574068544000056, 28.202153322000072],
              [69.578318514000046, 28.200917937000042],
              [69.575751896000043, 28.195181725000054],
              [69.571969509000041, 28.18672835700005],
              [69.560510588000056, 28.16996961500007],
              [69.560601707000046, 28.144141077000029],
              [69.55497507900003, 28.135593820000054],
              [69.553256545000067, 28.132983241000034],
            ],
          ],
          [
            [
              [69.580254000000082, 28.365236000000039],
              [69.582308000000069, 28.355480000000057],
              [69.582429291000039, 28.355491027000028],
              [69.604437456000028, 28.35749185700007],
              [69.604901000000041, 28.357534000000044],
              [69.607293648000052, 28.357268174000069],
              [69.628089272000068, 28.354957764000062],
              [69.637250000000051, 28.353940000000023],
              [69.647006000000033, 28.34726500000005],
              [69.66857200000004, 28.335455000000024],
              [69.669468682000058, 28.33409335500005],
              [69.676147941000067, 28.323950651000075],
              [69.682436000000052, 28.31440200000003],
              [69.694760000000031, 28.300538000000074],
              [69.709741055000052, 28.294545912000046],
              [69.712731000000076, 28.293350000000032],
              [69.713618763000056, 28.294092784000043],
              [69.737892000000045, 28.31440200000003],
              [69.747632058000079, 28.313875469000038],
              [69.746986845000038, 28.310386418000064],
              [69.745356384000047, 28.301569546000053],
              [69.744331789000057, 28.296028952000029],
              [69.74387523300004, 28.292234685000039],
              [69.743154939000078, 28.286248583000031],
              [69.741846342000031, 28.275373307000052],
              [69.741849271000035, 28.274549147000073],
              [69.741865927000049, 28.269862437000029],
              [69.741899035000074, 28.260546213000055],
              [69.741914634000068, 28.256156934000046],
              [69.741924366000035, 28.253418684000053],
              [69.741465119000054, 28.25309827600006],
              [69.741347077000057, 28.253015920000053],
              [69.737497141000063, 28.250329885000042],
              [69.736391276000063, 28.249558343000047],
              [69.725332009000056, 28.241842482000038],
              [69.724733960000037, 28.242076000000054],
              [69.708675959000061, 28.24834609800007],
              [69.704472538000061, 28.252585148000037],
              [69.693266569000059, 28.263886100000036],
              [69.692738731000077, 28.263764514000059],
              [69.68550950000008, 28.262099271000068],
              [69.680028315000072, 28.258856656000034],
              [69.676557290000062, 28.256803232000038],
              [69.673154882000063, 28.254790401000037],
              [69.668994860000055, 28.252329374000055],
              [69.667114097000081, 28.248125725000079],
              [69.666400112000076, 28.246529914000064],
              [69.662646976000076, 28.238141368000072],
              [69.642248833000053, 28.230239509000057],
              [69.639560059000075, 28.230532340000025],
              [69.633579481000083, 28.231183679000026],
              [69.633566482000049, 28.231185094000068],
              [69.628119043000083, 28.23177836900004],
              [69.603804788000048, 28.23184425900007],
              [69.594118000000037, 28.256379000000038],
              [69.593735170000059, 28.264293130000055],
              [69.593734415000029, 28.264308751000044],
              [69.592627388000039, 28.287194016000058],
              [69.59257800000006, 28.288215000000037],
              [69.576660000000061, 28.312348000000043],
              [69.572727604000079, 28.319384876000072],
              [69.566904000000079, 28.329806000000076],
              [69.562314743000059, 28.340064926000025],
              [69.558175000000062, 28.349319000000037],
              [69.554899379000062, 28.353651101000025],
              [69.542257000000063, 28.370371000000034],
              [69.540658415000053, 28.37332830500003],
              [69.540627326000049, 28.373385817000042],
              [69.535927969000056, 28.382079399000077],
              [69.535389945000077, 28.383074717000056],
              [69.531987000000072, 28.389370000000042],
              [69.532575448000046, 28.389817215000051],
              [69.544824000000062, 28.399126000000024],
              [69.556634000000031, 28.399126000000024],
              [69.569471000000078, 28.383208000000025],
              [69.580254000000082, 28.365236000000039],
            ],
          ],
          [
            [
              [70.897733000000073, 29.252636000000052],
              [70.906482978000042, 29.247570223000025],
              [70.906483023000078, 29.247570545000031],
              [70.910970000000077, 29.244973000000073],
              [70.914035000000069, 29.243198000000064],
              [70.913888969000084, 29.241372613000067],
              [70.912425299000063, 29.223076733000028],
              [70.912319000000082, 29.221748000000048],
              [70.899010084000054, 29.203448241000046],
              [70.886210263000066, 29.185848486000054],
              [70.884863000000053, 29.183996000000036],
              [70.887402812000062, 29.181773665000037],
              [70.89172700000006, 29.177990000000079],
              [70.895395488000077, 29.180191093000076],
              [70.90888700000005, 29.188286000000062],
              [70.912451630000078, 29.17861057600004],
              [70.914893000000063, 29.171984000000066],
              [70.919008541000039, 29.167626368000072],
              [70.923285857000053, 29.163097446000052],
              [70.929479000000072, 29.156540000000064],
              [70.929987252000046, 29.153151655000045],
              [70.930733907000047, 29.148173953000025],
              [70.932053000000053, 29.139380000000074],
              [70.932816249000041, 29.138330533000044],
              [70.936820958000055, 29.13282405700005],
              [70.945781000000068, 29.120504000000039],
              [70.949646281000071, 29.11617035300003],
              [70.950476305000052, 29.115239753000026],
              [70.959319802000039, 29.105324668000037],
              [70.974096000000031, 29.088758000000041],
              [70.981663138000044, 29.084343836000073],
              [70.987990041000046, 29.080653143000063],
              [70.994687226000053, 29.076746452000066],
              [71.00584200000003, 29.099054000000024],
              [71.005882601000053, 29.099169037000024],
              [71.01301750500005, 29.119384598000067],
              [71.014360633000081, 29.123190126000054],
              [71.016138000000069, 29.128226000000041],
              [71.028810830000054, 29.137974330000077],
              [71.029174604000048, 29.138254156000073],
              [71.037525468000069, 29.144677899000044],
              [71.038446000000079, 29.14538600000003],
              [71.042708162000054, 29.147517081000046],
              [71.056730816000083, 29.154528408000033],
              [71.060754000000031, 29.156540000000064],
              [71.074482000000046, 29.153108000000032],
              [71.076123132000077, 29.151056586000038],
              [71.080931960000044, 29.145045550000077],
              [71.081346000000053, 29.144528000000037],
              [71.076198000000034, 29.135090000000048],
              [71.07644865900005, 29.131580779000046],
              [71.076716715000032, 29.127827997000054],
              [71.077056000000084, 29.123078000000078],
              [71.079604000000074, 29.110128000000032],
              [71.081933388000039, 29.098286245000054],
              [71.082474578000074, 29.095535033000033],
              [71.086410890000082, 29.07552425800003],
              [71.086463894000076, 29.075254806000032],
              [71.086574045000077, 29.074694840000063],
              [71.087352000000067, 29.070740000000058],
              [71.085726312000077, 29.069520682000075],
              [71.078602065000041, 29.064177266000058],
              [71.05988174600003, 29.050136420000058],
              [71.056464000000062, 29.047573000000057],
              [71.056850654000073, 29.035973375000026],
              [71.056922121000071, 29.033829371000024],
              [71.056984830000033, 29.031948095000075],
              [71.057322000000056, 29.021833000000072],
              [71.055863215000045, 29.020631648000062],
              [71.042736000000048, 29.009821000000045],
              [71.03587200000004, 29.004673000000025],
              [71.035931303000041, 29.00463675900005],
              [71.04278245900008, 29.000449942000046],
              [71.051316000000043, 28.995235000000037],
              [71.05731518500005, 28.988235951000036],
              [71.057396573000062, 28.988140998000063],
              [71.061612000000082, 28.983223000000066],
              [71.058926036000059, 28.976508090000038],
              [71.058260211000061, 28.974843528000065],
              [71.054748000000075, 28.966063000000076],
              [71.054121852000037, 28.960114590000046],
              [71.053443506000065, 28.953670301000045],
              [71.053416049000077, 28.953409458000067],
              [71.053055166000036, 28.94998107400005],
              [71.05303200000003, 28.949761000000024],
              [71.05339120800005, 28.943654468000034],
              [71.053890000000081, 28.935175000000072],
              [71.052604784000039, 28.932090483000025],
              [71.049600000000055, 28.924879000000033],
              [71.04102000000006, 28.923163000000045],
              [71.04220943200005, 28.920338099000048],
              [71.047884000000067, 28.906861000000049],
              [71.051840786000071, 28.904223143000024],
              [71.053790391000064, 28.90292340600007],
              [71.060754000000031, 28.898281000000054],
              [71.056464000000062, 28.878547000000026],
              [71.055606000000068, 28.853665000000035],
              [71.051004387000035, 28.839348573000052],
              [71.041369520000046, 28.809372808000035],
              [71.040162000000066, 28.805616000000043],
              [71.025576000000058, 28.78245000000004],
              [71.024473614000044, 28.779877766000027],
              [71.019961764000072, 28.769350115000066],
              [71.015280000000075, 28.758426000000043],
              [71.008809984000038, 28.747334545000058],
              [70.997262000000035, 28.727538000000038],
              [70.983897612000078, 28.698581827000055],
              [70.981818000000032, 28.694076000000052],
              [70.969806000000062, 28.652034000000072],
              [70.96637400000003, 28.627152000000024],
              [70.955219000000056, 28.59368900000004],
              [70.942349000000036, 28.547357000000034],
              [70.948355000000049, 28.500167000000033],
              [70.955219000000056, 28.44782900000007],
              [70.965515000000039, 28.393775000000062],
              [70.971521000000052, 28.351732000000027],
              [70.968947000000071, 28.325134000000048],
              [70.966373000000033, 28.294246000000044],
              [70.966373000000033, 28.272796000000028],
              [70.97323700000004, 28.241908000000024],
              [70.979243000000054, 28.211020000000076],
              [70.992971000000068, 28.180132000000071],
              [71.004126000000042, 28.158682000000056],
              [71.021286000000032, 28.13894700000003],
              [71.034156000000053, 28.114065000000039],
              [71.034156000000053, 28.089183000000048],
              [71.05818000000005, 28.022259000000076],
              [71.069334000000083, 27.97592700000007],
              [71.071908000000064, 27.949329000000034],
              [71.088210000000061, 27.914150000000063],
              [71.089926000000048, 27.886694000000034],
              [71.09335800000008, 27.861812000000043],
              [71.101080000000081, 27.823202000000038],
              [71.108518000000061, 27.795375000000035],
              [71.108518746000072, 27.794864567000047],
              [71.108520011000053, 27.793998871000042],
              [71.095462000000055, 27.788604000000078],
              [71.071939000000043, 27.777536000000055],
              [71.050083000000029, 27.768121000000065],
              [71.02822800000007, 27.759153000000026],
              [71.014001000000064, 27.753773000000024],
              [70.999178000000029, 27.747942000000023],
              [70.980365000000063, 27.738067000000058],
              [70.963592000000062, 27.728192000000035],
              [70.949369000000047, 27.727300000000071],
              [70.905211000000065, 27.707093000000043],
              [70.872718000000077, 27.704243000000076],
              [70.851371000000029, 27.706935000000044],
              [70.809690000000046, 27.713207000000068],
              [70.758915000000059, 27.719003000000043],
              [70.74875000000003, 27.732467000000042],
              [70.737558000000035, 27.740987000000075],
              [70.732962000000043, 27.75741800000003],
              [70.722267000000045, 27.771790000000067],
              [70.711071000000061, 27.788389000000052],
              [70.69360000000006, 27.81378600000005],
              [70.68270300000006, 27.828429000000028],
              [70.680630000000065, 27.845056000000056],
              [70.677524000000062, 27.867077000000052],
              [70.675954000000047, 27.893113000000028],
              [70.676413000000082, 27.921856000000048],
              [70.664671000000055, 27.932631000000072],
              [70.650418000000059, 27.945355000000063],
              [70.638348000000065, 27.957322000000033],
              [70.62395900000007, 27.969215000000077],
              [70.60824400000007, 27.985180000000071],
              [70.593848000000037, 27.99706800000007],
              [70.588007000000061, 28.00940600000007],
              [70.568119000000081, 28.019247000000064],
              [70.550778000000037, 28.025051000000076],
              [70.542109000000039, 28.02772600000003],
              [70.532932000000073, 28.029950000000042],
              [70.517590000000041, 28.032192000000066],
              [70.502838000000054, 28.035753000000057],
              [70.45853900000003, 28.023468000000037],
              [70.437647000000084, 28.018898000000036],
              [70.395384000000035, 28.013798000000065],
              [70.373107000000061, 28.010457000000031],
              [70.361925000000042, 27.99914700000005],
              [70.35022900000007, 27.989186000000075],
              [70.338541000000077, 27.97607000000005],
              [70.319745000000069, 27.958425000000034],
              [70.298518000000058, 27.935410000000047],
              [70.272614000000033, 27.923595000000034],
              [70.256687000000056, 27.915409000000068],
              [70.235564000000068, 27.905879000000027],
              [70.223643000000038, 27.897886000000028],
              [70.216860000000054, 27.889568000000054],
              [70.202701000000047, 27.871042000000045],
              [70.188018000000056, 27.857018000000039],
              [70.180272419000062, 27.849098656000024],
              [70.177409000000068, 27.846171000000027],
              [70.177194410000084, 27.846447370000078],
              [70.177147299000069, 27.846508045000064],
              [70.160879000000079, 27.867460000000051],
              [70.145557000000053, 27.877879000000064],
              [70.114301000000069, 27.885233000000028],
              [70.09407600000003, 27.889523000000054],
              [70.074465000000032, 27.895039000000054],
              [70.056079000000068, 27.904232000000036],
              [70.041370000000029, 27.920779000000039],
              [70.028500000000065, 27.934262000000047],
              [70.007662000000039, 27.935488000000078],
              [69.994179000000031, 27.939778000000047],
              [69.983147000000031, 27.955100000000073],
              [69.977545000000077, 27.963784000000032],
              [69.970890000000054, 27.974099000000024],
              [69.96494574500008, 27.984158796000031],
              [69.962923000000046, 27.987582000000032],
              [69.962179258000049, 27.988373199000023],
              [69.936679710000078, 28.015499832000046],
              [69.934118000000069, 28.018225000000029],
              [69.925492010000028, 28.026483850000034],
              [69.90864463500003, 28.042614165000032],
              [69.905313000000035, 28.045804000000032],
              [69.902809036000065, 28.049032843000077],
              [69.899609278000071, 28.053158909000047],
              [69.896109229000047, 28.057672198000034],
              [69.882024000000058, 28.075835000000041],
              [69.879660876000059, 28.07951121900004],
              [69.872154186000046, 28.091189080000049],
              [69.870233644000052, 28.094176791000052],
              [69.866671318000044, 28.099718562000078],
              [69.859961000000055, 28.110155000000077],
              [69.856055212000058, 28.116754601000025],
              [69.847983585000065, 28.130393211000069],
              [69.842188000000078, 28.140186000000028],
              [69.842453513000066, 28.143194430000051],
              [69.84402700000004, 28.161023000000057],
              [69.840337451000039, 28.16605385500003],
              [69.837285000000065, 28.170216000000039],
              [69.837285000000065, 28.181861000000026],
              [69.849459757000034, 28.190383197000074],
              [69.851068244000032, 28.191509120000035],
              [69.855671000000029, 28.194731000000047],
              [69.850474021000082, 28.204604327000027],
              [69.844700727000031, 28.216343339000048],
              [69.844700683000042, 28.216343428000073],
              [69.841435698000055, 28.223066138000036],
              [69.839124000000083, 28.22782600000005],
              [69.829765513000041, 28.242902904000061],
              [69.828092000000083, 28.245599000000027],
              [69.828973535000046, 28.254856732000064],
              [69.830090953000081, 28.266591671000072],
              [69.830248509000057, 28.268246300000044],
              [69.830543000000034, 28.271339000000069],
              [69.825947193000047, 28.272399837000023],
              [69.819465969000078, 28.273895880000055],
              [69.814609000000075, 28.275017000000048],
              [69.810932000000037, 28.288500000000056],
              [69.81037733800008, 28.29903630900003],
              [69.809967493000045, 28.306821691000039],
              [69.809706000000062, 28.311789000000033],
              [69.812146253000037, 28.322526942000025],
              [69.81583500000005, 28.338755000000049],
              [69.811545000000081, 28.361431000000039],
              [69.803661490000081, 28.364335273000052],
              [69.799900000000036, 28.365721000000065],
              [69.788869000000034, 28.374914000000047],
              [69.783624915000075, 28.378847015000076],
              [69.773174033000032, 28.386685080000063],
              [69.761902000000077, 28.395139000000029],
              [69.75076604000003, 28.40512396400004],
              [69.750766000000056, 28.405124000000058],
              [69.767194532000076, 28.403776935000053],
              [69.774113169000032, 28.403209638000078],
              [69.776865000000043, 28.40298400000006],
              [69.78338860100007, 28.402549348000036],
              [69.790523000000064, 28.402074000000027],
              [69.801947667000036, 28.39717809800004],
              [69.803271000000052, 28.396611000000064],
              [69.81647300000003, 28.405261000000053],
              [69.818943964000084, 28.409708518000059],
              [69.825579000000062, 28.421651000000054],
              [69.829617309000071, 28.425285256000052],
              [69.834684000000038, 28.429845000000057],
              [69.837050126000065, 28.429529528000046],
              [69.842681853000045, 28.428778659000045],
              [69.848342000000059, 28.42802400000005],
              [69.849231590000045, 28.428509295000026],
              [69.858358000000067, 28.433488000000068],
              [69.854600839000057, 28.440675800000065],
              [69.847887000000071, 28.453520000000026],
              [69.852440000000058, 28.463991000000078],
              [69.863017263000074, 28.459213881000039],
              [69.866553000000067, 28.457617000000027],
              [69.870495156000061, 28.451704006000057],
              [69.874748000000068, 28.445325000000025],
              [69.876328974000046, 28.444020703000035],
              [69.878560588000084, 28.442179631000045],
              [69.881265542000051, 28.439948055000059],
              [69.881399280000039, 28.43983772100006],
              [69.884804520000046, 28.437028412000075],
              [69.892959000000076, 28.430301000000043],
              [69.898325682000063, 28.430881156000055],
              [69.90192993800008, 28.431270788000063],
              [69.909804000000065, 28.432122000000049],
              [69.911212094000064, 28.432598581000036],
              [69.919956693000074, 28.435558264000065],
              [69.926124583000046, 28.437645839000027],
              [69.931372066000051, 28.439421894000077],
              [69.931986077000033, 28.439629710000077],
              [69.933424451000064, 28.440116541000066],
              [69.939397000000042, 28.442138000000057],
              [69.947229531000062, 28.448127345000046],
              [69.94989574300007, 28.45016613100006],
              [69.953060964000031, 28.452586498000073],
              [69.956763054000078, 28.45541739600003],
              [69.962616000000082, 28.459893000000079],
              [69.962863904000073, 28.465781031000063],
              [69.963102595000066, 28.471450237000056],
              [69.963406056000053, 28.478657803000033],
              [69.963736966000056, 28.486517316000061],
              [69.963879367000061, 28.489899507000075],
              [69.964167975000066, 28.496754313000054],
              [69.964437000000032, 28.503144000000077],
              [69.96924372400008, 28.506487957000047],
              [69.971375730000034, 28.507971158000032],
              [69.976906786000029, 28.511819022000054],
              [69.982004551000045, 28.515365452000026],
              [69.98537900000008, 28.517713000000072],
              [69.994029000000069, 28.51816800000006],
              [69.996380927000075, 28.526005011000052],
              [69.996840403000078, 28.527536062000024],
              [69.998627396000074, 28.533496436000064],
              [69.998628002000032, 28.533498457000064],
              [70.000858000000051, 28.540932000000055],
              [70.00177308800005, 28.541367674000071],
              [70.004945864000035, 28.542878235000046],
              [70.01041900000007, 28.545484000000044],
              [70.018716590000054, 28.541137540000079],
              [70.025360395000064, 28.537657369000044],
              [70.029540000000054, 28.535468000000037],
              [70.040467000000035, 28.528184000000067],
              [70.04236644100007, 28.528330185000073],
              [70.042366538000067, 28.528330192000055],
              [70.052304000000049, 28.529095000000041],
              [70.070504000000028, 28.55275400000005],
              [70.070515000000057, 28.552769000000069],
              [70.074247443000047, 28.562473134000072],
              [70.077344000000039, 28.570524000000034],
              [70.083640621000029, 28.576820903000055],
              [70.086945976000038, 28.580126407000023],
              [70.090870446000054, 28.584047701000031],
              [70.09086830900003, 28.584048915000039],
              [70.096846680000056, 28.590027554000073],
              [70.09887172100008, 28.592052686000045],
              [70.099652000000049, 28.592833000000041],
              [70.113481858000057, 28.600839466000025],
              [70.11695300000008, 28.602849000000049],
              [70.116951879000055, 28.602850588000024],
              [70.116954574000033, 28.602852148000068],
              [70.111490574000072, 28.610591148000026],
              [70.103504206000082, 28.611755865000077],
              [70.101037833000078, 28.612115556000049],
              [70.089637574000051, 28.613778148000051],
              [70.076434574000075, 28.623794148000059],
              [70.076693558000045, 28.628582939000069],
              [70.076888155000063, 28.632181168000045],
              [70.077023632000078, 28.634686219000059],
              [70.077121979000083, 28.636504717000037],
              [70.077304226000081, 28.639874581000072],
              [70.077704500000038, 28.640457352000055],
              [70.078887258000066, 28.642179368000029],
              [70.090584360000037, 28.653877298000054],
              [70.091457000000048, 28.654750000000035],
              [70.099555168000052, 28.659678967000048],
              [70.112400000000036, 28.667497000000026],
              [70.122054020000064, 28.668784156000072],
              [70.140841839000075, 28.671288983000068],
              [70.142087967000066, 28.671455154000057],
              [70.146572597000045, 28.672053180000034],
              [70.153374000000042, 28.672960000000046],
              [70.154705248000084, 28.672946695000064],
              [70.172715373000074, 28.672766701000057],
              [70.179933684000048, 28.672694561000071],
              [70.182032846000084, 28.672673582000073],
              [70.195274364000056, 28.672699503000047],
              [70.198901000000035, 28.672505000000058],
              [70.202157258000057, 28.673491804000037],
              [70.209253308000029, 28.675642251000056],
              [70.211841567000079, 28.67642661900004],
              [70.213925000000074, 28.677058000000045],
              [70.21451640500004, 28.678129910000052],
              [70.215089305000049, 28.679168278000077],
              [70.21813230500004, 28.684683651000057],
              [70.223108258000082, 28.694606771000053],
              [70.228494000000069, 28.703464000000054],
              [70.227214466000078, 28.707893504000026],
              [70.226068080000061, 28.711862078000024],
              [70.224437035000051, 28.717508448000046],
              [70.223290132000045, 28.721478810000065],
              [70.223176537000029, 28.721872054000073],
              [70.222576000000061, 28.723951000000056],
              [70.214460587000076, 28.730563485000062],
              [70.210146241000075, 28.734078840000052],
              [70.208601406000071, 28.735337580000078],
              [70.205188260000057, 28.738118631000077],
              [70.197991000000059, 28.743983000000071],
              [70.212132230000066, 28.748225431000037],
              [70.212132428000075, 28.748225212000079],
              [70.214715356000056, 28.749000380000041],
              [70.217032409000069, 28.749695506000023],
              [70.220754000000056, 28.750812000000053],
              [70.22090313700005, 28.751296584000045],
              [70.222169932000043, 28.75541271000003],
              [70.223026913000069, 28.75819725100007],
              [70.223693947000072, 28.760364608000032],
              [70.224397000000067, 28.762649000000067],
              [70.228081438000061, 28.764952515000061],
              [70.229746447000082, 28.765992527000037],
              [70.232150595000064, 28.767495174000032],
              [70.235323000000051, 28.769478000000049],
              [70.240212046000067, 28.764728860000048],
              [70.245485254000073, 28.759606552000037],
              [70.24674354800004, 28.758384265000075],
              [70.251258000000064, 28.753999000000078],
              [70.26446100000004, 28.758551000000068],
              [70.274491006000062, 28.756370855000057],
              [70.285403000000031, 28.753999000000078],
              [70.291203290000055, 28.754661838000061],
              [70.294009204000076, 28.754982489000042],
              [70.29747509200007, 28.755378559000064],
              [70.300534025000047, 28.755728124000029],
              [70.301198313000043, 28.755804037000075],
              [70.301338000000044, 28.755820000000028],
              [70.307467442000075, 28.760470242000054],
              [70.309118850000061, 28.761723120000056],
              [70.309202139000035, 28.761786309000058],
              [70.311113800000044, 28.763236635000055],
              [70.312675987000034, 28.764421824000067],
              [70.314540000000079, 28.765836000000036],
              [70.308715524000036, 28.775328020000075],
              [70.305075807000037, 28.781259586000033],
              [70.302248000000077, 28.78586800000005],
              [70.294744648000062, 28.793894521000027],
              [70.290949495000064, 28.797954290000064],
              [70.28847122500008, 28.800605356000062],
              [70.28267100000005, 28.806810000000041],
              [70.285858000000076, 28.819558000000029],
              [70.300163969000039, 28.825348917000042],
              [70.304977956000073, 28.827296826000065],
              [70.304979919000061, 28.827296967000052],
              [70.304982073000076, 28.827297122000061],
              [70.311186557000042, 28.827743455000075],
              [70.328173132000074, 28.828661478000072],
              [70.328197017000036, 28.828662769000061],
              [70.328264923000063, 28.828640449000034],
              [70.336167713000066, 28.826042883000071],
              [70.345553876000054, 28.822816007000029],
              [70.347891240000081, 28.819953722000037],
              [70.362008408000065, 28.802666149000061],
              [70.362342039000055, 28.802257592000046],
              [70.367295672000068, 28.797303931000044],
              [70.373724046000063, 28.790875519000053],
              [70.373725553000043, 28.790876447000073],
              [70.373726000000033, 28.790876000000026],
              [70.385563000000047, 28.798160000000053],
              [70.394668000000081, 28.824566000000061],
              [70.39574960200008, 28.826479569000071],
              [70.406505000000038, 28.845508000000052],
              [70.40673600000008, 28.84703200000007],
              [70.408782000000031, 28.860532000000035],
              [70.401497000000063, 28.874646000000041],
              [70.399451376000059, 28.876038684000036],
              [70.399322118000043, 28.876126684000042],
              [70.393564197000046, 28.880046741000058],
              [70.38009900000003, 28.889214000000038],
              [70.359157000000039, 28.90651500000007],
              [70.340946000000031, 28.923815000000047],
              [70.35050700000005, 28.937018000000023],
              [70.37281500000006, 28.947489000000076],
              [70.394211577000078, 28.948854909000033],
              [70.409704020000049, 28.944613023000045],
              [70.426241205000053, 28.940085083000042],
              [70.432454606000078, 28.938383831000067],
              [70.450209672000028, 28.939749801000062],
              [70.450209852000057, 28.939749912000025],
              [70.450211000000081, 28.93975000000006],
              [70.460747606000041, 28.946233791000054],
              [70.462048000000038, 28.947034000000031],
              [70.467400915000042, 28.953279176000024],
              [70.470242860000042, 28.956594836000079],
              [70.487086848000047, 28.957049779000045],
              [70.487086781000073, 28.957049967000046],
              [70.487088000000028, 28.957050000000038],
              [70.486838960000057, 28.957744693000052],
              [70.481455491000077, 28.972836353000048],
              [70.478472585000077, 28.981198434000078],
              [70.47888867100005, 28.984943379000072],
              [70.479880491000074, 28.993870164000043],
              [70.479890156000067, 28.993957155000032],
              [70.479897279000056, 28.994021265000072],
              [70.481142381000041, 28.994457058000023],
              [70.489802380000071, 28.997488109000074],
              [70.490464431000078, 28.99771983100004],
              [70.49810836100005, 29.000395252000033],
              [70.513587423000047, 29.000395229000048],
              [70.513680285000078, 29.000415306000036],
              [70.51580225400005, 29.000874085000078],
              [70.519054863000065, 29.001577314000031],
              [70.523051518000045, 29.002441410000074],
              [70.52920368000008, 29.003776992000041],
              [70.530399114000033, 29.004036510000049],
              [70.530521634000081, 29.004227097000069],
              [70.53809700000005, 29.016011000000049],
              [70.538319737000052, 29.016184225000075],
              [70.538501446000055, 29.016463689000034],
              [70.543435084000066, 29.020563035000066],
              [70.558822000000077, 29.032129000000054],
              [70.579414000000043, 29.038993000000062],
              [70.579980941000031, 29.039163743000074],
              [70.586253179000039, 29.04105271800006],
              [70.600006000000064, 29.044999000000075],
              [70.602363000000082, 29.047020000000032],
              [70.604996634000031, 29.049277206000056],
              [70.612018000000035, 29.055295000000058],
              [70.612018000000035, 29.06061070100003],
              [70.612018000000035, 29.075888000000077],
              [70.619391509000081, 29.083998860000065],
              [70.620348295000042, 29.08573178000006],
              [70.629266869000048, 29.086270280000065],
              [70.642048000000045, 29.087042000000054],
              [70.651736523000068, 29.08930265500004],
              [70.66778800000003, 29.093048000000067],
              [70.675787600000035, 29.100577035000072],
              [70.681053973000076, 29.105533621000063],
              [70.682374000000038, 29.106776000000025],
              [70.685625653000045, 29.119060023000031],
              [70.69009600000004, 29.135948000000042],
              [70.697265261000041, 29.145225868000068],
              [70.697227822000059, 29.14523276400007],
              [70.704901755000037, 29.155223291000027],
              [70.708273872000063, 29.159134247000054],
              [70.713262000000043, 29.165120000000059],
              [70.727848000000051, 29.165120000000059],
              [70.740271394000047, 29.160979137000027],
              [70.740271396000082, 29.160978868000029],
              [70.740367669000079, 29.160946777000049],
              [70.743292000000054, 29.159972000000039],
              [70.745359903000065, 29.163073629000053],
              [70.745359816000075, 29.163072225000064],
              [70.751249303000066, 29.171906454000066],
              [70.756502332000082, 29.179785998000057],
              [70.757021000000066, 29.180564000000061],
              [70.758190054000067, 29.183570139000039],
              [70.769033000000036, 29.211452000000065],
              [70.769837780000046, 29.213966936000077],
              [70.773180477000039, 29.224412865000033],
              [70.774023407000072, 29.227047023000068],
              [70.775897000000043, 29.232902000000024],
              [70.786002265000036, 29.252508902000045],
              [70.803353000000072, 29.249204000000077],
              [70.813232833000029, 29.244066487000055],
              [70.824803000000031, 29.238050000000044],
              [70.832614620000072, 29.239209059000075],
              [70.835112967000043, 29.23952855400006],
              [70.838671232000081, 29.239983594000023],
              [70.843679000000066, 29.240624000000025],
              [70.842821000000072, 29.266364000000067],
              [70.85654900000003, 29.269796000000042],
              [70.863146805000042, 29.266872181000053],
              [70.876283000000058, 29.25950000000006],
              [70.881066360000034, 29.257969325000033],
              [70.897733000000073, 29.252636000000052],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "117",
      properties: { name: "Rajanpur", count: 709 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.957032843000036, 29.846552625000072],
            [69.968645747000039, 29.843972058000077],
            [69.985487762000048, 29.840023597000027],
            [69.990572888000031, 29.838682400000039],
            [69.996908336000047, 29.836819177000052],
            [70.008021000000042, 29.833551000000057],
            [70.016551211000035, 29.833551000000057],
            [70.031271000000061, 29.833551000000057],
            [70.036503480000079, 29.832085869000025],
            [70.047039126000072, 29.82913581400004],
            [70.049717298000076, 29.82838590700004],
            [70.053884154000059, 29.827219158000048],
            [70.065463000000079, 29.82397700000007],
            [70.073138623000034, 29.820973464000076],
            [70.080077815000038, 29.818258099000047],
            [70.083945837000044, 29.816744509000046],
            [70.08976433600003, 29.814467681000053],
            [70.096919000000071, 29.811668000000054],
            [70.100113840000063, 29.806753014000037],
            [70.104508509000084, 29.799992193000037],
            [70.109395818000053, 29.792473490000077],
            [70.11469900000003, 29.784315000000049],
            [70.119329271000083, 29.780542266000054],
            [70.12893690900006, 29.772714006000058],
            [70.137031646000082, 29.766118495000057],
            [70.143431821000036, 29.759888423000064],
            [70.14583729800006, 29.758943321000061],
            [70.147916506000058, 29.757248924000066],
            [70.151626000000078, 29.754226000000074],
            [70.154738559000066, 29.753058790000068],
            [70.163980516000038, 29.749593056000037],
            [70.174148822000063, 29.74577994200007],
            [70.184450000000083, 29.741917000000058],
            [70.196538409000084, 29.740644450000048],
            [70.204438261000064, 29.739812831000052],
            [70.217222728000081, 29.738467007000054],
            [70.22927405400003, 29.737198361000026],
            [70.236380890000078, 29.736452321000058],
            [70.242897320000054, 29.736446292000039],
            [70.251299241000083, 29.736447007000038],
            [70.259823263000044, 29.736447056000031],
            [70.265678047000051, 29.73661616000004],
            [70.269153878000054, 29.736351953000053],
            [70.272921928000073, 29.736351727000056],
            [70.276657096000065, 29.736351503000037],
            [70.280795583000042, 29.736351270000057],
            [70.284976802000074, 29.736351005000074],
            [70.289070145000039, 29.736382503000073],
            [70.295114614000056, 29.736350386000026],
            [70.299172121000083, 29.736337491000029],
            [70.303832517000046, 29.736196322000069],
            [70.308822040000052, 29.73591682700004],
            [70.313434971000049, 29.735697952000066],
            [70.315588247000051, 29.735654971000031],
            [70.319336405000058, 29.735565520000023],
            [70.321327674000031, 29.73537314500004],
            [70.323234081000066, 29.735414910000031],
            [70.32579185700007, 29.735271915000055],
            [70.330288192000069, 29.735117010000067],
            [70.333201446000032, 29.735016601000041],
            [70.338943824000069, 29.734818683000071],
            [70.340751300000079, 29.733997092000038],
            [70.343093945000078, 29.732932239000036],
            [70.346126295000033, 29.731553880000035],
            [70.349162271000068, 29.730173873000069],
            [70.351568347000068, 29.729080187000079],
            [70.354532135000056, 29.727732993000075],
            [70.356901881000056, 29.726655821000065],
            [70.359378545000084, 29.725530050000032],
            [70.361222670000075, 29.724719202000074],
            [70.364416481000035, 29.723311135000074],
            [70.366856832000053, 29.722320140000079],
            [70.369085000000041, 29.721402000000069],
            [70.372081100000059, 29.718934631000025],
            [70.374626040000066, 29.716838804000076],
            [70.377934390000064, 29.714114289000065],
            [70.379379823000079, 29.712923937000028],
            [70.38335025300006, 29.709654181000076],
            [70.385502744000064, 29.707881547000056],
            [70.38849627500008, 29.705416293000042],
            [70.390734545000043, 29.703573018000043],
            [70.392380002000039, 29.702153812000063],
            [70.394084371000076, 29.698256516000072],
            [70.395932816000084, 29.694031696000025],
            [70.396986290000029, 29.69162368800005],
            [70.398033032000058, 29.689231181000025],
            [70.399947707000081, 29.68485486700007],
            [70.401909000000046, 29.680372000000034],
            [70.396791053000072, 29.67525405300006],
            [70.390968000000044, 29.669431000000031],
            [70.400798188000067, 29.662877313000024],
            [70.404474291000042, 29.660426493000045],
            [70.407988121000074, 29.658083858000055],
            [70.411166896000054, 29.655964601000051],
            [70.419689000000062, 29.650283000000059],
            [70.421617426000068, 29.641411899000047],
            [70.422104965000074, 29.639169136000078],
            [70.422985530000062, 29.635118384000066],
            [70.423463466000044, 29.632926072000032],
            [70.424346068000034, 29.628873898000052],
            [70.425052058000063, 29.625625782000043],
            [70.425595817000044, 29.62311930900006],
            [70.426079418000029, 29.620890143000054],
            [70.426527000000078, 29.618827000000067],
            [70.429012024000031, 29.619379228000071],
            [70.432947238000054, 29.620253720000051],
            [70.434002086000078, 29.620488131000059],
            [70.437888992000069, 29.62135188700006],
            [70.441424707000067, 29.622137602000066],
            [70.446196374000067, 29.623197972000071],
            [70.448664486000041, 29.623746442000026],
            [70.450678481000068, 29.624193996000031],
            [70.453648551000072, 29.624854011000025],
            [70.455545710000081, 29.625275602000045],
            [70.45843147000005, 29.62591688200007],
            [70.461071815000082, 29.626503625000055],
            [70.464117520000059, 29.627244125000061],
            [70.467482267000037, 29.628314752000051],
            [70.471596886000043, 29.629623980000076],
            [70.475081484000043, 29.630732742000077],
            [70.478153430000077, 29.631710203000068],
            [70.480863839000051, 29.632572626000069],
            [70.484144313000058, 29.633616438000047],
            [70.486471007000034, 29.634356767000043],
            [70.490444685000057, 29.635621149000031],
            [70.493543000000045, 29.636607000000026],
            [70.496236972000077, 29.638469668000027],
            [70.50129361300003, 29.641968946000077],
            [70.50630740400004, 29.64544006400007],
            [70.51104700500008, 29.648718789000043],
            [70.514456405000033, 29.650927162000073],
            [70.516939293000064, 29.65252386800006],
            [70.520423458000039, 29.654761703000077],
            [70.525828260000083, 29.658237511000038],
            [70.530470000000037, 29.661225000000059],
            [70.532255705000068, 29.663010705000033],
            [70.536012043000028, 29.66676704300005],
            [70.541275599000073, 29.672030599000038],
            [70.544346763000078, 29.675101763000043],
            [70.54700619700003, 29.677761197000052],
            [70.549617000000069, 29.680372000000034],
            [70.550447115000054, 29.682150757000045],
            [70.552132738000068, 29.68576267900005],
            [70.553121511000029, 29.687881404000052],
            [70.555527547000054, 29.693037016000062],
            [70.556448400000079, 29.695010204000027],
            [70.559191000000055, 29.700887000000023],
            [70.561270127000057, 29.704525519000072],
            [70.563049965000062, 29.707640277000053],
            [70.564697525000042, 29.710523545000058],
            [70.568576832000076, 29.717311013000028],
            [70.570435481000061, 29.720550358000025],
            [70.571753064000063, 29.722812870000041],
            [70.574757729000055, 29.726672319000045],
            [70.575862348000044, 29.729857112000047],
            [70.576729514000078, 29.731343631000072],
            [70.579333462000079, 29.735807385000044],
            [70.579869074000044, 29.739056503000029],
            [70.580017549000047, 29.741433291000078],
            [70.580433095000046, 29.748085370000069],
            [70.580651203000059, 29.751576852000028],
            [70.580784728000083, 29.753714330000037],
            [70.580912697000031, 29.755762860000061],
            [70.581072714000072, 29.758899608000036],
            [70.58107300000006, 29.762324256000056],
            [70.58107300000006, 29.76451551100007],
            [70.58107300000006, 29.767479782000066],
            [70.58107300000006, 29.771191929000054],
            [70.58107300000006, 29.774506046000056],
            [70.581438693000052, 29.777841693000028],
            [70.582894777000035, 29.779297777000068],
            [70.584305336000057, 29.780708336000032],
            [70.586037899000075, 29.782440899000051],
            [70.588040638000052, 29.784443638000027],
            [70.593382000000076, 29.789785000000052],
            [70.59545600000007, 29.791340500000047],
            [70.600736131000076, 29.79530059800004],
            [70.609794000000079, 29.802094000000068],
            [70.614007113000071, 29.803829276000045],
            [70.619183743000065, 29.805960388000074],
            [70.620765141000049, 29.806611557000053],
            [70.625183807000042, 29.808432205000031],
            [70.627412273000061, 29.809350191000078],
            [70.633045000000038, 29.811668000000054],
            [70.635307917000034, 29.810639429000048],
            [70.63789513200004, 29.809463453000035],
            [70.63995426200006, 29.80852765800006],
            [70.643181944000048, 29.807060600000057],
            [70.647498240000061, 29.805098759000032],
            [70.65165027300003, 29.803347055000074],
            [70.655998172000068, 29.801534190000041],
            [70.659368721000078, 29.80012965800006],
            [70.662694184000031, 29.798743913000067],
            [70.66450100000003, 29.797991000000025],
            [70.669051654000043, 29.796691193000072],
            [70.671935074000032, 29.793813128000068],
            [70.67657594700006, 29.791198099000042],
            [70.67926602700004, 29.78968579900004],
            [70.682232449000082, 29.788017213000046],
            [70.684800403000054, 29.78657276000007],
            [70.686384000000032, 29.785682000000065],
            [70.687025234000032, 29.784032998000043],
            [70.687824891000048, 29.781976594000071],
            [70.688729353000042, 29.779650672000059],
            [70.689620364000064, 29.777359343000057],
            [70.690536575000067, 29.775003206000065],
            [70.691736727000034, 29.771916888000078],
            [70.692561192000028, 29.769796687000053],
            [70.693410749000066, 29.767611959000078],
            [70.695957000000078, 29.761064000000033],
            [70.693418540000039, 29.758805556000027],
            [70.690354036000031, 29.753771479000079],
            [70.688282592000064, 29.752430739000033],
            [70.685016000000076, 29.748755000000074],
            [70.691878262000046, 29.743363248000037],
            [70.694740348000039, 29.741114477000053],
            [70.697078734000058, 29.739277182000023],
            [70.701243159000057, 29.73600515000004],
            [70.704163000000051, 29.733711000000028],
            [70.703624571000034, 29.727246706000074],
            [70.703732871000057, 29.723551315000066],
            [70.703272208000044, 29.720989521000035],
            [70.702804551000042, 29.717401667000047],
            [70.704503543000044, 29.712176371000055],
            [70.705087692000063, 29.710423924000054],
            [70.705948813000077, 29.707840561000069],
            [70.706899000000078, 29.704990000000066],
            [70.708238040000083, 29.70231179700005],
            [70.711390876000053, 29.696005838000076],
            [70.713201540000057, 29.692384344000061],
            [70.715494104000072, 29.687799006000034],
            [70.717145377000065, 29.684496310000043],
            [70.71791941500004, 29.682963223000058],
            [70.720594141000049, 29.677943891000041],
            [70.722159519000058, 29.67500932300004],
            [70.725526449000029, 29.669131245000074],
            [70.723550499000055, 29.662462996000045],
            [70.722008000000073, 29.657257000000072],
            [70.716086216000065, 29.654405949000079],
            [70.713645654000061, 29.654022501000043],
            [70.709715877000065, 29.651343815000075],
            [70.71209759900006, 29.64657580100004],
            [70.714067322000062, 29.642636356000025],
            [70.716090000000065, 29.638591000000076],
            [70.715760491000083, 29.628708404000065],
            [70.715634500000078, 29.624933000000055],
            [70.708350000000053, 29.621291000000042],
            [70.708435211000051, 29.618480448000071],
            [70.708562230000041, 29.614290900000071],
            [70.708691265000084, 29.610034868000071],
            [70.708797622000077, 29.606526826000049],
            [70.708928660000083, 29.602204736000033],
            [70.709261000000083, 29.591243000000077],
            [70.706975840000041, 29.588035082000033],
            [70.705230671000038, 29.585817702000043],
            [70.703101753000055, 29.582951927000067],
            [70.700576843000078, 29.579553099000066],
            [70.699059183000031, 29.577510149000034],
            [70.697424000000069, 29.575309000000061],
            [70.695345343000042, 29.571931254000049],
            [70.692222097000069, 29.566856087000076],
            [70.689055049000046, 29.561709742000062],
            [70.686497000000031, 29.557553000000041],
            [70.688772666000034, 29.554613815000039],
            [70.691233259000057, 29.551435783000045],
            [70.697424000000069, 29.543440000000032],
            [70.700597917000039, 29.540265931000079],
            [70.705460545000051, 29.535403111000051],
            [70.707180483000059, 29.53368312300006],
            [70.708886692000078, 29.532339816000047],
            [70.710626500000046, 29.530237000000056],
            [70.709901610000031, 29.522261892000074],
            [70.709261000000083, 29.515213000000074],
            [70.705645847000028, 29.509991211000056],
            [70.701066000000083, 29.50337600000006],
            [70.698355106000065, 29.498707109000065],
            [70.695128773000079, 29.493150494000076],
            [70.692871000000082, 29.489262000000053],
            [70.68220601400003, 29.488852051000038],
            [70.672507649000067, 29.48839821100006],
            [70.669544988000041, 29.488364166000054],
            [70.660719981000057, 29.48796648900003],
            [70.653938029000074, 29.487658064000072],
            [70.649165000000039, 29.487441000000047],
            [70.646850247000032, 29.482811763000029],
            [70.644925038000054, 29.478961575000028],
            [70.64292516900008, 29.474962011000059],
            [70.638252325000053, 29.464751493000051],
            [70.638502900000049, 29.456085202000054],
            [70.638692218000074, 29.438795836000054],
            [70.637320627000065, 29.410956414000054],
            [70.635569165000049, 29.411022038000056],
            [70.619377461000056, 29.411838320000072],
            [70.609556033000047, 29.412321027000075],
            [70.608665807000079, 29.406679012000041],
            [70.60920760700003, 29.400119563000032],
            [70.609101021000072, 29.396387036000078],
            [70.613308538000069, 29.393998929000077],
            [70.625946044000045, 29.386826051000071],
            [70.62679539800007, 29.382777008000062],
            [70.626995062000049, 29.38032129100003],
            [70.627578333000031, 29.376704548000077],
            [70.627606732000061, 29.372268904000066],
            [70.620629826000084, 29.367773047000071],
            [70.614564008000059, 29.364063055000031],
            [70.613544168000033, 29.360664365000048],
            [70.610843395000074, 29.350907569000071],
            [70.61452478700005, 29.338259552000068],
            [70.616400508000083, 29.331555425000033],
            [70.618205989000046, 29.325820067000052],
            [70.618862966000052, 29.322600976000047],
            [70.620805707000045, 29.314085384000066],
            [70.62275898300004, 29.30351107000007],
            [70.621505839000065, 29.301954152000064],
            [70.613588188000051, 29.292117193000024],
            [70.610584365000079, 29.288385217000041],
            [70.608058143000051, 29.285246615000062],
            [70.603711563000047, 29.280999252000072],
            [70.596335576000058, 29.274096753000038],
            [70.591844144000049, 29.269864195000025],
            [70.58876146800003, 29.267271987000072],
            [70.585565793000058, 29.264591873000029],
            [70.577686866000079, 29.257984062000048],
            [70.576936986000078, 29.250735750000047],
            [70.576554061000081, 29.24705761000007],
            [70.575953807000076, 29.241691484000057],
            [70.575410844000032, 29.236587059000044],
            [70.573116556000059, 29.23508408400005],
            [70.566304826000078, 29.233400058000029],
            [70.563429283000062, 29.232230575000074],
            [70.560176700000056, 29.228078166000046],
            [70.554460701000039, 29.223114180000039],
            [70.54900470900003, 29.218376075000037],
            [70.553262927000048, 29.218575897000051],
            [70.559020800000042, 29.219286056000044],
            [70.565629716000046, 29.212016689000052],
            [70.568126810000081, 29.209270053000068],
            [70.569273899000052, 29.206871493000051],
            [70.571346663000043, 29.204292253000062],
            [70.575162938000062, 29.194557795000037],
            [70.57544759700005, 29.191924974000074],
            [70.578142312000068, 29.188328045000048],
            [70.577298430000042, 29.186540725000043],
            [70.570857787000079, 29.174214040000038],
            [70.571745472000032, 29.16297073100003],
            [70.572091637000028, 29.15858754900006],
            [70.572247192000077, 29.156247020000023],
            [70.57238295500008, 29.152377339000054],
            [70.572476796000046, 29.149706558000048],
            [70.572556082000062, 29.147447999000065],
            [70.572707134000041, 29.143145177000065],
            [70.573056749000045, 29.132983076000073],
            [70.573134757000048, 29.130964014000028],
            [70.575188075000028, 29.120296507000035],
            [70.575410752000039, 29.117305004000059],
            [70.557199709000031, 29.112297006000063],
            [70.557554644000049, 29.104305085000078],
            [70.55547714000005, 29.099049535000063],
            [70.55403827400005, 29.095409712000048],
            [70.549915671000065, 29.084980987000051],
            [70.550231126000028, 29.081351146000031],
            [70.550825665000048, 29.074509978000037],
            [70.548625271000049, 29.064607215000024],
            [70.54805188000006, 29.062026734000028],
            [70.54718364200005, 29.05811996400007],
            [70.545454175000032, 29.053780645000074],
            [70.540856807000068, 29.042233143000033],
            [70.539180918000056, 29.03457204800003],
            [70.537669788000073, 29.027664129000073],
            [70.537890253000057, 29.02488571400005],
            [70.538251747000061, 29.022935992000043],
            [70.538284763000036, 29.022080196000047],
            [70.538501446000055, 29.016463689000034],
            [70.538319737000052, 29.016184225000075],
            [70.53809700000005, 29.016011000000049],
            [70.530521634000081, 29.004227097000069],
            [70.530399114000033, 29.004036510000049],
            [70.52920368000008, 29.003776992000041],
            [70.523051518000045, 29.002441410000074],
            [70.519054863000065, 29.001577314000031],
            [70.51580225400005, 29.000874085000078],
            [70.513680285000078, 29.000415306000036],
            [70.513587423000047, 29.000395229000048],
            [70.49810836100005, 29.000395252000033],
            [70.490464431000078, 28.99771983100004],
            [70.489802380000071, 28.997488109000074],
            [70.481142381000041, 28.994457058000023],
            [70.479897279000056, 28.994021265000072],
            [70.479890156000067, 28.993957155000032],
            [70.479880491000074, 28.993870164000043],
            [70.47888867100005, 28.984943379000072],
            [70.478472585000077, 28.981198434000078],
            [70.481455491000077, 28.972836353000048],
            [70.486838960000057, 28.957744693000052],
            [70.487088000000028, 28.957050000000038],
            [70.487086781000073, 28.957049967000046],
            [70.487086848000047, 28.957049779000045],
            [70.470242860000042, 28.956594836000079],
            [70.467400915000042, 28.953279176000024],
            [70.462048000000038, 28.947034000000031],
            [70.460747606000041, 28.946233791000054],
            [70.450211000000081, 28.93975000000006],
            [70.450209852000057, 28.939749912000025],
            [70.450209672000028, 28.939749801000062],
            [70.432454606000078, 28.938383831000067],
            [70.426241205000053, 28.940085083000042],
            [70.409704020000049, 28.944613023000045],
            [70.394211577000078, 28.948854909000033],
            [70.37281500000006, 28.947489000000076],
            [70.35050700000005, 28.937018000000023],
            [70.340946000000031, 28.923815000000047],
            [70.359157000000039, 28.90651500000007],
            [70.38009900000003, 28.889214000000038],
            [70.393564197000046, 28.880046741000058],
            [70.399322118000043, 28.876126684000042],
            [70.399451376000059, 28.876038684000036],
            [70.401497000000063, 28.874646000000041],
            [70.408782000000031, 28.860532000000035],
            [70.40673600000008, 28.84703200000007],
            [70.406505000000038, 28.845508000000052],
            [70.39574960200008, 28.826479569000071],
            [70.394668000000081, 28.824566000000061],
            [70.385563000000047, 28.798160000000053],
            [70.373726000000033, 28.790876000000026],
            [70.373725553000043, 28.790876447000073],
            [70.373724046000063, 28.790875519000053],
            [70.367295672000068, 28.797303931000044],
            [70.362342039000055, 28.802257592000046],
            [70.362008408000065, 28.802666149000061],
            [70.347891240000081, 28.819953722000037],
            [70.345553876000054, 28.822816007000029],
            [70.336167713000066, 28.826042883000071],
            [70.328264923000063, 28.828640449000034],
            [70.328197017000036, 28.828662769000061],
            [70.328173132000074, 28.828661478000072],
            [70.311186557000042, 28.827743455000075],
            [70.304982073000076, 28.827297122000061],
            [70.304979919000061, 28.827296967000052],
            [70.304977956000073, 28.827296826000065],
            [70.300163969000039, 28.825348917000042],
            [70.285858000000076, 28.819558000000029],
            [70.28267100000005, 28.806810000000041],
            [70.28847122500008, 28.800605356000062],
            [70.290949495000064, 28.797954290000064],
            [70.294744648000062, 28.793894521000027],
            [70.302248000000077, 28.78586800000005],
            [70.305075807000037, 28.781259586000033],
            [70.308715524000036, 28.775328020000075],
            [70.314540000000079, 28.765836000000036],
            [70.312675987000034, 28.764421824000067],
            [70.311113800000044, 28.763236635000055],
            [70.309202139000035, 28.761786309000058],
            [70.309118850000061, 28.761723120000056],
            [70.307467442000075, 28.760470242000054],
            [70.301338000000044, 28.755820000000028],
            [70.301198313000043, 28.755804037000075],
            [70.300534025000047, 28.755728124000029],
            [70.29747509200007, 28.755378559000064],
            [70.294009204000076, 28.754982489000042],
            [70.291203290000055, 28.754661838000061],
            [70.285403000000031, 28.753999000000078],
            [70.274491006000062, 28.756370855000057],
            [70.26446100000004, 28.758551000000068],
            [70.251258000000064, 28.753999000000078],
            [70.24674354800004, 28.758384265000075],
            [70.245485254000073, 28.759606552000037],
            [70.240212046000067, 28.764728860000048],
            [70.235323000000051, 28.769478000000049],
            [70.232150595000064, 28.767495174000032],
            [70.229746447000082, 28.765992527000037],
            [70.228081438000061, 28.764952515000061],
            [70.224397000000067, 28.762649000000067],
            [70.223693947000072, 28.760364608000032],
            [70.223026913000069, 28.75819725100007],
            [70.222169932000043, 28.75541271000003],
            [70.22090313700005, 28.751296584000045],
            [70.220754000000056, 28.750812000000053],
            [70.217032409000069, 28.749695506000023],
            [70.214715356000056, 28.749000380000041],
            [70.212132428000075, 28.748225212000079],
            [70.212132230000066, 28.748225431000037],
            [70.197991000000059, 28.743983000000071],
            [70.205188260000057, 28.738118631000077],
            [70.208601406000071, 28.735337580000078],
            [70.210146241000075, 28.734078840000052],
            [70.214460587000076, 28.730563485000062],
            [70.222576000000061, 28.723951000000056],
            [70.223176537000029, 28.721872054000073],
            [70.223290132000045, 28.721478810000065],
            [70.224437035000051, 28.717508448000046],
            [70.226068080000061, 28.711862078000024],
            [70.227214466000078, 28.707893504000026],
            [70.228494000000069, 28.703464000000054],
            [70.223108258000082, 28.694606771000053],
            [70.21813230500004, 28.684683651000057],
            [70.215089305000049, 28.679168278000077],
            [70.21451640500004, 28.678129910000052],
            [70.213925000000074, 28.677058000000045],
            [70.211841567000079, 28.67642661900004],
            [70.209253308000029, 28.675642251000056],
            [70.202157258000057, 28.673491804000037],
            [70.198901000000035, 28.672505000000058],
            [70.195274364000056, 28.672699503000047],
            [70.182032846000084, 28.672673582000073],
            [70.179933684000048, 28.672694561000071],
            [70.172715373000074, 28.672766701000057],
            [70.154705248000084, 28.672946695000064],
            [70.153374000000042, 28.672960000000046],
            [70.146572597000045, 28.672053180000034],
            [70.142087967000066, 28.671455154000057],
            [70.140841839000075, 28.671288983000068],
            [70.122054020000064, 28.668784156000072],
            [70.112400000000036, 28.667497000000026],
            [70.099555168000052, 28.659678967000048],
            [70.091457000000048, 28.654750000000035],
            [70.090584360000037, 28.653877298000054],
            [70.078887258000066, 28.642179368000029],
            [70.077704500000038, 28.640457352000055],
            [70.077304226000081, 28.639874581000072],
            [70.077121979000083, 28.636504717000037],
            [70.077023632000078, 28.634686219000059],
            [70.076888155000063, 28.632181168000045],
            [70.076693558000045, 28.628582939000069],
            [70.076434574000075, 28.623794148000059],
            [70.089637574000051, 28.613778148000051],
            [70.101037833000078, 28.612115556000049],
            [70.103504206000082, 28.611755865000077],
            [70.111490574000072, 28.610591148000026],
            [70.116954574000033, 28.602852148000068],
            [70.116951879000055, 28.602850588000024],
            [70.11695300000008, 28.602849000000049],
            [70.113481858000057, 28.600839466000025],
            [70.099652000000049, 28.592833000000041],
            [70.09887172100008, 28.592052686000045],
            [70.096846680000056, 28.590027554000073],
            [70.09086830900003, 28.584048915000039],
            [70.090870446000054, 28.584047701000031],
            [70.086945976000038, 28.580126407000023],
            [70.083640621000029, 28.576820903000055],
            [70.077344000000039, 28.570524000000034],
            [70.074247443000047, 28.562473134000072],
            [70.070515000000057, 28.552769000000069],
            [70.070504000000028, 28.55275400000005],
            [70.052304000000049, 28.529095000000041],
            [70.042366538000067, 28.528330192000055],
            [70.04236644100007, 28.528330185000073],
            [70.040467000000035, 28.528184000000067],
            [70.029540000000054, 28.535468000000037],
            [70.025360395000064, 28.537657369000044],
            [70.018716590000054, 28.541137540000079],
            [70.01041900000007, 28.545484000000044],
            [70.004945864000035, 28.542878235000046],
            [70.00177308800005, 28.541367674000071],
            [70.000858000000051, 28.540932000000055],
            [69.998628002000032, 28.533498457000064],
            [69.998627396000074, 28.533496436000064],
            [69.996840403000078, 28.527536062000024],
            [69.996380927000075, 28.526005011000052],
            [69.994029000000069, 28.51816800000006],
            [69.98537900000008, 28.517713000000072],
            [69.982004551000045, 28.515365452000026],
            [69.976906786000029, 28.511819022000054],
            [69.971375730000034, 28.507971158000032],
            [69.96924372400008, 28.506487957000047],
            [69.964437000000032, 28.503144000000077],
            [69.964167975000066, 28.496754313000054],
            [69.963879367000061, 28.489899507000075],
            [69.963736966000056, 28.486517316000061],
            [69.963406056000053, 28.478657803000033],
            [69.963102595000066, 28.471450237000056],
            [69.962863904000073, 28.465781031000063],
            [69.962616000000082, 28.459893000000079],
            [69.956763054000078, 28.45541739600003],
            [69.953060964000031, 28.452586498000073],
            [69.94989574300007, 28.45016613100006],
            [69.947229531000062, 28.448127345000046],
            [69.939397000000042, 28.442138000000057],
            [69.933424451000064, 28.440116541000066],
            [69.931986077000033, 28.439629710000077],
            [69.931372066000051, 28.439421894000077],
            [69.926124583000046, 28.437645839000027],
            [69.919956693000074, 28.435558264000065],
            [69.911212094000064, 28.432598581000036],
            [69.909804000000065, 28.432122000000049],
            [69.90192993800008, 28.431270788000063],
            [69.898325682000063, 28.430881156000055],
            [69.892959000000076, 28.430301000000043],
            [69.884804520000046, 28.437028412000075],
            [69.881399280000039, 28.43983772100006],
            [69.881265542000051, 28.439948055000059],
            [69.878560588000084, 28.442179631000045],
            [69.876328974000046, 28.444020703000035],
            [69.874748000000068, 28.445325000000025],
            [69.870495156000061, 28.451704006000057],
            [69.866553000000067, 28.457617000000027],
            [69.863017263000074, 28.459213881000039],
            [69.852440000000058, 28.463991000000078],
            [69.847887000000071, 28.453520000000026],
            [69.854600839000057, 28.440675800000065],
            [69.858358000000067, 28.433488000000068],
            [69.849231590000045, 28.428509295000026],
            [69.848342000000059, 28.42802400000005],
            [69.842681853000045, 28.428778659000045],
            [69.837050126000065, 28.429529528000046],
            [69.834684000000038, 28.429845000000057],
            [69.829617309000071, 28.425285256000052],
            [69.825579000000062, 28.421651000000054],
            [69.818943964000084, 28.409708518000059],
            [69.81647300000003, 28.405261000000053],
            [69.803271000000052, 28.396611000000064],
            [69.801947667000036, 28.39717809800004],
            [69.790523000000064, 28.402074000000027],
            [69.78338860100007, 28.402549348000036],
            [69.776865000000043, 28.40298400000006],
            [69.774113169000032, 28.403209638000078],
            [69.767194532000076, 28.403776935000053],
            [69.750766000000056, 28.405124000000058],
            [69.750484644000039, 28.405374984000048],
            [69.744129000000044, 28.411073000000044],
            [69.731058910000058, 28.422035255000026],
            [69.725310000000036, 28.427040800000043],
            [69.712280526000029, 28.427344462000065],
            [69.702837153000075, 28.427564548000078],
            [69.700616000000082, 28.427620000000047],
            [69.694171686000061, 28.428403782000032],
            [69.668816868000079, 28.431487528000048],
            [69.655263000000048, 28.433136000000047],
            [69.650725715000078, 28.433134151000047],
            [69.622169000000042, 28.433136000000047],
            [69.610112915000059, 28.440193185000055],
            [69.597041000000047, 28.447845000000029],
            [69.616653000000042, 28.469908000000032],
            [69.60194400000006, 28.474811000000045],
            [69.563196830000038, 28.478369539000028],
            [69.560076542000047, 28.478656106000074],
            [69.541883000000041, 28.480327000000045],
            [69.521230841000033, 28.483631427000034],
            [69.519379828000069, 28.483927595000068],
            [69.511353701000075, 28.485211807000042],
            [69.511240000000043, 28.485230000000058],
            [69.476306000000079, 28.487681000000066],
            [69.457378850000055, 28.481945954000025],
            [69.456082000000038, 28.481553000000076],
            [69.455778713000029, 28.48147212300006],
            [69.437696000000074, 28.476650000000063],
            [69.424213000000066, 28.468683000000055],
            [69.419957575000069, 28.45686254900005],
            [69.419790496000076, 28.456398445000048],
            [69.418697000000066, 28.453361000000029],
            [69.407665000000065, 28.450297000000035],
            [69.405555498000069, 28.449984406000056],
            [69.391118000000063, 28.447845000000029],
            [69.37022470800008, 28.449151097000026],
            [69.361700000000042, 28.449684000000047],
            [69.360841806000053, 28.451244893000023],
            [69.354959000000065, 28.461941000000024],
            [69.34699100000006, 28.486456000000032],
            [69.346844823000083, 28.486894531000075],
            [69.346844786000077, 28.48689464000006],
            [69.338443576000032, 28.51209827200006],
            [69.338411000000065, 28.512196000000074],
            [69.334892901000046, 28.52028722600005],
            [69.334892287000059, 28.520288636000032],
            [69.332282000000077, 28.526292000000069],
            [69.321864000000062, 28.535485000000051],
            [69.31964799900004, 28.547449234000055],
            [69.31879900000007, 28.552033000000051],
            [69.326154000000031, 28.558161000000041],
            [69.34493115600003, 28.568044223000072],
            [69.349443000000065, 28.570419000000072],
            [69.377022000000068, 28.578999000000067],
            [69.398472000000083, 28.59003000000007],
            [69.41624500000006, 28.597385000000031],
            [69.416808439000079, 28.598265036000043],
            [69.420731927000077, 28.604393142000049],
            [69.420732369000064, 28.604393833000074],
            [69.431487366000056, 28.621192092000058],
            [69.436877934000051, 28.62961163500006],
            [69.445082033000062, 28.642425639000066],
            [69.451318273000084, 28.652166038000075],
            [69.451461769000048, 28.652390166000032],
            [69.452784522000059, 28.654456175000064],
            [69.45897874700006, 28.664130954000029],
            [69.467921354000055, 28.678098437000074],
            [69.473168712000074, 28.686294299000053],
            [69.478933036000058, 28.695297612000047],
            [69.480560190000062, 28.697839069000054],
            [69.480596000000048, 28.697895000000074],
            [69.484072339000079, 28.70285300200004],
            [69.48775167000008, 28.708100514000023],
            [69.487752906000082, 28.708102278000069],
            [69.501837155000032, 28.728189427000075],
            [69.514575690000072, 28.746357300000057],
            [69.517981000000077, 28.751214000000061],
            [69.523383440000032, 28.760881328000039],
            [69.529626000000064, 28.772052000000031],
            [69.533610966000083, 28.781084387000078],
            [69.538819000000046, 28.792889000000059],
            [69.537210191000042, 28.799726519000046],
            [69.535296526000081, 28.807859694000058],
            [69.533916000000033, 28.813727000000029],
            [69.528746254000055, 28.822342930000048],
            [69.525650686000063, 28.827502024000069],
            [69.522884000000033, 28.832113000000049],
            [69.502047000000061, 28.838242000000037],
            [69.499175550000075, 28.839930999000046],
            [69.499167533000048, 28.839931990000025],
            [69.482767710000076, 28.849581838000063],
            [69.481209000000035, 28.85049900000007],
            [69.482334882000032, 28.855002252000077],
            [69.483703032000051, 28.860474515000078],
            [69.485090464000052, 28.866023905000077],
            [69.48638148100008, 28.871187659000043],
            [69.488069916000029, 28.877940984000077],
            [69.489409847000047, 28.883300379000048],
            [69.490479815000072, 28.887579993000031],
            [69.49213297600005, 28.894192232000023],
            [69.493467000000066, 28.899528000000032],
            [69.503997542000036, 28.907186718000048],
            [69.506950000000074, 28.909334000000058],
            [69.513432329000068, 28.913694913000029],
            [69.519823794000047, 28.91799469800003],
            [69.525347787000044, 28.921710902000029],
            [69.540657000000067, 28.932010000000048],
            [69.545248923000031, 28.939089097000078],
            [69.551691273000074, 28.949020889000053],
            [69.555366000000049, 28.954686000000038],
            [69.564559000000031, 28.955299000000025],
            [69.577429000000052, 28.958976000000064],
            [69.582874781000044, 28.971683151000036],
            [69.582945000000052, 28.971847000000025],
            [69.587198385000079, 28.98428042200004],
            [69.587198638000075, 28.984281162000059],
            [69.59091200000006, 28.995136000000059],
            [69.598932286000036, 29.00457113300007],
            [69.601331000000073, 29.007393000000036],
            [69.604135885000062, 29.011800615000027],
            [69.606554198000083, 29.015600766000034],
            [69.606583474000047, 29.015588839000031],
            [69.607178961000045, 29.016524549000053],
            [69.607180823000078, 29.016527474000043],
            [69.614238220000061, 29.027617000000077],
            [69.61965553400006, 29.032231603000071],
            [69.630786220000061, 29.041713000000072],
            [69.639979221000033, 29.058261000000073],
            [69.652849220000064, 29.071131000000037],
            [69.677977220000059, 29.080324000000076],
            [69.697588220000057, 29.088291000000027],
            [69.708007221000059, 29.09871000000004],
            [69.708115013000054, 29.10075770800006],
            [69.708620221000047, 29.110355000000027],
            [69.715361220000034, 29.127515000000074],
            [69.719258963000073, 29.134444162000079],
            [69.72639322100008, 29.147127000000069],
            [69.745392221000031, 29.172867000000053],
            [69.749069220000081, 29.187576000000035],
            [69.742940220000037, 29.197995000000049],
            [69.738650220000068, 29.224348000000077],
            [69.744166221000057, 29.244572000000062],
            [69.753972221000083, 29.261733000000049],
            [69.762552220000032, 29.278893000000039],
            [69.76327740000005, 29.283605783000041],
            [69.76482285600008, 29.293649358000039],
            [69.764801519000059, 29.293752579000056],
            [69.76449060300007, 29.29525667200005],
            [69.76448209800003, 29.295297817000062],
            [69.758875221000039, 29.300343000000055],
            [69.758844501000056, 29.300337150000075],
            [69.746005220000029, 29.297892000000047],
            [69.72639322100008, 29.284409000000039],
            [69.700040220000062, 29.259894000000031],
            [69.684105220000049, 29.258056000000067],
            [69.684103406000077, 29.258060083000032],
            [69.679202220000036, 29.26908700000007],
            [69.680428221000057, 29.288086000000078],
            [69.676138221000031, 29.298505000000034],
            [69.663268220000077, 29.30831100000006],
            [69.663231000000053, 29.30831100000006],
            [69.654075220000038, 29.30831100000006],
            [69.636302220000061, 29.311988000000042],
            [69.63691422100004, 29.32547100000005],
            [69.649172221000072, 29.335277000000076],
            [69.660458027000061, 29.347431163000067],
            [69.66510622100003, 29.352437000000066],
            [69.670009221000043, 29.36530700000003],
            [69.657139220000033, 29.387983000000077],
            [69.656783016000077, 29.388291720000041],
            [69.646020871000076, 29.397619185000053],
            [69.645983246000071, 29.397619536000036],
            [69.638716000000045, 29.403918000000033],
            [69.629523000000063, 29.416175000000067],
            [69.612363000000073, 29.422917000000041],
            [69.604395000000068, 29.419240000000059],
            [69.603717840000058, 29.418647373000056],
            [69.599438148000047, 29.414901933000067],
            [69.594590000000039, 29.410659000000066],
            [69.588362608000068, 29.40710090500005],
            [69.581719000000078, 29.403305000000046],
            [69.567011000000036, 29.407595000000072],
            [69.565577590000032, 29.428231899000025],
            [69.563946000000044, 29.451722000000075],
            [69.57375200000007, 29.478075000000047],
            [69.585605653000073, 29.488817146000031],
            [69.593364000000065, 29.495848000000024],
            [69.595963547000053, 29.506463584000073],
            [69.596953247000044, 29.510505151000075],
            [69.597863286000063, 29.514221409000072],
            [69.600718000000029, 29.525879000000032],
            [69.600875098000074, 29.530669346000025],
            [69.601289214000076, 29.543296855000051],
            [69.601506764000078, 29.549930521000078],
            [69.601767533000043, 29.557882054000061],
            [69.60194400000006, 29.563263000000063],
            [69.602352684000039, 29.566766064000035],
            [69.602833918000044, 29.570890985000062],
            [69.604075317000081, 29.581531717000075],
            [69.606234000000029, 29.600035000000048],
            [69.598880000000065, 29.611680000000035],
            [69.594768817000045, 29.622368137000024],
            [69.592751000000078, 29.627614000000051],
            [69.604395000000068, 29.635582000000056],
            [69.622781964000069, 29.639258993000055],
            [69.622782000000029, 29.639259000000038],
            [69.624414991000037, 29.646200377000071],
            [69.626295940000034, 29.654195755000046],
            [69.627684000000045, 29.660096000000067],
            [69.631967732000078, 29.665683450000074],
            [69.63509749800005, 29.669765735000055],
            [69.64178000000004, 29.678482000000031],
            [69.651364000000058, 29.684582000000034],
            [69.651823433000061, 29.684873546000063],
            [69.662034100000028, 29.691353000000049],
            [69.674572166000075, 29.694299894000039],
            [69.674573417000033, 29.694307048000042],
            [69.682842000000051, 29.696256000000062],
            [69.704906000000051, 29.709126000000026],
            [69.717826623000064, 29.713432614000055],
            [69.729393220000077, 29.717287913000064],
            [69.738001001000043, 29.720157000000029],
            [69.750871000000075, 29.73854300000005],
            [69.771708000000046, 29.755704000000037],
            [69.78948100000008, 29.765510000000063],
            [69.796223000000055, 29.77776700000004],
            [69.796922833000053, 29.778523693000068],
            [69.803577000000075, 29.785734000000048],
            [69.81004138600008, 29.802432418000024],
            [69.810932000000037, 29.804733000000056],
            [69.81734537400007, 29.818272506000028],
            [69.830147299000032, 29.845336816000042],
            [69.830149414000061, 29.845355083000072],
            [69.830163668000068, 29.845336816000042],
            [69.832995000000039, 29.851311000000067],
            [69.84586500000006, 29.866633000000036],
            [69.867928000000063, 29.885019000000057],
            [69.867956861000039, 29.885046166000052],
            [69.868129340000053, 29.885189924000031],
            [69.878830853000068, 29.895271476000062],
            [69.880828000000065, 29.893728000000067],
            [69.895872000000054, 29.875948000000051],
            [69.910916000000043, 29.862272000000075],
            [69.915416730000061, 29.860271554000064],
            [69.91994829600003, 29.858257402000049],
            [69.935534000000075, 29.851330000000075],
            [69.950401666000062, 29.84802617400004],
            [69.953435593000052, 29.84735198900006],
            [69.957032843000036, 29.846552625000072],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "118",
      properties: { name: "Rawalpindi", count: 877 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.539492221000046, 33.96532350800004],
            [73.541600562000042, 33.959811485000046],
            [73.543497150000064, 33.954853068000034],
            [73.543615387000045, 33.954541988000074],
            [73.543699000000061, 33.954322000000047],
            [73.545370974000036, 33.952094037000052],
            [73.545371001000035, 33.952094001000034],
            [73.545371037000052, 33.952093953000031],
            [73.546699320000073, 33.950322813000071],
            [73.548354176000032, 33.948116222000067],
            [73.550922344000071, 33.94469181900007],
            [73.552600307000034, 33.942454831000077],
            [73.55260548800004, 33.942447924000078],
            [73.555823733000068, 33.938157500000045],
            [73.557813985000053, 33.935503909000033],
            [73.559338281000066, 33.933470768000063],
            [73.561216338000065, 33.930965771000047],
            [73.562834488000078, 33.928808124000057],
            [73.563532012000053, 33.92787906600006],
            [73.564292161000083, 33.926866594000046],
            [73.564992019000044, 33.925932368000076],
            [73.565738801000066, 33.924935504000075],
            [73.567306045000066, 33.922845735000067],
            [73.568185232000076, 33.921674704000054],
            [73.569137000000069, 33.920407000000068],
            [73.574064109000062, 33.917086244000075],
            [73.575675688000047, 33.915983077000078],
            [73.578651814000068, 33.913969807000058],
            [73.580493404000038, 33.912724420000075],
            [73.58189360800003, 33.911777186000052],
            [73.584671594000042, 33.909897713000078],
            [73.584924894000039, 33.90972623600004],
            [73.585513871000046, 33.909327809000047],
            [73.587439460000041, 33.908025064000071],
            [73.588355000000035, 33.907406000000037],
            [73.589011013000061, 33.905734944000073],
            [73.59028937800008, 33.902481054000077],
            [73.59028978300006, 33.902480023000066],
            [73.590911048000066, 33.900899957000036],
            [73.59167575400005, 33.898953511000059],
            [73.592576684000051, 33.896660328000053],
            [73.593569077000041, 33.894134338000072],
            [73.594573000000082, 33.891579000000036],
            [73.594196529000044, 33.887251416000026],
            [73.594005456000048, 33.885060757000076],
            [73.594005288000062, 33.885058822000076],
            [73.593495361000066, 33.879197152000074],
            [73.593230690000041, 33.875625266000043],
            [73.593230680000033, 33.875625125000056],
            [73.593216808000079, 33.875437916000067],
            [73.592475444000058, 33.865054229000066],
            [73.592287874000078, 33.862704939000025],
            [73.592285156000059, 33.862670898000033],
            [73.591125488000046, 33.860290527000075],
            [73.588341785000068, 33.855095229000028],
            [73.588322255000037, 33.855058780000036],
            [73.587100329000066, 33.852701183000079],
            [73.587099386000034, 33.852699364000046],
            [73.58602417600008, 33.850624844000038],
            [73.584398000000078, 33.847489000000053],
            [73.582836347000068, 33.843411112000069],
            [73.582445307000057, 33.842390004000038],
            [73.581523011000058, 33.83998164500008],
            [73.58075361300007, 33.837972544000024],
            [73.580709223000042, 33.837858405000077],
            [73.580709181000032, 33.83785830000005],
            [73.580260151000061, 33.836703745000079],
            [73.580094470000063, 33.836253204000059],
            [73.580094450000047, 33.836253151000051],
            [73.580077278000033, 33.836206454000035],
            [73.579460051000069, 33.834594710000033],
            [73.579447597000069, 33.834562307000056],
            [73.57925308800003, 33.834056242000031],
            [73.579040366000072, 33.833502790000068],
            [73.578820716000052, 33.832927262000055],
            [73.578820524000037, 33.832926756000063],
            [73.578595085000075, 33.832336057000077],
            [73.57805062500006, 33.830914329000052],
            [73.577588917000071, 33.82970868600006],
            [73.577181406000079, 33.828644567000026],
            [73.576839490000054, 33.827754009000046],
            [73.57648378600004, 33.826827540000068],
            [73.576156836000052, 33.825971510000045],
            [73.576156605000051, 33.82597090400003],
            [73.575817615000062, 33.825083348000078],
            [73.57483841800007, 33.822526409000034],
            [73.574224000000072, 33.820922000000053],
            [73.573935877000054, 33.820016279000072],
            [73.573935806000065, 33.820016056000043],
            [73.573437499000079, 33.818444159000023],
            [73.573139997000055, 33.817505835000077],
            [73.573028935000082, 33.817153718000043],
            [73.572975411000073, 33.816986730000053],
            [73.572874340000055, 33.816671405000079],
            [73.572315643000081, 33.814909244000035],
            [73.572126411000056, 33.814308978000042],
            [73.572013345000073, 33.813950323000029],
            [73.571736014000066, 33.813075558000037],
            [73.571408451000082, 33.812042352000049],
            [73.571141229000034, 33.811199477000059],
            [73.570890451000082, 33.810408466000069],
            [73.570662021000032, 33.809687949000079],
            [73.570265846000041, 33.808438324000065],
            [73.570080223000048, 33.807852829000069],
            [73.569925512000054, 33.807364836000033],
            [73.569868775000032, 33.807185875000073],
            [73.569633336000038, 33.806443248000051],
            [73.569321513000034, 33.805459688000042],
            [73.569134711000061, 33.80487047500003],
            [73.568946556000071, 33.804279700000052],
            [73.568162121000057, 33.801805588000036],
            [73.567926756000077, 33.801060318000054],
            [73.567329830000062, 33.799177480000026],
            [73.567105497000057, 33.798472989000061],
            [73.566874964000078, 33.797749027000066],
            [73.566874000000041, 33.797746000000075],
            [73.566874964000078, 33.797743240000045],
            [73.567106730000035, 33.797079766000024],
            [73.567339493000077, 33.796413433000055],
            [73.567644182000038, 33.79553743300005],
            [73.567862571000035, 33.794906680000054],
            [73.568081021000069, 33.794275749000064],
            [73.568478313000071, 33.793136387000061],
            [73.568478680000055, 33.793135332000077],
            [73.568875735000063, 33.791996651000034],
            [73.569183726000063, 33.791108285000064],
            [73.570444920000057, 33.787482284000077],
            [73.570445479000057, 33.787480679000055],
            [73.571398000000045, 33.784745000000044],
            [73.571640376000062, 33.784342486000071],
            [73.572125715000084, 33.783536484000024],
            [73.574030858000071, 33.780384319000063],
            [73.57474290600004, 33.779209930000036],
            [73.575822524000046, 33.777424471000074],
            [73.576329452000039, 33.776587336000034],
            [73.576898371000084, 33.775647830000025],
            [73.578139045000057, 33.773595855000053],
            [73.578775000000064, 33.772542703000056],
            [73.578775200000052, 33.772542373000078],
            [73.579608866000058, 33.77116359300004],
            [73.580120088000058, 33.77031936800006],
            [73.580514589000074, 33.769665641000074],
            [73.58076991300004, 33.769242543000075],
            [73.581793926000046, 33.767549042000041],
            [73.583793000000071, 33.764243000000079],
            [73.583787493000045, 33.764241435000031],
            [73.583787366000081, 33.76424139900007],
            [73.590492352000069, 33.753035943000043],
            [73.598529000000042, 33.739525000000071],
            [73.601350878000062, 33.723696047000033],
            [73.601264112000081, 33.719674559000055],
            [73.601264109000056, 33.719674386000065],
            [73.601239351000061, 33.718526926000038],
            [73.601015592000067, 33.70869586200007],
            [73.600996846000044, 33.707872260000045],
            [73.600946108000073, 33.705675900000074],
            [73.600790500000073, 33.698826000000054],
            [73.596384897000064, 33.683155829000043],
            [73.59570343300004, 33.680741306000073],
            [73.595702500000073, 33.680738000000076],
            [73.583832000000029, 33.672824000000048],
            [73.577049000000045, 33.666607000000056],
            [73.577049000000045, 33.658184145000064],
            [73.577049000000045, 33.651345000000049],
            [73.575532187000078, 33.645108684000036],
            [73.57415314800005, 33.639438819000077],
            [73.571962000000042, 33.630430000000047],
            [73.57243075000008, 33.629997293000031],
            [73.579310000000078, 33.623647000000062],
            [73.59344150000004, 33.61403800000005],
            [73.59768100000008, 33.610081000000037],
            [73.601921000000061, 33.606124000000079],
            [73.605889233000084, 33.598907831000076],
            [73.60589224000006, 33.598902362000047],
            [73.606763176000072, 33.59731878100007],
            [73.606763307000051, 33.597318743000073],
            [73.608138000000054, 33.59481900000003],
            [73.610543761000031, 33.591912297000079],
            [73.613549526000043, 33.588280655000062],
            [73.621705000000077, 33.578427000000033],
            [73.625096000000042, 33.573057000000063],
            [73.628487500000062, 33.567687000000035],
            [73.627943203000029, 33.565781838000078],
            [73.627399618000084, 33.563877423000065],
            [73.626227000000029, 33.559773000000064],
            [73.626128779000055, 33.557233460000077],
            [73.626059542000064, 33.555443319000062],
            [73.625825087000067, 33.549341233000064],
            [73.62576447400005, 33.547763667000027],
            [73.625729938000063, 33.545077028000037],
            [73.63301000000007, 33.539989000000048],
            [73.633575000000064, 33.526423000000079],
            [73.630110001000048, 33.52573036800004],
            [73.627922000000069, 33.525293000000033],
            [73.626128406000078, 33.524051056000076],
            [73.620574000000033, 33.520205000000033],
            [73.615925064000066, 33.513696819000074],
            [73.612095000000068, 33.508335000000045],
            [73.611743108000042, 33.498125766000044],
            [73.611739741000065, 33.498028090000048],
            [73.611669433000031, 33.495988274000069],
            [73.611530000000073, 33.491943000000049],
            [73.625661000000036, 33.471593000000041],
            [73.629750306000062, 33.457792893000033],
            [73.630008145000033, 33.456922768000027],
            [73.630183500000044, 33.456331000000034],
            [73.630008145000033, 33.456232809000028],
            [73.623380596000061, 33.452521682000054],
            [73.616052000000082, 33.448418000000061],
            [73.615679381000064, 33.448060291000047],
            [73.601920500000062, 33.434852000000035],
            [73.592311000000052, 33.413372000000038],
            [73.58270200000004, 33.40263200000004],
            [73.575977865000084, 33.377401190000057],
            [73.579311000000075, 33.357977000000062],
            [73.588833782000052, 33.341773342000067],
            [73.596841665000056, 33.341184651000049],
            [73.599095000000034, 33.341019000000074],
            [73.606364606000056, 33.336138515000073],
            [73.606257678000077, 33.336094616000025],
            [73.606181889000084, 33.336063501000069],
            [73.606812151000042, 33.335713355000053],
            [73.60827636700003, 33.334899902000075],
            [73.60590467600008, 33.326980866000042],
            [73.604147420000061, 33.320830470000033],
            [73.603698730000076, 33.319091797000056],
            [73.611083984000061, 33.300903320000032],
            [73.618313000000057, 33.289016000000061],
            [73.614357000000041, 33.278841000000057],
            [73.622835000000066, 33.264710000000036],
            [73.624677888000065, 33.255267220000064],
            [73.624681632000033, 33.255248036000069],
            [73.62468165000007, 33.255247946000054],
            [73.614380253000036, 33.253702310000051],
            [73.606592214000045, 33.252520394000044],
            [73.598412000000053, 33.243886000000032],
            [73.593404000000078, 33.232504000000063],
            [73.581567385000085, 33.23386886000003],
            [73.581553800000052, 33.233870426000067],
            [73.572193105000053, 33.234172877000049],
            [73.572191991000068, 33.234172913000066],
            [73.567454000000055, 33.234326000000067],
            [73.563092049000034, 33.233702799000071],
            [73.559520724000038, 33.233192556000063],
            [73.557893000000036, 33.232960000000048],
            [73.554706000000067, 33.221578000000079],
            [73.55288500000006, 33.211107000000027],
            [73.551064000000054, 33.204278000000045],
            [73.547148569000058, 33.205116494000038],
            [73.546565139000052, 33.205241436000051],
            [73.54469000000006, 33.205643000000066],
            [73.545448835000059, 33.211965883000062],
            [73.546056000000078, 33.217025000000035],
            [73.538772000000051, 33.224765000000048],
            [73.532670453000037, 33.234933606000027],
            [73.529211000000032, 33.240699000000063],
            [73.528122695000036, 33.239284143000077],
            [73.524936986000057, 33.235142547000066],
            [73.523313838000036, 33.233032366000032],
            [73.522194187000082, 33.231576758000074],
            [73.520106000000055, 33.228862000000049],
            [73.516662582000038, 33.22860696500004],
            [73.507824550000066, 33.227952380000033],
            [73.507824336000056, 33.227951648000044],
            [73.507814727000039, 33.227952128000027],
            [73.507813000000056, 33.227952000000073],
            [73.501030823000065, 33.228290923000031],
            [73.495325223000066, 33.228503590000059],
            [73.482773000000066, 33.228862000000049],
            [73.475995990000058, 33.224021278000066],
            [73.475995954000041, 33.224021252000057],
            [73.470026000000075, 33.219757000000072],
            [73.467955718000042, 33.225966834000076],
            [73.467955706000055, 33.225966870000036],
            [73.467914131000043, 33.226091573000076],
            [73.466316273000075, 33.230865102000053],
            [73.465920377000032, 33.232070116000045],
            [73.464308849000076, 33.238256189000026],
            [73.463806172000034, 33.240183257000069],
            [73.46177334500004, 33.247976320000078],
            [73.460465000000056, 33.252992000000063],
            [73.449994000000061, 33.250715000000071],
            [73.44160058600005, 33.246112390000064],
            [73.441600550000032, 33.246112370000048],
            [73.437645797000073, 33.243943743000045],
            [73.435881000000052, 33.242976000000056],
            [73.42888827400003, 33.241163113000027],
            [73.423588000000052, 33.23978900000003],
            [73.422836919000076, 33.238322506000031],
            [73.421858402000055, 33.236411942000075],
            [73.416035513000054, 33.225042689000077],
            [73.41402800000003, 33.221123000000034],
            [73.413948338000068, 33.219331436000061],
            [73.413493995000067, 33.20911344600006],
            [73.413191153000071, 33.202253408000047],
            [73.413120442000036, 33.200651643000072],
            [73.413117642000032, 33.200649437000038],
            [73.413117000000057, 33.200635000000034],
            [73.406743000000063, 33.195627000000059],
            [73.394417163000071, 33.192751753000039],
            [73.393085000000042, 33.192441000000031],
            [73.38350065700007, 33.193141880000042],
            [73.380067736000058, 33.193392921000054],
            [73.374419000000046, 33.193806000000052],
            [73.372726399000044, 33.191527559000065],
            [73.363840306000043, 33.179565829000069],
            [73.362582000000032, 33.177872000000036],
            [73.368847071000062, 33.171936479000067],
            [73.377596032000042, 33.163647723000054],
            [73.379882000000066, 33.161482000000035],
            [73.382889638000051, 33.155165915000055],
            [73.38824786400005, 33.143913563000069],
            [73.39315035900006, 33.13361825100003],
            [73.39354000000003, 33.132800000000032],
            [73.37581238100006, 33.117284486000074],
            [73.375330000000076, 33.116865000000075],
            [73.373288890000083, 33.112239182000053],
            [73.372740209000028, 33.110995692000074],
            [73.36932033100004, 33.103245136000055],
            [73.36850000000004, 33.101386000000048],
            [73.365873214000032, 33.102190121000035],
            [73.352842718000034, 33.106179060000045],
            [73.34619200000003, 33.10821500000003],
            [73.344238473000075, 33.10800580800003],
            [73.339029339000035, 33.107447993000051],
            [73.33344500000004, 33.106850000000065],
            [73.331323725000061, 33.106850000000065],
            [73.326985982000053, 33.106850000000065],
            [73.318876000000046, 33.106850000000065],
            [73.316212876000066, 33.106193841000049],
            [73.312967852000043, 33.105394310000065],
            [73.30945354000005, 33.104528429000027],
            [73.301077805000034, 33.102464757000064],
            [73.294952921000061, 33.100955665000072],
            [73.287462000000062, 33.099110000000053],
            [73.28687043900004, 33.099026976000061],
            [73.281394099000067, 33.09825838900008],
            [73.269878443000039, 33.096642204000034],
            [73.267728576000081, 33.096340477000069],
            [73.267728394000073, 33.09634045100006],
            [73.261502878000044, 33.095463922000079],
            [73.246033000000068, 33.089549000000034],
            [73.227276804000041, 33.091995733000033],
            [73.226912334000076, 33.092043278000062],
            [73.22509000000008, 33.092281000000071],
            [73.22006336100003, 33.092966291000039],
            [73.215845721000051, 33.093541290000076],
            [73.211632408000071, 33.094115699000042],
            [73.205058036000082, 33.09501199500005],
            [73.205058000000065, 33.095012000000054],
            [73.204559944000039, 33.095408904000067],
            [73.197893531000034, 33.095734253000046],
            [73.193462876000069, 33.095955143000026],
            [73.185043179000047, 33.096455652000031],
            [73.181274414000029, 33.096679687000062],
            [73.179065435000041, 33.096745824000038],
            [73.160888672000056, 33.097290039000029],
            [73.155368657000054, 33.097516515000052],
            [73.153581885000051, 33.097582664000072],
            [73.146874000000082, 33.097831000000042],
            [73.140033703000029, 33.096898392000071],
            [73.139155275000064, 33.096778627000049],
            [73.133283000000063, 33.095978000000059],
            [73.131914609000034, 33.089683580000042],
            [73.130195000000072, 33.081769000000065],
            [73.121545000000083, 33.076827000000037],
            [73.120704474000036, 33.077119362000076],
            [73.120446614000059, 33.076971808000053],
            [73.106222374000083, 33.081910095000069],
            [73.105908261000081, 33.091127898000025],
            [73.105548941000052, 33.101672292000046],
            [73.101344190000077, 33.107650947000025],
            [73.094655468000042, 33.107232146000058],
            [73.092511000000059, 33.107098000000065],
            [73.089808307000055, 33.103156702000035],
            [73.079334008000046, 33.087882183000033],
            [73.077684000000033, 33.085476000000028],
            [73.061004000000082, 33.073120000000074],
            [73.060266065000064, 33.072803767000039],
            [73.052356000000032, 33.069414000000052],
            [73.048603566000054, 33.068913513000041],
            [73.043089000000066, 33.068178000000046],
            [73.035909524000033, 33.068178000000046],
            [73.033822000000043, 33.068178000000046],
            [73.033221236000031, 33.068311575000052],
            [73.028263000000038, 33.069414000000052],
            [73.027997654000046, 33.071535907000055],
            [73.027920685000083, 33.07215141100005],
            [73.027027000000032, 33.079298000000051],
            [73.02480980200005, 33.084298444000069],
            [73.019691203000036, 33.095797583000035],
            [73.019675967000069, 33.095837502000052],
            [73.019222338000077, 33.094380439000076],
            [73.018453488000034, 33.096099172000038],
            [73.017746653000074, 33.097941037000055],
            [73.017746677000048, 33.097941062000075],
            [73.013477505000083, 33.109065514000065],
            [73.004310116000056, 33.110003407000079],
            [72.993331515000079, 33.111126601000024],
            [72.989379533000033, 33.111530911000045],
            [72.962192352000045, 33.114613216000066],
            [72.949481022000043, 33.116800739000041],
            [72.949405064000075, 33.116813811000043],
            [72.944272048000073, 33.117697206000059],
            [72.943363997000063, 33.119295976000046],
            [72.93620723500004, 33.131896286000028],
            [72.935878271000036, 33.132591424000054],
            [72.935847454000054, 33.132656535000024],
            [72.937061621000055, 33.132634429000063],
            [72.933101000000079, 33.141052000000059],
            [72.932508000000041, 33.142311000000063],
            [72.932295225000075, 33.146777550000024],
            [72.931890000000067, 33.155284000000051],
            [72.930847072000063, 33.157706378000057],
            [72.930766407000078, 33.157894931000044],
            [72.929775582000048, 33.160210982000024],
            [72.928228252000054, 33.163827860000026],
            [72.927332879000062, 33.165917010000044],
            [72.92706301100003, 33.166546685000071],
            [72.926330000000064, 33.16825700000004],
            [72.925429093000048, 33.168458211000029],
            [72.925422586000082, 33.168443332000038],
            [72.925337986000045, 33.168461548000039],
            [72.923511075000079, 33.168854912000029],
            [72.920213453000031, 33.169554359000074],
            [72.915257086000054, 33.170623167000031],
            [72.905944000000034, 33.172581000000037],
            [72.905629697000052, 33.172416895000026],
            [72.90312437700004, 33.171108812000057],
            [72.892971000000045, 33.165786000000026],
            [72.88185100000004, 33.165168000000051],
            [72.881324482000082, 33.165263759000027],
            [72.880542343000059, 33.16522013000008],
            [72.874612485000057, 33.166296358000068],
            [72.872844436000037, 33.165866277000077],
            [72.871443555000042, 33.165525511000055],
            [72.859726306000084, 33.162675192000052],
            [72.855518094000047, 33.161651583000037],
            [72.852198000000044, 33.160844000000054],
            [72.851591940000048, 33.16105903700003],
            [72.850909977000072, 33.160892974000035],
            [72.836545450000074, 33.165980171000058],
            [72.836545404000049, 33.165980187000059],
            [72.836529682000048, 33.165985765000073],
            [72.836529647000077, 33.165985767000052],
            [72.832580312000061, 33.167384435000031],
            [72.825058213000034, 33.163281337000058],
            [72.812661000000048, 33.15651900000006],
            [72.81239742300005, 33.15673157100008],
            [72.812007723000079, 33.15651900000006],
            [72.811729412000034, 33.156743454000036],
            [72.811465640000051, 33.156956182000044],
            [72.811134315000061, 33.156775229000061],
            [72.810745195000038, 33.156562712000039],
            [72.797207542000081, 33.16745392200005],
            [72.796695094000029, 33.167866193000066],
            [72.79669504900005, 33.167866229000026],
            [72.791568633000054, 33.171990494000056],
            [72.777184021000039, 33.176902454000071],
            [72.777020136000033, 33.176958416000048],
            [72.777301413000032, 33.177085444000056],
            [72.768740292000075, 33.180008838000049],
            [72.755653729000073, 33.18575626300003],
            [72.744509109000035, 33.190650816000073],
            [72.74436074700003, 33.190715974000057],
            [72.737840900000037, 33.193579395000029],
            [72.724606134000055, 33.195875984000054],
            [72.724606080000058, 33.195875993000072],
            [72.724606098000038, 33.195876062000025],
            [72.723635513000033, 33.196044432000065],
            [72.713990113000079, 33.208662799000024],
            [72.71373125000008, 33.209001385000079],
            [72.705844499000079, 33.213239299000065],
            [72.701173159000064, 33.212796978000029],
            [72.692441146000078, 33.211965374000044],
            [72.681076000000076, 33.210883000000024],
            [72.680185962000053, 33.210883000000024],
            [72.678912294000042, 33.210883000000024],
            [72.672808562000057, 33.210883000000024],
            [72.668676422000033, 33.210883000000024],
            [72.66254200000003, 33.210883000000024],
            [72.662461703000076, 33.210839213000042],
            [72.655401803000075, 33.206989342000043],
            [72.651932212000077, 33.205135699000039],
            [72.647716000000059, 33.202852000000064],
            [72.641164337000077, 33.200044072000026],
            [72.637704899000084, 33.198561418000054],
            [72.637383299000078, 33.198423586000047],
            [72.634743000000071, 33.197292000000061],
            [72.634129987000051, 33.197525582000026],
            [72.629796000000056, 33.199177000000077],
            [72.629796268000064, 33.199184535000029],
            [72.629825000000039, 33.199992000000066],
            [72.631234416000041, 33.206570395000028],
            [72.631237501000044, 33.206584793000047],
            [72.63259154800005, 33.212904755000068],
            [72.633184000000028, 33.215670000000046],
            [72.63799934900004, 33.220829346000073],
            [72.641023000000075, 33.224069000000043],
            [72.641024865000077, 33.224080190000052],
            [72.641071166000074, 33.224357971000074],
            [72.642386716000033, 33.232250487000044],
            [72.642649967000068, 33.234051376000025],
            [72.644383000000062, 33.245907000000045],
            [72.651374710000084, 33.255825859000026],
            [72.655315483000038, 33.261180241000034],
            [72.657065509000063, 33.263558024000076],
            [72.657233365000081, 33.263786093000078],
            [72.65726200000006, 33.263825000000054],
            [72.657643324000048, 33.26444101800007],
            [72.663567254000043, 33.274010943000064],
            [72.663648088000059, 33.27414152800003],
            [72.664178522000043, 33.274997294000059],
            [72.664541000000042, 33.275584000000038],
            [72.66504425100004, 33.275777516000062],
            [72.665104258000042, 33.275800591000063],
            [72.665118857000039, 33.275806205000038],
            [72.668079990000081, 33.276944851000053],
            [72.671818733000066, 33.278382513000054],
            [72.671820000000082, 33.278383000000076],
            [72.676126243000056, 33.276570079000066],
            [72.682459000000051, 33.273904000000073],
            [72.682460990000038, 33.273904663000053],
            [72.689862081000058, 33.276371484000038],
            [72.691911413000071, 33.277054536000037],
            [72.694217000000037, 33.277823000000069],
            [72.692555341000059, 33.279959963000067],
            [72.691805671000054, 33.280932091000068],
            [72.690280232000077, 33.282895708000069],
            [72.689339641000061, 33.284129893000056],
            [72.688758036000081, 33.284884084000055],
            [72.685665586000084, 33.288942688000077],
            [72.683124282000051, 33.292277955000031],
            [72.681339000000037, 33.294621000000063],
            [72.679911469000047, 33.299759600000073],
            [72.67970949100004, 33.300486652000075],
            [72.678539000000058, 33.304700000000025],
            [72.685906953000028, 33.312072942000043],
            [72.687362045000043, 33.313619348000032],
            [72.688216173000058, 33.314527076000047],
            [72.691697141000077, 33.31822562800005],
            [72.692682565000041, 33.319274600000028],
            [72.693606621000072, 33.320258246000037],
            [72.694777000000045, 33.321498000000076],
            [72.698373178000054, 33.32898950200007],
            [72.69952810500007, 33.331395429000054],
            [72.701478958000052, 33.335695267000062],
            [72.705594836000046, 33.34058951600008],
            [72.705595016000075, 33.340589523000062],
            [72.705599084000085, 33.340594567000039],
            [72.70561409000004, 33.340612411000052],
            [72.705613450000044, 33.340612381000028],
            [72.705690384000036, 33.34070777900007],
            [72.708776474000047, 33.344503403000033],
            [72.710943222000083, 33.349454880000053],
            [72.712694168000041, 33.353464127000052],
            [72.714309443000047, 33.354457492000051],
            [72.719537639000066, 33.357833485000072],
            [72.719823930000075, 33.359729790000074],
            [72.719776102000083, 33.360212385000068],
            [72.719776076000073, 33.360212646000036],
            [72.719685671000036, 33.361124858000039],
            [72.719171935000077, 33.362877035000054],
            [72.71936712400003, 33.363814382000044],
            [72.720529536000072, 33.366841374000046],
            [72.720534000000043, 33.366853000000049],
            [72.721381458000053, 33.368035610000049],
            [72.722267919000046, 33.369254994000073],
            [72.724428923000062, 33.372227595000027],
            [72.727596189000053, 33.376513213000067],
            [72.72795830900003, 33.377043481000044],
            [72.727960368000083, 33.377046496000048],
            [72.731535501000053, 33.382281726000031],
            [72.732853000000034, 33.38421100000005],
            [72.739012000000059, 33.390371000000073],
            [72.742293976000042, 33.390165853000042],
            [72.744687393000049, 33.390016248000052],
            [72.747185214000069, 33.389860117000069],
            [72.747971000000064, 33.389811000000066],
            [72.748020117000067, 33.389860117000069],
            [72.750573993000046, 33.392413993000048],
            [72.754685624000047, 33.39646652600004],
            [72.759730000000047, 33.401569000000052],
            [72.770369000000073, 33.398210000000063],
            [72.770535829000039, 33.398920151000027],
            [72.771127160000049, 33.401394869000057],
            [72.771876500000076, 33.40454268600007],
            [72.772507683000072, 33.407194149000077],
            [72.772683184000073, 33.407931394000059],
            [72.773168000000055, 33.409968000000049],
            [72.773160673000064, 33.409983880000027],
            [72.772526224000046, 33.411358928000027],
            [72.771916224000051, 33.412680988000034],
            [72.769809000000066, 33.417248000000029],
            [72.769821168000078, 33.417274775000067],
            [72.769811630000049, 33.417295456000033],
            [72.772607803000028, 33.42345619200006],
            [72.772607803000028, 33.433534622000025],
            [72.775968552000052, 33.439134598000066],
            [72.776541617000078, 33.440997302000028],
            [72.77820777900007, 33.446413040000039],
            [72.784265518000041, 33.448743820000061],
            [72.784265554000058, 33.448743834000027],
            [72.785486221000042, 33.449213028000031],
            [72.804079056000035, 33.457094193000046],
            [72.813486099000045, 33.460969925000029],
            [72.824682236000058, 33.467130661000056],
            [72.842599869000082, 33.468809128000032],
            [72.846536875000083, 33.46952482100005],
            [72.853448868000044, 33.470781326000065],
            [72.853448478000075, 33.470782592000035],
            [72.856473717000028, 33.471311533000062],
            [72.856473923000067, 33.471311569000079],
            [72.860534551000057, 33.47206852100004],
            [72.860548875000063, 33.472025550000069],
            [72.861079000000075, 33.472121000000072],
            [72.866257055000062, 33.474548620000064],
            [72.87220613900007, 33.477337720000037],
            [72.878996000000029, 33.480521000000067],
            [72.884090016000073, 33.480866234000075],
            [72.888224521000041, 33.481146440000032],
            [72.89077962600004, 33.48131963000003],
            [72.898452344000077, 33.481839605000062],
            [72.900982722000037, 33.482011095000075],
            [72.902655802000083, 33.482124484000053],
            [72.90381095500004, 33.482202822000033],
            [72.907196369000076, 33.482432267000036],
            [72.912033000000065, 33.482760000000042],
            [72.91385709900004, 33.483229102000053],
            [72.915167006000047, 33.483565970000029],
            [72.927544243000057, 33.486749012000075],
            [72.931630000000041, 33.48780000000005],
            [72.931581258000051, 33.487835471000039],
            [72.930084303000058, 33.488924836000024],
            [72.919312000000048, 33.496759000000054],
            [72.916994808000084, 33.498612712000067],
            [72.908113000000071, 33.505718000000059],
            [72.90292278000004, 33.512106127000038],
            [72.901957003000064, 33.513294807000079],
            [72.896235123000054, 33.52033730200003],
            [72.893555000000049, 33.523636000000067],
            [72.879556000000036, 33.528675000000078],
            [72.868592369000055, 33.529233688000033],
            [72.863125369000045, 33.529517712000029],
            [72.859470705000035, 33.529705157000024],
            [72.857719000000031, 33.529795000000036],
            [72.857722055000067, 33.529840862000071],
            [72.857722280000075, 33.529844234000052],
            [72.857721329000071, 33.529844284000035],
            [72.857877469000073, 33.532205432000069],
            [72.857823726000049, 33.532174767000072],
            [72.857828962000042, 33.532253939000043],
            [72.857829025000058, 33.53225397500006],
            [72.857829224000056, 33.532254089000048],
            [72.857830999000043, 33.532280756000034],
            [72.85852754800004, 33.542747912000038],
            [72.858531129000085, 33.54280172700004],
            [72.858787512000049, 33.54664370900008],
            [72.858108469000058, 33.547398427000076],
            [72.858112173000052, 33.547399705000032],
            [72.85385042300004, 33.552135357000054],
            [72.848760000000084, 33.557792000000063],
            [72.842451779000044, 33.559894657000029],
            [72.839933476000056, 33.560734058000037],
            [72.83884400200003, 33.561097202000042],
            [72.829822303000071, 33.564104315000066],
            [72.82563672200007, 33.565499453000029],
            [72.825630104000084, 33.565501659000063],
            [72.823562000000038, 33.56619100000006],
            [72.823123481000039, 33.568827724000073],
            [72.821323000000064, 33.579629000000068],
            [72.821327583000084, 33.57966199100008],
            [72.82247046100008, 33.587889897000025],
            [72.822994171000062, 33.591660235000063],
            [72.823498047000044, 33.595287787000075],
            [72.824121511000044, 33.599783474000048],
            [72.824122000000045, 33.599787000000049],
            [72.824121511000044, 33.599787413000058],
            [72.824115401000029, 33.59979257100008],
            [72.822184992000075, 33.601422161000073],
            [72.815123567000057, 33.607380115000069],
            [72.810275842000067, 33.611470298000029],
            [72.806205000000034, 33.614905000000078],
            [72.803405000000055, 33.622185000000059],
            [72.803416815000048, 33.622208630000046],
            [72.804550808000045, 33.624476617000028],
            [72.80793043400007, 33.631235868000033],
            [72.81012400000003, 33.635623000000066],
            [72.810102136000069, 33.635625187000073],
            [72.806707141000061, 33.635964727000044],
            [72.801071000000036, 33.636528408000061],
            [72.793326000000036, 33.637303000000031],
            [72.790021832000036, 33.637359772000025],
            [72.779580858000031, 33.637479240000062],
            [72.770549687000084, 33.637595038000029],
            [72.762645263000081, 33.637696388000052],
            [72.760051719000046, 33.637729642000068],
            [72.749651000000028, 33.637863000000038],
            [72.749051077000047, 33.640520176000052],
            [72.748620007000056, 33.642428300000063],
            [72.746718558000055, 33.650848034000035],
            [72.745731000000035, 33.65522100000004],
            [72.744669836000071, 33.662386108000078],
            [72.74349200000006, 33.67033900000007],
            [72.740328712000064, 33.673639833000038],
            [72.737967291000075, 33.676103933000036],
            [72.736076222000065, 33.678077228000063],
            [72.73380734300008, 33.680444761000047],
            [72.732215878000034, 33.682105426000078],
            [72.730613000000062, 33.683778000000075],
            [72.730636454000035, 33.683806584000024],
            [72.733378234000043, 33.687148042000047],
            [72.736389554000084, 33.690817994000042],
            [72.740319101000068, 33.695607005000056],
            [72.742589058000078, 33.698373445000072],
            [72.744497810000041, 33.700699676000056],
            [72.747579664000057, 33.704455589000077],
            [72.748056562000045, 33.705039235000072],
            [72.748531000000071, 33.70561500000008],
            [72.748531000000071, 33.70566094000003],
            [72.748531000000071, 33.707718859000067],
            [72.748531000000071, 33.709421040000052],
            [72.748531000000071, 33.71069583600007],
            [72.748531000000071, 33.712114148000069],
            [72.748531000000071, 33.716371812000034],
            [72.748531000000071, 33.718494000000078],
            [72.741109062000078, 33.725260992000074],
            [72.737427742000079, 33.728619303000073],
            [72.736240745000032, 33.729699707000066],
            [72.735118647000036, 33.730722785000069],
            [72.734053062000044, 33.731694339000057],
            [72.733132037000075, 33.732534088000079],
            [72.729493000000048, 33.735852000000079],
            [72.724264597000058, 33.744138482000039],
            [72.723030927000082, 33.746093722000069],
            [72.722054882000066, 33.747640801000045],
            [72.721579911000049, 33.748393591000024],
            [72.719004341000073, 33.752475448000041],
            [72.717011018000051, 33.755634906000068],
            [72.715634187000035, 33.757817070000044],
            [72.713734964000082, 33.760826870000074],
            [72.712297355000032, 33.763105333000055],
            [72.712169593000056, 33.763307823000048],
            [72.708640096000067, 33.768901713000048],
            [72.706769732000055, 33.771866347000071],
            [72.705702390000056, 33.773558145000038],
            [72.705692796000051, 33.773573347000024],
            [72.70445314300008, 33.775537604000078],
            [72.702036852000049, 33.779367483000044],
            [72.702012595000042, 33.779405932000031],
            [72.699817000000053, 33.782886000000076],
            [72.699456420000047, 33.785265569000046],
            [72.699028048000059, 33.788088497000047],
            [72.698482776000048, 33.791687995000075],
            [72.698298730000033, 33.79290293300005],
            [72.697711926000068, 33.796776593000061],
            [72.697443044000067, 33.798551560000078],
            [72.697440174000064, 33.798570506000033],
            [72.697258674000068, 33.79976864200006],
            [72.697017000000073, 33.801364000000035],
            [72.697639121000066, 33.802280837000069],
            [72.697640749000072, 33.802291693000029],
            [72.697640785000033, 33.802291748000073],
            [72.699890137000068, 33.805725098000039],
            [72.703674291000084, 33.811279261000038],
            [72.703674316000047, 33.811279297000056],
            [72.704699738000045, 33.812686271000075],
            [72.705757288000029, 33.814244813000073],
            [72.707346715000028, 33.816573112000071],
            [72.711352084000055, 33.822440443000062],
            [72.712602136000044, 33.824271603000057],
            [72.714893741000083, 33.827613022000037],
            [72.716937125000072, 33.830299248000074],
            [72.71916318500007, 33.833210344000065],
            [72.720091091000029, 33.834423798000046],
            [72.721549950000053, 33.836341517000051],
            [72.72172689100006, 33.83657026700007],
            [72.722214000000065, 33.837200000000053],
            [72.723454329000049, 33.839432711000029],
            [72.72435185300003, 33.841048339000054],
            [72.725093529000048, 33.842383457000039],
            [72.730636125000046, 33.852360891000046],
            [72.731105202000037, 33.853055273000052],
            [72.731229116000065, 33.853241241000035],
            [72.731620220000082, 33.853828203000035],
            [72.734025870000039, 33.857438558000069],
            [72.73477095800007, 33.85854217800005],
            [72.734908261000044, 33.858745550000037],
            [72.73496855500008, 33.858837812000047],
            [72.735001830000044, 33.858888729000057],
            [72.736031048000029, 33.860463647000074],
            [72.736736090000079, 33.861503604000063],
            [72.737332000000038, 33.862398000000042],
            [72.737856006000072, 33.862581371000033],
            [72.740587234000031, 33.86353874200006],
            [72.740587270000049, 33.863538755000036],
            [72.74853321300003, 33.866319643000054],
            [72.748533249000047, 33.86631965600003],
            [72.748548396000047, 33.866315066000027],
            [72.74854649200006, 33.866312306000054],
            [72.756131924000044, 33.864013852000028],
            [72.76700900000003, 33.860718000000077],
            [72.767373010000028, 33.860334746000035],
            [72.767646943000045, 33.860046330000046],
            [72.775653817000034, 33.851533054000072],
            [72.775846481000031, 33.851331711000057],
            [72.775930405000054, 33.851247787000034],
            [72.776319504000071, 33.850782394000078],
            [72.777456218000054, 33.849413085000037],
            [72.777479420000077, 33.849385242000039],
            [72.779898286000048, 33.846534758000075],
            [72.779898321000076, 33.846534722000058],
            [72.780883789000029, 33.845520020000038],
            [72.781302561000075, 33.845143836000034],
            [72.783838624000055, 33.842631886000049],
            [72.789463568000031, 33.838055629000053],
            [72.790526000000057, 33.837200000000053],
            [72.792856301000029, 33.83440405500005],
            [72.796088831000077, 33.83052559600003],
            [72.796081543000071, 33.830505371000072],
            [72.796349274000079, 33.829632335000042],
            [72.797485352000081, 33.825927734000061],
            [72.798706055000082, 33.821289062000062],
            [72.799423469000033, 33.820619159000046],
            [72.807922363000046, 33.812683105000076],
            [72.810729980000076, 33.805725098000039],
            [72.812500000000057, 33.801513672000056],
            [72.822875977000081, 33.796691895000038],
            [72.824841173000038, 33.800185579000072],
            [72.823447384000076, 33.79754136400004],
            [72.823002219000045, 33.796691859000077],
            [72.822954036000056, 33.79659991300008],
            [72.822953642000073, 33.796599161000074],
            [72.822838316000059, 33.796379088000037],
            [72.822826509000038, 33.796356552000077],
            [72.822693191000042, 33.796103220000077],
            [72.822692258000075, 33.79610144600008],
            [72.822616124000035, 33.795956771000078],
            [72.821772709000072, 33.794354080000062],
            [72.819985185000064, 33.790957352000078],
            [72.819985000000031, 33.790957000000049],
            [72.820800934000033, 33.788917351000066],
            [72.826248969000062, 33.775298501000066],
            [72.827196436000065, 33.77293005000007],
            [72.827200196000035, 33.772920651000049],
            [72.829236054000035, 33.767831468000054],
            [72.829241785000079, 33.767816785000036],
            [72.829262000000028, 33.767765000000054],
            [72.829263002000062, 33.767764833000058],
            [72.832963167000059, 33.767148087000066],
            [72.835280923000084, 33.766761762000044],
            [72.837997801000029, 33.766308923000054],
            [72.839432891000058, 33.766069710000068],
            [72.841189000000043, 33.765777000000071],
            [72.841189413000052, 33.765777014000037],
            [72.845179833000032, 33.765919607000058],
            [72.851386705000039, 33.766141400000038],
            [72.854893018000041, 33.766266693000034],
            [72.859637579000037, 33.766436233000036],
            [72.859743000000037, 33.766440000000046],
            [72.85974312500008, 33.766439969000032],
            [72.859743250000065, 33.766439938000076],
            [72.85981028100008, 33.76642318200004],
            [72.860960162000083, 33.766135733000056],
            [72.863056501000074, 33.765611687000046],
            [72.863057001000072, 33.765611562000061],
            [72.863057251000043, 33.765611499000045],
            [72.863065343000073, 33.765609477000055],
            [72.866202247000047, 33.764825310000049],
            [72.868880839000042, 33.764155273000028],
            [72.872996000000057, 33.763127000000054],
            [72.881964529000072, 33.759248332000027],
            [72.882710146000079, 33.758925871000031],
            [72.882710650000035, 33.758925653000063],
            [72.882717317000072, 33.758922770000027],
            [72.882722684000044, 33.758920449000072],
            [72.883188673000063, 33.758718922000071],
            [72.883336468000039, 33.758655039000075],
            [72.89338706500007, 33.754308989000037],
            [72.89751300000006, 33.752524000000051],
            [72.901301557000068, 33.751627136000025],
            [72.907873669000082, 33.750070501000039],
            [72.908232747000056, 33.749986274000037],
            [72.911250433000077, 33.749271669000052],
            [72.913596126000073, 33.748716197000078],
            [72.915305784000054, 33.748311340000043],
            [72.922695045000069, 33.746561523000025],
            [72.923085584000034, 33.744478335000053],
            [72.923717986000042, 33.741105665000077],
            [72.924069016000033, 33.739231223000047],
            [72.924580189000039, 33.736501652000072],
            [72.920723998000028, 33.734848515000067],
            [72.918078338000043, 33.733715051000047],
            [72.914689988000077, 33.732262462000051],
            [72.910265287000072, 33.730316655000024],
            [72.907757728000036, 33.729212370000027],
            [72.904309546000036, 33.727694774000042],
            [72.900288206000084, 33.725925436000068],
            [72.892911000000083, 33.722680000000025],
            [72.886900161000028, 33.722345873000052],
            [72.87722524600008, 33.721804024000051],
            [72.867919922000056, 33.721923828000058],
            [72.860629000000074, 33.721807000000069],
            [72.84983644600004, 33.716410723000024],
            [72.844925000000046, 33.713955000000055],
            [72.839744643000074, 33.708774643000027],
            [72.834453025000073, 33.703483025000025],
            [72.832993854000051, 33.702023854000061],
            [72.82485900000006, 33.693888000000072],
            [72.834719437000047, 33.68304167000008],
            [72.839813541000069, 33.677437133000069],
            [72.842468248000046, 33.674516996000079],
            [72.845838606000029, 33.670809654000038],
            [72.848062869000046, 33.668362999000067],
            [72.851032000000032, 33.66509700000006],
            [72.852838054000074, 33.66219433200007],
            [72.856181691000074, 33.656820477000053],
            [72.858517327000072, 33.653066670000044],
            [72.860753513000077, 33.649474307000048],
            [72.873174715000062, 33.629509491000078],
            [72.87546100000003, 33.625835000000052],
            [72.880482380000046, 33.618625003000034],
            [72.889281179000079, 33.605992597000068],
            [72.896779457000036, 33.595224686000051],
            [72.907747278000045, 33.579477869000073],
            [72.909488000000067, 33.576977000000056],
            [72.922974083000042, 33.582800664000047],
            [72.92853302900005, 33.585207443000058],
            [72.94066411600005, 33.590439709000066],
            [72.947876000000065, 33.59355400000004],
            [72.978610531000072, 33.610764302000064],
            [72.978655119000052, 33.610733076000031],
            [72.983719544000053, 33.61366070400004],
            [72.975723939000034, 33.622632872000054],
            [72.971146302000079, 33.62483013800005],
            [72.967301087000067, 33.626661192000029],
            [72.968704896000077, 33.63123882900004],
            [72.97151251300005, 33.629651915000068],
            [72.975296693000075, 33.627271544000052],
            [72.980301576000045, 33.629651915000068],
            [72.984146790000068, 33.63123882900004],
            [72.988114076000045, 33.63404644600007],
            [73.000138001000039, 33.621045958000025],
            [73.025528626000039, 33.630262267000035],
            [73.034467232000054, 33.634953923000069],
            [73.066566327000032, 33.652010000000075],
            [73.085437658000046, 33.665563973000076],
            [73.086106026000039, 33.663941160000036],
            [73.098169970000072, 33.648249663000058],
            [73.100841563000074, 33.64477473900007],
            [73.104123227000059, 33.640506299000037],
            [73.104123263000076, 33.64050625200008],
            [73.115082526000037, 33.626251606000039],
            [73.115071675000081, 33.626249506000079],
            [73.115120870000055, 33.626186070000074],
            [73.115659874000073, 33.625500654000064],
            [73.121167983000078, 33.618496359000062],
            [73.127871514000049, 33.609971924000035],
            [73.129640867000035, 33.607634835000056],
            [73.129727995000053, 33.607612159000041],
            [73.131413837000082, 33.605423805000044],
            [73.131667303000029, 33.605094786000052],
            [73.132317563000072, 33.604250697000055],
            [73.132316830000036, 33.604214004000028],
            [73.132314615000041, 33.604103155000075],
            [73.131770323000069, 33.604822095000031],
            [73.132267998000032, 33.601770049000038],
            [73.133274013000062, 33.598152394000067],
            [73.133818248000068, 33.597665237000058],
            [73.133818284000029, 33.597665205000055],
            [73.135848446000068, 33.595847961000061],
            [73.140100011000072, 33.59300584500005],
            [73.140471508000076, 33.593288635000079],
            [73.14049086700004, 33.593303371000047],
            [73.141985112000043, 33.591260759000079],
            [73.144818891000057, 33.587464155000077],
            [73.144818918000055, 33.58746411900006],
            [73.154330372000061, 33.574720986000045],
            [73.160753617000069, 33.565730430000031],
            [73.160753653000029, 33.565730380000048],
            [73.164605075000054, 33.560339580000061],
            [73.164590212000064, 33.559570528000052],
            [73.165208990000053, 33.559494286000074],
            [73.167484053000067, 33.556309903000056],
            [73.167487128000062, 33.556305382000062],
            [73.167496279000034, 33.556312688000048],
            [73.170356155000036, 33.552087948000064],
            [73.170373328000039, 33.552062579000051],
            [73.169661332000032, 33.549986534000027],
            [73.16917823600005, 33.549110245000065],
            [73.168180945000074, 33.549146302000054],
            [73.164077555000063, 33.548450770000045],
            [73.163154508000048, 33.545291697000039],
            [73.163273964000041, 33.544588066000074],
            [73.163455763000059, 33.54363203500003],
            [73.163674227000058, 33.542563803000064],
            [73.163827423000043, 33.541328046000046],
            [73.159908773000041, 33.539600549000056],
            [73.115413239000077, 33.519985142000053],
            [73.101632710000047, 33.513910138000028],
            [73.10386595500006, 33.506255784000075],
            [73.105176028000074, 33.501809817000037],
            [73.105721707000043, 33.499957960000074],
            [73.109308510000062, 33.487785509000048],
            [73.111697089000074, 33.482682265000051],
            [73.113228474000039, 33.47972061300004],
            [73.11336366300003, 33.479459161000079],
            [73.115009463000035, 33.476306585000032],
            [73.116709287000049, 33.473050526000065],
            [73.11836388200004, 33.470588118000023],
            [73.11836390600007, 33.470588082000063],
            [73.123956669000052, 33.462264800000071],
            [73.125274994000051, 33.460302837000029],
            [73.125275030000068, 33.460302783000031],
            [73.127797231000045, 33.456549184000039],
            [73.134146693000048, 33.459160906000079],
            [73.134149921000073, 33.459139933000074],
            [73.135491547000072, 33.459687074000044],
            [73.148580218000063, 33.465024885000048],
            [73.156217587000071, 33.468139550000046],
            [73.162145777000035, 33.470557179000025],
            [73.162161887000082, 33.470563750000053],
            [73.168150257000036, 33.473005921000038],
            [73.176159053000049, 33.476272062000078],
            [73.17963992600005, 33.477691629000049],
            [73.191107278000061, 33.482368234000035],
            [73.207178161000058, 33.491567116000056],
            [73.214239497000051, 33.495681543000046],
            [73.222761306000052, 33.500646945000028],
            [73.223464382000031, 33.501056606000077],
            [73.22581531700007, 33.502433144000065],
            [73.225826838000046, 33.502439890000062],
            [73.225835114000063, 33.502444736000029],
            [73.227624528000035, 33.503492488000063],
            [73.232663906000084, 33.50645454000005],
            [73.239380299000061, 33.513894744000027],
            [73.239838967000082, 33.514400946000023],
            [73.242931725000062, 33.517814223000073],
            [73.245477032000053, 33.520623314000034],
            [73.247606699000073, 33.522973690000072],
            [73.247863595000069, 33.523257209000064],
            [73.247860862000039, 33.523259357000029],
            [73.248019072000034, 33.523434305000023],
            [73.248019108000051, 33.523434345000055],
            [73.250788229000079, 33.526496444000031],
            [73.256757419000053, 33.533097183000052],
            [73.256774604000043, 33.533091715000069],
            [73.262490753000066, 33.539400259000047],
            [73.269967089000033, 33.546695793000026],
            [73.269971161000058, 33.54670821600007],
            [73.269938608000075, 33.546710131000054],
            [73.269946233000042, 33.546732692000035],
            [73.270331193000061, 33.547871713000063],
            [73.270756530000028, 33.549128039000038],
            [73.268948021000028, 33.550036227000078],
            [73.26760110500004, 33.551552475000051],
            [73.270309102000056, 33.556728150000026],
            [73.271016710000083, 33.557905081000058],
            [73.271016732000078, 33.557905117000075],
            [73.271086377000074, 33.558020954000028],
            [73.271107191000056, 33.558002638000062],
            [73.272284881000076, 33.559965276000071],
            [73.27382526100007, 33.562520977000077],
            [73.274734240000043, 33.563496052000062],
            [73.27685533600004, 33.565771382000037],
            [73.279001980000032, 33.568074119000073],
            [73.279832001000045, 33.568964494000056],
            [73.280492493000054, 33.570743191000076],
            [73.281538557000033, 33.573560226000041],
            [73.281649037000079, 33.573857747000034],
            [73.281718934000082, 33.574045980000051],
            [73.282575030000032, 33.57845246100004],
            [73.282371157000057, 33.580314423000061],
            [73.282101341000043, 33.582778638000036],
            [73.281978695000078, 33.583898759000078],
            [73.283071280000058, 33.588490777000061],
            [73.284019897000064, 33.591441308000071],
            [73.285567056000048, 33.596253518000026],
            [73.286255936000032, 33.600711827000055],
            [73.285902561000057, 33.605797023000036],
            [73.28596714300005, 33.607974136000053],
            [73.286761091000074, 33.610836655000071],
            [73.286969365000061, 33.611587573000065],
            [73.287096450000035, 33.611830052000073],
            [73.288703984000051, 33.614897235000058],
            [73.289965014000074, 33.61741171400007],
            [73.290405975000056, 33.618290986000034],
            [73.289705826000045, 33.621001577000072],
            [73.289783129000057, 33.621096759000068],
            [73.291183903000046, 33.622821521000049],
            [73.293319986000029, 33.62444800600008],
            [73.301459632000046, 33.627137889000039],
            [73.306373656000062, 33.629853624000077],
            [73.312993383000048, 33.633158791000028],
            [73.316070710000076, 33.633491735000064],
            [73.316121097000064, 33.633495013000072],
            [73.317182949000085, 33.633564095000054],
            [73.317492423000033, 33.633584229000064],
            [73.324641073000066, 33.632964540000046],
            [73.32599172700003, 33.632846603000075],
            [73.329738723000048, 33.63252108100005],
            [73.331597993000059, 33.632438268000044],
            [73.334686760000068, 33.632300692000058],
            [73.334697944000084, 33.632318256000076],
            [73.336716857000056, 33.632222986000045],
            [73.339710859000036, 33.63280345700008],
            [73.341947377000054, 33.63592045200005],
            [73.343611808000048, 33.639793060000045],
            [73.343633399000055, 33.639776647000076],
            [73.344076241000039, 33.640781140000058],
            [73.344859700000029, 33.643735096000057],
            [73.345926218000045, 33.647779425000067],
            [73.347783349000053, 33.650310790000049],
            [73.351271179000037, 33.653221408000036],
            [73.353620230000047, 33.655179564000036],
            [73.357042320000062, 33.657369780000067],
            [73.358705708000059, 33.658485622000057],
            [73.363429803000031, 33.664969772000063],
            [73.363853648000031, 33.665628644000037],
            [73.364187439000034, 33.666147525000042],
            [73.365484888000083, 33.667834909000078],
            [73.372771682000064, 33.677311675000055],
            [73.371120270000063, 33.681444231000057],
            [73.358222065000064, 33.681965024000078],
            [73.345868254000038, 33.683539403000054],
            [73.345868177000057, 33.683539439000072],
            [73.337895171000071, 33.687256791000038],
            [73.332236867000063, 33.693100556000047],
            [73.331678422000039, 33.698032936000061],
            [73.331746591000069, 33.700377873000036],
            [73.331870210000034, 33.704705675000071],
            [73.330218785000056, 33.708460424000066],
            [73.328266916000075, 33.709612247000052],
            [73.327984059000073, 33.709779613000023],
            [73.327983998000036, 33.709779649000041],
            [73.325556522000056, 33.711215984000034],
            [73.320262921000051, 33.714360012000043],
            [73.320001541000067, 33.714578782000046],
            [73.312399631000062, 33.720941427000071],
            [73.30464535200008, 33.726014909000071],
            [73.301885066000068, 33.727960137000025],
            [73.29865772900007, 33.730234506000045],
            [73.298384236000061, 33.730608981000046],
            [73.298384200000044, 33.730609030000039],
            [73.295392225000057, 33.734705729000041],
            [73.291083136000054, 33.742136814000048],
            [73.290104635000034, 33.743568943000071],
            [73.28805563800006, 33.746567842000047],
            [73.282261599000037, 33.753216354000074],
            [73.28205328100006, 33.753455394000071],
            [73.281565476000083, 33.754015138000057],
            [73.276477213000078, 33.75955156200007],
            [73.274912487000051, 33.761599539000031],
            [73.272535798000035, 33.764701803000037],
            [73.271013310000058, 33.766689051000071],
            [73.271013010000047, 33.766689443000075],
            [73.271004970000035, 33.766699937000055],
            [73.270512739000083, 33.767342428000063],
            [73.268826993000062, 33.769040535000045],
            [73.264111524000043, 33.773791154000037],
            [73.256945778000045, 33.777778010000077],
            [73.253809146000037, 33.778721488000031],
            [73.251594143000034, 33.779387746000054],
            [73.25037573000003, 33.781193407000046],
            [73.246967237000035, 33.786080876000028],
            [73.24172363100007, 33.78909558600003],
            [73.239806908000048, 33.790197569000043],
            [73.23781512100004, 33.791173043000072],
            [73.237164510000071, 33.791491679000046],
            [73.234959016000062, 33.792492202000062],
            [73.23270532500004, 33.793533122000042],
            [73.232644747000052, 33.79356110100008],
            [73.226752219000048, 33.796282703000031],
            [73.226206238000032, 33.796420203000025],
            [73.216943573000037, 33.798752912000054],
            [73.214067012000044, 33.799807170000065],
            [73.21215189000003, 33.800509061000071],
            [73.210162274000083, 33.801238254000054],
            [73.210182985000074, 33.801237384000046],
            [73.20191376300005, 33.804261327000063],
            [73.19240280300005, 33.806666716000052],
            [73.191592583000045, 33.806871626000031],
            [73.183815086000038, 33.808838861000027],
            [73.187333668000065, 33.809708123000064],
            [73.187338257000079, 33.809709257000065],
            [73.187354490000075, 33.809713267000063],
            [73.189491363000059, 33.810229070000048],
            [73.190096398000037, 33.810375114000067],
            [73.195146419000082, 33.811594099000047],
            [73.197914731000083, 33.812262321000048],
            [73.200672968000049, 33.81292811000003],
            [73.210652108000033, 33.815336896000076],
            [73.21126694000003, 33.815485306000028],
            [73.21127436200004, 33.815487097000073],
            [73.214502370000048, 33.816266281000026],
            [73.217306945000075, 33.816943255000069],
            [73.21983300100004, 33.817553000000032],
            [73.222207000000083, 33.818126000000063],
            [73.228861638000069, 33.820650101000069],
            [73.234465105000083, 33.82277511500007],
            [73.240556947000073, 33.825085748000049],
            [73.241425000000049, 33.825415000000078],
            [73.243202439000072, 33.829858866000052],
            [73.24518336400007, 33.834811476000027],
            [73.245526419000043, 33.835669168000038],
            [73.246738663000031, 33.838699961000032],
            [73.248051000000032, 33.841981000000033],
            [73.25027816100004, 33.843153661000031],
            [73.255812947000038, 33.846066567000037],
            [73.258888471000034, 33.847684660000027],
            [73.260640260000059, 33.848606610000047],
            [73.260640780000074, 33.848605831000043],
            [73.268592000000069, 33.836680000000058],
            [73.268592354000077, 33.836680219000073],
            [73.270550074000084, 33.83789151600007],
            [73.274171961000036, 33.840133624000032],
            [73.27612914000008, 33.841345204000049],
            [73.27676223900005, 33.841737120000062],
            [73.277619938000043, 33.842268073000071],
            [73.277995588000067, 33.842500617000042],
            [73.28092771300004, 33.844316351000032],
            [73.282507000000066, 33.845294000000024],
            [73.288126172000034, 33.845634486000051],
            [73.294164579000039, 33.846000374000027],
            [73.304374000000053, 33.846619000000032],
            [73.305607040000041, 33.848345256000073],
            [73.307945027000073, 33.85161843800006],
            [73.314314000000081, 33.860535000000027],
            [73.318785001000037, 33.862902070000075],
            [73.325579000000062, 33.866499000000033],
            [73.328284771000028, 33.870556326000042],
            [73.329085897000084, 33.871758066000041],
            [73.330357805000062, 33.873666009000033],
            [73.333530000000053, 33.878426000000047],
            [73.333707470000036, 33.881600859000059],
            [73.333937052000067, 33.885731268000029],
            [73.333984711000085, 33.886595947000046],
            [73.334055396000053, 33.887878375000071],
            [73.334194000000082, 33.890354000000059],
            [73.337333367000042, 33.894749067000078],
            [73.342530069000077, 33.902024370000049],
            [73.343915540000069, 33.903964009000049],
            [73.343921593000061, 33.903973884000038],
            [73.347446000000048, 33.908908000000054],
            [73.347730218000038, 33.910187195000049],
            [73.347730226000067, 33.910187231000066],
            [73.350096000000065, 33.920835000000068],
            [73.352533700000038, 33.922053981000033],
            [73.356549508000057, 33.924061602000052],
            [73.359345528000063, 33.925460260000079],
            [73.359373000000062, 33.925474000000065],
            [73.36426188400003, 33.928325163000068],
            [73.365419321000047, 33.929000462000033],
            [73.368736156000068, 33.930935645000034],
            [73.37036469700007, 33.93188501000003],
            [73.373962163000044, 33.933983457000068],
            [73.375278000000037, 33.934751000000063],
            [73.380861749000076, 33.939869847000068],
            [73.38200306400006, 33.940917052000032],
            [73.382003100000077, 33.94091708600007],
            [73.383228000000031, 33.942040000000077],
            [73.383299951000083, 33.942279831000064],
            [73.387204000000054, 33.95529300000004],
            [73.387565874000074, 33.958007468000062],
            [73.388271343000042, 33.963299286000051],
            [73.388529000000062, 33.965232000000071],
            [73.390019006000045, 33.96672000600006],
            [73.392871997000043, 33.969572997000057],
            [73.39287203300006, 33.969573033000074],
            [73.396179916000051, 33.972880916000065],
            [73.398887383000044, 33.975588383000058],
            [73.399133000000063, 33.975834000000077],
            [73.400372814000036, 33.975835000000075],
            [73.407746000000031, 33.975835000000075],
            [73.408408503000032, 33.97520559000003],
            [73.408409000000063, 33.975205118000076],
            [73.40841585000004, 33.975198610000064],
            [73.409199253000054, 33.974454338000044],
            [73.411763506000057, 33.972018173000038],
            [73.413477785000055, 33.970389523000051],
            [73.41347791700008, 33.970389589000035],
            [73.413486341000066, 33.97038158600003],
            [73.413494764000063, 33.970373584000072],
            [73.413494723000042, 33.970373431000041],
            [73.415957515000059, 33.968033658000024],
            [73.41900892600006, 33.965134668000076],
            [73.420999000000052, 33.963244000000032],
            [73.420999375000065, 33.963244119000024],
            [73.427297415000055, 33.965248277000057],
            [73.42778802600003, 33.965404400000068],
            [73.430227018000039, 33.966180533000056],
            [73.433049236000045, 33.967078617000027],
            [73.435453327000062, 33.967835874000059],
            [73.435578000000078, 33.967883000000029],
            [73.436747317000084, 33.969247220000057],
            [73.440314379000029, 33.97341000800003],
            [73.444661155000063, 33.97847742600004],
            [73.44698945600004, 33.981197692000023],
            [73.447504000000038, 33.981798000000026],
            [73.448387933000049, 33.982658694000065],
            [73.448700170000052, 33.982961702000068],
            [73.452172336000046, 33.986342448000073],
            [73.455512984000052, 33.989595138000027],
            [73.457833415000039, 33.991855847000068],
            [73.462138629000037, 33.996046334000027],
            [73.465588102000083, 33.999404985000069],
            [73.46662400200006, 34.000413610000066],
            [73.468693672000029, 34.002428787000042],
            [73.468785414000081, 34.002518114000054],
            [73.471540466000079, 34.005200627000079],
            [73.472686000000067, 34.006316000000027],
            [73.474207126000067, 34.007475963000047],
            [73.48038968700007, 34.012185529000078],
            [73.486600000000067, 34.016918000000032],
            [73.490466287000061, 34.017200934000073],
            [73.499635935000072, 34.01787196600003],
            [73.501413387000071, 34.018002034000062],
            [73.509794029000034, 34.018615259000057],
            [73.511034000000052, 34.018706000000066],
            [73.52448000000004, 34.012543000000051],
            [73.519394000000034, 34.000108000000068],
            [73.526789434000079, 33.994354949000069],
            [73.532763981000073, 33.989707862000046],
            [73.534655000000043, 33.988237000000026],
            [73.535229883000056, 33.983264252000026],
            [73.53634783900003, 33.973559762000036],
            [73.536350000000084, 33.973541000000068],
            [73.537637993000033, 33.970175045000076],
            [73.53871864100006, 33.967350951000071],
            [73.539117978000036, 33.966304345000026],
            [73.539492221000046, 33.96532350800004],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "119",
      properties: { name: "Sahiwal", count: 475 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.270662190000053, 30.907647207000025],
            [73.270372137000038, 30.899224268000069],
            [73.270263000000057, 30.896055000000047],
            [73.270348733000048, 30.895806363000077],
            [73.270415745000037, 30.895612021000034],
            [73.273760296000034, 30.885912408000024],
            [73.274002324000037, 30.88521049700006],
            [73.275099000000068, 30.882030000000043],
            [73.275540192000051, 30.881907413000079],
            [73.283805000000029, 30.879611000000068],
            [73.290092000000072, 30.881062000000043],
            [73.299281000000065, 30.884448000000077],
            [73.301216000000068, 30.888801000000058],
            [73.301419896000084, 30.885741508000024],
            [73.302107254000077, 30.875427580000064],
            [73.30218300000007, 30.874291000000028],
            [73.301579668000045, 30.873652226000047],
            [73.297219175000066, 30.869035575000055],
            [73.293961000000081, 30.865586000000064],
            [73.290204819000053, 30.863707632000057],
            [73.287190000000066, 30.86220000000003],
            [73.297880946000078, 30.859876132000068],
            [73.298314000000062, 30.859782000000052],
            [73.303150000000073, 30.857364000000075],
            [73.307987000000082, 30.851560000000063],
            [73.301216000000068, 30.849626000000058],
            [73.292355187000055, 30.843718537000029],
            [73.289608000000044, 30.841887000000042],
            [73.283804000000032, 30.838986000000034],
            [73.283804000000032, 30.838035782000077],
            [73.283804000000032, 30.82931300000007],
            [73.289608000000044, 30.820123000000024],
            [73.291059000000075, 30.814320000000066],
            [73.288598172000036, 30.810218337000038],
            [73.288157000000069, 30.809483000000057],
            [73.279452000000049, 30.809483000000057],
            [73.279652798000029, 30.809014495000042],
            [73.279652764000048, 30.809014494000053],
            [73.283852772000046, 30.799213269000063],
            [73.285255000000063, 30.795941000000028],
            [73.28562938500005, 30.794497195000076],
            [73.288641000000041, 30.782883000000027],
            [73.297015818000034, 30.774067066000043],
            [73.297830000000033, 30.773210000000063],
            [73.308954000000028, 30.773210000000063],
            [73.309723047000034, 30.773742473000027],
            [73.315241000000071, 30.777563000000043],
            [73.322065417000033, 30.782681092000075],
            [73.32298000000003, 30.783367000000055],
            [73.330234000000075, 30.785301000000061],
            [73.340874000000042, 30.786268000000064],
            [73.340874113000041, 30.786267919000068],
            [73.34087500000004, 30.786268000000064],
            [73.340995674000055, 30.786181812000052],
            [73.340995489000079, 30.786181230000068],
            [73.341732369000056, 30.785654934000036],
            [73.347645000000057, 30.781432000000052],
            [73.360220000000083, 30.772726000000034],
            [73.362519182000028, 30.770656902000042],
            [73.369893000000047, 30.764021000000071],
            [73.370341565000047, 30.75893747300006],
            [73.37079678200007, 30.753778555000054],
            [73.371344000000079, 30.747577000000035],
            [73.371015900000032, 30.741997597000079],
            [73.370884033000038, 30.739755185000035],
            [73.370883535000075, 30.739754797000046],
            [73.370376000000078, 30.731133000000057],
            [73.369969777000051, 30.724625026000069],
            [73.369893000000047, 30.723395000000039],
            [73.370342718000074, 30.718221613000026],
            [73.370530880000047, 30.716057074000048],
            [73.37086000000005, 30.712271000000044],
            [73.362258214000065, 30.706217867000078],
            [73.36184034200005, 30.70592380800008],
            [73.357802000000049, 30.703082000000052],
            [73.355608891000031, 30.700888891000034],
            [73.350547000000063, 30.695827000000065],
            [73.348602182000036, 30.689668857000072],
            [73.347645000000057, 30.686638000000073],
            [73.351514000000066, 30.677449000000024],
            [73.35365489000003, 30.674492603000033],
            [73.361671000000058, 30.663423000000023],
            [73.36191003700003, 30.662849233000031],
            [73.364089000000035, 30.657619000000068],
            [73.367056460000072, 30.649142544000028],
            [73.367305011000042, 30.648432567000043],
            [73.36747500000007, 30.647947000000045],
            [73.370186087000036, 30.641748743000051],
            [73.370554365000032, 30.640906763000032],
            [73.37086000000005, 30.64020800000003],
            [73.375948428000072, 30.633945195000024],
            [73.377147000000036, 30.632470000000069],
            [73.376664000000062, 30.621830000000045],
            [73.374246000000085, 30.608772000000044],
            [73.372469763000083, 30.607157027000028],
            [73.372260907000054, 30.606967132000079],
            [73.368926000000044, 30.603935000000035],
            [73.368030927000063, 30.603777040000068],
            [73.360704000000055, 30.602484000000061],
            [73.351514000000066, 30.598131000000024],
            [73.348355876000085, 30.593217475000074],
            [73.347162000000083, 30.591360000000066],
            [73.351514000000066, 30.586524000000054],
            [73.355185232000053, 30.584566178000046],
            [73.358769000000052, 30.582655000000045],
            [73.362638000000061, 30.576367000000062],
            [73.361674293000078, 30.574975127000073],
            [73.361675352000077, 30.574975212000027],
            [73.358286000000078, 30.570080000000075],
            [73.349602870000069, 30.566305133000071],
            [73.349602834000052, 30.566305213000078],
            [73.347162000000083, 30.565244000000064],
            [73.343776000000048, 30.56814600000007],
            [73.334189115000072, 30.565748535000068],
            [73.334103000000084, 30.565727000000038],
            [73.327816000000041, 30.562342000000058],
            [73.323947000000032, 30.557505000000049],
            [73.318627000000049, 30.555087000000071],
            [73.315321088000076, 30.553695056000038],
            [73.309438000000057, 30.551218000000063],
            [73.303150000000073, 30.55025100000006],
            [73.295896000000084, 30.549283000000059],
            [73.293871849000084, 30.548068788000023],
            [73.288641000000041, 30.544931000000076],
            [73.287674000000038, 30.541062000000068],
            [73.294928324000068, 30.542995820000044],
            [73.299281000000065, 30.540578000000039],
            [73.303150000000073, 30.53670900000003],
            [73.303150000000073, 30.533323000000053],
            [73.295896000000084, 30.530421000000047],
            [73.28912500000007, 30.530905000000075],
            [73.284727150000037, 30.531478380000067],
            [73.284727181000051, 30.531478646000039],
            [73.281487315000049, 30.531901250000033],
            [73.280591513000047, 30.532018097000048],
            [73.278076172000056, 30.532287598000039],
            [73.27056338400007, 30.534009278000042],
            [73.269296000000054, 30.534291000000053],
            [73.266908962000059, 30.534291000000053],
            [73.265710029000047, 30.534291000000053],
            [73.260590000000036, 30.534291000000053],
            [73.254090336000047, 30.534291000000053],
            [73.251884000000075, 30.534291000000053],
            [73.250989767000078, 30.534112098000037],
            [73.249716729000056, 30.533857411000042],
            [73.242212000000052, 30.53235600000005],
            [73.239722251000046, 30.526132913000026],
            [73.238342000000046, 30.522683000000029],
            [73.238292701000034, 30.522732316000031],
            [73.237684804000082, 30.523340422000047],
            [73.235464830000069, 30.525561162000031],
            [73.235441000000037, 30.525585000000035],
            [73.230619650000051, 30.535226704000024],
            [73.230604000000028, 30.535258000000056],
            [73.228363671000068, 30.533977859000061],
            [73.22383300000007, 30.531389000000047],
            [73.223552677000043, 30.530793287000051],
            [73.220092773000033, 30.523315430000025],
            [73.21998147100004, 30.523204128000032],
            [73.219964000000061, 30.523167000000058],
            [73.213677000000075, 30.516879000000074],
            [73.211258000000043, 30.517847000000074],
            [73.210007585000028, 30.519566361000045],
            [73.207389000000035, 30.523167000000058],
            [73.206422000000032, 30.523167000000058],
            [73.198665683000058, 30.522390807000079],
            [73.196749000000068, 30.522199000000057],
            [73.189086251000049, 30.521720697000035],
            [73.18901100000005, 30.521716000000026],
            [73.188734085000078, 30.517565139000055],
            [73.188527000000079, 30.51446100000004],
            [73.189273427000046, 30.512893766000047],
            [73.193481445000032, 30.504272461000028],
            [73.195922852000081, 30.499084473000039],
            [73.198412262000033, 30.492566743000054],
            [73.199279785000044, 30.490295410000044],
            [73.195495605000076, 30.489685059000067],
            [73.193446794000067, 30.489399178000042],
            [73.192880000000059, 30.489312000000041],
            [73.185142000000042, 30.488828000000069],
            [73.181273000000033, 30.483508000000029],
            [73.177860263000071, 30.482610068000042],
            [73.172119141000053, 30.481079102000024],
            [73.171517687000062, 30.481033422000053],
            [73.167247000000032, 30.480606000000023],
            [73.160310796000033, 30.482645862000027],
            [73.159025000000042, 30.483024000000057],
            [73.152254000000084, 30.480606000000023],
            [73.150222018000079, 30.478319823000049],
            [73.148498535000044, 30.476318359000061],
            [73.142700195000032, 30.469116211000028],
            [73.142681065000033, 30.469124064000027],
            [73.136901855000076, 30.471496582000043],
            [73.143676758000083, 30.479675293000071],
            [73.140686035000044, 30.485900879000042],
            [73.14066060600004, 30.485896514000046],
            [73.134066919000077, 30.484764762000054],
            [73.132502888000033, 30.484488745000078],
            [73.132425000000069, 30.484475000000032],
            [73.12947815900003, 30.478849413000034],
            [73.129473489000077, 30.478840498000068],
            [73.128365841000061, 30.476725974000033],
            [73.127078348000055, 30.474310116000026],
            [73.127075195000032, 30.474304199000073],
            [73.126724846000059, 30.474192282000047],
            [73.122752000000048, 30.472868000000062],
            [73.116066695000029, 30.470480603000055],
            [73.111694336000085, 30.462707520000038],
            [73.111694336000085, 30.454799960000059],
            [73.111694336000085, 30.454101562000062],
            [73.111628000000053, 30.454059835000066],
            [73.111628000000053, 30.454006000000049],
            [73.111054702000047, 30.453647707000073],
            [73.107759000000044, 30.451588000000072],
            [73.106792000000041, 30.460293000000036],
            [73.103774233000081, 30.465951800000028],
            [73.102905273000033, 30.467529297000056],
            [73.101928711000085, 30.467529297000056],
            [73.097717285000044, 30.467529297000056],
            [73.095345071000054, 30.466945966000026],
            [73.090348000000063, 30.465613000000076],
            [73.090839386000084, 30.461845823000033],
            [73.091799000000037, 30.454489000000024],
            [73.092281343000082, 30.445312500000057],
            [73.092282000000068, 30.445300000000032],
            [73.089864000000034, 30.445300000000032],
            [73.082610000000045, 30.449653000000069],
            [73.080675000000042, 30.45835900000003],
            [73.079445039000063, 30.457333750000032],
            [73.077773000000036, 30.455940000000055],
            [73.078257000000065, 30.448202000000038],
            [73.077560986000037, 30.442985492000048],
            [73.077289000000064, 30.440947000000051],
            [73.076597414000048, 30.434029708000025],
            [73.076322000000062, 30.431275000000028],
            [73.076322000000062, 30.426438000000076],
            [73.075893688000065, 30.425724246000073],
            [73.073891347000028, 30.422387472000025],
            [73.073469799000065, 30.421684988000038],
            [73.073420000000056, 30.421602000000064],
            [73.071901788000048, 30.42097676700007],
            [73.065335446000063, 30.418272603000048],
            [73.065294747000053, 30.418255842000065],
            [73.060485840000069, 30.411499023000033],
            [73.059964084000057, 30.408264140000028],
            [73.059878000000083, 30.407576000000063],
            [73.057121950000067, 30.406887166000047],
            [73.056009000000074, 30.40660900000006],
            [73.049553174000039, 30.409068393000041],
            [73.045853000000079, 30.410478000000069],
            [73.039082000000064, 30.417249000000027],
            [73.04195844000003, 30.422522142000048],
            [73.04198400000007, 30.422569000000067],
            [73.038196571000071, 30.423410361000037],
            [73.037705870000082, 30.423519368000029],
            [73.037631000000033, 30.42353600000007],
            [73.035117962000072, 30.42285058300007],
            [73.03231100000005, 30.422085000000038],
            [73.03231100000005, 30.415871616000061],
            [73.03231100000005, 30.415798000000052],
            [73.039082000000064, 30.409511000000066],
            [73.036351651000075, 30.409340243000031],
            [73.03210449200003, 30.409074624000027],
            [73.031343000000049, 30.409027000000037],
            [73.029764211000042, 30.409313972000064],
            [73.026023000000066, 30.40999400000004],
            [73.01973600000008, 30.413380000000075],
            [73.014516342000036, 30.417650897000044],
            [73.013488770000038, 30.410095215000069],
            [73.013488770000038, 30.406127930000025],
            [73.013324001000058, 30.406107148000046],
            [73.012183026000059, 30.405944200000079],
            [73.006678000000079, 30.405158000000029],
            [73.004655623000076, 30.402883218000056],
            [73.003104242000063, 30.401081614000077],
            [73.002929688000052, 30.400878906000059],
            [73.002901239000039, 30.400909876000071],
            [73.002808000000073, 30.400805000000048],
            [72.997972000000061, 30.406125000000031],
            [72.999991922000049, 30.413337900000045],
            [73.001358000000039, 30.418216000000029],
            [73.001266602000044, 30.418216000000029],
            [72.989750000000072, 30.418216000000029],
            [72.985306035000065, 30.419832367000026],
            [72.984430000000032, 30.420151000000033],
            [72.983540338000068, 30.424155858000063],
            [72.983530533000078, 30.424199997000073],
            [72.983463000000029, 30.42450400000007],
            [72.977659000000074, 30.432242000000031],
            [72.974152630000049, 30.436624736000056],
            [72.973790000000065, 30.437078000000042],
            [72.97040400000003, 30.442398000000026],
            [72.969755110000051, 30.443371418000027],
            [72.966535000000079, 30.448202000000038],
            [72.964214557000048, 30.448293804000059],
            [72.960878670000056, 30.448293814000067],
            [72.960674959000073, 30.448293815000056],
            [72.962605479000047, 30.440558425000063],
            [72.963845869000068, 30.438781779000067],
            [72.968326893000039, 30.432802225000046],
            [72.960453640000082, 30.432051404000049],
            [72.958286695000083, 30.431844756000032],
            [72.954101563000052, 30.425476074000073],
            [72.953491211000085, 30.419677734000061],
            [72.95147705100004, 30.410522461000028],
            [72.951419392000048, 30.410602505000043],
            [72.951410886000076, 30.410562124000023],
            [72.946853478000037, 30.416782466000029],
            [72.946097441000063, 30.417814370000031],
            [72.948516072000075, 30.427966905000062],
            [72.948517030000062, 30.432734763000042],
            [72.939514160000044, 30.435119629000042],
            [72.935119629000042, 30.429321289000029],
            [72.935729980000076, 30.423095703000058],
            [72.92881952700003, 30.421738293000033],
            [72.925476074000073, 30.421081543000071],
            [72.918471132000036, 30.421486453000057],
            [72.916593965000061, 30.42159496000005],
            [72.91469058000007, 30.421681567000064],
            [72.912377634000052, 30.421891620000054],
            [72.909374232000062, 30.422164377000058],
            [72.904058884000051, 30.422648165000055],
            [72.902586213000063, 30.419701369000052],
            [72.902586044000032, 30.419701650000036],
            [72.902126742000064, 30.418780875000039],
            [72.901407285000062, 30.415179503000047],
            [72.901490501000069, 30.414922920000038],
            [72.901489258000083, 30.41491699200003],
            [72.901306152000075, 30.413879395000038],
            [72.89929199200003, 30.408691406000059],
            [72.899902344000054, 30.401916504000042],
            [72.903320312000062, 30.396484375000057],
            [72.903686523000033, 30.391113281000059],
            [72.900027793000049, 30.391433170000028],
            [72.892517090000069, 30.392089844000054],
            [72.892493010000067, 30.39218616200003],
            [72.892458840000074, 30.392189138000049],
            [72.88956009900005, 30.403307836000067],
            [72.891009659000076, 30.409588485000029],
            [72.891010864000066, 30.409593707000056],
            [72.888591676000033, 30.41201324900004],
            [72.888110795000046, 30.412334328000043],
            [72.887293214000067, 30.412880218000055],
            [72.882873535000044, 30.410095215000069],
            [72.882873535000044, 30.402282715000069],
            [72.880493164000029, 30.399475098000039],
            [72.877075195000032, 30.397521973000039],
            [72.871408061000068, 30.394379799000035],
            [72.870910645000038, 30.394104004000042],
            [72.870718633000081, 30.394126266000058],
            [72.870708748000084, 30.394120942000029],
            [72.867045267000037, 30.394552164000061],
            [72.862487793000071, 30.395080566000047],
            [72.862582900000064, 30.395779885000024],
            [72.86345405700007, 30.402734740000028],
            [72.85772705100004, 30.404724121000072],
            [72.857003813000063, 30.404000883000037],
            [72.852905273000033, 30.399902344000054],
            [72.851214884000058, 30.399399796000068],
            [72.846130371000072, 30.397888184000067],
            [72.841937703000042, 30.399639552000053],
            [72.841230930000052, 30.39992238800005],
            [72.834468472000083, 30.404273432000025],
            [72.833217578000074, 30.403721631000053],
            [72.833156965000057, 30.403694893000079],
            [72.830113968000035, 30.402341195000076],
            [72.830119237000076, 30.402301558000033],
            [72.830642594000039, 30.398364638000032],
            [72.831115723000039, 30.395080566000047],
            [72.832357906000084, 30.39335783100006],
            [72.839470087000052, 30.383494222000024],
            [72.839477539000029, 30.383483887000068],
            [72.846130371000072, 30.37530517600004],
            [72.847290014000066, 30.370186755000077],
            [72.847196343000064, 30.370143934000055],
            [72.847234537000077, 30.369971894000059],
            [72.847220555000035, 30.369973834000064],
            [72.846769197000071, 30.369948667000074],
            [72.841241783000044, 30.369640469000046],
            [72.829614986000081, 30.36963961500004],
            [72.818984027000056, 30.368973869000058],
            [72.817633771000033, 30.368350447000068],
            [72.817523059000052, 30.36829933100006],
            [72.814665606000062, 30.366980027000068],
            [72.810014676000037, 30.366979723000043],
            [72.80768876500008, 30.363323408000042],
            [72.807715069000039, 30.363237823000077],
            [72.809016419000045, 30.359003629000028],
            [72.810677938000083, 30.355679579000025],
            [72.810680818000037, 30.355633350000062],
            [72.810704000000044, 30.355587000000071],
            [72.811036000000058, 30.350269000000026],
            [72.811036000000058, 30.34495000000004],
            [72.810039000000074, 30.341958000000034],
            [72.807047000000068, 30.33863400000007],
            [72.804387000000077, 30.335309000000052],
            [72.800731000000042, 30.330323000000078],
            [72.800533634000033, 30.330224342000065],
            [72.796742000000052, 30.328329000000053],
            [72.793085000000076, 30.327996000000041],
            [72.789761000000055, 30.331653000000074],
            [72.789754809000044, 30.331739528000071],
            [72.789745680000067, 30.33174957500006],
            [72.789413272000047, 30.33640229100007],
            [72.794065026000055, 30.34172049700004],
            [72.802702759000056, 30.34371489800003],
            [72.804003496000064, 30.347942000000046],
            [72.804032388000053, 30.348035891000052],
            [72.800045944000033, 30.348035502000073],
            [72.790411467000069, 30.344378384000038],
            [72.786739664000038, 30.340226380000047],
            [72.782769201000065, 30.33573665800003],
            [72.780775060000053, 30.331748393000055],
            [72.780442639000057, 30.325101120000056],
            [72.776455871000053, 30.324435725000058],
            [72.776448597000069, 30.32443495900003],
            [72.770143436000069, 30.323771038000075],
            [72.763498155000036, 30.32377008800006],
            [72.753531678000058, 30.325097480000068],
            [72.744228765000059, 30.330414102000077],
            [72.737381723000055, 30.336639905000027],
            [72.737381712000058, 30.336639951000052],
            [72.736920033000047, 30.337061207000033],
            [72.730275222000046, 30.334400469000059],
            [72.726743988000067, 30.333224896000047],
            [72.725293646000068, 30.332737020000025],
            [72.725293620000059, 30.332657540000071],
            [72.725291120000065, 30.325093957000036],
            [72.724291441000048, 30.31778253300007],
            [72.724623733000044, 30.31080201900005],
            [72.724603333000061, 30.310765905000039],
            [72.724606000000051, 30.310710000000029],
            [72.724040209000066, 30.309709066000039],
            [72.720284000000049, 30.303064000000063],
            [72.715046126000061, 30.297171556000023],
            [72.712306000000069, 30.294089000000042],
            [72.705658000000028, 30.295751000000052],
            [72.701336000000083, 30.297746000000075],
            [72.698677000000032, 30.301070000000038],
            [72.69202800000005, 30.304062000000044],
            [72.689369000000056, 30.308051000000034],
            [72.689748027000064, 30.309870423000064],
            [72.691031000000066, 30.316029000000071],
            [72.689070817000072, 30.319020794000039],
            [72.689090357000055, 30.319127179000077],
            [72.684749897000074, 30.325753099000053],
            [72.680527496000082, 30.326197205000028],
            [72.678436135000084, 30.32641717100006],
            [72.673853277000035, 30.317822586000034],
            [72.673857541000075, 30.317818872000032],
            [72.673815878000084, 30.317740754000056],
            [72.673080000000084, 30.316361000000029],
            [72.668665900000065, 30.311632014000054],
            [72.668426000000068, 30.311375000000055],
            [72.666245588000038, 30.311445247000051],
            [72.662967558000048, 30.311550857000043],
            [72.658121000000051, 30.311707000000069],
            [72.652802000000065, 30.302732000000049],
            [72.64816647300006, 30.298096473000044],
            [72.647177887000055, 30.295789441000068],
            [72.645202598000083, 30.291179776000035],
            [72.64517952500006, 30.291151888000059],
            [72.645156000000043, 30.291097000000036],
            [72.643343607000077, 30.288907044000041],
            [72.637178000000063, 30.281457000000046],
            [72.630565151000042, 30.277157853000062],
            [72.628486593000048, 30.269103866000023],
            [72.627921122000032, 30.266912784000056],
            [72.626924400000064, 30.255944271000033],
            [72.626878817000033, 30.255924001000039],
            [72.626873000000046, 30.255860000000041],
            [72.620890000000031, 30.253201000000047],
            [72.612911000000054, 30.248214000000075],
            [72.604602705000048, 30.245223614000054],
            [72.60033648600006, 30.23898762500005],
            [72.596859333000054, 30.23387243600007],
            [72.594686791000072, 30.23067644200006],
            [72.594680092000033, 30.230672612000035],
            [72.58770716500004, 30.226686244000064],
            [72.582056852000051, 30.232999996000046],
            [72.579065150000076, 30.23997939800006],
            [72.576738113000033, 30.249949722000053],
            [72.577402773000074, 30.257595180000067],
            [72.574410863000082, 30.265239848000078],
            [72.572955369000056, 30.265921811000055],
            [72.563774742000078, 30.270223341000076],
            [72.563736243000051, 30.270206226000028],
            [72.563733108000065, 30.270144574000028],
            [72.563713000000064, 30.270154000000048],
            [72.557729000000052, 30.267495000000054],
            [72.555226471000083, 30.265350052000031],
            [72.553075000000035, 30.263506000000064],
            [72.548089000000061, 30.262841000000037],
            [72.545097000000055, 30.266830000000027],
            [72.541129104000049, 30.268483207000031],
            [72.539841325000054, 30.266229633000023],
            [72.539807117000066, 30.266215944000066],
            [72.533193589000064, 30.263569349000079],
            [72.532368028000064, 30.263410426000064],
            [72.524550777000059, 30.261905578000039],
            [72.524510498000041, 30.261848519000068],
            [72.521085571000071, 30.256996711000056],
            [72.521086523000065, 30.256995433000043],
            [72.520562401000063, 30.256255650000071],
            [72.518566351000061, 30.250602991000051],
            [72.518805954000072, 30.245391200000029],
            [72.51890247800003, 30.243291641000042],
            [72.518901637000056, 30.235641958000031],
            [72.518838603000063, 30.235550206000028],
            [72.51491195300008, 30.229659370000036],
            [72.51488624600006, 30.229659364000042],
            [72.514846000000034, 30.229599000000064],
            [72.510257859000035, 30.229599000000064],
            [72.510258340000064, 30.222677568000051],
            [72.51158885600006, 30.217027241000039],
            [72.50627018800003, 30.210709120000047],
            [72.504607053000029, 30.206383734000042],
            [72.502946776000044, 30.202065778000076],
            [72.502890840000077, 30.202037799000038],
            [72.502879000000064, 30.202007000000037],
            [72.495603864000032, 30.198369930000069],
            [72.489318296000079, 30.190428543000053],
            [72.486991792000083, 30.184112201000062],
            [72.486659236000037, 30.178793561000077],
            [72.485357805000035, 30.175834470000041],
            [72.483003747000055, 30.170481998000071],
            [72.482957510000062, 30.170480444000077],
            [72.482934000000057, 30.170427000000075],
            [72.473021882000069, 30.170097027000054],
            [72.472550266000042, 30.167266794000057],
            [72.472550432000048, 30.167261753000048],
            [72.472363916000063, 30.166159594000078],
            [72.470647338000049, 30.16472925100004],
            [72.470645799000067, 30.164727968000079],
            [72.470612600000038, 30.164700306000043],
            [72.46837393800007, 30.162834936000024],
            [72.46837021500005, 30.162833692000049],
            [72.468307000000038, 30.162781000000052],
            [72.461326000000042, 30.160454000000072],
            [72.454678000000058, 30.162781000000052],
            [72.450259781000057, 30.167198519000067],
            [72.448361000000034, 30.169097000000079],
            [72.447897024000042, 30.175833735000026],
            [72.447697000000062, 30.178738000000067],
            [72.437419817000034, 30.180450291000056],
            [72.435729000000038, 30.180732000000035],
            [72.431935603000056, 30.180397367000069],
            [72.424427000000037, 30.179735000000051],
            [72.417427562000057, 30.179011095000078],
            [72.414787000000047, 30.178738000000067],
            [72.401822000000038, 30.183059000000071],
            [72.402819000000079, 30.189375000000041],
            [72.40381700000006, 30.193364000000031],
            [72.404104046000043, 30.194282651000037],
            [72.405479000000071, 30.198683000000074],
            [72.408803000000034, 30.203337000000033],
            [72.412792000000081, 30.206661000000054],
            [72.405479000000071, 30.213975000000062],
            [72.399440393000077, 30.217470432000027],
            [72.399492356000053, 30.217499395000061],
            [72.399212828000032, 30.21766107600007],
            [72.398874704000036, 30.217829992000077],
            [72.400723080000034, 30.218985564000036],
            [72.402657335000072, 30.22817685800004],
            [72.40123583500008, 30.237168711000038],
            [72.401204585000073, 30.237366388000055],
            [72.403390201000036, 30.244654045000061],
            [72.404106102000071, 30.247041125000067],
            [72.40434293800007, 30.251558214000056],
            [72.404587955000068, 30.256231336000042],
            [72.406037643000047, 30.268808204000038],
            [72.40454287600005, 30.277769843000044],
            [72.404101420000075, 30.280416522000053],
            [72.395875416000081, 30.289122401000043],
            [72.396615336000082, 30.290972426000053],
            [72.398777389000031, 30.296378215000061],
            [72.401232668000034, 30.29869762800007],
            [72.407484089000036, 30.304603121000071],
            [72.407274162000078, 30.310257424000042],
            [72.406999537000047, 30.317654345000051],
            [72.409051452000028, 30.320948835000024],
            [72.411805528000059, 30.325356289000069],
            [72.415886255000032, 30.325015994000069],
            [72.417601000000047, 30.324873000000025],
            [72.417634852000049, 30.324921901000039],
            [72.418334180000045, 30.325932131000059],
            [72.420467522000081, 30.329004295000061],
            [72.424872432000029, 30.335347679000051],
            [72.426354060000051, 30.337481328000024],
            [72.426854060000039, 30.339881328000047],
            [72.427896243000077, 30.345135668000069],
            [72.429254060000062, 30.351981328000079],
            [72.440854060000049, 30.368881328000043],
            [72.440850069000078, 30.368933206000065],
            [72.440857976000075, 30.368944738000039],
            [72.440746666000052, 30.370277452000039],
            [72.440654060000043, 30.371481328000073],
            [72.440646081000068, 30.371481754000058],
            [72.440522506000036, 30.372961314000065],
            [72.44028759400004, 30.375773898000034],
            [72.439888326000073, 30.380554325000048],
            [72.440763220000065, 30.391936749000024],
            [72.440821110000059, 30.392666166000026],
            [72.440854060000049, 30.393081328000051],
            [72.440853046000029, 30.393105382000044],
            [72.440297136000083, 30.406288372000063],
            [72.440154060000054, 30.409681328000033],
            [72.44005125700005, 30.412456999000028],
            [72.439987571000074, 30.414176534000035],
            [72.439854060000073, 30.417781328000046],
            [72.440354060000061, 30.432281328000045],
            [72.440365989000043, 30.432306964000077],
            [72.441651594000064, 30.435069646000045],
            [72.442176859000028, 30.436198407000063],
            [72.442527167000037, 30.436949232000075],
            [72.445062270000051, 30.442382792000046],
            [72.44656468900007, 30.445602971000028],
            [72.447103000000084, 30.446751000000063],
            [72.457654060000038, 30.455381328000044],
            [72.457689225000081, 30.455413000000078],
            [72.459475181000073, 30.457021543000053],
            [72.462343402000045, 30.459604842000033],
            [72.471400455000037, 30.467762187000062],
            [72.472754060000057, 30.468981328000041],
            [72.478554060000079, 30.477181328000029],
            [72.478563712000039, 30.47728556900006],
            [72.478580513000054, 30.477309373000026],
            [72.47931243000005, 30.485371722000025],
            [72.479551082000057, 30.487949164000042],
            [72.479552462000072, 30.487964067000064],
            [72.483730273000049, 30.496321389000059],
            [72.487285310000061, 30.503432910000072],
            [72.485772962000055, 30.508726849000027],
            [72.484635233000063, 30.512709440000037],
            [72.484553017000053, 30.512685047000048],
            [72.482964373000073, 30.518351211000038],
            [72.481554060000065, 30.523381328000028],
            [72.480954060000045, 30.525581328000044],
            [72.482454060000066, 30.531481328000041],
            [72.483638011000039, 30.535970475000056],
            [72.484032799000033, 30.537467380000066],
            [72.484853182000052, 30.540578000000039],
            [72.484854060000032, 30.54058132800003],
            [72.496438328000067, 30.54058132800003],
            [72.496454060000076, 30.54058132800003],
            [72.49759828200007, 30.541741442000045],
            [72.501416352000035, 30.545612541000025],
            [72.502591215000052, 30.54680372200005],
            [72.503654060000031, 30.547881328000074],
            [72.50195406000006, 30.551681328000029],
            [72.500254060000032, 30.555581328000073],
            [72.499359447000074, 30.558943886000066],
            [72.498021708000067, 30.563972007000075],
            [72.496979855000063, 30.567887992000067],
            [72.497725119000052, 30.571168904000046],
            [72.499345539000046, 30.578302562000033],
            [72.507558000000074, 30.578786000000036],
            [72.519649000000072, 30.578786000000036],
            [72.522726305000049, 30.580016922000027],
            [72.526904000000059, 30.581688000000042],
            [72.526904000000059, 30.58178857200005],
            [72.526947098000051, 30.581805807000023],
            [72.526947090000078, 30.581926993000025],
            [72.526946314000043, 30.593901432000052],
            [72.517300266000063, 30.597279451000077],
            [72.520133000000044, 30.60200100000003],
            [72.529806000000065, 30.600550000000055],
            [72.532824508000033, 30.602059254000039],
            [72.53282886900007, 30.602061435000053],
            [72.53588841800007, 30.603591209000058],
            [72.543348000000037, 30.60732100000007],
            [72.546164292000071, 30.607192890000078],
            [72.553988000000061, 30.606837000000041],
            [72.557865575000051, 30.608061586000076],
            [72.563177000000053, 30.609739000000047],
            [72.565808779000065, 30.61120089800005],
            [72.568415193000078, 30.612648706000073],
            [72.571883000000071, 30.614575000000059],
            [72.58010500000006, 30.611190000000079],
            [72.585375336000084, 30.605918938000059],
            [72.587359000000049, 30.603935000000035],
            [72.592196000000058, 30.595230000000072],
            [72.593647000000033, 30.587008000000026],
            [72.597553862000041, 30.580589461000045],
            [72.600418000000047, 30.57588400000003],
            [72.600429903000077, 30.576050869000028],
            [72.600901000000079, 30.582655000000045],
            [72.60094828900003, 30.582709043000079],
            [72.607672000000036, 30.590393000000063],
            [72.610091000000068, 30.584106000000077],
            [72.615651090000028, 30.578545233000057],
            [72.615756095000052, 30.578592320000041],
            [72.618363841000075, 30.575983397000073],
            [72.633943038000041, 30.576897088000067],
            [72.633944246000056, 30.576898607000032],
            [72.634808319000058, 30.576948737000066],
            [72.635986869000078, 30.576611433000039],
            [72.635982865000074, 30.576500571000054],
            [72.641527000000053, 30.574917000000028],
            [72.644524818000036, 30.573584407000055],
            [72.650233000000071, 30.571047000000078],
            [72.655553000000054, 30.57588400000003],
            [72.65559817500008, 30.575974349000035],
            [72.658561070000076, 30.581900141000062],
            [72.659422000000063, 30.583622000000048],
            [72.659424093000041, 30.58362485400005],
            [72.664742000000047, 30.590877000000035],
            [72.666994159000069, 30.589525798000068],
            [72.669579000000056, 30.587975000000029],
            [72.666193000000078, 30.574433000000056],
            [72.678284000000076, 30.576367000000062],
            [72.681320021000033, 30.576526912000077],
            [72.682978510000055, 30.576614268000071],
            [72.687473000000068, 30.576851000000033],
            [72.69656408600008, 30.576514061000069],
            [72.696558870000047, 30.576601346000075],
            [72.700571668000066, 30.576451841000051],
            [72.710086402000059, 30.576450138000041],
            [72.713142248000054, 30.57644971600007],
            [72.721845865000034, 30.579351786000075],
            [72.72466098600006, 30.579349672000035],
            [72.72466373900005, 30.579349670000056],
            [72.724666337000031, 30.579269000000068],
            [72.731001000000049, 30.579269000000068],
            [72.738739000000066, 30.581688000000042],
            [72.738700859000062, 30.581745212000044],
            [72.733903000000055, 30.588942000000031],
            [72.732201716000077, 30.592831080000053],
            [72.730518000000075, 30.596680000000049],
            [72.730558819000066, 30.596743507000042],
            [72.732828638000058, 30.600274974000058],
            [72.734870000000058, 30.603451000000064],
            [72.736179408000055, 30.601487058000032],
            [72.738739000000066, 30.597648000000049],
            [72.73932138300006, 30.597648000000049],
            [72.751314000000036, 30.597648000000049],
            [72.754728979000049, 30.598390307000045],
            [72.755145626000058, 30.598480873000028],
            [72.762438000000031, 30.600066000000027],
            [72.766973175000032, 30.608230440000057],
            [72.767274000000043, 30.608772000000044],
            [72.767272222000031, 30.608803120000061],
            [72.767297272000064, 30.608847937000064],
            [72.766332644000045, 30.625705411000069],
            [72.766382724000039, 30.625717931000054],
            [72.772111000000052, 30.627150000000029],
            [72.78420200000005, 30.630535000000066],
            [72.784159079000062, 30.630593737000027],
            [72.779288908000069, 30.637258488000043],
            [72.775013000000058, 30.643110000000036],
            [72.775055409000061, 30.643159499000035],
            [72.77666226000008, 30.645034990000056],
            [72.777914000000067, 30.64649600000007],
            [72.785169000000053, 30.651816000000053],
            [72.786560188000067, 30.656221110000047],
            [72.788071000000059, 30.661005000000046],
            [72.788064420000069, 30.661005000000046],
            [72.775496000000032, 30.661005000000046],
            [72.765823000000069, 30.661005000000046],
            [72.759536000000082, 30.665841000000057],
            [72.75856900000008, 30.674547000000075],
            [72.758605162000038, 30.674592203000032],
            [72.764373000000035, 30.681802000000062],
            [72.772111000000052, 30.683253000000036],
            [72.780333000000041, 30.677449000000024],
            [72.788071000000059, 30.677449000000024],
            [72.788723455000081, 30.678023520000067],
            [72.79774400000008, 30.678416000000027],
            [72.807620973000041, 30.680955843000049],
            [72.81467200000003, 30.682769000000064],
            [72.825312000000054, 30.693409000000031],
            [72.826241132000064, 30.692386878000036],
            [72.830148000000065, 30.688089000000048],
            [72.83685108700007, 30.682223294000039],
            [72.841738834000068, 30.677946146000068],
            [72.841755000000035, 30.677932000000055],
            [72.850461000000053, 30.685187000000042],
            [72.85134806800005, 30.685590238000032],
            [72.852074994000077, 30.685920680000038],
            [72.857496810000043, 30.688385296000035],
            [72.866421000000059, 30.692442000000028],
            [72.876059182000063, 30.68820123200004],
            [72.878512000000057, 30.687122000000045],
            [72.885133619000044, 30.683535100000029],
            [72.890120000000081, 30.680834000000061],
            [72.896698359000084, 30.677075215000059],
            [72.900276000000076, 30.675031000000047],
            [72.90185677900007, 30.67477801800004],
            [72.912367000000074, 30.673096000000044],
            [72.920106000000033, 30.677449000000024],
            [72.920106000000033, 30.690507000000025],
            [72.92784400000005, 30.694376000000034],
            [72.932680000000062, 30.69727800000004],
            [72.932687137000073, 30.697449175000031],
            [72.932912290000047, 30.702849116000039],
            [72.933164000000033, 30.708886000000064],
            [72.934266119000029, 30.710460316000024],
            [72.936937253000053, 30.714275885000063],
            [72.937525160000064, 30.715115677000028],
            [72.938168897000082, 30.716035220000037],
            [72.939321694000057, 30.717681926000068],
            [72.939935000000048, 30.71855800000003],
            [72.937566205000053, 30.720926795000025],
            [72.933164000000033, 30.725329000000045],
            [72.931724147000068, 30.726048989000049],
            [72.928291738000041, 30.727765341000065],
            [72.921557000000064, 30.731133000000057],
            [72.922637455000029, 30.733618315000058],
            [72.926393000000076, 30.742257000000052],
            [72.934615000000065, 30.739839000000075],
            [72.934960138000065, 30.739818683000067],
            [72.942837000000054, 30.739355000000046],
            [72.94298595600003, 30.741741374000071],
            [72.942993793000085, 30.741866927000046],
            [72.943033836000041, 30.742508446000045],
            [72.943320000000028, 30.747093000000064],
            [72.943547633000037, 30.747624144000042],
            [72.945363718000067, 30.751861676000033],
            [72.946960906000072, 30.755588448000026],
            [72.946963288000063, 30.755594006000024],
            [72.947673000000066, 30.757250000000056],
            [72.948175092000042, 30.757194231000028],
            [72.956379000000084, 30.756283000000053],
            [72.961505269000043, 30.757136887000058],
            [72.965084000000047, 30.75773300000003],
            [72.971372000000031, 30.763537000000042],
            [72.977659000000074, 30.769341000000054],
            [72.982979000000057, 30.765472000000045],
            [72.986827752000067, 30.763932579000027],
            [72.988850887000069, 30.763123367000048],
            [72.992652000000078, 30.761603000000036],
            [72.994586967000032, 30.774176785000066],
            [73.000068166000062, 30.772959161000074],
            [73.003292000000044, 30.77224300000006],
            [73.008122318000062, 30.77224300000006],
            [73.016350000000045, 30.77224300000006],
            [73.01659193100005, 30.772431132000065],
            [73.018081274000053, 30.773589282000046],
            [73.020703000000083, 30.77562800000004],
            [73.022804437000048, 30.776402346000054],
            [73.028650817000084, 30.778556644000048],
            [73.029892000000075, 30.779014000000075],
            [73.042467000000045, 30.778046000000074],
            [73.042467000000045, 30.778047000000072],
            [73.042467000000045, 30.778668478000043],
            [73.042467000000045, 30.790621000000044],
            [73.044063829000038, 30.793814658000031],
            [73.046820000000082, 30.799327000000062],
            [73.058818906000056, 30.805326065000031],
            [73.06229641200008, 30.807064706000062],
            [73.062297000000058, 30.80706500000008],
            [73.062770896000075, 30.806726533000074],
            [73.063286437000045, 30.806358322000051],
            [73.069068000000073, 30.802229000000068],
            [73.071590989000072, 30.800882989000058],
            [73.076322000000062, 30.798359000000062],
            [73.078287960000068, 30.797644122000065],
            [73.080928878000066, 30.796683811000037],
            [73.081060029000071, 30.796636121000063],
            [73.083329393000042, 30.79581091700004],
            [73.086962000000028, 30.794490000000053],
            [73.094319556000073, 30.800621297000077],
            [73.095668000000046, 30.801745000000039],
            [73.100988000000029, 30.812869000000035],
            [73.105742642000052, 30.810152162000065],
            [73.107759000000044, 30.809000000000026],
            [73.115497000000062, 30.807549000000051],
            [73.120022696000035, 30.812397530000055],
            [73.122268000000076, 30.81480300000004],
            [73.123716573000081, 30.81617543200008],
            [73.130408608000039, 30.822515715000065],
            [73.131457000000069, 30.823509000000058],
            [73.141130000000032, 30.831247000000076],
            [73.141130000000032, 30.832607864000067],
            [73.141130000000032, 30.833763890000057],
            [73.141130000000032, 30.838985000000037],
            [73.136777000000052, 30.847691000000054],
            [73.136281728000029, 30.848681748000047],
            [73.135804317000066, 30.84963676700005],
            [73.134687965000069, 30.851869934000035],
            [73.134359000000074, 30.852528000000063],
            [73.131457000000069, 30.86316800000003],
            [73.136777000000052, 30.869455000000073],
            [73.146934000000044, 30.866069000000039],
            [73.158057080000049, 30.867036920000032],
            [73.164828000000057, 30.860266000000024],
            [73.164828000000057, 30.854334111000071],
            [73.164828000000057, 30.853439152000078],
            [73.164828000000057, 30.850109000000032],
            [73.168214000000034, 30.842371000000071],
            [73.170051203000071, 30.840687002000038],
            [73.17007181200006, 30.84066811200006],
            [73.174017564000053, 30.837051399000075],
            [73.174018000000046, 30.837051000000031],
            [73.176560977000065, 30.840129471000068],
            [73.179249154000047, 30.84338371900003],
            [73.182931426000039, 30.847841396000035],
            [73.183207000000039, 30.848175000000026],
            [73.183276503000059, 30.848487771000066],
            [73.184776618000058, 30.85523848400004],
            [73.187076000000047, 30.865586000000064],
            [73.187068628000077, 30.865611279000063],
            [73.186034387000063, 30.869157639000036],
            [73.183973454000068, 30.87622447800004],
            [73.183691000000067, 30.877193000000034],
            [73.175952000000052, 30.884448000000077],
            [73.176738287000035, 30.88837916600005],
            [73.176738900000032, 30.888378583000076],
            [73.177881561000049, 30.894093464000036],
            [73.178854000000058, 30.898957000000053],
            [73.179441405000034, 30.899038577000056],
            [73.182385602000068, 30.899447460000033],
            [73.188837913000043, 30.900343543000076],
            [73.188849077000043, 30.900345093000055],
            [73.189384071000063, 30.90041939300005],
            [73.196265000000039, 30.90137500000003],
            [73.195879355000045, 30.902262078000035],
            [73.191429000000085, 30.912499000000025],
            [73.193847000000062, 30.922172000000046],
            [73.201585111000043, 30.922655944000041],
            [73.201586000000077, 30.922656000000075],
            [73.212226000000044, 30.917336000000034],
            [73.208840000000066, 30.912016000000051],
            [73.211087017000068, 30.909608363000075],
            [73.215423706000081, 30.904961683000067],
            [73.215551633000075, 30.904824610000048],
            [73.215611000000081, 30.904761000000065],
            [73.215641213000083, 30.904773588000069],
            [73.227219000000048, 30.909597000000076],
            [73.230715811000039, 30.912919549000037],
            [73.232650646000081, 30.914757962000067],
            [73.236891000000071, 30.918787000000066],
            [73.242695000000083, 30.930394000000035],
            [73.243215010000029, 30.93053266700008],
            [73.253961342000082, 30.933398306000072],
            [73.257204000000058, 30.934263000000044],
            [73.269328032000033, 30.935098872000026],
            [73.270935393000059, 30.928669429000024],
            [73.272197000000062, 30.923623000000077],
            [73.271387423000078, 30.91606732300005],
            [73.270746000000031, 30.910081000000048],
            [73.270731473000069, 30.909659135000027],
            [73.270662190000053, 30.907647207000025],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "120",
      properties: { name: "Sargodha", count: 992 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [73.204550965000067, 32.552263282000069],
            [73.205702735000045, 32.54359220200007],
            [73.205702735000045, 32.540063995000025],
            [73.205702735000045, 32.534909202000051],
            [73.205299627000045, 32.532222744000023],
            [73.203965735000054, 32.523333201000071],
            [73.189698770000064, 32.512274361000038],
            [73.185900267000079, 32.509342000000061],
            [73.177797267000074, 32.504711000000043],
            [73.169115267000052, 32.502396000000033],
            [73.165192576000038, 32.499543470000049],
            [73.162748266000051, 32.49776600000007],
            [73.15795638700007, 32.496787622000056],
            [73.151915509000048, 32.495515945000079],
            [73.154658436000034, 32.493520893000039],
            [73.154678546000071, 32.493506266000054],
            [73.158241409000084, 32.490914839000027],
            [73.172132409000028, 32.490335839000068],
            [73.172132409000028, 32.481653839000046],
            [73.171486339000069, 32.478638226000044],
            [73.170396409000034, 32.47355083900004],
            [73.169272591000038, 32.470179386000041],
            [73.16921764500006, 32.470240209000053],
            [73.169269004000057, 32.470168624000053],
            [73.167530769000052, 32.464953919000038],
            [73.167502409000065, 32.464868839000076],
            [73.161992303000034, 32.461477835000039],
            [73.160077269000055, 32.460299294000038],
            [73.160077212000033, 32.460299259000067],
            [73.159977409000078, 32.460237839000058],
            [73.159855492000077, 32.460232538000071],
            [73.147672019000083, 32.459702784000058],
            [73.146611800000073, 32.459656684000038],
            [73.137365536000061, 32.459112620000042],
            [73.137034873000061, 32.459093164000024],
            [73.135867011000073, 32.459027579000065],
            [73.126406409000083, 32.458501839000064],
            [73.118288451000069, 32.458501839000064],
            [73.118288293000035, 32.458496282000056],
            [73.113533143000041, 32.458496282000056],
            [73.11318645800003, 32.458496282000056],
            [73.113182155000061, 32.458501863000038],
            [73.11318089100007, 32.458501846000047],
            [73.113178934000075, 32.458501826000031],
            [73.111292638000066, 32.458485867000036],
            [73.111224911000079, 32.458485292000034],
            [73.101977520000048, 32.45840703600004],
            [73.101973266000073, 32.458407000000079],
            [73.101830000000064, 32.458407000000079],
            [73.100511983000047, 32.458407000000079],
            [73.100511986000072, 32.45840703600004],
            [73.100512563000052, 32.458413331000031],
            [73.088667914000041, 32.458413331000031],
            [73.08862700800006, 32.45840703600004],
            [73.088626774000033, 32.458407000000079],
            [73.088405366000075, 32.458372928000074],
            [73.081142914000054, 32.457255331000056],
            [73.077160281000033, 32.453968322000037],
            [73.076696226000081, 32.453585321000048],
            [73.076687932000084, 32.45361269600005],
            [73.076695936000078, 32.453585228000065],
            [73.074943018000056, 32.452151938000043],
            [73.074365018000037, 32.445205938000072],
            [73.074365018000037, 32.442281753000032],
            [73.074365018000037, 32.435366938000072],
            [73.074047000000064, 32.435366938000072],
            [73.074047000000064, 32.435255000000041],
            [73.071153000000038, 32.435255000000041],
            [73.069298287000038, 32.434989954000059],
            [73.059129716000029, 32.433536823000054],
            [73.055330523000066, 32.431637956000031],
            [73.05121282500005, 32.42957961500008],
            [73.050733854000043, 32.416629176000072],
            [73.050634825000031, 32.41395161500003],
            [73.059316825000053, 32.398902615000054],
            [73.059370729000079, 32.398899850000078],
            [73.081889825000076, 32.397744615000079],
            [73.083626825000067, 32.390798615000051],
            [73.086247502000049, 32.384433559000058],
            [73.087677825000071, 32.380959615000052],
            [73.09570519600004, 32.370639134000044],
            [73.098226318000059, 32.366967769000041],
            [73.101579136000055, 32.362067326000044],
            [73.103305825000064, 32.359543615000064],
            [73.106778825000049, 32.347967615000073],
            [73.106690424000078, 32.345846601000062],
            [73.106678569000053, 32.345562160000043],
            [73.106199825000033, 32.334075615000074],
            [73.102726825000047, 32.323078615000043],
            [73.10175776300008, 32.321786393000025],
            [73.095607267000048, 32.313705000000027],
            [73.09199828900006, 32.312051994000058],
            [73.091976872000032, 32.312077980000026],
            [73.09197671000004, 32.312077904000034],
            [73.091998101000058, 32.312051907000068],
            [73.087098825000055, 32.30976561500006],
            [73.087022294000064, 32.30976561500006],
            [73.08678100000003, 32.309653000000026],
            [73.080138804000057, 32.309653000000026],
            [73.07899582500005, 32.301083615000039],
            [73.079392977000055, 32.300395210000033],
            [73.081596115000082, 32.296576386000027],
            [73.081596835000084, 32.296575134000079],
            [73.082820391000041, 32.294443793000028],
            [73.087656194000033, 32.286020194000059],
            [73.087596218000044, 32.285913540000024],
            [73.103566000000058, 32.285344000000066],
            [73.115566089000083, 32.293983732000072],
            [73.118036000000075, 32.295762000000025],
            [73.136847059000047, 32.296902422000073],
            [73.137137000000052, 32.296920001000046],
            [73.136594417000083, 32.292038156000046],
            [73.135979000000077, 32.286501000000044],
            [73.136919146000082, 32.285343964000049],
            [73.143504000000064, 32.277240000000063],
            [73.143563037000035, 32.277127295000071],
            [73.149871000000076, 32.265085000000056],
            [73.150620849000063, 32.258084973000052],
            [73.15062088500008, 32.258084637000024],
            [73.15160700000007, 32.248879000000045],
            [73.16086800000005, 32.237881000000073],
            [73.165498000000071, 32.237881000000073],
            [73.176495000000045, 32.239039000000048],
            [73.176496000000043, 32.239039000000048],
            [73.185746056000085, 32.239039000000048],
            [73.187493000000075, 32.239039000000048],
            [73.194010999000056, 32.239039000000048],
            [73.196754000000055, 32.239039000000048],
            [73.203699000000029, 32.243669000000068],
            [73.207888403000084, 32.249115658000051],
            [73.209487000000081, 32.251194000000055],
            [73.213539000000083, 32.255824000000075],
            [73.221642000000031, 32.263928000000078],
            [73.221642455000051, 32.263927455000044],
            [73.221643000000029, 32.263928000000078],
            [73.226200000000063, 32.258459000000073],
            [73.226870537000082, 32.257654464000041],
            [73.227431000000081, 32.25698200000005],
            [73.229854930000045, 32.253211391000036],
            [73.23264000000006, 32.248879000000045],
            [73.237271000000078, 32.240775000000042],
            [73.242480000000057, 32.228042000000073],
            [73.246073935000084, 32.220135096000035],
            [73.248268000000053, 32.21530800000005],
            [73.254056000000048, 32.205468000000053],
            [73.254634984000063, 32.197365227000034],
            [73.254635000000064, 32.197365000000048],
            [73.254635000000064, 32.189691276000076],
            [73.254635000000064, 32.189678815000036],
            [73.254635000000064, 32.187525000000051],
            [73.253477000000032, 32.180001000000061],
            [73.253740057000073, 32.179562497000063],
            [73.260422000000062, 32.168424000000073],
            [73.260422968000057, 32.16842405400007],
            [73.267220405000046, 32.168801786000074],
            [73.270841000000075, 32.169003000000032],
            [73.276050000000055, 32.163215000000037],
            [73.276050000000055, 32.158006000000057],
            [73.285960991000081, 32.149581314000045],
            [73.287626000000046, 32.14816600000006],
            [73.292257000000063, 32.147009000000025],
            [73.292657118000079, 32.145897441000045],
            [73.297466000000043, 32.132538000000068],
            [73.305504196000072, 32.12355466200006],
            [73.30730600000004, 32.121541000000036],
            [73.31019090500007, 32.122090601000025],
            [73.310193000000083, 32.122091000000069],
            [73.310192962000031, 32.122090841000045],
            [73.309741949000056, 32.120196494000027],
            [73.30730600000004, 32.109965000000045],
            [73.30553614300004, 32.109375048000061],
            [73.296887000000083, 32.10649200000006],
            [73.289729464000061, 32.101624678000064],
            [73.282417000000066, 32.096652000000063],
            [73.267368000000033, 32.087391000000025],
            [73.265333319000035, 32.084518376000062],
            [73.257529000000034, 32.073500000000024],
            [73.257529000000034, 32.067940026000031],
            [73.257529000000034, 32.067735751000043],
            [73.257529000000034, 32.063989848000062],
            [73.257529000000034, 32.062503000000049],
            [73.261589403000073, 32.05488908500007],
            [73.261946254000065, 32.05421993300007],
            [73.262159000000054, 32.053821000000028],
            [73.255176177000067, 32.045763469000065],
            [73.254635000000064, 32.045139000000063],
            [73.253569281000068, 32.040166871000054],
            [73.252898000000073, 32.03703500000006],
            [73.242480000000057, 32.036456000000044],
            [73.240318951000063, 32.034604282000032],
            [73.238428000000056, 32.032984000000056],
            [73.233798000000036, 32.030668000000048],
            [73.234014234000028, 32.028286063000053],
            [73.234376000000054, 32.024301000000037],
            [73.235130664000053, 32.021660005000058],
            [73.236029218000056, 32.018515452000031],
            [73.236652038000045, 32.016335852000054],
            [73.23784900000004, 32.012147000000027],
            [73.237852858000053, 32.01206985500005],
            [73.238428000000056, 32.000570000000039],
            [73.238066871000058, 31.998981105000041],
            [73.236726333000036, 31.993083015000025],
            [73.23616908200006, 31.990631227000051],
            [73.23553400000003, 31.98783700000007],
            [73.230935930000044, 31.985300049000045],
            [73.223725295000065, 31.981321634000039],
            [73.219138103000034, 31.978790685000035],
            [73.218749000000059, 31.978576000000032],
            [73.216675757000075, 31.97656375300005],
            [73.207211478000033, 31.967377920000047],
            [73.207209812000031, 31.967378646000043],
            [73.207209714000044, 31.967378551000024],
            [73.207211377000078, 31.96737782200006],
            [73.200244465000083, 31.96061588300006],
            [73.199069000000065, 31.959475000000054],
            [73.196008402000075, 31.95478187100008],
            [73.194665564000047, 31.952722760000029],
            [73.194665538000038, 31.952722721000043],
            [73.191969626000059, 31.948588802000074],
            [73.190387000000044, 31.946162000000072],
            [73.193860001000076, 31.934586000000024],
            [73.192482110000071, 31.930911538000032],
            [73.191735799000071, 31.928921329000048],
            [73.18865100000005, 31.920695000000023],
            [73.187797681000063, 31.919110296000042],
            [73.187570836000077, 31.918689021000034],
            [73.184599000000048, 31.913170000000036],
            [73.181705000000079, 31.905067000000031],
            [73.179976840000052, 31.897000595000065],
            [73.179969000000028, 31.896964000000025],
            [73.173528153000063, 31.89490300600005],
            [73.173528152000074, 31.894902804000026],
            [73.166654000000051, 31.892702942000028],
            [73.166022497000029, 31.892500849000044],
            [73.163576256000056, 31.896668519000059],
            [73.159676256000068, 31.905668519000074],
            [73.159517235000067, 31.905382282000062],
            [73.159274914000036, 31.905910996000046],
            [73.150076256000034, 31.911468519000039],
            [73.146220548000031, 31.915017523000074],
            [73.142711291000069, 31.918247635000057],
            [73.141819503000079, 31.919068485000025],
            [73.141188000000056, 31.919537000000048],
            [73.131934379000029, 31.920249054000067],
            [73.126653370000042, 31.920633763000069],
            [73.126176256000065, 31.92066851900006],
            [73.118676256000072, 31.92066851900006],
            [73.118666841000049, 31.920695000000023],
            [73.118615000000034, 31.920695000000023],
            [73.11701682100005, 31.925090510000075],
            [73.116391355000076, 31.926810743000033],
            [73.116300000000081, 31.927062000000035],
            [73.109805319000031, 31.927062000000035],
            [73.102408000000082, 31.927062000000035],
            [73.093726000000061, 31.927062000000035],
            [73.093128427000067, 31.927093436000064],
            [73.091700451000065, 31.927168555000037],
            [73.09117868900006, 31.927196002000073],
            [73.083915522000041, 31.927578083000071],
            [73.074005990000046, 31.928099376000034],
            [73.073976292000054, 31.928100938000057],
            [73.071732000000054, 31.92821900000007],
            [73.071681666000075, 31.928269334000049],
            [73.068838000000085, 31.931113000000039],
            [73.06133883800004, 31.929382961000044],
            [73.061313000000041, 31.929377000000045],
            [73.050316000000066, 31.933429000000046],
            [73.049946145000035, 31.933724858000062],
            [73.046456295000041, 31.93651649800006],
            [73.041634000000045, 31.940374000000077],
            [73.04014197500004, 31.941120084000033],
            [73.035468475000073, 31.943457058000035],
            [73.031215000000032, 31.945584000000053],
            [73.015587000000039, 31.951951000000065],
            [72.999960000000044, 31.96063300000003],
            [72.992068000000074, 31.960217000000057],
            [72.988962000000072, 31.960054000000071],
            [72.986177594000083, 31.959028046000071],
            [72.981902160000061, 31.957452702000069],
            [72.977976256000034, 31.955968519000066],
            [72.968447291000075, 31.960553885000024],
            [72.964652000000058, 31.962369000000024],
            [72.962982157000056, 31.971277073000067],
            [72.962916000000064, 31.971630000000062],
            [72.950264291000053, 31.976230079000061],
            [72.950182000000041, 31.976260000000025],
            [72.949745976000031, 31.974843085000032],
            [72.946155186000055, 31.963174378000076],
            [72.94555100000008, 31.961211000000048],
            [72.94323600000007, 31.944426000000078],
            [72.960600000000056, 31.931114000000036],
            [72.950026152000078, 31.933463518000053],
            [72.939763000000084, 31.935744000000057],
            [72.937426780000067, 31.935243484000068],
            [72.934783128000049, 31.934677104000059],
            [72.931660000000079, 31.934008000000063],
            [72.928110420000053, 31.930812888000048],
            [72.925872000000084, 31.928798000000029],
            [72.92587650300004, 31.928768741000056],
            [72.925883645000056, 31.928722340000036],
            [72.927076256000078, 31.92126851900008],
            [72.927027789000078, 31.921288364000077],
            [72.927030000000059, 31.921274000000039],
            [72.914348127000039, 31.926461677000077],
            [72.910676256000045, 31.925568519000024],
            [72.904804670000033, 31.924229385000046],
            [72.904839443000071, 31.924300621000043],
            [72.899247000000059, 31.923010000000033],
            [72.899033020000047, 31.921226424000054],
            [72.897576220000076, 31.909083625000051],
            [72.897511000000065, 31.908540000000073],
            [72.897576220000076, 31.908482922000076],
            [72.902141000000029, 31.904488000000072],
            [72.907830442000034, 31.898229417000039],
            [72.907929000000081, 31.89812100000006],
            [72.909165505000033, 31.897524074000046],
            [72.924525512000059, 31.890108993000069],
            [72.924714000000051, 31.890018000000055],
            [72.929124941000055, 31.889678607000064],
            [72.93227625600008, 31.889468519000047],
            [72.932554578000065, 31.889439000000039],
            [72.938876256000071, 31.888768519000052],
            [72.940307467000082, 31.889722660000075],
            [72.941876256000057, 31.890768519000062],
            [72.943595051000045, 31.890675611000063],
            [72.94555100000008, 31.890597000000071],
            [72.953076000000067, 31.882494000000065],
            [72.963110579000045, 31.879324945000064],
            [72.964073000000042, 31.87902100000008],
            [72.968125000000043, 31.879600000000039],
            [72.968175107000036, 31.879568351000046],
            [72.968179730000031, 31.87956543100006],
            [72.977505756000085, 31.873674863000076],
            [72.979109884000081, 31.87266165300008],
            [72.979122000000075, 31.872654000000068],
            [72.984738003000075, 31.868161585000053],
            [72.98491000000007, 31.868024000000048],
            [72.984908398000073, 31.868023507000032],
            [72.977386000000081, 31.865708000000041],
            [72.977873156000044, 31.863027171000056],
            [72.977990521000038, 31.862381307000078],
            [72.977992838000034, 31.862368555000046],
            [72.978543000000059, 31.859341000000029],
            [72.978912277000063, 31.857249283000044],
            [72.98028000000005, 31.84950200000003],
            [72.981666759000063, 31.846173587000067],
            [72.983169253000028, 31.842567394000071],
            [72.983174000000076, 31.842556000000059],
            [72.978809571000056, 31.841015587000072],
            [72.97441301300006, 31.83946383500006],
            [72.974276256000053, 31.839368519000061],
            [72.964076256000055, 31.833868519000077],
            [72.956976256000075, 31.839668519000043],
            [72.957165768000038, 31.844027297000025],
            [72.957128000000068, 31.844292000000053],
            [72.950088795000056, 31.847979258000066],
            [72.944973000000061, 31.850659000000064],
            [72.936651617000052, 31.850104049000038],
            [72.936376256000074, 31.850068519000047],
            [72.923576256000047, 31.846568519000073],
            [72.915876256000047, 31.841968519000034],
            [72.915044232000071, 31.84287185900007],
            [72.911973936000038, 31.845442314000024],
            [72.90850800000004, 31.848344000000054],
            [72.903299000000061, 31.855869000000041],
            [72.903161579000084, 31.85660159400004],
            [72.901566808000041, 31.865103367000074],
            [72.901562000000069, 31.865129000000024],
            [72.895195000000058, 31.872654000000068],
            [72.892459364000047, 31.873240087000056],
            [72.887961997000048, 31.874203610000052],
            [72.887192097000082, 31.874368555000046],
            [72.887092000000052, 31.874390000000062],
            [72.887037192000037, 31.874368555000046],
            [72.876951624000071, 31.870422358000042],
            [72.87377900000007, 31.869181000000026],
            [72.872383533000061, 31.867320377000055],
            [72.865097000000048, 31.857605000000035],
            [72.856415000000084, 31.852975000000072],
            [72.854348888000061, 31.847034147000045],
            [72.851785000000064, 31.839662000000033],
            [72.859888000000069, 31.825771000000032],
            [72.850048000000072, 31.822298000000046],
            [72.83804695200007, 31.828584357000068],
            [72.837893000000065, 31.828665000000058],
            [72.827007408000043, 31.836200790000078],
            [72.824454997000032, 31.837967752000054],
            [72.822863664000067, 31.839069387000052],
            [72.822844000000032, 31.839083000000073],
            [72.817458409000039, 31.839442163000058],
            [72.814162000000067, 31.839662000000033],
            [72.81342610400003, 31.839563847000079],
            [72.805480000000045, 31.838504000000057],
            [72.80534583900004, 31.838478851000048],
            [72.796221769000056, 31.836768519000032],
            [72.796219000000065, 31.836768000000063],
            [72.787575144000073, 31.836768000000063],
            [72.784162012000081, 31.835175205000041],
            [72.780076256000029, 31.833268519000057],
            [72.772976256000049, 31.825668519000033],
            [72.772976220000032, 31.825668463000056],
            [72.770776256000033, 31.822268519000033],
            [72.761419637000074, 31.818499881000037],
            [72.756376256000067, 31.816468519000068],
            [72.749976256000082, 31.813068519000069],
            [72.745876256000031, 31.805468519000044],
            [72.738376256000038, 31.803768519000073],
            [72.738197312000068, 31.802068555000062],
            [72.737976256000081, 31.79996851900006],
            [72.73727625600003, 31.794468519000077],
            [72.736831621000078, 31.794678059000034],
            [72.728499000000056, 31.798567000000048],
            [72.725026000000071, 31.802039000000036],
            [72.721109560000059, 31.802039000000036],
            [72.716946737000058, 31.802039000000036],
            [72.711776256000064, 31.796868519000043],
            [72.703076256000031, 31.791068519000078],
            [72.698683796000068, 31.787936191000028],
            [72.691227180000055, 31.78261012400003],
            [72.690877000000057, 31.78236000000004],
            [72.686799849000067, 31.77970999300004],
            [72.679376256000069, 31.774868519000051],
            [72.679358734000061, 31.774873525000032],
            [72.679301000000066, 31.77483600000005],
            [72.677950413000076, 31.775221834000035],
            [72.663094000000058, 31.77946600000007],
            [72.659556982000083, 31.780090011000027],
            [72.653443573000061, 31.781168555000079],
            [72.653254000000061, 31.781202000000064],
            [72.653212834000044, 31.781168555000079],
            [72.651645272000053, 31.779895006000061],
            [72.64907622000004, 31.777807807000045],
            [72.648795586000062, 31.777579809000031],
            [72.649076256000058, 31.777168519000043],
            [72.64907622000004, 31.77716849400008],
            [72.644076256000062, 31.773668519000068],
            [72.633076256000038, 31.773668519000068],
            [72.616876256000069, 31.772468519000029],
            [72.609876256000064, 31.772468519000029],
            [72.605273022000063, 31.771614311000064],
            [72.600176256000054, 31.770668519000026],
            [72.597776256000031, 31.770168519000038],
            [72.585576256000081, 31.765568519000055],
            [72.572876256000029, 31.764968519000035],
            [72.572219716000063, 31.76483072700006],
            [72.572193740000046, 31.764866039000026],
            [72.570538593000038, 31.764511233000064],
            [72.570569157000079, 31.764484313000025],
            [72.564776256000073, 31.763268519000064],
            [72.562676306000071, 31.76176855500006],
            [72.562676256000032, 31.761768519000043],
            [72.557974921000039, 31.758605803000023],
            [72.557172000000037, 31.758050000000026],
            [72.557097035000083, 31.757768858000077],
            [72.554876256000057, 31.749368519000029],
            [72.55667625600006, 31.741268519000073],
            [72.554376256000069, 31.73316851900006],
            [72.545676256000036, 31.732368519000033],
            [72.541614791000029, 31.733161000000052],
            [72.541545000000042, 31.733161000000052],
            [72.535085064000043, 31.732415805000073],
            [72.526537527000073, 31.73142979000005],
            [72.526496000000066, 31.731425000000058],
            [72.520213200000057, 31.72514220100004],
            [72.520233200000064, 31.72512546300004],
            [72.51957625600005, 31.724468519000027],
            [72.518476256000042, 31.715768519000051],
            [72.520076256000038, 31.711268519000043],
            [72.521715564000033, 31.706441667000036],
            [72.521876256000041, 31.705968519000066],
            [72.521863142000029, 31.70596226300006],
            [72.521865000000048, 31.705957000000069],
            [72.512025000000051, 31.700169000000074],
            [72.510929623000038, 31.700716925000052],
            [72.509710000000041, 31.701327000000049],
            [72.506237000000056, 31.695539000000053],
            [72.506191084000079, 31.695424199000058],
            [72.504030253000053, 31.690021656000056],
            [72.503922000000046, 31.689751000000058],
            [72.503166975000056, 31.688995975000068],
            [72.498820865000084, 31.68464986500004],
            [72.498739519000083, 31.684568519000038],
            [72.498713000000066, 31.684542000000079],
            [72.489452000000028, 31.684542000000079],
            [72.489372158000037, 31.684462158000031],
            [72.485522295000067, 31.68061229500006],
            [72.484821000000068, 31.679911000000061],
            [72.477876000000037, 31.672387000000072],
            [72.477776220000067, 31.672298309000041],
            [72.477751926000053, 31.672276715000066],
            [72.477776256000084, 31.672268519000056],
            [72.473198585000034, 31.668229397000061],
            [72.472666000000061, 31.667756000000054],
            [72.471317719000069, 31.667284171000063],
            [72.470049017000065, 31.666840191000063],
            [72.47002532700003, 31.666831901000023],
            [72.46109000000007, 31.66370500000005],
            [72.458709888000044, 31.66370500000005],
            [72.446620000000053, 31.66370500000005],
            [72.44129029000004, 31.66370500000005],
            [72.439077000000054, 31.66370500000005],
            [72.437359000000072, 31.66370500000005],
            [72.43330700000007, 31.658495000000073],
            [72.424625000000049, 31.658495000000073],
            [72.417679000000078, 31.656180000000063],
            [72.412835418000043, 31.655739501000028],
            [72.404946000000052, 31.655022000000031],
            [72.398379706000071, 31.654694269000061],
            [72.393370000000061, 31.654444000000069],
            [72.390748121000058, 31.655026648000046],
            [72.386351259000037, 31.656003604000034],
            [72.382951000000048, 31.656759000000079],
            [72.379273957000066, 31.658056934000058],
            [72.375419218000047, 31.659417591000079],
            [72.375418275000072, 31.659418018000054],
            [72.373112000000049, 31.660232000000065],
            [72.371385982000049, 31.656973612000058],
            [72.367902000000072, 31.650392000000068],
            [72.368007340000077, 31.649991767000074],
            [72.368135837000068, 31.649503550000077],
            [72.370046830000035, 31.642242088000046],
            [72.370796000000041, 31.639395000000036],
            [72.374269000000083, 31.629555000000039],
            [72.373385192000057, 31.626019575000043],
            [72.371954000000073, 31.620294000000058],
            [72.369437754000046, 31.619150347000073],
            [72.364944815000058, 31.617108271000063],
            [72.363128785000072, 31.616282871000067],
            [72.35922000000005, 31.614506000000063],
            [72.348223000000075, 31.611033000000077],
            [72.339541000000054, 31.611033000000077],
            [72.329701000000057, 31.607560000000035],
            [72.320440000000076, 31.600615000000062],
            [72.31708223000004, 31.598726146000047],
            [72.311179000000038, 31.595405000000028],
            [72.309655177000081, 31.593881396000029],
            [72.304233000000067, 31.588460000000055],
            [72.290342000000066, 31.585566000000028],
            [72.28861607500005, 31.580881767000051],
            [72.287088825000069, 31.576736285000038],
            [72.286290000000065, 31.574568000000056],
            [72.286290000000065, 31.566465000000051],
            [72.279345000000035, 31.556625000000054],
            [72.264449237000065, 31.561745755000061],
            [72.260823000000073, 31.562992000000065],
            [72.249247000000082, 31.577462000000025],
            [72.248389804000055, 31.580605284000058],
            [72.247937633000049, 31.582263369000032],
            [72.24577400000004, 31.590196000000049],
            [72.250983000000076, 31.59656300000006],
            [72.251517971000055, 31.597365551000053],
            [72.25438777800008, 31.601670508000041],
            [72.260244000000057, 31.610454000000061],
            [72.264862381000057, 31.615072381000061],
            [72.264862417000074, 31.615072417000079],
            [72.265181372000029, 31.615391372000033],
            [72.265181013000074, 31.615391841000076],
            [72.268347000000063, 31.618558000000064],
            [72.272480286000075, 31.623008984000023],
            [72.275872000000049, 31.62666100000007],
            [72.280952471000035, 31.631016048000049],
            [72.283975000000055, 31.63360700000004],
            [72.285066787000062, 31.634126965000064],
            [72.296130000000062, 31.639395000000036],
            [72.298849564000079, 31.64131444800006],
            [72.303673696000033, 31.644719286000054],
            [72.305970000000059, 31.646340000000066],
            [72.308541781000031, 31.652217902000075],
            [72.309229062000043, 31.653788709000025],
            [72.309263035000072, 31.653866356000037],
            [72.31002200000006, 31.655601000000047],
            [72.304234000000065, 31.670650000000023],
            [72.304234000000065, 31.677500140000063],
            [72.304233013000044, 31.677500894000048],
            [72.304233000000067, 31.682226000000071],
            [72.304233964000048, 31.682226207000042],
            [72.31233700000007, 31.683963000000062],
            [72.318125000000066, 31.677017000000035],
            [72.324492000000078, 31.671229000000039],
            [72.331437000000051, 31.669493000000045],
            [72.331437053000059, 31.66949323700004],
            [72.331438000000048, 31.669493000000045],
            [72.333111684000073, 31.677026026000078],
            [72.333694163000075, 31.679647683000042],
            [72.333693742000037, 31.679647869000064],
            [72.334652848000076, 31.68396303600008],
            [72.334910000000036, 31.68512000000004],
            [72.340698000000032, 31.692645000000027],
            [72.349469107000061, 31.698184563000041],
            [72.351696000000061, 31.699591000000055],
            [72.355736998000054, 31.704978740000058],
            [72.355737034000072, 31.704978788000062],
            [72.35690500000004, 31.706536000000028],
            [72.358528179000075, 31.710189029000048],
            [72.35922000000005, 31.711746000000062],
            [72.35921950200003, 31.711746343000073],
            [72.349959000000069, 31.718112000000076],
            [72.348775581000041, 31.719295577000025],
            [72.348776223000073, 31.719296444000065],
            [72.343355517000077, 31.724722841000073],
            [72.340087891000053, 31.728088379000042],
            [72.328531160000068, 31.738435937000077],
            [72.328379487000063, 31.738573161000033],
            [72.327964000000065, 31.738949000000048],
            [72.325410095000052, 31.740165335000029],
            [72.32222732200006, 31.741681178000078],
            [72.315670671000078, 31.744814863000045],
            [72.315597792000062, 31.744855359000042],
            [72.310831497000038, 31.74750378400006],
            [72.300205893000054, 31.753406726000037],
            [72.300182000000063, 31.753420000000062],
            [72.286869000000081, 31.758629000000042],
            [72.284710421000057, 31.759168586000044],
            [72.284710049000068, 31.759168679000027],
            [72.279592359000048, 31.760447964000036],
            [72.278202657000065, 31.760795352000059],
            [72.277769222000074, 31.760903699000039],
            [72.277704363000055, 31.760919912000077],
            [72.277647144000071, 31.760934215000077],
            [72.277635057000055, 31.760937236000075],
            [72.277608000000043, 31.760944000000052],
            [72.270678260000068, 31.762329971000042],
            [72.266032000000052, 31.763259000000062],
            [72.256192000000055, 31.759208000000058],
            [72.255485230000033, 31.752843490000032],
            [72.255035000000078, 31.748789000000045],
            [72.252527986000075, 31.748403407000069],
            [72.246094200000073, 31.747413855000048],
            [72.239986000000044, 31.746474000000035],
            [72.235352411000065, 31.748580111000024],
            [72.227252000000078, 31.75226200000003],
            [72.217048016000035, 31.756344194000064],
            [72.217046000000039, 31.756345000000067],
            [72.230322000000058, 31.77836700000006],
            [72.23204478200006, 31.781166254000027],
            [72.232624474000033, 31.782108165000068],
            [72.232627137000065, 31.782112491000078],
            [72.237607000000082, 31.790204000000074],
            [72.242615000000058, 31.804773000000068],
            [72.261281000000054, 31.807505000000049],
            [72.263516289000052, 31.811696320000067],
            [72.263516376000041, 31.811696484000038],
            [72.268565000000081, 31.82116300000007],
            [72.256728000000066, 31.834366000000045],
            [72.256273000000078, 31.847569000000078],
            [72.257638000000043, 31.85439800000006],
            [72.25763896400008, 31.854397821000077],
            [72.25763900000004, 31.85439800000006],
            [72.269931000000042, 31.852121000000068],
            [72.284044000000051, 31.848024000000066],
            [72.288085938000052, 31.862121582000043],
            [72.282533110000031, 31.867059245000064],
            [72.281880123000064, 31.867639892000057],
            [72.281878513000038, 31.867641315000071],
            [72.281874243000061, 31.867637453000043],
            [72.279442878000054, 31.869771169000046],
            [72.273118000000068, 31.875340000000051],
            [72.270341526000038, 31.883437202000039],
            [72.26765400000005, 31.891275000000064],
            [72.264468504000035, 31.897882080000045],
            [72.264469244000054, 31.897882080000045],
            [72.260070602000042, 31.907004621000056],
            [72.260070259000031, 31.907004538000024],
            [72.255362000000048, 31.916770000000042],
            [72.256447235000053, 31.921111137000025],
            [72.258604817000048, 31.929741862000071],
            [72.258605365000051, 31.929744053000036],
            [72.260798532000081, 31.938517125000033],
            [72.260825000000068, 31.938623000000064],
            [72.263233318000061, 31.943156177000049],
            [72.263233498000034, 31.943156515000055],
            [72.268565000000081, 31.953192000000058],
            [72.269475000000057, 31.966850000000079],
            [72.266469984000082, 31.970329565000043],
            [72.266461206000031, 31.970339729000045],
            [72.26290713800006, 31.974030321000043],
            [72.260831658000029, 31.976858290000052],
            [72.260825000000068, 31.97686600000003],
            [72.253541000000041, 31.983239000000026],
            [72.254725802000053, 31.990111997000042],
            [72.255075860000034, 31.992142674000036],
            [72.255075872000077, 31.992142741000066],
            [72.255817000000036, 31.996442000000059],
            [72.259850000000029, 32.000475000000051],
            [72.26005549000007, 32.000680472000056],
            [72.260065240000074, 32.000674735000075],
            [72.265833000000043, 32.006442494000055],
            [72.276760000000081, 32.006003000000078],
            [72.284495553000056, 32.010789210000041],
            [72.284495589000073, 32.010789226000043],
            [72.294971000000032, 32.01556400000004],
            [72.298613000000046, 32.022848000000067],
            [72.303700882000044, 32.024747147000028],
            [72.30409320800004, 32.025130950000062],
            [72.304093249000061, 32.025130983000054],
            [72.311659228000053, 32.032532584000023],
            [72.324328993000051, 32.045215484000039],
            [72.324606544000062, 32.045493323000073],
            [72.325961000000063, 32.055986000000075],
            [72.326384000000076, 32.059270000000026],
            [72.326153698000041, 32.061267128000054],
            [72.325627994000058, 32.065825928000038],
            [72.325019000000054, 32.07110700000004],
            [72.325649679000037, 32.075648663000038],
            [72.326824411000075, 32.084108178000065],
            [72.327295000000049, 32.087497000000042],
            [72.327145483000038, 32.095276468000066],
            [72.326620602000048, 32.111020964000033],
            [72.328890035000029, 32.123928053000043],
            [72.329394886000046, 32.127069663000043],
            [72.330937000000063, 32.136666000000048],
            [72.329867894000074, 32.140456544000074],
            [72.327166883000075, 32.150033051000037],
            [72.325929000000031, 32.154422000000068],
            [72.327324823000083, 32.159243831000026],
            [72.330937000000063, 32.171722000000045],
            [72.334026280000046, 32.178243604000045],
            [72.335284773000069, 32.180900337000026],
            [72.336614104000034, 32.183706612000037],
            [72.33805385800008, 32.186745995000024],
            [72.339132000000063, 32.18902200000008],
            [72.340920284000049, 32.193194836000032],
            [72.345961000000045, 32.204957000000036],
            [72.345961000000045, 32.205713603000049],
            [72.345961000000045, 32.220891000000051],
            [72.346708457000034, 32.224005040000065],
            [72.348693000000083, 32.232273000000077],
            [72.353899514000034, 32.237311658000067],
            [72.362806000000035, 32.245931000000041],
            [72.363822393000078, 32.246721574000048],
            [72.363822947000074, 32.246722005000038],
            [72.374863030000029, 32.255309235000027],
            [72.375098000000037, 32.255492000000061],
            [72.392807340000047, 32.255945804000078],
            [72.392854000000057, 32.255947000000049],
            [72.393261562000077, 32.256306615000028],
            [72.406111610000039, 32.267644942000061],
            [72.408333000000084, 32.26960500000007],
            [72.420998122000071, 32.275649890000068],
            [72.428365000000042, 32.279166000000032],
            [72.42923584600004, 32.279474135000044],
            [72.446817981000038, 32.285695286000077],
            [72.450737544000049, 32.287082159000079],
            [72.457958000000076, 32.289637000000027],
            [72.471161000000052, 32.30010800000008],
            [72.472124638000082, 32.301553615000046],
            [72.472191174000045, 32.301653430000044],
            [72.480266000000029, 32.313767000000041],
            [72.498477000000037, 32.324693000000025],
            [72.498962530000028, 32.324896244000058],
            [72.508401794000065, 32.32884755300006],
            [72.511568077000049, 32.330172970000035],
            [72.511568116000035, 32.330172986000036],
            [72.518054000000063, 32.332888000000025],
            [72.51946453100004, 32.33646890700004],
            [72.523972000000072, 32.347912000000065],
            [72.529599030000043, 32.34964332100003],
            [72.535809000000029, 32.351554000000078],
            [72.540298032000067, 32.354546688000028],
            [72.543119755000077, 32.35642783600008],
            [72.548095741000054, 32.359745160000045],
            [72.550833000000068, 32.361570000000029],
            [72.569044000000076, 32.372497000000067],
            [72.579048596000064, 32.371271764000028],
            [72.591352000000029, 32.369765000000029],
            [72.600913000000048, 32.379781000000037],
            [72.601037160000033, 32.379836872000055],
            [72.619124000000056, 32.387976000000037],
            [72.624189183000055, 32.389664394000079],
            [72.625554678000071, 32.390119559000027],
            [72.634148000000039, 32.39298400000007],
            [72.652359000000047, 32.398447000000033],
            [72.66328500000003, 32.393894000000046],
            [72.66328500000003, 32.395271000000037],
            [72.663740000000075, 32.394805000000076],
            [72.675122000000044, 32.39298400000007],
            [72.675122590000058, 32.392984066000054],
            [72.675123000000042, 32.39298400000007],
            [72.687034406000066, 32.394307221000076],
            [72.692305150000038, 32.394892740000046],
            [72.698948591000033, 32.395630750000066],
            [72.699707000000046, 32.395715000000052],
            [72.699730558000056, 32.395743464000077],
            [72.699971394000045, 32.395770017000075],
            [72.710870268000065, 32.408912241000053],
            [72.72930000000008, 32.40846300000004],
            [72.729365210000083, 32.408523193000065],
            [72.732212420000053, 32.411151362000055],
            [72.732257665000077, 32.411193126000057],
            [72.732402052000054, 32.411326405000068],
            [72.737225034000062, 32.415778347000071],
            [72.747056000000043, 32.424853000000041],
            [72.747265158000062, 32.424871726000049],
            [72.761991676000036, 32.426190224000038],
            [72.777559000000053, 32.427584000000024],
            [72.777945708000061, 32.427611137000042],
            [72.797656541000038, 32.428994313000032],
            [72.803509000000076, 32.429405000000031],
            [72.803556546000038, 32.429446840000026],
            [72.810280111000054, 32.435363482000071],
            [72.814891000000046, 32.439421000000038],
            [72.817492923000032, 32.441606643000057],
            [72.817799025000056, 32.441863772000033],
            [72.822849145000077, 32.44610592600003],
            [72.823725526000032, 32.446842095000079],
            [72.826273000000072, 32.448982000000058],
            [72.836874245000047, 32.454419056000063],
            [72.844028000000037, 32.458088000000032],
            [72.846078225000042, 32.459612390000075],
            [72.854260624000062, 32.465696194000031],
            [72.855571056000031, 32.466670531000034],
            [72.861784000000057, 32.471290000000067],
            [72.871963469000036, 32.484863177000079],
            [72.872219056000063, 32.485203974000058],
            [72.874076000000059, 32.487680000000069],
            [72.874260312000047, 32.487745536000034],
            [72.874419068000066, 32.487801985000033],
            [72.87457558400007, 32.487857638000037],
            [72.894708004000051, 32.494966107000039],
            [72.91416126300004, 32.499052666000068],
            [72.927041880000047, 32.501758506000044],
            [72.937273049000055, 32.503907775000073],
            [72.93732078000005, 32.503917802000046],
            [72.937322718000075, 32.503917258000058],
            [72.94888426600005, 32.506346000000065],
            [72.950674627000069, 32.509181148000039],
            [72.950674650000053, 32.509181184000056],
            [72.950675937000028, 32.509183222000047],
            [72.95434726600007, 32.514997000000051],
            [72.963908266000033, 32.516362000000072],
            [72.972649261000072, 32.519359226000063],
            [72.979948471000057, 32.521881017000055],
            [72.981408431000034, 32.526625953000064],
            [72.985411471000077, 32.539636017000078],
            [72.989607315000058, 32.541731706000064],
            [72.989607351000075, 32.541731724000044],
            [72.990852048000079, 32.542354518000025],
            [72.990829159000043, 32.542414319000045],
            [72.990849323000077, 32.542424402000051],
            [72.997000000000071, 32.545500000000061],
            [72.997083968000084, 32.545472707000044],
            [73.015210000000081, 32.539581000000055],
            [73.016536881000036, 32.539701620000073],
            [73.020275731000083, 32.540041498000051],
            [73.021387115000039, 32.540142528000047],
            [73.035242000000039, 32.541402000000062],
            [73.03642628800003, 32.541548528000078],
            [73.03645044700005, 32.541551517000073],
            [73.045622191000064, 32.54268630200005],
            [73.052075313000046, 32.543484723000063],
            [73.055988159000037, 32.543968845000052],
            [73.064530267000066, 32.545025729000031],
            [73.065519082000037, 32.545148071000028],
            [73.068169067000042, 32.545475944000032],
            [73.071578790000046, 32.545897816000036],
            [73.079404000000068, 32.54686600000008],
            [73.080724632000056, 32.547281061000035],
            [73.084265858000038, 32.548387168000033],
            [73.084265973000072, 32.548387204000051],
            [73.093790905000048, 32.551362328000039],
            [73.093796289000068, 32.55134432400007],
            [73.095461258000057, 32.551867607000077],
            [73.101405958000043, 32.553735966000033],
            [73.101910760000067, 32.553894620000051],
            [73.10923155200004, 32.55619547200007],
            [73.122506589000068, 32.560367682000049],
            [73.126593196000044, 32.561652060000029],
            [73.126791715000081, 32.561714453000036],
            [73.127350267000054, 32.561890000000062],
            [73.129248120000057, 32.562359599000047],
            [73.13792184600004, 32.564505801000053],
            [73.148722496000062, 32.567178282000043],
            [73.148747324000055, 32.56718442500005],
            [73.161774088000072, 32.570407729000067],
            [73.165150964000077, 32.571243294000055],
            [73.165169519000074, 32.571247885000048],
            [73.165295267000033, 32.571279000000061],
            [73.165642266000077, 32.570696000000055],
            [73.16795726600003, 32.568959000000063],
            [73.172396930000048, 32.567360893000057],
            [73.172397450000062, 32.56736070900007],
            [73.18255696500006, 32.56383928200006],
            [73.184268811000038, 32.563585687000057],
            [73.184268848000045, 32.563585682000053],
            [73.198177735000058, 32.561535202000073],
            [73.204550965000067, 32.552263282000069],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "121",
      properties: { name: "Sheikhupura", count: 1492 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.602268930000037, 32.025342310000042],
            [74.602292095000053, 32.025322620000054],
            [74.602292629000033, 32.025323865000075],
            [74.604716000000053, 32.02326400000004],
            [74.612175945000047, 32.015981673000056],
            [74.612875192000047, 32.01529907400004],
            [74.620004000000051, 32.008340000000032],
            [74.621440187000076, 32.00614792500005],
            [74.621464161000063, 32.006111334000025],
            [74.621462644000076, 32.006110596000042],
            [74.626918000000046, 31.997784000000024],
            [74.632320981000078, 31.989139231000024],
            [74.632365201000084, 31.989068479000025],
            [74.632366926000032, 31.989068918000044],
            [74.635345138000048, 31.984303780000062],
            [74.637719247000064, 31.980505205000043],
            [74.63771765100006, 31.980504560000043],
            [74.637722428000075, 31.98049691600005],
            [74.639658000000054, 31.977400000000046],
            [74.640835254000081, 31.976390930000036],
            [74.640836340000078, 31.976391680000063],
            [74.649788249000039, 31.968718401000046],
            [74.649787280000055, 31.968717800000036],
            [74.649835395000082, 31.968676559000073],
            [74.652876509000066, 31.966069903000061],
            [74.654397043000074, 31.964766593000036],
            [74.654398301000072, 31.964766817000054],
            [74.66307906600008, 31.957325952000076],
            [74.664374502000044, 31.956215547000056],
            [74.664373690000048, 31.956215222000026],
            [74.664553984000065, 31.956060685000068],
            [74.673572895000063, 31.948330225000063],
            [74.673573519000058, 31.948330454000029],
            [74.675331000000028, 31.946824000000049],
            [74.678937548000079, 31.942516454000042],
            [74.679074530000037, 31.942352847000052],
            [74.681778515000076, 31.93912329300008],
            [74.682824452000034, 31.937874059000023],
            [74.688172570000063, 31.931486438000036],
            [74.688192556000047, 31.93146256700004],
            [74.688435000000084, 31.931173000000058],
            [74.691542467000033, 31.926748703000044],
            [74.690769000000046, 31.926726000000031],
            [74.690767261000076, 31.926705233000064],
            [74.690685286000075, 31.926702261000059],
            [74.690685286000075, 31.926350378000052],
            [74.683070281000084, 31.926302784000029],
            [74.681651000000045, 31.926304000000073],
            [74.678168938000056, 31.926254700000072],
            [74.677057667000042, 31.926241565000055],
            [74.67673288900005, 31.924254357000052],
            [74.676409000000035, 31.923470000000066],
            [74.676408932000072, 31.923469768000075],
            [74.676388257000042, 31.923420081000074],
            [74.675533616000052, 31.920480257000065],
            [74.670056619000036, 31.921902063000061],
            [74.667795737000063, 31.922479469000052],
            [74.666725000000042, 31.922757000000047],
            [74.666727002000073, 31.922752413000069],
            [74.666720630000043, 31.922754040000029],
            [74.667616157000054, 31.92071482700004],
            [74.66791500000005, 31.920030000000054],
            [74.66792741900008, 31.92001631100004],
            [74.667935359000069, 31.919998514000042],
            [74.668676575000063, 31.919182908000039],
            [74.668396961000042, 31.917987848000053],
            [74.668385906000083, 31.917941124000038],
            [74.668128115000059, 31.918279960000064],
            [74.668086110000047, 31.918335213000034],
            [74.66781700000007, 31.918472000000065],
            [74.666981262000036, 31.917894312000044],
            [74.666745000000049, 31.91773100000006],
            [74.666399000000069, 31.917316000000028],
            [74.667724000000078, 31.916555000000074],
            [74.666125000000079, 31.915365000000065],
            [74.665568000000064, 31.91481900000008],
            [74.665169000000049, 31.91454600000003],
            [74.664345000000083, 31.914115000000038],
            [74.663455000000056, 31.913436000000047],
            [74.66286060300007, 31.914241210000057],
            [74.662705000000074, 31.91445200000004],
            [74.661908609000079, 31.913824712000064],
            [74.661019000000067, 31.913124000000039],
            [74.659741000000054, 31.911875000000066],
            [74.658780000000036, 31.910398000000043],
            [74.65833200000003, 31.910398000000043],
            [74.658064473000081, 31.907696462000047],
            [74.657833000000039, 31.905359000000033],
            [74.657106000000056, 31.903609000000074],
            [74.657400000000052, 31.901902000000064],
            [74.655843000000061, 31.900509000000056],
            [74.654293000000052, 31.899683000000039],
            [74.651070000000061, 31.902514000000053],
            [74.64515700000004, 31.894227000000058],
            [74.64445500000005, 31.894536000000073],
            [74.643796794000082, 31.894023348000076],
            [74.64209900000003, 31.892701000000045],
            [74.640803000000062, 31.889552000000037],
            [74.64006100000006, 31.890534000000059],
            [74.639296000000058, 31.889589000000058],
            [74.638663000000065, 31.888383000000033],
            [74.636234000000059, 31.88430100000005],
            [74.632894000000078, 31.884488000000033],
            [74.631703000000073, 31.883595000000071],
            [74.630779000000075, 31.883262000000059],
            [74.630296000000044, 31.882928000000049],
            [74.629287000000033, 31.882188000000042],
            [74.628235000000075, 31.88241000000005],
            [74.627533000000085, 31.882670000000076],
            [74.625996000000043, 31.883300000000077],
            [74.625467000000071, 31.883746000000031],
            [74.620536000000072, 31.887642000000028],
            [74.618171818000064, 31.88638669900007],
            [74.609047128000043, 31.881541794000043],
            [74.607748000000072, 31.880852000000061],
            [74.608183000000054, 31.878525000000025],
            [74.609870000000058, 31.878338000000042],
            [74.609555000000057, 31.877617000000043],
            [74.609458000000075, 31.87636800000007],
            [74.609433400000057, 31.876295777000053],
            [74.609261362000041, 31.875790684000037],
            [74.609099000000072, 31.87531400000006],
            [74.607422000000042, 31.871099000000072],
            [74.606458000000032, 31.868413000000032],
            [74.604727000000082, 31.868416000000025],
            [74.602640000000065, 31.868608000000052],
            [74.602214046000029, 31.868629393000049],
            [74.600410000000068, 31.868720000000053],
            [74.599073000000033, 31.868606000000057],
            [74.597866000000067, 31.868111000000056],
            [74.596968000000061, 31.867387000000065],
            [74.596059000000082, 31.865751000000046],
            [74.59503731500007, 31.86381272400007],
            [74.594348000000082, 31.862505000000056],
            [74.593057755000075, 31.85971362600003],
            [74.59207200000003, 31.857581000000039],
            [74.591791000000057, 31.856237000000078],
            [74.591606174000049, 31.855091644000026],
            [74.591439802000082, 31.854060648000029],
            [74.591400000000078, 31.853814000000057],
            [74.590663000000063, 31.84874300000007],
            [74.587147000000073, 31.850015000000042],
            [74.584691000000078, 31.850948000000074],
            [74.583303000000058, 31.851953000000037],
            [74.581287000000032, 31.849479000000031],
            [74.578804093000031, 31.846461676000047],
            [74.577914000000078, 31.845380000000034],
            [74.576614000000063, 31.844406000000049],
            [74.57586266800007, 31.843831221000073],
            [74.575848000000065, 31.843820000000051],
            [74.57573773300004, 31.843612859000075],
            [74.573933642000043, 31.84022380600004],
            [74.573700492000057, 31.839785824000046],
            [74.573304000000064, 31.839041000000066],
            [74.573169835000044, 31.839124674000061],
            [74.572448439000084, 31.839574584000047],
            [74.57218000000006, 31.839742000000058],
            [74.570725000000039, 31.83716000000004],
            [74.570699940000054, 31.836454496000044],
            [74.57065300000005, 31.835133000000042],
            [74.570640023000067, 31.834703056000023],
            [74.570639786000072, 31.834695190000048],
            [74.570636009000054, 31.83469604000004],
            [74.570541743000035, 31.831446930000027],
            [74.570508000000075, 31.830329000000063],
            [74.570512000000065, 31.829499000000055],
            [74.570515000000057, 31.828678000000025],
            [74.57051000000007, 31.827964000000065],
            [74.570520000000045, 31.827398000000073],
            [74.570581000000061, 31.826591000000064],
            [74.570704000000035, 31.825086000000056],
            [74.570914977000029, 31.822370551000063],
            [74.570944000000054, 31.821997000000067],
            [74.571215000000052, 31.818948000000034],
            [74.571224613000084, 31.818932362000055],
            [74.57122501300006, 31.818927634000033],
            [74.571280975000036, 31.818840674000057],
            [74.57185000000004, 31.817915000000028],
            [74.572946340000044, 31.816252837000036],
            [74.573027190000062, 31.816127203000065],
            [74.572955641000078, 31.816062635000037],
            [74.572598861000074, 31.815758602000074],
            [74.572232083000074, 31.815467709000075],
            [74.571924310000043, 31.815233524000064],
            [74.571885952000059, 31.815197777000037],
            [74.571849000000043, 31.815169000000026],
            [74.571623000000045, 31.81495300000006],
            [74.571614064000073, 31.814944394000065],
            [74.571613459000048, 31.814943830000061],
            [74.571612488000085, 31.814942876000032],
            [74.57129800000007, 31.814640000000054],
            [74.57122508100008, 31.814521918000025],
            [74.571041261000062, 31.814239034000025],
            [74.570989000000054, 31.814162000000067],
            [74.570840803000067, 31.813977281000064],
            [74.570740687000068, 31.813855126000078],
            [74.570644143000038, 31.813741608000043],
            [74.570437882000078, 31.813518508000072],
            [74.570339753000042, 31.813447638000071],
            [74.570228547000056, 31.813374091000071],
            [74.570016827000074, 31.813262003000034],
            [74.569776901000068, 31.813133999000058],
            [74.568898853000064, 31.812376336000057],
            [74.568891061000045, 31.812367539000036],
            [74.568869000000063, 31.81234900000004],
            [74.568810348000056, 31.812276411000028],
            [74.568748822000032, 31.81220694600006],
            [74.568521356000076, 31.811960121000027],
            [74.568486764000056, 31.811910539000053],
            [74.568480000000079, 31.811903000000029],
            [74.568164535000051, 31.811474808000071],
            [74.568068414000038, 31.811348904000056],
            [74.567735977000041, 31.810939135000069],
            [74.567114786000047, 31.81034776100006],
            [74.565394830000059, 31.808688283000038],
            [74.564116531000082, 31.808767143000068],
            [74.56377023400006, 31.808790915000031],
            [74.56376909200003, 31.808791348000057],
            [74.562887745000069, 31.808203379000076],
            [74.562782798000057, 31.808136086000047],
            [74.562007000000051, 31.807645000000036],
            [74.560542000000055, 31.80670200000003],
            [74.559383000000082, 31.806005000000027],
            [74.559121593000043, 31.805788488000076],
            [74.559024373000057, 31.805726150000055],
            [74.558610516000044, 31.805391542000052],
            [74.55798597900008, 31.804914268000061],
            [74.556974617000037, 31.804525283000032],
            [74.55671231000008, 31.804418572000031],
            [74.555897000000073, 31.80409300000008],
            [74.553880000000049, 31.803303000000028],
            [74.553895160000081, 31.803276714000049],
            [74.544049880000046, 31.80258542200005],
            [74.544050089000052, 31.802585391000036],
            [74.553895185000044, 31.803276670000059],
            [74.554050102000076, 31.803008060000025],
            [74.554139337000038, 31.802838447000056],
            [74.554230000000075, 31.802630000000079],
            [74.554463000000055, 31.802059000000042],
            [74.55475000000007, 31.80144400000006],
            [74.554795782000042, 31.80136400400005],
            [74.554825987000072, 31.801299655000037],
            [74.554920601000049, 31.801145907000034],
            [74.554936000000055, 31.801119000000028],
            [74.554948447000072, 31.801100657000063],
            [74.555105499000035, 31.800845447000029],
            [74.555155670000033, 31.800795276000031],
            [74.555164000000048, 31.800783000000024],
            [74.555545000000052, 31.800401000000079],
            [74.555794447000039, 31.800178365000079],
            [74.555955681000057, 31.800030204000052],
            [74.556398242000057, 31.79962258200004],
            [74.556724340000073, 31.799319777000051],
            [74.557003852000037, 31.799063558000057],
            [74.557248425000068, 31.798842277000062],
            [74.55753958300005, 31.798620997000057],
            [74.55784238800004, 31.798329838000029],
            [74.558133547000068, 31.797968802000071],
            [74.558366473000035, 31.797596119000048],
            [74.558576108000068, 31.797072034000053],
            [74.558740520000072, 31.796691289000023],
            [74.558793000000037, 31.796551000000079],
            [74.558933000000081, 31.79612000000003],
            [74.55898127100005, 31.795726684000044],
            [74.558983729000033, 31.795686119000038],
            [74.559018668000078, 31.795360022000068],
            [74.55902880800005, 31.795197793000057],
            [74.558994664000068, 31.794843276000051],
            [74.558238364000033, 31.794579717000033],
            [74.557248425000068, 31.793624718000046],
            [74.556788395000069, 31.793230683000047],
            [74.556446391000065, 31.792726212000048],
            [74.55604500000004, 31.792144000000064],
            [74.555794000000049, 31.791413000000034],
            [74.555674716000055, 31.79114994400004],
            [74.555608017000054, 31.791020251000077],
            [74.555557823000072, 31.790892160000055],
            [74.55555260400007, 31.790880651000066],
            [74.555492000000072, 31.790747000000067],
            [74.555385000000058, 31.790473000000077],
            [74.55531363800003, 31.790153300000043],
            [74.55527005700003, 31.790000765000059],
            [74.555269828000064, 31.78995702900005],
            [74.555260000000033, 31.78991300000007],
            [74.55526866200006, 31.789734345000056],
            [74.555266606000032, 31.789341669000066],
            [74.555283528000075, 31.788563275000058],
            [74.555286839000075, 31.788365636000037],
            [74.555266606000032, 31.787354027000049],
            [74.555250427000033, 31.787154484000041],
            [74.555195000000083, 31.78666000000004],
            [74.555141676000062, 31.786252937000029],
            [74.555099788000064, 31.786029535000068],
            [74.555058217000067, 31.785877639000034],
            [74.554856154000049, 31.785439835000034],
            [74.554796749000047, 31.785331731000042],
            [74.554438422000032, 31.784765952000043],
            [74.55413524800008, 31.784390016000032],
            [74.55372600000004, 31.784010000000023],
            [74.553476644000057, 31.783775981000076],
            [74.553154737000057, 31.783523676000073],
            [74.552579937000075, 31.783023044000061],
            [74.552171000000044, 31.782692000000054],
            [74.551985314000035, 31.78251653500007],
            [74.551834172000042, 31.78238815800006],
            [74.55145178500004, 31.780035008000027],
            [74.551400000000058, 31.779725000000042],
            [74.551369320000049, 31.779527534000067],
            [74.551228562000063, 31.778661330000034],
            [74.551357133000067, 31.778489902000047],
            [74.55150100000003, 31.778286000000037],
            [74.551523781000071, 31.778254707000031],
            [74.551799233000054, 31.77785773200003],
            [74.551854484000046, 31.777800435000074],
            [74.551873000000057, 31.777775000000076],
            [74.552189000000055, 31.777432000000033],
            [74.552293170000041, 31.777324104000058],
            [74.552486367000029, 31.777100720000078],
            [74.552674080000031, 31.77692957000005],
            [74.552860000000067, 31.776737000000026],
            [74.553285433000042, 31.776203124000062],
            [74.553592769000034, 31.775807977000056],
            [74.554105208000067, 31.775214013000038],
            [74.554150012000036, 31.775070639000035],
            [74.554165000000069, 31.774976000000038],
            [74.55432305800008, 31.774684787000069],
            [74.554470952000031, 31.774367089000066],
            [74.554441000000054, 31.774154000000067],
            [74.554527000000064, 31.773979000000054],
            [74.555146008000065, 31.773036190000028],
            [74.555197780000071, 31.77295556200005],
            [74.555190091000043, 31.772816396000053],
            [74.555180000000064, 31.772637000000032],
            [74.556067657000028, 31.771613246000072],
            [74.55642371700003, 31.77120158200006],
            [74.556700000000035, 31.77088395100003],
            [74.556941000000052, 31.770606000000043],
            [74.556841031000033, 31.770096491000061],
            [74.556789826000056, 31.769836594000026],
            [74.556726995000076, 31.769515290000072],
            [74.55648502300005, 31.768282039000042],
            [74.556478281000068, 31.768248068000048],
            [74.556360950000055, 31.767657033000035],
            [74.556355147000033, 31.767631318000042],
            [74.556258264000064, 31.767208706000076],
            [74.556172897000067, 31.766832916000055],
            [74.556292131000077, 31.766777835000028],
            [74.55633000000006, 31.766760000000033],
            [74.556537000000048, 31.766598000000045],
            [74.55688200000003, 31.766437000000053],
            [74.557019696000054, 31.766365729000029],
            [74.557360356000061, 31.766188363000026],
            [74.557548581000049, 31.766080332000058],
            [74.557692000000031, 31.765997000000027],
            [74.557815237000057, 31.76590383100006],
            [74.55797719800006, 31.765778337000029],
            [74.558367308000072, 31.765565469000023],
            [74.558649666000065, 31.765440975000047],
            [74.55868300000003, 31.765426000000048],
            [74.55868631900006, 31.765424814000028],
            [74.558711513000048, 31.765413706000061],
            [74.558930588000067, 31.765337547000058],
            [74.559296000000074, 31.765207000000032],
            [74.559405220000031, 31.765172546000031],
            [74.559440602000052, 31.76516024600005],
            [74.560074252000049, 31.764959981000061],
            [74.560262057000045, 31.764857103000054],
            [74.560282370000039, 31.764846947000024],
            [74.561045000000036, 31.764423000000079],
            [74.562307214000043, 31.763749180000048],
            [74.562481865000052, 31.763643966000075],
            [74.562595482000063, 31.763189499000077],
            [74.562441328000034, 31.762638953000078],
            [74.562313754000058, 31.762330089000045],
            [74.562223233000054, 31.762115101000063],
            [74.561880000000031, 31.761417000000051],
            [74.561540000000036, 31.760650000000055],
            [74.561446526000054, 31.760457798000061],
            [74.560913956000036, 31.759417429000052],
            [74.559979674000033, 31.757417889000067],
            [74.559175612000047, 31.756943822000039],
            [74.558473725000056, 31.756545454000047],
            [74.556725735000043, 31.755527968000024],
            [74.556546000000083, 31.755426000000057],
            [74.555378000000076, 31.754806000000031],
            [74.554805769000041, 31.754474272000039],
            [74.553346761000057, 31.753721971000061],
            [74.552914709000049, 31.753515077000031],
            [74.550879088000045, 31.752586548000068],
            [74.547304513000029, 31.751449797000078],
            [74.547317584000041, 31.751404236000042],
            [74.547262000000046, 31.751387000000079],
            [74.547826000000043, 31.749595000000056],
            [74.547601793000069, 31.74954387300005],
            [74.547237863000078, 31.74947411900007],
            [74.546545805000051, 31.749399466000057],
            [74.545394302000034, 31.749283229000071],
            [74.54421300000007, 31.749172000000044],
            [74.543357736000075, 31.749077650000061],
            [74.538941750000049, 31.748631884000076],
            [74.537936488000071, 31.748533244000043],
            [74.53531600000008, 31.748425000000054],
            [74.534758386000078, 31.748411528000076],
            [74.532862861000069, 31.748372048000078],
            [74.53213641800005, 31.748168013000054],
            [74.530147000000056, 31.747634000000062],
            [74.529826000000071, 31.74753400000003],
            [74.529075848000048, 31.747194208000053],
            [74.528691716000083, 31.747023515000024],
            [74.528472478000083, 31.746920904000035],
            [74.526435000000049, 31.745998000000043],
            [74.519319679000034, 31.74265347100004],
            [74.518304902000068, 31.742464160000054],
            [74.51814754600008, 31.742436907000069],
            [74.518128381000054, 31.74243358800004],
            [74.518128343000058, 31.742433581000057],
            [74.517697220000059, 31.742358914000079],
            [74.511408883000058, 31.741304225000079],
            [74.510622107000074, 31.741665717000046],
            [74.509771538000052, 31.742069737000065],
            [74.509640275000038, 31.74210834400003],
            [74.509410000000059, 31.742216000000042],
            [74.509298627000078, 31.742207892000067],
            [74.509261196000068, 31.742218587000025],
            [74.509039524000059, 31.742189030000077],
            [74.50888800000007, 31.742178000000024],
            [74.508723296000085, 31.742121324000038],
            [74.508602005000057, 31.742091001000063],
            [74.508469718000072, 31.742034065000041],
            [74.508455000000083, 31.742029000000059],
            [74.50772100000006, 31.741750000000025],
            [74.506897000000038, 31.741510000000062],
            [74.506106000000045, 31.741251000000034],
            [74.504735000000039, 31.740758000000028],
            [74.504722067000046, 31.740752610000072],
            [74.503957974000059, 31.740482930000042],
            [74.503944294000064, 31.740359814000044],
            [74.503913000000068, 31.740210000000047],
            [74.504188000000056, 31.73964600000005],
            [74.50416000000007, 31.739142000000072],
            [74.503959812000062, 31.737573203000068],
            [74.503951503000053, 31.737525424000069],
            [74.503803536000078, 31.736760716000049],
            [74.50377381100003, 31.736641816000031],
            [74.503589648000059, 31.735773619000042],
            [74.503452174000074, 31.735361196000042],
            [74.503299758000082, 31.73498336800003],
            [74.503168704000075, 31.734721259000025],
            [74.503110296000045, 31.734429219000049],
            [74.503043000000048, 31.73413800000003],
            [74.502785700000061, 31.732916871000043],
            [74.502733763000037, 31.732703975000049],
            [74.502748562000079, 31.732245212000066],
            [74.502748636000035, 31.732243636000078],
            [74.502798000000041, 31.730535000000032],
            [74.502813195000044, 31.730159867000054],
            [74.502866836000067, 31.728566898000054],
            [74.502308938000056, 31.728502525000067],
            [74.501977000000068, 31.728469000000075],
            [74.501749997000047, 31.728434347000075],
            [74.500831537000067, 31.728304682000044],
            [74.500318016000051, 31.728215748000025],
            [74.499717000000032, 31.728124000000037],
            [74.499710552000067, 31.728099660000055],
            [74.49963907700004, 31.727861411000049],
            [74.496411317000081, 31.72655657200005],
            [74.495732691000057, 31.726276695000024],
            [74.493600000000072, 31.725416000000052],
            [74.492728000000056, 31.724328000000071],
            [74.491442000000063, 31.723711000000037],
            [74.490812056000038, 31.723875140000075],
            [74.490592609000032, 31.723934407000058],
            [74.490591487000074, 31.723932613000045],
            [74.490590000000054, 31.723933000000045],
            [74.488324000000034, 31.720323000000064],
            [74.487764697000046, 31.71846412900004],
            [74.487759414000038, 31.71846550500004],
            [74.487430160000031, 31.71737446000003],
            [74.486938107000071, 31.715716919000045],
            [74.486757000000068, 31.715115000000026],
            [74.486759091000067, 31.715113880000047],
            [74.486758523000049, 31.715111965000062],
            [74.487438061000034, 31.71474849100008],
            [74.487984405000077, 31.714457684000024],
            [74.489463331000081, 31.713665670000069],
            [74.489464475000034, 31.713665058000061],
            [74.489829644000054, 31.713469498000052],
            [74.489899546000061, 31.713431145000072],
            [74.489523500000075, 31.713412000000062],
            [74.484843883000053, 31.710249976000057],
            [74.471629500000063, 31.701321000000064],
            [74.469127277000041, 31.700606079000067],
            [74.467868330000044, 31.700246380000067],
            [74.46147250000007, 31.698419000000058],
            [74.460879165000051, 31.697364272000073],
            [74.460208305000037, 31.696171736000053],
            [74.459154194000064, 31.694297923000079],
            [74.457119611000053, 31.69068119700006],
            [74.457119500000033, 31.690681000000041],
            [74.446963889000074, 31.689713037000047],
            [74.446963778000054, 31.689713074000053],
            [74.438258000000076, 31.692615000000046],
            [74.438257987000043, 31.692614838000054],
            [74.438257500000077, 31.692615000000046],
            [74.438257487000044, 31.692614838000054],
            [74.437774500000046, 31.686811500000033],
            [74.441808413000047, 31.682179976000043],
            [74.445386877000033, 31.678071374000069],
            [74.447065423000083, 31.676144157000067],
            [74.448203250000063, 31.674837765000063],
            [74.450832500000047, 31.671819000000028],
            [74.437774500000046, 31.666982000000075],
            [74.437680774000057, 31.666888279000034],
            [74.419374058000074, 31.648582486000066],
            [74.417944500000033, 31.64715300000006],
            [74.416202512000041, 31.646044447000065],
            [74.415982666000048, 31.645904543000029],
            [74.407304783000029, 31.640382180000074],
            [74.40730456600005, 31.640382360000046],
            [74.40239026200004, 31.644454145000054],
            [74.39814152200006, 31.647974472000044],
            [74.395823562000032, 31.649895036000032],
            [74.395823242000063, 31.649895301000072],
            [74.395823036000081, 31.649895472000026],
            [74.39068646100003, 31.654151422000041],
            [74.390378000000055, 31.654407000000049],
            [74.383992305000049, 31.660556495000037],
            [74.377320000000054, 31.666982000000075],
            [74.372776790000046, 31.668496403000063],
            [74.368614000000036, 31.669884000000025],
            [74.368613879000065, 31.66988387400005],
            [74.368613500000038, 31.669884000000025],
            [74.368613379000067, 31.66988387400005],
            [74.363005863000069, 31.664009411000052],
            [74.359491977000062, 31.660328247000052],
            [74.358457000000044, 31.659244000000058],
            [74.344313846000034, 31.648636736000071],
            [74.341045500000064, 31.646185500000058],
            [74.327421002000051, 31.650639506000061],
            [74.324226708000083, 31.651683759000036],
            [74.315897000000064, 31.654407000000049],
            [74.312935995000032, 31.658683894000035],
            [74.31040134400007, 31.662344959000052],
            [74.310211752000043, 31.662618807000058],
            [74.307191000000046, 31.666982000000075],
            [74.305990224000084, 31.667239322000057],
            [74.296431041000062, 31.669287819000033],
            [74.293649000000073, 31.669884000000025],
            [74.288240529000063, 31.669690698000068],
            [74.280107000000044, 31.669400000000053],
            [74.279588507000028, 31.667029832000026],
            [74.276721500000065, 31.653924000000075],
            [74.273306106000064, 31.645196033000047],
            [74.272857913000053, 31.644050686000071],
            [74.272368500000084, 31.642800000000079],
            [74.269445670000039, 31.635282671000027],
            [74.268983500000047, 31.634094000000061],
            [74.268983500000047, 31.630967147000035],
            [74.268983500000047, 31.626840000000072],
            [74.26275722500003, 31.620247206000045],
            [74.260761500000058, 31.618134000000055],
            [74.258826500000055, 31.612814000000071],
            [74.254154500000084, 31.612445131000072],
            [74.249552521000055, 31.612081791000037],
            [74.248465167000063, 31.611995941000032],
            [74.24044850000007, 31.61136300000004],
            [74.242138514000032, 31.608194387000026],
            [74.244317500000079, 31.604109000000051],
            [74.249154000000033, 31.592501000000027],
            [74.24908137400007, 31.592373911000038],
            [74.246420894000039, 31.587718123000059],
            [74.243350500000076, 31.582345000000032],
            [74.247703500000057, 31.568319000000031],
            [74.240934398000036, 31.56527279900007],
            [74.238030565000031, 31.563966029000028],
            [74.238030500000036, 31.56396600000005],
            [74.232267776000072, 31.558779764000064],
            [74.22975371900003, 31.556517404000033],
            [74.22401680400003, 31.55135419000004],
            [74.224016780000056, 31.551354199000059],
            [74.223521000000062, 31.550908000000049],
            [74.220863239000039, 31.547841378000044],
            [74.216771290000054, 31.543119936000039],
            [74.216754051000066, 31.543100044000028],
            [74.216754172000037, 31.543100028000026],
            [74.21655252100004, 31.542867350000051],
            [74.205905801000029, 31.530582527000035],
            [74.205818724000039, 31.530482053000071],
            [74.205581752000057, 31.530208620000053],
            [74.20451199300004, 31.52897426800007],
            [74.199555108000084, 31.523254718000032],
            [74.198371500000064, 31.521889000000044],
            [74.196470174000069, 31.520355689000041],
            [74.188144043000079, 31.513641139000072],
            [74.185163464000084, 31.511237472000062],
            [74.18337850000006, 31.509798000000046],
            [74.183957298000053, 31.503432717000067],
            [74.184053521000067, 31.502374516000032],
            [74.184202754000069, 31.500733340000068],
            [74.184346000000062, 31.499158000000079],
            [74.183718433000081, 31.492673431000071],
            [74.183718424000062, 31.492673337000042],
            [74.18310277900008, 31.48631195400003],
            [74.18289500000003, 31.484165000000075],
            [74.175640500000043, 31.476427000000058],
            [74.176302091000082, 31.475030408000066],
            [74.178832285000055, 31.469689275000064],
            [74.17999350000008, 31.467238000000066],
            [74.170804000000032, 31.461434000000054],
            [74.169527566000056, 31.460653961000048],
            [74.16209850000007, 31.456114000000071],
            [74.150007500000072, 31.453212000000065],
            [74.145153850000042, 31.446625057000062],
            [74.143236500000057, 31.444023000000072],
            [74.140334500000051, 31.435801000000026],
            [74.140334500000051, 31.426612000000034],
            [74.140334000000053, 31.426612000000034],
            [74.133080000000064, 31.426612000000034],
            [74.128243816000065, 31.419357474000037],
            [74.128243632000078, 31.419357948000027],
            [74.124858000000074, 31.428063000000066],
            [74.125104839000073, 31.435215721000077],
            [74.125342000000046, 31.442088000000069],
            [74.125245060000054, 31.442294006000054],
            [74.121473000000037, 31.450310000000059],
            [74.108415000000036, 31.458048000000076],
            [74.108414863000064, 31.458047785000076],
            [74.108414500000038, 31.458048000000076],
            [74.108414363000065, 31.458047785000076],
            [74.106347451000033, 31.454818344000046],
            [74.100676000000078, 31.445957000000078],
            [74.094873129000064, 31.438321925000025],
            [74.09148650000003, 31.433866000000023],
            [74.088619312000048, 31.430216803000064],
            [74.086166500000047, 31.427095000000065],
            [74.080248499000049, 31.421670038000059],
            [74.075012042000083, 31.416869840000061],
            [74.074559500000078, 31.416455000000042],
            [74.073623349000059, 31.408499446000064],
            [74.073592000000076, 31.408233000000052],
            [74.076494000000082, 31.400495000000035],
            [74.08229700000004, 31.401462000000038],
            [74.082297053000048, 31.40146184200006],
            [74.082297526000048, 31.401461921000077],
            [74.084232500000041, 31.395658500000025],
            [74.080647553000063, 31.390082190000044],
            [74.080647239000029, 31.390081702000032],
            [74.07552650000008, 31.382116500000052],
            [74.075434237000081, 31.38169206200007],
            [74.073108500000046, 31.370993000000055],
            [74.06852075900008, 31.370665062000057],
            [74.068046236000043, 31.370631142000036],
            [74.059566500000074, 31.370025000000055],
            [74.055331726000077, 31.367806815000051],
            [74.04941000000008, 31.364705000000072],
            [74.036352000000079, 31.364705000000072],
            [74.031524904000037, 31.366056794000031],
            [74.030725127000039, 31.366280766000045],
            [74.024261000000081, 31.368091000000049],
            [74.024260500000082, 31.368091000000049],
            [74.024260000000083, 31.368091000000049],
            [74.023686000000055, 31.368091000000049],
            [74.025686449000034, 31.371841158000052],
            [74.02743317900007, 31.375115679000032],
            [74.029901000000052, 31.379742000000078],
            [74.034153047000075, 31.384703405000039],
            [74.034929229000056, 31.385609074000058],
            [74.036129000000074, 31.387009000000035],
            [74.036228178000044, 31.387702959000023],
            [74.036525059000041, 31.389780267000049],
            [74.036877881000066, 31.39224899900006],
            [74.037636676000034, 31.397558377000053],
            [74.038206000000059, 31.401542000000063],
            [74.03197700000004, 31.404657000000043],
            [74.030059781000034, 31.405998924000073],
            [74.025656217000062, 31.409081122000032],
            [74.021596000000045, 31.411923000000058],
            [74.023585598000068, 31.413515062000045],
            [74.02456556900006, 31.414299228000061],
            [74.026786000000072, 31.416076000000032],
            [74.035091000000079, 31.423342000000048],
            [74.035091000000079, 31.425903146000053],
            [74.035091000000079, 31.43021712500007],
            [74.035091000000079, 31.431647000000055],
            [74.035057259000041, 31.431731361000061],
            [74.034277450000047, 31.43368107200007],
            [74.033125626000071, 31.436560908000047],
            [74.030939000000046, 31.44202800000005],
            [74.030380081000033, 31.442746588000034],
            [74.028111095000042, 31.445663766000052],
            [74.026187404000041, 31.44813700800006],
            [74.023672000000033, 31.451371000000051],
            [74.009139000000062, 31.448257000000069],
            [74.005852692000076, 31.449571396000067],
            [74.003556555000046, 31.450489763000064],
            [73.999865443000033, 31.451966065000079],
            [73.998757223000041, 31.452403110000034],
            [73.998611612000047, 31.452921304000029],
            [73.997050542000068, 31.458384485000067],
            [73.994990148000056, 31.465595121000035],
            [73.994748582000057, 31.466440516000034],
            [73.994670467000049, 31.46671389100004],
            [73.994605000000035, 31.466943000000072],
            [73.987476727000058, 31.468279390000077],
            [73.980495925000071, 31.469588133000059],
            [73.979845325000042, 31.469710106000036],
            [73.977995000000078, 31.470057000000054],
            [73.967440740000029, 31.470057000000054],
            [73.965538000000038, 31.470057000000054],
            [73.960092208000049, 31.465973148000046],
            [73.957233000000031, 31.463829000000032],
            [73.953912973000058, 31.467149027000062],
            [73.951960720000045, 31.469101280000075],
            [73.951477333000071, 31.469584667000049],
            [73.947958910000068, 31.473103090000052],
            [73.947890000000029, 31.473172000000034],
            [73.946974674000046, 31.481410819000075],
            [73.946852000000035, 31.482515000000035],
            [73.947608129000059, 31.485538789000032],
            [73.947843890000058, 31.48648160700003],
            [73.949693443000058, 31.493878037000059],
            [73.949967000000072, 31.494972000000075],
            [73.951961167000036, 31.497251322000068],
            [73.955558691000078, 31.501363273000038],
            [73.956994813000051, 31.503004754000074],
            [73.957233000000031, 31.503277000000026],
            [73.957235461000039, 31.503278367000064],
            [73.959249077000038, 31.504396923000058],
            [73.964227764000043, 31.507162564000055],
            [73.966576000000032, 31.508467000000053],
            [73.968556782000064, 31.511438013000031],
            [73.969094695000081, 31.512244840000051],
            [73.972805000000051, 31.517810000000054],
            [73.972899839000036, 31.517833708000069],
            [73.973145914000042, 31.51789522200005],
            [73.979486550000047, 31.51948025300004],
            [73.982734806000053, 31.520292252000047],
            [73.98410050800004, 31.520633650000036],
            [73.985262000000034, 31.520924000000036],
            [73.986678188000042, 31.522576181000034],
            [73.98840322500007, 31.524588679000033],
            [73.991491000000053, 31.528191000000049],
            [73.991855859000054, 31.528920777000053],
            [73.996108538000044, 31.537426818000029],
            [73.996426104000079, 31.538062001000071],
            [73.997757223000065, 31.540703110000038],
            [73.997426638000036, 31.542142992000038],
            [73.994715978000045, 31.553949420000038],
            [73.994661965000034, 31.553981372000067],
            [73.993810265000036, 31.557696092000072],
            [73.993216000000075, 31.560288000000071],
            [73.993257994000032, 31.560299754000027],
            [73.993257973000084, 31.560299844000042],
            [73.992952380000077, 31.560214286000075],
            [73.991584923000062, 31.559831436000024],
            [73.98935722300007, 31.559203110000055],
            [73.986582908000059, 31.558376718000034],
            [73.979986000000054, 31.556398000000058],
            [73.971602000000075, 31.554534000000046],
            [73.964600593000057, 31.551866866000069],
            [73.961857223000038, 31.550803110000061],
            [73.96182400500004, 31.550809145000073],
            [73.961821162000035, 31.550808062000044],
            [73.960285869000074, 31.551087206000034],
            [73.951575840000032, 31.552670847000059],
            [73.951575000000048, 31.552671000000032],
            [73.949246500000072, 31.561055000000067],
            [73.948314500000038, 31.568507000000068],
            [73.942725500000051, 31.572699000000057],
            [73.942269082000053, 31.573003278000044],
            [73.938513737000051, 31.575506842000038],
            [73.937153124000076, 31.576413918000071],
            [73.937136500000065, 31.576425000000029],
            [73.936045143000058, 31.579262340000071],
            [73.932478500000059, 31.588535000000036],
            [73.92875250000003, 31.595987000000036],
            [73.928752025000051, 31.595986950000054],
            [73.928752000000031, 31.595987000000036],
            [73.919903000000033, 31.595056000000056],
            [73.915713291000031, 31.592800269000065],
            [73.913856084000031, 31.591800352000064],
            [73.913655877000053, 31.59131564200004],
            [73.912744847000056, 31.589037821000034],
            [73.911985000000072, 31.587138000000039],
            [73.908724000000063, 31.579686000000038],
            [73.908337161000077, 31.579105603000073],
            [73.908157223000046, 31.578703110000049],
            [73.90595722300003, 31.575503110000056],
            [73.90371418400008, 31.573799536000024],
            [73.898012188000052, 31.569439144000057],
            [73.895217500000058, 31.570728947000077],
            [73.891957000000048, 31.572234000000037],
            [73.884970500000065, 31.573398139000062],
            [73.883573500000068, 31.573631000000034],
            [73.883573313000056, 31.573630948000073],
            [73.883573000000069, 31.573631000000034],
            [73.876699424000037, 31.571721582000066],
            [73.875189000000034, 31.57130200000006],
            [73.875177287000042, 31.571301467000069],
            [73.864943000000039, 31.570836000000043],
            [73.855627000000084, 31.570370000000025],
            [73.852435232000062, 31.569173301000035],
            [73.848175000000083, 31.567576000000031],
            [73.832805440000072, 31.566644027000052],
            [73.824421500000028, 31.570370000000025],
            [73.820331730000078, 31.571857388000069],
            [73.818564337000055, 31.572500163000029],
            [73.814174002000073, 31.574097004000066],
            [73.813176350000049, 31.574287024000057],
            [73.807757223000067, 31.57530311000005],
            [73.806712400000038, 31.575518221000038],
            [73.806694957000047, 31.575521543000036],
            [73.80439350000006, 31.575960000000066],
            [73.800759571000071, 31.57896192700008],
            [73.793681500000048, 31.584809000000064],
            [73.790566106000028, 31.585210966000034],
            [73.789019605000078, 31.585410504000038],
            [73.78170502200004, 31.586354272000051],
            [73.779242500000066, 31.586672000000078],
            [73.779242187000079, 31.586671976000048],
            [73.779242000000067, 31.586672000000078],
            [73.77327667000003, 31.586212901000067],
            [73.773187000000064, 31.586206000000061],
            [73.773185925000064, 31.586205463000056],
            [73.767836000000045, 31.58353100000005],
            [73.767259000000081, 31.583242000000041],
            [73.765735000000063, 31.582480000000032],
            [73.755488000000071, 31.57875400000006],
            [73.748968000000048, 31.575960000000066],
            [73.746008177000078, 31.572316803000035],
            [73.742957223000076, 31.568503110000051],
            [73.740794176000065, 31.567580210000074],
            [73.735460000000046, 31.565247000000056],
            [73.729706997000051, 31.56173123800005],
            [73.728167444000064, 31.560790390000079],
            [73.727077000000065, 31.560115353000072],
            [73.727077000000065, 31.552586089000044],
            [73.727077000000065, 31.552206000000069],
            [73.733132000000069, 31.546616000000029],
            [73.738721000000055, 31.544288000000051],
            [73.745241000000078, 31.542890000000057],
            [73.747798068000066, 31.542707660000076],
            [73.751762000000042, 31.542425000000037],
            [73.755485831000044, 31.539717155000062],
            [73.756885900000043, 31.53869893600006],
            [73.750365000000045, 31.534507000000076],
            [73.750365000000045, 31.52984900000007],
            [73.741059550000045, 31.529383478000057],
            [73.741051000000084, 31.529383000000053],
            [73.741050917000052, 31.529383046000078],
            [73.741050458000075, 31.529383023000037],
            [73.730803500000036, 31.534972000000039],
            [73.73080307400005, 31.534971959000075],
            [73.730803000000037, 31.534972000000039],
            [73.72105894200007, 31.534044516000051],
            [73.721023000000059, 31.534041000000059],
            [73.720457223000039, 31.533803110000065],
            [73.711257223000075, 31.530303110000034],
            [73.707385069000054, 31.528638446000059],
            [73.700528000000077, 31.525657000000024],
            [73.69426511100005, 31.520288398000048],
            [73.694009000000051, 31.520068000000038],
            [73.686556000000053, 31.51913600000006],
            [73.686541866000084, 31.519135126000037],
            [73.679569000000072, 31.51867100000004],
            [73.671654251000064, 31.513083295000058],
            [73.670757223000066, 31.50710311000006],
            [73.670724348000078, 31.507061739000051],
            [73.670719000000076, 31.507027000000051],
            [73.669135402000052, 31.505026431000033],
            [73.661870000000079, 31.495848000000024],
            [73.661758334000069, 31.495625666000024],
            [73.661758246000034, 31.495625490000066],
            [73.657912500000066, 31.499470000000031],
            [73.652777495000066, 31.504238257000054],
            [73.638707540000041, 31.517303318000074],
            [73.638534500000048, 31.517464000000075],
            [73.628845500000068, 31.536842000000036],
            [73.628454726000029, 31.541140158000076],
            [73.628455138000049, 31.541140350000035],
            [73.627918427000054, 31.547042625000074],
            [73.627918107000085, 31.547042487000056],
            [73.627181583000038, 31.555143586000042],
            [73.626077001000056, 31.567292999000074],
            [73.63740561700007, 31.581453856000053],
            [73.642686500000082, 31.588055000000054],
            [73.654521288000069, 31.599889788000041],
            [73.656528500000036, 31.601897000000065],
            [73.667886071000055, 31.609468603000039],
            [73.673944512000048, 31.613507688000027],
            [73.68144250000006, 31.618506500000024],
            [73.700820084000043, 31.619890970000029],
            [73.700820168000064, 31.619890941000051],
            [73.707633269000041, 31.617486334000034],
            [73.71247182500008, 31.615778620000071],
            [73.718907547000072, 31.613507205000076],
            [73.721600434000038, 31.612556781000023],
            [73.72435100000007, 31.611586000000045],
            [73.754803000000038, 31.607433000000071],
            [73.75546532900006, 31.607280163000041],
            [73.772796000000085, 31.603281000000038],
            [73.778258500000049, 31.608744000000058],
            [73.78049500000003, 31.614614000000074],
            [73.784359681000069, 31.63470974300003],
            [73.784388000000035, 31.634857000000068],
            [73.782913328000063, 31.636055086000056],
            [73.775927354000032, 31.641731063000066],
            [73.775926953000067, 31.641731388000039],
            [73.774947111000074, 31.64252717800008],
            [73.771930500000053, 31.644978000000037],
            [73.764397869000049, 31.647598247000076],
            [73.759966945000031, 31.64913955600008],
            [73.754024000000072, 31.651207000000056],
            [73.754211443000031, 31.653462557000068],
            [73.754802000000041, 31.660550000000057],
            [73.756359000000032, 31.667557000000045],
            [73.758592187000033, 31.678724368000076],
            [73.758694500000047, 31.67923600000006],
            [73.758284017000051, 31.687040980000063],
            [73.757916500000078, 31.694029000000057],
            [73.757916500000078, 31.70492900000005],
            [73.759855809000044, 31.714141654000059],
            [73.761030500000061, 31.719722000000047],
            [73.76183569300008, 31.72088503100008],
            [73.768037500000048, 31.729843000000074],
            [73.770373500000062, 31.739965000000041],
            [73.761797988000069, 31.749860115000047],
            [73.761797969000042, 31.749860225000077],
            [73.761788482000043, 31.749871172000041],
            [73.761788499000033, 31.749871064000047],
            [73.760252000000037, 31.751644000000056],
            [73.754655336000042, 31.747338959000047],
            [73.75013107500007, 31.743858827000054],
            [73.750130538000064, 31.743858414000044],
            [73.750909000000036, 31.752422000000024],
            [73.751687498000081, 31.774222431000055],
            [73.75168750000006, 31.774222500000064],
            [73.751686998000082, 31.774222931000054],
            [73.751687000000061, 31.774223000000063],
            [73.750914520000038, 31.774885065000035],
            [73.746744861000082, 31.778459112000064],
            [73.746237557000029, 31.778893951000043],
            [73.739231000000075, 31.778115000000071],
            [73.739230706000058, 31.778115078000042],
            [73.739230353000039, 31.778115039000056],
            [73.73464083600004, 31.779339146000041],
            [73.730429214000083, 31.78046246200006],
            [73.727551500000061, 31.78123000000005],
            [73.718632337000031, 31.78598647900003],
            [73.718630262000033, 31.785987585000044],
            [73.715873000000045, 31.787458000000072],
            [73.704194500000028, 31.795244000000025],
            [73.702637000000038, 31.801473000000044],
            [73.707308500000067, 31.815487000000076],
            [73.711373003000062, 31.824835462000067],
            [73.715094500000077, 31.833395000000053],
            [73.716612225000063, 31.83773123800006],
            [73.717907209000032, 31.841431091000061],
            [73.720544500000074, 31.848966000000075],
            [73.723658500000056, 31.862202000000025],
            [73.733001500000057, 31.871545000000026],
            [73.738451500000053, 31.883224000000041],
            [73.736915088000046, 31.885528783000041],
            [73.73378050000008, 31.890231000000028],
            [73.72599450000007, 31.894902000000059],
            [73.715094500000077, 31.90424500000006],
            [73.714003747000049, 31.904556501000059],
            [73.712939789000075, 31.904860489000043],
            [73.71294006100004, 31.904860697000061],
            [73.713462724000067, 31.90526129400007],
            [73.713450708000039, 31.90526778800006],
            [73.718674660000033, 31.909274449000065],
            [73.720099429000072, 31.916398293000043],
            [73.720183213000041, 31.916494065000052],
            [73.720207000000073, 31.916613000000041],
            [73.725302451000061, 31.922436944000026],
            [73.725575535000075, 31.922402808000072],
            [73.731231465000064, 31.921695817000057],
            [73.734102913000072, 31.921336886000063],
            [73.736950000000036, 31.92098100000004],
            [73.736950075000038, 31.920981053000048],
            [73.736950500000034, 31.92098100000004],
            [73.740240044000075, 31.923303031000046],
            [73.74313576600008, 31.925347070000043],
            [73.743138500000043, 31.92534900000004],
            [73.746414500000071, 31.932629000000077],
            [73.749680280000064, 31.939160063000031],
            [73.749690500000042, 31.939180500000077],
            [73.756742372000076, 31.945104398000069],
            [73.758790000000033, 31.946824500000048],
            [73.76570600000008, 31.956288000000029],
            [73.778051360000063, 31.964861166000048],
            [73.778810000000078, 31.965388000000075],
            [73.778810911000051, 31.965388423000036],
            [73.791237188000082, 31.971168087000024],
            [73.794462000000067, 31.972668000000056],
            [73.809022868000056, 31.980836235000027],
            [73.809023000000082, 31.98083630900004],
            [73.809095600000035, 31.980877103000068],
            [73.809095583000044, 31.980850882000027],
            [73.809763624000084, 31.980116079000027],
            [73.822245879000036, 31.97114631900007],
            [73.823653715000034, 31.970134647000066],
            [73.826562827000032, 31.96625583000008],
            [73.826571443000034, 31.966244343000028],
            [73.829113715000062, 31.962854647000029],
            [73.835665715000061, 31.957030647000067],
            [73.840177874000062, 31.956097097000054],
            [73.840173931000038, 31.956068014000039],
            [73.84021500700004, 31.956059516000039],
            [73.840215043000057, 31.956059509000056],
            [73.846148000000085, 31.954832000000067],
            [73.847558089000074, 31.954401140000073],
            [73.85220146100005, 31.952982332000033],
            [73.85225663600005, 31.953002643000048],
            [73.854510892000064, 31.95231384300007],
            [73.854476513000066, 31.952287177000073],
            [73.859252000000083, 31.950828000000058],
            [73.869443000000047, 31.945909172000029],
            [73.869809000000032, 31.945732500000076],
            [73.872283451000044, 31.945237587000065],
            [73.876022053000042, 31.944489832000045],
            [73.87601903500007, 31.944519627000034],
            [73.880801215000076, 31.943563147000077],
            [73.880789072000084, 31.943174581000051],
            [73.880437215000029, 31.931915647000039],
            [73.872429715000067, 31.922087647000069],
            [73.866442584000083, 31.917697084000054],
            [73.866425741000057, 31.917684733000044],
            [73.861509715000068, 31.914079647000051],
            [73.85540701900004, 31.906035184000075],
            [73.85350171500005, 31.903523647000043],
            [73.855500253000059, 31.901400200000069],
            [73.859325715000068, 31.897335647000034],
            [73.865197849000083, 31.895263129000057],
            [73.865513715000077, 31.895151647000034],
            [73.871337715000038, 31.890055647000054],
            [73.876433715000076, 31.887507647000064],
            [73.878243663000035, 31.88343526400007],
            [73.879345715000056, 31.880955647000064],
            [73.880377042000077, 31.875111463000053],
            [73.880437715000085, 31.874767647000056],
            [73.880437715000085, 31.868579647000047],
            [73.880512296000063, 31.867049744000042],
            [73.880440410000062, 31.866998706000061],
            [73.881092000000081, 31.853642000000036],
            [73.881092499000033, 31.853641528000026],
            [73.881092500000079, 31.853641500000037],
            [73.88145650000007, 31.853296684000043],
            [73.88800800000007, 31.847090000000037],
            [73.895147371000064, 31.843009959000028],
            [73.898199000000034, 31.841266000000076],
            [73.898199954000063, 31.841266026000028],
            [73.898200000000031, 31.841266000000076],
            [73.911303000000032, 31.841629972000078],
            [73.911303095000051, 31.841629975000046],
            [73.919183693000036, 31.839539204000062],
            [73.929139000000077, 31.836898000000076],
            [73.93954026800003, 31.835952430000077],
            [73.945155000000057, 31.835442000000057],
            [73.945155127000078, 31.835442079000074],
            [73.945156000000054, 31.835442000000057],
            [73.945157232000042, 31.835442770000043],
            [73.945185693000042, 31.835460558000079],
            [73.945228715000042, 31.835456647000058],
            [73.956876715000078, 31.842736647000038],
            [73.960681545000057, 31.847810140000036],
            [73.963422373000071, 31.851464857000053],
            [73.963428215000079, 31.851472647000037],
            [73.977480443000047, 31.852913457000056],
            [73.97762371500005, 31.852928147000057],
            [73.977629592000085, 31.852913457000056],
            [73.979807715000049, 31.847468647000028],
            [73.981126732000064, 31.842339137000067],
            [73.982113721000076, 31.838500846000045],
            [73.982178851000072, 31.838247563000039],
            [73.982113685000058, 31.838199668000073],
            [73.982113721000076, 31.838199528000075],
            [73.983010000000036, 31.834714000000076],
            [73.985882397000069, 31.83209137700004],
            [73.991382000000044, 31.827070000000049],
            [73.991382835000081, 31.827070151000044],
            [73.991383000000042, 31.827070000000049],
            [74.001411576000066, 31.828882393000072],
            [74.021595000000048, 31.832530000000077],
            [74.021598854000047, 31.832532202000039],
            [74.021667715000035, 31.832544647000077],
            [74.027136490000032, 31.835669784000061],
            [74.034371868000051, 31.839804448000052],
            [74.034381347000078, 31.839809865000063],
            [74.034407215000044, 31.839824647000057],
            [74.034510684000054, 31.839809865000063],
            [74.041589900000076, 31.838798499000063],
            [74.044598715000063, 31.838368647000038],
            [74.051150715000063, 31.834000647000039],
            [74.057380910000063, 31.829764114000056],
            [74.060250715000052, 31.82781264700003],
            [74.060858218000078, 31.827508895000051],
            [74.060827848000031, 31.827472576000048],
            [74.071097000000066, 31.822338000000059],
            [74.084565000000055, 31.821610000000078],
            [74.084565435000059, 31.821610031000034],
            [74.084566000000052, 31.821610000000078],
            [74.085664674000043, 31.821687174000033],
            [74.088427816000035, 31.821881088000055],
            [74.090257037000072, 31.82200946100005],
            [74.090257312000062, 31.822009950000052],
            [74.101849630000061, 31.82282301500004],
            [74.105312321000042, 31.823065882000037],
            [74.10667249800008, 31.822567151000044],
            [74.112910597000052, 31.820279848000041],
            [74.11291063300007, 31.820279835000065],
            [74.116232000000082, 31.819062000000031],
            [74.122386999000071, 31.818358781000029],
            [74.127977958000031, 31.817719901000032],
            [74.127978911000071, 31.817719792000048],
            [74.128974000000085, 31.817606000000069],
            [74.133554132000029, 31.819083593000073],
            [74.135985434000077, 31.819867953000028],
            [74.140257000000076, 31.821246000000031],
            [74.140708796000069, 31.821717439000054],
            [74.141535842000053, 31.822580444000039],
            [74.148629000000085, 31.82998200000003],
            [74.159913000000074, 31.836534000000029],
            [74.163829488000033, 31.845590879000042],
            [74.165737000000036, 31.850002000000075],
            [74.174109000000044, 31.851094000000046],
            [74.174180754000076, 31.851096563000056],
            [74.175708246000056, 31.85115111600004],
            [74.176273865000042, 31.851171317000023],
            [74.184301000000062, 31.851458000000036],
            [74.192302860000041, 31.854658744000062],
            [74.193401000000051, 31.855098000000055],
            [74.197046035000085, 31.864375361000043],
            [74.19740500000006, 31.865289000000075],
            [74.197496948000037, 31.86581770500004],
            [74.198216484000056, 31.869955040000036],
            [74.198861000000079, 31.873661000000027],
            [74.198995297000067, 31.873879232000036],
            [74.199401500000079, 31.874539312000024],
            [74.20468500000004, 31.883125000000064],
            [74.21924400000006, 31.888585000000035],
            [74.223156329000062, 31.88676531200008],
            [74.224543332000053, 31.886120194000057],
            [74.234896000000049, 31.881305000000054],
            [74.246351496000045, 31.881548734000035],
            [74.252004000000056, 31.881669000000045],
            [74.265836000000036, 31.888221000000044],
            [74.265886458000068, 31.888192617000072],
            [74.269538970000042, 31.886138080000023],
            [74.274704055000029, 31.883232719000034],
            [74.277484000000072, 31.881669000000045],
            [74.284867791000067, 31.881669000000045],
            [74.292043000000035, 31.881669000000045],
            [74.292258525000079, 31.881662469000048],
            [74.292497411000056, 31.881655230000035],
            [74.302897313000074, 31.881340081000076],
            [74.304055000000062, 31.881305000000054],
            [74.309173566000084, 31.889434487000074],
            [74.310243000000071, 31.891133000000025],
            [74.319203607000077, 31.894333217000053],
            [74.320435000000032, 31.894773000000043],
            [74.326987000000031, 31.888585000000035],
            [74.328052846000048, 31.888470802000029],
            [74.340904266000052, 31.887093864000065],
            [74.347371000000066, 31.886401000000035],
            [74.350791917000038, 31.88672682300006],
            [74.351975507000077, 31.886839553000073],
            [74.352020974000084, 31.88684388300004],
            [74.362658000000067, 31.887857000000054],
            [74.363756570000078, 31.888992189000078],
            [74.364320498000041, 31.889574914000036],
            [74.372008789000063, 31.897519482000064],
            [74.373578000000066, 31.899141000000043],
            [74.374977052000077, 31.900497656000027],
            [74.375745596000058, 31.901242911000054],
            [74.378450474000033, 31.903865823000046],
            [74.383019365000052, 31.908296263000068],
            [74.383030879000046, 31.908307428000057],
            [74.385590000000036, 31.910789000000079],
            [74.393953900000042, 31.91357696700004],
            [74.397495862000085, 31.914757621000035],
            [74.399786000000063, 31.915521000000069],
            [74.411101681000048, 31.927904198000078],
            [74.41907800000007, 31.936633000000029],
            [74.419996593000064, 31.937367863000077],
            [74.42585301500003, 31.942052929000056],
            [74.429748037000081, 31.945168899000066],
            [74.431986713000072, 31.946959813000035],
            [74.434458711000048, 31.948937381000064],
            [74.435457000000042, 31.94973600000003],
            [74.442921245000036, 31.958078391000072],
            [74.443359397000052, 31.958568091000075],
            [74.444291294000038, 31.959609624000052],
            [74.44783300000006, 31.963568000000066],
            [74.460798226000065, 31.965163720000078],
            [74.464348501000075, 31.965600677000054],
            [74.471493000000066, 31.966480000000047],
            [74.471762408000075, 31.967078684000057],
            [74.475007041000083, 31.97428897900005],
            [74.476469800000075, 31.977539553000042],
            [74.476506670000049, 31.977621487000079],
            [74.477332282000077, 31.979456179000067],
            [74.478045000000066, 31.981040000000064],
            [74.47644527500006, 31.994477689000064],
            [74.476225000000056, 31.996328000000062],
            [74.477131942000028, 31.996894878000035],
            [74.490784000000076, 32.005428000000052],
            [74.493172151000067, 32.005428000000052],
            [74.497969686000033, 32.005428000000052],
            [74.50477988800003, 32.005428000000052],
            [74.513352000000054, 32.005428000000052],
            [74.518557085000054, 32.007778684000073],
            [74.524636000000044, 32.010524000000032],
            [74.524962837000032, 32.013383825000062],
            [74.526035164000064, 32.022766685000079],
            [74.526092000000062, 32.02326400000004],
            [74.528619075000051, 32.024877591000063],
            [74.536114511000051, 32.029663585000037],
            [74.541967140000054, 32.033400612000037],
            [74.546414715000083, 32.036240483000029],
            [74.548719508000033, 32.037712141000043],
            [74.556304000000068, 32.04255500000005],
            [74.557734691000064, 32.043515109000055],
            [74.568986143000075, 32.051065738000034],
            [74.573358799000061, 32.054000142000064],
            [74.575079498000036, 32.055154868000045],
            [74.575281457000074, 32.055570406000072],
            [74.576837846000046, 32.058772730000044],
            [74.576388069000075, 32.060501378000026],
            [74.578508000000056, 32.062211000000048],
            [74.579648252000084, 32.061950469000067],
            [74.579648288000044, 32.061950461000038],
            [74.583794964000049, 32.061003008000057],
            [74.583795000000066, 32.061003000000028],
            [74.591248000000064, 32.059299000000067],
            [74.594255747000034, 32.049607371000036],
            [74.594255758000031, 32.049607335000076],
            [74.594524000000035, 32.048743000000059],
            [74.595389376000071, 32.043010183000035],
            [74.596003260000032, 32.038943413000027],
            [74.596595684000079, 32.03501880400006],
            [74.596593725000048, 32.035018534000073],
            [74.596980632000054, 32.032455405000064],
            [74.596989678000057, 32.032395479000058],
            [74.597434000000078, 32.029452000000049],
            [74.602268930000037, 32.025342310000042],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "122",
      properties: { name: "Sialkot", count: 925 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [74.70579910400005, 32.835573043000068],
            [74.705779628000073, 32.835545380000042],
            [74.705706432000056, 32.835563018000073],
            [74.705018523000035, 32.83548322300004],
            [74.704990836000036, 32.835467576000042],
            [74.704975847000071, 32.83543362000006],
            [74.704912729000057, 32.835008615000049],
            [74.704926111000077, 32.834846337000045],
            [74.705109893000042, 32.834188509000057],
            [74.705145783000034, 32.834107981000045],
            [74.705500216000075, 32.83425502700004],
            [74.705836110000064, 32.834358043000066],
            [74.706183482000029, 32.834459791000029],
            [74.706129320000059, 32.834666912000046],
            [74.706118918000072, 32.834805275000065],
            [74.706475178000062, 32.834749787000078],
            [74.706804169000065, 32.834593998000059],
            [74.706914835000077, 32.834369763000041],
            [74.706961126000067, 32.834130719000029],
            [74.707015200000058, 32.833751949000032],
            [74.706895203000045, 32.833390373000043],
            [74.706839464000041, 32.833236279000062],
            [74.706792940000071, 32.833053877000054],
            [74.706765854000082, 32.832824151000068],
            [74.706732658000078, 32.832386122000059],
            [74.706729454000083, 32.832025553000051],
            [74.706748739000034, 32.831713454000067],
            [74.70678437600003, 32.831202083000051],
            [74.706826093000075, 32.830845903000068],
            [74.706846880000057, 32.83054523800007],
            [74.706887018000032, 32.830029130000071],
            [74.706900052000037, 32.829295504000072],
            [74.706901234000043, 32.828742224000052],
            [74.706887408000057, 32.828145225000071],
            [74.706879662000063, 32.827699297000038],
            [74.706869389000076, 32.827200050000044],
            [74.706677701000046, 32.827170242000079],
            [74.706808006000074, 32.826978527000051],
            [74.706863210000051, 32.826759443000071],
            [74.707062318000055, 32.825941765000039],
            [74.707107655000073, 32.82575124300007],
            [74.707089797000037, 32.825625050000042],
            [74.70693525400003, 32.824922825000044],
            [74.706819669000083, 32.824406624000062],
            [74.706714045000069, 32.823993372000075],
            [74.70663804600008, 32.823650989000043],
            [74.706566463000058, 32.822763850000058],
            [74.706531423000058, 32.822253959000079],
            [74.706339253000067, 32.821784717000071],
            [74.706118592000053, 32.821327573000076],
            [74.706115434000083, 32.821327574000065],
            [74.706257376000053, 32.820949873000075],
            [74.70631708500008, 32.820762541000079],
            [74.706360269000072, 32.820530275000067],
            [74.706450962000076, 32.82003850600006],
            [74.70636651500007, 32.819552869000063],
            [74.706245843000033, 32.818845969000051],
            [74.706320714000071, 32.818529506000061],
            [74.706452259000059, 32.818040973000052],
            [74.706543253000063, 32.817697469000052],
            [74.706390461000069, 32.817227937000041],
            [74.706189366000046, 32.816690343000062],
            [74.706087350000075, 32.816503309000041],
            [74.705753513000047, 32.815912979000075],
            [74.705561175000071, 32.815572865000036],
            [74.705348446000073, 32.815167855000027],
            [74.705228753000029, 32.814939977000051],
            [74.704844387000037, 32.814710726000044],
            [74.70403290400003, 32.814246119000074],
            [74.703119604000051, 32.813731864000033],
            [74.70263238800004, 32.813265515000069],
            [74.702053720000038, 32.812747060000049],
            [74.701664742000048, 32.812571386000059],
            [74.701325379000082, 32.812289813000064],
            [74.700520757000049, 32.811621388000049],
            [74.699900422000042, 32.811081501000046],
            [74.699699674000044, 32.810757337000041],
            [74.699466276000066, 32.810362716000043],
            [74.699191478000046, 32.809899070000029],
            [74.698937843000067, 32.809590768000078],
            [74.698585884000067, 32.809263246000057],
            [74.698293471000056, 32.808897628000068],
            [74.698014421000039, 32.808543775000032],
            [74.697892231000083, 32.808293526000057],
            [74.697841689000029, 32.808011131000058],
            [74.697792000000049, 32.80785768100003],
            [74.697769083000082, 32.807786911000051],
            [74.697655822000058, 32.807450129000074],
            [74.697492848000081, 32.806989528000031],
            [74.697350154000048, 32.806620145000068],
            [74.697305818000075, 32.806478091000031],
            [74.697101866000082, 32.805963936000069],
            [74.696972664000043, 32.805604435000078],
            [74.696896017000029, 32.805185274000053],
            [74.696769921000055, 32.80459425600003],
            [74.696719816000041, 32.804356518000077],
            [74.696691864000059, 32.80422389000006],
            [74.696499621000044, 32.803800639000031],
            [74.696414752000067, 32.80355987400003],
            [74.696402416000069, 32.803089671000066],
            [74.696120402000076, 32.802550064000059],
            [74.695957675000045, 32.802263695000079],
            [74.695416478000084, 32.801749700000073],
            [74.695268335000037, 32.801609003000067],
            [74.695082952000064, 32.801263995000056],
            [74.694610901000033, 32.80034583500003],
            [74.694413373000032, 32.799909938000042],
            [74.693781599000033, 32.799347750000038],
            [74.693578759000047, 32.798974126000076],
            [74.692804351000063, 32.798473623000064],
            [74.692660463000038, 32.798302844000034],
            [74.692238892000034, 32.797703329000058],
            [74.691991555000072, 32.797174824000024],
            [74.691684517000056, 32.796670273000075],
            [74.691224312000031, 32.79614412400008],
            [74.690895739000041, 32.795757890000061],
            [74.690296802000034, 32.795021060000067],
            [74.689735721000034, 32.794366840000066],
            [74.689019061000067, 32.793508276000068],
            [74.688786636000032, 32.79337268900008],
            [74.68866930300004, 32.79310586400004],
            [74.688488758000062, 32.792769193000026],
            [74.688013326000032, 32.791964840000048],
            [74.687767463000057, 32.791515296000057],
            [74.687251392000064, 32.790602363000062],
            [74.687004841000032, 32.790153890000056],
            [74.686894595000069, 32.789962965000029],
            [74.686696539000081, 32.789615432000062],
            [74.686436245000039, 32.78916250900005],
            [74.686214798000037, 32.788768653000034],
            [74.68588507700008, 32.788160068000025],
            [74.685437058000048, 32.787365537000028],
            [74.685247207000032, 32.787019536000059],
            [74.685033290000035, 32.786830470000041],
            [74.685042225000075, 32.786560446000067],
            [74.684797229000083, 32.785960776000024],
            [74.684571161000065, 32.785413508000033],
            [74.684247406000054, 32.784617913000034],
            [74.684030259000053, 32.784088312000051],
            [74.683807551000029, 32.78352198400006],
            [74.683686830000056, 32.783255893000046],
            [74.683491832000072, 32.782758287000036],
            [74.68325696900007, 32.782169076000059],
            [74.683135049000043, 32.781853582000053],
            [74.683019296000055, 32.781581311000025],
            [74.682810116000041, 32.781046371000059],
            [74.682650205000073, 32.78065990500005],
            [74.682484017000036, 32.780437947000053],
            [74.682523758000059, 32.780155772000057],
            [74.682433072000038, 32.779704155000047],
            [74.682421287000068, 32.779648776000045],
            [74.682310563000044, 32.779128494000076],
            [74.682205169000042, 32.778617460000078],
            [74.682104094000067, 32.77810026700007],
            [74.682010734000073, 32.777725176000047],
            [74.681907606000038, 32.777238277000038],
            [74.681712725000068, 32.776220364000039],
            [74.681307181000079, 32.775591821000035],
            [74.680907354000055, 32.77495216300008],
            [74.680207206000034, 32.77390999000005],
            [74.679419303000032, 32.772589157000027],
            [74.678574855000079, 32.771279177000054],
            [74.678362102000051, 32.771063253000079],
            [74.678396157000066, 32.770840098000065],
            [74.678384025000071, 32.770491234000076],
            [74.678327662000072, 32.769819192000057],
            [74.678301498000053, 32.769508145000032],
            [74.678271298000084, 32.769094560000042],
            [74.678212917000053, 32.768421902000057],
            [74.678153544000054, 32.767804504000026],
            [74.67810724900005, 32.767253663000076],
            [74.678071011000043, 32.76678509900006],
            [74.678021375000071, 32.766199250000057],
            [74.677956890000075, 32.765510225000071],
            [74.677902526000082, 32.764971646000049],
            [74.67785850100006, 32.764476197000079],
            [74.677816990000053, 32.76398241000004],
            [74.677766931000065, 32.763370087000055],
            [74.677716906000057, 32.762851608000062],
            [74.677672638000047, 32.76234855000007],
            [74.677641829000038, 32.761944738000068],
            [74.677603351000073, 32.761546895000038],
            [74.677460228000029, 32.761251594000043],
            [74.676866225000083, 32.76013230500007],
            [74.676465619000055, 32.759361603000059],
            [74.676032945000031, 32.758511827000063],
            [74.675766658000043, 32.757989320000036],
            [74.675468011000078, 32.757437244000073],
            [74.675330822000035, 32.757164295000052],
            [74.675103657000079, 32.756731932000037],
            [74.674876277000067, 32.756293206000066],
            [74.674624150000056, 32.755792695000025],
            [74.674285135000048, 32.755141801000036],
            [74.674067370000046, 32.75471112300005],
            [74.673884402000056, 32.754358864000039],
            [74.673736748000067, 32.754078360000051],
            [74.673357578000036, 32.753647408000063],
            [74.671906599000067, 32.751984096000058],
            [74.671280529000057, 32.751252069000031],
            [74.670662531000062, 32.750537759000053],
            [74.669866326000033, 32.749603470000068],
            [74.669316081000034, 32.748964818000047],
            [74.668660480000028, 32.748196650000068],
            [74.668002495000053, 32.747422268000037],
            [74.667920870000046, 32.747007799000073],
            [74.667763772000058, 32.746270980000077],
            [74.667569109000056, 32.745394035000061],
            [74.667393631000039, 32.744495019000055],
            [74.667068292000067, 32.74365722400006],
            [74.666917698000077, 32.743219830000044],
            [74.666834249000033, 32.742654977000029],
            [74.666771132000065, 32.742207302000054],
            [74.666659619000029, 32.741418857000042],
            [74.666347646000077, 32.741027753000026],
            [74.665712945000053, 32.740247890000035],
            [74.665026440000076, 32.739410466000038],
            [74.664653195000085, 32.738968020000073],
            [74.664243652000039, 32.738464610000051],
            [74.663868853000054, 32.738019197000028],
            [74.663635143000079, 32.737692071000026],
            [74.663228157000049, 32.737114796000071],
            [74.662883052000041, 32.736512198000071],
            [74.662309208000067, 32.73561802200004],
            [74.661953388000029, 32.735092511000062],
            [74.661322372000029, 32.735077521000051],
            [74.661114018000035, 32.735051789000067],
            [74.661092109000037, 32.734967037000047],
            [74.661179695000044, 32.734628131000079],
            [74.661288328000069, 32.734358966000059],
            [74.66137373600003, 32.734183933000054],
            [74.66110724300006, 32.73322627500005],
            [74.661053973000037, 32.732914058000063],
            [74.660970701000053, 32.73239329900008],
            [74.660872615000073, 32.73183503100006],
            [74.660728744000039, 32.731195195000055],
            [74.660625775000028, 32.73084700000004],
            [74.661356709000074, 32.729937377000056],
            [74.662632588000065, 32.728336401000035],
            [74.663221619000069, 32.727542603000074],
            [74.663459873000079, 32.727235922000034],
            [74.664479898000081, 32.725922943000057],
            [74.66477687500003, 32.725511748000031],
            [74.665290600000048, 32.72481367000006],
            [74.664938809000034, 32.724163804000057],
            [74.664481146000071, 32.723328238000079],
            [74.664170733000049, 32.722778442000049],
            [74.663787920000061, 32.721998562000067],
            [74.663345184000036, 32.721183076000045],
            [74.663021941000068, 32.720570745000032],
            [74.66273241600004, 32.720033759000046],
            [74.662542060000078, 32.719518262000065],
            [74.662300161000076, 32.718802940000046],
            [74.662007291000066, 32.71793586900003],
            [74.661793893000038, 32.717309283000077],
            [74.661452912000072, 32.716250380000076],
            [74.661206597000046, 32.715528332000076],
            [74.660890187000064, 32.714600461000032],
            [74.660716672000035, 32.714020533000053],
            [74.660479077000048, 32.71326201100004],
            [74.660146870000062, 32.712238487000036],
            [74.660027349000075, 32.711812235000025],
            [74.659645261000037, 32.710447178000038],
            [74.65927380800008, 32.709118910000029],
            [74.659044451000057, 32.708294215000024],
            [74.658797738000032, 32.707387428000061],
            [74.658587866000062, 32.706618819000028],
            [74.658357257000034, 32.705819212000051],
            [74.658019155000034, 32.704603353000039],
            [74.657948986000065, 32.70432711400008],
            [74.657655659000056, 32.703825250000079],
            [74.657538091000049, 32.703559563000056],
            [74.657210457000076, 32.702779186000043],
            [74.65717761600007, 32.702687208000043],
            [74.656826432000059, 32.701845835000029],
            [74.656600964000063, 32.70133652800007],
            [74.656371130000082, 32.70078510500008],
            [74.65605548800005, 32.696677431000069],
            [74.656174473000078, 32.69630124400004],
            [74.656385364000073, 32.695916799000031],
            [74.656679484000051, 32.695396698000025],
            [74.65736826300008, 32.694261174000076],
            [74.657896529000084, 32.69339130700007],
            [74.658389786000043, 32.692588699000055],
            [74.658665639000048, 32.692138872000044],
            [74.658870238000077, 32.691805238000029],
            [74.659254482000051, 32.691171217000033],
            [74.659691213000031, 32.690452664000077],
            [74.660230914000067, 32.68951035200007],
            [74.660945232000074, 32.68896667000007],
            [74.661062076000064, 32.688632487000064],
            [74.661259873000063, 32.688080324000055],
            [74.661471965000032, 32.687497163000046],
            [74.661726766000072, 32.68689233300006],
            [74.661928237000041, 32.686471859000051],
            [74.662282098000048, 32.685734086000025],
            [74.662621455000078, 32.685109130000058],
            [74.66285239900003, 32.684600949000071],
            [74.662982966000072, 32.68432372500007],
            [74.66324211400007, 32.683857065000041],
            [74.663451904000055, 32.683593034000069],
            [74.663867337000056, 32.683078740000042],
            [74.664439001000062, 32.682355861000076],
            [74.664845799000034, 32.681822505000071],
            [74.664837566000074, 32.681413984000073],
            [74.664757651000059, 32.681198901000073],
            [74.664681791000078, 32.681154506000041],
            [74.664639661000081, 32.681079737000061],
            [74.664614949000054, 32.680868220000036],
            [74.664659970000059, 32.680778961000044],
            [74.665277056000036, 32.680136141000048],
            [74.665308034000077, 32.679786049000029],
            [74.665409186000034, 32.679480412000032],
            [74.665651997000055, 32.678970977000063],
            [74.66583728300003, 32.678615701000069],
            [74.666038474000061, 32.67830702200007],
            [74.666349188000083, 32.677865456000063],
            [74.666640259000076, 32.677599595000061],
            [74.667026217000057, 32.677453396000033],
            [74.667395341000031, 32.677604694000024],
            [74.66785778600007, 32.67791658200008],
            [74.66804684300007, 32.678077016000032],
            [74.668288675000042, 32.678362850000042],
            [74.668258862000073, 32.678425980000043],
            [74.668220699000074, 32.678824918000032],
            [74.668227408000064, 32.679234508000036],
            [74.668262365000032, 32.679629387000034],
            [74.66826906700004, 32.680006263000053],
            [74.668298983000057, 32.680034234000061],
            [74.66839705600006, 32.680246735000026],
            [74.668418692000046, 32.680473319000043],
            [74.668461954000065, 32.680852551000044],
            [74.66854023500008, 32.681124324000052],
            [74.668630007000047, 32.681366283000045],
            [74.668684901000063, 32.681701854000039],
            [74.668844405000073, 32.682261149000055],
            [74.668958144000044, 32.682537776000061],
            [74.669072852000056, 32.68278814100006],
            [74.66927895200007, 32.682965759000069],
            [74.669607204000044, 32.683173155000077],
            [74.669853102000047, 32.683361885000068],
            [74.670143969000037, 32.68357585900003],
            [74.67044628900004, 32.683794503000058],
            [74.67070556300007, 32.683986094000034],
            [74.671138767000059, 32.684122772000023],
            [74.671318277000069, 32.684354928000062],
            [74.671649076000051, 32.684462688000053],
            [74.671924835000084, 32.684535406000066],
            [74.67259187500008, 32.684539510000036],
            [74.673104244000058, 32.68452265600007],
            [74.673749546000067, 32.684500183000068],
            [74.674342479000074, 32.684486098000036],
            [74.675118591000057, 32.684460796000053],
            [74.675376033000077, 32.684443970000075],
            [74.676038056000039, 32.684317003000046],
            [74.67667139100007, 32.684173405000024],
            [74.67723352400003, 32.684011046000023],
            [74.677685891000067, 32.683872492000035],
            [74.678259266000055, 32.683687401000043],
            [74.67845528600003, 32.683618138000043],
            [74.678935497000055, 32.683402642000033],
            [74.679038510000055, 32.683360662000041],
            [74.679258799000081, 32.683257009000044],
            [74.679453187000036, 32.683114308000029],
            [74.679612381000084, 32.683175340000048],
            [74.680051031000062, 32.682972448000044],
            [74.680349836000062, 32.682801047000055],
            [74.680688761000056, 32.682538030000046],
            [74.68101071500007, 32.682272263000073],
            [74.681251591000034, 32.682003670000029],
            [74.681376562000082, 32.681836038000029],
            [74.681441317000065, 32.681682177000027],
            [74.681811156000038, 32.68142516000006],
            [74.681917352000085, 32.680900431000055],
            [74.681971953000073, 32.680559021000079],
            [74.682052071000044, 32.680086126000049],
            [74.682120810000072, 32.679754557000024],
            [74.682213597000043, 32.679243442000029],
            [74.682261503000063, 32.679022102000033],
            [74.682296200000053, 32.679024041000048],
            [74.682360861000063, 32.678694599000039],
            [74.682409700000051, 32.678426561000038],
            [74.682490499000039, 32.677939223000067],
            [74.682445391000044, 32.677921712000057],
            [74.682505414000047, 32.677554136000026],
            [74.682592175000082, 32.677117538000061],
            [74.682624178000083, 32.676903605000064],
            [74.682722941000065, 32.676380442000038],
            [74.682773753000049, 32.676153610000028],
            [74.682841874000076, 32.675799035000068],
            [74.682927242000062, 32.675263513000061],
            [74.682986967000033, 32.674947373000066],
            [74.683083992000036, 32.674554946000057],
            [74.683125202000042, 32.674505551000038],
            [74.683164479000084, 32.674355625000032],
            [74.683484961000033, 32.674252927000055],
            [74.683773168000073, 32.674105517000044],
            [74.684088469000073, 32.673951636000027],
            [74.684342571000059, 32.673822106000046],
            [74.684611978000078, 32.673670182000023],
            [74.68486948900005, 32.67347995800003],
            [74.685095304000072, 32.673329714000033],
            [74.685340014000076, 32.67318735200007],
            [74.685521701000084, 32.672946378000063],
            [74.685813187000065, 32.672625223000068],
            [74.686080392000065, 32.672266617000048],
            [74.686359271000072, 32.671980595000036],
            [74.686693046000073, 32.671681265000075],
            [74.686886995000066, 32.671522036000056],
            [74.687213927000073, 32.671326497000052],
            [74.687491299000044, 32.671191977000035],
            [74.687663029000078, 32.671143407000045],
            [74.687903554000059, 32.671108971000024],
            [74.68804526100007, 32.671073548000038],
            [74.688216983000075, 32.671010821000039],
            [74.688699911000072, 32.670857926000053],
            [74.689048876000072, 32.670734473000039],
            [74.689239697000062, 32.670646473000033],
            [74.689354948000073, 32.670549394000034],
            [74.689626726000029, 32.670307668000078],
            [74.689968559000079, 32.670031302000041],
            [74.690096647000075, 32.670139537000068],
            [74.690477272000066, 32.669794893000073],
            [74.690584968000053, 32.668975268000054],
            [74.691465166000057, 32.667918858000064],
            [74.691820515000074, 32.667663679000043],
            [74.692165294000063, 32.667529602000059],
            [74.69324135200003, 32.667166188000067],
            [74.69398209600007, 32.666938003000041],
            [74.694193468000037, 32.666923981000025],
            [74.694492237000077, 32.666820960000052],
            [74.69536720900004, 32.666528774000028],
            [74.696278805000077, 32.666195919000074],
            [74.696759456000052, 32.666000390000079],
            [74.696614967000073, 32.665370976000077],
            [74.696473795000031, 32.664767282000071],
            [74.696276585000078, 32.663982628000042],
            [74.696129361000033, 32.66335636000008],
            [74.69592846200004, 32.662524279000024],
            [74.695716342000082, 32.661704275000034],
            [74.695486835000054, 32.660790037000027],
            [74.695377800000074, 32.660345962000065],
            [74.694951820000028, 32.659651063000069],
            [74.694562266000048, 32.658963863000054],
            [74.694113532000074, 32.658198475000063],
            [74.693776202000038, 32.657608667000034],
            [74.693568551000055, 32.657230385000048],
            [74.693324191000045, 32.656778487000054],
            [74.692976779000048, 32.656222414000069],
            [74.692930342000068, 32.656149525000046],
            [74.692596705000085, 32.65562582900003],
            [74.692161586000054, 32.654863283000054],
            [74.691901322000035, 32.654427184000042],
            [74.69147801500003, 32.653735292000079],
            [74.69136730200006, 32.653572058000066],
            [74.690947132000076, 32.653065046000052],
            [74.690298269000039, 32.652304334000064],
            [74.689575357000081, 32.651693255000055],
            [74.688508494000075, 32.650827058000061],
            [74.687292996000053, 32.650959077000039],
            [74.685931124000035, 32.651116050000041],
            [74.685056542000041, 32.651194034000071],
            [74.68395243100008, 32.651290450000033],
            [74.68266798600007, 32.65139412700006],
            [74.681766113000037, 32.651473546000034],
            [74.681360530000063, 32.650715652000031],
            [74.681037340000046, 32.650098581000066],
            [74.680689051000058, 32.649446019000038],
            [74.680441505000033, 32.648961209000049],
            [74.679648985000028, 32.64900110800005],
            [74.678582233000043, 32.649054718000059],
            [74.677955441000051, 32.649069740000073],
            [74.677134074000037, 32.648469044000024],
            [74.676712101000078, 32.648158084000045],
            [74.67589921800004, 32.647533102000068],
            [74.674810526000044, 32.646737031000043],
            [74.674100457000065, 32.646199553000031],
            [74.673310321000031, 32.645622153000033],
            [74.672674900000061, 32.645153153000024],
            [74.672471708000046, 32.644845565000026],
            [74.672368495000057, 32.644534695000061],
            [74.672251840000058, 32.644169851000072],
            [74.672140585000079, 32.643824507000033],
            [74.672009157000048, 32.643349865000062],
            [74.671919501000048, 32.643022622000046],
            [74.671577589000037, 32.641869854000049],
            [74.671413130000076, 32.641296434000026],
            [74.671215632000042, 32.64058069400005],
            [74.671134903000052, 32.640299009000046],
            [74.670979079000062, 32.639638365000053],
            [74.670822892000047, 32.638979013000039],
            [74.67080211900003, 32.638865358000032],
            [74.670890334000035, 32.638705057000038],
            [74.67093933600006, 32.638375858000074],
            [74.670487650000041, 32.638134023000077],
            [74.670265612000037, 32.637895068000034],
            [74.669698840000081, 32.637674289000074],
            [74.668984519000048, 32.637516995000055],
            [74.667727181000032, 32.637255302000028],
            [74.667186503000039, 32.637132412000028],
            [74.666542779000054, 32.63700424600006],
            [74.666227909000042, 32.636679221000065],
            [74.665711457000043, 32.636144176000073],
            [74.664831370000059, 32.635200592000047],
            [74.66469920000003, 32.635060555000052],
            [74.66460268000003, 32.635014297000055],
            [74.664426823000042, 32.63481536200004],
            [74.664055370000028, 32.634416507000026],
            [74.662986707000073, 32.634770595000077],
            [74.661816026000054, 32.635121627000046],
            [74.661301743000081, 32.635301291000076],
            [74.660177199000032, 32.635608596000054],
            [74.659010189000071, 32.635921171000064],
            [74.658668061000071, 32.636012808000032],
            [74.657601788000079, 32.636279476000027],
            [74.656301086000042, 32.636630758000024],
            [74.655600183000047, 32.635950147000074],
            [74.655872162000037, 32.635450946000049],
            [74.656160556000032, 32.634922551000045],
            [74.656409104000033, 32.634480723000024],
            [74.656462465000061, 32.63345302700003],
            [74.656475836000084, 32.633190881000075],
            [74.656478806000052, 32.633089504000054],
            [74.656446107000079, 32.632711523000069],
            [74.655350662000046, 32.632293140000058],
            [74.654072910000082, 32.631809206000071],
            [74.653910152000037, 32.631537953000077],
            [74.654332184000054, 32.631436587000053],
            [74.655394581000053, 32.631219139000052],
            [74.656109136000055, 32.631086167000035],
            [74.65632045600006, 32.631046842000046],
            [74.657235030000038, 32.630867849000026],
            [74.656963991000055, 32.630170059000079],
            [74.656668872000068, 32.629419445000053],
            [74.656471176000082, 32.62892484300005],
            [74.65631406600005, 32.628519980000078],
            [74.656082297000069, 32.627935099000069],
            [74.655893057000071, 32.62744332300008],
            [74.65570166100008, 32.626967090000051],
            [74.655630817000031, 32.626568999000028],
            [74.655590894000056, 32.626340835000065],
            [74.655517932000066, 32.626030240000034],
            [74.655452178000075, 32.625678108000045],
            [74.655381899000076, 32.625323653000066],
            [74.655271575000029, 32.624817551000035],
            [74.655161503000045, 32.624266587000079],
            [74.655196305000061, 32.623905575000038],
            [74.655226171000038, 32.62352762900008],
            [74.655264517000035, 32.623109028000044],
            [74.655289723000067, 32.622791947000053],
            [74.655312493000054, 32.622522381000067],
            [74.655416692000074, 32.621319497000059],
            [74.65545618200008, 32.62066399400004],
            [74.655405376000033, 32.619897717000072],
            [74.655339300000037, 32.618821730000036],
            [74.655288358000064, 32.617992187000027],
            [74.655061919000048, 32.614835243000073],
            [74.654200356000047, 32.614590814000053],
            [74.653412428000081, 32.614325447000056],
            [74.652547713000047, 32.614059152000038],
            [74.652108035000083, 32.613921582000046],
            [74.651384076000056, 32.613716214000078],
            [74.650996703000033, 32.613603486000045],
            [74.65021426900006, 32.613649125000052],
            [74.64933992300007, 32.613707657000077],
            [74.648401805000049, 32.613764683000056],
            [74.648038381000049, 32.613786775000051],
            [74.646639981000078, 32.613875692000079],
            [74.645849896000072, 32.613929182000049],
            [74.645175427000083, 32.614080761000025],
            [74.64326415000005, 32.614524883000058],
            [74.641618895000079, 32.615136698000072],
            [74.640387179000072, 32.614721356000075],
            [74.638915524000083, 32.614236247000065],
            [74.639256034000084, 32.613358214000073],
            [74.639442638000048, 32.612905796000064],
            [74.639823971000055, 32.61194383000003],
            [74.640062216000047, 32.611352904000057],
            [74.639947465000034, 32.610718469000062],
            [74.63985143900004, 32.610221460000048],
            [74.639761106000037, 32.609979212000042],
            [74.639924790000066, 32.609761031000062],
            [74.640253303000065, 32.609149758000058],
            [74.640505884000049, 32.608678012000041],
            [74.640379037000059, 32.608025457000053],
            [74.64026775800005, 32.607496419000029],
            [74.640057315000035, 32.606489467000074],
            [74.639989376000074, 32.606152671000075],
            [74.639874091000081, 32.605612751000024],
            [74.639724838000063, 32.60488639700003],
            [74.639542554000059, 32.604045438000071],
            [74.63943392300007, 32.603790016000062],
            [74.639747477000071, 32.603719857000044],
            [74.640125708000085, 32.603581379000047],
            [74.640780280000058, 32.603335371000071],
            [74.642089175000081, 32.602854244000071],
            [74.642286439000031, 32.602220916000078],
            [74.64222568200006, 32.601420054000073],
            [74.642128688000071, 32.600291614000071],
            [74.643865751000078, 32.59965138900003],
            [74.644053000000042, 32.599582691000023],
            [74.645081541000081, 32.599205342000062],
            [74.645349039000052, 32.599108350000051],
            [74.646487310000055, 32.598815949000027],
            [74.647816120000073, 32.598497168000051],
            [74.64829203100004, 32.59840557900003],
            [74.64960635500006, 32.598178659000041],
            [74.650498346000063, 32.59877190800006],
            [74.651037462000033, 32.599145157000066],
            [74.65148345700004, 32.599485799000036],
            [74.65213553600006, 32.599966496000036],
            [74.65274815600003, 32.59967726800005],
            [74.653365308000048, 32.599383428000067],
            [74.654331167000066, 32.598772814000029],
            [74.654774882000083, 32.59867506300003],
            [74.65540896400006, 32.598536429000035],
            [74.655853732000082, 32.598439754000026],
            [74.656556416000058, 32.598187274000054],
            [74.657298486000059, 32.597947970000064],
            [74.657855097000038, 32.597752473000071],
            [74.659014366000065, 32.597355403000051],
            [74.659783988000072, 32.597071647000064],
            [74.659497608000038, 32.596467138000037],
            [74.659238790000074, 32.59595058900004],
            [74.658660852000082, 32.594756553000025],
            [74.658285625000076, 32.594085017000054],
            [74.657884332000037, 32.593361357000049],
            [74.657418174000043, 32.592557851000038],
            [74.657277122000039, 32.592311543000051],
            [74.656561254000053, 32.591350864000049],
            [74.656360047000078, 32.591093025000077],
            [74.655713526000056, 32.590592295000079],
            [74.655092470000056, 32.590099207000037],
            [74.654331758000069, 32.589528772000051],
            [74.653992531000029, 32.589271819000032],
            [74.653188519000082, 32.588999245000025],
            [74.652066560000037, 32.588649402000044],
            [74.651808852000045, 32.58816549200003],
            [74.651523162000046, 32.58758887700003],
            [74.651563984000063, 32.587103300000024],
            [74.65164741600006, 32.586309668000069],
            [74.651430381000068, 32.585779970000033],
            [74.651289112000029, 32.585456285000078],
            [74.651147348000052, 32.585108656000045],
            [74.651095020000071, 32.583873723000067],
            [74.651074884000082, 32.583482122000078],
            [74.652137193000044, 32.582922712000027],
            [74.653797521000058, 32.582061051000039],
            [74.654899661000059, 32.581481179000036],
            [74.655604210000035, 32.581096055000046],
            [74.655585293000058, 32.579996794000067],
            [74.655526820000034, 32.578784513000073],
            [74.655480461000082, 32.577742405000038],
            [74.655886187000078, 32.57717696900005],
            [74.656837615000029, 32.575889561000054],
            [74.657134719000055, 32.575490474000048],
            [74.657905969000069, 32.574630341000045],
            [74.658225193000078, 32.574270246000026],
            [74.658057777000067, 32.573441786000046],
            [74.657881527000029, 32.572614877000035],
            [74.65760358600005, 32.571400167000036],
            [74.656379880000031, 32.570253846000071],
            [74.655238865000058, 32.569669992000058],
            [74.654479221000031, 32.569255850000047],
            [74.652520113000037, 32.568265240000073],
            [74.652521088000071, 32.56770070400006],
            [74.65256817900007, 32.566277334000063],
            [74.652566815000057, 32.565612530000067],
            [74.654718768000066, 32.565039868000042],
            [74.656422902000031, 32.565418624000074],
            [74.657467227000041, 32.56566310900007],
            [74.657858738000073, 32.565741686000024],
            [74.659386858000062, 32.565645398000072],
            [74.660244139000042, 32.56541827500007],
            [74.660714684000084, 32.565279607000036],
            [74.662066988000049, 32.564780216000031],
            [74.662890511000057, 32.564998543000058],
            [74.664474427000073, 32.565403219000075],
            [74.664892206000047, 32.566392801000063],
            [74.665099085000065, 32.566856061000067],
            [74.66581182200008, 32.567259051000065],
            [74.66670472900006, 32.567727466000065],
            [74.667185251000035, 32.568632262000051],
            [74.668353152000066, 32.568623115000037],
            [74.66910813800007, 32.568847529000038],
            [74.669836683000028, 32.569087302000071],
            [74.670537004000039, 32.569022371000074],
            [74.671380022000051, 32.569059185000071],
            [74.672229250000044, 32.569103313000028],
            [74.672878444000048, 32.56913570200004],
            [74.673480048000044, 32.568259007000051],
            [74.673676178000051, 32.568042941000044],
            [74.674149202000081, 32.567292395000038],
            [74.67460798500008, 32.566566795000028],
            [74.674279037000076, 32.566399309000076],
            [74.674294952000082, 32.566175379000072],
            [74.674462154000082, 32.566005375000032],
            [74.674762531000056, 32.566159208000045],
            [74.675207187000069, 32.565634207000073],
            [74.67528201500005, 32.56534436000004],
            [74.675322252000058, 32.565110313000048],
            [74.675237496000079, 32.564449875000037],
            [74.67514501900007, 32.563511405000042],
            [74.675036872000078, 32.562756682000042],
            [74.674966316000052, 32.562098920000039],
            [74.674969704000034, 32.561326927000039],
            [74.674973116000046, 32.561016626000026],
            [74.674890843000071, 32.560251280000045],
            [74.674797658000045, 32.559507004000068],
            [74.674679390000051, 32.558338147000029],
            [74.674639252000077, 32.557982546000062],
            [74.674539804000062, 32.556894010000065],
            [74.674509369000077, 32.556486294000024],
            [74.674465414000053, 32.556024626000067],
            [74.674448507000079, 32.555807387000073],
            [74.674306376000061, 32.555288976000043],
            [74.673993541000073, 32.554231837000032],
            [74.673860270000034, 32.553709723000054],
            [74.672946297000067, 32.55256659500003],
            [74.67236725500004, 32.552131617000043],
            [74.671311088000039, 32.552337331000047],
            [74.670279338000057, 32.552527293000026],
            [74.66904368400003, 32.552508622000062],
            [74.667410406000045, 32.552744859000029],
            [74.665659492000032, 32.552970231000074],
            [74.664200704000052, 32.553161095000064],
            [74.662349926000047, 32.553393748000076],
            [74.661949638000067, 32.552997842000025],
            [74.661678514000073, 32.551702918000046],
            [74.661653453000042, 32.551583226000048],
            [74.661346243000082, 32.549761616000069],
            [74.661721717000034, 32.54848793900004],
            [74.662026437000065, 32.547477867000055],
            [74.662237937000043, 32.546783293000033],
            [74.662420076000046, 32.546210603000077],
            [74.662491235000061, 32.545991212000047],
            [74.662662690000047, 32.545861350000052],
            [74.66354302700006, 32.545181642000045],
            [74.664675355000043, 32.544320326000047],
            [74.665760238000075, 32.543521640000051],
            [74.666570955000054, 32.542918888000031],
            [74.667112669000062, 32.54263940900006],
            [74.667985445000056, 32.542196851000028],
            [74.668744573000083, 32.54181346200005],
            [74.669286862000035, 32.541532053000026],
            [74.670246354000085, 32.541063003000033],
            [74.671162161000041, 32.540590054000063],
            [74.671949210000037, 32.540338710000071],
            [74.672717919000036, 32.540099200000043],
            [74.673783680000042, 32.539766017000034],
            [74.674212444000034, 32.539632545000075],
            [74.674620171000072, 32.539198671000065],
            [74.675131118000081, 32.538840975000028],
            [74.675812857000039, 32.538408707000031],
            [74.676646016000063, 32.538111337000032],
            [74.677363507000052, 32.537846354000067],
            [74.678132314000038, 32.537595929000076],
            [74.678802692000033, 32.537383687000045],
            [74.679649686000062, 32.537118682000028],
            [74.679727070000069, 32.537094470000056],
            [74.680304402000047, 32.536910525000053],
            [74.680905088000031, 32.53682767600003],
            [74.681597154000031, 32.536714439000036],
            [74.682288688000028, 32.536570334000032],
            [74.682873183000083, 32.536427572000036],
            [74.684482437000042, 32.536086604000047],
            [74.685378227000058, 32.535901157000069],
            [74.686262438000028, 32.53604689000008],
            [74.687341306000064, 32.535687310000071],
            [74.688634991000072, 32.535237688000052],
            [74.689319838000074, 32.534977308000066],
            [74.690388123000048, 32.534572688000026],
            [74.691012042000068, 32.533987326000045],
            [74.691090953000071, 32.533144035000078],
            [74.691175361000035, 32.532112136000023],
            [74.691275958000062, 32.530973109000058],
            [74.691318273000036, 32.530538646000025],
            [74.691420149000066, 32.529511679000052],
            [74.691473648000056, 32.528838005000068],
            [74.691575532000058, 32.52786726100004],
            [74.69160402600005, 32.527587875000052],
            [74.69148905600008, 32.526915529000064],
            [74.69138188900007, 32.526329978000035],
            [74.691259241000068, 32.525602410000033],
            [74.691166001000056, 32.525055369000029],
            [74.691033436000055, 32.52434254800005],
            [74.690884758000038, 32.523418980000031],
            [74.690763231000062, 32.522793680000063],
            [74.690378708000082, 32.522305305000032],
            [74.689609218000044, 32.521354988000041],
            [74.689210160000073, 32.520842841000047],
            [74.688782752000066, 32.520310535000078],
            [74.688183658000071, 32.519574419000037],
            [74.687602112000036, 32.518856636000066],
            [74.686902799000052, 32.517987461000075],
            [74.68631352500006, 32.517252173000031],
            [74.685940242000072, 32.516807114000073],
            [74.685279136000077, 32.516004772000031],
            [74.684842832000072, 32.515463427000043],
            [74.684251626000048, 32.514738197000042],
            [74.683818110000061, 32.514164264000044],
            [74.683425233000037, 32.513566798000056],
            [74.683348685000055, 32.51335344000006],
            [74.683376923000083, 32.512996335000025],
            [74.683454985000083, 32.511831400000062],
            [74.683501297000078, 32.511260745000072],
            [74.683501494000041, 32.511258325000028],
            [74.683554634000075, 32.510566293000068],
            [74.683582846000036, 32.510012331000041],
            [74.684374965000075, 32.509443489000034],
            [74.685007103000032, 32.508994772000051],
            [74.686451420000083, 32.507934495000029],
            [74.686420561000034, 32.506575063000071],
            [74.686428235000051, 32.505845134000026],
            [74.68640276800005, 32.504674468000076],
            [74.686373143000083, 32.503951535000056],
            [74.686839272000043, 32.503595593000057],
            [74.687512057000049, 32.503144485000064],
            [74.688316650000047, 32.502564351000046],
            [74.688393502000054, 32.502299251000068],
            [74.68847525700005, 32.502184542000066],
            [74.688467505000062, 32.501957945000072],
            [74.68852419500007, 32.501897070000041],
            [74.687571795000054, 32.500344233000078],
            [74.686884211000063, 32.499271132000047],
            [74.686555335000037, 32.498792070000036],
            [74.686165089000042, 32.498186606000047],
            [74.68615148300006, 32.498045048000051],
            [74.686119111000039, 32.497708257000056],
            [74.686110258000042, 32.497309251000047],
            [74.686093225000036, 32.496281081000063],
            [74.686076273000083, 32.495835874000079],
            [74.685717189000059, 32.494925079000041],
            [74.685423437000054, 32.494088630000078],
            [74.685602740000036, 32.493703241000048],
            [74.685977000000037, 32.493214179000063],
            [74.686580807000041, 32.492766008000046],
            [74.68705919000007, 32.492371902000059],
            [74.687346478000052, 32.492104181000059],
            [74.687747030000082, 32.491802456000073],
            [74.688234928000043, 32.491787077000026],
            [74.688644381000074, 32.491694559000052],
            [74.689434906000031, 32.491362320000064],
            [74.69012555900008, 32.491060462000064],
            [74.690786442000046, 32.490667328000029],
            [74.691369358000031, 32.490300465000075],
            [74.691962027000045, 32.489988345000029],
            [74.693137168000078, 32.489598982000075],
            [74.693943552000064, 32.489371494000068],
            [74.694884559000059, 32.489591848000032],
            [74.69591868100008, 32.490175178000072],
            [74.696326920000047, 32.490387459000033],
            [74.697866305000048, 32.49079320900006],
            [74.698778369000081, 32.491479550000065],
            [74.699118845000044, 32.491713144000073],
            [74.699477463000051, 32.492097277000028],
            [74.700138825000067, 32.492769535000036],
            [74.700647948000039, 32.493259730000034],
            [74.701085934000048, 32.493145679000065],
            [74.701769744000046, 32.493234302000076],
            [74.702742655000066, 32.493376141000056],
            [74.703459363000036, 32.493474408000054],
            [74.704189500000041, 32.493582415000049],
            [74.704822268000044, 32.493691609000052],
            [74.705538788000069, 32.493827942000053],
            [74.706134979000069, 32.493935004000036],
            [74.706850067000062, 32.494048321000037],
            [74.70795755100005, 32.493957752000028],
            [74.708461270000043, 32.493771133000052],
            [74.708841690000042, 32.49371390400006],
            [74.709565626000028, 32.493497432000026],
            [74.70982650600007, 32.49342751000006],
            [74.710420013000032, 32.493281169000056],
            [74.710629074000053, 32.493113107000056],
            [74.711169389000077, 32.492425311000034],
            [74.711254655000062, 32.492159573000038],
            [74.711458586000049, 32.491907961000038],
            [74.711695527000074, 32.491718048000052],
            [74.712246890000074, 32.491446832000065],
            [74.711484153000072, 32.490380032000076],
            [74.711882050000042, 32.489963000000046],
            [74.71256943800006, 32.489369243000056],
            [74.713354645000038, 32.488753604000067],
            [74.713964955000051, 32.488266254000052],
            [74.71512849700008, 32.487274625000055],
            [74.714855059000058, 32.486368612000035],
            [74.714608827000063, 32.485599463000028],
            [74.714431482000066, 32.485044535000043],
            [74.714234247000036, 32.484452518000069],
            [74.714013809000051, 32.483859271000028],
            [74.713806992000059, 32.48334707600003],
            [74.71345627200003, 32.482413353000027],
            [74.713203339000074, 32.481942726000057],
            [74.713055160000067, 32.481469021000066],
            [74.713043375000041, 32.481428658000027],
            [74.71298752000007, 32.481237349000025],
            [74.712898523000035, 32.480852048000031],
            [74.712817045000065, 32.480464884000071],
            [74.712843182000029, 32.480386183000064],
            [74.713342054000066, 32.479970790000039],
            [74.713725159000035, 32.479639683000073],
            [74.714237870000034, 32.479212987000039],
            [74.71448029000004, 32.479007145000026],
            [74.714987911000037, 32.478691686000047],
            [74.714533706000054, 32.47782875200005],
            [74.716240415000073, 32.477443197000071],
            [74.716943351000054, 32.477305618000059],
            [74.717027040000062, 32.477330929000061],
            [74.717185942000071, 32.477638566000053],
            [74.717908261000048, 32.477560310000058],
            [74.718496216000062, 32.47748972200003],
            [74.719617299000049, 32.477324280000062],
            [74.720164379000039, 32.477243546000068],
            [74.721422127000039, 32.477032088000044],
            [74.721683917000064, 32.476988075000065],
            [74.722729516000072, 32.47681086800003],
            [74.723465207000061, 32.476687875000039],
            [74.723891543000036, 32.476616984000032],
            [74.724686446000078, 32.476504678000026],
            [74.726712709000083, 32.476183974000037],
            [74.727607210000031, 32.478465214000039],
            [74.72791744500006, 32.479230492000056],
            [74.728087583000047, 32.479650704000051],
            [74.728403461000084, 32.480228047000026],
            [74.728851019000047, 32.481063178000056],
            [74.728972919000057, 32.48127807700007],
            [74.729056238000055, 32.481346477000045],
            [74.729252555000073, 32.481507646000068],
            [74.729392095000037, 32.481574446000025],
            [74.730176834000076, 32.481525952000027],
            [74.73110190400007, 32.481462681000039],
            [74.731794015000048, 32.481191201000058],
            [74.732585529000062, 32.48111328300007],
            [74.733005200000036, 32.481069349000052],
            [74.733516593000047, 32.481015812000066],
            [74.734272855000029, 32.480949137000039],
            [74.735155906000045, 32.480854130000068],
            [74.736079759000063, 32.480764886000031],
            [74.73794897700003, 32.480586110000047],
            [74.738447493000081, 32.480623319000074],
            [74.739589996000063, 32.48018178600006],
            [74.74111614800006, 32.47989924500007],
            [74.742166029000032, 32.479715852000027],
            [74.743310828000062, 32.479507148000039],
            [74.744321805000084, 32.479537804000074],
            [74.745298585000057, 32.479566398000031],
            [74.745856746000072, 32.479545260000066],
            [74.746851038000045, 32.479565493000052],
            [74.747229946000061, 32.479592331000049],
            [74.747952994000059, 32.479673084000069],
            [74.748569457000031, 32.47975557500007],
            [74.74935479100003, 32.479617804000043],
            [74.750167457000032, 32.479484100000036],
            [74.750728454000068, 32.479314762000058],
            [74.751774623000074, 32.478978019000067],
            [74.752253903000053, 32.478823677000037],
            [74.752406918000077, 32.478871454000057],
            [74.752845249000075, 32.479677017000029],
            [74.752913654000054, 32.479802731000063],
            [74.753455905000067, 32.480797995000046],
            [74.75358392000004, 32.481037265000055],
            [74.753706454000053, 32.481281254000066],
            [74.753954583000052, 32.481345194000028],
            [74.754271111000037, 32.481953322000038],
            [74.754596777000074, 32.482667953000032],
            [74.754736675000061, 32.482963771000072],
            [74.754835871000068, 32.483247456000072],
            [74.755768063000062, 32.483079641000074],
            [74.756466002000082, 32.483003732000043],
            [74.757763909000062, 32.482827495000038],
            [74.758753569000078, 32.482705081000063],
            [74.759612042000072, 32.482599278000066],
            [74.760340089000067, 32.482494993000046],
            [74.760892348000084, 32.482402032000039],
            [74.761630371000081, 32.482266988000049],
            [74.762144665000051, 32.482178223000062],
            [74.762739210000063, 32.482077858000025],
            [74.76329833300008, 32.482073610000043],
            [74.763610908000032, 32.482169481000028],
            [74.764413058000059, 32.482338030000051],
            [74.765286519000028, 32.482524113000068],
            [74.765984880000076, 32.482648531000052],
            [74.766813084000034, 32.482786379000061],
            [74.767396272000042, 32.482853975000069],
            [74.768831349000038, 32.483388618000049],
            [74.769719843000075, 32.483195708000039],
            [74.770356959000082, 32.483059050000065],
            [74.771468562000052, 32.482811993000041],
            [74.772681500000033, 32.482545560000062],
            [74.774261774000081, 32.482193575000053],
            [74.775671371000044, 32.481882171000052],
            [74.77706167100007, 32.481560253000055],
            [74.777196690000039, 32.481470306000062],
            [74.777414759000067, 32.481495552000069],
            [74.778062836000061, 32.48135596000003],
            [74.778424697000048, 32.481279592000078],
            [74.778945395000051, 32.481147038000074],
            [74.779623068000035, 32.480965063000042],
            [74.780703192000033, 32.480679756000029],
            [74.781169023000075, 32.480558464000069],
            [74.781683576000034, 32.480424486000061],
            [74.782526952000069, 32.480195553000044],
            [74.783350602000041, 32.479981817000066],
            [74.783896429000038, 32.479834114000028],
            [74.784547037000038, 32.479663051000045],
            [74.785095169000044, 32.479514246000065],
            [74.786094627000068, 32.479244867000034],
            [74.787746335000065, 32.478807470000049],
            [74.78801818800008, 32.478653583000039],
            [74.788352352000061, 32.478666556000064],
            [74.788939919000029, 32.478574968000032],
            [74.78978748000003, 32.478453323000053],
            [74.790755263000051, 32.478295024000033],
            [74.791713695000055, 32.478137589000028],
            [74.79261456200004, 32.47799662400007],
            [74.79389105000007, 32.477789199000028],
            [74.794548172000077, 32.477693532000046],
            [74.794844513000044, 32.477643141000044],
            [74.795996107000065, 32.477452619000076],
            [74.796725582000079, 32.477886596000076],
            [74.797592157000054, 32.478421253000079],
            [74.798350288000051, 32.478873300000032],
            [74.799155977000055, 32.47936947900007],
            [74.799959210000054, 32.47987052700006],
            [74.800116179000042, 32.480471926000064],
            [74.800274917000081, 32.481170741000028],
            [74.80039346600006, 32.481650923000075],
            [74.800643788000059, 32.482673119000026],
            [74.800748859000066, 32.483112331000029],
            [74.800818182000057, 32.483402111000032],
            [74.801529021000078, 32.483351615000061],
            [74.802191979000042, 32.483304768000039],
            [74.802467253000032, 32.483285316000035],
            [74.80367278500006, 32.483174649000034],
            [74.804705764000062, 32.48308884100004],
            [74.80503221500004, 32.483063080000079],
            [74.805681807000042, 32.482861936000063],
            [74.806614006000075, 32.482566869000038],
            [74.807462643000065, 32.482310251000058],
            [74.808296658000074, 32.482034739000028],
            [74.809963802000084, 32.481507926000063],
            [74.810903501000041, 32.481197862000045],
            [74.811376170000074, 32.481059145000074],
            [74.812242160000039, 32.480789874000038],
            [74.812525528000037, 32.481828868000036],
            [74.812771927000028, 32.482702687000028],
            [74.81300862300003, 32.483587662000048],
            [74.813089468000044, 32.483864080000046],
            [74.813271175000068, 32.484555164000028],
            [74.813409659000058, 32.485045672000069],
            [74.813675971000066, 32.485599577000073],
            [74.814020379000056, 32.486290457000052],
            [74.814173012000083, 32.486558848000072],
            [74.814487573000065, 32.487175031000049],
            [74.814558214000044, 32.487253621000036],
            [74.814532370000052, 32.487493923000045],
            [74.814696484000081, 32.487625894000075],
            [74.815108554000062, 32.48842922700004],
            [74.815850165000029, 32.489881492000052],
            [74.816176812000037, 32.490521150000063],
            [74.816503242000067, 32.491660703000036],
            [74.816694079000058, 32.492476709000073],
            [74.816792991000057, 32.492934469000033],
            [74.817393164000066, 32.493872080000074],
            [74.817471976000036, 32.494055241000069],
            [74.817684453000084, 32.49473585000004],
            [74.818076535000046, 32.496089491000077],
            [74.818540265000081, 32.497458026000061],
            [74.818758124000055, 32.498117341000068],
            [74.81926874100003, 32.498921354000061],
            [74.81960302300007, 32.49944967700003],
            [74.819816118000062, 32.499812204000079],
            [74.820323181000049, 32.499825220000048],
            [74.820695159000081, 32.499531024000078],
            [74.821234123000067, 32.498311007000041],
            [74.821375570000043, 32.498220778000075],
            [74.822204484000054, 32.497871175000057],
            [74.823705178000068, 32.497264018000067],
            [74.824576821000051, 32.497015679000071],
            [74.825073533000079, 32.49686409800006],
            [74.826195923000057, 32.496403949000069],
            [74.827116617000058, 32.496087177000049],
            [74.827515883000046, 32.496025447000079],
            [74.828504062000036, 32.495850279000024],
            [74.828842721000058, 32.49579165800003],
            [74.829128141000069, 32.495745010000064],
            [74.829336691000037, 32.495724912000071],
            [74.829589254000041, 32.495762958000057],
            [74.829889601000048, 32.496127839000053],
            [74.830215524000039, 32.496575240000027],
            [74.830462791000059, 32.496923902000049],
            [74.830701908000037, 32.497198894000064],
            [74.830922078000071, 32.497321279000062],
            [74.831426488000034, 32.497361998000031],
            [74.831431078000037, 32.497233870000059],
            [74.832020698000065, 32.497174142000063],
            [74.832277157000078, 32.497316185000045],
            [74.834680024000079, 32.497237874000064],
            [74.835136938000062, 32.497249495000062],
            [74.835462770000049, 32.497430078000036],
            [74.836324957000045, 32.497027566000042],
            [74.839011268000036, 32.495790637000027],
            [74.84053805800005, 32.493996161000041],
            [74.841194367000071, 32.49340836500005],
            [74.843785379000053, 32.492949472000078],
            [74.843908914000053, 32.492791415000056],
            [74.845146724000074, 32.492762617000039],
            [74.846134443000039, 32.492761267000049],
            [74.84613925900004, 32.492739526000037],
            [74.846550757000045, 32.49088176500004],
            [74.847978906000037, 32.490845640000032],
            [74.848955090000061, 32.492674015000034],
            [74.849206760000072, 32.493145390000052],
            [74.85108998000004, 32.493112276000033],
            [74.854104660000075, 32.493059297000059],
            [74.85401737400008, 32.495379617000026],
            [74.854633112000045, 32.495454174000031],
            [74.855202665000036, 32.49544448100005],
            [74.855837960000031, 32.495416544000079],
            [74.856565983000053, 32.495035155000039],
            [74.85745014500003, 32.494765543000028],
            [74.857791081000073, 32.494676536000043],
            [74.858495461000075, 32.494500972000026],
            [74.858946547000073, 32.49430916700004],
            [74.85937782700006, 32.494138762000034],
            [74.859956748000059, 32.494014750000076],
            [74.861060932000044, 32.493826816000023],
            [74.862061901000061, 32.493819164000058],
            [74.863237001000073, 32.493278544000077],
            [74.86388351800008, 32.493009031000042],
            [74.865603748000069, 32.492310537000037],
            [74.86628711600008, 32.492010266000079],
            [74.867203049000068, 32.491617260000055],
            [74.868269246000068, 32.491147186000035],
            [74.868378499000073, 32.491087220000054],
            [74.868000792000032, 32.490648610000051],
            [74.867027268000072, 32.489576760000034],
            [74.866823274000069, 32.489332870000055],
            [74.866302896000036, 32.488700917000074],
            [74.866316682000047, 32.488518077000037],
            [74.867192297000031, 32.487996810000027],
            [74.867862778000074, 32.487613601000078],
            [74.869234668000047, 32.486838218000059],
            [74.870295638000073, 32.48783115100008],
            [74.871191732000057, 32.488700446000053],
            [74.871728208000036, 32.489211182000076],
            [74.876994225000033, 32.494316501000071],
            [74.878325913000083, 32.493708308000066],
            [74.880044610000084, 32.492976966000072],
            [74.881493306000038, 32.49243494600006],
            [74.882365489000051, 32.491959945000076],
            [74.883068252000044, 32.491547748000073],
            [74.883698387000038, 32.49126119400006],
            [74.88421715000004, 32.491013295000073],
            [74.885019481000029, 32.490556565000077],
            [74.884275074000072, 32.489507238000044],
            [74.883559585000057, 32.488569176000055],
            [74.882691986000054, 32.487398847000065],
            [74.88223757600008, 32.486788525000065],
            [74.881432406000044, 32.485674994000078],
            [74.880779565000068, 32.484815625000067],
            [74.880623469000056, 32.484608341000069],
            [74.88001730700006, 32.483441953000067],
            [74.879333058000043, 32.482078859000069],
            [74.879290280000077, 32.481994146000034],
            [74.878861141000073, 32.481144327000038],
            [74.87828514000006, 32.480012093000028],
            [74.878218031000074, 32.479847309000036],
            [74.878259411000045, 32.479412868000054],
            [74.878817299000048, 32.479156246000059],
            [74.879620306000049, 32.478788543000064],
            [74.880325611000046, 32.478460326000061],
            [74.881239788000073, 32.478061349000029],
            [74.882412399000032, 32.477520474000073],
            [74.883670126000084, 32.476976066000077],
            [74.88406180000004, 32.476780190000056],
            [74.883707963000063, 32.475609848000033],
            [74.883593783000038, 32.475227528000062],
            [74.884837517000051, 32.474699809000072],
            [74.885544887000037, 32.474387382000032],
            [74.886473138000042, 32.47398264900005],
            [74.88733462600004, 32.473607886000025],
            [74.888012265000043, 32.473312173000068],
            [74.888824743000043, 32.472960680000028],
            [74.889236162000032, 32.472782693000056],
            [74.891133104000062, 32.471974198000055],
            [74.892062876000068, 32.471574683000028],
            [74.893508288000078, 32.470819985000048],
            [74.89420995200004, 32.470459361000053],
            [74.89601463200006, 32.469792187000053],
            [74.897508469000059, 32.469257550000066],
            [74.898992007000061, 32.468860471000028],
            [74.900859553000032, 32.468318285000066],
            [74.905679853000038, 32.473487732000024],
            [74.909061697000084, 32.472030578000044],
            [74.910787299000049, 32.471020871000064],
            [74.912258317000067, 32.470117714000025],
            [74.914065138000069, 32.468535914000029],
            [74.914933972000028, 32.467703636000067],
            [74.915693899000075, 32.466975684000033],
            [74.916450847000078, 32.466270421000047],
            [74.917613287000052, 32.466001392000067],
            [74.919434642000056, 32.46570441800003],
            [74.919898677000049, 32.465821463000054],
            [74.922190999000065, 32.467221756000072],
            [74.923164922000069, 32.468208277000031],
            [74.925577392000037, 32.469770699000037],
            [74.926365024000063, 32.470583856000076],
            [74.926642710000067, 32.470870540000078],
            [74.927981600000066, 32.471685268000044],
            [74.929493095000055, 32.472595738000052],
            [74.930572208000058, 32.473136662000059],
            [74.932153380000045, 32.473093769000059],
            [74.934375660000057, 32.473015289000045],
            [74.935971334000044, 32.472988364000059],
            [74.937516420000065, 32.472389775000067],
            [74.938917649000075, 32.471813432000033],
            [74.939903057000038, 32.471416205000025],
            [74.93888073200003, 32.470142559000067],
            [74.937897563000035, 32.468886373000032],
            [74.937434877000044, 32.468293632000041],
            [74.937413526000057, 32.46814495700005],
            [74.937890193000044, 32.467685202000041],
            [74.938398756000083, 32.467237522000062],
            [74.939262015000054, 32.466448270000058],
            [74.940458512000077, 32.465391638000028],
            [74.941252771000052, 32.464656550000029],
            [74.941967842000054, 32.463988784000037],
            [74.941968609000071, 32.46398662200005],
            [74.942371252000044, 32.462851775000047],
            [74.942711773000042, 32.461853432000055],
            [74.943248248000032, 32.460294817000033],
            [74.94364951700004, 32.459151534000057],
            [74.943876097000043, 32.458505428000024],
            [74.943848597000056, 32.45751726900005],
            [74.943825429000071, 32.456352566000078],
            [74.94378088600007, 32.454891686000053],
            [74.943742402000055, 32.453953823000063],
            [74.94374965600008, 32.45278339500004],
            [74.945123917000046, 32.45099060900003],
            [74.945141284000044, 32.450968315000068],
            [74.944954144000064, 32.450500488000046],
            [74.942099184000028, 32.44325856100005],
            [74.942099170000063, 32.443258525000033],
            [74.941314297000076, 32.441267606000054],
            [74.937988980000057, 32.432832566000059],
            [74.937194551000061, 32.430817406000074],
            [74.936177733000079, 32.428238133000036],
            [74.935951000000046, 32.427663000000052],
            [74.935210752000046, 32.425442257000043],
            [74.934051952000061, 32.421965855000053],
            [74.933698469000035, 32.420905406000031],
            [74.931947000000036, 32.415651000000025],
            [74.92348839400006, 32.405938279000054],
            [74.923331333000078, 32.405757931000039],
            [74.923331449000045, 32.405757923000067],
            [74.922120000000064, 32.404367000000036],
            [74.91874856000004, 32.40166984800004],
            [74.913258971000062, 32.397278176000043],
            [74.913259106000055, 32.397277985000073],
            [74.904042872000048, 32.389904687000069],
            [74.898461000000054, 32.385439000000076],
            [74.895521454000061, 32.38507155700006],
            [74.882998167000039, 32.383506146000059],
            [74.882998092000037, 32.383506262000026],
            [74.878076000000078, 32.382891000000029],
            [74.876256000000069, 32.374520000000075],
            [74.876319497000054, 32.373726293000061],
            [74.876326697000081, 32.373636288000057],
            [74.876335847000064, 32.373521917000062],
            [74.876591394000059, 32.370327573000054],
            [74.876733690000037, 32.368548875000045],
            [74.876964185000077, 32.365667694000024],
            [74.87698400000005, 32.365420000000029],
            [74.869704087000059, 32.358504083000071],
            [74.86970400000007, 32.358504000000039],
            [74.868394066000064, 32.358623085000033],
            [74.865701004000073, 32.358867909000026],
            [74.86570096500003, 32.35886791300004],
            [74.865700000000061, 32.358868000000029],
            [74.86570096500003, 32.35884773500004],
            [74.865928416000031, 32.354071281000074],
            [74.865929436000044, 32.354070833000037],
            [74.866080845000056, 32.350891254000032],
            [74.866086275000043, 32.350777219000065],
            [74.866331737000053, 32.345622516000049],
            [74.866429000000039, 32.343580000000031],
            [74.865602032000083, 32.339031674000069],
            [74.864907774000073, 32.335213258000067],
            [74.864245000000039, 32.331568000000061],
            [74.859619414000065, 32.327404972000068],
            [74.853325000000041, 32.321740000000034],
            [74.853281548000041, 32.319784659000049],
            [74.853221458000064, 32.317080607000037],
            [74.853145925000035, 32.313681625000072],
            [74.853076934000057, 32.310577046000049],
            [74.85296100000005, 32.305360000000064],
            [74.851868614000068, 32.305281972000046],
            [74.842769000000033, 32.304632000000026],
            [74.832214000000079, 32.304632000000026],
            [74.832213000000081, 32.304632000000026],
            [74.831926069000076, 32.304727644000025],
            [74.831925043000069, 32.304727986000046],
            [74.831909216000042, 32.30473326200007],
            [74.829574723000064, 32.305511426000066],
            [74.825661898000078, 32.306815701000062],
            [74.825661000000082, 32.306816000000026],
            [74.824523317000057, 32.303470059000063],
            [74.822692835000055, 32.298086583000043],
            [74.822578295000085, 32.297749719000024],
            [74.822132471000032, 32.296438544000068],
            [74.821289892000038, 32.293960507000065],
            [74.819473000000073, 32.288617000000045],
            [74.814086899000074, 32.287220604000026],
            [74.814086950000046, 32.287220357000024],
            [74.814086914000029, 32.287220348000062],
            [74.810191766000059, 32.286210495000034],
            [74.809692383000083, 32.286071777000075],
            [74.809672981000062, 32.286075995000033],
            [74.809646000000043, 32.286069000000055],
            [74.809645473000046, 32.286069123000061],
            [74.809645000000046, 32.286069000000055],
            [74.808782736000069, 32.286269526000069],
            [74.806884766000053, 32.286682129000042],
            [74.806508639000072, 32.286798386000044],
            [74.806006000000082, 32.286915279000027],
            [74.805381096000076, 32.287060566000036],
            [74.803332985000054, 32.287536740000064],
            [74.803332830000045, 32.287536776000024],
            [74.798974743000031, 32.288550006000037],
            [74.794688009000083, 32.289546647000066],
            [74.794632634000038, 32.289559521000058],
            [74.794213445000082, 32.289656980000075],
            [74.794181322000043, 32.289656980000075],
            [74.794107260000033, 32.289681668000071],
            [74.793994000000055, 32.289708000000076],
            [74.792336980000073, 32.290247495000074],
            [74.792337305000046, 32.290247992000047],
            [74.792285725000056, 32.290264783000055],
            [74.792284820000077, 32.290264477000051],
            [74.786865003000059, 32.29202906900008],
            [74.786864819000073, 32.29202912900007],
            [74.785621976000073, 32.292433775000063],
            [74.783675075000076, 32.293067650000069],
            [74.779481435000037, 32.294433021000032],
            [74.778342000000066, 32.294804000000056],
            [74.774751244000072, 32.290901433000045],
            [74.773855687000037, 32.289928109000073],
            [74.770080566000047, 32.285705566000047],
            [74.770071303000066, 32.285600113000044],
            [74.769877519000033, 32.283393947000036],
            [74.769396881000034, 32.277922072000024],
            [74.769287109000061, 32.276672363000046],
            [74.770692096000062, 32.27271879400007],
            [74.770594814000049, 32.272739818000048],
            [74.771790000000067, 32.269325000000038],
            [74.770343034000064, 32.267009854000037],
            [74.770342795000033, 32.267009471000051],
            [74.768479709000076, 32.264028534000033],
            [74.768150000000048, 32.263501000000076],
            [74.767942300000072, 32.262774049000029],
            [74.767942216000051, 32.262773755000069],
            [74.76724667600007, 32.260339368000075],
            [74.766684246000068, 32.258370862000049],
            [74.766113403000077, 32.256372910000039],
            [74.765966000000049, 32.255857000000049],
            [74.754682000000059, 32.25440100000003],
            [74.754623486000071, 32.254064549000077],
            [74.753702715000031, 32.248770110000066],
            [74.75231790600003, 32.240807459000052],
            [74.751832124000032, 32.238014212000053],
            [74.751770000000079, 32.23765700000007],
            [74.750777188000029, 32.236900572000025],
            [74.744126000000051, 32.231833000000051],
            [74.743592908000039, 32.228862914000047],
            [74.74314748900008, 32.226381296000056],
            [74.742285286000083, 32.221577595000042],
            [74.741700421000075, 32.218319061000045],
            [74.741578000000061, 32.217637000000025],
            [74.743146620000061, 32.214708909000024],
            [74.745116447000044, 32.211031898000044],
            [74.745404649000079, 32.210493921000079],
            [74.747038000000032, 32.207445000000064],
            [74.753146185000048, 32.200967130000038],
            [74.754058816000054, 32.199999264000041],
            [74.75405932700005, 32.199999783000067],
            [74.759051000000056, 32.194706000000053],
            [74.758797687000083, 32.190653000000054],
            [74.758734008000033, 32.189634137000041],
            [74.758310646000041, 32.182860341000037],
            [74.757581021000078, 32.171186338000041],
            [74.757231000000047, 32.165586000000076],
            [74.754565250000041, 32.163012172000037],
            [74.754248802000063, 32.162706636000053],
            [74.753923112000052, 32.162392177000072],
            [74.753922523000028, 32.162392574000023],
            [74.746674000000041, 32.155394000000058],
            [74.744950445000029, 32.154458356000077],
            [74.741037340000048, 32.152334099000029],
            [74.737028251000083, 32.15015773600004],
            [74.733934000000033, 32.148478000000068],
            [74.726654000000053, 32.14738600000004],
            [74.71692044100007, 32.143735706000029],
            [74.716918238000062, 32.143734880000068],
            [74.715719559000036, 32.143285350000042],
            [74.709183000000053, 32.140834000000041],
            [74.700617805000036, 32.139977481000074],
            [74.700280294000038, 32.139943730000027],
            [74.698263000000054, 32.139742000000069],
            [74.694259000000045, 32.136830000000032],
            [74.691901500000029, 32.134043864000034],
            [74.690255000000036, 32.132098000000042],
            [74.68814517800007, 32.132590292000032],
            [74.682283312000038, 32.133958060000055],
            [74.679335748000085, 32.134645825000064],
            [74.679335000000037, 32.134646000000032],
            [74.676207289000047, 32.132480662000034],
            [74.676207795000039, 32.13248032000007],
            [74.675642951000043, 32.132089274000066],
            [74.675642915000083, 32.132089249000046],
            [74.671589340000082, 32.129282928000066],
            [74.671588967000048, 32.129283362000024],
            [74.669871000000057, 32.128094000000033],
            [74.666771164000068, 32.126305634000062],
            [74.664759383000046, 32.12514499100007],
            [74.660407000000077, 32.122634000000062],
            [74.65488124400008, 32.116285582000046],
            [74.650982070000055, 32.111805908000065],
            [74.646388215000059, 32.106528130000072],
            [74.64636316800005, 32.106539638000072],
            [74.646374084000058, 32.10651189500004],
            [74.64329900000007, 32.102979000000062],
            [74.640243146000046, 32.101281173000075],
            [74.630196000000069, 32.095699000000025],
            [74.630186792000075, 32.095681242000069],
            [74.626535749000084, 32.088639944000079],
            [74.625100000000032, 32.085871000000054],
            [74.624751164000031, 32.083917521000046],
            [74.624162765000051, 32.080622486000038],
            [74.624161036000032, 32.080612801000029],
            [74.623874877000048, 32.079010311000047],
            [74.622821656000042, 32.07311227200006],
            [74.62146000000007, 32.065487000000076],
            [74.619166196000037, 32.066573539000046],
            [74.61454400000008, 32.068763000000047],
            [74.607264000000043, 32.066943000000038],
            [74.603580628000032, 32.06227739600007],
            [74.601804046000041, 32.06002705800006],
            [74.601804000000072, 32.060027000000048],
            [74.58396891600006, 32.061118944000043],
            [74.583968000000084, 32.061119000000076],
            [74.583795000000066, 32.061003000000028],
            [74.583794964000049, 32.061003008000057],
            [74.579648288000044, 32.061950461000038],
            [74.579648252000084, 32.061950469000067],
            [74.578508000000056, 32.062211000000048],
            [74.576388069000075, 32.060501378000026],
            [74.576387895000039, 32.060502045000078],
            [74.57027335500004, 32.055570964000026],
            [74.567223186000035, 32.053111150000063],
            [74.56722300000007, 32.053111000000058],
            [74.55630138600003, 32.05511701000006],
            [74.554380424000044, 32.055469840000058],
            [74.549387000000081, 32.056387000000029],
            [74.548193087000072, 32.056784970000024],
            [74.540429423000035, 32.059372859000064],
            [74.536283000000083, 32.060755000000029],
            [74.535855065000078, 32.061004629000024],
            [74.535421552000059, 32.061257512000054],
            [74.527547000000084, 32.065851000000066],
            [74.525321960000042, 32.067900526000074],
            [74.522877562000076, 32.070152109000048],
            [74.522876886000063, 32.070152731000064],
            [74.513716000000045, 32.078591000000074],
            [74.511986172000036, 32.082146757000032],
            [74.507164465000074, 32.092058044000055],
            [74.507164000000046, 32.092059000000063],
            [74.507164465000074, 32.092060163000042],
            [74.510804000000064, 32.101159000000052],
            [74.510804477000079, 32.10115894300003],
            [74.513032657000053, 32.100891561000026],
            [74.519904000000054, 32.100067000000024],
            [74.519903920000047, 32.100067071000069],
            [74.519904501000042, 32.10006700100007],
            [74.517934349000029, 32.101811993000069],
            [74.516725793000035, 32.102882428000044],
            [74.507164501000034, 32.11135100000007],
            [74.500262773000031, 32.114549238000052],
            [74.494252703000029, 32.117334284000037],
            [74.492843402000062, 32.117987375000041],
            [74.492240996000078, 32.118266538000057],
            [74.492240000000038, 32.11826700000006],
            [74.491846189000057, 32.122402024000053],
            [74.491847128000074, 32.122402162000071],
            [74.491641555000058, 32.124560682000038],
            [74.491641519000041, 32.124561060000076],
            [74.491513000000054, 32.125910500000032],
            [74.487145001000044, 32.13100600000007],
            [74.489311809000071, 32.134858104000045],
            [74.490421001000072, 32.136830000000032],
            [74.490421001000072, 32.137168734000056],
            [74.490421001000072, 32.143962976000068],
            [74.490421001000072, 32.149934001000076],
            [74.473677000000066, 32.152846001000057],
            [74.47250592000006, 32.153019494000034],
            [74.472505958000056, 32.153019339000025],
            [74.472504915000059, 32.153019494000034],
            [74.462843128000031, 32.154450869000073],
            [74.462810723000075, 32.154455671000051],
            [74.454020000000071, 32.155758000000048],
            [74.452524018000076, 32.158109290000027],
            [74.45248768700003, 32.158166392000055],
            [74.45155587000005, 32.15963096400003],
            [74.451546510000071, 32.159623024000041],
            [74.448925000000031, 32.163766000000066],
            [74.439097355000058, 32.168497829000046],
            [74.439097000000061, 32.168498000000056],
            [74.43903481600006, 32.168424842000036],
            [74.435516158000041, 32.16428524500003],
            [74.433664183000076, 32.162106451000056],
            [74.432909000000052, 32.161218000000076],
            [74.424126775000047, 32.159313418000067],
            [74.423937002000059, 32.159505004000039],
            [74.412890001000051, 32.164494000000047],
            [74.400213997000037, 32.164769570000033],
            [74.400213902000075, 32.164769566000075],
            [74.39924196700008, 32.164790701000072],
            [74.397665962000076, 32.164824954000039],
            [74.397665898000071, 32.164824963000058],
            [74.384721286000058, 32.168564157000048],
            [74.380256813000074, 32.169473587000027],
            [74.376649514000064, 32.178915083000049],
            [74.376606843000047, 32.17902676600005],
            [74.376235379000036, 32.179157337000049],
            [74.375763234000033, 32.18123477000006],
            [74.375762500000064, 32.181238000000064],
            [74.368268767000075, 32.183379067000033],
            [74.368118501000083, 32.183422000000064],
            [74.363818311000045, 32.185011201000066],
            [74.35373891200004, 32.188736196000036],
            [74.353738841000052, 32.188736037000069],
            [74.353738411000052, 32.188736196000036],
            [74.351374000000078, 32.189610000000073],
            [74.350706363000029, 32.190437871000029],
            [74.342274000000032, 32.200894000000062],
            [74.337901539000029, 32.202642984000079],
            [74.334974716000033, 32.203813713000045],
            [74.331355215000031, 32.205261514000028],
            [74.331354992000058, 32.205261603000054],
            [74.33095576900007, 32.21060114900007],
            [74.33053936500005, 32.216170489000035],
            [74.330539329000032, 32.216170970000064],
            [74.329654972000071, 32.227999086000068],
            [74.328836484000078, 32.238946221000049],
            [74.32844300000005, 32.244209001000058],
            [74.319526403000054, 32.247520879000035],
            [74.315703000000042, 32.248941000000059],
            [74.305670976000044, 32.249973729000033],
            [74.30567099700005, 32.249973645000068],
            [74.296153405000041, 32.250953437000078],
            [74.292489075000049, 32.251330666000058],
            [74.290951000000064, 32.251489005000053],
            [74.272751500000084, 32.251852996000025],
            [74.272751000000085, 32.25185300000004],
            [74.268882457000075, 32.254875299000048],
            [74.268036801000051, 32.255535968000061],
            [74.266637124000056, 32.256629466000049],
            [74.261103000000048, 32.260953000000029],
            [74.254497481000044, 32.268714484000043],
            [74.248993409000036, 32.275181769000028],
            [74.246543000000031, 32.278061000000037],
            [74.243369720000032, 32.287898168000027],
            [74.242903000000069, 32.289345000000026],
            [74.242608089000043, 32.291830555000047],
            [74.241927462000035, 32.297567013000048],
            [74.240355000000079, 32.310820000000035],
            [74.239127127000074, 32.315948562000074],
            [74.239127914000051, 32.315949326000066],
            [74.236413141000071, 32.327288058000079],
            [74.235858857000039, 32.329603125000062],
            [74.235085707000053, 32.332832691000078],
            [74.234168501000056, 32.336664001000031],
            [74.230994571000053, 32.341058671000042],
            [74.224704501000076, 32.349768001000029],
            [74.225122750000082, 32.351162167000041],
            [74.226766389000034, 32.356640964000064],
            [74.226784029000044, 32.356699764000041],
            [74.226888501000076, 32.357048001000067],
            [74.22688850000003, 32.362432650000073],
            [74.226888501000076, 32.372766237000064],
            [74.226888501000076, 32.379979000000048],
            [74.226692077000052, 32.380074235000052],
            [74.224416740000038, 32.381177429000047],
            [74.222649392000051, 32.382034325000063],
            [74.216470031000028, 32.385030379000057],
            [74.214876500000059, 32.385803001000056],
            [74.214489857000046, 32.386458895000032],
            [74.211324760000082, 32.39182568700005],
            [74.207984459000045, 32.397489557000029],
            [74.20650450100004, 32.399999000000037],
            [74.21050850000006, 32.408371000000045],
            [74.210118907000037, 32.414994093000075],
            [74.209995412000069, 32.417093516000079],
            [74.209926659000075, 32.418262322000032],
            [74.209780501000068, 32.420747001000052],
            [74.207464739000045, 32.426701815000058],
            [74.206322709000062, 32.42963846300006],
            [74.205915550000043, 32.430685444000062],
            [74.204684501000031, 32.433851000000061],
            [74.203628501000082, 32.436596597000062],
            [74.202971201000082, 32.43830557800004],
            [74.201620711000032, 32.441816853000034],
            [74.200469355000052, 32.444810378000057],
            [74.19922450100006, 32.448047000000031],
            [74.199592238000037, 32.449374888000079],
            [74.203032275000055, 32.461796715000048],
            [74.204031498000063, 32.465404869000054],
            [74.20577650000007, 32.47170600100003],
            [74.206743202000041, 32.477935850000051],
            [74.208289600000057, 32.487901532000024],
            [74.20838027700006, 32.488485898000079],
            [74.209052500000041, 32.492818001000046],
            [74.20801534900005, 32.497744468000064],
            [74.207024595000064, 32.502450550000049],
            [74.20614050000006, 32.506650001000025],
            [74.202136500000051, 32.522666001000061],
            [74.20287872800003, 32.524305087000073],
            [74.206106127000055, 32.531432260000031],
            [74.208953895000036, 32.53772108000004],
            [74.21087250100004, 32.541958001000069],
            [74.211591273000067, 32.552379227000074],
            [74.211600500000031, 32.552513001000079],
            [74.211572002000082, 32.552713939000057],
            [74.211873174000061, 32.552721463000069],
            [74.213135501000068, 32.552753000000052],
            [74.213282217000028, 32.552779675000068],
            [74.213284520000059, 32.552776881000057],
            [74.213283782000076, 32.552779959000077],
            [74.216448796000066, 32.55335539400005],
            [74.224663068000041, 32.554848840000034],
            [74.225868500000047, 32.555068001000052],
            [74.22765137600004, 32.557530072000077],
            [74.227688466000075, 32.557581292000066],
            [74.23078444500004, 32.561856697000053],
            [74.236268100000075, 32.569429373000048],
            [74.23795814600004, 32.571763249000071],
            [74.238023500000054, 32.571853500000032],
            [74.238034307000078, 32.571857908000027],
            [74.244095936000065, 32.574330403000033],
            [74.246075131000055, 32.575137703000053],
            [74.249480597000058, 32.576526768000065],
            [74.253544401000056, 32.578184364000037],
            [74.253551647000052, 32.57818732000004],
            [74.258483711000054, 32.580199073000074],
            [74.267798930000083, 32.583998684000051],
            [74.273562157000072, 32.586349462000044],
            [74.273563086000081, 32.586336550000055],
            [74.273568651000062, 32.586352111000053],
            [74.277835829000082, 32.588092663000054],
            [74.282013000000063, 32.589796501000023],
            [74.282050790000085, 32.589781105000043],
            [74.282053007000059, 32.589780202000043],
            [74.286874174000047, 32.587816011000029],
            [74.296000283000069, 32.584097944000064],
            [74.296246778000068, 32.583997520000025],
            [74.297641000000056, 32.583429500000079],
            [74.297892862000083, 32.583510098000033],
            [74.29894395000008, 32.583846453000035],
            [74.30204576400007, 32.584839055000032],
            [74.309702919000074, 32.587289398000053],
            [74.312032412000065, 32.588034852000078],
            [74.312111000000073, 32.588060001000031],
            [74.318827679000037, 32.588635627000031],
            [74.319077554000046, 32.588657042000079],
            [74.322193880000043, 32.588924115000054],
            [74.324251340000046, 32.589381328000059],
            [74.328642798000033, 32.589476794000063],
            [74.328868017000048, 32.589496096000062],
            [74.332164073000058, 32.589778123000031],
            [74.332256243000074, 32.589655939000068],
            [74.332259996000062, 32.589656131000027],
            [74.332168038000077, 32.589778462000027],
            [74.332373000000075, 32.589796000000035],
            [74.343443176000051, 32.593724206000047],
            [74.350087849000033, 32.596082041000045],
            [74.350316000000078, 32.596163000000047],
            [74.350333830000068, 32.59617473000003],
            [74.350633403000074, 32.596371812000029],
            [74.358051376000049, 32.601251924000053],
            [74.361833098000034, 32.603739831000041],
            [74.372311000000082, 32.610633000000064],
            [74.372486743000081, 32.61078129200007],
            [74.372400541000047, 32.610892672000034],
            [74.374288338000042, 32.612301476000027],
            [74.378721976000065, 32.616042575000051],
            [74.382648242000073, 32.619355554000038],
            [74.385734753000065, 32.62195994800004],
            [74.390180959000077, 32.625711652000064],
            [74.390807142000028, 32.626265384000078],
            [74.391051340000047, 32.626481328000068],
            [74.391454051000039, 32.626655473000028],
            [74.39418304700007, 32.628386065000029],
            [74.400423004000061, 32.632343130000038],
            [74.400885506000066, 32.632636426000033],
            [74.411539454000035, 32.639392620000024],
            [74.414563000000044, 32.641310000000033],
            [74.424320207000051, 32.648375344000044],
            [74.424451376000036, 32.648470325000062],
            [74.424466521000056, 32.648481292000042],
            [74.426385026000048, 32.649870511000074],
            [74.426506888000063, 32.649958753000078],
            [74.42874495500007, 32.651579372000072],
            [74.431349000000068, 32.65346500000004],
            [74.43600880200006, 32.654396879000046],
            [74.436060624000049, 32.654407243000037],
            [74.440643353000041, 32.655323710000062],
            [74.442925000000059, 32.65578000000005],
            [74.443233064000083, 32.656181834000051],
            [74.446171747000051, 32.660015013000077],
            [74.446183409000071, 32.660009749000039],
            [74.446189506000053, 32.660017725000046],
            [74.446177857000066, 32.660022983000033],
            [74.45145592700004, 32.666907630000026],
            [74.455906531000039, 32.672712940000054],
            [74.455907508000053, 32.672714215000042],
            [74.456237000000044, 32.673144000000036],
            [74.456247977000032, 32.673176929000078],
            [74.456253364000077, 32.673193087000072],
            [74.45625605500004, 32.673201160000076],
            [74.45965134000005, 32.687481328000047],
            [74.461992081000062, 32.690407254000036],
            [74.462026000000037, 32.690509000000077],
            [74.461369509000065, 32.70035297000004],
            [74.461339386000077, 32.70080465500007],
            [74.461067163000052, 32.704886587000033],
            [74.46110257600003, 32.704996165000068],
            [74.461062566000066, 32.704955511000037],
            [74.460868000000062, 32.707873000000063],
            [74.457395000000076, 32.721185000000048],
            [74.456987274000085, 32.722000478000041],
            [74.456318492000037, 32.723338081000065],
            [74.455164327000034, 32.725646481000069],
            [74.452261528000065, 32.731452251000064],
            [74.44885898800004, 32.738257535000059],
            [74.447479318000035, 32.741016957000056],
            [74.444002810000029, 32.747970179000049],
            [74.440610000000049, 32.754756000000043],
            [74.440538957000058, 32.75495137200005],
            [74.440425925000056, 32.755262214000027],
            [74.43915134000008, 32.757981328000028],
            [74.439151322000043, 32.757981364000045],
            [74.43903872900006, 32.758202528000027],
            [74.436351340000044, 32.763481328000069],
            [74.433881062000069, 32.771732057000065],
            [74.431351340000049, 32.780181328000026],
            [74.43131522200008, 32.780302815000027],
            [74.430426462000071, 32.783292281000058],
            [74.436885974000063, 32.786737835000054],
            [74.439519155000085, 32.788142394000033],
            [74.437538000000075, 32.788076000000046],
            [74.438826319000043, 32.790769646000058],
            [74.43954242500007, 32.792266896000058],
            [74.444741689000068, 32.789298164000058],
            [74.445796741000038, 32.788815645000057],
            [74.446237297000039, 32.789060619000054],
            [74.446413831000029, 32.789415658000053],
            [74.446472314000061, 32.789626333000058],
            [74.446405549000076, 32.789847741000074],
            [74.446372340000039, 32.789914127000031],
            [74.446298920000061, 32.790152138000053],
            [74.446298833000071, 32.790174297000078],
            [74.446211864000077, 32.790506450000066],
            [74.447013801000082, 32.791023857000027],
            [74.44835942800006, 32.791186933000063],
            [74.449018243000069, 32.79123306300005],
            [74.450258395000048, 32.790909613000053],
            [74.451122389000034, 32.790729160000069],
            [74.451803149000057, 32.790409708000027],
            [74.452214995000077, 32.789557703000071],
            [74.453008766000039, 32.789526618000025],
            [74.453396379000083, 32.789837892000037],
            [74.454800446000036, 32.789736419000064],
            [74.455745012000079, 32.788682567000023],
            [74.456004304000032, 32.788393276000079],
            [74.455623496000044, 32.788021075000074],
            [74.456575351000083, 32.78715280800003],
            [74.457186953000075, 32.787481309000043],
            [74.458716883000079, 32.785911610000028],
            [74.459120656000039, 32.785524556000041],
            [74.460159047000047, 32.784513514000025],
            [74.461571263000053, 32.782253900000057],
            [74.464557496000054, 32.782828066000036],
            [74.46441337400006, 32.783459546000074],
            [74.465086757000051, 32.783508998000059],
            [74.46532294800005, 32.782907562000048],
            [74.46818567300005, 32.782831566000027],
            [74.470193206000033, 32.776910357000077],
            [74.481320307000033, 32.769418587000075],
            [74.481485866000071, 32.768326578000028],
            [74.481269532000056, 32.768007441000066],
            [74.481109407000076, 32.76756163400006],
            [74.48111116900003, 32.767059973000073],
            [74.481189819000065, 32.766891388000033],
            [74.48151381200006, 32.766718724000043],
            [74.481422407000082, 32.765763726000046],
            [74.481377013000042, 32.764405788000033],
            [74.482257771000036, 32.764007210000045],
            [74.483281323000028, 32.763743462000036],
            [74.483153615000049, 32.76315384000003],
            [74.484410837000041, 32.762668957000074],
            [74.486317581000037, 32.762217683000074],
            [74.489006242000073, 32.762948362000031],
            [74.489766161000034, 32.763250291000077],
            [74.49034036300003, 32.763645625000038],
            [74.49201044800003, 32.763173404000042],
            [74.493146110000055, 32.763413410000055],
            [74.493906113000037, 32.763347974000055],
            [74.494758691000072, 32.762941677000072],
            [74.497315613000069, 32.761706020000076],
            [74.499427967000031, 32.761134938000055],
            [74.500191478000033, 32.761174501000028],
            [74.501281885000083, 32.76084651900004],
            [74.503757415000052, 32.759623547000047],
            [74.50514172000004, 32.758710692000079],
            [74.506042210000032, 32.758070575000033],
            [74.50554956600007, 32.757612026000061],
            [74.505475439000065, 32.756233058000078],
            [74.505446755000037, 32.755200015000071],
            [74.505481105000058, 32.754307517000029],
            [74.505220162000057, 32.752972093000039],
            [74.504849665000052, 32.751327426000046],
            [74.504720300000031, 32.750324094000064],
            [74.504679939000084, 32.748674888000039],
            [74.50469972500008, 32.74821681700007],
            [74.505447721000053, 32.747449214000028],
            [74.506130847000065, 32.746934664000037],
            [74.505605718000083, 32.746679580000034],
            [74.505890505000082, 32.746295577000069],
            [74.506324452000058, 32.746465818000047],
            [74.507282991000068, 32.745353371000078],
            [74.508915122000076, 32.744127810000066],
            [74.510637660000043, 32.74301508700006],
            [74.511087404000079, 32.742793002000042],
            [74.511709081000049, 32.742991036000035],
            [74.513722999000038, 32.74263129600007],
            [74.513999775000059, 32.742834739000045],
            [74.514663746000053, 32.742960395000068],
            [74.516138916000045, 32.743050595000057],
            [74.517230598000083, 32.743472308000037],
            [74.51792718400003, 32.744217276000029],
            [74.518354079000062, 32.744623859000058],
            [74.518867500000056, 32.74558154500005],
            [74.519344077000085, 32.746486886000071],
            [74.519589712000084, 32.746619871000064],
            [74.520276194000076, 32.746811915000023],
            [74.520909474000064, 32.746467664000079],
            [74.521500397000068, 32.746206021000035],
            [74.52217900800008, 32.746375882000052],
            [74.52288438100004, 32.746634437000068],
            [74.536653913000066, 32.750166232000026],
            [74.537472005000041, 32.750212680000061],
            [74.538080268000044, 32.750202315000024],
            [74.538816654000072, 32.75013417200006],
            [74.539511367000046, 32.750007911000068],
            [74.540277033000052, 32.749934859000064],
            [74.540880945000083, 32.749947689000066],
            [74.54145911300003, 32.751102995000053],
            [74.541714340000055, 32.751609101000042],
            [74.543186526000056, 32.751753972000074],
            [74.543232445000058, 32.751435413000024],
            [74.543127755000057, 32.750646342000039],
            [74.543668311000033, 32.750280837000048],
            [74.544312320000074, 32.749809755000058],
            [74.544973093000067, 32.749312406000058],
            [74.546165358000053, 32.748426920000043],
            [74.546695845000045, 32.749397539000029],
            [74.546783879000031, 32.749734078000074],
            [74.546854476000078, 32.750396408000029],
            [74.54685764900006, 32.751161806000027],
            [74.546857511000042, 32.751610672000027],
            [74.546857431000035, 32.751873517000035],
            [74.547264390000066, 32.752634337000075],
            [74.547586839000076, 32.752980012000023],
            [74.548271013000033, 32.753801605000035],
            [74.548771826000063, 32.754442979000032],
            [74.549243143000069, 32.754867387000047],
            [74.550458475000084, 32.75548111300003],
            [74.55174020700008, 32.756286071000034],
            [74.552012838000053, 32.756465459000026],
            [74.553311399000052, 32.757029657000032],
            [74.554049116000044, 32.757381701000043],
            [74.555202789000077, 32.758712161000062],
            [74.55548964500008, 32.75878541700007],
            [74.555922259000056, 32.758662634000075],
            [74.556843386000082, 32.75793113900005],
            [74.557818514000076, 32.758260998000026],
            [74.559767685000054, 32.758701016000032],
            [74.561390406000044, 32.759026056000039],
            [74.562216857000067, 32.75933597900007],
            [74.562653194000063, 32.759578463000025],
            [74.564038397000047, 32.760005818000025],
            [74.565178554000056, 32.76027835900004],
            [74.56643753700007, 32.76046242700005],
            [74.56750663300005, 32.76050691100005],
            [74.569102240000063, 32.760494546000075],
            [74.569408122000084, 32.760436054000024],
            [74.570279931000073, 32.759712081000032],
            [74.570641587000068, 32.759409496000046],
            [74.571216414000048, 32.758812154000054],
            [74.571443601000055, 32.758526878000055],
            [74.571583340000075, 32.758400355000049],
            [74.571984740000062, 32.758180147000076],
            [74.572306726000079, 32.758028349000028],
            [74.573055491000048, 32.757816644000059],
            [74.57383162800005, 32.757587227000045],
            [74.574577487000056, 32.757138044000044],
            [74.575207507000073, 32.756696469000076],
            [74.57582780000007, 32.756178989000034],
            [74.57589717500008, 32.755960119000065],
            [74.575848433000033, 32.755292870000062],
            [74.575786564000055, 32.754109372000073],
            [74.57576632100006, 32.753561722000029],
            [74.575818774000084, 32.75272508200004],
            [74.575095565000083, 32.751483132000033],
            [74.575830447000044, 32.750720118000061],
            [74.576470480000069, 32.75123906400006],
            [74.577828317000069, 32.750732518000063],
            [74.57773833400006, 32.750270170000078],
            [74.578602617000058, 32.750037589000044],
            [74.579253791000042, 32.749951936000059],
            [74.579539448000048, 32.749891888000036],
            [74.58014946000003, 32.750583475000042],
            [74.580531609000047, 32.751308187000063],
            [74.580729639000083, 32.751775082000051],
            [74.581152548000034, 32.752431711000042],
            [74.581674882000073, 32.753167348000034],
            [74.581941796000081, 32.753536242000052],
            [74.582124839000073, 32.753684857000053],
            [74.583727364000083, 32.754066163000061],
            [74.584177771000043, 32.753791834000026],
            [74.585250649000045, 32.753172499000073],
            [74.58599031500006, 32.752729613000042],
            [74.586693006000075, 32.752240244000063],
            [74.587317132000067, 32.751801966000073],
            [74.587811544000033, 32.751498287000061],
            [74.588670506000085, 32.750969163000036],
            [74.589785363000033, 32.751369030000035],
            [74.590461621000031, 32.75171415300008],
            [74.590662571000053, 32.751825389000032],
            [74.59081272800006, 32.75195612400006],
            [74.591231651000044, 32.752785428000038],
            [74.591855985000052, 32.753852460000076],
            [74.592460820000042, 32.754801543000042],
            [74.593247348000034, 32.756069208000042],
            [74.593605904000071, 32.756265197000062],
            [74.594461874000046, 32.756560290000039],
            [74.595292839000081, 32.756664346000036],
            [74.596012551000058, 32.756790456000033],
            [74.596772801000043, 32.756807467000044],
            [74.597283157000049, 32.75682408800003],
            [74.59766154600004, 32.756872130000033],
            [74.597971812000083, 32.756896340000026],
            [74.598585933000038, 32.75683996500004],
            [74.599632076000034, 32.756758558000058],
            [74.60013622200006, 32.75672374800007],
            [74.60075416400008, 32.756865077000043],
            [74.601518995000049, 32.757138313000041],
            [74.602208996000059, 32.757159137000031],
            [74.602707501000054, 32.757383882000056],
            [74.603679302000046, 32.757786685000042],
            [74.604446480000036, 32.758014642000035],
            [74.605254559000059, 32.758191811000074],
            [74.605895781000072, 32.758302749000052],
            [74.606669096000076, 32.758359262000056],
            [74.607377109000083, 32.758391949000043],
            [74.607995473000074, 32.758349376000069],
            [74.609118463000073, 32.758264051000026],
            [74.610177641000064, 32.758190479000064],
            [74.611503665000043, 32.758212195000056],
            [74.612723835000054, 32.758314792000078],
            [74.612742602000083, 32.758532223000032],
            [74.612796517000049, 32.75954272000007],
            [74.612933971000075, 32.759812134000072],
            [74.613759541000036, 32.760208477000049],
            [74.615045258000066, 32.760694923000074],
            [74.615861876000054, 32.76105087600007],
            [74.61719620100007, 32.761597618000053],
            [74.617428334000067, 32.761692259000029],
            [74.618709056000057, 32.761434087000055],
            [74.619109800000047, 32.761362956000028],
            [74.61975314600005, 32.761177125000074],
            [74.621198701000083, 32.760769707000065],
            [74.621454254000071, 32.760640784000032],
            [74.622365976000083, 32.759865514000069],
            [74.623068844000045, 32.759258525000064],
            [74.62326495800005, 32.758881726000027],
            [74.623597931000063, 32.758412962000079],
            [74.623882647000073, 32.75805194000003],
            [74.624852041000054, 32.757357554000066],
            [74.62445957400007, 32.756680398000071],
            [74.625322909000033, 32.756502973000067],
            [74.626832475000072, 32.755949804000068],
            [74.627785976000041, 32.755587643000069],
            [74.628706776000058, 32.754993150000075],
            [74.629847362000078, 32.754178550000063],
            [74.630865140000083, 32.753441892000069],
            [74.631555448000029, 32.75299473900003],
            [74.632612407000067, 32.752288582000062],
            [74.633877418000054, 32.75201971000007],
            [74.634340094000038, 32.75191202700006],
            [74.635208944000055, 32.751637990000063],
            [74.635516904000042, 32.752345194000043],
            [74.637285294000037, 32.751778517000048],
            [74.637510493000036, 32.752970955000023],
            [74.637664983000036, 32.753895259000046],
            [74.63787280400004, 32.754753047000065],
            [74.638331020000066, 32.755598874000043],
            [74.638550918000078, 32.755998101000046],
            [74.63876722200007, 32.757349786000077],
            [74.637698173000047, 32.757598851000068],
            [74.637706940000044, 32.759544076000054],
            [74.637089779000064, 32.759673945000031],
            [74.636456903000067, 32.759911806000048],
            [74.635095877000083, 32.761001849000024],
            [74.634843298000078, 32.761220965000064],
            [74.63381000000004, 32.762452413000062],
            [74.633533813000042, 32.762780982000038],
            [74.632603735000032, 32.763458590000027],
            [74.631660500000066, 32.764568101000066],
            [74.631467825000072, 32.765967437000029],
            [74.630831381000064, 32.766470287000061],
            [74.630258064000031, 32.767139497000073],
            [74.630137580000053, 32.76792335600004],
            [74.630092134000051, 32.768201212000065],
            [74.630235152000068, 32.769277952000039],
            [74.630021558000067, 32.76991406500008],
            [74.630885747000036, 32.770400782000024],
            [74.631303284000069, 32.770783771000026],
            [74.631312951000041, 32.771654115000047],
            [74.631988336000063, 32.772771824000074],
            [74.632229873000028, 32.773767662000068],
            [74.632411813000033, 32.774663492000059],
            [74.631387889000052, 32.775214447000053],
            [74.631679512000062, 32.776059560000078],
            [74.632006798000077, 32.776548621000074],
            [74.632565829000043, 32.777025710000032],
            [74.633724035000057, 32.777915528000051],
            [74.634619790000045, 32.778580199000032],
            [74.635694623000063, 32.779345576000026],
            [74.63701859300005, 32.780402008000067],
            [74.637501069000052, 32.780789359000039],
            [74.638188812000067, 32.781190748000029],
            [74.639402997000047, 32.782595168000057],
            [74.640702099000066, 32.782408244000067],
            [74.641792868000039, 32.782085369000072],
            [74.642467724000085, 32.782051592000073],
            [74.643254054000067, 32.78206784200006],
            [74.646151064000037, 32.781318413000065],
            [74.648439837000069, 32.781289860000072],
            [74.648936253000045, 32.781287122000037],
            [74.649313342000084, 32.781709963000026],
            [74.650988140000038, 32.781210710000039],
            [74.651417651000031, 32.781688189000079],
            [74.652376325000034, 32.781474108000054],
            [74.653631722000057, 32.780990547000044],
            [74.654325635000077, 32.782947016000037],
            [74.655710052000074, 32.78458381300004],
            [74.657306361000053, 32.785945956000035],
            [74.658144913000058, 32.786680837000063],
            [74.658285168000077, 32.787120003000041],
            [74.657065831000068, 32.787372448000042],
            [74.656047191000084, 32.788356044000068],
            [74.65585524100004, 32.788527558000055],
            [74.655118100000038, 32.789077043000077],
            [74.654810771000029, 32.789267769000048],
            [74.65364935100007, 32.789987474000043],
            [74.650494304000063, 32.791831312000056],
            [74.649880490000044, 32.792068796000024],
            [74.64801857100008, 32.793094684000039],
            [74.646840406000081, 32.793725459000029],
            [74.645259736000071, 32.793864148000068],
            [74.643426278000049, 32.795176282000057],
            [74.641108263000035, 32.796867445000032],
            [74.639639059000046, 32.797962645000041],
            [74.63936547600008, 32.798170760000062],
            [74.638849612000058, 32.799506511000061],
            [74.638630767000052, 32.800078199000041],
            [74.638488400000028, 32.800351990000024],
            [74.63792892500004, 32.800871500000028],
            [74.637130937000052, 32.802207155000076],
            [74.636660867000046, 32.802962192000052],
            [74.635288873000036, 32.804407059000027],
            [74.634898445000033, 32.805019371000071],
            [74.63436011400006, 32.80589804300007],
            [74.634202652000056, 32.806159076000029],
            [74.633406776000072, 32.80715525800008],
            [74.633012698000073, 32.807626274000029],
            [74.632868201000065, 32.807798981000076],
            [74.632547787000078, 32.809115701000053],
            [74.633282613000063, 32.809895363000066],
            [74.633880396000052, 32.810561299000028],
            [74.63457942000008, 32.811635372000069],
            [74.635299381000038, 32.812669006000078],
            [74.635661731000084, 32.813208727000074],
            [74.636446987000056, 32.814381204000028],
            [74.63716127400005, 32.814954033000049],
            [74.637409909000041, 32.815185247000045],
            [74.637765499000068, 32.815609410000036],
            [74.638383844000032, 32.816380688000038],
            [74.639286499000036, 32.817525531000058],
            [74.640151697000078, 32.818758137000032],
            [74.640446695000037, 32.819043204000025],
            [74.640452078000067, 32.819047686000033],
            [74.641224046000048, 32.81969047900003],
            [74.642234955000049, 32.820241672000066],
            [74.642722100000071, 32.820503968000025],
            [74.643736030000071, 32.821488097000042],
            [74.645200979000037, 32.822860099000025],
            [74.645736594000084, 32.823291161000043],
            [74.646372279000047, 32.823861955000041],
            [74.646922475000054, 32.824432762000072],
            [74.647337674000084, 32.825196056000038],
            [74.647546760000068, 32.825584054000046],
            [74.648402515000043, 32.826060808000079],
            [74.648389463000058, 32.826242121000064],
            [74.649070572000085, 32.826504591000059],
            [74.649592564000045, 32.826794919000065],
            [74.649915068000041, 32.827019035000035],
            [74.65013070200007, 32.827251208000064],
            [74.650225387000035, 32.827693659000033],
            [74.650337446000037, 32.827940296000065],
            [74.650690435000058, 32.828375496000035],
            [74.651380843000084, 32.828499073000046],
            [74.65177850300006, 32.828623125000036],
            [74.652973280000083, 32.829693345000067],
            [74.654783823000059, 32.830186309000055],
            [74.656161863000079, 32.830473951000045],
            [74.657520710000028, 32.830546497000057],
            [74.658281392000049, 32.830594437000059],
            [74.659006955000052, 32.830383061000077],
            [74.660270418000039, 32.829882891000068],
            [74.661177068000029, 32.829499235000071],
            [74.661218052000038, 32.828311582000026],
            [74.661612968000043, 32.82741049200007],
            [74.661834398000053, 32.826969323000071],
            [74.663100166000049, 32.825830115000031],
            [74.664279668000063, 32.825590968000029],
            [74.66629529800008, 32.825199840000039],
            [74.667122268000071, 32.825813233000076],
            [74.668596101000048, 32.826915236000048],
            [74.670127510000043, 32.828058274000057],
            [74.671108032000063, 32.828801136000038],
            [74.671717077000039, 32.829523065000046],
            [74.672304561000033, 32.830207182000038],
            [74.672136678000072, 32.830832400000077],
            [74.672493810000049, 32.83133674100003],
            [74.673212899000077, 32.832001192000064],
            [74.673796590000052, 32.83235944300003],
            [74.674764254000081, 32.832879430000048],
            [74.674988916000075, 32.83302019100006],
            [74.675506221000035, 32.833434231000069],
            [74.675953010000057, 32.833776864000072],
            [74.676243657000043, 32.834010787000068],
            [74.676746718000061, 32.834407570000053],
            [74.676990513000078, 32.834594245000062],
            [74.677484200000038, 32.834860701000025],
            [74.678007192000052, 32.835133281000026],
            [74.678686590000041, 32.835515888000032],
            [74.679161989000079, 32.835764753000035],
            [74.679424124000036, 32.835907081000073],
            [74.679910224000082, 32.836174889000063],
            [74.680401887000073, 32.83642775900006],
            [74.680906425000046, 32.836677245000033],
            [74.681363996000073, 32.836900728000046],
            [74.681962982000073, 32.837211852000053],
            [74.682328658000074, 32.837388743000076],
            [74.683212173000072, 32.837513959000034],
            [74.684105661000046, 32.837657980000074],
            [74.684959426000034, 32.83780488900004],
            [74.68579164700003, 32.838036436000039],
            [74.686615510000081, 32.838433597000062],
            [74.687535202000049, 32.838878647000058],
            [74.688227973000039, 32.839243827000075],
            [74.688422140000057, 32.840199253000037],
            [74.689770534000047, 32.840322950000029],
            [74.690169122000043, 32.840772719000029],
            [74.691564823000078, 32.841336246000026],
            [74.692775385000061, 32.841813177000063],
            [74.693551531000082, 32.841889283000057],
            [74.695266745000083, 32.842178002000026],
            [74.696256168000048, 32.842339529000071],
            [74.69754977100007, 32.842476425000029],
            [74.69894184900005, 32.842656079000051],
            [74.700395876000073, 32.842867191000039],
            [74.700931636000064, 32.842889098000057],
            [74.703296287000057, 32.842880882000031],
            [74.706207470000038, 32.842820610000047],
            [74.70639409000006, 32.842262824000045],
            [74.706608369000037, 32.841603732000067],
            [74.706830374000049, 32.840892799000073],
            [74.706772016000059, 32.840340809000054],
            [74.706680878000043, 32.839645726000072],
            [74.706637633000071, 32.839291625000044],
            [74.706609374000038, 32.838969906000045],
            [74.706588646000057, 32.838724708000029],
            [74.706551010000055, 32.838369626000031],
            [74.706516927000052, 32.837921871000049],
            [74.706449028000065, 32.837440736000076],
            [74.70637991600006, 32.837012777000041],
            [74.706256900000085, 32.836625923000042],
            [74.705955288000041, 32.836241426000072],
            [74.705877041000065, 32.835933516000068],
            [74.70579910400005, 32.835573043000068],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "123",
      properties: { name: "Toba Tek Singh", count: 1412 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.809902262000037, 31.332197050000048],
            [72.810059760000058, 31.331953638000073],
            [72.810062128000084, 31.331956176000062],
            [72.810062368000047, 31.331955815000072],
            [72.810066191000033, 31.331949907000023],
            [72.814456909000057, 31.325164099000062],
            [72.819429053000079, 31.317479707000075],
            [72.820483495000076, 31.31585007800004],
            [72.821875116000058, 31.314716149000049],
            [72.821875160000047, 31.314716113000031],
            [72.821803899000031, 31.314662652000038],
            [72.822060839000073, 31.314453291000063],
            [72.833694132000062, 31.304974179000055],
            [72.835530256000084, 31.302991208000037],
            [72.838357505000033, 31.299937844000056],
            [72.84578513200006, 31.291916179000054],
            [72.849611524000068, 31.28822182600004],
            [72.853422902000034, 31.284541968000042],
            [72.853459499000053, 31.28459388300007],
            [72.853868412000054, 31.284199081000054],
            [72.857506624000052, 31.280686414000058],
            [72.859650000000045, 31.278617000000054],
            [72.859621541000081, 31.278557228000068],
            [72.859811132000061, 31.278374179000025],
            [72.85497513200005, 31.268217179000033],
            [72.854090841000072, 31.265269642000078],
            [72.852073132000044, 31.258544179000069],
            [72.84887801800005, 31.256680454000048],
            [72.840465132000077, 31.251773179000054],
            [72.837563132000071, 31.240166179000028],
            [72.836551385000064, 31.238779656000077],
            [72.829920849000075, 31.229693008000027],
            [72.827911521000033, 31.226939377000065],
            [72.82450513200007, 31.222271179000074],
            [72.815273771000079, 31.214885938000066],
            [72.812414132000072, 31.212598179000054],
            [72.811635071000069, 31.212069543000041],
            [72.81121137000008, 31.212134196000079],
            [72.798711000000083, 31.203652000000034],
            [72.789522000000034, 31.202201000000059],
            [72.77984900000007, 31.197848000000079],
            [72.77913148500005, 31.196771727000055],
            [72.779383848000066, 31.196665753000048],
            [72.775174132000075, 31.190351179000061],
            [72.776625132000049, 31.174391179000054],
            [72.781461132000061, 31.163750179000033],
            [72.785109214000045, 31.156049231000054],
            [72.785675144000038, 31.154854577000037],
            [72.785814132000041, 31.154561179000041],
            [72.785519763000082, 31.154064490000053],
            [72.778075132000083, 31.14150317900004],
            [72.778366093000045, 31.140048856000078],
            [72.780494132000058, 31.129412179000042],
            [72.780711143000076, 31.124848659000065],
            [72.780977132000032, 31.119255179000049],
            [72.780246371000032, 31.117184416000043],
            [72.77752193200007, 31.109464155000069],
            [72.775174132000075, 31.102811179000071],
            [72.769649842000035, 31.097869374000027],
            [72.769645239000056, 31.097865256000034],
            [72.765984132000028, 31.094590179000079],
            [72.757572871000036, 31.093087680000053],
            [72.752442132000056, 31.092171179000047],
            [72.741913689000057, 31.087593677000029],
            [72.741462157000058, 31.087397363000036],
            [72.741319132000058, 31.087335179000036],
            [72.743317583000078, 31.085669612000061],
            [72.744813037000029, 31.084423257000026],
            [72.750024132000078, 31.080080179000049],
            [72.766460271000028, 31.070808600000078],
            [72.768418409000049, 31.069704020000074],
            [72.768886132000034, 31.069440179000026],
            [72.769413814000075, 31.069704020000074],
            [72.776952100000074, 31.073473163000074],
            [72.779526132000058, 31.074760179000066],
            [72.790494222000063, 31.084103086000027],
            [72.792585132000056, 31.085884179000061],
            [72.79803918500005, 31.082515363000027],
            [72.809029132000035, 31.075727179000069],
            [72.817640331000064, 31.070191771000054],
            [72.822509766000053, 31.067061626000054],
            [72.822509766000053, 31.066932922000035],
            [72.822491756000034, 31.06690672600007],
            [72.820310879000033, 31.063734615000044],
            [72.817889674000071, 31.060212945000046],
            [72.817251132000081, 31.059284179000031],
            [72.813865132000046, 31.051545179000072],
            [72.815502954000067, 31.045813366000061],
            [72.81671142600004, 31.041694697000025],
            [72.820312500000057, 31.032897949000073],
            [72.820495605000076, 31.03210449200003],
            [72.820483205000073, 31.032098517000065],
            [72.820636132000061, 31.031716179000057],
            [72.81996515700007, 31.031389746000059],
            [72.813891413000078, 31.028434842000024],
            [72.802741132000051, 31.023010179000039],
            [72.800807132000045, 31.018174179000027],
            [72.797905132000039, 31.007050179000032],
            [72.79783126600006, 31.005352486000049],
            [72.797767764000071, 31.003892988000075],
            [72.797763962000033, 31.003805602000057],
            [72.797421132000068, 30.995926179000037],
            [72.796938132000037, 30.98528617900007],
            [72.788085953000063, 30.978647731000024],
            [72.787891418000072, 30.978848742000025],
            [72.787111282000069, 30.978273392000062],
            [72.775514603000033, 30.967287064000061],
            [72.775835708000045, 30.960972000000027],
            [72.775880814000061, 30.960084915000039],
            [72.775892833000057, 30.959947898000053],
            [72.776358965000043, 30.954633989000058],
            [72.776491165000039, 30.953126907000069],
            [72.771730423000065, 30.944520950000026],
            [72.77167561300007, 30.944507506000036],
            [72.77162700000008, 30.944420000000036],
            [72.768910694000056, 30.943829318000041],
            [72.768495560000076, 30.94372749300004],
            [72.76819419900005, 30.943666458000052],
            [72.76756509300003, 30.943536707000078],
            [72.760503000000028, 30.942001000000062],
            [72.749379000000033, 30.936198000000047],
            [72.747454997000034, 30.935947013000032],
            [72.744212056000038, 30.935523970000077],
            [72.738256142000068, 30.934747019000042],
            [72.735475540000039, 30.921327591000079],
            [72.734425983000051, 30.920032683000045],
            [72.73030671600003, 30.91482902100006],
            [72.726165000000037, 30.909597000000076],
            [72.716422291000072, 30.899854291000054],
            [72.714391800000044, 30.897823800000026],
            [72.714074000000039, 30.897506000000078],
            [72.707527448000064, 30.889869108000028],
            [72.705507242000067, 30.88751243300004],
            [72.705368000000078, 30.887350000000026],
            [72.705507242000067, 30.88609682200007],
            [72.706819000000053, 30.874291000000028],
            [72.711556299000051, 30.867711781000025],
            [72.715525000000071, 30.86220000000003],
            [72.724078232000068, 30.861811728000077],
            [72.726165000000037, 30.861717000000056],
            [72.72714523500008, 30.861655688000042],
            [72.729303494000078, 30.861520692000056],
            [72.729363847000059, 30.861516917000074],
            [72.730489695000074, 30.861446497000031],
            [72.73057708500005, 30.861441031000027],
            [72.733903000000055, 30.861233000000027],
            [72.743576000000076, 30.857364000000075],
            [72.752281000000039, 30.850109000000032],
            [72.753146160000028, 30.849738303000038],
            [72.760456172000033, 30.846536978000074],
            [72.760508003000041, 30.84658395200006],
            [72.762438000000031, 30.845757000000049],
            [72.762581665000084, 30.845606147000069],
            [72.762582000000066, 30.845606000000032],
            [72.771576867000078, 30.836160865000068],
            [72.772111000000052, 30.835600000000056],
            [72.770660000000078, 30.82931300000007],
            [72.765823000000069, 30.82302500000003],
            [72.765213888000062, 30.818150844000058],
            [72.764856000000066, 30.815287000000069],
            [72.769306161000031, 30.806683561000057],
            [72.772111000000052, 30.801261000000068],
            [72.767758000000072, 30.797876000000031],
            [72.764856000000066, 30.791588000000047],
            [72.759674061000055, 30.793661061000023],
            [72.757601577000059, 30.794489769000052],
            [72.757601000000079, 30.794490000000053],
            [72.752281000000039, 30.791589000000045],
            [72.750450755000031, 30.790673799000047],
            [72.74067400000007, 30.785785000000033],
            [72.747929000000056, 30.779981000000078],
            [72.747319409000056, 30.779183917000069],
            [72.741641000000072, 30.771759000000031],
            [72.73702306000007, 30.766151452000031],
            [72.734870000000058, 30.763537000000042],
            [72.73583700000006, 30.753864000000078],
            [72.740104000000031, 30.74411200000003],
            [72.742608000000075, 30.738388000000043],
            [72.745199056000047, 30.738212297000075],
            [72.762417180000057, 30.737044711000067],
            [72.762930862000076, 30.737009877000048],
            [72.771143000000052, 30.73645300000004],
            [72.771143135000045, 30.736453059000041],
            [72.782133682000051, 30.741232026000034],
            [72.782145269000068, 30.741237064000074],
            [72.782267000000047, 30.741290000000049],
            [72.790830148000055, 30.749852305000047],
            [72.79242400000004, 30.751446000000044],
            [72.795954902000062, 30.750185075000047],
            [72.805966000000069, 30.746610000000032],
            [72.814332260000072, 30.746610000000032],
            [72.819508000000042, 30.746610000000032],
            [72.829206552000073, 30.742542469000057],
            [72.832217107000076, 30.741279855000073],
            [72.834501000000046, 30.740322000000049],
            [72.836396118000039, 30.735583814000051],
            [72.839337000000057, 30.728231000000051],
            [72.83936338400008, 30.727954189000059],
            [72.840209888000061, 30.719072889000074],
            [72.840305000000058, 30.718075000000056],
            [72.833626245000062, 30.715530932000036],
            [72.83362620500003, 30.715531046000024],
            [72.83201723500008, 30.714918098000055],
            [72.830148000000065, 30.714206000000047],
            [72.810802000000081, 30.712755000000072],
            [72.80654413700006, 30.709999973000038],
            [72.802580000000034, 30.707435000000032],
            [72.790973000000065, 30.700180000000046],
            [72.782751000000076, 30.691474000000028],
            [72.783145587000035, 30.690092895000078],
            [72.786620000000084, 30.677932000000055],
            [72.788649831000043, 30.67802075700007],
            [72.788724000000059, 30.67802400000005],
            [72.788723455000081, 30.678023520000067],
            [72.788071000000059, 30.677449000000024],
            [72.780333000000041, 30.677449000000024],
            [72.772111000000052, 30.683253000000036],
            [72.764373000000035, 30.681802000000062],
            [72.758605162000038, 30.674592203000032],
            [72.75856900000008, 30.674547000000075],
            [72.759536000000082, 30.665841000000057],
            [72.765823000000069, 30.661005000000046],
            [72.775496000000032, 30.661005000000046],
            [72.788064420000069, 30.661005000000046],
            [72.788071000000059, 30.661005000000046],
            [72.786560188000067, 30.656221110000047],
            [72.785169000000053, 30.651816000000053],
            [72.777914000000067, 30.64649600000007],
            [72.77666226000008, 30.645034990000056],
            [72.775055409000061, 30.643159499000035],
            [72.775013000000058, 30.643110000000036],
            [72.779288908000069, 30.637258488000043],
            [72.784159079000062, 30.630593737000027],
            [72.78420200000005, 30.630535000000066],
            [72.772111000000052, 30.627150000000029],
            [72.766382724000039, 30.625717931000054],
            [72.766332644000045, 30.625705411000069],
            [72.767297272000064, 30.608847937000064],
            [72.767272222000031, 30.608803120000061],
            [72.767274000000043, 30.608772000000044],
            [72.766973175000032, 30.608230440000057],
            [72.762438000000031, 30.600066000000027],
            [72.755145626000058, 30.598480873000028],
            [72.754728979000049, 30.598390307000045],
            [72.751314000000036, 30.597648000000049],
            [72.73932138300006, 30.597648000000049],
            [72.738739000000066, 30.597648000000049],
            [72.736179408000055, 30.601487058000032],
            [72.734870000000058, 30.603451000000064],
            [72.732828638000058, 30.600274974000058],
            [72.730558819000066, 30.596743507000042],
            [72.730518000000075, 30.596680000000049],
            [72.732201716000077, 30.592831080000053],
            [72.733903000000055, 30.588942000000031],
            [72.738700859000062, 30.581745212000044],
            [72.738739000000066, 30.581688000000042],
            [72.731001000000049, 30.579269000000068],
            [72.724666337000031, 30.579269000000068],
            [72.72466373900005, 30.579349670000056],
            [72.72466098600006, 30.579349672000035],
            [72.721845865000034, 30.579351786000075],
            [72.713142248000054, 30.57644971600007],
            [72.710086402000059, 30.576450138000041],
            [72.700571668000066, 30.576451841000051],
            [72.696558870000047, 30.576601346000075],
            [72.69656408600008, 30.576514061000069],
            [72.687473000000068, 30.576851000000033],
            [72.682978510000055, 30.576614268000071],
            [72.681320021000033, 30.576526912000077],
            [72.678284000000076, 30.576367000000062],
            [72.666193000000078, 30.574433000000056],
            [72.669579000000056, 30.587975000000029],
            [72.666994159000069, 30.589525798000068],
            [72.664742000000047, 30.590877000000035],
            [72.659424093000041, 30.58362485400005],
            [72.659422000000063, 30.583622000000048],
            [72.658561070000076, 30.581900141000062],
            [72.65559817500008, 30.575974349000035],
            [72.655553000000054, 30.57588400000003],
            [72.650233000000071, 30.571047000000078],
            [72.644524818000036, 30.573584407000055],
            [72.641527000000053, 30.574917000000028],
            [72.635982865000074, 30.576500571000054],
            [72.635986869000078, 30.576611433000039],
            [72.634808319000058, 30.576948737000066],
            [72.633944246000056, 30.576898607000032],
            [72.633943038000041, 30.576897088000067],
            [72.618363841000075, 30.575983397000073],
            [72.615756095000052, 30.578592320000041],
            [72.615651090000028, 30.578545233000057],
            [72.610091000000068, 30.584106000000077],
            [72.607672000000036, 30.590393000000063],
            [72.60094828900003, 30.582709043000079],
            [72.600901000000079, 30.582655000000045],
            [72.600429903000077, 30.576050869000028],
            [72.600418000000047, 30.57588400000003],
            [72.597553862000041, 30.580589461000045],
            [72.593647000000033, 30.587008000000026],
            [72.592196000000058, 30.595230000000072],
            [72.587359000000049, 30.603935000000035],
            [72.585375336000084, 30.605918938000059],
            [72.58010500000006, 30.611190000000079],
            [72.571883000000071, 30.614575000000059],
            [72.568415193000078, 30.612648706000073],
            [72.565808779000065, 30.61120089800005],
            [72.563177000000053, 30.609739000000047],
            [72.557865575000051, 30.608061586000076],
            [72.553988000000061, 30.606837000000041],
            [72.546164292000071, 30.607192890000078],
            [72.543348000000037, 30.60732100000007],
            [72.53588841800007, 30.603591209000058],
            [72.53282886900007, 30.602061435000053],
            [72.532824508000033, 30.602059254000039],
            [72.529806000000065, 30.600550000000055],
            [72.520133000000044, 30.60200100000003],
            [72.517300266000063, 30.597279451000077],
            [72.526946314000043, 30.593901432000052],
            [72.526947090000078, 30.581926993000025],
            [72.526947098000051, 30.581805807000023],
            [72.526904000000059, 30.58178857200005],
            [72.526904000000059, 30.581688000000042],
            [72.522726305000049, 30.580016922000027],
            [72.519649000000072, 30.578786000000036],
            [72.507558000000074, 30.578786000000036],
            [72.499345539000046, 30.578302562000033],
            [72.497725119000052, 30.571168904000046],
            [72.496979855000063, 30.567887992000067],
            [72.496974194000074, 30.567909271000076],
            [72.49697400000008, 30.56791000000004],
            [72.496774656000071, 30.568101931000058],
            [72.496361119000085, 30.568479120000063],
            [72.495927791000042, 30.568881508000061],
            [72.495423692000031, 30.569349613000043],
            [72.493940192000082, 30.570727190000071],
            [72.49171078300003, 30.572797415000025],
            [72.48640000000006, 30.577729000000033],
            [72.480952878000039, 30.579842978000045],
            [72.480831389000059, 30.580814888000077],
            [72.480200000000082, 30.585866000000067],
            [72.475550000000055, 30.593616000000054],
            [72.468052744000033, 30.59621157600003],
            [72.466030275000037, 30.596911762000047],
            [72.465701651000074, 30.597025533000078],
            [72.46545406000007, 30.597081328000058],
            [72.465139365000084, 30.597150832000068],
            [72.448812000000032, 30.599429000000043],
            [72.448795801000074, 30.599419278000028],
            [72.449154060000069, 30.600681328000064],
            [72.447187464000081, 30.599190521000025],
            [72.442954060000034, 30.59598132800005],
            [72.434854060000077, 30.589381328000059],
            [72.433159487000069, 30.589514609000048],
            [72.431644701000039, 30.589633750000075],
            [72.425954060000038, 30.590081328000053],
            [72.422454060000064, 30.587781328000062],
            [72.417854060000082, 30.587381328000049],
            [72.414454060000082, 30.585781328000053],
            [72.406554060000076, 30.583881328000075],
            [72.402854060000038, 30.579481328000043],
            [72.401768398000058, 30.578221960000064],
            [72.400354060000041, 30.576581328000032],
            [72.397763604000033, 30.574950301000058],
            [72.392254060000084, 30.571481328000061],
            [72.39203860300006, 30.571405627000047],
            [72.388554060000047, 30.570181328000047],
            [72.384754060000034, 30.568881328000032],
            [72.381300753000062, 30.565959299000042],
            [72.378272074000051, 30.563396570000066],
            [72.378254060000074, 30.563381328000048],
            [72.377187794000065, 30.563830282000026],
            [72.37703854800003, 30.563893122000025],
            [72.374454060000062, 30.564981328000044],
            [72.370154060000061, 30.568081328000062],
            [72.366661000000079, 30.571529000000055],
            [72.36511100000007, 30.568429000000037],
            [72.36355406000007, 30.558381328000053],
            [72.363408567000079, 30.558461813000065],
            [72.363465430000076, 30.558391225000037],
            [72.359115711000072, 30.560130963000063],
            [72.358854060000056, 30.560981328000025],
            [72.357760573000064, 30.564659421000044],
            [72.357754060000048, 30.564681328000063],
            [72.356624672000066, 30.569669459000067],
            [72.356554060000065, 30.56998132800004],
            [72.354261000000065, 30.576954000000057],
            [72.351321925000036, 30.576534132000063],
            [72.348836000000063, 30.576179000000025],
            [72.342248000000041, 30.571916000000044],
            [72.339536000000066, 30.566491000000042],
            [72.33612319000008, 30.561503203000029],
            [72.334555335000061, 30.559228277000045],
            [72.334495004000075, 30.559140736000074],
            [72.334445191000043, 30.559352457000045],
            [72.332948000000044, 30.565716000000066],
            [72.328800876000059, 30.565716000000066],
            [72.327910000000031, 30.565716000000066],
            [72.320935000000077, 30.563003000000037],
            [72.315038271000049, 30.558714470000041],
            [72.314790867000056, 30.558534540000039],
            [72.312410000000057, 30.556803000000059],
            [72.306598000000065, 30.55641600000007],
            [72.306586397000046, 30.556383520000054],
            [72.304660000000069, 30.550991000000067],
            [72.306598000000065, 30.540528000000052],
            [72.306222719000061, 30.533032055000035],
            [72.306254060000072, 30.532781328000056],
            [72.305945828000063, 30.532393795000075],
            [72.301544292000074, 30.526859844000057],
            [72.301543312000035, 30.526858612000069],
            [72.298072000000047, 30.52929000000006],
            [72.298204712000029, 30.533398952000027],
            [72.298242464000054, 30.534567812000034],
            [72.298299402000055, 30.536330692000035],
            [72.298459769000033, 30.541295861000037],
            [72.299554060000048, 30.544081328000061],
            [72.30022241100005, 30.545835749000048],
            [72.301154060000044, 30.54828132800003],
            [72.30094226600005, 30.548373747000028],
            [72.295654060000061, 30.550681328000053],
            [72.291733415000067, 30.551060745000029],
            [72.289725917000055, 30.551255019000052],
            [72.289454060000082, 30.551281328000073],
            [72.284096997000063, 30.547944141000073],
            [72.283354060000079, 30.54748132800006],
            [72.280254060000061, 30.547281328000054],
            [72.271335000000079, 30.54672800000003],
            [72.266669833000037, 30.547371443000031],
            [72.26016351100003, 30.548268826000026],
            [72.26009700000003, 30.548278000000039],
            [72.259354060000078, 30.554081328000052],
            [72.253821443000049, 30.558956605000049],
            [72.249254060000055, 30.562981328000035],
            [72.249120164000033, 30.563083720000066],
            [72.246656657000074, 30.564651510000033],
            [72.244984000000045, 30.565716000000066],
            [72.24692200000004, 30.551378000000057],
            [72.246456636000062, 30.551537975000031],
            [72.238916545000052, 30.55412997600007],
            [72.238887936000083, 30.554139811000027],
            [72.237042859000042, 30.554774079000026],
            [72.23455406000005, 30.555681328000048],
            [72.232754060000048, 30.558681328000034],
            [72.232720933000053, 30.558687569000028],
            [72.22948400000007, 30.564166000000057],
            [72.228404417000036, 30.563335634000055],
            [72.228237356000079, 30.563774168000066],
            [72.227442355000051, 30.562595660000056],
            [72.225740611000049, 30.561286756000072],
            [72.225451023000062, 30.561064018000025],
            [72.224592937000068, 30.560459820000062],
            [72.221277752000049, 30.559509277000075],
            [72.218287029000066, 30.559509277000075],
            [72.215904371000079, 30.561634725000033],
            [72.215015194000046, 30.562460308000027],
            [72.213573376000056, 30.563763864000066],
            [72.213554060000035, 30.563781328000061],
            [72.210454060000075, 30.561881328000027],
            [72.209769711000035, 30.560751743000026],
            [72.209732469000073, 30.560749239000074],
            [72.208669928000063, 30.559155351000072],
            [72.207500056000072, 30.557400458000075],
            [72.207106016000068, 30.556809370000053],
            [72.207097773000044, 30.556797005000078],
            [72.206392922000077, 30.555739679000055],
            [72.205991034000078, 30.555136818000051],
            [72.205040930000052, 30.55371159300006],
            [72.203954060000058, 30.552681328000062],
            [72.202446197000029, 30.550980035000066],
            [72.201303722000034, 30.548883563000061],
            [72.199832035000043, 30.547227914000075],
            [72.19856358100003, 30.545800903000043],
            [72.197353836000048, 30.544439940000075],
            [72.197321000000045, 30.553015425000069],
            [72.197318278000068, 30.56106740000007],
            [72.197318335000034, 30.561081328000057],
            [72.197195491000059, 30.56114502500003],
            [72.194633328000066, 30.562473554000064],
            [72.194654060000062, 30.562481328000047],
            [72.183754060000069, 30.568081328000062],
            [72.181554060000053, 30.568081328000062],
            [72.178986634000069, 30.568081328000062],
            [72.170554060000029, 30.568081328000062],
            [72.164054060000069, 30.570981328000073],
            [72.164043254000035, 30.570971463000035],
            [72.160156106000045, 30.57272542000004],
            [72.155890910000039, 30.574649960000045],
            [72.157829924000055, 30.576920648000055],
            [72.163490910000064, 30.583549960000028],
            [72.16556382300007, 30.587523043000033],
            [72.165863538000053, 30.588097497000035],
            [72.165933000000052, 30.588191000000052],
            [72.166272701000082, 30.588760826000055],
            [72.173460307000084, 30.600260995000042],
            [72.174890910000045, 30.602549960000033],
            [72.176686424000081, 30.605909308000037],
            [72.177990910000062, 30.608349960000055],
            [72.182958654000061, 30.618645429000026],
            [72.184921000000031, 30.622679000000062],
            [72.184912502000032, 30.622719055000061],
            [72.185390910000081, 30.624249960000043],
            [72.184720232000075, 30.626597335000042],
            [72.18227132800007, 30.635168497000052],
            [72.182190910000031, 30.635449960000074],
            [72.18248013200008, 30.635779970000044],
            [72.182480172000055, 30.635780015000023],
            [72.189990910000063, 30.644349960000056],
            [72.192249901000082, 30.646213628000055],
            [72.193990910000082, 30.647649960000024],
            [72.199290910000059, 30.656349960000057],
            [72.206529001000035, 30.665732670000068],
            [72.207248999000058, 30.666666001000067],
            [72.207396000000074, 30.666855000000055],
            [72.208022310000047, 30.667968440000038],
            [72.214390910000077, 30.67924996000005],
            [72.222290910000083, 30.690349960000049],
            [72.222798924000074, 30.691894737000041],
            [72.224798918000033, 30.697976352000069],
            [72.227190910000047, 30.70524996000006],
            [72.228814637000085, 30.70801984700006],
            [72.23262455400004, 30.714467399000057],
            [72.237234000000058, 30.722268000000042],
            [72.23735432400008, 30.722587548000035],
            [72.237375072000077, 30.722642650000068],
            [72.237538536000045, 30.723076768000055],
            [72.242300833000058, 30.725457917000028],
            [72.250264242000071, 30.724661805000039],
            [72.259665000000041, 30.723722000000066],
            [72.279923000000053, 30.716197000000079],
            [72.302496370000028, 30.724299774000031],
            [72.312915000000032, 30.717934000000071],
            [72.322755000000029, 30.72082800000004],
            [72.323002741000039, 30.720633335000059],
            [72.330858000000035, 30.714461000000028],
            [72.343013000000042, 30.713882000000069],
            [72.35690500000004, 30.720249000000024],
            [72.364331824000033, 30.73567515700006],
            [72.36442900000003, 30.735877000000073],
            [72.354011000000071, 30.738192000000026],
            [72.350538000000029, 30.745137000000057],
            [72.354011000000071, 30.75555600000007],
            [72.369639000000063, 30.756714000000045],
            [72.361621737000064, 30.762503604000074],
            [72.361621468000067, 30.76250307600003],
            [72.359219000000053, 30.764238000000034],
            [72.353062574000035, 30.771843585000056],
            [72.353062756000043, 30.77184382400003],
            [72.349380000000053, 30.776393000000041],
            [72.345907000000068, 30.782181000000037],
            [72.345937212000081, 30.782332006000047],
            [72.348223000000075, 30.793757000000028],
            [72.349380000000053, 30.804176000000041],
            [72.348853161000079, 30.806178222000028],
            [72.346472245000029, 30.815226758000051],
            [72.346060364000039, 30.816791666000029],
            [72.344715072000042, 30.821902986000055],
            [72.344260978000079, 30.823628275000033],
            [72.34426047900007, 30.823630173000026],
            [72.344141476000061, 30.824082316000045],
            [72.343592000000058, 30.826170000000047],
            [72.343108289000043, 30.824850861000073],
            [72.339050783000062, 30.813785854000059],
            [72.338467183000034, 30.812194076000026],
            [72.337225000000046, 30.808806000000061],
            [72.329701000000057, 30.808806000000061],
            [72.329700000000059, 30.808806000000061],
            [72.31754600000005, 30.808806000000061],
            [72.316926541000043, 30.809487607000051],
            [72.30979754100008, 30.817329676000043],
            [72.300182000000063, 30.827907000000039],
            [72.294008279000082, 30.832957874000044],
            [72.28744800000004, 30.838325000000054],
            [72.287447428000064, 30.838324650000061],
            [72.287447000000043, 30.838325000000054],
            [72.277029000000084, 30.83195900000004],
            [72.274544097000046, 30.834133425000061],
            [72.267769000000044, 30.840062000000046],
            [72.266866126000082, 30.840265961000057],
            [72.257910467000045, 30.842288348000068],
            [72.249826000000041, 30.844114000000047],
            [72.253728408000029, 30.86145958000003],
            [72.255035000000078, 30.867266000000029],
            [72.263552437000044, 30.876594316000023],
            [72.26621269900005, 30.879507842000066],
            [72.266212125000038, 30.879508140000041],
            [72.26718900000003, 30.880578000000071],
            [72.270892262000075, 30.883046920000027],
            [72.270892358000083, 30.883046634000038],
            [72.274989700000049, 30.885778633000029],
            [72.277594744000055, 30.887515607000068],
            [72.28281700000008, 30.890997000000027],
            [72.299024000000031, 30.895627000000047],
            [72.299497638000048, 30.895610690000069],
            [72.307122219000064, 30.895348134000074],
            [72.315809000000058, 30.895049000000029],
            [72.315995672000042, 30.898034669000026],
            [72.316504975000043, 30.906180717000041],
            [72.316967000000034, 30.91357000000005],
            [72.324784621000049, 30.912813955000047],
            [72.334910000000036, 30.911834000000056],
            [72.334910000000036, 30.919131579000066],
            [72.334910000000036, 30.921095000000037],
            [72.335792986000058, 30.921978052000043],
            [72.341277000000048, 30.927462000000048],
            [72.345156105000058, 30.93134073300007],
            [72.351696000000061, 30.937880000000064],
            [72.353684864000058, 30.940773531000048],
            [72.355615562000082, 30.943582139000057],
            [72.358062000000075, 30.947141000000045],
            [72.358062036000035, 30.954310703000033],
            [72.358062062000045, 30.959339016000058],
            [72.358062000000075, 30.972030000000075],
            [72.368530555000063, 30.978012363000062],
            [72.369229980000057, 30.97841199800007],
            [72.369992165000042, 30.978847493000046],
            [72.374269000000083, 30.981291000000056],
            [72.374720775000071, 30.98273671100003],
            [72.377163000000053, 30.990552000000037],
            [72.378121074000035, 30.99486354000004],
            [72.379478000000063, 31.000970000000052],
            [72.379837767000083, 31.007443945000034],
            [72.380057000000079, 31.011389000000065],
            [72.385845000000074, 31.022965000000056],
            [72.395106000000055, 31.028174000000035],
            [72.395474458000081, 31.028302994000057],
            [72.406682000000046, 31.032226000000037],
            [72.409733562000042, 31.034362041000065],
            [72.412132342000064, 31.03604114500007],
            [72.418258000000037, 31.040329000000042],
            [72.425086075000081, 31.043140837000067],
            [72.428098000000034, 31.044381000000044],
            [72.437916905000066, 31.051888870000028],
            [72.437938000000031, 31.051905000000033],
            [72.439669031000051, 31.064602550000075],
            [72.439674000000082, 31.064639000000057],
            [72.439176795000037, 31.06911679500007],
            [72.438517000000047, 31.07505800000007],
            [72.436780000000056, 31.082582000000059],
            [72.436994000000084, 31.083917000000042],
            [72.439095000000066, 31.097052000000076],
            [72.440962196000044, 31.106851754000047],
            [72.441411000000073, 31.109207000000026],
            [72.452091842000073, 31.113091629000053],
            [72.452100673000075, 31.113091110000028],
            [72.454116206000037, 31.11382410300007],
            [72.465692206000028, 31.11382410300007],
            [72.465699850000078, 31.113838000000044],
            [72.465720000000033, 31.113838000000044],
            [72.469439349000083, 31.120600595000042],
            [72.472078472000078, 31.125398497000049],
            [72.47900420600007, 31.124821103000045],
            [72.479721206000079, 31.124897103000023],
            [72.479917714000067, 31.124668977000056],
            [72.486865105000049, 31.125400139000078],
            [72.488976926000078, 31.125622393000071],
            [72.490191132000064, 31.125750179000079],
            [72.490340588000038, 31.127995114000043],
            [72.490769132000082, 31.134432179000044],
            [72.490602340000066, 31.134575135000034],
            [72.490609000000063, 31.134675000000072],
            [72.489091272000053, 31.135975830000064],
            [72.482506000000058, 31.141620000000046],
            [72.475721250000049, 31.15179712500003],
            [72.476878132000081, 31.161057179000068],
            [72.484971126000062, 31.160517460000051],
            [72.485560132000046, 31.160478179000052],
            [72.494531825000081, 31.161701976000074],
            [72.498294132000069, 31.162215179000043],
            [72.502924132000032, 31.170897179000065],
            [72.503503132000048, 31.181315179000023],
            [72.503331492000029, 31.181350938000037],
            [72.503343000000029, 31.181558000000052],
            [72.503342469000074, 31.181558111000072],
            [72.489451000000031, 31.184452000000078],
            [72.488987814000041, 31.185007877000032],
            [72.486737029000039, 31.187708954000072],
            [72.486718096000061, 31.187731675000066],
            [72.486557000000062, 31.187925000000064],
            [72.486718096000061, 31.188153237000051],
            [72.48798182400003, 31.189943653000057],
            [72.488726667000037, 31.19099888900007],
            [72.490235159000065, 31.193135922000067],
            [72.490266336000047, 31.193180088000076],
            [72.490687943000069, 31.193777346000047],
            [72.493502117000048, 31.197763749000046],
            [72.493503000000032, 31.197765000000061],
            [72.493502551000063, 31.197765673000049],
            [72.493499185000076, 31.19777072100004],
            [72.492764319000059, 31.198872914000049],
            [72.490191241000048, 31.202732161000029],
            [72.490030000000047, 31.20297400000004],
            [72.490541138000083, 31.207573111000045],
            [72.491188000000079, 31.213393000000053],
            [72.498951109000075, 31.218938215000037],
            [72.499291000000028, 31.219181000000049],
            [72.500585998000076, 31.218938215000037],
            [72.505888860000084, 31.217944040000077],
            [72.507766791000051, 31.217591967000033],
            [72.511382963000074, 31.216914011000028],
            [72.515744731000041, 31.216095961000065],
            [72.517813000000046, 31.215708000000063],
            [72.518075793000037, 31.215792087000068],
            [72.518025376000082, 31.215481895000039],
            [72.532443132000083, 31.220095179000054],
            [72.552123132000077, 31.222410179000065],
            [72.552225004000036, 31.222601934000068],
            [72.553965948000041, 31.225878943000055],
            [72.561943057000065, 31.24089439100004],
            [72.561963132000074, 31.240932179000026],
            [72.575275132000058, 31.24614217900006],
            [72.589745132000076, 31.235723179000047],
            [72.590427419000036, 31.235889131000079],
            [72.594576816000028, 31.236898386000064],
            [72.611161132000063, 31.240932179000026],
            [72.612242442000081, 31.240886190000026],
            [72.636747056000047, 31.239843997000037],
            [72.638365132000047, 31.239775179000048],
            [72.63838964100006, 31.239772950000031],
            [72.65109913200007, 31.238617179000073],
            [72.656705567000074, 31.237059851000026],
            [72.657224513000074, 31.236915701000044],
            [72.657146350000062, 31.237136188000079],
            [72.659433806000038, 31.236500857000067],
            [72.659433842000055, 31.236500847000059],
            [72.662939667000046, 31.235526873000026],
            [72.66266068200008, 31.235405668000055],
            [72.67106209800005, 31.233071964000032],
            [72.671936132000042, 31.232829179000078],
            [72.674364541000045, 31.233071964000032],
            [72.689300132000028, 31.234565179000072],
            [72.691673035000065, 31.237247506000074],
            [72.695761726000057, 31.241869357000041],
            [72.700396478000073, 31.247108476000051],
            [72.700734967000074, 31.247184274000062],
            [72.70039838100007, 31.24711062700004],
            [72.702613132000067, 31.249614179000048],
            [72.728080132000059, 31.260612179000077],
            [72.739050845000065, 31.26580849100003],
            [72.74176093300008, 31.267092133000062],
            [72.741884847000051, 31.267150825000044],
            [72.750075132000063, 31.271030179000036],
            [72.75461828400006, 31.275573331000032],
            [72.761651132000054, 31.282606179000027],
            [72.765466543000059, 31.287239480000039],
            [72.775772435000079, 31.299754596000071],
            [72.77548461300006, 31.299842933000036],
            [72.777697000000046, 31.30252900000005],
            [72.796798000000081, 31.318157000000042],
            [72.802163218000032, 31.323905353000043],
            [72.809900964000065, 31.332195659000035],
            [72.809902262000037, 31.332197050000048],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "124",
      properties: { name: "Vehari", count: 1368 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.847290014000066, 30.370186755000077],
            [72.847900391000053, 30.36749267600004],
            [72.848083496000072, 30.366516113000046],
            [72.846679687000062, 30.356872559000067],
            [72.845092773000033, 30.352294922000056],
            [72.843690000000038, 30.348086601000034],
            [72.843688965000069, 30.348083496000072],
            [72.843688965000069, 30.337524414000029],
            [72.843690000000038, 30.33752018000007],
            [72.845703125000057, 30.329284668000071],
            [72.847104281000043, 30.322888086000034],
            [72.847106934000067, 30.322875977000024],
            [72.847236379000037, 30.322650278000026],
            [72.84949400000005, 30.318586000000039],
            [72.85287900000003, 30.312298000000055],
            [72.861101000000076, 30.312782000000027],
            [72.866489923000074, 30.313611230000049],
            [72.866580138000074, 30.313625112000068],
            [72.866545313000074, 30.313692360000061],
            [72.869520139000031, 30.314109123000037],
            [72.871814270000073, 30.314430523000055],
            [72.873712512000054, 30.31469646000005],
            [72.881414000000063, 30.31036400000005],
            [72.890603000000056, 30.308913000000075],
            [72.890604839000048, 30.308913000000075],
            [72.890686035000044, 30.30889892600004],
            [72.891575939000063, 30.30889892600004],
            [72.891579909000029, 30.308913000000075],
            [72.898661736000065, 30.308913000000075],
            [72.898666153000079, 30.30889892600004],
            [72.903320312000062, 30.30889892600004],
            [72.913879395000038, 30.30889892600004],
            [72.921494539000037, 30.30889892600004],
            [72.923889160000044, 30.30889892600004],
            [72.925401349000083, 30.308556321000026],
            [72.931701660000044, 30.307128906000059],
            [72.934692383000083, 30.298278809000067],
            [72.940242771000044, 30.294159380000053],
            [72.940270306000059, 30.294138944000053],
            [72.942480130000035, 30.292498840000064],
            [72.942504883000083, 30.292480469000054],
            [72.950683594000054, 30.292480469000054],
            [72.962707520000038, 30.292480469000054],
            [72.973327637000068, 30.292480469000054],
            [72.980529785000044, 30.296081543000071],
            [72.986511230000076, 30.298889160000044],
            [72.992574326000067, 30.296367874000055],
            [72.992652088000057, 30.296335537000061],
            [72.992675781000059, 30.296325684000067],
            [72.993103027000075, 30.290283203000058],
            [72.993286133000083, 30.282897949000073],
            [72.992406982000034, 30.280919861000029],
            [72.992309570000032, 30.280700684000067],
            [72.990295410000044, 30.276123047000056],
            [72.990264791000072, 30.276096841000026],
            [72.990234000000044, 30.276025000000061],
            [72.986698938000075, 30.272995320000064],
            [72.983463000000029, 30.270222000000047],
            [72.977075310000032, 30.268688870000062],
            [72.971372000000031, 30.267320000000041],
            [72.965823768000064, 30.262388026000053],
            [72.962707520000038, 30.259704590000069],
            [72.961493978000078, 30.257512785000074],
            [72.956726074000073, 30.24890136700003],
            [72.95506646900003, 30.246043157000031],
            [72.954528809000067, 30.245117187000062],
            [72.95412940500006, 30.231651549000048],
            [72.954101563000052, 30.230712891000053],
            [72.958923340000069, 30.223327637000068],
            [72.958923340000069, 30.217529297000056],
            [72.959716797000056, 30.213500977000024],
            [72.959950577000029, 30.211747628000069],
            [72.960327148000033, 30.208923340000069],
            [72.960327148000033, 30.199111592000065],
            [72.960327148000033, 30.197692871000072],
            [72.959976198000049, 30.188167069000031],
            [72.959899902000075, 30.186096191000047],
            [72.951992325000049, 30.181566608000026],
            [72.951987686000052, 30.181581189000042],
            [72.951946529000054, 30.181557442000042],
            [72.947852923000084, 30.179195495000045],
            [72.947326660000044, 30.178894043000071],
            [72.947326199000031, 30.178891583000052],
            [72.947227523000038, 30.178834649000066],
            [72.947190000000035, 30.178813000000048],
            [72.946218283000064, 30.172982697000066],
            [72.945678711000085, 30.170104980000076],
            [72.945678711000085, 30.16520003200003],
            [72.945678711000085, 30.160095215000069],
            [72.946068268000033, 30.158490237000024],
            [72.946227578000048, 30.157833881000045],
            [72.948641000000066, 30.147376000000065],
            [72.947446132000039, 30.141601356000024],
            [72.947292731000061, 30.140859990000024],
            [72.947213255000065, 30.140475894000076],
            [72.946022830000061, 30.134722715000066],
            [72.945678711000085, 30.133483887000068],
            [72.946237480000036, 30.128224886000055],
            [72.946473837000042, 30.126000354000041],
            [72.946706000000063, 30.123678000000041],
            [72.946706000000063, 30.120581918000028],
            [72.946706000000063, 30.113246951000065],
            [72.946706000000063, 30.109168000000068],
            [72.946401413000046, 30.102776712000036],
            [72.946224103000077, 30.09905613400008],
            [72.943418125000051, 30.096250157000043],
            [72.939880371000072, 30.092712402000075],
            [72.937566500000059, 30.090065177000042],
            [72.93530614000008, 30.08747917200003],
            [72.932680000000062, 30.084474689000047],
            [72.932678223000039, 30.084472656000059],
            [72.932678223000039, 30.077880859000061],
            [72.932680000000062, 30.077872418000027],
            [72.932922363000046, 30.076721191000047],
            [72.933715820000032, 30.068481445000032],
            [72.936691997000082, 30.062758028000076],
            [72.938405586000044, 30.059501996000051],
            [72.938484000000074, 30.059353000000044],
            [72.93894507300007, 30.058488532000069],
            [72.942353000000082, 30.052099000000055],
            [72.942107342000043, 30.051674640000044],
            [72.937033000000042, 30.042909000000066],
            [72.936844447000055, 30.042248871000027],
            [72.935099000000037, 30.036138000000051],
            [72.930262000000084, 30.030335000000036],
            [72.930471336000039, 30.02907854800003],
            [72.93122900000003, 30.024531000000025],
            [72.93126992100008, 30.024501778000058],
            [72.932323560000043, 30.023749356000053],
            [72.932320450000077, 30.023746609000057],
            [72.932601309000063, 30.023551011000052],
            [72.934623009000063, 30.022143041000049],
            [72.934692383000083, 30.022094727000024],
            [72.935784259000059, 30.026498630000049],
            [72.93654900000007, 30.029367000000036],
            [72.943320000000028, 30.033237000000042],
            [72.944778748000033, 30.033237000000042],
            [72.949608000000069, 30.033237000000042],
            [72.959281000000033, 30.033237000000042],
            [72.961312488000033, 30.032559760000026],
            [72.967986000000053, 30.030335000000036],
            [72.97125384800006, 30.029766564000056],
            [72.971414777000064, 30.029738571000053],
            [72.971679688000052, 30.029724121000072],
            [72.979125977000081, 30.028503418000071],
            [72.984183829000074, 30.024710030000051],
            [72.984430000000032, 30.024525401000062],
            [72.984497070000032, 30.024475098000039],
            [72.984497070000032, 30.020324707000043],
            [72.984430000000032, 30.020255917000043],
            [72.982116699000073, 30.01788330100004],
            [72.973209705000045, 30.017319567000072],
            [72.972473145000038, 30.017272949000073],
            [72.971496582000043, 30.010314941000047],
            [72.970515621000061, 30.006938609000031],
            [72.968872070000032, 30.001281738000046],
            [72.968608533000065, 30.000064017000057],
            [72.968027426000049, 29.997378900000058],
            [72.96710205100004, 29.993103027000075],
            [72.96710205100004, 29.988525391000053],
            [72.96710205100004, 29.981079102000024],
            [72.968652556000052, 29.978032815000063],
            [72.972197723000079, 29.971067603000051],
            [72.971721323000054, 29.970182779000027],
            [72.968954000000053, 29.965043000000037],
            [72.96266600000007, 29.960690000000056],
            [72.955347983000081, 29.960690000000056],
            [72.952993000000049, 29.960690000000056],
            [72.946222000000034, 29.960690000000056],
            [72.939451000000076, 29.968428000000074],
            [72.934615000000065, 29.979069000000038],
            [72.930746000000056, 29.986323000000027],
            [72.928870159000041, 29.989323881000075],
            [72.927768275000062, 29.991086622000068],
            [72.925909000000047, 29.994061000000045],
            [72.917687000000058, 29.99357800000007],
            [72.917321266000044, 29.993760848000079],
            [72.908014000000037, 29.998414000000025],
            [72.900759472000061, 30.00131581100004],
            [72.893989000000033, 29.998898000000054],
            [72.88866900000005, 29.991160000000036],
            [72.882865000000038, 29.983421000000078],
            [72.880447000000061, 29.97568300100005],
            [72.873676000000046, 29.971330000000023],
            [72.867872000000034, 29.967945000000043],
            [72.863519000000053, 29.960206000000028],
            [72.860134000000073, 29.954886000000045],
            [72.860134000000073, 29.945697000000052],
            [72.861056000000076, 29.934631000000024],
            [72.861101000000076, 29.934090000000026],
            [72.860134000000073, 29.924417000000062],
            [72.855781000000036, 29.931188000000077],
            [72.854991731000041, 29.932120732000044],
            [72.852758320000078, 29.934760103000031],
            [72.852623159000075, 29.934919832000048],
            [72.850461000000053, 29.937475000000063],
            [72.847413391000032, 29.942961452000077],
            [72.845625000000041, 29.946181000000024],
            [72.845270097000082, 29.947127325000054],
            [72.84127200000006, 29.95778800000005],
            [72.838370000000054, 29.967945000000043],
            [72.83032047100005, 29.974062536000076],
            [72.828458366000064, 29.975477711000053],
            [72.826279000000056, 29.977134000000035],
            [72.819508000000042, 29.980519000000072],
            [72.808384000000046, 29.980036000000041],
            [72.807629948000056, 29.980107794000048],
            [72.804563266000059, 29.980399790000035],
            [72.798228000000051, 29.981003000000044],
            [72.798227488000066, 29.981002953000029],
            [72.792615856000054, 29.980492949000052],
            [72.787588000000085, 29.980036000000041],
            [72.78493796500004, 29.97659101000005],
            [72.784619135000071, 29.976176537000072],
            [72.782898814000077, 29.973940155000037],
            [72.782751000000076, 29.973748000000057],
            [72.772595000000081, 29.966977000000043],
            [72.767515586000059, 29.961897586000077],
            [72.767275000000041, 29.961657000000059],
            [72.767758000000072, 29.955854000000045],
            [72.77162700000008, 29.950050000000033],
            [72.772252318000028, 29.946924058000036],
            [72.773562000000084, 29.940377000000069],
            [72.773562000000084, 29.938650224000071],
            [72.773562000000084, 29.938649704000056],
            [72.773562000000084, 29.932155000000023],
            [72.768242000000043, 29.926351000000068],
            [72.767221004000078, 29.925228053000069],
            [72.763405000000034, 29.921031000000028],
            [72.762438108000083, 29.912809917000061],
            [72.762438000000031, 29.912809000000038],
            [72.770660000000078, 29.906522000000052],
            [72.773562000000084, 29.898784000000035],
            [72.770660000000078, 29.892496000000051],
            [72.761471000000029, 29.892496000000051],
            [72.760802086000069, 29.892496000000051],
            [72.751798000000065, 29.892496000000051],
            [72.747929000000056, 29.898784000000035],
            [72.742609000000073, 29.908457000000055],
            [72.739223000000038, 29.916195000000073],
            [72.73245200000008, 29.923450000000059],
            [72.72713200000004, 29.923450000000059],
            [72.718427000000077, 29.922966000000031],
            [72.717957226000067, 29.922939936000034],
            [72.713903000000073, 29.922715000000039],
            [72.709721000000059, 29.922482000000059],
            [72.703434000000072, 29.914744000000042],
            [72.694244000000083, 29.910391000000061],
            [72.692794000000049, 29.902653000000043],
            [72.694739291000076, 29.893202966000047],
            [72.695944052000073, 29.887350354000034],
            [72.696179000000029, 29.886209000000065],
            [72.693829952000044, 29.878574140000069],
            [72.692310000000077, 29.873634000000038],
            [72.689408000000071, 29.872184000000061],
            [72.686704012000064, 29.868127669000046],
            [72.685539000000063, 29.866380000000049],
            [72.683604000000059, 29.86154300000004],
            [72.683604000000059, 29.855740000000026],
            [72.681401379000079, 29.856841026000041],
            [72.679735000000051, 29.857674000000031],
            [72.672964000000036, 29.863478000000043],
            [72.665226000000075, 29.869282000000055],
            [72.655553000000054, 29.87073300000003],
            [72.649266000000068, 29.867347000000052],
            [72.644494919000067, 29.859974735000037],
            [72.644494989000066, 29.859973457000024],
            [72.643946000000028, 29.859125000000063],
            [72.643945047000045, 29.85912507300003],
            [72.638158660000045, 29.859570038000072],
            [72.631371000000058, 29.860092000000066],
            [72.621870536000074, 29.856221387000062],
            [72.618313000000057, 29.854772000000025],
            [72.61059798000008, 29.847914795000065],
            [72.60960700000004, 29.847034000000065],
            [72.605586201000051, 29.840197977000059],
            [72.604771000000028, 29.838812000000075],
            [72.594130000000064, 29.838329000000044],
            [72.592190673000061, 29.838522813000054],
            [72.584845767000047, 29.839256847000058],
            [72.582200196000031, 29.839521240000067],
            [72.579621000000031, 29.839779000000078],
            [72.569948000000068, 29.839779000000078],
            [72.564145000000053, 29.83687800000007],
            [72.563385915000083, 29.834221790000072],
            [72.56221000000005, 29.830107000000055],
            [72.565046644000063, 29.824433713000076],
            [72.566563000000031, 29.821401000000037],
            [72.563661000000081, 29.808343000000036],
            [72.563446000000056, 29.80834799400003],
            [72.563445969000043, 29.80834799400003],
            [72.542864000000066, 29.808826000000067],
            [72.541926658000079, 29.812342247000061],
            [72.54093000000006, 29.816081000000054],
            [72.539143717000059, 29.819256343000063],
            [72.536577000000079, 29.823819000000071],
            [72.532224000000042, 29.830107000000055],
            [72.531548602000044, 29.830613505000031],
            [72.524662178000028, 29.835777878000044],
            [72.524486000000081, 29.835910000000069],
            [72.520133000000044, 29.84026300000005],
            [72.512395000000083, 29.843165000000056],
            [72.511539940000034, 29.841882498000075],
            [72.507558000000074, 29.835910000000069],
            [72.506908740000028, 29.835341960000051],
            [72.506486778000067, 29.834708930000033],
            [72.503651980000029, 29.832228757000053],
            [72.502617778000058, 29.831323930000053],
            [72.501650778000055, 29.823585930000036],
            [72.502457691000075, 29.817532000000028],
            [72.496918000000051, 29.817532000000028],
            [72.493049000000042, 29.823819000000071],
            [72.49256500000007, 29.830590000000029],
            [72.49217410600005, 29.830590000000029],
            [72.489551973000061, 29.830590000000029],
            [72.48787261700005, 29.830590000000029],
            [72.48724500000003, 29.830590000000029],
            [72.481925000000047, 29.828656000000024],
            [72.479676922000067, 29.827531767000039],
            [72.476122000000032, 29.825754000000074],
            [72.470318000000077, 29.821401000000037],
            [72.461129000000028, 29.826721000000077],
            [72.460161000000085, 29.834943000000067],
            [72.460193629000059, 29.835046318000025],
            [72.460161000000085, 29.835323748000064],
            [72.462981407000029, 29.844254389000071],
            [72.460161000000085, 29.848485000000039],
            [72.458941980000077, 29.848274833000062],
            [72.456330131000072, 29.84782453400004],
            [72.449586340000053, 29.84666186100003],
            [72.446136000000081, 29.846067000000062],
            [72.44203617900007, 29.841221196000049],
            [72.440816000000041, 29.839779000000078],
            [72.434045000000083, 29.838812000000075],
            [72.426306000000068, 29.839779000000078],
            [72.41856800000005, 29.843649000000028],
            [72.414087000000052, 29.847592000000077],
            [72.406477000000052, 29.854289000000051],
            [72.402608000000043, 29.859609000000034],
            [72.392390295000041, 29.857644275000041],
            [72.390033000000074, 29.857191000000057],
            [72.383746000000031, 29.852354000000048],
            [72.378426000000047, 29.84461600000003],
            [72.374065931000075, 29.839165914000034],
            [72.372622000000035, 29.837361000000044],
            [72.363433000000043, 29.838328000000047],
            [72.355695000000082, 29.842198000000053],
            [72.350858000000073, 29.845099000000062],
            [72.348984872000074, 29.844742302000043],
            [72.340702000000078, 29.843165000000056],
            [72.340539057000058, 29.842859456000042],
            [72.33683300000007, 29.835910000000069],
            [72.328965160000052, 29.833156704000032],
            [72.327160000000049, 29.832525000000032],
            [72.32232300000004, 29.826237000000049],
            [72.312714550000067, 29.819991954000045],
            [72.312650000000076, 29.819950000000063],
            [72.314528113000051, 29.810092574000066],
            [72.314585000000079, 29.809794000000068],
            [72.314173396000058, 29.809199430000035],
            [72.310232000000042, 29.803506000000027],
            [72.302010000000053, 29.81076100000007],
            [72.299592000000075, 29.814146000000051],
            [72.295723000000066, 29.813179000000048],
            [72.294864487000041, 29.811032275000059],
            [72.294756000000064, 29.81076100000007],
            [72.302018114000077, 29.803498886000057],
            [72.297657000000072, 29.804370748000053],
            [72.292337000000032, 29.804370748000053],
            [72.285326234000081, 29.805097043000046],
            [72.28314800000004, 29.80544100000003],
            [72.275894000000051, 29.806408000000033],
            [72.267466642000045, 29.809705760000043],
            [72.264770000000055, 29.81076100000007],
            [72.258482000000072, 29.812695000000076],
            [72.255580000000066, 29.812212000000045],
            [72.248809000000051, 29.810277000000042],
            [72.247358000000077, 29.804474000000027],
            [72.245240297000066, 29.802204921000055],
            [72.24524156800004, 29.802204523000057],
            [72.240588000000059, 29.797219000000041],
            [72.239293965000059, 29.79662178500007],
            [72.237643192000064, 29.795859930000063],
            [72.234300000000076, 29.794317000000035],
            [72.233575177000034, 29.787310542000057],
            [72.232849000000044, 29.780291000000034],
            [72.231661773000042, 29.776254036000068],
            [72.230431000000067, 29.772069000000045],
            [72.228013000000033, 29.767233000000033],
            [72.219791000000043, 29.767717000000061],
            [72.218767771000046, 29.766079749000028],
            [72.217373000000066, 29.763848000000053],
            [72.217373000000066, 29.761456543000065],
            [72.217373000000066, 29.758044000000041],
            [72.220275000000072, 29.753207000000032],
            [72.222755737000057, 29.749899065000079],
            [72.223252079000076, 29.749237218000076],
            [72.223251644000072, 29.749237150000056],
            [72.22607800000003, 29.745469000000071],
            [72.228521060000048, 29.741804411000032],
            [72.228980000000035, 29.741116000000034],
            [72.231776982000042, 29.737920039000073],
            [72.23236600000007, 29.737247000000025],
            [72.232863351000049, 29.735630225000079],
            [72.234300000000076, 29.730960000000039],
            [72.238653000000056, 29.724189000000024],
            [72.244038990000035, 29.721496005000063],
            [72.244457000000068, 29.721287000000075],
            [72.245758824000063, 29.716513945000031],
            [72.245908000000043, 29.715967000000035],
            [72.245283645000029, 29.712637392000033],
            [72.244457000000068, 29.708229000000074],
            [72.234300000000076, 29.704359000000068],
            [72.23369165500003, 29.704359000000068],
            [72.22704600000003, 29.704359000000068],
            [72.223923465000041, 29.704229074000068],
            [72.215438000000063, 29.703876000000037],
            [72.21011800000008, 29.705810000000042],
            [72.207320272000061, 29.707034232000069],
            [72.204957384000068, 29.708068186000048],
            [72.202380000000062, 29.709196000000077],
            [72.19657600000005, 29.711130000000026],
            [72.196540979000076, 29.711042459000055],
            [72.193674000000044, 29.703876000000037],
            [72.19250581700004, 29.69530985800003],
            [72.192453644000068, 29.694927280000059],
            [72.19222300000007, 29.69323600000007],
            [72.19222300000007, 29.688686264000069],
            [72.19222300000007, 29.686465000000055],
            [72.187387000000058, 29.670988000000079],
            [72.186903047000044, 29.670564588000047],
            [72.183518000000049, 29.667603000000042],
            [72.183518000000049, 29.661822375000042],
            [72.183518000000049, 29.661315000000059],
            [72.183518000000049, 29.652610000000038],
            [72.183381560000043, 29.652493063000065],
            [72.180132000000071, 29.649708000000032],
            [72.17273146000008, 29.649245108000059],
            [72.172394000000054, 29.649224000000061],
            [72.167789404000075, 29.645973565000077],
            [72.164172000000065, 29.643420000000049],
            [72.163785393000069, 29.638398107000057],
            [72.163688000000036, 29.637133000000063],
            [72.164172000000065, 29.630846000000076],
            [72.16514877700007, 29.627428036000026],
            [72.16514826100007, 29.627428079000026],
            [72.16610600000007, 29.624075000000062],
            [72.16610600000007, 29.624066890000051],
            [72.166107000000068, 29.624066821000042],
            [72.166107000000068, 29.618755000000078],
            [72.161270000000059, 29.61827100000005],
            [72.155467000000044, 29.61488600000007],
            [72.147245000000055, 29.609082000000058],
            [72.144805364000035, 29.606438921000063],
            [72.144594832000053, 29.606210832000045],
            [72.141441000000043, 29.602794000000074],
            [72.137088000000062, 29.596507000000031],
            [72.125964000000067, 29.594573000000025],
            [72.119014538000044, 29.593754820000072],
            [72.117742000000078, 29.593605000000025],
            [72.111939000000064, 29.593122000000051],
            [72.099848000000065, 29.599409000000037],
            [72.10079853700006, 29.60103881200007],
            [72.103233000000046, 29.605213000000049],
            [72.107586000000083, 29.612467000000038],
            [72.10591335600003, 29.614641402000075],
            [72.102749000000074, 29.618755000000078],
            [72.096946000000059, 29.626977000000068],
            [72.097164879000047, 29.630483597000079],
            [72.097429000000034, 29.634715000000028],
            [72.100426179000067, 29.642706788000055],
            [72.101782000000071, 29.646322000000055],
            [72.103868825000063, 29.647017728000037],
            [72.10457445600008, 29.647252979000029],
            [72.107586000000083, 29.648257000000058],
            [72.107648875000052, 29.648343455000031],
            [72.111455000000035, 29.653577000000041],
            [72.104837835000069, 29.658303127000067],
            [72.104684000000077, 29.658413000000053],
            [72.103220455000042, 29.659313894000036],
            [72.098397000000034, 29.662283000000059],
            [72.097913000000062, 29.669537000000048],
            [72.096048258000053, 29.667139710000072],
            [72.094527000000085, 29.665184000000068],
            [72.09210900000005, 29.66179900000003],
            [72.089691000000073, 29.656479000000047],
            [72.089530651000075, 29.656391531000054],
            [72.089529083000059, 29.656390676000058],
            [72.089528457000029, 29.656390334000037],
            [72.084371000000033, 29.653577000000041],
            [72.072280000000035, 29.65406100000007],
            [72.066494208000051, 29.653809263000028],
            [72.06242078300005, 29.65363203000004],
            [72.06115600000004, 29.653577000000041],
            [72.055352000000084, 29.662283000000059],
            [72.055256252000049, 29.662642130000052],
            [72.053829502000042, 29.667993547000037],
            [72.053418000000079, 29.669537000000048],
            [72.056803000000059, 29.674374000000057],
            [72.06115600000004, 29.673406000000057],
            [72.068411000000083, 29.670988000000079],
            [72.069287436000081, 29.675954268000055],
            [72.069862000000057, 29.679210000000069],
            [72.062123000000042, 29.682112000000075],
            [72.055352000000084, 29.682112000000075],
            [72.052534152000078, 29.681935748000058],
            [72.047614000000067, 29.681628000000046],
            [72.041810000000055, 29.683079000000077],
            [72.040659308000045, 29.684709362000035],
            [72.036102654000047, 29.691165473000069],
            [72.036007000000041, 29.691301000000067],
            [72.025850000000048, 29.694203000000073],
            [72.020543462000035, 29.693613520000042],
            [72.017145000000085, 29.69323600000007],
            [72.01375900000005, 29.693719000000044],
            [72.011781416000076, 29.695088242000054],
            [72.007472000000064, 29.698072000000025],
            [71.999250000000075, 29.699039000000028],
            [71.99387992700008, 29.699452345000054],
            [71.992962000000034, 29.699523000000056],
            [71.991512000000057, 29.707745000000045],
            [71.991460725000081, 29.708513593000077],
            [71.991438151000068, 29.708851971000058],
            [71.991028000000028, 29.715000000000032],
            [71.991004642000064, 29.715373445000068],
            [71.990544000000057, 29.722738000000049],
            [71.991028000000028, 29.729509000000064],
            [71.989513338000052, 29.729687141000056],
            [71.982806000000039, 29.730476000000067],
            [71.977002000000084, 29.729025000000036],
            [71.972649000000047, 29.736280000000079],
            [71.968780000000038, 29.745469000000071],
            [71.968244384000059, 29.745469000000071],
            [71.966846000000032, 29.745469000000071],
            [71.962493000000052, 29.744502000000068],
            [71.960075000000074, 29.739182000000028],
            [71.959107000000074, 29.736280000000079],
            [71.956206000000066, 29.73579600000005],
            [71.953787000000034, 29.739182000000028],
            [71.953788000000031, 29.739451237000026],
            [71.953788000000031, 29.740538650000076],
            [71.953792054000075, 29.740542724000079],
            [71.953792098000065, 29.740542768000068],
            [71.953792098000065, 29.740554561000067],
            [71.953792098000065, 29.745469000000071],
            [71.953792098000065, 29.748371000000077],
            [71.953788000000031, 29.748371000000077],
            [71.94798909800005, 29.748371000000077],
            [71.943152098000041, 29.74401800000004],
            [71.943148256000029, 29.744019130000027],
            [71.943147000000067, 29.74401800000004],
            [71.937843890000067, 29.745577586000024],
            [71.937844799000061, 29.74557881800007],
            [71.934930098000052, 29.746436000000074],
            [71.937349098000084, 29.75369100000006],
            [71.938800098000058, 29.760946000000047],
            [71.939881098000058, 29.761518000000024],
            [71.939878646000068, 29.761519401000044],
            [71.939921165000044, 29.761541911000052],
            [71.941724263000083, 29.762496492000025],
            [71.947017000000073, 29.76529800000003],
            [71.952337000000057, 29.765782000000058],
            [71.955543390000059, 29.767385471000068],
            [71.958140000000071, 29.768684000000064],
            [71.96297700000008, 29.77061800000007],
            [71.964582764000056, 29.772937560000059],
            [71.965051474000063, 29.773614622000025],
            [71.967330000000061, 29.776906000000054],
            [71.970715000000041, 29.787062000000049],
            [71.973617000000047, 29.791899000000058],
            [71.974900415000036, 29.791727913000045],
            [71.980871000000036, 29.790932000000055],
            [71.985224000000073, 29.788030000000049],
            [71.989093000000082, 29.787062000000049],
            [71.989093000000082, 29.790984647000073],
            [71.989093000000082, 29.795768000000066],
            [71.977002000000084, 29.799637000000075],
            [71.973893643000054, 29.800988314000051],
            [71.973872310000047, 29.80099758800003],
            [71.965878000000032, 29.80447300000003],
            [71.959591000000046, 29.810277000000042],
            [71.958198741000047, 29.811901223000064],
            [71.957771337000054, 29.812399836000054],
            [71.953787000000034, 29.817048000000057],
            [71.951976039000044, 29.820104260000051],
            [71.950312331000077, 29.822912010000039],
            [71.950312490000044, 29.82291267100004],
            [71.946049000000073, 29.830107000000055],
            [71.941622825000081, 29.832762339000055],
            [71.936376000000052, 29.835910000000069],
            [71.93204238800007, 29.834909830000072],
            [71.930089000000066, 29.834459000000038],
            [71.928638000000035, 29.837361000000044],
            [71.928638000000035, 29.839779000000078],
            [71.930231744000082, 29.846550969000077],
            [71.930573000000038, 29.848001000000068],
            [71.92979426900007, 29.848142694000046],
            [71.925253000000055, 29.848969000000068],
            [71.921867000000077, 29.846550000000036],
            [71.919932000000074, 29.846067000000062],
            [71.914612000000034, 29.845583000000033],
            [71.912194000000056, 29.84461600000003],
            [71.907841000000076, 29.846067000000062],
            [71.907841000000076, 29.847732980000046],
            [71.907841000000076, 29.851870000000076],
            [71.907841000000076, 29.856707000000029],
            [71.90590700000007, 29.862027000000069],
            [71.903380550000065, 29.86160607000005],
            [71.900172309000084, 29.861071548000041],
            [71.900103000000058, 29.861060000000066],
            [71.898169000000053, 29.858641000000034],
            [71.896711660000051, 29.857184262000033],
            [71.896660601000065, 29.857133225000041],
            [71.895750000000078, 29.856223000000057],
            [71.892848488000084, 29.854289325000025],
            [71.892848000000072, 29.854289000000051],
            [71.891318692000084, 29.855262065000034],
            [71.887718110000037, 29.857553037000059],
            [71.887528000000032, 29.857674000000031],
            [71.887551544000075, 29.858120987000063],
            [71.887555994000081, 29.85820546900004],
            [71.88801200000006, 29.866863000000023],
            [71.888450424000041, 29.868178045000036],
            [71.889947000000063, 29.872667000000035],
            [71.890320742000029, 29.873094180000066],
            [71.893332000000044, 29.876536000000044],
            [71.89447888400008, 29.876685598000051],
            [71.894553268000038, 29.876695301000041],
            [71.901372992000063, 29.877584857000045],
            [71.903394909000042, 29.877848593000067],
            [71.904456000000039, 29.877987000000076],
            [71.910260000000051, 29.877020000000073],
            [71.914612000000034, 29.874602000000039],
            [71.917039784000053, 29.872326051000073],
            [71.922351000000049, 29.867347000000052],
            [71.922202847000051, 29.868272744000024],
            [71.920625044000076, 29.878131770000039],
            [71.920416000000046, 29.87943800000005],
            [71.910389193000071, 29.884690325000065],
            [71.910260000000051, 29.884758000000033],
            [71.903177764000077, 29.883341992000055],
            [71.90058700000003, 29.882824000000028],
            [71.900358248000032, 29.882860121000078],
            [71.895086610000078, 29.883692546000077],
            [71.891398000000038, 29.884275000000059],
            [71.885594000000083, 29.889111000000071],
            [71.88545381800003, 29.891492364000044],
            [71.885110000000054, 29.89733300000006],
            [71.887528000000032, 29.903137000000072],
            [71.892849000000069, 29.906038000000024],
            [71.896718000000078, 29.911359000000061],
            [71.894783000000075, 29.91522800000007],
            [71.885110000000054, 29.910875000000033],
            [71.872286093000071, 29.909320222000076],
            [71.869150000000047, 29.90894000000003],
            [71.864745442000071, 29.90894000000003],
            [71.863830000000064, 29.90894000000003],
            [71.853190000000041, 29.912809000000038],
            [71.848723950000078, 29.91504244500004],
            [71.842550000000074, 29.918130000000076],
            [71.834126672000082, 29.918130000000076],
            [71.832877000000053, 29.918130000000076],
            [71.820418538000069, 29.912790396000048],
            [71.819335000000081, 29.912326000000064],
            [71.810629000000063, 29.905555000000049],
            [71.804366734000041, 29.902548946000024],
            [71.798538000000065, 29.899751000000037],
            [71.785963000000038, 29.908457000000055],
            [71.771938000000034, 29.913293000000067],
            [71.771446092000076, 29.913493394000056],
            [71.758879000000036, 29.91861300000005],
            [71.754043000000081, 29.924901000000034],
            [71.752673926000057, 29.928734746000032],
            [71.751625000000047, 29.931672000000049],
            [71.760330000000067, 29.935541000000057],
            [71.760030314000062, 29.936665057000027],
            [71.758396000000062, 29.942795000000046],
            [71.754527000000053, 29.93650800000006],
            [71.750478465000072, 29.93650800000006],
            [71.749207000000069, 29.93650800000006],
            [71.743887000000029, 29.937475000000063],
            [71.737599000000046, 29.93650800000006],
            [71.737599000000046, 29.940861000000041],
            [71.736156132000076, 29.949517207000042],
            [71.736632000000043, 29.949566000000061],
            [71.736632000000043, 29.963108000000034],
            [71.742919000000029, 29.963108000000034],
            [71.750174000000072, 29.961657000000059],
            [71.75315248000004, 29.970051050000052],
            [71.753339198000049, 29.970577265000031],
            [71.753356952000047, 29.970627300000046],
            [71.755494000000056, 29.976650000000063],
            [71.757845904000078, 29.98004710400005],
            [71.764200000000073, 29.989225000000033],
            [71.764245937000055, 29.989283191000027],
            [71.764246007000054, 29.989283279000063],
            [71.771454000000062, 29.998414000000025],
            [71.772868957000071, 30.000536434000026],
            [71.777258000000074, 30.007120000000043],
            [71.769520000000057, 30.010989000000052],
            [71.77387200000004, 30.022596000000078],
            [71.786931000000038, 30.021145000000047],
            [71.799022000000036, 30.014374000000032],
            [71.800251810000077, 30.013720713000055],
            [71.814498000000071, 30.00615300000004],
            [71.816470013000071, 30.005589568000062],
            [71.824655000000064, 30.003251000000034],
            [71.826285488000053, 30.003522729000053],
            [71.839164000000039, 30.005669000000069],
            [71.840790178000077, 30.007062936000068],
            [71.845935000000054, 30.011473000000024],
            [71.850772000000063, 30.021145000000047],
            [71.85134350900006, 30.021961453000074],
            [71.857543000000078, 30.030818000000068],
            [71.863830000000064, 30.036138000000051],
            [71.873019000000056, 30.040491000000031],
            [71.873019000000056, 30.029367000000036],
            [71.880274000000043, 30.023564000000079],
            [71.889947000000063, 30.030818000000068],
            [71.896021112000028, 30.042292176000046],
            [71.896994254000049, 30.044130468000048],
            [71.896994886000073, 30.044131662000041],
            [71.898652000000084, 30.047262000000046],
            [71.907358000000045, 30.046779000000072],
            [71.91848200000004, 30.042426000000034],
            [71.925582061000057, 30.039198275000047],
            [71.929035468000052, 30.037628338000047],
            [71.929122000000064, 30.037589000000025],
            [71.942180000000064, 30.037589000000025],
            [71.955722000000037, 30.04097500000006],
            [71.963460000000055, 30.047746000000075],
            [71.96225878000007, 30.052100383000038],
            [71.96159992500003, 30.054488713000069],
            [71.95986685400004, 30.060771040000077],
            [71.959591000000046, 30.061771000000078],
            [71.961212780000039, 30.065555154000037],
            [71.963944000000083, 30.071928000000071],
            [71.970231000000069, 30.077732000000026],
            [71.972182000000032, 30.076906000000065],
            [71.982806000000039, 30.072412000000043],
            [71.985115273000076, 30.074585335000052],
            [71.991028000000028, 30.08015000000006],
            [71.999734000000046, 30.090790000000027],
            [72.001821535000033, 30.09068553700007],
            [72.00940600000007, 30.090306000000055],
            [72.020530000000065, 30.08353500000004],
            [72.029719000000057, 30.079183000000057],
            [72.033213000000046, 30.077436000000034],
            [72.033676938000042, 30.077204031000065],
            [72.039354060000051, 30.073481328000071],
            [72.043254060000038, 30.072381328000063],
            [72.057754060000036, 30.06998132800004],
            [72.06041563000008, 30.070298182000045],
            [72.060415740000053, 30.070298195000078],
            [72.063204815000063, 30.070620045000055],
            [72.070345000000032, 30.071444000000042],
            [72.071119208000084, 30.071917097000039],
            [72.074978866000038, 30.074275630000045],
            [72.07501676000004, 30.074136843000076],
            [72.079653077000046, 30.076777333000052],
            [72.088240000000042, 30.077732000000026],
            [72.097654060000082, 30.071981328000049],
            [72.097676137000064, 30.072002867000037],
            [72.101782000000071, 30.069510000000037],
            [72.104990828000041, 30.067244853000034],
            [72.104975310000043, 30.067226308000045],
            [72.11000400000006, 30.063706000000025],
            [72.119193000000053, 30.069993000000068],
            [72.123386657000083, 30.075085343000069],
            [72.125954060000083, 30.078181328000028],
            [72.127026090000072, 30.080628793000074],
            [72.127026112000067, 30.080628843000056],
            [72.129976000000056, 30.087333000000058],
            [72.131284000000051, 30.090306000000055],
            [72.131350499000064, 30.090443752000056],
            [72.137893930000075, 30.103998345000036],
            [72.137920893000057, 30.104054199000075],
            [72.137944259000051, 30.104102601000079],
            [72.138221090000059, 30.104498076000027],
            [72.138349463000054, 30.104681466000045],
            [72.149654060000046, 30.115981328000032],
            [72.158512691000055, 30.118889123000031],
            [72.160655880000036, 30.119592612000076],
            [72.162754060000054, 30.120281328000033],
            [72.169996897000033, 30.120622971000046],
            [72.173354060000065, 30.120781328000078],
            [72.175050272000078, 30.119875132000061],
            [72.180654060000052, 30.116881328000034],
            [72.186150675000079, 30.115430832000072],
            [72.18787100000003, 30.114972000000023],
            [72.188669824000044, 30.115262550000068],
            [72.190814914000043, 30.116042766000078],
            [72.19081501200003, 30.116042801000049],
            [72.19315406000004, 30.116881328000034],
            [72.193145087000062, 30.116890301000069],
            [72.19319100000007, 30.116907000000026],
            [72.191950039000062, 30.118147854000028],
            [72.181583000000046, 30.128514000000052],
            [72.17868100000004, 30.14060500000005],
            [72.17975365500007, 30.144895620000057],
            [72.179753668000046, 30.144895671000029],
            [72.181579015000068, 30.152197061000038],
            [72.190754060000074, 30.157981328000062],
            [72.197753939000052, 30.154709645000025],
            [72.199962000000085, 30.153664000000049],
            [72.20235391600005, 30.153664000000049],
            [72.21011800000008, 30.153664000000049],
            [72.226260388000071, 30.158886422000023],
            [72.226554060000069, 30.158981328000039],
            [72.226554164000049, 30.158981465000068],
            [72.226562000000058, 30.158984000000032],
            [72.227084342000069, 30.159680337000054],
            [72.230954060000045, 30.164781328000061],
            [72.239699450000046, 30.165623000000039],
            [72.241071000000034, 30.165755000000047],
            [72.241764820000071, 30.168808109000054],
            [72.243053239000062, 30.174477716000069],
            [72.245868802000075, 30.175533643000051],
            [72.247094006000054, 30.175993134000066],
            [72.247893956000041, 30.176293141000031],
            [72.247912095000061, 30.17629994400005],
            [72.25057088300008, 30.177297076000059],
            [72.250662266000063, 30.177331347000063],
            [72.250744000000054, 30.177362000000073],
            [72.26311434400003, 30.181486016000065],
            [72.263117255000054, 30.181468823000046],
            [72.265256742000076, 30.182178645000079],
            [72.272895445000074, 30.176309885000023],
            [72.273456742000064, 30.175878645000068],
            [72.276562505000072, 30.178116621000072],
            [72.280246000000034, 30.180748000000051],
            [72.285062193000044, 30.182352845000025],
            [72.286056742000028, 30.182678645000067],
            [72.288487108000083, 30.183819429000039],
            [72.290956742000049, 30.184978645000058],
            [72.290955392000058, 30.184981939000068],
            [72.292212115000041, 30.185557937000056],
            [72.293302179000079, 30.186057549000054],
            [72.293305000000032, 30.186068000000034],
            [72.292337000000032, 30.194773000000055],
            [72.294272000000035, 30.200093000000038],
            [72.302494000000081, 30.200577000000067],
            [72.309265000000039, 30.207832000000053],
            [72.309498062000046, 30.207893328000068],
            [72.318378478000056, 30.210230127000045],
            [72.318454000000031, 30.21025000000003],
            [72.323955647000048, 30.211130009000044],
            [72.329229137000084, 30.21197352300004],
            [72.329818482000064, 30.212067791000038],
            [72.329813303000037, 30.212033617000031],
            [72.333251086000075, 30.208666706000031],
            [72.335354060000043, 30.205881328000032],
            [72.339278469000078, 30.204226920000053],
            [72.34555406000004, 30.201581328000032],
            [72.347954060000063, 30.201181328000075],
            [72.352836467000031, 30.200391527000079],
            [72.35283650100007, 30.200391521000029],
            [72.354754060000062, 30.200081328000067],
            [72.356490868000037, 30.200231053000039],
            [72.359700048000036, 30.200507706000053],
            [72.362050269000065, 30.200703693000037],
            [72.366335000000049, 30.201061000000038],
            [72.370915084000046, 30.202482214000042],
            [72.375221449000037, 30.203804883000032],
            [72.378571048000083, 30.204833688000065],
            [72.380354060000059, 30.205381328000044],
            [72.382154060000062, 30.206681328000059],
            [72.38214881500005, 30.206704991000038],
            [72.389066000000071, 30.211701000000062],
            [72.398825000000045, 30.217800000000068],
            [72.398840212000039, 30.21779239500006],
            [72.399163000000044, 30.21763100000004],
            [72.399440393000077, 30.217470432000027],
            [72.405479000000071, 30.213975000000062],
            [72.412792000000081, 30.206661000000054],
            [72.408803000000034, 30.203337000000033],
            [72.405479000000071, 30.198683000000074],
            [72.404104046000043, 30.194282651000037],
            [72.40381700000006, 30.193364000000031],
            [72.402819000000079, 30.189375000000041],
            [72.401822000000038, 30.183059000000071],
            [72.414787000000047, 30.178738000000067],
            [72.417427562000057, 30.179011095000078],
            [72.424427000000037, 30.179735000000051],
            [72.431935603000056, 30.180397367000069],
            [72.435729000000038, 30.180732000000035],
            [72.437419817000034, 30.180450291000056],
            [72.447697000000062, 30.178738000000067],
            [72.447897024000042, 30.175833735000026],
            [72.448361000000034, 30.169097000000079],
            [72.450259781000057, 30.167198519000067],
            [72.454678000000058, 30.162781000000052],
            [72.461326000000042, 30.160454000000072],
            [72.468307000000038, 30.162781000000052],
            [72.46837021500005, 30.162833692000049],
            [72.46837393800007, 30.162834936000024],
            [72.470612600000038, 30.164700306000043],
            [72.470645799000067, 30.164727968000079],
            [72.470647338000049, 30.16472925100004],
            [72.472363916000063, 30.166159594000078],
            [72.472550432000048, 30.167261753000048],
            [72.472550266000042, 30.167266794000057],
            [72.473021882000069, 30.170097027000054],
            [72.482934000000057, 30.170427000000075],
            [72.482957510000062, 30.170480444000077],
            [72.483003747000055, 30.170481998000071],
            [72.485357805000035, 30.175834470000041],
            [72.486659236000037, 30.178793561000077],
            [72.486991792000083, 30.184112201000062],
            [72.489318296000079, 30.190428543000053],
            [72.495603864000032, 30.198369930000069],
            [72.502879000000064, 30.202007000000037],
            [72.502890840000077, 30.202037799000038],
            [72.502946776000044, 30.202065778000076],
            [72.504607053000029, 30.206383734000042],
            [72.50627018800003, 30.210709120000047],
            [72.51158885600006, 30.217027241000039],
            [72.510258340000064, 30.222677568000051],
            [72.510257859000035, 30.229599000000064],
            [72.514846000000034, 30.229599000000064],
            [72.51488624600006, 30.229659364000042],
            [72.51491195300008, 30.229659370000036],
            [72.518838603000063, 30.235550206000028],
            [72.518901637000056, 30.235641958000031],
            [72.51890247800003, 30.243291641000042],
            [72.518805954000072, 30.245391200000029],
            [72.518566351000061, 30.250602991000051],
            [72.520562401000063, 30.256255650000071],
            [72.521086523000065, 30.256995433000043],
            [72.521085571000071, 30.256996711000056],
            [72.524510498000041, 30.261848519000068],
            [72.524550777000059, 30.261905578000039],
            [72.532368028000064, 30.263410426000064],
            [72.533193589000064, 30.263569349000079],
            [72.539807117000066, 30.266215944000066],
            [72.539841325000054, 30.266229633000023],
            [72.541129104000049, 30.268483207000031],
            [72.545097000000055, 30.266830000000027],
            [72.548089000000061, 30.262841000000037],
            [72.553075000000035, 30.263506000000064],
            [72.555226471000083, 30.265350052000031],
            [72.557729000000052, 30.267495000000054],
            [72.563713000000064, 30.270154000000048],
            [72.563733108000065, 30.270144574000028],
            [72.563736243000051, 30.270206226000028],
            [72.563774742000078, 30.270223341000076],
            [72.572955369000056, 30.265921811000055],
            [72.574410863000082, 30.265239848000078],
            [72.577402773000074, 30.257595180000067],
            [72.576738113000033, 30.249949722000053],
            [72.579065150000076, 30.23997939800006],
            [72.582056852000051, 30.232999996000046],
            [72.58770716500004, 30.226686244000064],
            [72.594680092000033, 30.230672612000035],
            [72.594686791000072, 30.23067644200006],
            [72.596859333000054, 30.23387243600007],
            [72.60033648600006, 30.23898762500005],
            [72.604602705000048, 30.245223614000054],
            [72.612911000000054, 30.248214000000075],
            [72.620890000000031, 30.253201000000047],
            [72.626873000000046, 30.255860000000041],
            [72.626878817000033, 30.255924001000039],
            [72.626924400000064, 30.255944271000033],
            [72.627921122000032, 30.266912784000056],
            [72.628486593000048, 30.269103866000023],
            [72.630565151000042, 30.277157853000062],
            [72.637178000000063, 30.281457000000046],
            [72.643343607000077, 30.288907044000041],
            [72.645156000000043, 30.291097000000036],
            [72.64517952500006, 30.291151888000059],
            [72.645202598000083, 30.291179776000035],
            [72.647177887000055, 30.295789441000068],
            [72.64816647300006, 30.298096473000044],
            [72.652802000000065, 30.302732000000049],
            [72.658121000000051, 30.311707000000069],
            [72.662967558000048, 30.311550857000043],
            [72.666245588000038, 30.311445247000051],
            [72.668426000000068, 30.311375000000055],
            [72.668665900000065, 30.311632014000054],
            [72.673080000000084, 30.316361000000029],
            [72.673815878000084, 30.317740754000056],
            [72.673857541000075, 30.317818872000032],
            [72.673853277000035, 30.317822586000034],
            [72.678436135000084, 30.32641717100006],
            [72.680527496000082, 30.326197205000028],
            [72.684749897000074, 30.325753099000053],
            [72.689090357000055, 30.319127179000077],
            [72.689070817000072, 30.319020794000039],
            [72.691031000000066, 30.316029000000071],
            [72.689748027000064, 30.309870423000064],
            [72.689369000000056, 30.308051000000034],
            [72.69202800000005, 30.304062000000044],
            [72.698677000000032, 30.301070000000038],
            [72.701336000000083, 30.297746000000075],
            [72.705658000000028, 30.295751000000052],
            [72.712306000000069, 30.294089000000042],
            [72.715046126000061, 30.297171556000023],
            [72.720284000000049, 30.303064000000063],
            [72.724040209000066, 30.309709066000039],
            [72.724606000000051, 30.310710000000029],
            [72.724603333000061, 30.310765905000039],
            [72.724623733000044, 30.31080201900005],
            [72.724291441000048, 30.31778253300007],
            [72.725291120000065, 30.325093957000036],
            [72.725293620000059, 30.332657540000071],
            [72.725293646000068, 30.332737020000025],
            [72.726743988000067, 30.333224896000047],
            [72.730275222000046, 30.334400469000059],
            [72.736920033000047, 30.337061207000033],
            [72.737381712000058, 30.336639951000052],
            [72.737381723000055, 30.336639905000027],
            [72.744228765000059, 30.330414102000077],
            [72.753531678000058, 30.325097480000068],
            [72.763498155000036, 30.32377008800006],
            [72.770143436000069, 30.323771038000075],
            [72.776448597000069, 30.32443495900003],
            [72.776455871000053, 30.324435725000058],
            [72.780442639000057, 30.325101120000056],
            [72.780775060000053, 30.331748393000055],
            [72.782769201000065, 30.33573665800003],
            [72.786739664000038, 30.340226380000047],
            [72.790411467000069, 30.344378384000038],
            [72.800045944000033, 30.348035502000073],
            [72.804032388000053, 30.348035891000052],
            [72.804003496000064, 30.347942000000046],
            [72.802702759000056, 30.34371489800003],
            [72.794065026000055, 30.34172049700004],
            [72.789413272000047, 30.33640229100007],
            [72.789745680000067, 30.33174957500006],
            [72.789754809000044, 30.331739528000071],
            [72.789761000000055, 30.331653000000074],
            [72.793085000000076, 30.327996000000041],
            [72.796742000000052, 30.328329000000053],
            [72.800533634000033, 30.330224342000065],
            [72.800731000000042, 30.330323000000078],
            [72.804387000000077, 30.335309000000052],
            [72.807047000000068, 30.33863400000007],
            [72.810039000000074, 30.341958000000034],
            [72.811036000000058, 30.34495000000004],
            [72.811036000000058, 30.350269000000026],
            [72.810704000000044, 30.355587000000071],
            [72.810680818000037, 30.355633350000062],
            [72.810677938000083, 30.355679579000025],
            [72.809016419000045, 30.359003629000028],
            [72.807715069000039, 30.363237823000077],
            [72.80768876500008, 30.363323408000042],
            [72.810014676000037, 30.366979723000043],
            [72.814665606000062, 30.366980027000068],
            [72.817523059000052, 30.36829933100006],
            [72.817633771000033, 30.368350447000068],
            [72.818984027000056, 30.368973869000058],
            [72.829614986000081, 30.36963961500004],
            [72.841241783000044, 30.369640469000046],
            [72.846769197000071, 30.369948667000074],
            [72.847220555000035, 30.369973834000064],
            [72.847234537000077, 30.369971894000059],
            [72.847196343000064, 30.370143934000055],
            [72.847290014000066, 30.370186755000077],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "125",
      properties: { name: "Khanewal", count: 1156 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [72.079163239000081, 30.725694001000079],
            [72.092969000000039, 30.723722000000066],
            [72.093400580000036, 30.723771836000026],
            [72.093738203000044, 30.723810822000075],
            [72.101324502000068, 30.724686581000071],
            [72.114636842000039, 30.72622180500008],
            [72.113631444000077, 30.724859653000067],
            [72.118190910000067, 30.725949960000037],
            [72.12309091000003, 30.727149960000077],
            [72.128391521000083, 30.726446339000063],
            [72.134390910000036, 30.725649960000055],
            [72.143890910000039, 30.725449960000049],
            [72.15249091000004, 30.722649960000069],
            [72.152831867000032, 30.722569275000069],
            [72.152833902000054, 30.72256879400004],
            [72.152837806000036, 30.722567870000034],
            [72.163583000000074, 30.719091000000049],
            [72.164355536000073, 30.718782025000053],
            [72.167424481000069, 30.717554603000053],
            [72.167923140000084, 30.717355165000072],
            [72.171685704000083, 30.715849960000071],
            [72.172290910000072, 30.715849960000071],
            [72.18149926500007, 30.712184498000056],
            [72.181499345000077, 30.712184466000053],
            [72.182590910000044, 30.711749960000077],
            [72.18546129200007, 30.712467556000036],
            [72.185463001000073, 30.712467983000067],
            [72.187834566000049, 30.712637597000025],
            [72.194448260000058, 30.713109982000049],
            [72.204808587000059, 30.713849966000055],
            [72.205290910000031, 30.713849960000061],
            [72.216624913000032, 30.716317848000074],
            [72.216743674000043, 30.716343707000078],
            [72.217689608000057, 30.716546430000051],
            [72.218460194000045, 30.716711574000044],
            [72.221155533000058, 30.717289212000026],
            [72.229567000000031, 30.719091000000049],
            [72.237538536000045, 30.723076768000055],
            [72.237375072000077, 30.722642650000068],
            [72.23735432400008, 30.722587548000035],
            [72.237234000000058, 30.722268000000042],
            [72.23262455400004, 30.714467399000057],
            [72.228814637000085, 30.70801984700006],
            [72.227190910000047, 30.70524996000006],
            [72.224798918000033, 30.697976352000069],
            [72.222798924000074, 30.691894737000041],
            [72.222290910000083, 30.690349960000049],
            [72.214390910000077, 30.67924996000005],
            [72.208022310000047, 30.667968440000038],
            [72.207396000000074, 30.666855000000055],
            [72.207248999000058, 30.666666001000067],
            [72.206529001000035, 30.665732670000068],
            [72.199290910000059, 30.656349960000057],
            [72.193990910000082, 30.647649960000024],
            [72.192249901000082, 30.646213628000055],
            [72.189990910000063, 30.644349960000056],
            [72.182480172000055, 30.635780015000023],
            [72.18248013200008, 30.635779970000044],
            [72.182190910000031, 30.635449960000074],
            [72.18227132800007, 30.635168497000052],
            [72.184720232000075, 30.626597335000042],
            [72.185390910000081, 30.624249960000043],
            [72.184912502000032, 30.622719055000061],
            [72.184921000000031, 30.622679000000062],
            [72.182958654000061, 30.618645429000026],
            [72.177990910000062, 30.608349960000055],
            [72.176686424000081, 30.605909308000037],
            [72.174890910000045, 30.602549960000033],
            [72.173460307000084, 30.600260995000042],
            [72.166272701000082, 30.588760826000055],
            [72.165933000000052, 30.588191000000052],
            [72.165863538000053, 30.588097497000035],
            [72.16556382300007, 30.587523043000033],
            [72.163490910000064, 30.583549960000028],
            [72.157829924000055, 30.576920648000055],
            [72.155890910000039, 30.574649960000045],
            [72.160156106000045, 30.57272542000004],
            [72.164043254000035, 30.570971463000035],
            [72.164054060000069, 30.570981328000073],
            [72.170554060000029, 30.568081328000062],
            [72.178986634000069, 30.568081328000062],
            [72.181554060000053, 30.568081328000062],
            [72.183754060000069, 30.568081328000062],
            [72.194654060000062, 30.562481328000047],
            [72.194633328000066, 30.562473554000064],
            [72.197195491000059, 30.56114502500003],
            [72.197318335000034, 30.561081328000057],
            [72.197318278000068, 30.56106740000007],
            [72.197321000000045, 30.553015425000069],
            [72.197353836000048, 30.544439940000075],
            [72.19856358100003, 30.545800903000043],
            [72.199832035000043, 30.547227914000075],
            [72.201303722000034, 30.548883563000061],
            [72.202446197000029, 30.550980035000066],
            [72.203954060000058, 30.552681328000062],
            [72.205040930000052, 30.55371159300006],
            [72.205991034000078, 30.555136818000051],
            [72.206392922000077, 30.555739679000055],
            [72.207097773000044, 30.556797005000078],
            [72.207106016000068, 30.556809370000053],
            [72.207500056000072, 30.557400458000075],
            [72.208669928000063, 30.559155351000072],
            [72.209732469000073, 30.560749239000074],
            [72.209769711000035, 30.560751743000026],
            [72.210454060000075, 30.561881328000027],
            [72.213554060000035, 30.563781328000061],
            [72.213573376000056, 30.563763864000066],
            [72.215015194000046, 30.562460308000027],
            [72.215904371000079, 30.561634725000033],
            [72.218287029000066, 30.559509277000075],
            [72.221277752000049, 30.559509277000075],
            [72.224592937000068, 30.560459820000062],
            [72.225451023000062, 30.561064018000025],
            [72.225740611000049, 30.561286756000072],
            [72.227442355000051, 30.562595660000056],
            [72.228237356000079, 30.563774168000066],
            [72.228404417000036, 30.563335634000055],
            [72.22948400000007, 30.564166000000057],
            [72.232720933000053, 30.558687569000028],
            [72.232754060000048, 30.558681328000034],
            [72.23455406000005, 30.555681328000048],
            [72.237042859000042, 30.554774079000026],
            [72.238887936000083, 30.554139811000027],
            [72.238916545000052, 30.55412997600007],
            [72.246456636000062, 30.551537975000031],
            [72.24692200000004, 30.551378000000057],
            [72.244984000000045, 30.565716000000066],
            [72.246656657000074, 30.564651510000033],
            [72.249120164000033, 30.563083720000066],
            [72.249254060000055, 30.562981328000035],
            [72.253821443000049, 30.558956605000049],
            [72.259354060000078, 30.554081328000052],
            [72.26009700000003, 30.548278000000039],
            [72.26016351100003, 30.548268826000026],
            [72.266669833000037, 30.547371443000031],
            [72.271335000000079, 30.54672800000003],
            [72.280254060000061, 30.547281328000054],
            [72.283354060000079, 30.54748132800006],
            [72.284096997000063, 30.547944141000073],
            [72.289454060000082, 30.551281328000073],
            [72.289725917000055, 30.551255019000052],
            [72.291733415000067, 30.551060745000029],
            [72.295654060000061, 30.550681328000053],
            [72.30094226600005, 30.548373747000028],
            [72.301154060000044, 30.54828132800003],
            [72.30022241100005, 30.545835749000048],
            [72.299554060000048, 30.544081328000061],
            [72.298459769000033, 30.541295861000037],
            [72.298299402000055, 30.536330692000035],
            [72.298242464000054, 30.534567812000034],
            [72.298204712000029, 30.533398952000027],
            [72.298072000000047, 30.52929000000006],
            [72.301543312000035, 30.526858612000069],
            [72.301544292000074, 30.526859844000057],
            [72.305945828000063, 30.532393795000075],
            [72.306254060000072, 30.532781328000056],
            [72.306222719000061, 30.533032055000035],
            [72.306598000000065, 30.540528000000052],
            [72.304660000000069, 30.550991000000067],
            [72.306586397000046, 30.556383520000054],
            [72.306598000000065, 30.55641600000007],
            [72.312410000000057, 30.556803000000059],
            [72.314790867000056, 30.558534540000039],
            [72.315038271000049, 30.558714470000041],
            [72.320935000000077, 30.563003000000037],
            [72.327910000000031, 30.565716000000066],
            [72.328800876000059, 30.565716000000066],
            [72.332948000000044, 30.565716000000066],
            [72.334445191000043, 30.559352457000045],
            [72.334495004000075, 30.559140736000074],
            [72.334555335000061, 30.559228277000045],
            [72.33612319000008, 30.561503203000029],
            [72.339536000000066, 30.566491000000042],
            [72.342248000000041, 30.571916000000044],
            [72.348836000000063, 30.576179000000025],
            [72.351321925000036, 30.576534132000063],
            [72.354261000000065, 30.576954000000057],
            [72.356554060000065, 30.56998132800004],
            [72.356624672000066, 30.569669459000067],
            [72.357754060000048, 30.564681328000063],
            [72.357760573000064, 30.564659421000044],
            [72.358854060000056, 30.560981328000025],
            [72.359115711000072, 30.560130963000063],
            [72.363465430000076, 30.558391225000037],
            [72.363408567000079, 30.558461813000065],
            [72.36355406000007, 30.558381328000053],
            [72.36511100000007, 30.568429000000037],
            [72.366661000000079, 30.571529000000055],
            [72.370154060000061, 30.568081328000062],
            [72.374454060000062, 30.564981328000044],
            [72.37703854800003, 30.563893122000025],
            [72.377187794000065, 30.563830282000026],
            [72.378254060000074, 30.563381328000048],
            [72.378272074000051, 30.563396570000066],
            [72.381300753000062, 30.565959299000042],
            [72.384754060000034, 30.568881328000032],
            [72.388554060000047, 30.570181328000047],
            [72.39203860300006, 30.571405627000047],
            [72.392254060000084, 30.571481328000061],
            [72.397763604000033, 30.574950301000058],
            [72.400354060000041, 30.576581328000032],
            [72.401768398000058, 30.578221960000064],
            [72.402854060000038, 30.579481328000043],
            [72.406554060000076, 30.583881328000075],
            [72.414454060000082, 30.585781328000053],
            [72.417854060000082, 30.587381328000049],
            [72.422454060000064, 30.587781328000062],
            [72.425954060000038, 30.590081328000053],
            [72.431644701000039, 30.589633750000075],
            [72.433159487000069, 30.589514609000048],
            [72.434854060000077, 30.589381328000059],
            [72.442954060000034, 30.59598132800005],
            [72.447187464000081, 30.599190521000025],
            [72.449154060000069, 30.600681328000064],
            [72.448795801000074, 30.599419278000028],
            [72.448812000000032, 30.599429000000043],
            [72.465139365000084, 30.597150832000068],
            [72.46545406000007, 30.597081328000058],
            [72.465701651000074, 30.597025533000078],
            [72.466030275000037, 30.596911762000047],
            [72.468052744000033, 30.59621157600003],
            [72.475550000000055, 30.593616000000054],
            [72.480200000000082, 30.585866000000067],
            [72.480831389000059, 30.580814888000077],
            [72.480952878000039, 30.579842978000045],
            [72.48640000000006, 30.577729000000033],
            [72.49171078300003, 30.572797415000025],
            [72.493940192000082, 30.570727190000071],
            [72.495423692000031, 30.569349613000043],
            [72.495927791000042, 30.568881508000061],
            [72.496361119000085, 30.568479120000063],
            [72.496774656000071, 30.568101931000058],
            [72.49697400000008, 30.56791000000004],
            [72.496974194000074, 30.567909271000076],
            [72.496979855000063, 30.567887992000067],
            [72.498021708000067, 30.563972007000075],
            [72.499359447000074, 30.558943886000066],
            [72.500254060000032, 30.555581328000073],
            [72.50195406000006, 30.551681328000029],
            [72.503654060000031, 30.547881328000074],
            [72.502591215000052, 30.54680372200005],
            [72.501416352000035, 30.545612541000025],
            [72.49759828200007, 30.541741442000045],
            [72.496454060000076, 30.54058132800003],
            [72.496438328000067, 30.54058132800003],
            [72.484854060000032, 30.54058132800003],
            [72.484853182000052, 30.540578000000039],
            [72.484032799000033, 30.537467380000066],
            [72.483638011000039, 30.535970475000056],
            [72.482454060000066, 30.531481328000041],
            [72.480954060000045, 30.525581328000044],
            [72.481554060000065, 30.523381328000028],
            [72.482964373000073, 30.518351211000038],
            [72.484553017000053, 30.512685047000048],
            [72.484635233000063, 30.512709440000037],
            [72.485772962000055, 30.508726849000027],
            [72.487285310000061, 30.503432910000072],
            [72.483730273000049, 30.496321389000059],
            [72.479552462000072, 30.487964067000064],
            [72.479551082000057, 30.487949164000042],
            [72.47931243000005, 30.485371722000025],
            [72.478580513000054, 30.477309373000026],
            [72.478563712000039, 30.47728556900006],
            [72.478554060000079, 30.477181328000029],
            [72.472754060000057, 30.468981328000041],
            [72.471400455000037, 30.467762187000062],
            [72.462343402000045, 30.459604842000033],
            [72.459475181000073, 30.457021543000053],
            [72.457689225000081, 30.455413000000078],
            [72.457654060000038, 30.455381328000044],
            [72.447103000000084, 30.446751000000063],
            [72.44656468900007, 30.445602971000028],
            [72.445062270000051, 30.442382792000046],
            [72.442527167000037, 30.436949232000075],
            [72.442176859000028, 30.436198407000063],
            [72.441651594000064, 30.435069646000045],
            [72.440365989000043, 30.432306964000077],
            [72.440354060000061, 30.432281328000045],
            [72.439854060000073, 30.417781328000046],
            [72.439987571000074, 30.414176534000035],
            [72.44005125700005, 30.412456999000028],
            [72.440154060000054, 30.409681328000033],
            [72.440297136000083, 30.406288372000063],
            [72.440853046000029, 30.393105382000044],
            [72.440854060000049, 30.393081328000051],
            [72.440821110000059, 30.392666166000026],
            [72.440763220000065, 30.391936749000024],
            [72.439888326000073, 30.380554325000048],
            [72.44028759400004, 30.375773898000034],
            [72.440522506000036, 30.372961314000065],
            [72.440646081000068, 30.371481754000058],
            [72.440654060000043, 30.371481328000073],
            [72.440746666000052, 30.370277452000039],
            [72.440857976000075, 30.368944738000039],
            [72.440850069000078, 30.368933206000065],
            [72.440854060000049, 30.368881328000043],
            [72.429254060000062, 30.351981328000079],
            [72.427896243000077, 30.345135668000069],
            [72.426854060000039, 30.339881328000047],
            [72.426354060000051, 30.337481328000024],
            [72.424872432000029, 30.335347679000051],
            [72.420467522000081, 30.329004295000061],
            [72.418334180000045, 30.325932131000059],
            [72.417634852000049, 30.324921901000039],
            [72.417601000000047, 30.324873000000025],
            [72.415886255000032, 30.325015994000069],
            [72.411805528000059, 30.325356289000069],
            [72.409051452000028, 30.320948835000024],
            [72.406999537000047, 30.317654345000051],
            [72.407274162000078, 30.310257424000042],
            [72.407484089000036, 30.304603121000071],
            [72.401232668000034, 30.29869762800007],
            [72.398777389000031, 30.296378215000061],
            [72.396615336000082, 30.290972426000053],
            [72.395875416000081, 30.289122401000043],
            [72.404101420000075, 30.280416522000053],
            [72.40454287600005, 30.277769843000044],
            [72.406037643000047, 30.268808204000038],
            [72.404587955000068, 30.256231336000042],
            [72.40434293800007, 30.251558214000056],
            [72.404106102000071, 30.247041125000067],
            [72.403390201000036, 30.244654045000061],
            [72.401204585000073, 30.237366388000055],
            [72.40123583500008, 30.237168711000038],
            [72.402657335000072, 30.22817685800004],
            [72.400723080000034, 30.218985564000036],
            [72.398874704000036, 30.217829992000077],
            [72.399212828000032, 30.21766107600007],
            [72.399492356000053, 30.217499395000061],
            [72.399440393000077, 30.217470432000027],
            [72.399163000000044, 30.21763100000004],
            [72.398840212000039, 30.21779239500006],
            [72.398825000000045, 30.217800000000068],
            [72.389066000000071, 30.211701000000062],
            [72.38214881500005, 30.206704991000038],
            [72.382154060000062, 30.206681328000059],
            [72.380354060000059, 30.205381328000044],
            [72.378571048000083, 30.204833688000065],
            [72.375221449000037, 30.203804883000032],
            [72.370915084000046, 30.202482214000042],
            [72.366335000000049, 30.201061000000038],
            [72.362050269000065, 30.200703693000037],
            [72.359700048000036, 30.200507706000053],
            [72.356490868000037, 30.200231053000039],
            [72.354754060000062, 30.200081328000067],
            [72.35283650100007, 30.200391521000029],
            [72.352836467000031, 30.200391527000079],
            [72.347954060000063, 30.201181328000075],
            [72.34555406000004, 30.201581328000032],
            [72.339278469000078, 30.204226920000053],
            [72.335354060000043, 30.205881328000032],
            [72.333251086000075, 30.208666706000031],
            [72.329813303000037, 30.212033617000031],
            [72.329818482000064, 30.212067791000038],
            [72.329229137000084, 30.21197352300004],
            [72.323955647000048, 30.211130009000044],
            [72.318454000000031, 30.21025000000003],
            [72.318378478000056, 30.210230127000045],
            [72.309498062000046, 30.207893328000068],
            [72.309265000000039, 30.207832000000053],
            [72.302494000000081, 30.200577000000067],
            [72.294272000000035, 30.200093000000038],
            [72.292337000000032, 30.194773000000055],
            [72.293305000000032, 30.186068000000034],
            [72.293302179000079, 30.186057549000054],
            [72.292212115000041, 30.185557937000056],
            [72.290955392000058, 30.184981939000068],
            [72.290956742000049, 30.184978645000058],
            [72.288487108000083, 30.183819429000039],
            [72.286056742000028, 30.182678645000067],
            [72.285062193000044, 30.182352845000025],
            [72.280246000000034, 30.180748000000051],
            [72.276562505000072, 30.178116621000072],
            [72.273456742000064, 30.175878645000068],
            [72.272895445000074, 30.176309885000023],
            [72.265256742000076, 30.182178645000079],
            [72.263117255000054, 30.181468823000046],
            [72.26311434400003, 30.181486016000065],
            [72.250744000000054, 30.177362000000073],
            [72.250662266000063, 30.177331347000063],
            [72.25057088300008, 30.177297076000059],
            [72.247912095000061, 30.17629994400005],
            [72.247893956000041, 30.176293141000031],
            [72.247094006000054, 30.175993134000066],
            [72.245868802000075, 30.175533643000051],
            [72.243053239000062, 30.174477716000069],
            [72.241764820000071, 30.168808109000054],
            [72.241071000000034, 30.165755000000047],
            [72.239699450000046, 30.165623000000039],
            [72.230954060000045, 30.164781328000061],
            [72.227084342000069, 30.159680337000054],
            [72.226562000000058, 30.158984000000032],
            [72.226554164000049, 30.158981465000068],
            [72.226554060000069, 30.158981328000039],
            [72.226260388000071, 30.158886422000023],
            [72.21011800000008, 30.153664000000049],
            [72.20235391600005, 30.153664000000049],
            [72.199962000000085, 30.153664000000049],
            [72.197753939000052, 30.154709645000025],
            [72.190754060000074, 30.157981328000062],
            [72.181579015000068, 30.152197061000038],
            [72.179753668000046, 30.144895671000029],
            [72.17975365500007, 30.144895620000057],
            [72.17868100000004, 30.14060500000005],
            [72.181583000000046, 30.128514000000052],
            [72.191950039000062, 30.118147854000028],
            [72.19319100000007, 30.116907000000026],
            [72.193145087000062, 30.116890301000069],
            [72.19315406000004, 30.116881328000034],
            [72.19081501200003, 30.116042801000049],
            [72.190814914000043, 30.116042766000078],
            [72.188669824000044, 30.115262550000068],
            [72.18787100000003, 30.114972000000023],
            [72.186150675000079, 30.115430832000072],
            [72.180654060000052, 30.116881328000034],
            [72.175050272000078, 30.119875132000061],
            [72.173354060000065, 30.120781328000078],
            [72.169996897000033, 30.120622971000046],
            [72.162754060000054, 30.120281328000033],
            [72.160655880000036, 30.119592612000076],
            [72.158512691000055, 30.118889123000031],
            [72.149654060000046, 30.115981328000032],
            [72.138349463000054, 30.104681466000045],
            [72.138221090000059, 30.104498076000027],
            [72.137944259000051, 30.104102601000079],
            [72.137920893000057, 30.104054199000075],
            [72.137893930000075, 30.103998345000036],
            [72.131350499000064, 30.090443752000056],
            [72.131284000000051, 30.090306000000055],
            [72.129976000000056, 30.087333000000058],
            [72.127026112000067, 30.080628843000056],
            [72.127026090000072, 30.080628793000074],
            [72.125954060000083, 30.078181328000028],
            [72.123386657000083, 30.075085343000069],
            [72.119193000000053, 30.069993000000068],
            [72.11000400000006, 30.063706000000025],
            [72.104975310000043, 30.067226308000045],
            [72.104990828000041, 30.067244853000034],
            [72.101782000000071, 30.069510000000037],
            [72.097676137000064, 30.072002867000037],
            [72.097654060000082, 30.071981328000049],
            [72.088240000000042, 30.077732000000026],
            [72.079653077000046, 30.076777333000052],
            [72.07501676000004, 30.074136843000076],
            [72.074978866000038, 30.074275630000045],
            [72.071119208000084, 30.071917097000039],
            [72.070345000000032, 30.071444000000042],
            [72.063204815000063, 30.070620045000055],
            [72.060415740000053, 30.070298195000078],
            [72.06041563000008, 30.070298182000045],
            [72.057754060000036, 30.06998132800004],
            [72.043254060000038, 30.072381328000063],
            [72.039354060000051, 30.073481328000071],
            [72.033676938000042, 30.077204031000065],
            [72.033213000000046, 30.077436000000034],
            [72.029719000000057, 30.079183000000057],
            [72.020530000000065, 30.08353500000004],
            [72.00940600000007, 30.090306000000055],
            [72.001821535000033, 30.09068553700007],
            [71.999734000000046, 30.090790000000027],
            [71.991028000000028, 30.08015000000006],
            [71.985115273000076, 30.074585335000052],
            [71.982806000000039, 30.072412000000043],
            [71.972182000000032, 30.076906000000065],
            [71.970231000000069, 30.077732000000026],
            [71.963944000000083, 30.071928000000071],
            [71.961212780000039, 30.065555154000037],
            [71.959591000000046, 30.061771000000078],
            [71.95986685400004, 30.060771040000077],
            [71.96159992500003, 30.054488713000069],
            [71.96225878000007, 30.052100383000038],
            [71.963460000000055, 30.047746000000075],
            [71.955722000000037, 30.04097500000006],
            [71.942180000000064, 30.037589000000025],
            [71.929122000000064, 30.037589000000025],
            [71.929035468000052, 30.037628338000047],
            [71.925582061000057, 30.039198275000047],
            [71.91848200000004, 30.042426000000034],
            [71.907358000000045, 30.046779000000072],
            [71.898652000000084, 30.047262000000046],
            [71.896994886000073, 30.044131662000041],
            [71.896994254000049, 30.044130468000048],
            [71.896021112000028, 30.042292176000046],
            [71.889947000000063, 30.030818000000068],
            [71.880274000000043, 30.023564000000079],
            [71.873019000000056, 30.029367000000036],
            [71.873019000000056, 30.040491000000031],
            [71.863830000000064, 30.036138000000051],
            [71.857543000000078, 30.030818000000068],
            [71.85134350900006, 30.021961453000074],
            [71.850772000000063, 30.021145000000047],
            [71.845935000000054, 30.011473000000024],
            [71.840790178000077, 30.007062936000068],
            [71.839164000000039, 30.005669000000069],
            [71.826285488000053, 30.003522729000053],
            [71.824655000000064, 30.003251000000034],
            [71.816470013000071, 30.005589568000062],
            [71.814498000000071, 30.00615300000004],
            [71.800251810000077, 30.013720713000055],
            [71.799022000000036, 30.014374000000032],
            [71.786931000000038, 30.021145000000047],
            [71.77387200000004, 30.022596000000078],
            [71.769520000000057, 30.010989000000052],
            [71.777258000000074, 30.007120000000043],
            [71.772868957000071, 30.000536434000026],
            [71.771454000000062, 29.998414000000025],
            [71.764246007000054, 29.989283279000063],
            [71.764245937000055, 29.989283191000027],
            [71.764200000000073, 29.989225000000033],
            [71.757845904000078, 29.98004710400005],
            [71.755494000000056, 29.976650000000063],
            [71.753356952000047, 29.970627300000046],
            [71.753339198000049, 29.970577265000031],
            [71.75315248000004, 29.970051050000052],
            [71.750174000000072, 29.961657000000059],
            [71.742919000000029, 29.963108000000034],
            [71.736632000000043, 29.963108000000034],
            [71.736632000000043, 29.949566000000061],
            [71.736156132000076, 29.949517207000042],
            [71.729230646000076, 29.94880710800004],
            [71.729228251000052, 29.948806862000026],
            [71.722871020000071, 29.948155029000077],
            [71.722870819000036, 29.948155232000033],
            [71.71777000000003, 29.947632000000056],
            [71.710032000000069, 29.955370000000073],
            [71.710031000000072, 29.955370000000073],
            [71.703744000000029, 29.955370000000073],
            [71.704228000000057, 29.947632000000056],
            [71.698577642000032, 29.946545514000036],
            [71.696348400000034, 29.946116861000064],
            [71.692790224000078, 29.945432673000028],
            [71.691653000000031, 29.945214000000078],
            [71.676660000000084, 29.935541000000057],
            [71.675951677000057, 29.932883871000058],
            [71.674726000000078, 29.928286000000071],
            [71.682948000000067, 29.921031000000028],
            [71.676660000000084, 29.916195000000073],
            [71.681497000000036, 29.910391000000061],
            [71.681497000000036, 29.907720393000034],
            [71.681497000000036, 29.907220452000047],
            [71.681497000000036, 29.902653000000043],
            [71.679924439000047, 29.902216238000051],
            [71.672791000000075, 29.900235000000066],
            [71.66263500000008, 29.904104000000075],
            [71.654413000000034, 29.907006000000024],
            [71.646941498000047, 29.90671842900008],
            [71.646217185000069, 29.906690551000054],
            [71.641838000000064, 29.906522000000052],
            [71.628822706000051, 29.911727964000079],
            [71.627151575000084, 29.912396397000066],
            [71.627107499000033, 29.912414027000068],
            [71.624910000000057, 29.913293000000067],
            [71.614270000000033, 29.922966000000031],
            [71.612816316000078, 29.92500123800005],
            [71.607016000000044, 29.933122000000026],
            [71.608099866000032, 29.938108323000051],
            [71.609434000000078, 29.944246000000078],
            [71.611391661000084, 29.945644495000067],
            [71.611486442000057, 29.945712203000028],
            [71.616205000000036, 29.94908300000003],
            [71.617354983000041, 29.957515680000029],
            [71.617656000000068, 29.959723000000054],
            [71.616334207000079, 29.965274751000038],
            [71.616334014000074, 29.965275560000066],
            [71.615238000000033, 29.969879000000049],
            [71.610026317000063, 29.96964243900004],
            [71.604597000000069, 29.969396000000074],
            [71.598310000000083, 29.965043000000037],
            [71.594452708000063, 29.961185708000073],
            [71.594415809000054, 29.961148809000065],
            [71.591382000000067, 29.958115000000078],
            [71.591460500000039, 29.958435500000064],
            [71.591539000000068, 29.958756000000051],
            [71.590088000000037, 29.961174000000028],
            [71.586880442000052, 29.964381558000071],
            [71.582350000000076, 29.968912000000046],
            [71.575095000000033, 29.969396000000074],
            [71.568808000000047, 29.972781000000055],
            [71.565422000000069, 29.976650000000063],
            [71.564939000000038, 29.986807000000056],
            [71.573644000000058, 29.98825800000003],
            [71.578726361000065, 29.986563588000024],
            [71.57944800000007, 29.986323000000027],
            [71.584768000000054, 29.983421000000078],
            [71.58767000000006, 29.980519000000072],
            [71.593474000000072, 29.978585000000066],
            [71.596014613000079, 29.981125613000074],
            [71.601212000000032, 29.986323000000027],
            [71.603306407000048, 29.989255430000071],
            [71.606048000000044, 29.993094000000042],
            [71.607762639000043, 29.997951948000036],
            [71.60895000000005, 30.001316000000031],
            [71.615403589000039, 30.000025282000024],
            [71.616205000000036, 29.999865000000057],
            [71.618623000000071, 30.006636000000071],
            [71.621525000000076, 30.011473000000024],
            [71.624300854000069, 30.011755797000035],
            [71.633132000000046, 30.011473000000024],
            [71.639799698000047, 30.010731634000024],
            [71.640761535000081, 30.010624689000053],
            [71.640762033000044, 30.010624634000067],
            [71.641838000000064, 30.010505000000023],
            [71.64909300000005, 30.013407000000029],
            [71.652687441000069, 30.014305610000065],
            [71.658765000000074, 30.015825000000063],
            [71.662151000000051, 30.022113000000047],
            [71.672108589000061, 30.029680636000023],
            [71.674242000000049, 30.031302000000039],
            [71.686333000000047, 30.032269000000042],
            [71.691526968000062, 30.036164476000067],
            [71.692137000000059, 30.036622000000079],
            [71.696272441000076, 30.04144644400003],
            [71.697941000000071, 30.043393000000037],
            [71.703261000000055, 30.044844000000069],
            [71.70519500000006, 30.041942000000063],
            [71.710458261000042, 30.045451083000046],
            [71.712450000000047, 30.046779000000072],
            [71.720188000000064, 30.04532800000004],
            [71.721639000000039, 30.05790200000007],
            [71.722681105000049, 30.060637761000066],
            [71.722681861000069, 30.06063974500006],
            [71.725508000000048, 30.068059000000062],
            [71.728664261000063, 30.072161421000033],
            [71.729145731000074, 30.072787223000034],
            [71.730345000000057, 30.074346000000048],
            [71.736632000000043, 30.079666000000032],
            [71.74001700000008, 30.087888000000078],
            [71.744930386000078, 30.095927079000035],
            [71.746391699000071, 30.097796747000075],
            [71.747168879000071, 30.098791105000032],
            [71.75259200000005, 30.105299000000059],
            [71.753160901000058, 30.110420877000024],
            [71.753161000000034, 30.110421768000037],
            [71.753559000000052, 30.114005000000077],
            [71.750174000000072, 30.124161000000072],
            [71.754043000000081, 30.134318000000064],
            [71.758573536000029, 30.141113469000061],
            [71.760505180000052, 30.144010793000064],
            [71.760814000000039, 30.144474000000059],
            [71.770012802000053, 30.156301031000055],
            [71.770016806000058, 30.156300148000071],
            [71.770971000000031, 30.157533000000058],
            [71.784996000000035, 30.163820000000044],
            [71.786150077000059, 30.166114378000032],
            [71.79273500000005, 30.172526000000062],
            [71.793574572000068, 30.175675045000048],
            [71.793588035000084, 30.175725543000055],
            [71.794669000000056, 30.179780000000051],
            [71.798055000000033, 30.183649000000059],
            [71.798820836000061, 30.186713533000045],
            [71.79883286300003, 30.186761660000059],
            [71.799989000000039, 30.191388000000074],
            [71.799989000000039, 30.200577000000067],
            [71.804826000000048, 30.202995000000044],
            [71.811597000000063, 30.202995000000044],
            [71.812564000000066, 30.209766000000059],
            [71.813531000000069, 30.214603000000068],
            [71.81365500000004, 30.216703000000052],
            [71.81401500000004, 30.222825000000057],
            [71.818851000000052, 30.22814500000004],
            [71.824655000000064, 30.233948000000055],
            [71.824680718000081, 30.233976935000044],
            [71.832393000000081, 30.242654000000073],
            [71.827073000000041, 30.254261000000042],
            [71.825087185000029, 30.257381524000039],
            [71.824665221000032, 30.258044604000077],
            [71.823505742000066, 30.259866616000068],
            [71.820302000000083, 30.264901000000066],
            [71.818092000000036, 30.264736000000028],
            [71.818276654000044, 30.265048642000068],
            [71.801199963000045, 30.263469287000078],
            [71.801199691000079, 30.263469265000026],
            [71.800990910000053, 30.263449960000059],
            [71.790790910000055, 30.263149960000078],
            [71.783990910000057, 30.262949960000071],
            [71.783732470000075, 30.26296700000006],
            [71.783169626000074, 30.263004111000043],
            [71.78129436100005, 30.263127755000028],
            [71.774890910000067, 30.263549960000034],
            [71.769531592000078, 30.266558185000065],
            [71.769532100000049, 30.266546299000026],
            [71.769410988000061, 30.266616452000051],
            [71.768885227000055, 30.266920995000078],
            [71.767357993000076, 30.267778243000066],
            [71.766644189000033, 30.268268995000028],
            [71.76663512600004, 30.268275226000071],
            [71.769390910000084, 30.269849960000045],
            [71.777290910000033, 30.280349960000024],
            [71.776790910000045, 30.291549960000054],
            [71.777890910000053, 30.295649960000048],
            [71.779190910000068, 30.300649960000044],
            [71.779183703000058, 30.300655683000059],
            [71.779182879000075, 30.300656337000078],
            [71.774211470000068, 30.304604221000034],
            [71.774118086000044, 30.304678379000052],
            [71.772422000000063, 30.306011000000069],
            [71.768426138000052, 30.306490490000044],
            [71.768417130000046, 30.306491491000031],
            [71.766090910000059, 30.306749960000047],
            [71.760296836000066, 30.307449245000043],
            [71.760296486000072, 30.307449287000054],
            [71.759932332000062, 30.307311155000036],
            [71.759090193000077, 30.306985166000061],
            [71.754090910000059, 30.305049960000076],
            [71.74929091000007, 30.303249960000073],
            [71.747874077000063, 30.302730454000027],
            [71.747342135000054, 30.302535409000029],
            [71.746305000000064, 30.30214200000006],
            [71.744802797000034, 30.30254265700006],
            [71.739090910000073, 30.304049960000043],
            [71.739061483000057, 30.304073937000055],
            [71.739050000000077, 30.304077000000063],
            [71.737101659000075, 30.305670831000043],
            [71.733725101000061, 30.308422101000076],
            [71.733690910000064, 30.308449960000075],
            [71.729911800000082, 30.315217204000078],
            [71.729390910000063, 30.316149960000075],
            [71.72938836700007, 30.316147791000049],
            [71.722399897000059, 30.310187037000048],
            [71.722399862000032, 30.310187007000025],
            [71.719221000000061, 30.307462000000044],
            [71.713901000000078, 30.30214200000006],
            [71.710240097000053, 30.301860213000054],
            [71.709435295000048, 30.301796340000067],
            [71.707590910000079, 30.301649960000077],
            [71.706113018000053, 30.302530406000074],
            [71.706126949000065, 30.30254975400004],
            [71.702929143000063, 30.304468702000065],
            [71.702928876000044, 30.304468571000029],
            [71.702890910000065, 30.304449960000056],
            [71.697890910000069, 30.307449960000042],
            [71.695646946000068, 30.311029618000077],
            [71.695628465000084, 30.31105910000008],
            [71.693647198000065, 30.314140915000053],
            [71.690790910000032, 30.313549960000046],
            [71.683179893000045, 30.314233000000058],
            [71.682990910000058, 30.314249960000041],
            [71.675590910000039, 30.315849960000037],
            [71.672994251000034, 30.316396625000039],
            [71.671790910000084, 30.316649960000063],
            [71.660690910000028, 30.319549960000074],
            [71.657592965000049, 30.321883478000075],
            [71.654943922000029, 30.323870431000046],
            [71.654866592000076, 30.323928434000038],
            [71.654871167000067, 30.323933663000048],
            [71.652990910000028, 30.32534996000004],
            [71.652832128000057, 30.332336385000076],
            [71.652790910000078, 30.334149960000047],
            [71.656390910000084, 30.339849960000038],
            [71.659290910000038, 30.34564996000006],
            [71.659710319000055, 30.349592400000063],
            [71.659790910000083, 30.350349960000074],
            [71.66019091000004, 30.353849960000048],
            [71.660210957000061, 30.353849125000067],
            [71.660216000000048, 30.35389200000003],
            [71.666985066000052, 30.357759895000072],
            [71.665990910000062, 30.359449960000063],
            [71.665997076000053, 30.359493431000033],
            [71.663118000000054, 30.364532000000054],
            [71.663105763000033, 30.36452384100005],
            [71.65731500000004, 30.360663000000045],
            [71.654170026000031, 30.357203984000023],
            [71.654170007000062, 30.357203963000075],
            [71.65249091000004, 30.355349960000069],
            [71.652486159000034, 30.35535197400003],
            [71.652478000000031, 30.355343000000062],
            [71.643289000000038, 30.35921200000007],
            [71.634100000000046, 30.361630000000048],
            [71.633923012000082, 30.361700791000032],
            [71.623190910000062, 30.364949960000047],
            [71.621990910000079, 30.370849960000044],
            [71.622442659000058, 30.372527886000057],
            [71.622485733000076, 30.372535476000053],
            [71.622735818000081, 30.373435905000065],
            [71.622845587000029, 30.373831129000052],
            [71.62314950800004, 30.374925395000048],
            [71.624427000000082, 30.379525000000058],
            [71.624690460000068, 30.381105578000074],
            [71.625878000000057, 30.388230000000078],
            [71.616689000000065, 30.391616000000056],
            [71.61551492600006, 30.392672594000032],
            [71.611872159000029, 30.395950858000049],
            [71.611852000000056, 30.395969000000036],
            [71.611580697000079, 30.396387583000035],
            [71.606744568000067, 30.403209979000053],
            [71.606290910000041, 30.403849960000059],
            [71.607937031000063, 30.410240782000074],
            [71.607969493000041, 30.410366813000053],
            [71.607983000000047, 30.410478000000069],
            [71.612757028000033, 30.410478000000069],
            [71.611799089000044, 30.411270777000027],
            [71.609890910000047, 30.412849960000074],
            [71.60428445000008, 30.416276130000028],
            [71.602690910000035, 30.417249960000049],
            [71.602620526000067, 30.417263157000036],
            [71.59629091000005, 30.418449960000032],
            [71.590629339000031, 30.420966214000032],
            [71.588190910000037, 30.422049960000038],
            [71.585290910000083, 30.416749960000061],
            [71.580990910000082, 30.415049960000033],
            [71.57799091000004, 30.41384996000005],
            [71.577929399000084, 30.414024242000039],
            [71.577081350000071, 30.416427046000024],
            [71.57708133400007, 30.416427093000038],
            [71.575590910000074, 30.420649960000048],
            [71.575535951000063, 30.420696623000026],
            [71.57029091000004, 30.425149960000056],
            [71.568990910000082, 30.428149960000042],
            [71.568220766000081, 30.429443802000037],
            [71.56399091000003, 30.436549960000036],
            [71.563975733000063, 30.436588350000079],
            [71.563971000000038, 30.436595000000068],
            [71.563502691000053, 30.437784868000051],
            [71.56059091000003, 30.445149960000037],
            [71.560603356000058, 30.445151424000073],
            [71.561614526000028, 30.445270385000072],
            [71.562277958000038, 30.445348436000074],
            [71.562278956000057, 30.445348553000031],
            [71.563175871000055, 30.445454073000064],
            [71.565737713000033, 30.447750626000072],
            [71.56631644600003, 30.448263086000054],
            [71.566990910000072, 30.449049960000025],
            [71.568834337000055, 30.450492642000029],
            [71.571710000000053, 30.453039000000047],
            [71.578481000000068, 30.462228000000039],
            [71.581069307000064, 30.463791223000044],
            [71.586855833000072, 30.467286029000036],
            [71.587547202000053, 30.467694575000053],
            [71.590115629000081, 30.469212319000064],
            [71.590156844000035, 30.469232927000064],
            [71.59059091000006, 30.469449960000077],
            [71.590677437000068, 30.46969290100003],
            [71.594441000000074, 30.477221000000043],
            [71.597570966000035, 30.479068813000026],
            [71.603333244000055, 30.482470643000056],
            [71.604983665000077, 30.482993183000076],
            [71.605081000000041, 30.483024000000057],
            [71.605089991000057, 30.483049473000051],
            [71.607983000000047, 30.491246000000046],
            [71.608053367000082, 30.492655229000036],
            [71.608190910000076, 30.495749960000069],
            [71.608317199000055, 30.497938976000057],
            [71.608466000000078, 30.500919000000067],
            [71.608489553000084, 30.500926437000032],
            [71.608490910000057, 30.500949960000071],
            [71.617690910000078, 30.503849960000025],
            [71.620129578000046, 30.504391886000064],
            [71.623943000000054, 30.505272000000048],
            [71.625712415000066, 30.505328234000046],
            [71.630691655000078, 30.505272251000065],
            [71.630690903000072, 30.505249960000071],
            [71.635390910000069, 30.507649960000037],
            [71.63646403000007, 30.508625524000024],
            [71.636508263000053, 30.508651321000059],
            [71.641590910000048, 30.509049960000027],
            [71.644970389000036, 30.509361912000031],
            [71.648090910000064, 30.509649960000047],
            [71.653490910000073, 30.513049960000046],
            [71.66019091000004, 30.517349960000047],
            [71.666018461000078, 30.522778364000033],
            [71.667488738000031, 30.524147937000066],
            [71.667490910000083, 30.524149960000045],
            [71.66774511400007, 30.524376306000079],
            [71.669009651000067, 30.525494107000043],
            [71.669006989000081, 30.525499893000074],
            [71.674681228000054, 30.530552298000032],
            [71.67479091000007, 30.530649960000062],
            [71.677874071000076, 30.532114461000049],
            [71.678790910000032, 30.53254996000004],
            [71.678564513000083, 30.532177276000027],
            [71.680478569000059, 30.532351412000025],
            [71.68049091000006, 30.532349960000033],
            [71.68679091000007, 30.532349960000033],
            [71.686802846000035, 30.53235600000005],
            [71.686943711000083, 30.532427281000025],
            [71.695090910000033, 30.536549960000059],
            [71.704680437000036, 30.539802060000056],
            [71.706590910000045, 30.540449960000046],
            [71.712288830000034, 30.54810304800003],
            [71.716460364000056, 30.553705991000072],
            [71.716469071000063, 30.553717686000027],
            [71.716567415000043, 30.55384977600005],
            [71.716790910000043, 30.554149960000075],
            [71.717123081000068, 30.554652268000041],
            [71.718889764000039, 30.557580960000053],
            [71.720830166000042, 30.56040719300006],
            [71.72647500000005, 30.568629000000044],
            [71.72647500000005, 30.568796686000042],
            [71.72647500000005, 30.57599300000004],
            [71.725644659000068, 30.576454268000077],
            [71.72564464900006, 30.576454363000039],
            [71.728106199000081, 30.575086782000028],
            [71.728310985000064, 30.574973009000075],
            [71.728320000000053, 30.574968000000069],
            [71.736424000000056, 30.58422900000005],
            [71.737741266000057, 30.584699562000026],
            [71.746399375000067, 30.587791763000041],
            [71.752630000000067, 30.590017000000046],
            [71.759311773000036, 30.591817085000059],
            [71.76239399800005, 30.592647443000033],
            [71.764296655000066, 30.593159874000037],
            [71.764329304000057, 30.593168418000062],
            [71.769990910000047, 30.594649960000027],
            [71.770037666000064, 30.594706067000061],
            [71.771257280000043, 30.596169604000067],
            [71.771499249000044, 30.596453925000048],
            [71.773590910000053, 30.597449960000063],
            [71.780588020000039, 30.604946864000055],
            [71.780590910000058, 30.604949960000056],
            [71.782555663000039, 30.606825406000041],
            [71.782555700000046, 30.606825442000059],
            [71.782744000000037, 30.60700200000008],
            [71.782745490000082, 30.607006605000038],
            [71.782790910000074, 30.60704996000004],
            [71.783919283000046, 30.610634203000075],
            [71.785070390000044, 30.614191693000066],
            [71.786201000000062, 30.617800000000045],
            [71.788575050000077, 30.623866840000062],
            [71.789177226000049, 30.625415292000071],
            [71.790130630000078, 30.62786690300004],
            [71.790687000000048, 30.629263000000037],
            [71.790837766000038, 30.629648570000029],
            [71.791410000000042, 30.63111200000003],
            [71.791455237000037, 30.631146744000034],
            [71.795162456000071, 30.633993984000028],
            [71.800860958000044, 30.638359133000051],
            [71.806226122000055, 30.642517928000075],
            [71.810432538000043, 30.645724328000028],
            [71.812180627000032, 30.64698740700004],
            [71.812113876000069, 30.647026750000066],
            [71.81210178300006, 30.647033878000059],
            [71.813984000000062, 30.648477000000071],
            [71.819440323000038, 30.648477068000034],
            [71.822666000000083, 30.648477000000071],
            [71.827556274000074, 30.648189344000059],
            [71.83250600000008, 30.647898000000055],
            [71.832565442000032, 30.647987166000064],
            [71.837297613000032, 30.655085716000031],
            [71.840609000000029, 30.660053000000062],
            [71.858381092000059, 30.669179284000052],
            [71.858367925000039, 30.669187678000071],
            [71.858374275000074, 30.669190942000057],
            [71.858387145000052, 30.669182818000024],
            [71.858387729000071, 30.66918269100006],
            [71.862025000000074, 30.671050000000037],
            [71.862300708000078, 30.671273198000051],
            [71.865127710000081, 30.673561778000078],
            [71.874180000000081, 30.680890000000034],
            [71.87558686300008, 30.680952495000042],
            [71.885258624000073, 30.681382128000052],
            [71.885261041000035, 30.681382261000067],
            [71.886490910000077, 30.681449960000066],
            [71.886488641000028, 30.681436770000062],
            [71.900195910000036, 30.682045663000054],
            [71.900226000000032, 30.682047000000068],
            [71.900829968000039, 30.681525506000071],
            [71.902881456000046, 30.679754092000053],
            [71.906720647000043, 30.676438468000072],
            [71.912960000000055, 30.671050000000037],
            [71.915275000000065, 30.665262000000041],
            [71.915287095000053, 30.665259582000033],
            [71.915546721000055, 30.665207665000025],
            [71.915546932000041, 30.66520762600004],
            [71.920404419000079, 30.664236349000078],
            [71.926851000000056, 30.662947000000031],
            [71.927321534000043, 30.663260644000047],
            [71.933797000000084, 30.667577000000051],
            [71.939889726000047, 30.670623379000062],
            [71.946106711000084, 30.673731888000077],
            [71.94653100000005, 30.673944000000063],
            [71.962158000000045, 30.674523000000079],
            [71.969919653000034, 30.667192791000048],
            [71.971485373000064, 30.665714104000074],
            [71.972577000000058, 30.664683000000025],
            [71.972778404000053, 30.664615870000034],
            [71.97699091000004, 30.66384996000005],
            [71.986474345000033, 30.660056586000053],
            [71.999781000000041, 30.667577000000051],
            [71.999781033000033, 30.681357493000064],
            [71.999781055000028, 30.690750296000033],
            [71.999781000000041, 30.695939000000067],
            [71.999790910000058, 30.695939708000026],
            [71.999790910000058, 30.695939779000071],
            [71.999790910000058, 30.695949960000064],
            [72.013090910000074, 30.697149960000047],
            [72.015553407000084, 30.697065044000055],
            [72.015990910000085, 30.697049960000072],
            [72.026190910000082, 30.696649960000059],
            [72.030990910000071, 30.697649960000035],
            [72.031013465000058, 30.697674133000078],
            [72.031036000000029, 30.697675000000061],
            [72.037251563000041, 30.704359808000049],
            [72.053271001000041, 30.721588634000057],
            [72.059618819000036, 30.728415681000058],
            [72.060882501000037, 30.729774184000064],
            [72.061695775000032, 30.730648479000024],
            [72.061713000000054, 30.730667000000039],
            [72.069568377000053, 30.728250202000027],
            [72.076762000000031, 30.726037000000076],
            [72.079163239000081, 30.725694001000079],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "126",
      properties: { name: "Badin", count: 432 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.970662341000036, 25.264793041000075],
            [68.971256029000074, 25.256589703000031],
            [68.974420190000046, 25.212868726000067],
            [68.974873000000059, 25.206612000000064],
            [68.975508412000067, 25.200026632000061],
            [68.976152565000064, 25.193350675000033],
            [68.977678089000051, 25.177540253000075],
            [68.978510895000056, 25.168909116000066],
            [68.979288646000043, 25.160848556000076],
            [68.979455257000041, 25.159121808000066],
            [68.980055372000038, 25.152902269000037],
            [68.98163025100007, 25.136580331000062],
            [68.982020000000034, 25.13254100000006],
            [68.998040574000072, 25.118705330000068],
            [69.000496332000068, 25.116584491000026],
            [69.007441368000059, 25.110586627000032],
            [69.010609000000045, 25.107851000000039],
            [69.021202310000035, 25.102626820000069],
            [69.029469194000058, 25.09854993600004],
            [69.058040000000062, 25.084460000000036],
            [69.068119253000077, 25.079308512000068],
            [69.086758671000041, 25.069781939000052],
            [69.08727900000008, 25.069516000000078],
            [69.088281230000064, 25.064361391000034],
            [69.090165436000063, 25.054670658000077],
            [69.090736456000059, 25.051733822000074],
            [69.09182700000008, 25.046125000000075],
            [69.094850550000046, 25.043990778000023],
            [69.102873000000045, 25.038328000000035],
            [69.117817000000059, 25.031181000000061],
            [69.130162000000041, 25.016887000000054],
            [69.133062677000055, 25.004704872000048],
            [69.133411000000081, 25.003242000000057],
            [69.133411000000081, 25.001671982000062],
            [69.133411000000081, 24.995781000000079],
            [69.133411000000081, 24.992196000000035],
            [69.139908000000048, 24.990897000000075],
            [69.14113278800005, 24.990470980000055],
            [69.153476109000053, 24.986177579000071],
            [69.154852000000062, 24.985699000000068],
            [69.159032721000074, 24.97733805300004],
            [69.159709666000083, 24.975984244000074],
            [69.163299000000052, 24.968806000000029],
            [69.173045000000059, 24.953862000000072],
            [69.175861799000074, 24.953079462000062],
            [69.184740000000033, 24.950613000000033],
            [69.189645121000069, 24.95168977000003],
            [69.19351856000003, 24.952540065000051],
            [69.211251571000048, 24.956432807000056],
            [69.211380000000077, 24.956461000000047],
            [69.236070000000041, 24.960359000000039],
            [69.253694968000048, 24.960359000000039],
            [69.263359000000037, 24.960359000000039],
            [69.264503149000063, 24.96013017000007],
            [69.26830916800003, 24.959368966000056],
            [69.289349000000072, 24.955161000000032],
            [69.294251053000039, 24.948968808000075],
            [69.301694000000055, 24.939567000000068],
            [69.305594993000057, 24.933882676000053],
            [69.311221709000051, 24.925683719000062],
            [69.323368664000043, 24.907983810000076],
            [69.324435000000051, 24.906430000000057],
            [69.325924496000084, 24.895506011000066],
            [69.325955339000075, 24.895279808000055],
            [69.327264680000042, 24.88567708000005],
            [69.328333000000043, 24.877842000000044],
            [69.321417790000055, 24.860119903000054],
            [69.317938000000083, 24.851202000000058],
            [69.317779511000083, 24.850986463000027],
            [69.316425972000047, 24.849145720000024],
            [69.301694000000055, 24.829111000000069],
            [69.306811813000081, 24.822714226000073],
            [69.306892000000062, 24.822614000000044],
            [69.310979107000037, 24.819387023000047],
            [69.319237000000044, 24.81286700000004],
            [69.332644000000073, 24.800679000000059],
            [69.327434000000039, 24.798183000000051],
            [69.326028052000083, 24.796174547000078],
            [69.313804000000061, 24.778712000000041],
            [69.276160000000061, 24.757294000000059],
            [69.261410019000039, 24.754016367000077],
            [69.252795000000049, 24.752102000000036],
            [69.256447648000062, 24.740331731000026],
            [69.258636000000081, 24.733280000000036],
            [69.285896000000037, 24.721597000000031],
            [69.241761000000054, 24.701477000000068],
            [69.223645372000078, 24.683945718000075],
            [69.221641000000034, 24.682006000000058],
            [69.219045000000051, 24.665780000000041],
            [69.221103694000078, 24.665436899000042],
            [69.236793596000041, 24.662822027000061],
            [69.242410000000064, 24.661886000000038],
            [69.252146000000039, 24.647608000000048],
            [69.237521822000076, 24.636117402000025],
            [69.233973000000049, 24.63332900000006],
            [69.233973000000049, 24.611911000000077],
            [69.235271000000068, 24.59568500000006],
            [69.226834000000053, 24.59568500000006],
            [69.219694000000061, 24.607368000000065],
            [69.206714000000034, 24.620997000000045],
            [69.196978000000058, 24.61515600000007],
            [69.198719936000032, 24.605139198000074],
            [69.199574000000041, 24.600228000000072],
            [69.209310000000073, 24.59438700000004],
            [69.221641000000034, 24.582704000000035],
            [69.222074272000043, 24.579671098000063],
            [69.222939000000054, 24.573618000000067],
            [69.209959000000083, 24.565180000000055],
            [69.209959000000083, 24.559886000000063],
            [69.209959000000083, 24.55674300000004],
            [69.218396000000041, 24.553498000000047],
            [69.220559647000073, 24.54430166800006],
            [69.220992000000081, 24.542464000000052],
            [69.208012000000053, 24.533378000000027],
            [69.207592247000036, 24.533110922000049],
            [69.200872000000061, 24.528835000000072],
            [69.195031000000029, 24.53857000000005],
            [69.182699000000071, 24.531431000000055],
            [69.16978799900005, 24.523646193000047],
            [69.145617446000074, 24.509072337000077],
            [69.138565000000028, 24.504820000000052],
            [69.10935900000004, 24.476263000000074],
            [69.106787953000037, 24.470264476000068],
            [69.103517000000068, 24.462633000000039],
            [69.088590000000067, 24.460686000000067],
            [69.077556000000072, 24.450951000000032],
            [69.076223911000056, 24.441847705000043],
            [69.07366200000007, 24.424340000000029],
            [69.072493873000042, 24.417331239000077],
            [69.07171500000004, 24.412658000000079],
            [69.063926000000038, 24.382153000000073],
            [69.063439420000066, 24.379233427000031],
            [69.058734000000072, 24.351000000000056],
            [69.057065320000049, 24.333894744000077],
            [69.056138000000033, 24.324389000000053],
            [69.060032000000035, 24.308163000000036],
            [69.060773546000064, 24.307187361000047],
            [69.07236400000005, 24.291938000000073],
            [69.082028000000037, 24.263969000000031],
            [69.082406862000028, 24.261341345000062],
            [69.075117000000034, 24.259217000000035],
            [69.029355087000056, 24.231982317000075],
            [69.027476000000036, 24.230864000000054],
            [69.009185000000059, 24.22620500000005],
            [69.001664000000062, 24.224411000000032],
            [68.979001000000039, 24.228206000000057],
            [68.963959000000045, 24.244622000000049],
            [68.956998000000056, 24.259571000000051],
            [68.953006000000073, 24.263115000000028],
            [68.952176000000065, 24.265482642000052],
            [68.946806000000038, 24.280801000000054],
            [68.93331900000004, 24.287820000000067],
            [68.926377000000059, 24.287262000000055],
            [68.91667700000005, 24.283831000000077],
            [68.908701538000059, 24.278148866000038],
            [68.906004000000053, 24.276227000000063],
            [68.90378000000004, 24.273497000000077],
            [68.897700000000043, 24.266835000000071],
            [68.887270000000058, 24.248660000000029],
            [68.883070000000032, 24.233271000000059],
            [68.878028000000029, 24.217038000000059],
            [68.872718599000052, 24.207711910000057],
            [68.869674000000032, 24.202364000000046],
            [68.859953000000075, 24.200538000000051],
            [68.853165000000047, 24.203479000000073],
            [68.848802000000035, 24.217605000000049],
            [68.844540000000052, 24.237676000000079],
            [68.839064000000064, 24.256514000000038],
            [68.837884000000031, 24.265816000000029],
            [68.835601000000054, 24.289239000000066],
            [68.83211155500004, 24.299269740000057],
            [68.831901000000073, 24.299875000000043],
            [68.826246000000083, 24.303108000000066],
            [68.812317000000064, 24.301320000000032],
            [68.79955300000006, 24.301528000000076],
            [68.797406478000028, 24.300399350000077],
            [68.797342653000044, 24.300365791000047],
            [68.794205000000034, 24.29871600000007],
            [68.79033400000003, 24.298077000000035],
            [68.776492000000076, 24.294106000000056],
            [68.770144000000073, 24.282665000000065],
            [68.768964372000028, 24.27315715800006],
            [68.768408309000051, 24.268675273000042],
            [68.764129798000056, 24.277103974000056],
            [68.760930581000082, 24.287789617000044],
            [68.757209000000046, 24.300220000000024],
            [68.747463000000039, 24.325560000000053],
            [68.746009151000067, 24.332668618000071],
            [68.744345981000038, 24.340800715000057],
            [68.744322582000052, 24.340915127000073],
            [68.741616000000079, 24.354149000000064],
            [68.737829253000029, 24.362596220000057],
            [68.733169000000032, 24.372992000000067],
            [68.725453067000046, 24.375168234000057],
            [68.707829000000061, 24.380139000000042],
            [68.67924000000005, 24.378839000000028],
            [68.646753000000047, 24.378839000000028],
            [68.616215000000068, 24.37689000000006],
            [68.604545145000031, 24.371288211000035],
            [68.599972000000037, 24.369093000000078],
            [68.582429000000047, 24.359997000000078],
            [68.561637000000076, 24.361946000000046],
            [68.549695811000049, 24.364471932000072],
            [68.549654798000063, 24.364480608000065],
            [68.541256818000079, 24.366257042000029],
            [68.533136352000042, 24.367974773000071],
            [68.527850000000058, 24.369093000000078],
            [68.516186379000033, 24.373334465000028],
            [68.506409000000076, 24.37689000000006],
            [68.505433070000038, 24.37721532300003],
            [68.481069000000048, 24.38533700000005],
            [68.478495679000048, 24.386141101000078],
            [68.467339006000032, 24.389627293000046],
            [68.460277000000076, 24.391834000000074],
            [68.448582000000044, 24.394433000000049],
            [68.450575909000065, 24.40440254400005],
            [68.451181000000076, 24.407428000000039],
            [68.460927000000083, 24.428220000000067],
            [68.457580795000069, 24.43714207000005],
            [68.457028000000037, 24.438616000000025],
            [68.452430845000038, 24.438360493000062],
            [68.442349063000052, 24.437800155000048],
            [68.43363800000003, 24.437316000000067],
            [68.424541000000033, 24.42757000000006],
            [68.416744000000051, 24.426270000000045],
            [68.413083110000059, 24.431151812000053],
            [68.412670132000073, 24.431702520000044],
            [68.410897000000034, 24.434067000000027],
            [68.409522479000032, 24.440389204000041],
            [68.407648000000052, 24.449011000000041],
            [68.407648000000052, 24.452359066000042],
            [68.407648000000052, 24.452441211000064],
            [68.407648000000052, 24.452850751000028],
            [68.407648000000052, 24.473424679000061],
            [68.407648000000052, 24.477600000000052],
            [68.408947000000069, 24.495143000000041],
            [68.404399000000069, 24.504240000000038],
            [68.394003000000055, 24.519184000000052],
            [68.394391311000049, 24.522134878000031],
            [68.396023479000064, 24.534538147000035],
            [68.397252000000037, 24.543874000000073],
            [68.403812541000036, 24.554183628000033],
            [68.406348000000037, 24.558168000000023],
            [68.401593222000031, 24.560179626000036],
            [68.398460281000041, 24.56150509400004],
            [68.389455000000055, 24.565315000000055],
            [68.384907000000055, 24.575062000000059],
            [68.375810000000058, 24.591955000000041],
            [68.376176564000048, 24.599283458000059],
            [68.376209817000074, 24.599948263000044],
            [68.376460000000066, 24.604950000000031],
            [68.382958000000031, 24.610797000000048],
            [68.382023085000071, 24.61765001200007],
            [68.381008000000065, 24.625092000000052],
            [68.373861000000034, 24.637437000000034],
            [68.36982807000004, 24.64180613700006],
            [68.366064000000051, 24.645884000000024],
            [68.354369000000077, 24.648483000000056],
            [68.354369000000077, 24.651958971000056],
            [68.354369000000077, 24.654330000000073],
            [68.360866000000044, 24.662777000000062],
            [68.361692395000034, 24.66470490200004],
            [68.361703666000039, 24.664731196000048],
            [68.363417809000055, 24.668730129000039],
            [68.364765000000034, 24.671873000000062],
            [68.364765000000034, 24.67403850900007],
            [68.364765000000034, 24.674768630000074],
            [68.364765000000034, 24.686817000000076],
            [68.364765000000034, 24.690613433000067],
            [68.364765000000034, 24.702411000000041],
            [68.365969814000039, 24.70494135000007],
            [68.369003105000047, 24.711311868000053],
            [68.371262000000058, 24.716056000000037],
            [68.371262000000058, 24.726452000000052],
            [68.35386360800004, 24.738695239000037],
            [68.353719000000069, 24.738797000000034],
            [68.35647818700005, 24.752198189000069],
            [68.356478968000033, 24.752202360000069],
            [68.357618000000059, 24.758289000000048],
            [68.357755906000079, 24.758404545000076],
            [68.381658000000073, 24.778431000000069],
            [68.389354830000059, 24.780996522000066],
            [68.409233854000036, 24.787622637000027],
            [68.410897000000034, 24.788177000000076],
            [68.43363800000003, 24.791426000000058],
            [68.434057909000046, 24.792625806000046],
            [68.43513733900005, 24.795710061000023],
            [68.44273400000003, 24.817416000000037],
            [68.449685534000082, 24.830884430000026],
            [68.453130000000044, 24.837558000000058],
            [68.455410543000028, 24.838514284000041],
            [68.473272000000065, 24.84600400000005],
            [68.475151310000058, 24.850389324000048],
            [68.475592880000079, 24.851419716000066],
            [68.480752797000036, 24.863460259000078],
            [68.48496700000004, 24.873294000000044],
            [68.491465000000062, 24.859649000000047],
            [68.495763522000061, 24.859649000000047],
            [68.498984251000081, 24.859649000000047],
            [68.499262000000044, 24.859649000000047],
            [68.503031617000033, 24.863890024000057],
            [68.517603044000055, 24.880283667000072],
            [68.518105512000034, 24.880848971000034],
            [68.520053000000075, 24.883040000000051],
            [68.523554064000052, 24.884698220000075],
            [68.526409271000034, 24.886050540000042],
            [68.532209839000075, 24.888797881000073],
            [68.532398000000057, 24.888887000000068],
            [68.549292000000037, 24.879791000000068],
            [68.558636148000062, 24.881489995000038],
            [68.559515522000083, 24.881649887000037],
            [68.563586000000043, 24.882390000000044],
            [68.563609072000077, 24.882366006000041],
            [68.579830000000072, 24.865497000000062],
            [68.580209380000042, 24.865661050000028],
            [68.589134970000032, 24.86952061900007],
            [68.596372606000045, 24.872650290000024],
            [68.625788938000028, 24.885370386000034],
            [68.62791100000004, 24.886288000000036],
            [68.632466642000054, 24.886591834000058],
            [68.632612694000045, 24.886601575000043],
            [68.637657000000047, 24.886938000000043],
            [68.645319344000029, 24.895779724000079],
            [68.646103000000039, 24.89668400000005],
            [68.646103000000039, 24.899832669000034],
            [68.649783300000081, 24.898781155000052],
            [68.655851537000046, 24.895241350000049],
            [68.658759234000058, 24.894988507000051],
            [68.663563256000032, 24.894862085000057],
            [68.667355904000033, 24.89587345800004],
            [68.672286347000068, 24.896379144000036],
            [68.676205416000073, 24.897011252000027],
            [68.680756594000059, 24.894609242000058],
            [68.682273654000028, 24.893345026000077],
            [68.683285027000068, 24.892333653000037],
            [68.683570495000083, 24.892170528000065],
            [68.68303218300008, 24.890690172000063],
            [68.679618800000071, 24.887150367000061],
            [68.676584681000065, 24.882725610000023],
            [68.675699730000076, 24.879438649000065],
            [68.676711103000059, 24.876530952000053],
            [68.676963946000058, 24.874381784000036],
            [68.676837524000064, 24.870462714000041],
            [68.676078995000069, 24.868187125000077],
            [68.674561936000032, 24.866164380000043],
            [68.674785268000051, 24.865558194000073],
            [68.672286347000068, 24.863635948000024],
            [68.670263601000045, 24.862371731000053],
            [68.666218109000056, 24.860728251000069],
            [68.662678304000053, 24.860222564000026],
            [68.657368597000072, 24.85883192600005],
            [68.653955213000074, 24.857314867000071],
            [68.650415408000072, 24.856809181000074],
            [68.650321763000079, 24.856809181000074],
            [68.649109052000028, 24.854744294000056],
            [68.648097679000045, 24.853227235000077],
            [68.648350522000044, 24.847158998000054],
            [68.649741160000076, 24.845641939000075],
            [68.652901700000029, 24.842228555000077],
            [68.656315083000038, 24.838562329000069],
            [68.660234153000033, 24.837298112000042],
            [68.66238332100005, 24.835781053000062],
            [68.666808077000042, 24.832999778000044],
            [68.668451558000072, 24.83135629700007],
            [68.674266952000039, 24.826046589000043],
            [68.674772638000036, 24.82440310800007],
            [68.672497049000071, 24.818840558000034],
            [68.670727147000036, 24.815932861000078],
            [68.670898381000029, 24.814819837000073],
            [68.671553207000045, 24.814277913000069],
            [68.671062954000035, 24.812958002000073],
            [68.669925160000048, 24.810753525000052],
            [68.67006830300005, 24.810624235000034],
            [68.669344410000065, 24.810196480000059],
            [68.663655438000035, 24.800335595000035],
            [68.663939887000083, 24.799292616000059],
            [68.664588475000073, 24.798930170000062],
            [68.667163638000034, 24.797491108000031],
            [68.675602280000078, 24.795120703000066],
            [68.680437907000055, 24.794267357000024],
            [68.682808312000077, 24.793888093000078],
            [68.684395176000066, 24.794354817000055],
            [68.684574264000048, 24.793971057000078],
            [68.686494292000077, 24.793544384000029],
            [68.689054329000044, 24.794539954000072],
            [68.691116582000063, 24.796104421000052],
            [68.69367661900003, 24.798948908000057],
            [68.696521106000034, 24.799660029000052],
            [68.698085573000071, 24.799731141000052],
            [68.699863377000042, 24.797171104000029],
            [68.701356732000079, 24.793544384000029],
            [68.703205648000051, 24.791126571000063],
            [68.705765686000063, 24.789064318000044],
            [68.706448244000057, 24.789003646000026],
            [68.70896573300007, 24.788779869000052],
            [68.711312434000035, 24.789490991000037],
            [68.714868042000035, 24.791197683000064],
            [68.716633652000041, 24.792910588000041],
            [68.716618191000066, 24.792655482000043],
            [68.718388093000044, 24.793919698000025],
            [68.71990515300007, 24.793540433000032],
            [68.722307163000039, 24.791896952000059],
            [68.727743292000071, 24.79101200100007],
            [68.73014684900005, 24.790922980000062],
            [68.731156676000069, 24.790885579000076],
            [68.735960697000053, 24.791896952000059],
            [68.741902512000081, 24.791896952000059],
            [68.745541188000061, 24.791135369000074],
            [68.745465000000081, 24.791783000000066],
            [68.746020000000044, 24.803982000000076],
            [68.746364776000064, 24.80777826700006],
            [68.750529952000079, 24.807689646000028],
            [68.756429627000045, 24.807689646000028],
            [68.76114936700003, 24.808195333000072],
            [68.765531983000074, 24.810049516000049],
            [68.770420285000057, 24.813083635000055],
            [68.77143165800004, 24.813589321000052],
            [68.774297214000057, 24.815274943000077],
            [68.780871137000076, 24.82555723300004],
            [68.781169771000066, 24.82693094800004],
            [68.781376824000063, 24.826568606000023],
            [68.785340362000056, 24.831275308000045],
            [68.786096564000047, 24.830951222000067],
            [68.786265126000046, 24.82808566500006],
            [68.786265126000046, 24.825894357000038],
            [68.786939374000042, 24.823365925000076],
            [68.78963636900005, 24.821848866000039],
            [68.794018984000047, 24.821006055000055],
            [68.796210292000069, 24.820500369000058],
            [68.798064475000047, 24.818140499000037],
            [68.799037862000034, 24.817751144000056],
            [68.799750097000071, 24.817466250000052],
            [68.79926314000005, 24.816979293000031],
            [68.802109967000035, 24.816623440000058],
            [68.806829707000077, 24.816454878000059],
            [68.812897944000042, 24.817297688000053],
            [68.818797619000065, 24.818983310000078],
            [68.819194235000055, 24.819826120000073],
            [68.821494613000084, 24.824714422000056],
            [68.823854483000048, 24.830108411000026],
            [68.82503441800003, 24.832805405000045],
            [68.827057163000063, 24.836513772000046],
            [68.829417033000084, 24.840559263000046],
            [68.829932962000044, 24.843052918000069],
            [68.829767814000036, 24.843038238000076],
            [68.829009284000051, 24.84468171900005],
            [68.829641392000042, 24.848347946000047],
            [68.829267816000083, 24.849522041000057],
            [68.829767814000036, 24.849991427000077],
            [68.831537716000071, 24.860105155000042],
            [68.833054775000051, 24.863771382000039],
            [68.833549680000033, 24.867111986000054],
            [68.835203943000067, 24.868069717000026],
            [68.837226688000044, 24.871483100000034],
            [68.839628699000059, 24.874390797000046],
            [68.842283553000073, 24.87704565100006],
            [68.843391912000072, 24.877956088000076],
            [68.843180333000078, 24.878167667000071],
            [68.846284042000036, 24.882366804000071],
            [68.847927523000067, 24.888182198000038],
            [68.848686053000051, 24.895514651000042],
            [68.847168993000082, 24.902847104000045],
            [68.84573303500008, 24.90489847300006],
            [68.845399091000047, 24.905375536000065],
            [68.841353599000058, 24.909421027000064],
            [68.839457275000029, 24.911696616000029],
            [68.837308108000059, 24.915110000000027],
            [68.835315311000045, 24.91873326700005],
            [68.835680430000082, 24.918950056000028],
            [68.835016716000041, 24.921794542000043],
            [68.835016716000041, 24.924544212000058],
            [68.83492190000004, 24.928431676000059],
            [68.835392899000055, 24.929452174000062],
            [68.835394225000073, 24.929434270000058],
            [68.839313295000068, 24.93133059400003],
            [68.841083198000035, 24.931077751000032],
            [68.845255111000029, 24.931077751000032],
            [68.851702613000043, 24.93385902600005],
            [68.853725358000077, 24.935502507000024],
            [68.856759477000082, 24.939295155000025],
            [68.859035066000047, 24.937904518000039],
            [68.861184233000074, 24.936766723000062],
            [68.862322027000062, 24.934111869000048],
            [68.864850460000071, 24.933100497000055],
            [68.867631735000032, 24.933479761000058],
            [68.868516686000078, 24.935628929000075],
            [68.868620931000066, 24.937140472000067],
            [68.868769529000076, 24.939295155000025],
            [68.867758157000083, 24.944099176000066],
            [68.866493940000055, 24.949156041000037],
            [68.865861832000064, 24.95269584600004],
            [68.866200657000036, 24.956558444000052],
            [68.865861832000064, 24.956614915000046],
            [68.862448449000055, 24.954845013000067],
            [68.856633055000032, 24.950293835000025],
            [68.855242418000046, 24.950040992000027],
            [68.85220829900004, 24.953327954000031],
            [68.85069124000006, 24.953707218000034],
            [68.84904775900003, 24.953833640000028],
            [68.847404278000056, 24.953075110000043],
            [68.846392905000073, 24.955477121000058],
            [68.845760797000082, 24.959016926000061],
            [68.846519327000067, 24.962683152000068],
            [68.845249511000077, 24.987155960000052],
            [68.842915178000055, 24.986961434000023],
            [68.842915142000038, 24.986961431000054],
            [68.836406000000068, 24.986419000000069],
            [68.830861000000084, 24.985310000000027],
            [68.828073367000059, 24.985310000000027],
            [68.821988000000033, 24.985310000000027],
            [68.821721215000082, 24.985248411000043],
            [68.817341488000068, 24.984237331000031],
            [68.814780000000042, 24.983646000000078],
            [68.81093721700006, 24.982205390000047],
            [68.800362000000064, 24.978101000000038],
            [68.789272000000039, 24.976437000000033],
            [68.783727000000056, 24.97366500000004],
            [68.779686673000072, 24.972435816000029],
            [68.779306000000076, 24.972320000000025],
            [68.773251497000047, 24.970476692000034],
            [68.770973000000083, 24.969783000000064],
            [68.767971512000031, 24.96823057000006],
            [68.756368576000057, 24.962229298000068],
            [68.745747314000027, 24.969436707000057],
            [68.739017000000047, 24.974004000000036],
            [68.729306352000037, 24.980895272000055],
            [68.718875000000082, 24.988298000000043],
            [68.712988977000066, 24.987457509000023],
            [68.709778000000028, 24.986999000000026],
            [68.706144274000053, 24.991843967000023],
            [68.703931000000068, 24.994795000000067],
            [68.696783000000039, 25.004542000000072],
            [68.664296000000036, 25.025333000000046],
            [68.652630487000067, 25.030044298000064],
            [68.652000371000042, 25.030298781000056],
            [68.64916134300006, 25.031445366000071],
            [68.639609346000043, 25.035303088000035],
            [68.630510000000072, 25.038978000000043],
            [68.609535264000044, 25.055956853000055],
            [68.603220000000078, 25.061069000000032],
            [68.597971851000068, 25.065770576000034],
            [68.590540617000045, 25.072427876000063],
            [68.573266508000074, 25.08790295700004],
            [68.572033000000033, 25.089008000000035],
            [68.576843594000081, 25.090812204000031],
            [68.577206832000059, 25.090948436000076],
            [68.57749192600005, 25.091055360000041],
            [68.57751681700006, 25.091064695000057],
            [68.582429000000047, 25.092907000000025],
            [68.602571000000069, 25.100054000000057],
            [68.602824929000064, 25.100225011000077],
            [68.608116856000038, 25.103788922000035],
            [68.619875081000032, 25.111707636000062],
            [68.63364852400008, 25.120983522000074],
            [68.634408000000064, 25.121495000000039],
            [68.640816676000043, 25.126344758000073],
            [68.658449000000076, 25.139688000000035],
            [68.661185297000031, 25.145161295000037],
            [68.666061778000028, 25.154915509000034],
            [68.66624500000006, 25.155282000000057],
            [68.657718470000077, 25.156093783000074],
            [68.652601000000061, 25.156581000000074],
            [68.638956000000064, 25.150734000000057],
            [68.628035794000084, 25.150734000000057],
            [68.622063000000082, 25.150734000000057],
            [68.614916000000051, 25.159180000000049],
            [68.625556690000053, 25.16775178100005],
            [68.638307000000054, 25.178023000000053],
            [68.647401851000041, 25.183527798000057],
            [68.654326362000063, 25.187718964000055],
            [68.662997000000075, 25.192967000000067],
            [68.685738000000072, 25.196216000000049],
            [68.700682000000029, 25.200114000000042],
            [68.700682000000029, 25.207160511000041],
            [68.700682000000029, 25.212323582000067],
            [68.700682000000029, 25.217008000000078],
            [68.711727000000053, 25.228703000000053],
            [68.725458532000061, 25.235866918000056],
            [68.726672000000065, 25.236500000000035],
            [68.73381900000004, 25.248845000000074],
            [68.73747859000008, 25.253502567000055],
            [68.740966000000071, 25.257941000000073],
            [68.76285529200004, 25.248986672000058],
            [68.769555000000082, 25.246246000000042],
            [68.769555000000082, 25.232960116000072],
            [68.769555000000082, 25.226104000000078],
            [68.770854000000043, 25.208561000000032],
            [68.783922856000061, 25.207053204000033],
            [68.787747000000081, 25.206612000000064],
            [68.805940000000078, 25.216358000000071],
            [68.807240570000033, 25.216439275000027],
            [68.837128000000064, 25.218307000000038],
            [68.841042382000069, 25.21891443800007],
            [68.85203950600004, 25.22062098400005],
            [68.874813000000074, 25.224155000000053],
            [68.885859000000039, 25.228703000000053],
            [68.886720980000064, 25.235476023000047],
            [68.888559906000069, 25.249925424000025],
            [68.888625410000031, 25.250440107000031],
            [68.888625413000057, 25.250440144000038],
            [68.890407000000039, 25.264439000000039],
            [68.904701000000045, 25.261840000000063],
            [68.905737966000061, 25.262315259000047],
            [68.920295000000067, 25.268987000000038],
            [68.922385917000042, 25.269778139000039],
            [68.937458994000053, 25.275481330000048],
            [68.944335000000081, 25.278083000000038],
            [68.96122900000006, 25.296926000000042],
            [68.967726000000084, 25.297576000000049],
            [68.96826800000008, 25.297877000000028],
            [68.970662341000036, 25.264793041000075],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "127",
      properties: { name: "Dadu", count: 1364 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.262784977000081, 27.394198294000034],
            [67.264305427000068, 27.392920667000055],
            [67.267406642000083, 27.393216621000079],
            [67.270971793000058, 27.393556840000031],
            [67.272735423000029, 27.39336529600007],
            [67.275693961000059, 27.393039900000076],
            [67.27653377300004, 27.392947657000036],
            [67.277400121000028, 27.392773136000073],
            [67.282162711000069, 27.391813744000046],
            [67.284327614000063, 27.391377638000051],
            [67.284761378000042, 27.391290259000073],
            [67.285636547000081, 27.391091571000061],
            [67.289577594000036, 27.390196839000055],
            [67.291328784000029, 27.389799269000036],
            [67.292366089000041, 27.389397224000049],
            [67.29257949600003, 27.389314510000077],
            [67.297158695000064, 27.387539679000042],
            [67.298409382000045, 27.387054931000023],
            [67.299489639000058, 27.386277389000043],
            [67.302802410000083, 27.383892940000067],
            [67.303801916000054, 27.383173520000071],
            [67.304662030000031, 27.382233254000028],
            [67.30638940800003, 27.380344643000058],
            [67.306784322000055, 27.379913113000043],
            [67.306951720000029, 27.379730193000057],
            [67.307535273000042, 27.378858591000039],
            [67.309188909000056, 27.376388698000028],
            [67.309615304000033, 27.375751828000034],
            [67.310145429000045, 27.374857102000078],
            [67.311440644000072, 27.372671083000057],
            [67.312826055000073, 27.369450653000058],
            [67.314248162000069, 27.36713573000003],
            [67.316452019000053, 27.367019725000034],
            [67.317528383000081, 27.367511126000068],
            [67.323202965000064, 27.37010179400005],
            [67.325684242000079, 27.371127842000078],
            [67.329805920000069, 27.372832165000034],
            [67.331375817000037, 27.37348140000006],
            [67.332911505000084, 27.37406190300004],
            [67.335394415000053, 27.375000463000049],
            [67.337120111000047, 27.375539651000054],
            [67.33838546700008, 27.375935008000056],
            [67.33961533300004, 27.375752423000051],
            [67.34020855600005, 27.375664341000061],
            [67.340662411000039, 27.375596944000051],
            [67.341672875000029, 27.375446890000035],
            [67.34256643100008, 27.374763625000071],
            [67.345119898000064, 27.372707126000023],
            [67.346066777000033, 27.371943787000077],
            [67.346525721000035, 27.370542002000036],
            [67.347094174000063, 27.368803099000047],
            [67.347358049000036, 27.367998017000048],
            [67.347496297000077, 27.366828737000048],
            [67.347749793000048, 27.364684706000048],
            [67.347871755000028, 27.36365317800005],
            [67.348082986000065, 27.361889264000069],
            [67.348111834000065, 27.361648362000039],
            [67.348298256000078, 27.360097482000072],
            [67.348541843000078, 27.358942393000063],
            [67.348918593000064, 27.357161674000054],
            [67.350549324000042, 27.356868507000058],
            [67.351725066000029, 27.356657244000075],
            [67.352364484000077, 27.35658703200005],
            [67.357036162000043, 27.356889147000061],
            [67.357612872000061, 27.356926443000077],
            [67.358333705000064, 27.357115323000073],
            [67.359618703000081, 27.357452020000039],
            [67.361517888000037, 27.357963282000071],
            [67.362595667000051, 27.359458600000039],
            [67.362967684000068, 27.35981565700007],
            [67.363166962000037, 27.360586987000033],
            [67.36402060000006, 27.36290325300007],
            [67.36405009200007, 27.365655232000051],
            [67.364069482000048, 27.36685307700003],
            [67.36407459000003, 27.367168664000076],
            [67.364576846000034, 27.368846965000046],
            [67.366178608000041, 27.374193251000065],
            [67.366187000000082, 27.374221104000071],
            [67.366551579000031, 27.375431136000032],
            [67.36728056700008, 27.376409528000067],
            [67.368688309000049, 27.378297191000058],
            [67.369390192000083, 27.37913607400003],
            [67.370090710000056, 27.379640766000023],
            [67.372050331000082, 27.381045009000047],
            [67.374543274000075, 27.382394598000076],
            [67.375772728000072, 27.38305321100006],
            [67.377271208000082, 27.383120917000042],
            [67.381581065000034, 27.383316145000038],
            [67.382387837000067, 27.383352444000025],
            [67.383276738000063, 27.383285538000052],
            [67.386775887000056, 27.383017525000071],
            [67.388107595000065, 27.382915525000044],
            [67.389390997000078, 27.382641517000025],
            [67.395382742000038, 27.381362271000057],
            [67.397094805000052, 27.380996743000026],
            [67.398762164000061, 27.380537385000025],
            [67.403763150000032, 27.379159609000055],
            [67.405015843000058, 27.378814491000071],
            [67.406550032000041, 27.378162026000041],
            [67.407317470000066, 27.377835609000044],
            [67.408469689000071, 27.377345500000047],
            [67.411164565000036, 27.376199315000065],
            [67.413473585000077, 27.375217243000066],
            [67.415781711000079, 27.37431194100003],
            [67.417489114000034, 27.37364225500005],
            [67.418665557000054, 27.373034491000055],
            [67.419841813000062, 27.372426761000042],
            [67.421803165000028, 27.371413307000068],
            [67.423994251000067, 27.37028123500005],
            [67.425817519000077, 27.369339205000074],
            [67.426212622000037, 27.369145351000043],
            [67.42962986200007, 27.367474789000028],
            [67.432756257000051, 27.36594640900006],
            [67.434472306000032, 27.365107497000054],
            [67.435118149000061, 27.363027067000075],
            [67.435180918000071, 27.362823805000062],
            [67.435361735000072, 27.362238276000028],
            [67.43591168100005, 27.360769839000056],
            [67.436577940000063, 27.359011783000028],
            [67.43818613600007, 27.357244684000079],
            [67.439486554000041, 27.356969844000048],
            [67.441945429000043, 27.356457459000069],
            [67.442239270000073, 27.356396228000051],
            [67.446761424000044, 27.356025617000057],
            [67.449199732000068, 27.355311232000076],
            [67.450697552000065, 27.355276685000035],
            [67.452613609000082, 27.355232491000038],
            [67.455011222000053, 27.354980803000046],
            [67.456191581000041, 27.354856895000069],
            [67.457202661000053, 27.354041793000079],
            [67.458842880000077, 27.352719499000045],
            [67.459854323000059, 27.351904105000074],
            [67.460245875000055, 27.351043338000068],
            [67.460471494000046, 27.350547349000067],
            [67.460602270000038, 27.350259858000072],
            [67.461501361000046, 27.348283346000073],
            [67.462091446000045, 27.34727876200003],
            [67.463620613000046, 27.344679691000067],
            [67.464042146000054, 27.343963226000028],
            [67.464464255000053, 27.343245782000054],
            [67.466948985000045, 27.342285290000063],
            [67.467702220000035, 27.342156382000042],
            [67.468873580000036, 27.341955905000077],
            [67.473091322000073, 27.341234060000033],
            [67.474631431000034, 27.340553453000041],
            [67.477999580000073, 27.339064994000069],
            [67.478864859000055, 27.338682609000045],
            [67.479858522000029, 27.338113802000066],
            [67.480850431000079, 27.337546109000073],
            [67.481776569000033, 27.337016007000045],
            [67.482578000000046, 27.33650700000004],
            [67.486260104000053, 27.333769001000064],
            [67.488617177000037, 27.33201629000007],
            [67.489876462000041, 27.331196977000047],
            [67.491090975000077, 27.33040687600004],
            [67.491750858000046, 27.329686096000046],
            [67.492557916000067, 27.328804559000048],
            [67.493347530000051, 27.327902128000062],
            [67.495681757000057, 27.325216590000025],
            [67.496147050000047, 27.324681268000063],
            [67.497140496000043, 27.323538304000067],
            [67.497872947000076, 27.322268325000039],
            [67.498742640000046, 27.320760386000075],
            [67.500459893000084, 27.319235284000058],
            [67.502139709000062, 27.318903567000064],
            [67.502881353000078, 27.318762179000032],
            [67.508163119000073, 27.317481815000065],
            [67.508536109000033, 27.317391397000051],
            [67.508951952000075, 27.317290592000063],
            [67.509471783000038, 27.317166340000028],
            [67.510574414000075, 27.316902299000049],
            [67.512984571000061, 27.316468353000062],
            [67.513930522000067, 27.316300556000044],
            [67.522582699000054, 27.314765799000043],
            [67.528191340000035, 27.314552569000057],
            [67.529636521000043, 27.314497663000054],
            [67.530942807000031, 27.314681749000044],
            [67.531772455000066, 27.314798665000069],
            [67.533053855000048, 27.314979244000028],
            [67.536899853000079, 27.315521234000073],
            [67.539036294000084, 27.315822252000032],
            [67.540409841000042, 27.315936470000054],
            [67.540839185000038, 27.315972155000054],
            [67.541692504000082, 27.316043076000028],
            [67.54245759500003, 27.316106665000063],
            [67.545561945000031, 27.316364675000045],
            [67.547278250000033, 27.316507315000024],
            [67.549486654000077, 27.316783574000056],
            [67.556109547000062, 27.317612062000023],
            [67.556989208000061, 27.317722103000051],
            [67.557914463000031, 27.317719873000044],
            [67.559293314000058, 27.317716614000062],
            [67.560618052000052, 27.317713415000071],
            [67.561609059000034, 27.317711022000026],
            [67.564249794000034, 27.31722366200006],
            [67.564408749000052, 27.317194326000049],
            [67.565924173000042, 27.316470176000053],
            [67.568204947000083, 27.315380048000065],
            [67.56986900000004, 27.314584765000063],
            [67.571204127000044, 27.314201947000072],
            [67.574307881000038, 27.313312014000076],
            [67.575761643000078, 27.312895180000055],
            [67.577494518000037, 27.312771827000063],
            [67.582704952000029, 27.312400928000045],
            [67.585304824000048, 27.312215859000048],
            [67.585742412000059, 27.312184634000062],
            [67.594507332000035, 27.311559202000069],
            [67.596260418000043, 27.311434109000061],
            [67.597559925000041, 27.31153977200006],
            [67.604919475000031, 27.312138177000065],
            [67.606650848000072, 27.312278955000068],
            [67.608267883000053, 27.31286168500003],
            [67.608468876000074, 27.312934117000054],
            [67.613570106000054, 27.314772445000074],
            [67.614698201000067, 27.315178963000051],
            [67.616204783000057, 27.315721902000064],
            [67.61705859500006, 27.316282321000074],
            [67.617688531000056, 27.316695795000044],
            [67.620257219000052, 27.31838181300003],
            [67.621873608000044, 27.319442768000044],
            [67.623823903000073, 27.32104786900004],
            [67.625158067000029, 27.32214607700007],
            [67.626520431000074, 27.323245988000053],
            [67.627819185000078, 27.323501774000079],
            [67.631564372000071, 27.324238241000046],
            [67.632400340000061, 27.324402461000034],
            [67.633392757000081, 27.324287312000024],
            [67.634776784000053, 27.324126686000056],
            [67.635211740000045, 27.324076193000053],
            [67.635977634000028, 27.323987283000065],
            [67.636861060000058, 27.323495946000037],
            [67.639841182000055, 27.321837979000065],
            [67.640890959000046, 27.321254635000059],
            [67.641954659000078, 27.319782223000061],
            [67.642775480000068, 27.318646043000058],
            [67.643982364000067, 27.316975807000063],
            [67.644638043000043, 27.316068414000028],
            [67.64513282300004, 27.315399190000051],
            [67.645802471000081, 27.314552436000042],
            [67.648739890000058, 27.310838140000044],
            [67.649793650000049, 27.309505686000023],
            [67.651043480000055, 27.308381695000037],
            [67.652917237000054, 27.306696964000025],
            [67.654085727000052, 27.305646261000049],
            [67.654397096000082, 27.305366279000054],
            [67.654726288000063, 27.305083626000055],
            [67.657925771000066, 27.302336470000057],
            [67.66225178500008, 27.301724666000041],
            [67.663235372000031, 27.301585709000051],
            [67.663605019000045, 27.301533487000029],
            [67.666659710000033, 27.301358699000048],
            [67.666748872000028, 27.301402340000038],
            [67.674604036000062, 27.305182767000076],
            [67.675779059000035, 27.305506528000024],
            [67.677494950000039, 27.305626197000038],
            [67.68420284900003, 27.305650870000079],
            [67.701320580000072, 27.305876915000056],
            [67.723325018000082, 27.306209205000073],
            [67.733128947000068, 27.306363288000057],
            [67.739048865000029, 27.306463318000056],
            [67.741094470000064, 27.306497883000077],
            [67.756352874000072, 27.30667842400004],
            [67.763390583000046, 27.306761695000034],
            [67.779346415000077, 27.307036857000071],
            [67.799172923000071, 27.307338178000066],
            [67.805375689000073, 27.307586448000052],
            [67.81733037500004, 27.30820118400004],
            [67.826501643000029, 27.30867279000006],
            [67.831178383000065, 27.309495095000045],
            [67.840607136000074, 27.310869918000037],
            [67.842707431000065, 27.311470717000077],
            [67.84862243300006, 27.312831392000078],
            [67.849534700000049, 27.313046309000072],
            [67.86044778400003, 27.315617278000047],
            [67.861449093000033, 27.31585317300005],
            [67.868528903000083, 27.317613281000035],
            [67.869305722000036, 27.317806406000045],
            [67.871055339000065, 27.318259110000042],
            [67.871089962000042, 27.318268068000066],
            [67.880681414000037, 27.320749805000048],
            [67.881914368000082, 27.320954171000039],
            [67.883002645000033, 27.321092478000025],
            [67.888094648000049, 27.320420083000045],
            [67.890836634000038, 27.320039819000044],
            [67.893841622000082, 27.319623082000078],
            [67.894861818000038, 27.31930714300006],
            [67.90405336300006, 27.315425774000062],
            [67.915485824000029, 27.310681573000068],
            [67.924531371000057, 27.306732886000077],
            [67.926426048000053, 27.306099242000073],
            [67.928389002000074, 27.305919895000045],
            [67.928812832000062, 27.305880621000028],
            [67.93420517900006, 27.305380951000075],
            [67.936892846000035, 27.30509220600004],
            [67.947203801000057, 27.303984463000063],
            [67.965354776000083, 27.302046512000061],
            [67.970786524000061, 27.301466573000027],
            [67.97185728200003, 27.301352250000036],
            [67.998163637000062, 27.298543562000077],
            [67.995427956000071, 27.292389019000041],
            [67.99422971000007, 27.290300072000036],
            [67.99363358800008, 27.289260589000037],
            [67.993411678000029, 27.288873823000074],
            [67.993202547000067, 27.288509331000057],
            [67.992915425000035, 27.287972603000071],
            [67.992609065000067, 27.287399914000048],
            [67.992309030000058, 27.286839048000047],
            [67.991295777000062, 27.28494493900007],
            [67.990485332000048, 27.283429946000069],
            [67.989765183000031, 27.28238026300005],
            [67.987957717000029, 27.279745717000026],
            [67.987333191000062, 27.278901630000064],
            [67.986260407000032, 27.277476316000048],
            [67.985797289000061, 27.276860893000048],
            [67.98527615900008, 27.276186474000042],
            [67.984616503000041, 27.275506982000024],
            [67.980715921000069, 27.271497459000045],
            [67.978980006000029, 27.269804830000055],
            [67.97791625800005, 27.268767607000029],
            [67.976868532000083, 27.267746128000056],
            [67.976731003000054, 27.267633690000025],
            [67.97589636400005, 27.266951329000051],
            [67.975511281000081, 27.266636524000035],
            [67.975248990000068, 27.266422101000046],
            [67.973953763000054, 27.26536330700003],
            [67.973531751000053, 27.265018313000041],
            [67.971625525000036, 27.263459975000046],
            [67.969360377000044, 27.261555817000044],
            [67.967977571000063, 27.260393386000032],
            [67.967741095000065, 27.260194597000066],
            [67.965629745000058, 27.258800479000058],
            [67.96246085100006, 27.256707811000069],
            [67.961204399000053, 27.255878119000045],
            [67.960548004000032, 27.255384819000028],
            [67.959893267000041, 27.254892766000069],
            [67.959238460000051, 27.254400659000055],
            [67.958260303000031, 27.253665547000026],
            [67.957707325000058, 27.253122217000055],
            [67.95556827200005, 27.251020487000062],
            [67.954983387000084, 27.250540459000035],
            [67.954551700000081, 27.250186165000059],
            [67.954152976000046, 27.249858924000023],
            [67.953559947000031, 27.249372212000026],
            [67.95333170400005, 27.248716611000077],
            [67.953104550000035, 27.24805748700004],
            [67.952854000000059, 27.247321923000072],
            [67.952602902000081, 27.246584750000068],
            [67.952801808000061, 27.243874149000078],
            [67.952854000000059, 27.243759620000048],
            [67.953338341000062, 27.242696795000029],
            [67.954415361000031, 27.240354241000034],
            [67.954608111000084, 27.239935004000074],
            [67.955079578000039, 27.238739474000056],
            [67.955705409000075, 27.237150633000056],
            [67.95632885100008, 27.235569112000064],
            [67.956297930000062, 27.234395680000034],
            [67.956214205000038, 27.231169778000037],
            [67.956198184000073, 27.230497548000073],
            [67.955931313000065, 27.229468718000078],
            [67.955759427000032, 27.229168855000069],
            [67.954819372000031, 27.227528890000031],
            [67.953880423000044, 27.226718361000053],
            [67.952940959000045, 27.225909156000057],
            [67.951968565000072, 27.225528619000045],
            [67.950006894000069, 27.224760581000055],
            [67.949493958000062, 27.224559786000043],
            [67.948627455000064, 27.224357245000078],
            [67.947750666000047, 27.224152300000071],
            [67.946872380000059, 27.22394700500007],
            [67.942964142000051, 27.223033473000044],
            [67.942336228000045, 27.222860983000032],
            [67.941814624000074, 27.222713977000069],
            [67.940918104000048, 27.222461306000071],
            [67.940204388000041, 27.221898530000033],
            [67.939683921000039, 27.221128779000026],
            [67.939771976000031, 27.219733759000064],
            [67.939871817000039, 27.218356880000044],
            [67.939896002000069, 27.217916995000053],
            [67.94011613400005, 27.213913193000053],
            [67.940360457000054, 27.20947039400005],
            [67.940355976000035, 27.208041465000065],
            [67.940353990000062, 27.207381618000056],
            [67.940349902000037, 27.206054920000042],
            [67.94034651000004, 27.204979431000027],
            [67.941089767000051, 27.202223541000023],
            [67.94249235400008, 27.201282996000032],
            [67.944241375000047, 27.200109368000028],
            [67.945905407000055, 27.199147264000032],
            [67.948880174000067, 27.197426940000071],
            [67.950369402000035, 27.196565711000062],
            [67.95082569200008, 27.196275743000058],
            [67.951839358000029, 27.195631570000046],
            [67.956591383000045, 27.192611709000062],
            [67.956614568000077, 27.192596975000072],
            [67.958082565000041, 27.191664079000077],
            [67.959582253000065, 27.190775963000078],
            [67.96332835700008, 27.188557699000057],
            [67.963755442000036, 27.188304794000032],
            [67.964553615000057, 27.187832143000037],
            [67.965413887000068, 27.187290396000037],
            [67.966704633000063, 27.186477545000059],
            [67.968095326000082, 27.185601691000045],
            [67.969073798000068, 27.184985464000079],
            [67.970450185000061, 27.184118656000066],
            [67.970877064000035, 27.183913426000061],
            [67.971114407000073, 27.183799320000048],
            [67.97260841800005, 27.183081046000041],
            [67.973418167000034, 27.182691744000067],
            [67.973447886000031, 27.182676107000077],
            [67.97381585200003, 27.182482495000045],
            [67.976983797000059, 27.180815634000055],
            [67.978171219000046, 27.180190855000035],
            [67.979708566000056, 27.179086057000063],
            [67.981034296000075, 27.178133335000041],
            [67.982199446000038, 27.177159309000047],
            [67.984246842000061, 27.17544775500005],
            [67.986012219000031, 27.174637889000053],
            [67.98704164600008, 27.174164961000031],
            [67.98850935300004, 27.17320939800004],
            [67.988874617000079, 27.172971590000031],
            [67.990339541000083, 27.172018482000055],
            [67.991308554000057, 27.170946945000026],
            [67.993244576000052, 27.168809297000053],
            [67.995047986000031, 27.166778830000055],
            [67.996093792000067, 27.165565809000043],
            [67.997564873000044, 27.163852555000062],
            [67.998311349000062, 27.16305858100003],
            [67.998569839000083, 27.162783813000033],
            [67.99873986800003, 27.162603076000039],
            [67.999067178000075, 27.162240290000057],
            [68.000541286000043, 27.160361956000031],
            [68.001699119000079, 27.159444377000057],
            [68.002400153000053, 27.158889179000028],
            [68.003670260000035, 27.157633467000039],
            [68.004478988000074, 27.156833905000042],
            [68.005466710000064, 27.156044036000026],
            [68.006124586000055, 27.155517486000065],
            [68.007153710000068, 27.15469379700005],
            [68.007911716000081, 27.154095752000046],
            [68.008797668000057, 27.15343432800006],
            [68.010560773000066, 27.152119252000034],
            [68.011279136000041, 27.151578914000027],
            [68.012876901000084, 27.150377099000025],
            [68.014402351000058, 27.149410442000033],
            [68.016371248000041, 27.148155109000072],
            [68.017775002000064, 27.147060496000051],
            [68.019353285000079, 27.14582929200003],
            [68.019837566000035, 27.145560101000058],
            [68.020619306000071, 27.145125567000036],
            [68.021851925000078, 27.144439681000051],
            [68.023606822000033, 27.143221496000024],
            [68.025193327000068, 27.142120202000058],
            [68.028001626000048, 27.140249908000044],
            [68.028504029000032, 27.139915314000064],
            [68.02955858100006, 27.139176335000059],
            [68.03055101700005, 27.138480626000046],
            [68.032019184000035, 27.137451425000052],
            [68.034280537000029, 27.135678513000073],
            [68.035614224000028, 27.134632206000049],
            [68.035618812000052, 27.134630147000053],
            [68.036844840000072, 27.134079942000028],
            [68.038875003000044, 27.133173268000064],
            [68.039710956000079, 27.132801402000041],
            [68.040510208000057, 27.132534055000065],
            [68.041669945000081, 27.132147126000064],
            [68.04263621900003, 27.13182417400003],
            [68.043310518000055, 27.131625490000033],
            [68.044364577000067, 27.131330188000049],
            [68.045747081000059, 27.130942871000059],
            [68.047272373000055, 27.129705760000036],
            [68.047394761000078, 27.129620304000071],
            [68.048249318000046, 27.129023621000044],
            [68.047621281000033, 27.128318785000033],
            [68.047031674000038, 27.127657077000038],
            [68.045306740000058, 27.125721208000073],
            [68.044449579000059, 27.124757032000048],
            [68.043287084000042, 27.123449403000052],
            [68.042573579000077, 27.122644421000075],
            [68.042098774000067, 27.121932835000052],
            [68.041154339000059, 27.120522054000048],
            [68.040444383000079, 27.11946302900003],
            [68.040074508000032, 27.118543623000051],
            [68.039862416000062, 27.118017709000071],
            [68.039821148000044, 27.117915379000067],
            [68.039246695000031, 27.116490938000027],
            [68.039015275000054, 27.115917098000068],
            [68.038620568000056, 27.114940213000068],
            [68.038468222000063, 27.114224800000045],
            [68.03836784300006, 27.113753426000073],
            [68.03796016900003, 27.111839001000078],
            [68.037774510000077, 27.110967152000057],
            [68.037640198000076, 27.109711632000028],
            [68.037549625000054, 27.10887389100003],
            [68.037459013000046, 27.108035791000077],
            [68.03727774500004, 27.106364039000027],
            [68.037269697000056, 27.105003357000044],
            [68.037254373000053, 27.102735375000066],
            [68.037246824000079, 27.101852566000048],
            [68.036567641000033, 27.098901287000047],
            [68.035322707000034, 27.098060880000048],
            [68.033839078000028, 27.097059340000044],
            [68.033233908000057, 27.096650924000073],
            [68.032057580000071, 27.09623724000005],
            [68.030848897000055, 27.095812236000029],
            [68.030593084000031, 27.095722291000072],
            [68.030446874000063, 27.095670883000025],
            [68.029709318000073, 27.095411542000079],
            [68.029229461000057, 27.095307023000032],
            [68.028026437000051, 27.095044990000076],
            [68.026422368000055, 27.094695605000027],
            [68.024190250000061, 27.093502697000076],
            [68.023541756000043, 27.092934866000064],
            [68.021575976000065, 27.091213737000032],
            [68.020961624000051, 27.090675844000032],
            [68.020848412000078, 27.090576327000065],
            [68.019980635000081, 27.089813520000064],
            [68.01919773700007, 27.088374133000059],
            [68.017326621000052, 27.084930217000078],
            [68.015764510000054, 27.081799103000037],
            [68.015182128000049, 27.080631526000047],
            [68.014777065000033, 27.079917845000068],
            [68.013969989000032, 27.078495591000035],
            [68.013556098000038, 27.077766266000026],
            [68.013164371000073, 27.07707718000006],
            [68.012673635000056, 27.076386174000049],
            [68.011706397000069, 27.075024751000058],
            [68.010982641000055, 27.074006133000069],
            [68.01032413300004, 27.073414457000069],
            [68.008248927000068, 27.071550168000044],
            [68.007389738000029, 27.070778831000041],
            [68.006139386000029, 27.070316315000071],
            [68.005311412000083, 27.070010251000042],
            [68.004457157000047, 27.069694631000061],
            [68.003585079000061, 27.06963197400006],
            [68.000306149000039, 27.069399823000026],
            [67.998796778000042, 27.069593305000069],
            [67.996956629000067, 27.069833649000032],
            [67.995226246000072, 27.069887050000034],
            [67.991987725000058, 27.069987009000045],
            [67.990879150000069, 27.070020820000025],
            [67.990102615000069, 27.070012394000059],
            [67.985931058000062, 27.069966650000026],
            [67.984153519000074, 27.069947185000046],
            [67.981799942000066, 27.069365034000043],
            [67.98088296700007, 27.069138233000047],
            [67.980621933000066, 27.069073669000034],
            [67.979167394000058, 27.068713907000074],
            [67.976235351000071, 27.068308212000034],
            [67.974638635000076, 27.068516842000065],
            [67.972082185000033, 27.068850147000035],
            [67.966986624000072, 27.069186975000036],
            [67.965216445000067, 27.069282448000024],
            [67.962768844000038, 27.069414459000029],
            [67.960555781000039, 27.069540504000031],
            [67.958786577000069, 27.069641268000055],
            [67.956151064000039, 27.070202718000075],
            [67.95527644100008, 27.070389042000045],
            [67.950741313000037, 27.071309836000069],
            [67.950095221000083, 27.071376193000049],
            [67.949469929000031, 27.071411117000025],
            [67.947183245000076, 27.071538834000023],
            [67.947159513000031, 27.071540159000051],
            [67.947144450000053, 27.071528389000036],
            [67.945984904000056, 27.07062234700004],
            [67.944138795000072, 27.069181119000064],
            [67.943079117000082, 27.06899756100006],
            [67.942002366000054, 27.068811046000064],
            [67.940940428000033, 27.067414830000075],
            [67.940551201000062, 27.066903251000042],
            [67.940291940000066, 27.066562410000074],
            [67.937369840000031, 27.064155222000068],
            [67.936871783000072, 27.063807355000051],
            [67.934914728000081, 27.06244048800005],
            [67.933724908000045, 27.061608759000023],
            [67.932566001000055, 27.06119794500006],
            [67.930450635000057, 27.060447016000069],
            [67.929290255000069, 27.060136119000049],
            [67.927134524000053, 27.05959632500003],
            [67.925875977000032, 27.059673408000037],
            [67.92415565500005, 27.05977877600003],
            [67.922307765000028, 27.058885136000072],
            [67.920806953000067, 27.058057597000072],
            [67.915863074000072, 27.058084143000031],
            [67.914102458000059, 27.058093596000049],
            [67.912612472000035, 27.057303876000049],
            [67.911985138000034, 27.056971378000071],
            [67.911136917000078, 27.056521805000045],
            [67.910755858000073, 27.055238707000058],
            [67.910733576000041, 27.055163648000075],
            [67.909963914000059, 27.052570996000043],
            [67.909902249000083, 27.052362457000072],
            [67.909731536000038, 27.051785134000056],
            [67.909767510000052, 27.050648847000048],
            [67.909792977000052, 27.049943881000047],
            [67.909909506000076, 27.046718236000061],
            [67.909922135000045, 27.046368656000027],
            [67.909994065000035, 27.044375914000057],
            [67.910058294000066, 27.042596490000051],
            [67.910766006000074, 27.041528514000049],
            [67.91113041300008, 27.040978222000035],
            [67.91143452700004, 27.040513608000026],
            [67.91167831000007, 27.040150345000029],
            [67.912754154000083, 27.039529731000073],
            [67.916735330000051, 27.037233143000037],
            [67.917903147000061, 27.036559803000046],
            [67.919837426000072, 27.035805804000063],
            [67.923211547000051, 27.034490543000061],
            [67.925572050000028, 27.033585393000067],
            [67.925929540000084, 27.033448311000029],
            [67.928362165000067, 27.032515505000049],
            [67.928819133000047, 27.032340408000039],
            [67.930878163000045, 27.031551385000057],
            [67.931698542000049, 27.031271014000026],
            [67.932929472000069, 27.030850335000025],
            [67.937717906000046, 27.029213853000044],
            [67.938674155000058, 27.028887048000058],
            [67.940316073000076, 27.028325910000035],
            [67.941712621000079, 27.028020207000054],
            [67.946084038000038, 27.02706334800007],
            [67.94731583600003, 27.026793721000047],
            [67.948583939000059, 27.026516148000042],
            [67.953368319000049, 27.026237319000074],
            [67.955279899000061, 27.026125967000041],
            [67.956218357000068, 27.024591857000075],
            [67.956756014000064, 27.02371372600004],
            [67.95722894000005, 27.022941316000072],
            [67.957892057000038, 27.021977151000044],
            [67.959176997000043, 27.020108862000029],
            [67.962319217000072, 27.018328876000055],
            [67.963457918000074, 27.018321024000045],
            [67.964681772000063, 27.018312201000072],
            [67.966632322000066, 27.019171220000032],
            [67.967056268000078, 27.019357925000065],
            [67.968107307000082, 27.019353152000065],
            [67.969420701000047, 27.019347186000061],
            [67.97073041200008, 27.018632360000026],
            [67.971381072000042, 27.018277236000074],
            [67.971619968000084, 27.017755751000038],
            [67.97195325000007, 27.017028237000034],
            [67.973237877000031, 27.014224049000063],
            [67.973448653000048, 27.013625918000059],
            [67.974685058000034, 27.00953822300005],
            [67.975725814000043, 27.006097367000052],
            [67.976531514000044, 27.003433630000075],
            [67.977172684000038, 27.001313849000042],
            [67.977585487000056, 26.999164882000059],
            [67.978967902000079, 26.991968313000029],
            [67.979071665000049, 26.991428146000032],
            [67.979395468000064, 26.989294125000072],
            [67.979579666000063, 26.988080173000071],
            [67.979821510000079, 26.986486300000024],
            [67.979267358000072, 26.985316785000066],
            [67.978313009000033, 26.983302670000057],
            [67.977821701000039, 26.982265785000038],
            [67.976745977000064, 26.981505651000077],
            [67.974505167000075, 26.979922238000029],
            [67.973861853000074, 26.979467656000054],
            [67.972732820000033, 26.979036909000058],
            [67.970283720000054, 26.978102508000063],
            [67.969521604000079, 26.977811742000029],
            [67.969247883000037, 26.977761787000077],
            [67.968276119000052, 26.977584437000075],
            [67.963420912000061, 26.976698347000024],
            [67.962028009000051, 26.976444139000023],
            [67.960688234000031, 26.976345951000042],
            [67.959348107000039, 26.97624773800004],
            [67.957629387000054, 26.976121779000039],
            [67.95175148800007, 26.975691009000059],
            [67.94862489500008, 26.975461872000039],
            [67.94555764100005, 26.975204407000035],
            [67.944243775000075, 26.975094143000035],
            [67.942492929000082, 26.974947186000065],
            [67.940742501000045, 26.974800264000066],
            [67.939860474000056, 26.974680571000079],
            [67.935861930000044, 26.974137961000054],
            [67.934572701000036, 26.973963010000034],
            [67.933250473000044, 26.973783581000077],
            [67.932540920000065, 26.973563788000035],
            [67.932110574000035, 26.973430483000072],
            [67.927822850000041, 26.972102308000046],
            [67.926538036000068, 26.971704321000061],
            [67.92546416600004, 26.971171229000049],
            [67.92077837000005, 26.968845101000056],
            [67.920213653000076, 26.968564764000064],
            [67.919841330000054, 26.968276464000041],
            [67.919567913000037, 26.968064749000064],
            [67.918309436000072, 26.967090272000064],
            [67.917046742000082, 26.966112531000078],
            [67.916980738000063, 26.965153745000066],
            [67.916952526000046, 26.964743936000048],
            [67.916863252000041, 26.963462370000059],
            [67.916479828000035, 26.957958166000026],
            [67.916447681000079, 26.957496690000028],
            [67.916432301000043, 26.957275969000023],
            [67.916401000000064, 26.95682677800005],
            [67.916604718000031, 26.956413807000047],
            [67.917728905000047, 26.954134883000052],
            [67.919599878000042, 26.950342093000074],
            [67.920385370000076, 26.94874976400007],
            [67.920637904000046, 26.948237834000054],
            [67.921273915000029, 26.946950136000055],
            [67.922749133000082, 26.94575188400006],
            [67.923774703000049, 26.944969336000042],
            [67.925249019000034, 26.94384438000003],
            [67.92629667500006, 26.942976996000027],
            [67.926982510000073, 26.942409523000038],
            [67.92761138000003, 26.941889184000047],
            [67.929191849000063, 26.940581146000056],
            [67.929881536000039, 26.939264689000026],
            [67.931263116000082, 26.936616836000042],
            [67.931370909000066, 26.936410247000026],
            [67.931434897000031, 26.935662358000059],
            [67.931507165000028, 26.934830864000048],
            [67.931542426000078, 26.934425157000078],
            [67.931578181000077, 26.934013765000032],
            [67.931292600000063, 26.932817496000041],
            [67.930600875000039, 26.929904603000068],
            [67.930355675000044, 26.928874767000025],
            [67.929748108000069, 26.928026191000072],
            [67.92807814300005, 26.925696683000069],
            [67.927474889000052, 26.924855161000039],
            [67.927456947000053, 26.924844043000064],
            [67.926515111000072, 26.924260391000075],
            [67.925005657000042, 26.923324972000046],
            [67.924282713000082, 26.922877726000024],
            [67.923482212000067, 26.922628280000026],
            [67.92229751800005, 26.922262674000024],
            [67.921095724000054, 26.921891792000054],
            [67.919468911000081, 26.921838607000041],
            [67.916848852000044, 26.921752992000052],
            [67.915975243000048, 26.921707208000043],
            [67.912249529000064, 26.921510843000078],
            [67.910647040000072, 26.921425707000026],
            [67.908526081000048, 26.920218706000071],
            [67.908408294000083, 26.920114130000059],
            [67.907598753000059, 26.919395388000055],
            [67.904425923000076, 26.916566567000075],
            [67.903713424000046, 26.916038343000025],
            [67.901589315000081, 26.914462995000065],
            [67.900886386000082, 26.913941667000074],
            [67.899137543000052, 26.913045689000057],
            [67.897543831000064, 26.912972880000041],
            [67.894342574000063, 26.91282663100003],
            [67.893025572000056, 26.912744473000032],
            [67.888739199000042, 26.912477034000062],
            [67.888192559000061, 26.912442974000044],
            [67.887757072000056, 26.91237043600006],
            [67.886068872000067, 26.91209082000006],
            [67.884368393000045, 26.911809171000073],
            [67.883062787000028, 26.910709674000032],
            [67.880120235000049, 26.908232075000058],
            [67.879578411000068, 26.907866651000063],
            [67.878194571000051, 26.906933346000073],
            [67.876966695000078, 26.906105228000058],
            [67.876023998000051, 26.905339514000048],
            [67.874562130000072, 26.904152098000054],
            [67.872067737000066, 26.901916708000044],
            [67.869507480000038, 26.900215003000028],
            [67.869299908000073, 26.900077038000063],
            [67.868110092000052, 26.899198725000076],
            [67.866760379000084, 26.897256816000038],
            [67.866380438000078, 26.896710174000077],
            [67.865143762000059, 26.894930898000041],
            [67.864400892000049, 26.893862089000038],
            [67.86362267100003, 26.892818788000056],
            [67.862224539000067, 26.890943708000066],
            [67.861947877000034, 26.890572668000061],
            [67.861923568000066, 26.890540070000043],
            [67.860642995000035, 26.888822859000072],
            [67.859046976000059, 26.887407951000057],
            [67.85521710300003, 26.884013000000039],
            [67.853522697000074, 26.882510970000055],
            [67.85342901000007, 26.882412111000065],
            [67.852598890000081, 26.881536158000074],
            [67.851367124000035, 26.880236386000036],
            [67.847771046000048, 26.876441763000059],
            [67.847054740000033, 26.875685909000026],
            [67.846130689000063, 26.874710839000045],
            [67.84520636700006, 26.873735483000075],
            [67.844666278000034, 26.873119981000059],
            [67.844250049000038, 26.872645634000037],
            [67.843746712000041, 26.872072015000072],
            [67.840536166000049, 26.868413177000036],
            [67.839660018000075, 26.867414692000068],
            [67.838785260000066, 26.86593719900003],
            [67.83594339900003, 26.861137212000074],
            [67.835288510000055, 26.86003108500006],
            [67.834984083000052, 26.858963749000054],
            [67.834448192000082, 26.857084881000048],
            [67.834083414000077, 26.855805947000079],
            [67.834426906000033, 26.854034507000051],
            [67.834613017000038, 26.852633651000076],
            [67.834735675000047, 26.852111775000026],
            [67.836119014000076, 26.846226045000037],
            [67.836551307000036, 26.844437587000073],
            [67.837469659000078, 26.840638240000033],
            [67.837586145000046, 26.840154983000048],
            [67.837884194000083, 26.838918491000072],
            [67.838462906000075, 26.837238090000028],
            [67.83889699100007, 26.835977589000038],
            [67.841352246000042, 26.828847304000078],
            [67.842110473000048, 26.826645345000031],
            [67.842275935000032, 26.826175650000039],
            [67.842366526000035, 26.825918492000028],
            [67.845417627000074, 26.817283657000075],
            [67.846008780000034, 26.815610651000043],
            [67.847177366000039, 26.814316444000042],
            [67.84798777900005, 26.813418913000078],
            [67.849526000000083, 26.811715336000077],
            [67.850114800000028, 26.810547386000053],
            [67.850954433000084, 26.808881722000024],
            [67.851662826000052, 26.807473566000056],
            [67.851517839000053, 26.80572714300007],
            [67.851126383000064, 26.801035214000024],
            [67.851071862000083, 26.800381728000048],
            [67.850750810000079, 26.796533650000072],
            [67.850714413000048, 26.796097404000079],
            [67.850606983000034, 26.794788010000048],
            [67.850862522000057, 26.793488295000031],
            [67.851135369000076, 26.792100560000051],
            [67.851370141000075, 26.790906471000028],
            [67.851867965000054, 26.789908557000047],
            [67.852601016000051, 26.788439935000042],
            [67.852865354000073, 26.787910232000058],
            [67.853310449000048, 26.787018311000054],
            [67.854032480000058, 26.78611773800003],
            [67.855648987000052, 26.784101508000049],
            [67.856431661000045, 26.783125296000037],
            [67.85938008200003, 26.780454640000073],
            [67.859993822000035, 26.779898719000073],
            [67.861446022000052, 26.778583328000025],
            [67.861517746000061, 26.778518361000067],
            [67.862033384000028, 26.777009224000039],
            [67.86317064900004, 26.773680744000046],
            [67.863448497000036, 26.772867556000051],
            [67.863570148000065, 26.772032145000026],
            [67.863874954000039, 26.769940155000029],
            [67.864350693000063, 26.766674464000062],
            [67.864579129000049, 26.765106371000059],
            [67.864195170000073, 26.763429750000057],
            [67.863771959000076, 26.761582510000039],
            [67.862749293000036, 26.757117853000068],
            [67.862562738000065, 26.756303412000022],
            [67.862288105000061, 26.755472705000045],
            [67.860776101000056, 26.750897303000045],
            [67.859938413000066, 26.748362233000023],
            [67.859506341000042, 26.747054662000039],
            [67.85895682000006, 26.745392006000031],
            [67.85811734400005, 26.743447153000034],
            [67.856439178000073, 26.73955926800005],
            [67.855768154000032, 26.73800467600006],
            [67.855382060000068, 26.736814221000031],
            [67.854867795000075, 26.735228573000029],
            [67.854170196000041, 26.733077646000027],
            [67.853414341000075, 26.731398564000074],
            [67.852955823000059, 26.73037811100005],
            [67.852087636000078, 26.728445921000059],
            [67.850846003000072, 26.727568022000071],
            [67.849316198000054, 26.726487020000036],
            [67.847719269000038, 26.72593518900004],
            [67.844756276000055, 26.724917181000023],
            [67.842221789000064, 26.724046517000033],
            [67.837915183000064, 26.723364802000049],
            [67.837457510000036, 26.723292355000069],
            [67.835731613000064, 26.723019368000053],
            [67.835659916000054, 26.721345088000078],
            [67.835643299000083, 26.720959355000048],
            [67.835509148000028, 26.717845229000034],
            [67.835541571000078, 26.717336800000055],
            [67.835720664000064, 26.715460205000056],
            [67.836101717000076, 26.711467391000042],
            [67.836238329000082, 26.710035924000067],
            [67.836244217000058, 26.709974227000032],
            [67.836658336000028, 26.708712800000058],
            [67.837153754000042, 26.707203475000028],
            [67.83789573200005, 26.704942987000038],
            [67.838110817000029, 26.704287716000067],
            [67.838429179000059, 26.703317682000034],
            [67.839313512000047, 26.70062342600005],
            [67.83972078000005, 26.699382787000047],
            [67.840330301000051, 26.698227677000034],
            [67.840411189000065, 26.69807438600003],
            [67.84260512600008, 26.693916627000078],
            [67.843602634000035, 26.692026238000039],
            [67.844382563000067, 26.690548184000079],
            [67.845662937000043, 26.689676604000056],
            [67.848194027000034, 26.687953636000032],
            [67.848272810000083, 26.687900006000064],
            [67.849075792000065, 26.687353398000027],
            [67.850234373000035, 26.68686708000007],
            [67.850909106000074, 26.686583793000068],
            [67.853353727000069, 26.685557570000071],
            [67.854170636000049, 26.685214641000073],
            [67.855824847000065, 26.684665432000031],
            [67.86078333100005, 26.683019426000044],
            [67.861592641000072, 26.682750760000033],
            [67.862739174000069, 26.68237014500005],
            [67.862792926000054, 26.682352301000037],
            [67.863681860000042, 26.681706659000042],
            [67.865630214000078, 26.680291548000071],
            [67.866701119000084, 26.679513739000072],
            [67.867285787000071, 26.678374779000023],
            [67.868064378000042, 26.676857224000059],
            [67.868673803000036, 26.675669668000069],
            [67.868939053000076, 26.675152789000038],
            [67.869421279000051, 26.674213101000078],
            [67.86959881000007, 26.673102956000037],
            [67.870530213000052, 26.667039147000025],
            [67.869787178000081, 26.663160521000066],
            [67.869562674000065, 26.661989241000072],
            [67.869338310000046, 26.660818513000038],
            [67.868358382000054, 26.659514514000023],
            [67.866438597000069, 26.656959837000045],
            [67.866171780000059, 26.656604781000055],
            [67.865838243000042, 26.65623494700003],
            [67.864359708000052, 26.65459508400005],
            [67.863820223000062, 26.653996763000066],
            [67.863007488000051, 26.653095391000079],
            [67.861363181000058, 26.65173270300005],
            [67.859060618000058, 26.649824498000044],
            [67.857087848000049, 26.648189603000048],
            [67.855632127000035, 26.646322001000044],
            [67.854923920000033, 26.645413550000058],
            [67.854440275000059, 26.644793093000033],
            [67.853529888000082, 26.643625027000041],
            [67.853036367000072, 26.642557957000065],
            [67.85278986600008, 26.642024984000045],
            [67.850691808000079, 26.637488651000069],
            [67.849951230000045, 26.635887406000052],
            [67.849363864000054, 26.634846793000065],
            [67.847894803000031, 26.632243925000068],
            [67.846657488000062, 26.631311937000078],
            [67.844816739000066, 26.629924589000041],
            [67.842465146000052, 26.628749711000069],
            [67.840918871000042, 26.627977901000065],
            [67.839301782000064, 26.627170746000047],
            [67.838514498000052, 26.626777780000054],
            [67.837886173000072, 26.626359726000032],
            [67.837305381000078, 26.625973133000059],
            [67.836481409000044, 26.625424673000055],
            [67.835359093000079, 26.624677724000037],
            [67.834480865000046, 26.62376622000005],
            [67.834035633000042, 26.623304275000066],
            [67.832991971000069, 26.622221264000075],
            [67.832262290000074, 26.620688137000059],
            [67.832013241000084, 26.620164750000072],
            [67.831712684000081, 26.619533114000035],
            [67.830387379000058, 26.616748265000069],
            [67.829812181000079, 26.615539607000073],
            [67.828750367000055, 26.614460695000048],
            [67.824410044000047, 26.61005086800003],
            [67.819309219000047, 26.604615165000041],
            [67.818365950000043, 26.60360881400004],
            [67.817331597000077, 26.60288637900004],
            [67.813880749000077, 26.600476164000042],
            [67.812850297000068, 26.599756453000055],
            [67.811718515000052, 26.599215965000042],
            [67.811223301000041, 26.598979407000058],
            [67.807594764000044, 26.597237067000037],
            [67.803392717000065, 26.597337123000045],
            [67.80301124600004, 26.59741490600004],
            [67.801503880000041, 26.597722260000069],
            [67.799888914000064, 26.598064176000037],
            [67.79964758400007, 26.600108015000046],
            [67.799481005000075, 26.601511731000073],
            [67.799431947000073, 26.601925132000076],
            [67.797699629000078, 26.603202896000028],
            [67.796305293000046, 26.604230019000056],
            [67.795056215000045, 26.604333610000026],
            [67.791958477000037, 26.604589461000046],
            [67.791334558000074, 26.604558525000073],
            [67.787941542000056, 26.604283078000037],
            [67.787762349000047, 26.604268522000041],
            [67.785787172000028, 26.604108074000067],
            [67.78463634600007, 26.604014592000055],
            [67.783592181000074, 26.603929778000065],
            [67.780644521000056, 26.603452449000031],
            [67.779802238000059, 26.603316033000056],
            [67.779716007000047, 26.603302069000051],
            [67.775117014000045, 26.602557306000051],
            [67.772987630000046, 26.602212473000066],
            [67.772129922000033, 26.602023583000062],
            [67.770415540000045, 26.60164603100003],
            [67.76585377300006, 26.600641409000048],
            [67.763558471000067, 26.60013592200005],
            [67.758939372000043, 26.599118435000037],
            [67.757272415000045, 26.59875124000007],
            [67.756347543000061, 26.597763331000067],
            [67.755165895000061, 26.596501146000037],
            [67.754071126000042, 26.595331762000058],
            [67.753327904000059, 26.594537883000044],
            [67.753060942000047, 26.593518435000078],
            [67.752637854000056, 26.591902790000063],
            [67.752427099000045, 26.591097980000029],
            [67.752129247000028, 26.589960572000052],
            [67.752553138000053, 26.58880146100006],
            [67.753291705000038, 26.586781878000068],
            [67.753677943000071, 26.585725728000057],
            [67.754843559000051, 26.584669435000023],
            [67.75561741000007, 26.583968163000065],
            [67.756023826000046, 26.583599865000053],
            [67.756483653000032, 26.58325725900005],
            [67.759343473000058, 26.581121334000045],
            [67.759009535000075, 26.579148847000056],
            [67.75877296200008, 26.577753443000063],
            [67.755160535000073, 26.575482694000073],
            [67.751243767000062, 26.575507999000024],
            [67.749572327000067, 26.575518797000029],
            [67.748527954000053, 26.575525415000072],
            [67.747568744000034, 26.575861186000054],
            [67.745866185000068, 26.576457166000068],
            [67.744398004000061, 26.576971102000073],
            [67.743437794000045, 26.577307223000048],
            [67.742707899000038, 26.578034678000051],
            [67.739532432000033, 26.581199532000028],
            [67.738830139000072, 26.581083910000075],
            [67.736391053000034, 26.580683291000071],
            [67.73490643100007, 26.57924994800004],
            [67.734025343000042, 26.578401301000042],
            [67.732301796000058, 26.578407568000046],
            [67.731748905000074, 26.578409620000059],
            [67.730614882000054, 26.578413828000066],
            [67.729530892000071, 26.578464332000067],
            [67.728565109000044, 26.578716037000049],
            [67.726967563000073, 26.579132306000076],
            [67.725690096000051, 26.579443611000045],
            [67.724160481000069, 26.579816362000031],
            [67.722616130000063, 26.580192661000069],
            [67.721091121000029, 26.58056433400003],
            [67.720082946000048, 26.580485671000076],
            [67.718065307000074, 26.580328405000046],
            [67.716772752000054, 26.580228121000061],
            [67.715204548000031, 26.57917986800004],
            [67.713623908000045, 26.578124712000033],
            [67.711915009000052, 26.575085379000029],
            [67.710855113000036, 26.573200321000058],
            [67.70907389000007, 26.572521720000054],
            [67.708106074000057, 26.572153142000047],
            [67.707077495000078, 26.572214467000038],
            [67.703191428000082, 26.572446158000048],
            [67.70182944700008, 26.572527360000038],
            [67.700910365000084, 26.572990872000048],
            [67.699935771000071, 26.57348238000003],
            [67.698309373000029, 26.57430260600006],
            [67.697145295000041, 26.57465516700006],
            [67.694512244000066, 26.575453801000037],
            [67.693018747000053, 26.575906939000049],
            [67.691861126000049, 26.57546583900006],
            [67.689765088000058, 26.574667445000046],
            [67.688898142000028, 26.574335834000067],
            [67.688095430000033, 26.573289974000033],
            [67.687652765000053, 26.572711993000041],
            [67.687028063000071, 26.571896328000037],
            [67.686406046000059, 26.571084169000073],
            [67.684208923000028, 26.568213276000051],
            [67.683715932000041, 26.567569139000057],
            [67.682975896000073, 26.566602249000027],
            [67.681887434000032, 26.565855587000044],
            [67.680444209000029, 26.564865566000037],
            [67.678229875000056, 26.563346580000029],
            [67.677863043000059, 26.563094941000031],
            [67.677375760000075, 26.562841922000075],
            [67.676739029000032, 26.56251121400004],
            [67.674584823000032, 26.561392484000066],
            [67.673143251000056, 26.560643843000037],
            [67.671398321000083, 26.560649252000076],
            [67.669185607000031, 26.560650911000039],
            [67.664077617000032, 26.559907778000024],
            [67.662699677000035, 26.559609065000075],
            [67.661314350000055, 26.559308910000027],
            [67.659814604000076, 26.559455860000071],
            [67.658767554000065, 26.559562282000059],
            [67.657497130000081, 26.559691441000041],
            [67.656312023000055, 26.560199175000037],
            [67.65276510800004, 26.561718992000067],
            [67.652344762000041, 26.561898929000051],
            [67.65163190700008, 26.562288900000055],
            [67.650243438000075, 26.563048468000034],
            [67.649640364000049, 26.563380577000032],
            [67.649109903000067, 26.563672698000062],
            [67.647611844000039, 26.564811463000069],
            [67.647156605000077, 26.565157294000073],
            [67.645801221000056, 26.56452863800007],
            [67.643008510000072, 26.563233319000062],
            [67.642703935000043, 26.563092051000069],
            [67.63932254100007, 26.561539824000079],
            [67.637102399000071, 26.560863995000034],
            [67.635667330000047, 26.560428392000063],
            [67.634344514000077, 26.559184934000029],
            [67.633910720000074, 26.558777164000048],
            [67.633324340000058, 26.558225961000062],
            [67.632882811000059, 26.55752921100003],
            [67.632312309000042, 26.556628936000038],
            [67.631697306000035, 26.555658436000044],
            [67.631404210000028, 26.554668574000061],
            [67.631110682000042, 26.553676797000037],
            [67.63040171800003, 26.551281336000045],
            [67.629453123000076, 26.548076541000057],
            [67.629380205000075, 26.547830189000024],
            [67.628543398000033, 26.54538476700003],
            [67.627832430000069, 26.541923021000059],
            [67.627717115000053, 26.54136154400004],
            [67.627433763000056, 26.539982913000074],
            [67.62794479300004, 26.539007187000038],
            [67.628654280000035, 26.537652539000078],
            [67.629745682000078, 26.535568264000062],
            [67.630123113000082, 26.534521090000055],
            [67.630568229000062, 26.533286125000075],
            [67.630719485000043, 26.53286649100005],
            [67.631324016000065, 26.531189319000077],
            [67.631230298000048, 26.529625493000026],
            [67.631185603000063, 26.528879669000048],
            [67.631050539000057, 26.526654654000026],
            [67.630962255000043, 26.525241785000048],
            [67.630251261000069, 26.522758041000031],
            [67.629956723000078, 26.521723977000079],
            [67.629565309000043, 26.520349803000045],
            [67.629721423000035, 26.518621192000069],
            [67.630110193000064, 26.514336753000066],
            [67.630149168000059, 26.513904783000044],
            [67.630226826000069, 26.513044074000049],
            [67.630896749000044, 26.511393025000075],
            [67.634586340000055, 26.502299348000065],
            [67.634899081000071, 26.501528548000067],
            [67.635680895000064, 26.499601641000027],
            [67.636033180000084, 26.498801710000066],
            [67.636561340000071, 26.497602418000042],
            [67.638986660000057, 26.492095256000027],
            [67.64025865800005, 26.489206937000063],
            [67.64224204900006, 26.484702915000071],
            [67.643127106000065, 26.48269306800006],
            [67.643496743000071, 26.481734988000028],
            [67.643812270000069, 26.480917247000036],
            [67.645461540000042, 26.476642794000043],
            [67.645682344000079, 26.476070530000072],
            [67.646008059000053, 26.475103297000032],
            [67.646071812000059, 26.474499484000034],
            [67.646434471000077, 26.470968278000043],
            [67.646346473000051, 26.468969568000034],
            [67.645748579000042, 26.467813826000054],
            [67.64554858200006, 26.467427106000059],
            [67.645206177000034, 26.466765179000049],
            [67.644677169000033, 26.465742516000034],
            [67.64377746100007, 26.464801741000031],
            [67.642868391000036, 26.463851178000027],
            [67.64074792100007, 26.461633922000033],
            [67.638869025000076, 26.459521282000026],
            [67.638332467000055, 26.458918031000053],
            [67.635994930000038, 26.456289749000064],
            [67.634826085000043, 26.454975522000041],
            [67.634032526000055, 26.453439091000064],
            [67.632935273000044, 26.451314669000055],
            [67.631453815000043, 26.44844637500006],
            [67.630858543000045, 26.44729385200003],
            [67.630659576000028, 26.446908625000049],
            [67.63030092200006, 26.446161914000072],
            [67.629313805000038, 26.444105920000027],
            [67.628656235000051, 26.442736461000038],
            [67.628464456000074, 26.442337062000036],
            [67.627709973000037, 26.440765775000045],
            [67.627321929000061, 26.439299768000069],
            [67.627212255000074, 26.438885536000043],
            [67.627050259000043, 26.438273762000051],
            [67.626702666000028, 26.436961432000032],
            [67.626183850000075, 26.435002281000038],
            [67.625807423000083, 26.433580468000059],
            [67.625748867000084, 26.432966842000042],
            [67.625596409000082, 26.431369179000058],
            [67.625174703000027, 26.426946073000067],
            [67.625009092000028, 26.425209553000059],
            [67.624887517000047, 26.423934784000039],
            [67.624843464000037, 26.423472860000061],
            [67.624881254000059, 26.421241113000065],
            [67.624970325000049, 26.415885541000023],
            [67.625000009000075, 26.414100466000036],
            [67.624651247000031, 26.412458371000071],
            [67.623846727000057, 26.408670407000045],
            [67.623758090000081, 26.408253070000058],
            [67.623560317000056, 26.407533605000026],
            [67.622241355000028, 26.402735439000026],
            [67.621873672000049, 26.401397870000039],
            [67.621018507000031, 26.399889921000067],
            [67.620230572000082, 26.398500679000051],
            [67.619373623000058, 26.39755309800006],
            [67.617078911000078, 26.395015695000041],
            [67.616780059000064, 26.394703102000051],
            [67.616624908000063, 26.394540773000074],
            [67.614066065000031, 26.391863533000048],
            [67.613761540000041, 26.391544927000041],
            [67.613151724000033, 26.390906913000038],
            [67.612180003000049, 26.389942217000055],
            [67.60885053700008, 26.386636911000039],
            [67.608068820000028, 26.385860862000072],
            [67.607545875000028, 26.385261491000051],
            [67.60624559300004, 26.383770491000064],
            [67.605301580000059, 26.382688159000054],
            [67.604020192000064, 26.380037237000067],
            [67.603976637000073, 26.379914466000059],
            [67.603510631000063, 26.378600896000023],
            [67.60272473100008, 26.376388451000025],
            [67.601912251000044, 26.375318529000026],
            [67.600329952000038, 26.373262791000059],
            [67.59952561800003, 26.372217509000052],
            [67.597823385000083, 26.371456014000046],
            [67.59643821800006, 26.370798605000061],
            [67.595678044000067, 26.370475009000074],
            [67.594712212000047, 26.370063868000045],
            [67.593867601000056, 26.369686235000074],
            [67.593288546000053, 26.369289038000034],
            [67.592178445000059, 26.368522127000062],
            [67.591171890000055, 26.367826751000052],
            [67.590351284000064, 26.366411153000058],
            [67.589120549000029, 26.364288060000035],
            [67.586517546000039, 26.35946563300007],
            [67.585814725000034, 26.358163560000037],
            [67.585210953000058, 26.356633661000046],
            [67.585122527000067, 26.356409561000078],
            [67.584304958000075, 26.354337577000024],
            [67.580508473000066, 26.34471669900006],
            [67.580178974000034, 26.343881697000029],
            [67.578309912000066, 26.339258031000043],
            [67.577755808000063, 26.337887294000041],
            [67.577162667000039, 26.336419989000035],
            [67.576266279000038, 26.335502011000074],
            [67.573442963000048, 26.332607379000024],
            [67.572639993000053, 26.332004621000067],
            [67.571594714000071, 26.331267428000046],
            [67.571328230000063, 26.331079488000057],
            [67.569840936000048, 26.331273934000023],
            [67.567872506000072, 26.33153128500004],
            [67.567386628000065, 26.331594808000034],
            [67.567315647000044, 26.331604088000063],
            [67.566565952000076, 26.331870064000043],
            [67.561881166000035, 26.333532128000058],
            [67.560837341000081, 26.333902455000043],
            [67.559617206000041, 26.334474336000028],
            [67.559412099000042, 26.334570470000074],
            [67.558395970000049, 26.33504673300007],
            [67.55269687100008, 26.337717916000031],
            [67.551069055000028, 26.338480878000041],
            [67.549035437000043, 26.339434041000061],
            [67.547769944000038, 26.339831895000032],
            [67.539345206000064, 26.342480519000048],
            [67.538503582000033, 26.342745114000024],
            [67.537849720000054, 26.342950680000058],
            [67.536572389000071, 26.342935633000025],
            [67.535747298000047, 26.342925901000058],
            [67.533464888000083, 26.342898978000051],
            [67.532332757000063, 26.342885691000049],
            [67.531068718000029, 26.342618825000045],
            [67.525580384000079, 26.341460116000064],
            [67.52514457500007, 26.341368107000051],
            [67.523398684000028, 26.340999510000074],
            [67.522350865000078, 26.340589304000048],
            [67.520524692000038, 26.339874384000041],
            [67.518882575000077, 26.339231520000055],
            [67.516835918000083, 26.339145850000023],
            [67.51601966100003, 26.339111683000056],
            [67.515113951000046, 26.339081141000065],
            [67.514212198000052, 26.339122161000034],
            [67.513742382000032, 26.339143504000049],
            [67.51072742000008, 26.33927723000005],
            [67.509435774000053, 26.339334930000064],
            [67.507712625000067, 26.339411908000045],
            [67.506390239000041, 26.33926957400007],
            [67.504679645000067, 26.339085457000067],
            [67.50079201300008, 26.338667017000034],
            [67.499358568000048, 26.338512730000048],
            [67.49812797800007, 26.337517289000061],
            [67.497582445000035, 26.337075999000035],
            [67.496793319000062, 26.336437664000073],
            [67.496357027000045, 26.335699238000075],
            [67.495987288000038, 26.335073452000074],
            [67.49576913900006, 26.334704233000025],
            [67.495610385000077, 26.334435541000062],
            [67.495217913000033, 26.333770357000049],
            [67.495024668000042, 26.333442835000028],
            [67.494818680000037, 26.332127378000052],
            [67.493550474000074, 26.323795716000063],
            [67.493350522000071, 26.322482105000063],
            [67.49321718200008, 26.321606108000026],
            [67.492943750000052, 26.318519062000064],
            [67.492727498000079, 26.316077577000044],
            [67.492280290000053, 26.311028596000028],
            [67.492123489000051, 26.309258316000069],
            [67.491967267000064, 26.307494567000049],
            [67.491850098000043, 26.306171729000027],
            [67.491771676000042, 26.303970387000049],
            [67.491395268000076, 26.293404395000039],
            [67.491348230000028, 26.292084009000064],
            [67.491285498000082, 26.290323087000047],
            [67.491289287000029, 26.290275426000051],
            [67.491391130000068, 26.288994253000055],
            [67.491778293000039, 26.284123835000059],
            [67.491817626000056, 26.283629031000032],
            [67.491913117000081, 26.282427784000049],
            [67.491918932000033, 26.282354636000036],
            [67.492097093000041, 26.281528801000036],
            [67.492745100000036, 26.278525075000061],
            [67.49307238800003, 26.277007988000037],
            [67.493877056000031, 26.275426951000043],
            [67.495688433000055, 26.271867900000075],
            [67.496695307000039, 26.269889563000049],
            [67.497051731000056, 26.269078457000035],
            [67.497478451000063, 26.268107382000039],
            [67.497942462000083, 26.26705144500005],
            [67.499367783000082, 26.263807877000033],
            [67.500258267000049, 26.261781427000074],
            [67.50064936800004, 26.260563647000026],
            [67.501559846000077, 26.257728669000073],
            [67.501821133000078, 26.256915093000032],
            [67.50234714100003, 26.255277249000073],
            [67.50274113100005, 26.254045143000042],
            [67.502840463000041, 26.253204570000037],
            [67.50308203600008, 26.251153359000057],
            [67.50322763500003, 26.24989725100005],
            [67.502942792000056, 26.248565291000034],
            [67.502848044000075, 26.248125504000029],
            [67.501903651000077, 26.24374197800006],
            [67.501715130000036, 26.242860417000031],
            [67.501161406000051, 26.241323990000069],
            [67.499861962000068, 26.237717253000028],
            [67.498804727000049, 26.234783069000059],
            [67.498200681000071, 26.233106637000049],
            [67.497640968000042, 26.231920194000054],
            [67.497081754000078, 26.230734809000069],
            [67.495403435000071, 26.227177221000034],
            [67.494844209000064, 26.225991810000039],
            [67.493135401000075, 26.222514819000025],
            [67.492293694000068, 26.220802159000073],
            [67.491509247000067, 26.219206007000025],
            [67.49147199600003, 26.219062428000029],
            [67.49103133400007, 26.217363937000073],
            [67.489460013000041, 26.211307437000073],
            [67.489136377000079, 26.210060012000042],
            [67.488800661000028, 26.208312157000023],
            [67.487961195000082, 26.203942686000062],
            [67.487738602000036, 26.202783994000072],
            [67.487610539000059, 26.202117368000074],
            [67.487441767000064, 26.201238837000062],
            [67.487319264000064, 26.199609198000076],
            [67.487212106000072, 26.198183687000039],
            [67.486662009000042, 26.190865815000052],
            [67.485991755000043, 26.18194950700007],
            [67.485857883000051, 26.180168633000051],
            [67.485757465000063, 26.178832772000078],
            [67.485555126000065, 26.177566880000029],
            [67.485091324000052, 26.174668138000072],
            [67.485082843000043, 26.174615133000032],
            [67.484877099000073, 26.17332884700005],
            [67.484602693000056, 26.171613300000047],
            [67.484108582000033, 26.170015748000026],
            [67.483614621000072, 26.168417992000059],
            [67.482442247000051, 26.164626673000043],
            [67.481881975000078, 26.162814820000051],
            [67.481078873000058, 26.161330419000024],
            [67.478870579000045, 26.157249912000054],
            [67.476125755000055, 26.152177616000074],
            [67.475910261000081, 26.151788488000022],
            [67.474754311000083, 26.149701140000047],
            [67.473318232000054, 26.147107952000056],
            [67.472670439000069, 26.14593820500005],
            [67.472022268000046, 26.144767774000059],
            [67.471262097000078, 26.14365526000006],
            [67.470778382000049, 26.142947340000035],
            [67.468747242000063, 26.139974752000057],
            [67.467968719000055, 26.138835379000056],
            [67.467208189000075, 26.137722339000049],
            [67.46581120500008, 26.136216128000058],
            [67.464134352000031, 26.134407933000034],
            [67.461688504000051, 26.131770663000054],
            [67.460160224000049, 26.130122775000075],
            [67.459146752000038, 26.129261166000049],
            [67.458134390000055, 26.128400502000034],
            [67.45307004600005, 26.124095020000027],
            [67.452690622000034, 26.123772451000036],
            [67.452056946000027, 26.123233728000059],
            [67.450964727000041, 26.122482283000068],
            [67.445504862000064, 26.11872590400003],
            [67.444413997000083, 26.117975390000026],
            [67.443685157000061, 26.117508746000055],
            [67.443350255000041, 26.117294322000078],
            [67.441933476000031, 26.116387487000054],
            [67.441583503000061, 26.116163452000023],
            [67.438642988000083, 26.114281085000073],
            [67.437777683000036, 26.113728025000057],
            [67.437046505000069, 26.113260693000029],
            [67.436610194000082, 26.112981703000059],
            [67.435273240000072, 26.112127108000038],
            [67.434995438000044, 26.111907556000062],
            [67.434251159000041, 26.111319338000044],
            [67.432889808000084, 26.110243490000073],
            [67.431397563000075, 26.109064282000077],
            [67.429485442000043, 26.107553278000069],
            [67.429278839000062, 26.107390009000028],
            [67.42914118300007, 26.107281226000055],
            [67.425824377000083, 26.104660107000029],
            [67.424331982000069, 26.103480736000051],
            [67.422957137000083, 26.10239425900005],
            [67.422371786000042, 26.101487347000045],
            [67.421993855000039, 26.100901802000067],
            [67.419875299000068, 26.097619074000079],
            [67.418236713000056, 26.095080065000047],
            [67.416959893000069, 26.096498904000043],
            [67.402331000000061, 26.112754000000052],
            [67.400367514000038, 26.11697160600005],
            [67.391677000000072, 26.135639000000026],
            [67.359323000000074, 26.154578000000072],
            [67.356551804000048, 26.162371852000035],
            [67.353438076000032, 26.17112905700003],
            [67.348275000000058, 26.183776000000023],
            [67.34606683100003, 26.192842636000023],
            [67.343540000000075, 26.203110000000038],
            [67.337227000000041, 26.212579000000062],
            [67.332115791000035, 26.218852009000045],
            [67.328547000000071, 26.223232000000053],
            [67.319866000000047, 26.245328000000029],
            [67.294103729000028, 26.293312062000041],
            [67.294103531000076, 26.293312430000071],
            [67.265611772000057, 26.347194002000037],
            [67.255157000000054, 26.370801000000029],
            [67.240132330000051, 26.393259626000031],
            [67.23240710400006, 26.404807164000033],
            [67.215306000000055, 26.42840700000005],
            [67.193605000000048, 26.45366000000007],
            [67.17742700000008, 26.477334000000042],
            [67.168157824000048, 26.499955947000046],
            [67.16203900000005, 26.514028000000053],
            [67.155985009000062, 26.537154005000048],
            [67.150597000000062, 26.55585300000007],
            [67.14931494700005, 26.56280543500003],
            [67.145606966000059, 26.58291342800004],
            [67.141744986000049, 26.596856602000059],
            [67.128896000000054, 26.633977000000073],
            [67.127546823000046, 26.655087543000036],
            [67.127546794000068, 26.655088000000035],
            [67.127546789000064, 26.655088081000031],
            [67.133695057000068, 26.671001042000057],
            [67.134025000000065, 26.67185500000005],
            [67.131807791000028, 26.685404882000057],
            [67.130474000000049, 26.693556000000058],
            [67.127942153000049, 26.717818956000031],
            [67.125739000000067, 26.738932000000034],
            [67.125754700000073, 26.738948820000076],
            [67.131263000000047, 26.744850000000042],
            [67.137576000000081, 26.755898000000059],
            [67.147046000000046, 26.766157000000078],
            [67.147043981000081, 26.76617329100003],
            [67.144964375000029, 26.78295242400003],
            [67.13865276100006, 26.833877160000043],
            [67.138529515000073, 26.834871569000029],
            [67.134478519000083, 26.871516118000045],
            [67.134326220000048, 26.872893777000058],
            [67.132537400000047, 26.906880705000049],
            [67.13195369500005, 26.917970886000035],
            [67.131658000000073, 26.92358900000005],
            [67.132956912000054, 26.930897362000053],
            [67.133029006000072, 26.931302999000025],
            [67.133260456000073, 26.932605228000057],
            [67.133448591000047, 26.933663623000029],
            [67.133483923000028, 26.933862388000023],
            [67.133490014000074, 26.933896651000055],
            [67.133490412000072, 26.933898890000023],
            [67.133495642000071, 26.93392831400007],
            [67.133584596000048, 26.934428743000069],
            [67.134814000000063, 26.941345000000069],
            [67.138605884000071, 26.94687426400003],
            [67.144284000000027, 26.95515400000005],
            [67.145073000000082, 26.969359000000054],
            [67.143281727000044, 26.974104056000044],
            [67.141113281000059, 26.979675293000071],
            [67.141053688000056, 26.980006096000068],
            [67.140911036000034, 26.980383978000077],
            [67.13797100000005, 26.996189000000072],
            [67.148624000000041, 27.031306000000029],
            [67.154462268000032, 27.044672029000026],
            [67.163618000000042, 27.065633000000048],
            [67.162451774000033, 27.067840749000027],
            [67.15850830100004, 27.074707031000059],
            [67.158469108000077, 27.075380214000063],
            [67.158450482000035, 27.075415477000035],
            [67.158281083000077, 27.078609838000034],
            [67.157470703000058, 27.092529297000056],
            [67.155700684000067, 27.126892090000069],
            [67.155151351000029, 27.130340679000028],
            [67.15417437900004, 27.136375561000023],
            [67.149019000000067, 27.168221000000074],
            [67.140338000000042, 27.213990000000024],
            [67.134845915000028, 27.22965324300003],
            [67.129685000000052, 27.244372000000055],
            [67.123372000000074, 27.275938000000053],
            [67.127317000000062, 27.309081000000049],
            [67.137182000000053, 27.331571000000054],
            [67.15335900000008, 27.347354000000053],
            [67.163223000000073, 27.367477000000065],
            [67.165196000000037, 27.387600000000077],
            [67.166305000000079, 27.399616273000049],
            [67.166396491000057, 27.400607600000058],
            [67.16756300000003, 27.413247000000069],
            [67.169494094000072, 27.422423187000049],
            [67.173129938000045, 27.439700006000066],
            [67.17398380000003, 27.447917647000054],
            [67.175163155000064, 27.447196911000049],
            [67.18356714600003, 27.442060422000054],
            [67.188919824000038, 27.438789013000076],
            [67.190059596000083, 27.438092416000075],
            [67.190442395000048, 27.437858463000055],
            [67.191587996000067, 27.437158313000054],
            [67.192575541000053, 27.436267827000052],
            [67.192901575000064, 27.435973837000063],
            [67.194636449000029, 27.434409472000027],
            [67.195532947000061, 27.433601084000031],
            [67.198285061000036, 27.430846161000034],
            [67.199501527000052, 27.429628452000031],
            [67.200455655000042, 27.42895549900004],
            [67.203175223000073, 27.427037231000043],
            [67.204001176000077, 27.426454650000039],
            [67.20533726900004, 27.42551224400006],
            [67.20708705900006, 27.425332769000079],
            [67.213434182000071, 27.424681747000079],
            [67.216703306000056, 27.424346434000029],
            [67.218466052000053, 27.424165630000061],
            [67.219697309000082, 27.423787836000031],
            [67.220056014000079, 27.423677792000035],
            [67.223378449000052, 27.422658531000025],
            [67.224602191000031, 27.422266219000051],
            [67.22590017400006, 27.421554842000035],
            [67.228830161000076, 27.419948998000052],
            [67.233070737000048, 27.417530398000054],
            [67.233445681000035, 27.417316550000066],
            [67.234013904000051, 27.416908589000059],
            [67.234460307000063, 27.416588001000036],
            [67.235126836000063, 27.416109223000035],
            [67.235846368000068, 27.415592489000062],
            [67.236562223000078, 27.414461623000079],
            [67.237268110000059, 27.413347932000079],
            [67.23798490300004, 27.412215113000059],
            [67.23836302400008, 27.410212535000028],
            [67.23851449600005, 27.409409202000063],
            [67.238739029000044, 27.408218881000039],
            [67.238869627000042, 27.407547431000069],
            [67.239138810000043, 27.406163469000035],
            [67.239453214000036, 27.404544315000066],
            [67.241780429000073, 27.402651683000045],
            [67.242998538000052, 27.402371768000023],
            [67.244259513000031, 27.402082107000069],
            [67.246285252000064, 27.401616987000068],
            [67.248444795000069, 27.401103977000048],
            [67.24931543200006, 27.40089714100003],
            [67.251173412000071, 27.400455762000036],
            [67.252479136000034, 27.399924122000073],
            [67.255157763000057, 27.398833490000072],
            [67.256354460000068, 27.39834624100007],
            [67.257451149000076, 27.397674301000052],
            [67.26147489300007, 27.39520903600004],
            [67.261870613000042, 27.394966489000069],
            [67.262784977000081, 27.394198294000034],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "128",
      properties: { name: "Ghotki", count: 249 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.812146253000037, 28.322526942000025],
            [69.809706000000062, 28.311789000000033],
            [69.809967493000045, 28.306821691000039],
            [69.81037733800008, 28.29903630900003],
            [69.810932000000037, 28.288500000000056],
            [69.814609000000075, 28.275017000000048],
            [69.819465969000078, 28.273895880000055],
            [69.825947193000047, 28.272399837000023],
            [69.830543000000034, 28.271339000000069],
            [69.830248509000057, 28.268246300000044],
            [69.830090953000081, 28.266591671000072],
            [69.828973535000046, 28.254856732000064],
            [69.828092000000083, 28.245599000000027],
            [69.829765513000041, 28.242902904000061],
            [69.839124000000083, 28.22782600000005],
            [69.841435698000055, 28.223066138000036],
            [69.844700683000042, 28.216343428000073],
            [69.844700727000031, 28.216343339000048],
            [69.850474021000082, 28.204604327000027],
            [69.855671000000029, 28.194731000000047],
            [69.851068244000032, 28.191509120000035],
            [69.849459757000034, 28.190383197000074],
            [69.837285000000065, 28.181861000000026],
            [69.837285000000065, 28.170216000000039],
            [69.840337451000039, 28.16605385500003],
            [69.84402700000004, 28.161023000000057],
            [69.842453513000066, 28.143194430000051],
            [69.842188000000078, 28.140186000000028],
            [69.847983585000065, 28.130393211000069],
            [69.856055212000058, 28.116754601000025],
            [69.859961000000055, 28.110155000000077],
            [69.866671318000044, 28.099718562000078],
            [69.870233644000052, 28.094176791000052],
            [69.872154186000046, 28.091189080000049],
            [69.879660876000059, 28.07951121900004],
            [69.882024000000058, 28.075835000000041],
            [69.896109229000047, 28.057672198000034],
            [69.899609278000071, 28.053158909000047],
            [69.902809036000065, 28.049032843000077],
            [69.905313000000035, 28.045804000000032],
            [69.90864463500003, 28.042614165000032],
            [69.925492010000028, 28.026483850000034],
            [69.934118000000069, 28.018225000000029],
            [69.936679710000078, 28.015499832000046],
            [69.962179258000049, 27.988373199000023],
            [69.962923000000046, 27.987582000000032],
            [69.96494574500008, 27.984158796000031],
            [69.970890000000054, 27.974099000000024],
            [69.977545000000077, 27.963784000000032],
            [69.983147000000031, 27.955100000000073],
            [69.994179000000031, 27.939778000000047],
            [70.007662000000039, 27.935488000000078],
            [70.028500000000065, 27.934262000000047],
            [70.041370000000029, 27.920779000000039],
            [70.056079000000068, 27.904232000000036],
            [70.074465000000032, 27.895039000000054],
            [70.09407600000003, 27.889523000000054],
            [70.114301000000069, 27.885233000000028],
            [70.145557000000053, 27.877879000000064],
            [70.160879000000079, 27.867460000000051],
            [70.177147299000069, 27.846508045000064],
            [70.177194410000084, 27.846447370000078],
            [70.16527700000006, 27.836668000000031],
            [70.143059000000051, 27.816318000000024],
            [70.135946000000047, 27.80711400000007],
            [70.12636800000007, 27.792647000000045],
            [70.116861000000029, 27.768286000000046],
            [70.114871000000051, 27.761524000000065],
            [70.111879000000044, 27.752507000000037],
            [70.107868000000053, 27.744386000000077],
            [70.098363000000063, 27.723644000000036],
            [70.086911000000043, 27.695255000000031],
            [70.079405000000065, 27.676325000000077],
            [70.060979000000032, 27.633950000000027],
            [70.044890000000066, 27.594877000000054],
            [70.037526133000028, 27.578210449000039],
            [70.030309000000045, 27.561876000000041],
            [70.004550000000052, 27.547313000000031],
            [69.988902000000053, 27.537311000000045],
            [69.955120000000079, 27.512788000000057],
            [69.935991000000058, 27.497813000000065],
            [69.927451105000046, 27.486389635000023],
            [69.922468000000038, 27.479724000000033],
            [69.922190327000067, 27.479187014000047],
            [69.921135874000072, 27.479398115000038],
            [69.921997580000038, 27.478814266000029],
            [69.914997000000028, 27.465276000000074],
            [69.904477000000043, 27.450359000000049],
            [69.893062000000043, 27.432300000000055],
            [69.884509000000037, 27.422333000000037],
            [69.87402000000003, 27.411922000000061],
            [69.851369000000034, 27.391055000000051],
            [69.83432700000003, 27.371591000000024],
            [69.817803000000083, 27.355269000000078],
            [69.801022000000046, 27.342262000000062],
            [69.793444686000043, 27.335749433000046],
            [69.781581565000067, 27.337278519000051],
            [69.771929898000053, 27.330069566000077],
            [69.744156000000032, 27.342251000000033],
            [69.722627000000045, 27.360551000000044],
            [69.697868000000028, 27.377775000000042],
            [69.653732000000048, 27.388540000000035],
            [69.621438000000069, 27.399305000000027],
            [69.591296000000057, 27.421911000000023],
            [69.564384000000075, 27.439134000000024],
            [69.532499892000033, 27.462790536000057],
            [69.531915825000056, 27.463223888000073],
            [69.531014000000084, 27.463893000000041],
            [69.547161000000074, 27.488652000000059],
            [69.547443471000065, 27.48958016000006],
            [69.554696000000035, 27.513411000000076],
            [69.550690084000053, 27.527431705000026],
            [69.55039000000005, 27.528482000000054],
            [69.541775944000051, 27.525251541000046],
            [69.533167000000049, 27.522023000000047],
            [69.520249000000035, 27.518794000000071],
            [69.515764865000051, 27.518794000000071],
            [69.511295739000047, 27.518794000000071],
            [69.501277891000029, 27.518794000000071],
            [69.488209484000038, 27.518794000000071],
            [69.476113000000055, 27.518794000000071],
            [69.459500245000072, 27.518239967000056],
            [69.443819000000076, 27.517717000000061],
            [69.431880195000076, 27.525885564000077],
            [69.423366000000044, 27.53171100000003],
            [69.402913000000069, 27.53494100000006],
            [69.393454163000058, 27.535886737000055],
            [69.385301208000044, 27.536701907000065],
            [69.370618000000036, 27.538170000000036],
            [69.363404465000031, 27.539263108000057],
            [69.335095000000081, 27.543553000000031],
            [69.318523837000043, 27.555737453000063],
            [69.298494000000062, 27.57046500000007],
            [69.281271000000061, 27.588765000000024],
            [69.278935598000032, 27.592417984000065],
            [69.256100684000046, 27.628136520000055],
            [69.249230169000043, 27.638881992000051],
            [69.240822360000038, 27.652030500000023],
            [69.239288000000045, 27.654430000000048],
            [69.231905466000057, 27.661812534000035],
            [69.217759000000058, 27.675959000000034],
            [69.196229000000073, 27.689954000000057],
            [69.166325153000059, 27.694078735000062],
            [69.16501100000005, 27.694260000000043],
            [69.156675016000065, 27.695093158000077],
            [69.154246000000057, 27.695336000000054],
            [69.154840979000028, 27.703368084000033],
            [69.155519579000043, 27.712529020000034],
            [69.155918254000028, 27.717911040000047],
            [69.156399000000079, 27.724401000000057],
            [69.156437925000034, 27.724396134000074],
            [69.164673917000073, 27.723366475000034],
            [69.168117625000036, 27.722935945000074],
            [69.182235000000048, 27.721171000000027],
            [69.197306000000083, 27.727630000000033],
            [69.198382036000055, 27.734087213000066],
            [69.199553073000061, 27.741114523000078],
            [69.200535000000059, 27.747007000000053],
            [69.229600000000062, 27.73731900000007],
            [69.233906000000047, 27.727630000000033],
            [69.255435066000075, 27.736241836000033],
            [69.26334582000004, 27.739406121000059],
            [69.271583000000078, 27.742701000000068],
            [69.271583000000078, 27.750307530000043],
            [69.271583000000078, 27.754542000000072],
            [69.263080781000042, 27.763448890000063],
            [69.256283990000043, 27.770569179000063],
            [69.248976000000084, 27.778225000000077],
            [69.259741000000076, 27.791143000000034],
            [69.254359000000079, 27.798678000000052],
            [69.236059000000068, 27.815901000000054],
            [69.227447000000041, 27.829896000000076],
            [69.221189843000047, 27.837493871000049],
            [69.212376000000063, 27.84819600000003],
            [69.211635547000071, 27.859307607000062],
            [69.211632460000033, 27.859353945000066],
            [69.211300000000051, 27.864343000000076],
            [69.200535000000059, 27.883720000000039],
            [69.198869734000084, 27.88645316800006],
            [69.198806300000058, 27.886485899000036],
            [69.187873198000034, 27.903978727000037],
            [69.184388000000069, 27.909555000000069],
            [69.18395087500005, 27.910939196000072],
            [69.183908092000081, 27.911074672000041],
            [69.177929000000063, 27.930008000000043],
            [69.18331100000006, 27.948308000000054],
            [69.176852000000054, 27.968761000000029],
            [69.16501100000005, 27.977373000000057],
            [69.177928985000051, 27.991612953000072],
            [69.181009000000074, 27.991784000000052],
            [69.18260228500003, 27.992978942000036],
            [69.19199085300005, 28.000020238000047],
            [69.199172000000033, 28.00540600000005],
            [69.19872472600008, 28.016584303000059],
            [69.198331495000048, 28.026411972000062],
            [69.197658000000047, 28.043244000000072],
            [69.198226176000048, 28.046937238000055],
            [69.198269328000038, 28.047217733000025],
            [69.199824688000035, 28.057327830000077],
            [69.199830644000031, 28.057366548000061],
            [69.200041700000043, 28.05873844100006],
            [69.200259057000039, 28.06015129900004],
            [69.200685000000078, 28.062920000000076],
            [69.204032114000029, 28.069613859000071],
            [69.208648250000067, 28.078845624000053],
            [69.209767000000056, 28.081083000000035],
            [69.215925587000072, 28.087801660000025],
            [69.222088257000053, 28.094524775000025],
            [69.226415000000031, 28.099245000000053],
            [69.241971719000048, 28.100801273000059],
            [69.245484859000044, 28.101152723000041],
            [69.258552522000059, 28.102459994000071],
            [69.261186063000082, 28.102723370000035],
            [69.266327756000067, 28.10323711500007],
            [69.271822000000043, 28.103786000000071],
            [69.285119618000067, 28.113759580000078],
            [69.289984000000061, 28.117408000000069],
            [69.302463000000046, 28.116200000000049],
            [69.303501908000044, 28.116970794000054],
            [69.306339234000063, 28.11907588400004],
            [69.312942430000078, 28.123974976000056],
            [69.318381000000045, 28.128010000000074],
            [69.328650000000039, 28.126469000000043],
            [69.34143664700008, 28.137785868000037],
            [69.350968416000057, 28.146221995000076],
            [69.35100592100008, 28.146255189000044],
            [69.351461033000078, 28.146657987000026],
            [69.351509817000078, 28.146701164000035],
            [69.353721230000076, 28.148658382000065],
            [69.363199766000037, 28.157047395000063],
            [69.368400368000039, 28.161650207000037],
            [69.373323000000028, 28.166007000000036],
            [69.379128402000049, 28.16541469200007],
            [69.387766810000073, 28.164533341000038],
            [69.397254114000077, 28.163565380000023],
            [69.398483000000056, 28.163440000000037],
            [69.406346669000072, 28.160015515000055],
            [69.413930091000054, 28.156713072000059],
            [69.419354881000061, 28.154350675000046],
            [69.425838181000074, 28.151527315000067],
            [69.430319000000054, 28.149576000000025],
            [69.438564769000038, 28.158057611000061],
            [69.442260770000075, 28.161859324000034],
            [69.448290000000043, 28.168061000000023],
            [69.452110965000031, 28.173672693000071],
            [69.452474524000081, 28.174206637000054],
            [69.454619910000076, 28.177357477000044],
            [69.464722000000052, 28.192194000000029],
            [69.471012256000051, 28.198484256000029],
            [69.474811632000069, 28.202283632000047],
            [69.477559000000042, 28.205031000000076],
            [69.488719007000043, 28.203737846000024],
            [69.493422283000029, 28.199939617000041],
            [69.499246514000049, 28.195236135000073],
            [69.504125114000033, 28.19129631800007],
            [69.51218625000007, 28.184786377000023],
            [69.514725421000037, 28.182735816000047],
            [69.520807712000078, 28.17782393300007],
            [69.521925705000058, 28.166574002000061],
            [69.522317412000064, 28.162632410000072],
            [69.522688221000067, 28.15890111300007],
            [69.524117688000047, 28.144516940000074],
            [69.524787353000079, 28.137778364000042],
            [69.524996810000061, 28.137651267000024],
            [69.530528867000044, 28.13429446400005],
            [69.537613800000031, 28.129995389000044],
            [69.55126591800007, 28.129959333000045],
            [69.553256545000067, 28.132983241000034],
            [69.55497507900003, 28.135593820000054],
            [69.560601707000046, 28.144141077000029],
            [69.560510588000056, 28.16996961500007],
            [69.571969509000041, 28.18672835700005],
            [69.575751896000043, 28.195181725000054],
            [69.578318514000046, 28.200917937000042],
            [69.574068544000056, 28.202153322000072],
            [69.560383349000062, 28.206131347000053],
            [69.552676741000084, 28.213901140000075],
            [69.543849987000044, 28.213924587000065],
            [69.53603944200006, 28.213945335000062],
            [69.534063217000039, 28.215442084000074],
            [69.530981187000066, 28.217776344000072],
            [69.527115207000065, 28.220704351000052],
            [69.525773122000032, 28.221720816000072],
            [69.520476435000035, 28.230590936000056],
            [69.515043000000048, 28.242515000000026],
            [69.517784497000036, 28.253208654000048],
            [69.519499026000062, 28.259896454000057],
            [69.520177000000047, 28.262541000000056],
            [69.520177000000047, 28.271784000000025],
            [69.525770941000076, 28.270626594000078],
            [69.530193008000083, 28.269711653000059],
            [69.535068000000081, 28.268703000000073],
            [69.540501862000042, 28.264707440000052],
            [69.552526000000057, 28.255866000000026],
            [69.573137588000066, 28.253143286000068],
            [69.57974100000007, 28.252271000000064],
            [69.594118000000037, 28.256379000000038],
            [69.603804788000048, 28.23184425900007],
            [69.628119043000083, 28.23177836900004],
            [69.633566482000049, 28.231185094000068],
            [69.633579481000083, 28.231183679000026],
            [69.639560059000075, 28.230532340000025],
            [69.642248833000053, 28.230239509000057],
            [69.662646976000076, 28.238141368000072],
            [69.666400112000076, 28.246529914000064],
            [69.667114097000081, 28.248125725000079],
            [69.668994860000055, 28.252329374000055],
            [69.673154882000063, 28.254790401000037],
            [69.676557290000062, 28.256803232000038],
            [69.680028315000072, 28.258856656000034],
            [69.68550950000008, 28.262099271000068],
            [69.692738731000077, 28.263764514000059],
            [69.693266569000059, 28.263886100000036],
            [69.704472538000061, 28.252585148000037],
            [69.708675959000061, 28.24834609800007],
            [69.724733960000037, 28.242076000000054],
            [69.725332009000056, 28.241842482000038],
            [69.736391276000063, 28.249558343000047],
            [69.737497141000063, 28.250329885000042],
            [69.741347077000057, 28.253015920000053],
            [69.741465119000054, 28.25309827600006],
            [69.741924366000035, 28.253418684000053],
            [69.741914634000068, 28.256156934000046],
            [69.741899035000074, 28.260546213000055],
            [69.741865927000049, 28.269862437000029],
            [69.741849271000035, 28.274549147000073],
            [69.741846342000031, 28.275373307000052],
            [69.743154939000078, 28.286248583000031],
            [69.74387523300004, 28.292234685000039],
            [69.744331789000057, 28.296028952000029],
            [69.745356384000047, 28.301569546000053],
            [69.746986845000038, 28.310386418000064],
            [69.747632058000079, 28.313875469000038],
            [69.737892000000045, 28.31440200000003],
            [69.713618763000056, 28.294092784000043],
            [69.712731000000076, 28.293350000000032],
            [69.709741055000052, 28.294545912000046],
            [69.694760000000031, 28.300538000000074],
            [69.682436000000052, 28.31440200000003],
            [69.676147941000067, 28.323950651000075],
            [69.669468682000058, 28.33409335500005],
            [69.66857200000004, 28.335455000000024],
            [69.650279873000045, 28.345472544000074],
            [69.650773295000079, 28.345613537000077],
            [69.657334453000033, 28.347488154000075],
            [69.665543593000052, 28.348201993000032],
            [69.673395815000049, 28.352485022000053],
            [69.676805798000032, 28.358168865000039],
            [69.67837563900008, 28.360785275000069],
            [69.681961874000081, 28.366761788000076],
            [69.688743338000052, 28.374614009000027],
            [69.694810964000055, 28.38353698800006],
            [69.703733942000042, 28.389604614000064],
            [69.711586164000039, 28.392459967000036],
            [69.719953972000042, 28.392816044000028],
            [69.728361364000079, 28.39317380600005],
            [69.740853534000053, 28.39852759200005],
            [69.74967508900005, 28.404224847000023],
            [69.750892236000084, 28.405010827000069],
            [69.761902000000077, 28.395139000000029],
            [69.773174033000032, 28.386685080000063],
            [69.783624915000075, 28.378847015000076],
            [69.788869000000034, 28.374914000000047],
            [69.799900000000036, 28.365721000000065],
            [69.803661490000081, 28.364335273000052],
            [69.811545000000081, 28.361431000000039],
            [69.81583500000005, 28.338755000000049],
            [69.812146253000037, 28.322526942000025],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "129",
      properties: { name: "Hyderabad", count: 1375 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.625312000000065, 25.361250000000041],
            [68.625312000000065, 25.350205000000074],
            [68.624358125000072, 25.350101312000049],
            [68.620001388000048, 25.349627725000062],
            [68.608877202000031, 25.348418501000026],
            [68.595423000000039, 25.346956000000034],
            [68.593462835000082, 25.347908112000027],
            [68.585099231000072, 25.351970571000038],
            [68.573491243000035, 25.357608926000069],
            [68.573461322000071, 25.357623459000024],
            [68.572682000000043, 25.358002000000056],
            [68.572915431000069, 25.356796109000072],
            [68.57411959500007, 25.350575470000024],
            [68.576581000000033, 25.337860000000035],
            [68.593474000000072, 25.322916000000077],
            [68.598648569000034, 25.319731744000023],
            [68.605753806000052, 25.31535942000005],
            [68.610368000000051, 25.312520000000063],
            [68.612073723000037, 25.30910797100006],
            [68.612549878000038, 25.308155499000065],
            [68.616215000000068, 25.300824000000034],
            [68.615938674000063, 25.29833678500006],
            [68.614266000000043, 25.283281000000045],
            [68.640256000000079, 25.263139000000024],
            [68.640296861000081, 25.262689987000044],
            [68.641677885000036, 25.247496955000031],
            [68.642205000000047, 25.241698000000042],
            [68.62791100000004, 25.241048000000035],
            [68.619105309000076, 25.240419264000025],
            [68.609718000000044, 25.239749000000074],
            [68.600621000000046, 25.23844900000006],
            [68.604568335000067, 25.225481540000033],
            [68.604579475000037, 25.225444945000049],
            [68.605170000000044, 25.223505000000046],
            [68.607769000000076, 25.202063000000066],
            [68.605841677000058, 25.198356890000071],
            [68.599322000000029, 25.185820000000035],
            [68.596644641000069, 25.184539547000043],
            [68.584378000000072, 25.17867300000006],
            [68.57317196300005, 25.196281549000048],
            [68.572510666000028, 25.197320674000025],
            [68.570733000000075, 25.200114000000042],
            [68.565475408000054, 25.201327482000067],
            [68.559181218000049, 25.202780217000054],
            [68.553840000000037, 25.204013000000032],
            [68.562287000000083, 25.191018000000042],
            [68.559353208000061, 25.179284338000059],
            [68.558388000000036, 25.175424000000078],
            [68.539977369000042, 25.170960661000038],
            [68.536947000000055, 25.170226000000071],
            [68.529150000000072, 25.157881000000032],
            [68.52026932900003, 25.160840920000055],
            [68.509657000000061, 25.164378000000056],
            [68.503634987000055, 25.162293663000071],
            [68.503607017000036, 25.162283982000076],
            [68.49924055300005, 25.160772662000056],
            [68.492764000000079, 25.158531000000039],
            [68.476770716000033, 25.169561290000047],
            [68.473922000000073, 25.171526000000028],
            [68.471156933000032, 25.173164522000036],
            [68.46571582200005, 25.176388699000029],
            [68.456379000000084, 25.181921000000045],
            [68.455038048000063, 25.180899439000029],
            [68.454998121000074, 25.180869022000024],
            [68.44273400000003, 25.171526000000028],
            [68.440444141000057, 25.172468810000055],
            [68.431688000000065, 25.176074000000028],
            [68.419343000000083, 25.163729000000046],
            [68.405049000000076, 25.170876000000078],
            [68.376460000000066, 25.17867300000006],
            [68.36588523100005, 25.187660943000026],
            [68.363465000000076, 25.189718000000028],
            [68.350441710000041, 25.206462912000063],
            [68.349821000000077, 25.207261000000074],
            [68.343328635000034, 25.216426742000067],
            [68.338775000000055, 25.222855000000038],
            [68.33311380400005, 25.229082534000042],
            [68.327495869000074, 25.235262478000038],
            [68.327448736000065, 25.235314326000037],
            [68.325780000000066, 25.237150000000042],
            [68.325780000000066, 25.247178442000063],
            [68.325780000000066, 25.259241000000031],
            [68.339491329000055, 25.275236964000044],
            [68.345273000000077, 25.281982000000028],
            [68.345273000000077, 25.300261375000048],
            [68.345273000000077, 25.303423000000066],
            [68.344285268000078, 25.302717624000024],
            [68.336057000000039, 25.317940000000078],
            [68.321912000000054, 25.337658000000033],
            [68.318147020000083, 25.345422911000071],
            [68.308195000000069, 25.36594800000006],
            [68.297886598000048, 25.381271729000048],
            [68.293720859000075, 25.387463215000025],
            [68.292335000000037, 25.389523000000054],
            [68.293064344000072, 25.398272064000025],
            [68.293069485000046, 25.398333733000072],
            [68.294479000000081, 25.415242000000035],
            [68.304337000000032, 25.42167100000006],
            [68.305955674000074, 25.430688326000052],
            [68.307338000000072, 25.438389000000029],
            [68.306257233000053, 25.444408042000077],
            [68.305967352000039, 25.44602302100003],
            [68.305418736000036, 25.449079459000075],
            [68.304337000000032, 25.455106000000058],
            [68.30262300000004, 25.476538000000062],
            [68.307673048000083, 25.488192444000049],
            [68.308195000000069, 25.489397000000054],
            [68.319340000000068, 25.507400000000075],
            [68.336485000000039, 25.510400000000061],
            [68.36006100000003, 25.516830000000027],
            [68.373494969000035, 25.522378828000058],
            [68.379778000000044, 25.524974000000043],
            [68.384123362000082, 25.526375909000024],
            [68.387839674000077, 25.527574873000049],
            [68.390727940000033, 25.528506691000075],
            [68.393066000000033, 25.529261000000076],
            [68.388351000000057, 25.539548000000025],
            [68.383984472000066, 25.551921167000046],
            [68.383208000000081, 25.554122000000064],
            [68.392638000000034, 25.55455100000006],
            [68.420071000000064, 25.548121000000037],
            [68.422034579000069, 25.547211054000059],
            [68.433983934000082, 25.541673582000044],
            [68.437645000000032, 25.539977000000079],
            [68.441919940000048, 25.532140164000054],
            [68.445361000000048, 25.525832000000037],
            [68.446756793000077, 25.522641416000056],
            [68.45436200000006, 25.505257000000029],
            [68.468507000000045, 25.490683000000047],
            [68.476714153000046, 25.486498895000068],
            [68.477684789000079, 25.486004053000045],
            [68.482816054000068, 25.483388072000025],
            [68.482840739000039, 25.483375488000036],
            [68.491549385000042, 25.478989502000047],
            [68.492968383000061, 25.47833068500006],
            [68.526374000000033, 25.462821000000076],
            [68.538437563000059, 25.457543228000077],
            [68.540833005000081, 25.456495229000041],
            [68.540874655000039, 25.456477007000046],
            [68.557986392000032, 25.448990674000072],
            [68.564204238000059, 25.446270386000037],
            [68.567524000000049, 25.444818000000055],
            [68.589384000000052, 25.436674000000039],
            [68.589860414000043, 25.436606013000073],
            [68.590170380000075, 25.426702077000073],
            [68.59022600000003, 25.42492500000003],
            [68.612317000000075, 25.409981000000073],
            [68.615864897000051, 25.39401364500003],
            [68.616215000000068, 25.392438000000027],
            [68.614134083000067, 25.389056609000079],
            [68.605819000000054, 25.375545000000045],
            [68.625312000000065, 25.361250000000041],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "130",
      properties: { name: "Jacobabad", count: 922 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.919767999000044, 28.43466688500007],
            [68.921852029000036, 28.422166570000059],
            [68.922664371000053, 28.417294025000047],
            [68.925222000000076, 28.401953000000049],
            [68.924262223000028, 28.390756518000046],
            [68.92407036000003, 28.38851829500004],
            [68.922633007000059, 28.371750557000041],
            [68.921172845000058, 28.35471672400007],
            [68.920681000000059, 28.348979000000043],
            [68.921492236000063, 28.339649378000047],
            [68.921982462000074, 28.334011545000067],
            [68.923708000000033, 28.314167000000054],
            [68.923330376000081, 28.309446634000039],
            [68.923102345000075, 28.306596216000059],
            [68.922652194000079, 28.300969247000069],
            [68.921597415000065, 28.287784335000026],
            [68.921438361000071, 28.285796134000066],
            [68.921185284000046, 28.282632629000034],
            [68.920828037000035, 28.278166982000073],
            [68.920681000000059, 28.276329000000032],
            [68.91500467000003, 28.266489995000029],
            [68.905887990000053, 28.250687696000057],
            [68.903516290000084, 28.246576736000065],
            [68.89797800000008, 28.236977000000024],
            [68.903301515000066, 28.226329971000041],
            [68.903455521000069, 28.226021958000047],
            [68.907059000000061, 28.218815000000063],
            [68.908269388000065, 28.198243999000056],
            [68.908375230000047, 28.196445177000044],
            [68.908573000000047, 28.193084000000056],
            [68.901005000000055, 28.18249000000003],
            [68.913113000000067, 28.16130000000004],
            [68.909557105000033, 28.145652556000073],
            [68.909550496000065, 28.145623474000047],
            [68.909151977000079, 28.143869825000024],
            [68.908269456000028, 28.139986359000034],
            [68.906739627000036, 28.13325446500005],
            [68.906224900000041, 28.130989445000068],
            [68.905811516000028, 28.129170384000076],
            [68.905546000000072, 28.128002000000038],
            [68.905546000000072, 28.125614478000045],
            [68.905546000000072, 28.117677872000058],
            [68.905546000000072, 28.111354000000063],
            [68.933597750000047, 28.097327500000063],
            [68.920815000000061, 28.097170000000062],
            [68.902351000000067, 28.09420300000005],
            [68.897840839000082, 28.091877388000057],
            [68.886810359000037, 28.086189650000051],
            [68.881249000000082, 28.083322000000067],
            [68.87234600000005, 28.09123500000004],
            [68.861955745000046, 28.094081970000047],
            [68.854806258000053, 28.09604095800006],
            [68.848277000000053, 28.097830000000044],
            [68.843416915000034, 28.099489515000073],
            [68.835893146000046, 28.102058566000039],
            [68.835640955000031, 28.102144679000048],
            [68.821240000000046, 28.107062000000042],
            [68.801457000000028, 28.110029000000054],
            [68.788186174000032, 28.114578718000075],
            [68.784464221000064, 28.115854738000053],
            [68.779179904000046, 28.117666393000036],
            [68.777666054000065, 28.118287891000023],
            [68.771244115000059, 28.121416707000037],
            [68.752658000000054, 28.130472000000054],
            [68.737465069000052, 28.130802063000033],
            [68.722324000000071, 28.131131000000039],
            [68.718421973000034, 28.131583444000057],
            [68.718323610000084, 28.131594849000066],
            [68.712295293000068, 28.132293838000066],
            [68.712218574000076, 28.132302734000064],
            [68.699573000000044, 28.133769000000029],
            [68.679131000000041, 28.142342000000042],
            [68.669852325000079, 28.146749177000061],
            [68.669788211000082, 28.146779630000026],
            [68.655977977000077, 28.15333920300003],
            [68.652753000000075, 28.154871000000071],
            [68.627262325000061, 28.157853986000077],
            [68.621759000000054, 28.158498000000066],
            [68.600442504000057, 28.156811043000062],
            [68.597193626000035, 28.156553931000076],
            [68.577741769000056, 28.155014539000035],
            [68.575928000000033, 28.154871000000071],
            [68.567678114000046, 28.156567837000068],
            [68.530264989000045, 28.164262948000044],
            [68.526031063000062, 28.163850855000078],
            [68.525927188000082, 28.163840744000026],
            [68.524398595000036, 28.163691957000026],
            [68.513449426000079, 28.162081886000067],
            [68.507017000000076, 28.161136000000056],
            [68.502209441000048, 28.159683693000034],
            [68.500967021000065, 28.159308372000055],
            [68.494816239000045, 28.157450293000068],
            [68.491647406000084, 28.156493026000078],
            [68.488108454000042, 28.155423950000056],
            [68.483253141000034, 28.153957217000027],
            [68.47536400000007, 28.151574000000039],
            [68.470776973000056, 28.148402123000039],
            [68.470735457000046, 28.148373415000037],
            [68.451592482000081, 28.135136265000028],
            [68.45152274000003, 28.135088039000038],
            [68.444370000000049, 28.130142000000035],
            [68.436504916000047, 28.127438308000023],
            [68.436410124000076, 28.127405722000049],
            [68.427237303000084, 28.124252483000078],
            [68.412717000000043, 28.119261000000051],
            [68.401888686000063, 28.118158099000027],
            [68.38742605200008, 28.116685031000031],
            [68.38187012700007, 28.11611914100007],
            [68.381440569000063, 28.116075389000059],
            [68.37710700000008, 28.115634000000057],
            [68.370201747000067, 28.116355440000063],
            [68.364904744000057, 28.11690885400003],
            [68.355009921000033, 28.117918200000076],
            [68.353944289000083, 28.11802689700005],
            [68.335760350000044, 28.119467407000059],
            [68.326391970000032, 28.120209617000057],
            [68.321714000000043, 28.120580000000075],
            [68.319316064000077, 28.093355187000043],
            [68.319308583000065, 28.093270256000039],
            [68.318411973000082, 28.083090651000077],
            [68.318293797000081, 28.081748947000051],
            [68.318055829000059, 28.079047200000048],
            [68.316390666000075, 28.060141873000077],
            [68.31610900000004, 28.056944000000044],
            [68.315825907000033, 28.054821133000075],
            [68.31578255900007, 28.054496073000053],
            [68.311219752000056, 28.020280304000039],
            [68.310725042000058, 28.01656986100005],
            [68.308196000000066, 27.997595000000047],
            [68.309731022000051, 27.979268727000033],
            [68.310215024000058, 27.973490344000027],
            [68.310517105000031, 27.969883861000028],
            [68.31248430200003, 27.946397958000034],
            [68.31347100000005, 27.934618000000057],
            [68.289402000000052, 27.935937000000024],
            [68.288626581000074, 27.933029619000024],
            [68.288022855000065, 27.930765991000044],
            [68.28741528300003, 27.928487941000071],
            [68.286764000000062, 27.926046000000042],
            [68.270931014000041, 27.926434556000061],
            [68.252067413000077, 27.927282437000031],
            [68.242911000000049, 27.927694000000031],
            [68.241628012000035, 27.928243903000066],
            [68.22828362000007, 27.933963388000052],
            [68.222139000000084, 27.936597000000063],
            [68.213290999000037, 27.929960520000066],
            [68.209380619000058, 27.927027523000049],
            [68.206286852000062, 27.924707031000025],
            [68.205378794000069, 27.924025938000057],
            [68.204993495000053, 27.923736943000051],
            [68.203675000000032, 27.92274800000007],
            [68.197080000000028, 27.931651000000045],
            [68.192657902000064, 27.933493559000055],
            [68.177297000000067, 27.939894000000038],
            [68.160711037000056, 27.938899003000074],
            [68.144325000000038, 27.93791600000003],
            [68.135438315000044, 27.934451413000033],
            [68.124872000000039, 27.930332000000078],
            [68.122500954000031, 27.93281091800003],
            [68.118391313000075, 27.937107299000047],
            [68.11761800000005, 27.93791600000003],
            [68.111169533000066, 27.949455200000045],
            [68.110470547000034, 27.950706000000025],
            [68.105089000000078, 27.960336000000041],
            [68.099990312000045, 27.966865685000073],
            [68.099820127000044, 27.967083634000062],
            [68.09292311400003, 27.975916361000031],
            [68.09122568500004, 27.978090189000056],
            [68.086294000000066, 27.984406000000035],
            [68.072628657000052, 27.984406000000035],
            [68.052663000000052, 27.983417000000031],
            [68.049167261000036, 27.987262243000032],
            [68.047257423000076, 27.989363025000046],
            [68.042771000000073, 27.994298000000072],
            [68.029583000000059, 27.995287000000076],
            [68.003089522000039, 27.981409418000055],
            [68.00188600000007, 27.980779000000041],
            [67.976434164000068, 27.985445320000053],
            [67.975819970000032, 27.98555792600007],
            [67.962320000000034, 27.98803300000003],
            [67.954077000000041, 27.982757000000049],
            [67.944123594000075, 27.983553546000053],
            [67.942481961000055, 27.983684922000066],
            [67.930512767000039, 27.984642787000041],
            [67.929348000000061, 27.984736000000055],
            [67.921711345000062, 27.992054200000041],
            [67.91352100000006, 27.999903000000074],
            [67.913214013000072, 28.000751128000047],
            [67.913625000000081, 28.000708001000078],
            [67.918636286000037, 28.019501906000073],
            [67.918970997000031, 28.020757178000054],
            [67.919317894000073, 28.022058153000046],
            [67.91967388900008, 28.023393246000069],
            [67.922822217000032, 28.035200473000032],
            [67.923109000000068, 28.036276000000043],
            [67.927558060000081, 28.04005767700005],
            [67.936326619000056, 28.04751090600007],
            [67.945402885000078, 28.055225685000039],
            [67.954206528000043, 28.062708735000058],
            [67.960701683000082, 28.068229582000072],
            [67.967796813000064, 28.074260405000075],
            [67.970534000000043, 28.076587000000075],
            [67.980936688000043, 28.080967055000031],
            [67.981834075000052, 28.081344900000033],
            [67.983108813000058, 28.081881629000065],
            [67.984156458000029, 28.082322740000052],
            [67.985322256000075, 28.082813599000076],
            [67.986442564000072, 28.083285305000061],
            [67.987605976000054, 28.083775160000073],
            [67.98900801700006, 28.084365490000039],
            [67.990483692000055, 28.084986823000065],
            [67.99196244500007, 28.085609453000075],
            [67.993136040000081, 28.086103595000054],
            [67.993308606000028, 28.086176254000065],
            [67.994616610000037, 28.086726990000045],
            [67.99629838900006, 28.087435103000075],
            [67.997911080000051, 28.088114128000029],
            [67.999123544000042, 28.088624636000077],
            [68.000791726000045, 28.089327024000056],
            [68.002162167000051, 28.08990404900004],
            [68.003787712000076, 28.090588485000069],
            [68.005540027000052, 28.09132629800007],
            [68.007102281000073, 28.091984086000025],
            [68.008051743000067, 28.092383857000073],
            [68.010751065000079, 28.093520407000028],
            [68.015588000000037, 28.095557000000042],
            [68.020926969000072, 28.100133229000051],
            [68.02740439400003, 28.105685271000027],
            [68.031635447000042, 28.109311864000063],
            [68.038113184000053, 28.114864173000058],
            [68.049030874000039, 28.124222129000032],
            [68.061155760000076, 28.134614819000035],
            [68.065384000000051, 28.138239000000056],
            [68.071405955000046, 28.142888852000056],
            [68.07234055300006, 28.143610502000058],
            [68.073945412000057, 28.144849694000072],
            [68.078988694000032, 28.14874386300005],
            [68.093035623000048, 28.159590197000057],
            [68.098610510000071, 28.163894846000062],
            [68.129941468000084, 28.188087042000063],
            [68.13319599700003, 28.190600026000027],
            [68.134108765000065, 28.191304820000028],
            [68.15384748300005, 28.206546069000069],
            [68.177882901000032, 28.225105013000075],
            [68.17859444100003, 28.225654429000031],
            [68.179193157000043, 28.226115923000066],
            [68.179276175000041, 28.226179914000056],
            [68.179906999000082, 28.226667000000077],
            [68.191982532000054, 28.235974235000072],
            [68.191982568000071, 28.23597426300006],
            [68.19538667300003, 28.238597982000044],
            [68.200544000000036, 28.24257300000005],
            [68.211762586000077, 28.246078734000037],
            [68.22802929900007, 28.251161975000059],
            [68.231441400000051, 28.252228234000029],
            [68.241795274000083, 28.25551091400007],
            [68.24193738300005, 28.255556647000049],
            [68.246080737000057, 28.256909593000046],
            [68.255944440000064, 28.260130430000061],
            [68.257792015000064, 28.260733726000069],
            [68.297102079000069, 28.273569804000033],
            [68.302260279000052, 28.275254132000043],
            [68.307210276000035, 28.276870475000067],
            [68.31764996000004, 28.280279388000054],
            [68.322801452000078, 28.281961526000032],
            [68.338242394000076, 28.287003520000042],
            [68.343522187000076, 28.288727553000058],
            [68.350093462000075, 28.290873298000065],
            [68.354674000000045, 28.292369000000065],
            [68.364424583000073, 28.315885197000057],
            [68.366834943000072, 28.321698439000045],
            [68.368287247000069, 28.325201068000069],
            [68.368318767000062, 28.325277085000039],
            [68.374024903000077, 28.339038994000077],
            [68.383948276000069, 28.362971923000032],
            [68.389467553000031, 28.376283168000043],
            [68.394985000000077, 28.389590000000055],
            [68.40198033300004, 28.394087015000025],
            [68.410821630000044, 28.399770725000053],
            [68.43851267000008, 28.41757216700006],
            [68.461379000000079, 28.432272000000069],
            [68.46376308300006, 28.432272110000042],
            [68.475796804000083, 28.432272664000038],
            [68.485565558000076, 28.432273114000054],
            [68.504792000000066, 28.432274000000064],
            [68.50487135700007, 28.432273993000024],
            [68.513067464000073, 28.432273280000061],
            [68.546060040000043, 28.432272000000069],
            [68.551222955000071, 28.432272000000069],
            [68.573947321000048, 28.432272000000069],
            [68.608372132000056, 28.432272000000069],
            [68.608437536000054, 28.432272000000069],
            [68.610767000000067, 28.432272000000069],
            [68.620196915000065, 28.432272000000069],
            [68.628953722000062, 28.432272000000069],
            [68.647841776000064, 28.432272000000069],
            [68.656230773000061, 28.432272000000069],
            [68.670150314000068, 28.432272000000069],
            [68.683316215000048, 28.432272000000069],
            [68.703245000000038, 28.432272000000069],
            [68.707391530000052, 28.432272000000069],
            [68.724310495000054, 28.432272000000069],
            [68.736720113000047, 28.432272000000069],
            [68.777665738000053, 28.432272000000069],
            [68.787138632000051, 28.432272000000069],
            [68.820194962000073, 28.432272000000069],
            [68.827308038000069, 28.432272000000069],
            [68.833877073000053, 28.432272000000069],
            [68.840335749000076, 28.432272000000069],
            [68.845519000000081, 28.432272000000069],
            [68.854419462000067, 28.432560292000062],
            [68.85444510800005, 28.432561123000028],
            [68.864633510000033, 28.432891132000066],
            [68.867140521000067, 28.432972336000034],
            [68.875329615000055, 28.433237586000075],
            [68.90969844500006, 28.434350815000073],
            [68.91601997500004, 28.434553624000046],
            [68.919766325000069, 28.434676921000062],
            [68.919767999000044, 28.43466688500007],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "131",
      properties: { name: "Jamshoro", count: 1183 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.80130100000008, 26.585443000000055],
            [67.807475000000068, 26.572533000000078],
            [67.819475056000044, 26.567053298000076],
            [67.833295000000078, 26.560745000000054],
            [67.84679114100004, 26.549498483000036],
            [67.85013500000008, 26.54671200000007],
            [67.875969508000082, 26.546195586000067],
            [67.878200000000049, 26.546151000000066],
            [67.901993572000038, 26.548313789000076],
            [67.90289800000005, 26.548396000000025],
            [67.909279021000032, 26.547533860000044],
            [67.912319699000079, 26.537009762000025],
            [67.914513560000046, 26.529416578000053],
            [67.914744000000042, 26.528619000000049],
            [67.915018675000056, 26.527393464000056],
            [67.91527132300007, 26.526266203000034],
            [67.919377659000077, 26.507944666000071],
            [67.920014652000077, 26.505102547000035],
            [67.920316000000071, 26.503758000000062],
            [67.920316000000071, 26.501848668000036],
            [67.920316000000071, 26.497347457000046],
            [67.920316000000071, 26.485326000000043],
            [67.910029000000065, 26.469038000000069],
            [67.878929484000082, 26.435450368000033],
            [67.879776435000053, 26.434281435000059],
            [67.882206435000057, 26.432131435000031],
            [67.885958435000077, 26.430717435000076],
            [67.891238435000048, 26.428764435000062],
            [67.89187438700003, 26.428588987000069],
            [67.896512435000034, 26.427309435000041],
            [67.897303049000072, 26.426031244000058],
            [67.898200435000035, 26.424580435000053],
            [67.89796143500007, 26.420589435000068],
            [67.897934435000082, 26.416923435000058],
            [67.898665435000055, 26.414383435000047],
            [67.900807435000047, 26.412066435000042],
            [67.90720443500004, 26.408297435000065],
            [67.913126435000038, 26.404736435000075],
            [67.91809343500006, 26.40265243500005],
            [67.922827435000045, 26.400142435000078],
            [67.924633028000073, 26.398676658000056],
            [67.925675435000073, 26.39783043500006],
            [67.925697435000075, 26.395497435000038],
            [67.925011400000074, 26.394598725000037],
            [67.924068435000038, 26.393363435000026],
            [67.923155435000069, 26.39017543500006],
            [67.922484435000058, 26.386351435000051],
            [67.923076193000043, 26.385292544000038],
            [67.925171395000064, 26.381543395000051],
            [67.926941536000072, 26.377842777000069],
            [67.927092395000045, 26.377527395000072],
            [67.927355395000063, 26.374559395000063],
            [67.925710395000067, 26.374123395000026],
            [67.922402395000063, 26.375371395000059],
            [67.920993395000039, 26.374936395000077],
            [67.920057161000045, 26.374458516000061],
            [67.91888339500008, 26.373859395000068],
            [67.917231395000044, 26.374271395000051],
            [67.914485369000033, 26.374779364000062],
            [67.914183297000079, 26.374835242000074],
            [67.913928395000028, 26.374882395000043],
            [67.908953395000083, 26.378026395000063],
            [67.902329395000038, 26.381158395000057],
            [67.898049042000082, 26.383293852000065],
            [67.892852275000052, 26.370523768000055],
            [67.892513567000037, 26.369231623000076],
            [67.891597000000047, 26.365735000000029],
            [67.883024000000034, 26.351590000000044],
            [67.879234642000029, 26.34443172400006],
            [67.879170988000055, 26.344311479000055],
            [67.875309000000072, 26.337016000000062],
            [67.873304371000074, 26.326240462000044],
            [67.871880000000033, 26.318584000000044],
            [67.87798970800003, 26.29953379400007],
            [67.879166000000055, 26.295866000000046],
            [67.884052359000066, 26.295023633000028],
            [67.904028000000039, 26.291580000000067],
            [67.904885000000036, 26.271005000000059],
            [67.908093629000064, 26.263432843000032],
            [67.915601000000038, 26.245715000000075],
            [67.952893000000074, 26.228998000000047],
            [67.968312362000063, 26.217984415000046],
            [67.98889900000006, 26.203280000000063],
            [68.015475000000038, 26.196421000000043],
            [68.01834954800006, 26.187476140000058],
            [68.019332000000077, 26.184419000000048],
            [68.020811828000035, 26.16459661500005],
            [68.021076677000053, 26.161048950000065],
            [68.021476000000064, 26.155700000000024],
            [68.033614956000065, 26.143082223000079],
            [68.040423538000084, 26.13600507700005],
            [68.053362693000054, 26.12255553600005],
            [68.054053000000067, 26.121838000000025],
            [68.07981462500004, 26.098027393000052],
            [68.080170328000065, 26.097698628000046],
            [68.082444266000039, 26.095596903000057],
            [68.110633000000064, 26.069543000000067],
            [68.11725262300007, 26.062841680000076],
            [68.133868548000066, 26.046020688000056],
            [68.164132504000065, 26.015383227000029],
            [68.18007300000005, 25.999246000000028],
            [68.245227000000057, 26.000960000000077],
            [68.254657000000066, 25.986387000000036],
            [68.255085818000055, 25.986104723000039],
            [68.256654891000039, 25.98507135400007],
            [68.263007332000029, 25.980887727000038],
            [68.272231000000033, 25.97481300000004],
            [68.270068329000082, 25.971388385000068],
            [68.267088000000058, 25.966669000000024],
            [68.246942000000047, 25.945666000000074],
            [68.233654000000058, 25.929806000000042],
            [68.238466859000027, 25.920364694000057],
            [68.243116698000051, 25.911243183000067],
            [68.24479800000006, 25.907945000000041],
            [68.270088000000044, 25.885227000000043],
            [68.290593174000037, 25.873320944000056],
            [68.293351521000034, 25.871719347000067],
            [68.296664000000078, 25.869796000000065],
            [68.320668000000069, 25.854793000000029],
            [68.321251713000038, 25.853076226000042],
            [68.327955000000031, 25.833361000000025],
            [68.331105517000083, 25.810609175000025],
            [68.331813000000068, 25.805500000000052],
            [68.323240000000055, 25.79092600000007],
            [68.309404435000033, 25.788554136000073],
            [68.308237000000076, 25.788354000000027],
            [68.298807000000068, 25.778924000000075],
            [68.297038877000034, 25.771187883000039],
            [68.295378000000028, 25.763921000000039],
            [68.300436324000032, 25.757713154000044],
            [68.314238000000046, 25.740775000000042],
            [68.331384000000071, 25.726629000000059],
            [68.358073952000041, 25.713689217000024],
            [68.359675000000038, 25.712913000000071],
            [68.366058795000072, 25.710018013000024],
            [68.378626601000065, 25.704318640000054],
            [68.396538000000078, 25.696196000000043],
            [68.417971711000064, 25.688299404000077],
            [68.424665805000075, 25.685833170000024],
            [68.429115000000081, 25.684194000000048],
            [68.449981844000035, 25.679646284000057],
            [68.462549000000081, 25.676907000000028],
            [68.456119000000058, 25.66190400000005],
            [68.452298748000032, 25.659993874000065],
            [68.445330711000054, 25.656509855000024],
            [68.443044662000034, 25.655366831000038],
            [68.434687000000054, 25.651188000000047],
            [68.425945399000057, 25.64742241600004],
            [68.425250004000077, 25.647122864000039],
            [68.40873197600007, 25.640007460000049],
            [68.406825000000083, 25.639186000000052],
            [68.387671410000053, 25.633021638000059],
            [68.369533000000047, 25.627184000000057],
            [68.357103000000052, 25.616897000000051],
            [68.357103000000052, 25.606101571000067],
            [68.357103000000052, 25.598465000000033],
            [68.368247000000054, 25.572747000000049],
            [68.36974069200005, 25.570755378000058],
            [68.383678000000032, 25.552172000000041],
            [68.383984472000066, 25.551921167000046],
            [68.388351000000057, 25.539548000000025],
            [68.393066000000033, 25.529261000000076],
            [68.390727940000033, 25.528506691000075],
            [68.387839674000077, 25.527574873000049],
            [68.384123362000082, 25.526375909000024],
            [68.379778000000044, 25.524974000000043],
            [68.373494969000035, 25.522378828000058],
            [68.36006100000003, 25.516830000000027],
            [68.336485000000039, 25.510400000000061],
            [68.319340000000068, 25.507400000000075],
            [68.308195000000069, 25.489397000000054],
            [68.307673048000083, 25.488192444000049],
            [68.30262300000004, 25.476538000000062],
            [68.304337000000032, 25.455106000000058],
            [68.305418736000036, 25.449079459000075],
            [68.305967352000039, 25.44602302100003],
            [68.306257233000053, 25.444408042000077],
            [68.307338000000072, 25.438389000000029],
            [68.305955674000074, 25.430688326000052],
            [68.304337000000032, 25.42167100000006],
            [68.294479000000081, 25.415242000000035],
            [68.293069485000046, 25.398333733000072],
            [68.293064344000072, 25.398272064000025],
            [68.292335000000037, 25.389523000000054],
            [68.293720859000075, 25.387463215000025],
            [68.297886598000048, 25.381271729000048],
            [68.308195000000069, 25.36594800000006],
            [68.318147020000083, 25.345422911000071],
            [68.321912000000054, 25.337658000000033],
            [68.336057000000039, 25.317940000000078],
            [68.344285268000078, 25.302717624000024],
            [68.335372221000057, 25.296351421000054],
            [68.333238972000061, 25.294827738000038],
            [68.32775788400005, 25.290912726000045],
            [68.323695588000078, 25.288011123000047],
            [68.323628326000062, 25.287963080000054],
            [68.322532000000081, 25.287180000000035],
            [68.310907837000059, 25.28740328400005],
            [68.296452775000034, 25.287680945000034],
            [68.288745000000063, 25.287829000000045],
            [68.265785655000059, 25.289180080000051],
            [68.265141867000068, 25.289217964000045],
            [68.263148214000068, 25.28933528400006],
            [68.255608000000052, 25.289779000000067],
            [68.254914102000043, 25.289952461000041],
            [68.235251869000081, 25.294867641000053],
            [68.233198128000083, 25.295381037000027],
            [68.229171708000081, 25.296377441000061],
            [68.218258725000055, 25.298857942000041],
            [68.208073355000067, 25.301173058000074],
            [68.20103000000006, 25.302774000000056],
            [68.200514639000062, 25.302685998000072],
            [68.174390000000074, 25.298225000000059],
            [68.173782650000078, 25.298725182000055],
            [68.152299000000028, 25.316418000000056],
            [68.144121821000056, 25.331003997000039],
            [68.128258000000073, 25.359301000000073],
            [68.124244633000046, 25.366658978000032],
            [68.108162804000074, 25.395542006000028],
            [68.09808520200005, 25.403979144000061],
            [68.097928743000068, 25.404110134000064],
            [68.086202528000058, 25.413927517000047],
            [68.086079450000057, 25.414030561000061],
            [68.080132110000079, 25.418740704000072],
            [68.065704559000039, 25.425233176000063],
            [68.052117656000064, 25.431347352000046],
            [68.035631041000045, 25.438715788000025],
            [68.013904000000082, 25.448316000000034],
            [68.01347776700004, 25.44818685000007],
            [68.003419051000037, 25.445139024000071],
            [67.992462000000046, 25.441819000000066],
            [67.981811194000045, 25.42903803300004],
            [67.980667574000051, 25.427673024000057],
            [67.979467000000056, 25.426225000000045],
            [67.979047115000071, 25.425774000000047],
            [67.961924000000067, 25.407382000000041],
            [67.961246519000042, 25.407494925000037],
            [67.942432000000053, 25.410631000000024],
            [67.941939983000054, 25.409382007000033],
            [67.940355519000036, 25.405359987000054],
            [67.936343320000049, 25.395175383000037],
            [67.933985000000064, 25.389189000000044],
            [67.930737000000079, 25.375545000000045],
            [67.909295000000043, 25.360601000000031],
            [67.908040443000061, 25.348678364000023],
            [67.911894000000075, 25.333961000000045],
            [67.909295000000043, 25.315119000000038],
            [67.90604600000006, 25.301474000000042],
            [67.905596599000035, 25.301353010000071],
            [67.897565440000051, 25.299190830000043],
            [67.889153000000078, 25.296926000000042],
            [67.880706000000032, 25.278083000000038],
            [67.87226000000004, 25.266388000000063],
            [67.858615000000043, 25.265738000000056],
            [67.848869000000036, 25.247545000000059],
            [67.846076323000034, 25.24131561400003],
            [67.840422000000046, 25.228703000000053],
            [67.829887952000036, 25.22004970100005],
            [67.822230000000047, 25.213759000000039],
            [67.828077000000064, 25.189718000000028],
            [67.82158000000004, 25.155282000000057],
            [67.820447599000033, 25.14509143500004],
            [67.819955585000059, 25.140663762000031],
            [67.819233018000034, 25.134161326000026],
            [67.818331000000057, 25.126044000000036],
            [67.804686000000061, 25.09030800000005],
            [67.787957486000039, 25.086371731000042],
            [67.771550000000047, 25.082511000000068],
            [67.745560000000069, 25.069516000000078],
            [67.727367000000072, 25.045475000000067],
            [67.718920000000082, 25.018186000000071],
            [67.716798580000045, 25.014094524000029],
            [67.71678363500007, 25.01406570000006],
            [67.715704215000073, 25.011983875000055],
            [67.709824000000083, 25.000643000000025],
            [67.684484000000054, 25.00649100000004],
            [67.665790600000037, 25.011249092000071],
            [67.648748000000069, 25.015587000000039],
            [67.643329065000046, 25.013875723000069],
            [67.624058000000048, 25.007790000000057],
            [67.601593464000075, 24.984029599000053],
            [67.601572090000047, 24.984006992000047],
            [67.59027100000003, 24.972054000000071],
            [67.558434000000034, 24.968806000000029],
            [67.527896000000055, 24.978552000000036],
            [67.521388936000051, 24.979481361000069],
            [67.521388952000052, 24.979481667000073],
            [67.520086354000057, 24.979667492000033],
            [67.518800000000056, 24.979851000000053],
            [67.517636187000051, 24.989658596000027],
            [67.517635771000073, 24.989662103000057],
            [67.516795153000032, 24.996746100000053],
            [67.514251744000035, 25.018179728000064],
            [67.514251000000058, 25.018186000000071],
            [67.514150398000083, 25.018428189000076],
            [67.506581328000038, 25.036649960000034],
            [67.501601179000033, 25.048639167000033],
            [67.496708000000069, 25.060419000000024],
            [67.485022423000032, 25.070189020000043],
            [67.476849744000049, 25.077021993000074],
            [67.457074000000034, 25.093556000000035],
            [67.42783600000007, 25.109150000000056],
            [67.418539840000051, 25.113865366000027],
            [67.40158157500008, 25.122467242000027],
            [67.401554151000028, 25.122481152000034],
            [67.401343953000037, 25.122587772000031],
            [67.401316357000042, 25.122601770000074],
            [67.383003000000031, 25.131891000000053],
            [67.365544674000034, 25.13887440700006],
            [67.337521000000038, 25.150084000000049],
            [67.330715669000028, 25.152447049000045],
            [67.300912326000059, 25.162795813000059],
            [67.290740000000028, 25.166328000000078],
            [67.271248000000071, 25.181921000000045],
            [67.272141853000051, 25.189370843000063],
            [67.273197000000039, 25.198165000000074],
            [67.282293000000038, 25.226104000000078],
            [67.29887855000004, 25.248643069000025],
            [67.307633000000067, 25.260540000000049],
            [67.328425000000038, 25.27613400000007],
            [67.329075000000046, 25.296276000000034],
            [67.345968000000028, 25.307322000000056],
            [67.360262000000034, 25.305373000000031],
            [67.360294475000046, 25.305472919000067],
            [67.360356163000063, 25.305662719000054],
            [67.36938735800004, 25.333449653000059],
            [67.37715600000007, 25.357352000000049],
            [67.393399000000045, 25.407382000000041],
            [67.40038626300003, 25.424850156000048],
            [67.403795000000059, 25.433372000000077],
            [67.407223779000049, 25.439276793000033],
            [67.415482491000034, 25.453499346000058],
            [67.415491000000031, 25.453514000000041],
            [67.415482491000034, 25.453535272000067],
            [67.415482455000074, 25.453535362000025],
            [67.409859922000066, 25.46759133300003],
            [67.399897000000067, 25.492498000000069],
            [67.397298000000035, 25.528234000000054],
            [67.394512191000047, 25.547386303000053],
            [67.394480658000077, 25.547603091000042],
            [67.393382919000032, 25.555149996000068],
            [67.392100000000028, 25.56397000000004],
            [67.397298000000035, 25.589960000000076],
            [67.394875213000034, 25.597973525000043],
            [67.388851000000045, 25.617899000000023],
            [67.381066356000076, 25.642549874000053],
            [67.381054000000063, 25.642589000000044],
            [67.323934189000056, 25.642588980000028],
            [67.323934092000059, 25.642588980000028],
            [67.32973000000004, 25.668866000000037],
            [67.336043000000075, 25.697669000000076],
            [67.339080111000044, 25.70559849700004],
            [67.343146000000047, 25.716214000000036],
            [67.349853000000053, 25.733180000000061],
            [67.360901000000069, 25.745412000000044],
            [67.374316000000078, 25.752908000000048],
            [67.37323407100007, 25.75810367400004],
            [67.373177207000083, 25.758376747000057],
            [67.372344000000055, 25.762378000000069],
            [67.373660849000032, 25.763585173000024],
            [67.386548000000062, 25.77539900000005],
            [67.394607160000078, 25.780771361000063],
            [67.399569000000042, 25.784079000000077],
            [67.405394983000065, 25.795507764000035],
            [67.409827000000064, 25.804202000000032],
            [67.416535000000067, 25.825114000000042],
            [67.41574600000007, 25.842081000000064],
            [67.405882000000076, 25.85983600000003],
            [67.399174000000073, 25.88272100000006],
            [67.401792121000028, 25.892319894000025],
            [67.405093000000079, 25.904422000000068],
            [67.406671000000074, 25.934015000000045],
            [67.406276000000048, 25.965975000000071],
            [67.406577482000046, 25.967723432000071],
            [67.412195000000054, 26.000302000000033],
            [67.416535000000067, 26.023976000000062],
            [67.422059000000047, 26.047256000000061],
            [67.430345000000045, 26.076848000000041],
            [67.420086000000083, 26.093025000000068],
            [67.416959893000069, 26.096498904000043],
            [67.418236713000056, 26.095080065000047],
            [67.419875299000068, 26.097619074000079],
            [67.421993855000039, 26.100901802000067],
            [67.422371786000042, 26.101487347000045],
            [67.422957137000083, 26.10239425900005],
            [67.424331982000069, 26.103480736000051],
            [67.425824377000083, 26.104660107000029],
            [67.42914118300007, 26.107281226000055],
            [67.429278839000062, 26.107390009000028],
            [67.429485442000043, 26.107553278000069],
            [67.431397563000075, 26.109064282000077],
            [67.432889808000084, 26.110243490000073],
            [67.434251159000041, 26.111319338000044],
            [67.434995438000044, 26.111907556000062],
            [67.435273240000072, 26.112127108000038],
            [67.436610194000082, 26.112981703000059],
            [67.437046505000069, 26.113260693000029],
            [67.437777683000036, 26.113728025000057],
            [67.438642988000083, 26.114281085000073],
            [67.441583503000061, 26.116163452000023],
            [67.441933476000031, 26.116387487000054],
            [67.443350255000041, 26.117294322000078],
            [67.443685157000061, 26.117508746000055],
            [67.444413997000083, 26.117975390000026],
            [67.445504862000064, 26.11872590400003],
            [67.450964727000041, 26.122482283000068],
            [67.452056946000027, 26.123233728000059],
            [67.452690622000034, 26.123772451000036],
            [67.45307004600005, 26.124095020000027],
            [67.458134390000055, 26.128400502000034],
            [67.459146752000038, 26.129261166000049],
            [67.460160224000049, 26.130122775000075],
            [67.461688504000051, 26.131770663000054],
            [67.464134352000031, 26.134407933000034],
            [67.46581120500008, 26.136216128000058],
            [67.467208189000075, 26.137722339000049],
            [67.467968719000055, 26.138835379000056],
            [67.468747242000063, 26.139974752000057],
            [67.470778382000049, 26.142947340000035],
            [67.471262097000078, 26.14365526000006],
            [67.472022268000046, 26.144767774000059],
            [67.472670439000069, 26.14593820500005],
            [67.473318232000054, 26.147107952000056],
            [67.474754311000083, 26.149701140000047],
            [67.475910261000081, 26.151788488000022],
            [67.476125755000055, 26.152177616000074],
            [67.478870579000045, 26.157249912000054],
            [67.481078873000058, 26.161330419000024],
            [67.481881975000078, 26.162814820000051],
            [67.482442247000051, 26.164626673000043],
            [67.483614621000072, 26.168417992000059],
            [67.484108582000033, 26.170015748000026],
            [67.484602693000056, 26.171613300000047],
            [67.484877099000073, 26.17332884700005],
            [67.485082843000043, 26.174615133000032],
            [67.485091324000052, 26.174668138000072],
            [67.485555126000065, 26.177566880000029],
            [67.485757465000063, 26.178832772000078],
            [67.485857883000051, 26.180168633000051],
            [67.485991755000043, 26.18194950700007],
            [67.486662009000042, 26.190865815000052],
            [67.487212106000072, 26.198183687000039],
            [67.487319264000064, 26.199609198000076],
            [67.487441767000064, 26.201238837000062],
            [67.487610539000059, 26.202117368000074],
            [67.487738602000036, 26.202783994000072],
            [67.487961195000082, 26.203942686000062],
            [67.488800661000028, 26.208312157000023],
            [67.489136377000079, 26.210060012000042],
            [67.489460013000041, 26.211307437000073],
            [67.49103133400007, 26.217363937000073],
            [67.49147199600003, 26.219062428000029],
            [67.491509247000067, 26.219206007000025],
            [67.492293694000068, 26.220802159000073],
            [67.493135401000075, 26.222514819000025],
            [67.494844209000064, 26.225991810000039],
            [67.495403435000071, 26.227177221000034],
            [67.497081754000078, 26.230734809000069],
            [67.497640968000042, 26.231920194000054],
            [67.498200681000071, 26.233106637000049],
            [67.498804727000049, 26.234783069000059],
            [67.499861962000068, 26.237717253000028],
            [67.501161406000051, 26.241323990000069],
            [67.501715130000036, 26.242860417000031],
            [67.501903651000077, 26.24374197800006],
            [67.502848044000075, 26.248125504000029],
            [67.502942792000056, 26.248565291000034],
            [67.50322763500003, 26.24989725100005],
            [67.50308203600008, 26.251153359000057],
            [67.502840463000041, 26.253204570000037],
            [67.50274113100005, 26.254045143000042],
            [67.50234714100003, 26.255277249000073],
            [67.501821133000078, 26.256915093000032],
            [67.501559846000077, 26.257728669000073],
            [67.50064936800004, 26.260563647000026],
            [67.500258267000049, 26.261781427000074],
            [67.499367783000082, 26.263807877000033],
            [67.497942462000083, 26.26705144500005],
            [67.497478451000063, 26.268107382000039],
            [67.497051731000056, 26.269078457000035],
            [67.496695307000039, 26.269889563000049],
            [67.495688433000055, 26.271867900000075],
            [67.493877056000031, 26.275426951000043],
            [67.49307238800003, 26.277007988000037],
            [67.492745100000036, 26.278525075000061],
            [67.492097093000041, 26.281528801000036],
            [67.491918932000033, 26.282354636000036],
            [67.491913117000081, 26.282427784000049],
            [67.491817626000056, 26.283629031000032],
            [67.491778293000039, 26.284123835000059],
            [67.491391130000068, 26.288994253000055],
            [67.491289287000029, 26.290275426000051],
            [67.491285498000082, 26.290323087000047],
            [67.491348230000028, 26.292084009000064],
            [67.491395268000076, 26.293404395000039],
            [67.491771676000042, 26.303970387000049],
            [67.491850098000043, 26.306171729000027],
            [67.491967267000064, 26.307494567000049],
            [67.492123489000051, 26.309258316000069],
            [67.492280290000053, 26.311028596000028],
            [67.492727498000079, 26.316077577000044],
            [67.492943750000052, 26.318519062000064],
            [67.49321718200008, 26.321606108000026],
            [67.493350522000071, 26.322482105000063],
            [67.493550474000074, 26.323795716000063],
            [67.494818680000037, 26.332127378000052],
            [67.495024668000042, 26.333442835000028],
            [67.495217913000033, 26.333770357000049],
            [67.495610385000077, 26.334435541000062],
            [67.49576913900006, 26.334704233000025],
            [67.495987288000038, 26.335073452000074],
            [67.496357027000045, 26.335699238000075],
            [67.496793319000062, 26.336437664000073],
            [67.497582445000035, 26.337075999000035],
            [67.49812797800007, 26.337517289000061],
            [67.499358568000048, 26.338512730000048],
            [67.50079201300008, 26.338667017000034],
            [67.504679645000067, 26.339085457000067],
            [67.506390239000041, 26.33926957400007],
            [67.507712625000067, 26.339411908000045],
            [67.509435774000053, 26.339334930000064],
            [67.51072742000008, 26.33927723000005],
            [67.513742382000032, 26.339143504000049],
            [67.514212198000052, 26.339122161000034],
            [67.515113951000046, 26.339081141000065],
            [67.51601966100003, 26.339111683000056],
            [67.516835918000083, 26.339145850000023],
            [67.518882575000077, 26.339231520000055],
            [67.520524692000038, 26.339874384000041],
            [67.522350865000078, 26.340589304000048],
            [67.523398684000028, 26.340999510000074],
            [67.52514457500007, 26.341368107000051],
            [67.525580384000079, 26.341460116000064],
            [67.531068718000029, 26.342618825000045],
            [67.532332757000063, 26.342885691000049],
            [67.533464888000083, 26.342898978000051],
            [67.535747298000047, 26.342925901000058],
            [67.536572389000071, 26.342935633000025],
            [67.537849720000054, 26.342950680000058],
            [67.538503582000033, 26.342745114000024],
            [67.539345206000064, 26.342480519000048],
            [67.547769944000038, 26.339831895000032],
            [67.549035437000043, 26.339434041000061],
            [67.551069055000028, 26.338480878000041],
            [67.55269687100008, 26.337717916000031],
            [67.558395970000049, 26.33504673300007],
            [67.559412099000042, 26.334570470000074],
            [67.559617206000041, 26.334474336000028],
            [67.560837341000081, 26.333902455000043],
            [67.561881166000035, 26.333532128000058],
            [67.566565952000076, 26.331870064000043],
            [67.567315647000044, 26.331604088000063],
            [67.567386628000065, 26.331594808000034],
            [67.567872506000072, 26.33153128500004],
            [67.569840936000048, 26.331273934000023],
            [67.571328230000063, 26.331079488000057],
            [67.571594714000071, 26.331267428000046],
            [67.572639993000053, 26.332004621000067],
            [67.573442963000048, 26.332607379000024],
            [67.576266279000038, 26.335502011000074],
            [67.577162667000039, 26.336419989000035],
            [67.577755808000063, 26.337887294000041],
            [67.578309912000066, 26.339258031000043],
            [67.580178974000034, 26.343881697000029],
            [67.580508473000066, 26.34471669900006],
            [67.584304958000075, 26.354337577000024],
            [67.585122527000067, 26.356409561000078],
            [67.585210953000058, 26.356633661000046],
            [67.585814725000034, 26.358163560000037],
            [67.586517546000039, 26.35946563300007],
            [67.589120549000029, 26.364288060000035],
            [67.590351284000064, 26.366411153000058],
            [67.591171890000055, 26.367826751000052],
            [67.592178445000059, 26.368522127000062],
            [67.593288546000053, 26.369289038000034],
            [67.593867601000056, 26.369686235000074],
            [67.594712212000047, 26.370063868000045],
            [67.595678044000067, 26.370475009000074],
            [67.59643821800006, 26.370798605000061],
            [67.597823385000083, 26.371456014000046],
            [67.59952561800003, 26.372217509000052],
            [67.600329952000038, 26.373262791000059],
            [67.601912251000044, 26.375318529000026],
            [67.60272473100008, 26.376388451000025],
            [67.603510631000063, 26.378600896000023],
            [67.603976637000073, 26.379914466000059],
            [67.604020192000064, 26.380037237000067],
            [67.605301580000059, 26.382688159000054],
            [67.60624559300004, 26.383770491000064],
            [67.607545875000028, 26.385261491000051],
            [67.608068820000028, 26.385860862000072],
            [67.60885053700008, 26.386636911000039],
            [67.612180003000049, 26.389942217000055],
            [67.613151724000033, 26.390906913000038],
            [67.613761540000041, 26.391544927000041],
            [67.614066065000031, 26.391863533000048],
            [67.616624908000063, 26.394540773000074],
            [67.616780059000064, 26.394703102000051],
            [67.617078911000078, 26.395015695000041],
            [67.619373623000058, 26.39755309800006],
            [67.620230572000082, 26.398500679000051],
            [67.621018507000031, 26.399889921000067],
            [67.621873672000049, 26.401397870000039],
            [67.622241355000028, 26.402735439000026],
            [67.623560317000056, 26.407533605000026],
            [67.623758090000081, 26.408253070000058],
            [67.623846727000057, 26.408670407000045],
            [67.624651247000031, 26.412458371000071],
            [67.625000009000075, 26.414100466000036],
            [67.624970325000049, 26.415885541000023],
            [67.624881254000059, 26.421241113000065],
            [67.624843464000037, 26.423472860000061],
            [67.624887517000047, 26.423934784000039],
            [67.625009092000028, 26.425209553000059],
            [67.625174703000027, 26.426946073000067],
            [67.625596409000082, 26.431369179000058],
            [67.625748867000084, 26.432966842000042],
            [67.625807423000083, 26.433580468000059],
            [67.626183850000075, 26.435002281000038],
            [67.626702666000028, 26.436961432000032],
            [67.627050259000043, 26.438273762000051],
            [67.627212255000074, 26.438885536000043],
            [67.627321929000061, 26.439299768000069],
            [67.627709973000037, 26.440765775000045],
            [67.628464456000074, 26.442337062000036],
            [67.628656235000051, 26.442736461000038],
            [67.629313805000038, 26.444105920000027],
            [67.63030092200006, 26.446161914000072],
            [67.630659576000028, 26.446908625000049],
            [67.630858543000045, 26.44729385200003],
            [67.631453815000043, 26.44844637500006],
            [67.632935273000044, 26.451314669000055],
            [67.634032526000055, 26.453439091000064],
            [67.634826085000043, 26.454975522000041],
            [67.635994930000038, 26.456289749000064],
            [67.638332467000055, 26.458918031000053],
            [67.638869025000076, 26.459521282000026],
            [67.64074792100007, 26.461633922000033],
            [67.642868391000036, 26.463851178000027],
            [67.64377746100007, 26.464801741000031],
            [67.644677169000033, 26.465742516000034],
            [67.645206177000034, 26.466765179000049],
            [67.64554858200006, 26.467427106000059],
            [67.645748579000042, 26.467813826000054],
            [67.646346473000051, 26.468969568000034],
            [67.646434471000077, 26.470968278000043],
            [67.646071812000059, 26.474499484000034],
            [67.646008059000053, 26.475103297000032],
            [67.645682344000079, 26.476070530000072],
            [67.645461540000042, 26.476642794000043],
            [67.643812270000069, 26.480917247000036],
            [67.643496743000071, 26.481734988000028],
            [67.643127106000065, 26.48269306800006],
            [67.64224204900006, 26.484702915000071],
            [67.64025865800005, 26.489206937000063],
            [67.638986660000057, 26.492095256000027],
            [67.636561340000071, 26.497602418000042],
            [67.636033180000084, 26.498801710000066],
            [67.635680895000064, 26.499601641000027],
            [67.634899081000071, 26.501528548000067],
            [67.634586340000055, 26.502299348000065],
            [67.630896749000044, 26.511393025000075],
            [67.630226826000069, 26.513044074000049],
            [67.630149168000059, 26.513904783000044],
            [67.630110193000064, 26.514336753000066],
            [67.629721423000035, 26.518621192000069],
            [67.629565309000043, 26.520349803000045],
            [67.629956723000078, 26.521723977000079],
            [67.630251261000069, 26.522758041000031],
            [67.630962255000043, 26.525241785000048],
            [67.631050539000057, 26.526654654000026],
            [67.631185603000063, 26.528879669000048],
            [67.631230298000048, 26.529625493000026],
            [67.631324016000065, 26.531189319000077],
            [67.630719485000043, 26.53286649100005],
            [67.630568229000062, 26.533286125000075],
            [67.630123113000082, 26.534521090000055],
            [67.629745682000078, 26.535568264000062],
            [67.628654280000035, 26.537652539000078],
            [67.62794479300004, 26.539007187000038],
            [67.627433763000056, 26.539982913000074],
            [67.627717115000053, 26.54136154400004],
            [67.627832430000069, 26.541923021000059],
            [67.628543398000033, 26.54538476700003],
            [67.629380205000075, 26.547830189000024],
            [67.629453123000076, 26.548076541000057],
            [67.63040171800003, 26.551281336000045],
            [67.631110682000042, 26.553676797000037],
            [67.631404210000028, 26.554668574000061],
            [67.631697306000035, 26.555658436000044],
            [67.632312309000042, 26.556628936000038],
            [67.632882811000059, 26.55752921100003],
            [67.633324340000058, 26.558225961000062],
            [67.633910720000074, 26.558777164000048],
            [67.634344514000077, 26.559184934000029],
            [67.635667330000047, 26.560428392000063],
            [67.637102399000071, 26.560863995000034],
            [67.63932254100007, 26.561539824000079],
            [67.642703935000043, 26.563092051000069],
            [67.643008510000072, 26.563233319000062],
            [67.645801221000056, 26.56452863800007],
            [67.647156605000077, 26.565157294000073],
            [67.647611844000039, 26.564811463000069],
            [67.649109903000067, 26.563672698000062],
            [67.649640364000049, 26.563380577000032],
            [67.650243438000075, 26.563048468000034],
            [67.65163190700008, 26.562288900000055],
            [67.652344762000041, 26.561898929000051],
            [67.65276510800004, 26.561718992000067],
            [67.656312023000055, 26.560199175000037],
            [67.657497130000081, 26.559691441000041],
            [67.658767554000065, 26.559562282000059],
            [67.659814604000076, 26.559455860000071],
            [67.661314350000055, 26.559308910000027],
            [67.662699677000035, 26.559609065000075],
            [67.664077617000032, 26.559907778000024],
            [67.669185607000031, 26.560650911000039],
            [67.671398321000083, 26.560649252000076],
            [67.673143251000056, 26.560643843000037],
            [67.674584823000032, 26.561392484000066],
            [67.676739029000032, 26.56251121400004],
            [67.677375760000075, 26.562841922000075],
            [67.677863043000059, 26.563094941000031],
            [67.678229875000056, 26.563346580000029],
            [67.680444209000029, 26.564865566000037],
            [67.681887434000032, 26.565855587000044],
            [67.682975896000073, 26.566602249000027],
            [67.683715932000041, 26.567569139000057],
            [67.684208923000028, 26.568213276000051],
            [67.686406046000059, 26.571084169000073],
            [67.687028063000071, 26.571896328000037],
            [67.687652765000053, 26.572711993000041],
            [67.688095430000033, 26.573289974000033],
            [67.688898142000028, 26.574335834000067],
            [67.689765088000058, 26.574667445000046],
            [67.691861126000049, 26.57546583900006],
            [67.693018747000053, 26.575906939000049],
            [67.694512244000066, 26.575453801000037],
            [67.697145295000041, 26.57465516700006],
            [67.698309373000029, 26.57430260600006],
            [67.699935771000071, 26.57348238000003],
            [67.700910365000084, 26.572990872000048],
            [67.70182944700008, 26.572527360000038],
            [67.703191428000082, 26.572446158000048],
            [67.707077495000078, 26.572214467000038],
            [67.708106074000057, 26.572153142000047],
            [67.70907389000007, 26.572521720000054],
            [67.710855113000036, 26.573200321000058],
            [67.711915009000052, 26.575085379000029],
            [67.713623908000045, 26.578124712000033],
            [67.715204548000031, 26.57917986800004],
            [67.716772752000054, 26.580228121000061],
            [67.718065307000074, 26.580328405000046],
            [67.720082946000048, 26.580485671000076],
            [67.721091121000029, 26.58056433400003],
            [67.722616130000063, 26.580192661000069],
            [67.724160481000069, 26.579816362000031],
            [67.725690096000051, 26.579443611000045],
            [67.726967563000073, 26.579132306000076],
            [67.728565109000044, 26.578716037000049],
            [67.729530892000071, 26.578464332000067],
            [67.730614882000054, 26.578413828000066],
            [67.731748905000074, 26.578409620000059],
            [67.732301796000058, 26.578407568000046],
            [67.734025343000042, 26.578401301000042],
            [67.73490643100007, 26.57924994800004],
            [67.736391053000034, 26.580683291000071],
            [67.738830139000072, 26.581083910000075],
            [67.739532432000033, 26.581199532000028],
            [67.742707899000038, 26.578034678000051],
            [67.743437794000045, 26.577307223000048],
            [67.744398004000061, 26.576971102000073],
            [67.745866185000068, 26.576457166000068],
            [67.747568744000034, 26.575861186000054],
            [67.748527954000053, 26.575525415000072],
            [67.749572327000067, 26.575518797000029],
            [67.751243767000062, 26.575507999000024],
            [67.755160535000073, 26.575482694000073],
            [67.75877296200008, 26.577753443000063],
            [67.759009535000075, 26.579148847000056],
            [67.759343473000058, 26.581121334000045],
            [67.756483653000032, 26.58325725900005],
            [67.756023826000046, 26.583599865000053],
            [67.75561741000007, 26.583968163000065],
            [67.754843559000051, 26.584669435000023],
            [67.753677943000071, 26.585725728000057],
            [67.753291705000038, 26.586781878000068],
            [67.752553138000053, 26.58880146100006],
            [67.752129247000028, 26.589960572000052],
            [67.752427099000045, 26.591097980000029],
            [67.752637854000056, 26.591902790000063],
            [67.753060942000047, 26.593518435000078],
            [67.753327904000059, 26.594537883000044],
            [67.754071126000042, 26.595331762000058],
            [67.755165895000061, 26.596501146000037],
            [67.756347543000061, 26.597763331000067],
            [67.757272415000045, 26.59875124000007],
            [67.758939372000043, 26.599118435000037],
            [67.763558471000067, 26.60013592200005],
            [67.76585377300006, 26.600641409000048],
            [67.770415540000045, 26.60164603100003],
            [67.772129922000033, 26.602023583000062],
            [67.772987630000046, 26.602212473000066],
            [67.775117014000045, 26.602557306000051],
            [67.779716007000047, 26.603302069000051],
            [67.779802238000059, 26.603316033000056],
            [67.780644521000056, 26.603452449000031],
            [67.783592181000074, 26.603929778000065],
            [67.78463634600007, 26.604014592000055],
            [67.785787172000028, 26.604108074000067],
            [67.787762349000047, 26.604268522000041],
            [67.787941542000056, 26.604283078000037],
            [67.791334558000074, 26.604558525000073],
            [67.791958477000037, 26.604589461000046],
            [67.795056215000045, 26.604333610000026],
            [67.796305293000046, 26.604230019000056],
            [67.797699629000078, 26.603202896000028],
            [67.799431947000073, 26.601925132000076],
            [67.799481005000075, 26.601511731000073],
            [67.79964758400007, 26.600108015000046],
            [67.799888914000064, 26.598064176000037],
            [67.801503880000041, 26.597722260000069],
            [67.80301124600004, 26.59741490600004],
            [67.80130100000008, 26.585443000000055],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "132",
      properties: { name: "Kashmore", count: 1290 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.750892236000084, 28.405010827000069],
            [69.74967508900005, 28.404224847000023],
            [69.740853534000053, 28.39852759200005],
            [69.728361364000079, 28.39317380600005],
            [69.719953972000042, 28.392816044000028],
            [69.711586164000039, 28.392459967000036],
            [69.703733942000042, 28.389604614000064],
            [69.694810964000055, 28.38353698800006],
            [69.688743338000052, 28.374614009000027],
            [69.681961874000081, 28.366761788000076],
            [69.67837563900008, 28.360785275000069],
            [69.676805798000032, 28.358168865000039],
            [69.673395815000049, 28.352485022000053],
            [69.665543593000052, 28.348201993000032],
            [69.657334453000033, 28.347488154000075],
            [69.650773295000079, 28.345613537000077],
            [69.650279873000045, 28.345472544000074],
            [69.64821011500004, 28.346605759000056],
            [69.647006000000033, 28.34726500000005],
            [69.637250000000051, 28.353940000000023],
            [69.628089272000068, 28.354957764000062],
            [69.607293648000052, 28.357268174000069],
            [69.604901000000041, 28.357534000000044],
            [69.604437456000028, 28.35749185700007],
            [69.582429291000039, 28.355491027000028],
            [69.582308000000069, 28.355480000000057],
            [69.580254000000082, 28.365236000000039],
            [69.569471000000078, 28.383208000000025],
            [69.556634000000031, 28.399126000000024],
            [69.544824000000062, 28.399126000000024],
            [69.532575448000046, 28.389817215000051],
            [69.531987000000072, 28.389370000000042],
            [69.535389945000077, 28.383074717000056],
            [69.535927969000056, 28.382079399000077],
            [69.540627326000049, 28.373385817000042],
            [69.540658415000053, 28.37332830500003],
            [69.542257000000063, 28.370371000000034],
            [69.554899379000062, 28.353651101000025],
            [69.558175000000062, 28.349319000000037],
            [69.562314743000059, 28.340064926000025],
            [69.566904000000079, 28.329806000000076],
            [69.572727604000079, 28.319384876000072],
            [69.576660000000061, 28.312348000000043],
            [69.59257800000006, 28.288215000000037],
            [69.592627388000039, 28.287194016000058],
            [69.593734415000029, 28.264308751000044],
            [69.593735170000059, 28.264293130000055],
            [69.594118000000037, 28.256379000000038],
            [69.57974100000007, 28.252271000000064],
            [69.573137588000066, 28.253143286000068],
            [69.552526000000057, 28.255866000000026],
            [69.540501862000042, 28.264707440000052],
            [69.535068000000081, 28.268703000000073],
            [69.530193008000083, 28.269711653000059],
            [69.525770941000076, 28.270626594000078],
            [69.520177000000047, 28.271784000000025],
            [69.520177000000047, 28.262541000000056],
            [69.519499026000062, 28.259896454000057],
            [69.517784497000036, 28.253208654000048],
            [69.515043000000048, 28.242515000000026],
            [69.503746000000035, 28.25073100000003],
            [69.486126821000028, 28.261622811000052],
            [69.480731504000062, 28.264958084000057],
            [69.475505000000055, 28.268189000000064],
            [69.475874315000056, 28.241943150000054],
            [69.475931224000078, 28.237898894000068],
            [69.476018000000067, 28.231732000000079],
            [69.465749000000073, 28.217355000000055],
            [69.475800425000045, 28.206866112000057],
            [69.477559000000042, 28.205031000000076],
            [69.474811632000069, 28.202283632000047],
            [69.471012256000051, 28.198484256000029],
            [69.464722000000052, 28.192194000000029],
            [69.454619910000076, 28.177357477000044],
            [69.452474524000081, 28.174206637000054],
            [69.452110965000031, 28.173672693000071],
            [69.448290000000043, 28.168061000000023],
            [69.442260770000075, 28.161859324000034],
            [69.438564769000038, 28.158057611000061],
            [69.430319000000054, 28.149576000000025],
            [69.425838181000074, 28.151527315000067],
            [69.419354881000061, 28.154350675000046],
            [69.413930091000054, 28.156713072000059],
            [69.406346669000072, 28.160015515000055],
            [69.398483000000056, 28.163440000000037],
            [69.397254114000077, 28.163565380000023],
            [69.387766810000073, 28.164533341000038],
            [69.379128402000049, 28.16541469200007],
            [69.373323000000028, 28.166007000000036],
            [69.368400368000039, 28.161650207000037],
            [69.363199766000037, 28.157047395000063],
            [69.353721230000076, 28.148658382000065],
            [69.351509817000078, 28.146701164000035],
            [69.351461033000078, 28.146657987000026],
            [69.35100592100008, 28.146255189000044],
            [69.350968416000057, 28.146221995000076],
            [69.34143664700008, 28.137785868000037],
            [69.328650000000039, 28.126469000000043],
            [69.318381000000045, 28.128010000000074],
            [69.312942430000078, 28.123974976000056],
            [69.306339234000063, 28.11907588400004],
            [69.303501908000044, 28.116970794000054],
            [69.302463000000046, 28.116200000000049],
            [69.289984000000061, 28.117408000000069],
            [69.285119618000067, 28.113759580000078],
            [69.271822000000043, 28.103786000000071],
            [69.266327756000067, 28.10323711500007],
            [69.261186063000082, 28.102723370000035],
            [69.258552522000059, 28.102459994000071],
            [69.245484859000044, 28.101152723000041],
            [69.241971719000048, 28.100801273000059],
            [69.226415000000031, 28.099245000000053],
            [69.222088257000053, 28.094524775000025],
            [69.215925587000072, 28.087801660000025],
            [69.209767000000056, 28.081083000000035],
            [69.208648250000067, 28.078845624000053],
            [69.204032114000029, 28.069613859000071],
            [69.200685000000078, 28.062920000000076],
            [69.200259057000039, 28.06015129900004],
            [69.200041700000043, 28.05873844100006],
            [69.199830644000031, 28.057366548000061],
            [69.199824688000035, 28.057327830000077],
            [69.198269328000038, 28.047217733000025],
            [69.198226176000048, 28.046937238000055],
            [69.197658000000047, 28.043244000000072],
            [69.198331495000048, 28.026411972000062],
            [69.19872472600008, 28.016584303000059],
            [69.199172000000033, 28.00540600000005],
            [69.19199085300005, 28.000020238000047],
            [69.18260228500003, 27.992978942000036],
            [69.181009000000074, 27.991784000000052],
            [69.177928985000051, 27.991612953000072],
            [69.15889394800007, 27.990555795000034],
            [69.153766000000076, 27.990271000000064],
            [69.137973783000064, 28.001203795000038],
            [69.124331549000033, 28.010648179000043],
            [69.114414000000068, 28.017514000000062],
            [69.093128123000042, 27.994898250000062],
            [69.090197000000046, 27.991784000000052],
            [69.070521000000042, 28.003892000000064],
            [69.06248744700008, 28.020534973000053],
            [69.059149025000067, 28.027451124000038],
            [69.058684352000057, 28.028413687000068],
            [69.054998599000044, 28.03604795900003],
            [69.05009956300006, 28.046195171000079],
            [69.049332000000049, 28.047785000000033],
            [69.037091933000056, 28.061089023000079],
            [69.032054642000048, 28.066564176000043],
            [69.032037879000029, 28.066582396000058],
            [69.014520000000061, 28.085623000000055],
            [69.010628213000075, 28.086704123000061],
            [68.999792109000055, 28.089714351000055],
            [68.987277000000063, 28.093191000000047],
            [68.968853734000049, 28.092270171000052],
            [68.957006000000035, 28.091678000000059],
            [68.941531549000047, 28.094993432000024],
            [68.935816000000045, 28.096218000000079],
            [68.933597750000047, 28.097327500000063],
            [68.905546000000072, 28.111354000000063],
            [68.905546000000072, 28.117677872000058],
            [68.905546000000072, 28.125614478000045],
            [68.905546000000072, 28.128002000000038],
            [68.905811516000028, 28.129170384000076],
            [68.906224900000041, 28.130989445000068],
            [68.906739627000036, 28.13325446500005],
            [68.908269456000028, 28.139986359000034],
            [68.909151977000079, 28.143869825000024],
            [68.909550496000065, 28.145623474000047],
            [68.909557105000033, 28.145652556000073],
            [68.913113000000067, 28.16130000000004],
            [68.901005000000055, 28.18249000000003],
            [68.908573000000047, 28.193084000000056],
            [68.908375230000047, 28.196445177000044],
            [68.908269388000065, 28.198243999000056],
            [68.907059000000061, 28.218815000000063],
            [68.903455521000069, 28.226021958000047],
            [68.903301515000066, 28.226329971000041],
            [68.89797800000008, 28.236977000000024],
            [68.903516290000084, 28.246576736000065],
            [68.905887990000053, 28.250687696000057],
            [68.91500467000003, 28.266489995000029],
            [68.920681000000059, 28.276329000000032],
            [68.920828037000035, 28.278166982000073],
            [68.921185284000046, 28.282632629000034],
            [68.921438361000071, 28.285796134000066],
            [68.921597415000065, 28.287784335000026],
            [68.922652194000079, 28.300969247000069],
            [68.923102345000075, 28.306596216000059],
            [68.923330376000081, 28.309446634000039],
            [68.923708000000033, 28.314167000000054],
            [68.921982462000074, 28.334011545000067],
            [68.921492236000063, 28.339649378000047],
            [68.920681000000059, 28.348979000000043],
            [68.921172845000058, 28.35471672400007],
            [68.922633007000059, 28.371750557000041],
            [68.92407036000003, 28.38851829500004],
            [68.924262223000028, 28.390756518000046],
            [68.925222000000076, 28.401953000000049],
            [68.922664371000053, 28.417294025000047],
            [68.921852029000036, 28.422166570000059],
            [68.919767999000044, 28.43466688500007],
            [68.919766325000069, 28.434676921000062],
            [68.926845658000047, 28.434904282000048],
            [68.965308890000074, 28.436139572000059],
            [68.99253600000003, 28.437014000000033],
            [68.994645799000068, 28.437014000000033],
            [69.02685447600004, 28.437014000000033],
            [69.064428722000059, 28.437014000000033],
            [69.070786000000055, 28.437014000000033],
            [69.102797555000052, 28.438474601000053],
            [69.124496676000035, 28.439464675000067],
            [69.131034204000059, 28.439762964000067],
            [69.131035000000054, 28.439763000000028],
            [69.131036292000033, 28.439756871000043],
            [69.173581950000028, 28.441687212000033],
            [69.175121000000047, 28.441757000000052],
            [69.196245158000067, 28.444774783000071],
            [69.206107633000045, 28.446183728000051],
            [69.206175530000053, 28.446193427000026],
            [69.241515000000049, 28.451242000000036],
            [69.261884000000066, 28.451246256000047],
            [69.300115393000056, 28.451245730000039],
            [69.30011554400005, 28.451245730000039],
            [69.302894312000035, 28.451245691000054],
            [69.323826269000051, 28.45124540300003],
            [69.360841553000057, 28.451244893000023],
            [69.360841806000053, 28.451244893000023],
            [69.361700000000042, 28.449684000000047],
            [69.37022470800008, 28.449151097000026],
            [69.391118000000063, 28.447845000000029],
            [69.405555498000069, 28.449984406000056],
            [69.407665000000065, 28.450297000000035],
            [69.418697000000066, 28.453361000000029],
            [69.419790496000076, 28.456398445000048],
            [69.419957575000069, 28.45686254900005],
            [69.424213000000066, 28.468683000000055],
            [69.437696000000074, 28.476650000000063],
            [69.455778713000029, 28.48147212300006],
            [69.456082000000038, 28.481553000000076],
            [69.457378850000055, 28.481945954000025],
            [69.476306000000079, 28.487681000000066],
            [69.511240000000043, 28.485230000000058],
            [69.511353701000075, 28.485211807000042],
            [69.519379828000069, 28.483927595000068],
            [69.521230841000033, 28.483631427000034],
            [69.541883000000041, 28.480327000000045],
            [69.560076542000047, 28.478656106000074],
            [69.563196830000038, 28.478369539000028],
            [69.60194400000006, 28.474811000000045],
            [69.616653000000042, 28.469908000000032],
            [69.597041000000047, 28.447845000000029],
            [69.610112915000059, 28.440193185000055],
            [69.622169000000042, 28.433136000000047],
            [69.650725715000078, 28.433134151000047],
            [69.655263000000048, 28.433136000000047],
            [69.668816868000079, 28.431487528000048],
            [69.694171686000061, 28.428403782000032],
            [69.700616000000082, 28.427620000000047],
            [69.702837153000075, 28.427564548000078],
            [69.712280526000029, 28.427344462000065],
            [69.725310000000036, 28.427040800000043],
            [69.731058910000058, 28.422035255000026],
            [69.744129000000044, 28.411073000000044],
            [69.750484644000039, 28.405374984000048],
            [69.750766000000056, 28.405124000000058],
            [69.75076604000003, 28.40512396400004],
            [69.750892236000084, 28.405010827000069],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "133",
      properties: { name: "Khairpur", count: 337 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.673392146000083, 27.714642443000059],
            [68.677361000000076, 27.708148000000051],
            [68.681639215000075, 27.69867358700003],
            [68.682357000000081, 27.697084000000075],
            [68.685510799000042, 27.693254816000035],
            [68.685577863000049, 27.693173389000037],
            [68.687822924000045, 27.690447549000055],
            [68.687843737000037, 27.690422279000074],
            [68.692350000000033, 27.684951000000069],
            [68.718759000000034, 27.676742000000047],
            [68.719836994000048, 27.676742000000047],
            [68.719866395000054, 27.676742000000047],
            [68.723543473000063, 27.676742000000047],
            [68.743383000000051, 27.676742000000047],
            [68.757581240000036, 27.69310179200005],
            [68.759799000000044, 27.69565700000004],
            [68.768007000000068, 27.699939000000029],
            [68.776150224000048, 27.698129430000051],
            [68.786938383000063, 27.695732108000072],
            [68.787122532000069, 27.695691187000023],
            [68.787175848000061, 27.695679339000037],
            [68.793702500000052, 27.694229000000064],
            [68.806626683000047, 27.694026993000023],
            [68.816543000000081, 27.693872000000056],
            [68.841395003000059, 27.695197952000058],
            [68.843308000000036, 27.695300000000032],
            [68.842470687000059, 27.691112794000048],
            [68.84111402800005, 27.684328464000032],
            [68.839689050000061, 27.67720248300003],
            [68.839383000000055, 27.675672000000077],
            [68.842912039000055, 27.661050788000068],
            [68.84437900000006, 27.654973000000041],
            [68.848785683000074, 27.64809873300004],
            [68.84882168200005, 27.648042575000034],
            [68.850770155000077, 27.64500302700003],
            [68.850834974000065, 27.644901912000023],
            [68.853301000000044, 27.641055000000051],
            [68.848369580000053, 27.637274391000062],
            [68.848195553000039, 27.637140975000079],
            [68.831888000000049, 27.624639000000059],
            [68.824959096000043, 27.610439982000059],
            [68.822133803000042, 27.604650267000068],
            [68.821199175000061, 27.602734987000076],
            [68.818255783000041, 27.596703256000069],
            [68.810119000000043, 27.580029000000025],
            [68.809667499000057, 27.578316525000048],
            [68.806809472000054, 27.567476458000044],
            [68.799769000000083, 27.540773000000058],
            [68.797813315000042, 27.535492349000037],
            [68.793483739000067, 27.523801826000067],
            [68.792632000000083, 27.521502000000055],
            [68.795344253000053, 27.516890752000052],
            [68.799769000000083, 27.509368000000052],
            [68.820111000000054, 27.503301000000079],
            [68.827674178000052, 27.501788486000066],
            [68.832602000000065, 27.500803000000076],
            [68.831174000000033, 27.480461000000048],
            [68.845093000000077, 27.477606000000037],
            [68.863166850000084, 27.480618208000067],
            [68.875070000000051, 27.482602000000043],
            [68.881648340000083, 27.481993015000057],
            [68.881675166000036, 27.481990531000065],
            [68.881857990000071, 27.481973606000054],
            [68.894341000000054, 27.480818000000056],
            [68.866148000000067, 27.442989000000068],
            [68.89083183300005, 27.414132857000027],
            [68.891486000000043, 27.413368000000048],
            [68.883330855000054, 27.402327368000044],
            [68.868290000000059, 27.381963000000042],
            [68.837241000000063, 27.35448400000007],
            [68.832602000000065, 27.329859000000056],
            [68.83217343900003, 27.324215644000049],
            [68.830461000000071, 27.301666000000068],
            [68.839375756000038, 27.293358733000048],
            [68.846163000000047, 27.287034000000062],
            [68.868765713000073, 27.271023415000059],
            [68.887967192000076, 27.257996422000076],
            [68.909359174000031, 27.243606136000039],
            [68.912123128000076, 27.241957781000053],
            [68.931369562000043, 27.233037980000063],
            [68.940378000000067, 27.228863000000047],
            [68.96356538100008, 27.219647855000062],
            [68.963708770000039, 27.219590869000058],
            [68.968215000000043, 27.217800000000068],
            [68.978346182000053, 27.214208045000078],
            [68.984265836000077, 27.212109264000048],
            [68.997747024000034, 27.207329583000046],
            [68.997777738000082, 27.207318693000047],
            [69.007471000000066, 27.203882000000078],
            [69.029451350000045, 27.202558178000061],
            [69.035970684000063, 27.202165534000073],
            [69.043610950000073, 27.202225263000059],
            [69.043615469000031, 27.20217477500006],
            [69.056318310000051, 27.202324274000034],
            [69.056318301000033, 27.202324356000076],
            [69.056402213000069, 27.202325261000055],
            [69.067426000000069, 27.202455000000043],
            [69.07246983400006, 27.204309131000059],
            [69.075834117000056, 27.205545853000046],
            [69.075869304000037, 27.205558788000076],
            [69.077248088000033, 27.206065633000037],
            [69.07727968100005, 27.206077247000053],
            [69.091694000000075, 27.21137600000003],
            [69.107206105000046, 27.209922136000046],
            [69.115572023000084, 27.209138045000032],
            [69.125954000000036, 27.208165000000065],
            [69.133616474000064, 27.204781454000056],
            [69.133832471000062, 27.204686075000041],
            [69.149741860000063, 27.197660910000025],
            [69.182340000000067, 27.18354000000005],
            [69.209820000000036, 27.187109000000078],
            [69.239440000000059, 27.184611000000075],
            [69.257791971000074, 27.177270114000066],
            [69.276912000000038, 27.169622000000061],
            [69.302748012000052, 27.163284736000037],
            [69.314741000000083, 27.160343000000069],
            [69.332020103000048, 27.153769857000043],
            [69.347574000000066, 27.147853000000055],
            [69.366488000000061, 27.135005000000035],
            [69.388598065000053, 27.123508237000067],
            [69.402176000000054, 27.116448000000048],
            [69.427514000000031, 27.111094000000037],
            [69.459633000000053, 27.100745000000074],
            [69.477013000000056, 27.090606498000056],
            [69.485328000000038, 27.08575600000006],
            [69.498532000000068, 27.07255200000003],
            [69.515081685000041, 27.07123727100003],
            [69.537261069000067, 27.069270927000048],
            [69.540315633000034, 27.069016957000031],
            [69.539879145000043, 27.067977877000033],
            [69.537192000000061, 27.061581000000047],
            [69.525919000000044, 27.032621000000063],
            [69.514589000000058, 27.01000700000003],
            [69.515685000000076, 27.003269000000046],
            [69.513815000000079, 26.991554000000065],
            [69.502416000000039, 26.896425000000079],
            [69.499284000000046, 26.865335000000073],
            [69.485943000000077, 26.805370000000039],
            [69.49187500000005, 26.788293000000067],
            [69.50287000000003, 26.764167000000043],
            [69.507307000000083, 26.756294000000025],
            [69.507322000000045, 26.754233000000056],
            [69.511087000000032, 26.744512000000043],
            [69.518136000000084, 26.742424000000028],
            [69.551494000000048, 26.727930000000072],
            [69.560160000000053, 26.724155000000053],
            [69.573772000000076, 26.718279000000052],
            [69.593351000000041, 26.709776000000033],
            [69.617557000000033, 26.699265000000025],
            [69.666513000000066, 26.678449000000057],
            [69.675917000000084, 26.674292000000037],
            [69.707862000000034, 26.661569000000043],
            [69.717077000000074, 26.657133000000044],
            [69.723051000000055, 26.654729000000032],
            [69.728670000000079, 26.651085000000023],
            [69.735227000000066, 26.645985000000053],
            [69.749957000000052, 26.633500000000026],
            [69.763095000000078, 26.623257000000024],
            [69.780062000000044, 26.608881000000054],
            [69.791292000000055, 26.599989000000051],
            [69.807455000000061, 26.594868000000076],
            [69.825893000000065, 26.589822000000026],
            [69.856637000000035, 26.585135000000037],
            [69.887870000000078, 26.581026000000065],
            [69.895739000000049, 26.580231000000026],
            [69.934138000000075, 26.582818000000032],
            [69.938951000000031, 26.583502000000067],
            [69.951194000000044, 26.585266000000047],
            [69.97392700000006, 26.588401000000033],
            [69.986843000000079, 26.59044700000004],
            [70.015868000000069, 26.594648000000063],
            [70.055990000000065, 26.601958000000025],
            [70.084150000000079, 26.595188000000064],
            [70.117857000000072, 26.588313000000028],
            [70.129657000000066, 26.581354000000033],
            [70.13973500000003, 26.572335000000066],
            [70.149175000000071, 26.568282000000067],
            [70.156413000000043, 26.565032000000031],
            [70.175113000000067, 26.552564000000075],
            [70.176680000000033, 26.548613000000046],
            [70.176184000000035, 26.546098000000029],
            [70.177152000000035, 26.541989000000058],
            [70.177806000000032, 26.539660000000026],
            [70.178144000000032, 26.531525000000045],
            [70.18038800000005, 26.520957000000067],
            [70.180809000000068, 26.509563000000071],
            [70.182930000000056, 26.50216400000005],
            [70.184695000000033, 26.49530500000003],
            [70.185669000000075, 26.489702000000023],
            [70.186146000000065, 26.479125000000067],
            [70.18502600000005, 26.472480000000076],
            [70.184345000000064, 26.466404000000068],
            [70.181827000000055, 26.454896000000076],
            [70.179634000000078, 26.435142000000042],
            [70.178554000000076, 26.423436000000038],
            [70.180700000000058, 26.410591000000068],
            [70.184931000000063, 26.382386000000054],
            [70.186960000000056, 26.37453400000004],
            [70.185919000000069, 26.372089000000074],
            [70.18636500000008, 26.369573000000059],
            [70.18497300000007, 26.362463000000048],
            [70.180899000000068, 26.343416000000047],
            [70.177256000000057, 26.331819000000053],
            [70.176969000000042, 26.329762000000073],
            [70.172031000000061, 26.316914000000054],
            [70.166300000000035, 26.298049000000049],
            [70.164860000000033, 26.294011000000069],
            [70.173495000000059, 26.262636000000043],
            [70.176801000000069, 26.250663000000031],
            [70.169362000000035, 26.217548000000079],
            [70.161310000000071, 26.200256000000024],
            [70.153252000000066, 26.179182000000026],
            [70.142872000000068, 26.156025000000056],
            [70.138915000000054, 26.150365000000079],
            [70.136730000000057, 26.14951300000007],
            [70.131714527000042, 26.142515408000065],
            [70.129965229000049, 26.140074786000071],
            [70.12894526000008, 26.138651725000045],
            [70.120834000000059, 26.142694000000063],
            [70.096005000000048, 26.152372000000071],
            [70.071098000000063, 26.153617000000054],
            [70.050610696000035, 26.162929683000073],
            [70.043700000000058, 26.166071000000045],
            [70.020189015000028, 26.171948304000068],
            [70.003849000000059, 26.176033000000075],
            [69.97732699200003, 26.180221216000064],
            [69.956526000000054, 26.183506000000023],
            [69.942071359000067, 26.188324214000033],
            [69.926229302000081, 26.193604899000036],
            [69.922902000000079, 26.194714000000033],
            [69.915987009000048, 26.194425721000073],
            [69.893014000000051, 26.193468000000053],
            [69.882626874000039, 26.195941258000062],
            [69.85667982800004, 26.202119457000038],
            [69.840710000000058, 26.205922000000044],
            [69.808774881000033, 26.207695698000066],
            [69.799161836000053, 26.208229613000071],
            [69.795878000000073, 26.208412000000067],
            [69.774911924000037, 26.206315561000054],
            [69.746064000000047, 26.203431000000023],
            [69.728643456000043, 26.205366486000059],
            [69.706052801000055, 26.20787639100007],
            [69.701232000000061, 26.208412000000067],
            [69.696924157000069, 26.209085131000052],
            [69.670079889000078, 26.21327973700005],
            [69.661381000000063, 26.214639000000034],
            [69.65387529700007, 26.215123295000069],
            [69.637601862000054, 26.216173317000028],
            [69.622775000000047, 26.217130000000054],
            [69.596623000000079, 26.21214800000007],
            [69.576698000000079, 26.205922000000044],
            [69.54431900000003, 26.204676000000063],
            [69.525690148000081, 26.208029175000036],
            [69.513623819000031, 26.210201103000031],
            [69.49563362400005, 26.213439321000067],
            [69.482052000000067, 26.215884000000074],
            [69.44758033800008, 26.215884000000074],
            [69.404878616000076, 26.215884000000074],
            [69.391142000000059, 26.215884000000074],
            [69.373029557000052, 26.218411310000079],
            [69.347333706000029, 26.221996766000075],
            [69.29932576200008, 26.22869552800006],
            [69.284043000000054, 26.230828000000031],
            [69.279039476000037, 26.231710961000033],
            [69.241701000000035, 26.238300000000038],
            [69.241505718000042, 26.238293028000044],
            [69.241506000000072, 26.238294000000053],
            [69.206831000000079, 26.237055000000055],
            [69.205015181000078, 26.236772550000069],
            [69.189447284000039, 26.234350970000037],
            [69.172514124000031, 26.231717024000034],
            [69.154268423000076, 26.228878912000027],
            [69.136023041000044, 26.227552203000073],
            [69.132284235000043, 26.227280337000025],
            [69.122194396000054, 26.226703702000066],
            [69.112165393000055, 26.226130544000057],
            [69.107204000000081, 26.225847000000044],
            [69.093433000000061, 26.230134000000078],
            [69.081084000000033, 26.240799000000038],
            [69.078278000000068, 26.254832000000079],
            [69.074349000000041, 26.261006000000066],
            [69.06909326400006, 26.257011671000043],
            [69.060316000000057, 26.250341000000049],
            [69.055825000000084, 26.261006000000066],
            [69.055825000000084, 26.274478000000045],
            [69.062000000000069, 26.285704000000067],
            [69.057509000000039, 26.296369000000027],
            [69.038425000000075, 26.298614000000043],
            [69.017656000000045, 26.29973700000005],
            [69.015731563000031, 26.304307859000062],
            [69.008676000000037, 26.32106600000003],
            [68.997449000000074, 26.342396000000065],
            [68.985662000000048, 26.360358000000076],
            [68.969689895000045, 26.370286253000074],
            [68.964893000000075, 26.373268000000053],
            [68.961187112000061, 26.376768107000032],
            [68.95479000000006, 26.382810000000063],
            [68.951983000000041, 26.400772000000075],
            [68.949738000000082, 26.408630000000073],
            [68.949177000000077, 26.424347000000068],
            [68.935144000000037, 26.441186000000073],
            [68.926163000000031, 26.462516000000051],
            [68.916060000000073, 26.46925200000004],
            [68.903250280000066, 26.462334587000043],
            [68.887994000000049, 26.45409600000005],
            [68.868803309000043, 26.444241920000024],
            [68.867226000000073, 26.44343200000003],
            [68.864794769000071, 26.441896438000072],
            [68.856561000000056, 26.43669600000004],
            [68.856183111000064, 26.437276829000041],
            [68.856183183000041, 26.437277119000044],
            [68.849264000000062, 26.447922000000062],
            [68.853193000000033, 26.470936000000052],
            [68.84702109400007, 26.484102245000031],
            [68.847000065000032, 26.484147105000034],
            [68.836379511000075, 26.506085177000045],
            [68.83592657500003, 26.507027965000077],
            [68.832320827000046, 26.51463641600003],
            [68.820637000000033, 26.542783000000043],
            [68.808850000000064, 26.562429000000066],
            [68.786643593000065, 26.571636158000047],
            [68.776855000000069, 26.580952000000025],
            [68.752999802000033, 26.585723503000054],
            [68.689291000000082, 26.597230000000025],
            [68.656892776000063, 26.597230000000025],
            [68.629231000000061, 26.597230000000025],
            [68.599886359000038, 26.600716671000043],
            [68.599694956000064, 26.600739413000042],
            [68.576393997000082, 26.603507973000035],
            [68.572539000000063, 26.603966000000071],
            [68.568422801000054, 26.604957832000025],
            [68.560635197000067, 26.60683431800004],
            [68.546800218000044, 26.610167970000077],
            [68.52595000000008, 26.615192000000036],
            [68.479362000000037, 26.615753000000041],
            [68.457565512000031, 26.618547383000077],
            [68.435580000000073, 26.621366000000023],
            [68.407654295000043, 26.625574347000054],
            [68.394604000000072, 26.627541000000065],
            [68.38589201700006, 26.628211282000052],
            [68.381677471000046, 26.628535541000076],
            [68.381324515000074, 26.628562696000074],
            [68.373844127000041, 26.629138222000051],
            [68.371558337000067, 26.629314086000079],
            [68.365349222000077, 26.629791803000046],
            [68.357186973000069, 26.630419650000078],
            [68.344086000000061, 26.639328000000035],
            [68.33216132900003, 26.64258032400005],
            [68.325097299000049, 26.644506960000058],
            [68.320635591000041, 26.645723842000052],
            [68.313214000000073, 26.647748000000036],
            [68.308975967000038, 26.649910315000056],
            [68.299892369000077, 26.654544918000056],
            [68.293203030000029, 26.657957931000055],
            [68.285710000000051, 26.661781000000076],
            [68.285973272000035, 26.662611944000048],
            [68.28600225200006, 26.662703411000052],
            [68.289639274000081, 26.674182648000055],
            [68.289960622000081, 26.675196892000031],
            [68.292790586000081, 26.684128877000035],
            [68.292816360000074, 26.684210227000051],
            [68.294639189000065, 26.689963472000045],
            [68.297595210000054, 26.699293321000027],
            [68.300681147000034, 26.709033214000044],
            [68.302573535000079, 26.715006005000077],
            [68.307504364000067, 26.729587061000075],
            [68.310790867000037, 26.739118094000048],
            [68.310832116000029, 26.739237718000027],
            [68.314898000000028, 26.751029000000074],
            [68.319386416000043, 26.768083915000034],
            [68.321186674000046, 26.774924467000062],
            [68.321456559000069, 26.775949966000042],
            [68.322457610000072, 26.779753722000066],
            [68.323318000000029, 26.783023000000071],
            [68.326692381000043, 26.788506932000075],
            [68.327808000000061, 26.790320000000065],
            [68.324362293000036, 26.798308643000041],
            [68.322589331000074, 26.80241871100003],
            [68.315459000000033, 26.818947000000037],
            [68.317827316000034, 26.83789282400005],
            [68.318638243000066, 26.844380002000037],
            [68.318827000000056, 26.845890000000054],
            [68.321124742000052, 26.855846426000028],
            [68.322511919000078, 26.86185725100006],
            [68.323879000000034, 26.867781000000036],
            [68.327945247000059, 26.875287703000026],
            [68.331176000000028, 26.881252000000075],
            [68.331964648000053, 26.889401203000034],
            [68.332554492000042, 26.895496146000028],
            [68.332860000000039, 26.898653000000024],
            [68.337154717000033, 26.90241069800004],
            [68.350822000000051, 26.914369000000079],
            [68.353785267000035, 26.919244255000024],
            [68.365624128000036, 26.938721903000044],
            [68.367012587000033, 26.941006238000057],
            [68.36822200000006, 26.942996000000051],
            [68.372855556000047, 26.955572795000023],
            [68.374354846000074, 26.959641777000058],
            [68.374792504000084, 26.960829438000076],
            [68.375405193000063, 26.962492078000025],
            [68.376077604000045, 26.964327237000077],
            [68.376320441000075, 26.965004368000052],
            [68.37816545000004, 26.970231530000035],
            [68.382817000000045, 26.983410000000049],
            [68.390195635000055, 26.985377792000065],
            [68.390223558000059, 26.985385238000049],
            [68.391424876000031, 26.985705615000029],
            [68.402694754000038, 26.988711154000043],
            [68.408075000000053, 26.990146000000038],
            [68.412847045000035, 26.99455094700005],
            [68.413942367000061, 26.995562011000061],
            [68.421658746000048, 27.00268479500005],
            [68.429966000000036, 27.010353000000066],
            [68.435826845000065, 27.018860504000031],
            [68.440193793000049, 27.025199493000059],
            [68.44206902600007, 27.027921549000041],
            [68.447367000000042, 27.035612000000071],
            [68.448113174000071, 27.047919559000036],
            [68.448490000000049, 27.054135000000031],
            [68.448080426000047, 27.055909740000061],
            [68.448073433000047, 27.055940040000053],
            [68.446483802000046, 27.062828126000056],
            [68.443438000000071, 27.07602600000007],
            [68.442960285000083, 27.076596917000074],
            [68.442903022000053, 27.076665352000077],
            [68.43831104800006, 27.082153213000026],
            [68.435034105000057, 27.086069483000074],
            [68.432477512000048, 27.089124864000041],
            [68.430045069000073, 27.092031874000043],
            [68.421851312000058, 27.101824222000062],
            [68.420424000000082, 27.103530000000035],
            [68.420149405000075, 27.104396811000072],
            [68.417089209000039, 27.114048450000041],
            [68.415716055000075, 27.118379017000052],
            [68.413127000000031, 27.126544000000024],
            [68.403138407000029, 27.132759239000052],
            [68.400456061000057, 27.13442828500007],
            [68.398700074000033, 27.135520919000044],
            [68.397483768000029, 27.136277745000029],
            [68.387868000000083, 27.142261000000076],
            [68.383219641000039, 27.145430361000024],
            [68.379585938000048, 27.147907906000057],
            [68.375271202000079, 27.150849796000045],
            [68.366262676000076, 27.156992022000054],
            [68.363171000000079, 27.159100000000024],
            [68.360283657000082, 27.161120969000024],
            [68.358673364000083, 27.162248078000061],
            [68.356317048000051, 27.163897359000032],
            [68.351733956000032, 27.167105251000066],
            [68.346331000000077, 27.17088700000005],
            [68.34955675100008, 27.176346065000075],
            [68.350724477000028, 27.178322253000033],
            [68.351764408000065, 27.180082169000059],
            [68.352627427000073, 27.181542690000072],
            [68.352932131000046, 27.182058354000048],
            [68.353628000000072, 27.183236000000079],
            [68.347504231000073, 27.190126263000025],
            [68.344648000000063, 27.193340000000035],
            [68.341333822000081, 27.198642291000056],
            [68.336228000000062, 27.206811000000073],
            [68.324608882000064, 27.209809762000077],
            [68.318827000000056, 27.211302000000046],
            [68.308162000000038, 27.210179000000039],
            [68.305583169000045, 27.211468416000059],
            [68.295814000000064, 27.216353000000026],
            [68.285710000000051, 27.217476000000033],
            [68.277649848000067, 27.211834037000074],
            [68.274484000000029, 27.209618000000034],
            [68.26629467500004, 27.212099600000045],
            [68.261496856000065, 27.213553477000062],
            [68.261364631000049, 27.213593545000037],
            [68.25596100000007, 27.215231000000074],
            [68.255119519000061, 27.215435688000071],
            [68.246674564000045, 27.217489899000043],
            [68.238997374000064, 27.219357354000067],
            [68.23519200000004, 27.220283000000052],
            [68.229321983000034, 27.222519209000041],
            [68.229240429000072, 27.222550277000039],
            [68.224787233000029, 27.224246742000048],
            [68.220696491000069, 27.225805128000047],
            [68.213398519000066, 27.228585323000061],
            [68.211617000000047, 27.229264000000057],
            [68.210503843000083, 27.229620191000038],
            [68.204481684000029, 27.231547179000074],
            [68.204336524000041, 27.231593628000041],
            [68.197585000000061, 27.233754000000033],
            [68.186920000000043, 27.233754000000033],
            [68.177378000000033, 27.235438000000045],
            [68.177866390000077, 27.240484314000071],
            [68.179062000000044, 27.252838000000054],
            [68.193649581000045, 27.272290622000071],
            [68.194398209000042, 27.273168001000045],
            [68.21386300000006, 27.296059000000071],
            [68.215547000000072, 27.315144000000032],
            [68.21855688900007, 27.327560967000068],
            [68.220037000000048, 27.333667000000048],
            [68.228726298000083, 27.340056150000066],
            [68.231198254000049, 27.341873753000073],
            [68.231354464000049, 27.341988613000069],
            [68.239122000000066, 27.347700000000032],
            [68.25596100000007, 27.354997000000026],
            [68.264646538000079, 27.357991958000071],
            [68.27223900000007, 27.360610000000065],
            [68.277784821000068, 27.359798358000035],
            [68.286341933000074, 27.358546007000029],
            [68.295252000000062, 27.357242000000042],
            [68.32500200000004, 27.357242000000042],
            [68.331780969000079, 27.361761313000045],
            [68.341841000000045, 27.368468000000064],
            [68.345700964000059, 27.373155162000046],
            [68.348639983000055, 27.376724014000047],
            [68.349699000000044, 27.378010000000074],
            [68.349162103000083, 27.378591655000037],
            [68.341849876000083, 27.38651346000006],
            [68.336228000000062, 27.392604000000063],
            [68.323659244000055, 27.402413681000041],
            [68.313214000000073, 27.410566000000074],
            [68.29918200000003, 27.426844000000074],
            [68.298813452000047, 27.42773625500007],
            [68.28851700000007, 27.452664000000027],
            [68.276168000000041, 27.475678000000073],
            [68.274626711000053, 27.481842852000057],
            [68.271484799000064, 27.494409876000077],
            [68.271116000000063, 27.495885000000044],
            [68.286241725000082, 27.502112820000036],
            [68.28745475900007, 27.502612271000032],
            [68.287508946000059, 27.502634582000042],
            [68.288371547000054, 27.502989746000026],
            [68.290201000000081, 27.503743000000043],
            [68.299795731000074, 27.504143285000055],
            [68.303672000000063, 27.504305000000045],
            [68.31096741500005, 27.509515834000069],
            [68.315459000000033, 27.512724000000048],
            [68.329754772000058, 27.522254257000043],
            [68.333983000000046, 27.525073000000077],
            [68.339213133000044, 27.533571894000033],
            [68.34082141600004, 27.536185330000023],
            [68.342964000000052, 27.539667000000065],
            [68.343525000000056, 27.560436000000038],
            [68.338679643000035, 27.574091526000075],
            [68.337351000000069, 27.577836000000048],
            [68.331176000000028, 27.588501000000065],
            [68.332756935000077, 27.594033743000068],
            [68.335667000000058, 27.60421800000006],
            [68.336605466000037, 27.605156466000039],
            [68.336897605000047, 27.605448605000049],
            [68.337598023000055, 27.606149023000057],
            [68.342964000000052, 27.611515000000054],
            [68.344035096000084, 27.611300482000047],
            [68.347747764000076, 27.610557743000072],
            [68.362609000000077, 27.607585000000029],
            [68.381133000000034, 27.606463000000076],
            [68.391054739000083, 27.606976230000043],
            [68.395290432000081, 27.60719533300005],
            [68.402641912000036, 27.607575610000026],
            [68.407937022000056, 27.607849514000065],
            [68.413688000000036, 27.608147000000031],
            [68.43221200000005, 27.608708000000036],
            [68.448490000000049, 27.616566000000034],
            [68.456047051000041, 27.618984841000042],
            [68.462522000000035, 27.621057000000064],
            [68.474406925000039, 27.621440164000035],
            [68.47443712900008, 27.621441138000023],
            [68.479923000000042, 27.621618000000069],
            [68.492272000000071, 27.626670000000047],
            [68.496762000000047, 27.641264000000035],
            [68.492925390000039, 27.646514023000066],
            [68.492876065000075, 27.646581519000051],
            [68.492298086000062, 27.647372426000061],
            [68.486097000000029, 27.655858000000023],
            [68.475432000000069, 27.671013000000073],
            [68.468542822000074, 27.678668147000053],
            [68.460277000000076, 27.687853000000075],
            [68.459911012000077, 27.688661963000072],
            [68.470748000000071, 27.692274000000054],
            [68.491850000000056, 27.70909000000006],
            [68.496211294000034, 27.714203415000043],
            [68.501412000000073, 27.720301000000063],
            [68.510013316000084, 27.721582094000041],
            [68.510444563000078, 27.721646325000052],
            [68.516908000000058, 27.722609000000034],
            [68.523967332000041, 27.720096104000049],
            [68.527423154000076, 27.718865942000036],
            [68.536362000000054, 27.715684000000067],
            [68.556805000000054, 27.711728000000051],
            [68.571642000000054, 27.714695000000063],
            [68.600022203000037, 27.728742590000024],
            [68.600479030000031, 27.728286380000043],
            [68.600990000000081, 27.727776000000063],
            [68.601850808000052, 27.727624030000072],
            [68.609699614000078, 27.726238376000026],
            [68.611834240000064, 27.725904349000075],
            [68.615302409000037, 27.725361648000046],
            [68.628671139000062, 27.723691012000074],
            [68.634290066000062, 27.722988838000049],
            [68.635862063000047, 27.722792391000041],
            [68.636336233000065, 27.722733136000045],
            [68.637232431000029, 27.722621142000037],
            [68.638818000000072, 27.722423000000049],
            [68.654495199000053, 27.721693590000029],
            [68.669510000000059, 27.720995000000073],
            [68.673117747000049, 27.715091455000049],
            [68.673392146000083, 27.714642443000059],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "134",
      properties: { name: "Larkana", count: 435 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.197080000000028, 27.931651000000045],
            [68.203675000000032, 27.92274800000007],
            [68.204993495000053, 27.923736943000051],
            [68.205378794000069, 27.924025938000057],
            [68.206286852000062, 27.924707031000025],
            [68.209380619000058, 27.927027523000049],
            [68.213290999000037, 27.929960520000066],
            [68.222139000000084, 27.936597000000063],
            [68.22828362000007, 27.933963388000052],
            [68.241628012000035, 27.928243903000066],
            [68.247165265000035, 27.911096722000025],
            [68.247183360000065, 27.911040687000025],
            [68.251814000000081, 27.896701000000064],
            [68.263354000000049, 27.876588000000027],
            [68.271060099000067, 27.874462013000027],
            [68.282478000000083, 27.871312000000046],
            [68.290061000000037, 27.862080000000049],
            [68.289732000000072, 27.852848000000051],
            [68.289072000000033, 27.843286000000035],
            [68.297975000000065, 27.836692000000028],
            [68.300646398000083, 27.834063597000068],
            [68.302215421000028, 27.832519825000077],
            [68.31683698900008, 27.818133581000041],
            [68.316857791000075, 27.818113114000028],
            [68.318417000000068, 27.816579000000047],
            [68.335892000000058, 27.793169000000034],
            [68.33727633400008, 27.78763166400006],
            [68.337362391000056, 27.787287436000042],
            [68.340838000000076, 27.773385000000076],
            [68.341933264000033, 27.765111109000031],
            [68.342144376000078, 27.763516318000029],
            [68.343806000000029, 27.750964000000067],
            [68.35052819100008, 27.748022988000059],
            [68.359632000000033, 27.744040000000041],
            [68.381723000000079, 27.738435000000038],
            [68.382450009000081, 27.73835930000007],
            [68.382535974000064, 27.73835034800004],
            [68.386125589000073, 27.73797657700004],
            [68.397550000000081, 27.736787000000049],
            [68.397262171000079, 27.733506375000047],
            [68.396951781000041, 27.729968611000061],
            [68.396456416000035, 27.724322534000066],
            [68.395901000000038, 27.717992000000038],
            [68.398297111000034, 27.717945121000071],
            [68.401371201000075, 27.71788497700004],
            [68.40213966500005, 27.717869943000039],
            [68.402147213000035, 27.71786979500007],
            [68.40481589400008, 27.717817583000055],
            [68.40644327900003, 27.717785743000036],
            [68.407821290000072, 27.717758783000079],
            [68.412717000000043, 27.717663000000073],
            [68.438435000000084, 27.697220000000073],
            [68.45788900000008, 27.687988000000075],
            [68.459911012000077, 27.688661963000072],
            [68.460277000000076, 27.687853000000075],
            [68.468542822000074, 27.678668147000053],
            [68.475432000000069, 27.671013000000073],
            [68.486097000000029, 27.655858000000023],
            [68.492298086000062, 27.647372426000061],
            [68.492876065000075, 27.646581519000051],
            [68.492925390000039, 27.646514023000066],
            [68.496762000000047, 27.641264000000035],
            [68.492272000000071, 27.626670000000047],
            [68.479923000000042, 27.621618000000069],
            [68.47443712900008, 27.621441138000023],
            [68.474406925000039, 27.621440164000035],
            [68.462522000000035, 27.621057000000064],
            [68.456047051000041, 27.618984841000042],
            [68.448490000000049, 27.616566000000034],
            [68.43221200000005, 27.608708000000036],
            [68.413688000000036, 27.608147000000031],
            [68.407937022000056, 27.607849514000065],
            [68.402641912000036, 27.607575610000026],
            [68.395290432000081, 27.60719533300005],
            [68.391054739000083, 27.606976230000043],
            [68.381133000000034, 27.606463000000076],
            [68.362609000000077, 27.607585000000029],
            [68.347747764000076, 27.610557743000072],
            [68.344035096000084, 27.611300482000047],
            [68.342964000000052, 27.611515000000054],
            [68.337598023000055, 27.606149023000057],
            [68.336897605000047, 27.605448605000049],
            [68.336605466000037, 27.605156466000039],
            [68.335667000000058, 27.60421800000006],
            [68.332756935000077, 27.594033743000068],
            [68.331176000000028, 27.588501000000065],
            [68.337351000000069, 27.577836000000048],
            [68.338679643000035, 27.574091526000075],
            [68.343525000000056, 27.560436000000038],
            [68.342964000000052, 27.539667000000065],
            [68.34082141600004, 27.536185330000023],
            [68.339213133000044, 27.533571894000033],
            [68.333983000000046, 27.525073000000077],
            [68.329754772000058, 27.522254257000043],
            [68.315459000000033, 27.512724000000048],
            [68.31096741500005, 27.509515834000069],
            [68.303672000000063, 27.504305000000045],
            [68.299795731000074, 27.504143285000055],
            [68.290201000000081, 27.503743000000043],
            [68.288371547000054, 27.502989746000026],
            [68.287508946000059, 27.502634582000042],
            [68.28745475900007, 27.502612271000032],
            [68.286241725000082, 27.502112820000036],
            [68.271116000000063, 27.495885000000044],
            [68.271484799000064, 27.494409876000077],
            [68.274626711000053, 27.481842852000057],
            [68.276168000000041, 27.475678000000073],
            [68.28851700000007, 27.452664000000027],
            [68.298813452000047, 27.42773625500007],
            [68.29918200000003, 27.426844000000074],
            [68.313214000000073, 27.410566000000074],
            [68.323659244000055, 27.402413681000041],
            [68.336228000000062, 27.392604000000063],
            [68.341849876000083, 27.38651346000006],
            [68.349162103000083, 27.378591655000037],
            [68.349699000000044, 27.378010000000074],
            [68.348639983000055, 27.376724014000047],
            [68.345700964000059, 27.373155162000046],
            [68.341841000000045, 27.368468000000064],
            [68.331780969000079, 27.361761313000045],
            [68.32500200000004, 27.357242000000042],
            [68.295252000000062, 27.357242000000042],
            [68.286341933000074, 27.358546007000029],
            [68.277784821000068, 27.359798358000035],
            [68.27223900000007, 27.360610000000065],
            [68.264646538000079, 27.357991958000071],
            [68.25596100000007, 27.354997000000026],
            [68.239122000000066, 27.347700000000032],
            [68.231354464000049, 27.341988613000069],
            [68.231198254000049, 27.341873753000073],
            [68.228726298000083, 27.340056150000066],
            [68.220037000000048, 27.333667000000048],
            [68.21855688900007, 27.327560967000068],
            [68.215547000000072, 27.315144000000032],
            [68.21386300000006, 27.296059000000071],
            [68.194398209000042, 27.273168001000045],
            [68.193649581000045, 27.272290622000071],
            [68.179062000000044, 27.252838000000054],
            [68.177866390000077, 27.240484314000071],
            [68.177378000000033, 27.235438000000045],
            [68.169445375000066, 27.231181461000062],
            [68.162073668000062, 27.227225903000033],
            [68.15594309200003, 27.223936319000074],
            [68.154364000000044, 27.223089000000073],
            [68.152556651000054, 27.221582891000025],
            [68.149370489000034, 27.218927782000037],
            [68.146075923000069, 27.216182338000067],
            [68.144216227000072, 27.214632606000066],
            [68.141614281000045, 27.212464340000054],
            [68.135324469000068, 27.207222881000064],
            [68.134157000000073, 27.206250000000068],
            [68.117879000000073, 27.205689000000064],
            [68.110421530000053, 27.204716236000024],
            [68.10496900000004, 27.204005000000052],
            [68.102158067000062, 27.205832132000069],
            [68.095717326000056, 27.210018671000057],
            [68.093743000000075, 27.211302000000046],
            [68.09165480200005, 27.20384559200005],
            [68.090675599000065, 27.200349115000051],
            [68.090247624000028, 27.198820930000068],
            [68.089813000000049, 27.197269000000063],
            [68.090517151000029, 27.195156860000054],
            [68.091932745000065, 27.190910710000026],
            [68.094304000000079, 27.183798000000024],
            [68.084200000000067, 27.173694000000069],
            [68.084005555000033, 27.171164827000041],
            [68.083078000000057, 27.159100000000024],
            [68.087961733000043, 27.152160199000036],
            [68.08911571200008, 27.150520392000033],
            [68.090789946000029, 27.148141300000077],
            [68.091455969000037, 27.14719487800005],
            [68.091741778000028, 27.146788743000059],
            [68.092066120000084, 27.146327851000024],
            [68.093743000000075, 27.143945000000031],
            [68.084762000000069, 27.138893000000053],
            [68.079149000000029, 27.124299000000065],
            [68.071852000000035, 27.123176000000058],
            [68.070168000000081, 27.137770000000046],
            [68.064555000000041, 27.140577000000064],
            [68.062096521000058, 27.137884519000067],
            [68.052767000000074, 27.127667000000031],
            [68.047394761000078, 27.129620304000071],
            [68.047272373000055, 27.129705760000036],
            [68.045747081000059, 27.130942871000059],
            [68.044364577000067, 27.131330188000049],
            [68.043310518000055, 27.131625490000033],
            [68.04263621900003, 27.13182417400003],
            [68.041669945000081, 27.132147126000064],
            [68.040510208000057, 27.132534055000065],
            [68.039710956000079, 27.132801402000041],
            [68.038875003000044, 27.133173268000064],
            [68.036844840000072, 27.134079942000028],
            [68.035618812000052, 27.134630147000053],
            [68.035614224000028, 27.134632206000049],
            [68.034280537000029, 27.135678513000073],
            [68.032019184000035, 27.137451425000052],
            [68.02955858100006, 27.139176335000059],
            [68.028504029000032, 27.139915314000064],
            [68.028001626000048, 27.140249908000044],
            [68.025193327000068, 27.142120202000058],
            [68.023606822000033, 27.143221496000024],
            [68.021851925000078, 27.144439681000051],
            [68.020619306000071, 27.145125567000036],
            [68.019837566000035, 27.145560101000058],
            [68.019353285000079, 27.14582929200003],
            [68.017775002000064, 27.147060496000051],
            [68.016371248000041, 27.148155109000072],
            [68.014402351000058, 27.149410442000033],
            [68.012876901000084, 27.150377099000025],
            [68.011279136000041, 27.151578914000027],
            [68.010560773000066, 27.152119252000034],
            [68.008797668000057, 27.15343432800006],
            [68.007911716000081, 27.154095752000046],
            [68.007153710000068, 27.15469379700005],
            [68.006124586000055, 27.155517486000065],
            [68.005466710000064, 27.156044036000026],
            [68.004478988000074, 27.156833905000042],
            [68.003670260000035, 27.157633467000039],
            [68.002400153000053, 27.158889179000028],
            [68.001699119000079, 27.159444377000057],
            [68.000541286000043, 27.160361956000031],
            [67.999067178000075, 27.162240290000057],
            [67.99873986800003, 27.162603076000039],
            [67.998311349000062, 27.16305858100003],
            [67.997564873000044, 27.163852555000062],
            [67.996093792000067, 27.165565809000043],
            [67.995047986000031, 27.166778830000055],
            [67.993244576000052, 27.168809297000053],
            [67.991308554000057, 27.170946945000026],
            [67.990339541000083, 27.172018482000055],
            [67.988874617000079, 27.172971590000031],
            [67.98850935300004, 27.17320939800004],
            [67.98704164600008, 27.174164961000031],
            [67.986012219000031, 27.174637889000053],
            [67.984246842000061, 27.17544775500005],
            [67.982199446000038, 27.177159309000047],
            [67.981034296000075, 27.178133335000041],
            [67.979708566000056, 27.179086057000063],
            [67.978171219000046, 27.180190855000035],
            [67.976983797000059, 27.180815634000055],
            [67.97381585200003, 27.182482495000045],
            [67.973418167000034, 27.182691744000067],
            [67.97260841800005, 27.183081046000041],
            [67.971114406000083, 27.183799320000048],
            [67.970450185000061, 27.184118656000066],
            [67.969073798000068, 27.184985464000079],
            [67.968095326000082, 27.185601691000045],
            [67.966704633000063, 27.186477545000059],
            [67.965413887000068, 27.187290396000037],
            [67.964553615000057, 27.187832143000037],
            [67.963755442000036, 27.188304794000032],
            [67.96332835700008, 27.188557699000057],
            [67.959582253000065, 27.190775963000078],
            [67.958082565000041, 27.191664079000077],
            [67.956614568000077, 27.192596975000072],
            [67.956591383000045, 27.192611709000062],
            [67.951839358000029, 27.195631570000046],
            [67.95082569200008, 27.196275743000058],
            [67.950369402000035, 27.196565711000062],
            [67.948880174000067, 27.197426940000071],
            [67.945905407000055, 27.199147264000032],
            [67.944241375000047, 27.200109368000028],
            [67.94249235400008, 27.201282996000032],
            [67.941089767000051, 27.202223541000023],
            [67.94034651000004, 27.204979431000027],
            [67.940349902000037, 27.206054920000042],
            [67.940353990000062, 27.207381618000056],
            [67.940355976000035, 27.208041465000065],
            [67.940360457000054, 27.20947039400005],
            [67.94011613400005, 27.213913193000053],
            [67.939896002000069, 27.217916995000053],
            [67.939871817000039, 27.218356880000044],
            [67.939771976000031, 27.219733759000064],
            [67.939683921000039, 27.221128779000026],
            [67.940204388000041, 27.221898530000033],
            [67.940918104000048, 27.222461306000071],
            [67.941814624000074, 27.222713977000069],
            [67.942336228000045, 27.222860983000032],
            [67.942964142000051, 27.223033473000044],
            [67.946872380000059, 27.22394700500007],
            [67.947750666000047, 27.224152300000071],
            [67.948627455000064, 27.224357245000078],
            [67.949493958000062, 27.224559786000043],
            [67.950006894000069, 27.224760581000055],
            [67.951968565000072, 27.225528619000045],
            [67.952940959000045, 27.225909156000057],
            [67.953880423000044, 27.226718361000053],
            [67.954819372000031, 27.227528890000031],
            [67.955931313000065, 27.229468718000078],
            [67.956198184000073, 27.230497548000073],
            [67.956214205000038, 27.231169778000037],
            [67.956297930000062, 27.234395680000034],
            [67.95632885100008, 27.235569112000064],
            [67.955705409000075, 27.237150633000056],
            [67.955079578000039, 27.238739474000056],
            [67.954608111000084, 27.239935004000074],
            [67.953338341000062, 27.242696795000029],
            [67.952854000000059, 27.243759620000048],
            [67.952801808000061, 27.243874149000078],
            [67.952602902000081, 27.246584750000068],
            [67.952854000000059, 27.247321923000072],
            [67.953104550000035, 27.24805748700004],
            [67.95333170400005, 27.248716611000077],
            [67.953559947000031, 27.249372212000026],
            [67.954152976000046, 27.249858924000023],
            [67.954551700000081, 27.250186165000059],
            [67.954983387000084, 27.250540459000035],
            [67.95556827200005, 27.251020487000062],
            [67.957707325000058, 27.253122217000055],
            [67.958260303000031, 27.253665547000026],
            [67.959238460000051, 27.254400659000055],
            [67.959893267000041, 27.254892766000069],
            [67.960548004000032, 27.255384819000028],
            [67.961204399000053, 27.255878119000045],
            [67.96246085100006, 27.256707811000069],
            [67.965629745000058, 27.258800479000058],
            [67.967741095000065, 27.260194597000066],
            [67.967977571000063, 27.260393386000032],
            [67.969360377000044, 27.261555817000044],
            [67.971625525000036, 27.263459975000046],
            [67.973531751000053, 27.265018313000041],
            [67.973953763000054, 27.26536330700003],
            [67.975248990000068, 27.266422101000046],
            [67.97589636400005, 27.266951329000051],
            [67.976731003000054, 27.267633690000025],
            [67.976868532000083, 27.267746128000056],
            [67.97791625800005, 27.268767607000029],
            [67.980715921000069, 27.271497459000045],
            [67.984616503000041, 27.275506982000024],
            [67.98527615900008, 27.276186474000042],
            [67.985797289000061, 27.276860893000048],
            [67.986260407000032, 27.277476316000048],
            [67.987333191000062, 27.278901630000064],
            [67.987957717000029, 27.279745717000026],
            [67.989765183000031, 27.28238026300005],
            [67.990485332000048, 27.283429946000069],
            [67.991295777000062, 27.28494493900007],
            [67.992309030000058, 27.286839048000047],
            [67.992915425000035, 27.287972603000071],
            [67.993202547000067, 27.288509331000057],
            [67.993411678000029, 27.288873823000074],
            [67.99363358800008, 27.289260589000037],
            [67.99422971000007, 27.290300072000036],
            [67.995427956000071, 27.292389019000041],
            [67.998577010000076, 27.299473540000065],
            [68.000882413000056, 27.304970956000034],
            [68.002216000000033, 27.308151000000066],
            [68.00501011700004, 27.318292934000056],
            [68.005279992000055, 27.319272511000065],
            [68.011118000000067, 27.340463000000057],
            [68.011118000000067, 27.357279000000062],
            [68.012997288000065, 27.363205311000058],
            [68.013971110000057, 27.36627624700003],
            [68.015405000000044, 27.370798000000036],
            [68.033869000000038, 27.370468000000074],
            [68.047923232000073, 27.372252858000024],
            [68.048978349000038, 27.372386855000059],
            [68.054641000000061, 27.373106000000064],
            [68.053645750000044, 27.377883320000024],
            [68.051344000000029, 27.388932000000068],
            [68.036884996000083, 27.386902477000035],
            [68.032550000000072, 27.386294000000078],
            [68.03000054000006, 27.387800619000075],
            [68.018043000000034, 27.394867000000033],
            [68.014235872000029, 27.398922114000072],
            [68.010848352000039, 27.402530287000047],
            [68.00903846600005, 27.40445806300005],
            [68.006264878000081, 27.407412315000045],
            [68.002875000000074, 27.411023000000057],
            [68.009470000000078, 27.426520000000039],
            [68.013820136000049, 27.431305304000034],
            [68.015045539000027, 27.432653290000076],
            [68.015195920000053, 27.432818715000053],
            [68.021875093000062, 27.440166041000055],
            [68.02925300000004, 27.448282000000063],
            [68.028989922000051, 27.459847048000029],
            [68.028989168000066, 27.45988019400005],
            [68.028923000000077, 27.462789000000043],
            [68.03348008100005, 27.468784938000056],
            [68.041453000000047, 27.47927500000003],
            [68.042981271000031, 27.483690120000063],
            [68.04372733200006, 27.485845462000043],
            [68.047388000000069, 27.496421000000055],
            [68.05214677400005, 27.508317709000039],
            [68.055933508000066, 27.517784366000058],
            [68.057939000000033, 27.522798000000023],
            [68.068157905000078, 27.526282022000032],
            [68.07244600000007, 27.527744000000041],
            [68.072652512000047, 27.538679752000064],
            [68.072658388000036, 27.538990884000043],
            [68.072709413000041, 27.541692907000026],
            [68.072710463000078, 27.541748535000067],
            [68.072776000000033, 27.545219000000031],
            [68.076912715000049, 27.554182492000052],
            [68.080689000000064, 27.562365000000057],
            [68.083630848000041, 27.563571801000023],
            [68.093548000000055, 27.56764000000004],
            [68.106078000000082, 27.584456000000046],
            [68.108439862000068, 27.587632238000026],
            [68.108501599000078, 27.587715262000074],
            [68.112159579000036, 27.592634521000036],
            [68.115640000000042, 27.597315000000037],
            [68.115640000000042, 27.600825666000048],
            [68.115640000000042, 27.609544451000033],
            [68.115640000000042, 27.615120000000047],
            [68.113470154000083, 27.621221971000068],
            [68.110364000000061, 27.629957000000047],
            [68.110364000000061, 27.641815473000065],
            [68.110364000000061, 27.647068613000044],
            [68.110364000000061, 27.65336700000006],
            [68.10354181200006, 27.666714524000042],
            [68.102786526000045, 27.668208271000026],
            [68.096846000000028, 27.680075000000045],
            [68.097231980000061, 27.680847010000036],
            [68.099483000000077, 27.685350000000028],
            [68.106854059000057, 27.682259376000047],
            [68.109705000000076, 27.681064000000049],
            [68.114119809000044, 27.67943249700005],
            [68.124872000000039, 27.675459000000046],
            [68.133001217000071, 27.675872293000054],
            [68.13497768600007, 27.675972778000073],
            [68.135558082000045, 27.676002286000028],
            [68.136467844000038, 27.676048538000032],
            [68.144325000000038, 27.67644800000005],
            [68.145883406000053, 27.677839458000051],
            [68.159636429000045, 27.690119155000048],
            [68.162789000000032, 27.692934000000037],
            [68.173131147000049, 27.70168484800007],
            [68.176025349000042, 27.704133587000058],
            [68.179935000000057, 27.707441000000074],
            [68.195761000000061, 27.717992000000038],
            [68.187848000000031, 27.732170000000053],
            [68.173011000000031, 27.74437000000006],
            [68.171096983000041, 27.754896367000072],
            [68.170373000000041, 27.758878000000038],
            [68.171455076000029, 27.769912020000049],
            [68.17177375600005, 27.773161636000054],
            [68.171778803000052, 27.773213095000074],
            [68.172022000000084, 27.775693000000047],
            [68.177112442000066, 27.787495134000039],
            [68.179275000000075, 27.792509000000052],
            [68.181940699000052, 27.797597861000042],
            [68.185817602000043, 27.804998931000057],
            [68.185929118000047, 27.805211816000053],
            [68.186529000000064, 27.806357000000048],
            [68.186678394000069, 27.812178403000075],
            [68.186859000000084, 27.81921600000004],
            [68.184128018000081, 27.827019061000044],
            [68.182243000000028, 27.832405000000051],
            [68.174494638000056, 27.850653727000065],
            [68.169301085000029, 27.862238507000029],
            [68.166416000000083, 27.868674000000055],
            [68.157941961000063, 27.876265617000058],
            [68.150590000000079, 27.882852000000071],
            [68.132126000000028, 27.905603000000042],
            [68.129746176000083, 27.908435968000049],
            [68.118277000000035, 27.922089000000028],
            [68.118642598000065, 27.923017019000042],
            [68.122500954000031, 27.93281091800003],
            [68.124872000000039, 27.930332000000078],
            [68.135438315000044, 27.934451413000033],
            [68.144325000000038, 27.93791600000003],
            [68.160711037000056, 27.938899003000074],
            [68.177297000000067, 27.939894000000038],
            [68.192657902000064, 27.933493559000055],
            [68.197080000000028, 27.931651000000045],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "135",
      properties: { name: "Matiari", count: 875 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.429543000000081, 26.066114000000027],
            [68.434635560000061, 26.058475707000071],
            [68.434635971000034, 26.058475967000049],
            [68.434636007000051, 26.058475990000034],
            [68.438116000000036, 26.053255000000036],
            [68.446285406000072, 26.047720609000066],
            [68.451404000000082, 26.044253000000026],
            [68.473125568000057, 26.045043545000055],
            [68.474979000000076, 26.045111000000077],
            [68.490668618000029, 26.048400530000038],
            [68.501555000000053, 26.050683000000049],
            [68.530646742000044, 26.062638218000075],
            [68.532846000000063, 26.063542000000041],
            [68.548277000000041, 26.057970000000068],
            [68.543991000000062, 26.049826000000053],
            [68.531131000000073, 26.043825000000027],
            [68.515700000000038, 26.040396000000044],
            [68.514492045000054, 26.038986536000039],
            [68.508674585000051, 26.032198620000031],
            [68.507985000000076, 26.031394000000034],
            [68.502412000000049, 26.019392000000039],
            [68.501676032000034, 26.012473280000052],
            [68.501497082000071, 26.010790996000026],
            [68.500603106000028, 26.002386880000074],
            [68.50026900000006, 25.999246000000028],
            [68.493411000000037, 25.98381500000005],
            [68.482695000000035, 25.973099000000047],
            [68.478837000000055, 25.956810000000075],
            [68.47583700000007, 25.942665000000034],
            [68.475667201000078, 25.937236171000052],
            [68.475408000000073, 25.928949000000046],
            [68.466654257000073, 25.92584223800003],
            [68.462120000000084, 25.924233000000072],
            [68.450975000000028, 25.921662000000026],
            [68.444846348000056, 25.920371401000068],
            [68.442831000000069, 25.919947000000036],
            [68.447118000000046, 25.908374000000038],
            [68.453598004000071, 25.902575892000073],
            [68.455262000000062, 25.901087000000075],
            [68.468121000000053, 25.900658000000078],
            [68.470003122000037, 25.902657858000055],
            [68.474979000000076, 25.907945000000041],
            [68.477289305000056, 25.902694262000068],
            [68.479694000000052, 25.897229000000038],
            [68.479964946000052, 25.896666349000043],
            [68.480713645000037, 25.895111586000041],
            [68.485267000000078, 25.88565600000004],
            [68.495126000000027, 25.88008300000007],
            [68.50198400000005, 25.870653000000061],
            [68.509699000000069, 25.868510000000072],
            [68.522462000000075, 25.863869000000022],
            [68.523845000000051, 25.863366000000042],
            [68.515700000000038, 25.858651000000066],
            [68.503270000000043, 25.851793000000043],
            [68.491268000000048, 25.848364000000061],
            [68.491736914000057, 25.84449419300006],
            [68.492715027000031, 25.836422003000052],
            [68.49298200000004, 25.834219000000076],
            [68.507556000000079, 25.826932000000056],
            [68.52641600000004, 25.826074000000062],
            [68.53530902500006, 25.817459105000069],
            [68.537906792000058, 25.814942583000061],
            [68.539137908000043, 25.81374997000006],
            [68.540133000000083, 25.812786000000074],
            [68.541848000000073, 25.793498000000056],
            [68.541419000000076, 25.780638000000067],
            [68.544710237000061, 25.771175094000057],
            [68.544848000000059, 25.770779000000061],
            [68.54711670100005, 25.769232289000058],
            [68.547580769000035, 25.768915906000075],
            [68.554278000000068, 25.764350000000036],
            [68.557279000000051, 25.754920000000027],
            [68.551706000000081, 25.742489000000035],
            [68.564137000000073, 25.738631000000055],
            [68.565083425000068, 25.738090231000058],
            [68.573138000000029, 25.733488000000023],
            [68.587632376000045, 25.723135288000037],
            [68.588141000000064, 25.722772000000077],
            [68.597253544000068, 25.715210027000069],
            [68.608287000000075, 25.706054000000051],
            [68.613750097000036, 25.701103479000039],
            [68.622004000000061, 25.693624000000057],
            [68.638721000000032, 25.692338000000063],
            [68.65372300000007, 25.686337000000037],
            [68.664394134000077, 25.677505531000065],
            [68.665620000000047, 25.676491000000055],
            [68.665619995000043, 25.676491112000065],
            [68.665620123000053, 25.676491006000049],
            [68.66624500000006, 25.662731000000065],
            [68.653900000000078, 25.658183000000065],
            [68.653900000000078, 25.656851882000069],
            [68.653900000000078, 25.64562520800007],
            [68.653900000000078, 25.641939000000036],
            [68.653900000000078, 25.633630124000035],
            [68.653900000000078, 25.630894000000069],
            [68.659748000000036, 25.623747000000037],
            [68.668195000000082, 25.608802000000026],
            [68.670794000000058, 25.599056000000076],
            [68.669145874000037, 25.597613906000049],
            [68.663311461000035, 25.592508850000058],
            [68.65126377200005, 25.581967239000051],
            [68.650810342000057, 25.581570492000026],
            [68.618814000000043, 25.553574000000026],
            [68.616438210000069, 25.549218538000048],
            [68.614998230000083, 25.546578667000063],
            [68.607985445000054, 25.533722343000079],
            [68.603220000000078, 25.524986000000069],
            [68.604229298000064, 25.513378886000055],
            [68.604241994000063, 25.513232876000075],
            [68.605819000000054, 25.495097000000044],
            [68.603657502000033, 25.490953933000071],
            [68.59022600000003, 25.465209000000073],
            [68.590225323000084, 25.465188677000071],
            [68.590225309000061, 25.465188276000049],
            [68.590225308000072, 25.465188240000032],
            [68.589847245000044, 25.453851026000052],
            [68.589576000000079, 25.445717000000059],
            [68.589860414000043, 25.436606013000073],
            [68.589384000000052, 25.436674000000039],
            [68.567524000000049, 25.444818000000055],
            [68.564204238000059, 25.446270386000037],
            [68.557986392000032, 25.448990674000072],
            [68.540874655000039, 25.456477007000046],
            [68.540833005000081, 25.456495229000041],
            [68.538437563000059, 25.457543228000077],
            [68.526374000000033, 25.462821000000076],
            [68.492968383000061, 25.47833068500006],
            [68.491549385000042, 25.478989502000047],
            [68.482840739000039, 25.483375488000036],
            [68.482816054000068, 25.483388072000025],
            [68.477684789000079, 25.486004053000045],
            [68.476714153000046, 25.486498895000068],
            [68.468507000000045, 25.490683000000047],
            [68.45436200000006, 25.505257000000029],
            [68.446756793000077, 25.522641416000056],
            [68.445361000000048, 25.525832000000037],
            [68.441919940000048, 25.532140164000054],
            [68.437645000000032, 25.539977000000079],
            [68.433983934000082, 25.541673582000044],
            [68.422034579000069, 25.547211054000059],
            [68.420071000000064, 25.548121000000037],
            [68.392638000000034, 25.55455100000006],
            [68.383208000000081, 25.554122000000064],
            [68.383984472000066, 25.551921167000046],
            [68.383678000000032, 25.552172000000041],
            [68.36974069200005, 25.570755378000058],
            [68.368247000000054, 25.572747000000049],
            [68.357103000000052, 25.598465000000033],
            [68.357103000000052, 25.606101571000067],
            [68.357103000000052, 25.616897000000051],
            [68.369533000000047, 25.627184000000057],
            [68.387671410000053, 25.633021638000059],
            [68.406825000000083, 25.639186000000052],
            [68.40873197600007, 25.640007460000049],
            [68.425250004000077, 25.647122864000039],
            [68.425945399000057, 25.64742241600004],
            [68.434687000000054, 25.651188000000047],
            [68.443044662000034, 25.655366831000038],
            [68.445330711000054, 25.656509855000024],
            [68.452298748000032, 25.659993874000065],
            [68.456119000000058, 25.66190400000005],
            [68.462549000000081, 25.676907000000028],
            [68.449981844000035, 25.679646284000057],
            [68.429115000000081, 25.684194000000048],
            [68.424665805000075, 25.685833170000024],
            [68.417971711000064, 25.688299404000077],
            [68.396538000000078, 25.696196000000043],
            [68.378626601000065, 25.704318640000054],
            [68.366058795000072, 25.710018013000024],
            [68.359675000000038, 25.712913000000071],
            [68.358073952000041, 25.713689217000024],
            [68.331384000000071, 25.726629000000059],
            [68.314238000000046, 25.740775000000042],
            [68.300436324000032, 25.757713154000044],
            [68.295378000000028, 25.763921000000039],
            [68.297038877000034, 25.771187883000039],
            [68.298807000000068, 25.778924000000075],
            [68.308237000000076, 25.788354000000027],
            [68.309404435000033, 25.788554136000073],
            [68.323240000000055, 25.79092600000007],
            [68.331813000000068, 25.805500000000052],
            [68.331105517000083, 25.810609175000025],
            [68.327955000000031, 25.833361000000025],
            [68.321251713000038, 25.853076226000042],
            [68.320668000000069, 25.854793000000029],
            [68.296664000000078, 25.869796000000065],
            [68.293351521000034, 25.871719347000067],
            [68.290593174000037, 25.873320944000056],
            [68.270088000000044, 25.885227000000043],
            [68.24479800000006, 25.907945000000041],
            [68.243116698000051, 25.911243183000067],
            [68.238466859000027, 25.920364694000057],
            [68.233654000000058, 25.929806000000042],
            [68.246942000000047, 25.945666000000074],
            [68.267088000000058, 25.966669000000024],
            [68.270068329000082, 25.971388385000068],
            [68.272231000000033, 25.97481300000004],
            [68.263007332000029, 25.980887727000038],
            [68.256654891000039, 25.98507135400007],
            [68.255085818000055, 25.986104723000039],
            [68.270946000000038, 25.991530000000068],
            [68.276984946000084, 25.995153593000055],
            [68.292378000000042, 26.004390000000058],
            [68.301778785000067, 26.009795245000078],
            [68.304037813000036, 26.011094138000033],
            [68.30952300000007, 26.014248000000066],
            [68.320203769000045, 26.037341627000046],
            [68.32029545100005, 26.037539859000049],
            [68.325383000000045, 26.04854000000006],
            [68.339662652000072, 26.067959980000069],
            [68.346815000000049, 26.077687000000026],
            [68.352164383000058, 26.082614055000079],
            [68.35521781500006, 26.085426421000022],
            [68.359716481000078, 26.089569922000067],
            [68.363104000000078, 26.092690000000061],
            [68.37382000000008, 26.096548000000041],
            [68.384011760000078, 26.09309311800007],
            [68.390041229000076, 26.091049202000079],
            [68.399110000000064, 26.087975000000029],
            [68.407481949000044, 26.081378650000033],
            [68.409694265000041, 26.079635542000062],
            [68.413255000000049, 26.076830000000029],
            [68.425256055000034, 26.068934414000068],
            [68.429543000000081, 26.066114000000027],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "136",
      properties: { name: "Mirpurkhas", count: 237 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.157806405000031, 25.779727955000055],
            [69.160420036000062, 25.778869875000055],
            [69.169282269000064, 25.779892843000027],
            [69.177641102000052, 25.780465871000047],
            [69.18645771100006, 25.781352191000053],
            [69.193801120000046, 25.779560739000033],
            [69.198541035000062, 25.776249617000076],
            [69.203743762000045, 25.770795984000074],
            [69.206277400000033, 25.764120426000034],
            [69.206624418000047, 25.763436456000079],
            [69.208448631000067, 25.759930940000061],
            [69.211586256000032, 25.757778690000066],
            [69.212835632000065, 25.756932353000025],
            [69.220446824000078, 25.756863898000063],
            [69.221470869000029, 25.756700005000027],
            [69.22509400000007, 25.756120140000064],
            [69.227957110000034, 25.755110799000079],
            [69.233200065000062, 25.749967173000073],
            [69.234789040000067, 25.745507011000029],
            [69.234045308000077, 25.736235601000033],
            [69.233887970000069, 25.734259074000079],
            [69.233567385000072, 25.730984447000026],
            [69.234656683000082, 25.729613569000037],
            [69.236440151000068, 25.727369082000052],
            [69.23684538200007, 25.726861553000049],
            [69.241159510000045, 25.724763246000066],
            [69.245121116000064, 25.725893877000033],
            [69.246542496000075, 25.72462678100004],
            [69.250485715000082, 25.718807647000062],
            [69.251188709000076, 25.717429692000053],
            [69.256063621000067, 25.70787425900005],
            [69.260469988000068, 25.700276118000033],
            [69.263809146000028, 25.695326059000024],
            [69.263435046000041, 25.695281371000078],
            [69.268240861000038, 25.692347073000064],
            [69.274296316000061, 25.689385757000025],
            [69.279558294000083, 25.686812478000036],
            [69.285443907000058, 25.682353338000041],
            [69.287079860000063, 25.681662875000029],
            [69.286786832000075, 25.678128112000024],
            [69.286847150000028, 25.672235170000079],
            [69.286498344000051, 25.668323300000054],
            [69.285998699000061, 25.662719756000058],
            [69.284475615000076, 25.653454969000052],
            [69.286565214000063, 25.647412281000072],
            [69.284574433000046, 25.648645841000075],
            [69.287813468000081, 25.644231802000036],
            [69.289323766000052, 25.633451354000044],
            [69.288748115000033, 25.633594149000032],
            [69.285155957000029, 25.631813353000041],
            [69.283132933000047, 25.630810448000034],
            [69.276563639000074, 25.624426374000052],
            [69.275816123000084, 25.622842399000035],
            [69.27361475400005, 25.618190150000032],
            [69.272519597000041, 25.615838733000032],
            [69.270311967000055, 25.60798830400006],
            [69.270557537000059, 25.605203860000074],
            [69.271191357000077, 25.597615292000057],
            [69.275191034000045, 25.587978729000042],
            [69.275554703000068, 25.587102528000059],
            [69.281276926000032, 25.58016185200006],
            [69.288158224000028, 25.572893564000026],
            [69.292623026000058, 25.568192285000066],
            [69.296144530000049, 25.559556908000047],
            [69.295402711000065, 25.550486166000042],
            [69.295287538000082, 25.549115185000062],
            [69.267258000000083, 25.549676000000034],
            [69.260426623000058, 25.550311482000041],
            [69.254764694000073, 25.550838179000039],
            [69.23931900000008, 25.552275000000066],
            [69.238221739000039, 25.552508444000068],
            [69.218555332000051, 25.556692498000075],
            [69.208781000000045, 25.558772000000033],
            [69.207394205000071, 25.558805837000023],
            [69.182141000000058, 25.55942200000004],
            [69.181270260000076, 25.559373605000076],
            [69.172191000000055, 25.558869000000072],
            [69.170476939000082, 25.558773720000033],
            [69.170476903000065, 25.558773718000054],
            [69.170446000000084, 25.558772000000033],
            [69.170476903000065, 25.558045506000042],
            [69.170840677000058, 25.549493581000036],
            [69.170919939000044, 25.547630233000064],
            [69.171732470000052, 25.528528558000062],
            [69.171744625000031, 25.52823575900004],
            [69.171744965000073, 25.528234824000037],
            [69.171745000000044, 25.528234000000054],
            [69.175144705000037, 25.518885139000076],
            [69.17514471800007, 25.518885103000059],
            [69.179537510000046, 25.506804724000062],
            [69.180835172000059, 25.499888807000048],
            [69.183441000000073, 25.486001000000044],
            [69.183149969000056, 25.472618949000037],
            [69.18312983900006, 25.471693328000072],
            [69.183129833000066, 25.471693072000051],
            [69.183129820000033, 25.471692482000037],
            [69.182791000000066, 25.456113000000073],
            [69.182795171000066, 25.456109902000037],
            [69.182795166000062, 25.456109659000049],
            [69.188043482000069, 25.452211024000064],
            [69.188330453000049, 25.451997851000044],
            [69.190497696000079, 25.450387946000035],
            [69.205480356000066, 25.439258293000023],
            [69.213327838000055, 25.433329527000069],
            [69.217638431000069, 25.430072477000067],
            [69.234770000000083, 25.417128000000048],
            [69.241462675000037, 25.414718833000052],
            [69.249596074000067, 25.411791048000055],
            [69.249596000000054, 25.411791000000051],
            [69.251014000000055, 25.411281000000031],
            [69.25130576600003, 25.406031005000045],
            [69.251664000000062, 25.399585000000059],
            [69.266953249000039, 25.400034867000045],
            [69.273755000000051, 25.400235000000066],
            [69.278953000000058, 25.384641000000045],
            [69.271435392000058, 25.372290171000031],
            [69.270098119000068, 25.370093139000062],
            [69.269857000000059, 25.369697000000031],
            [69.29129800000004, 25.374895000000038],
            [69.291760421000049, 25.374652792000063],
            [69.291760513000042, 25.374652744000059],
            [69.304216766000081, 25.368128388000059],
            [69.304943000000037, 25.367748000000063],
            [69.312090000000069, 25.348905000000059],
            [69.31961915100004, 25.338308480000023],
            [69.328188989000068, 25.326247299000045],
            [69.329633000000058, 25.324215000000038],
            [69.332193640000071, 25.322245308000049],
            [69.338969687000031, 25.317033047000052],
            [69.340270555000075, 25.316032394000047],
            [69.352800996000042, 25.306393746000026],
            [69.35497300000003, 25.304723000000024],
            [69.357317906000048, 25.302545403000067],
            [69.364069000000029, 25.296276000000034],
            [69.364007422000043, 25.295557510000037],
            [69.363203216000045, 25.286173996000059],
            [69.362120000000061, 25.273535000000038],
            [69.363521757000058, 25.250406295000062],
            [69.363547883000081, 25.249975216000053],
            [69.364635165000038, 25.23203526900005],
            [69.364719000000036, 25.230652000000077],
            [69.365827305000039, 25.226153762000024],
            [69.36725961500008, 25.220340498000041],
            [69.369523189000063, 25.211153410000065],
            [69.370458925000037, 25.207355573000029],
            [69.373469646000046, 25.195136069000057],
            [69.375765000000058, 25.185820000000035],
            [69.383495541000059, 25.164114718000064],
            [69.39239800200005, 25.13911900100004],
            [69.39265800000004, 25.138389000000075],
            [69.393220000000042, 25.137451000000056],
            [69.406092079000075, 25.115999513000077],
            [69.406303000000037, 25.115648000000078],
            [69.406032478000043, 25.108346828000037],
            [69.405836864000037, 25.103067340000052],
            [69.405653000000029, 25.098105000000032],
            [69.399295965000078, 25.075221436000049],
            [69.399155000000064, 25.074714000000029],
            [69.399556498000038, 25.069496694000065],
            [69.400455000000079, 25.057821000000047],
            [69.409488918000079, 25.043140449000077],
            [69.410851000000036, 25.040927000000067],
            [69.423154637000039, 25.028623363000065],
            [69.426582374000077, 25.025195626000027],
            [69.427744000000075, 25.024034000000029],
            [69.438983658000041, 25.007382417000031],
            [69.445287000000064, 24.99804400000005],
            [69.457231558000046, 24.990080757000044],
            [69.464779000000078, 24.98504900000006],
            [69.46482577900008, 24.984881377000079],
            [69.465023090000045, 24.984174352000025],
            [69.470151528000031, 24.965797614000053],
            [69.470227183000077, 24.965526519000036],
            [69.472576000000061, 24.957110000000057],
            [69.472935611000082, 24.952076179000073],
            [69.474799634000078, 24.925983690000066],
            [69.475364300000081, 24.918079532000036],
            [69.476004894000084, 24.909112526000058],
            [69.47647500000005, 24.902532000000065],
            [69.47647500000005, 24.886288000000036],
            [69.485153000000082, 24.882239000000027],
            [69.465678000000082, 24.865034000000037],
            [69.46084815100005, 24.862136005000025],
            [69.44296200000008, 24.851404000000059],
            [69.42409467300007, 24.849418073000038],
            [69.405967000000032, 24.847510000000057],
            [69.396370000000047, 24.836492000000078],
            [69.388443000000052, 24.827390000000037],
            [69.370469723000042, 24.818786186000068],
            [69.370469648000039, 24.818786150000051],
            [69.332644000000073, 24.800679000000059],
            [69.319237000000044, 24.81286700000004],
            [69.310979107000037, 24.819387023000047],
            [69.306892000000062, 24.822614000000044],
            [69.306811813000081, 24.822714226000073],
            [69.301694000000055, 24.829111000000069],
            [69.316425972000047, 24.849145720000024],
            [69.317779511000083, 24.850986463000027],
            [69.317938000000083, 24.851202000000058],
            [69.321417790000055, 24.860119903000054],
            [69.328333000000043, 24.877842000000044],
            [69.327264680000042, 24.88567708000005],
            [69.325955339000075, 24.895279808000055],
            [69.325924496000084, 24.895506011000066],
            [69.324435000000051, 24.906430000000057],
            [69.323368664000043, 24.907983810000076],
            [69.311221709000051, 24.925683719000062],
            [69.305594993000057, 24.933882676000053],
            [69.301694000000055, 24.939567000000068],
            [69.294251053000039, 24.948968808000075],
            [69.289349000000072, 24.955161000000032],
            [69.26830916800003, 24.959368966000056],
            [69.264503149000063, 24.96013017000007],
            [69.263359000000037, 24.960359000000039],
            [69.253694968000048, 24.960359000000039],
            [69.236070000000041, 24.960359000000039],
            [69.211380000000077, 24.956461000000047],
            [69.211251571000048, 24.956432807000056],
            [69.19351856000003, 24.952540065000051],
            [69.189645121000069, 24.95168977000003],
            [69.184740000000033, 24.950613000000033],
            [69.175861799000074, 24.953079462000062],
            [69.173045000000059, 24.953862000000072],
            [69.163299000000052, 24.968806000000029],
            [69.159709666000083, 24.975984244000074],
            [69.159032721000074, 24.97733805300004],
            [69.154852000000062, 24.985699000000068],
            [69.153476109000053, 24.986177579000071],
            [69.14113278800005, 24.990470980000055],
            [69.139908000000048, 24.990897000000075],
            [69.133411000000081, 24.992196000000035],
            [69.133411000000081, 24.995781000000079],
            [69.133411000000081, 25.001671982000062],
            [69.133411000000081, 25.003242000000057],
            [69.133062677000055, 25.004704872000048],
            [69.130162000000041, 25.016887000000054],
            [69.117817000000059, 25.031181000000061],
            [69.102873000000045, 25.038328000000035],
            [69.094850550000046, 25.043990778000023],
            [69.09182700000008, 25.046125000000075],
            [69.090736456000059, 25.051733822000074],
            [69.090165436000063, 25.054670658000077],
            [69.088281230000064, 25.064361391000034],
            [69.08727900000008, 25.069516000000078],
            [69.086758671000041, 25.069781939000052],
            [69.068119253000077, 25.079308512000068],
            [69.058040000000062, 25.084460000000036],
            [69.029469194000058, 25.09854993600004],
            [69.021202310000035, 25.102626820000069],
            [69.010609000000045, 25.107851000000039],
            [69.007441368000059, 25.110586627000032],
            [69.000496332000068, 25.116584491000026],
            [68.998040574000072, 25.118705330000068],
            [68.982020000000034, 25.13254100000006],
            [68.98163025100007, 25.136580331000062],
            [68.980055372000038, 25.152902269000037],
            [68.979455257000041, 25.159121808000066],
            [68.979288646000043, 25.160848556000076],
            [68.978510895000056, 25.168909116000066],
            [68.977678089000051, 25.177540253000075],
            [68.976152565000064, 25.193350675000033],
            [68.975508412000067, 25.200026632000061],
            [68.974873000000059, 25.206612000000064],
            [68.974420190000046, 25.212868726000067],
            [68.971256029000074, 25.256589703000031],
            [68.970662341000036, 25.264793041000075],
            [68.96826800000008, 25.297877000000028],
            [68.967726000000084, 25.305373000000031],
            [68.967616043000078, 25.306542571000023],
            [68.966369000000043, 25.319807000000026],
            [68.964645148000045, 25.33814264800003],
            [68.962108447000048, 25.365124091000041],
            [68.961174339000081, 25.375059702000044],
            [68.960579000000052, 25.381392000000062],
            [68.960862186000043, 25.390450470000076],
            [68.96122900000006, 25.402184000000034],
            [68.959556441000075, 25.404860072000076],
            [68.958046176000039, 25.40727647500006],
            [68.946946105000052, 25.425036437000074],
            [68.946945600000049, 25.425037245000055],
            [68.940308387000073, 25.435656693000055],
            [68.931990000000042, 25.448966000000041],
            [68.921865419000028, 25.46322316100003],
            [68.917599303000031, 25.469230590000052],
            [68.917598878000035, 25.469231188000037],
            [68.913003638000077, 25.475702080000076],
            [68.903111684000066, 25.489631661000033],
            [68.900153000000046, 25.493798000000027],
            [68.893298583000046, 25.515608030000067],
            [68.893298573000038, 25.515608063000059],
            [68.885859000000039, 25.539280000000076],
            [68.885895247000064, 25.539499627000055],
            [68.885905248000029, 25.53956022400007],
            [68.88856263100007, 25.555661632000067],
            [68.888562669000066, 25.555661861000033],
            [68.891113580000081, 25.571118141000056],
            [68.891172075000043, 25.571472570000026],
            [68.891293259000065, 25.572206835000031],
            [68.89229086000006, 25.57825142400003],
            [68.89576986600008, 25.599331146000054],
            [68.896904000000063, 25.60620300000005],
            [68.898334917000057, 25.612761324000076],
            [68.898847132000071, 25.615108958000064],
            [68.901837639000064, 25.62881535300005],
            [68.904701000000045, 25.641939000000036],
            [68.904572247000033, 25.644450637000034],
            [68.903899018000061, 25.657583512000031],
            [68.903796853000074, 25.65957648400007],
            [68.903402000000028, 25.667279000000065],
            [68.89974145900004, 25.672769811000023],
            [68.898048277000044, 25.675309584000047],
            [68.892410660000053, 25.683766009000067],
            [68.882610000000057, 25.698467000000051],
            [68.882609712000033, 25.698466987000074],
            [68.880011000000081, 25.702365000000043],
            [68.881643456000063, 25.725766326000041],
            [68.881960000000049, 25.730304000000046],
            [68.883867829000053, 25.731084536000026],
            [68.890867676000028, 25.733948333000058],
            [68.896254000000056, 25.736152000000061],
            [68.910978256000078, 25.736792399000024],
            [68.911199000000067, 25.736802000000068],
            [68.920295000000067, 25.754345000000058],
            [68.921175491000042, 25.753721727000027],
            [68.934293936000074, 25.744435573000032],
            [68.949734715000034, 25.733505508000064],
            [68.953354285000046, 25.730943323000076],
            [68.955113857000072, 25.729697774000044],
            [68.964679553000053, 25.722926505000032],
            [68.978122000000042, 25.713411000000065],
            [68.994805217000078, 25.702844905000063],
            [68.997614000000056, 25.701066000000026],
            [68.997968041000036, 25.705666627000028],
            [68.998617283000044, 25.714103279000028],
            [68.99891400000007, 25.717959000000064],
            [69.006153862000076, 25.718937466000057],
            [69.022954000000084, 25.721208000000047],
            [69.031401000000074, 25.734203000000036],
            [69.031419053000036, 25.734189870000023],
            [69.04051307900005, 25.727575802000047],
            [69.048736917000042, 25.721594620000076],
            [69.048745387000054, 25.721605913000076],
            [69.048747247000051, 25.721606788000031],
            [69.049725749000061, 25.726934109000069],
            [69.052952000000062, 25.744499000000076],
            [69.05011200000007, 25.75017900000006],
            [69.060896000000071, 25.75197600000007],
            [69.068374000000063, 25.755247000000054],
            [69.078187000000071, 25.755715000000066],
            [69.083795000000066, 25.75197600000007],
            [69.086819594000076, 25.753399405000039],
            [69.086814029000038, 25.753417795000075],
            [69.094216524000046, 25.756952997000042],
            [69.094415366000078, 25.757052397000052],
            [69.096413000000041, 25.758051000000023],
            [69.104358000000047, 25.763659000000075],
            [69.109498000000031, 25.76926700000007],
            [69.110900000000072, 25.777212000000077],
            [69.108564000000058, 25.781885000000045],
            [69.099217000000067, 25.787960000000055],
            [69.098328748000029, 25.795507767000061],
            [69.09828200000004, 25.795905000000062],
            [69.098065157000065, 25.798183477000066],
            [69.097348000000068, 25.805719000000067],
            [69.099684000000082, 25.808990000000051],
            [69.107162000000073, 25.811794000000077],
            [69.111225250000075, 25.811523310000041],
            [69.117332889000068, 25.810895955000035],
            [69.124453000000074, 25.809925000000078],
            [69.136603000000036, 25.807121000000052],
            [69.145271316000048, 25.806810967000047],
            [69.145856047000052, 25.803226302000041],
            [69.14869634400003, 25.785813980000057],
            [69.154521893000037, 25.780793623000079],
            [69.157806405000031, 25.779727955000055],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "137",
      properties: { name: "Naushahro Feroze", count: 1412 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.177378000000033, 27.235438000000045],
            [68.186920000000043, 27.233754000000033],
            [68.197585000000061, 27.233754000000033],
            [68.204336524000041, 27.231593628000041],
            [68.204481684000029, 27.231547179000074],
            [68.210503843000083, 27.229620191000038],
            [68.211617000000047, 27.229264000000057],
            [68.213398519000066, 27.228585323000061],
            [68.220696491000069, 27.225805128000047],
            [68.224787233000029, 27.224246742000048],
            [68.229240429000072, 27.222550277000039],
            [68.229321983000034, 27.222519209000041],
            [68.23519200000004, 27.220283000000052],
            [68.238997374000064, 27.219357354000067],
            [68.246674564000045, 27.217489899000043],
            [68.255119519000061, 27.215435688000071],
            [68.25596100000007, 27.215231000000074],
            [68.261364631000049, 27.213593545000037],
            [68.261496856000065, 27.213553477000062],
            [68.26629467500004, 27.212099600000045],
            [68.274484000000029, 27.209618000000034],
            [68.277649848000067, 27.211834037000074],
            [68.285710000000051, 27.217476000000033],
            [68.295814000000064, 27.216353000000026],
            [68.305583169000045, 27.211468416000059],
            [68.308162000000038, 27.210179000000039],
            [68.318827000000056, 27.211302000000046],
            [68.324608882000064, 27.209809762000077],
            [68.336228000000062, 27.206811000000073],
            [68.341333822000081, 27.198642291000056],
            [68.344648000000063, 27.193340000000035],
            [68.347504231000073, 27.190126263000025],
            [68.353628000000072, 27.183236000000079],
            [68.352932131000046, 27.182058354000048],
            [68.352627427000073, 27.181542690000072],
            [68.351764408000065, 27.180082169000059],
            [68.350724477000028, 27.178322253000033],
            [68.34955675100008, 27.176346065000075],
            [68.346331000000077, 27.17088700000005],
            [68.351733956000032, 27.167105251000066],
            [68.356317048000051, 27.163897359000032],
            [68.358673364000083, 27.162248078000061],
            [68.360283657000082, 27.161120969000024],
            [68.363171000000079, 27.159100000000024],
            [68.366262676000076, 27.156992022000054],
            [68.375271202000079, 27.150849796000045],
            [68.379585938000048, 27.147907906000057],
            [68.383219641000039, 27.145430361000024],
            [68.387868000000083, 27.142261000000076],
            [68.397483768000029, 27.136277745000029],
            [68.398700074000033, 27.135520919000044],
            [68.400456061000057, 27.13442828500007],
            [68.403138407000029, 27.132759239000052],
            [68.413127000000031, 27.126544000000024],
            [68.415716055000075, 27.118379017000052],
            [68.417089209000039, 27.114048450000041],
            [68.420149405000075, 27.104396811000072],
            [68.420424000000082, 27.103530000000035],
            [68.421851312000058, 27.101824222000062],
            [68.430045069000073, 27.092031874000043],
            [68.432477512000048, 27.089124864000041],
            [68.435034105000057, 27.086069483000074],
            [68.43831104800006, 27.082153213000026],
            [68.442903022000053, 27.076665352000077],
            [68.442960285000083, 27.076596917000074],
            [68.443438000000071, 27.07602600000007],
            [68.446483802000046, 27.062828126000056],
            [68.448073433000047, 27.055940040000053],
            [68.448080426000047, 27.055909740000061],
            [68.448490000000049, 27.054135000000031],
            [68.448113174000071, 27.047919559000036],
            [68.447367000000042, 27.035612000000071],
            [68.44206902600007, 27.027921549000041],
            [68.440193793000049, 27.025199493000059],
            [68.435826845000065, 27.018860504000031],
            [68.429966000000036, 27.010353000000066],
            [68.421658746000048, 27.00268479500005],
            [68.413942367000061, 26.995562011000061],
            [68.412847045000035, 26.99455094700005],
            [68.408075000000053, 26.990146000000038],
            [68.402694754000038, 26.988711154000043],
            [68.391424876000031, 26.985705615000029],
            [68.390223558000059, 26.985385238000049],
            [68.390195635000055, 26.985377792000065],
            [68.382817000000045, 26.983410000000049],
            [68.37816545000004, 26.970231530000035],
            [68.376320441000075, 26.965004368000052],
            [68.376077604000045, 26.964327237000077],
            [68.375405193000063, 26.962492078000025],
            [68.374792504000084, 26.960829438000076],
            [68.374354846000074, 26.959641777000058],
            [68.372855556000047, 26.955572795000023],
            [68.36822200000006, 26.942996000000051],
            [68.367012587000033, 26.941006238000057],
            [68.365624128000036, 26.938721903000044],
            [68.353785267000035, 26.919244255000024],
            [68.350822000000051, 26.914369000000079],
            [68.337154717000033, 26.90241069800004],
            [68.332860000000039, 26.898653000000024],
            [68.332554492000042, 26.895496146000028],
            [68.331964648000053, 26.889401203000034],
            [68.331176000000028, 26.881252000000075],
            [68.327945247000059, 26.875287703000026],
            [68.323879000000034, 26.867781000000036],
            [68.322511919000078, 26.86185725100006],
            [68.321124742000052, 26.855846426000028],
            [68.318827000000056, 26.845890000000054],
            [68.318638243000066, 26.844380002000037],
            [68.317827316000034, 26.83789282400005],
            [68.315459000000033, 26.818947000000037],
            [68.322589331000074, 26.80241871100003],
            [68.324362293000036, 26.798308643000041],
            [68.327808000000061, 26.790320000000065],
            [68.326692381000043, 26.788506932000075],
            [68.323318000000029, 26.783023000000071],
            [68.322457610000072, 26.779753722000066],
            [68.321456559000069, 26.775949966000042],
            [68.321186674000046, 26.774924467000062],
            [68.319386416000043, 26.768083915000034],
            [68.314898000000028, 26.751029000000074],
            [68.310832116000029, 26.739237718000027],
            [68.310790867000037, 26.739118094000048],
            [68.307504364000067, 26.729587061000075],
            [68.302573535000079, 26.715006005000077],
            [68.300681147000034, 26.709033214000044],
            [68.297595210000054, 26.699293321000027],
            [68.294639189000065, 26.689963472000045],
            [68.292816360000074, 26.684210227000051],
            [68.292790586000081, 26.684128877000035],
            [68.289960622000081, 26.675196892000031],
            [68.289639274000081, 26.674182648000055],
            [68.28600225200006, 26.662703411000052],
            [68.285973272000035, 26.662611944000048],
            [68.285710000000051, 26.661781000000076],
            [68.293203030000029, 26.657957931000055],
            [68.299892369000077, 26.654544918000056],
            [68.308975967000038, 26.649910315000056],
            [68.313214000000073, 26.647748000000036],
            [68.320635591000041, 26.645723842000052],
            [68.325097299000049, 26.644506960000058],
            [68.33216132900003, 26.64258032400005],
            [68.344086000000061, 26.639328000000035],
            [68.357186973000069, 26.630419650000078],
            [68.350822000000051, 26.630909000000031],
            [68.319979683000042, 26.627299438000023],
            [68.306695534000028, 26.625744758000053],
            [68.305874472000028, 26.625648666000075],
            [68.29805900000008, 26.624734000000046],
            [68.284518364000064, 26.622768433000033],
            [68.275581113000044, 26.621471224000061],
            [68.263258000000064, 26.619682000000068],
            [68.262042659000031, 26.619226295000033],
            [68.24080500000008, 26.611263000000065],
            [68.230874853000046, 26.601332853000031],
            [68.227895000000046, 26.598353000000031],
            [68.202636000000041, 26.596669000000077],
            [68.186382907000052, 26.597365602000025],
            [68.183403190000035, 26.59749331200004],
            [68.163345000000049, 26.598353000000031],
            [68.145905156000083, 26.594865155000036],
            [68.137006891000055, 26.59308556600007],
            [68.135279000000082, 26.592740000000049],
            [68.134268832000032, 26.592099265000058],
            [68.112266000000034, 26.578146000000061],
            [68.103228816000069, 26.577309097000068],
            [68.08195500000005, 26.575339000000042],
            [68.081266889000062, 26.575562639000054],
            [68.059503000000063, 26.582636000000036],
            [68.056874089000075, 26.58463395800004],
            [68.053549590000046, 26.587160557000061],
            [68.031437000000039, 26.603966000000071],
            [68.008985000000052, 26.587688000000071],
            [67.99888100000004, 26.571971000000076],
            [67.975868000000048, 26.57926800000007],
            [67.959793816000058, 26.581440480000026],
            [67.955099000000075, 26.582075000000032],
            [67.946411099000045, 26.572559364000028],
            [67.943312000000049, 26.569165000000055],
            [67.937494422000043, 26.555413506000036],
            [67.937138000000061, 26.554571000000067],
            [67.923666000000082, 26.545590000000061],
            [67.921708974000069, 26.545854422000048],
            [67.909279021000032, 26.547533860000044],
            [67.90289800000005, 26.548396000000025],
            [67.901993572000038, 26.548313789000076],
            [67.878200000000049, 26.546151000000066],
            [67.875969508000082, 26.546195586000067],
            [67.85013500000008, 26.54671200000007],
            [67.84679114100004, 26.549498483000036],
            [67.833295000000078, 26.560745000000054],
            [67.819475056000044, 26.567053298000076],
            [67.807475000000068, 26.572533000000078],
            [67.80130100000008, 26.585443000000055],
            [67.80301124600004, 26.59741490600004],
            [67.803392717000065, 26.597337123000045],
            [67.807594764000044, 26.597237067000037],
            [67.811223301000041, 26.598979407000058],
            [67.811718515000052, 26.599215965000042],
            [67.812850297000068, 26.599756453000055],
            [67.813880749000077, 26.600476164000042],
            [67.817331597000077, 26.60288637900004],
            [67.818365950000043, 26.60360881400004],
            [67.819309219000047, 26.604615165000041],
            [67.824410044000047, 26.61005086800003],
            [67.828750367000055, 26.614460695000048],
            [67.829812181000079, 26.615539607000073],
            [67.830387379000058, 26.616748265000069],
            [67.831712684000081, 26.619533114000035],
            [67.832013241000084, 26.620164750000072],
            [67.832262290000074, 26.620688137000059],
            [67.832991971000069, 26.622221264000075],
            [67.834035633000042, 26.623304275000066],
            [67.834480865000046, 26.62376622000005],
            [67.835359093000079, 26.624677724000037],
            [67.836481409000044, 26.625424673000055],
            [67.837305381000078, 26.625973133000059],
            [67.837886173000072, 26.626359726000032],
            [67.838514498000052, 26.626777780000054],
            [67.839301782000064, 26.627170746000047],
            [67.840918871000042, 26.627977901000065],
            [67.842465146000052, 26.628749711000069],
            [67.844816739000066, 26.629924589000041],
            [67.846657488000062, 26.631311937000078],
            [67.847894803000031, 26.632243925000068],
            [67.849363864000054, 26.634846793000065],
            [67.849951230000045, 26.635887406000052],
            [67.850691808000079, 26.637488651000069],
            [67.85278986600008, 26.642024984000045],
            [67.853036367000072, 26.642557957000065],
            [67.853529888000082, 26.643625027000041],
            [67.854440275000059, 26.644793093000033],
            [67.854923920000033, 26.645413550000058],
            [67.855632127000035, 26.646322001000044],
            [67.857087848000049, 26.648189603000048],
            [67.859060618000058, 26.649824498000044],
            [67.861363181000058, 26.65173270300005],
            [67.863007488000051, 26.653095391000079],
            [67.863820223000062, 26.653996763000066],
            [67.864359708000052, 26.65459508400005],
            [67.865838243000042, 26.65623494700003],
            [67.866171780000059, 26.656604781000055],
            [67.866438597000069, 26.656959837000045],
            [67.868358382000054, 26.659514514000023],
            [67.869338310000046, 26.660818513000038],
            [67.869562674000065, 26.661989241000072],
            [67.869787178000081, 26.663160521000066],
            [67.870530213000052, 26.667039147000025],
            [67.86959881000007, 26.673102956000037],
            [67.869421279000051, 26.674213101000078],
            [67.868939053000076, 26.675152789000038],
            [67.868673803000036, 26.675669668000069],
            [67.868064378000042, 26.676857224000059],
            [67.867285787000071, 26.678374779000023],
            [67.866701119000084, 26.679513739000072],
            [67.865630214000078, 26.680291548000071],
            [67.863681860000042, 26.681706659000042],
            [67.862792926000054, 26.682352301000037],
            [67.862739174000069, 26.68237014500005],
            [67.861592641000072, 26.682750760000033],
            [67.86078333100005, 26.683019426000044],
            [67.855824847000065, 26.684665432000031],
            [67.854170636000049, 26.685214641000073],
            [67.853353727000069, 26.685557570000071],
            [67.850909106000074, 26.686583793000068],
            [67.850234373000035, 26.68686708000007],
            [67.849075792000065, 26.687353398000027],
            [67.848272810000083, 26.687900006000064],
            [67.848194027000034, 26.687953636000032],
            [67.845662937000043, 26.689676604000056],
            [67.844382563000067, 26.690548184000079],
            [67.843602634000035, 26.692026238000039],
            [67.84260512600008, 26.693916627000078],
            [67.840411189000065, 26.69807438600003],
            [67.840330301000051, 26.698227677000034],
            [67.83972078000005, 26.699382787000047],
            [67.839313512000047, 26.70062342600005],
            [67.838429179000059, 26.703317682000034],
            [67.838110817000029, 26.704287716000067],
            [67.83789573200005, 26.704942987000038],
            [67.837153754000042, 26.707203475000028],
            [67.836658336000028, 26.708712800000058],
            [67.836244217000058, 26.709974227000032],
            [67.836238329000082, 26.710035924000067],
            [67.836101717000076, 26.711467391000042],
            [67.835720664000064, 26.715460205000056],
            [67.835541571000078, 26.717336800000055],
            [67.835509148000028, 26.717845229000034],
            [67.835643299000083, 26.720959355000048],
            [67.835659916000054, 26.721345088000078],
            [67.835731613000064, 26.723019368000053],
            [67.837457510000036, 26.723292355000069],
            [67.837915183000064, 26.723364802000049],
            [67.842221789000064, 26.724046517000033],
            [67.844756276000055, 26.724917181000023],
            [67.847719269000038, 26.72593518900004],
            [67.849316198000054, 26.726487020000036],
            [67.850846003000072, 26.727568022000071],
            [67.852087636000078, 26.728445921000059],
            [67.852955823000059, 26.73037811100005],
            [67.853414341000075, 26.731398564000074],
            [67.854170196000041, 26.733077646000027],
            [67.854867795000075, 26.735228573000029],
            [67.855382060000068, 26.736814221000031],
            [67.855768154000032, 26.73800467600006],
            [67.856439178000073, 26.73955926800005],
            [67.85811734400005, 26.743447153000034],
            [67.85895682000006, 26.745392006000031],
            [67.859506341000042, 26.747054662000039],
            [67.859938413000066, 26.748362233000023],
            [67.860776101000056, 26.750897303000045],
            [67.862288105000061, 26.755472705000045],
            [67.862562738000065, 26.756303412000022],
            [67.862749293000036, 26.757117853000068],
            [67.863771959000076, 26.761582510000039],
            [67.864195170000073, 26.763429750000057],
            [67.864579129000049, 26.765106371000059],
            [67.864350693000063, 26.766674464000062],
            [67.863874954000039, 26.769940155000029],
            [67.863570148000065, 26.772032145000026],
            [67.863448497000036, 26.772867556000051],
            [67.86317064900004, 26.773680744000046],
            [67.862033384000028, 26.777009224000039],
            [67.861517746000061, 26.778518361000067],
            [67.861446022000052, 26.778583328000025],
            [67.859993822000035, 26.779898719000073],
            [67.85938008200003, 26.780454640000073],
            [67.856431661000045, 26.783125296000037],
            [67.855648987000052, 26.784101508000049],
            [67.854032480000058, 26.78611773800003],
            [67.853310449000048, 26.787018311000054],
            [67.852865354000073, 26.787910232000058],
            [67.852601016000051, 26.788439935000042],
            [67.851867965000054, 26.789908557000047],
            [67.851370141000075, 26.790906471000028],
            [67.851135369000076, 26.792100560000051],
            [67.850862522000057, 26.793488295000031],
            [67.850606983000034, 26.794788010000048],
            [67.850714413000048, 26.796097404000079],
            [67.850750810000079, 26.796533650000072],
            [67.851071862000083, 26.800381728000048],
            [67.851126383000064, 26.801035214000024],
            [67.851517839000053, 26.80572714300007],
            [67.851662826000052, 26.807473566000056],
            [67.850954433000084, 26.808881722000024],
            [67.850114800000028, 26.810547386000053],
            [67.849526000000083, 26.811715336000077],
            [67.84798777900005, 26.813418913000078],
            [67.847177366000039, 26.814316444000042],
            [67.846008780000034, 26.815610651000043],
            [67.845417627000074, 26.817283657000075],
            [67.842366526000035, 26.825918492000028],
            [67.842275935000032, 26.826175650000039],
            [67.842110473000048, 26.826645345000031],
            [67.841352246000042, 26.828847304000078],
            [67.83889699100007, 26.835977589000038],
            [67.838462906000075, 26.837238090000028],
            [67.837884194000083, 26.838918491000072],
            [67.837586145000046, 26.840154983000048],
            [67.837469659000078, 26.840638240000033],
            [67.836551307000036, 26.844437587000073],
            [67.836119014000076, 26.846226045000037],
            [67.834735675000047, 26.852111775000026],
            [67.834613017000038, 26.852633651000076],
            [67.834426906000033, 26.854034507000051],
            [67.834083414000077, 26.855805947000079],
            [67.834448192000082, 26.857084881000048],
            [67.834984083000052, 26.858963749000054],
            [67.835288510000055, 26.86003108500006],
            [67.83594339900003, 26.861137212000074],
            [67.838785260000066, 26.86593719900003],
            [67.839660018000075, 26.867414692000068],
            [67.840536166000049, 26.868413177000036],
            [67.843746712000041, 26.872072015000072],
            [67.844250049000038, 26.872645634000037],
            [67.844666278000034, 26.873119981000059],
            [67.84520636700006, 26.873735483000075],
            [67.846130689000063, 26.874710839000045],
            [67.847054740000033, 26.875685909000026],
            [67.847771046000048, 26.876441763000059],
            [67.851367124000035, 26.880236386000036],
            [67.852598890000081, 26.881536158000074],
            [67.85342901000007, 26.882412111000065],
            [67.853522697000074, 26.882510970000055],
            [67.85521710300003, 26.884013000000039],
            [67.859046976000059, 26.887407951000057],
            [67.860642995000035, 26.888822859000072],
            [67.861923568000066, 26.890540070000043],
            [67.861947877000034, 26.890572668000061],
            [67.862224539000067, 26.890943708000066],
            [67.86362267100003, 26.892818788000056],
            [67.864400892000049, 26.893862089000038],
            [67.865143762000059, 26.894930898000041],
            [67.866380438000078, 26.896710174000077],
            [67.866760379000084, 26.897256816000038],
            [67.868110092000052, 26.899198725000076],
            [67.869299908000073, 26.900077038000063],
            [67.869507480000038, 26.900215003000028],
            [67.872067737000066, 26.901916708000044],
            [67.874562130000072, 26.904152098000054],
            [67.876023998000051, 26.905339514000048],
            [67.876966695000078, 26.906105228000058],
            [67.878194571000051, 26.906933346000073],
            [67.879578411000068, 26.907866651000063],
            [67.880120235000049, 26.908232075000058],
            [67.883062787000028, 26.910709674000032],
            [67.884368393000045, 26.911809171000073],
            [67.886068872000067, 26.91209082000006],
            [67.887757072000056, 26.91237043600006],
            [67.888192559000061, 26.912442974000044],
            [67.888739199000042, 26.912477034000062],
            [67.893025572000056, 26.912744473000032],
            [67.894342574000063, 26.91282663100003],
            [67.897543831000064, 26.912972880000041],
            [67.899137543000052, 26.913045689000057],
            [67.900886386000082, 26.913941667000074],
            [67.901589315000081, 26.914462995000065],
            [67.903713424000046, 26.916038343000025],
            [67.904425923000076, 26.916566567000075],
            [67.907598753000059, 26.919395388000055],
            [67.908408294000083, 26.920114130000059],
            [67.908526081000048, 26.920218706000071],
            [67.910647040000072, 26.921425707000026],
            [67.912249529000064, 26.921510843000078],
            [67.915975243000048, 26.921707208000043],
            [67.916848852000044, 26.921752992000052],
            [67.919468911000081, 26.921838607000041],
            [67.921095724000054, 26.921891792000054],
            [67.92229751800005, 26.922262674000024],
            [67.923482212000067, 26.922628280000026],
            [67.924282713000082, 26.922877726000024],
            [67.925005657000042, 26.923324972000046],
            [67.926515111000072, 26.924260391000075],
            [67.927456947000053, 26.924844043000064],
            [67.927474889000052, 26.924855161000039],
            [67.92807814300005, 26.925696683000069],
            [67.929748108000069, 26.928026191000072],
            [67.930355675000044, 26.928874767000025],
            [67.930600875000039, 26.929904603000068],
            [67.931292600000063, 26.932817496000041],
            [67.931578181000077, 26.934013765000032],
            [67.931542426000078, 26.934425157000078],
            [67.931507165000028, 26.934830864000048],
            [67.931434897000031, 26.935662358000059],
            [67.931370909000066, 26.936410247000026],
            [67.931263116000082, 26.936616836000042],
            [67.929881536000039, 26.939264689000026],
            [67.929191849000063, 26.940581146000056],
            [67.92761138000003, 26.941889184000047],
            [67.926982510000073, 26.942409523000038],
            [67.92629667500006, 26.942976996000027],
            [67.925249019000034, 26.94384438000003],
            [67.923774703000049, 26.944969336000042],
            [67.922749133000082, 26.94575188400006],
            [67.921273915000029, 26.946950136000055],
            [67.920637904000046, 26.948237834000054],
            [67.920385370000076, 26.94874976400007],
            [67.919599878000042, 26.950342093000074],
            [67.917728905000047, 26.954134883000052],
            [67.916604718000031, 26.956413807000047],
            [67.916401000000064, 26.95682677800005],
            [67.916432301000043, 26.957275969000023],
            [67.916447681000079, 26.957496690000028],
            [67.916479828000035, 26.957958166000026],
            [67.916863252000041, 26.963462370000059],
            [67.916952526000046, 26.964743936000048],
            [67.916980738000063, 26.965153745000066],
            [67.917046742000082, 26.966112531000078],
            [67.918309436000072, 26.967090272000064],
            [67.919567913000037, 26.968064749000064],
            [67.919841330000054, 26.968276464000041],
            [67.920213653000076, 26.968564764000064],
            [67.92077837000005, 26.968845101000056],
            [67.92546416600004, 26.971171229000049],
            [67.926538036000068, 26.971704321000061],
            [67.927822850000041, 26.972102308000046],
            [67.932110574000035, 26.973430483000072],
            [67.932540920000065, 26.973563788000035],
            [67.933250473000044, 26.973783581000077],
            [67.934572701000036, 26.973963010000034],
            [67.935861930000044, 26.974137961000054],
            [67.939860474000056, 26.974680571000079],
            [67.940742501000045, 26.974800264000066],
            [67.942492929000082, 26.974947186000065],
            [67.944243775000075, 26.975094143000035],
            [67.94555764100005, 26.975204407000035],
            [67.94862489500008, 26.975461872000039],
            [67.95175148800007, 26.975691009000059],
            [67.957629387000054, 26.976121779000039],
            [67.959348107000039, 26.97624773800004],
            [67.960688234000031, 26.976345951000042],
            [67.962028009000051, 26.976444139000023],
            [67.963420912000061, 26.976698347000024],
            [67.968276119000052, 26.977584437000075],
            [67.969247883000037, 26.977761787000077],
            [67.969521604000079, 26.977811742000029],
            [67.970283720000054, 26.978102508000063],
            [67.972732820000033, 26.979036909000058],
            [67.973861853000074, 26.979467656000054],
            [67.974505167000075, 26.979922238000029],
            [67.976745977000064, 26.981505651000077],
            [67.977821701000039, 26.982265785000038],
            [67.978313009000033, 26.983302670000057],
            [67.979267358000072, 26.985316785000066],
            [67.979821510000079, 26.986486300000024],
            [67.979579666000063, 26.988080173000071],
            [67.979395468000064, 26.989294125000072],
            [67.979071665000049, 26.991428146000032],
            [67.978967902000079, 26.991968313000029],
            [67.977585487000056, 26.999164882000059],
            [67.977172684000038, 27.001313849000042],
            [67.976531514000044, 27.003433630000075],
            [67.975725814000043, 27.006097367000052],
            [67.974685058000034, 27.00953822300005],
            [67.973448653000048, 27.013625918000059],
            [67.973237877000031, 27.014224049000063],
            [67.97195325000007, 27.017028237000034],
            [67.971619968000084, 27.017755751000038],
            [67.971381072000042, 27.018277236000074],
            [67.97073041200008, 27.018632360000026],
            [67.969420701000047, 27.019347186000061],
            [67.968107307000082, 27.019353152000065],
            [67.967056268000078, 27.019357925000065],
            [67.966632322000066, 27.019171220000032],
            [67.964681772000063, 27.018312201000072],
            [67.963457918000074, 27.018321024000045],
            [67.962319217000072, 27.018328876000055],
            [67.959176997000043, 27.020108862000029],
            [67.957892057000038, 27.021977151000044],
            [67.95722894000005, 27.022941316000072],
            [67.956756014000064, 27.02371372600004],
            [67.956218357000068, 27.024591857000075],
            [67.955279899000061, 27.026125967000041],
            [67.953368319000049, 27.026237319000074],
            [67.948583939000059, 27.026516148000042],
            [67.94731583600003, 27.026793721000047],
            [67.946084038000038, 27.02706334800007],
            [67.941712621000079, 27.028020207000054],
            [67.940316073000076, 27.028325910000035],
            [67.938674155000058, 27.028887048000058],
            [67.937717906000046, 27.029213853000044],
            [67.932929472000069, 27.030850335000025],
            [67.931698542000049, 27.031271014000026],
            [67.930878163000045, 27.031551385000057],
            [67.928819133000047, 27.032340408000039],
            [67.928362165000067, 27.032515505000049],
            [67.925929540000084, 27.033448311000029],
            [67.925572050000028, 27.033585393000067],
            [67.923211547000051, 27.034490543000061],
            [67.919837426000072, 27.035805804000063],
            [67.917903147000061, 27.036559803000046],
            [67.916735330000051, 27.037233143000037],
            [67.912754154000083, 27.039529731000073],
            [67.91167831000007, 27.040150345000029],
            [67.91143452700004, 27.040513608000026],
            [67.91113041300008, 27.040978222000035],
            [67.910766006000074, 27.041528514000049],
            [67.910058294000066, 27.042596490000051],
            [67.909994065000035, 27.044375914000057],
            [67.909922135000045, 27.046368656000027],
            [67.909792977000052, 27.049943881000047],
            [67.909767510000052, 27.050648847000048],
            [67.909731536000038, 27.051785134000056],
            [67.909902249000083, 27.052362457000072],
            [67.909963914000059, 27.052570996000043],
            [67.910733576000041, 27.055163648000075],
            [67.910755858000073, 27.055238707000058],
            [67.911136917000078, 27.056521805000045],
            [67.911985138000034, 27.056971378000071],
            [67.912612472000035, 27.057303876000049],
            [67.914102458000059, 27.058093596000049],
            [67.915863074000072, 27.058084143000031],
            [67.920806953000067, 27.058057597000072],
            [67.922307765000028, 27.058885136000072],
            [67.92415565500005, 27.05977877600003],
            [67.925875977000032, 27.059673408000037],
            [67.927134524000053, 27.05959632500003],
            [67.929290255000069, 27.060136119000049],
            [67.930450635000057, 27.060447016000069],
            [67.932566001000055, 27.06119794500006],
            [67.933724908000045, 27.061608759000023],
            [67.934914728000081, 27.06244048800005],
            [67.936871783000072, 27.063807355000051],
            [67.937369840000031, 27.064155222000068],
            [67.940291940000066, 27.066562410000074],
            [67.940551201000062, 27.066903251000042],
            [67.940940428000033, 27.067414830000075],
            [67.942002366000054, 27.068811046000064],
            [67.943079117000082, 27.06899756100006],
            [67.944138795000072, 27.069181119000064],
            [67.945984904000056, 27.07062234700004],
            [67.947144450000053, 27.071528389000036],
            [67.947159513000031, 27.071540159000051],
            [67.947183245000076, 27.071538834000023],
            [67.949469929000031, 27.071411117000025],
            [67.950095221000083, 27.071376193000049],
            [67.950741313000037, 27.071309836000069],
            [67.95527644100008, 27.070389042000045],
            [67.956151064000039, 27.070202718000075],
            [67.958786577000069, 27.069641268000055],
            [67.960555781000039, 27.069540504000031],
            [67.962768844000038, 27.069414459000029],
            [67.965216445000067, 27.069282448000024],
            [67.966986624000072, 27.069186975000036],
            [67.972082185000033, 27.068850147000035],
            [67.974638635000076, 27.068516842000065],
            [67.976235351000071, 27.068308212000034],
            [67.979167394000058, 27.068713907000074],
            [67.980621933000066, 27.069073669000034],
            [67.98088296700007, 27.069138233000047],
            [67.981799942000066, 27.069365034000043],
            [67.984153519000074, 27.069947185000046],
            [67.985931058000062, 27.069966650000026],
            [67.990102615000069, 27.070012394000059],
            [67.990879150000069, 27.070020820000025],
            [67.991987725000058, 27.069987009000045],
            [67.995226246000072, 27.069887050000034],
            [67.996956629000067, 27.069833649000032],
            [67.998796778000042, 27.069593305000069],
            [68.000306149000039, 27.069399823000026],
            [68.003585079000061, 27.06963197400006],
            [68.004457157000047, 27.069694631000061],
            [68.005311412000083, 27.070010251000042],
            [68.006139386000029, 27.070316315000071],
            [68.007389738000029, 27.070778831000041],
            [68.008248927000068, 27.071550168000044],
            [68.01032413300004, 27.073414457000069],
            [68.010982641000055, 27.074006133000069],
            [68.011706397000069, 27.075024751000058],
            [68.012673635000056, 27.076386174000049],
            [68.013164371000073, 27.07707718000006],
            [68.013556098000038, 27.077766266000026],
            [68.013969989000032, 27.078495591000035],
            [68.014777065000033, 27.079917845000068],
            [68.015182128000049, 27.080631526000047],
            [68.015764510000054, 27.081799103000037],
            [68.017326621000052, 27.084930217000078],
            [68.01919773700007, 27.088374133000059],
            [68.019980635000081, 27.089813520000064],
            [68.020848412000078, 27.090576327000065],
            [68.020961624000051, 27.090675844000032],
            [68.021575976000065, 27.091213737000032],
            [68.023541756000043, 27.092934866000064],
            [68.024190250000061, 27.093502697000076],
            [68.026422368000055, 27.094695605000027],
            [68.028026437000051, 27.095044990000076],
            [68.029229461000057, 27.095307023000032],
            [68.029709318000073, 27.095411542000079],
            [68.030446874000063, 27.095670883000025],
            [68.030593084000031, 27.095722291000072],
            [68.030848897000055, 27.095812236000029],
            [68.032057580000071, 27.09623724000005],
            [68.033233908000057, 27.096650924000073],
            [68.033839078000028, 27.097059340000044],
            [68.035322707000034, 27.098060880000048],
            [68.036567641000033, 27.098901287000047],
            [68.037246824000079, 27.101852566000048],
            [68.037254373000053, 27.102735375000066],
            [68.037269697000056, 27.105003357000044],
            [68.03727774500004, 27.106364039000027],
            [68.037459013000046, 27.108035791000077],
            [68.037549625000054, 27.10887389100003],
            [68.037640198000076, 27.109711632000028],
            [68.037774510000077, 27.110967152000057],
            [68.03796016900003, 27.111839001000078],
            [68.03836784300006, 27.113753426000073],
            [68.038620568000056, 27.114940213000068],
            [68.039015275000054, 27.115917098000068],
            [68.039821148000044, 27.117915379000067],
            [68.039862416000062, 27.118017709000071],
            [68.040074508000032, 27.118543623000051],
            [68.040444383000079, 27.11946302900003],
            [68.041154339000059, 27.120522054000048],
            [68.042098774000067, 27.121932835000052],
            [68.042573579000077, 27.122644421000075],
            [68.043287084000042, 27.123449403000052],
            [68.045306740000058, 27.125721208000073],
            [68.047031674000038, 27.127657077000038],
            [68.048249318000046, 27.129023621000044],
            [68.047394761000078, 27.129620304000071],
            [68.052767000000074, 27.127667000000031],
            [68.062096521000058, 27.137884519000067],
            [68.064555000000041, 27.140577000000064],
            [68.070168000000081, 27.137770000000046],
            [68.071852000000035, 27.123176000000058],
            [68.079149000000029, 27.124299000000065],
            [68.084762000000069, 27.138893000000053],
            [68.093743000000075, 27.143945000000031],
            [68.092066120000084, 27.146327851000024],
            [68.091741778000028, 27.146788743000059],
            [68.091455969000037, 27.14719487800005],
            [68.090789946000029, 27.148141300000077],
            [68.08911571200008, 27.150520392000033],
            [68.087961733000043, 27.152160199000036],
            [68.083078000000057, 27.159100000000024],
            [68.084005555000033, 27.171164827000041],
            [68.084200000000067, 27.173694000000069],
            [68.094304000000079, 27.183798000000024],
            [68.091932745000065, 27.190910710000026],
            [68.090517151000029, 27.195156860000054],
            [68.089813000000049, 27.197269000000063],
            [68.090247624000028, 27.198820930000068],
            [68.090675599000065, 27.200349115000051],
            [68.09165480200005, 27.20384559200005],
            [68.093743000000075, 27.211302000000046],
            [68.095717326000056, 27.210018671000057],
            [68.102158067000062, 27.205832132000069],
            [68.10496900000004, 27.204005000000052],
            [68.110421530000053, 27.204716236000024],
            [68.117879000000073, 27.205689000000064],
            [68.134157000000073, 27.206250000000068],
            [68.135324469000068, 27.207222881000064],
            [68.141614281000045, 27.212464340000054],
            [68.144216227000072, 27.214632606000066],
            [68.146075923000069, 27.216182338000067],
            [68.149370489000034, 27.218927782000037],
            [68.152556651000054, 27.221582891000025],
            [68.154364000000044, 27.223089000000073],
            [68.15594309200003, 27.223936319000074],
            [68.162073668000062, 27.227225903000033],
            [68.169445375000066, 27.231181461000062],
            [68.177378000000033, 27.235438000000045],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "138",
      properties: { name: "Sanghar", count: 300 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.935144000000037, 26.441186000000073],
            [68.949177000000077, 26.424347000000068],
            [68.949738000000082, 26.408630000000073],
            [68.951983000000041, 26.400772000000075],
            [68.95479000000006, 26.382810000000063],
            [68.961187112000061, 26.376768107000032],
            [68.964893000000075, 26.373268000000053],
            [68.969689895000045, 26.370286253000074],
            [68.985662000000048, 26.360358000000076],
            [68.997449000000074, 26.342396000000065],
            [69.008676000000037, 26.32106600000003],
            [69.015731563000031, 26.304307859000062],
            [69.017656000000045, 26.29973700000005],
            [69.038425000000075, 26.298614000000043],
            [69.057509000000039, 26.296369000000027],
            [69.062000000000069, 26.285704000000067],
            [69.055825000000084, 26.274478000000045],
            [69.055825000000084, 26.261006000000066],
            [69.060316000000057, 26.250341000000049],
            [69.06909326400006, 26.257011671000043],
            [69.074349000000041, 26.261006000000066],
            [69.078278000000068, 26.254832000000079],
            [69.081084000000033, 26.240799000000038],
            [69.093433000000061, 26.230134000000078],
            [69.107204000000081, 26.225847000000044],
            [69.112165393000055, 26.226130544000057],
            [69.122194396000054, 26.226703702000066],
            [69.132284235000043, 26.227280337000025],
            [69.136023041000044, 26.227552203000073],
            [69.154268423000076, 26.228878912000027],
            [69.172514124000031, 26.231717024000034],
            [69.189447284000039, 26.234350970000037],
            [69.205015181000078, 26.236772550000069],
            [69.206831000000079, 26.237055000000055],
            [69.241506000000072, 26.238294000000053],
            [69.241505718000042, 26.238293028000044],
            [69.241701000000035, 26.238300000000038],
            [69.279039476000037, 26.231710961000033],
            [69.284043000000054, 26.230828000000031],
            [69.29932576200008, 26.22869552800006],
            [69.347333706000029, 26.221996766000075],
            [69.373029557000052, 26.218411310000079],
            [69.391142000000059, 26.215884000000074],
            [69.404878616000076, 26.215884000000074],
            [69.44758033800008, 26.215884000000074],
            [69.482052000000067, 26.215884000000074],
            [69.49563362400005, 26.213439321000067],
            [69.513623819000031, 26.210201103000031],
            [69.525690148000081, 26.208029175000036],
            [69.54431900000003, 26.204676000000063],
            [69.576698000000079, 26.205922000000044],
            [69.596623000000079, 26.21214800000007],
            [69.622775000000047, 26.217130000000054],
            [69.637601862000054, 26.216173317000028],
            [69.65387529700007, 26.215123295000069],
            [69.661381000000063, 26.214639000000034],
            [69.670079889000078, 26.21327973700005],
            [69.696924157000069, 26.209085131000052],
            [69.701232000000061, 26.208412000000067],
            [69.706052801000055, 26.20787639100007],
            [69.728643456000043, 26.205366486000059],
            [69.746064000000047, 26.203431000000023],
            [69.774911924000037, 26.206315561000054],
            [69.795878000000073, 26.208412000000067],
            [69.799161836000053, 26.208229613000071],
            [69.808774881000033, 26.207695698000066],
            [69.840710000000058, 26.205922000000044],
            [69.85667982800004, 26.202119457000038],
            [69.882626874000039, 26.195941258000062],
            [69.893014000000051, 26.193468000000053],
            [69.915987009000048, 26.194425721000073],
            [69.922902000000079, 26.194714000000033],
            [69.926229302000081, 26.193604899000036],
            [69.942071359000067, 26.188324214000033],
            [69.956526000000054, 26.183506000000023],
            [69.97732699200003, 26.180221216000064],
            [70.003849000000059, 26.176033000000075],
            [70.020189015000028, 26.171948304000068],
            [70.043700000000058, 26.166071000000045],
            [70.050610696000035, 26.162929683000073],
            [70.071098000000063, 26.153617000000054],
            [70.096005000000048, 26.152372000000071],
            [70.120834000000059, 26.142694000000063],
            [70.12894526000008, 26.138651725000045],
            [70.128644880000081, 26.138232634000076],
            [70.127145000000041, 26.136140000000069],
            [70.126309000000049, 26.135852000000057],
            [70.118119000000036, 26.124644000000046],
            [70.116353000000061, 26.12379500000003],
            [70.107115000000078, 26.111634000000038],
            [70.098194000000035, 26.099444000000062],
            [70.09123900000003, 26.091304000000036],
            [70.090612000000078, 26.091594000000043],
            [70.08544500000005, 26.082397000000071],
            [70.088493000000028, 26.046535000000063],
            [70.090092000000084, 26.03758700000003],
            [70.090457000000072, 26.026638000000048],
            [70.091773000000046, 26.019859000000054],
            [70.093980000000045, 26.018178000000034],
            [70.094326000000081, 26.015254000000027],
            [70.093837000000065, 26.012793000000045],
            [70.094486000000074, 26.010350000000074],
            [70.095980000000054, 26.008465000000058],
            [70.095468000000039, 26.006213000000059],
            [70.096234000000038, 26.001599000000056],
            [70.095177756000055, 26.000418513000056],
            [70.093352000000039, 25.998378000000059],
            [70.096811000000059, 25.969611000000043],
            [70.10087500000003, 25.939143000000058],
            [70.113515000000064, 25.92089500000003],
            [70.12433800000008, 25.904533000000072],
            [70.146032000000048, 25.873091000000045],
            [70.161044000000061, 25.850482000000056],
            [70.168538000000069, 25.839625000000069],
            [70.175498000000061, 25.828937000000053],
            [70.198578000000055, 25.815834000000052],
            [70.216891000000032, 25.799591000000078],
            [70.222326641000052, 25.794536869000069],
            [70.19444100000004, 25.782934000000068],
            [70.171050000000037, 25.770588000000032],
            [70.133365000000083, 25.770588000000032],
            [70.112573000000054, 25.76279100000005],
            [70.089832000000058, 25.769289000000072],
            [70.046299000000033, 25.753045000000043],
            [70.034604000000058, 25.742000000000075],
            [70.03122527000005, 25.741180923000059],
            [70.02957284200005, 25.740780339000025],
            [70.013162000000079, 25.736802000000068],
            [69.991071000000034, 25.725756000000047],
            [69.974711476000039, 25.727014946000054],
            [69.974178000000052, 25.727056000000061],
            [69.94429000000008, 25.75759400000004],
            [69.930645000000084, 25.760193000000072],
            [69.915051000000062, 25.744599000000051],
            [69.890838730000041, 25.718032321000067],
            [69.868270000000052, 25.693269000000043],
            [69.848777000000041, 25.687421000000029],
            [69.824399815000049, 25.673248745000024],
            [69.820838000000037, 25.671178000000054],
            [69.790950000000066, 25.653635000000065],
            [69.773407000000077, 25.608153000000073],
            [69.772996515000045, 25.607652726000026],
            [69.752615000000048, 25.582813000000044],
            [69.742220000000032, 25.564620000000048],
            [69.725185735000082, 25.549348555000051],
            [69.723377000000028, 25.547727000000066],
            [69.705493966000063, 25.54051101400006],
            [69.686342000000081, 25.532783000000052],
            [69.672047000000077, 25.521087000000023],
            [69.670098000000053, 25.512640000000033],
            [69.669463688000064, 25.512751954000066],
            [69.664671480000038, 25.513597765000043],
            [69.648007000000064, 25.516539000000023],
            [69.636311951000039, 25.517837894000024],
            [69.636311000000035, 25.51783800000004],
            [69.631763000000035, 25.508092000000033],
            [69.62916400000006, 25.492498000000069],
            [69.613570000000038, 25.494448000000034],
            [69.607738878000077, 25.496003006000024],
            [69.594078000000081, 25.499646000000041],
            [69.593877133000035, 25.499674695000067],
            [69.576117663000048, 25.502211762000059],
            [69.575885000000028, 25.502245000000073],
            [69.575885000000028, 25.513940000000048],
            [69.566407821000041, 25.525787614000023],
            [69.565490000000068, 25.526935000000037],
            [69.563587755000071, 25.55228850900005],
            [69.563540000000046, 25.552925000000073],
            [69.562729599000079, 25.558058094000046],
            [69.562727622000068, 25.558058447000064],
            [69.561591000000078, 25.565270000000055],
            [69.559335973000032, 25.565753167000025],
            [69.541942124000059, 25.569480010000063],
            [69.541942088000042, 25.569480018000036],
            [69.534302000000082, 25.571117000000072],
            [69.532876810000062, 25.571246617000043],
            [69.520008000000075, 25.57241700000003],
            [69.507937838000032, 25.565519764000044],
            [69.501815000000079, 25.562021000000072],
            [69.501814030000048, 25.562019922000047],
            [69.501813930000083, 25.562019865000025],
            [69.495966896000084, 25.555524021000053],
            [69.495966853000084, 25.555523974000039],
            [69.495966825000039, 25.555523942000036],
            [69.495966665000083, 25.555523974000039],
            [69.492171821000056, 25.55628284900007],
            [69.492156494000028, 25.556285914000057],
            [69.491802930000063, 25.556356609000034],
            [69.479723000000035, 25.558772000000033],
            [69.461531000000036, 25.554224000000033],
            [69.457141147000073, 25.550879411000039],
            [69.449976831000072, 25.545420985000078],
            [69.44788600000004, 25.543828000000076],
            [69.435623607000082, 25.546280646000071],
            [69.429586627000049, 25.547488125000029],
            [69.418648000000076, 25.549676000000034],
            [69.407896655000059, 25.557155322000028],
            [69.403704000000062, 25.560072000000048],
            [69.393308000000047, 25.567219000000023],
            [69.389412483000058, 25.564143608000052],
            [69.386822272000074, 25.562098716000037],
            [69.381011568000076, 25.557511343000044],
            [69.380963000000065, 25.557473000000073],
            [69.358222000000069, 25.561371000000065],
            [69.356707834000076, 25.561976706000053],
            [69.347565313000075, 25.565633956000056],
            [69.335481000000073, 25.570468000000062],
            [69.317938000000083, 25.560072000000048],
            [69.314918313000078, 25.558238577000054],
            [69.300665507000076, 25.549584892000041],
            [69.29974500000003, 25.549026000000026],
            [69.295287538000082, 25.549115185000062],
            [69.295402711000065, 25.550486166000042],
            [69.296144530000049, 25.559556908000047],
            [69.292623026000058, 25.568192285000066],
            [69.288158224000028, 25.572893564000026],
            [69.281276926000032, 25.58016185200006],
            [69.275554703000068, 25.587102528000059],
            [69.275191034000045, 25.587978729000042],
            [69.271191357000077, 25.597615292000057],
            [69.270557537000059, 25.605203860000074],
            [69.270311967000055, 25.60798830400006],
            [69.272519597000041, 25.615838733000032],
            [69.27361475400005, 25.618190150000032],
            [69.275816123000084, 25.622842399000035],
            [69.276563639000074, 25.624426374000052],
            [69.283132933000047, 25.630810448000034],
            [69.285155957000029, 25.631813353000041],
            [69.288748115000033, 25.633594149000032],
            [69.289323766000052, 25.633451354000044],
            [69.287813468000081, 25.644231802000036],
            [69.284574433000046, 25.648645841000075],
            [69.286565214000063, 25.647412281000072],
            [69.284475615000076, 25.653454969000052],
            [69.285998699000061, 25.662719756000058],
            [69.286498344000051, 25.668323300000054],
            [69.286847150000028, 25.672235170000079],
            [69.286786832000075, 25.678128112000024],
            [69.287079860000063, 25.681662875000029],
            [69.285443907000058, 25.682353338000041],
            [69.279558294000083, 25.686812478000036],
            [69.274296316000061, 25.689385757000025],
            [69.268240861000038, 25.692347073000064],
            [69.263435046000041, 25.695281371000078],
            [69.263809146000028, 25.695326059000024],
            [69.260469988000068, 25.700276118000033],
            [69.256063621000067, 25.70787425900005],
            [69.251188709000076, 25.717429692000053],
            [69.250485715000082, 25.718807647000062],
            [69.246542496000075, 25.72462678100004],
            [69.245121116000064, 25.725893877000033],
            [69.241159510000045, 25.724763246000066],
            [69.23684538200007, 25.726861553000049],
            [69.236440151000068, 25.727369082000052],
            [69.234656683000082, 25.729613569000037],
            [69.233567385000072, 25.730984447000026],
            [69.233887970000069, 25.734259074000079],
            [69.234045308000077, 25.736235601000033],
            [69.234789040000067, 25.745507011000029],
            [69.233200065000062, 25.749967173000073],
            [69.227957110000034, 25.755110799000079],
            [69.22509400000007, 25.756120140000064],
            [69.221470869000029, 25.756700005000027],
            [69.220446824000078, 25.756863898000063],
            [69.212835632000065, 25.756932353000025],
            [69.211586256000032, 25.757778690000066],
            [69.208448631000067, 25.759930940000061],
            [69.206624418000047, 25.763436456000079],
            [69.206277400000033, 25.764120426000034],
            [69.203743762000045, 25.770795984000074],
            [69.198541035000062, 25.776249617000076],
            [69.193801120000046, 25.779560739000033],
            [69.18645771100006, 25.781352191000053],
            [69.177641102000052, 25.780465871000047],
            [69.169282269000064, 25.779892843000027],
            [69.160420036000062, 25.778869875000055],
            [69.157806405000031, 25.779727955000055],
            [69.154521893000037, 25.780793623000079],
            [69.14869634400003, 25.785813980000057],
            [69.145856047000052, 25.803226302000041],
            [69.145271316000048, 25.806810967000047],
            [69.136603000000036, 25.807121000000052],
            [69.124453000000074, 25.809925000000078],
            [69.117332889000068, 25.810895955000035],
            [69.111225250000075, 25.811523310000041],
            [69.107162000000073, 25.811794000000077],
            [69.099684000000082, 25.808990000000051],
            [69.097348000000068, 25.805719000000067],
            [69.098065157000065, 25.798183477000066],
            [69.09828200000004, 25.795905000000062],
            [69.098328748000029, 25.795507767000061],
            [69.099217000000067, 25.787960000000055],
            [69.108564000000058, 25.781885000000045],
            [69.110900000000072, 25.777212000000077],
            [69.109498000000031, 25.76926700000007],
            [69.104358000000047, 25.763659000000075],
            [69.096413000000041, 25.758051000000023],
            [69.094415366000078, 25.757052397000052],
            [69.094216524000046, 25.756952997000042],
            [69.086814029000038, 25.753417795000075],
            [69.086819594000076, 25.753399405000039],
            [69.083795000000066, 25.75197600000007],
            [69.078187000000071, 25.755715000000066],
            [69.068374000000063, 25.755247000000054],
            [69.060896000000071, 25.75197600000007],
            [69.05011200000007, 25.75017900000006],
            [69.052952000000062, 25.744499000000076],
            [69.049725749000061, 25.726934109000069],
            [69.048747247000051, 25.721606788000031],
            [69.048745387000054, 25.721605913000076],
            [69.048736917000042, 25.721594620000076],
            [69.04051307900005, 25.727575802000047],
            [69.031419053000036, 25.734189870000023],
            [69.031401000000074, 25.734203000000036],
            [69.022954000000084, 25.721208000000047],
            [69.006153862000076, 25.718937466000057],
            [68.99891400000007, 25.717959000000064],
            [68.998617283000044, 25.714103279000028],
            [68.997968041000036, 25.705666627000028],
            [68.997614000000056, 25.701066000000026],
            [68.994805217000078, 25.702844905000063],
            [68.978122000000042, 25.713411000000065],
            [68.964679553000053, 25.722926505000032],
            [68.955113857000072, 25.729697774000044],
            [68.953354285000046, 25.730943323000076],
            [68.949734715000034, 25.733505508000064],
            [68.934293936000074, 25.744435573000032],
            [68.921175491000042, 25.753721727000027],
            [68.920295000000067, 25.754345000000058],
            [68.911199000000067, 25.736802000000068],
            [68.910978256000078, 25.736792399000024],
            [68.896254000000056, 25.736152000000061],
            [68.890867676000028, 25.733948333000058],
            [68.883867829000053, 25.731084536000026],
            [68.881960000000049, 25.730304000000046],
            [68.881643456000063, 25.725766326000041],
            [68.880011000000081, 25.702365000000043],
            [68.882609712000033, 25.698466987000074],
            [68.868315000000052, 25.697817000000043],
            [68.841636557000072, 25.721393876000036],
            [68.840377000000046, 25.722507000000064],
            [68.835249824000073, 25.724959172000069],
            [68.810488000000078, 25.736802000000068],
            [68.803148460000045, 25.738025296000046],
            [68.786354975000052, 25.740824300000043],
            [68.779301000000032, 25.742000000000075],
            [68.757560863000037, 25.748292850000041],
            [68.754610000000071, 25.74914700000005],
            [68.739017000000047, 25.754995000000065],
            [68.733789044000048, 25.742164274000061],
            [68.731869000000074, 25.737452000000076],
            [68.731597055000066, 25.736737993000077],
            [68.726672000000065, 25.723807000000079],
            [68.725477941000065, 25.723618501000033],
            [68.714326000000028, 25.721858000000054],
            [68.692235000000039, 25.729655000000037],
            [68.691585000000032, 25.713411000000065],
            [68.685738000000072, 25.701716000000033],
            [68.685195648000047, 25.700547891000042],
            [68.684268057000054, 25.698550063000027],
            [68.677291000000082, 25.683523000000037],
            [68.66559600000005, 25.677025000000071],
            [68.665619995000043, 25.676491112000065],
            [68.665620000000047, 25.676491000000055],
            [68.664394134000077, 25.677505531000065],
            [68.65372300000007, 25.686337000000037],
            [68.638721000000032, 25.692338000000063],
            [68.622004000000061, 25.693624000000057],
            [68.613750097000036, 25.701103479000039],
            [68.608287000000075, 25.706054000000051],
            [68.597253544000068, 25.715210027000069],
            [68.588141000000064, 25.722772000000077],
            [68.587632376000045, 25.723135288000037],
            [68.573138000000029, 25.733488000000023],
            [68.565083425000068, 25.738090231000058],
            [68.564137000000073, 25.738631000000055],
            [68.551706000000081, 25.742489000000035],
            [68.557279000000051, 25.754920000000027],
            [68.554278000000068, 25.764350000000036],
            [68.547580769000035, 25.768915906000075],
            [68.54711670100005, 25.769232289000058],
            [68.544848000000059, 25.770779000000061],
            [68.544710237000061, 25.771175094000057],
            [68.541419000000076, 25.780638000000067],
            [68.541848000000073, 25.793498000000056],
            [68.540133000000083, 25.812786000000074],
            [68.539137908000043, 25.81374997000006],
            [68.537906792000058, 25.814942583000061],
            [68.53530902500006, 25.817459105000069],
            [68.52641600000004, 25.826074000000062],
            [68.507556000000079, 25.826932000000056],
            [68.49298200000004, 25.834219000000076],
            [68.492715027000031, 25.836422003000052],
            [68.491736914000057, 25.84449419300006],
            [68.491268000000048, 25.848364000000061],
            [68.503270000000043, 25.851793000000043],
            [68.515700000000038, 25.858651000000066],
            [68.523845000000051, 25.863366000000042],
            [68.522462000000075, 25.863869000000022],
            [68.509699000000069, 25.868510000000072],
            [68.50198400000005, 25.870653000000061],
            [68.495126000000027, 25.88008300000007],
            [68.485267000000078, 25.88565600000004],
            [68.480713645000037, 25.895111586000041],
            [68.479964946000052, 25.896666349000043],
            [68.479694000000052, 25.897229000000038],
            [68.477289305000056, 25.902694262000068],
            [68.474979000000076, 25.907945000000041],
            [68.470003122000037, 25.902657858000055],
            [68.468121000000053, 25.900658000000078],
            [68.455262000000062, 25.901087000000075],
            [68.453598004000071, 25.902575892000073],
            [68.447118000000046, 25.908374000000038],
            [68.442831000000069, 25.919947000000036],
            [68.444846348000056, 25.920371401000068],
            [68.450975000000028, 25.921662000000026],
            [68.462120000000084, 25.924233000000072],
            [68.466654257000073, 25.92584223800003],
            [68.475408000000073, 25.928949000000046],
            [68.475667201000078, 25.937236171000052],
            [68.47583700000007, 25.942665000000034],
            [68.478837000000055, 25.956810000000075],
            [68.482695000000035, 25.973099000000047],
            [68.493411000000037, 25.98381500000005],
            [68.50026900000006, 25.999246000000028],
            [68.500603106000028, 26.002386880000074],
            [68.501497082000071, 26.010790996000026],
            [68.501676032000034, 26.012473280000052],
            [68.502412000000049, 26.019392000000039],
            [68.507985000000076, 26.031394000000034],
            [68.508674585000051, 26.032198620000031],
            [68.514492045000054, 26.038986536000039],
            [68.515700000000038, 26.040396000000044],
            [68.531131000000073, 26.043825000000027],
            [68.543991000000062, 26.049826000000053],
            [68.548277000000041, 26.057970000000068],
            [68.532846000000063, 26.063542000000041],
            [68.530646742000044, 26.062638218000075],
            [68.501555000000053, 26.050683000000049],
            [68.490668618000029, 26.048400530000038],
            [68.474979000000076, 26.045111000000077],
            [68.473125568000057, 26.045043545000055],
            [68.451404000000082, 26.044253000000026],
            [68.446285406000072, 26.047720609000066],
            [68.438116000000036, 26.053255000000036],
            [68.434636007000051, 26.058475990000034],
            [68.441907000000072, 26.06286300000005],
            [68.455257000000074, 26.062502000000052],
            [68.468246000000079, 26.070079000000078],
            [68.468246000000079, 26.071385660000033],
            [68.468246000000079, 26.083790000000079],
            [68.453782766000074, 26.112716467000041],
            [68.453192225000066, 26.113897549000058],
            [68.46043700000007, 26.115144311000051],
            [68.463384649000034, 26.116430832000049],
            [68.466435150000052, 26.118252400000074],
            [68.469701532000045, 26.121561113000041],
            [68.473148980000076, 26.123467486000038],
            [68.476923747000058, 26.124967225000034],
            [68.478559082000061, 26.126696000000038],
            [68.480530439000063, 26.129376371000035],
            [68.481035059000078, 26.130128339000066],
            [68.483211175000065, 26.130223541000078],
            [68.486005505000037, 26.130539116000023],
            [68.487270950000038, 26.13114444100006],
            [68.490974431000041, 26.136353436000036],
            [68.497235243000034, 26.144422682000027],
            [68.499704456000075, 26.147982900000045],
            [68.500583409000058, 26.149998570000037],
            [68.494903482000041, 26.151114758000062],
            [68.488661878000073, 26.15386092500006],
            [68.486600598000052, 26.154852503000029],
            [68.486283246000028, 26.155005159000041],
            [68.48771352600005, 26.155398658000024],
            [68.493021723000084, 26.158228628000074],
            [68.499899994000032, 26.162582336000071],
            [68.50526336300004, 26.166041886000073],
            [68.505698050000035, 26.165767589000041],
            [68.511113505000083, 26.162350298000035],
            [68.515169052000033, 26.16103441000007],
            [68.518467515000054, 26.159871363000036],
            [68.524509137000052, 26.156490212000051],
            [68.525603609000029, 26.156104678000077],
            [68.525816697000039, 26.155141289000028],
            [68.526511241000037, 26.155756557000075],
            [68.530905840000059, 26.157046100000059],
            [68.537441614000045, 26.160061764000034],
            [68.542521943000054, 26.162358788000063],
            [68.546574937000059, 26.165517302000069],
            [68.551855292000027, 26.168083067000055],
            [68.555335362000051, 26.168693242000074],
            [68.55953866200008, 26.167831448000072],
            [68.56562028500008, 26.165760545000069],
            [68.57247433100008, 26.162712024000029],
            [68.574715692000041, 26.161432105000074],
            [68.575479416000064, 26.161340556000027],
            [68.575687624000068, 26.161726821000059],
            [68.579137463000052, 26.166439248000074],
            [68.579947272000084, 26.168241354000031],
            [68.580418774000066, 26.168736383000066],
            [68.580488069000069, 26.16880913600005],
            [68.583208249000052, 26.171665136000058],
            [68.582997553000041, 26.172140692000028],
            [68.582123395000053, 26.174113494000039],
            [68.582800860000077, 26.174262691000024],
            [68.584605560000057, 26.17999424900006],
            [68.584713660000034, 26.179951856000059],
            [68.601747000000046, 26.173272000000054],
            [68.611153364000074, 26.183668074000025],
            [68.612796372000048, 26.185483955000052],
            [68.612908413000071, 26.185607784000069],
            [68.622314000000074, 26.196003000000076],
            [68.626702548000083, 26.189858945000026],
            [68.63488742800007, 26.178399947000059],
            [68.636270058000036, 26.176464238000051],
            [68.642069751000065, 26.168344551000075],
            [68.642158000000052, 26.168221000000074],
            [68.644667444000049, 26.171525990000077],
            [68.653737293000063, 26.183471165000071],
            [68.656952000000047, 26.187705000000051],
            [68.663319430000058, 26.200865072000056],
            [68.66331924900004, 26.200865002000057],
            [68.664853000000051, 26.204035000000033],
            [68.666698121000081, 26.207847702000038],
            [68.666698247000056, 26.207847721000064],
            [68.666994055000032, 26.208459036000079],
            [68.66777600000006, 26.210075000000074],
            [68.666061099000046, 26.214602567000043],
            [68.664984196000034, 26.217445733000034],
            [68.659307826000031, 26.232432105000044],
            [68.659281738000061, 26.232500981000044],
            [68.658756000000039, 26.233889000000033],
            [68.652967232000037, 26.243771853000055],
            [68.646868267000059, 26.254184287000044],
            [68.643962000000045, 26.259146000000044],
            [68.641947948000052, 26.262394413000038],
            [68.637126440000031, 26.27017089900005],
            [68.634392501000036, 26.274580399000058],
            [68.632777000000033, 26.277186000000029],
            [68.632579510000028, 26.278686988000061],
            [68.63097300000004, 26.290897000000029],
            [68.637025554000047, 26.295652535000045],
            [68.638889686000084, 26.297117197000034],
            [68.641076000000055, 26.298835000000054],
            [68.656528224000056, 26.298835000000054],
            [68.662003000000084, 26.298835000000054],
            [68.679683000000068, 26.299196000000052],
            [68.688959564000072, 26.304428900000062],
            [68.688962959000037, 26.304430815000046],
            [68.693755000000067, 26.307134000000076],
            [68.694528479000041, 26.312839411000027],
            [68.695929586000034, 26.323174394000034],
            [68.696641000000056, 26.328422000000046],
            [68.700971000000038, 26.334556000000077],
            [68.72377257100004, 26.336344043000054],
            [68.729740191000076, 26.336812009000028],
            [68.737774000000059, 26.337442000000067],
            [68.743919386000073, 26.319328575000043],
            [68.744629000000032, 26.317237000000034],
            [68.775299000000075, 26.33239100000003],
            [68.808854000000053, 26.359452000000033],
            [68.839523000000042, 26.398420000000044],
            [68.842876699000044, 26.405439314000034],
            [68.855038000000036, 26.430893000000026],
            [68.85612100000003, 26.437027000000057],
            [68.856183000000044, 26.437277000000051],
            [68.856183111000064, 26.437276829000041],
            [68.856561000000056, 26.43669600000004],
            [68.864794769000071, 26.441896438000072],
            [68.867226000000073, 26.44343200000003],
            [68.868803309000043, 26.444241920000024],
            [68.887994000000049, 26.45409600000005],
            [68.903250280000066, 26.462334587000043],
            [68.916060000000073, 26.46925200000004],
            [68.926163000000031, 26.462516000000051],
            [68.935144000000037, 26.441186000000073],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "139",
      properties: { name: "Shahdad Kot", count: 1471 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.912922177000041, 28.000781974000063],
            [67.913625000000081, 28.000708001000078],
            [67.913214013000072, 28.000751128000047],
            [67.91321815200007, 28.000739692000025],
            [67.91352100000006, 27.999903000000074],
            [67.921711345000062, 27.992054200000041],
            [67.929348000000061, 27.984736000000055],
            [67.930512767000039, 27.984642787000041],
            [67.942481961000055, 27.983684922000066],
            [67.944123594000075, 27.983553546000053],
            [67.954077000000041, 27.982757000000049],
            [67.962320000000034, 27.98803300000003],
            [67.975819970000032, 27.98555792600007],
            [67.976434164000068, 27.985445320000053],
            [68.00188600000007, 27.980779000000041],
            [68.003089522000039, 27.981409418000055],
            [68.029583000000059, 27.995287000000076],
            [68.042771000000073, 27.994298000000072],
            [68.047257423000076, 27.989363025000046],
            [68.049167261000036, 27.987262243000032],
            [68.052663000000052, 27.983417000000031],
            [68.072628657000052, 27.984406000000035],
            [68.086294000000066, 27.984406000000035],
            [68.09122568500004, 27.978090189000056],
            [68.09292311400003, 27.975916361000031],
            [68.099820127000044, 27.967083634000062],
            [68.099990312000045, 27.966865685000073],
            [68.105089000000078, 27.960336000000041],
            [68.110470547000034, 27.950706000000025],
            [68.111169533000066, 27.949455200000045],
            [68.11761800000005, 27.93791600000003],
            [68.118391313000075, 27.937107299000047],
            [68.122500954000031, 27.93281091800003],
            [68.118642598000065, 27.923017019000042],
            [68.118277000000035, 27.922089000000028],
            [68.129746176000083, 27.908435968000049],
            [68.132126000000028, 27.905603000000042],
            [68.150590000000079, 27.882852000000071],
            [68.157941961000063, 27.876265617000058],
            [68.166416000000083, 27.868674000000055],
            [68.169301085000029, 27.862238507000029],
            [68.174494638000056, 27.850653727000065],
            [68.182243000000028, 27.832405000000051],
            [68.184128018000081, 27.827019061000044],
            [68.186859000000084, 27.81921600000004],
            [68.186678394000069, 27.812178403000075],
            [68.186529000000064, 27.806357000000048],
            [68.185929118000047, 27.805211816000053],
            [68.185817602000043, 27.804998931000057],
            [68.181940699000052, 27.797597861000042],
            [68.179275000000075, 27.792509000000052],
            [68.177112442000066, 27.787495134000039],
            [68.172022000000084, 27.775693000000047],
            [68.171778803000052, 27.773213095000074],
            [68.17177375600005, 27.773161636000054],
            [68.171455076000029, 27.769912020000049],
            [68.170373000000041, 27.758878000000038],
            [68.171096983000041, 27.754896367000072],
            [68.173011000000031, 27.74437000000006],
            [68.187848000000031, 27.732170000000053],
            [68.195761000000061, 27.717992000000038],
            [68.179935000000057, 27.707441000000074],
            [68.176025349000042, 27.704133587000058],
            [68.173131147000049, 27.70168484800007],
            [68.162789000000032, 27.692934000000037],
            [68.159636429000045, 27.690119155000048],
            [68.145883406000053, 27.677839458000051],
            [68.144325000000038, 27.67644800000005],
            [68.136467844000038, 27.676048538000032],
            [68.135558082000045, 27.676002286000028],
            [68.13497768600007, 27.675972778000073],
            [68.133001217000071, 27.675872293000054],
            [68.124872000000039, 27.675459000000046],
            [68.114119809000044, 27.67943249700005],
            [68.109705000000076, 27.681064000000049],
            [68.106854059000057, 27.682259376000047],
            [68.099483000000077, 27.685350000000028],
            [68.097231980000061, 27.680847010000036],
            [68.096846000000028, 27.680075000000045],
            [68.102786526000045, 27.668208271000026],
            [68.10354181200006, 27.666714524000042],
            [68.110364000000061, 27.65336700000006],
            [68.110364000000061, 27.647068613000044],
            [68.110364000000061, 27.641815473000065],
            [68.110364000000061, 27.629957000000047],
            [68.113470154000083, 27.621221971000068],
            [68.115640000000042, 27.615120000000047],
            [68.115640000000042, 27.609544451000033],
            [68.115640000000042, 27.600825666000048],
            [68.115640000000042, 27.597315000000037],
            [68.112159579000036, 27.592634521000036],
            [68.108501599000078, 27.587715262000074],
            [68.108439862000068, 27.587632238000026],
            [68.106078000000082, 27.584456000000046],
            [68.093548000000055, 27.56764000000004],
            [68.083630848000041, 27.563571801000023],
            [68.080689000000064, 27.562365000000057],
            [68.076912715000049, 27.554182492000052],
            [68.072776000000033, 27.545219000000031],
            [68.072710463000078, 27.541748535000067],
            [68.072709413000041, 27.541692907000026],
            [68.072658388000036, 27.538990884000043],
            [68.072652512000047, 27.538679752000064],
            [68.07244600000007, 27.527744000000041],
            [68.068157905000078, 27.526282022000032],
            [68.057939000000033, 27.522798000000023],
            [68.055933508000066, 27.517784366000058],
            [68.05214677400005, 27.508317709000039],
            [68.047388000000069, 27.496421000000055],
            [68.04372733200006, 27.485845462000043],
            [68.042981271000031, 27.483690120000063],
            [68.041453000000047, 27.47927500000003],
            [68.03348008100005, 27.468784938000056],
            [68.028923000000077, 27.462789000000043],
            [68.028989168000066, 27.45988019400005],
            [68.028989922000051, 27.459847048000029],
            [68.02925300000004, 27.448282000000063],
            [68.021875093000062, 27.440166041000055],
            [68.015195920000053, 27.432818715000053],
            [68.015045539000027, 27.432653290000076],
            [68.013820136000049, 27.431305304000034],
            [68.009470000000078, 27.426520000000039],
            [68.002875000000074, 27.411023000000057],
            [68.006264878000081, 27.407412315000045],
            [68.00903846600005, 27.40445806300005],
            [68.010848352000039, 27.402530287000047],
            [68.014235872000029, 27.398922114000072],
            [68.018043000000034, 27.394867000000033],
            [68.03000054000006, 27.387800619000075],
            [68.032550000000072, 27.386294000000078],
            [68.034953457000029, 27.386631359000035],
            [68.036884996000083, 27.386902477000035],
            [68.051344000000029, 27.388932000000068],
            [68.051428503000068, 27.388526373000047],
            [68.053645750000044, 27.377883320000024],
            [68.054641000000061, 27.373106000000064],
            [68.048978349000038, 27.372386855000059],
            [68.047923232000073, 27.372252858000024],
            [68.034235941000077, 27.370514601000025],
            [68.033869000000038, 27.370468000000074],
            [68.018362565000075, 27.370745141000043],
            [68.015405000000044, 27.370798000000036],
            [68.013971110000057, 27.36627624700003],
            [68.012997288000065, 27.363205311000058],
            [68.011118000000067, 27.357279000000062],
            [68.011118000000067, 27.340463000000057],
            [68.005279992000055, 27.319272511000065],
            [68.00501011700004, 27.318292934000056],
            [68.002216000000033, 27.308151000000066],
            [68.000882413000056, 27.304970956000034],
            [67.998577010000076, 27.299473540000065],
            [67.998163637000062, 27.298543562000077],
            [67.97185728200003, 27.301352250000036],
            [67.970786524000061, 27.301466573000027],
            [67.965354776000083, 27.302046512000061],
            [67.947203801000057, 27.303984463000063],
            [67.936892846000035, 27.30509220600004],
            [67.93420517900006, 27.305380951000075],
            [67.928812832000062, 27.305880621000028],
            [67.928389002000074, 27.305919895000045],
            [67.926426048000053, 27.306099242000073],
            [67.924531371000057, 27.306732886000077],
            [67.915485824000029, 27.310681573000068],
            [67.90405336300006, 27.315425774000062],
            [67.894861818000038, 27.31930714300006],
            [67.893841622000082, 27.319623082000078],
            [67.890836634000038, 27.320039819000044],
            [67.888094648000049, 27.320420083000045],
            [67.883002645000033, 27.321092478000025],
            [67.881914368000082, 27.320954171000039],
            [67.880681414000037, 27.320749805000048],
            [67.871089962000042, 27.318268068000066],
            [67.871055339000065, 27.318259110000042],
            [67.869305722000036, 27.317806406000045],
            [67.868528903000083, 27.317613281000035],
            [67.861449093000033, 27.31585317300005],
            [67.86044778400003, 27.315617278000047],
            [67.849534700000049, 27.313046309000072],
            [67.84862243300006, 27.312831392000078],
            [67.842707431000065, 27.311470717000077],
            [67.840607136000074, 27.310869918000037],
            [67.831178383000065, 27.309495095000045],
            [67.826501643000029, 27.30867279000006],
            [67.81733037500004, 27.30820118400004],
            [67.805375689000073, 27.307586448000052],
            [67.799172923000071, 27.307338178000066],
            [67.779346415000077, 27.307036857000071],
            [67.763390583000046, 27.306761695000034],
            [67.756352874000072, 27.30667842400004],
            [67.741094470000064, 27.306497883000077],
            [67.739048865000029, 27.306463318000056],
            [67.733128947000068, 27.306363288000057],
            [67.723325018000082, 27.306209205000073],
            [67.701320580000072, 27.305876915000056],
            [67.68420284900003, 27.305650870000079],
            [67.677494950000039, 27.305626197000038],
            [67.675779059000035, 27.305506528000024],
            [67.674604036000062, 27.305182767000076],
            [67.666748872000028, 27.301402340000038],
            [67.666659710000033, 27.301358699000048],
            [67.663605019000045, 27.301533487000029],
            [67.663235372000031, 27.301585709000051],
            [67.66225178500008, 27.301724666000041],
            [67.657925771000066, 27.302336470000057],
            [67.654726288000063, 27.305083626000055],
            [67.654397096000082, 27.305366279000054],
            [67.654085727000052, 27.305646261000049],
            [67.652917237000054, 27.306696964000025],
            [67.651043480000055, 27.308381695000037],
            [67.649793650000049, 27.309505686000023],
            [67.648739890000058, 27.310838140000044],
            [67.645802471000081, 27.314552436000042],
            [67.64513282300004, 27.315399190000051],
            [67.644638043000043, 27.316068414000028],
            [67.643982364000067, 27.316975807000063],
            [67.642775480000068, 27.318646043000058],
            [67.641954659000078, 27.319782223000061],
            [67.640890959000046, 27.321254635000059],
            [67.639841182000055, 27.321837979000065],
            [67.636861060000058, 27.323495946000037],
            [67.635977634000028, 27.323987283000065],
            [67.635211740000045, 27.324076193000053],
            [67.634776784000053, 27.324126686000056],
            [67.633392757000081, 27.324287312000024],
            [67.632400340000061, 27.324402461000034],
            [67.631564372000071, 27.324238241000046],
            [67.627819185000078, 27.323501774000079],
            [67.626520431000074, 27.323245988000053],
            [67.625158067000029, 27.32214607700007],
            [67.623823903000073, 27.32104786900004],
            [67.621873608000044, 27.319442768000044],
            [67.620257219000052, 27.31838181300003],
            [67.617688531000056, 27.316695795000044],
            [67.61705859500006, 27.316282321000074],
            [67.616204783000057, 27.315721902000064],
            [67.614698201000067, 27.315178963000051],
            [67.613570106000054, 27.314772445000074],
            [67.608468876000074, 27.312934117000054],
            [67.608267883000053, 27.31286168500003],
            [67.606650848000072, 27.312278955000068],
            [67.604919475000031, 27.312138177000065],
            [67.597559925000041, 27.31153977200006],
            [67.596260418000043, 27.311434109000061],
            [67.594507332000035, 27.311559202000069],
            [67.585742412000059, 27.312184634000062],
            [67.585304824000048, 27.312215859000048],
            [67.582704952000029, 27.312400928000045],
            [67.577494518000037, 27.312771827000063],
            [67.575761643000078, 27.312895180000055],
            [67.574307881000038, 27.313312014000076],
            [67.571204127000044, 27.314201947000072],
            [67.56986900000004, 27.314584765000063],
            [67.568204947000083, 27.315380048000065],
            [67.565924173000042, 27.316470176000053],
            [67.564408749000052, 27.317194326000049],
            [67.564249794000034, 27.31722366200006],
            [67.561609059000034, 27.317711022000026],
            [67.560618052000052, 27.317713415000071],
            [67.559293314000058, 27.317716614000062],
            [67.557914463000031, 27.317719873000044],
            [67.556989208000061, 27.317722103000051],
            [67.556109547000062, 27.317612062000023],
            [67.549486654000077, 27.316783574000056],
            [67.547278250000033, 27.316507315000024],
            [67.545561945000031, 27.316364675000045],
            [67.54245759500003, 27.316106665000063],
            [67.541692504000082, 27.316043076000028],
            [67.540839185000038, 27.315972155000054],
            [67.540409841000042, 27.315936470000054],
            [67.539036294000084, 27.315822252000032],
            [67.536899853000079, 27.315521234000073],
            [67.533053855000048, 27.314979244000028],
            [67.531772455000066, 27.314798665000069],
            [67.530942807000031, 27.314681749000044],
            [67.529636521000043, 27.314497663000054],
            [67.528191340000035, 27.314552569000057],
            [67.522582699000054, 27.314765799000043],
            [67.513930522000067, 27.316300556000044],
            [67.512984571000061, 27.316468353000062],
            [67.510574414000075, 27.316902299000049],
            [67.509471783000038, 27.317166340000028],
            [67.508951952000075, 27.317290592000063],
            [67.508536109000033, 27.317391397000051],
            [67.508163119000073, 27.317481815000065],
            [67.502881353000078, 27.318762179000032],
            [67.502139709000062, 27.318903567000064],
            [67.500459893000084, 27.319235284000058],
            [67.498742640000046, 27.320760386000075],
            [67.497872947000076, 27.322268325000039],
            [67.497140496000043, 27.323538304000067],
            [67.496147050000047, 27.324681268000063],
            [67.495681757000057, 27.325216590000025],
            [67.493347530000051, 27.327902128000062],
            [67.492557916000067, 27.328804559000048],
            [67.491750858000046, 27.329686096000046],
            [67.491090975000077, 27.33040687600004],
            [67.489876462000041, 27.331196977000047],
            [67.488617177000037, 27.33201629000007],
            [67.486260104000053, 27.333769001000064],
            [67.482578000000046, 27.33650700000004],
            [67.481776569000033, 27.337016007000045],
            [67.480850431000079, 27.337546109000073],
            [67.479858522000029, 27.338113802000066],
            [67.478864859000055, 27.338682609000045],
            [67.477999580000073, 27.339064994000069],
            [67.474631431000034, 27.340553453000041],
            [67.473091322000073, 27.341234060000033],
            [67.468873580000036, 27.341955905000077],
            [67.467702220000035, 27.342156382000042],
            [67.466948985000045, 27.342285290000063],
            [67.464464255000053, 27.343245782000054],
            [67.464042146000054, 27.343963226000028],
            [67.463620613000046, 27.344679691000067],
            [67.462091446000045, 27.34727876200003],
            [67.461501361000046, 27.348283346000073],
            [67.460602270000038, 27.350259858000072],
            [67.460471494000046, 27.350547349000067],
            [67.460245875000055, 27.351043338000068],
            [67.459854323000059, 27.351904105000074],
            [67.458842880000077, 27.352719499000045],
            [67.457202661000053, 27.354041793000079],
            [67.456191581000041, 27.354856895000069],
            [67.455011222000053, 27.354980803000046],
            [67.452613609000082, 27.355232491000038],
            [67.450697552000065, 27.355276685000035],
            [67.449199732000068, 27.355311232000076],
            [67.446761424000044, 27.356025617000057],
            [67.442239270000073, 27.356396228000051],
            [67.441945429000043, 27.356457459000069],
            [67.439486554000041, 27.356969844000048],
            [67.43818613600007, 27.357244684000079],
            [67.436577940000063, 27.359011783000028],
            [67.43591168100005, 27.360769839000056],
            [67.435361735000072, 27.362238276000028],
            [67.435180918000071, 27.362823805000062],
            [67.435118149000061, 27.363027067000075],
            [67.434472306000032, 27.365107497000054],
            [67.432756257000051, 27.36594640900006],
            [67.42962986200007, 27.367474789000028],
            [67.426212622000037, 27.369145351000043],
            [67.425817519000077, 27.369339205000074],
            [67.423994251000067, 27.37028123500005],
            [67.421803165000028, 27.371413307000068],
            [67.419841813000062, 27.372426761000042],
            [67.418665557000054, 27.373034491000055],
            [67.417489114000034, 27.37364225500005],
            [67.415781711000079, 27.37431194100003],
            [67.413473585000077, 27.375217243000066],
            [67.411164565000036, 27.376199315000065],
            [67.408469689000071, 27.377345500000047],
            [67.407317470000066, 27.377835609000044],
            [67.406550032000041, 27.378162026000041],
            [67.405015843000058, 27.378814491000071],
            [67.403763150000032, 27.379159609000055],
            [67.398762164000061, 27.380537385000025],
            [67.397094805000052, 27.380996743000026],
            [67.395382742000038, 27.381362271000057],
            [67.389390997000078, 27.382641517000025],
            [67.388107595000065, 27.382915525000044],
            [67.386775887000056, 27.383017525000071],
            [67.383276738000063, 27.383285538000052],
            [67.382387837000067, 27.383352444000025],
            [67.381581065000034, 27.383316145000038],
            [67.377271208000082, 27.383120917000042],
            [67.375772728000072, 27.38305321100006],
            [67.374543274000075, 27.382394598000076],
            [67.372050331000082, 27.381045009000047],
            [67.370090710000056, 27.379640766000023],
            [67.369390192000083, 27.37913607400003],
            [67.368688309000049, 27.378297191000058],
            [67.36728056700008, 27.376409528000067],
            [67.366551579000031, 27.375431136000032],
            [67.366187000000082, 27.374221104000071],
            [67.366178608000041, 27.374193251000065],
            [67.364576846000034, 27.368846965000046],
            [67.36407459000003, 27.367168664000076],
            [67.364069482000048, 27.36685307700003],
            [67.36405009200007, 27.365655232000051],
            [67.36402060000006, 27.36290325300007],
            [67.363166962000037, 27.360586987000033],
            [67.362967684000068, 27.35981565700007],
            [67.362595667000051, 27.359458600000039],
            [67.361517888000037, 27.357963282000071],
            [67.359618703000081, 27.357452020000039],
            [67.358333705000064, 27.357115323000073],
            [67.357612872000061, 27.356926443000077],
            [67.357036162000043, 27.356889147000061],
            [67.352364484000077, 27.35658703200005],
            [67.351725066000029, 27.356657244000075],
            [67.350549324000042, 27.356868507000058],
            [67.348918593000064, 27.357161674000054],
            [67.348541843000078, 27.358942393000063],
            [67.348298256000078, 27.360097482000072],
            [67.348111834000065, 27.361648362000039],
            [67.348082986000065, 27.361889264000069],
            [67.347871755000028, 27.36365317800005],
            [67.347749793000048, 27.364684706000048],
            [67.347496297000077, 27.366828737000048],
            [67.347358049000036, 27.367998017000048],
            [67.347094174000063, 27.368803099000047],
            [67.346525721000035, 27.370542002000036],
            [67.346066777000033, 27.371943787000077],
            [67.345119898000064, 27.372707126000023],
            [67.34256643100008, 27.374763625000071],
            [67.341672875000029, 27.375446890000035],
            [67.340662411000039, 27.375596944000051],
            [67.34020855600005, 27.375664341000061],
            [67.33961533300004, 27.375752423000051],
            [67.33838546700008, 27.375935008000056],
            [67.337120111000047, 27.375539651000054],
            [67.335394415000053, 27.375000463000049],
            [67.332911505000084, 27.37406190300004],
            [67.331375817000037, 27.37348140000006],
            [67.329805920000069, 27.372832165000034],
            [67.325684242000079, 27.371127842000078],
            [67.323202965000064, 27.37010179400005],
            [67.317528383000081, 27.367511126000068],
            [67.316452019000053, 27.367019725000034],
            [67.314248162000069, 27.36713573000003],
            [67.312826055000073, 27.369450653000058],
            [67.311440644000072, 27.372671083000057],
            [67.310145429000045, 27.374857102000078],
            [67.309615304000033, 27.375751828000034],
            [67.309188909000056, 27.376388698000028],
            [67.307535273000042, 27.378858591000039],
            [67.306951720000029, 27.379730193000057],
            [67.306784322000055, 27.379913113000043],
            [67.30638940800003, 27.380344643000058],
            [67.304662030000031, 27.382233254000028],
            [67.303801916000054, 27.383173520000071],
            [67.302802410000083, 27.383892940000067],
            [67.299489639000058, 27.386277389000043],
            [67.298409382000045, 27.387054931000023],
            [67.297158695000064, 27.387539679000042],
            [67.29257949600003, 27.389314510000077],
            [67.292366089000041, 27.389397224000049],
            [67.291328784000029, 27.389799269000036],
            [67.289577594000036, 27.390196839000055],
            [67.285636547000081, 27.391091571000061],
            [67.284761378000042, 27.391290259000073],
            [67.284327614000063, 27.391377638000051],
            [67.282162711000069, 27.391813744000046],
            [67.277400121000028, 27.392773136000073],
            [67.27653377300004, 27.392947657000036],
            [67.275693961000059, 27.393039900000076],
            [67.272735423000029, 27.39336529600007],
            [67.270971793000058, 27.393556840000031],
            [67.267406642000083, 27.393216621000079],
            [67.264305427000068, 27.392920667000055],
            [67.262784977000081, 27.394198294000034],
            [67.261870613000042, 27.394966489000069],
            [67.26147489300007, 27.39520903600004],
            [67.257451149000076, 27.397674301000052],
            [67.256354460000068, 27.39834624100007],
            [67.255157763000057, 27.398833490000072],
            [67.252479136000034, 27.399924122000073],
            [67.251173412000071, 27.400455762000036],
            [67.24931543200006, 27.40089714100003],
            [67.248444795000069, 27.401103977000048],
            [67.246285252000064, 27.401616987000068],
            [67.244259513000031, 27.402082107000069],
            [67.242998538000052, 27.402371768000023],
            [67.241780429000073, 27.402651683000045],
            [67.239453214000036, 27.404544315000066],
            [67.239138810000043, 27.406163469000035],
            [67.238869627000042, 27.407547431000069],
            [67.238739029000044, 27.408218881000039],
            [67.23851449600005, 27.409409202000063],
            [67.23836302400008, 27.410212535000028],
            [67.23798490300004, 27.412215113000059],
            [67.237268110000059, 27.413347932000079],
            [67.236562223000078, 27.414461623000079],
            [67.235846368000068, 27.415592489000062],
            [67.235126836000063, 27.416109223000035],
            [67.234460307000063, 27.416588001000036],
            [67.234013904000051, 27.416908589000059],
            [67.233445681000035, 27.417316550000066],
            [67.233070737000048, 27.417530398000054],
            [67.228830161000076, 27.419948998000052],
            [67.22590017400006, 27.421554842000035],
            [67.224602191000031, 27.422266219000051],
            [67.223378449000052, 27.422658531000025],
            [67.220056014000079, 27.423677792000035],
            [67.219697309000082, 27.423787836000031],
            [67.218466052000053, 27.424165630000061],
            [67.216703306000056, 27.424346434000029],
            [67.213434182000071, 27.424681747000079],
            [67.20708705900006, 27.425332769000079],
            [67.20533726900004, 27.42551224400006],
            [67.204001176000077, 27.426454650000039],
            [67.203175223000073, 27.427037231000043],
            [67.200455655000042, 27.42895549900004],
            [67.199501527000052, 27.429628452000031],
            [67.198285061000036, 27.430846161000034],
            [67.195532947000061, 27.433601084000031],
            [67.194636449000029, 27.434409472000027],
            [67.192901575000064, 27.435973837000063],
            [67.192575541000053, 27.436267827000052],
            [67.191587996000067, 27.437158313000054],
            [67.190442395000048, 27.437858463000055],
            [67.190059596000083, 27.438092416000075],
            [67.188919824000038, 27.438789013000076],
            [67.18356714600003, 27.442060422000054],
            [67.175163155000064, 27.447196911000049],
            [67.17398380000003, 27.447917647000054],
            [67.173983845000066, 27.447918082000058],
            [67.175489965000054, 27.462413105000053],
            [67.176244000000054, 27.469670000000065],
            [67.177822000000049, 27.519780000000026],
            [67.183640644000036, 27.53570385300003],
            [67.185095069000056, 27.539684172000079],
            [67.185318706000032, 27.540296196000043],
            [67.18531900000005, 27.540297000000066],
            [67.18591426100005, 27.540780684000026],
            [67.204258000000038, 27.555686000000037],
            [67.210134408000044, 27.559643320000077],
            [67.223592000000053, 27.568706000000077],
            [67.230694000000028, 27.58409400000005],
            [67.237402000000031, 27.609347000000071],
            [67.246477000000084, 27.646436000000051],
            [67.250220992000038, 27.654423773000076],
            [67.250260728000057, 27.654508548000024],
            [67.252395000000035, 27.659062000000063],
            [67.280409000000077, 27.681947000000036],
            [67.304543179000063, 27.697918729000037],
            [67.330914000000064, 27.719431000000043],
            [67.344724000000042, 27.740343000000053],
            [67.354983000000061, 27.757704000000047],
            [67.358139000000051, 27.780194000000051],
            [67.358139000000051, 27.803868000000023],
            [67.368003000000044, 27.836617000000047],
            [67.380697616000077, 27.862301020000075],
            [67.380730928000048, 27.862368418000074],
            [67.384970000000067, 27.870945000000063],
            [67.390888000000075, 27.895013000000063],
            [67.392467000000067, 27.911191000000031],
            [67.397556026000075, 27.912321881000025],
            [67.399569000000042, 27.912769000000026],
            [67.400452130000076, 27.912304498000026],
            [67.411499023000033, 27.906494141000053],
            [67.420104980000076, 27.895080566000047],
            [67.423038241000029, 27.895135030000063],
            [67.443115234000061, 27.895507812000062],
            [67.490071192000073, 27.901484025000059],
            [67.490077402000054, 27.901455617000067],
            [67.501367000000073, 27.902905000000032],
            [67.532932000000073, 27.912374000000057],
            [67.533918527000083, 27.911777691000054],
            [67.543712000000028, 27.905858000000023],
            [67.545761539000068, 27.906416990000025],
            [67.550717379000048, 27.907768644000043],
            [67.58825239500004, 27.918005924000056],
            [67.595879000000082, 27.920086000000026],
            [67.613047392000055, 27.924536846000024],
            [67.623886054000081, 27.927346731000057],
            [67.659903000000043, 27.936684000000071],
            [67.668773972000054, 27.937222836000046],
            [67.671978093000064, 27.937417459000073],
            [67.671978139000032, 27.937419008000063],
            [67.67620682300003, 27.937675125000055],
            [67.676206537000041, 27.93767366000003],
            [67.721113295000066, 27.940394563000041],
            [67.732628387000034, 27.94109226300003],
            [67.732628814000066, 27.941092290000029],
            [67.738153000000068, 27.941427000000033],
            [67.747541112000079, 27.943304623000074],
            [67.779239512000061, 27.949644303000071],
            [67.781292447000055, 27.950054890000047],
            [67.785578000000044, 27.950912000000073],
            [67.787871884000083, 27.980735393000032],
            [67.787919362000082, 27.981352666000078],
            [67.787937678000048, 27.981590800000049],
            [67.787949000000083, 27.981738000000064],
            [67.804548000000068, 27.988851000000068],
            [67.804607351000072, 27.988855947000047],
            [67.807681951000063, 27.989112245000058],
            [67.810343223000075, 27.989334088000078],
            [67.812022884000044, 27.989474104000067],
            [67.816435925000064, 27.989841974000058],
            [67.833003000000076, 27.991223000000048],
            [67.83312430400008, 27.991271521000044],
            [67.833162513000048, 27.991286804000026],
            [67.83442893800003, 27.991793367000071],
            [67.838677951000079, 27.993492949000029],
            [67.858382000000063, 28.001374457000054],
            [67.86743361200007, 28.004995051000037],
            [67.868571000000031, 28.005450000000053],
            [67.868595780000078, 28.005447392000065],
            [67.88443815200003, 28.003779959000042],
            [67.899973841000076, 28.002144805000057],
            [67.904436910000072, 28.001675060000025],
            [67.91067445300007, 28.001018550000026],
            [67.912922177000041, 28.000781974000063],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "140",
      properties: { name: "Shaheed Benazirabad", count: 1233 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.599886359000038, 26.600716671000043],
            [68.629231000000061, 26.597230000000025],
            [68.656892776000063, 26.597230000000025],
            [68.689291000000082, 26.597230000000025],
            [68.752999802000033, 26.585723503000054],
            [68.776855000000069, 26.580952000000025],
            [68.786643593000065, 26.571636158000047],
            [68.808850000000064, 26.562429000000066],
            [68.820637000000033, 26.542783000000043],
            [68.832320827000046, 26.51463641600003],
            [68.83592657500003, 26.507027965000077],
            [68.836379511000075, 26.506085177000045],
            [68.847000065000032, 26.484147105000034],
            [68.84702109400007, 26.484102245000031],
            [68.853193000000033, 26.470936000000052],
            [68.849264000000062, 26.447922000000062],
            [68.856183183000041, 26.437277119000044],
            [68.856183111000064, 26.437276829000041],
            [68.856183000000044, 26.437277000000051],
            [68.85612100000003, 26.437027000000057],
            [68.855038000000036, 26.430893000000026],
            [68.842876699000044, 26.405439314000034],
            [68.839523000000042, 26.398420000000044],
            [68.808854000000053, 26.359452000000033],
            [68.775299000000075, 26.33239100000003],
            [68.744629000000032, 26.317237000000034],
            [68.743919386000073, 26.319328575000043],
            [68.737774000000059, 26.337442000000067],
            [68.729740191000076, 26.336812009000028],
            [68.72377257100004, 26.336344043000054],
            [68.700971000000038, 26.334556000000077],
            [68.696641000000056, 26.328422000000046],
            [68.695929586000034, 26.323174394000034],
            [68.694528479000041, 26.312839411000027],
            [68.693755000000067, 26.307134000000076],
            [68.688962959000037, 26.304430815000046],
            [68.688959564000072, 26.304428900000062],
            [68.679683000000068, 26.299196000000052],
            [68.662003000000084, 26.298835000000054],
            [68.656528224000056, 26.298835000000054],
            [68.641076000000055, 26.298835000000054],
            [68.638889686000084, 26.297117197000034],
            [68.637025554000047, 26.295652535000045],
            [68.63097300000004, 26.290897000000029],
            [68.632579510000028, 26.278686988000061],
            [68.632777000000033, 26.277186000000029],
            [68.634392501000036, 26.274580399000058],
            [68.637126440000031, 26.27017089900005],
            [68.641947948000052, 26.262394413000038],
            [68.643962000000045, 26.259146000000044],
            [68.646868267000059, 26.254184287000044],
            [68.652967232000037, 26.243771853000055],
            [68.658756000000039, 26.233889000000033],
            [68.659281738000061, 26.232500981000044],
            [68.659307826000031, 26.232432105000044],
            [68.664984196000034, 26.217445733000034],
            [68.666061099000046, 26.214602567000043],
            [68.66777600000006, 26.210075000000074],
            [68.666994055000032, 26.208459036000079],
            [68.666698247000056, 26.207847721000064],
            [68.666698121000081, 26.207847702000038],
            [68.664853000000051, 26.204035000000033],
            [68.66331924900004, 26.200865002000057],
            [68.663319430000058, 26.200865072000056],
            [68.656952000000047, 26.187705000000051],
            [68.653737293000063, 26.183471165000071],
            [68.644667444000049, 26.171525990000077],
            [68.642158000000052, 26.168221000000074],
            [68.642069751000065, 26.168344551000075],
            [68.636270058000036, 26.176464238000051],
            [68.63488742800007, 26.178399947000059],
            [68.626702548000083, 26.189858945000026],
            [68.622314000000074, 26.196003000000076],
            [68.612908413000071, 26.185607784000069],
            [68.612796372000048, 26.185483955000052],
            [68.611153364000074, 26.183668074000025],
            [68.601747000000046, 26.173272000000054],
            [68.584713660000034, 26.179951856000059],
            [68.584605560000057, 26.17999424900006],
            [68.582800860000077, 26.174262691000024],
            [68.582123395000053, 26.174113494000039],
            [68.582997553000041, 26.172140692000028],
            [68.583208249000052, 26.171665136000058],
            [68.580488069000069, 26.16880913600005],
            [68.580418774000066, 26.168736383000066],
            [68.579947272000084, 26.168241354000031],
            [68.579137463000052, 26.166439248000074],
            [68.575687624000068, 26.161726821000059],
            [68.575479416000064, 26.161340556000027],
            [68.574715692000041, 26.161432105000074],
            [68.57247433100008, 26.162712024000029],
            [68.56562028500008, 26.165760545000069],
            [68.55953866200008, 26.167831448000072],
            [68.555335362000051, 26.168693242000074],
            [68.551855292000027, 26.168083067000055],
            [68.546574937000059, 26.165517302000069],
            [68.542521943000054, 26.162358788000063],
            [68.537441614000045, 26.160061764000034],
            [68.530905840000059, 26.157046100000059],
            [68.526511241000037, 26.155756557000075],
            [68.525816697000039, 26.155141289000028],
            [68.525603609000029, 26.156104678000077],
            [68.524509137000052, 26.156490212000051],
            [68.518467515000054, 26.159871363000036],
            [68.515169052000033, 26.16103441000007],
            [68.511113505000083, 26.162350298000035],
            [68.505698050000035, 26.165767589000041],
            [68.50526336300004, 26.166041886000073],
            [68.499899994000032, 26.162582336000071],
            [68.493021723000084, 26.158228628000074],
            [68.48771352600005, 26.155398658000024],
            [68.486283246000028, 26.155005159000041],
            [68.486600598000052, 26.154852503000029],
            [68.488661878000073, 26.15386092500006],
            [68.494903482000041, 26.151114758000062],
            [68.500583409000058, 26.149998570000037],
            [68.499704456000075, 26.147982900000045],
            [68.497235243000034, 26.144422682000027],
            [68.490974431000041, 26.136353436000036],
            [68.487270950000038, 26.13114444100006],
            [68.486005505000037, 26.130539116000023],
            [68.483211175000065, 26.130223541000078],
            [68.481035059000078, 26.130128339000066],
            [68.480530439000063, 26.129376371000035],
            [68.478559082000061, 26.126696000000038],
            [68.476923747000058, 26.124967225000034],
            [68.473148980000076, 26.123467486000038],
            [68.469701532000045, 26.121561113000041],
            [68.466435150000052, 26.118252400000074],
            [68.463384649000034, 26.116430832000049],
            [68.46043700000007, 26.115144311000051],
            [68.453192225000066, 26.113897549000058],
            [68.453782766000074, 26.112716467000041],
            [68.468246000000079, 26.083790000000079],
            [68.468246000000079, 26.071385660000033],
            [68.468246000000079, 26.070079000000078],
            [68.455257000000074, 26.062502000000052],
            [68.441907000000072, 26.06286300000005],
            [68.434636007000051, 26.058475990000034],
            [68.434635971000034, 26.058475967000049],
            [68.434635560000061, 26.058475707000071],
            [68.429543000000081, 26.066114000000027],
            [68.425256055000034, 26.068934414000068],
            [68.413255000000049, 26.076830000000029],
            [68.409694265000041, 26.079635542000062],
            [68.407481949000044, 26.081378650000033],
            [68.399110000000064, 26.087975000000029],
            [68.390041229000076, 26.091049202000079],
            [68.384011760000078, 26.09309311800007],
            [68.37382000000008, 26.096548000000041],
            [68.363104000000078, 26.092690000000061],
            [68.359716481000078, 26.089569922000067],
            [68.35521781500006, 26.085426421000022],
            [68.352164383000058, 26.082614055000079],
            [68.346815000000049, 26.077687000000026],
            [68.339662652000072, 26.067959980000069],
            [68.325383000000045, 26.04854000000006],
            [68.32029545100005, 26.037539859000049],
            [68.320203769000045, 26.037341627000046],
            [68.30952300000007, 26.014248000000066],
            [68.304037813000036, 26.011094138000033],
            [68.301778785000067, 26.009795245000078],
            [68.292378000000042, 26.004390000000058],
            [68.276984946000084, 25.995153593000055],
            [68.270946000000038, 25.991530000000068],
            [68.255085818000055, 25.986104723000039],
            [68.254657000000066, 25.986387000000036],
            [68.245227000000057, 26.000960000000077],
            [68.18007300000005, 25.999246000000028],
            [68.164132504000065, 26.015383227000029],
            [68.133868548000066, 26.046020688000056],
            [68.11725262300007, 26.062841680000076],
            [68.110633000000064, 26.069543000000067],
            [68.082444266000039, 26.095596903000057],
            [68.080170328000065, 26.097698628000046],
            [68.07981462500004, 26.098027393000052],
            [68.054053000000067, 26.121838000000025],
            [68.053362693000054, 26.12255553600005],
            [68.040423538000084, 26.13600507700005],
            [68.033614956000065, 26.143082223000079],
            [68.021476000000064, 26.155700000000024],
            [68.021076677000053, 26.161048950000065],
            [68.020811828000035, 26.16459661500005],
            [68.019332000000077, 26.184419000000048],
            [68.01834954800006, 26.187476140000058],
            [68.015475000000038, 26.196421000000043],
            [67.98889900000006, 26.203280000000063],
            [67.968312362000063, 26.217984415000046],
            [67.952893000000074, 26.228998000000047],
            [67.915601000000038, 26.245715000000075],
            [67.908093629000064, 26.263432843000032],
            [67.904885000000036, 26.271005000000059],
            [67.904028000000039, 26.291580000000067],
            [67.884052359000066, 26.295023633000028],
            [67.879166000000055, 26.295866000000046],
            [67.87798970800003, 26.29953379400007],
            [67.871880000000033, 26.318584000000044],
            [67.873304371000074, 26.326240462000044],
            [67.875309000000072, 26.337016000000062],
            [67.879170988000055, 26.344311479000055],
            [67.879234642000029, 26.34443172400006],
            [67.883024000000034, 26.351590000000044],
            [67.891597000000047, 26.365735000000029],
            [67.892513567000037, 26.369231623000076],
            [67.892852275000052, 26.370523768000055],
            [67.898049042000082, 26.383293852000065],
            [67.902329395000038, 26.381158395000057],
            [67.908953395000083, 26.378026395000063],
            [67.913928395000028, 26.374882395000043],
            [67.914183297000079, 26.374835242000074],
            [67.914485369000033, 26.374779364000062],
            [67.917231395000044, 26.374271395000051],
            [67.91888339500008, 26.373859395000068],
            [67.920057161000045, 26.374458516000061],
            [67.920993395000039, 26.374936395000077],
            [67.922402395000063, 26.375371395000059],
            [67.925710395000067, 26.374123395000026],
            [67.927355395000063, 26.374559395000063],
            [67.927092395000045, 26.377527395000072],
            [67.926941536000072, 26.377842777000069],
            [67.925171395000064, 26.381543395000051],
            [67.923076193000043, 26.385292544000038],
            [67.922484435000058, 26.386351435000051],
            [67.923155435000069, 26.39017543500006],
            [67.924068435000038, 26.393363435000026],
            [67.925011400000074, 26.394598725000037],
            [67.925697435000075, 26.395497435000038],
            [67.925675435000073, 26.39783043500006],
            [67.924633028000073, 26.398676658000056],
            [67.922827435000045, 26.400142435000078],
            [67.91809343500006, 26.40265243500005],
            [67.913126435000038, 26.404736435000075],
            [67.90720443500004, 26.408297435000065],
            [67.900807435000047, 26.412066435000042],
            [67.898665435000055, 26.414383435000047],
            [67.897934435000082, 26.416923435000058],
            [67.89796143500007, 26.420589435000068],
            [67.898200435000035, 26.424580435000053],
            [67.897303049000072, 26.426031244000058],
            [67.896512435000034, 26.427309435000041],
            [67.89187438700003, 26.428588987000069],
            [67.891238435000048, 26.428764435000062],
            [67.885958435000077, 26.430717435000076],
            [67.882206435000057, 26.432131435000031],
            [67.879776435000053, 26.434281435000059],
            [67.878929484000082, 26.435450368000033],
            [67.910029000000065, 26.469038000000069],
            [67.920316000000071, 26.485326000000043],
            [67.920316000000071, 26.497347457000046],
            [67.920316000000071, 26.501848668000036],
            [67.920316000000071, 26.503758000000062],
            [67.920014652000077, 26.505102547000035],
            [67.919377659000077, 26.507944666000071],
            [67.91527132300007, 26.526266203000034],
            [67.915018675000056, 26.527393464000056],
            [67.914744000000042, 26.528619000000049],
            [67.914513560000046, 26.529416578000053],
            [67.912319699000079, 26.537009762000025],
            [67.909279021000032, 26.547533860000044],
            [67.921708974000069, 26.545854422000048],
            [67.923666000000082, 26.545590000000061],
            [67.937138000000061, 26.554571000000067],
            [67.937494422000043, 26.555413506000036],
            [67.943312000000049, 26.569165000000055],
            [67.946411099000045, 26.572559364000028],
            [67.955099000000075, 26.582075000000032],
            [67.959793816000058, 26.581440480000026],
            [67.975868000000048, 26.57926800000007],
            [67.99888100000004, 26.571971000000076],
            [68.008985000000052, 26.587688000000071],
            [68.031437000000039, 26.603966000000071],
            [68.053549590000046, 26.587160557000061],
            [68.056874089000075, 26.58463395800004],
            [68.059503000000063, 26.582636000000036],
            [68.081266889000062, 26.575562639000054],
            [68.08195500000005, 26.575339000000042],
            [68.103228816000069, 26.577309097000068],
            [68.112266000000034, 26.578146000000061],
            [68.134268832000032, 26.592099265000058],
            [68.135279000000082, 26.592740000000049],
            [68.137006891000055, 26.59308556600007],
            [68.145905156000083, 26.594865155000036],
            [68.163345000000049, 26.598353000000031],
            [68.183403190000035, 26.59749331200004],
            [68.186382907000052, 26.597365602000025],
            [68.202636000000041, 26.596669000000077],
            [68.227895000000046, 26.598353000000031],
            [68.230874853000046, 26.601332853000031],
            [68.24080500000008, 26.611263000000065],
            [68.262042659000031, 26.619226295000033],
            [68.263258000000064, 26.619682000000068],
            [68.275581113000044, 26.621471224000061],
            [68.284518364000064, 26.622768433000033],
            [68.29805900000008, 26.624734000000046],
            [68.305874472000028, 26.625648666000075],
            [68.306695534000028, 26.625744758000053],
            [68.319979683000042, 26.627299438000023],
            [68.350822000000051, 26.630909000000031],
            [68.357186973000069, 26.630419650000078],
            [68.365349222000077, 26.629791803000046],
            [68.371558337000067, 26.629314086000079],
            [68.373844127000041, 26.629138222000051],
            [68.381324515000074, 26.628562696000074],
            [68.381677471000046, 26.628535541000076],
            [68.38589201700006, 26.628211282000052],
            [68.394604000000072, 26.627541000000065],
            [68.407654295000043, 26.625574347000054],
            [68.435580000000073, 26.621366000000023],
            [68.457565512000031, 26.618547383000077],
            [68.479362000000037, 26.615753000000041],
            [68.52595000000008, 26.615192000000036],
            [68.546800218000044, 26.610167970000077],
            [68.560635197000067, 26.60683431800004],
            [68.568422801000054, 26.604957832000025],
            [68.572539000000063, 26.603966000000071],
            [68.576393997000082, 26.603507973000035],
            [68.599694956000064, 26.600739413000042],
            [68.599886359000038, 26.600716671000043],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "141",
      properties: { name: "Shikarpur", count: 657 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.567678114000046, 28.156567837000068],
            [68.575928000000033, 28.154871000000071],
            [68.577741769000056, 28.155014539000035],
            [68.597193626000035, 28.156553931000076],
            [68.600442504000057, 28.156811043000062],
            [68.621759000000054, 28.158498000000066],
            [68.627262325000061, 28.157853986000077],
            [68.652753000000075, 28.154871000000071],
            [68.655977977000077, 28.15333920300003],
            [68.669788211000082, 28.146779630000026],
            [68.669852325000079, 28.146749177000061],
            [68.679131000000041, 28.142342000000042],
            [68.699573000000044, 28.133769000000029],
            [68.712218574000076, 28.132302734000064],
            [68.712295293000068, 28.132293838000066],
            [68.718323610000084, 28.131594849000066],
            [68.718421973000034, 28.131583444000057],
            [68.722324000000071, 28.131131000000039],
            [68.737465069000052, 28.130802063000033],
            [68.752658000000054, 28.130472000000054],
            [68.771244115000059, 28.121416707000037],
            [68.777666054000065, 28.118287891000023],
            [68.779179904000046, 28.117666393000036],
            [68.784464221000064, 28.115854738000053],
            [68.788186174000032, 28.114578718000075],
            [68.801457000000028, 28.110029000000054],
            [68.821240000000046, 28.107062000000042],
            [68.835640955000031, 28.102144679000048],
            [68.835893146000046, 28.102058566000039],
            [68.843416915000034, 28.099489515000073],
            [68.848277000000053, 28.097830000000044],
            [68.854806258000053, 28.09604095800006],
            [68.861955745000046, 28.094081970000047],
            [68.87234600000005, 28.09123500000004],
            [68.881249000000082, 28.083322000000067],
            [68.886810359000037, 28.086189650000051],
            [68.897840839000082, 28.091877388000057],
            [68.902351000000067, 28.09420300000005],
            [68.920815000000061, 28.097170000000062],
            [68.933597750000047, 28.097327500000063],
            [68.935816000000045, 28.096218000000079],
            [68.941531549000047, 28.094993432000024],
            [68.957006000000035, 28.091678000000059],
            [68.968853734000049, 28.092270171000052],
            [68.987277000000063, 28.093191000000047],
            [68.999792109000055, 28.089714351000055],
            [69.010628213000075, 28.086704123000061],
            [69.014520000000061, 28.085623000000055],
            [69.032037879000029, 28.066582396000058],
            [69.032054642000048, 28.066564176000043],
            [69.037091933000056, 28.061089023000079],
            [69.049332000000049, 28.047785000000033],
            [69.05009956300006, 28.046195171000079],
            [69.054998599000044, 28.03604795900003],
            [69.058684352000057, 28.028413687000068],
            [69.057649000000083, 28.028259000000048],
            [69.055429296000057, 28.027417035000042],
            [69.048087000000066, 28.024632000000054],
            [69.040833000000077, 28.018367000000069],
            [69.04047405600005, 28.017768784000054],
            [69.036515629000064, 28.011171672000046],
            [69.030941000000041, 28.001881000000026],
            [69.016104000000041, 27.981768000000045],
            [68.998299000000031, 27.980120000000056],
            [68.980824000000041, 27.980449000000078],
            [68.979749272000049, 27.979873807000047],
            [68.96684730100003, 27.972968690000073],
            [68.957414000000028, 27.967920000000049],
            [68.957084000000066, 27.960996000000023],
            [68.951692061000074, 27.956713714000045],
            [68.946909603000051, 27.952915478000023],
            [68.94587400000006, 27.952093000000048],
            [68.930377000000078, 27.946159000000023],
            [68.926618576000067, 27.944627767000043],
            [68.912572000000068, 27.938905000000034],
            [68.900702000000081, 27.939564000000075],
            [68.897405000000049, 27.924397000000056],
            [68.895756000000063, 27.910219000000041],
            [68.88423092000005, 27.900250878000065],
            [68.884211329000038, 27.900233934000028],
            [68.883557000000053, 27.899668000000077],
            [68.87677405800008, 27.898624626000071],
            [68.876713961000064, 27.898615251000024],
            [68.870698000000061, 27.897690000000068],
            [68.860639359000061, 27.886649974000079],
            [68.860570101000064, 27.886573959000032],
            [68.857179000000031, 27.882852000000071],
            [68.863774000000035, 27.869334000000038],
            [68.866741000000047, 27.859442000000058],
            [68.857593593000047, 27.844807259000049],
            [68.856849000000068, 27.843616000000054],
            [68.853131070000074, 27.840052881000076],
            [68.851642119000076, 27.838625929000045],
            [68.844188189000079, 27.831482373000028],
            [68.844157037000059, 27.831452518000049],
            [68.834825971000043, 27.822509988000036],
            [68.829653873000041, 27.817553251000049],
            [68.825947210000038, 27.814000930000077],
            [68.825196000000062, 27.813281000000075],
            [68.815333003000035, 27.804827155000055],
            [68.81529191900006, 27.804791941000076],
            [68.806732000000068, 27.79745500000007],
            [68.793214000000035, 27.79745500000007],
            [68.791326059000028, 27.796977585000036],
            [68.773664719000067, 27.792511457000046],
            [68.77354925700007, 27.792482259000053],
            [68.77328880400006, 27.792416397000068],
            [68.773259277000079, 27.792408930000079],
            [68.772030757000039, 27.792098267000028],
            [68.764528000000041, 27.790201000000025],
            [68.761594242000058, 27.791110076000052],
            [68.760915683000064, 27.791320340000027],
            [68.741118000000029, 27.79745500000007],
            [68.724320504000048, 27.807643905000077],
            [68.723939664000056, 27.807874912000045],
            [68.723515057000043, 27.808132468000053],
            [68.721005000000048, 27.809655000000078],
            [68.704519000000062, 27.798774000000037],
            [68.702400779000072, 27.798192547000042],
            [68.701956518000031, 27.798070597000049],
            [68.697448734000034, 27.796833209000056],
            [68.695635289000052, 27.796335417000023],
            [68.687703000000056, 27.794158000000039],
            [68.683892916000048, 27.793909554000038],
            [68.68338976900003, 27.793876746000024],
            [68.682720029000052, 27.793833074000077],
            [68.680301566000082, 27.793675372000052],
            [68.680175718000044, 27.793667166000034],
            [68.672536000000036, 27.793169000000034],
            [68.668580000000077, 27.784266000000059],
            [68.672015822000048, 27.777178914000046],
            [68.67385500000006, 27.773385000000076],
            [68.655391000000066, 27.755251000000044],
            [68.647420475000047, 27.753402620000031],
            [68.634236659000067, 27.750345268000046],
            [68.632640000000038, 27.749975000000063],
            [68.618792000000042, 27.750305000000026],
            [68.604284000000064, 27.730852000000027],
            [68.603276454000081, 27.73035330700003],
            [68.600022203000037, 27.728742590000024],
            [68.571642000000054, 27.714695000000063],
            [68.556805000000054, 27.711728000000051],
            [68.536362000000054, 27.715684000000067],
            [68.527423154000076, 27.718865942000036],
            [68.523967332000041, 27.720096104000049],
            [68.516908000000058, 27.722609000000034],
            [68.510444563000078, 27.721646325000052],
            [68.510013316000084, 27.721582094000041],
            [68.501412000000073, 27.720301000000063],
            [68.496211294000034, 27.714203415000043],
            [68.491850000000056, 27.70909000000006],
            [68.470748000000071, 27.692274000000054],
            [68.459911012000077, 27.688661963000072],
            [68.45788900000008, 27.687988000000075],
            [68.438435000000084, 27.697220000000073],
            [68.412717000000043, 27.717663000000073],
            [68.407821290000072, 27.717758783000079],
            [68.40644327900003, 27.717785743000036],
            [68.40481589400008, 27.717817583000055],
            [68.402147213000035, 27.71786979500007],
            [68.40213966500005, 27.717869943000039],
            [68.401371201000075, 27.71788497700004],
            [68.398297111000034, 27.717945121000071],
            [68.395901000000038, 27.717992000000038],
            [68.396456416000035, 27.724322534000066],
            [68.396951781000041, 27.729968611000061],
            [68.397262171000079, 27.733506375000047],
            [68.397550000000081, 27.736787000000049],
            [68.386125589000073, 27.73797657700004],
            [68.382535974000064, 27.73835034800004],
            [68.382450009000081, 27.73835930000007],
            [68.381723000000079, 27.738435000000038],
            [68.359632000000033, 27.744040000000041],
            [68.35052819100008, 27.748022988000059],
            [68.343806000000029, 27.750964000000067],
            [68.342144376000078, 27.763516318000029],
            [68.341933264000033, 27.765111109000031],
            [68.340838000000076, 27.773385000000076],
            [68.337362391000056, 27.787287436000042],
            [68.33727633400008, 27.78763166400006],
            [68.335892000000058, 27.793169000000034],
            [68.318417000000068, 27.816579000000047],
            [68.316857791000075, 27.818113114000028],
            [68.31683698900008, 27.818133581000041],
            [68.302215421000028, 27.832519825000077],
            [68.300646398000083, 27.834063597000068],
            [68.297975000000065, 27.836692000000028],
            [68.289072000000033, 27.843286000000035],
            [68.289732000000072, 27.852848000000051],
            [68.290061000000037, 27.862080000000049],
            [68.282478000000083, 27.871312000000046],
            [68.271060099000067, 27.874462013000027],
            [68.263354000000049, 27.876588000000027],
            [68.251814000000081, 27.896701000000064],
            [68.247183360000065, 27.911040687000025],
            [68.247165265000035, 27.911096722000025],
            [68.241628012000035, 27.928243903000066],
            [68.242911000000049, 27.927694000000031],
            [68.252067413000077, 27.927282437000031],
            [68.270931014000041, 27.926434556000061],
            [68.286764000000062, 27.926046000000042],
            [68.28741528300003, 27.928487941000071],
            [68.288022855000065, 27.930765991000044],
            [68.288626581000074, 27.933029619000024],
            [68.289402000000052, 27.935937000000024],
            [68.31347100000005, 27.934618000000057],
            [68.31248430200003, 27.946397958000034],
            [68.310517105000031, 27.969883861000028],
            [68.310215024000058, 27.973490344000027],
            [68.309731022000051, 27.979268727000033],
            [68.308196000000066, 27.997595000000047],
            [68.310725042000058, 28.01656986100005],
            [68.311219752000056, 28.020280304000039],
            [68.31578255900007, 28.054496073000053],
            [68.315825907000033, 28.054821133000075],
            [68.31610900000004, 28.056944000000044],
            [68.316390666000075, 28.060141873000077],
            [68.318055829000059, 28.079047200000048],
            [68.318293797000081, 28.081748947000051],
            [68.318411973000082, 28.083090651000077],
            [68.319308583000065, 28.093270256000039],
            [68.319316064000077, 28.093355187000043],
            [68.321714000000043, 28.120580000000075],
            [68.326391970000032, 28.120209617000057],
            [68.335760350000044, 28.119467407000059],
            [68.353944289000083, 28.11802689700005],
            [68.355009921000033, 28.117918200000076],
            [68.364904744000057, 28.11690885400003],
            [68.370201747000067, 28.116355440000063],
            [68.37710700000008, 28.115634000000057],
            [68.381440569000063, 28.116075389000059],
            [68.38187012700007, 28.11611914100007],
            [68.38742605200008, 28.116685031000031],
            [68.401888686000063, 28.118158099000027],
            [68.412717000000043, 28.119261000000051],
            [68.427237303000084, 28.124252483000078],
            [68.436410124000076, 28.127405722000049],
            [68.436504916000047, 28.127438308000023],
            [68.444370000000049, 28.130142000000035],
            [68.45152274000003, 28.135088039000038],
            [68.451592482000081, 28.135136265000028],
            [68.470735457000046, 28.148373415000037],
            [68.470776973000056, 28.148402123000039],
            [68.47536400000007, 28.151574000000039],
            [68.483253141000034, 28.153957217000027],
            [68.488108454000042, 28.155423950000056],
            [68.491647406000084, 28.156493026000078],
            [68.494816239000045, 28.157450293000068],
            [68.500967021000065, 28.159308372000055],
            [68.502209441000048, 28.159683693000034],
            [68.507017000000076, 28.161136000000056],
            [68.513449426000079, 28.162081886000067],
            [68.524398595000036, 28.163691957000026],
            [68.525927188000082, 28.163840744000026],
            [68.526031063000062, 28.163850855000078],
            [68.530264989000045, 28.164262948000044],
            [68.567678114000046, 28.156567837000068],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "142",
      properties: { name: "Sukkur", count: 827 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [69.070521000000042, 28.003892000000064],
            [69.090197000000046, 27.991784000000052],
            [69.093128123000042, 27.994898250000062],
            [69.114414000000068, 28.017514000000062],
            [69.124331549000033, 28.010648179000043],
            [69.137973783000064, 28.001203795000038],
            [69.153766000000076, 27.990271000000064],
            [69.15889394800007, 27.990555795000034],
            [69.177928985000051, 27.991612953000072],
            [69.16501100000005, 27.977373000000057],
            [69.176852000000054, 27.968761000000029],
            [69.18331100000006, 27.948308000000054],
            [69.177929000000063, 27.930008000000043],
            [69.183908092000081, 27.911074672000041],
            [69.18395087500005, 27.910939196000072],
            [69.184388000000069, 27.909555000000069],
            [69.187873198000034, 27.903978727000037],
            [69.198806300000058, 27.886485899000036],
            [69.198869734000084, 27.88645316800006],
            [69.200535000000059, 27.883720000000039],
            [69.211300000000051, 27.864343000000076],
            [69.211632460000033, 27.859353945000066],
            [69.211635547000071, 27.859307607000062],
            [69.212376000000063, 27.84819600000003],
            [69.221189843000047, 27.837493871000049],
            [69.227447000000041, 27.829896000000076],
            [69.236059000000068, 27.815901000000054],
            [69.254359000000079, 27.798678000000052],
            [69.259741000000076, 27.791143000000034],
            [69.248976000000084, 27.778225000000077],
            [69.256283990000043, 27.770569179000063],
            [69.263080781000042, 27.763448890000063],
            [69.271583000000078, 27.754542000000072],
            [69.271583000000078, 27.750307530000043],
            [69.271583000000078, 27.742701000000068],
            [69.26334582000004, 27.739406121000059],
            [69.255435066000075, 27.736241836000033],
            [69.233906000000047, 27.727630000000033],
            [69.229600000000062, 27.73731900000007],
            [69.200535000000059, 27.747007000000053],
            [69.199553073000061, 27.741114523000078],
            [69.198382036000055, 27.734087213000066],
            [69.197306000000083, 27.727630000000033],
            [69.182235000000048, 27.721171000000027],
            [69.168117625000036, 27.722935945000074],
            [69.164673917000073, 27.723366475000034],
            [69.156437925000034, 27.724396134000074],
            [69.156399000000079, 27.724401000000057],
            [69.155918254000028, 27.717911040000047],
            [69.155519579000043, 27.712529020000034],
            [69.154840979000028, 27.703368084000033],
            [69.154246000000057, 27.695336000000054],
            [69.156675016000065, 27.695093158000077],
            [69.16501100000005, 27.694260000000043],
            [69.166325153000059, 27.694078735000062],
            [69.196229000000073, 27.689954000000057],
            [69.217759000000058, 27.675959000000034],
            [69.231905466000057, 27.661812534000035],
            [69.239288000000045, 27.654430000000048],
            [69.240822360000038, 27.652030500000023],
            [69.249230169000043, 27.638881992000051],
            [69.256100684000046, 27.628136520000055],
            [69.278935598000032, 27.592417984000065],
            [69.281271000000061, 27.588765000000024],
            [69.298494000000062, 27.57046500000007],
            [69.318523837000043, 27.555737453000063],
            [69.335095000000081, 27.543553000000031],
            [69.363404465000031, 27.539263108000057],
            [69.370618000000036, 27.538170000000036],
            [69.385301208000044, 27.536701907000065],
            [69.393454163000058, 27.535886737000055],
            [69.402913000000069, 27.53494100000006],
            [69.423366000000044, 27.53171100000003],
            [69.431880195000076, 27.525885564000077],
            [69.443819000000076, 27.517717000000061],
            [69.459500245000072, 27.518239967000056],
            [69.476113000000055, 27.518794000000071],
            [69.488209484000038, 27.518794000000071],
            [69.501277891000029, 27.518794000000071],
            [69.511295739000047, 27.518794000000071],
            [69.515764865000051, 27.518794000000071],
            [69.520249000000035, 27.518794000000071],
            [69.533167000000049, 27.522023000000047],
            [69.541775944000051, 27.525251541000046],
            [69.55039000000005, 27.528482000000054],
            [69.550690084000053, 27.527431705000026],
            [69.554696000000035, 27.513411000000076],
            [69.547443471000065, 27.48958016000006],
            [69.547161000000074, 27.488652000000059],
            [69.531014000000084, 27.463893000000041],
            [69.531915825000056, 27.463223888000073],
            [69.532499892000033, 27.462790536000057],
            [69.564384000000075, 27.439134000000024],
            [69.591296000000057, 27.421911000000023],
            [69.621438000000069, 27.399305000000027],
            [69.653732000000048, 27.388540000000035],
            [69.697868000000028, 27.377775000000042],
            [69.722627000000045, 27.360551000000044],
            [69.744156000000032, 27.342251000000033],
            [69.771929898000053, 27.330069566000077],
            [69.781581565000067, 27.337278519000051],
            [69.793444686000043, 27.335749433000046],
            [69.784022101000062, 27.327650888000051],
            [69.780932000000064, 27.324995000000058],
            [69.779452000000049, 27.320481000000029],
            [69.716360000000066, 27.293397000000027],
            [69.697793000000047, 27.278840000000059],
            [69.68531500000006, 27.263434000000075],
            [69.673796000000038, 27.252983000000029],
            [69.651988000000074, 27.229331000000059],
            [69.647546029000068, 27.225776278000069],
            [69.643452000000082, 27.222500000000025],
            [69.624406000000079, 27.20612200000005],
            [69.614345000000071, 27.20152900000005],
            [69.600299000000064, 27.192949000000056],
            [69.587769000000037, 27.179926000000023],
            [69.574457000000052, 27.152361000000042],
            [69.567588000000057, 27.134292000000073],
            [69.559222000000034, 27.115315000000066],
            [69.55141100000003, 27.095430000000079],
            [69.54035209500006, 27.069103756000061],
            [69.540315633000034, 27.069016957000031],
            [69.537261069000067, 27.069270927000048],
            [69.515081685000041, 27.07123727100003],
            [69.498532000000068, 27.07255200000003],
            [69.485328000000038, 27.08575600000006],
            [69.477013000000056, 27.090606498000056],
            [69.459633000000053, 27.100745000000074],
            [69.427514000000031, 27.111094000000037],
            [69.402176000000054, 27.116448000000048],
            [69.388598065000053, 27.123508237000067],
            [69.366488000000061, 27.135005000000035],
            [69.347574000000066, 27.147853000000055],
            [69.332020103000048, 27.153769857000043],
            [69.314741000000083, 27.160343000000069],
            [69.302748012000052, 27.163284736000037],
            [69.276912000000038, 27.169622000000061],
            [69.257791971000074, 27.177270114000066],
            [69.239440000000059, 27.184611000000075],
            [69.209820000000036, 27.187109000000078],
            [69.182340000000067, 27.18354000000005],
            [69.149741860000063, 27.197660910000025],
            [69.133832471000062, 27.204686075000041],
            [69.133616474000064, 27.204781454000056],
            [69.125954000000036, 27.208165000000065],
            [69.115572023000084, 27.209138045000032],
            [69.107206105000046, 27.209922136000046],
            [69.091694000000075, 27.21137600000003],
            [69.07727968100005, 27.206077247000053],
            [69.077248088000033, 27.206065633000037],
            [69.075869304000037, 27.205558788000076],
            [69.075834117000056, 27.205545853000046],
            [69.07246983400006, 27.204309131000059],
            [69.067426000000069, 27.202455000000043],
            [69.056402213000069, 27.202325261000055],
            [69.056318301000033, 27.202324356000076],
            [69.056318310000051, 27.202324274000034],
            [69.043615469000031, 27.20217477500006],
            [69.043610950000073, 27.202225263000059],
            [69.035970684000063, 27.202165534000073],
            [69.029451350000045, 27.202558178000061],
            [69.007471000000066, 27.203882000000078],
            [68.997777738000082, 27.207318693000047],
            [68.997747024000034, 27.207329583000046],
            [68.984265836000077, 27.212109264000048],
            [68.978346182000053, 27.214208045000078],
            [68.968215000000043, 27.217800000000068],
            [68.963708770000039, 27.219590869000058],
            [68.96356538100008, 27.219647855000062],
            [68.940378000000067, 27.228863000000047],
            [68.931369562000043, 27.233037980000063],
            [68.912123128000076, 27.241957781000053],
            [68.909359174000031, 27.243606136000039],
            [68.887967192000076, 27.257996422000076],
            [68.868765713000073, 27.271023415000059],
            [68.846163000000047, 27.287034000000062],
            [68.839375756000038, 27.293358733000048],
            [68.830461000000071, 27.301666000000068],
            [68.83217343900003, 27.324215644000049],
            [68.832602000000065, 27.329859000000056],
            [68.837241000000063, 27.35448400000007],
            [68.868290000000059, 27.381963000000042],
            [68.883330855000054, 27.402327368000044],
            [68.891486000000043, 27.413368000000048],
            [68.89083183300005, 27.414132857000027],
            [68.866148000000067, 27.442989000000068],
            [68.894341000000054, 27.480818000000056],
            [68.881857990000071, 27.481973606000054],
            [68.881675166000036, 27.481990531000065],
            [68.881648340000083, 27.481993015000057],
            [68.875070000000051, 27.482602000000043],
            [68.863166850000084, 27.480618208000067],
            [68.845093000000077, 27.477606000000037],
            [68.831174000000033, 27.480461000000048],
            [68.832602000000065, 27.500803000000076],
            [68.827674178000052, 27.501788486000066],
            [68.820111000000054, 27.503301000000079],
            [68.799769000000083, 27.509368000000052],
            [68.795344253000053, 27.516890752000052],
            [68.792632000000083, 27.521502000000055],
            [68.793483739000067, 27.523801826000067],
            [68.797813315000042, 27.535492349000037],
            [68.799769000000083, 27.540773000000058],
            [68.806809472000054, 27.567476458000044],
            [68.809667499000057, 27.578316525000048],
            [68.810119000000043, 27.580029000000025],
            [68.818255783000041, 27.596703256000069],
            [68.821199175000061, 27.602734987000076],
            [68.822133803000042, 27.604650267000068],
            [68.824959096000043, 27.610439982000059],
            [68.831888000000049, 27.624639000000059],
            [68.848195553000039, 27.637140975000079],
            [68.848369580000053, 27.637274391000062],
            [68.853301000000044, 27.641055000000051],
            [68.850834974000065, 27.644901912000023],
            [68.850770155000077, 27.64500302700003],
            [68.84882168200005, 27.648042575000034],
            [68.848785683000074, 27.64809873300004],
            [68.84437900000006, 27.654973000000041],
            [68.842912039000055, 27.661050788000068],
            [68.839383000000055, 27.675672000000077],
            [68.839689050000061, 27.67720248300003],
            [68.84111402800005, 27.684328464000032],
            [68.842470687000059, 27.691112794000048],
            [68.843308000000036, 27.695300000000032],
            [68.841395003000059, 27.695197952000058],
            [68.816543000000081, 27.693872000000056],
            [68.806626683000047, 27.694026993000023],
            [68.793702500000052, 27.694229000000064],
            [68.787175848000061, 27.695679339000037],
            [68.787122532000069, 27.695691187000023],
            [68.786938383000063, 27.695732108000072],
            [68.776150224000048, 27.698129430000051],
            [68.768007000000068, 27.699939000000029],
            [68.759799000000044, 27.69565700000004],
            [68.757581240000036, 27.69310179200005],
            [68.743383000000051, 27.676742000000047],
            [68.723543473000063, 27.676742000000047],
            [68.719866395000054, 27.676742000000047],
            [68.719836994000048, 27.676742000000047],
            [68.718759000000034, 27.676742000000047],
            [68.692350000000033, 27.684951000000069],
            [68.687843737000037, 27.690422279000074],
            [68.687822924000045, 27.690447549000055],
            [68.685577863000049, 27.693173389000037],
            [68.685510799000042, 27.693254816000035],
            [68.682357000000081, 27.697084000000075],
            [68.681639215000075, 27.69867358700003],
            [68.677361000000076, 27.708148000000051],
            [68.673392146000083, 27.714642443000059],
            [68.673117747000049, 27.715091455000049],
            [68.669510000000059, 27.720995000000073],
            [68.654495199000053, 27.721693590000029],
            [68.638818000000072, 27.722423000000049],
            [68.637232431000029, 27.722621142000037],
            [68.636336233000065, 27.722733136000045],
            [68.635862063000047, 27.722792391000041],
            [68.634290066000062, 27.722988838000049],
            [68.628671139000062, 27.723691012000074],
            [68.615302409000037, 27.725361648000046],
            [68.611834240000064, 27.725904349000075],
            [68.609699614000078, 27.726238376000026],
            [68.601850808000052, 27.727624030000072],
            [68.600990000000081, 27.727776000000063],
            [68.600479030000031, 27.728286380000043],
            [68.600022203000037, 27.728742590000024],
            [68.603276454000081, 27.73035330700003],
            [68.604284000000064, 27.730852000000027],
            [68.618792000000042, 27.750305000000026],
            [68.632640000000038, 27.749975000000063],
            [68.634236659000067, 27.750345268000046],
            [68.647420475000047, 27.753402620000031],
            [68.655391000000066, 27.755251000000044],
            [68.67385500000006, 27.773385000000076],
            [68.672015822000048, 27.777178914000046],
            [68.668580000000077, 27.784266000000059],
            [68.672536000000036, 27.793169000000034],
            [68.680175718000044, 27.793667166000034],
            [68.680301566000082, 27.793675372000052],
            [68.682720029000052, 27.793833074000077],
            [68.68338976900003, 27.793876746000024],
            [68.683892916000048, 27.793909554000038],
            [68.687703000000056, 27.794158000000039],
            [68.695635289000052, 27.796335417000023],
            [68.697448734000034, 27.796833209000056],
            [68.701956518000031, 27.798070597000049],
            [68.702400779000072, 27.798192547000042],
            [68.704519000000062, 27.798774000000037],
            [68.721005000000048, 27.809655000000078],
            [68.723515057000043, 27.808132468000053],
            [68.723939664000056, 27.807874912000045],
            [68.724320504000048, 27.807643905000077],
            [68.741118000000029, 27.79745500000007],
            [68.760915683000064, 27.791320340000027],
            [68.761594242000058, 27.791110076000052],
            [68.764528000000041, 27.790201000000025],
            [68.772030757000039, 27.792098267000028],
            [68.773259277000079, 27.792408930000079],
            [68.77328880400006, 27.792416397000068],
            [68.77354925700007, 27.792482259000053],
            [68.773664719000067, 27.792511457000046],
            [68.791326059000028, 27.796977585000036],
            [68.793214000000035, 27.79745500000007],
            [68.806732000000068, 27.79745500000007],
            [68.81529191900006, 27.804791941000076],
            [68.815333003000035, 27.804827155000055],
            [68.825196000000062, 27.813281000000075],
            [68.825947210000038, 27.814000930000077],
            [68.829653873000041, 27.817553251000049],
            [68.834825971000043, 27.822509988000036],
            [68.844157037000059, 27.831452518000049],
            [68.844188189000079, 27.831482373000028],
            [68.851642119000076, 27.838625929000045],
            [68.853131070000074, 27.840052881000076],
            [68.856849000000068, 27.843616000000054],
            [68.857593593000047, 27.844807259000049],
            [68.866741000000047, 27.859442000000058],
            [68.863774000000035, 27.869334000000038],
            [68.857179000000031, 27.882852000000071],
            [68.860570101000064, 27.886573959000032],
            [68.860639359000061, 27.886649974000079],
            [68.870698000000061, 27.897690000000068],
            [68.876713961000064, 27.898615251000024],
            [68.87677405800008, 27.898624626000071],
            [68.883557000000053, 27.899668000000077],
            [68.884211329000038, 27.900233934000028],
            [68.88423092000005, 27.900250878000065],
            [68.895756000000063, 27.910219000000041],
            [68.897405000000049, 27.924397000000056],
            [68.900702000000081, 27.939564000000075],
            [68.912572000000068, 27.938905000000034],
            [68.926618576000067, 27.944627767000043],
            [68.930377000000078, 27.946159000000023],
            [68.94587400000006, 27.952093000000048],
            [68.946909603000051, 27.952915478000023],
            [68.951692061000074, 27.956713714000045],
            [68.957084000000066, 27.960996000000023],
            [68.957414000000028, 27.967920000000049],
            [68.96684730100003, 27.972968690000073],
            [68.979749272000049, 27.979873807000047],
            [68.980824000000041, 27.980449000000078],
            [68.998299000000031, 27.980120000000056],
            [69.016104000000041, 27.981768000000045],
            [69.030941000000041, 28.001881000000026],
            [69.036515629000064, 28.011171672000046],
            [69.04047405600005, 28.017768784000054],
            [69.040833000000077, 28.018367000000069],
            [69.048087000000066, 28.024632000000054],
            [69.055429296000057, 28.027417035000042],
            [69.057649000000083, 28.028259000000048],
            [69.058684352000057, 28.028413687000068],
            [69.059149025000067, 28.027451124000038],
            [69.06248744700008, 28.020534973000053],
            [69.070521000000042, 28.003892000000064],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "143",
      properties: { name: "Tando Allah Yar", count: 428 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.840377000000046, 25.722507000000064],
            [68.841636557000072, 25.721393876000036],
            [68.868315000000052, 25.697817000000043],
            [68.882609712000033, 25.698466987000074],
            [68.882610000000057, 25.698467000000051],
            [68.892410660000053, 25.683766009000067],
            [68.898048277000044, 25.675309584000047],
            [68.89974145900004, 25.672769811000023],
            [68.903402000000028, 25.667279000000065],
            [68.903796853000074, 25.65957648400007],
            [68.903899018000061, 25.657583512000031],
            [68.904572247000033, 25.644450637000034],
            [68.904701000000045, 25.641939000000036],
            [68.901837639000064, 25.62881535300005],
            [68.898847132000071, 25.615108958000064],
            [68.898334917000057, 25.612761324000076],
            [68.896904000000063, 25.60620300000005],
            [68.89576986600008, 25.599331146000054],
            [68.89229086000006, 25.57825142400003],
            [68.891293259000065, 25.572206835000031],
            [68.891172075000043, 25.571472570000026],
            [68.891113580000081, 25.571118141000056],
            [68.888562669000066, 25.555661861000033],
            [68.88856263100007, 25.555661632000067],
            [68.885905248000029, 25.53956022400007],
            [68.885895247000064, 25.539499627000055],
            [68.885859000000039, 25.539280000000076],
            [68.893298573000038, 25.515608063000059],
            [68.893298583000046, 25.515608030000067],
            [68.900153000000046, 25.493798000000027],
            [68.903111684000066, 25.489631661000033],
            [68.913003638000077, 25.475702080000076],
            [68.917598878000035, 25.469231188000037],
            [68.917599303000031, 25.469230590000052],
            [68.921865419000028, 25.46322316100003],
            [68.931990000000042, 25.448966000000041],
            [68.940308387000073, 25.435656693000055],
            [68.946945600000049, 25.425037245000055],
            [68.946946105000052, 25.425036437000074],
            [68.958046176000039, 25.40727647500006],
            [68.959556441000075, 25.404860072000076],
            [68.96122900000006, 25.402184000000034],
            [68.960862186000043, 25.390450470000076],
            [68.960579000000052, 25.381392000000062],
            [68.961174339000081, 25.375059702000044],
            [68.962108447000048, 25.365124091000041],
            [68.964645148000045, 25.33814264800003],
            [68.966369000000043, 25.319807000000026],
            [68.967616043000078, 25.306542571000023],
            [68.967726000000084, 25.305373000000031],
            [68.96826800000008, 25.297877000000028],
            [68.967726000000084, 25.297576000000049],
            [68.96122900000006, 25.296926000000042],
            [68.944335000000081, 25.278083000000038],
            [68.937458994000053, 25.275481330000048],
            [68.922385917000042, 25.269778139000039],
            [68.920295000000067, 25.268987000000038],
            [68.905737966000061, 25.262315259000047],
            [68.904701000000045, 25.261840000000063],
            [68.890407000000039, 25.264439000000039],
            [68.888625413000057, 25.250440144000038],
            [68.888625410000031, 25.250440107000031],
            [68.888559906000069, 25.249925424000025],
            [68.886720980000064, 25.235476023000047],
            [68.885859000000039, 25.228703000000053],
            [68.874813000000074, 25.224155000000053],
            [68.85203950600004, 25.22062098400005],
            [68.841042382000069, 25.21891443800007],
            [68.837128000000064, 25.218307000000038],
            [68.807240570000033, 25.216439275000027],
            [68.805940000000078, 25.216358000000071],
            [68.787747000000081, 25.206612000000064],
            [68.783922856000061, 25.207053204000033],
            [68.770854000000043, 25.208561000000032],
            [68.769555000000082, 25.226104000000078],
            [68.769555000000082, 25.232960116000072],
            [68.769555000000082, 25.246246000000042],
            [68.76285529200004, 25.248986672000058],
            [68.740966000000071, 25.257941000000073],
            [68.73747859000008, 25.253502567000055],
            [68.737478285000066, 25.253502771000058],
            [68.737478262000081, 25.253502785000023],
            [68.727739000000042, 25.259423000000027],
            [68.727183939000042, 25.259760406000055],
            [68.722047000000032, 25.262883000000045],
            [68.713258562000078, 25.275634245000049],
            [68.706697634000079, 25.285153571000023],
            [68.706412000000057, 25.285568000000069],
            [68.683420000000069, 25.288634000000059],
            [68.663494000000071, 25.28342200000003],
            [68.660203857000056, 25.281050112000059],
            [68.654806458000053, 25.277159088000076],
            [68.650312000000042, 25.273919000000035],
            [68.640296988000046, 25.262690130000067],
            [68.640296861000081, 25.262689987000044],
            [68.640256000000079, 25.263139000000024],
            [68.614266000000043, 25.283281000000045],
            [68.615938674000063, 25.29833678500006],
            [68.616215000000068, 25.300824000000034],
            [68.612549878000038, 25.308155499000065],
            [68.612073723000037, 25.30910797100006],
            [68.610368000000051, 25.312520000000063],
            [68.605753806000052, 25.31535942000005],
            [68.598648569000034, 25.319731744000023],
            [68.593474000000072, 25.322916000000077],
            [68.576581000000033, 25.337860000000035],
            [68.57411959500007, 25.350575470000024],
            [68.572915431000069, 25.356796109000072],
            [68.572682000000043, 25.358002000000056],
            [68.573461322000071, 25.357623459000024],
            [68.573491243000035, 25.357608926000069],
            [68.585099231000072, 25.351970571000038],
            [68.593462835000082, 25.347908112000027],
            [68.595423000000039, 25.346956000000034],
            [68.608877202000031, 25.348418501000026],
            [68.620001388000048, 25.349627725000062],
            [68.624358125000072, 25.350101312000049],
            [68.625312000000065, 25.350205000000074],
            [68.625312000000065, 25.361250000000041],
            [68.605819000000054, 25.375545000000045],
            [68.614134083000067, 25.389056609000079],
            [68.616215000000068, 25.392438000000027],
            [68.615864897000051, 25.39401364500003],
            [68.612317000000075, 25.409981000000073],
            [68.59022600000003, 25.42492500000003],
            [68.590170380000075, 25.426702077000073],
            [68.589860414000043, 25.436606013000073],
            [68.589576000000079, 25.445717000000059],
            [68.589847245000044, 25.453851026000052],
            [68.590225308000072, 25.465188240000032],
            [68.590225309000061, 25.465188276000049],
            [68.590225323000084, 25.465188677000071],
            [68.59022600000003, 25.465209000000073],
            [68.603657502000033, 25.490953933000071],
            [68.605819000000054, 25.495097000000044],
            [68.604241994000063, 25.513232876000075],
            [68.604229298000064, 25.513378886000055],
            [68.603220000000078, 25.524986000000069],
            [68.607985445000054, 25.533722343000079],
            [68.614998230000083, 25.546578667000063],
            [68.616438210000069, 25.549218538000048],
            [68.618814000000043, 25.553574000000026],
            [68.650810342000057, 25.581570492000026],
            [68.65126377200005, 25.581967239000051],
            [68.663311461000035, 25.592508850000058],
            [68.669145874000037, 25.597613906000049],
            [68.670794000000058, 25.599056000000076],
            [68.668195000000082, 25.608802000000026],
            [68.659748000000036, 25.623747000000037],
            [68.653900000000078, 25.630894000000069],
            [68.653900000000078, 25.633630124000035],
            [68.653900000000078, 25.641939000000036],
            [68.653900000000078, 25.64562520800007],
            [68.653900000000078, 25.656851882000069],
            [68.653900000000078, 25.658183000000065],
            [68.66624500000006, 25.662731000000065],
            [68.665620123000053, 25.676491006000049],
            [68.665619995000043, 25.676491112000065],
            [68.66559600000005, 25.677025000000071],
            [68.677291000000082, 25.683523000000037],
            [68.684268057000054, 25.698550063000027],
            [68.685195648000047, 25.700547891000042],
            [68.685738000000072, 25.701716000000033],
            [68.691585000000032, 25.713411000000065],
            [68.692235000000039, 25.729655000000037],
            [68.714326000000028, 25.721858000000054],
            [68.725477941000065, 25.723618501000033],
            [68.726672000000065, 25.723807000000079],
            [68.731597055000066, 25.736737993000077],
            [68.731869000000074, 25.737452000000076],
            [68.733789044000048, 25.742164274000061],
            [68.739017000000047, 25.754995000000065],
            [68.754610000000071, 25.74914700000005],
            [68.757560863000037, 25.748292850000041],
            [68.779301000000032, 25.742000000000075],
            [68.786354975000052, 25.740824300000043],
            [68.803148460000045, 25.738025296000046],
            [68.810488000000078, 25.736802000000068],
            [68.835249824000073, 25.724959172000069],
            [68.840377000000046, 25.722507000000064],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "144",
      properties: { name: "Tando Muhammad Khan", count: 802 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [68.647401851000041, 25.183527798000057],
            [68.638307000000054, 25.178023000000053],
            [68.625556690000053, 25.16775178100005],
            [68.614916000000051, 25.159180000000049],
            [68.622063000000082, 25.150734000000057],
            [68.628035794000084, 25.150734000000057],
            [68.638956000000064, 25.150734000000057],
            [68.652601000000061, 25.156581000000074],
            [68.657718470000077, 25.156093783000074],
            [68.66624500000006, 25.155282000000057],
            [68.666061778000028, 25.154915509000034],
            [68.661185297000031, 25.145161295000037],
            [68.658449000000076, 25.139688000000035],
            [68.640816676000043, 25.126344758000073],
            [68.634408000000064, 25.121495000000039],
            [68.63364852400008, 25.120983522000074],
            [68.619875081000032, 25.111707636000062],
            [68.608116856000038, 25.103788922000035],
            [68.602824929000064, 25.100225011000077],
            [68.602571000000069, 25.100054000000057],
            [68.582429000000047, 25.092907000000025],
            [68.57751681700006, 25.091064695000057],
            [68.57749192600005, 25.091055360000041],
            [68.577206832000059, 25.090948436000076],
            [68.576843594000081, 25.090812204000031],
            [68.572033000000033, 25.089008000000035],
            [68.573266508000074, 25.08790295700004],
            [68.590540617000045, 25.072427876000063],
            [68.597971851000068, 25.065770576000034],
            [68.603220000000078, 25.061069000000032],
            [68.609535264000044, 25.055956853000055],
            [68.630510000000072, 25.038978000000043],
            [68.639609346000043, 25.035303088000035],
            [68.64916134300006, 25.031445366000071],
            [68.652000371000042, 25.030298781000056],
            [68.652630487000067, 25.030044298000064],
            [68.664296000000036, 25.025333000000046],
            [68.696783000000039, 25.004542000000072],
            [68.703931000000068, 24.994795000000067],
            [68.706144274000053, 24.991843967000023],
            [68.709778000000028, 24.986999000000026],
            [68.712988977000066, 24.987457509000023],
            [68.718875000000082, 24.988298000000043],
            [68.729306352000037, 24.980895272000055],
            [68.739017000000047, 24.974004000000036],
            [68.745747314000027, 24.969436707000057],
            [68.756368576000057, 24.962229298000068],
            [68.767971512000031, 24.96823057000006],
            [68.770973000000083, 24.969783000000064],
            [68.773251497000047, 24.970476692000034],
            [68.779306000000076, 24.972320000000025],
            [68.779686673000072, 24.972435816000029],
            [68.783727000000056, 24.97366500000004],
            [68.789272000000039, 24.976437000000033],
            [68.800362000000064, 24.978101000000038],
            [68.81093721700006, 24.982205390000047],
            [68.814780000000042, 24.983646000000078],
            [68.817341488000068, 24.984237331000031],
            [68.821721215000082, 24.985248411000043],
            [68.821988000000033, 24.985310000000027],
            [68.828073367000059, 24.985310000000027],
            [68.830861000000084, 24.985310000000027],
            [68.836406000000068, 24.986419000000069],
            [68.842915142000038, 24.986961431000054],
            [68.842915178000055, 24.986961434000023],
            [68.845249511000077, 24.987155960000052],
            [68.846519327000067, 24.962683152000068],
            [68.845760797000082, 24.959016926000061],
            [68.846392905000073, 24.955477121000058],
            [68.847404278000056, 24.953075110000043],
            [68.84904775900003, 24.953833640000028],
            [68.85069124000006, 24.953707218000034],
            [68.85220829900004, 24.953327954000031],
            [68.855242418000046, 24.950040992000027],
            [68.856633055000032, 24.950293835000025],
            [68.862448449000055, 24.954845013000067],
            [68.865861832000064, 24.956614915000046],
            [68.866200657000036, 24.956558444000052],
            [68.865861832000064, 24.95269584600004],
            [68.866493940000055, 24.949156041000037],
            [68.867758157000083, 24.944099176000066],
            [68.868769529000076, 24.939295155000025],
            [68.868620931000066, 24.937140472000067],
            [68.868516686000078, 24.935628929000075],
            [68.867631735000032, 24.933479761000058],
            [68.864850460000071, 24.933100497000055],
            [68.862322027000062, 24.934111869000048],
            [68.861184233000074, 24.936766723000062],
            [68.859035066000047, 24.937904518000039],
            [68.856759477000082, 24.939295155000025],
            [68.853725358000077, 24.935502507000024],
            [68.851702613000043, 24.93385902600005],
            [68.845255111000029, 24.931077751000032],
            [68.841083198000035, 24.931077751000032],
            [68.839313295000068, 24.93133059400003],
            [68.835394225000073, 24.929434270000058],
            [68.835392899000055, 24.929452174000062],
            [68.83492190000004, 24.928431676000059],
            [68.835016716000041, 24.924544212000058],
            [68.835016716000041, 24.921794542000043],
            [68.835680430000082, 24.918950056000028],
            [68.835315311000045, 24.91873326700005],
            [68.837308108000059, 24.915110000000027],
            [68.839457275000029, 24.911696616000029],
            [68.841353599000058, 24.909421027000064],
            [68.845399091000047, 24.905375536000065],
            [68.84573303500008, 24.90489847300006],
            [68.847168993000082, 24.902847104000045],
            [68.848686053000051, 24.895514651000042],
            [68.847927523000067, 24.888182198000038],
            [68.846284042000036, 24.882366804000071],
            [68.843180333000078, 24.878167667000071],
            [68.843391912000072, 24.877956088000076],
            [68.842283553000073, 24.87704565100006],
            [68.839628699000059, 24.874390797000046],
            [68.837226688000044, 24.871483100000034],
            [68.835203943000067, 24.868069717000026],
            [68.833549680000033, 24.867111986000054],
            [68.833054775000051, 24.863771382000039],
            [68.831537716000071, 24.860105155000042],
            [68.829767814000036, 24.849991427000077],
            [68.829267816000083, 24.849522041000057],
            [68.829641392000042, 24.848347946000047],
            [68.829009284000051, 24.84468171900005],
            [68.829767814000036, 24.843038238000076],
            [68.829932962000044, 24.843052918000069],
            [68.829417033000084, 24.840559263000046],
            [68.827057163000063, 24.836513772000046],
            [68.82503441800003, 24.832805405000045],
            [68.823854483000048, 24.830108411000026],
            [68.821494613000084, 24.824714422000056],
            [68.819194235000055, 24.819826120000073],
            [68.818797619000065, 24.818983310000078],
            [68.812897944000042, 24.817297688000053],
            [68.806829707000077, 24.816454878000059],
            [68.802109967000035, 24.816623440000058],
            [68.79926314000005, 24.816979293000031],
            [68.799750097000071, 24.817466250000052],
            [68.799037862000034, 24.817751144000056],
            [68.798064475000047, 24.818140499000037],
            [68.796210292000069, 24.820500369000058],
            [68.794018984000047, 24.821006055000055],
            [68.78963636900005, 24.821848866000039],
            [68.786939374000042, 24.823365925000076],
            [68.786265126000046, 24.825894357000038],
            [68.786265126000046, 24.82808566500006],
            [68.786096564000047, 24.830951222000067],
            [68.785340362000056, 24.831275308000045],
            [68.781376824000063, 24.826568606000023],
            [68.781169771000066, 24.82693094800004],
            [68.780871137000076, 24.82555723300004],
            [68.774297214000057, 24.815274943000077],
            [68.77143165800004, 24.813589321000052],
            [68.770420285000057, 24.813083635000055],
            [68.765531983000074, 24.810049516000049],
            [68.76114936700003, 24.808195333000072],
            [68.756429627000045, 24.807689646000028],
            [68.750529952000079, 24.807689646000028],
            [68.746364776000064, 24.80777826700006],
            [68.746020000000044, 24.803982000000076],
            [68.745465000000081, 24.791783000000066],
            [68.745541188000061, 24.791135369000074],
            [68.741902512000081, 24.791896952000059],
            [68.735960697000053, 24.791896952000059],
            [68.731156676000069, 24.790885579000076],
            [68.73014684900005, 24.790922980000062],
            [68.727743292000071, 24.79101200100007],
            [68.722307163000039, 24.791896952000059],
            [68.71990515300007, 24.793540433000032],
            [68.718388093000044, 24.793919698000025],
            [68.716618191000066, 24.792655482000043],
            [68.716633652000041, 24.792910588000041],
            [68.714868042000035, 24.791197683000064],
            [68.711312434000035, 24.789490991000037],
            [68.70896573300007, 24.788779869000052],
            [68.706448244000057, 24.789003646000026],
            [68.705765686000063, 24.789064318000044],
            [68.703205648000051, 24.791126571000063],
            [68.701356732000079, 24.793544384000029],
            [68.699863377000042, 24.797171104000029],
            [68.698085573000071, 24.799731141000052],
            [68.696521106000034, 24.799660029000052],
            [68.69367661900003, 24.798948908000057],
            [68.691116582000063, 24.796104421000052],
            [68.689054329000044, 24.794539954000072],
            [68.686494292000077, 24.793544384000029],
            [68.684574264000048, 24.793971057000078],
            [68.684395176000066, 24.794354817000055],
            [68.682808312000077, 24.793888093000078],
            [68.680437907000055, 24.794267357000024],
            [68.675602280000078, 24.795120703000066],
            [68.667163638000034, 24.797491108000031],
            [68.664588475000073, 24.798930170000062],
            [68.663939887000083, 24.799292616000059],
            [68.663655438000035, 24.800335595000035],
            [68.669344410000065, 24.810196480000059],
            [68.67006830300005, 24.810624235000034],
            [68.669925160000048, 24.810753525000052],
            [68.671062954000035, 24.812958002000073],
            [68.671553207000045, 24.814277913000069],
            [68.670898381000029, 24.814819837000073],
            [68.670727147000036, 24.815932861000078],
            [68.672497049000071, 24.818840558000034],
            [68.674772638000036, 24.82440310800007],
            [68.674266952000039, 24.826046589000043],
            [68.668451558000072, 24.83135629700007],
            [68.666808077000042, 24.832999778000044],
            [68.66238332100005, 24.835781053000062],
            [68.660234153000033, 24.837298112000042],
            [68.656315083000038, 24.838562329000069],
            [68.652901700000029, 24.842228555000077],
            [68.649741160000076, 24.845641939000075],
            [68.648350522000044, 24.847158998000054],
            [68.648097679000045, 24.853227235000077],
            [68.649109052000028, 24.854744294000056],
            [68.650321763000079, 24.856809181000074],
            [68.650415408000072, 24.856809181000074],
            [68.653955213000074, 24.857314867000071],
            [68.657368597000072, 24.85883192600005],
            [68.662678304000053, 24.860222564000026],
            [68.666218109000056, 24.860728251000069],
            [68.670263601000045, 24.862371731000053],
            [68.672286347000068, 24.863635948000024],
            [68.674785268000051, 24.865558194000073],
            [68.674561936000032, 24.866164380000043],
            [68.676078995000069, 24.868187125000077],
            [68.676837524000064, 24.870462714000041],
            [68.676963946000058, 24.874381784000036],
            [68.676711103000059, 24.876530952000053],
            [68.675699730000076, 24.879438649000065],
            [68.676584681000065, 24.882725610000023],
            [68.679618800000071, 24.887150367000061],
            [68.68303218300008, 24.890690172000063],
            [68.683570495000083, 24.892170528000065],
            [68.683285027000068, 24.892333653000037],
            [68.682273654000028, 24.893345026000077],
            [68.680756594000059, 24.894609242000058],
            [68.676205416000073, 24.897011252000027],
            [68.672286347000068, 24.896379144000036],
            [68.667355904000033, 24.89587345800004],
            [68.663563256000032, 24.894862085000057],
            [68.658759234000058, 24.894988507000051],
            [68.655851537000046, 24.895241350000049],
            [68.649783300000081, 24.898781155000052],
            [68.646103000000039, 24.899832669000034],
            [68.646103000000039, 24.89668400000005],
            [68.645319344000029, 24.895779724000079],
            [68.637657000000047, 24.886938000000043],
            [68.632612694000045, 24.886601575000043],
            [68.632466642000054, 24.886591834000058],
            [68.62791100000004, 24.886288000000036],
            [68.625788938000028, 24.885370386000034],
            [68.596372606000045, 24.872650290000024],
            [68.589134970000032, 24.86952061900007],
            [68.580209380000042, 24.865661050000028],
            [68.579830000000072, 24.865497000000062],
            [68.563609072000077, 24.882366006000041],
            [68.563586000000043, 24.882390000000044],
            [68.559515522000083, 24.881649887000037],
            [68.558636148000062, 24.881489995000038],
            [68.549292000000037, 24.879791000000068],
            [68.532398000000057, 24.888887000000068],
            [68.532209839000075, 24.888797881000073],
            [68.526409271000034, 24.886050540000042],
            [68.523554064000052, 24.884698220000075],
            [68.520053000000075, 24.883040000000051],
            [68.518105512000034, 24.880848971000034],
            [68.517603044000055, 24.880283667000072],
            [68.503031617000033, 24.863890024000057],
            [68.499262000000044, 24.859649000000047],
            [68.498984251000081, 24.859649000000047],
            [68.495763522000061, 24.859649000000047],
            [68.491465000000062, 24.859649000000047],
            [68.48496700000004, 24.873294000000044],
            [68.480752797000036, 24.863460259000078],
            [68.475592880000079, 24.851419716000066],
            [68.475151310000058, 24.850389324000048],
            [68.473272000000065, 24.84600400000005],
            [68.455410543000028, 24.838514284000041],
            [68.453130000000044, 24.837558000000058],
            [68.449685534000082, 24.830884430000026],
            [68.44273400000003, 24.817416000000037],
            [68.43513733900005, 24.795710061000023],
            [68.434057909000046, 24.792625806000046],
            [68.43363800000003, 24.791426000000058],
            [68.410897000000034, 24.788177000000076],
            [68.409233854000036, 24.787622637000027],
            [68.389354830000059, 24.780996522000066],
            [68.381658000000073, 24.778431000000069],
            [68.357755906000079, 24.758404545000076],
            [68.357618000000059, 24.758289000000048],
            [68.35277543400008, 24.767650697000079],
            [68.347871000000055, 24.777132000000051],
            [68.33730896000003, 24.787694040000076],
            [68.334877000000063, 24.790126000000043],
            [68.326907042000073, 24.79475397300007],
            [68.317068598000048, 24.800466933000052],
            [68.314735000000042, 24.801822000000072],
            [68.310215247000031, 24.80351698000004],
            [68.307873935000032, 24.804395010000064],
            [68.299141000000077, 24.80767000000003],
            [68.299407350000081, 24.817254516000048],
            [68.299618538000061, 24.824854006000066],
            [68.299791000000027, 24.831060000000036],
            [68.293293000000062, 24.834959000000026],
            [68.291067621000082, 24.83634982600006],
            [68.290314552000041, 24.836820482000064],
            [68.281339578000029, 24.842429697000057],
            [68.279008782000062, 24.84388640700007],
            [68.277699000000041, 24.844705000000033],
            [68.272501000000034, 24.870045000000061],
            [68.26487164100007, 24.879962874000057],
            [68.261345065000057, 24.884547287000032],
            [68.259506000000044, 24.886938000000043],
            [68.266721847000042, 24.889447734000044],
            [68.274451000000056, 24.89213600000005],
            [68.277874379000082, 24.899294105000024],
            [68.277892457000064, 24.899331904000064],
            [68.278859864000083, 24.901354702000049],
            [68.280282079000074, 24.904328479000071],
            [68.281018175000042, 24.905867617000069],
            [68.281598000000031, 24.907080000000065],
            [68.282983941000055, 24.909653956000056],
            [68.283006148000084, 24.909695199000055],
            [68.283048160000078, 24.909773223000059],
            [68.284469153000032, 24.912412276000055],
            [68.285848242000043, 24.91497350700007],
            [68.29069400000003, 24.923973000000046],
            [68.29823766700008, 24.924512041000071],
            [68.308887000000084, 24.925273000000061],
            [68.30930115700005, 24.928034404000073],
            [68.309639845000049, 24.930292609000048],
            [68.309988805000046, 24.932619306000049],
            [68.310323761000063, 24.934852636000073],
            [68.310836000000052, 24.938268000000051],
            [68.297799878000035, 24.951304122000067],
            [68.296542000000045, 24.952562000000057],
            [68.29569555900008, 24.95352934400006],
            [68.293397485000071, 24.956155666000029],
            [68.291727592000029, 24.958064079000053],
            [68.282897000000048, 24.968156000000079],
            [68.28170046300005, 24.97114734400003],
            [68.280662231000065, 24.973742922000042],
            [68.280629930000032, 24.973823676000052],
            [68.279957532000083, 24.975504669000031],
            [68.277699000000041, 24.981151000000068],
            [68.259933186000069, 24.989115414000025],
            [68.258857000000035, 24.989598000000058],
            [68.256907000000069, 25.018836000000078],
            [68.25937740300003, 25.034479565000026],
            [68.259453002000043, 25.034958287000052],
            [68.259564700000055, 25.035665599000026],
            [68.260612963000028, 25.042303612000069],
            [68.260616797000068, 25.042330022000044],
            [68.260806000000059, 25.043526000000043],
            [68.262306048000028, 25.05638426400003],
            [68.265354000000059, 25.082511000000068],
            [68.267225975000031, 25.088215825000077],
            [68.269621659000052, 25.095516647000068],
            [68.269632734000083, 25.095550398000057],
            [68.276778827000044, 25.117328044000033],
            [68.276841976000071, 25.11752049200004],
            [68.278999000000056, 25.124094000000071],
            [68.29394300000007, 25.126693000000046],
            [68.303511839000066, 25.126693000000046],
            [68.309554381000055, 25.126693000000046],
            [68.325780000000066, 25.126693000000046],
            [68.329111497000042, 25.127015366000023],
            [68.339416967000034, 25.128012554000065],
            [68.34592200000003, 25.12864200000007],
            [68.349634896000055, 25.137080825000055],
            [68.353069000000062, 25.144886000000042],
            [68.352420000000052, 25.163079000000039],
            [68.34508009700005, 25.17726927800004],
            [68.342674000000045, 25.181921000000045],
            [68.339877185000034, 25.186008781000055],
            [68.334227000000055, 25.194267000000025],
            [68.338775000000055, 25.212459000000024],
            [68.343328635000034, 25.216426742000067],
            [68.349821000000077, 25.207261000000074],
            [68.350441710000041, 25.206462912000063],
            [68.363465000000076, 25.189718000000028],
            [68.36588523100005, 25.187660943000026],
            [68.376460000000066, 25.17867300000006],
            [68.405049000000076, 25.170876000000078],
            [68.419343000000083, 25.163729000000046],
            [68.431688000000065, 25.176074000000028],
            [68.440444141000057, 25.172468810000055],
            [68.44273400000003, 25.171526000000028],
            [68.454998121000074, 25.180869022000024],
            [68.455038048000063, 25.180899439000029],
            [68.456379000000084, 25.181921000000045],
            [68.46571582200005, 25.176388699000029],
            [68.471156933000032, 25.173164522000036],
            [68.473922000000073, 25.171526000000028],
            [68.476770716000033, 25.169561290000047],
            [68.492764000000079, 25.158531000000039],
            [68.49924055300005, 25.160772662000056],
            [68.503607017000036, 25.162283982000076],
            [68.503634987000055, 25.162293663000071],
            [68.509657000000061, 25.164378000000056],
            [68.52026932900003, 25.160840920000055],
            [68.529150000000072, 25.157881000000032],
            [68.536947000000055, 25.170226000000071],
            [68.539977369000042, 25.170960661000038],
            [68.558388000000036, 25.175424000000078],
            [68.559353208000061, 25.179284338000059],
            [68.562287000000083, 25.191018000000042],
            [68.553840000000037, 25.204013000000032],
            [68.559181218000049, 25.202780217000054],
            [68.565475408000054, 25.201327482000067],
            [68.570733000000075, 25.200114000000042],
            [68.572510666000028, 25.197320674000025],
            [68.57317196300005, 25.196281549000048],
            [68.584378000000072, 25.17867300000006],
            [68.596644641000069, 25.184539547000043],
            [68.599322000000029, 25.185820000000035],
            [68.605841677000058, 25.198356890000071],
            [68.607769000000076, 25.202063000000066],
            [68.605170000000044, 25.223505000000046],
            [68.604579475000037, 25.225444945000049],
            [68.604568335000067, 25.225481540000033],
            [68.600621000000046, 25.23844900000006],
            [68.609718000000044, 25.239749000000074],
            [68.619105309000076, 25.240419264000025],
            [68.62791100000004, 25.241048000000035],
            [68.642205000000047, 25.241698000000042],
            [68.641677885000036, 25.247496955000031],
            [68.640296861000081, 25.262689987000044],
            [68.640296988000046, 25.262690130000067],
            [68.650312000000042, 25.273919000000035],
            [68.654806458000053, 25.277159088000076],
            [68.660203857000056, 25.281050112000059],
            [68.663494000000071, 25.28342200000003],
            [68.683420000000069, 25.288634000000059],
            [68.706412000000057, 25.285568000000069],
            [68.706697634000079, 25.285153571000023],
            [68.713258562000078, 25.275634245000049],
            [68.722047000000032, 25.262883000000045],
            [68.727183939000042, 25.259760406000055],
            [68.727739000000042, 25.259423000000027],
            [68.737478262000081, 25.253502785000023],
            [68.737478285000066, 25.253502771000058],
            [68.73747859000008, 25.253502567000055],
            [68.73381900000004, 25.248845000000074],
            [68.726672000000065, 25.236500000000035],
            [68.725458532000061, 25.235866918000056],
            [68.711727000000053, 25.228703000000053],
            [68.700682000000029, 25.217008000000078],
            [68.700682000000029, 25.212323582000067],
            [68.700682000000029, 25.207160511000041],
            [68.700682000000029, 25.200114000000042],
            [68.685738000000072, 25.196216000000049],
            [68.662997000000075, 25.192967000000067],
            [68.654326362000063, 25.187718964000055],
            [68.647401851000041, 25.183527798000057],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "145",
      properties: { name: "Tharparkar", count: 842 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.359461000000067, 25.680952000000048],
            [70.388089000000036, 25.674950000000024],
            [70.437408000000062, 25.681568000000027],
            [70.440308000000073, 25.681769000000031],
            [70.452380037000069, 25.682771290000062],
            [70.469299000000035, 25.684176000000036],
            [70.500570000000039, 25.685750000000041],
            [70.52925300000004, 25.684417000000053],
            [70.556850000000054, 25.695383000000049],
            [70.586804795000035, 25.708696701000065],
            [70.593157000000076, 25.711520000000064],
            [70.60817000000003, 25.715890000000059],
            [70.622844000000043, 25.715687000000059],
            [70.648317000000077, 25.713464000000045],
            [70.661357000000066, 25.703103000000056],
            [70.663939000000084, 25.697441000000026],
            [70.67079300000006, 25.685255000000041],
            [70.675519000000065, 25.675592000000051],
            [70.674698000000035, 25.655981000000054],
            [70.675429000000065, 25.651886000000047],
            [70.674912000000063, 25.646223000000077],
            [70.674119000000076, 25.639696000000072],
            [70.67311200000006, 25.639309000000026],
            [70.671788000000049, 25.63468000000006],
            [70.67168300000003, 25.630420000000072],
            [70.671915657000056, 25.62668603000003],
            [70.673024000000055, 25.608898000000067],
            [70.670881000000065, 25.593796000000054],
            [70.67013877100004, 25.577772655000047],
            [70.669650000000047, 25.567221000000075],
            [70.668578000000082, 25.53105000000005],
            [70.672211000000061, 25.530001000000027],
            [70.679120000000069, 25.526468000000023],
            [70.679734000000053, 25.522950000000037],
            [70.675762000000077, 25.487718000000029],
            [70.673768174000031, 25.46780926100007],
            [70.673466000000076, 25.464792000000045],
            [70.672453000000075, 25.461435000000051],
            [70.673824000000081, 25.458920000000035],
            [70.673571000000038, 25.434822000000054],
            [70.673192000000029, 25.420249000000069],
            [70.666251000000045, 25.397305000000074],
            [70.684266000000036, 25.381020000000035],
            [70.689413363000028, 25.376290792000077],
            [70.69746200000003, 25.368896000000063],
            [70.710101000000066, 25.357727000000068],
            [70.728396000000032, 25.341566000000057],
            [70.737760000000037, 25.33331000000004],
            [70.750684989000035, 25.284222648000025],
            [70.752181000000064, 25.278541000000075],
            [70.767555000000073, 25.263449000000037],
            [70.783123000000046, 25.249124000000052],
            [70.790814000000069, 25.241428000000042],
            [70.801906000000031, 25.230848000000037],
            [70.824008000000049, 25.209611000000052],
            [70.839806250000038, 25.194851201000063],
            [70.843481000000054, 25.191418000000056],
            [70.857344000000069, 25.178057000000024],
            [70.870707000000039, 25.16448500000007],
            [70.881942000000038, 25.154582000000062],
            [70.889020000000073, 25.147600000000068],
            [70.894979000000035, 25.118463000000077],
            [70.901640000000043, 25.086272000000065],
            [70.90369254400008, 25.075740859000064],
            [70.903771963000054, 25.075333379000028],
            [70.908935000000042, 25.048843000000033],
            [70.919013000000064, 25.000499000000048],
            [70.926093000000037, 24.977280000000064],
            [70.928511054000069, 24.969890404000068],
            [70.938698000000045, 24.938759000000061],
            [70.942607000000066, 24.931358000000046],
            [70.961263000000031, 24.906208000000049],
            [70.981324596000036, 24.879636850000054],
            [70.982494000000031, 24.878088000000048],
            [70.98928200000006, 24.869532000000049],
            [71.003625000000056, 24.846711000000028],
            [71.018602000000044, 24.820944000000054],
            [71.020147896000083, 24.819072242000061],
            [71.028313000000082, 24.809186000000068],
            [71.029044000000056, 24.804566000000023],
            [71.03597300000007, 24.786587000000054],
            [71.03866000000005, 24.780371000000059],
            [71.050451000000066, 24.754944000000023],
            [71.056396000000063, 24.741907000000026],
            [71.060936000000083, 24.732034000000056],
            [71.067339000000061, 24.718119000000058],
            [71.069312000000082, 24.716413000000045],
            [71.084140000000048, 24.701559000000032],
            [71.09254500000003, 24.694336000000078],
            [71.099087000000054, 24.687437000000045],
            [71.086698000000069, 24.672630000000026],
            [71.07723500000003, 24.66286400000007],
            [71.064639000000057, 24.654758000000072],
            [71.054439000000059, 24.649105000000077],
            [71.045358065000073, 24.646044271000051],
            [71.035605000000032, 24.642757000000074],
            [71.024259000000029, 24.639381000000071],
            [71.004233816000067, 24.621292003000065],
            [70.997813000000065, 24.615492000000074],
            [70.994998000000066, 24.610107000000028],
            [70.98780400000004, 24.595623000000046],
            [70.992769000000067, 24.573198000000048],
            [70.994936000000052, 24.56308800000005],
            [70.993110000000058, 24.545131000000026],
            [71.007214000000033, 24.528786000000025],
            [71.003536000000054, 24.502890000000036],
            [71.002270714000076, 24.492806211000072],
            [71.00008500000007, 24.475387000000069],
            [70.998712000000069, 24.463768000000073],
            [70.999212000000057, 24.447170000000028],
            [71.008830430000046, 24.446970930000077],
            [71.008972000000028, 24.446968000000027],
            [71.021789518000048, 24.441148840000039],
            [71.037331000000052, 24.434093000000075],
            [71.046369000000084, 24.435778000000028],
            [71.060273598000038, 24.437438551000071],
            [71.070560000000057, 24.438667000000066],
            [71.085479000000078, 24.437768000000062],
            [71.103543000000059, 24.436785000000043],
            [71.12137000000007, 24.424840000000074],
            [71.127278000000047, 24.420672000000025],
            [71.125585000000058, 24.405786000000035],
            [71.124951000000067, 24.400873000000047],
            [71.109306000000061, 24.393247000000031],
            [71.089566000000048, 24.390438000000074],
            [71.079708897000046, 24.387178480000046],
            [71.078773000000069, 24.386869000000047],
            [71.068121000000076, 24.380084000000068],
            [71.067387000000053, 24.372817000000055],
            [71.058452000000045, 24.367723000000069],
            [71.046725000000038, 24.358102000000031],
            [71.040986000000032, 24.353105000000028],
            [71.032155000000046, 24.349714000000063],
            [71.022304000000076, 24.349538000000052],
            [71.016006904000051, 24.351221250000037],
            [71.002065000000073, 24.354948000000036],
            [70.991290000000049, 24.360159000000067],
            [70.985375000000033, 24.360069000000067],
            [70.979772000000082, 24.357902000000024],
            [70.970637000000067, 24.350824000000046],
            [70.967125000000067, 24.346507000000031],
            [70.959552000000031, 24.342546000000027],
            [70.943472000000042, 24.338590000000067],
            [70.925954000000047, 24.329710000000034],
            [70.920762000000082, 24.321207000000072],
            [70.910714934000055, 24.31693843100004],
            [70.909377000000063, 24.316370000000063],
            [70.884641000000045, 24.311738000000048],
            [70.867742000000078, 24.302300000000059],
            [70.863179000000059, 24.297484000000054],
            [70.86079200000006, 24.289699000000041],
            [70.863693000000069, 24.284128000000067],
            [70.87716800000004, 24.276842000000045],
            [70.889924000000065, 24.273820000000057],
            [70.898430000000076, 24.268337000000031],
            [70.899859000000049, 24.259445000000028],
            [70.896646000000032, 24.256045000000029],
            [70.858437000000038, 24.247413000000051],
            [70.841336000000069, 24.237870000000044],
            [70.832524000000035, 24.233238000000028],
            [70.82524281700006, 24.230715445000044],
            [70.80197400000003, 24.222654000000034],
            [70.796091000000047, 24.220475000000079],
            [70.780024000000083, 24.221133000000066],
            [70.77246400000007, 24.219989000000055],
            [70.768934000000058, 24.219617000000028],
            [70.750421000000074, 24.221973000000048],
            [70.74482832700005, 24.22173071900005],
            [70.726068000000055, 24.22091800000004],
            [70.711177000000077, 24.220058000000051],
            [70.701502000000062, 24.219579000000067],
            [70.694344206000039, 24.220274218000043],
            [70.674558000000047, 24.222196000000054],
            [70.669006000000081, 24.221908000000042],
            [70.66132900000008, 24.221243000000072],
            [70.638842000000068, 24.232139000000075],
            [70.634280000000047, 24.233551000000034],
            [70.626091357000064, 24.236188972000036],
            [70.620569000000046, 24.237968000000023],
            [70.608784000000071, 24.238047000000051],
            [70.596124000000032, 24.240586000000064],
            [70.583061000000043, 24.243399000000068],
            [70.570598000000075, 24.255381000000057],
            [70.570685000000083, 24.263208000000077],
            [70.581332000000032, 24.278733000000045],
            [70.584949000000051, 24.283655000000067],
            [70.584733000000028, 24.287813000000028],
            [70.576335000000029, 24.288650000000075],
            [70.569799000000046, 24.290434000000062],
            [70.570234399000071, 24.291554530000042],
            [70.572069000000056, 24.296276000000034],
            [70.584292000000062, 24.303571000000034],
            [70.58479600000004, 24.308494000000053],
            [70.580641000000071, 24.312550000000044],
            [70.576702000000068, 24.313202000000047],
            [70.573795000000075, 24.314520000000073],
            [70.568047000000035, 24.336397000000034],
            [70.565761000000066, 24.338569000000064],
            [70.564910000000054, 24.348489000000029],
            [70.57236000000006, 24.359143000000074],
            [70.584279000000038, 24.364181000000031],
            [70.588513000000034, 24.374867000000052],
            [70.592761000000053, 24.377331000000027],
            [70.595908297000051, 24.387663818000078],
            [70.59823300000005, 24.39529600000003],
            [70.596857000000057, 24.407390000000078],
            [70.584912000000031, 24.413422000000025],
            [70.562930016000053, 24.413801200000023],
            [70.554652000000033, 24.413944000000072],
            [70.533274000000063, 24.410216000000048],
            [70.528716000000031, 24.405859000000078],
            [70.517786000000058, 24.402400000000057],
            [70.50360762300005, 24.396034654000061],
            [70.503577394000047, 24.396021083000051],
            [70.503285336000033, 24.395889964000048],
            [70.496837000000028, 24.392995000000042],
            [70.481014000000073, 24.382221000000072],
            [70.47063600000007, 24.379926000000069],
            [70.46336800000006, 24.381423000000041],
            [70.446615000000065, 24.379620000000045],
            [70.426484000000073, 24.373526000000027],
            [70.418818000000044, 24.367830000000026],
            [70.405368000000067, 24.363822000000027],
            [70.39107000000007, 24.357164000000068],
            [70.374077000000057, 24.348794000000055],
            [70.364104510000061, 24.347003330000064],
            [70.354262000000062, 24.345236000000057],
            [70.343299000000059, 24.343404000000078],
            [70.322025000000053, 24.343050000000062],
            [70.313844000000074, 24.338579000000038],
            [70.312391000000048, 24.338668000000041],
            [70.304462000000058, 24.33855200000005],
            [70.289966632000073, 24.331206111000029],
            [70.286233000000038, 24.329314000000068],
            [70.268825000000049, 24.324902000000066],
            [70.254145000000051, 24.320932000000028],
            [70.228241000000082, 24.312602000000027],
            [70.214398000000074, 24.306027000000029],
            [70.185948000000053, 24.298156000000063],
            [70.177049872000055, 24.298572895000063],
            [70.165586000000076, 24.299110000000042],
            [70.153243000000032, 24.297353000000044],
            [70.147347000000082, 24.294018000000051],
            [70.140494000000047, 24.29587900000007],
            [70.132649000000072, 24.295008000000053],
            [70.118473000000051, 24.287755000000061],
            [70.107708000000059, 24.272649000000058],
            [70.100284000000045, 24.264103000000034],
            [70.097755000000063, 24.252868000000035],
            [70.09281500000003, 24.246321000000023],
            [70.087181000000044, 24.234192000000064],
            [70.079255124000042, 24.224666273000025],
            [70.078432000000078, 24.223677000000066],
            [70.074052000000052, 24.210418000000061],
            [70.06211600000006, 24.197981000000027],
            [70.05886600000008, 24.192089000000067],
            [70.056885000000079, 24.188595000000078],
            [70.043516000000068, 24.17604700000004],
            [70.020269000000042, 24.164017000000058],
            [69.990117000000055, 24.164043000000049],
            [69.980709000000047, 24.165028000000063],
            [69.97532300000006, 24.162347000000068],
            [69.92775000000006, 24.164063000000056],
            [69.922010701000033, 24.163080840000077],
            [69.921874158000037, 24.163057474000027],
            [69.916881000000046, 24.162203000000034],
            [69.907125000000065, 24.164042000000052],
            [69.893946000000028, 24.164615000000026],
            [69.877155000000073, 24.164513000000056],
            [69.861484011000073, 24.164801638000029],
            [69.854352000000063, 24.164933000000076],
            [69.842715000000055, 24.163444000000027],
            [69.819819000000052, 24.162540000000035],
            [69.802575000000047, 24.163090000000068],
            [69.789603000000056, 24.16564900000003],
            [69.780947000000083, 24.162468000000047],
            [69.754367000000059, 24.168713000000025],
            [69.739872000000048, 24.169462000000067],
            [69.724932000000081, 24.171247000000051],
            [69.717126372000052, 24.175832047000029],
            [69.71285000000006, 24.178344000000038],
            [69.71085400000004, 24.181354000000056],
            [69.703151000000048, 24.190111000000059],
            [69.69471800000008, 24.194586000000072],
            [69.685619000000031, 24.203782000000047],
            [69.677568000000065, 24.21109400000006],
            [69.667191000000059, 24.217420000000061],
            [69.66375400000004, 24.219378000000063],
            [69.659338000000048, 24.226245000000063],
            [69.646556000000032, 24.237427000000025],
            [69.636727000000064, 24.246238000000062],
            [69.624294000000077, 24.256822000000057],
            [69.621786627000063, 24.257881814000029],
            [69.616172000000063, 24.260255000000029],
            [69.602755000000059, 24.272912000000076],
            [69.590547000000072, 24.280758000000048],
            [69.578120000000069, 24.278479000000061],
            [69.56160600000004, 24.276831000000072],
            [69.54240800000008, 24.270336000000043],
            [69.532407000000035, 24.264864000000046],
            [69.527485786000057, 24.263673468000036],
            [69.503236000000072, 24.257807000000071],
            [69.48361600000004, 24.260944000000052],
            [69.47987500000005, 24.26176300000003],
            [69.458791000000076, 24.264884000000052],
            [69.447096070000043, 24.264337982000029],
            [69.443798000000072, 24.264184000000057],
            [69.41972700000008, 24.268124000000057],
            [69.396848000000034, 24.26403300000004],
            [69.370662000000038, 24.263410000000079],
            [69.35625152700004, 24.26456797000003],
            [69.352804000000049, 24.264845000000037],
            [69.342400000000055, 24.267866000000026],
            [69.326888000000054, 24.270454000000029],
            [69.306352000000061, 24.270628000000045],
            [69.290884000000062, 24.268813000000023],
            [69.27488100000005, 24.26297900000003],
            [69.269475000000057, 24.264059000000032],
            [69.262625000000071, 24.259453000000065],
            [69.231269000000054, 24.245135000000062],
            [69.21841100000006, 24.236211000000026],
            [69.203287000000046, 24.235578000000032],
            [69.193875000000048, 24.233872000000076],
            [69.160885000000064, 24.241643000000067],
            [69.129994000000067, 24.250569000000041],
            [69.128303041000038, 24.251760198000056],
            [69.124445000000037, 24.254478000000063],
            [69.109253000000081, 24.258281000000068],
            [69.101001000000053, 24.262441000000024],
            [69.088960000000043, 24.263251000000025],
            [69.082406862000028, 24.261341345000062],
            [69.082028000000037, 24.263969000000031],
            [69.07236400000005, 24.291938000000073],
            [69.060773546000064, 24.307187361000047],
            [69.060032000000035, 24.308163000000036],
            [69.056138000000033, 24.324389000000053],
            [69.057065320000049, 24.333894744000077],
            [69.058734000000072, 24.351000000000056],
            [69.063439420000066, 24.379233427000031],
            [69.063926000000038, 24.382153000000073],
            [69.07171500000004, 24.412658000000079],
            [69.072493873000042, 24.417331239000077],
            [69.07366200000007, 24.424340000000029],
            [69.076223911000056, 24.441847705000043],
            [69.077556000000072, 24.450951000000032],
            [69.088590000000067, 24.460686000000067],
            [69.103517000000068, 24.462633000000039],
            [69.106787953000037, 24.470264476000068],
            [69.10935900000004, 24.476263000000074],
            [69.138565000000028, 24.504820000000052],
            [69.145617446000074, 24.509072337000077],
            [69.16978799900005, 24.523646193000047],
            [69.182699000000071, 24.531431000000055],
            [69.195031000000029, 24.53857000000005],
            [69.200872000000061, 24.528835000000072],
            [69.207592247000036, 24.533110922000049],
            [69.208012000000053, 24.533378000000027],
            [69.220992000000081, 24.542464000000052],
            [69.220559647000073, 24.54430166800006],
            [69.218396000000041, 24.553498000000047],
            [69.209959000000083, 24.55674300000004],
            [69.209959000000083, 24.559886000000063],
            [69.209959000000083, 24.565180000000055],
            [69.222939000000054, 24.573618000000067],
            [69.222074272000043, 24.579671098000063],
            [69.221641000000034, 24.582704000000035],
            [69.209310000000073, 24.59438700000004],
            [69.199574000000041, 24.600228000000072],
            [69.198719936000032, 24.605139198000074],
            [69.196978000000058, 24.61515600000007],
            [69.206714000000034, 24.620997000000045],
            [69.219694000000061, 24.607368000000065],
            [69.226834000000053, 24.59568500000006],
            [69.235271000000068, 24.59568500000006],
            [69.233973000000049, 24.611911000000077],
            [69.233973000000049, 24.63332900000006],
            [69.237521822000076, 24.636117402000025],
            [69.252146000000039, 24.647608000000048],
            [69.242410000000064, 24.661886000000038],
            [69.236793596000041, 24.662822027000061],
            [69.221103694000078, 24.665436899000042],
            [69.219045000000051, 24.665780000000041],
            [69.221641000000034, 24.682006000000058],
            [69.223645372000078, 24.683945718000075],
            [69.241761000000054, 24.701477000000068],
            [69.285896000000037, 24.721597000000031],
            [69.258636000000081, 24.733280000000036],
            [69.256447648000062, 24.740331731000026],
            [69.252795000000049, 24.752102000000036],
            [69.261410019000039, 24.754016367000077],
            [69.276160000000061, 24.757294000000059],
            [69.313804000000061, 24.778712000000041],
            [69.326028052000083, 24.796174547000078],
            [69.327434000000039, 24.798183000000051],
            [69.332644000000073, 24.800679000000059],
            [69.370469648000039, 24.818786150000051],
            [69.370469723000042, 24.818786186000068],
            [69.388443000000052, 24.827390000000037],
            [69.396370000000047, 24.836492000000078],
            [69.405967000000032, 24.847510000000057],
            [69.42409467300007, 24.849418073000038],
            [69.44296200000008, 24.851404000000059],
            [69.46084815100005, 24.862136005000025],
            [69.465678000000082, 24.865034000000037],
            [69.485153000000082, 24.882239000000027],
            [69.50876405200006, 24.903085623000038],
            [69.515653000000043, 24.909168000000022],
            [69.520736919000058, 24.914978034000058],
            [69.523377346000075, 24.917995581000071],
            [69.541370676000042, 24.938558821000072],
            [69.561086000000046, 24.96109000000007],
            [69.563903781000079, 24.963685461000068],
            [69.57322524500006, 24.972271415000023],
            [69.581555312000035, 24.979944209000053],
            [69.581555255000069, 24.979944296000042],
            [69.585016000000053, 24.983132000000069],
            [69.585016091000057, 24.983131920000062],
            [69.585749000000078, 24.98380700000007],
            [69.586552225000048, 24.98557415700003],
            [69.598729000000048, 25.012364000000048],
            [69.612015979000034, 25.025974528000063],
            [69.619952788000035, 25.034104605000039],
            [69.625340000000051, 25.039623000000063],
            [69.638969000000031, 25.044816000000026],
            [69.655195000000049, 25.032484000000068],
            [69.670552532000045, 25.020198330000028],
            [69.681157000000042, 25.011715000000038],
            [69.689533125000082, 25.007628924000073],
            [69.698225922000063, 25.00338836800006],
            [69.707767000000047, 24.99873400000007],
            [69.725291000000084, 25.001330000000053],
            [69.740219000000081, 25.005874000000063],
            [69.741517000000044, 25.019503000000043],
            [69.736973000000035, 25.038974000000053],
            [69.735862350000048, 25.04586037200005],
            [69.733728000000042, 25.059094000000073],
            [69.750603000000069, 25.061690000000056],
            [69.741638395000052, 25.076930036000078],
            [69.737622000000044, 25.083758000000046],
            [69.741517000000044, 25.104527000000076],
            [69.725291000000084, 25.112315000000024],
            [69.721952705000035, 25.114461112000072],
            [69.717412430000081, 25.117379950000043],
            [69.707610230000057, 25.123681557000054],
            [69.707118000000037, 25.123998000000029],
            [69.702739296000061, 25.126124706000041],
            [69.684402000000034, 25.135031000000026],
            [69.685700000000054, 25.151906000000054],
            [69.696235897000065, 25.155147828000054],
            [69.717185780000079, 25.161593973000038],
            [69.727031063000084, 25.164623304000031],
            [69.744762000000037, 25.170079000000044],
            [69.754884189000052, 25.175373672000035],
            [69.767492782000033, 25.181968922000067],
            [69.800409150000064, 25.199186679000036],
            [69.811682231000077, 25.205083355000056],
            [69.811684168000056, 25.20508436800003],
            [69.814817793000032, 25.206723492000037],
            [69.829136000000062, 25.214213000000029],
            [69.84202907100007, 25.220659651000062],
            [69.846819752000044, 25.223055035000073],
            [69.867855288000044, 25.233572991000074],
            [69.884953000000053, 25.242122000000052],
            [69.892879839000045, 25.242426860000023],
            [69.90182800000008, 25.242771000000062],
            [69.928197650000072, 25.229383341000073],
            [69.944015000000036, 25.221353000000079],
            [69.979712000000063, 25.204478000000051],
            [70.003726000000029, 25.200584000000049],
            [70.00834913500006, 25.202400132000037],
            [70.021899000000076, 25.207723000000044],
            [70.040072000000066, 25.201233000000059],
            [70.059973623000076, 25.20807385300003],
            [70.060841000000039, 25.208372000000054],
            [70.080312000000049, 25.212266000000056],
            [70.097437807000063, 25.207271133000063],
            [70.111465000000067, 25.203180000000032],
            [70.125744000000054, 25.210319000000027],
            [70.139374000000032, 25.210319000000027],
            [70.149109000000067, 25.218756000000042],
            [70.155723088000059, 25.223357240000041],
            [70.155725696000047, 25.22335905400007],
            [70.164037000000064, 25.229141000000027],
            [70.196489000000042, 25.229790000000037],
            [70.199533342000052, 25.229204550000077],
            [70.199658155000066, 25.229180547000055],
            [70.213363000000072, 25.226545000000044],
            [70.22699300000005, 25.238876000000062],
            [70.241272000000038, 25.239525000000071],
            [70.252305000000035, 25.233035000000029],
            [70.253604000000053, 25.220054000000061],
            [70.278267000000028, 25.231737000000066],
            [70.294493000000045, 25.247314000000074],
            [70.300419257000044, 25.260278258000028],
            [70.304877000000033, 25.270030000000077],
            [70.30378109600008, 25.279071634000047],
            [70.30228100000005, 25.291448000000059],
            [70.30293000000006, 25.318707000000074],
            [70.30293000000006, 25.343371000000047],
            [70.293195000000082, 25.34596700000003],
            [70.275671000000045, 25.357649000000038],
            [70.278004924000072, 25.360274889000038],
            [70.286055000000033, 25.369332000000043],
            [70.305526000000043, 25.379068000000075],
            [70.324997000000053, 25.387505000000033],
            [70.32240100000007, 25.397240000000068],
            [70.329540000000065, 25.40373100000005],
            [70.317209000000048, 25.40308200000004],
            [70.30422800000008, 25.416711000000078],
            [70.296440000000075, 25.424500000000023],
            [70.286704000000043, 25.425798000000043],
            [70.267882000000043, 25.406327000000033],
            [70.252954000000045, 25.40373100000005],
            [70.254902000000072, 25.419308000000058],
            [70.250358000000062, 25.42385100000007],
            [70.233484000000033, 25.42255300000005],
            [70.230887000000052, 25.434884000000068],
            [70.231643022000071, 25.436307040000031],
            [70.241921000000048, 25.455653000000041],
            [70.26398800000004, 25.473177000000078],
            [70.273587228000054, 25.485175903000027],
            [70.282161000000031, 25.495893000000024],
            [70.280863000000068, 25.506278000000066],
            [70.276320000000055, 25.523153000000036],
            [70.28281000000004, 25.536783000000071],
            [70.28475700000007, 25.552359000000024],
            [70.26593500000007, 25.560797000000036],
            [70.248411000000033, 25.562095000000056],
            [70.223099000000047, 25.547167000000059],
            [70.20946900000007, 25.538730000000044],
            [70.191296000000079, 25.536783000000071],
            [70.172474000000079, 25.533537000000024],
            [70.17052700000005, 25.546518000000049],
            [70.18870000000004, 25.556903000000034],
            [70.198436000000072, 25.566638000000069],
            [70.20882000000006, 25.575724000000037],
            [70.224397000000067, 25.586758000000032],
            [70.22634400000004, 25.595844000000056],
            [70.197787000000062, 25.602335000000039],
            [70.184157000000084, 25.599739000000056],
            [70.165335000000084, 25.590652000000034],
            [70.141970000000072, 25.589354000000071],
            [70.125744000000054, 25.590652000000034],
            [70.128340000000037, 25.601037000000076],
            [70.140672000000052, 25.613368000000037],
            [70.141970000000072, 25.623104000000069],
            [70.152354000000059, 25.633488000000057],
            [70.153003000000069, 25.651012000000037],
            [70.158323986000084, 25.656713015000037],
            [70.162090000000035, 25.660748000000069],
            [70.180263000000082, 25.660099000000059],
            [70.196489000000042, 25.67697400000003],
            [70.241921000000048, 25.706180000000074],
            [70.264159985000049, 25.720831816000043],
            [70.269718000000069, 25.71470400000004],
            [70.290918000000033, 25.706436000000053],
            [70.30831500000005, 25.699858000000063],
            [70.336091000000067, 25.689475000000073],
            [70.359461000000067, 25.680952000000048],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "146",
      properties: { name: "Sujawal", count: 588 },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [68.192075000000045, 23.83279600000003],
              [68.192905000000053, 23.816043000000036],
              [68.195165000000031, 23.798460000000034],
              [68.195003427000074, 23.796691900000042],
              [68.19402400000007, 23.785974000000067],
              [68.182848000000035, 23.781471000000067],
              [68.173110000000065, 23.775706000000071],
              [68.166255000000035, 23.766984000000036],
              [68.162303000000065, 23.754448000000025],
              [68.161898000000065, 23.734456000000023],
              [68.160415000000057, 23.730497000000071],
              [68.158646000000033, 23.720376000000044],
              [68.156850000000077, 23.71759000000003],
              [68.15675600000003, 23.715491000000043],
              [68.157943000000046, 23.713680000000068],
              [68.158579295000038, 23.712663071000065],
              [68.15883400000007, 23.712256000000025],
              [68.160395000000051, 23.712810000000047],
              [68.161265000000071, 23.712303000000077],
              [68.15949100000006, 23.708600000000047],
              [68.161213000000032, 23.708371000000056],
              [68.164333000000056, 23.709611000000052],
              [68.166442000000075, 23.71122500000007],
              [68.168027000000052, 23.710731000000067],
              [68.16342300000008, 23.70720300000005],
              [68.160004000000072, 23.706482000000051],
              [68.158489153000062, 23.706104175000064],
              [68.158296000000064, 23.706056000000046],
              [68.153580000000034, 23.705703000000028],
              [68.149120000000039, 23.706664000000046],
              [68.145369000000073, 23.707902000000047],
              [68.141341000000068, 23.708741000000032],
              [68.137743000000057, 23.709588000000053],
              [68.135987000000057, 23.711256000000049],
              [68.134647000000029, 23.713457000000062],
              [68.132073000000048, 23.713406000000077],
              [68.129897000000028, 23.714674000000059],
              [68.127440000000036, 23.709647000000075],
              [68.129735000000039, 23.709430000000054],
              [68.129936000000043, 23.706945000000076],
              [68.127981000000034, 23.704941000000076],
              [68.125291000000061, 23.703708000000063],
              [68.123435000000029, 23.70354100000003],
              [68.119440000000054, 23.703069000000028],
              [68.11227400000007, 23.70358200000004],
              [68.10653700000006, 23.704123000000038],
              [68.100077000000056, 23.705043000000046],
              [68.099626000000058, 23.705952000000025],
              [68.095911000000058, 23.705747000000031],
              [68.092473000000041, 23.705941000000053],
              [68.088751000000059, 23.705998000000022],
              [68.084328000000028, 23.705517000000043],
              [68.078319000000079, 23.705528000000072],
              [68.073614000000077, 23.706039000000033],
              [68.067548000000045, 23.708407000000022],
              [68.063757000000066, 23.711342000000059],
              [68.060506000000032, 23.715598000000057],
              [68.058424000000059, 23.718831000000023],
              [68.05664500000006, 23.721416000000033],
              [68.056124000000068, 23.725205000000074],
              [68.056170000000066, 23.729268000000047],
              [68.056676000000039, 23.732029000000068],
              [68.057309000000032, 23.735448000000076],
              [68.059253000000069, 23.737977000000058],
              [68.062052000000051, 23.740655000000061],
              [68.064388000000065, 23.742928000000063],
              [68.06744800000007, 23.746659000000022],
              [68.07079200000004, 23.750526000000036],
              [68.072630000000061, 23.751480000000072],
              [68.074096000000054, 23.750069000000053],
              [68.078954000000067, 23.750429000000054],
              [68.082684000000029, 23.750110000000063],
              [68.086106000000029, 23.750840000000039],
              [68.087642000000073, 23.752443000000028],
              [68.087897000000055, 23.753757000000064],
              [68.08557200000007, 23.755284000000074],
              [68.08294200000006, 23.757456000000047],
              [68.080139000000031, 23.761068000000023],
              [68.079071000000056, 23.763797000000068],
              [68.079286000000081, 23.76668200000006],
              [68.081002000000069, 23.77261100000004],
              [68.080474000000038, 23.776714000000027],
              [68.080547000000081, 23.779731000000027],
              [68.081056000000046, 23.782493000000045],
              [68.081275000000062, 23.785381000000029],
              [68.081485000000043, 23.788528000000042],
              [68.082009000000028, 23.79050200000006],
              [68.082970000000046, 23.792224000000033],
              [68.084783000000073, 23.79422500000004],
              [68.085174000000052, 23.79580500000003],
              [68.086408000000063, 23.798188000000039],
              [68.086909000000048, 23.801079000000072],
              [68.088279000000057, 23.803727000000038],
              [68.089390000000037, 23.805190000000039],
              [68.09110400000003, 23.805355000000077],
              [68.09411300000005, 23.805284000000029],
              [68.094622000000072, 23.807914000000039],
              [68.095312000000035, 23.80897600000003],
              [68.095577000000048, 23.809898000000032],
              [68.096516000000065, 23.812537000000077],
              [68.097752000000071, 23.814789000000076],
              [68.101996000000042, 23.816968000000031],
              [68.105683000000056, 23.818483000000072],
              [68.109069000000034, 23.820646000000067],
              [68.108975186000066, 23.822538378000047],
              [68.108858543000053, 23.82489126400003],
              [68.11765289300007, 23.831361771000047],
              [68.120399475000056, 23.831192017000035],
              [68.132354736000082, 23.821594238000046],
              [68.144660950000059, 23.817962646000069],
              [68.155143738000049, 23.81727027900007],
              [68.161071777000075, 23.819002151000063],
              [68.167404175000058, 23.824869156000034],
              [68.172935486000029, 23.822923660000072],
              [68.173355103000063, 23.825912476000042],
              [68.166732788000047, 23.841360092000059],
              [68.167404175000058, 23.844354630000055],
              [68.173750778000056, 23.843683584000075],
              [68.183639526000036, 23.842638016000024],
              [68.185768127000074, 23.837411880000047],
              [68.192123464000076, 23.83313337800007],
              [68.192075000000045, 23.83279600000003],
            ],
          ],
          [
            [
              [68.296542000000045, 24.952562000000057],
              [68.297799878000035, 24.951304122000067],
              [68.310836000000052, 24.938268000000051],
              [68.310323761000063, 24.934852636000073],
              [68.309988805000046, 24.932619306000049],
              [68.309639845000049, 24.930292609000048],
              [68.30930115700005, 24.928034404000073],
              [68.308887000000084, 24.925273000000061],
              [68.29823766700008, 24.924512041000071],
              [68.29069400000003, 24.923973000000046],
              [68.285848242000043, 24.91497350700007],
              [68.284469153000032, 24.912412276000055],
              [68.283048160000078, 24.909773223000059],
              [68.283006148000084, 24.909695199000055],
              [68.282983941000055, 24.909653956000056],
              [68.281598000000031, 24.907080000000065],
              [68.281018175000042, 24.905867617000069],
              [68.280282079000074, 24.904328479000071],
              [68.278859864000083, 24.901354702000049],
              [68.277892457000064, 24.899331904000064],
              [68.277874379000082, 24.899294105000024],
              [68.274451000000056, 24.89213600000005],
              [68.266721847000042, 24.889447734000044],
              [68.259506000000044, 24.886938000000043],
              [68.261345065000057, 24.884547287000032],
              [68.26487164100007, 24.879962874000057],
              [68.272501000000034, 24.870045000000061],
              [68.277699000000041, 24.844705000000033],
              [68.279008782000062, 24.84388640700007],
              [68.281339578000029, 24.842429697000057],
              [68.290314552000041, 24.836820482000064],
              [68.291067621000082, 24.83634982600006],
              [68.293293000000062, 24.834959000000026],
              [68.299791000000027, 24.831060000000036],
              [68.299618538000061, 24.824854006000066],
              [68.299407350000081, 24.817254516000048],
              [68.299141000000077, 24.80767000000003],
              [68.307873935000032, 24.804395010000064],
              [68.310215247000031, 24.80351698000004],
              [68.314735000000042, 24.801822000000072],
              [68.317068598000048, 24.800466933000052],
              [68.326907042000073, 24.79475397300007],
              [68.334877000000063, 24.790126000000043],
              [68.33730896000003, 24.787694040000076],
              [68.347871000000055, 24.777132000000051],
              [68.35277543400008, 24.767650697000079],
              [68.357618000000059, 24.758289000000048],
              [68.356478968000033, 24.752202360000069],
              [68.35647818700005, 24.752198189000069],
              [68.353719000000069, 24.738797000000034],
              [68.35386360800004, 24.738695239000037],
              [68.371262000000058, 24.726452000000052],
              [68.371262000000058, 24.716056000000037],
              [68.369003105000047, 24.711311868000053],
              [68.365969814000039, 24.70494135000007],
              [68.364765000000034, 24.702411000000041],
              [68.364765000000034, 24.690613433000067],
              [68.364765000000034, 24.686817000000076],
              [68.364765000000034, 24.674768630000074],
              [68.364765000000034, 24.67403850900007],
              [68.364765000000034, 24.671873000000062],
              [68.363417809000055, 24.668730129000039],
              [68.361703666000039, 24.664731196000048],
              [68.361692395000034, 24.66470490200004],
              [68.360866000000044, 24.662777000000062],
              [68.354369000000077, 24.654330000000073],
              [68.354369000000077, 24.651958971000056],
              [68.354369000000077, 24.648483000000056],
              [68.366064000000051, 24.645884000000024],
              [68.36982807000004, 24.64180613700006],
              [68.373861000000034, 24.637437000000034],
              [68.381008000000065, 24.625092000000052],
              [68.382023085000071, 24.61765001200007],
              [68.382958000000031, 24.610797000000048],
              [68.376460000000066, 24.604950000000031],
              [68.376209817000074, 24.599948263000044],
              [68.376176564000048, 24.599283458000059],
              [68.375810000000058, 24.591955000000041],
              [68.384907000000055, 24.575062000000059],
              [68.389455000000055, 24.565315000000055],
              [68.398460281000041, 24.56150509400004],
              [68.401593222000031, 24.560179626000036],
              [68.406348000000037, 24.558168000000023],
              [68.403812541000036, 24.554183628000033],
              [68.397252000000037, 24.543874000000073],
              [68.396023479000064, 24.534538147000035],
              [68.394391311000049, 24.522134878000031],
              [68.394003000000055, 24.519184000000052],
              [68.404399000000069, 24.504240000000038],
              [68.408947000000069, 24.495143000000041],
              [68.407648000000052, 24.477600000000052],
              [68.407648000000052, 24.473424679000061],
              [68.407648000000052, 24.452850751000028],
              [68.407648000000052, 24.452441211000064],
              [68.407648000000052, 24.452359066000042],
              [68.407648000000052, 24.449011000000041],
              [68.409522479000032, 24.440389204000041],
              [68.410897000000034, 24.434067000000027],
              [68.412670132000073, 24.431702520000044],
              [68.413083110000059, 24.431151812000053],
              [68.416744000000051, 24.426270000000045],
              [68.424541000000033, 24.42757000000006],
              [68.43363800000003, 24.437316000000067],
              [68.442349063000052, 24.437800155000048],
              [68.452430845000038, 24.438360493000062],
              [68.457028000000037, 24.438616000000025],
              [68.457580795000069, 24.43714207000005],
              [68.460927000000083, 24.428220000000067],
              [68.451181000000076, 24.407428000000039],
              [68.450575909000065, 24.40440254400005],
              [68.448582000000044, 24.394433000000049],
              [68.460277000000076, 24.391834000000074],
              [68.467339006000032, 24.389627293000046],
              [68.478495679000048, 24.386141101000078],
              [68.481069000000048, 24.38533700000005],
              [68.505433070000038, 24.37721532300003],
              [68.506409000000076, 24.37689000000006],
              [68.516186379000033, 24.373334465000028],
              [68.527850000000058, 24.369093000000078],
              [68.533136352000042, 24.367974773000071],
              [68.541256818000079, 24.366257042000029],
              [68.549654798000063, 24.364480608000065],
              [68.549695811000049, 24.364471932000072],
              [68.561637000000076, 24.361946000000046],
              [68.582429000000047, 24.359997000000078],
              [68.599972000000037, 24.369093000000078],
              [68.604545145000031, 24.371288211000035],
              [68.616215000000068, 24.37689000000006],
              [68.646753000000047, 24.378839000000028],
              [68.67924000000005, 24.378839000000028],
              [68.707829000000061, 24.380139000000042],
              [68.725453067000046, 24.375168234000057],
              [68.733169000000032, 24.372992000000067],
              [68.737829253000029, 24.362596220000057],
              [68.741616000000079, 24.354149000000064],
              [68.744322582000052, 24.340915127000073],
              [68.744345981000038, 24.340800715000057],
              [68.746009151000067, 24.332668618000071],
              [68.747463000000039, 24.325560000000053],
              [68.757209000000046, 24.300220000000024],
              [68.760930581000082, 24.287789617000044],
              [68.764129798000056, 24.277103974000056],
              [68.768408309000051, 24.268675273000042],
              [68.766846000000044, 24.256083000000046],
              [68.768830000000037, 24.248921000000053],
              [68.764819000000045, 24.233794000000046],
              [68.767032000000029, 24.225320000000067],
              [68.767173000000071, 24.216523000000052],
              [68.767161000000044, 24.204519000000062],
              [68.76729000000006, 24.177340000000072],
              [68.76546600000006, 24.168330000000026],
              [68.764544000000058, 24.161239000000023],
              [68.765539000000047, 24.150944000000038],
              [68.763884000000076, 24.144390000000044],
              [68.764185000000055, 24.138625000000047],
              [68.765459000000078, 24.126033000000064],
              [68.766972000000067, 24.116035000000068],
              [68.763818000000072, 24.099442000000067],
              [68.761777000000052, 24.078515000000039],
              [68.761495000000082, 24.07101300000005],
              [68.762391000000036, 24.047896000000037],
              [68.758451000000036, 24.022216000000071],
              [68.756887000000063, 24.008507000000066],
              [68.754218000000037, 23.989808000000039],
              [68.751530000000059, 23.970100000000059],
              [68.736527000000081, 23.97008500000004],
              [68.710941000000048, 23.970205000000078],
              [68.671248000000048, 23.970123000000058],
              [68.625673000000063, 23.97013400000003],
              [68.582869000000073, 23.970079000000055],
              [68.560778000000028, 23.970321000000069],
              [68.524039000000073, 23.969960000000071],
              [68.477711000000056, 23.970105000000046],
              [68.441311732000031, 23.97027306800004],
              [68.440027000000043, 23.970279000000062],
              [68.430548701000077, 23.970271815000046],
              [68.41496200000006, 23.970260000000053],
              [68.40670639700005, 23.970175725000047],
              [68.400906774000077, 23.970116522000069],
              [68.39752500000003, 23.970082000000048],
              [68.393555000000049, 23.966425000000072],
              [68.389823000000035, 23.966743000000065],
              [68.386492000000032, 23.967825000000062],
              [68.386241078000069, 23.968046584000035],
              [68.385440000000074, 23.968754000000047],
              [68.384600000000034, 23.969403000000057],
              [68.384467365000035, 23.969446932000039],
              [68.379703000000063, 23.971025000000054],
              [68.378047672000037, 23.970423225000047],
              [68.375079000000028, 23.969344000000035],
              [68.374262000000044, 23.968764000000078],
              [68.371424000000047, 23.965031000000067],
              [68.371104000000059, 23.960014000000058],
              [68.372540000000072, 23.955403000000047],
              [68.373560000000055, 23.950880000000041],
              [68.373198000000059, 23.948226000000034],
              [68.372736000000032, 23.945287000000064],
              [68.371444000000054, 23.942430000000058],
              [68.367643000000044, 23.940951000000041],
              [68.36465000000004, 23.940525000000036],
              [68.363084000000072, 23.941257000000064],
              [68.361204000000043, 23.942172000000028],
              [68.360742000000073, 23.944530000000043],
              [68.360806000000082, 23.946517000000028],
              [68.363337000000058, 23.949582000000078],
              [68.364023000000032, 23.95157800000004],
              [68.363386000000048, 23.953682000000072],
              [68.361557000000062, 23.956277000000057],
              [68.361093000000039, 23.959018000000071],
              [68.358026513000084, 23.97001375800005],
              [68.355958000000044, 23.977431000000024],
              [68.353196000000082, 23.978761000000077],
              [68.347987000000046, 23.979300000000023],
              [68.346624000000077, 23.979028000000028],
              [68.341718000000071, 23.978072000000054],
              [68.338998000000061, 23.977277000000072],
              [68.335473000000036, 23.975720000000024],
              [68.334059000000082, 23.972956000000067],
              [68.333082000000047, 23.962777000000074],
              [68.331471000000079, 23.957088000000056],
              [68.329381000000069, 23.948454000000027],
              [68.331921000000079, 23.945732000000078],
              [68.335379000000046, 23.945696000000055],
              [68.34096100000005, 23.94737200000003],
              [68.343108000000029, 23.948001000000033],
              [68.346566000000053, 23.947759000000076],
              [68.347560917000067, 23.947668682000028],
              [68.349375000000066, 23.947504000000038],
              [68.350800000000049, 23.946443000000045],
              [68.352374000000054, 23.943412000000023],
              [68.351207000000045, 23.940250000000049],
              [68.348651000000075, 23.937604000000078],
              [68.346760208000035, 23.936434509000037],
              [68.343964000000028, 23.934705000000065],
              [68.339005000000043, 23.933536000000061],
              [68.335680000000082, 23.933696000000054],
              [68.329477000000054, 23.935324000000037],
              [68.325532904000056, 23.938794947000076],
              [68.323917000000051, 23.940217000000075],
              [68.320574000000079, 23.94124400000004],
              [68.316098000000068, 23.939647000000036],
              [68.314756000000045, 23.93572000000006],
              [68.314792776000047, 23.935504023000078],
              [68.315566000000047, 23.930963000000077],
              [68.31518200000005, 23.926618000000076],
              [68.314068000000077, 23.923128000000077],
              [68.312215000000037, 23.920927000000063],
              [68.308197000000064, 23.920207000000062],
              [68.30224400000003, 23.921188000000029],
              [68.294594000000075, 23.923874000000069],
              [68.289498000000037, 23.929208000000074],
              [68.284111000000053, 23.937139000000059],
              [68.28073100000006, 23.939900000000023],
              [68.276247998000031, 23.939606375000039],
              [68.276227000000063, 23.939605000000029],
              [68.276203563000081, 23.939584231000026],
              [68.272731000000078, 23.936507000000063],
              [68.268102000000056, 23.930786000000069],
              [68.265812000000039, 23.926841000000024],
              [68.263790000000029, 23.921382000000051],
              [68.266488000000038, 23.917308000000048],
              [68.268594000000064, 23.914096000000029],
              [68.276255000000049, 23.909195000000068],
              [68.283011545000079, 23.906325492000065],
              [68.285186000000067, 23.905402000000038],
              [68.286716000000069, 23.904541000000052],
              [68.28922300000005, 23.903101000000049],
              [68.291831000000059, 23.897426000000053],
              [68.290413000000058, 23.892762000000062],
              [68.287554000000057, 23.890146000000072],
              [68.282820000000072, 23.889471000000071],
              [68.277078000000074, 23.890455000000031],
              [68.274454159000072, 23.891428259000065],
              [68.272503000000029, 23.892152000000067],
              [68.265195000000062, 23.895972000000029],
              [68.257429000000059, 23.900671000000045],
              [68.253637000000083, 23.901295000000061],
              [68.251489000000049, 23.90076300000004],
              [68.23876400000006, 23.895009000000073],
              [68.233035031000043, 23.890895410000041],
              [68.232703000000072, 23.890657000000033],
              [68.227917000000048, 23.88731400000006],
              [68.220198000000039, 23.884807000000023],
              [68.211510000000033, 23.882183000000055],
              [68.203687000000059, 23.87947500000007],
              [68.201050000000066, 23.876664000000062],
              [68.198921000000041, 23.864320000000077],
              [68.196331000000043, 23.853518000000065],
              [68.194119000000057, 23.847025000000031],
              [68.194115108000062, 23.84699790600007],
              [68.188934326000037, 23.850088120000066],
              [68.178115845000036, 23.85421180700007],
              [68.173210144000052, 23.851127625000061],
              [68.169869503000029, 23.854477756000051],
              [68.167839050000055, 23.856513977000077],
              [68.15990448000008, 23.854740143000072],
              [68.143501282000045, 23.869649887000037],
              [68.136720249000064, 23.869554453000035],
              [68.136047363000046, 23.869544983000026],
              [68.134696960000042, 23.865888596000048],
              [68.136940002000074, 23.856307983000079],
              [68.13704524700006, 23.856063801000062],
              [68.143302917000028, 23.841545105000023],
              [68.142105103000063, 23.839685440000039],
              [68.124099731000058, 23.842275620000066],
              [68.119544983000083, 23.844699860000048],
              [68.116874695000035, 23.851749420000033],
              [68.11165618900003, 23.853527069000052],
              [68.109704966000038, 23.854010294000034],
              [68.103530884000065, 23.855539322000027],
              [68.10493716600007, 23.852144802000055],
              [68.106210631000067, 23.849070879000067],
              [68.099015000000065, 23.848218000000031],
              [68.096169000000032, 23.847375000000056],
              [68.092029000000082, 23.846768000000054],
              [68.085862000000077, 23.847038000000055],
              [68.081964000000028, 23.848271000000068],
              [68.075265000000059, 23.852722000000028],
              [68.071025000000077, 23.856172000000072],
              [68.06490000000008, 23.860765000000072],
              [68.057253287000037, 23.859729827000024],
              [68.051897380000071, 23.859004772000048],
              [68.047058105000076, 23.881397247000052],
              [68.047828674000073, 23.889896393000072],
              [68.050384521000069, 23.897058487000038],
              [68.055335999000079, 23.903003693000073],
              [68.048477173000038, 23.905183792000059],
              [68.045639038000047, 23.911024094000027],
              [68.041961670000035, 23.911851883000054],
              [68.029830933000028, 23.903213501000039],
              [68.032752991000052, 23.894605637000041],
              [68.028160095000032, 23.879604340000071],
              [68.027687073000038, 23.86927795400004],
              [68.037545676000036, 23.857061912000063],
              [68.036490000000072, 23.856919000000062],
              [68.037883000000079, 23.852624000000048],
              [68.042839000000072, 23.843161000000066],
              [68.047112000000084, 23.826347000000055],
              [68.043957000000034, 23.802829000000031],
              [68.038989000000072, 23.785586000000023],
              [68.035303000000056, 23.778174000000035],
              [68.028631000000075, 23.769915000000026],
              [68.024665000000084, 23.764783000000023],
              [68.021014000000037, 23.761956000000055],
              [68.01747400000005, 23.760311000000058],
              [68.012776000000031, 23.759168000000045],
              [68.008065000000045, 23.758547000000078],
              [68.003180000000043, 23.759234000000049],
              [67.998705000000029, 23.760715000000062],
              [67.993915000000072, 23.763368000000071],
              [67.989193000000057, 23.763271000000032],
              [67.988256000000035, 23.760632000000044],
              [67.986958000000072, 23.760998000000029],
              [67.987043000000028, 23.763358000000039],
              [67.984178000000043, 23.763430000000028],
              [67.982624000000044, 23.762612000000047],
              [67.981651000000056, 23.761413000000061],
              [67.980373000000043, 23.760993000000042],
              [67.98005100000006, 23.762428000000057],
              [67.979482000000075, 23.762285000000077],
              [67.97607400000004, 23.761167000000057],
              [67.975713000000042, 23.758408000000031],
              [67.973027000000059, 23.757042000000069],
              [67.970019000000036, 23.757111000000066],
              [67.96452400000004, 23.759355000000028],
              [67.958017000000041, 23.761971000000074],
              [67.954138000000057, 23.762775000000033],
              [67.946018000000038, 23.766800000000046],
              [67.938743000000045, 23.771370000000047],
              [67.935231000000044, 23.774439000000029],
              [67.930567000000053, 23.777748000000031],
              [67.929646000000048, 23.780219000000045],
              [67.928842000000031, 23.783739000000025],
              [67.92873000000003, 23.788192000000038],
              [67.928238000000078, 23.790671000000032],
              [67.930794000000049, 23.791510000000073],
              [67.935897000000068, 23.793582000000072],
              [67.939738000000034, 23.794580000000053],
              [67.943865000000073, 23.795583000000079],
              [67.949672000000078, 23.79806300000007],
              [67.951645000000042, 23.799283000000059],
              [67.95500700000008, 23.802235000000053],
              [67.959628000000066, 23.806523000000027],
              [67.942895000000078, 23.817311000000075],
              [67.942218000000082, 23.82149000000004],
              [67.939706000000058, 23.824582000000078],
              [67.932640000000049, 23.832196000000067],
              [67.924698000000035, 23.840416000000062],
              [67.920138000000065, 23.839533000000074],
              [67.91676300000006, 23.840630000000033],
              [67.911492000000067, 23.845107000000041],
              [67.905595000000062, 23.851541000000054],
              [67.901455000000055, 23.856692000000066],
              [67.895987000000048, 23.863260000000025],
              [67.887784000000067, 23.870423000000073],
              [67.879305000000045, 23.877555000000029],
              [67.872543000000064, 23.884227000000067],
              [67.86849200000006, 23.891347000000053],
              [67.865150000000028, 23.898743000000024],
              [67.864253000000076, 23.905799000000059],
              [67.863857000000053, 23.910112000000026],
              [67.863483000000031, 23.913511000000028],
              [67.866305000000068, 23.920907000000057],
              [67.869805000000042, 23.929760000000044],
              [67.854720000000043, 23.936640000000068],
              [67.85092700000007, 23.933679000000041],
              [67.845349000000056, 23.92766400000005],
              [67.841559000000075, 23.924569000000076],
              [67.838803000000041, 23.920318000000066],
              [67.835774000000072, 23.915537000000029],
              [67.833891000000051, 23.910781000000043],
              [67.831877000000077, 23.901882000000057],
              [67.831224000000077, 23.893879000000027],
              [67.831447000000082, 23.885238000000072],
              [67.832468000000063, 23.878972000000033],
              [67.833173000000045, 23.873878000000047],
              [67.834733000000028, 23.868933000000027],
              [67.836685000000045, 23.859934000000067],
              [67.837399000000062, 23.854446000000053],
              [67.838884000000064, 23.846878000000061],
              [67.839683000000036, 23.838114000000076],
              [67.836004000000059, 23.832545000000039],
              [67.83316700000006, 23.828337000000033],
              [67.828397000000052, 23.824294000000066],
              [67.825961000000063, 23.822064000000069],
              [67.824420000000032, 23.820268000000056],
              [67.822095000000047, 23.818144000000075],
              [67.819765000000075, 23.816228000000024],
              [67.818227000000036, 23.814328000000046],
              [67.816026000000079, 23.811792000000025],
              [67.81165500000003, 23.809934000000055],
              [67.804776000000061, 23.808644000000072],
              [67.800029000000052, 23.808125000000075],
              [67.797645000000045, 23.808281000000022],
              [67.792869000000053, 23.808901000000048],
              [67.789165000000082, 23.80918500000007],
              [67.786335000000065, 23.809019000000035],
              [67.781698000000063, 23.808607000000052],
              [67.777499000000034, 23.808826000000067],
              [67.774313000000063, 23.809274000000073],
              [67.770882000000029, 23.810443000000078],
              [67.766604000000029, 23.813667000000066],
              [67.765743000000043, 23.81624000000005],
              [67.763304000000062, 23.818467000000055],
              [67.762263000000075, 23.819274000000064],
              [67.760128000000066, 23.822856000000058],
              [67.75758200000007, 23.824874000000023],
              [67.754204000000072, 23.828324000000066],
              [67.751460000000066, 23.829197000000079],
              [67.750515000000064, 23.826376000000039],
              [67.749428013000056, 23.822161128000062],
              [67.734985352000081, 23.837791443000071],
              [67.725215098000035, 23.844244486000036],
              [67.711868286000083, 23.853059769000026],
              [67.709037781000063, 23.856201172000056],
              [67.705062866000048, 23.855648041000052],
              [67.717323303000057, 23.836683273000062],
              [67.721137981000084, 23.83335149800007],
              [67.736498010000048, 23.819935907000058],
              [67.729144525000038, 23.818550844000072],
              [67.72783400000003, 23.818304000000069],
              [67.728592000000049, 23.815314000000058],
              [67.728435000000047, 23.812718000000075],
              [67.726799000000028, 23.810297000000048],
              [67.719704000000036, 23.807961000000034],
              [67.707741000000055, 23.806035000000065],
              [67.702526000000034, 23.806125000000065],
              [67.695501000000036, 23.806071000000031],
              [67.687426000000073, 23.807134000000076],
              [67.678854000000058, 23.809947000000022],
              [67.675875000000076, 23.811020000000042],
              [67.669228000000032, 23.813773000000026],
              [67.668512000000078, 23.810854000000063],
              [67.666228000000046, 23.811528000000067],
              [67.665073000000064, 23.812331000000029],
              [67.66457900000006, 23.813875000000053],
              [67.664068000000043, 23.816040000000044],
              [67.661241000000075, 23.816040000000044],
              [67.656428000000062, 23.817902000000061],
              [67.649330000000077, 23.824789000000067],
              [67.648781000000042, 23.832448000000056],
              [67.648904000000073, 23.836287000000027],
              [67.649250000000052, 23.840239000000054],
              [67.649437000000034, 23.845841000000064],
              [67.645722000000035, 23.849075000000028],
              [67.644656875000067, 23.848628992000044],
              [67.644153000000074, 23.848418000000038],
              [67.642371000000082, 23.847236000000066],
              [67.639108000000078, 23.846332000000075],
              [67.637579000000073, 23.848371000000043],
              [67.636914000000047, 23.851984000000073],
              [67.624407000000076, 23.853252000000055],
              [67.613347000000033, 23.858111000000065],
              [67.600525000000061, 23.862483000000054],
              [67.575257000000079, 23.874134000000026],
              [67.560912450000046, 23.880293795000057],
              [67.550931000000048, 23.884580000000028],
              [67.536865392000038, 23.891710498000066],
              [67.526887000000045, 23.896769000000063],
              [67.514245000000074, 23.902736000000061],
              [67.501783000000046, 23.910218000000043],
              [67.491635000000031, 23.916094000000044],
              [67.489140680000048, 23.917517880000048],
              [67.486732483000083, 23.922273636000057],
              [67.488479614000028, 23.930339813000046],
              [67.485527039000033, 23.937372208000056],
              [67.48292998200003, 23.939095563000024],
              [67.481201172000056, 23.940242767000029],
              [67.469512939000083, 23.939262390000067],
              [67.465980530000081, 23.942131042000028],
              [67.466087341000048, 23.945363998000062],
              [67.46902465800008, 23.947042465000038],
              [67.47901153600003, 23.94683647200003],
              [67.488883972000053, 23.958545685000047],
              [67.494285583000078, 23.961202621000041],
              [67.501480103000063, 23.962530136000055],
              [67.50437164300007, 23.966039658000057],
              [67.500762939000083, 23.969846725000025],
              [67.492675781000059, 23.97331237800006],
              [67.486419678000061, 23.974071503000062],
              [67.489258000000063, 23.984168000000068],
              [67.490330000000029, 24.005563000000052],
              [67.495597000000032, 24.014779000000033],
              [67.496913000000063, 24.030578000000048],
              [67.501640035000037, 24.038849190000064],
              [67.501652628000045, 24.038871224000047],
              [67.502180000000067, 24.039794000000029],
              [67.504533889000072, 24.040578762000052],
              [67.50810400000006, 24.041769000000045],
              [67.510209064000037, 24.041969533000042],
              [67.515600462000066, 24.042483130000051],
              [67.521929000000057, 24.043086000000073],
              [67.523975761000031, 24.043791769000052],
              [67.52504134000003, 24.044159204000039],
              [67.53197042100004, 24.046548504000043],
              [67.537396351000041, 24.048419485000068],
              [67.537440778000075, 24.048434805000056],
              [67.54102000000006, 24.049669000000051],
              [67.541863025000055, 24.049879783000051],
              [67.548919000000069, 24.051644000000067],
              [67.56076900000005, 24.056910000000073],
              [67.566636036000034, 24.061177598000029],
              [67.572618000000034, 24.064810000000023],
              [67.572618000000034, 24.072051000000044],
              [67.566035000000056, 24.072051000000044],
              [67.559452000000078, 24.078634000000022],
              [67.559452000000078, 24.08442205800003],
              [67.559594813000047, 24.086751556000024],
              [67.559653164000053, 24.087703341000065],
              [67.559692497000071, 24.088344926000048],
              [67.559947409000074, 24.091660328000046],
              [67.560110000000066, 24.093775000000051],
              [67.560775490000083, 24.09743443900004],
              [67.561508208000078, 24.102073070000074],
              [67.561639409000065, 24.103779286000076],
              [67.561826637000081, 24.10621410400006],
              [67.562085000000081, 24.109574000000066],
              [67.560871012000064, 24.113459426000077],
              [67.558794000000034, 24.120107000000075],
              [67.556541818000028, 24.124289763000036],
              [67.556124642000043, 24.125064545000043],
              [67.554186000000072, 24.128665000000069],
              [67.550236000000041, 24.130640000000028],
              [67.543653000000063, 24.132615000000044],
              [67.539045000000044, 24.138540000000035],
              [67.539878307000038, 24.141664979000041],
              [67.540694876000032, 24.144727191000072],
              [67.540952494000067, 24.145693283000071],
              [67.541470224000079, 24.147634820000064],
              [67.543396489000031, 24.153282761000071],
              [67.543978060000029, 24.154930448000073],
              [67.544383534000076, 24.156079222000074],
              [67.545628000000079, 24.159605000000056],
              [67.556819000000075, 24.159605000000056],
              [67.560355854000079, 24.158897683000077],
              [67.560633117000066, 24.158842235000066],
              [67.563737278000076, 24.158221449000052],
              [67.568121792000056, 24.157344613000078],
              [67.569234015000063, 24.157122186000038],
              [67.576976724000076, 24.156306474000075],
              [67.583151000000043, 24.155656000000079],
              [67.588662330000034, 24.157093438000061],
              [67.590717384000072, 24.157629427000074],
              [67.593318606000082, 24.158307864000051],
              [67.594834811000055, 24.158703313000046],
              [67.598292000000072, 24.159605000000056],
              [67.600353316000053, 24.161552021000034],
              [67.601808341000037, 24.162926368000058],
              [67.605010098000037, 24.165950595000027],
              [67.609248084000058, 24.169953595000038],
              [67.609482397000079, 24.170174915000075],
              [67.610141000000056, 24.17079700000005],
              [67.614749000000074, 24.177380000000028],
              [67.617783024000062, 24.180160933000025],
              [67.619925206000062, 24.182124418000058],
              [67.622649000000081, 24.18462100000005],
              [67.624160516000074, 24.18462100000005],
              [67.627922363000039, 24.18462100000005],
              [67.628048678000027, 24.18462100000005],
              [67.628156130000036, 24.18462100000005],
              [67.628263926000045, 24.18462100000005],
              [67.629937232000032, 24.18462100000005],
              [67.63122955800003, 24.18462100000005],
              [67.633735487000081, 24.18462100000005],
              [67.634326486000077, 24.18462100000005],
              [67.637132000000065, 24.18462100000005],
              [67.641300423000075, 24.18158952400006],
              [67.64437300000003, 24.179355000000044],
              [67.647872387000064, 24.172356226000034],
              [67.648323000000062, 24.171455000000037],
              [67.660275437000053, 24.167108460000065],
              [67.661120906000065, 24.166800992000049],
              [67.661404711000046, 24.163537101000031],
              [67.661509862000059, 24.162327706000042],
              [67.661580063000031, 24.161520289000066],
              [67.661845922000055, 24.15846252700004],
              [67.697781573000043, 24.194140966000077],
              [67.71308014400006, 24.209330037000029],
              [67.71400701500005, 24.21808720100006],
              [67.716219668000065, 24.222777754000049],
              [67.724187431000075, 24.227672062000067],
              [67.735327697000059, 24.22413292400006],
              [67.741788000000042, 24.217303000000072],
              [67.741788000000042, 24.225620000000049],
              [67.742843022000045, 24.231249113000047],
              [67.74345100000005, 24.234493000000043],
              [67.751215000000059, 24.24336500000004],
              [67.76455827500007, 24.244792200000063],
              [67.764868571000079, 24.244823631000031],
              [67.764897702000042, 24.244824546000075],
              [67.770175386000062, 24.245329143000049],
              [67.772841000000028, 24.245583000000067],
              [67.778026071000056, 24.243607820000022],
              [67.778311211000073, 24.243499199000041],
              [67.782371457000067, 24.241952506000075],
              [67.784486000000072, 24.241147000000069],
              [67.787503117000028, 24.241147000000069],
              [67.790713442000083, 24.241147000000069],
              [67.794249432000072, 24.241147000000069],
              [67.79834900000003, 24.241147000000069],
              [67.804655834000073, 24.241462057000035],
              [67.809439000000054, 24.241701000000035],
              [67.81332100000003, 24.260555000000068],
              [67.81332100000003, 24.262773000000038],
              [67.806112000000041, 24.266100000000051],
              [67.803387150000049, 24.268824850000044],
              [67.798903000000053, 24.27330900000004],
              [67.798204480000038, 24.274491030000036],
              [67.79490303700004, 24.28007769900006],
              [67.794816150000031, 24.280224727000075],
              [67.793618231000039, 24.282251835000068],
              [67.792493429000046, 24.284155214000066],
              [67.791694000000064, 24.28550800000005],
              [67.782268000000045, 24.295490000000029],
              [67.778386000000069, 24.307134000000076],
              [67.775613000000078, 24.314898000000028],
              [67.774504000000036, 24.324879000000067],
              [67.779495000000054, 24.332088000000056],
              [67.780077541000082, 24.332554005000077],
              [67.778207745000032, 24.33571598900005],
              [67.807721389000051, 24.350552806000053],
              [67.813827421000042, 24.355003539000052],
              [67.813995413000043, 24.355125990000033],
              [67.827645873000051, 24.360716019000051],
              [67.852137000000027, 24.377558000000079],
              [67.844374000000073, 24.388094000000024],
              [67.836610000000064, 24.402512000000058],
              [67.838784077000071, 24.406859719000067],
              [67.839994453000031, 24.409280229000046],
              [67.841601000000082, 24.41249300000004],
              [67.847146000000066, 24.420256000000052],
              [67.856573000000083, 24.42524700000007],
              [67.864891000000057, 24.427465000000041],
              [67.877090000000067, 24.425801000000035],
              [67.881329608000044, 24.426003059000038],
              [67.888735000000054, 24.426356000000055],
              [67.897053000000028, 24.425801000000035],
              [67.912025000000028, 24.423583000000065],
              [67.914475636000077, 24.422882818000062],
              [67.927551000000051, 24.419147000000066],
              [67.930546825000079, 24.418866242000036],
              [67.933150051000041, 24.418622276000065],
              [67.945296000000042, 24.417484000000059],
              [67.952756435000083, 24.418416554000032],
              [67.954327994000039, 24.418648163000057],
              [67.955810184000029, 24.418866601000047],
              [67.96400906000008, 24.42022834900007],
              [67.964035983000031, 24.420237101000055],
              [67.964149000000077, 24.420256000000052],
              [67.964880320000077, 24.42513000200006],
              [67.965578970000081, 24.429786266000065],
              [67.965813000000082, 24.431346000000076],
              [67.965813000000082, 24.438234499000032],
              [67.965813000000082, 24.444351952000034],
              [67.965813000000082, 24.447771112000055],
              [67.965813000000082, 24.450200000000052],
              [67.965258000000063, 24.457409000000041],
              [67.964365318000034, 24.461278719000063],
              [67.963595000000055, 24.46461800000003],
              [67.97302707700004, 24.467646952000052],
              [67.992424988000039, 24.475033514000074],
              [67.982593865000069, 24.504779001000031],
              [67.973240710000084, 24.504835653000043],
              [67.963214836000077, 24.502209829000037],
              [67.95092743400005, 24.502202170000032],
              [67.951950000000068, 24.507315000000062],
              [67.956386000000066, 24.516742000000079],
              [67.964149000000077, 24.525060000000053],
              [67.973022000000071, 24.53670500000004],
              [67.984667000000059, 24.545577000000037],
              [67.993539000000055, 24.546686000000022],
              [67.994555000000048, 24.547795000000065],
              [67.991321000000028, 24.547795000000065],
              [67.991321000000028, 24.566998793000039],
              [67.991321000000028, 24.571085000000039],
              [67.991452473000038, 24.573190470000043],
              [67.99187500000005, 24.579957000000036],
              [67.989103000000057, 24.588275000000067],
              [67.987745892000078, 24.589005850000035],
              [67.985287164000056, 24.59032988000007],
              [67.981894000000068, 24.592157000000043],
              [67.979573108000068, 24.594272690000025],
              [67.977027367000062, 24.596617714000047],
              [67.969695000000058, 24.603247000000067],
              [67.969140000000039, 24.610456000000056],
              [67.96872453900005, 24.612533008000071],
              [67.966367000000048, 24.624319000000071],
              [67.967819504000033, 24.632304171000044],
              [67.968134729000042, 24.634037127000056],
              [67.968586000000073, 24.636518000000024],
              [67.967719101000057, 24.640331668000044],
              [67.967140007000069, 24.642879224000069],
              [67.965813000000082, 24.648717000000033],
              [67.963040000000035, 24.657035000000064],
              [67.962822247000076, 24.661176239000042],
              [67.962702038000032, 24.663462373000073],
              [67.962486000000069, 24.667571000000066],
              [67.965258000000063, 24.674225000000035],
              [67.973022000000071, 24.686425000000042],
              [67.980795571000044, 24.694509223000068],
              [67.980902709000077, 24.694620642000075],
              [67.985745517000055, 24.699656980000043],
              [67.986885000000029, 24.700842000000023],
              [67.993539000000055, 24.710823000000062],
              [67.994093000000078, 24.717478000000028],
              [67.998219822000067, 24.720969398000022],
              [68.001302000000067, 24.723577000000034],
              [68.007956000000036, 24.719696000000056],
              [68.010174000000063, 24.727459000000067],
              [68.00740200000007, 24.734113000000036],
              [68.00740200000007, 24.744095000000073],
              [68.011838000000068, 24.747422000000029],
              [68.019047000000057, 24.750749000000042],
              [68.022928000000036, 24.755739000000062],
              [68.026692014000048, 24.762325318000023],
              [68.026709548000042, 24.762355998000032],
              [68.029583000000059, 24.76738400000005],
              [68.032355000000052, 24.777920000000051],
              [68.031246000000067, 24.789565000000039],
              [68.026810000000069, 24.800101000000041],
              [68.026255000000049, 24.807864000000052],
              [68.026255000000049, 24.810592435000046],
              [68.026255000000049, 24.826718000000028],
              [68.031246000000067, 24.835035000000062],
              [68.041227000000049, 24.842244000000051],
              [68.044918221000046, 24.845473454000057],
              [68.050100000000043, 24.850007000000062],
              [68.070062000000064, 24.856107000000065],
              [68.078935000000058, 24.853889000000038],
              [68.091134000000068, 24.854444000000058],
              [68.097339643000055, 24.848237736000044],
              [68.10111500000005, 24.844462000000078],
              [68.108879000000059, 24.838363000000072],
              [68.110752284000057, 24.838612786000056],
              [68.117196000000035, 24.839472000000058],
              [68.123680639000042, 24.840468952000037],
              [68.123296000000039, 24.843353000000036],
              [68.11608700000005, 24.854444000000058],
              [68.113075090000052, 24.857182325000053],
              [68.109374048000063, 24.86030792300005],
              [68.106603715000062, 24.862647521000042],
              [68.102224000000035, 24.866088000000047],
              [68.099591556000064, 24.868720760000031],
              [68.093063088000065, 24.87512349800005],
              [68.087807000000055, 24.879951000000062],
              [68.085589000000084, 24.888269000000037],
              [68.086698000000069, 24.895478000000026],
              [68.091689000000031, 24.900469000000044],
              [68.100561000000027, 24.900469000000044],
              [68.10923972300003, 24.904347146000077],
              [68.11664200000007, 24.90712300000007],
              [68.122742000000073, 24.913222000000076],
              [68.123851000000059, 24.919322000000022],
              [68.124024304000045, 24.922444291000033],
              [68.124142517000053, 24.924574049000057],
              [68.124226862000057, 24.926073381000037],
              [68.124651050000068, 24.933431679000023],
              [68.124882279000076, 24.937397124000029],
              [68.124771901000031, 24.939726982000025],
              [68.124770667000064, 24.939753204000056],
              [68.124481013000036, 24.945908762000045],
              [68.124960000000044, 24.954811000000063],
              [68.128287000000057, 24.963129000000038],
              [68.135495000000049, 24.970892000000049],
              [68.141595000000052, 24.971447000000069],
              [68.14658600000007, 24.972556000000054],
              [68.153240000000039, 24.975328000000047],
              [68.163900542000079, 24.975373253000043],
              [68.171116789000052, 24.975643686000069],
              [68.175421000000028, 24.975883000000067],
              [68.182075000000054, 24.974774000000025],
              [68.184848000000045, 24.96701100000007],
              [68.190393000000029, 24.96701100000007],
              [68.199819000000048, 24.965902000000028],
              [68.205919000000051, 24.962575000000072],
              [68.212005407000049, 24.956082491000075],
              [68.214237000000082, 24.953702000000078],
              [68.222000000000037, 24.95148400000005],
              [68.226991000000055, 24.956475000000069],
              [68.230872000000033, 24.968120000000056],
              [68.231563450000067, 24.969243275000053],
              [68.235309000000029, 24.975328000000047],
              [68.240511436000077, 24.97761717700007],
              [68.249172000000044, 24.981428000000051],
              [68.252917933000049, 24.984103927000035],
              [68.255276578000064, 24.98578883700003],
              [68.259933186000069, 24.989115414000025],
              [68.277699000000041, 24.981151000000068],
              [68.279957532000083, 24.975504669000031],
              [68.280629930000032, 24.973823676000052],
              [68.280662231000065, 24.973742922000042],
              [68.28170046300005, 24.97114734400003],
              [68.282897000000048, 24.968156000000079],
              [68.291727592000029, 24.958064079000053],
              [68.293397485000071, 24.956155666000029],
              [68.29569555900008, 24.95352934400006],
              [68.296542000000045, 24.952562000000057],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "147",
      properties: { name: "Umer Kot", count: 1480 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [70.244267000000036, 25.766455000000065],
            [70.25244500000008, 25.757099000000039],
            [70.258992905000071, 25.735295552000025],
            [70.262929000000042, 25.722189000000071],
            [70.264159985000049, 25.720831816000043],
            [70.241921000000048, 25.706180000000074],
            [70.196489000000042, 25.67697400000003],
            [70.180263000000082, 25.660099000000059],
            [70.162090000000035, 25.660748000000069],
            [70.158323986000084, 25.656713015000037],
            [70.153003000000069, 25.651012000000037],
            [70.152354000000059, 25.633488000000057],
            [70.141970000000072, 25.623104000000069],
            [70.140672000000052, 25.613368000000037],
            [70.128340000000037, 25.601037000000076],
            [70.125744000000054, 25.590652000000034],
            [70.141970000000072, 25.589354000000071],
            [70.165335000000084, 25.590652000000034],
            [70.184157000000084, 25.599739000000056],
            [70.197787000000062, 25.602335000000039],
            [70.22634400000004, 25.595844000000056],
            [70.224397000000067, 25.586758000000032],
            [70.20882000000006, 25.575724000000037],
            [70.198436000000072, 25.566638000000069],
            [70.18870000000004, 25.556903000000034],
            [70.17052700000005, 25.546518000000049],
            [70.172474000000079, 25.533537000000024],
            [70.191296000000079, 25.536783000000071],
            [70.20946900000007, 25.538730000000044],
            [70.223099000000047, 25.547167000000059],
            [70.248411000000033, 25.562095000000056],
            [70.26593500000007, 25.560797000000036],
            [70.28475700000007, 25.552359000000024],
            [70.28281000000004, 25.536783000000071],
            [70.276320000000055, 25.523153000000036],
            [70.280863000000068, 25.506278000000066],
            [70.282161000000031, 25.495893000000024],
            [70.273587228000054, 25.485175903000027],
            [70.26398800000004, 25.473177000000078],
            [70.241921000000048, 25.455653000000041],
            [70.231643022000071, 25.436307040000031],
            [70.230887000000052, 25.434884000000068],
            [70.233484000000033, 25.42255300000005],
            [70.250358000000062, 25.42385100000007],
            [70.254902000000072, 25.419308000000058],
            [70.252954000000045, 25.40373100000005],
            [70.267882000000043, 25.406327000000033],
            [70.286704000000043, 25.425798000000043],
            [70.296440000000075, 25.424500000000023],
            [70.30422800000008, 25.416711000000078],
            [70.317209000000048, 25.40308200000004],
            [70.329540000000065, 25.40373100000005],
            [70.32240100000007, 25.397240000000068],
            [70.324997000000053, 25.387505000000033],
            [70.305526000000043, 25.379068000000075],
            [70.286055000000033, 25.369332000000043],
            [70.278004924000072, 25.360274889000038],
            [70.275671000000045, 25.357649000000038],
            [70.293195000000082, 25.34596700000003],
            [70.30293000000006, 25.343371000000047],
            [70.30293000000006, 25.318707000000074],
            [70.30228100000005, 25.291448000000059],
            [70.30378109600008, 25.279071634000047],
            [70.304877000000033, 25.270030000000077],
            [70.300419257000044, 25.260278258000028],
            [70.294493000000045, 25.247314000000074],
            [70.278267000000028, 25.231737000000066],
            [70.253604000000053, 25.220054000000061],
            [70.252305000000035, 25.233035000000029],
            [70.241272000000038, 25.239525000000071],
            [70.22699300000005, 25.238876000000062],
            [70.213363000000072, 25.226545000000044],
            [70.199658155000066, 25.229180547000055],
            [70.199533342000052, 25.229204550000077],
            [70.196489000000042, 25.229790000000037],
            [70.164037000000064, 25.229141000000027],
            [70.155725696000047, 25.22335905400007],
            [70.155723088000059, 25.223357240000041],
            [70.149109000000067, 25.218756000000042],
            [70.139374000000032, 25.210319000000027],
            [70.125744000000054, 25.210319000000027],
            [70.111465000000067, 25.203180000000032],
            [70.097437807000063, 25.207271133000063],
            [70.080312000000049, 25.212266000000056],
            [70.060841000000039, 25.208372000000054],
            [70.059973623000076, 25.20807385300003],
            [70.040072000000066, 25.201233000000059],
            [70.021899000000076, 25.207723000000044],
            [70.00834913500006, 25.202400132000037],
            [70.003726000000029, 25.200584000000049],
            [69.979712000000063, 25.204478000000051],
            [69.944015000000036, 25.221353000000079],
            [69.928197650000072, 25.229383341000073],
            [69.90182800000008, 25.242771000000062],
            [69.892879839000045, 25.242426860000023],
            [69.884953000000053, 25.242122000000052],
            [69.867855288000044, 25.233572991000074],
            [69.846819752000044, 25.223055035000073],
            [69.84202907100007, 25.220659651000062],
            [69.829136000000062, 25.214213000000029],
            [69.814817793000032, 25.206723492000037],
            [69.811684168000056, 25.20508436800003],
            [69.811682231000077, 25.205083355000056],
            [69.800409150000064, 25.199186679000036],
            [69.767492782000033, 25.181968922000067],
            [69.754884189000052, 25.175373672000035],
            [69.744762000000037, 25.170079000000044],
            [69.727031063000084, 25.164623304000031],
            [69.717185780000079, 25.161593973000038],
            [69.696235897000065, 25.155147828000054],
            [69.685700000000054, 25.151906000000054],
            [69.684402000000034, 25.135031000000026],
            [69.702739296000061, 25.126124706000041],
            [69.707118000000037, 25.123998000000029],
            [69.707610230000057, 25.123681557000054],
            [69.717412430000081, 25.117379950000043],
            [69.721952705000035, 25.114461112000072],
            [69.725291000000084, 25.112315000000024],
            [69.741517000000044, 25.104527000000076],
            [69.737622000000044, 25.083758000000046],
            [69.741638395000052, 25.076930036000078],
            [69.750603000000069, 25.061690000000056],
            [69.733728000000042, 25.059094000000073],
            [69.735862350000048, 25.04586037200005],
            [69.736973000000035, 25.038974000000053],
            [69.741517000000044, 25.019503000000043],
            [69.740219000000081, 25.005874000000063],
            [69.725291000000084, 25.001330000000053],
            [69.707767000000047, 24.99873400000007],
            [69.698225922000063, 25.00338836800006],
            [69.689533125000082, 25.007628924000073],
            [69.681157000000042, 25.011715000000038],
            [69.670552532000045, 25.020198330000028],
            [69.655195000000049, 25.032484000000068],
            [69.638969000000031, 25.044816000000026],
            [69.625340000000051, 25.039623000000063],
            [69.619952788000035, 25.034104605000039],
            [69.612015979000034, 25.025974528000063],
            [69.598729000000048, 25.012364000000048],
            [69.586552225000048, 24.98557415700003],
            [69.585749000000078, 24.98380700000007],
            [69.585016091000057, 24.983131920000062],
            [69.585016000000053, 24.983132000000069],
            [69.581555255000069, 24.979944296000042],
            [69.581555312000035, 24.979944209000053],
            [69.57322524500006, 24.972271415000023],
            [69.563903781000079, 24.963685461000068],
            [69.561086000000046, 24.96109000000007],
            [69.541370676000042, 24.938558821000072],
            [69.523377346000075, 24.917995581000071],
            [69.520736919000058, 24.914978034000058],
            [69.515653000000043, 24.909168000000022],
            [69.50876405200006, 24.903085623000038],
            [69.485153000000082, 24.882239000000027],
            [69.47647500000005, 24.886288000000036],
            [69.47647500000005, 24.902532000000065],
            [69.476004894000084, 24.909112526000058],
            [69.475364300000081, 24.918079532000036],
            [69.474799634000078, 24.925983690000066],
            [69.472935611000082, 24.952076179000073],
            [69.472576000000061, 24.957110000000057],
            [69.470227183000077, 24.965526519000036],
            [69.470151528000031, 24.965797614000053],
            [69.465023090000045, 24.984174352000025],
            [69.46482577900008, 24.984881377000079],
            [69.464779000000078, 24.98504900000006],
            [69.457231558000046, 24.990080757000044],
            [69.445287000000064, 24.99804400000005],
            [69.438983658000041, 25.007382417000031],
            [69.427744000000075, 25.024034000000029],
            [69.426582374000077, 25.025195626000027],
            [69.423154637000039, 25.028623363000065],
            [69.410851000000036, 25.040927000000067],
            [69.409488918000079, 25.043140449000077],
            [69.400455000000079, 25.057821000000047],
            [69.399556498000038, 25.069496694000065],
            [69.399155000000064, 25.074714000000029],
            [69.399295965000078, 25.075221436000049],
            [69.405653000000029, 25.098105000000032],
            [69.405836864000037, 25.103067340000052],
            [69.406032478000043, 25.108346828000037],
            [69.406303000000037, 25.115648000000078],
            [69.406092079000075, 25.115999513000077],
            [69.393220000000042, 25.137451000000056],
            [69.39265800000004, 25.138389000000075],
            [69.39239800200005, 25.13911900100004],
            [69.383495541000059, 25.164114718000064],
            [69.375765000000058, 25.185820000000035],
            [69.373469646000046, 25.195136069000057],
            [69.370458925000037, 25.207355573000029],
            [69.369523189000063, 25.211153410000065],
            [69.36725961500008, 25.220340498000041],
            [69.365827305000039, 25.226153762000024],
            [69.364719000000036, 25.230652000000077],
            [69.364635165000038, 25.23203526900005],
            [69.363547883000081, 25.249975216000053],
            [69.363521757000058, 25.250406295000062],
            [69.362120000000061, 25.273535000000038],
            [69.363203216000045, 25.286173996000059],
            [69.364007422000043, 25.295557510000037],
            [69.364069000000029, 25.296276000000034],
            [69.357317906000048, 25.302545403000067],
            [69.35497300000003, 25.304723000000024],
            [69.352800996000042, 25.306393746000026],
            [69.340270555000075, 25.316032394000047],
            [69.338969687000031, 25.317033047000052],
            [69.332193640000071, 25.322245308000049],
            [69.329633000000058, 25.324215000000038],
            [69.328188989000068, 25.326247299000045],
            [69.31961915100004, 25.338308480000023],
            [69.312090000000069, 25.348905000000059],
            [69.304943000000037, 25.367748000000063],
            [69.304216766000081, 25.368128388000059],
            [69.291760513000042, 25.374652744000059],
            [69.291760421000049, 25.374652792000063],
            [69.29129800000004, 25.374895000000038],
            [69.269857000000059, 25.369697000000031],
            [69.270098119000068, 25.370093139000062],
            [69.271435392000058, 25.372290171000031],
            [69.278953000000058, 25.384641000000045],
            [69.273755000000051, 25.400235000000066],
            [69.266953249000039, 25.400034867000045],
            [69.251664000000062, 25.399585000000059],
            [69.25130576600003, 25.406031005000045],
            [69.251014000000055, 25.411281000000031],
            [69.249596000000054, 25.411791000000051],
            [69.249596074000067, 25.411791048000055],
            [69.241462675000037, 25.414718833000052],
            [69.234770000000083, 25.417128000000048],
            [69.217638431000069, 25.430072477000067],
            [69.213327838000055, 25.433329527000069],
            [69.205480356000066, 25.439258293000023],
            [69.190497696000079, 25.450387946000035],
            [69.188330453000049, 25.451997851000044],
            [69.188043482000069, 25.452211024000064],
            [69.182795166000062, 25.456109659000049],
            [69.182795171000066, 25.456109902000037],
            [69.182791000000066, 25.456113000000073],
            [69.183129820000033, 25.471692482000037],
            [69.183129833000066, 25.471693072000051],
            [69.18312983900006, 25.471693328000072],
            [69.183149969000056, 25.472618949000037],
            [69.183441000000073, 25.486001000000044],
            [69.180835172000059, 25.499888807000048],
            [69.179537510000046, 25.506804724000062],
            [69.17514471800007, 25.518885103000059],
            [69.175144705000037, 25.518885139000076],
            [69.171745000000044, 25.528234000000054],
            [69.171744965000073, 25.528234824000037],
            [69.171744625000031, 25.52823575900004],
            [69.171732470000052, 25.528528558000062],
            [69.170919939000044, 25.547630233000064],
            [69.170840677000058, 25.549493581000036],
            [69.170476903000065, 25.558045506000042],
            [69.170446000000084, 25.558772000000033],
            [69.170476903000065, 25.558773718000054],
            [69.170476939000082, 25.558773720000033],
            [69.172191000000055, 25.558869000000072],
            [69.181270260000076, 25.559373605000076],
            [69.182141000000058, 25.55942200000004],
            [69.207394205000071, 25.558805837000023],
            [69.208781000000045, 25.558772000000033],
            [69.218555332000051, 25.556692498000075],
            [69.238221739000039, 25.552508444000068],
            [69.23931900000008, 25.552275000000066],
            [69.254764694000073, 25.550838179000039],
            [69.260426623000058, 25.550311482000041],
            [69.267258000000083, 25.549676000000034],
            [69.295287538000082, 25.549115185000062],
            [69.29974500000003, 25.549026000000026],
            [69.300665507000076, 25.549584892000041],
            [69.314918313000078, 25.558238577000054],
            [69.317938000000083, 25.560072000000048],
            [69.335481000000073, 25.570468000000062],
            [69.347565313000075, 25.565633956000056],
            [69.356707834000076, 25.561976706000053],
            [69.358222000000069, 25.561371000000065],
            [69.380963000000065, 25.557473000000073],
            [69.381011568000076, 25.557511343000044],
            [69.386822272000074, 25.562098716000037],
            [69.389412483000058, 25.564143608000052],
            [69.393308000000047, 25.567219000000023],
            [69.403704000000062, 25.560072000000048],
            [69.407896655000059, 25.557155322000028],
            [69.418648000000076, 25.549676000000034],
            [69.429586627000049, 25.547488125000029],
            [69.435623607000082, 25.546280646000071],
            [69.44788600000004, 25.543828000000076],
            [69.449976831000072, 25.545420985000078],
            [69.457141147000073, 25.550879411000039],
            [69.461531000000036, 25.554224000000033],
            [69.479723000000035, 25.558772000000033],
            [69.491802930000063, 25.556356609000034],
            [69.492156494000028, 25.556285914000057],
            [69.492171821000056, 25.55628284900007],
            [69.495966665000083, 25.555523974000039],
            [69.495966825000039, 25.555523942000036],
            [69.495966853000084, 25.555523974000039],
            [69.495966896000084, 25.555524021000053],
            [69.501813930000083, 25.562019865000025],
            [69.501814030000048, 25.562019922000047],
            [69.501815000000079, 25.562021000000072],
            [69.507937838000032, 25.565519764000044],
            [69.520008000000075, 25.57241700000003],
            [69.532876810000062, 25.571246617000043],
            [69.534302000000082, 25.571117000000072],
            [69.541942088000042, 25.569480018000036],
            [69.541942124000059, 25.569480010000063],
            [69.559335973000032, 25.565753167000025],
            [69.561591000000078, 25.565270000000055],
            [69.562727622000068, 25.558058447000064],
            [69.562729599000079, 25.558058094000046],
            [69.563540000000046, 25.552925000000073],
            [69.563587755000071, 25.55228850900005],
            [69.565490000000068, 25.526935000000037],
            [69.566407821000041, 25.525787614000023],
            [69.575885000000028, 25.513940000000048],
            [69.575885000000028, 25.502245000000073],
            [69.576117663000048, 25.502211762000059],
            [69.593877133000035, 25.499674695000067],
            [69.594078000000081, 25.499646000000041],
            [69.607738878000077, 25.496003006000024],
            [69.613570000000038, 25.494448000000034],
            [69.62916400000006, 25.492498000000069],
            [69.631763000000035, 25.508092000000033],
            [69.636311000000035, 25.51783800000004],
            [69.636311951000039, 25.517837894000024],
            [69.648007000000064, 25.516539000000023],
            [69.664671480000038, 25.513597765000043],
            [69.669463688000064, 25.512751954000066],
            [69.670098000000053, 25.512640000000033],
            [69.672047000000077, 25.521087000000023],
            [69.686342000000081, 25.532783000000052],
            [69.705493966000063, 25.54051101400006],
            [69.723377000000028, 25.547727000000066],
            [69.725185735000082, 25.549348555000051],
            [69.742220000000032, 25.564620000000048],
            [69.752615000000048, 25.582813000000044],
            [69.772996515000045, 25.607652726000026],
            [69.773407000000077, 25.608153000000073],
            [69.790950000000066, 25.653635000000065],
            [69.820838000000037, 25.671178000000054],
            [69.824399815000049, 25.673248745000024],
            [69.848777000000041, 25.687421000000029],
            [69.868270000000052, 25.693269000000043],
            [69.890838730000041, 25.718032321000067],
            [69.915051000000062, 25.744599000000051],
            [69.930645000000084, 25.760193000000072],
            [69.94429000000008, 25.75759400000004],
            [69.974178000000052, 25.727056000000061],
            [69.974711476000039, 25.727014946000054],
            [69.991071000000034, 25.725756000000047],
            [70.013162000000079, 25.736802000000068],
            [70.02957284200005, 25.740780339000025],
            [70.03122527000005, 25.741180923000059],
            [70.034604000000058, 25.742000000000075],
            [70.046299000000033, 25.753045000000043],
            [70.089832000000058, 25.769289000000072],
            [70.112573000000054, 25.76279100000005],
            [70.133365000000083, 25.770588000000032],
            [70.171050000000037, 25.770588000000032],
            [70.19444100000004, 25.782934000000068],
            [70.222326641000052, 25.794536869000069],
            [70.222752807000063, 25.794140614000071],
            [70.223103000000037, 25.793815000000052],
            [70.244267000000036, 25.766455000000065],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "148",
      properties: { name: "Karachi Central", count: 1079 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.091535463000071, 25.006673369000055],
            [67.091591616000073, 25.006272031000037],
            [67.091619276000074, 25.006182809000052],
            [67.091626716000064, 25.006158810000045],
            [67.091730706000078, 25.005823382000074],
            [67.091812325000035, 25.005585161000056],
            [67.091888423000057, 25.005363053000053],
            [67.091903401000081, 25.005288504000077],
            [67.091909821000058, 25.005256551000059],
            [67.091904512000042, 25.00513846900003],
            [67.091858871000056, 25.00503169600006],
            [67.09182099700007, 25.004943092000076],
            [67.091683404000037, 25.004600375000052],
            [67.091658535000079, 25.004513778000046],
            [67.091567668000039, 25.00419736300006],
            [67.091557822000084, 25.004163078000033],
            [67.091555070000084, 25.004150911000067],
            [67.091480584000067, 25.003821639000023],
            [67.091453204000061, 25.003700602000038],
            [67.091357434000031, 25.003329997000037],
            [67.091349839000031, 25.00330060400006],
            [67.091274278000071, 25.003023422000069],
            [67.091272289000074, 25.003016124000055],
            [67.091195001000074, 25.002662010000051],
            [67.091190755000071, 25.002642553000044],
            [67.09118053800006, 25.002592995000043],
            [67.091115047000073, 25.002275316000066],
            [67.091097626000078, 25.002004936000048],
            [67.09111297100003, 25.001824987000077],
            [67.091135652000048, 25.001558999000054],
            [67.091196989000082, 25.001160404000075],
            [67.091261240000051, 25.000769364000064],
            [67.091320101000065, 25.000160656000048],
            [67.091331886000034, 25.000086431000057],
            [67.09137267300008, 24.999829557000055],
            [67.091445647000057, 24.999525692000077],
            [67.091464860000031, 24.999462396000069],
            [67.091469669000048, 24.999446555000077],
            [67.091484427000069, 24.999397937000026],
            [67.091562744000043, 24.999139933000038],
            [67.091629784000077, 24.998865446000025],
            [67.091714520000039, 24.998518506000039],
            [67.091790401000083, 24.998226182000053],
            [67.092133561000082, 24.997448140000074],
            [67.092137962000038, 24.997438163000027],
            [67.092313928000067, 24.997024112000076],
            [67.092475396000054, 24.996644175000029],
            [67.092575069000077, 24.996376022000049],
            [67.092756782000038, 24.995887154000059],
            [67.092982733000042, 24.995356078000043],
            [67.093013442000029, 24.995283900000061],
            [67.093182226000067, 24.994763937000073],
            [67.093182682000077, 24.994762534000074],
            [67.093206179000049, 24.994653722000066],
            [67.093244194000079, 24.994477676000031],
            [67.093261379000069, 24.994398095000065],
            [67.093302337000068, 24.994208422000042],
            [67.093340348000083, 24.993797770000072],
            [67.093333929000039, 24.993553663000057],
            [67.093329229000062, 24.993374927000048],
            [67.093328801000041, 24.99335865200004],
            [67.093293909000067, 24.992984665000051],
            [67.093292770000062, 24.992979852000076],
            [67.093246789000034, 24.992785540000057],
            [67.093166843000063, 24.99260163100007],
            [67.093147244000079, 24.992556544000024],
            [67.09309101100007, 24.992467757000043],
            [67.092945022000038, 24.992237254000031],
            [67.092921035000074, 24.992199381000034],
            [67.092792805000045, 24.991990595000061],
            [67.092650500000047, 24.991829902000063],
            [67.092558796000048, 24.991756893000058],
            [67.09241260400006, 24.991640505000078],
            [67.092408343000045, 24.991637034000064],
            [67.091897283000037, 24.991220629000054],
            [67.091886266000074, 24.991212591000078],
            [67.091863898000042, 24.99119627400006],
            [67.091502018000028, 24.990932274000045],
            [67.091447251000034, 24.990881295000065],
            [67.091288549000069, 24.990733566000074],
            [67.091173513000058, 24.990629659000035],
            [67.091088988000081, 24.990529220000042],
            [67.090985174000082, 24.99038874200005],
            [67.090952180000045, 24.990296499000067],
            [67.09094669600006, 24.990281166000045],
            [67.090932735000081, 24.990242136000063],
            [67.090906535000045, 24.990094143000078],
            [67.090856057000053, 24.989475585000037],
            [67.090832264000028, 24.989184038000076],
            [67.090815817000077, 24.988989390000029],
            [67.090815000000077, 24.988979717000063],
            [67.090810154000053, 24.988922360000061],
            [67.090791345000071, 24.988699767000071],
            [67.090756708000072, 24.988289836000035],
            [67.090750096000079, 24.988109774000066],
            [67.090744766000057, 24.987964627000053],
            [67.090743047000046, 24.98791781500006],
            [67.090727771000047, 24.987501784000074],
            [67.090734329000043, 24.987241471000061],
            [67.090740942000082, 24.986978986000054],
            [67.090743570000029, 24.986874679000039],
            [67.09075135300003, 24.986565721000034],
            [67.090757132000078, 24.986310902000071],
            [67.090762472000051, 24.986075403000029],
            [67.090772016000074, 24.985654550000049],
            [67.090792646000068, 24.984953569000027],
            [67.090851364000059, 24.98456956900003],
            [67.090853026000048, 24.984558701000026],
            [67.090888322000069, 24.984327880000023],
            [67.090919740000061, 24.984208910000063],
            [67.090962562000072, 24.984123569000076],
            [67.091011090000052, 24.984035646000052],
            [67.09107103000008, 24.983950314000026],
            [67.091164525000067, 24.983847895000054],
            [67.091600809000056, 24.983454290000054],
            [67.091983741000035, 24.983108817000073],
            [67.092178323000041, 24.982913153000027],
            [67.092182728000068, 24.982908723000037],
            [67.09239614300003, 24.982694121000065],
            [67.092545389000065, 24.982544045000054],
            [67.092744308000078, 24.98237871300006],
            [67.092795692000038, 24.98229854300007],
            [67.092818537000028, 24.982231300000024],
            [67.092818564000083, 24.982122669000034],
            [67.092818212000054, 24.982114910000064],
            [67.092810051000072, 24.981935099000054],
            [67.092793276000066, 24.981818479000026],
            [67.092770174000066, 24.981657876000043],
            [67.092698922000068, 24.981347368000058],
            [67.09260069000004, 24.981047802000035],
            [67.092564267000057, 24.980958347000069],
            [67.092536264000046, 24.98088957300007],
            [67.092511603000048, 24.980829006000079],
            [67.09245171300006, 24.98072294900004],
            [67.092419710000058, 24.980688648000068],
            [67.092388957000082, 24.980655686000034],
            [67.092208826000046, 24.980556658000069],
            [67.091892620000067, 24.980381039000065],
            [67.091648777000046, 24.980214587000034],
            [67.091627288000041, 24.980199918000039],
            [67.091426308000052, 24.980052684000043],
            [67.09127720400005, 24.979914911000037],
            [67.091275109000037, 24.979912975000047],
            [67.091175422000049, 24.979800580000074],
            [67.091084151000075, 24.979655715000035],
            [67.091002911000032, 24.979480730000034],
            [67.090940882000041, 24.979269106000061],
            [67.090932000000066, 24.979234000000076],
            [67.090927222000062, 24.979219422000028],
            [67.090890547000072, 24.979068741000049],
            [67.090879175000055, 24.978923895000037],
            [67.090876406000064, 24.978611683000054],
            [67.090875487000062, 24.978533629000026],
            [67.09087007200003, 24.978073922000078],
            [67.090865257000075, 24.977665196000032],
            [67.090860532000079, 24.977527292000048],
            [67.090850548000049, 24.977235956000072],
            [67.090845021000064, 24.97707465700006],
            [67.090842616000032, 24.977004501000067],
            [67.090839654000035, 24.976981164000051],
            [67.090813462000028, 24.976774817000035],
            [67.090802756000073, 24.976690473000076],
            [67.090791490000072, 24.976658346000079],
            [67.090706806000071, 24.976416845000074],
            [67.090694177000046, 24.976383633000069],
            [67.090645441000049, 24.976255466000055],
            [67.090486978000058, 24.975838737000061],
            [67.09047240600006, 24.975800415000037],
            [67.090390211000056, 24.97554608400003],
            [67.090352660000065, 24.975429896000037],
            [67.090319657000066, 24.975281067000026],
            [67.090281415000049, 24.975108609000074],
            [67.090272666000033, 24.974971304000064],
            [67.090266358000065, 24.974872319000042],
            [67.090257674000043, 24.974736032000067],
            [67.090255176000028, 24.974696831000074],
            [67.090254593000054, 24.974651684000037],
            [67.090254515000083, 24.974645646000056],
            [67.090274152000063, 24.974423076000051],
            [67.090293043000031, 24.974208968000028],
            [67.090293970000062, 24.974198455000078],
            [67.090301667000062, 24.97411121500005],
            [67.090336247000039, 24.973915823000027],
            [67.090416183000059, 24.973464153000066],
            [67.090476575000082, 24.973198941000078],
            [67.09051617800003, 24.973025026000073],
            [67.090617823000059, 24.972724136000068],
            [67.090931867000052, 24.972067938000066],
            [67.091118496000036, 24.971661278000056],
            [67.091312728000048, 24.971238051000057],
            [67.09140323400004, 24.971024890000024],
            [67.09142694500008, 24.970969045000061],
            [67.091461237000033, 24.970790559000022],
            [67.091471594000041, 24.970611118000079],
            [67.091473203000078, 24.970583243000078],
            [67.091473269000062, 24.970340079000039],
            [67.091468840000061, 24.970211992000031],
            [67.091468212000052, 24.970193822000056],
            [67.091459112000052, 24.969930605000059],
            [67.091422114000068, 24.969565770000031],
            [67.091390144000059, 24.969334809000031],
            [67.091379819000053, 24.969260215000077],
            [67.091331344000082, 24.96912827400007],
            [67.09132532600006, 24.969118306000041],
            [67.091288088000056, 24.969056628000033],
            [67.09126573900005, 24.969019609000043],
            [67.091190496000081, 24.968905881000069],
            [67.091113802000052, 24.968777768000052],
            [67.09102537900003, 24.968627707000053],
            [67.090962637000075, 24.968480235000072],
            [67.09087984100006, 24.968158288000041],
            [67.090844006000054, 24.968042790000027],
            [67.090705099000047, 24.967595088000053],
            [67.090551147000042, 24.967056897000077],
            [67.090471939000054, 24.966744658000039],
            [67.090453663000062, 24.966556657000069],
            [67.090426407000052, 24.966276276000031],
            [67.090392272000031, 24.965877834000025],
            [67.090365000000077, 24.965620217000037],
            [67.090356495000037, 24.965539877000026],
            [67.09027958300004, 24.965206472000034],
            [67.090263874000073, 24.965138373000059],
            [67.09016121600007, 24.964864115000069],
            [67.090032228000041, 24.964668433000043],
            [67.08971508500008, 24.964269245000025],
            [67.089563896000072, 24.964070005000053],
            [67.089373833000082, 24.963884146000055],
            [67.089136293000081, 24.963663441000051],
            [67.089010418000044, 24.963551076000044],
            [67.088925313000061, 24.963475106000033],
            [67.088752055000043, 24.963294173000065],
            [67.088657918000081, 24.96316220500006],
            [67.088556653000069, 24.962962079000079],
            [67.088459680000028, 24.962768014000062],
            [67.088424915000076, 24.962720469000033],
            [67.088342139000076, 24.962607262000063],
            [67.088298661000067, 24.962547799000049],
            [67.088056168000037, 24.962276082000074],
            [67.087619202000042, 24.961868261000063],
            [67.087281217000054, 24.961543238000047],
            [67.087079550000055, 24.961349305000056],
            [67.087073786000076, 24.961343761000023],
            [67.086704169000029, 24.960985906000076],
            [67.086635257000069, 24.960918497000023],
            [67.086434365000059, 24.960721986000067],
            [67.086320243000046, 24.960615876000077],
            [67.086189045000083, 24.960485073000029],
            [67.086185605000082, 24.960481644000026],
            [67.086082904000079, 24.96036001300007],
            [67.086020142000052, 24.960284964000039],
            [67.08594596100005, 24.960220262000064],
            [67.085817871000074, 24.960112967000043],
            [67.085683780000068, 24.959983565000073],
            [67.085663849000071, 24.95996145600003],
            [67.085578258000055, 24.95986651100003],
            [67.085500941000078, 24.959761466000032],
            [67.085491841000078, 24.959749102000046],
            [67.085411237000073, 24.959609756000077],
            [67.08534849800003, 24.959459672000037],
            [67.085291484000038, 24.959250079000071],
            [67.085267789000056, 24.959117541000069],
            [67.085251597000081, 24.959026969000035],
            [67.085188906000042, 24.958712726000044],
            [67.085169955000083, 24.958592602000067],
            [67.085160474000077, 24.958405689000074],
            [67.085170608000055, 24.958240235000062],
            [67.085189168000056, 24.957937230000027],
            [67.085266546000071, 24.95693422100004],
            [67.085312412000064, 24.956287271000065],
            [67.085335382000039, 24.955847367000047],
            [67.085346908000076, 24.95549920600007],
            [67.085372681000081, 24.955207633000043],
            [67.085426984000037, 24.954942317000075],
            [67.08550126800003, 24.954673902000025],
            [67.08554698100005, 24.954513488000032],
            [67.085576321000076, 24.954334086000074],
            [67.085570792000055, 24.953781025000069],
            [67.085545227000068, 24.953413113000067],
            [67.085511046000079, 24.953224225000042],
            [67.085462607000068, 24.953008647000047],
            [67.085351426000045, 24.952698067000028],
            [67.085204677000036, 24.95241902600003],
            [67.085141414000077, 24.952306051000051],
            [67.085078459000044, 24.952184633000059],
            [67.08502586700007, 24.952049407000061],
            [67.084951341000078, 24.951918152000076],
            [67.084889967000038, 24.951806785000031],
            [67.084797887000036, 24.951695408000035],
            [67.084700749000035, 24.951541865000024],
            [67.08463940200005, 24.951343001000055],
            [67.084473935000062, 24.950842937000061],
            [67.084325016000037, 24.950190983000027],
            [67.084261541000046, 24.949808914000073],
            [67.08423091700007, 24.94956488400004],
            [67.084253001000036, 24.949111467000023],
            [67.084267534000048, 24.949025057000028],
            [67.084283767000045, 24.948928538000075],
            [67.084318898000049, 24.948797313000057],
            [67.084446166000077, 24.948583485000029],
            [67.084627734000037, 24.94827758100007],
            [67.08494509500008, 24.947808995000059],
            [67.085492956000053, 24.947088597000061],
            [67.08553618600007, 24.947043151000059],
            [67.085672257000056, 24.946900107000033],
            [67.085835933000055, 24.946728043000064],
            [67.085844157000054, 24.946733796000046],
            [67.085852899000031, 24.946738874000062],
            [67.085862094000049, 24.946743237000078],
            [67.085871670000074, 24.946746854000025],
            [67.085881556000061, 24.94674969600004],
            [67.085891676000074, 24.946751741000071],
            [67.085901952000029, 24.946752975000038],
            [67.085912308000047, 24.946753387000058],
            [67.085922665000055, 24.946752975000038],
            [67.085932941000067, 24.946751741000071],
            [67.08594306100008, 24.94674969600004],
            [67.085952948000056, 24.946746854000025],
            [67.085962524000081, 24.946743237000078],
            [67.085971718000053, 24.946738874000062],
            [67.085980461000077, 24.946733796000046],
            [67.085988684000029, 24.946728043000064],
            [67.085995766000053, 24.94672216500004],
            [67.086002306000069, 24.946715787000073],
            [67.086271614000054, 24.946430946000078],
            [67.086663509000061, 24.946016447000034],
            [67.086844887000041, 24.945836499000052],
            [67.086983780000082, 24.945698700000037],
            [67.087271866000037, 24.945412884000064],
            [67.087275400000067, 24.94540921600003],
            [67.087280188000079, 24.945403655000064],
            [67.087751754000067, 24.944817492000027],
            [67.087757287000045, 24.944809974000066],
            [67.087766501000033, 24.944796248000046],
            [67.088344238000047, 24.943935604000046],
            [67.088349409000045, 24.943926790000035],
            [67.088740551000058, 24.94317727300006],
            [67.088744616000042, 24.943168511000067],
            [67.088914576000036, 24.942752087000031],
            [67.089079171000037, 24.942348811000045],
            [67.089081364000037, 24.942342852000024],
            [67.089423596000074, 24.941314589000058],
            [67.089647175000039, 24.940613958000029],
            [67.089648115000045, 24.940610841000023],
            [67.089764317000061, 24.940201523000042],
            [67.089860003000069, 24.939864471000078],
            [67.089908566000076, 24.939693409000029],
            [67.090166169000042, 24.938849492000031],
            [67.090253646000065, 24.938527685000054],
            [67.090333064000049, 24.938235525000039],
            [67.090334806000044, 24.938227969000025],
            [67.090418807000049, 24.937778348000052],
            [67.090436515000079, 24.937683566000032],
            [67.090541787000063, 24.937088938000045],
            [67.090542973000083, 24.93707977400004],
            [67.090586884000061, 24.936545691000049],
            [67.090587142000061, 24.936541441000031],
            [67.090604776000077, 24.936089091000042],
            [67.090604838000047, 24.936084072000028],
            [67.09058751300006, 24.935353057000043],
            [67.090587312000082, 24.935348679000072],
            [67.090539552000052, 24.93464797200005],
            [67.09053530500006, 24.934317971000041],
            [67.090533966000066, 24.934302980000041],
            [67.09043347800008, 24.933699514000068],
            [67.090432797000062, 24.933695882000052],
            [67.090236294000078, 24.932788049000067],
            [67.090127247000055, 24.932159528000057],
            [67.090125855000053, 24.932153109000069],
            [67.090011731000061, 24.931700773000046],
            [67.090007287000049, 24.931687265000051],
            [67.089803070000073, 24.931198077000033],
            [67.08980222100007, 24.931196148000026],
            [67.089801460000047, 24.931194422000033],
            [67.089626705000057, 24.930817617000059],
            [67.089622168000062, 24.930808931000058],
            [67.089425340000048, 24.930471570000066],
            [67.089423363000037, 24.930468318000067],
            [67.089218997000046, 24.930145278000055],
            [67.089214492000053, 24.930138714000066],
            [67.089209498000059, 24.93013247600004],
            [67.08901964000006, 24.929912256000023],
            [67.088786347000052, 24.929641654000022],
            [67.088783675000059, 24.929638667000063],
            [67.088564662000067, 24.929404182000042],
            [67.088557915000081, 24.929397542000061],
            [67.088550030000079, 24.929391021000072],
            [67.088269442000069, 24.929181538000023],
            [67.088263271000073, 24.92917723000005],
            [67.088166452000053, 24.929114119000076],
            [67.087963066000043, 24.928981544000067],
            [67.087703987000054, 24.928817191000064],
            [67.087471258000051, 24.928669554000066],
            [67.087468175000083, 24.928667663000056],
            [67.08699819800006, 24.928389000000038],
            [67.08649999000005, 24.92809630000005],
            [67.086081376000038, 24.927835225000024],
            [67.085828216000039, 24.927672835000067],
            [67.085818189000065, 24.92766707100003],
            [67.085650860000044, 24.927583140000024],
            [67.085640913000077, 24.927578690000075],
            [67.085385173000077, 24.927477469000053],
            [67.08498191700005, 24.92732299000005],
            [67.084975108000037, 24.927320612000074],
            [67.084590950000063, 24.927203117000033],
            [67.084214430000031, 24.927059169000074],
            [67.083672802000081, 24.926812916000074],
            [67.083197116000065, 24.926583214000061],
            [67.082591464000075, 24.926291358000071],
            [67.082318908000047, 24.926152083000034],
            [67.082306343000027, 24.926146521000078],
            [67.082156805000068, 24.926089969000031],
            [67.082065812000053, 24.926040423000074],
            [67.082000767000068, 24.925984873000061],
            [67.081998269000053, 24.925982799000053],
            [67.08192555800008, 24.925924149000025],
            [67.081825830000071, 24.925818573000072],
            [67.081819618000054, 24.925812486000041],
            [67.081562283000039, 24.925578968000025],
            [67.081417586000043, 24.925442874000055],
            [67.081259130000035, 24.925289038000074],
            [67.081168226000045, 24.925185425000052],
            [67.08116431500008, 24.925181277000036],
            [67.081063103000076, 24.925079563000054],
            [67.080943263000052, 24.924941593000028],
            [67.08083877100006, 24.924824009000076],
            [67.080682999000032, 24.924587125000073],
            [67.08059109800007, 24.924427295000044],
            [67.080468875000065, 24.924197537000055],
            [67.08040569800005, 24.924085109000032],
            [67.080303994000076, 24.923890093000068],
            [67.080243585000062, 24.92376868100007],
            [67.080155325000078, 24.923583958000052],
            [67.080100392000077, 24.923407480000037],
            [67.080031481000049, 24.923200178000059],
            [67.079959563000045, 24.922903904000066],
            [67.07989492300004, 24.922622062000073],
            [67.079791224000076, 24.921967576000043],
            [67.079478287000029, 24.919753547000028],
            [67.079340226000056, 24.918945829000052],
            [67.079248047000078, 24.918406545000039],
            [67.079080863000058, 24.917428450000045],
            [67.078963116000068, 24.916631529000028],
            [67.078906437000057, 24.916247922000025],
            [67.078905685000052, 24.91624351400003],
            [67.078756981000083, 24.915479657000049],
            [67.078755458000046, 24.915473042000031],
            [67.078667461000066, 24.915142409000055],
            [67.078663649000077, 24.915130909000027],
            [67.078661724000028, 24.915126401000066],
            [67.078561152000077, 24.914905617000045],
            [67.078558825000073, 24.914900838000051],
            [67.07843278100006, 24.914657937000072],
            [67.078427927000064, 24.914649558000065],
            [67.078196748000039, 24.914294146000032],
            [67.078192282000032, 24.914287795000064],
            [67.077968527000053, 24.91399274500003],
            [67.077963226000065, 24.913986283000042],
            [67.077838562000068, 24.913845587000026],
            [67.077836074000061, 24.913842913000053],
            [67.077744219000067, 24.913747639000064],
            [67.077740318000053, 24.913743785000065],
            [67.077622211000062, 24.913632627000027],
            [67.077615803000072, 24.913627030000043],
            [67.07749252800005, 24.91352715000005],
            [67.077365737000036, 24.913427981000041],
            [67.077356100000031, 24.913421264000078],
            [67.077214750000053, 24.913332817000025],
            [67.077209271000072, 24.913329587000078],
            [67.077050598000028, 24.913241592000077],
            [67.07704405700008, 24.913238298000067],
            [67.076747918000081, 24.913100397000051],
            [67.076738614000078, 24.913096516000053],
            [67.076524228000039, 24.913017073000049],
            [67.076520127000038, 24.913015633000043],
            [67.07608532200004, 24.912871413000062],
            [67.076076452000052, 24.912868824000043],
            [67.074702208000076, 24.912521409000078],
            [67.073265202000073, 24.912146568000026],
            [67.073230072000058, 24.912137405000067],
            [67.072119021000049, 24.911815661000048],
            [67.071324831000084, 24.91160469600004],
            [67.07131925200008, 24.911603347000039],
            [67.07074696400008, 24.911478482000064],
            [67.070138167000039, 24.911345651000033],
            [67.069138508000037, 24.911074037000049],
            [67.068384963000028, 24.910806768000043],
            [67.067893692000041, 24.91059705400005],
            [67.067334441000071, 24.910301699000058],
            [67.067079503000059, 24.910143733000041],
            [67.066710594000028, 24.909915148000039],
            [67.066674516000035, 24.909887918000038],
            [67.066463934000069, 24.909728980000068],
            [67.066330286000039, 24.909628108000049],
            [67.065925811000056, 24.909253135000029],
            [67.065615884000067, 24.908930207000026],
            [67.065233891000048, 24.90847655400006],
            [67.064967315000047, 24.90813627700004],
            [67.064815619000058, 24.907942641000034],
            [67.064595422000082, 24.907655044000023],
            [67.064506047000066, 24.907464352000034],
            [67.064439469000035, 24.907150471000023],
            [67.064418215000046, 24.906977372000028],
            [67.064414046000081, 24.906756230000042],
            [67.064413518000038, 24.90674772400007],
            [67.064412259000051, 24.906739281000057],
            [67.064410844000065, 24.906733042000042],
            [67.064371532000052, 24.90658219200003],
            [67.06436728500006, 24.906569447000038],
            [67.064292674000058, 24.906390673000033],
            [67.064288945000044, 24.906382712000038],
            [67.064284531000055, 24.906375048000029],
            [67.064277574000073, 24.906365305000065],
            [67.064106906000063, 24.906152261000045],
            [67.063835773000051, 24.90570920600004],
            [67.06337953600007, 24.904673032000062],
            [67.06310761800006, 24.90405547000006],
            [67.063055672000075, 24.903936617000056],
            [67.062506513000073, 24.902680124000028],
            [67.062117703000069, 24.90180390300003],
            [67.062033749000079, 24.901593745000071],
            [67.061900396000055, 24.901259929000048],
            [67.061868138000079, 24.90116749200007],
            [67.061786433000066, 24.900933357000042],
            [67.061715724000067, 24.900748649000036],
            [67.061711729000081, 24.90073970800006],
            [67.061707260000048, 24.900731743000051],
            [67.061628282000072, 24.900604263000048],
            [67.061621442000046, 24.900594574000024],
            [67.061520856000072, 24.900467520000063],
            [67.061515547000056, 24.900461303000043],
            [67.061511279000058, 24.900456911000049],
            [67.061374529000034, 24.90032364700005],
            [67.061363499000038, 24.900314190000074],
            [67.061215096000069, 24.900203885000053],
            [67.061212167000065, 24.900201778000053],
            [67.061025157000074, 24.900071479000076],
            [67.060798376000037, 24.899905839000041],
            [67.06065327500005, 24.899788916000034],
            [67.060148304000052, 24.899382007000042],
            [67.059833751000042, 24.899131605000036],
            [67.059747113000071, 24.899045306000062],
            [67.059688554000047, 24.89898697600006],
            [67.059638653000036, 24.898937271000023],
            [67.059633203000033, 24.89893218900005],
            [67.059627401000057, 24.898927443000048],
            [67.059620247000055, 24.898922379000055],
            [67.05961269200003, 24.898917827000048],
            [67.059432220000076, 24.898817914000063],
            [67.059419992000073, 24.898812147000058],
            [67.059214386000065, 24.898728708000078],
            [67.05920672600007, 24.898725886000022],
            [67.059203038000078, 24.898724724000033],
            [67.058772625000074, 24.898596276000035],
            [67.058050153000067, 24.898355304000063],
            [67.058040708000078, 24.898352562000071],
            [67.057162961000074, 24.898134129000027],
            [67.05696570300006, 24.898086319000072],
            [67.056955824000056, 24.898084331000064],
            [67.056945580000047, 24.898083099000075],
            [67.056935259000056, 24.898082686000066],
            [67.056788187000052, 24.898082618000046],
            [67.056779491000043, 24.89808290700006],
            [67.05667277200007, 24.898090029000059],
            [67.056580457000052, 24.89807993200003],
            [67.056398459000036, 24.898043587000075],
            [67.056226700000082, 24.897997901000053],
            [67.055957503000059, 24.897908490000077],
            [67.055653062000033, 24.897793922000062],
            [67.055381379000039, 24.897694383000044],
            [67.055377730000032, 24.897693109000045],
            [67.055371180000066, 24.897691130000055],
            [67.055026870000063, 24.897597087000065],
            [67.055020690000049, 24.897595562000049],
            [67.054326632000084, 24.897442618000071],
            [67.053761504000079, 24.897294543000044],
            [67.053749334000031, 24.897291977000066],
            [67.053739003000032, 24.897290740000074],
            [67.053539947000047, 24.897274947000028],
            [67.053348783000047, 24.897253182000043],
            [67.053135963000045, 24.897169896000037],
            [67.052914713000064, 24.897060961000022],
            [67.052908967000064, 24.897058314000049],
            [67.05274273200007, 24.896986789000039],
            [67.052731211000037, 24.896982500000036],
            [67.052503429000069, 24.896912975000077],
            [67.052498950000029, 24.896911697000064],
            [67.052381394000065, 24.89688047900006],
            [67.052035860000046, 24.896788717000049],
            [67.052029367000046, 24.896787173000064],
            [67.051689164000038, 24.896715556000061],
            [67.051683079000043, 24.896714427000063],
            [67.051677400000074, 24.896713642000066],
            [67.051543081000034, 24.896698103000062],
            [67.05141786300004, 24.896676746000026],
            [67.051307209000072, 24.896636539000042],
            [67.051070744000072, 24.896502227000042],
            [67.05031856100004, 24.896087783000041],
            [67.049115734000054, 24.895471790000045],
            [67.048101726000084, 24.894990235000023],
            [67.047733351000034, 24.894802688000027],
            [67.047387325000045, 24.894582859000025],
            [67.047380882000084, 24.894579042000032],
            [67.046881793000068, 24.894303887000035],
            [67.045959507000077, 24.893886261000034],
            [67.045242363000057, 24.893536867000023],
            [67.045234348000065, 24.893533308000031],
            [67.044653870000047, 24.89329970600005],
            [67.044428133000054, 24.893182006000075],
            [67.044141307000075, 24.892995839000037],
            [67.044006575000083, 24.892905057000064],
            [67.043995390000077, 24.892898374000026],
            [67.043986199000074, 24.892894009000031],
            [67.043976627000063, 24.892890393000073],
            [67.043971656000053, 24.892888855000024],
            [67.043888527000036, 24.892864995000025],
            [67.043881074000069, 24.892863097000031],
            [67.043873501000064, 24.892861645000039],
            [67.043865044000029, 24.892860570000039],
            [67.043860122000069, 24.892860270000028],
            [67.043856523000045, 24.892860051000071],
            [67.043718570000067, 24.892856185000028],
            [67.043712115000062, 24.892856004000066],
            [67.043708468000034, 24.892855953000037],
            [67.043699704000062, 24.892856249000033],
            [67.043579356000066, 24.892864370000041],
            [67.043524649000062, 24.892868062000048],
            [67.043515687000081, 24.89286898000006],
            [67.043368805000057, 24.892889202000049],
            [67.043171056000062, 24.892916428000035],
            [67.043167087000029, 24.892917042000079],
            [67.042754819000038, 24.892990481000027],
            [67.042630474000077, 24.893014137000023],
            [67.042416318000051, 24.893054878000044],
            [67.042239143000074, 24.893088584000054],
            [67.041622379000046, 24.893154745000061],
            [67.040525407000075, 24.893207713000038],
            [67.040521393000063, 24.893207969000059],
            [67.039953909000076, 24.893252993000033],
            [67.039402081000048, 24.893282085000067],
            [67.038943283000037, 24.893247062000057],
            [67.038486697000053, 24.893180935000032],
            [67.03812249300006, 24.893087553000044],
            [67.037827977000063, 24.892984320000039],
            [67.037493053000048, 24.892823079000038],
            [67.03748775300005, 24.892820680000057],
            [67.037396203000071, 24.89278179400003],
            [67.037178822000044, 24.892689462000078],
            [67.037150001000043, 24.892677220000053],
            [67.036802680000051, 24.892535762000023],
            [67.03679487900007, 24.892532882000069],
            [67.036789571000043, 24.892531248000068],
            [67.036496607000061, 24.892448033000051],
            [67.036326904000077, 24.892368825000062],
            [67.036119932000076, 24.892272223000077],
            [67.035737641000082, 24.89206187700006],
            [67.035366147000047, 24.891822027000046],
            [67.034993714000052, 24.891581571000074],
            [67.034990463000042, 24.89157954500007],
            [67.034052685000063, 24.891015677000041],
            [67.034046099000079, 24.891011994000053],
            [67.033660895000082, 24.890814566000074],
            [67.033645486000069, 24.890807947000042],
            [67.033245279000027, 24.890667044000054],
            [67.033235300000058, 24.890664077000054],
            [67.03272607100007, 24.890535360000058],
            [67.032517703000053, 24.890482691000045],
            [67.031969402000072, 24.890319217000069],
            [67.031874962000074, 24.890291060000038],
            [67.031867764000083, 24.890289141000039],
            [67.031759700000066, 24.890263681000079],
            [67.031466090000038, 24.890194506000057],
            [67.031343670000069, 24.890165664000051],
            [67.031228469000041, 24.890138523000076],
            [67.030807880000054, 24.890021827000055],
            [67.030027206000057, 24.889797491000024],
            [67.02962249400008, 24.889656503000026],
            [67.029613404000031, 24.889653783000028],
            [67.029605021000066, 24.889651911000044],
            [67.029030411000065, 24.889543647000039],
            [67.028379766000057, 24.889372232000028],
            [67.027763042000061, 24.88916454200006],
            [67.027283360000069, 24.888987089000068],
            [67.026979547000053, 24.888816940000027],
            [67.026692595000043, 24.888600206000035],
            [67.026558508000051, 24.888679173000071],
            [67.026488727000071, 24.888720268000043],
            [67.026471614000059, 24.88874478300005],
            [67.026366625000037, 24.888895175000073],
            [67.026231193000058, 24.889255857000023],
            [67.026215370000045, 24.88929799400006],
            [67.026202982000029, 24.889355697000042],
            [67.026146165000057, 24.889620348000051],
            [67.026135485000054, 24.889759620000063],
            [67.02612653500006, 24.889876325000046],
            [67.026109322000082, 24.890100782000047],
            [67.026099747000046, 24.890225649000058],
            [67.026099513000077, 24.89054810600004],
            [67.026076445000058, 24.890675745000067],
            [67.026075960000071, 24.890678429000047],
            [67.02606873600007, 24.890718399000036],
            [67.026052089000075, 24.890738105000025],
            [67.025944687000049, 24.890865247000079],
            [67.025515377000033, 24.89109785900007],
            [67.024921787000039, 24.891349857000023],
            [67.024807220000071, 24.891398493000054],
            [67.024518571000044, 24.891561158000059],
            [67.024438881000037, 24.891606066000065],
            [67.024339136000037, 24.891689474000032],
            [67.024139837000064, 24.891856128000029],
            [67.023743712000055, 24.892263029000048],
            [67.02360791600006, 24.892402520000076],
            [67.023388068000031, 24.892592479000029],
            [67.023218434000057, 24.892739051000035],
            [67.023069586000076, 24.892900349000058],
            [67.022808147000035, 24.89318365400004],
            [67.022565234000069, 24.893499054000074],
            [67.022347009000043, 24.893782400000077],
            [67.022306853000032, 24.893840769000064],
            [67.021914233000075, 24.894411473000048],
            [67.021882464000043, 24.894455597000047],
            [67.021845147000079, 24.894507427000065],
            [67.021678560000055, 24.894738803000052],
            [67.021608171000082, 24.894801915000073],
            [67.021409998000081, 24.894979601000045],
            [67.02120377600005, 24.895225277000065],
            [67.021023514000035, 24.895464968000056],
            [67.02088542000007, 24.895677809000063],
            [67.020839341000055, 24.895809850000035],
            [67.020831571000031, 24.895949073000054],
            [67.020864767000035, 24.896265569000036],
            [67.020865804000039, 24.896275455000023],
            [67.020867761000034, 24.896286043000032],
            [67.020889903000068, 24.89640580400004],
            [67.020900814000072, 24.896464814000069],
            [67.020907209000029, 24.896499403000064],
            [67.020926854000038, 24.896605660000034],
            [67.020996148000052, 24.896806605000052],
            [67.02105227900006, 24.896969379000041],
            [67.021121194000045, 24.897169225000027],
            [67.021142624000049, 24.897246598000038],
            [67.021220536000044, 24.897527904000071],
            [67.021231578000084, 24.897588039000027],
            [67.021265769000081, 24.897774240000047],
            [67.021299719000069, 24.897959129000071],
            [67.021335189000069, 24.898405979000074],
            [67.02135284700006, 24.898628441000028],
            [67.021374618000038, 24.899018690000048],
            [67.021401685000058, 24.899503866000032],
            [67.021406643000034, 24.899592735000056],
            [67.021409537000068, 24.899644616000046],
            [67.021444437000071, 24.899965518000045],
            [67.021465320000061, 24.900157540000066],
            [67.021472766000045, 24.900442509000072],
            [67.02146582000006, 24.900554474000046],
            [67.021455755000034, 24.900716715000044],
            [67.021422342000051, 24.901255341000024],
            [67.021420748000082, 24.901281914000037],
            [67.021404533000066, 24.901552222000078],
            [67.021395174000077, 24.901708259000031],
            [67.021387246000074, 24.901793498000075],
            [67.02135723300006, 24.90211619300004],
            [67.021356548000028, 24.90212355400007],
            [67.021353538000028, 24.902258513000049],
            [67.021352572000069, 24.902301855000076],
            [67.02135656300004, 24.90233601500006],
            [67.021376948000068, 24.902510514000028],
            [67.021383023000055, 24.902562513000078],
            [67.021422323000081, 24.902765522000038],
            [67.021437670000068, 24.902844798000046],
            [67.021451610000042, 24.902916808000043],
            [67.021489953000071, 24.903114870000024],
            [67.021493701000054, 24.90313423200007],
            [67.02158032300008, 24.903405685000052],
            [67.021605923000038, 24.903455310000027],
            [67.021695099000056, 24.903628173000072],
            [67.021707027000048, 24.903659314000038],
            [67.021724497000037, 24.903704919000063],
            [67.021776472000056, 24.903840605000028],
            [67.02181082900006, 24.904007438000065],
            [67.021814058000075, 24.904052433000061],
            [67.021820813000033, 24.904146547000039],
            [67.021829785000079, 24.904271567000023],
            [67.021829733000061, 24.90434074500007],
            [67.021829604000061, 24.904510164000044],
            [67.021829569000033, 24.90455653500004],
            [67.02183063800004, 24.904569140000035],
            [67.021847968000031, 24.904773548000037],
            [67.021853717000056, 24.904841350000027],
            [67.021856151000065, 24.904870062000043],
            [67.02185656100005, 24.904878111000073],
            [67.021863420000045, 24.905012810000073],
            [67.021864188000052, 24.90502790000005],
            [67.021867477000058, 24.905092483000033],
            [67.021848221000027, 24.905221495000035],
            [67.021835897000074, 24.905253196000046],
            [67.021813724000083, 24.905310228000076],
            [67.021778289000054, 24.90540137000005],
            [67.021775279000053, 24.905409112000029],
            [67.02165188500004, 24.905609747000028],
            [67.021410391000074, 24.905814633000034],
            [67.021320223000032, 24.905875732000027],
            [67.021245472000032, 24.905926384000054],
            [67.020906800000034, 24.906155873000046],
            [67.020833027000037, 24.906201926000051],
            [67.020679212000061, 24.906297945000063],
            [67.020530810000082, 24.906390586000043],
            [67.020429723000063, 24.906453690000035],
            [67.02024979600003, 24.906525258000045],
            [67.020157785000038, 24.906605125000056],
            [67.019904688000054, 24.906921179000051],
            [67.019665205000081, 24.90710961700006],
            [67.019573441000034, 24.907181822000041],
            [67.019524834000038, 24.907219145000056],
            [67.019220769000071, 24.907452625000076],
            [67.01911622700004, 24.907547111000042],
            [67.01902418800006, 24.907665196000039],
            [67.018844337000075, 24.90795664500007],
            [67.018700701000057, 24.908244865000029],
            [67.01861995400003, 24.908479070000055],
            [67.018570150000073, 24.908623526000042],
            [67.018457065000064, 24.908923110000046],
            [67.018349446000059, 24.909133165000071],
            [67.018199879000065, 24.909327657000063],
            [67.018137594000052, 24.909440844000073],
            [67.018109742000036, 24.909491456000069],
            [67.018096710000066, 24.909524730000044],
            [67.018002250000052, 24.90976590300005],
            [67.017979349000029, 24.909884790000035],
            [67.017952199000035, 24.910025736000023],
            [67.017951416000074, 24.910082827000053],
            [67.017948171000057, 24.910319299000037],
            [67.017952158000071, 24.910342188000072],
            [67.017979936000074, 24.910501662000058],
            [67.017997749000074, 24.910603928000057],
            [67.018051211000056, 24.910896210000033],
            [67.018062591000046, 24.910958424000057],
            [67.018080922000081, 24.911033108000026],
            [67.018141832000083, 24.911281258000031],
            [67.018241231000047, 24.91154565100004],
            [67.018353982000065, 24.911738736000075],
            [67.018487927000081, 24.911949485000036],
            [67.018565271000057, 24.912105523000037],
            [67.018593922000036, 24.912163323000073],
            [67.018635926000059, 24.912337080000043],
            [67.018628082000077, 24.91256640000006],
            [67.018619141000045, 24.912608138000053],
            [67.018535793000069, 24.912997204000078],
            [67.018478009000034, 24.913321778000068],
            [67.018441885000072, 24.913524690000031],
            [67.018430198000033, 24.913768309000034],
            [67.018429938000054, 24.914098983000031],
            [67.018443785000045, 24.914320260000068],
            [67.018445544000031, 24.914348367000059],
            [67.018448856000077, 24.914401296000051],
            [67.018458678000059, 24.914486614000054],
            [67.01846902300008, 24.914576476000036],
            [67.018469241000048, 24.914578372000051],
            [67.018474840000067, 24.914627002000032],
            [67.018483414000059, 24.91470147900003],
            [67.018484756000078, 24.91471314000006],
            [67.018490713000062, 24.914764886000057],
            [67.018490675000066, 24.914813208000055],
            [67.018490668000084, 24.914821761000042],
            [67.018490627000062, 24.914873514000078],
            [67.018490587000031, 24.914924554000038],
            [67.018490579000058, 24.914934903000074],
            [67.01849055100007, 24.914970953000022],
            [67.018490506000035, 24.91502734200003],
            [67.018490501000031, 24.915034485000035],
            [67.018490434000057, 24.915119425000057],
            [67.01849033700006, 24.915241640000033],
            [67.018485962000057, 24.915273461000027],
            [67.018474582000067, 24.915356226000029],
            [67.018472085000042, 24.915374391000057],
            [67.018452919000083, 24.915513786000076],
            [67.018441855000049, 24.915594253000052],
            [67.018437358000028, 24.915626961000044],
            [67.018424494000044, 24.915720520000036],
            [67.018412149000028, 24.915810308000061],
            [67.018405569000038, 24.915858164000042],
            [67.018403141000078, 24.915945058000034],
            [67.018399657000032, 24.916069711000034],
            [67.018399379000073, 24.916079663000062],
            [67.018395588000033, 24.91621529300005],
            [67.01839323400003, 24.916299525000056],
            [67.018390638000028, 24.916392399000074],
            [67.01838980000008, 24.916422408000074],
            [67.018372395000029, 24.916593598000077],
            [67.018343550000054, 24.916877329000044],
            [67.018325589000028, 24.917053992000035],
            [67.018316436000077, 24.917144019000034],
            [67.018302458000051, 24.917242017000035],
            [67.018296833000079, 24.917281450000075],
            [67.018267864000052, 24.917484547000072],
            [67.018249860000083, 24.917610767000042],
            [67.018216142000028, 24.917847152000036],
            [67.018197800000053, 24.918054688000041],
            [67.018188415000054, 24.918160898000053],
            [67.018170797000039, 24.918360254000049],
            [67.018169644000068, 24.918373302000077],
            [67.018134008000061, 24.918596816000047],
            [67.018113054000082, 24.918728240000064],
            [67.018103620000034, 24.918787408000071],
            [67.018030586000066, 24.919089020000058],
            [67.018030268000075, 24.919091982000054],
            [67.018024585000035, 24.919145043000071],
            [67.018003086000078, 24.919345752000027],
            [67.017991233000032, 24.919456401000048],
            [67.017993482000065, 24.919546697000044],
            [67.017999169000063, 24.919775091000076],
            [67.018001368000057, 24.919863376000023],
            [67.01799569800005, 24.919959295000069],
            [67.017989338000064, 24.920066882000071],
            [67.017962533000059, 24.920520329000055],
            [67.01795985800004, 24.920547428000077],
            [67.017942513000037, 24.920723102000068],
            [67.017898754000043, 24.921166311000036],
            [67.017893882000067, 24.921215650000079],
            [67.017893018000052, 24.921224401000075],
            [67.017889222000065, 24.921608558000059],
            [67.017888854000034, 24.921645758000068],
            [67.017899889000034, 24.92181621900005],
            [67.017907777000062, 24.92193805200003],
            [67.01793434700005, 24.922090102000027],
            [67.017964984000059, 24.922265425000035],
            [67.018032730000073, 24.922456982000028],
            [67.018083975000081, 24.922601883000027],
            [67.018152834000034, 24.922730485000045],
            [67.01826387400007, 24.92280699500003],
            [67.018447664000064, 24.922928717000048],
            [67.018572704000064, 24.922998632000031],
            [67.018627425000034, 24.923029229000065],
            [67.018696323000029, 24.923102233000066],
            [67.018734511000048, 24.923255122000057],
            [67.01873545400008, 24.923320946000047],
            [67.018735550000031, 24.923327660000041],
            [67.018740667000031, 24.923684921000074],
            [67.018741779000038, 24.923762529000044],
            [67.018740534000074, 24.923797842000056],
            [67.018737381000051, 24.923887293000064],
            [67.018728459000044, 24.924140372000068],
            [67.01872845500003, 24.924140484000077],
            [67.018727013000046, 24.924181402000045],
            [67.018724578000047, 24.92425048900003],
            [67.018720233000067, 24.924399369000071],
            [67.018719839000028, 24.924412869000037],
            [67.018715283000063, 24.924568983000029],
            [67.018712767000068, 24.924655206000068],
            [67.01869049700008, 24.924736301000053],
            [67.018666783000072, 24.924822655000071],
            [67.018620588000033, 24.924959528000045],
            [67.018609151000078, 24.925079538000034],
            [67.018609001000073, 24.925081116000058],
            [67.01862802200003, 24.925251358000025],
            [67.018698275000077, 24.925401233000059],
            [67.01871598300005, 24.925439009000058],
            [67.018739004000054, 24.925464963000024],
            [67.018743795000034, 24.925470365000024],
            [67.018826975000081, 24.925564142000042],
            [67.019045493000078, 24.925679732000049],
            [67.019184956000061, 24.925743081000064],
            [67.019213984000032, 24.925756266000064],
            [67.019323410000084, 24.925822525000058],
            [67.019351823000079, 24.925839729000074],
            [67.019377319000057, 24.925875870000027],
            [67.019424419000075, 24.925942636000059],
            [67.019445713000039, 24.925995320000027],
            [67.019566389000033, 24.926293888000032],
            [67.01956741500004, 24.926296424000043],
            [67.019632341000033, 24.926550064000025],
            [67.019656767000072, 24.926642118000075],
            [67.019685812000034, 24.926751582000065],
            [67.019724015000065, 24.926883616000055],
            [67.019785235000029, 24.926977454000053],
            [67.019884398000045, 24.92704672900004],
            [67.019884786000034, 24.927047000000073],
            [67.020068618000039, 24.927123551000022],
            [67.020208756000045, 24.927154226000027],
            [67.02035429700004, 24.92720296300007],
            [67.020453855000028, 24.927262088000077],
            [67.020515079000063, 24.927352453000026],
            [67.020534155000064, 24.927453211000056],
            [67.020530637000036, 24.927505251000071],
            [67.020526405000055, 24.927567848000024],
            [67.020458441000073, 24.927829871000029],
            [67.020438033000062, 24.927908584000079],
            [67.020363606000046, 24.928164022000033],
            [67.02035439000008, 24.928177730000073],
            [67.02033808300007, 24.928201986000033],
            [67.020309911000084, 24.928243889000044],
            [67.020160385000054, 24.928396649000035],
            [67.019799882000029, 24.928683955000054],
            [67.019797554000036, 24.928685810000047],
            [67.019678204000058, 24.928787333000059],
            [67.019422421000058, 24.92900491000006],
            [67.019351860000029, 24.929076563000024],
            [67.019120443000077, 24.929311557000062],
            [67.019112183000061, 24.929322712000044],
            [67.01890376700004, 24.929604156000039],
            [67.018829449000066, 24.929704515000026],
            [67.018695790000038, 24.929843814000037],
            [67.018521528000065, 24.930025429000068],
            [67.018491023000081, 24.930072302000042],
            [67.018378214000052, 24.930245641000056],
            [67.018293376000031, 24.93037600100007],
            [67.018279781000047, 24.930399418000036],
            [67.018268038000031, 24.93041964400004],
            [67.018245935000039, 24.930457715000045],
            [67.018223955000053, 24.930495575000066],
            [67.018164918000082, 24.930597264000028],
            [67.018126678000044, 24.93069397000005],
            [67.018093499000031, 24.930777879000061],
            [67.018039802000033, 24.930861212000025],
            [67.018030071000055, 24.930870653000056],
            [67.017986134000068, 24.930913281000073],
            [67.017821341000058, 24.93100349100007],
            [67.017561317000059, 24.931074046000049],
            [67.017553145000079, 24.931076264000069],
            [67.017292661000056, 24.931096934000038],
            [67.017271023000035, 24.931093591000035],
            [67.017067327000063, 24.931062116000078],
            [67.016937106000057, 24.931044675000066],
            [67.016865137000082, 24.931060942000045],
            [67.016783872000076, 24.93107931000003],
            [67.016666891000057, 24.93113642000003],
            [67.016505953000035, 24.931233557000041],
            [67.016429237000068, 24.931284242000061],
            [67.016364164000038, 24.931327236000072],
            [67.016324474000044, 24.931346739000048],
            [67.016252138000084, 24.931382285000041],
            [67.016230053000072, 24.931393137000043],
            [67.016161078000039, 24.931431298000064],
            [67.016092111000034, 24.93145903900006],
            [67.016034663000028, 24.931452054000033],
            [67.015965749000031, 24.931413801000076],
            [67.015854753000042, 24.93131995300007],
            [67.015745231000039, 24.93123038400006],
            [67.015700000000038, 24.931307000000061],
            [67.01567650100003, 24.931355677000056],
            [67.01565800000003, 24.931394000000068],
            [67.015625253000053, 24.931474957000034],
            [67.015612713000053, 24.931482481000046],
            [67.015508054000065, 24.93177552700007],
            [67.015424326000073, 24.932047642000043],
            [67.015403395000078, 24.932225563000031],
            [67.015539452000041, 24.932288358000051],
            [67.015832498000066, 24.932424415000071],
            [67.016052282000032, 24.932518609000056],
            [67.016209271000037, 24.932550006000042],
            [67.016439522000042, 24.932550006000042],
            [67.016722102000074, 24.932529075000048],
            [67.016973284000073, 24.932508143000064],
            [67.017245399000046, 24.932445347000055],
            [67.017538445000071, 24.932445347000055],
            [67.017747763000045, 24.932591870000067],
            [67.017998946000034, 24.932895382000027],
            [67.018260594000083, 24.933188428000051],
            [67.018543174000058, 24.933429145000048],
            [67.018888550000042, 24.933669861000055],
            [67.01931765300003, 24.933962907000023],
            [67.019694427000047, 24.934172226000044],
            [67.020123530000035, 24.934465272000068],
            [67.020468906000076, 24.934674591000032],
            [67.02081428200006, 24.934862978000069],
            [67.021211987000072, 24.935082762000036],
            [67.021546897000064, 24.935250217000032],
            [67.021839943000032, 24.935438604000069],
            [67.022017864000077, 24.935626991000049],
            [67.022164387000032, 24.935752582000077],
            [67.022352774000069, 24.935920037000074],
            [67.022603956000069, 24.936171219000073],
            [67.022698150000053, 24.936338674000069],
            [67.022802809000041, 24.936443333000057],
            [67.023116787000049, 24.936694516000045],
            [67.023441231000049, 24.936914300000069],
            [67.023723811000082, 24.937081755000065],
            [67.024048255000082, 24.937165483000058],
            [67.024268040000038, 24.937301540000078],
            [67.024410132000071, 24.93747546000003],
            [67.025776663000045, 24.938814679000075],
            [67.02684742100007, 24.939766464000058],
            [67.027561259000038, 24.940432713000064],
            [67.028203714000028, 24.941003783000042],
            [67.028834271000051, 24.941574854000066],
            [67.029298266000069, 24.942026952000049],
            [67.029809850000049, 24.942574228000069],
            [67.030428510000036, 24.943145298000047],
            [67.031059067000058, 24.943787753000038],
            [67.031606343000078, 24.944335029000058],
            [67.032189311000081, 24.944870408000043],
            [67.032772279000028, 24.945548554000027],
            [67.033640782000077, 24.946595517000048],
            [67.034021496000037, 24.947059512000067],
            [67.034223750000081, 24.947333150000077],
            [67.034497388000034, 24.947618685000066],
            [67.034961383000052, 24.948070783000048],
            [67.03529450700006, 24.948439599000039],
            [67.035687118000055, 24.948915491000037],
            [67.036508032000029, 24.949926762000075],
            [67.037174281000034, 24.950830957000051],
            [67.037888120000048, 24.951723255000047],
            [67.038316423000083, 24.952341915000034],
            [67.038911288000065, 24.953150932000028],
            [67.039458564000029, 24.953912359000071],
            [67.039744099000075, 24.954316868000035],
            [67.040017737000028, 24.954828452000072],
            [67.04023188900004, 24.955244858000071],
            [67.04050552700005, 24.955613674000062],
            [67.040981419000047, 24.956303718000072],
            [67.04133833800006, 24.956803405000073],
            [67.041623874000038, 24.957338783000068],
            [67.041826128000082, 24.957648113000062],
            [67.041956998000046, 24.957862265000074],
            [67.042087868000067, 24.958016930000042],
            [67.042266328000039, 24.958219184000029],
            [67.042551863000028, 24.958659384000043],
            [67.042873091000047, 24.959289941000065],
            [67.043170523000072, 24.959849115000054],
            [67.043396572000063, 24.960348801000066],
            [67.043629732000056, 24.961000012000056],
            [67.043794288000072, 24.961316466000028],
            [67.043946186000028, 24.961481023000033],
            [67.044161375000044, 24.961594946000048],
            [67.044300615000054, 24.961759503000053],
            [67.044351248000055, 24.962012666000078],
            [67.044249982000053, 24.962341778000052],
            [67.044123401000036, 24.962481018000062],
            [67.044009477000031, 24.962569626000061],
            [67.044009477000031, 24.962759498000025],
            [67.044124786000054, 24.963322664000032],
            [67.043933528000082, 24.963455698000075],
            [67.043768972000066, 24.963607596000031],
            [67.04369302300006, 24.96378481000005],
            [67.043687352000063, 24.963971866000065],
            [67.043740577000051, 24.96516682400005],
            [67.044405406000067, 24.965194526000062],
            [67.045111147000057, 24.965223931000025],
            [67.046205699000041, 24.965214414000059],
            [67.046708933000048, 24.965309930000046],
            [67.047053062000032, 24.96535433400004],
            [67.047108566000077, 24.965432040000053],
            [67.047175172000038, 24.965543049000075],
            [67.04731948400007, 24.965543049000075],
            [67.047907834000057, 24.965265526000053],
            [67.048407376000057, 24.964888094000059],
            [67.051881968000032, 24.964832590000071],
            [67.053555000000074, 24.964861270000029],
            [67.053555000000074, 24.965193000000056],
            [67.053532546000042, 24.965526563000026],
            [67.053204443000084, 24.96549535500003],
            [67.052846680000073, 24.965635349000024],
            [67.052504473000056, 24.965915337000069],
            [67.051975607000031, 24.966226434000077],
            [67.051882278000051, 24.96613310500004],
            [67.051773394000065, 24.96600866600005],
            [67.051228973000036, 24.965899782000065],
            [67.050280126000075, 24.965806453000027],
            [67.049222394000083, 24.965930892000074],
            [67.048849077000057, 24.966615306000051],
            [67.04889574200007, 24.967346386000031],
            [67.049035736000064, 24.968233013000031],
            [67.048864632000061, 24.968528556000024],
            [67.048849077000057, 24.969819611000048],
            [67.048826355000074, 24.970243754000023],
            [67.048802413000033, 24.970690684000033],
            [67.04889574200007, 24.971064001000059],
            [67.04923794900003, 24.971406208000076],
            [67.049300169000048, 24.972152842000071],
            [67.049393498000029, 24.972775037000076],
            [67.049580156000047, 24.973319458000049],
            [67.049516144000052, 24.973716338000031],
            [67.049502382000071, 24.97380165900006],
            [67.049440162000053, 24.974050537000039],
            [67.04965793100007, 24.975077159000023],
            [67.050015693000034, 24.976228220000053],
            [67.050497894000046, 24.977021519000061],
            [67.050871211000072, 24.977768153000056],
            [67.050902321000081, 24.978250354000068],
            [67.05063788800004, 24.978748110000026],
            [67.050217906000057, 24.979276976000051],
            [67.050062358000048, 24.979696957000044],
            [67.050109022000072, 24.980116939000027],
            [67.050342345000047, 24.980816909000055],
            [67.050373455000056, 24.981112451000058],
            [67.050326790000042, 24.981330220000075],
            [67.050171242000033, 24.981625762000078],
            [67.050077912000063, 24.982854597000028],
            [67.050077912000063, 24.983087921000049],
            [67.050186797000038, 24.983259024000063],
            [67.050124536000055, 24.983769560000042],
            [67.050109022000072, 24.983896774000073],
            [67.050217906000057, 24.984627853000063],
            [67.050435675000074, 24.985234494000053],
            [67.050824546000058, 24.985856689000059],
            [67.051384522000035, 24.986416664000046],
            [67.05191338800006, 24.986945530000071],
            [67.052220306000038, 24.987215246000062],
            [67.052737796000031, 24.987987707000059],
            [67.053001436000045, 24.988432127000067],
            [67.05328221700006, 24.988905444000068],
            [67.053811083000028, 24.990103170000054],
            [67.054091071000073, 24.991145347000042],
            [67.054231064000078, 24.991938645000062],
            [67.054495497000062, 24.992934157000036],
            [67.054481992000035, 24.993474382000045],
            [67.054479942000057, 24.993556352000041],
            [67.054417723000029, 24.993883005000043],
            [67.054386613000077, 24.994147438000027],
            [67.054215510000063, 24.994271877000074],
            [67.05404440600006, 24.994536310000058],
            [67.053951077000079, 24.994645194000043],
            [67.053624424000077, 24.994676304000052],
            [67.053173333000075, 24.995158505000063],
            [67.052831126000058, 24.995485157000076],
            [67.052395589000071, 24.996542889000068],
            [67.052146711000034, 24.997507291000034],
            [67.052068937000058, 24.997927273000073],
            [67.052162266000039, 24.998502803000065],
            [67.051804504000074, 24.99861168700005],
            [67.051617845000067, 24.998829456000067],
            [67.051319748000083, 24.999368520000075],
            [67.051200000000051, 25.001512000000048],
            [67.051083000000062, 25.003321000000028],
            [67.051065262000066, 25.003600629000061],
            [67.051049000000035, 25.003857000000039],
            [67.050939000000028, 25.005550000000028],
            [67.051008000000081, 25.005687000000023],
            [67.051163000000031, 25.005733000000077],
            [67.051315000000045, 25.005791000000045],
            [67.051517920000038, 25.005802988000028],
            [67.05158872700008, 25.005807171000072],
            [67.052100311000061, 25.005837394000025],
            [67.052150979000032, 25.005840387000035],
            [67.052867783000067, 25.005882734000068],
            [67.052940000000035, 25.00588700000003],
            [67.053802000000076, 25.005934000000025],
            [67.053867061000062, 25.005937349000078],
            [67.053938000000073, 25.005941000000064],
            [67.054834874000051, 25.005989407000072],
            [67.056424981000077, 25.006075231000068],
            [67.05692478900005, 25.006102207000026],
            [67.05762500000003, 25.006140000000073],
            [67.059120176000079, 25.006219864000059],
            [67.059194674000082, 25.006223844000033],
            [67.060246000000063, 25.006280000000061],
            [67.061093484000082, 25.006325247000063],
            [67.062119000000052, 25.006380000000036],
            [67.062746000000061, 25.006409000000076],
            [67.062990162000062, 25.006420597000044],
            [67.063299244000063, 25.006435277000037],
            [67.063525000000084, 25.006446000000039],
            [67.063597000000073, 25.006453000000079],
            [67.063668000000064, 25.006466000000046],
            [67.063738000000058, 25.006485000000055],
            [67.063806000000056, 25.006510000000048],
            [67.06387200000006, 25.00654000000003],
            [67.063936000000069, 25.006576000000052],
            [67.063996000000031, 25.006616000000065],
            [67.064053000000058, 25.006661000000065],
            [67.064075000000059, 25.006695000000036],
            [67.064117000000067, 25.006745000000024],
            [67.064119500000061, 25.006747344000075],
            [67.064133000000083, 25.006760000000043],
            [67.064167000000054, 25.006787000000031],
            [67.064223000000084, 25.006821000000059],
            [67.064283000000046, 25.006846000000053],
            [67.064347000000055, 25.006861000000072],
            [67.064412000000061, 25.006867000000057],
            [67.064477000000068, 25.006861000000072],
            [67.064541000000077, 25.006846000000053],
            [67.064601000000039, 25.006821000000059],
            [67.064657637000039, 25.006786223000063],
            [67.064658000000065, 25.006786000000034],
            [67.064691000000039, 25.006760000000043],
            [67.064707000000055, 25.006745000000024],
            [67.064749000000063, 25.006695000000036],
            [67.064780000000042, 25.006645000000049],
            [67.064793000000066, 25.006619000000057],
            [67.064808000000028, 25.006579000000045],
            [67.064981461000059, 25.00655555700007],
            [67.065111160000072, 25.006547928000032],
            [67.065519333000054, 25.006567001000064],
            [67.065582092000056, 25.006569454000044],
            [67.066251965000049, 25.006595638000078],
            [67.066290711000079, 25.00659715300003],
            [67.066303361000053, 25.006597647000035],
            [67.066579391000062, 25.006608437000068],
            [67.066690445000063, 25.006612778000033],
            [67.066841928000031, 25.006619586000056],
            [67.066998162000061, 25.006626608000033],
            [67.067086863000043, 25.006630594000058],
            [67.067102138000052, 25.006631281000068],
            [67.067370227000083, 25.006643330000031],
            [67.06770896900008, 25.006658554000069],
            [67.067851301000076, 25.006665372000043],
            [67.06834602400005, 25.006689072000029],
            [67.069067001000064, 25.006727219000027],
            [67.069127919000039, 25.006730641000047],
            [67.069608892000076, 25.006757662000041],
            [67.06987859700007, 25.006772814000044],
            [67.070085526000071, 25.006784439000057],
            [67.070196950000081, 25.006790704000025],
            [67.07081495400007, 25.006825451000054],
            [67.071035385000073, 25.006837845000064],
            [67.071299748000058, 25.006849129000045],
            [67.071660995000059, 25.006864548000067],
            [67.072249120000038, 25.006895882000038],
            [67.07255942300003, 25.006912415000045],
            [67.072591782000075, 25.00691413900006],
            [67.072855648000029, 25.006927671000028],
            [67.073001196000064, 25.006935135000049],
            [67.073175171000059, 25.006944057000055],
            [67.073335648000068, 25.006952286000057],
            [67.07376832500006, 25.006972478000023],
            [67.074265178000076, 25.006995664000044],
            [67.07434401900008, 25.006999343000075],
            [67.074480057000073, 25.007005692000064],
            [67.07456747100008, 25.00701014200007],
            [67.075317811000048, 25.007048341000029],
            [67.075382783000066, 25.007051649000061],
            [67.07552909900005, 25.007059097000024],
            [67.07607607500006, 25.007094446000053],
            [67.076484228000083, 25.007120824000026],
            [67.076650620000066, 25.007131577000052],
            [67.076790533000064, 25.007137482000076],
            [67.076971276000052, 25.007145111000057],
            [67.077357953000046, 25.007161432000032],
            [67.077713712000048, 25.007176448000052],
            [67.077825546000042, 25.007181168000045],
            [67.078046161000032, 25.007193424000036],
            [67.078230551000047, 25.007203668000045],
            [67.079077957000038, 25.007250746000068],
            [67.079404831000033, 25.007268906000036],
            [67.079416218000063, 25.007269638000025],
            [67.079440914000031, 25.007271226000057],
            [67.079768471000079, 25.007292283000027],
            [67.081007004000071, 25.007371902000045],
            [67.08133368600005, 25.007393088000072],
            [67.081552422000073, 25.007407274000059],
            [67.081696429000033, 25.007416613000032],
            [67.082830429000069, 25.007490158000053],
            [67.082894064000072, 25.007492506000062],
            [67.083296741000083, 25.007507362000069],
            [67.083865499000069, 25.007528345000026],
            [67.084573249000073, 25.007554456000037],
            [67.084794998000064, 25.007562637000035],
            [67.085048148000055, 25.007572784000047],
            [67.08621579000004, 25.007619589000058],
            [67.086603165000042, 25.007635117000063],
            [67.086851656000078, 25.007644453000069],
            [67.087923050000029, 25.007684708000056],
            [67.088153224000052, 25.007694913000023],
            [67.08947181700006, 25.007753372000025],
            [67.089765549000049, 25.007768631000033],
            [67.089906693000046, 25.007776260000071],
            [67.089994431000036, 25.007783890000042],
            [67.090066910000075, 25.007791519000023],
            [67.090173721000042, 25.007802963000074],
            [67.09026908900006, 25.007818222000026],
            [67.09040260300003, 25.007841110000072],
            [67.090585709000038, 25.007871628000032],
            [67.090698082000074, 25.007895148000046],
            [67.090749741000081, 25.007905960000073],
            [67.090893311000059, 25.007944352000038],
            [67.09089436000005, 25.007944633000022],
            [67.090988907000053, 25.007969915000047],
            [67.091133312000068, 25.008008530000041],
            [67.091470748000063, 25.008098764000067],
            [67.091589658000032, 25.008130562000076],
            [67.091627383000059, 25.008104059000061],
            [67.091596193000044, 25.007872678000069],
            [67.091594677000046, 25.007861430000048],
            [67.091581671000029, 25.007764946000066],
            [67.091561719000083, 25.007616929000051],
            [67.091546562000076, 25.007427377000056],
            [67.091535247000081, 25.007285876000026],
            [67.091530453000075, 25.006755379000026],
            [67.091530061000071, 25.006711978000055],
            [67.091535463000071, 25.006673369000055],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "149",
      properties: { name: "Karachi West", count: 614 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.932489507000071, 24.997686680000072],
            [66.933181862000083, 24.993969826000068],
            [66.93365557900006, 24.992111399000066],
            [66.933546260000071, 24.989086900000075],
            [66.93365557900006, 24.985187847000077],
            [66.933692019000034, 24.976515187000075],
            [66.933327621000046, 24.976005031000057],
            [66.932635266000034, 24.975421995000033],
            [66.932234429000061, 24.974401682000064],
            [66.931942911000078, 24.973527128000057],
            [66.931614953000064, 24.972834773000045],
            [66.930740400000047, 24.972251737000079],
            [66.93034626900004, 24.972049725000034],
            [66.931681806000029, 24.968584022000073],
            [66.937315114000057, 24.971824708000042],
            [66.938640683000074, 24.972634532000029],
            [66.939228705000062, 24.972963425000046],
            [66.94002602200004, 24.973282352000069],
            [66.941610689000072, 24.974109568000074],
            [66.945467711000049, 24.974548093000067],
            [66.94683311600005, 24.976062995000063],
            [66.949902787000042, 24.976292224000076],
            [66.951078830000029, 24.974418529000047],
            [66.953979071000049, 24.975823800000057],
            [66.95768659600003, 24.977956623000068],
            [66.958703175000039, 24.977278904000059],
            [66.959341029000029, 24.976989876000061],
            [66.960028715000078, 24.976740715000062],
            [66.960786166000048, 24.976571285000034],
            [66.960935663000043, 24.976501520000056],
            [66.961573516000044, 24.975534773000049],
            [66.96212167200008, 24.974607892000051],
            [66.962350901000036, 24.974119535000057],
            [66.962719660000062, 24.97370094300004],
            [66.963118318000056, 24.973182687000076],
            [66.963327614000036, 24.972933526000077],
            [66.963337580000029, 24.972803962000057],
            [66.963357513000062, 24.972594666000077],
            [66.963198050000074, 24.972265773000061],
            [66.962404066000033, 24.971051069000055],
            [66.96246053200008, 24.970750870000074],
            [66.962619995000068, 24.970372144000066],
            [66.962659861000077, 24.96979409000005],
            [66.962819324000066, 24.968707745000074],
            [66.963206451000076, 24.965858775000072],
            [66.963960702000065, 24.966059999000038],
            [66.964602510000077, 24.966238730000043],
            [66.965081836000081, 24.966254979000041],
            [66.965520540000057, 24.966173737000076],
            [66.966146101000049, 24.966092496000044],
            [66.966536060000067, 24.965978757000073],
            [66.966771661000053, 24.965832523000074],
            [66.967015386000071, 24.965743157000077],
            [66.968055278000065, 24.965759405000028],
            [66.967589992000057, 24.969171355000071],
            [66.967333010000061, 24.970856013000059],
            [66.967504331000043, 24.971655512000041],
            [66.968295879000038, 24.975887145000058],
            [66.969243710000057, 24.980876729000045],
            [66.977065094000068, 24.980063525000048],
            [66.977028545000053, 24.982311259000028],
            [66.979394936000062, 24.982358310000052],
            [66.979598311000075, 24.982366223000042],
            [66.981326504000037, 24.982433468000067],
            [66.981082086000072, 24.984388814000056],
            [66.980881314000044, 24.986317972000052],
            [66.985219738000069, 24.986990122000066],
            [66.984783277000076, 24.988412985000025],
            [66.990692960000047, 24.989146240000025],
            [66.990640585000051, 24.984371355000064],
            [66.993623539000055, 24.984151613000051],
            [66.994725305000031, 24.984080740000024],
            [66.996026806000032, 24.984016309000026],
            [66.996825747000059, 24.983977650000043],
            [66.997141458000044, 24.983938992000049],
            [66.997244547000037, 24.983913220000034],
            [66.997244547000037, 24.983823017000077],
            [66.997250990000055, 24.983726371000046],
            [66.997354079000047, 24.983629724000025],
            [66.997482941000044, 24.983520192000071],
            [66.997618245000069, 24.983417103000079],
            [66.997759993000045, 24.983281798000064],
            [66.997856639000076, 24.983165823000036],
            [66.99789529800006, 24.983036962000028],
            [66.997901741000078, 24.982908100000031],
            [66.99789529800006, 24.982753466000077],
            [66.997888855000042, 24.982540845000074],
            [66.997837310000079, 24.982347553000068],
            [66.997792208000078, 24.982173590000059],
            [66.997779322000042, 24.98199962700005],
            [66.997798651000039, 24.981748347000064],
            [66.997772879000081, 24.981613042000049],
            [66.997721335000051, 24.981497067000078],
            [66.997650461000035, 24.98143907900004],
            [66.997592473000054, 24.981374648000042],
            [66.997566701000039, 24.981239344000073],
            [66.997579587000075, 24.980981621000069],
            [66.99762468800003, 24.980820544000039],
            [66.997695562000047, 24.980723898000065],
            [66.997805095000047, 24.980627252000033],
            [66.997882411000035, 24.980517720000023],
            [66.997946842000033, 24.980408187000023],
            [66.997946842000033, 24.980272883000055],
            [66.997927513000036, 24.980066704000023],
            [66.997914627000057, 24.979924957000037],
            [66.997869525000056, 24.979821868000045],
            [66.99778576500006, 24.979731664000042],
            [66.997702005000065, 24.979538372000036],
            [66.997637575000056, 24.979428840000026],
            [66.997586030000036, 24.979254877000074],
            [66.997586030000036, 24.979126015000077],
            [66.997682676000068, 24.978964939000036],
            [66.997792208000078, 24.978855406000037],
            [66.997927513000036, 24.978707216000032],
            [66.997953285000051, 24.978546139000059],
            [66.997914627000057, 24.978404391000026],
            [66.997856639000076, 24.978269087000058],
            [66.997779322000042, 24.978133782000043],
            [66.997727778000069, 24.978037136000069],
            [66.997656904000053, 24.977940490000037],
            [66.997637575000056, 24.97781162800004],
            [66.997631132000038, 24.977560348000054],
            [66.997663347000071, 24.977373499000066],
            [66.997708448000083, 24.977205979000075],
            [66.997714892000033, 24.97707067500005],
            [66.997663347000071, 24.976948256000071],
            [66.997598916000072, 24.976787180000031],
            [66.997579587000075, 24.976587444000074],
            [66.99755381500006, 24.976458583000067],
            [66.997450725000078, 24.976271734000079],
            [66.997328307000032, 24.976181531000066],
            [66.997250990000055, 24.97609777100007],
            [66.997257433000073, 24.975981795000052],
            [66.997321864000071, 24.975833605000048],
            [66.997373409000033, 24.975685414000054],
            [66.997412067000027, 24.975260171000059],
            [66.997450725000078, 24.974996005000037],
            [66.997489384000062, 24.974873586000058],
            [66.997547372000042, 24.974764054000048],
            [66.997592473000054, 24.974622307000061],
            [66.997573144000057, 24.974487002000046],
            [66.99755381500006, 24.974274381000043],
            [66.997534485000074, 24.974106861000053],
            [66.99744428200006, 24.974035987000036],
            [66.997308978000035, 24.973952227000041],
            [66.997186559000056, 24.973887796000042],
            [66.997077027000046, 24.973829808000062],
            [66.99704481200007, 24.973771821000071],
            [66.997135015000083, 24.973700947000054],
            [66.997205889000043, 24.973668732000078],
            [66.997238104000076, 24.973572085000058],
            [66.997212332000061, 24.973494769000069],
            [66.997167230000059, 24.97338523600007],
            [66.997167230000059, 24.973320806000061],
            [66.99727676200007, 24.973295033000056],
            [66.997437839000042, 24.973269261000041],
            [66.99755381500006, 24.973198387000025],
            [66.99762468800003, 24.973082412000053],
            [66.997650461000035, 24.972927778000042],
            [66.99762468800003, 24.97277314400003],
            [66.997573144000057, 24.972644283000022],
            [66.997521599000038, 24.97254119400003],
            [66.997508713000059, 24.972412332000033],
            [66.99755381500006, 24.972277027000075],
            [66.997573144000057, 24.972180381000044],
            [66.997566701000039, 24.972051520000036],
            [66.997528042000056, 24.971954874000062],
            [66.997450725000078, 24.971851785000069],
            [66.997341193000068, 24.971832455000026],
            [66.997257433000073, 24.971774468000035],
            [66.997263876000034, 24.971645606000038],
            [66.997347636000029, 24.971536074000028],
            [66.997450725000078, 24.971432985000035],
            [66.997502270000041, 24.971271908000062],
            [66.997502270000041, 24.971104388000072],
            [66.997502270000041, 24.970988413000043],
            [66.997586030000036, 24.970917539000027],
            [66.997663347000071, 24.970743576000075],
            [66.997644018000074, 24.970582499000045],
            [66.997586030000036, 24.970518068000047],
            [66.997482941000044, 24.97044719400003],
            [66.997418510000045, 24.970324776000041],
            [66.997431396000081, 24.970170142000029],
            [66.99749582700008, 24.970021951000035],
            [66.997534485000074, 24.969905976000064],
            [66.997520875000077, 24.969767728000079],
            [66.997386902000073, 24.969641636000063],
            [66.997355379000055, 24.969468259000053],
            [66.997410544000047, 24.969373690000054],
            [66.997520875000077, 24.969271240000069],
            [66.997560279000083, 24.969184551000069],
            [66.997544517000051, 24.969113624000045],
            [66.997457829000041, 24.969034816000033],
            [66.997371140000041, 24.969011174000059],
            [66.997331737000081, 24.968948128000022],
            [66.997379021000029, 24.968853559000024],
            [66.997457829000041, 24.968782632000057],
            [66.997489352000059, 24.968656539000051],
            [66.997473591000073, 24.968538328000022],
            [66.997371140000041, 24.968435877000047],
            [66.997323856000037, 24.968396474000031],
            [66.99733961700008, 24.968317666000075],
            [66.997418425000035, 24.968246739000051],
            [66.997536637000053, 24.968160050000051],
            [66.997583921000057, 24.96801819600006],
            [66.997552398000039, 24.967915746000074],
            [66.997465710000029, 24.967813296000031],
            [66.997331737000081, 24.967655681000053],
            [66.997252929000069, 24.967505946000074],
            [66.997252929000069, 24.967356211000038],
            [66.997347498000067, 24.967340450000052],
            [66.997544517000051, 24.967277404000072],
            [66.997662729000069, 24.967222238000033],
            [66.997796702000073, 24.967088265000029],
            [66.997883391000073, 24.966946411000038],
            [66.997891272000061, 24.96678879600006],
            [66.997836106000079, 24.966631180000036],
            [66.997686371000043, 24.966505088000076],
            [66.997544517000051, 24.966449922000038],
            [66.997402664000049, 24.966402638000034],
            [66.997292333000075, 24.966410518000032],
            [66.997237167000037, 24.96635535300004],
            [66.997268690000055, 24.966276545000028],
            [66.997363260000043, 24.966142572000024],
            [66.997315975000049, 24.96609528700003],
            [66.997323856000037, 24.966024360000063],
            [66.997473591000073, 24.965921910000077],
            [66.997836106000079, 24.965724891000036],
            [66.998025245000065, 24.965646083000024],
            [66.998096172000032, 24.965559395000071],
            [66.998096172000032, 24.965456945000028],
            [66.998041006000051, 24.965291448000073],
            [66.997970079000083, 24.965125952000051],
            [66.997930675000077, 24.965031383000053],
            [66.997875510000028, 24.964834363000023],
            [66.997914914000035, 24.964771317000043],
            [66.998001602000045, 24.964747675000069],
            [66.998104052000031, 24.964755556000057],
            [66.998356237000053, 24.964724033000039],
            [66.99845080700004, 24.964653106000071],
            [66.998592661000032, 24.964495490000047],
            [66.998718753000048, 24.964361517000043],
            [66.998726634000036, 24.964235424000037],
            [66.998734514000034, 24.964117213000065],
            [66.998702991000073, 24.963967478000029],
            [66.998647826000081, 24.963809863000051],
            [66.998537495000051, 24.96358132000006],
            [66.998435045000065, 24.963352778000058],
            [66.998403522000046, 24.963203043000078],
            [66.998411403000034, 24.963076951000062],
            [66.998474449000071, 24.96299814300005],
            [66.998616303000063, 24.96299814300005],
            [66.998836965000066, 24.963013904000036],
            [66.99914431500008, 24.962974501000076],
            [66.999262526000052, 24.962942977000068],
            [66.999341334000064, 24.962832647000027],
            [66.99938073800007, 24.962714435000066],
            [66.99946742700007, 24.962572581000074],
            [66.999561996000068, 24.962454369000056],
            [66.999656565000066, 24.962383442000032],
            [66.999695969000072, 24.962288873000034],
            [66.999688088000028, 24.962170661000073],
            [66.999695969000072, 24.962052450000044],
            [66.99976689600004, 24.961981523000077],
            [66.999892988000056, 24.961957881000046],
            [66.999995438000042, 24.961902715000065],
            [67.000066365000066, 24.96183178800004],
            [67.000090008000029, 24.961626888000069],
            [67.000090008000029, 24.961555961000045],
            [67.000223981000033, 24.961461392000047],
            [67.000421000000074, 24.961280134000049],
            [67.000531331000047, 24.961114638000026],
            [67.000594377000084, 24.961004307000053],
            [67.000688947000071, 24.960925499000041],
            [67.000759874000039, 24.960838811000031],
            [67.000885966000055, 24.960712718000025],
            [67.001019939000059, 24.960657553000033],
            [67.001106628000059, 24.960555103000047],
            [67.001232720000075, 24.960444772000073],
            [67.001240601000063, 24.960334441000043],
            [67.001193316000069, 24.960263514000076],
            [67.001185435000082, 24.960168945000078],
            [67.001421859000061, 24.960098018000053],
            [67.001579474000039, 24.960074375000033],
            [67.001626759000033, 24.959948283000074],
            [67.001737090000063, 24.959845833000031],
            [67.001847421000036, 24.959751264000033],
            [67.002028678000045, 24.959672456000078],
            [67.002178413000081, 24.959633052000072],
            [67.002280863000067, 24.959546363000072],
            [67.002288744000055, 24.959404509000024],
            [67.002280863000067, 24.95931782100007],
            [67.002146890000063, 24.959160205000046],
            [67.002091725000071, 24.959081398000023],
            [67.00213112800003, 24.958986828000036],
            [67.002209936000042, 24.958963186000062],
            [67.002406956000073, 24.958742524000058],
            [67.00265126000005, 24.958553386000062],
            [67.002864041000066, 24.958403651000026],
            [67.00312410600003, 24.95823815500006],
            [67.003336887000046, 24.958119943000042],
            [67.003415695000058, 24.957946566000032],
            [67.003423576000046, 24.957781070000067],
            [67.003399933000082, 24.957639216000075],
            [67.003376291000052, 24.957473720000053],
            [67.003376291000052, 24.957347627000047],
            [67.003431456000044, 24.957268820000024],
            [67.003526026000031, 24.957331866000061],
            [67.00373092600006, 24.957465839000065],
            [67.003896422000082, 24.957528885000045],
            [67.004101322000054, 24.957481600000051],
            [67.004219534000072, 24.957434316000047],
            [67.004353507000076, 24.957331866000061],
            [67.004448076000074, 24.957213654000043],
            [67.00448748000008, 24.956977231000053],
            [67.004503242000055, 24.956740808000063],
            [67.004495361000068, 24.956449219000035],
            [67.004463838000049, 24.956236438000076],
            [67.004385030000037, 24.956039419000035],
            [67.004235295000058, 24.955913326000029],
            [67.00407768000008, 24.955842399000062],
            [67.003951587000074, 24.955802995000056],
            [67.00390430300007, 24.955732068000032],
            [67.003920064000056, 24.955605976000072],
            [67.004069799000035, 24.95550352600003],
            [67.004235295000058, 24.955472003000068],
            [67.004392911000082, 24.95550352600003],
            [67.004621453000084, 24.955605976000072],
            [67.004913042000055, 24.955802995000056],
            [67.005133704000059, 24.956031538000047],
            [67.005322842000055, 24.956157630000064],
            [67.005527742000083, 24.956157630000064],
            [67.005685358000051, 24.95611034600006],
            [67.005858735000061, 24.955976372000066],
            [67.00592178100004, 24.955834518000074],
            [67.006047874000046, 24.955519287000072],
            [67.00615820400003, 24.955274983000038],
            [67.006292178000081, 24.95518041400004],
            [67.006662574000075, 24.955085845000042],
            [67.00717482400006, 24.95500703700003],
            [67.007553102000031, 24.954967633000024],
            [67.007750121000072, 24.954904587000044],
            [67.007915617000037, 24.954762733000052],
            [67.007986544000062, 24.954612998000073],
            [67.007994425000049, 24.954392337000058],
            [67.007884094000076, 24.954195317000028],
            [67.007765882000058, 24.953998298000045],
            [67.007679194000048, 24.953872206000028],
            [67.007702836000078, 24.953832802000079],
            [67.00784469000007, 24.953880086000026],
            [67.008088994000047, 24.953982536000069],
            [67.008325417000037, 24.954140152000036],
            [67.008490914000049, 24.954250483000067],
            [67.008695814000077, 24.954282006000028],
            [67.008908595000037, 24.954289887000073],
            [67.009137137000039, 24.954282006000028],
            [67.009318395000037, 24.954203198000073],
            [67.009476011000061, 24.954108629000075],
            [67.009570580000059, 24.953880086000026],
            [67.009712434000051, 24.953651544000024],
            [67.009846407000055, 24.953572736000069],
            [67.010106473000064, 24.953470286000027],
            [67.010350777000042, 24.953352074000065],
            [67.01053203400005, 24.953257505000067],
            [67.010713292000048, 24.953123532000063],
            [67.011075808000044, 24.952855586000055],
            [67.011406800000032, 24.952697970000031],
            [67.011564416000056, 24.952556116000039],
            [67.011588058000029, 24.952382739000029],
            [67.011556535000068, 24.952185720000045],
            [67.01152501200005, 24.952067508000027],
            [67.011792958000058, 24.951980820000074],
            [67.011974216000056, 24.951949296000066],
            [67.012242163000053, 24.951949296000066],
            [67.012565274000053, 24.951925654000036],
            [67.012935671000037, 24.951838966000025],
            [67.013179975000071, 24.951768039000058],
            [67.013763152000081, 24.951665589000072],
            [67.014637918000062, 24.95147645000003],
            [67.015339307000033, 24.951334596000038],
            [67.01572546500006, 24.951303073000076],
            [67.016001292000055, 24.951342477000026],
            [67.016166788000078, 24.951444927000068],
            [67.016395331000069, 24.951547377000054],
            [67.016679039000053, 24.951657708000027],
            [67.016946985000061, 24.951704992000032],
            [67.017285858000037, 24.951720754000064],
            [67.017530162000071, 24.951768039000058],
            [67.017664136000064, 24.951838966000025],
            [67.017900559000054, 24.952020223000034],
            [67.018136982000044, 24.952138435000052],
            [67.01852314000007, 24.95225664700007],
            [67.019161483000062, 24.952374858000042],
            [67.019736779000084, 24.952469428000029],
            [67.020335718000069, 24.952532474000066],
            [67.020658830000059, 24.952516712000033],
            [67.021108034000065, 24.952374858000042],
            [67.021329610000066, 24.952223125000046],
            [67.021715082000071, 24.951994697000032],
            [67.022043448000034, 24.951709161000053],
            [67.022728733000065, 24.951295135000066],
            [67.023385464000057, 24.950938216000054],
            [67.024156409000057, 24.95040997600006],
            [67.024662640000031, 24.950071894000075],
            [67.024849035000045, 24.950615114000072],
            [67.025362041000051, 24.951290781000068],
            [67.026058936000084, 24.952523352000071],
            [67.026867953000078, 24.953665494000063],
            [67.027355743000044, 24.954343640000047],
            [67.028045786000064, 24.955283527000063],
            [67.028497884000046, 24.955735625000045],
            [67.028902392000077, 24.956175825000059],
            [67.032423995000045, 24.959352405000061],
            [67.033720801000072, 24.96069680100004],
            [67.034351358000038, 24.961315461000027],
            [67.034993812000039, 24.962195861000055],
            [67.035731445000067, 24.963338003000047],
            [67.036766511000053, 24.965003625000065],
            [67.038146598000083, 24.967276011000024],
            [67.038967512000056, 24.968549022000047],
            [67.039312534000032, 24.969084401000032],
            [67.039443404000053, 24.969358039000042],
            [67.039443404000053, 24.969584088000033],
            [67.039467199000057, 24.969857726000043],
            [67.039526686000045, 24.969952904000024],
            [67.03964565900003, 24.970048083000052],
            [67.039859810000053, 24.97005998000003],
            [67.04006206400004, 24.969917212000041],
            [67.040359497000054, 24.969465115000048],
            [67.041311281000048, 24.967585340000028],
            [67.041488823000066, 24.967149961000075],
            [67.042049964000057, 24.966011662000028],
            [67.042702144000032, 24.965040331000068],
            [67.043076800000051, 24.964540789000068],
            [67.04328494300006, 24.964249389000031],
            [67.043465333000029, 24.964055123000037],
            [67.043687352000063, 24.963971866000065],
            [67.04369302300006, 24.96378481000005],
            [67.043768972000066, 24.963607596000031],
            [67.043933528000082, 24.963455698000075],
            [67.044124786000054, 24.963322664000032],
            [67.044009477000031, 24.962759498000025],
            [67.044009477000031, 24.962569626000061],
            [67.044123401000036, 24.962481018000062],
            [67.044249982000053, 24.962341778000052],
            [67.044351248000055, 24.962012666000078],
            [67.044300615000054, 24.961759503000053],
            [67.044161375000044, 24.961594946000048],
            [67.043946186000028, 24.961481023000033],
            [67.043794288000072, 24.961316466000028],
            [67.043629732000056, 24.961000012000056],
            [67.043396572000063, 24.960348801000066],
            [67.043170523000072, 24.959849115000054],
            [67.042873091000047, 24.959289941000065],
            [67.042551863000028, 24.958659384000043],
            [67.042266328000039, 24.958219184000029],
            [67.042087868000067, 24.958016930000042],
            [67.041956998000046, 24.957862265000074],
            [67.041826128000082, 24.957648113000062],
            [67.041623874000038, 24.957338783000068],
            [67.04133833800006, 24.956803405000073],
            [67.040981419000047, 24.956303718000072],
            [67.04050552700005, 24.955613674000062],
            [67.04023188900004, 24.955244858000071],
            [67.040017737000028, 24.954828452000072],
            [67.039744099000075, 24.954316868000035],
            [67.039458564000029, 24.953912359000071],
            [67.038911288000065, 24.953150932000028],
            [67.038316423000083, 24.952341915000034],
            [67.037888120000048, 24.951723255000047],
            [67.037174281000034, 24.950830957000051],
            [67.036508032000029, 24.949926762000075],
            [67.035687118000055, 24.948915491000037],
            [67.03529450700006, 24.948439599000039],
            [67.034961383000052, 24.948070783000048],
            [67.034497388000034, 24.947618685000066],
            [67.034223750000081, 24.947333150000077],
            [67.034021496000037, 24.947059512000067],
            [67.033640782000077, 24.946595517000048],
            [67.032772279000028, 24.945548554000027],
            [67.032189311000081, 24.944870408000043],
            [67.031606343000078, 24.944335029000058],
            [67.031059067000058, 24.943787753000038],
            [67.030428510000036, 24.943145298000047],
            [67.029809850000049, 24.942574228000069],
            [67.029298266000069, 24.942026952000049],
            [67.028834271000051, 24.941574854000066],
            [67.028203714000028, 24.941003783000042],
            [67.027561259000038, 24.940432713000064],
            [67.02684742100007, 24.939766464000058],
            [67.025776663000045, 24.938814679000075],
            [67.024410132000071, 24.93747546000003],
            [67.024268040000038, 24.937301540000078],
            [67.024048255000082, 24.937165483000058],
            [67.023723811000082, 24.937081755000065],
            [67.023441231000049, 24.936914300000069],
            [67.023116787000049, 24.936694516000045],
            [67.022802809000041, 24.936443333000057],
            [67.022698150000053, 24.936338674000069],
            [67.022603956000069, 24.936171219000073],
            [67.022352774000069, 24.935920037000074],
            [67.022164387000032, 24.935752582000077],
            [67.022017864000077, 24.935626991000049],
            [67.021839943000032, 24.935438604000069],
            [67.021546897000064, 24.935250217000032],
            [67.021211987000072, 24.935082762000036],
            [67.02081428200006, 24.934862978000069],
            [67.020468906000076, 24.934674591000032],
            [67.020123530000035, 24.934465272000068],
            [67.019694427000047, 24.934172226000044],
            [67.01931765300003, 24.933962907000023],
            [67.018888550000042, 24.933669861000055],
            [67.018543174000058, 24.933429145000048],
            [67.018260594000083, 24.933188428000051],
            [67.017998946000034, 24.932895382000027],
            [67.017747763000045, 24.932591870000067],
            [67.017538445000071, 24.932445347000055],
            [67.017245399000046, 24.932445347000055],
            [67.016973284000073, 24.932508143000064],
            [67.016722102000074, 24.932529075000048],
            [67.016439522000042, 24.932550006000042],
            [67.016209271000037, 24.932550006000042],
            [67.016052282000032, 24.932518609000056],
            [67.015832498000066, 24.932424415000071],
            [67.015539452000041, 24.932288358000051],
            [67.015403395000078, 24.932225563000031],
            [67.015424326000073, 24.932047642000043],
            [67.015508054000065, 24.93177552700007],
            [67.015612713000053, 24.931482481000046],
            [67.015625253000053, 24.931474957000034],
            [67.01565800000003, 24.931394000000068],
            [67.01567650100003, 24.931355677000056],
            [67.015700000000038, 24.931307000000061],
            [67.015745231000039, 24.93123038400006],
            [67.015854753000042, 24.93131995300007],
            [67.015965749000031, 24.931413801000076],
            [67.016034663000028, 24.931452054000033],
            [67.016092111000034, 24.93145903900006],
            [67.016161078000039, 24.931431298000064],
            [67.016230053000072, 24.931393137000043],
            [67.016252138000084, 24.931382285000041],
            [67.016324474000044, 24.931346739000048],
            [67.016364164000038, 24.931327236000072],
            [67.016429237000068, 24.931284242000061],
            [67.016505953000035, 24.931233557000041],
            [67.016666891000057, 24.93113642000003],
            [67.016783872000076, 24.93107931000003],
            [67.016865137000082, 24.931060942000045],
            [67.016937106000057, 24.931044675000066],
            [67.017067327000063, 24.931062116000078],
            [67.017271023000035, 24.931093591000035],
            [67.017292661000056, 24.931096934000038],
            [67.017553145000079, 24.931076264000069],
            [67.017561317000059, 24.931074046000049],
            [67.017821341000058, 24.93100349100007],
            [67.017986134000068, 24.930913281000073],
            [67.018030071000055, 24.930870653000056],
            [67.018039802000033, 24.930861212000025],
            [67.018093499000031, 24.930777879000061],
            [67.018126678000044, 24.93069397000005],
            [67.018164918000082, 24.930597264000028],
            [67.018223955000053, 24.930495575000066],
            [67.018245935000039, 24.930457715000045],
            [67.018268038000031, 24.93041964400004],
            [67.018279781000047, 24.930399418000036],
            [67.018293376000031, 24.93037600100007],
            [67.018378214000052, 24.930245641000056],
            [67.018491023000081, 24.930072302000042],
            [67.018521528000065, 24.930025429000068],
            [67.018695790000038, 24.929843814000037],
            [67.018829449000066, 24.929704515000026],
            [67.01890376700004, 24.929604156000039],
            [67.019112183000061, 24.929322712000044],
            [67.019120443000077, 24.929311557000062],
            [67.019351860000029, 24.929076563000024],
            [67.019422421000058, 24.92900491000006],
            [67.019678204000058, 24.928787333000059],
            [67.019797554000036, 24.928685810000047],
            [67.019799882000029, 24.928683955000054],
            [67.020160385000054, 24.928396649000035],
            [67.020309911000084, 24.928243889000044],
            [67.02033808300007, 24.928201986000033],
            [67.02035439000008, 24.928177730000073],
            [67.020363606000046, 24.928164022000033],
            [67.020438033000062, 24.927908584000079],
            [67.020458441000073, 24.927829871000029],
            [67.020526405000055, 24.927567848000024],
            [67.020530637000036, 24.927505251000071],
            [67.020534155000064, 24.927453211000056],
            [67.020515079000063, 24.927352453000026],
            [67.020453855000028, 24.927262088000077],
            [67.02035429700004, 24.92720296300007],
            [67.020208756000045, 24.927154226000027],
            [67.020068618000039, 24.927123551000022],
            [67.019884786000034, 24.927047000000073],
            [67.019884398000045, 24.92704672900004],
            [67.019785235000029, 24.926977454000053],
            [67.019724015000065, 24.926883616000055],
            [67.019685812000034, 24.926751582000065],
            [67.019656767000072, 24.926642118000075],
            [67.019632341000033, 24.926550064000025],
            [67.01956741500004, 24.926296424000043],
            [67.019566389000033, 24.926293888000032],
            [67.019445713000039, 24.925995320000027],
            [67.019424419000075, 24.925942636000059],
            [67.019377319000057, 24.925875870000027],
            [67.019351823000079, 24.925839729000074],
            [67.019323410000084, 24.925822525000058],
            [67.019213984000032, 24.925756266000064],
            [67.019184956000061, 24.925743081000064],
            [67.019045493000078, 24.925679732000049],
            [67.018826975000081, 24.925564142000042],
            [67.018743795000034, 24.925470365000024],
            [67.018739004000054, 24.925464963000024],
            [67.01871598300005, 24.925439009000058],
            [67.018698275000077, 24.925401233000059],
            [67.01862802200003, 24.925251358000025],
            [67.018609001000073, 24.925081116000058],
            [67.018609151000078, 24.925079538000034],
            [67.018620588000033, 24.924959528000045],
            [67.018666783000072, 24.924822655000071],
            [67.01869049700008, 24.924736301000053],
            [67.018712767000068, 24.924655206000068],
            [67.018715283000063, 24.924568983000029],
            [67.018719839000028, 24.924412869000037],
            [67.018720233000067, 24.924399369000071],
            [67.018724578000047, 24.92425048900003],
            [67.018727013000046, 24.924181402000045],
            [67.01872845500003, 24.924140484000077],
            [67.018728459000044, 24.924140372000068],
            [67.018737381000051, 24.923887293000064],
            [67.018740534000074, 24.923797842000056],
            [67.018741779000038, 24.923762529000044],
            [67.018740667000031, 24.923684921000074],
            [67.018735550000031, 24.923327660000041],
            [67.01873545400008, 24.923320946000047],
            [67.018734511000048, 24.923255122000057],
            [67.018696323000029, 24.923102233000066],
            [67.018627425000034, 24.923029229000065],
            [67.018572704000064, 24.922998632000031],
            [67.018447664000064, 24.922928717000048],
            [67.01826387400007, 24.92280699500003],
            [67.018152834000034, 24.922730485000045],
            [67.018083975000081, 24.922601883000027],
            [67.018032730000073, 24.922456982000028],
            [67.017964984000059, 24.922265425000035],
            [67.01793434700005, 24.922090102000027],
            [67.017907777000062, 24.92193805200003],
            [67.017899889000034, 24.92181621900005],
            [67.017888854000034, 24.921645758000068],
            [67.017889222000065, 24.921608558000059],
            [67.017893018000052, 24.921224401000075],
            [67.017893882000067, 24.921215650000079],
            [67.017898754000043, 24.921166311000036],
            [67.017942513000037, 24.920723102000068],
            [67.01795985800004, 24.920547428000077],
            [67.017962533000059, 24.920520329000055],
            [67.017989338000064, 24.920066882000071],
            [67.01799569800005, 24.919959295000069],
            [67.018001368000057, 24.919863376000023],
            [67.017999169000063, 24.919775091000076],
            [67.017993482000065, 24.919546697000044],
            [67.017991233000032, 24.919456401000048],
            [67.018003086000078, 24.919345752000027],
            [67.018024585000035, 24.919145043000071],
            [67.018030268000075, 24.919091982000054],
            [67.018030586000066, 24.919089020000058],
            [67.018103620000034, 24.918787408000071],
            [67.018113054000082, 24.918728240000064],
            [67.018134008000061, 24.918596816000047],
            [67.018169644000068, 24.918373302000077],
            [67.018170797000039, 24.918360254000049],
            [67.018188415000054, 24.918160898000053],
            [67.018197800000053, 24.918054688000041],
            [67.018216142000028, 24.917847152000036],
            [67.018249860000083, 24.917610767000042],
            [67.018267864000052, 24.917484547000072],
            [67.018296833000079, 24.917281450000075],
            [67.018302458000051, 24.917242017000035],
            [67.018316436000077, 24.917144019000034],
            [67.018325589000028, 24.917053992000035],
            [67.018343550000054, 24.916877329000044],
            [67.018372395000029, 24.916593598000077],
            [67.01838980000008, 24.916422408000074],
            [67.018390638000028, 24.916392399000074],
            [67.01839323400003, 24.916299525000056],
            [67.018395588000033, 24.91621529300005],
            [67.018399379000073, 24.916079663000062],
            [67.018399657000032, 24.916069711000034],
            [67.018403141000078, 24.915945058000034],
            [67.018405569000038, 24.915858164000042],
            [67.018412149000028, 24.915810308000061],
            [67.018424494000044, 24.915720520000036],
            [67.018437358000028, 24.915626961000044],
            [67.018441855000049, 24.915594253000052],
            [67.018452919000083, 24.915513786000076],
            [67.018472085000042, 24.915374391000057],
            [67.018474582000067, 24.915356226000029],
            [67.018485962000057, 24.915273461000027],
            [67.01849033700006, 24.915241640000033],
            [67.018490434000057, 24.915119425000057],
            [67.018490501000031, 24.915034485000035],
            [67.018490506000035, 24.91502734200003],
            [67.01849055100007, 24.914970953000022],
            [67.018490579000058, 24.914934903000074],
            [67.018490587000031, 24.914924554000038],
            [67.018490627000062, 24.914873514000078],
            [67.018490668000084, 24.914821761000042],
            [67.018490675000066, 24.914813208000055],
            [67.018490713000062, 24.914764886000057],
            [67.018484756000078, 24.91471314000006],
            [67.018483414000059, 24.91470147900003],
            [67.018474840000067, 24.914627002000032],
            [67.018469241000048, 24.914578372000051],
            [67.01846902300008, 24.914576476000036],
            [67.018458678000059, 24.914486614000054],
            [67.018448856000077, 24.914401296000051],
            [67.018445544000031, 24.914348367000059],
            [67.018443785000045, 24.914320260000068],
            [67.018429938000054, 24.914098983000031],
            [67.018430198000033, 24.913768309000034],
            [67.018441885000072, 24.913524690000031],
            [67.018478009000034, 24.913321778000068],
            [67.018535793000069, 24.912997204000078],
            [67.018619141000045, 24.912608138000053],
            [67.018628082000077, 24.91256640000006],
            [67.018635926000059, 24.912337080000043],
            [67.018593922000036, 24.912163323000073],
            [67.018565271000057, 24.912105523000037],
            [67.018487927000081, 24.911949485000036],
            [67.018353982000065, 24.911738736000075],
            [67.018241231000047, 24.91154565100004],
            [67.018141832000083, 24.911281258000031],
            [67.018080922000081, 24.911033108000026],
            [67.018062591000046, 24.910958424000057],
            [67.018051211000056, 24.910896210000033],
            [67.017997749000074, 24.910603928000057],
            [67.017979936000074, 24.910501662000058],
            [67.017952158000071, 24.910342188000072],
            [67.017948171000057, 24.910319299000037],
            [67.017951416000074, 24.910082827000053],
            [67.017952199000035, 24.910025736000023],
            [67.017979349000029, 24.909884790000035],
            [67.018002250000052, 24.90976590300005],
            [67.018096710000066, 24.909524730000044],
            [67.018109742000036, 24.909491456000069],
            [67.018137594000052, 24.909440844000073],
            [67.018199879000065, 24.909327657000063],
            [67.018349446000059, 24.909133165000071],
            [67.018457065000064, 24.908923110000046],
            [67.018570150000073, 24.908623526000042],
            [67.01861995400003, 24.908479070000055],
            [67.018700701000057, 24.908244865000029],
            [67.018844337000075, 24.90795664500007],
            [67.01902418800006, 24.907665196000039],
            [67.01911622700004, 24.907547111000042],
            [67.019220769000071, 24.907452625000076],
            [67.019524834000038, 24.907219145000056],
            [67.019573441000034, 24.907181822000041],
            [67.019665205000081, 24.90710961700006],
            [67.019904688000054, 24.906921179000051],
            [67.020157785000038, 24.906605125000056],
            [67.02024979600003, 24.906525258000045],
            [67.020429723000063, 24.906453690000035],
            [67.020530810000082, 24.906390586000043],
            [67.020679212000061, 24.906297945000063],
            [67.020833027000037, 24.906201926000051],
            [67.020906800000034, 24.906155873000046],
            [67.021245472000032, 24.905926384000054],
            [67.021320223000032, 24.905875732000027],
            [67.021410391000074, 24.905814633000034],
            [67.02165188500004, 24.905609747000028],
            [67.021775279000053, 24.905409112000029],
            [67.021778289000054, 24.90540137000005],
            [67.021813724000083, 24.905310228000076],
            [67.021835897000074, 24.905253196000046],
            [67.021848221000027, 24.905221495000035],
            [67.021867477000058, 24.905092483000033],
            [67.021864188000052, 24.90502790000005],
            [67.021863420000045, 24.905012810000073],
            [67.02185656100005, 24.904878111000073],
            [67.021856151000065, 24.904870062000043],
            [67.021853717000056, 24.904841350000027],
            [67.021847968000031, 24.904773548000037],
            [67.02183063800004, 24.904569140000035],
            [67.021829569000033, 24.90455653500004],
            [67.021829604000061, 24.904510164000044],
            [67.021829733000061, 24.90434074500007],
            [67.021829785000079, 24.904271567000023],
            [67.021820813000033, 24.904146547000039],
            [67.021814058000075, 24.904052433000061],
            [67.02181082900006, 24.904007438000065],
            [67.021776472000056, 24.903840605000028],
            [67.021724497000037, 24.903704919000063],
            [67.021707027000048, 24.903659314000038],
            [67.021695099000056, 24.903628173000072],
            [67.021605923000038, 24.903455310000027],
            [67.02158032300008, 24.903405685000052],
            [67.021493701000054, 24.90313423200007],
            [67.021489953000071, 24.903114870000024],
            [67.021451610000042, 24.902916808000043],
            [67.021437670000068, 24.902844798000046],
            [67.021422323000081, 24.902765522000038],
            [67.021383023000055, 24.902562513000078],
            [67.021376948000068, 24.902510514000028],
            [67.02135656300004, 24.90233601500006],
            [67.021352572000069, 24.902301855000076],
            [67.021353538000028, 24.902258513000049],
            [67.021356548000028, 24.90212355400007],
            [67.02135723300006, 24.90211619300004],
            [67.021387246000074, 24.901793498000075],
            [67.021395174000077, 24.901708259000031],
            [67.021404533000066, 24.901552222000078],
            [67.021420748000082, 24.901281914000037],
            [67.021422342000051, 24.901255341000024],
            [67.021455755000034, 24.900716715000044],
            [67.02146582000006, 24.900554474000046],
            [67.021472766000045, 24.900442509000072],
            [67.021465320000061, 24.900157540000066],
            [67.021444437000071, 24.899965518000045],
            [67.021409537000068, 24.899644616000046],
            [67.021406643000034, 24.899592735000056],
            [67.021401685000058, 24.899503866000032],
            [67.021374618000038, 24.899018690000048],
            [67.02135284700006, 24.898628441000028],
            [67.021335189000069, 24.898405979000074],
            [67.021299719000069, 24.897959129000071],
            [67.021265769000081, 24.897774240000047],
            [67.021231578000084, 24.897588039000027],
            [67.021220536000044, 24.897527904000071],
            [67.021142624000049, 24.897246598000038],
            [67.021121194000045, 24.897169225000027],
            [67.02105227900006, 24.896969379000041],
            [67.020996148000052, 24.896806605000052],
            [67.020926854000038, 24.896605660000034],
            [67.020907209000029, 24.896499403000064],
            [67.020900814000072, 24.896464814000069],
            [67.020889903000068, 24.89640580400004],
            [67.020867761000034, 24.896286043000032],
            [67.020865804000039, 24.896275455000023],
            [67.020864767000035, 24.896265569000036],
            [67.020831571000031, 24.895949073000054],
            [67.020839341000055, 24.895809850000035],
            [67.02088542000007, 24.895677809000063],
            [67.021023514000035, 24.895464968000056],
            [67.02120377600005, 24.895225277000065],
            [67.021409998000081, 24.894979601000045],
            [67.021608171000082, 24.894801915000073],
            [67.021678560000055, 24.894738803000052],
            [67.021845147000079, 24.894507427000065],
            [67.021882464000043, 24.894455597000047],
            [67.021914233000075, 24.894411473000048],
            [67.022306853000032, 24.893840769000064],
            [67.022347009000043, 24.893782400000077],
            [67.022565234000069, 24.893499054000074],
            [67.022808147000035, 24.89318365400004],
            [67.023069586000076, 24.892900349000058],
            [67.023218434000057, 24.892739051000035],
            [67.023388068000031, 24.892592479000029],
            [67.02360791600006, 24.892402520000076],
            [67.023743712000055, 24.892263029000048],
            [67.024139837000064, 24.891856128000029],
            [67.024339136000037, 24.891689474000032],
            [67.024438881000037, 24.891606066000065],
            [67.024518571000044, 24.891561158000059],
            [67.024807220000071, 24.891398493000054],
            [67.024921787000039, 24.891349857000023],
            [67.025515377000033, 24.89109785900007],
            [67.025944687000049, 24.890865247000079],
            [67.026052089000075, 24.890738105000025],
            [67.02606873600007, 24.890718399000036],
            [67.026075960000071, 24.890678429000047],
            [67.026076445000058, 24.890675745000067],
            [67.026099513000077, 24.89054810600004],
            [67.026099747000046, 24.890225649000058],
            [67.026109322000082, 24.890100782000047],
            [67.02612653500006, 24.889876325000046],
            [67.026135485000054, 24.889759620000063],
            [67.026146165000057, 24.889620348000051],
            [67.026202982000029, 24.889355697000042],
            [67.026215370000045, 24.88929799400006],
            [67.026231193000058, 24.889255857000023],
            [67.026366625000037, 24.888895175000073],
            [67.026471614000059, 24.88874478300005],
            [67.026488727000071, 24.888720268000043],
            [67.026558508000051, 24.888679173000071],
            [67.026692595000043, 24.888600206000035],
            [67.026377054000079, 24.88830897400004],
            [67.025974311000084, 24.887919647000047],
            [67.025841223000043, 24.887790992000077],
            [67.025626655000053, 24.88758357200004],
            [67.025462853000079, 24.887412822000044],
            [67.025140476000047, 24.887076769000032],
            [67.024236659000053, 24.886061873000074],
            [67.023664163000035, 24.885419016000071],
            [67.023661770000047, 24.885416450000037],
            [67.023212202000082, 24.884950080000067],
            [67.02257884200003, 24.884293049000064],
            [67.02257129700007, 24.884285910000074],
            [67.022554769000067, 24.884271639000076],
            [67.022156966000068, 24.883928152000067],
            [67.022151663000045, 24.883923839000033],
            [67.022145264000073, 24.88391927300006],
            [67.022138538000036, 24.883915115000036],
            [67.021926078000035, 24.883793264000076],
            [67.021738507000066, 24.883685687000025],
            [67.021680895000031, 24.883652645000041],
            [67.021675075000076, 24.883649307000042],
            [67.021572584000069, 24.883578205000049],
            [67.021560214000033, 24.883569624000074],
            [67.021559524000054, 24.883569145000024],
            [67.021474718000036, 24.883510312000055],
            [67.021193577000076, 24.88331527400004],
            [67.021065279000084, 24.883226269000033],
            [67.021062055000073, 24.883224110000072],
            [67.020891702000029, 24.883113986000069],
            [67.020629393000036, 24.882944418000022],
            [67.020626352000079, 24.882942452000066],
            [67.02046008700006, 24.882796552000059],
            [67.020328667000058, 24.88268122900007],
            [67.02011391800005, 24.882492782000043],
            [67.020107923000069, 24.882487868000055],
            [67.020099650000077, 24.882482080000045],
            [67.01966995500004, 24.882207936000043],
            [67.019389127000068, 24.881979778000073],
            [67.019243365000079, 24.881861354000023],
            [67.018803265000031, 24.88145365500003],
            [67.018784364000055, 24.881436146000055],
            [67.018777544000045, 24.881430306000027],
            [67.018400462000045, 24.881133095000052],
            [67.018401167000036, 24.881132609000076],
            [67.018341045000057, 24.881085055000028],
            [67.018311143000062, 24.881061405000025],
            [67.018091759000072, 24.880887882000025],
            [67.018086448000076, 24.880883979000032],
            [67.017649548000065, 24.880581754000048],
            [67.017411905000074, 24.880417364000039],
            [67.017408150000051, 24.880414767000048],
            [67.017093566000028, 24.880176649000077],
            [67.016911535000077, 24.880038864000028],
            [67.016899145000082, 24.880030656000031],
            [67.01663205300008, 24.879876675000048],
            [67.016474621000043, 24.87978591500007],
            [67.016470556000058, 24.879783673000077],
            [67.016461365000055, 24.879779308000025],
            [67.015995345000078, 24.879581089000055],
            [67.015755550000051, 24.879479093000043],
            [67.015223030000072, 24.879267648000052],
            [67.015216256000031, 24.879265181000051],
            [67.015207493000048, 24.879262618000041],
            [67.01485346700008, 24.879172695000022],
            [67.014427888000057, 24.879064597000024],
            [67.014415949000067, 24.879062164000061],
            [67.014006599000083, 24.87900094400004],
            [67.01385484900004, 24.878978249000056],
            [67.013840041000037, 24.87897689600004],
            [67.013509744000032, 24.878965715000049],
            [67.013120610000044, 24.878952542000036],
            [67.013116296000078, 24.878952467000033],
            [67.013016788000073, 24.878952398000024],
            [67.012492099000042, 24.878952032000029],
            [67.012455923000061, 24.878952007000066],
            [67.012248579000072, 24.87893807100005],
            [67.01180314800007, 24.878908132000049],
            [67.011794421000047, 24.878907840000068],
            [67.011787285000082, 24.878908035000052],
            [67.011288403000037, 24.878935432000048],
            [67.011280294000073, 24.878936133000025],
            [67.01085306300007, 24.878986582000039],
            [67.010847686000034, 24.878987331000076],
            [67.010840677000033, 24.878988659000072],
            [67.010621858000036, 24.879036411000072],
            [67.009964420000074, 24.879179884000052],
            [67.009960271000068, 24.879180863000045],
            [67.009919994000029, 24.879191067000079],
            [67.009444681000048, 24.879311488000042],
            [67.009435843000063, 24.879314071000067],
            [67.00942241000007, 24.879319409000061],
            [67.009060380000051, 24.879488218000063],
            [67.009055053000054, 24.879490861000079],
            [67.009045326000034, 24.879496577000054],
            [67.008620693000069, 24.879773742000054],
            [67.00803075400006, 24.880142398000032],
            [67.007789001000049, 24.880266152000047],
            [67.007708964000074, 24.880307124000069],
            [67.007177917000035, 24.880578969000055],
            [67.006862328000068, 24.880726978000041],
            [67.006137959000057, 24.881066702000055],
            [67.00547741500003, 24.881320208000034],
            [67.004699435000077, 24.881527730000073],
            [67.004385938000041, 24.881588515000033],
            [67.004205765000052, 24.881599265000034],
            [67.003973127000052, 24.881583748000025],
            [67.00372651400005, 24.881567136000058],
            [67.003320723000058, 24.881539803000067],
            [67.00314793900003, 24.881518287000063],
            [67.002971000000059, 24.881448577000072],
            [67.002963479000073, 24.881445892000045],
            [67.002575028000081, 24.881324390000032],
            [67.002415183000039, 24.881274393000069],
            [67.002406324000049, 24.881271970000057],
            [67.002396197000053, 24.881270017000077],
            [67.001827602000048, 24.88118847100003],
            [67.001812639000036, 24.881187203000025],
            [67.000970089000077, 24.88116468000004],
            [66.999582608000082, 24.881130750000068],
            [66.999248320000049, 24.881108185000073],
            [66.998779239000044, 24.881076520000079],
            [66.997589278000078, 24.880996195000023],
            [66.996749531000034, 24.880884798000068],
            [66.996697210000036, 24.880872688000068],
            [66.995646254000064, 24.880629440000064],
            [66.995221197000035, 24.880538415000046],
            [66.995044022000059, 24.88050047400003],
            [66.99497857700004, 24.880486459000053],
            [66.994932707000032, 24.880476636000026],
            [66.994877795000036, 24.880464877000065],
            [66.994796463000057, 24.880440598000064],
            [66.994384196000055, 24.880317527000045],
            [66.994139962000077, 24.880225697000071],
            [66.993800241000031, 24.880097963000026],
            [66.99320457500005, 24.879821599000024],
            [66.99298182800004, 24.879718291000074],
            [66.992121246000067, 24.879319162000058],
            [66.992118105000031, 24.87931775800007],
            [66.988351755000053, 24.877696367000055],
            [66.984773932000053, 24.876113488000044],
            [66.982752373000039, 24.875218800000027],
            [66.981292718000077, 24.874572796000052],
            [66.979191480000054, 24.87367970400004],
            [66.978195411000058, 24.873226031000058],
            [66.977542191000055, 24.872856806000073],
            [66.976901146000046, 24.872451241000078],
            [66.976237758000082, 24.871913831000029],
            [66.976014587000066, 24.871694880000064],
            [66.975840386000073, 24.871470506000037],
            [66.975750706000042, 24.871354996000036],
            [66.975546265000048, 24.871091672000034],
            [66.976316931000042, 24.870609223000031],
            [66.976364083000078, 24.870579705000068],
            [66.976825719000033, 24.870304751000049],
            [66.977197887000045, 24.870164546000069],
            [66.977765786000077, 24.869950604000053],
            [66.978066000000069, 24.869742000000031],
            [66.978367569000056, 24.86955787100004],
            [66.978785000000073, 24.869303000000059],
            [66.979286000000059, 24.868996000000038],
            [66.979503000000079, 24.868863000000033],
            [66.980989000000079, 24.867920000000026],
            [66.981834000000049, 24.867394000000047],
            [66.981869000000074, 24.867372000000046],
            [66.982047636000061, 24.867260286000032],
            [66.982870000000048, 24.866746000000035],
            [66.983609066000042, 24.866265836000025],
            [66.984166000000073, 24.865904000000057],
            [66.984284000000059, 24.865820000000042],
            [66.984469000000047, 24.865690000000029],
            [66.984650000000045, 24.865553000000034],
            [66.984827000000053, 24.865412000000049],
            [66.984999000000073, 24.865265000000079],
            [66.985167000000047, 24.865114000000062],
            [66.985331000000031, 24.864957000000061],
            [66.985423000000083, 24.864865000000066],
            [66.985490000000084, 24.864796000000069],
            [66.985645000000034, 24.864630000000034],
            [66.985755395000069, 24.864499049000074],
            [66.985790000000065, 24.86445800000007],
            [66.985929000000056, 24.864281000000062],
            [66.986060000000066, 24.86409800000007],
            [66.98618300000004, 24.863909000000035],
            [66.986297000000036, 24.86371500000007],
            [66.98640400000005, 24.863517000000058],
            [66.986426086000051, 24.863471250000032],
            [66.98650200000003, 24.863314000000059],
            [66.986591000000033, 24.86310700000007],
            [66.986819000000082, 24.862481000000059],
            [66.986989000000051, 24.861821000000077],
            [66.987068000000079, 24.861140000000034],
            [66.98709000000008, 24.860324000000048],
            [66.98709400000007, 24.859665000000064],
            [66.987085777000061, 24.859446124000044],
            [66.987052000000062, 24.858547000000044],
            [66.98704513000007, 24.858362419000059],
            [66.987014000000045, 24.857526000000064],
            [66.987017000000037, 24.857375000000047],
            [66.987017304000062, 24.857370404000051],
            [66.987027000000069, 24.857224000000031],
            [66.98704500000008, 24.857074000000068],
            [66.987069000000076, 24.856925000000047],
            [66.987100000000055, 24.856778000000077],
            [66.987138000000073, 24.856632000000047],
            [66.987183000000073, 24.85648800000007],
            [66.987235000000055, 24.85634600000003],
            [66.987337859000036, 24.856085914000062],
            [66.987420599000075, 24.855876700000067],
            [66.987445000000037, 24.855815000000064],
            [66.987741000000028, 24.855155000000025],
            [66.987823251000066, 24.85495958000007],
            [66.987832834000073, 24.854787475000023],
            [66.987853861000076, 24.85470876100004],
            [66.987873302000082, 24.854613581000024],
            [66.98790778700004, 24.854479095000045],
            [66.987892514000066, 24.854410224000048],
            [66.987892428000066, 24.854409837000048],
            [66.987860734000037, 24.854342038000027],
            [66.987787531000038, 24.85424953100005],
            [66.98809474400008, 24.854210917000046],
            [66.988117333000048, 24.854107470000031],
            [66.988123893000079, 24.854077429000029],
            [66.988135439000075, 24.853986098000064],
            [66.988124919000029, 24.853760915000066],
            [66.988094799000066, 24.853607066000052],
            [66.988035976000049, 24.853306602000032],
            [66.988004000000046, 24.853164000000049],
            [66.987928000000068, 24.852796000000069],
            [66.987851000000035, 24.852428000000032],
            [66.987839000000065, 24.852303000000063],
            [66.987838000000067, 24.852193000000057],
            [66.987848000000042, 24.852053000000069],
            [66.987869000000046, 24.851930000000038],
            [66.987900000000081, 24.85180900000006],
            [66.987942000000032, 24.851691000000073],
            [66.987995000000069, 24.851577000000077],
            [66.988057000000083, 24.851469000000066],
            [66.988344000000041, 24.851024000000052],
            [66.988709000000028, 24.85045800000006],
            [66.989092000000028, 24.84986200000003],
            [66.989155999000047, 24.849761883000042],
            [66.989193000000057, 24.849704000000031],
            [66.989193131000036, 24.849703797000075],
            [66.989624000000049, 24.849035000000072],
            [66.989627081000037, 24.849029869000049],
            [66.989812824000069, 24.848720539000055],
            [66.989881000000082, 24.848607000000072],
            [66.989985000000047, 24.848529000000042],
            [66.990313000000071, 24.848035000000039],
            [66.990631000000064, 24.847557000000052],
            [66.991114699000036, 24.846823456000038],
            [66.991115000000036, 24.846823000000029],
            [66.991241000000059, 24.846639000000039],
            [66.99136800000008, 24.846455000000049],
            [66.99146200000007, 24.846340000000055],
            [66.991514000000052, 24.846273000000053],
            [66.991566000000034, 24.846207000000049],
            [66.991628000000048, 24.846135000000061],
            [66.991677000000038, 24.846079000000032],
            [66.99173600000006, 24.846018000000072],
            [66.991795000000081, 24.845958000000053],
            [66.991857000000039, 24.845900000000029],
            [66.991919000000053, 24.845844000000056],
            [66.991984000000059, 24.845789000000025],
            [66.992050000000063, 24.845736000000045],
            [66.992117000000064, 24.84568500000006],
            [66.992126447000032, 24.84567815500003],
            [66.991401517000043, 24.845296207000047],
            [66.991452000000038, 24.845180000000028],
            [66.991505000000075, 24.84508900000003],
            [66.991545000000031, 24.84499100000005],
            [66.991572000000076, 24.844889000000023],
            [66.991584000000046, 24.844785000000059],
            [66.991582000000051, 24.844679000000042],
            [66.991572699000074, 24.844614514000057],
            [66.991610170000058, 24.844477121000068],
            [66.992865541000072, 24.843886358000077],
            [66.99478552000005, 24.844255585000042],
            [66.996262427000033, 24.844329430000073],
            [66.998920859000066, 24.844329430000073],
            [67.001431601000036, 24.843886358000077],
            [67.004163878000043, 24.843517131000056],
            [67.006896156000039, 24.842926368000064],
            [67.009333052000045, 24.841449462000071],
            [67.01095765000008, 24.839972555000031],
            [67.012729938000064, 24.83938179200004],
            [67.013985309000077, 24.838052576000052],
            [67.015757597000061, 24.837535659000025],
            [67.016422205000083, 24.838569493000023],
            [67.017603731000065, 24.839898709000067],
            [67.020409854000036, 24.840267936000032],
            [67.021539585000028, 24.84016181100003],
            [67.021551294000062, 24.839932426000075],
            [67.021573500000045, 24.839522732000034],
            [67.021574532000045, 24.839503696000065],
            [67.021586384000045, 24.839285035000046],
            [67.021590348000075, 24.839211912000053],
            [67.021584722000057, 24.839051903000041],
            [67.021548319000033, 24.838947933000043],
            [67.021461522000038, 24.838744758000075],
            [67.021426415000064, 24.838665952000042],
            [67.021343880000074, 24.838480686000025],
            [67.021206688000063, 24.838175924000041],
            [67.021133894000059, 24.838021003000051],
            [67.021036913000046, 24.837498224000058],
            [67.021120815000074, 24.837498289000052],
            [67.021219417000054, 24.837476973000037],
            [67.021238757000049, 24.83742447700007],
            [67.021223816000031, 24.837364174000072],
            [67.021236735000059, 24.837303894000058],
            [67.02115325300008, 24.837206579000053],
            [67.021091178000063, 24.837128727000049],
            [67.02108052400007, 24.837066475000029],
            [67.021037754000076, 24.83696918000004],
            [67.020956415000057, 24.836858232000054],
            [67.020957706000047, 24.836725858000023],
            [67.02097451700007, 24.83667213800004],
            [67.020994103000078, 24.836609549000059],
            [67.021008722000033, 24.836504201000025],
            [67.021001605000038, 24.836365917000023],
            [67.020992023000076, 24.836273726000059],
            [67.020972731000029, 24.836216645000036],
            [67.020988285000044, 24.836136354000075],
            [67.020979210000064, 24.835982844000057],
            [67.020923905000075, 24.835929770000064],
            [67.020948621000059, 24.835820942000055],
            [67.020914968000056, 24.835650670000064],
            [67.020902819000071, 24.835502746000031],
            [67.020939769000051, 24.83545812400007],
            [67.020930630000066, 24.835374395000031],
            [67.020866130000059, 24.835296201000062],
            [67.020787754000082, 24.835139081000023],
            [67.020784500000048, 24.835130196000023],
            [67.020732405000047, 24.835026528000071],
            [67.020765075000043, 24.834979181000051],
            [67.020768402000044, 24.834914047000041],
            [67.020719525000061, 24.834854794000023],
            [67.020778265000047, 24.834837077000032],
            [67.020748977000039, 24.834763036000027],
            [67.020768645000032, 24.83466831000004],
            [67.02072961600004, 24.834546893000038],
            [67.02066443700005, 24.834478745000069],
            [67.020586241000046, 24.834375059000024],
            [67.020567937000067, 24.834285519000048],
            [67.020555266000031, 24.834223119000058],
            [67.020590947000073, 24.83419079600003],
            [67.020578283000077, 24.834121462000041],
            [67.020573225000078, 24.834086796000065],
            [67.020568146000073, 24.834072927000022],
            [67.020532499000069, 24.834070588000031],
            [67.020481611000037, 24.834031263000043],
            [67.020465797000043, 24.833969646000071],
            [67.020551180000041, 24.833810196000059],
            [67.020570667000072, 24.833757298000023],
            [67.020593906000045, 24.833694215000037],
            [67.020604788000071, 24.833462193000059],
            [67.020626322000055, 24.833230180000044],
            [67.02061051700008, 24.833051313000055],
            [67.020549406000043, 24.833012116000077],
            [67.020481910000058, 24.832957325000052],
            [67.02045307800006, 24.832836882000038],
            [67.020431457000029, 24.832744908000052],
            [67.020405019000066, 24.832644172000073],
            [67.020467850000045, 24.832536940000068],
            [67.020573389000049, 24.832504259000075],
            [67.020700587000078, 24.832494178000047],
            [67.020708133000028, 24.832467266000037],
            [67.020768158000067, 24.832253198000046],
            [67.020768455000052, 24.831951035000031],
            [67.020750004000035, 24.83169298100006],
            [67.020686126000044, 24.831379144000039],
            [67.020596689000058, 24.831313068000043],
            [67.020532861000049, 24.831207919000065],
            [67.020300702000043, 24.830825467000068],
            [67.020094111000049, 24.830444552000074],
            [67.020049440000037, 24.830363291000026],
            [67.019991574000073, 24.830348233000052],
            [67.019991574000073, 24.829574310000055],
            [67.019945119000056, 24.82883103000006],
            [67.019888956000045, 24.828725109000061],
            [67.019979141000078, 24.828630049000026],
            [67.020155000000045, 24.828442000000052],
            [67.020191000000068, 24.828403000000037],
            [67.020271000000037, 24.828325000000063],
            [67.020354000000054, 24.828252000000077],
            [67.020441000000062, 24.828183000000024],
            [67.020492000000047, 24.828146000000061],
            [67.02053200000006, 24.828119000000072],
            [67.020625000000052, 24.82806000000005],
            [67.020722000000035, 24.828006000000073],
            [67.020822000000067, 24.827958000000024],
            [67.021339000000069, 24.827686000000028],
            [67.021738000000028, 24.827476000000047],
            [67.021812000000068, 24.827441000000078],
            [67.021889000000044, 24.827410000000043],
            [67.021966000000077, 24.827381000000059],
            [67.022044000000051, 24.827355000000068],
            [67.022123000000079, 24.827331000000072],
            [67.022175000000061, 24.827318000000048],
            [67.022203000000047, 24.827311000000066],
            [67.022283000000073, 24.827293000000054],
            [67.022365000000036, 24.827279000000033],
            [67.023022000000083, 24.827187000000038],
            [67.023255000000063, 24.827163000000041],
            [67.02369200000004, 24.827117000000044],
            [67.024240001000067, 24.827131630000054],
            [67.024502464000079, 24.827071395000075],
            [67.024668323000071, 24.827047631000028],
            [67.024910766000062, 24.827016094000044],
            [67.025067073000059, 24.826995969000052],
            [67.025109000000043, 24.826985000000036],
            [67.025184000000081, 24.826958000000047],
            [67.025255838000078, 24.826909898000054],
            [67.02518548300003, 24.826803148000067],
            [67.025147279000066, 24.826728388000049],
            [67.025109106000059, 24.826586890000044],
            [67.025094464000063, 24.826445401000058],
            [67.025097452000068, 24.826341292000052],
            [67.025091628000041, 24.826213154000072],
            [67.025068157000078, 24.826082340000028],
            [67.025033386000075, 24.825816202000055],
            [67.024596000000031, 24.824846000000036],
            [67.023789467000029, 24.823054482000032],
            [67.023213000000055, 24.821774000000062],
            [67.022836015000053, 24.820936762000031],
            [67.022311874000081, 24.819772711000041],
            [67.022140000000036, 24.819391000000053],
            [67.021753000000047, 24.818530000000067],
            [67.02150800000004, 24.817988000000071],
            [67.020684000000074, 24.816157000000032],
            [67.020653000000038, 24.81610900000004],
            [67.020619000000067, 24.81606400000004],
            [67.02058100000005, 24.816021000000035],
            [67.02054000000004, 24.815981000000079],
            [67.020496000000037, 24.815944000000059],
            [67.020450000000039, 24.815911000000028],
            [67.020402000000047, 24.815881000000047],
            [67.020351000000062, 24.815855000000056],
            [67.020416000000068, 24.815859000000046],
            [67.020488000000057, 24.815855000000056],
            [67.020545000000084, 24.815846000000079],
            [67.020609000000036, 24.815832000000057],
            [67.020669000000055, 24.815807000000063],
            [67.02072400000003, 24.815773000000036],
            [67.020773000000077, 24.815729000000033],
            [67.020811000000037, 24.815676000000053],
            [67.020831000000044, 24.815616000000034],
            [67.020834087000082, 24.815597480000065],
            [67.020485000000065, 24.815523000000042],
            [67.020186000000081, 24.815671000000066],
            [67.018588000000079, 24.81629300000003],
            [67.018565803000058, 24.816301076000059],
            [67.01844477800006, 24.816345110000043],
            [67.017266000000063, 24.816774000000066],
            [67.016855000000078, 24.816919000000041],
            [67.016334000000029, 24.817050000000052],
            [67.015863000000081, 24.817165000000045],
            [67.015681000000029, 24.817192000000034],
            [67.015546000000029, 24.817205000000058],
            [67.015381000000048, 24.817198000000076],
            [67.015119000000084, 24.817165000000045],
            [67.014591000000053, 24.81702000000007],
            [67.013806000000045, 24.816781000000049],
            [67.012164000000041, 24.816313000000036],
            [67.011925000000076, 24.816266000000041],
            [67.011683000000062, 24.816243000000043],
            [67.011414000000059, 24.816263000000049],
            [67.011017000000038, 24.816340000000025],
            [67.010300000000029, 24.816445000000044],
            [67.00828400000006, 24.816761000000042],
            [67.008126433000029, 24.816789429000039],
            [67.007242000000076, 24.816949000000022],
            [67.006957000000057, 24.816989000000035],
            [67.006694000000039, 24.817029000000048],
            [67.006436000000065, 24.817064000000073],
            [67.006041000000039, 24.817108000000076],
            [67.005850000000066, 24.817141000000049],
            [67.005694000000062, 24.817168000000038],
            [67.005322000000035, 24.817231000000049],
            [67.005085000000065, 24.81726100000003],
            [67.004801000000043, 24.817296000000056],
            [67.004433651000056, 24.817346932000078],
            [67.004224000000079, 24.817376000000024],
            [67.00267400000007, 24.817607000000066],
            [67.002243000000078, 24.817674000000068],
            [67.001656000000082, 24.817765000000065],
            [67.001648000000046, 24.817766000000063],
            [67.001548000000071, 24.817782000000022],
            [67.001066000000037, 24.817858000000058],
            [67.000842000000034, 24.817893000000026],
            [67.000542000000053, 24.817940000000078],
            [67.000438000000031, 24.817957000000035],
            [66.999046000000078, 24.818176000000051],
            [66.998765000000049, 24.818220000000053],
            [66.997877000000074, 24.818360000000041],
            [66.997418000000039, 24.81843200000003],
            [66.99710900000008, 24.818481000000077],
            [66.996646000000055, 24.818554000000063],
            [66.996012000000064, 24.81865300000004],
            [66.995457000000044, 24.818741000000045],
            [66.994563000000028, 24.818882000000031],
            [66.992669427000067, 24.820188078000058],
            [66.98469483100007, 24.820111028000042],
            [66.981107155000075, 24.820267014000024],
            [66.979495300000053, 24.820942953000042],
            [66.978767366000056, 24.822398822000025],
            [66.97855938500004, 24.823074761000044],
            [66.97523168500004, 24.824530629000037],
            [66.974940511000057, 24.825692660000072],
            [66.974693823000052, 24.826677151000069],
            [66.973625183000081, 24.830604553000057],
            [66.971863173000031, 24.826456608000058],
            [66.970578264000039, 24.824457861000042],
            [66.968436749000034, 24.823387103000073],
            [66.966369629000042, 24.823663712000041],
            [66.963439941000047, 24.822723389000032],
            [66.962654114000031, 24.824993134000067],
            [66.96391654200005, 24.832588035000072],
            [66.965377808000028, 24.841379166000024],
            [66.968658447000053, 24.844837189000032],
            [66.972339918000046, 24.846913653000058],
            [66.971900336000033, 24.847325593000051],
            [66.973117339000055, 24.848282969000024],
            [66.97357168700006, 24.84951619900005],
            [66.974204529000076, 24.850392441000054],
            [66.974642650000078, 24.851203776000034],
            [66.975259264000044, 24.851950205000037],
            [66.975778519000073, 24.852323419000072],
            [66.975810972000033, 24.853037394000069],
            [66.976005693000047, 24.853394381000044],
            [66.97652494700003, 24.853670235000038],
            [66.977190242000063, 24.853702689000045],
            [66.977514777000067, 24.853832502000046],
            [66.978423472000031, 24.853994769000053],
            [66.979023860000041, 24.854075903000023],
            [66.980013689000032, 24.854400437000038],
            [66.980760118000035, 24.854822331000037],
            [66.981620133000035, 24.855179319000058],
            [66.981842741000037, 24.855766999000025],
            [66.982046302000072, 24.856683023000073],
            [66.982513428000061, 24.857860565000067],
            [66.979398416000038, 24.858377348000033],
            [66.978213247000042, 24.85613187000007],
            [66.97818247400005, 24.856073566000077],
            [66.97725065700007, 24.854308101000072],
            [66.975037758000042, 24.853594262000058],
            [66.973895617000039, 24.852880424000034],
            [66.97268209200007, 24.851881050000031],
            [66.971325799000056, 24.85123859600003],
            [66.97025504100003, 24.850881677000075],
            [66.969085693000068, 24.850908279000066],
            [66.964004517000035, 24.851980209000033],
            [66.961006165000072, 24.857927322000023],
            [66.95718781000005, 24.85742801300006],
            [66.95260081400005, 24.856320807000031],
            [66.94643209600008, 24.855846290000045],
            [66.939947033000067, 24.853790051000033],
            [66.934252831000038, 24.851733812000077],
            [66.931247558000052, 24.848570366000047],
            [66.928400458000056, 24.845565093000062],
            [66.926344218000054, 24.842559820000076],
            [66.92460432300004, 24.839712720000023],
            [66.924540937000074, 24.837027755000065],
            [66.92919131900004, 24.840661753000063],
            [66.932196592000082, 24.840978098000051],
            [66.941528756000082, 24.839712720000023],
            [66.948488335000036, 24.836549274000049],
            [66.949603377000074, 24.83581497800003],
            [66.957817078000062, 24.825862885000049],
            [66.960206549000077, 24.822028949000071],
            [66.951871133000054, 24.809986796000032],
            [66.951283000000046, 24.810514000000069],
            [66.949541000000067, 24.81198100000006],
            [66.947906000000046, 24.813411000000031],
            [66.943643000000066, 24.816819000000066],
            [66.941243000000043, 24.818975000000023],
            [66.939027000000067, 24.820705000000032],
            [66.937786000000074, 24.821706000000063],
            [66.936044000000038, 24.823089000000039],
            [66.934804000000042, 24.824074000000053],
            [66.931351000000063, 24.826787000000024],
            [66.931285046000028, 24.826836310000033],
            [66.928704000000039, 24.82876600000003],
            [66.927203000000077, 24.829837000000055],
            [66.926185000000032, 24.830452000000037],
            [66.925902000000065, 24.830737000000056],
            [66.925693000000081, 24.830917000000056],
            [66.925334000000078, 24.830953000000022],
            [66.92497000000003, 24.831160000000068],
            [66.924773000000073, 24.831447000000026],
            [66.924444000000051, 24.831654000000071],
            [66.916085000000066, 24.837137000000041],
            [66.914804000000061, 24.837841000000026],
            [66.909965000000057, 24.840327000000059],
            [66.909268000000054, 24.840695000000039],
            [66.908741000000077, 24.84101400000003],
            [66.908529000000044, 24.841146000000037],
            [66.908162000000061, 24.841369000000043],
            [66.907828000000052, 24.841518000000065],
            [66.907713000000058, 24.841647000000023],
            [66.907515000000046, 24.841761000000076],
            [66.907276000000081, 24.841842000000042],
            [66.907091000000037, 24.841913000000034],
            [66.906818000000044, 24.842030000000022],
            [66.906667000000084, 24.842102000000068],
            [66.906477000000052, 24.842160000000035],
            [66.906318000000056, 24.842289000000051],
            [66.906120000000044, 24.842365000000029],
            [66.905943000000036, 24.842436000000077],
            [66.905815000000075, 24.842521000000033],
            [66.905624000000046, 24.842585000000042],
            [66.905526000000066, 24.842589000000032],
            [66.905399000000045, 24.842630000000042],
            [66.905137000000082, 24.842712000000063],
            [66.90485300000006, 24.843066000000078],
            [66.904577000000074, 24.843173000000036],
            [66.904483000000027, 24.843271000000072],
            [66.904334000000063, 24.843349000000046],
            [66.904064000000062, 24.843493000000024],
            [66.903985000000034, 24.843610000000069],
            [66.903885000000059, 24.843713000000037],
            [66.903716000000031, 24.843784000000028],
            [66.903495000000078, 24.843825000000038],
            [66.903542000000073, 24.843914000000041],
            [66.903484000000049, 24.843958000000043],
            [66.903400000000033, 24.843901000000074],
            [66.903187000000059, 24.844050000000038],
            [66.902973000000031, 24.844184000000041],
            [66.902880000000039, 24.844270000000051],
            [66.902779000000066, 24.844368000000031],
            [66.90272200000004, 24.844448000000057],
            [66.902644000000066, 24.844484000000023],
            [66.902469000000053, 24.844491000000062],
            [66.902350000000069, 24.844544000000042],
            [66.902300000000082, 24.844599000000073],
            [66.902230000000031, 24.84461600000003],
            [66.902083000000061, 24.844687000000079],
            [66.901985000000082, 24.844789000000048],
            [66.901867000000038, 24.844841000000031],
            [66.901770000000056, 24.844869000000074],
            [66.901644000000033, 24.84490900000003],
            [66.901540000000068, 24.844944000000055],
            [66.901452000000063, 24.84489700000006],
            [66.901377000000082, 24.84486400000003],
            [66.901254000000051, 24.844816000000037],
            [66.901152000000081, 24.844763000000057],
            [66.901135000000068, 24.844624000000067],
            [66.901061000000084, 24.844566000000043],
            [66.900927000000081, 24.844505000000026],
            [66.900866000000065, 24.844471000000055],
            [66.900726000000077, 24.844360000000052],
            [66.900694000000044, 24.84424000000007],
            [66.900661000000071, 24.844177000000059],
            [66.900606000000039, 24.84411300000005],
            [66.900510000000054, 24.844066000000055],
            [66.900427000000036, 24.844089000000054],
            [66.900378000000046, 24.844131000000061],
            [66.900343000000078, 24.844180000000051],
            [66.90030100000007, 24.844198000000063],
            [66.900239000000056, 24.844184000000041],
            [66.900246000000038, 24.844053000000031],
            [66.900188000000071, 24.843957000000046],
            [66.900212000000067, 24.843858000000068],
            [66.900181000000032, 24.843782000000033],
            [66.900094000000081, 24.843704000000059],
            [66.899965000000066, 24.843606000000023],
            [66.899793000000045, 24.84355000000005],
            [66.899618000000032, 24.843564000000072],
            [66.89948000000004, 24.843535000000031],
            [66.899362000000053, 24.843544000000065],
            [66.899309000000073, 24.843480000000056],
            [66.899249000000054, 24.843403000000023],
            [66.899182000000053, 24.843332000000032],
            [66.899088000000063, 24.843216000000041],
            [66.89904800000005, 24.84314500000005],
            [66.898903000000075, 24.843109000000027],
            [66.898695000000032, 24.843104000000039],
            [66.898620000000051, 24.84305100000006],
            [66.898564000000079, 24.843030000000056],
            [66.898453000000075, 24.843014000000039],
            [66.89817400000004, 24.843026000000066],
            [66.898097000000064, 24.843011000000047],
            [66.897880000000043, 24.843017000000032],
            [66.89780600000006, 24.843116000000066],
            [66.89770500000003, 24.843259000000046],
            [66.89761800000008, 24.843377000000032],
            [66.897352000000069, 24.843559000000027],
            [66.897136000000046, 24.843743000000075],
            [66.896979000000044, 24.843891000000042],
            [66.895943000000045, 24.844470000000058],
            [66.895691000000056, 24.844686000000024],
            [66.89554000000004, 24.844858000000045],
            [66.895390000000077, 24.844986000000063],
            [66.895192000000065, 24.845074000000068],
            [66.895000000000039, 24.845189000000062],
            [66.894836000000055, 24.845312000000035],
            [66.894722000000058, 24.845378000000039],
            [66.894529000000034, 24.84550500000006],
            [66.894370000000038, 24.84564000000006],
            [66.894065000000069, 24.845803000000046],
            [66.893807000000038, 24.845998000000066],
            [66.893621000000053, 24.846158000000059],
            [66.893513000000041, 24.846269000000063],
            [66.893335000000036, 24.846359000000064],
            [66.893179000000032, 24.84642400000007],
            [66.893148000000053, 24.846543000000054],
            [66.893019000000038, 24.846629000000064],
            [66.892856000000052, 24.84669400000007],
            [66.892911000000083, 24.846727000000044],
            [66.892861000000039, 24.846764000000064],
            [66.892772000000036, 24.84669800000006],
            [66.892551000000083, 24.846806000000072],
            [66.892365000000041, 24.846941000000072],
            [66.892251000000044, 24.847039000000052],
            [66.89204600000005, 24.847140000000024],
            [66.891450000000077, 24.847410000000025],
            [66.891230000000064, 24.847511000000054],
            [66.891072000000065, 24.847659000000078],
            [66.890957000000071, 24.847751000000073],
            [66.890773000000081, 24.847834000000034],
            [66.890512000000058, 24.847960000000057],
            [66.890314000000046, 24.84804900000006],
            [66.890216000000066, 24.848077000000046],
            [66.890228000000036, 24.848153000000025],
            [66.890145000000075, 24.848145000000045],
            [66.889968000000067, 24.848197000000027],
            [66.889862000000051, 24.848232000000053],
            [66.88973500000003, 24.848280000000045],
            [66.889500000000055, 24.848394000000042],
            [66.889442000000031, 24.848480000000052],
            [66.889279000000045, 24.848564000000067],
            [66.889094000000057, 24.848679000000061],
            [66.88889000000006, 24.848756000000037],
            [66.888757000000055, 24.848808000000076],
            [66.888687000000061, 24.848875000000078],
            [66.88806500000004, 24.849184000000037],
            [66.887753000000032, 24.849371000000076],
            [66.887244000000067, 24.849616000000026],
            [66.886716000000035, 24.84999300000004],
            [66.886639000000059, 24.849985000000061],
            [66.886518000000081, 24.850039000000038],
            [66.886291000000028, 24.850172000000043],
            [66.886014000000046, 24.850360000000023],
            [66.88588100000004, 24.850381000000027],
            [66.885767000000044, 24.85050300000006],
            [66.885535000000061, 24.850628000000029],
            [66.885172000000068, 24.850713000000042],
            [66.884600000000034, 24.851098000000036],
            [66.884376000000032, 24.85120500000005],
            [66.884344000000056, 24.851317000000051],
            [66.884038000000032, 24.851473000000055],
            [66.883913000000064, 24.851489000000072],
            [66.883399000000054, 24.851745000000051],
            [66.882909000000041, 24.852035000000058],
            [66.882387000000051, 24.852247000000034],
            [66.881588000000079, 24.852502000000072],
            [66.88101800000004, 24.85282200000006],
            [66.880792000000042, 24.853003000000058],
            [66.880492000000061, 24.853052000000048],
            [66.879781000000037, 24.85334400000005],
            [66.879320000000064, 24.853559000000075],
            [66.879170000000045, 24.853675000000067],
            [66.878790000000038, 24.853859000000057],
            [66.878487000000064, 24.854033000000072],
            [66.87800500000003, 24.854229000000032],
            [66.877495000000067, 24.854400000000055],
            [66.877287000000081, 24.854534000000058],
            [66.877161000000058, 24.854556000000059],
            [66.876880000000028, 24.854730000000075],
            [66.876635000000078, 24.854816000000028],
            [66.876366000000075, 24.854928000000029],
            [66.87610600000005, 24.855033000000049],
            [66.875992000000053, 24.85510400000004],
            [66.875793000000044, 24.85523100000006],
            [66.875558000000069, 24.855359000000078],
            [66.875247000000059, 24.855510000000038],
            [66.874982000000045, 24.855567000000065],
            [66.874793000000068, 24.855656000000067],
            [66.874610000000075, 24.855765000000076],
            [66.874448000000029, 24.85588000000007],
            [66.874184000000071, 24.856017000000065],
            [66.873654000000045, 24.856141000000036],
            [66.87321600000007, 24.856305000000077],
            [66.873062000000061, 24.856383000000051],
            [66.873055000000079, 24.856345000000033],
            [66.872881000000064, 24.856460000000027],
            [66.872742000000073, 24.856531000000075],
            [66.872550000000047, 24.856696000000056],
            [66.87226000000004, 24.856814000000043],
            [66.872071000000062, 24.856892000000073],
            [66.871199000000047, 24.85719000000006],
            [66.870904000000053, 24.857289000000037],
            [66.870832000000064, 24.857356000000038],
            [66.870383000000061, 24.857470000000035],
            [66.869554000000051, 24.857718000000034],
            [66.869211000000064, 24.857859000000076],
            [66.868883000000039, 24.857944000000032],
            [66.868589000000043, 24.858012000000031],
            [66.868433000000039, 24.858077000000037],
            [66.868378000000064, 24.858177000000069],
            [66.868206000000043, 24.858317000000056],
            [66.868170000000077, 24.858366000000046],
            [66.867863000000057, 24.858427000000063],
            [66.867774000000054, 24.858478000000048],
            [66.867621000000042, 24.85848500000003],
            [66.867514000000028, 24.858540000000062],
            [66.867421000000036, 24.858534000000077],
            [66.867269000000078, 24.858614000000046],
            [66.867197000000033, 24.858612000000051],
            [66.867034000000046, 24.858651000000066],
            [66.86695300000008, 24.858637000000044],
            [66.866850000000056, 24.85866500000003],
            [66.866760000000056, 24.858675000000062],
            [66.866725000000031, 24.858735000000024],
            [66.866643000000067, 24.858733000000029],
            [66.866531000000066, 24.858758000000023],
            [66.866363000000035, 24.858788000000061],
            [66.866295000000036, 24.858787000000063],
            [66.866166000000078, 24.858848000000023],
            [66.866081000000065, 24.858846000000028],
            [66.865979000000038, 24.858813000000055],
            [66.865872000000081, 24.858840000000043],
            [66.865800000000036, 24.85881500000005],
            [66.865598000000034, 24.858872000000076],
            [66.865347000000042, 24.858951000000047],
            [66.865121000000045, 24.859026000000028],
            [66.864999000000068, 24.85908900000004],
            [66.864921000000038, 24.859125000000063],
            [66.864869000000056, 24.859054000000071],
            [66.864819000000068, 24.859119000000078],
            [66.864741000000038, 24.859159000000034],
            [66.864689000000055, 24.859196000000054],
            [66.864608000000032, 24.859198000000049],
            [66.864310000000046, 24.859262000000058],
            [66.864135000000033, 24.859304000000066],
            [66.863985000000071, 24.859338000000037],
            [66.86389900000006, 24.859382000000039],
            [66.863789000000054, 24.859382000000039],
            [66.863695000000064, 24.859426000000042],
            [66.863647000000071, 24.859459000000072],
            [66.86349400000006, 24.859485000000063],
            [66.863327000000083, 24.859512000000052],
            [66.863206000000048, 24.859524000000079],
            [66.862964000000034, 24.85959900000006],
            [66.862730000000056, 24.859608000000037],
            [66.862604000000033, 24.859655000000032],
            [66.86246600000004, 24.859667000000059],
            [66.862398000000042, 24.859713000000056],
            [66.862279000000058, 24.859727000000078],
            [66.861091000000044, 24.859964000000048],
            [66.860981000000038, 24.860007000000053],
            [66.860806000000082, 24.860048000000063],
            [66.86051400000008, 24.860105000000033],
            [66.860371000000043, 24.860174000000029],
            [66.860130000000083, 24.860190000000046],
            [66.859841000000074, 24.860263000000032],
            [66.859375000000057, 24.860381000000075],
            [66.859213000000068, 24.860416000000043],
            [66.859088000000042, 24.860459000000048],
            [66.859055000000069, 24.86042800000007],
            [66.858939000000078, 24.860471000000075],
            [66.858850000000075, 24.860454000000061],
            [66.858769000000052, 24.860433000000057],
            [66.858683000000042, 24.860447000000079],
            [66.858641000000034, 24.860442000000035],
            [66.858637000000044, 24.860438000000045],
            [66.858396000000027, 24.860479000000055],
            [66.85819900000007, 24.860520000000065],
            [66.858057000000031, 24.860581000000025],
            [66.857975000000067, 24.860621000000037],
            [66.85783500000008, 24.860632000000066],
            [66.857656000000077, 24.860650000000078],
            [66.857535000000041, 24.860660000000053],
            [66.857470000000035, 24.860678000000064],
            [66.857115000000078, 24.860768000000064],
            [66.857053000000064, 24.860732000000041],
            [66.856981000000076, 24.860738000000026],
            [66.856958000000077, 24.860779000000036],
            [66.856830000000059, 24.860803000000033],
            [66.856830000000059, 24.860815000000059],
            [66.856736000000069, 24.860816000000057],
            [66.856597000000079, 24.860789000000068],
            [66.856459000000029, 24.860801000000038],
            [66.85629700000004, 24.860819000000049],
            [66.855875000000083, 24.860946000000069],
            [66.855678000000069, 24.861013000000071],
            [66.855575000000044, 24.861034000000075],
            [66.855536000000029, 24.861063000000058],
            [66.855422000000033, 24.861079000000075],
            [66.85527600000006, 24.861144000000024],
            [66.855254000000059, 24.861166000000026],
            [66.855121000000054, 24.86117500000006],
            [66.854975000000081, 24.861202000000048],
            [66.85491200000007, 24.861189000000024],
            [66.854791000000034, 24.861212000000023],
            [66.854723000000035, 24.861207000000036],
            [66.854705000000081, 24.861245000000054],
            [66.854666000000066, 24.861259000000075],
            [66.85430400000007, 24.861289000000056],
            [66.854074000000082, 24.861302000000023],
            [66.853830000000073, 24.861323000000027],
            [66.853723000000059, 24.861339000000044],
            [66.853613000000053, 24.861339000000044],
            [66.853460000000041, 24.861380000000054],
            [66.853366000000051, 24.861393000000078],
            [66.853264000000081, 24.861405000000047],
            [66.853141000000051, 24.861405000000047],
            [66.853134000000068, 24.86135900000005],
            [66.85291200000006, 24.861403000000053],
            [66.852536000000043, 24.861399000000063],
            [66.852260000000058, 24.861384000000044],
            [66.852101000000062, 24.861489000000063],
            [66.851961000000074, 24.861540000000048],
            [66.851800000000082, 24.861473000000046],
            [66.851733000000081, 24.86140000000006],
            [66.851498000000049, 24.861465000000067],
            [66.850901000000079, 24.861487000000068],
            [66.850392000000056, 24.861495000000048],
            [66.849979000000076, 24.861530000000073],
            [66.84935100000007, 24.861497000000043],
            [66.849076000000082, 24.861450000000048],
            [66.848835000000065, 24.861382000000049],
            [66.848209000000054, 24.861428000000046],
            [66.847807000000046, 24.861472000000049],
            [66.846962000000076, 24.861416000000077],
            [66.846732000000031, 24.861393000000078],
            [66.84656700000005, 24.861372000000074],
            [66.845518000000084, 24.861346000000026],
            [66.84526100000005, 24.861349000000075],
            [66.845090000000027, 24.861409000000037],
            [66.844976000000031, 24.861419000000069],
            [66.84490900000003, 24.861411000000032],
            [66.844838000000038, 24.861371000000076],
            [66.844600000000071, 24.861365000000035],
            [66.844442000000072, 24.861330000000066],
            [66.844284000000073, 24.861302000000023],
            [66.844129000000066, 24.861341000000039],
            [66.844049000000041, 24.861362000000042],
            [66.843914000000041, 24.861354000000063],
            [66.843862000000058, 24.861336000000051],
            [66.843805000000032, 24.861374000000069],
            [66.84363600000006, 24.861342000000036],
            [66.843649000000028, 24.861275000000035],
            [66.843563000000074, 24.86126900000005],
            [66.843473000000074, 24.861270000000047],
            [66.843315000000075, 24.861252000000036],
            [66.843165000000056, 24.861234000000024],
            [66.843034000000046, 24.86122000000006],
            [66.842877000000044, 24.861165000000028],
            [66.842476000000033, 24.861173000000065],
            [66.842084000000057, 24.861103000000071],
            [66.841826000000083, 24.861072000000036],
            [66.841687000000036, 24.861082000000067],
            [66.841571000000044, 24.861031000000025],
            [66.84108400000008, 24.860977000000048],
            [66.840927000000079, 24.860925000000066],
            [66.840682000000072, 24.860902000000067],
            [66.840467000000046, 24.860863000000052],
            [66.84035700000004, 24.860853000000077],
            [66.840093000000081, 24.860782000000029],
            [66.839699000000053, 24.860688000000039],
            [66.839457000000039, 24.86069800000007],
            [66.839262000000076, 24.86066100000005],
            [66.839105000000075, 24.860629000000074],
            [66.839022000000057, 24.86062000000004],
            [66.838899000000083, 24.860589000000061],
            [66.838712000000044, 24.860547000000054],
            [66.838472000000081, 24.860479000000055],
            [66.838295000000073, 24.860460000000046],
            [66.837991000000045, 24.860411000000056],
            [66.837824000000069, 24.860385000000065],
            [66.837541000000044, 24.86034200000006],
            [66.837425000000053, 24.860312000000079],
            [66.837412000000029, 24.860274000000061],
            [66.837243000000058, 24.860259000000042],
            [66.837083000000064, 24.86020300000007],
            [66.837045000000046, 24.860219000000029],
            [66.836928000000057, 24.860141000000056],
            [66.836790000000065, 24.860113000000069],
            [66.836671000000081, 24.860079000000042],
            [66.83655600000003, 24.860031000000049],
            [66.836441000000036, 24.86000400000006],
            [66.836318000000063, 24.859984000000054],
            [66.83626600000008, 24.859972000000027],
            [66.836175000000082, 24.859874000000048],
            [66.836115000000063, 24.859852000000046],
            [66.836085000000082, 24.859868000000063],
            [66.836033000000043, 24.85983600000003],
            [66.835956000000067, 24.859793000000025],
            [66.835870000000057, 24.859798000000069],
            [66.83575200000007, 24.859744000000035],
            [66.83571900000004, 24.859698000000037],
            [66.835679000000027, 24.859666000000061],
            [66.835619000000065, 24.859654000000035],
            [66.835604000000046, 24.859650000000045],
            [66.835600000000056, 24.859647000000052],
            [66.835491000000047, 24.859620000000064],
            [66.835375000000056, 24.859592000000077],
            [66.835242000000051, 24.859541000000036],
            [66.835154000000045, 24.859498000000031],
            [66.835085000000049, 24.859442000000058],
            [66.834997000000044, 24.859450000000038],
            [66.834902000000056, 24.859417000000064],
            [66.834771000000046, 24.859353000000056],
            [66.83466100000004, 24.859333000000049],
            [66.834512000000075, 24.859170000000063],
            [66.834482000000037, 24.859186000000022],
            [66.834401000000071, 24.859143000000074],
            [66.834298000000047, 24.859055000000069],
            [66.83418000000006, 24.859004000000027],
            [66.834118000000046, 24.858944000000065],
            [66.83396300000004, 24.858833000000061],
            [66.833900000000028, 24.858781000000079],
            [66.833806000000038, 24.858700000000056],
            [66.833723000000077, 24.858585000000062],
            [66.833640000000059, 24.85847300000006],
            [66.833660000000066, 24.858373000000029],
            [66.833679000000075, 24.858332000000075],
            [66.833703000000071, 24.858239000000026],
            [66.833719000000031, 24.85816600000004],
            [66.833676000000082, 24.858099000000038],
            [66.833566000000076, 24.858039000000076],
            [66.833471000000031, 24.857937000000049],
            [66.833394000000055, 24.857880000000023],
            [66.833293000000083, 24.857856000000027],
            [66.833257290000063, 24.857827432000079],
            [66.833256635000055, 24.857830919000037],
            [66.833252519000041, 24.857827626000073],
            [66.833213000000057, 24.857796011000062],
            [66.833096000000069, 24.857691011000043],
            [66.832968000000051, 24.857578011000044],
            [66.832829000000061, 24.857513011000037],
            [66.832701000000043, 24.85741401100006],
            [66.832584000000054, 24.857339011000079],
            [66.832374000000073, 24.85708401100004],
            [66.832228000000043, 24.856947011000045],
            [66.832097000000033, 24.856843011000024],
            [66.832092000000046, 24.856735011000069],
            [66.832120000000032, 24.856614011000033],
            [66.832088000000056, 24.856408011000042],
            [66.832099000000028, 24.856189011000026],
            [66.832070000000044, 24.855913011000041],
            [66.832077000000083, 24.855718011000079],
            [66.832102000000077, 24.855551011000045],
            [66.832129000000066, 24.855438011000047],
            [66.832153000000062, 24.855257011000049],
            [66.832247000000052, 24.855109011000025],
            [66.832393000000081, 24.854980011000066],
            [66.832521000000042, 24.854886011000076],
            [66.832547000000034, 24.854815011000028],
            [66.832539000000054, 24.854715011000053],
            [66.832497000000046, 24.854610011000034],
            [66.832358000000056, 24.854551011000069],
            [66.832242000000065, 24.854517011000041],
            [66.832092000000046, 24.854485011000065],
            [66.831935000000044, 24.854426011000044],
            [66.831749000000059, 24.854339011000036],
            [66.831563000000074, 24.854289011000049],
            [66.831424000000084, 24.854254011000023],
            [66.830909000000077, 24.854005011000027],
            [66.830475000000035, 24.853777011000034],
            [66.83010100000007, 24.853556011000023],
            [66.829727000000048, 24.853317011000058],
            [66.829053000000044, 24.852737011000045],
            [66.828755000000058, 24.852372011000057],
            [66.828588000000082, 24.852171011000053],
            [66.828400000000045, 24.851958011000079],
            [66.828284000000053, 24.851747011000043],
            [66.828181000000029, 24.851596011000026],
            [66.828125000000057, 24.851435011000035],
            [66.828110000000038, 24.85128601100007],
            [66.828087000000039, 24.851138011000046],
            [66.828101000000061, 24.850935011000047],
            [66.828097000000071, 24.850848011000039],
            [66.828225000000032, 24.850660011000059],
            [66.828195000000051, 24.850560011000027],
            [66.828046000000029, 24.850532011000041],
            [66.82783500000005, 24.850360011000078],
            [66.827166000000034, 24.849695011000051],
            [66.827104000000077, 24.849495011000045],
            [66.827067000000056, 24.849345011000025],
            [66.826974000000064, 24.849318011000037],
            [66.826860000000067, 24.849267011000052],
            [66.826208000000065, 24.848631011000066],
            [66.825915000000066, 24.848370011000043],
            [66.825632000000041, 24.848115011000061],
            [66.825230000000033, 24.847549011000069],
            [66.824551000000042, 24.846793011000045],
            [66.824417000000039, 24.846687011000029],
            [66.824295000000063, 24.846462011000028],
            [66.824212000000045, 24.846318011000051],
            [66.824204000000066, 24.846152011000072],
            [66.824189000000047, 24.846004011000048],
            [66.824261000000035, 24.84586401100006],
            [66.824418000000037, 24.84583101100003],
            [66.824568000000056, 24.845816011000068],
            [66.824666000000036, 24.845732011000052],
            [66.824711000000036, 24.845615011000064],
            [66.824708000000044, 24.845491011000036],
            [66.824705000000051, 24.845392011000058],
            [66.824688000000037, 24.845286011000042],
            [66.82468300000005, 24.845242011000039],
            [66.824727000000053, 24.845132011000032],
            [66.824870000000033, 24.845135011000025],
            [66.824980000000039, 24.845131011000035],
            [66.825090000000046, 24.845122011000058],
            [66.824934000000042, 24.845069011000078],
            [66.824832000000072, 24.845066011000029],
            [66.824801000000036, 24.844960011000069],
            [66.824678000000063, 24.844976011000028],
            [66.824583000000075, 24.84494901100004],
            [66.82450300000005, 24.844898011000055],
            [66.824370000000044, 24.844808011000055],
            [66.824172000000033, 24.844616011000028],
            [66.82408300000003, 24.844545011000037],
            [66.823872000000051, 24.844326011000078],
            [66.82370700000007, 24.844166011000027],
            [66.82347800000008, 24.843889011000044],
            [66.823132000000044, 24.843355011000028],
            [66.82293500000003, 24.843076011000051],
            [66.822708000000034, 24.84267901100003],
            [66.822515000000067, 24.842319011000029],
            [66.82227400000005, 24.841598011000031],
            [66.822260000000028, 24.841461011000035],
            [66.82230700000008, 24.841254011000046],
            [66.822326000000032, 24.841118011000049],
            [66.822428000000059, 24.840908011000067],
            [66.822500000000048, 24.840715011000043],
            [66.822626000000071, 24.840537011000038],
            [66.822720000000061, 24.840408011000022],
            [66.822929000000045, 24.840215011000055],
            [66.823078000000066, 24.840145011000061],
            [66.823199000000045, 24.840152011000043],
            [66.82334000000003, 24.840173011000047],
            [66.823515000000043, 24.840117011000075],
            [66.823663000000067, 24.840087011000037],
            [66.823754000000065, 24.840062011000043],
            [66.823865000000069, 24.840065011000036],
            [66.823975000000075, 24.84008201100005],
            [66.824092000000064, 24.840058011000053],
            [66.824195000000032, 24.840011011000058],
            [66.824156000000073, 24.839982011000075],
            [66.824250000000063, 24.839875011000061],
            [66.824180000000069, 24.839855011000054],
            [66.824108000000081, 24.839899011000057],
            [66.824033000000043, 24.839892011000074],
            [66.823964000000046, 24.839844011000025],
            [66.823920000000044, 24.839816011000039],
            [66.823787000000038, 24.839862011000037],
            [66.823691000000053, 24.839882011000043],
            [66.823537000000044, 24.839956011000027],
            [66.823446000000047, 24.839959011000076],
            [66.823372000000063, 24.839916011000071],
            [66.823583000000042, 24.83979701100003],
            [66.823401000000047, 24.839661011000032],
            [66.823324000000071, 24.839700011000048],
            [66.823145000000068, 24.839810011000054],
            [66.82324600000004, 24.839621011000077],
            [66.823321000000078, 24.839481011000032],
            [66.823180000000036, 24.839496011000051],
            [66.823002000000031, 24.839434011000037],
            [66.822862000000043, 24.839449011000056],
            [66.822745000000054, 24.839502011000036],
            [66.822633000000053, 24.839586011000051],
            [66.822542000000055, 24.839634011000044],
            [66.822468000000072, 24.839624011000069],
            [66.822416000000032, 24.839559011000063],
            [66.822533000000078, 24.839462011000023],
            [66.82264500000008, 24.839396011000076],
            [66.822761000000071, 24.839324011000031],
            [66.822705000000042, 24.83929001100006],
            [66.822646000000077, 24.839261011000076],
            [66.822588000000053, 24.839223011000058],
            [66.822474000000057, 24.839197011000067],
            [66.822384000000056, 24.839190011000028],
            [66.822310000000073, 24.839188011000033],
            [66.82222900000005, 24.839204011000049],
            [66.82235200000008, 24.839121011000032],
            [66.822328000000027, 24.839080011000078],
            [66.822182000000055, 24.838962011000035],
            [66.822399000000075, 24.839031011000031],
            [66.822588000000053, 24.839077011000029],
            [66.822742000000062, 24.839109011000062],
            [66.822582000000068, 24.838954011000055],
            [66.82249900000005, 24.838897011000029],
            [66.822441000000083, 24.838810011000078],
            [66.822412000000043, 24.838650011000027],
            [66.82254400000005, 24.838576011000043],
            [66.822646000000077, 24.838533011000038],
            [66.822700000000054, 24.838434011000061],
            [66.822678000000053, 24.838342011000066],
            [66.822639000000038, 24.838291011000024],
            [66.822595000000035, 24.83825801100005],
            [66.82254000000006, 24.838265011000033],
            [66.822419000000082, 24.838290011000026],
            [66.82236400000005, 24.838284011000042],
            [66.82232300000004, 24.838301011000055],
            [66.822207000000049, 24.838325011000052],
            [66.822096000000045, 24.838350011000045],
            [66.822007000000042, 24.838306011000043],
            [66.821930000000066, 24.838381011000024],
            [66.821740000000034, 24.838526011000056],
            [66.821597000000054, 24.838632011000072],
            [66.821501000000069, 24.83863301100007],
            [66.821394000000055, 24.838652011000079],
            [66.821332000000041, 24.83867801100007],
            [66.821157000000028, 24.838803011000039],
            [66.821095000000071, 24.838835011000072],
            [66.820999000000029, 24.838893011000039],
            [66.820965000000058, 24.838906011000063],
            [66.820945000000052, 24.838864011000055],
            [66.820903000000044, 24.838832011000022],
            [66.820823000000075, 24.838816011000063],
            [66.820789000000048, 24.838834011000074],
            [66.820733000000075, 24.838871011000037],
            [66.820725000000039, 24.838957011000048],
            [66.820727000000034, 24.839019011000062],
            [66.820766000000049, 24.839064011000062],
            [66.820701000000042, 24.83909501100004],
            [66.820633000000043, 24.839113011000052],
            [66.820572000000084, 24.839116011000044],
            [66.820525000000032, 24.839126011000076],
            [66.820509000000072, 24.839150011000072],
            [66.820446000000061, 24.839173011000071],
            [66.820403000000056, 24.839186011000038],
            [66.820356000000061, 24.83919601100007],
            [66.820286000000067, 24.839255011000034],
            [66.820203000000049, 24.839325011000028],
            [66.820167000000083, 24.839332011000067],
            [66.820114000000046, 24.839358011000058],
            [66.820055000000082, 24.83942501100006],
            [66.82003300000008, 24.839510011000073],
            [66.820029000000034, 24.839579011000069],
            [66.820007000000032, 24.83965801100004],
            [66.81999200000007, 24.839723011000046],
            [66.819979000000046, 24.83979301100004],
            [66.819989000000078, 24.839846011000077],
            [66.81995900000004, 24.839906011000039],
            [66.819913000000042, 24.83993201100003],
            [66.819858000000067, 24.839950011000042],
            [66.819803000000036, 24.839962011000068],
            [66.819740000000081, 24.839955011000029],
            [66.819691000000034, 24.839973011000041],
            [66.819615000000056, 24.839963011000066],
            [66.819537000000082, 24.839947011000049],
            [66.819464000000039, 24.839934011000025],
            [66.81938400000007, 24.839896011000064],
            [66.819274000000064, 24.839840011000035],
            [66.819206000000065, 24.839781011000071],
            [66.819183000000066, 24.839739011000063],
            [66.819230000000061, 24.83969901100005],
            [66.81926100000004, 24.839664011000025],
            [66.819303000000048, 24.839599011000075],
            [66.819338000000073, 24.83955601100007],
            [66.819336000000078, 24.839517011000055],
            [66.819275000000061, 24.839513011000065],
            [66.819256000000053, 24.839543011000046],
            [66.81917100000004, 24.839557011000068],
            [66.81917100000004, 24.839538011000059],
            [66.81916700000005, 24.83947401100005],
            [66.81911000000008, 24.839475011000047],
            [66.819022000000075, 24.83947801100004],
            [66.818961000000058, 24.839488011000071],
            [66.818908000000079, 24.83943701100003],
            [66.818882000000031, 24.839392011000029],
            [66.818863000000078, 24.839322011000036],
            [66.818900000000042, 24.839221011000063],
            [66.818931000000077, 24.839173011000071],
            [66.818945000000042, 24.839115011000047],
            [66.818976000000077, 24.838991011000076],
            [66.819056000000046, 24.838877011000022],
            [66.819149000000039, 24.838802011000041],
            [66.819246000000078, 24.838746011000069],
            [66.819318000000067, 24.838664011000049],
            [66.819340000000068, 24.838587011000072],
            [66.81934300000006, 24.83849801100007],
            [66.819303000000048, 24.838413011000057],
            [66.81925300000006, 24.838376011000037],
            [66.819150000000036, 24.838345011000058],
            [66.81907300000006, 24.838354011000035],
            [66.818996000000084, 24.838347011000053],
            [66.818960000000061, 24.838351011000043],
            [66.818910000000074, 24.838375011000039],
            [66.818867000000068, 24.838388011000063],
            [66.818814000000032, 24.838403011000025],
            [66.818781000000058, 24.838380011000027],
            [66.818757000000062, 24.838390011000058],
            [66.818729000000076, 24.838484011000048],
            [66.818501000000083, 24.83863701100006],
            [66.818409000000031, 24.83873101100005],
            [66.81837100000007, 24.838775011000052],
            [66.818302000000074, 24.838831011000025],
            [66.818225000000041, 24.838854011000024],
            [66.818184000000031, 24.838875011000027],
            [66.81815400000005, 24.838849011000036],
            [66.818099000000075, 24.838875011000027],
            [66.818046000000038, 24.838896011000031],
            [66.817985000000078, 24.838902011000073],
            [66.817920000000072, 24.838920011000027],
            [66.817843000000039, 24.838950011000065],
            [66.817778000000033, 24.838979011000049],
            [66.817679000000055, 24.839031011000031],
            [66.817596000000037, 24.839065011000059],
            [66.81753400000008, 24.839088011000058],
            [66.817520000000059, 24.839071011000044],
            [66.817483000000038, 24.839053011000033],
            [66.817450000000065, 24.839052011000035],
            [66.817422000000079, 24.83905401100003],
            [66.817412000000047, 24.839112011000054],
            [66.817315000000065, 24.839167011000029],
            [66.817287000000078, 24.839177011000061],
            [66.817168000000038, 24.839235011000028],
            [66.817035000000033, 24.83933101100007],
            [66.816957000000059, 24.839411011000038],
            [66.816894000000048, 24.839480011000035],
            [66.816801000000055, 24.839541011000051],
            [66.81670900000006, 24.839558011000065],
            [66.816653000000031, 24.839576011000077],
            [66.81657000000007, 24.839569011000037],
            [66.816532000000052, 24.839557011000068],
            [66.816457000000071, 24.839566011000045],
            [66.816402000000039, 24.839584011000056],
            [66.816343000000074, 24.839608011000053],
            [66.816243000000043, 24.83961301100004],
            [66.816104000000053, 24.839640011000029],
            [66.815900000000056, 24.839639011000031],
            [66.815719000000058, 24.839607011000055],
            [66.815544000000045, 24.839550011000028],
            [66.815372000000082, 24.839473011000052],
            [66.815234000000032, 24.839411011000038],
            [66.815111000000059, 24.839344011000037],
            [66.814950000000067, 24.839204011000049],
            [66.814846000000045, 24.839093011000045],
            [66.814792000000068, 24.838975011000059],
            [66.814767000000074, 24.838851011000031],
            [66.814788000000078, 24.838743011000076],
            [66.81490000000008, 24.838601011000037],
            [66.81489700000003, 24.838601011000037],
            [66.81499800000006, 24.838489011000036],
            [66.81508400000007, 24.838409011000067],
            [66.815188000000035, 24.838337011000078],
            [66.815252000000044, 24.838257011000053],
            [66.815343000000041, 24.838215011000045],
            [66.815357000000063, 24.83816801100005],
            [66.815331000000072, 24.838109011000029],
            [66.815316000000053, 24.838061011000036],
            [66.81528000000003, 24.83804101100003],
            [66.815233000000035, 24.838042011000027],
            [66.815164000000038, 24.838099011000054],
            [66.815093000000047, 24.83817201100004],
            [66.815077000000031, 24.838113011000075],
            [66.815050000000042, 24.838085011000032],
            [66.815010000000029, 24.838075011000058],
            [66.814970000000073, 24.838085011000032],
            [66.814951000000065, 24.838104011000041],
            [66.814933000000053, 24.838081011000043],
            [66.814915000000042, 24.838058011000044],
            [66.814871000000039, 24.838085011000032],
            [66.814921000000083, 24.838156011000024],
            [66.814864000000057, 24.838135011000077],
            [66.814831000000083, 24.838173011000038],
            [66.814813000000072, 24.838181011000074],
            [66.814795000000061, 24.838265011000033],
            [66.814772000000062, 24.838306011000043],
            [66.814792000000068, 24.838346011000056],
            [66.814806000000033, 24.838382011000078],
            [66.814770000000067, 24.838368011000057],
            [66.814727000000062, 24.838358011000025],
            [66.81470500000006, 24.838380011000027],
            [66.814655000000073, 24.838487011000041],
            [66.814621000000045, 24.838489011000036],
            [66.814600000000041, 24.838488011000038],
            [66.814575000000048, 24.838488011000038],
            [66.814489000000037, 24.83850201100006],
            [66.814452000000074, 24.838504011000055],
            [66.814367000000061, 24.838468011000032],
            [66.814306000000045, 24.838469011000029],
            [66.81421000000006, 24.838522011000066],
            [66.814242000000036, 24.83855101100005],
            [66.814183000000071, 24.83859601100005],
            [66.814147000000048, 24.838573011000051],
            [66.814104000000043, 24.838591011000062],
            [66.814051000000063, 24.83863701100006],
            [66.813964000000055, 24.838709011000049],
            [66.813960000000066, 24.838712011000041],
            [66.813889000000074, 24.838767011000073],
            [66.813892000000067, 24.838831011000025],
            [66.813900000000046, 24.838872011000035],
            [66.813891000000069, 24.838910011000053],
            [66.813863000000083, 24.838934011000049],
            [66.813820000000078, 24.838957011000048],
            [66.813793000000032, 24.838970011000072],
            [66.813759000000061, 24.838969011000074],
            [66.813720000000046, 24.838944011000024],
            [66.81369600000005, 24.838913011000045],
            [66.81365900000003, 24.838893011000039],
            [66.813352000000066, 24.839048011000045],
            [66.813191000000074, 24.839168011000027],
            [66.813040000000058, 24.839219011000068],
            [66.812959000000035, 24.839231011000038],
            [66.812753000000043, 24.83933901100005],
            [66.81230400000004, 24.839575011000079],
            [66.812242000000083, 24.839668011000072],
            [66.812226000000067, 24.839755011000022],
            [66.812159000000065, 24.839856011000052],
            [66.812088000000074, 24.839981011000077],
            [66.812078000000042, 24.840039011000044],
            [66.812033000000042, 24.840097011000069],
            [66.811959000000058, 24.840150011000048],
            [66.811603000000048, 24.840333011000041],
            [66.811454000000083, 24.840441011000053],
            [66.811350000000061, 24.840553011000054],
            [66.81124200000005, 24.840627011000038],
            [66.81110300000006, 24.840687011000057],
            [66.810972000000049, 24.840711011000053],
            [66.810852000000068, 24.840731011000059],
            [66.810672000000068, 24.840841011000066],
            [66.810463000000084, 24.840953011000067],
            [66.810340000000053, 24.841002011000057],
            [66.810241000000076, 24.841036011000028],
            [66.810205000000053, 24.84099701100007],
            [66.810182000000054, 24.840980011000056],
            [66.810133000000064, 24.840973011000074],
            [66.810050000000047, 24.840998011000067],
            [66.810019000000068, 24.841047011000057],
            [66.809955000000059, 24.841059011000027],
            [66.809937000000048, 24.84104201100007],
            [66.809882000000073, 24.841057011000032],
            [66.809823000000051, 24.841082011000026],
            [66.809687000000054, 24.841167011000039],
            [66.809686000000056, 24.841227011000058],
            [66.80953100000005, 24.84128301100003],
            [66.809512000000041, 24.841340011000057],
            [66.809475000000077, 24.841351011000029],
            [66.809443000000044, 24.841388011000049],
            [66.809378000000038, 24.841428011000062],
            [66.809325000000058, 24.841451011000061],
            [66.809346000000062, 24.841490011000076],
            [66.809231000000068, 24.841575011000032],
            [66.809146000000055, 24.841617011000039],
            [66.809038000000044, 24.841645011000026],
            [66.808894000000066, 24.841688011000031],
            [66.808702000000039, 24.841773011000043],
            [66.808603000000062, 24.841840011000045],
            [66.808501000000035, 24.841873011000075],
            [66.808378000000062, 24.841905011000051],
            [66.808261000000073, 24.841979011000035],
            [66.808265000000063, 24.842056011000068],
            [66.808240000000069, 24.842093011000031],
            [66.808209000000033, 24.842128011000057],
            [66.808111000000054, 24.842180011000039],
            [66.807749000000058, 24.842422011000053],
            [66.80747800000006, 24.842552011000066],
            [66.807218000000034, 24.842693011000051],
            [66.807023000000072, 24.84279801100007],
            [66.806659000000082, 24.84293701100006],
            [66.806455000000028, 24.843020011000078],
            [66.806345000000078, 24.843060011000034],
            [66.806250000000034, 24.843085011000028],
            [66.806216000000063, 24.84308401100003],
            [66.806174000000055, 24.843048011000064],
            [66.80613500000004, 24.843026011000063],
            [66.806099000000074, 24.843016011000032],
            [66.806019000000049, 24.843028011000058],
            [66.805828000000076, 24.843119011000056],
            [66.805698000000064, 24.843222011000023],
            [66.805357000000072, 24.84340601100007],
            [66.805211000000043, 24.843511011000032],
            [66.804985000000045, 24.843583011000078],
            [66.80488100000008, 24.843611011000064],
            [66.804742000000033, 24.843653011000072],
            [66.804565000000082, 24.843638011000053],
            [66.804462000000058, 24.843626011000026],
            [66.804358000000036, 24.843644011000038],
            [66.804328000000055, 24.843689011000038],
            [66.804317000000083, 24.843704011000057],
            [66.804299000000071, 24.843719011000076],
            [66.804287000000045, 24.843734011000038],
            [66.80419500000005, 24.843773011000053],
            [66.804118000000074, 24.843858011000066],
            [66.804020000000037, 24.843861011000058],
            [66.80382000000003, 24.843961011000033],
            [66.803643000000079, 24.844107011000062],
            [66.803564000000051, 24.84421001100003],
            [66.803435000000036, 24.844269011000051],
            [66.803034000000082, 24.844394011000077],
            [66.802674000000081, 24.844556011000066],
            [66.80242700000008, 24.844646011000066],
            [66.802126000000044, 24.844745011000043],
            [66.801689000000067, 24.844907011000032],
            [66.801442000000065, 24.844977011000026],
            [66.801234000000079, 24.845090011000025],
            [66.801098000000081, 24.845051011000066],
            [66.800997000000052, 24.845002011000076],
            [66.800934000000041, 24.844969011000046],
            [66.800940000000082, 24.844964011000059],
            [66.80080600000008, 24.844987011000057],
            [66.800608000000068, 24.845034011000052],
            [66.800479000000053, 24.845073011000068],
            [66.800324000000046, 24.845151011000041],
            [66.800152000000082, 24.845187011000064],
            [66.800021000000072, 24.845194011000046],
            [66.799815000000081, 24.845234011000059],
            [66.799631000000034, 24.845266011000035],
            [66.799501000000078, 24.845328011000049],
            [66.799351000000058, 24.845471011000029],
            [66.799186000000077, 24.845441011000048],
            [66.799079000000063, 24.845397011000045],
            [66.799001000000032, 24.845344011000066],
            [66.798911000000032, 24.84530501100005],
            [66.798801000000083, 24.845349011000053],
            [66.798710000000028, 24.845321011000067],
            [66.798768000000052, 24.845276011000067],
            [66.798775000000035, 24.845246011000029],
            [66.798798000000033, 24.845205011000076],
            [66.798799000000031, 24.845159011000078],
            [66.798766000000057, 24.84511701100007],
            [66.798635000000047, 24.84508801100003],
            [66.798611000000051, 24.845123011000055],
            [66.798610000000053, 24.845169011000053],
            [66.798535000000072, 24.845168011000055],
            [66.798534000000075, 24.845224011000028],
            [66.798539000000062, 24.845265011000038],
            [66.79848000000004, 24.845382011000027],
            [66.798489000000075, 24.845485011000051],
            [66.798505000000034, 24.845546011000067],
            [66.798504000000037, 24.845592011000065],
            [66.798469000000068, 24.845612011000071],
            [66.798406000000057, 24.845615011000064],
            [66.798283000000083, 24.845505011000057],
            [66.798309000000074, 24.845608011000024],
            [66.798314000000062, 24.845685011000057],
            [66.798262000000079, 24.845749011000066],
            [66.798137000000054, 24.845771011000068],
            [66.798041000000069, 24.845773011000063],
            [66.797877000000028, 24.845798011000056],
            [66.797788000000082, 24.845760011000039],
            [66.797754000000054, 24.845693011000037],
            [66.797698000000082, 24.845640011000057],
            [66.79764700000004, 24.845623011000043],
            [66.797540000000083, 24.845630011000026],
            [66.79750000000007, 24.845593011000062],
            [66.79745500000007, 24.845617011000058],
            [66.797461000000055, 24.845668011000043],
            [66.797382000000027, 24.845686011000055],
            [66.797319000000073, 24.845619011000053],
            [66.797384000000079, 24.845585011000026],
            [66.797376000000042, 24.845519011000079],
            [66.797344000000066, 24.845482011000058],
            [66.797278000000063, 24.84545501100007],
            [66.797179000000028, 24.845483011000056],
            [66.797128000000043, 24.845491011000036],
            [66.797133000000031, 24.845395011000051],
            [66.797059000000047, 24.845382011000027],
            [66.797015000000044, 24.845366011000067],
            [66.796989000000053, 24.845304011000053],
            [66.796963000000062, 24.845267011000033],
            [66.79686300000003, 24.845218011000043],
            [66.796778000000074, 24.845195011000044],
            [66.796709000000078, 24.845193011000049],
            [66.796646000000067, 24.845196011000041],
            [66.79667100000006, 24.845156011000029],
            [66.79650300000003, 24.845197011000039],
            [66.796506000000079, 24.845136011000022],
            [66.79641300000003, 24.845046011000079],
            [66.796272000000044, 24.845118011000068],
            [66.79622100000006, 24.845112011000026],
            [66.796143000000029, 24.845058011000049],
            [66.796101000000078, 24.845118011000068],
            [66.796150000000068, 24.845176011000035],
            [66.79607100000004, 24.845292011000026],
            [66.796009000000083, 24.845402011000033],
            [66.795893000000035, 24.845449011000028],
            [66.795807000000082, 24.845477011000071],
            [66.795730000000049, 24.845424011000034],
            [66.795618000000047, 24.845360011000025],
            [66.795569000000057, 24.845322011000064],
            [66.795538000000079, 24.845393011000056],
            [66.795512000000031, 24.845468011000037],
            [66.795443000000034, 24.845496011000023],
            [66.795348000000047, 24.84544801100003],
            [66.79533900000007, 24.845382011000027],
            [66.795313000000078, 24.845316011000023],
            [66.795292000000074, 24.84526401100004],
            [66.795300000000054, 24.845188011000062],
            [66.795302000000049, 24.845132011000032],
            [66.795252000000062, 24.845095011000069],
            [66.795224000000076, 24.845058011000049],
            [66.795165000000054, 24.845139011000072],
            [66.79509900000005, 24.845234011000059],
            [66.795075000000054, 24.845279011000059],
            [66.795018000000027, 24.845278011000062],
            [66.794992000000036, 24.845206011000073],
            [66.79490700000008, 24.845163011000068],
            [66.794798000000071, 24.845181011000022],
            [66.794661000000076, 24.845147011000051],
            [66.794563000000039, 24.845140011000069],
            [66.794522000000029, 24.845191011000054],
            [66.794520000000034, 24.845262011000045],
            [66.79454700000008, 24.845349011000053],
            [66.794506000000069, 24.845424011000034],
            [66.794389000000081, 24.845538011000031],
            [66.794261000000063, 24.845642011000052],
            [66.794105000000059, 24.84572501100007],
            [66.79395100000005, 24.845767011000078],
            [66.793512000000078, 24.84574101100003],
            [66.793307000000084, 24.845740011000032],
            [66.792912000000058, 24.84569201100004],
            [66.792838000000074, 24.845644011000047],
            [66.792781000000048, 24.845622011000046],
            [66.792752000000064, 24.845576011000048],
            [66.79265300000003, 24.845607011000027],
            [66.792574000000059, 24.845604011000034],
            [66.792565000000081, 24.845550011000057],
            [66.79255900000004, 24.84550001100007],
            [66.792519000000084, 24.845476011000073],
            [66.792480000000069, 24.845442011000046],
            [66.79247300000003, 24.84541001100007],
            [66.792388000000074, 24.845381011000029],
            [66.792333000000042, 24.845342011000071],
            [66.792236000000059, 24.845302011000058],
            [66.792164000000071, 24.845274011000072],
            [66.79207800000006, 24.845266011000035],
            [66.792028000000073, 24.845214011000053],
            [66.791882000000044, 24.845189011000059],
            [66.791746000000046, 24.845244011000034],
            [66.791729000000032, 24.845161011000073],
            [66.791637000000037, 24.845136011000022],
            [66.791586000000052, 24.84508001100005],
            [66.791528000000028, 24.845020011000031],
            [66.791488000000072, 24.844993011000042],
            [66.791420000000073, 24.844987011000057],
            [66.791446000000064, 24.845060011000044],
            [66.791427000000056, 24.845149011000046],
            [66.791255000000035, 24.84512501100005],
            [66.791178000000059, 24.845141011000067],
            [66.791184000000044, 24.845055011000056],
            [66.79106500000006, 24.845004011000071],
            [66.790969000000075, 24.844944011000052],
            [66.790921000000083, 24.844921011000054],
            [66.790862000000061, 24.84485101100006],
            [66.790749000000062, 24.844793011000036],
            [66.790685000000053, 24.844838011000036],
            [66.790629000000081, 24.844879011000046],
            [66.790586000000076, 24.844809011000052],
            [66.790570000000059, 24.844772011000032],
            [66.790585000000078, 24.844722011000044],
            [66.790570000000059, 24.844696011000053],
            [66.790538000000083, 24.844684011000027],
            [66.790455000000065, 24.844703011000036],
            [66.79036700000006, 24.844725011000037],
            [66.79024000000004, 24.844735011000068],
            [66.790197000000035, 24.844708011000023],
            [66.790161000000069, 24.844639011000027],
            [66.790077000000053, 24.844578011000067],
            [66.790065000000084, 24.844494011000052],
            [66.790057000000047, 24.844439011000077],
            [66.78996200000006, 24.844403011000054],
            [66.789856000000043, 24.844381011000053],
            [66.789762000000053, 24.844389011000032],
            [66.789464000000066, 24.844146011000078],
            [66.789483000000075, 24.84405901100007],
            [66.789520000000039, 24.843939011000032],
            [66.789523000000031, 24.843861011000058],
            [66.78948100000008, 24.843783011000028],
            [66.789415000000076, 24.843696011000077],
            [66.789368000000081, 24.843632011000068],
            [66.789285000000064, 24.843593011000053],
            [66.78922300000005, 24.843636011000058],
            [66.789400000000057, 24.843895011000029],
            [66.789343000000031, 24.844037011000069],
            [66.789191000000073, 24.844000011000048],
            [66.789078000000075, 24.844044011000051],
            [66.788973000000055, 24.843593011000053],
            [66.789038000000062, 24.843430011000066],
            [66.789180000000044, 24.843430011000066],
            [66.789181000000042, 24.843400011000028],
            [66.789032000000077, 24.843314011000075],
            [66.788959000000034, 24.843323011000052],
            [66.788857000000064, 24.843567011000061],
            [66.788816000000054, 24.843570011000054],
            [66.788806000000079, 24.843477011000061],
            [66.78871300000003, 24.843485011000041],
            [66.788663000000042, 24.843562011000074],
            [66.788565000000062, 24.843577011000036],
            [66.788420000000031, 24.843650011000022],
            [66.788418000000036, 24.843735011000035],
            [66.788106000000028, 24.843932011000049],
            [66.787906000000078, 24.844011011000077],
            [66.787812000000031, 24.844023011000047],
            [66.787717000000043, 24.844005011000036],
            [66.787584000000038, 24.844024011000045],
            [66.787609000000032, 24.844142011000031],
            [66.787557000000049, 24.84424301100006],
            [66.78758700000003, 24.844335011000055],
            [66.787486000000058, 24.844369011000026],
            [66.787410000000079, 24.844312011000056],
            [66.787325000000067, 24.844317011000044],
            [66.787282000000062, 24.84437801100006],
            [66.787319000000082, 24.844441011000072],
            [66.787258000000065, 24.844450011000049],
            [66.787212000000068, 24.844549011000026],
            [66.787160000000029, 24.844609011000045],
            [66.786831000000063, 24.844610011000043],
            [66.786791000000051, 24.844512011000063],
            [66.786769000000049, 24.844356011000059],
            [66.786756000000082, 24.844290011000055],
            [66.78675400000003, 24.844235011000023],
            [66.786734000000081, 24.844224011000051],
            [66.786673000000064, 24.844248011000047],
            [66.786590000000047, 24.84419401100007],
            [66.786546000000044, 24.844277011000031],
            [66.786508000000083, 24.844323011000029],
            [66.786412000000041, 24.844320011000036],
            [66.786448000000064, 24.844183011000041],
            [66.786488000000077, 24.844081011000071],
            [66.786528000000033, 24.843986011000027],
            [66.78651300000007, 24.843930011000054],
            [66.786416000000031, 24.84392401100007],
            [66.786278000000038, 24.844060011000067],
            [66.786233000000038, 24.844048011000041],
            [66.786163000000045, 24.84392401100007],
            [66.786100000000033, 24.843874011000025],
            [66.785941000000037, 24.843892011000037],
            [66.785901000000081, 24.843839011000057],
            [66.785813000000076, 24.843789011000069],
            [66.785863000000063, 24.843704011000057],
            [66.785980000000052, 24.843711011000039],
            [66.785985000000039, 24.84369201100003],
            [66.785937000000047, 24.843653011000072],
            [66.785929000000067, 24.843608011000072],
            [66.785901000000081, 24.843569011000056],
            [66.785685000000058, 24.84355701100003],
            [66.785765000000083, 24.843408011000065],
            [66.785818000000063, 24.843293011000071],
            [66.785704000000067, 24.843245011000079],
            [66.785655000000077, 24.843251011000064],
            [66.785621000000049, 24.843311011000026],
            [66.785575000000051, 24.843359011000075],
            [66.785464000000047, 24.843333011000027],
            [66.785342000000071, 24.843348011000046],
            [66.78536900000006, 24.843304011000043],
            [66.785285000000044, 24.843239011000037],
            [66.785168000000056, 24.843180011000072],
            [66.785050000000069, 24.843146011000044],
            [66.784912000000077, 24.843113011000071],
            [66.784844000000078, 24.843073011000058],
            [66.784713000000067, 24.843051011000057],
            [66.784639000000084, 24.843060011000034],
            [66.784515000000056, 24.843065011000078],
            [66.784441000000072, 24.842976011000076],
            [66.784366000000034, 24.842910011000072],
            [66.784289000000058, 24.842882011000029],
            [66.784070000000042, 24.842890011000065],
            [66.783967000000075, 24.842914011000062],
            [66.78389100000004, 24.842971011000031],
            [66.783806000000084, 24.843062011000029],
            [66.783900000000074, 24.84318101100007],
            [66.783935000000042, 24.843253011000058],
            [66.78401000000008, 24.843318011000065],
            [66.784056000000078, 24.843402011000023],
            [66.784034000000077, 24.843449011000075],
            [66.783960000000036, 24.843577011000036],
            [66.783774000000051, 24.843776011000045],
            [66.783613000000059, 24.843927011000062],
            [66.783409000000063, 24.844095011000036],
            [66.783160000000066, 24.844268011000054],
            [66.782845000000066, 24.844428011000048],
            [66.78263400000003, 24.844506011000078],
            [66.782238000000063, 24.844473011000048],
            [66.782095000000083, 24.844354011000064],
            [66.78197300000005, 24.844321011000034],
            [66.781765000000064, 24.844244011000058],
            [66.781599000000028, 24.84419801100006],
            [66.781512000000077, 24.844196011000065],
            [66.781427000000065, 24.844164011000032],
            [66.781313000000068, 24.84411201100005],
            [66.781059000000027, 24.844093011000041],
            [66.780944000000034, 24.844079011000076],
            [66.780902000000083, 24.844227011000044],
            [66.780834000000084, 24.844307011000069],
            [66.780705000000069, 24.844366011000034],
            [66.780601000000047, 24.844422011000063],
            [66.780526000000066, 24.844494011000052],
            [66.780468000000042, 24.844558011000061],
            [66.780393000000061, 24.84461101100004],
            [66.780295000000081, 24.844655011000043],
            [66.780181000000027, 24.844659011000033],
            [66.780071000000078, 24.84466001100003],
            [66.779985000000067, 24.844679011000039],
            [66.779968000000054, 24.844726011000034],
            [66.779911000000084, 24.844750011000031],
            [66.779879000000051, 24.844680011000037],
            [66.779806000000065, 24.844663011000023],
            [66.779736000000071, 24.844701011000041],
            [66.779648000000066, 24.844731011000079],
            [66.779172000000074, 24.844498011000042],
            [66.779032000000029, 24.844420011000068],
            [66.778962000000035, 24.84434101100004],
            [66.778917000000035, 24.844225011000049],
            [66.77891900000003, 24.844141011000033],
            [66.778988000000084, 24.844033011000079],
            [66.77900900000003, 24.843875011000023],
            [66.779008000000033, 24.843644011000038],
            [66.778987000000029, 24.843523011000059],
            [66.778969000000075, 24.843440011000041],
            [66.779035000000079, 24.843311011000026],
            [66.779040000000066, 24.843273011000065],
            [66.779008000000033, 24.843239011000037],
            [66.778986000000032, 24.843151011000032],
            [66.779008000000033, 24.843107011000029],
            [66.778993000000071, 24.843065011000078],
            [66.778969000000075, 24.843034011000043],
            [66.778900000000078, 24.843002011000067],
            [66.778805000000034, 24.843011011000044],
            [66.778764000000081, 24.843014011000037],
            [66.778663000000051, 24.842947011000035],
            [66.778557000000035, 24.842903011000033],
            [66.77855900000003, 24.84298601100005],
            [66.778558000000032, 24.843031011000051],
            [66.778548000000058, 24.843088011000077],
            [66.778504000000055, 24.843019011000024],
            [66.778452000000073, 24.842987011000048],
            [66.778402000000028, 24.843028011000058],
            [66.778490000000033, 24.843106011000032],
            [66.778594000000055, 24.843213011000046],
            [66.778560000000027, 24.843258011000046],
            [66.778511000000037, 24.843298011000059],
            [66.778481000000056, 24.843327011000042],
            [66.778444000000036, 24.843345011000054],
            [66.778372000000047, 24.843340011000066],
            [66.778347000000053, 24.84337301100004],
            [66.778290000000084, 24.843353011000033],
            [66.778240000000039, 24.843411011000057],
            [66.77802900000006, 24.843376011000032],
            [66.777952000000028, 24.843340011000066],
            [66.777870000000064, 24.843184011000062],
            [66.777754000000073, 24.84308001100004],
            [66.777701000000036, 24.843032011000048],
            [66.777633000000037, 24.843031011000051],
            [66.777553000000069, 24.843016011000032],
            [66.777450000000044, 24.843038011000033],
            [66.777426000000048, 24.843008011000052],
            [66.777382000000046, 24.842961011000057],
            [66.777321000000029, 24.842968011000039],
            [66.77729000000005, 24.842996011000025],
            [66.777261000000067, 24.843012011000042],
            [66.777236000000073, 24.843048011000064],
            [66.777174000000059, 24.843002011000067],
            [66.777122000000077, 24.842955011000072],
            [66.777091000000041, 24.842877011000041],
            [66.777048000000036, 24.842854011000043],
            [66.776989000000071, 24.84289601100005],
            [66.777001000000041, 24.842954011000074],
            [66.777033000000074, 24.842998011000077],
            [66.777092000000039, 24.843036011000038],
            [66.777518000000043, 24.843172011000036],
            [66.777502000000084, 24.843211011000051],
            [66.777368000000081, 24.843176011000025],
            [66.777314000000047, 24.843161011000063],
            [66.777146000000073, 24.843133011000077],
            [66.777019000000053, 24.843127011000036],
            [66.776997000000051, 24.843192011000042],
            [66.776996000000054, 24.843216011000038],
            [66.776968000000068, 24.843249011000069],
            [66.776933000000042, 24.843266011000026],
            [66.776907000000051, 24.843284011000037],
            [66.776851000000079, 24.843304011000043],
            [66.776788000000067, 24.843313011000077],
            [66.77670100000006, 24.843266011000026],
            [66.776651000000072, 24.843223011000077],
            [66.776621000000034, 24.84318901100005],
            [66.776567000000057, 24.843161011000063],
            [66.776534000000083, 24.843139011000062],
            [66.776514000000077, 24.843134011000075],
            [66.776498000000061, 24.843175011000028],
            [66.776547000000051, 24.843254011000056],
            [66.776621000000034, 24.843345011000054],
            [66.776678000000061, 24.843445011000028],
            [66.776552000000038, 24.843434011000056],
            [66.776523000000054, 24.843371011000045],
            [66.776445000000081, 24.843301011000051],
            [66.776437000000044, 24.843209011000056],
            [66.776406000000065, 24.843188011000052],
            [66.776364000000058, 24.843187011000055],
            [66.776402000000076, 24.843323011000052],
            [66.77637500000003, 24.843343011000059],
            [66.776315000000068, 24.843198011000027],
            [66.77627700000005, 24.843221011000026],
            [66.776222000000075, 24.843216011000038],
            [66.776219000000083, 24.843326011000045],
            [66.776180000000068, 24.843309011000031],
            [66.776173000000028, 24.843231011000057],
            [66.776135000000068, 24.843167011000048],
            [66.776120000000049, 24.843120011000053],
            [66.77604400000007, 24.843063011000027],
            [66.775986000000046, 24.842991011000038],
            [66.775948000000028, 24.842948011000033],
            [66.775921000000039, 24.842895011000053],
            [66.775891000000058, 24.842868011000064],
            [66.775817000000075, 24.842845011000065],
            [66.775776000000064, 24.842800011000065],
            [66.77576300000004, 24.842750011000078],
            [66.775894000000051, 24.842647011000054],
            [66.77601100000004, 24.842523011000026],
            [66.776067000000069, 24.842477011000028],
            [66.776148000000035, 24.842414011000074],
            [66.776170000000036, 24.842357011000047],
            [66.776312000000075, 24.842290011000046],
            [66.776305000000036, 24.84224701100004],
            [66.77613400000007, 24.842127011000059],
            [66.77595400000007, 24.842031011000074],
            [66.775798000000066, 24.841963011000075],
            [66.775604000000044, 24.841880011000057],
            [66.775502000000074, 24.841888011000037],
            [66.775424000000044, 24.84179701100004],
            [66.775227000000029, 24.841778011000031],
            [66.775185000000079, 24.841841011000042],
            [66.774929000000043, 24.841736011000023],
            [66.774765000000059, 24.841663011000037],
            [66.774477000000047, 24.84156401100006],
            [66.774475000000052, 24.841601011000023],
            [66.77470900000003, 24.841674011000066],
            [66.77470900000003, 24.841762011000071],
            [66.77478700000006, 24.841764011000066],
            [66.77483600000005, 24.841864011000041],
            [66.774905000000047, 24.841890011000032],
            [66.774935000000028, 24.841923011000063],
            [66.774903000000052, 24.841940011000077],
            [66.77488100000005, 24.841971011000055],
            [66.774873000000071, 24.842027011000027],
            [66.77488900000003, 24.842088011000044],
            [66.774946000000057, 24.842171011000062],
            [66.774997000000042, 24.842199011000048],
            [66.775052000000073, 24.842263011000057],
            [66.775040000000047, 24.842287011000053],
            [66.774916000000076, 24.842291011000043],
            [66.774901000000057, 24.842369011000073],
            [66.77488500000004, 24.842416011000068],
            [66.774872000000073, 24.842455011000027],
            [66.774950000000047, 24.842607011000041],
            [66.774145000000033, 24.843075011000053],
            [66.773842000000059, 24.843258011000046],
            [66.773032000000057, 24.843677011000068],
            [66.772754000000077, 24.843821011000045],
            [66.771832000000074, 24.844290011000055],
            [66.77108000000004, 24.844663011000023],
            [66.769733000000031, 24.845463011000049],
            [66.768736000000047, 24.845988011000031],
            [66.768040000000042, 24.846397011000079],
            [66.766387000000066, 24.84748101100007],
            [66.763781000000051, 24.848947011000064],
            [66.761775000000057, 24.849829011000054],
            [66.760992000000044, 24.850132011000028],
            [66.75967200000008, 24.850581011000031],
            [66.757655000000057, 24.851119011000037],
            [66.756237000000056, 24.851457011000036],
            [66.755607000000055, 24.851599011000076],
            [66.753349000000071, 24.851997011000037],
            [66.752807000000075, 24.851970011000049],
            [66.75282100000004, 24.852060011000049],
            [66.752752000000044, 24.852109011000039],
            [66.752501000000052, 24.852178011000035],
            [66.752325000000042, 24.85222501100003],
            [66.752140000000054, 24.852253011000073],
            [66.751940000000047, 24.852251011000078],
            [66.751753000000065, 24.852248011000029],
            [66.751634000000081, 24.852248011000029],
            [66.75156000000004, 24.852195011000049],
            [66.75160500000004, 24.85212701100005],
            [66.751645000000053, 24.852068011000028],
            [66.751712000000055, 24.852010011000061],
            [66.751642000000061, 24.851909011000032],
            [66.751581000000044, 24.851930011000036],
            [66.751529000000062, 24.851919011000064],
            [66.751475000000028, 24.851922011000056],
            [66.751345000000072, 24.85198401100007],
            [66.751173000000051, 24.852053011000066],
            [66.750896000000068, 24.852145011000061],
            [66.750840000000039, 24.852106011000046],
            [66.750885000000039, 24.851936011000078],
            [66.750840000000039, 24.851855011000055],
            [66.750741000000062, 24.851785011000061],
            [66.75059600000003, 24.851670011000067],
            [66.750488000000075, 24.851561011000058],
            [66.75036300000005, 24.851565011000048],
            [66.750259000000028, 24.85151901100005],
            [66.750245000000064, 24.851432011000043],
            [66.750241000000074, 24.851360011000054],
            [66.750195000000076, 24.851273011000046],
            [66.750150000000076, 24.851122011000029],
            [66.750031000000035, 24.851277011000036],
            [66.750046000000054, 24.851383011000053],
            [66.74993900000004, 24.851508011000078],
            [66.749840000000063, 24.851599011000076],
            [66.749746000000073, 24.851732011000024],
            [66.74966500000005, 24.851831011000058],
            [66.749599000000046, 24.851926011000046],
            [66.749548000000061, 24.852016011000046],
            [66.749482000000057, 24.85208201100005],
            [66.749412000000063, 24.852129011000045],
            [66.749310000000037, 24.852139011000077],
            [66.749235000000056, 24.852163011000073],
            [66.749153000000035, 24.852156011000034],
            [66.74906500000003, 24.852130011000042],
            [66.748966000000053, 24.852091011000027],
            [66.74891400000007, 24.852039011000045],
            [66.748846000000071, 24.851950011000042],
            [66.748794000000032, 24.851867011000024],
            [66.748813000000041, 24.851749011000038],
            [66.748841000000084, 24.851650011000061],
            [66.748768000000041, 24.851546011000039],
            [66.748723000000041, 24.851424011000063],
            [66.748712000000069, 24.851350011000022],
            [66.74868900000007, 24.851293011000053],
            [66.748589000000038, 24.851261011000076],
            [66.748527000000081, 24.851225011000054],
            [66.748534000000063, 24.851119011000037],
            [66.748576000000071, 24.851060011000072],
            [66.748583000000053, 24.851032011000029],
            [66.748488000000066, 24.851000011000053],
            [66.748427000000049, 24.851025011000047],
            [66.748315000000048, 24.851025011000047],
            [66.748250000000041, 24.851041011000063],
            [66.748284000000069, 24.851107011000067],
            [66.74818700000003, 24.851131011000064],
            [66.748116000000039, 24.851170011000079],
            [66.747930000000053, 24.851101011000026],
            [66.747883000000058, 24.851104011000075],
            [66.74775900000003, 24.851066011000057],
            [66.747654000000068, 24.851071011000045],
            [66.747531000000038, 24.851015011000072],
            [66.747405000000072, 24.85092601100007],
            [66.747318000000064, 24.850895011000034],
            [66.747243000000083, 24.850680011000065],
            [66.747131000000081, 24.850564011000074],
            [66.747010000000046, 24.85046101100005],
            [66.746782000000053, 24.850323011000057],
            [66.746057000000064, 24.849753011000075],
            [66.745818000000043, 24.849559011000053],
            [66.74563100000006, 24.849431011000036],
            [66.745469000000071, 24.849321011000029],
            [66.745437000000038, 24.849242011000058],
            [66.745420000000081, 24.849141011000029],
            [66.745397000000082, 24.849048011000036],
            [66.745369000000039, 24.848986011000022],
            [66.74535000000003, 24.84893401100004],
            [66.745295000000056, 24.848919011000078],
            [66.745239000000083, 24.848908011000049],
            [66.745169000000033, 24.848873011000023],
            [66.745130000000074, 24.848838011000055],
            [66.745094000000051, 24.84879101100006],
            [66.74506400000007, 24.848735011000031],
            [66.745065000000068, 24.848679011000058],
            [66.744969000000083, 24.84860701100007],
            [66.744927000000075, 24.848559011000077],
            [66.744864000000064, 24.848520011000062],
            [66.744805000000042, 24.848480011000049],
            [66.744774000000064, 24.848428011000067],
            [66.744690000000048, 24.848378011000023],
            [66.74460700000003, 24.84836701100005],
            [66.744578000000047, 24.848342011000057],
            [66.744535000000042, 24.848256011000046],
            [66.74446800000004, 24.848137011000063],
            [66.744377000000043, 24.848074011000051],
            [66.744281000000058, 24.848010011000042],
            [66.744154000000037, 24.847937011000056],
            [66.744219000000044, 24.848108011000079],
            [66.744277000000068, 24.848199011000077],
            [66.744273000000078, 24.848278011000048],
            [66.744260000000054, 24.848334011000077],
            [66.744226000000083, 24.84836701100005],
            [66.744269000000031, 24.848419011000033],
            [66.744332000000043, 24.848477011000057],
            [66.744478000000072, 24.848644011000033],
            [66.744452000000081, 24.848768011000061],
            [66.744390000000067, 24.848857011000064],
            [66.744249000000082, 24.848953011000049],
            [66.74406300000004, 24.849039011000059],
            [66.74400200000008, 24.849055011000075],
            [66.743881000000044, 24.84906501100005],
            [66.743757000000073, 24.849035011000069],
            [66.743644000000074, 24.848964011000078],
            [66.743433000000039, 24.848830011000075],
            [66.743201000000056, 24.848663011000042],
            [66.742972000000066, 24.848475011000062],
            [66.742874000000029, 24.848361011000065],
            [66.742853000000082, 24.848293011000067],
            [66.742860000000064, 24.848205011000061],
            [66.742883000000063, 24.84817901100007],
            [66.742922000000078, 24.848159011000064],
            [66.742987000000028, 24.848144011000045],
            [66.743050000000039, 24.848102011000037],
            [66.74308700000006, 24.848030011000048],
            [66.743088000000057, 24.847929011000076],
            [66.743010000000083, 24.847830011000042],
            [66.742875000000083, 24.847816011000077],
            [66.742972000000066, 24.847773011000072],
            [66.743032000000028, 24.847733011000059],
            [66.743048000000044, 24.847645011000054],
            [66.743074000000036, 24.847576011000058],
            [66.743068000000051, 24.847487011000055],
            [66.743026000000043, 24.847393011000065],
            [66.742907000000059, 24.847357011000042],
            [66.742787000000078, 24.847349011000063],
            [66.74262600000003, 24.847330011000054],
            [66.742506000000049, 24.847341011000026],
            [66.742377000000033, 24.847347011000068],
            [66.742219000000034, 24.847393011000065],
            [66.74214500000005, 24.847433011000078],
            [66.742036000000041, 24.847453011000027],
            [66.741909000000078, 24.847431011000026],
            [66.741779000000065, 24.84739501100006],
            [66.741644000000065, 24.847345011000073],
            [66.741545000000031, 24.847273011000027],
            [66.741471000000047, 24.847202011000036],
            [66.741433000000029, 24.847103011000058],
            [66.741477000000032, 24.847016011000051],
            [66.741504000000077, 24.846955011000034],
            [66.74155200000007, 24.846905011000047],
            [66.741535000000056, 24.846801011000025],
            [66.741522000000032, 24.846725011000046],
            [66.741459000000077, 24.84661501100004],
            [66.741347000000076, 24.846575011000027],
            [66.741225000000043, 24.846563011000057],
            [66.741168000000073, 24.846577011000079],
            [66.741078000000073, 24.846730011000034],
            [66.741015000000061, 24.846861011000044],
            [66.740914000000032, 24.846882011000048],
            [66.740759000000082, 24.846856011000057],
            [66.740527000000043, 24.846810011000059],
            [66.740396000000032, 24.846739011000068],
            [66.740221000000076, 24.846612011000047],
            [66.740100000000041, 24.846486011000025],
            [66.740004000000056, 24.846388011000045],
            [66.739955000000066, 24.846275011000046],
            [66.739954000000068, 24.846176011000068],
            [66.739987000000042, 24.846029011000041],
            [66.740005000000053, 24.845887011000059],
            [66.739874000000043, 24.845861011000068],
            [66.739745000000028, 24.845828011000037],
            [66.739673000000039, 24.84581501100007],
            [66.73965400000003, 24.84577001100007],
            [66.739655000000027, 24.845706011000061],
            [66.739600000000053, 24.845630011000026],
            [66.739439000000061, 24.845567011000071],
            [66.739355000000046, 24.845524011000066],
            [66.73932700000006, 24.845470011000032],
            [66.739194000000055, 24.845426011000029],
            [66.739164000000073, 24.845373011000049],
            [66.739105000000052, 24.84531301100003],
            [66.739064000000042, 24.845243011000036],
            [66.739032000000066, 24.845163011000068],
            [66.738989000000061, 24.845067011000026],
            [66.739009000000067, 24.845021011000028],
            [66.739097000000072, 24.844993011000042],
            [66.739177000000041, 24.844954011000027],
            [66.739215000000058, 24.84490801100003],
            [66.739247000000034, 24.844840011000031],
            [66.739281000000062, 24.844811011000047],
            [66.739354000000048, 24.844771011000034],
            [66.739419000000055, 24.844709011000077],
            [66.739416000000062, 24.844652011000051],
            [66.739351000000056, 24.844616011000028],
            [66.739306000000056, 24.844569011000033],
            [66.739204000000029, 24.844502011000031],
            [66.73918400000008, 24.844478011000035],
            [66.739130000000046, 24.844473011000048],
            [66.739068000000032, 24.844452011000044],
            [66.739019000000042, 24.844409011000039],
            [66.738955000000033, 24.844350011000074],
            [66.738863000000038, 24.844340011000043],
            [66.738755000000083, 24.844322011000031],
            [66.738656000000049, 24.844293011000048],
            [66.738589000000047, 24.844287011000063],
            [66.738593000000037, 24.84437401100007],
            [66.738565000000051, 24.844436011000028],
            [66.738564000000054, 24.844541011000047],
            [66.738590000000045, 24.844620011000075],
            [66.738614000000041, 24.844724011000039],
            [66.738685000000032, 24.844801011000072],
            [66.738754000000029, 24.844852011000057],
            [66.738767000000053, 24.844918011000061],
            [66.738699000000054, 24.844929011000033],
            [66.738630000000057, 24.844972011000038],
            [66.738548000000037, 24.844965011000056],
            [66.738463000000081, 24.844933011000023],
            [66.738410000000044, 24.844912011000076],
            [66.73835500000007, 24.844863011000029],
            [66.738313000000062, 24.844797011000026],
            [66.738315000000057, 24.844833011000048],
            [66.738272000000052, 24.844889011000078],
            [66.738102000000083, 24.844850011000062],
            [66.738018000000068, 24.844804011000065],
            [66.737960000000044, 24.844722011000044],
            [66.737827000000038, 24.844645011000068],
            [66.737636000000066, 24.844598011000073],
            [66.737578000000042, 24.844554011000071],
            [66.737545000000068, 24.844493011000054],
            [66.737509000000045, 24.844429011000045],
            [66.737471000000028, 24.844420011000068],
            [66.737453000000073, 24.844485011000074],
            [66.737408000000073, 24.844551011000078],
            [66.737293000000079, 24.844585011000049],
            [66.737234000000058, 24.844581011000059],
            [66.737181000000078, 24.844548011000029],
            [66.73715100000004, 24.844511011000066],
            [66.737138000000073, 24.844440011000074],
            [66.737075000000061, 24.844364011000039],
            [66.736900000000048, 24.844253011000035],
            [66.736416000000077, 24.843944011000076],
            [66.736316000000045, 24.843941011000027],
            [66.73626100000007, 24.843893011000034],
            [66.736231000000032, 24.843799011000044],
            [66.736147000000074, 24.843666011000039],
            [66.736060000000066, 24.84363901100005],
            [66.735913000000039, 24.843614011000057],
            [66.735790000000065, 24.843571011000051],
            [66.735602000000029, 24.843442011000036],
            [66.735398000000032, 24.843315011000072],
            [66.73520300000007, 24.843269011000075],
            [66.734839000000079, 24.843176011000025],
            [66.734724000000028, 24.843099011000049],
            [66.734651000000042, 24.843031011000051],
            [66.73460300000005, 24.842929011000024],
            [66.734560000000045, 24.842825011000059],
            [66.734466000000054, 24.842670011000052],
            [66.733032000000037, 24.841923011000063],
            [66.732778000000053, 24.841828011000075],
            [66.732642000000055, 24.841816011000049],
            [66.732404000000031, 24.841781011000023],
            [66.732252000000074, 24.84175601100003],
            [66.732238000000052, 24.841771011000048],
            [66.732091000000082, 24.841787011000065],
            [66.731906000000038, 24.841816011000049],
            [66.731912000000079, 24.841773011000043],
            [66.731952000000035, 24.841713011000024],
            [66.731916000000069, 24.841672011000071],
            [66.731760000000065, 24.841647011000077],
            [66.731667000000073, 24.841612011000052],
            [66.731528000000083, 24.841559011000072],
            [66.731489000000067, 24.841526011000042],
            [66.731347000000028, 24.841477011000052],
            [66.731322000000034, 24.841460011000038],
            [66.731322000000034, 24.841457011000045],
            [66.731176000000062, 24.841386011000054],
            [66.731097000000034, 24.841263011000024],
            [66.730900000000076, 24.841128011000023],
            [66.730766000000074, 24.841017011000076],
            [66.730627000000084, 24.840833011000029],
            [66.730499000000066, 24.84052801100006],
            [66.730444000000034, 24.840370011000061],
            [66.730411000000061, 24.840244011000038],
            [66.730460000000051, 24.84007801100006],
            [66.730512000000033, 24.839991011000052],
            [66.730594000000053, 24.839908011000034],
            [66.730692000000033, 24.83983401100005],
            [66.730808000000081, 24.839787011000055],
            [66.73092100000008, 24.839803011000072],
            [66.731038000000069, 24.839866011000026],
            [66.731090000000052, 24.839828011000066],
            [66.731017000000065, 24.839760011000067],
            [66.730962000000034, 24.83970301100004],
            [66.731002000000046, 24.839659011000037],
            [66.731083000000069, 24.839665011000022],
            [66.731319000000042, 24.839621011000077],
            [66.731449000000055, 24.839555011000073],
            [66.73143900000008, 24.83947001100006],
            [66.731390000000033, 24.839436011000032],
            [66.731255000000033, 24.839352011000074],
            [66.731103000000076, 24.839226011000051],
            [66.730981000000043, 24.839055011000028],
            [66.730856000000074, 24.838748011000064],
            [66.730755000000045, 24.838518011000076],
            [66.730626000000029, 24.838243011000031],
            [66.730350000000044, 24.837888011000075],
            [66.730183000000068, 24.837807011000052],
            [66.730065000000081, 24.83771801100005],
            [66.729968000000042, 24.837775011000076],
            [66.729865000000075, 24.837747011000033],
            [66.729775000000075, 24.837668011000062],
            [66.729790000000037, 24.837575011000069],
            [66.729722000000038, 24.837607011000046],
            [66.729647000000057, 24.837596011000073],
            [66.729462000000069, 24.83752301100003],
            [66.729250000000036, 24.837424011000053],
            [66.72879800000004, 24.837135011000044],
            [66.728003000000058, 24.836589011000058],
            [66.72689900000006, 24.835933011000066],
            [66.726366000000041, 24.835594011000069],
            [66.72588200000007, 24.835280011000066],
            [66.725952000000063, 24.835507011000061],
            [66.726038000000074, 24.835707011000068],
            [66.726154000000065, 24.835834011000031],
            [66.726373000000081, 24.836026011000058],
            [66.726320000000044, 24.836392011000044],
            [66.725891000000047, 24.836939011000027],
            [66.725396000000046, 24.837348011000074],
            [66.72485000000006, 24.83766501100007],
            [66.724395000000072, 24.837978011000075],
            [66.724012000000073, 24.838139011000067],
            [66.723620000000039, 24.837881011000036],
            [66.723275000000058, 24.837700011000038],
            [66.722912000000065, 24.837518011000043],
            [66.722517000000039, 24.837662011000077],
            [66.722610000000032, 24.837851011000055],
            [66.722353000000055, 24.837953011000025],
            [66.721961000000078, 24.838050011000064],
            [66.721779000000083, 24.837967011000046],
            [66.721234000000038, 24.838138011000069],
            [66.720838000000072, 24.838216011000043],
            [66.719692000000066, 24.838349011000048],
            [66.719205000000045, 24.838194011000041],
            [66.71919500000007, 24.837836011000036],
            [66.718762000000083, 24.838230011000064],
            [66.718078000000048, 24.837833011000043],
            [66.717266000000052, 24.837361011000041],
            [66.716893000000027, 24.836902011000063],
            [66.716735000000028, 24.836425011000074],
            [66.715346000000068, 24.835626011000045],
            [66.715476000000081, 24.836152011000024],
            [66.715092000000084, 24.836522011000056],
            [66.714388000000042, 24.837002011000038],
            [66.713928000000067, 24.83682501100003],
            [66.71205800000007, 24.836097011000049],
            [66.711186000000055, 24.835383011000033],
            [66.709362000000056, 24.834297011000046],
            [66.707187000000033, 24.832837011000038],
            [66.705756000000065, 24.832253011000034],
            [66.704156000000069, 24.831436011000051],
            [66.704172000000028, 24.831697011000074],
            [66.704306000000031, 24.832062011000062],
            [66.70417400000008, 24.832470011000055],
            [66.703824000000054, 24.832723011000041],
            [66.703324000000066, 24.832784011000058],
            [66.702738000000068, 24.832623011000067],
            [66.702183000000048, 24.832389011000032],
            [66.701631000000077, 24.832105011000067],
            [66.701372000000049, 24.832074011000032],
            [66.701429000000076, 24.832761011000059],
            [66.700934000000075, 24.832797011000025],
            [66.700141000000031, 24.832631011000046],
            [66.699538000000075, 24.832299011000032],
            [66.698356000000047, 24.832118011000034],
            [66.697947000000056, 24.831966011000077],
            [66.697423000000072, 24.832139011000038],
            [66.696503000000064, 24.831803011000034],
            [66.695549000000028, 24.831681011000057],
            [66.693503000000078, 24.831671011000026],
            [66.692789000000062, 24.83126401100003],
            [66.691990000000033, 24.831980011000041],
            [66.689809000000082, 24.830488011000057],
            [66.689502000000061, 24.830695011000046],
            [66.688466000000062, 24.830124011000066],
            [66.688032000000078, 24.830756011000062],
            [66.687297000000058, 24.831274011000062],
            [66.682603000000029, 24.828554011000051],
            [66.682112000000075, 24.828709011000058],
            [66.676736000000062, 24.826572011000053],
            [66.674352577000036, 24.826151250000066],
            [66.674354702000073, 24.826113317000079],
            [66.67435448100008, 24.826113264000071],
            [66.674354702000073, 24.826109306000035],
            [66.673990577000041, 24.826045025000042],
            [66.668897998000034, 24.825146000000075],
            [66.665811998000038, 24.825315000000046],
            [66.664544998000054, 24.825977000000023],
            [66.664202998000064, 24.827319000000045],
            [66.659779998000033, 24.831753000000049],
            [66.657396998000081, 24.83372600000007],
            [66.656274998000072, 24.835470000000043],
            [66.654446998000083, 24.836705000000052],
            [66.654360998000072, 24.837832000000049],
            [66.654830998000079, 24.83822200000003],
            [66.65997599800005, 24.838765000000024],
            [66.66093599800007, 24.840309000000047],
            [66.660880998000039, 24.844624000000067],
            [66.659426998000072, 24.848888000000045],
            [66.658394998000063, 24.852517000000034],
            [66.659282998000037, 24.855617000000052],
            [66.663430998000081, 24.859225000000038],
            [66.668618998000056, 24.862385000000074],
            [66.671822998000039, 24.865598000000034],
            [66.672810696000056, 24.866632381000045],
            [66.672875332000046, 24.866927326000052],
            [66.674293000000034, 24.868412000000035],
            [66.675935000000038, 24.871377000000052],
            [66.679491000000041, 24.875709000000029],
            [66.682623000000035, 24.87841700000007],
            [66.684546000000068, 24.879413000000056],
            [66.685167000000035, 24.880678000000046],
            [66.687285000000031, 24.880007000000035],
            [66.688739123000062, 24.879903481000042],
            [66.689378000000033, 24.87985800000007],
            [66.689642368000079, 24.879821286000038],
            [66.691243000000043, 24.879599000000042],
            [66.693212000000074, 24.879659000000061],
            [66.693182000000036, 24.880498000000046],
            [66.693272000000036, 24.881235000000061],
            [66.693454627000051, 24.882350947000077],
            [66.69473266600005, 24.882167816000049],
            [66.699516949000042, 24.880440158000056],
            [66.703109741000048, 24.879142761000026],
            [66.71176147500006, 24.880094528000029],
            [66.713717288000055, 24.882324170000061],
            [66.717403231000048, 24.88652617200006],
            [66.721992493000073, 24.891757965000068],
            [66.721420288000047, 24.894243240000037],
            [66.716331984000078, 24.889801361000025],
            [66.711019840000063, 24.889176403000079],
            [66.705707697000037, 24.889488882000023],
            [66.698463440000069, 24.890663147000055],
            [66.692731016000039, 24.890736129000061],
            [66.692468000000076, 24.893737000000044],
            [66.692592170000069, 24.893779185000028],
            [66.69259100000005, 24.893783000000042],
            [66.691210622000028, 24.897204055000032],
            [66.697241000000076, 24.903500000000065],
            [66.702034658000059, 24.905896678000033],
            [66.704595142000073, 24.907176839000044],
            [66.707233212000062, 24.908495791000064],
            [66.70886259200006, 24.909310430000062],
            [66.710866387000067, 24.910312264000027],
            [66.711035135000031, 24.910396633000062],
            [66.717319935000035, 24.913538835000054],
            [66.720947155000033, 24.915352331000065],
            [66.724458224000045, 24.917107755000075],
            [66.728997000000049, 24.919377000000054],
            [66.73391687000003, 24.923165157000028],
            [66.737832167000079, 24.92617982400003],
            [66.755688918000033, 24.939929008000036],
            [66.75639091000005, 24.940449960000024],
            [66.768267630000082, 24.953125115000034],
            [66.768699705000074, 24.953393290000065],
            [66.769680797000035, 24.953977274000067],
            [66.77669091000007, 24.958149960000071],
            [66.781336421000049, 24.961097456000061],
            [66.781385240000077, 24.961128432000066],
            [66.781540318000054, 24.96122682500004],
            [66.79110541700004, 24.967295716000024],
            [66.79115911100007, 24.967329784000071],
            [66.79576482300007, 24.969010353000044],
            [66.796623226000065, 24.969321966000052],
            [66.813625469000044, 24.975565528000061],
            [66.820495605000076, 24.978088379000042],
            [66.828209345000062, 24.982213087000048],
            [66.836090408000075, 24.986427266000078],
            [66.838073730000076, 24.987487793000071],
            [66.858332895000046, 24.996765639000046],
            [66.858311254000057, 24.996670876000053],
            [66.877692903000082, 25.005515185000036],
            [66.878690910000046, 25.005949960000066],
            [66.89395378200004, 25.007977399000026],
            [66.893953915000054, 25.00797751500005],
            [66.893995007000058, 25.007982875000039],
            [66.903216358000066, 25.009185715000058],
            [66.91422392000004, 25.010621550000053],
            [66.915040000000033, 25.010728000000029],
            [66.915759807000029, 25.011276412000029],
            [66.918099254000083, 25.013058808000039],
            [66.925502216000041, 25.018699032000029],
            [66.925578796000082, 25.018757316000062],
            [66.928766085000063, 25.021183094000037],
            [66.931685009000034, 25.023404624000023],
            [66.932307309000066, 25.022575026000027],
            [66.933145423000042, 25.021700472000077],
            [66.933728459000065, 25.021481834000042],
            [66.934566573000041, 25.020898798000076],
            [66.935222488000079, 25.020206442000074],
            [66.935805524000045, 25.019113250000032],
            [66.936206361000075, 25.018311576000031],
            [66.93573264500003, 25.016380269000024],
            [66.934930970000039, 25.01295493300006],
            [66.933910657000069, 25.008072007000067],
            [66.933108983000068, 25.004938189000029],
            [66.932453068000029, 25.001330654000071],
            [66.932125110000072, 24.999873064000042],
            [66.932052230000068, 24.999144269000055],
            [66.932489507000071, 24.997686680000072],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "150",
      properties: { name: "Malir Cantonment", count: 2 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.23341477200006, 24.935076906000063],
            [67.231415241000036, 24.933363023000027],
            [67.230083526000044, 24.932221553000034],
            [67.227942011000039, 24.926034955000034],
            [67.224848712000039, 24.919134518000078],
            [67.222945143000061, 24.912709973000062],
            [67.219244794000076, 24.910463179000033],
            [67.21920300000005, 24.91045900000006],
            [67.218953000000056, 24.910397000000046],
            [67.218775000000051, 24.910363000000075],
            [67.218652000000077, 24.910339000000079],
            [67.217612000000031, 24.910086000000035],
            [67.217052000000081, 24.909956000000022],
            [67.216519368000036, 24.909808548000058],
            [67.216344000000049, 24.909760000000063],
            [67.216192000000035, 24.909715000000062],
            [67.216154653000046, 24.909703909000029],
            [67.21537059700006, 24.909471068000073],
            [67.215367000000072, 24.909470000000056],
            [67.214978000000031, 24.909373000000073],
            [67.214965179000046, 24.909368491000066],
            [67.214323789000048, 24.909402248000049],
            [67.211593120000032, 24.909545968000032],
            [67.20832136100006, 24.909545968000032],
            [67.205049602000031, 24.910140833000071],
            [67.20296757400007, 24.90895110200006],
            [67.202670141000056, 24.907761372000039],
            [67.203265007000084, 24.905381911000063],
            [67.20296757400007, 24.904192180000052],
            [67.199418945000048, 24.903403596000032],
            [67.19761378700008, 24.903002450000031],
            [67.196862702000033, 24.904075429000045],
            [67.196849495000038, 24.90409429500005],
            [67.196736136000084, 24.904256237000027],
            [67.196613973000069, 24.904430755000078],
            [67.195531758000072, 24.905976776000045],
            [67.197911219000048, 24.908653670000035],
            [67.198506085000076, 24.912222861000032],
            [67.193747163000069, 24.91162799600005],
            [67.188095943000064, 24.911330563000035],
            [67.184556767000061, 24.911330563000035],
            [67.184728000000064, 24.911783000000071],
            [67.184909000000061, 24.912210000000073],
            [67.184979000000055, 24.912289000000044],
            [67.185117000000048, 24.912323000000072],
            [67.185235000000034, 24.912367000000074],
            [67.187365000000057, 24.912327000000062],
            [67.18916500000006, 24.912298000000078],
            [67.191882000000078, 24.912234000000069],
            [67.191883000000075, 24.912278000000072],
            [67.191851000000042, 24.91727700000007],
            [67.187783918000036, 24.917275131000054],
            [67.188120000000083, 24.920606000000078],
            [67.188055000000077, 24.92062500000003],
            [67.188003000000037, 24.920657000000062],
            [67.187984000000029, 24.920673000000079],
            [67.187958000000037, 24.92070300000006],
            [67.187927000000059, 24.92075600000004],
            [67.18790800000005, 24.920819000000051],
            [67.187905000000058, 24.920849000000032],
            [67.187906000000055, 24.920880000000068],
            [67.187926000000061, 24.920961000000034],
            [67.188132000000053, 24.921539000000053],
            [67.188184000000035, 24.921689000000072],
            [67.188216000000068, 24.921799000000078],
            [67.188239000000067, 24.921934000000078],
            [67.188249000000042, 24.922011000000055],
            [67.188252000000034, 24.922827000000041],
            [67.188254000000029, 24.924946000000034],
            [67.188254000000029, 24.925801000000035],
            [67.188256000000081, 24.927074000000061],
            [67.188256000000081, 24.927901000000077],
            [67.188257000000078, 24.928446000000065],
            [67.188258000000076, 24.929016000000047],
            [67.188258000000076, 24.929103000000055],
            [67.188259000000073, 24.92984400000006],
            [67.188259000000073, 24.930794000000049],
            [67.188259000000073, 24.931247000000042],
            [67.188261000000068, 24.931410000000028],
            [67.188271000000043, 24.931456000000026],
            [67.188282000000072, 24.931494000000043],
            [67.188298000000032, 24.931540000000041],
            [67.188350000000071, 24.93164500000006],
            [67.18841800000007, 24.931763000000046],
            [67.188439000000074, 24.931831000000045],
            [67.188447000000053, 24.931905000000029],
            [67.188449000000048, 24.93201700000003],
            [67.188423363000084, 24.932085163000067],
            [67.188339123000048, 24.932091845000059],
            [67.188245360000053, 24.932101715000044],
            [67.186503347000041, 24.932444689000079],
            [67.185716233000051, 24.932592735000071],
            [67.184556536000059, 24.932812338000076],
            [67.183685529000059, 24.932975189000047],
            [67.182296360000066, 24.933241672000065],
            [67.181139130000076, 24.933456340000077],
            [67.179658665000034, 24.933717888000047],
            [67.178318845000035, 24.933949828000038],
            [67.177033110000082, 24.934164699000064],
            [67.177243000000033, 24.933543000000043],
            [67.177924000000075, 24.931679000000031],
            [67.178731000000028, 24.929435000000069],
            [67.178989000000058, 24.928688000000022],
            [67.179567000000077, 24.927120000000059],
            [67.179680935000079, 24.926799902000027],
            [67.180127129000084, 24.925546329000042],
            [67.180070149000073, 24.925534043000027],
            [67.179888302000052, 24.925483626000073],
            [67.179878190000068, 24.925480822000054],
            [67.179547000000071, 24.925389000000052],
            [67.178409000000045, 24.925068000000067],
            [67.177087000000029, 24.924705000000074],
            [67.176006000000029, 24.924396000000058],
            [67.175154000000077, 24.924154000000044],
            [67.17412500000006, 24.923874000000069],
            [67.173107000000073, 24.923596000000032],
            [67.172611000000074, 24.923455000000047],
            [67.172541000000081, 24.923429000000056],
            [67.17250400000006, 24.923417000000029],
            [67.172466000000043, 24.923398000000077],
            [67.17241400000006, 24.923376000000076],
            [67.171598000000074, 24.923059000000023],
            [67.17152800000008, 24.923031000000037],
            [67.171519303000082, 24.923028164000073],
            [67.171482000000083, 24.923016000000075],
            [67.171432000000038, 24.923007000000041],
            [67.171392000000083, 24.923004000000049],
            [67.170593000000054, 24.923004000000049],
            [67.170506000000046, 24.923014000000023],
            [67.170440000000042, 24.923025000000052],
            [67.170429657000057, 24.923028164000073],
            [67.170355000000029, 24.923051000000044],
            [67.170078000000046, 24.923170000000027],
            [67.169264000000055, 24.923522000000048],
            [67.168773000000044, 24.923743000000059],
            [67.167462000000057, 24.924329000000057],
            [67.167443000000048, 24.924307000000056],
            [67.166828000000066, 24.92358100000007],
            [67.166359495000052, 24.923028164000073],
            [67.166328000000078, 24.922991000000025],
            [67.166262463000066, 24.922913950000066],
            [67.166254000000038, 24.922904000000074],
            [67.166231765000077, 24.922913950000066],
            [67.16597652400003, 24.923028164000073],
            [67.165521000000069, 24.923232000000041],
            [67.165010000000052, 24.923461000000032],
            [67.163418000000036, 24.924154000000044],
            [67.16318700000005, 24.924254000000076],
            [67.163169000000039, 24.92404300000004],
            [67.162250586000027, 24.924195612000062],
            [67.162164000000075, 24.924210000000073],
            [67.162038000000052, 24.924231000000077],
            [67.161920922000036, 24.924236096000072],
            [67.161595881000039, 24.924250242000028],
            [67.161230862000082, 24.924266129000046],
            [67.161073000000044, 24.924273000000028],
            [67.160821960000078, 24.924283976000027],
            [67.160551174000034, 24.92429581600004],
            [67.160364000000072, 24.924304000000063],
            [67.160350000000051, 24.924305000000061],
            [67.160176059000037, 24.924281446000066],
            [67.159826164000037, 24.924234064000075],
            [67.159581028000048, 24.924200868000071],
            [67.158584000000076, 24.924066000000039],
            [67.158190000000047, 24.92398600000007],
            [67.157926000000032, 24.923930000000041],
            [67.157575701000042, 24.923856639000064],
            [67.15754400000003, 24.923850000000073],
            [67.157290165000063, 24.923801250000054],
            [67.156940000000077, 24.923734000000024],
            [67.15693200000004, 24.92373200000003],
            [67.156926000000055, 24.923730000000035],
            [67.156920000000071, 24.92372400000005],
            [67.156914000000029, 24.923714000000075],
            [67.156660000000045, 24.923280000000034],
            [67.156518000000062, 24.923038000000076],
            [67.156512267000039, 24.923028164000073],
            [67.156445696000048, 24.922913950000066],
            [67.156416000000036, 24.922863000000063],
            [67.156280000000038, 24.922632000000078],
            [67.156262238000068, 24.922601472000053],
            [67.156216000000029, 24.922522000000072],
            [67.156201000000067, 24.922466000000043],
            [67.156194000000028, 24.92241000000007],
            [67.156153000000074, 24.922046000000023],
            [67.156148024000061, 24.922003870000026],
            [67.156142939000063, 24.92196081700007],
            [67.156123000000036, 24.921792000000039],
            [67.15604200000007, 24.921080000000075],
            [67.156040000000075, 24.921065000000056],
            [67.15610300000003, 24.920939000000033],
            [67.156148024000061, 24.920851261000053],
            [67.156220000000076, 24.92071100000004],
            [67.156262238000068, 24.920630262000032],
            [67.156333000000075, 24.920495000000074],
            [67.156465000000082, 24.920270000000073],
            [67.156506000000036, 24.920200000000023],
            [67.15660500000007, 24.920060000000035],
            [67.156749332000061, 24.919858930000032],
            [67.156956000000037, 24.919567000000029],
            [67.156989556000042, 24.919519627000057],
            [67.15655600000008, 24.919156000000044],
            [67.156262238000068, 24.918910937000078],
            [67.156182000000058, 24.918844000000036],
            [67.156148024000061, 24.918815516000052],
            [67.15581700000007, 24.918538000000069],
            [67.155748275000064, 24.918480729000066],
            [67.155519846000061, 24.918290372000058],
            [67.155451000000028, 24.918233000000043],
            [67.155007000000069, 24.917861000000073],
            [67.154632000000049, 24.917548000000068],
            [67.154348000000084, 24.917310000000043],
            [67.15421200000003, 24.917196000000047],
            [67.153818000000058, 24.916867000000025],
            [67.153548000000058, 24.916641000000027],
            [67.153263000000038, 24.916403000000059],
            [67.15290600000003, 24.916104000000075],
            [67.152672000000052, 24.915908000000059],
            [67.15198300000003, 24.915333000000032],
            [67.151917000000083, 24.915278000000058],
            [67.151717000000076, 24.915113000000076],
            [67.151657000000057, 24.915064000000029],
            [67.151399000000083, 24.914853000000051],
            [67.151123000000041, 24.914625000000058],
            [67.150880000000029, 24.914421000000061],
            [67.150641000000064, 24.914229000000034],
            [67.150111000000038, 24.913793000000055],
            [67.149853000000064, 24.913581000000079],
            [67.149538000000064, 24.913322000000051],
            [67.149499293000076, 24.913290198000027],
            [67.149168000000031, 24.913018000000022],
            [67.149096000000043, 24.912462000000062],
            [67.145091000000036, 24.914334000000053],
            [67.144997000000046, 24.914438000000075],
            [67.143183616000044, 24.914899755000079],
            [67.142753592000076, 24.915027026000075],
            [67.14287736600005, 24.915197985000077],
            [67.143010616000083, 24.915416895000078],
            [67.143105795000054, 24.915740502000062],
            [67.143134348000046, 24.916121216000079],
            [67.143191455000078, 24.916292537000061],
            [67.143391330000043, 24.916492412000025],
            [67.143562651000082, 24.916587590000063],
            [67.143895776000079, 24.916597108000076],
            [67.144209864000061, 24.916720840000039],
            [67.144514435000076, 24.916863608000028],
            [67.144771417000072, 24.917111072000068],
            [67.144942738000054, 24.917377571000031],
            [67.145085506000044, 24.917939124000043],
            [67.145266345000039, 24.918453088000035],
            [67.145437666000078, 24.918967051000038],
            [67.145656577000068, 24.919309694000049],
            [67.145913559000064, 24.919604747000051],
            [67.146122951000052, 24.919804622000072],
            [67.146265719000041, 24.919880764000027],
            [67.146475111000029, 24.919966425000041],
            [67.146617879000075, 24.920061603000079],
            [67.146770164000031, 24.920280514000069],
            [67.14694148600006, 24.920575567000071],
            [67.147255575000031, 24.921051459000068],
            [67.147274610000068, 24.921451209000054],
            [67.147217503000036, 24.921927101000051],
            [67.14730316400005, 24.922250707000046],
            [67.147512556000038, 24.922736118000046],
            [67.147740985000041, 24.923164421000024],
            [67.148169288000076, 24.924021026000048],
            [67.14841675100007, 24.924506437000048],
            [67.148530966000067, 24.92492522200007],
            [67.14877843000005, 24.92530593500004],
            [67.149092518000032, 24.925724721000051],
            [67.149492268000074, 24.926267238000037],
            [67.149958642000058, 24.926923969000029],
            [67.150282249000043, 24.927409379000039],
            [67.151776550000079, 24.92566761300003],
            [67.151919318000068, 24.925581953000062],
            [67.152024014000062, 24.92564857800005],
            [67.152185818000078, 24.925896042000034],
            [67.152633156000036, 24.926571809000052],
            [67.153470727000069, 24.928151771000046],
            [67.154765153000028, 24.930293286000051],
            [67.157035952000058, 24.93407662900006],
            [67.157945118000043, 24.935417079000047],
            [67.158144385000071, 24.935722423000072],
            [67.158346639000058, 24.935948471000074],
            [67.158632174000047, 24.936115034000068],
            [67.159096169000065, 24.936293493000051],
            [67.159488780000061, 24.936352980000038],
            [67.159667240000033, 24.936519542000042],
            [67.159738624000056, 24.936757488000069],
            [67.15963154800005, 24.936947845000077],
            [67.159310321000078, 24.937078715000041],
            [67.158453715000064, 24.937233380000066],
            [67.157430546000057, 24.937352353000051],
            [67.156514454000046, 24.937507018000076],
            [67.155824410000037, 24.937614094000025],
            [67.155518862000065, 24.937693802000069],
            [67.154192100000046, 24.937928183000054],
            [67.153454467000074, 24.938082848000079],
            [67.153121342000077, 24.938166129000024],
            [67.152954780000073, 24.938261307000062],
            [67.152835807000031, 24.93841597200003],
            [67.152538375000063, 24.938891865000073],
            [67.151860228000032, 24.940069698000059],
            [67.150801368000032, 24.941794807000065],
            [67.133806977000063, 24.970543364000036],
            [67.133606642000075, 24.970882262000032],
            [67.131836723000049, 24.973842854000054],
            [67.126690837000069, 24.982390876000068],
            [67.130336521000061, 24.986711210000067],
            [67.133548793000045, 24.990994240000077],
            [67.137117985000032, 24.993135755000026],
            [67.156391619000033, 24.990280402000053],
            [67.238126105000049, 24.979929746000039],
            [67.240267619000065, 24.972077525000032],
            [67.240267619000065, 24.96101303100005],
            [67.242052215000058, 24.954588487000024],
            [67.242451740000035, 24.951037155000051],
            [67.242694670000049, 24.948877780000032],
            [67.24388440000007, 24.944832696000049],
            [67.242218778000051, 24.942215289000046],
            [67.236983963000057, 24.938408152000022],
            [67.23341477200006, 24.935076906000063],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "151",
      properties: { name: "Korangi Creek Cantonment", count: 970 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.141097372000047, 24.802044350000074],
            [67.139901954000038, 24.800875306000023],
            [67.138712224000074, 24.795878438000045],
            [67.135618924000028, 24.791595408000035],
            [67.13625131200007, 24.779813262000062],
            [67.135636848000047, 24.777199003000078],
            [67.13182971100008, 24.775105077000035],
            [67.123644365000075, 24.774724363000075],
            [67.116791517000081, 24.776437575000045],
            [67.111842238000065, 24.780244713000059],
            [67.108415814000068, 24.784242207000034],
            [67.107464030000074, 24.787097561000053],
            [67.104438782000045, 24.787187576000065],
            [67.102998533000061, 24.781084522000072],
            [67.102302551000037, 24.778135300000031],
            [67.09902954100005, 24.779901505000055],
            [67.095383168000069, 24.787195463000046],
            [67.094728891000045, 24.788504234000072],
            [67.093284607000044, 24.791393280000023],
            [67.090736389000028, 24.79214096100003],
            [67.085184254000069, 24.795331839000028],
            [67.081317630000058, 24.798008733000074],
            [67.080127900000036, 24.80038819400005],
            [67.080425332000061, 24.806039414000054],
            [67.082507361000069, 24.810798336000062],
            [67.083994524000047, 24.815854690000037],
            [67.082507361000069, 24.819423882000024],
            [67.079533034000065, 24.822695641000053],
            [67.079718765000052, 24.827675923000072],
            [67.080908496000063, 24.825581997000029],
            [67.08213747800005, 24.824353014000053],
            [67.087475808000079, 24.81915745200007],
            [67.088380003000054, 24.821013432000029],
            [67.089331788000038, 24.823488071000043],
            [67.089189020000049, 24.826962084000058],
            [67.092187141000068, 24.82529646200004],
            [67.095280440000067, 24.823678428000051],
            [67.102656769000077, 24.821061021000048],
            [67.104274803000067, 24.820299594000062],
            [67.107606048000036, 24.817729776000078],
            [67.108448596000073, 24.817188138000063],
            [67.108938546000047, 24.816873170000065],
            [67.114789318000078, 24.813140843000042],
            [67.115500367000038, 24.812766203000024],
            [67.130994171000054, 24.815865302000077],
            [67.131618526000068, 24.811211018000051],
            [67.132696958000054, 24.803974174000075],
            [67.134030845000041, 24.80604590400003],
            [67.13556331500007, 24.808798737000075],
            [67.142062285000065, 24.809962308000024],
            [67.142402842000081, 24.807890584000063],
            [67.141097372000047, 24.802044350000074],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "152",
      properties: { name: "Manora Cantonment", count: 325 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [66.951871133000054, 24.809986796000032],
            [66.960206549000077, 24.822028949000071],
            [66.968383789000029, 24.808908463000023],
            [66.993089000000055, 24.787281000000064],
            [66.982185000000072, 24.784881000000041],
            [66.981755000000078, 24.784871000000066],
            [66.981512000000066, 24.786042000000066],
            [66.981235000000083, 24.786881000000051],
            [66.981046623000054, 24.78783068000007],
            [66.980945000000077, 24.788343000000054],
            [66.980875000000083, 24.788655000000062],
            [66.980282000000045, 24.788908000000049],
            [66.979896000000053, 24.788835000000063],
            [66.979425000000049, 24.789058000000068],
            [66.978894000000082, 24.789390000000026],
            [66.978613000000053, 24.789748000000031],
            [66.978463000000033, 24.790136000000075],
            [66.978317000000061, 24.790442000000041],
            [66.978056000000038, 24.790806000000032],
            [66.977620670000078, 24.79103869100004],
            [66.977549000000067, 24.79107700000003],
            [66.977448000000038, 24.791365000000042],
            [66.977047000000027, 24.79184900000007],
            [66.975929000000065, 24.792700000000025],
            [66.974735000000067, 24.793678000000057],
            [66.973783000000083, 24.794518000000039],
            [66.972980000000064, 24.795281000000045],
            [66.97183700000005, 24.796077000000025],
            [66.970948000000078, 24.796889000000078],
            [66.970160000000078, 24.797342000000071],
            [66.969536000000062, 24.797657000000072],
            [66.968869000000041, 24.797866000000056],
            [66.968070000000068, 24.79798900000003],
            [66.966796000000045, 24.798016000000075],
            [66.966311000000076, 24.798091000000056],
            [66.965431000000081, 24.798527000000036],
            [66.964137000000051, 24.799429000000032],
            [66.962819000000081, 24.800389000000052],
            [66.961823000000038, 24.801204000000041],
            [66.958589000000075, 24.804080000000056],
            [66.958505000000059, 24.804059000000052],
            [66.958484000000055, 24.804059000000052],
            [66.951871133000054, 24.809986796000032],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "153",
      properties: { name: "Clifton Cantonment", count: 1272 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.080423228000029, 24.829468914000074],
            [67.079718765000052, 24.827675923000072],
            [67.079533034000065, 24.822695641000053],
            [67.082507361000069, 24.819423882000024],
            [67.083994524000047, 24.815854690000037],
            [67.082507361000069, 24.810798336000062],
            [67.080425332000061, 24.806039414000054],
            [67.080127900000036, 24.80038819400005],
            [67.081317630000058, 24.798008733000074],
            [67.085184254000069, 24.795331839000028],
            [67.090736389000028, 24.79214096100003],
            [67.093284607000044, 24.791393280000023],
            [67.094728891000045, 24.788504234000072],
            [67.095383168000069, 24.787195463000046],
            [67.09902954100005, 24.779901505000055],
            [67.102302551000037, 24.778135300000031],
            [67.107273673000066, 24.766348661000052],
            [67.110700097000063, 24.761399382000036],
            [67.107421067000075, 24.760283797000056],
            [67.088290200000074, 24.746435334000068],
            [67.083436100000029, 24.745864264000033],
            [67.080532000000062, 24.748213000000078],
            [67.07935400000008, 24.748178000000053],
            [67.078610000000083, 24.748244000000057],
            [67.077695000000062, 24.748469000000057],
            [67.07701000000003, 24.748826000000065],
            [67.076862000000062, 24.74869700000005],
            [67.077052000000037, 24.74826900000005],
            [67.077034000000083, 24.747202000000073],
            [67.076708000000053, 24.74633300000005],
            [67.075012000000072, 24.746688000000063],
            [67.074899174000052, 24.747700160000079],
            [67.074897328000077, 24.747716718000049],
            [67.074874000000079, 24.747926000000064],
            [67.072592000000043, 24.749063000000035],
            [67.071875000000034, 24.748579000000063],
            [67.071516000000031, 24.748347000000024],
            [67.070565000000045, 24.749076000000059],
            [67.070773000000031, 24.749437000000057],
            [67.071057000000053, 24.74971000000005],
            [67.071943000000033, 24.750266000000067],
            [67.069884000000059, 24.753559000000052],
            [67.069176000000084, 24.753504000000078],
            [67.068621000000064, 24.754781000000037],
            [67.067352000000028, 24.757367000000045],
            [67.06693400000006, 24.758003000000031],
            [67.062880028000052, 24.763558528000033],
            [67.062878932000046, 24.763560030000065],
            [67.061645000000055, 24.765251000000035],
            [67.057073000000059, 24.770542000000034],
            [67.052162000000067, 24.776108000000079],
            [67.053314000000057, 24.777036000000066],
            [67.051296000000036, 24.779708000000028],
            [67.043699000000061, 24.787809000000038],
            [67.039273000000037, 24.792273000000023],
            [67.033923000000073, 24.796990000000051],
            [67.032938654000077, 24.79763777900007],
            [67.033831000000077, 24.79854000000006],
            [67.034068000000048, 24.798518000000058],
            [67.034821000000079, 24.799432000000024],
            [67.034846000000073, 24.799518000000035],
            [67.034930000000031, 24.799732000000063],
            [67.034974000000034, 24.799802000000057],
            [67.035010756000077, 24.799872011000048],
            [67.035016000000041, 24.799882000000025],
            [67.035554981000075, 24.800453120000043],
            [67.036257000000035, 24.801197000000059],
            [67.03654845300008, 24.801515776000031],
            [67.036769000000049, 24.801757000000066],
            [67.03730900000005, 24.802308000000039],
            [67.038217000000031, 24.803283000000079],
            [67.038535633000038, 24.803617790000033],
            [67.038710000000037, 24.803801000000078],
            [67.039278000000081, 24.804381000000035],
            [67.039796000000081, 24.804928000000075],
            [67.039877524000076, 24.805013511000027],
            [67.04013176400008, 24.805280187000051],
            [67.040348000000051, 24.805507000000034],
            [67.039682000000028, 24.806015000000059],
            [67.039500000000032, 24.806129000000055],
            [67.039423000000056, 24.806182000000035],
            [67.039364000000035, 24.806215000000066],
            [67.037766000000033, 24.80721200000005],
            [67.037374663000037, 24.807460263000053],
            [67.036702000000048, 24.807887000000051],
            [67.035458000000062, 24.808665000000076],
            [67.035478000000069, 24.808701000000042],
            [67.03569600000003, 24.809092000000078],
            [67.035803000000044, 24.80928300000005],
            [67.035938000000044, 24.80952300000007],
            [67.036319000000049, 24.810204000000056],
            [67.036398000000077, 24.810345000000041],
            [67.036453000000051, 24.810445000000072],
            [67.036541000000057, 24.810601000000077],
            [67.036583000000064, 24.810676000000058],
            [67.03680200000008, 24.811067000000037],
            [67.036917000000074, 24.811273000000028],
            [67.036547000000041, 24.811567000000025],
            [67.036009000000035, 24.811996000000079],
            [67.036471000000063, 24.812519000000066],
            [67.035948000000076, 24.812940000000026],
            [67.035968000000082, 24.812976000000049],
            [67.035991000000081, 24.813010000000077],
            [67.036017000000072, 24.813041000000055],
            [67.036045000000058, 24.813071000000036],
            [67.036076000000037, 24.813097000000027],
            [67.036102000000028, 24.813116000000036],
            [67.036109000000067, 24.813121000000024],
            [67.036144000000036, 24.813142000000028],
            [67.036181000000056, 24.813160000000039],
            [67.036307000000079, 24.813208000000031],
            [67.036347000000035, 24.813220000000058],
            [67.036387000000047, 24.81323100000003],
            [67.03642700000006, 24.813239000000067],
            [67.03646800000007, 24.813246000000049],
            [67.036510000000078, 24.813250000000039],
            [67.036552000000029, 24.813253000000032],
            [67.036592000000041, 24.813254000000029],
            [67.036634000000049, 24.813252000000034],
            [67.036694000000068, 24.813268000000051],
            [67.036752000000035, 24.813283000000069],
            [67.037636000000077, 24.813460000000077],
            [67.038170000000036, 24.81356900000003],
            [67.038473000000067, 24.813641000000075],
            [67.039357000000052, 24.813821000000075],
            [67.039991000000043, 24.813955000000078],
            [67.040078000000051, 24.813967000000048],
            [67.040082000000041, 24.813988000000052],
            [67.040113000000076, 24.814046000000076],
            [67.040646070000037, 24.815032690000066],
            [67.04110600000007, 24.81588400000004],
            [67.041237000000081, 24.815861000000041],
            [67.041417000000081, 24.81583500000005],
            [67.041488000000072, 24.815824000000077],
            [67.041552000000081, 24.815818000000036],
            [67.041867000000082, 24.815788000000055],
            [67.041956000000027, 24.815779000000077],
            [67.042044000000033, 24.815760000000068],
            [67.042129000000045, 24.815733000000023],
            [67.042210000000068, 24.815696000000059],
            [67.042288000000042, 24.815651000000059],
            [67.042444000000046, 24.815533000000073],
            [67.042889000000059, 24.816059000000052],
            [67.04291500000005, 24.816084000000046],
            [67.042937000000052, 24.816110000000037],
            [67.042986000000042, 24.816185000000075],
            [67.043032000000039, 24.816262000000052],
            [67.043073000000049, 24.816341000000023],
            [67.043092000000058, 24.816381000000035],
            [67.043554000000029, 24.816206000000022],
            [67.043783000000076, 24.816139000000078],
            [67.04395100000005, 24.816104000000053],
            [67.044118000000083, 24.816103000000055],
            [67.044578000000058, 24.816119000000072],
            [67.045033000000046, 24.81610900000004],
            [67.045108000000027, 24.816107000000045],
            [67.045150000000035, 24.815686000000028],
            [67.045211000000052, 24.815075000000036],
            [67.04562100000004, 24.814749000000063],
            [67.046369000000084, 24.814156000000025],
            [67.04672800000003, 24.814543000000072],
            [67.046569000000034, 24.814669000000038],
            [67.047030000000063, 24.815378000000067],
            [67.047119000000066, 24.815513000000067],
            [67.047229000000073, 24.815683000000035],
            [67.047319000000073, 24.815822000000026],
            [67.047590000000071, 24.816238000000055],
            [67.047801168000035, 24.816563001000077],
            [67.047974000000067, 24.816829000000041],
            [67.048085000000071, 24.816999000000067],
            [67.048465000000078, 24.81758300000007],
            [67.048611000000051, 24.81780800000007],
            [67.048197000000073, 24.81815400000005],
            [67.048061000000075, 24.81819900000005],
            [67.048159000000055, 24.818257000000074],
            [67.04821452300007, 24.818291917000067],
            [67.048256000000038, 24.818318000000033],
            [67.048301000000038, 24.818348000000071],
            [67.048352000000079, 24.818382000000042],
            [67.048445000000072, 24.818448000000046],
            [67.048537000000067, 24.818517000000043],
            [67.04862600000007, 24.818588000000034],
            [67.048714000000075, 24.818662000000074],
            [67.048800000000028, 24.818738000000053],
            [67.048830000000066, 24.818759000000057],
            [67.048282000000029, 24.819193000000041],
            [67.048122000000035, 24.819335000000024],
            [67.048001000000056, 24.819427000000076],
            [67.047534000000041, 24.819896000000028],
            [67.047431000000074, 24.819998000000055],
            [67.04723400000006, 24.82022900000004],
            [67.047094000000072, 24.820439000000079],
            [67.046786000000054, 24.820969000000048],
            [67.04681400000004, 24.820985000000064],
            [67.047351000000049, 24.821250000000077],
            [67.04793200000006, 24.821509000000049],
            [67.047712865000051, 24.822432437000032],
            [67.047675000000083, 24.822592000000043],
            [67.047552000000053, 24.82311100000004],
            [67.047523000000069, 24.823232000000075],
            [67.047472000000027, 24.823448000000042],
            [67.047392000000059, 24.823785000000044],
            [67.047382000000027, 24.823822000000064],
            [67.047368000000063, 24.823857000000032],
            [67.047351000000049, 24.82389100000006],
            [67.047330000000045, 24.823922000000039],
            [67.047305000000051, 24.823951000000022],
            [67.047484000000054, 24.823987000000045],
            [67.047662000000059, 24.82402200000007],
            [67.047841000000062, 24.824056000000041],
            [67.048020000000065, 24.824089000000072],
            [67.048033000000032, 24.824092000000064],
            [67.04810800000007, 24.824105000000031],
            [67.048199000000068, 24.824121000000048],
            [67.048378000000071, 24.824153000000024],
            [67.048558000000071, 24.824183000000062],
            [67.048737000000074, 24.824213000000043],
            [67.04867500000006, 24.824560000000076],
            [67.048659000000043, 24.824648000000025],
            [67.048595000000034, 24.824921000000074],
            [67.04849100000007, 24.825358000000051],
            [67.048467000000073, 24.825502000000029],
            [67.048436000000038, 24.825644000000068],
            [67.048398000000077, 24.825785000000053],
            [67.048353000000077, 24.825924000000043],
            [67.04830000000004, 24.826060000000041],
            [67.048241000000075, 24.826194000000044],
            [67.048175000000072, 24.826324000000056],
            [67.048152000000073, 24.826365000000067],
            [67.048102000000029, 24.826450000000023],
            [67.048023000000057, 24.826573000000053],
            [67.047938000000045, 24.826692000000037],
            [67.047833000000082, 24.826941000000033],
            [67.047714000000042, 24.827188000000035],
            [67.047708000000057, 24.827222000000063],
            [67.047706000000062, 24.827257000000031],
            [67.047708000000057, 24.827292000000057],
            [67.047714000000042, 24.827327000000025],
            [67.047723000000076, 24.827360000000056],
            [67.047736000000043, 24.827393000000029],
            [67.047753000000057, 24.827423000000067],
            [67.047773000000063, 24.827452000000051],
            [67.047816000000068, 24.827462000000025],
            [67.047859000000074, 24.827469000000065],
            [67.047903000000076, 24.827472000000057],
            [67.047918000000038, 24.827473000000055],
            [67.048117000000047, 24.827466000000072],
            [67.048312000000067, 24.827460000000031],
            [67.048553000000084, 24.827453000000048],
            [67.048872000000074, 24.827443000000073],
            [67.050260000000037, 24.827399000000071],
            [67.050280000000043, 24.827406000000053],
            [67.05030000000005, 24.827414000000033],
            [67.050320000000056, 24.82742200000007],
            [67.050339000000065, 24.827431000000047],
            [67.050358000000074, 24.827441000000078],
            [67.050377000000083, 24.827451000000053],
            [67.050395000000037, 24.827462000000025],
            [67.050413000000049, 24.827474000000052],
            [67.05043100000006, 24.827492000000063],
            [67.050445000000082, 24.827513000000067],
            [67.050456000000054, 24.827536000000066],
            [67.050462000000039, 24.82756100000006],
            [67.050463000000036, 24.827587000000051],
            [67.050460000000044, 24.827612000000045],
            [67.050453000000061, 24.827636000000041],
            [67.050441000000035, 24.82765900000004],
            [67.049995000000081, 24.828361000000029],
            [67.049496000000033, 24.829153000000076],
            [67.049494000000038, 24.829197000000079],
            [67.04972900000007, 24.829230000000052],
            [67.050798000000043, 24.829444000000024],
            [67.050929000000053, 24.829469000000074],
            [67.051132000000052, 24.829512000000022],
            [67.051102000000071, 24.829588000000058],
            [67.050787000000071, 24.829901000000064],
            [67.05048000000005, 24.830204000000037],
            [67.049788000000035, 24.83088900000007],
            [67.049338077000073, 24.831335169000056],
            [67.049069000000031, 24.831602000000032],
            [67.048743000000059, 24.831924000000072],
            [67.048502000000042, 24.832340000000045],
            [67.048330000000078, 24.832670000000064],
            [67.048329000000081, 24.832652000000053],
            [67.048326000000031, 24.832636000000036],
            [67.048319000000049, 24.832620000000077],
            [67.048311000000069, 24.832606000000055],
            [67.04830000000004, 24.832594000000029],
            [67.04828800000007, 24.832583000000056],
            [67.048274000000049, 24.832574000000022],
            [67.04825900000003, 24.832568000000038],
            [67.04824300000007, 24.832564000000048],
            [67.048226000000056, 24.83256300000005],
            [67.04821000000004, 24.832565000000045],
            [67.04819400000008, 24.832569000000035],
            [67.04806300000007, 24.832633000000044],
            [67.048060097000075, 24.832634814000073],
            [67.048021994000067, 24.832658629000036],
            [67.048015000000078, 24.832663000000025],
            [67.04796900000008, 24.832697000000053],
            [67.047930423000082, 24.832728563000046],
            [67.047925000000077, 24.832733000000076],
            [67.04788300000007, 24.832772000000034],
            [67.047845000000052, 24.832814000000042],
            [67.047809000000029, 24.832858000000044],
            [67.047776000000056, 24.832905000000039],
            [67.047747000000072, 24.832953000000032],
            [67.047721000000081, 24.833004000000074],
            [67.047698000000082, 24.833056000000056],
            [67.047583000000031, 24.833371000000056],
            [67.047538000000031, 24.833549000000062],
            [67.047635000000071, 24.833553000000052],
            [67.047947000000079, 24.833633000000077],
            [67.047967000000028, 24.833638000000064],
            [67.048384000000055, 24.833745000000079],
            [67.04877300000004, 24.833844000000056],
            [67.049038000000053, 24.833912000000055],
            [67.049075000000073, 24.833936000000051],
            [67.049110000000042, 24.833964000000037],
            [67.049141000000077, 24.833995000000073],
            [67.049169000000063, 24.834030000000041],
            [67.049193000000059, 24.834067000000061],
            [67.049214000000063, 24.834107000000074],
            [67.049229000000082, 24.834149000000025],
            [67.04928019700003, 24.834144842000057],
            [67.049623000000054, 24.834117000000049],
            [67.04964700000005, 24.834115000000054],
            [67.049691000000053, 24.834116000000051],
            [67.049735000000055, 24.834119000000044],
            [67.04977800000006, 24.834126000000026],
            [67.049821000000065, 24.834137000000055],
            [67.049863000000073, 24.834151000000077],
            [67.049904000000083, 24.834168000000034],
            [67.049943000000042, 24.834189000000038],
            [67.05024700000007, 24.834534000000076],
            [67.05043500000005, 24.834749000000045],
            [67.050993000000062, 24.83492700000005],
            [67.05164700000006, 24.835136000000034],
            [67.051749000000029, 24.835169000000064],
            [67.05173700000006, 24.835189000000071],
            [67.051728000000082, 24.835210000000075],
            [67.051722000000041, 24.835232000000076],
            [67.051720000000046, 24.835255000000075],
            [67.051720000000046, 24.835278000000073],
            [67.051724000000036, 24.835301000000072],
            [67.051731000000075, 24.835322000000076],
            [67.05174100000005, 24.835343000000023],
            [67.051907690000064, 24.835709136000048],
            [67.05197000000004, 24.83584600000006],
            [67.052074000000061, 24.836224000000072],
            [67.052263000000039, 24.837263000000064],
            [67.052385274000073, 24.837918391000073],
            [67.052388000000064, 24.837933000000078],
            [67.052395000000047, 24.837977000000024],
            [67.052400000000034, 24.838021000000026],
            [67.052402000000029, 24.838066000000026],
            [67.052403000000083, 24.838111000000026],
            [67.052401000000032, 24.838155000000029],
            [67.052396000000044, 24.838200000000029],
            [67.052391000000057, 24.838238000000047],
            [67.052381000000082, 24.838288000000034],
            [67.052371000000051, 24.838332000000037],
            [67.051934000000074, 24.840256000000068],
            [67.051943000000051, 24.840295000000026],
            [67.052239000000043, 24.840193000000056],
            [67.052708000000052, 24.840011000000061],
            [67.052998000000059, 24.839891000000023],
            [67.053322000000037, 24.839768000000049],
            [67.053531000000078, 24.839672000000064],
            [67.053670000000068, 24.839623000000074],
            [67.053800000000081, 24.839592000000039],
            [67.053905000000043, 24.839598000000024],
            [67.054053000000067, 24.839632000000051],
            [67.054164000000071, 24.839697000000058],
            [67.054519000000028, 24.840002000000027],
            [67.054808000000037, 24.840272000000027],
            [67.054898000000037, 24.840375000000051],
            [67.055037000000084, 24.840545000000077],
            [67.055138000000056, 24.84068300000007],
            [67.055504000000042, 24.84096100000005],
            [67.055613000000051, 24.841027000000054],
            [67.055671000000075, 24.841053000000045],
            [67.055735000000084, 24.841074000000049],
            [67.055835000000059, 24.841089000000068],
            [67.055933000000039, 24.841102000000035],
            [67.055979000000036, 24.841124000000036],
            [67.055983000000083, 24.841154000000074],
            [67.055979000000036, 24.841187000000048],
            [67.05595900000003, 24.841209000000049],
            [67.055882000000054, 24.841266000000076],
            [67.055841000000044, 24.841292000000067],
            [67.05580400000008, 24.841336000000069],
            [67.05577100000005, 24.841390000000047],
            [67.05575900000008, 24.841447000000073],
            [67.055757000000028, 24.841490000000078],
            [67.055764000000067, 24.841527000000042],
            [67.055790000000059, 24.841568000000052],
            [67.055889000000036, 24.841631000000064],
            [67.05617593900007, 24.841793211000038],
            [67.056333000000052, 24.841882000000055],
            [67.05711800000006, 24.842327000000068],
            [67.057272000000069, 24.842456000000027],
            [67.057292964000055, 24.842484755000044],
            [67.057385000000068, 24.842611000000034],
            [67.056982000000062, 24.842954000000077],
            [67.056845000000067, 24.842900000000043],
            [67.05670200000003, 24.842894000000058],
            [67.056470000000047, 24.842959000000064],
            [67.056145000000072, 24.843094000000065],
            [67.055966000000069, 24.843169000000046],
            [67.055756000000031, 24.84328800000003],
            [67.055648000000076, 24.843337000000076],
            [67.05548200000004, 24.843410000000063],
            [67.055316000000062, 24.843482000000051],
            [67.055150000000083, 24.84355400000004],
            [67.055044000000066, 24.84359900000004],
            [67.054968000000031, 24.843624000000034],
            [67.054892000000052, 24.843650000000025],
            [67.054817000000071, 24.843677000000071],
            [67.054742000000033, 24.843705000000057],
            [67.054668000000049, 24.843735000000038],
            [67.054594000000066, 24.843766000000073],
            [67.054521000000079, 24.843799000000047],
            [67.054448000000036, 24.843833000000075],
            [67.054376000000047, 24.843867000000046],
            [67.054305000000056, 24.843904000000066],
            [67.054274000000078, 24.843920000000026],
            [67.054571000000067, 24.844126000000074],
            [67.055004000000054, 24.844426000000055],
            [67.055490000000077, 24.844763000000057],
            [67.055719000000067, 24.844921000000056],
            [67.055831000000069, 24.84495000000004],
            [67.055643000000032, 24.845351000000051],
            [67.055340000000058, 24.846135000000061],
            [67.055088000000069, 24.846819000000039],
            [67.054639000000066, 24.847718000000043],
            [67.054737000000046, 24.847741000000042],
            [67.055888000000039, 24.847322000000077],
            [67.05674300000004, 24.847795000000076],
            [67.057174000000032, 24.847710000000063],
            [67.057689000000039, 24.847710000000063],
            [67.058273000000042, 24.847686000000067],
            [67.05967400000003, 24.847519000000034],
            [67.060178497000038, 24.847461850000059],
            [67.060186000000044, 24.847461000000067],
            [67.060889000000032, 24.847381000000041],
            [67.060922000000062, 24.847377000000051],
            [67.061125000000061, 24.847532000000058],
            [67.061164000000076, 24.847561000000042],
            [67.061205000000029, 24.847591000000023],
            [67.061487352000029, 24.847898033000035],
            [67.061491000000046, 24.847902000000033],
            [67.06164700000005, 24.848073000000056],
            [67.061922000000038, 24.848366000000055],
            [67.061988000000042, 24.848445000000027],
            [67.062046000000066, 24.848501000000056],
            [67.062131000000079, 24.848571000000049],
            [67.062186000000054, 24.848602000000028],
            [67.062234000000046, 24.848631000000069],
            [67.062290000000075, 24.848655000000065],
            [67.062332000000083, 24.848673000000076],
            [67.062369000000047, 24.848687000000041],
            [67.062446000000079, 24.848696000000075],
            [67.062503000000049, 24.848699000000067],
            [67.062606000000073, 24.848704000000055],
            [67.062746000000061, 24.848709000000042],
            [67.06290000000007, 24.848707000000047],
            [67.06298600000008, 24.848712000000035],
            [67.063058000000069, 24.848707000000047],
            [67.063362000000041, 24.848694000000023],
            [67.064093000000071, 24.848655000000065],
            [67.064993000000072, 24.848609000000067],
            [67.065964000000065, 24.848593000000051],
            [67.066258000000062, 24.848598000000038],
            [67.066269000000034, 24.848507000000041],
            [67.066371000000061, 24.847422000000051],
            [67.066639000000066, 24.845891000000051],
            [67.066743000000031, 24.845279000000062],
            [67.066860000000077, 24.845304000000056],
            [67.066966429000047, 24.845278275000055],
            [67.068126000000063, 24.844998000000032],
            [67.068200000000047, 24.844908000000032],
            [67.068175000000053, 24.844833000000051],
            [67.068109000000049, 24.844638000000032],
            [67.067917000000079, 24.844674000000055],
            [67.067661000000044, 24.843708000000049],
            [67.068235000000072, 24.843576000000041],
            [67.068399000000056, 24.843529000000046],
            [67.06917500000003, 24.843340000000069],
            [67.069846000000041, 24.843164000000058],
            [67.069894000000033, 24.843151000000034],
            [67.070268000000056, 24.843053000000054],
            [67.070453000000043, 24.842943000000048],
            [67.070504000000028, 24.842888000000073],
            [67.07055500000007, 24.842833000000041],
            [67.070587000000046, 24.84279900000007],
            [67.071192000000053, 24.842457000000024],
            [67.072036000000082, 24.841934000000037],
            [67.072330000000079, 24.841751000000045],
            [67.072341000000051, 24.841744000000062],
            [67.072347000000036, 24.841739000000075],
            [67.072351000000083, 24.841735000000028],
            [67.072355000000073, 24.841729000000043],
            [67.072358000000065, 24.841723000000059],
            [67.072359000000063, 24.841719000000069],
            [67.07236000000006, 24.841715000000079],
            [67.072361000000058, 24.841710000000035],
            [67.072361000000058, 24.841705000000047],
            [67.072361000000058, 24.841701000000057],
            [67.072361000000058, 24.841697000000067],
            [67.07236000000006, 24.841694000000075],
            [67.072359000000063, 24.841689000000031],
            [67.072119000000043, 24.840798000000063],
            [67.072167000000036, 24.840594000000067],
            [67.071997000000067, 24.84022600000003],
            [67.07240500000006, 24.840106000000048],
            [67.07221700000008, 24.83946700000007],
            [67.072362000000055, 24.83943000000005],
            [67.072736000000077, 24.839337000000057],
            [67.072894000000076, 24.839042000000063],
            [67.07296455200003, 24.838910029000033],
            [67.073203000000035, 24.838464000000045],
            [67.072962000000075, 24.838191000000052],
            [67.072007000000042, 24.837109000000055],
            [67.071939000000043, 24.836960000000033],
            [67.072199000000069, 24.836862000000053],
            [67.072128000000077, 24.836605000000077],
            [67.072065000000066, 24.836536000000024],
            [67.071918000000039, 24.836374000000035],
            [67.071683000000064, 24.836116000000061],
            [67.071137000000078, 24.835515000000044],
            [67.070910000000083, 24.835318000000029],
            [67.071399000000042, 24.835046000000034],
            [67.07231500000006, 24.834547000000043],
            [67.073170000000061, 24.834048000000053],
            [67.073673000000042, 24.83382400000005],
            [67.07440900000006, 24.833500000000072],
            [67.075046000000043, 24.833258000000058],
            [67.075639000000081, 24.832912000000078],
            [67.076474000000076, 24.832386000000042],
            [67.076498959000048, 24.832422247000068],
            [67.076514901000053, 24.83244540000004],
            [67.076721000000077, 24.832762000000059],
            [67.076903000000073, 24.833007000000066],
            [67.07709600000004, 24.833280000000059],
            [67.077278000000035, 24.833545000000072],
            [67.07737700000007, 24.833741000000032],
            [67.07755700000007, 24.834026000000051],
            [67.077642000000083, 24.834215000000029],
            [67.077689000000078, 24.834425000000067],
            [67.077732000000083, 24.834692000000075],
            [67.077730000000031, 24.834888000000035],
            [67.077706000000035, 24.835077000000069],
            [67.077683000000036, 24.835191000000066],
            [67.077683000000036, 24.835293000000036],
            [67.077683000000036, 24.835395000000062],
            [67.077683000000036, 24.835497000000032],
            [67.077683000000036, 24.835599000000059],
            [67.077683000000036, 24.835701000000029],
            [67.077683000000036, 24.835803000000055],
            [67.077683000000036, 24.836006000000054],
            [67.077687000000083, 24.83622900000006],
            [67.077710000000081, 24.836551000000043],
            [67.077730000000031, 24.836838000000057],
            [67.077763000000061, 24.83705800000007],
            [67.077773645000036, 24.837160770000025],
            [67.077789000000053, 24.837309000000062],
            [67.077803000000074, 24.837525000000028],
            [67.077828000000068, 24.837796000000026],
            [67.077858000000049, 24.838073000000065],
            [67.077871000000073, 24.838276000000064],
            [67.077893000000074, 24.838521000000071],
            [67.077919000000065, 24.838825000000043],
            [67.077922000000058, 24.839026000000047],
            [67.077922000000058, 24.839230000000043],
            [67.077924000000053, 24.839536000000066],
            [67.077909000000034, 24.839854000000059],
            [67.077897000000064, 24.840059000000053],
            [67.077877000000058, 24.840330000000051],
            [67.077858000000049, 24.84087500000004],
            [67.077856000000054, 24.841001000000063],
            [67.077852000000064, 24.841207000000054],
            [67.077844000000027, 24.841427000000067],
            [67.077841090000049, 24.841658365000058],
            [67.077840000000037, 24.84174500000006],
            [67.077840000000037, 24.841830462000075],
            [67.077840000000037, 24.841891000000032],
            [67.077844000000027, 24.842083000000059],
            [67.077834000000053, 24.842283000000066],
            [67.077805000000069, 24.842470000000048],
            [67.077771000000041, 24.842637000000025],
            [67.077901000000054, 24.842791000000034],
            [67.078863000000069, 24.842891000000066],
            [67.079028000000051, 24.842908000000023],
            [67.079385000000059, 24.842945000000043],
            [67.081089000000077, 24.843121000000053],
            [67.081802354000047, 24.843175280000025],
            [67.082051181000054, 24.84319421400005],
            [67.08253232800007, 24.843230825000035],
            [67.083247056000062, 24.843285210000033],
            [67.083402000000035, 24.843297000000064],
            [67.083807000000036, 24.843327000000045],
            [67.08392200000003, 24.843557000000033],
            [67.084312000000068, 24.843545000000063],
            [67.083889000000056, 24.842863000000023],
            [67.08363600000007, 24.842320000000029],
            [67.083453089000045, 24.841830462000075],
            [67.083327000000054, 24.841493000000071],
            [67.082797000000028, 24.83994000000007],
            [67.082317000000046, 24.838299000000063],
            [67.081982550000077, 24.837160770000025],
            [67.081944069000031, 24.837029807000079],
            [67.081831000000079, 24.836645000000033],
            [67.081490000000031, 24.835369000000071],
            [67.081218000000035, 24.834422000000075],
            [67.081025000000068, 24.833956000000057],
            [67.080839384000058, 24.833560872000078],
            [67.080590000000029, 24.833030000000065],
            [67.080340000000035, 24.832535000000064],
            [67.080128000000059, 24.832135000000051],
            [67.079967000000067, 24.831792000000064],
            [67.079875524000045, 24.831598780000036],
            [67.079869000000031, 24.831585000000075],
            [67.079660000000047, 24.831172000000038],
            [67.079593000000045, 24.831031000000053],
            [67.079578000000083, 24.830948000000035],
            [67.079743993000079, 24.830627395000079],
            [67.080001000000038, 24.830131000000051],
            [67.08033692500004, 24.830020170000068],
            [67.080423228000029, 24.829468914000074],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "154",
      properties: { name: "Karachi Cantonment", count: 1491 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.054639000000066, 24.847718000000043],
            [67.055088000000069, 24.846819000000039],
            [67.055340000000058, 24.846135000000061],
            [67.055643000000032, 24.845351000000051],
            [67.055831000000069, 24.84495000000004],
            [67.055719000000067, 24.844921000000056],
            [67.055490000000077, 24.844763000000057],
            [67.055004000000054, 24.844426000000055],
            [67.054571000000067, 24.844126000000074],
            [67.054274000000078, 24.843920000000026],
            [67.054305000000056, 24.843904000000066],
            [67.054376000000047, 24.843867000000046],
            [67.054448000000036, 24.843833000000075],
            [67.054521000000079, 24.843799000000047],
            [67.054594000000066, 24.843766000000073],
            [67.054668000000049, 24.843735000000038],
            [67.054742000000033, 24.843705000000057],
            [67.054817000000071, 24.843677000000071],
            [67.054892000000052, 24.843650000000025],
            [67.054968000000031, 24.843624000000034],
            [67.055044000000066, 24.84359900000004],
            [67.055150000000083, 24.84355400000004],
            [67.055316000000062, 24.843482000000051],
            [67.05548200000004, 24.843410000000063],
            [67.055648000000076, 24.843337000000076],
            [67.055756000000031, 24.84328800000003],
            [67.055966000000069, 24.843169000000046],
            [67.056145000000072, 24.843094000000065],
            [67.056470000000047, 24.842959000000064],
            [67.05670200000003, 24.842894000000058],
            [67.056845000000067, 24.842900000000043],
            [67.056982000000062, 24.842954000000077],
            [67.057385000000068, 24.842611000000034],
            [67.057292964000055, 24.842484755000044],
            [67.057272000000069, 24.842456000000027],
            [67.05711800000006, 24.842327000000068],
            [67.056333000000052, 24.841882000000055],
            [67.05617593900007, 24.841793211000038],
            [67.055889000000036, 24.841631000000064],
            [67.055790000000059, 24.841568000000052],
            [67.055764000000067, 24.841527000000042],
            [67.055757000000028, 24.841490000000078],
            [67.05575900000008, 24.841447000000073],
            [67.05577100000005, 24.841390000000047],
            [67.05580400000008, 24.841336000000069],
            [67.055841000000044, 24.841292000000067],
            [67.055882000000054, 24.841266000000076],
            [67.05595900000003, 24.841209000000049],
            [67.055979000000036, 24.841187000000048],
            [67.055983000000083, 24.841154000000074],
            [67.055979000000036, 24.841124000000036],
            [67.055933000000039, 24.841102000000035],
            [67.055835000000059, 24.841089000000068],
            [67.055735000000084, 24.841074000000049],
            [67.055671000000075, 24.841053000000045],
            [67.055613000000051, 24.841027000000054],
            [67.055504000000042, 24.84096100000005],
            [67.055138000000056, 24.84068300000007],
            [67.055037000000084, 24.840545000000077],
            [67.054898000000037, 24.840375000000051],
            [67.054808000000037, 24.840272000000027],
            [67.054519000000028, 24.840002000000027],
            [67.054164000000071, 24.839697000000058],
            [67.054053000000067, 24.839632000000051],
            [67.053905000000043, 24.839598000000024],
            [67.053800000000081, 24.839592000000039],
            [67.053670000000068, 24.839623000000074],
            [67.053531000000078, 24.839672000000064],
            [67.053322000000037, 24.839768000000049],
            [67.052998000000059, 24.839891000000023],
            [67.052708000000052, 24.840011000000061],
            [67.052239000000043, 24.840193000000056],
            [67.051943000000051, 24.840295000000026],
            [67.051934000000074, 24.840256000000068],
            [67.052371000000051, 24.838332000000037],
            [67.052381000000082, 24.838288000000034],
            [67.052391000000057, 24.838238000000047],
            [67.052396000000044, 24.838200000000029],
            [67.052401000000032, 24.838155000000029],
            [67.052403000000083, 24.838111000000026],
            [67.052402000000029, 24.838066000000026],
            [67.052400000000034, 24.838021000000026],
            [67.052395000000047, 24.837977000000024],
            [67.052388000000064, 24.837933000000078],
            [67.052385274000073, 24.837918391000073],
            [67.052263000000039, 24.837263000000064],
            [67.052074000000061, 24.836224000000072],
            [67.05197000000004, 24.83584600000006],
            [67.051907690000064, 24.835709136000048],
            [67.05174100000005, 24.835343000000023],
            [67.051731000000075, 24.835322000000076],
            [67.051724000000036, 24.835301000000072],
            [67.051720000000046, 24.835278000000073],
            [67.051720000000046, 24.835255000000075],
            [67.051722000000041, 24.835232000000076],
            [67.051728000000082, 24.835210000000075],
            [67.05173700000006, 24.835189000000071],
            [67.051749000000029, 24.835169000000064],
            [67.05164700000006, 24.835136000000034],
            [67.050993000000062, 24.83492700000005],
            [67.05043500000005, 24.834749000000045],
            [67.05024700000007, 24.834534000000076],
            [67.049943000000042, 24.834189000000038],
            [67.049904000000083, 24.834168000000034],
            [67.049863000000073, 24.834151000000077],
            [67.049821000000065, 24.834137000000055],
            [67.04977800000006, 24.834126000000026],
            [67.049735000000055, 24.834119000000044],
            [67.049691000000053, 24.834116000000051],
            [67.04964700000005, 24.834115000000054],
            [67.049623000000054, 24.834117000000049],
            [67.04928019700003, 24.834144842000057],
            [67.049229000000082, 24.834149000000025],
            [67.049214000000063, 24.834107000000074],
            [67.049193000000059, 24.834067000000061],
            [67.049169000000063, 24.834030000000041],
            [67.049141000000077, 24.833995000000073],
            [67.049110000000042, 24.833964000000037],
            [67.049075000000073, 24.833936000000051],
            [67.049038000000053, 24.833912000000055],
            [67.04877300000004, 24.833844000000056],
            [67.048384000000055, 24.833745000000079],
            [67.047967000000028, 24.833638000000064],
            [67.047947000000079, 24.833633000000077],
            [67.047635000000071, 24.833553000000052],
            [67.047538000000031, 24.833549000000062],
            [67.047113000000081, 24.833649000000037],
            [67.047032000000058, 24.833638000000064],
            [67.04698185500007, 24.83363102800007],
            [67.046248000000048, 24.833529000000055],
            [67.044042907000062, 24.833599509000067],
            [67.043965000000071, 24.833602000000042],
            [67.042702000000077, 24.833642000000054],
            [67.04190600000004, 24.833662000000061],
            [67.041807000000063, 24.833664000000056],
            [67.041606000000058, 24.833678000000077],
            [67.041408000000047, 24.833707000000061],
            [67.041342000000043, 24.833720000000028],
            [67.04121200000003, 24.833750000000066],
            [67.041001000000051, 24.833813000000077],
            [67.040831000000082, 24.833878000000027],
            [67.040672000000029, 24.83395100000007],
            [67.040473000000077, 24.834060000000022],
            [67.040175000000033, 24.833826000000045],
            [67.039561000000049, 24.833345000000065],
            [67.039442000000065, 24.833252000000073],
            [67.039181000000042, 24.833048000000076],
            [67.039065000000051, 24.833044000000029],
            [67.038522000000057, 24.833714000000043],
            [67.037946000000034, 24.834434000000044],
            [67.037621000000058, 24.834804000000076],
            [67.037197132000074, 24.835357419000047],
            [67.036553000000083, 24.836131000000023],
            [67.035923000000082, 24.836885000000052],
            [67.036016884000048, 24.836935003000065],
            [67.036107000000072, 24.836983000000032],
            [67.037119000000075, 24.837633000000039],
            [67.037322000000074, 24.837784000000056],
            [67.037391000000071, 24.837857000000042],
            [67.037415000000067, 24.837919000000056],
            [67.037449000000038, 24.838049000000069],
            [67.037439000000063, 24.838190000000054],
            [67.037391000000071, 24.838304000000051],
            [67.037198000000046, 24.838823000000048],
            [67.03712831200005, 24.839047965000077],
            [67.036992000000055, 24.839488000000074],
            [67.036803000000077, 24.840031000000067],
            [67.036734000000081, 24.840269000000035],
            [67.036723000000052, 24.840355000000045],
            [67.036737000000073, 24.840489000000048],
            [67.036748516000046, 24.840551408000067],
            [67.036768000000052, 24.840657000000078],
            [67.036820000000034, 24.840854000000036],
            [67.036867018000066, 24.841044733000047],
            [67.036926000000051, 24.84128400000003],
            [67.036999000000037, 24.841600000000028],
            [67.037004116000048, 24.84162032100005],
            [67.037071000000083, 24.841886000000045],
            [67.037109000000044, 24.842075000000023],
            [67.037212000000068, 24.842801000000065],
            [67.037332000000049, 24.843632000000071],
            [67.037278000000072, 24.843647000000033],
            [67.037156000000039, 24.843682000000058],
            [67.036984000000075, 24.843736000000035],
            [67.036816000000044, 24.843802000000039],
            [67.036652000000061, 24.843879000000072],
            [67.036495000000059, 24.843967000000077],
            [67.036365000000046, 24.844051000000036],
            [67.036343000000045, 24.844066000000055],
            [67.036199000000067, 24.844175000000064],
            [67.03606300000007, 24.84429300000005],
            [67.035935000000052, 24.844421000000068],
            [67.035460000000057, 24.845180000000028],
            [67.034932000000083, 24.846047000000056],
            [67.034346000000028, 24.847053000000074],
            [67.034086000000059, 24.847516000000041],
            [67.033449000000076, 24.848669000000029],
            [67.032668000000058, 24.850072000000068],
            [67.032558444000074, 24.850259293000079],
            [67.032396000000062, 24.850537000000031],
            [67.03235200000006, 24.85058900000007],
            [67.032301000000075, 24.850633000000073],
            [67.032243000000051, 24.850669000000039],
            [67.032180000000039, 24.850696000000028],
            [67.032115000000033, 24.850713000000042],
            [67.032047000000034, 24.850719000000026],
            [67.031979000000035, 24.850716000000034],
            [67.031914000000029, 24.850702000000069],
            [67.031806000000074, 24.850722000000076],
            [67.031845000000033, 24.850949000000071],
            [67.031851454000048, 24.851283673000069],
            [67.031852000000072, 24.851312000000064],
            [67.031954000000042, 24.851366000000041],
            [67.033188000000052, 24.851931000000036],
            [67.03415700000005, 24.852374000000054],
            [67.034420000000068, 24.852491000000043],
            [67.034594000000084, 24.852612000000079],
            [67.034715000000062, 24.852707000000066],
            [67.03481800000003, 24.852839000000074],
            [67.035141000000067, 24.853396000000032],
            [67.03549600000008, 24.854025000000036],
            [67.035702000000072, 24.854384000000039],
            [67.035798000000057, 24.85453300000006],
            [67.03587200000004, 24.854650000000049],
            [67.035997000000066, 24.854778000000067],
            [67.036121000000037, 24.854884000000027],
            [67.036274000000049, 24.854980000000069],
            [67.036395000000027, 24.855037000000038],
            [67.036526000000038, 24.855083000000036],
            [67.036803000000077, 24.855136000000073],
            [67.037435000000073, 24.855225000000075],
            [67.038231000000053, 24.855357000000026],
            [67.038884000000053, 24.855474000000072],
            [67.039474000000041, 24.855648000000031],
            [67.039775000000077, 24.855769000000066],
            [67.040585000000078, 24.856035000000077],
            [67.040803271000073, 24.856105982000031],
            [67.041844015000038, 24.856444436000061],
            [67.042922000000033, 24.856795000000034],
            [67.04384900000008, 24.857054000000062],
            [67.043980000000033, 24.857083000000046],
            [67.044516000000044, 24.857161000000076],
            [67.044893000000059, 24.857175000000041],
            [67.04543300000006, 24.857193000000052],
            [67.045926000000065, 24.857189000000062],
            [67.046132000000057, 24.857179000000031],
            [67.046281000000079, 24.857182000000023],
            [67.046519000000046, 24.857218000000046],
            [67.046789000000047, 24.857271000000026],
            [67.046815740000056, 24.857278048000069],
            [67.046852239000032, 24.857287669000073],
            [67.047286000000042, 24.857402000000036],
            [67.04834500000004, 24.857764000000032],
            [67.050131000000079, 24.858429000000058],
            [67.050161418000073, 24.858440728000062],
            [67.049422991000029, 24.859035410000047],
            [67.049802257000067, 24.859156776000077],
            [67.050242206000064, 24.85933882300003],
            [67.05086420300006, 24.85962706600003],
            [67.051698589000068, 24.859991162000028],
            [67.05170963300003, 24.85910765400007],
            [67.051713760000041, 24.858777509000049],
            [67.052806047000047, 24.859202288000063],
            [67.053200484000058, 24.858231366000041],
            [67.054550672000062, 24.85604679100004],
            [67.056234615000051, 24.853558804000045],
            [67.058070265000083, 24.854560067000023],
            [67.059192893000045, 24.852785100000062],
            [67.058014447000062, 24.852053827000077],
            [67.055308136000065, 24.85037445200004],
            [67.054051293000043, 24.849594530000047],
            [67.054732720000061, 24.847900149000054],
            [67.054639000000066, 24.847718000000043],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "155",
      properties: { name: "Faisal Cantonment", count: 1071 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.188254000000029, 24.924946000000034],
            [67.188252000000034, 24.922827000000041],
            [67.188249000000042, 24.922011000000055],
            [67.188239000000067, 24.921934000000078],
            [67.188216000000068, 24.921799000000078],
            [67.188184000000035, 24.921689000000072],
            [67.188132000000053, 24.921539000000053],
            [67.187926000000061, 24.920961000000034],
            [67.187906000000055, 24.920880000000068],
            [67.187905000000058, 24.920849000000032],
            [67.18790800000005, 24.920819000000051],
            [67.187927000000059, 24.92075600000004],
            [67.187958000000037, 24.92070300000006],
            [67.187984000000029, 24.920673000000079],
            [67.188003000000037, 24.920657000000062],
            [67.188055000000077, 24.92062500000003],
            [67.188120000000083, 24.920606000000078],
            [67.187783918000036, 24.917275131000054],
            [67.191851000000042, 24.91727700000007],
            [67.191883000000075, 24.912278000000072],
            [67.191882000000078, 24.912234000000069],
            [67.18916500000006, 24.912298000000078],
            [67.187365000000057, 24.912327000000062],
            [67.185235000000034, 24.912367000000074],
            [67.185117000000048, 24.912323000000072],
            [67.184979000000055, 24.912289000000044],
            [67.184909000000061, 24.912210000000073],
            [67.184728000000064, 24.911783000000071],
            [67.184556767000061, 24.911330563000035],
            [67.184229319000053, 24.911330563000035],
            [67.183167488000038, 24.913100281000027],
            [67.18244472300006, 24.914304890000039],
            [67.180957560000081, 24.907463939000024],
            [67.179172964000031, 24.902407585000049],
            [67.177983233000077, 24.898838393000062],
            [67.175603772000045, 24.895864067000048],
            [67.178280666000035, 24.894079471000055],
            [67.181552425000064, 24.893187173000058],
            [67.182742155000028, 24.890510280000058],
            [67.179470397000046, 24.886941088000071],
            [67.178578099000049, 24.885453925000036],
            [67.179248912000048, 24.884514786000068],
            [67.179225000000031, 24.884506000000044],
            [67.178863000000035, 24.884392000000048],
            [67.178498000000047, 24.884295000000066],
            [67.178160000000048, 24.884251000000063],
            [67.177265000000034, 24.884137000000067],
            [67.177220768000041, 24.884157689000062],
            [67.177101099000083, 24.884213663000025],
            [67.176955000000078, 24.884282000000042],
            [67.176777000000072, 24.884352000000035],
            [67.176658000000032, 24.884391000000051],
            [67.176573000000076, 24.884415000000047],
            [67.176491497000029, 24.884434498000076],
            [67.176364000000035, 24.884465000000034],
            [67.176240953000047, 24.884488220000037],
            [67.175744000000066, 24.884582000000023],
            [67.175383396000029, 24.884636016000059],
            [67.175041000000078, 24.884682000000055],
            [67.173871082000062, 24.884836340000049],
            [67.172297000000071, 24.88504400000005],
            [67.170823746000053, 24.885232770000073],
            [67.167786000000035, 24.885622000000069],
            [67.167057000000057, 24.885730000000024],
            [67.166618000000028, 24.885913000000073],
            [67.166286550000052, 24.886053437000044],
            [67.165209000000061, 24.886510000000044],
            [67.164970000000039, 24.886614000000066],
            [67.164508000000069, 24.886805000000038],
            [67.164405000000045, 24.886840000000063],
            [67.164164000000028, 24.886920000000032],
            [67.163998000000049, 24.886953000000062],
            [67.163720000000069, 24.886990000000026],
            [67.163270000000068, 24.887004000000047],
            [67.161956000000032, 24.887027000000046],
            [67.161600272000044, 24.887015929000029],
            [67.159514000000058, 24.886951000000067],
            [67.159409442000083, 24.886949432000051],
            [67.158914000000038, 24.886942000000033],
            [67.158094990000052, 24.886947000000077],
            [67.157977995000067, 24.886945000000026],
            [67.157803177000062, 24.886947511000074],
            [67.157312602000047, 24.88695455900006],
            [67.156490015000031, 24.886974558000077],
            [67.156098888000031, 24.886984067000071],
            [67.155896000000041, 24.886989000000028],
            [67.155760000000043, 24.886994000000072],
            [67.155161000000078, 24.887009000000035],
            [67.154666587000065, 24.887005002000024],
            [67.152938729000084, 24.886991030000047],
            [67.152690310000082, 24.886987264000027],
            [67.152290105000077, 24.886981196000079],
            [67.151788664000037, 24.886979066000038],
            [67.150206621000052, 24.886972347000039],
            [67.150151066000035, 24.886974147000046],
            [67.150072303000059, 24.886976700000048],
            [67.150023621000059, 24.886978278000072],
            [67.148785037000039, 24.886963969000078],
            [67.14859050900003, 24.886961722000024],
            [67.146718253000074, 24.886940092000032],
            [67.145703261000051, 24.886928366000063],
            [67.145603158000029, 24.886927209000078],
            [67.144806000000074, 24.886918000000037],
            [67.144533000000081, 24.887966000000063],
            [67.144503557000064, 24.888079198000071],
            [67.144311547000029, 24.888817418000031],
            [67.14428907100006, 24.888903834000075],
            [67.14400500000005, 24.889996000000053],
            [67.143983254000034, 24.890078532000075],
            [67.143878000000029, 24.89047800000003],
            [67.143763000000035, 24.890903000000037],
            [67.14376255500008, 24.890904595000052],
            [67.143623275000039, 24.891403833000027],
            [67.143455000000074, 24.892007000000035],
            [67.143296946000078, 24.892591243000027],
            [67.143248520000043, 24.892770247000044],
            [67.143086000000039, 24.893371000000059],
            [67.143079963000048, 24.893391216000055],
            [67.14307400000007, 24.893416000000059],
            [67.143072000000075, 24.893432000000075],
            [67.143072000000075, 24.893451000000027],
            [67.143073000000072, 24.893464000000051],
            [67.143075000000067, 24.893481000000065],
            [67.14307892000005, 24.893497660000037],
            [67.143087000000037, 24.893509000000051],
            [67.143092245000048, 24.893525227000055],
            [67.143094000000076, 24.89354000000003],
            [67.143093000000079, 24.893550000000062],
            [67.143091000000084, 24.893562000000031],
            [67.143089000000032, 24.893567000000075],
            [67.143086000000039, 24.89357300000006],
            [67.143081000000052, 24.89358100000004],
            [67.143075000000067, 24.893588000000022],
            [67.143061700000032, 24.893598100000077],
            [67.143054000000063, 24.893603000000041],
            [67.143046000000083, 24.893606000000034],
            [67.143035000000054, 24.893608000000029],
            [67.14302500000008, 24.893609000000026],
            [67.143004000000076, 24.893606000000034],
            [67.142673552000076, 24.894856490000052],
            [67.142649688000063, 24.894946794000077],
            [67.14041317300007, 24.895912562000035],
            [67.136279886000068, 24.896179225000026],
            [67.136045233000061, 24.892592308000076],
            [67.135946556000079, 24.889979294000057],
            [67.135613226000032, 24.888579309000079],
            [67.135013233000052, 24.887845984000023],
            [67.134213242000044, 24.887712652000062],
            [67.133418968000058, 24.887741361000053],
            [67.13356900000008, 24.887509000000023],
            [67.133692875000065, 24.887317884000026],
            [67.133182284000043, 24.88734041400005],
            [67.133169000000066, 24.887341000000049],
            [67.13256251100006, 24.88732901000003],
            [67.131493119000083, 24.88730786800005],
            [67.130994000000044, 24.887298000000044],
            [67.130599000000075, 24.887278000000038],
            [67.128965000000051, 24.887206000000049],
            [67.128108994000058, 24.887171641000066],
            [67.127733571000078, 24.887156572000038],
            [67.126785577000078, 24.887118521000048],
            [67.12632086800005, 24.887099868000064],
            [67.125575023000067, 24.887069938000025],
            [67.125506407000046, 24.887067185000035],
            [67.125128000000075, 24.88705200000004],
            [67.124720136000064, 24.887011892000032],
            [67.124825921000081, 24.886043164000057],
            [67.123565083000074, 24.885622885000032],
            [67.123745203000055, 24.884332027000028],
            [67.125276220000046, 24.883011149000026],
            [67.126627118000044, 24.883101208000028],
            [67.129028715000061, 24.879258654000068],
            [67.128878615000076, 24.881209951000073],
            [67.12857841500005, 24.883491468000045],
            [67.129989353000042, 24.883521488000042],
            [67.129929313000048, 24.884812346000047],
            [67.131250191000049, 24.884962446000031],
            [67.13248100900006, 24.884842366000044],
            [67.132811229000083, 24.884271987000034],
            [67.132871269000077, 24.883371388000057],
            [67.132511029000057, 24.882801009000048],
            [67.132030710000038, 24.881690271000025],
            [67.131893240000068, 24.881340347000048],
            [67.13180200000005, 24.881462000000056],
            [67.131530000000055, 24.880993000000046],
            [67.131417000000056, 24.88079300000004],
            [67.131300000000067, 24.880520000000047],
            [67.131213000000059, 24.880223000000058],
            [67.131175000000042, 24.880069000000049],
            [67.131174000000044, 24.879917000000034],
            [67.13120500000008, 24.879587000000072],
            [67.131232361000059, 24.879311066000071],
            [67.131264000000044, 24.878992000000039],
            [67.131283151000048, 24.878741382000044],
            [67.131322000000068, 24.878233000000023],
            [67.13134000000008, 24.877926000000059],
            [67.131404411000062, 24.877880098000048],
            [67.131427000000031, 24.877864000000045],
            [67.131489000000045, 24.877799000000039],
            [67.131592000000069, 24.87769000000003],
            [67.131635300000028, 24.877584360000071],
            [67.131733000000054, 24.877346000000045],
            [67.13180200000005, 24.876794000000075],
            [67.131883000000073, 24.876640000000066],
            [67.13171600000004, 24.876584000000037],
            [67.131497000000081, 24.876547000000073],
            [67.131483359000072, 24.876544557000045],
            [67.13143000000008, 24.876535000000047],
            [67.131366000000071, 24.876524000000074],
            [67.131305000000054, 24.87651500000004],
            [67.131271000000083, 24.876508000000058],
            [67.131244000000038, 24.876496000000031],
            [67.131200000000035, 24.876455000000078],
            [67.131155000000035, 24.876389000000074],
            [67.13110400000005, 24.876320000000078],
            [67.131035000000054, 24.87624100000005],
            [67.130958000000078, 24.876166000000069],
            [67.130895000000066, 24.87611400000003],
            [67.130821000000083, 24.876078000000064],
            [67.130733000000077, 24.876043000000038],
            [67.130603000000065, 24.87601200000006],
            [67.130478000000039, 24.875982000000079],
            [67.130382000000054, 24.875960000000077],
            [67.130272000000048, 24.875926000000049],
            [67.129808000000082, 24.875799000000029],
            [67.128775000000076, 24.875548000000038],
            [67.127827000000082, 24.875298000000043],
            [67.127818975000082, 24.875295761000075],
            [67.127085000000079, 24.875091000000054],
            [67.126448000000039, 24.87489400000004],
            [67.125983000000076, 24.874750000000063],
            [67.124885000000063, 24.874202000000025],
            [67.124758000000043, 24.874307000000044],
            [67.124140000000068, 24.874068000000022],
            [67.123194353000031, 24.873759878000044],
            [67.122515283000041, 24.873538615000029],
            [67.122007000000053, 24.873373000000072],
            [67.11985472300006, 24.872585582000056],
            [67.119219000000044, 24.872353000000032],
            [67.119098000000065, 24.87230900000003],
            [67.118644823000068, 24.872063479000076],
            [67.116453000000035, 24.870876000000067],
            [67.11542600000007, 24.870300000000043],
            [67.114617000000067, 24.869777000000056],
            [67.113107653000043, 24.868845102000023],
            [67.112309000000039, 24.868352000000073],
            [67.111999000000083, 24.868017000000066],
            [67.111765000000048, 24.867784000000029],
            [67.111451000000045, 24.867512000000033],
            [67.111419000000069, 24.867490000000032],
            [67.111380000000054, 24.867469000000028],
            [67.111342000000036, 24.867452000000071],
            [67.11131000000006, 24.867441000000042],
            [67.111276000000032, 24.867431000000067],
            [67.111238000000071, 24.867424000000028],
            [67.111199000000056, 24.867420000000038],
            [67.110979727000029, 24.866859122000051],
            [67.113384330000031, 24.866929008000056],
            [67.113704787000074, 24.866918375000068],
            [67.116101774000072, 24.866838838000035],
            [67.118046394000032, 24.866884299000048],
            [67.119222767000053, 24.866863557000045],
            [67.120197216000065, 24.866846375000023],
            [67.120762358000036, 24.866913010000076],
            [67.122116835000043, 24.867072712000038],
            [67.123936739000044, 24.867593095000075],
            [67.12401152800004, 24.867638337000074],
            [67.124963654000055, 24.868098188000033],
            [67.125229760000082, 24.868226709000055],
            [67.125557201000049, 24.868384854000055],
            [67.126476083000057, 24.868689260000053],
            [67.127127847000054, 24.868905176000055],
            [67.127177708000033, 24.868927797000026],
            [67.127328261000059, 24.868979615000057],
            [67.128623743000048, 24.869425499000045],
            [67.130204072000083, 24.86973551400007],
            [67.130221219000077, 24.869236120000039],
            [67.130233000000032, 24.868893000000071],
            [67.130245000000059, 24.867976000000056],
            [67.130241000000069, 24.86779000000007],
            [67.130207000000041, 24.867496000000074],
            [67.130130000000065, 24.867079000000047],
            [67.130096000000037, 24.86687500000005],
            [67.130058000000076, 24.866596000000072],
            [67.12999700000006, 24.866144000000077],
            [67.129937000000041, 24.865704000000051],
            [67.129924000000074, 24.865611000000058],
            [67.129835000000071, 24.865408000000059],
            [67.129811000000075, 24.865338000000065],
            [67.129797000000053, 24.865207000000055],
            [67.129774000000054, 24.864992000000029],
            [67.129785000000084, 24.864858000000027],
            [67.129810000000077, 24.864574000000061],
            [67.129832000000079, 24.864256000000069],
            [67.129837000000066, 24.864179000000036],
            [67.129837000000066, 24.864158000000032],
            [67.129835000000071, 24.864133000000038],
            [67.129831000000081, 24.864115000000027],
            [67.129826000000037, 24.864094000000023],
            [67.129822000000047, 24.864082000000053],
            [67.12981300000007, 24.864061000000049],
            [67.129797000000053, 24.864034000000061],
            [67.129780000000039, 24.864010000000064],
            [67.129729000000054, 24.863945000000058],
            [67.129673000000082, 24.863875000000064],
            [67.129638000000057, 24.863831000000062],
            [67.129562000000078, 24.863734000000079],
            [67.129503000000057, 24.863656000000049],
            [67.129406000000074, 24.863528000000031],
            [67.12925400000006, 24.863327000000027],
            [67.128988916000083, 24.862990297000067],
            [67.128969000000041, 24.862965000000031],
            [67.128635000000031, 24.862542000000076],
            [67.128294000000039, 24.862108000000035],
            [67.127709000000038, 24.861353000000065],
            [67.127178000000072, 24.860677000000067],
            [67.126998000000071, 24.860501000000056],
            [67.12697137300006, 24.860467193000034],
            [67.125999269000033, 24.860250046000033],
            [67.123797319000062, 24.859985495000046],
            [67.118324657000073, 24.85922110000007],
            [67.113267807000057, 24.858426609000048],
            [67.111872659000028, 24.857981000000052],
            [67.111519264000037, 24.857868126000028],
            [67.109576438000033, 24.856898225000066],
            [67.105487176000054, 24.854405190000023],
            [67.102767497000059, 24.852759288000073],
            [67.09834438200005, 24.849895160000074],
            [67.096531605000052, 24.84807309100006],
            [67.096210296000038, 24.847657127000048],
            [67.096043000000066, 24.848311000000024],
            [67.095878000000027, 24.848880000000065],
            [67.095848412000066, 24.848965828000075],
            [67.095757000000049, 24.849231000000032],
            [67.095581000000038, 24.849656000000039],
            [67.09549700000008, 24.849781000000064],
            [67.095387000000073, 24.849927000000037],
            [67.095342000000073, 24.849974000000032],
            [67.095157000000029, 24.850169000000051],
            [67.094767000000047, 24.850581000000034],
            [67.094251000000042, 24.85102100000006],
            [67.09401100000008, 24.851229000000046],
            [67.093900000000076, 24.851337000000058],
            [67.093750000000057, 24.851489000000072],
            [67.09360300000003, 24.851667000000077],
            [67.093467000000032, 24.851769000000047],
            [67.093074000000058, 24.852036000000055],
            [67.092961000000059, 24.852110000000039],
            [67.092893000000061, 24.852152000000046],
            [67.09272100000004, 24.852342000000078],
            [67.09261500000008, 24.852470000000039],
            [67.092502000000081, 24.852608000000032],
            [67.092356000000052, 24.85277300000007],
            [67.092124000000069, 24.853012000000035],
            [67.091901000000064, 24.853238000000033],
            [67.091691000000083, 24.853455000000054],
            [67.09142600000007, 24.853786000000071],
            [67.091386000000057, 24.853834000000063],
            [67.091296000000057, 24.853934000000038],
            [67.091218000000083, 24.854019000000051],
            [67.091204000000062, 24.854030000000023],
            [67.09117800000007, 24.854050000000029],
            [67.091146000000037, 24.854070000000036],
            [67.091133697000032, 24.854075884000054],
            [67.091123000000039, 24.854081000000065],
            [67.091105000000027, 24.854089000000045],
            [67.091083000000083, 24.854097000000024],
            [67.091066000000069, 24.854102000000069],
            [67.091038000000083, 24.854108000000053],
            [67.091005000000052, 24.854112000000043],
            [67.090783000000044, 24.854125000000067],
            [67.090593598000055, 24.854136033000032],
            [67.090593581000064, 24.854184017000023],
            [67.090592954000044, 24.855952590000072],
            [67.09059459000008, 24.857221816000049],
            [67.088158580000083, 24.859577286000047],
            [67.086444931000074, 24.861433740000052],
            [67.08458938900003, 24.863443911000047],
            [67.084420006000073, 24.865669979000074],
            [67.085927945000037, 24.86634411600005],
            [67.090873305000059, 24.869146756000077],
            [67.092566461000047, 24.870098731000041],
            [67.097101489000067, 24.87264854600005],
            [67.096326092000083, 24.873924198000054],
            [67.095300568000027, 24.876000259000023],
            [67.093974891000073, 24.878301436000072],
            [67.092599187000076, 24.880502561000071],
            [67.091598676000046, 24.882353507000062],
            [67.089722717000029, 24.885254990000078],
            [67.088847269000041, 24.886705731000063],
            [67.088373371000046, 24.887515070000063],
            [67.088251351000054, 24.887721565000049],
            [67.088119945000074, 24.887815426000031],
            [67.087932223000053, 24.887693407000029],
            [67.087566163000076, 24.887430595000069],
            [67.087369054000078, 24.88720532800005],
            [67.087293965000072, 24.88688619900006],
            [67.087181332000057, 24.886792338000078],
            [67.087059312000065, 24.886811110000053],
            [67.086815272000081, 24.886998833000064],
            [67.08659939100005, 24.887158397000064],
            [67.086421055000073, 24.887252258000046],
            [67.086317807000057, 24.887195941000073],
            [67.086148857000069, 24.887102080000034],
            [67.085970520000046, 24.887083308000058],
            [67.085810956000046, 24.88720532800005],
            [67.085585688000037, 24.887364892000051],
            [67.085463669000035, 24.887477526000055],
            [67.085294718000057, 24.887458753000033],
            [67.085163312000077, 24.887346120000075],
            [67.084938045000058, 24.887327347000053],
            [67.084618916000068, 24.887533842000039],
            [67.084412421000081, 24.887637090000055],
            [67.084281016000034, 24.887749724000059],
            [67.08422469900006, 24.887862357000074],
            [67.084121451000044, 24.888087624000036],
            [67.084055748000083, 24.888265961000059],
            [67.083933728000034, 24.888303506000057],
            [67.083670917000063, 24.888312892000044],
            [67.083445649000055, 24.888265961000059],
            [67.083342402000028, 24.888172100000077],
            [67.083276699000066, 24.88806885200006],
            [67.08320161000006, 24.887984377000066],
            [67.083088976000056, 24.888087624000036],
            [67.082966956000064, 24.888228416000061],
            [67.082873095000082, 24.888294119000022],
            [67.082732303000057, 24.888387981000051],
            [67.082516422000083, 24.888359822000041],
            [67.082450719000065, 24.888425525000059],
            [67.082413174000067, 24.888585090000049],
            [67.08230992700004, 24.88893237700006],
            [67.082234838000034, 24.889289050000059],
            [67.082000184000037, 24.889702040000032],
            [67.081793689000051, 24.890133802000037],
            [67.081615353000075, 24.890509248000058],
            [67.081512105000058, 24.890753287000052],
            [67.08147456100005, 24.890875307000044],
            [67.082985729000029, 24.891522950000024],
            [67.087479317000032, 24.893209780000063],
            [67.087175181000077, 24.894150700000068],
            [67.086852037000028, 24.895148646000052],
            [67.086623935000034, 24.895880473000034],
            [67.086566910000045, 24.896175105000054],
            [67.086661952000043, 24.896336677000079],
            [67.086842533000038, 24.896621805000052],
            [67.086985097000081, 24.896792881000067],
            [67.087004105000062, 24.897049496000022],
            [67.08705210800008, 24.897351230000027],
            [67.087156173000039, 24.898332569000047],
            [67.087374771000043, 24.898342073000038],
            [67.087469813000041, 24.897933391000038],
            [67.087697915000035, 24.897534212000039],
            [67.08780246200007, 24.897344127000054],
            [67.088125606000062, 24.897401153000033],
            [67.088714870000047, 24.897534212000039],
            [67.089437192000048, 24.897800331000042],
            [67.089712815000041, 24.897800331000042],
            [67.089978934000044, 24.897695784000064],
            [67.090273566000064, 24.897629255000027],
            [67.090549189000058, 24.897790827000051],
            [67.09081530800006, 24.897800331000042],
            [67.091281016000039, 24.897781323000061],
            [67.091794246000063, 24.897724297000025],
            [67.092088877000037, 24.897610246000056],
            [67.092621115000043, 24.897524708000049],
            [67.092830209000056, 24.897496195000031],
            [67.092953764000072, 24.897543717000076],
            [67.093010789000061, 24.89783834800005],
            [67.093143849000057, 24.899111917000027],
            [67.093286412000054, 24.90001482100007],
            [67.093742616000043, 24.899957795000034],
            [67.093961214000046, 24.899938787000053],
            [67.094008735000045, 24.900166889000047],
            [67.094196169000043, 24.901593382000044],
            [67.094624472000078, 24.901621936000026],
            [67.095024221000074, 24.901643351000075],
            [67.095274065000069, 24.901821811000048],
            [67.095521291000068, 24.902101794000032],
            [67.095794929000078, 24.902583635000042],
            [67.095943645000034, 24.902833478000048],
            [67.095949594000047, 24.903124962000049],
            [67.095973388000061, 24.90339860000006],
            [67.096276769000042, 24.90328557600003],
            [67.096312461000082, 24.903124962000049],
            [67.096330307000073, 24.902470610000023],
            [67.096502818000033, 24.900882320000051],
            [67.096568253000044, 24.900828782000076],
            [67.096847840000066, 24.900846628000068],
            [67.097674703000052, 24.900989396000057],
            [67.097847214000069, 24.900906115000055],
            [67.098019725000029, 24.900745501000074],
            [67.098037571000077, 24.900656271000059],
            [67.097478397000032, 24.900340993000043],
            [67.096758610000052, 24.900061406000077],
            [67.09625892300005, 24.899835357000029],
            [67.096205386000065, 24.899746127000071],
            [67.096252975000084, 24.899573617000044],
            [67.096645586000079, 24.898657524000043],
            [67.097073889000058, 24.897675996000032],
            [67.097424859000057, 24.896884826000075],
            [67.097472449000065, 24.896783699000025],
            [67.097609268000042, 24.896652828000072],
            [67.097835316000044, 24.896587393000061],
            [67.098198184000069, 24.896605239000053],
            [67.098781152000072, 24.89659929000004],
            [67.098995304000027, 24.896557650000034],
            [67.099096431000078, 24.896420831000057],
            [67.099191609000059, 24.896248320000041],
            [67.099358171000063, 24.896176936000074],
            [67.099572323000075, 24.89621857700007],
            [67.099917345000051, 24.896367293000026],
            [67.10047651800005, 24.896545752000065],
            [67.100904821000029, 24.896670674000063],
            [67.102195679000033, 24.897015696000039],
            [67.103625735000037, 24.897410290000039],
            [67.104063556000028, 24.897619682000027],
            [67.10450137600003, 24.897924254000031],
            [67.105072447000055, 24.898324003000027],
            [67.105543580000074, 24.898685681000075],
            [67.105817218000084, 24.898828449000064],
            [67.106174137000039, 24.898947422000049],
            [67.106757105000042, 24.899090189000049],
            [67.107482841000035, 24.899304341000061],
            [67.107804068000064, 24.89944710900005],
            [67.108280000000036, 24.900091000000032],
            [67.108613000000048, 24.900197000000048],
            [67.109136000000035, 24.900369000000069],
            [67.109331000000054, 24.900450000000035],
            [67.10946400000006, 24.900516000000039],
            [67.109813000000031, 24.900685000000067],
            [67.110254000000054, 24.900888000000066],
            [67.110483000000045, 24.900996000000077],
            [67.110631000000069, 24.90105200000005],
            [67.110862000000054, 24.901126000000033],
            [67.111165000000028, 24.901207000000056],
            [67.111307000000068, 24.901234000000045],
            [67.111591000000033, 24.901257000000044],
            [67.111744000000044, 24.90126900000007],
            [67.111982000000069, 24.901250000000061],
            [67.112132000000031, 24.901230000000055],
            [67.112297000000069, 24.901193000000035],
            [67.112696000000028, 24.901079000000038],
            [67.113334000000066, 24.900899000000038],
            [67.11349000000007, 24.900851000000046],
            [67.113589000000047, 24.900824000000057],
            [67.11367000000007, 24.90080800000004],
            [67.113766000000055, 24.900800000000061],
            [67.11385800000005, 24.900803000000053],
            [67.114269000000036, 24.900807000000043],
            [67.114824000000056, 24.900807000000043],
            [67.115596000000039, 24.900785000000042],
            [67.116041000000052, 24.900710000000061],
            [67.116249000000039, 24.900697000000036],
            [67.116178000000048, 24.900965000000042],
            [67.116262000000063, 24.900961000000052],
            [67.116866000000073, 24.901114000000064],
            [67.116998000000081, 24.901227000000063],
            [67.11729100000008, 24.901496000000066],
            [67.117402000000084, 24.901603000000023],
            [67.117736000000036, 24.901872000000026],
            [67.117932000000053, 24.902001000000041],
            [67.118239000000074, 24.90220400000004],
            [67.11891200000008, 24.902649000000054],
            [67.118912068000043, 24.902649045000032],
            [67.118937000000074, 24.902651000000048],
            [67.119192000000055, 24.902671000000055],
            [67.119477000000074, 24.902694000000054],
            [67.119743000000028, 24.902715000000057],
            [67.119890000000055, 24.902727000000027],
            [67.119748000000072, 24.903080000000045],
            [67.11970800000006, 24.903242000000034],
            [67.11989600000004, 24.903399000000036],
            [67.120081000000027, 24.90355500000004],
            [67.120289000000071, 24.903729000000055],
            [67.12128000000007, 24.902640000000076],
            [67.121675000000039, 24.902807000000053],
            [67.122307000000035, 24.903046000000074],
            [67.122911000000045, 24.903265000000033],
            [67.123113000000046, 24.903335000000027],
            [67.123582000000056, 24.903358000000026],
            [67.12419600000004, 24.903389000000061],
            [67.124234000000058, 24.903390000000059],
            [67.124460000000056, 24.903399000000036],
            [67.124859000000072, 24.903414000000055],
            [67.124872597000035, 24.903264628000045],
            [67.125342106000062, 24.90326477900004],
            [67.125694907000081, 24.903264893000028],
            [67.128776000000073, 24.904680000000042],
            [67.129398000000037, 24.904733000000078],
            [67.131458000000066, 24.904860000000042],
            [67.132237000000032, 24.904909000000032],
            [67.133000000000038, 24.904970000000048],
            [67.133656694000081, 24.905022805000044],
            [67.133763988000055, 24.904620453000064],
            [67.134030487000075, 24.903577060000032],
            [67.134144702000071, 24.903305801000045],
            [67.13424463900003, 24.90292032800005],
            [67.134316023000054, 24.902549133000036],
            [67.134387407000077, 24.902334981000024],
            [67.13457300500005, 24.902106553000067],
            [67.134858540000039, 24.901835294000023],
            [67.135101245000044, 24.901435545000027],
            [67.135229736000042, 24.901264224000045],
            [67.135415334000072, 24.901435545000027],
            [67.135572378000063, 24.901692527000023],
            [67.135600932000045, 24.901920955000037],
            [67.13554382500007, 24.90243491800004],
            [67.135572378000063, 24.902763284000059],
            [67.135643762000029, 24.903120203000071],
            [67.136043511000082, 24.903505676000066],
            [67.136657412000034, 24.904105300000026],
            [67.137214206000067, 24.904562157000043],
            [67.137870938000049, 24.904904799000064],
            [67.13807081200008, 24.905104674000029],
            [67.138142196000047, 24.905418763000057],
            [67.138113643000054, 24.905875619000028],
            [67.138113643000054, 24.906275368000024],
            [67.13821358000007, 24.906532350000077],
            [67.138541946000032, 24.906732225000042],
            [67.138770374000046, 24.906789332000074],
            [67.139141570000049, 24.906832162000057],
            [67.139327168000079, 24.906917823000072],
            [67.139669810000044, 24.907160528000077],
            [67.139912515000049, 24.907488894000039],
            [67.14011239000007, 24.908431160000077],
            [67.140326541000036, 24.909302043000025],
            [67.140397925000059, 24.909944497000026],
            [67.14041220200005, 24.910429907000037],
            [67.14041220200005, 24.91084393400007],
            [67.140297988000043, 24.911158022000052],
            [67.140240881000068, 24.911372174000064],
            [67.140198050000038, 24.911643433000052],
            [67.140226604000077, 24.911957521000033],
            [67.140312265000034, 24.912086012000032],
            [67.140583523000032, 24.91240010100006],
            [67.140911889000051, 24.91255714600004],
            [67.141254531000072, 24.912657083000056],
            [67.141540066000061, 24.912842681000029],
            [67.141839878000042, 24.913071109000043],
            [67.142096860000038, 24.913599349000037],
            [67.142096860000038, 24.913941992000048],
            [67.142111137000029, 24.914241804000028],
            [67.142325289000041, 24.914513062000026],
            [67.142625101000078, 24.914812875000052],
            [67.142753592000076, 24.915027026000075],
            [67.143183616000044, 24.914899755000079],
            [67.144997000000046, 24.914438000000075],
            [67.145091000000036, 24.914334000000053],
            [67.149096000000043, 24.912462000000062],
            [67.149168000000031, 24.913018000000022],
            [67.149499293000076, 24.913290198000027],
            [67.149538000000064, 24.913322000000051],
            [67.149853000000064, 24.913581000000079],
            [67.150111000000038, 24.913793000000055],
            [67.150641000000064, 24.914229000000034],
            [67.150880000000029, 24.914421000000061],
            [67.151123000000041, 24.914625000000058],
            [67.151399000000083, 24.914853000000051],
            [67.151657000000057, 24.915064000000029],
            [67.151717000000076, 24.915113000000076],
            [67.151917000000083, 24.915278000000058],
            [67.15198300000003, 24.915333000000032],
            [67.152672000000052, 24.915908000000059],
            [67.15290600000003, 24.916104000000075],
            [67.153263000000038, 24.916403000000059],
            [67.153548000000058, 24.916641000000027],
            [67.153818000000058, 24.916867000000025],
            [67.15421200000003, 24.917196000000047],
            [67.154348000000084, 24.917310000000043],
            [67.154632000000049, 24.917548000000068],
            [67.155007000000069, 24.917861000000073],
            [67.155451000000028, 24.918233000000043],
            [67.155519846000061, 24.918290372000058],
            [67.155748275000064, 24.918480729000066],
            [67.15581700000007, 24.918538000000069],
            [67.156148024000061, 24.918815516000052],
            [67.156182000000058, 24.918844000000036],
            [67.156262238000068, 24.918910937000078],
            [67.15655600000008, 24.919156000000044],
            [67.156989556000042, 24.919519627000057],
            [67.156956000000037, 24.919567000000029],
            [67.156749332000061, 24.919858930000032],
            [67.15660500000007, 24.920060000000035],
            [67.156506000000036, 24.920200000000023],
            [67.156465000000082, 24.920270000000073],
            [67.156333000000075, 24.920495000000074],
            [67.156262238000068, 24.920630262000032],
            [67.156220000000076, 24.92071100000004],
            [67.156148024000061, 24.920851261000053],
            [67.15610300000003, 24.920939000000033],
            [67.156040000000075, 24.921065000000056],
            [67.15604200000007, 24.921080000000075],
            [67.156123000000036, 24.921792000000039],
            [67.156142939000063, 24.92196081700007],
            [67.156148024000061, 24.922003870000026],
            [67.156153000000074, 24.922046000000023],
            [67.156194000000028, 24.92241000000007],
            [67.156201000000067, 24.922466000000043],
            [67.156216000000029, 24.922522000000072],
            [67.156262238000068, 24.922601472000053],
            [67.156280000000038, 24.922632000000078],
            [67.156416000000036, 24.922863000000063],
            [67.156445696000048, 24.922913950000066],
            [67.156512267000039, 24.923028164000073],
            [67.156518000000062, 24.923038000000076],
            [67.156660000000045, 24.923280000000034],
            [67.156914000000029, 24.923714000000075],
            [67.156920000000071, 24.92372400000005],
            [67.156926000000055, 24.923730000000035],
            [67.15693200000004, 24.92373200000003],
            [67.156940000000077, 24.923734000000024],
            [67.157290165000063, 24.923801250000054],
            [67.15754400000003, 24.923850000000073],
            [67.157575701000042, 24.923856639000064],
            [67.157926000000032, 24.923930000000041],
            [67.158190000000047, 24.92398600000007],
            [67.158584000000076, 24.924066000000039],
            [67.159581028000048, 24.924200868000071],
            [67.159826164000037, 24.924234064000075],
            [67.160176059000037, 24.924281446000066],
            [67.160350000000051, 24.924305000000061],
            [67.160364000000072, 24.924304000000063],
            [67.160551174000034, 24.92429581600004],
            [67.160821960000078, 24.924283976000027],
            [67.161073000000044, 24.924273000000028],
            [67.161230862000082, 24.924266129000046],
            [67.161595881000039, 24.924250242000028],
            [67.161920922000036, 24.924236096000072],
            [67.162038000000052, 24.924231000000077],
            [67.162164000000075, 24.924210000000073],
            [67.162250586000027, 24.924195612000062],
            [67.163169000000039, 24.92404300000004],
            [67.16318700000005, 24.924254000000076],
            [67.163418000000036, 24.924154000000044],
            [67.165010000000052, 24.923461000000032],
            [67.165521000000069, 24.923232000000041],
            [67.16597652400003, 24.923028164000073],
            [67.166231765000077, 24.922913950000066],
            [67.166254000000038, 24.922904000000074],
            [67.166262463000066, 24.922913950000066],
            [67.166328000000078, 24.922991000000025],
            [67.166359495000052, 24.923028164000073],
            [67.166828000000066, 24.92358100000007],
            [67.167443000000048, 24.924307000000056],
            [67.167462000000057, 24.924329000000057],
            [67.168773000000044, 24.923743000000059],
            [67.169264000000055, 24.923522000000048],
            [67.170078000000046, 24.923170000000027],
            [67.170355000000029, 24.923051000000044],
            [67.170429657000057, 24.923028164000073],
            [67.170440000000042, 24.923025000000052],
            [67.170506000000046, 24.923014000000023],
            [67.170593000000054, 24.923004000000049],
            [67.171392000000083, 24.923004000000049],
            [67.171432000000038, 24.923007000000041],
            [67.171482000000083, 24.923016000000075],
            [67.171519303000082, 24.923028164000073],
            [67.17152800000008, 24.923031000000037],
            [67.171598000000074, 24.923059000000023],
            [67.17241400000006, 24.923376000000076],
            [67.172466000000043, 24.923398000000077],
            [67.17250400000006, 24.923417000000029],
            [67.172541000000081, 24.923429000000056],
            [67.172611000000074, 24.923455000000047],
            [67.173107000000073, 24.923596000000032],
            [67.17412500000006, 24.923874000000069],
            [67.175154000000077, 24.924154000000044],
            [67.176006000000029, 24.924396000000058],
            [67.177087000000029, 24.924705000000074],
            [67.178409000000045, 24.925068000000067],
            [67.179547000000071, 24.925389000000052],
            [67.179878190000068, 24.925480822000054],
            [67.179888302000052, 24.925483626000073],
            [67.180070149000073, 24.925534043000027],
            [67.180127129000084, 24.925546329000042],
            [67.179680935000079, 24.926799902000027],
            [67.179567000000077, 24.927120000000059],
            [67.178989000000058, 24.928688000000022],
            [67.178731000000028, 24.929435000000069],
            [67.177924000000075, 24.931679000000031],
            [67.177243000000033, 24.933543000000043],
            [67.177033110000082, 24.934164699000064],
            [67.178318845000035, 24.933949828000038],
            [67.179658665000034, 24.933717888000047],
            [67.181139130000076, 24.933456340000077],
            [67.182296360000066, 24.933241672000065],
            [67.183685529000059, 24.932975189000047],
            [67.184556536000059, 24.932812338000076],
            [67.185716233000051, 24.932592735000071],
            [67.186503347000041, 24.932444689000079],
            [67.188245360000053, 24.932101715000044],
            [67.188339123000048, 24.932091845000059],
            [67.188423363000084, 24.932085163000067],
            [67.188449000000048, 24.93201700000003],
            [67.188447000000053, 24.931905000000029],
            [67.188439000000074, 24.931831000000045],
            [67.18841800000007, 24.931763000000046],
            [67.188350000000071, 24.93164500000006],
            [67.188298000000032, 24.931540000000041],
            [67.188282000000072, 24.931494000000043],
            [67.188271000000043, 24.931456000000026],
            [67.188261000000068, 24.931410000000028],
            [67.188259000000073, 24.931247000000042],
            [67.188259000000073, 24.930794000000049],
            [67.188259000000073, 24.92984400000006],
            [67.188258000000076, 24.929103000000055],
            [67.188258000000076, 24.929016000000047],
            [67.188257000000078, 24.928446000000065],
            [67.188256000000081, 24.927901000000077],
            [67.188256000000081, 24.927074000000061],
            [67.188254000000029, 24.925801000000035],
            [67.188254000000029, 24.924946000000034],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "156",
      properties: { name: "Karachi South", count: 178 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.047874883000077, 24.893143949000034],
            [67.051478601000042, 24.89245425200005],
            [67.051119208000046, 24.891793931000052],
            [67.050229384000033, 24.890407916000072],
            [67.053572527000028, 24.888159325000061],
            [67.056079251000028, 24.886471785000026],
            [67.056397319000041, 24.887284626000053],
            [67.05904477100006, 24.885285837000026],
            [67.059530181000071, 24.884971749000044],
            [67.060044144000074, 24.884610071000054],
            [67.060710393000079, 24.884153214000037],
            [67.066748514000039, 24.879803559000038],
            [67.069899971000041, 24.877570002000027],
            [67.07066986500007, 24.877024349000067],
            [67.072421149000036, 24.875844136000069],
            [67.073487147000037, 24.875101744000062],
            [67.074724467000067, 24.874416460000077],
            [67.078017641000031, 24.872836498000026],
            [67.07952146000008, 24.872036999000045],
            [67.080035424000073, 24.871503999000026],
            [67.080720709000047, 24.870514144000026],
            [67.081805743000075, 24.869143574000077],
            [67.082806409000057, 24.86771595700003],
            [67.083233419000067, 24.867106755000066],
            [67.084420006000073, 24.865669979000074],
            [67.08458938900003, 24.863443911000047],
            [67.086444931000074, 24.861433740000052],
            [67.088158580000083, 24.859577286000047],
            [67.09059459000008, 24.857221816000049],
            [67.090592954000044, 24.855952590000072],
            [67.090593581000064, 24.854184017000023],
            [67.090593598000055, 24.854136033000032],
            [67.090783000000044, 24.854125000000067],
            [67.091005000000052, 24.854112000000043],
            [67.091038000000083, 24.854108000000053],
            [67.091066000000069, 24.854102000000069],
            [67.091083000000083, 24.854097000000024],
            [67.091105000000027, 24.854089000000045],
            [67.091123000000039, 24.854081000000065],
            [67.091133697000032, 24.854075884000054],
            [67.091146000000037, 24.854070000000036],
            [67.09117800000007, 24.854050000000029],
            [67.091204000000062, 24.854030000000023],
            [67.091218000000083, 24.854019000000051],
            [67.091296000000057, 24.853934000000038],
            [67.091386000000057, 24.853834000000063],
            [67.09142600000007, 24.853786000000071],
            [67.091691000000083, 24.853455000000054],
            [67.091901000000064, 24.853238000000033],
            [67.092124000000069, 24.853012000000035],
            [67.092356000000052, 24.85277300000007],
            [67.092502000000081, 24.852608000000032],
            [67.09261500000008, 24.852470000000039],
            [67.09272100000004, 24.852342000000078],
            [67.092893000000061, 24.852152000000046],
            [67.092961000000059, 24.852110000000039],
            [67.093074000000058, 24.852036000000055],
            [67.093467000000032, 24.851769000000047],
            [67.09360300000003, 24.851667000000077],
            [67.093750000000057, 24.851489000000072],
            [67.093900000000076, 24.851337000000058],
            [67.09401100000008, 24.851229000000046],
            [67.094251000000042, 24.85102100000006],
            [67.094767000000047, 24.850581000000034],
            [67.095157000000029, 24.850169000000051],
            [67.095342000000073, 24.849974000000032],
            [67.095387000000073, 24.849927000000037],
            [67.09549700000008, 24.849781000000064],
            [67.095581000000038, 24.849656000000039],
            [67.095757000000049, 24.849231000000032],
            [67.095848412000066, 24.848965828000075],
            [67.095878000000027, 24.848880000000065],
            [67.096043000000066, 24.848311000000024],
            [67.096210296000038, 24.847657127000048],
            [67.094783670000083, 24.845810229000051],
            [67.093084719000046, 24.842829794000068],
            [67.091984638000042, 24.840352811000059],
            [67.091913998000052, 24.840025781000065],
            [67.091832669000041, 24.839649272000031],
            [67.091499691000081, 24.838107757000046],
            [67.091522932000032, 24.836639590000061],
            [67.091532715000028, 24.836021652000056],
            [67.091606701000046, 24.835670813000036],
            [67.091948891000072, 24.834048161000055],
            [67.091044090000082, 24.833898630000078],
            [67.089360556000031, 24.833452989000023],
            [67.083072061000053, 24.832512191000035],
            [67.083088224000051, 24.830991769000036],
            [67.08244757500006, 24.830852078000078],
            [67.080556478000062, 24.830439734000038],
            [67.080423228000029, 24.829468914000074],
            [67.08033692500004, 24.830020170000068],
            [67.080001000000038, 24.830131000000051],
            [67.079743993000079, 24.830627395000079],
            [67.079578000000083, 24.830948000000035],
            [67.079593000000045, 24.831031000000053],
            [67.079660000000047, 24.831172000000038],
            [67.079869000000031, 24.831585000000075],
            [67.079875524000045, 24.831598780000036],
            [67.079967000000067, 24.831792000000064],
            [67.080128000000059, 24.832135000000051],
            [67.080340000000035, 24.832535000000064],
            [67.080590000000029, 24.833030000000065],
            [67.080839384000058, 24.833560872000078],
            [67.081025000000068, 24.833956000000057],
            [67.081218000000035, 24.834422000000075],
            [67.081490000000031, 24.835369000000071],
            [67.081831000000079, 24.836645000000033],
            [67.081944069000031, 24.837029807000079],
            [67.081982550000077, 24.837160770000025],
            [67.082317000000046, 24.838299000000063],
            [67.082797000000028, 24.83994000000007],
            [67.083327000000054, 24.841493000000071],
            [67.083453089000045, 24.841830462000075],
            [67.08363600000007, 24.842320000000029],
            [67.083889000000056, 24.842863000000023],
            [67.084312000000068, 24.843545000000063],
            [67.08392200000003, 24.843557000000033],
            [67.083807000000036, 24.843327000000045],
            [67.083402000000035, 24.843297000000064],
            [67.083247056000062, 24.843285210000033],
            [67.08253232800007, 24.843230825000035],
            [67.082051181000054, 24.84319421400005],
            [67.081802354000047, 24.843175280000025],
            [67.081089000000077, 24.843121000000053],
            [67.079385000000059, 24.842945000000043],
            [67.079028000000051, 24.842908000000023],
            [67.078863000000069, 24.842891000000066],
            [67.077901000000054, 24.842791000000034],
            [67.077771000000041, 24.842637000000025],
            [67.077805000000069, 24.842470000000048],
            [67.077834000000053, 24.842283000000066],
            [67.077844000000027, 24.842083000000059],
            [67.077840000000037, 24.841891000000032],
            [67.077840000000037, 24.841830462000075],
            [67.077840000000037, 24.84174500000006],
            [67.077841090000049, 24.841658365000058],
            [67.077844000000027, 24.841427000000067],
            [67.077852000000064, 24.841207000000054],
            [67.077856000000054, 24.841001000000063],
            [67.077858000000049, 24.84087500000004],
            [67.077877000000058, 24.840330000000051],
            [67.077897000000064, 24.840059000000053],
            [67.077909000000034, 24.839854000000059],
            [67.077924000000053, 24.839536000000066],
            [67.077922000000058, 24.839230000000043],
            [67.077922000000058, 24.839026000000047],
            [67.077919000000065, 24.838825000000043],
            [67.077893000000074, 24.838521000000071],
            [67.077871000000073, 24.838276000000064],
            [67.077858000000049, 24.838073000000065],
            [67.077828000000068, 24.837796000000026],
            [67.077803000000074, 24.837525000000028],
            [67.077789000000053, 24.837309000000062],
            [67.077773645000036, 24.837160770000025],
            [67.077763000000061, 24.83705800000007],
            [67.077730000000031, 24.836838000000057],
            [67.077710000000081, 24.836551000000043],
            [67.077687000000083, 24.83622900000006],
            [67.077683000000036, 24.836006000000054],
            [67.077683000000036, 24.835803000000055],
            [67.077683000000036, 24.835701000000029],
            [67.077683000000036, 24.835599000000059],
            [67.077683000000036, 24.835497000000032],
            [67.077683000000036, 24.835395000000062],
            [67.077683000000036, 24.835293000000036],
            [67.077683000000036, 24.835191000000066],
            [67.077706000000035, 24.835077000000069],
            [67.077730000000031, 24.834888000000035],
            [67.077732000000083, 24.834692000000075],
            [67.077689000000078, 24.834425000000067],
            [67.077642000000083, 24.834215000000029],
            [67.07755700000007, 24.834026000000051],
            [67.07737700000007, 24.833741000000032],
            [67.077278000000035, 24.833545000000072],
            [67.07709600000004, 24.833280000000059],
            [67.076903000000073, 24.833007000000066],
            [67.076721000000077, 24.832762000000059],
            [67.076514901000053, 24.83244540000004],
            [67.076498959000048, 24.832422247000068],
            [67.076474000000076, 24.832386000000042],
            [67.075639000000081, 24.832912000000078],
            [67.075046000000043, 24.833258000000058],
            [67.07440900000006, 24.833500000000072],
            [67.073673000000042, 24.83382400000005],
            [67.073170000000061, 24.834048000000053],
            [67.07231500000006, 24.834547000000043],
            [67.071399000000042, 24.835046000000034],
            [67.070910000000083, 24.835318000000029],
            [67.071137000000078, 24.835515000000044],
            [67.071683000000064, 24.836116000000061],
            [67.071918000000039, 24.836374000000035],
            [67.072065000000066, 24.836536000000024],
            [67.072128000000077, 24.836605000000077],
            [67.072199000000069, 24.836862000000053],
            [67.071939000000043, 24.836960000000033],
            [67.072007000000042, 24.837109000000055],
            [67.072962000000075, 24.838191000000052],
            [67.073203000000035, 24.838464000000045],
            [67.07296455200003, 24.838910029000033],
            [67.072894000000076, 24.839042000000063],
            [67.072736000000077, 24.839337000000057],
            [67.072362000000055, 24.83943000000005],
            [67.07221700000008, 24.83946700000007],
            [67.07240500000006, 24.840106000000048],
            [67.071997000000067, 24.84022600000003],
            [67.072167000000036, 24.840594000000067],
            [67.072119000000043, 24.840798000000063],
            [67.072359000000063, 24.841689000000031],
            [67.07236000000006, 24.841694000000075],
            [67.072361000000058, 24.841697000000067],
            [67.072361000000058, 24.841701000000057],
            [67.072361000000058, 24.841705000000047],
            [67.072361000000058, 24.841710000000035],
            [67.07236000000006, 24.841715000000079],
            [67.072359000000063, 24.841719000000069],
            [67.072358000000065, 24.841723000000059],
            [67.072355000000073, 24.841729000000043],
            [67.072351000000083, 24.841735000000028],
            [67.072347000000036, 24.841739000000075],
            [67.072341000000051, 24.841744000000062],
            [67.072330000000079, 24.841751000000045],
            [67.072036000000082, 24.841934000000037],
            [67.071192000000053, 24.842457000000024],
            [67.070587000000046, 24.84279900000007],
            [67.07055500000007, 24.842833000000041],
            [67.070504000000028, 24.842888000000073],
            [67.070453000000043, 24.842943000000048],
            [67.070268000000056, 24.843053000000054],
            [67.069894000000033, 24.843151000000034],
            [67.069846000000041, 24.843164000000058],
            [67.06917500000003, 24.843340000000069],
            [67.068399000000056, 24.843529000000046],
            [67.068235000000072, 24.843576000000041],
            [67.067661000000044, 24.843708000000049],
            [67.067917000000079, 24.844674000000055],
            [67.068109000000049, 24.844638000000032],
            [67.068175000000053, 24.844833000000051],
            [67.068200000000047, 24.844908000000032],
            [67.068126000000063, 24.844998000000032],
            [67.066966429000047, 24.845278275000055],
            [67.066860000000077, 24.845304000000056],
            [67.066743000000031, 24.845279000000062],
            [67.066639000000066, 24.845891000000051],
            [67.066371000000061, 24.847422000000051],
            [67.066269000000034, 24.848507000000041],
            [67.066258000000062, 24.848598000000038],
            [67.065964000000065, 24.848593000000051],
            [67.064993000000072, 24.848609000000067],
            [67.064093000000071, 24.848655000000065],
            [67.063362000000041, 24.848694000000023],
            [67.063058000000069, 24.848707000000047],
            [67.06298600000008, 24.848712000000035],
            [67.06290000000007, 24.848707000000047],
            [67.062746000000061, 24.848709000000042],
            [67.062606000000073, 24.848704000000055],
            [67.062503000000049, 24.848699000000067],
            [67.062446000000079, 24.848696000000075],
            [67.062369000000047, 24.848687000000041],
            [67.062332000000083, 24.848673000000076],
            [67.062290000000075, 24.848655000000065],
            [67.062234000000046, 24.848631000000069],
            [67.062186000000054, 24.848602000000028],
            [67.062131000000079, 24.848571000000049],
            [67.062046000000066, 24.848501000000056],
            [67.061988000000042, 24.848445000000027],
            [67.061922000000038, 24.848366000000055],
            [67.06164700000005, 24.848073000000056],
            [67.061491000000046, 24.847902000000033],
            [67.061487352000029, 24.847898033000035],
            [67.061205000000029, 24.847591000000023],
            [67.061164000000076, 24.847561000000042],
            [67.061125000000061, 24.847532000000058],
            [67.060922000000062, 24.847377000000051],
            [67.060889000000032, 24.847381000000041],
            [67.060186000000044, 24.847461000000067],
            [67.060178497000038, 24.847461850000059],
            [67.05967400000003, 24.847519000000034],
            [67.058273000000042, 24.847686000000067],
            [67.057689000000039, 24.847710000000063],
            [67.057174000000032, 24.847710000000063],
            [67.05674300000004, 24.847795000000076],
            [67.055888000000039, 24.847322000000077],
            [67.054737000000046, 24.847741000000042],
            [67.054639000000066, 24.847718000000043],
            [67.054732720000061, 24.847900149000054],
            [67.054051293000043, 24.849594530000047],
            [67.055308136000065, 24.85037445200004],
            [67.056137367000076, 24.850889023000036],
            [67.058014447000062, 24.852053827000077],
            [67.059192893000045, 24.852785100000062],
            [67.058070265000083, 24.854560067000023],
            [67.056234615000051, 24.853558804000045],
            [67.054550672000062, 24.85604679100004],
            [67.053200484000058, 24.858231366000041],
            [67.052806047000047, 24.859202288000063],
            [67.051713760000041, 24.858777509000049],
            [67.05170963300003, 24.85910765400007],
            [67.05170847100004, 24.859200637000072],
            [67.051698589000068, 24.859991162000028],
            [67.05086420300006, 24.85962706600003],
            [67.050242206000064, 24.85933882300003],
            [67.049802257000067, 24.859156776000077],
            [67.049422991000029, 24.859035410000047],
            [67.050161418000073, 24.858440728000062],
            [67.050131000000079, 24.858429000000058],
            [67.04834500000004, 24.857764000000032],
            [67.047286000000042, 24.857402000000036],
            [67.046852239000032, 24.857287669000073],
            [67.046815740000056, 24.857278048000069],
            [67.046789000000047, 24.857271000000026],
            [67.046519000000046, 24.857218000000046],
            [67.046281000000079, 24.857182000000023],
            [67.046132000000057, 24.857179000000031],
            [67.045926000000065, 24.857189000000062],
            [67.04543300000006, 24.857193000000052],
            [67.044893000000059, 24.857175000000041],
            [67.044516000000044, 24.857161000000076],
            [67.043980000000033, 24.857083000000046],
            [67.04384900000008, 24.857054000000062],
            [67.042922000000033, 24.856795000000034],
            [67.041844015000038, 24.856444436000061],
            [67.040803271000073, 24.856105982000031],
            [67.040585000000078, 24.856035000000077],
            [67.039775000000077, 24.855769000000066],
            [67.039474000000041, 24.855648000000031],
            [67.038884000000053, 24.855474000000072],
            [67.038231000000053, 24.855357000000026],
            [67.037435000000073, 24.855225000000075],
            [67.036803000000077, 24.855136000000073],
            [67.036526000000038, 24.855083000000036],
            [67.036395000000027, 24.855037000000038],
            [67.036274000000049, 24.854980000000069],
            [67.036121000000037, 24.854884000000027],
            [67.035997000000066, 24.854778000000067],
            [67.03587200000004, 24.854650000000049],
            [67.035798000000057, 24.85453300000006],
            [67.035702000000072, 24.854384000000039],
            [67.03549600000008, 24.854025000000036],
            [67.035141000000067, 24.853396000000032],
            [67.03481800000003, 24.852839000000074],
            [67.034715000000062, 24.852707000000066],
            [67.034594000000084, 24.852612000000079],
            [67.034420000000068, 24.852491000000043],
            [67.03415700000005, 24.852374000000054],
            [67.033188000000052, 24.851931000000036],
            [67.031954000000042, 24.851366000000041],
            [67.031852000000072, 24.851312000000064],
            [67.031851454000048, 24.851283673000069],
            [67.031845000000033, 24.850949000000071],
            [67.031806000000074, 24.850722000000076],
            [67.031914000000029, 24.850702000000069],
            [67.031979000000035, 24.850716000000034],
            [67.032047000000034, 24.850719000000026],
            [67.032115000000033, 24.850713000000042],
            [67.032180000000039, 24.850696000000028],
            [67.032243000000051, 24.850669000000039],
            [67.032301000000075, 24.850633000000073],
            [67.03235200000006, 24.85058900000007],
            [67.032396000000062, 24.850537000000031],
            [67.032558444000074, 24.850259293000079],
            [67.032668000000058, 24.850072000000068],
            [67.033449000000076, 24.848669000000029],
            [67.034086000000059, 24.847516000000041],
            [67.034346000000028, 24.847053000000074],
            [67.034932000000083, 24.846047000000056],
            [67.035460000000057, 24.845180000000028],
            [67.035935000000052, 24.844421000000068],
            [67.03606300000007, 24.84429300000005],
            [67.036199000000067, 24.844175000000064],
            [67.036343000000045, 24.844066000000055],
            [67.036365000000046, 24.844051000000036],
            [67.036495000000059, 24.843967000000077],
            [67.036652000000061, 24.843879000000072],
            [67.036816000000044, 24.843802000000039],
            [67.036984000000075, 24.843736000000035],
            [67.037156000000039, 24.843682000000058],
            [67.037278000000072, 24.843647000000033],
            [67.037332000000049, 24.843632000000071],
            [67.037212000000068, 24.842801000000065],
            [67.037109000000044, 24.842075000000023],
            [67.037071000000083, 24.841886000000045],
            [67.037004116000048, 24.84162032100005],
            [67.036999000000037, 24.841600000000028],
            [67.036926000000051, 24.84128400000003],
            [67.036867018000066, 24.841044733000047],
            [67.036820000000034, 24.840854000000036],
            [67.036768000000052, 24.840657000000078],
            [67.036748516000046, 24.840551408000067],
            [67.036737000000073, 24.840489000000048],
            [67.036723000000052, 24.840355000000045],
            [67.036734000000081, 24.840269000000035],
            [67.036803000000077, 24.840031000000067],
            [67.036992000000055, 24.839488000000074],
            [67.03712831200005, 24.839047965000077],
            [67.037198000000046, 24.838823000000048],
            [67.037391000000071, 24.838304000000051],
            [67.037439000000063, 24.838190000000054],
            [67.037449000000038, 24.838049000000069],
            [67.037415000000067, 24.837919000000056],
            [67.037391000000071, 24.837857000000042],
            [67.037322000000074, 24.837784000000056],
            [67.037119000000075, 24.837633000000039],
            [67.036107000000072, 24.836983000000032],
            [67.036033744000065, 24.836943983000026],
            [67.036016884000048, 24.836935003000065],
            [67.035923000000082, 24.836885000000052],
            [67.036553000000083, 24.836131000000023],
            [67.037197132000074, 24.835357419000047],
            [67.037621000000058, 24.834804000000076],
            [67.037946000000034, 24.834434000000044],
            [67.038522000000057, 24.833714000000043],
            [67.039065000000051, 24.833044000000029],
            [67.039181000000042, 24.833048000000076],
            [67.039442000000065, 24.833252000000073],
            [67.039561000000049, 24.833345000000065],
            [67.040175000000033, 24.833826000000045],
            [67.040473000000077, 24.834060000000022],
            [67.040672000000029, 24.83395100000007],
            [67.040831000000082, 24.833878000000027],
            [67.041001000000051, 24.833813000000077],
            [67.04121200000003, 24.833750000000066],
            [67.041342000000043, 24.833720000000028],
            [67.041408000000047, 24.833707000000061],
            [67.041606000000058, 24.833678000000077],
            [67.041807000000063, 24.833664000000056],
            [67.04190600000004, 24.833662000000061],
            [67.042702000000077, 24.833642000000054],
            [67.043965000000071, 24.833602000000042],
            [67.044042907000062, 24.833599509000067],
            [67.046248000000048, 24.833529000000055],
            [67.04698185500007, 24.83363102800007],
            [67.047032000000058, 24.833638000000064],
            [67.047113000000081, 24.833649000000037],
            [67.047538000000031, 24.833549000000062],
            [67.047583000000031, 24.833371000000056],
            [67.047698000000082, 24.833056000000056],
            [67.047721000000081, 24.833004000000074],
            [67.047747000000072, 24.832953000000032],
            [67.047776000000056, 24.832905000000039],
            [67.047809000000029, 24.832858000000044],
            [67.047845000000052, 24.832814000000042],
            [67.04788300000007, 24.832772000000034],
            [67.047925000000077, 24.832733000000076],
            [67.047930423000082, 24.832728563000046],
            [67.04796900000008, 24.832697000000053],
            [67.048015000000078, 24.832663000000025],
            [67.048021994000067, 24.832658629000036],
            [67.048032642000067, 24.832651974000044],
            [67.048060097000075, 24.832634814000073],
            [67.04806300000007, 24.832633000000044],
            [67.04819400000008, 24.832569000000035],
            [67.04821000000004, 24.832565000000045],
            [67.048226000000056, 24.83256300000005],
            [67.04824300000007, 24.832564000000048],
            [67.04825900000003, 24.832568000000038],
            [67.048274000000049, 24.832574000000022],
            [67.04828800000007, 24.832583000000056],
            [67.04830000000004, 24.832594000000029],
            [67.048311000000069, 24.832606000000055],
            [67.048319000000049, 24.832620000000077],
            [67.048326000000031, 24.832636000000036],
            [67.048329000000081, 24.832652000000053],
            [67.048330000000078, 24.832670000000064],
            [67.048502000000042, 24.832340000000045],
            [67.048743000000059, 24.831924000000072],
            [67.049069000000031, 24.831602000000032],
            [67.049338077000073, 24.831335169000056],
            [67.049788000000035, 24.83088900000007],
            [67.05048000000005, 24.830204000000037],
            [67.050787000000071, 24.829901000000064],
            [67.051102000000071, 24.829588000000058],
            [67.051132000000052, 24.829512000000022],
            [67.050929000000053, 24.829469000000074],
            [67.050798000000043, 24.829444000000024],
            [67.04972900000007, 24.829230000000052],
            [67.049494000000038, 24.829197000000079],
            [67.049496000000033, 24.829153000000076],
            [67.049995000000081, 24.828361000000029],
            [67.050441000000035, 24.82765900000004],
            [67.050453000000061, 24.827636000000041],
            [67.050460000000044, 24.827612000000045],
            [67.050463000000036, 24.827587000000051],
            [67.050462000000039, 24.82756100000006],
            [67.050456000000054, 24.827536000000066],
            [67.050445000000082, 24.827513000000067],
            [67.05043100000006, 24.827492000000063],
            [67.050413000000049, 24.827474000000052],
            [67.050395000000037, 24.827462000000025],
            [67.050377000000083, 24.827451000000053],
            [67.050358000000074, 24.827441000000078],
            [67.050339000000065, 24.827431000000047],
            [67.050320000000056, 24.82742200000007],
            [67.05030000000005, 24.827414000000033],
            [67.050280000000043, 24.827406000000053],
            [67.050260000000037, 24.827399000000071],
            [67.048872000000074, 24.827443000000073],
            [67.048553000000084, 24.827453000000048],
            [67.048312000000067, 24.827460000000031],
            [67.048117000000047, 24.827466000000072],
            [67.047918000000038, 24.827473000000055],
            [67.047903000000076, 24.827472000000057],
            [67.047859000000074, 24.827469000000065],
            [67.047816000000068, 24.827462000000025],
            [67.047773000000063, 24.827452000000051],
            [67.047753000000057, 24.827423000000067],
            [67.047736000000043, 24.827393000000029],
            [67.047723000000076, 24.827360000000056],
            [67.047714000000042, 24.827327000000025],
            [67.047708000000057, 24.827292000000057],
            [67.047706000000062, 24.827257000000031],
            [67.047708000000057, 24.827222000000063],
            [67.047714000000042, 24.827188000000035],
            [67.047833000000082, 24.826941000000033],
            [67.047938000000045, 24.826692000000037],
            [67.048023000000057, 24.826573000000053],
            [67.048102000000029, 24.826450000000023],
            [67.048152000000073, 24.826365000000067],
            [67.048175000000072, 24.826324000000056],
            [67.048241000000075, 24.826194000000044],
            [67.04830000000004, 24.826060000000041],
            [67.048353000000077, 24.825924000000043],
            [67.048398000000077, 24.825785000000053],
            [67.048436000000038, 24.825644000000068],
            [67.048467000000073, 24.825502000000029],
            [67.04849100000007, 24.825358000000051],
            [67.048595000000034, 24.824921000000074],
            [67.048659000000043, 24.824648000000025],
            [67.04867500000006, 24.824560000000076],
            [67.048737000000074, 24.824213000000043],
            [67.048558000000071, 24.824183000000062],
            [67.048378000000071, 24.824153000000024],
            [67.048199000000068, 24.824121000000048],
            [67.04810800000007, 24.824105000000031],
            [67.048033000000032, 24.824092000000064],
            [67.048020000000065, 24.824089000000072],
            [67.047841000000062, 24.824056000000041],
            [67.047662000000059, 24.82402200000007],
            [67.047484000000054, 24.823987000000045],
            [67.047305000000051, 24.823951000000022],
            [67.047330000000045, 24.823922000000039],
            [67.047351000000049, 24.82389100000006],
            [67.047368000000063, 24.823857000000032],
            [67.047382000000027, 24.823822000000064],
            [67.047392000000059, 24.823785000000044],
            [67.047472000000027, 24.823448000000042],
            [67.047523000000069, 24.823232000000075],
            [67.047552000000053, 24.82311100000004],
            [67.047675000000083, 24.822592000000043],
            [67.047712865000051, 24.822432437000032],
            [67.04793200000006, 24.821509000000049],
            [67.047351000000049, 24.821250000000077],
            [67.04681400000004, 24.820985000000064],
            [67.046786000000054, 24.820969000000048],
            [67.047094000000072, 24.820439000000079],
            [67.04723400000006, 24.82022900000004],
            [67.047431000000074, 24.819998000000055],
            [67.047534000000041, 24.819896000000028],
            [67.048001000000056, 24.819427000000076],
            [67.048122000000035, 24.819335000000024],
            [67.048282000000029, 24.819193000000041],
            [67.048830000000066, 24.818759000000057],
            [67.048800000000028, 24.818738000000053],
            [67.048714000000075, 24.818662000000074],
            [67.04862600000007, 24.818588000000034],
            [67.048537000000067, 24.818517000000043],
            [67.048445000000072, 24.818448000000046],
            [67.048352000000079, 24.818382000000042],
            [67.048301000000038, 24.818348000000071],
            [67.048256000000038, 24.818318000000033],
            [67.04821452300007, 24.818291917000067],
            [67.048159000000055, 24.818257000000074],
            [67.048061000000075, 24.81819900000005],
            [67.048197000000073, 24.81815400000005],
            [67.048611000000051, 24.81780800000007],
            [67.048465000000078, 24.81758300000007],
            [67.048085000000071, 24.816999000000067],
            [67.047974000000067, 24.816829000000041],
            [67.047801168000035, 24.816563001000077],
            [67.047590000000071, 24.816238000000055],
            [67.047319000000073, 24.815822000000026],
            [67.047229000000073, 24.815683000000035],
            [67.047119000000066, 24.815513000000067],
            [67.047030000000063, 24.815378000000067],
            [67.046569000000034, 24.814669000000038],
            [67.04672800000003, 24.814543000000072],
            [67.046369000000084, 24.814156000000025],
            [67.04562100000004, 24.814749000000063],
            [67.045211000000052, 24.815075000000036],
            [67.045150000000035, 24.815686000000028],
            [67.045108000000027, 24.816107000000045],
            [67.045033000000046, 24.81610900000004],
            [67.044578000000058, 24.816119000000072],
            [67.044118000000083, 24.816103000000055],
            [67.04395100000005, 24.816104000000053],
            [67.043783000000076, 24.816139000000078],
            [67.043554000000029, 24.816206000000022],
            [67.043092000000058, 24.816381000000035],
            [67.043073000000049, 24.816341000000023],
            [67.043032000000039, 24.816262000000052],
            [67.042986000000042, 24.816185000000075],
            [67.042937000000052, 24.816110000000037],
            [67.04291500000005, 24.816084000000046],
            [67.042889000000059, 24.816059000000052],
            [67.042444000000046, 24.815533000000073],
            [67.042288000000042, 24.815651000000059],
            [67.042210000000068, 24.815696000000059],
            [67.042129000000045, 24.815733000000023],
            [67.042044000000033, 24.815760000000068],
            [67.041956000000027, 24.815779000000077],
            [67.041867000000082, 24.815788000000055],
            [67.041552000000081, 24.815818000000036],
            [67.041488000000072, 24.815824000000077],
            [67.041417000000081, 24.81583500000005],
            [67.041237000000081, 24.815861000000041],
            [67.04110600000007, 24.81588400000004],
            [67.040646070000037, 24.815032690000066],
            [67.040113000000076, 24.814046000000076],
            [67.040082000000041, 24.813988000000052],
            [67.040078000000051, 24.813967000000048],
            [67.039991000000043, 24.813955000000078],
            [67.039357000000052, 24.813821000000075],
            [67.038473000000067, 24.813641000000075],
            [67.038170000000036, 24.81356900000003],
            [67.037636000000077, 24.813460000000077],
            [67.036752000000035, 24.813283000000069],
            [67.036694000000068, 24.813268000000051],
            [67.036634000000049, 24.813252000000034],
            [67.036592000000041, 24.813254000000029],
            [67.036552000000029, 24.813253000000032],
            [67.036510000000078, 24.813250000000039],
            [67.03646800000007, 24.813246000000049],
            [67.03642700000006, 24.813239000000067],
            [67.036387000000047, 24.81323100000003],
            [67.036347000000035, 24.813220000000058],
            [67.036307000000079, 24.813208000000031],
            [67.036181000000056, 24.813160000000039],
            [67.036144000000036, 24.813142000000028],
            [67.036109000000067, 24.813121000000024],
            [67.036102000000028, 24.813116000000036],
            [67.036076000000037, 24.813097000000027],
            [67.036045000000058, 24.813071000000036],
            [67.036017000000072, 24.813041000000055],
            [67.035991000000081, 24.813010000000077],
            [67.035968000000082, 24.812976000000049],
            [67.035948000000076, 24.812940000000026],
            [67.036471000000063, 24.812519000000066],
            [67.036009000000035, 24.811996000000079],
            [67.036547000000041, 24.811567000000025],
            [67.036917000000074, 24.811273000000028],
            [67.03680200000008, 24.811067000000037],
            [67.036583000000064, 24.810676000000058],
            [67.036541000000057, 24.810601000000077],
            [67.036453000000051, 24.810445000000072],
            [67.036398000000077, 24.810345000000041],
            [67.036319000000049, 24.810204000000056],
            [67.035938000000044, 24.80952300000007],
            [67.035803000000044, 24.80928300000005],
            [67.03569600000003, 24.809092000000078],
            [67.035478000000069, 24.808701000000042],
            [67.035458000000062, 24.808665000000076],
            [67.036702000000048, 24.807887000000051],
            [67.037374663000037, 24.807460263000053],
            [67.037766000000033, 24.80721200000005],
            [67.039364000000035, 24.806215000000066],
            [67.039423000000056, 24.806182000000035],
            [67.039500000000032, 24.806129000000055],
            [67.039682000000028, 24.806015000000059],
            [67.040348000000051, 24.805507000000034],
            [67.04013176400008, 24.805280187000051],
            [67.039877524000076, 24.805013511000027],
            [67.039796000000081, 24.804928000000075],
            [67.039278000000081, 24.804381000000035],
            [67.038710000000037, 24.803801000000078],
            [67.038535633000038, 24.803617790000033],
            [67.038217000000031, 24.803283000000079],
            [67.03730900000005, 24.802308000000039],
            [67.036769000000049, 24.801757000000066],
            [67.03654845300008, 24.801515776000031],
            [67.036257000000035, 24.801197000000059],
            [67.035554981000075, 24.800453120000043],
            [67.035016000000041, 24.799882000000025],
            [67.035010756000077, 24.799872011000048],
            [67.034974000000034, 24.799802000000057],
            [67.034930000000031, 24.799732000000063],
            [67.034846000000073, 24.799518000000035],
            [67.034821000000079, 24.799432000000024],
            [67.034068000000048, 24.798518000000058],
            [67.033831000000077, 24.79854000000006],
            [67.032938654000077, 24.79763777900007],
            [67.028141019000032, 24.800795011000048],
            [67.026430000000062, 24.80192100000005],
            [67.019282000000032, 24.805611000000056],
            [67.015387893000081, 24.807003756000029],
            [67.013869000000057, 24.807547000000056],
            [67.009386000000063, 24.809233000000063],
            [67.003205000000037, 24.811147000000062],
            [67.003571000000079, 24.809493000000032],
            [67.002657162000048, 24.808319149000056],
            [67.002349061000075, 24.807923384000048],
            [67.002246000000071, 24.807791000000066],
            [67.000855000000058, 24.808310000000063],
            [66.996385000000032, 24.809298000000069],
            [66.995266363000042, 24.809548044000053],
            [66.988912011000082, 24.809151978000045],
            [66.986056658000052, 24.808366756000055],
            [66.987270183000078, 24.805868322000038],
            [66.989126163000037, 24.800585918000024],
            [66.991339061000076, 24.796302888000071],
            [66.992195667000033, 24.794375525000078],
            [66.988269557000081, 24.793019232000063],
            [66.987288029000069, 24.792983540000023],
            [66.986365988000045, 24.793578406000051],
            [66.98398652700007, 24.796285042000022],
            [66.976574506000077, 24.80546976200003],
            [66.974575759000061, 24.809395873000028],
            [66.972152710000046, 24.816978455000026],
            [66.971720405000042, 24.820460366000077],
            [66.972291476000066, 24.823315720000039],
            [66.974693823000052, 24.826677151000069],
            [66.974940511000057, 24.825692660000072],
            [66.97523168500004, 24.824530629000037],
            [66.97855938500004, 24.823074761000044],
            [66.978767366000056, 24.822398822000025],
            [66.979495300000053, 24.820942953000042],
            [66.981107155000075, 24.820267014000024],
            [66.98469483100007, 24.820111028000042],
            [66.992669427000067, 24.820188078000058],
            [66.994563000000028, 24.818882000000031],
            [66.995457000000044, 24.818741000000045],
            [66.996012000000064, 24.81865300000004],
            [66.996646000000055, 24.818554000000063],
            [66.99710900000008, 24.818481000000077],
            [66.997418000000039, 24.81843200000003],
            [66.997877000000074, 24.818360000000041],
            [66.998765000000049, 24.818220000000053],
            [66.999046000000078, 24.818176000000051],
            [67.000438000000031, 24.817957000000035],
            [67.000542000000053, 24.817940000000078],
            [67.000842000000034, 24.817893000000026],
            [67.001066000000037, 24.817858000000058],
            [67.001548000000071, 24.817782000000022],
            [67.001648000000046, 24.817766000000063],
            [67.001656000000082, 24.817765000000065],
            [67.002243000000078, 24.817674000000068],
            [67.00267400000007, 24.817607000000066],
            [67.004224000000079, 24.817376000000024],
            [67.004433651000056, 24.817346932000078],
            [67.004801000000043, 24.817296000000056],
            [67.005085000000065, 24.81726100000003],
            [67.005322000000035, 24.817231000000049],
            [67.005694000000062, 24.817168000000038],
            [67.005850000000066, 24.817141000000049],
            [67.006041000000039, 24.817108000000076],
            [67.006436000000065, 24.817064000000073],
            [67.006694000000039, 24.817029000000048],
            [67.006957000000057, 24.816989000000035],
            [67.007242000000076, 24.816949000000022],
            [67.008126433000029, 24.816789429000039],
            [67.00828400000006, 24.816761000000042],
            [67.010300000000029, 24.816445000000044],
            [67.011017000000038, 24.816340000000025],
            [67.011414000000059, 24.816263000000049],
            [67.011683000000062, 24.816243000000043],
            [67.011925000000076, 24.816266000000041],
            [67.012164000000041, 24.816313000000036],
            [67.013806000000045, 24.816781000000049],
            [67.014591000000053, 24.81702000000007],
            [67.015119000000084, 24.817165000000045],
            [67.015381000000048, 24.817198000000076],
            [67.015546000000029, 24.817205000000058],
            [67.015681000000029, 24.817192000000034],
            [67.015863000000081, 24.817165000000045],
            [67.016334000000029, 24.817050000000052],
            [67.016855000000078, 24.816919000000041],
            [67.017266000000063, 24.816774000000066],
            [67.01844477800006, 24.816345110000043],
            [67.018565803000058, 24.816301076000059],
            [67.018588000000079, 24.81629300000003],
            [67.020186000000081, 24.815671000000066],
            [67.020485000000065, 24.815523000000042],
            [67.020834087000082, 24.815597480000065],
            [67.020831000000044, 24.815616000000034],
            [67.020811000000037, 24.815676000000053],
            [67.020773000000077, 24.815729000000033],
            [67.02072400000003, 24.815773000000036],
            [67.020669000000055, 24.815807000000063],
            [67.020609000000036, 24.815832000000057],
            [67.020545000000084, 24.815846000000079],
            [67.020488000000057, 24.815855000000056],
            [67.020416000000068, 24.815859000000046],
            [67.020351000000062, 24.815855000000056],
            [67.020402000000047, 24.815881000000047],
            [67.020450000000039, 24.815911000000028],
            [67.020496000000037, 24.815944000000059],
            [67.02054000000004, 24.815981000000079],
            [67.02058100000005, 24.816021000000035],
            [67.020619000000067, 24.81606400000004],
            [67.020653000000038, 24.81610900000004],
            [67.020684000000074, 24.816157000000032],
            [67.02150800000004, 24.817988000000071],
            [67.021753000000047, 24.818530000000067],
            [67.022140000000036, 24.819391000000053],
            [67.022311874000081, 24.819772711000041],
            [67.022836015000053, 24.820936762000031],
            [67.023213000000055, 24.821774000000062],
            [67.023789467000029, 24.823054482000032],
            [67.024596000000031, 24.824846000000036],
            [67.025033386000075, 24.825816202000055],
            [67.025068157000078, 24.826082340000028],
            [67.025091628000041, 24.826213154000072],
            [67.025097452000068, 24.826341292000052],
            [67.025094464000063, 24.826445401000058],
            [67.025109106000059, 24.826586890000044],
            [67.025147279000066, 24.826728388000049],
            [67.02518548300003, 24.826803148000067],
            [67.025255838000078, 24.826909898000054],
            [67.025184000000081, 24.826958000000047],
            [67.025109000000043, 24.826985000000036],
            [67.025067073000059, 24.826995969000052],
            [67.024910766000062, 24.827016094000044],
            [67.024668323000071, 24.827047631000028],
            [67.024502464000079, 24.827071395000075],
            [67.024240001000067, 24.827131630000054],
            [67.02369200000004, 24.827117000000044],
            [67.023255000000063, 24.827163000000041],
            [67.023022000000083, 24.827187000000038],
            [67.022365000000036, 24.827279000000033],
            [67.022283000000073, 24.827293000000054],
            [67.022203000000047, 24.827311000000066],
            [67.022175000000061, 24.827318000000048],
            [67.022123000000079, 24.827331000000072],
            [67.022044000000051, 24.827355000000068],
            [67.021966000000077, 24.827381000000059],
            [67.021889000000044, 24.827410000000043],
            [67.021812000000068, 24.827441000000078],
            [67.021738000000028, 24.827476000000047],
            [67.021339000000069, 24.827686000000028],
            [67.020822000000067, 24.827958000000024],
            [67.020722000000035, 24.828006000000073],
            [67.020625000000052, 24.82806000000005],
            [67.02053200000006, 24.828119000000072],
            [67.020492000000047, 24.828146000000061],
            [67.020441000000062, 24.828183000000024],
            [67.020354000000054, 24.828252000000077],
            [67.020271000000037, 24.828325000000063],
            [67.020191000000068, 24.828403000000037],
            [67.020155000000045, 24.828442000000052],
            [67.019979141000078, 24.828630049000026],
            [67.019888956000045, 24.828725109000061],
            [67.019945119000056, 24.82883103000006],
            [67.019991574000073, 24.829574310000055],
            [67.019991574000073, 24.830348233000052],
            [67.020049440000037, 24.830363291000026],
            [67.020094111000049, 24.830444552000074],
            [67.020300702000043, 24.830825467000068],
            [67.020532861000049, 24.831207919000065],
            [67.020596689000058, 24.831313068000043],
            [67.020686126000044, 24.831379144000039],
            [67.020750004000035, 24.83169298100006],
            [67.020768455000052, 24.831951035000031],
            [67.020768158000067, 24.832253198000046],
            [67.020708133000028, 24.832467266000037],
            [67.020700587000078, 24.832494178000047],
            [67.020573389000049, 24.832504259000075],
            [67.020467850000045, 24.832536940000068],
            [67.020405019000066, 24.832644172000073],
            [67.020431457000029, 24.832744908000052],
            [67.02045307800006, 24.832836882000038],
            [67.020481910000058, 24.832957325000052],
            [67.020549406000043, 24.833012116000077],
            [67.02061051700008, 24.833051313000055],
            [67.020626322000055, 24.833230180000044],
            [67.020604788000071, 24.833462193000059],
            [67.020593906000045, 24.833694215000037],
            [67.020570667000072, 24.833757298000023],
            [67.020551180000041, 24.833810196000059],
            [67.020465797000043, 24.833969646000071],
            [67.020481611000037, 24.834031263000043],
            [67.020532499000069, 24.834070588000031],
            [67.020568146000073, 24.834072927000022],
            [67.020573225000078, 24.834086796000065],
            [67.020578283000077, 24.834121462000041],
            [67.020590947000073, 24.83419079600003],
            [67.020555266000031, 24.834223119000058],
            [67.020567937000067, 24.834285519000048],
            [67.020586241000046, 24.834375059000024],
            [67.02066443700005, 24.834478745000069],
            [67.02072961600004, 24.834546893000038],
            [67.020768645000032, 24.83466831000004],
            [67.020748977000039, 24.834763036000027],
            [67.020778265000047, 24.834837077000032],
            [67.020719525000061, 24.834854794000023],
            [67.020768402000044, 24.834914047000041],
            [67.020765075000043, 24.834979181000051],
            [67.020732405000047, 24.835026528000071],
            [67.020784500000048, 24.835130196000023],
            [67.020787754000082, 24.835139081000023],
            [67.020866130000059, 24.835296201000062],
            [67.020930630000066, 24.835374395000031],
            [67.020939769000051, 24.83545812400007],
            [67.020902819000071, 24.835502746000031],
            [67.020914968000056, 24.835650670000064],
            [67.020948621000059, 24.835820942000055],
            [67.020923905000075, 24.835929770000064],
            [67.020979210000064, 24.835982844000057],
            [67.020988285000044, 24.836136354000075],
            [67.020972731000029, 24.836216645000036],
            [67.020992023000076, 24.836273726000059],
            [67.021001605000038, 24.836365917000023],
            [67.021008722000033, 24.836504201000025],
            [67.020994103000078, 24.836609549000059],
            [67.02097451700007, 24.83667213800004],
            [67.020957706000047, 24.836725858000023],
            [67.020956415000057, 24.836858232000054],
            [67.021037754000076, 24.83696918000004],
            [67.02108052400007, 24.837066475000029],
            [67.021091178000063, 24.837128727000049],
            [67.02115325300008, 24.837206579000053],
            [67.021236735000059, 24.837303894000058],
            [67.021223816000031, 24.837364174000072],
            [67.021238757000049, 24.83742447700007],
            [67.021219417000054, 24.837476973000037],
            [67.021120815000074, 24.837498289000052],
            [67.021036913000046, 24.837498224000058],
            [67.021133894000059, 24.838021003000051],
            [67.021206688000063, 24.838175924000041],
            [67.021343880000074, 24.838480686000025],
            [67.021426415000064, 24.838665952000042],
            [67.021461522000038, 24.838744758000075],
            [67.021548319000033, 24.838947933000043],
            [67.021584722000057, 24.839051903000041],
            [67.021590348000075, 24.839211912000053],
            [67.021586384000045, 24.839285035000046],
            [67.021574532000045, 24.839503696000065],
            [67.021573500000045, 24.839522732000034],
            [67.021551294000062, 24.839932426000075],
            [67.021539585000028, 24.84016181100003],
            [67.020409854000036, 24.840267936000032],
            [67.017603731000065, 24.839898709000067],
            [67.016422205000083, 24.838569493000023],
            [67.015757597000061, 24.837535659000025],
            [67.013985309000077, 24.838052576000052],
            [67.012729938000064, 24.83938179200004],
            [67.01095765000008, 24.839972555000031],
            [67.009333052000045, 24.841449462000071],
            [67.006896156000039, 24.842926368000064],
            [67.004163878000043, 24.843517131000056],
            [67.001431601000036, 24.843886358000077],
            [66.998920859000066, 24.844329430000073],
            [66.996262427000033, 24.844329430000073],
            [66.99478552000005, 24.844255585000042],
            [66.992865541000072, 24.843886358000077],
            [66.991610170000058, 24.844477121000068],
            [66.991572699000074, 24.844614514000057],
            [66.991582000000051, 24.844679000000042],
            [66.991584000000046, 24.844785000000059],
            [66.991572000000076, 24.844889000000023],
            [66.991545000000031, 24.84499100000005],
            [66.991505000000075, 24.84508900000003],
            [66.991452000000038, 24.845180000000028],
            [66.991401517000043, 24.845296207000047],
            [66.992126447000032, 24.84567815500003],
            [66.992117000000064, 24.84568500000006],
            [66.992050000000063, 24.845736000000045],
            [66.991984000000059, 24.845789000000025],
            [66.991919000000053, 24.845844000000056],
            [66.991857000000039, 24.845900000000029],
            [66.991795000000081, 24.845958000000053],
            [66.99173600000006, 24.846018000000072],
            [66.991677000000038, 24.846079000000032],
            [66.991628000000048, 24.846135000000061],
            [66.991566000000034, 24.846207000000049],
            [66.991514000000052, 24.846273000000053],
            [66.99146200000007, 24.846340000000055],
            [66.99136800000008, 24.846455000000049],
            [66.991241000000059, 24.846639000000039],
            [66.991115000000036, 24.846823000000029],
            [66.991114699000036, 24.846823456000038],
            [66.990631000000064, 24.847557000000052],
            [66.990313000000071, 24.848035000000039],
            [66.989985000000047, 24.848529000000042],
            [66.989881000000082, 24.848607000000072],
            [66.989812824000069, 24.848720539000055],
            [66.989627081000037, 24.849029869000049],
            [66.989624000000049, 24.849035000000072],
            [66.989193131000036, 24.849703797000075],
            [66.989193000000057, 24.849704000000031],
            [66.989155999000047, 24.849761883000042],
            [66.989092000000028, 24.84986200000003],
            [66.988709000000028, 24.85045800000006],
            [66.988344000000041, 24.851024000000052],
            [66.988057000000083, 24.851469000000066],
            [66.987995000000069, 24.851577000000077],
            [66.987942000000032, 24.851691000000073],
            [66.987900000000081, 24.85180900000006],
            [66.987869000000046, 24.851930000000038],
            [66.987848000000042, 24.852053000000069],
            [66.987838000000067, 24.852193000000057],
            [66.987839000000065, 24.852303000000063],
            [66.987851000000035, 24.852428000000032],
            [66.987928000000068, 24.852796000000069],
            [66.988004000000046, 24.853164000000049],
            [66.988035976000049, 24.853306602000032],
            [66.988094799000066, 24.853607066000052],
            [66.988124919000029, 24.853760915000066],
            [66.988135439000075, 24.853986098000064],
            [66.988123893000079, 24.854077429000029],
            [66.988117333000048, 24.854107470000031],
            [66.98809474400008, 24.854210917000046],
            [66.987787531000038, 24.85424953100005],
            [66.987860734000037, 24.854342038000027],
            [66.987892428000066, 24.854409837000048],
            [66.987892514000066, 24.854410224000048],
            [66.98790778700004, 24.854479095000045],
            [66.987873302000082, 24.854613581000024],
            [66.987853861000076, 24.85470876100004],
            [66.987832834000073, 24.854787475000023],
            [66.987823251000066, 24.85495958000007],
            [66.987741000000028, 24.855155000000025],
            [66.987445000000037, 24.855815000000064],
            [66.987420599000075, 24.855876700000067],
            [66.987337859000036, 24.856085914000062],
            [66.987235000000055, 24.85634600000003],
            [66.987183000000073, 24.85648800000007],
            [66.987138000000073, 24.856632000000047],
            [66.987100000000055, 24.856778000000077],
            [66.987069000000076, 24.856925000000047],
            [66.98704500000008, 24.857074000000068],
            [66.987027000000069, 24.857224000000031],
            [66.987017304000062, 24.857370404000051],
            [66.987017000000037, 24.857375000000047],
            [66.987014000000045, 24.857526000000064],
            [66.98704513000007, 24.858362419000059],
            [66.987052000000062, 24.858547000000044],
            [66.987085777000061, 24.859446124000044],
            [66.98709400000007, 24.859665000000064],
            [66.98709000000008, 24.860324000000048],
            [66.987068000000079, 24.861140000000034],
            [66.986989000000051, 24.861821000000077],
            [66.986819000000082, 24.862481000000059],
            [66.986591000000033, 24.86310700000007],
            [66.98650200000003, 24.863314000000059],
            [66.986426086000051, 24.863471250000032],
            [66.98640400000005, 24.863517000000058],
            [66.986297000000036, 24.86371500000007],
            [66.98618300000004, 24.863909000000035],
            [66.986060000000066, 24.86409800000007],
            [66.985929000000056, 24.864281000000062],
            [66.985790000000065, 24.86445800000007],
            [66.985755395000069, 24.864499049000074],
            [66.985645000000034, 24.864630000000034],
            [66.985490000000084, 24.864796000000069],
            [66.985423000000083, 24.864865000000066],
            [66.985331000000031, 24.864957000000061],
            [66.985167000000047, 24.865114000000062],
            [66.984999000000073, 24.865265000000079],
            [66.984827000000053, 24.865412000000049],
            [66.984650000000045, 24.865553000000034],
            [66.984469000000047, 24.865690000000029],
            [66.984284000000059, 24.865820000000042],
            [66.984166000000073, 24.865904000000057],
            [66.983609066000042, 24.866265836000025],
            [66.982870000000048, 24.866746000000035],
            [66.982047636000061, 24.867260286000032],
            [66.981869000000074, 24.867372000000046],
            [66.981834000000049, 24.867394000000047],
            [66.980989000000079, 24.867920000000026],
            [66.979503000000079, 24.868863000000033],
            [66.979286000000059, 24.868996000000038],
            [66.978785000000073, 24.869303000000059],
            [66.978367569000056, 24.86955787100004],
            [66.978066000000069, 24.869742000000031],
            [66.977765786000077, 24.869950604000053],
            [66.977197887000045, 24.870164546000069],
            [66.976825719000033, 24.870304751000049],
            [66.976364083000078, 24.870579705000068],
            [66.976316931000042, 24.870609223000031],
            [66.975546265000048, 24.871091672000034],
            [66.975750706000042, 24.871354996000036],
            [66.975840386000073, 24.871470506000037],
            [66.976014587000066, 24.871694880000064],
            [66.976237758000082, 24.871913831000029],
            [66.976901146000046, 24.872451241000078],
            [66.977542191000055, 24.872856806000073],
            [66.978195411000058, 24.873226031000058],
            [66.979191480000054, 24.87367970400004],
            [66.981292718000077, 24.874572796000052],
            [66.982752373000039, 24.875218800000027],
            [66.984773932000053, 24.876113488000044],
            [66.988351755000053, 24.877696367000055],
            [66.992118105000031, 24.87931775800007],
            [66.992121246000067, 24.879319162000058],
            [66.99298182800004, 24.879718291000074],
            [66.99320457500005, 24.879821599000024],
            [66.993800241000031, 24.880097963000026],
            [66.994139962000077, 24.880225697000071],
            [66.994384196000055, 24.880317527000045],
            [66.994796463000057, 24.880440598000064],
            [66.994877795000036, 24.880464877000065],
            [66.994932707000032, 24.880476636000026],
            [66.99497857700004, 24.880486459000053],
            [66.995044022000059, 24.88050047400003],
            [66.995221197000035, 24.880538415000046],
            [66.995646254000064, 24.880629440000064],
            [66.996697210000036, 24.880872688000068],
            [66.996749531000034, 24.880884798000068],
            [66.997589278000078, 24.880996195000023],
            [66.998779239000044, 24.881076520000079],
            [66.999248320000049, 24.881108185000073],
            [66.999582608000082, 24.881130750000068],
            [67.000970089000077, 24.88116468000004],
            [67.001812639000036, 24.881187203000025],
            [67.001827602000048, 24.88118847100003],
            [67.002396197000053, 24.881270017000077],
            [67.002406324000049, 24.881271970000057],
            [67.002415183000039, 24.881274393000069],
            [67.002575028000081, 24.881324390000032],
            [67.002963479000073, 24.881445892000045],
            [67.002971000000059, 24.881448577000072],
            [67.00314793900003, 24.881518287000063],
            [67.003320723000058, 24.881539803000067],
            [67.00372651400005, 24.881567136000058],
            [67.003973127000052, 24.881583748000025],
            [67.004205765000052, 24.881599265000034],
            [67.004385938000041, 24.881588515000033],
            [67.004699435000077, 24.881527730000073],
            [67.00547741500003, 24.881320208000034],
            [67.006137959000057, 24.881066702000055],
            [67.006862328000068, 24.880726978000041],
            [67.007177917000035, 24.880578969000055],
            [67.007708964000074, 24.880307124000069],
            [67.007789001000049, 24.880266152000047],
            [67.00803075400006, 24.880142398000032],
            [67.008620693000069, 24.879773742000054],
            [67.009045326000034, 24.879496577000054],
            [67.009055053000054, 24.879490861000079],
            [67.009060380000051, 24.879488218000063],
            [67.00942241000007, 24.879319409000061],
            [67.009435843000063, 24.879314071000067],
            [67.009444681000048, 24.879311488000042],
            [67.009919994000029, 24.879191067000079],
            [67.009960271000068, 24.879180863000045],
            [67.009964420000074, 24.879179884000052],
            [67.010621858000036, 24.879036411000072],
            [67.010840677000033, 24.878988659000072],
            [67.010847686000034, 24.878987331000076],
            [67.01085306300007, 24.878986582000039],
            [67.011280294000073, 24.878936133000025],
            [67.011288403000037, 24.878935432000048],
            [67.011787285000082, 24.878908035000052],
            [67.011794421000047, 24.878907840000068],
            [67.01180314800007, 24.878908132000049],
            [67.012248579000072, 24.87893807100005],
            [67.012455923000061, 24.878952007000066],
            [67.012492099000042, 24.878952032000029],
            [67.013016788000073, 24.878952398000024],
            [67.013116296000078, 24.878952467000033],
            [67.013120610000044, 24.878952542000036],
            [67.013509744000032, 24.878965715000049],
            [67.013840041000037, 24.87897689600004],
            [67.01385484900004, 24.878978249000056],
            [67.014006599000083, 24.87900094400004],
            [67.014415949000067, 24.879062164000061],
            [67.014427888000057, 24.879064597000024],
            [67.01485346700008, 24.879172695000022],
            [67.015207493000048, 24.879262618000041],
            [67.015216256000031, 24.879265181000051],
            [67.015223030000072, 24.879267648000052],
            [67.015755550000051, 24.879479093000043],
            [67.015995345000078, 24.879581089000055],
            [67.016461365000055, 24.879779308000025],
            [67.016470556000058, 24.879783673000077],
            [67.016474621000043, 24.87978591500007],
            [67.01663205300008, 24.879876675000048],
            [67.016899145000082, 24.880030656000031],
            [67.016911535000077, 24.880038864000028],
            [67.017093566000028, 24.880176649000077],
            [67.017408150000051, 24.880414767000048],
            [67.017411905000074, 24.880417364000039],
            [67.017649548000065, 24.880581754000048],
            [67.018086448000076, 24.880883979000032],
            [67.018091759000072, 24.880887882000025],
            [67.018311143000062, 24.881061405000025],
            [67.018341045000057, 24.881085055000028],
            [67.018401167000036, 24.881132609000076],
            [67.018400462000045, 24.881133095000052],
            [67.018777544000045, 24.881430306000027],
            [67.018784364000055, 24.881436146000055],
            [67.018803265000031, 24.88145365500003],
            [67.019243365000079, 24.881861354000023],
            [67.019389127000068, 24.881979778000073],
            [67.01966995500004, 24.882207936000043],
            [67.020099650000077, 24.882482080000045],
            [67.020107923000069, 24.882487868000055],
            [67.02011391800005, 24.882492782000043],
            [67.020328667000058, 24.88268122900007],
            [67.02046008700006, 24.882796552000059],
            [67.020626352000079, 24.882942452000066],
            [67.020629393000036, 24.882944418000022],
            [67.020891702000029, 24.883113986000069],
            [67.021062055000073, 24.883224110000072],
            [67.021065279000084, 24.883226269000033],
            [67.021193577000076, 24.88331527400004],
            [67.021474718000036, 24.883510312000055],
            [67.021559524000054, 24.883569145000024],
            [67.021560214000033, 24.883569624000074],
            [67.021572584000069, 24.883578205000049],
            [67.021675075000076, 24.883649307000042],
            [67.021680895000031, 24.883652645000041],
            [67.021738507000066, 24.883685687000025],
            [67.021926078000035, 24.883793264000076],
            [67.022138538000036, 24.883915115000036],
            [67.022145264000073, 24.88391927300006],
            [67.022151663000045, 24.883923839000033],
            [67.022156966000068, 24.883928152000067],
            [67.022554769000067, 24.884271639000076],
            [67.02257129700007, 24.884285910000074],
            [67.02257884200003, 24.884293049000064],
            [67.023212202000082, 24.884950080000067],
            [67.023661770000047, 24.885416450000037],
            [67.023664163000035, 24.885419016000071],
            [67.024236659000053, 24.886061873000074],
            [67.025140476000047, 24.887076769000032],
            [67.025462853000079, 24.887412822000044],
            [67.025626655000053, 24.88758357200004],
            [67.025841223000043, 24.887790992000077],
            [67.025974311000084, 24.887919647000047],
            [67.026377054000079, 24.88830897400004],
            [67.026692595000043, 24.888600206000035],
            [67.026979547000053, 24.888816940000027],
            [67.027283360000069, 24.888987089000068],
            [67.027763042000061, 24.88916454200006],
            [67.028379766000057, 24.889372232000028],
            [67.029030411000065, 24.889543647000039],
            [67.029605021000066, 24.889651911000044],
            [67.029613404000031, 24.889653783000028],
            [67.02962249400008, 24.889656503000026],
            [67.030027206000057, 24.889797491000024],
            [67.030807880000054, 24.890021827000055],
            [67.031228469000041, 24.890138523000076],
            [67.031343670000069, 24.890165664000051],
            [67.031466090000038, 24.890194506000057],
            [67.031759700000066, 24.890263681000079],
            [67.031867764000083, 24.890289141000039],
            [67.031874962000074, 24.890291060000038],
            [67.031969402000072, 24.890319217000069],
            [67.032517703000053, 24.890482691000045],
            [67.03272607100007, 24.890535360000058],
            [67.033235300000058, 24.890664077000054],
            [67.033245279000027, 24.890667044000054],
            [67.033645486000069, 24.890807947000042],
            [67.033660895000082, 24.890814566000074],
            [67.034046099000079, 24.891011994000053],
            [67.034052685000063, 24.891015677000041],
            [67.034990463000042, 24.89157954500007],
            [67.034993714000052, 24.891581571000074],
            [67.035366147000047, 24.891822027000046],
            [67.035737641000082, 24.89206187700006],
            [67.036119932000076, 24.892272223000077],
            [67.036326904000077, 24.892368825000062],
            [67.036496607000061, 24.892448033000051],
            [67.036789571000043, 24.892531248000068],
            [67.03679487900007, 24.892532882000069],
            [67.036802680000051, 24.892535762000023],
            [67.037150001000043, 24.892677220000053],
            [67.037178822000044, 24.892689462000078],
            [67.037396203000071, 24.89278179400003],
            [67.03748775300005, 24.892820680000057],
            [67.037493053000048, 24.892823079000038],
            [67.037827977000063, 24.892984320000039],
            [67.03812249300006, 24.893087553000044],
            [67.038486697000053, 24.893180935000032],
            [67.038943283000037, 24.893247062000057],
            [67.039402081000048, 24.893282085000067],
            [67.039953909000076, 24.893252993000033],
            [67.040521393000063, 24.893207969000059],
            [67.040525407000075, 24.893207713000038],
            [67.041622379000046, 24.893154745000061],
            [67.042239143000074, 24.893088584000054],
            [67.042416318000051, 24.893054878000044],
            [67.042630474000077, 24.893014137000023],
            [67.042754819000038, 24.892990481000027],
            [67.043167087000029, 24.892917042000079],
            [67.043171056000062, 24.892916428000035],
            [67.043368805000057, 24.892889202000049],
            [67.043515687000081, 24.89286898000006],
            [67.043524649000062, 24.892868062000048],
            [67.043579356000066, 24.892864370000041],
            [67.043699704000062, 24.892856249000033],
            [67.043708468000034, 24.892855953000037],
            [67.043712115000062, 24.892856004000066],
            [67.043718570000067, 24.892856185000028],
            [67.043856523000045, 24.892860051000071],
            [67.043860122000069, 24.892860270000028],
            [67.043865044000029, 24.892860570000039],
            [67.043873501000064, 24.892861645000039],
            [67.043881074000069, 24.892863097000031],
            [67.043888527000036, 24.892864995000025],
            [67.043971656000053, 24.892888855000024],
            [67.043976627000063, 24.892890393000073],
            [67.043986199000074, 24.892894009000031],
            [67.043995390000077, 24.892898374000026],
            [67.044006575000083, 24.892905057000064],
            [67.044141307000075, 24.892995839000037],
            [67.044428133000054, 24.893182006000075],
            [67.044653870000047, 24.89329970600005],
            [67.045234348000065, 24.893533308000031],
            [67.045242363000057, 24.893536867000023],
            [67.047874883000077, 24.893143949000034],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "157",
      properties: { name: "Malir", count: 1426 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.36938735800004, 25.333449653000059],
            [67.360356163000063, 25.305662719000054],
            [67.360294475000046, 25.305472919000067],
            [67.360262000000034, 25.305373000000031],
            [67.345968000000028, 25.307322000000056],
            [67.329075000000046, 25.296276000000034],
            [67.328425000000038, 25.27613400000007],
            [67.307633000000067, 25.260540000000049],
            [67.29887855000004, 25.248643069000025],
            [67.282293000000038, 25.226104000000078],
            [67.273197000000039, 25.198165000000074],
            [67.272141853000051, 25.189370843000063],
            [67.271248000000071, 25.181921000000045],
            [67.290740000000028, 25.166328000000078],
            [67.300912326000059, 25.162795813000059],
            [67.330715669000028, 25.152447049000045],
            [67.337521000000038, 25.150084000000049],
            [67.365544674000034, 25.13887440700006],
            [67.383003000000031, 25.131891000000053],
            [67.401316357000042, 25.122601770000074],
            [67.401343953000037, 25.122587772000031],
            [67.401554151000028, 25.122481152000034],
            [67.40158157500008, 25.122467242000027],
            [67.418539840000051, 25.113865366000027],
            [67.42783600000007, 25.109150000000056],
            [67.457074000000034, 25.093556000000035],
            [67.476849744000049, 25.077021993000074],
            [67.485022423000032, 25.070189020000043],
            [67.496708000000069, 25.060419000000024],
            [67.501601179000033, 25.048639167000033],
            [67.506581328000038, 25.036649960000034],
            [67.514150398000083, 25.018428189000076],
            [67.514251000000058, 25.018186000000071],
            [67.514251744000035, 25.018179728000064],
            [67.516795153000032, 24.996746100000053],
            [67.517635771000073, 24.989662103000057],
            [67.517636187000051, 24.989658596000027],
            [67.518800000000056, 24.979851000000053],
            [67.520086354000057, 24.979667492000033],
            [67.521388952000052, 24.979481667000073],
            [67.521388936000051, 24.979481361000069],
            [67.520780099000035, 24.95696130400006],
            [67.52076893800006, 24.956548487000077],
            [67.52074900000008, 24.95581100000004],
            [67.521537318000071, 24.952132407000079],
            [67.521537326000043, 24.952132371000062],
            [67.525971790000028, 24.931439469000054],
            [67.530833468000083, 24.912785217000078],
            [67.544923198000049, 24.885950273000049],
            [67.549926408000033, 24.877374094000061],
            [67.559455829000058, 24.861203143000068],
            [67.566464581000048, 24.849309642000037],
            [67.579875000000072, 24.817416000000037],
            [67.579875000000072, 24.80191700000006],
            [67.579875001000062, 24.798592977000055],
            [67.579875000000072, 24.79106624700006],
            [67.579875000000072, 24.790149617000054],
            [67.579875000000072, 24.790126000000043],
            [67.564747713000031, 24.789823799000033],
            [67.547388000000069, 24.789477000000034],
            [67.517640235000044, 24.798291419000066],
            [67.516988000000083, 24.79890000000006],
            [67.515746000000036, 24.800030000000049],
            [67.515175051000028, 24.800540254000055],
            [67.514636000000053, 24.801022000000046],
            [67.513276000000076, 24.802266000000031],
            [67.511258000000055, 24.804030000000068],
            [67.508658000000082, 24.806244000000049],
            [67.507042000000069, 24.80765400000007],
            [67.505832000000055, 24.80863800000003],
            [67.504786231000082, 24.80944915300006],
            [67.503532000000064, 24.810422000000074],
            [67.50314706000006, 24.810620509000046],
            [67.501046000000031, 24.811704000000077],
            [67.499986000000035, 24.812272000000064],
            [67.499452000000076, 24.81254000000007],
            [67.492310879000058, 24.816269001000023],
            [67.491880000000037, 24.816494000000034],
            [67.49104600000004, 24.816910000000064],
            [67.490199672000074, 24.817362244000037],
            [67.489358000000038, 24.81781200000006],
            [67.488206972000057, 24.81840926700005],
            [67.486654275000035, 24.819214960000068],
            [67.486395950000031, 24.81934900400006],
            [67.485072000000059, 24.820036000000073],
            [67.481550000000084, 24.821886000000063],
            [67.481110130000047, 24.822113969000043],
            [67.479343113000084, 24.823029750000046],
            [67.476020000000062, 24.824752000000046],
            [67.474346000000082, 24.825644000000068],
            [67.467112000000043, 24.829376000000025],
            [67.466996589000075, 24.829436625000028],
            [67.464622000000077, 24.830684000000076],
            [67.45915464400008, 24.83350422500007],
            [67.458368000000064, 24.83391000000006],
            [67.453149963000044, 24.836622598000076],
            [67.453028000000074, 24.836686000000043],
            [67.451948000000073, 24.837094000000036],
            [67.45024200000006, 24.837372000000073],
            [67.447628000000066, 24.837634000000037],
            [67.442967696000039, 24.838166768000065],
            [67.439046974000064, 24.838614987000028],
            [67.438977674000057, 24.838477377000061],
            [67.438967904000037, 24.83845797500004],
            [67.438927693000039, 24.838296704000072],
            [67.438854459000083, 24.838002991000053],
            [67.438917395000033, 24.837292277000074],
            [67.438952630000074, 24.837173042000074],
            [67.438901904000033, 24.837124816000028],
            [67.438818807000075, 24.837016364000078],
            [67.438714904000051, 24.836950344000059],
            [67.438683758000082, 24.836860744000035],
            [67.438751334000074, 24.836804158000064],
            [67.438777349000077, 24.836714562000054],
            [67.438756597000065, 24.836624963000077],
            [67.438683864000041, 24.836577797000075],
            [67.438689089000036, 24.836502345000042],
            [67.438767059000043, 24.836445756000046],
            [67.438715125000044, 24.836370295000052],
            [67.438642386000083, 24.836332560000074],
            [67.438658006000082, 24.836264410000069],
            [67.438590511000029, 24.836118202000023],
            [67.438517822000051, 24.835967279000045],
            [67.438361932000078, 24.835811715000034],
            [67.438226789000055, 24.835703268000032],
            [67.438180025000065, 24.835585360000039],
            [67.438180026000055, 24.835580644000061],
            [67.438185249000071, 24.835448585000051],
            [67.438221661000057, 24.835349541000028],
            [67.438237279000077, 24.835283505000064],
            [67.438278920000073, 24.835184453000068],
            [67.43841408600008, 24.835247804000062],
            [67.438512875000072, 24.835205285000029],
            [67.438507705000063, 24.835129830000028],
            [67.438408967000044, 24.835035564000066],
            [67.438320211000075, 24.835008772000037],
            [67.438315387000046, 24.835007315000041],
            [67.438284225000075, 24.834903557000075],
            [67.438263451000068, 24.834832810000023],
            [67.43823748300008, 24.834743193000065],
            [67.438289516000054, 24.83465355900006],
            [67.438409103000083, 24.834611109000036],
            [67.438497491000078, 24.834568660000059],
            [67.43849231400003, 24.834512061000055],
            [67.438471546000073, 24.834446028000059],
            [67.438440367000055, 24.834413009000059],
            [67.438326010000083, 24.834365830000024],
            [67.438268831000073, 24.834328086000028],
            [67.438268864000065, 24.834243177000076],
            [67.438237708000031, 24.834129957000073],
            [67.438253343000042, 24.83403561700004],
            [67.438310555000044, 24.833988464000072],
            [67.438367766000056, 24.833931880000023],
            [67.438414586000079, 24.833851712000069],
            [67.438388624000083, 24.833766798000056],
            [67.438357468000049, 24.833667728000023],
            [67.438315896000063, 24.833611102000077],
            [67.438315914000043, 24.833564509000041],
            [67.438284771000042, 24.833422983000048],
            [67.43826922900007, 24.833272025000042],
            [67.437812883000049, 24.832444898000062],
            [67.437710038000034, 24.83157802900007],
            [67.43775467200004, 24.830733998000028],
            [67.437780749000069, 24.830240883000045],
            [67.437812866000058, 24.829633555000044],
            [67.437762701000054, 24.826456582000048],
            [67.437955415000033, 24.823673571000029],
            [67.438177955000072, 24.820459827000036],
            [67.438410999000041, 24.815596772000049],
            [67.438487031000079, 24.814082261000067],
            [67.438534708000077, 24.813132559000053],
            [67.438618321000035, 24.811467028000038],
            [67.438644448000048, 24.810482706000073],
            [67.438851007000039, 24.810412494000047],
            [67.439135484000076, 24.809779552000066],
            [67.439626255000064, 24.808326841000053],
            [67.440582834000054, 24.806161277000058],
            [67.440893154000037, 24.804684891000079],
            [67.440919102000066, 24.804169342000023],
            [67.440351015000033, 24.802950750000036],
            [67.440118681000058, 24.802200686000049],
            [67.440274453000029, 24.799997340000061],
            [67.440688225000031, 24.798075361000031],
            [67.440653522000048, 24.796965915000044],
            [67.440636901000062, 24.796434563000048],
            [67.440223739000032, 24.795403007000061],
            [67.439707271000032, 24.794777259000057],
            [67.439532899000028, 24.793655275000049],
            [67.439509079000061, 24.79350200500005],
            [67.43939758700003, 24.792784620000077],
            [67.439543576000062, 24.792135078000058],
            [67.439682153000035, 24.791518518000032],
            [67.440354605000039, 24.789457287000062],
            [67.442111491000048, 24.787558588000024],
            [67.443454146000079, 24.78661846600005],
            [67.443631024000069, 24.784732304000045],
            [67.44366552300005, 24.784364423000056],
            [67.443763109000031, 24.783323798000026],
            [67.443901062000066, 24.781852722000053],
            [67.443761576000043, 24.779331077000052],
            [67.440668277000043, 24.778022373000056],
            [67.439240600000062, 24.776594697000064],
            [67.434453125000061, 24.774905280000041],
            [67.432111735000035, 24.773706031000074],
            [67.428799526000034, 24.772449676000065],
            [67.426115494000044, 24.772392569000033],
            [67.423675537000065, 24.773057938000079],
            [67.408576965000066, 24.779249191000076],
            [67.400177002000078, 24.777723312000035],
            [67.397621300000083, 24.776449540000044],
            [67.39659118700007, 24.775936127000023],
            [67.391059227000028, 24.776721115000043],
            [67.390609741000048, 24.776784897000027],
            [67.386060630000031, 24.779560027000059],
            [67.383654121000063, 24.779108807000057],
            [67.375610259000041, 24.779608081000049],
            [67.373668637000037, 24.780329255000026],
            [67.371116791000077, 24.779108807000057],
            [67.368343046000064, 24.777777409000066],
            [67.36640142400006, 24.776002212000037],
            [67.360077284000056, 24.774282490000076],
            [67.355694766000056, 24.774282490000076],
            [67.34881587700005, 24.774726289000057],
            [67.346208557000068, 24.77533651300007],
            [67.342380788000071, 24.772285393000061],
            [67.339717992000033, 24.769234273000052],
            [67.33410483800003, 24.764694940000027],
            [67.333338377000075, 24.764075106000064],
            [67.328567535000047, 24.761412311000072],
            [67.323463844000059, 24.762022535000028],
            [67.322081246000039, 24.762276481000072],
            [67.320745573000067, 24.762521809000077],
            [67.318178930000045, 24.764629377000063],
            [67.313922159000072, 24.768124775000047],
            [67.31120388800008, 24.771342319000041],
            [67.30764952100003, 24.773155772000052],
            [67.305767347000028, 24.774116065000044],
            [67.295707653000079, 24.778108007000071],
            [67.294598472000075, 24.778548158000035],
            [67.291787670000076, 24.77966355600006],
            [67.288138235000076, 24.781462930000032],
            [67.283799283000064, 24.783602274000032],
            [67.27619922000008, 24.787873843000057],
            [67.272306838000077, 24.790165219000073],
            [67.267044313000042, 24.789902878000078],
            [67.261874934000048, 24.789645181000026],
            [67.26185607900004, 24.789644241000076],
            [67.248588562000066, 24.784187317000033],
            [67.245355171000028, 24.783546800000067],
            [67.243413549000081, 24.783990599000049],
            [67.242304051000076, 24.785266522000029],
            [67.241527402000031, 24.787818368000046],
            [67.243358074000071, 24.788761441000077],
            [67.244301147000044, 24.790314739000053],
            [67.243690923000031, 24.792700160000038],
            [67.243396665000034, 24.795081222000078],
            [67.24065577500005, 24.796791855000038],
            [67.240640926000083, 24.796801123000023],
            [67.240617213000064, 24.796815922000064],
            [67.240310777000047, 24.797007174000044],
            [67.239593506000062, 24.797454834000064],
            [67.238727123000047, 24.799878999000043],
            [67.234608030000061, 24.800253092000048],
            [67.233850171000029, 24.800196954000057],
            [67.233256276000077, 24.800152962000027],
            [67.231654197000069, 24.799852572000077],
            [67.228852367000059, 24.800817136000035],
            [67.228600235000044, 24.800903936000054],
            [67.227398676000064, 24.801454651000029],
            [67.223043024000049, 24.80310679400003],
            [67.22262869900004, 24.803321100000062],
            [67.220139257000028, 24.804608743000074],
            [67.217458602000079, 24.806571365000025],
            [67.217335619000039, 24.806661406000046],
            [67.215433150000081, 24.808413680000058],
            [67.215079120000041, 24.810321100000067],
            [67.214658582000084, 24.813763440000059],
            [67.213775635000047, 24.813703537000038],
            [67.213343667000061, 24.813451556000075],
            [67.209976196000071, 24.811487198000066],
            [67.207135500000049, 24.807569859000068],
            [67.202835083000082, 24.801639557000044],
            [67.198335309000072, 24.799571147000051],
            [67.194984436000084, 24.798030853000057],
            [67.191596985000047, 24.791442871000072],
            [67.188903809000067, 24.790098190000037],
            [67.185699463000049, 24.784769058000052],
            [67.183036804000039, 24.782798767000031],
            [67.180751429000054, 24.779863999000042],
            [67.177325005000057, 24.779102572000056],
            [67.17396545400004, 24.781026840000038],
            [67.168731689000083, 24.780429840000068],
            [67.168525625000029, 24.780430099000057],
            [67.164571094000053, 24.780435070000067],
            [67.163537705000067, 24.782313958000032],
            [67.162477168000066, 24.784242207000034],
            [67.159241101000077, 24.789952914000025],
            [67.156576105000056, 24.789572200000066],
            [67.15162682600004, 24.789001129000042],
            [67.150669151000045, 24.78832512300005],
            [67.148390759000051, 24.786716847000037],
            [67.147058261000041, 24.784051850000026],
            [67.146106477000046, 24.781577211000069],
            [67.143251123000027, 24.778912215000048],
            [67.13944398600006, 24.778341144000024],
            [67.135636848000047, 24.777199003000078],
            [67.13625131200007, 24.779813262000062],
            [67.135618924000028, 24.791595408000035],
            [67.138712224000074, 24.795878438000045],
            [67.139901954000038, 24.800875306000023],
            [67.141097372000047, 24.802044350000074],
            [67.141042108000079, 24.801445383000043],
            [67.142629880000072, 24.798979333000034],
            [67.146773328000052, 24.800455082000042],
            [67.147936899000058, 24.80073888000004],
            [67.148647661000041, 24.800716196000053],
            [67.150604598000029, 24.80065374000003],
            [67.151824928000053, 24.80065374000003],
            [67.151597890000062, 24.801505134000024],
            [67.151512751000041, 24.801959210000064],
            [67.152420904000053, 24.802753844000051],
            [67.15321553800004, 24.802952502000039],
            [67.154606147000038, 24.802838983000072],
            [67.156507592000082, 24.802895743000079],
            [67.15735898500003, 24.802867363000075],
            [67.158409037000069, 24.803207920000034],
            [67.159998305000045, 24.803974174000075],
            [67.161871370000028, 24.804768808000063],
            [67.16346063800006, 24.805166125000028],
            [67.164822867000055, 24.805308024000055],
            [67.164510689000053, 24.807720305000032],
            [67.16641213500003, 24.808089242000051],
            [67.17004474600003, 24.808401420000052],
            [67.172627306000038, 24.808770357000071],
            [67.176118019000057, 24.809451472000035],
            [67.179750630000058, 24.810075827000048],
            [67.186023866000028, 24.809036822000053],
            [67.187213596000049, 24.809363997000048],
            [67.189027936000059, 24.809988606000047],
            [67.189712031000056, 24.810345525000059],
            [67.19042586900008, 24.811059363000027],
            [67.19108022100005, 24.812040891000038],
            [67.192954046000068, 24.814747528000055],
            [67.193638141000065, 24.815639826000051],
            [67.194381723000049, 24.816502380000031],
            [67.195036075000075, 24.817186476000074],
            [67.197802198000034, 24.819417220000048],
            [67.200122172000079, 24.821201816000041],
            [67.20137138900003, 24.822213087000023],
            [67.20273958000007, 24.822748466000064],
            [67.203771076000066, 24.823306449000029],
            [67.20772098100008, 24.82504345600006],
            [67.208220668000081, 24.825257607000026],
            [67.208553793000078, 24.825328991000049],
            [67.208553793000078, 24.824710331000063],
            [67.208244463000028, 24.823996493000038],
            [67.207625803000042, 24.822766347000027],
            [67.20726888400003, 24.822045335000041],
            [67.207673392000061, 24.821188729000028],
            [67.20795892700005, 24.820451096000056],
            [67.209362809000083, 24.820427301000052],
            [67.210338388000082, 24.820546274000037],
            [67.210742897000046, 24.820879399000034],
            [67.211599503000059, 24.821283907000065],
            [67.212194368000041, 24.821260113000051],
            [67.213455482000029, 24.821188729000028],
            [67.215811149000046, 24.821045961000038],
            [67.218856859000084, 24.82085560400003],
            [67.224305824000055, 24.820522480000022],
            [67.228707827000051, 24.820284534000052],
            [67.232062867000081, 24.82007038200004],
            [67.232895942000027, 24.820028308000076],
            [67.234418534000042, 24.819951409000055],
            [67.238344644000051, 24.819642079000062],
            [67.23986749900007, 24.819570695000039],
            [67.240700311000069, 24.819475517000058],
            [67.241295176000051, 24.819808642000055],
            [67.241771068000048, 24.82007038200004],
            [67.241794863000052, 24.822830557000032],
            [67.241723479000029, 24.829112334000058],
            [67.241699684000082, 24.831563179000057],
            [67.241699684000082, 24.835465495000051],
            [67.241628301000048, 24.838201875000038],
            [67.240248213000029, 24.838201875000038],
            [67.238463617000036, 24.838059107000049],
            [67.237035941000045, 24.837987724000072],
            [67.235703443000034, 24.83801151800003],
            [67.233419160000039, 24.837916340000049],
            [67.231491797000047, 24.837916340000049],
            [67.229326487000037, 24.83786875100003],
            [67.227779837000071, 24.837797367000064],
            [67.226066626000033, 24.837797367000064],
            [67.22551935000007, 24.837844956000026],
            [67.225527593000038, 24.838285065000036],
            [67.225733501000036, 24.839534373000049],
            [67.22590006300004, 24.84086687100006],
            [67.226090420000048, 24.841961424000033],
            [67.22644733900006, 24.843508073000066],
            [67.226709080000035, 24.845245080000041],
            [67.226780464000058, 24.845816150000076],
            [67.225638323000055, 24.846030302000031],
            [67.224091673000032, 24.846315837000077],
            [67.222687791000055, 24.846672756000032],
            [67.221045963000051, 24.847220032000052],
            [67.219570697000051, 24.847743514000058],
            [67.217643334000059, 24.848766682000075],
            [67.216168068000059, 24.849551904000066],
            [67.21478798000004, 24.850313332000042],
            [67.213550661000056, 24.851098554000032],
            [67.212170573000037, 24.852002749000064],
            [67.210933254000054, 24.852978328000063],
            [67.209315220000065, 24.854453594000063],
            [67.20850620300007, 24.855286405000072],
            [67.207221294000078, 24.856595109000068],
            [67.206436072000031, 24.857451715000025],
            [67.205341520000047, 24.858879391000073],
            [67.204532503000053, 24.860188095000069],
            [67.203342773000031, 24.861972691000062],
            [67.202850482000031, 24.86279214700005],
            [67.203330678000043, 24.863048840000033],
            [67.204671424000082, 24.863569464000022],
            [67.205288092000046, 24.863848847000042],
            [67.204836810000074, 24.864550813000051],
            [67.203729000000067, 24.866274000000033],
            [67.203684654000028, 24.866342115000066],
            [67.203604000000041, 24.866466000000059],
            [67.20343900000006, 24.866720000000043],
            [67.20343538700007, 24.866725314000064],
            [67.203388000000075, 24.866795000000025],
            [67.203173000000049, 24.867110000000025],
            [67.202846000000079, 24.867598000000044],
            [67.202738000000068, 24.867747000000065],
            [67.202594000000033, 24.867909000000054],
            [67.202358319000041, 24.868143882000027],
            [67.202299000000039, 24.868203000000051],
            [67.201984000000039, 24.868440000000078],
            [67.201756000000046, 24.868595000000028],
            [67.201117000000067, 24.869004000000075],
            [67.200673000000052, 24.86928400000005],
            [67.200358000000051, 24.869581000000039],
            [67.199727000000053, 24.870086000000072],
            [67.199615592000043, 24.870179576000055],
            [67.199447841000051, 24.870320476000074],
            [67.198960020000072, 24.870730216000027],
            [67.198415000000068, 24.871188000000075],
            [67.197335000000066, 24.872080000000039],
            [67.197114087000045, 24.872256390000075],
            [67.196544579000033, 24.872711117000051],
            [67.19629800000007, 24.872908000000052],
            [67.195142000000033, 24.873837000000037],
            [67.194467624000083, 24.874385855000071],
            [67.193729000000076, 24.874987000000033],
            [67.193370000000073, 24.875275000000045],
            [67.192825000000028, 24.875733000000025],
            [67.192513474000066, 24.87599874700004],
            [67.192267000000072, 24.876209000000074],
            [67.191678275000072, 24.876719038000033],
            [67.191444000000047, 24.876922000000036],
            [67.191236204000063, 24.877095274000055],
            [67.191131000000041, 24.877183000000059],
            [67.190785000000062, 24.877446000000077],
            [67.190420000000074, 24.877706000000046],
            [67.189659000000063, 24.878180000000043],
            [67.189228669000045, 24.878411404000076],
            [67.189023000000077, 24.878522000000032],
            [67.188358000000051, 24.878819000000078],
            [67.188131000000055, 24.878936000000067],
            [67.18790000000007, 24.879061000000036],
            [67.187532000000033, 24.879278000000056],
            [67.186791000000028, 24.879743000000076],
            [67.186218000000054, 24.880094000000042],
            [67.185065000000066, 24.880747000000042],
            [67.18471100000005, 24.880941000000064],
            [67.184567811000079, 24.881017058000054],
            [67.183941000000061, 24.881350000000054],
            [67.183765073000075, 24.881438840000044],
            [67.183339000000046, 24.881654000000026],
            [67.182288000000028, 24.882179000000065],
            [67.182162000000062, 24.882238000000029],
            [67.181976000000077, 24.882323000000042],
            [67.181623000000059, 24.882469000000071],
            [67.181066000000044, 24.882678000000055],
            [67.180540000000065, 24.88287500000007],
            [67.180378703000031, 24.882933078000065],
            [67.17997900000006, 24.883077000000071],
            [67.179657000000077, 24.883186000000023],
            [67.179243000000042, 24.883317000000034],
            [67.178902000000051, 24.883436000000074],
            [67.178092427000081, 24.883773003000044],
            [67.177265000000034, 24.884137000000067],
            [67.178160000000048, 24.884251000000063],
            [67.178498000000047, 24.884295000000066],
            [67.178863000000035, 24.884392000000048],
            [67.179225000000031, 24.884506000000044],
            [67.179248912000048, 24.884514786000068],
            [67.178578099000049, 24.885453925000036],
            [67.179470397000046, 24.886941088000071],
            [67.182742155000028, 24.890510280000058],
            [67.181552425000064, 24.893187173000058],
            [67.178280666000035, 24.894079471000055],
            [67.175603772000045, 24.895864067000048],
            [67.177983233000077, 24.898838393000062],
            [67.179172964000031, 24.902407585000049],
            [67.180957560000081, 24.907463939000024],
            [67.18244472300006, 24.914304890000039],
            [67.183167488000038, 24.913100281000027],
            [67.184229319000053, 24.911330563000035],
            [67.184556767000061, 24.911330563000035],
            [67.188095943000064, 24.911330563000035],
            [67.193747163000069, 24.91162799600005],
            [67.198506085000076, 24.912222861000032],
            [67.197911219000048, 24.908653670000035],
            [67.195531758000072, 24.905976776000045],
            [67.196613973000069, 24.904430755000078],
            [67.196736136000084, 24.904256237000027],
            [67.196849495000038, 24.90409429500005],
            [67.196862702000033, 24.904075429000045],
            [67.19761378700008, 24.903002450000031],
            [67.199418945000048, 24.903403596000032],
            [67.20296757400007, 24.904192180000052],
            [67.203265007000084, 24.905381911000063],
            [67.202670141000056, 24.907761372000039],
            [67.20296757400007, 24.90895110200006],
            [67.205049602000031, 24.910140833000071],
            [67.20832136100006, 24.909545968000032],
            [67.211593120000032, 24.909545968000032],
            [67.214323789000048, 24.909402248000049],
            [67.214965179000046, 24.909368491000066],
            [67.214978000000031, 24.909373000000073],
            [67.215367000000072, 24.909470000000056],
            [67.21537059700006, 24.909471068000073],
            [67.216154653000046, 24.909703909000029],
            [67.216192000000035, 24.909715000000062],
            [67.216344000000049, 24.909760000000063],
            [67.216519368000036, 24.909808548000058],
            [67.217052000000081, 24.909956000000022],
            [67.217612000000031, 24.910086000000035],
            [67.218652000000077, 24.910339000000079],
            [67.218775000000051, 24.910363000000075],
            [67.218953000000056, 24.910397000000046],
            [67.21920300000005, 24.91045900000006],
            [67.219244794000076, 24.910463179000033],
            [67.222945143000061, 24.912709973000062],
            [67.224848712000039, 24.919134518000078],
            [67.227942011000039, 24.926034955000034],
            [67.230083526000044, 24.932221553000034],
            [67.231415241000036, 24.933363023000027],
            [67.23341477200006, 24.935076906000063],
            [67.236983963000057, 24.938408152000022],
            [67.242218778000051, 24.942215289000046],
            [67.24388440000007, 24.944832696000049],
            [67.242694670000049, 24.948877780000032],
            [67.242451740000035, 24.951037155000051],
            [67.242052215000058, 24.954588487000024],
            [67.240267619000065, 24.96101303100005],
            [67.240267619000065, 24.972077525000032],
            [67.238126105000049, 24.979929746000039],
            [67.156391619000033, 24.990280402000053],
            [67.137117985000032, 24.993135755000026],
            [67.133548793000045, 24.990994240000077],
            [67.130336521000061, 24.986711210000067],
            [67.126690837000069, 24.982390876000068],
            [67.119484931000045, 24.974725548000038],
            [67.11058169000006, 24.964995256000066],
            [67.108168546000059, 24.962323560000073],
            [67.106272503000071, 24.960642977000077],
            [67.100799836000078, 24.956053693000058],
            [67.088816862000044, 24.945746575000044],
            [67.088216426000031, 24.945203323000044],
            [67.087833623000051, 24.944856978000075],
            [67.087766501000033, 24.944796248000046],
            [67.087757287000045, 24.944809974000066],
            [67.087751754000067, 24.944817492000027],
            [67.087280188000079, 24.945403655000064],
            [67.087275400000067, 24.94540921600003],
            [67.087271866000037, 24.945412884000064],
            [67.086983780000082, 24.945698700000037],
            [67.086844887000041, 24.945836499000052],
            [67.086663509000061, 24.946016447000034],
            [67.086271614000054, 24.946430946000078],
            [67.086002306000069, 24.946715787000073],
            [67.085995766000053, 24.94672216500004],
            [67.085988684000029, 24.946728043000064],
            [67.085980461000077, 24.946733796000046],
            [67.085971718000053, 24.946738874000062],
            [67.085962524000081, 24.946743237000078],
            [67.085952948000056, 24.946746854000025],
            [67.08594306100008, 24.94674969600004],
            [67.085932941000067, 24.946751741000071],
            [67.085922665000055, 24.946752975000038],
            [67.085912308000047, 24.946753387000058],
            [67.085901952000029, 24.946752975000038],
            [67.085891676000074, 24.946751741000071],
            [67.085881556000061, 24.94674969600004],
            [67.085871670000074, 24.946746854000025],
            [67.085862094000049, 24.946743237000078],
            [67.085852899000031, 24.946738874000062],
            [67.085844157000054, 24.946733796000046],
            [67.085835933000055, 24.946728043000064],
            [67.085672257000056, 24.946900107000033],
            [67.08553618600007, 24.947043151000059],
            [67.085492956000053, 24.947088597000061],
            [67.08494509500008, 24.947808995000059],
            [67.084627734000037, 24.94827758100007],
            [67.084446166000077, 24.948583485000029],
            [67.084318898000049, 24.948797313000057],
            [67.084283767000045, 24.948928538000075],
            [67.084267534000048, 24.949025057000028],
            [67.084253001000036, 24.949111467000023],
            [67.08423091700007, 24.94956488400004],
            [67.084261541000046, 24.949808914000073],
            [67.084325016000037, 24.950190983000027],
            [67.084473935000062, 24.950842937000061],
            [67.08463940200005, 24.951343001000055],
            [67.084700749000035, 24.951541865000024],
            [67.084797887000036, 24.951695408000035],
            [67.084889967000038, 24.951806785000031],
            [67.084951341000078, 24.951918152000076],
            [67.08502586700007, 24.952049407000061],
            [67.085078459000044, 24.952184633000059],
            [67.085141414000077, 24.952306051000051],
            [67.085204677000036, 24.95241902600003],
            [67.085351426000045, 24.952698067000028],
            [67.085462607000068, 24.953008647000047],
            [67.085511046000079, 24.953224225000042],
            [67.085545227000068, 24.953413113000067],
            [67.085570792000055, 24.953781025000069],
            [67.085576321000076, 24.954334086000074],
            [67.08554698100005, 24.954513488000032],
            [67.08550126800003, 24.954673902000025],
            [67.085426984000037, 24.954942317000075],
            [67.085372681000081, 24.955207633000043],
            [67.085346908000076, 24.95549920600007],
            [67.085335382000039, 24.955847367000047],
            [67.085312412000064, 24.956287271000065],
            [67.085266546000071, 24.95693422100004],
            [67.085189168000056, 24.957937230000027],
            [67.085170608000055, 24.958240235000062],
            [67.085160474000077, 24.958405689000074],
            [67.085169955000083, 24.958592602000067],
            [67.085188906000042, 24.958712726000044],
            [67.085251597000081, 24.959026969000035],
            [67.085267789000056, 24.959117541000069],
            [67.085291484000038, 24.959250079000071],
            [67.08534849800003, 24.959459672000037],
            [67.085411237000073, 24.959609756000077],
            [67.085491841000078, 24.959749102000046],
            [67.085500941000078, 24.959761466000032],
            [67.085578258000055, 24.95986651100003],
            [67.085663849000071, 24.95996145600003],
            [67.085683780000068, 24.959983565000073],
            [67.085817871000074, 24.960112967000043],
            [67.08594596100005, 24.960220262000064],
            [67.086020142000052, 24.960284964000039],
            [67.086082904000079, 24.96036001300007],
            [67.086185605000082, 24.960481644000026],
            [67.086189045000083, 24.960485073000029],
            [67.086320243000046, 24.960615876000077],
            [67.086434365000059, 24.960721986000067],
            [67.086635257000069, 24.960918497000023],
            [67.086704169000029, 24.960985906000076],
            [67.087073786000076, 24.961343761000023],
            [67.087079550000055, 24.961349305000056],
            [67.087281217000054, 24.961543238000047],
            [67.087619202000042, 24.961868261000063],
            [67.088056168000037, 24.962276082000074],
            [67.088298661000067, 24.962547799000049],
            [67.088342139000076, 24.962607262000063],
            [67.088424915000076, 24.962720469000033],
            [67.088459680000028, 24.962768014000062],
            [67.088556653000069, 24.962962079000079],
            [67.088657918000081, 24.96316220500006],
            [67.088752055000043, 24.963294173000065],
            [67.088925313000061, 24.963475106000033],
            [67.089010418000044, 24.963551076000044],
            [67.089136293000081, 24.963663441000051],
            [67.089373833000082, 24.963884146000055],
            [67.089563896000072, 24.964070005000053],
            [67.08971508500008, 24.964269245000025],
            [67.090032228000041, 24.964668433000043],
            [67.09016121600007, 24.964864115000069],
            [67.090263874000073, 24.965138373000059],
            [67.09027958300004, 24.965206472000034],
            [67.090356495000037, 24.965539877000026],
            [67.090365000000077, 24.965620217000037],
            [67.090392272000031, 24.965877834000025],
            [67.090426407000052, 24.966276276000031],
            [67.090453663000062, 24.966556657000069],
            [67.090471939000054, 24.966744658000039],
            [67.090551147000042, 24.967056897000077],
            [67.090705099000047, 24.967595088000053],
            [67.090844006000054, 24.968042790000027],
            [67.09087984100006, 24.968158288000041],
            [67.090962637000075, 24.968480235000072],
            [67.09102537900003, 24.968627707000053],
            [67.091113802000052, 24.968777768000052],
            [67.091190496000081, 24.968905881000069],
            [67.09126573900005, 24.969019609000043],
            [67.091288088000056, 24.969056628000033],
            [67.09132532600006, 24.969118306000041],
            [67.091331344000082, 24.96912827400007],
            [67.091379819000053, 24.969260215000077],
            [67.091390144000059, 24.969334809000031],
            [67.091422114000068, 24.969565770000031],
            [67.091459112000052, 24.969930605000059],
            [67.091468212000052, 24.970193822000056],
            [67.091468840000061, 24.970211992000031],
            [67.091473269000062, 24.970340079000039],
            [67.091473203000078, 24.970583243000078],
            [67.091471594000041, 24.970611118000079],
            [67.091461237000033, 24.970790559000022],
            [67.09142694500008, 24.970969045000061],
            [67.09140323400004, 24.971024890000024],
            [67.091312728000048, 24.971238051000057],
            [67.091118496000036, 24.971661278000056],
            [67.090931867000052, 24.972067938000066],
            [67.090617823000059, 24.972724136000068],
            [67.09051617800003, 24.973025026000073],
            [67.090476575000082, 24.973198941000078],
            [67.090416183000059, 24.973464153000066],
            [67.090336247000039, 24.973915823000027],
            [67.090301667000062, 24.97411121500005],
            [67.090293970000062, 24.974198455000078],
            [67.090293043000031, 24.974208968000028],
            [67.090274152000063, 24.974423076000051],
            [67.090254515000083, 24.974645646000056],
            [67.090254593000054, 24.974651684000037],
            [67.090255176000028, 24.974696831000074],
            [67.090257674000043, 24.974736032000067],
            [67.090266358000065, 24.974872319000042],
            [67.090272666000033, 24.974971304000064],
            [67.090281415000049, 24.975108609000074],
            [67.090319657000066, 24.975281067000026],
            [67.090352660000065, 24.975429896000037],
            [67.090390211000056, 24.97554608400003],
            [67.09047240600006, 24.975800415000037],
            [67.090486978000058, 24.975838737000061],
            [67.090645441000049, 24.976255466000055],
            [67.090694177000046, 24.976383633000069],
            [67.090706806000071, 24.976416845000074],
            [67.090791490000072, 24.976658346000079],
            [67.090802756000073, 24.976690473000076],
            [67.090813462000028, 24.976774817000035],
            [67.090839654000035, 24.976981164000051],
            [67.090842616000032, 24.977004501000067],
            [67.090845021000064, 24.97707465700006],
            [67.090850548000049, 24.977235956000072],
            [67.090860532000079, 24.977527292000048],
            [67.090865257000075, 24.977665196000032],
            [67.09087007200003, 24.978073922000078],
            [67.090875487000062, 24.978533629000026],
            [67.090876406000064, 24.978611683000054],
            [67.090879175000055, 24.978923895000037],
            [67.090890547000072, 24.979068741000049],
            [67.090927222000062, 24.979219422000028],
            [67.090932000000066, 24.979234000000076],
            [67.090940882000041, 24.979269106000061],
            [67.091002911000032, 24.979480730000034],
            [67.091084151000075, 24.979655715000035],
            [67.091175422000049, 24.979800580000074],
            [67.091275109000037, 24.979912975000047],
            [67.09127720400005, 24.979914911000037],
            [67.091426308000052, 24.980052684000043],
            [67.091627288000041, 24.980199918000039],
            [67.091648777000046, 24.980214587000034],
            [67.091892620000067, 24.980381039000065],
            [67.092208826000046, 24.980556658000069],
            [67.092388957000082, 24.980655686000034],
            [67.092419710000058, 24.980688648000068],
            [67.09245171300006, 24.98072294900004],
            [67.092511603000048, 24.980829006000079],
            [67.092536264000046, 24.98088957300007],
            [67.092564267000057, 24.980958347000069],
            [67.09260069000004, 24.981047802000035],
            [67.092698922000068, 24.981347368000058],
            [67.092770174000066, 24.981657876000043],
            [67.092793276000066, 24.981818479000026],
            [67.092810051000072, 24.981935099000054],
            [67.092818212000054, 24.982114910000064],
            [67.092818564000083, 24.982122669000034],
            [67.092818537000028, 24.982231300000024],
            [67.092795692000038, 24.98229854300007],
            [67.092744308000078, 24.98237871300006],
            [67.092545389000065, 24.982544045000054],
            [67.09239614300003, 24.982694121000065],
            [67.092182728000068, 24.982908723000037],
            [67.092178323000041, 24.982913153000027],
            [67.091983741000035, 24.983108817000073],
            [67.091600809000056, 24.983454290000054],
            [67.091164525000067, 24.983847895000054],
            [67.09107103000008, 24.983950314000026],
            [67.091011090000052, 24.984035646000052],
            [67.090962562000072, 24.984123569000076],
            [67.090919740000061, 24.984208910000063],
            [67.090888322000069, 24.984327880000023],
            [67.090853026000048, 24.984558701000026],
            [67.090851364000059, 24.98456956900003],
            [67.090792646000068, 24.984953569000027],
            [67.090772016000074, 24.985654550000049],
            [67.090762472000051, 24.986075403000029],
            [67.090757132000078, 24.986310902000071],
            [67.09075135300003, 24.986565721000034],
            [67.090743570000029, 24.986874679000039],
            [67.090740942000082, 24.986978986000054],
            [67.090734329000043, 24.987241471000061],
            [67.090727771000047, 24.987501784000074],
            [67.090743047000046, 24.98791781500006],
            [67.090744766000057, 24.987964627000053],
            [67.090750096000079, 24.988109774000066],
            [67.090756708000072, 24.988289836000035],
            [67.090791345000071, 24.988699767000071],
            [67.090810154000053, 24.988922360000061],
            [67.090815000000077, 24.988979717000063],
            [67.090815817000077, 24.988989390000029],
            [67.090832264000028, 24.989184038000076],
            [67.090856057000053, 24.989475585000037],
            [67.090906535000045, 24.990094143000078],
            [67.090932735000081, 24.990242136000063],
            [67.09094669600006, 24.990281166000045],
            [67.090952180000045, 24.990296499000067],
            [67.090985174000082, 24.99038874200005],
            [67.091088988000081, 24.990529220000042],
            [67.091173513000058, 24.990629659000035],
            [67.091288549000069, 24.990733566000074],
            [67.091447251000034, 24.990881295000065],
            [67.091502018000028, 24.990932274000045],
            [67.091863898000042, 24.99119627400006],
            [67.091886266000074, 24.991212591000078],
            [67.091897283000037, 24.991220629000054],
            [67.092408343000045, 24.991637034000064],
            [67.09241260400006, 24.991640505000078],
            [67.092558796000048, 24.991756893000058],
            [67.092650500000047, 24.991829902000063],
            [67.092792805000045, 24.991990595000061],
            [67.092921035000074, 24.992199381000034],
            [67.092945022000038, 24.992237254000031],
            [67.09309101100007, 24.992467757000043],
            [67.093147244000079, 24.992556544000024],
            [67.093166843000063, 24.99260163100007],
            [67.093246789000034, 24.992785540000057],
            [67.093292770000062, 24.992979852000076],
            [67.093293909000067, 24.992984665000051],
            [67.093328801000041, 24.99335865200004],
            [67.093329229000062, 24.993374927000048],
            [67.093333929000039, 24.993553663000057],
            [67.093340348000083, 24.993797770000072],
            [67.093302337000068, 24.994208422000042],
            [67.093261379000069, 24.994398095000065],
            [67.093244194000079, 24.994477676000031],
            [67.093206179000049, 24.994653722000066],
            [67.093182682000077, 24.994762534000074],
            [67.093182226000067, 24.994763937000073],
            [67.093013442000029, 24.995283900000061],
            [67.092982733000042, 24.995356078000043],
            [67.092756782000038, 24.995887154000059],
            [67.092575069000077, 24.996376022000049],
            [67.092475396000054, 24.996644175000029],
            [67.092313928000067, 24.997024112000076],
            [67.092137962000038, 24.997438163000027],
            [67.092133561000082, 24.997448140000074],
            [67.091790401000083, 24.998226182000053],
            [67.091714520000039, 24.998518506000039],
            [67.091629784000077, 24.998865446000025],
            [67.091562744000043, 24.999139933000038],
            [67.091484427000069, 24.999397937000026],
            [67.091469669000048, 24.999446555000077],
            [67.091464860000031, 24.999462396000069],
            [67.091445647000057, 24.999525692000077],
            [67.09137267300008, 24.999829557000055],
            [67.091331886000034, 25.000086431000057],
            [67.091320101000065, 25.000160656000048],
            [67.091261240000051, 25.000769364000064],
            [67.091196989000082, 25.001160404000075],
            [67.091135652000048, 25.001558999000054],
            [67.09111297100003, 25.001824987000077],
            [67.091097626000078, 25.002004936000048],
            [67.091115047000073, 25.002275316000066],
            [67.09118053800006, 25.002592995000043],
            [67.091190755000071, 25.002642553000044],
            [67.091195001000074, 25.002662010000051],
            [67.091272289000074, 25.003016124000055],
            [67.091274278000071, 25.003023422000069],
            [67.091349839000031, 25.00330060400006],
            [67.091357434000031, 25.003329997000037],
            [67.091453204000061, 25.003700602000038],
            [67.091480584000067, 25.003821639000023],
            [67.091555070000084, 25.004150911000067],
            [67.091557822000084, 25.004163078000033],
            [67.091567668000039, 25.00419736300006],
            [67.091658535000079, 25.004513778000046],
            [67.091683404000037, 25.004600375000052],
            [67.09182099700007, 25.004943092000076],
            [67.091858871000056, 25.00503169600006],
            [67.091904512000042, 25.00513846900003],
            [67.091909821000058, 25.005256551000059],
            [67.091903401000081, 25.005288504000077],
            [67.091888423000057, 25.005363053000053],
            [67.091812325000035, 25.005585161000056],
            [67.091730706000078, 25.005823382000074],
            [67.091626716000064, 25.006158810000045],
            [67.091619276000074, 25.006182809000052],
            [67.091591616000073, 25.006272031000037],
            [67.091535463000071, 25.006673369000055],
            [67.091530061000071, 25.006711978000055],
            [67.091530453000075, 25.006755379000026],
            [67.091535247000081, 25.007285876000026],
            [67.091546562000076, 25.007427377000056],
            [67.091561719000083, 25.007616929000051],
            [67.091581671000029, 25.007764946000066],
            [67.091594677000046, 25.007861430000048],
            [67.091596193000044, 25.007872678000069],
            [67.091627383000059, 25.008104059000061],
            [67.091589658000032, 25.008130562000076],
            [67.091470748000063, 25.008098764000067],
            [67.091133312000068, 25.008008530000041],
            [67.090988907000053, 25.007969915000047],
            [67.09089436000005, 25.007944633000022],
            [67.090893311000059, 25.007944352000038],
            [67.090749741000081, 25.007905960000073],
            [67.090698082000074, 25.007895148000046],
            [67.090585709000038, 25.007871628000032],
            [67.09040260300003, 25.007841110000072],
            [67.09026908900006, 25.007818222000026],
            [67.090173721000042, 25.007802963000074],
            [67.090066910000075, 25.007791519000023],
            [67.089994431000036, 25.007783890000042],
            [67.089906693000046, 25.007776260000071],
            [67.089765549000049, 25.007768631000033],
            [67.08947181700006, 25.007753372000025],
            [67.088153224000052, 25.007694913000023],
            [67.087923050000029, 25.007684708000056],
            [67.086851656000078, 25.007644453000069],
            [67.086603165000042, 25.007635117000063],
            [67.08621579000004, 25.007619589000058],
            [67.085048148000055, 25.007572784000047],
            [67.084794998000064, 25.007562637000035],
            [67.084573249000073, 25.007554456000037],
            [67.083865499000069, 25.007528345000026],
            [67.083296741000083, 25.007507362000069],
            [67.082894064000072, 25.007492506000062],
            [67.082830429000069, 25.007490158000053],
            [67.081696429000033, 25.007416613000032],
            [67.081552422000073, 25.007407274000059],
            [67.08133368600005, 25.007393088000072],
            [67.081007004000071, 25.007371902000045],
            [67.079768471000079, 25.007292283000027],
            [67.079440914000031, 25.007271226000057],
            [67.079416218000063, 25.007269638000025],
            [67.079404831000033, 25.007268906000036],
            [67.079077957000038, 25.007250746000068],
            [67.078230551000047, 25.007203668000045],
            [67.078046161000032, 25.007193424000036],
            [67.077825546000042, 25.007181168000045],
            [67.077713712000048, 25.007176448000052],
            [67.077357953000046, 25.007161432000032],
            [67.076971276000052, 25.007145111000057],
            [67.076790533000064, 25.007137482000076],
            [67.076650620000066, 25.007131577000052],
            [67.076484228000083, 25.007120824000026],
            [67.07607607500006, 25.007094446000053],
            [67.07552909900005, 25.007059097000024],
            [67.075382783000066, 25.007051649000061],
            [67.075317811000048, 25.007048341000029],
            [67.07456747100008, 25.00701014200007],
            [67.074480057000073, 25.007005692000064],
            [67.07434401900008, 25.006999343000075],
            [67.074265178000076, 25.006995664000044],
            [67.07376832500006, 25.006972478000023],
            [67.073335648000068, 25.006952286000057],
            [67.073175171000059, 25.006944057000055],
            [67.073001196000064, 25.006935135000049],
            [67.072855648000029, 25.006927671000028],
            [67.072591782000075, 25.00691413900006],
            [67.07255942300003, 25.006912415000045],
            [67.072249120000038, 25.006895882000038],
            [67.071660995000059, 25.006864548000067],
            [67.071299748000058, 25.006849129000045],
            [67.071035385000073, 25.006837845000064],
            [67.07081495400007, 25.006825451000054],
            [67.070196950000081, 25.006790704000025],
            [67.070085526000071, 25.006784439000057],
            [67.06987859700007, 25.006772814000044],
            [67.069608892000076, 25.006757662000041],
            [67.069127919000039, 25.006730641000047],
            [67.069067001000064, 25.006727219000027],
            [67.06834602400005, 25.006689072000029],
            [67.067851301000076, 25.006665372000043],
            [67.06770896900008, 25.006658554000069],
            [67.067370227000083, 25.006643330000031],
            [67.067102138000052, 25.006631281000068],
            [67.067086863000043, 25.006630594000058],
            [67.066998162000061, 25.006626608000033],
            [67.066841928000031, 25.006619586000056],
            [67.066690445000063, 25.006612778000033],
            [67.066579391000062, 25.006608437000068],
            [67.066303361000053, 25.006597647000035],
            [67.066290711000079, 25.00659715300003],
            [67.066251965000049, 25.006595638000078],
            [67.065582092000056, 25.006569454000044],
            [67.065519333000054, 25.006567001000064],
            [67.065111160000072, 25.006547928000032],
            [67.064981461000059, 25.00655555700007],
            [67.064808000000028, 25.006579000000045],
            [67.064793000000066, 25.006619000000057],
            [67.064780000000042, 25.006645000000049],
            [67.064749000000063, 25.006695000000036],
            [67.064707000000055, 25.006745000000024],
            [67.064691000000039, 25.006760000000043],
            [67.064658000000065, 25.006786000000034],
            [67.064657637000039, 25.006786223000063],
            [67.064601000000039, 25.006821000000059],
            [67.064541000000077, 25.006846000000053],
            [67.064477000000068, 25.006861000000072],
            [67.064412000000061, 25.006867000000057],
            [67.064347000000055, 25.006861000000072],
            [67.064283000000046, 25.006846000000053],
            [67.064223000000084, 25.006821000000059],
            [67.064167000000054, 25.006787000000031],
            [67.064133000000083, 25.006760000000043],
            [67.064119500000061, 25.006747344000075],
            [67.064117000000067, 25.006745000000024],
            [67.064075000000059, 25.006695000000036],
            [67.064053000000058, 25.006661000000065],
            [67.063996000000031, 25.006616000000065],
            [67.063936000000069, 25.006576000000052],
            [67.06387200000006, 25.00654000000003],
            [67.063806000000056, 25.006510000000048],
            [67.063738000000058, 25.006485000000055],
            [67.063668000000064, 25.006466000000046],
            [67.063597000000073, 25.006453000000079],
            [67.063525000000084, 25.006446000000039],
            [67.063299244000063, 25.006435277000037],
            [67.062990162000062, 25.006420597000044],
            [67.062746000000061, 25.006409000000076],
            [67.062119000000052, 25.006380000000036],
            [67.061093484000082, 25.006325247000063],
            [67.060246000000063, 25.006280000000061],
            [67.059194674000082, 25.006223844000033],
            [67.059120176000079, 25.006219864000059],
            [67.05762500000003, 25.006140000000073],
            [67.05692478900005, 25.006102207000026],
            [67.056424981000077, 25.006075231000068],
            [67.054834874000051, 25.005989407000072],
            [67.053938000000073, 25.005941000000064],
            [67.053867061000062, 25.005937349000078],
            [67.053802000000076, 25.005934000000025],
            [67.052940000000035, 25.00588700000003],
            [67.052867783000067, 25.005882734000068],
            [67.052150979000032, 25.005840387000035],
            [67.052100311000061, 25.005837394000025],
            [67.05158872700008, 25.005807171000072],
            [67.051517920000038, 25.005802988000028],
            [67.051315000000045, 25.005791000000045],
            [67.051163000000031, 25.005733000000077],
            [67.051008000000081, 25.005687000000023],
            [67.050939000000028, 25.005550000000028],
            [67.051049000000035, 25.003857000000039],
            [67.051065262000066, 25.003600629000061],
            [67.051083000000062, 25.003321000000028],
            [67.051200000000051, 25.001512000000048],
            [67.051319748000083, 24.999368520000075],
            [67.051617845000067, 24.998829456000067],
            [67.051804504000074, 24.99861168700005],
            [67.052162266000039, 24.998502803000065],
            [67.052068937000058, 24.997927273000073],
            [67.052146711000034, 24.997507291000034],
            [67.052395589000071, 24.996542889000068],
            [67.052831126000058, 24.995485157000076],
            [67.053173333000075, 24.995158505000063],
            [67.053624424000077, 24.994676304000052],
            [67.053951077000079, 24.994645194000043],
            [67.05404440600006, 24.994536310000058],
            [67.054215510000063, 24.994271877000074],
            [67.054386613000077, 24.994147438000027],
            [67.054417723000029, 24.993883005000043],
            [67.054479942000057, 24.993556352000041],
            [67.054481992000035, 24.993474382000045],
            [67.054495497000062, 24.992934157000036],
            [67.054231064000078, 24.991938645000062],
            [67.054091071000073, 24.991145347000042],
            [67.053811083000028, 24.990103170000054],
            [67.05328221700006, 24.988905444000068],
            [67.053001436000045, 24.988432127000067],
            [67.052737796000031, 24.987987707000059],
            [67.052220306000038, 24.987215246000062],
            [67.05191338800006, 24.986945530000071],
            [67.051384522000035, 24.986416664000046],
            [67.050824546000058, 24.985856689000059],
            [67.050435675000074, 24.985234494000053],
            [67.050217906000057, 24.984627853000063],
            [67.050109022000072, 24.983896774000073],
            [67.050124536000055, 24.983769560000042],
            [67.050186797000038, 24.983259024000063],
            [67.050077912000063, 24.983087921000049],
            [67.050077912000063, 24.982854597000028],
            [67.050171242000033, 24.981625762000078],
            [67.050326790000042, 24.981330220000075],
            [67.050373455000056, 24.981112451000058],
            [67.050342345000047, 24.980816909000055],
            [67.050109022000072, 24.980116939000027],
            [67.050062358000048, 24.979696957000044],
            [67.050217906000057, 24.979276976000051],
            [67.05063788800004, 24.978748110000026],
            [67.050902321000081, 24.978250354000068],
            [67.050871211000072, 24.977768153000056],
            [67.050497894000046, 24.977021519000061],
            [67.050015693000034, 24.976228220000053],
            [67.04965793100007, 24.975077159000023],
            [67.049440162000053, 24.974050537000039],
            [67.049502382000071, 24.97380165900006],
            [67.049516144000052, 24.973716338000031],
            [67.049580156000047, 24.973319458000049],
            [67.049393498000029, 24.972775037000076],
            [67.049300169000048, 24.972152842000071],
            [67.04923794900003, 24.971406208000076],
            [67.04889574200007, 24.971064001000059],
            [67.048802413000033, 24.970690684000033],
            [67.048826355000074, 24.970243754000023],
            [67.048849077000057, 24.969819611000048],
            [67.048864632000061, 24.968528556000024],
            [67.049035736000064, 24.968233013000031],
            [67.04889574200007, 24.967346386000031],
            [67.048849077000057, 24.966615306000051],
            [67.049222394000083, 24.965930892000074],
            [67.050280126000075, 24.965806453000027],
            [67.051228973000036, 24.965899782000065],
            [67.051773394000065, 24.96600866600005],
            [67.051882278000051, 24.96613310500004],
            [67.051975607000031, 24.966226434000077],
            [67.052504473000056, 24.965915337000069],
            [67.052846680000073, 24.965635349000024],
            [67.053204443000084, 24.96549535500003],
            [67.053532546000042, 24.965526563000026],
            [67.053555000000074, 24.965193000000056],
            [67.053555000000074, 24.964861270000029],
            [67.051881968000032, 24.964832590000071],
            [67.048407376000057, 24.964888094000059],
            [67.047907834000057, 24.965265526000053],
            [67.04731948400007, 24.965543049000075],
            [67.047175172000038, 24.965543049000075],
            [67.047108566000077, 24.965432040000053],
            [67.047053062000032, 24.96535433400004],
            [67.046708933000048, 24.965309930000046],
            [67.046205699000041, 24.965214414000059],
            [67.045111147000057, 24.965223931000025],
            [67.044405406000067, 24.965194526000062],
            [67.043740577000051, 24.96516682400005],
            [67.043687352000063, 24.963971866000065],
            [67.043465333000029, 24.964055123000037],
            [67.04328494300006, 24.964249389000031],
            [67.043076800000051, 24.964540789000068],
            [67.042702144000032, 24.965040331000068],
            [67.042049964000057, 24.966011662000028],
            [67.041488823000066, 24.967149961000075],
            [67.041311281000048, 24.967585340000028],
            [67.040359497000054, 24.969465115000048],
            [67.04006206400004, 24.969917212000041],
            [67.039859810000053, 24.97005998000003],
            [67.03964565900003, 24.970048083000052],
            [67.039526686000045, 24.969952904000024],
            [67.039467199000057, 24.969857726000043],
            [67.039443404000053, 24.969584088000033],
            [67.039443404000053, 24.969358039000042],
            [67.039312534000032, 24.969084401000032],
            [67.038967512000056, 24.968549022000047],
            [67.038146598000083, 24.967276011000024],
            [67.036766511000053, 24.965003625000065],
            [67.035731445000067, 24.963338003000047],
            [67.034993812000039, 24.962195861000055],
            [67.034351358000038, 24.961315461000027],
            [67.033720801000072, 24.96069680100004],
            [67.032423995000045, 24.959352405000061],
            [67.028902392000077, 24.956175825000059],
            [67.028497884000046, 24.955735625000045],
            [67.028045786000064, 24.955283527000063],
            [67.027355743000044, 24.954343640000047],
            [67.026867953000078, 24.953665494000063],
            [67.026058936000084, 24.952523352000071],
            [67.025362041000051, 24.951290781000068],
            [67.024849035000045, 24.950615114000072],
            [67.024662640000031, 24.950071894000075],
            [67.024156409000057, 24.95040997600006],
            [67.023385464000057, 24.950938216000054],
            [67.022728733000065, 24.951295135000066],
            [67.022043448000034, 24.951709161000053],
            [67.021715082000071, 24.951994697000032],
            [67.021329610000066, 24.952223125000046],
            [67.021108034000065, 24.952374858000042],
            [67.020658830000059, 24.952516712000033],
            [67.020335718000069, 24.952532474000066],
            [67.019736779000084, 24.952469428000029],
            [67.019161483000062, 24.952374858000042],
            [67.01852314000007, 24.95225664700007],
            [67.018136982000044, 24.952138435000052],
            [67.017900559000054, 24.952020223000034],
            [67.017664136000064, 24.951838966000025],
            [67.017530162000071, 24.951768039000058],
            [67.017285858000037, 24.951720754000064],
            [67.016946985000061, 24.951704992000032],
            [67.016679039000053, 24.951657708000027],
            [67.016395331000069, 24.951547377000054],
            [67.016166788000078, 24.951444927000068],
            [67.016001292000055, 24.951342477000026],
            [67.01572546500006, 24.951303073000076],
            [67.015339307000033, 24.951334596000038],
            [67.014637918000062, 24.95147645000003],
            [67.013763152000081, 24.951665589000072],
            [67.013179975000071, 24.951768039000058],
            [67.012935671000037, 24.951838966000025],
            [67.012565274000053, 24.951925654000036],
            [67.012242163000053, 24.951949296000066],
            [67.011974216000056, 24.951949296000066],
            [67.011792958000058, 24.951980820000074],
            [67.01152501200005, 24.952067508000027],
            [67.011556535000068, 24.952185720000045],
            [67.011588058000029, 24.952382739000029],
            [67.011564416000056, 24.952556116000039],
            [67.011406800000032, 24.952697970000031],
            [67.011075808000044, 24.952855586000055],
            [67.010713292000048, 24.953123532000063],
            [67.01053203400005, 24.953257505000067],
            [67.010350777000042, 24.953352074000065],
            [67.010106473000064, 24.953470286000027],
            [67.009846407000055, 24.953572736000069],
            [67.009712434000051, 24.953651544000024],
            [67.009570580000059, 24.953880086000026],
            [67.009476011000061, 24.954108629000075],
            [67.009318395000037, 24.954203198000073],
            [67.009137137000039, 24.954282006000028],
            [67.008908595000037, 24.954289887000073],
            [67.008695814000077, 24.954282006000028],
            [67.008490914000049, 24.954250483000067],
            [67.008325417000037, 24.954140152000036],
            [67.008088994000047, 24.953982536000069],
            [67.00784469000007, 24.953880086000026],
            [67.007702836000078, 24.953832802000079],
            [67.007679194000048, 24.953872206000028],
            [67.007765882000058, 24.953998298000045],
            [67.007884094000076, 24.954195317000028],
            [67.007994425000049, 24.954392337000058],
            [67.007986544000062, 24.954612998000073],
            [67.007915617000037, 24.954762733000052],
            [67.007750121000072, 24.954904587000044],
            [67.007553102000031, 24.954967633000024],
            [67.00717482400006, 24.95500703700003],
            [67.006662574000075, 24.955085845000042],
            [67.006292178000081, 24.95518041400004],
            [67.00615820400003, 24.955274983000038],
            [67.006047874000046, 24.955519287000072],
            [67.00592178100004, 24.955834518000074],
            [67.005858735000061, 24.955976372000066],
            [67.005685358000051, 24.95611034600006],
            [67.005527742000083, 24.956157630000064],
            [67.005322842000055, 24.956157630000064],
            [67.005133704000059, 24.956031538000047],
            [67.004913042000055, 24.955802995000056],
            [67.004621453000084, 24.955605976000072],
            [67.004392911000082, 24.95550352600003],
            [67.004235295000058, 24.955472003000068],
            [67.004069799000035, 24.95550352600003],
            [67.003920064000056, 24.955605976000072],
            [67.00390430300007, 24.955732068000032],
            [67.003951587000074, 24.955802995000056],
            [67.00407768000008, 24.955842399000062],
            [67.004235295000058, 24.955913326000029],
            [67.004385030000037, 24.956039419000035],
            [67.004463838000049, 24.956236438000076],
            [67.004495361000068, 24.956449219000035],
            [67.004503242000055, 24.956740808000063],
            [67.00448748000008, 24.956977231000053],
            [67.004448076000074, 24.957213654000043],
            [67.004353507000076, 24.957331866000061],
            [67.004219534000072, 24.957434316000047],
            [67.004101322000054, 24.957481600000051],
            [67.003896422000082, 24.957528885000045],
            [67.00373092600006, 24.957465839000065],
            [67.003526026000031, 24.957331866000061],
            [67.003431456000044, 24.957268820000024],
            [67.003376291000052, 24.957347627000047],
            [67.003376291000052, 24.957473720000053],
            [67.003399933000082, 24.957639216000075],
            [67.003423576000046, 24.957781070000067],
            [67.003415695000058, 24.957946566000032],
            [67.003336887000046, 24.958119943000042],
            [67.00312410600003, 24.95823815500006],
            [67.002864041000066, 24.958403651000026],
            [67.00265126000005, 24.958553386000062],
            [67.002406956000073, 24.958742524000058],
            [67.002209936000042, 24.958963186000062],
            [67.00213112800003, 24.958986828000036],
            [67.002091725000071, 24.959081398000023],
            [67.002146890000063, 24.959160205000046],
            [67.002280863000067, 24.95931782100007],
            [67.002288744000055, 24.959404509000024],
            [67.002280863000067, 24.959546363000072],
            [67.002178413000081, 24.959633052000072],
            [67.002028678000045, 24.959672456000078],
            [67.001847421000036, 24.959751264000033],
            [67.001737090000063, 24.959845833000031],
            [67.001626759000033, 24.959948283000074],
            [67.001579474000039, 24.960074375000033],
            [67.001421859000061, 24.960098018000053],
            [67.001185435000082, 24.960168945000078],
            [67.001193316000069, 24.960263514000076],
            [67.001240601000063, 24.960334441000043],
            [67.001232720000075, 24.960444772000073],
            [67.001106628000059, 24.960555103000047],
            [67.001019939000059, 24.960657553000033],
            [67.000885966000055, 24.960712718000025],
            [67.000759874000039, 24.960838811000031],
            [67.000688947000071, 24.960925499000041],
            [67.000594377000084, 24.961004307000053],
            [67.000531331000047, 24.961114638000026],
            [67.000421000000074, 24.961280134000049],
            [67.000223981000033, 24.961461392000047],
            [67.000090008000029, 24.961555961000045],
            [67.000090008000029, 24.961626888000069],
            [67.000066365000066, 24.96183178800004],
            [66.999995438000042, 24.961902715000065],
            [66.999892988000056, 24.961957881000046],
            [66.99976689600004, 24.961981523000077],
            [66.999695969000072, 24.962052450000044],
            [66.999688088000028, 24.962170661000073],
            [66.999695969000072, 24.962288873000034],
            [66.999656565000066, 24.962383442000032],
            [66.999561996000068, 24.962454369000056],
            [66.99946742700007, 24.962572581000074],
            [66.99938073800007, 24.962714435000066],
            [66.999341334000064, 24.962832647000027],
            [66.999262526000052, 24.962942977000068],
            [66.99914431500008, 24.962974501000076],
            [66.998836965000066, 24.963013904000036],
            [66.998616303000063, 24.96299814300005],
            [66.998474449000071, 24.96299814300005],
            [66.998411403000034, 24.963076951000062],
            [66.998403522000046, 24.963203043000078],
            [66.998435045000065, 24.963352778000058],
            [66.998537495000051, 24.96358132000006],
            [66.998647826000081, 24.963809863000051],
            [66.998702991000073, 24.963967478000029],
            [66.998734514000034, 24.964117213000065],
            [66.998726634000036, 24.964235424000037],
            [66.998718753000048, 24.964361517000043],
            [66.998592661000032, 24.964495490000047],
            [66.99845080700004, 24.964653106000071],
            [66.998356237000053, 24.964724033000039],
            [66.998104052000031, 24.964755556000057],
            [66.998001602000045, 24.964747675000069],
            [66.997914914000035, 24.964771317000043],
            [66.997875510000028, 24.964834363000023],
            [66.997930675000077, 24.965031383000053],
            [66.997970079000083, 24.965125952000051],
            [66.998041006000051, 24.965291448000073],
            [66.998096172000032, 24.965456945000028],
            [66.998096172000032, 24.965559395000071],
            [66.998025245000065, 24.965646083000024],
            [66.997836106000079, 24.965724891000036],
            [66.997473591000073, 24.965921910000077],
            [66.997323856000037, 24.966024360000063],
            [66.997315975000049, 24.96609528700003],
            [66.997363260000043, 24.966142572000024],
            [66.997268690000055, 24.966276545000028],
            [66.997237167000037, 24.96635535300004],
            [66.997292333000075, 24.966410518000032],
            [66.997402664000049, 24.966402638000034],
            [66.997544517000051, 24.966449922000038],
            [66.997686371000043, 24.966505088000076],
            [66.997836106000079, 24.966631180000036],
            [66.997891272000061, 24.96678879600006],
            [66.997883391000073, 24.966946411000038],
            [66.997796702000073, 24.967088265000029],
            [66.997662729000069, 24.967222238000033],
            [66.997544517000051, 24.967277404000072],
            [66.997347498000067, 24.967340450000052],
            [66.997252929000069, 24.967356211000038],
            [66.997252929000069, 24.967505946000074],
            [66.997331737000081, 24.967655681000053],
            [66.997465710000029, 24.967813296000031],
            [66.997552398000039, 24.967915746000074],
            [66.997583921000057, 24.96801819600006],
            [66.997536637000053, 24.968160050000051],
            [66.997418425000035, 24.968246739000051],
            [66.99733961700008, 24.968317666000075],
            [66.997323856000037, 24.968396474000031],
            [66.997371140000041, 24.968435877000047],
            [66.997473591000073, 24.968538328000022],
            [66.997489352000059, 24.968656539000051],
            [66.997457829000041, 24.968782632000057],
            [66.997379021000029, 24.968853559000024],
            [66.997331737000081, 24.968948128000022],
            [66.997371140000041, 24.969011174000059],
            [66.997457829000041, 24.969034816000033],
            [66.997544517000051, 24.969113624000045],
            [66.997560279000083, 24.969184551000069],
            [66.997520875000077, 24.969271240000069],
            [66.997410544000047, 24.969373690000054],
            [66.997355379000055, 24.969468259000053],
            [66.997386902000073, 24.969641636000063],
            [66.997520875000077, 24.969767728000079],
            [66.997534485000074, 24.969905976000064],
            [66.99749582700008, 24.970021951000035],
            [66.997431396000081, 24.970170142000029],
            [66.997418510000045, 24.970324776000041],
            [66.997482941000044, 24.97044719400003],
            [66.997586030000036, 24.970518068000047],
            [66.997644018000074, 24.970582499000045],
            [66.997663347000071, 24.970743576000075],
            [66.997586030000036, 24.970917539000027],
            [66.997502270000041, 24.970988413000043],
            [66.997502270000041, 24.971104388000072],
            [66.997502270000041, 24.971271908000062],
            [66.997450725000078, 24.971432985000035],
            [66.997347636000029, 24.971536074000028],
            [66.997263876000034, 24.971645606000038],
            [66.997257433000073, 24.971774468000035],
            [66.997341193000068, 24.971832455000026],
            [66.997450725000078, 24.971851785000069],
            [66.997528042000056, 24.971954874000062],
            [66.997566701000039, 24.972051520000036],
            [66.997573144000057, 24.972180381000044],
            [66.99755381500006, 24.972277027000075],
            [66.997508713000059, 24.972412332000033],
            [66.997521599000038, 24.97254119400003],
            [66.997573144000057, 24.972644283000022],
            [66.99762468800003, 24.97277314400003],
            [66.997650461000035, 24.972927778000042],
            [66.99762468800003, 24.973082412000053],
            [66.99755381500006, 24.973198387000025],
            [66.997437839000042, 24.973269261000041],
            [66.99727676200007, 24.973295033000056],
            [66.997167230000059, 24.973320806000061],
            [66.997167230000059, 24.97338523600007],
            [66.997212332000061, 24.973494769000069],
            [66.997238104000076, 24.973572085000058],
            [66.997205889000043, 24.973668732000078],
            [66.997135015000083, 24.973700947000054],
            [66.99704481200007, 24.973771821000071],
            [66.997077027000046, 24.973829808000062],
            [66.997186559000056, 24.973887796000042],
            [66.997308978000035, 24.973952227000041],
            [66.99744428200006, 24.974035987000036],
            [66.997534485000074, 24.974106861000053],
            [66.99755381500006, 24.974274381000043],
            [66.997573144000057, 24.974487002000046],
            [66.997592473000054, 24.974622307000061],
            [66.997547372000042, 24.974764054000048],
            [66.997489384000062, 24.974873586000058],
            [66.997450725000078, 24.974996005000037],
            [66.997412067000027, 24.975260171000059],
            [66.997373409000033, 24.975685414000054],
            [66.997321864000071, 24.975833605000048],
            [66.997257433000073, 24.975981795000052],
            [66.997250990000055, 24.97609777100007],
            [66.997328307000032, 24.976181531000066],
            [66.997450725000078, 24.976271734000079],
            [66.99755381500006, 24.976458583000067],
            [66.997579587000075, 24.976587444000074],
            [66.997598916000072, 24.976787180000031],
            [66.997663347000071, 24.976948256000071],
            [66.997714892000033, 24.97707067500005],
            [66.997708448000083, 24.977205979000075],
            [66.997663347000071, 24.977373499000066],
            [66.997631132000038, 24.977560348000054],
            [66.997637575000056, 24.97781162800004],
            [66.997656904000053, 24.977940490000037],
            [66.997727778000069, 24.978037136000069],
            [66.997779322000042, 24.978133782000043],
            [66.997856639000076, 24.978269087000058],
            [66.997914627000057, 24.978404391000026],
            [66.997953285000051, 24.978546139000059],
            [66.997927513000036, 24.978707216000032],
            [66.997792208000078, 24.978855406000037],
            [66.997682676000068, 24.978964939000036],
            [66.997586030000036, 24.979126015000077],
            [66.997586030000036, 24.979254877000074],
            [66.997637575000056, 24.979428840000026],
            [66.997702005000065, 24.979538372000036],
            [66.99778576500006, 24.979731664000042],
            [66.997869525000056, 24.979821868000045],
            [66.997914627000057, 24.979924957000037],
            [66.997927513000036, 24.980066704000023],
            [66.997946842000033, 24.980272883000055],
            [66.997946842000033, 24.980408187000023],
            [66.997882411000035, 24.980517720000023],
            [66.997805095000047, 24.980627252000033],
            [66.997695562000047, 24.980723898000065],
            [66.99762468800003, 24.980820544000039],
            [66.997579587000075, 24.980981621000069],
            [66.997566701000039, 24.981239344000073],
            [66.997592473000054, 24.981374648000042],
            [66.997650461000035, 24.98143907900004],
            [66.997721335000051, 24.981497067000078],
            [66.997772879000081, 24.981613042000049],
            [66.997798651000039, 24.981748347000064],
            [66.997779322000042, 24.98199962700005],
            [66.997792208000078, 24.982173590000059],
            [66.997837310000079, 24.982347553000068],
            [66.997888855000042, 24.982540845000074],
            [66.99789529800006, 24.982753466000077],
            [66.997901741000078, 24.982908100000031],
            [66.99789529800006, 24.983036962000028],
            [66.997856639000076, 24.983165823000036],
            [66.997759993000045, 24.983281798000064],
            [66.997618245000069, 24.983417103000079],
            [66.997482941000044, 24.983520192000071],
            [66.997354079000047, 24.983629724000025],
            [66.997250990000055, 24.983726371000046],
            [66.997244547000037, 24.983823017000077],
            [66.997244547000037, 24.983913220000034],
            [66.997141458000044, 24.983938992000049],
            [66.996825747000059, 24.983977650000043],
            [66.996026806000032, 24.984016309000026],
            [66.994725305000031, 24.984080740000024],
            [66.993623539000055, 24.984151613000051],
            [66.990640585000051, 24.984371355000064],
            [66.990692960000047, 24.989146240000025],
            [66.984783277000076, 24.988412985000025],
            [66.985219738000069, 24.986990122000066],
            [66.980881314000044, 24.986317972000052],
            [66.981082086000072, 24.984388814000056],
            [66.981326504000037, 24.982433468000067],
            [66.979598311000075, 24.982366223000042],
            [66.979394936000062, 24.982358310000052],
            [66.977028545000053, 24.982311259000028],
            [66.977065094000068, 24.980063525000048],
            [66.969243710000057, 24.980876729000045],
            [66.968295879000038, 24.975887145000058],
            [66.967504331000043, 24.971655512000041],
            [66.967333010000061, 24.970856013000059],
            [66.967589992000057, 24.969171355000071],
            [66.968055278000065, 24.965759405000028],
            [66.967015386000071, 24.965743157000077],
            [66.966771661000053, 24.965832523000074],
            [66.966536060000067, 24.965978757000073],
            [66.966146101000049, 24.966092496000044],
            [66.965520540000057, 24.966173737000076],
            [66.965081836000081, 24.966254979000041],
            [66.964602510000077, 24.966238730000043],
            [66.963960702000065, 24.966059999000038],
            [66.963206451000076, 24.965858775000072],
            [66.962819324000066, 24.968707745000074],
            [66.962659861000077, 24.96979409000005],
            [66.962619995000068, 24.970372144000066],
            [66.96246053200008, 24.970750870000074],
            [66.962404066000033, 24.971051069000055],
            [66.963198050000074, 24.972265773000061],
            [66.963357513000062, 24.972594666000077],
            [66.963337580000029, 24.972803962000057],
            [66.963327614000036, 24.972933526000077],
            [66.963118318000056, 24.973182687000076],
            [66.962719660000062, 24.97370094300004],
            [66.962350901000036, 24.974119535000057],
            [66.96212167200008, 24.974607892000051],
            [66.961573516000044, 24.975534773000049],
            [66.960935663000043, 24.976501520000056],
            [66.960786166000048, 24.976571285000034],
            [66.960028715000078, 24.976740715000062],
            [66.959341029000029, 24.976989876000061],
            [66.958703175000039, 24.977278904000059],
            [66.95768659600003, 24.977956623000068],
            [66.953979071000049, 24.975823800000057],
            [66.951078830000029, 24.974418529000047],
            [66.949902787000042, 24.976292224000076],
            [66.94683311600005, 24.976062995000063],
            [66.945467711000049, 24.974548093000067],
            [66.941610689000072, 24.974109568000074],
            [66.94002602200004, 24.973282352000069],
            [66.939228705000062, 24.972963425000046],
            [66.938640683000074, 24.972634532000029],
            [66.937315114000057, 24.971824708000042],
            [66.931681806000029, 24.968584022000073],
            [66.93034626900004, 24.972049725000034],
            [66.930740400000047, 24.972251737000079],
            [66.931614953000064, 24.972834773000045],
            [66.931942911000078, 24.973527128000057],
            [66.932234429000061, 24.974401682000064],
            [66.932635266000034, 24.975421995000033],
            [66.933327621000046, 24.976005031000057],
            [66.933692019000034, 24.976515187000075],
            [66.93365557900006, 24.985187847000077],
            [66.933546260000071, 24.989086900000075],
            [66.93365557900006, 24.992111399000066],
            [66.933181862000083, 24.993969826000068],
            [66.932489507000071, 24.997686680000072],
            [66.932052230000068, 24.999144269000055],
            [66.932125110000072, 24.999873064000042],
            [66.932453068000029, 25.001330654000071],
            [66.933108983000068, 25.004938189000029],
            [66.933910657000069, 25.008072007000067],
            [66.934930970000039, 25.01295493300006],
            [66.93573264500003, 25.016380269000024],
            [66.936206361000075, 25.018311576000031],
            [66.935805524000045, 25.019113250000032],
            [66.935222488000079, 25.020206442000074],
            [66.934566573000041, 25.020898798000076],
            [66.933728459000065, 25.021481834000042],
            [66.933145423000042, 25.021700472000077],
            [66.932307309000066, 25.022575026000027],
            [66.931685009000034, 25.023404624000023],
            [66.939890910000031, 25.029649960000029],
            [66.941936482000074, 25.031624995000072],
            [66.945698623000055, 25.035238593000031],
            [66.955903196000065, 25.045040251000046],
            [66.966007110000078, 25.054726648000042],
            [66.96601258000004, 25.054731891000074],
            [66.966016473000082, 25.054735624000045],
            [66.969890910000061, 25.058449960000075],
            [66.96990674400007, 25.058468881000067],
            [66.970066298000063, 25.058659533000025],
            [66.982364158000053, 25.071516044000077],
            [66.985752866000041, 25.075058689000059],
            [66.989731922000033, 25.079224556000042],
            [66.995910645000038, 25.085693359000061],
            [67.006208317000073, 25.088903810000033],
            [67.007556426000065, 25.093074525000077],
            [67.009390910000036, 25.098749960000077],
            [67.011286220000045, 25.10843710000006],
            [67.012104000000079, 25.112526000000059],
            [67.011257945000068, 25.115487103000078],
            [67.010056860000077, 25.119690774000048],
            [67.007390910000083, 25.129049960000032],
            [67.008496229000059, 25.146309939000048],
            [67.008690910000041, 25.149349960000052],
            [67.009390910000036, 25.159849960000031],
            [67.029466787000047, 25.193809155000054],
            [67.032155676000059, 25.197051656000042],
            [67.04289091000004, 25.209949960000074],
            [67.052630400000055, 25.21546187000007],
            [67.060569034000082, 25.219954613000027],
            [67.061279297000056, 25.220275879000042],
            [67.064365385000031, 25.222094467000034],
            [67.064581000000032, 25.22221600000006],
            [67.064610027000072, 25.222238630000049],
            [67.064697266000053, 25.222290039000029],
            [67.087890625000057, 25.240478516000053],
            [67.115112305000082, 25.270874024000022],
            [67.123158409000041, 25.283986194000079],
            [67.130032494000034, 25.295260068000061],
            [67.130077740000047, 25.295262141000023],
            [67.130142333000038, 25.295367403000057],
            [67.13009865600003, 25.295368577000033],
            [67.134513994000031, 25.302609973000074],
            [67.134814000000063, 25.303102000000024],
            [67.13681401100007, 25.305635212000027],
            [67.140686071000061, 25.310539559000063],
            [67.140733000000068, 25.310599000000025],
            [67.140686071000061, 25.311107247000052],
            [67.140627963000043, 25.311736559000053],
            [67.140428016000044, 25.313902001000031],
            [67.140423684000041, 25.313948843000048],
            [67.13922019000006, 25.326983601000052],
            [67.138365000000078, 25.336246000000074],
            [67.138789779000035, 25.338218097000038],
            [67.143889000000058, 25.361892000000068],
            [67.15335900000008, 25.371362000000033],
            [67.163991151000062, 25.39105139000003],
            [67.164012000000071, 25.391090000000077],
            [67.165347819000033, 25.399106606000032],
            [67.165590910000049, 25.400549960000035],
            [67.165589493000084, 25.400556957000049],
            [67.165590000000066, 25.400560000000041],
            [67.162434000000076, 25.41634300000004],
            [67.162268932000075, 25.431512552000072],
            [67.16203900000005, 25.452643000000023],
            [67.174665000000061, 25.462507000000073],
            [67.186897000000044, 25.469609000000048],
            [67.193496070000037, 25.484606472000053],
            [67.195590910000078, 25.489349960000027],
            [67.199055056000077, 25.492904084000031],
            [67.202757432000055, 25.496701257000041],
            [67.210966000000042, 25.505120000000034],
            [67.213127157000031, 25.506586527000024],
            [67.222014000000058, 25.512617000000034],
            [67.229657403000033, 25.51392770800004],
            [67.235790910000048, 25.514949960000024],
            [67.248490910000044, 25.526449960000036],
            [67.254390910000041, 25.544149960000027],
            [67.257216274000029, 25.55581210400004],
            [67.257216285000084, 25.55581214800003],
            [67.259103000000039, 25.56351600000005],
            [67.263833458000079, 25.57415977900007],
            [67.263838000000078, 25.574170000000038],
            [67.263990792000072, 25.574196087000075],
            [67.272717162000049, 25.575685995000072],
            [67.280015000000049, 25.576932000000056],
            [67.286796983000045, 25.580322583000054],
            [67.296587000000045, 25.585217000000057],
            [67.302762244000064, 25.595617802000049],
            [67.30409091000007, 25.597849960000076],
            [67.314490910000075, 25.621149960000025],
            [67.31486112400006, 25.621984910000037],
            [67.315711646000068, 25.623890092000067],
            [67.323812000000032, 25.642035000000078],
            [67.323872030000075, 25.642307379000044],
            [67.323934092000059, 25.642588980000028],
            [67.323934189000056, 25.642588980000028],
            [67.381054000000063, 25.642589000000044],
            [67.381066356000076, 25.642549874000053],
            [67.388851000000045, 25.617899000000023],
            [67.394875213000034, 25.597973525000043],
            [67.397298000000035, 25.589960000000076],
            [67.392100000000028, 25.56397000000004],
            [67.393382919000032, 25.555149996000068],
            [67.394480658000077, 25.547603091000042],
            [67.394512191000047, 25.547386303000053],
            [67.397298000000035, 25.528234000000054],
            [67.399897000000067, 25.492498000000069],
            [67.409859922000066, 25.46759133300003],
            [67.415482455000074, 25.453535362000025],
            [67.415482491000034, 25.453535272000067],
            [67.415491000000031, 25.453514000000041],
            [67.415482491000034, 25.453499346000058],
            [67.407223779000049, 25.439276793000033],
            [67.403795000000059, 25.433372000000077],
            [67.40038626300003, 25.424850156000048],
            [67.393399000000045, 25.407382000000041],
            [67.37715600000007, 25.357352000000049],
            [67.36938735800004, 25.333449653000059],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "158",
      properties: { name: "Korangi", count: 912 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.142673552000076, 24.894856490000052],
            [67.143004000000076, 24.893606000000034],
            [67.14302500000008, 24.893609000000026],
            [67.143035000000054, 24.893608000000029],
            [67.143046000000083, 24.893606000000034],
            [67.143054000000063, 24.893603000000041],
            [67.143061700000032, 24.893598100000077],
            [67.143075000000067, 24.893588000000022],
            [67.143081000000052, 24.89358100000004],
            [67.143086000000039, 24.89357300000006],
            [67.143089000000032, 24.893567000000075],
            [67.143091000000084, 24.893562000000031],
            [67.143093000000079, 24.893550000000062],
            [67.143094000000076, 24.89354000000003],
            [67.143092245000048, 24.893525227000055],
            [67.143087000000037, 24.893509000000051],
            [67.14307892000005, 24.893497660000037],
            [67.143075000000067, 24.893481000000065],
            [67.143073000000072, 24.893464000000051],
            [67.143072000000075, 24.893451000000027],
            [67.143072000000075, 24.893432000000075],
            [67.14307400000007, 24.893416000000059],
            [67.143079963000048, 24.893391216000055],
            [67.143086000000039, 24.893371000000059],
            [67.143248520000043, 24.892770247000044],
            [67.143296946000078, 24.892591243000027],
            [67.143455000000074, 24.892007000000035],
            [67.143623275000039, 24.891403833000027],
            [67.14376255500008, 24.890904595000052],
            [67.143763000000035, 24.890903000000037],
            [67.143878000000029, 24.89047800000003],
            [67.143983254000034, 24.890078532000075],
            [67.14400500000005, 24.889996000000053],
            [67.14428907100006, 24.888903834000075],
            [67.144311547000029, 24.888817418000031],
            [67.144503557000064, 24.888079198000071],
            [67.144533000000081, 24.887966000000063],
            [67.144806000000074, 24.886918000000037],
            [67.145603158000029, 24.886927209000078],
            [67.145703261000051, 24.886928366000063],
            [67.146718253000074, 24.886940092000032],
            [67.14859050900003, 24.886961722000024],
            [67.148785037000039, 24.886963969000078],
            [67.150023621000059, 24.886978278000072],
            [67.150072303000059, 24.886976700000048],
            [67.150151066000035, 24.886974147000046],
            [67.150206621000052, 24.886972347000039],
            [67.151788664000037, 24.886979066000038],
            [67.152290105000077, 24.886981196000079],
            [67.152690310000082, 24.886987264000027],
            [67.152938729000084, 24.886991030000047],
            [67.154666587000065, 24.887005002000024],
            [67.155161000000078, 24.887009000000035],
            [67.155760000000043, 24.886994000000072],
            [67.155896000000041, 24.886989000000028],
            [67.156098888000031, 24.886984067000071],
            [67.156490015000031, 24.886974558000077],
            [67.157312602000047, 24.88695455900006],
            [67.157803177000062, 24.886947511000074],
            [67.157977995000067, 24.886945000000026],
            [67.158094990000052, 24.886947000000077],
            [67.158914000000038, 24.886942000000033],
            [67.159409442000083, 24.886949432000051],
            [67.159514000000058, 24.886951000000067],
            [67.161600272000044, 24.887015929000029],
            [67.161956000000032, 24.887027000000046],
            [67.163270000000068, 24.887004000000047],
            [67.163720000000069, 24.886990000000026],
            [67.163998000000049, 24.886953000000062],
            [67.164164000000028, 24.886920000000032],
            [67.164405000000045, 24.886840000000063],
            [67.164508000000069, 24.886805000000038],
            [67.164970000000039, 24.886614000000066],
            [67.165209000000061, 24.886510000000044],
            [67.166286550000052, 24.886053437000044],
            [67.166618000000028, 24.885913000000073],
            [67.167057000000057, 24.885730000000024],
            [67.167786000000035, 24.885622000000069],
            [67.170823746000053, 24.885232770000073],
            [67.172297000000071, 24.88504400000005],
            [67.173871082000062, 24.884836340000049],
            [67.175041000000078, 24.884682000000055],
            [67.175383396000029, 24.884636016000059],
            [67.175744000000066, 24.884582000000023],
            [67.176240953000047, 24.884488220000037],
            [67.176364000000035, 24.884465000000034],
            [67.176491497000029, 24.884434498000076],
            [67.176573000000076, 24.884415000000047],
            [67.176658000000032, 24.884391000000051],
            [67.176777000000072, 24.884352000000035],
            [67.176955000000078, 24.884282000000042],
            [67.177101099000083, 24.884213663000025],
            [67.177220768000041, 24.884157689000062],
            [67.177265000000034, 24.884137000000067],
            [67.178092427000081, 24.883773003000044],
            [67.178902000000051, 24.883436000000074],
            [67.179243000000042, 24.883317000000034],
            [67.179657000000077, 24.883186000000023],
            [67.17997900000006, 24.883077000000071],
            [67.180378703000031, 24.882933078000065],
            [67.180540000000065, 24.88287500000007],
            [67.181066000000044, 24.882678000000055],
            [67.181623000000059, 24.882469000000071],
            [67.181976000000077, 24.882323000000042],
            [67.182162000000062, 24.882238000000029],
            [67.182288000000028, 24.882179000000065],
            [67.183339000000046, 24.881654000000026],
            [67.183765073000075, 24.881438840000044],
            [67.183941000000061, 24.881350000000054],
            [67.184567811000079, 24.881017058000054],
            [67.18471100000005, 24.880941000000064],
            [67.185065000000066, 24.880747000000042],
            [67.186218000000054, 24.880094000000042],
            [67.186791000000028, 24.879743000000076],
            [67.187532000000033, 24.879278000000056],
            [67.18790000000007, 24.879061000000036],
            [67.188131000000055, 24.878936000000067],
            [67.188358000000051, 24.878819000000078],
            [67.189023000000077, 24.878522000000032],
            [67.189228669000045, 24.878411404000076],
            [67.189659000000063, 24.878180000000043],
            [67.190420000000074, 24.877706000000046],
            [67.190785000000062, 24.877446000000077],
            [67.191131000000041, 24.877183000000059],
            [67.191236204000063, 24.877095274000055],
            [67.191444000000047, 24.876922000000036],
            [67.191678275000072, 24.876719038000033],
            [67.192267000000072, 24.876209000000074],
            [67.192513474000066, 24.87599874700004],
            [67.192825000000028, 24.875733000000025],
            [67.193370000000073, 24.875275000000045],
            [67.193729000000076, 24.874987000000033],
            [67.194467624000083, 24.874385855000071],
            [67.195142000000033, 24.873837000000037],
            [67.19629800000007, 24.872908000000052],
            [67.196544579000033, 24.872711117000051],
            [67.197114087000045, 24.872256390000075],
            [67.197335000000066, 24.872080000000039],
            [67.198415000000068, 24.871188000000075],
            [67.198960020000072, 24.870730216000027],
            [67.199447841000051, 24.870320476000074],
            [67.199615592000043, 24.870179576000055],
            [67.199727000000053, 24.870086000000072],
            [67.200358000000051, 24.869581000000039],
            [67.200673000000052, 24.86928400000005],
            [67.201117000000067, 24.869004000000075],
            [67.201756000000046, 24.868595000000028],
            [67.201984000000039, 24.868440000000078],
            [67.202299000000039, 24.868203000000051],
            [67.202358319000041, 24.868143882000027],
            [67.202594000000033, 24.867909000000054],
            [67.202738000000068, 24.867747000000065],
            [67.202846000000079, 24.867598000000044],
            [67.203173000000049, 24.867110000000025],
            [67.203388000000075, 24.866795000000025],
            [67.20343538700007, 24.866725314000064],
            [67.20343900000006, 24.866720000000043],
            [67.203604000000041, 24.866466000000059],
            [67.203684654000028, 24.866342115000066],
            [67.203729000000067, 24.866274000000033],
            [67.204836810000074, 24.864550813000051],
            [67.205288092000046, 24.863848847000042],
            [67.204671424000082, 24.863569464000022],
            [67.203330678000043, 24.863048840000033],
            [67.202850482000031, 24.86279214700005],
            [67.203342773000031, 24.861972691000062],
            [67.204532503000053, 24.860188095000069],
            [67.205341520000047, 24.858879391000073],
            [67.206436072000031, 24.857451715000025],
            [67.207221294000078, 24.856595109000068],
            [67.20850620300007, 24.855286405000072],
            [67.209315220000065, 24.854453594000063],
            [67.210933254000054, 24.852978328000063],
            [67.212170573000037, 24.852002749000064],
            [67.213550661000056, 24.851098554000032],
            [67.21478798000004, 24.850313332000042],
            [67.216168068000059, 24.849551904000066],
            [67.217643334000059, 24.848766682000075],
            [67.219570697000051, 24.847743514000058],
            [67.221045963000051, 24.847220032000052],
            [67.222687791000055, 24.846672756000032],
            [67.224091673000032, 24.846315837000077],
            [67.225638323000055, 24.846030302000031],
            [67.226780464000058, 24.845816150000076],
            [67.226709080000035, 24.845245080000041],
            [67.22644733900006, 24.843508073000066],
            [67.226090420000048, 24.841961424000033],
            [67.22590006300004, 24.84086687100006],
            [67.225733501000036, 24.839534373000049],
            [67.225527593000038, 24.838285065000036],
            [67.22551935000007, 24.837844956000026],
            [67.226066626000033, 24.837797367000064],
            [67.227779837000071, 24.837797367000064],
            [67.229326487000037, 24.83786875100003],
            [67.231491797000047, 24.837916340000049],
            [67.233419160000039, 24.837916340000049],
            [67.235703443000034, 24.83801151800003],
            [67.237035941000045, 24.837987724000072],
            [67.238463617000036, 24.838059107000049],
            [67.240248213000029, 24.838201875000038],
            [67.241628301000048, 24.838201875000038],
            [67.241699684000082, 24.835465495000051],
            [67.241699684000082, 24.831563179000057],
            [67.241723479000029, 24.829112334000058],
            [67.241794863000052, 24.822830557000032],
            [67.241771068000048, 24.82007038200004],
            [67.241295176000051, 24.819808642000055],
            [67.240700311000069, 24.819475517000058],
            [67.23986749900007, 24.819570695000039],
            [67.238344644000051, 24.819642079000062],
            [67.234418534000042, 24.819951409000055],
            [67.232895942000027, 24.820028308000076],
            [67.232062867000081, 24.82007038200004],
            [67.228707827000051, 24.820284534000052],
            [67.224305824000055, 24.820522480000022],
            [67.218856859000084, 24.82085560400003],
            [67.215811149000046, 24.821045961000038],
            [67.213455482000029, 24.821188729000028],
            [67.212194368000041, 24.821260113000051],
            [67.211599503000059, 24.821283907000065],
            [67.210742897000046, 24.820879399000034],
            [67.210338388000082, 24.820546274000037],
            [67.209362809000083, 24.820427301000052],
            [67.20795892700005, 24.820451096000056],
            [67.207673392000061, 24.821188729000028],
            [67.20726888400003, 24.822045335000041],
            [67.207625803000042, 24.822766347000027],
            [67.208244463000028, 24.823996493000038],
            [67.208553793000078, 24.824710331000063],
            [67.208553793000078, 24.825328991000049],
            [67.208220668000081, 24.825257607000026],
            [67.20772098100008, 24.82504345600006],
            [67.203771076000066, 24.823306449000029],
            [67.20273958000007, 24.822748466000064],
            [67.20137138900003, 24.822213087000023],
            [67.200122172000079, 24.821201816000041],
            [67.197802198000034, 24.819417220000048],
            [67.195036075000075, 24.817186476000074],
            [67.194381723000049, 24.816502380000031],
            [67.193638141000065, 24.815639826000051],
            [67.192954046000068, 24.814747528000055],
            [67.19108022100005, 24.812040891000038],
            [67.19042586900008, 24.811059363000027],
            [67.189712031000056, 24.810345525000059],
            [67.189027936000059, 24.809988606000047],
            [67.187213596000049, 24.809363997000048],
            [67.186023866000028, 24.809036822000053],
            [67.179750630000058, 24.810075827000048],
            [67.176118019000057, 24.809451472000035],
            [67.172627306000038, 24.808770357000071],
            [67.17004474600003, 24.808401420000052],
            [67.16641213500003, 24.808089242000051],
            [67.164510689000053, 24.807720305000032],
            [67.164822867000055, 24.805308024000055],
            [67.16346063800006, 24.805166125000028],
            [67.161871370000028, 24.804768808000063],
            [67.159998305000045, 24.803974174000075],
            [67.158409037000069, 24.803207920000034],
            [67.15735898500003, 24.802867363000075],
            [67.156507592000082, 24.802895743000079],
            [67.154606147000038, 24.802838983000072],
            [67.15321553800004, 24.802952502000039],
            [67.152420904000053, 24.802753844000051],
            [67.151512751000041, 24.801959210000064],
            [67.151597890000062, 24.801505134000024],
            [67.151824928000053, 24.80065374000003],
            [67.150604598000029, 24.80065374000003],
            [67.148647661000041, 24.800716196000053],
            [67.147936899000058, 24.80073888000004],
            [67.146773328000052, 24.800455082000042],
            [67.142629880000072, 24.798979333000034],
            [67.141042108000079, 24.801445383000043],
            [67.141097372000047, 24.802044350000074],
            [67.142402842000081, 24.807890584000063],
            [67.142062285000065, 24.809962308000024],
            [67.13556331500007, 24.808798737000075],
            [67.134030845000041, 24.80604590400003],
            [67.132696958000054, 24.803974174000075],
            [67.131618526000068, 24.811211018000051],
            [67.130994171000054, 24.815865302000077],
            [67.115500367000038, 24.812766203000024],
            [67.114789318000078, 24.813140843000042],
            [67.108938546000047, 24.816873170000065],
            [67.108448596000073, 24.817188138000063],
            [67.107606048000036, 24.817729776000078],
            [67.104274803000067, 24.820299594000062],
            [67.102656769000077, 24.821061021000048],
            [67.095280440000067, 24.823678428000051],
            [67.092187141000068, 24.82529646200004],
            [67.089189020000049, 24.826962084000058],
            [67.089331788000038, 24.823488071000043],
            [67.088380003000054, 24.821013432000029],
            [67.087475808000079, 24.81915745200007],
            [67.08213747800005, 24.824353014000053],
            [67.080908496000063, 24.825581997000029],
            [67.079718765000052, 24.827675923000072],
            [67.080423228000029, 24.829468914000074],
            [67.080556478000062, 24.830439734000038],
            [67.08244757500006, 24.830852078000078],
            [67.083088224000051, 24.830991769000036],
            [67.083072061000053, 24.832512191000035],
            [67.089360556000031, 24.833452989000023],
            [67.091044090000082, 24.833898630000078],
            [67.091948891000072, 24.834048161000055],
            [67.091606701000046, 24.835670813000036],
            [67.091532715000028, 24.836021652000056],
            [67.091522932000032, 24.836639590000061],
            [67.091499691000081, 24.838107757000046],
            [67.091832669000041, 24.839649272000031],
            [67.091913998000052, 24.840025781000065],
            [67.091984638000042, 24.840352811000059],
            [67.093084719000046, 24.842829794000068],
            [67.094783670000083, 24.845810229000051],
            [67.096210296000038, 24.847657127000048],
            [67.096531605000052, 24.84807309100006],
            [67.09834438200005, 24.849895160000074],
            [67.102767497000059, 24.852759288000073],
            [67.105487176000054, 24.854405190000023],
            [67.109576438000033, 24.856898225000066],
            [67.111519264000037, 24.857868126000028],
            [67.111872659000028, 24.857981000000052],
            [67.113267807000057, 24.858426609000048],
            [67.118324657000073, 24.85922110000007],
            [67.123797319000062, 24.859985495000046],
            [67.125999269000033, 24.860250046000033],
            [67.12697137300006, 24.860467193000034],
            [67.126998000000071, 24.860501000000056],
            [67.127178000000072, 24.860677000000067],
            [67.127709000000038, 24.861353000000065],
            [67.128294000000039, 24.862108000000035],
            [67.128635000000031, 24.862542000000076],
            [67.128969000000041, 24.862965000000031],
            [67.128988916000083, 24.862990297000067],
            [67.12925400000006, 24.863327000000027],
            [67.129406000000074, 24.863528000000031],
            [67.129503000000057, 24.863656000000049],
            [67.129562000000078, 24.863734000000079],
            [67.129638000000057, 24.863831000000062],
            [67.129673000000082, 24.863875000000064],
            [67.129729000000054, 24.863945000000058],
            [67.129780000000039, 24.864010000000064],
            [67.129797000000053, 24.864034000000061],
            [67.12981300000007, 24.864061000000049],
            [67.129822000000047, 24.864082000000053],
            [67.129826000000037, 24.864094000000023],
            [67.129831000000081, 24.864115000000027],
            [67.129835000000071, 24.864133000000038],
            [67.129837000000066, 24.864158000000032],
            [67.129837000000066, 24.864179000000036],
            [67.129832000000079, 24.864256000000069],
            [67.129810000000077, 24.864574000000061],
            [67.129785000000084, 24.864858000000027],
            [67.129774000000054, 24.864992000000029],
            [67.129797000000053, 24.865207000000055],
            [67.129811000000075, 24.865338000000065],
            [67.129835000000071, 24.865408000000059],
            [67.129924000000074, 24.865611000000058],
            [67.129937000000041, 24.865704000000051],
            [67.12999700000006, 24.866144000000077],
            [67.130058000000076, 24.866596000000072],
            [67.130096000000037, 24.86687500000005],
            [67.130130000000065, 24.867079000000047],
            [67.130207000000041, 24.867496000000074],
            [67.130241000000069, 24.86779000000007],
            [67.130245000000059, 24.867976000000056],
            [67.130233000000032, 24.868893000000071],
            [67.130221219000077, 24.869236120000039],
            [67.130204072000083, 24.86973551400007],
            [67.128623743000048, 24.869425499000045],
            [67.127328261000059, 24.868979615000057],
            [67.127177708000033, 24.868927797000026],
            [67.127127847000054, 24.868905176000055],
            [67.126476083000057, 24.868689260000053],
            [67.125557201000049, 24.868384854000055],
            [67.125229760000082, 24.868226709000055],
            [67.124963654000055, 24.868098188000033],
            [67.12401152800004, 24.867638337000074],
            [67.123936739000044, 24.867593095000075],
            [67.122116835000043, 24.867072712000038],
            [67.120762358000036, 24.866913010000076],
            [67.120197216000065, 24.866846375000023],
            [67.119222767000053, 24.866863557000045],
            [67.118046394000032, 24.866884299000048],
            [67.116101774000072, 24.866838838000035],
            [67.113704787000074, 24.866918375000068],
            [67.113384330000031, 24.866929008000056],
            [67.110979727000029, 24.866859122000051],
            [67.111199000000056, 24.867420000000038],
            [67.111238000000071, 24.867424000000028],
            [67.111276000000032, 24.867431000000067],
            [67.11131000000006, 24.867441000000042],
            [67.111342000000036, 24.867452000000071],
            [67.111380000000054, 24.867469000000028],
            [67.111419000000069, 24.867490000000032],
            [67.111451000000045, 24.867512000000033],
            [67.111765000000048, 24.867784000000029],
            [67.111999000000083, 24.868017000000066],
            [67.112309000000039, 24.868352000000073],
            [67.113107653000043, 24.868845102000023],
            [67.114617000000067, 24.869777000000056],
            [67.11542600000007, 24.870300000000043],
            [67.116453000000035, 24.870876000000067],
            [67.118644823000068, 24.872063479000076],
            [67.119098000000065, 24.87230900000003],
            [67.119219000000044, 24.872353000000032],
            [67.11985472300006, 24.872585582000056],
            [67.122007000000053, 24.873373000000072],
            [67.122515283000041, 24.873538615000029],
            [67.123194353000031, 24.873759878000044],
            [67.124140000000068, 24.874068000000022],
            [67.124758000000043, 24.874307000000044],
            [67.124885000000063, 24.874202000000025],
            [67.125983000000076, 24.874750000000063],
            [67.126448000000039, 24.87489400000004],
            [67.127085000000079, 24.875091000000054],
            [67.127818975000082, 24.875295761000075],
            [67.127827000000082, 24.875298000000043],
            [67.128775000000076, 24.875548000000038],
            [67.129808000000082, 24.875799000000029],
            [67.130272000000048, 24.875926000000049],
            [67.130382000000054, 24.875960000000077],
            [67.130478000000039, 24.875982000000079],
            [67.130603000000065, 24.87601200000006],
            [67.130733000000077, 24.876043000000038],
            [67.130821000000083, 24.876078000000064],
            [67.130895000000066, 24.87611400000003],
            [67.130958000000078, 24.876166000000069],
            [67.131035000000054, 24.87624100000005],
            [67.13110400000005, 24.876320000000078],
            [67.131155000000035, 24.876389000000074],
            [67.131200000000035, 24.876455000000078],
            [67.131244000000038, 24.876496000000031],
            [67.131271000000083, 24.876508000000058],
            [67.131305000000054, 24.87651500000004],
            [67.131366000000071, 24.876524000000074],
            [67.13143000000008, 24.876535000000047],
            [67.131483359000072, 24.876544557000045],
            [67.131497000000081, 24.876547000000073],
            [67.13171600000004, 24.876584000000037],
            [67.131883000000073, 24.876640000000066],
            [67.13180200000005, 24.876794000000075],
            [67.131733000000054, 24.877346000000045],
            [67.131635300000028, 24.877584360000071],
            [67.131592000000069, 24.87769000000003],
            [67.131489000000045, 24.877799000000039],
            [67.131427000000031, 24.877864000000045],
            [67.131404411000062, 24.877880098000048],
            [67.13134000000008, 24.877926000000059],
            [67.131322000000068, 24.878233000000023],
            [67.131283151000048, 24.878741382000044],
            [67.131264000000044, 24.878992000000039],
            [67.131232361000059, 24.879311066000071],
            [67.13120500000008, 24.879587000000072],
            [67.131174000000044, 24.879917000000034],
            [67.131175000000042, 24.880069000000049],
            [67.131213000000059, 24.880223000000058],
            [67.131300000000067, 24.880520000000047],
            [67.131417000000056, 24.88079300000004],
            [67.131530000000055, 24.880993000000046],
            [67.13180200000005, 24.881462000000056],
            [67.131893240000068, 24.881340347000048],
            [67.132030710000038, 24.881690271000025],
            [67.132511029000057, 24.882801009000048],
            [67.132871269000077, 24.883371388000057],
            [67.132811229000083, 24.884271987000034],
            [67.13248100900006, 24.884842366000044],
            [67.131250191000049, 24.884962446000031],
            [67.129929313000048, 24.884812346000047],
            [67.129989353000042, 24.883521488000042],
            [67.12857841500005, 24.883491468000045],
            [67.128878615000076, 24.881209951000073],
            [67.129028715000061, 24.879258654000068],
            [67.126627118000044, 24.883101208000028],
            [67.125276220000046, 24.883011149000026],
            [67.123745203000055, 24.884332027000028],
            [67.123565083000074, 24.885622885000032],
            [67.124825921000081, 24.886043164000057],
            [67.124720136000064, 24.887011892000032],
            [67.125128000000075, 24.88705200000004],
            [67.125506407000046, 24.887067185000035],
            [67.125575023000067, 24.887069938000025],
            [67.12632086800005, 24.887099868000064],
            [67.126785577000078, 24.887118521000048],
            [67.127733571000078, 24.887156572000038],
            [67.128108994000058, 24.887171641000066],
            [67.128965000000051, 24.887206000000049],
            [67.130599000000075, 24.887278000000038],
            [67.130994000000044, 24.887298000000044],
            [67.131493119000083, 24.88730786800005],
            [67.13256251100006, 24.88732901000003],
            [67.133169000000066, 24.887341000000049],
            [67.133182284000043, 24.88734041400005],
            [67.133692875000065, 24.887317884000026],
            [67.13356900000008, 24.887509000000023],
            [67.133418968000058, 24.887741361000053],
            [67.134213242000044, 24.887712652000062],
            [67.135013233000052, 24.887845984000023],
            [67.135613226000032, 24.888579309000079],
            [67.135946556000079, 24.889979294000057],
            [67.136045233000061, 24.892592308000076],
            [67.136279886000068, 24.896179225000026],
            [67.14041317300007, 24.895912562000035],
            [67.142649688000063, 24.894946794000077],
            [67.142673552000076, 24.894856490000052],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "159",
      properties: { name: "Karachi East", count: 137 },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [67.153470727000069, 24.928151771000046],
            [67.152633156000036, 24.926571809000052],
            [67.152185818000078, 24.925896042000034],
            [67.152024014000062, 24.92564857800005],
            [67.151919318000068, 24.925581953000062],
            [67.151776550000079, 24.92566761300003],
            [67.150282249000043, 24.927409379000039],
            [67.149958642000058, 24.926923969000029],
            [67.149492268000074, 24.926267238000037],
            [67.149092518000032, 24.925724721000051],
            [67.14877843000005, 24.92530593500004],
            [67.148530966000067, 24.92492522200007],
            [67.14841675100007, 24.924506437000048],
            [67.148169288000076, 24.924021026000048],
            [67.147740985000041, 24.923164421000024],
            [67.147512556000038, 24.922736118000046],
            [67.14730316400005, 24.922250707000046],
            [67.147217503000036, 24.921927101000051],
            [67.147274610000068, 24.921451209000054],
            [67.147255575000031, 24.921051459000068],
            [67.14694148600006, 24.920575567000071],
            [67.146770164000031, 24.920280514000069],
            [67.146617879000075, 24.920061603000079],
            [67.146475111000029, 24.919966425000041],
            [67.146265719000041, 24.919880764000027],
            [67.146122951000052, 24.919804622000072],
            [67.145913559000064, 24.919604747000051],
            [67.145656577000068, 24.919309694000049],
            [67.145437666000078, 24.918967051000038],
            [67.145266345000039, 24.918453088000035],
            [67.145085506000044, 24.917939124000043],
            [67.144942738000054, 24.917377571000031],
            [67.144771417000072, 24.917111072000068],
            [67.144514435000076, 24.916863608000028],
            [67.144209864000061, 24.916720840000039],
            [67.143895776000079, 24.916597108000076],
            [67.143562651000082, 24.916587590000063],
            [67.143391330000043, 24.916492412000025],
            [67.143191455000078, 24.916292537000061],
            [67.143134348000046, 24.916121216000079],
            [67.143105795000054, 24.915740502000062],
            [67.143010616000083, 24.915416895000078],
            [67.14287736600005, 24.915197985000077],
            [67.142753592000076, 24.915027026000075],
            [67.142625101000078, 24.914812875000052],
            [67.142325289000041, 24.914513062000026],
            [67.142111137000029, 24.914241804000028],
            [67.142096860000038, 24.913941992000048],
            [67.142096860000038, 24.913599349000037],
            [67.141839878000042, 24.913071109000043],
            [67.141540066000061, 24.912842681000029],
            [67.141254531000072, 24.912657083000056],
            [67.140911889000051, 24.91255714600004],
            [67.140583523000032, 24.91240010100006],
            [67.140312265000034, 24.912086012000032],
            [67.140226604000077, 24.911957521000033],
            [67.140198050000038, 24.911643433000052],
            [67.140240881000068, 24.911372174000064],
            [67.140297988000043, 24.911158022000052],
            [67.14041220200005, 24.91084393400007],
            [67.14041220200005, 24.910429907000037],
            [67.140397925000059, 24.909944497000026],
            [67.140326541000036, 24.909302043000025],
            [67.14011239000007, 24.908431160000077],
            [67.139912515000049, 24.907488894000039],
            [67.139669810000044, 24.907160528000077],
            [67.139327168000079, 24.906917823000072],
            [67.139141570000049, 24.906832162000057],
            [67.138770374000046, 24.906789332000074],
            [67.138541946000032, 24.906732225000042],
            [67.13821358000007, 24.906532350000077],
            [67.138113643000054, 24.906275368000024],
            [67.138113643000054, 24.905875619000028],
            [67.138142196000047, 24.905418763000057],
            [67.13807081200008, 24.905104674000029],
            [67.137870938000049, 24.904904799000064],
            [67.137214206000067, 24.904562157000043],
            [67.136657412000034, 24.904105300000026],
            [67.136043511000082, 24.903505676000066],
            [67.135643762000029, 24.903120203000071],
            [67.135572378000063, 24.902763284000059],
            [67.13554382500007, 24.90243491800004],
            [67.135600932000045, 24.901920955000037],
            [67.135572378000063, 24.901692527000023],
            [67.135415334000072, 24.901435545000027],
            [67.135229736000042, 24.901264224000045],
            [67.135101245000044, 24.901435545000027],
            [67.134858540000039, 24.901835294000023],
            [67.13457300500005, 24.902106553000067],
            [67.134387407000077, 24.902334981000024],
            [67.134316023000054, 24.902549133000036],
            [67.13424463900003, 24.90292032800005],
            [67.134144702000071, 24.903305801000045],
            [67.134030487000075, 24.903577060000032],
            [67.133763988000055, 24.904620453000064],
            [67.133656694000081, 24.905022805000044],
            [67.133000000000038, 24.904970000000048],
            [67.132237000000032, 24.904909000000032],
            [67.131458000000066, 24.904860000000042],
            [67.129398000000037, 24.904733000000078],
            [67.128776000000073, 24.904680000000042],
            [67.125694907000081, 24.903264893000028],
            [67.125342106000062, 24.90326477900004],
            [67.124872597000035, 24.903264628000045],
            [67.124859000000072, 24.903414000000055],
            [67.124460000000056, 24.903399000000036],
            [67.124234000000058, 24.903390000000059],
            [67.12419600000004, 24.903389000000061],
            [67.123582000000056, 24.903358000000026],
            [67.123113000000046, 24.903335000000027],
            [67.122911000000045, 24.903265000000033],
            [67.122307000000035, 24.903046000000074],
            [67.121675000000039, 24.902807000000053],
            [67.12128000000007, 24.902640000000076],
            [67.120289000000071, 24.903729000000055],
            [67.120081000000027, 24.90355500000004],
            [67.11989600000004, 24.903399000000036],
            [67.11970800000006, 24.903242000000034],
            [67.119748000000072, 24.903080000000045],
            [67.119890000000055, 24.902727000000027],
            [67.119743000000028, 24.902715000000057],
            [67.119477000000074, 24.902694000000054],
            [67.119192000000055, 24.902671000000055],
            [67.118937000000074, 24.902651000000048],
            [67.118912068000043, 24.902649045000032],
            [67.11891200000008, 24.902649000000054],
            [67.118239000000074, 24.90220400000004],
            [67.117932000000053, 24.902001000000041],
            [67.117736000000036, 24.901872000000026],
            [67.117402000000084, 24.901603000000023],
            [67.11729100000008, 24.901496000000066],
            [67.116998000000081, 24.901227000000063],
            [67.116866000000073, 24.901114000000064],
            [67.116262000000063, 24.900961000000052],
            [67.116178000000048, 24.900965000000042],
            [67.116249000000039, 24.900697000000036],
            [67.116041000000052, 24.900710000000061],
            [67.115596000000039, 24.900785000000042],
            [67.114824000000056, 24.900807000000043],
            [67.114269000000036, 24.900807000000043],
            [67.11385800000005, 24.900803000000053],
            [67.113766000000055, 24.900800000000061],
            [67.11367000000007, 24.90080800000004],
            [67.113589000000047, 24.900824000000057],
            [67.11349000000007, 24.900851000000046],
            [67.113334000000066, 24.900899000000038],
            [67.112696000000028, 24.901079000000038],
            [67.112297000000069, 24.901193000000035],
            [67.112132000000031, 24.901230000000055],
            [67.111982000000069, 24.901250000000061],
            [67.111744000000044, 24.90126900000007],
            [67.111591000000033, 24.901257000000044],
            [67.111307000000068, 24.901234000000045],
            [67.111165000000028, 24.901207000000056],
            [67.110862000000054, 24.901126000000033],
            [67.110631000000069, 24.90105200000005],
            [67.110483000000045, 24.900996000000077],
            [67.110254000000054, 24.900888000000066],
            [67.109813000000031, 24.900685000000067],
            [67.10946400000006, 24.900516000000039],
            [67.109331000000054, 24.900450000000035],
            [67.109136000000035, 24.900369000000069],
            [67.108613000000048, 24.900197000000048],
            [67.108280000000036, 24.900091000000032],
            [67.107804068000064, 24.89944710900005],
            [67.107482841000035, 24.899304341000061],
            [67.106757105000042, 24.899090189000049],
            [67.106174137000039, 24.898947422000049],
            [67.105817218000084, 24.898828449000064],
            [67.105543580000074, 24.898685681000075],
            [67.105072447000055, 24.898324003000027],
            [67.10450137600003, 24.897924254000031],
            [67.104063556000028, 24.897619682000027],
            [67.103625735000037, 24.897410290000039],
            [67.102195679000033, 24.897015696000039],
            [67.100904821000029, 24.896670674000063],
            [67.10047651800005, 24.896545752000065],
            [67.099917345000051, 24.896367293000026],
            [67.099572323000075, 24.89621857700007],
            [67.099358171000063, 24.896176936000074],
            [67.099191609000059, 24.896248320000041],
            [67.099096431000078, 24.896420831000057],
            [67.098995304000027, 24.896557650000034],
            [67.098781152000072, 24.89659929000004],
            [67.098198184000069, 24.896605239000053],
            [67.097835316000044, 24.896587393000061],
            [67.097609268000042, 24.896652828000072],
            [67.097472449000065, 24.896783699000025],
            [67.097424859000057, 24.896884826000075],
            [67.097073889000058, 24.897675996000032],
            [67.096645586000079, 24.898657524000043],
            [67.096252975000084, 24.899573617000044],
            [67.096205386000065, 24.899746127000071],
            [67.09625892300005, 24.899835357000029],
            [67.096758610000052, 24.900061406000077],
            [67.097478397000032, 24.900340993000043],
            [67.098037571000077, 24.900656271000059],
            [67.098019725000029, 24.900745501000074],
            [67.097847214000069, 24.900906115000055],
            [67.097674703000052, 24.900989396000057],
            [67.096847840000066, 24.900846628000068],
            [67.096568253000044, 24.900828782000076],
            [67.096502818000033, 24.900882320000051],
            [67.096330307000073, 24.902470610000023],
            [67.096312461000082, 24.903124962000049],
            [67.096276769000042, 24.90328557600003],
            [67.095973388000061, 24.90339860000006],
            [67.095949594000047, 24.903124962000049],
            [67.095943645000034, 24.902833478000048],
            [67.095794929000078, 24.902583635000042],
            [67.095521291000068, 24.902101794000032],
            [67.095274065000069, 24.901821811000048],
            [67.095024221000074, 24.901643351000075],
            [67.094624472000078, 24.901621936000026],
            [67.094196169000043, 24.901593382000044],
            [67.094008735000045, 24.900166889000047],
            [67.093961214000046, 24.899938787000053],
            [67.093742616000043, 24.899957795000034],
            [67.093286412000054, 24.90001482100007],
            [67.093143849000057, 24.899111917000027],
            [67.093010789000061, 24.89783834800005],
            [67.092953764000072, 24.897543717000076],
            [67.092830209000056, 24.897496195000031],
            [67.092621115000043, 24.897524708000049],
            [67.092088877000037, 24.897610246000056],
            [67.091794246000063, 24.897724297000025],
            [67.091281016000039, 24.897781323000061],
            [67.09081530800006, 24.897800331000042],
            [67.090549189000058, 24.897790827000051],
            [67.090273566000064, 24.897629255000027],
            [67.089978934000044, 24.897695784000064],
            [67.089712815000041, 24.897800331000042],
            [67.089437192000048, 24.897800331000042],
            [67.088714870000047, 24.897534212000039],
            [67.088125606000062, 24.897401153000033],
            [67.08780246200007, 24.897344127000054],
            [67.087697915000035, 24.897534212000039],
            [67.087469813000041, 24.897933391000038],
            [67.087374771000043, 24.898342073000038],
            [67.087156173000039, 24.898332569000047],
            [67.08705210800008, 24.897351230000027],
            [67.087004105000062, 24.897049496000022],
            [67.086985097000081, 24.896792881000067],
            [67.086842533000038, 24.896621805000052],
            [67.086661952000043, 24.896336677000079],
            [67.086566910000045, 24.896175105000054],
            [67.086623935000034, 24.895880473000034],
            [67.086852037000028, 24.895148646000052],
            [67.087175181000077, 24.894150700000068],
            [67.087479317000032, 24.893209780000063],
            [67.082985729000029, 24.891522950000024],
            [67.08147456100005, 24.890875307000044],
            [67.081512105000058, 24.890753287000052],
            [67.081615353000075, 24.890509248000058],
            [67.081793689000051, 24.890133802000037],
            [67.082000184000037, 24.889702040000032],
            [67.082234838000034, 24.889289050000059],
            [67.08230992700004, 24.88893237700006],
            [67.082413174000067, 24.888585090000049],
            [67.082450719000065, 24.888425525000059],
            [67.082516422000083, 24.888359822000041],
            [67.082732303000057, 24.888387981000051],
            [67.082873095000082, 24.888294119000022],
            [67.082966956000064, 24.888228416000061],
            [67.083088976000056, 24.888087624000036],
            [67.08320161000006, 24.887984377000066],
            [67.083276699000066, 24.88806885200006],
            [67.083342402000028, 24.888172100000077],
            [67.083445649000055, 24.888265961000059],
            [67.083670917000063, 24.888312892000044],
            [67.083933728000034, 24.888303506000057],
            [67.084055748000083, 24.888265961000059],
            [67.084121451000044, 24.888087624000036],
            [67.08422469900006, 24.887862357000074],
            [67.084281016000034, 24.887749724000059],
            [67.084412421000081, 24.887637090000055],
            [67.084618916000068, 24.887533842000039],
            [67.084938045000058, 24.887327347000053],
            [67.085163312000077, 24.887346120000075],
            [67.085294718000057, 24.887458753000033],
            [67.085463669000035, 24.887477526000055],
            [67.085585688000037, 24.887364892000051],
            [67.085810956000046, 24.88720532800005],
            [67.085970520000046, 24.887083308000058],
            [67.086148857000069, 24.887102080000034],
            [67.086317807000057, 24.887195941000073],
            [67.086421055000073, 24.887252258000046],
            [67.08659939100005, 24.887158397000064],
            [67.086815272000081, 24.886998833000064],
            [67.087059312000065, 24.886811110000053],
            [67.087181332000057, 24.886792338000078],
            [67.087293965000072, 24.88688619900006],
            [67.087369054000078, 24.88720532800005],
            [67.087566163000076, 24.887430595000069],
            [67.087932223000053, 24.887693407000029],
            [67.088119945000074, 24.887815426000031],
            [67.088251351000054, 24.887721565000049],
            [67.088373371000046, 24.887515070000063],
            [67.088847269000041, 24.886705731000063],
            [67.089722717000029, 24.885254990000078],
            [67.091598676000046, 24.882353507000062],
            [67.092599187000076, 24.880502561000071],
            [67.093974891000073, 24.878301436000072],
            [67.095300568000027, 24.876000259000023],
            [67.096326092000083, 24.873924198000054],
            [67.097101489000067, 24.87264854600005],
            [67.092566461000047, 24.870098731000041],
            [67.090873305000059, 24.869146756000077],
            [67.085927945000037, 24.86634411600005],
            [67.084420006000073, 24.865669979000074],
            [67.083233419000067, 24.867106755000066],
            [67.082806409000057, 24.86771595700003],
            [67.081805743000075, 24.869143574000077],
            [67.080720709000047, 24.870514144000026],
            [67.080035424000073, 24.871503999000026],
            [67.07952146000008, 24.872036999000045],
            [67.078017641000031, 24.872836498000026],
            [67.074724467000067, 24.874416460000077],
            [67.073487147000037, 24.875101744000062],
            [67.072421149000036, 24.875844136000069],
            [67.07066986500007, 24.877024349000067],
            [67.069899971000041, 24.877570002000027],
            [67.066748514000039, 24.879803559000038],
            [67.060710393000079, 24.884153214000037],
            [67.060044144000074, 24.884610071000054],
            [67.059530181000071, 24.884971749000044],
            [67.05904477100006, 24.885285837000026],
            [67.056397319000041, 24.887284626000053],
            [67.056079251000028, 24.886471785000026],
            [67.053572527000028, 24.888159325000061],
            [67.050229384000033, 24.890407916000072],
            [67.051119208000046, 24.891793931000052],
            [67.051478601000042, 24.89245425200005],
            [67.047874883000077, 24.893143949000034],
            [67.045242363000057, 24.893536867000023],
            [67.045959507000077, 24.893886261000034],
            [67.046881793000068, 24.894303887000035],
            [67.047380882000084, 24.894579042000032],
            [67.047387325000045, 24.894582859000025],
            [67.047733351000034, 24.894802688000027],
            [67.048101726000084, 24.894990235000023],
            [67.049115734000054, 24.895471790000045],
            [67.05031856100004, 24.896087783000041],
            [67.051070744000072, 24.896502227000042],
            [67.051307209000072, 24.896636539000042],
            [67.05141786300004, 24.896676746000026],
            [67.051543081000034, 24.896698103000062],
            [67.051677400000074, 24.896713642000066],
            [67.051683079000043, 24.896714427000063],
            [67.051689164000038, 24.896715556000061],
            [67.052029367000046, 24.896787173000064],
            [67.052035860000046, 24.896788717000049],
            [67.052381394000065, 24.89688047900006],
            [67.052498950000029, 24.896911697000064],
            [67.052503429000069, 24.896912975000077],
            [67.052731211000037, 24.896982500000036],
            [67.05274273200007, 24.896986789000039],
            [67.052908967000064, 24.897058314000049],
            [67.052914713000064, 24.897060961000022],
            [67.053135963000045, 24.897169896000037],
            [67.053348783000047, 24.897253182000043],
            [67.053539947000047, 24.897274947000028],
            [67.053739003000032, 24.897290740000074],
            [67.053749334000031, 24.897291977000066],
            [67.053761504000079, 24.897294543000044],
            [67.054326632000084, 24.897442618000071],
            [67.055020690000049, 24.897595562000049],
            [67.055026870000063, 24.897597087000065],
            [67.055371180000066, 24.897691130000055],
            [67.055377730000032, 24.897693109000045],
            [67.055381379000039, 24.897694383000044],
            [67.055653062000033, 24.897793922000062],
            [67.055957503000059, 24.897908490000077],
            [67.056226700000082, 24.897997901000053],
            [67.056398459000036, 24.898043587000075],
            [67.056580457000052, 24.89807993200003],
            [67.05667277200007, 24.898090029000059],
            [67.056779491000043, 24.89808290700006],
            [67.056788187000052, 24.898082618000046],
            [67.056935259000056, 24.898082686000066],
            [67.056945580000047, 24.898083099000075],
            [67.056955824000056, 24.898084331000064],
            [67.05696570300006, 24.898086319000072],
            [67.057162961000074, 24.898134129000027],
            [67.058040708000078, 24.898352562000071],
            [67.058050153000067, 24.898355304000063],
            [67.058772625000074, 24.898596276000035],
            [67.059203038000078, 24.898724724000033],
            [67.05920672600007, 24.898725886000022],
            [67.059214386000065, 24.898728708000078],
            [67.059419992000073, 24.898812147000058],
            [67.059432220000076, 24.898817914000063],
            [67.05961269200003, 24.898917827000048],
            [67.059620247000055, 24.898922379000055],
            [67.059627401000057, 24.898927443000048],
            [67.059633203000033, 24.89893218900005],
            [67.059638653000036, 24.898937271000023],
            [67.059688554000047, 24.89898697600006],
            [67.059747113000071, 24.899045306000062],
            [67.059833751000042, 24.899131605000036],
            [67.060148304000052, 24.899382007000042],
            [67.06065327500005, 24.899788916000034],
            [67.060798376000037, 24.899905839000041],
            [67.061025157000074, 24.900071479000076],
            [67.061212167000065, 24.900201778000053],
            [67.061215096000069, 24.900203885000053],
            [67.061363499000038, 24.900314190000074],
            [67.061374529000034, 24.90032364700005],
            [67.061511279000058, 24.900456911000049],
            [67.061515547000056, 24.900461303000043],
            [67.061520856000072, 24.900467520000063],
            [67.061621442000046, 24.900594574000024],
            [67.061628282000072, 24.900604263000048],
            [67.061707260000048, 24.900731743000051],
            [67.061711729000081, 24.90073970800006],
            [67.061715724000067, 24.900748649000036],
            [67.061786433000066, 24.900933357000042],
            [67.061868138000079, 24.90116749200007],
            [67.061900396000055, 24.901259929000048],
            [67.062033749000079, 24.901593745000071],
            [67.062117703000069, 24.90180390300003],
            [67.062506513000073, 24.902680124000028],
            [67.063055672000075, 24.903936617000056],
            [67.06310761800006, 24.90405547000006],
            [67.06337953600007, 24.904673032000062],
            [67.063835773000051, 24.90570920600004],
            [67.064106906000063, 24.906152261000045],
            [67.064277574000073, 24.906365305000065],
            [67.064284531000055, 24.906375048000029],
            [67.064288945000044, 24.906382712000038],
            [67.064292674000058, 24.906390673000033],
            [67.06436728500006, 24.906569447000038],
            [67.064371532000052, 24.90658219200003],
            [67.064410844000065, 24.906733042000042],
            [67.064412259000051, 24.906739281000057],
            [67.064413518000038, 24.90674772400007],
            [67.064414046000081, 24.906756230000042],
            [67.064418215000046, 24.906977372000028],
            [67.064439469000035, 24.907150471000023],
            [67.064506047000066, 24.907464352000034],
            [67.064595422000082, 24.907655044000023],
            [67.064815619000058, 24.907942641000034],
            [67.064967315000047, 24.90813627700004],
            [67.065233891000048, 24.90847655400006],
            [67.065615884000067, 24.908930207000026],
            [67.065925811000056, 24.909253135000029],
            [67.066330286000039, 24.909628108000049],
            [67.066463934000069, 24.909728980000068],
            [67.066674516000035, 24.909887918000038],
            [67.066710594000028, 24.909915148000039],
            [67.067079503000059, 24.910143733000041],
            [67.067334441000071, 24.910301699000058],
            [67.067893692000041, 24.91059705400005],
            [67.068384963000028, 24.910806768000043],
            [67.069138508000037, 24.911074037000049],
            [67.070138167000039, 24.911345651000033],
            [67.07074696400008, 24.911478482000064],
            [67.07131925200008, 24.911603347000039],
            [67.071324831000084, 24.91160469600004],
            [67.072119021000049, 24.911815661000048],
            [67.073230072000058, 24.912137405000067],
            [67.073265202000073, 24.912146568000026],
            [67.074702208000076, 24.912521409000078],
            [67.076076452000052, 24.912868824000043],
            [67.07608532200004, 24.912871413000062],
            [67.076520127000038, 24.913015633000043],
            [67.076524228000039, 24.913017073000049],
            [67.076738614000078, 24.913096516000053],
            [67.076747918000081, 24.913100397000051],
            [67.07704405700008, 24.913238298000067],
            [67.077050598000028, 24.913241592000077],
            [67.077209271000072, 24.913329587000078],
            [67.077214750000053, 24.913332817000025],
            [67.077356100000031, 24.913421264000078],
            [67.077365737000036, 24.913427981000041],
            [67.07749252800005, 24.91352715000005],
            [67.077615803000072, 24.913627030000043],
            [67.077622211000062, 24.913632627000027],
            [67.077740318000053, 24.913743785000065],
            [67.077744219000067, 24.913747639000064],
            [67.077836074000061, 24.913842913000053],
            [67.077838562000068, 24.913845587000026],
            [67.077963226000065, 24.913986283000042],
            [67.077968527000053, 24.91399274500003],
            [67.078192282000032, 24.914287795000064],
            [67.078196748000039, 24.914294146000032],
            [67.078427927000064, 24.914649558000065],
            [67.07843278100006, 24.914657937000072],
            [67.078558825000073, 24.914900838000051],
            [67.078561152000077, 24.914905617000045],
            [67.078661724000028, 24.915126401000066],
            [67.078663649000077, 24.915130909000027],
            [67.078667461000066, 24.915142409000055],
            [67.078755458000046, 24.915473042000031],
            [67.078756981000083, 24.915479657000049],
            [67.078905685000052, 24.91624351400003],
            [67.078906437000057, 24.916247922000025],
            [67.078963116000068, 24.916631529000028],
            [67.079080863000058, 24.917428450000045],
            [67.079248047000078, 24.918406545000039],
            [67.079340226000056, 24.918945829000052],
            [67.079478287000029, 24.919753547000028],
            [67.079791224000076, 24.921967576000043],
            [67.07989492300004, 24.922622062000073],
            [67.079959563000045, 24.922903904000066],
            [67.080031481000049, 24.923200178000059],
            [67.080100392000077, 24.923407480000037],
            [67.080155325000078, 24.923583958000052],
            [67.080243585000062, 24.92376868100007],
            [67.080303994000076, 24.923890093000068],
            [67.08040569800005, 24.924085109000032],
            [67.080468875000065, 24.924197537000055],
            [67.08059109800007, 24.924427295000044],
            [67.080682999000032, 24.924587125000073],
            [67.08083877100006, 24.924824009000076],
            [67.080943263000052, 24.924941593000028],
            [67.081063103000076, 24.925079563000054],
            [67.08116431500008, 24.925181277000036],
            [67.081168226000045, 24.925185425000052],
            [67.081259130000035, 24.925289038000074],
            [67.081417586000043, 24.925442874000055],
            [67.081562283000039, 24.925578968000025],
            [67.081819618000054, 24.925812486000041],
            [67.081825830000071, 24.925818573000072],
            [67.08192555800008, 24.925924149000025],
            [67.081998269000053, 24.925982799000053],
            [67.082000767000068, 24.925984873000061],
            [67.082065812000053, 24.926040423000074],
            [67.082156805000068, 24.926089969000031],
            [67.082306343000027, 24.926146521000078],
            [67.082318908000047, 24.926152083000034],
            [67.082591464000075, 24.926291358000071],
            [67.083197116000065, 24.926583214000061],
            [67.083672802000081, 24.926812916000074],
            [67.084214430000031, 24.927059169000074],
            [67.084590950000063, 24.927203117000033],
            [67.084975108000037, 24.927320612000074],
            [67.08498191700005, 24.92732299000005],
            [67.085385173000077, 24.927477469000053],
            [67.085640913000077, 24.927578690000075],
            [67.085650860000044, 24.927583140000024],
            [67.085818189000065, 24.92766707100003],
            [67.085828216000039, 24.927672835000067],
            [67.086081376000038, 24.927835225000024],
            [67.08649999000005, 24.92809630000005],
            [67.08699819800006, 24.928389000000038],
            [67.087468175000083, 24.928667663000056],
            [67.087471258000051, 24.928669554000066],
            [67.087703987000054, 24.928817191000064],
            [67.087963066000043, 24.928981544000067],
            [67.088166452000053, 24.929114119000076],
            [67.088263271000073, 24.92917723000005],
            [67.088269442000069, 24.929181538000023],
            [67.088550030000079, 24.929391021000072],
            [67.088557915000081, 24.929397542000061],
            [67.088564662000067, 24.929404182000042],
            [67.088783675000059, 24.929638667000063],
            [67.088786347000052, 24.929641654000022],
            [67.08901964000006, 24.929912256000023],
            [67.089209498000059, 24.93013247600004],
            [67.089214492000053, 24.930138714000066],
            [67.089218997000046, 24.930145278000055],
            [67.089423363000037, 24.930468318000067],
            [67.089425340000048, 24.930471570000066],
            [67.089622168000062, 24.930808931000058],
            [67.089626705000057, 24.930817617000059],
            [67.089801460000047, 24.931194422000033],
            [67.08980222100007, 24.931196148000026],
            [67.089803070000073, 24.931198077000033],
            [67.090007287000049, 24.931687265000051],
            [67.090011731000061, 24.931700773000046],
            [67.090125855000053, 24.932153109000069],
            [67.090127247000055, 24.932159528000057],
            [67.090236294000078, 24.932788049000067],
            [67.090432797000062, 24.933695882000052],
            [67.09043347800008, 24.933699514000068],
            [67.090533966000066, 24.934302980000041],
            [67.09053530500006, 24.934317971000041],
            [67.090539552000052, 24.93464797200005],
            [67.090587312000082, 24.935348679000072],
            [67.09058751300006, 24.935353057000043],
            [67.090604838000047, 24.936084072000028],
            [67.090604776000077, 24.936089091000042],
            [67.090587142000061, 24.936541441000031],
            [67.090586884000061, 24.936545691000049],
            [67.090542973000083, 24.93707977400004],
            [67.090541787000063, 24.937088938000045],
            [67.090436515000079, 24.937683566000032],
            [67.090418807000049, 24.937778348000052],
            [67.090334806000044, 24.938227969000025],
            [67.090333064000049, 24.938235525000039],
            [67.090253646000065, 24.938527685000054],
            [67.090166169000042, 24.938849492000031],
            [67.089908566000076, 24.939693409000029],
            [67.089860003000069, 24.939864471000078],
            [67.089764317000061, 24.940201523000042],
            [67.089648115000045, 24.940610841000023],
            [67.089647175000039, 24.940613958000029],
            [67.089423596000074, 24.941314589000058],
            [67.089081364000037, 24.942342852000024],
            [67.089079171000037, 24.942348811000045],
            [67.088914576000036, 24.942752087000031],
            [67.088744616000042, 24.943168511000067],
            [67.088740551000058, 24.94317727300006],
            [67.088349409000045, 24.943926790000035],
            [67.088344238000047, 24.943935604000046],
            [67.087766501000033, 24.944796248000046],
            [67.087833623000051, 24.944856978000075],
            [67.088216426000031, 24.945203323000044],
            [67.088816862000044, 24.945746575000044],
            [67.100799836000078, 24.956053693000058],
            [67.106272503000071, 24.960642977000077],
            [67.108168546000059, 24.962323560000073],
            [67.11058169000006, 24.964995256000066],
            [67.119484931000045, 24.974725548000038],
            [67.126690837000069, 24.982390876000068],
            [67.131836723000049, 24.973842854000054],
            [67.133606642000075, 24.970882262000032],
            [67.133806977000063, 24.970543364000036],
            [67.150801368000032, 24.941794807000065],
            [67.151860228000032, 24.940069698000059],
            [67.152538375000063, 24.938891865000073],
            [67.152835807000031, 24.93841597200003],
            [67.152954780000073, 24.938261307000062],
            [67.153121342000077, 24.938166129000024],
            [67.153454467000074, 24.938082848000079],
            [67.154192100000046, 24.937928183000054],
            [67.155518862000065, 24.937693802000069],
            [67.155824410000037, 24.937614094000025],
            [67.156514454000046, 24.937507018000076],
            [67.157430546000057, 24.937352353000051],
            [67.158453715000064, 24.937233380000066],
            [67.159310321000078, 24.937078715000041],
            [67.15963154800005, 24.936947845000077],
            [67.159738624000056, 24.936757488000069],
            [67.159667240000033, 24.936519542000042],
            [67.159488780000061, 24.936352980000038],
            [67.159096169000065, 24.936293493000051],
            [67.158632174000047, 24.936115034000068],
            [67.158346639000058, 24.935948471000074],
            [67.158144385000071, 24.935722423000072],
            [67.157945118000043, 24.935417079000047],
            [67.157035952000058, 24.93407662900006],
            [67.154765153000028, 24.930293286000051],
            [67.153470727000069, 24.928151771000046],
          ],
        ],
      },
    },
    {
      type: "Feature",
      id: "160",
      properties: { name: "Thatta", count: 1308 },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [67.422264099000074, 24.065248489000055],
              [67.412956238000049, 24.059310913000047],
              [67.409881592000033, 24.062547684000037],
              [67.410415649000072, 24.066322327000023],
              [67.40515136700003, 24.070373535000044],
              [67.409439087000067, 24.073801041000024],
              [67.40864563000008, 24.076696396000045],
              [67.410545349000074, 24.080514908000055],
              [67.413040161000083, 24.080595016000075],
              [67.415153503000056, 24.078786850000029],
              [67.416763306000064, 24.072370529000068],
              [67.423088074000077, 24.067569733000028],
              [67.422264099000074, 24.065248489000055],
            ],
          ],
          [
            [
              [67.357849121000072, 24.357374191000076],
              [67.352844238000046, 24.357210159000033],
              [67.338577271000077, 24.360912323000036],
              [67.337081909000062, 24.364202499000044],
              [67.337646484000061, 24.367141724000078],
              [67.341171265000071, 24.370182037000063],
              [67.346092224000074, 24.378274918000045],
              [67.355667114000028, 24.38381004300004],
              [67.35733795200008, 24.381776810000076],
              [67.355789185000049, 24.369203568000046],
              [67.359100342000033, 24.360338211000055],
              [67.357849121000072, 24.357374191000076],
            ],
          ],
          [
            [
              [67.33376312300004, 24.373275757000044],
              [67.315422058000081, 24.364734650000059],
              [67.308723450000059, 24.378496170000062],
              [67.310363770000038, 24.383142471000042],
              [67.315711975000056, 24.386034012000039],
              [67.330368042000032, 24.384223938000048],
              [67.333183289000033, 24.382022858000028],
              [67.334732056000064, 24.377481461000059],
              [67.33376312300004, 24.373275757000044],
            ],
          ],
          [
            [
              [67.386054993000073, 24.421960831000035],
              [67.37901306200007, 24.421520233000024],
              [67.374160767000035, 24.423238754000067],
              [67.371315002000074, 24.426069260000077],
              [67.371864319000053, 24.42942619300004],
              [67.377388000000053, 24.433782578000034],
              [67.386459351000042, 24.434913635000044],
              [67.392692566000051, 24.43282318100006],
              [67.395103455000083, 24.429561615000068],
              [67.394508362000067, 24.427246094000054],
              [67.391441345000032, 24.424222946000043],
              [67.386054993000073, 24.421960831000035],
            ],
          ],
          [
            [
              [67.365257263000046, 24.429418564000059],
              [67.367088318000071, 24.423425674000043],
              [67.366004944000053, 24.416292191000025],
              [67.363220215000069, 24.41181755100007],
              [67.357574463000049, 24.410379410000075],
              [67.344100952000076, 24.417032242000062],
              [67.341636658000084, 24.415906906000032],
              [67.343803406000063, 24.413055420000035],
              [67.343925476000038, 24.409929276000071],
              [67.338661194000053, 24.404745102000049],
              [67.33816528300008, 24.400136948000068],
              [67.340011597000057, 24.393728256000031],
              [67.33874511700003, 24.391181946000074],
              [67.331703186000084, 24.390739441000051],
              [67.327293396000073, 24.392679214000054],
              [67.321815491000052, 24.392915726000069],
              [67.30801391600005, 24.390577316000076],
              [67.305366516000049, 24.394247055000051],
              [67.309669495000037, 24.406286240000043],
              [67.310142517000031, 24.411520004000067],
              [67.308609009000065, 24.41564369200006],
              [67.316116333000082, 24.415893555000025],
              [67.322120667000036, 24.419641495000064],
              [67.332832336000081, 24.419580460000077],
              [67.339973450000059, 24.41773033100003],
              [67.335426331000065, 24.423213959000066],
              [67.328689575000055, 24.426330566000047],
              [67.322052002000078, 24.42715454100005],
              [67.314933777000078, 24.422742844000027],
              [67.310623169000053, 24.422389984000063],
              [67.306465149000076, 24.423713684000063],
              [67.30729675300006, 24.425619125000026],
              [67.310394287000065, 24.428018570000063],
              [67.320602417000032, 24.429193497000028],
              [67.335220337000067, 24.434061050000025],
              [67.34586334200003, 24.43587493900003],
              [67.352890015000071, 24.436733246000074],
              [67.359873783000069, 24.434364601000027],
              [67.360740662000069, 24.434070587000065],
              [67.365257263000046, 24.429418564000059],
            ],
          ],
          [
            [
              [67.370635986000082, 24.454851151000071],
              [67.372764587000063, 24.45283317600007],
              [67.371734619000051, 24.450086594000027],
              [67.359527588000049, 24.441753387000063],
              [67.35274505600006, 24.44048500100007],
              [67.347862244000055, 24.442829132000043],
              [67.342689514000028, 24.441196442000034],
              [67.337196350000056, 24.44164085400007],
              [67.305908203000058, 24.43204307600007],
              [67.301033020000034, 24.434177399000077],
              [67.296630859000061, 24.441753387000063],
              [67.301765442000033, 24.450065613000049],
              [67.308532715000069, 24.451751709000064],
              [67.316108704000044, 24.450542450000057],
              [67.333860617000028, 24.453200426000024],
              [67.335815430000082, 24.45349311800004],
              [67.346084595000036, 24.453207016000079],
              [67.35010528600003, 24.455217361000052],
              [67.362335205000079, 24.457290649000072],
              [67.369055691000028, 24.455315580000047],
              [67.370635986000082, 24.454851151000071],
            ],
          ],
          [
            [
              [67.321037292000028, 24.46970176700006],
              [67.316261292000036, 24.469543457000043],
              [67.309677124000075, 24.474542618000044],
              [67.310028076000037, 24.482902527000078],
              [67.319763184000067, 24.49596023600003],
              [67.323600769000052, 24.496923447000029],
              [67.329032898000037, 24.492301941000051],
              [67.32379913300008, 24.486284256000033],
              [67.322532654000042, 24.472255707000045],
              [67.321037292000028, 24.46970176700006],
            ],
          ],
          [
            [
              [67.340759277000075, 24.489768982000044],
              [67.33643341100003, 24.489624023000033],
              [67.335578918000067, 24.493980408000027],
              [67.314277649000076, 24.507884979000039],
              [67.313034058000028, 24.510349274000077],
              [67.31618499800004, 24.517133713000078],
              [67.319053650000058, 24.519525528000031],
              [67.326271057000042, 24.510162354000045],
              [67.334968567000033, 24.503562927000075],
              [67.346580505000077, 24.498310089000029],
              [67.348030090000066, 24.49627113300005],
              [67.346771240000066, 24.493515015000071],
              [67.344818115000066, 24.490945816000078],
              [67.340759277000075, 24.489768982000044],
            ],
          ],
          [
            [
              [67.29255676300005, 24.520521164000058],
              [67.306198120000033, 24.510330200000055],
              [67.312767029000042, 24.499902725000027],
              [67.310279846000071, 24.493766785000048],
              [67.305572510000047, 24.491731644000026],
              [67.303283691000047, 24.480590820000032],
              [67.304901123000036, 24.474174500000061],
              [67.302749634000065, 24.470972061000055],
              [67.286941606000028, 24.468892516000039],
              [67.275085449000073, 24.46733284000004],
              [67.255030521000037, 24.450680089000059],
              [67.254749000000061, 24.451553000000047],
              [67.252081000000032, 24.464409000000046],
              [67.252375000000029, 24.465972000000079],
              [67.252897000000075, 24.46754100000004],
              [67.254231000000061, 24.468612000000064],
              [67.25569900000005, 24.469063000000062],
              [67.258425000000045, 24.469339000000048],
              [67.257655000000057, 24.47212200000007],
              [67.253369000000077, 24.474300000000028],
              [67.251498000000083, 24.475910000000056],
              [67.250059000000078, 24.478360000000066],
              [67.248731000000078, 24.480815000000064],
              [67.248197000000062, 24.483394000000033],
              [67.249209000000064, 24.48756700000007],
              [67.250458000000037, 24.491436000000078],
              [67.252628000000072, 24.495016000000078],
              [67.254058000000043, 24.496711000000062],
              [67.256266000000039, 24.499048000000073],
              [67.258376000000055, 24.500864000000036],
              [67.258325912000032, 24.500976437000077],
              [67.254939957000033, 24.508577218000028],
              [67.26754760700004, 24.518638611000029],
              [67.269332886000029, 24.519742966000024],
              [67.276802349000036, 24.518449632000056],
              [67.27943420400004, 24.517993927000077],
              [67.283729553000057, 24.524608612000065],
              [67.288002014000028, 24.526212692000058],
              [67.29255676300005, 24.520521164000058],
            ],
          ],
          [
            [
              [67.361733877000063, 24.527851614000042],
              [67.36148834200003, 24.524894714000027],
              [67.356979370000033, 24.517856598000037],
              [67.350517273000037, 24.502405167000063],
              [67.332901001000039, 24.509756088000074],
              [67.327728271000069, 24.513549805000025],
              [67.325884631000065, 24.519258461000049],
              [67.325393677000079, 24.520778656000061],
              [67.321784973000035, 24.525461197000027],
              [67.315345764000028, 24.526708603000031],
              [67.312004089000084, 24.524717331000033],
              [67.307479858000079, 24.518096924000076],
              [67.304306030000078, 24.517572403000031],
              [67.294403076000037, 24.525800705000051],
              [67.293350220000036, 24.529314041000077],
              [67.296173096000075, 24.532749176000038],
              [67.304008484000065, 24.536558151000065],
              [67.308273315000065, 24.544006348000039],
              [67.312065125000061, 24.546012878000056],
              [67.320022583000082, 24.54669380200005],
              [67.333374023000033, 24.543796539000027],
              [67.347541809000063, 24.543012619000024],
              [67.359497070000032, 24.534849167000061],
              [67.361252286000081, 24.530135656000027],
              [67.361801147000051, 24.528661728000031],
              [67.361733877000063, 24.527851614000042],
            ],
          ],
          [
            [
              [67.285797119000051, 24.581041336000055],
              [67.291595459000064, 24.578729630000055],
              [67.295852661000083, 24.574907303000032],
              [67.29932403600003, 24.568132401000071],
              [67.304969788000051, 24.563728333000029],
              [67.305763245000037, 24.561040878000028],
              [67.299674988000049, 24.55937767000006],
              [67.298942566000051, 24.554759979000039],
              [67.30287170400004, 24.547794342000032],
              [67.300086975000056, 24.543317795000064],
              [67.295646667000028, 24.540246964000062],
              [67.287063599000078, 24.538080215000036],
              [67.28385925300006, 24.532754898000064],
              [67.276466370000037, 24.529375076000065],
              [67.273223877000078, 24.524883270000032],
              [67.270484924000073, 24.524999619000027],
              [67.266693115000066, 24.528629303000059],
              [67.260269165000068, 24.52945709200003],
              [67.253349304000039, 24.536949158000027],
              [67.249877930000082, 24.53829193100006],
              [67.245330811000031, 24.537929535000046],
              [67.243803849000074, 24.531948527000054],
              [67.242965698000035, 24.528665543000045],
              [67.236981523000054, 24.523153696000065],
              [67.23626100000007, 24.523445000000038],
              [67.232313000000033, 24.525834000000032],
              [67.229162000000031, 24.528242000000034],
              [67.219471000000055, 24.539451000000042],
              [67.210804000000053, 24.550637000000052],
              [67.208823000000052, 24.551977000000079],
              [67.20695900000004, 24.553278000000034],
              [67.206351000000041, 24.554507000000058],
              [67.204277000000047, 24.558914000000073],
              [67.201450000000079, 24.561849000000052],
              [67.197910000000036, 24.565699000000052],
              [67.196551000000056, 24.569086000000027],
              [67.19704100000007, 24.571692000000041],
              [67.199078000000043, 24.577005000000042],
              [67.20259800000008, 24.576397000000043],
              [67.205614000000082, 24.578444000000047],
              [67.208111000000031, 24.578819000000067],
              [67.215423000000044, 24.578383000000031],
              [67.224410000000034, 24.576678000000072],
              [67.230723021000074, 24.575937057000033],
              [67.232872009000062, 24.575084686000025],
              [67.233656728000028, 24.575592735000043],
              [67.246503000000075, 24.574085000000025],
              [67.246657044000074, 24.575077858000043],
              [67.24722613800003, 24.57874582900007],
              [67.253036499000075, 24.578689575000055],
              [67.258659363000049, 24.580549240000039],
              [67.269905090000066, 24.579048157000045],
              [67.285797119000051, 24.581041336000055],
            ],
          ],
          [
            [
              [67.307868958000029, 24.588249207000047],
              [67.304931641000053, 24.587526321000041],
              [67.300621033000084, 24.592601776000038],
              [67.301719666000054, 24.59931755100007],
              [67.30499267600004, 24.603185654000072],
              [67.308616638000046, 24.603931427000077],
              [67.309303284000066, 24.592472076000035],
              [67.307868958000029, 24.588249207000047],
            ],
          ],
          [
            [
              [67.346267700000055, 24.609771729000045],
              [67.342407227000081, 24.609228134000034],
              [67.339797974000078, 24.617490768000039],
              [67.343315125000061, 24.620946884000034],
              [67.347862244000055, 24.621307373000036],
              [67.349143982000044, 24.617799759000036],
              [67.34796142600004, 24.613168716000075],
              [67.346267700000055, 24.609771729000045],
            ],
          ],
          [
            [
              [67.334503174000076, 24.61293220500005],
              [67.335273743000073, 24.610662460000071],
              [67.332649231000062, 24.607860565000067],
              [67.335006714000031, 24.600214005000055],
              [67.33357238800005, 24.596200943000042],
              [67.322387695000032, 24.596246719000078],
              [67.319694519000052, 24.595113754000067],
              [67.317825317000029, 24.590463638000074],
              [67.317672729000037, 24.582731247000027],
              [67.314697266000053, 24.583049774000074],
              [67.313644409000062, 24.586563110000043],
              [67.316719055000078, 24.601070404000041],
              [67.315338135000047, 24.607078552000075],
              [67.314315796000074, 24.609758377000048],
              [67.308036804000039, 24.612680435000073],
              [67.30463409400005, 24.612150192000058],
              [67.298873902000082, 24.602146149000077],
              [67.293334961000028, 24.58651351900005],
              [67.291351318000068, 24.590412140000069],
              [67.290184021000073, 24.613964081000063],
              [67.291961670000035, 24.62070465100004],
              [67.296066284000062, 24.626478195000061],
              [67.304306030000078, 24.631763458000023],
              [67.312393188000044, 24.634954453000034],
              [67.31654357900004, 24.634048462000067],
              [67.320549011000082, 24.630842209000036],
              [67.326683045000038, 24.620189667000034],
              [67.334503174000076, 24.61293220500005],
            ],
          ],
          [
            [
              [67.374008179000043, 24.629890442000033],
              [67.368301392000035, 24.618221283000025],
              [67.362243652000075, 24.615516663000051],
              [67.357093811000084, 24.618896484000061],
              [67.353157043000067, 24.626073837000035],
              [67.347549438000044, 24.629228592000061],
              [67.33219146700003, 24.625173569000026],
              [67.328941345000032, 24.626525879000042],
              [67.326744080000083, 24.630002975000025],
              [67.328208923000034, 24.633392334000064],
              [67.333183289000033, 24.634599686000058],
              [67.339569092000033, 24.640447617000063],
              [67.347656250000057, 24.643846512000039],
              [67.355621338000049, 24.644525528000031],
              [67.372482300000058, 24.633806229000072],
              [67.374008179000043, 24.629890442000033],
            ],
          ],
          [
            [
              [67.298583984000061, 24.666227341000024],
              [67.303314209000064, 24.662210464000054],
              [67.30265045200008, 24.655923843000039],
              [67.307014465000066, 24.643960953000033],
              [67.305358887000068, 24.639730453000027],
              [67.293228149000072, 24.634733200000028],
              [67.28385925300006, 24.634838104000039],
              [67.280548096000075, 24.637859344000049],
              [67.279380798000034, 24.644083023000064],
              [67.283210754000038, 24.651100159000066],
              [67.280181885000047, 24.658306122000056],
              [67.280967712000063, 24.661464691000049],
              [67.283401489000028, 24.663423538000075],
              [67.290748596000071, 24.662416458000052],
              [67.298583984000061, 24.666227341000024],
            ],
          ],
          [
            [
              [67.364906311000084, 24.65213966400006],
              [67.344680786000083, 24.649803162000069],
              [67.343345642000031, 24.654767990000039],
              [67.346710205000079, 24.656133652000051],
              [67.352119446000074, 24.663825989000031],
              [67.358398438000052, 24.666748047000056],
              [67.367065430000082, 24.667032242000062],
              [67.368789673000038, 24.663747787000034],
              [67.364906311000084, 24.65213966400006],
            ],
          ],
          [
            [
              [67.344871521000073, 24.662334442000031],
              [67.342956543000071, 24.655002594000052],
              [67.334152222000057, 24.650499344000025],
              [67.332756042000028, 24.645441055000049],
              [67.329261780000081, 24.641359329000068],
              [67.324272156000063, 24.640567780000026],
              [67.312599182000042, 24.647069931000033],
              [67.306617737000067, 24.659606934000067],
              [67.308792114000028, 24.662183762000041],
              [67.311767578000058, 24.662073135000071],
              [67.316246033000084, 24.658672333000027],
              [67.31990051300005, 24.658586502000048],
              [67.325294495000037, 24.666488647000051],
              [67.331336975000056, 24.669612885000049],
              [67.334251404000042, 24.676597595000032],
              [67.337898254000038, 24.671081543000071],
              [67.342834473000039, 24.667486191000023],
              [67.344871521000073, 24.662334442000031],
            ],
          ],
          [
            [
              [67.237045288000047, 24.663326263000044],
              [67.243057251000039, 24.661441803000059],
              [67.253524780000078, 24.662422180000078],
              [67.256546021000077, 24.661062241000025],
              [67.258239746000072, 24.653184891000024],
              [67.265869141000053, 24.650728226000069],
              [67.269439697000053, 24.647089005000055],
              [67.277099609000061, 24.632732391000047],
              [67.281761169000049, 24.630384445000061],
              [67.283035278000057, 24.627086639000026],
              [67.280601501000035, 24.613851547000024],
              [67.272811890000071, 24.603153229000043],
              [67.265472412000065, 24.598314285000072],
              [67.25616455100004, 24.597166061000053],
              [67.246147156000063, 24.602254868000045],
              [67.241996765000067, 24.60879516600005],
              [67.238014221000071, 24.611375809000037],
              [67.225730896000073, 24.615970612000069],
              [67.219596863000049, 24.620773315000065],
              [67.217109680000078, 24.625907898000037],
              [67.221687317000033, 24.636709213000074],
              [67.220001221000075, 24.638948441000025],
              [67.214538574000073, 24.638555527000051],
              [67.20323181200007, 24.624599457000045],
              [67.198110927000073, 24.621448077000025],
              [67.191385000000082, 24.627971000000059],
              [67.184755620000033, 24.63744994800004],
              [67.181920000000048, 24.642558000000065],
              [67.177863000000059, 24.65526200000005],
              [67.176671000000056, 24.668834000000061],
              [67.176670196000032, 24.670598548000044],
              [67.183067322000056, 24.676523209000038],
              [67.193405151000036, 24.680635452000047],
              [67.198181152000075, 24.681007385000044],
              [67.203048706000061, 24.679292679000071],
              [67.20873260500008, 24.674476624000079],
              [67.216033936000031, 24.663450241000078],
              [67.237045288000047, 24.663326263000044],
            ],
          ],
          [
            [
              [67.320823669000049, 24.669681549000074],
              [67.316047668000067, 24.663469315000043],
              [67.291107178000061, 24.687482834000036],
              [67.296112061000031, 24.688066483000057],
              [67.300994873000036, 24.685934067000062],
              [67.308143616000052, 24.678445816000078],
              [67.320823669000049, 24.669681549000074],
            ],
          ],
          [
            [
              [67.415184021000073, 24.674448013000074],
              [67.415817261000029, 24.669876099000078],
              [67.426208496000072, 24.661027908000051],
              [67.436973572000056, 24.648431778000031],
              [67.435729980000076, 24.645259857000042],
              [67.432090759000062, 24.64493370100007],
              [67.41983795200008, 24.654254913000045],
              [67.41627352200004, 24.656966521000072],
              [67.411964417000036, 24.657434464000062],
              [67.409957886000029, 24.656324387000041],
              [67.406867981000062, 24.647665024000048],
              [67.401878357000044, 24.646875381000029],
              [67.398521423000034, 24.650941849000048],
              [67.395072937000066, 24.651456833000054],
              [67.389915466000048, 24.649200439000026],
              [67.386894226000038, 24.650562286000024],
              [67.377235413000051, 24.663816452000049],
              [67.369888306000064, 24.670673370000031],
              [67.366630554000039, 24.672237396000071],
              [67.347457886000029, 24.671815872000025],
              [67.341827393000074, 24.675596237000036],
              [67.339294434000067, 24.681983948000038],
              [67.340515137000068, 24.68578147900007],
              [67.351806641000053, 24.688869476000036],
              [67.385612488000049, 24.688934326000037],
              [67.40564727800006, 24.696475983000028],
              [67.420867920000035, 24.698432922000052],
              [67.42572784400005, 24.696920395000063],
              [67.428932190000069, 24.690759659000037],
              [67.422019958000078, 24.680725098000039],
              [67.415184021000073, 24.674448013000074],
            ],
          ],
          [
            [
              [67.245666504000042, 24.670299530000023],
              [67.244865417000028, 24.667558670000062],
              [67.241241455000079, 24.666809082000043],
              [67.231590271000073, 24.668361664000031],
              [67.22224426300005, 24.667837143000042],
              [67.218261719000054, 24.670415878000028],
              [67.213729858000079, 24.680700302000048],
              [67.188858032000041, 24.691127777000077],
              [67.18780517600004, 24.694641113000046],
              [67.189552307000042, 24.696578979000037],
              [67.19522857700008, 24.697399139000026],
              [67.199699402000078, 24.705278397000029],
              [67.202842712000063, 24.706638336000026],
              [67.209541321000074, 24.716051102000051],
              [67.212249756000062, 24.716770172000054],
              [67.21993255600006, 24.690933228000063],
              [67.221832275000054, 24.689117432000046],
              [67.23148345900006, 24.687774658000023],
              [67.238792419000049, 24.68217659000004],
              [67.24339294400005, 24.675649643000042],
              [67.245666504000042, 24.670299530000023],
            ],
          ],
          [
            [
              [67.325347900000054, 24.728080749000071],
              [67.329719543000067, 24.727390289000027],
              [67.33213806200007, 24.729766846000075],
              [67.337852478000059, 24.735383987000034],
              [67.346534729000041, 24.735462189000032],
              [67.353240967000033, 24.727748871000074],
              [67.361366272000055, 24.724466324000048],
              [67.368515015000071, 24.716976166000052],
              [67.374855041000046, 24.718437195000035],
              [67.381286621000072, 24.711757660000046],
              [67.384971619000055, 24.711042404000068],
              [67.385704041000054, 24.703968048000036],
              [67.390869141000053, 24.700380325000026],
              [67.39147186200006, 24.696641922000026],
              [67.382690430000082, 24.693431854000039],
              [67.372138977000077, 24.694547653000029],
              [67.349548340000069, 24.694013596000048],
              [67.340255737000064, 24.692245483000079],
              [67.33338165300006, 24.687007904000041],
              [67.330085754000038, 24.678131104000045],
              [67.327156067000033, 24.676990509000063],
              [67.32201385500008, 24.679950714000029],
              [67.317245483000079, 24.679374695000035],
              [67.312362671000074, 24.681509018000042],
              [67.304687500000057, 24.690649033000057],
              [67.292945862000067, 24.692972183000052],
              [67.287284851000038, 24.691738129000044],
              [67.286048889000028, 24.688356400000032],
              [67.287399292000032, 24.683183670000062],
              [67.295509338000045, 24.674684525000032],
              [67.294967651000036, 24.671117783000057],
              [67.292266846000075, 24.669984818000046],
              [67.275588989000028, 24.670261383000025],
              [67.272476196000071, 24.668069839000054],
              [67.271713257000044, 24.664285660000075],
              [67.274856567000029, 24.654369354000039],
              [67.271003723000035, 24.653614044000051],
              [67.265632629000038, 24.656564713000023],
              [67.262947083000029, 24.66086006200004],
              [67.265502930000082, 24.67117500300003],
              [67.273231506000059, 24.677698135000071],
              [67.272270203000062, 24.678918839000062],
              [67.268821716000048, 24.679430008000054],
              [67.26592254600007, 24.677661896000075],
              [67.259948730000076, 24.678504944000053],
              [67.253227234000065, 24.675355911000054],
              [67.248138428000061, 24.677062988000046],
              [67.245925903000057, 24.680746078000027],
              [67.242861729000083, 24.68710883600005],
              [67.239692688000048, 24.693689346000042],
              [67.236358643000074, 24.697126389000061],
              [67.22721862800006, 24.69723510700004],
              [67.223709106000058, 24.699203491000048],
              [67.217308044000049, 24.71610641500007],
              [67.217147827000076, 24.720067978000031],
              [67.220001221000075, 24.722879410000075],
              [67.225723267000035, 24.722656250000057],
              [67.233444109000061, 24.715923512000074],
              [67.24301147500006, 24.707580566000047],
              [67.247879028000057, 24.705865860000074],
              [67.254486084000064, 24.706296921000046],
              [67.261177063000048, 24.710281372000054],
              [67.274948120000033, 24.72577476500004],
              [67.285003662000065, 24.731330872000058],
              [67.29486846900005, 24.736045838000052],
              [67.303054810000049, 24.737154007000072],
              [67.312759399000072, 24.73434448200004],
              [67.325347900000054, 24.728080749000071],
            ],
          ],
          [
            [
              [68.086202528000058, 25.413927517000047],
              [68.097928743000068, 25.404110134000064],
              [68.09808520200005, 25.403979144000061],
              [68.108162804000074, 25.395542006000028],
              [68.124244633000046, 25.366658978000032],
              [68.128258000000073, 25.359301000000073],
              [68.144121821000056, 25.331003997000039],
              [68.152299000000028, 25.316418000000056],
              [68.173782650000078, 25.298725182000055],
              [68.174390000000074, 25.298225000000059],
              [68.200514639000062, 25.302685998000072],
              [68.20103000000006, 25.302774000000056],
              [68.208073355000067, 25.301173058000074],
              [68.218258725000055, 25.298857942000041],
              [68.229171708000081, 25.296377441000061],
              [68.233198128000083, 25.295381037000027],
              [68.235251869000081, 25.294867641000053],
              [68.254914102000043, 25.289952461000041],
              [68.255608000000052, 25.289779000000067],
              [68.263148214000068, 25.28933528400006],
              [68.265141867000068, 25.289217964000045],
              [68.265785655000059, 25.289180080000051],
              [68.288745000000063, 25.287829000000045],
              [68.296452775000034, 25.287680945000034],
              [68.310907837000059, 25.28740328400005],
              [68.322532000000081, 25.287180000000035],
              [68.323628326000062, 25.287963080000054],
              [68.323695588000078, 25.288011123000047],
              [68.32775788400005, 25.290912726000045],
              [68.333238972000061, 25.294827738000038],
              [68.335372221000057, 25.296351421000054],
              [68.344285268000078, 25.302717624000024],
              [68.345273000000077, 25.303423000000066],
              [68.345273000000077, 25.300261375000048],
              [68.345273000000077, 25.281982000000028],
              [68.339491329000055, 25.275236964000044],
              [68.325780000000066, 25.259241000000031],
              [68.325780000000066, 25.247178442000063],
              [68.325780000000066, 25.237150000000042],
              [68.327448736000065, 25.235314326000037],
              [68.327495869000074, 25.235262478000038],
              [68.33311380400005, 25.229082534000042],
              [68.338775000000055, 25.222855000000038],
              [68.343328635000034, 25.216426742000067],
              [68.338775000000055, 25.212459000000024],
              [68.334227000000055, 25.194267000000025],
              [68.339877185000034, 25.186008781000055],
              [68.342674000000045, 25.181921000000045],
              [68.34508009700005, 25.17726927800004],
              [68.352420000000052, 25.163079000000039],
              [68.353069000000062, 25.144886000000042],
              [68.349634896000055, 25.137080825000055],
              [68.34592200000003, 25.12864200000007],
              [68.339416967000034, 25.128012554000065],
              [68.329111497000042, 25.127015366000023],
              [68.325780000000066, 25.126693000000046],
              [68.309554381000055, 25.126693000000046],
              [68.303511839000066, 25.126693000000046],
              [68.29394300000007, 25.126693000000046],
              [68.278999000000056, 25.124094000000071],
              [68.276841976000071, 25.11752049200004],
              [68.276778827000044, 25.117328044000033],
              [68.269632734000083, 25.095550398000057],
              [68.269621659000052, 25.095516647000068],
              [68.267225975000031, 25.088215825000077],
              [68.265354000000059, 25.082511000000068],
              [68.262306048000028, 25.05638426400003],
              [68.260806000000059, 25.043526000000043],
              [68.260616797000068, 25.042330022000044],
              [68.260612963000028, 25.042303612000069],
              [68.259564700000055, 25.035665599000026],
              [68.259453002000043, 25.034958287000052],
              [68.25937740300003, 25.034479565000026],
              [68.256907000000069, 25.018836000000078],
              [68.258857000000035, 24.989598000000058],
              [68.259933186000069, 24.989115414000025],
              [68.255276578000064, 24.98578883700003],
              [68.252917933000049, 24.984103927000035],
              [68.249172000000044, 24.981428000000051],
              [68.240511436000077, 24.97761717700007],
              [68.235309000000029, 24.975328000000047],
              [68.231563450000067, 24.969243275000053],
              [68.230872000000033, 24.968120000000056],
              [68.226991000000055, 24.956475000000069],
              [68.222000000000037, 24.95148400000005],
              [68.214237000000082, 24.953702000000078],
              [68.212005407000049, 24.956082491000075],
              [68.205919000000051, 24.962575000000072],
              [68.199819000000048, 24.965902000000028],
              [68.190393000000029, 24.96701100000007],
              [68.184848000000045, 24.96701100000007],
              [68.182075000000054, 24.974774000000025],
              [68.175421000000028, 24.975883000000067],
              [68.171116789000052, 24.975643686000069],
              [68.163900542000079, 24.975373253000043],
              [68.153240000000039, 24.975328000000047],
              [68.14658600000007, 24.972556000000054],
              [68.141595000000052, 24.971447000000069],
              [68.135495000000049, 24.970892000000049],
              [68.128287000000057, 24.963129000000038],
              [68.124960000000044, 24.954811000000063],
              [68.124481013000036, 24.945908762000045],
              [68.124770667000064, 24.939753204000056],
              [68.124771901000031, 24.939726982000025],
              [68.124882279000076, 24.937397124000029],
              [68.124651050000068, 24.933431679000023],
              [68.124226862000057, 24.926073381000037],
              [68.124142517000053, 24.924574049000057],
              [68.124024304000045, 24.922444291000033],
              [68.123851000000059, 24.919322000000022],
              [68.122742000000073, 24.913222000000076],
              [68.11664200000007, 24.90712300000007],
              [68.10923972300003, 24.904347146000077],
              [68.100561000000027, 24.900469000000044],
              [68.091689000000031, 24.900469000000044],
              [68.086698000000069, 24.895478000000026],
              [68.085589000000084, 24.888269000000037],
              [68.087807000000055, 24.879951000000062],
              [68.093063088000065, 24.87512349800005],
              [68.099591556000064, 24.868720760000031],
              [68.102224000000035, 24.866088000000047],
              [68.106603715000062, 24.862647521000042],
              [68.109374048000063, 24.86030792300005],
              [68.113075090000052, 24.857182325000053],
              [68.11608700000005, 24.854444000000058],
              [68.123296000000039, 24.843353000000036],
              [68.123680639000042, 24.840468952000037],
              [68.117196000000035, 24.839472000000058],
              [68.110752284000057, 24.838612786000056],
              [68.108879000000059, 24.838363000000072],
              [68.10111500000005, 24.844462000000078],
              [68.097339643000055, 24.848237736000044],
              [68.091134000000068, 24.854444000000058],
              [68.078935000000058, 24.853889000000038],
              [68.070062000000064, 24.856107000000065],
              [68.050100000000043, 24.850007000000062],
              [68.044918221000046, 24.845473454000057],
              [68.041227000000049, 24.842244000000051],
              [68.031246000000067, 24.835035000000062],
              [68.026255000000049, 24.826718000000028],
              [68.026255000000049, 24.810592435000046],
              [68.026255000000049, 24.807864000000052],
              [68.026810000000069, 24.800101000000041],
              [68.031246000000067, 24.789565000000039],
              [68.032355000000052, 24.777920000000051],
              [68.029583000000059, 24.76738400000005],
              [68.026709548000042, 24.762355998000032],
              [68.026692014000048, 24.762325318000023],
              [68.022928000000036, 24.755739000000062],
              [68.019047000000057, 24.750749000000042],
              [68.011838000000068, 24.747422000000029],
              [68.00740200000007, 24.744095000000073],
              [68.00740200000007, 24.734113000000036],
              [68.010174000000063, 24.727459000000067],
              [68.007956000000036, 24.719696000000056],
              [68.001302000000067, 24.723577000000034],
              [67.998219822000067, 24.720969398000022],
              [67.994093000000078, 24.717478000000028],
              [67.993539000000055, 24.710823000000062],
              [67.986885000000029, 24.700842000000023],
              [67.985745517000055, 24.699656980000043],
              [67.980902709000077, 24.694620642000075],
              [67.980795571000044, 24.694509223000068],
              [67.973022000000071, 24.686425000000042],
              [67.965258000000063, 24.674225000000035],
              [67.962486000000069, 24.667571000000066],
              [67.962702038000032, 24.663462373000073],
              [67.962822247000076, 24.661176239000042],
              [67.963040000000035, 24.657035000000064],
              [67.965813000000082, 24.648717000000033],
              [67.967140007000069, 24.642879224000069],
              [67.967719101000057, 24.640331668000044],
              [67.968586000000073, 24.636518000000024],
              [67.968134729000042, 24.634037127000056],
              [67.967819504000033, 24.632304171000044],
              [67.966367000000048, 24.624319000000071],
              [67.96872453900005, 24.612533008000071],
              [67.969140000000039, 24.610456000000056],
              [67.969695000000058, 24.603247000000067],
              [67.977027367000062, 24.596617714000047],
              [67.979573108000068, 24.594272690000025],
              [67.981894000000068, 24.592157000000043],
              [67.985287164000056, 24.59032988000007],
              [67.987745892000078, 24.589005850000035],
              [67.989103000000057, 24.588275000000067],
              [67.99187500000005, 24.579957000000036],
              [67.991452473000038, 24.573190470000043],
              [67.991321000000028, 24.571085000000039],
              [67.991321000000028, 24.566998793000039],
              [67.991321000000028, 24.547795000000065],
              [67.994555000000048, 24.547795000000065],
              [67.993539000000055, 24.546686000000022],
              [67.984667000000059, 24.545577000000037],
              [67.973022000000071, 24.53670500000004],
              [67.964149000000077, 24.525060000000053],
              [67.956386000000066, 24.516742000000079],
              [67.951950000000068, 24.507315000000062],
              [67.95092743400005, 24.502202170000032],
              [67.963214836000077, 24.502209829000037],
              [67.973240710000084, 24.504835653000043],
              [67.982593865000069, 24.504779001000031],
              [67.992424988000039, 24.475033514000074],
              [67.97302707700004, 24.467646952000052],
              [67.963595000000055, 24.46461800000003],
              [67.964365318000034, 24.461278719000063],
              [67.965258000000063, 24.457409000000041],
              [67.965813000000082, 24.450200000000052],
              [67.965813000000082, 24.447771112000055],
              [67.965813000000082, 24.444351952000034],
              [67.965813000000082, 24.438234499000032],
              [67.965813000000082, 24.431346000000076],
              [67.965578970000081, 24.429786266000065],
              [67.964880320000077, 24.42513000200006],
              [67.964149000000077, 24.420256000000052],
              [67.964035983000031, 24.420237101000055],
              [67.96400906000008, 24.42022834900007],
              [67.955810184000029, 24.418866601000047],
              [67.954327994000039, 24.418648163000057],
              [67.952756435000083, 24.418416554000032],
              [67.945296000000042, 24.417484000000059],
              [67.933150051000041, 24.418622276000065],
              [67.930546825000079, 24.418866242000036],
              [67.927551000000051, 24.419147000000066],
              [67.914475636000077, 24.422882818000062],
              [67.912025000000028, 24.423583000000065],
              [67.897053000000028, 24.425801000000035],
              [67.888735000000054, 24.426356000000055],
              [67.881329608000044, 24.426003059000038],
              [67.877090000000067, 24.425801000000035],
              [67.864891000000057, 24.427465000000041],
              [67.856573000000083, 24.42524700000007],
              [67.847146000000066, 24.420256000000052],
              [67.841601000000082, 24.41249300000004],
              [67.839994453000031, 24.409280229000046],
              [67.838784077000071, 24.406859719000067],
              [67.836610000000064, 24.402512000000058],
              [67.844374000000073, 24.388094000000024],
              [67.852137000000027, 24.377558000000079],
              [67.827645873000051, 24.360716019000051],
              [67.813995413000043, 24.355125990000033],
              [67.813827421000042, 24.355003539000052],
              [67.807721389000051, 24.350552806000053],
              [67.778207745000032, 24.33571598900005],
              [67.780077541000082, 24.332554005000077],
              [67.779495000000054, 24.332088000000056],
              [67.774504000000036, 24.324879000000067],
              [67.775613000000078, 24.314898000000028],
              [67.778386000000069, 24.307134000000076],
              [67.782268000000045, 24.295490000000029],
              [67.791694000000064, 24.28550800000005],
              [67.792493429000046, 24.284155214000066],
              [67.793618231000039, 24.282251835000068],
              [67.794816150000031, 24.280224727000075],
              [67.79490303700004, 24.28007769900006],
              [67.798204480000038, 24.274491030000036],
              [67.798903000000053, 24.27330900000004],
              [67.803387150000049, 24.268824850000044],
              [67.806112000000041, 24.266100000000051],
              [67.81332100000003, 24.262773000000038],
              [67.81332100000003, 24.260555000000068],
              [67.809439000000054, 24.241701000000035],
              [67.804655834000073, 24.241462057000035],
              [67.79834900000003, 24.241147000000069],
              [67.794249432000072, 24.241147000000069],
              [67.790713442000083, 24.241147000000069],
              [67.787503117000028, 24.241147000000069],
              [67.784486000000072, 24.241147000000069],
              [67.782371457000067, 24.241952506000075],
              [67.778311211000073, 24.243499199000041],
              [67.778026071000056, 24.243607820000022],
              [67.772841000000028, 24.245583000000067],
              [67.770175386000062, 24.245329143000049],
              [67.764897702000042, 24.244824546000075],
              [67.764868571000079, 24.244823631000031],
              [67.76455827500007, 24.244792200000063],
              [67.751215000000059, 24.24336500000004],
              [67.74345100000005, 24.234493000000043],
              [67.742843022000045, 24.231249113000047],
              [67.741788000000042, 24.225620000000049],
              [67.741788000000042, 24.217303000000072],
              [67.735327697000059, 24.22413292400006],
              [67.724187431000075, 24.227672062000067],
              [67.716219668000065, 24.222777754000049],
              [67.71400701500005, 24.21808720100006],
              [67.71308014400006, 24.209330037000029],
              [67.697781573000043, 24.194140966000077],
              [67.661845922000055, 24.15846252700004],
              [67.661580063000031, 24.161520289000066],
              [67.661509862000059, 24.162327706000042],
              [67.661404711000046, 24.163537101000031],
              [67.661120906000065, 24.166800992000049],
              [67.660275437000053, 24.167108460000065],
              [67.648323000000062, 24.171455000000037],
              [67.647872387000064, 24.172356226000034],
              [67.64437300000003, 24.179355000000044],
              [67.641300423000075, 24.18158952400006],
              [67.637132000000065, 24.18462100000005],
              [67.634326486000077, 24.18462100000005],
              [67.633735487000081, 24.18462100000005],
              [67.63122955800003, 24.18462100000005],
              [67.629937232000032, 24.18462100000005],
              [67.628263926000045, 24.18462100000005],
              [67.628156130000036, 24.18462100000005],
              [67.628048678000027, 24.18462100000005],
              [67.627922363000039, 24.18462100000005],
              [67.624160516000074, 24.18462100000005],
              [67.622649000000081, 24.18462100000005],
              [67.619925206000062, 24.182124418000058],
              [67.617783024000062, 24.180160933000025],
              [67.614749000000074, 24.177380000000028],
              [67.610141000000056, 24.17079700000005],
              [67.609482397000079, 24.170174915000075],
              [67.609248084000058, 24.169953595000038],
              [67.605010098000037, 24.165950595000027],
              [67.601808341000037, 24.162926368000058],
              [67.600353316000053, 24.161552021000034],
              [67.598292000000072, 24.159605000000056],
              [67.594834811000055, 24.158703313000046],
              [67.593318606000082, 24.158307864000051],
              [67.590717384000072, 24.157629427000074],
              [67.588662330000034, 24.157093438000061],
              [67.583151000000043, 24.155656000000079],
              [67.576976724000076, 24.156306474000075],
              [67.569234015000063, 24.157122186000038],
              [67.568121792000056, 24.157344613000078],
              [67.563737278000076, 24.158221449000052],
              [67.560633117000066, 24.158842235000066],
              [67.560355854000079, 24.158897683000077],
              [67.556819000000075, 24.159605000000056],
              [67.545628000000079, 24.159605000000056],
              [67.544383534000076, 24.156079222000074],
              [67.543978060000029, 24.154930448000073],
              [67.543396489000031, 24.153282761000071],
              [67.541470224000079, 24.147634820000064],
              [67.540952494000067, 24.145693283000071],
              [67.540694876000032, 24.144727191000072],
              [67.539878307000038, 24.141664979000041],
              [67.539045000000044, 24.138540000000035],
              [67.543653000000063, 24.132615000000044],
              [67.550236000000041, 24.130640000000028],
              [67.554186000000072, 24.128665000000069],
              [67.556124642000043, 24.125064545000043],
              [67.556541818000028, 24.124289763000036],
              [67.558794000000034, 24.120107000000075],
              [67.560871012000064, 24.113459426000077],
              [67.562085000000081, 24.109574000000066],
              [67.561826637000081, 24.10621410400006],
              [67.561639409000065, 24.103779286000076],
              [67.561508208000078, 24.102073070000074],
              [67.560775490000083, 24.09743443900004],
              [67.560110000000066, 24.093775000000051],
              [67.559947409000074, 24.091660328000046],
              [67.559692497000071, 24.088344926000048],
              [67.559653164000053, 24.087703341000065],
              [67.559594813000047, 24.086751556000024],
              [67.559452000000078, 24.08442205800003],
              [67.559452000000078, 24.078634000000022],
              [67.566035000000056, 24.072051000000044],
              [67.572618000000034, 24.072051000000044],
              [67.572618000000034, 24.064810000000023],
              [67.566636036000034, 24.061177598000029],
              [67.56076900000005, 24.056910000000073],
              [67.548919000000069, 24.051644000000067],
              [67.541863025000055, 24.049879783000051],
              [67.54102000000006, 24.049669000000051],
              [67.537440778000075, 24.048434805000056],
              [67.537396351000041, 24.048419485000068],
              [67.53197042100004, 24.046548504000043],
              [67.52504134000003, 24.044159204000039],
              [67.523975761000031, 24.043791769000052],
              [67.521929000000057, 24.043086000000073],
              [67.515600462000066, 24.042483130000051],
              [67.510209064000037, 24.041969533000042],
              [67.50810400000006, 24.041769000000045],
              [67.504533889000072, 24.040578762000052],
              [67.502180000000067, 24.039794000000029],
              [67.501652628000045, 24.038871224000047],
              [67.501640035000037, 24.038849190000064],
              [67.496913000000063, 24.030578000000048],
              [67.495597000000032, 24.014779000000033],
              [67.490330000000029, 24.005563000000052],
              [67.489258000000063, 23.984168000000068],
              [67.486419678000061, 23.974071503000062],
              [67.477551945000073, 23.970871805000058],
              [67.477539063000052, 23.970867157000043],
              [67.471586452000054, 23.967605408000054],
              [67.467563943000073, 23.965401264000036],
              [67.441280124000059, 23.95099897800003],
              [67.436178000000041, 23.954702000000054],
              [67.421224000000052, 23.969278000000031],
              [67.420980217000078, 23.969581238000046],
              [67.418690204000029, 23.972429752000039],
              [67.420585632000041, 23.973552704000042],
              [67.423950195000032, 23.977764130000025],
              [67.422119141000053, 23.980466843000045],
              [67.415936876000046, 23.978270674000044],
              [67.415473000000077, 23.979716000000053],
              [67.41530248600003, 23.980263949000062],
              [67.414096000000029, 23.984141000000022],
              [67.412702000000081, 23.993026000000043],
              [67.410294905000057, 23.995700326000076],
              [67.408179929000028, 23.998050102000036],
              [67.40198300000003, 24.004935000000046],
              [67.399195000000077, 24.003208000000029],
              [67.396376000000032, 24.002517000000068],
              [67.394199000000071, 24.003190000000075],
              [67.391216000000043, 24.004259000000047],
              [67.387808000000064, 24.008220000000051],
              [67.385677000000044, 24.011175000000037],
              [67.384697000000074, 24.013640000000066],
              [67.383716000000049, 24.016105000000039],
              [67.383942000000047, 24.020066000000043],
              [67.387238000000082, 24.019939000000022],
              [67.392118000000039, 24.020058000000063],
              [67.392078000000083, 24.021405000000073],
              [67.392223000000058, 24.024209000000042],
              [67.392484000000081, 24.026912000000038],
              [67.393533000000048, 24.029842000000031],
              [67.39496900000006, 24.031225000000063],
              [67.397002000000043, 24.031586000000061],
              [67.39741900000007, 24.032840000000078],
              [67.397424656000055, 24.033019066000065],
              [67.409202576000041, 24.039157867000029],
              [67.409996033000084, 24.042104721000044],
              [67.414693526000065, 24.047519332000036],
              [67.424339294000049, 24.058637619000024],
              [67.435340882000048, 24.061183929000038],
              [67.438026428000057, 24.063631058000055],
              [67.436851453000031, 24.065929873000073],
              [67.434700109000062, 24.070138933000067],
              [67.431908350000072, 24.075600954000038],
              [67.430137634000062, 24.079065323000066],
              [67.420890808000081, 24.083147049000047],
              [67.418045044000053, 24.086183548000065],
              [67.418418884000062, 24.088281631000029],
              [67.428291321000074, 24.091524124000046],
              [67.433105469000054, 24.096271515000069],
              [67.434518134000029, 24.102367091000076],
              [67.435249329000044, 24.105522156000063],
              [67.437622070000032, 24.108728409000037],
              [67.446556091000048, 24.112773895000032],
              [67.448898315000065, 24.116813660000048],
              [67.446487427000079, 24.12028312700005],
              [67.443084717000033, 24.12017250100007],
              [67.43370056200007, 24.110271454000042],
              [67.423149109000065, 24.095321655000077],
              [67.408821106000062, 24.084005356000034],
              [67.405029297000056, 24.076370239000028],
              [67.40457153300008, 24.070512772000029],
              [67.405551801000058, 24.064938450000056],
              [67.407347000000073, 24.054730000000063],
              [67.398592304000033, 24.067402966000031],
              [67.397974000000033, 24.068298000000027],
              [67.397915927000042, 24.068304151000063],
              [67.394783000000075, 24.068636000000026],
              [67.389666000000034, 24.068822000000068],
              [67.387510000000077, 24.068770000000029],
              [67.384654000000069, 24.069322000000057],
              [67.381860000000074, 24.071639000000062],
              [67.375782000000072, 24.082582000000059],
              [67.373094000000037, 24.088947000000076],
              [67.369338000000027, 24.09684100000004],
              [67.36433500000004, 24.108540000000062],
              [67.362252000000069, 24.11367400000006],
              [67.361568000000034, 24.121399000000054],
              [67.362654000000077, 24.12308500000006],
              [67.36776100000003, 24.127253000000053],
              [67.367263229000059, 24.127790530000027],
              [67.36645062100007, 24.128668042000072],
              [67.365883000000053, 24.129281000000049],
              [67.363388212000075, 24.132348334000028],
              [67.372169495000037, 24.132253647000027],
              [67.383113859000048, 24.129791744000045],
              [67.411834717000033, 24.123331070000063],
              [67.420677185000045, 24.123826981000036],
              [67.425056458000029, 24.128143311000031],
              [67.424514771000077, 24.130212784000037],
              [67.422859192000033, 24.132036209000034],
              [67.414573669000049, 24.128845215000069],
              [67.39909362800006, 24.129383087000065],
              [67.382835388000046, 24.132604599000047],
              [67.36946868900003, 24.137382507000041],
              [67.360626221000075, 24.142515182000068],
              [67.357084616000066, 24.140440742000067],
              [67.350111000000084, 24.150502000000074],
              [67.349978061000058, 24.150513873000023],
              [67.34828600000003, 24.15066500000006],
              [67.345512000000042, 24.152154000000053],
              [67.342784000000051, 24.155925000000025],
              [67.341354000000081, 24.162008000000071],
              [67.339785000000063, 24.168505000000039],
              [67.337855000000047, 24.172397000000046],
              [67.33623700000004, 24.177296000000069],
              [67.335305629000061, 24.178941718000033],
              [67.33455900000007, 24.18026100000003],
              [67.330602000000056, 24.187113000000068],
              [67.322873000000072, 24.198433000000023],
              [67.317742000000067, 24.210313000000042],
              [67.316816062000044, 24.213201689000073],
              [67.337074280000081, 24.226459503000058],
              [67.341964722000057, 24.235177994000026],
              [67.347702508000054, 24.235784582000065],
              [67.350570679000043, 24.236087799000074],
              [67.361221313000044, 24.231431961000055],
              [67.359603882000044, 24.237848282000073],
              [67.36253356900005, 24.238571167000032],
              [67.378904220000038, 24.236858128000051],
              [67.388343811000084, 24.235870361000025],
              [67.401634216000048, 24.233385086000055],
              [67.407012939000083, 24.229595184000061],
              [67.412475586000028, 24.229564667000034],
              [67.415161133000083, 24.224853516000053],
              [67.41516308100006, 24.221815481000078],
              [67.415165190000039, 24.218527778000066],
              [67.415167127000075, 24.215506785000059],
              [67.415168762000064, 24.21295738200007],
              [67.41683960000006, 24.210926056000062],
              [67.42074585000006, 24.209800720000032],
              [67.428146362000064, 24.212545395000063],
              [67.431213379000042, 24.221410751000064],
              [67.432945251000035, 24.241291046000072],
              [67.431655884000065, 24.247184753000056],
              [67.427268982000044, 24.246950149000043],
              [67.426445007000041, 24.238784790000068],
              [67.428283691000047, 24.22653198200004],
              [67.42501068100006, 24.217035294000027],
              [67.421157837000067, 24.216701508000028],
              [67.419746399000076, 24.217906952000078],
              [67.41915130600006, 24.227487564000057],
              [67.414344788000051, 24.234008789000029],
              [67.409103394000056, 24.234254837000037],
              [67.397201539000037, 24.242004394000048],
              [67.38558959900007, 24.242250442000056],
              [67.373947144000056, 24.243328094000049],
              [67.361689494000075, 24.248926917000063],
              [67.350082397000051, 24.254228592000061],
              [67.348937988000046, 24.260242462000065],
              [67.351020813000048, 24.265111923000063],
              [67.361297608000029, 24.275676727000075],
              [67.368522644000052, 24.277166367000063],
              [67.37549591100003, 24.273431778000031],
              [67.381416321000074, 24.273208618000069],
              [67.398109436000084, 24.282728195000061],
              [67.395683289000033, 24.286615372000028],
              [67.393379211000081, 24.287374496000041],
              [67.378967285000044, 24.277719498000067],
              [67.375061035000044, 24.278633118000073],
              [67.369346619000055, 24.285123825000028],
              [67.363174438000044, 24.28575515700004],
              [67.358474731000058, 24.283721924000076],
              [67.35189056400003, 24.277662278000037],
              [67.343399048000038, 24.262145996000072],
              [67.337959290000072, 24.255912781000063],
              [67.334114075000059, 24.255369187000042],
              [67.330131531000063, 24.252523422000024],
              [67.330497742000034, 24.250572205000026],
              [67.330993652000075, 24.247961044000078],
              [67.328857422000056, 24.244342804000041],
              [67.325881958000082, 24.244869232000042],
              [67.320703097000035, 24.250324127000056],
              [67.312820434000059, 24.258626938000077],
              [67.307978809000076, 24.254678003000038],
              [67.307543000000067, 24.254752000000053],
              [67.298612000000048, 24.256772000000069],
              [67.295713000000035, 24.258567000000028],
              [67.291842000000031, 24.26241200000004],
              [67.288205000000062, 24.266054000000054],
              [67.285759000000041, 24.271697000000074],
              [67.285300000000063, 24.275686000000064],
              [67.285007000000064, 24.281591000000049],
              [67.286585000000059, 24.289616000000024],
              [67.287694000000045, 24.290577000000042],
              [67.288711000000035, 24.290810000000079],
              [67.290435000000059, 24.290231000000063],
              [67.292741000000035, 24.289251000000036],
              [67.292875000000038, 24.292366000000072],
              [67.290589000000068, 24.296457000000032],
              [67.290047280000067, 24.297741882000025],
              [67.295425415000068, 24.301036835000048],
              [67.297225952000076, 24.307357788000047],
              [67.298581023000054, 24.308760207000034],
              [67.301811218000068, 24.312103271000069],
              [67.306709290000072, 24.314979553000057],
              [67.311609637000061, 24.315036306000025],
              [67.316261292000036, 24.315090179000038],
              [67.321762085000046, 24.319864273000064],
              [67.322586060000049, 24.322187424000049],
              [67.320884705000083, 24.324844360000043],
              [67.315399170000035, 24.325286865000066],
              [67.314437866000048, 24.326715469000078],
              [67.316368103000059, 24.329702377000046],
              [67.325317383000083, 24.333547592000059],
              [67.32985687300004, 24.333908081000061],
              [67.334098816000051, 24.330291748000036],
              [67.338500977000081, 24.322715759000062],
              [67.340187073000038, 24.30878830000006],
              [67.343109131000062, 24.30408477800006],
              [67.35469818100006, 24.292989731000034],
              [67.359565735000047, 24.290645599000072],
              [67.362144470000032, 24.294487000000061],
              [67.359375000000057, 24.301282883000056],
              [67.341331482000044, 24.328199387000041],
              [67.338569641000049, 24.332319260000077],
              [67.338821411000083, 24.337543488000051],
              [67.343925476000038, 24.340843201000041],
              [67.346580505000077, 24.342809677000048],
              [67.350898742000084, 24.342952728000057],
              [67.373725891000049, 24.33577346800007],
              [67.400474548000034, 24.321414947000051],
              [67.406295776000036, 24.312213898000039],
              [67.410881042000028, 24.311319351000066],
              [67.415008545000035, 24.316463470000031],
              [67.420127868000066, 24.319341660000077],
              [67.428085327000076, 24.319601059000036],
              [67.43479919400005, 24.316688538000051],
              [67.442108154000039, 24.316089631000068],
              [67.446434021000073, 24.322072983000055],
              [67.44899749800004, 24.332174301000066],
              [67.452392578000058, 24.332700729000067],
              [67.45873260500008, 24.327688217000059],
              [67.466239929000039, 24.327930451000043],
              [67.473762512000064, 24.321701050000058],
              [67.482139587000063, 24.322805404000064],
              [67.490600586000028, 24.321825027000045],
              [67.496353149000072, 24.332233429000041],
              [67.50508880600006, 24.336061478000033],
              [67.514633178000054, 24.342416764000063],
              [67.518089294000049, 24.341274261000024],
              [67.525131226000042, 24.329811096000071],
              [67.529052734000061, 24.334526062000066],
              [67.525459290000072, 24.339212418000045],
              [67.516326904000039, 24.351861954000071],
              [67.512428284000066, 24.352573395000036],
              [67.508575439000083, 24.345981598000037],
              [67.506340027000078, 24.344865799000047],
              [67.499977112000067, 24.338611603000061],
              [67.493881226000042, 24.337371826000037],
              [67.487243652000075, 24.326307297000028],
              [67.475654602000077, 24.325727463000078],
              [67.466468811000084, 24.33378028900006],
              [67.460914612000067, 24.336105347000057],
              [67.457984924000073, 24.341228485000045],
              [67.456581116000052, 24.342227936000029],
              [67.448463440000069, 24.340087891000053],
              [67.443818511000075, 24.332250420000037],
              [67.43799591100003, 24.32242584200003],
              [67.434341431000064, 24.322725296000044],
              [67.431869507000044, 24.327653885000075],
              [67.427375793000067, 24.326255798000034],
              [67.424606323000035, 24.327209473000039],
              [67.425926208000078, 24.334140778000062],
              [67.420959473000039, 24.333143234000033],
              [67.417236328000058, 24.329265594000049],
              [67.411117554000043, 24.328649521000045],
              [67.403366089000031, 24.328813553000032],
              [67.396675110000047, 24.331100464000031],
              [67.369613647000051, 24.347743988000047],
              [67.366523743000073, 24.350980759000038],
              [67.369018555000082, 24.357116699000073],
              [67.36463165300006, 24.364276886000027],
              [67.362022400000058, 24.372747421000042],
              [67.36360931400003, 24.37864494300004],
              [67.369026184000063, 24.385709763000023],
              [67.376358032000041, 24.390542984000035],
              [67.387428284000066, 24.392784119000055],
              [67.397911072000056, 24.392709732000071],
              [67.405456543000071, 24.391912460000071],
              [67.41774749800004, 24.386468887000035],
              [67.422218323000038, 24.38264846800007],
              [67.423171997000054, 24.375791550000031],
              [67.418144226000038, 24.364566803000059],
              [67.419387817000029, 24.361894608000057],
              [67.422859192000033, 24.360338211000055],
              [67.428306580000083, 24.360931396000069],
              [67.429550171000074, 24.369945526000038],
              [67.428764343000068, 24.37847709700003],
              [67.428483073000052, 24.382315452000057],
              [67.427787781000063, 24.391803742000036],
              [67.419639587000063, 24.390495300000055],
              [67.41754913300008, 24.391471863000049],
              [67.418907166000054, 24.397567749000075],
              [67.41579437300004, 24.401432037000063],
              [67.409439087000067, 24.394964218000041],
              [67.406272888000046, 24.394443512000066],
              [67.398361206000061, 24.398777008000025],
              [67.387397766000049, 24.399461746000043],
              [67.375617981000062, 24.397823334000066],
              [67.362625122000054, 24.392179489000057],
              [67.360519409000062, 24.387725830000079],
              [67.350646973000039, 24.389696121000043],
              [67.347778320000032, 24.393150330000026],
              [67.346900940000069, 24.398130417000061],
              [67.349082947000056, 24.40049743700007],
              [67.357048035000048, 24.400760651000041],
              [67.365692139000032, 24.406888962000039],
              [67.369807962000039, 24.406582701000048],
              [67.373458862000064, 24.406311035000044],
              [67.385910034000062, 24.414024353000059],
              [67.390289307000046, 24.418550491000076],
              [67.398376465000069, 24.421527863000051],
              [67.400215149000076, 24.427015305000054],
              [67.403053284000066, 24.430238724000048],
              [67.406143188000044, 24.432844162000038],
              [67.412303227000052, 24.435071890000074],
              [67.416923523000037, 24.436742783000057],
              [67.424636841000051, 24.437620163000076],
              [67.433326721000071, 24.43685722400005],
              [67.444694519000052, 24.431798935000074],
              [67.451965332000043, 24.42639732400005],
              [67.456573486000082, 24.425085068000044],
              [67.464607239000031, 24.423465729000043],
              [67.470497131000059, 24.424489975000029],
              [67.472679138000046, 24.426855087000035],
              [67.472091675000058, 24.430385590000071],
              [67.469268799000076, 24.432590485000048],
              [67.459823608000079, 24.429365158000053],
              [67.453445435000049, 24.429367065000065],
              [67.445457458000078, 24.435581207000041],
              [67.440422058000081, 24.436044693000042],
              [67.434486389000028, 24.442321777000075],
              [67.408828735000043, 24.439819336000028],
              [67.398361206000061, 24.439477921000048],
              [67.395805359000065, 24.440647125000055],
              [67.395225525000058, 24.443967819000022],
              [67.393173218000072, 24.449745178000057],
              [67.390197754000042, 24.450065613000049],
              [67.388664246000076, 24.448553085000071],
              [67.388221741000052, 24.442277908000051],
              [67.381271362000064, 24.439336777000051],
              [67.37532806400003, 24.434131622000052],
              [67.370666504000042, 24.436691284000062],
              [67.371673584000064, 24.440065384000036],
              [67.381858826000041, 24.447704315000067],
              [67.384216309000067, 24.451538086000028],
              [67.383415222000053, 24.45443534900005],
              [67.376152039000033, 24.459415436000029],
              [67.369499207000047, 24.460657120000064],
              [67.365467684000066, 24.460123859000078],
              [67.36110687300004, 24.459547043000043],
              [67.360374451000041, 24.460775375000026],
              [67.364370565000058, 24.462427808000029],
              [67.369706643000029, 24.464634329000035],
              [67.375209508000069, 24.466909818000033],
              [67.38011169400005, 24.468936920000033],
              [67.387435913000047, 24.479822159000037],
              [67.395042419000049, 24.483411789000058],
              [67.400932312000066, 24.484437943000046],
              [67.40779113800005, 24.484035492000032],
              [67.413192749000075, 24.479827881000062],
              [67.418487549000076, 24.466850281000063],
              [67.421775818000071, 24.464452744000027],
              [67.427024841000048, 24.464204788000075],
              [67.431480408000084, 24.466854095000031],
              [67.43513488800005, 24.466764450000028],
              [67.443359375000057, 24.460350037000069],
              [67.450431824000077, 24.460161209000034],
              [67.454002380000077, 24.462156296000046],
              [67.455009460000042, 24.465526581000063],
              [67.452623758000072, 24.465114982000046],
              [67.444595337000067, 24.463729858000079],
              [67.440650940000069, 24.465480804000038],
              [67.434959412000069, 24.471141815000067],
              [67.435035706000065, 24.475109100000054],
              [67.437210083000082, 24.477893829000038],
              [67.435997009000062, 24.479732513000044],
              [67.42803192100007, 24.479267120000031],
              [67.426460266000049, 24.47295379600007],
              [67.421302795000031, 24.470699310000043],
              [67.417686462000063, 24.475799561000031],
              [67.415466309000067, 24.48595619200006],
              [67.407905579000044, 24.492597580000051],
              [67.403945923000038, 24.506454468000072],
              [67.405319214000031, 24.512134552000077],
              [67.413116455000079, 24.516981125000029],
              [67.41202545200008, 24.521537781000063],
              [67.40250396700003, 24.514129639000032],
              [67.398200989000031, 24.513364792000061],
              [67.395805359000065, 24.516416550000031],
              [67.391937256000062, 24.516082764000032],
              [67.391105652000078, 24.514175415000068],
              [67.394874573000038, 24.505323410000074],
              [67.399940491000052, 24.504028320000032],
              [67.402099609000061, 24.501174927000022],
              [67.402191162000065, 24.493038177000074],
              [67.399574280000081, 24.49003028900006],
              [67.388656616000048, 24.48925590500005],
              [67.382492065000065, 24.48383522000006],
              [67.374290466000048, 24.472085953000033],
              [67.359039307000046, 24.465740204000042],
              [67.353141785000048, 24.464920044000053],
              [67.34243011500007, 24.459138870000061],
              [67.328521729000045, 24.459304810000049],
              [67.322700500000053, 24.456605911000054],
              [67.317481995000037, 24.456016541000054],
              [67.312202454000044, 24.456884384000034],
              [67.309387207000043, 24.458877563000044],
              [67.30994415300006, 24.462236404000066],
              [67.313079834000064, 24.463592529000039],
              [67.32151794400005, 24.463455200000055],
              [67.325775146000069, 24.465267181000058],
              [67.328277588000049, 24.471195221000073],
              [67.326324463000049, 24.480104446000041],
              [67.328178406000063, 24.485176086000024],
              [67.330650330000083, 24.486093521000043],
              [67.335762024000076, 24.483549118000042],
              [67.339607239000031, 24.472822189000055],
              [67.346847534000062, 24.474313736000056],
              [67.352333069000053, 24.479713440000069],
              [67.351387024000076, 24.486362457000041],
              [67.356605530000081, 24.492588043000069],
              [67.355293274000076, 24.502771378000034],
              [67.367011059000049, 24.524530403000028],
              [67.370170593000068, 24.530397415000039],
              [67.375999451000041, 24.532884598000066],
              [67.380149841000048, 24.531768799000076],
              [67.390945435000049, 24.523981094000078],
              [67.395324707000043, 24.522872925000058],
              [67.397537231000058, 24.524614334000034],
              [67.401336670000035, 24.532461166000076],
              [67.401977539000029, 24.539371490000065],
              [67.401565552000079, 24.543949127000076],
              [67.398719788000051, 24.546779633000028],
              [67.395278931000064, 24.547292709000033],
              [67.391510010000047, 24.544456482000044],
              [67.389129639000032, 24.54709243800005],
              [67.383872986000029, 24.541492462000065],
              [67.380012512000064, 24.541158676000066],
              [67.376663208000082, 24.545015335000073],
              [67.373931885000047, 24.544925690000071],
              [67.372848511000029, 24.543428421000044],
              [67.374862671000074, 24.538692474000072],
              [67.373588562000066, 24.536146164000058],
              [67.368354797000052, 24.535974503000034],
              [67.357856750000053, 24.541891098000065],
              [67.355186462000063, 24.545978546000072],
              [67.357063293000067, 24.550424576000069],
              [67.365471774000071, 24.557766704000073],
              [67.380661011000029, 24.571029663000047],
              [67.397872925000058, 24.580152512000041],
              [67.408958435000045, 24.582599640000069],
              [67.413627625000061, 24.585674286000028],
              [67.418205261000082, 24.58540535000003],
              [67.432357788000047, 24.573339462000035],
              [67.438575745000037, 24.571870804000071],
              [67.43961334200003, 24.574617386000057],
              [67.437194824000073, 24.578088760000071],
              [67.432036259000029, 24.581191638000064],
              [67.417106628000056, 24.59017181400003],
              [67.407089234000068, 24.589427948000036],
              [67.400444031000063, 24.584411621000072],
              [67.383377075000055, 24.577381134000063],
              [67.36748504600007, 24.563709259000063],
              [67.360992432000046, 24.56057357800006],
              [67.352088929000047, 24.555061340000066],
              [67.336090088000049, 24.549940109000033],
              [67.323348999000075, 24.549100876000068],
              [67.315185547000056, 24.553630829000042],
              [67.309112549000076, 24.551549911000052],
              [67.305465698000035, 24.551427841000077],
              [67.304023743000073, 24.553258896000045],
              [67.305526733000079, 24.555604935000076],
              [67.314598083000078, 24.556951523000066],
              [67.315208435000045, 24.55885124200006],
              [67.299644470000032, 24.577119827000047],
              [67.299011230000076, 24.581483841000079],
              [67.300552368000069, 24.582996368000067],
              [67.305091858000083, 24.583564758000023],
              [67.315460205000079, 24.575349808000055],
              [67.320236206000061, 24.575717926000038],
              [67.323310852000077, 24.578742981000062],
              [67.323478698000031, 24.579549789000055],
              [67.323890686000084, 24.581476212000041],
              [67.321571350000056, 24.588079453000034],
              [67.323066711000081, 24.590633392000029],
              [67.333198547000052, 24.588254929000072],
              [67.336830139000028, 24.588792801000068],
              [67.338134766000053, 24.590505600000029],
              [67.339956876000031, 24.595898851000072],
              [67.340988159000062, 24.598951340000042],
              [67.349792480000076, 24.607173919000047],
              [67.355888367000034, 24.608837128000062],
              [67.366050720000032, 24.605831146000071],
              [67.370986938000044, 24.608079910000072],
              [67.376708984000061, 24.613487244000055],
              [67.379898071000071, 24.619436264000058],
              [67.380882263000046, 24.634918213000049],
              [67.383743286000083, 24.637723923000067],
              [67.390121460000046, 24.638141632000043],
              [67.398887634000062, 24.635713577000047],
              [67.406875610000043, 24.635765076000041],
              [67.412178040000072, 24.640321732000075],
              [67.41433715800008, 24.649368286000026],
              [67.419387817000029, 24.648696899000072],
              [67.434082031000059, 24.640613556000062],
              [67.440483093000068, 24.64040374800004],
              [67.446533203000058, 24.643312454000068],
              [67.453208923000034, 24.65375709500006],
              [67.452301025000054, 24.659572601000036],
              [67.44385528600003, 24.64760971100003],
              [67.441558838000049, 24.647953033000078],
              [67.438049316000047, 24.655982971000071],
              [67.431495667000036, 24.660154343000045],
              [67.420272827000076, 24.672735214000056],
              [67.434387207000043, 24.685716629000069],
              [67.436279297000056, 24.689954758000056],
              [67.43587493900003, 24.694324493000067],
              [67.432533264000028, 24.698183060000076],
              [67.428825378000056, 24.699525833000052],
              [67.415473938000048, 24.702014923000036],
              [67.404113770000038, 24.700601578000033],
              [67.393218994000051, 24.704631805000076],
              [67.391464233000079, 24.708541870000033],
              [67.392791748000036, 24.715475082000069],
              [67.391357422000056, 24.717098236000027],
              [67.387222290000068, 24.717588425000031],
              [67.385169983000083, 24.723367691000078],
              [67.382125854000037, 24.725355149000052],
              [67.371429443000068, 24.724170685000047],
              [67.368385315000069, 24.726158142000031],
              [67.36517333900008, 24.732107162000034],
              [67.357711792000032, 24.736038208000025],
              [67.355926514000032, 24.740573883000025],
              [67.351516723000032, 24.742307663000076],
              [67.34955596900005, 24.74579238900003],
              [67.350891113000046, 24.752307892000033],
              [67.349334717000033, 24.762905121000074],
              [67.354606628000056, 24.768297195000059],
              [67.364830017000031, 24.769886017000033],
              [67.374412536000079, 24.77604675300006],
              [67.380569458000082, 24.776456833000054],
              [67.393356323000035, 24.771028519000026],
              [67.397926331000065, 24.770969391000051],
              [67.409706116000052, 24.773649216000024],
              [67.426574707000043, 24.768976212000041],
              [67.43356323200004, 24.771497726000064],
              [67.433525085000042, 24.772541046000072],
              [67.44275665300006, 24.775970459000064],
              [67.452812195000035, 24.776084900000058],
              [67.456130982000047, 24.773059845000034],
              [67.456253052000079, 24.769931793000069],
              [67.460594178000065, 24.76401710500005],
              [67.462005615000066, 24.756963729000063],
              [67.465759277000075, 24.754579544000023],
              [67.468070984000065, 24.753818512000066],
              [67.470863343000076, 24.758501053000032],
              [67.469367981000062, 24.761793137000041],
              [67.459701538000047, 24.769416809000063],
              [67.459350586000028, 24.772537232000047],
              [67.463150025000061, 24.774747848000061],
              [67.463729858000079, 24.777479172000028],
              [67.460705813000061, 24.780104782000024],
              [67.449142456000061, 24.782230377000076],
              [67.443901062000066, 24.781852722000053],
              [67.443763109000031, 24.783323798000026],
              [67.44366552300005, 24.784364423000056],
              [67.443631024000069, 24.784732304000045],
              [67.443454146000079, 24.78661846600005],
              [67.442111491000048, 24.787558588000024],
              [67.440354605000039, 24.789457287000062],
              [67.439682153000035, 24.791518518000032],
              [67.439543576000062, 24.792135078000058],
              [67.43939758700003, 24.792784620000077],
              [67.439509079000061, 24.79350200500005],
              [67.439532899000028, 24.793655275000049],
              [67.439707271000032, 24.794777259000057],
              [67.440223739000032, 24.795403007000061],
              [67.440636901000062, 24.796434563000048],
              [67.440653522000048, 24.796965915000044],
              [67.440688225000031, 24.798075361000031],
              [67.440274453000029, 24.799997340000061],
              [67.440118681000058, 24.802200686000049],
              [67.440351015000033, 24.802950750000036],
              [67.440919102000066, 24.804169342000023],
              [67.440893154000037, 24.804684891000079],
              [67.440582834000054, 24.806161277000058],
              [67.439626255000064, 24.808326841000053],
              [67.439135484000076, 24.809779552000066],
              [67.438851007000039, 24.810412494000047],
              [67.438644448000048, 24.810482706000073],
              [67.438618321000035, 24.811467028000038],
              [67.438534708000077, 24.813132559000053],
              [67.438487031000079, 24.814082261000067],
              [67.438410999000041, 24.815596772000049],
              [67.438177955000072, 24.820459827000036],
              [67.437955415000033, 24.823673571000029],
              [67.437762701000054, 24.826456582000048],
              [67.437812866000058, 24.829633555000044],
              [67.437780749000069, 24.830240883000045],
              [67.43775467200004, 24.830733998000028],
              [67.437710038000034, 24.83157802900007],
              [67.437812883000049, 24.832444898000062],
              [67.43826922900007, 24.833272025000042],
              [67.438284771000042, 24.833422983000048],
              [67.438315914000043, 24.833564509000041],
              [67.438315896000063, 24.833611102000077],
              [67.438357468000049, 24.833667728000023],
              [67.438388624000083, 24.833766798000056],
              [67.438414586000079, 24.833851712000069],
              [67.438367766000056, 24.833931880000023],
              [67.438310555000044, 24.833988464000072],
              [67.438253343000042, 24.83403561700004],
              [67.438237708000031, 24.834129957000073],
              [67.438268864000065, 24.834243177000076],
              [67.438268831000073, 24.834328086000028],
              [67.438326010000083, 24.834365830000024],
              [67.438440367000055, 24.834413009000059],
              [67.438471546000073, 24.834446028000059],
              [67.43849231400003, 24.834512061000055],
              [67.438497491000078, 24.834568660000059],
              [67.438409103000083, 24.834611109000036],
              [67.438289516000054, 24.83465355900006],
              [67.43823748300008, 24.834743193000065],
              [67.438263451000068, 24.834832810000023],
              [67.438284225000075, 24.834903557000075],
              [67.438315387000046, 24.835007315000041],
              [67.438320211000075, 24.835008772000037],
              [67.438408967000044, 24.835035564000066],
              [67.438507705000063, 24.835129830000028],
              [67.438512875000072, 24.835205285000029],
              [67.43841408600008, 24.835247804000062],
              [67.438278920000073, 24.835184453000068],
              [67.438237279000077, 24.835283505000064],
              [67.438221661000057, 24.835349541000028],
              [67.438185249000071, 24.835448585000051],
              [67.438180026000055, 24.835580644000061],
              [67.438180025000065, 24.835585360000039],
              [67.438226789000055, 24.835703268000032],
              [67.438361932000078, 24.835811715000034],
              [67.438517822000051, 24.835967279000045],
              [67.438590511000029, 24.836118202000023],
              [67.438658006000082, 24.836264410000069],
              [67.438642386000083, 24.836332560000074],
              [67.438715125000044, 24.836370295000052],
              [67.438767059000043, 24.836445756000046],
              [67.438689089000036, 24.836502345000042],
              [67.438683864000041, 24.836577797000075],
              [67.438756597000065, 24.836624963000077],
              [67.438777349000077, 24.836714562000054],
              [67.438751334000074, 24.836804158000064],
              [67.438683758000082, 24.836860744000035],
              [67.438714904000051, 24.836950344000059],
              [67.438818807000075, 24.837016364000078],
              [67.438901904000033, 24.837124816000028],
              [67.438952630000074, 24.837173042000074],
              [67.438917395000033, 24.837292277000074],
              [67.438854459000083, 24.838002991000053],
              [67.438927693000039, 24.838296704000072],
              [67.438967904000037, 24.83845797500004],
              [67.438977674000057, 24.838477377000061],
              [67.439046974000064, 24.838614987000028],
              [67.442967696000039, 24.838166768000065],
              [67.447628000000066, 24.837634000000037],
              [67.45024200000006, 24.837372000000073],
              [67.451948000000073, 24.837094000000036],
              [67.453028000000074, 24.836686000000043],
              [67.453149963000044, 24.836622598000076],
              [67.458368000000064, 24.83391000000006],
              [67.45915464400008, 24.83350422500007],
              [67.464622000000077, 24.830684000000076],
              [67.466996589000075, 24.829436625000028],
              [67.467112000000043, 24.829376000000025],
              [67.474346000000082, 24.825644000000068],
              [67.476020000000062, 24.824752000000046],
              [67.479343113000084, 24.823029750000046],
              [67.481110130000047, 24.822113969000043],
              [67.481550000000084, 24.821886000000063],
              [67.485072000000059, 24.820036000000073],
              [67.486395950000031, 24.81934900400006],
              [67.486654275000035, 24.819214960000068],
              [67.488206972000057, 24.81840926700005],
              [67.489358000000038, 24.81781200000006],
              [67.490199672000074, 24.817362244000037],
              [67.49104600000004, 24.816910000000064],
              [67.491880000000037, 24.816494000000034],
              [67.492310879000058, 24.816269001000023],
              [67.499452000000076, 24.81254000000007],
              [67.499986000000035, 24.812272000000064],
              [67.501046000000031, 24.811704000000077],
              [67.50314706000006, 24.810620509000046],
              [67.503532000000064, 24.810422000000074],
              [67.504786231000082, 24.80944915300006],
              [67.505832000000055, 24.80863800000003],
              [67.507042000000069, 24.80765400000007],
              [67.508658000000082, 24.806244000000049],
              [67.511258000000055, 24.804030000000068],
              [67.513276000000076, 24.802266000000031],
              [67.514636000000053, 24.801022000000046],
              [67.515175051000028, 24.800540254000055],
              [67.515746000000036, 24.800030000000049],
              [67.516988000000083, 24.79890000000006],
              [67.517640235000044, 24.798291419000066],
              [67.547388000000069, 24.789477000000034],
              [67.564747713000031, 24.789823799000033],
              [67.579875000000072, 24.790126000000043],
              [67.579875000000072, 24.790149617000054],
              [67.579875000000072, 24.79106624700006],
              [67.579875001000062, 24.798592977000055],
              [67.579875000000072, 24.80191700000006],
              [67.579875000000072, 24.817416000000037],
              [67.566464581000048, 24.849309642000037],
              [67.559455829000058, 24.861203143000068],
              [67.549926408000033, 24.877374094000061],
              [67.544923198000049, 24.885950273000049],
              [67.530833468000083, 24.912785217000078],
              [67.525971790000028, 24.931439469000054],
              [67.521537326000043, 24.952132371000062],
              [67.521537318000071, 24.952132407000079],
              [67.52074900000008, 24.95581100000004],
              [67.52076893800006, 24.956548487000077],
              [67.520780099000035, 24.95696130400006],
              [67.521388936000051, 24.979481361000069],
              [67.527896000000055, 24.978552000000036],
              [67.558434000000034, 24.968806000000029],
              [67.59027100000003, 24.972054000000071],
              [67.601572090000047, 24.984006992000047],
              [67.601593464000075, 24.984029599000053],
              [67.624058000000048, 25.007790000000057],
              [67.643329065000046, 25.013875723000069],
              [67.648748000000069, 25.015587000000039],
              [67.665790600000037, 25.011249092000071],
              [67.684484000000054, 25.00649100000004],
              [67.709824000000083, 25.000643000000025],
              [67.715704215000073, 25.011983875000055],
              [67.71678363500007, 25.01406570000006],
              [67.716798580000045, 25.014094524000029],
              [67.718920000000082, 25.018186000000071],
              [67.727367000000072, 25.045475000000067],
              [67.745560000000069, 25.069516000000078],
              [67.771550000000047, 25.082511000000068],
              [67.787957486000039, 25.086371731000042],
              [67.804686000000061, 25.09030800000005],
              [67.818331000000057, 25.126044000000036],
              [67.819233018000034, 25.134161326000026],
              [67.819955585000059, 25.140663762000031],
              [67.820447599000033, 25.14509143500004],
              [67.82158000000004, 25.155282000000057],
              [67.828077000000064, 25.189718000000028],
              [67.822230000000047, 25.213759000000039],
              [67.829887952000036, 25.22004970100005],
              [67.840422000000046, 25.228703000000053],
              [67.846076323000034, 25.24131561400003],
              [67.848869000000036, 25.247545000000059],
              [67.858615000000043, 25.265738000000056],
              [67.87226000000004, 25.266388000000063],
              [67.880706000000032, 25.278083000000038],
              [67.889153000000078, 25.296926000000042],
              [67.897565440000051, 25.299190830000043],
              [67.905596599000035, 25.301353010000071],
              [67.90604600000006, 25.301474000000042],
              [67.909295000000043, 25.315119000000038],
              [67.911894000000075, 25.333961000000045],
              [67.908040443000061, 25.348678364000023],
              [67.909295000000043, 25.360601000000031],
              [67.930737000000079, 25.375545000000045],
              [67.933985000000064, 25.389189000000044],
              [67.936343320000049, 25.395175383000037],
              [67.940355519000036, 25.405359987000054],
              [67.941939983000054, 25.409382007000033],
              [67.942432000000053, 25.410631000000024],
              [67.961246519000042, 25.407494925000037],
              [67.961924000000067, 25.407382000000041],
              [67.979047115000071, 25.425774000000047],
              [67.979467000000056, 25.426225000000045],
              [67.980667574000051, 25.427673024000057],
              [67.981811194000045, 25.42903803300004],
              [67.992462000000046, 25.441819000000066],
              [68.003419051000037, 25.445139024000071],
              [68.01347776700004, 25.44818685000007],
              [68.013904000000082, 25.448316000000034],
              [68.035631041000045, 25.438715788000025],
              [68.052117656000064, 25.431347352000046],
              [68.065704559000039, 25.425233176000063],
              [68.080132110000079, 25.418740704000072],
              [68.086079450000057, 25.414030561000061],
              [68.086202528000058, 25.413927517000047],
            ],
          ],
        ],
      },
    },
  ],
};



/*===================================District Heat Map============================================*/
