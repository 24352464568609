import React, { useEffect } from "react";
import usePageMeta from "../../hooks/usePageMeta";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../redux/reducers";
import { getFormatedDate } from "../../Utility";

const MonitoringVisitReportView = () => {
  const { setMeta } = usePageMeta();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data, created, current } = useSelector(
    (state) => state.records
  );

  useEffect(() => {
    setMeta({ title: "Monitoring Visit Report View" });
  }, []);

  useEffect(() => {
    getMonitoringVisitReportView();
  }, []);

  const getMonitoringVisitReportView = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/MonitoringVisitReport/${id}`,
        CurrentReducerKey: "monitoringVisitReportView",
      },
    });
  };

  return (
    <div className="row justify-content-center">
      <div className="col-lg-8">
        <h4 className="pt-1 mb-3 text-center">Monitoring Visit Report</h4>
        <div className="row bg-white shadow pt-3 pb-3 mt-5 rounded p-2 border border-warning">
          <div className="col-sm-3 col-6 mt-2">
            <h6>Project Name</h6>
          </div>
          <div className="col-sm-9 col-6">{data?.project?.name}</div>
          <div className="col-sm-3 col-6 mt-2">
            <h6>Reporting Person</h6>
          </div>
          <div className="col-sm-9 col-6">{data?.reportingPerson?.name}</div>
          <div className="col-sm-3 col-6 mt-2">
            <h6>Date of Monitoring</h6>
          </div>
          <div className="col-sm-9 col-6">
            {getFormatedDate(data?.dateOfMonitoring)}
          </div>
          <div className="col-sm-3 col-6 mt-2">
            <h6>Location of Visit</h6>
          </div>
          <div className="col-sm-9 col-6">{data?.locationOfVisit}</div>
        </div>
      </div>
    </div>
  );
};

export default MonitoringVisitReportView;
