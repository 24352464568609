import TARGET_TYPE from "../enums/TARGET_TYPE";

const targetTypes = [
    {
        label: 'Beneficiaries',
        value: TARGET_TYPE.BENEFICIARIES
    },
    {
        label: 'Amount',
        value: TARGET_TYPE.AMOUNT
    }
]
export default targetTypes;