import React from "react";
import * as Icons from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ToolTip from "../tooltip/Tooltip";
import * as IconsNew from "react-bootstrap-icons";

const ListHeader = ({ children, options = [], ...otherProps }) => {
  return (
    <div className="d-flex">
      {children}
      {options.map(({ url, id, name, label = "Add" }) => {
        const { [name]: Icon } = Icons;
        return (
          // <ToolTip label={label} key={id}>
          //   <Link to={url} key={id}>
          //     <Icon className="action-icons" />
          //   </Link>
          // </ToolTip>
          <Link to={url} key={id}>
          <div className="add-filter-btns d-flex align-items-center">
            <span>
              <IconsNew.Plus size={20} />
            </span>
            <span className="ml-1">
              ADD NEW
            </span>
          </div>
        </Link>
        );
      })}
    </div>
  );
};

export default ListHeader;
