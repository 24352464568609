export const complaintAppealFields = [
  {
    "name": "appeal",
    "label": "Appeal",
    "placeholder": "Enter Appeal",
    "inputType": "textarea",
    "inForm": true,
    "wrapperClass": "col-12"
  },
  {
    "inputType": "button",
    "buttonType": "submit",
    "children": "Submit",
    "inForm": true,
    "wrapperClass": "mr-3 btn-wrapper position-right"
  }
]