import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { FormStepper, Step } from "../../components/form-stepper/FormStepper";
import Form from "../../components/form/Form";
import Loader from "../../components/loader/loader";
import {
  clearForm,
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
  resetData,
} from "../../redux/reducers";
import { getFieldsByCategory } from "../../utils/util";
import complaintFields from "../../assets/fields/complaintFields";

const AddUpdateComplaint = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, created, data } = useSelector((state) => state.records);
  const { values, validations } = useSelector((state) => state.form);
  const formValues = values;

  let canUpdate = false;
  if (id) canUpdate = true;

  useEffect(() => {
    if (created && !canUpdate) {
      navigate("/complaints");
    }
  }, [created]);

  //update project actions
  useEffect(() => {
    if (id)
      dispatch({ type: getRecords.type, payload: { url: `/complaint/${id}` } });
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [id]);

  //mask data on single project data retrieval
  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...data } });
    }
  }, [data]);

  function addRecord() {
    dispatch({
      type: createRecord.type,
      payload: { ...formValues, url: "/Complaint" },
    });
  }

  function updateRecord() {
    dispatch({
      type: putRecord.type,
      payload: { ...formValues, url: "/Complaint" },
    });
  }

  return (
    <div className="">
      <Loader loading={loading} />
      <FormStepper
        initialStep={0}
        onSubmit={() => (canUpdate ? updateRecord() : addRecord())}
        fields={complaintFields}
        data={formValues}
      >
        <Step key="complainantInfo" title="Complaint Info">
          <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
          <Form
            formData={getFieldsByCategory(complaintFields, "complainant")}
            withValidation={true}
            formValidation={validations}
          />
          </div>
        </Step>
        <Step key="complaintInfo" title="About Complaint">
          <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
          <Form
            formData={getFieldsByCategory(complaintFields, "complain-info")}
            withValidation={false}
          />
          </div>
        </Step>
      </FormStepper>
    </div>
  );
};

export default AddUpdateComplaint;
