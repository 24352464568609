import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";

import Table from "../../components/table/Table";
import Loader from "../../components/loader/loader";
import MonitoringVisitColumns, { MonitoringVisitColumnsForExcel } from "../../assets/columns/MonitoringVisitColumns";
import DeleteRow from "../DeleteRow";
import { getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";
import ToolTip from "../../components/tooltip/Tooltip";
import usePageMeta from "../../hooks/usePageMeta";
import Searchbar from "../../serachbar/Searchbar";
import "./MonitoringVisitReport.scss";

const MonitoringVisitReports = () => {
  const columns = [
    ...MonitoringVisitColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          {row.canView && (
            <ToolTip label="Details">
              <Link to={`/projects/${row?.id}`}>
                <Icon.EyeFill className="text-primary mr-2" />
              </Link>
            </ToolTip>
          )}
          <DeleteRow row={row} url="/MonitoringVisitReport" />
        </>
      ),
    },
  ];

  const { setMeta } = usePageMeta();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.records);
  const { items = [] } = data;

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: getRecords.type,
        payload: {
          url: "/Project/GetAll",
          params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
        },
      });
    }, 400);
  }, []);

  useEffect(() => {
    setMeta({ title: "Project List (Monitoring Visit Report)" });
  }, []);

  return (
    <>
      <div className="flex justify-content-between align-items-center mb-3">
        <Searchbar
          searchListParams={{
            url: "/Project/GetAll",
            params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
          }}
          searchFieldName="Title"
          placeholder="Search Project"
        />
      </div>

      <div className="projectTable">
        <Loader loading={loading} />
        <Table columns={columns} customColumnNames={MonitoringVisitColumnsForExcel} data={items && items} />
      </div>
    </>
  );
};

export default MonitoringVisitReports;
