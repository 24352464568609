import { Link } from "react-router-dom";
import TableTitle from "../../components/table-title/TableTitle";
import { getFormatedDate } from "../../Utility";

const DebriefColumns = [
  // {
  //   name: "Monitoring Visit Report",
  //   selector: (row) => row?.monitoringVisitReport?.name,
  // },
  {
    name: "Key Findings",
    selector: (row) => (
      <>
        <Link to={`/monitoring-visit-report/key-finidings/action-point/${row?.id}`}>
          <TableTitle title={row?.keyFindings} />
        </Link>
      </>
    ),
    wrap: true,
    maxWidth: "200px",
  },
  // {
  //   name: "Parent DeBrief",
  //   selector: (row) => row?.parentDebrief?.deBriefMemo,
  // },
  {
    name: "De-brief Memo",
    selector: (row) => row?.debriefMemo,
    wrap: true,
    minWidth: "350px",
  },
  // {
  //   name: "Response of BM/PM",
  //   selector: (row) => row?.response,
  // },
  // {
  //   name: "Action Point",
  //   selector: (row) => row?.action,
  // },
  // {
  //   name: "Timeline",
  //   selector: (row) => row?.timeline,
  // },
  // {
  //   name: "Challenge",
  //   selector: (row) => row?.keyChallenge,
  // },
  {
    name: "Followup Date",
    selector: (row) => getFormatedDate(row?.dateOfDeBrief),
  },
  // {
  //   name: "Status",
  //   selector: (row) => row?.status,
  // },
];

export default DebriefColumns;

export const DebriefColumnsForExcel = {
  "Key Findings": 'keyFindings',
  "De-brief Memo": "debriefMemo",
  "Followup Date": "dateOfDeBrief",
}
