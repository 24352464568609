import logframeIcon from "./../../assets/images/logframe.svg";
import activityIcon from "./../../assets/images/activity.svg";
import assumptionIcon from "./../../assets/images/assumption_monitoring.svg";
import benifictiaryIcon from "./../../assets/images/beneficiaries.svg";
import projectcomplaintsIcon from "./../../assets/images/project _complaints.svg";
import projecteditIcon from "./../../assets/images/edit_project.svg";
import targetIcon from "./../../assets/images/target.svg";
import projectPhotoIcon from "./../../assets/images/projectPhotoIcon.svg";


export const projectMenu = [
  {
    thumbnail: logframeIcon,
    label: "Logframe",
    link: "/projects/logframe",
  },
  {
    thumbnail: benifictiaryIcon,
    label: "Beneficiaries",
    link: "/beneficiaries",
  },
  {
    thumbnail: targetIcon,
    label: "Facility",
    link: "/projects/projectFacilities",
  },
  // {
  //   thumbnail: activityIcon,
  //   label: "Activity",
  //   link: "/activity",
  // },
  {
    thumbnail: assumptionIcon,
    label: "Visit Reports",
    link: "/projects/monitoringVisitReport",
  },
  // {
  //   thumbnail: projectPhotoIcon,
  //   label: "Project Photos",
  //   link: "/targets",
  // },
  {
    thumbnail: projectPhotoIcon,
    label: "Project Photos",
    link: "/projects/albums",
  },
  {
    thumbnail: projectcomplaintsIcon,
    label: "Project Complaints",
    link: "/projects/projectComplaint",
  },
  {
    thumbnail: projecteditIcon,
    label: "Edit Project",
    link: "/projects/update",
  },
  
];
