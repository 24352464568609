import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import logo from "./../../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import Form from "./../../components/form/Form";
import { loginFields } from "../../assets/fields/loginFields";
import { clearForm, createRecord } from "../../redux/reducers";
import Loader from "../loader/loader";
import careImg from '../../assets/images/care_img.png'

//
const LoginSignupForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, created } = useSelector((state) => state.records);
  useEffect(() => {
    if (created) navigate("/projects");
    return () => {
      dispatch({ type: "records/setRecords", payload: { created: false } });
    };
  }, [created]);

  const handleSubmit = (values) => {
    dispatch({
      type: createRecord.type,
      payload: { ...values, url: "/Account/Login" },
    });
  };

  useEffect(() => {
    dispatch({ type: clearForm.type });
    return () => {
      dispatch({ type: "records/resetData" });
    };
  }, [loginFields]);

  return (
    <div
      className="d-flex justify-content-center align-items-center bg-light"
      style={{ minHeight: "100vh" }}
    >
      <div
        style={{ minHeight: "75vh" }}
        className="d-md-flex shadow align-items-center justify-content-center bg-white col-8"
      >
        <Loader loading={loading} />
        <div className="d-flex flex-column align-items-center justify-content-center col-6 mr-4">
          <div className="d-inline-flex justify-content-center mx-3 mb-5 mt-3 ">
            <img className="img-fluid" src={logo} alt="" />
          </div>
    
          <div className="container col-10">
            <Form
              formData={loginFields}
              onSubmit={(params) => handleSubmit(params)}
            />
          </div>
          <div className="mt-4">
            <Link to="/forget" className="text-primary">
              Forget Password?
            </Link>
            {/* <p className="mt-2">
          Not a member?{" "}
          <Link to="/signup" className="text-primary">
          Register Now
          </Link>
        </p> */}
          </div>
        </div>
        <div className="d-inline-flex flex-column align-items-center pr-1 justify-content-center col-6">
          <img 
          className="h-100 w-auto object-cover img-fluid ml-3"
          src={careImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default LoginSignupForm;
