import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { OverlayTrigger, Popover } from "react-bootstrap";
import { createRecord, getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";
import { truncateText } from "../../utils/util";
import ToolTip from "../tooltip/Tooltip";
import "./Notifications.scss";

const Notifications = () => {
  const dispatch = useDispatch();
  const { created } = useSelector((state) => state.records);
  const notifications = useSelector(
    (state) => state.records?.current?.headerNotification?.data?.items
  );

  let items = [];
  if (notifications) {
    items = [...notifications]?.sort((a, b) =>
      a.isRead === b.isRead ? 0 : a.isRead ? 1 : -1
    );
  }

  useEffect(() => {
    if (created) getNotifications();
  }, [created]);

  const getNotifications = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Notification/GetAll`,
        params: { OrderDir: ORDER_DIRECTION.DESC },
        appendCurrentReducerKey: "headerNotification",
      },
    });
  };

  const markAsRead = (id) => {
    dispatch({
      type: createRecord.type,
      payload: {
        url: `/Notification/MarkAsRead?id=${id}`,
      },
    });
  };

  const notificationTitle = (title, id, type) => (
    <>
      {type === "Complaint" ? (
        <Link to={`/complaints/${id}`} className=" cursor-pointer">
          {truncateText(title, 32)}
        </Link>
      ) : (
        truncateText(title, 32)
      )}
    </>
  );

  return (
    <div className="notif-wrapper">
      <div className="icon cursor-pointer">
        <OverlayTrigger
          trigger="click"
          onEnter={() => getNotifications()}
          rootClose
          placement="bottom"
          overlay={
            <Popover
              id={`popover-contained popover-positioned-bottom`}
              style={{ minWidth: 280 }}
            >
              <Popover.Body className="h-25">
                <p className="mb-1 border-bottom text-center p-3">
                  Recent Notifications
                </p>

                {items &&
                  items.map(
                    ({ id, subject, isRead, entityType }, index) =>
                      index < 5 && (
                        <div
                          style={{
                            backgroundColor: !isRead
                              ? "rgb(228 112 30 / 7%)"
                              : "",
                          }}
                          className="custom-notification-bar shadow"
                          key={id}
                        >
                          <div className="notification-status">
                            {isRead ? (
                              <Icon.EnvelopeOpen color="#E4701E" />
                            ) : (
                              <Icon.EnvelopeFill
                                color="#E4701E"
                                onClick={() => markAsRead(id)}
                              />
                            )}
                          </div>
                          <div
                            className={`notification-text ${
                              !isRead && "font-weight-bold"
                            }`}
                          >
                            {notificationTitle(subject, id, entityType)}
                          </div>
                        </div>
                      )
                  )}

                <div className="text-center">
                  <Link to="/notifications" style={{ fontSize: 12 }}>
                    Show All
                  </Link>
                </div>
              </Popover.Body>
            </Popover>
          }
        >
          <Icon.BellFill color="#E4701E" />
        </OverlayTrigger>
        {/* <span className="badge badge-pill bg-danger text-light">New</span> */}
      </div>
    </div>
  );
};

export default Notifications;
