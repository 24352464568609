import React, { useEffect, useState } from "react";
import "./Expandable.scss";
import * as Icon from "react-bootstrap-icons";
import Table from "../table/Table";

const Expandable = ({
  title,
  content,
  addIndicator,
  totalYears,
  setObjectiveYear,
  setObjective,
  item,
  handleIndicatorList,
  indicatorList,
  toggleObjectiveModal,
  customColumn,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setObjective({ id: item?.id, name: item?.objectiveTitle });
    setIsOpen(!isOpen);
    handleIndicatorList();
  };

  const openIndicator = () => {
    addIndicator();
    setObjectiveYear(totalYears);
    setObjective({ id: item?.id, name: item?.objectiveTitle });
  };

  return (
    <div className="accordion">
      <div className="accordion-header">
        <button className="openCloseBtn" onClick={handleClick}>
          {isOpen ? "-" : "+"}
        </button>
        <p className="m-0 mt-1" style={{ flex: 1 }}>
          <b>{title} </b>
        </p>

        <div>
          <Icon.PencilSquare className="m-2" color="#67748E"
            onClick={() => toggleObjectiveModal(item)}
          />
          <button
            className="btn btn-primary btn-sm ml-auto mb-2"
            onClick={() => openIndicator()}
          >
            Add
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <Table columns={content} customColumnNames={customColumn} data={indicatorList} />
        </div>
      )}
    </div>
  );
};

export default Expandable;
