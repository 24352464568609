export const smartIndicatorFields = [
  {
    label: "Indicator Title",
    placeholder: "Enter Indicator",
    inputType: "text",
    inForm: true,
    name: "indicator",
    wrapperClass: "col-md-12",
  },
  {
    label: "Indicator Type",
    placeholder: "Select Indicator Type",
    name: "indicatorType",
    inputType: "async-dropdown",
    options: [
      {
        label: "Output",
        value: "Output",
      },
      {
        label: "Outcome",
        value: "Outcome",
      },
      {
        label: "Impact",
        value: "Impact",
      },
    ],
    searchAttrib: "Search.value",
    id: "indicatorType",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  {
    label: "Target Type",
    placeholder: "Select Target Type",
    name: "targetTypeLookup",
    inputType: "async-dropdown",
    url: "/TargetType/GetAll ",
    searchAttrib: "Search.value",
    id: "targetTypeLookup",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    label: "Baseline Value",
    placeholder: "Add Baseline Value",
    inputType: "text",
    type: "number",
    inForm: true,
    name: "baselineValue",
    wrapperClass: "col-md-6",
  },
  {
    label: "Location",
    inForm: true,
    name: "siLocations",
    placeholder: "Select Location",
    inputType: "async-dropdown",
    url: "",
    // options: [],
    searchAttrib: "Search.value",
    id: "siLocations",
    labelAttribute: "label",
    valueAttribute: "value",
    onChangeMethod: "dropdown",
    inForm: true,
    wrapperClass: "col-md-6 ",
    isMulti: true,
  },
  {
    label: "Thematic Area",
    inForm: true,
    name: "thematicArea",
    placeholder: "Select Thematic Area",
    inputType: "async-dropdown",
    url: "",
    // options: [],
    searchAttrib: "Search.value",
    id: "thematicArea",
    labelAttribute: "label",
    valueAttribute: "value",
    onChangeMethod: "dropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    label: "Mean Of Verifications",
    inForm: true,
    name: "siMeanOfVerifications",
    placeholder: "Select Mean Of Verifications",
    inputType: "async-dropdown",
    url: "/MeansOfVerification/GetAll",
    searchAttrib: "Search.value",
    id: "siMeanOfVerifications",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: true,
  },
  {
    label: "Data Collection Tools",
    inForm: true,
    name: "siDataCollectionTools",
    placeholder: "Select Data Collection Tools",
    inputType: "async-dropdown",
    url: "/DataCollectionTool/GetAll",
    searchAttrib: "Search.value",
    id: "siDataCollectionTools",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: true,
  },
  {
    label: "Frequency",
    placeholder: "Select Frequency of Verification",
    name: "periodicity",
    inputType: "async-dropdown",
    url: "/Periodicity/GetAll ",
    searchAttrib: "Search.value",
    id: "periodicity",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    label: "Responsible",
    placeholder: "Select Responsible Entity",
    name: "responsiblePerson",
    inputType: "async-dropdown",
    url: "/UserManagement/GetAll?DisablePagination=true",
    searchAttrib: "Search.value",
    id: "responsiblePerson",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    label: "Household Members to be Considered as Beneficiaries",
    placeholder: "Select Status",
    name: "hhIndicator",
    inputType: "async-dropdown",
    options: [
      {
        label: "True",
        value: "true",
      },
      {
        label: "False",
        value: "false",
      },
    ],
    searchAttrib: "Search.value",
    id: "siHouseholdToBeneficiaries",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  {
    inputType: "dataContainer",
    inForm: true,
    wrapperClass: "col-md-12",
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "btn-wrapper position-right mr-3 ",
    // className: "btn btn-primary py-1 float-right",
  },
];
