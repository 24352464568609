import { useParams } from "react-router";
import usePageMeta from "../../hooks/usePageMeta";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getRecords } from "../../redux/reducers";
import { getFacebookFormattedDate, getFormatedDate } from "../../Utility";

const BeneficiaryView = () => {
  const { setMeta } = usePageMeta();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data, created, current } = useSelector(
    (state) => state.records
  );

  useEffect(() => {
    setMeta({ title: "Beneficiary View" });
  }, []);

  useEffect(() => {
    getBeneficiary();
  }, []);

  const getBeneficiary = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Beneficiary/${id}`,
        CurrentReducerKey: "beneficiaryView",
      },
    });
  };

  return (
    <>
      <h3 className="pt-1 mb-3 text-center">Beneficiary View</h3>
      <div className="container my-3 bg-light pt-3 pb-3 mt-5 shadow rounded p-5 border border-warning">
        <div className="row">
          <div className="col-lg-6">
            <h4
              style={{ backgroundColor: "#E4701e" }}
              className="pt-1 mb-3 text-center text-white px-2 rounded py-2"
            >
              Beneficiary Detail
            </h4>
            <div className="row px-2">
              <div className="col-sm-3 col-6 mt-2">
                <h6>Project Name</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">{data?.project?.name}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Beneficiary Name</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">{data?.name}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Gender</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">{data?.gender}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Age</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">{data?.age}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Cell no</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">{data?.phoneNo}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>CNIC</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">{data?.cnic}</div>
              <div className="col-sm-3 col-6 mt-2">
                <h6>Accomplishment Date</h6>
              </div>
              <div className="col-sm-9 col-6 mt-2">
                {getFacebookFormattedDate(data?.accomplishmentDate)}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-md-12 mb-4">
                <h4
                  style={{ backgroundColor: "#E4701e" }}
                  className="pt-1 mb-3 text-center text-white px-2 rounded py-2"
                >
                  Household Info
                </h4>
                <div className="row px-2">
                  <div className="col-sm-4 col-6 mt-2">
                    <h6>Head of Household Name</h6>
                  </div>
                  <div className="col-md-8 col-6 mt-2">
                    {data?.headOfHouseHoldName}
                  </div>
                  <div className="col-sm-4 col-6 mt-2">
                    <h6>Relationship with Beneficiary</h6>
                  </div>
                  <div className="col-md-8 col-6 mt-2">
                    {data?.relationshipWithBeneficiary}
                  </div>
                  <div className="col-sm-4 col-6 mt-2">
                    <h6>Number of Male</h6>
                  </div>
                  <div className="col-md-8 col-6 mt-2">
                    {data?.peopleInHousehold?.map((item) => item?.noOfMales)}
                  </div>
                  <div className="col-sm-4 col-6 mt-2">
                    <h6>Number of Female</h6>
                  </div>
                  <div className="col-md-8 col-6 mt-2">
                    {data?.peopleInHousehold?.map((item) => item?.noOfMales)}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <h4
                  style={{ backgroundColor: "#E4701e" }}
                  className="pt-1 mb-3 text-center text-white px-2 rounded py-2"
                >
                  Residential Info
                </h4>
                <div className="row px-2">
                  <div className="col-sm-4 col-6 mt-2">
                    <h6>Province</h6>
                  </div>
                  <div className="col-md-8 col-6 mt-2">
                    {data?.addresses?.map((item) => item?.province?.name)}
                  </div>
                  <div className="col-sm-4 col-6 mt-2">
                    <h6>District</h6>
                  </div>
                  <div className="col-md-8 col-6 mt-2">
                    {data?.addresses?.map((item) => item?.district?.name)}
                  </div>
                  <div className="col-sm-4 col-6 mt-2">
                    <h6>Tehsil</h6>
                  </div>
                  <div className="col-md-8 col-6 mt-2">
                    {data?.addresses?.map((item) => item?.tehsil?.name)}
                  </div>
                  <div className="col-sm-4 col-6 mt-2">
                    <h6>Union Council</h6>
                  </div>
                  <div className="col-md-8 col-6 mt-2">
                    {data?.addresses?.map((item) => item?.unionCouncil?.name)}
                  </div>
                  <div className="col-sm-4 col-6 mt-2">
                    <h6>Village</h6>
                  </div>
                  <div className="col-md-8 col-6 mt-2">
                    {data?.addresses?.map((item) => item?.village)}
                  </div>
                  <div className="col-sm-4 col-6 mt-2">
                    <h6>Residential Address</h6>
                  </div>
                  <div className="col-md-8 col-6 mt-2">
                    {data?.addresses?.map((item) => item?.resAddress)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneficiaryView;
