import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import RecordsTable from "../records-table/RecordsTable";
import * as Icon from "react-bootstrap-icons";
import { getRecords, putRecord } from "../../redux/reducers";
import FaIcon from "../icon/FaIcon";
import MdIcon from "../icon/MdIcon";
import Modal from "react-bootstrap/Modal";
import Form from "../form/Form";
import Loader from "../loader/loader";
import { deleteRecord, formatRecords } from "../../redux/reducers/recordSlice";
import ToolTip from "../tooltip/Tooltip";
import { canView } from "../../utils/util";

const FindValue = () => {
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);

  const { roles } = useSelector((state) => state.setting?.user?.userDetail);
  const userRoles = roles && roles.map(({ name }) => name);

  const { data, loading, created } = useSelector((state) => state.records);
  const formValues = useSelector((state) => state.form?.values);

  const { resultFields, searchFields, formFields } = useSelector(
    (state) => state?.fields
  );
  const headers = resultFields ? resultFields : [];

  let items = data.items ? data.items : [];

  const currentDefinationApiMeta =
    formFields && formFields.find(({ name }) => name == "url");

  useEffect(() => {
    if (created) {
      handleSearch();
    }
  }, [created]);

  const handleSearch = (obj = null) => {
    const Name = obj?.target?.value;
    if (!Array.isArray(searchFields)) return;
    const defUrl = searchFields.find(
      ({ name, inSearch = null }) => name == "url" && inSearch == true
    );
    dispatch({
      type: getRecords.type,
      payload: { url: defUrl?.value, params: { Name } },
    });
  };

  useEffect(() => {
    handleSearch();
  }, [searchFields]);

  const onDelete = (v) => {
    confirmAlert({
      message: "Are you sure to Delete this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDelete(v),
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => { },
      afterClose: () => { },
      onClickOutside: () => { },
      onKeypressEscape: () => { },
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleDelete = ({ id }) => {
    dispatch({
      type: deleteRecord.type,
      payload: { id, url: currentDefinationApiMeta?.value },
    });
  };

  const toggleModel = (value) => {
    dispatch({ type: formatRecords.type, payload: { ...value } });
    setModalShow(true);
  };

  const postData = () => {
    dispatch({
      type: putRecord.type,
      payload: { ...formValues, url: currentDefinationApiMeta?.value },
    });
    setModalShow(!modalShow);
  };

  return (
    <>
      <Loader loading={loading} />
      {/* table section */}
      <div className="datatable-header">
        <div className="float-right">
          <div className="dataTables_filter">
            <label>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                onChange={(e) => handleSearch(e)}
              />
            </label>
          </div>
        </div>
      </div>

      <div className="overflow-auto w-100">
        <RecordsTable
          data={items}
          header={headers}
          actionButtons={
            canView(["Admin"], userRoles)
              ?
              [
                <span onClick={(e, value) => toggleModel(value)}>
                  <ToolTip label="Edit">
                    <Icon.PencilSquare className="action-icons" />
                  </ToolTip>
                </span>,
                <span onClick={(e, v) => onDelete(v)}>
                  <ToolTip label="Delete">
                    <Icon.Trash className="action-icons" />
                  </ToolTip>
                </span>,
              ]
              :
              [
                <span onClick={(e, value) => toggleModel(value)}>
                  <ToolTip label="Edit">
                    <Icon.PencilSquare className="action-icons" />
                  </ToolTip>
                </span>,
              ]
          }
        />
      </div>

      {/* Update Record Model */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="w-100 d-flex justify-content-between"
          >
            <div>Update Record</div>
            <div>
              <MdIcon
                iconName={"MdOutlineClose"}
                action={() => setModalShow(!modalShow)}
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            formData={formFields ? [...formFields] : []}
            onSubmit={(params) => postData(params)}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FindValue;
