const uploadSmartIndicatorExcelFields = [
  {
    category: "details",
    label: "Project Title",
    placeholder: "Select Project",
    name: "project",
    inputType: "async-dropdown",
    url: "/Project/GetAll",
    searchAttrib: "Search.value",
    id: "project",
    labelAttribute: "title",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    label: "Objective Name",
    category: "details",
    placeholder: "Select Objective",
    name: "objectiveName",
    inputType: "async-dropdown",
    url: "/Objective/GetAll?DisablePagination=true",
    searchAttrib: "Search.value",
    id: "objectiveName",
    labelAttribute: "objectiveTitle",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    condition: {
      fieldName: "project",
      paramField: "Project.Id",
      action: "useValue",
    },
  },
  {
    label: "Indicator Name",
    category: "details",
    placeholder: "Select Indicator",
    name: "smartIndicator",
    inputType: "async-dropdown",
    url: "/SmartIndicator/GetAll?DisablePagination=true",
    searchAttrib: "Search.value",
    id: "smartIndicator",
    labelAttribute: "indicator",
    valueAttribute: "id",
    onChangeMethod: "dropdown-in-table",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    condition: {
      fieldName: "objectiveName",
      paramField: "Objective.Id",
      action: "useValue",
    },
  },
  {
    category: "details",
    label: "Upload File",
    placeholder: "Attachments",
    name: "attachments",
    onChangeMethod: "onFileChange",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "file",
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "btn-wrapper ml-auto mt-2",
  },
];
export default uploadSmartIndicatorExcelFields;
