import BeneficiaryColumns from "../columns/BeneficiaryColumns";

export const subActivityAchievementFields = [
  {
    label: "Numbers Achieved",
    placeholder: "Enter Numbers",
    type: "number",
    required: true,
    inForm: true,
    name: "NumbersAchieved",
    wrapperClass: "col-6",
  },
  {
    label: "Beneficiaries",
    name: "BeneficiaryIds",
    id: "BeneficiaryIds",
    inputType: "SelectableTable",
    columns: BeneficiaryColumns,
    url: "/ProjectBeneficiary/GetSubActivityAchievementBeneficiaries?ProjectId=",
    valueAttribute: "id",
    searchAttrib: "Search.value",
    onChangeMethod: "onTableSelection",
    inForm: true,
    customStyles: { maxHeight: 200 },
    wrapperClass: "col-12",
  },
  {
    label: "Date",
    inputType: "date",
    inForm: true,
    required: true,
    name: "Date",
    wrapperClass: "col-6",
  },
  {
    label: "Attachments",
    inputType: "file",
    inForm: true,
    name: "Attachments",
    wrapperClass: "col-6",
  },
  {
    label: "Comments",
    placeholder: "Enter Comments",
    inputType: "textarea",
    inForm: true,
    name: "Comments",
    wrapperClass: "col-6",
  },
  {
    type: "hidden",
    inForm: true,
    wrapperClass: "col-12",
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "mr-3 btn-wrapper position-right",
  },
];
