import { loadUserState } from "./localStorage";

export const isUserLoggedIn = () => {
  let userState = loadUserState();
  if (userState?.token) return true;
  return false;
};

export const getAuthToken = () => {
  let userState = loadUserState();
  return userState?.token;
};

export const getFieldsByCategory = (fields = [], key) => {
  return fields.filter(({ category = "" }) => category === key);
};

export const getError = (message) => {
  try {
    const messageJson =
      typeof message === "string" ? JSON.parse(message) : message;
    let error = "";
    if (typeof messageJson === "object") {
      Object.keys(messageJson).forEach((i) => {
        messageJson[i].forEach((j) => {
          error += j;
        });
      });
    } else if (typeof messageJson === "string") {
      error = message;
    } else error = "Something went wrong, please try again!";
    return error;
  } catch (error) {
    console.log("🚀 ~ file: util.js ~ line 35 ~ getError ~ error", error);
    return "Something went wrong, please try again";
  }
};

export const getIntProgressValue = (value) => {
  if (!isNaN(value)) return value;
  else if (typeof value === "string")
    return parseInt(value.replace(/[`%{\}[\]/]/gi, ""));
  // return parseInt(value.replace(/[`%\{\}\[\]\\\/]/gi, ""));
  else return 0;
};

export const getPieFormatedData = (data) => {
  let labels = [],
    values = [];
  if (!data) return { labels, values };
  Object.entries(data)
    .filter(([key, value]) => key !== "total")
    .forEach(([key, value]) => {
      labels = [...labels, key.toUpperCase()];
      values = [...values, value];
    });
  return { labels, values };
};

export const getBarChartFormattedDate = (data = []) => {
  return data.map(({ name, count }) => ({ x: name, y: count }));
};

export const getPieFormatedDataByArray = (data) => {
  let labels = [],
    values = [];
  if (!data) return { labels, values };

  data.forEach((d) => {
    let label =
      typeof Object.values(d)[0] === "number"
        ? Object.values(d)[0]
        : Object.values(d)[0].toUpperCase();
    if (label && label.length > 10) {
      label = truncateText(label, 10);
    }
    labels = [...labels, label];
    values = [...values, getIntProgressValue(Object.values(d)[1])];
  });
  return { labels, values };
};

export const canView = (allowedRoles, CurrentMemberRoles) => {
  if (typeof CurrentMemberRoles === "string")
    CurrentMemberRoles = [CurrentMemberRoles];

  let result = false;
  allowedRoles.forEach((role) => {
    if (
      CurrentMemberRoles &&
      (CurrentMemberRoles.some((res) => res.includes(role)) ||
        CurrentMemberRoles.includes(role))
    ) {
      result = true;
    }
  });

  return result;
};

export const getCurrentUserRole = (user) => user?.userDetail?.role;

export const truncateText = (lbl, maxNumber) => {
  if (lbl?.length > maxNumber) return lbl?.substring(0, maxNumber) + "...";
  return lbl;
};

export const formateDateTime = (date) => {
  return date.replace(/T.*/, "").split("-").join("-");
};

export const getFormattedArrayBarChartData = (dataX) => {
  const data = dataX?.map((d) => d?.count);
  const formattedLabels = dataX?.map((d) => d?.name);
  return {
    data: [{ data }],
    categories: formattedLabels,
  };
};

export function readFileAsDataUrl(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const fileContent = e.target.result;
      resolve(fileContent);
    };

    reader.onerror = (err) => {
      reject(err.target.error);
    };

    reader.readAsDataURL(file);
  });
}

export function formateStatus(status) {
  let itemStatus, bg_color;
  switch (status) {
    case "Open":
      itemStatus = "Open";
      bg_color = "badge-success";
      break;
    case "Resolved":
      itemStatus = "Resolved";
      bg_color = "badge-danger";
      break;
    case "Pending":
      itemStatus = "Pending";
      bg_color = "badge-warning";
      break;
  }

  return (
    <span className={`badge badge-pill ${bg_color} py-1 text-white ml-2`}>
      {itemStatus}
    </span>
  );
}
