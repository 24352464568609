import { getFormatedDate } from "../../Utility";
import { formateStatus } from "../../utils/util";

const ActionPointColumns = [
  {
    name: "Action Point",
    selector: (row) => row?.actionPoints,
    wrap: true,
    minWidth: "300px",
  },

  {
    name: "Accountable Person",
    selector: (row) => row?.accountablePerson?.name,
  },

  {
    name: "Timeline",
    selector: (row) => row?.timeline,
  },
  {
    name: "Followup Date",
    selector: (row) => getFormatedDate(row?.followUpDate),
  },
  {
    name: "Status",
    selector: (row) => <>{formateStatus(row?.status)}</>,
  },
];

export default ActionPointColumns;

export const ActionPointColumnsForExcel = {
  'Action Point': 'actionPoints',
  'Accountable Person': 'accountablePerson',
  'Timeline': 'timeline',
  'Followup Date': 'followUpDate',
  'Status': 'status',
}
