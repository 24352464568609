import React, { useCallback, useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-modal";
import "./Projects.scss";
import { UploadImageFields } from "../../assets/fields/UploadImageFields";
import Form from "../../components/form/Form";
import { useDispatch, useSelector } from "react-redux";
import {
  clearForm,
  createRecord,
  getRecords,
  resetData,
} from "../../redux/reducers";
import { readFileAsDataUrl } from "../../utils/util";

const UploadProjectImages = ({ ...props }) => {
  const dispatch = useDispatch();
  const { validations, values } = useSelector((state) => state.form);
  const { data, created } = useSelector((state) => state.records);
  const formValues = values;

  useEffect(() => {
    if (created) {
      props?.closeModal();
    }
  }, [created]);

  const handleClearForm = useCallback(() => {
    dispatch({ type: resetData.type });
    dispatch({ type: clearForm.type });
    props?.closeModal();
  });


  const postData = async () => {
    const formattedUploads = formValues?.model?.value?.map((file, index) => {
      return {
        attachment: file,
        name: file?.name,
        entityId: Number(props?.projectId),
        entityType: "ProjectPhotos",
      };
    });

    let convertedFiles = [];

    for (let data of formattedUploads) {
      const fileContent = await readFileAsDataUrl(data?.attachment);
      convertedFiles.push({ ...data, attachment: fileContent });
    }

    let params = {
      model: {
        name: "model",
        value: convertedFiles,
      },
    };

    // debugger;
    dispatch({
      type: createRecord.type,
      payload: {
        ...params,
        url: "/Attachment/MultipleUploads",
        headers: { "Content-Type": "multipart/form-data" },
      },
    });
  };
  return (
    <div className="upload-img">
      <Modal
        isOpen={props?.openModal}
        onAfterOpen={props?.afterOpenModal}
        onRequestClose={props?.closeModal}
        ariaHideApp={false}
        overlayClassName="modal-sm upload-image-modal"
        contentLabel="Upload Images"
      >
        <Icon.XCircle onClick={handleClearForm} className="action-icons" />
        <h4>Upload Project Images</h4>
        <Form
          formData={UploadImageFields}
          onSubmit={() => postData()}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </Modal>
    </div>
  );
};

export default UploadProjectImages;
