export const subActivityCompleteFields = [
  {
    label: "Actual Completion Date",
    inputType: "date",
    inForm: true,
    name: "Date",
    wrapperClass: "col-md-6",
  },
  {
    label: "Venue",
    placeholder: "Add Venue",
    inForm: true,
    name: "venue",
    wrapperClass: "col-md-6",
    required: true,
  },
  {
    label: "Note",
    inputType: "textarea",
    placeholder: "Add Note",
    inForm: true,
    name: "note",
    rows: 7,
    wrapperClass: "col-12",
    required: true,
  },
  {
    type: "hidden",
    inForm: true,
    wrapperClass: "col-12",
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Submit",
    inForm: true,
    wrapperClass: "mr-3 btn-wrapper position-right",
  },
];
