import React from "react";
import DataTable from "react-data-table-component";
import "./table.scss";

const Table = ({ columns = [], data = [], styles = {}, customColumnNames = {}, ...otherProps }) => {
  console.log("🚀 ~ file: Table.js:6 ~ Table ~ data:", data)

  // const customColumnNames = {
  //   "Target Name": 'targetName',
  //   "Remarks": 'remarks',
  //   "Accomplishment Date": "accomplishmentDate",
  //   "Locations": "locations",
  //   "SDG": "sdGs",
  //   "Union Council": "unionCouncil",
  //   "Title": "title",
  //   "End Date" : 'endDate',
  // };

  const commonValues = {
    "name": "name",
    "title": "title",
    // "followUpPersonName": "followUpPersonName",
    // "dateofFollowup": "dateofFollowup",
  }

  const extractedData = data.map(obj => {
    const newObj = {};
    const checkDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?$/
    Object.entries(customColumnNames).forEach(([columnName, dataKey]) => {
      const value = obj[dataKey];
      if (Array.isArray(value)) {
        const matchedValues = value
          .map(item => Object.entries(item).map(([key, val]) => (key in commonValues) ? val : ''))
          .flat()
          .filter(Boolean);
        newObj[columnName] = matchedValues.join(", ");
      } else if (typeof value === 'object' && value !== null) {
        Object.entries(value).forEach(([key, val]) => {
          if (key === 'dateofFollowup') {
            newObj["Latest Followup Date"] = val
          } else if (key === 'followUpPersonName') {
            newObj['Latest Followup by (Person)'] = val
          }

          if (key in commonValues) {
            newObj[columnName] = val
          }
        })
      } else {
        newObj[columnName] = value;
      }
    });

    Object.entries(newObj).forEach(([key, value]) => {
      if (checkDateRegex.test(value)) {
        newObj[key] = new Date(value).toLocaleDateString();
      }
    })
    return newObj;
  });
  console.log("🚀 ~ file: Table.js:53 ~ extractedData ~ extractedData:", extractedData)


  function downloadXLS() {
    const XLSX = window.XLSX;
    const ws = XLSX.utils.json_to_sheet([...extractedData]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "report");
    XLSX.writeFile(wb, "records.xlsx");
  }


  return (
    <>
      <DataTable
        columns={columns}
        data={data}
        customStyles={{ ...customStyles, ...styles }}
        pagination
        dense
        {...otherProps}
      />
      {otherProps?.nav ? (
        <div className="flex justify-content-between mt-2">
          <button
            onClick={() => {
              otherProps?.nav(-1);
            }}
            className="btn btn-primary"
          >
            Back
          </button>
          <button onClick={() => downloadXLS()} className="btn btn-primary">
            Export to Excel
          </button>
        </div>
      ) : (
        <div className="flex justify-content-end ">
          <button
            onClick={() => downloadXLS()}
            className="btn btn-primary float-right"
          >
            Export to Excel
          </button>
        </div>
      )}
    </>
  );
};

const customStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#F8F9FA",
      paddingBottom: 10,
      color: "#162E4A",
      paddingTop: 10,
      fontSize: "14px",
    },
  },
  cells: {
    style: {
      padding: 16,
      fontSize: 15,
      maxWidth: 100,
    },
  },
};

export default Table;
