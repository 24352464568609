import Badge from "../../components/badge/Badge";
import ColumnLink from "../../components/column-link/ColumnLink";
import { getFormatedDate } from "../../Utility";

const ProjectColumns = [
  {
    name: "Project Name",
    selector: (row) => (
      <>
        {/* <Link to={`${row.id}`} className="text-capitalize">
          {row.title}
        </Link> */}
        <ColumnLink to={`/projects/${row.id}`}>{row?.title}</ColumnLink>
      </>
    ),
    wrap: true,
    minWidth: "300px",
  },
  // {
  //   name: "Budget",
  //   selector: (row) => row.totalCostInEUR
  // },
  // {
  //   name: "Status",
  //   selector: (row) => (
  //     <label className={row?.isActive ? "text-success" : "text-danger"}>
  //       {row?.isActive ? "Opened" : "Closed"}
  //     </label>
  //   )
  // },
  {
    name: "Project SDGs",
    selector: (row) => (
      <>
        {row.sdGs &&
          row.sdGs.map(({ name = "" }, index) => (
            <Badge
              className="m-1 text-left btn btn-sm btn-info"
              style={{ lineHeight: "10px", fontSize: "10px", backgroundColor: "#f5f5f5", color: "#000", borderColor: '#f5f5f5', borderRadius: 20, paddingTop: 8, paddingBottom: 8, paddingRight: 12, paddingLeft: 12 }}
              key={index}
            >
              {name}
            </Badge>
          ))}
      </>
    ),
    wrap: true,
    minWidth: "250px",
  },
  {
    name: "Start Date",
    selector: (row) => getFormatedDate(row?.startDate),
  },
  {
    name: "End Date",
    selector: (row) => getFormatedDate(row?.endDate),
  },
];

export default ProjectColumns;


export const ProjectCustomColumnForExcel = {
  "Project Name": 'title',
  "Project SDGs": 'sdGs',
  "Start Date": 'startDate',
  "End Date": 'endDate',
}
