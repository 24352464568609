import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../components/loader/loader";
import usePageMeta from "../../hooks/usePageMeta";
import { getRecords } from "../../redux/reducers";

const UserManagement = () => {
  const { setMeta } = usePageMeta();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, data, created } = useSelector((state) => state.records);

  useEffect(() => {
    getUserManagement();
  }, [id, created]);
  useEffect(() => {
    setMeta({ title: data?.userName });
  }, [data?.userName]);
  const getUserManagement = () => {
    dispatch({
      type: getRecords.type,
      payload: { url: `/UserManagement/${id}` },
    });
  };

  return (
    <div className="container my-3">
      <Loader loading={loading} />
      <div className="row">
        <div className="col-md-6">
          <h4 className="pt-1 mb-3">User Detail</h4>
          <div className="row">
            <div className="col-sm-4">
              <h6>First Name</h6>
            </div>
            <div className="col-sm-8">{data?.firstName}</div>
            <div className="col-sm-4">
              <h6>Last Name</h6>
            </div>
            <div className="col-sm-8">{data?.lastName}</div>
            <div className="col-sm-4">
              <h6>User Name</h6>
            </div>
            <div className="col-sm-8">{data?.userName}</div>
            <div className="col-sm-4">
              <h6>Email</h6>
            </div>
            <div className="col-sm-8">{data?.email}</div>
            <div className="col-sm-4">
              <h6>Role</h6>
            </div>
            <div className="col-sm-8">
              {data?.roles?.map(({ name }) => (
                <span className="mx-1">{name}</span>
              ))}
            </div>
            <div className="col-sm-4">
              <h6>Image URL</h6>
            </div>
            <div className="col-sm-8">{data?.imageUrl}</div>
            <div className="col-sm-4">
              <h6>File</h6>
            </div>
            <div className="col-sm-8">{data?.file}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
