export const keyfindingsFields = [
  {
    label: "Key Findings",
    placeholder: "Enter KeyFindings",
    name: "keyFindings",
    inputType : 'textarea',
    inForm: true,
  },
  {
    label: "Date of Debrief",
    placeholder: "Enter Date of Debrief",
    name: "dateOfDeBrief",
    inputType : 'date',
    inForm: true,
  },
  {
    label: "Debrief Memo",
    placeholder: "Enter Debrief Memo",
    name: "debriefMemo",
    inputType : 'textarea',
    inForm: true,
  },
  {
    inputType: "button",
    buttonType: "submit",
    children: "Add Keyfinding",
    inForm: true,
    wrapperClass: "mr-3 mt-2 btn-wrapper position-right",
  },
  
];
