import React from "react";
import ListHeader from "../components/list-header/ListHeader";
import SearchList from "../components/search-list/SearchList";

const Searchbar = ({
  searchListParams = {},
  listHeaderParams = {},
  className = "",
  listheaderChildren = "",
}) => {
  const { options } = listHeaderParams;
  const { url, params, searchFieldName } = searchListParams;

  return (
    <div
      className={`d-md-flex py-2 justify-content-between align-items-center ${className}`}
    >
      <SearchList url={url} params={""} searchFieldName={searchFieldName} />
      <div className="right text-right mt-2">
        <ListHeader options={options}>{listheaderChildren}</ListHeader>
      </div>
    </div>
  );
};

export default Searchbar;
