import React from "react";
import { truncateText } from "../../utils/util";
import ToolTip from "../tooltip/Tooltip";
import "./table-title.scss";

const TableTitle = ({ title = "", limit = 25 }) => {
  return (
    <ToolTip label={title}>
      <div className="text-capitalize table-title">
        {truncateText(title, limit)}
      </div>
    </ToolTip>
  );
};

export default TableTitle;
