export function getQuarterlyData(startDate, endDate) {
  const quarters = [];

  for (
    let year = startDate.getFullYear();
    year <= endDate.getFullYear();
    year++
  ) {
    const yearData = { year: year, quarters: [] };

    for (let quarter = 1; quarter <= 4; quarter++) {
      const quarterStartMonth = (quarter - 1) * 3;
      const quarterEndMonth = quarterStartMonth + 2;
      const quarterStart = new Date(year, quarterStartMonth, 1);
      const quarterEnd = new Date(year, quarterEndMonth, 0);

      if (quarterEnd < startDate || quarterStart > endDate) {
        continue;
      }

      const quarterData = {
        id: `${year}-Q${quarter}`,
        quarter: `Q${quarter}`,
        // startDate: quarterStart,
        // endDate: quarterEnd,
        value: null,
      };

      yearData.quarters.push(quarterData);
    }

    if (yearData.quarters.length > 0) {
      quarters.push(yearData);
    }
  }

  // return JSON.stringify({ quarters });
  return { quarters };
}


export const getQuarterDataArray = (quarterData) => {
  const quarterDataArray = [];
  quarterData?.quarters?.map((data) => {
    data?.quarters?.map((item) => {
      quarterDataArray.push(Number(item?.value));
    });
  });
  return quarterDataArray;
};

export const updateQuarterDataArray = (data2, quarterValues) => {
  const updatedData = {
    quarters: data2.quarters.map((quarterObj, index) => {
      return {
        year: quarterObj.year,
        quarters: quarterObj.quarters.map((qtr, qtrIndex) => {
          return {
            ...qtr,
            value: quarterValues[index * 3 + qtrIndex] || 0,
          };
        }),
      };
    }),
  };
  return updatedData;

}


export const saveQuarterValuesToLocalStorage = (quarterData) => {
  localStorage.setItem('quarter-data', JSON.stringify(quarterData))
}

export const getQuarterValuesFromLocalStorage = () => {
  const quarterArray = localStorage.getItem('quarter-data')
  return JSON.parse(quarterArray)
}
