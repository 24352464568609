import { ACTIVITY_STATUS_TYPE, COMPLAINT_STATUS } from "./Constants";

export function getStatusStyle(status = "") {
  switch (status.toLowerCase()) {
    case COMPLAINT_STATUS.PENDING.toLowerCase() || COMPLAINT_STATUS.FALSE:
      return "text-warning";
      break;

    case COMPLAINT_STATUS.CLOSED.toLowerCase() || COMPLAINT_STATUS.TRUE:
      return "text-success";
      break;

    default:
      return "text-danger";
      break;
  }
}

export function getActivityStatusStyle(status) {
  switch (status) {
    case ACTIVITY_STATUS_TYPE.PENDING:
      return "text-info";
      break;

    case ACTIVITY_STATUS_TYPE.COMPLETED:
      return "text-success";
      break;

    default:
      return "text-warning";
      break;
  }
}

export const getFormatedDate = (date = "") => {
  return new Date(date).toLocaleDateString();
};

export const getFacebookFormattedDate = (date = "") => {
  const dateX = new Date(date);
  const option = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  const formattedDate = dateX.toLocaleDateString("en-US", option);
  return formattedDate;
};

// export const getInputFormatedDate = (date = '') => {
//   let _date = new Date(date);
//   let compiledDate = new Date(_date.getFullYear(), _date.getMonth(), _date.getDate() - 0);
//   return `${compiledDate.getDate()}-${compiledDate.getMonth() + 1}-${compiledDate.getFullYear()}`
//   // return `${compiledDate.getFullYear()}-${compiledDate.getMonth() + 1}-${compiledDate.getDate()}`
// }

export function shortId() {
  return "_" + Math.random().toString(36).substr(2, 9);
}

export function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`;
}
