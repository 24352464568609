import React, { useEffect, useState } from "react";
import ActionPointMVRColumns, { ActionPointMVRColumnsForExcel } from "../../assets/columns/ActionPointMVRColumns";
import Table from "../../components/table/Table";
import * as Icon from "react-bootstrap-icons";
import Loader from "../../components/loader/loader";
import DeleteRow from "../DeleteRow";
import ToolTip from "../../components/tooltip/Tooltip";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
} from "../../redux/reducers";
import "./MonitoringVisitReport.scss";
import { ORDER_DIRECTION } from "../../Constants";
import Modal from "react-modal";
import { formateStatus } from "../../utils/util";
import { getFacebookFormattedDate } from "../../Utility";
import { StatusFields } from "../../assets/fields/StatusFields";
import Form from "../../components/form/Form";

const ActionPointMVR = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { userDetail } = useSelector((state) => state.setting.user);
  const { loading, current, created } = useSelector((state) => state.records);
  const items = current?.actionPointMVR?.data?.items;
  const followUps = current?.follow_ups?.data?.items;
  const filteredItems = items?.filter((i) => i?.status === "Open");
  const formValues = useSelector((state) => state.form.values);

  const [actionPointModal, setActionPointModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [followupModal, setFollowupModal] = useState(false);
  const [actionPointInfo, setActionPointInfo] = useState({});
  const [actionPointId, setActionPointId] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    getAllActionPoints();
  }, []);

  useEffect(() => {
    if (created) {
      getAllActionPoints();
      getAllFollowUps();
      setResponseMessage("");
    }
  }, [created]);

  useEffect(() => {
    if (!actionPointId) return;
    getAllFollowUps();
  }, [actionPointId]);

  function getAllActionPoints() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/MonitoringVisitReport/GetAllActionPoints?Project.Id=${id}`,
        params: { DisablePagination: true, OrderDir: ORDER_DIRECTION.DESC },
        appendCurrentReducerKey: "actionPointMVR",
      },
    });
  }

  function getAllFollowUps() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/FollowUp/GetAll?ActionPoint.Id=${actionPointInfo?.id}`,
        appendCurrentReducerKey: "follow_ups",
      },
    });
  }

  function toggleActionPointModal(id) {
    setActionPointId(id);
    setActionPointModal(!actionPointModal);
  }

  function toggleStatusModal() {
    setStatusModal(!statusModal);
  }

  function toggleFollowupModal() {
    setFollowupModal(!followupModal);
  }

  const ActionPointColumns = [
    ...ActionPointMVRColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <ToolTip label="Details">
            <Icon.Eye
              style={{ cursor: "pointer" }}
              onClick={() => {
                toggleActionPointModal(row?.id);
                setActionPointInfo(row);
              }}
              className="text-primary mr-2"
            />
          </ToolTip>
          <DeleteRow row={row} url="/ActionPoint" />
        </>
      ),
    },
  ];

  return (
    <>
      <Modal
        isOpen={actionPointModal}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Indicator"
      >
        <div
          style={{ height: "100%" }}
          className=" d-flex flex-column justify-content-between"
        >
          <div>
            <div
              style={{ position: "sticky", top: 0, background: "#fff" }}
              className="rounded border-bottom"
            >
              <div className="d-flex justify-content-between align-items-center px-3 py-2">
                <div className="row">
                  <h4>Action Point</h4>
                  <span>{formateStatus(actionPointInfo?.status)}</span>
                </div>
                <Icon.XCircle
                  onClick={toggleActionPointModal}
                  className="action-icons"
                />
              </div>

              <div className="font-weight-bold px-0">
                <p>{actionPointInfo && actionPointInfo?.actionPoints}</p>
              </div>
            </div>

            <div className="mt-3">
              {followUps &&
                followUps?.map((item, index) => {
                  return (
                    <div className="mb-3 pb-1 border-bottom">
                      <div className="row align-items-center ml-0">
                        <h6 className=" py-0 my-0">
                          {item?.followupPerson?.name}
                        </h6>
                        <sub className="ml-5">
                          {" "}
                          {`(${getFacebookFormattedDate(
                            item?.dateofFollowup
                          )})`}
                        </sub>
                      </div>
                      <div style={{ color: "#7C9DB2" }}>{item?.response}</div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className="text-right mt-5 row justify-content-end"
            style={{
              position: "sticky",
              bottom: -20,
              right: 20,
              background: "#fff",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={toggleActionPointModal}
            >
              Cancel
            </button>

            <button
              class="btn btn-primary ml-2"
              type="button"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => toggleStatusModal()}
            >
              Change Status
            </button>

            <button
              className="btn btn-primary ml-2"
              onClick={() => toggleFollowupModal()}
            >
              Add Follow Up
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={statusModal}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Indicator"
      >
        <div
          style={{ position: "sticky", top: -6, background: "#fff" }}
          className="rounded border-bottom d-flex justify-content-between align-items-center pt-3 px-3 py-2"
        >
          <h4>Action Point Status</h4>
          <Icon.XCircle onClick={toggleStatusModal} className="action-icons" />
        </div>

        <div class="container mt-3">
          <div class="row">
            <div class="col-md-12 border rounded-top py-2">
              <b> Action Point : </b> {actionPointInfo?.actionPoints}
            </div>
            <div class="col-sm border mt-1 mr-1 py-2">
              <b>Followup Date : </b>
              {getFacebookFormattedDate(actionPointInfo?.followUpDate)}
            </div>
            <div class="col-sm border mt-1 ml-1 py-2">
              <b>Current Status : </b>
              {formateStatus(actionPointInfo?.status)}
            </div>
          </div>
        </div>

        <div className="mt-3">
          <Form
            formData={StatusFields}
            onSubmit={(params) => changeActionPointStatus(params)}
            withValidation={false}
            formValidation={[]}
            extraInputClass=""
          />
        </div>
      </Modal>

      <Modal
        isOpen={followupModal}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Indicator"
      >
        <div
          style={{ height: "100%" }}
          className=" d-flex flex-column justify-content-between"
        >
          <div>
            <div
              style={{ position: "sticky", top: -6, background: "#fff" }}
              className="rounded border-bottom d-flex justify-content-between align-items-center pt-3 px-3 py-2"
            >
              <h4>Follow up List</h4>
              <Icon.XCircle
                onClick={toggleFollowupModal}
                className="action-icons"
              />
            </div>

            <div className="mt-3">
              {followUps &&
                followUps?.map((item, index) => {
                  return (
                    <div className="mb-3 pb-1 border-bottom">
                      <div className="row align-items-center ml-0">
                        <h6 className=" py-0 my-0">
                          {item?.followupPerson?.name}
                        </h6>
                        <sub className="ml-5">
                          {`(${getFacebookFormattedDate(
                            item?.dateofFollowup
                          )})`}
                        </sub>
                      </div>
                      <div style={{ color: "#7C9DB2" }}>{item?.response}</div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className="row"
            style={{
              position: "sticky",
              bottom: -20,
              // background: "#fff",
              right: 20,
              width: "100%",
            }}
          >
            <div className="col-12">
              <div className="text-right mt-5 d-flex align-items-center">
                <input
                  type="text"
                  className="form-control w-100 mr-2"
                  placeholder="Add Followups"
                  style={{ height: "46px" }}
                  value={responseMessage}
                  onChange={(e) => setResponseMessage(e.target.value)}
                />
                <button
                  onClick={() => postFollowUp()}
                  className="btn btn-primary mr-2"
                >
                  Add
                </button>
                <button
                  className="btn btn-primary"
                  onClick={toggleFollowupModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="mt-5">
        <div
          id="actionPointAccordion"
          className="bg-white shadow-lg py-3 accordion accordion-group col-md-12"
        >
          <div class="accordion-item">
            <h2 class="accordion-header">
              <h2> Action Points </h2>
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#actionPointTableCollapse"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <div>
                  <Icon.ChevronBarExpand />
                </div>
              </button>
            </h2>
            <div
              id="actionPointTableCollapse"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#actionPointAccordion"
            >
              <div class="accordion-body">
                <Loader loading={loading} />
                <Table
                  columns={ActionPointColumns}
                  customColumnNames={ActionPointMVRColumnsForExcel}
                  data={filteredItems && filteredItems}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  function changeActionPointStatus(params) {
    let updatedParams = {
      ...actionPointInfo,
      action: { name: "action", value: "string" },
      timeline: { name: "timeline", value: actionPointInfo.timeline },
      status: { name: "status", value: params.status.value.value },
      id: { name: "id", value: actionPointInfo?.id },
      actionPoints: {
        name: "actionPoints",
        value: actionPointInfo?.actionPoints,
      },
      followUpDate: {
        name: "followUpDate",
        value: actionPointInfo?.followUpDate,
      },
    };


    dispatch({
      type: putRecord.type,
      payload: {
        ...updatedParams,
        url: "/ActionPoint",
      },
    });

    toggleStatusModal();
  }

  function postFollowUp() {
    let updatedParams = {
      response: { name: "response", value: responseMessage },
      followupPerson: { id: userDetail?.id, name: userDetail?.name },
      actionPoint: { id: actionPointInfo?.id, name: "string" },
      dateofFollowup: {
        name: "dateofFollowup",
        value: new Date().toISOString(),
      },
      action: { name: "action", value: "string" },
    };

    dispatch({
      type: createRecord.type,
      payload: {
        ...updatedParams,
        url: "/FollowUp",
      },
    });

    toggleActionPointModal();
  }
};

export default ActionPointMVR;
