import Badge from "../../components/badge/Badge";

const SmartIndicatorColumns = [
  {
    name: "Indicator",
    selector: (row) => row.indicator,
    wrap: true,
    minWidth: "300px",
  },

  {
    name: "Type",
    selector: (row) => row.indicatorType,
  },

  {
    name: "Target",
    selector: (row) => row.target,
  },
  {
    name: "Target Type",
    selector: (row) => row.targetTypeLookup?.name,
  },
  {
    name: "Baseline Value",
    selector: (row) => row.baselineValue,
  },
  {
    name: "Means of Verification",
    selector: (row) => (
      <>
        {row.siMeanOfVerifications &&
          row.siMeanOfVerifications.map(({ name = "" }, index) => (
            <Badge className="m-1 text-left btn btn-sm btn-info" key={index}>
              {name}
            </Badge>
          ))}
      </>
    ),
    wrap: true,
    minWidth: "250px",
  },
];

export default SmartIndicatorColumns;

export const SmartIndicatorColumnsForExcel = {
  "Indicator": "indicator",
  "Type": "indicatorType",
  "Target": "target",
  "Target Type": "targetTypeLookup",
  "Baseline Value": "baselineValue",
  "Means of Verification": "siMeanOfVerifications",
}
