import { put } from "redux-saga/effects";
import {
  setFormFields,
  setErrors,
  setRecords,
  setUserMessages,
} from "../reducers";
import { baseUrl, baseClientUrl } from "..";
import axios from "./api-client";

export function* getLayoutAsync(action) {
  const fullUrl = baseClientUrl + action.payload + ".json";
  if (fullUrl) {
    try {
      const data = yield axios.get(fullUrl).then(({ data }) => data);
      yield put({ type: setFormFields.type, payload: data });
    } catch (e) {
      yield put({
        type: setErrors.type,
        payload: { errorIn: action.type, message: e.message },
      });
    }
  } else {
    yield put({
      type: setErrors.type,
      payload: { errorIn: action.type, message: "No url specified!" },
    });
  }
}
export function* getRecordsAsync(action) {
  const {
    url,
    typeOverride = null,
    errorTypeOverride = null,
    appendCurrentReducerKey = null,
    params = {},
  } = action.payload;
  const fullUrl = baseUrl + "/api" + url;
  if (url) {
    try {
      const data = yield axios
        .get(fullUrl, { params })
        .then(({ data }) => data);
      let appendData = appendCurrentReducerKey
        ? { current: { [appendCurrentReducerKey]: data } }
        : data;
      yield put({
        type: typeOverride ? typeOverride : setRecords.type,
        payload: { ...appendData },
      });
      yield put({
        type: setUserMessages.type,
        payload: { ...action.payload, ...data },
      });
    } catch (e) {
      yield put({
        type: errorTypeOverride ? errorTypeOverride : setErrors.type,
        payload: { errorIn: action.type, message: e.message },
      });
      yield put({
        type: typeOverride ? typeOverride : setRecords.type,
        payload: { loading: false },
      });
      yield put({
        type: setUserMessages.type,
        payload: { ...action.payload, ...e },
      });
    }
  } else {
    yield put({
      type: errorTypeOverride ? errorTypeOverride : setErrors.type,
      payload: { errorIn: action.type, message: "No url specified!" },
    });
  }
}
export function* getLookupAsync(action) {
  try {
    const { url, params = {} } = action.payload;
    const fullUrl = baseUrl + "/api" + url;
    const data = yield axios.get(fullUrl, { params }).then(({ data }) => data);
    yield put({
      type: "form/setLookup",
      payload: { key: action.payload.key, ...data },
    });
  } catch (e) {}
}
