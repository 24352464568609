import React, { useCallback, useReducer, useState } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  Popup,
  Polygon,
} from "react-leaflet";

import PakistanJson from "./pakistan.json";
import { provincesGEOData } from "./provinces_geo_code";
import { map, polygon } from "leaflet";

const PakistanMap = ({ items }) => {
  const position = [30.3753, 69.3451];
  const [event, setEvent] = useReducer((state, action) => {
    return {
      ...state,
      ...action
    }
  }, { popoverVisible: false, popoverContent: null, item: null })


  const handleMapClick = useCallback((feature, e) => {
    const properties = feature.properties.name;
    setEvent({ item: properties })
    const getDistrictInfo = items.filter(
      (item) => item?.districtName === properties
    );
    setEvent({ popoverContent: getDistrictInfo })
    setEvent({ popoverVisible: true })
  });

  const handleMouseHover = (e, feature) => {
    e.target.setStyle({
      fillOpacity: 0.5,
      weight: 1,
      color: "green",
      fillColor: "red",
    });
    const properties = feature.properties.name;
    setEvent({ item: properties })
    const getDistrictInfo = items.filter(
      (item) => item?.districtName === properties
    );
    setEvent({ popoverContent: getDistrictInfo })
    setEvent({ popoverVisible: true })
  }

  return (
    <MapContainer center={position} zoom={5} style={{ height: "500px" }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
      />

      {provincesGEOData.features.map((feature) => {
        if (feature.geometry.type === "Polygon") {
          const coordinates = feature.geometry.coordinates[0].map(
            (coordinate) => [coordinate[1], coordinate[0]]
          );

          const properties = feature.properties.name;
          const getDistrictInfo = items.find(
            (item) => item?.districtName === properties
          );

          const fillColor = getDistrictInfo && getDistrictInfo.projects > 0 ? "rgba(277,111,30,0.8)" : "rgba(0,255,0,0.2)";

          return (
            <Polygon
              positions={coordinates}
              pathOptions={{
                fillColor,
                fillOpacity: 0.5,
                opacity: 1,
                weight: 1,
                color: "green",
              }}
              eventHandlers={{
                mouseover: (e) => handleMouseHover(e, feature),
                mouseout: (e) => {
                  e.target.setStyle({
                    fillOpacity: 0.5,
                    weight: 1,
                    color: "green",
                    fillColor: "rgba(0,255,0,0.2)",
                  });
                },
                click: (e) => handleMapClick(feature, e),
              }}
            />
          );
        } else if (feature.geometry.type === "MultiPolygon") {
          const multiPolygons = feature.geometry.coordinates.map((coordinate) =>
            coordinate[0].map((coordinate) => [coordinate[1], coordinate[0]])
          );

          const properties = feature.properties.name;
          const getDistrictInfo = items.find(
            (item) => item?.districtName === properties
          );

          const fillColor = getDistrictInfo && getDistrictInfo.projects > 0 ? "rgba(277,111,30,0.8)" : "rgba(0,255,0,0.2)";

          return multiPolygons.map((coordinates, index) => (
            <Polygon
              key={`${feature.id}-${index}`}
              positions={coordinates}
              pathOptions={{
                fillColor,
                fillOpacity: 0.5,
                opacity: 1,
                weight: 1,
                color: "green",
              }}
              eventHandlers={{
                mouseover: (e) => handleMouseHover(e, feature),
                mouseout: (e) => {
                  e.target.setStyle({
                    fillOpacity: 0.5,
                    weight: 1,
                    color: "green",
                    fillColor: "rgba(0,255,0,0.2)",
                  });
                },
                click: (e) => handleMapClick(feature, e),
              }}
            />
          ));
        }
      })}

      {event?.popoverVisible && (
        <div
          className="shadow"
          style={{
            position: "absolute",
            right: 10,
            bottom: 10,
            background: "white",
            border: "1px solid gray",
            borderRadius: "8px",
            padding: "8px",
            zIndex: 9999,
          }}
        >
          {event?.popoverContent.length !== 0 ? (
            <>
              <h4>{event?.popoverContent[0]?.districtName}</h4>
              <p
                style={{ margin: 0 }}
              >{`Projects: ${event?.popoverContent[0]?.projects}`}</p>
              <p
                style={{ margin: 0 }}
              >{`Beneficiaries: ${event?.popoverContent[0]?.beneficiaries}`}</p>
            </>
          ) : (
            <>
              <h4>{event?.item}</h4>
              <p
                style={{ margin: 0 }}
              >{`Projects: 0`}</p>
              <p
                style={{ margin: 0 }}
              >{`Beneficiaries: 0`}</p>
            </>
          )}
        </div>
      )}
    </MapContainer>
  );
};

export default PakistanMap;
