import { FormStepper, Step } from "../../components/form-stepper/FormStepper";
import Form from "../../components/form/Form";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader/loader";
import {
  clearForm,
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
  resetData,
} from "../../redux/reducers";
import { useNavigate, useParams } from "react-router-dom";
import { getFieldsByCategory } from "../../utils/util";
import { keyfindingsFields } from "../../assets/fields/keyfindingsFields";

const AddUpdateKeyFindings = () => {
  const dispatch = useDispatch();
  let { id, reportId } = useParams();
  const navigate = useNavigate();
  const formFields = useSelector((state) => state.fields.formFields);
  const { loading, created, data } = useSelector((state) => state.records);
  const formValues = useSelector((state) => state.form.values);
  const { userDetail } = useSelector((state) => state.setting.user);
  let canUpdate = false;
  if (id) canUpdate = true;

  useEffect(() => {
    if (created && !canUpdate) {
      navigate(`/monitoring-visit-report/key-finidings/${reportId}`);
    }
  }, [created]);

  useEffect(() => {
    if (created && canUpdate) {
      navigate(`/monitoring-visit-report/key-finidings/${reportId}`);
    }
  }, [created]);

  //update project actions
  useEffect(() => {
    if (id)
      dispatch({
        type: getRecords.type,
        payload: { url: `/KeyFinding/${id}` },
      });
    return () => {
      dispatch({ type: resetData.type });
      dispatch({ type: clearForm.type });
    };
  }, [id]);

  //mask data on single project data retrieval
  useEffect(() => {
    if (data) {
      dispatch({ type: formatRecords.type, payload: { ...data } });
    }
  }, [data]);

  const addRecord = (params) => {
    let updatedPrams = { ...params, monitoringVisitReport: { id: reportId, name: '' } }
    dispatch({
      type: createRecord.type,
      payload: { ...updatedPrams, url: "/KeyFinding" },
    });
  };

  function updateRecord(params) {
    let updatedPrams = {
      ...params, monitoringVisitReport: {
        id: params?.monitoringVisitReport?.value?.id,
        name: ''
      }
    }
    dispatch({
      type: putRecord.type,
      payload: {
        ...updatedPrams,
        url: `/KeyFinding`,
        headers: { "Content-Type": "application/json" },
      },
    });
  }

  return (
    <div className="mb-4">
      <Loader loading={loading} />
      <div className="col-sm-12 bg-white shadow mt-4 mb-4 px-4 py-4 rounded">
        <Form
          formData={keyfindingsFields}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
          onSubmit={(params) => canUpdate ? updateRecord(params) : addRecord(params)}
        />
      </div>
    </div>
  );
};

export default AddUpdateKeyFindings;
