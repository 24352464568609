import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MAP_ICON from "./../../assets/images/map-icon.png";
import "./Projects.scss";
import LightBoxGallery from "../lightbox-gallery/LightBoxGallery";
import { getRecords } from "../../redux/reducers";
import UploadProjectImages from "./UploadProjectImages";
import { baseUrl } from "../../redux";
import { getFacebookFormattedDate } from "../../Utility";

const ProjectAlbums = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const { data, current } = useSelector((state) => state.records);
  const projectDetails = current?.projectDetails?.data;
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  function showModal() {
    setOpenModal(true);
  }
  function closeModal() {
    setOpenModal(false);
  }
  function afterOpenModal() {}

  useEffect(() => {
    getProjectDetails();
    setTimeout(() => {
      getProjectPhotos();
    }, 200);
  }, []);

  const getProjectDetails = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Project/GetProjectDetails?id=${id}`,
        appendCurrentReducerKey: "projectDetails",
      },
    });
  };

  const getProjectPhotos = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Project/GetProjectPhotos?ProjectId=${id}`,
      },
    });
  };

  const baseUrlX = `/projects/albums/${id}/album`;

  const featuredImage = data?.items?.length > 0 ? data?.items[0] : null;
  return (
    <>
      <UploadProjectImages
        showModal={showModal}
        closeModal={closeModal}
        afterOpenModal={afterOpenModal}
        openModal={openModal}
        projectId={id}
      />

      <h4 className="font-weight-bold mb-4">Albums</h4>
      <div className="d-flex align-items-center mb-4">
        <>
          <img src={MAP_ICON} alt="map" className="map-icon" />
          <div className="ml-2">
            <p className="text-primary font-weight-bold mb-0 w-75">
              {projectDetails?.title}
            </p>
            <p className="mb-0 small">
              {`StartDate: ${getFacebookFormattedDate(
                projectDetails?.startDate
              )} - EndDate: ${getFacebookFormattedDate(
                projectDetails?.endDate
              )}`}
            </p>
          </div>
        </>
        <button
          onClick={() => setOpenModal(true)}
          className="btn btn-primary ml-auto"
        >
          Upload Project Photos
        </button>
      </div>

      <div className="d-flex align-items-center">
        {featuredImage && featuredImage?.url != "" && (
          <div
            className="mx-1"
            onClick={() =>
              navigate(baseUrlX, {
                state: { imageList: data?.items, index: 0 },
              })
            }
            style={{ cursor: "pointer" }}
          >
            <img
              style={{ width: "250px", height: "250px", objectFit: "contain" }}
              src={`${baseUrl}${featuredImage?.url}`}
              alt="image"
            />
          </div>
        )}
        {/* image section */}
        <div className="d-flex flex-wrap">
          {data?.items &&
            data?.items.map(({ id, url }, index) =>
              index > 0 ? (
                <div
                  key={index}
                  className="m-1"
                  onClick={() =>
                    navigate(`${baseUrlX}/${id}`, {
                      state: { imageList: data?.items, index },
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  {url !== "" && (
                    <img
                      style={{
                        width: "200px",
                        height: "200px",
                        objectFit: "contain",
                      }}
                      src={`${baseUrl}${url}`}
                      alt={id}
                    />
                  )}
                </div>
              ) : null
            )}
          <div
            className="border d-flex flex-column p-3 justify-content-center align-items-center"
            onClick={() =>
              navigate(baseUrlX, {
                state: { imageList: data?.items, index: 0 },
              })
            }
            style={{ cursor: "pointer" }}
          >
            <span className="h4 font-weight-bold">40</span>
            <p>More photos</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectAlbums;
