import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INTERVENTION_LOGIC_TYPE } from "../../Constants";
import {
  clearForm,
  createRecord,
  getRecords,
  putRecord,
} from "../../redux/reducers";

import Form from "../form/Form";
import Loader from "../loader/loader";

const AddSpecificObjective = ({ projectId = "" }) => {
  const dispatch = useDispatch();
  const { formFields } = useSelector((state) => state.fields);
  const formValues = useSelector((state) => state.form.values);
  const {
    created,
    loading,
    response = {},
    data = {},
    current,
  } = useSelector((state) => state.records);

  const projectDetails = current?.projectDetail?.data;

  if (projectDetails && projectDetails != undefined) {
    storeDataInLocalStorage();
  }

  useEffect(() => {
    dispatch({ type: clearForm.type });
  }, []);

  useEffect(() => {
    if (!created) return;
    (() => {
      dispatch({
        type: getRecords.type,
        payload: { url: `/Objective/GetAll?Project.Id=${projectId}` },
      });
    })();
  }, [created]);

  useEffect(() => {
    getProjectDetail();
  }, [projectId]);

  function storeDataInLocalStorage() {
    localStorage.setItem(
      "locations",
      JSON.stringify(projectDetails?.locations)
    );
    localStorage.setItem(
      "thematicAreas",
      JSON.stringify(projectDetails?.thematicAreas)
    );
  }

  function getProjectDetail() {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Project/GetProjectDetails?id=${projectId}`,
        appendCurrentReducerKey: "projectDetail",
      },
    });
  }

  const onAdd = (params) => {
    if (params?.id) {
      dispatch({
        type: putRecord.type,
        payload: {
          ...params,
          url: "/Objective",
        },
      });
    } else {
      let formData = { ...params, projectId: { value: projectId } };
      dispatch({
        type: createRecord.type,
        payload: { ...formData, url: "/Objective" },
      });
    }
  };

  function getFieldsByCategory(fields = [], key) {
    return fields.filter(({ category = "" }) => category == key);
  }

  return (
    <div className="position-relative">
      <Loader loading={loading} theme="light" />
      <Form
        formData={getFieldsByCategory(formFields, "intervention")}
        onSubmit={(params) => onAdd(params)}
        withValidation={false}
        formValidation={[]}
        extraInputClass=""
      />
    </div>
  );
};
export default AddSpecificObjective;
