import { Link } from "react-router-dom";
import TableTitle from "../../components/table-title/TableTitle";
import { getFormatedDate } from "../../Utility";

const VisitsColumns = [
  {
    name: "Visit",
    selector: (row) => (
      <>
        <Link  to={`/monitoring-visit-report/visits/key-finidings/${row?.id}`}>
          <TableTitle title={row?.visitName} limit={100} />
        </Link>
      </>
    ),
    wrap: true,
    minWidth: "300px",
  },

  {
    name: "Reporting Person",
    selector: (row) => row?.reportingPerson?.name,
  },
  {
    name: "Location of Visit",
    selector: (row) => row?.locationOfVisit,
  },

  {
    name: "Date of Monitoring",
    selector: (row) => getFormatedDate(row?.dateOfMonitoring),
  },
];

export default VisitsColumns;

export const VisitsColumnsForExcel = {
  "Visit": "visitName",
  "Reporting Person": "reportingPerson",
  "Location of Visit": "locationOfVisit",
  "Date of Monitoring": "dateOfMonitoring",
}