import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "./Sidebar.scss";
import Logo from "../../assets/logo.png";
import MobileLogo from "../../assets/mobileLogo.png";
import { NavLink, Link } from "react-router-dom";
import { canView } from "../../utils/util";
import { useSelector } from "react-redux";
import {
  FaHome,
  FaRocket,
  FaUserFriends,
  FaExclamationTriangle,
  FaCog,
  FaFile,
  FaJournalWhills,
  FaList,
  FaBars,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaBuilding,
  FaUsers,
} from "react-icons/fa";

const Sidebar = () => {
  const { roles } = useSelector((state) => state.setting?.user?.userDetail);
  const userRoles = roles && roles.map(({ name }) => name);

  const [toggle, setToggler] = useState(false);

  const toggleSidebar = () => {
    setToggler(!toggle);
  };

  useEffect(() => { }, [toggle]);

  const sidebarData = [
    {
      id: 1,
      name: "dashboard",
      route: "dashboard",
      icon: <Icon.Speedometer2 />,
      path: (
        <>
          <div className="icon-bg">
            <FaHome className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Dashboard</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <FaHome className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 2,
      name: "project",
      route: "projects",
      path: (
        <>
          <div className="icon-bg">
            <FaRocket className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Projects</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <FaRocket className="sidebar-icon" />
          </div>
        </>
      ),
      children: [
        {
          id: 3,
          name: "project",
          route: "projects",
          path: (
            <>
              <div className="sidebar-child">
                <Link to="/projects">
                  <div className="sidebar-child-text">Projects</div>
                </Link>
              </div>
            </>
          ),
        },
        {
          id: 4,
          name: "add-project",
          route: "projects/add",
          path: (
            <>
              <div className="sidebar-child">
                <Link to="projects/add">
                  <div className="sidebar-child-text">Add Project</div>
                </Link>
              </div>
            </>
          ),
        },
      ],
    },
    {
      id: 5,
      name: "beneficiary",
      title: "Beneficiaries",
      route: "/beneficiaries",
      path: (
        <>
          <div className="icon-bg">
            <FaUserFriends className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Beneficiaries</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <FaUserFriends className="sidebar-icon" />
          </div>
        </>
      ),
      children: [
        {
          id: 6,
          name: "beneficiary",
          title: "Beneficiaries",
          route: "/beneficiaries",
          path: (
            <>
              <div className="sidebar-child">
                <Link to="/beneficiaries">
                  <div className="sidebar-child-text">Beneficiaries</div>
                </Link>
              </div>
            </>
          ),
        },
        {
          id: 7,
          name: "add-beneficiaries",
          route: "beneficiaries/add",
          path: (
            <>
              <div className="sidebar-child">
                <Link to="beneficiaries/add">
                  <div className="sidebar-child-text">Add Beneficiaries</div>
                </Link>
              </div>
            </>
          ),
        },
      ],
    },
    {
      id: 8,
      name: "facility",
      route: "/facilities",
      path: (
        <>
          <div className="icon-bg">
            <FaBuilding className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Facility</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <FaBuilding className="sidebar-icon" />
          </div>
        </>
      ),
      children: [
        {
          id: 9,
          name: "facilities",
          route: "facilities",
          path: (
            <>
              <div className="sidebar-child">
                <Link to="/facilities">
                  <div className="sidebar-child-text">Facilities</div>
                </Link>
              </div>
            </>
          ),
        },
        {
          id: 10,
          name: "add-facility",
          route: "facilities/add",
          path: (
            <>
              <div className="sidebar-child">
                <Link to="facilities/add">
                  <div className="sidebar-child-text">Add Facility</div>
                </Link>
              </div>
            </>
          ),
        },
      ],
    },
    {
      id: 11,
      name: "monitoring",
      route: "/monitoring-visit-report",
      icon: <Icon.Speedometer2 />,
      path: (
        <>
          <div className="icon-bg">
            <FaJournalWhills className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Monitoring</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <FaJournalWhills className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 12,
      name: "complaint",
      title: "Complaints",
      route: "/complaints",
      path: (
        <>
          <div className="icon-bg">
            <FaExclamationTriangle className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Complaints</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <FaExclamationTriangle className="sidebar-icon" />
          </div>
        </>
      ),
      children: [
        {
          id: 13,
          name: "complaint",
          title: "Complaints",
          route: "/complaints",
          path: (
            <>
              <div className="sidebar-child">
                <Link to="/complaints">
                  <div className="sidebar-child-text">Complaints</div>
                </Link>
              </div>
            </>
          ),
        },
        {
          id: 14,
          name: "complaint",
          route: "complaints/add",
          path: (
            <>
              <div className="sidebar-child">
                <Link to="complaints/add">
                  <div className="sidebar-child-text">Add Complaints</div>
                </Link>
              </div>
            </>
          ),
        },
        {
          id: 15,
          name: "dashboard",
          route: "complaints/dashboard",
          path: (
            <>
              <div className="sidebar-child">
                <Link to="complaints/dashboard">
                  <div className="sidebar-child-text">Dashboard</div>
                </Link>
              </div>
            </>
          ),
        },
      ],
    },

    // {
    //   id: 3,
    //   name: "meal",
    //   path: (
    //     <NavLink to="/meal-progress-report">
    //       <span>
    //         <Icon.JournalPlus />
    //       </span>
    //       <span className="title">MEAL Unit Progress</span>
    //     </NavLink>
    //   ),
    // },
    // {
    //   id: 4,
    //   name: "monitoring",
    //   path: (
    //     <NavLink to="/monitoring-visit-report">
    //       <span>
    //         <Icon.JournalPlus />
    //       </span>
    //       <span className="title">Monitoring Visit Report</span>
    //     </NavLink>
    //   ),
    // },
    // {
    //   id: 5,
    //   name: "beneficiary",
    //   title: "Beneficiaries",
    //   path: (
    //     <NavLink to="/beneficiaries">
    //       <span>
    //         <Icon.PersonWorkspace />
    //       </span>
    //       <span className="title">Target Beneficiaries</span>
    //     </NavLink>
    //   ),
    // },
    // {
    //   id: 6,
    //   title: "Complaints",
    //   name: "complaint",
    //   path: (
    //     <NavLink to="/complaints">
    //       <span>
    //         <Icon.PatchQuestion />
    //       </span>
    //       <span className="title">All Complaints</span>
    //     </NavLink>
    //   ),
    //   children: [
    //     {
    //       id: 7,
    //       title: "Dashboard",
    //       path: (
    //         <NavLink to="/complaints/dashboard">
    //           <span>
    //             <Icon.Speedometer2 />
    //           </span>
    //           <span className="title">Complaint Dashboard</span>
    //         </NavLink>
    //       ),
    //     },
    //     // {
    //     //   id: 8,
    //     //   title: "Add Complaint",
    //     //   path: (
    //     //     <NavLink to="/complaints/add">
    //     //       <span>
    //     //         <Icon.Plus />
    //     //       </span>
    //     //       <span className="title">Add Complaint</span>
    //     //     </NavLink>
    //     //   ),
    //     // },
    //   ],
    // },
    {
      id: 16,
      name: "user",
      title: "User Management",
      route: "/UserManagement",
      icon: <FaUsers />,
      path: (
        <>
          <div className="icon-bg">
            <FaUsers className="sidebar-icon" />
          </div>
          <div className="sidebar-text">User Management</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <FaUsers className="sidebar-icon" />
          </div>
        </>
      ),
    },
    {
      id: 17,
      name: "definition",
      route: "/definitions/country",
      path: (
        <>
          <div className="icon-bg">
            <FaList className="sidebar-icon" />
          </div>
          <div className="sidebar-text">Definitions</div>
        </>
      ),
      passivePath: (
        <>
          <div className="icon-bg">
            <FaList className="sidebar-icon" />
          </div>
        </>
      ),
    },
  ];
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedTabForSubMenu, setSelectedTabForSubMenu] = useState(1);
  const [sidebarLinks, setSidebarLinks] = useState([]);

  useEffect(() => {
    getSidebarLinks();
  }, [roles]);

  const getSidebarLinks = () => {
    if (
      canView(["Management"], userRoles) &&
      canView(["Manager"], userRoles) &&
      canView(["Officer"], userRoles)
    ) {
      const data = sidebarData.filter(
        ({ name }) =>
          name == "dashboard" || name == "project" || name == "complaint"
      );
      setSidebarLinks(data);
    } else if (
      canView(["Management"], userRoles) &&
      canView(["Manager"], userRoles)
    ) {
      const data = sidebarData.filter(
        ({ name }) =>
          name == "dashboard" || name == "project" || name == "complaint"
      );
      setSidebarLinks(data);
    } else if (
      canView(["Management"], userRoles) &&
      canView(["Officer"], userRoles)
    ) {
      const data = sidebarData.filter(
        ({ name }) => name == "dashboard" || name == "project"
      );
      setSidebarLinks(data);
    } else if (
      canView(["Manager"], userRoles) &&
      canView(["Officer"], userRoles)
    ) {
      const data = sidebarData.filter(
        ({ name }) => name == "complaint" || name == "project"
      );
      setSidebarLinks(data);
    } else if (canView(["Management"], userRoles)) {
      const data = sidebarData.filter(
        ({ name }) => name == "dashboard" || name == "project"
      );
      setSidebarLinks(data);
    } else if (canView(["Officer"], userRoles)) {
      const data = sidebarData.filter(({ name }) => name == "project" || name == 'beneficiary'
        || name == 'facility' || name == "complaint");
      setSidebarLinks(data);
    } else if (canView(["Manager"], userRoles)) {
      const data = sidebarData.filter(
        ({ name }) => name == 'dashboard' || name == "project" || name == "complaint" ||
          name == "beneficiary" || name == "facility" || name == "monitoring" || name == "user" || name == "definition"
      );
      setSidebarLinks(data);
    } else if (canView(['Partner'], userRoles)) {
      const data = sidebarData.filter(
        ({ name }) => name == 'project' || name == "beneficiary" || name == "facility" || name == 'complaint'
      );
      setSidebarLinks(data);
    }
    else {
      setSidebarLinks(sidebarData);
    }
  };

  return (
    <div
      className="wrap"
      style={{ width: toggle ? "" : "5rem", borderWidth: 1 }}
    >
      {/* <a
        href="/"
        className="logo"
        style={{ justifyContent: "start", marginLeft: "20px" }}
      >
        <img src={Logo} alt="logo" />
      </a>
      <a href="/" className="mobileLogo">
        <img src={MobileLogo} alt="logo" />
      </a>  */}
      {toggle && (
        <a
          href="/"
          className="logo"
          style={{ justifyContent: "start", marginLeft: "20px" }}
        >
          <img src={Logo} alt="logo" />
        </a>
      )}
      {!toggle && (
        <a href="/" className="logo">
          <img src={MobileLogo} alt="logo" height={50} />
        </a>
      )}

      {/* <div className="btn" onClick={() => toggleSidebar()}>
        <div className="btn-bg">{toggle ? <FaAngleDoubleLeft /> : <FaBars />}</div>
      </div> */}
      <div className="toggle-btn-new" onClick={() => toggleSidebar()}>
        <div className="inside-btn-toggle">
          {toggle ? (
            <FaAngleDoubleLeft color="#E4701E" />
          ) : (
            <FaAngleDoubleRight color="#E4701E" />
          )}
        </div>
      </div>

      <div className="sidebar">
        {sidebarLinks.length > 0 &&
          sidebarLinks.map((i) => {
            return (
              <NavLink to={i.route}>
                <div
                  className={`${toggle ? `sidebar-nav` : `passive-sidebar-nav`
                    } ${i.id === selectedTab ? "active-sidebar-link" : ""}`}
                  key={i.id}
                  onClick={() => {
                    setSelectedTab(i.id);
                  }}
                >
                  {toggle ? i.path : i.passivePath}
                </div>

                {i.id === selectedTab ? (
                  <>
                    {toggle && i.children && (
                      <>
                        {i.children.map((j, i) => {
                          return (
                            <li
                              style={{
                                fontWeight: "normal",
                              }}
                              className={`nav-link pl-2 ${j.id === selectedTabForSubMenu ? "active" : ""
                                }`}
                              key={`child-${j.id}-${i}`}
                              // key={`${j.id}`}
                              onClick={() => setSelectedTabForSubMenu(j.id)}
                            >
                              {j.path}
                            </li>
                          );
                        })}
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </NavLink>
            );
          })}
      </div>
      <hr className="line1" />
      {/* <ul className="items">
        {sidebarLinks.length > 0 &&
          sidebarLinks.map((i) => {
            return (
              <li
                className={`nav-link ${i.id === selectedTab ? "active" : ""} ${i.children && "has-children"
                  }`}
                key={i.id}
                onClick={() => setSelectedTab(i.id)}
              >
                {i.path}
                {i.children && (
                  <ul className="items child-items">
                    {i.children.map((j, i) => {
                      return (
                        <li
                          className={`nav-link pl-2 ${j.id === selectedTab ? "active" : ""
                            }`}
                          key={`child-${j.id}-${i}`}
                          // key={`${j.id}`}
                          onClick={() => setSelectedTab(j.id)}
                        >
                          {j.path}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
      </ul> */}
      <hr className="line1" />
    </div>
  );
};

export default Sidebar;
