import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  values: {},
  validations: {},
  lookups: {},
  reset: false,
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setFormValues(state, action) {
      return { ...state, values: { ...state.values, ...action.payload } };
    },
    setValidations(state, action) {
      return { ...state, validations: action.payload };
    },
    setLookup: (state, action) => {
      let data = action.payload.data;
      let key = action.payload.key;
      if (typeof key === "object") {
        data = {
          ...data,
          items: data.items.filter(
            (i) =>
              i.targetTypeLookup[key.onCondition.key] === key.onCondition.value
          ),
        };
        key = key.key;
      }
      let lookup = { [key]: data };
      return {
        ...state,
        lookups: { ...state.lookups, ...lookup },
      };
    },
    clearForm: (state, action) => {
      return {
        ...state,
        reset: true,
        values: {},
      };
    },
  },
});

export const { setValidations, clearForm, setLookup, setFormValues } =
  formSlice.actions;
// Action creators are generated for each case reducer function

export default formSlice.reducer;
