import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import LogframeColumns from "../../assets/columns/LogframeColumns";
import "./Facility.scss";
import { ProjectFacilitiesColumn, ProjectFacilitiesCustomColumnForExcel } from "../../assets/columns/ProjectFacilities";

const ProjectFacilities = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const { items } = useSelector((state) => state.records.data);


  useEffect(() => {
    dispatch({
      type: "records/getRecords",
      // we need to still add the project id
      payload: { url: `/Facility/GetAll` },
    });
  }, []);
  
//   useEffect(() => {
//     // Wait until the `items` variable is defined
//     if (items) {
//       // Extract the smartIndicator arrays from each object into a new array
//       const smartIndicators = items?.map(obj => obj?.smartIndicator);
  
//       // Or, if you want to flatten the arrays into a single array of smart indicators
//       const flattenedSmartIndicators = [].concat(...smartIndicators);
  
//       setSmartIndicators(flattenedSmartIndicators);
//     }
//   }, [items]);

  const columns = [
    ...ProjectFacilitiesColumn,
    {
      name: "Action",
      selector: (row) => (
        <>
          <Link to={`/projects/specific-objective/${row.id}`}>
            <Icon.Folder2Open className="action-icons" />
          </Link>
          {/* <Icon.PencilSquare className="action-icons" />
          <Icon.Trash className="action-icons" /> */}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="sub d-flex justify-content-between align-items-center px-3 mb-3">
        <div>
          <h3>Facilities</h3>
        </div>
        {/* <div className="right">
          <span className="px-1">
            <Icon.Funnel />
          </span>
          <span>
            <Link to="/projects/add">
              <Icon.PlusCircle />
            </Link>
          </span>
        </div> */}
      </div>
      <div>
        <Table columns={columns} customColumnNames={ProjectFacilitiesCustomColumnForExcel} data={items} />
      </div>
    </>
  );
};

export default ProjectFacilities;
