import React from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";
import Loader from "../../components/loader/loader";
import usePageMeta from "../../hooks/usePageMeta";
import { getFormatedDate } from "../../Utility";

const MealProgressReport = () => {
  const { setMeta } = usePageMeta();
  const { id } = useParams();
  const { loading, data } = useSelector((state) => state.records);
  const dispatch = useDispatch();
  const {
    monitoring = "",
    evaluation = "",
    learning = "",
    accountability = "",
    programFacilitation = "",
    startDate = "",
    endDate = "",
  } = data;

  useEffect(() => {
    dispatch({
      type: "records/getRecords",
      payload: { url: `/MealProject/${id}` },
    });
  }, [id]);

  useEffect(() => {
    setMeta({ title: data?.project?.name });
  }, [data?.project]);

  return (
    <div className="mt-4">
      <Loader loading={loading} />
      <h5 className="mb-4">MEAL Unit Progress</h5>
      <hr className="m-0" />

      {/* details */}
      <div className="row">
        <div className="col-md-6">
          <div className="py-2">
            <h5 className="text-primary">Details</h5>
            <div aria-colspan="mt-2">
              <p>
                <b>Start Date:</b> {getFormatedDate(startDate)}
              </p>
              <p>
                <b>End Date:</b> {getFormatedDate(endDate)}
              </p>
              <p>
                <b>Village:</b> {data?.village}
              </p>
            </div>
          </div>
          <div className="py-2">
            <h5 className="text-primary">Monitoring</h5>
            <p>{monitoring}</p>
          </div>
          <div className="py-2">
            <h5 className="text-primary">Evaluation/Survey/Assessment</h5>
            <p>{evaluation}</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="py-2">
            <h5 className="text-primary">Accountability</h5>
            <p>{accountability}</p>
          </div>
          <div className="py-2">
            <h5 className="text-primary">Learning</h5>
            <p>{learning}</p>
          </div>
          <div className="py-2">
            <h5 className="text-primary">Program Facilitation</h5>
            <p>{programFacilitation}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MealProgressReport;
