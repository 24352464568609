const RELATION_SHIP_TYPE = {
    FATHER: "Father",
    MOTHER: "Mother",
    SISTER: "Sister",
    BROTHER: "Brother",
    SON: "Son",
    DAUGHTER: "Daughter",
    WIFE: "Wife",
    HUSBAND: "Husband",
}
export default RELATION_SHIP_TYPE;