import { MODE_OF_RESPONSE } from "../../Constants";

const modeOfResponseTypes = [
    {
        label: MODE_OF_RESPONSE.TELEPHONICALLY,
        value: MODE_OF_RESPONSE.TELEPHONICALLY
    },
    {
        label: MODE_OF_RESPONSE.MEETING,
        value: MODE_OF_RESPONSE.MEETING
    },
    {
        label: MODE_OF_RESPONSE.OTHERS,
        value: MODE_OF_RESPONSE.OTHERS
    }
]
export default modeOfResponseTypes;