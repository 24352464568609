import { COMPLAINT_STATUS_TYPE } from "../../Constants";
import { getFormatedDate } from "../../Utility";

const ComplaintFeedbackColumns = [
  {
    name: "Designated Person",
    selector: (row) => row?.designatedPerson?.name,
    // maxWidth: 150
  },
  {
    name: "Communicaton Person",
    selector: (row) => row?.communicatingPerson?.name,
    // maxWidth: 150
  },
  {
    name: "Complaint Status",
    selector: (row) => (
      <>
        <span
          className={
            row.statusOfComplaint == COMPLAINT_STATUS_TYPE.CLOSED
              ? "text-success"
              : "text-warning"
          }
        >
          {row?.statusOfComplaint}
        </span>
      </>
    ),
    maxWidth: 150,
  },
  {
    name: "Resolving Date",
    selector: (row) => getFormatedDate(row?.complaintResolvingDate),
    maxWidth: 150,
  },
  {
    name: "Days to Resolving",
    selector: (row) => row?.noOfDaysToResolveComplaint,
    maxWidth: 150,
  },
  {
    name: "Action Taken",
    selector: (row) => row?.actionTaken,
    maxWidth: 150,
  },
  {
    name: "Outcome",
    selector: (row) => row?.outcome,
    maxWidth: 150,
  },
];

export default ComplaintFeedbackColumns;

export const ComplaintFeedbackCustomColumnForExcel = {
  "Designated Person": "designatedPerson",
  "Communication Person": "communicatingPerson",
  "Complaint Status": "statusOfComplaint",
  "Resolving Date": "complaintResolvingDate",
  "Days to Resolving": "noOfDaysToResolveComplaint",
  "Action Taken": "actionTaken",
  "Outcome": "outcome",
}
