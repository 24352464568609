import genderTypes from "./../../assets/values/genderTypes";
import ageGroupTypes from "./../../assets/values/ageGroupTypes";

const complaintFields = [
  {
    category: "complainant",
    label: "Complainant Consent",
    placeholder:
      "Do you give me your consent to collect this information, file your complaint and report it?",
    name: "consent",
    id: "consent",
    inputType: "async-dropdown",
    options: [
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
    onChangeMethod: "asyncdropdown",
    searchAttrib: "Search.value",
    labelAttribute: "label",
    valueAttribute: "value",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  {
    category: "complainant",
    label: "Anonymity",
    placeholder: "Do you want to remain Anonymous?",
    name: "remainAnonymous",
    id: "remainAnonymous",
    inputType: "async-dropdown",
    options: [
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
    onChangeMethod: "asyncdropdown",
    searchAttrib: "Search.value",
    labelAttribute: "label",
    valueAttribute: "value",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  {
    category: "complainant",
    label: "Name",
    placeholder: "Enter Complainant's Name",
    name: "complainantName",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "text",
  },
  {
    category: "complainant",
    label: "Guardian Name",
    placeholder: "Enter Guardian's Name",
    name: "guardianName",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "text",
  },
  {
    category: "complainant",
    label: "CNIC/ POR No.",
    placeholder: "CNIC/ POR No.",
    name: "cnic",
    // type: "number",
    pattern: "[0-9]+",
    maxlength: 13,
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      min: 13,
      max: 13,
      required: true,
    },
  },
  {
    category: "complainant",
    label: "Phone",
    placeholder: "Enter Telephone Number",
    name: "cellNo",
    // type: "number",
    pattern: "[0-9]+",
    maxlength: 13,
    inForm: true,
    wrapperClass: "col-md-6",
    validation: {
      min: 10,
      max: 11,
      required: true,
    },
  },
  // {
  //   category: "complainant",
  //   label: "Father Name",
  //   placeholder: "Enter Father Name",
  //   name: "guardianName",
  //   inForm: true,
  //   wrapperClass: "col-md-6",
  //   inputType: "text",
  // },
  {
    category: "complainant",
    label: "Project",
    placeholder: "Which project does the feedback concern?",
    name: "project",
    inputType: "async-dropdown",
    url: "/project/GetAll?DisablePagination=true",
    searchAttrib: "Search.value",
    id: "project",
    labelAttribute: "title",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    category: "complainant",
    label: "Other Contact Info",
    placeholder: "Enter any other contact information",
    name: "otherContactInfo",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "text",
  },
  {
    category: "complainant",
    label: "Gender",
    placeholder: "What is complainant's Gender",
    name: "complainantGender",
    id: "complainantGender",
    inputType: "async-dropdown",
    options: genderTypes,
    onChangeMethod: "asyncdropdown",
    searchAttrib: "Search.value",
    labelAttribute: "label",
    valueAttribute: "value",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  {
    category: "complainant",
    label: "Age",
    placeholder: "Enter Complainant's Age",
    name: "age",
    type : "number",
    id: "age",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "text",
  },
  {
    category: "complainant",
    label: "Disability",
    placeholder: "Is complainant disabled?",
    name: "disability",
    id: "disability",
    inputType: "async-dropdown",
    options: [
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
    ],
    onChangeMethod: "asyncdropdown",
    searchAttrib: "Search.value",
    labelAttribute: "label",
    valueAttribute: "value",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  // {
  //   category: "complainant",
  //   label: "Activity",
  //   placeholder: "Select Activity",
  //   name: "activity",
  //   id: "activity",
  //   inputType: "async-dropdown",
  //   url: "/ERActivity/GetActivities", //?ProjectId=3
  //   searchAttrib: "Search.value",  //   labelAttribute: "name",
  //   valueAttribute: "id",
  //   onChangeMethod: "asyncdropdown",
  //   inForm: true,
  //   wrapperClass: "col-md-6",
  //   isMulti: false,
  //   condition: {
  //     fieldName: "project",
  //     paramField: "projectId",
  //     action: "useValue",
  //   },
  // },

  {
    category: "complain-info",
    label: "Complainant's Status",
    placeholder:
      "Are you a participant in CARE or partner activities and programs?",
    name: "participantsStatus",
    id: "participantsStatus",
    inputType: "async-dropdown",
    options: [
      {
        label: "Yes",
        value: "Yes",
      },
      {
        label: "No",
        value: "No",
      },
      {
        label: "Do not know",
        value: "DonotKnow",
      },
    ],
    onChangeMethod: "asyncdropdown",
    searchAttrib: "Search.value",
    labelAttribute: "label",
    valueAttribute: "value",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
    isEnum: true,
  },
  {
    category: "complain-info",
    label: "Feedback Channel",
    placeholder: "Select Mechanism used to give Feedback",
    name: "modeOfComplaint",
    id: "modeOfComplaint",
    inputType: "async-dropdown",
    url: "/ModeOfComplaint/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    category: "complain-info",
    label: "Complaint Topic",
    placeholder: "Enter Topic of Complaint",
    name: "complaintTopic",
    inForm: true,
    wrapperClass: "col-md-6",
    inputType: "text",
  },
  {
    category: "complain-info",
    label: "Complainant Type",
    placeholder: "Select Type of Complainant",
    name: "typeOfComplainant",
    id: "typeOfComplainant",
    inputType: "async-dropdown",
    url: "/TypeOfComplainant/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    category: "complain-info",
    label: "Nature of Complaint",
    placeholder: "Select Nature of Complaint",
    name: "natureOfComplaint",
    id: "natureOfComplaint",
    inputType: "async-dropdown",
    url: "/NatureOfComplaint/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    category: "complain-info",
    label: "Acknowledgement Of Complaint",
    placeholder: "Select Acknowledgement",
    name: "acknowledgementOfComplaint",
    id: "acknowledgementOfComplaint",
    inputType: "async-dropdown",
    url: "/AcknowledgementOfComplaint/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-6",
    isMulti: false,
  },
  {
    category: "complain-info",
    label: "Date of Complain",
    placeholder: "Enter Date of Complain",
    name: "dateOfComplaint",
    inForm: true,
    wrapperClass: "col-6",
    inputType: "date",
  },
  // {
  //   category: "complain-info",
  //   label: "Complaint Type",
  //   placeholder: "Select Complaint Nature",
  //   name: "type",
  //   inputType: "async-dropdown",
  //   id: "dropdown",
  //   options: [
  //     {
  //       value: "Complaint",
  //       label: "Complaint",
  //     },
  //   ],
  //   onChangeMethod: "asyncdropdown",
  //   inForm: true,
  //   isEnum: true,
  //   wrapperClass: "col-md-6",
  //   isMulti: false,
  // },
  {
    category: "complain-info",
    label: "Description",
    placeholder: "Enter Complainant's Feedback",
    name: "descriptionOfComplaint",
    inForm: true,
    rows: 3,
    wrapperClass: "col-md-6",
    inputType: "textarea",
    className: "bg-light rounded",
  },
  {
    category: "complainant",
    children: "Address",
    inputType: "h4",
    inForm: true,
    wrapperClass: "col-12 mt-4",
  },
  // {
  //   "category": "complainant",
  //   "label": "Province",
  //   "placeholder": "Enter Province",
  //   "name": "province",
  //   "id": "province",
  //   "inputType": "async-dropdown",
  //   "url": "/Province/GetAll",
  //   "searchAttrib: "Search.value",
  //   "labelAttribute": "name",
  //   "valueAttribute": "id",
  //   "onChangeMethod": "asyncdropdown",
  //   "inForm": true,
  //   "wrapperClass": "col-md-4",
  //   "isMulti": false
  // },
  {
    category: "complainant",
    label: "District",
    placeholder: "Enter District",
    name: "district",
    id: "district",
    inputType: "async-dropdown",
    url: "/District/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    // "condition": {
    //   "fieldName": "province",
    //   "paramField": "ProvinceId",
    //   "action": "useValue"
    // }
  },
  {
    category: "complainant",
    label: "Tehsil",
    placeholder: "Enter Tehsil",
    name: "tehsil",
    id: "tehsil",
    inputType: "async-dropdown",
    url: "/Tehsil/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    condition: {
      fieldName: "district",
      paramField: "DistrictId",
      action: "useValue",
    },
  },
  {
    category: "complainant",
    label: "UC",
    placeholder: "Enter UC",
    name: "unionCouncil",
    id: "unionCouncil",
    inputType: "async-dropdown",
    url: "/UnionCouncil/GetAll",
    searchAttrib: "Search.value",
    labelAttribute: "name",
    valueAttribute: "id",
    onChangeMethod: "asyncdropdown",
    inForm: true,
    wrapperClass: "col-md-4",
    isMulti: false,
    condition: {
      fieldName: "tehsil",
      paramField: "TehsilId",
      action: "useValue",
    },
  },
  {
    category: "complainant",
    label: "Address",
    placeholder: "Enter Address",
    name: "address",
    inputType: "textarea",
    inForm: true,
    wrapperClass: "col-md-4",
    className: "bg-light rounded",
  },
];
export default complaintFields;
