import React, { useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { complaintResponseFields } from "../../assets/fields/complaintResponseFields";

import { clearForm, createRecord, putRecord } from "../../redux/reducers";
import Form from "../form/Form";
import Loader from "../loader/loader";

const ComplaintResponseModal = ({
  modalIsOpen,
  toggleComplaintResponseModal,
}) => {
  const dispatch = useDispatch();
  const { loading, created } = useSelector((state) => state.records);
  const formData = useSelector((state) => state.form.values);
  const { isEdit } = formData;
  const id = formData?.complaintFeedback?.value?.id

  useEffect(() => {
    if (created) { }
    toggleComplaintResponseModal()
    return () => {

    }
  }, [created])

  useEffect(() => {
    if (!modalIsOpen) {
      dispatch({ type: clearForm.type })
    }
  }, [modalIsOpen])

  useEffect(() => {
    if (id)
      dispatch({
        type: "records/getRecords",
        payload: { url: `/ComplaintsFeedbackResolution/${id}`, appendCurrentReducerKey: "currentComplaint" },
      });
  }, [id])

  function postData(params) {
    const formValues = { ...formData, ...params }

    if (isEdit?.value) {
      dispatch({ type: putRecord.type, payload: { ...formValues, url: '/ComplaintsResponse' } })
    }
    else {
      dispatch({ type: createRecord.type, payload: { ...formValues, url: '/ComplaintsResponse' } })
    }
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
      >
        <Icon.XCircle onClick={toggleComplaintResponseModal} className="action-icons" />
        <h4 className="text-capitalize">{formData?.complaint?.name}: Complaint Response</h4>
        <Loader loading={loading} />
        <Form
          formData={[...complaintResponseFields]}
          onSubmit={(params) => postData(params)}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </Modal>
    </>
  );
};

export default ComplaintResponseModal;
