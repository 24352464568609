export const loginFields = [
  {
    name: "email",
    inputType: "email",
    label: "Email Address",
    type: "email",
    inForm: true,
    required: true,
    placeholder: "Enter Email Address",
    wrapperClass: "form-outline mb-0 col-12",
  },
  {
    name: "password",
    inputType: "password",
    label: "Password",
    required: true,
    type: "password",
    placeholder: "Enter Password",
    wrapperClass: "form-outline mb-3 col-12",
    inForm: true,
  },
  {
    name: "signin",
    inputType: "button",
    buttonType: "submit",
    children: "Sign in",
    inForm: true,
    wrapperClass: "pr-3 btn-wrapper col-12",
  },
];
