import React, { useState, useEffect, useRef, useCallback } from "react";
import * as Icon from "react-bootstrap-icons";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import SmartIndicatorColumns, { SmartIndicatorColumnsForExcel } from "../../assets/columns/SmartIndicatorColumns";

import { smartIndicatorFields } from "../../assets/fields/smartIndicatorFields";
import { INTERVENTION_LOGIC_TYPE } from "../../Constants";
import DeleteRow from "../../pages/DeleteRow";
import {
  createRecord,
  formatRecords,
  getRecords,
  putRecord,
  setFormFields,
  setFormValues,
} from "../../redux/reducers";
import Expandable from "../accordian/Expandable";
import Form from "../form/Form";
import "./SmartIndicator.scss";
import { getQuarterValuesFromLocalStorage } from "./Helper";
import { formatFormValue } from "../form/form.helper";

const SmartIndicator = ({
  projectId,
  type = INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE,
}) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const [objectiveYear, setObjectiveYear] = useState();
  const [objective, setObjective] = useState();
  const { data, current, created, creating, loading } = useSelector(
    (state) => state.records
  );
  const { values } = useSelector((state) => state.form);
  const formValues = values;

  const soData = data?.items;
  console.log("🚀 ~ file: SmartIndicator.js:40 ~ soData:", soData)
  const indicatorList = current?.indicatorList?.data?.items;

  const SM_URL =
    type == INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE
      ? "/SmartIndicator/GetAll"
      : "/SmartIndicator/GetAll";
  const IL_URL =
    type == INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE
      ? "/InterventionLogic/GetSpecificObjectives"
      : "/InterventionLogic/GetExpectedResults";
  ///ILSmartIndicator/GetAll?ProjectId=${projectId}

  useEffect(() => {
    if (projectId) {
      getAllObjectives();
    }
    // getSmartIndicatorsByProjectId();
  }, [projectId]);

  useEffect(() => {
    if (objective) {
      handleIndicatorList();
    }
  }, [objective]);

  useEffect(() => {
    if (created) {
      // setTimeout(() => {
      // getSmartIndicatorsByProjectId();
      closeModal();
      // }, 1000);
    }
  }, [created]);

  function getAllObjectives() {
    dispatch({
      type: "records/getRecords",
      payload: { url: `/Objective/GetAll?Project.Id=${projectId}` },
    });
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Project/GetProjectDetails?id=${projectId}`,
        appendCurrentReducerKey: "projectDetails",
      },
    });
  }

  const location = current?.projectDetails?.data?.locations.map((item) => ({
    label: item.name,
    value: { label: item.name, value: item.id },
  }));

  const thematicArea = current?.projectDetails?.data?.thematicAreas.map(
    (item) => ({
      label: item.name,
      value: { label: item.name, value: item.id },
    })
  );

  function openSmartIndicatorModal(row) {
    if (row) {
      setCurrentRow(row);
      localStorage.setItem("currentRow", JSON.stringify(row));
      const overridePayload = {
        interventionLogic:
          type == INTERVENTION_LOGIC_TYPE.SPECIFIC_OBJECTIVE
            ? row?.specificObjective
            : row?.expectedResult,
      };

      // let updateData = {
      //   ...row,
      //   hhIndicator: JSON.stringify(row.hhIndicator),
      // };

      dispatch({
        type: formatRecords.type,
        payload: {
          ...row,
          ...overridePayload,
        },
      });
    }

    dispatch({
      type: "form/setLookup",
      payload: { key: "siLocations", data: { items: location } },
    });
    dispatch({
      type: "form/setLookup",
      payload: { key: "thematicArea", data: { items: thematicArea } },
    });
    setIsOpen(true);
  }

  function afterOpenSmartIndicatorModal() {}

  function closeModal() {
    setIsOpen(false);

    setCurrentRow({});
    dispatch({ type: "form/clearForm" });
    localStorage.setItem("currentRow", JSON.stringify({ targetList: [] }));
  }

  function handleUpdate() {
    getAllObjectives();
  }

  const columns = [
    ...SmartIndicatorColumns,
    {
      name: "Action",
      selector: (row) => (
        <>
          <Icon.PencilSquare
            className="action-icons"
            onClick={() => openSmartIndicatorModal(row)}
          />
          <DeleteRow
            row={row}
            url="/SmartIndicator"
            handleUpdate={handleUpdate}
          />
        </>
      ),
    },
  ];

  function formatFields() {
    let fields = [
      ...smartIndicatorFields.slice(0, 2),
      ...smartIndicatorFields.slice(2),
    ];

    return fields.map((field) => {
      if (field.name == "interventionLogic")
        return {
          ...field,
          url: `${IL_URL}?Project.Id=${projectId}`,
          label:
            type == INTERVENTION_LOGIC_TYPE.EXPECTED_RESULT
              ? "Expected Result"
              : field.label,
        };
      return field;
    });
  }

  function handleIndicatorList() {
    if (objective) {
      dispatch({
        type: getRecords.type,
        payload: {
          url: `/SmartIndicator/GetAll?Objective.Id=${objective?.id}`,
          appendCurrentReducerKey: "indicatorList",
        },
      });
    }
  }

  function postData(params) {
    const quarterArray = getQuarterValuesFromLocalStorage();
    let updatedFormValues = {
      ...formValues,
      hhIndicator: {
        name: "hhIndicator",
        value: formValues.hhIndicator?.value?.value,
      },
      objective: objective,
      targetList: { name: "targetList", value: quarterArray },
    };

    if (currentRow && Object.keys(currentRow).length == 0) {
      dispatch({
        type: createRecord.type,
        payload: { ...updatedFormValues, url: "/SmartIndicator" },
      });
    } else {
      dispatch({
        type: putRecord.type,
        payload: { ...updatedFormValues, url: "/SmartIndicator" },
      });
    }

    getAllObjectives();
  }

  function toggleObjectiveModal(item) {
    if (item) {
      dispatch({
        type: formatRecords.type,
        payload: { ...item },
      });
    }
  }

  return (
    <div className="table-wrapper table-responsive smart-indicator mt-4">
      <div className="d-sm-flex col-sm-12">
        <h5 className="font-weight-bold">Indicators</h5>
      </div>

      <div className="col-sm-12 mt-6">
        {soData?.map((item, key) => {
          return (
            <div key={key}>
              <Expandable
                title={item.objectiveTitle}
                totalYears={item.numberOfYears}
                setObjectiveYear={setObjectiveYear}
                setObjective={setObjective}
                item={item}
                indicatorList={indicatorList}
                addIndicator={openSmartIndicatorModal}
                content={columns}
                customColumn={SmartIndicatorColumnsForExcel}
                handleIndicatorList={handleIndicatorList}
                toggleObjectiveModal={toggleObjectiveModal}
              />
            </div>
          );
        })}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenSmartIndicatorModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        overlayClassName="modal-sm smart-indicator-modal"
        contentLabel="Indicator"
      >
        <Icon.XCircle onClick={closeModal} className="action-icons" />
        <h4>Indicator</h4>
        <Form
          formData={formatFields()}
          onSubmit={() => postData()}
          withValidation={false}
          formValidation={[]}
          extraInputClass=""
        />
      </Modal>
    </div>
  );
};

export default SmartIndicator;
