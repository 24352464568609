import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import "./Header.scss";
import { saveUserState } from "../../utils/localStorage";
import { getRecords } from "../../redux/reducers";
import { ORDER_DIRECTION } from "../../Constants";
import Notifications from "../notification/Notifications";
import HeaderProfile from "../header-profile/HeaderProfile";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { id, firstName = "" } = useSelector(
    (state) => state?.setting?.user?.userDetail
  );
  const { pageMeta } = useSelector((state) => state.setting);

  const items = useSelector(
    (state) => state.records?.current?.headerNotification?.data?.items
  );

  function handleChange({ value }) {
    if (value == "logout") {
      dispatch({ type: "setting/setUser", payload: {} });
      saveUserState({});
      navigate("/");
    } else {
      navigate(`user/${value}`);
    }
  }

  function handleChangeValue(value) {
    if (value == "logout") {
      dispatch({ type: "setting/setUser", payload: {} });
      saveUserState({});
      navigate("/");
    } else {
      navigate(`user/${value}`);
    }
  }

  const getNotifications = () => {
    dispatch({
      type: getRecords.type,
      payload: {
        url: `/Notification/GetAll`,
        params: { OrderDir: ORDER_DIRECTION.DESC },
        appendCurrentReducerKey: "headerNotification",
      },
    });
  };

  const [isImageClicked, setIsImageClicked] = useState(false);

  return (
    <div className="header row justify-content-between">
      <div className="col-md-6 col-12">
        <h4 className="text-capitalize" style={{ fontSize: "15px" }}>
          Pages / <b>{pageMeta?.title}</b>
        </h4>
        <p>{pageMeta?.breadcrumbs}</p>
      </div>

      {/* right side */}
      <div className="right col-md-3 col-12 text-right align-items-center">
        {/* <div className="dropdown">
          <select
            name="dropdown"
            className="p-1 form-control"
            id="dropdown"
            onChange={(evt) => handleChange(evt.target)}
          >
            <option value={"Detail"}>{firstName}'s Profile</option>
            <option value={`${id}`} data-content="<Icon.PencilSquare />">
              Account
              <span>
                <Icon.PencilSquare />
              </span>
            </option>
            <option value={"changePassword"}>Change Password</option>
            <option value="logout">Logout</option>
          </select>
        </div> */}

        {/* <div className="header-profile" onClick={() => setIsImageClicked(!isImageClicked)}>
              <div className="profile-img-bg">
              <img src={require('../../assets/profile.png')} alt=""  />
              <p>
                <b>{firstName}</b>
                <p style={{fontSize:'12px'}}>Admin</p>
              </p>
              
              </div>
              {isImageClicked ? 
              <>
              <div className="img-click-text">
                    <div className="profile-box">
                      <p onClick={() => handleChangeValue("Detail")}>{firstName}'s Profile</p>
                      <hr />
                      <p onClick={() => handleChangeValue(`${id}`)}>Account</p>
                      <hr />
                      <p onClick={() => handleChangeValue("changePassword")}>Change Password</p>
                      <hr />
                      <p onClick={() => handleChangeValue("logout")}>Logout</p>
                    </div>
              </div>
              </> : ""}
        </div> */}

        <HeaderProfile id={id} />

        <div className="ml-3">
          <Notifications />
        </div>
      </div>
    </div>
  );
};

export default Header;
