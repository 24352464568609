import { getFormatedDate } from "../../Utility";

const FacilitiesColumns = [

  {
    name: "Name",
    selector: (row) => row?.targetName,
    wrap: true,
    minWidth: "250px",
  },
  {
    name: "Thematic Area",
    selector: (row) => row?.thematicArea?.name,
  },
  {
    name: "Location",
    selector: (row) => row?.location?.name,
  },
  {
    name: "Accomplishment Date",
    selector: (row) => getFormatedDate(row?.accomplishmentDate),
  },
  // {
  //   name: "Address",
  //   selector: (row) => row?.address?.tehsil?.name,
  // },
];

export default FacilitiesColumns;

export const FacilitiesCustomColumnForExcel = {
  "Name": 'targetName',
  "Thematic Area": 'thematicArea',
  "Location": 'location',
  "Accomplishment Date": 'accomplishmentDate',
}
