import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "./SearchFields.scss"

const SearchFields = ({ setHeadingName,match }) => {
  const fields = useSelector((state) => state.fields.leftMenu);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({type:'fields/fetchLayout',payload:match.pathname})
  },[match.pathname])
  return (
    <div className="panel-content-container h-100 ">
      <div className="panel-tab-heading">
        <h5 className="panel-heading-content">Definitions</h5>
      </div>
      <div className="input-group">
        <div className="panel-tab-content">
          {/* <input
            type="text"
            className="panel-tab-heading-text form-control"
            placeholder="Search"
          /> */}
          <ul className="searchList p-0">
            {fields &&
              fields.map((item, index) => {
                return (
                  <li key={index} className="m-2 my-3 border-bottom">
                    <NavLink
                      onClick={() => {
                        setHeadingName(item.label);
                      }}
                      to={item.path}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SearchFields;
