import React, { useEffect, useState } from "react";
import {
  getQuarterDataArray,
  getQuarterlyData,
  saveQuarterValuesToLocalStorage,
  updateQuarterDataArray,
} from "./Helper";
import { useDispatch, useSelector } from "react-redux";
import { getRecords } from "../../redux/reducers";
import "./SmartIndicator.scss";
import { useParams } from "react-router-dom";

const DataContainer = () => {
  const dispatch = useDispatch();
  const { data, current, created, creating, loading } = useSelector(
    (state) => state.records
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [targetList, setTargetList] = useState();
  const [quarterData, setQuarterData] = useState(null);

  let dataX = JSON.parse(localStorage.getItem("currentRow"));

  let { id } = useParams();

  //TODO: get project details
  useEffect(() => {
    setStartDate(current?.projectDetails?.data?.startDate);
    setEndDate(current?.projectDetails?.data?.endDate);
    setTargetList(dataX?.targetList);

    handleQuarterData();
  }, [startDate, endDate]);

  // useEffect(() => {
  //   handleQuarterData();
  // }, [quarterData]);

  //TODO: get project details from redux
  // function getProjectDetails() {
  //   dispatch({
  //     type: getRecords.type,
  //     payload: {
  //       url: `/Project/GetProjectDetails?id=${id}`,
  //       appendCurrentReducerKey: "projectDetails",
  //     },
  //   });

  //   setStartDate(current?.projectDetails?.data?.startDate);
  //   setEndDate(current?.projectDetails?.data?.endDate);
  //   handleQuarterData(startDate, endDate);
  // }

  //TODO: get quarter data
  const handleQuarterData = () => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);
    const data2 = getQuarterlyData(newStartDate, newEndDate);

    setTimeout(() => {
      const data3 = updateQuarterDataArray(data2, targetList);

      setQuarterData(data3);
    }, 2);
  };

  //TODO: update quarter data
  function updateQuarterlyValue(quartersData, id, value) {
    const parsedData = quartersData;
    let foundQuarter = null;

    parsedData.quarters.forEach((yearData) => {
      yearData.quarters.forEach((quarterData) => {
        if (quarterData.id === id) {
          quarterData.value = value;
          foundQuarter = quarterData;
        }
      });
    });

    if (foundQuarter) {
      let finalQuarterArray = getQuarterDataArray(parsedData);
      setQuarterData(parsedData);
      saveQuarterValuesToLocalStorage(finalQuarterArray);
      // return JSON.stringify(parsedData);
    } else {
      throw new Error(`Quarter with id "${id}" not found.`);
    }
  }

  //TODO : JSX
  return (
    <>
      <div className="d-inline-flex QuarterData mt-5">
        {quarterData &&
          quarterData.quarters.map((item, index) => {
            return (
              <div className="quarterBox mb-3 w-1" key={index}>
                <div className="quarterYear border-bottom- text-center border">
                  {item.year}
                </div>
                <div className="quarterValues">
                  <div className="row m-0">
                    {item.quarters.map((q, index) => {
                      return (
                        <div
                          className="col border border-bottom- text-center"
                          key={q.id}
                        >
                          {q.quarter}
                        </div>
                      );
                    })}
                  </div>
                  <div className="row m-0">
                    {item.quarters.map((q, index) => {
                      return (
                        <div className="col text-center p-0" key={q.id}>
                          <input
                            onChange={(ev) =>
                              updateQuarterlyValue(
                                quarterData,
                                q.id,
                                ev.target.value
                              )
                            }
                            defaultValue={q.value}
                            className="quarterVal border text-center"
                            placeholder=""
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default DataContainer;

{
  /* {!showQuarter && (
          <div className="quarter_bg">
            <button className="btn btn-primary" onClick={showQuarterTable}>
              Add Quarter
            </button>
          </div>
        )}
        {showQuarter && quaterDataContainer()}
        {showQuarter && (
          <button
            onClick={() => getQuarterDataArray()}
            className="btn btn-primary"
          >
            Update Quarter
          </button>
        )} */
}

// const quaterDataContainer = () => {
//   return (
//     <>
//       <div className="QuarterData mt-5">
//         {quarterData &&
//           quarterData.quarters.map((item, index) => {
//             return (
//               <div className="quarterBox mb-3" key={index}>
//                 <div className="quarterYear border-bottom- text-center border">
//                   {item.year}
//                 </div>
//                 <div className="quarterValues">
//                   <div className="row m-">
//                     {item.quarters.map((q, index) => {
//                       return (
//                         <div
//                           className="col border border-bottom- text-center"
//                           key={q.id}
//                         >
//                           {q.quarter}
//                         </div>
//                       );
//                     })}
//                   </div>
//                   <div className="row m-">
//                     {item.quarters.map((q, index) => {
//                       return (
//                         <div className="col text-center p-" key={q.id}>
//                           <input
//                             onChange={(ev) =>
//                               updateQuarterlyValue(
//                                 quarterData,
//                                 q.id,
//                                 ev.target.value
//                               )
//                             }
//                             className="quarterVal border text-center"
//                             placeholder=""
//                           />
//                         </div>
//                       );
//                     })}
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//       </div>
//     </>
//   );
// };

// const showQuarterTable = () => {
//   setShowQuarter(true);
//   handleQuarterData();
// };
